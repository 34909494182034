import { useState } from 'react'

import { TeacherSelectorProps } from './types'
import { useRelatedTeachersQuery, UserFragment } from '../schema'

export interface DecoratedSite {
  site: { id: string; name: string }
  teachers: {
    id: string
    user: UserFragment
    onToggle: () => void
    selected: boolean
  }[]
  checked: boolean
  indeterminate: boolean
  selectAll?: () => void
  selectNone?: () => void
}

export const filterMatches = (filter: string, content: string) => {
  const contentSegments = content.toLowerCase().trim().split(' ')
  return (
    !filter ||
    filter
      .toLowerCase()
      .trim()
      .split(' ')
      .some(segment => contentSegments.some(childSegment => childSegment.includes(segment)))
  )
}

export const useTeacherSelector = ({
  selectedTeachers = [],
  onSelected: _onSelected,
  maxSelection = Infinity,
  showSelf = false,
}: TeacherSelectorProps) => {
  const [filter, setFilter] = useState<string>('')
  const { data, loading } = useRelatedTeachersQuery({ variables: { showSelf } })
  const sites = data?.viewer?.teacher?.relatedTeachers?.sites || []
  const onSelected: typeof _onSelected = teachers => {
    if (teachers.length > maxSelection) {
      return _onSelected([...teachers].reverse().slice(0, maxSelection))
    } else {
      return _onSelected(teachers)
    }
  }
  const filteredSites = []
  for (const site of sites) {
    if (filterMatches(filter, site.site.name)) {
      filteredSites.push(site)
    } else {
      const matchedTeachers = site.teachers.filter(teacher => {
        return filterMatches(filter, teacher.user.name || teacher.user.username)
      })
      if (matchedTeachers.length > 0) {
        filteredSites.push({
          ...site,
          teachers: matchedTeachers,
        })
      }
    }
  }
  const decoratedSites = filteredSites
    .filter(site => site.teachers.length)
    .map<DecoratedSite>(site => {
      const selectedSiteTeachers = site.teachers.filter(teacher =>
        selectedTeachers.some(t => t.teacherId === teacher.id),
      )
      return {
        ...site,
        teachers: site.teachers.map(teacher => ({
          ...teacher,
          onToggle: () => {
            const without = selectedTeachers.filter(t => t.teacherId !== teacher.id)
            if (without.length === selectedTeachers.length) {
              onSelected([...selectedTeachers, { teacherId: teacher.id, userId: teacher.user.id }])
            } else {
              onSelected(without)
            }
          },
          selected: selectedTeachers.some(t => t.teacherId === teacher.id),
        })),
        checked: selectedSiteTeachers.length === site.teachers.length,
        indeterminate:
          selectedSiteTeachers.length > 0 && selectedSiteTeachers.length < site.teachers.length,
        selectAll:
          site.teachers.length > 0
            ? () => {
                const newSelectedTeachers = [...(selectedTeachers || [])]
                for (const teacher of site.teachers) {
                  const existing = newSelectedTeachers.find(t => t.teacherId === teacher.id)
                  if (!existing) {
                    newSelectedTeachers.push({
                      teacherId: teacher.id,
                      userId: teacher.user.id,
                    })
                  }
                }
                onSelected(newSelectedTeachers)
              }
            : undefined,
        selectNone:
          site.teachers.length > 0
            ? () => {
                const teacherIds = site.teachers.map(t => t.id)
                onSelected(
                  (selectedTeachers || []).filter(
                    selectedTeacher => !teacherIds.includes(selectedTeacher.teacherId),
                  ),
                )
              }
            : undefined,
      }
    })

  return { loading, decoratedSites, filter, setFilter }
}
