import { Format } from '@thesisedu/feature-utils'

import { ShareIndicatorProps } from './types'

export interface ShareIndicatorResult {
  message?: string
}

export const useShareIndicator = (props: ShareIndicatorProps): ShareIndicatorResult => {
  let message: string | undefined = undefined
  if (props.shareable.sharing.sharedFrom) {
    const from = props.shareable.sharing.sharedFrom
    message = `Shared from ${from.name || from.username}`
  } else if (props.shareable.sharing.sharedWith.totalCount) {
    message = `Shared with ${Format.plural(
      'other user',
      props.shareable.sharing.sharedWith.totalCount,
    )}`
  }

  return { message }
}
