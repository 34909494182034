import { s } from '@thesisedu/ui'
import { Transition, styled } from '@thesisedu/web'
import React from 'react'

export function SegmentCompletionWrapper({
  children,
  className,
  style,
  onClick,
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <Transition type={'fade'} in state={'content'}>
      <Container
        className={`segment-completion-container ${className}`}
        style={style}
        onClick={onClick}
      >
        {children}
      </Container>
    </Transition>
  )
}

const Container = styled.div`
  background: ${props => props.theme['@gray-6']};
  border-radius: ${s.var('radii.2')};
  margin: ${props => props.theme['@size-l']} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme['@size-xxl']} ${props => props.theme['@size-l']};
`
