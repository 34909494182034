import { styled, Reset, Tooltip } from '@thesisedu/react'
import { s } from '@thesisedu/ui'
import { CapitalSmall } from '@thesisedu/web'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ShellContext } from './Shell'

export interface LeftMenuItemProps {
  name: string
  icon: React.ReactElement
  path: string
  childOf?: string
  highlighted?: boolean
  rightContent?: React.ReactElement
  onClick?: () => void
  rightContentDanger?: boolean
  stopRightContentClick?: boolean
  leftMenuCollapsed?: boolean
}
export function LeftMenuItem({
  highlighted,
  name,
  icon,
  path: __path,
  childOf: _childOf,
  onClick,
  stopRightContentClick,
  rightContentDanger,
  rightContent,
  leftMenuCollapsed,
  ...rest
}: LeftMenuItemProps) {
  const {
    shellProps: { leftNavPrefix },
  } = React.useContext(ShellContext)
  const location = useLocation()
  const _path = _childOf ? `${_childOf}/${__path}` : __path
  const path = leftNavPrefix ? `${leftNavPrefix}/${_path}` : _path
  const childOf = _childOf ? (leftNavPrefix ? `${leftNavPrefix}/${_childOf}` : _childOf) : undefined
  const isActive = React.useMemo(() => {
    return location.pathname.replace(/^\//, '').startsWith(path.replace(/^\//, ''))
  }, [location.pathname, path])
  const isParentActive = React.useMemo(() => {
    return childOf
      ? location.pathname.replace(/^\//, '').startsWith(childOf.replace(/^\//, ''))
      : false
  }, [location.pathname, childOf])
  const isVisible = !childOf || isParentActive
  if (!isVisible) return null
  return (
    <Tooltip title={leftMenuCollapsed ? name : ''} mouseEnterDelay={0} placement={'right'}>
      <LinkContainer
        {...rest}
        $isSub={!!childOf}
        to={path}
        as={onClick ? (Reset.Button as any) : undefined}
        className={[isActive ? 'active' : highlighted ? 'highlighted' : '', 'left-button'].join(
          ' ',
        )}
        onClick={(e: any) => {
          if (onClick) {
            e.preventDefault()
            onClick()
          }
        }}
      >
        <span className={'icon'}>{childOf && !leftMenuCollapsed ? null : icon}</span>
        <span>{name}</span>
        {rightContent ? (
          <div
            className={`right-content ${rightContentDanger ? 'danger' : ''}`}
            onClick={
              stopRightContentClick
                ? e => {
                    e.stopPropagation()
                    e.preventDefault()
                  }
                : undefined
            }
            children={rightContent}
          />
        ) : null}
      </LinkContainer>
    </Tooltip>
  )
}

const LinkContainer = styled(Link)<{ $isSub?: boolean }>`
  padding: 0 ${s.size('s')};
  display: flex;
  width: 100%;
  align-items: center;
  transition:
    color 0.1s linear,
    background 0.1s linear;
  color: ${s.color('secondary')};
  background: transparent;
  border-radius: ${s.var('radii.1')};
  font-weight: 500;
  line-height: 1;
  height: ${props => (props.$isSub ? 30 : 42)}px;
  font-size: ${props => s.size(props.$isSub ? 'font.s' : 'font.m')};
  .icon {
    margin-right: ${s.size('s')};
    font-size: ${props => s.size(props.$isSub ? 'font.m' : 'font.l')};
    margin-left: ${props =>
      props.$isSub ? `calc((${s.size('font.l')} - ${s.size('font.m')}) / 2)` : 0};
    width: ${s.size('font.l')};
    text-align: center;
  }
  &.highlighted {
    color: ${s.color('primary')};
    background: ${s.color('primary.element')};
  }
  &:hover {
    background: ${s.color('hoverElement')};
    color: ${s.color('textEmphasis')};
  }
  &.active {
    color: ${s.color('primary')};
    background: ${props => (props.$isSub ? null : s.color('hoverElement'))};
  }
  .right-content {
    margin-left: auto;
    background: ${s.color('border')};
    display: inline-block;
    padding: ${s.size('xxs')} ${s.size('xs')};
    border-radius: ${s.var('radii.1')};
    * {
      color: ${s.color('secondary')};
      font-weight: 500;
      line-height: 1;
      text-align: center;
    }
    &.danger {
      background: ${s.color('red')};
      * {
        color: white;
      }
    }
  }
`

export interface LeftMenuDividerItemProps {}
export function LeftMenuDividerItem(props: LeftMenuDividerItemProps) {
  return <_LeftMenuDividerItem {...props} className={'left-divider'} />
}
const _LeftMenuDividerItem = styled.div<LeftMenuDividerItemProps>`
  height: 1px;
  width: 100%;
  background: ${s.color('border')};
  margin: ${s.size('s')} 0 ${s.size('xs')} 0;
`

export interface LeftMenuHeaderItemProps {
  name: string
}
export function LeftMenuHeaderItem({ name }: LeftMenuHeaderItemProps) {
  return (
    <HeaderContainer className={'left-header'} color={'@text-color-secondary'}>
      {name}
    </HeaderContainer>
  )
}
const HeaderContainer = styled(CapitalSmall)`
  margin-left: ${s.size('s')};
  margin-top: ${s.size('m')};
  margin-bottom: ${s.size('xs')} !important;
  font-size: ${s.size('s')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
