import React from 'react'

export function useDebounce<T>(input: T, debounceMs = 500): [T] {
  const [debounced, setDebounced] = React.useState(input)
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(input)
    }, debounceMs)
    return () => clearTimeout(timeout)
  }, [input])

  return [debounced]
}

export function useStateAndDebounce<T>(
  defaultValue: T,
  debounceMs = 500,
): [T, T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState(defaultValue)
  const [debounced, setDebounced] = React.useState(defaultValue)
  React.useEffect(() => {
    if (state !== debounced) {
      const timeout = setTimeout(() => {
        setDebounced(state)
      }, debounceMs)
      return () => clearTimeout(timeout)
    }
  }, [state])

  return [debounced, state, setState]
}
