import React from 'react'

import { Button } from './ant/Button'
import { styledContainer } from './styled'
import { Block } from './styled/Block'
import { Blur } from './styled/Blur'
import { getSize, useTheme } from './theme'
import { BodyLarge, H2 } from './typography'
import { useOpenSettings } from './useOpenSettings'
import { HelpCircle, WarningCircle, ShieldAlert } from '../icons'

export enum PermissionMode {
  Authorized = 'authorized',
  Unavailable = 'unavailable',
  NotDetermined = 'not-determined',
  Denied = 'denied',
  Restricted = 'restricted',
}

export function mergePermissions(...permissions: PermissionMode[]) {
  return permissions.reduce((acc, permission) => {
    return permission !== PermissionMode.Authorized ? permission : acc
  }, PermissionMode.Authorized)
}

export function getPermissionIcon(mode: PermissionMode) {
  switch (mode) {
    case PermissionMode.Restricted:
      return <ShieldAlert />
    case PermissionMode.Unavailable:
      return <WarningCircle />
    default:
      return <HelpCircle />
  }
}
export function getPermissionMessage(mode: PermissionMode, requesting: string) {
  switch (mode) {
    case PermissionMode.Authorized:
      return {
        title: '',
        description: '',
      }
    case PermissionMode.NotDetermined:
      return {
        title: 'Allow Access',
        description: `We need access to your ${requesting} to continue.`,
      }
    case PermissionMode.Denied:
      return {
        title: 'Allow Access',
        description: `Open Settings to allow us access to your ${requesting}.`,
      }
    case PermissionMode.Restricted:
      return {
        title: "Can't Allow Access",
        description: `We need access to your ${requesting}, but parental controls on your device are preventing this.`,
      }
    case PermissionMode.Unavailable:
      return {
        title: 'Device not Available',
        description: `We need access to your ${requesting}, but it appears one of those is not available.`,
      }
  }
}

export interface PermissionOverlayProps {
  permissionMode: PermissionMode
  onRequest: () => void
  /** What are you requesting access to? */
  requesting: string
  hideBlur?: boolean
}
export function PermissionOverlay({
  permissionMode,
  onRequest,
  requesting,
  hideBlur,
}: PermissionOverlayProps) {
  const openSettings = useOpenSettings()
  const [loading, setLoading] = React.useState(false)
  const theme = useTheme()
  if (permissionMode !== PermissionMode.Authorized) {
    const canRequest = permissionMode === PermissionMode.NotDetermined
    const canOpenSettings = permissionMode === PermissionMode.Denied
    const icon = getPermissionIcon(permissionMode)
    const { title, description } = getPermissionMessage(permissionMode, requesting)
    return (
      <>
        {hideBlur ? null : <Blur forceDark />}
        <Container>
          {React.cloneElement(icon, {
            width: getSize(theme, '@size-xl'),
            height: getSize(theme, '@size-xl'),
            color: 'white',
          })}
          <Block marginTop={'@size-m'} marginBottom={'@size-xs'}>
            <H2 style={{ color: 'white', textAlign: 'center' }} isBlock={false}>
              {title}
            </H2>
          </Block>
          <BodyLarge children={description} style={{ color: 'white', textAlign: 'center' }} />
          {canOpenSettings ? (
            <Block marginTop={'@size-s'}>
              <Button type={'link'} onClick={openSettings} children={'Open Settings'} />
            </Block>
          ) : canRequest ? (
            <Block marginTop={'@size-s'}>
              <Button
                loading={loading}
                type={'link'}
                onClick={async () => {
                  setLoading(true)
                  try {
                    await onRequest()
                  } finally {
                    setLoading(false)
                  }
                }}
                children={'Grant Access and Continue'}
              />
            </Block>
          ) : null}
        </Container>
      </>
    )
  } else return null
}
const Container = styledContainer`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
