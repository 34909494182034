import { CloseOutlined } from '@ant-design/icons'
import { toGlobalId } from '@thesisedu/feature-utils'
import { ScrollableContainerProvider } from '@thesisedu/react'
import { confirmClose } from '@thesisedu/react-confirm-close'
import { AntIconWrapper, Body, BodySmall, HSpaced, Key, PageHead } from '@thesisedu/web'
import { Drawer } from 'antd'
import { DrawerProps } from 'antd/es/drawer'
import { MultiWindow } from 'iconoir-react'
import React from 'react'

import { EditableSegmentContent, EditableSegmentContentProps } from './EditableSegmentContent'

export interface EditableSegmentDrawerProps extends EditableSegmentContentProps {
  onClose: () => void
  visible?: boolean
  drawerProps?: Partial<DrawerProps>
}

function InnerEditableSegmentDrawer({
  onClose,
  visible,
  drawerProps,
  ...contentProps
}: EditableSegmentDrawerProps) {
  const contentContainerRef = React.useRef<HTMLDivElement>(null)
  const parentRef = React.useRef<HTMLDivElement | null>(null)
  React.useEffect(() => {
    parentRef.current = (contentContainerRef.current?.parentElement as HTMLDivElement) || null
  }, [visible])
  return (
    <Drawer
      title={'Edit Segment'}
      width={400}
      keyboard={false}
      destroyOnClose
      {...drawerProps}
      onClose={onClose}
      closeIcon={
        <HSpaced space={'@size-l'}>
          <a
            href={`${window.location.pathname}/${toGlobalId('Segment', contentProps.segment.id)}`}
            target={'_blank'}
            rel={'noopener noreferrer'}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <div style={{ fontWeight: 400, textAlign: 'right' }}>
              <Body>
                Open in Window <AntIconWrapper children={<MultiWindow />} />
              </Body>
              <BodySmall color={'@text-color-secondary'}>
                <Key isCtrl /> + Click
              </BodySmall>
            </div>
          </a>
          <CloseOutlined />
        </HSpaced>
      }
      visible={visible}
    >
      {visible ? <PageHead title={contentProps.segment.name} /> : null}
      <div ref={contentContainerRef}>
        <ScrollableContainerProvider targetRef={parentRef}>
          <EditableSegmentContent
            {...contentProps}
            onChange={(...args) => {
              if (contentProps.onChange) {
                contentProps.onChange(...args)
              }
              onClose()
            }}
          />
        </ScrollableContainerProvider>
      </div>
    </Drawer>
  )
}

export const EditableSegmentDrawer = confirmClose<EditableSegmentDrawerProps>()(
  InnerEditableSegmentDrawer,
)
