import { UserLeftItemsHook, UserRoutesHook } from '@thesisedu/feature-users-react'
import { Shell, WebRoutesHook } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { Clock } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { TrialsAdminRoutes } from './TrialsAdminRoutes'
import { WelcomePage } from './WelcomePage'
import { TrialsWebFeature } from '../../TrialsWebFeature'

export default function (feature: TrialsWebFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [<Route path={'welcome'} key={'welcome'} element={<WelcomePage />} />, ...routes]
  })
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...routes,
          <Route path={'trials/*'} key={'trials'} element={<TrialsAdminRoutes />} />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'top',
            weight: 6,
            element: (
              <Shell.LeftMenuItem
                name={'Trials'}
                icon={<AntIconWrapper children={<Clock />} />}
                path={'/admin/trials'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
