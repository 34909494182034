import { useTeacherOrStudentSelectedClass } from '@thesisedu/feature-classes-react'
import { useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'

import { ClassFragmentWithPermissions, Segment, SegmentTypeResource } from '../types'

export interface UseSegmentNavigationFooterOpts {
  segment: Segment | undefined
  isPreview?: boolean
}
export function useSegmentNavigationFooter({
  segment,
  isPreview = false,
}: UseSegmentNavigationFooterOpts) {
  const resource = useResource<SegmentTypeResource>('SegmentType', segment?.type ?? 'none')
  const viewer = useViewerContext(false)
  const { cls } = useTeacherOrStudentSelectedClass<ClassFragmentWithPermissions>(false)

  return segment && resource?.getNavigationFooter
    ? resource.getNavigationFooter({
        isPreview,
        segment,
        cls,
        viewer,
      })
    : null
}
