import { useFlag } from '@thesisedu/react'
import React from 'react'

const getFlagName = (classId: string) => `feature-courses-expanded-ids:${classId}`
export function useExpandedParentIds(
  classId?: string,
): [string[], (expandedIds: string[]) => void, { loading: boolean }] {
  const state = React.useState<string[]>([])
  const flag = useFlag<string[]>(getFlagName(classId || 'no-class'), [])
  return classId ? flag : [...state, { loading: false }]
}
