import { styled } from '../styled'

/** @deprecated */
export const SimpleTable = styled.table`
  width: 100%;
  thead {
    background: ${props => props.theme['@gray-1']};
    border-radius: ${props => props.theme['@border-radius-base']};
    th {
      font-size: ${props => props.theme['@font-size-sm']};
      padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
      font-weight: bold;
      text-align: left;
    }
  }
  td {
    padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-xs']};
    font-size: ${props => props.theme['@font-size-base']};
  }
`
export const SimpleTableFooter = styled.tr`
  td {
    border-top: solid 1px ${props => props.theme['@border-color-base']};
  }
`
