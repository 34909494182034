import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Block } from '@thesisedu/ui'
import React from 'react'

import { MetronomeWidgetConfig } from '.'
import { BlockMetronome } from '../../../metronome/BlockMetronome'

export function MetronomeElement({ id }: SimpleWidget.SimpleWidgetProps<MetronomeWidgetConfig>) {
  return (
    <Block top={'s'} bottom={'s'} key={`metronome-${id}`}>
      <BlockMetronome />
    </Block>
  )
}
