import { Button, Modal, Modal$ } from '@thesisedu/ui'
import React from 'react'

import { TeacherSelector } from './TeacherSelector'
import { TeacherSelectorModalProps } from './types'

export function TeacherSelectorModal({
  visible,
  onVisibleChange,
  onSelected,
  title = 'Select a Teacher',
  submit = 'Save',
  selectedTeachers: _selectedTeachers,
  trigger,
  ...props
}: TeacherSelectorModalProps & Pick<Modal$.ModalProps, 'trigger'>) {
  const [loading, setLoading] = React.useState(false)
  const [selectedTeachers, setSelectedTeachers] = React.useState(_selectedTeachers || [])
  React.useEffect(() => {
    setSelectedTeachers(_selectedTeachers || [])
  }, [_selectedTeachers, visible])
  return (
    <Modal title={title} onVisibleChange={onVisibleChange} visible={visible} trigger={trigger}>
      <TeacherSelector
        {...props}
        selectedTeachers={selectedTeachers}
        onSelected={setSelectedTeachers}
      />
      <Modal.Footer>
        <Modal.CloseButton />
        <Button
          variant={'primary'}
          loading={loading}
          onPress={async () => {
            setLoading(true)
            try {
              await onSelected(selectedTeachers)
            } finally {
              setLoading(false)
            }
          }}
          children={submit}
        />
      </Modal.Footer>
    </Modal>
  )
}
