import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'
import { Block } from '@thesisedu/react'
import { Check } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { CategoryManagement } from '../../class/CategoryManagement'

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<ClassConfigurationFieldResource>({
    type: 'ClassConfigurationField',
    identifier: 'gradingCategories',
    group: 'Assignments & Grading',
    groupIcon: <Check />,
    weight: -10,
    renderField({ classId }) {
      if (classId) {
        return (
          <Block marginBottom={'@size-l'}>
            <CategoryManagement classId={classId} />
          </Block>
        )
      } else return null
    },
  })
}
