import { FileField } from '@thesisedu/feature-attachments-react'
import { Button, Checkbox, Drawer, Form } from 'antd'
import React from 'react'

import { BackgroundOptions } from './types'

export interface BackgroundOptionsDrawerProps {
  visible?: boolean
  onClose?: () => void
  value?: BackgroundOptions
  onChange?: (value: BackgroundOptions | undefined) => void
}
export function BackgroundOptionsDrawer({
  visible,
  onClose,
  value,
  onChange,
}: BackgroundOptionsDrawerProps) {
  const [form] = Form.useForm<BackgroundOptions>()
  React.useEffect(() => {
    form.setFieldsValue(value || {})
  }, [visible])
  return (
    <Drawer
      visible={visible}
      onClose={() => {
        form.submit()
      }}
      width={350}
      title={'Background Settings'}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          if (onChange) {
            onChange(values.path ? { ...value, ...values } : undefined)
          }
          if (onClose) {
            onClose()
          }
        }}
      >
        <Form.Item name={'path'} label={'Background Image'}>
          <FileField
            uploadProps={{
              accept: 'image/*',
              isPublic: true,
              maxFileSizeBytes: 2e6, // 2MB
              uploadMessage: 'Attach a new background image here.',
            }}
          />
        </Form.Item>
        <Form.Item name={'noGrayscale'} label={'No Grayscale'} valuePropName={'checked'}>
          <Checkbox>Always show background in color.</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} onClick={form.submit}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
