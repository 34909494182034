import { getSegmentName } from '@thesisedu/feature-course-types'
import {
  MinimalSegmentFragment,
  MinimalSegmentFragmentDoc,
  SegmentType,
} from '@thesisedu/feature-courses-react/dist/schema'
import {
  ReportDimensionResource,
  AllMetricSummariesFragment,
  ReportResultItemFragment,
  ReportDimensionSummaryRow,
  ReportDimensionContext,
  inferGraphContext,
} from '@thesisedu/feature-reports-react'
import { BookmarkBook } from '@thesisedu/react/icons'
import React from 'react'

import { SegmentHeader, useGetSegmentHeader } from './components/SegmentHeader'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import {
  SegmentReportDimensionInput,
  SegmentReportDimensionResultFragment,
  SegmentReportDimensionResultFragmentDoc,
} from '../../schema'

export interface ReportSegmentSummaryItem {
  segment: MinimalSegmentFragment
  summary: AllMetricSummariesFragment
  items: ReportResultItemFragment[]
  children?: ReportSegmentSummaryItem[]
}

function itemsToReportItems(items: ReportSegmentSummaryItem[]): ReportDimensionSummaryRow[] {
  return items.reduce<ReportDimensionSummaryRow[]>((acc, item) => {
    return [
      ...acc,
      {
        id: item.segment.id,
        results: item.items.map(resultItem => ({
          ...resultItem,
          dimensions: {
            ...resultItem.dimensions,
            segment:
              item.segment.type === SegmentType.Section ||
              item.segment.type === SegmentType.Assignment
                ? {
                    segment: {
                      id: item.segment.id,
                    },
                  }
                : resultItem.dimensions.segment,
          },
        })),
        summaries: item.summary,
        item,
        children: item.children ? itemsToReportItems(item.children) : undefined,
      },
    ]
  }, [])
}

export const segmentGraphOptions = inferGraphContext({
  useContext: () => {
    return {}
  },
  useHorizontalGraph: true,
  label: (
    item: SegmentReportDimensionResultFragment,
    context,
    summaryItem?: ReportSegmentSummaryItem,
  ) => {
    if (summaryItem) {
      return getSegmentName(summaryItem.segment)
    } else return null
  },
})

export default function addDimension(feature: CourseReportsReactFeature) {
  feature.resources.addResource<
    ReportDimensionResource<
      SegmentReportDimensionInput,
      SegmentReportDimensionResultFragment,
      ReportDimensionContext,
      ReportSegmentSummaryItem
    >
  >({
    type: 'ReportDimension',
    identifier: 'Lessons',
    inputKey: 'segment',
    title: 'Lessons',
    singular: 'Lesson',
    description: `Compare SHORTDESC based on each ${feature.root.appOptions.name} lesson.`,
    icon: <BookmarkBook />,
    hasMultipleValues: true,
    supportedIndices: [1],
    fragment: SegmentReportDimensionResultFragmentDoc,
    graph: segmentGraphOptions,
    summary: {
      summariesKey: 'segments',
      getRows(data) {
        const summary = data.summaries?.segments?.segments
        return itemsToReportItems(summary || [])
      },
      queryPieceFragments: [MinimalSegmentFragmentDoc],
      renderTableHeader: SegmentHeader,
      useGetExportHeader: useGetSegmentHeader,
      getQueryPiece(reportDimensionResult, reportMetricSummaries, allMetricSummaries) {
        const ReportSegmentSummaryItem = `
          segment {
            ...MinimalSegment
          }
          summary {
            ${allMetricSummaries}
          }
          items {
            dimensions {
              ${reportDimensionResult}
            }
            metrics {
              ${reportMetricSummaries}
            }
          }
        `
        return `
          segments {
            ${ReportSegmentSummaryItem}
            children {
              ${ReportSegmentSummaryItem}
              children {
                ${ReportSegmentSummaryItem}
                children {
                  ${ReportSegmentSummaryItem}
                  children {
                    ${ReportSegmentSummaryItem}
                    children {
                      ${ReportSegmentSummaryItem}
                      children {
                        ${ReportSegmentSummaryItem}
                      }
                    }
                  }
                }
              }
            }
          }
        `
      },
    },
    shortLabel: () => 'Lesson',
  })
}
