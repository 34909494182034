import { AuthLoadingScreen } from '@thesisedu/feature-users-web'
import { WebRoutesHook } from '@thesisedu/feature-web'
import { Error403 } from '@thesisedu/feature-web/dist/Error403'
import React from 'react'
import { Route } from 'react-router-dom'

import { Admin } from './Admin'
import { UsersReactFeature } from '../UsersReactFeature'
import { AuthRoutes } from '../web/routes/AuthRoutes'

export default function (feature: UsersReactFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [
      <Route path={'auth/*'} key={'auth'} element={<AuthRoutes />} />,
      <Route path={'admin/*'} key={'admin'} element={<Admin />} />,
      <Route
        path={'403'}
        key={'403'}
        element={<Error403 onBack={() => window.history.back()} />}
      />,
      <Route key={'root'} path={'/'} element={<AuthLoadingScreen />} />,
      ...routes,
    ]
  })
}
