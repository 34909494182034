import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import {
  Empty,
  List,
  LoadingIndicator,
  useStateAndDebounce,
  BackgroundHoverListItem,
} from '@thesisedu/react'
import { Text, TextField, styled, Grid, s } from '@thesisedu/ui'
import { NavArrowRight, Search } from '@thesisedu/ui/icons'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  SavedReportFragment,
  SavedReportsQuery,
  SavedReportsDocument,
  SavedReportsQueryVariables,
} from '../schema'

export interface SavedReportsListProps {
  reportLink: (savedReport: SavedReportFragment) => string
}
export function SavedReportsList({ reportLink }: SavedReportsListProps) {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null)
  const [dName, name, setName] = useStateAndDebounce('')

  return (
    <>
      <Header>
        <TextField
          value={name}
          aria-label={'Find a saved report'}
          onChange={setName}
          suffix={<Search />}
          placeholder={'Find a Saved Report...'}
          style={{ width: 300, maxWidth: '100%' }}
        />
      </Header>
      <ScrollContainer ref={scrollContainerRef}>
        <InfiniteQuery<SavedReportFragment, SavedReportsQuery, SavedReportsQueryVariables>
          document={SavedReportsDocument}
          resultPath={'viewer.savedReports'}
          variables={{
            name: dName,
          }}
          queryOpts={{
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
          }}
          infiniteScrollerProps={{
            scrollableTarget: () => scrollContainerRef.current,
          }}
          children={({ data, loadingFresh }) => {
            const savedReports = data?.viewer?.savedReports.edges.map(edge => edge.node) || []
            if (loadingFresh) {
              return <LoadingIndicator block />
            } else if (savedReports.length) {
              return (
                <Grid>
                  <List>
                    {savedReports.map(savedReport => (
                      <Link to={reportLink(savedReport)} key={savedReport.id}>
                        <BackgroundHoverListItem
                          rightContent={
                            <Text level={'s'} color={'secondary'}>
                              <NavArrowRight />
                            </Text>
                          }
                        >
                          <Text>{savedReport.name}</Text>
                        </BackgroundHoverListItem>
                      </Link>
                    ))}
                  </List>
                </Grid>
              )
            } else {
              return <Empty description={'No saved reports found!'} />
            }
          }}
        />
      </ScrollContainer>
    </>
  )
}

const Header = styled.div`
  margin-bottom: ${s.size('s')};
  display: flex;
  justify-content: flex-end;
`
export const ScrollContainer = styled.div`
  padding-top: ${s.size('s')};
  flex: 1;
  overflow-y: auto;
  max-height: 400px;
`
