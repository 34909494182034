import { Format } from '@thesisedu/feature-utils'
import { HSpaced, Table, Text, Tooltip } from '@thesisedu/ui'
import React from 'react'

import { DeleteCategoryButton } from './DeleteCategoryButton'
import { EditCategoryButton } from './EditCategoryButton'
import { BasicAssignmentCategoryFragment } from '../schema'

export interface CategoryManagementRowProps {
  category: BasicAssignmentCategoryFragment
  readOnly?: boolean
  totalPoints: number
}
export function CategoryManagementRow({
  category,
  readOnly,
  totalPoints,
}: CategoryManagementRowProps) {
  const gradeWeight = category.gradeWeight * 100
  return (
    <Table.Row id={category.id} data-testid={`CategoryManagementRow ${category.name}`}>
      <Table.Cell>
        <Text>{category.name}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text>{Format.plural('point', gradeWeight)}</Text>
        <Text level={'s'} color={'secondary'}>
          {Format.number(gradeWeight / totalPoints, 'percentage')} of total
        </Text>
      </Table.Cell>
      <Table.Cell visibleOnHover={!readOnly}>
        {readOnly ? null : (
          <HSpaced space={'xxs'}>
            <Tooltip title={'Edit'}>
              <EditCategoryButton category={category} />
            </Tooltip>
            <Tooltip title={'Delete'}>
              <DeleteCategoryButton
                classId={category.classId}
                assignmentCategoryId={category.id}
                variant={'ghost'}
                status={'danger'}
                aria-label={'Delete'}
              />
            </Tooltip>
          </HSpaced>
        )}
      </Table.Cell>
    </Table.Row>
  )
}
