import { OrderedListOutlined } from '@ant-design/icons'
import { OptionsConfig } from '@thesisedu/feature-assignments-core'

import { CheckboxSelectAnswer } from './CheckboxSelectAnswer'
import { CheckboxSelectContent } from './CheckboxSelectContent'
import { CheckboxSelectEdit } from './CheckboxSelectEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  identifier: 'CheckboxSelect',
  defaults: {
    name: 'Question',
    config: {
      options: [{ id: 'option-one', name: 'Option 1', correct: false }],
      requireExpansion: false,
      excludeFromQuizVault: false,
      isRecommended: false,
      explanation: null,
    },
  },
  icon: OrderedListOutlined,
  label: 'Checkbox Select',
  group: 'Multiple Choice',
  type: 'QuestionType',
  renderAnswerView: CheckboxSelectAnswer,
  renderContent: CheckboxSelectContent,
  renderEdit: CheckboxSelectEdit,
  isAutoGraded: question => {
    return !!question.config?.options?.some(opt => opt.correct !== undefined)
  },
  usesRealTimeConfirmation: true,
} as QuestionTypeResource<OptionsConfig>
