import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { IndividualCourseViewerModal } from './IndividualCourseViewerModal'
import { useCourseViewerModalContext } from '../contexts/CourseViewerModalContext'
import { OutlineSegmentContextProvider as TeacherOutlineSegmentContextProvider } from '../outline/OutlineSegmentContext'

export const useViewingSegmentId = (): [string | null, (value: any) => void] => {
  const { segmentId, setSegmentId } = useCourseViewerModalContext(true)
  return [segmentId || null, setSegmentId]
}

export function CourseViewerModal() {
  const viewer = useViewerContext(false)
  const children = <IndividualCourseViewerModal />
  return viewer?.group === 'TEACHER' ? (
    <TeacherOutlineSegmentContextProvider children={children} />
  ) : (
    children
  )
}
