import { HSpaced, VSpaced } from '@thesisedu/ui'
import { PageHead } from '@thesisedu/web'
import { orderBy } from 'lodash'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { BasicNavLink } from './BasicNavigationItem'

export interface GroupedNavigationGroup {
  identifier: string
  icon?: React.ReactElement
  title: string
  component: React.ComponentType<any>
  weight?: number
}
export interface GroupedNavigationProps {
  groups: GroupedNavigationGroup[]
  title?: string
}
export function GroupedNavigation({ groups, title }: GroupedNavigationProps) {
  const sortedGroups = orderBy(groups, ['weight'], ['asc'])
  const firstItem = sortedGroups[0]?.identifier

  return (
    <HSpaced align={'flex-start'} space={'l'}>
      <VSpaced align={'stretch'} space={'xs'} style={{ width: 230 }}>
        {sortedGroups.map(group => {
          return (
            <BasicNavLink
              key={group.identifier}
              to={group.identifier}
              icon={group.icon}
              children={group.title}
            />
          )
        })}
      </VSpaced>
      <div style={{ flex: 1 }}>
        <Routes>
          {firstItem ? <Route path={'/'} element={<Navigate to={firstItem} replace />} /> : null}
          {sortedGroups.map(group => {
            const Comp = group.component
            return (
              <Route
                key={group.identifier}
                path={`${group.identifier}/*`}
                element={
                  <>
                    <PageHead title={[group.title, title].filter(Boolean).join(' - ')} />
                    <Comp />
                  </>
                }
              />
            )
          })}
        </Routes>
      </div>
    </HSpaced>
  )
}
