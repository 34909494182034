import React from 'react'

export interface DropdownContextValue {
  forceMount?: boolean
  setForceMount: (forceMount: boolean) => void
}
export const DropdownContext = React.createContext<DropdownContextValue | undefined>(undefined)

export function useDropdownContext(): DropdownContextValue | undefined
export function useDropdownContext(require: false): DropdownContextValue | undefined
export function useDropdownContext(require: true): DropdownContextValue
export function useDropdownContext(require?: boolean): DropdownContextValue | undefined {
  const context = React.useContext(DropdownContext)
  if (!context && require) {
    throw new Error('DropdownContext is required, yet not provided.')
  }
  return context
}
