import { StudentContextHook } from '@thesisedu/feature-students-react'
import React from 'react'

import { ClassContextProvider } from '../ClassContext'
import { ClassSelectorModalContextProvider } from '../ClassSelectorModal'
import ClassesReactFeature from '../ClassesReactFeature'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<StudentContextHook>(
    'students-react:student-context',
    children => {
      return (
        <ClassContextProvider>
          <ClassSelectorModalContextProvider>{children}</ClassSelectorModalContextProvider>
        </ClassContextProvider>
      )
    },
  )
}
