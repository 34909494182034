import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useReportsContext } from '@thesisedu/feature-reports-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Dropdown, Toast, useToast } from '@thesisedu/ui'
import { Download, CloudUpload, NavArrowDown } from '@thesisedu/ui/icons'
import csvtojson from 'csvtojson'
import React from 'react'

import {
  CourseViewWeightInput,
  useImportCourseViewWeightsMutation,
  usePrepareCourseViewSampleSpreadsheetMutation,
} from '../schema'

export interface ImportButtonProps {
  courseViewId: string
}
export function ImportButton({ courseViewId }: ImportButtonProps) {
  const [importWeights, { loading: importLoading }] = useImportCourseViewWeightsMutation({
    onError: onMutationError('There was an error importing your weights.'),
  })
  const toast = useToast()
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { trackReport } = useReportsContext(true)
  const [prepareSample, { loading: prepareLoading }] =
    usePrepareCourseViewSampleSpreadsheetMutation({
      onError: onMutationError('There was an error preparing your spreadsheet.'),
      onCompleted(data) {
        const reportJob = data?.prepareCourseViewSampleSpreadsheet.reportJob
        if (reportJob) {
          trackReport(reportJob.id, 'sample', reportJob.downloadUrl)
        }
      },
    })

  return (
    <>
      <input
        ref={inputRef}
        type={'file'}
        hidden
        onChange={e => {
          const file = e.target.files?.[0]
          if (file) {
            const reader = new FileReader()
            reader.onload = async () => {
              if (inputRef.current) {
                inputRef.current.value = ''
              }
              const csv = reader.result as string
              // Convert the CSV to JSON.
              const json = await csvtojson().fromString(csv)
              // Import the weights.
              const validWeights = json
                .map(({ segmentId, weight }) => {
                  const floatWeight = parseFloat(weight)
                  if (floatWeight != null && !isNaN(floatWeight) && segmentId) {
                    return {
                      segmentId: toGlobalId('Segment', segmentId),
                      weight: floatWeight,
                    }
                  } else return null
                })
                .filter(Boolean)
              if (validWeights.length) {
                importWeights({
                  variables: {
                    input: {
                      courseViewId,
                      weights: validWeights as CourseViewWeightInput[],
                    },
                  },
                })
              } else {
                toast({
                  title: 'No valid weights found.',
                  description:
                    'Please check your CSV file and try again. It should have a "segmentId" column and a "weight" column, at least.',
                  status: 'error',
                })
              }
            }
            reader.readAsText(file)
          }
        }}
      />
      {importLoading ? <Toast loading title={'Starting import job...'} /> : null}
      {prepareLoading ? <Toast loading title={'Starting sample job...'} /> : null}
      <Dropdown.Container>
        <Dropdown.Button icon={<CloudUpload />}>
          Import Weights <NavArrowDown />
        </Dropdown.Button>
        <Dropdown.Menu align={'end'}>
          <Dropdown.Item
            children={'Download Template'}
            icon={<Download />}
            onClick={() => {
              prepareSample({
                variables: {
                  input: { courseViewId },
                },
              })
            }}
          />
          <Dropdown.Item
            children={'Import Weights'}
            icon={<CloudUpload />}
            onClick={() => {
              inputRef.current?.click()
            }}
          />
        </Dropdown.Menu>
      </Dropdown.Container>
    </>
  )
}
