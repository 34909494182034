import React from 'react'

import { styledContainer } from './styled'
import { getColor } from './theme'
import { Cancel } from '../icons'

export interface AntiIconProps {
  children: React.ReactElement
  color?: string
  icon?: React.ReactElement
}
/** @deprecated */
export function AntiIcon({ children, color, icon }: AntiIconProps) {
  const iconProps = {
    width: '90%',
    height: '90%',
    color: 'white',
  }
  return (
    <Container>
      {children}
      <IconContainer color={color}>
        {icon ? React.cloneElement(icon, iconProps) : <Cancel {...iconProps} />}
      </IconContainer>
    </Container>
  )
}

const Container = styledContainer`
  position: relative;
`
const IconContainer = styledContainer<{ color?: string }>`
  background: ${props => getColor(props.theme, props.color || '@red')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10%;
  right: -10%;
  width: 50%;
  height: auto;
  aspect-ratio: 1;
  border-radius: 200px;
`
