import React from 'react'

export interface KeyboardContextValue {
  playKey: (key: string) => void
}
export const KeyboardContext = React.createContext<KeyboardContextValue | undefined>(undefined)

export function useKeyboardContext(): KeyboardContextValue | undefined
export function useKeyboardContext(require: false): KeyboardContextValue | undefined
export function useKeyboardContext(require: true): KeyboardContextValue
export function useKeyboardContext(require?: boolean): KeyboardContextValue | undefined {
  const context = React.useContext(KeyboardContext)
  if (!context && require) {
    throw new Error('KeyboardContext is required, yet not provided.')
  }
  return context
}
