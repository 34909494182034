import {
  isVisibleToTeacher,
  isVisibleToStudent,
  getSegmentParents as _getSegmentParents,
  SegmentMetadata,
  SegmentMetadataSegment,
} from '@thesisedu/feature-course-types'
import { fromGlobalId } from '@thesisedu/feature-utils'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { LABEL_ICONS } from './constants'
import { GroupSegment, Segment } from './types'

export interface SegmentLinkOptions {
  id: string
  hash?: string
}

export const getSegmentLinkPath = (
  { id, hash }: SegmentLinkOptions,
  location: ReturnType<typeof useLocation>,
) => {
  const params = new URLSearchParams(location.search.slice(1))
  params.append('viewSegment', id)
  return `?${params.toString()}${hash ? `#${hash}` : ''}`
}

export const overrideSegment = (
  segmentId: string,
  overrides: Omit<SegmentMetadataSegment, 'id'>,
  segmentMetadata?: SegmentMetadata,
): SegmentMetadata => {
  const existing = (segmentMetadata || {}).segments || []
  const existingOverride = existing.find(override => override.id === segmentId)
  if (existingOverride) {
    return {
      ...segmentMetadata,
      segments: [
        ...existing.filter(override => override.id !== segmentId),
        {
          ...existingOverride,
          ...overrides,
        },
      ],
    }
  } else {
    return {
      ...segmentMetadata,
      segments: [...existing, { id: segmentId, ...overrides }],
    }
  }
}

export const toggleSegments = (
  segmentIds: string[],
  segmentMetadata: SegmentMetadata,
  enable: boolean,
) => {
  let updatedMetadata = segmentMetadata
  for (const segmentId of segmentIds) {
    updatedMetadata = overrideSegment(
      segmentId,
      { visibleOverride: enable, enabledStudentIds: undefined },
      updatedMetadata,
    )
  }

  return updatedMetadata
}

export const getSegmentTags = (segment: Segment) => {
  return segment.tags || segment.tags
}

export const getIconForSegment = (segment: Partial<Pick<Segment, 'config'>>) => {
  const configuredIcon = get(segment, 'config.icon')
  if (configuredIcon) return configuredIcon
  const configuredLabel = get(segment, 'config.label')
  if (configuredLabel && LABEL_ICONS[configuredLabel.trim()])
    return LABEL_ICONS[configuredLabel.trim()]
  return 'book'
}

export const isTeacherInfoSegment = (segment: Segment) => {
  return (
    // @ts-ignore segment.type !== 'Assignment' is invalid
    segment.type !== 'Assignment' && !isVisibleToStudent(segment) && isVisibleToTeacher(segment)
  )
}

interface ParentsCache {
  [segmentId: string]: string[]
}

let parentsCache: ParentsCache = {}
export const getSegmentParents = (
  segments: Segment[],
  segmentId: string,
  withReferences = false,
) => {
  if (!parentsCache[segmentId] || parentsCache[segmentId].length <= 0) {
    parentsCache[segmentId] = _getSegmentParents(segments, segmentId, withReferences)
  }
  if (parentsCache[segmentId]) {
    return [...parentsCache[segmentId]]
  } else return []
}

export const clearParentsCache = () => {
  parentsCache = {}
}

export function getSegmentIds(segments: (Segment | GroupSegment)[]): string[] {
  let ids: string[] = []
  for (const segment of segments.filter(Boolean)) {
    ids.push(segment.id)
    if (segment.childSegments?.length) {
      ids = [...ids, ...getSegmentIds(segment.childSegments)]
    }
  }

  return ids
}

export function getSegmentRawId(dbIdOrRawId: string): string {
  const { type, id } = fromGlobalId(dbIdOrRawId) ?? {}
  return type === 'Segment' && id ? id : dbIdOrRawId
}
