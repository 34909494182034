import { Feature } from '@thesisedu/feature'
import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { Checkbox, Form } from 'antd'
import React from 'react'

export const USE_ADVANCED_MODE_FIELD = 'composeUseAdvancedMode'
function ComposeComplexityField({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, USE_ADVANCED_MODE_FIELD]}
      valuePropName={'checked'}
      extra={
        'When this is enabled, students are presented the 25-note option in the Compose Tool interface, versus the 8-note option.'
      }
    >
      <Checkbox>Use the more advanced editing controls inside the Compose tool.</Checkbox>
    </Form.Item>
  )
}

export function addClassConfigurationFields(feature: Feature) {
  feature.resources.addResource<ClassConfigurationFieldResource>({
    type: 'ClassConfigurationField',
    identifier: USE_ADVANCED_MODE_FIELD,
    group: 'Compose',
    renderField: ComposeComplexityField,
  })
}
