import { useMutateHook } from '@thesisedu/feature-react'
import { LoadingIndicator, Block, Button, NotFound, VSpaced, PageHead } from '@thesisedu/react'
import { NavArrowDown } from '@thesisedu/react/icons'
import { useToast } from '@thesisedu/ui'
import { useBreadcrumb, Row, PageHeader } from '@thesisedu/web'
import { Col, Dropdown, Menu } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SiteForm } from '../../SiteForm'
import { useSiteQuery } from '../../schema'
import { SiteActionsHook, SiteContentHook } from '../../types'
import { useEditSiteForm } from '../../useEditSiteForm'

export interface SiteNameProps {
  siteId: string
}
export function SiteName({ siteId }: SiteNameProps) {
  const { data } = useSiteQuery({ variables: { id: siteId } })
  return <span>{data?.node?.__typename === 'Site' ? data.node.name : '...'}</span>
}

export function EditSite() {
  const { siteId } = useParams()
  const { data, loading } = useSiteQuery({ variables: { id: siteId } })
  const toast = useToast()
  useBreadcrumb({ title: <SiteName siteId={siteId} /> })
  const siteFormData = useEditSiteForm(siteId, {
    onCompleted: () => {
      toast({ status: 'success', title: 'Site updated!' })
    },
  })
  const saveData = async () => {
    await Promise.all([siteFormData.submit()])
  }
  const navigate = useNavigate()
  const siteActions = useMutateHook<SiteActionsHook>('sites-web:site-actions', [], { siteId })
  const siteContent = useMutateHook<SiteContentHook>('sites-web:site-content', [], { siteId })
  if (loading) {
    return <LoadingIndicator block />
  } else if (data?.node && data.node.__typename === 'Site') {
    const site = data.node
    return (
      <>
        <PageHead title={'Edit Site'} />
        <Block marginBottom={'@size-l'}>
          <PageHeader title={data.node.name} onBack={() => navigate('/admin/sites')}>
            {siteActions.length ? (
              <Dropdown arrow placement={'bottomRight'} overlay={<Menu>{siteActions}</Menu>}>
                <Button>
                  Other Actions <NavArrowDown />
                </Button>
              </Dropdown>
            ) : null}
            <Button type={'primary'} onClick={saveData} loading={siteFormData.isLoading}>
              Save Changes
            </Button>
          </PageHeader>
        </Block>
        <Row>
          <Col xs={24} lg={12} xl={8}>
            <SiteForm
              form={siteFormData.form}
              onFinish={saveData}
              siteId={siteId}
              districtId={site.district?.id}
            />
          </Col>
          <Col xs={24} lg={12} xl={16}>
            <VSpaced space={'@size-m'} align={'stretch'}>
              {siteContent}
            </VSpaced>
          </Col>
        </Row>
      </>
    )
  } else {
    return <NotFound description={'The site could not be found.'} />
  }
}
