import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ClassFragment } from './schema'
import { ClassRoutesHook } from './types'

export interface ClassRoutesProps {
  cls: ClassFragment
  group: string
}
export function ClassRoutes({ cls, group }: ClassRoutesProps) {
  const routes = useMutateHook<ClassRoutesHook>('classes-react:class-routes', [], { cls, group })
  const firstRoutePath = routes[0]?.props?.path?.replace('/*', '').split('/').find(Boolean)
  const hasRoot = routes.some(route => route.props?.path === '/')
  return (
    <Routes>
      {hasRoot ? null : <Route path={'/'} element={<Navigate to={firstRoutePath} replace />} />}
      {routes}
    </Routes>
  )
}
