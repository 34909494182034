import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { CommentComposeProps } from '@thesisedu/feature-comments-react'
import { MediaPlaybackContext } from '@thesisedu/react'
import { useToast } from '@thesisedu/ui'
import React from 'react'

import { RecordViewModal } from '../record/RecordViewModal'
import { MediaCommentMetadata } from '../types'

export function ComposeMediaComment({
  visible,
  onClose,
  onCreate,
}: CommentComposeProps<MediaCommentMetadata>) {
  const toast = useToast()
  const playbackContext = React.useContext(MediaPlaybackContext)
  const classId = useSelectedClassId()
  React.useEffect(() => {
    if (playbackContext) {
      if (visible) {
        playbackContext.blockPlaying('compose-media-comment')
      } else {
        playbackContext.unblockPlaying('compose-media-comment')
      }
    }
  }, [visible, !!playbackContext])
  return (
    <RecordViewModal
      visible={visible}
      onVisibleChange={v => {
        if (!v) onClose()
      }}
      maxDurationSeconds={60}
      classId={classId || undefined}
      waitForUpload
      onCreated={async result => {
        if (result.media) {
          const close = toast({
            title: 'Creating comment...',
            loading: true,
          })
          try {
            await onCreate({
              comment: 'Comment with media recording.',
              metadata: {
                customType: 'media',
                mediaId: result.media.media.id,
                mediaType: result.media.media.type,
              },
            })
          } finally {
            close()
          }
        }
        onClose()
      }}
      label={'Comment'}
    />
  )
}
