import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateRubricTemplateMutation as useMutation } from '../schema'

export const useCreateRubricTemplateMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that rubric template.'),
    refetchQueries: ['rubricTemplates'],
    ...opts,
  })
