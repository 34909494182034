import { segmentGraphOptions } from '@thesisedu/feature-course-reports-react/dist/resources/ReportDimension/Lessons'
import {
  SegmentReportDimensionResultFragment,
  SegmentReportDimensionResultFragmentDoc,
} from '@thesisedu/feature-course-reports-react/dist/schema'
import {
  AllMetricSummariesFragment,
  ReportDimensionContext,
  ReportDimensionResource,
  ReportDimensionSummaryRow,
  ReportResultItemFragment,
} from '@thesisedu/feature-reports-react'
import { TaskList } from '@thesisedu/react/icons'
import React from 'react'

import { StandardHeader, useGetStandardHeader } from './components/StandardHeader'
import { StandardOptionsLabel, StandardOptionsWrapper } from './components/StandardOptions'
import { StandardSetsReactFeature } from '../../StandardSetsReactFeature'
import { ReportStandardsSummaryInput, SegmentReportDimensionInput } from '../../schema'

declare module '@thesisedu/feature-reports-react' {
  interface ReportSummaries {
    standards?: {
      standards: ReportStandardSummaryItem[]
    }
  }
}

export interface ReportStandardSummaryItem {
  tag: {
    id: string
    name: string
  }
  summary: AllMetricSummariesFragment
  items: ReportResultItemFragment[]
  children?: ReportStandardSummaryItem[]
}

export interface StandardDimensionContext extends ReportDimensionContext {
  standardSetName: string
}

function itemsToReportItems(
  items: ReportStandardSummaryItem[],
  parents: string[] = [],
): ReportDimensionSummaryRow[] {
  return items.reduce<ReportDimensionSummaryRow[]>((acc, item) => {
    return [
      ...acc,
      {
        id: [item.tag.id, ...parents].join('-'),
        results: item.items,
        summaries: item.summary,
        item,
        children: item.children
          ? itemsToReportItems(item.children, [...parents, item.tag.id])
          : undefined,
      },
    ]
  }, [])
}

export default function addDimension(feature: StandardSetsReactFeature) {
  feature.resources.addResource<
    ReportDimensionResource<
      ReportStandardsSummaryInput,
      SegmentReportDimensionResultFragment,
      StandardDimensionContext,
      ReportStandardSummaryItem,
      SegmentReportDimensionInput
    >
  >({
    type: 'ReportDimension',
    identifier: 'Standards',
    inputKey: 'segment',
    title: 'Standards',
    singular: 'Standard',
    description: 'Compare SHORTDESC based on state or national standards.',
    icon: <TaskList />,
    hasMultipleValues: true,
    supportedIndices: [1],
    fragment: SegmentReportDimensionResultFragmentDoc,
    graph: {
      ...segmentGraphOptions,
      label: (
        result: SegmentReportDimensionResultFragment,
        context,
        summaryItem?: ReportStandardSummaryItem,
      ) => {
        return summaryItem?.tag.name || null
      },
    },
    shortLabel: (options, context) => `${context.standardSetName} Standard`,
    getInput: () => ({}),
    renderOptionsWrapper: StandardOptionsWrapper,
    renderOptionsLabel: StandardOptionsLabel,
    summary: {
      summariesKey: 'standards',
      getRows(data) {
        const summary = data.summaries?.standards?.standards
        return itemsToReportItems(summary || [])
      },
      getInput(options, context) {
        return {
          standardSetId: options.standardSetId,
        }
      },
      renderTableHeader: StandardHeader,
      useGetExportHeader: useGetStandardHeader,
      getQueryPiece(reportDimensionResult, reportMetricSummaries, allMetricSummaries) {
        const ReportStandardSummaryItem = `
          tag {
            id
            name
          }
          summary {
            ${allMetricSummaries}
          }
          items {
            dimensions {
              ${reportDimensionResult}
            }
            metrics {
              ${reportMetricSummaries}
            }
          }
        `
        return `
          standards {
            ${ReportStandardSummaryItem}
            children {
              ${ReportStandardSummaryItem}
              children {
                ${ReportStandardSummaryItem}
                children {
                  ${ReportStandardSummaryItem}
                  children {
                    ${ReportStandardSummaryItem}
                    children {
                      ${ReportStandardSummaryItem}
                      children {
                        ${ReportStandardSummaryItem}
                      }
                    }
                  }
                }
              }
            }
          }
        `
      },
    },
  })
}
