import { ImperativeTable, Table$, Text } from '@thesisedu/ui'
import { AddPage, User } from '@thesisedu/ui/icons'
import React from 'react'

import { PresetActions } from './PresetActions'
import { BasicClassGroupPresetFragment, GroupSubmissionType } from '../../schema'

function NameColumn({
  item,
}: Table$.ImperativeTableRenderCellProps<BasicClassGroupPresetFragment>) {
  return (
    <>
      <Text color={'primary'} style={{ cursor: 'pointer' }}>
        {item.name}
      </Text>
      {item.isDefault ? (
        <Text level={'xs'} color={'secondary'} data-testid={'NameColumn DefaultLabel'}>
          Default
        </Text>
      ) : null}
    </>
  )
}

const labels: Record<GroupSubmissionType, string> = {
  [GroupSubmissionType.Group]: 'Group with shared answers',
  [GroupSubmissionType.GroupIndividual]: 'Group with individual answers',
}
function SubmissionTypeColumn({
  item,
}: Table$.ImperativeTableRenderCellProps<BasicClassGroupPresetFragment>) {
  return <Text level={'s'}>{labels[item.submissionType]}</Text>
}

export interface PresetsTableProps {
  presets: BasicClassGroupPresetFragment[]
  onViewPreset: (presetId: string) => void
  classId: string
}
export function PresetsTable({ classId, presets, onViewPreset }: PresetsTableProps) {
  return (
    <ImperativeTable
      aria-label={'Group Presets'}
      columns={[
        { icon: <User />, title: 'Name', id: 'name', isRowHeader: true, renderCell: NameColumn },
        {
          icon: <AddPage />,
          title: 'Submission Mode',
          id: 'submissionType',
          renderCell: SubmissionTypeColumn,
        },
        {
          id: 'actions',
          renderCell: ({ item }) => <PresetActions preset={item} classId={classId} />,
          style: { width: 0 },
        },
      ]}
      items={presets}
      onRowAction={key => onViewPreset(key.toString())}
      emptyMessage={'No presets found for this class.'}
    />
  )
}
