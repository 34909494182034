import { Dropdown } from '@thesisedu/ui'
import { StyleBorder } from '@thesisedu/ui/icons'
import React from 'react'

import { $isImageNode, ImageNode, Radius } from '../../../nodes/Image/ImageNode'
import {
  useSettingsDropdownItem,
  SettingsDropdownElementProps,
} from '../../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../../ui/SettingsDropdown/useNodeState'

const RadiusLabelMap: Record<Radius, string> = {
  '0': 'Extra Small',
  '1': 'Small',
  '2': 'Medium',
  '3': 'Large',
}

export interface RadiusItemProps {
  radius: Radius
  selected: boolean
  onChange: (radius: Radius) => void
}
export function RadiusItem({ radius, selected, onChange }: RadiusItemProps) {
  const label = RadiusLabelMap[radius]
  return (
    <Dropdown.Item.Checkbox
      checked={selected}
      children={label}
      onCheckedChange={checked => {
        if (checked) {
          onChange(radius)
        }
      }}
    />
  )
}

function ImageRadiusElement({ editor, node }: SettingsDropdownElementProps<ImageNode>) {
  const [radius, setRadius] = useNodeState(
    editor,
    node,
    node => node.getRadius(),
    (node, value) => node.setRadius(value),
  )
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<StyleBorder />}>Corner Radius</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        {(Object.keys(RadiusLabelMap) as Radius[]).map(r => {
          return <RadiusItem radius={r} key={r} selected={radius === r} onChange={setRadius} />
        })}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

export function useImageRadiusSetting() {
  useSettingsDropdownItem({
    identifier: 'image-radius',
    group: 'image',
    weight: -8,
    filter(editor, node) {
      return $isImageNode(node)
    },
    element: ImageRadiusElement,
  })
}
