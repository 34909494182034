export * from './DismissablePopover'
export * from './ResetDismissablePopovers'
export * from './NewFeatureDismissablePopover'
import { withSubComponents } from '@thesisedu/ui'

import { DismissablePopover } from './DismissablePopover'
import { NewFeatureDismissablePopover } from './NewFeatureDismissablePopover'
import { ResetDismissablePopoversButton } from './ResetDismissablePopovers'

export default withSubComponents(DismissablePopover, {
  Reset: ResetDismissablePopoversButton,
  NewFeature: NewFeatureDismissablePopover,
})
