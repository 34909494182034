import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import {
  MutateSyncAssignmentRefetchQueries,
  MutateSyncMaterialRefetchQueries,
} from '@thesisedu/feature-class-sync-react'

import { CoursesReactFeature } from '../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<MutateSyncAssignmentRefetchQueries>(
    'class-sync-react:sync-assignment-refetch',
    async queries => {
      return [...queries, 'classDecorations']
    },
  )
  feature.hookManager.registerMutateHook<MutateSyncMaterialRefetchQueries>(
    'class-sync-react:sync-material-refetch',
    async queries => {
      return [...queries, 'classDecorations']
    },
  )
  useModifiedFragment(feature.hookManager, 'Class', fragment => {
    return addField(fragment, 'canSyncSegments')
  })
}
