import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled } from '@thesisedu/web'
import React from 'react'

import { BackgroundOptions } from './types'

export interface BackgroundProps {
  background?: BackgroundOptions
  onLoad?: (current: number, total: number) => void
  onSize?: (width: number, height: number) => void
  isGrayscale?: boolean
}
export function Background({
  isGrayscale: _isGrayscale,
  background,
  onLoad,
  onSize,
}: BackgroundProps) {
  React.useEffect(() => {
    if (onLoad) {
      onLoad(0, background?.path ? 1 : 0)
    }
  }, [background?.path])
  if (background) {
    const isGrayscale = _isGrayscale && !background.noGrayscale
    const { filename, label } = getFileInfo(background.path)
    return (
      <BackgroundImage
        alt={label}
        src={filename}
        grayscale={isGrayscale}
        onLoad={e => {
          if (onLoad) {
            onLoad(1, 1)
          }
          if (onSize && e.currentTarget.naturalWidth && e.currentTarget.naturalHeight) {
            onSize(e.currentTarget.naturalWidth, e.currentTarget.naturalHeight)
          }
        }}
      />
    )
  } else {
    return <BackgroundPlaceholder />
  }
}

const BackgroundImage = styled.img<{ grayscale?: boolean }>`
  width: 100%;
  transition: filter 0.25s linear;
  filter: ${props => (props.grayscale ? 'grayscale(100%)' : 'none')};
`
const BackgroundPlaceholder = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
`
