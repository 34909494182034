import React from 'react'

import { styled, styledContainer } from '.'
import { Button, ButtonProps } from '../ant/Button'
import { color, getColor, size } from '../theme'

/** @deprecated */
export const SelectableButton: React.FC<
  React.PropsWithChildren<ButtonProps & { selected?: boolean }>
> = styled(Button)<{
  selected?: boolean
}>`
  ${props => (props.selected ? `background: ${getColor(props.theme, '@gray-2')} !important;` : '')}
  ${props => (props.selected ? `color: ${getColor(props.theme, '@gray-7')} !important;` : '')}
`

export const SelectableButtonContainer = styledContainer`
  background: ${color('@gray-1')};
  padding: ${size('@size-xxs')};
  border-radius: 200px;
`
