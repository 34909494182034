import { LabeledLoadingIndicator, InlineLabeledLoadingIndicator } from './LabeledLoadingIndicator'
import { LoadingIndicator } from './LoadingIndicator'
import { withSubComponents } from '../..'

export * from './LoadingIndicator'
export * from './LabeledLoadingIndicator'

export default withSubComponents(LoadingIndicator, {
  Labeled: LabeledLoadingIndicator,
  InlineLabeled: InlineLabeledLoadingIndicator,
})
