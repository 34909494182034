import { styled } from '@thesisedu/web'
import React from 'react'

import { getColor, getSize } from '../theme'

/** @deprecated */
export const Skeleton = styled.div<{ fill?: boolean }>`
  border-radius: ${props => getSize(props.theme, '@border-radius-base')};
  background: ${props => getColor(props.theme, '@gray-1')};
  width: ${props => (props.fill ? '100%' : 'auto')};
  height: ${props => (props.fill ? '100%' : 'auto')};
`

const MIN_LENGTH = 10
const MAX_LENGTH = 20
function getRandomChildren() {
  const length = Math.random() * (MAX_LENGTH - MIN_LENGTH) + MIN_LENGTH
  return 'a'.repeat(length)
}

export interface SkeletonTextProps {
  children?: string | React.ReactElement
  [key: string]: any
}
/** @deprecated */
export function SkeletonText({ children: _children, ...rest }: SkeletonTextProps) {
  const randomChildren = React.useRef(getRandomChildren()).current
  const children = _children || randomChildren
  return (
    <SkeletonTextOuter {...rest}>
      <SkeletonTextContainer children={children} />
    </SkeletonTextOuter>
  )
}

const SkeletonTextOuter = styled.div``
const SkeletonTextContainer = styled.span`
  display: inline-block;
  position: relative;
  &::after {
    position: absolute;
    inset: 0;
    display: block;
    content: '';
    border-radius: ${props => getSize(props.theme, '@border-radius-base')};
    background: ${props => getColor(props.theme, '@gray-1')};
  }
`
