import { Button, PageHead } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import { PageHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'

import { BadgeName } from '../../admin/BadgeName'
import { CreateBadgePage } from '../../admin/CreateBadgePage'
import { EditBadgePage } from '../../admin/EditBadgePage'
import { ListBadges } from '../../admin/ListBadges'

export function List() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Badges'} />
      <PageHeader title={'Badges'}>
        <Button type={'primary'} icon={<Plus />} onClick={() => navigate('create')}>
          Create Badge
        </Button>
      </PageHeader>
      <ListBadges />
    </>
  )
}

export function Create() {
  useBreadcrumb({ title: 'Create' })
  return <CreateBadgePage />
}

export function Edit() {
  const { badgeId } = useParams()
  useBreadcrumb({ title: <BadgeName id={badgeId} /> })
  return <EditBadgePage id={badgeId} />
}

export function Badges() {
  useBreadcrumb({ title: 'Badges' })
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
      <Route path={'/create'} element={<Create />} />
      <Route path={'/:badgeId'} element={<Edit />} />
    </Routes>
  )
}
