import {
  DateValue,
  parseAbsoluteToLocal,
  parseTime as coreParseTime,
  parseDate,
  parseDateTime,
  parseZonedDateTime,
  toLocalTimeZone,
} from '@internationalized/date'
import { TimeValue } from '@react-aria/datepicker'

function _parse(value?: string | null): DateValue | undefined {
  if (!value) return undefined
  try {
    return parseAbsoluteToLocal(value)
  } catch {
    try {
      const zoned = parseZonedDateTime(value)
      return toLocalTimeZone(zoned)
    } catch {
      try {
        return parseDateTime(value)
      } catch {
        return parseDate(value)
      }
    }
  }
}

export function parse(value?: string | null): DateValue | undefined {
  try {
    return value ? _parse(value) : undefined
  } catch {
    return value ? _parse(new Date(value).toISOString()) : undefined
  }
}

export function parseTime(value?: string | null): TimeValue | undefined {
  return value ? coreParseTime(value) : undefined
}
