import { createHeadlessEditor } from '@lexical/headless'
import { $getRoot } from 'lexical'

import { WidgetConverter } from './types'
import { nodesFromHtml } from './util/dom'
import { markdownToHtml } from './util/html'
import * as Legacy from '..'
import { CORE_NODES } from '../../editorNodes'
import { debug } from '../../log'
import * as Nodes from '../../nodes'

const MARK_NAME_MAP: Record<string, string> = {
  'marker-green': 'bg-green',
  'pen-green': 'fg-green',
  // This one is different from the rest because we use it for definitions.
  'marker-orange': 'definition',
  'pen-orange': 'fg-orange',
  'marker-red': 'bg-red',
  'pen-red': 'fg-red',
  'marker-blue': 'bg-blue',
  'pen-blue': 'fg-blue',
}

function correctNode(node: Nodes.Node): Nodes.Node {
  // Re-calculate all of the values of the list's children, recursively.
  if (Nodes.is(node, 'list')) {
    for (let i = 0; i < node.children.length; i++) {
      const previous = node.children[i - 1]
      const child = node.children[i]
      if (Nodes.is(child, 'listitem')) {
        delete child.checked
        if (Nodes.is(previous, 'listitem')) {
          const previousIsList = Nodes.is(previous.children[0], 'list')
          if (previousIsList) {
            child.value = previous.value
          } else {
            child.value = previous.value + 1
          }
        } else {
          child.value = 1
        }
      }
    }
  }

  // Map the class names of mark nodes.
  if (Nodes.is(node, 'mark')) {
    const classNames = node.ids
      .filter(id => id.startsWith('cls-'))
      .map(id => id.replace('cls-', ''))
    const newClassNames = classNames.map(className => {
      return MARK_NAME_MAP[className] || className
    })
    node.ids = newClassNames.map(className => `cls-${className}`)
  }

  // Convert all code nodes to mark nodes with the accounting account class.
  if (Nodes.is(node, 'text') && node.format === 16) {
    node.format = 0
    const markNode = {
      children: [{ ...node }],
      direction: null,
      format: '',
      indent: 0,
      version: 1,
      type: 'mark',
      ids: ['cls-accounting-account'],
    } satisfies Nodes.Mark
    Object.assign(node, markNode)
  }

  // Decrease the level of all headings by 1.
  if (Nodes.is(node, 'heading')) {
    node.tag = `h${parseFloat(node.tag[1]) - 1}` as typeof node.tag
  }

  if ((node as any).children) {
    ;(node as any).children = correctNodes((node as any).children)
  }

  return node
}

function correctNodes(nodes: Nodes.Node[]): Nodes.Node[] {
  return nodes.map(correctNode)
}

function getNodesFromHTML(html: string) {
  const editor = createHeadlessEditor({
    nodes: CORE_NODES,
  })

  // If an img tag is wrapped in a p, remove the surrounding p tag.
  html = html.replace(/<p><img(.*?)><\/p>/g, '<img$1/>').replace(/\/\/>/g, '/>')

  debug('html content', html)

  // Import the nodes into the editor.
  let result: Nodes.Node[] = []
  editor.update(() => {
    const nodes = nodesFromHtml(html, editor)
    const root = $getRoot()
    root.append(...nodes)
    const state = editor._pendingEditorState?.toJSON()
    result = state ? state.root.children : []
  })

  // Correct the state...
  result = correctNodes(result)

  return result
}

interface ContentWidget extends Legacy.Widget {
  config?: {
    content?: string
    isMarkdown?: boolean
  }
}

export default {
  weight: 0,
  label: 'content',
  identifier: 'Content',
  convert({ config }) {
    if (config?.content) {
      // Regardless of if it's Markdown or not, the parser should allow all normal HTML tags.
      const html = markdownToHtml(config.content)
      return getNodesFromHTML(html)
    } else {
      debug('content widget has no content')
      return []
    }
  },
} satisfies WidgetConverter<ContentWidget>
