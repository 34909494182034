import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled, useAudioPlayback } from '@thesisedu/web'
import React from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { keyframes, css } from 'styled-components'

import { DraggableImage } from './DraggableImage'
import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { Draggable as TDraggable, DragItem, DragItemType, DropItem } from './types'

export interface DraggableViewProps {
  draggable: TDraggable
  onLoad: (current: number, total: number) => void
  onDrop?: (target?: string) => void
  onCollect?: (monitor: DragSourceMonitor) => void
  style?: any
  correct?: boolean
  isDropped?: boolean
}
export function DraggableView({
  correct,
  draggable,
  onLoad,
  onDrop,
  onCollect,
  style,
  isDropped,
}: DraggableViewProps) {
  const [animationDelay] = React.useState(Math.random())
  const { filename: audioFilename } = getFileInfo(draggable.audioPath || '')
  const audioLoaded = React.useRef<boolean>(false)
  const playAudio = useAudioPlayback(audioFilename, {
    modifyAudio: audio => {
      audioLoaded.current = false
      audio.preload = 'auto'
      audio.addEventListener('canplaythrough', () => {
        audioLoaded.current = true
        updateOnLoad()
      })
    },
  })
  const imageLoaded = React.useRef<boolean>(false)
  const { width = 0, height = 0 } = useGraphicDragDropContext(true)
  const [{ isDragging }, dragRef, preview] = useDrag<DragItem, any, { isDragging: boolean }>(
    () => ({
      type: DragItemType.Image,
      item: { id: draggable.id },
      end: (item, monitor) => {
        if (onDrop) {
          const dropResult = monitor.getDropResult<DropItem>()
          onDrop(dropResult?.id)
        }
      },
      collect: monitor => {
        if (onCollect) onCollect(monitor)
        return {
          isDragging: monitor.isDragging(),
        }
      },
    }),
    [onDrop, onCollect],
  )
  const { filename } = getFileInfo(draggable.imagePath || '')
  const updateOnLoad = () => {
    let loadTotal = 0
    let loadCurrent = 0
    if (draggable.imagePath) {
      loadTotal++
      if (imageLoaded.current) loadCurrent++
    }
    if (draggable.audioPath) {
      loadTotal++
      if (audioLoaded.current) loadCurrent++
    }
    onLoad(loadCurrent, loadTotal)
  }
  React.useEffect(() => {
    updateOnLoad()
  }, [draggable.audioPath, draggable.imagePath])
  React.useEffect(() => {
    imageLoaded.current = false
  }, [draggable.imagePath])
  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])
  return (
    <DraggableContainer
      ref={dragRef}
      className={'draggable'}
      isDragging={isDragging}
      hasAnswer={correct !== undefined || isDropped}
      style={{
        left: draggable.x * width,
        top: draggable.y * height,
        width: draggable.width * width,
        height: draggable.height * height,
        cursor: draggable.audioPath ? 'pointer' : 'default',
        animationDelay: `${animationDelay}s`,
        ...style,
      }}
      onMouseDown={
        draggable.audioPath
          ? () => {
              playAudio()
            }
          : undefined
      }
    >
      {correct !== undefined ? (
        <AnswerIconContainer correct={correct}>
          {correct ? <CheckCircleFilled /> : <CloseCircleFilled />}
        </AnswerIconContainer>
      ) : null}
      {filename ? (
        <DraggableImage
          imagePath={filename}
          width={draggable.width * width}
          height={draggable.height * height}
          onLoad={() => {
            imageLoaded.current = true
            updateOnLoad()
          }}
          gifProps={{
            loopTimes: draggable.gifPlaybackLoopTimes || 1,
          }}
        />
      ) : null}
    </DraggableContainer>
  )
}

const DraggableAnimation = keyframes`
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(0.95);
  }
`
const DraggableContainer = styled.div<{ isDragging: boolean; hasAnswer?: boolean }>`
  position: absolute;
  opacity: ${props => (props.isDragging ? 0 : 1)};
  transition: opacity 0.25s linear;
  animation: ${props =>
    props.isDragging || props.hasAnswer
      ? 'none'
      : css`
          ${DraggableAnimation} 2s ease-in-out infinite
        `};
`
const AnswerIconContainer = styled.div<{ correct: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  font-size: ${props => props.theme['@size-m']};
  color: ${props => props.theme[props.correct ? '@green' : '@red']};
  background: white;
  border-radius: 50%;
  line-height: 1;
  > * {
    display: block;
  }
`
