import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { Format } from '@thesisedu/feature-utils'
import { Block, BodyExtraSmall, Empty, styled, useStateAndDebounce } from '@thesisedu/react'
import { NavigationList } from '@thesisedu/react/devtools'
import { orderBy, uniqBy } from 'lodash'
import React from 'react'

import { ListHeader, ListHeaderContext } from './ListHeader'
import { LogFragmentListItem } from './LogFragmentListItem'
import { ViewDetailedLog } from './ViewDetailedLog'
import { useDetailedLogContentSearch } from './useDetailedLogContentSearch'
import { useUserDetailedLogs } from './useUserDetailedLogs'
import { DetailedLogFragment } from '../../schema'

export function ViewDetailedLogs() {
  const { coreAuthKey } = useImpersonation()
  const [selectedLogId, setSelectedLogId] = React.useState<string | null>(null)
  const { detailedLogs, fetchingInitial } = useUserDetailedLogs()
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(undefined)
  const [dContentQuery, , setContentQuery] = useStateAndDebounce<string | undefined>(
    undefined,
    1000,
  )
  const _filteredLogs = searchQuery?.trim()
    ? detailedLogs.filter(log => {
        const text = [log.filename, ...log.tags].join(' ')
        return text.toLowerCase().includes(searchQuery.toLowerCase())
      })
    : detailedLogs
  const { matchingLogs, progress } = useDetailedLogContentSearch({
    filteredLogs: _filteredLogs,
    query: dContentQuery,
  })
  const selectedLog = selectedLogId ? detailedLogs.find(l => l.id === selectedLogId) : undefined
  const filteredLogs = matchingLogs || _filteredLogs

  if (!coreAuthKey) {
    return <Empty description={'No core auth key found; make sure you are impersonating a user.'} />
  }

  return (
    <Container>
      <LeftContainer>
        <ListHeaderContext.Provider value={{ fetchingInitial }}>
          <NavigationList<DetailedLogFragment>
            children={LogFragmentListItem}
            itemHeight={50}
            items={uniqBy(orderBy(filteredLogs, ['filename'], ['desc']), 'id')}
            selectedIds={selectedLogId ? [selectedLogId] : undefined}
            onItemSelected={id => setSelectedLogId(id)}
            onSearch={query => {
              const [searchQuery, contentQuery] = query?.split(';').map(q => q.trim()) || []
              setSearchQuery(searchQuery || undefined)
              setContentQuery(contentQuery || undefined)
            }}
            searchProps={{
              placeholder: 'Search ([filename]; [log content])',
              right: progress ? (
                <BodyExtraSmall color={'@text-color-secondary'}>
                  {Format.number(progress, 'percentage')}
                </BodyExtraSmall>
              ) : undefined,
            }}
            header={{
              height: 30,
              component: ListHeader,
            }}
          />
        </ListHeaderContext.Provider>
      </LeftContainer>
      <RightContainer>
        {selectedLog ? (
          <ViewDetailedLog detailedLog={selectedLog} defaultSearch={dContentQuery} />
        ) : (
          <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
            <Empty description={'Select a log from the left to view more information.'} />
          </Block>
        )}
      </RightContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`
const LeftContainer = styled.div`
  width: 300px;
  border-right: solid 1px ${props => props.theme['@border-color-base']};
`
const RightContainer = styled.div`
  flex: 1;
`
