import { Reset, styled } from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import React from 'react'

export interface CollapseButtonProps {
  collapsed?: boolean
  onClick?: () => void
}
export function CollapseButton({ collapsed, onClick }: CollapseButtonProps) {
  return (
    <Button collapsed={collapsed} onClick={onClick}>
      <NavArrowRight />
    </Button>
  )
}

const Button = styled(Reset.Button)<{ collapsed?: boolean }>`
  transform: rotate(${props => (props.collapsed ? '0deg' : '90deg')});
  padding: ${props => props.theme['@size-xs']};
  color: ${props => props.theme['@text-color-secondary']};
  font-size: ${props => props.theme['@size-m']};
  margin-right: ${props => props.theme['@size-s']};
  margin-left: -${props => props.theme['@size-xs']} !important;
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
`
