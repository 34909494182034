import { BlockSpin, Body } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

import { UpdateTeacherSiteButton } from './UpdateTeacherSiteButton'
import { useUserTeacherQuery } from './schema'

export interface UserTeacherInfoProps {
  userId: string
}
export const UserTeacherInfo: React.FC<React.PropsWithChildren<UserTeacherInfoProps>> = ({
  userId,
}) => {
  const { data, loading } = useUserTeacherQuery({ variables: { userId } })
  if (loading) {
    return <BlockSpin />
  }
  const user = data?.node?.__typename === 'User' && data.node.teacher ? data?.node : undefined
  if (user) {
    return (
      <>
        <Body isBlock>
          <strong>Site:</strong>{' '}
          <Link to={`/admin/sites/${user.teacher!.site.id}`}>{user.teacher!.site.name}</Link>
          <UpdateTeacherSiteButton teacherId={user.teacher!.id} />
        </Body>
        {user.teacher!.site.district ? (
          <Body isBlock>
            <strong>District:</strong>{' '}
            <Link to={`/admin/districts/${user.teacher!.site.district.id}`}>
              {user.teacher!.site.district.name}
            </Link>
          </Body>
        ) : null}
      </>
    )
  } else {
    return null
  }
}
