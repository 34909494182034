import { styled } from '@thesisedu/web'
import React from 'react'

import { BeamProps } from './Beam'
import { Chord } from './Chord'
import { Lyric } from './Lyric'
import { getNoteColor, Note, NoteProps } from './Note'
import { useNoteItemContext } from './NoteItemContext'
import { NoteText } from './NoteText'
import { Rest } from './Rest'
import { Solfege } from './Solfege'
import { useStaffContext } from './StaffContext'
import { isNoteItem, TuneNoteBase, isRestItem, CommonSheetMusicViewerProps, HEIGHTS } from './types'
import { MultiDropBox } from '../editor/DropBox'

export interface NoteItemProps extends CommonSheetMusicViewerProps {
  voice: TuneNoteBase
  beam?: BeamProps
  inBeam?: NoteProps['inBeam']
  compact?: boolean
  noDropBox?: boolean
  forceInverse?: boolean
  remainingDuration?: number
}
export function NoteItem({
  voice,
  beam,
  inBeam,
  compact,
  noDropBox,
  forceInverse,
  remainingDuration,
  ...common
}: NoteItemProps) {
  const { showChords, showLyrics, showSolfege, showNote, solfegeOffset, chordOpacity } = common
  const { extraMargin } = useStaffContext(true)
  const { noteTopContent } = useNoteItemContext(false) || {}
  let noteContent
  let lyricColor = '@text-color'
  if (isNoteItem(voice)) {
    const pitch = voice.pitches[0].pitch
    noteContent = (
      <Note
        note={voice}
        beam={beam}
        inBeam={compact ? inBeam : undefined}
        forceInverse={forceInverse}
      />
    )
    lyricColor = getNoteColor(pitch, voice.pitches[0].accidental)
  } else if (isRestItem(voice)) {
    noteContent = <Rest note={voice} />
  } else {
    noteContent = null
  }

  let topContent
  if (noteTopContent) {
    topContent = noteTopContent.render(voice)
  } else {
    topContent = null
  }

  let chordContent
  if (showChords && voice.chord?.length) {
    chordContent = <Chord chord={voice.chord[0].name} opacity={chordOpacity} />
  } else {
    chordContent = null
  }

  let lyricContent
  if (showLyrics && voice.lyric?.length) {
    lyricContent = <Lyric lyric={voice.lyric[0].syllable} color={lyricColor} />
  }

  let noteTextContent
  if (showNote && isNoteItem(voice)) {
    noteTextContent = <NoteText pitch={voice.pitches[0].pitch} color={lyricColor} />
  }

  let solfegeContent
  if (showSolfege && isNoteItem(voice)) {
    solfegeContent = (
      <Solfege
        pitch={voice.pitches[0].pitch}
        accidental={voice.pitches[0].accidental}
        offset={solfegeOffset}
        inBeam={!!inBeam}
        duration={voice.duration}
      />
    )
  }

  let children = (
    <ItemInnerContainer compact={compact} data-start-char={voice.startChar}>
      <TopContainer compact={compact}>
        {topContent}
        {chordContent}
      </TopContainer>
      {noteContent}
      <BottomContainer extraMargin={extraMargin} compact={compact}>
        {lyricContent}
        {noteTextContent}
        {solfegeContent}
      </BottomContainer>
    </ItemInnerContainer>
  )
  if (!noDropBox) {
    children = (
      <MultiDropBox
        {...common}
        compact={compact}
        startPos={voice.startChar}
        duration={voice.duration}
        replace={voice.endChar - voice.startChar}
        children={children}
        allowActions
        note={voice}
        remainingDuration={remainingDuration}
      />
    )
  }

  return <ItemContainer duration={voice.duration} children={children} />
}

const NOTE_PADDING_LEFT = '@size-m'
const ItemContainer = styled.div<{ duration: number }>`
  flex: ${props => props.duration * 4};
  display: flex;
`
const ItemInnerContainer = styled.div<{ compact?: boolean }>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding-left: ${props => (props.compact ? 0 : props.theme[NOTE_PADDING_LEFT])};
  position: relative;
`
const TopContainer = styled.div<{ compact?: boolean }>`
  position: absolute;
  top: 0;
  left: ${props => (props.compact ? 0 : props.theme[NOTE_PADDING_LEFT])};
  transform: translateY(-100%);
  width: 100%;
`
const BottomContainer = styled.div<{ extraMargin: number; compact?: boolean }>`
  width: 100%;
  position: absolute;
  top: ${props => HEIGHTS.staff + (props.extraMargin / 100) * HEIGHTS.staff}px;
  left: ${props => (props.compact ? 0 : props.theme[NOTE_PADDING_LEFT])};
  right: 0;
`
