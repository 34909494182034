import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import { Button, Dropdown, Modal, Text, TextField } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { SegmentType } from '../../schema'
import { useCreateOrUpdateSegmentMutation } from '../../segment/CreateEditSegment'
import { SegmentActionResource, SegmentActionComponentProps } from '../../types'

function Rename({ segment }: SegmentActionComponentProps) {
  const [visible, setVisible] = React.useState(false)
  const [name, setName] = React.useState<string>(segment.name)
  const [createOrUpdate, { loading }] = useCreateOrUpdateSegmentMutation({
    onCompleted: () => {
      setVisible(false)
    },
  })
  React.useEffect(() => {
    setName(segment.name)
  }, [segment.name])
  return (
    <Modal
      visible={visible}
      onVisibleChange={setVisible}
      title={'Rename Content'}
      trigger={<Dropdown.Item icon={<Edit />}>Rename</Dropdown.Item>}
    >
      <Text bottom>
        What would you like to rename <strong>{segment.name}</strong> to?
      </Text>
      <TextField value={name} onChange={setName} size={'large'} />
      <Modal.Footer>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Button
          variant={'primary'}
          onPress={() => {
            if (name?.trim()) {
              createOrUpdate({
                variables: {
                  input: {
                    id: toGlobalId('Segment', segment.id),
                    name,
                  },
                },
              })
            }
          }}
          loading={loading}
          disabled={!name?.trim()}
        >
          Rename
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const RenameAction: SegmentActionResource = {
  identifier: 'rename',
  type: 'SegmentAction',
  group: 'My Content',
  weight: 402,
  component: Rename,
  filter: (segment, { status, viewer }) => {
    return (
      !NON_GROUP_TYPES.includes(segment.type as SegmentType) &&
      !!status?.isContentCustom(segment.id) &&
      !!segment.userId &&
      !!viewer?.id &&
      segment.userId.toString() === fromGlobalId(viewer.id, true).id
    )
  },
  handler: () => {},
}
