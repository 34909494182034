import { PaperClipOutlined } from '@ant-design/icons'
import { Nodes } from '@thesisedu/feature-widgets-core'
import { getDefaultEditorValue } from '@thesisedu/feature-widgets-react'
import React from 'react'

import { SegmentType } from '../schema'
import { SimpleSegmentPresetResource } from '../types'

export const FilePreset: () => SimpleSegmentPresetResource = () =>
  ({
    type: 'SimpleSegmentPreset',
    identifier: 'file',
    segmentType: SegmentType.Section,
    label: 'File',
    icon: <PaperClipOutlined />,
    formValues: {
      config: {
        icon: 'paper-clip',
        label: 'File',
        studentContent: getDefaultEditorValue([Nodes.createFile()]),
      },
    },
  }) satisfies SimpleSegmentPresetResource
