import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { useFeature, useStatePropFallback } from '@thesisedu/feature-react'
import { Modal$, Modal, s } from '@thesisedu/ui'
import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { NavigationGroupIndexes, SetupNavigation } from './Navigation/SetupNavigation'
import { DefaultStep, useSteps } from './steps'
import { TransitionOpts } from './types'
import { ClassSyncReactFeature } from '../ClassSyncReactFeature'

export interface SetupModalProps extends Partial<Modal$.ModalProps> {
  defaultStep?: TransitionOpts<any>
}
export function SetupModal({ defaultStep = DefaultStep, ...props }: SetupModalProps) {
  const cls = useSelectedClass(true)
  const feature = useFeature(ClassSyncReactFeature)
  const [stepOpts, setStepOpts] = React.useState<TransitionOpts>(defaultStep)
  const steps = useSteps()
  const step = steps[stepOpts.step]
  const [visible, setVisible] = useStatePropFallback(props.visible, props.onVisibleChange, false)
  React.useEffect(() => {
    if (!visible) {
      setStepOpts(defaultStep)
    }
  }, [visible])
  if (!step) throw new Error(`Step '${stepOpts.step}' is invalid.`)
  const current = step.animationGroup ?? stepOpts.step
  return (
    <Modal
      {...props}
      width={'auto'}
      style={{ padding: 0 }}
      visible={visible}
      onVisibleChange={v => {
        setVisible(v)
      }}
      noClose={step.hideClose}
    >
      <motion.div
        layout
        animate={{
          height: step.size.height,
          width: step.size.width,
        }}
        style={{
          maxWidth: '90vw',
        }}
        initial={false}
        transition={{ duration: 0.2, delay: 0.1 }}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            initial={{ opacity: 0, scale: 0.97 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.97 }}
            transition={{ duration: 0.2 }}
            style={{
              minWidth: '100%',
              minHeight: '100%',
              maxHeight: '100%',
              display: 'flex',
              justifyContent:
                current === 'loading' || step.centerVertically ? 'center' : 'flex-start',
              alignItems: 'stretch',
              flexDirection: 'row',
            }}
            key={current}
          >
            {step.group ? (
              <SetupNavigation activeIndex={NavigationGroupIndexes[step.group]} />
            ) : null}
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: s.size('m'),
                minWidth: 0,
              }}
            >
              <step.Component
                cls={cls}
                feature={feature}
                context={stepOpts.context}
                transition={_opts => {
                  if (_opts) {
                    let opts = _opts
                    let newStep = steps[opts.step]
                    if (!newStep) throw new Error(`Step '${opts.step}' is invalid.`)
                    while (newStep?.onTransitionTo) {
                      const candidate = newStep.onTransitionTo({
                        feature,
                        cls,
                        context: opts.context,
                      })
                      if (candidate) {
                        opts = candidate
                        newStep = steps[opts.step]
                        if (!newStep) throw new Error(`Step '${opts.step}' is invalid.`)
                      } else break
                    }
                    setStepOpts(opts)
                  } else {
                    setVisible(false)
                  }
                }}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      </motion.div>
    </Modal>
  )
}
