import { Breadcrumb as AntBreadcrumb } from 'antd'
import orderBy from 'lodash/orderBy'
import path from 'path'
import React, { useState, useContext, useEffect } from 'react'
import { useResolvedPath, Link } from 'react-router-dom'

export interface BreadcrumbInfo {
  href: string
  title: string | React.ReactElement
}
export interface BreadcrumbContextValue {
  activeCrumbs: BreadcrumbInfo[]
  addCrumb: (info: BreadcrumbInfo) => void
  removeCrumb: (href: string) => void
}
export const BreadcrumbContext = React.createContext<BreadcrumbContextValue>({
  activeCrumbs: [],
  addCrumb: () => {},
  removeCrumb: () => {},
})

export function BreadcrumbProvider({ children }: React.PropsWithChildren<object>) {
  const [activeCrumbs, setActiveCrumbs] = useState<BreadcrumbInfo[]>([])
  const value: BreadcrumbContextValue = {
    activeCrumbs,
    addCrumb: info => {
      setActiveCrumbs(crumbs => [...crumbs, info])
    },
    removeCrumb: href => {
      setActiveCrumbs(crumbs => crumbs.filter(crumb => crumb.href !== href))
    },
  }

  return <BreadcrumbContext.Provider value={value} children={children} />
}

type PartialBreadcrumbInfo = Omit<BreadcrumbInfo, 'href'> &
  Partial<Pick<BreadcrumbInfo, 'href'>> & {
    relativeHref?: string
  }
export function useBreadcrumb(
  info: Omit<BreadcrumbInfo, 'href'> | PartialBreadcrumbInfo[],
  key?: string,
) {
  const href = useResolvedPath('').pathname
  const { addCrumb, removeCrumb } = useContext(BreadcrumbContext)
  useEffect(() => {
    if (Array.isArray(info)) {
      const resolvedItems = info.map(item => {
        return {
          ...item,
          href: item.href
            ? item.href
            : item.relativeHref
            ? path.resolve(href, item.relativeHref)
            : href,
        }
      })
      for (const item of resolvedItems) {
        addCrumb(item)
      }
      return () => {
        for (const item of resolvedItems) {
          removeCrumb(item.href)
        }
      }
    } else {
      addCrumb({ ...info, href })
      return () => {
        removeCrumb(href)
      }
    }
  }, [key])
}

export function Breadcrumbs() {
  const { activeCrumbs } = useContext(BreadcrumbContext)
  const sorted = orderBy(activeCrumbs, crumb => crumb.href.length)
  return (
    <AntBreadcrumb>
      {sorted.map(crumb => (
        <AntBreadcrumb.Item key={crumb.href}>
          <Link to={crumb.href}>{crumb.title}</Link>
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  )
}
