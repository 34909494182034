export * from './Bar'
export * from './BarSegment'
export * from './Container'
export * from './Label'
export * from './ValueLabel'
export * from './context'

import { Bar } from './Bar'
import { BarSegment, BarSegments } from './BarSegment'
import { Container } from './Container'
import { Label } from './Label'
import { ProgressBar } from './ProgressBar'
import { ValueLabel } from './ValueLabel'
import { withSubComponents } from '../../utils/withSubComponents'

export default withSubComponents(ProgressBar, {
  Bar,
  BarSegment,
  BarSegments,
  Container,
  Label,
  ValueLabel,
})
