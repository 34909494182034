import { Dropdown } from '@thesisedu/ui'
import {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  MinusSquare,
  NavArrowDown,
  RemoveSquare,
  SortDown,
  SortUp,
  Trash,
} from '@thesisedu/ui/icons'
import { $addUpdateTag, $getRoot, $createParagraphNode } from 'lexical'
import * as React from 'react'

import { SortOptions } from './TableComponent'
import { Cell, Rows, TableNode } from './TableNode'

export interface TableActionMenuProps {
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
  cell: Cell
  updateCellsByID: (ids: string[], fn: () => void) => void
  updateTableNode: (fn2: (tableNode: TableNode) => void) => void
  cellCoordMap: Map<string, [number, number]>
  rows: Rows
  setSortingOptions: (options: null | SortOptions) => void
  sortingOptions: null | SortOptions
}

export function TableActionMenu({
  visible,
  onVisibleChange,
  cell,
  rows,
  cellCoordMap,
  updateCellsByID,
  updateTableNode,
  setSortingOptions,
  sortingOptions,
}: TableActionMenuProps) {
  const coords = cellCoordMap.get(cell.id)
  if (!coords) return null
  const [x, y] = coords
  return (
    <Dropdown.Container open={visible} onOpenChange={onVisibleChange}>
      <Dropdown.Button icon={<NavArrowDown />} size={'extraSmall'} rightDecorator={null} />
      <Dropdown.Menu
        onPointerMove={e => e.stopPropagation()}
        onPointerDown={e => e.stopPropagation()}
        onPointerUp={e => e.stopPropagation()}
        onClick={e => e.stopPropagation()}
      >
        <Dropdown.Item
          icon={<span>H</span>}
          onClick={() => {
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.updateCellType(x, y, cell.type === 'normal' ? 'header' : 'normal')
            })
          }}
        >
          {cell.type === 'normal' ? 'Make header' : 'Remove header'}
        </Dropdown.Item>
        <Dropdown.Item
          icon={<RemoveSquare />}
          onClick={() => {
            updateCellsByID([cell.id], () => {
              const root = $getRoot()
              root.clear()
              root.append($createParagraphNode())
            })
          }}
        >
          Clear cell
        </Dropdown.Item>
        <Dropdown.Item.Separator />
        {cell.type === 'header' && y === 0 ? (
          <>
            {sortingOptions !== null && sortingOptions.x === x ? (
              <Dropdown.Item icon={<MinusSquare />} onClick={() => setSortingOptions(null)}>
                Remove sorting
              </Dropdown.Item>
            ) : null}
            {sortingOptions === null ||
            sortingOptions.x !== x ||
            sortingOptions.type === 'descending' ? (
              <Dropdown.Item
                icon={<SortUp />}
                onClick={() => setSortingOptions({ type: 'ascending', x })}
              >
                Sort ascending
              </Dropdown.Item>
            ) : null}
            {sortingOptions === null ||
            sortingOptions.x !== x ||
            sortingOptions.type === 'ascending' ? (
              <Dropdown.Item
                icon={<SortDown />}
                onClick={() => setSortingOptions({ type: 'descending', x })}
              >
                Sort descending
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item.Separator />
          </>
        ) : null}
        <Dropdown.Item
          icon={<ArrowUp />}
          onClick={() =>
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.insertRowAt(y)
            })
          }
        >
          Insert row above
        </Dropdown.Item>
        <Dropdown.Item
          icon={<ArrowDown />}
          onClick={() =>
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.insertRowAt(y + 1)
            })
          }
        >
          Insert row below
        </Dropdown.Item>
        <Dropdown.Item.Separator />
        <Dropdown.Item
          icon={<ArrowLeft />}
          onClick={() =>
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.insertColumnAt(x)
            })
          }
        >
          Insert column left
        </Dropdown.Item>
        <Dropdown.Item
          icon={<ArrowRight />}
          onClick={() =>
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.insertColumnAt(x + 1)
            })
          }
        >
          Insert column right
        </Dropdown.Item>
        <Dropdown.Item.Separator />
        {rows[0].cells.length !== 1 ? (
          <Dropdown.Item
            danger
            icon={<MinusSquare />}
            onClick={() =>
              updateTableNode(tableNode => {
                $addUpdateTag('history-push')
                tableNode.deleteColumnAt(x)
              })
            }
          >
            Delete column
          </Dropdown.Item>
        ) : null}
        {rows.length !== 1 ? (
          <Dropdown.Item
            danger
            icon={<MinusSquare />}
            onClick={() =>
              updateTableNode(tableNode => {
                $addUpdateTag('history-push')
                tableNode.deleteRowAt(y)
              })
            }
          >
            Delete row
          </Dropdown.Item>
        ) : null}
        <Dropdown.Item
          danger
          icon={<Trash />}
          onClick={() =>
            updateTableNode(tableNode => {
              $addUpdateTag('history-push')
              tableNode.selectNext()
              tableNode.remove()
            })
          }
        >
          Delete table
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
