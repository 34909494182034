import { useResource } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { ImperativeTable, Table$, Text, Tag, Tag$, Tooltip, HSpaced, Button } from '@thesisedu/ui'
import {
  Box,
  Cancel,
  Check,
  CheckCircle,
  Clock,
  HelpCircle,
  NavArrowRight,
  User,
} from '@thesisedu/ui/icons'
import React from 'react'

import { PendingStudentClassReasonResource } from './types'
import { PendingStudentClassFragment, PendingStudentClassState } from '../schema'

function StudentNameCell({
  item,
}: Table$.ImperativeTableRenderCellProps<PendingStudentClassFragment>) {
  return (
    <div>
      <Text>{item.student.user.name ?? item.student.user.username}</Text>
      {item.student.user.email ? (
        <Text level={'s'} color={'secondary'}>
          {item.student.user.email}
        </Text>
      ) : null}
    </div>
  )
}

const StateMap: Record<PendingStudentClassFragment['state'], Tag$.TagProps> = {
  PENDING: { color: 'element', icon: <Clock />, children: 'Pending' },
  APPROVED: { color: 'green', icon: <Check />, children: 'Approved' },
  DECLINED: { color: 'red', icon: <Cancel />, children: 'Declined' },
}
function StateCell({ item }: Table$.ImperativeTableRenderCellProps<PendingStudentClassFragment>) {
  return <Tag {...StateMap[item.state]} />
}

function ReasonCell({ item }: Table$.ImperativeTableRenderCellProps<PendingStudentClassFragment>) {
  const { label, info } = useResource<PendingStudentClassReasonResource>(
    'PendingStudentClassReason',
    item.reason,
  )
  return (
    <Tooltip title={info}>
      <HSpaced space={'xs'}>
        <Text>{label}</Text>
        <Text>
          <HelpCircle />
        </Text>
      </HSpaced>
    </Tooltip>
  )
}

function ActionsCell({ item }: Table$.ImperativeTableRenderCellProps<PendingStudentClassFragment>) {
  const { reviewComponent: ReviewComponent } = useResource<PendingStudentClassReasonResource>(
    'PendingStudentClassReason',
    item.reason,
  )
  const [visible, setVisible] = React.useState(false)
  if (item.state !== PendingStudentClassState.Pending) return null
  return (
    <>
      {visible ? <ReviewComponent item={item} onClose={() => setVisible(false)} /> : null}
      <Button
        rightDecorator={<NavArrowRight />}
        variant={'chromeless'}
        children={'Review'}
        onPress={() => setVisible(true)}
      />
    </>
  )
}

export interface ClassApprovalTableProps {
  records: PendingStudentClassFragment[]
  loading?: boolean
}
export function ClassApprovalTable({ records, loading }: ClassApprovalTableProps) {
  return (
    <ImperativeTable
      items={records}
      emptyMessage={'There are no pending students to review.'}
      loading={loading}
      aria-label={'Pending Students'}
      style={{ width: '100%' }}
      columns={[
        {
          id: 'name',
          title: 'Student Name',
          icon: <User />,
          renderCell: StudentNameCell,
          isRowHeader: true,
        },
        {
          id: 'requested',
          title: 'Requested',
          icon: <Clock />,
          renderCell: props => Format.date(props.item.createdAt, 'dateTime'),
        },
        {
          id: 'reason',
          title: 'Reason',
          icon: <Box />,
          renderCell: ReasonCell,
        },
        {
          id: 'state',
          title: 'State',
          icon: <CheckCircle />,
          renderCell: StateCell,
        },
        {
          id: 'actions',
          title: '',
          renderCell: ActionsCell,
        },
      ]}
    />
  )
}
