import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { LicenseSubscriptionSize } from './schema'

export const SubscriptionSizeSelect = React.forwardRef<any, SelectProps<string>>((props, ref) => (
  <Select<string> placeholder={'Select a size'} {...props} ref={ref}>
    <Select.Option value={LicenseSubscriptionSize.Small}>Small</Select.Option>
    <Select.Option value={LicenseSubscriptionSize.Large}>Large</Select.Option>
  </Select>
))
