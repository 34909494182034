import { Shell } from '@thesisedu/feature-web'
import React from 'react'

import { NotificationDbReactFeature } from '../NotificationDbReactFeature'
import { UserNotificationsPopover } from '../UserNotificationsPopover'

export default function (feature: NotificationDbReactFeature) {
  feature.hookManager.registerMutateHook<Shell.TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    items => {
      return [
        ...items,
        {
          weight: 0,
          element: <UserNotificationsPopover key={'notifications'} />,
        },
      ]
    },
  )
}
