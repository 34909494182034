import { TableProps } from 'antd/es/table'
import { Moment } from 'moment'
import React from 'react'

import { TypographyProps } from '../Typography'

export interface FragmentListSection<T> {
  title?: string
  titleProps?: Partial<TypographyProps>
  key: string
  items: T[]
}

export interface FragmentListTableRow {
  [key: string]: any
}

interface ItemKeyFunc<T> {
  (item: T): string
}
export interface FragmentListCommonProps<T> {
  sections: FragmentListSection<T>[]
  itemKey: keyof T | ItemKeyFunc<T>
  refetch?: (after: string | null) => Promise<any>
  renderGridItem: (item: T) => React.ReactNode
  renderCalendarDate: (date: Moment) => React.ReactNode[]
  getRowData?: (item: T) => FragmentListTableRow
  tableProps?: Partial<TableProps<T>>
}

export enum FragmentListMode {
  GRID,
  CALENDAR,
  LIST,
}
