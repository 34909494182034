import { Dropdown, Tooltip } from '@thesisedu/ui'
import { Edit, NavArrowDown, Trash } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DeletePresetButton } from './DeletePresetButton'
import { MakeDefaultAction } from './MakeDefaultAction'
import { BasicClassGroupPresetFragment } from '../../schema'

export interface PresetActionsProps {
  preset: BasicClassGroupPresetFragment
  classId: string
}
export function PresetActions({ preset, classId }: PresetActionsProps) {
  const navigate = useNavigate()
  return (
    <Dropdown.Container>
      <Tooltip title={'Preset Actions'}>
        <Dropdown.Button
          icon={<NavArrowDown />}
          rightDecorator={null}
          data-testid={'PresetActions'}
        />
      </Tooltip>
      <Dropdown.Menu side={'bottom'} align={'end'}>
        <Dropdown.Item
          children={'Edit'}
          icon={<Edit />}
          onClick={() => {
            navigate(preset.id)
          }}
        />
        <MakeDefaultAction id={preset.id} isDefault={preset.isDefault} classId={classId} />
        <Dropdown.Item.Separator />
        <DeletePresetButton
          presetId={preset.id}
          trigger={<Dropdown.Item danger children={'Delete'} icon={<Trash />} />}
        />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
