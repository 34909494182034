import * as React from 'react'

function SvgNumber3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#F1E902'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#F1E902'}
          fillRule={'nonzero'}
          d={
            'M134.187 204.134c8.52.315 15.778-.694 21.773-3.03 5.995-2.334 10.855-4.985 14.578-7.951 3.724-2.966 6.437-5.68 8.141-8.141l2.556-3.692c.127-.252.82-1.799 2.083-4.639 1.262-2.84 2.398-6.847 3.408-12.022 1.01-5.175 1.136-10.697.379-16.566-.758-5.87-3.03-11.865-6.816-17.986-3.787-6.122-9.845-11.644-18.176-16.566 8.078-5.175 13.663-10.54 16.756-16.093 3.092-5.554 4.544-10.792 4.354-15.714-.189-4.923-1.199-9.404-3.029-13.443-1.83-4.039-3.629-7.163-5.396-9.371-1.767-2.21-2.713-3.377-2.84-3.503-.252-.252-1.735-1.483-4.449-3.692-2.714-2.209-6.942-4.544-12.685-7.005-5.743-2.461-12.59-3.976-20.542-4.544-7.952-.568-17.229.631-27.831 3.597-10.602 2.967-22.088 8.804-34.458 17.513l18.554 34.458c8.205-5.68 15.052-9.309 20.542-10.886 5.491-1.578 9.845-2.21 13.064-1.894 3.219.316 5.49 1.105 6.816 2.367l1.988 1.893 1.136 2.367c.757 1.577.726 3.281-.095 5.112-.82 1.83-3.03 3.344-6.626 4.543-3.598 1.2-9.688 1.42-18.27.663l-2.083 32.375c8.078-.757 14.389-.663 18.933.284 4.544.947 7.73 2.304 9.56 4.07 1.83 1.768 2.872 3.661 3.125 5.68.252 2.02.126 3.692-.379 5.018-.505 1.325-.757 2.05-.757 2.177l-1.704 1.799c-1.136 1.199-3.282 1.893-6.437 2.082-3.156.19-7.574-.757-13.253-2.84-5.68-2.082-13.064-6.405-22.152-12.969l-27.83 39.76c14.262 9.592 27.041 16.376 38.338 20.352 11.297 3.976 21.205 6.121 29.724 6.437z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M178.869 97.448c3.091-5.553 4.544-10.792 4.354-15.714-.189-4.923-1.2-9.402-3.029-13.443-1.831-4.038-3.63-7.162-5.396-9.371-1.769-2.207-2.716-3.376-2.84-3.503-.254-.251-1.736-1.482-4.449-3.692-2.716-2.207-6.943-4.544-12.685-7.005-5.745-2.461-12.59-3.976-20.542-4.544-7.952-.568-17.229.633-27.831 3.597-10.602 2.968-22.09 8.804-34.458 17.513l18.554 34.458c8.204-5.68 15.052-9.307 20.542-10.887 5.491-1.576 9.846-2.206 13.064-1.893 3.219.317 6.153 1.737 8.804 4.26 1.514 3.157 1.86 5.65 1.041 7.478-.822 1.832-3.03 3.346-6.626 4.544-3.598 1.202-9.689 1.42-18.27.663l-2.083 32.375c8.076-.757 14.389-.663 18.933.284 4.544.947 7.73 2.305 9.56 4.07 1.829 1.77 2.87 3.663 3.125 5.68.251 2.021.124 3.692-.379 5.018-.506 1.325-.757 2.053-.757 2.177-2.272 2.4-4.988 3.692-8.141 3.881-3.157.19-7.573-.757-13.253-2.84-5.68-2.082-13.064-6.404-22.152-12.969l-27.83 39.759c14.26 9.594 27.04 16.377 38.338 20.353 11.294 3.976 21.205 6.12 29.724 6.437 8.52.313 15.777-.695 21.773-3.03 5.993-2.333 10.854-4.984 14.578-7.951 3.722-2.964 7.29-6.91 10.697-11.833.124-.251.82-1.799 2.083-4.639 1.26-2.84 2.396-6.845 3.408-12.022 1.009-5.174 1.136-10.697.379-16.566-.758-5.87-3.03-11.862-6.816-17.986-3.787-6.12-9.845-11.644-18.176-16.566 8.076-5.174 13.661-10.537 16.756-16.093z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber3 = React.memo(SvgNumber3)
export default MemoSvgNumber3
