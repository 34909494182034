import { ResponsiveRadar as NivoResponsiveRadar, RadarSvgProps } from '@nivo/radar'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { AspectBox } from './AspectBox'
import { getColors, getNivoTheme } from './theme'

const generateLoadingData = (keys: string[], indexBy: string) => {
  return new Array(6).fill('').map(() =>
    keys.reduce<object>(
      (acc, key) => ({
        ...acc,
        [key]: Math.random() * 100 + 50,
      }),
      { [indexBy]: '--' },
    ),
  )
}

interface Data {
  [key: string]: any
}
export interface ValueConfig {
  name: string
  formatter?: (value: any, data: Data) => any
}
export interface ValueLabelMap {
  [dataKey: string]: ValueConfig
}
export type ResponsiveRadarProps = Omit<RadarSvgProps<any>, 'width' | 'height'> & {
  loading?: boolean
  valueLabels?: ValueLabelMap
}
export const ResponsiveRadar: React.FC<React.PropsWithChildren<ResponsiveRadarProps>> = ({
  loading,
  valueLabels,
  ...props
}) => {
  const theme = useContext(ThemeContext)
  return (
    <AspectBox numYears={2} numItems={props.data?.length || 0}>
      <div>
        <NivoResponsiveRadar
          colors={getColors(theme, loading)}
          theme={getNivoTheme(theme)}
          margin={{ top: 25, right: 0, bottom: 25, left: 0 }}
          enableDots={false}
          {...props}
          data={
            loading
              ? generateLoadingData((props.keys as any[]) || ['one'], props.indexBy.toString())
              : props.data
          }
        />
      </div>
    </AspectBox>
  )
}
