import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { eventFiles } from '@lexical/rich-text'
import { PASTE_COMMAND, COMMAND_PRIORITY_HIGH } from 'lexical'
import React from 'react'

import { $createImageNode } from '../../nodes/Image/ImageNode'
import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function usePasteImage() {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (payload, editor) => {
        const [isFileTransfer, files] = eventFiles(payload)
        if (!isFileTransfer || files.length !== 1) return false
        if (!files[0].type.startsWith('image')) return false

        // Create a new image block with the upload promise.
        editor.update(() => {
          const element = $getSelectedBlockElement()
          if (element) {
            const node = $createImageNode({
              uploadFile: {
                editor,
                file: files[0],
              },
            })
            element.insertAfter(node)
          }
        })

        return true
      },
      COMMAND_PRIORITY_HIGH,
    )
  }, [editor])
}
