import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'

import BulkUpdateAssignments from './BulkUpdateAssignments'
import defaultAssignmentSettings from './DefaultAssignmentSettings'
import enableGeneratedAssignmentProviders from './EnableGeneratedAssignmentProviders'
import gradeIncompleteLateAsZero from './GradeIncompleteLateAsZero'
import GradingCategories from './GradingCategories'
import GradingMode from './GradingMode'
import GroupsPresets from './GroupsPresets'
import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

const RESOURCES: ClassConfigurationFieldResource[] = [
  gradeIncompleteLateAsZero,
  enableGeneratedAssignmentProviders,
  defaultAssignmentSettings,
]
export function addClassConfigurationFields(feature: AssignmentsReactFeature) {
  for (const resource of RESOURCES) {
    feature.resources.addResource({
      ...resource,
      weight: 1,
    })
  }
  GradingMode(feature)
  GradingCategories(feature)
  GroupsPresets(feature)
  BulkUpdateAssignments(feature)
}
