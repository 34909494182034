import { DeepLinkProps } from '@thesisedu/feature-react'
import React from 'react'

import { useViewerContext } from './useViewerContext'

export function UserGroupPrefixDeepLinkRemapper({ resolved, url }: DeepLinkProps) {
  const viewer = useViewerContext(false)
  React.useEffect(() => {
    if (viewer?.group) {
      resolved({
        type: 'Found',
        resolvedPath: `/${viewer.group.toLowerCase()}/${url.replace(/^\//, '')}`,
      })
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [])

  return null
}
