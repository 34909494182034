import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { ExpandedContentComponentProps } from '@thesisedu/feature-classes-react'
import { useTeacherIdQuery } from '@thesisedu/feature-teachers-react/dist/schema'
import { Body } from '@thesisedu/web'
import { message, Modal, Upload } from 'antd'
import React from 'react'

import { StudentSampleLink } from './StudentSampleLink'
import { ImportStudentIDPayload } from '../ImportStudentID'
import { useImportStudentsMutation } from '../queries/useImportStudentsMutation'

export interface SuccessModalContentsProps {
  count: number
  teacherId?: string
}
export function SuccessModalContents({ teacherId, count }: SuccessModalContentsProps) {
  return (
    <div>
      <p>
        You've just imported <strong>{count}</strong> students. They'll login with their student IDs
        you specified in the spreadsheet, and their teacher ID:
      </p>
      <p style={{ fontSize: 20 }}>
        <strong>{teacherId || '--'}</strong>
      </p>
    </div>
  )
}

export function Import({
  onClose,
  class: cls,
}: ExpandedContentComponentProps<ImportStudentIDPayload>) {
  const { data } = useTeacherIdQuery()
  const teacherId = data?.viewer?.teacher?.teacherId
  const [importStudents, { loading }] = useImportStudentsMutation({
    onError: () => {
      onClose()
    },
    onCompleted: data => {
      Modal.success({
        title: 'Students imported successfully',
        content: (
          <SuccessModalContents teacherId={teacherId} count={data.importStudents.createdStudents} />
        ),
      })
      onClose()
    },
  })
  return (
    <>
      <Body isBlock>
        To import students, simply create a spreadsheet with the following columns (please note,
        these column headers must appear in your spreadsheet <em>exactly as they appear here</em>):
      </Body>
      <ul>
        <li>
          <Body>
            <strong>studentID</strong> - The student ID (optional). They will use this to login. If
            one is not provided, one will be generated for them.
          </Body>
        </li>
        <li>
          <Body>
            <strong>firstName</strong> - The student's first name (optional).
          </Body>
        </li>
        <li>
          <Body>
            <strong>lastName</strong> - The student's last name (optional).
          </Body>
        </li>
      </ul>
      <Body isBlock>
        <strong>You may import up to 100 students at a time.</strong> You can also{' '}
        <StudentSampleLink />
      </Body>
      <Upload.Dragger
        name={'file'}
        multiple={false}
        beforeUpload={file => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = () => {
            // eslint-disable-next-line unicorn/no-unsafe-regex
            let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '')
            if (encoded && encoded.length % 4 > 0) {
              encoded += '='.repeat(4 - (encoded.length % 4))
            }
            if (encoded) {
              importStudents({
                variables: {
                  input: {
                    base64File: encoded,
                    id: cls.id,
                  },
                },
              })
            }
          }
          reader.onerror = () => {
            message.error('It looks like the file you selected is invalid.')
          }
          return false
        }}
        disabled={loading}
      >
        <p className={'ant-upload-drag-icon'}>
          {loading ? <LoadingOutlined /> : <CloudUploadOutlined />}
        </p>
        <p className={'ant-upload-text'}>
          {loading ? 'Importing students...' : 'Click or drag spreadsheet to this area to upload'}
        </p>
        <p className={'ant-upload-hint'}>
          Most spreadsheet types are supported
          <br />
          (Excel, CSV, Numbers, etc)
        </p>
      </Upload.Dragger>
    </>
  )
}
