import { isEqual } from 'lodash'

import { ReportDimensionResult } from '../../../execute/extensibleTypes'
import { getInputIdentifier } from '../../../execute/input'
import { ReportResultItemFragment, RunReportQuery } from '../../../execute/types'
import { ReportMetricResource, RunReportOpts } from '../../../types'
import { AxesResponsibility, isMetrics } from '../axes'
import { GridExtents } from '../extents'

export interface ReportCell {
  metricValue?: number | null
  metricIdentifier: string
  item: ReportResultItemFragment | null
}

// TODO: Create a common package; this shares the same logic as the server.
export function dimensionMatches(a: Record<string, any>, b: Record<string, any>) {
  return isEqual(a, b)
}

type FirstGetter = (data: RunReportQuery['runReport'], index: number) => ReportResultItemFragment[]
export type SecondGetter = (fragments: ReportResultItemFragment[], index: number) => ReportCell
export function getReportCells(
  data: RunReportQuery['runReport'],
  dimensionResults: ReportDimensionResult[],
  opts: RunReportOpts,
  extents: GridExtents,
  responsibility: AxesResponsibility,
  allMetricResources: ReportMetricResource[],
): ReportCell[][] {
  if (!data.results) {
    throw new Error('#getReportCells() only supports results, not summaries.')
  }
  let secondGetter: SecondGetter
  const results: ReportCell[][] = []
  const { col: respCol, row: respRow } = responsibility
  const firstType = !isMetrics(respCol) ? 'col' : !isMetrics(respRow) ? 'row' : undefined
  const firstGetterResponsibility = !isMetrics(respCol)
    ? respCol
    : !isMetrics(respRow)
    ? respRow
    : undefined
  if (!firstType) throw new Error('Metrics are both col and row')
  if (!firstGetterResponsibility) throw new Error('Metrics are both col and row')
  const firstGetter: FirstGetter = (data, index) => {
    const dimensionValue = dimensionResults[index][firstGetterResponsibility.inputKey]
    if (!dimensionValue) throw new Error('No dimension summary found for index ' + index.toString())
    return data.results!.filter(r =>
      Object.keys(r.dimensions).some(dimKey =>
        dimensionMatches(r.dimensions[dimKey], dimensionValue),
      ),
    )
  }
  if ((isMetrics(respCol) && !isMetrics(respRow)) || (isMetrics(respRow) && !isMetrics(respCol))) {
    // One of them is a metric.
    secondGetter = (fragments, index) => {
      const metricResource = isMetrics(respCol)
        ? respCol[index]
        : isMetrics(respRow)
        ? respRow[index]
        : undefined
      if (!metricResource) throw new Error('Invalid metric found in metricGetter')
      return {
        metricIdentifier: metricResource.identifier,
        // This is 0 because all of the metrics are contained inside the first result.
        metricValue:
          fragments[0]?.metrics[metricResource.summarization]?.[metricResource.metricKey],
        item: fragments[0],
      }
    }
  } else if (!isMetrics(respCol) && !isMetrics(respRow)) {
    // Both are dimensions.
    const metric = getInputIdentifier(opts.metrics[0])
    const metricResource = allMetricResources.find(r => r.identifier === metric)
    if (!metricResource) throw new Error(`Metric '${metric}' could not be found.`)
    secondGetter = (fragments, index) => {
      const dimensionValue = dimensionResults[index][respRow.inputKey]
      if (!dimensionValue)
        throw new Error('No dimension summary found for index ' + index.toString())
      const value = fragments.find(r =>
        Object.keys(r.dimensions).some(dimKey =>
          dimensionMatches(r.dimensions[dimKey], dimensionValue),
        ),
      )
      return {
        metricIdentifier: metric,
        metricValue: value?.metrics?.[metricResource?.summarization]?.[metricResource?.metricKey],
        item: value || null,
      }
    }
  } else {
    throw new Error('Cannot have metrics as both the column and row.')
  }

  if (firstType === 'col') {
    for (let col = 0; col < extents.numColumns; col++) {
      const columnValues = firstGetter(data, col)
      for (let row = 0; row < extents.numRows; row++) {
        const item = secondGetter(columnValues, row)
        if (!results[col]) results[col] = []
        results[col][row] = item
      }
    }
  } else if (firstType === 'row') {
    for (let row = 0; row < extents.numRows; row++) {
      const rowValues = firstGetter(data, row)
      for (let col = 0; col < extents.numColumns; col++) {
        const item = secondGetter(rowValues, col)
        if (!results[col]) results[col] = []
        results[col][row] = item
      }
    }
  }

  return results
}
