import { cloneDeep } from 'lodash'

import {
  CourseConfiguration,
  Segment,
  SegmentMetadataSegment,
  SegmentStructureOverride,
  bakeCourseConfiguration,
  combineSegmentMetadata,
  combineStructureOverrides,
  orderSegments,
} from './courseConfiguration'

export interface Overrides {
  metadataOverrides: SegmentMetadataSegment[]
  structureOverrides: SegmentStructureOverride[]
}
export interface ApplyOverridesOpts extends Overrides {
  builtForeignSegments: Record<string, Segment>
}
export function applyOverrides(
  configuration: CourseConfiguration,
  { builtForeignSegments, metadataOverrides, structureOverrides }: ApplyOverridesOpts,
): CourseConfiguration {
  const newConfiguration = cloneDeep(configuration)

  // Then, apply the structure overrides...
  newConfiguration.segments = combineStructureOverrides(
    structureOverrides,
    newConfiguration.segments,
    builtForeignSegments,
  )

  // Then, add in the metadata overrides...
  newConfiguration.segments = combineSegmentMetadata(metadataOverrides, newConfiguration.segments)

  // Re-bake the configuration.
  const baked = bakeCourseConfiguration(newConfiguration)
  return {
    ...baked,
    segments: orderSegments(baked.segments || []),
  }
}
