import { Text, styled } from '@thesisedu/ui'
import React from 'react'

import { useRecordViewContext } from '../../RecordViewContext'
import { RecordState } from '../types'

function Disabled() {
  const { disabled } = useRecordViewContext(false) ?? {}
  return (
    <Container>
      <Text color={'secondary'} level={'s'}>
        {typeof disabled === 'string' ? disabled : 'Recording is disabled.'}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

export default {
  Component: Disabled,
} satisfies RecordState<object>
