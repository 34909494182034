import { fromGlobalId, getNewWeight } from '@thesisedu/feature-utils'
import { styled, s, LoadingIndicator, Table, Text, VSpaced } from '@thesisedu/ui'
import { Barcode, Edit } from '@thesisedu/ui/icons'
import { orderBy } from 'lodash'
import React from 'react'

import { useImportCourseViewWeightsSyncMutation } from '../queries/useImportCourseViewWeightsSyncMutation'
import { CourseViewSegmentFragment } from '../schema'

export interface CourseViewSegmentsTableProps {
  segments: CourseViewSegmentFragment[]
  courseViewId: string
}
export function CourseViewSegmentsTable({ segments, courseViewId }: CourseViewSegmentsTableProps) {
  const orderedSegments = React.useMemo(() => {
    return orderBy(segments, segment => segment.courseViewWeight ?? -Infinity, 'asc')
  }, [segments])
  const segmentsMap = React.useMemo(() => {
    return orderedSegments.reduce(
      (map, segment) => {
        map[segment.id] = segment
        return map
      },
      {} as Record<string, CourseViewSegmentFragment>,
    )
  }, [orderedSegments])
  const [updateWeights, { loading }] = useImportCourseViewWeightsSyncMutation()
  const { dragAndDropHooks } = Table.useDragAndDrop({
    getItems: keys =>
      [...keys].map(key => ({
        'text/plain': segmentsMap[key]?.name,
      })),
    onReorder(e) {
      if (e.target.dropPosition === 'on') return
      const segmentsToUpdate = getNewWeight({
        collection: orderedSegments,
        field: 'courseViewWeight',
        draggingIds: [...e.keys].map(k => k.toString()),
        dropPosition: e.target.dropPosition,
        targetId: e.target.key.toString(),
      })
      if (segmentsToUpdate) {
        const updates = Object.entries(segmentsToUpdate).map(entry => {
          return {
            segmentId: entry[0],
            weight: entry[1],
          }
        })

        updateWeights({
          variables: {
            input: {
              courseViewId,
              weights: updates,
            },
          },
        })
      }
    },
  })

  return (
    <VSpaced space={'xs'}>
      <div style={{ alignSelf: 'flex-end' }}>
        <LoadingIndicator.InlineLabeled
          label={'Saving order...'}
          style={{ opacity: loading ? 1 : 0 }}
        />
      </div>
      <Table dragAndDropHooks={dragAndDropHooks} aria-label={'Segments'} style={{ width: '100%' }}>
        <Table.Header>
          <Table.Column isRowHeader icon={<Barcode />}>
            ID
          </Table.Column>
          <Table.Column icon={<Edit />} style={{ width: '100%' }}>
            Name
          </Table.Column>
        </Table.Header>
        <Table.Body items={orderedSegments}>
          {item => (
            <TableRow $missingWeight={item.courseViewWeight == null}>
              <Table.Cell>
                <Text font={'code'} style={{ whiteSpace: 'nowrap' }}>
                  {fromGlobalId(item.id, true).id}
                </Text>
              </Table.Cell>
              <Table.Cell>{item.name}</Table.Cell>
            </TableRow>
          )}
        </Table.Body>
      </Table>
    </VSpaced>
  )
}

const TableRow = styled(Table.Row)<{ $missingWeight: boolean }>`
  ${s.ifs(props => props.$missingWeight)} {
    background: ${s.color('orange.element')};
    * {
      color: ${s.color('orange.text')} !important;
    }
  }
`
