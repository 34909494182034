import { Theme } from '@thesisedu/native'
import React from 'react'
import { keyframes } from 'styled-components'

import { styled } from '../styled'

export interface PulseIndicatorProps {
  size?: `@size-${keyof Theme['size']}` | number | string
  color?: string
}
export function PulseIndicator({ size = '0.5em', color = '@primary-color' }: PulseIndicatorProps) {
  return <Indicator size={size} color={color} />
}

const IndicatorAnimation = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  90% {
    opacity: 0;
    transform: scale(2);
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
`
const Indicator = styled.div<Required<PulseIndicatorProps>>`
  border-radius: 50%;
  width: ${props => props.theme[props.size] || props.size};
  height: ${props => props.theme[props.size] || props.size};
  background: ${props => props.theme[props.color] || props.color};
  display: block;
  position: relative;
  &::before {
    display: block;
    position: absolute;
    content: '';
    background: ${props => props.theme[props.color] || props.color};
    opacity: 0.1;
    inset: 0;
    animation: ${IndicatorAnimation} 2s infinite ease-out;
    border-radius: 50%;
  }
`
