import { EditOutlined } from '@ant-design/icons'

import { LongFormAnswer } from './LongFormAnswer'
import { LongFormContent } from './LongFormContent'
import { LongFormEdit } from './LongFormEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'LongForm',
  defaults: {
    name: 'Question',
    config: {
      answer: null,
    },
  },
  icon: EditOutlined,
  label: 'Long-Form Response',
  group: 'Short Answer',
  renderAnswerView: LongFormAnswer,
  renderContent: LongFormContent,
  renderEdit: LongFormEdit,
} as QuestionTypeResource
