import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { DATE_FORMATS, HSpaced } from '@thesisedu/web'
import { Table } from 'antd'
import Case from 'case'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteBadgeButton } from './DeleteBadgeButton'
import { AdminBadgeFragment, BadgesQuery, BadgesQueryVariables, BadgesDocument } from '../schema'

export function ListBadges() {
  return (
    <InfiniteQuery<AdminBadgeFragment, BadgesQuery, BadgesQueryVariables>
      document={BadgesDocument}
      resultPath={'badges'}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only',
      }}
      children={({ data, refetch }) => (
        <Table<AdminBadgeFragment>
          dataSource={data?.badges.edges.map(edge => edge.node) || []}
          rowKey={'id'}
          pagination={false}
          columns={[
            {
              title: 'Name',
              dataIndex: 'name',
              render: (name, record) => <Link to={record.id}>{name}</Link>,
            },
            {
              title: 'Created',
              dataIndex: 'createdAt',
              render: created => moment(created).format(DATE_FORMATS.FULL),
            },
            {
              title: 'Last Updated',
              dataIndex: 'updatedAt',
              render: updated => moment(updated).format(DATE_FORMATS.FULL),
            },
            { title: 'Event', dataIndex: 'event', render: event => Case.title(event) },
            {
              title: 'Total Earned',
              dataIndex: ['users', 'totalCount'],
              render: (_, record) => record.users.totalCount || 0,
            },
            {
              dataIndex: 'id',
              onCell: () => ({ style: { textAlign: 'right' } }),
              render: (_, record) => (
                <HSpaced>
                  <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
                    Edit
                  </Link>
                  <DeleteBadgeButton id={record.id} onComplete={() => refetch()} />
                </HSpaced>
              ),
            },
          ]}
        />
      )}
    />
  )
}
