import { LoadingOutlined } from '@ant-design/icons'
import { AntIconWrapper, Body, BodySmall, Space, styled } from '@thesisedu/web'
import { NavArrowRight } from 'iconoir-react'
import React from 'react'

import { warn } from '../log'

export interface SearchResultCalendarItemProps {
  type: string
  title: string
  color: string
  onClick: () => void | Promise<void>
}
export function SearchResultCalendarItem({
  type,
  title,
  color,
  onClick,
}: SearchResultCalendarItemProps) {
  const [loading, setLoading] = React.useState(false)
  return (
    <CalendarItemContainer
      color={color}
      className={loading ? 'loading' : undefined}
      onClick={async () => {
        setLoading(true)
        try {
          await onClick()
        } catch (err) {
          warn('error in calendar item click handler')
          warn(err)
        } finally {
          setLoading(false)
        }
      }}
    >
      <div>
        <BodySmall color={'@text-color-secondary'} children={type} />
        <Body children={title} />
      </div>
      <Space />
      <BodySmall color={'@text-color-secondary'}>
        {loading ? <LoadingOutlined /> : <AntIconWrapper children={<NavArrowRight />} />}
      </BodySmall>
    </CalendarItemContainer>
  )
}

const CalendarItemContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin: ${props => props.theme['@size-xxs']} 0;
  border-left: solid 2px ${props => props.theme[props.color]};
  padding-left: ${props => props.theme['@size-xxs']};
  cursor: pointer;
  transition: opacity 0.25s linear;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xxs']};
  }
  &.loading {
    pointer-events: none;
    opacity: 0.5;
  }
`
