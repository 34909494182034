import { Result as AntResult } from 'antd'
import React from 'react'

import { Empty, EmptyProps } from './Empty'
import { CheckCircle, DeleteCircle, InfoEmpty, WarningCircle } from '../../icons'
import { Color } from '../typography'

function ErrorResult(props: EmptyProps) {
  return (
    <Empty
      icon={<Color color={'@red'} children={<DeleteCircle />} />}
      title={'Something went wrong.'}
      {...props}
    />
  )
}

function WarningResult(props: EmptyProps) {
  return <Empty icon={<Color color={'@orange'} children={<WarningCircle />} />} {...props} />
}

function InfoResult(props: EmptyProps) {
  return <Empty icon={<Color color={'@blue'} children={<InfoEmpty />} />} {...props} />
}

function SuccessResult(props: EmptyProps) {
  return <Empty icon={<Color color={'@green'} children={<CheckCircle />} />} {...props} />
}

type ResultType = typeof AntResult & {
  Error: typeof ErrorResult
  Warning: typeof WarningResult
  Info: typeof InfoResult
  Success: typeof SuccessResult
}

const Result = AntResult as ResultType
Result.Error = ErrorResult
Result.Warning = WarningResult
Result.Info = InfoResult
Result.Success = SuccessResult

export { Result }

export { ResultProps } from 'antd'
