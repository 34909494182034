import { Dropdown } from '@thesisedu/ui'
import { Refresh } from '@thesisedu/ui/icons'
import React from 'react'

import { $isImageNode, ImageNode } from '../../../nodes/Image/ImageNode'
import {
  SettingsDropdownElementProps,
  useSettingsDropdownItem,
} from '../../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../../ui/SettingsDropdown/useNodeState'

function ClearImageElement({ editor, node }: SettingsDropdownElementProps<ImageNode>) {
  const [src] = useNodeState(
    editor,
    node,
    node => node.getSrc(),
    () => {},
  )
  return (
    <Dropdown.Item
      icon={<Refresh />}
      disabled={!src}
      onClick={() => {
        editor.update(() => {
          node.setSrc('')
        })
      }}
    >
      Upload New Image
    </Dropdown.Item>
  )
}

export function useClearImageSetting() {
  useSettingsDropdownItem({
    identifier: 'clear-image',
    group: 'image',
    weight: -6,
    filter(editor, node) {
      return $isImageNode(node)
    },
    element: ClearImageElement,
  })
}
