import { EnrichedBackgroundJob } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { BackgroundJobFragment } from '../schema'

export interface UserBackgroundJobContextValue {
  enrichedJobs: EnrichedBackgroundJob[]
  allJobs: BackgroundJobFragment[]
}
export const UserBackgroundJobContext = React.createContext<
  UserBackgroundJobContextValue | undefined
>(undefined)

export function useUserBackgroundJobContext(): UserBackgroundJobContextValue | undefined
export function useUserBackgroundJobContext(
  require: false,
): UserBackgroundJobContextValue | undefined
export function useUserBackgroundJobContext(require: true): UserBackgroundJobContextValue
export function useUserBackgroundJobContext(
  require?: boolean,
): UserBackgroundJobContextValue | undefined {
  const context = React.useContext(UserBackgroundJobContext)
  if (!context && require) {
    throw new Error('UserBackgroundJobContext is required, yet not provided.')
  }
  return context
}

export function useBackgroundJob(id: string) {
  const context = useUserBackgroundJobContext(true)
  const job = React.useMemo(
    () =>
      context.enrichedJobs.find(job => job.id === id) ?? context.allJobs.find(job => job.id === id),
    [context, id],
  )
  return job
}
