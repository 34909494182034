import { ClassStudentPair } from '@thesisedu/feature-classes-react'
import { Random } from '@thesisedu/feature-utils'
import { s, styled, Button, Separator, VSpaced, Well, HSpaced } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { GroupsEditorProps } from './GroupsEditor'
import { LoadGenerateGroupsConfiguration } from './generate/LoadGenerateGroupsConfiguration'
import { LoadFromPresetButton } from './presets/LoadFromPresetButton'

export interface NoGroupsProps {
  onChange: GroupsEditorProps['onChange']
  students: ClassStudentPair[]
  classId: string
}
export function NoGroups({ students, onChange, classId }: NoGroupsProps) {
  return (
    <Container>
      <Well paddingHorizontal={'l'} paddingVertical={'l'}>
        <VSpaced align={'center'}>
          <Button
            size={'large'}
            variant={['primary', 'chromeless']}
            children={'Manually create groups'}
            icon={<Plus />}
            onPress={() => {
              onChange?.({
                [Random.id()]: {
                  name: 'Group 1',
                  students: [],
                },
              })
            }}
          />
          <Separator children={'or'} top={'xs'} bottom={'xs'} />
          <LoadGenerateGroupsConfiguration onChange={onChange} students={students} />
        </VSpaced>
      </Well>
      <Well paddingHorizontal={'l'} paddingVertical={'m'}>
        <HSpaced justify={'center'}>
          <LoadFromPresetButton
            onChange={v => onChange?.(v)}
            classId={classId}
            variant={['primary', 'chromeless']}
          />
        </HSpaced>
      </Well>
    </Container>
  )
}

const Container = styled.div`
  margin: auto;
  margin-top: ${s.size('xl')};
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: ${s.size('l')};
  width: 400px;
`
