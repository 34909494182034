import { SegmentMetadata } from '@thesisedu/feature-course-types'
import React, { useContext } from 'react'

import { ClassCourseFragment } from '../schema'
import { CourseConfiguration } from '../types'

export interface CourseContextValue {
  bakedCourseConfiguration: CourseConfiguration
  segmentMetadata?: SegmentMetadata
  courseVersion: ClassCourseFragment['courseVersion']
}
export const CourseContext = React.createContext<CourseContextValue | undefined>(undefined)

export interface CourseContextProviderProps {
  class: ClassCourseFragment
}
export function CourseContextProvider({
  children,
  class: cls,
}: React.PropsWithChildren<CourseContextProviderProps>) {
  return (
    <CourseContext.Provider
      value={{
        bakedCourseConfiguration: cls.bakedCourseConfiguration,
        segmentMetadata: cls.segmentMetadata || undefined,
        courseVersion: cls.courseVersion,
      }}
      children={children}
    />
  )
}

export function useCourseContext(): CourseContextValue | undefined
export function useCourseContext(require: false): CourseContextValue | undefined
export function useCourseContext(require: true): CourseContextValue
export function useCourseContext(require?: boolean): CourseContextValue | undefined
export function useCourseContext(require?: boolean): CourseContextValue | undefined {
  const context = useContext(CourseContext)
  if (!context && require) {
    throw new Error('CourseContext is required, yet not provided.')
  }
  return context
}
