import React from 'react'

import { DevToolsContextProvider } from './context'
import { useFeatureRoot } from '..'
import { RootDevToolsInstaller } from '../ReactFeature'
import { debug, error } from '../log'

export interface DevToolsWrapperProps {
  shouldUse: boolean
  getInstaller: () => null | Promise<RootDevToolsInstaller>
}
export function DevToolsWrapper({
  children,
  shouldUse,
  getInstaller,
}: React.PropsWithChildren<DevToolsWrapperProps>) {
  const root = useFeatureRoot()
  const [loaded, setLoaded] = React.useState(!shouldUse)
  const [portal, setPortal] = React.useState<React.ReactPortal | undefined>(undefined)
  React.useEffect(() => {
    if (shouldUse) {
      debug('loading DevTools')
      const install = async () => {
        const installer = await getInstaller()
        if (installer) {
          setPortal(installer(root))
        }
      }
      install()
        .catch(err => {
          error('error installing devtools')
          error(err)
        })
        .finally(() => {
          setLoaded(true)
        })
    }
  }, [])

  return shouldUse ? (
    <DevToolsContextProvider>
      {portal}
      {loaded ? children : null}
    </DevToolsContextProvider>
  ) : (
    <>{children}</>
  )
}
