import { Button, Empty, getSize, PageSection, Popconfirm, styledContainer } from '@thesisedu/react'
import React from 'react'

import { UploadList } from './UploadList'
import { UploadManagerContext, UploadManagerStateContext } from './UploadManagerContext'
import { sortUploads } from './helpers'
import { Upload, UploadStatus } from './types'

export function UploadsManager() {
  const uploadManager = React.useContext(UploadManagerContext)
  const state = React.useContext(UploadManagerStateContext)
  const uploads = sortUploads(state && state.uploads ? state.uploads : [])
  if (uploads.length > 0) {
    const uploadingUploads = uploads.filter(
      upload => upload.currentStatus !== UploadStatus.Complete,
    )
    const completeUploads = uploads.filter(upload => upload.currentStatus === UploadStatus.Complete)
    const onUploadClick = (upload: Upload) => {
      if (uploadManager && upload.currentStatus !== UploadStatus.Complete) {
        uploadManager.confirmAndRemoveUpload(upload.mediaId)
      }
    }
    const uploadingContent = uploadingUploads.length ? (
      <PageSection title={'Uploading'}>
        <UploadList uploads={uploadingUploads} onUploadClick={onUploadClick} />
      </PageSection>
    ) : null
    const completeContent = completeUploads.length ? (
      <PageSection title={'Complete'}>
        <UploadList uploads={completeUploads} onUploadClick={onUploadClick} />
      </PageSection>
    ) : null

    return (
      <>
        {uploadingContent}
        {completeContent}
        <BottomContainer>
          <Popconfirm
            title={
              'Are you sure you want to clear all uploads? You will not be able to recover your recordings.'
            }
            cancelText={'No, keep'}
            okText={'Yes, delete'}
            okType={'danger'}
            onConfirm={async () => {
              if (uploadManager) {
                await uploadManager.clearUploads()
              }
            }}
          >
            <Button type={'link'} size={'small'} danger children={'Clear Uploads'} />
          </Popconfirm>
        </BottomContainer>
      </>
    )
  } else {
    return <Empty description={'There are no recent uploads.'} />
  }
}

const BottomContainer = styledContainer`
  margin-top: ${props => getSize(props.theme, '@size-l')};
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
`
