import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Popconfirm, message } from 'antd'
import React from 'react'

import { DistrictDocument, DistrictsDocument } from './schema'

export interface DeleteDistrictButtonProps {
  children: React.ReactElement<{ onClick?: () => any }>
  districtId: string
  goBack?: boolean
}
export const DeleteDistrictButton: React.FC<React.PropsWithChildren<DeleteDistrictButtonProps>> = ({
  children,
  districtId,
  goBack,
}) => {
  const [deleteDistrict] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that district.',
    refetchQueries: [
      { query: DistrictsDocument },
      { query: DistrictDocument, variables: { id: districtId } },
    ],
    onCompleted: () => {
      message.success('District deleted successfully.')
      if (goBack) {
        window.history.back()
      }
    },
    awaitRefetchQueries: true,
    variables: { id: districtId },
  })
  return (
    <Popconfirm
      title={'Are you sure you want to delete this district?'}
      onConfirm={() => deleteDistrict()}
      okText={'Yes, delete'}
      okType={'danger'}
      cancelText={'No, keep'}
      placement={'topRight'}
    >
      {children}
    </Popconfirm>
  )
}
