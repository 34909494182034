import { useSaveAssignmentSubmissionMutation } from '../queries/useSaveAssignmentSubmissionMutation'
import { BasicAssignmentSubmissionFragment } from '../schema'

export interface UseCreateNewSubmissionOpts {
  onCreated: (submission: BasicAssignmentSubmissionFragment) => void
  classId: string
  assignmentId: string
}
export function useCreateNewSubmission({
  onCreated,
  classId,
  assignmentId,
}: UseCreateNewSubmissionOpts): [() => Promise<void>, { loading: boolean }] {
  const [save, { loading }] = useSaveAssignmentSubmissionMutation({
    onCompleted: data => {
      const submission = data.saveAssignmentSubmission.assignmentSubmission
      onCreated(submission)
    },
    refetchQueries: ['assignmentSubmissions'],
    awaitRefetchQueries: true,
  })

  return [
    async () => {
      save({
        variables: {
          classId,
          includeConfiguration: true,
          input: {
            classId,
            id: assignmentId,
            submissionData: {},
            createNew: true,
          },
        },
      })
    },
    { loading },
  ]
}
