import { InteractionProps } from '@thesisedu/feature-interactions-react'
import { InteractionListItem } from '@thesisedu/feature-interactions-react/web'
import { ModelMetadata, useModelLink } from '@thesisedu/feature-react'
import React from 'react'

export function CommentInteraction({ interaction }: InteractionProps) {
  const link = useModelLink({ id: interaction.reference || undefined })
  const modelFragment = interaction.reference ? { id: interaction.reference } : undefined
  return (
    <InteractionListItem
      fragment={interaction}
      linkProps={link ? { to: link } : undefined}
      targetChildren={
        <span>
          {interaction.user.name || interaction.user.username} commented on your{' '}
          <ModelMetadata instance={modelFragment} />
        </span>
      }
      userChildren={
        <span>
          commented on <ModelMetadata instance={modelFragment} includeAAn />
        </span>
      }
    />
  )
}
