import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Button, Popconfirm, Tooltip } from 'antd'
import React from 'react'

import { useDeleteAttachmentMutation } from './queries/useDeleteAttachmentMutation'
import { AttachmentDeleteProps } from './types'

export const AttachmentDelete: React.FC<React.PropsWithChildren<AttachmentDeleteProps>> = ({
  attachmentId,
  onDelete,
}) => {
  const [deleteAttachment, { loading }] = useDeleteAttachmentMutation({
    onError: onMutationError('There was an error deleting that attachment.'),
    variables: {
      input: {
        id: attachmentId,
      },
    },
  })
  return (
    <Tooltip mouseEnterDelay={1} title={'Delete Attachment'} placement={'topRight'}>
      <Popconfirm
        title={'Are you sure you want to delete this file?'}
        okText={'Yes, delete'}
        okType={'danger'}
        cancelText={'No, keep'}
        placement={'topRight'}
        onConfirm={() => {
          if (onDelete) {
            return onDelete()
          } else {
            return deleteAttachment()
          }
        }}
      >
        <Button type={'link'} icon={<DeleteOutlined />} loading={loading} />
      </Popconfirm>
    </Tooltip>
  )
}
