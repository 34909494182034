import { Dropdown, useToast } from '@thesisedu/ui'
import { NavArrowDown, Star } from '@thesisedu/ui/icons'
import React from 'react'

import { SubmissionWithMediaFragment } from './types'
import { warn } from '../log'
import { useSubmitAssignmentMutation } from '../queries/useSubmitAssignmentMutation'

export interface AssignmentSubmissionActionsDropdownProps {
  submission: SubmissionWithMediaFragment
  onUpdate: () => void
}
export function AssignmentSubmissionActionsDropdown({
  submission,
  onUpdate,
}: AssignmentSubmissionActionsDropdownProps) {
  const toast = useToast()
  const [submit] = useSubmitAssignmentMutation({
    onCompleted() {
      onUpdate()
    },
    variables: {
      classId: submission.classId,
      useCurrentSubmissions: true,
      studentClassId: submission.classId,
      orderBy: 'createdAt',
      input: {
        id: submission.id,
        force: true,
      },
    },
    fetchPolicy: 'no-cache',
    refetchQueries: ['assignmentSubmissions'],
    awaitRefetchQueries: true,
  })
  return (
    <Dropdown.Container>
      <Dropdown.Button size={'small'} icon={<NavArrowDown />} />
      <Dropdown.Menu align={'end'}>
        <Dropdown.Item
          icon={<Star />}
          disabled={!!submission.submittedAt}
          onClick={async e => {
            e.stopPropagation()
            const updateToast = toast({ loading: true, title: 'Submitting attempt...' })
            try {
              await submit()
              updateToast({ loading: false, status: 'success', title: 'Submitted!' })
            } catch (err: any) {
              warn('error while submitting attempt %O', err)
              updateToast({ loading: false, status: 'error', title: 'Error submitting attempt.' })
            }
          }}
        >
          Submit Attempt
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
