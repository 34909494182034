import { Text, Form } from '@thesisedu/ui'
import React from 'react'

import { ConfigureCellColorsProps } from './ConfigureCellColors'

export function ConfigurePercent({ value }: ConfigureCellColorsProps) {
  return (
    <Form.NumberField
      name={'percent'}
      label={'Percentage'}
      required
      description={
        <>
          Values in the{' '}
          <strong>
            <Text level={null} inline color={'blue'}>
              top
            </Text>
          </strong>{' '}
          {value.percent}% will be highlighted in{' '}
          <Text level={null} inline color={'blue'}>
            blue.
          </Text>
          <br />
          Values in the{' '}
          <strong>
            <Text level={null} inline color={'red'}>
              bottom
            </Text>
          </strong>{' '}
          {value.percent}% will be highlighted in{' '}
          <Text level={null} inline color={'red'}>
            red.
          </Text>
        </>
      }
      minValue={0}
      min={0}
      maxValue={50}
      max={50}
      formatOptions={{ maximumFractionDigits: 0 }}
    />
  )
}
