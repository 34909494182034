import { SegmentedControl } from '@thesisedu/ui'
import React from 'react'

import { PendingStudentClassState } from '../schema'

const StatesValues: [PendingStudentClassState | undefined, string][] = [
  [undefined, 'All'],
  [PendingStudentClassState.Pending, 'Pending'],
  [PendingStudentClassState.Approved, 'Approved'],
  [PendingStudentClassState.Declined, 'Declined'],
]

export interface ClassApprovalStateSelectProps {
  state?: PendingStudentClassState
  onStateChange: (state?: PendingStudentClassState) => void
}
export function ClassApprovalStateSelect({ state, onStateChange }: ClassApprovalStateSelectProps) {
  return (
    <SegmentedControl size={'small'}>
      {StatesValues.map(([value, label]) => (
        <SegmentedControl.Item
          key={label}
          selected={value === state}
          onPress={() => onStateChange(value)}
        >
          {label}
        </SegmentedControl.Item>
      ))}
    </SegmentedControl>
  )
}
