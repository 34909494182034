import { styled, AntIconWrapper } from '@thesisedu/web'
import { Drawer, Button } from 'antd'
import { MusicDoubleNote } from 'iconoir-react'
import React from 'react'

import { Keyboard } from './Keyboard'
import { useKeyboardButtonContext } from './KeyboardButtonContext'

export function KeyboardButton() {
  const { buttonHidden } = useKeyboardButtonContext(true)
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Drawer
        placement={'bottom'}
        height={400}
        title={'Keyboard'}
        visible={visible && !buttonHidden}
        onClose={() => setVisible(false)}
      >
        <Keyboard dontHideButton />
      </Drawer>
      <Container visible={!buttonHidden}>
        <Button
          shape={'circle'}
          icon={<AntIconWrapper children={<MusicDoubleNote />} />}
          size={'large'}
          onClick={e => {
            e.preventDefault()
            setVisible(v => !v)
          }}
        />
      </Container>
    </>
  )
}

const Container = styled.div<{ visible?: boolean }>`
  position: fixed;
  bottom: ${props => props.theme['@size-s']};
  left: ${props => props.theme['@size-s']};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.25s linear;
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  z-index: 1001;
`
