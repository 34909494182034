import { Empty } from '@thesisedu/react'
import { styled, s, Checkbox, Text, VSpaced, Block, Button } from '@thesisedu/ui'
import { Link, MagicWand } from '@thesisedu/ui/icons'
import React from 'react'

import { getAttachProviderClassStep } from './attachProviderClass'
import { ClassSyncProviderClassFragment, SiteClassSyncProviderFragment } from '../../schema'
import { StepHeader } from '../StepHeader'
import { SetupStep, getStepFactory } from '../types'

export interface ClassCheckboxProps {
  name: string
  suggested?: boolean
  attached?: boolean
  checked?: boolean
  onCheckedChange: (checked: boolean) => void
}
export function ClassCheckbox({
  name,
  attached,
  suggested,
  checked,
  onCheckedChange,
}: ClassCheckboxProps) {
  return (
    <CheckboxContainer $checked={checked}>
      <Checkbox checked={checked} onCheckedChange={onCheckedChange} size={'small'} />
      <Text>{name}</Text>
      {suggested ? (
        <Text color={'primary'} level={'s'} style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}>
          <MagicWand style={{ display: 'inline' }} /> Suggested
        </Text>
      ) : attached ? (
        <Text color={'secondary'} level={'s'} style={{ whiteSpace: 'nowrap', marginLeft: 'auto' }}>
          <Link style={{ display: 'inline' }} /> Already Attached
        </Text>
      ) : null}
    </CheckboxContainer>
  )
}
const CheckboxContainer = styled.label<{ $checked?: boolean }>`
  padding: ${s.size('xs')} ${s.size('s')};
  border-radius: ${s.var('radii.1')};
  background: ${props => (props.$checked ? s.color('subtle') : 'transparent')};
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  &:hover {
    background: ${s.color('subtle')};
  }
`

export interface ListProviderClassesContext {
  classes: ClassSyncProviderClassFragment[]
  provider: SiteClassSyncProviderFragment
}
export default {
  size: { width: 750, height: 450 },
  group: 'attachClass',
  Component({ cls, context, transition }) {
    const [selectedClassId, setSelectedClassId] = React.useState<string | null>(null)
    React.useEffect(() => {
      const suggestedClass = context.classes.find(cls => cls.isSuggested)
      if (suggestedClass) {
        setSelectedClassId(suggestedClass?.id ?? null)
      }
    }, [context.classes])
    return (
      <>
        <StepHeader
          title={'Attach a Class'}
          description={
            <>
              Choose from one of your {context.provider.name} classes below to connect to{' '}
              <strong>{cls.name}.</strong>
            </>
          }
        />
        {context.classes.length ? (
          <VSpaced space={false} style={{ flex: 1 }}>
            <div style={{ flex: 1, overflow: 'auto', maxHeight: 225 }}>
              {context.classes.map(cls => {
                return (
                  <ClassCheckbox
                    key={cls.id}
                    suggested={!!cls.isSuggested}
                    attached={!!cls.isAttached}
                    name={cls.name}
                    checked={selectedClassId === cls.id}
                    onCheckedChange={checked => {
                      if (checked) {
                        setSelectedClassId(cls.id)
                      }
                    }}
                  />
                )
              })}
            </div>
            <Block top left paddingTop={'s'}>
              <Button
                variant={'primary'}
                children={'Attach and Continue'}
                disabled={!selectedClassId}
                onPress={() => {
                  const selectedClass = context.classes.find(cls => cls.id === selectedClassId)
                  if (selectedClass) {
                    transition(
                      getAttachProviderClassStep({
                        provider: context.provider,
                        providerClassId: selectedClass.id,
                        providerClassName: selectedClass.name,
                      }),
                    )
                  }
                }}
              />
            </Block>
          </VSpaced>
        ) : (
          <Empty description={'We could not find any classes.'} />
        )}
      </>
    )
  },
} satisfies SetupStep<ListProviderClassesContext>
export const getListProviderClasses =
  getStepFactory<ListProviderClassesContext>('listProviderClasses')
