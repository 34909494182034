import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'

export interface ShuffleOpts {
  groups?: boolean
  leaders?: boolean
}
export function shuffle(
  groups: GroupsConfiguration,
  { groups: shuffleGroups, leaders: shuffleLeaders }: ShuffleOpts,
): GroupsConfiguration {
  let allStudents = Object.values(groups)
    .flatMap(value => value.students)
    .sort(() => Math.random() - 0.5)
  if (!shuffleLeaders) {
    allStudents = allStudents.filter(s => !Object.values(groups).some(g => g.leader === s))
  }
  const newGroups: GroupsConfiguration = {}
  const groupKeys = Object.keys(groups)
  const groupStudents: Record<string, string[]> = {}
  let currentGroup = 0
  if (shuffleGroups) {
    for (const student of allStudents) {
      if (!groupStudents[groupKeys[currentGroup]]) groupStudents[groupKeys[currentGroup]] = []
      groupStudents[groupKeys[currentGroup]].push(student)
      currentGroup++
      if (currentGroup >= groupKeys.length) currentGroup = 0
    }
  }
  for (const groupKey of Object.keys(groups)) {
    let students = shuffleGroups ? groupStudents[groupKey] : groups[groupKey].students
    const leader = groups[groupKey].leader
    if (!shuffleLeaders && leader) students = [leader, ...students]
    newGroups[groupKey] = {
      ...groups[groupKey],
      students,
      leader: shuffleLeaders ? [...students].sort(() => Math.random() - 0.5)[0] : leader,
    }
  }

  return newGroups
}
