import React from 'react'

export interface BaseRecordingState {
  type: string
}
export interface CountdownRecordingState extends BaseRecordingState {
  type: 'countdown'
}
export interface RecordingRecordingState extends BaseRecordingState {
  type: 'recording'
  startTime: number
}
export interface PreparingRecordingState extends BaseRecordingState {
  type: 'preparing'
}
export interface StoppingRecordingState extends BaseRecordingState {
  type: 'stopping'
}
export interface PausedRecordingState extends BaseRecordingState {
  type: 'paused'
}
export type RecordingState =
  | CountdownRecordingState
  | RecordingRecordingState
  | PreparingRecordingState
  | StoppingRecordingState
  | PausedRecordingState

export interface RecordStateContextValue {
  state?: RecordingState
  setState: (recordingState?: RecordingState) => void
}
export const RecordStateContext = React.createContext<RecordStateContextValue | undefined>(
  undefined,
)

export interface RecordStateProviderProps {
  children: React.ReactNode
}
export function RecordStateProvider({ children }: RecordStateProviderProps) {
  const [state, setState] = React.useState<RecordingState>()
  return <RecordStateContext.Provider value={{ state, setState }} children={children} />
}

export function useRecordStateContext(): RecordStateContextValue | undefined
export function useRecordStateContext(require: false): RecordStateContextValue | undefined
export function useRecordStateContext(require: true): RecordStateContextValue
export function useRecordStateContext(require?: boolean): RecordStateContextValue | undefined {
  const context = React.useContext(RecordStateContext)
  if (!context && require) {
    throw new Error('RecordStateContext is required, yet not provided.')
  }
  return context
}
