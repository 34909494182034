import React from 'react'

import { KeyboardProps } from '../Keyboard'

export interface ModeContentsProps extends KeyboardProps {}

export interface Mode {
  id: string
  name: string
  icon: string | React.FC<React.PropsWithChildren<any>>
  contents: (props: ModeContentsProps) => React.ReactElement
  large?: boolean
  types: KeyboardType[]
  setInstrument?: string
}

export enum KeyboardType {
  Note8 = '8-note',
  Note25 = '25-note',
}
