import {
  BodyLarge,
  BodySmall,
  Collapse,
  getSize,
  styledContainer,
  Checkbox,
} from '@thesisedu/react'
import pluralize from 'pluralize'
import React from 'react'

import { StudentSelectorProps } from './StudentSelector'
import { SimpleMultiStudentSelect } from '../teacher/SimpleMultiStudentSelect'
import { ClassStudentPair } from '../types'
import { ClassWithStudents } from '../useAllStudentsQuery'

const Container = styledContainer`
  flex: 1;
`

export interface StudentSelectorCollapseHeaderProps {
  cls: ClassWithStudents
}
export function StudentSelectorCollapseHeader({ cls }: StudentSelectorCollapseHeaderProps) {
  return (
    <Container>
      <BodyLarge>{cls.name}</BodyLarge>
      <BodySmall color={'@text-color-secondary'}>
        {pluralize('student', cls.students.edges.length, true)}
      </BodySmall>
    </Container>
  )
}

export interface StudentSelectorCollapsePanelProps
  extends Pick<StudentSelectorProps, 'value' | 'onChange' | 'single'> {
  cls: ClassWithStudents
  [key: string]: any
}
export function StudentSelectorCollapsePanel({
  cls,
  value,
  onChange,
  single,
  ...rest
}: StudentSelectorCollapsePanelProps) {
  const classHasStudents = !!cls.students.edges.length
  return (
    <Collapse
      {...rest}
      key={cls.id}
      panelKey={cls.id}
      header={<StudentSelectorCollapseHeader cls={cls} />}
      rightContent={
        single || !classHasStudents ? undefined : (
          <SelectClassCheckbox cls={cls} selectedPairs={value} onChange={onChange} />
        )
      }
    >
      <ContentWrapper>
        <SimpleMultiStudentSelect
          value={value?.filter(v => v.classId === cls.id).map(v => v.studentId)}
          classId={cls.id}
          single={single}
          onChange={selectedStudentIds => {
            onChange([
              ...(value || []).filter(v => v.classId !== cls.id),
              ...(selectedStudentIds || []).map(studentId => ({
                studentId,
                classId: cls.id,
              })),
            ])
          }}
        />
      </ContentWrapper>
    </Collapse>
  )
}

export interface SelectClassCheckboxProps {
  cls: ClassWithStudents
  selectedPairs?: ClassStudentPair[]
  onChange: (selectedPairs: ClassStudentPair[]) => void
}
export function SelectClassCheckbox({
  cls,
  selectedPairs = [],
  onChange,
}: SelectClassCheckboxProps) {
  const selectedCount = selectedPairs.filter(pair => pair.classId === cls.id).length
  const checked = selectedCount === cls.students.edges.length
  return (
    <Checkbox
      checked={checked}
      indeterminate={selectedCount > 0 && !checked}
      onClick={e => e.stopPropagation()}
      onChange={e => {
        e.stopPropagation()
        const checked = e.target.checked
        if (checked) {
          const newSelectedPairs = [...selectedPairs]
          for (const edge of cls.students.edges) {
            const existing = newSelectedPairs.find(
              p => p.classId === cls.id && p.studentId === edge.node.id,
            )
            if (!existing) {
              newSelectedPairs.push({
                studentId: edge.node.id,
                classId: cls.id,
              })
            }
          }
          onChange(newSelectedPairs)
        } else {
          onChange(selectedPairs.filter(p => p.classId !== cls.id))
        }
      }}
    />
  )
}

const ContentWrapper = styledContainer`
  padding: ${props => getSize(props.theme, '@size-xs')} 0 0 ${props =>
    getSize(props.theme, '@size-xs')};
`
