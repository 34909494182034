import { ClassSyncProviderResource } from '@thesisedu/feature-class-sync-react'
import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

import { SchoologyReactFeatureOptions } from './types'

export class SchoologyReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-schoology-react'
  static path = __dirname
  static requires = []
  public options!: SchoologyReactFeatureOptions

  public reactResources() {
    if (!isNative) {
      require('./hooks/routes').default(this)
      require('./resources/SAMLButton').default(this)
    }
    const { AuthProviderHandler } = require('./web/AuthProviderHandler')
    this.resources.addResource<AuthProviderHandlerResource>({
      type: 'AuthProviderHandler',
      identifier: 'schoology',
      name: 'Schoology',
      headerLogoPath: require('../assets/large.svg').default,
      squareLogoPath: require('../assets/icon.svg').default,
      render: payload => <AuthProviderHandler {...payload} />,
    })
    this.resources.addResource<ClassSyncProviderResource>({
      type: 'ClassSyncProvider',
      identifier: 'schoology',
      logos: {
        horizontal: require('../assets/large.svg').default,
        square: require('../assets/icon.svg').default,
      },
    })
  }
}

export const schoologyReactFeature: ReactUse<SchoologyReactFeatureOptions> = opts => [
  SchoologyReactFeature,
  opts,
]
