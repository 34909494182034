import { Random } from '@thesisedu/feature-utils'
import { Button, styled, s, Well } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { useGroupsContext } from './GroupsContext'

export function AddGroup() {
  const { groups, onChange } = useGroupsContext(true)
  return (
    <StyledWell>
      <Button
        variant={['primary', 'chromeless']}
        icon={<Plus />}
        children={'Add Group'}
        onPress={() => {
          onChange({
            ...groups,
            [Random.id()]: {
              name: 'Group',
              students: [],
            },
          })
        }}
      />
    </StyledWell>
  )
}

const StyledWell = styled(Well)`
  border-color: ${s.color('primary')};
  display: flex;
  justify-content: center;
  align-items: center;
`
