export function getStuckHeight(exclude: string[] = []) {
  const elements = document.querySelectorAll('[data-sticky]')
  let sum = 0
  elements.forEach(element => {
    const htmlElement = element as HTMLElement
    if (htmlElement.dataset.sticky && exclude.includes(htmlElement.dataset.sticky)) return
    const rect = htmlElement.getBoundingClientRect()
    const computed = window.getComputedStyle(htmlElement)
    if (computed.display === 'none') return
    const top = parseFloat(computed.top.replace('px', ''))
    sum += rect.height + (isNaN(top) ? 0 : top)
  })

  return sum
}
