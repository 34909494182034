import React from 'react'

export interface ClickEvent {
  preventDefault: () => void
}
export interface ClickableContainerProps {
  onClick?: (e: ClickEvent) => any
  disabled?: boolean
  [key: string]: any
}
function _ClickableContainer(
  { disabled, ...props }: React.PropsWithChildren<ClickableContainerProps>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      {...props}
      ref={ref}
      style={{
        ...props.style,
        cursor: 'pointer',
        ...(disabled ? { pointerEvents: 'none', cursor: 'default' } : {}),
      }}
    />
  )
}
/** @deprecated */
export const ClickableContainer = React.forwardRef(_ClickableContainer)
