import { MediaType } from '../schema'

export interface AdditionalFields {
  [key: string]: string
}

export interface UploadPayload {
  mediaId: string
  mediaType: MediaType
  signedUrl: string
  /** fileBlob is only used in web contexts */
  fileBlob?: Blob
  /** filePath is only used in native contexts */
  filePath?: string
  signedUrlPath: string
  additionalFields?: AdditionalFields
  label: string
  contentType: string
  processedCallback?: () => void
  statusCallback?: (status: UploadStatus) => void
}

export enum UploadStatus {
  Waiting = 'waiting',
  WaitingConnection = 'waiting-connection',
  Submitting = 'submitting',
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Processing = 'processing',
  Failed = 'failed',
  Complete = 'complete',
}
export interface Upload extends UploadPayload {
  completedDate?: string
  addedDate: string
  currentStatus: UploadStatus
  uploaded?: number
  /** The number of times we've attempted the upload. Currently only used in native contexts. */
  attempts?: number
}

export interface PartialUpload extends Partial<Upload> {
  currentStatus: Upload['currentStatus']
}

export interface UploadManagerState {
  uploads: Upload[]
}

export interface UploadManagerBlobMap {
  [mediaId: string]: Blob
}

export type UploadManagerListener = (state: UploadManagerState) => any
export interface IUploadManager {
  addUpload(upload: UploadPayload): Promise<void>
  retryUpload(mediaId: string): Promise<void>
  removeUpload(mediaId: string): Promise<void>
  addListener(fn: UploadManagerListener): void
  removeListener(fn: UploadManagerListener): void
}
