import { Modal, ModalProps } from '@thesisedu/web'
import { Form, message, Button } from 'antd'
import { omit } from 'lodash'
import React from 'react'

import { SubscriptionForm } from './SubscriptionForm'
import { useCreateSubscriptionMutation } from './queries/useCreateSubscriptionMutation'
import { LicenseSubscriptionFragment } from './schema'

export interface CreateSubscriptionModalProps extends Partial<ModalProps> {
  onCancel: () => void
  onCreated?: (subscription: LicenseSubscriptionFragment) => void
}
export function CreateSubscriptionModal({
  onCancel: _onCancel,
  onCreated,
  ...props
}: CreateSubscriptionModalProps) {
  const [form] = Form.useForm()
  const onCancel = () => {
    form.resetFields()
    _onCancel()
  }
  const [createLicenseSubscription, { loading }] = useCreateSubscriptionMutation({
    onCompleted: data => {
      message.success('Subscription created successfully!')
      onCancel()
      if (onCreated) {
        onCreated(data.createLicenseSubscription.subscription)
      }
    },
  })

  return (
    <Modal
      title={'Create Subscription'}
      style={{
        maxWidth: '90vw',
      }}
      width={1000}
      {...props}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} type={'primary'} onClick={form.submit} loading={loading}>
          Create Subscription
        </Button>,
      ]}
    >
      <SubscriptionForm
        form={form}
        showUserClass
        onFinish={values => {
          return createLicenseSubscription({
            variables: {
              input: {
                ...omit(values, ['class', 'user', 'license']),
                classId: values.class ? values.class.id : undefined,
                userId: values.user ? values.user.id : undefined,
                licenseId: values.license ? values.license.id : undefined,
              },
            },
          })
        }}
      />
    </Modal>
  )
}
