import { useResource } from '@thesisedu/feature-react'

import { ReportDimensionResource } from '../../types'

export function useDimensionContexts(): Record<string, any> {
  const dimensions = useResource<ReportDimensionResource<any, any>>('ReportDimension')
  return dimensions.reduce((acc, dimension) => {
    return {
      ...acc,
      [dimension.identifier]: dimension.graph.useContext(),
    }
  }, {})
}
