import { WebRoutesHook } from '@thesisedu/feature-web'
import React from 'react'
import { Route } from 'react-router-dom'

import { GeneratePDFPage } from '../../GeneratePDFPage'
import { PdfGenerationReactFeature } from '../../PdfGenerationReactFeature'

export default function (feature: PdfGenerationReactFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [
      <Route key={'generate-pdf'} path={'generate-pdf/:id'} element={<GeneratePDFPage />} />,
      ...routes,
    ]
  })
}
