import { Format } from '@thesisedu/feature-utils'
import { VSpaced, Form, useToast } from '@thesisedu/ui'
import React from 'react'

import { SelectDeviceContext } from '.'
import { getFileDurationInSeconds } from './getFileDuration'
import { useRecordViewContext } from '../../RecordViewContext'
import { getReview } from '../review'
import { RecordStateProps } from '../types'

export function UploadExisting({ onTransition }: RecordStateProps<SelectDeviceContext>) {
  const form = Form.useForm<{ file?: File }>()
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const { maxDurationSeconds, hideAudio, hideVideo } = useRecordViewContext(true)
  return (
    <Form
      form={form}
      onValuesChange={async values => {
        if (values.file) {
          setLoading(true)
          try {
            const { duration: durationInSeconds, hasVideoTrack } = await getFileDurationInSeconds(
              values.file,
            )
            if (hasVideoTrack && hideVideo) {
              toast({
                title: "The file you've selected is invalid.",
                description: 'It must be an audio-only file.',
                status: 'error',
              })
              form.reset()
            } else if (durationInSeconds == null) {
              toast({
                title: "The file you've selected is invalid.",
                description:
                  'It must be a valid video or audio file ending in .mp4, .webm, .ogg, .mp3, or .wav.',
                status: 'error',
              })
              form.reset()
            } else if (maxDurationSeconds && durationInSeconds > maxDurationSeconds) {
              toast({
                title: "The file you've selected is too long.",
                description: `It cannot be longer than ${Format.duration(maxDurationSeconds)}.`,
                status: 'error',
              })
              form.reset()
            } else {
              onTransition(
                getReview({
                  durationInMS: durationInSeconds * 1000,
                  streamHasVideo: values.file.type.startsWith('video/'),
                  stream: values.file,
                }),
              )
            }
          } finally {
            setLoading(false)
          }
        }
      }}
    >
      <VSpaced space={'s'}>
        <Form.FileField
          name={'file'}
          style={{ width: '100%' }}
          variant={'primary'}
          size={'large'}
          loading={loading}
          accept={hideVideo ? 'audio/*' : hideAudio ? 'video/*' : 'video/*,audio/*'}
        />
      </VSpaced>
    </Form>
  )
}
