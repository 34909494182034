import { filterSegments, SegmentCalculationOptions } from '@thesisedu/feature-course-types'
import { SegmentTree, useCourseContext } from '@thesisedu/feature-courses-react'
import { useFlag } from '@thesisedu/react'
import { HelpCircle } from '@thesisedu/react/icons'
import { AntIconWrapper, Block, Body, BodyLarge, Modal } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

export interface SourceSegmentSelectProps {
  config?: SegmentCalculationOptions
  segmentIds: string[]
  onConfigChange: (config: SegmentCalculationOptions) => void | Promise<void>
  children: any
}
export function SourceSegmentSelect({
  config,
  segmentIds,
  onConfigChange,
  children,
}: SourceSegmentSelectProps) {
  const [visible, setVisible] = React.useState(false)
  const [loading, setLoading] = React.useState<string | undefined>()
  const [helpVisible, setHelpVisible] = useFlag('SourceSegmentSelect:helpVisible', true)
  const [workingSegmentIds, setWorkingSegmentIds] = React.useState(segmentIds)
  const { bakedCourseConfiguration } = useCourseContext(true)
  const filteredSegments = filterSegments(
    bakedCourseConfiguration.segments,
    segment => {
      return !!segment.config?.templateId
    },
    { leavesOnly: true },
  )
  const selectedSegmentIds = config?.segmentIds?.length ? config.segmentIds : segmentIds
  React.useEffect(() => {
    if (visible) {
      setWorkingSegmentIds(selectedSegmentIds)
    }
  }, [visible])
  return (
    <>
      <Modal
        title={'Change Content Pool'}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={600}
        destroyOnClose
        footer={[
          <Button
            key={'reset'}
            danger
            loading={loading === 'reset'}
            onClick={async () => {
              setLoading('reset')
              try {
                await onConfigChange({
                  identifier: 'segment',
                  ...config,
                  segmentIds: [],
                })
                setVisible(false)
              } finally {
                setLoading(undefined)
              }
            }}
          >
            Reset to Suggested
          </Button>,
          <Button
            key={'save'}
            type={'primary'}
            loading={loading === 'save'}
            onClick={async () => {
              setLoading('save')
              try {
                await onConfigChange({
                  identifier: 'segment',
                  ...config,
                  segmentIds: workingSegmentIds,
                })
                setVisible(false)
              } finally {
                setLoading(undefined)
              }
            }}
          >
            Save Changes
          </Button>,
        ]}
      >
        <Block marginBottom={'@size-s'}>
          {helpVisible ? (
            <>
              <BodyLarge isBlock>
                Quiz Vault generates a cumulative assignment from selected assignments (the content
                pool) inside your class.
              </BodyLarge>
              <Body isBlock>
                By default, we intelligently create a content pool for you based on where you have
                placed this quiz vault assignment in your class.
              </Body>
              <Body isBlock>
                Sometimes, however, you want some more control over specifically which assignments
                are included. To do that, check / uncheck which assignments you would like to
                include in the content pool for this quiz vault assignment below.
              </Body>
              <Button
                type={'link'}
                icon={<AntIconWrapper children={<HelpCircle />} />}
                onClick={() => setHelpVisible(false)}
                style={{ padding: 0 }}
              >
                Hide Help
              </Button>
            </>
          ) : (
            <Button
              type={'link'}
              icon={<AntIconWrapper children={<HelpCircle />} />}
              onClick={() => setHelpVisible(true)}
              style={{ padding: 0 }}
            >
              How do I use this?
            </Button>
          )}
        </Block>
        <SegmentTree
          segments={filteredSegments}
          value={workingSegmentIds}
          onChange={setWorkingSegmentIds}
        />
      </Modal>
      <a
        onClick={e => {
          e.preventDefault()
          setVisible(true)
        }}
        children={children}
      />
    </>
  )
}
