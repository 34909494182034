import { Question } from '@thesisedu/feature-assignments-core'
import { useResource } from '@thesisedu/feature-react'
import {
  Space,
  BodyLarge,
  HSpaced,
  styledContainer as styled,
  Button,
  getColor,
  getSize,
  Bold,
  SolidIcon,
} from '@thesisedu/react'
import { Check, Clock, WarningCircle } from '@thesisedu/react/icons'
import React from 'react'

import { useGradeQuestionCallback } from './useGradeQuestionCallback'
import { QuestionTypeResource } from '../node'
import { useQuestionRealTimeConfirmationContext } from '../resources/questions/QuestionRealTimeConfirmationContext'

export interface QuestionGradeFeedbackProps {
  question: Question
  onTryAgain: () => void
  isComplete?: boolean
  submissionData?: boolean
}
export function QuestionGradeFeedback({
  isComplete,
  question,
  onTryAgain,
}: QuestionGradeFeedbackProps) {
  const gradeCallback = useGradeQuestionCallback()
  const isCorrect = isComplete ? gradeCallback(question) : false
  const { updateQuestion } = useQuestionRealTimeConfirmationContext(false) || {}
  const questionResource = useResource<QuestionTypeResource>('QuestionType', question.type)
  if (isComplete) {
    if (isCorrect) {
      return (
        <Container color={'@green'}>
          <HSpaced>
            <BodyLarge useDiv>
              <SolidIcon children={<Check />} shape={'rounded'} color={'@green'} />
            </BodyLarge>
            <BodyLarge>
              <Bold>Good work!</Bold> That answer is correct.
            </BodyLarge>
          </HSpaced>
        </Container>
      )
    } else if (isCorrect === false) {
      return (
        <Container color={'@red'}>
          <HSpaced>
            <BodyLarge useDiv>
              <SolidIcon children={<WarningCircle />} shape={'rounded'} color={'@red'} />
            </BodyLarge>
            <BodyLarge>
              <Bold>Try again.</Bold> That answer is not correct.
            </BodyLarge>
            <Space />
            <Button type={'primary'} size={'large'} danger onClick={onTryAgain}>
              Try Again
            </Button>
          </HSpaced>
        </Container>
      )
    } else {
      return null
    }
  } else if (questionResource?.usesRealTimeConfirmation) {
    return (
      <Container color={'@blue'}>
        <HSpaced>
          <BodyLarge useDiv>
            <SolidIcon children={<Clock />} shape={'rounded'} color={'@blue'} />
          </BodyLarge>
          <BodyLarge>
            When you're ready, click <strong>Check Answer</strong> to check your answer.
          </BodyLarge>
          <Space />
          <Button
            type={'primary'}
            size={'large'}
            disabled={!updateQuestion}
            onClick={() => {
              if (updateQuestion) updateQuestion(question.id, true)
            }}
          >
            Check Answer
          </Button>
        </HSpaced>
      </Container>
    )
  } else {
    return null
  }
}

const Container = styled<{ color: string }>`
  background: ${props => getColor(props.theme, `${props.color}-light`)};
  padding: ${props => getSize(props.theme, '@size-xs')};
  border: solid 1px ${props => getColor(props.theme, props.color)};
  border-radius: ${props => getSize(props.theme, '@border-radius-base')};
  margin-top: ${props => getSize(props.theme, '@size-s')};
  > div {
    > p,
    > span {
      color: ${props => props.theme[props.color]} !important;
    }
    > span:not(:last-child) {
      font-size: ${props => props.theme['@size-l']};
      margin-right: ${props => props.theme['@size-s']} !important;
    }
  }
`
