import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { getSegmentVodId } from '@thesisedu/feature-course-types'
import { useMountInteraction } from '@thesisedu/feature-interactions-react'
import { PageHead } from '@thesisedu/web'
import React from 'react'

import { SectionSettings } from './SectionSettings'
import { fromNavigationKey } from '../../contexts/SegmentNavigationContext'
import { SegmentContent } from '../../segment/SegmentContent'
import { useSegmentViewedDecorationUpdater } from '../../segment/useSegmentViewedDecorationUpdater'
import { SegmentProps } from '../../types'

export function SectionContent(props: SegmentProps) {
  const selectedClassId = useSelectedClassId()

  // We don't want to update the completion if the segment has an associated video.
  const hasVideo = !!getSegmentVodId(props.segment)
  useSegmentViewedDecorationUpdater(props.segment.id, hasVideo ? false : undefined)

  useMountInteraction({
    type: 'VIEW_SEGMENT',
    reference: selectedClassId,
    metadata: {
      id: props.segment.id,
      type: props.segment.type,
      name: props.segment.name,
      label: props.segment.config.label,
      days: props.segment.config.days,
      classId: selectedClassId,
    },
  })

  const [parent] = fromNavigationKey(props.selectedNavigationId || '')

  return (
    <>
      <PageHead title={props.segment.name} />
      <SegmentContent {...props}>
        {parent === 'settings' ? <SectionSettings {...props} /> : null}
      </SegmentContent>
    </>
  )
}
