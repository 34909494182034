import { useTeacherClass } from '@thesisedu/feature-classes-react'
import { Result } from '@thesisedu/react'
import { Button, useToast } from '@thesisedu/ui'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { Rostering } from './Rostering'
import { RosteringProgress } from './RosteringProgress'
import {
  ClassSyncProviderFeature,
  useClassSyncRosteringQuery,
  useRosterStudentsFromClassSyncMutation,
} from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'

export interface StandaloneRosteringProps {
  syncId: string
  classId: string
  providerIdentifier: string
  providerName: string
  onComplete: () => void
}
export function StandaloneRostering({
  classId,
  syncId,
  providerIdentifier,
  providerName,
  onComplete,
}: StandaloneRosteringProps) {
  const { data, loading } = useClassSyncRosteringQuery({
    variables: { id: syncId },
    fetchPolicy: 'network-only',
  })
  const { cls, loading: classLoading, error } = useTeacherClass(classId, true)
  const sync = data?.node?.__typename === 'ClassSync' ? data.node : undefined
  const defaultUpdates = sync?.rostering.updates
  const toast = useToast()
  const onError = () => {
    toast({
      title: 'There was an error rostering your students.',
      status: 'error',
    })
    setJobId(null)
  }
  const [startRostering, { loading: rosteringLoading }] = useRosterStudentsFromClassSyncMutation({
    onError,
    onCompleted(data) {
      const jobId = data.rosterStudentsFromClassSync.job?.id
      if (jobId) {
        setJobId(jobId)
      } else {
        onError()
      }
    },
  })
  const [jobId, setJobId] = React.useState<string | null>(null)
  const [isComplete, setIsComplete] = React.useState(false)

  let key = 'none'
  let content
  if (loading || classLoading) {
    key = 'loading'
    content = (
      <LoadingStep
        providerIdentifier={providerIdentifier}
        providerName={providerName}
        title={'Finding Students'}
        direction={'left'}
        message={<>We are asking {providerName} for a list of students in your class.</>}
      />
    )
  } else if (defaultUpdates && !error && cls) {
    key = isComplete ? 'complete' : jobId ?? 'no-job-id'
    content = isComplete ? (
      <LoadingStep
        providerIdentifier={providerIdentifier}
        providerName={providerName}
        state={'success'}
        title={'Students Updated!'}
        message={<>Your students have been updated succesfully.</>}
        action={<Button variant={'chromeless'} children={'Close'} onPress={() => onComplete()} />}
      />
    ) : jobId ? (
      <RosteringProgress
        jobId={jobId}
        classId={classId}
        providerIdentifier={providerIdentifier}
        providerName={providerName}
        onError={onError}
        onSuccess={() => {
          setIsComplete(true)
        }}
      />
    ) : (
      <Rostering
        defaultUpdates={defaultUpdates}
        cls={cls}
        providerClassName={sync.label}
        noImport={
          !!sync.siteProvider?.enabledFeatures &&
          !sync.siteProvider.enabledFeatures.includes(ClassSyncProviderFeature.Rostering)
        }
        providerIdentifier={providerIdentifier}
        providerName={providerName}
        onComplete={updates => {
          startRostering({
            variables: {
              input: {
                id: syncId,
                actions: updates,
              },
            },
          })
        }}
        loading={rosteringLoading}
      />
    )
  } else {
    key = 'error'
    content = <Result.Error description={'There was an error fetching your class information.'} />
  }

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        initial={{ opacity: 0, scale: 0.99 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.99 }}
        transition={{ duration: 0.2 }}
        key={key}
        style={{ flex: 1 }}
      >
        {content}
      </motion.div>
    </AnimatePresence>
  )
}
