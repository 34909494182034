import { DeepLinkProps } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { useSheetMusicCompositionQuery } from './schema'

export function SheetMusicCompositionDeepLinkRemapper({ params, resolved }: DeepLinkProps) {
  const viewer = useViewerContext(false)
  const { data, loading, error } = useSheetMusicCompositionQuery({
    variables: { id: params.id || '' },
    skip: !params.id,
  })
  const comp = data?.node?.__typename === 'SheetMusicComposition' ? data.node : undefined
  React.useEffect(() => {
    if (viewer?.group) {
      if (comp) {
        resolved({
          type: 'Found',
          resolvedPath: `/${viewer.group.toLowerCase()}/classes/${comp.classId}/${
            viewer.group === 'TEACHER' ? 'compositions' : 'compose/shared'
          }/${comp.id}`,
        })
      } else if (error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND_ERROR') {
        resolved({
          type: 'NotFound',
        })
      } else if (!comp && !loading) {
        resolved({
          type: 'AccessDenied',
        })
      }
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [comp, loading, error, viewer])
  return null
}
