import { uniq } from 'lodash'
import React from 'react'

import { debug } from '../log'

export interface MediaPlaybackProgress {
  played: number
  playedSeconds: number
  loaded: number
  loadedSeconds: number
}

export interface MediaPlaybackContextValue {
  isPlaying: boolean
  isBuffering: boolean
  blocks: string[]
  blockPlaying: (identifier: string) => void
  unblockPlaying: (identifier: string) => void
  setIsBuffering: (buffering: boolean) => void
  duration: number
  playedPercentage: number
  playedSeconds: number
  loadedPercentage: number
  loadedSeconds: number
  setOnSeek: (callback: (seconds: number) => void) => void
  onSeek: (seconds: number) => void
  onSetPlaying: React.Dispatch<React.SetStateAction<boolean>>
  setDuration: (duration: number) => void
  setProgress: (progress: MediaPlaybackProgress) => void
  playerRef: React.MutableRefObject<HTMLVideoElement | HTMLAudioElement | undefined>
  fullscreen: boolean
  playbackSpeed: number
  setPlaybackSpeed: React.Dispatch<React.SetStateAction<number>>
  volume: number
  setVolume: React.Dispatch<React.SetStateAction<number>>
  setFullscreen: React.Dispatch<React.SetStateAction<boolean>>
  allowSeek: boolean
}

export const MediaPlaybackContext = React.createContext<MediaPlaybackContextValue | undefined>(
  undefined,
)
export const useMediaPlaybackContext = (): MediaPlaybackContextValue => {
  const val = React.useContext(MediaPlaybackContext)
  if (!val) {
    throw new Error('A media playback context could not be found.')
  }
  return val
}

type StaticProps =
  | 'blockPlaying'
  | 'unblockPlaying'
  | 'setOnSeek'
  | 'setIsBuffering'
  | 'setDuration'
  | 'setProgress'
  | 'onSetPlaying'
  | 'setFullscreen'
  | 'setPlaybackSpeed'
  | 'setVolume'
export interface MediaPlaybackContextProviderProps {
  duration?: number
  onProgress?: (progress: MediaPlaybackProgress) => void
  allowSeek?: boolean
}
export function MediaPlaybackContextProvider({
  duration: propDuration,
  onProgress,
  allowSeek = true,
  children,
}: React.PropsWithChildren<MediaPlaybackContextProviderProps>) {
  const [duration, _setDuration] = React.useState<number>(propDuration || 0)
  const [progress, setProgress] = React.useState<MediaPlaybackProgress>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  })
  React.useEffect(() => {
    if (onProgress) {
      onProgress(progress)
    }
  }, [progress])
  const [isBuffering, setIsBuffering] = React.useState(false)
  const [playbackSpeed, setPlaybackSpeed] = React.useState(1)
  const [volume, setVolume] = React.useState(1)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [blocks, setBlocks] = React.useState<string[]>([])
  const [onSeek, setOnSeek] = React.useState<(seconds: number) => void>(() => () => null)
  const [fullscreen, setFullscreen] = React.useState(false)
  const playerRef = React.useRef<HTMLVideoElement | HTMLAudioElement>()

  // iOS Fullscreen Support
  React.useEffect(() => {
    if (
      playerRef.current &&
      (playerRef.current as any).webkitSupportsFullscreen &&
      !document?.fullscreenEnabled
    ) {
      if (fullscreen) {
        ;(playerRef.current as any).webkitEnterFullscreen()
        /**
         * The event handlers are not working; we need to manually reset this since whenever
         * they come back to this screen, they will no longer be in fullscreen.
         */
        setFullscreen(false)
      }
    }
  }, [fullscreen])

  // Don't override the duration if we've already set it from the server.
  const setDuration: typeof _setDuration = (...args) => {
    if (!propDuration) {
      debug('updating duration %d', args[0])
      _setDuration(...args)
    }
  }
  React.useEffect(() => {
    if (propDuration) setDuration(propDuration)
  }, [propDuration])
  React.useEffect(() => {
    if (isPlaying && blocks?.length) {
      setBlocks([])
    }
  }, [isPlaying])

  const staticContext = React.useMemo<Pick<MediaPlaybackContextValue, StaticProps>>(
    () => ({
      blockPlaying: identifier => setBlocks(blk => uniq([...blk, identifier])),
      unblockPlaying: identifier => setBlocks(blk => blk.filter(item => item !== identifier)),
      setOnSeek: val => setOnSeek(() => val),
      setIsBuffering,
      setDuration,
      setProgress,
      setFullscreen,
      onSetPlaying: setIsPlaying,
      setPlaybackSpeed,
      setVolume,
    }),
    [],
  )
  const contextValue: MediaPlaybackContextValue = {
    ...staticContext,
    isPlaying: isPlaying && blocks.length === 0,
    isBuffering,
    duration,
    blocks,
    playedPercentage: progress.played,
    playedSeconds: progress.playedSeconds,
    loadedPercentage: progress.loaded,
    loadedSeconds: progress.loadedSeconds,
    onSeek,
    playerRef,
    fullscreen,
    playbackSpeed,
    volume,
    allowSeek,
  }

  return <MediaPlaybackContext.Provider value={contextValue} children={children} />
}
