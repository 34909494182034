import { GoogleOAuthProvider } from '@react-oauth/google'
import { useFeature } from '@thesisedu/feature-react'
import { AuthProviderWrapper, AuthRequiredPayload } from '@thesisedu/feature-users-react'
import React from 'react'

import { useGoogleAssociate } from './useGoogleLogin'
import { AuthGoogleReactFeature } from '../AuthGoogleReactFeature'
import { useGoogleScopesQuery } from '../schema'

export interface AuthProviderInnerProps extends AuthRequiredPayload {
  scopes: string[]
}
export function AuthProviderInner({ onSuccess, onError, scopes, ...opts }: AuthProviderInnerProps) {
  const { signIn, associating } = useGoogleAssociate({ scopes, onSuccess, onError })
  return (
    <AuthProviderWrapper
      associating={associating}
      identifier={'google'}
      onAuth={signIn}
      onError={onError}
      {...opts}
    />
  )
}

export function AuthProviderHandler(props: AuthRequiredPayload) {
  const { data } = useGoogleScopesQuery()
  const feature = useFeature(AuthGoogleReactFeature)
  const scopes = data?.googleScopes
  return scopes?.length ? (
    <GoogleOAuthProvider clientId={feature.options.clientId}>
      <AuthProviderInner scopes={scopes} {...props} />
    </GoogleOAuthProvider>
  ) : null
}
