import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { ClassFragmentDoc, useUnarchiveClassMutation as useMutation } from '../schema'

export const useUnarchiveClassMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error unarchiving your class.'),
    update: updateMutation({
      fragment: ClassFragmentDoc,
      fragmentName: 'Class',
      path: 'unarchiveClass.class',
    }),
    refetchQueries: ['classesAndStudents'],
    ...opts,
  })
