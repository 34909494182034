import { MutateHook } from '@thesisedu/feature'
import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { useMutateHook } from '@thesisedu/feature-react'
import { NonOptional, NonFunction } from '@thesisedu/feature-types'

import {
  SiteFragment,
  SiteFragmentDoc,
  UpdateSiteMutation,
  useUpdateSiteMutation as useMutation,
} from '../schema'

export type UpdateSiteRefetchQueriesPayload = NonOptional<
  NonFunction<NonOptional<Parameters<typeof useUpdateSiteMutation>[0]>['refetchQueries']>
>
export type UpdateSiteRefetchQueriesHook = MutateHook<
  'feature-sites-web:update-site-refetch-queries',
  UpdateSiteRefetchQueriesPayload,
  undefined
>

export const useUpdateSiteMutation: typeof useMutation = opts => {
  const refetchQueries = useMutateHook<UpdateSiteRefetchQueriesHook>(
    'feature-sites-web:update-site-refetch-queries',
    opts?.refetchQueries || ([] as any),
    undefined,
  )
  return useMutation({
    onError: onMutationError('There was an error updating that site.'),
    update: updateMutation<UpdateSiteMutation, SiteFragment>({
      path: 'updateSite.site',
      fragment: SiteFragmentDoc,
    }),
    refetchQueries,
    ...opts,
  })
}
