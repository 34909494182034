import React from 'react'

import { OutlineWindowList } from './OutlineWindowList'
import { generateOutlineItems } from './generate'
import { GroupSegment, Segment } from '../types'

export interface OutlineCommonProps {
  segmentHeight?: number
}
export interface OutlineTermProps extends OutlineCommonProps {
  segments: (Segment | GroupSegment)[]
  defaultDepth?: number
}
export function OutlineTerm({ segments, defaultDepth = 0, ...props }: OutlineTermProps) {
  const items = generateOutlineItems(segments, {
    depth: defaultDepth,
    parentSegment: segments[0],
    parentIds: [segments[0].id],
  })
  return <OutlineWindowList items={items} selectedTerm={segments[0] as GroupSegment} {...props} />
}
