import React from 'react'

import { Piano } from './Piano'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function AdvancedNotesContent(props: ModeContentsProps) {
  return <Piano {...props} />
}

export const AdvancedNotes: Mode = {
  id: 'AdvancedNotes',
  name: 'Notes',
  icon: require('../../../../assets/sketch/sheet-music/note-c.svg').default,
  large: false,
  types: [KeyboardType.Note25],
  contents: AdvancedNotesContent,
}
