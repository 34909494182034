import { LexicalEditor, $nodesOfType } from 'lexical'

import { Validator } from './types'
import Image from './validators/Image'
import ListItem from './validators/ListItem'
import Paragraph from './validators/Paragraph'
import { debug } from '../../../log'

const VALIDATORS: Validator<any>[] = [Paragraph, ListItem, Image]

export function validateEditor(editor: LexicalEditor) {
  editor.update(() => {
    for (const validator of VALIDATORS) {
      debug('validating %s nodes', validator.node.name)
      const nodes = $nodesOfType(validator.node)
      for (const node of nodes) {
        validator.validate(node, editor)
      }
    }
  })
}
