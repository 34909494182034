import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { useMutateHook } from '@thesisedu/feature-react'

import { UPDATE_ASSIGNMENT_QUERIES } from './constants'
import { UpdateAssignmentRefetchQueriesHook } from '..'
import {
  AssignmentAssignedStudentsFragmentDoc,
  BasicAssignmentFragmentDoc,
  useResetGeneratedAssignmentMutation as useMutation,
} from '../schema'

export const useResetGeneratedAssignmentMutation: typeof useMutation = opts => {
  const refetchQueries = useMutateHook<UpdateAssignmentRefetchQueriesHook>(
    'feature-assignments-react:update-assignment-refetch-queries',
    UPDATE_ASSIGNMENT_QUERIES,
    undefined,
  )
  return useMutation({
    onError: onMutationError('There was an error resetting your assignment.'),
    refetchQueries,
    update: (...args) => {
      updateMutation({
        fragment: BasicAssignmentFragmentDoc,
        fragmentName: 'BasicAssignment',
        path: 'updateAssignment.assignment',
      })(...args)
      updateMutation({
        fragment: AssignmentAssignedStudentsFragmentDoc,
        fragmentName: 'AssignmentAssignedStudents',
        path: 'updateAssignment.assignment',
      })(...args)
    },
    ...opts,
  })
}
