import { useApolloClient } from '@apollo/client'
import { PostComment, CommentList, CommentListProps } from '@thesisedu/feature-comments-react'
import { useMountInteraction } from '@thesisedu/feature-interactions-react'
import {
  formatTime,
  LoadingIndicator,
  useMediaPlaybackContext,
  styledContainer,
  isNative,
  getSize,
  BodyExtraSmall,
} from '@thesisedu/react'
import { Text } from '@thesisedu/ui'
import { transparentize } from 'polished'
import React, { useEffect } from 'react'

import { useMediaCommentsQuery, MediaFragmentDoc } from '../schema'

const Container = styledContainer`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const CommentsContainer = styledContainer`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  min-height: ${isNative ? '10px' : '200px'};
  max-height: ${isNative ? '10000px' : '50vh'};
  margin-bottom: ${props => getSize(props.theme, '@size-m')};
  position: relative;
  > div {
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
    > div:last-child {
      padding-top: ${props => props.theme['@size-m']};
    }
  }
  .ant-empty-description {
    color: ${props => props.theme['@text-color']};
  }
`
const CommentBlur = styledContainer`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.theme['@size-m']};
  background: linear-gradient(
    to bottom,
    ${props => props.theme['@component-background']} 0%,
    ${props => transparentize(1, props.theme['@component-background'])} 100%
  );
`
const Footer = styledContainer`
  flex-shrink: 0;
  textarea {
    resize: none;
  }
`

export interface MediaCommentsProps extends Partial<CommentListProps> {
  mediaId: string
  onPosted?: () => void
}
export const MediaComments: React.FC<React.PropsWithChildren<MediaCommentsProps>> = ({
  mediaId,
  onPosted,
  ...commentListProps
}) => {
  const { data, loading, refetch } = useMediaCommentsQuery({ variables: { mediaId } })
  const { playedSeconds, blockPlaying, unblockPlaying, onSeek } = useMediaPlaybackContext()
  useMountInteraction({ type: 'VIEW_MEDIA_COMMENTS', reference: mediaId })
  const client = useApolloClient()
  const media = data?.node?.__typename === 'Media' ? data!.node : undefined
  useEffect(() => {
    if (media) {
      const cachedMediaFragment = client.readFragment({
        id: `Media:${media.id}`,
        fragment: MediaFragmentDoc,
        fragmentName: 'Media',
      })
      if (cachedMediaFragment) {
        client.writeFragment({
          id: `Media:${cachedMediaFragment.id}`,
          fragment: MediaFragmentDoc,
          fragmentName: 'Media',
          data: {
            ...cachedMediaFragment,
            hasUnreadComments: false,
          },
        })
      }
    }
  }, [media])
  let content
  if (loading && !media) {
    content = <LoadingIndicator block />
  } else if (!media?.comments.edges.length) {
    content = (
      <Text color={'secondary'} style={{ textAlign: 'center' }}>
        No comments yet
      </Text>
    )
  } else {
    content = (
      <CommentList
        commentableId={media.id}
        // TODO this is a hack to get around me not wanting to update the types
        comments={(media?.comments.edges.map(edge => edge.node) || []) as any}
        renderMetadata={comment => {
          let content = ''
          if (comment.metadata?.currentTimeSeconds) {
            content = formatTime(comment.metadata.currentTimeSeconds) || ''
          }
          return content ? (
            <BodyExtraSmall
              color={'@text-color-secondary'}
              style={{ cursor: 'pointer' }}
              onClick={e => {
                e.preventDefault()
                onSeek(comment.metadata.currentTimeSeconds)
              }}
            >
              {content}
            </BodyExtraSmall>
          ) : undefined
        }}
        onCommentDeleted={() => refetch({ mediaId })}
        {...commentListProps}
      />
    )
  }
  return (
    <Container>
      <CommentsContainer>
        {isNative ? null : <CommentBlur />}
        {content}
      </CommentsContainer>
      {media?.canComment ? (
        <Footer>
          <PostComment
            entityId={mediaId}
            onPosted={async () => {
              if (onPosted) {
                onPosted()
              }
              await refetch({ mediaId })
            }}
            metadata={{
              currentTimeSeconds: playedSeconds,
            }}
            onFocus={() => {
              if (blockPlaying) {
                blockPlaying('comments')
              }
            }}
            onBlur={() => {
              if (unblockPlaying) {
                unblockPlaying('comments')
              }
            }}
          />
        </Footer>
      ) : null}
    </Container>
  )
}
