import { Feature } from '@thesisedu/feature'
import { SiteConfigurationFieldResource } from '@thesisedu/feature-sites-web'

import defaultClassConfiguration from './defaultClassConfiguration'

const RESOURCES: SiteConfigurationFieldResource[] = [defaultClassConfiguration]
export function addSiteConfigurationFields(feature: Feature) {
  for (const resource of RESOURCES) {
    feature.resources.addResource(resource)
  }
}
