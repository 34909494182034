import { Shell } from '@thesisedu/feature-web'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

import { JoinClassModal } from '../JoinClassModal'

export function JoinClassLeftNavItem(props: any) {
  const [joinVisible, setJoinVisible] = React.useState(false)
  return (
    <>
      <JoinClassModal visible={joinVisible} onClose={() => setJoinVisible(false)} />
      <Shell.LeftMenuItem
        {...props}
        name={'Join Class'}
        icon={<Plus />}
        path={'#'}
        onClick={() => setJoinVisible(true)}
      />
    </>
  )
}
