import { Form, FormInstance, usePrompt } from '@thesisedu/react'
import React from 'react'

import { LessonFormValues } from './useCreateLesson'
import { LessonFragment, useLessonQuery } from '..'
import { useUpdateLessonMutation } from '../queries/useUpdateLessonMutation'
import { useUpdateLessonTagsMutation } from '../queries/useUpdateLessonTagsMutation'
import { BasicLessonFragment } from '../schema'

export interface UseEditLessonOpts {
  onSave?: (lesson: BasicLessonFragment) => void
  lesson: string | LessonFragment
  fieldsKey?: any
}
export interface UseEditLesson {
  form: FormInstance<LessonFormValues>
  onFinish: (values: LessonFormValues) => Promise<void>
  onValuesChange: (values: LessonFormValues) => void
  pageLoading: boolean
  loading: boolean
  isDirty: boolean
  lesson?: LessonFragment
}
export function useEditLesson({
  onSave,
  lesson: _lesson,
  fieldsKey,
}: UseEditLessonOpts): UseEditLesson {
  const form = Form.useForm<LessonFormValues>()
  const { data, loading: lessonDataLoading } = useLessonQuery({
    variables: {
      id: typeof _lesson === 'string' ? _lesson : '',
    },
    skip: typeof _lesson !== 'string',
  })
  const [updateLesson, { loading: lessonLoading }] = useUpdateLessonMutation()
  const [updateTags, { loading: tagsLoading }] = useUpdateLessonTagsMutation()
  const [dirty, setDirty] = React.useState(false)
  usePrompt(dirty && !form.formState.isSubmitSuccessful)

  const lesson =
    typeof _lesson === 'string'
      ? data?.node?.__typename === 'Lesson'
        ? data.node
        : undefined
      : _lesson
  const realLessonId = typeof _lesson === 'string' ? _lesson : _lesson.id
  React.useEffect(() => {
    if (lesson) {
      form.setValue('mediaId', lesson.media.id)
      form.setValue('name', lesson.name)
      form.setValue('attachment', lesson.attachment)
      form.setValue(
        'tags',
        lesson.tags.edges.map(edge => edge.node.name),
      )
    }
  }, [lesson, fieldsKey])

  return {
    form,
    lesson,
    pageLoading: lessonDataLoading,
    loading: lessonLoading || tagsLoading,
    isDirty: dirty,
    onValuesChange() {
      setDirty(true)
    },
    async onFinish(values) {
      const updated = await updateLesson({
        variables: {
          input: {
            id: realLessonId,
            patch: {
              name: values.name,
              attachment: values.attachment,
              mediaId: values.mediaId,
            },
          },
        },
      })
      await updateTags({
        variables: {
          input: {
            id: realLessonId,
            tags: values.tags || [],
          },
        },
      })
      if (onSave && updated.data?.updateLesson.lesson) {
        const updatedLesson = updated.data.updateLesson.lesson
        setTimeout(() => {
          onSave(updatedLesson)
        }, 10)
      }
    },
  }
}
