import {
  QuestionInstructions,
  QuestionProps,
  useSubmissionData,
  QuestionTypeResource,
} from '@thesisedu/feature-assignments-react'
import { QuestionTextMediaView } from '@thesisedu/feature-assignments-react/dist/questions/QuestionTextMediaField'
import { useResource } from '@thesisedu/feature-react'
import { SheetMusicDragDropRecordMode } from '@thesisedu/feature-sheet-music-core'
import { Form } from '@thesisedu/react'
import { H3Alternate, styled } from '@thesisedu/web'
import { renderAbc } from 'abcjs'
import { transparentize } from 'polished'
import React from 'react'
import { useDrag } from 'react-dnd'

import { CustomGroupStaff } from './CustomGroupStaff'
import { DragDropRecordButton } from './DragDropRecordButton'
import { CustomGroupContainer, CustomGroupsContainer } from './styled'
import {
  CustomGroup,
  flattenNoteItems,
  INSTRUCTIONS,
  isNoteItem,
  SheetMusicDragDropAnswer,
  SheetMusicDragDropConfig,
  TuneItem,
} from './types'
import { ScrollContextProvider, useScrollContext } from '../../../editor/ScrollContext'
import { SimpleSheetMusicEditor } from '../../../editor/SimpleSheetMusicEditor'
import { DragItem, DragItemType } from '../../../editor/types'

export interface SheetMusicDragDropContentInnerProps {
  value?: SheetMusicDragDropAnswer
  onChange?: (value?: SheetMusicDragDropAnswer) => void
  config?: SheetMusicDragDropConfig
  disabled?: boolean
  questionName: string
}
export function SheetMusicDragDropContentInner({
  config,
  value,
  onChange,
  disabled,
  questionName,
}: SheetMusicDragDropContentInnerProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const abc = typeof value === 'string' ? value : value?.abc
  const valueObj = typeof value === 'object' ? value : undefined
  return (
    <EditorContainer style={disabled ? { pointerEvents: 'none' } : undefined} ref={containerRef}>
      <ScrollContextProvider containerRef={containerRef}>
        <SimpleSheetMusicEditor
          value={abc}
          onChange={
            onChange
              ? (newValue, state) => {
                  onChange({
                    ...state,
                    mediaId: valueObj?.mediaId,
                    abc: newValue,
                  })
                }
              : undefined
          }
          viewerProps={{
            actions:
              config?.recordMode !== SheetMusicDragDropRecordMode.None ? (
                <DragDropRecordButton
                  mediaId={valueObj?.mediaId}
                  abc={abc || ''}
                  questionName={questionName}
                  onComplete={mediaId => {
                    if (onChange) {
                      onChange({
                        ...valueObj,
                        abc: abc || '',
                        mediaId,
                      })
                    }
                  }}
                />
              ) : undefined,
            playbackContainerProps: {
              hideControls: disabled,
            },
          }}
          hideLeftControls={!!config?.customGroups?.length && !config?.showControls}
          useAdvancedMode={
            valueObj?.advancedMode !== undefined ? valueObj.advancedMode : config?.useAdvancedMode
          }
          defaultInstrument={
            valueObj?.selectedInstrument !== undefined
              ? valueObj.selectedInstrument
              : config?.defaultInstrument
          }
          defaultMeter={config?.meter}
          defaultBpm={valueObj?.bpm !== undefined ? valueObj.bpm : config?.bpm}
          readOnlyMeter={config?.meter && !config?.allowChangingTimeSignature}
          readOnlyBpm={!!config?.bpm && !config?.allowChangingBpm}
          topControls={
            <CustomGroupsContainer space={'@size-m'} align={'stretch'}>
              {config?.customGroups?.map(customGroup => (
                <CustomGroupDraggable customGroup={customGroup} key={customGroup.id} />
              ))}
            </CustomGroupsContainer>
          }
          fullscreenHeader={
            <H3Alternate>
              <QuestionTextMediaView value={questionName} />
            </H3Alternate>
          }
        />
      </ScrollContextProvider>
    </EditorContainer>
  )
}

interface CustomGroupDraggableProps {
  customGroup: CustomGroup
  children?: React.ReactElement
}
export function CustomGroupDraggable({ customGroup, children }: CustomGroupDraggableProps) {
  const contents = renderAbc('*', customGroup.abc) as TuneItem[]
  const flatContents = flattenNoteItems(contents)
  const { onCollect, onDragEnd } = useScrollContext(false) || {}
  const [{ opacity }, dragRef] = useDrag<DragItem, any, { opacity: number }>(
    () => ({
      type: DragItemType.Note,
      item: {
        pitch: flatContents.map(item => {
          if (isNoteItem(item) && item.pitches[0]?.pitch !== undefined) {
            return item.pitches[0]!.pitch
          } else {
            return undefined
          }
        }),
        accidental: flatContents.map(item => {
          if (isNoteItem(item) && item.pitches[0]?.pitch !== undefined) {
            return item.pitches[0]!.accidental
          } else {
            return undefined
          }
        }),
        duration: flatContents.map(item => item.duration),
      },
      end: () => {
        if (onDragEnd) onDragEnd()
      },
      collect: monitor => {
        if (onCollect) onCollect(monitor)
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
        }
      },
    }),
    [customGroup.abc],
  )

  return (
    <DraggableContainer style={{ opacity }} ref={dragRef}>
      {children ? (
        children
      ) : (
        <CustomGroupContainer space={'@size-m'}>
          <CustomGroupStaff customGroup={customGroup} />
        </CustomGroupContainer>
      )}
    </DraggableContainer>
  )
}

export function SheetMusicDragDropContent(props: QuestionProps<SheetMusicDragDropConfig>) {
  const disabled = props.disabled
  const questionType = useResource<QuestionTypeResource>('QuestionType', 'SheetMusicDragDrop')
  return (
    <Form.Item
      name={props.question.id}
      validate={value => {
        if (
          questionType?.isQuestionComplete &&
          questionType.isQuestionComplete(props.question, value)
        ) {
          return true
        } else {
          return 'This question is required.'
        }
      }}
      initialValue={useSubmissionData(props.question.id)}
    >
      <QuestionInstructions
        instructions={INSTRUCTIONS}
        padded
        containerStyle={{ overflow: 'visible' }} // This is so the position:sticky in the controls works.
      >
        <SheetMusicDragDropContentInner
          config={props.question.config}
          disabled={disabled}
          questionName={props.question.name}
        />
      </QuestionInstructions>
    </Form.Item>
  )
}

const DraggableContainer = styled.div`
  cursor: pointer;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s linear;
  > div {
    transition: box-shadow 0.25s ease-in-out;
  }
  &:hover {
    transform: scale(1.05);
    > div {
      box-shadow: 0 2px 10px ${props => transparentize(0.5, props.theme['@gray-4'])};
    }
  }
`
const EditorContainer = styled.div`
  .sheet-music-controls {
    margin: 0 -${props => props.theme['@size-s']};
    bottom: -${props => props.theme['@size-l']};
  }
`
