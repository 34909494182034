import { MutationHookOptions, MutationTuple, useApolloClient } from '@apollo/client'
import { PureQueryOptions } from '@apollo/client/core'
import { onMutationError, useModifiedFragmentDocument } from '@thesisedu/feature-apollo-react'
import { useMutation } from '@thesisedu/feature-apollo-react/apollo'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import {
  bakeCourseConfiguration,
  combineSegmentMetadata,
  diffSegmentMetadata,
  orderSegments,
  SegmentMetadataDiff,
  SegmentMetadataSegment,
} from '@thesisedu/feature-course-types'
import { Modal } from 'antd'
import { cloneDeep } from 'lodash'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSegmentCache } from '../contexts/SegmentContext'
import { getSegmentLinkPath } from '../helpers'
import { debug } from '../log'
import {
  ClassInput,
  ClassCourseFragment,
  ClassCourseFragmentDoc,
  UpdateClassWithCourseDocument,
  UpdateClassWithCourseMutation,
  UpdateClassWithCourseMutationVariables,
  ClassFragment,
  ClassFragmentDoc,
} from '../schema'

/** These queries are refetched whenever updating classes (enabling / disabling segments), etc. */
export const CLASS_SEGMENT_UPDATE_REFETCH_QUERIES = ['classDecorations']

type MutateOpts = Omit<
  MutationHookOptions<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>,
  'refetchQueries'
> & { refetchQueries?: (string | PureQueryOptions)[] }
type MutateClass = (
  changes: Partial<ClassInput>,
  requiresRefetch?: boolean,
  force?: boolean,
  opts?: MutateOpts,
) => Promise<any>
export const useUpdateClassMutation = (
  _classId?: string,
  {
    onError,
    ...otherOpts
  }: Omit<
    MutationHookOptions<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>,
    'refetchQueries'
  > & { refetchQueries?: (string | PureQueryOptions)[] } = {},
): [
  MutateClass,
  MutationTuple<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>[1],
] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classId = _classId || useSelectedClassId()
  const segments = useSegmentCache()
  const location = useLocation()
  const navigate = useNavigate()
  const client = useApolloClient()
  const modified = {
    ClassFragmentDoc: useModifiedFragmentDocument(ClassFragmentDoc),
    ClassCourseFragmentDoc: useModifiedFragmentDocument(ClassCourseFragmentDoc),
  }
  const [innerMutate, mutateData] = useMutation(UpdateClassWithCourseDocument, {
    ...otherOpts,
    onError: err => {
      const handler = onMutationError('There was an error updating that class.')
      for (const { extensions, message } of err.graphQLErrors || []) {
        if (extensions?.code === 'DISABLE_IN_PROGRESS_SEGMENT_WARNING') {
          // FIXME proper types
          const { segmentIds, patch, originalOverrides } = extensions as any
          const segmentNames: string[] = segmentIds
            .map((segmentId: string) => {
              const segment = segments[segmentId]
              return segment && segment.name
            })
            .filter(Boolean)
          Modal.confirm({
            title: 'Are you sure you want to disable this content?',
            content: (
              <>
                <p>
                  <strong>
                    It looks like some work has already started on some of the content you are
                    trying to disable.
                  </strong>{' '}
                  If you continue, your students will not be able to see their grades for the
                  following segments:
                </p>
                <ul>
                  {segmentNames.map(name => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
                <p>
                  Their grades will not be lost. Once you enable the segment again, their grades
                  will re-appear. Do you want to keep the segments with grades associated enabled?
                </p>
              </>
            ),
            okText: 'Yes, disable content and hide grades',
            okType: 'danger',
            cancelText: 'No, keep content enabled with grades',
            width: 750,
            onOk() {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              return mutate(patch, false, true)
            },
            onCancel() {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              return mutate({
                ...patch,
                segmentMetadata: {
                  ...patch.segmentMetadata,
                  segments: [
                    ...patch.segmentMetadata.segments.filter(
                      ({ id }: any) => !segmentIds.includes(id),
                    ),
                    ...segmentIds
                      .map((segmentId: string) => {
                        return originalOverrides?.find(
                          (s: SegmentMetadataSegment) => s.id === segmentId,
                        )
                      })
                      .filter(Boolean),
                  ],
                },
              })
            },
          })
          return
        } else if (extensions?.code === 'CALCULATED_ASSIGNMENT_NOT_SETUP_ERROR') {
          Modal.warn({
            title: 'Assignment Setup Required',
            content: (
              <>
                <strong>You need to setup this assignment before you can enable it.</strong>
                <p>
                  It looks like you're trying to enable a quiz-vault assignment. Before you can do
                  that, you'll need to setup the assignment questions so we know which ones to
                  present to your students.
                </p>
              </>
            ),
            onOk() {
              navigate(getSegmentLinkPath({ id: message }, location))
            },
          })
        }
      }
      handler(err)
      if (onError) onError(err)
    },
    update: (proxy, { data }) => {
      // You may think we can remove the below code since the configuration already comes back from
      // the server, but you're wrong.
      const updateClass = cloneDeep(data?.updateClass)
      if (!updateClass) return
      const classFragment = proxy.readFragment<ClassFragment>({
        fragment: modified.ClassFragmentDoc,
        fragmentName: 'Class',
        id: `Class:${classId}`,
      })!
      const fragment = cloneDeep(
        proxy.readFragment<ClassCourseFragment>({
          fragment: modified.ClassCourseFragmentDoc,
          fragmentName: 'ClassCourse',
          id: `Class:${updateClass.class.id}`,
        }),
      )
      if (fragment) {
        const segmentMetadata =
          (updateClass.class as ClassCourseFragment).segmentMetadata || fragment.segmentMetadata
        const configuration = {
          ...fragment.bakedCourseConfiguration, // TODO: this might break and we might need to go back to just courseConfiguration
          segments: combineSegmentMetadata(
            (segmentMetadata || {}).segments,
            fragment.bakedCourseConfiguration.segments,
          ),
        }
        const baked = bakeCourseConfiguration(configuration, segmentMetadata)
        const bakedCourseConfiguration = {
          ...baked,
          segments: orderSegments(baked.segments || []),
        }
        debug('new bakedCourseConfiguration after updating class')
        debug(bakedCourseConfiguration)
        proxy.writeFragment({
          fragment: modified.ClassCourseFragmentDoc,
          fragmentName: 'ClassCourse',
          id: `Class:${updateClass.class.id}`,
          data: {
            ...classFragment,
            ...fragment,
            ...updateClass.class,
            segmentMetadata,
            bakedCourseConfiguration,
          },
        })
      }
    },
  })

  const mutateOptsRef = React.useRef<MutateOpts | undefined>(undefined)
  const mutate: MutateClass = (changes, needsRefetch, force, opts = mutateOptsRef.current) => {
    mutateOptsRef.current = opts
    const classCourseFragment = client.cache.readFragment<ClassCourseFragment>({
      fragment: modified.ClassCourseFragmentDoc,
      fragmentName: 'ClassCourse',
      id: `Class:${classId}`,
    })
    if (!classCourseFragment) {
      throw new Error('Cannot find existing fragment when updating class.')
    }
    let segmentMetadataChanges: SegmentMetadataDiff | null = null
    let includesStructureChanges = false
    if (changes.segmentMetadata) {
      debug('generating diff for segmentMetadata updates:')
      debug(changes.segmentMetadata)
      segmentMetadataChanges = diffSegmentMetadata(
        classCourseFragment.segmentMetadata,
        changes.segmentMetadata,
      )
      debug('diff %O', segmentMetadataChanges)
      if (segmentMetadataChanges?.structureOverrides?.length) {
        debug('includes structure changes; refetching full configuration')
        includesStructureChanges = true
      }

      if (!segmentMetadataChanges) {
        debug('no changes detected with diff, not updating segmentMetadata')
        segmentMetadataChanges = null
      }
    }
    const { segmentMetadata, ...remainingChanges } = changes
    return innerMutate({
      ...opts,
      variables: {
        refetchCourseConfiguration: includesStructureChanges,
        input: {
          id: classId,
          patch: remainingChanges,
          force,
          segmentMetadataChanges: segmentMetadataChanges || undefined,
        } as any,
      },
      optimisticResponse: () => {
        const fragment = client.cache.readFragment<ClassCourseFragment>({
          fragment: modified.ClassCourseFragmentDoc,
          fragmentName: 'ClassCourse',
          id: `Class:${classId}`,
        })
        let bakedCourseConfiguration: any | undefined = undefined
        if (includesStructureChanges && fragment) {
          const segmentMetadata = changes.segmentMetadata
          const configuration = {
            ...fragment.bakedCourseConfiguration, // TODO: this might break and we might need to go back to just courseConfiguration
            segments: combineSegmentMetadata(
              (segmentMetadata || {}).segments,
              fragment.bakedCourseConfiguration.segments,
            ),
          }
          const baked = bakeCourseConfiguration(configuration, segmentMetadata)
          bakedCourseConfiguration = {
            ...baked,
            segments: orderSegments(baked.segments || []),
          }
        }
        return {
          __typename: 'Mutation',
          updateClass: {
            __typename: 'ClassPayload',
            class: {
              __typename: 'Class',
              id: classId,
              bakedCourseConfiguration,
              segmentMetadata: changes.segmentMetadata,
            } as Pick<
              ClassCourseFragment,
              'id' | '__typename' | 'bakedCourseConfiguration' | 'segmentMetadata'
            >,
          },
        } as any
      },
      refetchQueries: needsRefetch
        ? [
            ...(otherOpts.refetchQueries || []),
            ...(opts?.refetchQueries || []),
            'class',
            ...CLASS_SEGMENT_UPDATE_REFETCH_QUERIES,
          ]
        : [
            ...(otherOpts.refetchQueries || []),
            ...(opts?.refetchQueries || []),
            ...CLASS_SEGMENT_UPDATE_REFETCH_QUERIES,
          ],
      awaitRefetchQueries: needsRefetch,
    })
  }

  return [
    classId
      ? mutate
      : () => {
          console.warn('Trying to update class without a class ID passed.')
          return Promise.resolve()
        },
    mutateData,
  ]
}
