import { copyToClipboard__EXPERIMENTAL } from '@lexical/clipboard'
import { Copy, Trash } from '@thesisedu/react/icons'
import { Dropdown$, Dropdown } from '@thesisedu/ui'
import {
  $createNodeSelection,
  $isDecoratorNode,
  $isElementNode,
  $setSelection,
  LexicalEditor,
  LexicalNode,
} from 'lexical'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { SettingsDropdownElementProps, useSettingsDropdownContext } from './SettingsDropdownContext'

function DeleteDropdownItem({ editor, node }: SettingsDropdownElementProps) {
  return (
    <Dropdown.Item
      icon={<Trash />}
      danger
      onClick={() => {
        editor.update(() => {
          node.remove()
        })
      }}
    >
      Remove Item
    </Dropdown.Item>
  )
}

function CopyDropdownItem({ editor, node }: SettingsDropdownElementProps) {
  return (
    <Dropdown.Item
      icon={<Copy />}
      onClick={async () => {
        editor.update(async () => {
          if ($isElementNode(node)) {
            node.select(0, undefined)
          } else if ($isDecoratorNode(node)) {
            const selection = $createNodeSelection()
            selection.add(node.getKey())
            $setSelection(selection)
          }

          await copyToClipboard__EXPERIMENTAL(editor, null)
          editor.update(() => {
            $setSelection(null)
          })
        })
      }}
    >
      Copy
    </Dropdown.Item>
  )
}

export interface SettingsDropdownMenuContentProps extends Partial<Dropdown$.DropdownMenuProps> {
  editor: LexicalEditor
  node: LexicalNode
}
export function SettingsDropdownMenuContent({ editor, node }: SettingsDropdownMenuContentProps) {
  const context = useSettingsDropdownContext(true)
  const items = React.useMemo(() => {
    return editor.getEditorState().read(() => {
      let items = orderBy(context.items, 'weight', 'asc')
      items = items.filter(item => {
        return !item.filter || item.filter(editor, node)
      })

      items = [
        ...items,
        {
          identifier: 'copy',
          weight: 9999,
          element: CopyDropdownItem,
        },
        {
          identifier: 'delete',
          weight: Infinity,
          group: 'delete',
          element: DeleteDropdownItem,
        },
      ]
      return items
    })
  }, [node, context.items.length])

  const grouped = groupBy(items, 'group')
  const sortedGroupKeys = orderBy(
    Object.keys(grouped),
    key => {
      return grouped[key].reduce((min, item) => Math.min(min, item.weight), Infinity)
    },
    'asc',
  )
  return (
    <Dropdown.Menu align={'start'}>
      {sortedGroupKeys.map((groupKey, index) => {
        const itemsInsideGroup = grouped[groupKey]
        const isLast = index === sortedGroupKeys.length - 1
        return (
          <React.Fragment key={groupKey || 'no-group'}>
            {itemsInsideGroup.map(({ element: Element, identifier }) => {
              return <Element key={identifier} editor={editor} node={node} />
            })}
            {isLast ? null : <Dropdown.Item.Separator />}
          </React.Fragment>
        )
      })}
    </Dropdown.Menu>
  )
}
