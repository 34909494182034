import React from 'react'

import { SegmentOutlineItemProps } from '../items/Segment'

export interface OutlineContextGroupActionsOpts extends SegmentOutlineItemProps {
  overlayVisible?: boolean
  setOverlayVisible: (visible: boolean) => void
}

export interface OutlineContextValue {
  renderSegment?: (props: SegmentOutlineItemProps) => React.ReactElement | null
  segmentContainerProps?: (
    props: SegmentOutlineItemProps,
  ) => Partial<React.HTMLAttributes<HTMLDivElement>>
  groupActions?: (props: OutlineContextGroupActionsOpts) => React.ReactElement | null
}
export const OutlineContext = React.createContext<OutlineContextValue | undefined>(undefined)

export interface OutlineContextProviderProps {
  renderSegment?: OutlineContextValue['renderSegment']
  segmentContainerProps?: OutlineContextValue['segmentContainerProps']
  groupActions?: OutlineContextValue['groupActions']
}
export function OutlineContextProvider({
  renderSegment,
  groupActions,
  segmentContainerProps,
  children,
}: React.PropsWithChildren<OutlineContextProviderProps>) {
  return (
    <OutlineContext.Provider
      value={{ renderSegment, groupActions, segmentContainerProps }}
      children={children}
    />
  )
}

export function useOutlineContext(): OutlineContextValue | undefined
export function useOutlineContext(require: false): OutlineContextValue | undefined
export function useOutlineContext(require: true): OutlineContextValue
export function useOutlineContext(require?: boolean): OutlineContextValue | undefined {
  const context = React.useContext(OutlineContext)
  if (!context && require) {
    throw new Error('OutlineContext is required, yet not provided.')
  }
  return context
}
