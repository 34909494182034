import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  BasicCourseVersionFragmentDoc,
  useUpdateCourseVersionMutation as useMutation,
} from '../schema'

export const useUpdateCourseVersionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that course version.'),
    ...opts,
    update: updateMutation({
      fragmentName: 'BasicCourseVersion',
      fragment: BasicCourseVersionFragmentDoc,
      path: 'updateCourseVersion.courseVersion',
    }),
  })
