import { SearchOutlined } from '@ant-design/icons'
import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { findSegment } from '@thesisedu/feature-course-types'
import { Block, BodySmall } from '@thesisedu/web'
import { Button, Input, Empty } from 'antd'
import { set } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { UseInClassButton } from './UseInClassButton'
import { CUSTOM_SEGMENT_HEIGHT, CustomSegment } from '../custom_content/CustomSegment'
import { OutlineTerm } from '../outline/OutlineTerm'
import { OutlineContextProvider } from '../outline/context/OutlineContext'
import {
  ListBuiltSegmentsQuery,
  ListBuiltSegmentsQueryVariables,
  ListBuiltSegmentsDocument,
  SegmentType,
  FilterOperation,
  BuiltSegmentFragment,
  OrderDirection,
} from '../schema'
import { GroupSegment } from '../types'

const DEBOUNCE = 1000
export interface ExploreTagProps {
  tagName?: string
  tagType?: string
}
export function ExploreTag({ tagType, tagName }: ExploreTagProps) {
  const params = new URLSearchParams(window.location.search)
  const [dNameFilter, setNameFilter] = React.useState<string>(params.get('query') || '')
  const timeoutRef = React.useRef<any>(null)
  const navigate = useNavigate()
  React.useEffect(() => {
    const newParams = new URLSearchParams(window.location.search)
    const hadQuery = newParams.has('query')
    if (dNameFilter) {
      newParams.set('query', dNameFilter)
    } else {
      newParams.delete('query')
    }
    if ((hadQuery && !dNameFilter) || dNameFilter) {
      window.history.pushState(
        {},
        document.title,
        `${window.location.origin}${window.location.pathname}?${newParams.toString()}`,
      )
    }
  }, [dNameFilter])
  const showEmpty = !tagType && !tagName && !dNameFilter
  return (
    <>
      <Input
        size={'large'}
        style={{ width: '100%' }}
        suffix={<SearchOutlined />}
        placeholder={`Search ${tagName || 'Content'}...`}
        defaultValue={dNameFilter}
        onChange={e => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
          }
          const value = e.target.value
          timeoutRef.current = setTimeout(() => {
            setNameFilter(value)
          }, DEBOUNCE)
        }}
      />
      <BodySmall isBlock color={'@text-color-secondary'}>
        Click on a piece of content to preview and use it in your class.
      </BodySmall>
      {showEmpty ? (
        <Block marginTop={'@size-l'} marginBottom={'@size-l'}>
          <Empty description={'Start searching for content using the search box above.'} />
        </Block>
      ) : (
        <InfiniteQuery<
          BuiltSegmentFragment,
          ListBuiltSegmentsQuery,
          ListBuiltSegmentsQueryVariables
        >
          document={ListBuiltSegmentsDocument}
          getResult={d => d?.segments}
          setResult={(p, n) => set(cloneDeep(p), 'segments', n)}
          variables={{
            name: dNameFilter || undefined,
            types: [SegmentType.Group],
            tags: tagType && tagName ? [{ type: tagType, name: tagName }] : undefined,
            tagsOp: FilterOperation.And,
            orderBy: 'ig',
            orderDirection: OrderDirection.Desc,
          }}
          children={({ data }) => {
            if (data?.segments.edges.length) {
              return (
                <>
                  {data?.segments.edges
                    .filter((edge, index) => {
                      return !data!.segments.edges.some((otherEdge, otherIndex) => {
                        return otherIndex > index && otherEdge.node.name === edge.node.name
                      })
                    }) // This should get rid of duplicates.
                    .filter(edge => {
                      return !data!.segments.edges.some(otherEdge => {
                        return !!findSegment(
                          otherEdge.node.built.childSegments || [],
                          edge.node.built.id,
                        )
                      })
                    }) // This should get rid of items contained inside other items.
                    .map(edge => {
                      const ig = edge.node
                      return (
                        <Block key={ig.id} marginTop={'@size-l'}>
                          <OutlineContextProvider
                            renderSegment={props => (
                              <CustomSegment
                                segment={props.item.segment}
                                rawId={props.item.segment.id}
                                noMargin
                                useInClass
                              />
                            )}
                            segmentContainerProps={() => ({
                              style: {
                                display: 'flex',
                                alignItems: 'center',
                              },
                            })}
                            groupActions={props => (
                              <UseInClassButton rawId={props.item.segment.id} children={null} />
                            )}
                          >
                            <OutlineTerm
                              segments={[ig.built as GroupSegment]}
                              segmentHeight={CUSTOM_SEGMENT_HEIGHT}
                            />
                          </OutlineContextProvider>
                        </Block>
                      )
                    })}
                </>
              )
            } else {
              return (
                <Block marginTop={'@size-l'} marginBottom={'@size-l'}>
                  <Empty
                    description={
                      dNameFilter
                        ? 'We could not find any content matching your search.'
                        : 'It looks like your license does not include this content.'
                    }
                  >
                    {dNameFilter ? null : (
                      <Button
                        onClick={() => {
                          navigate('/teacher/resources')
                        }}
                        type={'primary'}
                        size={'large'}
                      >
                        Get Access
                      </Button>
                    )}
                  </Empty>
                </Block>
              )
            }
          }}
        />
      )}
    </>
  )
}
