import React from 'react'

export function useStatePropFallback<T>(
  current: T | undefined,
  update: ((val: T) => void) | undefined,
  defaultValue: T,
): [T, (val: T) => void] {
  const [state, setState] = React.useState<T>(defaultValue)
  return update !== undefined ? [current!, update] : [state, setState]
}
