import { SiteActionsHook } from '@thesisedu/feature-sites-web'
import React from 'react'

import { CanvasReactFeature } from '../CanvasReactFeature'
import { GenerateCanvasTokenMenuItem } from '../web/GenerateCanvasCredentialsTokenModal'

export default function (feature: CanvasReactFeature) {
  feature.hookManager.registerMutateHook<SiteActionsHook>(
    'sites-web:site-actions',
    (actions, { siteId }) => {
      return [<GenerateCanvasTokenMenuItem key={'canvas'} siteId={siteId} />, ...actions]
    },
  )
}
