import * as Legacy from '.'
import { WeightedWidget, Widget } from '../WidgetNode'

export function isLegacyWidget(
  widget: Legacy.Widget | Widget | Legacy.WeightedAnyWidget,
): widget is Legacy.Widget {
  return typeof widget.version === 'undefined'
}

export function isWidget(widget: Legacy.WeightedAnyWidget): widget is WeightedWidget
export function isWidget(widget: Legacy.Widget | Widget): widget is Widget
export function isWidget(
  widget: Legacy.Widget | Widget | Legacy.WeightedAnyWidget,
): widget is Widget | WeightedWidget {
  return typeof widget.version !== 'undefined'
}
