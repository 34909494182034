import { MutateSegmentStandardsHeader } from '@thesisedu/feature-courses-react'
import { ReactFeature } from '@thesisedu/feature-react'
import React from 'react'

import { SegmentStandards } from '../segments/SegmentStandards'

export default function registerStandards(feature: ReactFeature) {
  feature.hookManager.registerMutateHook<MutateSegmentStandardsHeader>(
    'feature-courses-react:segment-standards-header',
    (items, { segment }) => {
      return [...items, <SegmentStandards segmentId={segment.id} key={'standard-sets'} />]
    },
  )
}
