import { LinkNode } from '@lexical/link'
import { EditorConfig } from 'lexical'

export const modifyLinkNode = () => {
  const originalCreateDOM = LinkNode.prototype.createDOM
  LinkNode.prototype.createDOM = function (config: EditorConfig) {
    const dom = originalCreateDOM.call(this, config)
    if (!dom.href.includes(window.location.origin)) {
      dom.target = '_blank'
      dom.rel = 'noopener noreferrer'
    }
    return dom
  }

  const originalUpdateDOM = LinkNode.prototype.updateDOM
  LinkNode.prototype.updateDOM = function (
    prevNode: LinkNode,
    element: HTMLAnchorElement,
    config: EditorConfig,
  ) {
    const originalUpdate = originalUpdateDOM.call(this, prevNode, element, config)
    if (!element.href.includes(window.location.origin)) {
      element.target = '_blank'
      element.rel = 'noopener noreferrer'
    }
    return originalUpdate
  }
}
