import { MutationHookOptions } from '@apollo/client'
import { omitDeep } from '@thesisedu/feature-utils'
import { useControllableForm } from '@thesisedu/web'
import { useEffect } from 'react'

import { error } from './log'
import { useUpdateSiteMutation } from './queries/useUpdateSiteMutation'
import { UpdateSiteMutation, UpdateSiteMutationVariables, useSiteQuery } from './schema'

export const useEditSiteForm = (
  siteId: string,
  opts?: MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>,
) => {
  const { data } = useSiteQuery({ variables: { id: siteId } })
  const [updateSite] = useUpdateSiteMutation(opts)
  const result = useControllableForm(async values => {
    try {
      await updateSite({
        variables: {
          input: {
            id: siteId,
            patch: { ...omitDeep(values, ['__typename']), districtId: values.districtId || null },
          },
        },
      })
    } catch (err: any) {
      error(err)
    }
  })
  useEffect(() => {
    const site = data?.node?.__typename === 'Site' ? data.node : undefined
    if (site) {
      result.form.setFieldsValue({
        ...site,
        districtId: site.district?.id,
      })
    }
  }, [data, result])

  return result
}
