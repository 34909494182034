import { onMutationError } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper, Block, Form } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

import { FormValue, StandardSetForm } from './StandardSetForm'
import { formValueToInput } from './helpers'
import {
  CreateStandardSetInput,
  FullStandardSetFragment,
  useCreateStandardSetMutation,
} from '../schema'

export interface CreateStandardSetProps {
  onCreated: (standardSet: FullStandardSetFragment) => void
}
export function CreateStandardSet({ onCreated }: CreateStandardSetProps) {
  const [create, { loading }] = useCreateStandardSetMutation({
    refetchQueries: ['standardSets'],
    awaitRefetchQueries: true,
    onError: onMutationError('There was an error creating your standard set.'),
    onCompleted: data => {
      onCreated(data.createStandardSet.standardSet)
    },
  })
  const form = Form.useForm<FormValue>()
  return (
    <Form
      form={form}
      onFinish={values => {
        create({
          variables: {
            input: formValueToInput<CreateStandardSetInput>(values),
          },
        })
      }}
    >
      <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
        <Form.Submit
          type={'primary'}
          icon={<AntIconWrapper children={<Plus />} />}
          loading={loading}
        >
          Create Standard Set
        </Form.Submit>
      </Block>
      <StandardSetForm />
    </Form>
  )
}
