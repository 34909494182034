import { getSegmentName } from '@thesisedu/feature-course-types'
import {
  SegmentGridItemProps,
  SegmentGridRendererResource,
  SegmentGridTitle,
  SegmentGridSubtitle,
} from '@thesisedu/feature-courses-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

// @ts-ignore
import explorePrintable from '../../assets/explore-printable.jpg'
// @ts-ignore
import exploreSheetMusic from '../../assets/explore-sheet-music.jpg'

function SheetMusicRenderer({ segment }: SegmentGridItemProps) {
  const image =
    segment.config?.label?.trim() === 'Sheet Music' ? exploreSheetMusic : explorePrintable
  return (
    <>
      <BasicImage src={image} alt={segment.config?.label ?? 'Printable'} />
      <SegmentGridTitle>{getSegmentName(segment)}</SegmentGridTitle>
      <SegmentGridSubtitle>{segment.config?.label ?? segment.type}</SegmentGridSubtitle>
    </>
  )
}

const BasicImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${s.var('radii.1')};
`

export default function (root: FeatureWeb) {
  root.deps.resources.addResource<SegmentGridRendererResource>({
    type: 'SegmentGridRenderer',
    identifier: 'SheetMusicRenderer',
    height: ({ width }) => {
      return width + 125
    },
    weight: segment =>
      segment.config?.label?.trim() === 'Sheet Music' ||
      segment.config?.label?.trim() === 'Printable Option'
        ? -10
        : Infinity,
    Component: SheetMusicRenderer,
  })
}
