import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { LicenseFragmentDoc, useUpdateLicenseMutation as useMutation } from '../schema'

export const useUpdateLicenseMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that license.'),
    update: updateMutation({
      fragmentName: 'License',
      fragment: LicenseFragmentDoc,
      path: 'updateLicense.license',
    }),
    ...opts,
  })
