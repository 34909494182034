import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  useUpdateTeacherClassPermissionsMutation as useMutation,
  TeamTeachingClassFragmentDoc,
} from '../schema'

export const useUpdateTeacherClassPermissionsMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError("There was an error updating that teacher's permissions."),
    update: updateMutation({
      fragment: TeamTeachingClassFragmentDoc,
      fragmentName: 'TeamTeachingClass',
      path: 'updateTeacherClassPermissions.class',
    }),
    ...opts,
  })
