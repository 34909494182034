import { Button, Tooltip, s, styled } from '@thesisedu/ui'
import { MoreVert } from '@thesisedu/ui/icons'
import { ResetTutorialPopoversButton } from '@thesisedu/web'
import React from 'react'

import { AddContentButton } from './AddContentButton'
import { OutlineProps } from './Outline'
import { OutlineSearch } from './OutlineSearch'
import { SegmentActions } from './SegmentActions'
import { TermSelector } from './TermSelector'

export interface OutlineFiltersProps
  extends Pick<
    OutlineProps,
    | 'selectedTermId'
    | 'segments'
    | 'courseName'
    | 'showAddContent'
    | 'showTermActions'
    | 'filterContent'
    | 'headerContent'
  > {
  setSelectedTermId: (id: string) => void
}
export function OutlineFilters({
  selectedTermId,
  setSelectedTermId,
  segments,
  courseName,
  showAddContent,
  showTermActions,
  filterContent,
  headerContent,
}: OutlineFiltersProps) {
  const selectedTerm = segments?.find(s => s.id === selectedTermId)
  return (
    <FilterContainer className={'filter-container'}>
      <div style={{ background: 'white' }}>
        <FilterInner>
          <TermSelectorContainer>
            <TermSelector
              segments={segments}
              selectedTermId={selectedTermId}
              onSelectedTermChanged={setSelectedTermId}
              hideSplitView={!showAddContent}
            />
          </TermSelectorContainer>
          <OutlineSearch courseName={courseName} className={'search'} />
          {showAddContent ? (
            <AddContentButton topics={selectedTerm?.childSegments} termId={selectedTerm?.id} />
          ) : null}
          {showTermActions && selectedTerm ? (
            <SegmentActions segment={selectedTerm}>
              <Tooltip title={'Term Settings'}>
                <Button icon={<MoreVert />} />
              </Tooltip>
            </SegmentActions>
          ) : null}
          {showTermActions ? (
            <Tooltip
              title={'Click here to reset the hints for this view.'}
              contentProps={{ side: 'left' }}
            >
              <ResetTutorialPopoversButton />
            </Tooltip>
          ) : null}
          {filterContent}
        </FilterInner>
        {headerContent ? <HeaderContentContainer>{headerContent}</HeaderContentContainer> : null}
      </div>
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  margin: ${s.size('s')} 0 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
`
const FilterInner = styled.div`
  display: flex;
  align-items: center;
  height: 95px;
  background: white;
  > :not(:last-child) {
    margin-right: ${s.size('xs')};
  }
  > .search {
    flex-grow: 1;
    &:not(:last-child) {
      margin-right: ${s.size('s')};
    }
  }
`
const TermSelectorContainer = styled.div`
  min-width: 300px;
  margin-right: ${s.size('l')} !important;
`
const HeaderContentContainer = styled.div`
  padding-bottom: ${s.size('m')};
`
