import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical'
import React from 'react'

export interface CallbacksPluginProps {
  onBlur?: () => void
  onFocus?: () => void
}
export function CallbacksPlugin({ onBlur, onFocus }: CallbacksPluginProps) {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          if (onFocus) onFocus()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          if (onBlur) onBlur()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor])

  return null
}
