import { s } from '@thesisedu/ui'
import React from 'react'
import * as ToneJS from 'tone'

import { MetronomeContext, useMetronomeContext } from './MetronomeContext'
import { MetronomeContextValue, Note, Tone } from './types'

let animation: Animation | undefined = undefined
function animateOpacity(element: HTMLElement | null | undefined, target: number, duration: number) {
  animation?.cancel()
  animation = element?.animate(
    {
      opacity: [0, target, 0.1],
      offset: [0, 0.25, 1],
    },
    { duration },
  )
}

let stop: ((currentId: string) => void) | null = null

export interface ContainerProps {
  children: React.ReactNode
}
export function Container({ children }: ContainerProps) {
  const existing = useMetronomeContext(false)
  return existing ? <>{children}</> : <_Container children={children} />
}
function _Container({ children }: ContainerProps) {
  const indicatorRef = React.useRef<HTMLElement | null>(null)
  const id = React.useId()
  const [value, setValue] = React.useState<Omit<MetronomeContextValue, 'update' | 'indicatorRef'>>({
    visible: false,
    playing: false,
    bpm: 120,
    note: Note.Quarter,
    emphasizedNote: 4,
    tones: [Tone.C4, Tone.C3],
  })
  const synthRef = React.useRef<ToneJS.AMSynth | null>(null)
  const repeatRef = React.useRef<number | null>(null)
  function createSynth() {
    if (synthRef.current) {
      synthRef.current.dispose()
      synthRef.current = null
    }
    if (repeatRef.current != null) {
      ToneJS.Transport.clear(repeatRef.current)
      repeatRef.current = null
    }
    const synth = new ToneJS.AMSynth().toDestination()
    synthRef.current = synth
    if (value.playing) {
      ToneJS.Transport.stop()
    }
    let detunedNotes = 0
    ToneJS.Transport.bpm.value = value.bpm
    ToneJS.Transport.loopEnd = '1m'
    ToneJS.Transport.loop = true
    repeatRef.current = ToneJS.Transport.scheduleRepeat(() => {
      if (!synthRef.current) return
      if (indicatorRef.current && indicatorRef.current.style.opacity !== '0.1') {
        indicatorRef.current.style.background = s.color('blue')
        indicatorRef.current.style.opacity = '0.1'
      }
      if (detunedNotes === 0) {
        animateOpacity(indicatorRef.current, 1, 300)
        synth.triggerAttackRelease(value.tones[0], 0.1)
        detunedNotes = value.emphasizedNote - 1
      } else {
        animateOpacity(indicatorRef.current, 0.5, 300)
        synth.triggerAttackRelease(value.tones[1], 0.1)
        detunedNotes--
      }
    }, value.note)
    if (value.playing) {
      ToneJS.Transport.start()
    }
  }

  React.useEffect(() => {
    if (value.playing) {
      createSynth()
      return () => {
        synthRef.current?.disconnect()
        synthRef.current?.dispose()
        synthRef.current = null
        if (repeatRef.current != null) {
          ToneJS.Transport.clear(repeatRef.current)
          repeatRef.current = null
        }
      }
    }
  }, [value.bpm, value.note, value.emphasizedNote, value.tones])

  React.useEffect(() => {
    if (value.playing) {
      // Stop any others that are currently playing.
      stop?.(id)
      // Wait for react to re-render to respond to the playing: false state change.
      setTimeout(() => {
        // Re-create the synth so we can support multiple versions on the same
        // page.
        createSynth()
        ToneJS.Transport.start()
        stop = (otherId: string) => {
          if (otherId === id) return
          synthRef.current?.disconnect()
          synthRef.current?.dispose()
          synthRef.current = null
          if (repeatRef.current != null) {
            ToneJS.Transport.clear(repeatRef.current)
            repeatRef.current = null
          }
          setValue(existing => ({ ...existing, playing: false }))
          ToneJS.Transport.stop()
          stop = null
        }
      }, 10) // We might need to tweak this timeout in the future on slower machines.
    } else {
      if (indicatorRef.current) {
        indicatorRef.current.style.background = s.color('secondary')
        indicatorRef.current.style.opacity = '0.5'
      }
      synthRef.current?.disconnect()
      synthRef.current?.dispose()
      synthRef.current = null
      if (repeatRef.current != null) {
        ToneJS.Transport.clear(repeatRef.current)
        repeatRef.current = null
      }
      ToneJS.Transport.stop()
    }
  }, [value.playing])

  return (
    <MetronomeContext.Provider
      value={React.useMemo(
        () => ({
          ...value,
          indicatorRef,
          update: changes => {
            setValue(existing => ({ ...existing, ...changes }))
          },
        }),
        [value, indicatorRef],
      )}
      children={children}
    />
  )
}
