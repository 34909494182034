import { useQuery } from '@apollo/client'
import { BlockSpin } from '@thesisedu/web'
import React from 'react'

import { AttachmentRead } from './AttachmentRead'
import { AttachmentWrite } from './AttachmentWrite'
import { CommonAttachmentField } from './common/CommonAttachmentField'
import { AttachmentFieldProps, AttachmentFieldVariables } from './types'

export function AttachmentField<
  Result,
  Variables extends AttachmentFieldVariables = AttachmentFieldVariables,
>(props: AttachmentFieldProps<Result, Variables>) {
  const { data, loading } = useQuery<Result, Variables>(props.readWriteQueryDocument, {
    variables: props.variables as Variables,
    skip: !!props.fragment,
  })
  if (loading) {
    return <BlockSpin />
  } else {
    return (
      <CommonAttachmentField<Result, Variables>
        AttachmentRead={AttachmentRead}
        AttachmentWrite={AttachmentWrite}
        data={data}
        {...props}
      />
    )
  }
}
