import { Result } from '@thesisedu/react'
import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { useSegmentNavigationContext } from '../contexts/SegmentNavigationContext'
import { Segment as CSegment } from '../segment/Segment'
import { useHydratedSegment } from '../segment/useHydratedSegment'
import { Segment } from '../types'

export interface CourseViewerSegmentProps {
  segment: Segment
}
export function CourseViewerSegment({ segment: _segment }: CourseViewerSegmentProps) {
  const { segment, loading, error } = useHydratedSegment(_segment)
  if (loading) {
    return <LoadingIndicator.Labeled size={'l'} label={'Loading content...'} />
  } else if (error) {
    return <Result.Error description={'There was an error loading that content.'} />
  } else {
    return <_CourseViewerSegment segment={segment ?? _segment} />
  }
}

function _CourseViewerSegment({ segment }: CourseViewerSegmentProps) {
  const { selectedNavigationItemId } = useSegmentNavigationContext(false) || {}
  return <CSegment segment={segment} selectedNavigationId={selectedNavigationItemId} readOnly />
}
