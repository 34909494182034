import { media, H2, H2Subtitle, Body, Block, styled } from '@thesisedu/web'
import { Switch } from 'antd'
import React from 'react'

import { FeaturePreviewResource } from './types'
import { useFeaturePreview } from './useFeaturePreview'

const Container = styled.div`
  background: ${props => props.theme['@background-color-base']};
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@padding-sm']};
  margin-bottom: ${props => props.theme['@size-m']};
  ${media.lg} {
    padding: ${props => props.theme['@padding-lg']};
    margin-bottom: ${props => props.theme['@size-l']};
  }
  display: flex;
  align-items: flex-start;
  > div:first-child {
    margin-top: 4px;
    margin-right: ${props => props.theme['@size-s']};
    ${media.lg} {
      margin-right: ${props => props.theme['@size-m']};
    }
  }
`

export interface FeaturePreviewProps {
  resource: FeaturePreviewResource
}
export const FeaturePreview: React.FC<React.PropsWithChildren<FeaturePreviewProps>> = ({
  resource,
}) => {
  const [enabled, setEnabled] = useFeaturePreview(resource.identifier)
  return (
    <Container>
      <div>
        <Switch checked={enabled} onChange={checked => setEnabled(checked)} />
      </div>
      <div>
        <H2 isBlock={false}>{resource.title}</H2>
        <H2Subtitle isBlock>{resource.subtitle}</H2Subtitle>
        {resource.description}
        {resource.link ? (
          <Block marginTop={'@size-m'}>
            <Body>
              <a href={resource.link} target={'_blank'} rel={'noopener noreferrer'}>
                Learn more about this preview &rarr;
              </a>
            </Body>
          </Block>
        ) : null}
      </div>
    </Container>
  )
}
