import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { CommentFragmentDoc, useApproveCommentMutation as useMutation } from '../schema'

export const useApproveCommentMutation: typeof useMutation = opts =>
  useMutation({
    ...opts,
    onError: onMutationError('There was an error approving that comment.'),
    update: updateMutation({
      fragment: CommentFragmentDoc,
      fragmentName: 'Comment',
      path: 'approveComment.comment',
    }),
  })
