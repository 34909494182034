import { Body, Block, styled, AntIconWrapper } from '@thesisedu/web'
import { Trash } from 'iconoir-react'
import React from 'react'

import { usePasteContext } from './PasteContext'
import { TagSearch, TagSearchProps } from './TagSearch'
import { TagPair } from './types'

const Container = styled.div`
  > .item,
  > .no-items {
    padding: 5px;
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
    color: ${props => props.theme['@text-color-secondary']};
  }
  > .item {
    display: flex;
    align-items: center;
    > span:first-child {
      margin: 0 auto 0 0;
    }
    > strong,
    > span[role='img'] {
      margin: 0 0 0 5px;
    }
    > span[role='img'] {
      color: ${props => props.theme['@error-color']};
      font-size: 12px;
    }
  }
  > .new-item {
    margin-top: 10px;
  }
  > .no-items {
    color: ${props => props.theme['@text-color-secondary']};
  }
`

interface TagItemProps {
  name: string
  onDelete: () => void
}
function TagItem({ name, onDelete }: TagItemProps) {
  return (
    <TagItemContainer
      onClick={e => {
        e.preventDefault()
        onDelete()
      }}
    >
      <Body>{name}</Body>
      <Body color={'@text-color-secondary'}>
        <AntIconWrapper>
          <Trash />
        </AntIconWrapper>
      </Body>
    </TagItemContainer>
  )
}
const TagItemContainer = styled.a`
  display: inline-flex;
  align-items: center;
  margin: 0 ${props => props.theme['@size-xs']} ${props => props.theme['@size-xs']} 0;
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
  transition: background 0.1s linear;
  color: ${props => props.theme['@text-color']};
  max-width: 100%;
  > :first-child {
    flex: 1;
    min-width: 0;
  }
  * {
    transition: color 0.1s linear;
    display: block;
  }
  > :last-child {
    margin-left: ${props => props.theme['@size-xs']};
  }
  &:hover {
    background: ${props => props.theme['@red']};
    * {
      color: ${props => props.theme['@gray-0']};
    }
  }
`

export interface TagEditorProps {
  value: TagPair[]
  onChange: (value: TagPair[]) => void
  type?: string
  typeLabel?: string
  searchProps?: Partial<TagSearchProps>
}
export function TagEditor({
  value = [],
  onChange,
  type,
  typeLabel,
  searchProps = {},
}: TagEditorProps) {
  const pasteContext = usePasteContext(false)
  React.useEffect(() => {
    if (pasteContext && type) {
      const handler: React.ClipboardEventHandler = e => {
        const clipboardData = e.clipboardData.getData('text/plain')
        const newTags = clipboardData
          .split('\n')
          .map(item => item.replace(/^\s+|\s+$/g, ''))
          .filter(Boolean)
        onChange([
          ...value.filter(t => !newTags.includes(t.name)),
          ...newTags.map(t => ({ type, name: t })),
        ])
      }
      pasteContext.addPasteListener(handler)
      return () => pasteContext.removePasteListener(handler)
    }
  }, [])
  let items: React.ReactElement | React.ReactElement[] | null = null
  if (value.length) {
    items = value.map(item => (
      <TagItem
        name={item.name}
        key={[item.type, item.name].join('|')}
        onDelete={() => {
          onChange(
            value.filter(
              i =>
                i.type !== item.type ||
                i.name.toLowerCase().trim() !== item.name.toLowerCase().trim(),
            ),
          )
        }}
      />
    ))
  }

  return (
    <Container
      onPaste={e => {
        console.info(e)
      }}
    >
      <div className={'new-item'}>
        <TagSearch
          onSelect={item => {
            const existing = value.find(
              i =>
                i.type === item.type &&
                i.name.toLowerCase().trim() === item.name.toLowerCase().trim(),
            )
            if (!existing) {
              onChange([...value, item])
            }
          }}
          type={type}
          typeLabel={typeLabel}
          {...searchProps}
        />
      </div>
      <Block marginTop={'@size-s'}>{items}</Block>
    </Container>
  )
}
