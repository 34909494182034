import { AttachmentReadFragment } from '@thesisedu/feature-attachments-react/dist/schema'
import { AttachmentFormFieldValue } from '@thesisedu/feature-attachments-react/dist/types'
import { Form, FormInstance, usePrompt } from '@thesisedu/react'
import React from 'react'

import { debug } from '../log'
import { useUpdateLessonTagsMutation } from '../queries/useUpdateLessonTagsMutation'
import { BasicLessonFragment, useCreateLessonMutation } from '../schema'

export interface LessonFormValues {
  mediaId: string
  name: string
  tags: string[]
  attachment?: AttachmentReadFragment | AttachmentFormFieldValue
}
export interface UseCreateLessonOpts {
  onCreated?: (lesson: BasicLessonFragment) => void
}
export interface UseCreateLesson {
  form: FormInstance<LessonFormValues>
  onFinish: (values: LessonFormValues) => Promise<void>
  loading: boolean
}
export function useCreateLesson({ onCreated }: UseCreateLessonOpts = {}): UseCreateLesson {
  const form = Form.useForm<LessonFormValues>()
  const [createLesson, { loading }] = useCreateLessonMutation()
  const [updateTags, { loading: tagsLoading }] = useUpdateLessonTagsMutation()
  const dirty = form.formState.isDirty
  const [isCreated, setIsCreated] = React.useState(false)
  usePrompt(dirty && !isCreated)

  return {
    form,
    loading: loading || tagsLoading,
    async onFinish(values) {
      debug('creating lesson...')
      const result = await createLesson({
        variables: {
          input: {
            mediaId: values.mediaId,
            name: values.name,
            attachment: values.attachment,
          },
        },
      })
      if (result.data?.createLesson.lesson.id) {
        debug('lesson create successful. checking for tags...', values.tags)
        if (values.tags?.length) {
          debug('updating tags')
          await updateTags({
            variables: {
              input: {
                id: result.data.createLesson.lesson.id,
                tags: values.tags,
              },
            },
          })
        }
        setIsCreated(true)
        if (onCreated) {
          debug('finished; calling onCreated')
          const createdLesson = result.data.createLesson.lesson
          // We have to do this because of the usePrompt() above.
          setTimeout(() => {
            onCreated(createdLesson)
          }, 10)
        }
      }
    },
  }
}
