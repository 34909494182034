import { useExpectedErrorListener } from '@thesisedu/feature-apollo-react'
import { PlatformImport } from '@thesisedu/feature-course-types'
import React from 'react'

import { ImportErrorModal } from '../../import/ImportErrorModal'

export function Import() {
  const { error, onClose } = useExpectedErrorListener({
    error: 'INVALID_IMPORT_CSV_ERROR',
  })
  const payload: PlatformImport.InvalidImportDetails[] | undefined = error?.extensions?.details
  const isValid = Array.isArray(payload) && payload.length && payload[0]?.reason
  if (isValid && payload) {
    return <ImportErrorModal details={payload} visible onVisibleChange={onClose} />
  } else return null
}
