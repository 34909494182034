import React from 'react'
import { TypedEmitter } from 'tiny-typed-emitter'

import { getItem, storeItem, deleteItem } from './storage'
import { warn } from '../log'

interface InternalFlagEventDefinitions {
  flagUpdated: (flagName: string, value: any | null) => void
}
class InternalFlagEvents extends TypedEmitter<InternalFlagEventDefinitions> {}
const events = new InternalFlagEvents()
events.setMaxListeners(100)

export const LOCAL_STORAGE_FLAG_PREFIX = 'feature-react-flags'

function getFlagKey(key: string): string {
  return [LOCAL_STORAGE_FLAG_PREFIX, key].join('-')
}

export async function setFlag<T>(flag: string, value: T | null) {
  events.emit('flagUpdated', flag, value)
  if (value === null) {
    await deleteItem(getFlagKey(flag))
  } else {
    await storeItem(getFlagKey(flag), value)
  }
}

export async function getFlag<T>(flag: string): Promise<T | null> {
  return getItem<T>(getFlagKey(flag))
}

export function useFlag<T>(
  name: string,
  defaultValue: T,
): [T, (value: React.SetStateAction<T>) => void, { loading: boolean }] {
  const [flagValue, _setFlagValue] = React.useState<T>(defaultValue)
  const [loading, setLoading] = React.useState(true)
  React.useEffect(() => {
    setLoading(true)
    getFlag<T>(name)
      .then(val => {
        _setFlagValue(val === null ? defaultValue : val)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [name])
  React.useEffect(() => {
    const handler: InternalFlagEventDefinitions['flagUpdated'] = (flag, payload) => {
      if (flag === name) {
        _setFlagValue(payload)
      }
    }
    events.on('flagUpdated', handler)
    return () => {
      events.off('flagUpdated', handler)
    }
  }, [name])

  return [
    flagValue,
    (newValue: React.SetStateAction<T>) => {
      _setFlagValue(existing => {
        const n = typeof newValue === 'function' ? (newValue as any)(existing) : newValue
        setFlag<T>(name, n).catch(err => {
          warn('error setting flag %s to %O', name, newValue)
          warn(err)
        })
        return n
      })
    },
    { loading },
  ]
}
