import * as ReactContextMenu from '@radix-ui/react-context-menu'
import React from 'react'

import { styled } from '../../'
import { Check } from '../../../icons'
import { withSubComponents } from '../../utils/withSubComponents'
import {
  DropdownMenuItemContent,
  DropdownMenuItemContentProps,
  IndicatorStyle,
  LabelStyles,
  RightStyles,
  SeparatorStyles,
} from '../Dropdown/DropdownMenuItem'
import { ItemStyles } from '../Dropdown/itemStyles'

export interface ContextMenuItemProps
  extends ReactContextMenu.ContextMenuItemProps,
    DropdownMenuItemContentProps {
  danger?: boolean
}
function _ContextMenuItem({ icon, danger, children, ...rest }: ContextMenuItemProps) {
  return (
    <__ContextMenuItem {...rest} data-danger={danger}>
      <DropdownMenuItemContent icon={icon} children={children} />
    </__ContextMenuItem>
  )
}

export interface ContextMenuCheckboxItemProps extends ReactContextMenu.MenuCheckboxItemProps {}
function Checkbox({ children, ...props }: ContextMenuCheckboxItemProps) {
  return (
    <_CheckboxItem {...props}>
      <ContextMenuItemIndicator>
        <Check />
      </ContextMenuItemIndicator>
      {children}
    </_CheckboxItem>
  )
}

export interface ContextMenuRadioItemProps extends ReactContextMenu.MenuRadioItemProps {}
function Radio({ children, ...props }: ContextMenuRadioItemProps) {
  return (
    <_RadioItem {...props}>
      <ContextMenuItemIndicator>&bull;</ContextMenuItemIndicator>
      {children}
    </_RadioItem>
  )
}

const __ContextMenuItem = styled(ReactContextMenu.Item)`
  ${ItemStyles}
`
const _CheckboxItem = styled(ReactContextMenu.CheckboxItem)`
  ${ItemStyles}
`
const _RadioItem = styled(ReactContextMenu.RadioItem)`
  ${ItemStyles}
`
const ContextMenuItemIndicator = styled(ReactContextMenu.ItemIndicator)`
  ${IndicatorStyle}
`
const Separator = styled(ReactContextMenu.Separator)`
  ${SeparatorStyles}
`
const Label = styled(ReactContextMenu.Label)`
  ${LabelStyles}
`
const Right = styled.div`
  ${RightStyles}
`

export const ContextMenuItem = withSubComponents(_ContextMenuItem, {
  Checkbox,
  Radio,
  RadioGroup: ReactContextMenu.RadioGroup,
  Right,
  Indicator: ContextMenuItemIndicator,
  Separator,
  Label,
})
