import { useUnreadThreads } from '@thesisedu/feature-direct-messages-react'
import { Shell } from '@thesisedu/feature-web'
import { Dropdown, Text } from '@thesisedu/ui'
import { Mail } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface MessagesLeftMenuItemProps {
  classId: string
  path: string
  [key: string]: any
}
export function MessagesLeftMenuItem({ classId, path, ...rest }: MessagesLeftMenuItemProps) {
  const unreadThreads = useUnreadThreads({ filters: { classIds: [classId] } })
  return (
    <Shell.LeftMenuItem
      {...rest}
      name={'Messages'}
      icon={<Mail />}
      path={path}
      rightContent={unreadThreads ? <Text level={'s'}>{unreadThreads}</Text> : undefined}
    />
  )
}

export interface MessagesMenuItemProps {
  classId: string
  path: string
  disabled?: boolean
}
export function MessagesMenuItem({ classId, path, disabled }: MessagesMenuItemProps) {
  const unreadThreads = useUnreadThreads({ filters: { classIds: [classId] } })
  const navigate = useNavigate()
  return (
    <Dropdown.Item onClick={() => navigate(path)} icon={<Mail />} disabled={disabled}>
      Messages
      {unreadThreads ? <Dropdown.Item.Right>{unreadThreads} unread</Dropdown.Item.Right> : null}
    </Dropdown.Item>
  )
}
