import { BodyLarge } from '@thesisedu/react'
import React from 'react'

import { AssignmentCountdownTimer } from './AssignmentCountdownTimer'
import { useAssignmentViewerFormContext } from './form/AssignmentViewerFormContext'
import { useSubmissionContext } from '../contexts/SubmissionContext'
import { useAssignmentRemainingAttemptsQuery } from '../schema'

export interface AssignmentTimerProps {
  assignmentId: string
  classId: string
  children?: React.ReactNode
}
export function AssignmentTimer({ assignmentId, classId, children }: AssignmentTimerProps) {
  const { data } = useAssignmentRemainingAttemptsQuery({
    variables: {
      id: assignmentId,
      classId,
    },
  })
  const assignment = data?.node?.__typename === 'Assignment' ? data.node : undefined
  if (
    assignment?.timeLimit &&
    parseInt(assignment.timeLimit.toString(), 10) > 30 &&
    assignment.startedAt &&
    !assignment.submittedAt
  ) {
    return (
      <>
        <BodyLarge>
          <AssignmentCountdownTimer
            onTimerReached={() => {}}
            timeLimit={assignment.timeLimit}
            createdAt={assignment.startedAt}
          />
        </BodyLarge>
        {children}
      </>
    )
  } else {
    return null
  }
}

export function AssignmentActionsTimer() {
  const {
    onSaveSubmission,
    onSubmit,
    assignment: _assignment,
    classId,
  } = useSubmissionContext(true)
  const { setTimeLimitReached, form, disableFormSubmit } = useAssignmentViewerFormContext(true)
  const { data } = useAssignmentRemainingAttemptsQuery({
    variables: {
      id: _assignment.id,
      classId,
    },
  })
  const assignment = data?.node?.__typename === 'Assignment' ? data.node : undefined
  if (
    assignment?.timeLimit &&
    assignment?.startedAt &&
    parseInt(assignment.timeLimit.toString(), 10) > 30 &&
    !assignment?.submittedAt
  ) {
    return (
      <BodyLarge>
        <AssignmentCountdownTimer
          onTimerReached={async () => {
            setTimeLimitReached(true)
            const submissionId = await onSaveSubmission({
              id: assignment.id,
              classId,
              submissionData: form.getValues(),
            })
            if (submissionId && !disableFormSubmit) {
              return onSubmit({
                id: submissionId,
                force: true,
              })
            }
          }}
          timeLimit={assignment.timeLimit}
          createdAt={assignment.startedAt}
        />
      </BodyLarge>
    )
  } else {
    return null
  }
}
