/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AttachFileInput = {
  parentField: Scalars['String']['input'];
  parentType: Scalars['String']['input'];
};

export type AttachmentUploadInput = {
  name: Scalars['String']['input'];
  signedUploadInput: SignedUploadInput;
  sizeInBytes: Scalars['Float']['input'];
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type ConfirmationInput = {};

export type DeleteAttachmentInput = {
  id: Scalars['ID']['input'];
};

export type DetailedLogsForUserInput = {
  handler?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jwt: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FreeUsernameInput = {
  username: Scalars['String']['input'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Permission {
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  CreateUser = 'CREATE_USER',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  FreeUsername = 'FREE_USERNAME',
  Impersonate = 'IMPERSONATE',
  ListUser = 'LIST_USER',
  Login = 'LOGIN',
  ManageLogs = 'MANAGE_LOGS',
  Register = 'REGISTER',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  SearchUsers = 'SEARCH_USERS',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdateUser = 'UPDATE_USER',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserName = 'VIEW_USER_NAME'
}

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignedUploadInput = {
  extension: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type UnlinkAccountInput = {
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type UpdateUserDetailedLoggingInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UploadRequestMode {
  Basic = 'BASIC',
  FormData = 'FORM_DATA'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type AttachmentReadFragment_Attachment_url_SignedFetchResult = (
  {
  signedUrl: string,
  path: string
}
  & {
  __typename?: 'SignedFetchResult'
}
);

export type AttachmentReadFragment_Attachment_field_StoredAttachment = (
  {
  bucket: string,
  path: string,
  sizeInBytes: number,
  name: string
}
  & {
  __typename?: 'StoredAttachment'
}
);

export type AttachmentReadFragment = (
  {
  id: string,
  url?: AttachmentReadFragment_Attachment_url_SignedFetchResult | null,
  field?: AttachmentReadFragment_Attachment_field_StoredAttachment | null
}
  & {
  __typename?: 'Attachment'
}
);

export type AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult_upload_SignedUploadResult = (
  {
  signedUrl: string,
  data?: any | null,
  path: string
}
  & {
  __typename?: 'SignedUploadResult'
}
);

export type AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult_attachment_Attachment = (
  {
  id: string,
  url?: AttachmentReadFragment_Attachment_url_SignedFetchResult | null,
  field?: AttachmentReadFragment_Attachment_field_StoredAttachment | null
}
  & {
  __typename?: 'Attachment'
}
);

export type AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult = (
  {
  upload: AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult_upload_SignedUploadResult,
  attachment: AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult_attachment_Attachment
}
  & {
  __typename?: 'AttachmentUploadUrlResult'
}
);

export type AttachmentWriteFragment = (
  {
  id: string,
  uploadUrl: AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult
}
  & {
  __typename?: 'Attachment'
}
);

export type AttachmentReadWriteFragment = (
  {
  id: string,
  url?: AttachmentReadFragment_Attachment_url_SignedFetchResult | null,
  field?: AttachmentReadFragment_Attachment_field_StoredAttachment | null,
  uploadUrl: AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult
}
  & {
  __typename?: 'Attachment'
}
);

export type UserWithAvatarFragment_User_avatar_Attachment = (
  {
  id: string,
  url?: AttachmentReadFragment_Attachment_url_SignedFetchResult | null,
  field?: AttachmentReadFragment_Attachment_field_StoredAttachment | null
}
  & {
  __typename?: 'Attachment'
}
);

export type UserWithAvatarFragment = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean,
  avatar: UserWithAvatarFragment_User_avatar_Attachment
}
  & {
  __typename?: 'User'
}
);

export type SignedUploadResultFragment = (
  {
  signedUrl: string,
  extension: string,
  data?: any | null,
  path: string
}
  & {
  __typename?: 'SignedUploadResult'
}
);

export type StoredAttachmentFragment = (
  {
  bucket: string,
  path: string,
  mimeType: string,
  sizeInBytes: number,
  name: string
}
  & {
  __typename?: 'StoredAttachment'
}
);

export type UserFragment = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  phone?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type AttachFileMutation_attachFile_Attachment = (
  {
  id: string,
  uploadUrl: AttachmentWriteFragment_Attachment_uploadUrl_AttachmentUploadUrlResult
}
  & {
  __typename?: 'Attachment'
}
);

export type AttachFileMutation_Mutation = (
  {
  attachFile: AttachFileMutation_attachFile_Attachment
}
  & {
  __typename?: 'Mutation'
}
);


export type AttachFileMutationVariables = Exact<{
  attachInput: AttachFileInput;
  attachmentWriteInput: AttachmentUploadInput;
}>;


export type AttachFileMutation = AttachFileMutation_Mutation;

export type AttachGenericFileMutation_attachGenericFile_SignedUploadResult = (
  {
  signedUrl: string,
  data?: any | null,
  path: string,
  extension: string
}
  & {
  __typename?: 'SignedUploadResult'
}
);

export type AttachGenericFileMutation_Mutation = (
  {
  attachGenericFile: AttachGenericFileMutation_attachGenericFile_SignedUploadResult
}
  & {
  __typename?: 'Mutation'
}
);


export type AttachGenericFileMutationVariables = Exact<{
  input: SignedUploadInput;
}>;


export type AttachGenericFileMutation = AttachGenericFileMutation_Mutation;

export type DeleteAttachmentMutation_deleteAttachment_Attachment = (
  {
  id: string,
  url?: AttachmentReadFragment_Attachment_url_SignedFetchResult | null,
  field?: AttachmentReadFragment_Attachment_field_StoredAttachment | null
}
  & {
  __typename?: 'Attachment'
}
);

export type DeleteAttachmentMutation_Mutation = (
  {
  deleteAttachment: DeleteAttachmentMutation_deleteAttachment_Attachment
}
  & {
  __typename?: 'Mutation'
}
);


export type DeleteAttachmentMutationVariables = Exact<{
  input: DeleteAttachmentInput;
}>;


export type DeleteAttachmentMutation = DeleteAttachmentMutation_Mutation;

export type GenericFetchUrlQuery_genericFetchUrl_SignedFetchResult = (
  {
  signedUrl: string,
  path: string,
  requireHeaders?: any | null,
  isCacheable?: boolean | null
}
  & {
  __typename?: 'SignedFetchResult'
}
);

export type GenericFetchUrlQuery_Query = (
  {
  genericFetchUrl: GenericFetchUrlQuery_genericFetchUrl_SignedFetchResult
}
  & {
  __typename?: 'Query'
}
);


export type GenericFetchUrlQueryVariables = Exact<{
  path: Scalars['String']['input'];
}>;


export type GenericFetchUrlQuery = GenericFetchUrlQuery_Query;

export const AttachmentReadFragmentDoc = gql`
    fragment AttachmentRead on Attachment {
  id
  url {
    signedUrl
    path
  }
  field {
    bucket
    path
    sizeInBytes
    name
  }
}
    `;
export const AttachmentWriteFragmentDoc = gql`
    fragment AttachmentWrite on Attachment {
  id
  uploadUrl(input: $attachmentWriteInput) {
    upload {
      signedUrl
      data
      path
    }
    attachment {
      ...AttachmentRead
    }
  }
}
    `;
export const AttachmentReadWriteFragmentDoc = gql`
    fragment AttachmentReadWrite on Attachment {
  ...AttachmentRead
  ...AttachmentWrite
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  phone
  firstName
  lastName
  username
  group
  status
  createdAt
  updatedAt
  lastActivity
  canUpdate
}
    `;
export const UserWithAvatarFragmentDoc = gql`
    fragment UserWithAvatar on User {
  ...User
  avatar {
    ...AttachmentRead
  }
}
    `;
export const SignedUploadResultFragmentDoc = gql`
    fragment SignedUploadResult on SignedUploadResult {
  signedUrl
  extension
  data
  path
}
    `;
export const StoredAttachmentFragmentDoc = gql`
    fragment StoredAttachment on StoredAttachment {
  bucket
  path
  mimeType
  sizeInBytes
  name
}
    `;
export const AttachFileDocument = gql`
    mutation attachFile($attachInput: AttachFileInput!, $attachmentWriteInput: AttachmentUploadInput!) {
  attachFile(input: $attachInput) {
    ...AttachmentWrite
  }
}
    ${AttachmentWriteFragmentDoc}
${AttachmentReadFragmentDoc}`;
export type AttachFileMutationFn = ApolloReactCommon.MutationFunction<AttachFileMutation, AttachFileMutationVariables>;

/**
 * __useAttachFileMutation__
 *
 * To run a mutation, you first call `useAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFileMutation, { data, loading, error }] = useAttachFileMutation({
 *   variables: {
 *      attachInput: // value for 'attachInput'
 *      attachmentWriteInput: // value for 'attachmentWriteInput'
 *   },
 * });
 */
export function useAttachFileMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<AttachFileMutation, AttachFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<AttachFileMutation, AttachFileMutationVariables>(AttachFileDocument, options);
      }
export type AttachFileMutationHookResult = ReturnType<typeof useAttachFileMutation>;
export type AttachFileMutationResult = ApolloReactCommon.MutationResult<AttachFileMutation>;
export type AttachFileMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachFileMutation, AttachFileMutationVariables>;
export const AttachGenericFileDocument = gql`
    mutation attachGenericFile($input: SignedUploadInput!) {
  attachGenericFile(input: $input) {
    signedUrl
    data
    path
    extension
  }
}
    `;
export type AttachGenericFileMutationFn = ApolloReactCommon.MutationFunction<AttachGenericFileMutation, AttachGenericFileMutationVariables>;

/**
 * __useAttachGenericFileMutation__
 *
 * To run a mutation, you first call `useAttachGenericFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachGenericFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachGenericFileMutation, { data, loading, error }] = useAttachGenericFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAttachGenericFileMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<AttachGenericFileMutation, AttachGenericFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<AttachGenericFileMutation, AttachGenericFileMutationVariables>(AttachGenericFileDocument, options);
      }
export type AttachGenericFileMutationHookResult = ReturnType<typeof useAttachGenericFileMutation>;
export type AttachGenericFileMutationResult = ApolloReactCommon.MutationResult<AttachGenericFileMutation>;
export type AttachGenericFileMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachGenericFileMutation, AttachGenericFileMutationVariables>;
export const DeleteAttachmentDocument = gql`
    mutation deleteAttachment($input: DeleteAttachmentInput!) {
  deleteAttachment(input: $input) {
    ...AttachmentRead
  }
}
    ${AttachmentReadFragmentDoc}`;
export type DeleteAttachmentMutationFn = ApolloReactCommon.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = ApolloReactCommon.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const GenericFetchUrlDocument = gql`
    query genericFetchUrl($path: String!) {
  genericFetchUrl(path: $path) {
    signedUrl
    path
    requireHeaders
    isCacheable
  }
}
    `;

/**
 * __useGenericFetchUrlQuery__
 *
 * To run a query within a React component, call `useGenericFetchUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericFetchUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericFetchUrlQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGenericFetchUrlQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GenericFetchUrlQuery, GenericFetchUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GenericFetchUrlQuery, GenericFetchUrlQueryVariables>(GenericFetchUrlDocument, options);
      }
export function useGenericFetchUrlLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GenericFetchUrlQuery, GenericFetchUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GenericFetchUrlQuery, GenericFetchUrlQueryVariables>(GenericFetchUrlDocument, options);
        }
export type GenericFetchUrlQueryHookResult = ReturnType<typeof useGenericFetchUrlQuery>;
export type GenericFetchUrlLazyQueryHookResult = ReturnType<typeof useGenericFetchUrlLazyQuery>;
export type GenericFetchUrlQueryResult = ApolloReactCommon.QueryResult<GenericFetchUrlQuery, GenericFetchUrlQueryVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}
