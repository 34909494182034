import React from 'react'

export interface DraggingOverList {
  sortableId: string
  overId: string
}
export interface SplitViewDraggingOverContextValue {
  draggingOverList?: React.RefObject<DraggingOverList | null | undefined>
}
export const SplitViewDraggingOverContext = React.createContext<
  SplitViewDraggingOverContextValue | undefined
>(undefined)

export function useSplitViewDraggingOverContext(): SplitViewDraggingOverContextValue | undefined
export function useSplitViewDraggingOverContext(
  require: false,
): SplitViewDraggingOverContextValue | undefined
export function useSplitViewDraggingOverContext(require: true): SplitViewDraggingOverContextValue
export function useSplitViewDraggingOverContext(
  require?: boolean,
): SplitViewDraggingOverContextValue | undefined {
  const context = React.useContext(SplitViewDraggingOverContext)
  if (!context && require) {
    throw new Error('SplitViewDraggingOverContext is required, yet not provided.')
  }
  return context
}
