import { Empty } from '@thesisedu/react'
import { AspectRatioBox, H3Alternate, VSpaced } from '@thesisedu/web'
import { COLORS } from '@thesisedu/web/dist/nivo/theme'
import { Bar, HorizontalBar, ResponsiveBarProps } from '@thesisedu/web/nivo'
import React from 'react'

import { GraphConfiguration } from './GraphConfig'
import { GraphData, _getGraphData } from './data'
import { useGraphTitle } from './title'
import { useDimensionContexts } from './useDimensionContexts'
import { useReportResources } from '../../execute/input'
import { ReportDimensionResource, ReportMetricResource } from '../../types'
import { ReportResultsProps } from '../ReportResults'
import { getFormattedMetric } from '../getFormattedMetric'
import { getAxesResponsibility, isMetrics } from '../grid/axes'

export interface GraphProps extends ReportResultsProps {
  config: GraphConfiguration
  noAnimation?: boolean
  noMaxHeight?: boolean
}
export function Graph({ data, report, config, noAnimation, noMaxHeight }: GraphProps) {
  const resources = useReportResources()
  const { row, col } = getAxesResponsibility(resources, report)
  const dimensionContexts = useDimensionContexts()
  const { selectedMetricKey, selectedDimensionIdentifier, selectedDimensionValue } = config
  const selectedMetric = selectedMetricKey
    ? resources.metrics.find(m => m.identifier === selectedMetricKey)
    : undefined
  const selectedDimension = selectedDimensionIdentifier
    ? resources.dimensions.find(d => d.identifier === selectedDimensionIdentifier)
    : undefined
  const title = useGraphTitle({
    report,
    data,
    config,
    resources,
  })

  const commonGraphDataOpts = {
    data,
    dimensionContexts,
    report,
    responsibility: { row, col },
    filterDimension: config.selectedDimensionValue,
  }

  let graphData: GraphData[]
  let metric: ReportMetricResource
  let mainDimension: ReportDimensionResource<any, any>
  if (isMetrics(row) || isMetrics(col)) {
    const metricResponsibility = isMetrics(row) ? row : isMetrics(col) ? col : []
    const dimensionResponsibility = !isMetrics(row) ? row : !isMetrics(col) ? col : undefined
    const m =
      metricResponsibility.length > 1
        ? metricResponsibility.find(r => r.identifier === selectedMetricKey) ||
          metricResponsibility[0]
        : metricResponsibility[0]
    if (!m) throw new Error(`Metric '${selectedMetricKey}' is invalid.`)
    if (!dimensionResponsibility) throw new Error('Could not find dimension responsibility.')
    metric = m
    mainDimension = dimensionResponsibility
    graphData = _getGraphData({
      ...commonGraphDataOpts,
      metric,
      dimension: dimensionResponsibility,
    })
  } else if (
    selectedMetric &&
    selectedDimension &&
    selectedDimensionValue &&
    !isMetrics(row) &&
    !isMetrics(col)
  ) {
    const otherDimension = selectedDimension
    mainDimension = row.identifier === selectedDimension.identifier ? col : row
    metric = selectedMetric
    graphData = _getGraphData({
      ...commonGraphDataOpts,
      metric,
      dimension: mainDimension,
      otherDimension,
    })
  } else return null

  const isHorizontal = mainDimension.graph.useHorizontalGraph
  const barProps: ResponsiveBarProps<GraphData> = {
    animate: !noAnimation,
    isInteractive: true,
    data: graphData,
    colors: [COLORS[0]],
    keys: [metric.identifier],
    indexBy: 'dimensionLabel',
    margin: { left: 100, top: 30, bottom: 30, right: 30 },
    maxValue: metric.graphAxisMax || 'auto',
    valueLabels: {
      [metric.identifier]: {
        name: metric.title,
        formatter: value => getFormattedMetric(value, metric),
      },
    },
  }

  if (graphData.length === 0) {
    return <Empty description={'No data!'} />
  } else {
    return (
      <VSpaced style={{ height: '100%' }} align={'stretch'}>
        <div>
          <H3Alternate style={{ textAlign: 'center', width: '70%', margin: '0 auto' }}>
            {title}
          </H3Alternate>
        </div>
        {isHorizontal ? (
          <div style={{ flex: 1, overflowY: 'auto' }}>
            <div style={{ width: '100%', height: `${100 + graphData.length * 35}px` }}>
              <HorizontalBar<GraphData>
                {...barProps}
                data={[...barProps.data].reverse()}
                axisBottom={{
                  legend: metric.title,
                  legendOffset: 40,
                  legendPosition: 'middle',
                  format: value => getFormattedMetric(value, metric, true),
                }}
                margin={{ left: 30, top: 30, bottom: 60, right: 30 }}
              />
            </div>
          </div>
        ) : (
          <AspectRatioBox ratio={9 / 16}>
            <div style={noMaxHeight ? undefined : { maxHeight: 'calc(100vh - 275px)' }}>
              <Bar<GraphData>
                {...barProps}
                layout={'vertical'}
                margin={{ left: 100, top: 30, bottom: 30, right: 30 }}
                axisLeft={{
                  legend: metric.title,
                  legendOffset: -80,
                  format: value => getFormattedMetric(value, metric, true),
                }}
              />
            </div>
          </AspectRatioBox>
        )}
      </VSpaced>
    )
  }
}
