import { BodyLarge, BodySmall, styled } from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'

import { TrialRequestCampaignFragment } from '../schema'

export interface CampaignListItemProps {
  campaign: TrialRequestCampaignFragment
  onClick: () => void
  isSelected?: boolean
}
export function CampaignListItem({ campaign, onClick, isSelected }: CampaignListItemProps) {
  return (
    <Container selected={isSelected} onClick={onClick}>
      <BodyLarge>{campaign.identifier}</BodyLarge>
      <BodySmall color={'@text-color-secondary'}>
        {pluralize('trial', campaign.trialRequests.totalCount, true)}
      </BodySmall>
    </Container>
  )
}

const Container = styled.div<{ selected?: boolean }>`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition:
    background 0.25s linear,
    color 0.25s linear;
  cursor: pointer;
  color: ${props => props.theme['@gray-6']};
  &:hover {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
  ${props => (props.selected ? '&' : '&.noop')} {
    background: ${props => props.theme['@primary-color-extra-light']};
    color: ${props => props.theme['@primary-color']};
  }
`
