import { Descriptions } from 'antd'
import { DescriptionsProps } from 'antd/es/descriptions'
import React from 'react'

import { LooseTagSummary, TagType } from './types'

export interface TagDescriptionsProps extends DescriptionsProps {
  types: TagType[]
  tagSummary: LooseTagSummary
  title?: string
}
export function TagDescriptions({
  types,
  tagSummary,
  title = 'Tags',
  ...props
}: TagDescriptionsProps) {
  return (
    <Descriptions
      bordered
      title={title}
      size={'small'}
      layout={'vertical'}
      column={{ xs: 1, lg: 2 }}
      {...props}
    >
      {Object.keys(tagSummary).length ? (
        Object.keys(tagSummary)
          .map(tagKey => {
            const tagType = types.find(type => type.type === tagKey)
            if (tagType) {
              return (
                <Descriptions.Item key={tagType.type} label={tagType.label}>
                  <ul>
                    {tagSummary[tagKey].map(value => (
                      <li key={value}>{value}</li>
                    ))}
                  </ul>
                </Descriptions.Item>
              )
            } else {
              return null
            }
          })
          .filter(Boolean)
      ) : (
        <Descriptions.Item>No tags found.</Descriptions.Item>
      )}
    </Descriptions>
  )
}
