import { styled, s } from '@thesisedu/ui'
import { WarningCircle, Link, CheckCircle } from '@thesisedu/ui/icons'
import React from 'react'

export interface ErrorIndicatorProps {
  className?: string
  style?: any
}
export function ErrorIndicator(props: ErrorIndicatorProps) {
  return (
    <Container {...props} $color={'red'}>
      <IconContainer>
        <WarningCircle />
      </IconContainer>
    </Container>
  )
}

export function ConnectIndicator(props: ErrorIndicatorProps) {
  return (
    <Container {...props} $color={'blue'}>
      <IconContainer>
        <Link />
      </IconContainer>
    </Container>
  )
}

export function SuccessIndicator(props: ErrorIndicatorProps) {
  return (
    <Container {...props} $color={'green'}>
      <IconContainer>
        <CheckCircle />
      </IconContainer>
    </Container>
  )
}

const Container = styled.div<{ $color: keyof s.ColorAliases }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  --shadow-color: ${props => s.opacity(s.color(`${props.$color}.solid`), 0.9)};
  --color: ${props => s.color(props.$color)};
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    border-radius: 9999px;
    height: ${s.size('xs')};
    background: var(--color);
  }
`
const { keyframes } = s
const iconEnter = keyframes`
  0% {
    box-shadow: 0 0 0px var(--shadow-color);
  }
  15% {
    box-shadow: 0 0 0px var(--shadow-color);
  }
  75% {
    box-shadow: 0 0 15px var(--shadow-color);
  }
  100% {
    box-shadow: 0 0 0 var(--shadow-color);
  }
`
const IconContainer = styled.div`
  color: var(--color);
  border-radius: 50%;
  font-size: ${s.size('m')};
  padding: 2px;
  background: ${s.color('background')};
  animation: ${iconEnter} 1s ease-in-out;
  animation-delay: 0.5s;
  z-index: 1;
`
