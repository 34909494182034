export async function storeItem<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value))
}

export async function deleteItem(key: string) {
  localStorage.removeItem(key)
}

export async function getItem<T>(key: string): Promise<T | null> {
  try {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    } else return null
  } catch {
    return null
  }
}
