import { getFragmentDefinitions } from '@apollo/client/utilities'
import { modifyFragmentDocument } from '@thesisedu/feature-apollo-react'
import { FeatureReactRoot } from '@thesisedu/feature-react'
import { DefinitionNode, DocumentNode } from 'graphql'
import gql from 'graphql-tag'

import { QUERY_DOC_BASE } from './queries/searchQuery'
import { SearchFragmentsMutateHook } from './types'

let createdDocument: DocumentNode | undefined = undefined

export function getSearchQueryDocument(root: FeatureReactRoot<any>) {
  if (!createdDocument) {
    const fragments = root.deps.hookManager.mutateHookSync<SearchFragmentsMutateHook>(
      'feature-search:fragments',
      [],
      undefined,
    )
    let resultingDocument = QUERY_DOC_BASE
    const documentNodes: DocumentNode[] = []
    resultingDocument = resultingDocument.replace(
      'ADDITIONAL_FRAGMENTS',
      fragments
        .map(f => {
          if (typeof f === 'string') {
            return f
          } else {
            documentNodes.push(modifyFragmentDocument(f, root.deps.hookManager))
            const fragmentName = getFragmentDefinitions(f)[0]!.name.value
            return `...${fragmentName}`
          }
        })
        .join('\n'),
    )
    createdDocument = gql(resultingDocument)

    // Add in the modified fragments.
    const addedFragmentNames = new Set<string>()
    createdDocument = {
      ...createdDocument,
      definitions: [
        ...createdDocument.definitions,
        ...documentNodes.reduce<DefinitionNode[]>((acc, documentNode) => {
          const fragments = getFragmentDefinitions(documentNode).filter(def => {
            if (addedFragmentNames.has(def.name.value)) {
              return false
            } else {
              addedFragmentNames.add(def.name.value)
              return true
            }
          })
          return [...acc, ...fragments]
        }, []),
      ],
    }
  }
  return createdDocument
}
