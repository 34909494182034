import { Container } from '@thesisedu/feature-tags-react/dist/TagsEditor'
import { Button } from '@thesisedu/react'
import pluralize from 'pluralize'
import React from 'react'

import { StandardsEditorModal, StandardsEditorModalProps } from './StandardsEditorModal'

export interface StandardsEditorProps
  extends Omit<StandardsEditorModalProps, 'visible' | 'onClose'> {}
export function StandardsEditor({ value, onChange, ...rest }: StandardsEditorProps) {
  const [visible, setVisible] = React.useState(false)
  const totalStandards = value
    ? Object.keys(value)
        .filter(k => k.startsWith('SS_'))
        .reduce((total, key) => total + value[key].length, 0)
    : 0

  return (
    <Container>
      <StandardsEditorModal
        {...rest}
        value={value}
        onChange={onChange}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <span>{pluralize('standard', totalStandards, true)}</span>
      <Button onClick={() => setVisible(true)}>Edit Standards &rarr;</Button>
    </Container>
  )
}
