import * as React from 'react'

function SvgNoteHic(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#ED3131'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={2.45}
          d={
            'M169.333 98.147l-22.014 26.998a36.94 36.94 0 00-11.901-9.164 13.53 13.53 0 00-8.79-.932 11.538 11.538 0 00-6.223 4.4 32.991 32.991 0 00-4.045 6.783 50.386 50.386 0 00-2.748 8.956c0 .181.181 1.268.544 3.287a23.273 23.273 0 002.593 6.704 12.697 12.697 0 005.912 5.462c3.396 1.146 7.1.97 10.372-.492a50.979 50.979 0 0016.283-11.182L174 165.085a24.474 24.474 0 01-6.534 7.429 57.318 57.318 0 01-11.072 6.885 79.765 79.765 0 01-14.079 5.177 71.182 71.182 0 01-15.557 2.382 53.734 53.734 0 01-15.739-1.709 39.568 39.568 0 01-14.287-7.015 49 49 0 01-14.08-15.945 49.999 49.999 0 01-5.418-18.12 55.044 55.044 0 011.452-18.584 81.968 81.968 0 016.534-17.473 73.791 73.791 0 019.956-14.676 57.685 57.685 0 0111.642-10.354 49.308 49.308 0 019.413-4.09 53.599 53.599 0 0110.371-1.864 39.268 39.268 0 0112.965 1.113 52.11 52.11 0 0114.597 6.342 63.204 63.204 0 0115.169 13.564z'
          }
        />
        <path
          fill={'#EC2224'}
          d={
            'M128 10.03c-31.51 0-61.136 12.27-83.417 34.552C22.3 66.864 10.029 96.49 10.029 128c0 31.51 12.271 61.136 34.554 83.418C66.863 233.699 96.489 245.97 128 245.97c31.51 0 61.136-12.272 83.418-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.271-61.136-34.553-83.418C189.136 22.3 159.51 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.315 194.332 0 162.19 0 128c0-34.19 13.315-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.685 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.334 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteHic = React.memo(SvgNoteHic)
export default MemoSvgNoteHic
