import * as ReactContextMenu from '@radix-ui/react-context-menu'
import React from 'react'

import { styled, s } from '../../'
import { NavArrowRight } from '../../../icons'
import { usePortalContainer } from '../../context/ScrollableContainerContext'
import { withSubComponents } from '../../utils/withSubComponents'
import {
  DropdownContentStyles,
  DropdownMenuItem,
  DropdownMenuItemContent,
  DropdownMenuItemContentProps,
} from '../Dropdown'
import { ItemStyles } from '../Dropdown/itemStyles'

export interface ContextMenuProps extends ReactContextMenu.ContextMenuContentProps {}
export function ContextMenu({ children, ...rest }: ContextMenuProps) {
  return (
    <ReactContextMenu.Portal container={usePortalContainer()}>
      <_ContextMenuContent {...rest}>{children}</_ContextMenuContent>
    </ReactContextMenu.Portal>
  )
}

export interface ContextSubMenuTriggerProps
  extends ReactContextMenu.ContextMenuSubTriggerProps,
    DropdownMenuItemContentProps {}
function ContextSubMenuTrigger({ children, icon, ...rest }: ContextSubMenuTriggerProps) {
  return (
    <_SubTrigger {...rest}>
      <DropdownMenuItemContent icon={icon}>
        {children}
        <DropdownMenuItem.Right>
          <NavArrowRight />
        </DropdownMenuItem.Right>
      </DropdownMenuItemContent>
    </_SubTrigger>
  )
}

interface ContextSubMenuContentProps extends ReactContextMenu.ContextMenuSubContentProps {}
function ContextSubMenuContent(props: ContextSubMenuContentProps) {
  return (
    <ReactContextMenu.Portal container={usePortalContainer()}>
      <_ContextMenuSubContent {...props} />
    </ReactContextMenu.Portal>
  )
}

export const ContextSubMenu = withSubComponents(ReactContextMenu.Sub, {
  Trigger: ContextSubMenuTrigger,
  Content: ContextSubMenuContent,
})

export const ContextMenuTrigger = ReactContextMenu.Trigger

const { css } = s
const _ContentStyles = css`
  ${DropdownContentStyles}
  min-width: 220px;
  max-height: var(--radix-context-menu-content-available-height);
  overflow-y: auto;
  will-change: transform, opacity;
`
const _ContextMenuContent = styled(ReactContextMenu.Content)`
  ${_ContentStyles}
`
const _ContextMenuSubContent = styled(ReactContextMenu.SubContent)`
  ${_ContentStyles}
`
const _SubTrigger = styled(ReactContextMenu.SubTrigger)`
  ${ItemStyles}
  &[data-state="open"] {
    background-color: ${s.color('element')};
  }
`
