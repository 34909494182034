import { Button, message, Slider } from 'antd'
import FileSaver from 'file-saver'
import html2canvas from 'html2canvas'
import { SaveFloppyDisk } from 'iconoir-react'
import React from 'react'

import { AntIconWrapper } from './AntIconWrapper'
import { Block, HSpaced } from './Block'
import { Modal } from './Modal'
import { Body, BodyLarge } from './Typography'
import { styled } from './styledTypes'

const DEFAULT_WIDTH = 800
export interface SaveAsImageProps {
  children: React.ReactElement
  contentWrapper?: React.FC<React.PropsWithChildren<{ children: React.ReactElement | null }>>
  visible?: boolean
  onCancel: () => void
  background?: string
}
export function SaveAsImage({
  children,
  contentWrapper: ContentWrapper,
  visible,
  onCancel,
  background,
}: SaveAsImageProps) {
  const [loading, setLoading] = React.useState(false)
  const [width, setWidth] = React.useState(DEFAULT_WIDTH)
  const [dWidth, setDWidth] = React.useState(DEFAULT_WIDTH)
  React.useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setShowContent(false)
        setDWidth(width)
        setTimeout(() => {
          setShowContent(true)
        }, 300)
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [width])
  const containerRef = React.useRef<HTMLDivElement>(null)

  // This is here because we need to make sure the container is ready for content
  // that checks widths before rendering.
  const [showContent, setShowContent] = React.useState(false)
  React.useEffect(() => {
    if (visible) {
      const handle = setTimeout(() => {
        setShowContent(true)
      }, 100)
      return () => clearTimeout(handle)
    } else {
      setShowContent(false)
    }
  }, [visible])

  return (
    <Modal
      visible={visible}
      title={'Save as Image'}
      onCancel={onCancel}
      width={900}
      bodyStyle={{ maxWidth: '90vw' }}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key={'save'}
          type={'primary'}
          loading={loading}
          icon={<AntIconWrapper children={<SaveFloppyDisk />} />}
          onClick={async e => {
            e.preventDefault()
            setLoading(true)
            try {
              if (containerRef.current) {
                const canvas = await html2canvas(containerRef.current, {
                  backgroundColor: background || null,
                  scale: 4,
                })
                if (canvas) {
                  canvas.toBlob(blob => {
                    if (blob) {
                      FileSaver(blob, 'content.png')
                    } else {
                      message.error('There was an error saving as an image.')
                    }
                    setLoading(false)
                  })
                } else {
                  message.error('There was an error saving as an image.')
                }
              } else {
                message.error('There was an error saving as an image.')
              }
            } catch {
              setLoading(false)
            }
          }}
        >
          Save as Image
        </Button>,
      ]}
    >
      <BodyLarge>
        <strong>This is a preview.</strong>
      </BodyLarge>
      <Body isBlock>
        If you are satisfied with how the content looks, click <strong>Save as Image</strong> below.
      </Body>
      <Block marginBottom={'@size-m'}>
        <HSpaced>
          <Body>Scale:</Body>
          <div style={{ flex: 1 }}>
            <Slider value={width} onChange={setWidth} min={300} max={2000} />
          </div>
        </HSpaced>
      </Block>
      <OuterContainer style={{ zoom: DEFAULT_WIDTH / dWidth }}>
        <div ref={containerRef} style={{ padding: 30, width: dWidth }}>
          {ContentWrapper ? (
            <ContentWrapper children={showContent ? children : null} />
          ) : showContent ? (
            children
          ) : null}
        </div>
      </OuterContainer>
    </Modal>
  )
}

const OuterContainer = styled.div`
  border: solid 1px ${props => props.theme['@border-color-base']};
  border-radius: ${props => props.theme['@border-radius-base']};
  * {
    overflow: visible !important;
  }
`
