import { ExclamationCircleOutlined } from '@ant-design/icons'
import { isInNode } from '@thesisedu/feature-react'
import { styled, LargeBlock, H2 } from '@thesisedu/web'
import React from 'react'

export interface ErrorOutputProps {
  error: Error
  extraStack?: string
}
export function ErrorOutput({ error, extraStack }: ErrorOutputProps) {
  return (
    <Container>
      <HeaderContainer>
        <ExclamationCircleOutlined /> &nbsp;We ran into an error.
      </HeaderContainer>
      <ContentContainer>
        {`${error.toString()}\n\n${error.stack}`}
        {extraStack ? `\n\n${extraStack}` : null}
      </ContentContainer>
    </Container>
  )
}

const Container = isInNode()
  ? (undefined as any)
  : styled(LargeBlock)`
      width: 90vw;
      height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 0;
      margin: 2.5vh auto 0 auto;
    `
const HeaderContainer = isInNode()
  ? (undefined as any)
  : styled(H2)`
      background: ${props => props.theme['@red']};
      color: ${props => props.theme['@gray-0']} !important;
      padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-l']};
      margin: 0 !important;
    `
const ContentContainer = isInNode()
  ? (undefined as any)
  : styled.div`
      padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-l']}
        ${props => props.theme['@size-l']} ${props => props.theme['@size-l']};
      font-family: ${props => props.theme['@code-family']};
      font-size: 18px;
      color: ${props => props.theme['@gray-2']} !important;
      background: ${props => props.theme['@gray-7']};
      flex: 1;
      overflow: auto;
      display: block;
      margin: 0;
      white-space: pre-wrap;
    `
