import { DeleteLink, Body, BodySmall, DATE_FORMATS, FontWeight } from '@thesisedu/web'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import Case from 'case'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteSubscriptionButton } from './DeleteSubscriptionButton'
import { UsageSummary } from './UsageSummary'
import { LicenseSubscriptionFragment, LicenseSubscriptionType } from './schema'

export const SubscriptionsTable: React.FC<
  React.PropsWithChildren<Partial<TableProps<LicenseSubscriptionFragment>>>
> = props => (
  <Table<LicenseSubscriptionFragment> rowKey={'id'} pagination={false} {...props}>
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={['user', 'id']}
      title={'User'}
      render={(id, record) =>
        record.user ? (
          <Link to={`/admin/users/${id}`}>{record.user.name || record.user.username}</Link>
        ) : (
          '(deleted)'
        )
      }
    />
    <Table.Column dataIndex={['class', 'name']} title={'Course'} />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'id'}
      title={'Site'}
      render={(_, fragment) =>
        fragment.license ? (
          <Link to={`/admin/sites/${fragment.license?.site.id}`}>{fragment.license.site.name}</Link>
        ) : null
      }
    />
    <Table.Column dataIndex={'type'} title={'Type'} render={Case.title} />
    <Table.Column dataIndex={'size'} title={'Size'} render={Case.title} />
    <Table.Column
      dataIndex={'expiresAt'}
      title={'Expires'}
      render={expiresAt => (expiresAt ? moment(expiresAt).format(DATE_FORMATS.FULL) : 'Never')}
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'nextValidityCheck'}
      title={'Validity'}
      render={(nextCheck, record) => (
        <>
          <Body
            style={{ display: 'inline' }}
            weight={FontWeight.Bold}
            color={record.isValid ? '@green' : '@orange'}
          >
            {record.isValid ? 'Valid' : 'Invalid'}
          </Body>
          <BodySmall style={{ display: 'inline' }} color={'@text-color-secondary'}>
            {` next check: ${moment(nextCheck).format(DATE_FORMATS.FULL)}`}
          </BodySmall>
        </>
      )}
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'usage'}
      title={'Usage'}
      render={(_, record) =>
        record.type === LicenseSubscriptionType.Individual ? (
          <UsageSummary usage={record.used || undefined} quota={record.quota || undefined} />
        ) : null
      }
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'id'}
      onCell={() => ({ style: { textAlign: 'right' } })}
      render={(_, record) => (
        <>
          <DeleteSubscriptionButton subscriptionId={record.id}>
            <DeleteLink>Delete &rarr;</DeleteLink>
          </DeleteSubscriptionButton>
          <Link to={`/admin/subscriptions/${record.id}`} style={{ whiteSpace: 'nowrap' }}>
            Edit &rarr;
          </Link>
        </>
      )}
    />
  </Table>
)
