import { ModalNavigationItem } from '@thesisedu/react'
import { styled, s, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { useSegmentNavigationItems } from './useSegmentNavigationItems'
import { useCourseViewerModalContext } from '../contexts/CourseViewerModalContext'
import { useHeadingScrollContext } from '../contexts/HeadingScrollContext'
import {
  fromNavigationKey,
  toNavigationKey,
  useSegmentNavigationContext,
} from '../contexts/SegmentNavigationContext'
import { SegmentNavigationItem } from '../types'

export function getSelections(
  selectedNavigationItemId: string | undefined,
  id: string,
  parents: string[],
) {
  const selectedParents = fromNavigationKey(selectedNavigationItemId || '')
  const selected =
    [...parents, id].every((item, index) => selectedParents[index] === item) &&
    selectedParents.length >= parents.length + 1
  const parentSelected =
    !!parents.length && parents.every((item, index) => selectedParents[index] === item)
  return { selected, parentSelected }
}

interface SegmentNavigationItemCProps {
  navigationItem: SegmentNavigationItem
  parents?: string[]
  lineHighlighted?: boolean
  lastChild?: boolean
}
function SegmentNavigationItemC({
  navigationItem,
  parents = [],
  lineHighlighted,
  lastChild,
}: SegmentNavigationItemCProps) {
  const { selectedNavigationItemId, setSelectedNavigationItemId: _setSelectedNavigationItemId } =
    useSegmentNavigationContext(true)
  const { segmentId, setSegmentId } = useCourseViewerModalContext(false) ?? {}
  const setSelectedNavigationItemId = (id: string) => {
    if (segmentId && setSegmentId) {
      setSegmentId(segmentId, id)
    } else {
      _setSelectedNavigationItemId(id)
    }
  }
  const { currentHeading } = useHeadingScrollContext(false) || {}
  const { parentSelected, selected } = getSelections(
    selectedNavigationItemId,
    navigationItem.id,
    parents,
  )
  let children = (
    <ModalNavigationItem
      onClick={
        navigationItem.isElement
          ? () => {
              let timeout = 0
              if (selectedNavigationItemId !== toNavigationKey(parents)) {
                setSelectedNavigationItemId(toNavigationKey(parents))
                timeout = 500
              }
              setTimeout(() => {
                const element = document.getElementById(navigationItem.id)

                // Find the scrollable parent.
                let scrollElement = element
                while (scrollElement && scrollElement.scrollHeight <= scrollElement.clientHeight) {
                  scrollElement = scrollElement.parentNode as HTMLElement | null
                }

                if (element && scrollElement) {
                  scrollElement.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth',
                  })
                }
              }, timeout)
            }
          : () => {
              setSelectedNavigationItemId(toNavigationKey([...parents, navigationItem.id]))
            }
      }
      icon={navigationItem.icon}
      label={navigationItem.name}
      selected={
        selected ||
        (parentSelected && navigationItem.isElement && currentHeading === navigationItem.id)
      }
      parentSelected={parentSelected}
      depth={parents.length}
      lineHighlighted={parentSelected && lineHighlighted}
      hasChildren={!!navigationItem.children?.length}
      lastChild={lastChild}
      data-testid={`SegmentNavigation ${navigationItem.id}`}
    />
  )
  if (navigationItem.render) {
    children = navigationItem.render({ children })
  }

  const selectedChildIndex = navigationItem.children?.findIndex(child => {
    return child.isElement && child.id === currentHeading
  })

  return (
    <>
      {children}
      {navigationItem.children?.map((child, index) => (
        <SegmentNavigationItemC
          navigationItem={child}
          key={child.id}
          parents={[...parents, navigationItem.id]}
          lineHighlighted={
            selectedChildIndex !== undefined && selectedChildIndex > -1
              ? index < selectedChildIndex
              : false
          }
          lastChild={index === navigationItem.children!.length - 1}
        />
      ))}
    </>
  )
}

export function SegmentNavigationVerticalTabs() {
  const { navigationItems, loading, error } = useSegmentNavigationItems()

  return error ? null : loading ? (
    <VerticalTabsLoading />
  ) : (
    <>
      {navigationItems.map(navigationItem => (
        <SegmentNavigationItemC navigationItem={navigationItem} key={navigationItem.id} />
      ))}
    </>
  )
}

function VerticalTabsLoading() {
  return (
    <VSpaced space={'xs'} align={'stretch'}>
      <LoadingTab />
      <LoadingTab />
      <LoadingTab />
    </VSpaced>
  )
}
const LoadingTab = styled.div`
  background: ${s.color('gray.element')};
  border-radius: ${s.var('radii.1')};
  margin: 0 ${s.size('s')};
  height: 40px;
`
