import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { Empty, List } from '@thesisedu/react'
import React from 'react'

import { AssignmentSubmissionListItem } from './AssignmentSubmissionListItem'
import { AssignmentSubmissionsGridProps } from './AssignmentSubmissionsGrid'
import { _useInfiniteQueryProps } from './submissionsList'
import {
  AssignmentSubmissionsQuery,
  AssignmentSubmissionsQueryVariables,
  BasicAssignmentSubmissionFragment,
} from '../schema'

export function AssignmentSubmissionsList(props: AssignmentSubmissionsGridProps) {
  const { assignment, onClick } = props
  return (
    <InfiniteQuery<
      BasicAssignmentSubmissionFragment,
      AssignmentSubmissionsQuery,
      AssignmentSubmissionsQueryVariables
    >
      {..._useInfiniteQueryProps(props)}
      children={({ data, refetch }) => {
        const submissions =
          data?.node?.__typename === 'Assignment'
            ? data.node.currentSubmissions.edges.map(edge => edge.node)
            : []
        if (submissions.length) {
          return (
            <List separated>
              {submissions.map((submission, index) => (
                <AssignmentSubmissionListItem
                  key={submission.id}
                  assignment={assignment}
                  item={submission}
                  index={index + 1}
                  onClick={() => onClick(submission)}
                  onUpdate={() => {
                    refetch()
                  }}
                />
              ))}
            </List>
          )
        } else {
          return <Empty description={'No submissions yet!'} />
        }
      }}
    />
  )
}
