import { FeatureResource } from '@thesisedu/feature'
import { useResource } from '@thesisedu/feature-react'
import { Button$, Dropdown, Tooltip, useToast } from '@thesisedu/ui'
import { NavArrowDown } from '@thesisedu/ui/icons'
import { ProgressModal } from '@thesisedu/web'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { debug } from '../../log'
import { ClassStudentPair } from '../../types'

interface RunActionProps {
  identifier: string
  payload: object
  students: ClassStudentPair[]
  onComplete: () => void
}
function RunAction({ identifier, payload, students, onComplete }: RunActionProps) {
  const resource = useResource<StudentActionResource<any>>('StudentAction', identifier, true)
  if (!resource.useAct) throw new Error(`Resource '${identifier}' does not have useAct.`)
  const act = resource.useAct({ payload })
  const [current, setCurrent] = React.useState(0)
  const toast = useToast()
  React.useEffect(() => {
    const run = async () => {
      try {
        for (const student of students) {
          await act(student)
          setCurrent(current => current + 1)
        }
      } catch (err: any) {
        debug('error processing student action', err)
        toast({
          title: 'There was an error updating your students',
          status: 'error',
        })
      }
    }

    run().finally(() => {
      setTimeout(() => {
        onComplete()
      }, 1000)
    })
  }, [])

  return students.length > 1 ? (
    <ProgressModal
      visible
      current={current}
      total={students.length}
      buildLeftLabel={() => resource.actingLabel}
    />
  ) : null
}

function ConfigureAction({
  identifier,
  ...rest
}: StudentActionConfigureProps<any> & { identifier: string }) {
  const resource = useResource<StudentActionResource>('StudentAction', identifier, true)
  return resource.renderConfigure ? <resource.renderConfigure {...rest} /> : null
}

const UNGROUPED = 'ungrouped'
export interface StudentActionsDropdownProps extends Partial<Button$.ButtonProps> {
  students: ClassStudentPair[]
}
export function StudentActionsDropdown({ students, ...buttonProps }: StudentActionsDropdownProps) {
  const resources = useResource<StudentActionResource>('StudentAction')
  const grouped = groupBy(orderBy(resources, 'weight', 'asc'), g => g.group || UNGROUPED)
  const [selectedAction, setSelectedAction] = React.useState<{
    identifier: string
    payload: any
  } | null>(null)
  const [configuringAction, setConfiguringAction] = React.useState<string | null>(null)

  return (
    <>
      {selectedAction ? (
        <RunAction
          identifier={selectedAction.identifier}
          payload={selectedAction.payload}
          students={students}
          onComplete={() => {
            setConfiguringAction(null)
            setSelectedAction(null)
          }}
        />
      ) : null}
      {configuringAction ? (
        <ConfigureAction
          identifier={configuringAction}
          students={students}
          loading={!!selectedAction}
          onClose={() => setConfiguringAction(null)}
          onAct={payload => {
            setSelectedAction({ identifier: configuringAction, payload })
          }}
        />
      ) : null}
      <Dropdown.Container>
        <Tooltip title={'Student Actions'}>
          <Dropdown.Button icon={<NavArrowDown />} {...buttonProps} rightDecorator={null} />
        </Tooltip>
        <Dropdown.Menu align={'end'}>
          {Object.keys(grouped).map((group, index) => {
            const isLast = index === Object.keys(grouped).length - 1
            return (
              <React.Fragment key={group}>
                {grouped[group].map(action => {
                  return (
                    <action.render
                      students={students}
                      onAct={payload => {
                        setSelectedAction({ identifier: action.identifier, payload })
                      }}
                      onConfigure={() => setConfiguringAction(action.identifier)}
                      key={action.identifier}
                      loading={!!selectedAction}
                    />
                  )
                })}
                {isLast ? null : <Dropdown.Item.Separator />}
              </React.Fragment>
            )
          })}
        </Dropdown.Menu>
      </Dropdown.Container>
    </>
  )
}

export interface StudentActionProps<Payload = undefined> {
  students: ClassStudentPair[]
  loading?: boolean
  onConfigure: () => void
  onAct: (payload: Payload) => void
}
export type StudentActionConfigureProps<Payload = undefined> = Omit<
  StudentActionProps<Payload>,
  'onConfigure'
> & {
  onClose: () => void
}
export interface StudentActionUseActOptions<Payload = undefined> {
  payload: Payload
}
export type UseAct<Payload = undefined> = (
  opts: StudentActionUseActOptions<Payload>,
) => (student: ClassStudentPair) => Promise<any>
export interface StudentActionResource<Payload = undefined> extends FeatureResource {
  type: 'StudentAction'
  identifier: string
  weight?: number
  group?: string
  useAct?: UseAct<Payload>
  actingLabel?: string
  render: (props: StudentActionProps<Payload>) => React.ReactElement | null
  renderConfigure?: (props: StudentActionConfigureProps<Payload>) => React.ReactElement | null
}
