import * as React from 'react'

function SvgNoteD(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#F39322'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={1.785}
          d={
            'M66 59.044c21.612-4.17 42.742-7.528 63.39-10.072a60.183 60.183 0 0126.683 1.169 48.064 48.064 0 0119.683 11.24 51.578 51.578 0 0112.27 18.897 74.297 74.297 0 014.908 23.882 93.448 93.448 0 01-2.764 26.348 96.623 96.623 0 01-10.746 26.27 105.165 105.165 0 01-18.986 23.57 103.285 103.285 0 01-27.303 18.17 68.723 68.723 0 01-13.69 2.778c-6.578.6-13.185.817-19.787.65a288.46 288.46 0 01-30.79-2.597L66 59.044zm46.212 99.629a43.363 43.363 0 0016.557-7.113 37.556 37.556 0 009.558-9.89 36.22 36.22 0 004.572-10.15 32.567 32.567 0 001.343-7.787v-3.323c0-.242-.18-1.41-.542-3.504a34.857 34.857 0 00-2.325-7.476 18.741 18.741 0 00-5.011-7.113 11.195 11.195 0 00-8.757-2.232c-3.72.337-8.24 2.595-13.613 7.008l-1.782 51.58z'
          }
        />
        <path
          fill={'#F79824'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteD = React.memo(SvgNoteD)
export default MemoSvgNoteD
