import React, { useContext } from 'react'

export interface DetachContextValue {
  allowDetach?: boolean
}
export const DetachContext = React.createContext<DetachContextValue>({})

export const useDetach = (): null | DetachContextValue => {
  const context = useContext(DetachContext)
  if (context.allowDetach) {
    return context
  } else {
    return null
  }
}
