import { PageHead } from '@thesisedu/react'
import { PageHeader } from '@thesisedu/web'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CreateDistrictButton } from '../../CreateDistrictButton'
import { AllDistrictsTable } from '../../DistrictsTable'

export function ListDistricts() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Districts'} />
      <PageHeader title={'Districts'}>
        <CreateDistrictButton onCreated={d => navigate(`/admin/districts/${d.id}`)} />
      </PageHeader>
      <AllDistrictsTable />
    </>
  )
}
