import { styled } from '@thesisedu/web'
import React from 'react'

import { GifReader } from './omggif'
import { debug } from '../../../log'

export interface ControlledGifProps {
  playing?: boolean
  loopTimes?: number
  src: string
  width: number
  height: number
  onLoad?: () => void
  donePlaying?: () => void
}
export function ControlledGif({
  width,
  height,
  playing,
  loopTimes = 1,
  src,
  onLoad,
  donePlaying,
}: ControlledGifProps) {
  const [loading, setLoading] = React.useState(true)
  const [frame, setFrame] = React.useState(-1)
  const _frameRef = React.useRef<number>(frame)
  const _loopTimes = React.useRef<number>(0)
  const _frames = React.useRef<any[]>([])
  const _delays = React.useRef<any[]>([])
  const _decoded = React.useRef<number>(-1)
  const _rendered = React.useRef<number>(-1)
  const _reader = React.useRef<GifReader | null>(null)
  const _ctx = React.useRef<CanvasRenderingContext2D | null>(null)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  React.useEffect(() => {
    _frameRef.current = frame
  }, [frame])
  React.useEffect(() => {
    if (canvasRef.current) {
      _ctx.current = canvasRef.current.getContext('2d')
    }
  }, [])
  React.useEffect(() => {
    if (width && height) {
      _reader.current = null
      _frames.current = []
      _delays.current = []
      _decoded.current = -1
      _rendered.current = -1
      setLoading(true)
      fetch(src, {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      })
        .then(resp => resp.arrayBuffer())
        .then(buf => new Uint8Array(buf))
        .then(buf => new GifReader(buf))
        .then(gif => {
          debug('gif width %d, height %d', gif.width, gif.height)
          debug('element width %d, height %d', width, height)

          const gifRatio = gif.width / gif.height
          const eleRatio = width / height

          if (canvasRef.current) {
            let _width, _height
            if (gifRatio > eleRatio) {
              _width = width
              _height = width / gifRatio
              canvasRef.current.style.top = (height - _height) / 2 + 'px'
              canvasRef.current.style.left = '0'
            } else {
              _width = height * gifRatio
              _height = height
              canvasRef.current.style.top = '0'
              canvasRef.current.style.left = (width - _width) / 2 + 'px'
            }
            canvasRef.current.style.width = _width + 'px'
            canvasRef.current.style.height = _height + 'px'
          } else {
            debug('no canvas yet')
          }

          const count = gif.numFrames()
          _decoded.current = -1
          _delays.current = new Array(count)
          _frames.current = new Array(count)
          _reader.current = gif

          for (let i = 0; i < count; i++) {
            const frameInfo = gif.frameInfo(i)
            _delays.current[i] = frameInfo.delay * 10
          }

          setLoading(false)
          setFrame(0) // And render the first frame...
          if (onLoad) {
            onLoad()
          }
        })
    }
  }, [src, width, height])

  // Playback
  React.useEffect(() => {
    if (playing && !loading) {
      setFrame(0)
      _loopTimes.current = 0
      let handle: any
      const advanceFrame = () => {
        // Don't continue playing if we've looped enough.
        if (_loopTimes.current >= loopTimes) {
          if (donePlaying) {
            donePlaying()
          }
          return
        }
        const frame = _frameRef.current + 1
        if (frame >= _frames.current?.length) {
          _loopTimes.current++
          setFrame(0)
        } else {
          setFrame(frame)
        }
        handle = setTimeout(advanceFrame, _delays.current[frame])
      }
      handle = setTimeout(advanceFrame, _delays.current[frame])
      return () => {
        clearTimeout(handle)
      }
    }
  }, [playing, loading])

  // --------
  // Rendering Frames
  // --------
  const renderFrame = (frame: number) => {
    if (_reader.current && _ctx.current) {
      while (_decoded.current < frame) {
        const curr = _decoded.current + 1
        const frameInfo = _reader.current.frameInfo(curr)
        const imageData = _ctx.current.createImageData(
          _reader.current.width,
          _reader.current.height,
        )
        if (curr > 0 && frameInfo.disposal < 2) {
          imageData.data.set(new Uint8ClampedArray(_frames.current[curr - 1].data))
        }
        _reader.current.decodeAndBlitFrameRGBA(curr, imageData.data)
        _frames.current[curr] = imageData
        _decoded.current = curr
      }
    }
  }
  // Equivalent of displayFrame()
  React.useEffect(() => {
    if (!_frames.current?.length || loading) return
    renderFrame(frame)
    if (_rendered.current !== frame) {
      requestAnimationFrame(() => {
        if (_ctx.current && _frames.current![frame]) {
          _ctx.current.putImageData(_frames.current![frame], 0, 0)
          _rendered.current = frame
        }
      })
    }
  }, [frame, loading])
  // Logic for pre-rendering frames.
  React.useEffect(() => {
    if ((window as any).requestIdleCallback) {
      let cancel: number
      const prerender = (deadline: IdleDeadline) => {
        while (deadline.timeRemaining() > 0 && _decoded.current < _frames.current?.length - 1) {
          renderFrame(_decoded.current + 1)
        }

        // If we ran out of time and still have work to do, schedule another idle callback.
        if (_decoded.current < _frames.current?.length - 1) {
          cancel = requestIdleCallback(prerender)
        }
      }
      cancel = requestIdleCallback(prerender)
      return () => {
        cancelIdleCallback(cancel)
      }
    }
  }, [src])

  return (
    <Container style={{ width, height }}>
      <canvas ref={canvasRef} />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  canvas {
    position: absolute;
  }
`
