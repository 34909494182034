import { useNavigate } from 'react-router-dom'

export interface NavigateToSplitViewOpts {
  classId: string
  pane: string
  rightTerm?: string
  return?: string
}
export function useNavigateToSplitView(): (opts: NavigateToSplitViewOpts) => void {
  const navigate = useNavigate()
  return ({ classId, pane, rightTerm, return: ret }) => {
    const args = new URLSearchParams({
      pane,
      return: ret ?? window.location.pathname,
      ...(rightTerm ? { rightTerm } : {}),
    })
    navigate(`/teacher/classes/${classId}/course/split?${args.toString()}`)
  }
}
