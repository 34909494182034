import { HSpaced, LoadingIndicator, ProgressBar$, Toast$ } from '@thesisedu/ui'
import { CheckCircle, WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

export function DevServerListener() {
  const [status, setStatus] = React.useState<string | null>(null)
  const [percent, setPercent] = React.useState<number | null>(null)
  const [percentMessage, setPercentMessage] = React.useState<string | null>(null)
  const timeoutRef = React.useRef<any>()
  const hadStatus = React.useRef(false)
  React.useEffect(() => {
    const handler = (status: string) => {
      if (!status) return
      clearTimeout(timeoutRef.current)
      if (!hadStatus.current && status === 'idle') return
      setStatus(status)
      if (status !== 'idle') hadStatus.current = true
      if (status === 'idle') {
        timeoutRef.current = setTimeout(() => {
          console.info('clearing status')
          setStatus(null)
          setPercent(null)
        }, 4000)
      }
    }
    if (typeof module !== 'undefined' && (module as any).hot) {
      const webpackMessage = (e: MessageEvent) => {
        const { type, data } = e.data
        console.info('type', type)
        if (type === 'webpackInvalid') {
          handler('Waiting...')
        } else if (type === 'webpackProgress') {
          setPercent(data.percent >= 99 ? null : data.percent)
          if (data.pluginName || data.msg) {
            setPercentMessage(data.pluginName ? `${data.pluginName}: ${data.msg}` : data.msg)
          } else {
            setPercentMessage(null)
          }
        } else if (type === 'webpackStillOk') {
          handler('idle')
        } else if (type === 'webpackOk') {
          handler('idle')
        } else if (type === 'webpackWarnings') {
          handler('Warnings while rebuilding')
        } else if (type === 'webpackErrors') {
          handler('Errors while rebuilding')
        } else if (type === 'webpackClose') {
          handler('idle')
        }
      }
      window.addEventListener('message', webpackMessage)
      ;(module as any).hot.addStatusHandler(handler)
      return () => {
        ;(module as any).hot.removeStatusHandler(handler)
        window.removeEventListener('message', webpackMessage)
      }
    }
  }, [])

  console.info('status', status)
  if (status) {
    const toastStatus =
      status === 'fail' || status === 'abort' ? 'error' : status === 'idle' ? 'success' : undefined
    const message =
      toastStatus === 'error' ? 'Failed' : toastStatus === 'success' ? 'Success' : 'Rebuilding'
    return (
      <Toast$.ExternalRoot open onOpenChange={() => {}} status={toastStatus}>
        <Toast$.Icon>
          {toastStatus === 'error' ? (
            <WarningCircle />
          ) : toastStatus === 'success' ? (
            <CheckCircle />
          ) : (
            <LoadingIndicator />
          )}
        </Toast$.Icon>
        <Toast$.Title>
          {percent !== null && percentMessage ? `${percentMessage.slice(0, 40)}...` : message}
        </Toast$.Title>
        <Toast$.Description>
          {percent !== null ? (
            <ProgressBar$.Container maxValue={100} value={percent} aria-label={'Refresh status'}>
              <HSpaced top={'xxs'}>
                <ProgressBar$.Bar style={{ flex: 1 }} />
                <ProgressBar$.ValueLabel level={'s'} color={'secondary'} />
              </HSpaced>
            </ProgressBar$.Container>
          ) : (
            <span>{status}</span>
          )}
        </Toast$.Description>
      </Toast$.ExternalRoot>
    )
  } else {
    return null
  }
}
