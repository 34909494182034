import { ColorGoogle } from '@sammarks/icons'
import { getSAMLButton } from '@thesisedu/feature-users-react'
import React from 'react'

import { useGoogleLogin } from './useGoogleLogin'
import { useGoogleScopesQuery } from '../schema'

export const GoogleLoginButton = getSAMLButton({
  identifier: 'google',
  icon: <ColorGoogle />,
  label: 'Google',
  useLoginHook: useGoogleLogin,
  useScopes: () => {
    const { data } = useGoogleScopesQuery()
    return data?.googleScopes ?? []
  },
})
