import { ClassDraggableOverlayItemsHook } from '@thesisedu/feature-classes-react'
import { Dropdown } from '@thesisedu/ui'
import { DoubleCheck } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'

function GradesItem({ classId, group }: { classId: string; group: string }) {
  const navigate = useNavigate()
  return (
    <Dropdown.Item
      onClick={() => navigate(`/${group.toLowerCase()}/classes/${classId}/grades`)}
      icon={<DoubleCheck />}
    >
      Grades
    </Dropdown.Item>
  )
}

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<ClassDraggableOverlayItemsHook>(
    'classes-react:class-draggable-overlay-items',
    (items, props) => {
      return [<GradesItem key={'grades'} classId={props.class.id} group={props.group} />, ...items]
    },
  )
}
