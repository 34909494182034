export const LOCAL_STORAGE_FLAG_KEY = 'feature-web-flags'

const saveContents = (contents = {}) => {
  localStorage.setItem(LOCAL_STORAGE_FLAG_KEY, window.btoa(JSON.stringify(contents)))
}

const readContents = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_FLAG_KEY)
  return value ? JSON.parse(window.atob(value)) : {}
}

export const setFlag = (flag: string, value: any) => {
  saveContents({
    ...readContents(),
    [flag]: value,
  })
}

export const getFlag = (flag: string): any => {
  return readContents()[flag]
}
