import { PlusOutlined } from '@ant-design/icons'
import { Modal } from '@thesisedu/web'
import { Input, message, Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'

import { useCreateCourseVersionMutation } from '../queries/useCreateCourseVersionMutation'
import { BasicCourseVersionFragment, CourseVersionsDocument } from '../schema'

export interface AddCourseVersionModalProps {
  visible?: boolean
  onClose?: () => void
  versions: BasicCourseVersionFragment[]
  courseId: string
  onCreated?: (courseVersion: BasicCourseVersionFragment) => void
}
export const AddCourseVersionModal: React.FC<
  React.PropsWithChildren<AddCourseVersionModalProps>
> = ({ visible, onClose, versions, courseId, onCreated }) => {
  const [selectedVersion, setSelectedVersion] = useState<string | undefined>()
  const [name, setName] = useState<string | undefined>()
  const [createCourseVersion, { loading }] = useCreateCourseVersionMutation({
    refetchQueries: [{ query: CourseVersionsDocument, variables: { courseId } }],
    awaitRefetchQueries: true,
    onCompleted: data => {
      message.success('Course version created successfully!')
      if (onCreated) {
        onCreated(data.createCourseVersion.courseVersion)
      }
    },
  })
  useEffect(() => {
    if (visible) {
      setSelectedVersion(undefined)
      setName(undefined)
    }
  }, [visible, setSelectedVersion, setName])
  const nameTaken =
    name &&
    !!versions.some(version => version.name.toLowerCase().trim() === name.toLowerCase().trim())
  return (
    <Modal
      title={'Create Version'}
      onCancel={onClose}
      visible={visible}
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          disabled={!selectedVersion || !name || !!nameTaken}
          loading={loading}
          onClick={async () => {
            if (selectedVersion && name && !nameTaken) {
              await createCourseVersion({
                variables: {
                  input: {
                    baseCourseVersionId: selectedVersion,
                    name,
                  },
                },
              })
            }
          }}
        >
          Create Version
        </Button>,
      ]}
    >
      <Input
        size={'large'}
        placeholder={'Version Name'}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <Select<string>
        placeholder={'Select a base version'}
        value={selectedVersion}
        onChange={value => setSelectedVersion(value)}
        style={{
          width: '100%',
          marginTop: 10,
        }}
      >
        {versions.map(version => (
          <Select.Option key={version.id} value={version.id}>
            {version.name}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  )
}

export const AddCourseVersionButton: React.FC<
  React.PropsWithChildren<Pick<AddCourseVersionModalProps, 'versions' | 'courseId' | 'onCreated'>>
> = props => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <AddCourseVersionModal
        {...props}
        visible={visible}
        onClose={() => setVisible(false)}
        onCreated={version => {
          setVisible(false)
          if (props.onCreated) {
            props.onCreated(version)
          }
        }}
      />
      <Button type={'link'} icon={<PlusOutlined />} block onClick={() => setVisible(true)}>
        Add Version
      </Button>
    </>
  )
}
