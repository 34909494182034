import { onMutationError, updateMutation } from './apollo'
import { ShareableNodeFragmentDoc, useShareNodeWithUsersMutation as useMutation } from '../schema'

export const useShareNodeWithUsersMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error sharing that content.'),
    update: updateMutation({
      fragmentName: 'ShareableNode',
      fragment: ShareableNodeFragmentDoc,
      path: 'shareNodeWithUser.node',
    }),
    ...opts,
  })
