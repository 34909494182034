import { LexicalNode, SerializedLexicalNode } from 'lexical'

import { BaseWidgetConfig, SerializedWidget } from './WidgetNode'
import { MaybeElement } from './nodes'
import { EditorValue } from './types'

export type NodeWalker<Child, Parent> = (node: Child, parent?: Parent) => void
export function walk(
  value: LexicalNode[],
  walker: NodeWalker<LexicalNode, LexicalNode | LexicalNode[]>,
): void
export function walk(value: EditorValue, walker: NodeWalker<MaybeElement, MaybeElement>): void
export function walk(value: EditorValue | LexicalNode[], walker: NodeWalker<any, any>): void
export function walk(value: EditorValue | LexicalNode[], walker: NodeWalker<any, any>): void {
  function walkNode(
    node: MaybeElement | LexicalNode,
    parent?: MaybeElement | LexicalNode | LexicalNode[],
  ) {
    walker(node, parent)
    if (typeof (node as LexicalNode).getChildren !== 'undefined') {
      ;(node as LexicalNode)
        .getChildren()
        .forEach((childNode: MaybeElement | LexicalNode) => walkNode(childNode, node))
    } else {
      node.children?.forEach((childNode: MaybeElement | LexicalNode) => walkNode(childNode, node))
    }
  }
  if ((value as EditorValue).root) {
    ;(value as EditorValue).root.children.forEach((childNode: MaybeElement | LexicalNode) =>
      walkNode(childNode, (value as EditorValue).root),
    )
  } else {
    ;(value as LexicalNode[]).forEach((childNode: MaybeElement | LexicalNode) =>
      walkNode(childNode, value as LexicalNode[]),
    )
  }
}

export function walkType<Type extends LexicalNode>(
  value: LexicalNode[],
  type: string,
  walker: NodeWalker<Type, Type | Type[]>,
): void
export function walkType<Type extends MaybeElement>(
  value: EditorValue,
  type: string,
  walker: NodeWalker<Type, Type>,
): void
export function walkType<Type extends MaybeElement | LexicalNode>(
  value: EditorValue | LexicalNode[],
  type: string,
  walker: NodeWalker<Type, Type>,
): void
export function walkType<Type extends MaybeElement | LexicalNode>(
  value: EditorValue | LexicalNode[],
  type: string,
  walker: NodeWalker<Type, Type>,
): void {
  walk(value, (node, parent) => {
    if (node.type === type) {
      walker(node as Type, parent as Type)
    }
  })
}

export function walkWidget<Widget extends SerializedWidget<string, BaseWidgetConfig>>(
  value: EditorValue | LexicalNode[],
  type: Widget['type'],
  walker: NodeWalker<Widget, SerializedLexicalNode>,
) {
  walk(value, (node, parent) => {
    if ((node.type ?? node.__type) === type) {
      walker(node as Widget, parent)
    }
  })
}
