import { s, styled, HSpaced, Text } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { useCourseViewerModalContext } from '../contexts/CourseViewerModalContext'
import { SegmentCompletionWrapper } from '../course_viewer/completion/SegmentCompletionWrapper'
import { SegmentCompletionHostProps } from '../types'

export function DefaultSegmentCompletionHost({ onMarkComplete }: SegmentCompletionHostProps) {
  React.useEffect(() => {
    onMarkComplete()
  }, [])
  const { setSegmentId } = useCourseViewerModalContext(false) ?? {}
  return (
    <StyledContainer
      onClick={e => {
        e.preventDefault()
        setSegmentId?.(undefined)
      }}
    >
      <HSpaced align={'center'} space={'m'}>
        <IconContainer>
          <Check />
        </IconContainer>
        <div>
          <Text level={'h3'} color={'background'} style={{ lineHeight: 1.3 }}>
            Great work!
          </Text>
          <Text color={'background'} style={{ opacity: 0.5 }}>
            You've completed this content. Click here to go back to the outline view.
          </Text>
        </div>
      </HSpaced>
    </StyledContainer>
  )
}

const StyledContainer = styled(SegmentCompletionWrapper)`
  padding: ${s.size('m')} ${s.size('xl')};
  cursor: pointer;
  background: ${s.color('green')};
`
const IconContainer = styled.div`
  width: 64px;
  height: 64px;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${s.color('green')};
  background: white;
  border-radius: ${s.var('radii.2')};
`
