import { BodySmall, TypographyProps } from '@thesisedu/react'
import moment from 'moment'
import React from 'react'

import { PartialUpload, UploadStatus } from './types'

type StatusLabelPropsFn = (upload: PartialUpload) => TypographyProps
export const STATUS_LABEL_PROPS: StatusLabelPropsFn = upload => {
  switch (upload.currentStatus) {
    case UploadStatus.Waiting:
      return {
        color: '@text-color-secondary',
        children: 'Waiting',
      }
    case UploadStatus.Uploading:
    case UploadStatus.Submitting:
      if (upload.uploaded !== undefined) {
        return {
          color: '@primary-color',
          children: `Uploading (${((upload.uploaded || 0) * 100).toFixed(0)}%)`,
        }
      } else {
        return {
          color: '@text-color-secondary',
          children: 'Waiting for upload...',
        }
      }
    case UploadStatus.WaitingConnection:
      return {
        color: '@text-color-secondary',
        children: 'Waiting for Wifi...',
      }
    case UploadStatus.Uploaded:
    case UploadStatus.Processing:
      return {
        color: '@primary-color',
        children: 'Processing',
      }
    case UploadStatus.Failed:
      return {
        color: '@orange',
        children: upload.fileBlob ? 'Failed. Click to view options' : 'Failed',
      }
    case UploadStatus.Complete:
      return {
        color: '@blue',
        children: `Uploaded ${moment(upload.completedDate).fromNow()}`,
      }
  }
}

export interface UploadStatusLabelProps {
  upload: PartialUpload
  style?: any
}

export const UploadStatusLabel: React.FC<React.PropsWithChildren<UploadStatusLabelProps>> = ({
  upload,
  style,
}) => {
  return <BodySmall {...STATUS_LABEL_PROPS(upload)} style={style} />
}
