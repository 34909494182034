import { FeatureResource, MutateHook } from '@thesisedu/feature'
import { Viewer } from '@thesisedu/feature-users-web/dist/types'
import { Shell } from '@thesisedu/feature-web'

import { AuthRequiredPayload } from './saml/ProviderAuthContext'
import { Permission as SchemaPermission } from './schema'
import { SAMLButtonsProps } from './web/routes/SAMLButtons'

export type AuthRoutesHook = MutateHook<
  'feature-users-react:auth-routes',
  React.ReactElement[],
  undefined
>
export type UserRoutesHook = MutateHook<
  'feature-users-react:user-routes',
  React.ReactElement[],
  { viewer?: Viewer; group: string }
>
export type UserLeftItemsHook = MutateHook<
  'feature-users-react:left-items',
  Shell.LeftNavigationItem[],
  { viewer?: Viewer; group: string }
>
export type UserTopItemsHook = MutateHook<
  'feature-users-react:top-items',
  Shell.TopNavigationItem[],
  { viewer?: Viewer; group: string }
>

export type RegisterPageHook = MutateHook<
  'feature-users-react:register-page',
  React.ReactElement,
  undefined
>

export enum SAMLButtonPlacement {
  Prominent = 'prominent',
  Secondary = 'secondary',
}
export interface SAMLButtonResource extends FeatureResource {
  type: 'SAMLButton'
  identifier: string
  weight: number
  component: (props: SAMLButtonsProps) => React.ReactElement | null
  placement?: SAMLButtonPlacement
}

export interface AuthProviderHandlerResource extends FeatureResource {
  type: 'AuthProviderHandler'
  identifier: string
  render: (payload: AuthRequiredPayload) => React.ReactElement
  buttonIcon?: React.ReactElement
  headerLogoPath: string
  squareLogoPath: string
  name: string
  preAuthenticateScreen?: () => React.ReactElement
}

export type LoginLinksHook = MutateHook<
  'feature-users-react:login-links',
  React.ReactElement[],
  undefined
>

export type UserMenuItemsHook = MutateHook<
  'feature-users-react:user-menu',
  React.ReactElement[],
  { viewer?: Viewer }
>

export interface UserSettingsPageResource extends FeatureResource {
  type: 'UserSettingsPage'
  identifier: string
  title: string
  weight: number
  icon: React.ReactElement
  filter?: (viewer?: Viewer) => boolean
  component: () => React.ReactElement | null
}

export interface MyContentPageResource extends FeatureResource {
  type: 'MyContentPage'
  identifier: string
  title: string
  weight: number
  icon: React.ReactElement
  filter?: (viewer?: Viewer) => boolean
  component: () => React.ReactElement | null
}

/**
 * This is some fun Typescript trickery to allow for module augmentation so we
 * have access to all available permissions anywhere in the system, as long as
 * you've generated your schema.
 */
export interface PermissionsMap extends Record<`${SchemaPermission}`, true> {}
export type Permission = keyof PermissionsMap
