import { DeepPartial } from '@thesisedu/feature-types'

export function mergeDeep<T extends Record<string, any>, Other extends DeepPartial<T>>(
  base: T,
  other: Other,
): T {
  const result: Record<string, any> = { ...base }
  for (const [key, value] of Object.entries(other)) {
    if (!other.hasOwnProperty(key)) continue
    if (key === '__proto__' || key === 'constructor') continue
    if (value === undefined) {
      continue
    }
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      result[key] = mergeDeep(result[key] || {}, value)
    } else {
      result[key] = value
    }
  }
  return result as T
}

export function mergeDeepInPlace<T extends Record<string, any>, Other extends DeepPartial<T>>(
  base: T,
  other: Other,
): void {
  const anyBase = base as any
  for (const [key, value] of Object.entries(other)) {
    if (!other.hasOwnProperty(key)) continue
    if (key === '__proto__' || key === 'constructor') continue
    if (value === undefined) {
      continue
    }
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      mergeDeepInPlace(base[key] || {}, value)
    } else {
      anyBase[key] = value
    }
  }
}
