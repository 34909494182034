import { $getNodeByKey, LexicalEditor, LexicalNode } from 'lexical'
import React from 'react'

export function useNodeState<T extends LexicalNode, State>(
  editor: LexicalEditor,
  node: T,
  getState: (node: T) => State,
  setState: (node: T, value: State) => void,
): [State | undefined, (newState: State) => void] {
  const stateHook = React.useState<State | undefined>(
    editor.getEditorState().read(() => {
      const nodeKey = node.getKey()
      const _node = $getNodeByKey(nodeKey)
      return _node ? getState(_node as T) : undefined
    }),
  )
  React.useEffect(() => {
    stateHook[1](
      editor.getEditorState().read(() => {
        const nodeKey = node.getKey()
        const _node = $getNodeByKey(nodeKey)
        return _node ? getState(_node as T) : undefined
      }),
    )
  }, [])

  return [
    stateHook[0],
    (newState: State) => {
      stateHook[1](newState)
      editor.update(() => {
        const nodeKey = node.getKey()
        const _node = $getNodeByKey(nodeKey)
        if (!_node) throw new Error(`Cannot find node with key ${nodeKey}`)
        setState(_node as T, newState)
      })
    },
  ]
}
