import { LoadingOutlined } from '@ant-design/icons'
import { styled, ErrorIndicator, DATE_FORMATS } from '@thesisedu/web'
import { Table, Result, Empty } from 'antd'
import moment from 'moment'
import React from 'react'

import { GradeColumn } from './GradeColumn'
import { NameColumn } from './NameColumn'
import { GradeTreeRecord, isAssignment, translateGradeTreeToTableData } from './translateGradeTree'
import { useGradeTree } from './useGradeTree'
import { useGradeIncompleteLateAsZero } from '../resources/class_configuration/GradeIncompleteLateAsZero'

export interface CategoryGradesTableProps {
  studentIds?: string[]
  classId: string
  renderName?: (record: GradeTreeRecord, children: React.ReactElement) => React.ReactElement
}
export function CategoryGradesTable({ classId, studentIds, renderName }: CategoryGradesTableProps) {
  const { categories, loading } = useGradeTree(classId, studentIds) || {}
  const incompleteAsZero = useGradeIncompleteLateAsZero(classId)
  let content = <ErrorIndicator />
  if (loading && !categories?.length) {
    content = <Result icon={<LoadingOutlined size={75} />} title={'Crunching numbers...'} />
  } else if (categories?.length) {
    content = (
      <Table<GradeTreeRecord>
        dataSource={translateGradeTreeToTableData(categories, !!incompleteAsZero)}
        rowClassName={record => {
          if (record.overdue) {
            return 'overdue'
          } else if (record.dueToday) {
            return 'due-today'
          } else if (record.todo) {
            return 'todo'
          } else if (record.notGraded) {
            return 'not-graded'
          } else if (record.late) {
            return 'late'
          }
          return ''
        }}
        size={'middle'}
        pagination={false}
        expandRowByClick
        loading={loading}
      >
        <Table.Column<GradeTreeRecord>
          title={'Name'}
          dataIndex={'name'}
          render={(_, record) => <NameColumn record={record} renderName={renderName} />}
        />
        <Table.Column<GradeTreeRecord>
          title={'Grade'}
          dataIndex={'grade'}
          render={(_, record) => <GradeColumn record={record} />}
        />
        <Table.Column<GradeTreeRecord>
          title={'Submitted At'}
          dataIndex={'name'}
          render={(_, record) => {
            if (isAssignment(record)) {
              const submitted = record.submissions?.[0]?.submittedAt
              return submitted ? moment(submitted).format(DATE_FORMATS.FULL) : null
            } else {
              return null
            }
          }}
          width={'25%'}
        />
      </Table>
    )
  } else if (categories?.length === 0) {
    content = <Empty description={'No assignments yet!'} />
  }

  return <Container>{content}</Container>
}

const Container = styled.div`
  tr.not-graded {
    background: ${props => props.theme['@blue-light']};
  }
  tr.todo,
  tr.due-today,
  tr.late {
    background: ${props => props.theme['@orange-light']};
  }
  tr.overdue {
    background: ${props => props.theme['@red-light']};
  }
`
