import * as Core from '@thesisedu/feature-assignments-core'
import { useResource } from '@thesisedu/feature-react'
import { Legacy } from '@thesisedu/feature-widgets-core'
import { BlockEditor } from '@thesisedu/feature-widgets-react'
import { styled, Form, isNative } from '@thesisedu/react'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { AssignmentQuestionsContext } from './AssignmentQuestionsContext'
import { QuestionGradeFeedback } from './QuestionGradeFeedback'
import { WrapViewerContent } from './WrapViewerContent'
import { isFieldCompleteDefault } from './form/AssignmentViewerFormForm'
import { MaybeQuestionTotalPoints } from './settings/QuestionTotalPoints'
import { useGradeQuestionCallback } from './useGradeQuestionCallback'
import { Grade } from '../Grade'
import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { useSubmissionContext } from '../contexts/SubmissionContext'
import { useTeacherSubmissionContext } from '../contexts/TeacherSubmissionContext'
import { getCombinedItems } from '../edit/getCombinedItems'
import { QuestionWidgetProvider } from '../resources/Widgets/Question/QuestionWidgetContext'
import { QuestionRealTimeConfirmationContext } from '../resources/questions/QuestionRealTimeConfirmationContext'
import { QuestionProps, QuestionTypeResource } from '../types'

export interface AssignmentQuestionsProps {
  /** The unique ID to use for the underlying editor. */
  editorId: string
  questions: Core.Question[]
  widgets?: Legacy.WeightedAnyWidget[]
  renderGradeComment?: (question: Core.Question, index: number) => React.ReactElement | null
  showGradeFeedback?: boolean
  resetQuestion?: (id: string) => void
  disabled?: boolean
  /** If this is enabled and there is only one question, no wrapper or question headers are rendered. */
  singleQuestionMode?: boolean
  /** Render any additional content underneath questions. */
  questionFooter?: (question: Core.Question) => React.ReactElement | null
  /** Any additional props to pass to each question. */
  questionProps?: Partial<QuestionProps>
}
export function AssignmentQuestions({
  editorId,
  questions,
  widgets,
  renderGradeComment,
  showGradeFeedback,
  resetQuestion,
  disabled,
  singleQuestionMode,
  questionFooter,
  questionProps,
}: AssignmentQuestionsProps) {
  const gradeQuestion = useGradeQuestionCallback()
  const { teacherView } = useAssignmentViewerContext(false) || {}
  const { submissionSummary } = useTeacherSubmissionContext(false) || {}
  const { realTimeSubmissionData: submissionData } = useSubmissionContext(false) || {}
  const resources = useResource<QuestionTypeResource>('QuestionType')
  const renderHeaders = !singleQuestionMode || questions.length !== 1
  const [completeQuestionIds, setCompleteQuestionIds] = React.useState<string[]>([])
  function updateQuestion(id: string, complete: boolean) {
    setCompleteQuestionIds(completeQuestionIds => {
      const without = completeQuestionIds.filter(q => q !== id)
      return complete ? [...without, id] : without
    })
  }
  function getRealTimeConfirmationFeedback(
    question: Core.Question,
    isRealTimeConfirmedComplete: boolean,
  ) {
    return (
      <QuestionGradeFeedback
        question={question}
        onTryAgain={() => {
          const resource = resources.find(r => r.identifier === question.type)
          // Don't clear the user's current answer if we are using the real-time confirmation
          // wrapper.
          const shouldResetQuestion = !resource?.usesRealTimeConfirmation
          if (shouldResetQuestion && resetQuestion) {
            resetQuestion(question.id)
          }
          updateQuestion(question.id, false)
        }}
        isComplete={isRealTimeConfirmedComplete}
      />
    )
  }

  return (
    <QuestionRealTimeConfirmationContext.Provider
      value={{
        completeQuestionIds,
        updateQuestion,
      }}
    >
      <AssignmentQuestionsContext.Provider
        value={{
          questionHeaderProps: renderHeaders
            ? question => {
                const questionIndex = questions.findIndex(q => q.id === question.id)
                const gradeComment = renderGradeComment
                  ? renderGradeComment(question, questionIndex)
                  : null
                const resource = resources.find(r => r.identifier === question.type)
                const isComplete = resource?.isQuestionComplete
                  ? resource.isQuestionComplete(question, submissionData?.[question.id])
                  : isFieldCompleteDefault(submissionData || {}, question.id)
                const isRealTimeConfirmedComplete = resource?.usesRealTimeConfirmation
                  ? completeQuestionIds.includes(question.id)
                  : isComplete
                let summaryGrade: React.ReactElement | null = null
                const questionSummary = submissionSummary?.questions.find(q => q.id === question.id)

                // Check for question.config.options, because we don't want to show the summary for
                // multiple choice or checkbox select, as that might be confusing.
                if (questionSummary && !question.config?.options) {
                  summaryGrade = <Grade hideProgress grade={questionSummary.averageGrade} />
                }

                return {
                  totalQuestions: questions.length,
                  afterContent:
                    showGradeFeedback && !disabled && resource?.realTimeConfirmationInHeader
                      ? getRealTimeConfirmationFeedback(question, isRealTimeConfirmedComplete)
                      : undefined,
                  rightContent: (
                    <VSpaced
                      align={'flex-end'}
                      style={{ alignSelf: 'flex-end', flexShrink: '0' }}
                      bottom={'xxs'}
                    >
                      {gradeComment}
                      {summaryGrade}
                      <MaybeQuestionTotalPoints questionId={question.id} />
                    </VSpaced>
                  ),
                }
              }
            : undefined,
          questionProps(question) {
            const resource = resources.find(r => r.identifier === question.type)
            const isComplete = resource?.isQuestionComplete
              ? resource.isQuestionComplete(question, submissionData?.[question.id])
              : isFieldCompleteDefault(submissionData || {}, question.id)
            const isRealTimeConfirmedComplete = resource?.usesRealTimeConfirmation
              ? completeQuestionIds.includes(question.id)
              : isComplete

            return {
              answerView:
                teacherView ||
                (showGradeFeedback &&
                  isRealTimeConfirmedComplete &&
                  resource?.isAutoGraded &&
                  resource.isAutoGraded(question))
                  ? {
                      showExplanation:
                        teacherView || (showGradeFeedback && gradeQuestion(question) !== false),
                      hideCorrectAnswer: !teacherView && showGradeFeedback,
                    }
                  : undefined,
              showGradeFeedback,
              disabled,
              ...questionProps,
            }
          },
          questionFooter(question) {
            const resource = resources.find(r => r.identifier === question.type)
            const isComplete = resource?.isQuestionComplete
              ? resource.isQuestionComplete(question, submissionData?.[question.id])
              : isFieldCompleteDefault(submissionData || {}, question.id)
            const isRealTimeConfirmedComplete = resource?.usesRealTimeConfirmation
              ? completeQuestionIds.includes(question.id)
              : isComplete

            return (
              <>
                {showGradeFeedback && isRealTimeConfirmedComplete ? (
                  <Form.Item name={question.id} defaultValue={submissionData?.[question.id]}>
                    {isNative ? <></> : <input type={'hidden'} />}
                  </Form.Item>
                ) : null}
                {showGradeFeedback && !disabled
                  ? getRealTimeConfirmationFeedback(question, isRealTimeConfirmedComplete)
                  : null}
                {questionFooter ? questionFooter(question) : null}
              </>
            )
          },
        }}
      >
        <WrapViewerContent questionTypes={questions.map(q => q.type)}>
          <Container>
            <QuestionWidgetProvider questions={questions}>
              <BlockEditor
                id={editorId}
                defaultValue={getCombinedItems(questions, widgets)}
                // We need to forcefully update the value when the question order changes.
                defaultValueKey={questions.map(q => q.id).join(',')}
                readOnly
              />
            </QuestionWidgetProvider>
          </Container>
        </WrapViewerContent>
      </AssignmentQuestionsContext.Provider>
    </QuestionRealTimeConfirmationContext.Provider>
  )
}

const Container = styled.div`
  .fteditor-root > *:not(.assignment-question, .full-width) {
    max-width: 800px;
    margin-left: 0;
  }
`
