import { ClassLeftNavHook, ClassRoutesHook } from '@thesisedu/feature-classes-react'
import { Shell } from '@thesisedu/feature-web'
import { MusicDoubleNote } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { Compositions } from './Compositions'
import { SheetMusicReactFeature } from '../../SheetMusicReactFeature'

export default function (feature: SheetMusicReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls }) => {
      return [
        ...routes,
        <Route key={'compositions'} path={'compositions/*'} element={<Compositions />} />,
      ]
    },
  )
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    items => {
      return [
        ...items.map(item => {
          if (item.group === 'Grades') {
            return { ...item, group: 'Grades & Compositions' }
          } else {
            return item
          }
        }),
        {
          group: 'Grades & Compositions',
          weight: 10,
          element: (
            <Shell.LeftMenuItem
              key={'compositions'}
              name={'Compositions'}
              icon={<MusicDoubleNote width={'1.1em'} height={'1.1em'} />}
              path={'compositions'}
            />
          ),
        },
      ]
    },
  )
}
