/**
 * This is here to store a quick map from Class ID + Segment ID -> Assignment ID.
 * We use it to automatically invalidate assignments when a segment is enabled
 * or disabled.
 */

/** The key is the class ID + segment ID; the value is the assignment ID. */
type SegmentAssignmentCache = Record<string, string>
const _cache: SegmentAssignmentCache = {}

export function cacheSegmentAssignmentId(classId: string, segmentId: string, assignmentId: string) {
  _cache[`${classId}-${segmentId}`] = assignmentId
}

export function getCachedSegmentAssignmentId(
  classId: string,
  segmentId: string,
): string | undefined {
  return _cache[`${classId}-${segmentId}`]
}
