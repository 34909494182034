import { AriaCalendarProps, DateValue, useCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import React from 'react'
import { CalendarStateOptions, useCalendarState } from 'react-stately'

import { CalendarGrid } from './CalendarGrid'
import { createCalendar } from './createCalendar'
import { HSpaced, Button, Text, styled, VSpaced } from '../..'
import { NavArrowLeft, NavArrowRight } from '../../../icons'

export interface CalendarProps<T extends DateValue>
  extends Omit<CalendarStateOptions<T>, 'locale' | 'createCalendar'>,
    AriaCalendarProps<T> {}
function _Calendar<T extends DateValue>(
  props: CalendarProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { locale } = useLocale()
  const state = useCalendarState<T>({
    ...props,
    locale,
    createCalendar,
  })
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar<T>(props, state)

  return (
    <__Calendar {...calendarProps} ref={ref}>
      <HSpaced space={'xs'}>
        <Button size={'small'} icon={<NavArrowLeft />} {...prevButtonProps} />
        <CalendarTitle level={'l'} weight={'bold'}>
          {title}
        </CalendarTitle>
        <Button size={'small'} icon={<NavArrowRight />} {...nextButtonProps} />
      </HSpaced>
      <CalendarGrid state={state} />
    </__Calendar>
  )
}
export const Calendar = React.forwardRef(_Calendar)

export const __Calendar = styled(VSpaced)`
  width: 300px;
  max-width: 100%;
  line-height: 2;
`
export const CalendarTitle = styled(Text)`
  margin: 0 auto;
  text-align: center;
`
