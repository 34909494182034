import { SerializedLexicalNode } from 'lexical'

import { BaseWidgetConfig, SerializedWidget } from './WidgetNode'

export function isWidget<Widget extends SerializedWidget<string, BaseWidgetConfig>>(
  node: SerializedLexicalNode,
  type: Widget['type'],
): node is Widget {
  return (node as Widget).type === type
}
