import { ApolloClient } from '@apollo/client'
import React from 'react'

import { AttachFileDocument, AttachFileMutation, AttachFileMutationVariables } from '../schema'
import { AttachmentCreateProps, UploadProps } from '../types'

export interface InternalCreateProps {
  client: ApolloClient<any>
  UploadComponent: React.FC<React.PropsWithChildren<UploadProps>>
}
export const CommonAttachmentCreate: React.FC<
  React.PropsWithChildren<AttachmentCreateProps & InternalCreateProps>
> = ({ client, UploadComponent, type, camelField, ...uploadProps }) => {
  return (
    <UploadComponent
      getWriteFragment={async input => {
        const result = await client.mutate<AttachFileMutation, AttachFileMutationVariables>({
          mutation: AttachFileDocument,
          variables: {
            attachmentWriteInput: input,
            attachInput: {
              parentType: type,
              parentField: camelField,
            },
          },
        })
        return result.data?.attachFile || null
      }}
      {...uploadProps}
    />
  )
}
