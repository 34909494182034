import { getBase64Contents, getFileExtension } from '@thesisedu/feature-attachments-react'
import { LexicalEditor, NodeKey } from 'lexical'
import React from 'react'

import { error } from '../../../log'
import { useLexicalUpload } from '../../utils/upload'

export interface ImageUploadFile {
  editor: LexicalEditor
  file: File
}

export interface UseImageUploadFileOpts {
  uploadFile?: ImageUploadFile
  nodeKey: NodeKey
  afterUpload: (path?: string) => void
}
export interface UseImageUploadFile {
  loading: boolean
  src?: string
}
export function useImageUploadFile({
  uploadFile,
  nodeKey,
  afterUpload,
}: UseImageUploadFileOpts): UseImageUploadFile {
  const doUpload = useLexicalUpload('image', { isPublic: true })
  const [loading, setLoading] = React.useState(false)
  const [src, setSrc] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    if (!uploadFile) return

    setLoading(true)
    let shouldContinue = true

    const callback = async () => {
      const extension = getFileExtension(uploadFile.file)
      if (!extension) return
      const base64Contents = await getBase64Contents(uploadFile.file)
      if (!shouldContinue) return
      setSrc(base64Contents)

      const uploadedPath = await doUpload({
        contents: uploadFile.file,
        name: uploadFile.file.name,
        extension,
        mimeType: uploadFile.file.type,
      })
      if (uploadedPath) {
        setSrc(uploadedPath)
        afterUpload(uploadedPath)
      } else {
        // Something went wrong with the upload...
      }
    }
    callback()
      .catch(err => {
        error(err)
      })
      .finally(() => {
        setLoading(false)
        afterUpload()
      })

    return () => {
      shouldContinue = false
      setLoading(false)
    }
  }, [uploadFile])

  return { loading, src }
}
