import { Form, Form$, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { RubricEditor } from './RubricEditor'
import { AssignmentRubricInput } from '../schema'

export interface FormVariables {
  rubric: AssignmentRubricInput
  name: string
}
export interface RubricTemplateFormProps extends Partial<Form$.FormProps<FormVariables>> {
  form: Form$.FormInstance<FormVariables>
}
export function RubricTemplateForm({ form, ...formProps }: RubricTemplateFormProps) {
  return (
    <Form form={form} {...formProps}>
      <VSpaced space={'m'}>
        <Form.TextField
          name={'name'}
          label={'Name'}
          required
          size={'large'}
          placeholder={'My Custom Rubric'}
          style={{ maxWidth: 900 }}
          autoComplete={'off'}
        />
        <Form.Field name={'rubric'} required aria-label={'Rubric'}>
          <RubricEditor />
        </Form.Field>
      </VSpaced>
    </Form>
  )
}
