import React from 'react'

import { useProgressBarContext } from './context'
import { styled, s } from '../..'
import { HTMLProps } from '../../sharedTypes'

export interface BarProps extends HTMLProps<HTMLDivElement> {}
export function Bar(props: BarProps) {
  const { value, minValue, maxValue, isIndeterminate } = useProgressBarContext(true)
  if (isIndeterminate) {
    return (
      <Track {...props}>
        <IndeterminateFill />
      </Track>
    )
  } else {
    const percentage = (value - minValue) / (maxValue - minValue)
    const barWidth = `${Math.round(percentage * 100)}%`
    return (
      <Track {...props}>
        <Fill style={{ width: barWidth }} />
      </Track>
    )
  }
}

const Track = styled.div`
  background: ${s.color('element')};
  border-radius: 9999px;
  height: 4px;
  width: 100%;
  overflow: hidden;
`
const Fill = styled.div`
  background: ${s.color('primary')};
  border-radius: 9999px;
  height: 4px;
  transition: width 0.5s ${s.var('curves.exponential')};
`
const IndeterminateAnimation = s.keyframes`
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
`
const IndeterminateFill = styled(Fill)`
  position: relative;
  animation: ${IndeterminateAnimation} 2s infinite;
  width: 50%;
`
