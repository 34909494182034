import { Select, Table } from '@thesisedu/ui'
import React from 'react'

import { BasicAssignmentCategoryFragment } from '../schema'

export interface CategoryMapRowProps {
  categoryName: string
  mappedTo?: string
  onMappedToChange: (mappedTo?: string) => void
  categories: BasicAssignmentCategoryFragment[]
  loading?: boolean
}
export function CategoryMapRow({
  categoryName,
  mappedTo,
  onMappedToChange,
  categories,
  loading,
}: CategoryMapRowProps) {
  return (
    <Table.Row data-testid={`CategoryMapRow ${categoryName}`}>
      <Table.Cell>{categoryName}</Table.Cell>
      <Table.Cell>
        <Select
          aria-label={`Mapping for ${categoryName}`}
          size={'small'}
          placeholder={'Select a category...'}
          value={mappedTo}
          onValueChange={onMappedToChange}
          error={mappedTo ? undefined : `Select a category to map ${categoryName} to.`}
          disabled={loading}
        >
          {categories.map(cat => (
            <Select.Item key={cat.id} value={cat.id}>
              {cat.name}
            </Select.Item>
          ))}
        </Select>
      </Table.Cell>
    </Table.Row>
  )
}
