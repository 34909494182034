export function ifs<T>(callback: (props: T) => boolean | undefined | null): (props: T) => string {
  return props => (callback(props) ? '&' : '&.noop')
}

export function transparentize(color: string, transparency: number | string) {
  const transparencyString =
    typeof transparency === 'string' ? transparency : `${Math.round(transparency * 100)}%`
  return `color-mix(in srgb, ${color}, transparent ${transparencyString})`
}

export function opacity(color: string, opacity: number | string) {
  const opacityString = typeof opacity === 'string' ? opacity : `${Math.round(opacity * 100)}%`
  return transparentize(color, `calc(100% - ${opacityString})`)
}
