import * as Nodes from './nodes'
import { EditorValue } from './types'
import { walk } from './walk'

const SPECIAL_CHARS = /[*_]/g
export function normalizeHeading(heading: string) {
  return heading
    .toLowerCase()
    .replace(SPECIAL_CHARS, '')
    .replace(/[^\da-z]/g, '')
    .replace(/ /g, '-')
}

export function getHeadingIdentifier(heading: string) {
  return normalizeHeading(heading)
}

export interface WidgetHeading {
  id: string
  name: string
}
export function getWidgetHeadings(
  value: EditorValue,
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' = 'h1',
): WidgetHeading[] {
  const result: WidgetHeading[] = []
  walk(value, widget => {
    if (Nodes.is(widget, 'heading') && widget.tag === tag) {
      const textChildren = widget.children.filter(child => Nodes.is(child, 'text')) as Nodes.Text[]
      const header = textChildren.map(child => child.text).join('')
      result.push({
        id: getHeadingIdentifier(header),
        name: header,
      })
    }
  })

  return result
}
