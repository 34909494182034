import { CheckOutlined } from '@ant-design/icons'
import { styled, useBreadcrumb } from '@thesisedu/web'
import { Button, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { PublishCourseVersionButton } from './PublishCourseVersionButton'
import { useUpdateCourseVersionMutation } from '../queries/useUpdateCourseVersionMutation'
import { useBasicCourseVersionQuery } from '../schema'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`
const Right = styled.div`
  margin-left: auto;
`

export interface CourseVersionNameProps {
  courseVersionId: string
}
export function CourseVersionName({ courseVersionId }: CourseVersionNameProps) {
  const { data } = useBasicCourseVersionQuery({ variables: { id: courseVersionId } })
  return <span>{data?.node?.__typename === 'CourseVersion' ? data.node.name : '...'}</span>
}

export interface CourseVersionHeaderProps {
  courseVersionId: string
}
export const CourseVersionHeader: React.FC<React.PropsWithChildren<CourseVersionHeaderProps>> = ({
  courseVersionId,
}) => {
  useBreadcrumb({ title: <CourseVersionName courseVersionId={courseVersionId} /> })
  const [updateVersion, { loading: updating }] = useUpdateCourseVersionMutation({
    onCompleted: () => {
      message.success('Version updated successfully.')
    },
  })
  const { data } = useBasicCourseVersionQuery({ variables: { id: courseVersionId } })
  const [name, setName] = useState<string | undefined>()
  const version = data?.node?.__typename === 'CourseVersion' ? data.node : undefined
  useEffect(() => {
    if (version?.name) {
      setName(version.name)
    } else {
      setName(undefined)
    }
  }, [version])
  const showButton = version && name !== version.name
  const disableButton = !name

  const submit = async () => {
    if (name) {
      await updateVersion({
        variables: {
          input: {
            id: courseVersionId,
            patch: {
              name,
            },
          },
        },
      })
    }
  }

  if (version) {
    return (
      <Container>
        <Input
          size={'large'}
          style={{ width: '50%', marginRight: 10 }}
          placeholder={'Rename version...'}
          value={name}
          onChange={e => setName(e.target.value)}
          onPressEnter={() => submit()}
        />
        {showButton ? (
          <Button
            icon={<CheckOutlined />}
            disabled={disableButton}
            loading={updating}
            onClick={() => submit()}
          />
        ) : null}
        {version.publishedAt ? null : (
          <Right>
            <PublishCourseVersionButton courseVersionId={courseVersionId} />
          </Right>
        )}
      </Container>
    )
  } else {
    return null
  }
}
