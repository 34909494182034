import { BrochureLink } from '@thesisedu/feature-react'
import { Body, styled, LargeButton } from '@thesisedu/web'
import { Result } from 'antd'
import classnames from 'classnames'
import React from 'react'
import Markdown from 'react-markdown'

import { useSegmentCalculationContext } from './SegmentCalculationContext'
import { QuizVaultPreset, QUIZ_VAULT_PRESETS } from './presets'

const Container = styled.div`
  .preset-list {
    margin-top: 40px;
  }
  .preset-list.loading > a > div {
    pointer-events: none;
    color: ${props => props.theme['@gray-5']};
    opacity: 0.75;
    cursor: default;
  }
  .preset-list button {
    width: 100%;
  }
`
export function PresetSelection() {
  const [loading, setLoading] = React.useState(false)
  const context = useSegmentCalculationContext(true)
  const onPresetSelected = (preset: QuizVaultPreset) => async () => {
    setLoading(true)
    try {
      await context.selectPreset(preset)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Result title={'This is a Quiz Vault Assignment'}>
        <Body>
          Quiz Vault assignments are automatically generated from questions presented to your
          students throughout the course. Get started by selecting one of our preset assignments
          below, and then add, remove, and re-order your assignment questions on the next screen.{' '}
          <BrochureLink to={'/learn/articles/quiz-vault'} target={'_blank'}>
            Learn more about the Quiz Vault.
          </BrochureLink>
        </Body>
        <div
          className={classnames({
            'preset-list': true,
            loading: context.loading,
          })}
        >
          {QUIZ_VAULT_PRESETS.map(preset => (
            <LargeButton
              title={preset.title}
              icon={<preset.icon />}
              onClick={onPresetSelected(preset)}
              key={preset.type}
              disabled={loading}
            >
              <Markdown skipHtml children={preset.description} />
            </LargeButton>
          ))}
        </div>
      </Result>
    </Container>
  )
}
