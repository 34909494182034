import { Empty } from '@thesisedu/react'
import { Button } from '@thesisedu/ui'
import { Group } from '@thesisedu/ui/icons'
import React from 'react'

import { GroupsAssignment } from './types'
import { SubmissionType } from '../schema'

export interface IndividualAssignmentProps {
  onAssignmentChange: (assignment: Partial<GroupsAssignment>) => Promise<void>
}
export function IndividualAssignment({ onAssignmentChange }: IndividualAssignmentProps) {
  const [loading, setLoading] = React.useState(false)
  return (
    <Empty
      icon={<Group />}
      title={
        <>
          Allow students to collaborate with <strong>groups.</strong>
        </>
      }
      description={
        <>
          With groups, students can collaborate on the same assignment, or work together with
          individual answers. Use our powerful group setup features to get started.
        </>
      }
      action={
        <Button
          variant={'chromeless'}
          icon={<Group />}
          children={'Make this a Group Assignment'}
          loading={loading}
          onPress={async () => {
            setLoading(true)
            try {
              await onAssignmentChange({
                submissionType: SubmissionType.Group,
              })
            } finally {
              setLoading(false)
            }
          }}
        />
      }
    />
  )
}
