import { SolutionOutlined } from '@ant-design/icons'
import { SimpleSegmentPresetResource } from '@thesisedu/feature-courses-react'
import { SegmentType } from '@thesisedu/feature-courses-react/dist/schema'
import React from 'react'

export const AssignmentPreset: SimpleSegmentPresetResource = {
  type: 'SimpleSegmentPreset',
  identifier: 'assignment',
  segmentType: SegmentType.Assignment,
  label: 'Assignment',
  icon: <SolutionOutlined />,
  formValues: {
    config: {
      icon: 'solution',
      label: 'Assignment',
      rubricType: 'Classwork',
    },
    assignment: {
      defaults: {
        submissionType: 'INDIVIDUAL',
      },
    },
  },
}
