import { Text } from '@thesisedu/ui'
import React from 'react'

import { useAssignmentViewerContext } from './AssignmentViewerFormContext'
import { Grade } from '../../Grade'
import { useSubmissionContext } from '../../contexts/SubmissionContext'

export function FormGrade() {
  const { assignment } = useAssignmentViewerContext()
  const { submission } = useSubmissionContext(true)
  const hasMultipleAttempts = assignment.maxAttempts > 1
  return submission?.grade !== null && submission?.grade !== undefined ? (
    <Text level={'xl'} as={'div'}>
      <Grade
        prefix={hasMultipleAttempts ? 'Your highest grade: ' : 'Your grade: '}
        grade={submission.grade}
        totalPoints={assignment.totalPoints}
      />
    </Text>
  ) : null
}
