import { useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Form, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { AssignmentFlagResource } from '../types'

export interface AssignmentFlagsProps {
  prefix?: string[]
  hideRandomizeQuestions?: boolean
  hideManageAttempts?: boolean
}
export function AssignmentFlags(props: AssignmentFlagsProps) {
  const { prefix, hideRandomizeQuestions, hideManageAttempts: _hideManageAttempts } = props
  const viewer = useViewerContext(false)
  const { form } = Form.useFormContext()
  const maxAttempts = form.watch('maxAttempts')
  const hideManageAttempts = _hideManageAttempts || !maxAttempts || maxAttempts <= 1
  const resources = useResource<AssignmentFlagResource>('AssignmentFlag')
  return (
    <VSpaced space={'m'}>
      <Form.Checkbox
        name={[...(prefix || []), 'excluded'].join('.')}
        label={'Exclude from Gradebook'}
        description={
          "If an assignment is excluded, it does not contribute to the student's overall grade."
        }
      />
      {hideRandomizeQuestions ? null : (
        <Form.Checkbox
          description={
            "If this is checked, the assignment's questions will be randomized for students."
          }
          name={[...(prefix || []), 'configuration', 'isRandomized'].join('.')}
          label={'Randomize Questions'}
        />
      )}
      {hideManageAttempts || viewer?.group !== 'ADMIN' ? null : (
        <Form.Checkbox
          name={[...(prefix || []), 'configuration', 'studentsManageAttempts'].join('.')}
          label={'Allow Students to Manage Attempts'}
          description={
            'If this is checked, students are allowed to create multiple attempts and manually ' +
            'select which one to submit for final grading.'
          }
        />
      )}
      {resources.map(resource => {
        const ResourceFields = resource.render
        return (
          <ResourceFields
            key={resource.identifier}
            {...props}
            prefix={[...(props.prefix || []), 'configuration']}
          />
        )
      })}
    </VSpaced>
  )
}
