import { Dropdown } from '@thesisedu/ui'
import { Refresh } from '@thesisedu/ui/icons'
import React from 'react'

import { $isFileNode, FileNode } from '../../nodes/File/FileNode'
import {
  SettingsDropdownElementProps,
  useSettingsDropdownItem,
} from '../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../ui/SettingsDropdown/useNodeState'

function ClearFileElement({ editor, node }: SettingsDropdownElementProps<FileNode>) {
  const [file] = useNodeState(
    editor,
    node,
    node => node.getFile(),
    () => {},
  )
  return (
    <Dropdown.Item
      icon={<Refresh />}
      disabled={!file}
      onClick={() => {
        editor.update(() => {
          node.setFile(null)
        })
      }}
    >
      Upload New File
    </Dropdown.Item>
  )
}

export function useClearFileSetting() {
  useSettingsDropdownItem({
    identifier: 'clear-File',
    group: 'file',
    weight: -8,
    filter(editor, node) {
      return $isFileNode(node)
    },
    element: ClearFileElement,
  })
}
