import {
  Block,
  HSpaced,
  H2,
  Space,
  Reset,
  Color,
  LoadingIndicator,
  getColor,
  useTheme,
} from '@thesisedu/react'
import React from 'react'

import { error } from '../log'

export function useStudentSelectorOverlayHeader(): Pick<
  StudentSelectorOverlayHeaderProps,
  'saveLoading' | 'setSaveLoading'
> {
  const [saveLoading, setSaveLoading] = React.useState(false)

  return { saveLoading, setSaveLoading }
}

export interface StudentSelectorOverlayHeaderProps {
  saveLoading: boolean
  setSaveLoading: (saveLoading: boolean) => void
  onSave: () => any | Promise<any>
  onCancel: () => void
  saveText: string
  single?: boolean
  title?: string
}
export function StudentSelectorOverlayHeader({
  title = 'Students',
  ...props
}: StudentSelectorOverlayHeaderProps) {
  return (
    <Block marginBottom={'@size-l'}>
      <HSpaced>
        <H2 isBlock={false}>{title}</H2>
        <Space />
        <StudentSelectorOverlayActions {...props} />
      </HSpaced>
    </Block>
  )
}

export function StudentSelectorOverlayActions({
  saveLoading,
  setSaveLoading,
  onSave,
  onCancel,
  saveText,
  single,
}: StudentSelectorOverlayHeaderProps) {
  const theme = useTheme()
  return (
    <HSpaced space={'@size-s'}>
      <Reset.Button disabled={saveLoading} onClick={() => onCancel()}>
        <Color color={'@text-color'}>Cancel</Color>
      </Reset.Button>
      {single ? null : (
        <Reset.Button
          onClick={async () => {
            try {
              setSaveLoading(true)
              await onSave()
            } catch (err: any) {
              error('error when saving student filters')
              error(err)
            } finally {
              setSaveLoading(false)
            }
          }}
          disabled={saveLoading}
        >
          <Color color={saveLoading ? '@text-color-secondary' : '@primary-color'}>
            {`${saveText} `}
          </Color>
          {saveLoading ? (
            <LoadingIndicator
              color={getColor(theme, saveLoading ? '@text-color-secondary' : '@primary-color')}
            />
          ) : null}
        </Reset.Button>
      )}
    </HSpaced>
  )
}
