import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { ClassFragment } from './schema'
import { ClassContextHook } from './types'

export interface WrapWithClassContextProps {
  cls: ClassFragment
  group: string
  children: React.ReactElement
}
export function WrapWithClassContext({ cls, children, group }: WrapWithClassContextProps) {
  return useMutateHook<ClassContextHook>('classes-react:class-context', children, { cls, group })
}
