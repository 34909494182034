import React, { DetailedHTMLProps, HTMLAttributes } from 'react'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { CSSTransitionProps } from 'react-transition-group/CSSTransition'
import { SwitchTransitionProps } from 'react-transition-group/SwitchTransition'
import { createGlobalStyle } from 'styled-components'

export type TransitionProps = Partial<CSSTransitionProps> & {
  state: string
  type: 'fade-up' | 'fade-scale' | 'fade' | string
  containerProps?: Partial<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
  switchProps?: SwitchTransitionProps
}
const FALLBACK_DELAY = 1000
export function Transition({
  state,
  type,
  children,
  containerProps,
  switchProps,
  ...props
}: React.PropsWithChildren<TransitionProps>) {
  return (
    <>
      <TransitionGlobalStyle />
      <SwitchTransition {...switchProps}>
        <CSSTransition
          key={state}
          addEndListener={(node, done) => {
            const interval = setTimeout(() => {
              done()
            }, FALLBACK_DELAY)
            node.addEventListener(
              'transitionend',
              () => {
                clearTimeout(interval)
                done()
              },
              false,
            )
          }}
          classNames={type}
          appear
          {...props}
        >
          <div {...containerProps} children={children} />
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export const TransitionGlobalStyle = createGlobalStyle`
  .fade {
    &-enter-active, &-exit-active {
      transition: opacity 0.25s ease-in-out;
    }
    &-enter {
      opacity: 0;
      &-active {
        opacity: 1;
      }
    }
    &-exit {
      opacity: 1;
      &-active {
        opacity: 0;
      }
    }
  }

  .fade-up {
    &-enter-active, &-exit-active {
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
    &-enter {
      opacity: 0;
      transform: translateY(50px);
      &-active {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &-exit {
      opacity: 1;
      transform: translateY(0);
      &-active {
        opacity: 0;
        transform: translateY(-50px);
      }
    }
  }

  .fade-scale {
    &-enter-active, &-exit-active {
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      transform-origin: 50% 300px;
    }
    &-enter {
      opacity: 0;
      transform: scale(0.98);
      &-active {
        opacity: 1;
        transform: none;
      }
    }
    &-exit {
      opacity: 1;
      transform: none;
      &-active {
        opacity: 0;
        transform: scale(0.98);
      }
    }
  }
`
