import { useResource } from '@thesisedu/feature-react'
import { VSpaced } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { VideoThumbnailTypeResource, VideoThumbnail } from './types'

export interface VideoThumbnailFieldProps {
  value?: VideoThumbnail | null
  onChange?: (value: VideoThumbnail | null | undefined) => void
}
export function VideoThumbnailField({ value, onChange }: VideoThumbnailFieldProps) {
  const [selectedType, setSelectedType] = React.useState(value?.type)
  const types = useResource<VideoThumbnailTypeResource<VideoThumbnail>>('VideoThumbnailType')
  React.useEffect(() => {
    if (!value?.type && types.length === 1) {
      setSelectedType(types[0].identifier)
    }
  }, [types, value?.type])
  const type = types.find(type => type.identifier === selectedType)
  if (selectedType && type) {
    const { FieldComponent } = type
    return <FieldComponent value={value} onChange={onChange} />
  } else {
    return (
      <VSpaced space={'@size-xs'}>
        {types.map(type => (
          <Button
            key={type.identifier}
            icon={type.icon}
            children={`Add ${type.label}`}
            onClick={() => {
              setSelectedType(type.identifier)
            }}
          />
        ))}
      </VSpaced>
    )
  }
}
