import { Draggable } from '@thesisedu/feature-graphic-drag-drop-core'

export const DEFAULT_INSTRUCTIONS = 'Drag and drop to create matches in the scene below.'
export const DRAGGABLE_DEFAULTS: Partial<Draggable> = {
  gifPlaybackLoopTimes: 1,
}

export enum DragItemType {
  Image = 'image',
}
export interface DragItem {
  id: string
}
export interface DropItem {
  id: string
}

// To maintain references from items importing from ./types
export * from '@thesisedu/feature-graphic-drag-drop-core'
