import InboxOutlined from '@ant-design/icons/lib/icons/InboxOutlined'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import { useErrorService } from '@thesisedu/feature-web'
import { UploadFile } from '@thesisedu/native-upload'
import { useToast } from '@thesisedu/ui'
import { styled, StyledThemeContext as ThemeContext, BodyLarge } from '@thesisedu/web'
import { Progress, Upload as AntUpload } from 'antd'
import ImgCrop from 'antd-img-crop'
import React, { useContext } from 'react'

import { useUpload } from './common/useUpload'
import { debug, warn } from './log'
import { UploadProps } from './types'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`
const Left = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: ${props => props.theme['@size-s']};
  margin-right: ${props => props.theme['@size-xs']};
  font-size: 24px;
  color: ${props => props.theme['@text-color']};
`
const Right = styled.div`
  flex-grow: 1;
  text-align: left;
`

function _Upload({ cropProps, ...props }: UploadProps, ref: React.ForwardedRef<any>) {
  const toast = useToast()
  const { uploadAction, beforeUpload, loading, uploadFile, setLoading } = useUpload(
    useErrorService,
    {
      ...props,
      onError: err =>
        toast({
          status: 'error',
          title: err,
        }),
    },
  )
  const theme = useContext(ThemeContext)

  const content = (
    <AntUpload.Dragger
      name={'file'}
      showUploadList={false}
      action={uploadAction}
      beforeUpload={beforeUpload}
      accept={props.accept}
      disabled={props.disabled}
      customRequest={options => {
        const { file, onError, onProgress, onSuccess, withCredentials } = options
        uploadFile(file as UploadFile, {
          withCredentials,
          onUploadProgress: ({ total, loaded }) => {
            const opts = {
              percent: parseFloat(Math.round((loaded / total) * 100).toFixed(2)),
            }
            ;(onProgress as any)(opts, file)
          },
        })
          .then(() => (onSuccess as any)({ status: 200 }, file))
          .catch((err: any) => {
            warn('error uploading file %s', err)
            if (onError) {
              onError(err)
            }
          })
        return { abort: () => false }
      }}
      onChange={info => {
        debug('status is %s', info.file.status)
        if (info.file.status === 'error') {
          setLoading(false)
        }
      }}
    >
      <Container ref={ref}>
        <Left>
          {loading && loading !== true ? (
            <Progress
              type={'circle'}
              percent={loading}
              width={24}
              showInfo={false}
              strokeWidth={parseFloat(theme['@size-xs'].replace('px', ''))}
              strokeColor={theme['@primary-color']}
            />
          ) : loading === true ? (
            <LoadingOutlined spin />
          ) : (
            <InboxOutlined />
          )}
        </Left>
        <Right>
          {loading || typeof props.uploadMessage === 'string' || !props.uploadMessage ? (
            <BodyLarge color={'@text-color'}>
              {loading
                ? 'Uploading...'
                : props.uploadMessage || 'Click or drag file here to upload'}
            </BodyLarge>
          ) : (
            props.uploadMessage
          )}
        </Right>
      </Container>
    </AntUpload.Dragger>
  )

  if (cropProps) {
    // @ts-ignore not upgrading dependency; children types are missing.
    return <ImgCrop {...cropProps} children={content} />
  } else {
    return content
  }
}
export const Upload = React.forwardRef(_Upload)
