import React from 'react'

import { useSplitViewDraggingOverContext } from './SplitViewDraggingOverContext'
import { OutlineItem, isItemWithSegment } from '../outline/types'
import { OutlineData } from '../outline/useOutlineData'
import { Segment } from '../types'

export interface SplitViewOutlineItemContextValue {
  items: React.MutableRefObject<Record<string, OutlineItem>>
}
export const SplitViewOutlineItemContext = React.createContext<
  SplitViewOutlineItemContextValue | undefined
>(undefined)

export function useSplitViewOutlineItemContext(): SplitViewOutlineItemContextValue | undefined
export function useSplitViewOutlineItemContext(
  require: false,
): SplitViewOutlineItemContextValue | undefined
export function useSplitViewOutlineItemContext(require: true): SplitViewOutlineItemContextValue
export function useSplitViewOutlineItemContext(
  require?: boolean,
): SplitViewOutlineItemContextValue | undefined {
  const context = React.useContext(SplitViewOutlineItemContext)
  if (!context && require) {
    throw new Error('SplitViewOutlineItemContext is required, yet not provided.')
  }
  return context
}

export interface UpdateOutlineItemContextOpts {
  data: OutlineData
  droppableDisabled: boolean | undefined
  draggingId: string | undefined
  sortableId: string
}
export interface UpdateOutlineItemContextResult {
  data: OutlineData
}
export function useUpdateOutlineItemContext({
  data,
  sortableId,
  droppableDisabled,
  draggingId,
}: UpdateOutlineItemContextOpts): UpdateOutlineItemContextResult {
  const { draggingOverList } = useSplitViewDraggingOverContext(true)
  const { items: contextItems } = useSplitViewOutlineItemContext(true)
  React.useEffect(() => {
    for (const item of data.items) {
      contextItems.current[item.id] = item
    }
  }, [data.items])

  const isDraggingOver = draggingOverList?.current?.sortableId === sortableId

  // Inject items dragged from other panes into this one.
  if (isDraggingOver || droppableDisabled) {
    if (!droppableDisabled && draggingId && contextItems.current[draggingId]) {
      const overId = draggingOverList?.current?.overId
      if (overId === draggingId && data.items.some(item => item.id === overId)) return { data }
      const filteredItems = data.items.filter(item => item.id !== draggingId)
      const overIndex = overId ? filteredItems.findIndex(item => item.id === overId) : -1
      const insertIndex = overIndex === -1 ? 0 : overIndex + 1
      filteredItems.splice(insertIndex, 0, {
        ...contextItems.current[draggingId],
        isForeign: true,
      })
      return {
        data: {
          ...data,
          items: filteredItems,
        },
      }
    } else {
      return { data }
    }
  } else {
    return {
      data: {
        ...data,
        items: data.items.filter(item => item.id !== draggingId),
      },
    }
  }
}

export function useSplitViewSegment(segmentId?: string): Segment | undefined {
  const { items } = useSplitViewOutlineItemContext(false) ?? {}
  const item = items?.current[segmentId ?? '']
  return item && isItemWithSegment(item) ? item.segment : undefined
}
