import { BrochureLink, useFeatureRoot } from '@thesisedu/feature-react'
import { Button } from '@thesisedu/ui'
import { RefreshCircular } from '@thesisedu/ui/icons'
import React from 'react'

import { LoadingStep, LoadingStepProps } from './LoadingStep'

export interface ProviderErrorProps
  extends Omit<LoadingStepProps, 'title' | 'message' | 'action' | 'state'> {
  onNext: () => void
}
export function ProviderError({ onNext, ...props }: ProviderErrorProps) {
  const appOptions = useFeatureRoot().appOptions
  return (
    <LoadingStep
      {...props}
      state={'error'}
      title={`Check your ${props.providerName} account`}
      message={
        <>
          <span>
            We ran into an issue when trying to talk to {props.providerName}. Try reconnecting your{' '}
            {props.providerName} account, and then try again. If the issue persists,{' '}
            <BrochureLink to={'contact'}>contact {appOptions.name} support.</BrochureLink>
          </span>
        </>
      }
      action={
        <Button
          variant={['chromeless', 'primary']}
          onPress={onNext}
          icon={<RefreshCircular />}
          children={`Reconnect ${props.providerName} Account`}
        />
      }
    />
  )
}
