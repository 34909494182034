import { FeatureResourceManager } from '@thesisedu/feature'

import URLVideoThumbnailType from './URLVideoThumbnailType'
import { VideoThumbnailTypeResource } from '../../types'

const RESOURCES: VideoThumbnailTypeResource<any>[] = [URLVideoThumbnailType]
export function addVideoThumbnailTypes(resourceManager: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resourceManager.addResource(resource)
  }
}
