import React from 'react'

import { ModalFooter, ModalCloseButton } from './Footer'
import { Modal, ModalProps } from './Modal'
import { useStatePropFallback } from '../../utils/useStatePropFallback'
import * as Button$ from '../Button'
import { Text } from '../Text'

export interface ConfirmModalProps extends Partial<ModalProps> {
  title: string
  children?: React.ReactNode | string
  confirm?: Partial<Button$.ButtonProps>
  cancel?: Partial<Button$.ButtonProps>
  onConfirm?: () => void
  onCancel?: () => void
}
export function ConfirmModal({
  title,
  children,
  confirm,
  cancel,
  onConfirm,
  onCancel,
  visible: _visible,
  onVisibleChange,
  ...rest
}: ConfirmModalProps) {
  const [loading, setLoading] = React.useState(false)
  const [visible, setVisible] = useStatePropFallback(_visible, onVisibleChange, false)
  return (
    <Modal title={title} noClose visible={visible} onVisibleChange={setVisible} {...rest}>
      {typeof children === 'string' ? <Text>{children}</Text> : children}
      <ModalFooter>
        <ModalCloseButton
          {...cancel}
          onPress={() => {
            onCancel?.()
            setVisible(false)
          }}
        />
        <Button$.Button
          variant={'primary'}
          children={'OK'}
          {...confirm}
          loading={loading}
          onPress={async () => {
            setLoading(true)
            try {
              await onConfirm?.()
            } finally {
              setLoading(false)
              setVisible(false)
            }
          }}
        />
      </ModalFooter>
    </Modal>
  )
}
