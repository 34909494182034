import { PlusOutlined } from '@ant-design/icons'
import { Modal, AvailableType, AvailableTypeSelector } from '@thesisedu/web'
import { Button } from 'antd'
import Case from 'case'
import React from 'react'

import { useSegmentResources } from './useSegmentResource'
import { SegmentType } from '../types'

export interface AddSegmentModalProps {
  visible?: boolean
  onCancel: () => void
  label: string
  availableTypes: SegmentType[]
  onItemSelected: (type: SegmentType) => void
}
export function AddSegmentModal({
  visible,
  onCancel,
  label,
  availableTypes,
  onItemSelected,
}: AddSegmentModalProps) {
  const resources = useSegmentResources()
  const items = availableTypes.map<AvailableType>(type => {
    const segmentType = resources.find(rsc => rsc.identifier === type)
    if (!segmentType) {
      throw new Error(`Segment type ${type} seems to not exist, inside AddSegmentModal`)
    }
    return {
      icon: segmentType.icon || PlusOutlined,
      label: segmentType.label || Case.title(type),
      type,
    }
  })
  return (
    <Modal
      title={label}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
      ]}
      visible={visible}
    >
      <AvailableTypeSelector onItemSelected={onItemSelected} items={items} />
    </Modal>
  )
}
