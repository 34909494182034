import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { Empty } from '@thesisedu/react'
import { NavigationList } from '@thesisedu/react/devtools'
import { styled, s, Block } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncViewItemOpts } from '.'
import { HistoryListItem } from './HistoryListItem'
import { ListHeader, ListHeaderContext } from './ListHeader'
import { ViewSyncHistoryItem } from './ViewSyncHistoryItem'
import { useSyncHistory } from './useSyncHistory'
import { ListClassSyncHistoryFragment } from '../schema'

export interface ViewSyncHistoryProps extends ClassSyncViewItemOpts {}
export function ViewSyncHistory({ classSyncIds, syncIds }: ViewSyncHistoryProps) {
  const { current, items, loading, totalCount, loadAll } = useSyncHistory(classSyncIds, syncIds)
  const [selectedHistoryId, setSelectedHistoryId] = React.useState<string>()
  const { coreAuthKey } = useImpersonation()
  if (!coreAuthKey) {
    return (
      <Block top={'s'} bottom={'s'}>
        <Empty description={'This is not available in your current authentication scheme.'} />
      </Block>
    )
  }
  return (
    <Container>
      <LeftContainer>
        <ListHeaderContext.Provider
          value={{
            current,
            loading,
            totalCount,
            onRefresh() {
              return loadAll()
            },
          }}
        >
          <NavigationList<ListClassSyncHistoryFragment>
            children={HistoryListItem}
            itemHeight={50}
            items={items}
            selectedIds={selectedHistoryId ? [selectedHistoryId] : undefined}
            onItemSelected={id => setSelectedHistoryId(id)}
            header={{
              height: 30,
              component: ListHeader,
            }}
          />
        </ListHeaderContext.Provider>
      </LeftContainer>
      <RightContainer>
        {selectedHistoryId ? (
          <ViewSyncHistoryItem id={selectedHistoryId} />
        ) : (
          <Block top={'s'} bottom={'s'}>
            <Empty description={'Select a history record from the left to view more detail.'} />
          </Block>
        )}
      </RightContainer>
    </Container>
  )
}

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`
export const LeftContainer = styled.div`
  width: 300px;
  border-right: solid 1px ${s.color('border')};
`
export const RightContainer = styled.div`
  flex: 1;
`
