import { FeatureResourceManager } from '@thesisedu/feature'

import { ClassCode } from './ClassCode'
import { Email } from './Email'
import { ImportStudentModeResource } from '../../types'

const RESOURCES: ImportStudentModeResource[] = [Email, ClassCode]
export function addImportModes(resources: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resources.addResource(resource)
  }
}
