import * as React from 'react'

function SvgB(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#EC6CA4'}
          d={
            'M255.035 194.793a64 64 0 01.865 14.645 80.718 80.718 0 01-2.718 18.435 53.288 53.288 0 01-14.94 24.635c-8.423 8.177-18.473 13.82-29.037 18.63a157.123 157.123 0 01-42.423 12.235 241.027 241.027 0 01-31.508 2.606h-7.207a245.649 245.649 0 01-36.039-2.183c-21.16-2.832-41.187-9.104-59.587-20.124a73.8 73.8 0 01-20.089-17.055 53.755 53.755 0 01-11.017-25.16A90.02 90.02 0 01.12 201.374a51.974 51.974 0 0112.5-30.804c3.676-4.408 8.072-8.013 12.459-11.627a25.446 25.446 0 004.49-4.378 7.87 7.87 0 001.853-4.366 31.633 31.633 0 012.584-11.061c5.931-14.419 10.523-29.414 17.02-43.637a87.542 87.542 0 0116.558-24.85c.834-.866 1.688-1.73 2.584-2.534 2.863-2.72 5.983-5.15 8.835-8.002 4.19-4.12 9.576-6.406 15.074-8.301a95.958 95.958 0 0125.505-4.758c9.909-.64 19.858 0 29.603 1.905 6.693 1.349 13.283 3.162 19.214 6.601 11.45 6.623 21.51 14.83 28.83 26.036a122.464 122.464 0 0111.522 22.77c4.552 11.824 8.917 23.688 13.52 35.522a29.213 29.213 0 012.234 9.619 9.548 9.548 0 003.223 6.426c2.904 2.863 6.178 5.263 9.267 7.972 9.34 8.373 15.641 18.465 18.04 30.886z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.499 211.086c5.838 22.534 22.879 44.759 46.963 48.909a43.608 43.608 0 0018.174-.638c2.872-.721 1.657-5.15-1.226-4.44-21.098 5.325-41.125-8.496-51.596-26.025a72.276 72.276 0 01-7.867-19.032c-.752-2.863-5.148-1.648-4.448 1.226zm234.126.267c-5.426 20.928-20.85 41.392-43.122 45.583a38.244 38.244 0 01-16.352-.525c-2.872-.72-4.118 3.718-1.225 4.439a45.482 45.482 0 0031.724-3.965c10.503-5.314 19.07-14.13 25.073-24.172a76.91 76.91 0 008.34-20.134c.741-2.873-3.696-4.12-4.438-1.226z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.08 52.617h25.536v10.01H117.08z'} />
        <path
          fill={'#EC6CA4'}
          d={
            'M131.166 3.98c-17.06 0-30.938 11.623-30.938 26.02 0 10.374 7.303 19.62 18.356 23.757a35.75 35.75 0 0012.584 2.245 36.232 36.232 0 009.767-1.322c12.534-3.482 21.2-13.379 21.2-24.68 0-14.392-13.905-26.02-30.97-26.02z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.166 5.27c16.398 0 29.679 11.106 29.679 24.73 0 10.682-8.248 20.101-20.255 23.437a34.948 34.948 0 01-9.423 1.275 34.463 34.463 0 01-12.132-2.164c-10.575-3.959-17.517-12.747-17.517-22.547 0-13.63 13.256-24.732 29.648-24.732z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M127.72 12.214l.444.002c2.085.022 3.776.2 5.08.54 1.454.376 2.694 1.016 3.716 1.926 1.019.925 1.824 2.074 2.412 3.437.604 1.377.907 2.778.907 4.197 0 2.054-.624 3.873-1.86 5.433l-.224.272-.082.092.198.097a8.671 8.671 0 012.85 2.333l.228.3.201.285c1.128 1.63 1.692 3.525 1.692 5.666 0 2.813-1.005 5.216-2.99 7.155-1.195 1.195-2.548 2.032-4.076 2.51-1.473.406-3.268.622-5.39.65l-.496.004h-8.394v-34.9h5.784zm2.567 20.075h-1.783v8.555l2.344.001c2.112 0 3.614-.401 4.512-1.154.954-.82 1.418-1.831 1.418-3.092 0-1.223-.448-2.227-1.372-3.064-.84-.771-2.432-1.205-4.78-1.243l-.34-.003zm-.778-13.916h-1.005v8.167h.81c1.693 0 2.879-.358 3.587-1.031.705-.67 1.068-1.689 1.068-3.106 0-1.368-.368-2.353-1.089-3.01-.687-.626-1.702-.972-3.072-1.015l-.3-.005z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.777 165.657h.165c.092 18.425-14.261 33.41-32.157 33.698-17.916-.123-32.404-14.974-32.497-33.389h.186c0 6.643 14.199 11.947 31.827 12.02h.762c17.618-.238 31.745-5.676 31.714-12.329z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.38 125.275a7.98 7.98 0 01-15.96-.013 7.98 7.98 0 017.98-7.978 7.969 7.969 0 017.98 7.991zm59.36 0a7.992 7.992 0 01-13.644 5.648 7.993 7.993 0 015.664-13.64 7.969 7.969 0 017.98 7.992zm-68.607-29.98c3.03-.669 6.18-.573 9.164.279a.793.793 0 00.597-1.453 14.733 14.733 0 00-19.121 4.182.793.793 0 001.153 1.03 18.852 18.852 0 018.207-4.038zm61.605-.7a18.873 18.873 0 017.856 4.738.782.782 0 001.226-.968 14.733 14.733 0 00-18.71-5.747.793.793 0 00.474 1.493 18.757 18.757 0 019.154.484zm-30.952 57.756c1.725.005 3.422.433 4.942 1.246a.433.433 0 10.495-.71 8.236 8.236 0 00-10.884 0 .443.443 0 00.494.72 10.419 10.419 0 014.953-1.256z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgB = React.memo(SvgB)
export default MemoSvgB
