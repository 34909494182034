import { useFeatureRoot } from '@thesisedu/feature-react'
import {
  color,
  styled,
  size,
  BodyLarge,
  Space,
  Color,
  BodySmallTransparent,
  VSpaced,
} from '@thesisedu/react'
import { ShoppingCodeCheck } from '@thesisedu/react/icons'
import React from 'react'

import { ResetGeneratedAssignmentButton } from './ResetGeneratedAssignmentButton'

export interface GeneratedAssignmentDirtyStatusProps {
  assignmentId: string
}
export function GeneratedAssignmentDirtyStatus({
  assignmentId,
}: GeneratedAssignmentDirtyStatusProps) {
  const root = useFeatureRoot()
  return (
    <Container>
      <Color color={'@green'}>
        <ShoppingCodeCheck width={64} height={64} />
      </Color>
      <VSpaced space={'@size-xs'} align={'flex-start'}>
        <BodyLarge color={'@green'}>This assignment has been automatically generated.</BodyLarge>
        <BodySmallTransparent color={'@green'}>
          This means it has been automatically created by another part of {root.appOptions.name},
          and will automatically update unless you customize the settings.
        </BodySmallTransparent>
      </VSpaced>
      <Space />
      <ResetGeneratedAssignmentButton assignmentId={assignmentId} />
    </Container>
  )
}

export const Container = styled.div`
  background: ${color('@green-light')};
  padding: ${size('@size-s')};
  border-radius: ${size('@border-radius-medium')};
  display: flex;
  align-items: center;
  gap: ${size('@size-s')};
  button {
    background: ${color('@gray-0')};
  }
`
