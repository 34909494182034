import * as Toast from '@radix-ui/react-toast'
import { Random } from '@thesisedu/feature-utils'
import { motion } from 'framer-motion'
import React from 'react'

import { styled, s, Text$, Text } from '../..'

export const Provider = Toast.Provider

export const Viewport = styled(Toast.Viewport)`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: ${s.size('l')};
  gap: ${s.size('s')};
  width: 500px;
  max-width: 100vw;
  list-style: none;
  outline: none;
  z-index: ${s.var('zIndices.notifications')};
`

const { css } = s
const ToastRootStatuses = {
  default: css`
    --toast-background: ${s.color('background')};
    --toast-text-primary: ${s.color('text')};
    --toast-text-secondary: ${s.color('secondary')};
  `,
  success: css`
    --toast-background: ${s.color('green')};
    --toast-text-primary: white;
    --toast-text-secondary: rgba(255, 255, 255, 0.7);
  `,
  error: css`
    --toast-background: ${s.color('red')};
    --toast-text-primary: white;
    --toast-text-secondary: rgba(255, 255, 255, 0.7);
  `,
} satisfies s.Variants
export type ToastStatus = keyof typeof ToastRootStatuses

export const Icon = styled.div`
  grid-area: icon;
  color: var(--toast-text-primary);
  font-size: ${s.size('font.xl')};
  transition: color 0.1s linear;
  margin-right: ${s.size('s')};
`

const ToastRootVariants = { ...ToastRootStatuses } satisfies s.Variants
const __Root = s.styledWithVariants(
  Toast.Root,
  'StyledToastRoot',
  css`
    ${s.getVariantCss('dark')}

    position: relative;
    background: var(--toast-background);
    transition: background 0.1s linear;
    border-radius: ${s.var('radii.1')};
    box-shadow: ${s.var('shadow.1')};
    padding: ${s.size('s')} calc(${s.size('s')} * 1.5);
    display: grid;
    grid-template-areas: 'icon title action' 'icon description action';
    grid-template-columns: max-content auto max-content;
    align-items: center;

    &[data-swipe='move'] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe='cancel'] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
  `,
  ToastRootVariants,
)

export interface RootProps extends Toast.ToastProps {
  status?: ToastStatus
  className?: string
  id?: string
}
function _Root(
  { status = 'default', className, children, id: _id, ...rest }: RootProps,
  ref: React.ForwardedRef<HTMLLIElement>,
) {
  const id = React.useMemo(() => _id ?? Random.id(), [_id])
  return (
    <__Root
      {...rest}
      className={s.variants<typeof ToastRootVariants>(className, {}, status)}
      ref={ref}
      asChild
    >
      <motion.li
        key={id}
        children={children}
        layoutId={id}
        initial={{ opacity: 0, y: 15, scale: 0.7 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9, transition: { duration: 0.2 } }}
      />
    </__Root>
  )
}
export const Root = React.forwardRef(_Root)

export interface TitleProps extends Text$.TextProps {}
export function Title(props: TitleProps) {
  return (
    <_Title>
      <Text level={'m'} {...props} />
    </_Title>
  )
}
const _Title = styled(Toast.Title)`
  grid-area: title;
  * {
    color: var(--toast-text-primary);
    transition: color 0.1s linear;
  }
`

export interface DescriptionProps extends Text$.TextProps {}
export function Description(props: DescriptionProps) {
  return (
    <_Description>
      <Text level={'s'} {...props} />
    </_Description>
  )
}
const _Description = styled(Toast.Description)`
  grid-area: description;
  * {
    color: var(--toast-text-secondary);
    transition: color 0.1s linear;
  }
`

export type ActionProps = Toast.ToastActionProps
export const Action = styled(Toast.Action)`
  grid-area: action;
  margin-left: ${s.size('s')};
`
export const MultipleActions = styled.div`
  grid-area: action;
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  > ${Action} {
    margin-left: 0;
  }
`
