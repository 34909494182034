import { SettingOutlined } from '@ant-design/icons'
import { Play, Pause, Stop } from '@sammarks/icons'
import { useStatePropFallback } from '@thesisedu/feature-react'
import { Row, Block, HSpaced, styled, VSpaced, Body } from '@thesisedu/web'
import { TimingCallbacks, MidiBuffer, renderAbc, synth } from 'abcjs'
import { Col, Button, Popover } from 'antd'
import React from 'react'

import { InstrumentSelect } from './InstrumentSelect'
import { useHasValue } from '../editor/useHasValue'
import { warn, debug } from '../log'
import { InstrumentResource, DEFAULT_BPM } from '../types'
import { useInstruments } from '../useInstruments'

declare module 'abcjs' {
  interface MidiBuffer {
    stop(): void
  }
}

type OnProgress = (percent: number) => void
type OnDuration = (durationInMs: number) => void
export interface PlaybackContainerProps {
  abc: string
  playing?: boolean
  onPlayingChange?: (playing: boolean) => void
  hideControls?: boolean
  onProgress?: OnProgress
  onDuration?: OnDuration
  actions?: React.ReactElement
  defaultSelectedInstrumentKey?: string
  selectedInstrumentKey?: string
  onInstrumentSelected?: (selectedInstrumentKey: string) => void
  instruments?: InstrumentResource[]
  additionalOptions?: React.ReactElement
  bpm?: number
}
export function PlaybackContainer({
  abc,
  playing: _playing,
  onPlayingChange,
  children,
  hideControls,
  onProgress,
  onDuration,
  actions,
  defaultSelectedInstrumentKey,
  selectedInstrumentKey: _selectedInstrumentKey,
  onInstrumentSelected: _onInstrumentSelected,
  instruments: _instruments,
  additionalOptions,
  bpm = DEFAULT_BPM,
}: React.PropsWithChildren<PlaybackContainerProps>) {
  const hasValue = useHasValue(abc)
  const childrenMemo = React.useMemo(() => children, [children])
  const synthRef = React.useRef<MidiBuffer>()
  const timingCallbacksRef = React.useRef<TimingCallbacks>()
  const onProgressRef = React.useRef<OnProgress | undefined>(onProgress)
  const durationCalledRef = React.useRef(false)
  const onDurationRef = React.useRef<OnDuration | undefined>(onDuration)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const instruments = _instruments || useInstruments({ abc })
  const [selectedInstrumentKey, setSelectedInstrumentKey] = useStatePropFallback<string>(
    _selectedInstrumentKey,
    _onInstrumentSelected,
    defaultSelectedInstrumentKey &&
      instruments.some(i => i.identifier === defaultSelectedInstrumentKey)
      ? defaultSelectedInstrumentKey
      : instruments[0].identifier!,
  )
  const selectedInstrument = instruments.find(i => i.identifier === selectedInstrumentKey)
  // When the list of instruments changes and the selected instrument is invalid, reset it.
  React.useEffect(() => {
    if (!instruments.some(i => i.identifier === selectedInstrumentKey)) {
      debug('resetting instrument inside PlaybackContainer')
      setSelectedInstrumentKey(instruments[0]!.identifier!)
    }
  }, [instruments.length, selectedInstrumentKey])
  React.useEffect(() => {
    onProgressRef.current = onProgress
  }, [onProgress])
  React.useEffect(() => {
    onDurationRef.current = onDuration
  }, [onDurationRef])
  const [context] = React.useState(new AudioContext())
  const [synthReady, setSynthReady] = React.useState(false)
  const [playing, setPlaying] = useStatePropFallback<boolean>(
    _playing || false,
    onPlayingChange,
    false,
  )
  const stoppedRef = React.useRef<boolean>(false)
  const stop = () => {
    stoppedRef.current = true
    synthRef.current?.stop()
    timingCallbacksRef.current?.stop()
  }
  React.useEffect(() => {
    return () => stop()
  }, [])
  React.useEffect(() => {
    if (!_playing && synthRef.current && timingCallbacksRef.current) {
      stop()
    }
  }, [_playing])
  const [startChar, setStartChar] = React.useState<number | undefined>(undefined)
  React.useLayoutEffect(() => {
    durationCalledRef.current = false
    if (!playing) {
      setStartChar(undefined)
    }
    if (synthRef.current && timingCallbacksRef.current) {
      if (playing) {
        const wasStopped = stoppedRef.current
        stoppedRef.current = false
        synthRef.current.start!()
        timingCallbacksRef.current.start(wasStopped ? 0 : undefined)
      } else if (!stoppedRef.current) {
        synthRef.current.pause!()
        timingCallbacksRef.current.pause()
      }
    }
  }, [playing])
  React.useEffect(() => {
    timingCallbacksRef.current = undefined
  }, [bpm])
  React.useEffect(() => {
    if (!selectedInstrument) return
    setSynthReady(false)
    const contents = renderAbc('*', abc)
    if (!contents[0]) return
    stop()
    if (timingCallbacksRef.current) {
      timingCallbacksRef.current.replaceTarget(contents[0])
    } else {
      timingCallbacksRef.current = new TimingCallbacks(contents[0], {
        qpm: bpm,
        eventCallback: event => {
          if (event === null) {
            setTimeout(() => {
              stop()
              setPlaying(false)
            }, 100)
          } else if (event.startCharArray.length) {
            setStartChar(event.startCharArray[0])
          } else {
            setStartChar(undefined)
          }
        },
        beatSubdivisions: 1,
        beatCallback: (beatNumber, totalBeats, totalTime) => {
          if (onDurationRef.current && !durationCalledRef.current) {
            onDurationRef.current(totalTime)
            durationCalledRef.current = true
          }
          if (onProgressRef.current) {
            onProgressRef.current(beatNumber / totalBeats)
          }
        },
      })
    }
    setPlaying(false)
    synthRef.current = new synth.CreateSynth()
    synthRef.current.init!({
      audioContext: context,
      visualObj: contents[0],
      millisecondsPerMeasure: 2000 * (120 / bpm), // 2000 is 120bpm?
      options: {
        soundFontUrl: selectedInstrument.soundfontUrl,
        program: synth.instrumentIndexToName.indexOf(selectedInstrument.instrument),
      },
    })
      .then(() => {
        if (synthRef.current) {
          synthRef.current.prime!()
        }
      })
      .then(() => {
        setSynthReady(true)
      })
      .catch(err => {
        warn('error setting up synth')
        warn(err)
      })
  }, [abc, selectedInstrument, bpm])

  // Scroll the current note into view.
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (startChar && containerRef.current) {
      const element = containerRef.current.querySelector(`[data-start-char='${startChar}']`)
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }, [startChar])

  return (
    <Container startChar={startChar} playing={playing} ref={containerRef}>
      {!hideControls ? (
        <Block marginBottom={'@size-l'}>
          <Row>
            <Col xs={24} md={10} />
            <Col xs={24} md={4}>
              <PlayContainer>
                <HSpaced>
                  <StyledButton
                    size={'large'}
                    shape={'circle'}
                    type={'primary'}
                    disabled={!synthReady || !hasValue}
                    className={playing ? '' : 'play'}
                    icon={playing ? <Pause /> : <Play />}
                    onClick={() => {
                      setPlaying(!playing)
                    }}
                  />
                  <StyledButton
                    size={'small'}
                    shape={'circle'}
                    icon={<Stop />}
                    disabled={!playing || !synthReady || !hasValue}
                    onClick={() => {
                      if (synthRef.current && timingCallbacksRef.current) {
                        stop()
                        setPlaying(false)
                      }
                    }}
                  />
                </HSpaced>
              </PlayContainer>
            </Col>
            <Col xs={24} md={10}>
              <HSpaced style={{ width: '100%' }} justify={'flex-end'}>
                <Popover
                  title={'Composition Settings'}
                  placement={'bottomRight'}
                  trigger={['click']}
                  content={
                    <VSpaced align={'stretch'} space={'@size-s'}>
                      <VSpaced space={'@size-xxs'}>
                        <Body>Instrument</Body>
                        <InstrumentSelect
                          style={{ width: 300 }}
                          value={selectedInstrumentKey}
                          onChange={setSelectedInstrumentKey}
                          instruments={instruments}
                        />
                      </VSpaced>
                      {additionalOptions}
                    </VSpaced>
                  }
                >
                  <Button icon={<SettingOutlined />} />
                </Popover>
                {actions}
              </HSpaced>
            </Col>
          </Row>
        </Block>
      ) : null}
      {childrenMemo}
    </Container>
  )
}

const Container = styled.div<{ playing: boolean; startChar: number | undefined }>`
  [data-start-char] {
    transition: opacity 0.25s ease-in-out;
    opacity: ${props => (props.playing ? 0.25 : 1)};
    .scale {
      transition: transform 0.25s ease-in-out;
    }
  }
  [data-start-char~='${props => props.startChar}'] {
    opacity: 1;
    .scale {
      transform: scale(1.1);
    }
  }
`
const PlayContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
`
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  &.play * {
    position: relative;
    left: 1px;
  }
`
