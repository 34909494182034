import { darken } from 'polished'
import React from 'react'
import {
  VariableSizeGrid as Grid,
  VariableSizeGridProps,
  GridChildComponentProps,
} from 'react-window'

import { debug } from '../log'
import { styled } from '../styledTypes'

interface StickyGridContextValue {
  ItemRenderer: VariableSizeGridProps['children']
  stickColumns?: number
  stickRows?: number
  columnCount: number
  rowCount: number
  data?: ItemData
  columnWidth: VariableSizeGridProps['columnWidth']
  rowHeight: VariableSizeGridProps['rowHeight']
}
const StickyGridContext = React.createContext<StickyGridContextValue | undefined>(undefined)

function ItemWrapper({ data, columnIndex, rowIndex, style, ...rest }: GridChildComponentProps) {
  const { ItemRenderer, stickColumns, stickRows, columnCount } = data as StickyItemData
  const stuckColumns = stickColumns && columnIndex < stickColumns
  const stuckRows = stickRows && rowIndex < stickRows
  if (stuckColumns || stuckRows) {
    return null
  }
  return (
    <ItemRenderer
      columnIndex={columnIndex}
      rowIndex={rowIndex}
      data={data}
      style={{
        ...style,
        borderRight: columnIndex === columnCount - 1 ? 'none' : undefined,
      }}
      {...rest}
    />
  )
}

const Z_INDEX = 2
const innerElementType = React.forwardRef<HTMLDivElement, React.PropsWithChildren<any>>(
  ({ children, ...rest }, ref) => {
    const {
      stickColumns,
      stickRows,
      ItemRenderer,
      columnCount,
      rowCount,
      data,
      columnWidth,
      rowHeight,
    } = React.useContext(StickyGridContext)!
    const items: React.ReactElement[] = []
    const columnLefts: number[] = [0]
    const rowTops: number[] = [0]
    for (let x = 1; x < columnCount; x++) {
      columnLefts.push(columnLefts[columnLefts.length - 1] + columnWidth(x - 1))
    }
    for (let y = 1; y < rowCount; y++) {
      rowTops.push(rowTops[rowTops.length - 1] + rowHeight(y - 1))
    }
    const heightOffset = stickRows ? rowTops[stickRows] : 0
    if (stickRows && stickColumns) {
      const stuckItems: React.ReactElement[] = []
      for (let y = 0; y < stickRows; y++) {
        for (let x = 0; x < stickColumns; x++) {
          stuckItems.push(
            <ItemRenderer
              rowIndex={y}
              columnIndex={x}
              key={`stuck-both-${x}-${y}`}
              style={{
                top: rowTops[y],
                left: columnLefts[x],
                width: columnWidth(x),
                height: rowHeight(y),
                position: 'absolute',
              }}
              data={data}
            />,
          )
        }
      }
      items.push(
        <div
          key={'stuck-rows-columns'}
          style={{
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: Z_INDEX + 1,
            width: columnLefts[stickColumns],
            height: rowTops[stickRows],
          }}
        >
          {stuckItems}
        </div>,
      )
    }
    if (stickRows) {
      const stuckItems: React.ReactElement[] = []
      for (let y = 0; y < stickRows; y++) {
        for (let x = 0; x < columnCount; x++) {
          if (stickColumns && x < stickColumns) continue // We don't want to duplicate stuck columns.
          stuckItems.push(
            <ItemRenderer
              rowIndex={y}
              columnIndex={x}
              key={`stuck-row-${x}-${y}`}
              style={{
                top: rowTops[y] - heightOffset,
                left: columnLefts[x],
                width: columnWidth(x),
                height: rowHeight(y),
                position: 'absolute',
                pointerEvents: 'all',
                borderRight: x === columnCount - 1 ? 'none' : undefined,
              }}
              data={data}
            />,
          )
        }
      }
      items.push(
        <div
          key={'stuck-rows'}
          style={{
            position: 'sticky',
            top: heightOffset,
            zIndex: Z_INDEX,
            width: columnLefts[columnLefts.length - 1] + columnWidth(columnCount),
            height: rowTops[stickRows],
            pointerEvents: 'none',
          }}
        >
          {stuckItems}
        </div>,
      )
    }
    if (stickColumns) {
      const stuckItems: React.ReactElement[] = []
      for (let x = 0; x < stickColumns; x++) {
        for (let y = 0; y < rowCount; y++) {
          if (stickRows && y < stickRows) continue // We don't want to duplicate stuck rows.
          stuckItems.push(
            <ItemRenderer
              rowIndex={y}
              columnIndex={x}
              key={`stuck-column-${x}-${y}`}
              style={{
                top: rowTops[y] - heightOffset * 2,
                left: columnLefts[x],
                width: columnWidth(x),
                height: rowHeight(y),
                position: 'absolute',
                zIndex: Z_INDEX,
              }}
              data={data}
            />,
          )
        }
      }
      items.push(
        <div
          key={'stuck-columns'}
          className={'sticky-grid-stuck-columns'}
          style={{
            position: 'sticky',
            left: 0,
            zIndex: Z_INDEX,
            height: rowTops[rowTops.length - 1] + rowHeight(rowCount) - heightOffset * 2,
            width: columnLefts[stickColumns],
          }}
        >
          <div className={'sticky-grid-stuck-columns-ping'} style={{ top: -heightOffset * 2 }} />
          {stuckItems}
        </div>,
      )
    }
    return (
      <div ref={ref} {...rest}>
        {items}
        {children}
      </div>
    )
  },
)

interface ItemData {
  [key: string]: any
}
type StickyItemData = StickyGridContextValue & ItemData
export interface StickyGridProps extends VariableSizeGridProps {
  stickRows?: number
  stickColumns?: number
  itemData?: ItemData
  gridRef?: React.MutableRefObject<Grid | null>
}
export function StickyGrid({
  stickRows,
  stickColumns,
  children,
  gridRef: propGridRef,
  ...rest
}: StickyGridProps) {
  const [hasPingLeft, setHasPingLeft] = React.useState(false)
  const [hasPingRight, setHasPingRight] = React.useState(false)
  const gridRef = React.useRef<Grid | null>(null)
  const value = {
    ItemRenderer: children,
    stickRows,
    stickColumns,
    columnCount: rest.columnCount,
    rowCount: rest.rowCount,
  }
  const didMount = React.useRef<boolean>(false)
  React.useEffect(() => {
    if (gridRef.current && didMount.current) {
      debug('stickColumns or stickRows changed; resetting grid sizes')
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      })
    }
    didMount.current = true
  }, [stickColumns, stickRows])
  const totalWidth = Array(rest.columnCount)
    .fill('')
    .reduce((sum, _, index) => {
      return sum + rest.columnWidth(index)
    }, 0)
  const classes: string[] = []
  if (hasPingLeft) classes.push('sticky-grid-ping-left')
  if (hasPingRight) classes.push('sticky-grid-ping-right')
  return (
    <StickyGridContext.Provider
      value={{
        ...value,
        data: rest.itemData,
        columnWidth: rest.columnWidth,
        rowHeight: rest.rowHeight,
      }}
    >
      <PingContainer className={classes.join(' ')}>
        <Grid
          {...rest}
          ref={c => {
            gridRef.current = c
            if (propGridRef) {
              propGridRef.current = c
            }
          }}
          innerElementType={innerElementType}
          onScroll={opts => {
            if (rest.onScroll) {
              rest.onScroll(opts)
            }
            const { scrollLeft } = opts
            setHasPingLeft(scrollLeft > 10)
            setHasPingRight(scrollLeft < totalWidth - 10 - rest.width)
          }}
          itemData={{
            ...value,
            ...rest.itemData,
          }}
        >
          {ItemWrapper}
        </Grid>
      </PingContainer>
    </StickyGridContext.Provider>
  )
}

const PingContainer = styled.div`
  .sticky-grid-stuck-columns-ping {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    width: ${props => props.theme['@size-m']};
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: ' ';
    pointer-events: none;
  }
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 30px;
    transition: box-shadow 0.3s;
    content: '';
    pointer-events: none;
    right: 0;
  }
  &.sticky-grid-ping-left .sticky-grid-stuck-columns-ping {
    box-shadow: inset 10px 0 8px -8px ${props => darken(0.15, props.theme['@shadow-color'])};
  }
  &.sticky-grid-ping-right::after {
    box-shadow: inset -10px 0 8px -8px ${props => darken(0.15, props.theme['@shadow-color'])};
  }
`
