import { ElementsDropdownSubMenu } from '../../ui/ElementsMenu/ElementsDropdownSubMenu'
import { useSettingsDropdownItem } from '../../ui/SettingsDropdown/SettingsDropdownContext'

export function useReplaceWithSettingsItem() {
  return useSettingsDropdownItem({
    identifier: 'replace-with',
    weight: 0,
    element: ElementsDropdownSubMenu,
  })
}
