import React from 'react'

import { ExternalRoot } from './ExternalRoot'
import * as Parts from './ToastParts'
import { Button, Button$, s } from '../..'
import { Cancel, CheckCircle, WarningCircle } from '../../../icons'
import { useStatePropFallback } from '../../utils/useStatePropFallback'
import LoadingIndicator from '../LoadingIndicator'

export const DEFAULT_TIMEOUT = 5000

export interface ToastProps extends Parts.RootProps {
  /** An icon to display with the toast. */
  icon?: React.ReactElement
  /** The title of the toast. */
  title: string
  /** A simple description for the toast. Displayed in smaller text. */
  description?: string
  /**
   * Either a single button or an array of custom elements (not necessarily buttons). These
   * will automatically have their sizes adjusted to fit the toast.
   */
  action?:
    | (Parts.ActionProps['children'] & React.ReactElement<Pick<Button$.ButtonProps, 'size'>>)
    | React.ReactElement[]
  /**
   * A short description for an alternate way to carry out the action. For screen reader users
   * who will not be able to navigate to the button easily/quickly.
   */
  actionAlt?: string
  /**
   * How long should the toast be displayed for? If `null`, the toast will not automatically
   * close.
   *
   * @default 5000
   */
  timeout?: number | null
  /**
   * The status of the toast. This will automatically set the icon and color.
   */
  status?: Parts.ToastStatus
  /**
   * Whether the toast is currently loading. This will automatically set the icon and disable
   * the timeout (by default).
   */
  loading?: boolean
  /** Whether or not the toast is closable. */
  closable?: boolean
  /** @internal */
  __useInternalRoot?: boolean
}
export function Toast({
  title,
  description,
  action,
  actionAlt,
  status,
  closable,
  icon = status === 'success' ? (
    <CheckCircle />
  ) : status === 'error' ? (
    <WarningCircle />
  ) : undefined,
  loading,
  timeout = loading ? null : DEFAULT_TIMEOUT,
  __useInternalRoot,
  ...rest
}: ToastProps) {
  const [open, setOpen] = useStatePropFallback(rest.open, rest.onOpenChange, true)
  React.useEffect(() => {
    if (timeout === null) return
    const timer = setTimeout(() => setOpen(false), timeout)
    return () => clearTimeout(timer)
  }, [timeout])
  const Root = __useInternalRoot ? Parts.Root : ExternalRoot
  return (
    <Root status={status} {...rest} open={open} onOpenChange={() => {}}>
      {loading ? (
        <Parts.Icon children={<LoadingIndicator style={{ display: 'block' }} />} />
      ) : icon ? (
        <Parts.Icon children={icon} />
      ) : null}
      {closable ? (
        <Button
          size={'extraSmall'}
          variant={'ghost'}
          icon={<Cancel />}
          onPress={() => setOpen(false)}
          style={{
            position: 'absolute',
            top: s.size('xxs'),
            right: s.size('xxs'),
          }}
        />
      ) : null}
      <Parts.Title children={title} />
      {description ? <Parts.Description children={description} /> : null}
      {Array.isArray(action) ? (
        <Parts.MultipleActions
          children={action.map(act => React.cloneElement(act, { size: 'small' }))}
        />
      ) : action && actionAlt ? (
        <Parts.Action
          altText={actionAlt}
          asChild
          children={React.cloneElement(action, {
            size: 'small',
          })}
        />
      ) : null}
    </Root>
  )
}
