import { toGlobalId } from '@thesisedu/feature-utils'
import { List } from 'antd'
import React from 'react'

import { SegmentListItem } from './SegmentListItem'
import { useSegmentPreviewContext } from './SegmentPreviewContext'
import { Segment } from '../types'

export interface SegmentGroupViewProps {
  segment: Segment
}
export function SegmentGroupView({ segment }: SegmentGroupViewProps) {
  const { setPreviewSegmentId, actionsRef } = useSegmentPreviewContext(false) || {}
  if (segment.childSegments?.length) {
    return (
      <>
        <List itemLayout={'vertical'}>
          {(segment.childSegments || []).map(child => {
            const action = actionsRef?.current
              ? actionsRef.current(toGlobalId('Segment', child.id))
              : undefined
            return (
              <SegmentListItem
                key={child.id}
                segment={
                  {
                    ...child,
                    id: toGlobalId('Segment', child.id),
                    tagSummary: child.tags,
                  } as any
                }
                onClick={() => {
                  if (setPreviewSegmentId) {
                    setPreviewSegmentId(toGlobalId('Segment', child.id))
                  }
                }}
                onAct={() => {}}
                action={
                  action ? (
                    React.cloneElement(action, {
                      type: 'link',
                    })
                  ) : (
                    <></>
                  )
                }
              />
            )
          })}
        </List>
      </>
    )
  } else {
    return null
  }
}
