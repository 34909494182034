import { UploadStatus } from './types'
import { MediaStatus } from '../schema'

export const convertStatus = (status: MediaStatus): UploadStatus => {
  switch (status) {
    case MediaStatus.Complete:
      return UploadStatus.Complete
    case MediaStatus.Error:
      return UploadStatus.Failed
    case MediaStatus.Pending:
      return UploadStatus.Uploading
    case MediaStatus.Processing:
      return UploadStatus.Processing
    case MediaStatus.Submitted:
      return UploadStatus.Processing
  }
}
