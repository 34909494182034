import { Dropdown, LoadingIndicator, styled } from '@thesisedu/ui'
import React from 'react'

import { RecordFooterButton } from './RecordFooterButton'
import { SelectMediaSourceProps } from '../../selectDevice/SelectMediaSource'
import { Indicator } from '../../selectDevice/styled'
import { useMediaDevices } from '../../selectDevice/useMediaDevices'

export interface SelectMediaSourceFooterButtonProps extends SelectMediaSourceProps {}
export function SelectMediaSourceFooterButton({
  value,
  onChange,
  disabled,
  type,
  constraints,
  icon,
  label,
}: SelectMediaSourceFooterButtonProps) {
  const { devices, state } = useMediaDevices({ constraints, type, refreshDevicesOnMount: true })
  const errorState = state === 'error' || state === 'noPermission' || state === 'unsupported'

  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <MediaSourceFooterButton
          disabled={disabled || state === 'loading' || state === 'unsupported'}
          aria-label={`Select a ${label} Device...`}
        >
          {state === 'loading' ? <LoadingIndicator /> : icon}
          <Indicator
            $color={
              state === 'loading'
                ? 'blue'
                : state === 'noPermission'
                ? 'orange'
                : disabled || errorState || !value
                ? 'red'
                : 'green'
            }
          />
        </MediaSourceFooterButton>
      </Dropdown.ManualTrigger>
      <Dropdown.Menu side={'top'} align={'center'}>
        {state === 'idle' ? (
          <>
            {devices.map(device => {
              return (
                <Dropdown.Item.Checkbox
                  key={device.id}
                  checked={value === device.id}
                  onCheckedChange={checked => {
                    onChange?.(checked ? device.id : undefined)
                  }}
                >
                  {device.label}
                </Dropdown.Item.Checkbox>
              )
            })}
            <Dropdown.Item.Separator />
            <Dropdown.Item.Checkbox
              checked={!value}
              onCheckedChange={checked => {
                if (checked) {
                  onChange?.(undefined)
                }
              }}
            >
              No {label}
            </Dropdown.Item.Checkbox>
          </>
        ) : (
          []
        )}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}

export const MediaSourceFooterButton = styled(RecordFooterButton)`
  display: flex;
  align-items: center;
  flex-direction: column;
`
