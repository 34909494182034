import { AnswerWrapper } from '@thesisedu/feature-assignments-react/dist/resources/questions/Answer'
import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

export const Container: any = styled.div<{ width?: number; height?: number }>`
  position: relative;
  margin: 0 auto;
  aspect-ratio: ${props =>
    props.width && props.height ? `${props.width} / ${props.height}` : 'auto'};
  max-height: 80vh;
  &.read-only .draggable {
    pointer-events: none;
  }
`
export const FullscreenContainer: React.FC<React.PropsWithChildren<any>> = styled.div<{
  enabled?: boolean
}>`
  ${props => (props.enabled ? '&' : '&.noop')} {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Container} {
      max-height: 100vh;
    }
    &.editor {
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
      ${Container} {
        height: calc(100vh - 75px);
        max-height: calc(100vh - 75px);
      }
    }
  }
`
export const ItemContainer: React.FC<React.PropsWithChildren<any>> = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
export const DropArea: React.FC<React.PropsWithChildren<any>> = styled(AnswerWrapper)`
  background: ${props => transparentize(0.75, props.theme['@primary-color'])};
  border: solid 2px ${props => transparentize(0.25, props.theme['@primary-color'])};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition: opacity 0.25s linear;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.has-children {
    background: transparent;
    border-color: transparent;
  }
`
export const DraggableContainer: React.FC<React.PropsWithChildren<any>> = styled.div`
  position: relative;
  > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
`
export const DraggableEditContainer: React.FC<React.PropsWithChildren<any>> = styled(
  DraggableContainer,
)`
  border: solid 2px ${props => props.theme['@border-color-base']};
  &.pairing {
    border-color: ${props => props.theme['@primary-color']};
  }
  &.pairing-mode {
    cursor: pointer;
  }
  &.not-paired:not(.pairing) {
    border-color: ${props => props.theme['@red']};
  }
  border-radius: ${props => props.theme['@border-radius-base']};
`
