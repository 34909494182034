import { DocumentNode } from '@apollo/client'
import { IFeatureHookManager } from '@thesisedu/feature'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { uniqBy } from 'lodash'

import { useFeatureRoot } from './feature'
import { modifyFragments } from './fragments'

interface ModifyCache {
  [queryName: string]: DocumentNode
}
const modifyCache: ModifyCache = {}

export function useModifiedFragmentDocument(fragment: DocumentNode): DocumentNode {
  const root = useFeatureRoot()!
  return modifyFragmentDocument(fragment, root.deps.hookManager)
}

export function modifyFragmentDocument(
  fragment: DocumentNode,
  hookManager: IFeatureHookManager,
): DocumentNode {
  const fragments = fragment.definitions.filter(
    def => def.kind === 'FragmentDefinition',
  ) as FragmentDefinitionNode[]
  const nonFragments = fragment.definitions.filter(def => def.kind !== 'FragmentDefinition')
  const modifiedFragments = modifyFragments(fragments, hookManager)
  return {
    ...fragment,
    definitions: [...nonFragments, ...modifiedFragments],
  }
}

export function modifyQueryDocument(
  query: DocumentNode,
  hookManager: IFeatureHookManager,
): DocumentNode {
  const operations = query.definitions.filter(
    def => def.kind === 'OperationDefinition',
  ) as OperationDefinitionNode[]
  const operationName = operations[0]?.name?.value
  if (operationName && modifyCache[operationName]) {
    return modifyCache[operationName]
  } else if (operationName) {
    const fragments = query.definitions.filter(
      def => def.kind === 'FragmentDefinition',
    ) as FragmentDefinitionNode[]
    const nonFragments = query.definitions.filter(def => def.kind !== 'FragmentDefinition')
    const modifiedFragments = modifyFragments(fragments, hookManager)
    modifyCache[operationName] = {
      ...query,
      // Make sure we're not accidentally adding multiple fragments of the same name.
      definitions: uniqBy(
        [...nonFragments, ...modifiedFragments],
        f => (f as FragmentDefinitionNode).name.value,
      ),
    }
    return modifyCache[operationName]
  } else {
    return query
  }
}
