import { useResource } from '@thesisedu/feature-react'
import { BlockSpin, styled } from '@thesisedu/web'
import React from 'react'

import { UserAccountProvider } from './UserAccountProvider'
import { useViewerLinkedAccountsQuery } from '../../schema'
import { AuthProviderHandlerResource } from '../../types'

export function UserAccountsPage() {
  const { data, loading, refetch } = useViewerLinkedAccountsQuery({
    fetchPolicy: 'cache-and-network',
  })
  const accounts = data?.viewer?.linkedAccounts
  const authProviders = useResource<AuthProviderHandlerResource>('AuthProviderHandler')

  if (accounts) {
    return (
      <Container>
        {authProviders.map(provider => (
          <UserAccountProvider
            key={provider.identifier!}
            provider={provider}
            accounts={accounts.filter(acc => acc.provider === provider.identifier)}
            refetchAccounts={() => {
              setTimeout(() => {
                refetch()
              }, 1000)
            }}
          />
        ))}
      </Container>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return null
  }
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
  > div {
    margin: 0 ${props => props.theme['@size-l']} ${props => props.theme['@size-l']} 0;
  }
`
