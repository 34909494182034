import { Format } from '@thesisedu/feature-utils'
import { Empty } from '@thesisedu/react'
import {
  styled,
  Text,
  LoadingIndicator,
  TextField,
  Block,
  Checkbox,
  VSpaced,
  HSpaced,
} from '@thesisedu/ui'
import { Search } from '@thesisedu/ui/icons'
import React from 'react'

import { SiteTeacher } from './SiteTeacher'
import { TeacherSelectorProps } from './types'
import { useTeacherSelector } from './useTeacherSelector'

const CollapseWrapper = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const TeacherSelector: React.FC<React.PropsWithChildren<TeacherSelectorProps>> = props => {
  const { loading, decoratedSites, filter, setFilter } = useTeacherSelector(props)
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading teachers...'} />
  } else {
    let innerContent = <Empty description={'There are no other teachers available.'} />
    if (decoratedSites.length > 1) {
      innerContent = (
        <CollapseWrapper>
          <VSpaced>
            {decoratedSites.map(site => {
              return (
                <VSpaced space={'xs'} key={site.site.id}>
                  <HSpaced justify={'space-between'}>
                    <div>
                      <Text level={'l'}>{site.site.name}</Text>
                      <Text level={'s'} color={'secondary'}>
                        {Format.plural('teacher', site.teachers.length)}
                      </Text>
                    </div>
                    {site.selectAll && site.selectNone ? (
                      <Checkbox
                        checked={site.indeterminate ? 'indeterminate' : site.checked}
                        aria-label={`Select all / none for ${site.site.name}`}
                        onClick={e => e.stopPropagation()}
                        onChange={checked => {
                          if (checked) {
                            site.selectAll!()
                          } else {
                            site.selectNone!()
                          }
                        }}
                      />
                    ) : null}
                  </HSpaced>
                  {site.teachers.map(teacher => (
                    <SiteTeacher
                      key={teacher.id}
                      user={teacher.user}
                      onClick={teacher.onToggle}
                      rightContent={
                        <Checkbox
                          aria-label={`Checkbox for ${teacher.user.name ?? teacher.user.username}`}
                          checked={teacher.selected}
                        />
                      }
                    />
                  ))}
                </VSpaced>
              )
            })}
          </VSpaced>
        </CollapseWrapper>
      )
    } else if (decoratedSites.length === 1) {
      const site = decoratedSites[0]
      innerContent = (
        <>
          {site.teachers.map(teacher => (
            <SiteTeacher
              key={teacher.id}
              user={teacher.user}
              onClick={teacher.onToggle}
              rightContent={
                <Checkbox
                  aria-label={`Checkbox for ${teacher.user.name ?? teacher.user.username}`}
                  checked={teacher.selected}
                />
              }
            />
          ))}
        </>
      )
    }
    return (
      <div>
        <Block bottom={'s'}>
          <TextField
            aria-label={'Find a teacher or site...'}
            placeholder={'Find a teacher or site...'}
            suffix={<Search />}
            value={filter}
            onChange={e => setFilter(e)}
          />
        </Block>
        {innerContent}
      </div>
    )
  }
}
