export {
  colorToVariable as color,
  sizeToVariable as size,
  colorToThemePath,
  sizeToThemePath,
  ColorAlias,
  SizeAlias,
  AnySize,
} from './variables'
export { themePathToVariable as var } from './themePathToVariableName'
export { getThemeValue, setTheme } from './theme'
export * as SharedVariants from './sharedVariants'
export * from './ThemeProvider'
export { useForwardThemeContext } from './ThemeContext'

export * from './types'
export { createGlobalStyle, css, keyframes } from './styled'
export * from './variants'
export * as animations from './animations'
export * from './mergeTheme'
export * from './utils'
export * from './StyleContext'
export * from './useForegroundColor'

export { default as styled } from 'styled-components'
