import { Widgets, Segment } from '@thesisedu/feature-course-types'
import { VodThumbnail } from '@thesisedu/feature-video-on-demand-react'
import { Legacy } from '@thesisedu/feature-widgets-react'
import { styled, formatTime } from '@thesisedu/web'
import React from 'react'

import { SegmentGridSubtitle, SegmentGridTitle, ImageContainer } from './DefaultGridRenderer'
import { SegmentGridRendererResource, SegmentGridItemProps } from '../../explore/grid/types'

function isVideoWidget(widget: any): widget is Widgets.VideoWidget {
  return (widget as Widgets.VideoWidget)?.type === 'Video'
}

function getVodId(segment: Segment): string | null {
  for (const contentPiece of [
    Legacy.flatten(segment.config?.studentContent) || [],
    Legacy.flatten(segment.config?.teacherContent) || [],
  ]) {
    for (const widget of contentPiece) {
      const config = Legacy.getConfig(widget)
      if (isVideoWidget(widget) && config?.vodId) {
        return config.vodId
      }
    }
  }

  return null
}

function VideoGridRenderer({ segment, width }: SegmentGridItemProps) {
  const vodId = getVodId(segment)
  const [durationSeconds, setDurationSeconds] = React.useState<number | null>(null)
  if (!vodId) return null
  return (
    <>
      <ImageContainer width={width} aspectRatio={16 / 9}>
        <VodThumbnail
          vodId={vodId}
          onLoaded={vod => setDurationSeconds(vod.status.duration || null)}
        />
        {durationSeconds ? <Duration>{formatTime(durationSeconds.toString())}</Duration> : null}
      </ImageContainer>
      <SegmentGridTitle>{segment.name}</SegmentGridTitle>
      <SegmentGridSubtitle>{segment.config?.label || segment.type}</SegmentGridSubtitle>
    </>
  )
}
const Duration = styled.span`
  position: absolute;
  bottom: ${props => props.theme['@size-s']};
  right: ${props => props.theme['@size-s']};
  display: inline-block;
  background: ${props => props.theme['@gray-7']};
  border-radius: ${props => props.theme['@border-radius-base']};
  font-size: ${props => props.theme['@font-size-sm']};
  color: ${props => props.theme['@gray-0']};
  padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
`

const resource: SegmentGridRendererResource = {
  type: 'SegmentGridRenderer',
  identifier: 'VideoGridRenderer',
  height: ({ width }) => width * (9 / 16) + 125,
  weight: segment => (getVodId(segment) ? 0 : null),
  Component: VideoGridRenderer,
}
export default resource
