import { useFeatureRoot } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { Block, HSpaced, LoadingIndicator, Separator, Text, VSpaced, Well } from '@thesisedu/ui'
import React from 'react'

import { AddCategoryButton } from './AddCategoryButton'
import { CategoryManagementTable } from './CategoryManagementTable'
import { CategoryMapTable } from './CategoryMapTable'
import { hasDefaultCategories } from './hasDefaultCategories'
import { useClassAssignmentCategoriesQuery } from '../schema'

export interface CategoryManagementProps {
  classId: string
}
export function CategoryManagement({ classId }: CategoryManagementProps) {
  const [saving, setSaving] = React.useState(false)
  const { name } = useFeatureRoot().appOptions
  const { data, loading } = useClassAssignmentCategoriesQuery({
    variables: { classId },
  })
  const categories =
    data?.node?.__typename === 'Class'
      ? data.node.assignmentCategories.edges.map(edge => edge.node)
      : []
  const totalPoints = parseFloat(
    (categories.reduce((acc, category) => acc + category.gradeWeight, 0) * 100).toFixed(2),
  )
  const readOnly =
    data?.node?.__typename === 'Class' ? !data.node.canManageAssignmentCategories : true
  const categoryMap =
    data?.node?.__typename === 'Class' ? data.node.assignmentCategoriesMap : undefined
  const configuration = data?.node?.__typename === 'Class' ? data.node.configuration : undefined
  const shouldShowMapping = categoryMap ? !hasDefaultCategories({ categories, categoryMap }) : false
  return (
    <Well
      title={'Grading Categories'}
      actions={
        <HSpaced space={'xs'}>
          <LoadingIndicator.InlineLabeled size={'s'} label={'Saving...'} visible={saving} />
          <AddCategoryButton classId={classId} totalPoints={totalPoints} />
        </HSpaced>
      }
    >
      <VSpaced space={'m'}>
        <HSpaced align={'flex-end'}>
          <Text level={'s'} color={'orange'}>
            As you update these categories, your students' grades might change.
          </Text>
          <Block left>
            <Text level={'s'} color={'secondary'}>
              {Format.plural('point', totalPoints)} total
            </Text>
          </Block>
        </HSpaced>
        <CategoryManagementTable
          categories={categories}
          loading={loading}
          readOnly={readOnly}
          totalPoints={totalPoints}
          onSavingChange={setSaving}
        />
        {shouldShowMapping && categoryMap ? (
          <>
            <Separator />
            <div>
              <Text weight={'bold'} bottom>
                Grading Category Mappings
              </Text>
              <Text level={'s'} bottom color={'secondary'}>
                When you create or enable an assignment inside {name}, we suggest a grading category
                to use for that assignment. You can change which of your grading categories is used
                for each suggestion here.
              </Text>
              <Text level={'s'} bottom color={'secondary'}>
                For example, if you have our "Participation" grading category mapped to a custom
                "Non-grade" category, every time you enable an assignment that would have gone in
                the "Participation" category, it will instead go in the "Non-grade" category.
              </Text>
            </div>
            <CategoryMapTable
              categories={categories}
              categoryMap={categoryMap}
              onSavingChange={setSaving}
              classId={classId}
              classConfiguration={configuration}
            />
          </>
        ) : null}
      </VSpaced>
    </Well>
  )
}
