import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html'
import { LexicalEditor } from 'lexical'

export function nodesFromHtml(html: string, editor: LexicalEditor) {
  const parser = new DOMParser()
  const dom = parser.parseFromString(html, 'text/html')
  return $generateNodesFromDOM(editor, dom)
}

export function editorToHtml(editor: LexicalEditor): string {
  return $generateHtmlFromNodes(editor)
}
