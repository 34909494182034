import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { useFeature } from '@thesisedu/feature-react'
import {
  styled,
  H2,
  BodyLarge,
  Image,
  Markdown,
  StyledThemeContext,
  showParticles,
  sparkle,
  BodySmall,
  DATE_FORMATS,
} from '@thesisedu/web'
import moment from 'moment'
import React from 'react'
import { keyframes } from 'styled-components'

import { BadgesReactFeature } from './BadgesReactFeature'
import { BadgeInput } from './schema'
import { usePlaceholder } from './usePlaceholder'

export interface BadgeProps {
  badge: Pick<Partial<BadgeInput>, 'image' | 'label' | 'description' | 'awardedText'>
  noBorder?: boolean
  awarded?: boolean
  awardedAt?: string
}
export function Badge({ badge, noBorder, awarded, awardedAt }: BadgeProps) {
  const { soundEffects } = useFeature(BadgesReactFeature)
  const placeholder = usePlaceholder()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const imageInfo = badge.image ? getFileInfo(badge.image) : undefined
  const theme = React.useContext(StyledThemeContext)
  React.useEffect(() => {
    if (awarded) {
      const soundTimeout = setTimeout(() => {
        soundEffects.play('badges.earn')
      }, 500)
      // Wait for the modal to show...
      const timeout = setTimeout(() => {
        if (containerRef.current) {
          showParticles(containerRef.current, {
            color: theme['@orange'],
            duration: 3000,
            minSize: 15,
            maxSize: 60,
            radius: 225,
          })
        }
      }, 5000 + 500)
      let cancelSparkle = () => {}
      const sparkleTimeout = setTimeout(() => {
        if (containerRef.current) {
          cancelSparkle = sparkle(containerRef.current)
        }
      }, 5000 + 500)
      return () => {
        clearTimeout(timeout)
        clearTimeout(soundTimeout)
        clearTimeout(sparkleTimeout)
        cancelSparkle()
        soundEffects.stop('badges.earn')
      }
    }
  }, [])
  const classes = []
  if (noBorder) classes.push('no-border')
  if (awarded) classes.push('awarded')
  return (
    <Container className={classes.join(' ')}>
      <ImageContainer ref={containerRef}>
        {imageInfo?.filename ? (
          <>
            <BackgroundPlaceholder>{placeholder}</BackgroundPlaceholder>
            <Image src={imageInfo.filename} placeholder={placeholder} />
          </>
        ) : (
          placeholder
        )}
      </ImageContainer>
      <H2 isBlock color={'@text-color'}>
        {badge.label || 'Badge Label'}
      </H2>
      <BodyLarge color={'@text-color'}>
        <Markdown
          children={
            (awarded ? badge.awardedText : badge.description) ||
            `You earned the **Badge Label** treasure for doing Some Action 10 times!`
          }
        />
      </BodyLarge>
      {awardedAt ? (
        <BodySmall color={'@gray-3'}>
          Earned {moment(awardedAt).format(DATE_FORMATS.FULL)}
        </BodySmall>
      ) : null}
    </Container>
  )
}

const TextAnimation = keyframes`
  0% {
    filter: blur(6px);
    opacity: 0.75;
  }
  75% {
    filter: blur(6px);
    opacity: 0.75;
  }
  83% {
    filter: blur(0);
    opacity: 1;
  }
`
// Total time: 6s - thump at 4.5s - 5s
const BadgeEnterAnimation = keyframes`
  0% {
    filter: blur(7px) grayscale(100%);
    opacity: 0;
    transform: scale(0.75) translateY(40%);
  }
  25% {
    opacity: 1;
  }
  77% {
    filter: blur(7px) grayscale(25%);
    transform: scale(1.2) translateY(0);
  }
  81% {
    filter: blur(0) grayscale(0);
    transform: scale(0.8) translateY(0);
  }
  83% {
    transform: scale(1);
  }
`
const BackgroundPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const ImageContainer = styled.div`
  margin-bottom: ${props => props.theme['@size-l']};
  position: relative;
  > * {
    margin: 0 auto;
  }
  svg,
  img {
    height: 236px;
    width: auto;
    position: relative;
    path {
      fill: ${props => props.theme['@gray-2']};
    }
  }
`
const Container = styled.div`
  text-align: center;
  padding: ${props => props.theme['@padding-lg']};
  &:not(.no-border) {
    border-radius: ${props => props.theme['@border-radius-large']};
    box-shadow: ${props => props.theme['@shadow-large']};
    background: white;
  }
  &.awarded {
    ${ImageContainer} img {
      animation: ${BadgeEnterAnimation} 6s ease-out;
      animation-delay: 0.5s;
    }
    p,
    h2 {
      animation: ${TextAnimation} 6s linear;
      animation-delay: 0.5s;
    }
  }
`
