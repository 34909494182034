import { styled, s } from '@thesisedu/ui'

export const SPACE = 4
export const LINE_WIDTH = 16

export function startDrag(lineElement: HTMLElement) {
  lineElement.style.transition =
    'transform 0.25s cubic-bezier(0.16, 1, 0.3, 1), height 0.25s cubic-bezier(0.16, 1, 0.3, 1)'
}

export function stopDrag(lineElement: HTMLElement) {
  lineElement.style.transition = ''
}

export interface SetResizeLineOpts {
  active?: boolean
  getElement?: (element: HTMLElement) => HTMLElement | null | undefined
  alignment?: 'left' | 'right'
  offset?: number
}
export function setResizeLine(
  lineElement: HTMLElement | undefined | null,
  _hoverElement: HTMLElement | undefined | null,
  anchorElement: HTMLElement | undefined | null,
  { active, getElement, offset = 0, alignment = 'right' }: SetResizeLineOpts = {},
) {
  const hoverElement = getElement && _hoverElement ? getElement(_hoverElement) : _hoverElement
  // When removing widgets, these can sometimes be null or undefined if we can't find the
  // element anymore.
  if (!hoverElement || !anchorElement || !lineElement) return
  const hoverStyle = window.getComputedStyle(hoverElement)
  const {
    top: hoverElementTop,
    height: hoverElementHeight,
    width: hoverElementWidth,
    left: hoverElementLeft,
  } = hoverElement.getBoundingClientRect()
  const { left: anchorLeft, top: anchorTop } = anchorElement.getBoundingClientRect()

  let lineLeft
  if (alignment === 'left') {
    lineLeft = hoverElementLeft - parseFloat(hoverStyle.marginLeft) - offset
  } else {
    lineLeft = hoverElementLeft + hoverElementWidth + parseFloat(hoverStyle.marginRight) + offset
  }
  const top = hoverElementTop - SPACE - anchorTop
  const left = lineLeft - anchorLeft - LINE_WIDTH

  lineElement.style.transform = `translate(${left}px, ${top}px)`
  lineElement.style.height = `${hoverElementHeight + SPACE * 2}px`
  lineElement.style.opacity = '.4'
  if (active) {
    lineElement.classList.add('active')
  } else {
    lineElement.classList.remove('active')
  }
}

export function hideResizeLine(lineElement: HTMLElement | null) {
  if (lineElement) {
    lineElement.style.opacity = '0'
    lineElement.style.transform = 'translate(-10000px, -10000px)'
  }
}

export const ResizeLine = styled.div`
  width: ${LINE_WIDTH}px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.1s linear;
  will-change: transform;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: col-resize;
  z-index: ${s.var('zIndices.overlays')};
  &:hover {
    opacity: 1;
  }
  > div {
    width: 4px;
    height: 100%;
    transition: background 0.1s linear;
    background: ${s.color('gray.secondary')};
    border-radius: 2px;
  }
  &:active > div,
  &.active > div {
    background: ${s.color('blue.solid')};
  }
  &.active {
    pointer-events: none !important;
  }
`
