import { TextField, VSpaced, styled } from '@thesisedu/ui'
import { Search } from '@thesisedu/ui/icons'
import React from 'react'

import { CustomSegmentList } from '../../custom_content/CustomSegmentList'
import { FilterOperation } from '../../schema'
import { SegmentUserFilter } from '../../segment/SegmentFilters'
import { MyContentDraggable } from '../MyContentDraggable'
import { MyContentListWrapper } from '../MyContentListWrapper'
import { PlacementPaneImperativeHandle } from '../types'

function _MyContentPane(_: object, ref: React.ForwardedRef<PlacementPaneImperativeHandle>) {
  const [search, setSearch] = React.useState<string | undefined>(undefined)
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useImperativeHandle(
    ref,
    () => {
      return {
        highlightSegment(segmentId) {},
        onDragEnd(opts) {
          // Do nothing...?
        },
        getSortableId() {
          return 'my-content'
        },
      }
    },
    [],
  )
  return (
    <VSpaced style={{ flex: 1 }}>
      <TextField
        suffix={<Search />}
        placeholder={'Search My Content...'}
        value={search}
        onChange={setSearch}
      />
      <Container ref={containerRef}>
        <CustomSegmentList
          filters={{
            tags: [],
            tagsOp: FilterOperation.And,
            name: search,
            user: SegmentUserFilter.Me,
          }}
          hideCreate
          renderSegment={MyContentDraggable}
          wrapItems={MyContentListWrapper}
          infiniteScrollerProps={{
            scrollableTarget: () => containerRef.current,
          }}
        />
      </Container>
    </VSpaced>
  )
}
export const MyContentPane = React.forwardRef(_MyContentPane)

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
`
