import { ArrowRightOutlined } from '@ant-design/icons'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Button, List } from 'antd'
import { set } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { SegmentFilterValues, SegmentUserFilter } from './SegmentFilters'
import { SegmentListItem } from './SegmentListItem'
import { SegmentPreview } from './SegmentPreview'
import {
  ListSegmentsDocument,
  ListSegmentsQuery,
  ListSegmentsQueryVariables,
  SegmentFragment,
} from '../schema'

export interface SegmentPreviewListProps {
  filters: SegmentFilterValues
  onSegmentClick?: (segment: SegmentFragment) => any
  blacklistIds?: string[]
}
export function SegmentPreviewList({
  filters,
  onSegmentClick,
  blacklistIds,
}: SegmentPreviewListProps) {
  const viewer = useViewerContext(true)
  const [dFilters] = useDebounce(filters, 500)
  const [variables, setVariables] = useState<ListSegmentsQueryVariables>({})
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setVariables({
      userIds: dFilters.user === SegmentUserFilter.Me ? [viewer.id] : [],
      types: dFilters.type ? [dFilters.type] : [],
      name: dFilters.name,
      tags: dFilters.tags,
      tagsOp: dFilters.tagsOp,
      hiddenFromTeachers: dFilters.hiddenFromTeachers,
    })
  }, [dFilters, viewer])
  const [previewSegmentId, setPreviewSegmentId] = useState<string | undefined>()

  return (
    <div style={{ maxHeight: '75vh', overflowY: 'auto' }} ref={containerRef}>
      <SegmentPreview
        segmentDatabaseId={previewSegmentId}
        onClose={() => setPreviewSegmentId(undefined)}
        actions={
          onSegmentClick
            ? segment => (
                <Button
                  type={'primary'}
                  icon={<ArrowRightOutlined />}
                  onClick={() => onSegmentClick(segment)}
                >
                  Select
                </Button>
              )
            : undefined
        }
      />
      <InfiniteQuery<SegmentFragment, ListSegmentsQuery, ListSegmentsQueryVariables>
        document={ListSegmentsDocument}
        getResult={d => d?.segments}
        setResult={(previous, result) => set(previous, 'segments', result)}
        children={({ data }) => (
          <List itemLayout={'vertical'}>
            {data?.segments.edges.map(edge => {
              const item = edge.node
              if (!blacklistIds || !blacklistIds.includes(item.id)) {
                return (
                  <SegmentListItem
                    key={item.id}
                    segment={item}
                    onClick={() => {
                      setPreviewSegmentId(item.id)
                    }}
                    onAct={() => {
                      if (onSegmentClick) {
                        onSegmentClick(item)
                      }
                    }}
                  />
                )
              } else {
                return <div />
              }
            })}
          </List>
        )}
        variables={{
          ...variables,
          first: 32,
        }}
      />
    </div>
  )
}
