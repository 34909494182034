import { DocumentNode } from '@apollo/client'
import { FeatureResource } from '@thesisedu/feature'
import React from 'react'

import {
  AllMetricSummariesFragment,
  ReportResultItemFragment,
  RunReportQuery,
} from './execute/types'
import { ReportDisplayMode } from './result/ReportActions'
import { GraphConfiguration } from './result/graph/GraphConfig'
import { ReportMetricInput } from '../schema'

export interface ReportDimensionInput {
  [key: string]: object | undefined
}
export interface ReportDimensionOptionsProps<
  Options,
  Context extends ReportDimensionContext = ReportDimensionContext,
> {
  value?: Options
  context?: Context
  onConfirm: (value: Options, context: Context, overrideIdentifier?: string) => void
  children: React.ReactElement<{ onClick?: () => void; children?: any }>
  isInDropdown?: boolean
  [key: string]: any
}
export interface ReportDimensionOptionsLabelProps<
  Options,
  Context extends ReportDimensionContext = ReportDimensionContext,
> {
  value?: Options
  context?: Context
}

export interface ReportDimensionTableHeaderProps<DimensionResult> {
  result: DimensionResult
  type: 'column' | 'row'
  depth: number | undefined
}

export interface ReportDimensionContext {}
export interface ReportDimensionGraphOptions<Result, Context, RawSummaryItem = undefined> {
  useContext: () => Context
  label: (result: Result, context: Context, summaryItem?: RawSummaryItem) => string | null
  useHorizontalGraph?: boolean
}
export const inferGraphContext = <Result, Context, RawSummaryItem = undefined>(
  generic: ReportDimensionGraphOptions<Result, Context, RawSummaryItem>,
) => generic

export interface ReportDimensionResource<
  Options,
  DimensionResult,
  Context extends ReportDimensionContext = ReportDimensionContext,
  RawSummaryItem = any,
  DimensionInput = Options,
  SummaryInput = Options,
> extends FeatureResource {
  type: 'ReportDimension'
  identifier: string
  /** The key for this dimension's input configuration. */
  inputKey: string
  /** The 0-based indexes of dimensions this dimension is supported in. */
  supportedIndices: number[]
  /** The icon for the dimension. */
  icon: React.ReactElement
  /** The title of the dimension. */
  title: string
  /** The singular version of the title. */
  singular: string
  /** A short one-sentence explanation of the dimension. */
  description: string
  /** Gets a short summary of the currently selected values. */
  shortLabel?: (options: Options, context: Context) => string
  /** Gets a title summary of the currently selected values. */
  titleLabel?: (options: Options, context: Context) => string
  /** Graph-specific dimension options. */
  graph: ReportDimensionGraphOptions<DimensionResult, any, RawSummaryItem>
  /** The fragment representing the dimension value. */
  fragment: DocumentNode
  /**
   * If a summary is required for this dimension instead of displaying values, configure
   * it here.
   */
  summary?: ReportDimensionSummaryOptions<RawSummaryItem, Options, Context, SummaryInput>
  /**
   * Whether or not the dimension has multiple values returned.
   * This controls whether or not we display all metrics in place of this dimension in the results.
   */
  hasMultipleValues: boolean
  /** If you want to override the column width when this dimension is displayed as columns. */
  columnWidth?: number
  /** Optional function to override the dimension input. */
  getInput?: (options: Options, context: Context) => DimensionInput
  /** Renderer for an options button to configure additional options. */
  renderOptionsLabel?: (
    props: ReportDimensionOptionsLabelProps<Options, Context>,
  ) => React.ReactElement
  /** Renderer for a wrapper around an options button to configure additional options. */
  renderOptionsWrapper?: (
    props: ReportDimensionOptionsProps<Options, Context>,
  ) => React.ReactElement
  /** Renderer for the table header inside the results. */
  renderTableHeader?: (
    props: ReportDimensionTableHeaderProps<DimensionResult>,
  ) => React.ReactElement | null
  /** A hook for getting the export header. */
  useGetExportHeader?: () => GetExportHeaderFn<DimensionResult>
}
export type GetExportHeaderFn<DimensionResult> = (
  props: ReportDimensionTableHeaderProps<DimensionResult>,
) => string

export type CustomMetricFormat = (value: number) => string
export type MetricFormat = 'percent' | 'number' | CustomMetricFormat
export interface ReportMetricResource extends FeatureResource {
  type: 'ReportMetric'
  identifier: string
  /** The key of the metric inside the results. */
  metricKey: string
  /** The summarization strategy for the metric. */
  summarization: keyof AllMetricSummariesFragment | string
  /** The short label for the metric. For a grade metric, this would be "grades" */
  shortLabel: string
  /** The icon for this metric. */
  icon: React.ReactElement
  /** The title of the metric. */
  title: string
  /** A one-sentence description of what this metric reports. */
  description: string
  /** Either a standard format or a custom metric formatter. */
  format: MetricFormat
  /** A shorter version of the format. */
  shortFormat?: MetricFormat
  /** Gets the onClick handler for a result cell. */
  getOnClick?: (item: ReportResultItemFragment) => OnClick | null
  /** The maximum value for this metric when displayed inside a graph. */
  graphAxisMax?: number
}
type OnClick = () => void | Promise<void>

/**
 * Custom summarization strategy fields, for metrics. These are similar to
 * sum, average, etc. but are custom to supported metrics. These are automatically
 * added to the GraphQL query to fetch whenever loading reports.
 */
export interface ReportMetricSummaryResource extends FeatureResource {
  type: 'ReportMetricSummary'
  /** This should match the field coming back down from GraphQL. */
  identifier: string
  /** The human label of the summarization strategy. */
  label: string
  /** The identifiers for the metrics this summary supports. */
  associatedMetrics: string[]
}

export interface ReportDimensionSummaryRow<RawSummaryItem = any> {
  id: string
  results: ReportResultItemFragment[]
  summaries: AllMetricSummariesFragment
  item: RawSummaryItem
  children?: ReportDimensionSummaryRow<RawSummaryItem>[]
}
export interface FlatReportDimensionSummaryRow<RawSummaryItem = any>
  extends ReportDimensionSummaryRow<RawSummaryItem> {
  depth: number
}

export interface ReportDimensionSummaryOptions<
  RawSummaryItem,
  Options,
  Context,
  Input = undefined,
> {
  /** Gets the query piece for the summary. Similar to a fragment, but not a fragment. */
  getQueryPiece: (
    reportDimensionResult: string,
    reportMetricSummaries: string,
    allMetricSummaries: string,
  ) => string
  /** The key inside the GraphQL query of the summary. */
  summariesKey: string
  /** The fragments used inside getQueryPiece above. */
  queryPieceFragments?: DocumentNode[]
  /** Gets the input to pass to the summary field. */
  getInput?: (options: Options, context: Context) => Input
  /** Gets the tree of rows for the report data. */
  getRows: (data: RunReportQuery['runReport']) => ReportDimensionSummaryRow<RawSummaryItem>[]
  /** Renders the summary header. */
  renderTableHeader?: (
    props: ReportDimensionTableHeaderProps<RawSummaryItem>,
  ) => React.ReactElement | null
  /** A hook for getting the export header. */
  useGetExportHeader?: () => GetExportHeaderFn<RawSummaryItem>
}

export interface SelectedDimension<Context extends object = object> {
  /** The identifier of the dimension resource. */
  identifier: string
  /** The options for the dimension. */
  options: object
  /** Any additional context for the selection. */
  context: Context
}

export interface ReportFilterInput {
  [identifier: string]: any
}
export interface RunReportOpts {
  metrics: ReportMetricInput[]
  dimensions: SelectedDimension[]
  filters?: ReportFilterInput[]
  graphOptions?: GraphConfiguration
  displayMode?: ReportDisplayMode
}

export interface ReportFilterProps<FilterValue> {
  hasValue?: boolean
  value?: FilterValue
  onChange?: (value?: FilterValue) => void
}
export interface ReportFilterShouldShowOpts {
  opts: RunReportOpts
}
export interface ReportFilterResource<FilterValue> extends FeatureResource {
  type: 'ReportFilter'
  /** The key of the filter inside the GraphQL query. */
  identifier: string
  /** Returns false if the filter should not be visible. */
  shouldShow?: (opts: ReportFilterShouldShowOpts) => boolean
  render: (props: ReportFilterProps<FilterValue>) => React.ReactElement
}

export * from './execute/types'
export * from './execute/extensibleTypes'
