/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from './apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type BackgroundJob = {
  __typename?: 'BackgroundJob';
  errorDetails?: Maybe<ErrorDetails>;
  id: Scalars['ID']['output'];
  payload?: Maybe<Scalars['JSON']['output']>;
  progress?: Maybe<BackgroundJobProgress>;
  resultPayload?: Maybe<Scalars['JSON']['output']>;
  status: BackgroundJobStatus;
  type: Scalars['String']['output'];
};

export type BackgroundJobProgress = {
  __typename?: 'BackgroundJobProgress';
  current: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type ConfirmationInput = {};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type DeleteNodeResponse = {
  __typename?: 'DeleteNodeResponse';
  id: Scalars['ID']['output'];
  numDeleted: Scalars['Int']['output'];
};

export type DetailedLog = {
  __typename?: 'DetailedLog';
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
  sizeInBytes: Scalars['Float']['output'];
  tags: Array<Scalars['String']['output']>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type ErrorDetails = {
  __typename?: 'ErrorDetails';
  code: Scalars['String']['output'];
  extensions?: Maybe<Scalars['JSON']['output']>;
  message: Scalars['String']['output'];
};

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type Mutation = {
  __typename?: 'Mutation';
  deleteNode: DeleteNodeResponse;
  restoreNode: RestoreNodeResponse;
};


export type MutationDeleteNodeArgs = {
  id: Scalars['ID']['input'];
  permanent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRestoreNodeArgs = {
  id: Scalars['ID']['input'];
};

export type Node = {
  id: Scalars['ID']['output'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  node?: Maybe<Node>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RestoreNodeResponse = {
  __typename?: 'RestoreNodeResponse';
  id: Scalars['ID']['output'];
  numRestored: Scalars['Int']['output'];
};

export type SignedFetchResult = {
  __typename?: 'SignedFetchResult';
  bucket: Scalars['String']['output'];
  isCacheable?: Maybe<Scalars['Boolean']['output']>;
  path: Scalars['String']['output'];
  requireHeaders?: Maybe<Scalars['JSON']['output']>;
  signedUrl: Scalars['String']['output'];
};

export type SignedUploadInput = {
  extension: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type SignedUploadResult = {
  __typename?: 'SignedUploadResult';
  data?: Maybe<Scalars['JSON']['output']>;
  extension: Scalars['String']['output'];
  path: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** @deprecated This is a placeholder field. */
  node?: Maybe<Node>;
};


export type SubscriptionNodeArgs = {
  id: Scalars['ID']['input'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean']['output'];
};

export enum UploadRequestMode {
  Basic = 'BASIC',
  FormData = 'FORM_DATA'
}

export type BackgroundJobFragment_BackgroundJob_errorDetails_ErrorDetails = (
  {
  code: string,
  message: string,
  extensions?: any | null
}
  & {
  __typename?: 'ErrorDetails'
}
);

export type BackgroundJobFragment_BackgroundJob_progress_BackgroundJobProgress = (
  {
  current: number,
  total: number
}
  & {
  __typename?: 'BackgroundJobProgress'
}
);

export type BackgroundJobFragment = (
  {
  id: string,
  type: string,
  status: BackgroundJobStatus,
  payload?: any | null,
  resultPayload?: any | null,
  errorDetails?: BackgroundJobFragment_BackgroundJob_errorDetails_ErrorDetails | null,
  progress?: BackgroundJobFragment_BackgroundJob_progress_BackgroundJobProgress | null
}
  & {
  __typename?: 'BackgroundJob'
}
);

export type DeleteNodeMutation_deleteNode_DeleteNodeResponse = (
  {
  id: string,
  numDeleted: number
}
  & {
  __typename?: 'DeleteNodeResponse'
}
);

export type DeleteNodeMutation_Mutation = (
  {
  deleteNode: DeleteNodeMutation_deleteNode_DeleteNodeResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type DeleteNodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteNodeMutation = DeleteNodeMutation_Mutation;

export type RestoreNodeMutation_restoreNode_RestoreNodeResponse = (
  {
  id: string,
  numRestored: number
}
  & {
  __typename?: 'RestoreNodeResponse'
}
);

export type RestoreNodeMutation_Mutation = (
  {
  restoreNode: RestoreNodeMutation_restoreNode_RestoreNodeResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type RestoreNodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RestoreNodeMutation = RestoreNodeMutation_Mutation;

export const BackgroundJobFragmentDoc = gql`
    fragment BackgroundJob on BackgroundJob {
  id
  type
  status
  payload
  resultPayload
  errorDetails {
    code
    message
    extensions
  }
  progress {
    current
    total
  }
}
    `;
export const DeleteNodeDocument = gql`
    mutation deleteNode($id: ID!) {
  deleteNode(id: $id) {
    id
    numDeleted
  }
}
    `;
export type DeleteNodeMutationFn = ApolloReactCommon.MutationFunction<DeleteNodeMutation, DeleteNodeMutationVariables>;

/**
 * __useDeleteNodeMutation__
 *
 * To run a mutation, you first call `useDeleteNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNodeMutation, { data, loading, error }] = useDeleteNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNodeMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<DeleteNodeMutation, DeleteNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<DeleteNodeMutation, DeleteNodeMutationVariables>(DeleteNodeDocument, options);
      }
export type DeleteNodeMutationHookResult = ReturnType<typeof useDeleteNodeMutation>;
export type DeleteNodeMutationResult = ApolloReactCommon.MutationResult<DeleteNodeMutation>;
export type DeleteNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNodeMutation, DeleteNodeMutationVariables>;
export const RestoreNodeDocument = gql`
    mutation restoreNode($id: ID!) {
  restoreNode(id: $id) {
    id
    numRestored
  }
}
    `;
export type RestoreNodeMutationFn = ApolloReactCommon.MutationFunction<RestoreNodeMutation, RestoreNodeMutationVariables>;

/**
 * __useRestoreNodeMutation__
 *
 * To run a mutation, you first call `useRestoreNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreNodeMutation, { data, loading, error }] = useRestoreNodeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestoreNodeMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<RestoreNodeMutation, RestoreNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<RestoreNodeMutation, RestoreNodeMutationVariables>(RestoreNodeDocument, options);
      }
export type RestoreNodeMutationHookResult = ReturnType<typeof useRestoreNodeMutation>;
export type RestoreNodeMutationResult = ApolloReactCommon.MutationResult<RestoreNodeMutation>;
export type RestoreNodeMutationOptions = ApolloReactCommon.BaseMutationOptions<RestoreNodeMutation, RestoreNodeMutationVariables>;