import {
  useSubmissionData,
  PerformanceRecordField,
  QuestionProps,
} from '@thesisedu/feature-assignments-react'
import { Form } from '@thesisedu/react'
import React from 'react'

import { SheetMusicPerformanceConfig } from './types'
import { SheetMusicPlugin } from '../../../media/SheetMusicPlugin'

export function SheetMusicPerformanceContent(props: QuestionProps<SheetMusicPerformanceConfig>) {
  return (
    <Form.Item
      name={props.question.id}
      required={'This question is required.'}
      initialValue={useSubmissionData(props.question.id)}
    >
      <PerformanceRecordField
        name={props.question.name}
        disabled={props.disabled}
        label={`Answer for '${props.question.name}'`}
      >
        <SheetMusicPlugin abc={''} {...props.question.config?.viewerProps} />
      </PerformanceRecordField>
    </Form.Item>
  )
}
