import { useFeatureRoot, useResource } from '@thesisedu/feature-react'
import React from 'react'

import { MutationConfirmationProps, MutationConfirmationResource } from './types'
import { ApolloReactHooks, ExpectedErrorContext, ExpectedErrorPayload } from '../types'

export function ConfirmationHandler() {
  const root = useFeatureRoot()
  const onConfirmRef = React.useRef<MutationConfirmationProps<never>['onConfirm'] | null>(null)
  const onCancelRef = React.useRef<MutationConfirmationProps<never>['onCancel'] | null>(null)
  const handlers = useResource<MutationConfirmationResource<never>>('MutationConfirmation')
  const [state, setState] = React.useState<{
    payload: ExpectedErrorPayload
    context: ExpectedErrorContext
  } | null>(null)

  React.useEffect(() => {
    root.deps.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
      ApolloReactHooks.ExpectedError,
      (err, context) => {
        const handler = handlers.find(handler => handler.identifier === context.code)
        const operation = context.operation
        if (handler && operation) {
          return new Promise(resolve => {
            setState({
              context,
              payload: err,
            })
            onConfirmRef.current = async payload => {
              if (!operation.variables.input) {
                throw new Error(
                  'ConfirmationHandler attempted to retry, but input variable is not present.',
                )
              }
              operation.variables.input = {
                ...operation.variables.input,
                confirm: {
                  ...operation.variables.input.confirm,
                  [handler.input]: payload,
                },
              }
              err.shouldRetry = true
              setState(null)
              resolve(err)
            }
            onCancelRef.current = () => {
              setState(null)
              resolve(err)
            }
          })
        } else return err
      },
    )
  }, [])

  if (state) {
    const handler = handlers.find(handler => handler.identifier === state.context.code)
    if (handler) {
      if (!onConfirmRef.current || !onCancelRef.current)
        throw new Error('Confirmation refs are not set.')
      return (
        <handler.render
          error={state.payload}
          {...state.context}
          onConfirm={onConfirmRef.current}
          onCancel={onCancelRef.current}
        />
      )
    } else {
      throw new Error('Cannot find confirmation handler.')
    }
  } else return null
}
