import { Dropdown, HSpaced, Text } from '@thesisedu/ui'
import { Packages } from '@thesisedu/ui/icons'
import React from 'react'

import { DimensionOptionsMenuItem } from './DimensionOptionsMenuItem'
import { useDimensionsAtLevel } from './resources'
import { getSelectedResources } from '../execute/input'
import { SelectedDimension } from '../types'

export interface DimensionDropdownButtonProps {
  allowNone?: boolean
  dimension?: SelectedDimension
  index: number
  onChange: (value?: SelectedDimension) => void
}
export function DimensionDropdownButton({
  allowNone,
  dimension,
  index,
  onChange,
}: DimensionDropdownButtonProps) {
  const dimensions = useDimensionsAtLevel(index)
  const selectedDimensionResource = dimension
    ? getSelectedResources(dimensions, [dimension])[0]
    : undefined
  const icon = selectedDimensionResource ? selectedDimensionResource.icon : null
  const LabelComponent = selectedDimensionResource?.renderOptionsLabel

  return (
    <Dropdown.Container>
      <Dropdown.Button icon={icon ?? <Packages />}>
        <HSpaced>
          <span>{selectedDimensionResource?.title ?? 'In General'}</span>
          {LabelComponent && dimension ? (
            <Text as={'div'} level={'s'} color={'secondary'}>
              <LabelComponent context={dimension.context} value={dimension.options} />
            </Text>
          ) : null}
        </HSpaced>
      </Dropdown.Button>
      <Dropdown.Menu style={{ width: 400 }}>
        {dimensions.map(dim => {
          if (dim.renderOptionsLabel && dim.renderOptionsWrapper) {
            const selected = dimension?.identifier === dim.identifier ? dimension : undefined
            return (
              <DimensionOptionsMenuItem
                key={dim.identifier}
                dimension={dim}
                options={selected?.options}
                context={selected?.context}
                onChange={(options, context, identifier) => {
                  onChange({
                    identifier: identifier || dim.identifier,
                    options,
                    context,
                  })
                }}
              />
            )
          } else {
            return (
              <Dropdown.Item.Checkbox
                key={dim.identifier}
                checked={dimension?.identifier === dim.identifier}
                onCheckedChange={checked => {
                  if (checked) {
                    onChange({ identifier: dim.identifier, options: {}, context: {} })
                  }
                }}
              >
                <HSpaced space={'xs'}>
                  <span>{dim.icon}</span>
                  <span>{dim.title}</span>
                </HSpaced>
              </Dropdown.Item.Checkbox>
            )
          }
        })}
        {allowNone ? (
          <>
            <Dropdown.Item.Separator />
            <Dropdown.Item.Checkbox
              onCheckedChange={checked => {
                if (checked) onChange(undefined)
              }}
            >
              <HSpaced space={'xs'}>
                <span>
                  <Packages />
                </span>
                <span>In General</span>
              </HSpaced>
              <Dropdown.Item.Right>Compare all metrics</Dropdown.Item.Right>
            </Dropdown.Item.Checkbox>
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
