import { addFragmentReference, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { ReactFeature, ReactUse, ReactFeatureDependencies } from '@thesisedu/feature-react'
import { addSearch } from '@thesisedu/feature-search-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import { FragmentDefinitionNode } from 'graphql'
import React from 'react'

import { addBadgeEvents } from './resources/badges'
import { InteractionsResultWithBadgesFragmentDoc, BadgeFragmentDoc } from './schema'

export interface SoundEffects {
  play: (code: string) => void
  stop: (code: string) => void
}
export interface BadgesReactFeatureOptions {
  badgePlaceholderSvg?: React.ReactElement
  getSoundEffects: () => SoundEffects
}

export class BadgesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-badges-react'
  static path = ''
  static requires: string[] = []
  public options!: BadgesReactFeatureOptions
  public placeholder!: React.ReactElement
  public soundEffects!: SoundEffects

  constructor(opts: BadgesReactFeatureOptions, deps: ReactFeatureDependencies) {
    super(opts, deps)
    if (!this.root.getFeature('@thesisedu/feature-interactions-react')) {
      throw new Error('feature-badges-react must be added AFTER feature-interactions-react')
    }
  }

  public reactResources() {
    // Get the sound effects...
    this.soundEffects = this.options.getSoundEffects()

    // Configure the default options...
    const { DefaultBadgePlaceholder } = require('./DefaultBadgePlaceholder')
    this.placeholder = this.options.badgePlaceholderSvg || <DefaultBadgePlaceholder />

    // Add support for our own events...
    addBadgeEvents(this)

    // Add support for search.
    const { SearchResult } = require('./admin/SearchResult')
    addSearch(this, '...on Badge { badgeName: name, badgeDescription: description }', 'Badge', {
      renderList: SearchResult,
    })

    // Add the modification to the interactions fragment.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'RecordInteractionsPayload', (fragment, context) => {
      const result = addFragmentReference(
        fragment,
        InteractionsResultWithBadgesFragmentDoc.definitions[0] as FragmentDefinitionNode,
        context,
      )
      // The fragment above references yet another fragment, the Badge fragment...
      context.additionalDefinitions.push(BadgeFragmentDoc.definitions[0] as FragmentDefinitionNode)
      return result
    })

    // Wrap the app with the interaction listener...
    this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, async children => {
      const { BadgeNotificationListener } = require('./BadgeNotificationListener')
      return (
        <>
          <BadgeNotificationListener />
          {children}
        </>
      )
    })

    require('./hooks/routes').default(this)
  }
}

export const badgesReactFeature: ReactUse<BadgesReactFeatureOptions> = (
  opts: BadgesReactFeatureOptions,
) => [BadgesReactFeature, opts]
