import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import { MatchAnswer, MatchOption } from '@thesisedu/feature-assignments-core'
import { Block, BodySmall } from '@thesisedu/react'
import { Body, StyledThemeContext } from '@thesisedu/web'
import classnames from 'classnames'
import React from 'react'

import { AnswerIconContainer } from './Answer'
import {
  Destination,
  DestinationHeader,
  DestinationText,
  DestinationFooter,
  DestinationContainer,
  Source,
} from './DragDropField'
import { QuestionInstructions } from './QuestionInstructions'
import { QuestionTextMediaView } from '../../questions/QuestionTextMediaField'

export interface DragDropAnswerViewProps {
  selectedAnswers?: MatchAnswer[]
  destinations: string[]
  answer?: MatchOption[]
  allowMultipleInDestinations?: boolean
  hideCorrectAnswers?: boolean
}
export function DragDropAnswerView({
  selectedAnswers,
  destinations: _destinations,
  answer,
  allowMultipleInDestinations,
  hideCorrectAnswers,
}: DragDropAnswerViewProps) {
  const theme = React.useContext(StyledThemeContext)
  const hasAnswer = selectedAnswers != null
  // We want the unsorted destinations if they are given to us.
  let destinations = answer ? answer.map(a => a.destination) : _destinations

  // Only show unique destinations if multiple are allowed.
  if (allowMultipleInDestinations) {
    destinations = [...new Set(destinations)]
  }

  return (
    <QuestionInstructions instructions={'Drag and drop to create matches below.'}>
      <DestinationContainer style={{ width: '100%', paddingLeft: theme['@size-s'] }}>
        {destinations.map(destination => {
          const answerOptions = answer?.filter(opt => opt.destination === destination) || []
          const selectedOptions = selectedAnswers?.filter(v => v.destination === destination)
          const selectedSingleAnswer =
            !allowMultipleInDestinations && selectedOptions?.length === 1
              ? selectedOptions[0]
              : undefined
          const isCorrect =
            selectedSingleAnswer && selectedSingleAnswer.source === answerOptions[0]?.source
          return (
            <Destination
              key={destination}
              className={classnames({
                hasValue: true,
                correct: answer && selectedSingleAnswer && isCorrect,
                incorrect: answer && selectedSingleAnswer && !isCorrect,
                multiple: allowMultipleInDestinations,
              })}
            >
              {selectedSingleAnswer && answer ? (
                <AnswerIconContainer>
                  {isCorrect ? <CheckCircleFilled /> : <CloseCircleFilled />}
                </AnswerIconContainer>
              ) : null}
              <DestinationHeader>
                <div>
                  <Body useDiv>
                    <DestinationText
                      destination={destination}
                      value={
                        hasAnswer
                          ? selectedSingleAnswer?.source
                          : answerOptions.length === 1
                          ? answerOptions[0].source
                          : undefined
                      }
                    />
                  </Body>
                  {answerOptions.length && !hideCorrectAnswers ? (
                    <Block marginTop={'@size-xs'}>
                      <BodySmall color={'@green'}>
                        <strong>Correct answer{answerOptions.length === 1 ? '' : 's'}:</strong>{' '}
                        {answerOptions.map(opt => opt.source).join(', ')}
                      </BodySmall>
                    </Block>
                  ) : null}
                </div>
              </DestinationHeader>
              <DestinationFooter>
                {(hasAnswer ? selectedOptions ?? [] : answerOptions).map(opt => {
                  const isCorrect = answerOptions.some(fo => fo.source === opt.source)
                  return (
                    <Source
                      style={{ pointerEvents: 'none' }}
                      key={opt.source}
                      className={classnames({
                        correct:
                          answer && allowMultipleInDestinations && selectedOptions && isCorrect,
                        incorrect:
                          answer && allowMultipleInDestinations && selectedOptions && !isCorrect,
                      })}
                    >
                      {allowMultipleInDestinations && selectedOptions && answer ? (
                        <AnswerIconContainer>
                          {isCorrect ? <CheckCircleFilled /> : <CloseCircleFilled />}
                        </AnswerIconContainer>
                      ) : null}
                      <Body useDiv>
                        <QuestionTextMediaView value={opt.source} />
                      </Body>
                    </Source>
                  )
                })}
              </DestinationFooter>
            </Destination>
          )
        })}
      </DestinationContainer>
    </QuestionInstructions>
  )
}
