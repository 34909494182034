import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { SettingsFormValues } from './types'

export interface AssignmentSettingsContextValue {
  form: Form$.FormInstance<SettingsFormValues>
}
export const AssignmentSettingsContext = React.createContext<
  AssignmentSettingsContextValue | undefined
>(undefined)

export function useAssignmentSettingsContext(): AssignmentSettingsContextValue | undefined
export function useAssignmentSettingsContext(
  require: false,
): AssignmentSettingsContextValue | undefined
export function useAssignmentSettingsContext(require: true): AssignmentSettingsContextValue
export function useAssignmentSettingsContext(
  require?: boolean,
): AssignmentSettingsContextValue | undefined {
  const context = React.useContext(AssignmentSettingsContext)
  if (!context && require) {
    throw new Error('AssignmentSettingsContext is required, yet not provided.')
  }
  return context
}
