import { LicenseCourse } from '@thesisedu/feature-course-types'
import { Modal, Button, Tooltip, AntIconWrapper, Form } from '@thesisedu/react'
import { Settings } from '@thesisedu/react/icons'
import { Select } from 'antd'
import React from 'react'

import { LicenseCourseCheckboxProps } from './LicenseCourseCheckbox'

export interface LicenseCourseSettingsProps extends Omit<LicenseCourseCheckboxProps, 'course'> {}
export function LicenseCourseSettings({ allLabels, onChange, value }: LicenseCourseSettingsProps) {
  const [visible, setVisible] = React.useState(false)
  const form = Form.useForm<LicenseCourse>()
  React.useEffect(() => {
    if (!value?.enabled) setVisible(false)
  }, [value])
  React.useEffect(() => {
    if (visible) {
      form.reset(value || {})
    }
  }, [visible])
  if (value?.enabled) {
    return (
      <>
        <Modal
          title={'Course Settings'}
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key={'cancel'} onClick={() => setVisible(false)}>
              Cancel
            </Button>,
            <Form.StandaloneSubmit
              key={'submit'}
              form={form}
              disableUntilDirty
              onFinish={values => {
                if (onChange) {
                  onChange(values)
                  setVisible(false)
                }
              }}
            >
              Save Changes
            </Form.StandaloneSubmit>,
          ]}
        >
          <Form form={form}>
            <Form.Item
              name={'enabledLabels'}
              label={'Enabled Labels'}
              extra={
                <>
                  If this is specified, teachers will have access to this course, but will only have
                  access to content that matches the selected labels. Other content will display
                  with a "Not in License" label, and teachers will not be able to access it.
                  <br />
                  <br />
                  <strong>Groups are not included in this restriction.</strong> This means if you
                  have groups of segments with the "Lesson" label, and you don't include that label
                  here, the segments inside will still be included.{' '}
                  <strong>
                    In other words, this restriction only applies to leaf-level segments.
                  </strong>
                </>
              }
            >
              <Select
                options={
                  allLabels?.map(label => ({
                    value: label,
                    key: label,
                  })) || []
                }
                mode={'multiple'}
                allowClear
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Form>
        </Modal>
        <Tooltip title={'Course Settings'}>
          <Button
            icon={<AntIconWrapper children={<Settings />} />}
            onClick={() => setVisible(true)}
          />
        </Tooltip>
      </>
    )
  } else {
    return null
  }
}
