import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { MediaImage } from '@thesisedu/ui/icons'
import React from 'react'

import { useImageSettings } from './settings'
import { useDropImage } from './useDropImage'
import { usePasteImage } from './usePasteImage'
import { useResizableImage } from './useResizableImage'
import { $createImageNode, $isImageNode } from '../../nodes/Image/ImageNode'
import { useElementsMenuItem, ElementsGroup } from '../../ui/ElementsMenu/ElementsMenuContext'

export interface ImagePluginProps {
  anchorElement?: HTMLElement
}
export function ImagePlugin({ anchorElement = document.body }: ImagePluginProps) {
  const [editor] = useLexicalComposerContext()
  useElementsMenuItem({
    identifier: 'image',
    icon: <MediaImage />,
    weight: 11,
    group: ElementsGroup.Media,
    title: 'Image',
    isSelected: $isImageNode,
    onCommit(element) {
      return element.replace($createImageNode())
    },
  })
  const settingsElements = useImageSettings()
  usePasteImage()
  useDropImage()
  const resizeChildren = useResizableImage(editor, anchorElement)

  return (
    <>
      {resizeChildren}
      {settingsElements}
    </>
  )
}
