import { Tooltip } from 'antd'
import React from 'react'

import { BodyLarge, BodySmall, FontWeight } from './Typography'
import { styled } from './styledTypes'

export interface PipelineStep<T extends string> {
  name: string
  subtitle: string
  icon: React.ReactElement
  key: T
  disabled?: boolean
  activeColor?: string
  tooltip?: string
}
export interface PipelineProps<T extends string> {
  activeKey?: T
  steps: PipelineStep<T>[]
  disabled?: boolean
  onChange: (activeKey: T) => void
}
export function Pipeline<T extends string = string>({
  onChange,
  activeKey,
  steps,
  disabled,
}: PipelineProps<T>) {
  return (
    <Container>
      {steps.map(
        ({
          name,
          subtitle,
          tooltip,
          icon,
          key,
          disabled: itemDisabled,
          activeColor = '@primary-color',
        }) => {
          const contents = (
            <Step
              key={key}
              activeColor={activeColor}
              className={disabled || itemDisabled ? 'disabled' : activeKey === key ? 'active' : ''}
              onClick={
                activeKey === key || disabled || itemDisabled
                  ? undefined
                  : () => {
                      onChange(key)
                    }
              }
            >
              <div children={icon} />
              <div>
                <BodyLarge
                  children={name}
                  style={{ lineHeight: 1.2 }}
                  weight={FontWeight.SemiBold}
                />
                <BodySmall children={subtitle} style={{ lineHeight: 1 }} />
              </div>
            </Step>
          )
          if (tooltip) {
            return <Tooltip title={tooltip}>{contents}</Tooltip>
          } else return contents
        },
      )}
    </Container>
  )
}

const HEIGHT = '@size-xxl'
const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  height: ${props => props.theme[HEIGHT]};
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
`
const Step = styled.div<{ activeColor: string }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition:
    background 0.1s linear,
    color 0.1s linear;
  background: ${props => props.theme['@background-color-base']};
  padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-l']};
  cursor: pointer;
  position: relative;
  > :first-child {
    margin-right: ${props => props.theme['@size-s']};
    font-size: ${props => props.theme['@size-m']};
    transition: color 0.1s linear;
    color: ${props => props.theme['@text-color-secondary']};
  }
  &:first-child {
    padding-left: ${props => props.theme['@size-s']};
  }
  &:not(:last-child)::after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -${props => props.theme['@size-xs']};
    transition: border-left-color 0.1s linear;
    border-top: calc(${props => props.theme[HEIGHT]} / 2) solid transparent;
    border-bottom: calc(${props => props.theme[HEIGHT]} / 2) solid transparent;
    border-left: ${props => props.theme['@size-xs']} solid
      ${props => props.theme['@background-color-base']};
    z-index: 501;
  }
  &:not(:last-child)::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: calc(-${props => props.theme['@size-xs']} - 2px);
    transition: border-left-color 0.1s linear;
    border-top: calc(${props => props.theme[HEIGHT]} / 2) solid transparent;
    border-bottom: calc(${props => props.theme[HEIGHT]} / 2) solid transparent;
    border-left: calc(${props => props.theme['@size-xs']} + 2px) solid
      ${props => props.theme['@border-color-base']};
    z-index: 500;
  }
  &:hover {
    background: ${props => props.theme['@gray-3']};
    &:not(:last-child)::after {
      border-left-color: ${props => props.theme['@gray-3']};
    }
    &:not(:last-child)::before {
      border-left-color: ${props => props.theme['@gray-3']};
    }
  }
  &.active {
    background: ${props => props.theme[props.activeColor]};
    color: ${props => props.theme['@gray-0']};
    &:not(:last-child)::after {
      border-left-color: ${props => props.theme[props.activeColor]};
    }
    &:not(:last-child)::before {
      border-left-color: ${props => props.theme[props.activeColor]};
    }
    > :first-child {
      color: ${props => props.theme['@gray-0']};
    }
  }
  &.disabled {
    cursor: default;
    background: ${props => props.theme['@background-color-light']};
    * {
      color: ${props => props.theme['@gray-3']} !important;
    }
    &::after {
      border-left-color: ${props => props.theme['@background-color-light']};
    }
    &:not(:last-child)::before {
      border-left-color: ${props => props.theme['@background-color-light']};
    }
  }
`
