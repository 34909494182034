import { ApplicationOptions } from '@thesisedu/feature'
import { useFeature } from '@thesisedu/feature-react'

import ClassesReactFeature from './ClassesReactFeature'
import { ClassesWebOptions } from './types'

export interface UseOptions extends ClassesWebOptions {
  classNameCapitalized: string
  classNamePluralCapitalized: string
}
export function useOptions(): UseOptions & ApplicationOptions {
  const feature = useFeature(ClassesReactFeature)
  return {
    ...feature.options,
    classNameCapitalized:
      feature.options.className.charAt(0).toUpperCase() + feature.options.className.slice(1),
    classNamePluralCapitalized:
      feature.options.classNamePlural.charAt(0).toUpperCase() +
      feature.options.classNamePlural.slice(1),
    ...feature.appOptions,
  }
}
