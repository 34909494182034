import { styled } from '@thesisedu/react'

export const DragDropFieldAffix = styled.div`
  position: sticky;
  top: 100px;
  bottom: 0;
  flex: 0.4;
  align-items: stretch;
  align-self: flex-start;
  z-index: 3;
`
