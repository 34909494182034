import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { useAudioPlayback } from '@thesisedu/web'
import React from 'react'

import { ImageWithAudioPayload } from './ImageWithAudioEdit'
import { Wrapper, WrapperProps } from '../QuestionTextMediaField'

export interface ImageWithAudioViewProps extends Partial<WrapperProps> {
  value: ImageWithAudioPayload
  onLoad?: (e: React.SyntheticEvent<HTMLImageElement>) => void
}
export function ImageWithAudioView({ value, onLoad, ...rest }: ImageWithAudioViewProps) {
  const { filename: imageFilename, label: imageLabel } = getFileInfo(value?.imagePath || '')
  const { filename: audioFilename } = getFileInfo(value?.audioPath || '')
  const playAudio = useAudioPlayback(audioFilename)
  return imageFilename ? (
    <Wrapper
      className={'question-text-media-wrapper'}
      style={value.size}
      disableSizeCheck={!!value.size}
      {...rest}
    >
      <img
        src={imageFilename}
        alt={imageLabel}
        style={audioFilename ? { cursor: 'pointer' } : undefined}
        onClick={audioFilename ? playAudio : undefined}
        onLoad={onLoad}
      />
    </Wrapper>
  ) : null
}
