export interface Collection {
  id: string
}
export function mergeCollections<T extends Collection>(base: T[], ...others: T[][]): T[] {
  const flatOthers = others.flat() as T[]
  const mergedIds: Record<string, T> = {}
  for (const other of flatOthers) {
    if (mergedIds[other.id]) mergedIds[other.id] = { ...mergedIds[other.id], ...other }
    else mergedIds[other.id] = other
  }

  const result = [...base]
  for (const otherId of Object.keys(mergedIds)) {
    const existingIndex = result.findIndex(r => r.id === otherId)
    if (existingIndex !== -1) {
      result.splice(existingIndex, 1)
      result.push({ ...result[existingIndex], ...mergedIds[otherId] })
    } else {
      result.push(mergedIds[otherId])
    }
  }

  return result
}
