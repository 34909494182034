import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  AssignmentWithRemainingAttemptsFragmentDoc,
  useSaveAssignmentSubmissionMutation as useMutation,
  BasicAssignmentSubmissionFragmentDoc,
  BasicAssignmentFragmentDoc,
} from '../schema'

export const useSaveAssignmentSubmissionMutation: typeof useMutation = opts => {
  const [mutate, rest] = useMutation({
    onError: onMutationError('There was an error saving your submission.'),
    ...opts,
  })

  return [
    mutateOpts =>
      mutate({
        ...mutateOpts,
        update: (proxy, result, ...rest) => {
          if (mutateOpts?.update) mutateOpts.update(proxy, result, ...rest)
          updateMutation({
            fragment: BasicAssignmentSubmissionFragmentDoc,
            fragmentName: 'BasicAssignmentSubmission',
            path: 'saveAssignmentSubmission.assignmentSubmission',
          })(proxy, result, ...rest)
          updateMutation({
            fragment: BasicAssignmentFragmentDoc,
            fragmentName: 'BasicAssignment',
            path: 'saveAssignmentSubmission.assignment',
          })(proxy, result, ...rest)
          updateMutation({
            fragment: AssignmentWithRemainingAttemptsFragmentDoc,
            fragmentName: 'AssignmentWithRemainingAttempts',
            path: 'saveAssignmentSubmission.assignment',
          })(proxy, result, ...rest)
        },
      }),
    rest,
  ]
}
