import { styled } from '@thesisedu/web'
import React from 'react'

import { SegmentList, SegmentListProps } from './SegmentList'
import { SegmentProps } from '../types'

const Container = styled.div`
  border-left: solid 2px ${props => props.theme['@gray-2']};
  padding-left: 5px;
  margin: 10px -10px 0 0;
`

export type SegmentChildrenProps = SegmentProps &
  Pick<SegmentListProps, 'addTypes' | 'expandLevels'>
export function SegmentChildren({ expanded, segment, ...props }: SegmentChildrenProps) {
  if (!expanded) return null
  return (
    <Container>
      <SegmentList
        {...props}
        id={segment.id}
        segments={segment.childSegments || []}
        parentSegment={segment}
      />
    </Container>
  )
}
