import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import DateField, * as DateField$ from '../DateField'

function _FormDateField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<DateField$.DateFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <DateField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormDateField = React.forwardRef(_FormDateField)

function _FormTimeField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<DateField$.TimeFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <DateField.TimeField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormTimeField = React.forwardRef(_FormTimeField)

function _FormDatePickerField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<DateField$.DatePickerFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <DateField.DatePickerField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormDatePickerField = React.forwardRef(_FormDatePickerField)

function _FormDateRangePickerField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<DateField$.DateRangePickerFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <DateField.DateRangePickerField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormDateRangePickerField = React.forwardRef(_FormDateRangePickerField)
