import { LoadingOutlined } from '@ant-design/icons'
import { toGlobalId } from '@thesisedu/feature-utils'
import { ErrorIndicator, styled } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { useFetchSegmentQuery } from '../queries/useFetchSegmentQuery'
import { CreateEditSegment } from '../segment/CreateEditSegment'
import { SegmentProps } from '../types'

export function EditSegmentItem({ segment }: SegmentProps) {
  const { data, loading, error } = useFetchSegmentQuery(toGlobalId('Segment', segment.id))

  if (error) return <ErrorIndicator />
  if (loading)
    return <Result icon={<LoadingOutlined size={75} />} title={'Loading your content...'} />

  return (
    <CourseViewerEditSegmentWrapper>
      <CreateEditSegment
        segment={data?.node?.__typename === 'Segment' ? data.node : undefined}
        skipRedirect
        skipHeader
      />
    </CourseViewerEditSegmentWrapper>
  )
}

const CourseViewerEditSegmentWrapper = styled.div`
  .fteditor-toolbar {
    top: 64px;
  }
`
