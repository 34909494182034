import { s } from '@thesisedu/ui'

import { MarkType } from './types'

const COLORS: (keyof s.ColorAliases)[] = ['primary', 'blue', 'green', 'orange', 'red']

const { css } = s
export const FOREGROUND_MARKS: MarkType[] = [
  {
    identifier: 'fg-secondary',
    weight: 0,
    label: 'Gray',
    style: css`
      color: ${s.color('gray.secondary')};
    `,
  },
  ...COLORS.map((color, index) => ({
    identifier: `fg-${color}`,
    weight: 1 + index,
    label: color[0].toUpperCase() + color.slice(1),
    style: css`
      color: ${s.color(`${color}.solid`)};
    `,
  })),
]

const baseBackground = css`
  background: var(--bg-mark-element);
  color: var(--bg-mark-text);
  padding: ${s.size('0.1')} ${s.size('0.25')};
  border-radius: ${s.var('radii.0')};
`
export const BACKGROUND_MARKS: MarkType[] = [
  {
    identifier: 'bg-gray',
    weight: 0,
    label: 'Gray',
    style: css`
      --bg-mark-element: ${s.color('gray.element')};
      --bg-mark-text: ${s.color('gray.text')};
      --bg-mark-solid: ${s.color('gray.solid')};
      ${baseBackground}
    `,
  },
  ...COLORS.map((color, index) => ({
    identifier: `bg-${color}`,
    weight: 1 + index,
    label: color[0].toUpperCase() + color.slice(1),
    style: css`
      --bg-mark-element: ${s.color(`${color}.element`)};
      --bg-mark-text: ${s.color(`${color}.text`)};
      --bg-mark-solid: ${s.color(`${color}.solid`)};
      ${baseBackground}
    `,
  })),
]

export const OTHER_MARKS: MarkType[] = [
  {
    identifier: 'accounting-account',
    weight: 0,
    label: 'Accounting Account',
    style: css`
      padding: ${s.size('0.1')} ${s.size('0.25')};
      border-radius: ${s.var('radii.0')};
      font-weight: bold;
      background: ${s.color('gray.element')};
      color: ${s.color('gray.highContrast')};
      font-size: 1.1em;
    `,
  },
  {
    identifier: 'definition',
    weight: -1,
    label: 'Definition',
    style: css`
      padding: ${s.size('0.1')} ${s.size('0.25')};
      border-radius: ${s.var('radii.0')};
      font-weight: bold;
      background: ${s.color('orange.element')};
      color: ${s.color('gray.highContrast')};
      font-size: 1.1em;
    `,
  },
]

export const ALL_MARKS = [...FOREGROUND_MARKS, ...BACKGROUND_MARKS, ...OTHER_MARKS]
