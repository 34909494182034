import { Question } from '@thesisedu/feature-assignments-core'
import React from 'react'

export interface QuestionWidgetContextValue {
  questions: Question[]
}
export const QuestionWidgetContext = React.createContext<QuestionWidgetContextValue | undefined>(
  undefined,
)

export interface QuestionWidgetProviderProps {
  questions: Question[]
  children: React.ReactElement
}
export function QuestionWidgetProvider({ questions, children }: QuestionWidgetProviderProps) {
  return <QuestionWidgetContext.Provider value={{ questions }} children={children} />
}

export function useQuestionWidgetContext(): QuestionWidgetContextValue | undefined
export function useQuestionWidgetContext(require: false): QuestionWidgetContextValue | undefined
export function useQuestionWidgetContext(require: true): QuestionWidgetContextValue
export function useQuestionWidgetContext(
  require?: boolean,
): QuestionWidgetContextValue | undefined {
  const context = React.useContext(QuestionWidgetContext)
  if (!context && require) {
    throw new Error('QuestionWidgetContext is required, yet not provided.')
  }
  return context
}

interface QuestionWidgetQuestionResult {
  question: Question
  questionIndex: number
  totalQuestions: number
}
export function useQuestionWidgetQuestion(id: string): QuestionWidgetQuestionResult {
  const { questions } = useQuestionWidgetContext(true)
  const questionIndex = questions.findIndex(q => q.id === id)
  const question = questions[questionIndex]
  return { question, questionIndex, totalQuestions: questions.length }
}
