import { QuestionOverride } from '@thesisedu/feature-assignments-core'
import { SegmentAssignmentConfiguration } from '@thesisedu/feature-course-types'
import React from 'react'

import { QuizVaultPreset } from './presets'

export interface SegmentCalculationContextValue {
  updateConfiguration: (
    configuration: Partial<SegmentAssignmentConfiguration>,
    awaitRefetch?: boolean,
  ) => Promise<any>
  updateOverrides: (
    overrides: QuestionOverride[],
    configuration?: Partial<SegmentAssignmentConfiguration>,
    awaitRefetch?: boolean,
  ) => Promise<any>
  selectPreset: (preset: QuizVaultPreset) => Promise<any>
  loading?: boolean
}
export const SegmentCalculationContext = React.createContext<
  SegmentCalculationContextValue | undefined
>(undefined)

export function useSegmentCalculationContext(): SegmentCalculationContextValue | undefined
export function useSegmentCalculationContext(
  require: false,
): SegmentCalculationContextValue | undefined
export function useSegmentCalculationContext(require: true): SegmentCalculationContextValue
export function useSegmentCalculationContext(
  require?: boolean,
): SegmentCalculationContextValue | undefined {
  const context = React.useContext(SegmentCalculationContext)
  if (!context && require) {
    throw new Error('SegmentCalculationContext is required, yet not provided.')
  }
  return context
}

export interface SegmentCalculationSegmentIdContextValue {
  segmentId: string
}
export const SegmentCalculationSegmentIdContext = React.createContext<
  SegmentCalculationSegmentIdContextValue | undefined
>(undefined)

export function useSegmentCalculationSegmentIdContext():
  | SegmentCalculationSegmentIdContextValue
  | undefined
export function useSegmentCalculationSegmentIdContext(
  require: false,
): SegmentCalculationSegmentIdContextValue | undefined
export function useSegmentCalculationSegmentIdContext(
  require: true,
): SegmentCalculationSegmentIdContextValue
export function useSegmentCalculationSegmentIdContext(
  require?: boolean,
): SegmentCalculationSegmentIdContextValue | undefined {
  const context = React.useContext(SegmentCalculationSegmentIdContext)
  if (!context && require) {
    throw new Error('SegmentCalculationSegmentIdContext is required, yet not provided.')
  }
  return context
}
