import { Button, HSpaced } from '@thesisedu/ui'
import { Block, styled, ZIndex } from '@thesisedu/web'
import React from 'react'

import { AssignmentViewerFormForm } from './AssignmentViewerFormForm'
import { FormActions } from './FormActions'
import { FormGrade } from './FormGrade'
import { FormGradeRubric, useHasRubric } from './FormGradeRubric'
import { FormQuestions } from './FormQuestions'
import { useAssignmentViewerContext } from '../../contexts/AssignmentViewerContext'

export function AssignmentViewerForm() {
  const { teacherView } = useAssignmentViewerContext(true)
  const hasRubric = useHasRubric()
  const actions = <FormActions />
  return (
    <AssignmentViewerFormForm>
      {teacherView ? null : (
        <ActionsContainer children={actions} data-sticky={'AssignmentViewerForm'} />
      )}
      <HSpaced vertical={'m'} align={'flex-start'}>
        <FormGrade />
        {hasRubric ? (
          <Button
            children={'Jump to Rubric'}
            size={'small'}
            onPress={() => {
              document
                .getElementById('custom-rubric')
                ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }}
          />
        ) : null}
      </HSpaced>
      <QuestionsContainer $teacherView={!!teacherView}>
        <FormQuestions />
      </QuestionsContainer>
      <FormGradeRubric />
      {teacherView ? null : <Block marginTop={'@size-l'} children={actions} />}
    </AssignmentViewerFormForm>
  )
}

const ActionsContainer = styled.div`
  position: sticky;
  top: -${props => props.theme['@size-l']};
  margin: -${props => props.theme['@size-l']} -${props => props.theme['@size-m']};
  margin-bottom: 0;
  padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-m']}
    ${props => props.theme['@size-m']} ${props => props.theme['@size-m']};
  z-index: ${ZIndex.StickyHeaders};
  background: white;
  border-top-right-radius: ${props => props.theme['@border-radius-large']};
`
const QuestionsContainer = styled.div<{ $teacherView?: boolean }>`
  .fteditor-toolbar {
    // This is to account for the height of the sticky header.
    top: ${props => (props.$teacherView ? 0 : 100)}px;
  }
`
