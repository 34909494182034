import React from 'react'

import { getFetchClassesFromProvider } from './fetchClassesFromProvider'
import { ProviderGrid } from '../SelectProvider/ProviderGrid'
import { StepHeader } from '../StepHeader'
import { SetupStep, getStepFactory } from '../types'

export default {
  Component({ transition }) {
    return (
      <>
        <StepHeader
          title={'Select a Provider'}
          description={
            'To get started with syncing content, select a provider from the list below.'
          }
        />
        <ProviderGrid
          onProviderSelected={provider => {
            transition(getFetchClassesFromProvider({ provider }))
          }}
        />
      </>
    )
  },
  size: {
    width: 600,
    height: 400,
  },
} satisfies SetupStep
export const getSelectProvider = getStepFactory<object>('selectProvider')
