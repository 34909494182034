import { AuthRequiredPayload, AuthProviderWrapper } from '@thesisedu/feature-users-react'
import React from 'react'

import { useSchoologyAssociate } from './useSchoologyLogin'

function _AuthProviderInner({ onSuccess, onError, ...opts }: AuthRequiredPayload) {
  const { signIn, associating } = useSchoologyAssociate({ onSuccess, onError })
  return (
    <AuthProviderWrapper
      identifier={'schoology'}
      onAuth={signIn}
      associating={associating}
      onError={onError}
      {...opts}
    />
  )
}
export const AuthProviderInner = React.memo(_AuthProviderInner, () => false)

export function AuthProviderHandler(props: AuthRequiredPayload) {
  return <AuthProviderInner {...props} />
}
