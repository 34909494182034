import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled, s, Select, Select$ } from '@thesisedu/ui'
import React from 'react'

import { OrderDirection, useListCoursesQuery } from '../schema'

export interface CourseSelectProps extends Omit<Select$.SelectProps, 'children'> {}
export function CourseSelect(props: CourseSelectProps) {
  const { data, loading } = useListCoursesQuery({
    variables: {
      first: 50,
      orderBy: 'weight',
      orderDirection: OrderDirection.Asc,
      isByoc: false,
    },
  })
  const courses = data?.courses.edges.map(edge => edge.node) ?? []

  return (
    <Select
      disabled={loading}
      {...props}
      placeholder={loading ? 'Loading courses...' : props.placeholder}
    >
      {loading
        ? []
        : courses.map(course => {
            const iconFilename = getFileInfo(course.basicConfiguration?.icon ?? '')?.filename
            return (
              <Select.Item
                value={course.id}
                icon={
                  iconFilename ? (
                    <CourseIcon
                      src={iconFilename}
                      alt={`Icon for ${course.label ?? course.name}`}
                    />
                  ) : undefined
                }
              >
                {course.label ?? course.name}
              </Select.Item>
            )
          })}
    </Select>
  )
}

export const CourseIcon = styled.img`
  width: 1em;
  border-radius: ${s.var('radii.0')};
`
