import { Block, Text } from '@thesisedu/ui'
import { InfoEmpty } from '@thesisedu/ui/icons'
import React from 'react'

import { SidebarTab } from '../api/SidebarTab'

export interface SummaryPluginProps {
  title: string
  subtitle?: string
  children?: React.ReactNode
}
export function SummaryPlugin({ title, subtitle, children }: SummaryPluginProps) {
  return (
    <SidebarTab weight={-Infinity} icon={<InfoEmpty />} title={'Info'}>
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <Text level={'h3'} bottom={!subtitle}>
          {title}
        </Text>
        {subtitle ? (
          <Text level={'s'} color={'secondary'}>
            {subtitle}
          </Text>
        ) : null}
        {children ? <Block top={'m'}>{children}</Block> : null}
      </div>
    </SidebarTab>
  )
}
