import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { Block } from '@thesisedu/web'
import { ResponsiveBar, ResponsiveBarProps } from '@thesisedu/web/nivo'
import { Table } from 'antd'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { UserFragment } from '../schema'

export interface ReportSummaryItem {
  student: { id: string; user: UserFragment }
}
export interface ReportSummaryProps<Item extends ReportSummaryItem> {
  rows: Item[]
  loading?: boolean
  metric: {
    key: keyof Item
    name: string
  }
  barProps?: Partial<ResponsiveBarProps>
}
export function ReportSummary<Item extends ReportSummaryItem>({
  rows,
  loading,
  children,
  metric,
  barProps,
}: React.PropsWithChildren<ReportSummaryProps<Item>>) {
  const cls = useSelectedClass(true)
  const navigate = useNavigate()
  return (
    <div>
      <Block marginBottom={'@size-l'}>
        <ResponsiveBar
          loading={loading}
          data={rows.map(row => ({
            student: row.student.user.name || row.student.user.username,
            studentId: row.student.id,
            [metric.key.toString()]: row[metric.key],
          }))}
          keys={[metric.key.toString()]}
          indexBy={'student'}
          axisLeft={{ legend: metric.name }}
          onClick={({ data }) => {
            navigate(`/teacher/reports/${cls.id}/${data.studentId}`)
          }}
          {...barProps}
        />
      </Block>
      <Table<Item>
        dataSource={rows}
        loading={loading}
        rowKey={item => item.student.id}
        pagination={false}
      >
        <Table.Column<Item>
          title={'Student'}
          dataIndex={['student', 'id']}
          render={(_, record) => (
            <Link to={`/teacher/reports/${cls.id}/${record.student.id}`}>
              {record.student.user.name || record.student.user.username}
            </Link>
          )}
        />
        {children}
      </Table>
    </div>
  )
}
