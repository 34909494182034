import { SAMLButtonIcon, getSAMLButton } from '@thesisedu/feature-users-react'
import React from 'react'

import { useCleverLogin } from './useCleverLogin'
// @ts-ignore
import icon from '../../assets/icon.svg'

export const CleverLoginButton = getSAMLButton({
  identifier: 'clever',
  label: 'Clever',
  icon: <SAMLButtonIcon src={icon} alt={'Clever Icon'} />,
  useLoginHook: useCleverLogin,
})
