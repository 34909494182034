
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseClassSyncHistoryDetail": [
      "ClassSyncHistoryErrorDetail",
      "ClassSyncHistorySuccessDetail"
    ],
    "ClassSyncHistoryDetail": [
      "ClassSyncHistoryErrorDetail",
      "ClassSyncHistorySuccessDetail"
    ],
    "Connection": [
      "AnnouncementsConnection",
      "AssignedLessonStudentsConnection",
      "AssignedLessonsConnection",
      "AssignmentCategoriesConnection",
      "AssignmentClassesConnection",
      "AssignmentStudentsConnection",
      "AssignmentSubmissionsConnection",
      "AssignmentSyncsConnection",
      "AssignmentTemplatesConnection",
      "AssignmentsConnection",
      "BadgesConnection",
      "ClassGroupPresetsConnection",
      "ClassStudentsConnection",
      "ClassSyncHistoryConnection",
      "ClassSyncsConnection",
      "ClassTeachersConnection",
      "ClassesConnection",
      "CommentsConnection",
      "CourseVersionsConnection",
      "CourseViewsConnection",
      "CoursesConnection",
      "DirectMessageThreadsConnection",
      "DistrictsConnection",
      "InteractionsConnection",
      "LessonsConnection",
      "LicenseSubscriptionsConnection",
      "LicensesConnection",
      "MediaConnection",
      "NotificationsConnection",
      "PendingStudentClassesConnection",
      "PracticesConnection",
      "RubricTemplatesConnection",
      "SavedReportsConnection",
      "SearchResultsConnection",
      "SegmentsConnection",
      "SheetMusicCompositionsConnection",
      "SiteStandardSetsConnection",
      "SitesConnection",
      "StandardSetsConnection",
      "StudentClassesConnection",
      "StudentsConnection",
      "TagsConnection",
      "TeacherClassesConnection",
      "TeachersConnection",
      "TrialRequestCampaignsConnection",
      "TrialRequestsConnection",
      "UserBadgesConnection",
      "UsersConnection",
      "VideoOnDemandConnection",
      "WidgetTemplatesConnection"
    ],
    "Edge": [
      "AnnouncementsEdge",
      "AssignedLessonStudentsEdge",
      "AssignedLessonsEdge",
      "AssignmentCategoriesEdge",
      "AssignmentClassesEdge",
      "AssignmentStudentsEdge",
      "AssignmentSubmissionsEdge",
      "AssignmentSyncsEdge",
      "AssignmentTemplatesEdge",
      "AssignmentsEdge",
      "BadgesEdge",
      "ClassGroupPresetsEdge",
      "ClassStudentsEdge",
      "ClassSyncHistoryEdge",
      "ClassSyncsEdge",
      "ClassTeachersEdge",
      "ClassesEdge",
      "CommentsEdge",
      "CourseVersionsEdge",
      "CourseViewsEdge",
      "CoursesEdge",
      "DirectMessageThreadsEdge",
      "DistrictsEdge",
      "InteractionsEdge",
      "LessonsEdge",
      "LicenseSubscriptionsEdge",
      "LicensesEdge",
      "MediaEdge",
      "NotificationsEdge",
      "PendingStudentClassEdge",
      "PracticesEdge",
      "RubricTemplatesEdge",
      "SavedReportsEdge",
      "SearchResultsEdge",
      "SegmentsEdge",
      "SheetMusicCompositionsEdge",
      "SiteStandardSetsEdge",
      "SitesEdge",
      "StandardSetsEdge",
      "StudentClassesEdge",
      "StudentsEdge",
      "TagsEdge",
      "TeacherClassesEdge",
      "TeachersEdge",
      "TrialRequestCampaignsEdge",
      "TrialRequestsEdge",
      "UserBadgesEdge",
      "UsersEdge",
      "VideoOnDemandEdge",
      "WidgetTemplatesEdge"
    ],
    "Node": [
      "Announcement",
      "Assignment",
      "AssignmentCategory",
      "AssignmentSubmission",
      "AssignmentSync",
      "AssignmentTemplate",
      "Badge",
      "Class",
      "ClassGroupPreset",
      "ClassSync",
      "ClassSyncHistory",
      "Comment",
      "Course",
      "CourseVersion",
      "CourseView",
      "DirectMessageThread",
      "District",
      "GeneratedPDF",
      "Interaction",
      "Lesson",
      "License",
      "LicenseSubscription",
      "MaterialSync",
      "Media",
      "Notification",
      "PendingStudentClass",
      "Practice",
      "ReportJob",
      "RubricTemplate",
      "SavedReport",
      "Segment",
      "SheetMusicComposition",
      "Site",
      "StandardSet",
      "Student",
      "Tag",
      "Teacher",
      "TrialRequest",
      "TrialRequestCampaign",
      "User",
      "VideoOnDemand",
      "WidgetTemplate"
    ],
    "ShareableNode": [
      "Lesson",
      "Practice"
    ],
    "UserTask": [
      "BasicUserTask",
      "CompleteAssignmentUserTask",
      "ReviewAssignmentUserTask",
      "ViewSegmentUserTask"
    ],
    "VodPlaybackData": [
      "CustomVodPlaybackData",
      "DefaultVodPlaybackData"
    ],
    "VodUploadPayload": [
      "CustomVodUploadPayload",
      "SignedUploadResult"
    ],
    "WithClassSyncHistory": [
      "AssignmentSync",
      "ClassSync",
      "MaterialSync"
    ]
  }
};
      export default result;
    