import moment from 'moment'

import { PracticeFragment } from './schema'

export const getPracticeLabel = (practice: PracticeFragment): string => {
  if (practice.name) {
    return practice.name
  } else if ((practice as any).lesson) {
    return (practice as any).lesson.name
  } else {
    return `Practice on ${moment(practice.createdAt).format('MMMM D')}`
  }
}
