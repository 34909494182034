import { isEqual } from './isEqual'

export function isDirty<T extends Record<string, any>>(original: T, currentValue: Partial<T>) {
  if (!original && !currentValue) return false
  if (original && !currentValue) return true
  for (const key of Object.keys(original)) {
    if (!isEqual(original[key], currentValue[key])) return true
  }

  return false
}
