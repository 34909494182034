import React from 'react'
import { FieldValues, useForm as useFormCore } from 'react-hook-form'

import { isNative } from '../isNative'

/** @deprecated */
export function useForm<Values extends FieldValues>() {
  return useFormCore<Values>({
    mode: isNative ? 'onChange' : undefined,
  })
}

export interface UseControlledFormOpts<Values> {
  value: Values
  onChange: (values: Values) => void
}
/** @deprecated */
export function useControlledForm<Values extends FieldValues>({
  value,
  onChange,
}: UseControlledFormOpts<Values>) {
  const form = useForm<Values>()
  React.useEffect(() => {
    form.reset(value as any)
  }, [JSON.stringify(value)])
  const allValues = form.watch()
  React.useEffect(() => {
    form.trigger().then(result => {
      if (result) {
        onChange({
          ...value,
          ...allValues,
        })
      }
    })
  }, [JSON.stringify(allValues)])

  return form
}
