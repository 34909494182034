import { AutoComplete, Input } from 'antd'
import React from 'react'

import { useAllStudentsLazyQuery } from '../useAllStudentsQuery'

export interface StudentAutoCompleteProps {
  placeholder?: string
  onSelect: (studentId: string, classId: string) => void
}
export function StudentAutoComplete({
  placeholder = 'Find a student...',
  onSelect,
}: StudentAutoCompleteProps) {
  const didFetchRef = React.useRef(false)
  const [studentResults, doFetch] = useAllStudentsLazyQuery()
  const [filter, setFilter] = React.useState<string | undefined>()
  const [loading, setLoading] = React.useState(false)
  const classes = studentResults.classes
  const options = (classes || []).map(cls => {
    return {
      label: cls.name,
      options: cls.students.edges
        .filter(stu => {
          if (!filter) return true
          const name = stu.node.user.name || stu.node.user.username
          return name.toLowerCase().includes(filter.trim().toLowerCase())
        })
        .map(stu => ({
          value: [stu.node.id, cls.id].join('|'),
          label: stu.node.user.name || stu.node.user.username,
        })),
    }
  })
  return (
    <AutoComplete
      placeholder={placeholder}
      options={options}
      value={filter}
      onSelect={val => {
        const [studentId, classId] = val.toString().split('|')
        onSelect(studentId, classId)
        setFilter('')
      }}
      style={{ maxWidth: 400, width: '100%' }}
      onSearch={async filter => {
        setFilter(filter)
        if (!didFetchRef.current) {
          didFetchRef.current = true
          setLoading(true)
          try {
            await doFetch()
          } finally {
            setLoading(false)
          }
        }
      }}
    >
      <Input.Search
        value={filter}
        allowClear
        style={{ maxWidth: 400, width: '100%' }}
        loading={loading}
      />
    </AutoComplete>
  )
}
