import * as ReactTabs from '@radix-ui/react-tabs'
import React from 'react'

import { TabsContext } from './context'
import { useStatePropFallback } from '../../utils/useStatePropFallback'
import { VSpaced } from '../VSpaced/VSpaced'

export interface RootProps extends Omit<ReactTabs.TabsProps, 'asChild'> {}
export function Root({
  children,
  value: _value,
  onValueChange: _onValueChange,
  defaultValue,
  style,
  ...rest
}: RootProps) {
  const [value, onValueChange] = useStatePropFallback(_value, _onValueChange, defaultValue ?? '')
  return (
    <ReactTabs.Root asChild {...rest} value={value} onValueChange={onValueChange}>
      <TabsContext.Provider value={{ selectedTab: value }}>
        <VSpaced children={children} space={'s'} style={style} />
      </TabsContext.Provider>
    </ReactTabs.Root>
  )
}
