import { copyToClipboard } from '@thesisedu/react'
import { HSpaced, Table$, Text, Tooltip, useToast } from '@thesisedu/ui'
import React from 'react'

import { StudentTableStudent } from './StudentsTable'
import { useSelectedClass } from '../../ClassContext'
import { ClassStudentMetadata } from '../../ClassStudentMetadata'
import { StudentAvatar } from '../StudentAvatar'
import { StudentLink } from '../StudentLink'

export function NameCell({ item }: Table$.ImperativeTableRenderCellProps<StudentTableStudent>) {
  const cls = useSelectedClass(true)
  const toast = useToast()
  return (
    <HSpaced space={'xs'} style={{ display: 'inline-flex' }}>
      <StudentAvatar user={item.node.user} isTeacher={item.isTeacher} />
      <div>
        <StudentLink studentId={item.node.id} classId={item.classId}>
          <Text>{item.node.user.name ?? item.node.user.username}</Text>
        </StudentLink>
        <Text level={'s'} color={'secondary'}>
          {item.node.user.email ? (
            <Tooltip title={'Click to copy to clipboard'}>
              <Text
                level={null}
                onClick={() => {
                  if (item.node.user.email) {
                    copyToClipboard(item.node.user.email)
                    toast({ status: 'success', title: 'Copied to clipboard!', key: 'copy-email' })
                  }
                }}
              >
                {item.node.user.email}
              </Text>
            </Tooltip>
          ) : (
            'No Email'
          )}
          <ClassStudentMetadata
            context={{ edge: item, class: cls, refetchQueries: ['teacherClass'] }}
          />
        </Text>
      </div>
    </HSpaced>
  )
}
