import { HTMLProps } from '@thesisedu/ui'
import React from 'react'

import { AudioStreamPreview } from './AudioStreamPreview'
import { ErrorStreamPreview } from './ErrorStreamPreview'
import { VideoStreamPreview } from './VideoStreamPreview'

export interface StreamsPreviewProps extends HTMLProps<HTMLVideoElement> {
  stream?: MediaStream | Blob
  streamHasVideo?: boolean
}
function _StreamsPreview(
  { stream, ...rest }: StreamsPreviewProps,
  ref: React.ForwardedRef<HTMLVideoElement>,
) {
  if (
    stream &&
    (stream instanceof Blob || (stream instanceof MediaStream && stream?.getVideoTracks().length))
  ) {
    return <VideoStreamPreview stream={stream} {...rest} ref={ref} />
  } else if (stream && stream instanceof MediaStream) {
    return <AudioStreamPreview stream={stream} />
  } else {
    return <ErrorStreamPreview />
  }
}
export const StreamsPreview = React.forwardRef(_StreamsPreview)
