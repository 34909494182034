import React from 'react'
import {
  DragAndDropOptions as CoreDragAndDropOptions,
  DragAndDropHooks,
  DropIndicator,
  useDragAndDrop as useCoreDragAndDrop,
} from 'react-aria-components'

import { styled, s } from '../..'

export interface DragAndDropOptions extends CoreDragAndDropOptions {}
export function useDragAndDrop(opts: DragAndDropOptions): { dragAndDropHooks: DragAndDropHooks } {
  return useCoreDragAndDrop({
    ...opts,
    renderDropIndicator: target => <_DropIndicator target={target} />,
  })
}

const _DropIndicator = styled(DropIndicator)`
  outline: 1px solid ${s.color('primary')};
  transform: translateZ(0);
`
