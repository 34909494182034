import { Select } from 'antd'
import React from 'react'

import { useVideoOnDemandProvidersQuery } from './schema'

export interface ProviderSelectProps {
  value?: string
  onChange?: (provider: string) => void
  className?: string
}
export function ProviderSelect({ value, onChange, className }: ProviderSelectProps) {
  const { data, loading } = useVideoOnDemandProvidersQuery()
  const providers = data?.videoOnDemandProviders

  return (
    <Select<string>
      style={{ width: '100%' }}
      className={className}
      disabled={loading && !providers?.length}
      placeholder={loading ? 'Loading providers...' : 'Select a Provider'}
      options={
        providers?.map(provider => ({
          value: provider.identifier,
          label: provider.label,
          key: provider.identifier,
        })) || []
      }
      value={value}
      onChange={onChange}
    />
  )
}
