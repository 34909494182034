import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import React from 'react'

export interface ArrayTextAreaProps extends Omit<TextAreaProps, 'value' | 'onChange'> {
  value?: string[]
  onChange?: (value: string[]) => void
}
export const ArrayTextArea = React.forwardRef<TextAreaRef, ArrayTextAreaProps>(
  ({ value, onChange, ...props }, outerRef) => {
    const ref = React.useRef<any>()
    const [localValue, setLocalValue] = React.useState<string>(value?.join('\n') || '')
    const focusRef = React.useRef(false)
    React.useEffect(() => {
      if (!focusRef.current) {
        setLocalValue(value?.join('\n') || '')
      }
    }, [value])
    return (
      <Input.TextArea
        {...props}
        ref={c => {
          if (typeof outerRef === 'function') {
            outerRef(c)
          } else if (outerRef) {
            // @ts-ignore this code is old
            outerRef.current = c
          }
          ref.current = c
        }}
        value={localValue}
        onFocus={() => (focusRef.current = true)}
        onBlur={() => (focusRef.current = false)}
        onChange={e => {
          setLocalValue(e.target.value)
          if (onChange) {
            onChange(
              e.target.value
                .split('\n')
                .map(s => s.trim())
                .filter(Boolean),
            )
          }
        }}
      />
    )
  },
)
