import { findSegment } from '@thesisedu/feature-course-types'
import { NestedDragDropProvider } from '@thesisedu/web/react-beautiful-dnd'
import { cloneDeep, orderBy } from 'lodash'
import React from 'react'

import { SegmentList } from './SegmentList'
import {
  useCourseEditorReadContext,
  useCourseEditorStatusContext,
  useCourseEditorWriteContext,
} from '../contexts/CourseEditorContext'
import {
  SegmentDragDropContext,
  useSegmentDragDrop,
  useSegmentDragDropContext,
} from '../contexts/SegmentDragDropContext'
import {
  SegmentOverrideContext,
  SegmentOverrideContextValue,
} from '../contexts/SegmentOverrideContext'
import { debug, warn } from '../log'
import { SegmentType } from '../schema'
import { Segment } from '../types'

export interface SegmentContainerProps {
  segments: Segment[]
  id: string
  onSegmentsOverride?: SegmentOverrideContextValue['onSegmentsOverride']
  rePlaceSegment?: SegmentOverrideContextValue['rePlaceSegment']
  disableMove?: boolean
  expandLevels?: number
}
export function SegmentContainer({
  segments,
  id,
  onSegmentsOverride,
  rePlaceSegment,
  disableMove,
  expandLevels = 0,
}: SegmentContainerProps) {
  const dragDrop = useSegmentDragDrop()
  const { rootSegment } = useCourseEditorReadContext(true)
  const { enableReferences } = useCourseEditorStatusContext(true)
  const { onSegmentChange } = useCourseEditorWriteContext(false) || {}
  const existingDragDropContext = useSegmentDragDropContext(false)
  const content = (
    <SegmentDragDropContext.Provider
      value={{
        ...existingDragDropContext,
        ...dragDrop,
        disableMove: existingDragDropContext
          ? existingDragDropContext.disableMove || disableMove
          : disableMove,
      }}
    >
      <NestedDragDropProvider
        name={`segment-container-${id}`}
        onDragEnd={result => {
          if (result.destination) {
            const id = result.draggableId
            const weight = result.destination.index
            const parentSegment = findSegment(segments, result.destination.droppableId)
            let childSegments = orderBy(parentSegment?.childSegments || [], 'weight')

            // Re-map the referenceSegment as the main child if we're allowing references.
            if (enableReferences) {
              childSegments = childSegments.map(childSegment => {
                return childSegment.referenceSegment || childSegment
              })
            }

            let fromIndex = null
            childSegments.forEach((childSegment, index) => {
              if (childSegment.id === id) fromIndex = index
            })
            if (fromIndex === null) {
              throw new Error(`Could not find segment ID '${id}' in parent to reorder.`)
            }
            debug('moving segment to weight', id, weight)
            let newBuiltChildSegments = cloneDeep(childSegments)
            const removed = newBuiltChildSegments.splice(fromIndex, 1)[0]
            debug('after removing', newBuiltChildSegments, removed)
            newBuiltChildSegments.splice(weight, 0, removed)
            debug('after final splice', newBuiltChildSegments)
            newBuiltChildSegments = newBuiltChildSegments.map((segment, index) => ({
              ...segment,
              weight: index,
            }))
            let newChildSegments = childSegments.map(childSegment => ({
              id: childSegment.id,
              weight: 0,
            }))
            newChildSegments.splice(weight, 0, newChildSegments.splice(fromIndex, 1)[0])
            newChildSegments = newChildSegments.map((segment, index) => ({
              ...segment,
              weight: index,
            }))
            if (onSegmentChange && parentSegment) {
              onSegmentChange({
                id: parentSegment.id,
                config: {
                  ...parentSegment.config,
                  childSegments: newChildSegments,
                },
                childSegments: newBuiltChildSegments,
              }).catch(err => {
                warn('error moving segments')
                warn(err)
              })
            } else if (onSegmentsOverride) {
              onSegmentsOverride(
                newChildSegments.map(segment => ({
                  id: segment.id,
                  changes: { weight: segment.weight },
                })),
              )
            }
          }
        }}
      >
        <SegmentList
          segments={segments}
          id={id}
          parentSegment={rootSegment}
          addTypes={[SegmentType.Term, SegmentType.Reference]}
          expandLevels={expandLevels}
        />
      </NestedDragDropProvider>
    </SegmentDragDropContext.Provider>
  )
  return onSegmentsOverride ? (
    <SegmentOverrideContext.Provider
      value={{ onSegmentsOverride, rePlaceSegment }}
      children={content}
    />
  ) : (
    content
  )
}
