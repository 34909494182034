import React from 'react'
import { ListChildComponentProps, VariableSizeList as List } from 'react-window'

import { NavigationListSearch, NavigationListSearchProps } from './NavigationListSearch'
import { useResizeObserver } from '../common'

type HeaderKey = 'nl-header' | 'nl-search'
const HEADER_KEY: 'nl-header' = 'nl-header'
const SEARCH_KEY: 'nl-search' = 'nl-search'

export interface BaseListItem {
  id: string
}

export interface RenderNavigationListItemProps<T extends BaseListItem> {
  data: T
  isSelected?: boolean
  onClick: () => void
}
export type RenderNavigationListItem<T extends BaseListItem> = (
  props: RenderNavigationListItemProps<T>,
) => React.ReactElement
export interface NavigationListHeader {
  component: () => React.ReactElement
  height: number
}
type InternalChildItem<T extends BaseListItem> = T & {
  isSelected?: boolean
}

interface SearchContextValue {
  search?: string
  setSearch: (search?: string) => void
  props?: NavigationListSearchProps
}
export const SearchContext = React.createContext<SearchContextValue>({
  setSearch: () => {},
})

export interface NavigationListProps<T extends BaseListItem> {
  /**
   * This should be a static component rather than a render function, or you'll
   * have performance issues.
   */
  children: RenderNavigationListItem<T>
  selectedIds?: string[]
  items: T[]
  itemHeight: number
  header?: NavigationListHeader
  onItemSelected?: (id: string) => void
  /** You'll need to handle the debouncing for this yourself. */
  onSearch?: (term?: string) => void
  searchProps?: NavigationListSearchProps
}
export function NavigationList<T extends BaseListItem>({
  children,
  selectedIds,
  items,
  itemHeight,
  header,
  onItemSelected,
  onSearch,
  searchProps,
}: NavigationListProps<T>) {
  const { ref, height } = useResizeObserver()
  const itemData = [
    ...(header ? [HEADER_KEY] : []),
    ...(onSearch ? [SEARCH_KEY] : []),
    ...items.map<InternalChildItem<T>>(item => {
      return {
        ...item,
        isSelected: selectedIds?.includes(item.id),
      }
    }),
  ]
  const onItemSelectedRef = React.useRef(onItemSelected)
  React.useEffect(() => {
    onItemSelectedRef.current = onItemSelected
  }, [onItemSelected])
  const onSearchRef = React.useRef(onSearch)
  React.useEffect(() => {
    onSearchRef.current = onSearch
  }, [onSearch])
  const [search, setSearch] = React.useState<string | undefined>(undefined)

  // Preserve scroll positions when new items are added to the list.
  const listRef = React.useRef<List>(null)
  const lastScrollRowIndexRef = React.useRef(0)
  React.useEffect(() => {
    if (listRef.current && items.length > lastScrollRowIndexRef.current) {
      listRef.current.scrollToItem(lastScrollRowIndexRef.current, 'start')
    }
  }, [items.length])

  const ChildItem = React.useCallback(
    ({ data, index, style }: ListChildComponentProps<(HeaderKey | InternalChildItem<T>)[]>) => {
      const item = data[index]
      if (item === HEADER_KEY) {
        const HeaderComponent = header!.component
        return (
          <div key={'header'} style={style}>
            <HeaderComponent />
          </div>
        )
      } else if (item === SEARCH_KEY) {
        return (
          <div key={'search'} style={style}>
            <NavigationListSearch />
          </div>
        )
      } else {
        const ChildComponent = children
        return (
          <div key={index} style={style}>
            <ChildComponent
              data={item}
              onClick={() => {
                if (onItemSelectedRef.current) {
                  onItemSelectedRef.current(item.id)
                }
              }}
              isSelected={item.isSelected}
            />
          </div>
        )
      }
    },
    [children, header?.component],
  )

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch: s => {
          setSearch(s)
          if (onSearchRef.current) {
            onSearchRef.current(s)
          }
        },
        props: searchProps,
      }}
    >
      <div ref={ref} style={{ height: '100%' }}>
        {height ? (
          <List<(HeaderKey | InternalChildItem<T>)[]>
            ref={listRef}
            itemCount={(header ? 1 : 0) + (onSearch ? 1 : 0) + items.length}
            itemData={itemData}
            width={'100%'}
            height={height}
            onItemsRendered={({ visibleStartIndex }) => {
              lastScrollRowIndexRef.current = visibleStartIndex
            }}
            itemKey={(index, data) => {
              const item = data[index]
              if (item === HEADER_KEY || item === SEARCH_KEY) {
                return item
              } else {
                return item.id
              }
            }}
            itemSize={index => {
              const item = itemData[index]
              if (item === HEADER_KEY) {
                return header!.height
              } else if (item === SEARCH_KEY) {
                return 30
              } else {
                return itemHeight
              }
            }}
            children={ChildItem}
          />
        ) : null}
      </div>
    </SearchContext.Provider>
  )
}
