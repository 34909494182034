import { styled, Text, HSpaced, VSpaced, s, Button } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncRosterActionFragment, ClassSyncRosterActionMode } from '../../schema'

export interface RosteringGroupHeaderProps {
  onChange: (update: ClassSyncRosterActionFragment) => void
  updates: ClassSyncRosterActionFragment[]
  group: string
}
export function RosteringGroupHeader({ onChange, updates, group }: RosteringGroupHeaderProps) {
  const unmatchedUpdates = updates.filter(up => !up.student)
  return (
    <VSpaced space={'xxs'} align={'flex-end'}>
      <HSpaced>
        <Text level={'s'} weight={'bold'} style={{ textAlign: 'right' }}>
          {group}
        </Text>
        <RightBorder />
      </HSpaced>
      {unmatchedUpdates.length ? (
        <HSpaced style={{ marginRight: `calc(22px + ${s.size('s')})` }}>
          <Button
            variant={'chromeless'}
            size={'extraSmall'}
            onPress={() => {
              for (const update of unmatchedUpdates) {
                onChange({ ...update, mode: ClassSyncRosterActionMode.Import })
              }
            }}
          >
            <Text color={'secondary'} level={'xs'}>
              Import All
            </Text>
          </Button>
          <Button
            variant={'chromeless'}
            size={'extraSmall'}
            onPress={() => {
              for (const update of unmatchedUpdates) {
                onChange({ ...update, mode: null })
              }
            }}
          >
            <Text color={'secondary'} level={'xs'}>
              Ignore All
            </Text>
          </Button>
        </HSpaced>
      ) : null}
    </VSpaced>
  )
}

const RightBorder = styled.div`
  background: ${s.color('text')};
  height: 1px;
  width: 22px;
`
