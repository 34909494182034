import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Block, BlockSpin, HSpaced, NotFound } from '@thesisedu/web'
import { Button, Form } from 'antd'
import React from 'react'

import { CampaignForm } from './CampaignForm'
import { useTrialRequestCampaignQuery, useUpdateTrialRequestCampaignMutation } from '../schema'

export interface EditCampaignProps {
  campaignId: string
  actions?: React.ReactElement
}
export function EditCampaign({ actions, campaignId }: EditCampaignProps) {
  const [isDirty, setIsDirty] = React.useState(false)
  const { data, loading } = useTrialRequestCampaignQuery({
    variables: {
      id: campaignId,
    },
  })
  const [save, { loading: saveLoading }] = useUpdateTrialRequestCampaignMutation({
    onError: onMutationError('There was an error saving your campaign.'),
  })
  const campaign = data?.node?.__typename === 'TrialRequestCampaign' ? data.node : undefined
  const [form] = Form.useForm()
  React.useEffect(() => {
    form.setFieldsValue(campaign)
    setIsDirty(false)
  }, [campaign])

  if (campaign) {
    return (
      <>
        <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
          <HSpaced justify={'flex-end'}>
            {actions}
            <Button
              type={'primary'}
              loading={saveLoading}
              onClick={() => form.submit()}
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </HSpaced>
        </Block>
        <CampaignForm
          campaign={campaign}
          form={form}
          onValuesChange={() => setIsDirty(true)}
          onFinish={values => {
            save({
              variables: {
                input: {
                  id: campaignId,
                  patch: values,
                },
              },
            })
          }}
        />
      </>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'We could not find that campaign.'} />
  }
}
