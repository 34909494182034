import { useResource } from '@thesisedu/feature-react'
import {
  LoadingStep as UsersLoadingStep,
  LoadingStepProps as UsersLoadingStepProps,
  ProviderError as UsersProviderError,
  ProviderErrorProps as UsersProviderErrorProps,
} from '@thesisedu/feature-users-react'
import React from 'react'

import { ClassSyncProviderResource } from '../../types'

export interface LoadingStepProps extends Omit<UsersLoadingStepProps, 'providerLogo'> {
  providerIdentifier: string
}
export function LoadingStep({ providerIdentifier, ...rest }: LoadingStepProps) {
  const resource = useResource<ClassSyncProviderResource>('ClassSyncProvider', providerIdentifier)
  if (resource) {
    return <UsersLoadingStep {...rest} providerLogo={resource.logos.square} />
  } else return null
}

export interface ProviderErrorProps extends Omit<UsersProviderErrorProps, 'providerLogo'> {
  providerIdentifier: string
}
export function ProviderError({ providerIdentifier, ...rest }: ProviderErrorProps) {
  const resource = useResource<ClassSyncProviderResource>('ClassSyncProvider', providerIdentifier)
  if (resource) {
    return <UsersProviderError {...rest} providerLogo={resource.logos.square} />
  } else return null
}
