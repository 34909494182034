import { onMutationError } from '@thesisedu/feature-apollo-react'
import { message, Button, Modal } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { useBulkUpdateAssignmentsInClassMutation } from './schema'

export interface BulkUpdateAssignmentsInClassButtonProps extends Partial<ButtonProps> {
  classId: string
}
export function BulkUpdateAssignmentsInClassButton({
  classId,
  ...props
}: BulkUpdateAssignmentsInClassButtonProps) {
  const [update, { loading }] = useBulkUpdateAssignmentsInClassMutation({
    onError: onMutationError('There was an error bulk-updating the assignments in this class.'),
    onCompleted: () => {
      message.success('Assignments updated.')
    },
    refetchQueries: ['assignment', 'classAssignments'],
  })
  return (
    <Button
      loading={loading}
      children={'Bulk-Update All Assignments'}
      {...props}
      onClick={() => {
        Modal.confirm({
          title: 'Are you sure?',
          content: (
            <p>
              <strong>This is a potentially destructive action. Please read this carefully.</strong>
              &nbsp;This will <em>at least</em> assign all students currently inside your class to
              every assignment you've already created. There is no way to undo these changes. If you
              are not sure what to do, contact support.
            </p>
          ),
          okButtonProps: { danger: true },
          width: 600,
          okText: 'Yes, bulk-update all assignments',
          onOk: () =>
            update({
              variables: {
                input: {
                  id: classId,
                },
              },
            }),
        })
      }}
    />
  )
}
