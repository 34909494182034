import { Format } from '@thesisedu/feature-utils'
import pluralize from 'pluralize'

export const DATE_FORMATS = {
  FULL: 'MMMM D, YYYY [at] h:mm a',
  TEXT: 'MM/DD/YYYY h:mm A',
  TEXT_SHORT: 'MM/DD/YYYY',
  DATE: 'MMMM D, YYYY',
  MONTH_DAY: 'MMMM D',
  MONTH_DAY_SHORT: 'MMM D',
  TIME: 'h:mm a',
}

export interface AbbreviatePluralOpts {
  threshold?: number
  showWord?: boolean
}

const ABBREVIATE_THRESHOLD = 1000
export const abbreviatePlural = (
  word: string,
  number: number,
  { showWord, threshold = ABBREVIATE_THRESHOLD }: AbbreviatePluralOpts = {},
): string => {
  if (number >= threshold) {
    if (showWord) {
      return `${Format.number(number, 'abbreviated')} ${pluralize.plural(word)}`
    } else {
      return number.toString()
    }
  } else {
    if (showWord) {
      return pluralize(word, number, true)
    } else {
      return number.toString()
    }
  }
}
