import { useApolloClient } from '@apollo/client'
import { LicenseInvalidContext } from '@thesisedu/feature-licenses-react'
import { Modal } from 'antd'
import React from 'react'

import { debug } from '../log'
import {
  CanCreateMediaDocument,
  CanCreateMediaQuery,
  CanCreateMediaQueryVariables,
} from '../schema'

export const usePrepareForRecording = (
  classId?: string,
  onPrepare?: () => Promise<void> | void,
) => {
  const { showLicenseInvalid } = React.useContext(LicenseInvalidContext)
  const client = useApolloClient()
  return async () => {
    try {
      const result = await client.query<CanCreateMediaQuery, CanCreateMediaQueryVariables>({
        query: CanCreateMediaDocument,
        variables: { classId },
        fetchPolicy: 'network-only',
      })
      if (onPrepare && result?.data?.canCreateMedia) {
        debug('can create media and onPrepare specified, preparing for recording...')
        await onPrepare()
      }
      return !!result?.data?.canCreateMedia
    } catch (error: any) {
      const codes = (error.graphQLErrors || []).map((err: any) => err.extensions?.code)
      if (codes.includes('LICENSE_INVALID_ERROR')) {
        if (classId) {
          showLicenseInvalid('Cannot Start Recording', classId)
        } else {
          Modal.error({
            title: 'Cannot Start Recording',
            content:
              'It looks like you have exceeded your quota for private recordings. Either delete ' +
              'some practices or upgrade your plan in the mobile app to record more.',
          })
        }
      }
      return false
    }
  }
}
