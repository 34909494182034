import { Button, Tooltip } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { useGroupsContext } from './GroupsContext'

export interface DeleteGroupButtonProps {
  groupKey: string
}
export function DeleteGroupButton({ groupKey }: DeleteGroupButtonProps) {
  const { groups, onChange } = useGroupsContext(true)
  return (
    <Tooltip title={'Delete Group'}>
      <Button
        variant={'ghost'}
        status={'danger'}
        size={'small'}
        icon={<Trash />}
        onPress={() => {
          const { [groupKey]: current, ...remaining } = groups
          onChange(remaining)
        }}
      />
    </Tooltip>
  )
}
