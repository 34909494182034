import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateClassMutation as useMutation } from '../schema'

export const useCreateClassMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error creating that class.'),
    refetchQueries: ['minimalTeacherClasses'],
    awaitRefetchQueries: true,
    ...opts,
  })
}
