import { DeepLinkHandler } from '@thesisedu/feature-react'
import { styled } from '@thesisedu/web'
import { message } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface DeepLinkProps {
  universalPath: string
  onClick?: () => void
}
export function DeepLink({
  universalPath,
  onClick,
  children,
}: React.PropsWithChildren<DeepLinkProps>) {
  const navigate = useNavigate()
  const [didClick, setDidClick] = React.useState(false)
  if (didClick) {
    return (
      <DeepLinkHandler
        url={universalPath}
        onAccessDenied={() => {
          message.error('You do not have access to that content.')
          setDidClick(false)
        }}
        onFound={path => {
          setDidClick(false)
          if (onClick) {
            onClick()
          }
          navigate(path)
        }}
        renderNotFound={() => {
          message.error('We could not find that content!')
          setDidClick(false)
          return <span>Not found!</span>
        }}
        renderLoading={() => (
          <LoadingContent
            href={`/${universalPath}`}
            onClick={e => {
              e.preventDefault()
              setDidClick(true)
            }}
            children={'Loading...'}
          />
        )}
      />
    )
  } else {
    return (
      <a
        href={`/${universalPath}`}
        onClick={e => {
          e.preventDefault()
          setDidClick(true)
        }}
        children={children}
      />
    )
  }
}

const LoadingContent = styled.a`
  color: ${props => props.theme['@text-color-secondary']};
  pointer-events: none;
`
