import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm, message } from 'antd'
import React from 'react'

import { useDeleteDirectMessageThreadMutation } from '../queries/useDeleteDirectMessageThreadMutation'

export interface DeleteThreadButtonProps {
  threadId: string
  onCompleted?: () => void
}
export function DeleteThreadButton({ threadId, onCompleted }: DeleteThreadButtonProps) {
  const [del, { loading }] = useDeleteDirectMessageThreadMutation({
    onCompleted: () => {
      message.success('Thread deleted successfully.')
      if (onCompleted) {
        onCompleted()
      }
    },
  })

  return (
    <Popconfirm
      title={'Are you sure?'}
      okButtonProps={{ danger: true }}
      okText={'Yes, delete'}
      onConfirm={() => del({ variables: { id: threadId } })}
      cancelText={'No, keep'}
      placement={'bottomRight'}
    >
      <Button size={'small'} danger icon={<DeleteOutlined />} loading={loading} />
    </Popconfirm>
  )
}
