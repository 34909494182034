import React from 'react'
import { FieldValues } from 'react-hook-form'

import { Form, FormProps, getFormProps } from './Form'
import { useStatePropFallback } from '../../utils/useStatePropFallback'
import Modal, * as Modal$ from '../Modal'

export interface FormModalProps<Values extends FieldValues>
  extends Omit<FormProps<Values>, 'style' | 'onFinish'>,
    Modal$.ModalProps {
  /**
   * The modal will automatically close once this task is complete, if passed.
   * If false is returned instead, the modal will not automatically close.
   */
  onFinish?: (values: Values) => boolean | Promise<boolean> | void | Promise<void>
  /**
   * The value to reset the form to whenever the modal first opens.
   */
  defaultValue?: Values
}
export function FormModal<Values extends FieldValues>({
  defaultValue,
  ...props
}: FormModalProps<Values>) {
  const { formProps, rest } = getFormProps(props)
  const [visible, setVisible] = useStatePropFallback(props.visible, props.onVisibleChange, false)
  React.useEffect(() => {
    if (visible) {
      formProps.form.reset(defaultValue)
    }
  }, [visible])
  return (
    <Modal {...rest} visible={visible} onVisibleChange={setVisible}>
      <Form
        {...formProps}
        onFinish={async (...args) => {
          if (props.onFinish) {
            const result = await props.onFinish(...args)
            if (result !== false) setVisible(false)
          } else {
            setVisible(false)
          }
        }}
      >
        {rest.children}
      </Form>
    </Modal>
  )
}
