import { Format } from '@thesisedu/feature-utils'
import { styled, s, HSpaced, VSpaced, Text, Block } from '@thesisedu/ui'
import React from 'react'

import { StandardSetListItem } from './StandardSetListItem'
import { StandardSetSelectList } from './StandardSetSelectList'
import { StandardSetTreeList } from './StandardSetTreeList'
import { SuggestionsList } from './SuggestionsList'
import { TagListItem } from './TagListItem'
import { StandardSetFragment, TagFragment } from '../schema'

export interface SelectedStandardSet extends Pick<StandardSetFragment, 'id' | 'name'> {}
export type InternalStandardSelectValue = Pick<TagFragment, 'id' | 'name' | 'type'>
export interface StandardSelectValue extends InternalStandardSelectValue {
  standardSet: SelectedStandardSet
}
export interface StandardSelectProps {
  value?: StandardSelectValue[]
  onChange?: (value?: StandardSelectValue[]) => void
  suggestions?: StandardSelectValue[]
}
export function StandardSelect({ value, onChange, suggestions }: StandardSelectProps) {
  const [selectedStandardSet, setSelectedStandardSet] = React.useState<
    SelectedStandardSet | undefined
  >(value?.[0]?.standardSet)
  const [selectedLevels, setSelectedLevels] = React.useState<string[]>([])
  React.useEffect(() => {
    setSelectedLevels([])
  }, [selectedStandardSet])
  let content
  if (selectedStandardSet) {
    content = (
      <>
        <StandardSetListItem
          standardSet={selectedStandardSet}
          onClick={() => setSelectedStandardSet(undefined)}
          right={null}
        />
        <StandardSetTreeList
          standardSetId={selectedStandardSet.id}
          selectedLevels={selectedLevels}
          onSelectedLevelsChange={setSelectedLevels}
          value={value}
          onChange={newValue => {
            onChange?.(newValue?.map(v => ({ ...v, standardSet: selectedStandardSet })))
          }}
        />
      </>
    )
  } else {
    content = (
      <VSpaced space={'xs'} style={{ overflowY: 'auto' }}>
        {suggestions?.length ? (
          <SuggestionsList suggestions={suggestions} value={value} onChange={onChange} />
        ) : null}
        <StandardSetSelectList onChange={setSelectedStandardSet} />
      </VSpaced>
    )
  }

  return (
    <VSpaced space={'xs'} style={{ height: 350 }} data-testid={'StandardSelect'}>
      <Container children={content} style={{ flex: 1, minHeight: 0 }} />
      {value?.length ? (
        <Container
          style={{ height: 100, overflowY: 'auto' }}
          data-testid={'StandardSelect Selected'}
        >
          <Block paddingHorizontal={'xs'} paddingTop={'xs'}>
            <Text level={'s'} color={'secondary'}>
              {Format.plural('Selected Standard', value.length)}
            </Text>
          </Block>
          {value.map(item => {
            return (
              <TagListItem
                key={item.id}
                tag={item}
                value
                hasChildren={false}
                onChange={selected => {
                  if (!selected) {
                    onChange?.(value.filter(v => v.id !== item.id))
                  }
                }}
              />
            )
          })}
        </Container>
      ) : null}
    </VSpaced>
  )
}

const Container = styled.div`
  border: solid 1px ${s.color('border')};
  border-radius: ${s.var('radii.1')};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
export const ListItem = styled(HSpaced)`
  padding: ${s.size('xs')};
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: solid 1px ${s.color('subtleBorder')};
  }
`
