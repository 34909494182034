import { Body, DATE_FORMATS, HSpaced, List, Space } from '@thesisedu/react'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

import { AssignmentProperty } from './AssignmentProperty'
import { FullAssignment } from '../grading/types'
import { AssignmentCountdownTimer } from '../questions/AssignmentCountdownTimer'

export interface AssignmentPropertiesProps {
  assignment: FullAssignment
  includeCountdown?: boolean
}
export function AssignmentProperties({ assignment, includeCountdown }: AssignmentPropertiesProps) {
  return (
    <List separated>
      {[
        assignment.openAt ? (
          <AssignmentProperty
            key={'opens'}
            name={'Opens at'}
            value={moment(assignment.openAt).format(DATE_FORMATS.FULL)}
          />
        ) : null,
        assignment.dueAt ? (
          <AssignmentProperty
            key={'due'}
            name={'Due at'}
            value={moment(assignment.dueAt).format(DATE_FORMATS.FULL)}
          />
        ) : null,
        <AssignmentProperty
          key={'maxAttempts'}
          name={'Max Attempts'}
          value={pluralize('attempt', assignment.maxAttempts, true)}
        />,
        assignment.timeLimit ? (
          <AssignmentProperty
            key={'timeLimit'}
            name={'Time Limit'}
            value={
              includeCountdown && assignment.startedAt ? (
                <HSpaced>
                  <Body>{pluralize('minute', Math.ceil(assignment.timeLimit / 60), true)}</Body>
                  <Space />
                  <Body>
                    <AssignmentCountdownTimer
                      onTimerReached={() => {}}
                      timeLimit={assignment.timeLimit}
                      createdAt={assignment.startedAt}
                    />
                  </Body>
                </HSpaced>
              ) : (
                pluralize('minute', Math.ceil(assignment.timeLimit / 60), true)
              )
            }
          />
        ) : null,
      ].filter(Boolean)}
    </List>
  )
}
