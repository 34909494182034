import { DraggableNode } from '@thesisedu/feature-widgets-core'
import { $getNodeByKey, LexicalEditor, LexicalNode } from 'lexical'

import { TargetResult } from './getBlockElement'
import { getBeforeAfter } from './targetLine'

export function modifyDrop(
  editor: LexicalEditor,
  draggedNodes: LexicalNode[],
  clientY: number,
  targetResult: TargetResult,
) {
  let targetBlockElement = targetResult.element
  let targetKey = targetResult.key
  let rect = targetBlockElement.getBoundingClientRect()
  let beforeAfter = getBeforeAfter(clientY, rect)
  const firstDragged = draggedNodes[0]
  if (targetResult && firstDragged?.modifyDropDOMElement) {
    const result = firstDragged.modifyDropDOMElement(
      editor,
      targetBlockElement,
      targetResult.key,
      beforeAfter,
    )
    if (result) {
      targetBlockElement = result.element
      beforeAfter = result.beforeAfter
      targetKey = result.key
      rect = targetBlockElement.getBoundingClientRect()
    }
  }

  const targetNode = $getNodeByKey<DraggableNode>(targetKey)
  if (targetNode?.modifyDropOnDOMElement) {
    const result = targetNode.modifyDropOnDOMElement(
      editor,
      targetBlockElement,
      draggedNodes,
      beforeAfter,
    )
    if (result) {
      targetBlockElement = result.element
      beforeAfter = result.beforeAfter
      targetKey = result.key
      rect = targetBlockElement.getBoundingClientRect()
    }
  }

  return { targetBlockElement, beforeAfter, rect, targetKey }
}
