import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { ClassRoute } from './ClassRoute'
import { ClassesRoot } from './ClassesRoot'

export function StudentClasses() {
  useBreadcrumb({ title: 'Classes' })
  return (
    <Routes>
      <Route path={'/:classId/*'} element={<ClassRoute group={'STUDENT'} />} />
      <Route path={'/'} element={<ClassesRoot group={'STUDENT'} />} />
    </Routes>
  )
}
