import { Body, BodyLarge, FontWeight, Space, HSpaced, Div } from '@thesisedu/react'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { QuestionTextMediaView } from './QuestionTextMediaField'

export interface QuestionHeaderProps {
  name: string
  index: number
  totalQuestions: number
  rightContent?: React.ReactElement
  afterContent?: React.ReactElement
}
export function QuestionHeader({
  afterContent,
  name,
  index,
  totalQuestions,
  rightContent,
}: QuestionHeaderProps) {
  return (
    <VSpaced>
      <HSpaced align={'flex-start'}>
        <Div>
          <Body color={'@text-color-secondary'}>
            Question {index + 1} of {totalQuestions}
          </Body>
          <BodyLarge weight={FontWeight.Bold} useDiv>
            <QuestionTextMediaView value={name} />
          </BodyLarge>
        </Div>
        <Space />
        {rightContent}
      </HSpaced>
      {afterContent}
    </VSpaced>
  )
}
