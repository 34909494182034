import { FilterButton, FilterMenuItem } from '@thesisedu/react'
import { Packages } from '@thesisedu/react/icons'
import React from 'react'

import { useClassAssignmentCategoriesQuery } from './schema'

export interface AssignmentCategoryFilterProps {
  classId: string
  value?: string
  onChange: (value?: string) => void
}

function useAssignmentCategoryFilter({ classId, value, onChange }: AssignmentCategoryFilterProps) {
  const { data, loading } = useClassAssignmentCategoriesQuery({ variables: { classId } })
  const categories =
    data?.node?.__typename === 'Class'
      ? data.node.assignmentCategories.edges.map(edge => edge.node) || []
      : [] || []
  if (!categories?.length && !loading) return null

  return {
    value,
    label: 'Category',
    disabled: loading,
    options: categories.map(cat => ({ label: cat.name, value: cat.id })),
    onChange,
  }
}

export function AssignmentCategoryFilter(props: AssignmentCategoryFilterProps) {
  const buttonProps = useAssignmentCategoryFilter(props)
  if (buttonProps) {
    return <FilterButton<string> {...buttonProps} />
  } else return null
}

export interface AssignmentCategoryFilterMenuProps extends AssignmentCategoryFilterProps {
  [key: string]: any
}
export function AssignmentCategoryFilterMenu({
  classId,
  onChange,
  value,
  ...rest
}: AssignmentCategoryFilterMenuProps) {
  const props = useAssignmentCategoryFilter({ classId, onChange, value })
  if (props) {
    return <FilterMenuItem<string> {...props} icon={<Packages />} {...rest} />
  } else return null
}
