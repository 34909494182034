import { useFeatureRoot } from '@thesisedu/feature-react'
import { Text, VSpaced } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { getLearnGradeSync } from './learnGradeSync'
import { getSelectProvider } from './selectProvider'
import { ClassSyncProviderFeature, SiteClassSyncProviderFragment } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, getStepFactory } from '../types'

export interface RosterCompleteContext {
  provider: SiteClassSyncProviderFragment
}
export default {
  size: {
    width: 550,
    height: 600,
    noMaxHeight: true,
  },
  centerVertically: true,
  Component({ context, transition }) {
    const appName = useFeatureRoot().appOptions.name
    const supportsGrades =
      context.provider.supportedFeatures?.includes(ClassSyncProviderFeature.GradeMaterialSync) &&
      context.provider.enabledFeatures?.includes(ClassSyncProviderFeature.GradeMaterialSync)
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        state={'success'}
        title={"Linked! What's next?"}
        message={
          <>
            Your {appName} and {context.provider.name} classes are now linked. Select from the
            options below to learn more about how to use {appName} with {context.provider.name}.
          </>
        }
        action={
          <VSpaced space={'s'} align={'center'} style={{ textAlign: 'center' }}>
            {supportsGrades ? (
              <div>
                <a
                  href={'#'}
                  onClick={e => {
                    e.preventDefault()
                    transition(getLearnGradeSync(context))
                  }}
                >
                  <Text color={'primary'} weight={'bold'}>
                    Learn how to sync grades and content with {context.provider.name}{' '}
                    <ArrowRight style={{ display: 'inline', transform: 'translateY(0.2em)' }} />
                  </Text>
                </a>
                <Text level={'s'} color={'secondary'}>
                  Automatically sync {appName} assignments and grades to your class in{' '}
                  {context.provider.name}.
                </Text>
              </div>
            ) : null}
            <div>
              <a
                href={'#'}
                onClick={e => {
                  e.preventDefault()
                  transition(getSelectProvider({}))
                }}
              >
                <Text color={'text'} weight={'bold'}>
                  Connect with another LMS{' '}
                  <ArrowRight style={{ display: 'inline', transform: 'translateY(0.2em)' }} />
                </Text>
              </a>
              <Text level={'s'} color={'secondary'}>
                Select this option if you roster using {context.provider.name}, but your gradebook
                lives in another LMS.
              </Text>
            </div>
            <a
              href={'#'}
              onClick={e => {
                e.preventDefault()
                transition(null)
              }}
            >
              <Text color={'secondary'} top={'m'} level={'s'}>
                I don't want to learn how to sync grades and content right now.
              </Text>
            </a>
          </VSpaced>
        }
      />
    )
  },
} satisfies SetupStep<RosterCompleteContext>
export const getRosterComplete = getStepFactory<RosterCompleteContext>('rosterComplete')
