import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { styled, ProgressOverlay, useResizeObserver } from '@thesisedu/web'
import { useScrollIfRequired } from '@thesisedu/web/react-dnd'
import { Button } from 'antd'
import React from 'react'
import FullScreen from 'react-full-screen'

import { Background } from './Background'
import { DragLayer } from './DragLayer'
import { DraggableView } from './DraggableView'
import { DropAreaView } from './DropAreaView'
import { GraphicDragDropContext } from './GraphicDragDropContext'
import { Container, FullscreenContainer } from './styled'
import { GraphicDragDropConfig, GraphicDragDropValue } from './types'

interface NumberMap {
  [entityId: string]: number
}
interface Loaded {
  current: NumberMap
  total: NumberMap
}
function getLoadedSummary(loaded: Loaded): { current: number; total: number } {
  const current = Object.keys(loaded.current).reduce((acc, key) => acc + loaded.current[key], 0)
  const total = Object.keys(loaded.total).reduce((acc, key) => acc + loaded.total[key], 0)
  return { current, total }
}

export interface GraphicDragDropViewProps {
  config: GraphicDragDropConfig
  value?: GraphicDragDropValue
  onChange?: (value?: GraphicDragDropValue) => void
  showAnswers?: boolean
  disabled?: boolean
}
export function GraphicDragDropView({
  showAnswers: _showAnswers,
  config,
  value: _value,
  disabled,
  onChange,
}: GraphicDragDropViewProps) {
  const ref = React.useRef<HTMLElement>(null)
  const { width, height } = useResizeObserver({
    ref,
  })
  const [fullscreen, setFullscreen] = React.useState(false)
  const [onCollect, onDragEnd] = useScrollIfRequired(ref)
  const [size, setSize] = React.useState<{ width: number; height: number } | undefined>(undefined)
  const [loaded, setLoaded] = React.useState<Loaded>({ current: {}, total: {} })
  const loadedSummary = getLoadedSummary(loaded)
  const hasAnswers = !!config.dragDropPairs
  const showAnswers = hasAnswers && _showAnswers
  let value = _value || {}
  if (showAnswers && Object.keys(value).length === 0 && config.dragDropPairs) {
    value = config.dragDropPairs.reduce<GraphicDragDropValue>((acc, pair) => {
      return { ...acc, [pair.draggableId]: pair.dropAreaId }
    }, {})
  }
  const isComplete =
    Object.keys(value).filter(k => value[k]).length >= (config.draggables?.length || 0)
  const renderedDraggables =
    config.draggables?.reduce<{ [key: string]: React.ReactElement }>((acc, draggable) => {
      return {
        ...acc,
        [draggable.id]: (
          <DraggableView
            draggable={draggable}
            key={draggable.id}
            onCollect={onCollect}
            onDrop={target => {
              onDragEnd()
              if (onChange) {
                const { [draggable.id]: current, ...without } = value
                onChange({ ...without, [draggable.id]: target })
              }
            }}
            onLoad={(current, total) => {
              setLoaded(l => ({
                current: {
                  ...l.current,
                  [draggable.id]: current,
                },
                total: {
                  ...l.total,
                  [draggable.id]: total,
                },
              }))
            }}
          />
        ),
      }
    }, {}) || {}
  return (
    <FullScreen enabled={fullscreen} onChange={setFullscreen}>
      <FullscreenContainer enabled={fullscreen}>
        <Container
          ref={ref}
          className={showAnswers || disabled ? 'read-only' : ''}
          width={size?.width}
          height={size?.height}
        >
          <GraphicDragDropContext.Provider value={{ width, height }}>
            <FullscreenButtonContainer>
              <Button
                icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                onClick={() => setFullscreen(f => !f)}
              />
            </FullscreenButtonContainer>
            <Background
              background={config.background}
              isGrayscale={!showAnswers && !isComplete}
              onSize={(width, height) => {
                setSize({ width, height })
              }}
              onLoad={(current, total) => {
                setLoaded(l => ({
                  current: {
                    ...l.current,
                    background: current,
                  },
                  total: {
                    ...l.total,
                    background: total,
                  },
                }))
              }}
            />
            {config.dropAreas?.map(dropArea => {
              const draggableIds = Object.keys(value || {}).filter(key => {
                return value![key] === dropArea.id
              })
              const draggables = config.draggables?.filter(d => draggableIds.includes(d.id))
              const validDraggableIds =
                config.dragDropPairs
                  ?.filter(ddp => ddp.dropAreaId === dropArea.id)
                  .map(ddp => ddp.draggableId) || []
              return (
                <DropAreaView
                  dropArea={dropArea}
                  key={dropArea.id}
                  draggables={draggables?.map(draggable => ({
                    draggable,
                    correct: showAnswers ? validDraggableIds.includes(draggable.id) : undefined,
                    child: React.cloneElement(renderedDraggables[draggable.id], {
                      isDropped: true,
                    }),
                  }))}
                />
              )
            })}
            {config.draggables
              ?.filter(d => {
                return !config.dropAreas?.find(drop => drop.id === value?.[d.id])
              })
              .map(draggable => renderedDraggables[draggable.id])}
            <DragLayer draggables={config.draggables || []} />
            <ProgressOverlay current={loadedSummary.current} total={loadedSummary.total} />
          </GraphicDragDropContext.Provider>
        </Container>
      </FullscreenContainer>
    </FullScreen>
  )
}

const FullscreenButtonContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-s']};
  right: ${props => props.theme['@size-s']};
`
