import { onMutationError } from '@thesisedu/feature-apollo-react'
import { CLASS_STUDENTS_REFETCH_QUERIES } from '@thesisedu/feature-classes-react'

import { useGenerateStudentsMutation as useMutation } from '../schema'

export const useGenerateStudentsMutation: typeof useMutation = opts =>
  useMutation({
    refetchQueries: CLASS_STUDENTS_REFETCH_QUERIES,
    awaitRefetchQueries: true,
    ...opts,
    onError: err => {
      onMutationError('There was an error generating your students.')(err)
      if (opts?.onError) {
        opts.onError(err)
      }
    },
  })
