import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Dropdown } from '@thesisedu/ui'
import { RefreshDouble } from '@thesisedu/ui/icons'
import React from 'react'

import {
  useForceResyncAssignmentSyncMutation,
  useForceResyncMaterialSyncMutation,
} from '../../schema'

/**
 * We need this to prevent teachers from spamming the dropdown button
 * accidentally, which will trigger a lot of background jobs on our end.
 * So we disable the button either for a few minutes, or until they
 * refresh the page.
 */
const disabledSyncIds = new Set<string>()
const DISABLE_TIMEOUT_MS = 1000 * 60 * 2 // 2 minutes

export interface ForceSyncActionProps {
  id: string
  typename: 'AssignmentSync' | 'MaterialSync'
}
export function ForceSyncAction({ id, typename }: ForceSyncActionProps) {
  const [resyncAssignment, { loading: assignmentLoading }] = useForceResyncAssignmentSyncMutation({
    onError: onMutationError('There was an error resyncing this assignment.'),
  })
  const [resyncMaterial, { loading: materialLoading }] = useForceResyncMaterialSyncMutation({
    onError: onMutationError('There was an error resyncing this material.'),
  })
  const loading = assignmentLoading || materialLoading
  return (
    <Dropdown.Item
      loading={loading}
      disabled={disabledSyncIds.has(id)}
      icon={<RefreshDouble />}
      children={'Force Resync'}
      onClick={async () => {
        if (!disabledSyncIds.has(id)) {
          disabledSyncIds.add(id)
          setTimeout(() => {
            disabledSyncIds.delete(id)
          }, DISABLE_TIMEOUT_MS)
          const variables = { input: { id } }
          if (typename === 'AssignmentSync') {
            await resyncAssignment({ variables })
          } else if (typename === 'MaterialSync') {
            await resyncMaterial({ variables })
          } else {
            throw new Error('Invalid typename in ForceSyncAction')
          }
        }
      }}
    />
  )
}
