import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isHeadingNode, HeadingNode, HeadingTagType } from '@lexical/rich-text'
import { mergeRegister } from '@lexical/utils'
import { normalizeHeading } from '@thesisedu/feature-widgets-core'
import { $getNodeByKey, TextNode } from 'lexical'
import React from 'react'

import { $getDOMNode } from '../../utils/getDomNode'

export interface HeadingIdentifiersPluginProps {
  levels?: HeadingTagType[]
}
export function HeadingIdentifiersPlugin({ levels = ['h1'] }: HeadingIdentifiersPluginProps) {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    function $updateHeading(heading: HeadingNode) {
      if (levels.includes(heading.getTag())) {
        const domElement = $getDOMNode(editor, heading.getKey())
        if (domElement) {
          domElement.setAttribute('id', normalizeHeading(heading.getTextContent()))
        }
      }
    }

    return mergeRegister(
      editor.registerMutationListener(HeadingNode, nodes => {
        editor.getEditorState().read(() => {
          for (const [key] of nodes) {
            const node = $getNodeByKey<HeadingNode>(key)
            if (node) $updateHeading(node)
          }
        })
      }),
      editor.registerMutationListener(TextNode, nodes => {
        editor.getEditorState().read(() => {
          for (const [key] of nodes) {
            const parent = $getNodeByKey<TextNode>(key)?.getParent()
            if ($isHeadingNode(parent)) {
              $updateHeading(parent)
            }
          }
        })
      }),
    )
  }, [editor])

  return null
}
