import { findSegment } from '@thesisedu/feature-course-types'
import { toGlobalId } from '@thesisedu/feature-utils'
import { pick, omit } from 'lodash'

import { convertChildSegments } from './convertChildSegments'
import { INPUT_FIELDS } from '../constants'
import { CourseEditorWriteContextValue } from '../contexts/CourseEditorContext'
import { SegmentType } from '../schema'
import { Segment, CreateOrUpdateSegment } from '../types'

export const getOnCreateSegment = (
  rootSegment: Segment,
  createOrUpdateSegment: CreateOrUpdateSegment,
  courseVersionId: string,
): CourseEditorWriteContextValue['onCreateSegment'] => {
  return async (segment, parentSegmentId) => {
    const parentSegment = findSegment([rootSegment], parentSegmentId)
    if (!parentSegment) {
      throw new Error('Cannot create a segment, no parent segment found')
    }
    const createResult = await createOrUpdateSegment({
      variables: {
        input: {
          ...pick(segment, INPUT_FIELDS),
          id: toGlobalId('Segment', segment.id),
        },
      },
    })
    const builtResult = createResult?.data?.createOrUpdateSegment.segment.built
    if (builtResult) {
      const maxWeight = (parentSegment.childSegments || []).reduce((acc, segment) => {
        return Math.max(acc, segment.weight || 0)
      }, 0)
      const updated = convertChildSegments({
        ...parentSegment,
        config: omit(parentSegment.config || {}, ['childSegments']),
        id: parentSegmentId,
        childSegments: (parentSegment.childSegments || []).concat({
          ...builtResult,
          weight: maxWeight + 1,
        }),
      })
      // TODO: I'm not sure why we have to call this again...
      await createOrUpdateSegment({
        variables: {
          input: {
            ...pick({ ...updated, updateCourseVersionId: courseVersionId }, INPUT_FIELDS),
            type: updated.type as SegmentType,
            id: toGlobalId('Segment', updated.id),
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createOrUpdateSegment: {
            __typename: 'SegmentPayload',
            segment: {
              __typename: 'Segment',
              id: toGlobalId('Segment', updated.id),
              built: updated,
            },
          },
        },
      })
    } else {
      console.warn('could not find build result to continue creating segment')
    }
  }
}
