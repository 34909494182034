import { WebRoutesHook } from '@thesisedu/feature-web'
import React from 'react'
import { Route } from 'react-router-dom'

import { NotificationPreferencesReactFeature } from '../../NotificationPreferencesReactFeature'
import { JWTPreferencesPage } from '../../web/JWTPreferencesEditor'

export default function (feature: NotificationPreferencesReactFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [
      <Route key={'notifications'} path={'notifications'} element={<JWTPreferencesPage />} />,
      ...routes,
    ]
  })
}
