import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'

export function validateGroups(groups: GroupsConfiguration): GroupsConfiguration {
  const newGroups: GroupsConfiguration = {}
  for (const key of Object.keys(groups)) {
    const group = groups[key]
    let leader = group.leader
    if (!leader && group.students.length) leader = group.students[0]
    if (!group.students.some(s => s === leader)) leader = group.students[0]
    newGroups[key] = {
      ...groups[key],
      leader,
    }
  }

  return newGroups
}
