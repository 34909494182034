import { QuestionCircleOutlined } from '@ant-design/icons'
import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import {
  CoursesHooks,
  OutlineContextsPayload,
  SegmentProgressPayload,
  SegmentProgressContext,
  SegmentTypeResource,
  SegmentEnableRefetchQueriesContext,
  SegmentEnableRefetchQueriesPayload,
  SegmentDescriptionsPayload,
  SegmentDescriptionsContext,
  SegmentRightContentPayload,
  SegmentRightContentContext,
  SegmentInfoPopoverItemsPayload,
  SegmentInfoPopvoerItemsContext,
} from '@thesisedu/feature-courses-react'
import { useSegmentDecoration } from '@thesisedu/feature-courses-react/dist/contexts/SegmentDecorationsContext'
import { isInNode, ReactFeature } from '@thesisedu/feature-react'
import React from 'react'

import { AssignmentForSegmentDocument, SegmentType } from './schema'
import { SegmentDecorationWithDueDate } from './segments/AssignmentSegmentDescription'
import { ASSIGNMENT_KIND_TAG, CourseAssignmentsReactOptions } from './types'

export class CourseAssignmentsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-course-assignments-react'
  static path = ''
  static requires: string[] = []
  public options!: CourseAssignmentsReactOptions

  constructor(opts: object, deps: FeatureDependencies) {
    super(opts, deps)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'SegmentDecoration', fragment => {
      let result = addField(fragment, 'assignmentDueDate')
      result = addField(result, 'calculation')
      return addField(result, 'assignmentId')
    })
  }

  public reactResources() {
    const { defaultNavigationKey, getNavigation } = require('./segments/getNavigation')
    const { getNavigationFooter } = require('./segments/getNavigationFooter')
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Assignment',
      renderEdit: require('./segments/assignment/AssignmentEdit').AssignmentEdit,
      renderContent: require('./segments/assignment/AssignmentContent').AssignmentContent,
      renderSimpleEdit: require('./segments/assignment/AssignmentSimpleEdit').AssignmentSimpleEdit,
      additionalTagTypes: [{ type: ASSIGNMENT_KIND_TAG, label: 'Assignment Kind' }],
      defaultNavigationKey: defaultNavigationKey.bind(this),
      getNavigation: getNavigation.bind(this),
      getNavigationFooter: getNavigationFooter.bind(this),
      hasChildren: false,
      icon: QuestionCircleOutlined,
      allowCustom: true,
      defaults: {
        name: 'Assignment 1',
        config: {
          label: null,
          rubricType: null,
          templateId: null,
          teacherContent: [],
          studentContent: [],
          days: 0,
        },
      },
    })
    const { addSegmentPresets } = require('./segments/presets')
    addSegmentPresets(this)
    this.hookManager.registerMutateHook<OutlineContextsPayload>(
      CoursesHooks.OutlineContexts,
      children => {
        const {
          ImplementationGuideContextProvider,
        } = require('./contexts/ImplementationGuideContext')
        return <ImplementationGuideContextProvider children={children} />
      },
    )
    this.hookManager.registerMutateHook<SegmentProgressPayload, SegmentProgressContext>(
      CoursesHooks.SegmentProgress,
      (progress, context) => {
        const { useCourseAssignmentsContext } = require('./contexts/CourseAssignmentsContext')
        const { segmentId } = context!
        const status = useCourseAssignmentsContext(false)?.segmentProgress?.[segmentId]
        return status !== undefined ? status : progress
      },
    )
    const { SegmentRightContent } = require('./outline/SegmentRightContent')
    this.hookManager.registerMutateHook<SegmentRightContentPayload, SegmentRightContentContext>(
      CoursesHooks.SegmentRightContent,
      (children, context) => {
        const { segment } = context!
        if (segment.type === SegmentType.Assignment) {
          return [
            ...children,
            <SegmentRightContent key={'assignment-right-content'} segment={segment} />,
          ]
        } else {
          return children
        }
      },
    )
    const { SegmentInfoGrade } = require('./outline/SegmentInfoGrade')
    this.hookManager.registerMutateHook<
      SegmentInfoPopoverItemsPayload,
      SegmentInfoPopvoerItemsContext
    >(CoursesHooks.SegmentInfoPopoverItems, (payload, context) => {
      return [
        ...payload,
        <SegmentInfoGrade key={'info-grade'} segmentName={context!.segment.name} />,
      ]
    })
    this.hookManager.registerMutateHook<
      SegmentEnableRefetchQueriesPayload,
      SegmentEnableRefetchQueriesContext
    >(CoursesHooks.SegmentEnableRefetchQueries, (refetchQueries, context) => {
      const segmentIds = Array.isArray(context!.segmentId)
        ? context!.segmentId
        : [context!.segmentId]
      return [
        ...refetchQueries,

        // TODO: This currently doesn't work because of this Apollo issue:
        //   https://github.com/apollographql/apollo-client/issues/3540
        'classAssignments',

        ...(context?.enabled
          ? segmentIds.map(segmentId => ({
              query: AssignmentForSegmentDocument,
              variables: {
                classId: context!.classId,
                segmentId,
              },
            }))
          : []),
      ]
    })
    const { AssignmentSegmentDescription } = require('./segments/AssignmentSegmentDescription')
    this.hookManager.registerMutateHook<SegmentDescriptionsPayload, SegmentDescriptionsContext>(
      CoursesHooks.SegmentDescriptions,
      (children, context) => {
        // We are allowed to use hooks here because this is being called within another component.
        const decoration = useSegmentDecoration(context!.segment.id) as
          | SegmentDecorationWithDueDate
          | undefined
        if (decoration?.assignmentDueDate) {
          return [
            ...children,
            <AssignmentSegmentDescription segment={context!.segment} key={'assignment-due-date'} />,
          ]
        } else {
          return children
        }
      },
    )

    // New builders...
    require('./hooks/AssignmentSettingsOptions').default(this)
    require('./hooks/AssignmentDeepLink').default(this)
    require('./hooks/UpdateAssignmentRefetchQueries').default(this)
    require('./hooks/SubmitAssignmentRefetchQueries').default(this)
    require('./hooks/GradingModalNavigationItems').default(this)
    require('./hooks/GradingModalReportItems').default(this)
    require('./hooks/CanDeleteAssignment').default(this)
    require('./resources/assignment_calculation').default(this)
    require('./resources/assignment_filters/AssignmentsIGFilter').default(this)
    require('./resources/background_job_reporters/courseAssignmentsAfterClassUpdated').default(this)
    require('./resources/move_student_error').default(this)
    require('./hooks/sync').default(this)
    require('./hooks/GradingModalVisibilityContextChildren').default(this)
    require('./hooks/GradingModalVisibilityAssignment').default(this)
    require('./hooks/TeacherOutlineHeaderActions').default(this)
    require('./hooks/classRoutes').default(this)
    require('./hooks/CourseClassContext').default(this)
    require('./hooks/AfterSegmentEnableChanged').default(this)
    require('./hooks/CourseViewerContext').default(this)
  }

  public prepareEarly() {
    if (!isInNode()) {
      const { addSegmentActions } = require('./resources/segment_actions')
      addSegmentActions(this)
    }
  }
}

export const courseAssignmentsReactFeature = (
  opts: CourseAssignmentsReactOptions = {},
): FeatureUse<CourseAssignmentsReactOptions> => [CourseAssignmentsReactFeature, opts]
