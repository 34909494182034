import { AntIconWrapper, Block, Button, PageHead } from '@thesisedu/react'
import { Megaphone } from '@thesisedu/react/icons'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { AllTrialRequests } from '../../AllTrialRequests'
import { CreateTrialRequestButton } from '../../CreateTrialRequestButton'
import { ManageCampaigns } from '../../campaigns/ManageCampaigns'

export function TrialsAdminRoutes() {
  useBreadcrumb({ title: 'Trial Requests' })
  return (
    <Routes>
      <Route path={'/campaigns/*'} element={<Campaigns />} />
      <Route path={'/'} element={<Trials />} />
    </Routes>
  )
}

function Trials() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Trial Requests'} />
      <PageHeader title={'Trial Requests'}>
        <Button
          children={'Manage Campaigns'}
          icon={<AntIconWrapper children={<Megaphone />} />}
          onClick={() => {
            navigate('campaigns')
          }}
        />
        <CreateTrialRequestButton />
      </PageHeader>
      <AllTrialRequests
        approveProps={{
          onApproved: siteId => {
            navigate(`/admin/sites/${siteId}`)
          },
        }}
      />
    </>
  )
}

function Campaigns() {
  useBreadcrumb({ title: 'Campaigns' })
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Campaigns - Trial Requests'} />
      <PageHeader title={'Campaigns'} onBack={() => navigate('..')} />
      <Block marginTop={'@size-m'}>
        <ManageCampaigns />
      </Block>
    </>
  )
}
