import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export class AnnouncementsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-announcements-react'
  static path = __dirname
  static requires = []

  reactResources() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Class', fragment => {
      return addField(fragment, 'canCreateAnnouncement')
    })
    require('./resources/ClassTeacherPermission').default(this)
    require('./hooks/ClassDraggableOverlayItems').default(this)
    require('./hooks/classRoutes').default(this)
  }
}

export const announcementsReactFeature: ReactUse = opts => [AnnouncementsReactFeature, opts]
