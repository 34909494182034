import { areEqual } from '@thesisedu/react-virtual'
import { usePlaceholder } from '@thesisedu/web'
import React from 'react'

import { OutlineListProps } from './OutlineList'
import { useOutlineListConfigContext } from './context/OutlineListConfigContext'
import { OutlineItem } from './items/OutlineItem'
import { OutlineRowPlaceholder } from './items/Placeholder'

export interface OutlineRowProps {
  data: OutlineListProps['items']
  index: number
  style: object
}
export const OutlineRow = React.memo(({ data, index, style }: OutlineRowProps) => {
  const { outlineItemComponent } = useOutlineListConfigContext(false) || {}
  const Component = outlineItemComponent || OutlineItem
  const item = data[index]
  return usePlaceholder({
    placeholder: <OutlineRowPlaceholder style={style} item={item} />,
    getChildren: React.useCallback(
      () => <Component item={item} index={index} style={style} />,
      [data, index, style],
    ),
  })
}, areEqual)
OutlineRow.displayName = 'OutlineRow'
