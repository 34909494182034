import { useIsMountedRef, useFreshRef } from '@thesisedu/feature-react'
import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'
import { useDrop } from 'react-dnd'

import { NoteActions } from './NoteActions'
import { Accidental, useSimpleSheetMusicEditorContext } from './SimpleContext'
import { DragItemType, DragItem } from './types'
import { NoteItem } from '../viewer/NoteItem'
import {
  TuneNoteItem,
  TuneRestItem,
  CommonSheetMusicViewerProps,
  TuneNoteBase,
} from '../viewer/types'

const DROP_DURATIONS_THREE = [0.125, 0.25, 0.375, 0.5, 0.75, 1]
const DROP_DURATIONS_TWO = [0.125, 0.25, 0.5, 0.75, 1]

interface DurationPitch {
  durations: (number | undefined)[]
  pitches: (number | undefined)[]
  accidentals: (Accidental | undefined)[]
}
function splitBasedOnMaxAvailableDuration(
  { durations, pitches, accidentals }: DurationPitch,
  maxAvailableDuration: number,
): DurationPitch {
  const resultDurations: (number | undefined)[] = []
  const resultPitches: (number | undefined)[] = []
  const resultAccidentals: (Accidental | undefined)[] = []
  let currentTotalDuration = 0
  let currentMaxAvailableDuration = maxAvailableDuration
  for (let i = 0; i < durations.length; i++) {
    const duration = durations[i]
    const pitch = pitches[i]
    const accidental = accidentals[i]
    if (duration !== undefined) {
      // If we're out of room, add a bar and move on.
      if (currentMaxAvailableDuration - currentTotalDuration - duration < 0) {
        resultDurations.push(undefined)
        resultPitches.push(undefined)
        resultAccidentals.push(undefined)
        currentTotalDuration = 0
        currentMaxAvailableDuration = 1
      } else {
        // Otherwise, add the duration to the running total of durations.
        currentTotalDuration += duration
      }
    }
    resultDurations.push(duration)
    resultPitches.push(pitch)
    resultAccidentals.push(accidental)
  }

  return {
    durations: resultDurations,
    pitches: resultPitches,
    accidentals: resultAccidentals,
  }
}

interface HoverInfo {
  duration: number
  pitch?: number
  accidental?: Accidental
}
export interface DropBoxProps {
  duration: number
  maxAvailableDuration: number
  startPos: number
  replace?: number
  onHover: (pitch?: number, accidental?: Accidental, isDotting?: boolean) => void
  onHoverOut: () => void
  onHasGroup: (hasGroup: boolean) => void
}
export function DropBox({
  duration,
  replace,
  maxAvailableDuration,
  startPos,
  onHover,
  onHoverOut,
  onHasGroup,
}: DropBoxProps) {
  const { onDrop } = useSimpleSheetMusicEditorContext(true)
  const maxAvailableDurationRef = useFreshRef(maxAvailableDuration)
  const startPosRef = useFreshRef(startPos)
  const replaceRef = useFreshRef(replace)
  const durationRef = useFreshRef(duration)
  const [, dropRef] = useDrop<DragItem, any, object>(
    () => ({
      accept: DragItemType.Note,
      drop: item => {
        const _duration = item.duration ? item.duration : durationRef.current
        if (
          Array.isArray(_duration) &&
          item.pitch &&
          Array.isArray(item.pitch) &&
          item.accidental &&
          Array.isArray(item.accidental)
        ) {
          const { durations, pitches, accidentals } = splitBasedOnMaxAvailableDuration(
            { durations: _duration, pitches: item.pitch, accidentals: item.accidental },
            maxAvailableDurationRef.current,
          )
          onDrop(durations, pitches, accidentals, startPosRef.current, replaceRef.current)
        } else {
          onDrop(
            duration,
            item.pitch,
            item.accidental,
            startPosRef.current,
            replaceRef.current,
            maxAvailableDurationRef.current,
          )
        }
      },
      collect: monitor => {
        const item = monitor.getItem<DragItem>()
        // We don't support previews with a group of items. Instead, we just need to show a
        // little cursor of where it will be dropped at.
        if (
          monitor.isOver() &&
          item &&
          !Array.isArray(item.pitch) &&
          !Array.isArray(item.accidental)
        ) {
          onHover(item.pitch, item.accidental)
        } else {
          onHoverOut()
        }
        onHasGroup(monitor.isOver() && !!item?.duration)
        return {}
      },
    }),
    [],
  )
  return <DropBoxContainer style={{ flex: duration * 4 }} ref={dropRef} />
}
const DropBoxContainer = styled.div`
  display: flex;
  position: relative;
  top: -${props => props.theme['@size-xl']};
  padding: ${props => props.theme['@size-xl']} 0;
  height: calc(100% + ${props => props.theme['@size-xl']} * 2);
`

export interface MultiDropBoxProps extends CommonSheetMusicViewerProps {
  duration: number
  startPos: number
  compact?: boolean
  replace?: number
  allowActions?: boolean
  note?: TuneNoteBase
  remainingDuration?: number
}
export function MultiDropBox({
  children,
  duration,
  startPos,
  compact,
  replace,
  allowActions,
  note,
  remainingDuration,
  ...common
}: React.PropsWithChildren<MultiDropBoxProps>) {
  const overRef = React.useRef<number | null>(null)
  const mountedRef = useIsMountedRef()
  const [hoverInfo, setHoverInfo] = React.useState<HoverInfo | null>(null)
  // We are using a ref for this instead of state because React was throwing errors from updating this
  // value inside the collect() in DropBox above. Since we're going to re-render this component anyway
  // since the collect props changed, we can just use a ref here instead.
  const hasGroupRef = React.useRef(false)
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const groupRef = React.useRef<boolean[]>([])
  const editorContext = useSimpleSheetMusicEditorContext(false)
  const hasContext = !!editorContext
  const onHasGroup = (index: number) => (hasGroup: boolean) => {
    if (mountedRef.current) {
      groupRef.current[index] = hasGroup
      hasGroupRef.current = groupRef.current.some(Boolean)
    }
  }
  const onEnter = (duration: number) => (pitch?: number, accidental?: Accidental) => {
    if (overRef.current !== duration && mountedRef.current) {
      overRef.current = duration
      setHoverInfo({ duration, pitch, accidental })
    }
  }
  const onLeave = (duration: number) => () => {
    if (overRef.current === duration && mountedRef.current) {
      setHoverInfo(null)
      overRef.current = null
    }
  }
  const commonDropBox = {
    startPos,
    replace,
    maxAvailableDuration: duration,
  }
  const dropDurations = common.meter.num % 3 === 0 ? DROP_DURATIONS_THREE : DROP_DURATIONS_TWO
  const dragBoxes =
    hasContext && duration
      ? dropDurations
          .filter(d => duration >= d)
          .map((d, index) => (
            <DropBox
              duration={d}
              key={d}
              onHover={onEnter(d)}
              onHoverOut={onLeave(d)}
              onHasGroup={onHasGroup(index)}
              {...commonDropBox}
            />
          ))
      : null
  return (
    <DragBoxContainer
      style={{ opacity: hoverInfo !== null ? 0.5 : 1 }}
      dropdownVisible={dropdownVisible}
      highlight={duration < 1 && !note && hoverInfo === null}
    >
      {allowActions && hasContext && replace && note && remainingDuration !== undefined ? (
        <NoteActions
          startPos={startPos}
          replace={replace}
          dropdownVisible={dropdownVisible}
          onDropdownVisibleChange={setDropdownVisible}
          note={note}
          remainingDuration={remainingDuration}
        />
      ) : null}
      {hoverInfo && hoverInfo.duration ? (
        <>
          <NoteItem
            {...common}
            compact={compact}
            voice={
              {
                el_type: 'note',
                duration:
                  editorContext?.isShift && hoverInfo.duration * 1.5 <= duration
                    ? hoverInfo.duration * 1.5
                    : hoverInfo.duration,
                startChar: 0,
                endChar: 0,
                pitches:
                  hoverInfo.pitch !== undefined
                    ? [
                        {
                          highestVert: 0,
                          pitch: hoverInfo.pitch,
                          verticalPos: 0,
                          accidental: hoverInfo.accidental,
                        },
                      ]
                    : undefined,
                rest:
                  hoverInfo.pitch === undefined
                    ? {
                        type: 'invisible',
                      }
                    : undefined,
              } as TuneNoteItem | TuneRestItem
            }
          />
          <div
            style={{
              flex:
                (duration -
                  (editorContext?.isShift ? hoverInfo.duration * 1.5 : hoverInfo.duration)) *
                4,
            }}
          />
        </>
      ) : (
        children
      )}
      <DragBoxInnerContainer className={hasGroupRef.current ? 'has-group' : undefined}>
        {dragBoxes}
      </DragBoxInnerContainer>
    </DragBoxContainer>
  )
}
const DragBoxContainer = styled.div<{ dropdownVisible?: boolean; highlight?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  background: transparent;
  transition: background 0.25s linear;
  ${props => (props.highlight ? '&' : '&.noop')} {
    background: ${props => transparentize(0.75, props.theme['@red'])};
  }
  &:hover .actions-container,
  ${props => (props.dropdownVisible ? '.actions-container' : '&.noop')} {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
`
const DragBoxInnerContainer = styled.div`
  transition: background 0.25s linear;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 8;
  &.has-group {
    background: ${props => transparentize(0.75, props.theme['@primary-color'])};
  }
`
