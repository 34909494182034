import React from 'react'

import { useProgressBarContext } from './context'
import { Text, Text$ } from '../..'

export interface LabelProps extends Text$.TextProps {}
export function Label(props: LabelProps) {
  const { labelProps, label } = useProgressBarContext(true)
  return label ? <Text {...props} {...labelProps} children={label} /> : null
}
