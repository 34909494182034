import * as React from 'react'

function SvgNumber6(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#7D569E'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#7D569E'}
          fillRule={'nonzero'}
          d={
            'M93.02 195.898c6.438 4.418 13.885 6.532 22.341 6.343 8.457-.19 16.819-2.114 25.086-5.775 8.268-3.66 15.872-8.677 22.814-15.051 6.942-6.374 12.18-13.506 15.714-21.394 3.535-7.89 4.797-16.188 3.787-24.897-1.01-8.71-5.364-17.229-13.064-25.56-.126-.125-.82-.788-2.082-1.987-1.262-1.2-3.156-2.62-5.68-4.26-2.524-1.64-5.364-2.998-8.52-4.07-3.155-1.073-6.658-1.578-10.508-1.515-3.85.063-7.793 1.167-11.832 3.313l2.65-3.219a135.51 135.51 0 011.594-1.896l.394-.46a309.745 309.745 0 015.301-5.974c3.093-3.408 6.753-7.163 10.981-11.265 4.229-4.102 8.804-8.11 13.726-12.022l-28.967-28.02c-16.66 15.02-29.787 29.061-39.38 42.125-9.592 13.063-16.471 24.928-20.637 35.593-4.165 10.666-6.342 20.321-6.531 28.967-.19 8.646.82 16.125 3.029 22.436 2.209 6.31 4.86 11.612 7.952 15.903 3.092 4.292 5.806 7.447 8.14 9.467 2.336 2.02 3.566 3.092 3.693 3.218zm39.57-36.161c-4.291 2.398-8.456 3.218-12.495 2.46-4.04-.756-6.816-2.965-8.33-6.626-1.516-3.66-1.358-7.573.472-11.738 1.83-4.165 4.828-7.51 8.993-10.034 4.166-2.398 8.3-3.219 12.401-2.462 4.103.758 6.91 2.967 8.426 6.627 1.514 3.66 1.356 7.573-.474 11.738-1.83 4.165-4.828 7.51-8.993 10.035z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M167.616 107.577c-1.264-1.198-3.157-2.618-5.68-4.26-2.526-1.639-5.367-2.997-8.52-4.07-3.156-1.071-6.659-1.577-10.508-1.515-3.851.065-7.795 1.168-11.833 3.313 3.532-4.29 6.846-8.141 9.94-11.549 3.091-3.408 6.751-7.162 10.981-11.265 4.227-4.1 8.804-8.108 13.726-12.022l-28.967-28.02c-16.66 15.021-29.789 29.061-39.38 42.125-9.594 13.063-16.471 24.929-20.637 35.593-4.165 10.668-6.342 20.324-6.531 28.967-.19 8.647.822 16.126 3.029 22.436 2.206 6.313 4.857 11.614 7.952 15.903 3.09 4.293 5.804 7.45 8.14 9.467 2.335 2.02 3.566 3.094 3.693 3.218 6.437 4.417 13.882 6.532 22.34 6.343 8.455-.19 16.818-2.116 25.086-5.775 8.266-3.66 15.871-8.676 22.814-15.051 6.94-6.372 12.18-13.505 15.714-21.394 3.533-7.887 4.796-16.188 3.787-24.897-1.012-8.71-5.366-17.229-13.064-25.56a699.73 699.73 0 00-2.082-1.987zm-47.522 54.62c-4.04-.756-6.815-2.963-8.33-6.626-1.515-3.659-1.358-7.573.473-11.738 1.829-4.165 4.828-7.508 8.993-10.034 4.166-2.396 8.298-3.219 12.401-2.462 4.1.758 6.91 2.968 8.425 6.627 1.515 3.662 1.356 7.573-.473 11.738-1.831 4.165-4.828 7.511-8.993 10.035-4.292 2.399-8.458 3.218-12.496 2.46z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber6 = React.memo(SvgNumber6)
export default MemoSvgNumber6
