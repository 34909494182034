import {
  getColor,
  getSize,
  styledContainer,
  MiddotDelimitedList,
  styled,
  BodyExtraSmall,
  Block,
  isNative,
  HSpaced,
  BodySmall,
} from '@thesisedu/react'
import React from 'react'

import { CommentProps } from './types'
import { useComment } from './useComment'

export function BubbleComment(props: CommentProps) {
  const { isOwn, needsApproval, metadataSegments, user, actionLinks, sideContent, customContent } =
    useComment({
      ...props,
      shorthandPosted: true,
    })

  const nameMessage = [user ? user.name || user.username : 'Anonymous', ...metadataSegments]
  if (isOwn) {
    nameMessage.reverse()
  }
  return (
    <Container isOwn={isOwn} needsApproval={needsApproval} isSelected={props.isSelected}>
      <Block marginBottom={'@size-xxs'}>
        <RightContent space={'@size-xs'}>
          <MiddotDelimitedList>
            {nameMessage.map((item, index) =>
              typeof item === 'string' ? (
                <BodyExtraSmall color={'@text-color-secondary'} key={index}>
                  {item}
                </BodyExtraSmall>
              ) : (
                item
              ),
            )}
          </MiddotDelimitedList>
          <HSpaced align={'center'} className={'edit'} space={'@size-xs'}>
            {actionLinks}
          </HSpaced>
        </RightContent>
      </Block>
      <CommentContainer isOwn={isOwn}>
        <SideContent>{sideContent}</SideContent>
        <CommentInner isOwn={isOwn}>
          {customContent || (
            <Comment
              isOwn={isOwn}
              isHighlighted={props.isHighlighted}
              isSelected={props.isSelected}
            >
              {isNative ? null : (
                <CommentBefore
                  isHighlighted={!!props.isHighlighted}
                  isSelected={!!props.isSelected}
                  isOwn={isOwn}
                />
              )}
              <CommentText
                isOwn={isOwn}
                isHighlighted={props.isHighlighted}
                isSelected={props.isSelected}
                color={isOwn || props.isHighlighted ? '@gray-0' : '@text-color'}
              >
                {props.comment.comment}
              </CommentText>
            </Comment>
          )}
        </CommentInner>
      </CommentContainer>
    </Container>
  )
}

export interface StyledProps {
  isOwn?: boolean
  isSelected?: boolean
  isHighlighted?: boolean
  needsApproval?: boolean
}
const CommentContainer = styledContainer<{ isOwn: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isOwn ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`
const CommentInner = styledContainer<{ isOwn: boolean }>`
  width: 80%;
  display: flex;
  justify-content: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
`
const SideContent = styledContainer`
  margin: 0 ${props => props.theme['@size-s']};
`
const Container = styledContainer<StyledProps>`
  flex-direction: column;
  padding-top: ${props => getSize(props.theme, '@size-xs')};
  align-items: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
  justify-content: flex-start;
  opacity: ${props => (props.needsApproval ? 0.5 : 1)};
  width: 100%;
  display: flex;
  .edit {
    display: none;
    > * {
      display: inline-block;
      margin-left: ${props => getSize(props.theme, '@size-xxs')};
    }
  }
  &:hover .edit {
    display: inline;
  }
`
const RightContent = styled(HSpaced)`
  line-height: 1.2;
  a {
    color: ${props => getColor(props.theme, '@text-color')};
  }
`
export const Comment = styledContainer<StyledProps>`
  position: relative;
  border-radius: ${props => getSize(props.theme, '@size-xxs')};
  overflow: hidden;
`
const CommentText = styled(BodySmall)<StyledProps>`
  border-radius: ${props => getSize(props.theme, '@size-xxs')};
  overflow: hidden;
  align-self: ${props => (props.isOwn ? 'flex-end' : 'flex-start')};
  background: ${props =>
    getColor(
      props.theme,
      props.isOwn ? '@primary-color' : props.isHighlighted ? '@gray-2' : '@gray-1',
    )};
  padding: 0 ${props => getSize(props.theme, '@size-xs')};
  transition: border-color 0.25s linear;
  border: ${props =>
    `solid 3px ${props.isSelected ? getColor(props.theme, '@gray-6') : 'transparent'}`};
`
const CommentBefore = styledContainer<{
  isOwn: boolean
  isSelected: boolean
  isHighlighted: boolean
}>`
  content: ' ';
  position: absolute;
  top: -6px;
  ${props => (props.isOwn ? 'right' : 'left')}: ${props => getSize(props.theme, '@size-xs')};
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid
    ${props =>
      getColor(
        props.theme,
        props.isSelected
          ? '@red'
          : props.isOwn
          ? '@primary-color'
          : props.isHighlighted
          ? '@background-color-dark'
          : '@background-color-base',
      )};
`
