import { SimpleWidget } from '@thesisedu/feature-widgets-react'

import PDF from './PDF'
import PDFGeneration from './PDFGeneration'
import Tour from './Tour'
import Video from './Video'
import { CoursesReactFeature } from '../../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(Video))
  feature.resources.addResource(SimpleWidget.createWidgetResource(PDF))
  feature.resources.addResource(SimpleWidget.createWidgetResource(PDFGeneration))
  feature.resources.addResource(SimpleWidget.createWidgetResource(Tour))
}
