import { SearchOutlined } from '@ant-design/icons'
import { LargeBlock, H2, Block, styled } from '@thesisedu/web'
import { Input } from 'antd'
import React from 'react'

export interface ExploreWidgetProps {
  title: string
  nameFilter: string
  setNameFilter: (filter: string) => void
  placeholder: string
}
function _ExploreWidget(
  {
    title,
    nameFilter,
    setNameFilter,
    placeholder,
    children,
  }: React.PropsWithChildren<ExploreWidgetProps>,
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <Block marginBottom={'@size-l'}>
      <LargeBlock>
        <H2>{title}</H2>
        <Block marginBottom={'@size-m'}>
          <Input
            suffix={<SearchOutlined />}
            placeholder={placeholder}
            value={nameFilter}
            onChange={e => {
              setNameFilter(e.target.value)
            }}
          />
        </Block>
        <ContentContainer children={children} ref={ref} />
      </LargeBlock>
    </Block>
  )
}
export const ExploreWidget = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ExploreWidgetProps>
>(_ExploreWidget)

const ContentContainer = styled.div`
  height: 400px;
  overflow-y: auto;
`
