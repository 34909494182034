import React from 'react'

import { styled } from '../styled'
import { color, size } from '../theme'

export interface NavigationListItemProps {
  children: React.ReactElement | React.ReactElement[]
  isSelected?: boolean
  onClick: () => void
  [key: string]: any
}
export function NavigationListItem({
  children,
  isSelected,
  onClick,
  ...rest
}: NavigationListItemProps) {
  return <Container {...rest} isSelected={isSelected} onClick={onClick} children={children} />
}

const Container = styled.div<{ isSelected?: boolean }>`
  padding: ${size('@size-xxs')} ${size('@size-xs')};
  transition:
    background 0.1s linear,
    color 0.1s linear;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: stretch;
  color: ${props => (props.isSelected ? props.theme['@primary-color'] : props.theme['@gray-6'])};
  background: ${props => (props.isSelected ? props.theme['@gray-1'] : props.theme['@gray-0'])};
  border-bottom: solid 1px ${color('@border-color-split')};
  &:hover {
    background: ${color('@gray-1')};
    color: ${props => (props.isSelected ? props.theme['@primary-color'] : props.theme['@gray-7'])};
  }
  > * {
    width: 100%;
  }
`
