import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { styled, s, Text } from '@thesisedu/ui'
import React from 'react'

import { DiscussionGuideItemConfig } from './common'
import { PluginLevel } from '../../../lexical/EditorPlugins'
import { SimpleWidget } from '../utils'

export function DiscussionGuideItemElement({
  config,
  node,
}: SimpleWidget.SimpleWidgetProps<DiscussionGuideItemConfig>) {
  const [editor] = useLexicalComposerContext()
  const number = React.useMemo(() => {
    return editor.getEditorState().read(() => SimpleWidget.getWidgetNumber(config, node))
  }, [config, node])

  return (
    <Container>
      <NumberContainer $hasSubtitle={!!config.subtitle}>
        <Text level={'xl'} weight={'bold'} as={'div'}>
          {number}.
        </Text>
      </NumberContainer>
      <div style={{ flex: 1 }}>
        {config.subtitle ? (
          <Text level={'s'} style={{ lineHeight: 1 }} truncate color={'secondary'}>
            {config.subtitle}
          </Text>
        ) : null}
        <Text level={'xl'} weight={'bold'} as={'div'} bottom={'xs'}>
          <SimpleWidget.NestedEditor
            hostNodeKey={node.getKey()}
            editor={config.title}
            level={PluginLevel.Bare}
            placeholder={'Your question goes here?'}
          />
        </Text>
        <SimpleWidget.NestedEditor
          hostNodeKey={node.getKey()}
          editor={config.content}
          level={PluginLevel.Simple}
          placeholder={'Notes related to the discussion.'}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  margin: ${s.size('m')} 0;
  display: flex;
  align-items: flex-start;
  gap: ${s.size('xs')};
  background: ${s.color('gray.background')};
  .fteditor {
    &-ol-depth-1 {
      list-style-type: lower-alpha;
    }
    &-ol-depth-2 {
      list-style-type: lower-roman;
    }
    &-ol-depth-3 {
      list-style-type: decimal;
    }
    &-ol-depth-4 {
      list-style-type: lower-alpha;
    }
  }
`
const NumberContainer = styled.div<{ $hasSubtitle?: boolean }>`
  margin-top: ${props => (props.$hasSubtitle ? s.size('font.s') : 0)};
`
