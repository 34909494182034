export const colors = {
  '@blue': '#3788DD',
  '@blue-light': '#A3C8F0',
  '@red': '#E52E59',
  '@red-light': '#FFC8D5',
  '@green': '#53D590',
  '@green-light': '#A3F0C7',
  '@orange': '#F0A760',
  '@orange-light': '#F0CAA3',

  '@gray-0': '#FFF',
  '@gray-1': '#F7F8F9',
  '@gray-2': '#E2E5E9',
  '@gray-3': '#C3CBD4',
  '@gray-4': '#A2ABB4',
  '@gray-5': '#7C8894',
  '@gray-6': '#5D6874',
  '@gray-7': '#37404A',
}

export const sizes = {
  '@size-xxs': '4px',
  '@size-xs': '8px',
  '@size-s': '16px',
  '@size-xm': '20px',
  '@size-m': '24px',
  '@size-l': '32px',
  '@size-xl': '48px',
  '@size-xxl': '64px',
}

export const mappings = {
  '@text-color-secondary': colors['@gray-4'],
  '@font-size-base': sizes['@size-s'],
  '@font-size-lg': sizes['@size-xm'],
  '@font-size-sm': '@font-size-base - 2px',
}
