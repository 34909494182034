import { RocketOutlined } from '@ant-design/icons'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import {
  styled,
  getFlag,
  Block,
  setFlag,
  StyledThemeContext,
  BodyLarge,
  FontWeight,
  Markdown as ReactMarkdown,
} from '@thesisedu/web'
import { Empty, Button, Popover, Tooltip, Badge, Tag } from 'antd'
import axios from 'axios'
import React from 'react'

const LAST_UPDATE_FLAG = 'feature-updates-react:last-update'
const MAX_UPDATES = 10

interface Update {
  id: string
  url: string
  title: string
  excerpt: string
}

export interface ChangelogButtonProps {
  hideOnNoUpdates?: boolean
  updatesPath?: string
  rootPath?: string
}
export function ChangelogButton({
  hideOnNoUpdates,
  updatesPath: _updatesPath,
  rootPath: _rootPath,
}: ChangelogButtonProps) {
  const viewer = useViewerContext(false)
  const root = useFeatureRoot()
  const updatesPath =
    _updatesPath ||
    `https://${process.env.REACT_APP_ENVIRONMENT === 'production' ? '' : 'qa.'}${
      root.appOptions.domain
    }/updates-1.json`
  const rootPath =
    _rootPath ||
    `https://${process.env.REACT_APP_ENVIRONMENT === 'production' ? '' : 'qa.'}${
      root.appOptions.domain
    }`
  const [lastUpdate, _setLastUpdate] = React.useState<string>(getFlag(LAST_UPDATE_FLAG) || '')
  const [visible, setVisible] = React.useState(false)
  const setLastUpdate = (lastUpdate: string) => {
    _setLastUpdate(lastUpdate)
    setFlag(LAST_UPDATE_FLAG, lastUpdate)
  }
  const [updates, setUpdates] = React.useState<Update[]>([])
  React.useEffect(() => {
    axios
      .get<any>(updatesPath)
      .then(result => {
        const serverUpdates: Update[] = result.data.items
        setUpdates(serverUpdates)
        if (!lastUpdate && serverUpdates[0]) {
          setLastUpdate(serverUpdates[0].id)
        }
      })
      .catch(() => {
        // Do nothing, because we failed to get the updates...
      })
  }, [])
  const wasVisible = React.useRef<boolean>(false)
  React.useEffect(() => {
    if (visible) {
      wasVisible.current = true
    }
    if (!visible && updates.length && wasVisible.current) {
      setLastUpdate(updates[0].id)
    }
  }, [visible])
  const lastUpdateIndex = lastUpdate
    ? Math.max(
        0,
        updates.findIndex(upd => upd.id === lastUpdate),
      )
    : 0
  const unreadUpdates = lastUpdateIndex

  if ((hideOnNoUpdates && !unreadUpdates) || viewer?.group === 'STUDENT') {
    return null
  } else {
    return (
      <Badge count={unreadUpdates}>
        <Popover
          content={
            <UpdatesContainer>
              <Updates
                lastUpdateIndex={lastUpdateIndex}
                updates={updates.slice(0, MAX_UPDATES)}
                rootPath={rootPath}
                onClick={update => {
                  setLastUpdate(update.id)
                }}
              />
            </UpdatesContainer>
          }
          trigger={['click']}
          title={'Product Updates'}
          placement={'bottomRight'}
          visible={visible}
          onVisibleChange={setVisible}
        >
          <Tooltip title={'Product Updates'} placement={'bottomRight'} mouseEnterDelay={1}>
            <Button type={unreadUpdates ? 'primary' : 'default'} icon={<RocketOutlined />} />
          </Tooltip>
        </Popover>
      </Badge>
    )
  }
}

const UpdatesContainer = styled.div`
  margin: -16px -18px;
  width: 375px;
  height: 400px;
  overflow-y: auto;
`

export interface UpdatesProps {
  updates: Update[]
  rootPath: string
  lastUpdateIndex?: number
  onClick?: (update: Update) => void
}
export function Updates({ onClick, lastUpdateIndex, rootPath, updates }: UpdatesProps) {
  const theme = React.useContext(StyledThemeContext)
  if (updates.length) {
    return (
      <>
        {updates.map((update, index) => {
          const isUnread = !!lastUpdateIndex && index < lastUpdateIndex
          return (
            <UpdateContainer key={update.id} isUnread={isUnread}>
              <Block marginBottom={'@size-xs'}>
                <BodyLarge weight={FontWeight.Bold}>
                  <a
                    href={`${rootPath}${update.url}`}
                    target={'_blank'}
                    rel={'noreferrer'}
                    onClick={() => {
                      if (onClick) {
                        onClick(update)
                      }
                    }}
                  >
                    {update.title}
                    {isUnread ? (
                      <>
                        {' '}
                        <Tag color={theme['@primary-color']}>New!</Tag>
                      </>
                    ) : null}{' '}
                    &rarr;
                  </a>
                </BodyLarge>
              </Block>
              <ReactMarkdown children={update.excerpt} skipHtml />
            </UpdateContainer>
          )
        })}
      </>
    )
  } else {
    return (
      <Block marginTop={'@size-m'} marginBottom={'@size-m'}>
        <Empty description={'No updates yet!'} />
      </Block>
    )
  }
}
const UpdateContainer = styled.div<{ isUnread?: boolean }>`
  transition: border-left-color 0.25s linear;
  border-left: solid 2px transparent;
  border-left-color: ${props => (props.isUnread ? props.theme['@primary-color'] : 'transparent')};
  padding: ${props => props.theme['@size-s']};
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
  p {
    margin-bottom: ${props => props.theme['@size-xs']};
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
    margin-bottom: ${props => props.theme['@size-xs']};
  }
`
