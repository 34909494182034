import { Block, Text } from '@thesisedu/ui'
import React from 'react'

import { useAssignmentViewerContext } from './AssignmentViewerFormContext'
import { useSubmissionContext } from '../../contexts/SubmissionContext'
import { RubricGrader } from '../../rubric/RubricGrader'
import { transformRubric } from '../../rubric/helpers'
import { AssignmentGradingMode, FullAssignmentSubmissionFragment } from '../../schema'

type SubmissionWithGradesAndComments = Pick<FullAssignmentSubmissionFragment, 'grades' | 'comments'>
function isFullSubmission(submission: any): submission is SubmissionWithGradesAndComments {
  return submission?.grades != null
}

export function useHasRubric() {
  const { assignment, configuration } = useAssignmentViewerContext()
  const { submission } = useSubmissionContext(true)
  const questionIds = configuration.questions.map(q => q.id)
  const remainingCategories =
    assignment.rubric?.categories.filter(cat => !questionIds.includes(cat.categoryId)) || []
  return (
    isFullSubmission(submission) &&
    remainingCategories?.length &&
    submission?.gradedAt &&
    submission?.grade != null &&
    submission?.reviewed &&
    assignment.gradingMode === AssignmentGradingMode.Rubric
  )
}

export function FormGradeRubric() {
  const hasRubric = useHasRubric()
  const { assignment, configuration } = useAssignmentViewerContext()
  const { submission } = useSubmissionContext(true)
  const questionIds = configuration.questions.map(q => q.id)
  const remainingCategories =
    assignment.rubric?.categories.filter(cat => !questionIds.includes(cat.categoryId)) || []
  const rubric = React.useMemo(() => {
    return transformRubric({ categories: remainingCategories })
  }, [assignment.id])
  if (hasRubric && isFullSubmission(submission)) {
    const hasOtherQuestions = !!configuration.questions.length
    return (
      <Block vertical={'l'}>
        {hasOtherQuestions ? (
          <Text bottom level={'h2'}>
            Rubric
          </Text>
        ) : null}
        <RubricGrader
          disabled
          onChange={() => {}}
          value={{ grades: submission.grades, comments: submission.comments }}
          rubric={rubric}
        />
      </Block>
    )
  } else {
    return null
  }
}
