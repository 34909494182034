import { AssignmentCalculation } from '@thesisedu/feature-assignments-core'
import { useResource } from '@thesisedu/feature-react'
import { Select } from 'antd'
import React from 'react'

import { AssignmentCalculationResource } from '../types'

const NO_VALUE = 'no-value'
export interface CalculationFieldProps {
  value?: AssignmentCalculation
  onChange?: (value: AssignmentCalculation | undefined) => void
}
export function CalculationField({ value, onChange }: CalculationFieldProps) {
  const resources = useResource<AssignmentCalculationResource>('AssignmentCalculation')
  return (
    <Select
      value={value?.identifier || NO_VALUE}
      onChange={identifier => {
        if (onChange) {
          if (identifier === NO_VALUE) {
            onChange(undefined)
          } else {
            onChange({ identifier })
          }
        }
      }}
      options={resources.map(resource => ({
        label: resource.label,
        value: resource.identifier,
      }))}
    />
  )
}
