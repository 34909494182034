import { SiteContentHook } from '@thesisedu/feature-sites-web'
import { H2 } from '@thesisedu/react'
import React from 'react'

import { SiteTeachers } from '../SiteTeachers'
import { TeachersWebFeature } from '../TeachersWebFeature'

export default function (feature: TeachersWebFeature) {
  feature.hookManager.registerMutateHook<SiteContentHook>(
    'sites-web:site-content',
    (content, { siteId }) => {
      return [
        ...content,
        <div key={'teachers'}>
          <H2>Site Teachers</H2>
          <SiteTeachers siteId={siteId} />
        </div>,
      ]
    },
  )
}
