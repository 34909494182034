import { BlockEditor, EditorValue, PluginLevel } from '@thesisedu/feature-widgets-react'
import React from 'react'

import { valueTreeToEditorValue } from './helpers'

export const DEFAULT_VALUE: EditorValue = valueTreeToEditorValue([
  {
    name: '(1) The student demonstrates professional standards / employability skills required by business and industry.',
    children: [{ name: 'All' }],
  },
  {
    name: '(2) The student describes the characteristics of business',
    children: [
      { name: '(A) explain the principles of supply and demand' },
      { name: '(B) differentiate between goods and services' },
      { name: '(C) identify the types of business' },
    ],
  },
  { name: 'Something else...' },
])

export interface TagsFieldProps {
  onChange?: (value?: EditorValue) => void
  value?: EditorValue
}
export function TagsField({ onChange, value }: TagsFieldProps) {
  return (
    <BlockEditor
      id={'standard-set-tags'}
      placeholder={'Add your standards here as a list.'}
      defaultValue={value || DEFAULT_VALUE}
      onChange={onChange}
      noAdd
      level={PluginLevel.Simple}
    />
  )
}
