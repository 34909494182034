import { OrderedListOutlined } from '@ant-design/icons'
import { SimpleSegmentPresetResource } from '@thesisedu/feature-courses-react'
import { SegmentType } from '@thesisedu/feature-courses-react/dist/schema'
import React from 'react'

export const TestQuizPreset: SimpleSegmentPresetResource = {
  type: 'SimpleSegmentPreset',
  identifier: 'test-quiz',
  segmentType: SegmentType.Assignment,
  label: 'Test / Quiz',
  icon: <OrderedListOutlined />,
  formValues: {
    config: {
      icon: 'ordered-list',
      label: 'Test / Quiz',
      rubricType: 'Classwork',
    },
    assignment: {
      defaults: {
        submissionType: 'INDIVIDUAL',
      },
      configuration: {
        questions: [
          {
            id: 'one',
            type: 'MultipleChoice',
            name: 'Question',
            weight: 0,
            totalPoints: 1,
            config: {
              options: [{ id: 'option-one', name: 'Option 1', correct: false }],
            },
          },
        ],
      },
    },
  },
}
