import React, { useContext } from 'react'

import { SegmentNameProps } from '../segment/SegmentName'
import { CourseConfiguration, Segment } from '../types'

export interface CourseEditorReadContextValue {
  courseVersionId: string
  courseConfiguration: CourseConfiguration
  rootSegment?: Segment
}
export const CourseEditorReadContext = React.createContext<
  CourseEditorReadContextValue | undefined
>(undefined)
export function useCourseEditorReadContext(): CourseEditorReadContextValue | undefined
export function useCourseEditorReadContext(require: false): CourseEditorReadContextValue | undefined
export function useCourseEditorReadContext(require: true): CourseEditorReadContextValue
export function useCourseEditorReadContext(
  require?: boolean,
): CourseEditorReadContextValue | undefined {
  const context = useContext(CourseEditorReadContext)
  if (!context && require) {
    throw new Error('CourseEditorReadContext is required, yet not provided.')
  }
  return context
}

export interface CourseEditorStatusContextValue {
  expandedSegmentIds?: string[]
  highlightedSegmentId?: string
  enableReferences?: boolean
  loadingSegmentIds?: string[]
}
export const CourseEditorStatusContext = React.createContext<
  CourseEditorStatusContextValue | undefined
>(undefined)
export function useCourseEditorStatusContext(): CourseEditorStatusContextValue | undefined
export function useCourseEditorStatusContext(
  require: false,
): CourseEditorStatusContextValue | undefined
export function useCourseEditorStatusContext(require: true): CourseEditorStatusContextValue
export function useCourseEditorStatusContext(
  require?: boolean,
): CourseEditorStatusContextValue | undefined {
  const context = useContext(CourseEditorStatusContext)
  if (!context && require) {
    throw new Error('CourseEditorStatusContext is required, yet not provided.')
  }
  return context
}

export interface CourseEditorSegmentNameContextValue {
  renderSegmentName?: (props: SegmentNameProps, children: React.ReactElement) => React.ReactElement
}
export const CourseEditorSegmentNameContext = React.createContext<
  CourseEditorSegmentNameContextValue | undefined
>(undefined)
export function useCourseEditorSegmentNameContext(): CourseEditorSegmentNameContextValue | undefined
export function useCourseEditorSegmentNameContext(
  require: false,
): CourseEditorSegmentNameContextValue | undefined
export function useCourseEditorSegmentNameContext(
  require: true,
): CourseEditorSegmentNameContextValue
export function useCourseEditorSegmentNameContext(
  require?: boolean,
): CourseEditorSegmentNameContextValue | undefined {
  const context = useContext(CourseEditorSegmentNameContext)
  if (!context && require) {
    throw new Error('CourseEditorSegmentNameContext is required, yet not provided.')
  }
  return context
}

type CreateRequiredKeys = 'id' | 'type'
export type CreateSegmentPayload = Partial<Omit<Segment, CreateRequiredKeys>> &
  Pick<Segment, CreateRequiredKeys>
export interface CourseEditorWriteContextValue {
  onSegmentChange: (
    segment: Partial<Omit<Segment, 'id'>> & Pick<Segment, 'id'>,
    isSimple?: boolean,
  ) => Promise<void>
  onCreateSegment: (segment: CreateSegmentPayload, parentSegmentId: string) => Promise<void>
  onDeleteSegment: (segmentId: string) => Promise<void>
}
export const CourseEditorWriteContext = React.createContext<
  CourseEditorWriteContextValue | undefined
>(undefined)
export function useCourseEditorWriteContext(): CourseEditorWriteContextValue | undefined
export function useCourseEditorWriteContext(
  require: false,
): CourseEditorWriteContextValue | undefined
export function useCourseEditorWriteContext(require: true): CourseEditorWriteContextValue
export function useCourseEditorWriteContext(
  require?: boolean,
): CourseEditorWriteContextValue | undefined {
  const context = useContext(CourseEditorWriteContext)
  if (!context && require) {
    throw new Error('CourseEditorWriteContext is required, yet not provided.')
  }
  return context
}

export interface CourseEditorProviderProps {
  segmentName?: CourseEditorSegmentNameContextValue
  status: CourseEditorStatusContextValue
  read: CourseEditorReadContextValue
  write?: CourseEditorWriteContextValue
}
export function CourseEditorProvider({
  children,
  segmentName,
  status,
  read,
  write,
}: React.PropsWithChildren<CourseEditorProviderProps>) {
  let content = segmentName ? (
    <CourseEditorSegmentNameContext.Provider value={segmentName} children={children} />
  ) : (
    children
  )
  if (write) {
    content = <CourseEditorWriteContext.Provider value={write} children={content} />
  }
  return (
    <CourseEditorReadContext.Provider value={read}>
      <CourseEditorStatusContext.Provider value={status}>
        {content}
      </CourseEditorStatusContext.Provider>
    </CourseEditorReadContext.Provider>
  )
}
