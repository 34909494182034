import { getFragmentDefinitions } from '@apollo/client/utilities'
import { Feature } from '@thesisedu/feature'
import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

import { SearchResultNode, SearchResultResource, SearchFragmentsMutateHook } from './types'

export function addSearch<Node extends SearchResultNode>(
  feature: Feature,
  fragment: string | DocumentNode,
  typename: string,
  resource: Omit<SearchResultResource<Node>, 'identifier' | 'type' | 'fragment' | 'fragmentName'>,
) {
  if (
    typeof fragment === 'string' &&
    (!fragment.replace(/\s/g, '').startsWith('...on') ||
      (!fragment.includes('... on') && !fragment.includes('...on')))
  ) {
    throw new Error('Fragment must start with ... on')
  }
  feature.hookManager.registerMutateHook<SearchFragmentsMutateHook>(
    'feature-search:fragments',
    fragments => [...fragments, fragment],
  )
  feature.resources.addResource<SearchResultResource<Node>>({
    type: 'SearchResult',
    identifier: typename,
    fragment:
      typeof fragment === 'string'
        ? gql(
            fragment
              .replace('... on', `fragment Search${typename} on`)
              .replace('...on', `fragment Search${typename} on`)
              .replace('{', '{ id'),
          )
        : fragment,
    fragmentName:
      typeof fragment === 'string'
        ? `Search${typename}`
        : getFragmentDefinitions(fragment)[0]!.name.value,
    ...resource,
  })
}
