import { Button as UiButton } from '@thesisedu/ui'
import { Trophy } from '@thesisedu/ui/icons'
import { BlockSpin, NotFound, PageHead, PageHeader } from '@thesisedu/web'
import { message, Form, Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AwardBadgeModal } from './AwardBadgeModal'
import { BadgeForm } from './BadgeForm'
import { DeleteBadgeButton } from './DeleteBadgeButton'
import { useBadgeQuery, useUpdateBadgeMutation } from '../schema'

export interface EditBadgePageProps {
  id: string
}
export function EditBadgePage({ id }: EditBadgePageProps) {
  const navigate = useNavigate()
  const { data, loading } = useBadgeQuery({ variables: { id } })
  const [updateBadge, { loading: updating }] = useUpdateBadgeMutation({
    onCompleted: () => {
      message.success('Badge saved!')
      navigate('..')
    },
  })
  const badge = data?.node?.__typename === 'Badge' ? data.node : undefined
  const [form] = Form.useForm()
  React.useEffect(() => {
    form.setFieldsValue(badge)
  }, [badge])
  if (badge) {
    return (
      <>
        <PageHead title={badge.name} />
        <PageHeader title={'Edit Badge'} onBack={() => navigate('..')}>
          <AwardBadgeModal
            badgeId={badge.id}
            trigger={<UiButton icon={<Trophy />} children={'Award Badge to User'} />}
          />
          <DeleteBadgeButton id={badge.id} onComplete={() => navigate('..')} />
          <Button type={'primary'} onClick={() => form.submit()} loading={updating}>
            Save Changes
          </Button>
        </PageHeader>
        <BadgeForm
          form={form}
          badge={badge}
          onFinish={values => {
            updateBadge({
              variables: {
                input: {
                  id,
                  patch: values,
                },
              },
            })
          }}
        />
      </>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'That badge could not be found.'} />
  }
}
