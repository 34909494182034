import { BlockEditor, EditorValue, isEmpty, PluginLevel } from '@thesisedu/feature-widgets-react'
import { Modal, Input, Button, ClickableContainer, BodyExtraSmall } from '@thesisedu/react'
import React, { useState } from 'react'

import { useUpdateCommentMutation } from './queries/useUpdateCommentMutation'

export interface UpdateCommentLinkProps {
  commentId: string
  originalMessage?: string
  originalBlockMessage?: EditorValue
  useRichText?: boolean
  style?: any
}
export const UpdateCommentLink: React.FC<React.PropsWithChildren<UpdateCommentLinkProps>> = ({
  commentId,
  originalMessage,
  originalBlockMessage,
  useRichText,
  style,
}) => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = React.useState<string | undefined>(originalMessage)
  const [blockValue, setBlockValue] = React.useState<EditorValue | undefined>(originalBlockMessage)
  const [defaultValueKey, setDefaultValueKey] = React.useState(Math.random().toString())
  React.useEffect(() => {
    if (visible) {
      setValue(originalMessage)
      setBlockValue(originalBlockMessage)
      setDefaultValueKey(Math.random().toString())
    }
  }, [visible])
  const [updateComment, { loading }] = useUpdateCommentMutation({
    onCompleted: () => {
      setVisible(false)
    },
  })
  return (
    <>
      <Modal
        title={'Update Comment'}
        onCancel={() => setVisible(false)}
        visible={visible}
        width={800}
        style={{ maxWidth: '90vw' }}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key={'save'}
            type={'primary'}
            loading={loading}
            disabled={!value && isEmpty(blockValue)}
            onClick={() => {
              if (value || !isEmpty(blockValue)) {
                updateComment({
                  variables: {
                    input: {
                      id: commentId,
                      patch: {
                        comment: value || '',
                        blockComment: blockValue,
                      },
                    },
                  },
                })
              }
            }}
          >
            Update Comment
          </Button>,
        ]}
      >
        {useRichText ? (
          <BlockEditor
            id={'update-comment'}
            defaultValue={blockValue}
            defaultValueKey={defaultValueKey}
            onChange={setBlockValue}
            level={PluginLevel.Simple}
            minHeight={200}
          />
        ) : (
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 10 }}
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        )}
      </Modal>
      <ClickableContainer
        onClick={(e: any) => {
          e.preventDefault()
          setVisible(true)
        }}
        style={style}
      >
        <BodyExtraSmall color={'@text-color-secondary'}>Edit</BodyExtraSmall>
      </ClickableContainer>
    </>
  )
}
