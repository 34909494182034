import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { ClassGradesRoute } from '../../ClassGradesRoute'

export function ClassGrades() {
  Shell.useShellProps(
    'grades',
    {
      noScroll: true,
    },
    [],
  )
  return (
    <>
      <GradeStyles />
      <ClassGradesRoute
        hideHeader
        hideFullscreen
        tableProps={{
          studentLink: (classId, studentId) => `/teacher/reports/${classId}/${studentId}`,
        }}
      />
    </>
  )
}
const GradeStyles = createGlobalStyle`
  .grade-grid-container {
    height: calc(100% + ${props => (props as any).theme['@size-s']});
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: -${props => (props as any).theme['@size-l']};
  }
`
