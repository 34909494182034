import { SegmentCalculationOptions } from '@thesisedu/feature-course-types'
import { VSpaced, Button, Checkbox, AntIconWrapper, Form } from '@thesisedu/react'
import { Settings } from '@thesisedu/react/icons'
import { Popover } from 'antd'
import React from 'react'

export interface VaultSettingsProps {
  config: SegmentCalculationOptions
  onConfigChange: (config: SegmentCalculationOptions) => void | Promise<void>
}
export function VaultSettings({ config, onConfigChange }: VaultSettingsProps) {
  const form = Form.useForm<SegmentCalculationOptions>()
  React.useEffect(() => {
    form.reset(config)
  }, [config])
  return (
    <Form
      form={form}
      onFinish={values => {
        return onConfigChange(values)
      }}
    >
      <VSpaced space={'@size-s'}>
        <Form.Item
          name={'includeNonAutoGradedQuestions'}
          initialValue={config.includeNonAutoGradedQuestions}
          valuePropName={'checked'}
          extra={
            <span>
              By default, quiz vault will only include questions that are auto-gradeable. If this is
              enabled, all questions are available for use,{' '}
              <strong>including ones that require you to manually grade each submission.</strong>
            </span>
          }
        >
          <Checkbox>Include non-auto-graded questions</Checkbox>
        </Form.Item>
        <Form.Submit disableUntilDirty block children={'Save Changes'} type={'primary'} />
      </VSpaced>
    </Form>
  )
}

export function VaultSettingsButton(props: VaultSettingsProps) {
  return (
    <Popover
      placement={'bottomRight'}
      content={<VaultSettings {...props} />}
      overlayInnerStyle={{ width: 400 }}
      destroyTooltipOnHide
      trigger={['click']}
    >
      <Button icon={<AntIconWrapper children={<Settings />} />} />
    </Popover>
  )
}
