import * as React from 'react'

function SvgNumber4(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#68B545'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#68B545'}
          fillRule={'nonzero'}
          d={
            'M114.026 200.442l48.279-2.083 2.082-34.647 16.85-.189 2.651-43.924-16.85 3.029 1.893-11.17h-43.545l-.568 10.981h-19.312l70.809-66.643-46.385-14.389-63.425 63.235 3.976 63.046 46.953-3.597z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M66.505 104.642l3.976 63.046 46.953-3.597-3.408 36.351 48.279-2.083 2.082-34.647 16.85-.189 2.651-43.924-16.85 3.029 1.893-11.17h-43.545l-.568 10.981h-19.312l70.809-66.643-46.385-14.389z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber4 = React.memo(SvgNumber4)
export default MemoSvgNumber4
