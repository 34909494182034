import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { DISABLE_STUDENT_OVERRIDES_CONFIG } from '@thesisedu/feature-course-types'
import { Form, Checkbox } from 'antd'
import React from 'react'

function Field({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, DISABLE_STUDENT_OVERRIDES_CONFIG]}
      valuePropName={'checked'}
      extra={
        'Typically, overrides are used to hide lesson identifiers from students. When this is ' +
        'checked, the overrides will be disabled and those identifiers will be shown to students.'
      }
    >
      <Checkbox>Disable student segment name overrides.</Checkbox>
    </Form.Item>
  )
}

const config: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: DISABLE_STUDENT_OVERRIDES_CONFIG,
  group: 'Courses',
  renderField: Field,
  hidden: true,
}
export default config
