import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { CourseVersionFragmentDoc, useDetachSegmentMutation as useMutation } from '../schema'

export const useDetachSegmentMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error detaching that segment.'),
    ...opts,
    update: updateMutation({
      fragment: CourseVersionFragmentDoc,
      fragmentName: 'CourseVersion',
      path: 'detachSegment.courseVersion',
    }),
  })
