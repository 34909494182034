import { BackgroundJobReporterResource } from '@thesisedu/feature-apollo-react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'bulkUpdateAssignmentsInClass',
    humanLabel: () => 'Updating assignments...',
  })
}
