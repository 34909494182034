import React from 'react'

export interface AudioPlaybackContextValue {
  startPlaying: (element: HTMLAudioElement, silenceOthers?: boolean) => void
}
export const AudioPlaybackContext = React.createContext<AudioPlaybackContextValue | undefined>(
  undefined,
)

export function AudioPlaybackProvider({ children }: React.PropsWithChildren<object>) {
  const playingAudioRefs = React.useRef<HTMLAudioElement[]>([])
  const value = React.useMemo<AudioPlaybackContextValue>(
    () => ({
      startPlaying: (element, silenceOthers) => {
        if (silenceOthers) {
          for (const ref of playingAudioRefs.current) {
            try {
              ref.pause()
            } catch {
              // Oh well, couldn't stop playing it...
            }
          }
          playingAudioRefs.current = []
        }
        playingAudioRefs.current.push(element)
        element.currentTime = 0
        element.play()
      },
    }),
    [],
  )
  return <AudioPlaybackContext.Provider value={value} children={children} />
}

export interface AudioPlaybackOpts {
  silenceOthers?: boolean
  modifyAudio?: (element: HTMLAudioElement) => void
}
export function useAudioPlayback(
  url?: string,
  { silenceOthers = true, modifyAudio }: AudioPlaybackOpts = {},
) {
  const audioRef = React.useRef<HTMLAudioElement | null>(null)
  React.useEffect(() => {
    if (url) {
      audioRef.current = new Audio(url)
      if (modifyAudio) {
        modifyAudio(audioRef.current)
      }
    } else {
      audioRef.current = null
    }
  }, [url])
  const context = React.useContext(AudioPlaybackContext)
  return () => {
    if (context) {
      if (audioRef.current) {
        context.startPlaying(audioRef.current, silenceOthers)
      }
    } else {
      console.warn('Cannot useAudioPlayback without AudioPlaybackContext')
    }
  }
}
