import { NodeKey } from 'lexical'

import { useAdvanceToNextOnTab } from './useAdvanceToNextOnTab'
import { useForwardFocusToParent } from './useForwardFocusToParent'
import { useOnChangePropagate } from './useOnChangePropagate'

export interface NestedEditorPluginProps {
  forwardFocusToParent?: boolean
  advanceToNextOnTab?: boolean
  hostNodeKey: NodeKey
}
export function NestedEditorPlugin({
  hostNodeKey,
  forwardFocusToParent = false,
  advanceToNextOnTab = false,
}: NestedEditorPluginProps) {
  useForwardFocusToParent(forwardFocusToParent, hostNodeKey)
  useAdvanceToNextOnTab(advanceToNextOnTab, hostNodeKey)
  useOnChangePropagate()

  return null
}
