import {
  AssignmentConfiguration,
  Question,
  QuestionConfig,
  QuestionOverride,
} from '@thesisedu/feature-assignments-core'

export interface CourseQuestionConfig extends QuestionConfig {
  excludeFromQuizVault?: boolean
  isRecommended?: boolean
}

export interface CourseQuestion extends Question<CourseQuestionConfig> {
  segmentId?: string
  isCovered?: boolean
  /** Whether or not the question is automatically gradeable. */
  autograde?: boolean
}

export enum CalculatedPreset {
  Recommended = 'RECOMMENDED',
  RecommendedCovered = 'RECOMMENDED_COVERED',
  AllCovered = 'ALL_COVERED',
  All = 'ALL',
  Manual = 'MANUAL',
}

export interface SegmentCalculationOptions {
  identifier: 'segment'
  calculatedPreset?: CalculatedPreset
  /** This overrides the automatically-calculated segment IDs. */
  segmentIds?: string[]
  /** Whether or not to include non auto-gradeable questions inside the question bank. */
  includeNonAutoGradedQuestions?: boolean
}

export interface SegmentAssignmentConfiguration extends AssignmentConfiguration {
  segmentId: string
  calculation?: SegmentCalculationOptions
  /** If this is true, all questions inside this assignment are excluded from quiz vault. */
  excludeAllFromQuizVault?: boolean
}

export interface SegmentAssignmentOverrides
  extends Omit<SegmentAssignmentConfiguration, 'questions'> {
  questions: QuestionOverride[]
}
