import { ReportFilterProps, ReportFilterResource } from '@thesisedu/feature-reports-react'
import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import { ReportMetricInput } from '../../schema'

function IsLeaderFilter({ value, onChange }: ReportFilterProps<boolean>) {
  return (
    <Dropdown.Item.Checkbox
      checked={value}
      onCheckedChange={checked => onChange?.(checked || undefined)}
      children={'Group Leader'}
    />
  )
}

const GRADE_METRICS: (keyof ReportMetricInput)[] = ['grade', 'numAttempts']
export default function (feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportFilterResource<boolean>>({
    type: 'ReportFilter',
    identifier: 'isLeader',
    render: IsLeaderFilter,
    shouldShow({ opts }) {
      return (
        !opts.metrics.length ||
        opts.metrics.some(metric => GRADE_METRICS.includes(Object.keys(metric)[0] as any))
      )
    },
  })
}
