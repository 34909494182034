import { WidgetConverter } from './types'
import * as Legacy from '..'

interface FloatWidget extends Legacy.Widget {
  config?: {
    float: 'left' | 'right'
    widthPercentage?: number | null
  }
  childGroups?: {
    children?: Legacy.Widget[]
  }
}

export default {
  weight: 0,
  label: 'float',
  identifier: 'Float',
  convert(value, opts) {
    return [
      {
        type: 'float',
        version: 1,
        alignment: value.config?.float || 'right',
        width: value.config?.widthPercentage ? value.config.widthPercentage / 100 : 0.5,
        children:
          value.childGroups?.children?.flatMap(child => Legacy.convertWidget(child, opts)) || [],
      },
    ]
  },
} satisfies WidgetConverter<FloatWidget>
