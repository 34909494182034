import { Check } from '@thesisedu/react/icons'
import { styled } from '@thesisedu/web'
import { Input, Button, Form, message } from 'antd'
import React, { useEffect } from 'react'

import { useUpdateClassMutation } from '../queries/useUpdateClassMutation'
import { ClassFragment } from '../schema'

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export interface BasicClassSettingsProps {
  cls: ClassFragment
}
export function BasicClassSettings({ cls }: BasicClassSettingsProps) {
  const [update, { loading }] = useUpdateClassMutation({
    onCompleted: () => {
      message.success('Changes saved!')
    },
  })
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      name: cls.name,
    })
  }, [cls])
  return (
    <Form
      layout={'vertical'}
      form={form}
      onFinish={values => {
        update({
          variables: {
            input: {
              id: cls.id,
              patch: {
                name: values.name,
              },
            },
          },
        })
      }}
    >
      <Form.Item
        name={'name'}
        label={'Class Name'}
        rules={[{ required: true, message: 'A name is required.' }]}
        style={{ marginBottom: 16 }}
      >
        <Input placeholder={'Period 1'} size={'large'} />
      </Form.Item>
      <Footer>
        <Button type={'primary'} htmlType={'submit'} loading={loading} icon={<Check />}>
          Save Class Name
        </Button>
      </Footer>
    </Form>
  )
}
