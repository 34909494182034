import React from 'react'

import { styled } from '../styled'
import { getColor, size, typographyFromSize, useTheme } from '../theme'
import { Color, Size } from '../types'

export interface TagProps {
  color: Color
  textColor?: Color
  /** If ghost, the tag is rendered with a semi-transparent background. */
  ghost?: boolean
  size?: Size
  children?: string | React.ReactElement | React.ReactElement[]
}
/** @deprecated */
export function Tag({ color, textColor = 'gray-0', size = 'default', ghost, children }: TagProps) {
  const Typo = typographyFromSize(size)
  const theme = useTheme()
  return (
    <StyledTag style={{ background: getColor(theme, color, undefined, { ghost }) }}>
      <Typo color={textColor} children={children} />
    </StyledTag>
  )
}

const StyledTag = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${size('@size-xxs')} ${size('@size-xs')};
  border-radius: ${size('@border-radius-base')};
`
