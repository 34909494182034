import { SiteActionsHook } from '@thesisedu/feature-sites-web'
import React from 'react'

import { MSTeamsReactFeature } from '../MSTeamsReactFeature'
import { GenerateTenantTokenMenuItem } from '../tenant/GenerateTenantTokenModal'

export default function (feature: MSTeamsReactFeature) {
  feature.hookManager.registerMutateHook<SiteActionsHook>(
    'sites-web:site-actions',
    (actions, { siteId }) => {
      return [<GenerateTenantTokenMenuItem key={'ms-tenant'} siteId={siteId} />, ...actions]
    },
  )
}
