import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { MediaView, MediaViewProps } from './MediaView'
import { MediaViewProviders } from './MediaViewProviders'
import { Sidebar } from '../sidebar/Sidebar'
import { SidebarProvider } from '../sidebar/SidebarContext'

export interface MediaViewWithSidebarProps extends MediaViewProps {
  defaultSidebarTab?: string
}
export function MediaViewWithSidebar({ defaultSidebarTab, ...props }: MediaViewWithSidebarProps) {
  return (
    <SidebarProvider defaultSidebarTab={defaultSidebarTab}>
      <MediaViewProviders {...props}>
        <Container>
          <div style={{ flex: 1 }}>
            <MediaView {...props} />
          </div>
          <div style={{ width: '33%' }}>
            <Sidebar />
          </div>
        </Container>
      </MediaViewProviders>
    </SidebarProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${s.size('m')};
`
