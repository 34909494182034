import { Button, Button$ } from '@thesisedu/ui'
import React from 'react'

export interface UpdateButtonProps extends Button$.ButtonProps {
  onUpdate: () => void
}
function _UpdateButton(
  { onUpdate, ...rest }: UpdateButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const [loading, setLoading] = React.useState(false)
  return (
    <Button
      variant={'primary'}
      ref={ref}
      {...rest}
      onPress={() => {
        setLoading(true)
        onUpdate()
      }}
      loading={loading}
      children={'Refresh Now'}
    />
  )
}
export const UpdateButton = React.forwardRef(_UpdateButton)
