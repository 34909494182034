import React from 'react'

/**
 * This automatically detects if the width of an embedded SVG (on load) is 0, meaning
 * we're running into the Safari-specific bug where SVG viewBox sizes are not honored, so
 * we need to calculate and set the width explicitly.
 */
export function useSVGAutoSize(compact?: boolean) {
  const parentRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const parent = parentRef.current
    // Wait for them to render to the DOM.
    setTimeout(() => {
      const svgs = parent?.querySelectorAll('svg')
      svgs?.forEach(svg => {
        if (svg && svg.clientWidth === 0 && svg.clientHeight > 0) {
          const [, , width, height]: string[] = svg.getAttribute('viewBox')?.split(' ') || []
          if (width && height) {
            const numWidth = parseFloat(width)
            const numHeight = parseFloat(height)
            const realWidth = (svg.clientHeight / numHeight) * numWidth
            if (!isNaN(realWidth)) {
              svg.setAttribute('width', realWidth.toString())
            }
          }
        }
      })
    }, 1)
  }, [compact])
  return parentRef
}
