import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  CreateSiteMutation,
  SiteFragment,
  SiteFragmentDoc,
  SitesDocument,
  useCreateSiteMutation as useMutation,
} from '../schema'

export const useCreateSiteMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error creating that site.'),
    refetchQueries: [{ query: SitesDocument }],
    update: updateMutation<CreateSiteMutation, SiteFragment>({
      path: 'createSite.site',
      fragment: SiteFragmentDoc,
    }),
    ...opts,
  })
}
