import { styled, s, Text, Button } from '@thesisedu/ui'
import { MinusCircle, NavArrowLeft } from '@thesisedu/ui/icons'
import React from 'react'

import { getSelectDevice } from '../selectDevice'
import { RecordState, RecordStateProps, getStateFactory } from '../types'

export interface ErrorContext {
  error: any
}
function Error({ context, onTransition }: RecordStateProps<ErrorContext>) {
  return (
    <Container>
      <MinusCircle />
      <Text>We ran into an error.</Text>
      <Button
        onPress={() => {
          onTransition(getSelectDevice({}))
        }}
        icon={<NavArrowLeft />}
      >
        Go Back
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${s.size('l')};
  > svg {
    font-size: ${s.size('xl')};
  }
`

export default {
  Component: Error,
} satisfies RecordState<ErrorContext>
export const getError = getStateFactory<ErrorContext>('error')
