import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { File } from '@thesisedu/feature-attachments-react'
import { FilePayload } from '@thesisedu/feature-widgets-core'
import { Button } from '@thesisedu/ui'
import { Upload, WarningCircle } from '@thesisedu/ui/icons'
import { LexicalEditor } from 'lexical'
import React from 'react'

import { UploadFile, useUploadFile } from './useUploadFile'
import { useEditorContext } from '../../context/EditorContext'

const MAX_SIZE = 10000000 // 10MB

export interface FileComponentProps extends FilePayload {
  afterUpload: (editor: LexicalEditor, file?: string) => void
  uploadFile?: UploadFile
}
export function FileComponent({
  afterUpload,
  uploadFile: _uploadFile,
  file: _file,
  label,
}: FileComponentProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const { noDynamicWidgets } = useEditorContext(false) || {}
  const [uploadFile, setUploadFile] = React.useState<UploadFile | undefined>(_uploadFile)
  const [fileTooLarge, setFileTooLarge] = React.useState(false)
  React.useEffect(() => {
    setUploadFile(_uploadFile)
  }, [_uploadFile])
  React.useEffect(() => {
    if (_file) setUploadFile(undefined)
  }, [_file])
  const [editor] = useLexicalComposerContext()
  const uploadFileResult = useUploadFile({
    uploadFile,
    afterUpload: (...args) => afterUpload(editor, ...args),
  })
  const file = uploadFile ? uploadFileResult.file : _file

  if (noDynamicWidgets) return null

  if (file) {
    return <File filename={file} label={label || undefined} />
  } else {
    return (
      <>
        <input
          ref={fileInputRef}
          type={'file'}
          style={{ display: 'none' }}
          onChange={e => {
            if (e.target.files && e.target.files.length === 1) {
              if (e.target.files[0].size <= MAX_SIZE) {
                setFileTooLarge(false)
                setUploadFile({
                  editor,
                  file: e.target.files[0],
                })
              } else {
                setFileTooLarge(true)
              }
            }
          }}
        />
        <Button
          style={{ width: '100%' }}
          icon={fileTooLarge ? <WarningCircle /> : <Upload />}
          status={fileTooLarge ? 'danger' : undefined}
          loading={!!uploadFileResult?.loading}
          onPress={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click()
            }
          }}
        >
          {fileTooLarge
            ? 'That file is too large, please upload another.'
            : 'Click here to upload a file...'}
        </Button>
      </>
    )
  }
}
