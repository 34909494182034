import { useFeatureRoot } from '@thesisedu/feature-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { Button } from '@thesisedu/react'
import { Download } from '@thesisedu/react/icons'
import { SaveAsImage, styled } from '@thesisedu/web'
import React from 'react'

import { Graph } from './Graph'
import { ReportResultsProps } from '../ReportResults'

export interface ReportGraphExportProps extends ReportResultsProps {}
export function ReportGraphExport(props: ReportGraphExportProps) {
  const [visible, setVisible] = React.useState(false)
  const root = useFeatureRoot() as FeatureWeb
  return (
    <>
      <SaveAsImage visible={visible} onCancel={() => setVisible(false)} background={'white'}>
        <GraphContainer>
          <Graph {...props} config={props.report.graphOptions || {}} noAnimation noMaxHeight />
          <LogoContainer>
            <img src={root.appOptions.logo()} alt={`${root.appOptions.name} Logo`} />
          </LogoContainer>
        </GraphContainer>
      </SaveAsImage>
      <Button
        type={'primary'}
        children={'Export'}
        icon={<Download />}
        onClick={() => setVisible(true)}
      />
    </>
  )
}

const GraphContainer = styled.div`
  position: relative;
`
const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => props.theme['@size-l']};
  display: flex;
  align-items: center;
  flex-direction: row;
  img {
    height: ${props => props.theme['@size-m']};
  }
`
