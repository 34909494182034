import { isVisibleToTeacher, reduceSegments } from '@thesisedu/feature-course-types'
import { orderBy } from 'lodash'

import { OutlineItem, OutlineItemType } from './types'
import { NON_GROUP_TYPES } from '../constants'
import { SegmentType } from '../schema'
import { GroupSegment, Segment } from '../types'

export function getMaximumDepth(segments: Segment[]): number {
  if (segments.length === 1) {
    if (segments[0].type === SegmentType.Topic) return 2
    if (segments[0].type === SegmentType.Group) return 1
  }
  return reduceSegments(
    segments,
    (max, segment, depth) => {
      return depth > max ? depth : max
    },
    0,
  )
}

export function between(
  previousWeight: number | undefined | null,
  nextWeight: number | undefined | null,
): number {
  const hasPrevious = previousWeight !== undefined && previousWeight !== null
  const hasNext = nextWeight !== undefined && nextWeight !== null
  if (hasPrevious && hasNext) {
    return previousWeight + (nextWeight - previousWeight) / 2
  } else if (hasNext) {
    return nextWeight - 1
  } else if (hasPrevious) {
    return previousWeight + 1
  } else return 0
}

export interface GenerateOutlineItemsOpts {
  depth?: number
  parentIds?: string[]
  segmentFilter?: (segment: Segment) => boolean
  hideEmptyGroups?: boolean
  collapseDepth?: number
  expandedParentIds?: string[]
  /** Segment IDs to forcefully collapse. Used inside the placement drawer. */
  forceCollapseIds?: string[]
  parentSegment: Segment
  maximumDepth?: number
}
export function generateOutlineItems(
  segments: (Segment | GroupSegment)[],
  opts: GenerateOutlineItemsOpts,
): OutlineItem[] {
  const {
    parentIds = [],
    depth = 0,
    segmentFilter,
    hideEmptyGroups,
    collapseDepth,
    expandedParentIds = [],
    parentSegment,
    maximumDepth = Infinity,
    forceCollapseIds,
  } = opts
  let resultItems: OutlineItem[] = []
  let lastDepth = depth

  // First, add all segments in the traditional fashion.
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    if (NON_GROUP_TYPES.includes(segment.type as SegmentType)) {
      if (isVisibleToTeacher(segment) && (!segmentFilter || segmentFilter(segment))) {
        if (depth < lastDepth) {
          resultItems.push({
            name: 'Additional Content',
            id: `additional-${segment.id}`,
            depth,
            type: OutlineItemType.HEADER,
            parentIds,
            place: {
              parentSegment,
              maximumAllowedDepth: maximumDepth - depth,
            },
          })
          lastDepth = depth
        }
        resultItems.push({
          parentIds,
          id: segment.id,
          segment,
          depth,
          type: OutlineItemType.SEGMENT,
          place: {
            parentSegment,
            maximumAllowedDepth: maximumDepth - depth,
          },
        })
      }
    } else {
      const childItems = generateOutlineItems(
        orderBy(
          (segment.childSegments || []).filter(
            s =>
              !NON_GROUP_TYPES.includes(s.type as SegmentType) ||
              (isVisibleToTeacher(s) && (!segmentFilter || segmentFilter(s))),
          ),
          'weight',
          'asc',
        ),
        {
          ...opts,
          maximumDepth,
          depth: depth + 1,
          parentIds: [...parentIds, segment.id],
          parentSegment: segment,
        },
      )
      if (childItems.length) lastDepth = depth + 1
      if (hideEmptyGroups && !childItems.length) continue
      const isCollapsed =
        (collapseDepth === lastDepth && !expandedParentIds?.includes(segment.id)) ||
        forceCollapseIds?.includes(segment.id)
      resultItems = [
        ...resultItems,
        {
          parentIds,
          segment,
          depth,
          id: segment.id,
          type: OutlineItemType.HEADER,
          collapsed: isCollapsed,
          collapsible: collapseDepth === lastDepth,
          place: {
            parentSegment: segment,
            trueParentSegment: parentSegment,
            maximumAllowedDepth: maximumDepth - depth,
          },
        },
        ...(isCollapsed ? [] : [...childItems]),
      ]
    }
  }

  return resultItems
}
