import { Transition, styled, Block, PageHead, PageHeader, Row, useBreadcrumb } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'

import { CreateDirectMessageThreadButton } from './CreateDirectMessageThreadModal'
import { DirectMessageThread } from './DirectMessageThread'
import { DirectMessageThreadList, DirectMessageThreadListProps } from './DirectMessageThreadList'

export interface DirectMessagesRouteProps {
  listProps?: Partial<DirectMessageThreadListProps>
}
export function DirectMessagesRoute({ listProps }: DirectMessagesRouteProps) {
  useBreadcrumb({ title: 'Messages' })
  const navigate = useNavigate()
  const listRef = React.useRef<HTMLDivElement>(null)
  return (
    <>
      <PageHead title={'Messages'} />
      <Block marginBottom={'@size-l'}>
        <PageHeader title={'Messages'}>
          <CreateDirectMessageThreadButton
            onCreated={id => {
              navigate(`./${id}`)
            }}
          />
        </PageHeader>
      </Block>
      <Row>
        <Col xs={24} md={8} xl={6}>
          <ContentContainer ref={listRef}>
            <DirectMessageThreadList
              {...listProps}
              infiniteQueryProps={{
                ...listProps?.infiniteQueryProps,
                infiniteScrollerProps: {
                  scrollableTarget: listRef.current || undefined,
                },
              }}
            />
          </ContentContainer>
        </Col>
        <Col xs={24} md={16} xl={18}>
          <Routes>
            <Route path={'/:threadId'} element={<ThreadRoute />} />
            <Route path={'/'} element={<span />} />
          </Routes>
        </Col>
      </Row>
    </>
  )
}

function ThreadRoute() {
  const { threadId } = useParams()
  if (threadId) {
    return (
      <Transition type={'fade-up'} state={threadId}>
        <div>
          <DirectMessageThread threadId={threadId} />
        </div>
      </Transition>
    )
  } else return null
}

const ContentContainer = styled.div`
  height: calc(100vh - 350px);
  min-height: 600px;
  overflow-y: auto;
`
