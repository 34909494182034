import { ClassLeftNavHook, ClassRoutesHook } from '@thesisedu/feature-classes-react'
import { Shell } from '@thesisedu/feature-web'
import { Check } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { ClassGrades } from './ClassGrades'
import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        // The student grades route is added by course-assignments-react.
        return [...routes, <Route key={'grades'} path={'grades/*'} element={<ClassGrades />} />]
      } else {
        return routes
      }
    },
  )
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    items => {
      return [
        ...items,
        {
          group: 'Grades',
          weight: 3,
          element: (
            <Shell.LeftMenuItem key={'grades'} name={'Grades'} icon={<Check />} path={'grades'} />
          ),
        },
      ]
    },
  )
}
