import { BrochureLink, useFeatureRoot } from '@thesisedu/feature-react'
import { Result } from '@thesisedu/react'
import { Text, Form, Modal, LoadingIndicator, Block, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { CredentialHealthCheck } from './CredentialHealthCheck'
import { useRecordCanvasCredentialsMutation, RecordCanvasCredentialsInput } from '../schema'

// used to extract the baseUrl for a string with http or https
// Example: https://test.com/32?admin=true => https://test.com
const getBaseUrl = (url: string): RegExpMatchArray | null => url.match(/^https?:\/\/[^#/?]+/)

export function CanvasPage() {
  const form = Form.useForm<Omit<RecordCanvasCredentialsInput, 'token'>>()
  const [recordCredentials, { loading, error, data }] = useRecordCanvasCredentialsMutation()
  const root = useFeatureRoot()
  const healthCheckProps = form.watch()
  const [isHealthy, setIsHealthy] = React.useState(false)
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  const { confirm, modal } = Modal.useConfirmModal()
  if (loading) {
    return <LoadingIndicator.Labeled label={'Saving...'} size={'l'} />
  } else if (error) {
    return (
      <Result.Error
        title={'Error'}
        description={`There was an error recording your Canvas credentials. Please contact ${root.appOptions.name} support.`}
      />
    )
  } else if (data?.recordCanvasCredentials.success) {
    return (
      <Result.Success
        title={'Success!'}
        description={`Your information has been saved successfully. Thanks for using ${root.appOptions.name}`}
      />
    )
  } else {
    const submitCredentials = ({
      url,
      consumerKey,
      consumerSecret,
    }: Omit<RecordCanvasCredentialsInput, 'token'>) => {
      const _url = getBaseUrl(url)
      if (token && Array.isArray(_url) && _url[0]) {
        const finish = () => {
          recordCredentials({
            variables: {
              input: {
                token,
                consumerKey: consumerKey.trim(),
                consumerSecret: consumerSecret.trim(),
                url: _url[0],
              },
            },
          })
        }
        if (isHealthy) {
          finish()
        } else {
          confirm({
            title: "It doesn't look like your information is valid.",
            width: 500,
            children: (
              <>
                <Text bottom>
                  <strong>
                    If you submit these changes anyway, your Canvas integration might not work.
                  </strong>
                </Text>
                <Text>
                  We recommend you click <strong>Cancel</strong> and review the information you've
                  entered, in addition to reviewing our support documentation, to make sure
                  everything entered is accurate. If you are having troubles, please don't hesitate
                  to reach out to {root.appOptions.name} Support.
                </Text>
              </>
            ),
            confirm: { status: 'danger', children: 'Save Anyway' },
            onConfirm: finish,
          })
        }
      }
    }
    return (
      <>
        {modal}
        <Text level={'h1'}>Canvas</Text>
        <Text bottom>
          In order for teachers to get the most out of {root.appOptions.name}, you'll need to create
          a <strong>Developer Key</strong> inside your Canvas administration portal. You can opt to
          allow our service access to all scopes (we don't recommend it), or you can restrict our
          service to the scopes we require, listed in our{' '}
          <BrochureLink to={'support/portal/canvas-credentials'} target={'_blank'}>
            support article.
          </BrochureLink>
        </Text>
        <Text bottom>We also need the URL to your Canvas portal.</Text>
        <Text bottom>
          For more information on how this works and why we're request specific scopes, check out
          our{' '}
          <BrochureLink to={'support/portal/canvas-credentials'} target={'_blank'}>
            support article.
          </BrochureLink>
        </Text>
        <Block top={'l'}>
          <Form form={form} onFinish={submitCredentials}>
            <VSpaced space={'m'}>
              <Form.TextField
                name={'url'}
                label={'Canvas URL'}
                required
                validate={(value: any) => {
                  // Is value a valid URL starting with https?
                  if (!value.match(/^https:\/\/[^#/?]+/)) {
                    return 'Must be a valid URL starting with https://'
                  }
                }}
                placeholder={'https://canvas.schooldistrict.edu'}
              />
              <Form.TextField
                name={'consumerKey'}
                label={'Consumer Key'}
                required={{ value: true, message: 'A Consumer Key is required' }}
                data-sentry-ignore
              />
              <Form.TextField
                name={'consumerSecret'}
                label={'Consumer Secret'}
                required={{ value: true, message: 'A Consumer Secret is required' }}
                data-sentry-ignore
              />
              <CredentialHealthCheck
                {...healthCheckProps}
                onHealthChange={({ healthy }) => {
                  setIsHealthy(healthy)
                }}
              />
              <Block style={{ textAlign: 'right' }}>
                <Form.Submit size={'large'} children={'Save and Continue'} />
              </Block>
            </VSpaced>
          </Form>
        </Block>
      </>
    )
  }
}
