import { useClasses } from '@thesisedu/feature-classes-react'
import { HSpaced, Text, Button, Button$, styled, Modal, s } from '@thesisedu/ui'
import pluralize from 'pluralize'
import React from 'react'

import { AssignmentCategorySelect } from './AssignmentCategorySelect'

export interface MultiAssignmentCategorySelectProps {
  value?: string[]
  onChange?: (value: string[]) => void
  classIds: string[]
}
export function MultiAssignmentCategorySelect({
  value,
  onChange,
  classIds,
}: MultiAssignmentCategorySelectProps) {
  const { classes } = useClasses()
  const filteredClasses = classes.filter(cls => classIds.includes(cls.id))
  return (
    <Container>
      {filteredClasses.map(filteredClass => (
        <HSpaced key={filteredClass.id}>
          <ClassNameContainer as={'div'}>{filteredClass.name}</ClassNameContainer>
          <SelectContainer>
            <AssignmentCategorySelect
              classId={filteredClass.id}
              possibleValues={value}
              onChange={(newId, replaceId) => {
                if (onChange) {
                  onChange([...(value?.filter(v => v !== replaceId) || []), newId])
                }
              }}
            />
          </SelectContainer>
        </HSpaced>
      ))}
    </Container>
  )
}
const ClassNameContainer = styled(Text)`
  flex: 0.5;
  text-align: right;
`
const SelectContainer = styled.div`
  flex: 0.5;
  > * {
    width: 100%;
  }
`
const Container = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${s.size('xs')};
  }
`

export interface MultiAssignmentCategorySelectButtonProps
  extends MultiAssignmentCategorySelectProps {
  buttonProps?: Partial<Button$.ButtonProps>
}
export function MultiAssignmentCategorySelectButton({
  buttonProps,
  ...rest
}: MultiAssignmentCategorySelectButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const label = rest.value?.length
    ? pluralize('category', rest.value.length, true)
    : 'Select Categories'
  return (
    <>
      <Modal title={'Select Categories'} visible={visible} onVisibleChange={setVisible}>
        <MultiAssignmentCategorySelect {...rest} />
        <Modal.Footer>
          <Modal.CloseButton variant={'primary'} children={'Save'} />
        </Modal.Footer>
      </Modal>
      <Button children={label} {...buttonProps} />
    </>
  )
}
