import { onMutationError } from '@thesisedu/feature-apollo-react'
import { notNil } from '@thesisedu/feature-utils'
import { Button, Button$, Modal, Text, useToast } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentCategorySelect } from '../AssignmentCategorySelect'
import { debug } from '../log'
import { useDeleteAssignmentCategoryMutation } from '../schema'

export interface DeleteCategoryButtonProps extends Partial<Button$.ButtonProps> {
  assignmentCategoryId: string
  classId: string
}
export function DeleteCategoryButton({
  assignmentCategoryId,
  classId,
  ...buttonProps
}: DeleteCategoryButtonProps) {
  const [selectReplacementMode, setSelectReplacementMode] = React.useState<
    undefined | 'assignments' | 'default'
  >(undefined)
  const [replacementCategoryId, setReplacementCategoryId] = React.useState<string | undefined>(
    undefined,
  )
  const toast = useToast()
  const [deleteCategory, { loading }] = useDeleteAssignmentCategoryMutation({
    onError: onMutationError(
      'There was an error deleting your grading category.',
      ['CANNOT_DELETE_USED_CATEGORY_ERROR', 'CANNOT_DELETE_DEFAULT_CATEGORY_ERROR'],
      code => {
        if (code === 'CANNOT_DELETE_USED_CATEGORY_ERROR') {
          setSelectReplacementMode('assignments')
        } else if (code === 'CANNOT_DELETE_DEFAULT_CATEGORY_ERROR') {
          setSelectReplacementMode('default')
        }
      },
    ),
    update(cache, result, options) {
      const ids = [
        options.variables?.input.id,
        options.variables?.input.replacementCategoryId,
      ].filter(notNil)
      for (const id of ids) {
        debug('evicting category %s from cache', id)
        cache.evict({
          id: cache.identify({ __typename: 'AssignmentCategory', id }),
        })
      }
      cache.evict({
        id: cache.identify({ __typename: 'Class', id: classId }),
        fieldName: 'assignmentCategories',
      })
      cache.evict({
        id: cache.identify({ __typename: 'Class', id: classId }),
        fieldName: 'assignmentCategoriesMap',
      })
    },
    onCompleted() {
      toast({
        title: 'Grading Category deleted',
        status: 'success',
      })
    },
  })

  return (
    <>
      <Modal.Confirm
        title={'Are you sure?'}
        children={'Are you sure you want to delete this grading category?'}
        width={600}
        confirm={{ status: 'danger', children: 'Yes, delete grading category' }}
        cancel={{ children: 'No, keep' }}
        onConfirm={async () => {
          try {
            await deleteCategory({
              variables: {
                input: { id: assignmentCategoryId },
              },
            })
          } catch {
            // Ignore any errors so the modal closes...
          }
        }}
        trigger={<Button status={'danger'} icon={<Trash />} {...buttonProps} />}
      />
      {selectReplacementMode ? (
        <Modal
          visible
          onVisibleChange={() => setSelectReplacementMode(undefined)}
          title={'Select a Replacement Category'}
          width={600}
        >
          {selectReplacementMode === 'default' ? (
            <>
              <Text bottom>
                The grading category you are trying to delete is currently associated with a default
                grading category. If you delete this category without choosing a replacement, we
                won't know which category to add new assignments to.
              </Text>
              <Text bottom>What grading category do you want to use instead?</Text>
            </>
          ) : (
            <>
              <Text bottom>
                The grading category you are trying to delete has assignments currently associated
                with it.
              </Text>
              <Text bottom>What grading category do you want to move those assignments to?</Text>
            </>
          )}
          <AssignmentCategorySelect
            classId={classId}
            value={replacementCategoryId}
            onChange={setReplacementCategoryId}
            hideCategoryIds={[assignmentCategoryId]}
          />
          <Modal.Footer>
            <Modal.CloseButton />
            <Button
              variant={'primary'}
              children={
                selectReplacementMode === 'assignments'
                  ? 'Move Assignments and Delete Category'
                  : 'Delete Category'
              }
              status={'danger'}
              loading={loading}
              disabled={!replacementCategoryId}
              onPress={async () => {
                if (!replacementCategoryId) return
                await deleteCategory({
                  variables: {
                    input: {
                      id: assignmentCategoryId,
                      replacementCategoryId,
                    },
                  },
                })
                setSelectReplacementMode(undefined)
              }}
            />
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  )
}
