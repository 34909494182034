import { useImageAlignmentSetting } from './alignment'
import { useImageCaptionSetting } from './caption'
import { useClearImageSetting } from './clear'
import { useOtherImageSettings } from './other'
import { useImageRadiusSetting } from './radius'

export function useImageSettings() {
  useImageCaptionSetting()
  useImageAlignmentSetting()
  useImageRadiusSetting()
  useClearImageSetting()
  return useOtherImageSettings()
}
