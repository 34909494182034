import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { MediaType } from './schema'

export interface MediaTypeSelectProps extends Omit<SelectProps<string>, 'value' | 'onChange'> {
  value?: MediaType[]
  onChange?: (types: MediaType[]) => any
}
export const MediaTypeSelect = React.forwardRef<any, MediaTypeSelectProps>(
  ({ value, onChange, ...props }, ref) => (
    <Select<string>
      ref={ref}
      {...props}
      value={(value || []).join(',') || undefined}
      onChange={selected => (onChange ? onChange(selected.split(',') as MediaType[]) : undefined)}
    >
      <Select.Option value={MediaType.Audio}>Audio Only</Select.Option>
      <Select.Option value={[MediaType.Audio, MediaType.Video].join(',')}>
        Video & Audio
      </Select.Option>
    </Select>
  ),
)
