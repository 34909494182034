import { styled } from '@thesisedu/web'
import React from 'react'

export function DefaultGridPlaceholder() {
  return (
    <div>
      <PlaceholderHero>
        <PlaceholderBlock />
      </PlaceholderHero>
      <PlaceholderTitle />
      <PlaceholderSubtitle />
    </div>
  )
}

const PlaceholderBlock = styled.div`
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
`
const PlaceholderHero = styled.div`
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: ${props => props.theme['@size-s']};
  > ${PlaceholderBlock} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`
const PlaceholderTitle = styled(PlaceholderBlock)`
  height: calc(${props => props.theme['@font-size-lg']} + ${props => props.theme['@size-xs']});
  margin-bottom: ${props => props.theme['@size-xs']};
`
const PlaceholderSubtitle = styled(PlaceholderBlock)`
  width: 32%;
  height: calc(${props => props.theme['@font-size-sm']} + ${props => props.theme['@size-xs']});
  opacity: 0.75;
`
