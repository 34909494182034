import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import React from 'react'

import { SegmentPreview } from './SegmentPreview'
import { SegmentSelect, SegmentSelectProps } from './SegmentSelect'
import { SegmentSummary } from './SegmentSummary'

export interface SegmentSelectFieldProps
  extends Partial<Pick<SegmentSelectProps, 'segmentListProps'>> {
  value?: string
  onChange?: (segmentDatabaseId: string | undefined | null) => any
}
export const SegmentSelectField = React.forwardRef<any, SegmentSelectFieldProps>(
  ({ value, onChange, segmentListProps }) => {
    if (value) {
      return (
        <>
          <SegmentSummary
            segmentDatabaseId={toGlobalId('Segment', value)}
            onAct={() => {
              if (onChange) {
                onChange(null)
              }
            }}
            action={'Choose Another'}
          />
          <SegmentPreview isModal={false} segmentDatabaseId={toGlobalId('Segment', value)} />
        </>
      )
    } else {
      return (
        <SegmentSelect
          onSegmentSelected={segment => {
            if (onChange) {
              onChange(fromGlobalId(segment.id, true).id)
            }
          }}
          segmentListProps={segmentListProps}
        />
      )
    }
  },
)
