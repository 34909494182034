import {
  SelectedTeacher,
  TeacherSelectorModalProps,
} from '@thesisedu/feature-teachers-react/dist/selector/types'
import { Format } from '@thesisedu/feature-utils'
import React from 'react'

import { useShareNodeWithUsersMutation } from './queries/useShareNodeWithUsersMutation'
import { useNodeSharedUsersQuery } from './schema'
import { ShareActionItemProps } from './types'

export const useShareActionItem = ({ shareable }: ShareActionItemProps, onSuccess: () => void) => {
  const [visible, setVisible] = React.useState(false)
  const sharedFrom = shareable.sharing.sharedFrom
  const disabled = !!sharedFrom
  const { data, loading } = useNodeSharedUsersQuery({
    variables: { id: shareable.id },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !visible,
  })
  const sharedWith =
    data?.node && 'sharing' in data.node
      ? data.node.sharing.sharedWith.edges.map(edge => edge.node)
      : []
  const selectedTeachers = sharedWith
    .filter(item => !!item.teacher)
    .map<SelectedTeacher>(item => ({
      userId: item.id,
      teacherId: item.teacher!.id,
    }))
  const [share] = useShareNodeWithUsersMutation({
    onCompleted: () => {
      setVisible(false)
      onSuccess()
    },
  })
  let messageReg: string
  let messageBold: string
  if (sharedFrom) {
    messageReg = 'Shared from'
    messageBold = sharedFrom.name || sharedFrom.username
  } else if (shareable.sharing.sharedWith.totalCount) {
    messageReg = 'Shared with'
    messageBold = Format.plural('user', shareable.sharing.sharedWith.totalCount)
  } else {
    messageReg = 'Share'
    messageBold = ''
  }
  const teacherModalProps: TeacherSelectorModalProps = {
    onVisibleChange: setVisible,
    onSelected: async teachers => {
      await share({
        variables: {
          input: {
            replace: true,
            id: shareable.id,
            userIds: teachers.map(teacher => teacher.userId),
          },
        },
      })
    },
    title: 'Share with Teachers',
    submit: 'Share',
    visible: visible && !loading && !!data,
    selectedTeachers,
  }
  const onClick = () => setVisible(true)

  return { teacherModalProps, onClick, messageReg, messageBold, disabled }
}
