import { DndContext } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { findSegment, SegmentMetadataSegment } from '@thesisedu/feature-course-types'
import { mergeCollections } from '@thesisedu/feature-utils'
import { Body, styled, useForwardedRef } from '@thesisedu/react'
import { message } from 'antd'
import React from 'react'

import { DragDropOutlineContext } from './DragDropOutlineContext'
import { OutlineProps, useOutline } from './Outline'
import { OutlineListImperativeHandle } from './OutlineList'
import { OutlineListContextProvider } from './OutlineListContext'
import { useIsSearching } from './OutlineSearchContext'
import { useSelectedTermId } from './TermSelector'
import { OutlineListConfigContext } from './context/OutlineListConfigContext'
import { getDragEndPlacement } from './getDragEndPlacement'
import { DraggableOutlineItem } from './items/DraggableOutlineItem'
import { DraggableOutlineItemPreview } from './items/DraggableOutlineItemPreview'
import { OutlineItem, isItemWithSegment } from './types'
import { useDragDropOutline } from './useDragDropOutline'
import { useExpandedParentIds } from './useExpandedParentIds'
import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { useCourseContext } from '../contexts/CourseContext'
import { clearParentsCache } from '../helpers'
import { debug, error } from '../log'
import { GroupSegment } from '../types'

export const LOADING_KEY = 'drag-drop-outline-saving'
export const DRAGGABLE_HEIGHT = 60

function _DragDropOutline(
  props: OutlineProps,
  ref: React.ForwardedRef<OutlineListImperativeHandle>,
) {
  const itemsRef = React.useRef<OutlineItem[]>([])
  const listRef = useForwardedRef(ref)
  const isSearching = useIsSearching()
  const [loading, setLoading] = React.useState(false)
  const courseClass = useCourseContext(true)
  const [updateClass] = useUpdateClassMutation(props.courseOrClassId, {
    onCompleted() {
      message.success({
        key: LOADING_KEY,
        content: 'Order updated!',
      })
    },
    onError() {
      message.destroy(LOADING_KEY)
    },
  })

  // Hooks for keeping track of expanded parents / loading the selected term.
  const [expandedParentIds, setExpandedParentIds] = useExpandedParentIds(props.courseOrClassId)
  const ogSelectedTermId = useSelectedTermId(props.courseOrClassId, props.segments)
  const [selectedTermId, setSelectedTermId] = props.setSelectedTermId
    ? [props.selectedTermId, props.setSelectedTermId]
    : ogSelectedTermId
  const selectedTerm = props.segments?.find(s => s.id === selectedTermId)

  // The drag / drop helpers.
  const { dndContextProps, overlayPortal, activeId, offsetLeft } = useDragDropOutline({
    async onDragEnd({ moving, over }) {
      const movingId = moving.id.toString()
      const overId = over.id.toString()
      const dragEndResult = getDragEndPlacement({
        movingId,
        overId,
        offsetLeft,
        items: itemsRef.current,
        selectedTerm,
      })
      if (dragEndResult) {
        const { structureOverrides, metadataOverrides } = dragEndResult

        // Save the results:
        //   - if inside the same parent, just the new weight
        //   - if inside a different parent, then weight + parent
        message.loading({
          content: 'Saving order...',
          duration: 0,
          key: LOADING_KEY,
        })
        debug('saving order...')
        if (structureOverrides.length) setLoading(true)
        try {
          const segments: SegmentMetadataSegment[] = courseClass.segmentMetadata?.segments || []

          // Add the weight overrides & structure overrides.
          const newSegments = mergeCollections(segments, metadataOverrides)
          const newStructureOverrides = mergeCollections(
            courseClass.segmentMetadata?.structureOverrides || [],
            structureOverrides,
          )

          clearParentsCache()
          await updateClass(
            {
              segmentMetadata: {
                ...courseClass.segmentMetadata,
                segments: newSegments,
                structureOverrides: newStructureOverrides,
              },
            },
            !!structureOverrides.length,
          )
          clearParentsCache()

          // Wait a bit for the DOM to update.
          setTimeout(() => {
            listRef.current?.highlightSegment(movingId)
          }, 10)
        } catch (err: any) {
          error('error updating class orders')
          error(err)
          message.destroy(LOADING_KEY)
        } finally {
          setLoading(false)
        }
      }
    },
    renderPortalItem(id) {
      const item = itemsRef.current.find(item => item.id === id)
      const segment = item && isItemWithSegment(item) ? item.segment : undefined
      return <DraggableOutlineItemPreview segment={segment} />
    },
  })

  // Preparing the outline itself.
  const activeSegment = activeId ? findSegment(props.segments, activeId.toString()) : undefined
  const { items, children } = useOutline(
    {
      ...props,
      segments: props.segments,
      expandedParentIds,
      forceCollapseIds: activeSegment ? [activeSegment.id] : undefined,
      onExpandedParentIdsChange: setExpandedParentIds,
      setSelectedTermId,
      selectedTermId,
      headerContent: isSearching ? (
        <IsSearchingMessage color={'@text-color-secondary'}>
          You cannot drag and drop content while searching. Clear your search to rearrange content.
        </IsSearchingMessage>
      ) : undefined,
      headerContentHeight: isSearching ? 46 : 0,
      forceRenderIds: activeId ? [activeId.toString()] : undefined,
    },
    listRef,
  )

  // Getting the final outline items list, and updating the ref.
  itemsRef.current = items

  return (
    <OutlineListContextProvider
      items={items}
      selectedTerm={selectedTerm! as GroupSegment}
      highlightedItemIds={[]}
    >
      <DragDropOutlineContext.Provider
        value={{
          offsetLeft,
          draggingId: activeId?.toString(),
        }}
      >
        <DndContext {...dndContextProps}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <OutlineListConfigContext.Provider
              value={{
                outlineItemComponent: DraggableOutlineItem,
              }}
            >
              <DragContainer loading={loading}>
                {children}
                {overlayPortal}
              </DragContainer>
            </OutlineListConfigContext.Provider>
          </SortableContext>
        </DndContext>
      </DragDropOutlineContext.Provider>
    </OutlineListContextProvider>
  )
}
export const DragDropOutline = React.forwardRef(_DragDropOutline)

const IsSearchingMessage = styled(Body)`
  margin-left: calc(300px + ${props => props.theme['@size-l']});
`
const DragContainer = styled.div<{ loading?: boolean }>`
  transition: opacity 0.25s linear;
  opacity: ${props => (props.loading ? 0.25 : 1)};
`
