import * as React from 'react'

function SvgSolfegeFi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      viewBox={'0 0 256 256'}
      {...props}
    >
      <defs>
        <path id={'solfege-fi_svg__a'} d={'M0 0h230v229.999H0z'} />
      </defs>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M238.494 128.001c0 61.025-49.47 110.495-110.495 110.495-61.025 0-110.496-49.47-110.496-110.495 0-61.025 49.47-110.495 110.496-110.495 61.025 0 110.495 49.47 110.495 110.495'
          }
        />
        <g transform={'translate(13 13)'}>
          <mask id={'solfege-fi_svg__b'} fill={'#fff'}>
            <use xlinkHref={'#solfege-fi_svg__a'} />
          </mask>
          <path
            fill={'#63BF83'}
            d={
              'M114.998 9.012C56.558 9.012 9.013 56.556 9.013 115s47.545 105.99 105.985 105.99c58.444 0 105.989-47.545 105.989-105.989S173.442 9.012 114.998 9.012m0 220.987C51.59 229.999 0 178.409 0 115 0 51.591 51.59 0 114.998 0 178.411-.001 230 51.59 230 115s-51.59 115-115.002 115'
            }
            mask={'url(#solfege-fi_svg__b)'}
          />
        </g>
        <path
          fill={'#63BF83'}
          d={
            'M97.11 51.824l44.28-3.204-2.481 20.819-20.178.802-.881 6.242 16.814-1.038 1.124 16.814-16.896 1.038-.802 20.497-20.418.483z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M98.358 52.974l.54 60.033 18.002-.422.795-20.458 16.774-1.03-.96-14.34-17.095 1.052 1.235-8.766 20.156-.799 2.177-18.28-41.624 3.01zm-1.911 62.571l-.584-64.873L142.8 47.28l-2.785 23.356-20.2.799-.526 3.719 16.53-1.02 1.286 19.287-17.018 1.045-.802 20.54-22.837.54z'
          }
        />
        <path fill={'#63BF83'} d={'M157.033 67.806l-2.298 45.343-14.74 1.682.562-45.064z'} />
        <path
          fill={'#502D1B'}
          d={
            'M141.782 70.868l-.53 42.572 12.3-1.403 2.172-42.83-13.942 1.661zm-3.043 45.354l.594-47.555 19.012-2.263-2.424 47.856-17.182 1.962z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M58.792 158.79v27.444h15.56c1.268 0 4.854 4.643 15.2 6.755 10.345 2.112 22.802-.315 24.28-.526 1.475-.212 5.301-35.292 4.245-36.77-1.052-1.475-16.068-.705-18.392-3.658-2.323-2.957 7.389-10.768 6.544-19.635-.845-8.866-5.91-6.965-7.81-6.754-1.902.21-1.48 9.922-6.544 14.776-5.065 4.854-16.044 17.1-18.79 17.945-2.745.845-14.293.422-14.293.422'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M60.405 184.62h13.95c.88 0 1.582.495 2.749 1.314 2.026 1.414 5.792 4.052 12.772 5.477 6.039 1.235 13.75 1.088 22.92-.411 1.353-4.418 4.181-29.5 3.859-34.218-1.06-.243-3.483-.437-5.327-.583-6.711-.537-11.24-1.042-12.911-3.168-1.726-2.202-.004-5.076 1.994-8.402 2.061-3.436 4.632-7.717 4.216-12.077-.25-2.581-.902-4.342-1.893-5.101-.963-.73-2.302-.508-3.369-.319-.1.018-.2.033-.293.047-.244.558-.534 1.808-.773 2.838-.827 3.53-1.958 8.355-5.309 11.57-1.54 1.475-3.622 3.633-5.856 5.945-7.013 7.26-11.426 11.713-13.578 12.375-2.344.723-9.722.637-13.151.54v24.174zm38.606 10.847c-3.594 0-6.862-.3-9.78-.895-7.635-1.56-11.758-4.45-13.974-6-.444-.307-.995-.697-1.178-.765l-16.896.039v-30.727l1.672.057c4.35.165 11.942.204 13.753-.35 1.568-.524 8.226-7.418 12.203-11.534 2.27-2.345 4.385-4.54 5.949-6.036 2.66-2.545 3.666-6.84 4.396-9.972.687-2.921 1.177-5.03 3.082-5.24l.576-.09c1.332-.23 3.794-.659 5.878.934 1.772 1.353 2.799 3.762 3.143 7.356.519 5.416-2.474 10.406-4.661 14.043-.93 1.557-2.495 4.16-2.223 4.75.924 1.174 7.481 1.693 10.632 1.944 5.15.411 6.976.612 7.81 1.779 1.718 2.409-2.57 35.087-3.801 37.961-.34.791-.852 1.242-1.532 1.34l-.598.092c-5.27.877-10.098 1.314-14.451 1.314z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M123.667 154.449c-2.585-1.597-18.26-1.715-20.479.644-2.216 2.356-1.267 8.036 2.112 9.107 3.38 1.07 15.94.644 18.367 0 2.427-.645 2.427-8.251 0-9.751'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M114.211 154.44c-4.542 0-9.257.455-10.184 1.439-.83.884-1.11 2.663-.68 4.32.383 1.483 1.246 2.567 2.302 2.904 3.146 1.002 15.456.583 17.726-.018.186-.05.651-.616.866-2.048.369-2.516-.33-5.08-1.178-5.609-1.038-.64-4.879-.988-8.852-.988m-.677 11.559c-3.558 0-6.998-.197-8.583-.702-1.844-.584-3.24-2.234-3.834-4.518-.64-2.455-.154-5 1.235-6.479 2.742-2.917 19.025-2.62 21.921-.83 2.033 1.256 2.674 5.008 2.245 7.904-.322 2.19-1.232 3.586-2.556 3.937-1.453.39-6.028.688-10.428.688'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M123.553 164.47c-2.746-1.596-19.409-1.715-21.764.644-2.356 2.356-1.346 8.037 2.244 9.107 3.59 1.07 16.939.644 19.52 0 2.58-.644 2.58-8.251 0-9.751'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M113.54 164.473c-4.868 0-9.937.458-10.936 1.453-.863.863-1.163 2.53-.755 4.152.394 1.55 1.331 2.685 2.516 3.04 3.369 1.009 16.502.586 18.908-.015.268-.064.77-.712.973-2.17.326-2.287-.282-4.893-1.274-5.469-1.11-.64-5.194-.991-9.432-.991m-.755 11.55c-3.759 0-7.396-.196-9.078-.697-1.97-.59-3.498-2.338-4.092-4.679-.612-2.416-.075-4.907 1.36-6.35 2.896-2.892 20.096-2.595 23.153-.823h.004c2.101 1.22 2.803 4.922 2.398 7.785-.326 2.277-1.281 3.73-2.699 4.081-1.539.383-6.386.684-11.046.684'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M121.419 174.864c-2.628-1.596-18.579-1.715-20.834.644-2.255 2.356-1.288 8.037 2.148 9.107 3.436 1.07 16.216.644 18.686 0 2.47-.644 2.47-8.251 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M111.813 174.858c-4.632 0-9.45.455-10.395 1.447-.852.887-1.135 2.605-.705 4.274.39 1.5 1.27 2.595 2.362 2.935 3.208 1.002 15.75.587 18.056-.014.197-.054.68-.63.895-2.084.365-2.498-.348-5.043-1.203-5.566-1.06-.64-4.965-.992-9.01-.992m-.705 11.559c-3.612 0-7.11-.197-8.72-.705-1.854-.577-3.318-2.277-3.905-4.557-.63-2.449-.133-4.976 1.27-6.443 2.778-2.91 19.32-2.613 22.265-.83 2.052 1.245 2.707 4.986 2.284 7.87-.325 2.213-1.242 3.623-2.591 3.978-1.479.386-6.129.687-10.603.687'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M119.179 184.992c-2.363-1.397-16.692-1.5-18.718.562-2.026 2.061-1.16 7.03 1.93 7.968 3.085.938 14.568.562 16.788 0 2.22-.562 2.22-7.217 0-8.53'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M110.988 185.104c-4.306 0-8.913.451-9.708 1.26-.716.727-.956 2.148-.605 3.537.33 1.289 1.095 2.23 2.048 2.52 2.878.866 14.114.505 16.172-.014.13-.036.54-.512.72-1.754.304-2.112-.3-4.242-1.024-4.668v-.004c-1.013-.598-4.217-.877-7.603-.877m-1.07 10.134c-3.254 0-6.408-.172-7.865-.616-1.729-.522-3.078-2.076-3.608-4.152-.555-2.17-.086-4.417 1.192-5.724 2.524-2.563 17.48-2.32 20.128-.744 1.876 1.11 2.495 4.424 2.13 6.98-.29 2.033-1.156 3.333-2.434 3.655-1.328.34-5.513.601-9.543.601'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M49.09 156.362v32.824s8.17.097 8.377 0c8.552-4.105 1.07-32.842 1.07-32.842l-9.446.018z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M49.23 189.047c3.2.043 7.907.075 8.193.007 8.08-3.88 1.49-30.659 1.006-32.57l-9.2.017v32.546zm5.727.326c-1.794 0-4.16-.025-5.867-.047h-.14v-33.104l9.697-.018.029.104c.018.072 1.88 7.285 2.674 14.906 1.07 10.277-.218 16.37-3.827 18.098-.09.043-1.149.06-2.566.06z'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M58.588 188.142l-8.505 1.088c.54-5.87 1.872-23.543-.673-31.811l8.49-.323c1.02 3.53 3.93 15.722.688 31.046m3.304-27.165c-.104-.115.075-.154.06-.297-.043-.466-.29-1-.597-1.543l-.029.2c.029-.229.04-.458.032-.687-.007-.018-.01-.036-.018-.054a2.363 2.363 0 01-.204-.988c-.397-1.539-.716-2.448-.766-2.591a1.492 1.492 0 00-1.453-.992l-11.741.448a1.482 1.482 0 00-1.242.77 1.531 1.531 0 00-.004 1.474c3.197 5.892 1.815 26.604 1.01 34.089a1.532 1.532 0 00.837 1.532c.254.121.54.171.827.132l11.39-1.453a1.497 1.497 0 001.264-1.16 77.87 77.87 0 00.426-2.008c.068-.011.154-.025.236-.04.086-1.052.312-2.09.523-3.164a6.07 6.07 0 00-.2.032c1.539-10.076.658-18.442-.351-23.7'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M49.596 157.551c2.474 8.348 1.142 25.988.641 31.518l8.233-1.052c3.182-15.149.333-27.255-.676-30.777l-8.198.311zm.333 31.84l.015-.175c.486-5.348 1.89-23.443-.666-31.754l-.054-.176 8.78-.333.03.104c.987 3.4 3.955 15.704.69 31.114l-.021.097-.097.014-8.677 1.11zm9.042-35.223h-.05l-11.74.444a1.34 1.34 0 00-1.121.695c-.237.422-.24.923-.007 1.346 3.218 5.92 1.829 26.671 1.027 34.167-.047.415.104.837.394 1.131.107.104.229.19.368.261.233.111.498.15.745.122l11.39-1.457a1.36 1.36 0 001.146-1.052c.136-.602.272-1.235.426-2.001l.018-.09.225-.046c.082-.906.258-1.794.444-2.732l.029-.143v.004l-.194.032.029-.193c1.536-10.027.666-18.374-.344-23.618-.09-.136-.003-.258.029-.298.021-.028.029-.039.029-.05-.04-.426-.269-.916-.509-1.356l-.118-.015c.025-.197.036-.4.032-.644-.007-.021-.01-.036-.014-.05a2.437 2.437 0 01-.208-.999 37.366 37.366 0 00-.751-2.538c-.204-.565-.71-.92-1.275-.92zm-10.549 38.456a1.607 1.607 0 01-1.142-.476 1.673 1.673 0 01-.48-1.357c.803-7.474 2.188-28.146-.994-34.006a1.67 1.67 0 01.007-1.614 1.609 1.609 0 011.353-.838l11.745-.444h.06a1.63 1.63 0 011.532 1.078c.255.748.516 1.639.77 2.61l.004.035c0 .333.064.644.19.923l.032.115c.003.161 0 .308-.008.447.272.487.559 1.06.602 1.568a.347.347 0 01-.079.24h.004l.01.043c1.006 5.233 1.883 13.556.38 23.557h.007l.208-.043-.111.548c-.194.967-.376 1.876-.451 2.807l-.008.103-.25.05a76.832 76.832 0 01-.408 1.916 1.642 1.642 0 01-1.382 1.27l-11.39 1.457c-.068.007-.136.01-.2.01z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M99.495 146.45c-2.044 2.65-2.145 5.356-3.154 8.434-1.325 4.049-4.772 6.68-8.054 9.128-1.504 1.12-.036 3.676 1.485 2.545 2.875-2.14 5.785-4.32 7.765-7.367 2.287-3.518 1.907-7.889 4.503-11.254 1.156-1.5-1.404-2.967-2.545-1.485'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M199.208 158.79v27.444h-15.56c-1.267 0-4.854 4.643-15.2 6.755-10.344 2.112-22.801-.315-24.28-.526-1.474-.212-5.301-35.292-4.245-36.77 1.052-1.475 16.069-.705 18.392-3.658 2.323-2.957-7.388-10.768-6.544-19.635.845-8.866 5.91-6.965 7.811-6.754 1.9.21 1.478 9.922 6.544 14.776 5.065 4.854 16.043 17.1 18.789 17.945 2.745.845 14.293.422 14.293.422'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M145.203 191c9.168 1.5 16.874 1.646 22.924.411 6.977-1.425 10.742-4.063 12.768-5.477 1.167-.82 1.873-1.313 2.753-1.313h13.95v-24.173c-3.426.1-10.807.182-13.159-.54-2.144-.663-6.558-5.116-13.574-12.376-2.233-2.312-4.317-4.47-5.856-5.945-3.347-3.211-4.482-8.04-5.305-11.566-.24-1.034-.533-2.284-.777-2.842-.093-.014-.193-.029-.293-.047-1.063-.19-2.406-.408-3.369.319-.991.759-1.643 2.52-1.893 5.1-.412 4.357 2.155 8.638 4.217 12.075 1.997 3.329 3.722 6.203 1.993 8.405-1.671 2.126-6.196 2.63-12.911 3.168-1.84.146-4.267.34-5.323.583-.326 4.71 2.502 29.8 3.855 34.218zm13.785 4.467c-4.353 0-9.181-.437-14.45-1.314l-.595-.093c-.684-.097-1.199-.551-1.54-1.342-1.227-2.878-5.508-35.56-3.793-37.958.834-1.167 2.656-1.368 7.807-1.78 3.15-.25 9.708-.769 10.628-1.943.279-.59-1.29-3.193-2.22-4.754-2.187-3.637-5.176-8.623-4.66-14.039.343-3.594 1.37-6.003 3.142-7.356 2.084-1.593 4.547-1.163 5.878-.934l.576.09c1.905.21 2.399 2.322 3.082 5.24.734 3.135 1.74 7.427 4.396 9.972 1.564 1.497 3.68 3.691 5.95 6.036 3.976 4.116 10.634 11.01 12.202 11.533 1.812.555 9.4.516 13.757.35l1.671-.056v30.727h-17.17c.092.029-.46.419-.907.73-2.215 1.55-6.339 4.435-13.974 5.996-2.918.594-6.186.895-9.78.895z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M134.333 154.449c2.585-1.597 18.26-1.715 20.48.644 2.215 2.356 1.266 8.036-2.113 9.107-3.379 1.07-15.94.644-18.367 0-2.427-.645-2.427-8.251 0-9.751'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M143.788 154.44c-3.973 0-7.814.347-8.852.988-.849.53-1.547 3.093-1.174 5.613.214 1.428.676 1.994.862 2.047 2.27.595 14.58 1.017 17.727.015 1.06-.337 1.922-1.425 2.305-2.907.43-1.654.147-3.433-.684-4.317-.927-.984-5.641-1.439-10.184-1.439m.677 11.559c-4.4 0-8.974-.298-10.428-.688-1.324-.35-2.23-1.747-2.555-3.937-.43-2.893.214-6.648 2.244-7.904 2.892-1.79 19.176-2.087 21.921.83 1.39 1.479 1.876 4.02 1.239 6.48-.594 2.283-1.994 3.93-3.837 4.517-1.586.505-5.03.702-8.584.702'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M134.448 164.47c2.745-1.596 19.409-1.715 21.764.644 2.355 2.356 1.346 8.037-2.245 9.107-3.59 1.07-16.938.644-19.52 0-2.58-.644-2.58-8.251 0-9.751'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M135.025 165.465c-.988.576-1.597 3.182-1.271 5.466.204 1.46.705 2.105.973 2.173 2.406.601 15.54 1.024 18.912.014 1.18-.354 2.122-1.489 2.512-3.039.412-1.621.111-3.29-.755-4.152-1.865-1.869-17.984-1.847-20.371-.462m10.19 10.556c-4.663 0-9.51-.3-11.046-.68-1.417-.354-2.373-1.804-2.699-4.084-.404-2.864.297-6.561 2.398-7.786h.004c3.053-1.775 20.257-2.072 23.153.827 1.439 1.44 1.972 3.93 1.36 6.347-.594 2.344-2.12 4.091-4.088 4.682-1.682.501-5.323.694-9.081.694'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M136.582 174.864c2.628-1.596 18.578-1.715 20.833.644 2.256 2.356 1.289 8.037-2.147 9.107-3.437 1.07-16.216.644-18.686 0-2.47-.644-2.47-8.251 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M146.186 174.858c-4.045 0-7.947.351-9.006.988v.004c-.856.523-1.568 3.064-1.206 5.563.214 1.457.701 2.033.898 2.087 2.305.6 14.834 1.02 18.052.017 1.092-.343 1.976-1.439 2.366-2.942.43-1.665.147-3.383-.705-4.27-.945-.988-5.767-1.447-10.399-1.447m.705 11.559c-4.47 0-9.124-.3-10.599-.687-1.35-.355-2.27-1.765-2.595-3.98-.422-2.886.236-6.623 2.287-7.865 2.943-1.786 19.48-2.087 22.265.827 1.404 1.464 1.898 3.99 1.271 6.44-.59 2.28-2.055 3.983-3.909 4.563-1.614.505-5.108.702-8.72.702'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M138.821 184.992c2.363-1.397 16.692-1.5 18.718.562 2.026 2.061 1.16 7.03-1.93 7.968-3.085.938-14.568.562-16.788 0-2.219-.562-2.219-7.217 0-8.53'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M147.011 185.104c-3.386 0-6.59.28-7.603.88-.723.427-1.328 2.557-1.024 4.669.18 1.242.591 1.718.72 1.754 2.062.522 13.298.891 16.173.014.952-.29 1.718-1.235 2.047-2.524.354-1.385.111-2.806-.605-3.533-.795-.809-5.401-1.26-9.708-1.26m1.07 10.134c-4.026 0-8.215-.261-9.543-.601-1.278-.322-2.144-1.622-2.434-3.655-.365-2.556.254-5.874 2.13-6.984 2.663-1.56 17.608-1.808 20.128.748 1.278 1.307 1.747 3.551 1.196 5.72-.534 2.077-1.883 3.634-3.612 4.156-1.457.444-4.61.616-7.864.616'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M208.91 156.362v32.824s-8.169.097-8.376 0c-8.552-4.105-1.07-32.842-1.07-32.842l9.446.018z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M199.573 156.485c-.484 1.908-7.074 28.69 1.02 32.578.268.054 4.986.025 8.176-.014v-32.546l-9.196-.018zm3.468 32.89c-1.42 0-2.477-.015-2.566-.061-3.609-1.73-4.894-7.825-3.82-18.103.791-7.62 2.656-14.83 2.674-14.901l.025-.104 9.694.018v33.104h-.14c-1.707.021-4.073.046-5.867.046z'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M200.101 157.096l8.491.322c-2.549 8.27-1.213 25.942-.676 31.812l-8.502-1.088c-3.243-15.324-.336-27.516.687-31.046m-4.342 27.58c-.064-.01-.136-.02-.2-.031.211 1.074.437 2.112.519 3.164.082.014.172.029.24.043.129.662.268 1.328.422 2.005a1.505 1.505 0 001.264 1.16l11.394 1.456a1.48 1.48 0 001.227-.426c.323-.322.484-.78.434-1.242-.802-7.485-2.184-28.196 1.013-34.085.25-.462.247-1.02-.008-1.478a1.488 1.488 0 00-1.238-.77l-11.741-.447a1.488 1.488 0 00-1.454.991c-.05.143-.368 1.053-.77 2.592.004.33-.056.666-.203.988l-.015.054c-.007.229 0 .458.03.687l-.026-.2c-.308.544-.558 1.077-.598 1.542-.014.143.165.183.061.297-1.013 5.259-1.893 13.624-.35 23.7'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M199.527 188.016l8.237 1.052c-.505-5.53-1.836-23.17.64-31.518l-8.2-.311c-1.006 3.529-3.852 15.646-.677 30.777zm8.545 1.374l-.172-.021-8.602-1.103-.021-.096c-3.254-15.392-.294-27.703.687-31.11l.032-.108.111.004 8.673.33-.057.174c-2.56 8.312-1.156 26.407-.666 31.755l.015.175zm-11.863-1.7c.01 0 .021.004.032.004l.193.04.018.089c.154.766.29 1.4.426 2a1.37 1.37 0 001.142 1.053l11.394 1.457a1.39 1.39 0 00.745-.122 1.52 1.52 0 00.365-.258c.29-.293.44-.719.397-1.134-.805-7.496-2.19-28.24 1.027-34.168a1.414 1.414 0 00-.003-1.345 1.36 1.36 0 00-1.128-.695l-11.74-.444h-.05c-.567 0-1.068.355-1.261.899-.014.036-.336.923-.762 2.563 0 .354-.072.701-.215 1.023-.015.244-.004.462.025.666l-.118.015c-.226.418-.47.927-.509 1.36 0 .007.004.018.03.05.031.046.114.157.028.293-1.01 5.241-1.883 13.585-.348 23.622l.03.19-.19-.032.039.211c.182.913.354 1.775.433 2.663zm13.37 4.933a1.88 1.88 0 01-.2-.01l-11.395-1.458a1.638 1.638 0 01-1.378-1.27 62.678 62.678 0 01-.411-1.916h-.008c-.043-.007-.09-.018-.14-.028l-.096-.018-.01-.108c-.076-.905-.251-1.793-.437-2.734l-.122-.62.204.047h.004c-1.504-10.012-.623-18.331.386-23.561l.015-.04c-.05-.067-.094-.139-.083-.243.05-.519.344-1.11.602-1.571a4.594 4.594 0 01-.008-.444.262.262 0 01.03-.104c.132-.297.196-.608.192-.934l.004-.036c.43-1.665.759-2.57.773-2.606a1.614 1.614 0 011.525-1.081h.064l11.742.444a1.622 1.622 0 011.356.838c.28.508.28 1.113.004 1.614-3.182 5.863-1.797 26.535-.992 34.006a1.68 1.68 0 01-.916 1.672 1.614 1.614 0 01-.705.16z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M155.962 147.938c2.595 3.364 2.212 7.732 4.503 11.254 1.98 3.043 4.89 5.223 7.764 7.363 1.522 1.135 2.99-1.421 1.486-2.541-3.258-2.427-6.79-5.08-8.058-9.128-.96-3.068-1.12-5.803-3.15-8.434-1.142-1.482-3.705-.014-2.545 1.486'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgSolfegeFi = React.memo(SvgSolfegeFi)
export default MemoSvgSolfegeFi
