import { onMutationError } from '@thesisedu/feature-apollo-react'
import { isEqual } from 'lodash'
import React from 'react'

import { AssignmentCell, LoadingMode } from './AssignmentCell'
import { AssignmentSubmissionActions } from '../actions/AssignmentSubmissionActions'
import { useGradingModalVisibilityContext } from '../contexts/GradingModalVisibilityContext'
import { getStudentClassTabName } from '../grading/GradingModal'
import { AssignmentWithSubmissions } from '../grading/types'
import { getAssignmentCellMode } from '../helpers'
import { debug } from '../log'
import { useGradeAssignmentSubmissionMutation } from '../queries/useGradeAssignmentSubmissionMutation'
import { BasicAssignmentSubmissionFragment } from '../schema'

export interface AssignmentStudentCellProps {
  assignment: AssignmentWithSubmissions
  submission?: BasicAssignmentSubmissionFragment
  studentId: string
  classId: string
}
function _AssignmentStudentCell({
  assignment,
  submission,
  studentId,
  classId,
}: AssignmentStudentCellProps) {
  const { setGradingAssignment } = useGradingModalVisibilityContext(false) || {}
  const [grade, { loading }] = useGradeAssignmentSubmissionMutation({
    onCompleted: () => {
      setLoadingMode(LoadingMode.Success)
    },
    onError: (...args) => {
      onMutationError('There was an error saving that submission.')(...args)
      setLoadingMode(LoadingMode.Error)
    },
  })
  const [loadingMode, setLoadingMode] = React.useState<LoadingMode | boolean>(false)
  React.useEffect(() => {
    if (loadingMode !== true && loadingMode !== false) {
      const cancel = setTimeout(() => {
        setLoadingMode(false)
      }, 1000)
      return () => clearTimeout(cancel)
    }
  }, [loadingMode])
  const mode = getAssignmentCellMode(assignment, submission)
  const hasGrade = submission?.grade !== null && submission?.grade !== undefined
  return (
    <AssignmentCell
      mode={mode}
      onClick={
        setGradingAssignment
          ? () => {
              setGradingAssignment(assignment, {
                defaultTab: getStudentClassTabName(studentId, classId),
              })
            }
          : undefined
      }
      totalPoints={assignment.totalPoints}
      percentage={submission?.grade}
      points={
        hasGrade ? parseFloat((submission!.grade! * assignment.totalPoints).toFixed(2)) : undefined
      }
      isLate={!!submission?.isLate}
      id={submission?.id}
      classId={classId}
      loading={loading || loadingMode}
      readOnly={!assignment.canGrade}
      actions={
        assignment.canReset ? (
          <AssignmentSubmissionActions
            studentId={studentId}
            submission={submission}
            assignment={assignment}
          />
        ) : undefined
      }
      onSave={(percentage, points) => {
        debug('onSave with points: %d and percentage:', points, percentage)
        if (submission && percentage !== submission.grade) {
          grade({
            refetch: true,
            variables: {
              input: {
                id: submission.id,
                grades: {
                  all: percentage,
                },
                comments: {},
              },
            },
          })
        }
      }}
    />
  )
}

export const AssignmentStudentCell = React.memo(_AssignmentStudentCell, (p, n) => {
  return (
    p.studentId === n.studentId &&
    isEqual(p.submission, n.submission) &&
    isEqual(p.assignment.dueAt, n.assignment.dueAt)
  )
})
