import React from 'react'

import { MediaBookmarkInput } from '../../schema'

export interface BookmarksContextValue {
  draftBookmark?: Partial<MediaBookmarkInput>
  setDraftBookmark: React.Dispatch<React.SetStateAction<Partial<MediaBookmarkInput> | undefined>>
}
export const BookmarksContext = React.createContext<BookmarksContextValue | undefined>(undefined)

export interface BookmarksProviderProps {
  children: React.ReactNode
}
export function BookmarksProvider({ children }: BookmarksProviderProps) {
  const [draftBookmark, setDraftBookmark] = React.useState<Partial<MediaBookmarkInput> | undefined>(
    undefined,
  )
  return (
    <BookmarksContext.Provider value={{ draftBookmark, setDraftBookmark }}>
      {children}
    </BookmarksContext.Provider>
  )
}

export function useBookmarksContext(): BookmarksContextValue | undefined
export function useBookmarksContext(require: false): BookmarksContextValue | undefined
export function useBookmarksContext(require: true): BookmarksContextValue
export function useBookmarksContext(require?: boolean): BookmarksContextValue | undefined {
  const context = React.useContext(BookmarksContext)
  if (!context && require) {
    throw new Error('BookmarksContext is required, yet not provided.')
  }
  return context
}
