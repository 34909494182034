import { styled, s } from '@thesisedu/ui'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { DetailedRecordStateContext } from './DetailedRecordStateContext'
import { StreamsLoading } from './StreamsLoading'
import { StreamsPreview } from './StreamsPreview'
import { RecordFooter } from './footer/RecordFooter'
import { useRecordState } from './useRecordState'
import { useRecordStreams } from './useRecordStreams'
import { getError } from '../error'
import { getSelectDevice } from '../selectDevice'
import { RecordState, RecordStateProps, Streams, getStateFactory } from '../types'

export interface RecordContext extends Streams {}
function Record({ context, onTransition }: RecordStateProps<RecordContext>) {
  const onError = (e: any) => onTransition(getError({ error: e }))
  const { streams, updateStreams, loading } = useRecordStreams({ defaultStreams: context })
  const { state, setState } = useRecordState({ onError, streams, onTransition })
  return (
    <DetailedRecordStateContext.Provider
      value={React.useMemo(
        () => ({
          loading,
          updateStreams,
          streams,
          state,
          setState,
          onError,
        }),
        [loading, updateStreams, streams, state, setState],
      )}
    >
      <>
        <AnimatePresence exitBeforeEnter initial={false}>
          <motion.div
            key={loading ? 'loading' : 'preview'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ flex: 1, display: 'flex' }}
            transition={{ duration: 0.2 }}
          >
            {loading ? <StreamsLoading /> : <StreamsPreview stream={streams.stream} />}
          </motion.div>
        </AnimatePresence>
        <FooterContainer>
          <RecordFooter
            onBack={() => {
              onTransition(getSelectDevice({}))
            }}
          />
        </FooterContainer>
      </>
    </DetailedRecordStateContext.Provider>
  )
}

const FooterContainer = styled.div`
  position: absolute;
  bottom: ${s.size('s')};
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export default {
  Component: Record,
} satisfies RecordState<RecordContext>
export const getRecord = getStateFactory<RecordContext>('record')
