import { ExploreRoutesHook } from '@thesisedu/feature-courses-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { StandardSet } from './StandardSet'
import { StandardSetsReactFeature } from '../../StandardSetsReactFeature'

export default function (feature: StandardSetsReactFeature) {
  feature.hookManager.registerMutateHook<ExploreRoutesHook>(
    'courses-react:explore-routes',
    routes => {
      return [
        ...routes,
        <Route
          key={'standard-sets'}
          path={'/standards/:standardSetId'}
          element={<StandardSet />}
        />,
      ]
    },
  )
}
