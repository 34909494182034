import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dropdown, Form, Modal, VSpaced } from '@thesisedu/ui'
import { Check, Settings, Upload } from '@thesisedu/ui/icons'
import React from 'react'

import { useClearFileSetting } from './clear'
import { useDropFile } from './useDropFile'
import { usePasteFile } from './usePasteFile'
import { $createFileNode, $isFileNode, FileNode } from '../../nodes/File/FileNode'
import { ElementsGroup, useElementsMenuItem } from '../../ui/ElementsMenu/ElementsMenuContext'
import { useSettingsDropdownItem } from '../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../ui/SettingsDropdown/useNodeState'

export interface FileNodeSettingsProps {
  node: FileNode
  onClose: () => void
}
export function FileNodeSettings({ node, onClose }: FileNodeSettingsProps) {
  const [editor] = useLexicalComposerContext()
  const [label, setLabel] = useNodeState(
    editor,
    node,
    node => node.getLabel(),
    (node, value) => node.setLabel(value),
  )
  const form = Form.useForm<{ label?: string }>()
  return (
    <Form.Modal
      title={'File Settings'}
      form={form}
      visible
      onVisibleChange={visible => {
        if (!visible) {
          onClose()
        }
      }}
      defaultValue={{ label: label || undefined }}
      onFinish={values => {
        setLabel(values.label)
      }}
    >
      <VSpaced space={'m'}>
        <Form.TextField
          name={'label'}
          label={'Label'}
          description={'Displayed on the download link instead of the original filename.'}
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Check />} children={'Save Settings'} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export function FilePlugin() {
  const [visibleNode, setVisibleNode] = React.useState<FileNode | undefined>(undefined)
  usePasteFile()
  useDropFile()
  useElementsMenuItem({
    identifier: 'file',
    icon: <Upload />,
    weight: 12,
    group: ElementsGroup.Media,
    title: 'File',
    isSelected(element) {
      return $isFileNode(element)
    },
    onCommit(element) {
      return element.replace($createFileNode())
    },
  })
  useSettingsDropdownItem({
    identifier: 'file-settings',
    group: 'file',
    weight: -7,
    filter(editor, node) {
      return $isFileNode(node)
    },
    element: ({ node }) => (
      <Dropdown.Item onClick={() => setVisibleNode(node)} icon={<Settings />}>
        File Settings...
      </Dropdown.Item>
    ),
  })
  useClearFileSetting()

  return visibleNode ? (
    <FileNodeSettings node={visibleNode} onClose={() => setVisibleNode(undefined)} />
  ) : null
}
