import { useMutateHook } from '@thesisedu/feature-react'
import { useFeature } from '@thesisedu/feature-web'
import { Row, BlockSpin, NotFound, Block, PageHeader, HSpaced } from '@thesisedu/web'
import { Col, Button, Form, Input, message } from 'antd'
import React, { useEffect, useState } from 'react'

import { GroupSelect } from './GroupSelect'
import { StatusSelect } from './StatusSelect'
import UsersWebFeature from '../UsersWebFeature'
import { HOOKS } from '../constants'
import { warn } from '../log'
import { useUpdateUserMutation } from '../queries/useUpdateUserMutation'
import { UserFragment, useUserQuery } from '../schema'
import { EditUserFieldsContext, EditUserFieldsPayload, EditUserPageActionsHook } from '../types'

export interface EditUserPageProps {
  userId: string
  otherActions?: (user: UserFragment) => React.ReactNode
}
export const EditUserPage: React.FC<React.PropsWithChildren<EditUserPageProps>> = ({
  userId,
  otherActions,
}) => {
  const { data, loading } = useUserQuery({ variables: { id: userId } })
  const userFeature = useFeature<UsersWebFeature>(UsersWebFeature.package)
  const [editUser, { loading: editLoading }] = useUpdateUserMutation({
    onCompleted: () => {
      message.success('User updated!')
      window.history.back()
    },
  })
  const [form] = Form.useForm()
  const [additionalContent, setAdditionalContent] = useState<React.ReactElement>()
  useEffect(() => {
    if (data?.node?.__typename === 'User') {
      form.setFieldsValue(data.node)
      userFeature.hookManager
        .mutateHook<EditUserFieldsPayload, EditUserFieldsContext>(HOOKS.EditUserFields, undefined, {
          user: data.node,
        })
        .then(result => {
          setAdditionalContent(result)
        })
        .catch(err => {
          warn('error getting additional context')
          warn(err)
          userFeature.services.error.reportError(err)
          setAdditionalContent(undefined)
        })
    }
  }, [data])
  const hookOtherActions = useMutateHook<EditUserPageActionsHook>(
    'users-web:edit-user-page-actions',
    [],
    { user: data?.node?.__typename === 'User' ? data.node : undefined },
  )
  if (loading) {
    return <BlockSpin />
  } else if (data?.node?.__typename === 'User') {
    return (
      <>
        <Block marginBottom={'@size-l'}>
          <PageHeader
            onBack={() => window.history.back()}
            title={`Editing ${data!.node.name || data!.node.username}`}
          >
            <HSpaced space={'@size-xs'}>
              {hookOtherActions}
              {otherActions ? otherActions(data!.node) : null}
              <Button type={'primary'} onClick={() => form.submit()} loading={editLoading}>
                Save Changes
              </Button>
            </HSpaced>
          </PageHeader>
        </Block>
        <Form
          form={form}
          onFinish={values => {
            editUser({
              variables: {
                input: {
                  id: userId,
                  patch: values,
                },
              },
            })
          }}
          layout={'vertical'}
        >
          <Row>
            <Col xs={24} md={18} lg={12} xl={8}>
              <Form.Item
                name={'username'}
                label={'Username'}
                rules={[{ required: true, message: 'A username is required.' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name={'firstName'} label={'First Name'}>
                <Input />
              </Form.Item>
              <Form.Item name={'lastName'} label={'Last Name'}>
                <Input />
              </Form.Item>
              <Form.Item
                name={'email'}
                label={'Email'}
                rules={[{ required: true, type: 'email', message: 'A valid email is required.' }]}
              >
                <Input type={'email'} />
              </Form.Item>
              <Form.Item name={'phone'} label={'Phone'}>
                <Input type={'tel'} />
              </Form.Item>
            </Col>
            <Col xs={24} md={18} lg={12} xl={8}>
              <Form.Item
                name={'status'}
                label={'Status'}
                rules={[{ required: true, message: 'A status is required.' }]}
              >
                <StatusSelect />
              </Form.Item>
              <Form.Item
                name={'group'}
                label={'Group'}
                rules={[{ required: true, message: 'A group is required.' }]}
              >
                <GroupSelect />
              </Form.Item>
              {additionalContent}
            </Col>
          </Row>
        </Form>
      </>
    )
  } else {
    return <NotFound description={'That user could not be found.'} />
  }
}
