import { useFeatureRoot } from '@thesisedu/feature-react'
import { Format, diffDate } from '@thesisedu/feature-utils'
import { Alert } from 'antd'
import React from 'react'

import { useViewerLicenseQuery } from './schema'

export function LicenseExpiringBanner() {
  const root = useFeatureRoot()
  const { data } = useViewerLicenseQuery()
  const license = data?.viewer?.teacher?.site?.license

  if (!license) return null

  const licenseExpirationDays = diffDate(new Date(license?.expiresAt), new Date(), 'days')
  const willExpireSoon = licenseExpirationDays <= 30
  if (license?.type !== 'TRIAL' && willExpireSoon) {
    return (
      <Alert
        banner
        type={'info'}
        message={
          <span>
            Your {root.appOptions.name} license will expire in{' '}
            <strong>{Format.plural('day', licenseExpirationDays)}</strong>. Please{' '}
            <a
              href={`mailto:sales@${root.appOptions.domain}`}
              target={'_blank'}
              rel={'nofollow noreferrer'}
            >
              contact sales
            </a>{' '}
            with any questions.
          </span>
        }
      />
    )
  }
  return null
}
