export const SPACE = 0
export const VERTICAL_SPACE = 4
export const TARGET_LINE_HALF_HEIGHT = 2
export const DRAGGABLE_BLOCK_MENU_CLASSNAME = 'draggable-block-menu'
export const DRAG_DATA_FORMAT = 'application/x-lexical-drag-block'
export const DRAG_DATA_EDITOR = 'application/x-lexical-drag-editor'

export const Downward = 1
export const Upward = -1
export const Indeterminate = 0
