import { Modal } from '@thesisedu/react'
import React from 'react'

import { UploadsManager } from './UploadsManager'

export interface UploadModalProps {
  visible?: boolean
  onClose: () => void
}
export const UploadModal: React.FC<React.PropsWithChildren<UploadModalProps>> = ({
  visible,
  onClose,
}) => {
  return (
    <Modal
      title={'Manage Uploads'}
      visible={visible}
      onCancel={onClose}
      footer={null}
      alternateBackground
    >
      <UploadsManager />
    </Modal>
  )
}
