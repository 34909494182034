import { MutationTuple } from '@apollo/client'
import { PureQueryOptions } from '@apollo/client/core'
import { FeatureResource, MutateHook } from '@thesisedu/feature'
import { ClassLeftNavHook, ClassRoutesHook } from '@thesisedu/feature-classes-react'
import {
  Segment as CTSegment,
  SegmentConfig as CTSegmentConfig,
  SegmentMetadataSegment,
} from '@thesisedu/feature-course-types'
import { LicenseFragment } from '@thesisedu/feature-licenses-react/dist/schema'
import { TagType } from '@thesisedu/feature-tags-react'
import { Viewer } from '@thesisedu/feature-users-web/dist/types'
import { Legacy } from '@thesisedu/feature-widgets-react'
import { FormProps } from 'antd'
import React from 'react'

import { SegmentStatusContextValue } from './contexts/SegmentStatusContext'
import { SegmentGridItemProps } from './explore/grid/SegmentGridItem'
import {
  ClassCourseFragment,
  ClassFragment,
  CreateOrUpdateSegmentMutation,
  CreateOrUpdateSegmentMutationVariables,
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables,
  SegmentType as OSegmentType,
  SegmentDecorationFragment,
} from './schema'

export interface SegmentCompletionHostProps {
  segment: Segment
  /** This function should be called whenever we are ready to mark the segment as complete. */
  onMarkComplete: () => Promise<void>
}
export type SegmentCompletionHost = (props: SegmentCompletionHostProps) => React.ReactElement
export interface TagNameOverrides {
  [tagType: string]: string
}

export interface CoursesReactOptions {
  getSegmentCompletionHost?: () => SegmentCompletionHost
  /** Override tag names by specifying the type, along with the new name. */
  tagNameOverrides?: TagNameOverrides
  /** Add more tag types. */
  additionalTagTypes?: TagType[]
  /** Override the entire list of available tag types. */
  tagTypes?: TagType[]
  /** Inside the tag editor modal, always show the specified types. */
  alwaysShowTagTypes?: string[]
  sectionLabels?: string[]
  labelPlaceholder?: string
}

enum AdditionalSegmentType {
  Assignment = 'Assignment',
}
export type SegmentType = AdditionalSegmentType | OSegmentType | string

export type LicenseFragmentWithCourses = LicenseFragment & { courses?: LicenseCourses | null }
export interface LicenseCourses {
  [courseId: string]: {
    enabled?: boolean
  }
}

export enum CoursesHooks {
  OutlineContexts = 'courses-react:outline-contexts',
  SegmentInfoPopoverItems = 'courses-react:segment-info-popover-items',
  SegmentProgress = 'courses-react:segment-progress',
  SegmentDescriptions = 'courses-react:segment-descriptions',
  SegmentRightContent = 'courses-react:segment-right-content',
  SegmentEnableRefetchQueries = 'courses-react:segment-enable-refetch-queries',
  VideoWidgetContent = 'courses-react:video-widget-content',
  SegmentSelectorSegmentProps = 'courses-react:segment-selector-segment-props',
}
export type VideoWidgetContentPayload = React.ReactElement | null
export interface VideoWidgetContentContext {}

export type SegmentEnableRefetchQueriesPayload = (string | PureQueryOptions)[]
export interface SegmentEnableRefetchQueriesContext {
  classId: string
  segmentId: string | string[]
  enabled?: boolean
}

export interface AfterSegmentEnableChangedContext {
  classId: string
  segmentId: string | string[]
  enabled: boolean
}
export type AfterSegmentEnableChangedHook = MutateHook<
  'feature-courses-react:after-segment-enable-changed',
  undefined,
  AfterSegmentEnableChangedContext
>

export type OutlineContextsPayload = React.ReactElement
export type SegmentInfoPopoverItemsPayload = React.ReactElement[]
export interface SegmentInfoPopvoerItemsContext {
  segment: Segment
}
export type SegmentRightContentPayload = React.ReactElement[]
export interface SegmentRightContentContext {
  segment: Segment
  readOnly?: boolean
}
export enum SegmentProgress {
  NONE,
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETE,
  OVERDUE,
}
export type SegmentProgressPayload = SegmentProgress
export interface SegmentProgressContext {
  segmentId: string
}
export type SegmentDescriptionsPayload = React.ReactElement[]
export interface SegmentDescriptionsContext {
  segment: Segment
}

export interface SegmentActionComponentProps {
  segment: Segment
}
export interface SegmentActionContext {
  status?: SegmentStatusContextValue
  viewer?: Viewer
}
export interface SegmentActionResource extends FeatureResource {
  type: 'SegmentAction'
  identifier: string
  component: (props: SegmentActionComponentProps) => React.ReactElement | null
  group: string
  weight: number
  filter?: (segment: Segment, context: SegmentActionContext) => boolean
  handler: (segment: Segment, subMenuKey?: string) => any
}

export type ReferenceSegment = Segment & {
  type: OSegmentType.Reference
}

export enum CourseFlags {
  ContentManagerIntroductionShown = 'content-manager-introduction-shown',
}

export interface TagSummary {
  [group: string]: string[]
}

export interface SegmentConfig extends CTSegmentConfig {
  days?: number
  isHidden?: boolean
  excludeInTrial?: boolean
  studentContent?: Legacy.AnyEditorValue
  teacherContent?: Legacy.AnyEditorValue
  templateId?: string | null
  [key: string]: any
  isCopyable?: boolean
}
export interface Segment<Config extends SegmentConfig = SegmentConfig> extends CTSegment<Config> {
  id: string
  config: Config
  name: string
  type: SegmentType
  referenceSegment?: ReferenceSegment
  childSegments?: Segment[]
  weight?: number
  visibleOverride?: boolean
  enabledStudentIds?: string[]
  /**
   * This is true if the segment was already inside the course, but just relocated
   * to a different parent.
   */
  isMoved?: boolean
  isLocked?: boolean
  tags?: TagSummary
  isHiddenInSearch?: boolean
  userId?: string | number
  [key: string]: any
}
export interface GroupSegmentConfig {
  label: string
  [key: string]: any
}
export type GroupSegment = Segment<GroupSegmentConfig>

export interface SegmentReference {
  id: string
  weight: number
}
export interface DatabaseSegment<Config extends object = object> extends Segment<Config> {
  config: Config & {
    childSegments?: SegmentReference[]
  }
  rawSegmentId: string
}

export enum SegmentLabel {
  ImplementationGuide = 'Lesson',
}

export interface SegmentOverrideWithChanges extends SegmentMetadataSegment {
  changes: Partial<Segment>
}

export interface CourseConfiguration {
  segments: Segment[]
}

export interface SegmentProps<Config extends SegmentConfig = SegmentConfig> {
  segment: Segment<Config>
  /**
   * This is the currently-selected navigation item, when viewing segments.
   * @see SegmentTypeResource.getNavigation
   */
  selectedNavigationId?: string
  useSimpleEditing?: boolean
  /** If you are rendering the segment editor on its own rather than a link + drawer */
  useStandaloneEditing?: boolean
  /**
   * If you would like to use your own form, specify it here.
   */
  form?: FormProps['form']
  /**
   * Fires onChange every time form values change, checking first for actual differences.
   * Also hides the save and cancel buttons.
   */
  autoSave?: boolean
  loading?: boolean
  expanded?: boolean
  disabled?: boolean
  readOnly?: boolean
  expandLevels?: number
  onChange?: (originalSegment: Segment, updates: Partial<Segment>) => void
  onDirty?: () => void
  onDelete?: (segmentId: string) => void
  skipHeader?: boolean
}
export interface GetNavigationOpts {
  segment: Segment
  isPreview: boolean
  viewer?: Viewer
  cls?: ClassFragmentWithPermissions | null
  decoration?: SegmentDecorationFragment & Record<string, any>
}
export interface SegmentTypeResource extends FeatureResource {
  type: 'SegmentType'
  renderContent?: (props: SegmentProps) => React.ReactElement | null
  renderEdit: (props: SegmentProps) => React.ReactElement | null
  renderSimpleEdit?: (props: SegmentProps) => React.ReactElement | null
  /**
   * Gets the child navigation to render inside the course navigation views. When one
   * of these items is selected, it will be passed as selectedNavigationId.
   * @see SegmentProps.selectedNavigationId
   */
  getNavigation?: (opts: GetNavigationOpts) => SegmentNavigation
  /**
   * Gets the footer navigation to render inside the course navigation views. For example,
   * this is used inside the course assignments feature to render the attempts and submissions
   * for the associated assignment.
   */
  getNavigationFooter?: (opts: GetNavigationOpts) => React.ReactNode | null
  /** Specify an override for the default navigation key. */
  defaultNavigationKey?: (
    viewer: Viewer | undefined,
    segment: Segment,
    decoration: SegmentDecorationFragment | undefined,
  ) => string
  hasChildren?: boolean
  icon?: React.FC<React.PropsWithChildren<object>>
  label?: string
  defaults?: Partial<Segment>
  allowCustom?: boolean
  additionalTagTypes?: TagType[]
}

export type SegmentNavigation = SegmentNavigationItem[]
export interface SegmentNavigationChildChildProps {
  onClick: (e?: MouseEvent) => void
  disabled?: boolean
  [key: string]: any
}
export interface SegmentNavigationChildProps {
  children: React.ReactElement<SegmentNavigationChildChildProps>
}
export interface SegmentNavigationItem {
  id: string
  name: string | React.ReactElement
  /**
   * If this is true, the id should match the ID of an HTML element on the page, and
   * this menu item will automatically have its selection changed based on how far
   * down the page the user has scrolled.
   */
  isElement?: boolean
  icon?: React.ReactElement
  children?: SegmentNavigationItem[]
  render?: (props: SegmentNavigationChildProps) => React.ReactElement
  /**
   * Use `renderContent` if you would like to override how content is rendered
   * for this segment, when this navigation item is selected.
   */
  renderContent?: (props: SegmentProps) => React.ReactElement | null
}

export interface SimpleSegmentPresetResource extends FeatureResource {
  type: 'SimpleSegmentPreset'
  segmentType: SegmentType
  label: string
  icon: React.ReactElement
  formValues: object
}

export type CreateOrUpdateSegment = MutationTuple<
  CreateOrUpdateSegmentMutation,
  CreateOrUpdateSegmentMutationVariables
>[0]
export type DeleteSegment = MutationTuple<DeleteSegmentMutation, DeleteSegmentMutationVariables>[0]

export type MutateSegmentStandardsHeader = MutateHook<
  'feature-courses-react:segment-standards-header',
  React.ReactElement[],
  { segment: Segment }
>

export type MutateDefaultSegmentThumbnail = MutateHook<
  'feature-courses-react:default-segment-thumbnail',
  React.ReactElement,
  { segment: SegmentGridItemProps['segment'] }
>

export interface CourseConfigurationFieldProps {}
export interface CourseConfigurationFieldResource extends FeatureResource {
  type: 'CourseConfigurationField'
  identifier: string
  render: (props: CourseConfigurationFieldProps) => React.ReactElement | null
}

export type MutateGroupSegmentFields = MutateHook<
  'courses-react:mutate-group-fields',
  React.ReactElement[],
  SegmentProps
>

export interface ClassFragmentWithPermissions extends ClassFragment {
  canChangeCourseStructure?: boolean
  canEnableSegments?: boolean
  canCopySegmentsFrom?: boolean
  canCopySegmentsTo?: boolean
  canLockSegments?: boolean
  canSyncSegments?: boolean
}

export type CourseClassContextHook = MutateHook<
  'courses-react:course-class-context',
  React.ReactElement,
  { cls: ClassCourseFragment; group: string }
>
export type CourseClassRoutesHook = MutateHook<
  ClassRoutesHook['identifier'],
  ClassRoutesHook['payload'],
  { cls: ClassCourseFragment; group: string }
>
export type CourseClassLeftNavHook = MutateHook<
  ClassLeftNavHook['identifier'],
  ClassLeftNavHook['payload'],
  { cls: ClassCourseFragment; group: string }
>
export type CourseTeacherOutlineHeaderActionsHook = MutateHook<
  'courses-react:teacher-outline-header-actions',
  React.ReactElement[],
  { cls: ClassFragmentWithPermissions }
>
export type ExploreBannerHook = MutateHook<
  'courses-react:explore-banner',
  React.ReactElement | null,
  undefined
>
export type ExploreHomeContentHook = MutateHook<
  'courses-react:explore-home-content',
  React.ReactElement[],
  undefined
>
export type ExploreRoutesHook = MutateHook<
  'courses-react:explore-routes',
  React.ReactElement[],
  undefined
>
export type ExploreGridFiltersHook = MutateHook<
  'courses-react:explore-grid-filters',
  React.ReactElement[],
  undefined
>

export interface ExploreLookingForResource extends FeatureResource {
  type: 'ExploreLookingFor'
  identifier: string
  weight: number
  icon: React.ReactElement
  title: string
  page: React.ReactElement
}
