import { styled, s } from '@thesisedu/ui'
import { Mic } from '@thesisedu/ui/icons'
import React from 'react'

import { Container, IconContainer } from './styled'
import { useAudioStreamLevels } from './useAudioStreamLevels'

export interface AudioStreamPreviewProps {
  stream: MediaStream
}
export function AudioStreamPreview({ stream }: AudioStreamPreviewProps) {
  const indicatorRef = React.useRef<HTMLDivElement>(null)
  useAudioStreamLevels({
    stream,
    onLevel(level) {
      if (indicatorRef.current) {
        // 1 - 1.5
        indicatorRef.current.style.transform = `scale(${1 + level})`
      }
    },
  })
  return (
    <Container>
      <IconContainer>
        <Indicator ref={indicatorRef} />
        <Mic />
      </IconContainer>
    </Container>
  )
}

const Indicator = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${s.color('element')};
  transform: scale(1);
`
