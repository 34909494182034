import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { SheetMusicCompositionsList } from './SheetMusicCompositionsList'
import { useNameFilter } from './useNameFilter'
import {
  SheetMusicCompositionFragment,
  ViewerSheetMusicCompositionsQuery,
  ViewerSheetMusicCompositionsQueryVariables,
  ViewerSheetMusicCompositionsDocument,
} from '../schema'

export interface ViewerSheetMusicCompositionsListProps {
  classIds?: string[]
  onClick?: (id: string) => void
}
export function ViewerSheetMusicCompositionsList({
  classIds,
  onClick,
}: ViewerSheetMusicCompositionsListProps) {
  const [name, filter] = useNameFilter()
  return (
    <InfiniteQuery<
      SheetMusicCompositionFragment,
      ViewerSheetMusicCompositionsQuery,
      ViewerSheetMusicCompositionsQueryVariables
    >
      document={ViewerSheetMusicCompositionsDocument}
      resultPath={'viewer.sheetMusicCompositions'}
      variables={{ classIds, name }}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      }}
      children={({ data, loading, refetch }) => {
        const items = data?.viewer?.sheetMusicCompositions.edges.map(s => s.node) || []
        return (
          <SheetMusicCompositionsList
            refetch={after => refetch({ classIds, name, after })}
            onClick={onClick}
            comps={items}
            loading={loading}
            filters={filter}
          />
        )
      }}
    />
  )
}
