import { useResource } from '@thesisedu/feature-react'

import { FullAssignment } from './types'
import { QuestionTypeResource } from '../node'

export function useSupportsRealTimeGrading(assignment: FullAssignment | undefined): boolean | null {
  const resources = useResource<QuestionTypeResource>('QuestionType')
  const questions = assignment?.configuration?.questions
  if (!questions) return null
  return !questions.every(question => {
    const resource = resources.find(r => r.identifier === question.type)
    return resource?.disableRealTimeGrading
  })
}
