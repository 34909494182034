import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface WidgetsReactFeatureOptions {}

export class WidgetsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-widgets-react'
  static path = ''
  static requires: string[] = []
  public options!: WidgetsReactFeatureOptions

  public reactResources() {
    require('./resources/Widgets/LinkEmbed').default(this)
    require('./resources/Widgets/VideoEmbed').default(this)
    require('./resources/Widgets/Definition').default(this)
    require('./resources/Widgets/DiscussionGuideItem').default(this)
  }
}

export const widgetsReactFeature: ReactUse = (opts: WidgetsReactFeatureOptions) => [
  WidgetsReactFeature,
  opts,
]
