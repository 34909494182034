import { ReactFeature } from '@thesisedu/feature-react'
import { Random } from '@thesisedu/feature-utils'
import { BaseWidgetConfig, SerializedWidget } from '@thesisedu/feature-widgets-core'
import { DecoratorNode, NodeKey } from 'lexical'

import { WidgetResource } from '../../../types'

export abstract class WidgetNode<
  Type extends string,
  Config extends BaseWidgetConfig = BaseWidgetConfig,
> extends DecoratorNode<JSX.Element> {
  __config: Config
  __id: string

  constructor(config: Config, id: string = Random.id(), key?: NodeKey) {
    super(key)
    this.__config = config
    this.__id = id
  }

  getId(): string {
    const self = this.getLatest()
    return self.__id
  }

  getConfig(): Config {
    const self = this.getLatest()
    return self.__config
  }

  patchConfig(partialConfig: Partial<Config>) {
    const self = this.getWritable()
    self.__config = {
      ...self.__config,
      ...partialConfig,
    }
  }

  setConfig(config: Config) {
    const self = this.getWritable()
    self.__config = config
  }

  _updateClassName(element: HTMLElement) {
    const className = this.getClassName()
    element.className = ''
    if (className) {
      element.classList.add(className)
    }
  }

  createDOM(): HTMLElement {
    const div = document.createElement('div')

    // Prevent all key events from propagating to parents.
    div.addEventListener('keydown', e => e.stopPropagation())
    div.addEventListener('keyup', e => e.stopPropagation())
    div.addEventListener('keypress', e => e.stopPropagation())
    div.addEventListener('paste', e => e.stopPropagation())
    div.addEventListener('copy', e => e.stopPropagation())

    this._updateClassName(div)
    return div
  }

  updateDOM(prevNode: WidgetNode<Type, Config>, domNode: HTMLElement) {
    this._updateClassName(domNode)
    return false
  }

  abstract getClassName(): string | null
  abstract exportJSON(): SerializedWidget<Type, Config>
  abstract decorate(): JSX.Element
  abstract clone(): WidgetNode<Type, Config>
}

export function $isWidgetNode<Config extends BaseWidgetConfig = BaseWidgetConfig>(
  node: any,
  identifier: string,
): node is WidgetNode<any, Config> {
  if (node instanceof WidgetNode) {
    return node.getType() === identifier
  } else {
    return false
  }
}

export function $createWidgetNode<Config extends BaseWidgetConfig = BaseWidgetConfig>(
  feature: ReactFeature,
  identifier: string,
  config: Config,
) {
  const resource = feature.resources.getResource<WidgetResource>('Widget', identifier)
  if (!resource) throw new Error(`Cannot find widget resource '${resource}'`)
  const WidgetClass = resource.nodes?.[0]
  if (WidgetClass) {
    return new WidgetClass(config)
  } else return null
}
