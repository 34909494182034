import { createGlobalStyle } from 'styled-components'

export const Transitions = createGlobalStyle`
  .fade-scale {
    &-enter-active, &-exit-active {
      transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
      transform-origin: 50% 300px;
    }
    &-enter {
      opacity: 0;
      transform: scale(0.98);
      &-active {
        opacity: 1;
        transform: none;
      }
    }
    &-exit {
      opacity: 1;
      transform: none;
      &-active {
        opacity: 0;
        transform: scale(0.98);
      }
    }
  }
`
