// @ts-ignore no types
import jsonUrl from 'json-url'
import React from 'react'

import { error } from '../../log'
import { RunReportOpts } from '../types'

const codec = jsonUrl('lzma') as any

export async function packOpts(opts: RunReportOpts): Promise<string> {
  return codec.compress(opts)
}

export async function unpackOpts(packed: string): Promise<RunReportOpts | null> {
  const result: Partial<RunReportOpts> = await codec.decompress(packed)
  if (
    typeof result === 'object' &&
    Array.isArray(result.metrics) &&
    Array.isArray(result.dimensions)
  ) {
    return result as RunReportOpts
  } else {
    return null
  }
}

export function useUnpackedReport(packed: string) {
  const [loading, setLoading] = React.useState(true)
  const [opts, setOpts] = React.useState<RunReportOpts | null>(null)
  React.useEffect(() => {
    setLoading(true)
    unpackOpts(packed)
      .then(unpacked => {
        setOpts(unpacked)
      })
      .catch(err => {
        error('error unpacking opts')
        error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [packed])

  return { loading, opts }
}
