import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  styled,
  s,
  Text,
  VSpaced,
  TextField,
  TextAreaField,
  NumberField,
  HSpaced,
  Button,
  Tooltip,
} from '@thesisedu/ui'
import { Copy, Menu, Plus, Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { PRESET_MIN_WIDTH_PX } from './CriterionPresetEditor'
import { CriterionPresetsEditor } from './CriterionPresetsEditor'
import { AssignmentRubricCategoryInput } from '../schema'

export const HEADER_WIDTH_PX = 250
export const FOOTER_WIDTH_PX = 50

export interface RubricEditorCriterionProps {
  value: AssignmentRubricCategoryInput
  onChange: (input: AssignmentRubricCategoryInput) => void
  onDelete: () => void
  onCopyScores: () => void
  onAdd: (delta: number, defaults: Partial<AssignmentRubricCategoryInput>) => void
  /**
   * This is a pretty terrible hack around the CSS which was really not cooperating with us
   * at this point. If we don't do this, if you add a lot of items, the horizontal scrolling
   * will cause the delete icon to be stuck at the right side of the scroll container, not
   * the right side of the content. The borders will stop too.
   */
  maxPresets: number
}
export function RubricEditorCriterion({
  onDelete,
  onAdd,
  value,
  onChange,
  onCopyScores,
  maxPresets,
}: RubricEditorCriterionProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isOver, isDragging } =
    useSortable({
      id: value.id,
    })

  return (
    <Container
      data-over={isOver}
      data-dragging={isDragging}
      data-testid={'RubricEditorCriterion'}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        minWidth:
          (PRESET_MIN_WIDTH_PX + 1) * maxPresets +
          HEADER_WIDTH_PX +
          FOOTER_WIDTH_PX +
          2 /* Borders */,
      }}
      {...attributes}
    >
      <Header space={'xs'}>
        <Text color={'secondary'} as={'div'} {...listeners}>
          <Menu />
        </Text>
        <VSpaced space={false} style={{ flex: 1, alignSelf: 'stretch' }}>
          <TextField
            aria-label={'Criterion Name'}
            data-testid={'CriterionName'}
            placeholder={'Name'}
            variant={['ghost', 'bold']}
            size={'small'}
            value={value.name ?? undefined}
            onChange={name => onChange({ ...value, name })}
            autoComplete={'off'}
          />
          <TextAreaField
            aria-label={'Criterion Description'}
            data-testid={'CriterionDescription'}
            placeholder={'Description'}
            size={'small'}
            variant={'ghost'}
            value={value.description ?? undefined}
            onChange={description => onChange({ ...value, description })}
          />
          <NumberField
            aria-label={'Total Points'}
            data-testid={'CriterionTotalPoints'}
            size={'small'}
            minValue={0}
            variant={'ghost'}
            value={value.totalPoints ?? 0}
            onChange={totalPoints => onChange({ ...value, totalPoints })}
            suffix={<>points</>}
            style={{ width: 125, marginTop: 'auto' }}
          />
        </VSpaced>
        <InsertCriterionContainer data-bottom>
          <Button
            data-testid={'AddRubricCriteria Below'}
            size={'small'}
            icon={<Plus />}
            aria-label={'Add Criterion Below'}
            variant={'ghost'}
            excludeFromTabOrder // FIXME Accessibility
            onPress={() => onAdd(1, { presets: value.presets, totalPoints: value.totalPoints })}
          />
        </InsertCriterionContainer>
        <InsertCriterionContainer data-top>
          <Button
            data-testid={'AddRubricCriteria Above'}
            size={'small'}
            icon={<Plus />}
            aria-label={'Add Criterion Above'}
            variant={'ghost'}
            excludeFromTabOrder // FIXME Accessibility
            onPress={() => onAdd(0, { presets: value.presets, totalPoints: value.totalPoints })}
          />
        </InsertCriterionContainer>
      </Header>
      <CriterionPresetsEditor
        value={value.presets ?? undefined}
        onChange={presets => onChange({ ...value, presets })}
        totalPoints={value.totalPoints}
      />
      <Footer justify={'center'} align={'center'} space={'xs'}>
        <Tooltip title={'Delete Criterion'} contentProps={{ side: 'left', align: 'center' }}>
          <Button
            variant={'ghost'}
            status={'danger'}
            icon={<Trash />}
            aria-label={'Delete Criterion'}
            size={'small'}
            onPress={onDelete}
          />
        </Tooltip>
        <Tooltip
          title={'Copy Scores to Other Criteria'}
          contentProps={{ side: 'left', align: 'center' }}
        >
          <Button
            variant={'ghost'}
            icon={<Copy />}
            aria-label={'Copy Scores to Other Criteria'}
            size={'small'}
            disabled={!value.presets?.length}
            onPress={onCopyScores}
          />
        </Tooltip>
      </Footer>
    </Container>
  )
}

const InsertCriterionContainer = styled.div`
  opacity: 0;
  transition: opacity 0.1s linear;
  background: ${s.color('subtle')};
  border-radius: ${s.var('radii.1')};
  left: ${s.size('xs')};
  position: absolute;
  color: ${s.color('secondary')};
  z-index: 10;
  &[data-top] {
    top: ${s.size('xs')};
  }
  &[data-bottom] {
    bottom: ${s.size('xs')};
  }
`
const Container = styled.div`
  background: ${s.color('background')};
  transition:
    background 0.1s linear,
    border 0.1s linear,
    box-shadow 0.1s linear,
    border-radius 0.1s linear;
  &:not(:last-child) {
    border-bottom: solid 1px ${s.color('border')};
  }
  display: flex;
  align-items: stretch;
  position: relative;
  &[data-dragging='true'] {
    ${InsertCriterionContainer} {
      display: none;
    }
    background: ${s.color('background')};
    border: solid 1px ${s.color('border')};
    border-radius: ${s.var('radii.1')};
    box-shadow: ${s.var('shadow.0')};
    z-index: 2;
  }
`
const Header = styled(HSpaced)`
  background: ${s.color('subtle')};
  padding: ${s.size('xs')};
  padding-left: ${s.size('s')};
  border-right: solid 1px ${s.color('border')};
  position: sticky;
  width: ${HEADER_WIDTH_PX}px;
  left: 0;
  z-index: 2;
  flex-shrink: 0;
  &:hover ${InsertCriterionContainer}, &:focus-within ${InsertCriterionContainer} {
    opacity: 1;
  }
`
const Footer = styled(VSpaced)`
  background: ${s.color('subtle')};
  padding: ${s.size('xs')} 0;
  width: ${FOOTER_WIDTH_PX}px;
  flex-shrink: 0;
`
