import { EllipsisOutlined } from '@ant-design/icons'
import {
  formatTime,
  FragmentListSection,
  formatRelativeWeek,
  FragmentList,
  FragmentListProps,
  DATE_FORMATS,
  TableActions,
  styled,
  StyledThemeContext,
} from '@thesisedu/web'
import { Badge, Tooltip } from 'antd'
import { TableProps } from 'antd/es/table'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useContext } from 'react'

import { MediaPreview } from './preview/MediaPreview'
import { SmallMediaPreview } from './preview/SmallMediaPreview'
import { MediaStatus } from './schema'
import { MediaListItem, MediaPreviewProps, MediaTableListItem } from './types'
import { UploadStatusLabel } from './upload/UploadStatusLabel'
import { convertStatus } from './upload/convertStatus'

const ImagePreview = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
`

const ImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 84px;
  color: ${props => props.theme['@text-color-secondary']};
`

const OuterContainer = styled.div`
  .ant-badge-dot {
    width: ${props => props.theme['@size-s']};
    height: ${props => props.theme['@size-s']};
  }
`

const NameContainer = styled.div`
  text-align: left;
`

const StatusContainer = styled.div`
  text-align: left;
  width: max-content;
  margin: 0;
`

export interface FragmentTableProps<T extends MediaTableListItem> {
  columns: TableProps<T>['columns']
}

export interface MediaListProps<T extends MediaListItem>
  extends Partial<FragmentListProps<MediaListItem>> {
  items: T[]
  noSections?: boolean
  getItemProps: (
    item: T,
  ) => Pick<MediaPreviewProps, 'label'> & Omit<Partial<MediaPreviewProps>, 'label'>
}

export function MediaList<T extends MediaListItem>({
  items,
  getItemProps,
  noSections,
  tableProps: _tableProps,
  ...otherProps
}: MediaListProps<T>) {
  const theme = useContext(StyledThemeContext)
  let sections: FragmentListSection<MediaListItem>[] = []
  if (noSections) {
    sections = [
      {
        key: 'main',
        title: undefined,
        items,
      },
    ]
  } else {
    const groupedByWeek = groupBy(items, item => moment(item.media.createdAt).format('YYYY-ww'))
    sections = Object.keys(groupedByWeek).reduce<FragmentListSection<MediaListItem>[]>(
      (acc, weekKey) => {
        return [
          ...acc,
          {
            key: weekKey,
            title: formatRelativeWeek(weekKey),
            items: groupedByWeek[weekKey],
          },
        ]
      },
      [],
    )
  }

  const parentColumns = _tableProps?.columns ? _tableProps.columns : []
  const columns: TableProps<MediaTableListItem>['columns'] = [
    {
      title: '',
      dataIndex: ['thumbnail'],
      key: 'thumbnail',
      render: (_, record) => {
        return (
          <OuterContainer>
            <Tooltip
              placement={'rightTop'}
              title={record.toolTipTitle}
              trigger={record.toolTipTitle ? 'hover' : ''}
              mouseEnterDelay={1}
            >
              <Badge
                {...record?.badgeProps}
                dot={record?.hasUnreadComments || !!record?.badgeProps?.dot}
              >
                <ImageContainer
                  onClick={e => {
                    e.preventDefault()
                    return record?.onClick ? record.onClick() : null
                  }}
                >
                  <ImagePreview
                    style={{
                      backgroundImage: record.thumbnail ? `url(${record.thumbnail})` : undefined,
                      cursor: 'pointer',
                    }}
                  >
                    {record.thumbnail ? null : (
                      <EllipsisOutlined
                        style={{
                          fontSize: theme['@size-m'],
                        }}
                      />
                    )}
                  </ImagePreview>
                </ImageContainer>
              </Badge>
            </Tooltip>
          </OuterContainer>
        )
      },
    },
    {
      title: 'Name',
      dataIndex: ['label'],
      key: 'label',
      render: (_, record) => (
        <NameContainer>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => (record?.onClick ? record.onClick() : null)}
          >
            {record.label}
          </a>
          <br />
          {record.status !== MediaStatus.Complete ? (
            <StatusContainer>
              <UploadStatusLabel
                upload={{
                  ...record.media,
                  currentStatus: convertStatus(record.status),
                }}
                style={{
                  fontSize: theme['@size-s'],
                  color: theme['@text-color-secondary'],
                  alignSelf: 'center',
                }}
              />
            </StatusContainer>
          ) : null}
          {record.meta ? record.meta : null}
        </NameContainer>
      ),
    },
    {
      title: 'Length',
      dataIndex: ['durationInSeconds'],
      key: 'durationInSeconds',
      render: (_, record) =>
        record?.durationInSeconds ? formatTime(record.durationInSeconds.toString()) : null,
    },
    {
      title: 'Created On',
      dataIndex: ['createdAt'],
      key: 'createdAt',
      render: (_, record) => moment(record.createdAt).format(DATE_FORMATS.FULL),
    },
    ...parentColumns,
    {
      title: '',
      dataIndex: ['actions'],
      key: 'actions',
      align: 'center',
      width: 75,
      render: (_, record) => <TableActions overlay={record.actions} />,
    },
  ]

  const tableProps: FragmentTableProps<MediaListItem> = { ..._tableProps, columns }

  return (
    <FragmentList<MediaListItem>
      sections={sections}
      itemKey={item => item.media.id}
      renderGridItem={item => <MediaPreview mediaNode={item.media} {...getItemProps(item as T)} />}
      renderCalendarDate={date => {
        const itemsForDay = items.filter(item => moment(item.media.createdAt).isSame(date, 'day'))
        return itemsForDay.map(item => (
          <SmallMediaPreview
            key={item.media.id}
            mediaNode={item.media}
            {...getItemProps(item as T)}
          />
        ))
      }}
      getRowData={item => {
        return {
          ...getItemProps(item as T),
          hasUnreadComments: item.media.hasUnreadComments,
          thumbnail: item.media.thumbnail,
          createdAt: item.media.createdAt,
          durationInSeconds: item.media.durationInSeconds,
          status: item.media.status,
        }
      }}
      tableProps={tableProps}
      {...otherProps}
    />
  )
}
