import React from 'react'

import { MediaViewProps } from './MediaView'

export interface MediaViewContextValue extends MediaViewProps {}
export const MediaViewContext = React.createContext<MediaViewContextValue | undefined>(undefined)

export function useMediaViewContext(): MediaViewContextValue | undefined
export function useMediaViewContext(require: false): MediaViewContextValue | undefined
export function useMediaViewContext(require: true): MediaViewContextValue
export function useMediaViewContext(require?: boolean): MediaViewContextValue | undefined {
  const context = React.useContext(MediaViewContext)
  if (!context && require) {
    throw new Error('MediaViewContext is required, yet not provided.')
  }
  return context
}
