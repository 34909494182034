import React from 'react'

import { useOutlineSegmentContext } from './OutlineSegmentContext'
import { SegmentEnable } from './SegmentEnable'
import { SegmentIndicators } from './SegmentIndicators'

export interface SegmentLockEnableProps {
  segmentId: string
  disabled?: boolean
}
export const SegmentLockEnable: React.FC<React.PropsWithChildren<SegmentLockEnableProps>> = ({
  segmentId,
  disabled,
}) => {
  const { setSegmentEnabled, enabledSegmentIds, partiallyEnabledSegmentIds } =
    useOutlineSegmentContext()
  const isEnabled = enabledSegmentIds.includes(segmentId)
  const isPartiallyEnabled = partiallyEnabledSegmentIds.includes(segmentId)

  return (
    <>
      {disabled ? null : <SegmentIndicators segmentId={segmentId} />}
      <SegmentEnable
        setSegmentEnable={setSegmentEnabled}
        segmentId={segmentId}
        isEnabled={isEnabled}
        isPartiallyEnabled={isPartiallyEnabled}
        disabled={disabled}
      />
    </>
  )
}
