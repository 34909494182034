import React from 'react'
import { ColumnProps as RAColumnProps, Column as RAColumn } from 'react-aria-components'

import { CellStyles } from './Cell'
import { styled } from '../..'
import { SortDown, SortUp } from '../../../icons'

export interface ColumnProps extends RAColumnProps {
  icon?: React.ReactElement
  children?: React.ReactNode
  title?: string
}
export function Column({ icon, children, title, ...props }: ColumnProps) {
  return (
    <__Column {...props}>
      {({ allowsSorting, sortDirection }) => {
        return (
          <ColumnInner>
            {icon}
            {children ?? title}
            {allowsSorting ? (
              <SortIndicator aria-hidden={'true'}>
                {sortDirection === 'ascending' ? <SortUp /> : <SortDown />}
              </SortIndicator>
            ) : null}
          </ColumnInner>
        )
      }}
    </__Column>
  )
}

const SortIndicator = styled.span`
  transition: opacity 0.1s linear;
  margin-left: auto;
`
const __Column: typeof RAColumn = styled(RAColumn)`
  ${CellStyles}
  color: var(--table-text-emphasis-color);
  font-weight: normal;
  &[aria-sort='none'] ${SortIndicator} {
    opacity: 0;
  }
`
export const ColumnGroup = __Column
const ColumnInner = styled.div`
  display: flex;
  align-items: center;
  gap: var(--table-size-xs);
`
