import { Block, Form, Select } from '@thesisedu/ui'
import React from 'react'

import { ConfigurePercent } from './ConfigurePercent'
import { ConfigureStandardDeviation } from './ConfigureStandardDeviation'
import { ConfigureStatic } from './ConfigureStatic'
import { CellColorOpts, CellColorMode } from './getCellColors'

export interface ConfigureCellColorsProps {
  value: CellColorOpts
  onChange: (opts: CellColorOpts) => void
}
export function ConfigureCellColors(props: ConfigureCellColorsProps) {
  const form = Form.useControlledForm<CellColorOpts>(props)
  let child: React.ReactElement | null = null
  if (props.value.mode === CellColorMode.StandardDeviation) {
    child = <ConfigureStandardDeviation {...props} />
  } else if (props.value.mode === CellColorMode.Percent) {
    child = <ConfigurePercent {...props} />
  } else if (props.value.mode === CellColorMode.Static) {
    child = <ConfigureStatic {...props} />
  }
  return (
    <Form form={form}>
      <Block bottom={'xs'}>
        <Form.Select name={'mode'} label={'Color Mode'} style={{ width: '100%' }}>
          <Select.Item value={CellColorMode.StandardDeviation}>Standard Deviation</Select.Item>
          <Select.Item value={CellColorMode.Percent}>Percent</Select.Item>
          <Select.Item value={CellColorMode.Static}>Manual</Select.Item>
          <Select.Item value={CellColorMode.Heatmap}>Heatmap</Select.Item>
        </Form.Select>
      </Block>
      {child}
    </Form>
  )
}
