import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  rectSortingStrategy,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { Format, Random } from '@thesisedu/feature-utils'
import { styled, s, Button, VSpaced, Text, HSpaced } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import { orderBy } from 'lodash'
import React from 'react'

import { RubricEditorActions } from './RubricEditorActions'
import { RubricEditorCriterion } from './RubricEditorCriterion'
import { AssignmentRubricCategoryInput, AssignmentRubricInput } from '../schema'

export interface RubricEditorProps {
  value?: AssignmentRubricInput | null
  onChange?: (value?: AssignmentRubricInput | null) => void
}
function _RubricEditor({ value, onChange }: RubricEditorProps, ref: React.ForwardedRef<never>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const categories = value?.categories ? orderBy(value.categories, 'weight', 'asc') : []
  const addCriteria = (index: number, defaults: Partial<AssignmentRubricCategoryInput>) => {
    categories.splice(index, 0, {
      id: Random.id(),
      totalPoints: 1,
      ...defaults,
    })
    onChange?.({ categories: categories.map((cat, weight) => ({ ...cat, weight })) })
  }

  let content
  if (categories.length) {
    const maxPresets = categories.reduce((acc, category) => {
      return category.presets && category.presets.length > acc ? category.presets.length : acc
    }, 0)
    const totalPoints = categories.reduce((acc, category) => acc + category.totalPoints, 0)
    content = (
      <>
        <HSpaced justify={'space-between'} bottom={'xs'}>
          <Text level={'xs'} color={'secondary'}>
            {Format.plural('total point', totalPoints)}
          </Text>
          <RubricEditorActions value={value} onChange={onChange} />
        </HSpaced>
        <Container>
          {categories.map((category, index) => {
            return (
              <RubricEditorCriterion
                key={category.id}
                value={category}
                maxPresets={maxPresets}
                onChange={updatedCategory => {
                  onChange?.({
                    ...value,
                    categories: categories.map(c =>
                      c.id === updatedCategory.id ? updatedCategory : c,
                    ),
                  })
                }}
                onCopyScores={() => {
                  onChange?.({
                    ...value,
                    categories: categories.map(c => {
                      return { ...c, presets: category.presets }
                    }),
                  })
                }}
                onDelete={() => {
                  onChange?.({
                    ...value,
                    categories: categories.filter(c => c.id !== category.id),
                  })
                }}
                onAdd={(delta, defaults) => {
                  addCriteria(index + delta, defaults)
                }}
              />
            )
          })}
        </Container>
      </>
    )
  } else {
    content = (
      <Container>
        <VSpaced space={'xs'} align={'center'} paddingVertical={'l'} paddingHorizontal={'s'}>
          <Button
            icon={<Plus />}
            data-testid={'AddRubricCriteria'}
            children={'Add Rubric Criteria'}
            onPress={() => addCriteria(0, {})}
          />
        </VSpaced>
      </Container>
    )
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over.id) {
          const oldIndex = categories.findIndex(item => item.id === active.id)
          const newIndex = categories.findIndex(item => item.id === over.id)
          if (oldIndex != null && newIndex != null) {
            const newCategories = arrayMove(categories, oldIndex, newIndex)
            onChange?.({
              categories: newCategories.map((cat, weight) => ({ ...cat, weight })),
            })
          }
        }
      }}
    >
      <SortableContext items={categories} strategy={rectSortingStrategy}>
        {content}
      </SortableContext>
    </DndContext>
  )
}
export const RubricEditor = React.forwardRef(_RubricEditor)

export const Container = styled.div`
  border: solid 1px ${s.color('border')};
  border-radius: ${s.var('radii.1')};
  overflow-x: auto;
  overflow-y: hidden;
  textarea {
    resize: none;
  }
`
