import { UserOutlined } from '@ant-design/icons'
import { useAnnouncementsForClassQuery } from '@thesisedu/feature-announcements-react/dist/schema'
import {
  useClassConfiguration,
  useClasses,
  useSelectedClass,
} from '@thesisedu/feature-classes-react'
import { Segment, walkSegments, isVisibleToStudent } from '@thesisedu/feature-course-types'
import {
  SegmentProgress,
  useCourseContext,
  useSegmentProgressCallback,
} from '@thesisedu/feature-courses-react'
import { useUnreadThreads } from '@thesisedu/feature-direct-messages-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Block, HSpaced, PageHead, styled } from '@thesisedu/web'
import { Badge } from 'antd'
import { LogOut } from 'iconoir-react'
import React from 'react'
import { keyframes } from 'styled-components'

import { NavButton } from './components/NavButton'
import { SmallNavButton, TinyNavButton } from './components/SmallNavButton'
// @ts-ignore
import { ReactComponent as HoverLogo } from '../../assets/hover-logo.svg'
// @ts-ignore
import bellB from '../../assets/sketch/branding/bell/b.svg'
// @ts-ignore
import bellF from '../../assets/sketch/branding/bell/f.svg'
// @ts-ignore
import composeKid from '../../assets/sketch/website/compose-kid.svg'
// @ts-ignore
import todaysLessonGirl from '../../assets/sketch/website/todays-lesson-girl.svg'
// @ts-ignore
import treasureChest from '../../assets/sketch/website/treasure-chest-icon.svg'
import { ENABLE_FREE_COMPOSE } from '../../resources/class_configuration/enableFreeCompose'

const FanAnimation = keyframes`
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.2);
  }
  100% {
    transform: scaleX(1);
  }
`
const BANNER_DELTA = 8
const BannerAnimation = keyframes`
  0% {
    transform: translateY(-${BANNER_DELTA}px);
  }
  50% {
    transform: translateY(${BANNER_DELTA}px);
  }
  100% {
    transform: translateY(-${BANNER_DELTA}px);
  }
`
const BannerBannerAnimation = keyframes`
  0% {
    transform: skew(-0.01turn, 0.2deg);
  }
  20% {
    transform: skew(0.00turn, 0.3deg);
  }
  40% {
    transform: skew(-0.005turn, -0.2deg);
  }
  60% {
    transform: skew(0.007turn, 0.2deg);
  }
  80% {
    transform: skew(-0.005turn, 0deg);
  }
  100% {
    transform: skew(-0.01turn, 0.2deg);
  }
`
const Logo = styled(HoverLogo)`
  width: 316px;
  margin-left: -30px;
  height: auto;
  animation: ${BannerAnimation} 5s ease-in-out infinite;
  path#fan {
    animation: ${FanAnimation} 0.75s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }
  #banner-group {
    animation: ${BannerBannerAnimation} 5s ease-in-out infinite;
    transform-origin: top center;
    transform-box: fill-box;
  }
`

export function AnnouncementsMenuItem() {
  const cls = useSelectedClass(true)
  const { data: announcementsData } = useAnnouncementsForClassQuery({
    variables: { classId: cls.id, first: 16 },
    pollInterval: 30000,
    fetchPolicy: 'network-only',
  })
  const unreadAnnouncements =
    announcementsData?.node?.__typename === 'Class'
      ? announcementsData.node.announcements.edges.reduce((total, edge) => {
          return total + (edge.node.isRead ? 0 : 1)
        }, 0)
      : 0
  const unreadThreads = useUnreadThreads({ filters: { classIds: [cls.id] } }) || 0
  return (
    <AnnouncementsContainer>
      <Badge count={unreadAnnouncements + unreadThreads}>
        <SmallNavButton
          subtitle={'Announcements &'}
          title={'Messages'}
          linkTo={'messages'}
          color={'@pink'}
          rightContent={<img src={bellB} alt={'B Bell'} style={{ transform: 'rotate(7deg)' }} />}
        />
      </Badge>
    </AnnouncementsContainer>
  )
}
const AnnouncementsContainer = styled.div`
  .ant-badge {
    display: block;
  }
`

export function StudentHome() {
  const { bakedCourseConfiguration } = useCourseContext(true)
  const getProgress = useSegmentProgressCallback()
  const { classes } = useClasses()
  const enableFreeCompose = useClassConfiguration(ENABLE_FREE_COMPOSE)
  let firstValidSegment: Segment | null = null
  let firstIncompleteSegment: Segment | null = null
  walkSegments(bakedCourseConfiguration.segments, segment => {
    if (isVisibleToStudent(segment)) {
      if (!firstValidSegment) firstValidSegment = segment
      if (!firstIncompleteSegment && getProgress(segment) !== SegmentProgress.COMPLETE) {
        firstIncompleteSegment = segment
      }
    }
  })
  const viewer = useViewerContext(false)
  return (
    <>
      <PageHead title={'Prodigies Academy'} />
      <Block marginBottom={'@size-l'} className={'logo'}>
        <Logo />
      </Block>
      <ContentContainer>
        <LeftContent className={'left-content'}>
          <NavButton
            subtitle={'Browse Course'}
            title={'Get Started'}
            linkText={'Jump back in'}
            linkTo={'course'}
            color={'@red'}
            imageUrl={todaysLessonGirl}
          />
          <NavButton
            subtitle={'Collect them All'}
            title={'Treasure Chest'}
            linkText={"See what you've earned"}
            linkTo={'treasures'}
            color={'@orange'}
            imageUrl={treasureChest}
            centerImage
          />
          {enableFreeCompose ? (
            <NavButton
              subtitle={'Create a Colorful Composition'}
              title={'Compose your own Music'}
              linkText={'Write your own song'}
              linkTo={'compose'}
              color={'@blue'}
              imageUrl={composeKid}
              imageWidth={'60%'}
            />
          ) : null}
        </LeftContent>
        <RightContent className={'right-content'}>
          <SmallNavButton
            title={viewer?.firstName || viewer?.username || 'Student'}
            subtitle={'Welcome back'}
            color={'@gray-7'}
            rightContent={<ProfileIcon />}
          />
          <SmallNavButton
            subtitle={'Review'}
            title={'Grades'}
            linkTo={'grades'}
            color={'@green'}
            rightContent={<img src={bellF} alt={'F Bell'} style={{ transform: 'rotate(-7deg)' }} />}
          />
          <AnnouncementsMenuItem />
          <TinyNavButton linkTo={'settings'} text={'Settings'} />
          {classes?.filter(cls => !cls.archivedAt).length > 1 ? (
            <HSpaced style={{ width: '100%' }}>
              <TinyNavButton linkTo={'/student'} text={'Switch Class'} style={{ flex: 1 }} />
              <TinyNavButton
                linkTo={'/auth/logout'}
                text={<LogOut style={{ display: 'block' }} />}
              />
            </HSpaced>
          ) : (
            <TinyNavButton linkTo={'/auth/logout'} text={'Sign Out'} />
          )}
        </RightContent>
      </ContentContainer>
    </>
  )
}

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
`
const LeftContent = styled.div`
  width: 600px;
  margin-right: ${props => props.theme['@size-l']};
`
const RightContent = styled.div`
  width: 235px;
`

function ProfileIcon() {
  return (
    <ProfileIconContainer>
      <UserOutlined />
    </ProfileIconContainer>
  )
}
const ProfileIconContainer = styled.div`
  border-radius: 50%;
  height: ${props => props.theme['@size-xl']};
  background: ${props => props.theme['@gray-2']};
  font-size: ${props => props.theme['@size-l']};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
