import { VideoCamera } from '@thesisedu/ui/icons'
import React from 'react'

import {
  SelectMediaSourceFooterButton,
  SelectMediaSourceFooterButtonProps,
} from './SelectMediaSourceFooterButton'

export interface SelectVideoSourceFooterButtonProps
  extends Pick<SelectMediaSourceFooterButtonProps, 'value' | 'onChange' | 'disabled'> {}
export function SelectVideoSourceFooterButton(props: SelectVideoSourceFooterButtonProps) {
  return (
    <SelectMediaSourceFooterButton
      {...props}
      icon={<VideoCamera />}
      label={'Camera'}
      constraints={{ video: true }}
      type={'videoinput'}
    />
  )
}
