import { Format } from '@thesisedu/feature-utils'
import { Body, BodySmall } from '@thesisedu/web'
import path from 'path'
import React from 'react'

import { AttachmentDelete } from './AttachmentDelete'
import { Container, Page, Content, ImageContainer } from './styled'
import { AttachmentReadProps } from './types'

const IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg', 'bmp', 'gif', 'svg', 'eps']

export const AttachmentRead: React.FC<React.PropsWithChildren<AttachmentReadProps>> = ({
  fragment,
  allowDelete,
}) => {
  if (!fragment.url || !fragment.field) return null
  const extension = path.extname(fragment.url.path).substr(1)
  return (
    <a href={fragment.url.signedUrl} target={'_blank'} rel={'noopener noreferrer'}>
      {IMAGE_EXTENSIONS.includes(extension) ? (
        <ImageContainer>
          <img
            className={'attachment-image'}
            src={fragment.url.signedUrl}
            alt={fragment.field.name}
            width={'100%'}
          />
        </ImageContainer>
      ) : null}
      <Container>
        <Page>{extension}</Page>
        <Content>
          <Body color={'@text-color'}>{fragment.field.name} &rarr;</Body>
          <BodySmall color={'@text-color-secondary'}>
            Size: {Format.filesize(fragment.field.sizeInBytes)}
          </BodySmall>
        </Content>
        {allowDelete ? (
          <AttachmentDelete
            attachmentId={fragment.id}
            {...(allowDelete === true ? {} : allowDelete)}
          />
        ) : null}
      </Container>
    </a>
  )
}
