import { RequireFields } from '@thesisedu/feature-types'
import { Block, BodySmall, HSpaced, LoadingIndicator, Space } from '@thesisedu/react'
import { MinusCircle } from '@thesisedu/react/icons'
import { Capital } from '@thesisedu/web'
import React from 'react'

import { ForfeitAttemptsWarning } from './ForfeitAttemptsWarning'
import { GradingModalSidebarAttemptsListItem } from './GradingModalSidebarAttemptsListItem'
import { GradingModalSidebarInProgressAttemptListItem } from './GradingModalSidebarInProgressAttemptListItem'
import { FullAssignment } from './types'
import {
  BasicAssignmentSubmissionFragment,
  OrderDirection,
  useAllAssignmentSubmissionsQuery,
} from '../schema'

export interface GradingModalSidebarAttemptsListProps {
  assignment: FullAssignment
  studentId: string
  classId: string
}
export function GradingModalSidebarAttemptsList({
  assignment,
  studentId,
  classId,
}: GradingModalSidebarAttemptsListProps) {
  const { data, loading } = useAllAssignmentSubmissionsQuery({
    variables: {
      id: assignment.id,
      first: 20,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Desc,
      classId,
      studentId,
    },
    fetchPolicy: 'cache-and-network',
  })
  const attempts =
    data?.node?.__typename === 'Assignment'
      ? data.node.submissions.edges.map(edge => edge.node)
      : []
  const totalAttempts =
    data?.node?.__typename === 'Assignment' ? data.node.submissions.totalCount : 0
  const submittedAttempts = attempts.filter(attempt => attempt.submittedAt) as RequireFields<
    BasicAssignmentSubmissionFragment,
    'submittedAt'
  >[]
  const inProgressAttempts = attempts.filter(attempt => !attempt.submittedAt)
  const hasSubmittedNonDeleted = submittedAttempts.some(attempt => !attempt.deletedAt)
  return (
    <>
      <Block marginLeft={'@size-m'} marginRight={'@size-m'} marginBottom={'@size-s'}>
        <HSpaced>
          <Capital>Attempts</Capital>
          <Space />
          {loading ? (
            <BodySmall
              color={'@text-color-secondary'}
              useDiv
              style={{ lineHeight: 1, maxHeight: '1em' }}
            >
              <LoadingIndicator />
            </BodySmall>
          ) : null}
          <BodySmall color={'@text-color-secondary'} style={{ lineHeight: 1 }}>
            {assignment.maxAttempts} max
          </BodySmall>
        </HSpaced>
      </Block>
      <ForfeitAttemptsWarning assignment={assignment} classId={classId} studentId={studentId} />
      {inProgressAttempts.length ? (
        <GradingModalSidebarInProgressAttemptListItem
          submission={inProgressAttempts[0]}
          noSubmissionIdInTabName={!hasSubmittedNonDeleted}
        />
      ) : null}
      {submittedAttempts.map((attempt, index) => {
        return (
          <GradingModalSidebarAttemptsListItem
            key={attempt.id}
            submission={attempt}
            index={submittedAttempts.length - index}
          />
        )
      })}
      {!attempts.length && !loading ? (
        <Block marginTop={'@size-xs'}>
          <BodySmall color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
            <MinusCircle /> Not started yet.
          </BodySmall>
        </Block>
      ) : null}
      {totalAttempts > attempts.length && attempts.length ? (
        <Block marginTop={'@size-xs'}>
          <BodySmall color={'@text-color-secondary'}>
            {totalAttempts - attempts.length} more attempt
            {totalAttempts - attempts.length === 1 ? '' : 's'}
          </BodySmall>
        </Block>
      ) : null}
    </>
  )
}
