import { useResource } from '@thesisedu/feature-react'
import { TuneItem } from '@thesisedu/feature-sheet-music-core'
import { renderAbc } from 'abcjs'

import { InstrumentResource } from './types'

export interface UseInstrumentsOptions {
  simpleMode?: boolean
  abc?: string
}
export function useInstruments({
  simpleMode,
  abc,
}: UseInstrumentsOptions = {}): InstrumentResource[] {
  let notes: TuneItem[] | undefined = undefined
  if (abc) {
    try {
      notes = renderAbc('*', abc)
    } catch {
      // Do nothing...
    }
  }
  const instruments = useResource<InstrumentResource>('Instrument')
  return instruments.filter(instrument => {
    if (notes && instrument.filter && !instrument.filter(notes)) {
      return false
    }
    if (simpleMode !== undefined && !simpleMode && instrument.simpleOnly) {
      return false
    }
    return true
  })
}
