import { s } from '@thesisedu/ui'

import { isNative } from '../isNative'
import { styledContainer } from '../styled'

export const PlayerFooterContainer = styledContainer<{ compact?: boolean; visible?: boolean }>`
  padding: ${props => s.size(props.compact ? 'xxs' : 's')} ${props =>
    s.size(props.compact ? 'xs' : 'm')};
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  transition: opacity 0.25s linear;
  opacity: ${props => (props.visible ? 1 : 0)};
`

export const FooterContainer = styledContainer<{
  footerVisible?: boolean
  isAudio: boolean
}>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    display: block;
    transition: opacity 0.25s linear;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 68%,
      rgba(0, 0, 0, 0.6) 100%
    );
    opacity: ${props => (props.isAudio || isNative || !props.footerVisible ? 0 : 1)};
  }
  z-index: 2;
`
