import { Form, HSpaced, useFlag, VSpaced } from '@thesisedu/react'
import { Select } from 'antd'
import React from 'react'

export function useDevToolsSettings() {
  return useFlag<DevToolsSettings>('devToolsSettings', {})
}

export interface DevToolsSettings {
  defaultViewMode?: ViewMode
}
export enum ViewMode {
  Inline = 'inline',
  FullScreen = 'full-screen',
  PoppedOut = 'poppedOut',
}

export function DevToolsSettings() {
  const [settings, setSettings] = useDevToolsSettings()
  const form = Form.useForm<DevToolsSettings>()
  React.useEffect(() => {
    form.reset(settings || {})
  }, [settings])
  return (
    <Form form={form} onFinish={values => setSettings(values)}>
      <VSpaced space={'@size-xs'} align={'stretch'}>
        <HSpaced justify={'flex-end'} space={'@size-xs'}>
          <Form.Submit children={'Save Changes'} size={'small'} />
        </HSpaced>
        <Form.Item name={'defaultViewMode'} label={'Default View Mode'}>
          <Select
            options={[
              { label: 'In-Browser', value: ViewMode.Inline },
              { label: 'Full Screen', value: ViewMode.FullScreen },
              { label: 'Popped Out', value: ViewMode.PoppedOut },
            ]}
            style={{ width: 300 }}
            size={'small'}
          />
        </Form.Item>
      </VSpaced>
    </Form>
  )
}
