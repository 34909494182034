import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { ReactFeature } from '@thesisedu/feature-react'
import { SiteConfigurationFieldResource } from '@thesisedu/feature-sites-web'
import { Form } from 'antd'
import React from 'react'

import { SiteStandardSetsField } from '../sites/SiteStandardSetsField'

export default function addSiteConfiguration(feature: ReactFeature) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useModifiedFragment(feature.hookManager, 'SiteConfiguration', fragment => {
    return addField(fragment, 'standardSetIds')
  })
  feature.resources.addResource<SiteConfigurationFieldResource>({
    type: 'SiteConfigurationField',
    group: 'Standards',
    identifier: 'standard-sets',
    renderField: ({ siteId }) => {
      if (!siteId) return <></>
      return (
        <Form.Item name={['standardSetIds']}>
          <SiteStandardSetsField siteId={siteId} />
        </Form.Item>
      )
    },
  })
}
