import { Feature } from '@thesisedu/feature'
import { modifyQueryDocument } from '@thesisedu/feature-apollo-react'
import { SubmitAssignmentRefetchQueriesHook } from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { ClassDecorationsDocument } from '@thesisedu/feature-courses-react/dist/schema'

export default function addSubmitAssignmentRefetchQueries(feature: Feature) {
  feature.hookManager.registerMutateHook<SubmitAssignmentRefetchQueriesHook>(
    'feature-assignments-react:submit-assignment-refetch-queries',
    queries => {
      // Using a hook like this is risky, but technically shouldn't cause problems.
      const selectedClassId = useSelectedClassId()
      const addlQueries = selectedClassId
        ? [
            {
              query: modifyQueryDocument(ClassDecorationsDocument, feature.hookManager),
              variables: { classId: selectedClassId },
            },
          ]
        : []
      return [...addlQueries, ...queries]
    },
  )
}
