import React, { useState } from 'react'
import { useParams, usePrompt } from 'react-router-dom'

import { Announcement } from './Announcement'

export function AnnouncementRoute() {
  const { announcementId } = useParams()
  const [dirty, setDirty] = useState(false)
  usePrompt(
    'Are you sure you want to leave this page? You have changes that have not been saved.',
    dirty,
  )
  return <Announcement onDirty={setDirty} announcementId={announcementId} />
}
