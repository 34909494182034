import React from 'react'

import { ExploreGridConfiguration } from './types'
import { TagFilterInput as TagSearchInput } from '../../schema'

export interface ExploreGridContextValue {
  configuration: ExploreGridConfiguration
  selectedTags: TagSearchInput[]
  setSelectedTags: (action: React.SetStateAction<TagSearchInput[]>) => void
}
export const ExploreGridContext = React.createContext<ExploreGridContextValue | undefined>(
  undefined,
)

export function useExploreGridContext(): ExploreGridContextValue | undefined
export function useExploreGridContext(require: false): ExploreGridContextValue | undefined
export function useExploreGridContext(require: true): ExploreGridContextValue
export function useExploreGridContext(require?: boolean): ExploreGridContextValue | undefined {
  const context = React.useContext(ExploreGridContext)
  if (!context && require) {
    throw new Error('ExploreGridContext is required, yet not provided.')
  }
  return context
}
