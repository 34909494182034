import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import {
  TagFragment,
  SearchTagsQuery,
  SearchTagsQueryVariables,
  SearchTagsDocument,
  OrderDirection,
} from '@thesisedu/feature-tags-react/dist/schema'
import { BodyLarge, Reset, Space, styled, BodySmall, Block, StyledComponent } from '@thesisedu/web'
import { Checkbox, Input } from 'antd'
import { Minus, Plus } from 'iconoir-react'
import React from 'react'
import { useDebounce } from 'use-debounce'

import { useExploreGridContext } from './ExploreGridContext'
import { ExploreGridItemProps } from './types'

const SEARCH_DEBOUNCE = 1000
const SEARCH_LIMIT = 32
export interface GridTagFilterProps extends ExploreGridItemProps {
  tagType: string
  label: string
}
export function GridTagFilter({ tagType, label }: GridTagFilterProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = React.useState(false)
  const [nameFilter, setNameFilter] = React.useState('')
  const [showNameFilter, setShowNameFilter] = React.useState(false)
  const [dNameFilter] = useDebounce(nameFilter, SEARCH_DEBOUNCE)
  const { selectedTags, setSelectedTags } = useExploreGridContext(true)
  const isActive = selectedTags.some(tag => tag.type === tagType)
  return (
    <Container>
      <Header
        onClick={e => {
          e.preventDefault()
          setExpanded(ex => !ex)
        }}
      >
        <BodyLarge>{label}</BodyLarge>
        <Space />
        {isActive ? <ActiveIndicator /> : null}
        {expanded ? <Minus /> : <Plus />}
      </Header>
      <Content className={expanded ? 'expanded' : ''} ref={containerRef}>
        <div>
          {showNameFilter ? (
            <Input.Search
              size={'small'}
              placeholder={`Find ${label}`}
              value={nameFilter}
              onChange={e => setNameFilter(e.target.value)}
            />
          ) : null}
          <InfiniteQuery<TagFragment, SearchTagsQuery, SearchTagsQueryVariables>
            document={SearchTagsDocument}
            variables={{
              types: [tagType],
              name: dNameFilter || undefined,
              first: SEARCH_LIMIT,
              orderBy: 'name',
              orderDirection: OrderDirection.Asc,
              filters: { inLicense: true },
            }}
            infiniteScrollerProps={{ scrollableTarget: containerRef.current || undefined }}
            queryOpts={{
              skip: !expanded,
              onCompleted: data => {
                const totalCount = data?.tags.totalCount
                if (!dNameFilter) {
                  setShowNameFilter(totalCount ? totalCount >= SEARCH_LIMIT : false)
                }
              },
            }}
            resultPath={'tags'}
            isCompact
            children={({ data }) => {
              const _tags = data?.tags.edges.map(edge => edge.node) || []
              const selectedTagsNotInList = selectedTags.filter(
                st => st.type === tagType && !_tags.some(t => t.name === st.name),
              )
              const tags = [...selectedTagsNotInList, ..._tags]
              if (tags?.length) {
                return tags.map(tag => (
                  <Block marginTop={'@size-xs'} key={tag.name}>
                    <Checkbox
                      checked={selectedTags.some(st => st.type === tagType && st.name === tag.name)}
                      onChange={e => {
                        setSelectedTags(st => {
                          const without = st.filter(i => i.type !== tagType || i.name !== tag.name)
                          if (e.target.checked) {
                            return [...without, { type: tagType, name: tag.name }]
                          } else {
                            return without
                          }
                        })
                      }}
                    >
                      {tag.name}
                    </Checkbox>
                  </Block>
                ))
              } else {
                return (
                  <BodySmall style={{ textAlign: 'center' }} color={'@text-color-secondary'}>
                    No tags found.
                  </BodySmall>
                )
              }
            }}
          />
        </div>
      </Content>
    </Container>
  )
}

export const ActiveIndicator: StyledComponent<'div'> = styled.div`
  width: ${props => props.theme['@size-xs']};
  height: ${props => props.theme['@size-xs']};
  border-radius: 50%;
  background: ${props => props.theme['@primary-color']};
  margin-right: ${props => props.theme['@size-xs']};
`
export const Container: StyledComponent<'div'> = styled.div`
  border-bottom: solid 1px ${props => props.theme['@border-color-split']};
`
export const Header: typeof Reset.Button = styled(Reset.Button)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-m']};
  width: 100%;
  * {
    transition: color 0.1s linear;
  }
  &:hover * {
    color: ${props => props.theme['@primary-color']};
  }
`
export const Content: StyledComponent<'div'> = styled.div`
  opacity: 0;
  transition:
    opacity 0.25s linear,
    max-height 0.25s ease-in-out;
  box-sizing: border-box;
  // For the outline on the search box.
  padding-top: ${props => props.theme['@size-xxs']};
  overflow-y: auto;
  max-height: 0px;
  &.expanded {
    opacity: 1;
    max-height: 400px;
  }
  > div {
    padding: 0 ${props => props.theme['@size-m']} ${props => props.theme['@size-s']}
      ${props => props.theme['@size-m']};
  }
`
