import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { findSegment } from '@thesisedu/feature-course-types'
import { useResizeObserver } from '@thesisedu/react'
import { s, styled } from '@thesisedu/ui'
import React from 'react'

import {
  SplitViewDraggableOutlineItem,
  SplitViewDraggableOutlineItemProps,
} from './SplitViewDraggableOutlineItem'
import { useUpdateOutlineItemContext } from './SplitViewOutlineItemContext'
import { SplitViewPendingChanges } from './SplitViewPendingChangesContext'
import { OnPlacedOpts, PlacementPaneImperativeHandle } from './types'
import { usePlacementOutlineHandle } from './usePlacementOutlineHandle'
import { useDragDropOutlineContext } from '../outline/DragDropOutlineContext'
import { OutlineListImperativeHandle } from '../outline/OutlineList'
import { OutlineParts } from '../outline/OutlineParts'
import { Segment } from '../outline/Segment'
import { OutlineCollapseProvider } from '../outline/context/OutlineCollapseContext'
import { OutlineContextProvider } from '../outline/context/OutlineContext'
import { OutlineListConfigContext } from '../outline/context/OutlineListConfigContext'
import { useOutlineData } from '../outline/useOutlineData'
import { CourseConfiguration } from '../types'

export interface PlacementOutlineProps {
  configuration: CourseConfiguration
  courseOrClassId: string
  selectedTermId?: string
  setSelectedTermId: (termId: string) => void
  forceCollapseIds?: string[]
  droppableDisabled?: boolean
  draggableDisabled?: boolean
  draggableComponent?: (props: SplitViewDraggableOutlineItemProps) => React.ReactElement
  sortableId: string
  onPlaced?: (opts: OnPlacedOpts) => void
  onSave?: (changes: SplitViewPendingChanges) => Promise<any>
  isConfigurationModified?: boolean
  emptyAction?: React.ReactElement
}
function _PlacementOutline(
  {
    configuration,
    courseOrClassId,
    selectedTermId,
    setSelectedTermId,
    droppableDisabled,
    draggableComponent: DraggableComponent = SplitViewDraggableOutlineItem,
    sortableId,
    forceCollapseIds,
    onPlaced,
    onSave,
    isConfigurationModified,
    emptyAction,
  }: PlacementOutlineProps,
  imperativeRef: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  const [expandedParentIds, setExpandedParentIds] = React.useState<string[]>([])
  const { draggingId, offsetLeft } = useDragDropOutlineContext(false) ?? {}
  const listRef = React.useRef<OutlineListImperativeHandle>(null)
  const activeSegment = draggingId
    ? findSegment(configuration.segments, draggingId.toString())
    : undefined
  const initialData = useOutlineData({
    courseOrClassId,
    segments: configuration.segments,
    selectedTermId,
    setSelectedTermId,
    forceCollapseIds: [...(forceCollapseIds ?? []), ...(activeSegment ? [activeSegment.id] : [])],
    collapseDepth: 2,
    expandedParentIds: droppableDisabled
      ? expandedParentIds
      : expandedParentIds.filter(parentId => parentId !== draggingId),
  })
  const { data } = useUpdateOutlineItemContext({
    data: initialData,
    sortableId,
    draggingId,
    droppableDisabled,
  })
  const { ref, height } = useResizeObserver()
  usePlacementOutlineHandle({
    sortableId,
    onPlaced,
    onSave,
    data,
    listRef,
    ref: imperativeRef,
    offsetLeft: offsetLeft ?? null,
    // Don't update the foreign segments if we've modified the configuration. If we've
    // modified the configuration, we'll save an "impure" version of the segment to
    // the "pure" foreign segment cache.
    dontUpdateForeignSegments: isConfigurationModified,
  })

  return (
    <OutlineListConfigContext.Provider
      value={React.useMemo(
        () => ({
          listId: sortableId,
          outlineItemComponent(props) {
            return <DraggableComponent {...props} droppableDisabled={droppableDisabled} />
          },
        }),
        [draggingId, sortableId, droppableDisabled, DraggableComponent],
      )}
    >
      <OutlineContextProvider
        renderSegment={({ item, index, children }) => (
          <Segment
            segment={item.segment}
            readOnly
            data-index={index}
            className={'hover-container content-container'}
            children={children}
            noLink
          />
        )}
      >
        <Container ref={ref}>
          <OutlineCollapseProvider
            expandedParentIds={expandedParentIds}
            setExpandedParentIds={setExpandedParentIds}
          >
            {data.items.length && !droppableDisabled ? (
              <SortableContext
                items={data.items}
                strategy={verticalListSortingStrategy}
                id={sortableId}
              >
                <StyledList
                  data={data}
                  height={height ?? 10}
                  listProps={{ width: 'auto' }}
                  forceRenderIds={draggingId ? [draggingId] : undefined}
                  ref={listRef}
                />
              </SortableContext>
            ) : data.items.length ? (
              <StyledList
                data={data}
                height={height ?? 10}
                listProps={{ width: 'auto' }}
                forceRenderIds={draggingId ? [draggingId] : undefined}
                ref={listRef}
              />
            ) : (
              <OutlineParts.Empty
                data={data}
                readOnly
                message={'No content yet!'}
                action={emptyAction}
              />
            )}
          </OutlineCollapseProvider>
        </Container>
      </OutlineContextProvider>
    </OutlineListConfigContext.Provider>
  )
}
export const PlacementOutline = React.forwardRef(_PlacementOutline)

const StyledList = styled(OutlineParts.List)`
  overflow-y: auto;
  flex: 1;
  margin: 0 calc(-1 * ${s.size('m')});
  .OutlineItem {
    left: ${s.size('m')} !important;
    right: ${s.size('m')} !important;
    width: auto !important;
  }
`
const Container = styled.div`
  flex: 1;
`
