import { Feature } from '@thesisedu/feature'
import { DocumentNode, FragmentDefinitionNode } from 'graphql'
import React from 'react'

import { debug } from './log'
import { TaskFragment, UserTaskProps, UserTaskResource } from './types'

export function addUserTask<T extends TaskFragment = TaskFragment>(
  feature: Feature,
  fragmentDocument: DocumentNode,
  Comp: (props: UserTaskProps<T>) => React.ReactElement,
) {
  const fragment = fragmentDocument.definitions.find(def => def.kind === 'FragmentDefinition') as
    | FragmentDefinitionNode
    | undefined
  if (!fragment) {
    throw new Error('Could not find fragment in query document.')
  }
  debug('adding UserTask type %s', fragment.name.value)
  feature.resources.addResource<UserTaskResource<T>>({
    type: 'UserTask',
    identifier: fragment.name.value,
    fragment,
    render: props => <Comp {...props} />,
  })
}
