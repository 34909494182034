import { notNil } from './notNil'

interface WithId {
  id: string
}

type NumberValuesOnly<T extends WithId> = {
  [K in keyof T]: T[K] extends number | null | undefined ? K : never
}
export interface GetNewWeightOpts<
  T extends WithId & Partial<Record<WeightField, number | null | undefined>>,
  WeightField extends keyof NumberValuesOnly<T>,
> {
  collection: T[]
  field: WeightField
  targetId: string
  draggingIds: string[]
  dropPosition: ('before' | 'after') | { toIndex: number; fromIndex: number }
}
export function getNewWeight<
  T extends WithId & Partial<Record<WeightField, number | null | undefined>>,
  WeightField extends keyof NumberValuesOnly<T>,
>({
  collection,
  field,
  targetId,
  dropPosition: _dropPosition,
  draggingIds,
}: GetNewWeightOpts<T, WeightField>): Record<string, number> | null {
  let dropPosition: 'before' | 'after'
  if (typeof _dropPosition === 'string') {
    dropPosition = _dropPosition
  } else {
    const { toIndex, fromIndex } = _dropPosition
    dropPosition = toIndex < fromIndex ? 'before' : 'after'
  }
  const targetIndex = collection.findIndex(item => item.id === targetId)
  if (targetIndex === -1) return null
  const itemBefore = collection[targetIndex + (dropPosition === 'before' ? -1 : 0)]
  const itemAfter = collection[targetIndex + (dropPosition === 'after' ? 1 : 0)]
  if (!itemBefore && !itemAfter) return null
  let weightBefore: number | null | undefined = itemBefore?.[field]
  let weightAfter: number | null | undefined = itemAfter?.[field]
  const minWeight = Math.min(...collection.map(c => c[field] ?? 0))
  if (weightBefore == null && weightAfter == null && minWeight != null) weightAfter = minWeight
  if (weightBefore == null && weightAfter == null) {
    if (minWeight != null) {
      // Just put it at the front.
      weightAfter = minWeight
    } else {
      // There are no weighted items.
      weightAfter = 10
    }
  }
  if (weightBefore == null && weightAfter != null) weightBefore = weightAfter - 1
  if (weightBefore != null && weightAfter == null) weightAfter = weightBefore + 1
  if (weightBefore == null || weightAfter == null) return null
  if (weightBefore === weightAfter) weightAfter += 1

  const itemsToUpdate = draggingIds.map(item => collection.find(c => c.id === item)).filter(notNil)

  // Add 2 to the length so we don't end up with duplicate weights.
  const delta = (weightAfter - weightBefore) / (itemsToUpdate.length + 2)
  return itemsToUpdate.reduce<Record<string, number>>((acc, item, index) => {
    acc[item.id] = weightBefore! + delta * (index + 1)
    return acc
  }, {})
}
