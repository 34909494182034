import { BellOutlined } from '@ant-design/icons'
import { setFlag, getFlag, styled } from '@thesisedu/web'
import { Badge, Button, Popover } from 'antd'
import { ButtonProps } from 'antd/es/button'
import moment from 'moment'
import React from 'react'

import { ViewerInteractionsList } from './ViewerInteractionsList'
import { useViewerInteractionsCountQuery } from './schema'

export interface UseViewerInteractionCountResult {
  updateLastViewed: () => void
  interactionCount?: number
}
export interface UseViewerInteractionCountOptions {
  types?: string[]
}

const REFRESH_INTERVAL = 30000
const VIEWER_INTERACTION_COUNT_FLAG = 'feature-interactions:last-viewed'
export function useViewerInteractionCount({ types }: UseViewerInteractionCountOptions) {
  const [lastViewed, _setLastViewed] = React.useState<string>(
    moment().subtract(30, 'days').format(),
  )
  function updateLastViewed() {
    _setLastViewed(moment().format())
    setFlag(VIEWER_INTERACTION_COUNT_FLAG, moment().format())
  }
  React.useEffect(() => {
    const flagValue = getFlag(VIEWER_INTERACTION_COUNT_FLAG)
    if (flagValue && moment(flagValue).isValid()) {
      _setLastViewed(moment(flagValue).format())
    }
  }, [])
  const { data } = useViewerInteractionsCountQuery({
    variables: { types, after: lastViewed },
    pollInterval: REFRESH_INTERVAL, // 30 seconds.
  })
  return {
    updateLastViewed,
    interactionCount: data?.viewer?.interactions.totalCount,
    lastViewed,
  }
}

const ListContainer = styled.div`
  height: 400px;
  width: 375px;
  overflow-y: auto;
  margin: -8px -18px;
`

const LAST_VIEWED_UPDATE_DELAY = 3000
export interface ViewerInteractionsButtonProps {
  buttonProps?: Partial<ButtonProps>
  types?: string[]
}
export function ViewerInteractionsButton({ buttonProps, types }: ViewerInteractionsButtonProps) {
  const { updateLastViewed, interactionCount, lastViewed } = useViewerInteractionCount({ types })
  const [visible, setVisible] = React.useState(false)
  const visibleTimeout = React.useRef<any>(null)
  const containerRef = React.useRef<any>()
  React.useEffect(() => {
    if (visibleTimeout.current) {
      clearTimeout(visibleTimeout.current)
    }
    if (visible) {
      visibleTimeout.current = setTimeout(() => {
        updateLastViewed()
      }, LAST_VIEWED_UPDATE_DELAY)
    }
  }, [visible])
  return (
    <Popover
      title={'Notifications'}
      content={
        <ListContainer ref={containerRef}>
          <ViewerInteractionsList
            types={types}
            infiniteScrollerProps={{
              scrollableTarget: containerRef.current,
            }}
            interactionListProps={{
              unreadAfterDate: lastViewed,
            }}
          />
        </ListContainer>
      }
      trigger={['click']}
      visible={visible}
      onVisibleChange={setVisible}
      placement={'bottomRight'}
    >
      <Badge count={interactionCount}>
        <Button icon={<BellOutlined />} {...buttonProps} />
      </Badge>
    </Popover>
  )
}
