import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { AdminClassFragment, ClassesDocument, ClassesQuery, ClassesQueryVariables } from '../schema'

export type ClassSelectProps = Partial<
  SingleSelectButtonProps<AdminClassFragment, ClassesQuery, ClassesQueryVariables>
>
export function ClassSelect(props: ClassSelectProps) {
  return (
    <SingleSelectButton<AdminClassFragment, ClassesQuery, ClassesQueryVariables>
      filterVariableName={'name'}
      selectLabel={'Select a Class'}
      maxSelection={1}
      getItemName={item => item.name}
      getItemSubtitle={item =>
        item.teachers.edges.map(edge => edge.node.user.name || edge.node.user.username).join(', ')
      }
      document={ClassesDocument}
      getResult={data => data?.classes}
      setResult={(previous, result) => ({
        ...previous,
        classes: {
          ...previous.classes,
          ...result,
        },
      })}
      {...props}
    />
  )
}
