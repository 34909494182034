import { Button, Dropdown, Modal, Text } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { useRemoveStudent } from '../../teacher/RemoveStudentButton'
import {
  StudentActionConfigureProps,
  StudentActionProps,
  StudentActionResource,
} from '../../teacher/students/StudentActionsDropdown'
import { useTeacherClass } from '../../useClass'

function ConfigureRemoveStudentAction({
  students,
  loading,
  onAct,
  onClose,
}: StudentActionConfigureProps) {
  const classIds = React.useMemo(() => [...new Set(students.map(s => s.classId))], [students])
  const { cls } = useTeacherClass(classIds[0], false)
  return (
    <Modal
      visible
      onVisibleChange={v => {
        if (!v) {
          onClose()
        }
      }}
      title={'Are you sure?'}
    >
      <Text bottom>
        Are you sure you want to remove {students.length === 1 ? 'this student' : 'these students'}{' '}
        from your {cls?.name ?? ''} class?
      </Text>
      <Text>
        If you change your mind, you can always add them back later and they can pickup where they
        left off.
      </Text>
      <Modal.Footer>
        <Modal.CloseButton children={'No, go back'} />
        <Button
          variant={'primary'}
          status={'danger'}
          onPress={() => onAct(undefined)}
          children={`Yes, remove student${students.length === 1 ? '' : 's'}`}
          loading={loading}
        />
      </Modal.Footer>
    </Modal>
  )
}

export function RemoveStudentAction({ students, onConfigure }: StudentActionProps) {
  const classIds = React.useMemo(() => [...new Set(students.map(s => s.classId))], [students])
  const { cls } = useTeacherClass(classIds[0], false)
  const canRemove = !!cls?.canRemoveStudents
  return (
    <Dropdown.Item disabled={!canRemove} icon={<Trash />} danger onClick={() => onConfigure()}>
      Remove from class
    </Dropdown.Item>
  )
}

export default {
  type: 'StudentAction',
  identifier: 'RemoveStudent',
  weight: Infinity,
  group: 'danger',
  render: RemoveStudentAction,
  renderConfigure: ConfigureRemoveStudentAction,
  useAct: () => {
    const removeStudent = useRemoveStudent()
    return student => removeStudent(student.studentId, student.classId, true)
  },
} satisfies StudentActionResource
