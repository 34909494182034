import { SearchTagsQueryVariables, useSearchTagsQuery as ustQuery } from '../schema'

export const useSearchTagsQuery = (query?: string, type?: string) => {
  const variables: SearchTagsQueryVariables = { name: query || '' }
  if (type) {
    variables.types = [type]
  }
  const { data, loading, error } = ustQuery({
    variables,
    fetchPolicy: 'no-cache',
  })

  return { data, loading, error, tags: data?.tags?.edges.map(edge => edge.node) || [] }
}
