import { HSpaced, Text } from '@thesisedu/ui'
import { MagicWand } from '@thesisedu/ui/icons'
import React from 'react'

import { StandardSelectProps } from './StandardSelect'
import { TagListItem } from './TagListItem'

export interface SuggestionsListProps extends Pick<StandardSelectProps, 'value' | 'onChange'> {
  suggestions: Required<StandardSelectProps>['suggestions']
}
export function SuggestionsList({ suggestions, value, onChange }: SuggestionsListProps) {
  return (
    <>
      {suggestions.map(suggestion => {
        const isSelected = value?.some(v => v.id === suggestion.id)
        return (
          <TagListItem
            key={suggestion.id}
            tag={suggestion}
            value={isSelected}
            footer={
              <HSpaced space={'xxs'}>
                <Text color={'primary'} level={'xs'}>
                  <MagicWand />
                </Text>
                <Text color={'primary'} level={'xs'}>
                  Suggested
                </Text>
                <Text level={'xs'} color={'secondary'} left={'xs'} truncate>
                  {suggestion.standardSet.name}
                </Text>
              </HSpaced>
            }
            onChange={selected => {
              if (selected) {
                onChange?.([...(value ?? []), suggestion])
              } else {
                onChange?.((value ?? []).filter(v => v.id !== suggestion.id))
              }
            }}
            hasChildren={false}
          />
        )
      })}
    </>
  )
}
