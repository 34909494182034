import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper } from '@thesisedu/web'
import { Button, Popconfirm } from 'antd'
import { Trash } from 'iconoir-react'
import React from 'react'

export interface DeleteCampaignButtonProps {
  campaignId: string
  onDeleted: () => void
}
export function DeleteCampaignButton({ campaignId, onDeleted }: DeleteCampaignButtonProps) {
  const [del] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that campaign.',
    refetchQueries: ['trialRequestCampaigns'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onDeleted()
    },
  })
  return (
    <Popconfirm
      okButtonProps={{ danger: true }}
      okText={'Yes, delete'}
      cancelText={'No, keep'}
      title={'Are you sure you want to delete this campaign?'}
      onConfirm={() =>
        del({
          variables: {
            id: campaignId,
          },
        })
      }
    >
      <Button danger children={'Delete Campaign'} icon={<AntIconWrapper children={<Trash />} />} />
    </Popconfirm>
  )
}
