import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { Link, useFlag } from '@thesisedu/react'
import { Block, SortableTable } from '@thesisedu/web'
import { IconView } from '@thesisedu/web/dist/IconSelect'
import { Input } from 'antd'
import { Search } from 'iconoir-react'
import React from 'react'

import { DeleteTagButton } from './DeleteTagButton'
import {
  OrderDirection,
  TagFragment,
  TagsDocument,
  TagsQuery,
  TagsQueryVariables,
  useTagTypesQuery,
} from '../schema'

interface TagListFilters {
  name: string
  types?: string[]
  orderBy: string
  orderDirection: OrderDirection
}

const SEARCH_DEBOUNCE = 1000
export interface TagListProps {
  showNameFilter?: boolean
}
export function TagList({ showNameFilter }: TagListProps) {
  const [filters, setFilters] = useFlag<TagListFilters>('tag-list-filters', {
    name: '',
    orderBy: 'createdAt',
    orderDirection: OrderDirection.Desc,
  })
  const [name, setName] = React.useState(filters.name)
  React.useEffect(() => {
    setName(filters.name)
  }, [filters.name])
  const nameTimeout = React.useRef<any>(null)
  const { data } = useTagTypesQuery()
  const tagTypes = data?.tagTypes || []

  return (
    <>
      {showNameFilter ? (
        <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
          <Input
            style={{ width: 400 }}
            suffix={<Search />}
            value={name}
            onChange={e => {
              const val = e.target.value
              setName(val)
              clearTimeout(nameTimeout.current)
              nameTimeout.current = setTimeout(() => {
                setFilters(f => ({
                  ...f,
                  name: val,
                }))
              }, SEARCH_DEBOUNCE)
            }}
            placeholder={'Find a tag...'}
          />
        </Block>
      ) : null}
      <InfiniteQuery<TagFragment, TagsQuery, TagsQueryVariables>
        document={TagsDocument}
        resultPath={'tags'}
        variables={{
          orderBy: filters.orderBy,
          orderDirection: filters.orderDirection,
          name: filters.name,
          types: filters.types?.length ? filters.types : undefined,
        }}
        children={({ data, loading }) => (
          <SortableTable<TagFragment>
            orderBy={filters.orderBy}
            orderDirection={filters.orderDirection}
            onSortChange={(orderBy, orderDirection) => {
              setFilters(f => ({
                ...f,
                orderBy,
                orderDirection,
              }))
            }}
            onChange={(pagination, _filters) => {
              setFilters(f => ({
                ...f,
                types: (_filters.type || []) as string[],
              }))
            }}
            dataSource={data?.tags.edges.map(edge => edge.node) || []}
            loading={loading}
            pagination={false}
            columns={[
              {
                width: 30,
                key: 'icon',
                render: (_, record) =>
                  record.config?.icon ? <IconView icon={record.config.icon} /> : null,
              },
              {
                title: 'Name',
                key: 'name',
                dataIndex: 'name',
                sorter: true,
                render: (name, record) => <Link to={record.id}>{name}</Link>,
              },
              {
                title: 'Type',
                key: 'type',
                dataIndex: 'type',
                filters: tagTypes.map(tagType => ({
                  text: tagType,
                  value: tagType,
                })),
                filtered: filters.types && filters.types.length > 0,
                filteredValue: filters.types || [],
                sorter: true,
              },
              {
                title: '',
                key: 'actions',
                width: 150,
                render: (_, record) => (
                  <>
                    <Link to={record.id}>Edit</Link>
                    <DeleteTagButton tagId={record.id} />
                  </>
                ),
              },
            ]}
          />
        )}
      />
    </>
  )
}
