import { Form, Form$ } from '@thesisedu/ui'
import React from 'react'

import { useQuestionSettingsContext } from './QuestionSettingsContext'
import { QuestionSettingsValue } from './types'
import { useQuestionSettingsSubmit } from './useQuestionSettingsSubmit'
import { FullAssignment } from '../../grading/types'

export interface QuestionSettingsSubmitProps
  extends Omit<Form$.StandaloneFormSubmitProps<QuestionSettingsValue>, 'form' | 'onFinish'> {
  assignment: FullAssignment | undefined
}
export function QuestionSettingsSubmit({ assignment, ...rest }: QuestionSettingsSubmitProps) {
  const { form } = useQuestionSettingsContext(false) ?? {}
  const handleSubmit = useQuestionSettingsSubmit({ assignment })
  return form ? <Form.StandaloneSubmit form={form} onFinish={handleSubmit} {...rest} /> : null
}
