import { ApolloReactFeature } from '@thesisedu/feature-apollo-react'
import { WebContext } from '@thesisedu/feature-web'
import React, { useContext, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { InteractionsContext } from './InteractionsContext'
import { InteractionsManager } from './InteractionsManager'
import { debug, warn } from './log'
import { ApolloClient } from './types'

export const LOCALSTORAGE_EXISTING_KEY = 'feature-interactions-existing'
export const PAGE_TIMEOUT = 1000 * 60 * 15 // 15 minutes

export const WebInteractionsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const root = useContext(WebContext)
  const apollo = root?.featureWeb.getFeature<ApolloReactFeature>(ApolloReactFeature.package)
  let client: ApolloClient | undefined
  try {
    client = apollo!.client!
  } catch {
    client = undefined
  }
  const [manager, setManager] = useState<InteractionsManager>()
  useEffect(() => {
    if (client) {
      let existing = []
      try {
        existing = JSON.parse(localStorage.getItem(LOCALSTORAGE_EXISTING_KEY) || '[]')
        localStorage.removeItem(LOCALSTORAGE_EXISTING_KEY)
      } catch (err: any) {
        warn('error fetching existing contents', err)
      }
      setManager(new InteractionsManager(root!.featureWeb.deps.hookManager, client, existing))
    }
  }, [client])
  useEffect(() => {
    if (manager) {
      const onBeforeUnload = () => {
        debug('checking for pending interactions to persist')
        if (manager && manager.pendingInteractions.length) {
          debug('found pending interactions to persist')
          manager.stopAllInteractions('discard')
          localStorage.setItem(
            LOCALSTORAGE_EXISTING_KEY,
            JSON.stringify(manager.pendingInteractions),
          )
        }
      }
      window.addEventListener('beforeunload', onBeforeUnload)
      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
      }
    }
  }, [manager])
  useIdleTimer({
    onActive() {
      if (manager) {
        manager.resumeInteractions()
      }
    },
    onIdle() {
      if (manager) {
        manager.pauseInteractions()
      }
    },
    element: document,
    timeout: PAGE_TIMEOUT,
  })
  return <InteractionsContext.Provider value={manager}>{children}</InteractionsContext.Provider>
}
