import { Button, Button$, Modal, Text, TextAreaField, VSpaced } from '@thesisedu/ui'
import { Check, Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { useRubricGraderContext } from './RubricGraderContext'

interface CommentButtonProps extends Partial<Button$.ButtonProps> {
  value?: string
  onChange: (value: string | null | undefined) => void
}
function CommentButton({ value, onChange, ...buttonProps }: CommentButtonProps) {
  const [commentValue, setCommentValue] = React.useState(value)
  const [visible, setVisible] = React.useState(false)
  return (
    <Modal
      title={'Add Rubric Comment'}
      visible={visible}
      width={600}
      onVisibleChange={v => {
        setVisible(v)
        setCommentValue(value)
      }}
      trigger={<Button {...buttonProps} />}
    >
      <TextAreaField label={'Comments'} value={commentValue} onChange={setCommentValue} />
      <Modal.Footer>
        <Modal.CloseButton />
        <Button
          status={'danger'}
          icon={<Trash />}
          onPress={() => {
            onChange(undefined)
            setVisible(false)
          }}
          children={'Remove Comments'}
        />
        <Button
          variant={'primary'}
          icon={<Check />}
          children={'Save Comments'}
          onPress={() => {
            onChange(commentValue)
            setVisible(false)
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export interface RubricGraderPresetCommentsProps {
  categoryId: string
}
export function RubricGraderPresetComments({ categoryId }: RubricGraderPresetCommentsProps) {
  const { value, onChange, disabled } = useRubricGraderContext(true)
  const comment = value?.comments?.[categoryId]
  if (disabled && !comment) return null
  return (
    <VSpaced vertical={'xs'} align={'flex-start'} space={'xxs'}>
      {comment ? (
        <Text level={'xs'} color={'primary'}>
          <strong>Comment: </strong> {comment}
        </Text>
      ) : null}
      {disabled ? null : (
        <CommentButton
          value={comment}
          disabled={disabled}
          children={comment ? 'Edit Comment...' : 'Add Comment...'}
          size={'extraSmall'}
          variant={'chromeless'}
          onChange={newValue => {
            const { [categoryId]: existing, ...comments } = value?.comments ?? {}
            onChange({
              ...value,
              comments: newValue
                ? {
                    ...comments,
                    [categoryId]: newValue,
                  }
                : comments,
            })
          }}
        />
      )}
    </VSpaced>
  )
}
