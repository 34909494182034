import {
  AppstoreOutlined,
  ArrowRightOutlined,
  BookOutlined,
  ProjectOutlined,
  ScheduleOutlined,
} from '@ant-design/icons'
import { FeatureUse } from '@thesisedu/feature'
import {
  addField,
  addFragmentReference,
  useModifiedFragment,
} from '@thesisedu/feature-apollo-react'
import { InteractionTypeResource } from '@thesisedu/feature-interactions-react'
import { LicenseFormPayload, LicenseHooks } from '@thesisedu/feature-licenses-react'
import { isInNode, ReactFeature, ReactFeatureDependencies } from '@thesisedu/feature-react'
import { TagType } from '@thesisedu/feature-tags-react'
import { addUserTask } from '@thesisedu/feature-user-tasks-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import { WrapAppContext } from '@thesisedu/feature-web/dist/types'
import { FragmentDefinitionNode } from 'graphql'
import React from 'react'

import { TAG_TYPES, SECTION_LABELS, LABEL_PLACEHOLDER } from './constants'
import { debug } from './log'
import { addBadgeResources } from './resources/badges'
import { BasicClassCourseFragmentDoc, ViewSegmentUserTaskFragmentDoc } from './schema'
import { addSegmentPresets } from './segment_presets'
import { SegmentTypeResource, CoursesReactOptions, SegmentCompletionHost } from './types'

export class CoursesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-courses-react'
  static path = ''
  static requires: string[] = []
  public options!: CoursesReactOptions
  public completionHost?: SegmentCompletionHost
  public tagTypes: TagType[]
  public sectionLabels: string[]
  public labelPlaceholder: string

  constructor(options: CoursesReactOptions, deps: ReactFeatureDependencies) {
    super(
      {
        alwaysShowTagTypes: [
          'COURSE',
          'CONCEPT_OBJECTIVE',
          'IMPLEMENTATION_GUIDE',
          'CONTENT_AREA',
          'TOPIC',
        ],
        ...options,
      },
      deps,
    )
    this.tagTypes = this.options.tagTypes
      ? this.options.tagTypes
      : [
          ...TAG_TYPES.map(tagType => {
            const override = options.tagNameOverrides?.[tagType.type]
            if (override) {
              return { ...tagType, label: override }
            } else {
              return tagType
            }
          }),
          ...(this.options.additionalTagTypes || []),
        ]
    this.sectionLabels = options.sectionLabels || SECTION_LABELS
    this.labelPlaceholder = options.labelPlaceholder || LABEL_PLACEHOLDER
  }

  public reactResources() {
    if (this.options.getSegmentCompletionHost) {
      this.completionHost = this.options.getSegmentCompletionHost()
    } else {
      this.completionHost =
        require('./completion/DefaultSegmentCompletionHost').DefaultSegmentCompletionHost
    }
    debug('adding wrap app hook')
    const { SegmentPreviewProvider } = require('./segment/SegmentPreviewContext')
    this.hookManager.registerMutateHook<React.ReactElement, WrapAppContext>(
      WRAP_APP_HOOK,
      children => {
        const { CourseViewerModalContextProvider } = require('./contexts/CourseViewerModalContext')
        const { PlacementDrawerProvider } = require('./placement/drawer/PlacementDrawerProvider')
        return (
          <PlacementDrawerProvider>
            <CourseViewerModalContextProvider>
              <SegmentPreviewProvider children={children} />
            </CourseViewerModalContextProvider>
          </PlacementDrawerProvider>
        )
      },
    )
    debug('adding default segment types')
    const { Settings } = require('@thesisedu/react/icons')
    const { getSegmentNavigationItems } = require('./course_viewer/getSegmentNavigationItems')
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Group',
      renderEdit: require('./segment_types/group/GroupEdit').GroupEdit,
      getNavigation: getSegmentNavigationItems,
      hasChildren: true,
      icon: AppstoreOutlined,
      defaults: {
        name: 'Segment Group',
        config: {
          label: 'Group',
          teacherContent: [],
          studentContent: [],
        },
        childSegments: [],
      },
    })
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Term',
      renderEdit: require('./segment_types/term/TermEdit').TermEdit,
      getNavigation: getSegmentNavigationItems,
      hasChildren: true,
      icon: ScheduleOutlined,
      defaults: {
        name: 'Fall Semester',
        config: {
          teacherContent: [],
          studentContent: [],
        },
        childSegments: [],
      },
    })
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Topic',
      renderEdit: require('./segment_types/topic/TopicEdit').TopicEdit,
      getNavigation: getSegmentNavigationItems,
      hasChildren: true,
      icon: ProjectOutlined,
      defaults: {
        name: 'Business Principles',
        config: {
          teacherContent: [],
          studentContent: [],
        },
        childSegments: [],
      },
    })
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Reference',
      renderEdit: require('./segment_types/reference/ReferenceEdit').ReferenceEdit,
      icon: ArrowRightOutlined,
      defaults: {
        name: 'Segment Reference',
        config: {
          referenceId: '',
        },
      },
    })
    this.resources.addResource<SegmentTypeResource>({
      type: 'SegmentType',
      identifier: 'Section',
      renderEdit: require('./segment_types/section/SectionEdit').SectionEdit,
      renderContent: require('./segment_types/section/SectionContent').SectionContent,
      renderSimpleEdit: require('./segment_types/section/SectionSimpleEdit').SectionSimpleEdit,
      getNavigation: opts => {
        const existing = getSegmentNavigationItems(opts)
        return opts.isPreview ||
          opts.viewer?.group !== 'TEACHER' ||
          (!opts.cls?.canSyncSegments && !opts.cls?.canEnableSegments)
          ? existing
          : [
              ...existing,
              {
                id: 'settings',
                name: 'Settings',
                icon: <Settings />,
              },
            ]
      },
      icon: BookOutlined,
      allowCustom: true,
      defaults: {
        name: 'Section A',
        config: {
          label: null,
          studentContent: [],
          teacherContent: [],
          days: 0,
        },
      },
    })
    debug('adding segment presets')
    addSegmentPresets(this)
    debug('adding segment actions')
    const { addSegmentActionResources } = require('./resources/segment_actions')
    addSegmentActionResources(this)
    addBadgeResources(this)
    debug('adding interaction types')
    this.resources.addResource<InteractionTypeResource>({
      type: 'InteractionType',
      identifier: 'VIEW_SEGMENT',
      handler: require('./interactions/ViewSegment').ViewSegment,
    })
    debug('adding user task types')
    addUserTask(this, ViewSegmentUserTaskFragmentDoc, require('./tasks').ViewSegmentUserTask)
    debug('modfying class fragment')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'MinimalClass', (fragment, context) => {
      const otherFragment = BasicClassCourseFragmentDoc.definitions[1] as FragmentDefinitionNode
      if (!context.additionalDefinitions.some(def => def.name.value === otherFragment.name.value)) {
        context.additionalDefinitions.push(otherFragment)
      }
      return addFragmentReference(
        fragment,
        BasicClassCourseFragmentDoc.definitions[0] as FragmentDefinitionNode,
        context,
      )
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'MinimalClass', fragment => {
      const result = addField(fragment, 'canCopySegmentsTo')
      return addField(result, 'canChangeCourseStructure')
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Class', fragment => {
      let result = addField(fragment, 'canEnableSegments')
      result = addField(result, 'canCopySegmentsFrom')
      result = addField(result, 'canCopySegmentsTo')
      result = addField(result, 'canChangeCourseStructure')
      result = addField(result, 'isOwn')
      return addField(result, 'canLockSegments')
    })
    debug('modifying tag fragment')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Tag', fragment => {
      return addField(fragment, 'inLicense')
    })
    debug('modifying license fragment')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'License', fragment => {
      return addField(fragment, 'courses')
    })
    debug('modifying license form')
    const { LicenseCourseField } = require('./license/LicenseCourseField')
    const { Form } = require('antd')
    this.hookManager.registerMutateHook<LicenseFormPayload>(LicenseHooks.LicenseForm, children => {
      return (
        <>
          <Form.Item name={'courses'} label={'Courses'}>
            <LicenseCourseField />
          </Form.Item>
          {children}
        </>
      )
    })

    const { addPdfGeneratorResource } = require('./pdf_generation/PDFGeneratorResource')
    addPdfGeneratorResource(this)

    const { addClassConfigurationFields } = require('./resources/class_configuration')
    addClassConfigurationFields(this)

    const { addSegmentIndicators } = require('./resources/segment_indicators')
    addSegmentIndicators(this.resources)

    const { addSegmentGridRendererResources } = require('./resources/segment_grid_renderer')
    addSegmentGridRendererResources(this.resources)

    require('./resources/widgets').default(this)
    require('./hooks/ExpectedErrors').default(this)
    require('./resources/deep_link').default(this)
    require('./resources/ClassTeacherPermission').default(this)
    require('./hooks/routes').default(this)
    require('./hooks/CreateClass').default(this)
    require('./hooks/ListClasses').default(this)
    require('./hooks/routes/cls').default(this)
    require('./hooks/CourseClassLeftNav').default(this)
    require('./hooks/routes/cls/routes').default(this)
    require('./hooks/ClassDangerZone').default(this)
    require('./resources/ExploreLookingFor/Lesson').default(this)
    require('./hooks/TodoTopRight').default(this)
    require('./resources/BackgroundJobReporter/importSegments').default(this)
    require('./resources/BackgroundJobReporter/copyTagsToChildren').default(this)
    require('./resources/MyContentPage/CustomContent').default(this)
  }

  public prepareEarly() {
    if (this.root.getFeature('@thesisedu/feature-class-sync-react') && !isInNode()) {
      require('./resources/sync').default(this)
      require('./hooks/sync').default(this)
    }
  }
}

export const coursesReactFeature = (opts: CoursesReactOptions = {}): FeatureUse => [
  CoursesReactFeature,
  opts,
]
