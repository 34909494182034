import { ClassLeftNav } from '@thesisedu/feature-classes-react'
import { ClassFragment as ClassesClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import React from 'react'

import { ShellClassHeader } from './ShellClassHeader'
import { ClassCourseFragment } from '../../../schema'

export interface ClassRouteNavProps {
  cls: ClassCourseFragment
  group: string
}
export function ClassRouteNav({ cls, group }: ClassRouteNavProps) {
  const color = cls.courseVersion?.course.basicConfiguration?.color
  const icon = cls.courseVersion?.course.basicConfiguration?.icon
  return (
    <ClassLeftNav
      cls={cls as ClassesClassFragment}
      leftTopItem={<ShellClassHeader color={color} icon={icon} />}
      group={group}
    />
  )
}
