import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { HSpaced } from '@thesisedu/ui'
import { Body, DATE_FORMATS } from '@thesisedu/web'
import { Divider } from 'antd'
import moment from 'moment'
import React from 'react'

import { useCourseContext } from '../../contexts/CourseContext'
import { SegmentSyncSettingsField } from '../../resources/sync/SegmentSyncSettingsField'
import { ScheduleSegmentButton } from '../../segment/ScheduleSegmentButton'
import { ClassFragmentWithPermissions, SegmentProps } from '../../types'

export function SectionSettings({ segment }: SegmentProps) {
  const { segmentMetadata } = useCourseContext(true)
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canEnableSegments = cls?.canEnableSegments
  const canSyncSegments = cls?.canSyncSegments
  const scheduledAt = segmentMetadata?.segments?.find(s => s.id === segment.id)?.scheduledAt
  const hasClassSync = useFeatureRoot().getFeature('@thesisedu/feature-class-sync-react')
  return (
    <>
      {canEnableSegments ? (
        <>
          <Body isBlock style={{ maxWidth: 800 }}>
            You may schedule this content to be enabled at a later date.
          </Body>
          <HSpaced>
            <ScheduleSegmentButton segmentId={segment.id} />
            {scheduledAt ? (
              <Body>Scheduled for {moment(scheduledAt).format(DATE_FORMATS.FULL)}</Body>
            ) : null}
          </HSpaced>
        </>
      ) : null}
      {hasClassSync && canSyncSegments ? (
        <>
          {canEnableSegments ? <Divider /> : null}
          <SegmentSyncSettingsField segmentId={segment.id} />
        </>
      ) : null}
    </>
  )
}
