import { onMutationError } from '@thesisedu/feature-apollo-react'
import { message, Popconfirm } from 'antd'
import React from 'react'

import { useCancelSubscriptionMutation, ViewerSubscriptionsDocument } from './schema'

export interface CancelSubscriptionButtonProps {
  subscriptionId: string
}
export const CancelSubscriptionButton: React.FC<
  React.PropsWithChildren<CancelSubscriptionButtonProps>
> = ({ subscriptionId, children }) => {
  const [cancel] = useCancelSubscriptionMutation({
    refetchQueries: [{ query: ViewerSubscriptionsDocument }],
    variables: { input: { id: subscriptionId } },
    awaitRefetchQueries: true,
    onError: onMutationError('There was an error cancelling your subscription.'),
    onCompleted: () => {
      message.success('Subscription cancelled successfully.')
    },
  })
  return (
    <Popconfirm
      title={
        'Are you sure you want to cancel this subscription? You will no longer be able to access ' +
        'the associated course.'
      }
      onConfirm={() => cancel()}
      okText={'Yes, cancel'}
      okType={'danger'}
      cancelText={'No, keep'}
      placement={'topRight'}
    >
      {children as any}
    </Popconfirm>
  )
}
