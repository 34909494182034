import { useTheme } from '@thesisedu/react'
import { RefreshDouble } from '@thesisedu/react/icons'
import { ExtraSmallButton } from '@thesisedu/web'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'
import { useCourseViewerModalContext } from '../../contexts/CourseViewerModalContext'
import { useSegmentDecoration } from '../../contexts/SegmentDecorationsContext'
import { SegmentIndicatorResource } from '../../outline/SegmentIndicators'
import { SegmentDecorationWithAssignmentSyncFragment } from '../../schema'

export interface SegmentSyncIndicatorProps {
  segmentId: string
}
export function SegmentSyncIndicator({ segmentId }: SegmentSyncIndicatorProps) {
  const { assignmentSyncs, materialSyncs } =
    useSegmentDecoration<SegmentDecorationWithAssignmentSyncFragment>(segmentId) || {}
  const { setSegmentId } = useCourseViewerModalContext(false) || {}
  const theme = useTheme()
  const syncedWithClassNames = [
    ...new Set([
      ...(assignmentSyncs?.map(sync => sync.classSync.label) || []),
      ...(materialSyncs?.map(sync => sync.classSync.label) || []),
    ]),
  ]
  if (syncedWithClassNames.length) {
    return (
      <ExtraSmallButton
        title={`Synced with ${syncedWithClassNames.join(', ')}`}
        icon={<RefreshDouble />}
        style={{ background: theme['@blue-light'], color: theme['@blue'] }}
        onClick={() => {
          if (setSegmentId) {
            setSegmentId(segmentId, 'settings')
          }
        }}
      />
    )
  } else return null
}

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<SegmentIndicatorResource>({
    type: 'SegmentIndicator',
    identifier: 'SegmentSync',
    component: SegmentSyncIndicator,
  })
}
