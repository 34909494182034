import { Feature } from '@thesisedu/feature'
import {
  AssignmentGrader,
  AssignmentGraderCanGrade,
  AssignmentGraderResource,
} from '@thesisedu/feature-assignments-core'
import { renderAbc } from 'abcjs'

import { debug } from './log'

export interface TuneClef {
  el_type: 'clef'
  type: 'treble' // TODO: Other clef types.
}
export interface TuneItemBase {
  endChar: number
  startChar: number
  el_type: string
}
export interface TuneNoteItemChord {
  name: string
  position: 'above' // TODO: Support other positions?
}
export interface TuneNoteItemPitch {
  pitch: number
  verticalPos: number
  highestVert: number
  accidental?: 'sharp' | 'flat'
}
export interface TuneNoteItemLyric {
  divider: string
  syllable: string
}
export interface TuneNoteBase extends TuneItemBase {
  el_type: 'note'
  duration: number
  chord?: TuneNoteItemChord[]
  lyric?: TuneNoteItemLyric[]
}
export interface TuneNoteItem extends TuneNoteBase {
  pitches: TuneNoteItemPitch[]
  startBeam?: boolean
  endBeam?: boolean
}
export function isNoteItem(item: TuneNoteBase): item is TuneNoteItem {
  return !!(item as TuneNoteItem).pitches
}
export function isRestItem(item: TuneNoteBase): item is TuneRestItem {
  return !!(item as TuneRestItem).rest
}
export interface TuneRestItem extends TuneNoteBase {
  rest: {
    type: 'invisible' // TODO: Handle other rest types.
  }
}
export interface TuneBarItem extends TuneItemBase {
  el_type: 'bar'
  type: 'bar_thin' // TODO: Other bar types.
}
export type TuneVoiceItem = TuneNoteItem | TuneRestItem | TuneBarItem
export type TuneVoice = TuneVoiceItem[]
export interface TuneStaff {
  clef: TuneClef
  voices: TuneVoice[]
}
export interface TuneLine {
  staff: TuneStaff[]
}
export interface TuneMeter {
  num: number
  den: number
}
export interface TuneItem {
  lines: TuneLine[]
  meter: TuneMeter
}

export interface CustomGroup {
  abc: string
  id: string
}

export enum SheetMusicDragDropRecordMode {
  None = 'none',
  Allow = 'allow',
  Require = 'require',
}
export interface SheetMusicDragDropConfig {
  answer?: string
  customGroups?: CustomGroup[]
  /** Defaults to true if there are no custom groups, otherwise false unless specified. */
  showControls?: boolean
  /** If empty or undefined, falls back to the class option. */
  useAdvancedMode?: boolean
  defaultInstrument?: string
  /** By default, the time signature used in the answer is the only one allowed. */
  allowChangingTimeSignature?: boolean
  /** The default meter to use. */
  meter?: TuneMeter
  /** By default, the BPM used in the answer is the only one allowed. */
  allowChangingBpm?: boolean
  /** The default BPM to use. */
  bpm?: number
  /** How an associated recording should be required / enabled. */
  recordMode?: SheetMusicDragDropRecordMode
}

export type SheetMusicDragDropValue = string | { abc: string; [key: string]: any }

export function flattenNoteItems(items: TuneItem[]): TuneNoteBase[] {
  return items.reduce<TuneNoteBase[]>((acc, root) => {
    const result: TuneNoteBase[] = []
    for (const line of root.lines) {
      for (const staff of line.staff) {
        for (const voice of staff.voices) {
          for (const item of voice) {
            if (item.el_type === 'note') {
              result.push(item)
            }
          }
        }
      }
    }
    return acc.concat(result)
  }, [])
}

export interface AnnotatedNoteItem extends TuneNoteBase {
  correct: boolean
}
interface AnnotateRangesResult {
  annotations: AnnotatedNoteItem[]
  submissionAnnotations: AnnotatedNoteItem[]
  insertions: number
  distance: number
}

function notesAreSame(a?: TuneNoteBase, b?: TuneNoteBase): boolean {
  if ((a && !b) || (b && !a)) return false
  if (a && b) {
    if (isNoteItem(a)) {
      if (!isNoteItem(b)) return false
      if (a.duration !== b.duration) return false
      if (a.pitches.length !== b.pitches.length) return false
      for (let i = 0; i < a.pitches.length; i++) {
        if (a.pitches[i].pitch !== b.pitches[i].pitch) return false
      }
    } else if (isRestItem(a)) {
      if (!isRestItem(b)) return false
      if (a.rest.type !== b.rest.type) return false
      if (a.duration !== b.duration) return false
    } else {
      return false
    }
  }
  return true
}

// This is a modification of the naive algorithm mentioned here: https://www.geeksforgeeks.org/edit-distance-dp-5/
function annotateRangesWithNaiveEditDistance(
  answer: TuneNoteBase[],
  submission: TuneNoteBase[],
  m: number,
  n: number,
): AnnotateRangesResult {
  // If the first array is empty, this means the submission has a bunch of extra items and we should
  // mark all of those as wrong.
  if (m === 0) {
    return {
      annotations: [],
      submissionAnnotations: submission.slice(0, n).map(a => ({ ...a, correct: false })),
      insertions: n,
      distance: n,
    }
  }

  // If the second array is empty, this means the submission is missing the rest of the items, and
  // we should mark all of those as wrong.
  if (n === 0) {
    return {
      annotations: answer.slice(0, m).map(a => ({ ...a, correct: false })),
      submissionAnnotations: [],
      insertions: 0,
      distance: m,
    }
  }

  // If the last items of the two arrays are the same, nothing much to do. Ignore the last items
  // and get the count for the remaining items. Annotate the item as correct.
  if (notesAreSame(answer[m - 1], submission[n - 1])) {
    const result = annotateRangesWithNaiveEditDistance(answer, submission, m - 1, n - 1)
    return {
      ...result,
      annotations: [{ ...answer[m - 1], correct: true }, ...result.annotations],
      submissionAnnotations: [
        { ...submission[n - 1], correct: true },
        ...result.submissionAnnotations,
      ],
    }
  }

  // If the last items are not the same, consider all three operations on the last item of the
  // first string, recursively compute the minimum cost for all three operations and take the
  // minimum of the three values.
  const insertResult = annotateRangesWithNaiveEditDistance(answer, submission, m, n - 1)
  const removalResult = annotateRangesWithNaiveEditDistance(answer, submission, m - 1, n)
  const replaceResult = annotateRangesWithNaiveEditDistance(answer, submission, m - 1, n - 1)
  let result
  let markCurrentWrong = false
  let markCurrentSubmissionWrong = false
  let insertions = 0
  if (
    insertResult.distance <= removalResult.distance &&
    insertResult.distance <= replaceResult.distance
  ) {
    result = insertResult
    markCurrentSubmissionWrong = true
    insertions++
  } else if (
    removalResult.distance <= insertResult.distance &&
    removalResult.distance <= replaceResult.distance
  ) {
    result = removalResult
    markCurrentWrong = true
  } else {
    result = replaceResult
    markCurrentWrong = true
    markCurrentSubmissionWrong = true
  }
  return {
    ...result,
    annotations: markCurrentWrong
      ? [{ ...answer[m - 1], correct: false }, ...result.annotations]
      : result.annotations,
    submissionAnnotations: markCurrentSubmissionWrong
      ? [{ ...submission[n - 1], correct: false }, ...result.submissionAnnotations]
      : result.submissionAnnotations,
    insertions: insertions + result.insertions,
    distance: 1 + result.distance,
  }
}

let _preparedNodeEnvironment = false
function prepareNodeEnvironment() {
  if (!_preparedNodeEnvironment && typeof document === 'undefined') {
    const domino = require('domino')
    const window = domino.createWindow('<div />', 'http://example.com')
    global.Element = window.Element
    global.CharacterData = window.CharacterData
    global.DocumentType = window.DocumentType
    // noinspection JSConstantReassignment
    global.window = window
    // noinspection JSConstantReassignment
    global.document = window.document
    _preparedNodeEnvironment = true
  }
}

export function getAnnotatedAnswerRanges(answer: string, submission: string): AnnotateRangesResult {
  prepareNodeEnvironment()
  const contents = renderAbc('*', answer) as TuneItem[]
  let submissionContents: TuneItem[] | null
  try {
    submissionContents = renderAbc('*', submission)
  } catch (err) {
    debug('error loading submission contents: %s', submission)
    submissionContents = null
  }

  // Combine all of the voice items, and see if they are the same.
  const flatContents = flattenNoteItems(contents)
  const flatSubmissionContents = submissionContents ? flattenNoteItems(submissionContents) : null

  // Get the edit distance results.
  const result = annotateRangesWithNaiveEditDistance(
    flatContents,
    flatSubmissionContents || [],
    flatContents.length,
    flatSubmissionContents?.length || 0,
  )
  // Reverse the annotations because the edit distance algorithm above works backwards...
  result.annotations.reverse()
  result.submissionAnnotations.reverse()
  return result
}

export const SheetMusicDragDropGrader: AssignmentGrader = (
  submissionData: SheetMusicDragDropValue,
  question,
) => {
  const config = question.config as SheetMusicDragDropConfig | undefined
  if (config?.answer && submissionData) {
    const result = getAnnotatedAnswerRanges(
      config.answer,
      typeof submissionData === 'string' ? submissionData : submissionData.abc,
    )
    const totalItems = result.annotations.length
    const totalCorrect = result.annotations.filter(a => a.correct).length
    return totalItems > 0 ? totalCorrect / totalItems : 0
  } else {
    return 0
  }
}

export const SheetMusicDragDropCanGrade: AssignmentGraderCanGrade = (
  submissionData: SheetMusicDragDropValue | undefined,
  question,
) => {
  const config = question.config as SheetMusicDragDropConfig | undefined
  if (config?.answer) {
    try {
      prepareNodeEnvironment()
      const rendered = renderAbc('*', config.answer) as TuneItem[]
      return !!rendered?.[0]?.lines[0]?.staff[0]?.voices[0]?.filter(
        voice => voice.el_type === 'note',
      ).length
    } catch {
      debug('error with sheet music drag / drop answer, not automatically grading')
      return false
    }
  } else {
    return false
  }
}

export function addAssignmentGraders(feature: Feature) {
  feature.resources.addResource<AssignmentGraderResource>({
    type: 'AssignmentGrader',
    identifier: 'SheetMusicDragDrop',
    grader: SheetMusicDragDropGrader,
    canGradeQuestion: SheetMusicDragDropCanGrade,
  })
}
