import { debounce } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const hasWindow = typeof window !== 'undefined'
const RESIZE_DEBOUNCE = 250

const AppHeightGlobal = createGlobalStyle`
  :root {
    --app-height: 100vh;
  }
`

export enum ResponsiveType {
  LessThan,
  GreaterThan,
  EqualTo,
  NotEqualTo,
}

export interface ResponsiveContextValue {
  width: number
  height: number
}
export const ResponsiveContext = React.createContext<ResponsiveContextValue>({
  width: hasWindow ? window.innerWidth : 0,
  height: hasWindow ? window.innerHeight : 0,
})
export const ResponsiveContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [width, setWidth] = useState(hasWindow ? window.innerWidth : 0)
  const [height, setHeight] = useState(hasWindow ? window.innerHeight : 0)

  useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    const debounced = debounce(() => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    }, RESIZE_DEBOUNCE)
    window.addEventListener('resize', debounced)
    return () => window.removeEventListener('resize', debounced)
  }, [])

  return (
    <>
      <AppHeightGlobal />
      <ResponsiveContext.Provider children={children} value={{ width, height }} />
    </>
  )
}

const calculateResponsiveResult = (
  type: ResponsiveType,
  size: keyof typeof BREAKPOINTS,
  width: number,
): boolean => {
  switch (type) {
    case ResponsiveType.EqualTo:
      return (
        width >= BREAKPOINTS[size] &&
        width <= (Object.values(BREAKPOINTS).find(val => val > BREAKPOINTS[size]) || Infinity)
      )
    case ResponsiveType.GreaterThan:
      return width >= BREAKPOINTS[size]
    case ResponsiveType.LessThan:
      return width < BREAKPOINTS[size]
    case ResponsiveType.NotEqualTo:
      return !(
        width >= BREAKPOINTS[size] &&
        width <= (Object.values(BREAKPOINTS).find(val => val > BREAKPOINTS[size]) || Infinity)
      )
  }
}

export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
}
export const useResponsive = (type: ResponsiveType, size: keyof typeof BREAKPOINTS) => {
  const [result, setResult] = useState<boolean>()
  const { width } = useContext(ResponsiveContext)
  useEffect(() => {
    const newResult = calculateResponsiveResult(type, size, width)
    if (newResult !== result) {
      setResult(newResult)
    }
  }, [width, setResult, result])

  return result
}

export const customMediaQuery = (minMax: string, width: number) =>
  `@media (${minMax}-width: ${width}px)`
export type Media = {
  [key in keyof typeof BREAKPOINTS]: string
}
const breakpointKeys = Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[]
export const media = breakpointKeys.reduce<Partial<Media>>((acc, label) => {
  return { ...acc, [label]: customMediaQuery('min', BREAKPOINTS[label]) }
}, {}) as Media
export const maxMedia = breakpointKeys.reduce<Partial<Media>>((acc, label) => {
  return { ...acc, [label]: customMediaQuery('max', BREAKPOINTS[label]) }
}, {}) as Media

export interface OnProps {
  size: keyof typeof BREAKPOINTS
}
export const ShowOn = styled.div<OnProps>`
  display: none;
  ${props => media[props.size]} {
    display: block;
  }
`
export const HideOn = styled.div<OnProps>`
  display: block;
  ${props => media[props.size]} {
    display: none;
  }
`
