import type { List as NativeList } from '@thesisedu/native'
import type { ListItemProps } from '@thesisedu/native/dist/list/ListItem'
import { styled } from '@thesisedu/web'
import React from 'react'

export type ListProps = NativeList.ListProps
/** @deprecated */
export function List({ children, separated, large }: ListProps) {
  if (separated) {
    const childrenArr = React.Children.toArray(children)
    return (
      <>
        {childrenArr.reduce<React.ReactNode[]>((acc, child, index) => {
          if (index === childrenArr.length - 1) {
            return [...acc, child]
          } else {
            return [...acc, child, <ListSeparator large={large} key={`sep-${index}`} />]
          }
        }, [])}
      </>
    )
  } else return <>{children}</>
}

export type ListSeparatorProps = React.PropsWithChildren<object>
export const ListSeparator: React.FC<React.PropsWithChildren<any>> = styled.div<{
  large?: boolean
}>`
  height: 1px;
  background: ${props => props.theme['@border-color-split']};
  width: calc(100% - ${props => props.theme[props.large ? '@size-l' : '@size-s']});
  margin-left: ${props => props.theme[props.large ? '@size-l' : '@size-s']};
`

export function ListItem({
  children,
  rightContent,
  large,
}: React.PropsWithChildren<ListItemProps>) {
  return (
    <ListItemContainer large={large}>
      {children}
      {rightContent ? <ListItemRight children={rightContent} /> : null}
    </ListItemContainer>
  )
}
const ListItemContainer = styled.div<{ large?: boolean }>`
  padding: ${props => props.theme[props.large ? '@size-s' : '@size-xs']};
  padding-left: ${props => props.theme[props.large ? '@size-l' : '@size-s']};
  display: flex;
  align-items: center;
`
export const ListItemRight: React.FC<React.PropsWithChildren<any>> = styled.div`
  margin-left: auto;
`
export const ListItemLeft: React.FC<React.PropsWithChildren<any>> = styled.div`
  margin-right: auto;
  padding-right: ${props => props.theme['@size-s']};
`

export function BackgroundHoverListItem({
  children,
  rightContent,
}: React.PropsWithChildren<ListItemProps>) {
  return (
    <BackgroundHoverListItemContainer>
      {children}
      {rightContent ? <ListItemRight children={rightContent} /> : null}
    </BackgroundHoverListItemContainer>
  )
}
const BackgroundHoverListItemContainer = styled.div`
  background: transparent;
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xs']};
  display: flex;
  align-items: center;
  transition:
    background 0.1s linear,
    color 0.1s linear;
  color: ${props => props.theme['@text-color']};
  &:hover {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
`

export { ListItemProps } from '@thesisedu/native/dist/list/ListItem'
