import * as Sentry from '@sentry/react'
import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { SentryErrorService, SentryOptions } from '@thesisedu/sentry-error-service'

import { debug } from './log'
import { MutateSentryReplaySampleRate } from './types'

type SentryReleaseFn = () => Promise<string>
export interface SentryWebOptions extends SentryOptions {
  release?: string | SentryReleaseFn
  project: string
  organization: string
}

const REPLAY_SESSION_SAMPLE_RATE = 0.0001
const REPLAY_ERROR_SAMPLE_RATE = 0.01 // This should end up with about 50% of the time for teachers.
const IGNORE_DEFAULT_LOGLEVELS = ['log', 'info', 'debug']

export class SentryWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-sentry-web'
  public static path = __dirname
  public static requires: string[] = []
  public readonly options!: SentryWebOptions
  public readonly sentry = Sentry
  public replay: Sentry.Replay | null = null

  constructor(options: SentryWebOptions, deps: FeatureDependencies) {
    super(options, deps)
    const environment = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV
    const release = process.env.REACT_APP_RELEASE
    debug('setting up sentry with environment %s and release %s', environment, release)
    const root = this.root as FeatureWeb
    const enabled =
      (process.env.NODE_ENV === 'production' || !!process.env.REACT_APP_ENABLE_SENTRY) &&
      root.isValidBrowserVersion
    const apiDomain = this.appOptions.apiDomain
    if (enabled) {
      debug('sentry is enabled')
    } else {
      debug('sentry is not enabled')
    }
    const service = new SentryErrorService(
      this.root,
      {
        environment,
        release,
        ...this.options,
        dsn: root.isValidBrowserVersion ? this.options.dsn : undefined,
        additionalOptions: {
          ...this.options.additionalOptions,
          beforeBreadcrumb(breadcrumb, hint) {
            // Debug messages get included twice, if they are logging to
            // the console. We only want them to be included once, so we ignore
            // all built-in debug console messages.
            if (
              breadcrumb.category === 'console' &&
              breadcrumb.data?.logger === 'console' &&
              breadcrumb.level &&
              IGNORE_DEFAULT_LOGLEVELS.includes(breadcrumb.level)
            ) {
              return null
            } else {
              return breadcrumb
            }
          },
          integrations(defaultIntegrations) {
            return [
              ...defaultIntegrations,
              new Sentry.BrowserProfilingIntegration(),
              new Sentry.BrowserTracing(),
            ]
          },
          tracePropagationTargets: ['http://localhost', apiDomain],
          profilesSampleRate: 1,
          tracesSampleRate: 0.00000001, // This will be modified elsewhere, but we'll disable until then.
          ignoreErrors: [
            ...(this.options.additionalOptions?.ignoreErrors || []),
            /ExpectedError/,
            /ResizeObserver/,
            /Illegal invocation/,
            /Viewer context is required/, // We may want to un-ignore this one later.
            /cannot serialize cyclic structures/,
            /circular structure to JSON/,
            /ckeditor/,
            /Document is not focused/,
            /postMessage/,
            /Permissions check failed/,
            /The operation was aborted/,
            /play\(\) request was interrupted by a new load request/,
            /useSelectedClass/, // We may want to un-ignore this one later.
            'Object captured as exception with keys: message',
            'Load failed',
            'NotAllowedError: Read permission denied',
            'NotAllowedError: Permission denied',
            'Fullscreen request denied',
            'AbortError: The play() request was interrupted by a call to pause()',
            "Failed to execute 'insertBefore' on 'Node': The node before which",
            "null is not an object (evaluating 'window.localStorage.getItem')",
            "null is not an object (evaluating 'localStorage.getItem')",
            "Cannot read properties of null (reading 'removeChild')",
            "Unexpected token '<'",
          ],
          enabled,
        },
      },
      this.root.appOptions,
      Sentry,
    )
    this.services.setErrorService(service)

    require('./hooks/wrap').default(this)
    require('./hooks/preBuild').default(this)
    require('./hooks/postBuild').default(this)
    require('./hooks/errorPage').default(this)
  }

  public async prepareLate() {
    const client = Sentry.getCurrentHub().getClient()
    if (client?.addIntegration) {
      // Setup the replay integration after everything else has been added so we can
      // properly calculate the sample rate based on the current user. This means replay
      // sessions will only work if a teacher already exists.
      const sampleRate = await this.hookManager.mutateHook<MutateSentryReplaySampleRate>(
        'feature-sentry-web:replay-sample-rate',
        {
          session: REPLAY_SESSION_SAMPLE_RATE,
          error: REPLAY_ERROR_SAMPLE_RATE,
        },
        undefined,
      )

      debug(
        'replay sample rates are %O (session); %O (error)',
        sampleRate.session,
        sampleRate.error,
      )
      debug('initializing replay')

      const options = client.getOptions() as Record<string, any>
      options.replaysSessionSampleRate = sampleRate.session
      options.replaysOnErrorSampleRate = sampleRate.error

      this.replay = new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        unblock: ['.sentry-unblock', '[data-sentry-unblock]', 'svg'],
        networkDetailAllowUrls: ['localhost', this.appOptions.apiDomain],
      })
      client.addIntegration(this.replay)
    }
  }
}

export const sentryWebFeature = (options: SentryWebOptions): FeatureUse<SentryWebOptions> => [
  SentryWebFeature,
  options,
]
