import React from 'react'
import { TableBody as RATableBody, TableBodyProps as RATableBodyProps } from 'react-aria-components'

import { useTableContext } from './Table'
import { Text, VSpaced, styled } from '../..'
import { MinusCircle } from '../../../icons'
import LoadingIndicator from '../LoadingIndicator'

export interface TableBodyProps<T> extends RATableBodyProps<T> {
  emptyMessage?: string | null
  loadingMessage?: string
}
export function TableBody<T extends object>({
  emptyMessage = 'No results found.',
  loadingMessage = 'Loading...',
  ...props
}: TableBodyProps<T>) {
  const { loading } = useTableContext()
  let renderEmptyState
  if (loading) {
    renderEmptyState = () => <LoadingIndicator.Labeled label={loadingMessage} />
  } else if (props.renderEmptyState) {
    renderEmptyState = props.renderEmptyState
  } else if (emptyMessage === null) {
    renderEmptyState = undefined
  } else {
    renderEmptyState = () => {
      return (
        <VSpaced
          align={'center'}
          space={'xs'}
          paddingVertical={'s'}
          style={{ textAlign: 'center' }}
        >
          <Text level={'xl'} color={'secondary'}>
            <MinusCircle />
          </Text>
          <Text color={'secondary'}>{emptyMessage}</Text>
        </VSpaced>
      )
    }
  }
  return (
    <_TableBody<T>
      renderEmptyState={renderEmptyState}
      {...props}
      items={loading ? [] : props.items}
    />
  )
}

const _TableBody: typeof RATableBody = styled(RATableBody)``
