import { InteractionProps } from '@thesisedu/feature-interactions-react'
import { InteractionListItem } from '@thesisedu/feature-interactions-react/web'
import React from 'react'

export function SubmitAssignment({ interaction }: InteractionProps) {
  const { assignmentId, name, classId } = interaction.metadata
  return (
    <InteractionListItem
      fragment={interaction}
      linkProps={{ to: `/teacher/classes/${classId}/grades?grading=${assignmentId}` }}
    >
      {interaction.user.name || interaction.user.username} submitted {name}
    </InteractionListItem>
  )
}
