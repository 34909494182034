import { useMountInteraction } from '@thesisedu/feature-interactions-react'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { CommentFragment } from './schema'
import { BubbleComment } from './styles/BubbleComment'
import { LongComment } from './styles/LongComment'
import { CommentProps } from './styles/types'

interface RefMap {
  [key: string]: React.RefObject<HTMLDivElement>
}

export interface CommentListProps extends Omit<CommentProps, 'comment'> {
  comments: CommentFragment[]
  commentableId: string
  selectedCommentId?: string
  style?: 'bubbles' | 'longform'
  highlightUserIds?: string[]
}
export const CommentList: React.FC<React.PropsWithChildren<CommentListProps>> = ({
  comments,
  commentableId,
  selectedCommentId,
  highlightUserIds,
  style = 'bubbles',
  ...commentProps
}) => {
  const container = React.useRef<HTMLDivElement>(null)
  // TODO: Maybe support automatically updating the "unread" status whenever this is finished.
  useMountInteraction({
    type: 'COMMENTS_VIEWED',
    reference: commentableId,
  })
  const itemRefs = comments.reduce<RefMap>((acc, value) => {
    acc[value.id] = React.createRef<HTMLDivElement>()
    return acc
  }, {})
  React.useEffect(() => {
    if (selectedCommentId && itemRefs[selectedCommentId] && itemRefs[selectedCommentId]!.current) {
      itemRefs[selectedCommentId].current!.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [selectedCommentId])
  React.useEffect(() => {
    if (container.current) {
      container.current.scrollTop = container.current.scrollHeight
    }
  }, [comments.length, container])
  return (
    <VSpaced
      ref={container}
      className={'comment-list-container'}
      space={style === 'bubbles' ? false : 'm'}
    >
      {comments.map(comment => {
        let content
        switch (style) {
          case 'bubbles':
            content = (
              <BubbleComment
                comment={comment}
                key={comment.id}
                {...commentProps}
                isHighlighted={highlightUserIds?.includes(comment.user.id)}
                isSelected={selectedCommentId === comment.id}
              />
            )
            break
          case 'longform':
            content = (
              <LongComment
                comment={comment}
                key={comment.id}
                {...commentProps}
                isHighlighted={highlightUserIds?.includes(comment.user.id)}
                isSelected={selectedCommentId === comment.id}
              />
            )
            break
        }
        return (
          <div ref={itemRefs[comment.id]} key={comment.id}>
            {content}
          </div>
        )
      })}
    </VSpaced>
  )
}
