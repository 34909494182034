import {
  CollisionDetection,
  DndContextProps,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import React from 'react'
import { createPortal } from 'react-dom'

import { coordinateGetter } from './multipleContainersKeyboardCoordinates'

export interface UseMultiSortableOpts {
  items: Record<string, string[]>
  setItems: (items: Record<string, string[]>) => void
  renderDragOverlay: (itemId: UniqueIdentifier) => React.ReactElement
}
export interface UseMultiSortable {
  contextProps: DndContextProps
  overlay: React.ReactElement
}
export function useMultiSortable({
  items,
  setItems,
  renderDragOverlay,
}: UseMultiSortableOpts): UseMultiSortable {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null)
  const [clonedItems, setClonedItems] = React.useState<Record<string, string[]> | null>(null)
  const lastOverId = React.useRef<UniqueIdentifier | null>(null)
  const recentlyMovedToNewContainer = React.useRef(false)
  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id
    }
    return Object.keys(items).find(key => items[key].includes(id.toString()))
  }
  const collisionDetectionStrategy: CollisionDetection = React.useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items),
        })
      }

      // Start by finding any intersecting droppable.
      const pointerIntersections = pointerWithin(args)
      const intersections =
        pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args)
      let overId = getFirstCollision(intersections, 'id')

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId]

          // If a container is matched and it contains items...
          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container =>
                  container.id !== overId && containerItems.includes(container.id.toString()),
              ),
            })[0]?.id
          }
        }

        lastOverId.current = overId
        return [{ id: overId }]
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions.
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId
      }

      // If no droppable is matched, return the last match.
      return lastOverId.current ? [{ id: lastOverId.current }] : []
    },
    [activeId, items],
  )

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  )

  return {
    contextProps: {
      sensors,
      collisionDetection: collisionDetectionStrategy,
      measuring: {
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      },
      onDragStart({ active }) {
        setActiveId(active.id)
        setClonedItems(items)
      },
      onDragOver({ active, over }) {
        const overId = over?.id
        if (overId == null || active.id in items) {
          return
        }

        const overContainer = findContainer(overId)
        const activeContainer = findContainer(active.id)

        if (!overContainer || !activeContainer) {
          return
        }

        if (activeContainer !== overContainer) {
          const activeItems = items[activeContainer]
          const overItems = items[overContainer]
          const overIndex = overItems.indexOf(overId.toString())
          const activeIndex = activeItems.indexOf(active.id.toString())

          let newIndex: number
          if (overId in items) {
            newIndex = overItems.length + 1
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height
            const modifier = isBelowOverItem ? 1 : 0
            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1
          }

          recentlyMovedToNewContainer.current = true

          setItems({
            ...items,
            [activeContainer]: items[activeContainer].filter(id => id !== active.id),
            [overContainer]: [
              ...overItems.slice(0, newIndex),
              items[activeContainer][activeIndex],
              ...overItems.slice(newIndex),
            ],
          })
        }
      },
      onDragCancel() {
        if (clonedItems) {
          setItems(clonedItems)
        }

        setActiveId(null)
        setClonedItems(null)
      },
      onDragEnd({ active, over }) {
        const activeContainer = findContainer(active.id)
        if (!activeContainer) {
          setActiveId(null)
          return
        }
        const overId = over?.id
        if (overId == null) {
          setActiveId(null)
          return
        }

        const overContainer = findContainer(overId)
        if (overContainer) {
          const activeIndex = items[overContainer].indexOf(active.id.toString())
          const overIndex = items[overContainer].indexOf(overId.toString())
          if (activeIndex !== overIndex) {
            setItems({
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
            })
          }
        }

        setActiveId(null)
      },
    },
    overlay: createPortal(
      <DragOverlay zIndex={1000}>{activeId ? renderDragOverlay(activeId) : null}</DragOverlay>,
      document.body,
    ),
  }
}
