import { useFeatureRoot } from '@thesisedu/feature-react'
import React from 'react'

import { CommentComposerResource, CreateCommentCallback } from './types'

/** An option to display to users for composing a new comment. */
export interface ComposeOption {
  key: string
  icon: React.ReactElement
  name: string
}

/** The return value of the {@link useCommentComposers} hook. */
export interface UseCommentComposers {
  elements: React.ReactElement[]
  options: ComposeOption[]
  onOptionSelected: (identifier: string) => void
}

/** The options required for the {@link useCommentComposers} hook */
export interface CommentComposersOptions {
  /**
   * Called whenever a comment is to be posted.
   * @param payload - the payload to send to the mutation
   */
  onCreate: CreateCommentCallback
}

/**
 * A hook containing the business logic for custom comment composer buttons.
 */
export function useCommentComposers({ onCreate }: CommentComposersOptions): UseCommentComposers {
  const resources =
    useFeatureRoot().deps.resources.getResourcesForType<CommentComposerResource>('CommentComposer')
  const [visibleResource, setVisibleResource] = React.useState<string | undefined>(undefined)

  return {
    elements: resources.map(resource => (
      <resource.ComposeComponent
        key={resource.identifier}
        visible={visibleResource === resource.identifier}
        onClose={() => setVisibleResource(undefined)}
        onCreate={onCreate}
      />
    )),
    options: resources.map<ComposeOption>(resource => ({
      key: resource.identifier,
      name: resource.name,
      icon: resource.icon,
    })),
    onOptionSelected: setVisibleResource,
  }
}
