import { createGlobalStyle } from 'styled-components'

export const AntOverrides = createGlobalStyle`
  * {
    font-variant: normal !important;
    font-feature-settings: normal !important;
  }

  body {
    // We have to override Radix taking over the body pointer-events because
    // Ant Design is a royal pile of garbage that doesn't respect the overlay
    // context, so it keeps adding items to the body instead of the proper overlay.
    pointer-events: all !important;
  }

  .ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-dropdown-menu-title-content > svg {
    margin-right: 8px;
  }

  // Increase the size of icons in icon-only circular buttons.
  .ant-btn-circle.ant-btn-icon-only {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      font-size: 24px;
    }
    svg {
      display: block;
      stroke-width: 1.25;
    }
  }

  // Border radius for modals.
  .ant-modal-content {
    border-radius: ${props => (props.theme as any)['@border-radius-large']} !important;
    .ant-modal-header {
      border-radius: ${props => (props.theme as any)['@border-radius-large']} ${props =>
        (props.theme as any)['@border-radius-large']} 0 0 !important;
    }
  }

  .ant-btn-dangerous {
    color: ${props => (props.theme as any)['@red']} !important;
    &.ant-btn-primary {
      color: white !important;
      background: ${props => (props.theme as any)['@red']} !important;
      border-color: ${props => (props.theme as any)['@red']} !important;
    }
  }

  .ant-tabs {
    overflow: visible !important;
  }

  .ant-drawer-body {
    padding: 0;
    > * {
      padding: ${props => (props.theme as any)['@size-m']};
    }
  }
`
