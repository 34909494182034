import React from 'react'

export interface GraphicDragDropContextValue {
  width?: number
  height?: number
}
export const GraphicDragDropContext = React.createContext<GraphicDragDropContextValue | undefined>(
  undefined,
)

export function useGraphicDragDropContext(): GraphicDragDropContextValue | undefined
export function useGraphicDragDropContext(require: false): GraphicDragDropContextValue | undefined
export function useGraphicDragDropContext(require: true): GraphicDragDropContextValue
export function useGraphicDragDropContext(
  require?: boolean,
): GraphicDragDropContextValue | undefined {
  const context = React.useContext(GraphicDragDropContext)
  if (!context && require) {
    throw new Error('GraphicDragDropContext is required, yet not provided.')
  }
  return context
}
