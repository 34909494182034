import { styled } from '@thesisedu/web'
import classnames from 'classnames'
import { transparentize } from 'polished'
import React, { useMemo, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import {
  HoverProps,
  SegmentDraggableContent,
  SegmentDraggableProps,
} from './SegmentDraggableContent'
import { Dragger } from '../Dragger'
import { useSegmentOverrideContext } from '../contexts/SegmentOverrideContext'

const Container = styled.div`
  display: flex;
  background: transparent;
  align-items: flex-start;
  transition:
    background 0.1s linear,
    opacity 0.1s linear;
  border-radius: ${props => props.theme['@border-radius-base']};
  > .dragger {
    margin: 7px 5px 0 0;
    &.dragger-disabled {
      visibility: hidden;
      pointer-events: none;
    }
  }
  > .children {
    padding: 5px 10px 5px 10px;
  }
  > .right {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  &.handle-hover {
    background: ${props => props.theme['@gray-1']};
  }
  &.highlighted {
    background: ${props => transparentize(0.9, props.theme['@blue'])};
  }
  &.disabled {
    opacity: 0.5;
  }
`

export function SegmentDraggable({
  children,
  ...props
}: React.PropsWithChildren<SegmentDraggableProps>) {
  const [handleHover, setHandleHover] = useState(false)
  const isOverrideMode = !!useSegmentOverrideContext()
  const hoverProps = useMemo<HoverProps>(() => {
    return {
      onMouseEnter: () => setHandleHover(true),
      onMouseLeave: () => setHandleHover(false),
    }
  }, [])

  return (
    <Draggable
      draggableId={props.segment.id}
      // @ts-ignore type doesn't seem to be typed.
      type={props.id}
      index={props.index !== undefined ? props.index : props.segment.weight || 0}
    >
      {provided => (
        <Container
          ref={provided.innerRef}
          className={classnames({
            'segment-draggable': true,
            'handle-hover': handleHover,
            disabled:
              isOverrideMode && props.segment.visibleOverride !== true && !props.parentDisabled,
            highlighted: props.highlighted,
          })}
          {...provided.draggableProps}
        >
          <Dragger {...provided.dragHandleProps} className={'dragger'} {...hoverProps} />
          <div className={'right'}>
            <SegmentDraggableContent {...props} hoverProps={hoverProps} />
            {children ? <div className={'children'}>{children}</div> : null}
          </div>
        </Container>
      )}
    </Draggable>
  )
}
SegmentDraggable.displayName = 'SegmentDraggable'
