import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

type CancelCallback = () => void
export interface GradesTableContextValue {
  editingId?: string
  setEditingId: (editingId?: string, onCancel?: CancelCallback) => void
  cancelEditing: () => void
}
export const GradesTableContext = React.createContext<GradesTableContextValue | undefined>(
  undefined,
)

export interface GradesTableContextProviderProps {
  containerRef?: React.RefObject<HTMLDivElement>
}
export function GradesTableContextProvider({
  children,
  containerRef,
}: React.PropsWithChildren<GradesTableContextProviderProps>) {
  const cancelRef = React.useRef<CancelCallback>()
  const [editingId, __setEditingId] = React.useState<string | undefined>()
  const _setEditingId = (editingId: string | undefined) => {
    editingIdRef.current = editingId
    __setEditingId(editingId)
  }
  const editingIdRef = React.useRef<string | undefined>()
  const setEditingId = (editingId?: string, onCancel?: CancelCallback) => {
    if (cancelRef.current) cancelRef.current()
    cancelRef.current = onCancel
    _setEditingId(editingId)
  }
  const cancelEditing = React.useCallback(() => {
    _setEditingId(undefined)
    cancelRef.current = undefined
  }, [_setEditingId])
  useHotkeys('escape', cancelEditing)
  React.useEffect(() => {
    if (containerRef) {
      const handler = (event: DocumentEventMap['mousedown']) => {
        const target = event.target as HTMLElement
        const parentCellId = target.closest('[data-id]')?.getAttribute('data-id')
        const isOutsideContainer =
          !!containerRef.current && !containerRef.current.contains(event.target as Node)
        if (isOutsideContainer || (editingIdRef.current && parentCellId !== editingIdRef.current)) {
          setEditingId(undefined)
        }
      }
      document.addEventListener('mousedown', handler)
      return () => document.removeEventListener('mousedown', handler)
    }
  }, [containerRef])
  return (
    <GradesTableContext.Provider
      value={{ cancelEditing, editingId, setEditingId }}
      children={children}
    />
  )
}

export function useGradesTableContext(): GradesTableContextValue | undefined
export function useGradesTableContext(require: false): GradesTableContextValue | undefined
export function useGradesTableContext(require: true): GradesTableContextValue
export function useGradesTableContext(require?: boolean): GradesTableContextValue | undefined {
  const context = React.useContext(GradesTableContext)
  if (!context && require) {
    throw new Error('GradesTableContext is required, yet not provided.')
  }
  return context
}
