import { FeatureResource } from '@thesisedu/feature'
import { TuneItem } from '@thesisedu/feature-sheet-music-core'
import React from 'react'

export const DEFAULT_BPM = 90

export interface WrapNoteProps {
  pitch: number | undefined
  accidental?: 'flat' | 'sharp' | 'natural'
  children: React.ReactElement
}

export interface KeyboardProps {
  simple?: boolean
  wrapNote?: (props: WrapNoteProps) => React.ReactElement | null
  onInstrumentChange?: (selectedInstrumentKey: string) => void
  onSimpleChange?: (simple: boolean) => void
  selectedInstrument?: string
}

export type KeyboardComponent = (props: KeyboardProps) => React.ReactElement | null

export interface InstrumentResource extends FeatureResource {
  type: 'Instrument'
  name: string
  soundfontUrl: string
  instrument: string
  filter?: (abc: TuneItem[]) => boolean
  simpleOnly?: boolean
  hidden?: boolean
}

export interface SheetMusicState {
  selectedInstrument?: string
  advancedMode?: boolean
  bpm?: number
}
