// From: https://github.com/FedericoDiRosa/react-window-scroller/blob/master/src/index.jsx

import { throttle } from 'lodash'
import React, { useRef, useEffect, useCallback } from 'react'
import { GridOnScrollProps, GridProps, ListOnScrollProps, ListProps } from 'react-window'

import { useScrollableContainerContext } from '../../dom/ScrollableContainerContext'
import { isHtmlElement } from '../../dom/isHtmlElement'

interface PositionKey {
  x: string
  y: string
}
const windowScrollPositionKey: PositionKey = {
  y: 'pageYOffset',
  x: 'pageXOffset',
}

const documentScrollPositionKey: PositionKey = {
  y: 'scrollTop',
  x: 'scrollLeft',
}

function getFirstNonNil(items: (number | undefined | null)[]): number {
  for (const item of items) {
    if (item !== undefined && item !== null) return item
  }
  return 0
}
const getScrollPosition = (axis: keyof PositionKey, element?: HTMLElement | null) =>
  getFirstNonNil([
    // @ts-ignore indexing as string
    element?.[documentScrollPositionKey[axis] as any],
    // @ts-ignore indexing as string
    window[windowScrollPositionKey[axis] as any],
    // @ts-ignore indexing as string
    document.documentElement[documentScrollPositionKey[axis] as any],
    // @ts-ignore indexing as string
    document.body[documentScrollPositionKey[axis] as any],
  ])

interface ChildOpts<Props extends ListProps | GridProps> {
  ref: React.MutableRefObject<any>
  outerRef: React.MutableRefObject<any>
  style: object
  onScroll: Props['onScroll']
}
interface ReactWindowScrollerProps<Props extends ListProps | GridProps> {
  children: (opts: ChildOpts<Props>) => React.ReactElement
  throttleTime?: number
  isGrid?: boolean
}
export function ReactWindowScroller<Props extends ListProps | GridProps = ListProps>({
  children,
  throttleTime = 10,
  isGrid = false,
}: ReactWindowScrollerProps<Props>) {
  const ref = useRef<any>()
  const outerRef = useRef<HTMLElement>()
  const { target } = useScrollableContainerContext(false) || {}
  const targetElement = target?.current || window
  const isScrollingRef = React.useRef(false)

  useEffect(() => {
    let scrollTimeout: any
    const handleWindowScroll = throttle(() => {
      const rect = outerRef.current?.parentElement?.getBoundingClientRect()
      const offsetTop =
        (rect?.top || 0) +
        (isHtmlElement(targetElement) ? targetElement.scrollTop : targetElement.scrollY)
      const offsetLeft =
        (rect?.left || 0) +
        (isHtmlElement(targetElement) ? targetElement.scrollLeft : targetElement.scrollX)
      const scrollTop = getScrollPosition('y', target?.current) - offsetTop
      const scrollLeft = getScrollPosition('x', target?.current) - offsetLeft
      isScrollingRef.current = true
      if (isGrid) ref.current && ref.current!.scrollTo({ scrollLeft, scrollTop })
      if (!isGrid) ref.current && ref.current!.scrollTo(scrollTop)
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        isScrollingRef.current = false
      }, 100)
    }, throttleTime)

    targetElement.addEventListener('scroll', handleWindowScroll)
    return () => {
      handleWindowScroll.cancel()
      targetElement.removeEventListener('scroll', handleWindowScroll)
    }
  }, [isGrid, targetElement])

  const onScroll = useCallback(
    ({
      scrollLeft,
      scrollTop,
      scrollOffset,
      scrollUpdateWasRequested,
    }: GridOnScrollProps & ListOnScrollProps) => {
      if (!scrollUpdateWasRequested || isScrollingRef.current) return
      const top = getScrollPosition('y', target?.current)
      const left = getScrollPosition('x', target?.current)
      const rect = outerRef.current?.parentElement?.getBoundingClientRect()
      const offsetTop =
        (rect?.top || 0) +
        (isHtmlElement(targetElement) ? targetElement.scrollTop : targetElement.scrollY)
      const offsetLeft =
        (rect?.left || 0) +
        (isHtmlElement(targetElement) ? targetElement.scrollLeft : targetElement.scrollX)

      if (scrollOffset !== 0) scrollOffset += offsetTop
      if (scrollTop !== 0) scrollTop += offsetTop
      if (scrollLeft !== 0) scrollLeft += offsetLeft

      if (!isGrid && scrollOffset !== top) targetElement.scrollTo(0, scrollOffset)
      if (isGrid && (scrollTop !== top || scrollLeft !== left)) {
        targetElement.scrollTo(scrollLeft, scrollTop)
      }
    },
    [isGrid, targetElement],
  )

  React.useEffect(() => {
    // We have to get rid of the scroll handlers here, because they will cause the list
    // to go blank whenever adjusting the number of items.
    ref.current._onScrollVertical = () => {}
    ref.current._onScrollHorizontal = () => {}
  }, [outerRef])

  return children({
    ref,
    outerRef,
    style: {
      width: isGrid ? 'auto' : '100%',
      height: '100%',
      display: 'inline-block',
      overflow: 'hidden',
      zIndex: 3,
    },
    onScroll: onScroll as any,
  })
}
