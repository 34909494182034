import { AssignmentGraderResource } from '@thesisedu/feature-assignments-core'
import { GraphicDragDropGrader } from '@thesisedu/feature-graphic-drag-drop-core'
import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

import { addQuestionTypes } from './resources/questionTypes'

export interface GraphicDragDropReactFeatureOptions {}

export class GraphicDragDropReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-graphic-drag-drop-react'
  static path = ''
  static requires: string[] = []
  public options!: GraphicDragDropReactFeatureOptions

  public reactResources() {
    addQuestionTypes(this)
    this.resources.addResource<AssignmentGraderResource>({
      type: 'AssignmentGrader',
      identifier: 'GraphicDragDrop',
      grader: GraphicDragDropGrader,
    })
  }
}

export const graphicDragDropReactFeature: ReactUse = (opts: GraphicDragDropReactFeatureOptions) => [
  GraphicDragDropReactFeature,
  opts,
]
