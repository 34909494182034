import { EyeOutlined } from '@ant-design/icons'
import { Feature } from '@thesisedu/feature'
import { GradingModalNavigationItemsHook } from '@thesisedu/feature-assignments-react'
import React from 'react'

import { isSegmentConfiguration } from '../helpers'
import { GradeModalAssignmentNavigationItem } from '../segments/assignment/GradeModalAssignmentNavigationItem'

export default function addNavItems(feature: Feature) {
  feature.hookManager.registerMutateHook<GradingModalNavigationItemsHook>(
    'feature-assignments-react:grading-modal-navigation-items',
    (items, { assignment }) => {
      const config = assignment.configuration
      if (config && isSegmentConfiguration(config)) {
        return [
          ...items,
          {
            key: 'view-content',
            icon: <EyeOutlined />,
            label: 'View Content',
            render: () => null,
            renderNavigationItem: props => (
              <GradeModalAssignmentNavigationItem
                {...props}
                key={'view-content'}
                assignment={assignment}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
