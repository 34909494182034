import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { Block, BlockSpin, styled } from '@thesisedu/web'
import { Empty, Radio } from 'antd'
import React, { useState } from 'react'

import { TrialRequestsList, TrialRequestsListProps } from './TrialRequestsList'
import {
  TrialRequestFragment,
  TrialRequestsDocument,
  TrialRequestsQuery,
  TrialRequestsQueryVariables,
  TrialRequestStatus,
} from './schema'

export function AllTrialRequests(props: Omit<TrialRequestsListProps, 'requests'>) {
  const [status, setStatus] = useState(TrialRequestStatus.Open)
  return (
    <Container>
      <Block marginBottom={'@size-m'}>
        <Radio.Group value={status} onChange={e => setStatus(e.target.value)} buttonStyle={'solid'}>
          <Radio.Button value={TrialRequestStatus.Open}>Active</Radio.Button>
          <Radio.Button value={TrialRequestStatus.Ignored}>Ignored</Radio.Button>
        </Radio.Group>
      </Block>
      <InfiniteQuery<TrialRequestFragment, TrialRequestsQuery, TrialRequestsQueryVariables>
        document={TrialRequestsDocument}
        resultPath={'trialRequests'}
        variables={{ status, first: 32 }}
        children={({ data, loading }) => {
          const requests =
            data?.trialRequests.edges
              .map(edge => edge.node)
              .filter(node => node.status === status) || []
          if (requests.length) {
            return <TrialRequestsList requests={requests} {...props} />
          } else if (loading) {
            return <BlockSpin />
          } else {
            return <Empty description={'No trial requests.'} />
          }
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`
