import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { isEqual } from '@thesisedu/feature-utils'
import { isEmpty } from '@thesisedu/feature-widgets-core'
import { SerializedEditorState } from 'lexical'
import React from 'react'

import { debug } from '../../../log'

export interface DefaultValuePluginProps {
  defaultValue?: SerializedEditorState | any
  defaultValueKey?: string
}
export function DefaultValuePlugin({ defaultValue, defaultValueKey }: DefaultValuePluginProps) {
  const [editor] = useLexicalComposerContext()

  /**
   * After many attempts to get the editor to stop updating the defaultValue, instead
   * we have to fall back to this, which will only set the default value if the editor
   * didn't already have a value. This means we shouldn't need to worry about using
   * useMemo() for the default value.
   */
  const didHaveContentRef = React.useRef(false)
  React.useEffect(() => {
    didHaveContentRef.current = false
  }, [defaultValueKey])

  React.useEffect(() => {
    if (defaultValue && !didHaveContentRef.current) {
      setTimeout(() => {
        const editorValue = editor.toJSON().editorState
        if (isEqual(defaultValue, editorValue)) return

        // This is here to help us diagnose if we are constantly updating the editor's state,
        // which will harm performance. If you see a lot of these logs, it means we are
        // updating the editor's state too much.
        debug('editor is updating defaultValue', defaultValue)

        // We do this in the next iteration of the event loop because we use flushSync to
        // render some nodes, and you can't call flushSync inside a React lifecycle method
        // (like useEffect).
        // So we execute this code in the next iteration of the event loop so React will
        // be done rendering by the time we get here.

        // If the editor state is empty, we do nothing.
        if (isEmpty(defaultValue)) {
          debug('defaultValue is empty; setting root editor state and doing nothing')
          const rootElement = editor.getRootElement()
          if (rootElement) {
            rootElement.classList.add('empty')
          }
          return
        }

        try {
          editor.setEditorState(editor.parseEditorState(defaultValue))
          didHaveContentRef.current = true
        } catch (err: any) {
          if (
            err.message?.match(/the editor state is empty/i) ||
            err.message?.match(/minified lexical error #38/i)
          ) {
            didHaveContentRef.current = false

            debug('attempting to set empty editor state')
            const rootElement = editor.getRootElement()
            if (rootElement) {
              rootElement.classList.add('empty')
            }
          } else throw err
        }
      })
    } else if (!didHaveContentRef.current) {
      debug('no default value; attempting to set empty editor state')
      const rootElement = editor.getRootElement()
      if (rootElement) {
        rootElement.classList.add('empty')
      }
    }
  }, [defaultValue, defaultValueKey])

  return null
}
