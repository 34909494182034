import {
  FullAssignment,
  useGradingModalContext,
  useGradingPage,
} from '@thesisedu/feature-assignments-react'
import {
  CourseViewerModalCustomHeader,
  CourseViewerSegmentHeaderPortalContent,
} from '@thesisedu/feature-courses-react'
import { styled } from '@thesisedu/ui'
import React from 'react'

export interface AssignmentGradingModalContentProps {
  selectedTab: string
}
export function AssignmentGradingModalContent({ selectedTab }: AssignmentGradingModalContentProps) {
  const gradingModalContext = useGradingModalContext(false)
  if (gradingModalContext) {
    return (
      <_AssignmentGradingModalContent
        assignment={gradingModalContext.assignment}
        selectedTab={selectedTab}
      />
    )
  } else {
    return null
  }
}

interface _AssignmentGradingModalContentProps {
  assignment: FullAssignment
  selectedTab: string
}
function _AssignmentGradingModalContent({
  assignment,
  selectedTab,
}: _AssignmentGradingModalContentProps) {
  const { headerRightContent, tabContent, studentTab, classStudent } = useGradingPage(
    assignment,
    selectedTab,
  )

  return (
    <>
      {studentTab && classStudent ? (
        <CourseViewerModalCustomHeader>
          <span>{classStudent.user.name || classStudent.user.username}</span>
        </CourseViewerModalCustomHeader>
      ) : null}
      <GradingWrapper>
        {headerRightContent ? (
          <CourseViewerSegmentHeaderPortalContent>
            {headerRightContent}
          </CourseViewerSegmentHeaderPortalContent>
        ) : null}
        {tabContent}
      </GradingWrapper>
    </>
  )
}

const GradingWrapper = styled.div`
  .header {
    top: 10px !important;
  }
`
