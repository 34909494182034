import { useMediaPlaybackContext } from '@thesisedu/react'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { useBookmarks } from './useBookmarks'

export function PlayerBookmarkTrackDecorator() {
  const { duration } = useMediaPlaybackContext()
  const bookmarks = useBookmarks()
  return (
    <>
      {bookmarks.map(bookmark => {
        const left = (bookmark.startSeconds / duration) * 100
        const width = ((bookmark.endSeconds - bookmark.startSeconds) / duration) * 100
        return (
          <Decorator key={bookmark.startSeconds} style={{ left: `${left}%`, width: `${width}%` }} />
        )
      })}
    </>
  )
}

const Decorator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 9999px;
  background: ${s.color('orange')};
  pointer-events: none;
`
