import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { SpinProps } from 'antd/lib/spin'
import React from 'react'
import styled, { keyframes, ThemeContext } from 'styled-components'

import { Block, VSpaced } from './Block'
import { BodyLarge, BodySmall } from './Typography'

const Container = styled.div`
  margin: ${props => props.theme['@size-s']};
  display: flex;
  justify-content: center;
  flex-direction: row;
`

export const BlockSpin: React.FC<React.PropsWithChildren<SpinProps>> = props => (
  <Container>
    <Spin {...props} />
  </Container>
)

export interface DiscreteLoadingIndicatorProps {
  loading?: boolean
}
export function DiscreteLoadingIndicator({ loading }: DiscreteLoadingIndicatorProps) {
  return (
    <LoadingContainer loading={loading}>
      <LoadingOutlined />
      <BodySmall color={'@text-color-secondary'}>Saving...</BodySmall>
    </LoadingContainer>
  )
}

export interface LoadingIndicatorProps {
  /* This can either be a pixel value, or a theme size. Defaults to the font size. */
  size?: number | string
}
export function LoadingIndicator({ size }: LoadingIndicatorProps) {
  const theme = React.useContext(ThemeContext)
  const sizePx =
    typeof size === 'number'
      ? size
      : size
      ? theme[size]
        ? parseFloat(theme[size].replace('px', ''))
        : undefined
      : undefined
  if (sizePx !== undefined && isNaN(sizePx))
    throw new Error(`Invalid LoadingIndicator size '${size}'`)
  return <LoadingIndicatorElement size={sizePx} />
}
const SpinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const LoadingIndicatorElement = styled.div<{ size?: number }>`
  position: relative;
  display: inline-block;
  width: ${props => (props.size ? `${props.size}px` : '1em')};
  height: ${props => (props.size ? `${props.size}px` : '1em')};
  animation: ${SpinAnimation} 1s infinite linear;
  &::before {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid currentColor ${props => (props.size ? `${props.size * 0.2}px` : '0.2em')};
    opacity: 0.25;
  }
  &::after {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 50%;
    width: ${props => (props.size ? `${props.size * 0.2}px` : '0.2em')};
    height: ${props => (props.size ? `${props.size * 0.2}px` : '0.2em')};
    margin-left: -${props => (props.size ? `${props.size * 0.1}px` : '0.1em')};
    border-radius: 50%;
    background: currentColor;
  }
`

const LoadingContainer = styled.div<{ loading?: boolean }>`
  opacity: ${props => (props.loading ? 1 : 0)};
  transition: opacity 0.25s linear;
  color: ${props => props.theme['@text-color-secondary']};
  display: flex;
  align-items: center;
  pointer-events: none;
  > :first-child {
    font-size: ${props => props.theme['@size-xm']};
  }
  > :last-child {
    margin-left: ${props => props.theme['@size-xs']};
  }
`

export interface LargeLoadingIndicatorProps {
  message: string
}
export function LargeLoadingIndicator({ message }: LargeLoadingIndicatorProps) {
  return (
    <Block marginTop={'@size-l'} marginBottom={'@size-l'} style={{ width: '100%' }}>
      <VSpaced align={'center'}>
        <LoadingIndicator size={'@size-l'} />
        <BodyLarge color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
          {message}
        </BodyLarge>
      </VSpaced>
    </Block>
  )
}
