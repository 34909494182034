import jwt from 'jsonwebtoken'
import React from 'react'

import { ApolloReactFeature } from './ApolloReactFeature'
import { useFeatureRoot } from './feature'
import { getAuthenticationKey, removeAuthenticationKey } from './platform/context'

export function useAutoRefresh() {
  const root = useFeatureRoot()!
  const apolloFeature = root.requireFeature<ApolloReactFeature>(ApolloReactFeature.package)
  React.useEffect(() => {
    getAuthenticationKey(apolloFeature.options).then(authKey => {
      if (authKey) {
        try {
          const decoded = jwt.decode(authKey)
          if (decoded && typeof decoded === 'object' && decoded.exp) {
            const untilExpiration = decoded.exp * 1000 - Date.now()
            // Unset the key and refresh the page so we're forced to login again.
            setTimeout(
              async () => {
                removeAuthenticationKey(apolloFeature.options)
                // Only refresh the page if we actually removed the key (if we don't
                // do this we'll end up in an infinite refresh loop on pages that have
                // the auth key in the URL).
                if (!(await getAuthenticationKey(apolloFeature.options))) window.location.reload()
              },
              Math.max(0, untilExpiration),
            )
          }
        } catch {
          // Do nothing...
        }
      }
    })
  }, [])
}
