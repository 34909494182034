import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { SegmentMetadataSegment, SegmentStructureOverride } from '@thesisedu/feature-course-types'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Modal, BodyLarge } from '@thesisedu/web'
import { Form, Input, message, Select, Button } from 'antd'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { NON_GROUP_TYPES } from '../constants'
import { useCourseContext } from '../contexts/CourseContext'
import { error } from '../log'
import { SegmentType, useCreateOrUpdateSegmentMutation } from '../schema'
import { Segment } from '../types'

export interface CreateGroupModalProps {
  topics: Pick<Segment, 'id' | 'name' | 'childSegments' | 'weight' | 'type'>[]
  visible?: boolean
  termId: string
  isTopic?: boolean
  onClose: () => void
  header?: React.ReactElement
  noClose?: boolean
}
export function CreateGroupModal({
  isTopic,
  termId,
  topics,
  visible,
  header,
  noClose,
  onClose,
}: CreateGroupModalProps) {
  const [form] = Form.useForm()
  const cls = useSelectedClass(true)
  const courseClass = useCourseContext(true)
  const [updateClass, { loading }] = useUpdateClassMutation(cls.id, {
    onCompleted: () => {
      message.success(`${isTopic ? 'Topic' : 'Group'} created successfully!`)
      form.resetFields()
      onClose()
    },
  })
  const [createOrUpdate, { loading: createLoading }] = useCreateOrUpdateSegmentMutation({
    onError: onMutationError(`There was an error creating that ${isTopic ? 'topic' : 'group'}.`),
  })
  return (
    <Modal
      title={isTopic ? 'Create Topic' : 'Create Group'}
      visible={visible}
      onCancel={() => {
        if (!noClose) {
          onClose()
        }
      }}
      closable={!noClose}
      footer={[
        noClose ? null : (
          <Button key={'cancel'} onClick={onClose}>
            Cancel
          </Button>
        ),
        <Button
          key={'submit'}
          type={'primary'}
          onClick={form.submit}
          loading={loading || createLoading}
        >
          Create {isTopic ? 'Topic' : 'Group'}
        </Button>,
      ].filter(Boolean)}
      width={600}
      style={{ maxWidth: '90vw' }}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={async values => {
          try {
            const topic = topics.find(t => t.id === values.topicId)
            const topicIndex = topics.findIndex(t => t.id === values.topicId)
            const minWeight =
              topic?.childSegments?.reduce<number>((min, child) => {
                return child.weight !== undefined && child.weight < min ? child.weight : min
              }, 100) || 0
            let weight = 0
            if (isTopic && values.topicId === 'top') {
              weight =
                (topics.reduce<number>((min, child) => {
                  return child.weight !== undefined && child.weight < min ? child.weight : min
                }, 100) || 0) - 1
            } else if (isTopic && topic && topicIndex !== -1) {
              const nextTopic = topics[topicIndex + 1]
              weight =
                ((topic.weight || 0) +
                  (nextTopic ? nextTopic.weight || 0 : (topic.weight || 0) + 1)) /
                2
            } else if (!isTopic) {
              weight = minWeight - 1
            }
            const segmentId = uuid()
            const createResult = await createOrUpdate({
              variables: {
                input: {
                  id: toGlobalId('Segment', segmentId),
                  name: values.name,
                  type: SegmentType.Group,
                  config: {
                    studentContent: [],
                    teacherContent: [],
                  },
                },
              },
            })
            if (createResult?.data?.createOrUpdateSegment.segment.built.id) {
              const segments: SegmentMetadataSegment[] = courseClass.segmentMetadata?.segments || []
              const structureOverrides: SegmentStructureOverride[] =
                courseClass.segmentMetadata?.structureOverrides || []
              await updateClass(
                {
                  segmentMetadata: {
                    ...courseClass.segmentMetadata,
                    segments: [...segments, { id: segmentId, weight }],
                    structureOverrides: [
                      ...structureOverrides,
                      { id: segmentId, parentId: isTopic ? termId : values.topicId },
                    ],
                  },
                },
                true,
              )
            }
          } catch (err: any) {
            error(err)
          }
        }}
      >
        {header}
        <BodyLarge isBlock>
          Enter the name for your custom {isTopic ? 'topic' : 'group'} below and select which topic
          you would like {isTopic ? 'it to appear after' : 'to place it in'}, then click{' '}
          <strong>Create {isTopic ? 'Topic' : 'Group'}</strong>
        </BodyLarge>
        <Form.Item
          name={'name'}
          label={'Name'}
          rules={[
            { required: true, message: `A ${isTopic ? 'topic' : 'group'} name is required.` },
          ]}
        >
          <Input size={'large'} placeholder={`My Custom ${isTopic ? 'Topic' : 'Group'}`} />
        </Form.Item>
        <Form.Item
          name={'topicId'}
          label={isTopic ? 'After Topic' : 'Topic'}
          extra={"Don't worry, you can always move it somewhere else later."}
          rules={[{ required: true, message: 'An initial topic is required.' }]}
        >
          <Select
            placeholder={'Select a Topic...'}
            showSearch
            options={[
              ...(isTopic ? [{ key: 'top', value: 'top', label: 'Beginning of Semester' }] : []),
              ...topics
                .filter(topic => !NON_GROUP_TYPES.includes(topic.type as SegmentType))
                .map(topic => ({
                  key: topic.id,
                  value: topic.id,
                  label: topic.name,
                })),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
