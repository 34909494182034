import { useViewerContext } from '@thesisedu/feature-users-web'
import { BlockEditor, EditorValue } from '@thesisedu/feature-widgets-react'
import { Plus, SendMail } from '@thesisedu/react/icons'
import { styled, Block, Modal, BodySmall } from '@thesisedu/web'
import { message, Form, Input, Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { parseUserSources, UserSelect } from './UserSelect'
import { useCreateDirectMessageThreadMutation } from '../queries/useCreateDirectMessageThreadMutation'
import { UserSource } from '../types'

const DISCLAIMER_MESSAGE =
  'By proceeding with this multi-student message thread you understand that students have the ' +
  'ability to respond to all students in the message thread. You can remove individual student ' +
  'messages from the thread at any time by clicking "Delete" next to the student\'s ' +
  'message. Or, you can remove any student from the message thread at any time by clicking ' +
  '"Remove from Thread" next to their name. At all times, follow your institution\'s / system\'s ' +
  "/ district's policies regarding the sharing of students' communications with other students."

interface FormValues {
  subject?: string
  blockMessage: EditorValue
  sources: UserSource[]
}
export interface CreateDirectMessageThreadModalProps {
  visible?: boolean
  onClose: () => void
  onCreated?: (id: string) => void
}
export function CreateDirectMessageThreadModal({
  visible,
  onClose: _onClose,
  onCreated,
}: CreateDirectMessageThreadModalProps) {
  const viewer = useViewerContext(false)
  const [form] = Form.useForm<FormValues>()
  const [hasMultiUsers, setHasMultiUsers] = React.useState(false)
  const onClose = () => {
    form.resetFields()
    _onClose()
  }
  const [create, { loading }] = useCreateDirectMessageThreadMutation({
    onCompleted: data => {
      message.success('Thread created successfully!')
      if (onCreated) {
        onCreated(data.createDirectMessageThread.directMessageThread.id)
      }
      onClose()
    },
  })
  return (
    <Modal
      title={'Create Thread'}
      visible={visible}
      onCancel={onClose}
      width={900}
      style={{ maxWidth: '90vw' }}
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key={'submit'}
          onClick={form.submit}
          type={'primary'}
          icon={<SendMail />}
          loading={loading}
        >
          Send Message
        </Button>,
      ]}
    >
      <Form
        form={form}
        onValuesChange={(_, allValues) => {
          const parsedSources = parseUserSources(allValues.sources || [])
          setHasMultiUsers(parsedSources.recipientIds.length > 1)
        }}
        onFinish={values => {
          return create({
            variables: {
              input: {
                subject: values.subject || 'No Subject',
                blockMessage: values.blockMessage,
                ...parseUserSources(values.sources),
              },
            },
          })
        }}
        layout={'vertical'}
      >
        <Block marginTop={'@size-l'}>
          <Form.Item name={'subject'}>
            <Input size={'large'} placeholder={'Subject'} />
          </Form.Item>
        </Block>
        <Form.Item
          name={'sources'}
          rules={[
            { required: true, message: 'You must select some users to send the message to.' },
          ]}
        >
          <UserSelect size={'large'} placeholder={'Send to...'} />
        </Form.Item>
        <EditorContainer>
          <Form.Item
            name={'blockMessage'}
            rules={[{ required: true, message: 'A message is required.' }]}
          >
            <BlockEditor
              id={'create-dm-thread'}
              minHeight={200}
              placeholder={'What would you like to say?'}
              bordered
            />
          </Form.Item>
        </EditorContainer>
        {hasMultiUsers && viewer?.group === 'TEACHER' ? (
          <BodySmall isBlock color={'@text-color-secondary'}>
            <strong>You are adding multiple students to the same thread.</strong>{' '}
            {DISCLAIMER_MESSAGE}
          </BodySmall>
        ) : null}
      </Form>
    </Modal>
  )
}

export interface CreateDirectMessageThreadButtonProps
  extends Omit<CreateDirectMessageThreadModalProps, 'visible' | 'onClose'> {
  buttonProps?: Partial<ButtonProps>
}
export function CreateDirectMessageThreadButton({
  buttonProps,
  ...modalProps
}: CreateDirectMessageThreadButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <CreateDirectMessageThreadModal
        onClose={() => setVisible(false)}
        visible={visible}
        {...modalProps}
      />
      <Button type={'primary'} icon={<Plus />} onClick={() => setVisible(true)}>
        New Message
      </Button>
    </>
  )
}

const EditorContainer = styled.div`
  .ck-content {
    min-height: 250px;
  }
`
