import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { DATE_FORMATS } from '@thesisedu/web'
import { Table } from 'antd'
import { set } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteCourseButton } from './DeleteCourseButton'
import {
  CourseFragment,
  ListCoursesDocument,
  ListCoursesQuery,
  ListCoursesQueryVariables,
} from '../schema'

export const CoursesTable: React.FC<React.PropsWithChildren<unknown>> = () => (
  <InfiniteQuery<CourseFragment, ListCoursesQuery, ListCoursesQueryVariables>
    document={ListCoursesDocument}
    getResult={d => d?.courses}
    setResult={(previous, result) => set(previous, 'courses', result)}
    variables={{ first: 32 }}
    children={({ data }) => (
      <Table<CourseFragment>
        dataSource={data?.courses.edges.map(edge => edge.node) || []}
        rowKey={'id'}
        pagination={false}
      >
        <Table.Column<CourseFragment>
          title={'Name'}
          dataIndex={'name'}
          render={(name, record) => <Link to={record.id}>{name}</Link>}
        />
        <Table.Column<CourseFragment>
          title={'Created'}
          dataIndex={'createdAt'}
          render={created => moment(created).format(DATE_FORMATS.FULL)}
        />
        <Table.Column
          title={'Last Updated'}
          dataIndex={'updatedAt'}
          render={updated => moment(updated).format(DATE_FORMATS.FULL)}
        />
        {/* TODO students and 30 / 60 / 90 / 180 / 365 */}
        <Table.Column<CourseFragment>
          dataIndex={'id'}
          onCell={() => ({
            style: { textAlign: 'right' },
          })}
          render={(_, record) => (
            <>
              <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
                Edit
              </Link>
              <DeleteCourseButton courseId={record.id} />
            </>
          )}
        />
      </Table>
    )}
  />
)
