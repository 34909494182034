import { useProgressBar, AriaProgressBarProps } from '@react-aria/progress'
import React from 'react'

import { ProgressBarContext } from './context'

export interface ContainerProps extends AriaProgressBarProps {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
}
export function Container({
  children,
  label,
  value = 0,
  minValue = 0,
  maxValue = 100,
  isIndeterminate,
  style,
  className,
  ...props
}: ContainerProps) {
  const contextProps = { label, value, minValue, maxValue, isIndeterminate }
  const { progressBarProps, labelProps } = useProgressBar({ ...contextProps, ...props })
  return (
    <ProgressBarContext.Provider
      value={{
        progressBarProps,
        labelProps,
        ...contextProps,
      }}
    >
      <div style={style} className={className} {...progressBarProps}>
        {children}
      </div>
    </ProgressBarContext.Provider>
  )
}
