import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  IgnoreTrialRequestMutation,
  TrialRequestFragment,
  TrialRequestFragmentDoc,
  useIgnoreTrialRequestMutation as useMutation,
} from '../schema'

export const useIgnoreTrialRequestMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error ignoring that trial request.'),
    update: updateMutation<IgnoreTrialRequestMutation, TrialRequestFragment>({
      fragment: TrialRequestFragmentDoc,
      fragmentName: 'TrialRequest',
      path: 'ignoreTrialRequest.trialRequest',
    }),
    ...opts,
  })
