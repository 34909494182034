import { styled, s, Tabs$ as Tabs } from '@thesisedu/ui'
import React from 'react'

import { RecordNew } from './RecordNew'
import { UploadExisting } from './UploadExisting'
import { RecordState, RecordStateProps, getStateFactory } from '../types'

export interface SelectDeviceContext {}
function SelectDevice(props: RecordStateProps<SelectDeviceContext>) {
  return (
    <Container>
      <Content>
        <Tabs.Root defaultValue={'new'}>
          <Tabs.SegmentedControlList style={{ alignSelf: 'stretch' }}>
            <Tabs.SegmentedControlListItem value={'new'}>Record New</Tabs.SegmentedControlListItem>
            <Tabs.SegmentedControlListItem value={'existing'}>
              Upload Existing
            </Tabs.SegmentedControlListItem>
          </Tabs.SegmentedControlList>
          <Tabs.Content>
            <Tabs.Tab value={'new'}>
              <RecordNew {...props} />
            </Tabs.Tab>
            <Tabs.Tab value={'existing'}>
              <UploadExisting {...props} />
            </Tabs.Tab>
          </Tabs.Content>
        </Tabs.Root>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${s.size('s')};
`
const Content = styled.div`
  max-width: 350px;
  height: 315px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${s.size('xs')};
  align-items: stretch;
`

export default {
  Component: SelectDevice,
} satisfies RecordState<SelectDeviceContext>
export const getSelectDevice = getStateFactory<SelectDeviceContext>('selectDevice')
