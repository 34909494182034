import React from 'react'
import { FieldValues, FormProvider, useFormState } from 'react-hook-form'

import {
  ReactFormContextProvider,
  ReactFormContextProviderProps,
  useReactFormContext,
} from './Form'
import { ButtonProps, Button } from '../ant/Button'
import { isNative } from '../isNative'

export interface FormSubmitProps extends Partial<ButtonProps> {
  disableUntilDirty?: boolean
  loadingWhenSubmitting?: boolean
}
/** @deprecated */
export function FormSubmit({
  disableUntilDirty,
  loadingWhenSubmitting = true,
  ...rest
}: FormSubmitProps) {
  const { handleSubmit } = useReactFormContext()
  const { isDirty, isValid, isSubmitting } = useFormState()
  const isDisabled = rest.disabled || (disableUntilDirty && !isDirty) || (isNative && !isValid)
  const isLoading = rest.loading || (loadingWhenSubmitting && isSubmitting)
  return (
    <Button
      children={'Submit'}
      type={'primary'}
      {...rest}
      disabled={isDisabled}
      loading={isLoading}
      onClick={handleSubmit}
      data-testid={'FormSubmit'}
    />
  )
}

export type StandaloneFormSubmitProps<Values extends FieldValues> =
  ReactFormContextProviderProps<Values> & Omit<FormSubmitProps, 'form'>
export function StandaloneFormSubmit<Values extends FieldValues>({
  form,
  onFinish,
  ...rest
}: StandaloneFormSubmitProps<Values>) {
  return (
    <FormProvider {...form}>
      <ReactFormContextProvider form={form} onFinish={onFinish}>
        <FormSubmit {...rest} />
      </ReactFormContextProvider>
    </FormProvider>
  )
}
