import { orderBy } from 'lodash'

import { useMediaContext } from '../../record/MediaContext'
import { useMediaUpdatesContext } from '../../record/MediaUpdatesContext'

export function useBookmarks() {
  const { media } = useMediaContext(false) ?? {}
  const { mediaUpdates } = useMediaUpdatesContext(false) ?? {}
  return orderBy(
    mediaUpdates?.metadata?.bookmarks ?? media?.metadata?.bookmarks ?? [],
    'startSeconds',
    'asc',
  )
}

export function useCurrentBookmark(currentTime: number | null) {
  return useBookmarks().find(bookmark => {
    return (
      currentTime != null &&
      bookmark.startSeconds <= currentTime &&
      bookmark.endSeconds >= currentTime
    )
  })
}
