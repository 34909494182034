import { Icon as LegacyIcon } from '@ant-design/compatible'
import { TagDescriptionsLink } from '@thesisedu/feature-tags-react'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { DATE_FORMATS, styled } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { SegmentName } from './SegmentName'
import { getIconForSegment } from '../helpers'
import { SegmentFragment } from '../schema'
import { Segment } from '../types'
import { useTagTypes } from '../useOptions'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px;
  border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  > span[role='img'] {
    margin-right: 10px;
    margin-top: 2px;
    font-size: 16px;
  }
  > .left-content {
    line-height: 1.3;
    margin-right: 10px;
    > div {
      display: block;
      white-space: nowrap;
      a.edit-link span {
        white-space: normal;
      }
    }
    > span {
      font-size: 12px;
      color: ${props => props.theme['@text-color-secondary']};
    }
  }
  > .right-actions {
    text-align: right;
    margin: -3px 0 0 auto;
    font-size: 12px;
    line-height: 1.5;
    > div {
      white-space: nowrap;
    }
    a {
      color: ${props => props.theme['@gray-5']};
      opacity: 0.5;
      transition: opacity 0.1s linear;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        opacity: 1;
      }
    }
    a.action {
      font-size: 14px;
      color: ${props => props.theme['@blue']};
      opacity: 1;
    }
  }
`

export interface SegmentListItemProps {
  segment: SegmentFragment
  onAct: () => void
  onClick?: () => void
  action?: string | React.ReactElement
  actionProps?: any
}
export const SegmentListItem: React.FC<React.PropsWithChildren<SegmentListItemProps>> = ({
  segment,
  onAct,
  onClick,
  action = 'Select',
  actionProps,
}) => {
  const courseTags = segment.tagSummary?.COURSE || []
  const updatedDate = `Updated on ${moment(segment.updatedAt).format(DATE_FORMATS.DATE)}`
  const TAG_TYPES = useTagTypes()
  return (
    <Container>
      {/* @ts-ignore react 18 support */}
      <LegacyIcon type={getIconForSegment(segment as Segment)} />
      <div className={'left-content'}>
        <div>
          <SegmentName
            segment={{
              ...segment,
              id: fromGlobalId(segment.id, true).id,
            }}
            onClick={onClick}
          />
        </div>
        <span>
          {!!courseTags.length && <span>{courseTags.join(', ')} &middot;&nbsp;</span>}
          <TagDescriptionsLink
            types={TAG_TYPES}
            tagSummary={segment.tagSummary || {}}
          /> &middot; {updatedDate}
        </span>
      </div>
      <div className={'right-actions'}>
        <div>
          {typeof action === 'string' ? (
            <a
              className={'action'}
              onClick={e => {
                e.preventDefault()
                onAct()
              }}
              {...actionProps}
            >
              {action} &rarr;
            </a>
          ) : (
            action
          )}
        </div>
      </div>
    </Container>
  )
}
