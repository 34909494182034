import { useResource } from '@thesisedu/feature-react'
import { MenuItemProps, MultiFilterMenuItem } from '@thesisedu/react'
import { ShoppingCodeCheck } from '@thesisedu/react/icons'
import React from 'react'

import { GeneratedAssignmentProviderReactResource } from './types'
import { useClassGeneratedProviders } from './useClassGeneratedProviders'

export interface GeneratedProvidersFilterMenuItemProps
  extends Omit<Partial<MenuItemProps>, 'onChange'> {
  value?: string[]
  onChange?: (value?: string[]) => void
  classId?: string
}
export function GeneratedProvidersFilterMenuItem({
  value,
  onChange,
  classId,
  ...rest
}: GeneratedProvidersFilterMenuItemProps) {
  const { generatedProviders } = useClassGeneratedProviders(classId)
  const reactResources = useResource<GeneratedAssignmentProviderReactResource>(
    'GeneratedAssignmentProviderReact',
  )

  if (!generatedProviders.length) return null

  return (
    <MultiFilterMenuItem
      value={value}
      onChange={onChange ? onChange : () => {}}
      label={'Generated Type'}
      icon={<ShoppingCodeCheck />}
      options={generatedProviders.map(provider => {
        const resource = reactResources.find(r => r.identifier === provider)
        if (!resource) {
          throw new Error(`Cannot find React resource for '${provider}'`)
        }
        return {
          label: resource.label,
          value: provider,
        }
      })}
      {...rest}
    />
  )
}
