import { getSegmentName } from '@thesisedu/feature-course-types'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { Empty, Result } from '@thesisedu/react'
import { Block, LoadingIndicator, Modal, Modal$, useStatePropFallback } from '@thesisedu/ui'
import React from 'react'

import { PlacementDrawerSaveButton } from './PlacementDrawerSaveButton'
import { PlacementDrawerWithSegment } from './PlacementDrawerWithSegment'
import { ShowInClassButton } from '../../explore/ShowInClassButton'
import { useBuiltSegmentQuery } from '../../schema'
import { SplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'
import { useSplitViewPendingChanges } from '../useSplitViewPendingChanges'

export interface PlacementDrawerProps extends Omit<Modal$.ModalProps, 'children'> {
  globalSegmentId: string
  classId: string
}
export function PlacementDrawer({ globalSegmentId, classId, ...rest }: PlacementDrawerProps) {
  const [visible, setVisible] = useStatePropFallback(rest.visible, rest.onVisibleChange, false)
  const { data, loading } = useBuiltSegmentQuery({
    variables: {
      id: globalSegmentId,
    },
    // So the class IDs this segment is in will update.
    fetchPolicy: 'no-cache',
  })
  const segmentId = fromGlobalId(globalSegmentId, true).id
  const builtSegment = data?.node?.__typename === 'Segment' ? data.node : undefined
  const classIdsWithSegment = builtSegment?.classIdsWithSegment ?? []
  const pendingChanges = useSplitViewPendingChanges()
  const hasClassId = classIdsWithSegment.includes(classId)

  let content
  if (loading) {
    content = (
      <Block center>
        <LoadingIndicator.Labeled label={'Loading content...'} />
      </Block>
    )
  } else if (hasClassId) {
    const name = builtSegment?.built ? getSegmentName(builtSegment.built) : undefined
    content = (
      <Block center>
        <Result.Info
          title={'This content is already in your class.'}
          description={
            <>
              {name ? <strong>{name}</strong> : <span>This content</span>} is already in your class,
              and you can't add the same content to your class twice.
            </>
          }
          action={
            <ShowInClassButton
              classId={classId}
              segmentId={segmentId}
              variant={'ghost'}
              onPress={() => {
                rest.onVisibleChange?.(false)
              }}
            />
          }
        />
      </Block>
    )
  } else if (builtSegment?.built) {
    content = (
      <PlacementDrawerWithSegment
        segment={builtSegment}
        classId={classId}
        onCancel={() => {
          setVisible(false)
        }}
      />
    )
  } else {
    content = (
      <Block center>
        <Empty description={'That content could not be found.'} />
      </Block>
    )
  }

  return (
    <Modal
      presentation={'drawer'}
      drawerSide={'left'}
      hideTitle
      title={'Placing Content'}
      noClose
      {...rest}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <SplitViewPendingChangesContext.Provider value={pendingChanges.context}>
        <>
          {content}
          <Modal.Footer>
            <Modal.CloseButton children={'Cancel'} />
            <PlacementDrawerSaveButton classId={classId} onClose={() => setVisible(false)} />
          </Modal.Footer>
        </>
      </SplitViewPendingChangesContext.Provider>
    </Modal>
  )
}
