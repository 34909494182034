import { useMutateHook } from '@thesisedu/feature-react'
import { Logout } from '@thesisedu/feature-users-web'
import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'

import { AuthWrapper } from './AuthWrapper'
import { Login } from './Login'
import { RequestPasswordReset } from './RequestPasswordReset'
import { ResetPassword } from './ResetPassword'
import { Register } from './register'
import { AuthRoutesHook } from '../../types'

export function AuthRoutes() {
  const routes = useMutateHook<AuthRoutesHook>('feature-users-react:auth-routes', [], undefined)
  return (
    <AuthWrapper>
      <Routes>
        <Route path={'/'} element={<Navigate to={'login'} replace />} />
        <Route path={'register/*'} element={<Register />} />
        <Route path={'login'} element={<Login />} />
        <Route path={'logout'} element={<Logout />} />
        <Route path={'request-password-reset'} element={<RequestPasswordReset />} />
        <Route path={'reset-password'} element={<ResetPassword />} />
        {routes}
      </Routes>
    </AuthWrapper>
  )
}
