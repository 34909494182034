import { NavigationListItem } from '@thesisedu/react/devtools'
import { Text } from '@thesisedu/ui'
import React from 'react'

import { Container } from './HistoryListItem'
import { useClassSyncQuery } from '../schema'

export interface ClassSyncListItemProps {
  classSyncId: string
  onClick: () => void
  isSelected: boolean
}
export function ClassSyncListItem({ classSyncId, onClick, isSelected }: ClassSyncListItemProps) {
  const { data } = useClassSyncQuery({ variables: { classSyncId } })
  const sync = data?.node?.__typename === 'ClassSync' ? data.node : undefined
  return (
    <NavigationListItem onClick={onClick} isSelected={isSelected}>
      <Container>
        <Text level={'s'}>{sync?.label ?? '--'}</Text>
        <Text level={'xs'} color={'secondary'}>
          {sync?.provider ?? '--'}
        </Text>
      </Container>
    </NavigationListItem>
  )
}
