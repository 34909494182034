import { useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Dropdown } from '@thesisedu/ui'
import { MoreVert } from '@thesisedu/ui/icons'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { useSegmentStatusContext } from '../contexts/SegmentStatusContext'
import { Segment, SegmentActionResource } from '../types'

export interface SegmentActionsProps {
  segment: Segment
  children?: React.ReactElement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}
export function SegmentActions({ segment, children, open, onOpenChange }: SegmentActionsProps) {
  const resources = orderBy(useResource<SegmentActionResource>('SegmentAction'), 'weight', 'asc')
  const status = useSegmentStatusContext(false)
  const viewer = useViewerContext(false)
  const validResourcesGrouped = groupBy(
    resources.filter(resource => {
      return !resource.filter || resource.filter(segment, { status, viewer })
    }),
    'group',
  )

  return (
    <Dropdown.Container open={open} onOpenChange={onOpenChange}>
      {children ? (
        <Dropdown.ManualTrigger children={children} />
      ) : (
        <Dropdown.Button variant={'ghost'} icon={<MoreVert />} rightDecorator={null} />
      )}
      <Dropdown.Menu align={'end'}>
        {Object.keys(validResourcesGrouped).map((groupName, index) => {
          const group = validResourcesGrouped[groupName]
          const isLast = index === Object.keys(validResourcesGrouped).length - 1
          return (
            <React.Fragment key={`group-${groupName}`}>
              {group.map(({ component: Component, identifier }) => {
                return <Component key={identifier} segment={segment} />
              })}
              {isLast ? null : <Dropdown.Item.Separator />}
            </React.Fragment>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
