import { UserLeftItemsHook, UserRoutesHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { Trophy } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { Badges } from './Badges'
import { BadgesReactFeature } from '../../BadgesReactFeature'

export default function (feature: BadgesReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [...routes, <Route key={'badges'} path={'badges/*'} element={<Badges />} />]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            weight: 0.1,
            element: (
              <Shell.LeftMenuItem
                name={'Badges'}
                icon={<Trophy />}
                path={'/admin/badges'}
                key={'badges'}
              />
            ),
          },
        ]
      } else {
        return items
      }
    },
  )
}
