import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Row } from '@thesisedu/web'
import { Col, Form, Input, Tabs } from 'antd'
import React from 'react'

import { Editor } from '../../editor/Editor'
import { SegmentType } from '../../schema'
import { EditableSegment } from '../../segment/EditableSegment'
import { SegmentProps } from '../../types'

export function TermEdit(props: SegmentProps) {
  return (
    <EditableSegment
      {...props}
      addTypes={[
        SegmentType.Section,
        SegmentType.Group,
        SegmentType.Reference,
        'Assignment',
        SegmentType.Topic,
      ]}
    >
      {({ childrenFields, submitFields, segment }) => (
        <Row>
          <Col xs={24} md={8} lg={6}>
            <Form.Item
              label={'Name'}
              name={'name'}
              initialValue={segment.name}
              rules={[{ required: true, message: 'A term name is required.' }]}
            >
              <Input />
            </Form.Item>
            {childrenFields}
            {submitFields}
          </Col>
          <Col xs={24} md={16} lg={18}>
            <Tabs defaultActiveKey={'student'}>
              <Tabs.TabPane
                forceRender
                tab={
                  <span>
                    <UserOutlined /> Student View
                  </span>
                }
                key={'student'}
              >
                <Form.Item
                  name={['config', 'studentContent']}
                  initialValue={segment.config.studentContent || []}
                >
                  <Editor id={`${segment.id}-student`} />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane
                forceRender
                tab={
                  <span>
                    <InfoCircleOutlined /> Teacher View
                  </span>
                }
                key={'teacher'}
              >
                <Form.Item
                  name={['config', 'teacherContent']}
                  initialValue={segment.config.teacherContent || []}
                >
                  <Editor id={`${segment.id}-teacher`} />
                </Form.Item>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      )}
    </EditableSegment>
  )
}
