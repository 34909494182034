import { useResource } from '@thesisedu/feature-react'
import {
  GridContainer,
  Block,
  H3Alternate,
  HSpaced,
  Space,
  styled,
  Body,
  BodySmall,
  Button,
  message,
  Tooltip,
  Switch,
  BodyLarge,
  FontWeight,
} from '@thesisedu/react'
import { NavArrowLeft } from '@thesisedu/react/icons'
import { groupBy } from 'lodash'
import React from 'react'

import { RemoveFromClassButton } from './RemoveFromClassButton'
import { useUpdateTeacherClassPermissionsMutation } from '../queries/useUpdateTeacherClassPermissionsMutation'
import { TeamTeachingEdgeFragment } from '../schema'
import { ClassTeacherPermissionResource } from '../types'

interface DisabledResources {
  [identifier: string]: string[]
}
export function usePermissionResources(enabled: string[]) {
  const availablePerms = useResource<ClassTeacherPermissionResource>('ClassTeacherPermission')
  const enabledResources = availablePerms.filter(p => {
    return p.permissions.every(ip => enabled.includes(ip))
  })
  const disabledResources = enabledResources.reduce<DisabledResources>((acc, enabled) => {
    let result = acc
    for (const dep of enabled.dependencies || []) {
      result = { ...result, [dep.identifier]: [...(result[dep.identifier] || []), dep.message] }
    }
    return result
  }, {})
  return { availablePerms, enabledResources, disabledResources }
}

export interface TeacherPermissionsProps {
  classId: string
  teacher: TeamTeachingEdgeFragment
  onBack: () => void
}
export function TeacherPermissions({ classId, teacher, onBack }: TeacherPermissionsProps) {
  const [permissions, _setPermissions] = React.useState(teacher.permissions)
  const setPermissions: typeof _setPermissions = arg => {
    setIsDirty(true)
    _setPermissions(arg)
  }
  const [isDirty, setIsDirty] = React.useState(false)
  const [update, { loading }] = useUpdateTeacherClassPermissionsMutation({
    onCompleted: () => {
      message.success('Permissions updated!')
      setIsDirty(false)
      onBack()
    },
  })
  const { availablePerms, enabledResources, disabledResources } =
    usePermissionResources(permissions)
  const groupedPermissions = groupBy(availablePerms, 'group')
  const sortedGroups = Object.keys(groupedPermissions).sort()

  const setEnabled = (newEnabledResources: ClassTeacherPermissionResource[]) => {
    // Recalculate the permissions.
    const newPermissions = newEnabledResources.reduce<Set<string>>((acc, resource) => {
      for (const perm of resource.permissions) {
        acc.add(perm)
      }
      for (const dependency of resource.dependencies || []) {
        const depResource = availablePerms.find(p => p.identifier === dependency.identifier)
        if (depResource) {
          for (const perm of depResource.permissions) {
            acc.add(perm)
          }
        }
      }
      return acc
    }, new Set<string>())
    // Update the permissions.
    setPermissions([...newPermissions])
  }

  return (
    <>
      <Block marginBottom={'@size-m'}>
        <HSpaced>
          <div>
            <a
              href={'#'}
              onClick={e => {
                e.preventDefault()
                onBack()
              }}
            >
              <BodySmall color={'@text-color-secondary'}>
                <NavArrowLeft /> Back
              </BodySmall>
            </a>
            <H3Alternate isBlock={false}>
              {teacher.node.user.name || teacher.node.user.username}
            </H3Alternate>
          </div>
          <Space />
          <RemoveFromClassButton classId={classId} teacher={teacher} />
          <Button
            type={'primary'}
            loading={loading}
            disabled={!isDirty}
            onClick={() => {
              update({
                variables: {
                  input: {
                    id: classId,
                    teacherId: teacher.node.id,
                    permissions,
                  },
                },
              })
            }}
          >
            Save Changes
          </Button>
        </HSpaced>
      </Block>
      {sortedGroups.map(sortedGroup => (
        <Block marginBottom={'@size-m'} key={sortedGroup}>
          <Block marginBottom={'@size-s'}>
            <HSpaced>
              <BodyLarge weight={FontWeight.Bold} isBlock={false}>
                {sortedGroup}
              </BodyLarge>
              <Space />
              <Button
                type={'link'}
                size={'small'}
                onClick={() => {
                  setEnabled([...enabledResources, ...groupedPermissions[sortedGroup]])
                }}
              >
                Select All
              </Button>
              <Button
                type={'link'}
                size={'small'}
                onClick={() => {
                  setEnabled(enabledResources.filter(r => r.group !== sortedGroup))
                }}
              >
                Select None
              </Button>
            </HSpaced>
          </Block>
          <GridContainer gap={'@size-s'} columnSize={300}>
            {(groupedPermissions[sortedGroup] as ClassTeacherPermissionResource[]).map(
              permission => (
                <PermissionToggle
                  key={permission.identifier}
                  resource={permission}
                  value={!!enabledResources.some(er => er.identifier === permission.identifier)}
                  disabledMessages={disabledResources[permission.identifier!]}
                  onChange={enabled => {
                    setEnabled(
                      enabled
                        ? [...enabledResources, permission]
                        : enabledResources.filter(e => e.identifier !== permission.identifier),
                    )
                  }}
                />
              ),
            )}
          </GridContainer>
        </Block>
      ))}
    </>
  )
}

export interface PermissionToggleProps {
  resource: ClassTeacherPermissionResource
  value?: boolean
  onChange: (enabled: boolean) => void
  disabledMessages?: string[]
}
export function PermissionToggle({
  resource,
  value,
  onChange,
  disabledMessages,
}: PermissionToggleProps) {
  return (
    <Tooltip title={disabledMessages?.join(' ') || ''}>
      <Container>
        <HSpaced align={'flex-start'}>
          <Body>{resource.label}</Body>
          <Space />
          <Switch checked={value} onChange={onChange} disabled={!!disabledMessages?.length} />
        </HSpaced>
      </Container>
    </Tooltip>
  )
}
const Container = styled.div`
  border: solid 1px ${props => props.theme['@border-color-base']};
  border-radius: ${props => props.theme['@border-radius-base']};
  margin-bottom: ${props => props.theme['@size-s']};
  padding: ${props => props.theme['@padding-sm']};
  width: 100%;
`
