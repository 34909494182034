import { Question } from '@thesisedu/feature-assignments-core'
import React, { useContext } from 'react'

export interface QuestionListContextValue {
  createdQuestionId?: string
  acknowledgeCreatedQuestionId: () => void
  questions: Question[]
  singleQuestionMode?: boolean
}
export const QuestionListContext = React.createContext<QuestionListContextValue | undefined>(
  undefined,
)

export interface QuestionListContextProviderProps {
  value: QuestionListContextValue
}
export function QuestionListContextProvider({
  value,
  children,
}: React.PropsWithChildren<QuestionListContextProviderProps>) {
  const existing = useQuestionListContext(false)
  return <QuestionListContext.Provider value={{ ...existing, ...value }} children={children} />
}

export interface PartialQuestionListContextProviderProps {
  value: Partial<QuestionListContextValue>
}
export function PartialQuestionListContextProvider({
  value,
  children,
}: React.PropsWithChildren<PartialQuestionListContextProviderProps>) {
  return <QuestionListContextProvider value={value as any} children={children} />
}

export function useQuestionListContext(): QuestionListContextValue | undefined
export function useQuestionListContext(require: false): QuestionListContextValue | undefined
export function useQuestionListContext(require: true): QuestionListContextValue
export function useQuestionListContext(require?: boolean): QuestionListContextValue | undefined {
  const context = useContext(QuestionListContext)
  if (!context && require) {
    throw new Error('QuestionListContext is required, yet not provided.')
  }
  return context
}
