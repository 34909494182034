import { isNative } from '@thesisedu/react'

import { UploadManager } from './manager'
import { showUploadAddedNotification } from './showUploadAddedNotification'
import { AdditionalFields, Upload, UploadPayload, UploadStatus } from './types'
import { debug, warn } from '../log'
import { GetMediaUploadResponse } from '../record/types'
import { MediaType } from '../schema'

export interface FetchAndAddUploadResult {
  payload: UploadPayload
  initialResponse: GetMediaUploadResponse
}

export const getContentType = (mediaType: MediaType): string => {
  if (isNative) {
    return mediaType === MediaType.Video ? 'video/mp4' : 'audio/aac'
  } else {
    return mediaType === MediaType.Video ? 'video/webm' : 'audio/webm'
  }
}

export const getExtension = (mediaType: MediaType): string => {
  if (isNative) {
    return mediaType === MediaType.Video ? 'mp4' : 'aac'
  } else {
    return mediaType === MediaType.Video ? 'webm' : 'webm'
  }
}

export const addUpload = async (
  label: string,
  uploadManager: UploadManager,
  mutationResult: GetMediaUploadResponse,
  blobOrPath: Pick<Upload, 'fileBlob' | 'filePath'>,
  statusCallback?: (status: UploadStatus) => void,
): Promise<FetchAndAddUploadResult | null> => {
  if (!mutationResult.media) return null
  debug('adding new upload...')
  const mediaType = mutationResult.media.media.type!
  const contentType = getContentType(mediaType)
  if (mutationResult?.media.signedUrl) {
    const result = mutationResult.media
    const mediaId = result.media.id
    debug('resulting media id is %s', mediaId)
    debug('adding upload...')
    const payload: UploadPayload = {
      mediaId,
      mediaType,
      signedUrl: result.signedUrl,
      signedUrlPath: result.path,
      additionalFields: result.data as AdditionalFields,
      ...blobOrPath,
      label,
      contentType,
      statusCallback,
    }
    showUploadAddedNotification(mediaType, blobOrPath)
    await uploadManager.addUpload(payload)
    return {
      payload,
      initialResponse: mutationResult,
    }
  } else {
    warn('could not determine signedUrl from mutation result')
    return null
  }
}
