import { PlusOutlined } from '@ant-design/icons'
import { useTeacherClass } from '@thesisedu/feature-classes-react'
import { Modal } from '@thesisedu/web'
import { Button, message, Form, Input } from 'antd'
import React from 'react'

import { AssignmentCategorySelect } from './AssignmentCategorySelect'
import { useGradingModalVisibilityContext } from './contexts/GradingModalVisibilityContext'
import { useCreateAssignmentMutation } from './queries/useCreateAssignmentMutation'
import { BasicAssignmentFragment } from './schema'
import { ClassFragmentWithPermissions } from './types'

interface CreateAssignmentForm {
  name: string
  assignmentCategoryId: string
}

export function useCreateAssignment(created: (assignment: BasicAssignmentFragment) => void) {
  const { setGradingAssignment } = useGradingModalVisibilityContext(false) || {}
  const [create, { loading }] = useCreateAssignmentMutation({
    onCompleted: data => {
      message.success('Assignment created successfully!')
      if (setGradingAssignment) {
        setGradingAssignment(data.createAssignment.assignment, { defaultTab: 'settings' })
      }
      created(data.createAssignment.assignment)
    },
  })

  return { create, loading }
}

export interface CreateAssignmentModalProps {
  visible?: boolean
  classId?: string
  onCancel: () => void
}
export function CreateAssignmentModal({
  visible,
  classId,
  onCancel: _onCancel,
}: CreateAssignmentModalProps) {
  const { create, loading } = useCreateAssignment(() => onCancel())
  const [form] = Form.useForm<CreateAssignmentForm>()
  const onCancel = () => {
    form.resetFields()
    _onCancel()
  }

  return (
    <Modal
      title={'Create Assignment'}
      width={600}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button type={'primary'} onClick={form.submit} loading={loading}>
          Create Assignment
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values =>
          create({
            variables: {
              input: {
                assignment: {
                  name: values.name,
                  assignmentCategoryIds: values.assignmentCategoryId
                    ? [values.assignmentCategoryId]
                    : undefined,
                },
              },
            },
          })
        }
      >
        <Form.Item
          name={'name'}
          label={'Name'}
          rules={[{ required: true, message: 'A name is required.' }]}
          extra={'First, give your assignment a name. You can configure other options later.'}
        >
          <Input placeholder={'My Custom Assignment'} size={'large'} />
        </Form.Item>
        {classId ? (
          <Form.Item
            name={'assignmentCategoryId'}
            label={'Category'}
            rules={[{ required: true, message: 'A category is required.' }]}
          >
            <AssignmentCategorySelect classId={classId} placeholder={'Select a Category'} />
          </Form.Item>
        ) : null}
      </Form>
    </Modal>
  )
}

export function CreateAssignmentButton(
  props: Omit<CreateAssignmentModalProps, 'onCancel' | 'visible'>,
) {
  const { cls } = useTeacherClass(props.classId || null, false)
  const canCreate = props.classId
    ? (cls as ClassFragmentWithPermissions | undefined)?.canCreateAssignment
    : true
  const [visible, setVisible] = React.useState(false)
  if (!canCreate) return null
  return (
    <>
      <CreateAssignmentModal onCancel={() => setVisible(false)} visible={visible} {...props} />
      <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        Create
      </Button>
    </>
  )
}
