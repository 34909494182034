import { useFeature } from '@thesisedu/feature-web'
import { Empty } from 'antd'
import React from 'react'

import { FeaturePreview } from './FeaturePreview'
import { FeaturePreviewReactFeature } from './FeaturePreviewReactFeature'
import { FeaturePreviewResource } from './types'

export const FeaturePreviewSettings: React.FC<React.PropsWithChildren<unknown>> = () => {
  const feature = useFeature<FeaturePreviewReactFeature>(FeaturePreviewReactFeature.package)
  const resources = feature.resources.getResourcesForType<FeaturePreviewResource>('FeaturePreview')
  const children = resources.map(resource => <FeaturePreview resource={resource} />)
  if (children.length > 0) {
    return <>{children}</>
  } else {
    return <Empty description={'There are currently no features to preview. Check back later!'} />
  }
}
