import { EditorValue, Legacy } from '@thesisedu/feature-widgets-core'
import {
  ControlledNestedBlockEditor,
  ControlledNestedBlockEditorProps,
} from '@thesisedu/feature-widgets-react'
import React from 'react'

export const BLOCKS_DEBOUNCE = 1000

export function convertLegacyAnswer(answer?: string | EditorValue | null): EditorValue | undefined
export function convertLegacyAnswer(answer: string | EditorValue): EditorValue
export function convertLegacyAnswer(answer?: string | null | EditorValue): EditorValue | undefined {
  if (!answer) return undefined
  if (typeof answer === 'string') {
    return Legacy.getBlockValueFromAnyValue(answer)
  } else return answer
}

export interface LegacyEditorWrapperProps extends Omit<ControlledNestedBlockEditorProps, 'value'> {
  value?: string | null | EditorValue
}
export function LegacyEditorWrapper({ value, ...rest }: LegacyEditorWrapperProps) {
  return <ControlledNestedBlockEditor {...rest} value={convertLegacyAnswer(value)} />
}
