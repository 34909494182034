import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { Format } from '@thesisedu/feature-utils'
import { DeleteLink } from '@thesisedu/web'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteTeacherButton } from './DeleteTeacherButton'
import { MoveTeacherToSiteButton } from './MoveTeacherSiteButton'
import {
  TeacherFragment,
  TeachersForSiteDocument,
  TeachersForSiteQuery,
  TeachersForSiteQueryVariables,
} from './schema'

export interface SiteTeachersTableProps {
  siteId: string
}
export const SiteTeachersTable: React.FC<React.PropsWithChildren<SiteTeachersTableProps>> = ({
  siteId,
}) => (
  <InfiniteQuery<TeacherFragment, TeachersForSiteQuery, TeachersForSiteQueryVariables>
    document={TeachersForSiteDocument}
    variables={{ siteId }}
    getResult={d => (d?.node?.__typename === 'Site' ? d.node.teachers : undefined)}
    setResult={(previous, result) => ({
      ...previous,
      node: {
        ...previous.node!,
        teachers: {
          ...(previous.node?.__typename === 'Site' ? previous.node.teachers : {}),
          ...result,
        },
      },
    })}
    children={({ data }) => (
      <TeachersTable
        dataSource={
          data?.node?.__typename === 'Site' ? data.node.teachers.edges.map(edge => edge.node) : []
        }
      />
    )}
  />
)

export interface TeachersTableProps extends TableProps<TeacherFragment> {
  hideActions?: boolean
}
export const TeachersTable: React.FC<React.PropsWithChildren<TeachersTableProps>> = ({
  hideActions,
  ...props
}) => (
  <Table<TeacherFragment> rowKey={'id'} pagination={false} {...props}>
    <Table.Column<TeacherFragment>
      title={'First Name'}
      dataIndex={['user', 'firstName']}
      render={(name, record) => <Link to={`/admin/users/${record.user.id}`}>{name}</Link>}
    />
    <Table.Column<TeacherFragment>
      title={'Last Name'}
      dataIndex={['user', 'lastName']}
      render={(name, record) => <Link to={`/admin/users/${record.user.id}`}>{name}</Link>}
    />
    <Table.Column
      title={'Email'}
      dataIndex={['user', 'email']}
      render={email => <a href={`mailto:${email}`}>{email}</a>}
    />
    <Table.Column
      title={'Last Activity'}
      dataIndex={['user', 'lastActivity']}
      render={last => (last ? Format.date(last, 'dateTime') : null)}
    />
    <Table.Column<TeacherFragment>
      title={'Students'}
      dataIndex={'id'}
      render={(_, record) => record.totalStudents}
    />
    {!hideActions ? (
      <Table.Column<TeacherFragment>
        dataIndex={'id'}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(_, record) => (
          <>
            <DeleteTeacherButton teacherId={record.id} siteId={record.siteId}>
              <DeleteLink>Delete &rarr;</DeleteLink>
            </DeleteTeacherButton>
            <MoveTeacherToSiteButton teacherId={record.id} />
            <Link to={`/admin/users/${record.user.id}`} style={{ whiteSpace: 'nowrap' }}>
              Edit &rarr;
            </Link>
          </>
        )}
      />
    ) : null}
  </Table>
)
