import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import Case from 'case'
import React from 'react'
import { Link } from 'react-router-dom'

import { SITE_TYPES } from './constants'
import {
  DistrictSitesDocument,
  DistrictSitesQuery,
  DistrictSitesQueryVariables,
  SiteFragment,
  SitesDocument,
  SitesQuery,
  SitesQueryVariables,
} from './schema'

export const AllSitesTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <InfiniteQuery<SiteFragment, SitesQuery, SitesQueryVariables>
      document={SitesDocument}
      getResult={d => d?.sites}
      setResult={(previous, result) => ({
        ...previous,
        sites: {
          ...previous.sites,
          ...result,
        },
      })}
      children={({ data }) => <SitesTable dataSource={data?.sites.edges.map(edge => edge.node)} />}
    />
  )
}

export interface DistrictSitesTableProps extends SitesTableProps {
  districtId: string
}
export const DistrictSitesTable: React.FC<React.PropsWithChildren<DistrictSitesTableProps>> = ({
  districtId,
  ...props
}) => {
  return (
    <InfiniteQuery<SiteFragment, DistrictSitesQuery, DistrictSitesQueryVariables>
      document={DistrictSitesDocument}
      variables={{ districtId }}
      getResult={d => (d?.node?.__typename === 'District' ? d.node.sites : undefined)}
      setResult={(previous, result) => ({
        ...previous,
        node: {
          ...previous.node!,
          sites: {
            ...(previous.node?.__typename === 'District' ? previous.node.sites : {}),
            ...result,
          },
        },
      })}
      children={({ data }) => (
        <SitesTable
          hideActions
          dataSource={
            data?.node?.__typename === 'District'
              ? data.node.sites.edges.map(edge => edge.node)
              : []
          }
          {...props}
        />
      )}
    />
  )
}

export interface SitesTableProps extends TableProps<SiteFragment> {
  hideActions?: boolean
}
export const SitesTable: React.FC<React.PropsWithChildren<SitesTableProps>> = ({
  hideActions,
  ...props
}) => (
  <Table<SiteFragment> rowKey={'id'} pagination={false} {...props}>
    <Table.Column<SiteFragment>
      title={'Name'}
      dataIndex={'name'}
      render={(name, record) => <Link to={`/admin/sites/${record.id}`}>{name}</Link>}
    />
    <Table.Column
      title={'Type'}
      dataIndex={'type'}
      render={(type: keyof typeof SITE_TYPES) => SITE_TYPES[type]}
    />
    <Table.Column title={'City'} dataIndex={'city'} />
    <Table.Column title={'State'} dataIndex={'state'} />
    <Table.Column<SiteFragment>
      title={'District'}
      dataIndex={'district.id'}
      render={(_, record) => (
        <Link to={`/admin/districts/${record.district?.id}`}>{record.district?.name}</Link>
      )}
    />
    <Table.Column<SiteFragment>
      title={'License Status'}
      dataIndex={'id'}
      render={(_, record) =>
        record.license
          ? `${record.license.usedSeats} / ${record.license.seats} (${Case.title(
              record.license.type,
            )})`
          : 'No License'
      }
    />
    {!hideActions ? (
      <Table.Column<SiteFragment>
        dataIndex={'id'}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(_, record) => (
          <>
            <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
              Edit &rarr;
            </Link>
          </>
        )}
      />
    ) : null}
  </Table>
)
