import { getFlag, useFlag } from '@thesisedu/react'

import { debug } from './log'
import { FeaturePreviewState, UseFeaturePreviewHook } from './types'

const PERSISTED_KEY = 'feature-feature-preview-react-state'
const DEFAULT_STATE: FeaturePreviewState = {
  enabledPreviews: [],
}

export const useFeaturePreview = (identifier: string): UseFeaturePreviewHook => {
  const [state, setState] = useFlag<FeaturePreviewState>(PERSISTED_KEY, DEFAULT_STATE)
  return [
    state?.enabledPreviews.includes(identifier) || false,
    enabled => {
      debug('%s feature preview %s', enabled ? 'enabling' : 'disabling', identifier)
      const without = state?.enabledPreviews.filter(preview => preview !== identifier) || []
      setState({
        ...state,
        enabledPreviews: enabled ? [...without, identifier] : without,
      })
    },
  ]
}

/**
 * Helper function to arbitrarily fetch if a feature preview is enabled or not, from
 * anywhere in the application (doesn't necessarily need to be a hook).
 * @param identifier - the identifier of the feature preview to check for
 */
export async function hasFeaturePreview(identifier: string): Promise<boolean> {
  if (getFlag) {
    const value = await getFlag<FeaturePreviewState>(PERSISTED_KEY)
    return !!value && value.enabledPreviews.includes(identifier)
  } else {
    return false
  }
}
