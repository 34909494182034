import { LoginLinksHook } from '@thesisedu/feature-users-react'
import { Body } from '@thesisedu/react'
import { ArrowRight } from '@thesisedu/react/icons'
import React from 'react'
import { Link } from 'react-router-dom'

import { StudentIdReactFeature } from '../StudentIdReactFeature'

export default function (feature: StudentIdReactFeature) {
  feature.hookManager.registerMutateHook<LoginLinksHook>(
    'feature-users-react:login-links',
    links => {
      return [
        ...links,
        <Link to={'../student'} key={'student-id'}>
          <Body color={'@text-color-secondary'}>
            I have a Student ID <ArrowRight />
          </Body>
        </Link>,
      ]
    },
  )
}
