import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'

import { styled, s, HSpaced } from '../../'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusRing } from '../FocusRing/FocusRing'

export interface TextFieldProps<T extends 'input' | 'textarea' = 'input'>
  extends ExtendedBareFieldProps,
    Omit<AriaTextFieldOptions<T>, 'isDisabled'> {
  className?: string
  style?: React.CSSProperties
  inputStyle?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  disabled?: boolean
  variant?: TextFieldVariant | TextFieldVariant[]
  prefix?: React.ReactElement
  suffix?: React.ReactElement
}
function _TextField<T extends 'input' | 'textarea' = 'input'>(
  {
    className,
    style,
    inputStyle,
    disabled,
    size = s.SharedVariants.Size.defaultValue,
    variant,
    prefix,
    suffix,
    ...props
  }: TextFieldProps<T>,
  _ref: React.ForwardedRef<T extends 'input' ? HTMLInputElement : HTMLTextAreaElement>,
) {
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const ref = useObjectRef(_ref)
  const { inputProps, ...aria } = useTextField<any>(
    { ...bareFieldProps, ...rest, isDisabled: disabled },
    ref,
  )
  return (
    <BareField {...bareFieldProps} style={style} __aria={aria}>
      <FocusRing isTextInput>
        <_Input
          {...inputProps}
          isTextArea={props.inputElementType === 'textarea'}
          prefix={prefix}
          suffix={suffix}
          value={inputProps.value || ''}
          disabled={disabled}
          ref={ref}
          style={inputStyle}
          className={s.variants<typeof _variants>(
            className,
            {
              disabled,
              error: !!props.error,
            },
            size,
            variant,
          )}
        />
      </FocusRing>
    </BareField>
  )
}
export const TextField = React.forwardRef(_TextField)

interface _InputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'prefix' | 'suffix'
  > {
  prefix: TextFieldProps['prefix']
  suffix: TextFieldProps['suffix']
  isTextArea?: boolean
}
function __Input(
  { prefix, suffix, className, style, isTextArea, ...rest }: _InputProps,
  ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
) {
  return (
    <_InputContainer className={className} style={style} space={'xs'}>
      {prefix}
      <_ResetInput {...rest} as={isTextArea ? 'textarea' : 'input'} ref={ref as any} />
      {suffix}
    </_InputContainer>
  )
}
export const _Input = React.forwardRef(__Input)

const { css } = s
export const _ResetInputStyles = css`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.2;
  margin: 0;
  text-transform: none;
  background: transparent;
  padding: 0;
  color: inherit;
  border: none;
  outline: none;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 0.9em;
    width: 0.9em;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='51px' height='51px' stroke-width='2.3' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23000000'%3E%3Cpath d='M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243' stroke='%23000000' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.9em;
  }
`
const _ResetInput = styled.input`
  ${_ResetInputStyles}
`

export const InputStates = {
  disabled: css`
    opacity: 0.5;
    cursor: default;
  `,
  error: css`
    --focus-ring-color: ${s.color('red.solid')};
    --text-color: ${s.color('red.text')};
    color: ${s.color('red.text')};
  `,
} satisfies s.Variants

const TextFieldVariants = {
  code: css`
    font-family: ${s.var('font.code')};
  `,
  bold: css`
    --weight: bold;
  `,
  bare: css`
    padding: 0;
    height: auto;
  `,
  ghost: css`
    background: transparent;
    transition: background 0.1s linear;
    &:hover,
    &:focus,
    &:focus-within {
      background: ${s.color('gray.element')};
    }
  `,
} satisfies s.Variants
export type TextFieldVariant = keyof typeof TextFieldVariants

export const _variants = s.merge(InputStates, s.SharedVariants.Size.Variants, TextFieldVariants)

export const _InputContainerStyle = css`
  outline-offset: ${s.size('0.1')};
  background: ${s.color('gray.element')};
  border-radius: ${s.var('radii.1')};
  padding: 0 var(--horizontal-padding);
  line-height: 1.2;
  height: calc(1.2em + var(--vertical-padding) * 2);
`
export const _InputContainer = s.styledWithVariants(
  HSpaced,
  '_Input',
  css`
    ${_ResetInputStyles}
    ${_InputContainerStyle}
    input {
      width: 100%;
      font-weight: var(--weight, normal);
      &::placeholder {
        color: ${s.color('secondary')};
      }
    }
  `,
  _variants,
)
