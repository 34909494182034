import React from 'react'

import { ClickableContainer } from './dom/ClickableContainer'
import { isNative } from './isNative'
import { styled } from './styled'
import { HSpaced } from './styled/Block'
import { ListSeparator } from './styled/List'
import { getColor, getSize, size, sizeToNumber } from './theme'
import { Body, FontWeight } from './typography'

export interface ModalNavigationItemProps {
  index?: number
  label?: string | React.ReactElement
  icon?: React.ReactElement
  selected?: boolean
  onClick: () => void
  depth?: number
  disabled?: boolean
  parentSelected?: boolean
  lineHighlighted?: boolean
  hasChildren?: boolean
  lastChild?: boolean
  [key: string]: any
}
export function ModalNavigationItem({
  children,
  onClick,
  index,
  label,
  icon,
  selected,
  depth,
  disabled,
  parentSelected,
  lineHighlighted,
  hasChildren,
  lastChild,
  ...rest
}: React.PropsWithChildren<ModalNavigationItemProps>) {
  return (
    <>
      {isNative && index !== 0 ? <ListSeparator /> : null}
      <ClickableContainer onClick={onClick} disabled={disabled}>
        <_ModalNavigationItem
          space={'@size-xs'}
          depth={depth}
          className={[selected ? 'selected' : '', lineHighlighted ? 'line-highlight' : ''].join(
            ' ',
          )}
          disabled={disabled}
          parentSelected={parentSelected}
          hasChildren={hasChildren}
          lastChild={lastChild}
          {...rest}
        >
          {children ? (
            children
          ) : (
            <>
              {icon ? <div className={'icon'}>{icon}</div> : null}
              <Body weight={FontWeight.SemiBold} useDiv style={{ flex: 1 }}>
                {label}
              </Body>
            </>
          )}
        </_ModalNavigationItem>
      </ClickableContainer>
    </>
  )
}
const _ModalNavigationItem = styled(HSpaced)<{
  depth?: number
  disabled?: boolean
  parentSelected?: boolean
  hasChildren?: boolean
  lastChild?: boolean
}>`
  transition:
    background 0.1s linear,
    color 0.1s linear,
    opacity 0.1s linear;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${props => getColor(props.theme, '@gray-5')};
  border-radius: ${size('@border-radius-base')};
  margin: 0 calc(${size('@size-m')} / 2);
  padding: ${props => getSize(props.theme, '@size-xs')} calc(${size('@size-m')} / 2);
  ${props => (props.depth ? '&' : '&.noop')} {
    padding-left: ${props => {
      const left = sizeToNumber(getSize(props.theme, '@size-m')) / 2
      const icon =
        sizeToNumber(getSize(props.theme, '@size-m')) +
        sizeToNumber(getSize(props.theme, '@size-xs'))
      const extra = Math.max(0, sizeToNumber(getSize(props.theme, '@size-s')) * (props.depth! - 1))
      return left + icon + extra
    }}px;
  }
  &,
  &::after {
    background: ${props =>
      props.depth
        ? getColor(props.theme, props.parentSelected ? '@gray-2' : '@gray-1')
        : getColor(props.theme, '@gray-1')};
  }
  > .icon {
    transition:
      color 0.1s linear,
      background 0.1s linear;
    color: ${props => props.theme['@gray-5']};
    font-size: ${props => props.theme['@size-s']};
    background: ${props => props.theme['@gray-2']};
    border-radius: ${props => props.theme['@border-radius-base']};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.theme['@size-m']};
    height: ${props => props.theme['@size-m']};
    z-index: 2;
  }
  &:hover,
  &.selected {
    background: ${props => props.theme['@gray-2']};
    color: ${props => props.theme['@primary-color']};
    > .icon {
      color: ${props => props.theme['@gray-0']};
      background: ${props => props.theme['@primary-color']};
    }
  }
  ${props => (props.hasChildren ? '&' : '&:noop')} {
    position: relative;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: calc(${props => props.theme['@size-m']} - 1px);
      width: 2px;
      background: ${props => getColor(props.theme, '@gray-2')};
      transition: background 0.1s linear;
    }
    &.selected::before,
    &:hover::before {
      background: ${props => getColor(props.theme, '@primary-color')};
    }
    &.selected {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  ${props => (props.depth ? '&' : '&.noop')} {
    position: relative;
    ${props => (props.lastChild ? '&.selected' : '&.noop')} {
      border-radius: 0;
      border-bottom-left-radius: ${size('@border-radius-base')};
      border-bottom-right-radius: ${size('@border-radius-base')};
    }
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      bottom: ${props => (props.lastChild ? '50%' : 0)};
      left: calc(${props => props.theme['@size-m']} - 1px);
      width: 2px;
      background: ${props => getColor(props.theme, props.parentSelected ? '@gray-3' : '@gray-2')};
      transition: background 0.1s linear;
    }
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 50%;
      left: calc(${props => props.theme['@size-m']} - ${props => props.theme['@size-xs']} / 2);
      margin-top: calc(${props => props.theme['@size-xs']} / -2);
      width: ${props => props.theme['@size-xs']};
      height: ${props => props.theme['@size-xs']};
      border-radius: 2px;
      transform: rotate(45deg);
      border: solid 2px
        ${props => getColor(props.theme, props.parentSelected ? '@gray-3' : '@gray-2')};
      transition:
        border 0.1s linear,
        background 0.1s linear;
    }
    &.selected::before,
    &:hover::before,
    &.line-highlight::before {
      background: ${props => props.theme['@primary-color']};
    }
    &.selected::after,
    &:hover::after,
    &.line-highlight::after {
      border-color: ${props => props.theme['@primary-color']};
    }
  }
`
