import { OptionsConfig } from '@thesisedu/feature-assignments-core'
import { Markdown } from '@thesisedu/web'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { MultipleChoiceAnswerView } from '../MultipleChoiceAnswerView'
import { QuestionExtra } from '../QuestionExtra'

export function CheckboxSelectAnswer({ question, answerView }: QuestionProps<OptionsConfig>) {
  const answer = useSubmissionData(question.id)
  const expansion = useSubmissionData(`${question.id}-expansion`)
  return (
    <>
      <MultipleChoiceAnswerView
        expansion={expansion}
        questionId={question.id}
        selectedAnswers={answer}
        options={question.config?.options || []}
        usesExpansion={question.config?.requireExpansion}
        hideCorrectAnswer={
          typeof answerView === 'object' ? answerView.hideCorrectAnswer : undefined
        }
      />
      {question.config?.explanation &&
      typeof answerView === 'object' &&
      answerView.showExplanation ? (
        <QuestionExtra title={'Explanation'}>
          <Markdown children={question.config.explanation} skipHtml />
        </QuestionExtra>
      ) : null}
    </>
  )
}
