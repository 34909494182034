import { PageHead } from '@thesisedu/web'
import { Skeleton } from 'antd'
import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { CourseEditorHeader } from './CourseEditorHeader'
import { getOnCreateSegment } from './onCreateSegment'
import { getOnDeleteSegment } from './onDeleteSegment'
import { getOnSegmentChange } from './onSegmentChange'
import { useCreateOrUpdateSegmentMutation } from './useCreateOrUpdateSegmentMutation'
import { useDeleteSegmentMutation } from './useDeleteSegmentMutation'
import { CourseEditorProvider } from '../contexts/CourseEditorContext'
import { SegmentDaysContextProvider } from '../contexts/DaysContext'
import { SegmentContextProvider } from '../contexts/SegmentContext'
import { SegmentType, useCourseVersionQuery } from '../schema'
import { SegmentContainer } from '../segment/SegmentContainer'

export interface CourseVersionEditorProps {
  courseVersionId: string
}
export const CourseVersionEditor: React.FC<React.PropsWithChildren<CourseVersionEditorProps>> = ({
  courseVersionId,
}) => {
  const {
    data,
    loading: queryLoading,
    error: queryError,
  } = useCourseVersionQuery({
    variables: { id: courseVersionId },
    fetchPolicy: 'cache-and-network',
  })
  const [errorSaving, setErrorSaving] = useState<boolean>(false)
  const onCompleted = () => setErrorSaving(false)
  const onError = (error: any) => {
    console.error('error modifying segments')
    console.error(error)
    setErrorSaving(true)
  }
  const [deleteSegment, deleteMutation] = useDeleteSegmentMutation(
    { onCompleted, onError },
    courseVersionId,
  )
  const [createOrUpdateSegment, createMutation] = useCreateOrUpdateSegmentMutation(
    { onCompleted, onError },
    courseVersionId,
  )
  const loading = deleteMutation.loading || createMutation.loading
  const error = !!queryError || errorSaving
  const version = data?.node?.__typename === 'CourseVersion' ? data.node : undefined
  if (queryLoading && !version) {
    return <Skeleton active />
  } else if (version) {
    const configuration = version.configuration
    const rootSegment = {
      id: version.rootSegmentId,
      name: `${version.name} - Root`,
      type: SegmentType.Root,
      config: {},
      childSegments: configuration.segments,
    }
    const onCreateSegment = getOnCreateSegment(rootSegment, createOrUpdateSegment, courseVersionId)
    const onSegmentChange = getOnSegmentChange(courseVersionId, createOrUpdateSegment)
    const onDeleteSegment = getOnDeleteSegment(courseVersionId, deleteSegment)

    return (
      <SegmentContextProvider courseConfiguration={configuration}>
        <SegmentDaysContextProvider courseConfiguration={configuration}>
          <CourseEditorProvider
            status={{
              enableReferences: true,
            }}
            read={{
              courseVersionId,
              courseConfiguration: configuration,
              rootSegment,
            }}
            write={{
              onSegmentChange,
              onCreateSegment,
              onDeleteSegment,
            }}
          >
            <PageHead title={`Editing ${version.name}`} />
            <CourseEditorHeader loading={loading} error={error} />
            <SegmentContainer segments={configuration.segments || []} id={courseVersionId} />
          </CourseEditorProvider>
        </SegmentDaysContextProvider>
      </SegmentContextProvider>
    )
  } else {
    return <Navigate to={'/404'} />
  }
}

export default CourseVersionEditor
