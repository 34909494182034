import React from 'react'

import { DimensionOptionsButton } from './DimensionOptionsButton'
import { LargeConfigurationButton } from './LargeConfigurationButton'
import { ReportDimensionResource } from '../types'

export interface LargeDimensionButtonProps<Options> {
  dimension: ReportDimensionResource<object, object>
  shortDescription?: string
  options?: Options
  context?: object
  selected: boolean
  onChange: (options: Options, context: object, overrideIdentifier?: string) => void
}
export function LargeDimensionButton({
  dimension,
  options,
  context,
  shortDescription,
  selected,
  onChange,
}: LargeDimensionButtonProps<object>) {
  const hasConfiguration = !!dimension.renderOptionsLabel && !!dimension.renderOptionsWrapper
  return (
    <LargeConfigurationButton
      title={dimension.title}
      description={dimension.description}
      shortDescription={shortDescription}
      icon={dimension.icon}
      selected={selected}
      children={
        hasConfiguration ? (
          <DimensionOptionsButton
            dimension={dimension}
            options={options}
            context={context}
            onChange={onChange}
          />
        ) : undefined
      }
      onClick={() => {
        if (!hasConfiguration && onChange) {
          onChange({}, {})
        }
      }}
    />
  )
}
