import { styled, s, Dropdown, Tooltip } from '@thesisedu/ui'
import { Settings } from '@thesisedu/ui/icons'
import { Level } from 'hls.js'
import React from 'react'

import { Reset } from '../../Reset'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

function getLevelFpsLabel(level: Level) {
  const attributes = level.attrs as any // Types are wrong.
  return attributes['FRAME-RATE'] === '30' || !attributes['FRAME-RATE']
    ? ''
    : attributes['FRAME-RATE']
}

export interface QualitySelectorProps extends PlayerFooterProps {
  levels: Level[]
  currentLevel?: number
  onChange: (level: number) => void
}
export function QualitySelector({ levels, currentLevel, onChange, compact }: QualitySelectorProps) {
  const currentLevelObject = currentLevel !== undefined ? levels[currentLevel] : undefined
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <Tooltip title={'Video Quality'}>
          <ControlContainer compact={!!compact} padded>
            {currentLevelObject ? (
              <Container>
                <span>{currentLevelObject.height}p</span>
              </Container>
            ) : (
              <Settings />
            )}
          </ControlContainer>
        </Tooltip>
      </Dropdown.ManualTrigger>
      <Dropdown.Menu side={'top'} align={'end'}>
        {[...levels].reverse().map((level, index) => {
          const value = (levels.length - 1 - index).toString()
          return (
            <Dropdown.Item.Checkbox
              checked={currentLevel?.toString() === value}
              key={(levels.length - 1 - index).toString()}
              onCheckedChange={checked => {
                if (checked) {
                  onChange(parseInt(value, 10))
                }
              }}
            >
              {level.height}p{getLevelFpsLabel(level)}
            </Dropdown.Item.Checkbox>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}

const Container = styled(Reset.Button)`
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 1em;
  white-space: nowrap;
  > span {
    display: block;
    font-size: ${s.size('font.xs')};
    color: white;
    text-align: center;
    padding: ${s.size('xxs')};
    border: solid 1px white;
    border-radius: 2px;
    line-height: 1;
  }
`
