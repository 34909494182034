import { HomeOutlined, PlusOutlined } from '@ant-design/icons'
import {
  ClassContextProvider,
  ClassSelectorModalContextProvider,
  ClassSelectorText,
  NO_CLASS_OPTION,
  SelectedClassSync,
  useClasses,
  useSelectedClassId,
} from '@thesisedu/feature-classes-react'
import { JoinClassModal } from '@thesisedu/feature-classes-react/dist/JoinClassModal'
import { CourseAssignmentsContextProvider } from '@thesisedu/feature-course-assignments-react'
import {
  CourseBasicClassManager,
  ClassDraggable,
  CoursesContextProvider,
  CourseViewerModal,
  useClassCourseQuery,
} from '@thesisedu/feature-courses-react'
import { useCourseViewerModalContext } from '@thesisedu/feature-courses-react/dist/contexts/CourseViewerModalContext'
import { UploadMiniStatus } from '@thesisedu/feature-media-react'
import { useProtection } from '@thesisedu/feature-users-web'
import { FeatureWeb, WebRoutesHook, NotFoundPage } from '@thesisedu/feature-web'
import { Body, ScrollableContainerProvider } from '@thesisedu/react'
import {
  AntIconWrapper,
  Block,
  BlockSpin,
  NotFound,
  PageHead,
  PageHeader,
  styled,
  Transition,
  useBreadcrumb,
} from '@thesisedu/web'
import { Button, Tooltip } from 'antd'
import { LogOut } from 'iconoir-react'
import React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { keyframes, createGlobalStyle } from 'styled-components'

import { DefaultContent } from './DefaultContent'
import { StudentHome } from './Home'
import { Messages } from './Messages'
import { Treasures } from './Treasures'
import { ParticleStyle } from './components/Particle'
import { ProdigiesHomepageTransition } from './components/transition'
import { BIG_WIDTH } from './constants'
import { NewStudent } from '../../NewUser'
// @ts-ignore
import clouds from '../../assets/sketch/website/clouds.png'
// @ts-ignore
import content from '../../assets/sketch/website/content.png'
// @ts-ignore
import content2x from '../../assets/sketch/website/content@2x.png'
// @ts-ignore
import torch from '../../assets/sketch/website/fire-light.svg'
// @ts-ignore
import lights from '../../assets/sketch/website/lights.svg'
import { useSyncKeyboardButtonWithClass } from '../../components/keyboard/KeyboardButtonContext'

enum LocationMode {
  Homepage = 'homepage',
  Badges = 'badges',
  Default = 'default',
}
function useLocationMode(): LocationMode {
  const location = useLocation()
  if (location.pathname.includes('/treasures')) {
    return LocationMode.Badges
  } else if (
    location.pathname.startsWith('/student') &&
    location.pathname
      .split('/')
      .map((i: any) => i.trim())
      .filter(Boolean).length === 3
  ) {
    return LocationMode.Homepage
  } else {
    return LocationMode.Default
  }
}

function StudentFindClass() {
  const { classes, loading } = useClasses()
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!loading && classes.length === 1) {
      navigate(`classes/${classes[0].id}`)
    } else if (!loading && classes.length > 1) {
      navigate('classes')
    } else if (!loading && !classes.length) {
      navigate('classes')
    }
  }, [classes.length, loading])

  if (loading) {
    return <BlockSpin />
  } else if (classes.length) {
    return null
  } else {
    return <p>No classes.</p>
  }
}

function _Student() {
  return (
    <Routes>
      <Route path={'classes/:classId/*'} element={<StudentClass />} />
      <Route path={'classes'} element={<SelectClass />} />
      <Route path={'messages'} element={<Messages />} />
      <Route path={'/'} element={<StudentFindClass />} />
      <Route path={'*'} element={<NotFoundPage />} />
    </Routes>
  )
}

export function Student() {
  useBreadcrumb({ title: <HomeOutlined /> })
  useProtection({ allowedGroups: ['STUDENT'], redirect: '/' })
  return (
    <ClassContextProvider>
      <ClassSelectorModalContextProvider>
        <ParticleStyle />
        <_Student />
      </ClassSelectorModalContextProvider>
    </ClassContextProvider>
  )
}

function SelectClass() {
  const [joinVisible, setJoinVisible] = React.useState(false)
  const navigate = useNavigate()
  return (
    <BasicContainer>
      <JoinClassModal visible={joinVisible} onClose={() => setJoinVisible(false)} />
      <PageHead title={'Select a Class'} />
      <Block marginBottom={'@size-l'}>
        <PageHeader title={'Select a Class'}>
          <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setJoinVisible(true)}>
            Join Class
          </Button>
          <Tooltip title={'Logout'} mouseEnterDelay={1}>
            <Button
              icon={
                <AntIconWrapper>
                  <LogOut />
                </AntIconWrapper>
              }
              onClick={() => navigate('/auth/logout')}
            />
          </Tooltip>
        </PageHeader>
      </Block>
      <CourseBasicClassManager
        emptyContent={<NewStudent />}
        renderDraggable={props => <ClassDraggable {...props} readOnly />}
      />
    </BasicContainer>
  )
}

function ClassName() {
  return (
    <Body color={'@primary-color'} style={{ display: 'inline' }}>
      <ClassSelectorText />
    </Body>
  )
}

function StudentClass() {
  useBreadcrumb({ title: <ClassName /> })
  useSyncKeyboardButtonWithClass()
  const selectedClassId = useSelectedClassId(true)
  const { data, loading } = useClassCourseQuery({
    variables: { id: selectedClassId || '' },
    skip: !selectedClassId || selectedClassId === NO_CLASS_OPTION,
  })
  const contentContainerRef = React.useRef<HTMLDivElement>(null)
  const cls = data?.node?.__typename === 'Class' ? data.node : undefined
  const mode = useLocationMode()
  const { segmentId: courseViewerModalSegmentId } = useCourseViewerModalContext(false) || {}
  const [dMode, setDMode] = React.useState(mode)
  React.useEffect(() => {
    setTimeout(() => {
      setDMode(mode)
    }, 1)
  }, [mode])
  let content: React.ReactElement | null
  if (loading) {
    content = <BlockSpin />
  } else if (cls) {
    const classes: string[] = [`mode-${mode}`]
    if (courseViewerModalSegmentId) {
      // For performance reasons, let's disable all animations when the course viewer is visible.
      classes.push('no-animations')
    }
    content = (
      <CoursesContextProvider class={cls}>
        <CourseAssignmentsContextProvider>
          <CourseViewerModal />
          <AnimationContainer className={classes.join(' ')}>
            <BackgroundContainer>
              <BackgroundContents>
                <BackgroundGradient />
                <Clouds>
                  <img src={clouds} alt={''} />
                </Clouds>
                <ForegroundContainer />
                <LightContainer />
                <TorchContainer />
              </BackgroundContents>
              <GlobalStyle />
              <ScrollableContainerProvider targetRef={contentContainerRef}>
                <ContentContainer ref={contentContainerRef}>
                  <MiniStatusContainer>
                    <UploadMiniStatus />
                  </MiniStatusContainer>
                  <ProdigiesHomepageTransition />
                  <InnerContentContainer
                    style={{ maxWidth: dMode === LocationMode.Default ? BIG_WIDTH : 900 }}
                  >
                    <Transition
                      type={'prodigies-homepage'}
                      state={dMode}
                      timeout={
                        mode === LocationMode.Badges || dMode === LocationMode.Badges ? 4500 : 1000
                      }
                      addEndListener={undefined}
                    >
                      {dMode === LocationMode.Homepage ? (
                        <StudentHome />
                      ) : dMode === LocationMode.Badges ? (
                        <Treasures />
                      ) : (
                        <DefaultContent />
                      )}
                    </Transition>
                  </InnerContentContainer>
                </ContentContainer>
              </ScrollableContainerProvider>
            </BackgroundContainer>
          </AnimationContainer>
        </CourseAssignmentsContextProvider>
      </CoursesContextProvider>
    )
  } else {
    content = <NotFound description={'That class could not be found.'} />
  }

  return (
    <div>
      <SelectedClassSync />
      {content}
    </div>
  )
}

const MIN_WIDTH = 1400
const GlobalStyle = createGlobalStyle`
  html, body {
    position: fixed; // This was the trick to get react-beautiful-dnd not to use this as a scroll target.
    overflow: hidden !important;
    height: 100vh !important;
    width: 100vw !important;
  }
`
const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fff;
`
const BackgroundItem = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
`
const BackgroundContents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
`
const BackgroundGradient = styled(BackgroundItem)`
  background: linear-gradient(#93deff, #ffffff);
  min-width: ${MIN_WIDTH}px;
  width: 100vw;
  height: max(${0.45 * MIN_WIDTH}px, 45vw);
`
const CloudsAnimation = keyframes`
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(10%);
  }
`
const DigitalFlickerAnimation = keyframes`
  0%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 100% {
		opacity: .99;
	}
	20%, 21.999%, 63%, 63.999%, 65%, 69.999% {
		opacity: 0.4;
	}
`
const FireFlickerAnimation = keyframes`
  0%, 10% { opacity: 0.38; }
  15%,20% { opacity: 0.89; }
  22%,23% { opacity: 0.386; }
  25%,35% { opacity: 0.84; }
  39%,42% { opacity: 0.98; }
  44%,47% { opacity: 0.89; }
  49%,50% { opacity: 0.386; }
  52%,54% { opacity: 0.84; }
  57%,58% { opacity: 1; }
  60%,63% { opacity: 0.93; }
  65%,72% { opacity: 0.84; }
  77%,85% { opacity: 0.386; }
  90%,95% { opacity: 0.93; }
  100% { opacity: 0.38; }
`
const Clouds = styled(BackgroundItem)`
  min-width: ${MIN_WIDTH}px;
  width: 100vw;
  top: max(${0.09 * MIN_WIDTH}px, 9vw);
  text-align: center;
  > img {
    height: 480px;
    width: auto;
    margin: 0 auto;
    mix-blend-mode: screen;
    animation: ${CloudsAnimation} 120s ease-in-out infinite;
  }
`
const MainContentImage = styled(BackgroundItem)`
  min-width: ${MIN_WIDTH}px;
  width: calc(100vw + 50px);
  height: max(${1.59 * MIN_WIDTH}px, 159vw);
  background-size: cover;
  margin-left: -5px;
`
const ForegroundContainer = styled(MainContentImage)`
  background-image: image-set(url(${content}) 1x, url(${content2x}) 2x);
`
const LightContainer = styled(MainContentImage)`
  background-image: url(${lights});
`
const TorchContainer = styled(MainContentImage)`
  background-image: url(${torch});
`
const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px ${props => props.theme['@size-m']};
`
const InnerContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const MiniStatusContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-m']};
  right: ${props => props.theme['@size-m']};
`

const TRANSITION_TIME = 5
const AnimationContainer = styled.div`
  ${BackgroundContainer} > ${BackgroundContents} {
    transition: transform ${TRANSITION_TIME - 1}s ease-in-out 0.5s;
    transform: translateY(0);
  }
  &.mode-${LocationMode.Homepage}, &.mode-${LocationMode.Default} {
    ${BackgroundContainer} > ${BackgroundContents} {
      transform: translateY(0);
    }
  }
  &.mode-${LocationMode.Badges} {
    ${BackgroundContainer} > ${BackgroundContents} {
      transform: translateY(calc(-1 * max(${1.59 * MIN_WIDTH}px, 159vw) + 100vh));
    }
    // Disable the cloud animation as well...
    ${Clouds} img {
      animation: none !important;
    }
    // We only want the animations when the badges are visible.
    ${LightContainer} {
      animation: ${DigitalFlickerAnimation} 3s linear infinite;
    }
    ${TorchContainer} {
      animation: ${FireFlickerAnimation} 10s linear infinite;
      animation-delay: 0.5s;
    }
  }
  &.no-animations * {
    animation: none !important;
  }
`
const BasicContainer = styled.div`
  margin: 100px auto;
  width: 100%;
  max-width: 1100px;
  padding: 0 ${props => props.theme['@size-s']};
`

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [
      ...routes.filter(route => route.key !== 'student'),
      <Route key={'student'} path={'student/*'} element={<Student />} />,
    ]
  })
}
