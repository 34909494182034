import { SegmentActionComponentProps, useSegmentNavigate } from '@thesisedu/feature-courses-react'
import { Dropdown } from '@thesisedu/ui'
import { Settings } from '@thesisedu/ui/icons'
import React from 'react'

export function AssignmentSegmentAction({ segment }: SegmentActionComponentProps) {
  const nav = useSegmentNavigate({ id: segment.id, defaultTab: 'settings' })
  return (
    <Dropdown.Item
      icon={<Settings />}
      onClick={() => {
        nav()
      }}
    >
      Assignment Settings
    </Dropdown.Item>
  )
}
