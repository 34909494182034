export interface CollapsibleRange {
  id: string
  length: number
}
export interface CollapsibleGridOpts {
  expandedRowIds: string[]
  rowIds: string[]
  collapsibleRowRanges: CollapsibleRange[]
}
export interface CollapsibleGridResults {
  visibleRows: number
  getRealRowIndex: (visibleIndex: number) => number
}

interface CollapsibleRangeWithRowIds extends CollapsibleRange {
  rowIdsExcludingParent: string[]
}
function populateCollapsedRangesWithIds(
  ranges: CollapsibleRange[],
  rowIds: string[],
): CollapsibleRangeWithRowIds[] {
  return ranges.map(range => {
    const parentIndex = rowIds.indexOf(range.id)
    if (parentIndex === -1) {
      return { ...range, rowIdsExcludingParent: [] }
    } else {
      return {
        ...range,
        rowIdsExcludingParent: rowIds.slice(parentIndex + 1, parentIndex + range.length + 1),
      }
    }
  })
}

export function useCollapsibleGrid({
  expandedRowIds,
  collapsibleRowRanges,
  rowIds,
}: CollapsibleGridOpts): CollapsibleGridResults {
  const collapsedRanges = collapsibleRowRanges.filter(c => !expandedRowIds.includes(c.id))
  const withIds = populateCollapsedRangesWithIds(collapsedRanges, rowIds)
  const hiddenWithIds = withIds.filter(withId => !expandedRowIds.includes(withId.id))
  const visibleRowIds = rowIds.filter(rowId => {
    return !hiddenWithIds.some(withIds => withIds.rowIdsExcludingParent.includes(rowId))
  })

  return {
    visibleRows: visibleRowIds.length,
    getRealRowIndex(visibleIndex) {
      const visibleRowId = visibleRowIds[visibleIndex]
      return rowIds.indexOf(visibleRowId)
    },
  }
}
