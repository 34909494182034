import { mergeRegister } from '@lexical/utils'
import { styled, s } from '@thesisedu/ui'
import {
  $getSelection,
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import React from 'react'

import { TextFormatToolbar } from './TextFormatToolbar'
import { getDOMRangeRect } from '../../utils/getDOMRangeRect'
import { setFloatingElementPosition } from '../../utils/setFloatingElementPosition'

export interface TextFormatFloatingToolbarProps {
  editor: LexicalEditor
  anchorElement: HTMLElement
  activeItems: Record<string, string[] | boolean>
}
export function TextFormatFloatingToolbar({
  editor,
  anchorElement,
  activeItems,
}: TextFormatFloatingToolbarProps) {
  const popupContainerRef = React.useRef<HTMLDivElement>(null)

  // Update the position of the floating toolbar.
  const updateTextFormatFloatingToolbar = React.useCallback(() => {
    const selection = $getSelection()
    const popupContainer = popupContainerRef.current
    const nativeSelection = window.getSelection()
    if (!popupContainer) return

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)
      setFloatingElementPosition(rangeRect, popupContainer, anchorElement)
    }
  }, [editor, anchorElement])

  // Update the position of the overlay when we scroll the page or resize the window.
  React.useEffect(() => {
    const scrollerElement = anchorElement.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener('resize', update)
    if (scrollerElement) {
      scrollerElement.addEventListener('scroll', update)
    }

    return () => {
      window.removeEventListener('resize', update)
      if (scrollerElement) {
        scrollerElement.removeEventListener('scroll', update)
      }
    }
  }, [editor, updateTextFormatFloatingToolbar, anchorElement])

  // Update the position whenever the editor selection changes or the editor state changes.
  React.useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, updateTextFormatFloatingToolbar])

  return (
    <FloatingToolbarContainer ref={popupContainerRef}>
      <TextFormatToolbar editor={editor} activeItems={activeItems} />
    </FloatingToolbarContainer>
  )
}

export const FloatingToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${s.size('1')};
  padding: ${s.size('0.25')};
  border-radius: ${s.var('radii.1')};
  box-shadow: ${s.var('shadow.1')};
  background: ${s.color('gray.background')};
  will-change: transform;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: ${s.var('zIndices.overlays')};
  transition: opacity 0.1s linear;
`
