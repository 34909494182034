import { getNewWeight } from '@thesisedu/feature-utils'
import { Table } from '@thesisedu/ui'
import { Label, List } from '@thesisedu/ui/icons'
import { orderBy } from 'lodash'
import React from 'react'

import { CategoryManagementRow } from './CategoryManagementRow'
import { debug } from '../log'
import { useUpdateAssignmentCategoryMutation } from '../queries/useUpdateAssignmentCategoryMutation'
import { BasicAssignmentCategoryFragment } from '../schema'

export interface CategoryManagementTableProps {
  categories: BasicAssignmentCategoryFragment[]
  loading?: boolean
  readOnly?: boolean
  totalPoints: number
  onSavingChange: (saving: boolean) => void
}
export function CategoryManagementTable({
  categories: _categories,
  loading,
  readOnly,
  totalPoints,
  onSavingChange,
}: CategoryManagementTableProps) {
  const categories = React.useMemo(() => {
    return orderBy(_categories, 'orderWeight', 'asc')
  }, [_categories])
  const [update] = useUpdateAssignmentCategoryMutation()
  const { dragAndDropHooks } = Table.useDragAndDrop({
    getItems: keys =>
      [...keys].map(key => ({
        'text/plain': key.toString(),
      })),
    async onReorder(e) {
      debug('getting new weights from reorder %O', e)
      const newWeights = getNewWeight<BasicAssignmentCategoryFragment, 'orderWeight'>({
        collection: categories,
        draggingIds: [...e.keys].map(k => k.toString()),
        field: 'orderWeight',
        targetId: e.target.key.toString(),
        dropPosition: e.target.dropPosition === 'before' ? 'before' : 'after',
      })
      debug('new weights: %O', newWeights)
      onSavingChange(true)
      try {
        for (const [key, orderWeight] of Object.entries(newWeights ?? {})) {
          const existingCategory = categories.find(cat => cat.id === key)
          if (!existingCategory)
            throw new Error('Cannot find existing assignment category when updating weights.')
          await update({
            variables: {
              input: {
                id: key,
                patch: { orderWeight },
              },
            },
            optimisticResponse: {
              updateAssignmentCategory: {
                __typename: 'AssignmentCategoryPayload',
                assignmentCategory: {
                  __typename: 'AssignmentCategory',
                  ...existingCategory,
                  orderWeight,
                },
              },
            },
          })
        }
      } finally {
        onSavingChange(false)
      }
    },
  })
  return (
    <Table
      loading={loading}
      dragAndDropHooks={dragAndDropHooks}
      aria-label={'Grading Categories'}
      data-testid={'CategoryManagementTable'}
    >
      <Table.Header>
        <Table.Column isRowHeader icon={<Label />} style={{ width: '100%' }}>
          Name
        </Table.Column>
        <Table.Column icon={<List />} style={{ width: 200 }}>
          Weight
        </Table.Column>
        <Table.Column style={{ width: 100 }} />
      </Table.Header>
      <Table.Body items={categories}>
        {item => {
          return (
            <CategoryManagementRow
              key={item.id}
              category={item}
              readOnly={readOnly}
              totalPoints={totalPoints}
            />
          )
        }}
      </Table.Body>
    </Table>
  )
}
