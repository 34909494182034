import { MutateHook, FeatureResource } from '@thesisedu/feature'
import { InfiniteQueryProps } from '@thesisedu/feature-apollo-react'
import { DocumentNode } from 'graphql'

import { SearchFilterInput, OrderDirection, SearchQueryVariables } from './schema'

export type SearchFragmentsPayload = (string | DocumentNode)[]
export type SearchFragmentsMutateHook = MutateHook<
  'feature-search:fragments',
  SearchFragmentsPayload,
  undefined
>

export function infiniteQueryProps(
  searchDocument?: CustomSearchDocument,
): Partial<InfiniteQueryProps<any, any, SearchQueryVariables>> {
  return searchDocument
    ? {
        document: searchDocument.document,
        resultPath: searchDocument.resultPath,
      }
    : {}
}

export interface SearchFilters extends SearchFilterInput {
  [key: string]: any
}
export interface CustomSearchDocument {
  document: DocumentNode
  resultPath: string
}
export type CommonSearchProps<Variables extends SearchQueryVariables = SearchQueryVariables> = {
  query?: string
  filters?: SearchFilters
  orderBy?: string
  orderDirection?: OrderDirection
  searchDocument?: CustomSearchDocument
  // TODO: Make this required if the type requires it.
  variables?: Omit<Variables, keyof SearchQueryVariables>
  queryOpts?: InfiniteQueryProps<any, any, any>['queryOpts']
}

export type RenderGrid<Node extends SearchResultNode> = (
  props: RenderSearchItemProps<Node>,
) => React.ReactElement | null
export type RenderList<Node extends SearchResultNode> = (
  props: RenderSearchListItemProps<Node>,
) => React.ReactElement | null
export type RenderCalendar<Node extends SearchResultNode> = (
  props: RenderSearchCalendarItemProps<Node>,
) => React.ReactElement | null
export interface SearchResultResource<Node extends SearchResultNode> extends FeatureResource {
  type: 'SearchResult'
  identifier: string
  renderList?: RenderList<Node>
  renderGrid?: RenderGrid<Node>
  renderGridHeight?: (width: number, props: RenderSearchItemProps<Node>) => number
  renderCalendar?: RenderCalendar<Node>
  /** When this result is clicked, where do we go? */
  getUniversalLink?: (item: Node) => string | null
  getDate?: (item: Node) => string | null | undefined
  /** The search fragment. */
  fragment: DocumentNode
  fragmentName: string
}
export type ResourceRenderMode = 'renderList' | 'renderGrid' | 'renderCalendar'

export interface SearchResultNode {
  __typename: string
  id: string
  [key: string]: any
}
export interface RenderSearchItemProps<Node extends SearchResultNode = SearchResultNode> {
  item: Node
}
export interface RenderSearchListItemProps<Node extends SearchResultNode = SearchResultNode>
  extends RenderSearchItemProps<Node> {
  onClick: () => Promise<void> | void
}
export interface RenderSearchCalendarItemProps<Node extends SearchResultNode = SearchResultNode>
  extends RenderSearchItemProps<Node> {
  onClick: () => Promise<void> | void
}
