import { Button$, Dropdown } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import React from 'react'

import { useCopySegmentToClasses } from '../resources/segment_actions/useCopySegmentToClasses'

export interface CopySettingsButtonProps extends Partial<Button$.ButtonProps> {}
export function CopySettingsButton(rest: CopySettingsButtonProps) {
  const { copy, otherClasses, disabled, progressModal } = useCopySegmentToClasses()
  if (otherClasses.length) {
    return (
      <>
        {progressModal}
        <Dropdown.Container>
          <Dropdown.Button
            disabled={disabled}
            {...rest}
            icon={<Copy />}
            children={'Copy Content'}
          />
          <Dropdown.Menu side={'bottom'} align={'end'}>
            {otherClasses.map(cls => (
              <Dropdown.Item
                key={cls.id}
                onClick={() => copy(undefined, [cls.id])}
                children={cls.name}
              />
            ))}
            <Dropdown.Item.Separator />
            <Dropdown.Item
              onClick={() => {
                copy()
              }}
              children={'All Classes'}
            />
          </Dropdown.Menu>
        </Dropdown.Container>
      </>
    )
  } else {
    return null
  }
}
