import {
  OrderDirection,
  TagFragment,
  useSearchTagsQuery,
} from '@thesisedu/feature-tags-react/dist/schema'
import { BodyLarge, BodySmall, Empty, Link, Skeleton, SkeletonText } from '@thesisedu/react'
import { Block, Body, styled, Tooltip } from '@thesisedu/web'
import { IconView } from '@thesisedu/web/dist/IconSelect'
import { NavArrowRight } from 'iconoir-react'
import React from 'react'

export interface NewExploreTagsWidgetProps {
  title: string
  type: string
  getPath: (tag: TagFragment) => string
}
export function NewExploreTagsWidget({ title, type, getPath }: NewExploreTagsWidgetProps) {
  const { data, loading } = useSearchTagsQuery({
    variables: {
      first: 100,
      types: [type],
      orderBy: 'name',
      orderDirection: OrderDirection.Asc,
    },
  })
  const tags = data?.tags.edges.map(edge => edge.node) || []

  return (
    <>
      <Block marginBottom={'@size-s'} marginLeft={'@size-xs'} marginRight={'@size-xs'}>
        <Body color={'@text-color-secondary'}>{title}</Body>
      </Block>
      {tags.length || loading ? (
        <GridContainer>
          {loading
            ? new Array(24).fill('').map((_, index) => <TagListItemLoading key={index} />)
            : tags.map(tag => <TagListItem tag={tag} key={tag.id} getPath={getPath} />)}
        </GridContainer>
      ) : (
        <Empty description={'There are no tags available.'} />
      )}
    </>
  )
}

interface TagListItemProps {
  tag: TagFragment
  getPath: NewExploreTagsWidgetProps['getPath']
}
function TagListItem({ tag, getPath }: TagListItemProps) {
  const inLicense = !!(tag as any).inLicense

  const content = (
    <ListItemContainer inLicense={inLicense}>
      <ListItemIconContainer>
        <IconView icon={tag.config?.icon || 'Label'} />
      </ListItemIconContainer>
      <ListItemTextContainer>
        <BodyLarge>{tag.name}</BodyLarge>
      </ListItemTextContainer>
      <BodySmall color={'@text-color-secondary'}>
        <NavArrowRight />
      </BodySmall>
    </ListItemContainer>
  )

  if (inLicense) {
    return <Link to={getPath(tag)} children={content} />
  } else {
    return <Tooltip children={content} title={'This content is not in your license.'} />
  }
}

function TagListItemLoading() {
  return (
    <ListItemContainer style={{ pointerEvents: 'none' }} inLicense>
      <ListItemIconContainer>
        <Skeleton fill />
      </ListItemIconContainer>
      <ListItemTextContainer>
        <SkeletonText as={BodyLarge} />
      </ListItemTextContainer>
      <BodySmall color={'@text-color-secondary'}>
        <NavArrowRight />
      </BodySmall>
    </ListItemContainer>
  )
}

const ListItemTextContainer = styled.div`
  margin-left: ${props => props.theme['@size-s']};
  margin-right: ${props => props.theme['@size-xs']};
  flex: 1;
`
const ListItemIconContainer = styled.div`
  background: ${props => props.theme['@gray-4']};
  border-radius: ${props => props.theme['@border-radius-base']};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.theme['@size-l']};
  height: ${props => props.theme['@size-l']};
  * {
    color: white !important;
  }
  svg {
    width: ${props => props.theme['@size-m']};
    height: ${props => props.theme['@size-m']};
  }
`
const ListItemContainer = styled.div<{ inLicense: boolean }>`
  background: transparent;
  padding: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
  * {
    color: ${props => props.theme['@gray-5']};
    transition: color 0.1s linear;
  }
  ${props => (props.inLicense ? '&' : '&.noop')} {
    &:hover {
      background: ${props => props.theme['@gray-1']};
      * {
        color: ${props => props.theme['@gray-7']};
      }
    }
  }
  ${props => (props.inLicense ? '&.noop' : '&')} {
    opacity: 0.5;
  }
`
const GridContainer = styled.div`
  display: grid;
  gap: 0 ${props => props.theme['@size-l']};
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
`
