import {
  BodySmaller as _BodySmaller,
  BodySmall as _BodySmall,
  Body as _Body,
  BodyLarge as _BodyLarge,
  BodyLarger as _BodyLarger,
  BodyCapital as _BodyCapital,
  BodySmallTransparent as _BodySmallTransparent,
  H1 as _H1,
  H2 as _H2,
  H3 as _H3,
  H1Subtitle as _H1Subtitle,
  H2Subtitle as _H2Subtitle,
  H3Alternate as _H3Alternate,
  Color as _Color,
  TypographyProps,
  styled,
  StyledComponent,
} from '@thesisedu/web'
import React from 'react'

export enum FontWeight {
  Light = 300,
  Normal = 400,
  SemiBold = 500,
  Bold = 600,
  ExtraBold = 800,
}

interface NativeTypographyProps {
  lineHeight?: number
}
type Wrapped = React.FC<React.PropsWithChildren<TypographyProps & NativeTypographyProps>>

/** @deprecated */
export const BodyExtraSmall = _BodySmaller as Wrapped
/** @deprecated */
export const BodySmaller = _BodySmaller as Wrapped
/** @deprecated */
export const BodySmall = _BodySmall as Wrapped
/** @deprecated */
export const Body = _Body as Wrapped
/** @deprecated */
export const BodyLarge = _BodyLarge as Wrapped
/** @deprecated */
export const BodyLarger = _BodyLarger as Wrapped
/** @deprecated */
export const BodyCapital = _BodyCapital as Wrapped
/** @deprecated */
export const BodySmallTransparent = _BodySmallTransparent as Wrapped
/** @deprecated */
export const H1 = _H1 as Wrapped
/** @deprecated */
export const H2 = _H2 as Wrapped
/** @deprecated */
export const H3 = _H3 as Wrapped
/** @deprecated */
export const H1Subtitle = _H1Subtitle as Wrapped
/** @deprecated */
export const H2Subtitle = _H2Subtitle as Wrapped
/** @deprecated */
export const H3Alternate = _H3Alternate as Wrapped
/** @deprecated */
export const Color = _Color as Wrapped

/** @deprecated */
export const Text: StyledComponent<'span'> = styled.span``
/** @deprecated */
export const Bold: StyledComponent<'strong'> = styled.strong``

export { TypographyProps } from '@thesisedu/web'
