import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { Block, Body, BlockSpin } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'
import { useDebounce } from 'use-debounce'

import { ExploreListItem } from './ExploreListItem'
import { ExploreWidget } from './ExploreWidget'
import { OrderDirection, useListCoursesQuery } from '../schema'

const SEARCH_TIMEOUT = 1000
export function ExploreCoursesWidget() {
  const [nameFilter, setNameFilter] = React.useState('')
  const [dNameFilter] = useDebounce(nameFilter, SEARCH_TIMEOUT)
  const { data, loading } = useListCoursesQuery({
    variables: {
      name: dNameFilter ? dNameFilter : undefined,
      orderBy: 'name',
      orderDirection: OrderDirection.Asc,
      isByoc: false,
    },
  })
  const courses = data?.courses.edges.map(edge => edge.node) || []
  let content
  if (loading) {
    content = <BlockSpin />
  } else if (courses.length) {
    content = (
      <>
        {courses.map(course => {
          const { filename } = getFileInfo(course.basicConfiguration.icon)
          return (
            <ExploreListItem
              key={course.id}
              title={course.label || course.name}
              path={`courses/${course.id}`}
              image={
                filename
                  ? {
                      alt: course.name,
                      url: filename,
                    }
                  : undefined
              }
            />
          )
        })}
        <Block marginTop={'@size-m'} marginBottom={'@size-m'} style={{ textAlign: 'center' }}>
          <Body>
            <a href={'/teacher/resources'} target={'_blank'}>
              Get More Courses
            </a>
          </Body>
        </Block>
      </>
    )
  } else {
    content = (
      <Empty
        description={'There are no courses in your license.'}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )
  }
  return (
    <ExploreWidget
      title={'Courses in your License'}
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      placeholder={'Find a course...'}
      children={content}
    />
  )
}
