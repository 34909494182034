import { Random } from '@thesisedu/feature-utils'
import { Button } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import { orderBy } from 'lodash'
import React from 'react'

import { CriterionPresetEditor } from './CriterionPresetEditor'
import { AssignmentRubricPresetInput } from '../schema'

export interface CriterionPresetsEditorProps {
  value?: AssignmentRubricPresetInput[]
  onChange: (value: AssignmentRubricPresetInput[]) => void
  totalPoints: number
  style?: React.CSSProperties
}
export function CriterionPresetsEditor({
  value: _value = [],
  onChange,
  totalPoints,
  style,
}: CriterionPresetsEditorProps) {
  const value = orderBy(_value, 'weight', 'asc')
  const addScore = (index: number) => {
    const presets = [...value]
    presets.splice(index, 0, {
      id: Random.id(),
      name: 'Score',
      points: 0,
      weight: 1,
    })
    // Re-calculate the weight field based on the index.
    onChange(presets.map((preset, weight) => ({ ...preset, weight })))
  }
  return (
    <>
      {value.map((preset, index) => {
        return (
          <CriterionPresetEditor
            key={preset.id}
            value={preset}
            totalPoints={totalPoints}
            onChange={updatedPreset => {
              onChange(value.map(p => (p.id === updatedPreset.id ? updatedPreset : p)))
            }}
            onDelete={() => {
              onChange(value.filter(p => p.id !== preset.id))
            }}
            onAdd={delta => {
              addScore(index + 1 + delta)
            }}
          />
        )
      })}
      {value.length === 0 ? (
        <Button
          icon={<Plus />}
          data-testid={'AddScore'}
          children={'Add Score'}
          onPress={() => addScore(0)}
          size={'small'}
          style={{ alignSelf: 'center', margin: '0 auto' }}
        />
      ) : null}
    </>
  )
}
