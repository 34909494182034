import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useModifiedQueryDocument } from '@thesisedu/feature-apollo-react/apollo'

import { debug } from '../log'
import {
  GradeAssignmentSubmissionRefetchDocument,
  useGradeAssignmentSubmissionMutation as useMutation,
} from '../schema'

export const useGradeAssignmentSubmissionMutation = (
  opts?: Parameters<typeof useMutation>[0],
): [
  (
    opts: Parameters<ReturnType<typeof useMutation>[0]>[0] & { refetch?: boolean },
  ) => ReturnType<ReturnType<typeof useMutation>[0]>,
  ReturnType<typeof useMutation>[1],
] => {
  const refetchDoc = useModifiedQueryDocument(GradeAssignmentSubmissionRefetchDocument)
  const [mutate, result] = useMutation({
    onError: onMutationError('There was an error grading that submission.'),
    ...opts,
  })

  return [
    async ({ refetch, ...innerOpts }) => {
      return mutate({
        ...innerOpts,
        refetchQueries(result) {
          const submission = result.data?.gradeAssignmentSubmission.assignmentSubmission
          if (submission && refetch) {
            const { assignmentId, classId } = submission
            debug(
              'refetching assignment submissions for assignment %s and class %s because refetch was specified',
              assignmentId,
              classId,
            )
            return [
              {
                query: refetchDoc,
                variables: { classId, assignmentId },
              },
            ]
          } else return []
        },
        awaitRefetchQueries: true,
      })
    },
    result,
  ]
}
