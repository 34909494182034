import { FileField } from '@thesisedu/feature-attachments-react'
import { StateSelect } from '@thesisedu/feature-districts-web'
import { useResource } from '@thesisedu/feature-react'
import { Row, ColorSelect } from '@thesisedu/web'
import { Col, Checkbox, Form, Input, InputNumber } from 'antd'
import { FormInstance, FormProps } from 'antd/es/form'
import React from 'react'

import { CourseConfigurationFieldResource } from '../types'

export interface CourseFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
}
export function CourseForm({ form, onFinish }: CourseFormProps) {
  const fields = useResource<CourseConfigurationFieldResource>('CourseConfigurationField')
  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            name={'name'}
            label={'Name'}
            rules={[{ required: true, message: 'A course name is required.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'label'}
            label={'Label'}
            extra={'A label, if provided, is only displayed to teachers and students.'}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={'shortCode'}
            label={'Short Code'}
            extra={'A short version of the course. For a Marketing course, this might be "MKT"'}
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (value?.toString().includes(' ')) {
                    callback('The short code cannot contain a space.')
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            <Input placeholder={'ABC'} />
          </Form.Item>
          <Form.Item
            label={'Callout Text'}
            name={['configuration', 'calloutText']}
            extra={
              <>
                This will appear to teachers inside the explore tab, and courses with callout text
                are displayed before other courses. <strong>Keep this short.</strong>
              </>
            }
          >
            <Input placeholder={'Customized for Arizona'} />
          </Form.Item>
          <Form.Item
            name={'state'}
            label={'State'}
            extra={
              <span>
                If you would like this course to automatically be associated with certain sites,
                select the state here. If a state is selected, sites matching this state will
                automatically be associated with this course.
              </span>
            }
          >
            <StateSelect allOption />
          </Form.Item>
          <Form.Item label={'Color'} name={['configuration', 'color']}>
            <ColorSelect />
          </Form.Item>
          <Form.Item label={'Icon'} name={['configuration', 'icon']}>
            <FileField uploadProps={{ accept: 'image/svg+xml', isPublic: true }} />
          </Form.Item>
          <Form.Item label={'Short Description'} name={['configuration', 'shortDescription']}>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
          <Form.Item
            label={'Long Description'}
            name={['configuration', 'longDescription']}
            extra={'Markdown is supported.'}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={'Display on Homepage'}
            name={['configuration', 'homepage']}
            valuePropName={'checked'}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item label={'Hidden'} name={'isHidden'} valuePropName={'checked'}>
            <Checkbox>Hide this course from teachers.</Checkbox>
          </Form.Item>
          <Form.Item label={'BYOC'} name={'isByoc'} valuePropName={'checked'}>
            <Checkbox>This course is a build-your-own course.</Checkbox>
          </Form.Item>
          <Form.Item
            label={'Include in Trials'}
            name={['configuration', 'includeInTrials']}
            valuePropName={'checked'}
          >
            <Checkbox>Include in trials for teachers.</Checkbox>
          </Form.Item>
          <Form.Item
            label={'Enable all Content'}
            name={['configuration', 'enableByDefault']}
            valuePropName={'checked'}
          >
            <Checkbox>Enable all content in this course by default.</Checkbox>
          </Form.Item>
          <Form.Item
            label={'Weight'}
            extra={'This only applies to the homepage.'}
            name={['configuration', 'weight']}
            initialValue={0}
          >
            <InputNumber />
          </Form.Item>
          {fields.map(field => {
            const Component = field.render
            return <Component key={field.identifier} />
          })}
        </Col>
      </Row>
    </Form>
  )
}
