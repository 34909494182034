import * as Slider$ from '@radix-ui/react-slider'
import React from 'react'

import * as Elements from './elements'
import { s } from '../..'

export interface SliderProps extends Slider$.SliderProps {
  className?: string
  variant?: SliderVariant[] | SliderVariant
  disabled?: boolean
  size?: SliderSize
}
export function Slider({
  className,
  variant,
  size = 'medium',
  min = 0,
  max = 100,
  value,
  ...props
}: SliderProps) {
  const filteredValue = Array.isArray(value)
    ? value.map(v => Math.max(min, Math.min(max, v)))
    : value != null
    ? Math.max(min, Math.min(max, value))
    : undefined
  return (
    <_Root
      {...props}
      min={min}
      max={max}
      value={filteredValue}
      className={s.variants(className, {}, variant, size)}
    >
      <Elements.Track>
        <Elements.Range />
      </Elements.Track>
      <Elements.Thumb />
    </_Root>
  )
}

const { css } = s
const _SliderVariants = {
  monochrome: css`
    --track-color: ${s.color('element')};
    --range-color: ${s.color('solid')};
    --thumb-color: ${s.color('solid')};
    --thumb-hover: ${s.color('activeSolid')};
    --thumb-focus: ${s.color('text')};
    --thumb-shadow: ${s.opacity(s.color('gray.text'), 0.1)};
  `,
} satisfies s.Variants
export type SliderVariant = keyof typeof _SliderVariants

const _SliderSizes = {
  small: css`
    --track-height: 2px;
    --thumb-size: calc(${s.size('s')} * 0.75);
  `,
  medium: css`
    --track-height: 3px;
    --thumb-size: ${s.size('s')};
  `,
  large: css`
    --track-height: 5px;
    --thumb-size: calc(${s.size('s')} * 1.25);
  `,
} satisfies s.Variants
export type SliderSize = keyof typeof _SliderSizes

const _Root = s.styledWithVariants(Elements.Root, 'SliderRoot', css``, {
  ..._SliderVariants,
  ..._SliderSizes,
})
