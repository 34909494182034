import * as ReactSelect from '@radix-ui/react-select'
import React from 'react'

import { styled } from '../../'
import { Check } from '../../../icons'
import { LabelStyles, SeparatorStyles, IndicatorStyle } from '../Dropdown'
import { ItemStyles } from '../Dropdown/itemStyles'

export interface SelectItemProps extends Omit<ReactSelect.SelectItemProps, 'disabled'> {
  danger?: boolean
  disabled?: boolean
  icon?: React.ReactNode
}
function _SelectItem(
  { children, danger, icon, disabled, ...props }: SelectItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <_Item {...props} disabled={disabled} ref={ref} data-danger={danger}>
      {icon ? <_Icon children={icon} /> : null}
      <ReactSelect.ItemText>{children}</ReactSelect.ItemText>
      <_ItemIndicator>
        <Check />
      </_ItemIndicator>
    </_Item>
  )
}
export const SelectItem = React.forwardRef(_SelectItem)

const _Icon = styled.span`
  ${IndicatorStyle}
`
const _Item = styled(ReactSelect.Item)`
  ${ItemStyles}
  &[data-state='checked'] ${_Icon} {
    display: none;
  }
`
const _ItemIndicator = styled(ReactSelect.ItemIndicator)`
  ${IndicatorStyle}
`

export const Label = styled(ReactSelect.Label)`
  ${LabelStyles}
`
export const Separator = styled(ReactSelect.Separator)`
  ${SeparatorStyles}
`
