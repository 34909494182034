import { Modal } from 'antd'
import React from 'react'

import { TagDescriptions, TagDescriptionsProps } from './TagDescriptions'

export interface TagDescriptionsModalProps extends TagDescriptionsProps {
  visible?: boolean
  onClose: () => void
}
export function TagDescriptionsModal({ visible, onClose, ...props }: TagDescriptionsModalProps) {
  return (
    <Modal
      title={'Viewing Tags'}
      onCancel={onClose}
      visible={visible}
      footer={null}
      width={'90vw'}
      style={{ maxWidth: 700 }}
    >
      <TagDescriptions {...props} title={''} />
    </Modal>
  )
}
