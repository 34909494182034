import { ButtonProps, MultiFilterButton, MultiFilterMenuItem } from '@thesisedu/react'
import { Group } from '@thesisedu/react/icons'
import React from 'react'

import { useClasses } from './ClassContext'

interface CommonClassFilterProps {
  value?: string[]
  onChange: (value?: string[]) => void
}

function useClassFilter({ onChange, value }: CommonClassFilterProps) {
  const { classes, loading } = useClasses()
  const label =
    value?.length === 1
      ? classes.find(cls => cls.id === value[0])?.name
      : `${value?.length} classes`
  return {
    value,
    label,
    options: classes.map(cls => ({ label: cls.name, value: cls.id })),
    disabled: loading,
    onChange,
  }
}

export interface ClassFilterProps
  extends Omit<ButtonProps, 'value' | 'onChange'>,
    CommonClassFilterProps {
  valuePrefix?: string
  emptyLabel?: string
  hideClear?: boolean
}
export function ClassFilter({
  value,
  onChange,
  valuePrefix,
  emptyLabel,
  hideClear,
  ...rest
}: ClassFilterProps) {
  const props = useClassFilter({ value, onChange })
  return <MultiFilterButton<string> {...props} buttonProps={rest} />
}

export interface ClassFilterMenuProps extends CommonClassFilterProps {
  [key: string]: any
}
export function ClassFilterMenu({ value, onChange, ...rest }: CommonClassFilterProps) {
  const props = useClassFilter({ value, onChange })
  return <MultiFilterMenuItem<string> {...props} icon={<Group />} {...rest} />
}
