import React from 'react'

import { DevtoolsEvents } from './DevtoolsEvents'
import { FlagContextProvider, RefContextProvider } from './FlagContext'
import { IDsProvider } from '../IDsContext'

export interface InternalDevToolsContextValue {
  tabEnabledState: Record<string, boolean>
  tabData: Record<string, any>
}
export interface DevToolsContextValue {
  setTabDisabled: (tabKey: string, disabled: boolean, data?: Record<string, any>) => void
  events: DevtoolsEvents
}

export const InternalDevToolsContext = React.createContext<
  InternalDevToolsContextValue | undefined
>(undefined)
export const DevToolsContext = React.createContext<DevToolsContextValue | undefined>(undefined)

export function DevToolsContextProvider({ children }: React.PropsWithChildren<object>) {
  const [tabEnabledState, setTabEnabledState] = React.useState<Record<string, boolean>>({})
  const [tabData, setTabData] = React.useState<Record<string, any>>({})
  const eventsRef = React.useRef(new DevtoolsEvents())
  return (
    <IDsProvider>
      <InternalDevToolsContext.Provider value={{ tabEnabledState, tabData }}>
        <RefContextProvider>
          <FlagContextProvider>
            <DevToolsContext.Provider
              value={React.useMemo(
                () => ({
                  events: eventsRef.current,
                  setTabDisabled(tabKey, disabled, data) {
                    setTabEnabledState(st => {
                      return {
                        ...st,
                        [tabKey]: disabled,
                      }
                    })
                    setTabData(d => {
                      return { ...d, [tabKey]: data }
                    })
                  },
                }),
                [],
              )}
              children={children}
            />
          </FlagContextProvider>
        </RefContextProvider>
      </InternalDevToolsContext.Provider>
    </IDsProvider>
  )
}

export function useInternalDevToolsContext(): InternalDevToolsContextValue | undefined
export function useInternalDevToolsContext(require: false): InternalDevToolsContextValue | undefined
export function useInternalDevToolsContext(require: true): InternalDevToolsContextValue
export function useInternalDevToolsContext(
  require?: boolean,
): InternalDevToolsContextValue | undefined {
  const context = React.useContext(InternalDevToolsContext)
  if (!context && require) {
    throw new Error('InternalDevToolsContext is required, yet not provided.')
  }
  return context
}

export function useDevToolsContext(): DevToolsContextValue | undefined
export function useDevToolsContext(require: false): DevToolsContextValue | undefined
export function useDevToolsContext(require: true): DevToolsContextValue
export function useDevToolsContext(require?: boolean): DevToolsContextValue | undefined {
  const context = React.useContext(DevToolsContext)
  if (!context && require) {
    throw new Error('DevToolsContext is required, yet not provided.')
  }
  return context
}

/** Enables the passed tab while this component is mounted. */
/** @deprecated use useDevTab instead */
export function useEnableTab(identifier: string) {
  const { setTabDisabled } = useDevToolsContext(false) || {}
  React.useEffect(() => {
    if (setTabDisabled) {
      setTabDisabled(identifier, false)
      return () => {
        setTabDisabled(identifier, true)
      }
    }
  }, [])
}
