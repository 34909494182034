import { DirectMessagesRoute } from '@thesisedu/feature-direct-messages-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { MessagesLeftMenuItem } from './MessagesMenuItem'
import ClassesReactFeature from '../ClassesReactFeature'
import { ClassLeftNavHook, ClassRoutesHook } from '../types'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls, group }) => {
      if ((cls as any).canMessageStudents || group === 'STUDENT') {
        return [
          ...routes,
          <Route
            key={'messages'}
            path={'messages/*'}
            element={<DirectMessagesRoute listProps={{ filters: { classIds: [cls.id] } }} />}
          />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { cls, group }) => {
      if ((cls as any).canMessageStudents || group === 'STUDENT') {
        return [
          ...items,
          {
            group: group === 'TEACHER' ? 'Students' : 'Communications',
            weight: 14,
            element: <MessagesLeftMenuItem key={'messages'} classId={cls.id} path={'messages'} />,
          },
        ]
      } else return items
    },
  )
}
