import { DevToolsWrapper } from '@thesisedu/feature-react'
import { BreadcrumbProvider } from '@thesisedu/web'
import React, { useContext, useEffect, useState } from 'react'

import { FeatureWeb } from './FeatureWeb'
import { error, debug } from './log'
import { ShellProvider } from './shell'
import { CheckForUpdates } from './updates/CheckForUpdates'
import { DevServerListener } from './webpack/DevServerListener'

export interface WebProviderProps {
  featureWeb: FeatureWeb
}
export interface WebContextValue {
  featureWeb: FeatureWeb
}

export const WebContext = React.createContext<WebContextValue | null>(null)
export const WebProvider: React.FC<React.PropsWithChildren<WebProviderProps>> = ({
  featureWeb,
  children,
}) => {
  const [wrappedChildren, setWrappedChildren] = useState<React.ReactNode>(<p>Loading...</p>)
  useEffect(() => {
    featureWeb
      .wrapApp(
        <ShellProvider>
          <DevToolsWrapper
            shouldUse={featureWeb.shouldUseDevTools()}
            getInstaller={featureWeb.getDevToolsInstaller}
          >
            <CheckForUpdates />
            <DevServerListener />
            <BreadcrumbProvider>{children}</BreadcrumbProvider>
          </DevToolsWrapper>
        </ShellProvider>,
      )
      .then(wrapped => {
        debug('children have been wrapped')
        setWrappedChildren(wrapped)
      })
      .catch(err => {
        error('There was an error when wrapping the children.')
        error(err)
      })
  }, [featureWeb])
  return <WebContext.Provider children={wrappedChildren} value={{ featureWeb }} />
}

export const useWebContext = () => {
  const value = useContext<WebContextValue | null>(WebContext)
  if (!value) {
    throw new Error('WebContext not provided.')
  }
  return value
}
