import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  BasicAssignmentSubmissionFragmentDoc,
  useAutoGradeAssignmentSubmissionMutation as useMutation,
} from '../schema'

export const useAutoGradeAssignmentSubmissionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error re-grading that submission.'),
    update: updateMutation({
      fragment: BasicAssignmentSubmissionFragmentDoc,
      fragmentName: 'BasicAssignmentSubmission',
      path: 'autoGradeAssignmentSubmission.assignmentSubmission',
    }),
    ...opts,
  })
