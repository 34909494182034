import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Format } from '@thesisedu/feature-utils'
import { Result } from '@thesisedu/react'
import { HSpaced, LoadingIndicator, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteRubricTemplateButton } from './DeleteRubricTemplateButton'
import {
  RubricTemplatesDocument,
  RubricTemplatesQuery,
  RubricTemplatesQueryVariables,
  RubricTemplateFragment,
  OrderDirection,
  AllRubricTemplatesDocument,
  AllRubricTemplatesQuery,
} from '../schema'

function isAll(
  result: RubricTemplatesQuery | undefined | AllRubricTemplatesQuery,
): result is AllRubricTemplatesQuery {
  return !!result && 'rubricTemplates' in result
}

export function RubricTemplateList() {
  const viewer = useViewerContext(false)
  const useAllQuery = viewer?.group === 'ADMIN' || viewer?.group === 'CONTENT_MANAGER'
  const document = useAllQuery ? AllRubricTemplatesDocument : RubricTemplatesDocument
  const resultPath = useAllQuery ? 'rubricTemplates' : 'viewer.rubricTemplates'
  return (
    <InfiniteQuery<
      RubricTemplateFragment,
      RubricTemplatesQuery | AllRubricTemplatesQuery,
      RubricTemplatesQueryVariables
    >
      document={document}
      resultPath={resultPath}
      variables={{
        orderBy: 'createdAt',
        orderDirection: OrderDirection.Desc,
      }}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      children={({ data, loading }) => {
        const templates = (
          isAll(data) ? data.rubricTemplates : data?.viewer?.rubricTemplates
        )?.edges.map(({ node }) => node)
        if (templates) {
          return (
            <VSpaced space={'m'} align={'stretch'}>
              {templates.map(template => (
                <RubricTemplate key={template.id} template={template} />
              ))}
            </VSpaced>
          )
        } else if (loading) {
          return <LoadingIndicator.Labeled label={'Loading rubric templates...'} />
        } else {
          return <Result.Error description={'There was an error loading your templates.'} />
        }
      }}
    />
  )
}

interface RubricTemplateProps {
  template: RubricTemplateFragment
}
export function RubricTemplate({ template }: RubricTemplateProps) {
  return (
    <HSpaced justify={'space-between'}>
      <Link to={template.id}>
        <div>
          <Text level={'l'}>{template.name}</Text>
          <Text color={'secondary'} level={'s'}>
            Created {Format.date(template.createdAt, 'dateTime')}
          </Text>
        </div>
      </Link>
      <DeleteRubricTemplateButton templateId={template.id} />
    </HSpaced>
  )
}
