import { WidgetConverter } from './types'
import * as Legacy from '..'

const IGNORED_TYPES = ['NumberedContent']

export default {
  weight: Infinity,
  label: 'fallback',
  convert({ config, ...rest }) {
    if (IGNORED_TYPES.includes(rest.type)) return []
    const result = {
      version: 1,
      ...config,
      ...rest,
    }
    return result.type ? [result] : []
  },
} satisfies WidgetConverter<Legacy.Widget>
