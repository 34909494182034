import React from 'react'

import { useCourseViewerModalHeaderContext } from '../contexts/CourseViewerModalHeaderContext'

export interface CourseViewerModalCustomHeaderProps {
  children: React.ReactElement
}
export function CourseViewerModalCustomHeader({ children }: CourseViewerModalCustomHeaderProps) {
  const { setCustomHeader } = useCourseViewerModalHeaderContext(false) ?? {}
  React.useEffect(() => {
    if (setCustomHeader) {
      setCustomHeader(children)
      return () => {
        setCustomHeader(null)
      }
    }
  }, [children])
  return null
}
