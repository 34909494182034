import { Body, FontWeight } from '@thesisedu/web'
import { Tooltip } from 'antd'
import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'

export interface ClassIdProps {
  code?: string
  noWrap?: boolean
  children: React.ReactNode
}
export const ClassId: React.FC<React.PropsWithChildren<ClassIdProps>> = ({
  code,
  children,
  noWrap,
}) => {
  const [title, setTitle] = useState('Click to Copy')
  const [interval, setInterval] = useState<any>(null)
  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [interval])
  return (
    <Tooltip title={title}>
      <div
        onClick={e => {
          e.stopPropagation()
          copy(code || children!.toString())
          setTitle('Copied!')
          setInterval(
            setTimeout(() => {
              setTitle('Click to Copy')
            }, 2000),
          )
        }}
        style={{ cursor: 'pointer', display: 'inline-block' }}
      >
        {noWrap ? (
          children
        ) : (
          <Body
            code
            weight={FontWeight.Bold}
            color={'@text-color-secondary'}
            style={{ cursor: 'pointer' }}
          >
            {children}
          </Body>
        )}
      </div>
    </Tooltip>
  )
}
