import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import { Button, Modal, Result } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export enum LicenseStatus {
  Success = 'success',
  Cancelled = 'cancel',
}

export const modalProps = (
  status: LicenseStatus | undefined | string,
  onClose: () => void,
): Partial<ModalProps & { children: React.ReactElement }> => {
  switch (status) {
    case LicenseStatus.Success:
      return {
        visible: true,
        title: 'Purchase Successful',
        children: (
          <Result
            status={'success'}
            title={'Your purchase was successful!'}
            subTitle={
              'Thanks for purchasing Collabra. You can close this dialog and go back to what you were\n' +
              'doing.'
            }
            extra={[
              <Button key={'close'} onClick={onClose} icon={<ArrowLeftOutlined />}>
                Finish & Go Back
              </Button>,
            ]}
          />
        ),
      }
    case LicenseStatus.Cancelled:
      return {
        visible: true,
        title: 'Purchase Cancelled',
        children: (
          <Result
            status={'warning'}
            title={'It looks like you cancelled your purchase.'}
            subTitle={
              "If you would like to record practices and assessments in this course, you'll need to\n" +
              'purchase a subscription or talk to your instructor.'
            }
            extra={[
              <Button key={'close'} onClick={onClose} icon={<ArrowLeftOutlined />}>
                Go Back
              </Button>,
            ]}
          />
        ),
      }
    default:
      return {
        visible: false,
      }
  }
}

export const LicenseStatusModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const [visible, setVisible] = useState(false)
  const status = queryParams.get('licenseStatus') as LicenseStatus
  useEffect(() => {
    const newProps = modalProps(status, () => setVisible(false))
    setVisible(newProps.visible || false)
  }, [status])
  return (
    <Modal
      {...modalProps(status, () => setVisible(false))}
      width={600}
      style={{ maxWidth: '90vw' }}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    />
  )
}
