import {
  RenderSearchItemProps,
  SearchResultListItem,
  SearchResultNode,
} from '@thesisedu/feature-search-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface SiteSearchResult extends SearchResultNode {
  siteName: string
  city: string
  state: string
}
interface SearchResultProps extends RenderSearchItemProps<SiteSearchResult> {
  searchResultPath?: string
}
export const SearchResult: React.FC<React.PropsWithChildren<SearchResultProps>> = ({
  item,
  searchResultPath,
}) => {
  const navigate = useNavigate()
  return (
    <SearchResultListItem
      title={item.siteName}
      subtitle={`${item.city}, ${item.state}`}
      type={'Site'}
      onClick={() => {
        if (searchResultPath) {
          navigate(searchResultPath.replace(':id', item.id))
        }
      }}
    />
  )
}
