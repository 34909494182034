import { ApolloError } from '@apollo/client'
import { message as msg } from 'antd'

import { warn } from './log'

export type CodeCallback = (code: string, error: ApolloError) => void
export const onMutationError =
  (message: string, expectedCodes: string[] = [], codeCallback?: CodeCallback) =>
  (error: ApolloError) => {
    const code = (error.graphQLErrors || [])[0]?.extensions?.code as string | undefined
    if (code && expectedCodes.includes(code) && codeCallback) {
      codeCallback(code, error)
    } else if (!code || !expectedCodes.includes(code)) {
      warn(`Error with mutation: '${message}'`)
      warn(error)
      msg.error(message)
    }
  }
