import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createParagraphNode,
  $getNodeByKey,
  COMMAND_PRIORITY_CRITICAL,
  KEY_TAB_COMMAND,
  NodeKey,
} from 'lexical'
import React from 'react'

import { useEditorContext } from '../../context/EditorContext'
import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function useAdvanceToNextOnTab(enable: boolean, hostNodeKey: NodeKey) {
  const [editor] = useLexicalComposerContext()
  const { editorsRef } = useEditorContext(false) || {}

  React.useEffect(() => {
    const parentEditor = editor._parentEditor
    if (enable && parentEditor) {
      return editor.registerCommand(
        KEY_TAB_COMMAND,
        event => {
          const isPrevious = !!event.shiftKey
          const selectedBlockElement = $getSelectedBlockElement()
          if (!selectedBlockElement) return false
          const domNode = editor.getElementByKey(selectedBlockElement.getKey())
          if (!domNode) return false
          const editorContentEditable = domNode.closest('[contenteditable="true"]')
          if (!editorContentEditable) return false
          // @ts-ignore JS says you can do this; TS says no.
          const editors = [...document.querySelectorAll('[contenteditable="true"]')]
          const currentIndex = editors.findIndex(e => e === editorContentEditable)
          if (currentIndex === -1) return false
          let nextIndex = currentIndex + (isPrevious ? -1 : 1)
          if (nextIndex < 0) nextIndex = editors.length + nextIndex
          const nextEditorDomNode = editors[nextIndex]
          if (nextEditorDomNode) {
            const matchingEditor = [...(editorsRef?.current ?? [])].find(
              e => e.getRootElement() === nextEditorDomNode,
            )
            if (matchingEditor) {
              matchingEditor.focus()
            }
            return true
          } else if (parentEditor) {
            parentEditor.focus()
            parentEditor.update(() => {
              const node = $getNodeByKey(hostNodeKey)
              if (!node) return
              const next = node?.getNextSibling()
              if (next) {
                next.select()
              } else {
                const paragraphNode = $createParagraphNode()
                node.insertAfter(paragraphNode)
                paragraphNode.select()
              }
            })
            return true
          } else {
            return false
          }
        },
        COMMAND_PRIORITY_CRITICAL,
      )
    }
  }, [editor, enable])
}
