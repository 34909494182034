import * as React from 'react'
import { SVGProps, Ref, forwardRef } from 'react'
const Icon = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns={'http://www.w3.org/2000/svg'}
    width={'1em'}
    height={'1em'}
    viewBox={'0 0 24 24'}
    ref={ref}
    fill={'currentColor'}
    {...props}
  >
    <path
      fill={'currentColor'}
      fillRule={'nonzero'}
      d={
        'M13.55 2.55a1.95 1.95 0 0 1 1.903 1.521l3.324 14.75a1.95 1.95 0 0 1-1.903 2.379H7.55a1.95 1.95 0 0 1-1.919-2.301l1.379-7.53-1.457-1.7a2 2 0 1 1 .752-.505l.913 1.066 1.114-6.081A1.95 1.95 0 0 1 10.25 2.55h3.3ZM12 14.95c-1.128 0-1.881.617-2.323 1.942l-.103.308H6.857l-.34 1.86A1.05 1.05 0 0 0 7.55 20.3h9.324a1.05 1.05 0 0 0 1.025-1.28l-.411-1.82h-2.78l-.12-.267C13.99 15.586 13.143 14.95 12 14.95Zm-4.236-2.701L7.022 16.3l1.914-.001.044-.11c.247-.584.568-1.053.96-1.4l-2.176-2.54ZM13.55 3.45h-3.3a1.05 1.05 0 0 0-1.033.86l-1.245 6.8 2.736 3.19c.26-.112.54-.186.841-.223L11.55 6a.45.45 0 0 1 .9 0v8.076c1.171.14 2.096.84 2.75 2.06l.084.163 2 .001-2.71-12.03a1.05 1.05 0 0 0-1.024-.82ZM4.671 6.713a1.1 1.1 0 1 0 .344 2.173 1.1 1.1 0 0 0-.344-2.173Z'
      }
    />
  </svg>
)
const ForwardRef = forwardRef(Icon)
export default ForwardRef
