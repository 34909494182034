import { useEffect, useState } from 'react'

import { MediaStatus, useMediaNodeQuery as useQuery } from '../schema'

const POLL_INTERVAL = 5000
export const useMediaNodeQuery: typeof useQuery = opts => {
  const [shouldPoll, setShouldPoll] = useState(false)
  const result = useQuery({
    ...opts,
    pollInterval: shouldPoll ? POLL_INTERVAL : undefined,
  })
  useEffect(() => {
    if (result.data?.node?.__typename === 'Media') {
      setShouldPoll(
        result.data.node.status !== MediaStatus.Complete &&
          result.data.node.status !== MediaStatus.Error,
      )
    }
  }, [result.data])
  return result
}
