import { BlockSpin, H2, media, styled } from '@thesisedu/web'
import { Button, Form, message } from 'antd'
import { omit } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'

import { LicenseForm } from './LicenseForm'
import { useCreateLicenseMutation } from './queries/useCreateLicenseMutation'
import { useUpdateLicenseMutation } from './queries/useUpdateLicenseMutation'
import { LicenseInput, LicenseType, useSiteLicenseQuery } from './schema'

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: ${props => props.theme['@size-m']};
`
const HeaderRight = styled.div`
  ${media.md} {
    margin-left: auto;
  }
`

const convertValues = (values: any): LicenseInput => ({
  ...omit(values, ['payUpFrontPriceInDollars', 'monthlyPriceInDollars', 'noEmail']),
  monthlyPriceInCents: values.monthlyPriceInDollars
    ? Math.floor(values.monthlyPriceInDollars * 100)
    : null,
  payUpFrontPriceInCents: values.payUpFrontPriceInDollars
    ? Math.floor(values.payUpFrontPriceInDollars * 100)
    : null,
  expiresAt: values.expiresAt ? values.expiresAt.format() : null,
})

export interface SiteLicenseFormProps {
  siteId: string
}
export const SiteLicenseForm: React.FC<React.PropsWithChildren<SiteLicenseFormProps>> = ({
  siteId,
}) => {
  const { data, loading } = useSiteLicenseQuery({ variables: { siteId } })
  const [form] = Form.useForm()
  const [updateLicense, { loading: updateLoading }] = useUpdateLicenseMutation({
    onCompleted: () => {
      message.success('License updated successfully')
    },
  })
  const [createLicense, { loading: createLoading }] = useCreateLicenseMutation({
    onCompleted: () => {
      message.success('License created successfully')
    },
  })
  const saveLoading = updateLoading || createLoading
  const license = data?.node?.__typename === 'Site' ? data.node.license : undefined
  useEffect(() => {
    if (license) {
      form.setFieldsValue({
        ...license,
        monthlyPriceInDollars: license.monthlyPriceInCents
          ? license.monthlyPriceInCents / 100
          : undefined,
        payUpFrontPriceInDollars: license.payUpFrontPriceInCents
          ? license.payUpFrontPriceInCents / 100
          : undefined,
        expiresAt: license.expiresAt ? moment(license.expiresAt) : undefined,
      })
    } else {
      // Set some default values if we don't have an existing license.
      form.setFieldsValue({
        monthlyPriceInDollars: 8,
        payUpFrontPriceInDollars: 32,
        payUpFrontMonths: 5,
        seats: 500,
      })
    }
  }, [license])
  if (loading) {
    return <BlockSpin />
  }

  return (
    <>
      <HeaderContainer>
        <H2>Site License</H2>
        <HeaderRight>
          <Button
            size={'small'}
            type={'primary'}
            onClick={() => form.submit()}
            loading={saveLoading}
          >
            {license ? 'Save License' : 'Create License'}
          </Button>
        </HeaderRight>
      </HeaderContainer>
      <LicenseForm
        form={form}
        isStudentPay={license?.type === LicenseType.StudentPay}
        disableMonthly={!!license?.monthlyPriceInCents}
        onFinish={values => {
          if (license) {
            updateLicense({
              variables: {
                input: {
                  id: license.id,
                  patch: {
                    ...convertValues(values),
                    siteId,
                  },
                  noEmail: !!values.noEmail,
                },
              },
            })
          } else {
            createLicense({
              variables: {
                input: {
                  ...convertValues(values),
                  siteId,
                },
              },
            })
          }
        }}
      />
    </>
  )
}
