import { Dropdown, Tooltip } from '@thesisedu/ui'
import { MediaPlaylist } from 'hls.js'
import React from 'react'

import { ClosedCaptions } from '../../../icons'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

export interface CaptionSelectorProps extends PlayerFooterProps {
  tracks: MediaPlaylist[]
  selectedTrack: number
  onChange: (selectedTrack: number) => void
}
export function CaptionSelector({
  tracks,
  selectedTrack,
  onChange,
  compact,
}: CaptionSelectorProps) {
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <Tooltip title={'Subtitles'}>
          <ControlContainer compact={!!compact} style={{ opacity: selectedTrack > -1 ? 1 : 0.5 }}>
            <ClosedCaptions />
          </ControlContainer>
        </Tooltip>
      </Dropdown.ManualTrigger>
      <Dropdown.Menu side={'top'} align={'end'}>
        <Dropdown.Item.Checkbox
          checked={selectedTrack === -1}
          onCheckedChange={checked => {
            if (checked) {
              onChange(-1)
            }
          }}
        >
          None
        </Dropdown.Item.Checkbox>
        {tracks.map(track => (
          <Dropdown.Item.Checkbox
            key={track.id.toString()}
            checked={selectedTrack === track.id}
            onCheckedChange={checked => {
              if (checked) {
                onChange(track.id)
              }
            }}
          >
            {track.name}
          </Dropdown.Item.Checkbox>
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
