import { base64, unbase64 } from './base64'

interface ResolvedGlobalId {
  type: string
  id: string
}

/**
 * Takes a type name and an ID specific to that type name, and returns a
 * "global ID" that is unique among all types.
 */
export function toGlobalId(type: string, id: string | number): string {
  return base64([type, id.toString()].join(':'))
}

/**
 * Takes the "global ID" created by toGlobalID, and returns the type name and ID
 * used to create it.
 */
export function fromGlobalId(globalId: string, require: true): ResolvedGlobalId
export function fromGlobalId(globalId: string, require?: boolean): ResolvedGlobalId | null
export function fromGlobalId(globalId: string, require?: boolean): ResolvedGlobalId | null {
  let result
  try {
    const unbasedGlobalId = unbase64(globalId)
    const delimiterPos = unbasedGlobalId.indexOf(':')
    const r = {
      type: unbasedGlobalId.substring(0, delimiterPos),
      id: unbasedGlobalId.substring(delimiterPos + 1),
    }
    result = r.type && r.id ? r : null
  } catch {
    result = null
  }

  if (!result && require) {
    throw new Error(`ID ${globalId} is invalid.`)
  }

  return result
}
