import { Input } from 'antd'
import { InputProps } from 'antd/es/input'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export interface SmallSearchFieldProps extends InputProps {
  path: string
}
export function SmallSearchField({ path, ...props }: SmallSearchFieldProps) {
  const navigate = useNavigate()
  const [value, setValue] = useState('')
  return (
    <Input.Search
      value={value}
      onChange={e => setValue(e.target.value)}
      placeholder={'Find content...'}
      onSearch={() => {
        if (value) {
          navigate(path.replace(/QUERY/g, encodeURIComponent(value)))
          setValue('')
        }
      }}
      {...props}
    />
  )
}
