import { ShoppingCode } from '@thesisedu/react/icons'
import { BodySmall, styled, CapitalBold, Color, HSpaced } from '@thesisedu/web'
import { Tooltip, Menu } from 'antd'
import copy from 'copy-to-clipboard'
import React, { useEffect, useState } from 'react'

import { useTeacherIdQuery } from './schema'

const Container = styled.div`
  display: block;
  text-align: left;
  cursor: pointer;
  max-width: 200px;
`

export function TeacherIDMenuItem(props: any) {
  const { data } = useTeacherIdQuery()
  const teacherId = data?.viewer?.teacher?.teacherId
  const [message, setMessage] = React.useState('Copy Teacher ID')
  if (!teacherId) return null

  return (
    <Menu.Item
      {...props}
      onClick={() => {
        copy(teacherId.toUpperCase())
        setMessage('Copied!')
        setTimeout(() => {
          setMessage('Copy Teacher ID')
        }, 2000)
      }}
    >
      <HSpaced space={'@size-xs'}>
        <ShoppingCode />
        <span>{message}</span>
        <Color color={'@text-color-secondary'} style={{ marginLeft: 'auto' }}>
          {teacherId.toUpperCase()}
        </Color>
      </HSpaced>
    </Menu.Item>
  )
}

export function TeacherID() {
  const [title, setTitle] = useState('Click to Copy')
  const [interval, setInterval] = useState<any>(null)
  const { data } = useTeacherIdQuery()
  useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [interval])
  const teacherId = data?.viewer?.teacher?.teacherId
  if (!teacherId) return null
  return (
    <Tooltip title={title}>
      <Container
        onClick={() => {
          copy(teacherId.toUpperCase())
          setTitle('Copied!')
          setInterval(
            setTimeout(() => {
              setTitle('Click to Copy')
            }, 2000),
          )
        }}
      >
        <BodySmall>Teacher ID</BodySmall>
        <CapitalBold>{teacherId}</CapitalBold>
      </Container>
    </Tooltip>
  )
}
