import { useFeatureRoot } from '@thesisedu/feature-react'
import { FeatureWebApplicationOptions } from '@thesisedu/feature-web'
import { styled, s, Text, HSpaced, Block } from '@thesisedu/ui'
import React from 'react'

import { ConnectIndicator, ErrorIndicator, SuccessIndicator } from './ErrorIndicator'
import { MovingParticles, MovingParticlesProps } from './MovingParticles'

export interface LoadingStepProps {
  title?: string
  message?: string | React.ReactElement
  action?: React.ReactElement
  providerLogo: string
  providerName: string
  direction?: MovingParticlesProps['direction']
  state?: 'loading' | 'error' | 'connect' | 'success'
  style?: React.CSSProperties
}
export function LoadingStep({
  title,
  message = 'This should only take a few moments.',
  providerLogo,
  providerName,
  direction = 'right',
  state = 'loading',
  action,
  style,
}: LoadingStepProps) {
  const appOptions = useFeatureRoot().appOptions as FeatureWebApplicationOptions
  return (
    <Container style={style}>
      <HSpaced>
        <LoadingImage src={appOptions.icon()} alt={`${appOptions.name} Logo`} />
        {state === 'loading' ? (
          <Particles direction={direction} />
        ) : state === 'error' ? (
          <ErrorIndicator style={{ width: 75 }} />
        ) : state === 'connect' ? (
          <ConnectIndicator style={{ width: 75 }} />
        ) : state === 'success' ? (
          <SuccessIndicator style={{ width: 75 }} />
        ) : null}
        <LoadingImage src={providerLogo} alt={`${providerName} Logo`} />
      </HSpaced>
      <div>
        <Text level={'h3'} bottom={'xs'}>
          {title ?? `Talking to ${providerName}`}
        </Text>
        <Text level={'s'} color={'secondary'}>
          {message}
        </Text>
      </div>
      {action ? <Block top={'s'}>{action}</Block> : null}
    </Container>
  )
}

const Container = styled.div`
  width: 450px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  gap: ${s.size('m')};
`
const Particles = styled(MovingParticles)`
  width: 75px;
  height: ${s.size('s')};
`
const LoadingImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: ${s.var('radii.1')};
  overflow: hidden;
`
