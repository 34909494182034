import * as React from 'react'

function SvgAccidentalSharp(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 50 55'} {...props}>
      <path
        fill={'#512D1C'}
        d={
          'M28.265 39.525l-10.263 1.6c-.233 2.035-.417 3.93-.552 5.683-.147 1.84-.24 3.255-.294 4.47.034.88-.39 1.715-1.122 2.206a3.678 3.678 0 01-2.28.626 3.68 3.68 0 01-2.171-.883 2.557 2.557 0 01-.883-2.244l1.048-8.939-9.196 1.453a1.6 1.6 0 01-1.84-.68 4.34 4.34 0 01-.698-2.152 4.78 4.78 0 01.386-2.28c.295-.724.7-1.129 1.214-1.215.938-.22 2.373-.46 4.286-.735a436.29 436.29 0 006.676-1.03l1.343-11.22L3.03 25.86a1.619 1.619 0 01-1.839-.68 4.672 4.672 0 01-.625-2.134 5.095 5.095 0 01.386-2.3 1.84 1.84 0 011.232-1.213c1.03-.202 2.649-.478 4.874-.846 2.226-.368 4.82-.736 7.67-1.14l1.84-15.413A1.84 1.84 0 0117.762.385a3.329 3.329 0 012.041-.128 4.69 4.69 0 011.931 1.03c.491.385.776.976.773 1.6-.11 1.177-.294 2.98-.607 5.389-.313 2.41-.644 5.168-1.012 8.276l5.224-.79 5.168-.81L32.9 2.077A1.84 1.84 0 0133.985.238 3.2 3.2 0 0136.026.11a4.488 4.488 0 011.932 1.03c.49.386.775.977.772 1.6-.092.939-.257 2.502-.46 4.433a274.911 274.911 0 01-.809 6.713c1.84-.312 3.513-.625 5.058-.956l4.01-.883a2.244 2.244 0 012.243.626c.603.6 1.008 1.37 1.16 2.207a4.69 4.69 0 01-.258 2.52 2.262 2.262 0 01-1.748 1.526l-11.385 1.84c-.239 1.838-.46 3.678-.662 5.517l-.662 5.61 5.996-1.14a161.84 161.84 0 004.672-.994 2.262 2.262 0 012.244.607c.603.6 1.007 1.37 1.158 2.207a4.874 4.874 0 01-.092 2.373 2.244 2.244 0 01-1.839 1.526l-12.875 1.987c-.294 2.593-.515 4.984-.717 7.136-.202 2.152-.313 3.991-.423 5.518.035.88-.39 1.716-1.122 2.207a3.678 3.678 0 01-2.28.607 3.531 3.531 0 01-2.17-.865 2.667 2.667 0 01-.884-2.262l1.38-11.274zm-8.222-16.26c-.239 1.84-.46 3.68-.662 5.518-.202 1.84-.441 3.679-.68 5.61l5.223-.699a203.31 203.31 0 005.242-.883l1.324-11.146-10.447 1.6z'
        }
      />
    </svg>
  )
}

const MemoSvgAccidentalSharp = React.memo(SvgAccidentalSharp)
export default MemoSvgAccidentalSharp
