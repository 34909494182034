import { Feature } from '@thesisedu/feature'
import { modifyQueryDocument } from '@thesisedu/feature-apollo-react'
import { AssignmentSettingsOptionsHook } from '@thesisedu/feature-assignments-react'
import {
  CourseViewerSegmentHeaderPortalContent,
  ScheduleSegmentButton,
  useCourseContext,
} from '@thesisedu/feature-courses-react'
import { ClassDecorationsDocument } from '@thesisedu/feature-courses-react/dist/schema'
import { DateField } from '@thesisedu/ui'
import React from 'react'

import { cacheSegmentAssignmentId } from '../cache/segmentAssignmentCache'

export interface OpensAtProps {
  segmentId: string
}
export function OpensAt({ segmentId }: OpensAtProps) {
  const { segmentMetadata } = useCourseContext(true)
  const scheduledAt = segmentMetadata?.segments?.find(s => s.id === segmentId)?.scheduledAt
  return (
    <DateField
      label={'Opens at'}
      labelRight={
        <ScheduleSegmentButton
          segmentId={segmentId}
          variant={'chromeless'}
          style={{ paddingRight: 0 }}
          size={'small'}
        />
      }
      granularity={'minute'}
      disabled
      value={scheduledAt}
    />
  )
}

export default function addASOHook(feature: Feature) {
  feature.hookManager.registerMutateHook<AssignmentSettingsOptionsHook>(
    'feature-assignments-react:assignment-settings-options',
    (opts, { assignment }) => {
      if (assignment.configuration?.segmentId) {
        const classIds = assignment.assignmentCategories.edges.map(cat => cat.node.classId)

        // Update the cached assignment IDs.
        for (const classId of classIds) {
          cacheSegmentAssignmentId(classId, assignment.configuration.segmentId, assignment.id)
        }

        const modifiedDocument = modifyQueryDocument(ClassDecorationsDocument, feature.hookManager)
        return {
          ...opts,
          hideAssignment: true,
          hideName: true,
          hideDescription: true,
          questionSettingsTab: 'teacher',
          assignmentSyncsProps: {
            mutateOpts: {
              refetchQueries: classIds.map(classId => ({
                query: modifiedDocument,
                variables: { classId },
              })),
            },
          },
          OpensAt: () => <OpensAt segmentId={assignment.configuration!.segmentId} />,
          Submit: ({ button }) => <CourseViewerSegmentHeaderPortalContent children={button} />,
        }
      }
      return opts
    },
  )
}
