import { useClassConfiguration } from '@thesisedu/feature-classes-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { KeyboardButton } from './KeyboardButton'
import { HIDE_KEYBOARD_FIELD } from '../../resources/class_configuration/hideKeyboardField'

export interface KeyboardButtonContextValue {
  setButtonHidden: (hidden: boolean, key: string) => void
  buttonHidden: boolean
}
export const KeyboardButtonContext = React.createContext<KeyboardButtonContextValue | undefined>(
  undefined,
)

export function KeyboardButtonContextProvider({ children }: React.PropsWithChildren<object>) {
  const viewer = useViewerContext(false)
  const [buttonHidden, _setButtonHidden] = React.useState(false)
  const keysRef = React.useRef<Set<string>>(new Set<string>())
  const setButtonHidden = React.useCallback((hidden: boolean, key: string) => {
    if (hidden) {
      keysRef.current.add(key)
    } else {
      keysRef.current.delete(key)
    }
    _setButtonHidden(!!keysRef.current.size)
  }, [])
  return (
    <KeyboardButtonContext.Provider value={{ buttonHidden, setButtonHidden }}>
      {children}
      {viewer?.group === 'STUDENT' ? <KeyboardButton /> : null}
    </KeyboardButtonContext.Provider>
  )
}

export function useSyncKeyboardButtonWithClass() {
  const hideKeyboard = useClassConfiguration<boolean>(HIDE_KEYBOARD_FIELD)
  const { setButtonHidden } = useKeyboardButtonContext(false) || {}
  React.useEffect(() => {
    if (hideKeyboard && setButtonHidden) {
      setButtonHidden(true, 'class-hide')
      return () => {
        setButtonHidden(false, 'class-hide')
      }
    }
  }, [hideKeyboard])
}

export function useKeyboardButtonContext(): KeyboardButtonContextValue | undefined
export function useKeyboardButtonContext(require: false): KeyboardButtonContextValue | undefined
export function useKeyboardButtonContext(require: true): KeyboardButtonContextValue
export function useKeyboardButtonContext(
  require?: boolean,
): KeyboardButtonContextValue | undefined {
  const context = React.useContext(KeyboardButtonContext)
  if (!context && require) {
    throw new Error('KeyboardButtonContext is required, yet not provided.')
  }
  return context
}
