import {
  GetNavigationOpts,
  SegmentNavigation,
  getSegmentNavigationItems,
  NavigationKey,
  Segment,
} from '@thesisedu/feature-courses-react'
import { Viewer } from '@thesisedu/feature-users-web'
import { Folder, GraphUp, Group, Settings } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentNavigationIds } from './assignment/AssignmentContent'
import { DisableIfNoAssignmentNavigationItem } from './assignment/DisableIfNoAssignmentNavigationItem'
import { GroupsNavigationItem } from './assignment/GroupsNavigationItem'
import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'

type SegmentDecoration = {
  calculation?: {
    identifier?: string
  }
}
export function defaultNavigationKey(
  this: CourseAssignmentsReactFeature,
  viewer: Viewer | undefined,
  segment: Segment,
  decoration: SegmentDecoration | undefined,
): string {
  return viewer?.group === 'TEACHER'
    ? decoration?.calculation?.identifier
      ? AssignmentNavigationIds.Vault
      : NavigationKey.Teacher
    : NavigationKey.Student
}

export function getNavigation(
  this: CourseAssignmentsReactFeature,
  opts: GetNavigationOpts,
): SegmentNavigation {
  const { segment, isPreview, viewer, decoration } = opts
  const result = getSegmentNavigationItems({
    ...opts,
    forceTeacher: true,
    forceStudent: true,
  })
  if (segment.config?.templateId && !isPreview && viewer?.group !== 'STUDENT') {
    if (decoration?.calculation?.identifier) {
      result.unshift({
        id: AssignmentNavigationIds.Vault,
        name: 'Quiz Vault',
        icon: <Folder />,
      })
    }
    result.push({
      id: AssignmentNavigationIds.Settings,
      name: 'Settings',
      icon: <Settings />,
    })
    result.push({
      id: AssignmentNavigationIds.Groups,
      name: 'Groups',
      icon: <Group />,
      render: props => <GroupsNavigationItem {...props} segmentId={segment.id} />,
    })
    result.push({
      id: AssignmentNavigationIds.Reports,
      name: 'Reports',
      icon: <GraphUp />,
      render: props => <DisableIfNoAssignmentNavigationItem {...props} segmentId={segment.id} />,
    })
  }

  return result
}
