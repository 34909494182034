import { InteractionsContext } from '@thesisedu/feature-interactions-react'
import React from 'react'

import { BadgeAwardModal } from './BadgeAwardModal'
import { BadgeFragment, InteractionsResultWithBadgesFragment } from './schema'

export function BadgeNotificationListener() {
  const manager = React.useContext(InteractionsContext)
  const [notifyingBadges, setNotifyingBadges] = React.useState<BadgeFragment[]>([])
  React.useEffect(() => {
    if (manager) {
      const handler = (result: InteractionsResultWithBadgesFragment) => {
        setNotifyingBadges(n => [...n, ...result.awardedBadges])
      }
      manager.on('interactionsRecorded', handler as any)
      return () => {
        manager.off('interactionsRecorded', handler)
      }
    }
  }, [!!manager])

  if (notifyingBadges.length) {
    return (
      <BadgeAwardModal
        visible
        onCancel={() => {
          setNotifyingBadges(n => n.slice(1))
        }}
        badge={notifyingBadges[0]}
      />
    )
  } else {
    return null
  }
}
