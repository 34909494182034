import { Widgets } from '@thesisedu/feature-course-types'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Dropdown } from '@thesisedu/ui'
import { Square, Minus } from '@thesisedu/ui/icons'
import React from 'react'

const IconMap: Record<Widgets.VideoWidgetDisplayMode, typeof Square> = {
  [Widgets.VideoWidgetDisplayMode.Block]: Square,
  [Widgets.VideoWidgetDisplayMode.Inline]: Minus,
}

export function DisplayModeSetting({
  editor,
  node,
}: SimpleWidget.SettingsDropdownProps<Widgets.VideoConfig>) {
  const [displayMode, setDisplayMode] = SimpleWidget.useWidgetConfig(editor, node, 'displayMode')
  const Icon = IconMap[displayMode || Widgets.VideoWidgetDisplayMode.Block]
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<Icon />}>Display Mode</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        {(Object.keys(IconMap) as Widgets.VideoWidgetDisplayMode[]).map(a => {
          return (
            <SimpleWidget.SettingsDropdownEnumItem
              value={a}
              key={a}
              selected={a === displayMode}
              label={a[0].toUpperCase() + a.slice(1)}
              onChange={setDisplayMode}
            />
          )
        })}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}
