import { styled } from '@thesisedu/web'
import React from 'react'

import { Body, TypographyProps } from '../typography'

export interface TruncatedTextProps {
  textComponent?: React.FC<React.PropsWithChildren<TypographyProps & Record<string, any>>>
  numberOfLines: number
  children: string | null | undefined | React.ReactElement
  [key: string]: any
}
/** @deprecated */
export function TruncatedText({
  textComponent: Text = Body,
  numberOfLines,
  children,
  ...rest
}: TruncatedTextProps) {
  return <Container children={children} as={Text as any} numberOfLines={numberOfLines} {...rest} />
}

const Container = styled.div<{ numberOfLines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
