import { styled } from '@thesisedu/web'
import React from 'react'

interface ChordImages {
  [chord: string]: string
}
const CHORD_IMAGES: ChordImages = {
  amajor: require('../../assets/chord-a-major.svg').default,
  aminor: require('../../assets/chord-a-minor.svg').default,
  a7: require('../../assets/chord-a7.svg').default,
  bmajor: require('../../assets/chord-b-major.svg').default,
  bbmajor: require('../../assets/chord-bb-major.svg').default,
  cmajor: require('../../assets/chord-c-major.svg').default,
  c7: require('../../assets/chord-c7.svg').default,
  dmajor: require('../../assets/chord-d-major.svg').default,
  dminor: require('../../assets/chord-d-minor.svg').default,
  d7: require('../../assets/chord-d7.svg').default,
  emajor: require('../../assets/chord-e-major.svg').default,
  eminor: require('../../assets/chord-e-minor.svg').default,
  fmajor: require('../../assets/chord-f-major.svg').default,
  gmajor: require('../../assets/chord-g-major.svg').default,
  g7: require('../../assets/chord-g7.svg').default,
}
interface ChordColors {
  [chord: string]: string[]
}
const CHORD_COLORS: ChordColors = {
  amajor: ['@yellow', '@orange', '@purple'],
  aminor: ['@yellow', '@red', '@purple'],
  a7: ['@yellow', '@orange', '@blue', '@purple'],
  bmajor: ['@green', '@orange', '@pink'],
  bbmajor: ['@green', '@orange', '@purple'],
  cmajor: ['@blue', '@yellow', '@red'],
  c7: ['@blue', '@yellow', '@purple', '@red'],
  dmajor: ['@purple', '@green', '@orange'],
  dminor: ['@purple', '@green', '@orange'],
  d7: ['@purple', '@green', '@red', '@orange'],
  emajor: ['@pink', '@blue', '@yellow'],
  eminor: ['@pink', '@blue', '@yellow'],
  fmajor: ['@red', '@purple', '@green'],
  gmajor: ['@orange', '@pink', '@blue'],
  g7: ['@orange', '@pink', '@green', '@blue'],
}

export interface ChordProps {
  chord: string
  opacity?: number
}
export function Chord({ chord, opacity = 1 }: ChordProps) {
  const duration = parseFloat(chord.match(/\d+/)?.[0] || '')
  const chordName = chord.replace(duration.toString(), '')
  if (!isNaN(duration) && duration && CHORD_IMAGES[chordName]) {
    return (
      <Container duration={duration} style={{ opacity }}>
        <Colors colors={CHORD_COLORS[chordName]} />
        <img src={CHORD_IMAGES[chordName]} alt={chordName} />
      </Container>
    )
  } else {
    return null
  }
}

const INNER_HEIGHT = 65
const Colors = styled.div<{ colors: string[] }>`
  position: absolute;
  top: 22%;
  bottom: 20%;
  right: ${props => props.theme['@size-m']};
  left: ${INNER_HEIGHT / 2}px;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    ${props => {
      const unit = 100 / props.colors.length
      return props.colors
        .map(
          (color, index) =>
            `${props.theme[color]} ${unit * index}%, ${props.theme[color]} ${unit * (index + 1)}%`,
        )
        .join(',')
    }}
  );
`
const Container = styled.div<{ duration: number }>`
  height: ${INNER_HEIGHT}px;
  width: ${props => props.duration * 100}%;
  position: relative;
  left: -${INNER_HEIGHT / 4}px;
  img {
    height: 100%;
    position: relative;
  }
`
