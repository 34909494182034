import {
  Observable,
  ApolloClient,
  ApolloLink,
  concat,
  InMemoryCache,
  Operation,
  selectURI,
  split,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { fromGlobalId } from '@thesisedu/feature-utils'

import { ApolloReactFeature } from './ApolloReactFeature'
import { debug, warn } from './log'
import { onError } from './onErrorWithPromise'
import { persist } from './persistence'
import { getAuthenticationKey, platform } from './platform/context'
import {
  ApolloReactHooks,
  ApolloReactOptions,
  ExpectedErrorContext,
  ExpectedErrorPayload,
} from './types'

global.Buffer = global.Buffer || require('buffer').Buffer

export function getWebsocketUrl(host: string) {
  if (host.includes('localhost')) {
    return host.replace('https', 'wss').replace('http', 'ws').replace('/graphql', '')
  } else {
    return host
      .replace('https://', 'wss://ws-')
      .replace('http://', 'ws://ws-')
      .replace('/graphql', '')
  }
}

export function getBatchKey(operation: Operation, uri: string): string {
  const context = operation.getContext()
  if (context.customBatchKey) return context.customBatchKey
  const contextConfig = {
    http: context.http,
    options: context.fetchOptions,
    credentials: context.credentials,
    headers: context.headers,
  }
  return selectURI(operation, uri) + JSON.stringify(contextConfig)
}

const MAINTENANCE_RETRY_DELAY_SECONDS = 30

export const createClient = async (
  options: ApolloReactOptions,
  feature: ApolloReactFeature,
): Promise<ApolloClient<any>> => {
  const errorService = feature.services.error
  const authMiddleware = new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      let handle: any
      debug(`QUERY ${operation.operationName}`)
      debug(operation.variables)
      getAuthenticationKey(options)
        .then(key => {
          const hasKeyFromQuery = operation.getContext().headers?.Authorization
          if (key && !hasKeyFromQuery) {
            operation.setContext({
              headers: {
                Authorization: `Bearer ${key}`,
              },
            })
          }
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))
      return () => {
        if (handle) handle.unsubscribe()
      }
    })
  })
  const errorHandler = onError(async ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path, originalError, extensions = {} } = err
        debug(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${
            operation.operationName
          }, Variables: ${JSON.stringify(operation.variables)}`,
        )
        if (extensions?.code === 'UNDER_MAINTENANCE_ERROR') {
          debug(
            'system is under maintenance, retrying in %d seconds',
            MAINTENANCE_RETRY_DELAY_SECONDS,
          )
          await new Promise(resolve => setTimeout(resolve, MAINTENANCE_RETRY_DELAY_SECONDS * 1000))
          return forward(operation)
        } else if (extensions?.code) {
          debug('expected error %s caught, notifying hooks', extensions.code)
          const { shouldRetry } = await feature.hookManager.mutateHook<
            ExpectedErrorPayload,
            ExpectedErrorContext
          >(
            ApolloReactHooks.ExpectedError,
            { ...err, shouldRetry: false },
            { code: (extensions.code as string | undefined) || '', operation },
          )
          if (shouldRetry) {
            return forward(operation)
          }
        }
        if (errorService && (!extensions || !extensions.code)) {
          debug('sending to error service...')
          errorService.reportError(originalError || message, {
            err,
            operation,
            'sentry:fingerprint': ['GraphQL Error', message],
          })
        }
      }
    }
    if (networkError) {
      warn(`[Network error]: ${networkError}`)
    }
  })
  const abortController = new AbortController()
  const httpLink = new BatchHttpLink({
    uri: options.host,
    batchKey: operation => getBatchKey(operation, options.host),
    batchMax: 4,
    fetchOptions: {
      // This is to support response bodies being processed by Sentry.
      signal: abortController.signal,
    },
  })
  const { createClient: createWebSocketClient } = require('graphql-ws')
  const wsLink = new GraphQLWsLink(
    createWebSocketClient({
      url: getWebsocketUrl(options.host),
      async connectionParams() {
        const authKey = await getAuthenticationKey(options)
        return authKey ? { Authorization: `Bearer ${authKey}` } : {}
      },
    }),
  )
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )

  const authAndRequest = concat(authMiddleware, splitLink)
  const cache = new InMemoryCache({
    possibleTypes: options.possibleTypes,
    typePolicies: {
      Query: {
        queryType: true,
        fields: {
          node: {
            keyArgs: ['id'],
            read(existingData, { args, toReference }) {
              if (args?.id) {
                const { type } = fromGlobalId(args.id, true)
                return existingData || toReference({ __typename: type, id: args.id })
              } else {
                return existingData
              }
            },
          },
        },
      },
    },
  })
  if (options.persistence && !options.persistence.disabled) {
    await persist(feature, cache)
  }
  return new ApolloClient({
    cache,
    // @ts-ignore need @apollo/link-error to use the latest version.
    link: concat(errorHandler, authAndRequest),
    name: `${feature.appOptions.name}-${platform}`,
    version: feature.appOptions.release,
    assumeImmutableResults: true, // This is a performance optimization.
  })
}
