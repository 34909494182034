import { ExclamationCircleFilled } from '@ant-design/icons/lib'
import React from 'react'
import styled from 'styled-components'

import { Body } from './Typography'

export interface NoMoreProps {
  isCompact?: boolean
}

const Container = styled.div<NoMoreProps>`
  width: 100%;
  text-align: center;
  margin: ${props => props.theme[props.isCompact ? '@size-s' : '@size-m']} 0;
  svg {
    width: ${props => props.theme['@size-l']};
    height: ${props => props.theme['@size-l']};
    margin: 0 0 ${props => props.theme['@size-s']} 0;
    opacity: 0.25;
    display: block;
  }
`

export const NoMore: React.FC<React.PropsWithChildren<NoMoreProps>> = ({ isCompact }) => {
  if (isCompact) {
    return <Container>No more results!</Container>
  } else {
    return (
      <Container>
        <ExclamationCircleFilled />
        <Body>No more results!</Body>
      </Container>
    )
  }
}
