import { Button, message } from 'antd'
import React from 'react'

import { useIgnoreTrialRequestMutation } from './queries/useIgnoreTrialRequestMutation'

export interface IgnoreTrialRequestLinkProps {
  id: string
}
export const IgnoreTrialRequestButton: React.FC<
  React.PropsWithChildren<IgnoreTrialRequestLinkProps>
> = ({ id }) => {
  const [ignoreRequest, { loading }] = useIgnoreTrialRequestMutation({
    onCompleted: () => {
      message.success('Trial request ignored')
    },
  })
  return (
    <Button
      type={'link'}
      size={'small'}
      loading={loading}
      onClick={() =>
        ignoreRequest({
          variables: {
            trialRequestId: id,
          },
        })
      }
    >
      Ignore
    </Button>
  )
}
