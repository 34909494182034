export const omitDeep = (input: any, excludeKeys: string[]): any => {
  if (Array.isArray(input)) {
    return input.map(item => omitDeep(item, excludeKeys))
  } else if (typeof input === 'object' && input) {
    const newResult: any = {}
    for (const key of Object.keys(input)) {
      if (!excludeKeys.includes(key)) {
        newResult[key] = omitDeep(input[key], excludeKeys)
      }
    }
    return newResult
  } else {
    return input
  }
}
