import { SearchResultListItem, SearchResultListItemProps } from '@thesisedu/feature-search-react'
import { DATE_FORMATS } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { SearchResultMedia } from './MediaGridItem'
import { MediaThumbnail } from '../preview/MediaThumbnail'
import { MediaStatus } from '../schema'
import { UploadStatusLabel } from '../upload/UploadStatusLabel'
import { convertStatus } from '../upload/convertStatus'

export interface MediaListItemProps {
  media: SearchResultMedia
  title: string
  type: string
  subtitle?: string
  onClick: SearchResultListItemProps['onClick']
}
export function MediaListItem({ media, title, type, subtitle, onClick }: MediaListItemProps) {
  return (
    <SearchResultListItem
      title={title}
      type={type}
      subtitle={
        subtitle ? (
          subtitle
        ) : media.status !== MediaStatus.Complete ? (
          <UploadStatusLabel upload={{ ...media, currentStatus: convertStatus(media.status) }} />
        ) : (
          moment(media.createdAt).format(DATE_FORMATS.DATE)
        )
      }
      onClick={onClick}
      thumbnail={<MediaThumbnail node={media} />}
    />
  )
}
