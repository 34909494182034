import { Button, Form, message, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React from 'react'

import { SiteForm } from './SiteForm'
import { useCreateSiteMutation } from './queries/useCreateSiteMutation'
import { SiteFragment } from './schema'

export interface CreateSiteModalProps extends Partial<ModalProps> {
  onCancel: () => void
  onCreated?: (site: SiteFragment) => void
}
export const CreateSiteModal: React.FC<React.PropsWithChildren<CreateSiteModalProps>> = props => {
  const [form] = Form.useForm()
  const onCancel = () => {
    form.resetFields()
    props.onCancel()
  }
  const [createSite, { loading }] = useCreateSiteMutation({
    onCompleted: data => {
      message.success('Site created successfully!')
      onCancel()
      if (props.onCreated) {
        props.onCreated(data.createSite.site)
      }
    },
  })

  return (
    <Modal
      title={'Create Site'}
      {...props}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} type={'primary'} onClick={form.submit} loading={loading}>
          Create Site
        </Button>,
      ]}
    >
      <SiteForm
        form={form}
        onFinish={values =>
          createSite({
            variables: {
              input: values,
            },
          })
        }
      />
    </Modal>
  )
}
