import { renderAbc } from 'abcjs'

import { TuneItem } from '../viewer/types'

export function useHasValue(abc: string) {
  try {
    const rendered = renderAbc('*', abc) as TuneItem[]
    return !!rendered?.[0]?.lines[0]?.staff[0]?.voices[0]?.filter(voice => voice.el_type === 'note')
      .length
  } catch {
    return false
  }
}

export const hasValue = useHasValue
