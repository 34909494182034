import { useFeatureRoot, useResource } from '@thesisedu/feature-react'
import { styled, s, HSpaced, LoadingIndicator, Text, Button, Tooltip } from '@thesisedu/ui'
import { MinusCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { useOnboardingContext } from './OnboardingBanner'
import { SiteClassSyncProviderFragment, useTeacherSiteClassSyncProvidersQuery } from '../../schema'
import { ClassSyncProviderResource } from '../../types'
import { getFetchClassesFromProvider } from '../steps/fetchClassesFromProvider'

interface ProviderButtonProps {
  resource: Pick<ClassSyncProviderResource, 'logos'>
  provider: SiteClassSyncProviderFragment
}
function ProviderButton({ resource, provider }: ProviderButtonProps) {
  const appOptions = useFeatureRoot().appOptions
  const { name, requiresSetup } = provider
  const { setSetupProps } = useOnboardingContext(true)
  const disabled = !!requiresSetup
  const button = (
    <ProviderButtonButton
      disabled={disabled}
      size={'small'}
      onPress={() => {
        setSetupProps({
          defaultStep: getFetchClassesFromProvider({
            provider,
          }),
        })
      }}
    >
      <ProviderButtonImage src={resource.logos.square} alt={`Logo for ${name}`} />
      <ProviderButtonContent>{name}</ProviderButtonContent>
    </ProviderButtonButton>
  )
  if (requiresSetup) {
    return (
      <Tooltip
        title={
          <div style={{ textAlign: 'left' }}>
            <Text>Requires Admin Setup</Text>
            <Text level={'s'} color={'secondary'}>
              Contact {appOptions.name} support for help.
            </Text>
          </div>
        }
      >
        {button}
      </Tooltip>
    )
  } else {
    return button
  }
}
const ProviderButtonButton = styled(Button)`
  padding: 0;
  --accent-default-color: rgba(255, 255, 255, 0.15);
  --accent-hover-color: rgba(255, 255, 255, 0.3);
  --accent-active-color: rgba(255, 255, 255, 0.3);
  --text-default-color: white;
  --text-hover-color: white;
  --text-active-color: white;
  display: flex;
  align-items: center;
  gap: 0;
`
const ProviderButtonContent = styled.div`
  padding: var(--vertical-padding) var(--horizontal-padding);
`
const ProviderButtonImage = styled.img`
  height: 36px;
`

export function OnboardingProviders() {
  const providerResources = useResource<ClassSyncProviderResource>('ClassSyncProvider')
  const { data, loading } = useTeacherSiteClassSyncProvidersQuery()
  const providers = data?.viewer?.teacher?.site?.classSyncProviders ?? []

  if (loading) {
    return <LoadingIndicator.InlineLabeled label={'Getting providers...'} />
  } else if (providers.length) {
    return (
      <s.ThemeProvider colorVariant={'light'}>
        <HSpaced space={'xs'} style={{ flexWrap: 'wrap' }}>
          {providers.map(provider => {
            const resource = providerResources.find(r => r.identifier === provider.identifier)
            if (!resource) throw new Error(`Cannot find provider resource '${provider.identifier}'`)
            return (
              <ProviderButton key={provider.identifier} provider={provider} resource={resource} />
            )
          })}
        </HSpaced>
      </s.ThemeProvider>
    )
  } else {
    return (
      <HSpaced space={'xs'} justify={'center'}>
        <Text color={'primary.secondary'}>
          <MinusCircle />
        </Text>
        <Text color={'primary.secondary'}>No providers found.</Text>
      </HSpaced>
    )
  }
}
