import React from 'react'
import styled from 'styled-components'

import { Reset } from './Reset'

export interface ColorInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  onChange?: (color: string) => void
}
export const ColorInput = React.forwardRef<HTMLInputElement, ColorInputProps>(
  ({ onChange, ...rest }, ref) => {
    return (
      <_ColorInput
        type={'color'}
        {...(rest as any)}
        ref={ref}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      />
    )
  },
)

export const _ColorInput = styled(Reset.Input)`
  min-height: 40px;
  width: 40px;
  border-radius: ${props => props.theme['@border-radius-base']};
  border: solid 1px ${props => props.theme['@border-color-split']};
  overflow: hidden;
  text-align: center;
  font-size: ${props => props.theme['@font-size-base']};
  line-height: ${props => props.theme['@line-height-base']};
  color: ${props => props.theme['@text-color']};
  padding: 0px;
  cursor: pointer;
  &::-webkit-color-swatch,
  &::-moz-color-swatch {
    border: none !important;
    border-color: transparent;
  }
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;
  }
`
