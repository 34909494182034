import { ClassFilterMenu } from '@thesisedu/feature-classes-react'
import { useResource } from '@thesisedu/feature-react'
import { Button, Menu, FilterMenuItem, BodySmall, Color } from '@thesisedu/react'
import { Check, Filter, NavArrowDown, Sort, MinusCircle } from '@thesisedu/react/icons'
import { DateFilterButton, DateFilterMode, HSpaced, MenuDateChildren } from '@thesisedu/web'
import { Dropdown, Input } from 'antd'
import React from 'react'

import { AssignmentCategoryFilterMenu } from '../AssignmentCategoryFilter'
import { GeneratedProvidersFilterMenuItem } from '../generated/GeneratedProvidersFilterMenuItem'
import { AssignmentFiltersInput, DueDateFilterType, OrderDirection } from '../schema'
import { AssignmentFilterResource } from '../types'

const DEBOUNCE = 500
export type Filters = AssignmentFiltersInput & { orderBy?: string; orderDirection?: OrderDirection }
export interface AssignmentFiltersProps {
  filters?: Filters
  name?: string
  onChange: (value: Filters, name: string | undefined) => void
  classId: string
}
export function AssignmentFilters({
  classId,
  filters: _filters,
  name: _name,
  onChange,
}: AssignmentFiltersProps) {
  const [name, _setName] = React.useState<string | undefined>(_name)
  const [visible, setVisible] = React.useState(false)

  const filters = _filters || {}
  const userSetFilters = !!(
    filters.categoryIds?.length ||
    filters.classIds?.length ||
    filters.dueDate ||
    filters.ungraded !== undefined ||
    filters.generatedProviders?.length ||
    filters.missing
  )
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('ungraded')) {
      onChange({ ...filters, ungraded: true }, name)
    }
    if (params.has('dueSoon')) {
      onChange(
        {
          ...filters,
          dueDate: {
            type: DueDateFilterType.After,
          },
          orderBy: 'dueAt',
          orderDirection: OrderDirection.Asc,
        },
        name,
      )
    }
  }, [])
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (name !== _name) {
        onChange(filters || {}, name)
      }
    }, DEBOUNCE)
    return () => clearTimeout(timeout)
  }, [name])
  const additionalFilters = useResource<AssignmentFilterResource>('AssignmentFilter')
  const additionalFilterHasValue = additionalFilters.some(filter => filter.hasValue(filters))
  return (
    <HSpaced>
      <Input.Search
        value={name}
        onChange={e => _setName(e.target.value)}
        placeholder={'Search'}
        allowClear
        style={{ width: '200px' }}
      />
      <Dropdown
        overlay={
          <Menu style={{ minWidth: 200 }}>
            {userSetFilters || additionalFilterHasValue ? (
              <>
                <Menu.Item
                  onClick={() =>
                    onChange(
                      {
                        orderBy: filters.orderBy,
                        orderDirection: filters.orderDirection,
                      },
                      name,
                    )
                  }
                >
                  <Color color={'@text-color-secondary'}>Reset Filters</Color>
                </Menu.Item>
                <Menu.Divider />
              </>
            ) : null}
            {classId ? null : (
              <ClassFilterMenu
                value={filters?.classIds || []}
                onChange={classIds => {
                  const ids = classIds?.filter(Boolean)
                  onChange(
                    {
                      ...filters,
                      classIds: ids,
                    },
                    name,
                  )
                }}
              />
            )}
            <FilterMenuItem<boolean>
              label={'Ungraded'}
              value={filters?.ungraded === undefined ? undefined : !!filters?.ungraded}
              options={[
                { label: 'Show Ungraded', value: true },
                { label: 'Hide Ungraded', value: false },
              ]}
              onChange={opt =>
                onChange(
                  {
                    ...filters,
                    ungraded: opt,
                  },
                  name,
                )
              }
              icon={<Check />}
            />
            <FilterMenuItem<boolean>
              label={'Missing'}
              value={filters?.missing === undefined ? undefined : !!filters?.missing}
              options={[{ label: 'Show Missing', value: true }]}
              onChange={opt =>
                onChange(
                  {
                    ...filters,
                    missing: opt,
                  },
                  name,
                )
              }
              icon={<MinusCircle />}
            />
            <DateFilterButton
              onChange={dateFilter => {
                onChange(
                  {
                    ...filters,
                    dueDate: dateFilter
                      ? {
                          date: dateFilter.date,
                          type: dateFilter.mode as unknown as DueDateFilterType,
                        }
                      : null,
                  },
                  name,
                )
              }}
              popoverProps={{
                placement: 'right',
                trigger: ['hover'],
              }}
              forceHide={!visible}
              label={'Due Date'}
              value={
                filters?.dueDate
                  ? {
                      date: filters.dueDate.date,
                      mode: filters.dueDate.type as unknown as DateFilterMode,
                    }
                  : undefined
              }
              children={MenuDateChildren}
            />
            {classId || filters.classIds?.length === 1 ? (
              <AssignmentCategoryFilterMenu
                classId={classId || filters.classIds![0]}
                onChange={categoryId => {
                  onChange(
                    {
                      ...filters,
                      categoryIds: categoryId ? [categoryId] : undefined,
                    },
                    name,
                  )
                }}
                value={filters?.categoryIds?.[0]}
              />
            ) : null}
            {classId ? (
              <GeneratedProvidersFilterMenuItem
                classId={classId}
                value={filters?.generatedProviders || undefined}
                onChange={providers => {
                  onChange(
                    {
                      ...filters,
                      generatedProviders: providers,
                    },
                    name,
                  )
                }}
              />
            ) : null}
            {additionalFilters.map(filter => (
              <filter.component
                key={filter.identifier}
                filters={filters}
                classId={classId}
                onChange={filters => onChange(filters, name)}
              />
            ))}
          </Menu>
        }
        trigger={['click']}
        mouseLeaveDelay={3}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Button
          icon={<Filter />}
          type={userSetFilters || additionalFilterHasValue ? 'primary' : 'default'}
        >
          <span>Filter</span>
          <NavArrowDown />
        </Button>
      </Dropdown>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu
            style={{ minWidth: 300 }}
            selectedKeys={[[filters.orderBy, filters.orderDirection].filter(Boolean).join(':-:')]}
            onClick={({ key }) => {
              const [newOrderBy, newOrderDirection] = key.toString().split(':-:')
              onChange(
                {
                  ...filters,
                  orderBy: newOrderBy,
                  orderDirection: newOrderDirection as OrderDirection,
                },
                name,
              )
            }}
          >
            <SortMenuItem
              key={'createdAt:-:ASC'}
              by={'createdAt'}
              order={OrderDirection.Asc}
              label={'Created Date'}
            />
            <SortMenuItem
              key={'createdAt:-:DESC'}
              by={'createdAt'}
              order={OrderDirection.Desc}
              label={'Created Date'}
            />
            <SortMenuItem
              key={'due:-:ASC'}
              by={'due'}
              order={OrderDirection.Asc}
              label={'Due Date'}
            />
            <SortMenuItem
              key={'due:-:DESC'}
              by={'due'}
              order={OrderDirection.Desc}
              label={'Due Date'}
            />
            <SortMenuItem
              key={'submission:-:ASC'}
              by={'submission'}
              order={OrderDirection.Asc}
              label={'Last Submission'}
            />
            <SortMenuItem
              key={'submission:-:DESC'}
              by={'submission'}
              order={OrderDirection.Desc}
              label={'Last Submission'}
            />
          </Menu>
        }
      >
        <Button icon={<Sort />}>
          <span>Sort</span>
          <NavArrowDown />
        </Button>
      </Dropdown>
    </HSpaced>
  )
}

interface SortMenuItemProps {
  by: string
  order: OrderDirection
  label: string
  [key: string]: any
}
function SortMenuItem({ by, order, label, ...rest }: SortMenuItemProps) {
  return (
    <Menu.Item
      key={[by, order].join(':-:')}
      style={{ display: 'flex', alignItems: 'center' }}
      {...rest}
    >
      <span>{label}</span>
      <BodySmall useDiv style={{ marginLeft: 'auto' }} color={'@text-color-secondary'}>
        {order === OrderDirection.Asc ? <span>Oldest First</span> : <span>Newest First</span>}
      </BodySmall>
    </Menu.Item>
  )
}
