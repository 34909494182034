import { useStudentUser } from '@thesisedu/feature-classes-react'
import { useMutateHook } from '@thesisedu/feature-react'
import { Block, NotFound } from '@thesisedu/web'
import React from 'react'

import { AssignmentStudent } from './AssignmentStudent'
import { getStudentClassFromTabName } from './GradingModal'
import { DEFAULT_NAVIGATION_ITEMS, REPORTS_ITEM } from './constants'
import { FullAssignment } from './types'
import { debug, warn } from '../log'
import { useAssignmentReports } from '../reporting/useAssignmentReports'
import { GradingModalNavigationItemsHook } from '../types'

export function useGradingPage(assignment: FullAssignment, selectedTab?: string) {
  const reports = useAssignmentReports({ assignment })
  let navigationItems = DEFAULT_NAVIGATION_ITEMS
  if (reports.length) {
    navigationItems = [...navigationItems, REPORTS_ITEM]
  }
  navigationItems = useMutateHook<GradingModalNavigationItemsHook>(
    'feature-assignments-react:grading-modal-navigation-items',
    navigationItems,
    { assignment },
  )
  const studentTab = getStudentClassFromTabName(selectedTab || '')
  const selectedStudent = assignment.students.edges.find(
    edge => edge.node.id === studentTab?.studentId && edge.classId === studentTab?.classId,
  )
  const classStudent = useStudentUser(
    studentTab ? studentTab.studentId : '',
    studentTab ? studentTab.classId : '',
    !studentTab,
  ).student
  const selectedNavigationItem = navigationItems.find(i => i.key === selectedTab)

  let title: string
  let tabContent: React.ReactElement | null
  const headerRightContent: React.ReactElement | null = null
  if (selectedNavigationItem) {
    title = selectedNavigationItem.label
    tabContent = <selectedNavigationItem.render assignment={assignment} />
  } else if (selectedStudent && classStudent && studentTab) {
    title = classStudent.user.name || classStudent.user.username
    tabContent = (
      <AssignmentStudent
        studentId={selectedStudent.node.id}
        classId={studentTab.classId}
        submissionId={studentTab.submissionId}
      />
    )
  } else {
    warn(`cannot find navigation item ${selectedTab}`)
    debug('navigationItems %O', navigationItems)
    debug('selectedNavigationItem %O', selectedNavigationItem)
    debug('selectedStudent %O', selectedStudent)
    debug('classStudent %O', classStudent)
    debug('studentTab %O', studentTab)
    title = ''
    tabContent = (
      <Block marginTop={'@size-l'}>
        <NotFound description={'We cannot find that content.'} />
      </Block>
    )
  }

  return {
    reports,
    navigationItems,
    selectedNavigationItem: navigationItems.find(item => item.key === selectedTab),
    title,
    tabContent,
    headerRightContent,
    studentTab,
    classStudent,
  }
}
