import { Format } from '@thesisedu/feature-utils'
import { Button } from '@thesisedu/ui'
import React from 'react'

import { TeacherSelectorModal } from './TeacherSelectorModal'
import { TeacherSelectorButtonProps } from './types'

export const TeacherSelectorButton: React.FC<
  React.PropsWithChildren<TeacherSelectorButtonProps>
> = ({ label = 'Select Teachers', buttonProps, ...props }) => {
  const [visible, setVisible] = React.useState(false)
  const { selectedTeachers } = props
  return (
    <TeacherSelectorModal
      {...props}
      visible={visible}
      onVisibleChange={setVisible}
      onSelected={async (...params) => {
        if (props.onSelected) {
          await props.onSelected(...params)
        }
        setVisible(false)
      }}
      trigger={
        <Button
          {...buttonProps}
          children={
            selectedTeachers?.length
              ? `Selected ${Format.plural('teacher', selectedTeachers.length)}`
              : label
          }
        />
      }
    />
  )
}
