import { ClassConfigurationFieldResource, useTeacherClass } from '@thesisedu/feature-classes-react'
import { Empty } from '@thesisedu/react'
import { Group } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { PresetsRoutes } from '../../groups/presets/PresetsRoutes'
import { ClassFragmentWithPermissions } from '../../types'

interface GroupsPresetsProps {
  classId: string
}
function GroupsPresets({ classId }: GroupsPresetsProps) {
  const { cls } = useTeacherClass<ClassFragmentWithPermissions>(classId, false)
  if (cls?.canUpdateDefaultGroups) {
    return <PresetsRoutes classId={classId} />
  } else if (cls) {
    return <Empty description={'You cannot edit groups presets for this class.'} />
  } else {
    return null
  }
}

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<ClassConfigurationFieldResource>({
    type: 'ClassConfigurationField',
    identifier: 'groupsPresets',
    group: 'Groups Presets',
    groupIcon: <Group />,
    skipForm: true,
    weight: -5,
    renderField({ classId }) {
      if (classId) {
        return <GroupsPresets classId={classId} />
      } else return null
    },
  })
}
