import { useSwitch } from '@react-aria/switch'
import { useObjectRef } from '@react-aria/utils'
import { motion } from 'framer-motion'
import React from 'react'
import { ToggleProps, useToggleState } from 'react-stately'

import { styled, s, VisuallyHidden, Text } from '../..'
import { Cancel, Check } from '../../../icons'
import { TextLevelMap } from '../../style/sharedVariants/size'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusRing } from '../FocusRing/FocusRing'

export interface SwitchProps
  extends Omit<ToggleProps, 'isDisabled' | 'isSelected'>,
    ExtendedBareFieldProps {
  className?: string
  checked?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  enabledIcon?: React.ReactElement
  enabledColor?: s.ColorAlias
  disabledIcon?: React.ReactElement
  disabledColor?: s.ColorAlias
}
function _Switch(
  {
    size = s.SharedVariants.Size.defaultValue,
    checked,
    disabled,
    className,
    style,
    enabledIcon = <Check />,
    enabledColor = 'green',
    disabledIcon = <Cancel />,
    disabledColor = 'red',
    ..._props
  }: SwitchProps,
  _ref: React.ForwardedRef<HTMLInputElement>,
) {
  const props = { ..._props, isSelected: checked }
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const { label, ...restBareField } = bareFieldProps
  const ref = useObjectRef(_ref)
  const state = useToggleState({ isDisabled: disabled, ...props })
  const { inputProps } = useSwitch(
    { isDisabled: disabled, 'aria-label': typeof label === 'string' ? label : undefined, ...props },
    state,
    ref,
  )
  return (
    <BareField {...restBareField} style={style}>
      <_Container {...(rest as any)}>
        <FocusRing within>
          <_CheckboxContainer
            className={s.variants<typeof _variants>(className, { disabled }, size)}
            style={
              {
                '--switch-selected-background': s.color(enabledColor),
                '--switch-deselected-background': s.color(disabledColor),
                background: state.isSelected
                  ? 'var(--switch-selected-background)'
                  : 'var(--switch-deselected-background)',
              } as any
            }
          >
            <VisuallyHidden>
              <input {...inputProps} ref={ref} />
            </VisuallyHidden>
            {enabledIcon}
            {disabledIcon}
            <_Thumb
              animate={{
                translateX: state.isSelected ? '1.475em' : '0.125em',
                translateY: '0.125em',
              }}
              initial={{
                translateX: state.isSelected ? '1.625em' : '0.125em',
                translateY: '0.125em',
              }}
              transition={{
                type: 'spring',
                duration: 0.3,
              }}
            />
          </_CheckboxContainer>
        </FocusRing>
        <Text level={TextLevelMap[size]} as={'div'}>
          {label}
        </Text>
      </_Container>
    </BareField>
  )
}
export const Switch = React.forwardRef(_Switch)

const { css } = s
const status = {
  disabled: css`
    background: ${s.color('element')} !important;
    --switch-foreground: ${s.color('secondary')};
  `,
} satisfies s.Variants

const _variants = s.merge(s.SharedVariants.Size.Variants, status)

const _Container = styled.label`
  display: flex;
  align-items: center;
`
const _CheckboxContainer = s.styledWithVariants(
  'div',
  'CheckboxContainer',
  css`
    width: 3em;
    height: 1.5em;
    margin-right: 0.5em;
    transition:
      opacity 0.1s linear,
      background 0.1s linear;
    border-radius: 9999px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.3em;
    color: var(--switch-foreground, white);
  `,
  _variants,
)
const _Thumb = styled(motion.div)`
  border-radius: 9999px;
  background: white;
  height: 1.25em;
  width: 1.4em;
  position: absolute;
  left: 0em;
  top: 0em;
`
