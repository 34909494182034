import { FeatureRoot, CLI_HOOK } from '@thesisedu/feature'
import React from 'react'
import yargs from 'yargs'

import { ReactProvider } from './contexts/ReactContext'
import { ReactAppOptions, ReactHooks, RootConfiguration, WrapAppContext } from './types'

export abstract class FeatureReactRoot<Options extends ReactAppOptions> extends FeatureRoot {
  public appOptions!: Options

  constructor(appOptions: Options) {
    super(appOptions)
    this.deps.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
      return this.wrapAppWithDefaults(children)
    })
  }

  public get release() {
    return process.env.REACT_APP_RELEASE ?? 'development'
  }

  public abstract rootConfiguration(): RootConfiguration

  public async wrapAppWithDefaults(children: React.ReactElement): Promise<React.ReactElement> {
    return <ReactProvider root={this} theme={this.appOptions.uiTheme} children={children} />
  }

  public async bootstrapScripts(argv: yargs.Argv<any>): Promise<yargs.Argv<any>> {
    await this.prepare()
    return this.deps.hookManager.mutateHook<yargs.Argv<any>>(CLI_HOOK, argv, undefined)
  }

  public async wrapApp(children: React.ReactElement): Promise<React.ReactElement> {
    await this.prepare()
    return this.deps.hookManager.mutateHook<React.ReactElement, WrapAppContext<Options>>(
      [ReactHooks.WrapApp, this.rootConfiguration().wrapAppHook],
      children,
      { appOptions: this.appOptions },
      { reverse: true },
    )
  }
}
