import { useErrorService } from '@thesisedu/feature-react'
import { useFlag } from '@thesisedu/react'
import { Button, VSpaced, HSpaced, useToast } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { SelectDeviceContext } from '.'
import { ScreenRecordingSelect } from './ScreenRecordingSelect'
import { SelectAudioSource } from './SelectAudioSource'
import { SelectVideoSource } from './SelectVideoSource'
import { useGetStreamsCallback } from './getStreams'
import { debug } from '../../../log'
import { useRecordViewContext } from '../../RecordViewContext'
import { getRecord } from '../record'
import { RecordStateProps } from '../types'

export function RecordNew({ onTransition }: RecordStateProps<SelectDeviceContext>) {
  const { hideAudio, hideVideo } = useRecordViewContext(true)
  const [videoDevice, setVideoDevice] = React.useState<string | undefined>(undefined)
  const [audioDevice, setAudioDevice] = React.useState<string | undefined>(undefined)
  const [screenRecording, setScreenRecording] = useFlag<boolean | undefined>(
    'media:default-device:screen',
    undefined,
  )
  const [getStreams, { loading }] = useGetStreamsCallback()
  const errorService = useErrorService()
  const toast = useToast()
  const hasDevice = !!videoDevice || !!audioDevice || !!screenRecording
  return (
    <VSpaced space={'s'}>
      {hideVideo ? null : <SelectVideoSource value={videoDevice} onChange={setVideoDevice} />}
      {hideAudio ? null : <SelectAudioSource value={audioDevice} onChange={setAudioDevice} />}
      {hideVideo ? null : (
        <ScreenRecordingSelect value={screenRecording} onChange={setScreenRecording} />
      )}
      <Button
        size={'large'}
        variant={'primary'}
        style={{ width: '100%' }}
        disabled={!hasDevice}
        loading={loading}
        onPress={async () => {
          try {
            const opts = {
              videoDeviceId: hideVideo ? undefined : videoDevice,
              audioDeviceId: hideAudio ? undefined : audioDevice,
              screenRecording: hideVideo ? undefined : screenRecording,
            }
            debug('getting streams %O', opts)
            const streams = await getStreams(opts)
            onTransition(getRecord(streams))
          } catch (err: any) {
            debug('error getting streams %O', err)
            toast({
              title: 'There was an error setting up your devices for recording.',
              status: 'error',
            })
            errorService.reportError(err, {
              videoDevice,
              audioDevice,
              screenRecording,
            })
          }
        }}
      >
        <HSpaced justify={'space-between'} style={{ flex: 1 }}>
          <span>Get Ready to Record</span>
          <ArrowRight />
        </HSpaced>
      </Button>
    </VSpaced>
  )
}
