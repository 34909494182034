import { Form, NumberField, NumberField$ } from '@thesisedu/ui'
import React from 'react'

export function AssignmentTimeLimitField() {
  return (
    <Form.Field label={'Time limit'} labelRight={'in minutes'} name={'timeLimit'}>
      <MinutesField
        aria-label={'Time Limit'}
        minValue={0}
        formatOptions={{ minimumFractionDigits: 0, maximumFractionDigits: 2 }}
      />
    </Form.Field>
  )
}

function MinutesField({ value, onChange, ...rest }: NumberField$.NumberFieldProps) {
  return (
    <NumberField
      {...rest}
      value={value != null ? value / 60 : undefined}
      onChange={val => onChange?.(val != null ? val * 60 : 0)}
    />
  )
}
