export { CollapseProps as CollapseRootProps } from 'antd'
import { AntIconWrapper, styled } from '@thesisedu/web'
import { Collapse, CollapseProps } from 'antd'
import React from 'react'

import { NavArrowRight } from '../../icons'

export function CollapseRoot(props: React.PropsWithChildren<CollapseProps>) {
  return (
    <CollapseWrapper>
      <Collapse
        expandIcon={() => (
          <AntIconWrapper className={'ant-collapse-arrow'} children={<NavArrowRight />} />
        )}
        {...props}
      />
    </CollapseWrapper>
  )
}

const CollapseWrapper = styled.div`
  .ant-collapse-item > .ant-collapse-header {
    padding-left: 34px;
  }
  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0px;
    font-size: 24px;
    color: ${props => props.theme['@text-color-secondary']};
    transition: transform 0.25s linear;
    transform: rotate(0deg);
    padding: 0;
    top: 10px;
  }
  .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    transform: rotate(90deg);
  }
  .ant-collapse-content {
    padding-left: 34px;
    > .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
