import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface CourseReportsReactFeatureOptions {}

export class CourseReportsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-course-reports-react'
  static path = ''
  static requires: string[] = []
  public options!: CourseReportsReactFeatureOptions

  public reactResources() {
    require('./resources/ReportDimension/MultipleClasses').default(this)
    require('./resources/ReportDimension/MultipleStudents').default(this)
    require('./resources/ReportDimension/OneStudent').default(this)
    require('./resources/ReportDimension/Lessons').default(this)
    require('./resources/ReportMetric/TimeOnTask').default(this)
    require('./resources/ReportMetric/ContentVisited').default(this)
    require('./resources/ReportMetric/Grade').default(this)
    require('./resources/ReportMetric/NumAttempts').default(this)
    require('./resources/ReportMetricSummary/Grade').default(this)
    require('./resources/ReportFilter/IsLeader').default(this)
    require('./hooks/routes').default(this)
    require('./hooks/GroupsStudentDraggable/GroupsTooltip').default(this)
  }
}

export const courseReportsReactFeature: ReactUse = (opts: CourseReportsReactFeatureOptions) => [
  CourseReportsReactFeature,
  opts,
]
