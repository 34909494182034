import { SimpleSelect, SimpleSelectProps } from '@thesisedu/web'
import React from 'react'

import { InstrumentResource } from '../types'
import { useInstruments } from '../useInstruments'

export interface InstrumentSelectProps
  extends Omit<SimpleSelectProps, 'value' | 'onChange' | 'options'> {
  value?: string
  onChange?: (instrument: string) => void
  instruments?: InstrumentResource[]
}
export function InstrumentSelect({
  value,
  onChange,
  instruments: _instruments,
  ...rest
}: InstrumentSelectProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const instruments = (_instruments || useInstruments()).filter(instrument => !instrument.hidden)
  return (
    <SimpleSelect
      value={value}
      onChange={onChange ? key => onChange(key) : undefined}
      options={instruments.map(instrument => ({
        value: instrument.identifier!,
        label: instrument.name,
      }))}
      {...rest}
    />
  )
}
