import { Reset, styled } from '@thesisedu/react'
import { Search } from '@thesisedu/react/icons'
import React from 'react'

export interface ExploreSearchFieldProps {
  onConfirm: (searchText: string) => void
}
export function ExploreSearchField({ onConfirm }: ExploreSearchFieldProps) {
  const [value, setValue] = React.useState('')
  const [focused, setFocused] = React.useState(false)
  const showPlaceholder = !focused && !value
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <Container
      onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }}
      className={focused ? 'focused' : ''}
    >
      <Input
        value={value}
        onChange={e => setValue(e.target.value)}
        ref={inputRef}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onKeyPress={event => {
          if (event.key.toLowerCase() === 'enter' && inputRef.current?.value) {
            onConfirm(inputRef.current.value)
          }
        }}
      />
      <PlaceholderContainer style={{ opacity: showPlaceholder ? 1 : 0 }}>
        <Search />
        <span>Search for Content...</span>
      </PlaceholderContainer>
      <SearchButton
        style={{
          opacity: showPlaceholder ? 0 : 1,
          pointerEvents: showPlaceholder ? 'none' : 'all',
        }}
        onClick={() => {
          if (inputRef.current?.value) {
            onConfirm(inputRef.current.value)
          }
        }}
      >
        <Search />
      </SearchButton>
    </Container>
  )
}

const Input = styled.input`
  width: 100%;
  font-size: ${props => props.theme['@font-size-larger']};
  color: ${props => props.theme['@gray-6']};
  box-sizing: border-box;
  padding: 0 ${props => props.theme['@size-l']};
  text-align: center;
  background: none;
  border: none;
  outline: none;
`
const SearchButton = styled(Reset.Button)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    opacity 0.1s linear,
    background 0.1s linear,
    color 0.1s linear;
  font-size: ${props => props.theme['@size-m']};
  color: ${props => props.theme['@gray-6']};
  background: transparent;
  &:hover {
    background: ${props => props.theme['@gray-3']};
    color: ${props => props.theme['@gray-7']};
  }
`
const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  color: ${props => props.theme['@text-color-secondary']};
  pointer-events: none;
  transition: opacity 0.1s linear;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
const Container = styled.div`
  position: relative;
  border-radius: ${props => props.theme['@border-radius-large']};
  font-size: ${props => props.theme['@font-size-larger']};
  background: ${props => props.theme['@gray-1']};
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  overflow: hidden;
  &:hover,
  &.focused {
    background: ${props => props.theme['@gray-2']};
  }
`
