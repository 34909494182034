import { Accessibility } from '@thesisedu/feature-utils'

import { useThemeContext } from './ThemeContext'
import { getThemeValue } from './theme'
import { ColorAlias, colorToThemePath } from './variables'

export function useForegroundColor(background: ColorAlias): string {
  const { colorVariant = 'light' } = useThemeContext(false) ?? {}
  const lightVariant = getThemeValue(colorToThemePath('text', 'light')).toString()
  const darkVariant = getThemeValue(colorToThemePath('text', 'dark')).toString()
  const backgroundColor = getThemeValue(colorToThemePath(background, colorVariant)).toString()
  return Accessibility.best(lightVariant, darkVariant, backgroundColor)
}
