import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { CreateDistrictButton } from './CreateDistrictButton'
import {
  DistrictDocument,
  DistrictFragment,
  DistrictsDocument,
  DistrictsQuery,
  DistrictsQueryVariables,
} from './schema'

export type DistrictSelectProps = Partial<
  SingleSelectButtonProps<DistrictFragment, DistrictsQuery, DistrictsQueryVariables>
>
export const DistrictSelect: React.FC<React.PropsWithChildren<DistrictSelectProps>> = props => {
  return (
    <SingleSelectButton<DistrictFragment, DistrictsQuery, DistrictsQueryVariables>
      filterVariableName={'name'}
      maxSelection={1}
      getItemName={item => item.name}
      getResult={data => data?.districts}
      setResult={(previous, result) => ({
        ...previous,
        districts: {
          ...previous.districts,
          ...result,
        },
      })}
      document={DistrictsDocument}
      nodeDocument={DistrictDocument}
      selectLabel={'Select a District'}
      addButton={
        <CreateDistrictButton
          onCreated={district => (props.onChange ? props.onChange(district.id) : null)}
        />
      }
      {...props}
    />
  )
}
