import React from 'react'
import { Helmet } from 'react-helmet'

export interface PageHeadContextValue {
  titleSuffix?: string
  titlePrefix?: string
  setTitlePrefix: (prefix?: string) => void
}
export const PageHeadContext = React.createContext<PageHeadContextValue | undefined>(undefined)
export function PageHeadProvider({
  children,
  ...rest
}: React.PropsWithChildren<Omit<PageHeadContextValue, 'titlePrefix' | 'setTitlePrefix'>>) {
  const [titlePrefix, setTitlePrefix] = React.useState<string | undefined>(undefined)
  return (
    <PageHeadContext.Provider
      value={{ ...rest, titlePrefix, setTitlePrefix }}
      children={children}
    />
  )
}

export function usePageHeadPrefix(prefix?: string) {
  const { setTitlePrefix } = React.useContext(PageHeadContext) || {}
  React.useEffect(() => {
    if (setTitlePrefix) {
      setTitlePrefix(prefix)
    }
  }, [prefix])
}

export interface PageHeadProps {
  title: string
}
export function PageHead({ title: _title, children }: React.PropsWithChildren<PageHeadProps>) {
  const { titleSuffix, titlePrefix } = React.useContext(PageHeadContext) || {}
  const titleSegments = _title.split(' - ')
  if (titleSuffix && !titleSegments.includes(titleSuffix)) titleSegments.push(titleSuffix)
  const title = `${titlePrefix ? `[${titlePrefix}] ` : ''}${titleSegments.join(' - ')}`
  const AnyHelmet = Helmet as any
  return (
    <AnyHelmet>
      <title>{title}</title>
      <meta name={'og:title'} content={title} />
      <meta name={'og:title'} content={title} />
      {children}
    </AnyHelmet>
  )
}
