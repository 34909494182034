import { useMutateHook } from '@thesisedu/feature-react'
import {
  UserLeftItemsHook,
  UserRoutesHook,
  UserTopItemsHook,
  useViewerContext,
} from '@thesisedu/feature-users-react'
import { UserSettings } from '@thesisedu/feature-users-react/dist/web/UserSettings'
import { useProtection } from '@thesisedu/feature-users-web'
import { Shell, NotFoundPage } from '@thesisedu/feature-web'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { WrapWithStudentContext } from '../../WrapWithStudentContext'

export function Student() {
  useProtection({ allowedGroups: ['STUDENT'], redirect: '/' })
  const viewer = useViewerContext(false)
  const routes = useMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    [<Route key={'/settings/*'} path={'/settings/*'} element={<UserSettings />} />],
    { viewer, group: 'STUDENT' },
  )
  const topItems = useMutateHook<UserTopItemsHook>('feature-users-react:top-items', [], {
    viewer,
    group: 'STUDENT',
  })
  const leftItems = useMutateHook<UserLeftItemsHook>('feature-users-react:left-items', [], {
    viewer,
    group: 'STUDENT',
  })

  if (!viewer) return null
  return (
    <WrapWithStudentContext>
      <Shell.Shell hideBreadcrumb topItems={topItems} leftItems={leftItems}>
        <Routes>
          {routes}
          <Route key={'404'} path={'*'} element={<NotFoundPage />} />
        </Routes>
      </Shell.Shell>
    </WrapWithStudentContext>
  )
}
