import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import { ErrorPage } from '@thesisedu/web'
import React from 'react'

export interface Error404Props {
  onBack: () => void
}
export function Error404({ onBack }: Error404Props) {
  return (
    <ErrorPage
      title={'Not found.'}
      technical={'Error 404'}
      description={
        'Yikes! We could not find the page you were looking for. Double-check and make sure you ' +
        'clicked on the right link.'
      }
      action={
        <>
          <ArrowLeftOutlined />
          <span>Go Back</span>
        </>
      }
      onActionClick={onBack}
    />
  )
}
