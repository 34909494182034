import React from 'react'

export interface TabsContextValue {
  selectedTab?: string
}
export const TabsContext = React.createContext<TabsContextValue | undefined>(undefined)

export function useTabsContext(): TabsContextValue {
  const context = React.useContext(TabsContext)
  if (!context) {
    throw new Error('TabsContext is required, yet not provided.')
  }
  return context
}
