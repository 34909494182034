import { ListItemNode, ListNode } from '@lexical/list'
import { $isImageNode } from '@thesisedu/feature-widgets-core'
import { $isParagraphNode } from 'lexical'

import { splitListIfRequired } from '../../../nodes/List'
import { Validator } from '../types'

export default {
  node: ListItemNode,
  validate(node) {
    for (const child of node.getChildren()) {
      if ($isParagraphNode(child)) {
        for (const childChild of child.getChildren()) {
          child.insertBefore(childChild)
        }
        child.remove()
      }
      if ($isImageNode(child)) {
        splitListIfRequired([child], node.getIndexWithinParent() + 1, node.getParent() as ListNode)
      }
    }
  },
} satisfies Validator<ListItemNode>
