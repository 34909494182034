import { PureQueryOptions } from '@apollo/client/core'
import { onMutationError } from '@thesisedu/feature-apollo-react'

import { ClassCourseDocument, useCopySegmentSettingsMutation as useMutation } from '../schema'

export const useCopySegmentSettingsMutation: typeof useMutation = opts => {
  const result = useMutation({
    onError: onMutationError('There was an error copying your settings.'),
    ...opts,
  })

  return [
    mutateOpts =>
      result[0]({
        refetchQueries: mutateOpts?.variables?.input.destinationIds.map<PureQueryOptions>(
          classId => ({
            query: ClassCourseDocument,
            variables: { id: classId },
          }),
        ),
        ...mutateOpts,
      }),
    result[1],
  ]
}
