import { AuthRoutesHook, AuthRedirecting } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { CleverReactFeature } from '../../CleverReactFeature'
import { CleverAuthRoute } from '../../web/CleverAuthRoute'

export default function (feature: CleverReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [
        ...routes,
        <Route path={'clever-redirect'} element={<AuthRedirecting />} key={'clever-redirect'} />,
        <Route path={'clever'} element={<CleverAuthRoute />} key={'clever'} />,
      ]
    },
  )
}
