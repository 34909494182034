import { ApolloLink, Observable, Operation, NextLink, FetchResult } from '@apollo/client'
import { ErrorResponse } from '@apollo/client/link/error'

/**
 * This is from: https://github.com/apollographql/apollo-link/pull/1066/files
 *
 * Apollo hasn't yet merged this in with their new structure of link, so I'm adding
 * it manually here.
 */

export interface ErrorHandler {
  (
    error: ErrorResponse,
  ): Observable<FetchResult> | void | Promise<undefined | void | Observable<FetchResult>>
}

export function onError(errorHandler: ErrorHandler): ApolloLink {
  return new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      let sub: any
      let retriedSub: any
      let retriedResult: Observable<FetchResult> | void
      let handling = false
      let completed = false
      try {
        sub = forward(operation).subscribe({
          next: async result => {
            if (result.errors) {
              handling = true
              retriedResult = await Promise.resolve<Observable<FetchResult> | void>(
                errorHandler({
                  graphQLErrors: result.errors,
                  response: result,
                  operation,
                  forward,
                }),
              )
              handling = false

              if (retriedResult) {
                retriedSub = retriedResult.subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                })
                return
              }
            }
            observer.next(result)
            if (completed) {
              observer.complete()
            }
          },
          error: async networkError => {
            handling = true
            retriedResult = await Promise.resolve<Observable<FetchResult> | void>(
              errorHandler({
                operation,
                networkError,
                graphQLErrors: networkError && networkError.result && networkError.result.errors,
                forward,
              }),
            )
            handling = false

            if (retriedResult) {
              retriedSub = retriedResult.subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              })
              return
            }
            observer.error(networkError)
            if (completed) {
              observer.complete()
            }
          },
          complete: () => {
            completed = true
            // disable the previous sub from calling complete on observable
            // if retry is in flight
            if (!handling && !retriedResult) {
              observer.complete.bind(observer)()
            }
          },
        })
      } catch (e: any) {
        Promise.resolve<Observable<FetchResult> | void>(
          errorHandler({ networkError: e, operation, forward }),
        ).then(
          () => {
            observer.error(e)
          },
          () => {
            observer.error(e)
          },
        )
      }

      return () => {
        if (sub) sub.unsubscribe()
        if (retriedSub) sub.unsubscribe()
      }
    })
  })
}

export class ErrorWithPromiseLink extends ApolloLink {
  private link: ApolloLink
  constructor(errorHandler: ErrorHandler) {
    super()
    this.link = onError(errorHandler)
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    return this.link.request(operation, forward)
  }
}
