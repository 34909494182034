export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'

export interface UploadTypes {
  [type: string]: {
    label: string
  }
}
export const FILE_UPLOAD_TYPES: UploadTypes = {
  DOCUMENT: {
    label: 'Document',
  },
  VIDEO: {
    label: 'Video',
  },
}
