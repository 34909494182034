import React from 'react'

import { AntIconWrapper } from './ant/AntIconWrapper'
import { Button, ButtonProps } from './ant/Button'
import { Dropdown, DropdownOption, getSelectableMenuOptions } from './ant/Dropdown'
import { Menu } from './ant/Menu'
import { Color } from './typography'
import { NavArrowDown, DeleteCircle } from '../icons'

export interface FilterButtonProps<OptValue> {
  label: string
  value: OptValue | undefined | null
  onChange: (value?: OptValue) => void
  disabled?: boolean
  options: DropdownOption<OptValue>[]
  hideViewAllOption?: boolean
  buttonProps?: Partial<ButtonProps>
}

export function FilterButton<OptValue>(props: FilterButtonProps<OptValue>) {
  const { value, onChange, options } = props
  return (
    <MultiFilterButton<OptValue>
      {...props}
      options={options}
      value={value !== undefined && value !== null ? [value] : undefined}
      onChange={opt => {
        onChange(opt ? opt[opt.length - 1] : undefined)
      }}
    />
  )
}

export interface FilterMenuItemProps<OptValue>
  extends Omit<FilterButtonProps<OptValue>, 'buttonProps'> {
  [key: string]: any
}
export function FilterMenuItem<OptValue>(props: FilterMenuItemProps<OptValue>) {
  const { value, onChange, options } = props
  return (
    <MultiFilterMenuItem<OptValue>
      {...props}
      options={options}
      value={value !== undefined && value !== null ? [value] : undefined}
      onChange={opt => {
        onChange(opt ? opt[opt.length - 1] : undefined)
      }}
    />
  )
}

export interface MultiFilterButtonProps<OptValue>
  extends Partial<Omit<FilterButtonProps<OptValue>, 'value' | 'onChange' | 'options'>> {
  options: DropdownOption<OptValue>[]
  value: OptValue[] | undefined | null
  onChange: (value: OptValue[] | undefined) => void
}

type AllValue = 'all-value'
const ALL_VALUE: AllValue = 'all-value'

interface UseFilterButtonProps<OptValue> extends MultiFilterButtonProps<OptValue> {}
function useFilterButton<OptValue>({
  value,
  options,
  hideViewAllOption,
  label,
  onChange,
}: UseFilterButtonProps<OptValue>) {
  const viewAllOption = {
    label: 'View All',
    value: ALL_VALUE,
    icon: <AntIconWrapper children={<DeleteCircle />} />,
  }
  const hasValue = value !== null && value !== undefined && value?.length
  const optionsWithViewAll: DropdownOption<OptValue | AllValue>[] =
    hideViewAllOption || !hasValue ? options : [viewAllOption, 'divider', ...options]
  const selectedOptions = hasValue
    ? value
        .map(val => {
          return getSelectableMenuOptions(options).find(opt => opt.value === val)
        })
        .filter(Boolean)
    : []
  const selectedLabel =
    selectedOptions?.length > 1
      ? `${selectedOptions.length} selected`
      : selectedOptions[0]
      ? selectedOptions[0].label
      : null
  const buttonInner = selectedOptions?.length ? (
    <>
      <strong>{label}: </strong>
      <span>
        &nbsp;
        {selectedLabel?.length && selectedLabel.length > 30
          ? `${selectedLabel?.slice(0, 30)}...`
          : selectedLabel}
      </span>
    </>
  ) : (
    `Filter ${label}`
  )

  const onSelected = (selectedValue: 'all-value' | OptValue) => {
    if (selectedValue === ALL_VALUE) {
      onChange(undefined)
    } else {
      const selectedOptValue = selectedValue
      const isSelected = value?.includes(selectedOptValue)
      const without = value ? value.filter(v => v !== selectedOptValue) : []
      onChange(isSelected ? without : [...without, selectedOptValue])
    }
  }

  return { buttonInner, optionsWithViewAll, selectedOptions, onSelected }
}

export function MultiFilterButton<OptValue>(props: MultiFilterButtonProps<OptValue>) {
  const { selectedOptions, optionsWithViewAll, onSelected, buttonInner } = useFilterButton(props)
  const { buttonProps } = props

  return (
    <Dropdown<OptValue | AllValue>
      disabled={props.disabled}
      selectedOptions={selectedOptions}
      options={optionsWithViewAll}
      onOptionSelected={selectedOption => {
        onSelected(selectedOption.value)
      }}
      children={
        <Button
          type={selectedOptions?.length ? 'primary' : 'default'}
          style={{ display: 'flex', alignItems: 'center' }}
          {...buttonProps}
        >
          {buttonInner}&nbsp;
          <NavArrowDown />
        </Button>
      }
    />
  )
}

export interface MultiFilterMenuItemProps<OptValue>
  extends Omit<MultiFilterButtonProps<OptValue>, 'buttonProps'> {
  [key: string]: any
}
export function MultiFilterMenuItem<OptValue>({
  onChange,
  options,
  value,
  disabled,
  hideViewAllOption,
  label,
  ...rest
}: MultiFilterMenuItemProps<OptValue>) {
  const { selectedOptions, optionsWithViewAll, onSelected, buttonInner } = useFilterButton({
    onChange,
    options,
    value,
    disabled,
    hideViewAllOption,
    label,
  })

  return (
    <Menu.SubMenu
      title={
        <Color color={selectedOptions?.length ? '@primary-color' : '@text-color'}>
          {buttonInner}
        </Color>
      }
      {...rest}
    >
      {optionsWithViewAll.map(opt => {
        if (opt === 'divider') {
          return <Menu.Divider />
        } else {
          return (
            <Menu.Item
              key={JSON.stringify(opt.value)}
              // @ts-ignore they removed this?
              isSelected={selectedOptions.some(
                selectedOption =>
                  selectedOption &&
                  JSON.stringify(selectedOption.value) === JSON.stringify(opt.value),
              )}
              danger={opt.danger}
              onClick={() => {
                onSelected(opt.value)
              }}
              icon={
                !opt.icon || opt.icon?.type === 'AntIconWrapper' ? (
                  opt.icon
                ) : (
                  <AntIconWrapper children={opt.icon} />
                )
              }
            >
              {opt.label}
            </Menu.Item>
          )
        }
      })}
    </Menu.SubMenu>
  )
}
