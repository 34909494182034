import * as React from 'react'

function SvgNumber7(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#EC6BA2'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#EC6BA2'}
          fillRule={'nonzero'}
          d={
            'M141.916 200.631l15.525-64.371 13.442-.568 6.058-34.458-12.306.379 16.85-57.934-107.538 5.869L76.598 91.2l49.036-5.869-7.384 17.986-23.666 1.325-2.083 33.701 15.146-.568-17.796 56.798z'
          }
        />
        <path
          fill={'#502D1B'}
          fillRule={'nonzero'}
          d={
            'M166.488 100.176l16.864-57.98-110.869 6.051 2.832 44.495 48.151-5.763-6.162 15.01-24.02 1.345-2.253 36.443 14.717-.552-17.711 56.525 54.933 6.392 15.567-64.549 13.512-.57 6.544-37.219-12.105.372zM75.411 50.848l104.207-5.687-16.836 57.888 12.508-.385-5.573 31.697-13.373.566-15.482 64.194-49.198-5.725 17.883-57.072-15.576.584 1.913-30.958 23.311-1.305 8.605-20.962-49.92 5.975-2.47-38.81z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber7 = React.memo(SvgNumber7)
export default MemoSvgNumber7
