import { Format, toGlobalId } from '@thesisedu/feature-utils'
import {
  BodyExtraSmall,
  BodySmall,
  DATE_FORMATS,
  FontWeight,
  HSpaced,
  Space,
  styled,
  Dropdown,
} from '@thesisedu/react'
import { NavigationListItem, RenderNavigationListItemProps } from '@thesisedu/react/devtools'
import { CloudDownload } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

import { DetailedLogFragment } from '../../schema'
import { useViewerContext } from '../../useViewerContext'

export function LogFragmentListItem({
  data,
  onClick,
  isSelected,
}: RenderNavigationListItemProps<DetailedLogFragment>) {
  const date = data.filename.replace('.log.gz', '')
  const userId = useViewerContext(true).id
  return (
    <Dropdown
      trigger={['contextMenu']}
      options={[
        {
          label: 'Download',
          value: 'download',
          icon: <CloudDownload />,
          onClick() {
            window.open(data.signedUrl, '_blank')
          },
        },
      ]}
    >
      <NavigationListItem onClick={onClick} isSelected={isSelected}>
        <Container>
          <HSpaced space={'@size-xxs'}>
            <BodySmall weight={FontWeight.Bold}>{moment(date).format(DATE_FORMATS.TEXT)}</BodySmall>
            <Space />
            <BodyExtraSmall color={'@text-color-secondary'}>
              {Format.filesize(data.sizeInBytes)}
            </BodyExtraSmall>
          </HSpaced>
          <BodyExtraSmall color={'@text-color-secondary'}>
            {data.tags.filter(tag => toGlobalId('User', tag) !== userId).join(', ')}
          </BodyExtraSmall>
        </Container>
      </NavigationListItem>
    </Dropdown>
  )
}

const Container = styled.div`
  * {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
