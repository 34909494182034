import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'
import { Block } from '@thesisedu/react'
import { Wrench } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { BulkUpdateAssignmentsInClassButton } from '../../BulkUpdateAssignmentsInClassButton'

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<ClassConfigurationFieldResource>({
    type: 'ClassConfigurationField',
    identifier: 'bulkUpdateAssignments',
    group: 'Maintenance',
    groupIcon: <Wrench />,
    weight: 1000,
    filter(viewer, isImpersonating) {
      return !!isImpersonating
    },
    renderField({ classId }) {
      if (classId) {
        return (
          <Block marginBottom={'@size-l'}>
            <BulkUpdateAssignmentsInClassButton classId={classId} />
          </Block>
        )
      } else return null
    },
  })
}
