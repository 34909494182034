import { useStudentUserId } from '@thesisedu/feature-teachers-react'
import React from 'react'

/**
 * Hook to quickly determine if a student user is the teacher user or not.
 * @returns a function, when called with a user ID, returns if it is the
 *  teacher user or not.
 */
export function useIsTeacherUser() {
  const studentUserId = useStudentUserId()
  return React.useCallback(
    (userId: string) => {
      return userId === studentUserId
    },
    [studentUserId],
  )
}
