import { ReactFeature } from '@thesisedu/feature-react'
import { MutateTagsEditorAdditionalEditors } from '@thesisedu/feature-tags-react'

import { StandardsEditor } from '../course/StandardsEditor'

export default function addEditors(feature: ReactFeature) {
  feature.hookManager.registerMutateHook<MutateTagsEditorAdditionalEditors>(
    'feature-tags:tags-editor-additional-editors',
    editors => {
      return [...editors, StandardsEditor]
    },
  )
}
