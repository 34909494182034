import { SettingOutlined } from '@ant-design/icons'
import { Body, Block, HSpaced, StyledThemeContext, VSpaced } from '@thesisedu/web'
import { Input, Button, Form, Drawer, Checkbox, InputNumber } from 'antd'
import React from 'react'

import { SheetMusicEditorProps } from './SheetMusicEditor'
import { SafeSheetMusicViewer } from '../viewer/SafeSheetMusicViewer'
import { SheetMusicViewerProps } from '../viewer/SheetMusicViewer'

type FormValues = Omit<SheetMusicViewerProps, 'abc'>
export function AdvancedSheetMusicEditor({ value, onChange }: SheetMusicEditorProps) {
  const theme = React.useContext(StyledThemeContext)
  const [optionsVisible, setOptionsVisible] = React.useState(false)
  const [chordsEnabled, setChordsEnabled] = React.useState(!!value?.showChords)
  const [solfegeEnabled, setSolfegeEnabled] = React.useState(!!value?.showSolfege)
  const [abc, setAbc] = React.useState(value?.abc)
  const [form] = Form.useForm<FormValues>()
  React.useEffect(() => {
    if (abc !== value?.abc) {
      const timeout = setTimeout(() => {
        if (onChange) {
          onChange({
            ...value,
            ...form.getFieldsValue(),
            abc: abc || '',
          })
        }
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [abc])
  React.useEffect(() => {
    form.setFieldsValue(value || {})
    setChordsEnabled(!!value?.showChords)
    setSolfegeEnabled(!!value?.showSolfege)
  }, [value])
  return (
    <>
      <Drawer
        visible={optionsVisible}
        onClose={() => {
          form.submit()
        }}
        width={400}
      >
        <Form
          form={form}
          onFinish={values => {
            setOptionsVisible(false)
            if (onChange) {
              onChange({ ...value, abc: abc || '', ...values })
            }
          }}
          layout={'vertical'}
          onValuesChange={(_, allValues) => {
            setChordsEnabled(!!allValues.showChords)
            setSolfegeEnabled(!!allValues.showSolfege)
          }}
        >
          <Form.Item label={'Title'} name={'title'}>
            <Input placeholder={'Cool Song'} />
          </Form.Item>
          <Form.Item label={'Chords'} name={'showChords'} valuePropName={'checked'}>
            <Checkbox>Show Chords</Checkbox>
          </Form.Item>
          {chordsEnabled ? (
            <Form.Item label={'Chord Opacity'} name={'chordOpacity'}>
              <InputNumber min={0} max={1} precision={2} step={0.1} />
            </Form.Item>
          ) : null}
          <Form.Item label={'Solfege'} name={'showSolfege'} valuePropName={'checked'}>
            <Checkbox>Show Solfege</Checkbox>
          </Form.Item>
          {solfegeEnabled ? (
            <Form.Item label={'Solfege Offset'} name={'solfegeOffset'}>
              <InputNumber precision={0} />
            </Form.Item>
          ) : null}
          <Form.Item label={'Other Elements'}>
            <VSpaced style={{ width: '100%' }}>
              <Form.Item noStyle name={'showLyrics'} valuePropName={'checked'}>
                <Checkbox>Show Lyrics</Checkbox>
              </Form.Item>
              <Form.Item noStyle name={'showNote'} valuePropName={'checked'}>
                <Checkbox style={{ marginLeft: 0 }}>Show Note Letters</Checkbox>
              </Form.Item>
            </VSpaced>
          </Form.Item>
          <Button block type={'primary'} onClick={form.submit}>
            Save Changes
          </Button>
        </Form>
      </Drawer>
      <Block marginBottom={'@size-s'}>
        <HSpaced align={'flex-start'} space={'@size-m'}>
          <Input.TextArea
            value={abc}
            onChange={e => setAbc(e.target.value)}
            placeholder={'Start composing your music here with ABC syntax...'}
            style={{ fontFamily: theme['@code-family'], flex: 2 }}
            autoSize={{ minRows: 6, maxRows: 20 }}
            spellCheck={false}
          />
          <div style={{ flex: 1 }}>
            <Body isBlock>
              Only{' '}
              <a href={'http://abcnotation.com/'} target={'_blank'}>
                ABC Notation
              </a>{' '}
              is supported.
            </Body>
            <Body isBlock>
              <a href={'http://abcnotation.com/examples'} target={'_blank'}>
                Some examples are available here,
              </a>{' '}
              and{' '}
              <a
                href={'http://www.stephenmerrony.co.uk/uploads/ABCquickRefv0_6.pdf'}
                target={'_blank'}
              >
                here
              </a>{' '}
              is a cheat-sheet you might find helpful.
            </Body>
            <Button icon={<SettingOutlined />} onClick={() => setOptionsVisible(true)}>
              Edit Options
            </Button>
          </div>
        </HSpaced>
      </Block>
      {value?.abc ? <SafeSheetMusicViewer {...value} /> : null}
    </>
  )
}
