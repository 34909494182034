import * as React from 'react'

function SvgNoteNoStem(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <path
        fillRule={'evenodd'}
        d={
          'M96.427 210.835c0 15.243-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1-7.612-16.644 6.771-39.552 32.125-51.168 17.75-8.132 36.173-8.574 48.238-2.396 8.914 4.882 13.472 11.003 13.472 20.117z'
        }
      />
    </svg>
  )
}

const MemoSvgNoteNoStem = React.memo(SvgNoteNoStem)
export default MemoSvgNoteNoStem
