import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { styled } from '@thesisedu/web'
import React from 'react'

import { VideoThumbnailView } from './VideoThumbnailView'
import { useVideoOnDemandQuery, VideoOnDemandFragment, VideoOnDemandStatusFragment } from './schema'

export interface ThumbnailLoadingContentProps {
  loading?: boolean
  error?: boolean
  children: React.ReactElement | null
  aspectRatio?: number
}
export function ThumbnailLoadingContent({
  loading,
  error,
  children,
  aspectRatio = 16 / 9,
}: ThumbnailLoadingContentProps) {
  if (loading) {
    return (
      <IconContainer aspectRatio={aspectRatio}>
        <div>
          <LoadingOutlined />
        </div>
      </IconContainer>
    )
  } else if (error) {
    return (
      <IconContainer aspectRatio={aspectRatio}>
        <div>
          <ExclamationCircleOutlined />
        </div>
      </IconContainer>
    )
  } else {
    return children
  }
}

export interface VideoThumbnailProps {
  vodId: string
  onLoaded?: (vod: VideoOnDemandFragment & VideoOnDemandStatusFragment) => void
}
export function VideoThumbnail({ vodId, onLoaded }: VideoThumbnailProps) {
  const { data, loading } = useVideoOnDemandQuery({ variables: { id: vodId } })
  const vod = data?.node?.__typename === 'VideoOnDemand' ? data.node : undefined
  React.useEffect(() => {
    if (onLoaded && vod) {
      onLoaded(vod)
    }
  }, [vod, onLoaded])
  return (
    <ThumbnailLoadingContent loading={loading} error={!vod}>
      {vod && vod.thumbnailData ? <VideoThumbnailView thumbnail={vod.thumbnailData} /> : null}
    </ThumbnailLoadingContent>
  )
}

const IconContainer = styled.div<{ aspectRatio?: number }>`
  position: relative;
  padding-bottom: ${props => (1 / (props.aspectRatio || 16 / 9)) * 100}%;
  background: ${props => props.theme['@gray-1']};
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme['@size-l']};
    color: ${props => props.theme['@text-color-secondary']};
  }
`
