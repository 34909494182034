import { ExploreGridFiltersHook } from '@thesisedu/feature-courses-react'
import React from 'react'

import { StandardSetsReactFeature } from '../StandardSetsReactFeature'
import { StandardSetExploreFilters } from '../explore/StandardSetExploreFilters'

export default function (feature: StandardSetsReactFeature) {
  feature.hookManager.registerMutateHook<ExploreGridFiltersHook>(
    'courses-react:explore-grid-filters',
    filters => {
      return [...filters, <StandardSetExploreFilters key={'standards'} />]
    },
  )
}
