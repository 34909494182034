import React from 'react'

import { styled } from './styledTypes'

export interface ResetButtonProps extends React.HTMLProps<HTMLButtonElement> {
  noPreventDefault?: boolean
}
export function ResetButton({ noPreventDefault, ...rest }: ResetButtonProps) {
  return (
    <StyledResetButton
      {...(rest as any)}
      onClick={e => {
        if (!noPreventDefault) {
          e.preventDefault()
        }
        if (rest.onClick) {
          rest.onClick(e)
        }
      }}
    />
  )
}
const StyledResetButton = styled.button`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  background: none;
  display: block;
`

export const ResetInput = styled.input`
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  -webkit-appearance: none;
  border: none;

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 0.9em;
    width: 0.9em;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='51px' height='51px' stroke-width='2.3' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23000000'%3E%3Cpath d='M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243' stroke='%23000000' stroke-width='2.3' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.9em;
  }
`

export const Reset = {
  Button: ResetButton,
  Input: ResetInput,
}
