import { SortableData, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { styled } from '@thesisedu/react'
import classnames from 'classnames'
import React from 'react'

import { DragPreviewOutlineItem } from './DragPreviewOutlineItem'
import { DarkContentContainer } from './Header'
import { OutlineItem, OutlineItemProps } from './OutlineItem'
import { Segment } from '../../types'
import { useDragDropOutlineContext } from '../DragDropOutlineContext'
import { useOutlineListContext } from '../OutlineListContext'
import { useIsSearching } from '../OutlineSearchContext'
import { useOutlineListConfigContext } from '../context/OutlineListConfigContext'
import { getValidPlacement, getValidPlacementWithOffset } from '../getValidPlacement'
import { DEPTH_INDENT_PX, OutlineItem as TOutlineItem, isItemWithSegment } from '../types'

/**
 * This function is responsible for finding the "real" item we are currently hovering
 * over, based on the current index. We need a helper function for this because it
 * has to take into account the position of items in the list shifting depending on
 * if we are currently hovering over an item further down the list, or further up the
 * list.
 */
export function getRealItem(
  items: TOutlineItem[],
  fromIndex: number,
  toIndex: number,
): TOutlineItem | undefined {
  const realIndex = toIndex > fromIndex ? toIndex + 1 : toIndex
  return items[realIndex - 1]
}

export interface DraggableOutlineItemProps extends OutlineItemProps {
  disabled?: boolean
  droppableDisabled?: boolean
  draggingSegment?: Segment
}
export function DraggableOutlineItem({
  droppableDisabled,
  draggingSegment,
  disabled,
  ...props
}: DraggableOutlineItemProps) {
  const { selectedTerm, items = [] } = useOutlineListContext(false) || {}
  const { listId } = useOutlineListConfigContext(false) ?? {}
  const { item } = props
  const isSearching = useIsSearching()
  const { draggingId, offsetLeft } = useDragDropOutlineContext(true)
  const itemsWithoutDragging = draggingId ? items.filter(item => item.id !== draggingId) : items
  const activeIndexRef = React.useRef<number | undefined>()

  // The valid placement depth offset for the draggable over the current item. Used for disable state.
  const realIndex = items.indexOf(item)
  const overItem =
    activeIndexRef.current !== undefined
      ? getRealItem(items, activeIndexRef.current, realIndex)
      : undefined
  const draggingOverCurrentItemValidPlacement =
    draggingId && selectedTerm && overItem
      ? getValidPlacement({
          outlineItems: items,
          overItemId: overItem.id,
          draggingItemId: draggingId,
          unsortedTerm: selectedTerm,
          draggingSegment,
        })
      : null
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    active,
    newIndex,
    activeIndex,
    over,
  } = useSortable({
    id: item.id,
    transition: null, // These don't work well with @thesisedu/react-virtual.
    // We don't want to allow the teacher to re-arrange elements if she is currently placing
    // something.
    disabled: {
      draggable: disabled || isSearching || !isItemWithSegment(item),
      droppable: droppableDisabled
        ? false
        : draggingOverCurrentItemValidPlacement === null &&
          item.id !== draggingId &&
          realIndex !== 0,
    },
  })
  activeIndexRef.current = activeIndex

  const isActive = !droppableDisabled && active?.id === item.id
  const hasActive = !droppableDisabled && !!active
  const isOverCurrentList = listId
    ? (over?.data?.current as SortableData | undefined)?.sortable?.containerId === listId
    : true
  let overDepth = 0
  let minimumDepth: number | undefined = undefined
  let realItem: TOutlineItem | undefined = undefined
  let targetOffsetParentSegment: Segment | null | undefined = undefined
  if (isActive) {
    realItem = getRealItem(items, activeIndex, newIndex)

    // This is getting the valid placement of the current draggable item, as the current
    // active item.
    const currentDraggingOverItemValidPlacement =
      realItem && draggingId && selectedTerm
        ? getValidPlacementWithOffset({
            // We have to exclude the current item from this list because otherwise we
            // end up with a false minDepth, since the foreign segment is often added
            // to the bottom of the list, with a depth. Makes the system think you are
            // placing content in the middle of a lesson when actually you're at the
            // end of the course. Same logic inside getDragEndPlacement.
            outlineItems: itemsWithoutDragging,
            overItemId: realItem.id,
            draggingItemId: draggingId,
            unsortedTerm: selectedTerm,
            draggingSegment,
            offsetLeft,
          })
        : null
    if (currentDraggingOverItemValidPlacement) {
      minimumDepth = currentDraggingOverItemValidPlacement.minimumDepth
      overDepth = currentDraggingOverItemValidPlacement.depth
      targetOffsetParentSegment = currentDraggingOverItemValidPlacement.parentSegment
    }
  }

  const hasSegment = !!(props.item as any).segment
  const containerProps = {
    className: classnames({
      [props.className ?? '']: true,
      dragging: !droppableDisabled && isDragging,
      disabled,
      hoverable: hasSegment,
      'has-active': hasActive,
      'draggable-outline-item': true,
      [`depth-${props.item.depth}`]: true,
      ...props.item.parentIds.reduce<{ [key: string]: true }>((acc, parentId) => {
        return {
          ...acc,
          [`parent-${parentId}`]: true,
        }
      }, {}),
    }),
    ref: setNodeRef,
    ...listeners,
    // For some bonkers reason, the events inside a modal are ending up all
    // the way back here, so we have to ignore them.
    onKeyDown: (event: React.KeyboardEvent) => {
      const element = event.target as HTMLElement
      if (!element.closest('[role="dialog"]')) {
        listeners?.onKeyDown(event)
      }
    },
    onMouseDown: (event: React.MouseEvent) => {
      const element = event.target as HTMLElement
      if (!element.closest('[role="dialog"]')) {
        listeners?.onMouseDown(event)
      }
    },
    ...attributes,
    style: {
      ...props.style,
      transform: CSS.Transform.toString(transform),
      transition,
    },
  }

  if (isActive && !isOverCurrentList) {
    return null
  } else if (isActive) {
    return (
      <DragPreviewOutlineItem
        {...containerProps}
        segment={targetOffsetParentSegment}
        depth={overDepth}
        minimumDepth={minimumDepth}
      />
    )
  } else {
    return <StyledOutlineItem {...props} {...containerProps} />
  }
}

const StyledOutlineItem = styled(OutlineItem)`
  z-index: 3;
  position: relative;
  .hover-container {
    position: relative;
    box-shadow: none;
    transition:
      box-shadow 0.1s linear,
      background 0.1s linear,
      transform 0.1s linear,
      border 0.1s linear;
    background: transparent;
    border-radius: ${props => props.theme['@border-radius-base']};
    border: solid 1px transparent;
    overflow: visible;
    padding-left: ${props => props.theme['@size-xs']};
    padding-right: ${props => props.theme['@size-xs']};
  }
  .type-header .content-container > :first-child {
    margin-left: ${props => props.theme['@size-xs']};
  }
  .action-container {
    transition: opacity 0.1s linear;
    opacity: 0;
  }
  .dragger {
    transition: opacity 0.1s linear;
    opacity: 0;
    padding-left: ${props => props.theme['@size-xs']};
    font-size: ${props => props.theme['@size-xm']};
  }
  &:not(.disabled) {
    cursor: grab;
    &:hover.hoverable,
    .overlay-visible {
      .hover-container {
        box-shadow: ${props => props.theme['@shadow-medium']};
        border-color: ${props => props.theme['@border-color-base']};
      }
      .action-container {
        opacity: 1 !important;
      }
    }
  }
  &.dragging .hover-container {
    background: transparent;
    box-shadow: none !important;
  }
  &.dragging .content-container {
    background: ${props => props.theme['@gray-2']};
    border-radius: ${props => props.theme['@border-radius-base']};
    > *,
    .action-container {
      opacity: 0 !important;
      pointer-events: none !important;
    }
  }
  &.dragging ${DarkContentContainer} {
    opacity: 0;
    box-shadow: ${props => props.theme['@shadow-medium']};
    border: solid 1px ${props => props.theme['@border-color-base']};
    .content-container {
      background: transparent;
      box-shadow: none;
      border: none;
    }
  }
  &:hover.hoverable,
  &.dragging {
    .dragger {
      opacity: 1;
    }
  }
  &.has-active {
    &.depth-1 {
      padding-left: ${DEPTH_INDENT_PX * 1}px;
    }
    &.depth-2 {
      padding-left: ${DEPTH_INDENT_PX * 2}px;
    }
    &.depth-3 {
      padding-left: ${DEPTH_INDENT_PX * 3}px;
    }
    &.depth-4 {
      padding-left: ${DEPTH_INDENT_PX * 4}px;
    }
  }
`
