import { PlusOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { BodyLarge, FontWeight, styled } from '@thesisedu/web'
import classnames from 'classnames'
import React from 'react'

import { ALPHABET } from './constants'

export enum MultipleChoiceLetterMode {
  Default,
  Selected,
  Correct,
  Incorrect,
}

export interface MultipleChoiceLetterProps {
  index: number
  isAdd?: boolean
  mode?: MultipleChoiceLetterMode
  readOnly?: boolean
  onChange?: (selected: boolean) => void
}
export function MultipleChoiceLetter({
  index,
  mode = MultipleChoiceLetterMode.Default,
  readOnly,
  onChange,
  isAdd,
}: MultipleChoiceLetterProps) {
  const letter = ALPHABET[index].toUpperCase()
  let icon: React.ReactElement | null = null
  if (mode === MultipleChoiceLetterMode.Correct) icon = <CheckCircleFilled />
  else if (mode === MultipleChoiceLetterMode.Incorrect) icon = <CloseCircleFilled />
  else if (isAdd) icon = <PlusOutlined />
  return (
    <Container
      className={classnames({
        'is-add': isAdd,
        'mode-selected': mode === MultipleChoiceLetterMode.Selected,
        'mode-correct': mode === MultipleChoiceLetterMode.Correct,
        'mode-incorrect': mode === MultipleChoiceLetterMode.Incorrect,
        editing: !readOnly && !isAdd,
        'has-icon': !!icon,
      })}
      onClick={
        !readOnly
          ? () => {
              if (onChange) {
                onChange(mode !== MultipleChoiceLetterMode.Correct)
              }
            }
          : undefined
      }
    >
      {isAdd ? null : <BodyLarge weight={FontWeight.Bold}>{letter}</BodyLarge>}
      {icon ? <div className={'icon-container'}>{icon}</div> : null}
    </Container>
  )
}

const Container = styled.div`
  width: ${props => props.theme['@size-xl']};
  height: ${props => props.theme['@size-xl']};
  flex-shrink: 0;
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@gray-1']};
  transition:
    background 0.1s linear,
    color 0.1s linear;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &.has-icon > p {
    display: none;
  }
  > .icon-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    justify-content: center;
    font-size: ${props => props.theme['@size-l']};
  }
  &.is-add {
    color: ${props => props.theme['@gray-4']};
    font-size: ${props => props.theme['@size-m']};
  }
  &.editing {
    cursor: pointer;
    > .icon-container {
      font-size: ${props => props.theme['@size-s']};
      align-items: flex-end;
      justify-content: flex-end;
    }
    > p {
      display: block;
    }
  }
  &.mode-selected {
    background: ${props => props.theme['@gray-6']};
    color: ${props => props.theme['@white']};
  }
  &.mode-correct {
    background: ${props => props.theme['@blue']};
    color: ${props => props.theme['@white']};
  }
  &.mode-incorrect {
    background: ${props => props.theme['@red']};
    color: ${props => props.theme['@white']};
  }
`
