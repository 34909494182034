import React from 'react'

import { s, styled } from '../../'
import { HTMLProps } from '../../sharedTypes'

export type BlockSize = s.AnySize | true
export function getSpace(size: BlockSize | undefined) {
  return size === true ? 'auto' : size ? s.size(size) : undefined
}

export interface BlockProps extends HTMLProps<HTMLDivElement> {
  left?: BlockSize
  right?: BlockSize
  top?: BlockSize
  bottom?: BlockSize
  horizontal?: BlockSize
  vertical?: BlockSize
  paddingLeft?: BlockSize
  paddingRight?: BlockSize
  paddingTop?: BlockSize
  paddingBottom?: BlockSize
  paddingHorizontal?: BlockSize
  paddingVertical?: BlockSize
  /** Centers content both vertically and horizontally. */
  center?: boolean
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}
function _Block(
  {
    left,
    right,
    top,
    bottom,
    horizontal,
    vertical,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    paddingHorizontal,
    paddingVertical,
    center,
    className,
    style,
    children,
    ...rest
  }: BlockProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const leftSpace = left ?? horizontal
  const rightSpace = right ?? horizontal
  const topSpace = top ?? vertical
  const bottomSpace = bottom ?? vertical
  const paddingLeftSpace = paddingLeft ?? paddingHorizontal
  const paddingRightSpace = paddingRight ?? paddingHorizontal
  const paddingTopSpace = paddingTop ?? paddingVertical
  const paddingBottomSpace = paddingBottom ?? paddingVertical
  return (
    <__Block
      ref={ref}
      {...rest}
      style={{
        marginLeft: getSpace(leftSpace),
        marginRight: getSpace(rightSpace),
        marginTop: getSpace(topSpace),
        marginBottom: getSpace(bottomSpace),
        paddingLeft: getSpace(paddingLeftSpace),
        paddingRight: getSpace(paddingRightSpace),
        paddingTop: getSpace(paddingTopSpace),
        paddingBottom: getSpace(paddingBottomSpace),
        ...(center
          ? {
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {}),
        ...style,
      }}
      className={className}
      children={children}
    />
  )
}
export const Block = React.forwardRef(_Block)

const __Block = styled.div`
  &:empty {
    display: none;
  }
`
