import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined'
import { BodySmall, FontWeight } from '@thesisedu/web'
import { AutoComplete, Button, Input } from 'antd'
import { DataSourceItemType } from 'antd/es/auto-complete'
import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { useSearchTagsQuery } from './queries/useSearchTagsQuery'
import { TagPair } from './types'

const Option = AutoComplete.Option

const LOADING_VALUE = 'loading'
const CREATE_VALUE = 'create'

export interface TagSearchProps {
  onSelect: (tag: TagPair) => void
  type?: string
  typeLabel: any
  noAdd?: boolean
  placeholder?: string
}
export function TagSearch({
  onSelect,
  type,
  typeLabel,
  noAdd,
  placeholder = `Add ${typeLabel.toString()}...`,
}: TagSearchProps) {
  const [_searchQuery, setSearchQuery] = useState<string | null>(null)
  const [searchQuery] = useDebounce(_searchQuery, 250)
  const { tags, loading } = useSearchTagsQuery(searchQuery?.trim(), type)
  if (!noAdd && !type) {
    throw new Error('Cannot set noAdd to false without a type.')
  }

  const onSelectItem = (item: string) => {
    const tag = tags.find(t => t.name === item)
    if (tag && tag.type) {
      onSelect({ type: tag.type, name: tag.name })
    } else if (!noAdd && type) {
      onSelect({ type, name: item })
    }
    setSearchQuery(null)
  }

  let dataSource: React.ReactElement[] | DataSourceItemType | null = null
  if (loading) {
    dataSource = [
      <Option
        key={'loading'}
        value={LOADING_VALUE}
        style={{ display: 'flex', alignItems: 'center', opacity: 0.5 }}
      >
        <LoadingOutlined size={12} />
        <span>&nbsp; Searching for tags...</span>
      </Option>,
    ]
  } else if (tags) {
    dataSource = tags.map(tag => {
      const tagType = tag.type === 'IMPLEMENTATION_GUIDE' ? 'LESSON' : tag.type
      return (
        <Option key={tag.name} value={tag.name} title={`${tag.name} (${tagType})`}>
          <span>{tag.name}</span>
          {type ? null : (
            <BodySmall weight={FontWeight.Bold} style={{ marginLeft: 5, display: 'inline-block' }}>
              {tagType}
            </BodySmall>
          )}
        </Option>
      )
    })
  }

  if (dataSource && Array.isArray(dataSource) && !loading && _searchQuery?.trim()) {
    const createItem = noAdd ? (
      dataSource.length === 0 ? (
        <Option style={{ opacity: 0.5 }} key={CREATE_VALUE} value={CREATE_VALUE}>
          No items found...
        </Option>
      ) : null
    ) : (
      { value: CREATE_VALUE, text: `Create '${_searchQuery}'` }
    )
    dataSource = [createItem, ...dataSource].filter(Boolean) as any
  }

  return (
    <AutoComplete
      onSearch={setSearchQuery}
      onSelect={value => {
        if (value !== LOADING_VALUE) {
          if (value === CREATE_VALUE) {
            onSelectItem(_searchQuery!)
          } else {
            onSelectItem(value)
          }
        }
      }}
      style={{ width: '100%' }}
      value={_searchQuery || ''}
      dataSource={dataSource as any}
    >
      <Input.Search
        placeholder={placeholder}
        enterButton={
          noAdd ? undefined : (
            <Button type={'primary'} icon={<PlusOutlined />} disabled={!_searchQuery?.trim()} />
          )
        }
        onSearch={() => {
          if (_searchQuery) {
            onSelectItem(_searchQuery)
          }
        }}
      />
    </AutoComplete>
  )
}
