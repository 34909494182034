import { ImportGroupsDropdown } from '@thesisedu/feature-class-sync-react'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'
import { GroupsEditImportGroupsHook } from '../types'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<GroupsEditImportGroupsHook>(
    'feature-assignments-react:groups-edit-import-groups',
    (existing, { classId, onComplete }) => {
      return (
        <ImportGroupsDropdown
          classId={classId}
          onComplete={groups => {
            return onComplete(
              groups.reduce((acc, group) => {
                return {
                  ...acc,
                  [uuid()]: {
                    name: group.name,
                    leader: group.leaderStudentId,
                    students: group.memberStudentIds,
                  },
                }
              }, {}),
            )
          }}
        />
      )
    },
  )
}
