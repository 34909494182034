import { MediaList, MediaListItem, MediaListProps } from '@thesisedu/feature-media-react'
import { MediaType } from '@thesisedu/feature-media-react/dist/schema'
import { BlockSpin, DATE_FORMATS } from '@thesisedu/web'
import { Empty } from 'antd'
import moment from 'moment'
import React from 'react'

import { MediaStatus, SheetMusicCompositionFragment } from '../schema'

const thumbnail = require('../../assets/sheet-music-thumbnail.svg').default

interface SheetMusicWithMedia extends Omit<SheetMusicCompositionFragment, 'media'> {
  media: MediaListItem['media']
}
/**
 * This whole function is a hack because some compositions have media associated with them,
 * and some do not. We still want to use the media list, so we have to "shim" some of the
 * required fields.
 */
function provideMediaDefaults(items: SheetMusicCompositionFragment[]): SheetMusicWithMedia[] {
  return items.map(item => {
    if (item.media) return item as SheetMusicWithMedia
    else
      return {
        ...item,
        media: {
          id: item.id,
          status: MediaStatus.Complete,
          thumbnail,
          type: MediaType.Video,
          createdAt: item.createdAt,
          hasUnreadComments: false,
        },
      }
  })
}

export interface SheetMusicCompositionsListProps
  extends Partial<MediaListProps<SheetMusicWithMedia>> {
  comps: SheetMusicCompositionFragment[]
  refetch: (after: string | null) => Promise<any>
  onClick?: (id: string) => void
  loading?: boolean
}
export function SheetMusicCompositionsList({
  onClick,
  comps,
  refetch,
  loading,
  ...rest
}: SheetMusicCompositionsListProps) {
  return (
    <MediaList<SheetMusicWithMedia>
      {...rest}
      items={provideMediaDefaults(comps)}
      emptyContent={loading ? <BlockSpin /> : <Empty description={'No shared compositions yet!'} />}
      refetch={refetch}
      getItemProps={item => {
        return {
          label: item.name,
          subtitle: `Created by ${item.user.name || item.user.username} on ${moment(
            item.createdAt,
          ).format(DATE_FORMATS.FULL)}`,
          onClick: onClick ? () => onClick(item.id) : undefined,
        }
      }}
    />
  )
}
