import { getSize, getColor, styledContainer as styled, BodyLarger, Body } from '@thesisedu/react'
import { callAttention } from '@thesisedu/web'
import React from 'react'

export interface LargeConfigurationButtonProps {
  icon: React.ReactElement
  title: string
  description: string
  children?: React.ReactElement
  shortDescription?: string
  selected?: boolean
  hasConfiguration?: boolean
  onClick: () => void
}
export function LargeConfigurationButton({
  icon,
  title,
  description,
  children,
  hasConfiguration,
  shortDescription,
  selected,
  onClick,
}: LargeConfigurationButtonProps) {
  const childRef = React.useRef<HTMLDivElement>(null)
  return (
    <Container
      selected={selected}
      onClick={() => {
        // If we try to select the item without configuring it, call attention to it.
        if (children && childRef.current && !selected && !hasConfiguration) {
          callAttention(childRef.current)
        } else {
          onClick()
        }
      }}
    >
      <IconContainer children={icon} selected={selected} />
      <BodyLarger color={selected ? '@primary-color' : undefined}>{title}</BodyLarger>
      <Body color={'@text-color-secondary'}>
        {description.replace(/SHORTDESC/g, shortDescription || '')}
      </Body>
      {children ? (
        <ChildrenContainer ref={childRef} onClick={e => e.stopPropagation()} children={children} />
      ) : null}
    </Container>
  )
}

const ChildrenContainer = styled`
  margin-top: ${props => props.theme['@size-s']};
  max-width: 100%;
`
const Container = styled<{ selected?: boolean }>`
  background: white;
  border-radius: ${props => getSize(props.theme, '@border-radius-large')};
  padding: ${props => getSize(props.theme, '@size-l')} ${props => getSize(props.theme, '@size-m')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: solid 2px ${props =>
    props.selected ? getColor(props.theme, '@primary-color') : 'transparent'};
  transition: border 0.1s linear;
  width: 300px;
  cursor: pointer;
  * {
    transition: color 0.1s linear;
  }
  > :not(:last-child) {
    margin-bottom: ${props => getSize(props.theme, '@size-xs')} !important;
  }
  button {
    ${props =>
      props.selected ? `color: ${getColor(props.theme, '@primary-color')} !important;` : ''}
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const IconContainer = styled<{ selected?: boolean }>`
  color: ${props =>
    getColor(props.theme, props.selected ? '@primary-color' : '@text-color-secondary')};
  svg {
    width: ${props => getSize(props.theme, '@size-xxl')};
    height: ${props => getSize(props.theme, '@size-xxl')};
  }
`
