import { useMutateHook } from '@thesisedu/feature-react'
import { useProtection } from '@thesisedu/feature-users-web'
import React from 'react'

import { RegisterPage } from './RegisterPage'
import { RegisterPageHook } from '../../../types'

export function Register() {
  useProtection({ allowedGroups: [], redirect: '/' })
  return useMutateHook<RegisterPageHook>(
    'feature-users-react:register-page',
    <RegisterPage />,
    undefined,
  )
}
