import { onMutationError } from '@thesisedu/feature-apollo-react'
import { SAMLButtons } from '@thesisedu/feature-users-react/dist/web/routes/SAMLButtons'
import { RegisterForm, useProtection } from '@thesisedu/feature-users-web'
import { H1, Block, Body } from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { UserFragment, useAcceptInviteMutation } from '../../schema'

export function AcceptInvitePage() {
  useProtection({ allowedGroups: [], redirect: '/' })
  const location = useLocation()
  const [defaultValue, setDefaultValue] = React.useState<Partial<UserFragment>>()
  const [token, setToken] = React.useState<string>()
  const [acceptInvite] = useAcceptInviteMutation({
    onError: onMutationError(
      'Your account was created, but there was an error joining your class.',
    ),
  })
  React.useEffect(() => {
    const search = new URLSearchParams(location.search)
    if (search.has('email')) {
      setDefaultValue({
        email: search.get('email'),
      })
    }
    if (search.has('token')) {
      setToken(search.get('token')!)
    }
  }, [location])
  const onRegister = async () => {
    if (token) {
      try {
        await acceptInvite({
          variables: {
            input: {
              token,
            },
          },
        })
      } catch {
        // Do nothing...
      }
    }
  }
  return (
    <>
      <H1 isBlock>Register</H1>
      <RegisterForm defaultValues={defaultValue} onRegister={onRegister} hideEduWarning />
      <Block marginTop={'@size-s'} marginBottom={'@size-l'}>
        <SAMLButtons onRegister={onRegister} />
      </Block>
      <Link to={'../login'}>
        <Body color={'@text-color-secondary'}>
          <ArrowLeft /> Login
        </Body>
      </Link>
    </>
  )
}
