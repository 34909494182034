import { IssuesCloseOutlined } from '@ant-design/icons'
import { DefaultAssignmentSettings, PartialAssignment } from '@thesisedu/feature-assignments-core'
import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { useFeatureRoot, useFreshRef } from '@thesisedu/feature-react'
import { isEqual } from '@thesisedu/feature-utils'
import { Result } from '@thesisedu/react'
import { Button, Modal, SplitModal, styled, s, LoadingIndicator, VSpaced } from '@thesisedu/ui'
import { Check, UndoAction } from '@thesisedu/ui/icons'
import { Form } from 'antd'
import { pick } from 'lodash'
import React from 'react'

import { DefaultAssignmentSettingsCategory } from './DefaultAssignmentSettingsCategory'
import { useClassAssignmentCategoriesQuery } from '../../schema'

const NONE_VALUE = '--none--'
export interface DefaultAssignmentSettingsFieldProps {
  value?: DefaultAssignmentSettings
  onChange?: (value: DefaultAssignmentSettings) => void
  classId?: string
}
export function DefaultAssignmentSettingsField({
  value,
  onChange,
  classId,
}: DefaultAssignmentSettingsFieldProps) {
  const [visible, setVisible] = React.useState(false)
  const root = useFeatureRoot()
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<string | null>(null)
  const [draftValue, setDraftValue] = React.useState<DefaultAssignmentSettings>(value || {})
  const draftValueRef = useFreshRef(draftValue)
  const [initialValue, setInitialValue] = React.useState<DefaultAssignmentSettings>(value || {})
  const { categoryDefaultSettings, ...restDraftValue } = draftValue
  const { data, loading, error } = useClassAssignmentCategoriesQuery({
    variables: { classId: classId || '' },
    skip: !classId,
  })
  const assignmentCategories =
    data?.node?.__typename === 'Class'
      ? data.node.assignmentCategories.edges.map(edge => edge.node)
      : undefined
  React.useLayoutEffect(() => {
    setDraftValue(value || {})
    if (visible) {
      // Give the form some time to settle / provide default values.
      setTimeout(() => {
        setInitialValue(draftValueRef.current)
      }, 10)
    }
    setSelectedCategoryId(null)
  }, [visible])
  const { modal, confirm } = Modal.useConfirmModal()
  return (
    <>
      {modal}
      <SplitModal
        title={'Default Assignment Settings'}
        visible={visible}
        value={selectedCategoryId ?? NONE_VALUE}
        onValueChange={value => setSelectedCategoryId(value === NONE_VALUE ? null : value)}
        trigger={<Button icon={<IssuesCloseOutlined />}>Edit Default Assignment Settings</Button>}
        style={{ height: '90vh' }}
        onVisibleChange={v => {
          if (v) {
            setVisible(true)
            return
          }
          const isDirty = !isEqual(initialValue, draftValue || {})
          if (isDirty) {
            confirm({
              title: 'Are you sure you want to discard your changes?',
              onConfirm: () => {
                setVisible(false)
              },
              confirm: {
                children: 'Discard Changes',
                status: 'danger',
              },
              cancel: { children: 'Continue Editing' },
            })
          } else {
            setVisible(false)
          }
        }}
      >
        {loading ? (
          <LoadingErrorContainer>
            <LoadingIndicator.Labeled label={'Loading assignment categories...'} />
          </LoadingErrorContainer>
        ) : error ? (
          <LoadingErrorContainer>
            <Result.Error
              description={'There was an error loading the assignment categories for your class.'}
            />
          </LoadingErrorContainer>
        ) : (
          <>
            <SplitModal.Navigation>
              <SplitModal.Navigation.Item value={NONE_VALUE} title={'All Assignments'} />
              {assignmentCategories?.map(category => {
                return (
                  <SplitModal.Navigation.Item
                    key={category.id}
                    value={category.id}
                    title={category.name}
                  />
                )
              })}
              <VSpaced top align={'center'}>
                <Button
                  onPress={() => {
                    confirm({
                      title: `Are you sure you want to reset all settings to ${root.appOptions.name} defaults?`,
                      children: 'You will lose all of your default settings. You cannot undo this.',
                      confirm: {
                        children: 'Reset All',
                        status: 'danger',
                      },
                      cancel: { children: "Don't Reset" },
                      onConfirm() {
                        if (onChange) {
                          onChange({})
                          setVisible(false)
                        }
                      },
                    })
                  }}
                  status={'danger'}
                  icon={<UndoAction />}
                  children={'Reset All'}
                  variant={'ghost'}
                />
              </VSpaced>
            </SplitModal.Navigation>
            <SplitModal.Content>
              {[NONE_VALUE, ...(assignmentCategories?.map(cat => cat.id) ?? [])].map(categoryId => {
                const selectedCategoryId = categoryId === NONE_VALUE ? null : categoryId
                return (
                  <SplitModal.Content.Item value={categoryId} key={categoryId}>
                    <DefaultAssignmentSettingsCategory
                      key={selectedCategoryId || 'all'}
                      categoryId={
                        selectedCategoryId
                          ? selectedCategoryId
                          : visible
                          ? 'all-visible'
                          : undefined
                      }
                      categoryName={
                        assignmentCategories?.find(cat => cat.id === selectedCategoryId)?.name
                      }
                      value={
                        selectedCategoryId
                          ? categoryDefaultSettings?.[selectedCategoryId]
                          : restDraftValue
                      }
                      baseValue={selectedCategoryId ? restDraftValue : undefined}
                      onChange={newValue => {
                        if (selectedCategoryId) {
                          setDraftValue(v => {
                            const differentKeys = (
                              Object.keys(newValue) as (keyof PartialAssignment)[]
                            ).filter(k => !isEqual(v[k], newValue[k]))
                            const { categoryDefaultSettings, ...restV } = v
                            const newDefaultSettings = {
                              ...categoryDefaultSettings,
                              [selectedCategoryId]: pick(newValue, differentKeys),
                            }
                            for (const key of Object.keys(newDefaultSettings)) {
                              if (
                                !newDefaultSettings[key] ||
                                !Object.keys(newDefaultSettings[key]).length
                              ) {
                                delete newDefaultSettings[key]
                              }
                            }
                            return Object.keys(newDefaultSettings).length
                              ? {
                                  ...v,
                                  categoryDefaultSettings: newDefaultSettings,
                                }
                              : restV
                          })
                        } else {
                          setDraftValue(v =>
                            v.categoryDefaultSettings
                              ? {
                                  ...newValue,
                                  categoryDefaultSettings: v.categoryDefaultSettings,
                                }
                              : newValue,
                          )
                        }
                      }}
                    />
                    <SplitModal.Content.Footer>
                      <Button
                        variant={'primary'}
                        style={{ marginLeft: 'auto' }}
                        icon={<Check />}
                        children={'Save Changes'}
                        onPress={() => {
                          if (onChange) {
                            onChange(draftValue)
                            setVisible(false)
                          }
                        }}
                      />
                    </SplitModal.Content.Footer>
                  </SplitModal.Content.Item>
                )
              })}
            </SplitModal.Content>
          </>
        )}
      </SplitModal>
    </>
  )
}

const LoadingErrorContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${s.size('l')};
`

export function Field({ fieldPrefix, classId }: ClassConfigurationFieldProps) {
  return (
    <Form.Item name={[...fieldPrefix, DEFAULT_ASSIGNMENT_SETTINGS]}>
      <DefaultAssignmentSettingsField classId={classId} />
    </Form.Item>
  )
}

export const DEFAULT_ASSIGNMENT_SETTINGS = 'defaultAssignmentSettings'
const config: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: DEFAULT_ASSIGNMENT_SETTINGS,
  group: 'Assignments & Grading',
  renderField: Field,
}
export default config
