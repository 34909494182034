import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { Copy, LogOut } from '@thesisedu/react/icons'
import { styled, Tooltip } from '@thesisedu/web'
import { Alert } from 'antd'
import { AlertProps } from 'antd/es/alert'
import React from 'react'

import { useViewerContext } from '../ViewerContext'

export interface ImpersonateHeaderProps extends Omit<AlertProps, 'message'> {
  formatMessage?: (name: string) => React.ReactElement
  forGroup?: string
}
export const ImpersonateHeader: React.FC<React.PropsWithChildren<ImpersonateHeaderProps>> = ({
  formatMessage,
  forGroup = 'ADMIN',
  ...props
}) => {
  const viewer = useViewerContext(false)
  const { stopImpersonating, isImpersonating, duplicate } = useImpersonation()

  if (isImpersonating && viewer && viewer.coreViewer && forGroup === viewer.coreViewer.group) {
    const message = formatMessage ? (
      formatMessage(viewer.name || viewer.username)
    ) : (
      <Container>
        <span>You are impersonating</span>
        <strong>
          {viewer.name || viewer.username} (
          {viewer.group ? viewer.group[0] + viewer.group.slice(1).toLowerCase() : 'Unknown'}
          ).
        </strong>
        <span>Be careful!</span>
        <div style={{ marginLeft: 'auto' }}>
          <Tooltip title={'Open Another Tab'} placement={'bottomRight'}>
            <button
              className={'ant-alert-close-icon'}
              onClick={e => {
                e.preventDefault()
                return duplicate()
              }}
            >
              <span className={'ant-alert-close-text'}>
                <Copy width={'1.25em'} height={'1.25em'} />
              </span>
            </button>
          </Tooltip>
        </div>
        <div style={{ marginLeft: 20, marginRight: 20 }}>
          <Tooltip title={'Stop Impersonating'} placement={'bottomRight'}>
            <button
              className={'ant-alert-close-icon'}
              onClick={e => {
                e.preventDefault()
                return stopImpersonating()
              }}
            >
              <span className={'ant-alert-close-text'}>
                <LogOut width={'1.25em'} height={'1.25em'} />
              </span>
            </button>
          </Tooltip>
        </div>
      </Container>
    )
    return (
      <Alert
        banner
        message={message}
        closeText={null}
        onClose={async () => {
          await stopImpersonating()
        }}
        {...props}
      />
    )
  } else {
    return null
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  > :not(:first-child) {
    display: block;
    margin-left: 0.25em;
  }
`
