import {
  BasicAssignmentFragment,
  BasicAssignmentCategoryFragment,
  SubmittedAssignmentSubmissionFragment,
  useClassAssignmentCategoriesWithGradesQuery,
} from '../schema'

export interface GradeTreeAssignment
  extends Pick<
    BasicAssignmentFragment,
    'dueAt' | 'totalPoints' | 'id' | 'name' | 'rawConfiguration'
  > {
  averageGrade?: number | null
  submissions: Pick<
    SubmittedAssignmentSubmissionFragment,
    'grade' | 'submittedAt' | 'reviewed' | 'id' | 'comments'
  >[]
}
export interface GradeTreeCategory {
  category: BasicAssignmentCategoryFragment
  grade?: number | null
  points?: number | null
  totalPoints?: number | null
  assignments: GradeTreeAssignment[]
}
export interface GradeTree {
  categories: GradeTreeCategory[]
  loading?: boolean
}

interface ServerAssignment
  extends Pick<
    BasicAssignmentFragment,
    'dueAt' | 'totalPoints' | 'id' | 'name' | 'rawConfiguration'
  > {
  submissions: {
    averageGrade?: number | null
    edges: {
      node: Pick<
        SubmittedAssignmentSubmissionFragment,
        'grade' | 'submittedAt' | 'reviewed' | 'id' | 'comments'
      >
    }[]
  }
}
function mapAssignments(assignments: ServerAssignment[]): GradeTreeAssignment[] {
  return assignments.map(assignment => ({
    ...assignment,
    averageGrade: assignment.submissions.averageGrade,
    submissions: assignment.submissions.edges.map(edge => edge.node),
  }))
}
export function useGradeTree(classId: string, studentIds?: string[]): GradeTree | null {
  const { data, loading } = useClassAssignmentCategoriesWithGradesQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      classId,
      studentIds,
    },
  })
  const categories =
    data?.node?.__typename === 'Class'
      ? data.node.assignmentCategories.edges.map(edge => edge.node)
      : undefined
  if (categories) {
    return {
      loading,
      categories: categories.map<GradeTreeCategory>(cat => ({
        category: cat,
        grade: cat.grade,
        points: cat.points,
        totalPoints: cat.totalPoints,
        assignments: mapAssignments(cat.assignments.edges.map(edge => edge.node)),
      })),
    }
  } else if (loading) {
    return { loading: true, categories: [] }
  } else {
    return null
  }
}
