import { AntIconWrapper } from '@thesisedu/react'
import { Plus } from '@thesisedu/ui/icons'
import { Button, ButtonProps } from 'antd'
import React from 'react'

import { useSubmissionContext } from '../contexts/SubmissionContext'
import { useCreateNewSubmission } from '../submissions/useCreateNewSubmission'

export interface CreateAnotherAttemptAssignmentActionProps extends Partial<ButtonProps> {}
export function CreateAnotherAttemptAssignmentAction(
  rest: CreateAnotherAttemptAssignmentActionProps,
) {
  const { classId, assignment, onSubmissionCreated } = useSubmissionContext(true)
  const [createSubmission, { loading }] = useCreateNewSubmission({
    classId,
    assignmentId: assignment.id,
    onCreated(submission) {
      onSubmissionCreated?.(submission.id)
    },
  })

  return (
    <Button
      onClick={() => {
        createSubmission()
      }}
      loading={loading}
      children={'Create Another Attempt'}
      icon={<AntIconWrapper children={<Plus />} />}
      data-testid={'CreateAnotherAttemptAssignmentAction'}
      type={'primary'}
      {...rest}
    />
  )
}
