import {
  GradingModalContext,
  useGradingModalVisibilityContext,
} from '@thesisedu/feature-assignments-react'
import {
  useCourseViewerModalContext,
  useSegmentNavigationContext,
} from '@thesisedu/feature-courses-react'
import React from 'react'

import { debug } from '../../log'
import { useAssignmentForSegment } from '../assignment/useAssignmentForSegment'

export interface CourseViewerGradingProviderProps {
  children: React.ReactNode
  segmentId?: string
}
export function CourseViewerGradingProvider({
  children,
  segmentId,
}: CourseViewerGradingProviderProps) {
  const { selectedNavigationItemId, setSelectedNavigationItemId } =
    useSegmentNavigationContext(true)
  const { assignment } = useAssignmentForSegment(segmentId)
  const { setGradingAssignment } = useGradingModalVisibilityContext(false) ?? {}
  React.useEffect(() => {
    if (segmentId && setGradingAssignment) {
      return () => {
        debug('course viewer has closed; clearing grading assignment')
        setGradingAssignment(undefined)
      }
    }
  }, [!!segmentId])
  const { addPrompt, checkForPrompts, clearPrompts, removePrompt, setSegmentId } =
    useCourseViewerModalContext(true)
  if (assignment) {
    return (
      <GradingModalContext.Provider
        children={children}
        value={{
          addPrompt,
          checkForPrompts,
          clearPrompts,
          removePrompt,
          selectedTab: selectedNavigationItemId ?? 'none',
          setSelectedTab(tab) {
            checkForPrompts(() => {
              setSegmentId(segmentId, tab)
              setSelectedNavigationItemId(tab)
            })
          },
          assignment,
        }}
      />
    )
  } else return <>{children}</>
}
