import { useMutateHook } from '@thesisedu/feature-react'
import {
  UserLeftItemsHook,
  UserRoutesHook,
  UserTopItemsHook,
  useViewerContext,
  UserSettings,
  MyContent,
} from '@thesisedu/feature-users-react'
import { useProtection } from '@thesisedu/feature-users-web'
import { Shell, NotFoundPage } from '@thesisedu/feature-web'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { WrapWithTeacherContext } from '../../WrapWithTeacherContext'

export function Teacher() {
  useProtection({ allowedGroups: ['TEACHER'], redirect: '/' })
  const viewer = useViewerContext(false)
  const routes = useMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    [
      <Route key={'/settings/*'} path={'/settings/*'} element={<UserSettings />} />,
      <Route key={'/my-content/*'} path={'/my-content/*'} element={<MyContent />} />,
    ],
    { viewer, group: 'TEACHER' },
  )
  const topItems = useMutateHook<UserTopItemsHook>(
    'feature-users-react:top-items',
    [
      {
        weight: 1,
        element: (
          <Shell.TopMenuItem key={'my-content'} name={'My Content'} path={'/teacher/my-content'} />
        ),
      },
    ],
    {
      viewer,
      group: 'TEACHER',
    },
  )
  const leftItems = useMutateHook<UserLeftItemsHook>('feature-users-react:left-items', [], {
    viewer,
    group: 'TEACHER',
  })

  if (!viewer) return null
  return (
    <WrapWithTeacherContext>
      <Shell.Shell hideBreadcrumb topItems={topItems} leftItems={leftItems}>
        <Routes>
          {routes}
          <Route key={'404'} path={'*'} element={<NotFoundPage />} />
        </Routes>
      </Shell.Shell>
    </WrapWithTeacherContext>
  )
}
