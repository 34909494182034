import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { eventFiles } from '@lexical/rich-text'
import { COMMAND_PRIORITY_HIGH, PASTE_COMMAND } from 'lexical'
import React from 'react'

import { $createFileNode } from '../../nodes/File/FileNode'
import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function usePasteFile() {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (payload, editor) => {
        const [isFileTransfer, files] = eventFiles(payload)
        if (!isFileTransfer || !files.length) return false
        const fileFiles = files.filter(file => !file.type.startsWith('image'))

        // For each of the files, create new blocks.
        editor.update(() => {
          const element = $getSelectedBlockElement()
          if (element) {
            for (const file of fileFiles) {
              const node = $createFileNode({
                uploadFile: {
                  editor,
                  file,
                },
              })
              element.insertAfter(node)
            }
          }
        })

        // Only allow others to continue if there are more files left.
        return fileFiles.length === files.length
      },
      COMMAND_PRIORITY_HIGH,
    )
  }, [editor])
}
