import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { useResource } from '@thesisedu/feature-react'
import { CORE_NODES } from '@thesisedu/feature-widgets-core'
import { createGlobalStyle } from 'styled-components'

import { CalloutNode } from './CalloutElementNode'
import { ClearFloatNode } from './ClearFloatNode'
import { ColumnNodeStyles, ColumnNode } from './ColumnNode'
import { ColumnsNodeStyles, ColumnsNode } from './ColumnsNode'
import { FileNode } from './File/FileNode'
import { FloatNode } from './FloatNode'
import { WidgetResource } from '../../types'

// These modify the prototypes of the nodes from @thesisedu/feature-widgets-core
import './Image/ImageNode'
import './Table/TableNode'
import './List'

export const EDITOR_NODES = [
  ...CORE_NODES.filter(node => node.name !== 'HorizontalRuleNode'),
  HorizontalRuleNode,
  ColumnsNode,
  ColumnNode,
  FileNode,
  CalloutNode,
  FloatNode,
  ClearFloatNode,
]

export function useEditorNodes() {
  const resources = useResource<WidgetResource>('Widget')
  return [...EDITOR_NODES, ...resources.flatMap(r => r.nodes || [])]
}

export const NodeStyles: any = createGlobalStyle`
  ${ColumnsNodeStyles}
  ${ColumnNodeStyles}
`
