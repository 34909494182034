import { AuthRequiredPayload, AuthProviderWrapper } from '@thesisedu/feature-users-react'
import React from 'react'

import { useCleverAssociate } from './useCleverLogin'

function _AuthProviderHandler({ onSuccess, onError, ...opts }: AuthRequiredPayload) {
  const { signIn, associating } = useCleverAssociate({ onSuccess, onError })
  return (
    <AuthProviderWrapper
      identifier={'clever'}
      onAuth={signIn}
      associating={associating}
      onError={onError}
      {...opts}
    />
  )
}
export const AuthProviderHandler = React.memo(_AuthProviderHandler, () => false)
