import { PlatformImport } from '@thesisedu/feature-course-types'
import { Format } from '@thesisedu/feature-utils'
import { Modal, Modal$, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

export interface ImportErrorModalProps extends Partial<Modal$.ModalProps> {
  details: PlatformImport.InvalidImportDetails[]
}
export function ImportErrorModal({ details, ...rest }: ImportErrorModalProps) {
  return (
    <Modal
      status={'error'}
      title={Format.plural('invalid entry', details.length, { plural: 'invalid entries' })}
      {...rest}
    >
      <VSpaced space={'s'}>
        {details.map(detail => {
          return (
            <VSpaced space={'xxs'} key={detail.row}>
              <Text weight={'bold'}>Row {detail.row}</Text>
              <Text level={'s'} color={'secondary'}>
                {detail.reason}
              </Text>
            </VSpaced>
          )
        })}
      </VSpaced>
    </Modal>
  )
}
