import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Page } from '@thesisedu/ui/icons'
import React from 'react'

import { AllowDownloadSetting } from './AllowDownloadSetting'
import { PDFElement } from './PDFElement'
import { PDFSettingsModal } from './PDFSettingsModal'
import { PDFConfig } from './common'

export default {
  identifier: 'PDF',
  title: 'PDF',
  icon: <Page />,
  weight: 16,
  group: SimpleWidget.ElementsGroup.Media,
  defaultConfig: {
    file: null,
    startPage: null,
    pageCount: null,
    version: 1,
  },
  hidden({ group }) {
    return group !== 'ADMIN'
  },
  settingsDropdownItems: [
    {
      identifier: 'allow-downloads',
      weight: 0,
      element: AllowDownloadSetting,
    },
  ],
  element: PDFElement,
  settingsModal: PDFSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<PDFConfig, 'PDF'>
