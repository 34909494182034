import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { FOCUS_COMMAND, COMMAND_PRIORITY_NORMAL, BLUR_COMMAND } from 'lexical'
import React from 'react'
import { createPortal } from 'react-dom'

import { Toolbar } from '../../ui/Toolbar'

export interface ToolbarPluginProps {
  host: React.RefObject<HTMLDivElement>
}
export function ToolbarPlugin({ host }: ToolbarPluginProps) {
  const [editor] = useLexicalComposerContext()
  const [focused, setFocused] = React.useState(false)

  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          setFocused(true)
          return false
        },
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          setFocused(false)
          return false
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    )
  }, [editor])

  if (host.current) {
    return createPortal(<Toolbar editor={editor} focused={focused} />, host.current)
  } else {
    return null
  }
}
