import { toGlobalId } from '@thesisedu/feature-utils'
import { PageHeader, PageHead, Row, Block, useBreadcrumb, HSpaced, styled } from '@thesisedu/web'
import { Button, Col, Form } from 'antd'
import React from 'react'
import { Prompt, useNavigate } from 'react-router-dom'

import { UseInClassesWarning } from './UseInClassesWarning'
import { useSimplePreset } from './useSimplePreset'
import { SegmentProps } from '../../types'
import { useCreateEditSegmentContext } from '../CreateEditSegment'
import { ChildrenOpts } from '../EditableSegmentContent'

export type SimpleChildrenOpts = Pick<ChildrenOpts, 'form'> & {
  typeLabel: string
}
export interface SimpleEditableSegmentProps extends SegmentProps {
  children: (opts: SimpleChildrenOpts) => React.ReactElement
  rightContent: (opts: SimpleChildrenOpts) => React.ReactElement
  typeLabel: string
}
export function SimpleEditableSegment({
  segment,
  onChange,
  children,
  loading,
  typeLabel: _typeLabel,
  rightContent,
  skipHeader,
}: SimpleEditableSegmentProps) {
  const { isCreateMode = false } = useCreateEditSegmentContext(false) || {}
  const [form] = Form.useForm()
  const preset = useSimplePreset({ type: segment.type })
  const typeLabel = preset?.typeLabel || _typeLabel
  const navigate = useNavigate()
  React.useEffect(() => {
    if (preset) {
      form.setFieldsValue({
        ...preset.formValues,
        ...segment,
        config: {
          ...preset.formValues.config,
          ...segment.config,
        },
      })
    }
  }, [])
  const [isDirty, setIsDirty] = React.useState(false)
  const title = isCreateMode ? `Create ${typeLabel}` : `Edit ${typeLabel}: ${segment.name}`
  useBreadcrumb({ title }, title)
  const submitButton = (
    <Button type={'primary'} onClick={form.submit} loading={loading}>
      Save Changes
    </Button>
  )
  return (
    <Form
      form={form}
      onFinish={values => {
        if (onChange) {
          onChange(segment, values)
        }
        setIsDirty(false)
      }}
      layout={'vertical'}
      onValuesChange={() => {
        setIsDirty(true)
      }}
    >
      <Prompt
        when={isDirty}
        message={'Are you sure you want to leave this page? You will lose changes.'}
      />
      <PageHead title={`${title} - My Content`} />
      {skipHeader ? (
        <HSpaced justify={'flex-end'}>
          <ButtonContainer>{submitButton}</ButtonContainer>
        </HSpaced>
      ) : (
        <PageHeader title={title} onBack={() => navigate('/teacher/my-content/custom-content')}>
          {submitButton}
        </PageHeader>
      )}
      <UseInClassesWarning globalSegmentId={toGlobalId('Segment', segment.id)} />
      <Block marginTop={skipHeader ? '@size-s' : '@size-l'}>
        <Row>
          <Col xs={24} md={16}>
            {children({ form, typeLabel })}
          </Col>
          <Col xs={24} md={8}>
            {rightContent({ form, typeLabel })}
          </Col>
        </Row>
      </Block>
    </Form>
  )
}

const ButtonContainer = styled.div`
  margin-top: -${props => props.theme['@size-xss']};
`
