import React from 'react'

import { useUnreadDirectMessageThreadsQuery } from '../schema'
import { ThreadListFilters } from '../types'

// TODO: Convert this to use subscriptions when we start using that more widely.
export const POLL = process.env.REACT_APP_ENVIRONMENT === 'development' ? undefined : 60 * 10 * 1000 // 10 minutes.
export function useUnreadThreads({ filters }: UnreadThreadsProps): number | null {
  const { data } = useUnreadDirectMessageThreadsQuery({
    variables: {
      filters,
    },
    pollInterval: POLL,
  })
  const unreadCount = data?.viewer?.directMessageThreads.unread
  return unreadCount !== undefined && unreadCount !== null ? unreadCount : null
}

export interface UnreadThreadsProps {
  filters?: ThreadListFilters
}
export function UnreadThreads(props: UnreadThreadsProps) {
  const unreadCount = useUnreadThreads(props)
  return unreadCount ? <span>{unreadCount}</span> : null
}
