const MAX_COLUMNS = 10
const DEFAULT_ITEM_WIDTH_SPAN = [250, 350]

export function getItemWidthAndColumns(
  gutter: number,
  totalWidth: number,
  itemWidthSpan = DEFAULT_ITEM_WIDTH_SPAN,
): { itemWidth: number; numColumns: number } {
  for (let i = MAX_COLUMNS; i > 0; i--) {
    const itemWidth = (totalWidth - gutter * (i - 1)) / i
    if (itemWidth >= itemWidthSpan[0] && itemWidth <= itemWidthSpan[1]) {
      return { itemWidth, numColumns: i }
    }
  }
  return { itemWidth: itemWidthSpan[0], numColumns: 1 }
}
