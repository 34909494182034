import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Popover } from 'antd'
import { PopoverProps } from 'antd/lib/popover'
import React from 'react'

import { HSpaced } from './Block'
import { getFlag, setFlag } from './flags'

// This flag is here to make sure we only render one of these on the page, every time
// the page is refreshed. That way the teacher isn't inundated with these popovers.
let didEmbedKeys: string[] = []

// This is to make sure only one popover automatically shows at the same time on the page.
let visiblePopoverKey: string | null = null
let keyTimeout: any = null

// These resetCallbacks are used in the ResetTutorialPopoversButton.
type ResetCallback = () => void
let resetCallbacks: ResetCallback[] = []
const resetFlags = new Set<string>()
function performResetCallbacks() {
  didEmbedKeys = []
  for (const flag of resetFlags) {
    setFlag(flag, null)
  }
  resetFlags.clear()
  visiblePopoverKey = null
  keyTimeout = null
  for (const callback of resetCallbacks) {
    callback()
  }
}

export interface TutorialPopoverProps extends Omit<Partial<PopoverProps>, 'content'> {
  children: React.ReactElement
  /** This should include your feature name, or a short version of it. */
  tutorialKey: string
  content: React.ReactElement
  autoShow?: boolean
}
/** @deprecated use the DimissablePopover from @thesisedu/react instead. */
export function TutorialPopover({
  autoShow,
  children,
  tutorialKey,
  content,
  ...rest
}: TutorialPopoverProps) {
  const [shouldEmbed, setShouldEmbed] = React.useState(false)
  const [visible, setVisible] = React.useState(true)
  React.useEffect(() => {
    if (visible) {
      clearTimeout(keyTimeout)
      keyTimeout = setTimeout(() => {
        visiblePopoverKey = tutorialKey
      }, 100)
    } else if (visiblePopoverKey === tutorialKey) {
      visiblePopoverKey = null
    }
  }, [visible, tutorialKey])
  React.useEffect(() => {
    if (!visible) {
      const cancel = setTimeout(() => {
        setShouldEmbed(false)
      }, 500)
      return () => clearTimeout(cancel)
    }
  }, [visible])
  React.useEffect(() => {
    const callback = () => {
      if (autoShow && !didEmbedKeys.includes(tutorialKey) && !getFlag(tutorialKey)) {
        if (!visiblePopoverKey) {
          didEmbedKeys.push(tutorialKey)
          setShouldEmbed(true)
          setVisible(true)
        } else {
          console.warn(
            'Not showing TutorialPopover with key "%s" - we\'re already showing another one with autoShow enabled ("%s").',
            tutorialKey,
            visiblePopoverKey,
          )
        }
      }
    }
    resetFlags.add(tutorialKey)
    resetCallbacks.push(callback)
    callback()
    return () => {
      const index = resetCallbacks.indexOf(callback)
      resetCallbacks = resetCallbacks.splice(index, 1)
    }
  }, [autoShow])
  if (shouldEmbed) {
    return (
      <Popover
        placement={'left'}
        trigger={['click']}
        overlayStyle={{ width: 300 }}
        {...rest}
        visible={visible}
        onVisibleChange={setVisible}
        content={
          <>
            {content}
            <HSpaced justify={'flex-end'}>
              <Button
                type={'link'}
                size={'small'}
                onClick={() => {
                  setFlag(tutorialKey, true)
                  setVisible(false)
                }}
              >
                Don't show again
              </Button>
              <Button
                size={'small'}
                onClick={() => {
                  setVisible(false)
                }}
              >
                Close
              </Button>
            </HSpaced>
          </>
        }
      >
        {children}
      </Popover>
    )
  } else {
    return (
      <span
        onMouseEnter={
          autoShow
            ? undefined
            : () => {
                const willEmbed =
                  !didEmbedKeys.includes(tutorialKey) && !getFlag(tutorialKey) && !visiblePopoverKey
                if (willEmbed) {
                  didEmbedKeys.push(tutorialKey)
                  setShouldEmbed(true)
                  setVisible(true)
                }
              }
        }
      >
        {children}
      </span>
    )
  }
}

export function ResetTutorialPopoversButton(props: Partial<ButtonProps>) {
  return (
    <Button
      icon={<QuestionCircleOutlined />}
      type={'link'}
      {...props}
      onClick={() => {
        performResetCallbacks()
      }}
    />
  )
}
