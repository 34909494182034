import {
  DndContextProps,
  DragOverEvent,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { useFreshRef } from '@thesisedu/feature-react'
import React from 'react'
import { createPortal } from 'react-dom'

import { useOffsetClosestCenter } from './offsetClosestCenter'
import { snapTopLeftToCursor } from './snapTopLeftToCursor'
import { DEPTH_INDENT_PX } from './types'

export interface DraggingOverList {
  sortableId: string
  overId: string
}
interface DragEndItem {
  id: number | string
  sortableId?: string
}
export interface OnDragEndOpts {
  moving: DragEndItem
  over: DragEndItem
}
export interface DragDropOutlineOpts {
  onDragEnd: (opts: OnDragEndOpts) => void
  onDragOver?: (opts: DragOverEvent) => void
  renderPortalItem: (id: number | string) => React.ReactElement
  activationDistance?: number | false
}
export interface DragDropOutlineResult {
  dndContextProps: DndContextProps
  overlayPortal: React.ReactPortal
  activeId: UniqueIdentifier | null
  offsetLeft: number | null
  draggingOverList: React.RefObject<DraggingOverList | undefined>
}
export function useDragDropOutline({
  onDragEnd,
  onDragOver,
  renderPortalItem,
  activationDistance = 5,
}: DragDropOutlineOpts): DragDropOutlineResult {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = React.useState<number | null>(null)
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint:
        activationDistance === false
          ? undefined
          : {
              distance: activationDistance,
            },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  React.useEffect(() => {
    if (activeId) {
      document.body.style.cursor = 'grabbing'
      return () => {
        document.body.style.cursor = ''
      }
    }
  }, [!!activeId])

  const [draggingOverList, setDraggingOverList] = React.useState<DraggingOverList | undefined>(
    undefined,
  )
  const draggingOverListRef = useFreshRef(draggingOverList)
  const justSetDraggingOver = React.useRef<string | null>(null)
  React.useEffect(() => {
    if (draggingOverList) {
      const handle = setTimeout(() => {
        justSetDraggingOver.current = null
      }, 5)
      return () => clearTimeout(handle)
    }
  }, [draggingOverList])

  const offsetClosestCenter = useOffsetClosestCenter({ forceOverIdRef: justSetDraggingOver })

  return {
    dndContextProps: {
      sensors,
      collisionDetection: offsetClosestCenter,
      onDragStart({ active }) {
        setActiveId(active.id)
        const sortableId = active.data.current?.sortable?.containerId
        if (sortableId) {
          const overId = active.id.toString()
          justSetDraggingOver.current = overId
          draggingOverListRef.current = { overId, sortableId }
          setDraggingOverList({ overId, sortableId })
        }
      },
      onDragOver(event) {
        const { over } = event
        if (over && !justSetDraggingOver.current) {
          const sortableId = over.data.current?.sortable?.containerId
          const overId = over.id.toString()
          setDraggingOverList(list => {
            if (!sortableId) return undefined
            // We only want to set this once we first move over the list, because
            // otherwise we'll keep changing the overId and that's not great.
            if (list?.sortableId !== sortableId) {
              justSetDraggingOver.current = overId
              draggingOverListRef.current = { overId, sortableId }
              return { sortableId, overId }
            }
            return list
          })
        }
        onDragOver?.(event)
      },
      onDragMove({ activatorEvent, over, delta, collisions, active }) {
        const pointerEvent =
          activatorEvent.type.startsWith('pointer') || activatorEvent.type.startsWith('mouse')
            ? (activatorEvent as PointerEvent)
            : undefined
        if (pointerEvent && over?.rect.left) {
          const currentOffsetX = (pointerEvent.offsetX || pointerEvent.clientX) + delta.x
          const currentX = currentOffsetX - over.rect.left
          const normalizedDelta = currentX - 30 // Add some padding...
          setOffsetLeft(Math.max(0, Math.floor(normalizedDelta / (DEPTH_INDENT_PX * 2))))
        } else {
          setOffsetLeft(null)
        }
      },
      onDragEnd({ over, active }) {
        setDraggingOverList(undefined)
        if (over) {
          onDragEnd({
            moving: {
              id: active.id,
              sortableId: active.data.current?.sortable?.containerId,
            },
            over: {
              id: over.id,
              sortableId: over.data.current?.sortable?.containerId,
            },
          })
        }
        setActiveId(null)
        setOffsetLeft(null)
      },
      onDragCancel() {
        setOffsetLeft(null)
        setActiveId(null)
      },
    },
    overlayPortal: createPortal(
      <DragOverlay
        dropAnimation={null}
        style={{ width: 300, pointerEvents: 'none' }}
        modifiers={[snapTopLeftToCursor]}
      >
        {activeId ? renderPortalItem(activeId) : null}
      </DragOverlay>,
      document.body,
    ),
    offsetLeft,
    activeId,
    draggingOverList: draggingOverListRef,
  }
}
