import React from 'react'
import { CSVLink } from 'react-csv'

export const StudentSampleLink: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <CSVLink
      data={[
        ['student id', 'firstName', 'lastName'],
        ['abcd.001', 'John', 'Doe'],
        ['abcd.002', 'Kathy', 'Smith'],
      ]}
      filename={'student-sample.csv'}
    >
      download a sample spreadsheet.
    </CSVLink>
  )
}
