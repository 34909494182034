import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { WrapItemsProps } from '../custom_content/CustomSegmentList'

export function MyContentListWrapper({ children, items }: WrapItemsProps) {
  return (
    <SortableContext
      items={items.map(item => ({ ...item, id: fromGlobalId(item.id, true).id }))}
      strategy={verticalListSortingStrategy}
      id={'my-content'}
    >
      <VSpaced
        style={{ flex: 1, overflowY: 'auto', overflowX: 'visible' }}
        paddingVertical={'xs'}
        paddingHorizontal={'s'}
        children={children}
      />
    </SortableContext>
  )
}
