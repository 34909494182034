import { ClockCircleOutlined } from '@ant-design/icons'
import {
  BodySmall,
  DATE_FORMATS,
  ExtraSmallButton,
  HSpaced,
  styled,
  StyledThemeContext,
} from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { useOutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentIndicatorProps, SegmentIndicatorResource } from '../../outline/SegmentIndicators'
import { ScheduleSegmentModal, useSegmentScheduledAt } from '../../segment/ScheduleSegmentButton'

function ScheduleIndicator({ segmentId }: SegmentIndicatorProps) {
  const scheduledAt = useSegmentScheduledAt(segmentId)
  const { enabledSegmentIds } = useOutlineSegmentContext()
  const isEnabled = enabledSegmentIds.includes(segmentId)
  const [visible, setVisible] = React.useState(false)
  const theme = React.useContext(StyledThemeContext)
  if (scheduledAt && !isEnabled) {
    return (
      <>
        <ScheduleSegmentModal
          segmentId={segmentId}
          visible={visible}
          onVisibleChange={setVisible}
        />
        <ScheduleContainer
          space={'@size-xxs'}
          onClick={() => setVisible(true)}
          title={`Scheduled for ${moment(scheduledAt).format(
            DATE_FORMATS.FULL,
          )}. Click to Reschedule / Unschedule Segment`}
        >
          <ExtraSmallButton
            icon={<ClockCircleOutlined />}
            style={{ background: theme['@orange-light'], color: theme['@orange'] }}
          />
          <BodySmall color={'@orange'} style={{ lineHeight: 1 }}>
            {moment(scheduledAt).format(DATE_FORMATS.MONTH_DAY_SHORT)}
          </BodySmall>
        </ScheduleContainer>
      </>
    )
  } else {
    return null
  }
}

const ScheduleContainer = styled(HSpaced)`
  cursor: pointer;
  &:not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']} !important;
  }
`

export const Schedule: SegmentIndicatorResource = {
  type: 'SegmentIndicator',
  identifier: 'Schedule',
  component: ScheduleIndicator,
}
