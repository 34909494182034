import { Select, Form } from 'antd'
import React from 'react'

import { REGIONS } from './constants'
import { AccountRegion } from './schema'

export function SiteFormExtension() {
  return (
    <Form.Item
      name={'region'}
      label={'Region'}
      initialValue={Object.keys(REGIONS)[0]}
      rules={[{ required: true, message: 'A region is required.' }]}
    >
      <Select<AccountRegion>>
        {(Object.keys(REGIONS) as AccountRegion[]).map(region => (
          <Select.Option key={region} value={region}>
            {REGIONS[region]}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
