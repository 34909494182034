import { BrochureLink, useFeatureRoot, useMutateHook } from '@thesisedu/feature-react'
import { styled, media } from '@thesisedu/react'
import { s } from '@thesisedu/ui'
import React from 'react'

import { FeatureWeb } from '../FeatureWeb'
import { FooterContentHook } from '../types'

export function Footer() {
  const root = useFeatureRoot() as FeatureWeb
  const content = (
    <React.Fragment key={'primary'}>
      <Container key={'primary'}>
        Copyright &copy; {new Date().getFullYear()} {root.appOptions.name} &middot;{' '}
        {process.env.REACT_APP_RELEASE
          ? `Version ${process.env.REACT_APP_RELEASE}`
          : 'Development Version'}
        &nbsp;&middot;&nbsp;
        <BrochureLink to={'terms-of-use'} target={'_blank'} rel={'noreferrer'}>
          Terms of Use
        </BrochureLink>
        &nbsp;&middot;&nbsp;
        <BrochureLink to={'privacy'} target={'_blank'} rel={'noreferrer'}>
          Privacy Policy
        </BrochureLink>
        &nbsp;&middot;&nbsp;
        <BrochureLink to={'support'} target={'_blank'} rel={'noreferrer'}>
          Support
        </BrochureLink>
      </Container>
      {root.isValidBrowserVersion ? null : (
        <OutdatedMessage>
          You're using an unsupported or outdated browser. Please update.
        </OutdatedMessage>
      )}
    </React.Fragment>
  )

  return <>{useMutateHook<FooterContentHook>('feature-web:footer-content', [content], undefined)}</>
}

const Container = styled.div`
  text-align: center;
  font-size: 11px;
  margin-top: auto;
  ${media.lg} {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-right: 0;
  }
`
export const OutdatedMessage = styled(Container)`
  background: ${s.color('orange.hoverElement')};
  color: ${s.color('orange.text')};
  margin-top: ${s.size('xxs')};
  padding: ${s.size('xxs')} ${s.size('xs')};
  border-radius: ${s.var('radii.1')};
  display: inline-block !important;
  font-size: ${s.size('font.xs')};
`
