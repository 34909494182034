import { Button } from 'antd'
import React, { useState } from 'react'

import { CreateSiteModal, CreateSiteModalProps } from './CreateSiteModal'

export const CreateSiteButton: React.FC<
  React.PropsWithChildren<Partial<CreateSiteModalProps>>
> = props => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CreateSiteModal onCancel={() => setVisible(false)} visible={visible} {...props} />
      <Button type={'primary'} onClick={() => setVisible(true)}>
        Create Site
      </Button>
    </>
  )
}
