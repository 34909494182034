import { PictureOutlined } from '@ant-design/icons'
import { FileField, getFileInfo } from '@thesisedu/feature-attachments-react'
import { ImageLoadResult, useImageLoad } from '@thesisedu/web'
import React from 'react'

import { ThumbnailLoadingContent } from '../../VideoThumbnail'
import {
  VideoThumbnailTypeResource,
  URLVideoThumbnail,
  VideoThumbnailTypeFieldProps,
  VideoThumbnailTypeViewProps,
} from '../../types'

function URLVideoThumbnailField({
  value,
  onChange,
}: VideoThumbnailTypeFieldProps<URLVideoThumbnail>) {
  return (
    <FileField
      value={value?.url}
      onChange={onChange ? e => onChange(e ? { url: e } : null) : undefined}
      uploadProps={{
        isPublic: true,
        accept: 'image/*',
        maxFileSizeBytes: 1e6, // 1MB
        uploadMessage: 'Click or drop to upload a new thumbnail',
      }}
    />
  )
}

function URLVideoThumbnailView({ value }: VideoThumbnailTypeViewProps<URLVideoThumbnail>) {
  const { filename, label } = getFileInfo(value.url)
  const imageLoadState = useImageLoad(filename)
  return (
    <ThumbnailLoadingContent
      loading={imageLoadState === ImageLoadResult.Loading}
      error={imageLoadState === ImageLoadResult.Error}
    >
      <img src={filename} alt={label} />
    </ThumbnailLoadingContent>
  )
}

const resource: VideoThumbnailTypeResource<URLVideoThumbnail> = {
  type: 'VideoThumbnailType',
  identifier: 'URLVideoThumbnail',
  FieldComponent: URLVideoThumbnailField,
  ViewComponent: URLVideoThumbnailView,
  icon: <PictureOutlined />,
  label: 'Uploaded Thumbnail',
}
export default resource
