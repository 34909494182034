import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { getStudentClassTabName } from '@thesisedu/feature-assignments-react'
import {
  AssignmentForSegmentDocument,
  AssignmentForSegmentQuery,
  AssignmentForSegmentQueryVariables,
} from '@thesisedu/feature-course-assignments-react/dist/schema'
import { getSegmentRawId } from '@thesisedu/feature-courses-react/dist/helpers'
import { ReportMetricResource } from '@thesisedu/feature-reports-react'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export function getGradeOnClick(
  feature: CourseReportsReactFeature,
  metric: 'grade' | 'numAttempts',
): ReportMetricResource['getOnClick'] {
  return item => {
    const anyMetrics = item.metrics as Record<string, any>
    const hasValue = Object.keys(anyMetrics).some(
      key => anyMetrics[key]?.[metric] !== undefined && anyMetrics[key]?.[metric] !== null,
    )
    const segmentId = item.dimensions.segment?.segment.id
    const classId = item.dimensions.classes?.class.id || item.dimensions.students?.class.id
    const studentId = item.dimensions.students?.student.id
    if (segmentId && classId && hasValue) {
      const rawSegmentId = getSegmentRawId(segmentId)
      return async () => {
        const apolloFeature = feature.root.requireFeature<ApolloReactFeature>(
          ApolloReactFeature.package,
        )
        const result = await apolloFeature.client.query<
          AssignmentForSegmentQuery,
          AssignmentForSegmentQueryVariables
        >({
          query: AssignmentForSegmentDocument,
          variables: {
            classId,
            segmentId: rawSegmentId,
          },
        })
        const assignment =
          result.data?.node?.__typename === 'Class'
            ? result.data.node.assignmentForSegment
            : undefined
        if (assignment) {
          window.open(
            `${window.location.origin}/teacher/classes/${classId}/grades?grading=${
              assignment.id
            }&tab=${studentId ? getStudentClassTabName(studentId, classId) : 'all-students'}`,
            '_blank',
          )
        }
      }
    } else {
      return null
    }
  }
}

export default function addMetric(feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportMetricResource>({
    type: 'ReportMetric',
    metricKey: 'grade',
    summarization: 'weighted',
    identifier: 'grade',
    icon: <Check />,
    title: 'Grade',
    description: 'All assignment grades.',
    shortLabel: 'grades',
    format: 'percent',
    graphAxisMax: 1,
    getOnClick: getGradeOnClick(feature, 'grade'),
  })
}
