import { MutationHookOptions } from '@apollo/client'

import {
  NotificationFragment,
  NotificationFragmentDoc,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
  UnreadNotificationsFragment,
  UnreadNotificationsFragmentDoc,
  useReadNotificationsMutation as useMutation,
} from '../schema'

export const updateNotificationsMutation: MutationHookOptions<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
>['update'] = (proxy, result) => {
  let delta = 0
  let userId: string | null = null
  const notifications = result?.data?.readNotifications.notifications
  for (const notification of notifications || []) {
    const existingFragment = proxy.readFragment<NotificationFragment>({
      fragment: NotificationFragmentDoc,
      fragmentName: 'Notification',
      id: `Notification:${notification.itemId}`,
    })
    if (existingFragment) {
      userId = existingFragment.userId
      if (existingFragment.isRead && !notification.isRead) delta++
      else if (!existingFragment.isRead && notification.isRead) delta--
      proxy.writeFragment<NotificationFragment>({
        fragment: NotificationFragmentDoc,
        fragmentName: 'Notification',
        id: `Notification:${notification.itemId}`,
        data: {
          ...existingFragment,
          ...notification,
        },
      })
    }
  }

  if (delta && userId) {
    const fragmentOpts = {
      fragment: UnreadNotificationsFragmentDoc,
      fragmentName: 'UnreadNotifications',
      id: `User:${userId}`,
    }
    const fragment = proxy.readFragment<UnreadNotificationsFragment>(fragmentOpts)
    if (fragment) {
      proxy.writeFragment<UnreadNotificationsFragment>({
        ...fragmentOpts,
        data: {
          ...fragment,
          unreadNotifications: fragment.unreadNotifications + delta,
        },
      })
    }
  }
}

export const useReadNotificationsMutation: typeof useMutation = opts =>
  useMutation({
    update: (proxy, result, options) => {
      if (opts?.update) {
        opts.update(proxy, result, options)
      }
      updateNotificationsMutation(proxy, result, options)
    },
    ...opts,
  })
