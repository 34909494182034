import { useMutateHook } from '@thesisedu/feature-react'
import { PageHead, styled, useNavigate, VSpaced } from '@thesisedu/react'
import React from 'react'

import { ExploreBanner } from '../../../explore/ExploreBanner'
import { ExploreLookingFor } from '../../../explore/ExploreLookingFor'
import { ExploreSearchField } from '../../../explore/ExploreSearchField'
import { NewExploreCoursesWidget } from '../../../explore/NewExploreCoursesWidget'
import { NewExploreTagsWidget } from '../../../explore/NewExploreTagsWidget'
import { ExploreHomeContentHook } from '../../../types'

export function ExploreHome() {
  const navigate = useNavigate()
  const content = useMutateHook<ExploreHomeContentHook>(
    'courses-react:explore-home-content',
    [
      <VSpaced space={'@size-s'} align={'stretch'} key={'search-looking-for'}>
        <ExploreSearchField
          onConfirm={value => navigate(`./search?name=${encodeURIComponent(value)}`)}
        />
        <ExploreLookingFor />
      </VSpaced>,
      <NewExploreCoursesWidget key={'courses'} />,
      <NewExploreTagsWidget
        key={'content-areas'}
        title={'Content Areas in your license'}
        type={'CONTENT_AREA'}
        getPath={tag => `content-area/${tag.id}`}
      />,
    ],
    undefined,
  )
  return (
    <>
      <PageHead title={'Explore'} />
      <ExplorePageContainer>
        <ExploreBanner />
        <VSpaced align={'stretch'} space={'@size-l'}>
          {content}
        </VSpaced>
      </ExplorePageContainer>
    </>
  )
}

const ExplorePageContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`
