import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  MediaFragment,
  UpdateMediaMutation,
  useUpdateMediaMutation as useMutation,
  MediaFragmentDoc,
} from '../schema'

const updater = updateMutation<UpdateMediaMutation, MediaFragment>({
  fragment: MediaFragmentDoc,
  fragmentName: 'Media',
  path: 'updateMedia.media',
})
export const useUpdateMediaMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating this media.'),
    update: (proxy, mutationResult, options) => {
      // We're not passing the updated path or thumbnail as it will cause the
      // video feed to refresh, losing the user's current position.
      delete mutationResult.data?.updateMedia.media.path
      delete mutationResult.data?.updateMedia.media.thumbnail
      return updater(proxy, mutationResult, options)
    },
    ...opts,
  })
