import {
  Button,
  Body,
  HSpaced,
  InputNumber,
  AntIconWrapper,
  ButtonProps,
  isNative,
} from '@thesisedu/react'
import { ArrowLeft, Check } from '@thesisedu/react/icons'
import pluralize from 'pluralize'
import React from 'react'

const QUICK_GRADE_SEGMENTS = isNative ? 4 : 5

export interface QuickGradeFieldProps {
  value?: number
  onChange?: (value: number | undefined) => void
  totalPoints: number
  buttonProps?: Partial<ButtonProps>
  hideConfirm?: boolean
}
export function QuickGradeField({
  value,
  onChange,
  totalPoints,
  buttonProps,
  hideConfirm,
}: QuickGradeFieldProps) {
  const [otherVisible, setOtherVisible] = React.useState(false)
  const [otherValue, setOtherValue] = React.useState<number>()
  const segmentValues = new Array(QUICK_GRADE_SEGMENTS - 1)
    .fill('')
    .map<number>((_, index) => {
      return Math.floor((totalPoints / (QUICK_GRADE_SEGMENTS - 1)) * index)
    })
    .filter((val, index, arr) => !arr.includes(val, index + 1))
    .concat(totalPoints)
  React.useEffect(() => {
    const transformedValue = value !== null && value !== undefined ? value * totalPoints : value
    setOtherValue(transformedValue)
    if (
      transformedValue !== null &&
      transformedValue !== undefined &&
      !segmentValues.includes(transformedValue)
    ) {
      setOtherVisible(true)
    } else {
      setOtherVisible(false)
    }
  }, [value, totalPoints])

  let content
  if (otherVisible) {
    content = (
      <HSpaced justify={'flex-start'}>
        <Button
          size={'small'}
          icon={<AntIconWrapper children={<ArrowLeft />} />}
          onClick={() => setOtherVisible(false)}
        />
        <InputNumber
          value={otherValue}
          onChange={val => {
            const numberVal = parseFloat(val?.toString() || '')
            setOtherValue(isNaN(numberVal) ? undefined : numberVal)
          }}
          precision={2}
          min={0}
          style={{ width: 100 }}
        />
        {hideConfirm ? null : (
          <Button
            type={'primary'}
            size={'small'}
            icon={<AntIconWrapper children={<Check />} />}
            disabled={
              otherValue === null ||
              otherValue === undefined ||
              otherValue.toString() === value?.toString()
            }
            onClick={() => {
              if (onChange && otherValue !== null && otherValue !== undefined) {
                onChange(otherValue / totalPoints)
              }
            }}
          />
        )}
      </HSpaced>
    )
  } else {
    content = (
      <Button.Group>
        {segmentValues.map(val => (
          <Button
            {...buttonProps}
            type={
              value !== null && value !== undefined && value * totalPoints === val
                ? 'primary'
                : 'default'
            }
            key={val}
            children={parseFloat(val.toFixed(2)).toString()}
            onClick={onChange ? () => onChange(val / totalPoints) : undefined}
            data-testid={`${val}`}
          />
        ))}
        <Button {...buttonProps} onClick={() => setOtherVisible(true)}>
          <Body color={'@text-color-secondary'} data-testid={'other'}>
            Other...
          </Body>
        </Button>
      </Button.Group>
    )
  }

  return (
    <HSpaced justify={'flex-start'} data-testid={'QuickGradeField'}>
      {content}
      <Body>out of {pluralize('point', parseFloat(totalPoints.toFixed(2)), true)}</Body>
    </HSpaced>
  )
}
