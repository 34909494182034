import { MutateGradingModalVisibilityAssignment } from '@thesisedu/feature-assignments-react'
import { useCourseViewerModalContext } from '@thesisedu/feature-courses-react'

import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'
import { debug } from '../log'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<MutateGradingModalVisibilityAssignment>(
    'feature-assignments-react:grading-modal-visibility-assignment',
    callback => {
      const { setSegmentId } = useCourseViewerModalContext(true)
      return (assignment, context) => {
        const result = callback?.(assignment, context) ?? assignment
        if (assignment?.configuration?.segmentId) {
          debug(
            'setting segment id %s from grading modal with tab %s',
            assignment.configuration.segmentId,
            context.defaultTab,
          )
          setSegmentId(assignment.configuration.segmentId, context.defaultTab ?? 'settings')
          return undefined
        } else {
          return result
        }
      }
    },
  )
}
