import { useTagQuery } from '@thesisedu/feature-tags-react/dist/schema'
import { BlockSpin, NotFound, PageHead, PageHeader } from '@thesisedu/web'
import React from 'react'

import { ExploreGrid, ExploreGridProps } from './grid/ExploreGrid'
import { SegmentType } from '../schema'

export interface NewExploreTagProps {
  tagId: string
  children: ExploreGridProps['children']
}
export function NewExploreTag({ tagId, children }: NewExploreTagProps) {
  const { data, loading } = useTagQuery({
    variables: {
      id: tagId,
    },
  })
  const tag = data?.node?.__typename === 'Tag' ? data.node : undefined

  if (tag && tag.type) {
    return (
      <>
        <PageHead title={`${tag.name} - Explore`} />
        <PageHeader title={tag.name} />
        <ExploreGrid
          searchPlaceholder={'Search Lessons'}
          variables={{
            types: [SegmentType.Group],
            labels: ['Lesson'],
            tags: [
              {
                type: tag.type,
                name: tag.name,
              },
            ],
          }}
          children={children}
        />
      </>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'That tag could not be found.'} />
  }
}
