import { styled } from '@thesisedu/web'
import React from 'react'
import { useDragLayer } from 'react-dnd'

export function DragLayer() {
  const { isDragging, item, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !item?.element) return null
  return (
    <Layer>
      <div
        style={{
          transform: currentOffset
            ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
            : undefined,
          opacity: currentOffset ? 1 : 0,
          width: item?.width,
          height: item?.height,
        }}
      >
        {item.element}
      </div>
    </Layer>
  )
}

const Layer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
