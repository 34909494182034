import { ColorMicrosoft } from '@sammarks/icons'
import { getSAMLButton } from '@thesisedu/feature-users-react'
import React from 'react'

import { MSALSignInOpts, useMSALLogin } from './useMSALLogin'
import { useMsalIntegrationSearchQuery } from '../schema'

export const MSALLoginButton = getSAMLButton<MSALSignInOpts>({
  identifier: 'msal',
  icon: <ColorMicrosoft />,
  label: 'Microsoft',
  useLoginHook: useMSALLogin,
  useIntegrationSearch: ({ name, skip }) => {
    const { data, loading } = useMsalIntegrationSearchQuery({
      variables: {
        input: { name },
      },
      skip,
    })
    return {
      loading,
      results:
        data?.msalIntegrationSearch.map(item => {
          return {
            id: item.integrationId,
            name: item.name,
            siteName: item.siteName,
            signInOpts: {
              scopes: item.scopes,
            },
          }
        }) ?? [],
    }
  },
})
