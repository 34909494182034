import { SyncAssignmentActions } from '@thesisedu/feature-class-sync-react'
import { useSelectedClassId, useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import React from 'react'

import { useSyncAssignmentActionsProps } from './useSyncAssignmentActionsProps'
import { CoursesReactFeature } from '../../CoursesReactFeature'
import { SegmentType } from '../../schema'
import {
  ClassFragmentWithPermissions,
  SegmentActionComponentProps,
  SegmentActionResource,
} from '../../types'

function SyncAction({ segment, ...rest }: SegmentActionComponentProps) {
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const id = useSelectedClassId(true)
  const canSyncSegments = cls?.canSyncSegments
  const props = useSyncAssignmentActionsProps({
    classId: id,
    rawSegmentId: segment.id,
  })
  return <SyncAssignmentActions {...props} {...rest} disabled={!canSyncSegments} />
}

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<SegmentActionResource>({
    type: 'SegmentAction',
    identifier: 'sectionSync',
    component: SyncAction,
    group: '',
    weight: 10,
    handler: () => {},
    filter: segment => segment.type === SegmentType.Section,
  })
}
