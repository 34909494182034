import { Dropdown, Tooltip, Button } from '@thesisedu/ui'
import { CloudUpload } from '@thesisedu/ui/icons'
import React from 'react'

import { UploadModalContext, UploadModalContextValue } from './UploadModalContext'

export function UploadModalMenuItem() {
  const modalContext = React.useContext<UploadModalContextValue | null>(UploadModalContext)
  if (modalContext) {
    return (
      <Dropdown.Item
        onClick={() => {
          modalContext.showUploads()
        }}
        icon={<CloudUpload />}
      >
        Manage Uploads
      </Dropdown.Item>
    )
  } else {
    return null
  }
}

export function UploadModalMenuButton(props: any) {
  const modalContext = React.useContext<UploadModalContextValue | null>(UploadModalContext)
  if (modalContext) {
    return (
      <Tooltip title={'Manage Uploads'} contentProps={{ side: 'bottom' }}>
        <Button
          shape={'circle'}
          icon={<CloudUpload />}
          {...props}
          onPress={() => {
            modalContext.showUploads()
          }}
        />
      </Tooltip>
    )
  } else {
    return null
  }
}
