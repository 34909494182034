import React from 'react'

export interface FlagContextValue {
  flags: Record<string, any>
  setFlag: (flag: string, value?: any) => void
}
export const FlagContext = React.createContext<FlagContextValue | undefined>(undefined)

export function FlagContextProvider({ children }: React.PropsWithChildren<object>) {
  const [flags, setFlags] = React.useState<Record<string, any>>({})
  return (
    <FlagContext.Provider
      value={{
        flags,
        setFlag(flag, value) {
          setFlags(f => ({
            ...f,
            [flag]: value,
          }))
        },
      }}
    >
      {children}
    </FlagContext.Provider>
  )
}

export function useFlagContext(): FlagContextValue | undefined
export function useFlagContext(require: false): FlagContextValue | undefined
export function useFlagContext(require: true): FlagContextValue
export function useFlagContext(require?: boolean): FlagContextValue | undefined {
  const context = React.useContext(FlagContext)
  if (!context && require) {
    throw new Error('FlagContext is required, yet not provided.')
  }
  return context
}

export interface RefContextValue {
  refs: React.MutableRefObject<Record<string, React.MutableRefObject<any>>>
}
export const RefContext = React.createContext<RefContextValue | undefined>(undefined)

export function RefContextProvider({ children }: React.PropsWithChildren<object>) {
  const refs = React.useRef<Record<string, React.MutableRefObject<any>>>({})
  return <RefContext.Provider value={{ refs }} children={children} />
}

export function useRefContext(): RefContextValue | undefined
export function useRefContext(require: false): RefContextValue | undefined
export function useRefContext(require: true): RefContextValue
export function useRefContext(require?: boolean): RefContextValue | undefined {
  const context = React.useContext(RefContext)
  if (!context && require) {
    throw new Error('RefContext is required, yet not provided.')
  }
  return context
}

export function useDevtoolsRef<T>(name: string, defaultValue: T): React.MutableRefObject<T>
export function useDevtoolsRef<T>(
  name: string,
  defaultValue?: T,
): React.MutableRefObject<T | undefined>
export function useDevtoolsRef<T>(
  name: string,
  defaultValue?: T,
): React.MutableRefObject<T | undefined> {
  const newRef = React.useRef<T | undefined>(defaultValue)
  const { refs } = useRefContext(false) || {}
  const existingRef = refs?.current[name]
  if (!existingRef && refs) {
    refs.current[name] = newRef
  }

  return existingRef || newRef
}

export function useDevtoolsFlag<T>(name: string): [T | undefined, (value: T | undefined) => void] {
  const { flags = {}, setFlag } = useFlagContext(false) || {}
  return [
    flags[name],
    value => {
      if (setFlag) {
        setFlag(name, value)
      }
    },
  ]
}
