import { Block, BodyExtraSmall, DATE_FORMATS, styled, Tooltip } from '@thesisedu/react'
import { Clock, RefreshDouble, ShoppingCodeCheck } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

import { AssignmentWithSyncFragment, BasicAssignmentFragment } from '../schema'

type Assignment = BasicAssignmentFragment & Partial<AssignmentWithSyncFragment>
interface Decorator {
  filter: (assignment: Assignment) => boolean
  message: string | ((assignment: Assignment) => string)
  icon: React.ReactElement
  color: string
  id: string
}
const Decorators: Decorator[] = [
  {
    id: 'sync',
    filter: assignment => !!assignment.syncs?.edges.length,
    color: '@blue',
    icon: <RefreshDouble />,
    message: assignment => {
      const classNames = assignment.syncs!.edges.map(edge => edge.node.classSync.label)
      return `Synced with ${classNames.join(', ')}`
    },
  },
  {
    id: 'generated',
    filter: assignment => !!assignment.generated,
    color: '@green',
    icon: <ShoppingCodeCheck style={{ transform: 'scale(1.3)' }} />,
    message: 'Automatically suggested assignment',
  },
]

export interface AssignmentHeaderDecoratorProps {
  assignment: BasicAssignmentFragment & Partial<AssignmentWithSyncFragment>
  classId?: string
}
export function AssignmentHeaderDecorator({ assignment, classId }: AssignmentHeaderDecoratorProps) {
  const decorators = Decorators.filter(decorator => decorator.filter(assignment))
  const category = classId
    ? assignment.assignmentCategories.edges.find(edge => edge.node.classId === classId)?.node
    : assignment.assignmentCategories.edges[0]?.node
  return (
    <>
      {category?.name ? (
        <Block marginBottom={'@size-xxs'}>
          <Container color={'@gray-2'} padded style={{ display: 'inline-block' }}>
            <BodyExtraSmall color={'@gray-5'} truncate>
              {category.name}
            </BodyExtraSmall>
          </Container>
        </Block>
      ) : null}
      <OuterContainer>
        <Container color={'@gray-2'} padded>
          <BodyExtraSmall color={'@gray-5'}>
            {parseFloat(assignment.totalPoints.toFixed(1))} pt
            {assignment.totalPoints === 1 ? '' : 's'}
          </BodyExtraSmall>
        </Container>
        {assignment.dueAt ? (
          <Tooltip title={`Due ${moment(assignment.dueAt).format(DATE_FORMATS.FULL)}`}>
            <Container color={'@gray-2'} padded>
              <BodyExtraSmall color={'@gray-5'}>
                <Clock /> {moment(assignment.dueAt).format('MM/DD')}
              </BodyExtraSmall>
            </Container>
          </Tooltip>
        ) : null}
        {decorators.map(decorator => {
          const message =
            typeof decorator.message === 'string'
              ? decorator.message
              : decorator.message(assignment)
          return (
            <Tooltip title={message} key={decorator.id}>
              <Container color={decorator.color}>{decorator.icon}</Container>
            </Tooltip>
          )
        })}
      </OuterContainer>
    </>
  )
}

const Container = styled.div<{ color: string; padded?: boolean }>`
  min-width: ${props => props.theme['@size-m']};
  height: ${props => props.theme['@size-m']};
  padding: ${props => (props.padded ? `0 ${props.theme['@size-xs']}` : '0')};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(${props => props.theme['@size-s']} - 4px);
  background: ${props => props.theme[`${props.color}-light`] || props.theme[props.color]};
  color: ${props => props.theme[props.color]};
`
const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${props => props.theme['@size-xxs']};
  flex-wrap: wrap;
`
