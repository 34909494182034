import { LoadingOutlined } from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import { modifyQueryDocument, InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { filterStudents, useTeacherClass } from '@thesisedu/feature-classes-react'
import { useFreshRef, useFeatureRoot } from '@thesisedu/feature-react'
import { useEffectAfterMount } from '@thesisedu/react'
import { Block, BlockSpin, ScrollDirection, styled } from '@thesisedu/web'
import { StickyGrid } from '@thesisedu/web/dist/react-window'
import { Empty } from 'antd'
import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  areEqual,
  GridChildComponentProps,
  GridOnItemsRenderedProps,
  VariableSizeGrid as Grid,
} from 'react-window'

import {
  getColumnOffset,
  gridColumnWidth,
  gridRowHeight,
  ROW_OFFSET,
  STUCK_COLUMNS,
  STUCK_ROWS,
} from './constants'
import { GradesTableContextProvider } from './contexts/GradesTableContext'
import { AssignmentWithSubmissions } from './grading/types'
import {
  OrderDirection,
  BasicAssignmentFragment,
  AssignmentDocument,
  AssignmentQuery,
  AssignmentQueryVariables,
  AssignmentSubmissionsDocument,
  AssignmentSubmissionsQuery,
  AssignmentAssignedStudentsFragment,
  AssignmentsQuery,
  AssignmentsQueryVariables,
  AssignmentsDocument,
} from './schema'
import { Filters } from './table/AssignmentFilters'
import {
  AssignmentAverageHeaderCell,
  AssignmentHeaderCell,
  AssignmentStudentCellCell,
  ClassAverageCell,
  ColumnHeaderCell,
  RowHeaderCell,
  StudentAverageGradeCell,
  StudentNameHeaderCell,
} from './table/GridCells'
import { NameFilterCell } from './table/NameFilterCell'
import { TableFilterContext } from './table/TableFilterContext'
import { useHeaderTagVisibility } from './table/useHeaderTagVisibility'
import { ClassFragmentWithGrade } from './types'

interface StudentEdge {
  grade?: number | null
  classId?: string
  /** If node is not present, then it's the average row */
  node?: {
    id: string
    user: {
      id: string
      name?: string | null
      username: string
    }
  }
}

export interface ClassGradesTableProps
  extends Omit<ClassGradesTableInnerProps, 'assignments' | 'cls'> {
  filters?: Filters
  name?: string
}
export function ClassGradesTable({ filters: _filters, name, ...props }: ClassGradesTableProps) {
  const { orderBy, orderDirection, ...filters } = _filters || {}
  const { cls } = useTeacherClass(
    filters.classIds?.length === 1 ? filters.classIds[0] : null,
    false,
  )
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <GradesTableContextProvider containerRef={containerRef}>
      <div ref={containerRef} style={{ width: '100%' }}>
        <InfiniteQuery<BasicAssignmentFragment, AssignmentsQuery, AssignmentsQueryVariables>
          document={AssignmentsDocument}
          resultPath={'viewer.teacher.assignments'}
          noLoading
          variables={{
            filters,
            name,
            orderBy: orderBy || 'createdAt',
            orderDirection: orderDirection || OrderDirection.Asc,
            first: 16,
          }}
          infiniteScrollerProps={{
            scrollableTarget: () => {
              if (containerRef.current) {
                return containerRef.current.querySelector<HTMLDivElement>(
                  '.grade-grid-outer-content',
                )
              } else {
                return null
              }
            },
            direction: ScrollDirection.Horizontal,
            hideLoader: true,
          }}
          children={({ data, loading, loadingMore }) => {
            const assignments =
              data?.viewer?.teacher?.assignments.edges.map(edge => edge.node) || []
            return (
              <ClassGradesTableInner
                {...props}
                loading={loading && !loadingMore}
                loadingMore={loadingMore}
                cls={cls || undefined}
                assignments={assignments}
              />
            )
          }}
        />
      </div>
    </GradesTableContextProvider>
  )
}

export interface ClassGradesTableContextValue extends ClassGradesTableInnerProps {
  students: StudentEdge[]
  hasGradeColumn: boolean
}
export const ClassGradesTableContext = React.createContext<
  ClassGradesTableContextValue | undefined
>(undefined)
export function useClassGradesTableContext(): ClassGradesTableContextValue | undefined
export function useClassGradesTableContext(require: false): ClassGradesTableContextValue | undefined
export function useClassGradesTableContext(require: true): ClassGradesTableContextValue
export function useClassGradesTableContext(
  require?: boolean,
): ClassGradesTableContextValue | undefined {
  const context = React.useContext(ClassGradesTableContext)
  if (!context && require) {
    throw new Error('ClassGradesTableContext is required, yet not provided.')
  }
  return context
}

interface LoadedAssignments {
  [assignmentId: string]: AssignmentWithSubmissions &
    Pick<Partial<AssignmentAssignedStudentsFragment>, 'students'>
}
interface LazyLoadSubmissionsOpts {
  includeAssignedStudents?: boolean
  hasGradeColumn?: boolean
}
const PINNED_COLUMN_COUNT = 1
const LOAD_TIMEOUT = 500
function useLazyLoadSubmissions(
  assignments: ClassGradesTableInnerProps['assignments'],
  { includeAssignedStudents = false, hasGradeColumn }: LazyLoadSubmissionsOpts = {},
) {
  const [loadedAssignments, setLoadedAssignments] = React.useState<LoadedAssignments>({})
  const loadedRef = useFreshRef(loadedAssignments)
  const assignmentsRef = useFreshRef(assignments)
  const toLoadRef = React.useRef<string[]>([])
  const toLoadTimeout = React.useRef<any>()
  const client = useApolloClient()
  const root = useFeatureRoot()
  const pinnedCount = hasGradeColumn ? PINNED_COLUMN_COUNT : PINNED_COLUMN_COUNT - 1
  const onItemsRendered = React.useCallback(
    ({ overscanColumnStartIndex, overscanColumnStopIndex }: GridOnItemsRenderedProps) => {
      const assignmentIndexStart = overscanColumnStartIndex - pinnedCount
      const assignmentIndexStop = overscanColumnStopIndex - pinnedCount
      clearTimeout(toLoadTimeout.current)
      toLoadRef.current = assignmentsRef.current
        .filter((assignment, index) => {
          return (
            index >= assignmentIndexStart &&
            index < assignmentIndexStop &&
            !loadedRef.current[assignment.id]
          )
        })
        .map(assignment => assignment.id)
      if (toLoadRef.current.length) {
        toLoadTimeout.current = setTimeout(async () => {
          await Promise.all(
            toLoadRef.current.map(assignmentId => {
              return new Promise<void>(resolve => {
                client
                  .watchQuery<
                    AssignmentQuery | AssignmentSubmissionsQuery,
                    AssignmentQueryVariables
                  >({
                    query: modifyQueryDocument(
                      includeAssignedStudents ? AssignmentDocument : AssignmentSubmissionsDocument,
                      root.deps.hookManager,
                    ),
                    variables: {
                      id: assignmentId,
                    },
                    errorPolicy: 'ignore',
                  })
                  .subscribe(result => {
                    const assignment =
                      result?.data?.node?.__typename === 'Assignment' ? result.data.node : undefined
                    if (assignment) {
                      setLoadedAssignments(la => {
                        const loaded = la[assignment.id]
                        return { ...la, [assignment.id]: { ...loaded, ...assignment } }
                      })
                    }
                    resolve()
                  })
              })
            }),
          )
        }, LOAD_TIMEOUT)
      }
    },
    [setLoadedAssignments],
  )

  return {
    onItemsRendered,
    assignments: assignments.map(assignment => {
      return {
        ...assignment,
        ...loadedAssignments[assignment.id],
      }
    }),
  }
}

export interface ClassGradesTableInnerProps {
  assignments: (BasicAssignmentFragment | AssignmentWithSubmissions)[]
  cls?: ClassFragmentWithGrade
  studentLink?: (classId: string | undefined, studentId: string) => string
  loading?: boolean
  loadingMore?: boolean
}
export function ClassGradesTableInner(props: ClassGradesTableInnerProps) {
  const { assignments: _assignments, loadingMore } = props
  const [nameFilter, setNameFilter] = React.useState<string | null>(null)
  const hasGradeColumn = !!props.cls
  const [hasDecorations] = useHeaderTagVisibility()
  const gridRef = React.useRef<Grid>(null)
  useEffectAfterMount(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      })
    }
  }, [hasDecorations])
  const { onItemsRendered, assignments } = useLazyLoadSubmissions(_assignments, {
    // If the class was not provided, we'll need to calculate the students based on the assignments.
    includeAssignedStudents: !props.cls,
    hasGradeColumn,
  })

  // If the class was not provided, we'll need to grab the students from the assignment.
  let students: StudentEdge[]
  if (props.cls) {
    students =
      props.cls.students?.edges.map(edge => ({
        ...edge,
        classId: props.cls?.id,
      })) || []
  } else {
    const addedStudentClassIds = new Set<string>()
    students = assignments.reduce<StudentEdge[]>((acc, assignment) => {
      const toAdd: StudentEdge[] = []
      for (const studentEdge of assignment.students?.edges || []) {
        const studentClassId = [studentEdge.node.id, studentEdge.classId].join(':')
        if (!addedStudentClassIds.has(studentClassId)) {
          addedStudentClassIds.add(studentClassId)
          toAdd.push({
            node: studentEdge.node,
            classId: studentEdge.classId,
          })
        }
      }
      return [...acc, ...toAdd]
    }, [])
  }

  students = filterStudents(students, nameFilter, entry => {
    return entry.node
  })

  if (props.loading && !_assignments.length) {
    return <BlockSpin />
  } else if (_assignments.length) {
    return (
      <TableFilterContext.Provider value={{ name: nameFilter, setName: setNameFilter }}>
        <ClassGradesTableContext.Provider
          value={{
            ...props,
            assignments,
            students,
            hasGradeColumn,
          }}
        >
          <Container className={'grade-grid-container'}>
            <AutoSizer>
              {({ width, height }) => (
                <StickyGrid
                  height={height}
                  width={width}
                  columnCount={assignments.length + getColumnOffset(hasGradeColumn)} // One for student name, one for average grade.
                  columnWidth={index => gridColumnWidth(hasGradeColumn, index)}
                  rowHeight={index => gridRowHeight(hasDecorations, index)}
                  stickColumns={hasGradeColumn ? STUCK_COLUMNS : STUCK_COLUMNS - 1}
                  rowCount={students.length + ROW_OFFSET} // One for average grade, one for assignment header.
                  stickRows={STUCK_ROWS}
                  gridRef={gridRef}
                  className={'grade-grid-outer-content'}
                  onItemsRendered={onItemsRendered}
                  itemData={{
                    hasGradeColumn,
                  }}
                >
                  {GridItem}
                </StickyGrid>
              )}
            </AutoSizer>
            <LoadingMoreContainer className={loadingMore ? 'visible' : ''}>
              <LoadingOutlined /> <span>Loading more...</span>
            </LoadingMoreContainer>
          </Container>
        </ClassGradesTableContext.Provider>
      </TableFilterContext.Provider>
    )
  } else {
    return (
      <Block marginTop={'@size-l'} style={{ width: '100%' }}>
        <Empty description={'No assignments found!'} />
      </Block>
    )
  }
}

const GridItem = React.memo((props: GridChildComponentProps) => {
  const { rowIndex, columnIndex, data } = props
  if (rowIndex === 0 && columnIndex === 0) {
    return (
      <ColumnHeaderCell {...props} title={<NameFilterCell />} className={'grid-border-bottom'} />
    )
  } else if (rowIndex === 0 && columnIndex === 1 && data.hasGradeColumn) {
    return (
      <ColumnHeaderCell
        {...props}
        title={'Grade'}
        className={'grid-border-right grid-border-bottom'}
      />
    )
  } else if (rowIndex === 0) {
    return <AssignmentHeaderCell {...props} />
  } else if (rowIndex === 1 && columnIndex === 0) {
    return <RowHeaderCell className={'grid-border-bottom'} {...props} title={'Class Average'} />
  } else if (rowIndex === 1 && columnIndex === 1 && data.hasGradeColumn) {
    return <ClassAverageCell {...props} />
  } else if (rowIndex === 1) {
    return <AssignmentAverageHeaderCell {...props} />
  } else if (columnIndex === 0) {
    return <StudentNameHeaderCell {...props} />
  } else if (columnIndex === 1 && data.hasGradeColumn) {
    return <StudentAverageGradeCell {...props} />
  } else {
    return <AssignmentStudentCellCell {...props} />
  }
}, areEqual)
GridItem.displayName = 'GridItem'

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  position: relative;
  .grid-border-right {
    border-right: solid 1px ${props => props.theme['@border-color-base']};
  }
  .grid-border-bottom {
    border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  }
`
const LoadingMoreContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-s']};
  left: ${props => props.theme['@size-s']};
  padding: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@gray-5']};
  transition: opacity 0.25s linear;
  font-size: ${props => props.theme['@size-m']};
  color: ${props => props.theme['@gray-1']};
  opacity: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  > * {
    margin-right: ${props => props.theme['@size-xs']};
  }
  > :last-child {
    font-size: ${props => props.theme['@font-size-sm']};
  }
  &.visible {
    opacity: 1;
  }
`
