import * as Tabs from '@radix-ui/react-tabs'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { ModalProps, Modal as _Modal } from './Modal'
import { styled, s, withSubComponents } from '../..'
import { HTMLProps } from '../../sharedTypes'
import { FocusRing } from '../FocusRing/FocusRing'
import VerticalNavigationItem, {
  SelectedStyles,
  VerticalNavigationItemProps,
} from '../Navigation/Item/Vertical'
import Text from '../Text'

export const DEFAULT_WIDTH = 450

export interface SplitModalContextValue extends Pick<SplitModalProps, 'title' | 'ariaLabel'> {
  selectedTab?: string
}
export const SplitModalContext = React.createContext<SplitModalContextValue | undefined>(undefined)
export function useSplitModalContext(): SplitModalContextValue {
  const context = React.useContext(SplitModalContext)
  if (!context) throw new Error('Cannot find SplitModalContext')
  return context
}

export interface SplitModalProps
  extends Omit<ModalProps, 'title' | 'children'>,
    Omit<Tabs.TabsProps, 'title' | 'orientation'> {
  title?: string | React.ReactNode
  /** This should only be passed if title is a React element instead of a string */
  ariaLabel?: string
  contentWidth?: number
}
export function SplitModal({
  title,
  contentWidth = 450,
  children,
  ariaLabel,
  defaultValue,
  value: _value,
  onValueChange: _onValueChange,
  dir,
  activationMode,
  ...rest
}: SplitModalProps) {
  const tabsRootProps = { defaultValue, dir, activationMode }
  const [value, _setValue] = React.useState(_value ?? defaultValue)
  const setValue = (value: string) => {
    _setValue(value)
    _onValueChange?.(value)
  }
  React.useEffect(() => {
    if (_value) setValue(_value)
  }, [_value])
  return (
    <Tabs.Root
      {...tabsRootProps}
      orientation={'vertical'}
      asChild
      value={value}
      onValueChange={setValue}
    >
      <Modal
        {...rest}
        title={typeof title === 'string' ? title : ariaLabel}
        hideTitle
        width={'auto'}
      >
        <SplitModalContext.Provider value={{ title, ariaLabel, selectedTab: value }}>
          {children}
        </SplitModalContext.Provider>
      </Modal>
    </Tabs.Root>
  )
}

function _Navigation(
  { children, ...props }: Tabs.TabsListProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { ariaLabel, title } = useSplitModalContext()
  return (
    <__Navigation {...props} ref={ref} aria-label={typeof title === 'string' ? title : ariaLabel}>
      <TitleContainer>
        {typeof title === 'string' ? (
          <Text level={'l'} weight={'bold'}>
            {title}
          </Text>
        ) : (
          title
        )}
      </TitleContainer>
      {children}
    </__Navigation>
  )
}
export const Navigation = React.forwardRef(_Navigation)

export interface NavigationItemProps
  extends Omit<VerticalNavigationItemProps, keyof HTMLProps<HTMLButtonElement> | 'as' | 'ref'>,
    Omit<Tabs.TabsTriggerProps, 'title'> {
  title: string
}
function _NavigationItem(props: NavigationItemProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  return (
    <FocusRing>
      <Trigger {...props} asChild>
        <VerticalNavigationItem {...(props as any)} as={'button'} ref={ref} />
      </Trigger>
    </FocusRing>
  )
}
export const NavigationItem = React.forwardRef(_NavigationItem)

export interface ContentProps {
  children: React.ReactElement<ContentItemProps>[]
}
export function Content({ children }: ContentProps) {
  const { selectedTab } = useSplitModalContext()
  const child = children.find(child => child.props.value === selectedTab)
  const width = child?.props.width ?? DEFAULT_WIDTH
  return (
    <__Content layout animate={{ width }} transition={{ delay: 0.1 }}>
      <AnimatePresence initial={false} exitBeforeEnter>
        {child ? React.cloneElement(child, { key: child.props.value, forceMount: true }) : null}
      </AnimatePresence>
    </__Content>
  )
}

export interface ContentItemProps extends Tabs.TabsContentProps {
  width?: number
}
function _ContentItem(
  { children, width = DEFAULT_WIDTH, style, ...rest }: ContentItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <Tabs.Content {...rest} asChild>
      <__ContentItem
        ref={ref}
        children={children}
        initial={{ opacity: 0, scale: 0.99 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.99 }}
        transition={{ duration: 0.2 }}
        style={{ width, ...style }}
      />
    </Tabs.Content>
  )
}
export const ContentItem = React.forwardRef(_ContentItem)

const Trigger = styled(Tabs.Trigger)`
  outline: none;
  border: none;
  &[data-state='active'] {
    ${SelectedStyles}
  }
`

export const Modal = styled(_Modal)`
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: auto;
`
const __Navigation = styled(Tabs.List)`
  width: 250px;
  padding: ${s.size('s')} ${s.size('xs')};
  padding-top: ${s.size('l')};
  overflow-y: auto;
  background: ${s.color('subtle')};
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column;
`
const __Content = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`
const __ContentItem = styled(motion.div)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: ${s.size('m')};
  padding-top: calc(${s.size('m')} + ${s.size('l')});
  overflow-y: auto;
  transition: width 0s linear 0.2s;
`
const Footer = styled.div`
  position: sticky;
  bottom: 0;
  padding-top: ${s.size('m')};
  background: ${s.color('background')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${s.size('s')};
`
const TitleContainer = styled.div`
  &:empty {
    display: none;
  }
  padding: ${s.size('s')};
  padding-top: ${s.size('m')};
`

export default withSubComponents(SplitModal, {
  Navigation: withSubComponents(Navigation, {
    Item: withSubComponents(NavigationItem, {
      Label: VerticalNavigationItem.Label,
    }),
  }),
  Content: withSubComponents(Content, {
    Item: ContentItem,
    Footer,
  }),
})
