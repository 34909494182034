import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dropdown, Form, Modal, VSpaced } from '@thesisedu/ui'
import { Check, Settings } from '@thesisedu/ui/icons'
import React from 'react'

import { ImageAttributionSettings } from './attribution'
import { $isImageNode, ImageAttribution, ImageNode } from '../../../nodes/Image/ImageNode'
import { useSettingsDropdownItem } from '../../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../../ui/SettingsDropdown/useNodeState'

export interface ImageNodeSettingsProps {
  node: ImageNode
  onClose: () => void
}
export function ImageNodeSettings({ node, onClose }: ImageNodeSettingsProps) {
  const [editor] = useLexicalComposerContext()
  const [alt, setAlt] = useNodeState(
    editor,
    node,
    node => node.getAltText(),
    (node, value) => node.setAltText(value),
  )
  const [attribution, setAttribution] = useNodeState(
    editor,
    node,
    node => node.getAttribution(),
    (node, value) => node.setAttribution(value),
  )
  const form = Form.useForm<{ alt?: string; attribution?: ImageAttribution | null }>()
  return (
    <Form.Modal
      title={'Image Settings'}
      form={form}
      visible
      onVisibleChange={visible => {
        if (!visible) {
          onClose()
        }
      }}
      defaultValue={{ alt, attribution }}
      onFinish={values => {
        setAlt(values.alt)
        setAttribution(values.attribution)
      }}
    >
      <VSpaced space={'m'}>
        <Form.Field aria-label={'Attribution Settings'} name={'attribution'}>
          <ImageAttributionSettings />
        </Form.Field>
        <Form.TextField
          name={'alt'}
          label={'Alt Text'}
          description={
            'Recommended. Read aloud on screen readers when they come across this image.'
          }
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Check />} children={'Save Settings'} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export function useOtherImageSettings() {
  const [visibleNode, setVisibleNode] = React.useState<ImageNode | undefined>(undefined)
  useSettingsDropdownItem({
    identifier: 'image-other',
    group: 'image',
    weight: -7,
    filter(editor, node) {
      return $isImageNode(node)
    },
    element: ({ node }) => (
      <Dropdown.Item onClick={() => setVisibleNode(node)} icon={<Settings />}>
        Image Settings...
      </Dropdown.Item>
    ),
  })

  return visibleNode ? (
    <ImageNodeSettings node={visibleNode} onClose={() => setVisibleNode(undefined)} />
  ) : null
}
