import {
  ListItemLeft,
  ListItemRight,
  Body,
  ClickableContainer,
  ListItem,
  HSpaced,
} from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentSubmissionGridItemProps } from './AssignmentSubmissionGridItem'

export interface AssignmentSubmissionListItemProps extends AssignmentSubmissionGridItemProps {
  onClick?: () => void
}
export function AssignmentSubmissionListItem({
  index,
  item,
  onClick,
}: AssignmentSubmissionListItemProps) {
  return (
    <ClickableContainer onClick={onClick}>
      <ListItem>
        <ListItemLeft>
          <Body>Attempt {index}</Body>
        </ListItemLeft>
        <ListItemRight as={HSpaced} space={'@size-xs'}>
          {item.submittedAt ? <Body color={'@blue'}>Submitted</Body> : null}
          <Body color={'@text-color-secondary'} useDiv>
            <NavArrowRight />
          </Body>
        </ListItemRight>
      </ListItem>
    </ClickableContainer>
  )
}
