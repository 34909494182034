import { DeepLinkProps } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

export function SegmentDeepLinkRemapper({ params, resolved }: DeepLinkProps) {
  const viewer = useViewerContext(false)
  React.useEffect(() => {
    if (viewer?.group && params.classId && params.id) {
      const coursePath = viewer?.group === 'TEACHER' ? '/course/outline' : '/course'
      resolved({
        type: 'Found',
        resolvedPath: `/${viewer.group.toLowerCase()}/classes/${
          params.classId
        }${coursePath}?viewSegment=${params.id}`,
      })
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [viewer, params.classId, params.id])

  return null
}
