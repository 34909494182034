import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { CourseViewSegmentsTable } from './CourseViewSegmentsTable'
import {
  CourseViewSegmentsDocument,
  CourseViewSegmentsQuery,
  CourseViewSegmentsQueryVariables,
  CourseViewSegmentFragment,
} from '../schema'

export interface CourseViewProps {
  courseViewId: string
}
export function CourseView({ courseViewId }: CourseViewProps) {
  return (
    <InfiniteQuery<
      CourseViewSegmentFragment,
      CourseViewSegmentsQuery,
      CourseViewSegmentsQueryVariables
    >
      document={CourseViewSegmentsDocument}
      resultPath={'node.segments'}
      variables={{ courseViewId }}
      children={({ data }) => {
        const segments =
          data?.node?.__typename === 'CourseView'
            ? data.node.segments.edges.map(edge => edge.node)
            : []
        return <CourseViewSegmentsTable courseViewId={courseViewId} segments={segments} />
      }}
    />
  )
}
