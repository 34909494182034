import React from 'react'

import { useStatePropFallback } from './useStatePropFallback'
import { useOverlayContext } from '../context/OverlayContext'

export const PRESET_WEIGHTS = {
  tooltip: 0,
  popover: 1,
  dropdown: 1,
}
export interface UseOverlayOpts {
  open?: boolean
  onOpenChange?: (open: boolean) => void
  weight: keyof typeof PRESET_WEIGHTS | number
}
/**
 * This helper hook ensures there is only one overlay visible at a time, within
 * the scope of an OverlayContext. Overlays are configured with a weight, with
 * some preset options available. The higher the weight, the more preference it
 * has.
 *
 * For example, if a tooltip is open, but then you want to open a dropdown, the
 * tooltip will close and the dropdown will open because it has a higher weight.
 *
 * If you have a dropdown open and a tooltip wants to open, the tooltip won't
 * open because the dropdown has a higher weight and therefore takes preference.
 */
export function useOverlay({
  open: _open,
  onOpenChange,
  weight: _weight,
}: UseOverlayOpts): [boolean, (open: boolean) => void] {
  const [open, _setOpen] = useStatePropFallback(_open, onOpenChange, false)
  const { overlayRef } = useOverlayContext(false) || {}
  // If this changes on each render, our conditional checking it below won't work and we
  // won't end up clearing the overlay context properly.
  const onClose = React.useCallback(() => _setOpen(false), [])
  const weight = typeof _weight === 'string' ? PRESET_WEIGHTS[_weight] : _weight
  return [
    open,
    React.useCallback(
      open => {
        if (open) {
          if (!overlayRef?.current || overlayRef?.current?.weight <= weight) {
            if (overlayRef?.current) {
              overlayRef?.current.onClose()
            }
            if (overlayRef) {
              overlayRef.current = { weight, onClose }
            }
            _setOpen(true)
          }
        } else {
          if (overlayRef?.current?.onClose === onClose) {
            overlayRef.current = null
          }
          _setOpen(false)
        }
      },
      [_setOpen],
    ),
  ]
}
