import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { useClassConfiguration } from '@thesisedu/feature-classes-react'
import { useFeature } from '@thesisedu/feature-react'
import { TuneMeter, TuneItem } from '@thesisedu/feature-sheet-music-core'
import { BodyLarge, styled, useOnScreen, Fullscreen, FullscreenMode } from '@thesisedu/web'
import { renderAbc } from 'abcjs'
import { Button, ConfigProvider } from 'antd'
import { transparentize } from 'polished'
import React from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { BPMField } from './BPMField'
import { DragLayer } from './DragLayer'
import { MeterField } from './MeterField'
import { ScrollContextProvider } from './ScrollContext'
import { SimpleSheetMusicEditorContext } from './SimpleContext'
import { WrapNote } from './WrapNote'
import { SheetMusicReactFeature } from '../SheetMusicReactFeature'
import { debug } from '../log'
import { USE_ADVANCED_MODE_FIELD } from '../resources/classConfiguration'
import { DEFAULT_BPM } from '../types'
import { useInstruments } from '../useInstruments'
import { SheetMusicViewer, SheetMusicViewerProps } from '../viewer/SheetMusicViewer'

function normalizeLinesBars(value: string): string {
  let newValue = value

  const headerContents = newValue
    .split('\n')
    .filter(l => l[1] === ':')
    .join('\n')
  const numHeaderLines = headerContents.split('\n').length

  const normalizedBody = newValue
    .replace(`${headerContents}\n`, '')
    .replace(/\n/g, '')
    .split('|')
    .reduce<string>((acc, bar, index) => {
      return `${acc}${bar}|${index % 2 === 1 ? '\n' : ''}`
    }, '')
  newValue = `${headerContents}\n${normalizedBody}`

  // Remove empty lines...
  newValue = newValue
    .split('\n')
    .filter(line => !line.match(/^[ |]+$/))
    .join('\n')

  // Top off the last line if we're not yet at 2.
  let lines = newValue.split('\n').slice(numHeaderLines)
  let lastLine = lines[lines.length - 1]
  if (lastLine) {
    if (lastLine.split('|').length === 2) {
      newValue += ' |'
    }

    // Add a new line if we're full.
    lines = newValue.split('\n').slice(numHeaderLines)
    lastLine = lines[lines.length - 1]
    if (!lastLine.replace(/ /g, '').includes('||')) {
      newValue += '\n| |'
    }
  } else {
    // There are no lines; let's add them.
    newValue += '\n | |'
  }

  // Remove duplicate newlines...
  newValue = newValue.replace(/\n\n/g, '\n')

  return newValue
}

const pitchLetters = ['G,', 'A,', 'B,', ...'CDEFGABcdefgabc'.split('')]
const TIME_SIGNATURE_REGEX = /M:\d+\/\d+\n/
const FULLSCREEN_WIDTH_TRIGGER = 800

export interface SheetMusicEditorState {
  advancedMode: boolean
  selectedInstrument?: string
  bpm: number
}
export interface SimpleSheetMusicEditorProps {
  value?: string
  onChange?: (value: string, state: SheetMusicEditorState) => void
  viewerProps?: Partial<SheetMusicViewerProps>
  topControls?: React.ReactElement
  hideLeftControls?: boolean
  useAdvancedMode?: boolean | null
  defaultInstrument?: string
  defaultMeter?: TuneMeter
  defaultBpm?: number
  readOnlyMeter?: boolean
  readOnlyBpm?: boolean
  fullscreenHeader?: React.ReactElement
}
export function SimpleSheetMusicEditor({
  value: _value,
  onChange: _onChange,
  viewerProps,
  topControls,
  hideLeftControls,
  useAdvancedMode,
  defaultInstrument: _defaultInstrument,
  defaultMeter = { num: 4, den: 4 },
  defaultBpm = DEFAULT_BPM,
  readOnlyMeter,
  readOnlyBpm,
  fullscreenHeader,
}: SimpleSheetMusicEditorProps) {
  const { width, ref } = useResizeDetector()
  const [isFullscreen, setIsFullscreen] = React.useState(false)
  const containerRef = ref
  const _showFullscreenOverlay = width && width <= FULLSCREEN_WIDTH_TRIGGER
  const wasFullscreenOverlayRef = React.useRef(_showFullscreenOverlay)
  React.useEffect(() => {
    if (_showFullscreenOverlay) {
      wasFullscreenOverlayRef.current = true
    }
  }, [_showFullscreenOverlay])
  const showFullscreenOverlay = wasFullscreenOverlayRef.current
  const onScreen = useOnScreen(containerRef)
  const advancedModeConfig = useClassConfiguration<boolean>(USE_ADVANCED_MODE_FIELD)
  const { keyboard: KeyboardComponent } = useFeature(SheetMusicReactFeature)
  const [timeSignature, setTimeSignature] = React.useState<TuneMeter>(defaultMeter)
  const [bpm, setBpm] = React.useState(defaultBpm)
  const value =
    _value || `L:1/8\n${defaultMeter ? `M:${defaultMeter.num}/${defaultMeter.den}\n` : ''}| |`
  const valueRef = React.useRef<string>(value)
  React.useEffect(() => {
    valueRef.current = value
  }, [value])
  const onChange: typeof _onChange = _onChange
    ? (value, state) => {
        valueRef.current = value
        _onChange(value, state)
      }
    : undefined
  const [isAdvancedMode, setIsAdvancedMode] = React.useState(
    useAdvancedMode !== undefined && useAdvancedMode !== null
      ? useAdvancedMode
      : !!advancedModeConfig,
  )
  const [isShift, setIsShift] = React.useState(false)
  const instruments = useInstruments({
    abc: value,
    simpleMode: !isAdvancedMode,
  })
  const defaultInstrument =
    _defaultInstrument && instruments.some(i => i.identifier === _defaultInstrument)
      ? _defaultInstrument
      : instruments[0]?.identifier
  const [selectedInstrument, setSelectedInstrument] = React.useState(defaultInstrument)
  // When the list of instruments changes and the selected instrument is invalid, reset it.
  React.useEffect(() => {
    if (!instruments.some(i => i.identifier === selectedInstrument)) {
      debug('resetting instrument inside SimpleSheetMusicEditor')
      setSelectedInstrument(instruments[0]?.identifier)
    }
  }, [instruments.length, selectedInstrument])
  const isShiftRef = React.useRef(isShift)
  React.useEffect(() => {
    const listener = (e: WindowEventMap['drag']) => {
      setIsShift(e.shiftKey)
      isShiftRef.current = e.shiftKey
    }
    window.addEventListener('drag', listener)
    return () => window.removeEventListener('drag', listener)
  }, [])

  // Handle setting time signature.
  const state = {
    advancedMode: isAdvancedMode,
    selectedInstrument,
    bpm,
  }
  React.useEffect(() => {
    try {
      const rendered = renderAbc('*', value) as TuneItem[]
      if (
        rendered[0]?.meter &&
        (rendered[0].meter.num !== timeSignature.num || rendered[0].meter.den !== timeSignature.den)
      ) {
        setTimeSignature(rendered[0].meter)
      }
    } catch {
      // Do nothing...
    }
  }, [value])
  React.useEffect(() => {
    if (onChange) {
      if (TIME_SIGNATURE_REGEX.test(value)) {
        onChange(
          value.replace(TIME_SIGNATURE_REGEX, `M:${timeSignature.num}/${timeSignature.den}\n`),
          state,
        )
      } else {
        onChange(`M:${timeSignature.num}/${timeSignature.den}\n${value}`, state)
      }
    }
  }, [timeSignature])

  return (
    <Fullscreen
      fullscreen={isFullscreen ? FullscreenMode.Inline : false}
      onChange={f => setIsFullscreen(!!f)}
    >
      <FullScreenContainer
        enabled={isFullscreen}
        ref={containerRef}
        style={{ position: 'relative' }}
      >
        <ConfigProvider getPopupContainer={() => containerRef.current}>
          {showFullscreenOverlay && !isFullscreen ? (
            <FullscreenMessageContainer onClick={() => setIsFullscreen(true)}>
              <BodyLarge color={'@white'}>
                Click here to edit this sheet music in fullscreen mode.
              </BodyLarge>
            </FullscreenMessageContainer>
          ) : null}
          {isFullscreen && fullscreenHeader ? (
            <FullscreenHeaderContainer>{fullscreenHeader}</FullscreenHeaderContainer>
          ) : null}
          <ScrollContextProvider containerRef={containerRef}>
            <SimpleSheetMusicEditorContext.Provider
              value={{
                isShift,
                useAdvancedControls: isAdvancedMode,
                onRemove: (after, length) => {
                  if (onChange && valueRef.current) {
                    const value = valueRef.current // To this scope to make things easy.

                    // If the range starts with a space, don't remove the space.
                    let realAfter = after
                    let realLength = length
                    if (value.substring(after, after + 1) === ' ') {
                      realAfter++
                      realLength--
                    }

                    const newValue =
                      value.substring(0, realAfter) + value.substring(realAfter + realLength)
                    onChange(normalizeLinesBars(newValue), state)
                  }
                },
                onDrop: (duration, pitch, accidental, startChar, replace, maxAvailableDuration) => {
                  if (onChange && valueRef.current) {
                    const value = valueRef.current // To this scope to make things easy.
                    const durations = Array.isArray(duration) ? duration : [duration]
                    const pitches = Array.isArray(pitch) ? pitch : [pitch]
                    const accidentals = Array.isArray(accidental) ? accidental : [accidental]
                    let newValue = value
                    let realStartChar = startChar
                    let realReplace = replace
                    for (let i = 0; i < durations.length; i++) {
                      // Only dot if we have enough space for one.
                      const duration =
                        isShiftRef.current &&
                        durations[i] !== undefined &&
                        maxAvailableDuration &&
                        durations[i]! * 1.5 <= maxAvailableDuration
                          ? durations[i]! * 1.5
                          : durations[i]
                      const pitch = pitches[i]
                      const accidental = accidentals[i]
                      let toAdd
                      if (duration === undefined) {
                        toAdd = '|'
                      } else {
                        const durationStrPart = duration * 8 // Because 1/8 time.
                        let durationStr = durationStrPart > 1 ? durationStrPart.toString() : ''
                        if (durationStrPart === 1.5) durationStr = '3/2' // For dotted eighth.
                        const accidentalStr =
                          accidental === 'sharp' ? '^' : accidental === 'flat' ? '_' : '='
                        toAdd =
                          accidentalStr +
                          (pitch === undefined ? 'x' : pitchLetters[pitch + 3]) +
                          durationStr
                      }
                      newValue =
                        newValue.substring(0, realStartChar) +
                        toAdd +
                        newValue.substring(
                          realReplace ? realStartChar + realReplace : realStartChar,
                        )

                      // Normalize the lines and bars...
                      newValue = normalizeLinesBars(newValue)

                      realStartChar += toAdd.length
                      realReplace = realReplace
                        ? Math.max(0, realReplace - toAdd.length)
                        : realReplace
                    }

                    // Save the value.
                    onChange(newValue, state)
                  }
                },
              }}
            >
              {topControls}
              <ContentContainer className={'sheet-music-editor'}>
                <SheetMusicViewer
                  {...viewerProps}
                  abc={value}
                  showSolfege
                  solfegeOffset={0}
                  actions={
                    <>
                      <Button
                        icon={isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                        onClick={() => setIsFullscreen(f => !f)}
                      />
                      {viewerProps?.actions}
                    </>
                  }
                  playbackContainerProps={{
                    ...viewerProps?.playbackContainerProps,
                    selectedInstrumentKey: selectedInstrument,
                    onInstrumentSelected: setSelectedInstrument,
                    bpm,
                    instruments,
                    additionalOptions: (
                      <>
                        {readOnlyMeter ? null : (
                          <MeterField timeSignature={timeSignature} onChange={setTimeSignature} />
                        )}
                        {readOnlyBpm ? null : <BPMField bpm={bpm} onChange={setBpm} />}
                      </>
                    ),
                  }}
                />
                <DragLayer />
                {hideLeftControls || (showFullscreenOverlay && !isFullscreen) ? null : onScreen ||
                  isFullscreen ? (
                  <ControlsContainer className={'sheet-music-controls'}>
                    <BorderContainer />
                    <ControlsContentContainer>
                      <KeyboardComponent
                        simple={!isAdvancedMode}
                        wrapNote={WrapNote}
                        selectedInstrument={selectedInstrument}
                        onInstrumentChange={setSelectedInstrument}
                        onSimpleChange={simple => {
                          setIsAdvancedMode(!simple)
                        }}
                      />
                    </ControlsContentContainer>
                  </ControlsContainer>
                ) : (
                  <ControlsPlaceholder />
                )}
              </ContentContainer>
            </SimpleSheetMusicEditorContext.Provider>
          </ScrollContextProvider>
        </ConfigProvider>
      </FullScreenContainer>
    </Fullscreen>
  )
}

const MAX_WIDTH = 1400
const ContentContainer = styled.div`
  flex: 1;
  padding-top: ${props => props.theme['@size-l']};
  min-width: ${FULLSCREEN_WIDTH_TRIGGER}px;
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
`
const FullscreenMessageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => transparentize(0.5, props.theme['@gray-6'])};
  margin: 0 -${props => props.theme['@size-s']};
  padding: ${props => props.theme['@padding-lg']};
  padding-top: calc(${props => props.theme['@size-xxl']} * 2);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.25s linear;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
  > p {
    display: block;
    background: ${props => props.theme['@gray-6']};
    border-radius: ${props => props.theme['@border-radius-base']};
    padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-m']};
    max-width: 75%;
    text-align: center;
  }
`
const PLACEHOLDER_HEIGHT = 235
const FullScreenContainer = styled.div<{ enabled?: boolean }>`
  ${props => (props.enabled ? '&' : '&.noop')} {
    background: white;
    padding: ${props => props.theme['@padding-lg']};
    position: absolute;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--app-height);
    > div > .sheet-music-viewer {
      padding-bottom: ${PLACEHOLDER_HEIGHT}px;
    }
    > div > .sheet-music-controls {
      position: fixed !important;
      left: 0;
      right: 0;
      > div:last-child {
        max-width: ${MAX_WIDTH}px;
        margin: 0 auto;
      }
    }
  }
`
const ControlsContainer = styled.div`
  position: sticky;
  bottom: 0;
  margin: 0 -${props => props.theme['@size-l']};
  z-index: 9;
`
const ControlsPlaceholder = styled.div`
  height: ${PLACEHOLDER_HEIGHT}px;
`
const BorderContainer = styled.div`
  height: ${props => props.theme['@size-xs']};
  background: linear-gradient(
    ${props => transparentize(1, props.theme['@gray-2'])},
    ${props => transparentize(0.5, props.theme['@gray-2'])}
  );
  border-bottom: solid 1px ${props => props.theme['@gray-1']};
  width: 100%;
  position: relative;
  z-index: 9;
`
const ControlsContentContainer = styled.div`
  padding: ${props => props.theme['@size-m']} ${props => props.theme['@size-l']};
  background: white;
`
const FullscreenHeaderContainer = styled.div`
  margin: 0 auto ${props => props.theme['@size-m']} auto;
  text-align: center;
`
