import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import MediaReactFeature from '../../MediaReactFeature'
import { RecordFooterCell } from '../../record/states/record/footer/RecordFooterCell'
import { PitchTool } from '../../record/toolbox/PitchTool'
import { FooterItem } from '../api/FooterItem'

export function PitchPlugin() {
  const enabled = useFeature(MediaReactFeature).options.enablePitchTool
  if (!enabled) return null
  return (
    <FooterItem weight={11}>
      <RecordFooterCell>
        <PitchTool />
      </RecordFooterCell>
    </FooterItem>
  )
}
