import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd'
import React from 'react'

import { Cancel, Check } from '../../icons'
import { useTheme } from '../theme'

export interface SwitchProps extends AntSwitchProps {
  color?: string
}
export function Switch({ color, ...props }: SwitchProps) {
  const theme = useTheme()
  return (
    <AntSwitch
      checkedChildren={<Check width={'1.25em'} height={'1.25em'} />}
      unCheckedChildren={<Cancel width={'1.25em'} height={'1.25em'} />}
      {...props}
      style={{
        ...props.style,
        backgroundColor: props.checked ? (color ? theme[color] : theme['@green']) : theme['@red'],
      }}
    />
  )
}
