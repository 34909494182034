import React from 'react'

import { BodySmall, LoadingIndicator, PulseIndicator } from '../common'
import { styled } from '../styled'
import { color, size } from '../theme'

export interface NavigationListPulseHeaderProps {
  children: string | React.ReactElement
  loading?: boolean
}
export function NavigationListPulseHeader({ children, loading }: NavigationListPulseHeaderProps) {
  return (
    <Container
      useDiv
      color={loading ? '@text-color-secondary' : '@primary-color'}
      className={loading ? 'loading' : undefined}
    >
      {loading ? <LoadingIndicator /> : <PulseIndicator size={'@size-xs'} />}
      {children}
    </Container>
  )
}

const Container = styled(BodySmall)`
  background: ${color('@blue-light')};
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${size('@size-xs')};
  padding: 0 ${size('@size-xs')};
  &.loading {
    background: ${color('@gray-1')};
  }
`
