import { styled } from '@thesisedu/web'
import React from 'react'

import { OutlineItem, OutlineItemType } from '../types'

export type PlaceholderMap = {
  [key in OutlineItemType]: React.FC<React.PropsWithChildren<OutlineRowPlaceholderProps>>
}

export const OUTLINE_PLACEHOLDERS: PlaceholderMap = {
  [OutlineItemType.HEADER]: ({ style }) => (
    <PlaceholderContainer style={style}>
      <div className={'name'} />
      <div className={'actions'} />
    </PlaceholderContainer>
  ),
  [OutlineItemType.SEGMENT]: ({ style }) => (
    <PlaceholderContainer style={style}>
      <div className={'icon'} />
      <div className={'name'} />
      <div className={'actions'} />
    </PlaceholderContainer>
  ),
}

export interface OutlineRowPlaceholderProps {
  style: any
  item: OutlineItem
}
export function OutlineRowPlaceholder(props: OutlineRowPlaceholderProps) {
  const Component = OUTLINE_PLACEHOLDERS[props.item.type]
  return <Component {...props} />
}

const PlaceholderContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    background: ${props => props.theme['@gray-1']};
    border-radius: ${props => props.theme['@border-radius-base']};
    margin-right: ${props => props.theme['@size-xs']};
  }
  > div.actions {
    width: ${props => props.theme['@size-xs']};
    height: ${props => props.theme['@size-m']};
    margin-left: auto;
    margin-right: ${props => props.theme['@size-s']};
  }
  > div.name {
    width: 80%;
    height: ${props => props.theme['@size-m']};
  }
  > div.icon {
    width: ${props => props.theme['@size-l']};
    height: ${props => props.theme['@size-l']};
    border-radius: 50%;
  }
`
