import { Overrides, applyOverrides } from './applyOverrides'
import {
  CourseConfiguration,
  Segment,
  SegmentMetadataSegment,
  getSegmentParent,
  getSegmentParents,
} from './courseConfiguration'
import { debug, warn } from './log'

export interface CleanOverridesResult {
  wasChanged: boolean
  overrides: Overrides
}
export interface CleanOverridesOpts {
  /**
   * These Segment IDs will be ignored when cleaning segment metadata, as in
   * they will never be removed if they exist inside segment metadata. This
   * is used inside the split view feature for manually-created groups and
   * topics so they preserve their weight.
   */
  ignoreMetadataSegmentIds?: string[]
}
export function cleanOverrides(
  originalConfiguration: CourseConfiguration,
  { metadataOverrides, structureOverrides }: Overrides,
  builtForeignSegments: Record<string, Segment>,
  { ignoreMetadataSegmentIds }: CleanOverridesOpts = {},
): CleanOverridesResult {
  debug('cleaning structure overrides')
  let wasChanged = false
  const segmentIds = [
    ...new Set([...metadataOverrides.map(o => o.id), ...structureOverrides.map(o => o.id)]),
  ].filter(segmentId => !ignoreMetadataSegmentIds?.includes(segmentId))

  const newStructureOverrides = [...structureOverrides]
  let newMetadataOverrides = [...metadataOverrides]
  for (const segmentId of segmentIds) {
    const structureOverrideIndex = newStructureOverrides.findIndex(i => i.id === segmentId)
    const structureOverride = structureOverrides[structureOverrideIndex]

    if (structureOverride) {
      // If the element has the same parent as before, we can remove it.
      const originalParents = getSegmentParents(
        originalConfiguration.segments,
        structureOverride.id,
      )
      const originalParent = originalParents
        ? originalParents[originalParents.length - 1]
        : undefined
      if (originalParent === structureOverride.parentId) {
        debug('override %s has same parent as before; removing', structureOverride.id)
        wasChanged = true
        newStructureOverrides.splice(structureOverrideIndex, 1)
      }

      // This might come back to bite me later. We had a check here for "If any of the
      // element's parents are a structureOverride, we can remove it." but I can't think
      // of a case where we would want to do that.
    }
  }

  debug('modifying configuration with new structure overrides')
  const modifiedConfigurationStructureOnly = applyOverrides(originalConfiguration, {
    builtForeignSegments,
    metadataOverrides: [],
    structureOverrides: newStructureOverrides,
  })
  const modifiedConfigurationBoth = applyOverrides(originalConfiguration, {
    builtForeignSegments,
    metadataOverrides,
    structureOverrides: newStructureOverrides,
  })

  // Process metadata overrides _after_ all structure overrides.
  for (const segmentId of segmentIds) {
    const metadataOverrideIndex = newMetadataOverrides.findIndex(i => i.id === segmentId)
    const metadataOverride = metadataOverrides[metadataOverrideIndex]

    if (metadataOverride?.weight != null) {
      const originalParent = getSegmentParent(
        modifiedConfigurationStructureOnly.segments,
        metadataOverride.id,
      )
      const modifiedParent = getSegmentParent(
        modifiedConfigurationBoth.segments,
        metadataOverride.id,
      )
      const originalChildren = originalParent?.childSegments ?? []
      const filteredModifiedChildren = modifiedParent?.childSegments ?? []
      const filteredOriginalChildren = originalChildren
      const filteredSegmentIds = filteredOriginalChildren.map(s => s.id)
      if (filteredOriginalChildren.length !== filteredModifiedChildren.length) {
        warn({ filteredOriginalChildren, filteredModifiedChildren })
        throw new Error(
          'filteredOriginalChildren and filteredModifiedChildren are not the same length; this should never happen',
        )
      }
      let isSameOrder = true
      for (let i = 0; i < filteredOriginalChildren.length; i++) {
        if (filteredOriginalChildren[i].id !== filteredModifiedChildren[i].id) {
          isSameOrder = false
          break
        }
      }

      if (isSameOrder) {
        debug(
          'override %s has same order as before; checking for sibling overrides',
          metadataOverride.id,
        )
        wasChanged = true
        newMetadataOverrides = newMetadataOverrides
          .map<SegmentMetadataSegment | null>(override => {
            if (filteredSegmentIds.includes(override.id)) {
              const { weight, ...restOverride } = override
              if (Object.keys(restOverride).length <= 1) {
                debug(
                  '...override %s has no other properties after removing weight; removing',
                  override.id,
                )
                return null
              }
              return restOverride
            } else {
              return override
            }
          })
          .filter(Boolean) as SegmentMetadataSegment[]
      }
    }
  }

  return {
    wasChanged,
    overrides: {
      metadataOverrides: newMetadataOverrides,
      structureOverrides: newStructureOverrides,
    },
  }
}
