import { PageHead } from '@thesisedu/react'
import { Block, styled } from '@thesisedu/web'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { SearchField } from './SearchField'
import { SearchList } from './results/SearchList'

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
`

export const SearchPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [query, setQuery] = React.useState<string | null>()
  const location = useLocation()
  const navigate = useNavigate()
  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('query') && params.get('query') !== query) {
      setQuery(params.get('query'))
    }
  }, [location])
  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (query && params.get('query') !== query) {
      navigate(`${location.pathname}?${new URLSearchParams({ query }).toString()}`)
    }
  }, [query])
  return (
    <Container>
      <PageHead title={'Search'} />
      <Block marginBottom={'@size-xl'}>
        <SearchField onChange={setQuery} value={query || undefined} />
      </Block>
      <div>{query ? <SearchList query={query || undefined} /> : null}</div>
    </Container>
  )
}
