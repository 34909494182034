import {
  AssignmentSubmissionsGrid,
  AssignmentTimer,
  SubmissionsGridAssignment,
  useCreateNewSubmission,
} from '@thesisedu/feature-assignments-react'
import { useSegmentNavigationContext } from '@thesisedu/feature-courses-react'
import { Block, HSpaced, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

export interface AssignmentManageAttemptsProps {
  classId: string
  assignment: SubmissionsGridAssignment
}
export function AssignmentManageAttempts({ assignment, classId }: AssignmentManageAttemptsProps) {
  const { setSelectedNavigationItemId } = useSegmentNavigationContext(true)
  const [create] = useCreateNewSubmission({
    onCreated(submission) {
      setSelectedNavigationItemId(submission.id)
    },
    assignmentId: assignment.id,
    classId,
  })
  return (
    <VSpaced space={'m'}>
      <HSpaced space={'m'}>
        <div>
          <Text weight={'bold'}>
            You are allowed to create multiple attempts and submit the best one.
          </Text>
          <Text level={'s'} color={'secondary'}>
            Click on the Create Attempt button to create new attempts. Click on the dropdown next to
            an attempt you want to submit, and then click the submit option.
          </Text>
        </div>
        <Block left style={{ textAlign: 'right' }}>
          <AssignmentTimer assignmentId={assignment.id} classId={classId}>
            <Text color={'secondary'} level={'s'}>
              Remaining Time
            </Text>
          </AssignmentTimer>
        </Block>
      </HSpaced>
      <AssignmentSubmissionsGrid
        assignment={assignment}
        classId={classId}
        onCreateClick={create}
        onClick={submission => {
          setSelectedNavigationItemId(submission.id)
        }}
      />
    </VSpaced>
  )
}
