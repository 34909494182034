import { Button, Button$, Form, Modal, VSpaced } from '@thesisedu/ui'
import { Plus, Star } from '@thesisedu/ui/icons'
import React from 'react'

import { useBookmarksContext } from './BookmarksContext'
import { MediaBookmarkFragment } from '../../schema'
import { useUpdateMedia } from '../api/useUpdateMedia'

export interface BookmarkButtonProps extends Button$.ButtonProps {
  getCurrentTimeSeconds: () => number | null
  onPause: () => void
  onResume: () => void
  activeBookmark?: MediaBookmarkFragment
}
export function BookmarkButton({
  getCurrentTimeSeconds,
  onPause,
  onResume,
  activeBookmark,
  ...buttonProps
}: BookmarkButtonProps) {
  const { draftBookmark, setDraftBookmark } = useBookmarksContext(true)
  const [updateMedia, { loading }] = useUpdateMedia()
  const [formVisible, setFormVisible] = React.useState(false)
  const form = Form.useForm<{ message: string }>()
  return (
    <>
      <Form.Modal
        title={'Create Bookmark'}
        form={form}
        visible={formVisible}
        onVisibleChange={v => {
          setFormVisible(v)
          if (!v) {
            form.reset()
            setDraftBookmark(undefined)
          }
        }}
        onFinish={async values => {
          if (draftBookmark?.endSeconds != null && draftBookmark?.startSeconds != null) {
            const bookmarkToSave: MediaBookmarkFragment = {
              startSeconds: draftBookmark.startSeconds,
              endSeconds: draftBookmark.endSeconds,
              name: values.message,
            }
            await updateMedia(media => ({
              metadata: {
                ...media.metadata,
                bookmarks: [...(media.metadata?.bookmarks || []), bookmarkToSave],
              },
            }))
            setDraftBookmark(undefined)
            setFormVisible(false)
            onResume()
          }
        }}
      >
        <VSpaced space={'m'}>
          <Form.TextAreaField
            name={'message'}
            aria-label={'Message'}
            placeholder={'Add a description for your bookmark.'}
            required
          />
          <Modal.Footer>
            <Modal.CloseButton />
            <Form.Submit children={'Create Bookmark'} icon={<Plus />} loading={loading} />
          </Modal.Footer>
        </VSpaced>
      </Form.Modal>
      <Button
        children={<Star />}
        loading={loading}
        {...buttonProps}
        onPress={() => {
          if (activeBookmark) {
            updateMedia(media => ({
              metadata: {
                ...media.metadata,
                bookmarks: (media.metadata?.bookmarks ?? []).filter(
                  bookmark => bookmark.startSeconds !== activeBookmark.startSeconds,
                ),
              },
            }))
          }
          const currentSeconds = getCurrentTimeSeconds()
          if (currentSeconds == null) return
          if (draftBookmark?.startSeconds && currentSeconds > draftBookmark.startSeconds) {
            onPause()
            setDraftBookmark(draft => ({
              ...draft,
              endSeconds: currentSeconds,
            }))
            setFormVisible(true)
          } else {
            setDraftBookmark({
              startSeconds: currentSeconds,
            })
          }
        }}
      />
    </>
  )
}
