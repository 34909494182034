import { Tabs } from 'antd'
import React from 'react'

import { useSegmentNavigationItems } from './useSegmentNavigationItems'
import { useSegmentNavigationContext } from '../contexts/SegmentNavigationContext'

export function SegmentNavigationTabs() {
  const { navigationItems, loading, error } = useSegmentNavigationItems()
  const { selectedNavigationItemId, setSelectedNavigationItemId } =
    useSegmentNavigationContext(true)

  if (error || loading) return null
  return (
    <Tabs
      size={'large'}
      activeKey={selectedNavigationItemId}
      onTabClick={key => setSelectedNavigationItemId(key.toString())}
    >
      {navigationItems.map(navigationItem => {
        const children = (
          <span>
            {navigationItem.icon}
            {navigationItem.name}
          </span>
        )
        return (
          <Tabs.TabPane
            key={navigationItem.id}
            tab={navigationItem.render ? navigationItem.render({ children }) : children}
          />
        )
      })}
    </Tabs>
  )
}
