import { DislikeOutlined } from '@ant-design/icons'
import { Modal, Body, BodyLarge, Block } from '@thesisedu/web'
import { Button, Form, Input } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { useRejectCommentMutation } from '../queries/useRejectCommentMutation'
import { AdminCommentFragment } from '../schema'

export interface RejectCommentButtonProps extends ButtonProps {
  comment: Pick<AdminCommentFragment, 'comment' | 'id' | 'user'>
}
export function RejectCommentButton({ comment, ...props }: RejectCommentButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const [reject, { loading }] = useRejectCommentMutation({
    onCompleted: () => {
      setVisible(false)
    },
  })
  const [form] = Form.useForm()
  return (
    <>
      <Modal
        title={'Reject Comment'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button type={'primary'} danger onClick={form.submit} loading={loading}>
            Reject Comment
          </Button>,
        ]}
      >
        <Block marginBottom={'@size-l'}>
          <Body isBlock>
            You are rejecting the comment from{' '}
            <strong>{comment.user.name || comment.user.username}:</strong>
          </Body>
          <BodyLarge isBlock>"{comment.comment}"</BodyLarge>
        </Block>
        <Form
          layout={'vertical'}
          form={form}
          onFinish={async ({ reason }) => {
            await reject({
              variables: {
                input: {
                  id: comment.id,
                  reason,
                },
              },
            })
          }}
        >
          <Form.Item
            name={'reason'}
            label={'What is your reason?'}
            rules={[{ required: true, message: 'A reason is required.' }]}
          >
            <Input.TextArea
              placeholder={'This comment violates our comment guidelines.'}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button
        type={'link'}
        danger
        icon={<DislikeOutlined />}
        loading={loading}
        onClick={() => setVisible(true)}
        {...props}
      />
    </>
  )
}
