import { Badge as AntBadge, BadgeProps } from 'antd'
import React from 'react'

import { styled } from '../styled'
import { useTheme } from '../theme'

export function Badge(props: React.PropsWithChildren<BadgeProps>) {
  const theme = useTheme()
  if (props.count) {
    return (
      <BadgeWrapper>
        <AntBadge
          {...props}
          style={{
            background: theme['@red'],
            ...props.style,
          }}
        />
      </BadgeWrapper>
    )
  } else {
    return <AntBadge color={props.dot ? theme['@red'] : undefined} {...props} />
  }
}

const BadgeWrapper = styled.div`
  .ant-badge-count {
    min-width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    padding: 0 4px;
    &.ant-badge-multiple-words {
      padding: 0 6px;
    }
  }
`
