import { HSpaced, Text } from '@thesisedu/ui'
import { CheckCircle, WarningCircle } from '@thesisedu/ui/icons'
import { ProgressModal } from '@thesisedu/web'
import React from 'react'

import { UseBulkActionsOpts, useBulkActions } from './useBulkActions'

export interface UseBulkActionsWithModalOpts<Variables extends object, Data extends object = object>
  extends UseBulkActionsOpts<Variables, Data> {
  /**
   * By default, we wait 2 seconds at the end for the success message to appear. Set this
   * to true to disable that behavior.
   */
  noWait?: boolean
  message: string
}
export type UseBulkActionsWithModal = [
  <Variables extends object, Data extends object = object>(
    opts: UseBulkActionsWithModalOpts<Variables, Data>,
  ) => Promise<void>,
  { modal: React.ReactElement | null; visible: boolean },
]
export function useBulkActionsWithModal(): UseBulkActionsWithModal {
  const run = useBulkActions()
  const [state, setState] = React.useState<{
    message: string | React.ReactElement
    current: number
    total: number
  } | null>(null)
  const modal = state ? (
    <ProgressModal
      total={state.total}
      current={state.current}
      buildLeftLabel={() => state.message}
      visible
    />
  ) : null
  return [
    React.useCallback(
      async ({ message, noWait, ...opts }) => {
        try {
          setState({ total: opts.variables.length, current: 0, message })
          await run({
            ...opts,
            onProgress(current, total) {
              opts.onProgress?.(current, total)
              setState({ total, current, message })
            },
          })
          setState({
            total: opts.variables.length,
            current: opts.variables.length,
            message: (
              <HSpaced space={'xs'}>
                <Text level={null} color={'green'}>
                  <CheckCircle />
                </Text>
                <Text level={null} color={'green'}>
                  Done!
                </Text>
              </HSpaced>
            ),
          })
          if (!noWait) {
            // Wait 2 seconds at the end to keep the success message on the screen.
            await new Promise(resolve => setTimeout(resolve, 2000))
          }
        } catch (err: unknown) {
          setState(state => ({
            total: state?.total ?? 1,
            current: state?.current ?? 1,
            message: (
              <HSpaced space={'xs'}>
                <Text level={null} color={'red'}>
                  <WarningCircle />
                </Text>
                <Text level={null} color={'red'}>
                  We ran into an error
                </Text>
              </HSpaced>
            ),
          }))
          // Wait 2 seconds at the end to keep the error message on the screen.
          await new Promise(resolve => setTimeout(resolve, 2000))
          throw err
        } finally {
          setState(null)
        }
      },
      [run],
    ),
    { modal, visible: !!state },
  ]
}
