import { useAssignmentViewerContext } from '@thesisedu/feature-assignments-react'
import React from 'react'

import { HIDE_KEYBOARD_FIELD } from './class_configuration/hideKeyboardField'
import { useKeyboardButtonContext } from '../components/keyboard/KeyboardButtonContext'

export function ClassAssignmentViewerExtraContent() {
  const { assignment } = useAssignmentViewerContext(true)
  const { setButtonHidden } = useKeyboardButtonContext(false) || {}
  React.useEffect(() => {
    if (assignment?.configuration?.[HIDE_KEYBOARD_FIELD] && setButtonHidden) {
      setButtonHidden(true, assignment.id)
      return () => {
        setButtonHidden(false, assignment.id)
      }
    }
  }, [assignment?.configuration?.[HIDE_KEYBOARD_FIELD]])
  return null
}
