import * as ReactDropdown from '@radix-ui/react-dropdown-menu'
import React from 'react'

import { DropdownContext } from './DropdownContext'
import { useOverlay } from '../../utils/useOverlay'

export function DropdownContainer(props: ReactDropdown.DropdownMenuProps) {
  const [open, setOpen] = useOverlay({ ...props, weight: 'dropdown' })
  const [forceMount, setForceMount] = React.useState(false)
  // Whenever forceMount turns false and we are still open, close the dropdown.
  React.useEffect(() => {
    if (open && !forceMount) {
      setOpen(false)
    }
  }, [forceMount])
  return (
    <DropdownContext.Provider value={{ forceMount, setForceMount }}>
      <ReactDropdown.Root {...props} open={open || forceMount} onOpenChange={setOpen} />
    </DropdownContext.Provider>
  )
}
