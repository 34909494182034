import { Button, ButtonProps, AntIconWrapper, DATE_FORMATS, Form } from '@thesisedu/react'
import { ShareIos } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

import { useAssignmentViewerFormContext } from './AssignmentViewerFormContext'
import { useSubmissionContext } from '../../contexts/SubmissionContext'
import { useOptions } from '../../useOptions'

export interface FormSubmitButtonProps extends Partial<ButtonProps> {}
export function FormSubmitButton({ disabled: _disabled, ...rest }: FormSubmitButtonProps) {
  const { actionsDisabled } = useAssignmentViewerFormContext(true)
  const submit = Form.useFormSubmit()
  const {
    loading,
    submission,
    submitMessage,
    submitAlternative,
    hideSubmit,
    groups: { areAnswersShared, isGroupLeader },
  } = useSubmissionContext(true)
  const { submitForGradingLabel } = useOptions()
  const disabled =
    _disabled || actionsDisabled || (areAnswersShared && !isGroupLeader) || submission?.submittedAt
  if (hideSubmit) return null
  return (
    <Button
      onClick={submit}
      loading={loading}
      children={
        submission?.submittedAt
          ? `Submitted ${moment(submission.submittedAt).format(DATE_FORMATS.FULL)}`
          : submitMessage ?? submitForGradingLabel
      }
      icon={<AntIconWrapper children={<ShareIos />} />}
      data-testid={'FormSubmitButton'}
      {...rest}
      type={submitAlternative ? 'default' : 'primary'}
      disabled={disabled}
    />
  )
}
