import { onMutationError } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper, Modal } from '@thesisedu/web'
import { Button, Form, Input, message } from 'antd'
import { Plus } from 'iconoir-react'
import React from 'react'

import { useCreateWidgetTemplateMutation, WidgetTemplateFragment } from '../schema'

export interface CreateTemplateButtonProps
  extends Omit<CreateTemplateModalProps, 'visible' | 'onCancel'> {}
export function CreateTemplateButton(rest: CreateTemplateButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <CreateTemplateModal visible={visible} onCancel={() => setVisible(false)} {...rest} />
      <Button
        type={'primary'}
        icon={<AntIconWrapper children={<Plus />} />}
        children={'Create Template'}
        onClick={() => setVisible(true)}
      />
    </>
  )
}

export interface CreateTemplateModalProps {
  visible?: boolean
  onCancel: () => void
  afterCreate: (template: WidgetTemplateFragment) => void
}
export function CreateTemplateModal({ afterCreate, visible, onCancel }: CreateTemplateModalProps) {
  const [form] = Form.useForm()
  React.useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [visible])
  const [create, { loading }] = useCreateWidgetTemplateMutation({
    onError: onMutationError('There was an error creating that template.'),
    onCompleted: data => {
      message.success('Template created successfully!')
      const template = data.createWidgetTemplate.widgetTemplate
      afterCreate(template)
      onCancel()
    },
  })

  return (
    <Modal
      title={'Create Widget Template'}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'create'} type={'primary'} onClick={form.submit} loading={loading}>
          Create
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          create({
            variables: {
              input: {
                name: values.name,
              },
            },
          })
        }}
      >
        <Form.Item
          name={'name'}
          label={'Name'}
          rules={[{ required: true, message: 'A name is required.' }]}
        >
          <Input size={'large'} placeholder={'My Template'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
