import { useViewerContext } from '@thesisedu/feature-users-react'
import { AntIconWrapper, Bold, Button, Result, Text } from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

export interface StartAssignmentButtonProps {
  loading?: boolean
  onStartAssignment: () => void
  timeLimit: number
  isGroupLeader?: boolean
}
export function StartAssignmentButton({
  loading,
  onStartAssignment,
  timeLimit,
  isGroupLeader,
}: StartAssignmentButtonProps) {
  const viewer = useViewerContext(true)
  return (
    <>
      <Result
        title={'This assignment is timed.'}
        subTitle={
          isGroupLeader === true || isGroupLeader === undefined ? (
            <Text>
              Once you click the "Start Assignment" button below, you will have{' '}
              <Bold>{moment.duration(timeLimit, 'seconds').humanize()}</Bold> to complete it. When
              you are ready, click the "Start Assignment" button below.
            </Text>
          ) : (
            <Text>
              Once the group leader starts the assignment, your group will have{' '}
              <Bold>{moment.duration(timeLimit, 'seconds').humanize()}</Bold> to complete it.
            </Text>
          )
        }
        extra={
          <Button
            onClick={onStartAssignment}
            loading={loading}
            size={'large'}
            disabled={viewer.group !== 'STUDENT' || isGroupLeader === false}
            type={'primary'}
            data-testid={'StartAssignmentButton'}
          >
            Start Assignment <AntIconWrapper children={<NavArrowRight />} />
          </Button>
        }
      />
    </>
  )
}

export interface StartRandomizedAssignmentButtonProps {
  loading?: boolean
  onStartAssignment: () => void
}
export function StartRandomizedAssignmentButton({
  loading,
  onStartAssignment,
}: StartRandomizedAssignmentButtonProps) {
  const viewer = useViewerContext(true)
  return (
    <Result
      title={'This assignment is hidden until you start it.'}
      subTitle={
        <Text>
          You won't be able to see the questions or start submitting your answers until you start
          the assignment. When you are ready, click the "Start Assignment" button below.
        </Text>
      }
      extra={
        <Button
          onClick={onStartAssignment}
          loading={loading}
          size={'large'}
          disabled={viewer.group !== 'STUDENT'}
          type={'primary'}
          data-testid={'StartAssignmentButton'}
        >
          Start Assignment <AntIconWrapper children={<NavArrowRight />} />
        </Button>
      }
    />
  )
}
