import React from 'react'

import { Body, BodySmall } from './Typography'

export interface ContactProps {
  name?: string | null
  email?: string | null
}
export const Contact: React.FC<React.PropsWithChildren<ContactProps>> = ({ name, email }) => {
  if (!name) return null
  const emailContent = email ? (
    <BodySmall>
      <a href={`mailto:${email}`}>{email}</a>
    </BodySmall>
  ) : null
  const nameContent = <Body>{name}</Body>

  return (
    <>
      {nameContent}
      {emailContent}
    </>
  )
}
