import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { InteractionTypeResource } from '@thesisedu/feature-interactions-react'
import { ReactUse, ReactFeature, addModelMetadata } from '@thesisedu/feature-react'

import { CommentFragment } from './schema'

export interface CommentsReactOptions {
  useLikes?: boolean
}

export class CommentsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-comments-react'
  static path = __dirname
  static requires = []
  public options!: CommentsReactOptions

  public reactResources() {
    require('./hooks/routes').default(this)
    addModelMetadata<CommentFragment>(this, 'Comment', comment => ({
      name: 'comment',
      detail: comment.comment,
    }))
    this.resources.addResource<InteractionTypeResource>({
      type: 'InteractionType',
      identifier: 'COMMENT_POSTED',
      handler: require('./interactions/CommentInteraction').CommentInteraction,
    })
    this.resources.addResource<InteractionTypeResource>({
      type: 'InteractionType',
      identifier: 'COMMENT_MODERATED',
      handler: require('./interactions/CommentModeratedInteraction').CommentModeratedInteraction,
    })
    if (this.options.useLikes) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useModifiedFragment(this.hookManager, 'Comment', fragment => {
        let result = addField(fragment, 'likes')
        result = addField(result, 'isLiked')
        return result
      })
    }
  }
}

export const commentsReactFeature: ReactUse<CommentsReactOptions> = opts => [
  CommentsReactFeature,
  opts,
]
