import React from 'react'

import { RubricEditor } from './RubricEditor'
import { DEFAULT_RUBRIC } from './default'
import { AssignmentRubricInput } from '../schema'

export interface AssignmentSettingsRubricFieldProps {
  value?: AssignmentRubricInput | null
  onChange?: (value?: AssignmentRubricInput | null) => void
}
export function AssignmentSettingsRubricField({
  value,
  onChange,
}: AssignmentSettingsRubricFieldProps) {
  const [localValue, setLocalValue] = React.useState<AssignmentRubricInput | undefined | null>(
    DEFAULT_RUBRIC,
  )
  const timeoutRef = React.useRef<any>()
  React.useEffect(() => {
    if (value?.categories.length) {
      setLocalValue(value)
    }
  }, [value])
  return (
    <div id={'custom-rubric'}>
      <RubricEditor
        value={localValue}
        onChange={v => {
          setLocalValue(v)
          if (onChange) {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
              onChange?.(v)
            }, 500)
          }
        }}
      />
    </div>
  )
}
