import { HSpaced, Button, TextField, Tooltip, s } from '@thesisedu/ui'
import { DeleteCircle, Search } from '@thesisedu/ui/icons'
import React from 'react'

import { useTableFilterContext } from './TableFilterContext'

export function NameFilterCell() {
  const { name, setName } = useTableFilterContext(true)
  const [searchVisible, setSearchVisible] = React.useState(!!name?.trim())
  return (
    <HSpaced style={{ flex: 1 }}>
      {searchVisible ? (
        <TextField
          aria-label={'Filter Students'}
          value={name ?? ''}
          autoFocus
          size={'small'}
          placeholder={'Find a student...'}
          onChange={value => {
            React.startTransition(() => {
              setName(value)
            })
          }}
          variant={'bare'}
          style={{ flex: 1 }}
          suffix={
            <Button
              aria-label={'Clear Filter'}
              icon={<DeleteCircle />}
              size={'small'}
              variant={'chromeless'}
              style={{ color: s.color('secondary') }}
              onPress={() => {
                setName(null)
                setSearchVisible(false)
              }}
            />
          }
        />
      ) : (
        <>
          <span style={{ marginRight: 'auto' }}>Name</span>
          <Tooltip title={'Filter Students'}>
            <Button
              aria-label={'Filter Students'}
              icon={<Search />}
              size={'small'}
              variant={'chromeless'}
              style={{ color: s.color('secondary') }}
              onPress={() => {
                setSearchVisible(true)
              }}
            />
          </Tooltip>
        </>
      )}
    </HSpaced>
  )
}
