import { getSegmentParents } from '@thesisedu/feature-course-types'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { OutlineListImperativeHandle } from './OutlineList'
import { useCourseContext } from '../contexts/CourseContext'
import { debug } from '../log'

export interface UseOutlineHighlightOnMountOpts {
  listRef: React.RefObject<OutlineListImperativeHandle>
  setSelectedTermId: (id: string) => void
  selectedTermId: string
}
export function useOutlineHighlightOnMount({
  listRef,
  selectedTermId,
  setSelectedTermId,
}: UseOutlineHighlightOnMountOpts) {
  const { bakedCourseConfiguration } = useCourseContext(false) || {}

  // Highlight on mount if specified in the URL params.
  const [searchParams] = useSearchParams()
  React.useEffect(() => {
    const highlightId = searchParams.get('highlight')
    if (highlightId) {
      debug('highlightId %s specified from URL; highlighting segment', highlightId)
      const parents = getSegmentParents(bakedCourseConfiguration?.segments || [], highlightId)
      if (
        selectedTermId !== parents[0] &&
        bakedCourseConfiguration?.segments.some(s => s.id === parents[0])
      ) {
        debug('...changing to term %s', parents[0])
        setSelectedTermId(parents[0])
      }
      setTimeout(() => {
        debug('...highlighting %s after delay', highlightId)
        listRef.current?.highlightSegment(highlightId)
      }, 500)
    }
  }, [])
}
