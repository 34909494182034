import { useResource } from '@thesisedu/feature-react'
import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { CreateDirectMessageThreadInput } from '../schema'
import { DirectMessageUserSourceResource, UserSource } from '../types'

export interface UserSelectProps
  extends Omit<Partial<SelectProps<string[]>>, 'value' | 'onChange'> {
  value?: UserSource[]
  onChange?: (userIds: UserSource[]) => void
}
export function UserSelect({ value, onChange, ...selectProps }: UserSelectProps) {
  const resources = useResource<DirectMessageUserSourceResource>('DirectMessageUserSource')
  let loading = false
  let userSources: UserSource[] = []
  for (const resource of resources) {
    const { loading: _loading, sources } = resource.useUsers()
    loading = _loading || loading
    userSources = [...userSources, ...sources]
  }

  const grouped: Record<string, UserSource[]> = groupBy(userSources, 'group')
  for (const key of Object.keys(grouped)) {
    grouped[key] = orderBy(grouped[key], ['weight', 'label'], ['asc', 'asc'])
  }

  return (
    <Select<string[]>
      mode={'multiple'}
      {...selectProps}
      disabled={loading || selectProps?.disabled}
      placeholder={loading ? 'Loading users...' : selectProps?.placeholder}
      optionFilterProp={'label'}
      value={value?.map(i => i.value)}
      onChange={e => {
        if (onChange) {
          onChange(
            e
              .map(v => {
                return userSources.find(s => s.value === v)
              })
              .filter(Boolean) as UserSource[],
          )
        }
      }}
      options={Object.keys(grouped).map(groupKey => {
        return {
          label: groupKey,
          options: grouped[groupKey],
        }
      })}
    />
  )
}

export function parseUserSources(
  sources: UserSource[],
): Pick<CreateDirectMessageThreadInput, 'recipientIds' | 'recipientsLabel'> {
  const labels = sources.filter(source => source.userIds.length > 1).map(source => source.label)
  const userIds = new Set<string>()
  for (const source of sources) {
    for (const userId of source.userIds) {
      userIds.add(userId)
    }
  }
  return {
    recipientIds: Array.from(userIds),
    recipientsLabel: labels.length > 1 ? undefined : labels[0],
  }
}
