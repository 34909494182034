import { StudentsPageHeaderHook } from '@thesisedu/feature-classes-react'
import React from 'react'

import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import { OnboardingBanner } from '../../setup/OnboardingBanner'
import { ClassSyncSummary } from '../../web/ClassSyncSummary'

export default function (feature: ClassSyncReactFeature) {
  feature.hookManager.registerMutateHook<StudentsPageHeaderHook>(
    'classes-react:students-page-header',
    (content, { cls }) => {
      return [
        <OnboardingBanner key={'class-sync-onboarding'} classId={cls.id} />,
        <ClassSyncSummary key={'class-sync-summary'} classId={cls.id} />,
        ...content,
      ]
    },
  )
}
