import { Shell } from '@thesisedu/feature-web'
import { BookmarkBook } from '@thesisedu/react/icons'
import React from 'react'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { CourseClassLeftNavHook } from '../types'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<CourseClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { cls, group }) => {
      return [
        ...items,
        {
          group: group === 'STUDENT' ? 'Content' : 'top',
          weight: -10,
          element: (
            <Shell.LeftMenuItem
              key={'course'}
              name={'Course'}
              icon={<BookmarkBook />}
              path={'course'}
            />
          ),
        },
      ]
    },
  )
}
