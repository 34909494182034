import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import { VideoEmbedElement } from './VideoEmbedElement'
import { VideoEmbedSettingsModal } from './VideoEmbedSettingsModal'
import { VideoEmbedConfig } from './common'
import { WidgetsReactFeature } from '../../../WidgetsReactFeature'
import { SimpleWidget } from '../utils'

export const definition = {
  identifier: 'VideoEmbed',
  title: 'Video Embed',
  icon: <Play />,
  weight: 15,
  group: SimpleWidget.ElementsGroup.Media,
  defaultConfig: { url: null, version: 1 },
  draggerUsesFirstChild: true,
  isDynamic: true,
  settingsDropdownItems: [
    {
      identifier: 'alignment',
      weight: 0,
      element: SimpleWidget.AlignmentSetting,
    },
  ],
  element: VideoEmbedElement,
  settingsModal: VideoEmbedSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<VideoEmbedConfig, 'VideoEmbed'>

export default function (feature: WidgetsReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(definition))
}
