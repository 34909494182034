import { useField, AriaFieldProps, FieldAria } from '@react-aria/label'
import React from 'react'

import { FieldDescription } from './FieldDescription'
import { FieldError } from './FieldError'
import { FieldLabel } from './FieldLabel'
import { FieldLabelRight } from './FieldLabelRight'
import { FieldRequired } from './FieldRequired'
import { styled } from '../../'
import HSpaced from '../HSpaced'

export interface FieldProps extends Omit<BareFieldProps, 'children'>, AriaFieldProps {
  children: React.ReactElement
}
function _Field(
  { label, labelRight, error, description, children, ...fieldProps }: FieldProps,
  ref: React.ForwardedRef<any>,
) {
  const { fieldProps: childrenFieldProps, ...__aria } = useField({
    'aria-label': typeof label === 'string' ? label : undefined,
    ...fieldProps,
  })
  const child = React.cloneElement(children, { ...fieldProps, ...childrenFieldProps, ref })
  return (
    <BareField
      label={label}
      labelRight={labelRight}
      error={error}
      description={description}
      children={child}
      __aria={__aria}
    />
  )
}
export const Field = React.forwardRef(_Field)

export interface BareFieldProps {
  label?: React.ReactNode
  labelRight?: React.ReactNode
  error?: React.ReactNode
  description?: React.ReactNode
  children: React.ReactElement
  style?: React.CSSProperties
  isRequired?: boolean
  className?: string
  __aria?: Omit<FieldAria, 'fieldProps'>
}
export type ExtendedBareFieldProps = Omit<BareFieldProps, '__aria' | 'children'>
export function getBareFieldProps<T extends object>(props: T & ExtendedBareFieldProps) {
  const { label, error, description, isRequired, labelRight, ...rest } = props
  return { bareFieldProps: { label, error, description, isRequired, labelRight }, rest }
}

export function BareField({
  label,
  labelRight,
  error,
  description,
  isRequired,
  __aria,
  style,
  className,
  children,
}: BareFieldProps) {
  return (
    <_FormField style={style} className={className}>
      {label ? (
        <HSpaced>
          <FieldLabel {...(__aria?.labelProps as any)} children={label} />
          {labelRight ? (
            <FieldLabelRight children={labelRight} />
          ) : isRequired ? (
            <FieldRequired />
          ) : null}
        </HSpaced>
      ) : null}
      {children}
      {error ? (
        <FieldError {...__aria?.errorMessageProps} children={error} />
      ) : description ? (
        <FieldDescription {...__aria?.descriptionProps} children={description} />
      ) : null}
    </_FormField>
  )
}

const _FormField = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`
