import { useViewerContext } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import React from 'react'

import { SearchReactFeature } from '../SearchReactFeature'
import { SmallSearchField } from '../SmallSearchField'

function TopRightSearchItem() {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'ADMIN') {
    return <SmallSearchField path={'/admin/search?query=QUERY'} />
  } else {
    return null
  }
}

export default function (feature: SearchReactFeature) {
  feature.hookManager.registerMutateHook<Shell.TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    items => {
      return [
        ...items,
        {
          weight: -1,
          element: <TopRightSearchItem key={'search'} />,
        },
      ]
    },
  )
}
