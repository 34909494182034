import { ApolloError } from '@thesisedu/feature-apollo-react/apollo'
import { mergeDeep, toGlobalId } from '@thesisedu/feature-utils'
import React from 'react'

import { useSegmentHydrationQuery } from '../schema'
import { Segment } from '../types'

export function isSegmentHydrated(segment?: Segment) {
  return segment?.__hydrated === true
}

export interface UseHydratedSegment {
  loading?: boolean
  error?: ApolloError
  segment?: Segment
}
export function useHydratedSegment(segment: Segment | undefined): UseHydratedSegment {
  const {
    data,
    loading: _loading,
    error,
  } = useSegmentHydrationQuery({
    variables: {
      id: toGlobalId('Segment', segment?.id ?? ''),
    },
    skip: !segment,
  })

  const mergedSegment = React.useMemo(() => {
    const resultSegment = data?.node?.__typename === 'Segment' ? data.node : undefined
    if (resultSegment?.hydrationData && segment) {
      return mergeDeep(segment, {
        ...resultSegment.hydrationData,
        __hydrated: true,
      })
    } else {
      return segment
    }
  }, [data, segment])

  /**
   * This is a memo because the mergedSegment above is a memo. We were running into issues
   * because loading gets set to false in one render, and then mergedSegment is re-calculated
   * with the proper value in the next render, meaning there was a render where we're
   * reporting the full segment as loaded, but the segment itself has not been updated.
   */
  const loading = React.useMemo(() => _loading, [_loading])

  return { segment: mergedSegment, loading, error }
}
