import { GradingModalContextValue } from '../contexts/GradingModalContext'

const SEP = '-'
export function getStudentClassTabName(studentId: string, classId: string, submissionId?: string) {
  return [studentId, classId, submissionId].filter(Boolean).join(SEP)
}

export interface StudentClassTabResult {
  classId: string
  studentId: string
  submissionId?: string
}
export function getStudentClassFromTabName(tabName: string): StudentClassTabResult | null {
  const [studentId, classId, submissionId] = tabName.split(SEP)
  if (studentId && classId && studentId !== 'all') return { studentId, classId, submissionId }
  else return null
}

export function guessAssignmentCategoryName(
  assignment: GradingModalContextValue['assignment'],
  classId?: string,
) {
  const classCategory = classId
    ? assignment.assignmentCategories.edges.find(edge => edge.node.classId === classId)?.node
    : undefined
  if (classCategory) return classCategory.name
  const allCategoryNames = [
    ...new Set(assignment.assignmentCategories.edges.map(edge => edge.node.name)),
  ]
  if (allCategoryNames.length === 1) return allCategoryNames[0]
  return null
}
