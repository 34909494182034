import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import { Popover, Button, Tooltip } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { PopoverProps } from 'antd/es/popover'
import React, { useState } from 'react'

import { TagsFilterContent, TagsFilterContentProps } from './TagsFilterContent'

export interface TagsFilterChildProps {
  onClick: () => void
}
export interface TagsFilterProps extends TagsFilterContentProps {
  popoverProps?: Partial<PopoverProps>
  buttonProps?: Partial<ButtonProps>
  children?: (props: TagsFilterChildProps) => React.ReactElement
}
export function TagsFilter({ popoverProps, buttonProps, children, ...props }: TagsFilterProps) {
  const [visible, setVisible] = useState(false)
  const { tags, tagsOp, onChange } = props

  return (
    <Popover
      title={'Filter Tags'}
      visible={visible}
      onVisibleChange={setVisible}
      trigger={['click']}
      placement={'bottom'}
      overlayStyle={{ minWidth: 300, width: 500, maxWidth: '100%' }}
      content={<TagsFilterContent {...props} />}
      {...popoverProps}
    >
      {children ? (
        children({ onClick: () => setVisible(true) })
      ) : (
        <Button.Group>
          <Button
            type={tags.length > 0 ? 'primary' : 'default'}
            children={
              tags.length > 0
                ? `Filtering by ${tags.length === 1 ? `${tags.length} tag` : `${tags.length} tags`}`
                : 'Filter Tags'
            }
            onClick={() => setVisible(true)}
            {...buttonProps}
          />
          {tags.length > 0 && (
            <Tooltip title={'Clear Filter'}>
              <Button
                onClick={e => {
                  e.stopPropagation() // So the popover doesn't open.
                  onChange([], tagsOp)
                }}
                type={'primary'}
                icon={<CloseOutlined />}
                {...buttonProps}
              />
            </Tooltip>
          )}
        </Button.Group>
      )}
    </Popover>
  )
}
