import { Grid } from '@thesisedu/react'
import { styled } from '@thesisedu/web'
import React from 'react'

export interface SearchResultGridItemProps {
  image?: React.ReactElement
  title: string | React.ReactElement
  description?: string | React.ReactElement
}
export function SearchResultGridItem({ image, title, description }: SearchResultGridItemProps) {
  return (
    <Container>
      {image}
      <Grid.GridTitle children={title} />
      {description ? <Grid.GridSubtitle children={description} /> : null}
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
`
