import * as React from 'react'

function SvgD(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#F49420'}
          d={
            'M255.044 194.616a63.364 63.364 0 01.848 14.673 80.577 80.577 0 01-2.714 18.45 53.488 53.488 0 01-14.966 24.677c-8.441 8.185-18.468 13.842-29.044 18.65a156.636 156.636 0 01-42.404 12.243 239.509 239.509 0 01-31.468 2.669c-2.415.05-4.82 0-7.224 0a244.564 244.564 0 01-36.078-2.189c-21.152-2.839-41.187-9.115-59.575-20.15a73.916 73.916 0 01-20.104-17.1A53.758 53.758 0 011.34 221.342a89.385 89.385 0 01-1.217-20.1 52.131 52.131 0 0112.491-30.853c3.672-4.407 8.072-7.995 12.462-11.643a25.463 25.463 0 004.48-4.378A7.753 7.753 0 0031.412 150a32.03 32.03 0 012.584-11.084c5.926-14.452 10.526-29.444 17.031-43.687a87.555 87.555 0 0116.542-24.886c.839-.86 1.687-1.72 2.585-2.54 2.873-2.718 5.986-5.166 8.83-7.995 4.19-4.128 9.568-6.416 15.065-8.315a95.893 95.893 0 0125.512-4.758 114.664 114.664 0 0129.583 1.91c6.705 1.338 13.28 3.157 19.216 6.606 11.444 6.616 21.492 14.842 28.835 26.056a121.817 121.817 0 0111.514 22.768c4.56 11.843 8.92 23.747 13.52 35.57a29.087 29.087 0 012.234 9.626 9.61 9.61 0 003.223 6.436c2.903 2.869 6.226 5.277 9.299 7.996 9.348 8.335 15.644 18.52 18.059 30.913z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.998 210.808a73.197 73.197 0 0017.96 32.712c7.682 7.916 17.64 13.993 28.594 15.992a43.129 43.129 0 0018.02-.63c2.314-.59 1.336-4.178-.998-3.588-21.382 5.407-41.686-8.655-52.232-26.496a72.922 72.922 0 01-7.772-18.93c-.599-2.308-4.18-1.329-3.572 1v-.06zm234.068.51c-5.477 21.158-21.142 41.887-43.67 45.975a38.537 38.537 0 01-16.343-.57c-2.315-.59-3.303 2.999-.998 3.578a44.898 44.898 0 0031.339-3.907c10.426-5.278 18.957-13.993 24.943-23.988a76.463 76.463 0 008.331-20.129c.599-2.309-2.993-3.298-3.572-1l-.03.04z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.117 52.252h25.532v10.025h-25.532z'} />
        <path
          fill={'#F49420'}
          d={
            'M131.175 3.128c-17.269 0-31.361 11.833-31.361 26.476 0 10.554 7.424 19.937 18.65 24.189a36.254 36.254 0 0012.745 2.268c3.33.007 6.653-.44 9.869-1.328 12.71-3.544 21.508-13.608 21.508-25.129 0-14.662-14.117-26.476-31.411-26.476z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.175 5.268c16.188 0 29.271 10.949 29.271 24.336 0 10.494-8.107 19.767-19.94 23.067a34.663 34.663 0 01-9.299 1.25 34.114 34.114 0 01-11.992-2.132c-10.434-3.952-17.261-12.58-17.261-22.185 0-13.37 13.06-24.336 29.22-24.336z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M126.526 11.934l.528.003c2.994.031 5.424.336 7.284.916l.322.105c2.18.677 4.155 1.828 5.921 3.448 3.578 3.269 5.37 7.573 5.37 12.868 0 5.314-1.868 9.643-5.594 12.94-1.74 1.534-3.567 2.637-5.468 3.301l-.409.136c-1.824.613-4.33.93-7.532.961l-.511.002h-7.55v-34.68h7.64zm.3 6.015h-1.63v22.649h1.63c2.199 0 4.014-.23 5.432-.68a10.794 10.794 0 003.833-2.267c2.341-2.14 3.507-4.918 3.507-8.377 0-3.49-1.154-6.29-3.466-8.444-1.997-1.844-4.945-2.81-8.867-2.877l-.44-.004z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.803 165.442h.17c.08 18.44-14.268 33.452-32.157 33.732-17.91-.12-32.397-14.992-32.486-33.422h.18c0 6.666 14.197 11.993 31.817 12.043h.768c17.62-.25 31.738-5.697 31.708-12.353z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.427 125.003c0 4.416-3.574 7.996-7.982 7.996-4.409 0-7.982-3.58-7.982-7.996 0-4.415 3.573-7.995 7.982-7.995 4.408 0 7.982 3.58 7.982 7.995zm59.345 0c0 4.416-3.574 7.996-7.982 7.996s-7.982-3.58-7.982-7.996c0-4.415 3.574-7.995 7.982-7.995s7.982 3.58 7.982 7.995zM99.178 94.99a18.736 18.736 0 019.159.27.79.79 0 00.599-1.45c-6.511-3.66-14.726-1.86-19.117 4.188a.79.79 0 00.064 1.084.787.787 0 001.083-.005 18.625 18.625 0 018.212-4.088zm61.59-.7a18.702 18.702 0 017.852 4.787.79.79 0 001.112.115.793.793 0 00.115-1.114c-3.88-6.389-11.919-8.858-18.707-5.747a.79.79 0 00.478 1.49 18.686 18.686 0 019.15.469zm-30.95 57.829a10.292 10.292 0 014.989 1.25.439.439 0 00.499-.72 8.19 8.19 0 00-10.886 0 .44.44 0 00.5.72 10.462 10.462 0 014.898-1.25z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgD = React.memo(SvgD)
export default MemoSvgD
