import { UserMenuItemsHook } from '@thesisedu/feature-users-react'
import React from 'react'

import MediaReactFeature from '../../MediaReactFeature'
import { UploadModalMenuItem } from '../../upload/UploadModalMenuItem'

export default function (feature: MediaReactFeature) {
  feature.hookManager.registerMutateHook<UserMenuItemsHook>(
    'feature-users-react:user-menu',
    items => {
      return [...items, <UploadModalMenuItem key={'uploads'} />]
    },
  )
}
