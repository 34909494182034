import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { useParams } from 'react-router-dom'

import { StandardSetPage } from '../../explore/StandardSetPage'

export function StandardSet() {
  const { standardSetId } = useParams()
  Shell.useShellProps(
    'standard-set',
    {
      smallWidth: true,
    },
    [],
  )
  return <StandardSetPage standardSetId={standardSetId} />
}
