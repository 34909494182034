import { onMutationError, useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, Modal, Text, useToast } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

export interface DeletePresetButtonProps {
  presetId: string
  onDelete?: () => void
  trigger?: React.ReactElement
}
export function DeletePresetButton({ presetId, onDelete, trigger }: DeletePresetButtonProps) {
  const toast = useToast()
  const [deletePreset] = useDeleteNodeMutation({
    onError: onMutationError('There was an error deleting that preset.'),
    onCompleted() {
      toast.success('Deleted preset')
      onDelete?.()
    },
    update(cache) {
      cache.evict({
        id: cache.identify({
          __typename: 'ClassGroupPreset',
          id: presetId,
        }),
      })
    },
  })
  return (
    <Modal.Confirm
      title={'Are you sure?'}
      trigger={
        trigger ?? (
          <Button children={'Delete Preset'} icon={<Trash />} variant={'ghost'} status={'danger'} />
        )
      }
      confirm={{
        children: 'Yes, delete preset',
        status: 'danger',
      }}
      cancel={{
        children: 'No, keep preset',
      }}
      onConfirm={async () => {
        await deletePreset({
          variables: {
            id: presetId,
          },
        })
      }}
    >
      <Text>
        Are you sure you want to delete this preset? Deleting a preset has no impact on existing
        assignments.
      </Text>
    </Modal.Confirm>
  )
}
