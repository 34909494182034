import { BarDatum, BarSvgProps, ResponsiveBar as NivoResponsiveBar } from '@nivo/bar'
import { Format } from '@thesisedu/feature-utils'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { AspectBox } from './AspectBox'
import { getColors, getNivoTheme } from './theme'
import { styled } from '../styledTypes'

const generateLoadingData = (keys: string[], indexBy: BarSvgProps<any>['indexBy']): any[] => {
  const ind = typeof indexBy === 'function' ? indexBy({}) : indexBy
  if (!ind) return []
  return new Array(15).fill('').map(() =>
    keys.reduce<object>(
      (acc, key) => ({
        ...acc,
        [key]: Math.random() * 100 + 50,
      }),
      { [ind]: '--' },
    ),
  )
}
const loadingKeys = new Array(15).fill('').map(() => Math.random().toString())

interface Data {
  [key: string]: any
}
export interface ValueConfig {
  name: string
  formatter?: (value: any, data: Data) => any
}
export interface ValueLabelMap {
  [dataKey: string]: ValueConfig
}
export type ResponsiveBarProps<T extends BarDatum = any> = Omit<
  BarSvgProps<T>,
  'height' | 'width'
> & {
  loading?: boolean
  valueLabels?: ValueLabelMap
}
export function Bar<T extends BarDatum = any>({
  loading,
  valueLabels,
  ...props
}: ResponsiveBarProps<T>) {
  const theme = useContext(ThemeContext)
  return (
    <NivoResponsiveBar
      colors={getColors(theme, loading)}
      theme={getNivoTheme(theme)}
      borderRadius={parseFloat(theme['@border-radius-base'].replace('px', ''))}
      layers={['grid', 'axes', 'bars', 'markers']}
      enableLabel={false}
      margin={{ left: 45, top: 10, bottom: 30 }}
      axisBottom={{
        format: value =>
          value
            .toString()
            .split(' ')
            .map((item: string) => (item[0] ? item[0].toUpperCase() : ''))
            .join(''),
        ...props.axisBottom,
      }}
      padding={0.2}
      tooltip={p => {
        if (valueLabels) {
          return (
            <TooltipContainer>
              {p.data[props.indexBy as string]}
              <br />
              {Object.keys(valueLabels).map(valueLabelKey => (
                <span key={valueLabelKey}>
                  <strong>{valueLabels[valueLabelKey].name}: </strong>
                  <span>
                    {valueLabels[valueLabelKey].formatter
                      ? valueLabels[valueLabelKey].formatter!(p.data[valueLabelKey], p.data)
                      : Format.number(parseFloat(p.data[valueLabelKey].toString()), 'number')}
                  </span>
                  <br />
                </span>
              ))}
            </TooltipContainer>
          )
        } else {
          return (
            <TooltipContainer>
              {p.data[props.indexBy as string]} -{' '}
              <strong>{Format.number(p.value, 'number')}</strong>
            </TooltipContainer>
          )
        }
      }}
      {...props}
      keys={loading ? loadingKeys : props.keys}
      axisLeft={{
        legendOffset: -40,
        legendPosition: 'middle',
        ...props.axisLeft,
      }}
      data={
        loading ? generateLoadingData(props.keys || ['one'], props.indexBy || 'test') : props.data
      }
    />
  )
}

export function ResponsiveBar<T extends BarDatum = any>(props: ResponsiveBarProps<T>) {
  return (
    <AspectBox numYears={2} numItems={props.data?.length || 0}>
      <div>
        <Bar {...props} />
      </div>
    </AspectBox>
  )
}

const TooltipContainer = styled.div`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  border-radius: ${props => props.theme['@border-radius-base']};
  box-shadow: ${props => props.theme['@shadow-small']};
  background: ${props => props.theme['@gray-0']};
  border: solid 1px ${props => props.theme['@border-color-base']};
`
