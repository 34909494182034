import * as React from 'react'

function SvgF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#69B945'}
          d={
            'M255.038 194.616c.86 4.84 1.145 9.765.848 14.673a80.567 80.567 0 01-2.714 18.45 53.397 53.397 0 01-14.968 24.677c-8.422 8.185-18.47 13.842-29.048 18.65a156.474 156.474 0 01-42.42 12.243 239.283 239.283 0 01-31.442 2.669c-2.415.05-4.81 0-7.225 0a244.881 244.881 0 01-36.083-2.189c-21.154-2.839-41.192-9.115-59.582-20.15a73.923 73.923 0 01-20.087-17.08A53.754 53.754 0 011.34 221.362a89.373 89.373 0 01-1.217-20.1 52.067 52.067 0 0112.503-30.853c3.662-4.407 8.063-7.995 12.453-11.644a24.796 24.796 0 004.48-4.377 7.752 7.752 0 001.857-4.368A32.026 32.026 0 0134 138.936c5.928-14.452 10.538-29.444 17.034-43.687a87.554 87.554 0 0116.544-24.886c.849-.86 1.687-1.72 2.605-2.54 2.854-2.718 5.987-5.166 8.811-7.995 4.211-4.128 9.58-6.416 15.078-8.315a95.768 95.768 0 0125.505-4.758 114.794 114.794 0 0129.597 1.91c6.696 1.338 13.282 3.157 19.209 6.606 11.456 6.626 21.504 14.842 28.848 26.066a121.9 121.9 0 0111.526 22.738c4.54 11.843 8.9 23.747 13.5 35.57a29.344 29.344 0 012.246 9.626 9.67 9.67 0 003.203 6.436c2.924 2.869 6.247 5.277 9.31 7.996 9.38 8.335 15.647 18.52 18.022 30.913z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M11 210.808a73.195 73.195 0 0017.96 32.712c7.684 7.916 17.652 13.993 28.609 15.992a43.1 43.1 0 0018.011-.63c2.315-.59 1.337-4.178-.998-3.588-21.384 5.407-41.69-8.655-52.238-26.496a72.917 72.917 0 01-7.793-18.98c-.599-2.308-4.171-1.329-3.573 1l.02-.01zm234.06.51c-5.48 21.158-21.146 41.887-43.668 45.975a38.587 38.587 0 01-16.355-.57c-2.315-.59-3.303 2.999-.997 3.578a44.91 44.91 0 0031.343-3.907c10.427-5.278 18.959-13.993 24.946-23.988a76.117 76.117 0 008.332-20.129c.6-2.309-2.993-3.298-3.572-1l-.03.04z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.133 52.252h25.535v10.025h-25.535z'} />
        <path
          fill={'#69B945'}
          d={
            'M131.192 3.128c-17.264 0-31.365 11.836-31.365 26.476 0 10.554 7.425 19.937 18.653 24.189a36.302 36.302 0 0012.746 2.268c3.331.007 6.654-.44 9.87-1.328 12.712-3.544 21.512-13.608 21.512-25.129 0-14.66-14.126-26.476-31.416-26.476z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.192 5.268c16.184 0 29.276 10.952 29.276 24.336 0 10.494-8.108 19.767-19.943 23.067a34.67 34.67 0 01-9.3 1.25 34.161 34.161 0 01-11.994-2.132c-10.435-3.952-17.264-12.58-17.264-22.185 0-13.367 13.068-24.336 29.225-24.336z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M138.979 11.934h-17.931v34.68h6.311l-.001-15.901h11.211v-6.015l-11.211-.001v-6.748h11.621z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.825 165.442h.17c.08 18.44-14.27 33.452-32.162 33.732-17.961-.12-32.4-14.992-32.49-33.422h.18c0 6.666 14.209 11.993 31.821 12.043h.769c17.622-.25 31.742-5.697 31.712-12.353z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.441 125.003c0 4.416-3.574 7.996-7.983 7.996s-7.983-3.58-7.983-7.996c0-4.415 3.574-7.995 7.983-7.995s7.983 3.58 7.983 7.995zm59.353 0c0 4.416-3.574 7.996-7.983 7.996s-7.983-3.58-7.983-7.996c0-4.415 3.574-7.995 7.983-7.995 4.41 0 7.983 3.58 7.983 7.995zM99.19 94.99a18.74 18.74 0 019.161.27.79.79 0 00.599-1.45c-6.511-3.66-14.727-1.86-19.12 4.188a.79.79 0 00.096 1.05.787.787 0 001.052.02 18.629 18.629 0 018.213-4.079zm61.6-.7a18.698 18.698 0 017.852 4.737.79.79 0 001.113.115.793.793 0 00.115-1.114c-3.881-6.389-11.92-8.858-18.71-5.747a.79.79 0 00.479 1.49c3.046-.59 6.19-.411 9.15.519zm-30.955 57.829a10.35 10.35 0 014.99 1.25.439.439 0 00.489-.72 8.183 8.183 0 00-10.877 0 .44.44 0 00.489.72 10.464 10.464 0 014.91-1.25z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgF = React.memo(SvgF)
export default MemoSvgF
