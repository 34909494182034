import { Button, Modal, Table, Text } from '@thesisedu/ui'
import React from 'react'

interface Field {
  name: string
  example?: string
  description: string
  extra?: string
  required?: boolean | string
}
const Fields: Field[] = [
  {
    name: 'label',
    description: 'The label of the content',
    example: 'Lesson',
    required: 'If ID is missing',
  },
  {
    name: 'name',
    description: 'The name of the content',
    example: 'Welcome to Marketing',
    required: 'If ID is missing',
  },
  {
    name: 'id',
    description: 'The segment ID of the content',
    extra:
      'If the segment ID is specified, you will be able to update the name and label of the content',
  },
  {
    name: '...[tag type]',
    description: 'Provides a new value for this tag',
    extra:
      'For example, if the [tag type] is "CONTENT_AREA" this might be something like "Business." If multiple CONTENT_AREA columns are passed, then those are all added to the CONTENT_AREA tags for the content.',
  },
]

export function FieldsTable() {
  return (
    <Modal
      title={'Supported Fields'}
      trigger={
        <Button
          variant={'chromeless'}
          size={'small'}
          children={'Click here to view supported fields.'}
        />
      }
      width={800}
    >
      <Table>
        <Table.Header>
          <Table.Column isRowHeader>Field</Table.Column>
          <Table.Column>Example</Table.Column>
          <Table.Column>Description</Table.Column>
        </Table.Header>
        <Table.Body>
          {Fields.map(field => (
            <Table.Row id={field.name} key={field.name}>
              <Table.Cell>
                <Text level={null} weight={'bold'} font={'code'} style={{ whiteSpace: 'nowrap' }}>
                  {field.name}
                </Text>
              </Table.Cell>
              <Table.Cell>{field.example}</Table.Cell>
              <Table.Cell>
                {field.description}
                {field.extra ? (
                  <>
                    <br />
                    <Text level={'xs'} color={'secondary'}>
                      {field.extra}
                    </Text>
                  </>
                ) : null}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Modal>
  )
}
