import { FetchMoreOptions, FetchMoreQueryOptions, QueryResult } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { set, get } from 'lodash'

import { debug, error } from './log'

export interface RequiredVariables {
  after?: string | null
}
export interface DataResult<Edge = any> {
  pageInfo: {
    hasNextPage?: boolean | null
    endCursor?: string | null
    [other: string]: any
  }
  edges: Edge[]
  [other: string]: any
}

type InternalFetchMoreOptions<TData, TVariables extends RequiredVariables> = FetchMoreOptions<
  TData,
  TVariables
> &
  FetchMoreQueryOptions<TVariables, 'after'>
export interface LoadMoreOpts<TData, TVariables extends RequiredVariables, TEdge = any> {
  fetchMore: QueryResult<TData, TVariables>['fetchMore']
  getResult?: (
    data?: QueryResult<TData, TVariables>['data'],
  ) => DataResult<TEdge> | undefined | null
  setResult?: (previous: TData, data: DataResult<TEdge>) => TData
  resultPath?: string
  data?: QueryResult<TData, TVariables>['data']
  variables?: Omit<TVariables, 'after'>
}
export async function loadMore<TData, TVariables extends RequiredVariables>({
  fetchMore,
  data,
  getResult: _getResult,
  setResult: _setResult,
  resultPath,
  variables,
}: LoadMoreOpts<TData, TVariables>) {
  const { getResult, setResult } = getSetResult<TData, TVariables>({
    getResult: _getResult,
    setResult: _setResult,
    resultPath,
  })
  const result = getResult(data)
  if (result?.pageInfo.hasNextPage) {
    debug('fetching more after %s', result.pageInfo.endCursor)
    const opts: InternalFetchMoreOptions<TData, TVariables> = {
      variables: {
        after: result!.pageInfo.endCursor,
        ...variables,
      } as TVariables,
      updateQuery: (previous, { fetchMoreResult }) => {
        const previousResult = getResult(previous)
        const newResult = getResult(fetchMoreResult)
        if (!previousResult) {
          throw new Error('Cannot update query. No previous results.')
        }
        if (!newResult) return previous
        const newEdges = newResult.edges || []
        const pageInfo = newResult.pageInfo
        if (newEdges.length) {
          return setResult(previous, {
            ...previousResult,
            edges: [...previousResult.edges, ...newEdges],
            pageInfo,
          })
        } else {
          return previous
        }
      },
    }
    await fetchMore(opts).catch(err => {
      error('Error fetching more')
      error(err)
    })
  }
}

export function getSetResult<TData, TVariables extends RequiredVariables>({
  getResult: _getResult,
  setResult: _setResult,
  resultPath,
}: Pick<LoadMoreOpts<TData, TVariables>, 'getResult' | 'setResult' | 'resultPath'>) {
  const getResult: typeof _getResult | null = _getResult
    ? _getResult
    : resultPath
    ? d => get(d, resultPath)
    : null
  const setResult: typeof _setResult | null = _setResult
    ? _setResult
    : resultPath
    ? (p, n) => set(cloneDeep(p as any), resultPath, n)
    : null
  if (!getResult || !setResult) {
    throw new Error('Either getResult and setResult are required, or resultPath is required.')
  }

  return {
    getResult,
    setResult,
  }
}
