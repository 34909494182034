import { Result } from '@thesisedu/react'
import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import {
  GenerateGroupsConfiguration,
  GenerateGroupsConfigurationProps,
} from './GenerateGroupsConfiguration'
import { useGroupGenerationModesQuery } from '../../schema'

export interface LoadGenerateGroupsConfigurationProps
  extends Omit<GenerateGroupsConfigurationProps, 'groupModes' | 'leaderModes'> {}
export function LoadGenerateGroupsConfiguration({
  onChange,
  students,
  submitButtonProps,
}: LoadGenerateGroupsConfigurationProps) {
  const { data, loading } = useGroupGenerationModesQuery()
  const groupModes = data?.generateGroupModes ?? []
  const leaderModes = data?.generateLeaderModes ?? []
  if (loading) {
    return <LoadingIndicator.Labeled label={'Getting generation info...'} />
  } else if (groupModes.length && leaderModes.length) {
    return (
      <GenerateGroupsConfiguration
        onChange={onChange}
        groupModes={groupModes}
        leaderModes={leaderModes}
        students={students}
        submitButtonProps={submitButtonProps}
      />
    )
  } else {
    return <Result.Error description={'Could not grab generation info.'} />
  }
}
