/* eslint-disable import/export */
// This is because useQuery and useMutation below override the exports from @apollo/client,
// which might cause issues in the future. They don't currently, apparently.
import {
  useQuery as apolloUseQuery,
  useMutation as apolloUseMutation,
  DocumentNode,
} from '@apollo/client'

import { useFeatureRoot } from './feature'
import { modifyFragmentDocument, modifyQueryDocument } from './queries'
import { MutateCompletedMutation } from './types'
export * from '@apollo/client'

export const useModifiedQueryDocument = (query: DocumentNode): DocumentNode => {
  const root = useFeatureRoot()
  let realQuery = query
  if (root) {
    realQuery = modifyQueryDocument(query, root.deps.hookManager)
  }
  return realQuery
}

export const useQuery: typeof apolloUseQuery = (query, opts) =>
  apolloUseQuery(useModifiedQueryDocument(query), opts)

export const useMutation: typeof apolloUseMutation = (query, opts) => {
  const root = useFeatureRoot()
  return apolloUseMutation(useModifiedQueryDocument(query), {
    ...opts,
    onCompleted: async data => {
      await root?.deps.hookManager.mutateHook<MutateCompletedMutation>(
        'apollo-react:completed-mutation',
        undefined,
        { data },
      )
      if (opts?.onCompleted) {
        return opts.onCompleted(data)
      }
    },
    update: (proxy, mutationResult, options) => {
      if (opts?.update) {
        if (root) {
          const ogReadFragment = proxy.readFragment.bind(proxy)
          const readFragment: (typeof proxy)['readFragment'] = ({ fragment, ...readOpts }) =>
            ogReadFragment({
              ...readOpts,
              fragment: modifyFragmentDocument(fragment, root.deps.hookManager),
            })
          const ogWriteFragment = proxy.writeFragment.bind(proxy)
          const writeFragment: (typeof proxy)['writeFragment'] = ({ fragment, ...writeOpts }) =>
            ogWriteFragment({
              ...writeOpts,
              fragment: modifyFragmentDocument(fragment, root.deps.hookManager),
            })

          // This might be dangerous?
          proxy.writeFragment = writeFragment
          proxy.readFragment = readFragment

          return opts.update(proxy, mutationResult, options)
        } else {
          return opts.update(proxy, mutationResult, options)
        }
      }
    },
  })
}
