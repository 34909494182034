import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface WidgetTemplatesReactFeatureOptions {}

export class WidgetTemplatesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-widget-templates-react'
  static path = ''
  static requires: string[] = []
  public options!: WidgetTemplatesReactFeatureOptions

  public reactResources() {
    require('./resources/Widget/WidgetTemplate').default(this)
    require('./hooks/routes').default(this)
  }
}

export const widgetTemplatesReactFeature: ReactUse = (opts: WidgetTemplatesReactFeatureOptions) => [
  WidgetTemplatesReactFeature,
  opts,
]
