import { ExploreHomeContentHook } from '@thesisedu/feature-courses-react'
import { FeatureWeb } from '@thesisedu/feature-web'

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<ExploreHomeContentHook>(
    'courses-react:explore-home-content',
    content => {
      return content.filter(c => c.key !== 'content-areas')
    },
  )
}
