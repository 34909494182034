import { LexicalEditor, LexicalNode } from 'lexical'
import React from 'react'

export interface SettingsDropdownElementProps<Node extends LexicalNode = LexicalNode> {
  editor: LexicalEditor
  node: Node
}
export interface SettingsDropdownItem {
  element: (props: SettingsDropdownElementProps<any>) => React.ReactElement | null
  filter?: (editor: LexicalEditor, node: LexicalNode) => boolean
  group?: string
  identifier: string
  weight: number
}

export interface SettingsDropdownContextValue {
  items: SettingsDropdownItem[]
  registerItem: (item: SettingsDropdownItem) => void
  removeItem: (item: SettingsDropdownItem) => void
}
export const SettingsDropdownContext = React.createContext<
  SettingsDropdownContextValue | undefined
>(undefined)

export function SettingsDropdownProvider({ children }: React.PropsWithChildren<object>) {
  const [items, setItems] = React.useState<SettingsDropdownItem[]>([])
  const contextValue = React.useMemo<SettingsDropdownContextValue>(() => {
    return {
      items,
      registerItem(item) {
        setItems(items => {
          if (items.some(i => i.identifier === item.identifier)) {
            return items
          }
          return [...items, item]
        })
      },
      removeItem(item) {
        setItems(items => {
          return items.filter(i => i.identifier !== item.identifier)
        })
      },
    }
  }, [items])

  return <SettingsDropdownContext.Provider value={contextValue} children={children} />
}

export function useSettingsDropdownContext(): SettingsDropdownContextValue | undefined
export function useSettingsDropdownContext(require: false): SettingsDropdownContextValue | undefined
export function useSettingsDropdownContext(require: true): SettingsDropdownContextValue
export function useSettingsDropdownContext(
  require?: boolean,
): SettingsDropdownContextValue | undefined {
  const context = React.useContext(SettingsDropdownContext)
  if (!context && require) {
    throw new Error('SettingsDropdownContext is required, yet not provided.')
  }
  return context
}

export function useSettingsDropdownItem(item: SettingsDropdownItem) {
  const context = useSettingsDropdownContext(false)
  React.useEffect(() => {
    if (context) {
      context.registerItem(item)
      return () => context.removeItem(item)
    }
  }, [])
}
