import React from 'react'

import { ScreenRecordingFooterButton } from './ScreenRecordingFooterButton'
import { SelectAudioSourceFooterButton } from './SelectAudioSourceFooterButton'
import { SelectVideoSourceFooterButton } from './SelectVideoSourceFooterButton'
import { useRecordViewContext } from '../../../RecordViewContext'
import { useDetailedRecordStateContext } from '../DetailedRecordStateContext'

export function DeviceButtons() {
  const { streams, updateStreams, loading } = useDetailedRecordStateContext()
  const { hideAudio, hideVideo } = useRecordViewContext(false) ?? {}
  return (
    <>
      {hideAudio ? null : (
        <SelectAudioSourceFooterButton
          value={streams.audioDeviceId}
          disabled={loading}
          onChange={audioDeviceId => {
            updateStreams({
              audioDeviceId,
              videoDeviceId: streams.videoDeviceId,
              screenRecording: streams.hasDesktopRecording,
            })
          }}
        />
      )}
      {hideVideo ? null : (
        <SelectVideoSourceFooterButton
          value={streams.videoDeviceId}
          disabled={loading}
          onChange={videoDeviceId => {
            updateStreams({
              videoDeviceId,
              audioDeviceId: streams.audioDeviceId,
              screenRecording: streams.hasDesktopRecording,
            })
          }}
        />
      )}
      {hideVideo ? null : (
        <ScreenRecordingFooterButton
          value={streams.hasDesktopRecording}
          disabled={loading}
          onChange={enabled => {
            updateStreams({
              videoDeviceId: streams.videoDeviceId,
              audioDeviceId: streams.audioDeviceId,
              screenRecording: enabled,
            })
          }}
        />
      )}
    </>
  )
}
