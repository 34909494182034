import { Nullable } from '@thesisedu/feature-types'
import React from 'react'
import { Control, FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

import { InnerForm } from './InnerForm'
import { FormInstance } from './types'

export interface ReactFormContextValue<Values extends FieldValues> {
  handleSubmit: (e: any) => void
  control: Control<Values>
  form: FormInstance<Values>
}
export const ReactFormContext = React.createContext<ReactFormContextValue<any> | undefined>(
  undefined,
)
export function useReactFormContext<Values extends FieldValues>(): ReactFormContextValue<Values> {
  const context = React.useContext(ReactFormContext)
  if (!context) {
    throw new Error('ReactFormContext is required, but not provided.')
  }
  return context
}

export function useFormSubmit() {
  const { handleSubmit } = useReactFormContext()
  return (e?: any) => handleSubmit(e || {})
}

export interface ReactFormContextProviderProps<Values extends FieldValues> {
  onFinish?: FormProps<Values>['onFinish']
  onFinishFailed?: FormProps<Values>['onFinishFailed']
  onValuesChange?: FormProps<Values>['onValuesChange']
  form: FormInstance<Values>
}
/** @deprecated */
export function ReactFormContextProvider<Values extends Record<string, any>>({
  onFinish,
  onFinishFailed,
  onValuesChange,
  form,
  children,
}: React.PropsWithChildren<ReactFormContextProviderProps<Values>>) {
  return (
    <ReactFormContext.Provider
      value={{
        form,
        control: form.control,
        handleSubmit: form.handleSubmit(onFinish as any, async () => {
          if (onFinishFailed) {
            await onFinishFailed(form.getValues() as Partial<Nullable<Values>>)
          }
        }),
      }}
    >
      {onValuesChange ? (
        <OnValuesChangeWatcher onValuesChange={onValuesChange} form={form} />
      ) : null}
      {children}
    </ReactFormContext.Provider>
  )
}

export interface OnValuesChangeWatcherProps<T extends FieldValues>
  extends Pick<FormProps<T>, 'onValuesChange' | 'form'> {}
/** @deprecated */
export function OnValuesChangeWatcher<T extends FieldValues>({
  onValuesChange,
  form,
}: OnValuesChangeWatcherProps<T>) {
  const values = onValuesChange ? form.watch() : undefined
  // We have to stringify because it looks like the watcher returns a different value by reference each time.
  React.useEffect(() => {
    if (onValuesChange && values) {
      onValuesChange(values as Partial<Nullable<T>>)
    }
  }, [JSON.stringify(values)])
  return null
}

export interface FormProps<Values extends FieldValues> {
  form: UseFormReturn<Values>
  onFinish?: (values: Values) => void | Promise<void>
  onFinishFailed?: (values: Partial<Nullable<Values>>) => void | Promise<void>
  onValuesChange?: (values: Partial<Nullable<Values>>) => void
  className?: string
  style?: any
}
/** @deprecated */
export function Form<Values extends FieldValues>({
  form,
  onFinish,
  onFinishFailed,
  onValuesChange,
  children,
  className,
  style,
}: React.PropsWithChildren<FormProps<Values>>) {
  return (
    <FormProvider {...form}>
      <ReactFormContextProvider
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <InnerForm children={children} className={className} style={style} />
      </ReactFormContextProvider>
    </FormProvider>
  )
}
