import { Modal, Image, H2, BodyLarge, styled } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

// @ts-ignore
import DragBellGif from '../../../../assets/bell-drag-error.gif'
// @ts-ignore
import { ReactComponent as AccidentalFlat } from '../../../../assets/sketch/sheet-music/accidental-flat.svg'
// @ts-ignore
import { ReactComponent as AccidentalSharp } from '../../../../assets/sketch/sheet-music/accidental-sharp.svg'

const DragErrorGifContainer = styled.div`
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-large']};
  margin: 0 auto ${props => props.theme['@size-m']} auto;
  width: 80%;
  padding: ${props => props.theme['@padding-md']};
  > div,
  img {
    border-radius: ${props => props.theme['@border-radius-base']};
    display: block;
  }
`
export interface DrageErrorModalProps {
  visible: boolean
  onClose: () => void
}

export function DragErrorModal({ visible, onClose }: DrageErrorModalProps) {
  return (
    <Modal
      visible={visible}
      onCancel={() => onClose()}
      footer={null}
      width={600}
      style={{ maxWidth: '90vw' }}
      bodyStyle={{ padding: 60 }}
      closable={false}
    >
      <H2 style={{ paddingTop: 60, textAlign: 'center' }}>Whoops!</H2>
      <DragErrorGifContainer>
        <Image
          src={DragBellGif}
          alt={
            'A gif of a mouse hovering over the note, then clicking and dragging the correct accidental version of the note'
          }
        />
      </DragErrorGifContainer>
      <BodyLarge style={{ textAlign: 'center' }} isBlock>
        Select the flat or sharp version of this bell...
        <br />
        <strong>not the bell itself</strong>
      </BodyLarge>
      <Button
        style={{ margin: '0 auto', display: 'block', height: 80, lineHeight: 1 }}
        type={'primary'}
        size={'large'}
        onClick={() => onClose()}
      >
        Got it:
        <br /> Hover, then select <AccidentalFlat style={{ height: 20 }} /> or{' '}
        <AccidentalSharp style={{ height: 20 }} />
      </Button>
    </Modal>
  )
}
