import React from 'react'

import { ResetAssignmentSubmissionAction } from './ResetAssignmentSubmissionAction'
import { BasicAssignmentFragment, BasicAssignmentSubmissionFragment } from '../schema'

export interface AssignmentSubmissionActionProps {
  submission?: BasicAssignmentSubmissionFragment
  assignment: BasicAssignmentFragment
  studentId: string
}
export type AssignmentSubmissionActionsProps = AssignmentSubmissionActionProps
export function AssignmentSubmissionActions({
  submission,
  assignment,
  studentId,
}: AssignmentSubmissionActionsProps) {
  const props: AssignmentSubmissionActionProps = { studentId, submission, assignment }
  return <ResetAssignmentSubmissionAction {...props} />
}
