import { CheckOutlined } from '@ant-design/icons'
import { Block, DiscreteLoadingIndicator } from '@thesisedu/web'
import { Button, Checkbox, Form, message, Modal, Radio } from 'antd'
import React from 'react'

import { TrialRequestWarning } from './TrialRequestsList'
import { useApproveTrialRequestMutation } from './queries/useApproveTrialRequestMutation'
import { TrialRequestApproval, TrialRequestFragment, useTrialRequestQuery } from './schema'

export interface ApproveTrialRequestModalProps {
  id: string
  existingRequest?: TrialRequestFragment
  visible?: boolean
  onClose: () => void
  onApproved?: (siteId: string) => any
}
export const ApproveTrialRequestModal: React.FC<
  React.PropsWithChildren<ApproveTrialRequestModalProps>
> = ({ id, existingRequest, visible, onClose, onApproved }) => {
  const { data, loading: requestLoading } = useTrialRequestQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  })
  const loadedRequest = data?.node?.__typename === 'TrialRequest' ? data.node : undefined
  const request = loadedRequest || existingRequest
  const [approve, { loading }] = useApproveTrialRequestMutation({
    onCompleted: data => {
      message.success('Trial request approved')
      onClose()
      if (onApproved) {
        onApproved(data.approveTrialRequest.site.id)
      }
    },
  })
  const [form] = Form.useForm()
  return (
    <Modal
      title={'Approve Trial Request'}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key={'cancel'} onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button
          key={'submit'}
          type={'primary'}
          loading={loading}
          onClick={() => form.submit()}
          icon={<CheckOutlined />}
        >
          Approve Trial Request
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={values => {
          approve({
            variables: {
              input: {
                id,
                approval: values.approval,
                noEmail: values.noEmail,
              },
            },
          })
        }}
        layout={'vertical'}
      >
        <Form.Item
          label={'Approval'}
          name={'approval'}
          rules={[{ required: true, message: 'An approval type is required.' }]}
        >
          <Radio.Group>
            <Radio value={TrialRequestApproval.Created_15Day}>15-day Trial</Radio>
            <Radio value={TrialRequestApproval.Created_30Day}>30-day Trial</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label={'Email Notifications'} name={'noEmail'} valuePropName={'checked'}>
          <Checkbox>Don't send email notification.</Checkbox>
        </Form.Item>
      </Form>
      <Block marginTop={'@size-m'} style={{ position: 'relative' }}>
        <TrialRequestWarning
          large
          cantApproveReason={request?.cantApproveReason}
          existingLicense={request?.existingLicense}
        />
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <DiscreteLoadingIndicator loading={requestLoading} />
        </div>
      </Block>
    </Modal>
  )
}
