import { UserRoutesHook } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { DirectMessagesReactFeature } from '../../DirectMessagesReactFeature'
import { DirectMessagesRoute } from '../../web/DirectMessagesRoute'

export default function (feature: DirectMessagesReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      return [
        ...routes,
        <Route key={'messages'} path={'messages/*'} element={<DirectMessagesRoute />} />,
      ]
    },
  )
}
