import { LoadingOutlined } from '@ant-design/icons'
import { ErrorIndicator } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { QuestionAssignmentReadOnlyViewer } from './QuestionAssignmentReadOnlyViewer'
import { QuestionAssignmentRealTimeGradingSampleViewer } from './QuestionAssignmentRealTimeGradingSampleViewer'
import { QuestionAssignmentSubmissionViewer } from './QuestionAssignmentSubmissionViewer'
import { useAssignmentTemplateAndConfiguration } from './useAssignmentTemplateAndConfiguration'
import { AssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { FullAssignment } from '../grading/types'
import { FullAssignmentSubmissionFragment } from '../schema'

export interface QuestionAssignmentViewerProps {
  templateId: string
  classId: string
  teacherView?: boolean | 'real-time-grading'
  forceShowAssignment?: boolean
  loading?: boolean
  assignment?: FullAssignment
  submission?: FullAssignmentSubmissionFragment
  /** Called whenever the first submission is created for an assignment. */
  onFirstSubmissionCreated?: (submission: FullAssignmentSubmissionFragment) => void
}
export function QuestionAssignmentViewer({
  templateId,
  classId,
  teacherView,
  forceShowAssignment,
  submission,
  loading,
  assignment,
  onFirstSubmissionCreated,
}: QuestionAssignmentViewerProps) {
  const {
    template,
    configuration,
    loading: templateLoading,
    error,
  } = useAssignmentTemplateAndConfiguration(templateId, assignment)

  if (templateLoading || loading) {
    return <Result icon={<LoadingOutlined size={75} />} title={'Preparing assignment...'} />
  } else if (error || !template || !configuration?.questions) {
    return <ErrorIndicator />
  }

  let content
  if (teacherView === 'real-time-grading') {
    content = <QuestionAssignmentRealTimeGradingSampleViewer />
  } else if (assignment && !teacherView) {
    content = (
      <QuestionAssignmentSubmissionViewer
        assignment={assignment}
        submission={submission}
        onFirstSubmissionCreated={onFirstSubmissionCreated}
      />
    )
  } else {
    content = <QuestionAssignmentReadOnlyViewer />
  }

  return (
    <AssignmentViewerContext.Provider
      value={{
        configuration,
        assignment,
        forceShowAssignment,
        // We have to set this to false if teacherView is real-time-grading because we want the
        // questions to be editable.
        teacherView: teacherView === true,
        classId,
        templateId,
      }}
      children={content}
    />
  )
}
