import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isTableNode } from '@thesisedu/feature-widgets-core'
import { Table } from '@thesisedu/ui/icons'
import React from 'react'

import { INSERT_NEW_TABLE_COMMAND } from '.'
import { ElementsGroup, useElementsMenuItem } from '../../ui/ElementsMenu/ElementsMenuContext'

export function useTableElements() {
  const [editor] = useLexicalComposerContext()
  useElementsMenuItem({
    identifier: 'table',
    title: 'Table',
    icon: <Table />,
    weight: 30,
    group: ElementsGroup.Layout,
    noReplace: true,
    isSelected: $isTableNode,
    onCommit(element) {
      element.remove()
      editor.dispatchCommand(INSERT_NEW_TABLE_COMMAND, { columns: '3', rows: '3' })
    },
  })
}
