import { useTheme, Avatar, getColor, AntIconWrapper } from '@thesisedu/react'
import { Star } from '@thesisedu/react/icons'
import React from 'react'

import { UserFragment } from '../schema'

export interface StudentAvatarProps {
  isTeacher?: boolean
  user: Pick<UserFragment, 'name' | 'username'>
  isHighlighted?: boolean
  size?: number
  style?: any
}
export function StudentAvatar({
  isTeacher,
  user,
  isHighlighted,
  size = 32,
  style,
}: StudentAvatarProps) {
  const theme = useTheme()
  const name = (user.name || user.username)
    .split(' ')
    .map(item => item[0])
    .join('')
  if (isTeacher) {
    return (
      <Avatar
        size={size}
        style={
          isHighlighted
            ? { ...style, background: getColor(theme, '@primary-color') }
            : { ...style, background: getColor(theme, '@text-color-secondary') }
        }
        shape={'square'}
        icon={<AntIconWrapper children={<Star />} />}
      />
    )
  } else {
    return (
      <Avatar
        size={size}
        style={isHighlighted ? { ...style, background: getColor(theme, '@primary-color') } : style}
        shape={'square'}
      >
        {name}
      </Avatar>
    )
  }
}
