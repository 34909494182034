import { useModifiedFragmentDocument } from '@thesisedu/feature-apollo-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import {
  bakeCourseConfiguration,
  combineSegmentMetadata,
  findSegment,
  orderSegments,
} from '@thesisedu/feature-course-types'
import { Dropdown, useToast } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import { cloneDeep } from 'lodash'
import React from 'react'

import { debug, warn } from '../../log'
import {
  useCopySegmentAsOwnMutation,
  ClassCourseFragment,
  ClassCourseFragmentDoc,
} from '../../schema'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function CopySegmentAsOwn({ segment }: SegmentActionComponentProps) {
  const classId = useSelectedClassId(true)
  const toast = useToast()

  const modified = {
    ClassCourseFragmentDoc: useModifiedFragmentDocument(ClassCourseFragmentDoc),
  }

  const [copySegmentAsOwn, { loading }] = useCopySegmentAsOwnMutation({
    update: (proxy, { data }) => {
      const updateClass = cloneDeep(data?.copySegmentAsOwn.class)
      if (!updateClass) return

      const fragment = cloneDeep(
        proxy.readFragment<ClassCourseFragment>({
          fragment: modified.ClassCourseFragmentDoc,
          fragmentName: 'ClassCourse',
          id: `Class:${updateClass.id}`,
        }),
      )
      if (fragment) {
        const newParent = data?.copySegmentAsOwn.bakedConfigurationParent
        if (!newParent) throw new Error('Error: new parent was not returned from server')
        const parentSegment = findSegment(fragment.bakedCourseConfiguration.segments, newParent.id)
        if (!parentSegment) throw new Error('Error: parent segment was not found')
        Object.assign(parentSegment, newParent)

        const segmentMetadata =
          (updateClass as ClassCourseFragment).segmentMetadata || fragment.segmentMetadata
        const configuration = {
          ...fragment.bakedCourseConfiguration, // TODO: this might break and we might need to go back to just courseConfiguration
          segments: combineSegmentMetadata(
            (segmentMetadata || {}).segments,
            fragment.bakedCourseConfiguration.segments,
          ),
          overrides: segmentMetadata.overrides,
        }
        const baked = bakeCourseConfiguration(configuration)
        const bakedCourseConfiguration = {
          ...baked,
          segments: orderSegments(baked.segments || []),
        }

        debug('new bakedCourseConfiguration after updating class')
        debug(bakedCourseConfiguration)
        proxy.writeFragment({
          fragment: modified.ClassCourseFragmentDoc,
          fragmentName: 'ClassCourse',
          id: `Class:${updateClass.id}`,
          data: {
            ...fragment,
            ...updateClass,
            segmentMetadata,
            bakedCourseConfiguration,
          },
        })
      }
    },
  })
  if (segment.config?.isCopyable) {
    return (
      <Dropdown.Item
        icon={<Copy />}
        loading={loading}
        onClick={async e => {
          e.preventDefault()
          const updateToast = toast({ loading: true, title: `Copying ${segment.name}...` })
          try {
            await copySegmentAsOwn({
              variables: {
                input: {
                  id: classId || '',
                  segmentId: segment.id,
                },
              },
            })
            updateToast({
              loading: false,
              title: `${segment.name} Copied!`,
              status: 'success',
            })
          } catch (err) {
            warn('error while copying segment %O', err)
            updateToast({
              loading: false,
              title: `Could not copy ${segment.name}`,
              status: 'error',
            })
          }
        }}
        children={loading ? 'Copying...' : 'Create a copy'}
      />
    )
  } else {
    return null
  }
}

export const CopySegmentAsOwnAction: SegmentActionResource = {
  identifier: 'copy-segment-as-own',
  type: 'SegmentAction',
  component: CopySegmentAsOwn,
  weight: 400,
  group: 'My Content',
  handler: () => {},
  filter(segment, context) {
    return !!segment.config?.isCopyable
  },
}
