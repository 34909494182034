import { Button, Calendar as AntCalendar, CalendarProps as AntCalendarProps } from 'antd'
import { NavArrowLeft, NavArrowRight } from 'iconoir-react'
import moment from 'moment'
import React from 'react'

import { AntIconWrapper } from './AntIconWrapper'
import { LoadingIndicator } from './LoadingIndicators'
import { BodyLarge, FontWeight } from './Typography'
import { styled } from './styledTypes'

export interface CalendarProps extends AntCalendarProps<moment.Moment> {
  loading?: boolean
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
}
export function Calendar({ loading, headerLeft, headerRight, ...rest }: CalendarProps) {
  return (
    <Container>
      <AntCalendar
        {...rest}
        headerRender={({ value, onChange }) => {
          const previousMonth = value.clone().subtract(1, 'month').format('MMMM YYYY')
          const nextMonth = value.clone().add(1, 'month').format('MMMM YYYY')
          const currentMonth = value.clone().format('MMMM YYYY')
          return (
            <HeaderContainer>
              <div>{headerLeft}</div>
              <HeaderRight>
                {loading ? (
                  <SpinContainer>
                    <LoadingIndicator />
                  </SpinContainer>
                ) : null}
                <BodyLarge
                  weight={FontWeight.Bold}
                  style={{ display: 'inline-block', marginRight: '1em' }}
                >
                  {currentMonth}
                </BodyLarge>
                <Button.Group>
                  <Button
                    icon={<AntIconWrapper children={<NavArrowLeft />} />}
                    onClick={() => onChange(value.clone().subtract(1, 'month'))}
                  >
                    {previousMonth}
                  </Button>
                  <Button onClick={() => onChange(value.clone().add(1, 'month'))}>
                    {nextMonth} <AntIconWrapper children={<NavArrowRight />} />
                  </Button>
                </Button.Group>
                {headerRight}
              </HeaderRight>
            </HeaderContainer>
          )
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  .ant-picker-calendar-date {
    height: 150px !important;
    cursor: default;
    pointer-events: none;
    background: transparent !important;
  }
  .ant-picker-calendar-date-value {
    color: ${props => props.theme['@text-color-secondary']} !important;
  }
  .ant-picker-cell {
    pointer-events: none;
    cursor: default;
  }
  .ant-picker-calendar-date-content {
    height: 120px !important;
    pointer-events: all;
    padding-bottom: ${props => props.theme['@size-xxs']};
  }
`
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${props => props.theme['@size-m']};
`
const HeaderRight = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  > div:not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
const SpinContainer = styled.div`
  margin-right: ${props => props.theme['@size-m']} !important;
  font-size: ${props => props.theme['@size-s']};
`
