import { LoadingIndicator, NotFound, Result } from '@thesisedu/react'
import React from 'react'

import { useSelectedClassId } from './ClassContext'
import { ClassFragment, StudentClassFragment } from './schema'
import { useStudentClass, useTeacherClass } from './useClass'

export interface WithTeacherClassChildProps<F extends ClassFragment = ClassFragment> {
  cls: F
}
export interface WithTeacherClassProps<F extends ClassFragment = ClassFragment> {
  classId?: string | null
  selected?: boolean
  render: (props: WithTeacherClassChildProps<F>) => React.ReactElement | null
}
export function WithTeacherClass<F extends ClassFragment = ClassFragment>({
  classId,
  selected,
  render: Component,
}: WithTeacherClassProps<F>) {
  const selectedClassId = useSelectedClassId()
  const { cls, error, loading } = useTeacherClass<F>(
    selected ? selectedClassId : classId || '',
    false,
  )
  if (classId === undefined && !selected) return null
  if (cls) {
    return <Component cls={cls} />
  } else if (loading) {
    return <LoadingIndicator block />
  } else if (error) {
    return <Result.Error description={'There was an error loading your class.'} />
  } else {
    return <NotFound description={'That class could not be found.'} />
  }
}

export interface WithStudentClassChildProps<F extends StudentClassFragment = StudentClassFragment> {
  cls: F
}
export interface WithStudentClassProps<F extends StudentClassFragment = StudentClassFragment> {
  classId?: string | null
  selected?: boolean
  render: (props: WithStudentClassChildProps<F>) => React.ReactElement | null
}
export function WithStudentClass<F extends StudentClassFragment = StudentClassFragment>({
  classId,
  selected,
  render: Component,
}: WithStudentClassProps<F>) {
  const selectedClassId = useSelectedClassId()
  if (classId === undefined && !selected)
    throw new Error('You must pass either selected or classId to WithStudentClass')
  const { cls, error, loading } = useStudentClass<F>(selected ? selectedClassId : classId!, false)
  if (cls) {
    return <Component cls={cls} />
  } else if (loading) {
    return <LoadingIndicator block />
  } else if (error) {
    return <Result.Error description={'There was an error loading your class.'} />
  } else {
    return <NotFound description={'That class could not be found.'} />
  }
}
