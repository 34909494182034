import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, Popconfirm } from 'antd'
import React from 'react'

export interface DeleteCourseButtonProps {
  courseId: string
}
export function DeleteCourseButton({ courseId }: DeleteCourseButtonProps) {
  const [deleteCourse, { loading }] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that course.',
  })

  return (
    <Popconfirm
      title={'Are you sure?'}
      placement={'topLeft'}
      okText={'Yes, delete course.'}
      okButtonProps={{ danger: true }}
      cancelText={'No, keep.'}
      onConfirm={() => {
        return deleteCourse({
          variables: {
            id: courseId,
          },
        })
      }}
    >
      <Button type={'link'} danger loading={loading}>
        Delete
      </Button>
    </Popconfirm>
  )
}
