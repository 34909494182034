import { Dropdown } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { MoveStudentModal } from '../../teacher/MoveStudentModal'
import {
  StudentActionConfigureProps,
  StudentActionProps,
  StudentActionResource,
} from '../../teacher/students/StudentActionsDropdown'

function MoveStudentConfigure({ students, onClose }: StudentActionConfigureProps) {
  if (students.length !== 1) return null
  return <MoveStudentModal students={students} visible onClose={onClose} />
}

export function MoveStudentAction({ students, onConfigure }: StudentActionProps) {
  if (students.length !== 1) return null
  return (
    <>
      <Dropdown.Item icon={<ArrowRight />} onClick={onConfigure}>
        Move to another class
      </Dropdown.Item>
    </>
  )
}

export default {
  type: 'StudentAction',
  identifier: 'MoveStudent',
  weight: 1,
  render: MoveStudentAction,
  renderConfigure: MoveStudentConfigure,
} satisfies StudentActionResource
