import { useResource } from '@thesisedu/feature-react'
import { Block, Button, HSpaced, Text, s, styled } from '@thesisedu/ui'
import { CloudSync } from '@thesisedu/ui/icons'
import React from 'react'

import { SyncAssignmentItemActionsDropdown } from './SyncAssignmentItemActionsDropdown'
import { SyncAssignmentItemSummary } from './SyncAssignmentItemSummary'
import { ClassSyncProviderResource } from '../types'
import { SyncAssignmentActionItem } from '../useSyncAssignmentActions'

export interface SyncAssignmentItemProps {
  item: SyncAssignmentActionItem
  onSelected: () => void
  disabled?: boolean
  loading?: boolean
}
export function SyncAssignmentItem({
  loading,
  disabled,
  item,
  onSelected,
}: SyncAssignmentItemProps) {
  const provider = useResource<ClassSyncProviderResource>(
    'ClassSyncProvider',
    item.providerIdentifier,
  )
  return (
    <HSpaced>
      <Logo
        src={provider.logos.square}
        alt={`${item.providerName} logo`}
        style={{ opacity: item.isSynced ? undefined : 0.6 }}
      />
      <div style={{ opacity: item.isSynced ? undefined : 0.6 }}>
        <Text truncate>{item.classNameOnProvider}</Text>
        <SyncAssignmentItemSummary item={item} />
      </div>
      <Block left style={{ flexShrink: 0 }}>
        {item.isSynced ? (
          <SyncAssignmentItemActionsDropdown item={item} />
        ) : (
          <Button
            children={'Sync'}
            icon={<CloudSync />}
            size={'small'}
            onPress={() => onSelected()}
            disabled={disabled}
            loading={loading}
          />
        )}
      </Block>
    </HSpaced>
  )
}

export const Logo = styled.img`
  background: ${s.color('element')};
  border-radius: ${s.var('radii.1')};
  overflow: hidden;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
`
