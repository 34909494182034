import { Button, VSpaced } from '@thesisedu/ui'
import { Refresh } from '@thesisedu/ui/icons'
import { PageHead, useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useSelectedClass } from '../../ClassContext'
import { ClassApprovalPage, ClassApprovalPageRef } from '../../approval/ClassApprovalPage'
import { ClassApprovalStateSelect } from '../../approval/ClassApprovalStateSelect'
import { PendingStudentClassState } from '../../schema'

export function StudentsApprovalRoute() {
  const cls = useSelectedClass(true)
  useBreadcrumb([{ title: 'Students', relativeHref: '..' }, { title: 'Join Requests' }])
  const { state } = useParams()
  const navigate = useNavigate()
  const approvalPageRef = React.useRef<ClassApprovalPageRef>(null)
  const [refreshing, setRefreshing] = React.useState(false)
  return (
    <>
      <PageHead title={`Join Requests - ${cls.name}`} />
      <PageHeader title={'Join Requests'}>
        <Button
          loading={refreshing}
          icon={<Refresh />}
          aria-label={'Refresh'}
          onPress={async () => {
            setRefreshing(true)
            try {
              await approvalPageRef.current?.refresh()
            } finally {
              setRefreshing(false)
            }
          }}
        />
        <ClassApprovalStateSelect
          state={state?.toUpperCase() as PendingStudentClassState | undefined}
          onStateChange={newState => {
            navigate(
              state
                ? window.location.pathname.replace(state, (newState ?? '').toLowerCase())
                : `${window.location.pathname}/${(newState ?? '').toLowerCase()}`,
            )
          }}
        />
      </PageHeader>
      <VSpaced top={'m'}>
        <ClassApprovalPage
          state={state?.toUpperCase() as PendingStudentClassState | undefined}
          classId={cls.id}
          ref={approvalPageRef}
        />
      </VSpaced>
    </>
  )
}
