import { PageHead } from '@thesisedu/react'
import { Packages } from '@thesisedu/react/icons'
import { PageHeader } from '@thesisedu/web'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'
import { ExploreGrid } from '../../explore/grid'
import { SegmentType } from '../../schema'
import { ExploreLookingForResource } from '../../types'

function Lessons() {
  return (
    <>
      <PageHead title={'Lessons - Explore'} />
      <PageHeader title={'Lessons'} />
      <ExploreGrid
        searchPlaceholder={'Search Lessons'}
        variables={{
          types: [SegmentType.Group],
          labels: ['Lesson'],
        }}
      />
    </>
  )
}

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<ExploreLookingForResource>({
    type: 'ExploreLookingFor',
    identifier: 'lessons',
    title: 'Lessons',
    icon: <Packages />,
    weight: 1000,
    page: <Lessons />,
  })
}
