export const FILENAME_SEPARATOR = '##-##'

export interface FileInfo {
  filename: string
  label?: string
}
export function getFileInfo(filename?: string): FileInfo {
  const components = (filename || '').split(FILENAME_SEPARATOR)
  return {
    filename: components[components.length - 1] || '',
    label: components.length > 1 ? components[0] : undefined,
  }
}

export function mergeFileInfo(filename: string, label?: string) {
  return [label, filename].filter(Boolean).join(FILENAME_SEPARATOR)
}
