import { PlusOutlined } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import React, { useState } from 'react'

import { CreateClassModal } from './CreateClassModal'

export const CreateClassButton: React.FC<React.PropsWithChildren<Partial<ButtonProps>>> = props => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CreateClassModal visible={visible} onClose={() => setVisible(false)} />
      <Button
        icon={<PlusOutlined />}
        type={'primary'}
        children={'Create Class'}
        {...props}
        onClick={() => setVisible(true)}
      />
    </>
  )
}
