import { ArrowLeftOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'

import { Block } from './Block'
import { H1, H1Subtitle } from './Typography'
import { media } from './responsive'

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme['@size-s']};
`
const Icon = styled(ArrowLeftOutlined)`
  font-size: ${props => props.theme['@size-m']};
  color: ${props => props.theme['@gray-5']};
  margin-right: ${props => props.theme['@size-s']};
  cursor: pointer;
  transition: color 0.1s linear;
  &:hover {
    color: ${props => props.theme['@gray-7']};
  }
`
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme['@PageHeader-topMargin'] || props.theme['@size-s']};
  margin-left: auto;
  justify-content: flex-start;
  ${media.md} {
    justify-content: flex-end;
    margin-top: ${props => props.theme['@PageHeader-topMargin'] || 0};
  }
  > button:not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
const Container = styled.div`
  display: flex;
  align-items: center;
`

export interface PageHeaderProps {
  title: string | React.ReactElement
  subtitle?: string
  onBack?: () => void
}
export const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = ({
  title,
  onBack,
  children,
  subtitle,
}) => {
  return (
    <Block marginBottom>
      <Container>
        <TitleContainer>
          {onBack ? <Icon onClick={onBack} /> : null}
          <div>
            <H1 isBlock={false}>{title}</H1>
            {subtitle ? <H1Subtitle isBlock={false}>{subtitle}</H1Subtitle> : null}
          </div>
        </TitleContainer>
        <ActionsContainer>{children}</ActionsContainer>
      </Container>
    </Block>
  )
}
