import { useResource } from '@thesisedu/feature-react'
import { NavArrowRight } from '@thesisedu/react/icons'
import { Space, styled, useMeasureItem } from '@thesisedu/web'
import React from 'react'

import { AllMetricSummariesFragment } from '../../../execute/types'
import { ReportDimensionTableHeaderProps, ReportMetricResource } from '../../../types'
import { useReportGridContext } from '../Context'
import { MetricRowHeader } from '../MetricRowHeader'
import { MetricSummaries } from '../MetricSummaries'
import { isMetrics } from '../axes'
import { useDimensionGetHeaderCallbacks } from '../dimensions'
import { getMetricSummaryFromOpts } from '../metrics'

export interface RowHeaderInfo {
  metricName: string | undefined
  summaryKey: keyof AllMetricSummariesFragment
  summary: AllMetricSummariesFragment
  header: React.ReactElement | null
  label: string
  depth?: number
}
export type RowHeaderInfoFn = (rawIndex: number) => RowHeaderInfo
export function useGetRowHeaderInfo(): RowHeaderInfoFn {
  const { responsibility, data, flatSummaryRows, expandedRowIds, setExpandedRowIds, opts } =
    useReportGridContext(true)
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  const headerCallbacks = useDimensionGetHeaderCallbacks()
  return rawIndex => {
    let metricName: string | undefined = undefined
    let summaryKey: keyof AllMetricSummariesFragment = getMetricSummaryFromOpts(
      opts,
      metricResources,
    )
    let summary
    let header
    let label
    let depth: number | undefined = undefined
    if (isMetrics(responsibility.row)) {
      const metricIndex = rawIndex - 1
      const resource = responsibility.row[metricIndex]
      metricName = resource.identifier
      summaryKey = resource.summarization
      summary = data.allMetrics
      header = <MetricRowHeader metric={resource} />
      label = resource.title
    } else if (flatSummaryRows?.[rawIndex - 1]) {
      const summaryRow = flatSummaryRows[rawIndex - 1]
      summary = summaryRow.summaries
      const Header = responsibility.row.summary?.renderTableHeader
      depth = summaryRow.depth
      const headerProps: ReportDimensionTableHeaderProps<any> = {
        result: summaryRow.item,
        type: 'row',
        depth: summaryRow.depth,
      }
      const labelCallback = headerCallbacks[responsibility.row.identifier].summary
      label = labelCallback ? labelCallback(headerProps) : ''
      if (Header) {
        header = (
          <>
            {summaryRow.depth ? (
              <DepthSpacer depth={summaryRow.depth} hasChildren={!!summaryRow.children?.length} />
            ) : null}
            {summaryRow.children?.length ? (
              <RowHeaderCellExpand
                expanded={expandedRowIds.includes(summaryRow.id)}
                onChange={expanded =>
                  setExpandedRowIds(current => {
                    const without = current.filter(i => i !== summaryRow.id)
                    return expanded ? [...without, summaryRow.id] : without
                  })
                }
              />
            ) : null}
            <Header {...headerProps} />
          </>
        )
      } else {
        header = null
      }
    } else {
      const dimensionSummary = data.dimensionSummaries[rawIndex - 1]
      if (!dimensionSummary) throw new Error(`Cannot find dimensionSummary at index ${rawIndex}`)
      summary = dimensionSummary.metrics
      const dimensionResult = (dimensionSummary.dimensions as Record<string, any>)[
        responsibility.row.inputKey
      ]
      const headerProps: ReportDimensionTableHeaderProps<any> = {
        result: dimensionResult,
        type: 'row',
        depth: undefined,
      }
      const Header = responsibility.row.renderTableHeader
      const labelCallback = headerCallbacks[responsibility.row.identifier].resource
      label = labelCallback ? labelCallback(headerProps) : ''
      if (Header) {
        header = <Header {...headerProps} />
      } else {
        header = null
      }
    }

    return {
      header,
      depth,
      label,
      summary,
      metricName,
      summaryKey,
    }
  }
}

export interface RowHeaderCellProps {
  /** The 0-based index of the row, including the row headers. */
  rawIndex: number
  visibleIndex: number
  style: any
}
export function RowHeaderCell({ rawIndex, visibleIndex, style }: RowHeaderCellProps) {
  const { responsibility, visibleRows, measureCache } = useReportGridContext(true)
  const getInfo = useGetRowHeaderInfo()
  const { header, summary, summaryKey, metricName } = getInfo(rawIndex)
  const containerRef = useMeasureItem(measureCache, rawIndex.toString())
  const isLast = visibleIndex === visibleRows
  const columnsAreMetrics = isMetrics(responsibility.col)
  return (
    <RowHeaderCellContainer isLast={isLast} style={style} ref={containerRef}>
      {header}
      <Space />
      {columnsAreMetrics ? null : (
        <MetricSummaries display={summaryKey} summaries={summary} metric={metricName} isRow />
      )}
    </RowHeaderCellContainer>
  )
}
const RowHeaderCellContainer = styled.div<{ isLast?: boolean }>`
  background: ${props => props.theme['@gray-1']};
  padding: ${props => props.theme['@size-xs']};
  border-bottom: ${props =>
    props.isLast ? 'none' : `solid 1px ${props.theme['@border-color-base']}`};
  border-right: solid 1px ${props => props.theme['@border-color-base']};
  display: flex;
  flex-direction: Row;
  align-items: stretch;
  justify-content: flex-start;
`
const DEPTH_SPACING_PX = 12
const DepthSpacer = styled.div<{ depth: number; hasChildren?: boolean }>`
  height: 1px;
  width: ${props => props.depth * DEPTH_SPACING_PX}px;
  margin-right: ${props => (props.hasChildren ? 0 : props.theme['@size-xs'])};
  flex-shrink: 0;
`

interface RowHeaderCellExpandProps {
  expanded?: boolean
  onChange: (expanded: boolean) => void
}
function RowHeaderCellExpand({ expanded, onChange }: RowHeaderCellExpandProps) {
  return (
    <ExpandedContainer expanded={expanded} onClick={() => onChange(!expanded)}>
      <NavArrowRight />
    </ExpandedContainer>
  )
}
const ExpandedContainer = styled.div<{ expanded?: boolean }>`
  transition: transform 0.25s cubic-bezier(0.16, 1, 0.3, 1);
  color: ${props => props.theme['@text-color-secondary']};
  margin: -${props => props.theme['@size-xxs']};
  margin-right: ${props => props.theme['@size-xxs']};
  transform: rotate(${props => (props.expanded ? 90 : 0)}deg);
  padding: ${props => props.theme['@size-xxs']};
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
  }
`
