import {
  ClassSelectorList,
  MultiStudentSelectButton,
  StudentFilterButton,
} from '@thesisedu/feature-classes-react'
import { s, Form, Text, VisuallyHidden } from '@thesisedu/ui'
import React from 'react'

import { AssignmentModeSelect } from '../AssignmentModeSelect'
import { AssignInput, AssignmentStudentAssignmentMode } from '../schema'

export interface AssignFieldProps extends AssignFieldsProps {
  value?: AssignInput
  onChange?: (value: AssignInput) => void
}
export function AssignField({ value, onChange, classId, assignmentMode }: AssignFieldProps) {
  if (assignmentMode === AssignmentStudentAssignmentMode.AllStudents && !classId) {
    return (
      <>
        <Text level={'s'} bottom color={'secondary'}>
          Select classes below to assign to all students in those classes.
        </Text>
        <ClassSelectorList
          value={value?.assignedClasses?.filter(Boolean) as string[] | undefined}
          onChange={selectedClasses => {
            if (onChange) {
              onChange({
                assignedClasses: selectedClasses,
              })
            }
          }}
        />
      </>
    )
  } else if (assignmentMode === AssignmentStudentAssignmentMode.SpecificStudents && classId) {
    return (
      <MultiStudentSelectButton
        classId={classId}
        value={value?.assignedStudents?.map(s => s.studentId)}
        onChange={studentIds => {
          if (onChange) {
            onChange({
              assignedStudents: studentIds?.map(studentId => ({ studentId, classId })),
            })
          }
        }}
      />
    )
  } else if (assignmentMode === AssignmentStudentAssignmentMode.SpecificStudents && !classId) {
    return (
      <StudentFilterButton
        emptyLabel={'Select Students'}
        saveText={'Assign'}
        valuePrefix={'Assigned to'}
        type={'default'}
        value={value?.assignedStudents?.filter(Boolean)}
        onChange={pairs => {
          if (onChange) {
            onChange({
              assignedStudents: pairs,
            })
          }
        }}
      />
    )
  } else {
    return null
  }
}

export interface AssignFieldsProps {
  classId?: string
  assignmentMode?: AssignmentStudentAssignmentMode
}
export function AssignFields({ classId, assignmentMode }: AssignFieldsProps) {
  const shouldHideAssign = classId && assignmentMode === AssignmentStudentAssignmentMode.AllStudents
  return (
    <>
      <Form.Field
        name={'assignmentMode'}
        label={'For'}
        style={shouldHideAssign ? undefined : { marginBottom: s.size('xs') }}
      >
        <AssignmentModeSelect />
      </Form.Field>
      {shouldHideAssign ? (
        <Form.Field name={'assign'} initialValue={{ assignedClasses: [classId] } as AssignInput}>
          <input type={'hidden'} />
        </Form.Field>
      ) : (
        <Form.Field name={'assign'}>
          <AssignField classId={classId} assignmentMode={assignmentMode} />
        </Form.Field>
      )}
    </>
  )
}

export function HiddenAssignField() {
  return (
    <VisuallyHidden>
      <Form.Field name={'assignmentMode'} aria-label={'Assignment Mode'}>
        <input type={'hidden'} />
      </Form.Field>
    </VisuallyHidden>
  )
}
