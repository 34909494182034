import { AuthRoutesHook, AuthRedirecting } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { SchoologyReactFeature } from '../../SchoologyReactFeature'

export default function (feature: SchoologyReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [
        ...routes,
        <Route
          key={'schoology-redirect'}
          path={'schoology-redirect'}
          element={<AuthRedirecting />}
        />,
      ]
    },
  )
}
