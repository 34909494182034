import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { ScheduledSegmentWatcher } from './ScheduledSegmentWatcher'
import { CourseContextProvider } from './contexts/CourseContext'
import { SegmentDaysContextProvider } from './contexts/DaysContext'
import { EnableForStudentsContextProvider } from './contexts/EnableForStudentsContext'
import { SegmentContextProvider } from './contexts/SegmentContext'
import { SegmentDecorationsContextProvider } from './contexts/SegmentDecorationsContext'
import { SegmentStatusContextProvider } from './contexts/SegmentStatusContext'
import { ClassCourseFragment } from './schema'

export interface CoursesContextProviderProps {
  class: ClassCourseFragment
}
export function CoursesContextProvider({
  children,
  class: cls,
}: React.PropsWithChildren<CoursesContextProviderProps>) {
  const viewer = useViewerContext(false)
  const withContexts = (
    <>
      <ScheduledSegmentWatcher class={cls} />
      <SegmentDecorationsContextProvider classId={cls.id}>
        <SegmentContextProvider courseConfiguration={cls.bakedCourseConfiguration}>
          <SegmentDaysContextProvider courseConfiguration={cls.bakedCourseConfiguration}>
            <CourseContextProvider class={cls}>
              <SegmentStatusContextProvider class={cls}>
                <EnableForStudentsContextProvider>{children}</EnableForStudentsContextProvider>
              </SegmentStatusContextProvider>
            </CourseContextProvider>
          </SegmentDaysContextProvider>
        </SegmentContextProvider>
      </SegmentDecorationsContextProvider>
    </>
  )
  if (viewer?.group === 'STUDENT' || viewer?.group === 'TEACHER') {
    return withContexts
  } else {
    return <>{children}</>
  }
}
