import { Format } from '@thesisedu/feature-utils'
import { LoadableStatistic } from '@thesisedu/web'
import React from 'react'

import { useClassGradeQuery } from '../schema'

export interface ClassGradeProps {
  classId: string
}
export function ClassGrade({ classId }: ClassGradeProps) {
  const { data, loading } = useClassGradeQuery({
    variables: { id: classId },
    fetchPolicy: 'cache-and-network',
  })
  const grade = data?.node?.__typename === 'Class' ? data.node.averageGrade : undefined
  return (
    <LoadableStatistic
      title={'Grade'}
      value={grade !== undefined && grade !== null ? grade : 'N/A'}
      loading={loading}
      formatter={val => (val !== 'N/A' ? Format.number(grade, 'decimalPercentage') : 'N/A')}
    />
  )
}
