import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { H1 } from './Typography'
import { styled } from './styledTypes'

export interface SplitHeaderProps {
  smallText: any
  largeText: any
  actionName?: string
  actionIcon?: React.ReactElement
  actionHref?: string
  back?: string
  style?: any
}
export function SplitHeader({
  smallText,
  largeText,
  actionName,
  actionHref,
  actionIcon,
  back,
  style,
  children,
}: React.PropsWithChildren<SplitHeaderProps>) {
  const navigate = useNavigate()
  const [entered, setEntered] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setEntered(true)
    }, Math.random() * 500)
  }, [])

  let actionLink
  if (actionHref) {
    actionLink = <Link to={actionHref}>{actionName} &rarr;</Link>
  }
  if (actionIcon) {
    actionLink = (
      <Button type={'primary'} icon={actionIcon} onClick={() => navigate(actionHref!)}>
        {actionName}
      </Button>
    )
  }

  return (
    <Container style={style}>
      <H1 className={`title ${entered ? 'entered' : ''} ${back ? 'with-back' : ''}`}>
        <span className={'small'}>{smallText}</span>
        <span>
          {back && (
            <Link to={back} className={'back'}>
              <ArrowLeftOutlined /> Back
            </Link>
          )}
          {largeText}
        </span>
      </H1>
      {children ? children : actionHref && actionName && actionLink}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;
  > a,
  > button {
    margin: 0 0 10px 10px;
  }
  .title {
    position: relative;
    transition:
      left 0.25s ease-out,
      opacity 0.25s ease-out;
    left: -20px;
    opacity: 0;
    margin-bottom: 0;
    margin-right: auto;
    line-height: 1;
    > span {
      display: flex;
      align-items: center;
      > a {
        margin-right: 10px;
        color: ${props => props.theme['@text-color']};
        font-size: 14px;
        display: flex;
        align-items: center;
        font-weight: normal;
        letter-spacing: 0;
        line-height: 1;
        > span[role='img'] {
          margin-right: 3px;
        }
      }
    }
    > .small {
      margin: 10px 0 0 0;
      display: block;
      font-size: 18px;
      font-weight: normal;
      line-height: 1.2;
      position: relative;
      transition: left 0.25s ease-out;
      letter-spacing: -0.5px;
      left: 10px;
      color: ${props => props.theme['@text-color-secondary']};
    }
    &.entered {
      left: 0;
      opacity: 1;
      > .small {
        left: 0;
      }
    }
    &.with-back > .small {
      margin-left: 56px;
    }
  }
`
