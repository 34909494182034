import { Format } from '@thesisedu/feature-utils'
import { Text } from '@thesisedu/ui'
import React from 'react'

import { useSplitViewOutlineItemContext } from './SplitViewOutlineItemContext'
import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { isItemWithSegment } from '../outline/types'
import { SegmentType } from '../schema'

function formatAdditions(updates: Record<string, number>) {
  return Object.keys(updates)
    .reduce<string[]>((acc, key) => {
      return [...acc, Format.plural(key, updates[key])]
    }, [])
    .join(', ')
}

export function SplitViewPendingChangesSummary() {
  const updates: Record<string, number> = {}
  const { items } = useSplitViewOutlineItemContext(true)
  const { addedSegmentIds, changedSegmentIds, changes } = useSplitViewPendingChangesContext(true)

  let hasAdded = false
  for (const override of changes.structureOverrides) {
    const item = items.current[override.id]
    const segment = item && isItemWithSegment(item) ? item.segment : null
    if (item && !addedSegmentIds.includes(item.id)) continue
    hasAdded = true
    if (segment?.type === SegmentType.Topic) {
      updates['topic'] = (updates['topic'] ?? 0) + 1
    } else if (segment?.type === SegmentType.Group) {
      const label = (segment.config?.label ?? 'Group').toLowerCase()
      updates[label] = (updates[label] ?? 0) + 1
    } else {
      updates[segment?.type ?? 'other item'] = (updates[segment?.type ?? 'other item'] ?? 0) + 1
    }
  }

  const addedItems = addedSegmentIds.length
  const movedItems = changedSegmentIds.length
  let child
  if (!addedItems && !movedItems) {
    child = <Text level={'s'}>No changes</Text>
  } else {
    child = (
      <Text level={'s'}>
        {hasAdded ? (
          <>
            <Text level={null} weight={'bold'} color={'green'} inline>
              Added {formatAdditions(updates)}
            </Text>
            {movedItems ? ', ' : ''}
          </>
        ) : null}
        {movedItems ? (
          <>
            <Text level={null} weight={'bold'} color={'blue'} inline>
              {movedItems} moved
            </Text>{' '}
            {Format.plural('item', movedItems, { hideCount: true })}
          </>
        ) : null}
      </Text>
    )
  }
  return (
    <div>
      <Text level={'xs'} color={'secondary'}>
        Summary
      </Text>
      {child}
    </div>
  )
}
