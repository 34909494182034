import { useMutateHook } from '@thesisedu/feature-react'
import { LoadingIndicator, message, Block, Button, NotFound, PageHead } from '@thesisedu/react'
import { NavArrowDown } from '@thesisedu/react/icons'
import { useBreadcrumb, PageHeader, Row } from '@thesisedu/web'
import { Col, Dropdown, Menu } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'

import { DistrictForm } from '../../DistrictForm'
import { useDistrictQuery } from '../../schema'
import { EditDistrictActionsHook, EditDistrictRightContentHook } from '../../types'
import { useEditDistrictForm } from '../../useEditDistrictForm'

export interface DistrictNameProps {
  districtId: string
}
export function DistrictName({ districtId }: DistrictNameProps) {
  const { data } = useDistrictQuery({ variables: { id: districtId } })
  return <span>{data?.node?.__typename === 'District' ? data.node.name : '...'}</span>
}

export function EditDistrict() {
  const { districtId } = useParams()
  const { data, loading } = useDistrictQuery({ variables: { id: districtId } })
  const districtFormData = useEditDistrictForm(districtId)
  useBreadcrumb({ title: <DistrictName districtId={districtId} /> })
  const saveData = async () => {
    await Promise.all([districtFormData.submit()])
    message.success('District updated!')
    window.history.back()
  }
  const rightContent = useMutateHook<EditDistrictRightContentHook>(
    'districts-web:right-content',
    [],
    { districtId },
  )
  const actionsContent = useMutateHook<EditDistrictActionsHook>('districts-web:edit-actions', [], {
    districtId,
  })
  if (loading) {
    return <LoadingIndicator block />
  } else if (data?.node && data.node.__typename === 'District') {
    return (
      <>
        <PageHead title={'Edit District'} />
        <Block marginBottom={'@size-l'}>
          <PageHeader title={data.node.name} onBack={() => window.history.back()}>
            {actionsContent.length ? (
              <Dropdown arrow placement={'bottomRight'} overlay={<Menu>{actionsContent}</Menu>}>
                <Button>
                  Other Actions <NavArrowDown />
                </Button>
              </Dropdown>
            ) : null}
            <Button type={'primary'} onClick={saveData} loading={districtFormData.isLoading}>
              Save Changes
            </Button>
          </PageHeader>
        </Block>
        <Row>
          <Col xs={24} lg={12} xl={8}>
            <DistrictForm
              form={districtFormData.form}
              onFinish={saveData}
              districtId={districtId}
            />
          </Col>
          <Col xs={24} lg={12} xl={16}>
            {rightContent}
          </Col>
        </Row>
      </>
    )
  } else {
    return <NotFound description={'The district could not be found.'} />
  }
}
