import { useCachedStudentUserCallback } from '@thesisedu/feature-classes-react'
import { inferGraphContext, ReportDimensionResource } from '@thesisedu/feature-reports-react'
import { User } from '@thesisedu/react/icons'
import React from 'react'

import {
  StudentsOptionsLabel,
  StudentsOptionsContext,
  StudentsOptionsWrapper,
} from './MultipleStudents'
import { StudentHeader, useGetStudentHeader } from './components/StudentHeader'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import {
  StudentsReportDimensionInput,
  StudentsReportDimensionResultFragment,
  StudentsReportDimensionResultFragmentDoc,
} from '../../schema'

export const studentGraphOptions = inferGraphContext({
  useContext: () => {
    const getStudent = useCachedStudentUserCallback()
    return { getStudent }
  },
  label: (item: StudentsReportDimensionResultFragment, context) => {
    const studentId = item.student.id
    const classId = item.class.id
    if (studentId) {
      const student = context.getStudent(studentId, classId)
      if (student) {
        return student.user.name || student.user.username || 'Student'
      } else return 'Student'
    } else return null
  },
})

export default function addDimension(feature: CourseReportsReactFeature) {
  feature.resources.addResource<
    ReportDimensionResource<
      StudentsReportDimensionInput,
      StudentsReportDimensionResultFragment,
      StudentsOptionsContext
    >
  >({
    type: 'ReportDimension',
    identifier: 'OneStudent',
    inputKey: 'students',
    title: 'One Student',
    singular: 'Student',
    description: 'I want to view data for one specific student.',
    icon: <User />,
    hasMultipleValues: false,
    supportedIndices: [0],
    fragment: StudentsReportDimensionResultFragmentDoc,
    shortLabel: (opts, { firstName }) => `${firstName}'${firstName.endsWith('s') ? '' : 's'} data`,
    titleLabel: (opts, { firstName }) => firstName,
    graph: studentGraphOptions,
    renderTableHeader: StudentHeader,
    useGetExportHeader: useGetStudentHeader,
    renderOptionsWrapper: props => <StudentsOptionsWrapper single {...props} />,
    renderOptionsLabel: props => <StudentsOptionsLabel single {...props} />,
  })
}
