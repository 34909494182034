import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useClasses } from '@thesisedu/feature-classes-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import {
  Dropdown,
  Button,
  Button$,
  Text,
  Form,
  Modal,
  LoadingIndicator,
  useStatePropFallback,
} from '@thesisedu/ui'
import { AddFolder, Check, Plus } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ShowInClassButton } from './ShowInClassButton'
import { usePlacementDrawerContext } from '../placement/drawer/PlacementDrawerContext'
import { useClassesWithSegmentQuery, useCreateClassWithPlacedContentMutation } from '../schema'
import { ClassFragmentWithPermissions } from '../types'

export interface UseInClassButtonProps extends Omit<Button$.ButtonProps, 'onPress'> {
  rawId: string
  onClick?: () => void
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}
export function UseInClassButton(props: UseInClassButtonProps) {
  const { rawId, onClick, visible: _visible, onVisibleChange: _onVisibleChange, ...rest } = props
  const [visible, onVisibleChange] = useStatePropFallback(_visible, _onVisibleChange, false)
  const [inClass, setInClass] = React.useState<{ id: string; name: string } | null>(null)
  const classesWithSegmentResult = useClassesWithSegmentQuery({
    variables: { id: toGlobalId('Segment', rawId) },
    skip: !visible && !inClass,
    // We always want the latest version of this data, and we cache it pretty
    // aggressively on the server.
    fetchPolicy: 'no-cache',
  })
  const classesWithSegment =
    classesWithSegmentResult.data?.node?.__typename === 'Segment'
      ? classesWithSegmentResult.data.node.classIdsWithSegment
      : undefined
  const segmentName =
    classesWithSegmentResult.data?.node?.__typename === 'Segment'
      ? classesWithSegmentResult.data.node.name
      : undefined

  const { classes: allClasses, loading } = useClasses({ archived: false })
  const classes = (allClasses as ClassFragmentWithPermissions[]).filter(
    cls => cls.canChangeCourseStructure,
  )
  const { setPlacing } = usePlacementDrawerContext(false) ?? {}
  if (classes.length || loading) {
    return (
      <>
        <Modal
          visible={!!inClass}
          onVisibleChange={v => {
            if (!v) setInClass(null)
          }}
          title={'Already in Class'}
        >
          <Text bottom>
            {segmentName ? <strong>{segmentName}</strong> : <span>This content</span>} is already in
            your <strong>{inClass?.name}</strong> class, and you can't add the same content to your
            class twice.
          </Text>
          <Text>Would you like to show it in your class?</Text>
          <Modal.Footer>
            <Modal.CloseButton />
            {inClass ? <ShowInClassButton classId={inClass.id} segmentId={rawId} /> : null}
          </Modal.Footer>
        </Modal>
        <Dropdown.Container open={visible} onOpenChange={onVisibleChange}>
          <Dropdown.Button
            children={'Use in Class'}
            icon={<AddFolder />}
            size={'small'}
            loading={loading}
            disabled={!setPlacing}
            {...rest}
            style={{ ...rest.style, whiteSpace: 'nowrap' }}
          />
          <Dropdown.Menu side={'bottom'} align={'end'}>
            {classes.map((cls, index) => {
              const loading = index === 0 && classesWithSegmentResult.loading
              const inClass = classesWithSegment?.includes(cls.id)
              return (
                <Dropdown.Item
                  key={cls.id}
                  disabled={!setPlacing}
                  icon={inClass ? <Check /> : undefined}
                  onClick={() => {
                    if (inClass) {
                      setInClass({ id: cls.id, name: cls.name })
                    } else {
                      setPlacing?.({
                        classId: cls.id,
                        rawSegmentId: rawId,
                      })
                      if (onClick) {
                        onClick()
                      }
                    }
                  }}
                >
                  {cls.name}
                  {loading ? (
                    <Dropdown.Item.Right>
                      <LoadingIndicator />
                    </Dropdown.Item.Right>
                  ) : inClass ? (
                    <Dropdown.Item.Right>In Class</Dropdown.Item.Right>
                  ) : null}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown.Container>
      </>
    )
  } else {
    return <UseInNewClassButton {...props} />
  }
}

export function UseInNewClassButton({
  rawId,
  onClick,
  visible,
  onVisibleChange,
  ...rest
}: UseInClassButtonProps) {
  const navigate = useNavigate()
  const [create, { loading }] = useCreateClassWithPlacedContentMutation({
    onError: onMutationError('There was an error creating a class with that content.'),
    refetchQueries: ['minimalTeacherClasses'],
    awaitRefetchQueries: true,
    onCompleted: data => {
      const classId = data.createClassWithPlacedContent.class.id
      navigate(`/teacher/classes/${classId}/course/outline`)
    },
  })
  const [modalVisible, setModalVisible] = React.useState(false)
  const form = Form.useForm()
  return (
    <>
      <Form.Modal
        title={'Create Class'}
        visible={modalVisible}
        onVisibleChange={setModalVisible}
        form={form}
        onFinish={async values => {
          await create({
            variables: {
              input: {
                class: {
                  name: values.name.trim(),
                },
                segmentId: toGlobalId('Segment', rawId),
              },
            },
          })
        }}
      >
        <Text bottom>
          Type a name for your class in the field below and then click{' '}
          <strong>Create Class.</strong>
        </Text>
        <Form.TextField
          name={'name'}
          aria-label={'Class Name'}
          placeholder={'My New Class'}
          size={'large'}
          required
        />
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Form.Submit variant={'primary'} loading={loading} icon={<Plus />}>
            Create Class
          </Form.Submit>
        </Modal.Footer>
      </Form.Modal>
      <Button
        children={'Use in New Class'}
        icon={<AddFolder />}
        size={'small'}
        {...rest}
        style={{ ...rest.style, whiteSpace: 'nowrap' }}
        onPress={() => {
          setModalVisible(true)
        }}
      />
    </>
  )
}
