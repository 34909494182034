import { Link } from '@thesisedu/ui/icons'
import React from 'react'

import { LinkEmbedElement } from './LinkEmbedElement'
import { LinkEmbedSettingsModal } from './LinkEmbedSettingsModal'
import { LinkEmbedConfig, LinkEmbedConfigV1 } from './common'
import { WidgetsReactFeature } from '../../../WidgetsReactFeature'
import { SimpleWidget } from '../utils'

export const definition = {
  identifier: 'LinkEmbed',
  title: 'Link Embed',
  icon: <Link />,
  weight: 14,
  group: SimpleWidget.ElementsGroup.Media,
  defaultConfig: { url: null, version: 1 },
  element: LinkEmbedElement,
  draggerUsesFirstChild: true,
  isDynamic: true,
  settingsDropdownItems: [
    {
      identifier: 'alignment',
      weight: 0,
      element: SimpleWidget.AlignmentSetting,
    },
  ],
  settingsModal: LinkEmbedSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<LinkEmbedConfig, 'LinkEmbed'>

export default function (feature: WidgetsReactFeature) {
  feature.resources.addResource(
    SimpleWidget.createWidgetResource<LinkEmbedConfigV1, 'LinkEmbed'>(definition),
  )
}
