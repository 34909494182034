import { QuestionCircleOutlined } from '@ant-design/icons'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { Block, styled } from '@thesisedu/web'
import { Tooltip } from 'antd'
import React, { useState } from 'react'

import { CustomSegmentList } from './CustomSegmentList'
import { FilterOperation } from '../schema'
import { CreateSegmentButton } from '../segment/CreateSegmentButton'
import { SegmentFilters, SegmentFilterValues, SegmentUserFilter } from '../segment/SegmentFilters'

export function ContentBrowser() {
  const { appOptions } = useFeatureRoot()!
  const [filters, setFilters] = useState<SegmentFilterValues>({
    type: undefined,
    user: SegmentUserFilter.Me,
    name: undefined,
    tags: [],
    tagsOp: FilterOperation.Or,
  })
  return (
    <>
      <Header>
        <SegmentFilters filters={filters} onChange={setFilters} hideCreator hideGroup />
        <div style={{ marginLeft: 'auto' }} />
        <Tooltip title={'Click here to see how to use this feature.'}>
          <a
            href={`https://${appOptions.domain}/support/portal/content-manager`}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <QuestionCircleOutlined />
          </a>
        </Tooltip>
        <CreateSegmentButton />
      </Header>
      <Block marginTop={'@size-s'}>
        <CustomSegmentList filters={filters} />
      </Block>
    </>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
