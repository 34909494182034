import React from 'react'

import { Empty } from '../ant/Empty'

export interface NotFoundProps {
  description?: string
  hideBack?: boolean
}
/** @deprecated */
export function NotFound({
  description = 'That content could not be found.',
  hideBack,
}: NotFoundProps) {
  return (
    <Empty
      description={description}
      action={
        hideBack
          ? undefined
          : {
              title: 'Go Back',
              onClick() {
                if (typeof window !== 'undefined' && typeof window.history !== 'undefined') {
                  window.history.back()
                }
              },
            }
      }
    />
  )
}
