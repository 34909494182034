import { onMutationError, useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Popconfirm, BodyExtraSmall } from '@thesisedu/react'
import { useToast } from '@thesisedu/ui'
import React from 'react'

export interface DeleteCommentLinkProps {
  commentId: string
  onDeleted?: (commentId: string) => void
  style?: any
}

export const DeleteCommentLink: React.FC<React.PropsWithChildren<DeleteCommentLinkProps>> = ({
  commentId,
  onDeleted,
  style,
}) => {
  const toast = useToast()
  const [deleteNode] = useDeleteNodeMutation({
    onError: onMutationError('There was an error deleting that comment.'),
    onCompleted: () => {
      if (onDeleted) {
        toast.success('Comment deleted successfully!')
        onDeleted(commentId)
      }
    },
  })
  return (
    <Popconfirm
      title={'Are you sure you want to delete this comment?'}
      okType={'danger'}
      okText={'Yes, delete'}
      cancelText={'No, keep'}
      placement={'topRight'}
      onConfirm={() => {
        return deleteNode({
          variables: {
            id: commentId,
          },
        })
      }}
    >
      <BodyExtraSmall style={{ ...style, cursor: 'pointer' }} color={'@text-color-secondary'}>
        Delete
      </BodyExtraSmall>
    </Popconfirm>
  )
}
