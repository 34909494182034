import { FooterItem, RecordFooterButton } from '@thesisedu/feature-media-react'
import { Tooltip } from '@thesisedu/ui'
import { Redo } from '@thesisedu/ui/icons'
import React from 'react'

import { useSheetMusicPluginContext } from './SheetMusicPluginContext'
import { debug } from '../../log'

export function RestartButton() {
  const { setVisible, setPlaying } = useSheetMusicPluginContext(true)
  const [countdown, setCountdown] = React.useState<number | false>(false)
  const startCountdown = async () => {
    let start = 5
    while (start > 0) {
      setCountdown(start)
      start--
      await new Promise(resolve => setTimeout(resolve, 1000))
    }
    setPlaying(true)
    setCountdown(false)
  }
  return (
    <FooterItem weight={-1}>
      <Tooltip title={'Restart Composition'}>
        <RecordFooterButton
          icon={<Redo />}
          variant={countdown === false ? undefined : 'primary'}
          onPress={() => {
            setVisible(false)
            setTimeout(() => {
              setVisible(true)
              startCountdown().catch(err => {
                debug('error counting down %O', err)
              })
            }, 2)
          }}
          children={countdown === false ? undefined : countdown}
        />
      </Tooltip>
    </FooterItem>
  )
}
