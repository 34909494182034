import { styled } from '@thesisedu/web'
import React from 'react'

export interface AssignmentLoadingCellProps {
  isFull?: boolean
  children: React.ReactElement | null
}
export function AssignmentLoadingCell({ isFull, children }: AssignmentLoadingCellProps) {
  if (!isFull) {
    return (
      <LoadingOverlay>
        <span />
        <span />
      </LoadingOverlay>
    )
  } else {
    return children
  }
}

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    display: block;
    width: ${props => props.theme['@size-l']};
    height: ${props => props.theme['@size-s']};
    border-radius: ${props => props.theme['@border-radius-base']};
    background: ${props => props.theme['@gray-3']};
    &:not(:last-child) {
      margin-right: ${props => props.theme['@size-xs']};
    }
  }
`
