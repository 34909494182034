import { SegmentStructureOverride } from '@thesisedu/feature-course-types'
import React, { useContext, useMemo } from 'react'

import { useSegmentCache } from './SegmentContext'
import { getSegmentParents } from '../helpers'
import { ClassCourseFragment } from '../schema'

export interface SegmentStatusContextValue {
  isContentCustom: (rawSegmentId: string) => boolean
  areContentParentsCustom: (rawSegmentId: string) => boolean
  isContentMoved: (rawSegmentId: string) => boolean
  areContentParentsMoved: (rawSegmentId: string) => boolean
}
export const SegmentStatusContext = React.createContext<SegmentStatusContextValue | undefined>(
  undefined,
)

export interface SegmentStatusContextProviderProps {
  class: ClassCourseFragment
}
export function SegmentStatusContextProvider({
  class: cls,
  children,
}: React.PropsWithChildren<SegmentStatusContextProviderProps>) {
  const segments = useSegmentCache()
  const segmentCacheRef = React.useRef(segments)
  React.useEffect(() => {
    segmentCacheRef.current = segments
  }, [segments])
  const value = useMemo<SegmentStatusContextValue>(() => {
    const isContentCustom = (rawSegmentId: string) => {
      const foundSegment = segmentCacheRef.current[rawSegmentId]
      if (foundSegment?.isMoved) return false
      return !!cls.segmentMetadata?.structureOverrides?.find(
        (segment: SegmentStructureOverride) => segment.id === rawSegmentId,
      )
    }
    const isContentMoved = (rawSegmentId: string) => {
      const foundSegment = segmentCacheRef.current[rawSegmentId]
      return foundSegment ? !!foundSegment.isMoved : false
    }
    return {
      isContentCustom,
      areContentParentsCustom: rawSegmentId => {
        const parents = getSegmentParents(cls.bakedCourseConfiguration.segments, rawSegmentId)
        return parents.some(segmentId => isContentCustom(segmentId))
      },
      isContentMoved,
      areContentParentsMoved: rawSegmentId => {
        const parents = getSegmentParents(cls.bakedCourseConfiguration.segments, rawSegmentId)
        return parents.some(segmentId => isContentMoved(segmentId))
      },
    }
  }, [cls.id, cls.segmentMetadata?.structureOverrides])
  return <SegmentStatusContext.Provider value={value} children={children} />
}

export function useSegmentStatusContext(): SegmentStatusContextValue | undefined
export function useSegmentStatusContext(require: false): SegmentStatusContextValue | undefined
export function useSegmentStatusContext(require: true): SegmentStatusContextValue
export function useSegmentStatusContext(require?: boolean): SegmentStatusContextValue | undefined {
  const context = useContext(SegmentStatusContext)
  if (!context && require) {
    throw new Error('SegmentStatusContext is required, yet not provided.')
  }
  return context
}
