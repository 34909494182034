import { useResource } from '@thesisedu/feature-react'
import { styled } from '@thesisedu/web'
import React from 'react'

import { AllMetricSummariesFragment } from '../../../execute/types'
import { ReportDimensionTableHeaderProps, ReportMetricResource } from '../../../types'
import { useReportGridContext } from '../Context'
import { MetricColHeader } from '../MetricColHeader'
import { MetricSummaries } from '../MetricSummaries'
import { isMetrics } from '../axes'
import { useDimensionGetHeaderCallbacks } from '../dimensions'
import { getMetricSummaryFromOpts } from '../metrics'

export interface ColumnHeaderInfo {
  metricName: string | undefined
  summaryKey: keyof AllMetricSummariesFragment
  summary: AllMetricSummariesFragment | undefined
  header: React.ReactElement | null
  label: string
}
export type ColumnHeaderInfoFn = (rawIndex: number) => ColumnHeaderInfo
export function useGetColumnHeaderInfo(): ColumnHeaderInfoFn {
  const { responsibility, data, dimensionResults, opts } = useReportGridContext(true)
  const headerCallbacks = useDimensionGetHeaderCallbacks()
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  return (rawIndex: number) => {
    let metricName
    let summaryKey: keyof AllMetricSummariesFragment = getMetricSummaryFromOpts(
      opts,
      metricResources,
    )
    let summary
    let header
    let label
    if (isMetrics(responsibility.col)) {
      const metricIndex = rawIndex - 1
      const resource = responsibility.col[metricIndex]
      summary = data.allMetrics
      summaryKey = resource.summarization
      metricName = resource.identifier
      header = <MetricColHeader metric={resource} />
      label = resource.title
    } else {
      const dimension = dimensionResults[rawIndex - 1]
      if (!dimension) throw new Error(`Cannot find dimension at index ${rawIndex}`)
      const dimensionSummary = data.dimensionSummaries[rawIndex - 1]
      const dimensionResult = dimension[responsibility.col.inputKey]
      summary = dimensionSummary ? dimensionSummary.metrics : undefined
      const headerProps: ReportDimensionTableHeaderProps<any> = {
        result: dimensionResult,
        type: 'column',
        depth: undefined,
      }
      const labelCallback = headerCallbacks[responsibility.col.identifier].resource
      label = labelCallback ? labelCallback(headerProps) : ''
      const Header = responsibility.col.renderTableHeader
      if (Header) {
        header = <Header {...headerProps} />
      } else {
        header = null
      }
    }

    return {
      summary,
      summaryKey,
      metricName,
      header,
      label,
    }
  }
}

export interface ColumnHeaderCellProps {
  /** The 0-based index of the column, including the row headers. */
  rawIndex: number
  style: any
}
export function ColumnHeaderCell({ rawIndex, style }: ColumnHeaderCellProps) {
  const { extents } = useReportGridContext(true)
  const getInfo = useGetColumnHeaderInfo()
  const { header, summary, summaryKey, metricName } = getInfo(rawIndex)
  const isLast = rawIndex === extents.numColumns
  return (
    <ColumnHeaderCellContainer isLast={isLast} style={style}>
      {header}
      {summary ? (
        <MetricSummaries display={summaryKey} summaries={summary} metric={metricName} />
      ) : null}
    </ColumnHeaderCellContainer>
  )
}
const ColumnHeaderCellContainer = styled.div<{ isLast?: boolean }>`
  background: ${props => props.theme['@gray-1']};
  padding: ${props => props.theme['@size-xs']};
  border-right: ${props =>
    props.isLast ? 'none' : `solid 1px ${props.theme['@border-color-base']}`};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`
