import disabled from './disabled'
import error from './error'
import record from './record'
import review from './review'
import selectDevice from './selectDevice'
import { RecordState } from './types'
import upload from './upload'

const states: Record<string, RecordState<any>> = {
  selectDevice,
  record,
  error,
  review,
  upload,
  disabled,
}

export default states
