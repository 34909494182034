import { ClassContext, useClasses, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { SegmentMetadata, cleanOverrides } from '@thesisedu/feature-course-types'
import { useFreshRef } from '@thesisedu/feature-react'
import { mergeCollections } from '@thesisedu/feature-utils'
import { Empty, useForwardedRef } from '@thesisedu/react'
import { Block, HSpaced, LoadingIndicator, Text, VSpaced } from '@thesisedu/ui'
import { Home } from '@thesisedu/ui/icons'
import React from 'react'

import { useCourseContext } from '../../contexts/CourseContext'
import { debug } from '../../log'
import { TermSelect } from '../../outline/TermSelect'
import { useClassCourseQuery } from '../../schema'
import { CourseConfiguration, GroupSegment } from '../../types'
import {
  AddGroupsAndTopicsButton,
  AddGroupsAndTopicsButtonProps,
  AddTopicButton,
} from '../AddGroupsAndTopicsButton'
import { BreadcrumbSeparator } from '../BreadcrumbSeparator'
import { PlacementOutline, PlacementOutlineProps } from '../PlacementOutline'
import { SplitViewFooterContent } from '../SplitViewFooterHostContext'
import { useSplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'
import { PlacementPaneImperativeHandle } from '../types'
import { useModifiedCurrentClassConfiguration } from '../useModifiedCurrentClassConfiguration'
import { useSaveChangesToClass } from '../useSaveChangesToClass'
import { useSelectedTermId } from '../useSelectedTermId'

export interface CurrentClassPaneProps
  extends Pick<PlacementOutlineProps, 'draggableComponent' | 'forceCollapseIds'> {
  selectedTermId?: string
  onSelectedTermChanged?: (selectedTerm: string) => void
  hideTermSelect?: boolean
  justify?: 'left' | 'right'
  /** If this is specified, it overrides the currently-selected class. */
  classId?: string
  className?: string
  onClose: () => void
  noFooterContent?: boolean
  includeAllTermsInCreateTermSelect?: boolean
}
function ___CurrentClassPane(
  {
    selectedTermId: _selectedTermId,
    onSelectedTermChanged,
    hideTermSelect,
    includeAllTermsInCreateTermSelect,
    justify = 'right',
    classId: _classId,
    className,
    onClose,
    configuration: originalConfiguration,
    segmentMetadata,
    noFooterContent = justify === 'left',
    ...rest
  }: CurrentClassPaneProps & {
    configuration: CourseConfiguration
    segmentMetadata?: SegmentMetadata
  },
  _ref: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  const ref = useForwardedRef(_ref)
  const { classes } = useClasses()
  const selectedClassId = useSelectedClassId(false)
  const classId = _classId ?? selectedClassId
  if (!classId) throw new Error('Cannot find class ID inside CurrentClassPane')
  const cls = classes.find(cls => cls.id === classId)
  const bakedCourseConfiguration = useModifiedCurrentClassConfiguration(originalConfiguration)
  const { setChanges, changes, foreignSegmentsRef } = useSplitViewPendingChangesContext(true)
  const changesRef = useFreshRef(changes)
  const [selectedTermId, setSelectedTermId] = useSelectedTermId(
    bakedCourseConfiguration,
    _selectedTermId,
    onSelectedTermChanged,
  )
  const selectedTerm = bakedCourseConfiguration.segments.find(s => s.id === selectedTermId)
  const selectedTermName = selectedTerm?.name
  const saveChanges = useSaveChangesToClass({ classId, onClose, segmentMetadata })
  const addTopicProps: AddGroupsAndTopicsButtonProps = {
    selectedTerm: selectedTerm as GroupSegment,
    listRef: ref,
    otherTerms: includeAllTermsInCreateTermSelect
      ? (bakedCourseConfiguration.segments.filter(s => s.id !== selectedTermId) as GroupSegment[])
      : undefined,
  }
  return (
    <VSpaced space={'s'} style={{ flex: 1 }}>
      {!noFooterContent && selectedTerm ? (
        <SplitViewFooterContent>
          <AddGroupsAndTopicsButton {...addTopicProps} />
        </SplitViewFooterContent>
      ) : null}
      <HSpaced
        justify={justify === 'left' ? 'flex-start' : 'flex-end'}
        paddingRight={justify !== 'left' ? 'l' : undefined}
      >
        <HSpaced space={'xs'} style={{ minWidth: 0 }}>
          <Text color={hideTermSelect ? undefined : 'primary'}>
            <Home />
          </Text>
          <Text color={hideTermSelect ? undefined : 'primary'} truncate>
            {cls?.name ?? className ?? 'Unknown Class'}
          </Text>
        </HSpaced>
        <BreadcrumbSeparator />
        {hideTermSelect ? (
          <Text color={'primary'}>{selectedTermName ?? '--'}</Text>
        ) : (
          <TermSelect
            value={selectedTermId}
            onChange={setSelectedTermId}
            configuration={bakedCourseConfiguration}
          />
        )}
      </HSpaced>
      <PlacementOutline
        {...rest}
        configuration={bakedCourseConfiguration}
        courseOrClassId={classId}
        selectedTermId={selectedTermId}
        setSelectedTermId={setSelectedTermId}
        sortableId={`current-class-${selectedTermId}`}
        isConfigurationModified
        ref={ref}
        onSave={saveChanges}
        emptyAction={<AddTopicButton {...addTopicProps} />}
        onPlaced={changes => {
          const mergedChanges: typeof changes = {
            metadataOverrides: mergeCollections(
              changesRef.current.metadataOverrides,
              changes.metadataOverrides,
            ),
            structureOverrides: mergeCollections(
              changesRef.current.structureOverrides,
              changes.structureOverrides,
            ),
          }
          debug('merged changes before cleaning %O', mergedChanges)
          const { overrides: cleanedChanges } = cleanOverrides(
            originalConfiguration,
            mergedChanges,
            foreignSegmentsRef.current,
            {
              ignoreMetadataSegmentIds: changesRef.current.createdSegments.map(s => s.id),
            },
          )
          debug('cleaned changes %O', cleanedChanges)
          setChanges(cleanedChanges)

          // ...once we're all done, highlight the segment.
          const structureSegmentIds = changes.structureOverrides.map(s => s.id)
          if (structureSegmentIds.length === 1) {
            debug(
              'highlighting %s because it is the only structure override changed',
              structureSegmentIds[0],
            )
            // Wait a bit for the DOM to update.
            setTimeout(() => {
              ref.current?.highlightSegment(structureSegmentIds[0])
            }, 10)
          }
        }}
      />
    </VSpaced>
  )
}
const __CurrentClassPane = React.forwardRef(___CurrentClassPane)

function _ClassPaneWithoutClassOverride(
  props: CurrentClassPaneProps,
  ref: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  const { bakedCourseConfiguration: configuration, segmentMetadata } = useCourseContext(true)
  return (
    <__CurrentClassPane
      {...props}
      configuration={configuration}
      segmentMetadata={segmentMetadata}
      ref={ref}
    />
  )
}
const ClassPaneWithoutClassOverride = React.forwardRef(_ClassPaneWithoutClassOverride)

function _ClassPaneWithClassOverride(
  props: CurrentClassPaneProps & { classId: string },
  ref: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  const { data, loading } = useClassCourseQuery({
    variables: {
      id: props.classId,
    },
  })
  const classCourse = data?.node?.__typename === 'Class' ? data.node : undefined
  const existingClassContext = React.useContext(ClassContext)
  if (loading) {
    return (
      <Block center>
        <LoadingIndicator.Labeled label={'Loading class...'} />
      </Block>
    )
  } else if (classCourse && existingClassContext) {
    return (
      <ClassContext.Provider value={{ ...existingClassContext, selectedClassId: props.classId }}>
        <__CurrentClassPane
          {...props}
          className={classCourse.name}
          configuration={classCourse.bakedCourseConfiguration}
          segmentMetadata={classCourse.segmentMetadata}
          ref={ref}
        />
      </ClassContext.Provider>
    )
  } else {
    return (
      <Block center>
        <Empty description={'Your class could not be found.'} />
      </Block>
    )
  }
}
const ClassPaneWithClassOverride = React.forwardRef(_ClassPaneWithClassOverride)

function _CurrentClassPane(
  props: CurrentClassPaneProps,
  ref: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  if (props.classId) {
    return <ClassPaneWithClassOverride {...props} classId={props.classId} ref={ref} />
  } else {
    return <ClassPaneWithoutClassOverride {...props} ref={ref} />
  }
}
export const CurrentClassPane = React.forwardRef(_CurrentClassPane)
