import { Modal, Text, styled, s } from '@thesisedu/ui'
import React from 'react'

export interface NoPermissionModalProps {
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
  label: string
  icon: React.ReactElement
}
export function NoPermissionModal({
  icon,
  label,
  visible,
  onVisibleChange,
}: NoPermissionModalProps) {
  return (
    <Modal title={`No ${label} Permission`} visible={visible} onVisibleChange={onVisibleChange}>
      <IconContainer>{icon}</IconContainer>
      <Text level={'l'} bottom>
        You need to grant {label} permission before you can continue.
      </Text>
      <Text bottom>
        Your browser is reporting you have denied {label} access in the past, so you'll have to
        re-enable it manually in order to continue.
      </Text>
      <Text>Please enable access to your {label}, and click OK to continue.</Text>
      <Modal.Footer>
        <Modal.CloseButton children={'OK'} variant={'primary'} />
      </Modal.Footer>
    </Modal>
  )
}

const IconContainer = styled.div`
  border-radius: ${s.var('radii.1')};
  width: 64px;
  height: 64px;
  font-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${s.color('orange')};
  color: ${s.getThemeValue('color.light.gray.11')};
  margin-bottom: ${s.size('s')};
  margin-top: ${s.size('l')};
`
