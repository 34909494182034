import { Block } from '@thesisedu/web'
import { Select } from 'antd'
import React from 'react'

import { TagEditor } from './TagEditor'
import { TagSearchProps } from './TagSearch'
import { FilterOperation, TagFilterOperation } from './schema'
import { TagPair } from './types'

export interface TagsFilterContentProps {
  tags: TagPair[]
  tagsOp: FilterOperation | TagFilterOperation
  onChange: (tags: TagPair[], op: FilterOperation | TagFilterOperation) => void
  disableOp?: boolean
  searchProps?: Partial<TagSearchProps>
}
export function TagsFilterContent({
  tagsOp,
  tags,
  onChange,
  disableOp,
  searchProps,
}: TagsFilterContentProps) {
  return (
    <Block marginBottom={'@size-s'}>
      <div className={'summary'}>
        Show content that matches
        <Select
          value={tagsOp}
          onChange={value => onChange(tags, value)}
          size={'small'}
          style={{ margin: '0 5px' }}
          disabled={disableOp}
        >
          <Select.Option value={'OR'}>any of these</Select.Option>
          <Select.Option value={'AND'}>all of these</Select.Option>
        </Select>
        tags.
      </div>
      <TagEditor
        searchProps={{ noAdd: true, placeholder: 'Search for tags...', ...searchProps }}
        value={tags}
        onChange={tags => onChange(tags, tagsOp)}
      />
    </Block>
  )
}
