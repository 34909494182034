import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { message, Popconfirm } from 'antd'
import React from 'react'

import { SubscriptionsDocument } from './schema'

export interface DeleteSubscriptionButtonProps {
  children: React.ReactElement<{ onClick?: () => any }>
  subscriptionId: string
  goBack?: boolean
}
export const DeleteSubscriptionButton: React.FC<
  React.PropsWithChildren<DeleteSubscriptionButtonProps>
> = ({ children, subscriptionId, goBack }) => {
  const [deleteSubscription] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that subscription.',
    refetchQueries: [{ query: SubscriptionsDocument }],
    awaitRefetchQueries: true,
    variables: { id: subscriptionId },
    onCompleted: () => {
      message.success('Subscription deleted successfully.')
      if (goBack) {
        window.history.back()
      }
    },
  })
  return (
    <Popconfirm
      title={'Are you sure you want to delete this subscription?'}
      onConfirm={() => deleteSubscription()}
      okText={'Yes, delete'}
      okType={'danger'}
      cancelText={'No, keep'}
      placement={'topRight'}
    >
      {children}
    </Popconfirm>
  )
}
