import { DraggableNode } from '@thesisedu/feature-widgets-core'
import { $getRoot, $isDecoratorNode, $isElementNode, ElementNode, LexicalEditor } from 'lexical'

import { SPACE } from './constants'
import { Point } from '../../utils/point'
import { Rect } from '../../utils/rect'

function _getElementNodeKeys(element: ElementNode, result: Set<DraggableNode>) {
  for (const child of element.getChildren() as DraggableNode[]) {
    // Add the children keys first so we can select the deepest element.
    if ($isElementNode(child) && !child.isInline()) {
      _getElementNodeKeys(child, result)
      const canDrag = !child.canDrag || child.canDrag()
      if (canDrag) {
        result.add(child)
      }
    }
    if ($isDecoratorNode(child)) {
      result.add(child)
    }
  }
}

function getElementNodeKeys(editor: LexicalEditor): DraggableNode[] {
  const result = new Set<DraggableNode>()
  return editor.getEditorState().read(() => {
    _getElementNodeKeys($getRoot(), result)
    return Array.from(result)
  })
}

export interface TargetResult {
  element: HTMLElement
  key: string
}
export function getBlockElement(
  menuElement: HTMLElement | null,
  editor: LexicalEditor,
  event: MouseEvent,
): TargetResult | null {
  const menuElementWidth = menuElement?.clientWidth || 0
  const lexicalNodes = getElementNodeKeys(editor)

  let blockElement: HTMLElement | null = null
  let blockKey: string | null = null

  editor.getEditorState().read(() => {
    // See getBlockElement.old.ts for a more performant way of doing this, but less accurate.
    // Ideally we develop a two-dimensional strategy (the one they have here is one-dimensional
    // because it's not expecting to support columns, etc)

    // Return the first element we are inside, understanding the keys are children-first.
    const point = new Point(event.x, event.y)
    for (const lexicalNode of lexicalNodes) {
      const element = editor.getElementByKey(lexicalNode.getKey())
      if (element === null) break
      const domRect = Rect.fromDOM(element)
      const { marginTop, marginBottom, marginLeft, marginRight } = window.getComputedStyle(element)
      const { width: _markerWidth } = window.getComputedStyle(element, '::marker')
      let markerWidth = parseFloat(_markerWidth.replace('px', ''))
      if (isNaN(markerWidth)) markerWidth = 0

      const rect = domRect.generateNewRect({
        bottom: domRect.bottom + parseFloat(marginBottom) + SPACE,
        left: domRect.left - parseFloat(marginLeft) - markerWidth - menuElementWidth - SPACE,
        right: domRect.right + parseFloat(marginRight) - menuElementWidth,
        top: domRect.top - parseFloat(marginTop) + SPACE,
      })

      const { result } = rect.contains(point)
      if (result) {
        blockElement = element
        blockKey = lexicalNode.getKey()
        break
      }
    }
  })

  if (blockElement && blockKey) {
    return { element: blockElement, key: blockKey }
  }
  return null
}
