import { DeleteOutlined } from '@ant-design/icons'
import { useFeaturePreview } from '@thesisedu/feature-feature-preview-react'
import { useFeature } from '@thesisedu/feature-web'
import { Menu } from 'antd'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import localForage from 'localforage'
import React from 'react'

import { ApolloReactFeature } from './ApolloReactFeature'
import { PREVIEW_PERSISTED_CACHE } from './constants'

export function PersistenceMenuItem(props: MenuItemProps) {
  const [enabled] = useFeaturePreview(PREVIEW_PERSISTED_CACHE)
  const ft = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  if ((enabled || !ft.options.persistence?.hideBehindPreview) && ft.options.persistence) {
    return (
      <Menu.Item
        key={'reset-cache'}
        {...props}
        onClick={e => {
          e.domEvent.stopPropagation()
          e.domEvent.preventDefault()
          localForage.removeItem(ft.options.persistence!.persistKey).then(() => {
            window.location.reload()
          })
        }}
      >
        <DeleteOutlined />
        <span>Reset Cache</span>
      </Menu.Item>
    )
  } else {
    return null
  }
}
