import React from 'react'

import { SegmentListItem, SegmentListItemProps } from './SegmentListItem'
import { useBuiltSegmentQuery } from '../schema'

export interface SegmentSummaryProps extends Omit<SegmentListItemProps, 'segment'> {
  segmentDatabaseId: string
}
export const SegmentSummary: React.FC<React.PropsWithChildren<SegmentSummaryProps>> = ({
  segmentDatabaseId,
  ...props
}) => {
  const { data } = useBuiltSegmentQuery({
    variables: {
      id: segmentDatabaseId,
    },
  })
  if (data && data.node?.__typename === 'Segment') {
    return <SegmentListItem segment={data.node} {...props} />
  } else {
    return null
  }
}
