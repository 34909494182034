import React from 'react'

import { SelectButton } from './SelectButton'
import { SelectButtonProps } from './types'
import { RequiredVariables } from '../loadMore'
import { Node } from '../schema'

export interface SingleSelectButtonProps<
  Item extends Node,
  Data,
  Variables extends RequiredVariables,
> extends Omit<SelectButtonProps<Item, Data, Variables>, 'value' | 'onChange'> {
  value?: string
  onChange?: (item?: string, itemFragment?: Item) => void
}
export function SingleSelectButton<Item extends Node, Data, Variables extends RequiredVariables>({
  value,
  onChange,
  ...props
}: SingleSelectButtonProps<Item, Data, Variables>) {
  return (
    <SelectButton
      value={value ? [value] : undefined}
      onChange={(items, changedFragments) =>
        onChange ? onChange(items[0], changedFragments[0]) : undefined
      }
      {...props}
    />
  )
}
