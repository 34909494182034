import { useDroppable } from '@dnd-kit/core'
import { styled, s, Text } from '@thesisedu/ui'
import React from 'react'

import { useGroupsContext } from './GroupsContext'
import { StudentDraggable } from './StudentDraggable'

export function UngroupedStudents() {
  const { ungroupedStudents } = useGroupsContext(true)
  const { setNodeRef } = useDroppable({
    id: 'ungrouped',
  })
  return (
    <>
      <Text bottom={'xs'} level={'s'} color={'secondary'}>
        Ungrouped students
      </Text>
      <Container ref={setNodeRef} data-testid={'UngroupedStudents'}>
        {ungroupedStudents.map(student => {
          return <StudentDraggable key={student.studentId} student={student} />
        })}
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${s.size('s')};
  min-height: ${s.size('l')};
  border-radius: ${s.var('radii.1')};
  transition: background 0.1s linear;
  position: relative;
  &::before {
    content: 'Drag students here to ungroup them';
    display: block;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${s.color('secondary')};
    text-align: center;
    font-size: ${s.size('font.s')};
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;
  }
  &:empty {
    background: ${s.color('element')};
    justify-content: center;
    &::before {
      opacity: 1;
    }
  }
`
