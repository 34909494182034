import { TagsTreeItemFragment } from './schema'

export type TagTreeItemWithChildren = TagsTreeItemFragment & {
  children?: TagTreeItemWithChildren[]
}
export function flattenTagTree(tree: TagTreeItemWithChildren[]): TagTreeItemWithChildren[] {
  const items: TagTreeItemWithChildren[] = []
  function recurse(node: TagTreeItemWithChildren) {
    items.push(node as TagTreeItemWithChildren)
    if (node.children) {
      node.children.forEach(recurse)
    }
  }
  tree.forEach(recurse)
  return items
}
