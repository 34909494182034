import { generateSegmentDays } from '@thesisedu/feature-course-types'
import React, { useContext, useMemo } from 'react'

import { Segment } from '../types'

export interface TermDaysMap {
  [termSegmentId: string]: Segment[][]
}
export interface SegmentDaysContextValue {
  days: Segment[][]
  termDays: TermDaysMap
}
export const SegmentDaysContext = React.createContext<SegmentDaysContextValue | undefined>(
  undefined,
)

export interface SegmentDaysContextProviderProps {
  courseConfiguration: { segments: any[] }
}
export const SegmentDaysContextProvider: React.FC<
  React.PropsWithChildren<SegmentDaysContextProviderProps>
> = ({ courseConfiguration, children }) => {
  const value: SegmentDaysContextValue = useMemo(
    () => ({
      days: generateSegmentDays(courseConfiguration) as Segment[][],
      termDays: courseConfiguration.segments.reduce<TermDaysMap>((acc, termSegment) => {
        return {
          ...acc,
          [termSegment.id]: generateSegmentDays({ segments: [termSegment] }),
        }
      }, {}),
    }),
    [courseConfiguration],
  )
  return <SegmentDaysContext.Provider value={value} children={children} />
}

export function useSegmentDaysContext(): SegmentDaysContextValue | undefined
export function useSegmentDaysContext(require: false): SegmentDaysContextValue | undefined
export function useSegmentDaysContext(require: true): SegmentDaysContextValue
export function useSegmentDaysContext(require?: boolean): SegmentDaysContextValue | undefined {
  const context = useContext(SegmentDaysContext)
  if (!context && require) {
    throw new Error('SegmentDaysContext is required, yet not provided.')
  }
  return context
}
