import { Button, styled, s } from '@thesisedu/ui'

import { RecordFooterCellStyles } from './RecordFooterCell'

const { css } = s
export const RecordFooterButtonStyles = css`
  ${RecordFooterCellStyles}
  --vertical-padding: ${s.size('xs')} !important;
  background: ${s.opacity('var(--accent-color)', 0.85)};
`
export const RecordFooterButton = styled(Button)`
  ${RecordFooterButtonStyles}
`
