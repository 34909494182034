import { onMutationError } from '@thesisedu/feature-apollo-react'
import { message, Button } from 'antd'
import React from 'react'

import { useReadNotificationsMutation } from './queries/useReadNotificationsMutation'
import { NotificationFragment } from './schema'

export interface MarkNotificationReadProps {
  notification: Pick<NotificationFragment, 'id' | 'isRead'>
}
export function MarkNotificationRead({ notification }: MarkNotificationReadProps) {
  const [mark, { loading }] = useReadNotificationsMutation({
    onError: onMutationError('There was an error updating that notification.'),
  })

  return (
    <Button
      type={'link'}
      size={'small'}
      loading={loading}
      onClick={() => {
        return mark({
          variables: {
            input: {
              notifications: [{ id: notification.id, read: !notification.isRead }],
            },
          },
        })
      }}
    >
      {notification.isRead ? 'Mark as Unread' : 'Mark as Read'}
    </Button>
  )
}

export interface MarkAllNotificationsProps {
  read: boolean
}
export function MarkAllNotifications({ read }: MarkAllNotificationsProps) {
  const [mark, { loading }] = useReadNotificationsMutation({
    onError: onMutationError('There was an error updating your notifications.'),
    onCompleted: () => {
      message.success(`All notifications marked as ${read ? 'read' : 'unread'}.`)
    },
  })

  return (
    <Button
      type={'link'}
      size={'small'}
      loading={loading}
      onClick={() => {
        return mark({
          variables: {
            input: {
              allRead: read,
            },
          },
        })
      }}
    >
      Mark all as {read ? 'Read' : 'Unread'}
    </Button>
  )
}
