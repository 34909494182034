import { DeleteOutlined } from '@ant-design/icons'
import { Modal, Menu } from 'antd'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import React from 'react'

import { useDeleteSegmentMutation } from '../queries/useDeleteSegmentMutation'

export interface DeleteCustomSegmentProps extends MenuItemProps {
  apiId: string
}
export function DeleteCustomSegment({ apiId, ...menuItemProps }: DeleteCustomSegmentProps) {
  const [deleteSegment] = useDeleteSegmentMutation(apiId)
  return (
    <Menu.Item
      danger
      icon={<DeleteOutlined />}
      {...menuItemProps}
      onClick={() => {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'Are you sure you want to delete this custom content? This cannot be undone.',
          okText: 'Yes, delete',
          okButtonProps: { danger: true },
          cancelText: 'No, keep',
          onOk: async () => {
            return deleteSegment()
          },
        })
      }}
    >
      Delete
    </Menu.Item>
  )
}
