import { BodySmall } from '@thesisedu/react'
import { OpenNewWindow } from '@thesisedu/react/icons'
import { styled } from '@thesisedu/web'
import React from 'react'

// @ts-ignore
import logo from './assets/academy-light-icon.svg'
// @ts-ignore
import background from './assets/auth-background.jpg'
// @ts-ignore
import background2x from './assets/auth-background@2x.jpg'

const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  padding: 16px 7vw;
  background-color: #add364;
  background-image: image-set(url(${background}) 1x, url(${background2x}) 2x);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 115vh;
`

const Page = styled.div`
  border-radius: ${props => props.theme['@size-s']};
  padding: ${props => props.theme['@size-m']};
  box-shadow: ${props => props.theme['@shadow-large']};
  background: ${props => props.theme['@component-background']};
  width: 100%;
  max-width: 500px;
  min-height: 70vh;
  margin: ${props => props.theme['@size-m']} 0 ${props => props.theme['@size-xxl']} 0;
  display: flex;
  flex-direction: column;
`

const Logo = styled.img`
  width: ${props => props.theme['@size-l']};
`
const LogoContainer = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-l']};
  > :last-child {
    margin-left: auto;
    padding-left: 0.5em;
  }
`

export const AuthWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <PageContainer>
    <Page>
      <LogoContainer href={'https://prodigiesacademy.com'}>
        <Logo src={logo} alt={'Prodigies Academy'} />
        <BodySmall>
          Prodigies Academy Home <OpenNewWindow />
        </BodySmall>
      </LogoContainer>
      <div style={{ marginTop: 'auto' }}>{children}</div>
    </Page>
  </PageContainer>
)
