import { Modal } from '@thesisedu/web'
import React from 'react'

import { CreateLesson } from './CreateLesson'
import { LessonFragment } from '../schema'

export interface CreateLessonModalProps {
  onCreated: (lesson: LessonFragment) => void
  visible?: boolean
  onCancel: () => void
}
export function CreateLessonModal({ onCreated, visible, onCancel }: CreateLessonModalProps) {
  const [modalKey, setModalKey] = React.useState(0)
  React.useEffect(() => {
    if (visible) setModalKey(mk => mk + 1)
  }, [visible])
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Create Lesson'}
      width={1400}
      key={modalKey}
      style={{ maxWidth: '90vw' }}
    >
      <CreateLesson
        onCreated={lesson => {
          onCreated(lesson)
        }}
      />
    </Modal>
  )
}
