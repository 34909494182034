import { ErrorIndicator } from '@thesisedu/web'
import React from 'react'

import { ReportLoadingIndicator } from './ReportLoadingIndicator'
import { ReportResults } from './ReportResults'
import { useRunReportQuery } from '../execute/useRunReportQuery'
import { RunReportOpts } from '../types'

export interface ReportProps {
  report: RunReportOpts
  onReportChange: (report: RunReportOpts, noNewTab?: boolean) => void
}
export function Report({ report, onReportChange }: ReportProps) {
  const { reportData, loading, error } = useRunReportQuery(report)
  if (loading) {
    return <ReportLoadingIndicator />
  } else if (reportData) {
    return <ReportResults data={reportData} report={report} onReportChange={onReportChange} />
  } else if (error) {
    return <ErrorIndicator />
  } else {
    return null
  }
}
