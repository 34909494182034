import { useResource } from '@thesisedu/feature-react'
import React from 'react'

import { QuestionTypeWrapEditContentProps } from '../node'
import { QuestionTypeResource } from '../types'

export interface WrapEditContentProps extends QuestionTypeWrapEditContentProps {
  questionTypes: string[]
}
export function WrapEditContent({ questionTypes, children, ...rest }: WrapEditContentProps) {
  const resources = useResource<QuestionTypeResource>('QuestionType')
  const questionResources = resources.filter(r => questionTypes.includes(r.identifier!))
  const wrappers = questionResources
    .filter(r => r.wrapEditContent)
    .map(r => r.wrapEditContent) as Required<QuestionTypeResource>['wrapEditContent'][]

  let content = children
  for (const Wrapper of wrappers) {
    content = <Wrapper children={children} {...rest} />
  }

  return content
}
