import { RegisterPageHook } from '@thesisedu/feature-users-react'
import {
  H1,
  Block,
  H1Subtitle,
  styled,
  Button,
  VSpaced,
  BodySmall,
  Link,
  Body,
} from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { RegisterStudent } from './Student'
import ClassesReactFeature from '../../ClassesReactFeature'

function RegisterHome() {
  const navigate = useNavigate()
  return (
    <>
      <H1 isBlock={false}>Register</H1>
      <Block marginBottom={'@size-l'}>
        <H1Subtitle isBlock={false}>Are you a teacher or a student?</H1Subtitle>
      </Block>
      <VSpaced align={'stretch'} space={'@size-s'}>
        <LargeButton size={'large'} onClick={() => navigate('student')}>
          <span>Student &rarr;</span>
          <BodySmall>Connect to your institution's class to start learning.</BodySmall>
        </LargeButton>
      </VSpaced>
      <Block marginTop={'@size-l'}>
        <Link to={'../login'}>
          <Body color={'@text-color-secondary'}>
            <ArrowLeft /> Go Back
          </Body>
        </Link>
      </Block>
    </>
  )
}

function RegisterPage() {
  return (
    <Routes>
      <Route path={'/'} element={<RegisterHome />} />
      <Route path={'student'} element={<RegisterStudent />} />
    </Routes>
  )
}

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<RegisterPageHook>(
    'feature-users-react:register-page',
    () => {
      return <RegisterPage />
    },
  )
}

const LargeButton = styled(Button)`
  height: auto !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  > span {
    display: block;
  }
  > p {
    margin: ${props => props.theme['@size-xxs']} 0 0 0;
    white-space: pre-wrap;
  }
`
