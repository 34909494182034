import { KeysOfType } from '@thesisedu/feature-types'

export interface FuzzySearchOpts {
  /** Immediately returns the first match. */
  bail?: boolean
}

function _indexesOfFirstLetter(item: string, query: string): number[] {
  const match = query[0]
  return item
    .split('')
    .map((letter, index) => {
      if (letter !== match) return false
      return index
    })
    .filter(index => index !== false) as number[]
}

function _nearestIndexesFor(item: string, query: string): number[] | false {
  const letters = query.split('')
  const indexes: number[][] = []
  const indexesOfFirstLetter = _indexesOfFirstLetter(item, query)
  indexesOfFirstLetter.forEach((startingIndex, loopingIndex) => {
    let index = startingIndex + 1
    indexes[loopingIndex] = [startingIndex]
    for (let i = 1; i < letters.length; i++) {
      const letter = letters[i]
      index = item.indexOf(letter, index)
      if (index === -1) {
        delete indexes[loopingIndex]
        break
      }
      indexes[loopingIndex].push(index)
      index++
    }
  })

  if (!indexes.length) return false
  return indexes.sort((a, b) => {
    if (a.length === 1) {
      return a[0] - b[0]
    }
    const _a = a[a.length - 1] - a[0]
    const _b = b[b.length - 1] - b[0]
    return _a - _b
  })[0]
}

function _isMatch(item: string, query: string, caseSensitive?: boolean) {
  const normItem = caseSensitive ? item : item.toLowerCase()
  const normQuery = caseSensitive ? query : query.toLowerCase()
  const indexes = _nearestIndexesFor(normItem, normQuery)
  if (!indexes) return false

  // Exact matches should be first.
  if (item === query) return 1

  // If we have more than 2 letters, matches close to each other should be first.
  if (indexes.length > 1) {
    return 2 + (indexes[indexes.length - 1] - indexes[0])
  }

  // Matches closest to the start of the string should be first.
  return 2 + indexes[0]
}

export type GetKeysCallback<Item> = (item: Item) => (string | null | undefined)[]
export function fuzzySearchMatch<Item extends Record<string, any>>(
  item: Item,
  keys: (keyof Item)[] | GetKeysCallback<Item>,
  searchTerm: string | null | undefined,
): number | null {
  if (!searchTerm?.trim()) return null
  const values: string[] =
    typeof keys === 'function'
      ? (keys(item).filter(Boolean) as string[])
      : (keys
          .map<string | undefined>(key => {
            let value = item[key]
            if (Array.isArray(value) && typeof value[0] === 'string') {
              value = value.join(' ')
            }
            return typeof value !== 'string' ? undefined : value
          })
          .filter(Boolean) as string[])
  const highestScore = values.reduce((highestScore, value) => {
    const score = _isMatch(value, searchTerm)
    if (score === false) return highestScore
    return Math.max(highestScore, score)
  }, -Infinity)
  if (highestScore === -Infinity) return null
  return highestScore
}

type ItemWithScore<T extends Record<string, any>> = T & {
  _score: number
}
export function fuzzySearch<Item extends Record<string, any>>(
  items: Item[],
  keys: KeysOfType<Item, string | string[]>[] | GetKeysCallback<Item>,
  searchTerm: string | null | undefined,
  { bail }: FuzzySearchOpts = {},
): ItemWithScore<Item>[] {
  const matchingItems: ItemWithScore<Item>[] = []
  for (const item of items) {
    const score = fuzzySearchMatch(item, keys, searchTerm)
    if (score === null) continue
    matchingItems.push({ ...item, _score: score })
    if (bail) break
  }

  return matchingItems.sort((a, b) => b._score - a._score)
}
