import { useResource } from '@thesisedu/feature-react'
import React from 'react'

import { QuestionTypeResource, QuestionTypeWrapViewerContentProps } from '../types'

export interface WrapViewerContentProps extends QuestionTypeWrapViewerContentProps {
  questionTypes: string[]
}
export function WrapViewerContent({ questionTypes, children, ...rest }: WrapViewerContentProps) {
  const resources = useResource<QuestionTypeResource>('QuestionType')
  const questionResources = resources.filter(r => questionTypes.includes(r.identifier!))
  const wrappers = questionResources
    .filter(r => r.wrapViewerContent)
    .map(r => r.wrapViewerContent) as Required<QuestionTypeResource>['wrapViewerContent'][]

  let content = children
  for (const Wrapper of wrappers) {
    content = <Wrapper children={children} {...rest} />
  }

  return content
}
