import React from 'react'

import { AttachmentCreate } from './AttachmentCreate'
import { AttachmentRead } from './AttachmentRead'
import { CommonAttachmentFormField } from './common/CommonAttachmentFormField'
import { AttachmentFormFieldProps } from './types'

export const AttachmentFormField: React.FC<
  React.PropsWithChildren<AttachmentFormFieldProps>
> = props => (
  <CommonAttachmentFormField
    AttachmentRead={AttachmentRead}
    AttachmentCreate={AttachmentCreate}
    {...props}
  />
)
