import { Toast } from '@thesisedu/ui'
import React from 'react'

import { debug } from '../../log'
import { useExpectedErrorListener } from '../../useExpectedErrorListener'

export function RateLimitErrorWatcher() {
  const { error, onClose } = useExpectedErrorListener({
    error: 'RATE_LIMITED_ERROR',
  })
  React.useEffect(() => {
    if (error) {
      debug('user received rate limited error: %O', error.extensions?.rateLimit)
    }
  }, [error])

  if (error) {
    return (
      <Toast
        open
        onOpenChange={v => {
          if (!v) onClose()
        }}
        title={'Please slow down'}
        description={'You are making too many requests. Please wait a minute and try again.'}
        status={'error'}
      />
    )
  } else return null
}
