import { Feature } from '@thesisedu/feature'
import { InteractionTypeResource } from '@thesisedu/feature-interactions-react'

import { SubmitAssignment } from './SubmitAssignment'

export default function (feature: Feature) {
  feature.resources.addResource<InteractionTypeResource>({
    type: 'InteractionType',
    identifier: 'SUBMIT_ASSIGNMENT',
    handler: SubmitAssignment,
  })
}
