import { Modal, Text, Dropdown } from '@thesisedu/ui'
import React, { useState } from 'react'

import { useSegmentCalculationContext } from './SegmentCalculationContext'
import { ResetOption } from '../../types'

type ResetConfirmModalProps = {
  config: ResetOption
}

export function ResetConfirmModal({ config }: ResetConfirmModalProps) {
  const [visible, setVisible] = useState(false)
  const context = useSegmentCalculationContext(true)

  return (
    <>
      <Modal.Confirm
        title={config.title}
        onConfirm={async () => {
          await context.updateConfiguration(config.onConfirm)
          setVisible(false)
        }}
        confirm={config.confirm}
        cancel={{ children: 'Cancel' }}
        trigger={<Dropdown.Item>{config.triggerText}</Dropdown.Item>}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Text>{config.modalText}</Text>
      </Modal.Confirm>
    </>
  )
}
