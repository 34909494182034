import { CopyLinkAction } from './CopyLinkAction'
import { CopySegmentAsOwnAction } from './CopySegmentAsOwnAction'
import { CopySegmentToAllClassesAction } from './CopySegmentToAllClassesAction'
import { CopySegmentToClassAction } from './CopySegmentToClassAction'
import { EditCustomSegmentAction } from './EditCustomSegmentAction'
import { EnableChildrenAction, DisableChildrenAction } from './EnableChildrenAction'
import { EnableForAction } from './EnableForAction'
import { LockAction } from './LockAction'
import { MarkCompleteAction } from './MarkCompleteAction'
import { RemoveCustomSegmentAction } from './RemoveCustomSegmentAction'
import { RenameAction } from './RenameAction'
import { ScheduleSegmentAction } from './ScheduleAction'
import { CoursesReactFeature } from '../../CoursesReactFeature'
import { SegmentActionResource } from '../../types'

const DEFAULT_RESOURCES: SegmentActionResource[] = [
  CopySegmentToClassAction,
  CopySegmentToAllClassesAction,
  LockAction,
  EditCustomSegmentAction,
  EnableForAction,
  EnableChildrenAction,
  DisableChildrenAction,
  ScheduleSegmentAction,
  MarkCompleteAction,
  RenameAction,
  RemoveCustomSegmentAction,
  CopyLinkAction,
  CopySegmentAsOwnAction,
]
export function addSegmentActionResources(feature: CoursesReactFeature) {
  for (const resource of DEFAULT_RESOURCES) {
    feature.resources.addResource<SegmentActionResource>(resource)
  }
}
