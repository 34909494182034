import { PlusOutlined } from '@ant-design/icons'
import { Block } from '@thesisedu/web'
import { Button } from 'antd'
import Case from 'case'
import React, { useState } from 'react'

import { AddSegmentModal } from './AddSegmentModal'
import { useSegmentResources } from './useSegmentResource'
import { SegmentType } from '../types'

export interface AddSegmentButtonProps {
  availableTypes: SegmentType[]
  onAdd: (type: SegmentType) => void
  label?: string
}
export function AddSegmentButton({
  label = 'Add Segment',
  availableTypes,
  onAdd,
}: AddSegmentButtonProps) {
  const segments = useSegmentResources()
  const [multipleVisible, setMultipleVisible] = useState(false)
  let content: React.ReactElement
  if (availableTypes.length > 1) {
    content = (
      <>
        <Button icon={<PlusOutlined />} size={'small'} onClick={() => setMultipleVisible(true)}>
          {label}
        </Button>
        <AddSegmentModal
          label={label}
          onCancel={() => setMultipleVisible(false)}
          visible={multipleVisible}
          onItemSelected={item => {
            onAdd(item)
            setMultipleVisible(false)
          }}
          availableTypes={availableTypes}
        />
      </>
    )
  } else {
    const resource = segments.find(type => type.identifier === availableTypes[0])
    if (!resource) {
      throw new Error(`Segment type ${availableTypes[0]} is invalid, when creating.`)
    }
    const ResourceIcon = resource.icon || PlusOutlined
    content = (
      <Button icon={<ResourceIcon />} size={'small'} onClick={() => onAdd(resource.identifier)}>
        Add {resource.label || Case.title(resource.identifier)}
      </Button>
    )
  }

  return <Block marginTop={'@size-xs'}>{content}</Block>
}
