import { MatchAnswer } from '@thesisedu/feature-assignments-core'
import { Select } from '@thesisedu/ui'
import { styled, Body } from '@thesisedu/web'
import React from 'react'

import { QuestionInstructions } from './QuestionInstructions'

export interface DragDropDropdownFieldProps {
  sources: string[]
  destinations: string[]
  disabled?: boolean
  value?: MatchAnswer[]
  onChange?: (value: MatchAnswer[]) => void
  labelField: 'source' | 'destination'
}

export function DragDropDropdownField({
  sources,
  destinations,
  disabled,
  value,
  onChange,
  labelField,
}: DragDropDropdownFieldProps) {
  const oppositePropertyName = labelField === 'source' ? 'destination' : 'source'
  const selectOptions = labelField === 'source' ? destinations : sources
  const labelOptions = labelField === 'source' ? sources : destinations

  const handleChange = (val: string, label: string) => {
    if (!onChange) return
    const newAnswer = {
      [labelField]: label,
      [oppositePropertyName]: val,
    } as unknown as MatchAnswer
    const filteredVals = (value || []).filter(v => v[labelField] !== label)
    onChange([...filteredVals, newAnswer])
  }

  const formatSelectValue = (label: string) => {
    const correspondingAnswer = value?.find(v => {
      return v[labelField] === label
    })
    return correspondingAnswer?.[oppositePropertyName]
  }

  const validateMatchAnswer = (label: string) => {
    const allSources = (value || []).map(val => val.source)
    const duplicates = allSources.filter((val, idx) => allSources.indexOf(val) !== idx)
    const correspondingAnswer = value?.find(v => {
      return v.destination === label
    })
    return !!(correspondingAnswer && duplicates.includes(correspondingAnswer.source))
  }

  return (
    <div>
      <QuestionInstructions
        instructions={'Select the dropdown option that best fits the given word/scenario'}
      >
        {labelOptions.map(label => {
          return (
            <DropdownContainer key={label}>
              <StyledLabel>{label}</StyledLabel>
              <StyledSelect
                disabled={disabled}
                placeholder={'Select the best option'}
                onValueChange={val => handleChange(val, label)}
                value={formatSelectValue(label)}
                invalidAnswer={validateMatchAnswer(label)}
                style={{ minWidth: '50%' }}
              >
                {selectOptions.length &&
                  selectOptions.map(optionText => {
                    return <Select.Item key={optionText} value={optionText} children={optionText} />
                  })}
              </StyledSelect>
            </DropdownContainer>
          )
        })}
      </QuestionInstructions>
    </div>
  )
}

const DropdownContainer = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${props => props.theme['@size-s']};
`
const StyledLabel = styled(Body)`
  min-width: 50%;
  display: flex;
  align-items: center;
`
interface StyledSelectProps {
  invalidAnswer: boolean
}
const StyledSelect = styled(Select)<StyledSelectProps>`
  min-width: 50%;
  height: 100%;
  ${props => (props.invalidAnswer ? '&' : '&.noop')} {
    border: 1px solid ${props => props.theme['@red']};
    border-radius: ${props => props.theme['@border-radius-base']};
  }
`
