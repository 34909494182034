import React from 'react'

export function useForwardedRef<T>(
  forwardedRef?: ((instance: T | null) => void) | React.MutableRefObject<T | null> | null,
): React.MutableRefObject<T> {
  const objRef = React.useRef<T>()

  /**
   * We're using `useLayoutEffect` here instead of `useEffect` because we want
   * to make sure that the `ref` value is up to date before other places in the
   * the execution cycle try to read it.
   */
  React.useLayoutEffect(() => {
    if (!forwardedRef) {
      return
    }

    if (typeof forwardedRef === 'function') {
      forwardedRef(objRef.current as any)
    } else {
      forwardedRef.current = objRef.current as any
    }

    return () => {
      if (typeof forwardedRef === 'function') {
        forwardedRef(null)
      } else {
        forwardedRef.current = null
      }
    }
  }, [forwardedRef])

  return objRef as any
}
