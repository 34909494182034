import { v4 as uuid } from 'uuid'

import { CreatedSegment, useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { SegmentType } from '../schema'
import { GroupSegment } from '../types'

export interface UseCreateSegmentResult {
  createSegment: (
    segment: Partial<CreatedSegment & { weight?: number }>,
    parentId?: string,
  ) => CreatedSegment
}
export interface UseCreateSegmentOpts {
  selectedTerm: GroupSegment
}
export function useCreateSegment({ selectedTerm }: UseCreateSegmentOpts): UseCreateSegmentResult {
  const { foreignSegmentsRef, addChanges, addSegment } = useSplitViewPendingChangesContext(true)
  return {
    createSegment({ weight, ...segment }, _parentId) {
      const created = {
        name: 'Unnamed Segment',
        type: SegmentType.Group,
        id: uuid(),
        config: {},
        isCreated: true,
        ...segment,
      } satisfies CreatedSegment

      let parentId: string
      if (_parentId) {
        parentId = _parentId
      } else if (segment.type === SegmentType.Group) {
        const firstTopic = selectedTerm.childSegments?.[0]
        if (!firstTopic) {
          throw new Error('Cannot create a group segment with no topics.')
        }
        parentId = firstTopic.id
      } else if (segment.type === SegmentType.Topic) {
        parentId = selectedTerm.id
      } else {
        throw new Error('Creating invalid segment type.')
      }

      addSegment(created)
      addChanges({
        metadataOverrides: weight != null ? [{ id: created.id, weight }] : [],
        structureOverrides: [{ id: created.id, parentId }],
      })
      foreignSegmentsRef.current[created.id] = created
      return created
    },
  }
}
