import { PartialAssignment } from '@thesisedu/feature-assignments-core'
import { Form, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { AssignmentAttemptsField } from '../../grading/AssignmentAttemptsField'
import { AssignmentFlags } from '../../grading/AssignmentFlags'
import { RevealAnswersFields } from '../../grading/RevealAnswersFields'

export interface DefaultAssignmentSettingsCategoryProps {
  value?: PartialAssignment
  baseValue?: PartialAssignment
  onChange: (value: PartialAssignment) => void
  categoryId?: string | null
  categoryName?: string
}
export function DefaultAssignmentSettingsCategory({
  categoryId,
  categoryName,
  baseValue,
  value,
  onChange,
}: DefaultAssignmentSettingsCategoryProps) {
  const form = Form.useControlledForm({ value: { ...baseValue, ...value }, onChange })
  return (
    <Form form={form}>
      <VSpaced align={'stretch'} space={'m'}>
        <div>
          <Text level={'h3'} bottom={false}>
            {categoryName || 'All Assignments'}
          </Text>
          <Text level={'s'} color={'secondary'}>
            {categoryName ? (
              <>
                These settings apply to all assignments in the <strong>{categoryName}</strong>{' '}
                category.
              </>
            ) : (
              <>
                These settings apply to all new assignments, unless overridden by their grading
                category settings.
              </>
            )}
          </Text>
        </div>
        <RevealAnswersFields />
        <AssignmentAttemptsField />
        <AssignmentFlags />
      </VSpaced>
    </Form>
  )
}
