import { createHeadlessEditor } from '@lexical/headless'

import { CORE_NODES } from './editorNodes'
import { filter } from './filter'
import { editorToHtml } from './legacy/converters/util/dom'
import { EditorValue } from './types'

const VALID_TYPES = [...CORE_NODES.map(coreNode => coreNode.getType()), 'text', 'paragraph', 'root']

export async function getHtmlFromEditorState(state: EditorValue): Promise<string> {
  const filteredState = filter(state, node => {
    return VALID_TYPES.includes(node.type)
  })
  const editor = createHeadlessEditor({
    nodes: CORE_NODES,
  })
  return new Promise<string>(resolve => {
    editor.setEditorState(
      editor.parseEditorState(filteredState, () => {
        resolve(editorToHtml(editor))
      }),
    )
  })
}
