import { styled } from '@thesisedu/web'
import React from 'react'

import { getNoteColor, NOTE_WIDTH, SINGLE_8TH_RIGHT_OFFSET } from './Note'
import { HEIGHTS } from './types'
import { Accidental } from '../editor/SimpleContext'
import SolfegeHiDo from '../svg/SolfegeHiDo'

const BASIC_SOLFEGE: React.FC<React.PropsWithChildren<unknown>>[] = [
  require('../svg/SolfegeDo').default,
  require('../svg/SolfegeRe').default,
  require('../svg/SolfegeMi').default,
  require('../svg/SolfegeFa').default,
  require('../svg/SolfegeSol').default,
  require('../svg/SolfegeLa').default,
  require('../svg/SolfegeTi').default,
]
const SHARP_SOLFEGE: (React.FC<React.PropsWithChildren<unknown>> | null)[] = [
  require('../svg/SolfegeSi').default,
  require('../svg/SolfegeLi').default,
  null,
  require('../svg/SolfegeDi').default,
  require('../svg/SolfegeRi').default,
  null,
  require('../svg/SolfegeFi').default,
  require('../svg/SolfegeSi').default,
  require('../svg/SolfegeLi').default,
  null,
  require('../svg/SolfegeHighDi').default,
  require('../svg/SolfegeHighRi').default,
  null,
  require('../svg/SolfegeHighFi').default,
]
const FLAT_SOLFEGE: (React.FC<React.PropsWithChildren<unknown>> | null)[] = [
  null,
  require('../svg/SolfegeLe').default,
  require('../svg/SolfegeTe').default,
  null,
  require('../svg/SolfegeRa').default,
  require('../svg/SolfegeMe').default,
  null,
  require('../svg/SolfegeSe').default,
  require('../svg/SolfegeLe').default,
  require('../svg/SolfegeTe').default,
  null,
  require('../svg/SolfegeHighRa').default,
  require('../svg/SolfegeHighMe').default,
  null,
  require('../svg/SolfegeHighSi').default,
]

export interface SolfegeProps {
  pitch: number
  accidental?: Accidental
  offset?: number
  inBeam?: boolean
  duration?: number
}
export function Solfege({ pitch, accidental, offset = 0, inBeam, duration }: SolfegeProps) {
  let SVGComponent
  if (pitch === 7 && (!accidental || accidental === 'natural')) SVGComponent = SolfegeHiDo
  else if (accidental === 'sharp')
    SVGComponent =
      SHARP_SOLFEGE[(pitch + offset + 3 + SHARP_SOLFEGE.length * 3) % SHARP_SOLFEGE.length] ||
      React.Fragment
  else if (accidental === 'flat')
    SVGComponent =
      FLAT_SOLFEGE[(pitch + offset + 3 + FLAT_SOLFEGE.length * 3) % FLAT_SOLFEGE.length] ||
      React.Fragment
  else SVGComponent = BASIC_SOLFEGE[(pitch + offset + 21) % 7]
  return (
    <Container
      pitch={pitch}
      accidental={accidental}
      offsetForSingle8th={!inBeam && duration === 0.125}
    >
      <SVGComponent />
    </Container>
  )
}

const Container = styled.div<{
  pitch: number
  accidental?: Accidental
  offsetForSingle8th: boolean
}>`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-xs']}
    ${props => props.theme['@size-xs']} 0;
  margin-left: ${props => (props.offsetForSingle8th ? -SINGLE_8TH_RIGHT_OFFSET : 0)}px;
  height: ${HEIGHTS.solfege}px;
  width: ${NOTE_WIDTH}px;
  svg {
    height: 100%;
    max-width: ${NOTE_WIDTH + 20}px;
    transform: translateX(calc((100% - ${NOTE_WIDTH}px) / -2));
    path:not([fill^='#502']):not([fill^='#512']):not([fill^='#FE']) {
      fill: ${props => props.theme[getNoteColor(props.pitch, props.accidental)]};
    }
    mask {
      display: none;
    }
  }
`
