// We have to import something here, otherwise this will be treated as a
// definition file for the entire reports-react feature.
import '../../CourseReportsReactFeature'

declare module '@thesisedu/feature-reports-react' {
  interface ReportDimensionResult {
    students?: {
      student: {
        id: string
      }
      class: {
        id: string
      }
    }
    classes?: {
      class: {
        id: string
      }
    }
    segment?: {
      segment: {
        id: string
      }
    }
  }
}
