import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { LicenseSubscriptionType } from './schema'

export const SubscriptionTypeSelect = React.forwardRef<any, SelectProps<string>>((props, ref) => (
  <Select<string> placeholder={'Select a type'} {...props} ref={ref}>
    <Select.Option value={LicenseSubscriptionType.StudentPay}>Student Pay</Select.Option>
    <Select.Option value={LicenseSubscriptionType.Individual}>Individual</Select.Option>
  </Select>
))
