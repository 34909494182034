import * as Legacy from '../..'
import { WidgetConverter } from '../types'

export interface LayoutWidget extends Legacy.Widget {
  childGroups?: {
    leftWidgets?: Legacy.Widget[]
    rightWidgets?: Legacy.Widget[]
  }
}

export interface CreateLayoutConverterOpts {
  leftWidth: number
  rightWidth: number
  label: string
  identifier: string
}
export function createLayoutConverter({
  leftWidth,
  rightWidth,
  label,
  identifier,
}: CreateLayoutConverterOpts): WidgetConverter<LayoutWidget> {
  return {
    weight: 0,
    label,
    identifier,
    convert(value, opts) {
      const leftWidgets = value.childGroups?.leftWidgets ?? []
      const rightWidgets = value.childGroups?.rightWidgets ?? []

      return [
        {
          type: 'columns',
          version: 1,
          children: [
            {
              type: 'column',
              width: leftWidth,
              version: 1,
              children: leftWidgets.flatMap(widget => Legacy.convertWidget(widget, opts)),
            },
            {
              type: 'column',
              width: rightWidth,
              version: 1,
              children: rightWidgets.flatMap(widget => Legacy.convertWidget(widget, opts)),
            },
          ],
        },
      ]
    },
  }
}
