const REMOVE = Symbol()

export interface MapInfo {
  path: string
  REMOVE: typeof REMOVE
}
export type MapCallback = (value: any, info: MapInfo) => any

function _mapDeep<T extends Record<string, any>>(
  base: T,
  callback: MapCallback,
  parentKeys: string[],
) {
  const result: Record<string, any> = {}
  for (const [key, value] of Object.entries(base)) {
    if (value && typeof value === 'object') {
      result[key] = _mapDeep(value, callback, [...parentKeys, key])
    } else {
      const r = callback(value, { REMOVE, path: [...parentKeys, key].join('.') })
      if (r !== REMOVE) result[key] = r
    }
  }

  return result as T
}

export function mapDeep<T extends Record<string, any>>(base: T, callback: MapCallback): T {
  return _mapDeep(base, callback, [])
}

function _mapDeepInPlace<T extends Record<string, any>>(
  base: T,
  callback: MapCallback,
  parentKeys: string[],
) {
  const anyBase = base as any
  for (const [key, value] of Object.entries(base)) {
    if (value && typeof value === 'object') {
      _mapDeepInPlace(value, callback, [...parentKeys, key])
    } else {
      const r = callback(value, { REMOVE, path: [...parentKeys, key].join('.') })
      if (r === REMOVE) delete anyBase[key]
      else anyBase[key] = r
    }
  }
}

export function mapDeepInPlace<T extends Record<string, any>>(base: T, callback: MapCallback) {
  _mapDeepInPlace(base, callback, [])
}
