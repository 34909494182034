import { WidgetConverter } from './types'
import * as Legacy from '..'

interface CalloutWidget extends Legacy.Widget {
  config?: {
    type: 'info' | 'warning' | 'success' | 'error'
    customIcon?: 'Transaction'
  }
  childGroups?: {
    children?: Legacy.Widget[]
  }
}

export default {
  weight: 0,
  label: 'callout',
  identifier: 'Callout',
  convert(value, opts) {
    return [
      {
        type: 'Callout',
        version: 1,
        variant: value.config?.type || 'info',
        customIcon: value.config?.customIcon,
        children:
          value.childGroups?.children?.flatMap(child => Legacy.convertWidget(child, opts)) || [],
      },
    ]
  },
} satisfies WidgetConverter<CalloutWidget>
