import { InteractionProps } from '@thesisedu/feature-interactions-react'
import { InteractionListItem } from '@thesisedu/feature-interactions-react/web'
import React from 'react'

export function CommentModeratedInteraction({ interaction }: InteractionProps) {
  return (
    <InteractionListItem
      fragment={interaction}
      targetChildren={
        <span>
          Your comment: "{interaction.metadata.comment}" was{' '}
          {interaction.metadata.status === 'APPROVED'
            ? 'approved'
            : `rejected with the reason "${interaction.metadata.rejectedReason}"`}
        </span>
      }
      userChildren={
        <span>
          {interaction.metadata.status === 'APPROVED' ? 'approved' : 'rejected'} the comment: "
          {interaction.metadata.comment}"
        </span>
      }
    />
  )
}
