import React from 'react'

import { MediaInput } from '../schema'

export interface MediaUpdatesContextValue {
  mediaUpdates?: Partial<MediaInput>
  setMediaUpdates: React.Dispatch<React.SetStateAction<Partial<MediaInput>>>
}
export const MediaUpdatesContext = React.createContext<MediaUpdatesContextValue | undefined>(
  undefined,
)

export interface MediaUpdatesProviderProps {
  children: React.ReactNode
}
export function MediaUpdatesProvider({ children }: MediaUpdatesProviderProps) {
  const [mediaUpdates, setMediaUpdates] = React.useState<Partial<MediaInput>>({})
  return (
    <MediaUpdatesContext.Provider value={{ mediaUpdates, setMediaUpdates }} children={children} />
  )
}

export function useMediaUpdatesContext(): MediaUpdatesContextValue | undefined
export function useMediaUpdatesContext(require: false): MediaUpdatesContextValue | undefined
export function useMediaUpdatesContext(require: true): MediaUpdatesContextValue
export function useMediaUpdatesContext(require?: boolean): MediaUpdatesContextValue | undefined {
  const context = React.useContext(MediaUpdatesContext)
  if (!context && require) {
    throw new Error('MediaUpdatesContext is required, yet not provided.')
  }
  return context
}
