import { useExpectedErrorListener } from '@thesisedu/feature-apollo-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Modal, Text } from '@thesisedu/ui'
import React from 'react'

export function GoogleClassroomApiDisabledHandler() {
  const root = useFeatureRoot()
  const { error, onClose } = useExpectedErrorListener({
    error: 'GOOGLE_CLASSROOM_API_DISABLED',
  })
  if (error) {
    return (
      <Modal
        status={'error'}
        title={'Your IT team needs to enable access to Google Classroom.'}
        width={700}
        onVisibleChange={onClose}
        visible
      >
        <>
          <Text weight={'bold'}>
            Before you can use Google Classroom with {root.appOptions.name}, you'll need to have
            your IT team enable access to the Google Classroom API for {root.appOptions.name}.
          </Text>
          <br />
          <Text>
            It might be helpful to send{' '}
            <a href={'https://support.google.com/edu/classroom/answer/6250906'}>
              this support article
            </a>{' '}
            to your IT team.
          </Text>
        </>
      </Modal>
    )
  } else return null
}
