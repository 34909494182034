import { FooterControl, Player } from '@thesisedu/react'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { PlaybackFooterLocation } from './PlaybackFooterContext'
import { usePlaybackFooterItems } from './usePlaybackFooterItems'

export interface MediaPlayerProps {
  extraFooterItems?: React.ReactElement[]
  children: React.ReactElement
}
export function MediaPlayer({ extraFooterItems, children }: MediaPlayerProps) {
  const footerItems = usePlaybackFooterItems(PlaybackFooterLocation.Toolbar)
  const trackItems = usePlaybackFooterItems(PlaybackFooterLocation.Track)
  const beforeFooterItems = usePlaybackFooterItems(PlaybackFooterLocation.BeforeFooter)
  return (
    <Container>
      <Player
        children={children}
        noContext
        footerProps={{
          beforeFooter: <>{beforeFooterItems}</>,
          decorators: <>{trackItems}</>,
          controls: [
            ...footerItems,
            FooterControl.SkipBackward,
            FooterControl.SkipForward,
            FooterControl.PlaybackSpeed,
            FooterControl.Volume,
            FooterControl.Fullscreen,
            ...(extraFooterItems ?? []),
          ],
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${s.var('radii.2')};
  overflow: hidden;
`
