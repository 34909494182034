import { Pitch } from '@sammarks/icons'
import { useFeature } from '@thesisedu/feature-react'
import { ThemeProvider, BlockSpin, styled } from '@thesisedu/web'
import { Piano as TonePiano } from '@tonejs/piano'
import { Drawer } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import * as Tone from 'tone'

import { Piano } from './pitch/Piano'
import { MediaReactFeature } from '../../MediaReactFeature'
import { debug, error } from '../../log'

const PitchIcon = styled.a`
  color: inherit;
  display: flex;
`

export function DefaultKeyboard() {
  const [loading, setLoading] = useState(true)
  const [playingKey, setPlayingKey] = useState<string | null>(null)
  const piano = useRef<any>()
  useEffect(() => {
    if (piano.current) {
      if (playingKey) {
        debug('playing key', playingKey)
        piano.current.keyDown(playingKey)
        return () => {
          if (piano.current) {
            debug('off playing key', playingKey)
            piano.current.keyUp(playingKey)
          }
        }
      }
    }
  }, [playingKey])
  useEffect(() => {
    piano.current = new TonePiano({
      velocities: 1,
      minNote: 12 * 4,
      maxNote: 12 * 5,
      samples: `${process.env.PUBLIC_URL}/audio/`,
    }).toDestination()
    debug('loading piano samples')
    piano.current
      .load()
      .then(() => {
        debug('loaded piano samples')
        setLoading(false)
      })
      .catch((err: any) => {
        error('error loading piano samples')
        error(err)
      })
  }, [])

  return loading ? (
    <BlockSpin />
  ) : (
    <Piano
      onKeyChange={midiKey => {
        setPlayingKey(midiKey === null ? null : Tone.Midi(midiKey).toNote())
      }}
    />
  )
}

export const PitchTool: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [visible, setVisible] = useState(false)
  const feature = useFeature(MediaReactFeature)
  const Keyboard = feature.keyboard || DefaultKeyboard
  return (
    <>
      <Drawer
        placement={'bottom'}
        title={'Pitch Tool'}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ textAlign: 'center' }}
        height={350}
      >
        <ThemeProvider theme={'light'}>
          <Keyboard />
        </ThemeProvider>
      </Drawer>
      <PitchIcon
        onClick={() => {
          setVisible(true)
        }}
        title={'Pitch Tool'}
      >
        <Pitch />
      </PitchIcon>
    </>
  )
}
