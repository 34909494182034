import { NotFound, LoadingIndicator } from '@thesisedu/react'
import { Spacer } from '@thesisedu/web'
import React from 'react'

import { SavedReportActions } from './SavedReportActions'
import { useSavedReport } from './useSavedReport'
import { UnpackedReportPage } from '../reporting/result/UnpackedReportPage'
import { SavedReportFragment } from '../schema'

export interface SavedReportPageContextValue {
  savedReport: SavedReportFragment
}
export const SavedReportPageContext = React.createContext<SavedReportPageContextValue | undefined>(
  undefined,
)

export function useSavedReportPageContext(): SavedReportPageContextValue | undefined
export function useSavedReportPageContext(require: false): SavedReportPageContextValue | undefined
export function useSavedReportPageContext(require: true): SavedReportPageContextValue
export function useSavedReportPageContext(
  require?: boolean,
): SavedReportPageContextValue | undefined {
  const context = React.useContext(SavedReportPageContext)
  if (!context && require) {
    throw new Error('SavedReportPageContext is required, yet not provided.')
  }
  return context
}

export interface SavedReportPageProps {
  savedReportId: string
  onReportChange: (packed: string) => void
}
export function SavedReportPage({ savedReportId, onReportChange }: SavedReportPageProps) {
  const { savedReport, loading } = useSavedReport(savedReportId)

  if (loading) {
    return <LoadingIndicator block />
  } else if (savedReport) {
    return (
      <SavedReportPageContext.Provider value={{ savedReport }}>
        <UnpackedReportPage
          onReportPacked={onReportChange}
          report={savedReport.report}
          ConfigurationRight={props => (
            <>
              <Spacer horizontal={'@size-s'} />
              <SavedReportActions {...props} savedReport={savedReport} />
            </>
          )}
        />
      </SavedReportPageContext.Provider>
    )
  } else {
    return <NotFound description={'That saved report could not be found.'} />
  }
}
