import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, message, Modal } from 'antd'
import React from 'react'

import { usePublishCourseVersionMutation } from '../queries/usePublishCourseVersionMutation'

export interface PublishCourseVersionButtonProps {
  courseVersionId: string
}
export const PublishCourseVersionButton: React.FC<
  React.PropsWithChildren<PublishCourseVersionButtonProps>
> = ({ courseVersionId }) => {
  const [publish, { loading }] = usePublishCourseVersionMutation({
    onCompleted: () => {
      message.success('Version published successfully!')
    },
  })
  return (
    <Button
      size={'large'}
      type={'primary'}
      icon={<CloudUploadOutlined />}
      loading={loading}
      onClick={() => {
        Modal.confirm({
          title: 'Are you sure you want to publish this course version?',
          width: 600,
          content: (
            <>
              <p>
                You cannot (and should not) undo this action, as it would result in a broken
                experience for teachers. So before you confirm below,{' '}
                <strong>you need to be very sure you know what you are doing.</strong>
              </p>
              <p>
                Once you publish, this version will automatically be used for all teachers whenever
                creating a new class with this course.
              </p>
            </>
          ),
          okType: 'danger',
          okText: 'Yes, I understand. Publish the version.',
          cancelText: 'Go back to safety.',
          cancelButtonProps: { type: 'primary' },
          onOk: async () => {
            await publish({
              variables: {
                input: {
                  id: courseVersionId,
                },
              },
            })
          },
        })
      }}
    >
      Publish Course Version
    </Button>
  )
}
