import {
  ApplicationOptions,
  FeatureDependencies,
  FeatureResource,
  FeatureUse,
} from '@thesisedu/feature'
import { s } from '@thesisedu/ui'
import React from 'react'

import { DeepLinkResolvedState } from './DeepLinkHandler'

export enum ReactHooks {
  WrapApp = 'feature-react:wrap-app',
  DeepLinkResolved = 'feature-react:deep-link-resolved',
}
export type DeepLinkResolvedPayload = FoundResolution | string
export interface DeepLinkResolvedContext {
  state: DeepLinkResolvedState
  resolution: FoundResolution
}

export type ReactFeatureDependencies = FeatureDependencies

export interface ReactAppOptions extends ApplicationOptions {
  uiTheme?: s.Theme
}
export interface RootConfiguration {
  wrapAppHook: string
}
export interface WrapAppContext<Options extends ReactAppOptions> {
  appOptions: Options
}

export type ReactUse<Options extends object = object> = (
  opts: Options,
) => FeatureUse<Options, ReactFeatureDependencies>

export interface NotFoundResolution {
  type: 'NotFound'
}
export interface AccessDeniedResolution {
  type: 'AccessDenied'
}
export interface FoundResolution {
  type: 'Found'
  resolvedPath: string
}
export type DeepLinkResolution = NotFoundResolution | AccessDeniedResolution | FoundResolution
export interface ParamMap {
  [paramId: string]: string | undefined
}
export interface DeepLinkProps {
  url: string
  params: ParamMap
  resolved: (resolution: DeepLinkResolution) => void
}
export interface DeepLinkParam {
  identifier: string
  required?: boolean
}
export interface DeepLinkResource extends FeatureResource {
  type: 'DeepLink'
  identifier: string
  params: DeepLinkParam[]
  Component: (props: DeepLinkProps) => React.ReactElement | null
}

export interface ModelLinkResource extends FeatureResource {
  type: 'ModelLink'
  /** The identifier is the GraphQL type */
  identifier: string
  /** Should contain an "[id]" placeholder, where we will inject the ID. */
  path: string
}

/** The result returned when getting a model's metadata. */
export interface ModelMetadataResult {
  /** The human-readable name of the model. For example: "comment" */
  name: string | React.ReactElement
  /** Whether or not the name starts with a vowel. */
  nameStartsWithVowel?: boolean
  /** Any detail we can provide for the model. For example, the comment text itself. */
  detail?: string | React.ReactElement
}

export type PartialFragment<T extends object> = Partial<Omit<T, 'id'>> & { id: string }
export type GetMetadataCallback<T extends object> = (
  fragment: PartialFragment<T>,
) => ModelMetadataResult
export interface ModelMetadataResource<T extends object = any> extends FeatureResource {
  type: 'ModelMetadata'
  /** The identifier is the GraphQL type */
  identifier: string
  /** Gets the metadata for the specified model. All fields on the model are optional. */
  getMetadata: GetMetadataCallback<T>
}

/** Props passed to an embedded file renderer. */
export interface EmbeddedFileRenderProps {
  /** The path to the file to render. */
  path: string
}
/** Allows specifying custom renderers for specific file types. */
export interface EmbeddedFileResource extends FeatureResource {
  type: 'EmbeddedFile'
  /** The extensions this resource supports. */
  extensions: string[]
  /** React component to render this file embed. */
  render: (props: EmbeddedFileRenderProps) => React.ReactElement
}

/** Props passed to a custom emoji renderer. */
export interface CustomEmojiRenderProps {
  /** The code used inside the original source text. Does not include the : */
  code: string
}
/** Allows specifying custom emoji renderers for supported text fields. */
export interface CustomEmojiResource extends FeatureResource {
  type: 'CustomEmoji'
  /** The codes this resource handles. Do not include the : */
  codes: string[]
  /** The component to render to replace the matched code. */
  render: (props: CustomEmojiRenderProps) => React.ReactElement
}

export interface DevtoolsEventTypes {}
