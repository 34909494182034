import { FeatureResource } from '@thesisedu/feature'
import { DocumentNode } from 'graphql'

import { ApolloReactFeature } from './ApolloReactFeature'
import { debug } from './log'

type DocumentNodeFn = () => DocumentNode
export interface PreloadQueryResource<Variables extends object = object> extends FeatureResource {
  type: 'PreloadQuery'
  identifier: string
  document: DocumentNode | DocumentNodeFn
  variables?: Variables
  /**
   * If a prefetch query is marked as required, the initial loading indicator will not be
   * dismissed until that query comes back. This means the user will see the initial loading
   * indicator for longer, but it won't be a cascade of loading indicators as soon as they
   * login.
   */
  required?: boolean
}

export async function preloadQueries(this: ApolloReactFeature) {
  const queries = this.resources.getResourcesForType<PreloadQueryResource>('PreloadQuery')
  const requiredQueries = queries.filter(query => query.required)
  const restQueries = queries.filter(query => !query.required)
  await Promise.all(
    requiredQueries.map(requiredQuery => {
      debug('preloading query %s (required)', requiredQuery.identifier)
      const document =
        typeof requiredQuery.document === 'function'
          ? requiredQuery.document()
          : requiredQuery.document
      return this.client.query({
        query: document,
        variables: requiredQuery.variables,
      })
    }),
  )

  Promise.all(
    restQueries.map(restQuery => {
      debug('preloading query %s (optional)', restQuery.identifier)
      const document =
        typeof restQuery.document === 'function' ? restQuery.document() : restQuery.document
      return this.client.query({
        query: document,
        variables: restQuery.variables,
      })
    }),
  )
}
