import { Feature } from '@thesisedu/feature'
import { AssignmentCalculationResource } from '@thesisedu/feature-assignments-react'
import { SegmentCalculationOptions } from '@thesisedu/feature-course-types'

import { SegmentCalculationEditor } from './SegmentCalculationEditor'

export default function addCalculationResources(feature: Feature) {
  feature.resources.addResource<AssignmentCalculationResource<SegmentCalculationOptions>>({
    type: 'AssignmentCalculation',
    identifier: 'segment',
    label: 'Segments (Quiz Vault)',
    Editor: SegmentCalculationEditor,
  })
}
