import { Select, Button, Popover, Menu } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { PopoverProps } from 'antd/es/popover'
import { NavArrowDown, Check, Calendar } from 'iconoir-react'
import moment from 'moment'
import React from 'react'

import { AntIconWrapper } from './AntIconWrapper'
import { HSpaced } from './Block'
import { DateTimePicker, DateTimePickerProps } from './DateTime'
import { Color } from './Typography'
import { DATE_FORMATS } from './constants'
import { ThemeContext } from './styledTypes'

export function convertToDateFilterInput(value: DateFilterValue) {
  if (value.mode === DateFilterMode.Before) return { before: value.date }
  else return { after: value.date }
}

interface DateFilterInput {
  before?: string
  after?: string
}
export function convertFromDateFilterInput(input: DateFilterInput) {
  if (input.before) return { date: input.before, mode: DateFilterMode.Before }
  else if (input.after) return { date: input.after, mode: DateFilterMode.After }
  else return undefined
}

export enum DateFilterMode {
  Before = 'BEFORE',
  After = 'AFTER',
}
export interface DateFilterValue {
  date: string
  mode: DateFilterMode
}
interface DateFilterChildrenProps {
  onClick: () => void
  valueLabel?: string
  label: string
  buttonProps?: Partial<ButtonProps>
  [key: string]: any
}
export interface DateFilterButtonProps {
  value?: DateFilterValue
  onChange: (value?: DateFilterValue) => void
  label?: string
  popoverProps?: Partial<PopoverProps>
  buttonProps?: Partial<ButtonProps>
  datePickerProps?: Partial<DateTimePickerProps>
  children?: (props: DateFilterChildrenProps) => React.ReactElement
  forceHide?: boolean
  [key: string]: any
}

export function DateFilterButton({
  label = 'Date',
  value: _value,
  onChange,
  popoverProps,
  buttonProps,
  datePickerProps,
  children = DefaultDateChildren,
  forceHide,
  ...rest
}: DateFilterButtonProps) {
  const [value, setValue] = React.useState(_value)
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (forceHide) setVisible(false)
  }, [forceHide])
  const theme = React.useContext(ThemeContext)
  React.useEffect(() => {
    setValue(_value)
  }, [_value])

  return (
    <Popover
      trigger={['click']}
      title={`Filter ${label}`}
      overlayStyle={{ width: 400 }}
      mouseLeaveDelay={3}
      {...popoverProps}
      visible={visible}
      onVisibleChange={setVisible}
      children={children({
        onClick: () => setVisible(true),
        label,
        buttonProps,
        valueLabel: _value
          ? `${_value.mode.toLowerCase()} ${moment(_value.date).format(DATE_FORMATS.FULL)}`
          : undefined,
        ...rest,
      })}
      content={
        <>
          <HSpaced style={{ width: '100%' }}>
            <Select<DateFilterMode>
              options={[
                { value: DateFilterMode.Before, label: 'Before' },
                { value: DateFilterMode.After, label: 'After' },
              ]}
              onChange={mode =>
                setValue({
                  mode,
                  date: value?.date || moment().format(),
                })
              }
              value={value?.mode || DateFilterMode.After}
            />
            <div style={{ flex: 1 }}>
              <DateTimePicker
                {...datePickerProps}
                value={value?.date ? moment(value.date).format(DATE_FORMATS.TEXT) : undefined}
                onChange={date => {
                  setValue({
                    mode: value?.mode || DateFilterMode.After,
                    date: moment(date).format(),
                  })
                }}
                inputProps={{
                  ...datePickerProps?.inputProps,
                  placeholder: `Pick a ${label}`,
                  style: { flex: 1 },
                }}
              />
            </div>
            <Button
              type={'primary'}
              icon={<Check />}
              disabled={!value?.date || !value?.mode}
              onClick={() => {
                onChange(value)
                setVisible(false)
              }}
            />
          </HSpaced>
          {value ? (
            <Button
              type={'default'}
              style={{ display: 'block', marginLeft: 'auto', marginTop: theme['@size-s'] }}
              onClick={() => {
                setValue(undefined)
                onChange(undefined)
                setVisible(false)
              }}
            >
              Clear Filter
            </Button>
          ) : null}
        </>
      }
    />
  )
}

function DefaultDateChildren({ onClick, valueLabel, label, buttonProps }: DateFilterChildrenProps) {
  return (
    <Button
      {...buttonProps}
      type={valueLabel ? 'primary' : 'default'}
      onClick={onClick}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      {valueLabel ? `${label} ${valueLabel}` : `Filter ${label}`}&nbsp;
      <AntIconWrapper children={<NavArrowDown />} />
    </Button>
  )
}

export function MenuDateChildren({
  onClick,
  valueLabel,
  label,
  buttonProps,
  ...rest
}: DateFilterChildrenProps) {
  return (
    <Menu.Item onClick={onClick} icon={<AntIconWrapper children={<Calendar />} />} {...rest}>
      <Color color={valueLabel ? '@primary-color' : '@text-color'}>
        {valueLabel ? `${label} ${valueLabel}` : `Filter ${label}`}
      </Color>
    </Menu.Item>
  )
}
