import { ResetPasswordForm, useProtection } from '@thesisedu/feature-users-web'
import { H1, Block, H1Subtitle, Body } from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

export function ResetPassword() {
  useProtection({ allowedGroups: [], redirect: '/' })
  const navigate = useNavigate()
  return (
    <>
      <H1 isBlock={false}>Reset Password</H1>
      <Block marginBottom={'@size-l'}>
        <H1Subtitle isBlock={false}>Enter your new password below.</H1Subtitle>
      </Block>
      <ResetPasswordForm onReset={() => navigate('../login')} />
      <Block marginTop={'@size-l'}>
        <Link to={'../login'}>
          <Body color={'@text-color-secondary'}>
            <ArrowLeft /> Go Back
          </Body>
        </Link>
      </Block>
    </>
  )
}
