import { LinkOutlined } from '@ant-design/icons'
import { Random } from '@thesisedu/feature-utils'
import { getDefaultEditorValue, LinkEmbedWidget } from '@thesisedu/feature-widgets-react'
import React from 'react'

import { SegmentType } from '../schema'
import { SimpleSegmentPresetResource } from '../types'

export const LinkPreset: () => SimpleSegmentPresetResource = () =>
  ({
    type: 'SimpleSegmentPreset',
    identifier: 'link',
    segmentType: SegmentType.Section,
    label: 'Link',
    icon: <LinkOutlined />,
    formValues: {
      config: {
        icon: 'link',
        label: 'Link',
        studentContent: getDefaultEditorValue([
          {
            id: Random.id(),
            type: 'LinkEmbed',
            version: 1,
            url: null,
          } satisfies LinkEmbedWidget,
        ]),
      },
    },
  }) satisfies SimpleSegmentPresetResource
