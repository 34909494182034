import { ReportMetricResource } from '@thesisedu/feature-reports-react'
import { Format } from '@thesisedu/feature-utils'
import { Clock, Timer } from '@thesisedu/react/icons'
import React from 'react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export default function addMetric(feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportMetricResource>({
    type: 'ReportMetric',
    identifier: 'totalTimeOnTask',
    metricKey: 'timeOnTask',
    summarization: 'sum',
    icon: <Timer />,
    title: 'Total Time on Task',
    description: 'Time spent reading content or completing assignments.',
    shortLabel: 'total time on task',
    format: value =>
      `${Format.number(value, 'number')} ${Format.plural('min', value, { hideCount: true })}`,
    shortFormat: value =>
      `${Format.number(value, 'abbreviated')} ${Format.plural('min', value, { hideCount: true })}`,
  })
  feature.resources.addResource<ReportMetricResource>({
    type: 'ReportMetric',
    identifier: 'averageTimeOnTask',
    metricKey: 'timeOnTask',
    summarization: 'average',
    icon: <Clock />,
    title: 'Average Time on Task',
    description: 'Time spent reading content or completing assignments.',
    shortLabel: 'average time on task',
    format: value =>
      `${Format.number(value, 'number')} ${Format.plural('min', value, { hideCount: true })}`,
  })
}
