import { useFreshRef } from '@thesisedu/feature-react'
import React from 'react'

import { PlacementOutlineProps } from './PlacementOutline'
import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { PlacementPaneImperativeHandle } from './types'
import { OutlineListImperativeHandle } from '../outline/OutlineList'
import { getDragEndPlacement } from '../outline/getDragEndPlacement'
import { OutlineData } from '../outline/useOutlineData'

export interface UsePlacementOutlineHandleOpts
  extends Pick<PlacementOutlineProps, 'sortableId' | 'onPlaced' | 'onSave'> {
  ref: React.ForwardedRef<PlacementPaneImperativeHandle>
  listRef: React.RefObject<OutlineListImperativeHandle>
  data: OutlineData
  offsetLeft: number | null
  dontUpdateForeignSegments?: boolean
}
export function usePlacementOutlineHandle({
  sortableId,
  onPlaced,
  onSave,
  data,
  offsetLeft,
  listRef,
  ref,
  dontUpdateForeignSegments,
}: UsePlacementOutlineHandleOpts) {
  const onPlacedRef = useFreshRef(onPlaced)
  const dataRef = useFreshRef(data)
  const offsetLeftRef = useFreshRef(offsetLeft)
  const dontUpdateForeignSegmentsRef = useFreshRef(dontUpdateForeignSegments)
  const { foreignSegmentsRef } = useSplitViewPendingChangesContext(false) ?? {}
  const sortableIdRef = useFreshRef(sortableId)
  React.useImperativeHandle(
    ref,
    () => {
      return {
        highlightSegment() {},
        ...listRef.current,
        getSortableId() {
          return sortableIdRef.current
        },
        onDragEnd({ movingSegment, overId }) {
          // Make sure we are using useFreshRef() for all of these, so we don't constantly have to
          // update the handle when props change.
          const onPlaced = onPlacedRef.current
          const data = dataRef.current
          const offsetLeft = offsetLeftRef.current
          const dontUpdateForeignSegments = dontUpdateForeignSegmentsRef.current
          if (onPlaced && data) {
            const dragEndResult = getDragEndPlacement({
              items: data.items,
              movingId: movingSegment.id,
              movingSegment,
              overId,
              offsetLeft,
              selectedTerm: data.selectedTerm,
            })
            if (dragEndResult) {
              // dontUpdateForeignSegments doesn't _exactly_ solve the problem here, because the
              // drop event is called on the CurrentClassPane, but we can't just not update the
              // foreignSegmentsRef if dontUpdate... is set because then it'll never be set. So
              // instead we have to prevent it from *updating* because we don't want to overwrite
              // the "pure" foreign segment with the "impure" one from the current (modified) course
              // configuration.
              const item = data.items.find(item => item.id === movingSegment.id)
              if (foreignSegmentsRef && item?.isForeign) {
                const cantUpdate =
                  dontUpdateForeignSegments && !!foreignSegmentsRef.current[movingSegment.id]
                if (!cantUpdate) {
                  foreignSegmentsRef.current[movingSegment.id] = movingSegment
                }
              }
              onPlaced?.(dragEndResult)
            }
          }
        },
        onSave,
      }
    },
    [onSave, listRef.current],
  )
}
