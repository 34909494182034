import { useApolloClient } from '@apollo/client'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { modifyFragmentDocument } from '@thesisedu/feature-apollo-react/dist/queries'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { FragmentDefinitionNode } from 'graphql'
import React from 'react'

import { debug } from '../log'
import {
  SegmentDecorationFragmentDoc,
  SegmentDecorationFragment,
  ClassDecorationsDocument,
  ClassDecorationsQuery,
  ClassDecorationsQueryVariables,
} from '../schema'

const MARK_VIEWED_TIMEOUT_MS = 30000 // 30 seconds.
export function useSegmentViewedDecorationUpdater(
  segmentId: string,
  timeout: number | false = MARK_VIEWED_TIMEOUT_MS,
) {
  const root = useFeatureRoot()
  const client = useApolloClient()
  // Ignoring undefined here because for all valid use-cases, this is present.
  const classId = useSelectedClassId()!
  const callback = React.useCallback(() => {
    if (client && root) {
      const fragmentDoc = modifyFragmentDocument(
        SegmentDecorationFragmentDoc,
        root.deps.hookManager,
      )
      const existingFragment = client.readFragment<SegmentDecorationFragment>({
        fragment: fragmentDoc,
        fragmentName: 'SegmentDecoration',
        id: `SegmentDecoration:${toGlobalId('Segment', segmentId)}`,
      })
      if (existingFragment && !existingFragment.isViewed) {
        debug('marking segment %s as viewed', segmentId)
        client.writeFragment<SegmentDecorationFragment>({
          fragment: fragmentDoc,
          fragmentName: 'SegmentDecoration',
          id: `SegmentDecoration:${toGlobalId('Segment', segmentId)}`,
          data: {
            ...existingFragment,
            isViewed: true,
          },
        })
      } else if (!existingFragment) {
        const queryData = client.readQuery<ClassDecorationsQuery, ClassDecorationsQueryVariables>({
          query: ClassDecorationsDocument,
          variables: { classId },
        })
        if (queryData?.node?.__typename === 'Class') {
          debug('marking segment %s as viewed (writing)', segmentId)

          const data: SegmentDecorationFragment = {
            __typename: 'SegmentDecoration',
            id: toGlobalId('Segment', segmentId),
            startedCount: null,
            segmentId,
            isViewed: true,
          }
          const requiredFields = (
            fragmentDoc.definitions[0] as FragmentDefinitionNode
          ).selectionSet.selections
            .map(s => {
              if (s.kind === 'Field') {
                return s.name.value
              }
            })
            .filter(f => !!f && !Object.keys(data).includes(f)) as string[]
          // Fill in the rest of the keys so we don't get errors.
          for (const otherKey of requiredFields) {
            debug('adding required field %s', otherKey)
            ;(data as any)[otherKey] = null
          }
          client.writeFragment<SegmentDecorationFragment>({
            fragment: fragmentDoc,
            fragmentName: 'SegmentDecoration',
            id: `SegmentDecoration:${toGlobalId('Segment', segmentId)}`,
            data,
          })
          client.writeQuery<ClassDecorationsQuery, ClassDecorationsQueryVariables>({
            query: ClassDecorationsDocument,
            variables: { classId },
            data: {
              ...queryData,
              node: {
                ...queryData.node,
                segmentDecorations: [...(queryData.node.segmentDecorations || []), data],
              },
            },
          })
        }
      }
    }
  }, [segmentId])
  React.useEffect(() => {
    if (timeout !== false) {
      const handle = setTimeout(callback, timeout)
      return () => {
        clearTimeout(handle)
      }
    }
  }, [callback, timeout])

  return callback
}
