import { H1, styled, Row, Block } from '@thesisedu/web'
import { renderAbc } from 'abcjs'
import { Col } from 'antd'
import React from 'react'

import { NoteItemContext } from './NoteItemContext'
import { PlaybackContainer, PlaybackContainerProps } from './PlaybackContainer'
import { Staff } from './Staff'
import { TuneItem, CommonSheetMusicViewerProps, NoteItemContextValue } from './types'

type ProvidedCommonProps = 'meter'
export interface SheetMusicViewerProps
  extends Omit<CommonSheetMusicViewerProps, ProvidedCommonProps> {
  title?: string
  abc: string
  renderOneLine?: boolean
  empty?: React.ReactElement
  playbackContainerProps?: Partial<PlaybackContainerProps>
  actions?: React.ReactElement
  noteItemContext?: NoteItemContextValue
}
export function SheetMusicViewer(props: SheetMusicViewerProps) {
  const {
    title,
    abc,
    empty,
    actions,
    renderOneLine,
    playbackContainerProps,
    noteItemContext,
    ..._commonProps
  } = props
  // If rendering one-line, remove empty measures.
  const contents = renderAbc('*', abc) as TuneItem[]
  const commonProps: CommonSheetMusicViewerProps = {
    ..._commonProps,
    meter: contents[0].meter,
  }
  const firstItem = contents?.[0]
  if (!firstItem?.lines?.[0]?.staff?.length) return empty || null
  let lines
  if (renderOneLine) {
    const mergedStaff = firstItem.lines.slice(1).reduce((acc, line) => {
      return {
        ...acc,
        voices: [[...acc.voices[0], ...line.staff[0].voices[0]]],
      }
    }, firstItem.lines[0].staff[0])

    // Filter out empty measures.
    let barHasNotes = false
    mergedStaff.voices[0] = mergedStaff.voices[0].filter(voice => {
      if (voice.el_type === 'note') {
        barHasNotes = true
        return true
      } else if (voice.el_type === 'bar') {
        const original = barHasNotes
        barHasNotes = false
        return original
      }
    })

    lines = [
      <Staff
        staff={mergedStaff}
        chordOpacity={0.35}
        solfegeOffset={4}
        {...commonProps}
        key={'all'}
      />,
    ]
  } else {
    lines = firstItem.lines.map((line, index) => (
      <Staff
        staff={line.staff[0]}
        chordOpacity={0.35}
        solfegeOffset={4}
        {...commonProps}
        key={index}
      />
    ))
  }
  return (
    <Container className={'sheet-music-viewer'}>
      {title ? (
        <Block marginBottom={'@size-l'}>
          <Row>
            <Col xs={0} md={6} />
            <Col xs={24} md={12}>
              {title ? <H1 style={{ textAlign: 'center' }}>{title}</H1> : null}
            </Col>
            <Col xs={24} md={6} />
          </Row>
        </Block>
      ) : null}
      <PlaybackContainer abc={abc} {...playbackContainerProps} actions={actions}>
        <NoteItemContext.Provider value={noteItemContext}>{lines}</NoteItemContext.Provider>
      </PlaybackContainer>
    </Container>
  )
}

const Container = styled.div`
  * {
    font-family: ${props => props.theme['@alt-family'] || props.theme['@font-family']} !important;
    letter-spacing: 0 !important;
  }
`
