import { WidgetConverter } from './types'
import { convertPlainText } from './util/text'
import * as Legacy from '..'
import { getDefaultEditorValue } from '../../defaults'
import * as Nodes from '../../nodes'

interface ImageWidgetConfig {
  image?: string | null
  caption?: string
  alt?: string
  width?: number
  widgetMetadata?: never // We're not going to convert this.
  roundedCorners?: boolean
}

export default {
  weight: 0,
  label: 'image',
  identifier: 'Image',
  convert(value) {
    if (!value.config?.image) return []
    return [
      {
        type: 'Image',
        version: 1,
        caption: value.config?.caption
          ? convertPlainText(value.config.caption)
          : { editorState: getDefaultEditorValue([]) },
        width: value.config?.width ?? 'auto',
        altText: value.config?.alt,
        radius: value.config?.roundedCorners !== false ? '2' : '0',
        showCaption: !!value.config?.caption,
        src: value.config.image,
      } satisfies Nodes.Image,
    ]
  },
} satisfies WidgetConverter<Legacy.Widget<ImageWidgetConfig>>
