import { useMutateHook } from '@thesisedu/feature-react'
import { VSpaced } from '@thesisedu/ui'
import { PageHead, useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'

import { useSelectedClass } from '../../ClassContext'
import { ClassApprovalOverview } from '../../approval/ClassApprovalOverview'
import { AddStudentsButton } from '../../teacher/AddStudentsButton'
import { StudentsTable } from '../../teacher/students/StudentsTable'
import { StudentsPageActionsHook, StudentsPageHeaderHook } from '../../types'

export function StudentsRoute() {
  const cls = useSelectedClass(true)
  useBreadcrumb({ title: 'Students' })
  const actions = useMutateHook<StudentsPageActionsHook>(
    'classes-react:students-page-actions',
    [<AddStudentsButton key={'add-students'} classId={cls.id} />],
    { cls },
  )
  const header = useMutateHook<StudentsPageHeaderHook>('classes-react:students-page-header', [], {
    cls,
  })
  return (
    <>
      <PageHead title={`Students - ${cls.name}`} />
      <PageHeader title={'Students'} children={actions} />
      <VSpaced top={'s'} space={'m'}>
        {header}
        <ClassApprovalOverview classId={cls.id} />
        <StudentsTable classId={cls.id} />
      </VSpaced>
    </>
  )
}
