import { MailOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons'
import { useFeature, useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import IntercomWebFeature from '@thesisedu/feature-intercom-web'
import { UserRoutesHook, useViewerContext } from '@thesisedu/feature-users-react'
import { FeatureWeb, Shell } from '@thesisedu/feature-web'
import { Tooltip, useNavigate, Button } from '@thesisedu/react'
import { HelpCircle } from '@thesisedu/react/icons'
import { PageHead, styled, PageHeader, H2, Row, useBreadcrumb } from '@thesisedu/web'
import { Col, Divider } from 'antd'
import React from 'react'
import { Route } from 'react-router-dom'

const ContactGrid = styled.div`
  padding: 100px 0;
  h1 {
    font-weight: 500 !important;
    margin-bottom: 50px !important;
  }
  strong {
    font-weight: 800;
    color: ${props => props.theme['@blue']};
  }
  div h2 {
    margin-bottom: 0 !important;
  }
  div.ant-col > span {
    display: block;
    margin-bottom: 25px;
    font-size: 125%;
    > span[role='img'] {
      margin-right: 10px;
    }
  }
`

export function Resources() {
  const root = useFeatureRoot()!
  const intercom = useFeature<IntercomWebFeature>('@thesisedu/feature-intercom-web')
  useBreadcrumb({ title: 'Resources' })
  return (
    <ContactGrid>
      <PageHead title={'Resources & Training'} />
      <PageHeader title={'Need some help?'} />
      <H2>
        <a href={`https://${root.appOptions.domain}/support/portal`}>
          Visit our Knowledge Base &rarr;
        </a>
      </H2>
      <Divider orientation={'left'}>or</Divider>
      <Row>
        <Col xs={24} md={12} lg={8}>
          <H2>
            <strong>
              <a href={'tel:8005156490'}>(800) 515-6490</a>
            </strong>
          </H2>
          <span>
            <PhoneOutlined /> Phone Sales &amp; Support
          </span>
        </Col>
        <Col xs={24} md={12} lg={8}>
          <H2>
            <strong>
              <a href={'mailto:support@prodigiesacademy.com'}>support@prodigiesacademy.com</a>
            </strong>
          </H2>
          <span>
            <MailOutlined /> Email Support
          </span>
        </Col>
        {intercom.intercom ? (
          <Col xs={24} md={12} lg={8}>
            <H2>
              <strong>
                <a onClick={() => intercom.intercom('showNewMessage')}>Send Message</a>
              </strong>
            </H2>
            <span>
              <MessageOutlined /> Live Chat Support
            </span>
          </Col>
        ) : null}
      </Row>
    </ContactGrid>
  )
}

function HelpButton() {
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  if (viewer?.group === 'TEACHER') {
    return (
      <Tooltip title={'Resources & Support'}>
        <Button
          icon={<HelpCircle />}
          onClick={() => {
            navigate('/teacher/resources')
          }}
        />
      </Tooltip>
    )
  } else return null
}

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        return [...routes, <Route key={'resources'} path={'resources'} element={<Resources />} />]
      } else {
        return routes
      }
    },
  )
  web.deps.hookManager.registerMutateHook<Shell.TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    items => {
      return [...items, { weight: 10, element: <HelpButton key={'help'} /> }]
    },
  )
}
