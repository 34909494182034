import { styled, StyledThemeContext } from '@thesisedu/web'
import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

export const ContentPageContainer = styled.section`
  /* page-break-after: page; */
  page: contentPage;
`

interface Theme {
  [key: string]: string
}
const scaleTheme = (theme: Theme, scale: number): Theme => {
  const result = { ...theme }
  for (const key of Object.keys(theme)) {
    const numberVal = parseFloat(theme[key].replace('px', ''))
    if (!isNaN(numberVal)) {
      result[key] = `${numberVal * scale}px`
    }
  }

  return result
}

export interface ContentPageProps {
  className?: string
}
export function ContentPage({ children, className }: React.PropsWithChildren<ContentPageProps>) {
  const existingTheme = React.useContext(StyledThemeContext)
  return (
    <ContentPageContainer className={className}>
      <ThemeProvider theme={scaleTheme(existingTheme, 0.8)}>{children}</ThemeProvider>
    </ContentPageContainer>
  )
}

export const FullPage = styled(ContentPageContainer)`
  page: full;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  break-inside: avoid;
  overflow: hidden;
  max-height: 100px;
`

export const DefaultPageStyle = createGlobalStyle<{
  product: string
  domain: string
  hasCoverPage?: boolean
}>`
  * {
    // We don't want WebKit futzing with our colors.
    -webkit-print-color-adjust: exact;
  }

  body.rendered .pagedjs_page ${FullPage} {
    // We only want it position: absolute whenever the full page is being rendered
    // so we don't mess with other content appearing on top of it.
    position: absolute;
    max-height: none;
    zoom: 137%; // This is the difference between the pixel size here and the one in Sketch.
  }

  body:not(.rendered) ${FullPage} * {
    position: static !important;
    transform: none !important;
  }

  #root {
    min-height: 0 !important;
  }

  // We don't want the last page visible unless it was explicitly defined as a page.
  body.rendered .pagedjs_pages .pagedjs_page:last-child:not(.pagedjs_named_page) {
    display: none;
  }

  @media print {
    @page {
      size: letter;
      margin: 2cm;
      @bottom-left {
        content: 'Copyright © ${() => new Date().getFullYear()} ${props => props.product}';
        color: ${props => (props.theme as any)['@text-color-secondary']};
        font-size: ${props => (props.theme as any)['@font-size-sm']};
      }
      @bottom-center {
        content: '${props => props.domain}';
        text-align: center;
        color: ${props => (props.theme as any)['@text-color-secondary']};
        font-size: ${props => (props.theme as any)['@font-size-sm']};
      }
      @bottom-right {
        content: 'Page ' counter(page);
        color: ${props => (props.theme as any)['@text-color-secondary']};
        font-size: ${props => (props.theme as any)['@font-size-sm']};
      }
    }
    ${props =>
      props.hasCoverPage
        ? `
      @page :first {
        size: letter;
        margin: 0 !important;
        @bottom-left { content: '' !important; }
        @bottom-center { content: '' !important; }
        @bottom-right { content: '' !important; }
      }
    `
        : ''}

    @page full {
      size: letter;
      margin: 0 !important;
      @bottom-left { content: '' !important; }
      @bottom-center { content: '' !important; }
      @bottom-right { content: '' !important; }
    }

    @page contentPage {
      size: letter;
      margin: 2cm !important;
    }
  }
`
