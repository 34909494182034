import React from 'react'

export interface DragDropOutlineContextValue {
  draggingId?: string
  offsetLeft: number | null
}
export const DragDropOutlineContext = React.createContext<DragDropOutlineContextValue | undefined>(
  undefined,
)

export function useDragDropOutlineContext(): DragDropOutlineContextValue | undefined
export function useDragDropOutlineContext(require: false): DragDropOutlineContextValue | undefined
export function useDragDropOutlineContext(require: true): DragDropOutlineContextValue
export function useDragDropOutlineContext(
  require?: boolean,
): DragDropOutlineContextValue | undefined {
  const context = React.useContext(DragDropOutlineContext)
  if (!context && require) {
    throw new Error('DragDropOutlineContext is required, yet not provided.')
  }
  return context
}
