import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Button, Form, Input, message } from 'antd'
import React from 'react'

import { useResetPasswordMutation } from './schema'

export interface ResetPasswordFormProps {
  onReset: () => void
}
export const ResetPasswordForm: React.FC<React.PropsWithChildren<ResetPasswordFormProps>> = ({
  onReset,
}) => {
  const [reset, { loading }] = useResetPasswordMutation({
    onError: onMutationError('There was an error resetting your password.'),
    onCompleted: () => {
      message.success('Password reset successfully! Please login again.')
      onReset()
    },
  })
  const [form] = Form.useForm()
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={values => {
        const params = new URLSearchParams(window.location.search)
        const jwt = params.get('jwt')
        if (!jwt) {
          message.error('Uh oh! Something went wrong. Try clicking the link in your email again.')
        } else {
          reset({
            variables: {
              input: {
                jwt: params.get('jwt')!,
                password: values.password,
              },
            },
          })
        }
      }}
      hideRequiredMark
    >
      <Form.Item
        name={'password'}
        rules={[
          { required: true, min: 8, message: 'Password must be at least 8 characters long.' },
        ]}
      >
        <Input type={'password'} data-sentry-ignore placeholder={'Password'} size={'large'} />
      </Form.Item>
      <Form.Item
        name={'confirmPassword'}
        rules={[
          { required: true, message: 'Password confirmation is required.' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Passwords must match.')
            },
          }),
        ]}
      >
        <Input
          type={'password'}
          data-sentry-ignore
          placeholder={'Confirm password'}
          size={'large'}
        />
      </Form.Item>
      <Button block loading={loading} size={'large'} type={'primary'} onClick={form.submit}>
        Reset Password
      </Button>
    </Form>
  )
}
