import { LicenseCourses } from '@thesisedu/feature-course-types'
import { Checkbox } from '@thesisedu/react'
import { Block, BlockSpin, BodySmall, HSpaced, Space, styled } from '@thesisedu/web'
import { omit } from 'lodash'
import React from 'react'

import { LicenseCourseCheckbox } from './LicenseCourseCheckbox'
import { LicenseCourseSettings } from './LicenseCourseSettings'
import { OrderDirection, useListCoursesQuery } from '../schema'

export interface LicenseCourseFieldProps {
  value?: LicenseCourses
  onChange?: (value: LicenseCourses) => void
}
export function LicenseCourseField({ value = {}, onChange }: LicenseCourseFieldProps) {
  const { data, loading } = useListCoursesQuery({
    variables: {
      first: 200,
      orderBy: 'name',
      orderDirection: OrderDirection.Asc,
      withVersionLabels: true,
    },
  })
  if (loading) {
    return <BlockSpin />
  }

  const courses = data?.courses.edges.map(edge => edge.node)
  const allCoursesAllLabels = [
    ...new Set(
      courses?.reduce<string[]>((acc, course) => {
        return [...acc, ...(course.latestPublishedVersion?.allLabels || [])]
      }, []),
    ),
  ]
  return (
    <>
      <Container>
        {courses?.map(course => (
          <Block key={course.id} marginBottom={'@size-s'}>
            <LicenseCourseCheckbox
              value={value?.[course.id]}
              onChange={v => {
                if (onChange) {
                  const without = omit(value, [course.id])
                  onChange(v ? { ...without, [course.id]: v } : without)
                }
              }}
              course={course}
              allLabels={course.latestPublishedVersion?.allLabels}
            />
          </Block>
        ))}
      </Container>
      <Block marginTop={'@size-xs'}>
        <HSpaced>
          <BodySmall color={'@text-color-secondary'}>
            Set settings for all selected courses
          </BodySmall>
          <Space />
          <LicenseCourseSettings
            allLabels={allCoursesAllLabels}
            value={{ enabled: true }}
            onChange={v => {
              if (v && onChange) {
                onChange(
                  Object.keys(value).reduce<LicenseCourses>((newValue, key) => {
                    return {
                      ...newValue,
                      [key]: newValue[key]?.enabled ? { ...newValue[key], ...v } : newValue[key],
                    }
                  }, value),
                )
              }
            }}
          />
        </HSpaced>
      </Block>
      <Block marginTop={'@size-s'}>
        <Checkbox
          checked={value?.noAutomaticStateAssociation}
          onChange={e => {
            if (onChange) {
              onChange({
                ...value,
                noAutomaticStateAssociation: !!e.target.checked,
              } as LicenseCourses)
            }
          }}
        >
          Don't automatically associate courses with this license.
        </Checkbox>
      </Block>
    </>
  )
}

const Container = styled.div`
  max-height: 300px;
  overflow-y: auto;
  border: solid 1px ${props => props.theme['@border-color-base']};
  padding: ${props => props.theme['@padding-sm']};
  border-radius: ${props => props.theme['@border-radius-base']};
`
