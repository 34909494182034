import { SAMLButtonIcon, getSAMLButton } from '@thesisedu/feature-users-react'
import React from 'react'

import { useSchoologyLogin } from './useSchoologyLogin'
// @ts-ignore
import icon from '../../assets/icon.svg'

export const SchoologyLoginButton = getSAMLButton({
  identifier: 'schoology',
  icon: <SAMLButtonIcon src={icon} alt={'Schoology Icon'} />,
  label: 'Schoology',
  useLoginHook: useSchoologyLogin,
})
