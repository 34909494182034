import {
  Body,
  useTheme,
  getSize,
  styledContainer,
  VSpaced,
  getColor,
  styled,
  BodyExtraSmall,
} from '@thesisedu/react'
import { Cancel, DoubleCheck } from '@thesisedu/react/icons'
import React from 'react'

import { useGradeDisplay } from './grading/useGradeDisplay'

type GradeColors = [number, string][]
export const GRADE_COLORS: GradeColors = [
  [1, '@green'],
  [0.75, '@blue'],
  [0.3, '@orange'],
  [-Infinity, '@red'],
]

const ProgressContainer = styledContainer`
  min-height: 10px;
  border-radius: ${props => getSize(props.theme, '@border-radius-base')};
`

export interface GradeProps {
  grade: number
  totalPoints?: number
  prefix?: string
  suffix?: string
  hideProgress?: boolean
  style?: any
  size?: 'medium' | 'small'
  align?: string
}
export function Grade({
  grade,
  totalPoints,
  prefix,
  hideProgress,
  style,
  align,
  size = 'medium',
}: GradeProps) {
  const theme = useTheme()
  const colorResult = GRADE_COLORS.find(([percentage]) => percentage <= grade)
  const colorName = colorResult ? colorResult[1] : '@blue'
  const color = getColor(theme, colorName)
  const renderedGrade = useGradeDisplay({ percentage: grade, totalPoints })
  const icon = colorName === '@red' ? <Cancel /> : <DoubleCheck />

  return (
    <VSpaced
      style={{
        width: '100%',
        ...style,
      }}
      justify={'flex-end'}
      align={align}
      data-testid={'Grade'}
    >
      <GradeContainer
        $color={colorName}
        $size={size}
        data-testid={'text'}
        as={size === 'small' ? BodyExtraSmall : (Body as any)}
      >
        {icon} {prefix ? `${prefix} ${renderedGrade}` : renderedGrade}
      </GradeContainer>
      {hideProgress ? null : (
        <ProgressContainer
          style={{ backgroundColor: color, width: `${(grade * 100).toFixed(2)}` }}
        />
      )}
    </VSpaced>
  )
}

const GradeContainer = styled(Body)<{ $color: string; $size: GradeProps['size'] }>`
  background: ${props => props.theme[props.$color]};
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xxs']}
    ${props => props.theme[props.$size === 'small' ? '@size-xs' : '@size-s']};
  white-space: nowrap;
  color: white !important;
  font-weight: bold;
`
