import { IFeatureHookManager } from '@thesisedu/feature'
import { FragmentDefinitionNode, Kind } from 'graphql'

import { getMutateFragmentHook } from './constants'
import { FragmentModifyFn, MutateFragmentContext, MutateFragmentPayload } from './types'

export function useModifiedFragment(
  hookManager: IFeatureHookManager,
  fragmentName: string,
  modify: FragmentModifyFn,
) {
  hookManager.registerMutateHook<MutateFragmentPayload, MutateFragmentContext>(
    getMutateFragmentHook(fragmentName),
    (fragmentDoc, context) => {
      if (!context) throw new Error('Fragment context not available.')
      return modify(fragmentDoc, context)
    },
  )
}

export function addField(
  fragment: Readonly<FragmentDefinitionNode>,
  name: string,
  alias?: string,
): Readonly<FragmentDefinitionNode> {
  return {
    ...fragment,
    selectionSet: {
      ...fragment.selectionSet,
      selections: [
        ...fragment.selectionSet.selections,
        {
          kind: Kind.FIELD,
          name: { kind: Kind.NAME, value: name },
          alias: alias ? { kind: Kind.NAME, value: alias } : undefined,
        },
      ],
    },
  }
}

export function addFragmentReference(
  fragment: Readonly<FragmentDefinitionNode>,
  otherFragment: FragmentDefinitionNode | FragmentDefinitionNode[],
  context: MutateFragmentContext,
): Readonly<FragmentDefinitionNode> {
  const definitions = Array.isArray(otherFragment) ? otherFragment : [otherFragment]
  for (const other of definitions) {
    if (!context.additionalDefinitions.some(def => def.name.value === other.name.value)) {
      context.additionalDefinitions.push(other)
    }
  }

  if (definitions.length <= 0) {
    throw new Error('Cannot add a fragment reference when the fragment does not exist.')
  }

  return {
    ...fragment,
    selectionSet: {
      ...fragment.selectionSet,
      selections: [
        ...fragment.selectionSet.selections,
        {
          kind: Kind.FRAGMENT_SPREAD,
          name: { kind: Kind.NAME, value: definitions[0].name.value },
        },
      ],
    },
  }
}
