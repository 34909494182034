import { LoadingIndicator } from '@thesisedu/react'
import { Checkbox } from 'antd'
import { CheckboxGroupProps } from 'antd/lib/checkbox'
import React from 'react'

import { useUserGroupsQuery } from '../schema'

export interface GroupCheckboxGroupProps extends Omit<CheckboxGroupProps, 'options'> {}
export function GroupCheckboxGroup(rest: GroupCheckboxGroupProps) {
  const { data, loading } = useUserGroupsQuery()
  if (loading) return <LoadingIndicator />
  return (
    <Checkbox.Group
      {...rest}
      options={(data?.userGroups || []).map(group => ({
        label: group.label,
        value: group.name,
      }))}
    />
  )
}
