import {
  Popover,
  PopoverProps,
  Affix as AntAffix,
  AffixProps as AntAffixProps,
  Tooltip as AntTooltip,
} from 'antd'
import { TooltipPropsWithTitle } from 'antd/lib/tooltip'
import React from 'react'

import { StyledThemeContext } from '.'

export function StyledPopover({ className, ...rest }: PopoverProps) {
  return <Popover {...rest} overlayClassName={className} />
}

export interface AffixProps extends Omit<AntAffixProps, 'offsetTop' | 'offsetBottom'> {
  offsetTop?: string | boolean
  offsetBottom?: string | boolean
  noDiv?: boolean
}
export function Affix({ offsetTop, offsetBottom, noDiv, children, ...rest }: AffixProps) {
  const theme = React.useContext(StyledThemeContext)
  const top =
    offsetTop === true
      ? theme['@size-l']
      : typeof offsetTop === 'string'
      ? theme[offsetTop]
      : undefined
  const bottom =
    offsetBottom === true
      ? theme['@size-l']
      : typeof offsetBottom === 'string'
      ? theme[offsetBottom]
      : undefined
  const content = noDiv ? children : <div children={children} />
  return (
    <AntAffix
      {...rest}
      offsetTop={top ? parseFloat(top.replace('px', '')) : undefined}
      offsetBottom={bottom ? parseFloat(bottom.replace('px', '')) : undefined}
      children={content}
    />
  )
}

export interface TooltipProps extends Omit<Partial<TooltipPropsWithTitle>, 'title'> {
  title: string | React.ReactElement
}
export function Tooltip(rest: TooltipProps) {
  return <AntTooltip mouseEnterDelay={1} {...rest} />
}
