import {
  NoClassContentPayload,
  NoClassContentContext,
  ClassHooks,
} from '@thesisedu/feature-classes-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import React from 'react'

import LicensesReactFeature from '../LicensesReactFeature'

export default function reactResources(this: LicensesReactFeature) {
  this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, async children => {
    const { LicenseInvalidProvider } = require('../LicenseInvalidContext')
    const { ViewerLicenseContextProvider } = require('../ViewerLicenseContext')
    const { TrialBanner } = require('../TrialBanner')
    const { LicenseExpiringBanner } = require('../LicenseExpiringBanner')
    return (
      <ViewerLicenseContextProvider>
        <TrialBanner />
        <LicenseExpiringBanner />
        <LicenseInvalidProvider children={children} />
      </ViewerLicenseContextProvider>
    )
  })
  this.hookManager.registerMutateHook<NoClassContentPayload, NoClassContentContext>(
    ClassHooks.NoClassContent,
    children => {
      const { UsageSummary } = require('../UsageSummary')
      return (
        <>
          {children}
          <UsageSummary />
        </>
      )
    },
  )
}
