import { useRecordStateContext } from '@thesisedu/feature-media-react'
import { styled, s } from '@thesisedu/ui'
import { renderAbc } from 'abcjs'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { useSheetMusicPluginContext } from './SheetMusicPluginContext'
import { SheetMusicViewer, SheetMusicViewerProps } from '../../viewer/SheetMusicViewer'
import { MEASURE_MIN_WIDTH } from '../../viewer/Staff'
import { TuneItem } from '../../viewer/types'

const LINE_WIDTH_DEFAULT = 2 * MEASURE_MIN_WIDTH
const LINE_WIDTH_EIGHTHS = 3 * MEASURE_MIN_WIDTH

function calculateLineWidth(rendered: TuneItem[]) {
  let minDuration = Infinity
  for (const line of rendered[0].lines) {
    for (const staff of line.staff) {
      for (const voice of staff.voices) {
        for (const x of voice) {
          if (x.el_type === 'note' && x.duration < minDuration) {
            minDuration = x.duration
          }
        }
      }
    }
  }

  return minDuration <= 0.125 ? LINE_WIDTH_EIGHTHS : LINE_WIDTH_DEFAULT
}

function getNumBars(rendered: TuneItem[]) {
  let noteSinceLastBar = false
  let numBars = 0
  for (const line of rendered[0].lines) {
    for (const staff of line.staff) {
      for (const voice of staff.voices) {
        for (const x of voice) {
          if (x.el_type === 'note') {
            noteSinceLastBar = true
          } else if (x.el_type === 'bar') {
            if (noteSinceLastBar) numBars++
            noteSinceLastBar = false
          }
        }
      }
    }
  }

  return numBars
}

export interface SheetMusicProps extends SheetMusicViewerProps {}
export function SheetMusic(rest: SheetMusicProps) {
  const { playing, setPlaying, visible, setVisible } = useSheetMusicPluginContext(true)
  const [playbackKey, setPlaybackKey] = React.useState(uuid())
  const { state: recordState } = useRecordStateContext(true)
  const { lineWidth, numBars } = React.useMemo(() => {
    const rendered = renderAbc('*', rest.abc) as TuneItem[]
    const lineWidth = calculateLineWidth(rendered)
    const numBars = getNumBars(rendered)
    return { lineWidth, numBars }
  }, [rest.abc])
  // Each bar is 0.5 of a line
  const numLines = numBars * 0.5
  const containerRef = React.useRef<HTMLDivElement>(null)
  const animationRef = React.useRef<Animation | null>(null)

  // Start the animation whenever the playback duration is received.
  const onDuration = React.useCallback(
    (duration: number) => {
      if (containerRef.current) {
        const outerStaffContainer =
          containerRef.current.querySelector<HTMLDivElement>('.outer-staff-container')
        if (outerStaffContainer) {
          animationRef.current = outerStaffContainer.animate(
            [
              { transform: 'translateX(0)' },
              { transform: `translateX(-${lineWidth * (numLines - 0.2)}px)` },
            ],
            {
              duration,
              easing: 'linear',
            },
          )
          animationRef.current.onfinish = () => {
            outerStaffContainer.style.transform = `translateX(-${lineWidth * (numLines - 0.2)}px)`
            setTimeout(() => {
              setVisible(false)
            }, 2000)
            animationRef.current = null
          }
        }
      }
    },
    [numLines, lineWidth],
  )

  // Stop the animation and hide whenever the player is stopped.
  React.useEffect(() => {
    if (visible) {
      setPlaybackKey(uuid())
      return () => {
        if (animationRef.current) {
          animationRef.current.cancel()
          animationRef.current = null
        }
        setPlaying(false)
      }
    }
  }, [visible])

  // Update visibility and playback status whenever the recording state changes.
  React.useEffect(() => {
    if (recordState?.type === 'countdown') {
      setVisible(true)
    } else if (recordState?.type === 'recording') {
      setPlaying(true)
    } else if (!recordState || recordState?.type === 'stopping') {
      setPlaying(false)
      setVisible(false)
    }
  }, [recordState?.type])

  return (
    <SheetMusicContainer>
      <SheetMusicInnerContainer
        $numLines={numLines}
        ref={containerRef}
        $visible={visible}
        $lineWidth={lineWidth}
      >
        <SheetMusicViewer
          {...rest}
          key={playbackKey}
          renderOneLine
          title={''}
          showChords={false}
          playbackContainerProps={{
            playing,
            onPlayingChange: setPlaying,
            hideControls: true,
            onDuration,
          }}
        />
      </SheetMusicInnerContainer>
    </SheetMusicContainer>
  )
}

const SheetMusicContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  pointer-events: none;
`
const SheetMusicInnerContainer = styled.div<{
  $numLines: number
  $visible: boolean
  $lineWidth: number
}>`
  background: linear-gradient(
    0deg,
    ${s.color('background')} 0%,
    ${s.transparentize(s.color('background'), 0.35)} 35%,
    ${s.transparentize(s.color('background'), 0.85)} 90%,
    ${s.transparentize(s.color('background'), 1)} 100%
  );
  padding: ${s.size('xl')} 0;
  width: 100%;
  overflow-x: hidden;
  opacity: ${props => (props.$visible ? 1 : 0)};
  transition: opacity 0.5s linear;
  .outer-staff-container {
    width: ${props => props.$numLines * props.$lineWidth}px;
    box-sizing: content-box;
    padding-left: ${props => props.$lineWidth / 2}px;
    padding-right: ${props => props.$lineWidth / 2}px;
    position: relative;
    [data-start-char] .scale {
      transform: none !important;
    }
  }
`
