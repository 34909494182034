import { SearchResultGridItem } from '@thesisedu/feature-search-react'
import {
  Grid,
  styledContainer,
  DATE_FORMATS,
  formatTime,
  isNative,
  BodySmall,
} from '@thesisedu/react'
import moment from 'moment'
import React from 'react'

import { MediaThumbnail } from '../preview/MediaThumbnail'
import { MediaStatus, MediaSearchResultFragment } from '../schema'
import { UploadStatusLabel } from '../upload/UploadStatusLabel'
import { convertStatus } from '../upload/convertStatus'
import { PartialUpload } from '../upload/types'

const { ThumbnailMetricContainer, TopLeftIconContainer, TopRightIcon } = Grid

export type SearchResultMedia = MediaSearchResultFragment & Pick<PartialUpload, 'uploaded'>
export interface MediaGridItemProps {
  media: SearchResultMedia
  title: string | React.ReactElement
  description?: string | React.ReactElement
  topLeftIcon?: React.ReactElement
  topRightIcon?: Grid.TopRightIconProps
}
export function MediaGridItem({
  media,
  title,
  description,
  topLeftIcon,
  topRightIcon,
  children,
}: React.PropsWithChildren<MediaGridItemProps>) {
  const prefix = isNative ? '' : 'Created '
  return (
    <SearchResultGridItem
      title={title}
      description={
        media.status !== MediaStatus.Complete ? (
          <UploadStatusLabel
            upload={{
              ...media,
              currentStatus: convertStatus(media.status),
            }}
          />
        ) : description ? (
          description
        ) : (
          `${prefix}${moment(media.createdAt).format(DATE_FORMATS.DATE)}`
        )
      }
      image={
        <OverlayContainer>
          <MediaThumbnail node={media} />
          {media.durationInSeconds ? (
            <DurationOverlay>
              <BodySmall
                children={formatTime(media.durationInSeconds.toString())}
                lineHeight={1.2}
              />
            </DurationOverlay>
          ) : null}
          {topLeftIcon ? <TopLeftIconContainer>{topLeftIcon}</TopLeftIconContainer> : null}
          {topRightIcon ? <TopRightIcon {...topRightIcon} /> : null}
          {children}
        </OverlayContainer>
      }
    />
  )
}

const OverlayContainer = styledContainer`
  position: relative;
`
export const DurationOverlay = ThumbnailMetricContainer
