import { ExploreGrid, ExploreGridProps } from '@thesisedu/feature-courses-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import React from 'react'

import { CourseViewBuiltSegmentsDocument, CourseViewBuiltSegmentsQueryVariables } from './schema'

export interface CourseViewExploreGridProps extends ExploreGridProps {
  /** This is NOT the global GraphQL ID. */
  courseViewId: string
}
export function CourseViewExploreGrid({ courseViewId, ...rest }: CourseViewExploreGridProps) {
  const { filters, after, first, orderBy, orderDirection, ...restVariables } = rest.variables ?? {}
  return (
    <ExploreGrid<CourseViewBuiltSegmentsQueryVariables>
      {...rest}
      document={CourseViewBuiltSegmentsDocument}
      resultPath={'node.segments'}
      variables={{
        courseViewId: toGlobalId('CourseView', courseViewId),
        filters: {
          ...restVariables,
          ...filters,
        } as any,
      }}
    />
  )
}
