import { HSpaced } from '@thesisedu/web'
import { createGlobalStyle } from 'styled-components'

export const ProdigiesHomepageTransition = createGlobalStyle`
  .prodigies-homepage {
    &-enter-active, &-exit-active {
      .left-content > *, .right-content > *, .logo, .animate-up, .animate-down {
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
        &:not(.disabled):not(${HSpaced}) { // We don't want disabled links to be displayed as blocks.
          display: block;
        }
      }
      .left-content > :nth-child(2), .right-content > :nth-child(2) { transition-delay: 0.1s; }
      .left-content > :nth-child(3), .right-content > :nth-child(3) { transition-delay: 0.2s; }
      .left-content > :nth-child(4), .right-content > :nth-child(4) { transition-delay: 0.3s; }
      .left-content > :nth-child(5), .right-content > :nth-child(5) { transition-delay: 0.4s; }
      .left-content > :nth-child(6), .right-content > :nth-child(6) { transition-delay: 0.5s; }
    }
    &-enter {
      .left-content > * {
        opacity: 0;
        transform: translateX(-50px);
      }
      .right-content > * {
        opacity: 0;
        transform: translateX(50px);
      }
      .logo, .animate-down {
        opacity: 0;
        transform: translateY(-50px);
      }
      .animate-up {
        opacity: 0;
        transform: translateY(50px);
      }
      &-active {
        .left-content > *, .right-content > *, .logo, .animate-up, .animate-down {
          opacity: 1;
          transform: none;
        }
      }
    }
    &-exit {
      .left-content > *, .right-content > *, .logo, .animate-up, .animate-down {
        opacity: 1;
        transform: none;
      }
      &-active {
        pointer-events: none;
        .left-content > * {
          opacity: 0;
          transform: translateX(-50px);
        }
        .right-content > * {
          opacity: 0;
          transform: translateX(50px);
        }
        .logo, .animate-down {
          opacity: 0;
          transform: translateY(-50px);
        }
        .animate-up {
          opacity: 0;
          transform: translateY(50px);
        }
      }
    }
  }
`
