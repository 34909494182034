import { ReactFeature } from '@thesisedu/feature-react'
import {
  addSearch,
  RenderSearchItemProps,
  RenderSearchListItemProps,
  SearchResultCalendarItem,
  SearchResultGridItem,
  SearchResultListItem,
} from '@thesisedu/feature-search-react'
import { Block, BodySmall, getColor, getSize, sizeToNumber, useTheme } from '@thesisedu/react'
import { ThumbnailMetricContainer } from '@thesisedu/react/dist/grid'
import { Check } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentSubtitle, AssignmentThumbnail } from '../grid'
import { AssignmentSearchResultFragment, AssignmentSearchResultFragmentDoc } from '../schema'

function _convertSearchResult(assignment: AssignmentResultNode) {
  return {
    ...assignment,
    dueAt: assignment.assignmentDue,
    openAt: assignment.assignmentOpens,
    students: assignment.assignmentStudents,
  }
}

type AssignmentResultNode = AssignmentSearchResultFragment
function AssignmentGridItem({ item }: RenderSearchItemProps<AssignmentResultNode>) {
  const { totalCount, totalSubmitted, totalGraded } = item.assignmentStudents
  const totalUngraded = Math.max(0, totalSubmitted - totalGraded)
  const converted = _convertSearchResult(item)
  const theme = useTheme()
  return (
    <SearchResultGridItem
      title={item.assignmentTitle}
      description={<AssignmentSubtitle assignment={converted} />}
      image={
        <AssignmentThumbnail assignment={converted}>
          <ThumbnailMetricContainer
            color={totalUngraded ? '@orange' : undefined}
            style={{ right: 'auto', left: sizeToNumber(getSize(theme, '@size-xs')) }}
          >
            <Block marginRight={'@size-xxs'}>
              <Check
                width={getSize(theme, '@size-s')}
                height={getSize(theme, '@size-s')}
                color={getColor(theme, '@gray-0')}
              />
            </Block>
            <BodySmall color={'@gray-0'} lineHeight={1.4}>
              {totalSubmitted} / {totalCount}
            </BodySmall>
          </ThumbnailMetricContainer>
        </AssignmentThumbnail>
      }
    />
  )
}

function AssignmentListItem({ item, onClick }: RenderSearchListItemProps<AssignmentResultNode>) {
  const converted = _convertSearchResult(item)
  return (
    <SearchResultListItem
      onClick={onClick}
      title={item.assignmentTitle}
      subtitle={<AssignmentSubtitle assignment={converted} />}
      thumbnail={<AssignmentThumbnail assignment={converted} compact />}
      type={'Assignment'}
    />
  )
}

export default function addSearchResources(feature: ReactFeature) {
  addSearch(feature, AssignmentSearchResultFragmentDoc, 'Assignment', {
    renderGrid: AssignmentGridItem,
    renderList: AssignmentListItem,
    renderCalendar: ({ item, onClick }) => {
      if (item.assignmentDue || item.assignmentOpens) {
        return (
          <SearchResultCalendarItem
            title={item.assignmentTitle}
            type={'Assignment'}
            onClick={onClick}
            color={'@orange'}
          />
        )
      } else return null
    },
    getDate: item => item.assignmentDue || item.assignmentOpens || item.createdAt,
    getUniversalLink: item => `assignment/${item.id}`,
  })
}
