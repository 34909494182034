import { useErrorService } from '@thesisedu/feature-react'
import { ProgressBar$, VSpaced, styled } from '@thesisedu/ui'
import React from 'react'

import { getMediaTypeFromContext } from './getMediaTypeFromContext'
import { useFinishUpload } from './useFinishUpload'
import { useGetMediaUploadUrl } from './useGetMediaUploadUrl'
import { debug } from '../../../log'
import { UploadManagerContext } from '../../../upload/UploadManagerContext'
import { STATUS_LABEL_PROPS } from '../../../upload/UploadStatusLabel'
import { UploadStatus } from '../../../upload/types'
import { useUpload } from '../../../upload/useUpload'
import { getError } from '../error'
import { ReviewContext } from '../review'
import { RecordState, RecordStateProps, getStateFactory } from '../types'

export interface UploadContext extends ReviewContext {}
function Upload({ context, onTransition }: RecordStateProps<UploadContext>) {
  const errorService = useErrorService()
  const uploadManager = React.useContext(UploadManagerContext)
  if (!uploadManager) throw new Error('Cannot use RecordView outside UploadManagerContext')
  const [uploadId, setUploadId] = React.useState<string>()
  const upload = useUpload(uploadId ?? '')
  const statusLabelProps = upload ? STATUS_LABEL_PROPS(upload) : undefined
  const onError = (err: any) => onTransition(getError({ error: err }))
  const getMediaUploadUrl = useGetMediaUploadUrl({ onError })
  const { finishUpload, loading: finishLoading } = useFinishUpload({
    setUploadId,
    onError,
  })

  // Finish the upload as soon as this launches.
  React.useEffect(() => {
    // TODO: Handle desktop streams.
    if (context.stream) {
      finishUpload({
        mediaType: getMediaTypeFromContext(context),
        data: context.stream,
        getMediaUploadUrl,
      }).catch(err => {
        debug('error finishing upload %O', err)
        errorService.reportError(err)
        onError(err)
      })
    }
  }, [])

  const statusMessage = finishLoading
    ? 'Getting ready for upload...'
    : upload
    ? statusLabelProps?.children?.toString()
      ? `${statusLabelProps.children.toString()}...`
      : 'Unknown Status'
    : 'Waiting for upload...'
  const percentage = (upload?.uploaded ?? 0) * 100
  const isIndeterminate = upload?.currentStatus !== UploadStatus.Uploading

  React.useEffect(() => {
    if (upload?.currentStatus === UploadStatus.Failed) {
      onError(new Error('Upload failed'))
    }
  }, [upload?.currentStatus])

  return (
    <Container
      maxValue={100}
      value={percentage}
      isIndeterminate={isIndeterminate}
      label={statusMessage}
    >
      <VSpaced space={'xs'} align={'center'} style={{ maxWidth: 500, width: '100%' }}>
        <ProgressBar$.Bar />
        <ProgressBar$.Label level={'s'} color={'secondary'} />
      </VSpaced>
    </Container>
  )
}

const Container = styled(ProgressBar$.Container)`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`

export default {
  Component: Upload,
} satisfies RecordState<UploadContext>
export const getUpload = getStateFactory<UploadContext>('upload')
