import React from 'react'

import { Bar } from './Bar'
import { Container, ContainerProps } from './Container'
import { Label } from './Label'
import { ValueLabel } from './ValueLabel'
import { styled, s, HSpaced, Block } from '../..'

export interface ProgressBarProps extends Omit<ContainerProps, 'children'> {}
export function ProgressBar({ ...props }: ProgressBarProps) {
  return (
    <StyledContainer {...props}>
      <Bar />
      <HSpaced>
        <Block right>
          <Label level={'s'} color={'secondary'} truncate />
        </Block>
        <ValueLabel level={'s'} color={'secondary'} truncate />
      </HSpaced>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${s.size('xs')};
`
