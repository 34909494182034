import { useFeature } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import pluralize from 'pluralize'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'
import { GradeDisplayMode } from '../types'

export interface UseGradeDisplayOpts {
  percentage: number
  totalPoints?: number
  hideSuffix?: boolean
}
export function useGradeDisplay(opts: UseGradeDisplayOpts) {
  return useGradeDisplayFn()(opts)
}

export function useGradeDisplayFn() {
  const feature = useFeature(AssignmentsReactFeature)
  const gradeMode = feature.options.gradeDisplayMode || GradeDisplayMode.Percentage
  return ({ hideSuffix, percentage, totalPoints }: UseGradeDisplayOpts) => {
    if (gradeMode === GradeDisplayMode.Percentage || totalPoints === undefined) {
      return Format.number(percentage, 'decimalPercentage')
    } else if (gradeMode === GradeDisplayMode.Points) {
      return `${Math.round(percentage * totalPoints)} / ${Math.round(totalPoints)}${
        hideSuffix ? '' : ` ${pluralize('point', Math.round(totalPoints))}`
      }`
    } else {
      throw new Error(`Invalid grade mode configured: ${gradeMode}`)
    }
  }
}
