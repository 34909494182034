import * as React from 'react'

function SvgNoteWholeInverse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#000'}
          d={
            'M97.427 24.835c0 15.243-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1C-4.02 41.637 10.363 18.73 35.717 7.113 53.467-1.018 71.89-1.46 83.955 4.718 92.869 9.6 97.427 15.72 97.427 24.835z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M33.938 13.738C14.056 26.808 4.93 47.817 13.556 60.664c8.625 12.847 31.734 12.667 51.616-.403C85.055 47.192 94.18 26.183 85.555 13.336 76.929.489 53.82.669 33.938 13.738z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteWholeInverse = React.memo(SvgNoteWholeInverse)
export default MemoSvgNoteWholeInverse
