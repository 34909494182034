import { MutationTuple } from '@apollo/client'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { withLoadingMessage } from '@thesisedu/web'
import { message } from 'antd'

import {
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables,
  useDeleteSegmentMutation as useMutation,
} from '../schema'

export function useDeleteSegmentMutation(
  segmentId: string,
): MutationTuple<DeleteSegmentMutation, DeleteSegmentMutationVariables> {
  const [mutate, mutateOpts] = useMutation({
    variables: { input: { id: segmentId } },
    update: cache => {
      cache.evict({
        id: `Segment:${segmentId}`,
      })
    },
    onError: onMutationError('There was an error deleting that content.'),
    onCompleted: () => {
      message.success('Content deleted successfully!')
    },
  })

  return [withLoadingMessage('Deleting segment...', mutate), mutateOpts]
}
