import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { CommentFragmentDoc, useUpdateCommentMutation as useMutation } from '../schema'

export const useUpdateCommentMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating your comment.'),
    update: updateMutation({
      fragment: CommentFragmentDoc,
      fragmentName: 'Comment',
      path: 'updateComment.comment',
    }),
    ...opts,
  })
