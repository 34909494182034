import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export class StudentsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-students-react'
  static path = ''
  static requires = []

  public reactResources() {
    require('./hooks/routes').default(this)
    if (this.root.getFeature('@thesisedu/feature-search-react')) {
      require('./resources/search').default(this)
    }
  }
}

export const studentsReactFeature: ReactUse = opts => [StudentsReactFeature, opts]
