import { getFragmentDefinitions } from '@apollo/client/utilities'
import { addFragmentReference, useModifiedFragment } from '@thesisedu/feature-apollo-react'

import { PendingStudentClassReasonResource } from './types'
import { ClassesReactFeature } from '../ClassesReactFeature'

export default function (feature: ClassesReactFeature) {
  const resources = feature.resources.getResourcesForType<PendingStudentClassReasonResource>(
    'PendingStudentClassReason',
  )
  for (const resource of resources) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(feature.hookManager, 'PendingStudentClass', (fragment, context) => {
      const definitions = getFragmentDefinitions(resource.fragmentDocument)
      context.additionalDefinitions.push(...definitions.slice(1))
      return addFragmentReference(fragment, definitions[0], context)
    })
  }
}
