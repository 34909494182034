import { PendingStudentClassReasonResource } from '@thesisedu/feature-classes-react'
import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { DuplicateUserExpectedErrorListener } from './DuplicateUserExpectedErrorListener'
import { ReviewDuplicateUser } from './ReviewDuplicateUser'
import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import {
  PendingStudentClassReason,
  WithDuplicateUserPendingStudentClassMetadataFragmentDoc,
} from '../../schema'

export default function (feature: ClassSyncReactFeature) {
  feature.resources.addResource<PendingStudentClassReasonResource>({
    type: 'PendingStudentClassReason',
    identifier: PendingStudentClassReason.DuplicateUser,
    fragmentDocument: WithDuplicateUserPendingStudentClassMetadataFragmentDoc,
    info: 'This student is probably already in this class.',
    label: 'Duplicate',
    reviewComponent: ReviewDuplicateUser,
  })
  feature.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
    return (
      <>
        <DuplicateUserExpectedErrorListener />
        {children}
      </>
    )
  })
}
