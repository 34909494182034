import { useSortable } from '@dnd-kit/sortable'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { useSplitViewOutlineItemContext } from './SplitViewOutlineItemContext'
import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { getForeignOutlineItem } from './getForeignOutlineItem'
import { CustomSegment, CustomSegmentProps } from '../custom_content/CustomSegment'
import { useDragDropOutlineContext } from '../outline/DragDropOutlineContext'

export interface MyContentDraggableProps extends CustomSegmentProps {}
function MyContentDraggableInner(props: MyContentDraggableProps) {
  const { foreignSegmentsRef } = useSplitViewPendingChangesContext(true)
  const { items } = useSplitViewOutlineItemContext(true)
  if (props.segment.built) {
    if (!foreignSegmentsRef.current[props.rawId]) {
      foreignSegmentsRef.current[props.rawId] = props.segment.built
    }
    if (!items.current[props.rawId]) {
      items.current[props.rawId] = getForeignOutlineItem(props.segment.built)
    }
  }
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.rawId,
    transition: null,
    disabled: {
      draggable: false,
      droppable: true,
    },
  })
  return (
    <Container $dragging={isDragging} ref={setNodeRef} {...attributes} {...listeners}>
      <CustomSegment {...props} useInClass={false} actionMenuItems={[]} onClick={null} noMargin />
    </Container>
  )
}

export function MyContentDraggable(props: MyContentDraggableProps) {
  const { draggingId } = useDragDropOutlineContext(true)
  return (
    <MyContentDraggableInner
      {...props}
      key={draggingId === props.rawId ? 'is-dragging' : 'not-dragging'}
    />
  )
}

const Container = styled.div<{ $dragging: boolean }>`
  position: relative;
  cursor: grab;
  &::before {
    position: absolute;
    content: '';
    display: block;
    inset: calc(${s.size('xs')} * -1);
    border-radius: ${s.var('radii.1')};
    border: solid 1px transparent;
    transition:
      box-shadow 0.1s linear,
      background 0.1s linear,
      transform 0.1s linear,
      border 0.1s linear;
    pointer-events: none;
  }
  &:hover::before,
  ${s.ifs(props => props.$dragging)}::before {
    box-shadow: ${s.var('shadow.1')};
    border-color: ${s.color('border')};
  }
`
