import { useResource } from '@thesisedu/feature-react'
import { Block, DiscreteLoadingIndicator, H2, HSpaced, Space } from '@thesisedu/web'
import { Form } from 'antd'
import { orderBy } from 'lodash'
import React from 'react'

import { ClassConfiguration, ClassConfigurationProps } from './ClassConfiguration'
import { useUpdateClassMutation } from './queries/useUpdateClassMutation'
import { BasicClassFragment, ClassFragment } from './schema'
import { ClassConfigurationFieldResource } from './types'

const SAVE_DELAY = 1000
export interface ClassConfigurationWithSaveProps
  extends Omit<ClassConfigurationProps, 'fieldPrefix'> {
  class: BasicClassFragment & Pick<ClassFragment, 'isOwn'>
  title?: string
  fields?: ClassConfigurationFieldResource[]
}
export function ClassConfigurationWithSave({
  class: cls,
  title,
  fields,
  ...rest
}: ClassConfigurationWithSaveProps) {
  const _customFields = useResource<ClassConfigurationFieldResource>('ClassConfigurationField')
  const customFields = orderBy(fields || _customFields, 'weight', 'asc')
  const [update, { loading }] = useUpdateClassMutation()
  const [form] = Form.useForm()
  const timeoutRef = React.useRef<any>()
  React.useEffect(() => {
    form.setFieldsValue(cls)
  }, [cls.id])
  if (!customFields.length) return null
  return (
    <>
      <Block marginBottom={title ? '@size-m' : '@size-xxs'}>
        <HSpaced>
          {title ? <H2 isBlock={false}>{title}</H2> : null}
          <Space />
          <DiscreteLoadingIndicator loading={loading} />
        </HSpaced>
      </Block>
      <Form
        layout={'vertical'}
        form={form}
        onValuesChange={(_, values) => {
          clearTimeout(timeoutRef.current)
          timeoutRef.current = setTimeout(() => {
            update({
              variables: {
                input: {
                  id: cls.id,
                  patch: {
                    configuration: {
                      ...cls.configuration,
                      ...values.configuration,
                    },
                  },
                },
              },
            })
          }, SAVE_DELAY)
        }}
      >
        <ClassConfiguration
          fieldPrefix={['configuration']}
          classId={cls.id}
          fields={fields}
          {...rest}
        />
      </Form>
    </>
  )
}
