import { Random } from '@thesisedu/feature-utils'
import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { Toast, ToastProps } from './Toast'
import { RootProps, ToastStatus } from './ToastParts'

function isElementRecord(record: ToastRecord): record is ToastElementRecord {
  return !!(record as ToastElementRecord).element
}
type ToastElementRecord = { key: string; element: React.ReactElement<RootProps> }
type ToastRecord = (ToastProps & { key: string }) | ToastElementRecord
type OptionalKeyToastRecord =
  | (ToastProps & { key?: string })
  | { element: React.ReactElement<RootProps>; key?: string }
  | string
export interface UseToastContextValue {
  setToasts: React.Dispatch<React.SetStateAction<ToastRecord[]>>
}
export const UseToastContext = React.createContext<UseToastContextValue | undefined>(undefined)

export interface UseToastProviderProps {
  children: React.ReactNode
}
export function UseToastProvider({ children }: UseToastProviderProps) {
  const [toasts, setToasts] = React.useState<ToastRecord[]>([])
  const contextValue = React.useMemo(() => ({ setToasts }), [setToasts])
  return (
    <>
      <AnimatePresence>
        {toasts.map(opts => {
          const props = {
            key: opts.key,
            open: true,
            onOpenChange: (open: boolean) => {
              if (!open) {
                setToasts(t => t.filter(t => t.key !== opts.key))
              }
            },
          }
          if (isElementRecord(opts)) {
            const { key, element } = opts
            return React.cloneElement<RootProps>(element, { key })
          } else {
            const { key, ...toast } = opts
            return <Toast {...props} {...toast} __useInternalRoot />
          }
        })}
      </AnimatePresence>
      <UseToastContext.Provider value={contextValue} children={children} />
    </>
  )
}

export function useUseToastContext(): UseToastContextValue | undefined
export function useUseToastContext(require: false): UseToastContextValue | undefined
export function useUseToastContext(require: true): UseToastContextValue
export function useUseToastContext(require?: boolean): UseToastContextValue | undefined {
  const context = React.useContext(UseToastContext)
  if (!context && require) {
    throw new Error('UseToastContext is required, yet not provided.')
  }
  return context
}

type NotString<T> = T extends string ? never : T
function transformOpts(opts: OptionalKeyToastRecord): NotString<OptionalKeyToastRecord> {
  return typeof opts === 'string' ? { title: opts } : opts
}

export type UpdateOrCloseToast = (updates?: OptionalKeyToastRecord) => void
export type CreateToast = (opts: OptionalKeyToastRecord) => UpdateOrCloseToast
export type CreateToastStatusShortcuts = Omit<Record<ToastStatus, CreateToast>, 'default'>
export function useToast(): CreateToast & CreateToastStatusShortcuts {
  const { setToasts } = useUseToastContext(true)
  return React.useMemo(() => {
    const callback = (opts: OptionalKeyToastRecord): UpdateOrCloseToast => {
      const { key = Random.id(), ...toast } = transformOpts(opts)
      setToasts(t => {
        let hasExisting = false
        const toasts = t.map(t => {
          if (t.key === key) {
            hasExisting = true
            return { key, ...toast }
          } else return t
        })
        return hasExisting ? toasts : [...toasts, { key, ...toast }]
      })
      return _updates => {
        setToasts(t => {
          if (_updates) {
            const updates = typeof _updates === 'string' ? { title: _updates } : _updates
            return t.map(t => {
              return t.key === key
                ? isElementRecord(t)
                  ? { key, element: t.element, ...updates }
                  : { key, ...updates }
                : t
            })
          } else {
            return t.filter(t => t.key !== key)
          }
        })
      }
    }
    const shortcuts = {
      error: (opts: OptionalKeyToastRecord) =>
        callback({ status: 'error', ...transformOpts(opts) }),
      success: (opts: OptionalKeyToastRecord) =>
        callback({ status: 'success', ...transformOpts(opts) }),
    } satisfies CreateToastStatusShortcuts
    Object.assign(callback, shortcuts)
    return callback as CreateToast & CreateToastStatusShortcuts
  }, [])
}
