import React from 'react'

import { Reset } from './Reset'
import { Tooltip } from './ant/Tooltip'
import { useWindowContext } from './dom/WindowContext'
import { copyToClipboard } from './dom/clipboard'
import { useTheme } from './theme'
import { WarningCircle, Check, Copy } from '../icons'

export interface CopyToClipboardButtonProps {
  text: string
  style?: React.CSSProperties
}
export function CopyToClipboardButton({ text, style }: CopyToClipboardButtonProps) {
  const [status, setStatus] = React.useState<'error' | 'copied' | 'default'>('default')
  const { window: contextWindow } = useWindowContext(false) || {}
  const theme = useTheme()
  React.useEffect(() => {
    if (status !== 'default') {
      const handle = setTimeout(() => {
        setStatus('default')
      }, 3000)
      return () => clearTimeout(handle)
    }
  }, [status])
  return (
    <Tooltip title={'Copy to Clipboard'}>
      <Reset.Button
        onClick={async e => {
          e.preventDefault()
          e.stopPropagation()
          const success = await copyToClipboard(text, contextWindow)
          setStatus(success ? 'copied' : 'error')
        }}
        style={style}
      >
        <span
          style={
            status === 'error'
              ? { color: theme['@red'] }
              : status === 'copied'
              ? { color: theme['@green'] }
              : undefined
          }
        >
          {status === 'error' ? <WarningCircle /> : status === 'copied' ? <Check /> : <Copy />}
        </span>
      </Reset.Button>
    </Tooltip>
  )
}
