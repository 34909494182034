import { useNavigate } from '@thesisedu/react'
import React from 'react'

import { DeepLinkHandler, DeepLinkHandlerProps } from '../DeepLinkHandler'

export interface UseDeepLinkOpts {
  onError?: (message: string) => void
}
export function useDeepLink({ onError }: UseDeepLinkOpts = {}) {
  const [deepLinkProps, setDeepLinkProps] = React.useState<DeepLinkHandlerProps | null>(null)
  const navigate = useNavigate()
  return {
    linkHandler: deepLinkProps ? <DeepLinkHandler {...deepLinkProps} /> : null,
    navigate: async (universalLink: string) => {
      return new Promise<void>(resolve => {
        setDeepLinkProps({
          renderNoResource: () => {
            if (onError) onError('That item could not be found.')
            resolve()
            setDeepLinkProps(null)
            return <></>
          },
          onAccessDenied: () => {
            if (onError) onError("You don't have access to that.")
            resolve()
            setDeepLinkProps(null)
          },
          renderNotFound: () => {
            if (onError) onError('That item could not be found.')
            resolve()
            setDeepLinkProps(null)
            return <></>
          },
          renderLoading: () => <></>,
          onFound: resolvedPath => {
            resolve()
            setDeepLinkProps(null)
            navigate(resolvedPath)
          },
          url: universalLink,
        })
      })
    },
  }
}
