import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Body, BodyLarge, Modal, styled, Block, ModalProps } from '@thesisedu/web'
import { Button, Tooltip, Menu } from 'antd'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import copy from 'copy-to-clipboard'
import React from 'react'

import { useGenerateCanvasCredentialsTokenMutation } from '../schema'

const LinkContainer = styled(BodyLarge)`
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s linear;
  color: ${props => props.theme[props.disabled ? '@text-color-secondary' : '@primary-color']};
`

export interface GenerateCanvasTokenModalProps extends ModalProps {
  siteId?: string
  districtId?: string
}
export function GenerateCanvasTokenModal({
  siteId,
  districtId,
  ...modalProps
}: GenerateCanvasTokenModalProps) {
  const [token, setToken] = React.useState<string | undefined>(undefined)
  const [generateLink, { loading }] = useGenerateCanvasCredentialsTokenMutation({
    onError: onMutationError('There was an error generating your canvas link.'),
    onCompleted: data => {
      setToken(data.generateCanvasCredentialsToken.token)
    },
  })
  const [tooltipTitle, setTooltipTitle] = React.useState<string>('Click to copy')
  React.useEffect(() => {
    if (tooltipTitle === 'Copied!') {
      const handle = setTimeout(() => {
        setTooltipTitle('Click to copy')
      }, 1000)
      return () => clearTimeout(handle)
    }
  }, [tooltipTitle])
  return (
    <Modal
      title={'Generate Canvas Link'}
      footer={null}
      width={600}
      style={{ maxWidth: '90vw' }}
      {...modalProps}
    >
      <Body>
        Use the button below to generate your link, and then copy the link once it has been created.{' '}
        <strong>Links are valid for up to 30 days after creation.</strong>
      </Body>
      <Block marginTop={'@size-m'}>
        {token ? (
          <Tooltip title={tooltipTitle}>
            <LinkContainer
              onClick={() => {
                copy(`${window.location.origin}/auth/canvas?token=${token}`)
                setTooltipTitle('Copied!')
              }}
            >
              {`${window.location.origin}/auth/canvas?token=${token}`}
            </LinkContainer>
          </Tooltip>
        ) : (
          <Button
            size={'large'}
            loading={loading}
            onClick={() => generateLink({ variables: { input: { siteId, districtId } } })}
          >
            Generate Link
          </Button>
        )}
      </Block>
    </Modal>
  )
}

export type GenerateCanvasTokenMenuItemProps = Omit<
  GenerateCanvasTokenModalProps,
  'visible' | 'onCancel'
> &
  Partial<MenuItemProps>

export function GenerateCanvasTokenMenuItem({
  siteId,
  districtId,
  ...menuItemProps
}: GenerateCanvasTokenMenuItemProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <Menu.Item key={'canvas-link'} {...menuItemProps}>
      <a onClick={() => setVisible(true)}>Generate Canvas Credentials Link</a>
      <GenerateCanvasTokenModal
        siteId={siteId}
        districtId={districtId}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </Menu.Item>
  )
}
