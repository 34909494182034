import { $findMatchingParent } from '@lexical/utils'
import { Dropdown } from '@thesisedu/ui'
import { AlignLeftBox, AlignRightBox } from '@thesisedu/ui/icons'
import { LexicalNode } from 'lexical'
import React from 'react'

import { $isFloatNode, FloatAlignment, FloatNode } from '../../nodes/FloatNode'
import {
  useSettingsDropdownItem,
  SettingsDropdownElementProps,
} from '../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../ui/SettingsDropdown/useNodeState'

const IconMap: Record<FloatAlignment, typeof AlignLeftBox> = {
  left: AlignLeftBox,
  right: AlignRightBox,
}

interface AlignmentItemProps {
  alignment: FloatAlignment
  label: string
  selected: boolean
  onChange: (alignment: FloatAlignment) => void
}
function AlignmentItem({ alignment, label, selected, onChange }: AlignmentItemProps) {
  return (
    <Dropdown.Item.Checkbox
      checked={selected}
      children={label}
      onCheckedChange={checked => {
        if (checked) {
          onChange(alignment)
        }
      }}
    />
  )
}

function findFloatNode(node: LexicalNode): FloatNode {
  return $isFloatNode(node) ? node : ($findMatchingParent(node, $isFloatNode) as FloatNode)
}

function FloatAlignmentSetting({ editor, node }: SettingsDropdownElementProps) {
  const matchingNode = React.useMemo(() => {
    return editor.getEditorState().read(() => {
      return findFloatNode(node)
    })
  }, [node])
  const [alignment, setAlignment] = useNodeState(
    editor,
    matchingNode,
    node => node.getAlignment(),
    (node, value) => node.setAlignment(value),
  )
  if (alignment === undefined) return null
  const Icon = IconMap[alignment]
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<Icon />}>Alignment</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        {(Object.keys(IconMap) as FloatAlignment[]).map(a => {
          return (
            <AlignmentItem
              alignment={a}
              key={a}
              selected={alignment === a}
              label={a[0].toUpperCase() + a.slice(1)}
              onChange={setAlignment}
            />
          )
        })}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

export function useFloatSettings() {
  useSettingsDropdownItem({
    identifier: 'float-alignment',
    group: 'floats',
    weight: -10,
    filter(editor, node) {
      const parentFloatNode = $findMatchingParent(node, $isFloatNode)
      return $isFloatNode(node) || !!parentFloatNode
    },
    element: FloatAlignmentSetting,
  })
}
