import { message, Form, FormInstance } from '@thesisedu/react'
import React from 'react'

import { debug } from '../log'
import { useGradeAssignmentSubmissionMutation } from '../queries/useGradeAssignmentSubmissionMutation'
import {
  AssignmentRubricFragment,
  BasicAssignmentFragment,
  FullAssignmentSubmissionFragment,
  useAssignmentSubmissionQuery,
} from '../schema'
import { CommentsMap, GradesMap } from '../types'

export interface SubmissionValues {
  grades: GradesMap
  comments: CommentsMap
}
export interface GradingModalSubmissionContextValue {
  submission?: FullAssignmentSubmissionFragment
  loading?: boolean
  form: FormInstance<SubmissionValues>
  canSave: boolean
  onSave: (values: SubmissionValues) => Promise<void>
  rubric?: AssignmentRubricFragment
  rubricLoading?: boolean
}
export const GradingModalSubmissionContext = React.createContext<
  GradingModalSubmissionContextValue | undefined
>(undefined)

export interface GradingModalSubmissionContextProviderProps {
  assignment: BasicAssignmentFragment
  submission?: FullAssignmentSubmissionFragment
  canSave?: boolean
  onSave?: (values: SubmissionValues) => Promise<void>
  onValuesChange?: (values: SubmissionValues) => void
  loading?: boolean
}
export function GradingModalSubmissionContextProvider({
  assignment,
  submission: _submission,
  onSave: _onSave,
  onValuesChange,
  children,
  loading: _loading,
}: React.PropsWithChildren<GradingModalSubmissionContextProviderProps>) {
  const form = Form.useForm<SubmissionValues>()
  const [canSave, setCanSave] = React.useState(false)
  const [grade, { loading }] = useGradeAssignmentSubmissionMutation({
    onCompleted: () => {
      message.success('Graded successfully!')
    },
  })
  const { data, loading: submissionLoading } = useAssignmentSubmissionQuery({
    variables: { id: _submission?.id || '' },
    skip: !_submission,
  })
  const querySubmission = data?.node?.__typename === 'AssignmentSubmission' ? data.node : undefined
  const rubric = assignment.rubric
  const submission = querySubmission || _submission
  React.useEffect(() => {
    if (submission) {
      debug('updating form fields', {
        grades: submission.grades,
        comments: submission.comments,
      })
      form.setValue('grades', submission.grades)
      form.setValue('comments', submission.comments)
    }
  }, [submission?.grades, submission?.comments])
  React.useEffect(() => {
    setCanSave(
      rubric?.categories.every(cat => submission?.grades?.[cat.categoryId] !== undefined) ||
        submission?.grades?.['all'] !== undefined,
    )
  }, [submission?.grades, rubric])
  const onSave = React.useMemo<GradingModalSubmissionContextValue['onSave']>(() => {
    return async values => {
      const grades = values.grades || {}
      if (
        grades.all !== null &&
        grades.all !== undefined &&
        Object.keys(grades).filter(key => grades[key] !== undefined && grades[key] !== null)
          .length > 1
      ) {
        debug('removing all grade, as there are other grades')
        delete grades.all
      }
      debug('saving assignment with grades', { ...values, grades })
      if (_onSave) {
        return _onSave({ ...values, grades })
      } else if (submission) {
        await grade({
          refetch: true,
          variables: {
            input: {
              id: submission.id,
              grades,
              comments: values.comments || {},
            },
          },
        })
      }
    }
  }, [_onSave])

  return (
    <GradingModalSubmissionContext.Provider
      value={{
        form,
        onSave,
        canSave: canSave && assignment.canGrade,
        loading: _loading || loading,
        submission,
        rubricLoading: submissionLoading,
        rubric: assignment.rubric || undefined,
      }}
    >
      <Form
        form={form}
        onValuesChange={values => {
          if (onValuesChange) {
            onValuesChange({
              comments: values.comments || {},
              grades: values.grades || {},
            })
          }
          setCanSave(
            rubric?.categories.every(cat => values.grades?.[cat.categoryId] !== undefined) ||
              values.grades?.['all'] !== undefined,
          )
        }}
        onFinish={onSave}
      >
        <Form.Item name={'grades.all'}>
          <input type={'hidden'} />
        </Form.Item>
        {children}
      </Form>
    </GradingModalSubmissionContext.Provider>
  )
}

export function useGradingModalSubmissionContext(): GradingModalSubmissionContextValue | undefined
export function useGradingModalSubmissionContext(
  require: false,
): GradingModalSubmissionContextValue | undefined
export function useGradingModalSubmissionContext(require: true): GradingModalSubmissionContextValue
export function useGradingModalSubmissionContext(
  require?: boolean,
): GradingModalSubmissionContextValue | undefined {
  const context = React.useContext(GradingModalSubmissionContext)
  if (!context && require) {
    throw new Error('GradingModalSubmissionContext is required, yet not provided.')
  }
  return context
}
