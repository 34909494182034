import { useIgnoreSamlErrors } from '@thesisedu/feature-users-react'
import React from 'react'

import { getListProviderClasses } from './listProviderClasses'
import { getPreAuthenticateWithProvider } from './preAuthenticateWithProvider'
import { getProviderError } from './providerError'
import { debug } from '../../log'
import { SiteClassSyncProviderFragment, useListSyncProviderClassesQuery } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, getStepFactory } from '../types'

export interface FetchClassesFromProviderContext {
  provider: SiteClassSyncProviderFragment
}

export default {
  animationGroup: 'loading',
  size: StepSizes.loading,
  hideClose: true,
  Component({ transition, context, cls }) {
    useIgnoreSamlErrors()
    useListSyncProviderClassesQuery({
      variables: { input: { provider: context.provider.identifier, classId: cls.id } },
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const classes = data?.listSyncProviderClasses ?? []
        transition(getListProviderClasses({ provider: context.provider, classes }))
      },
      onError(err) {
        const code = (err.graphQLErrors || [])[0]?.extensions?.code as string | undefined
        if (
          code === 'ASSOCIATED_ACCOUNT_IS_INVALID_ERROR' ||
          code === 'NOT_AUTHENTICATED_WITH_PROVIDER_ERROR'
        ) {
          debug('associated account is invalid; got code %s', code)
          debug('transitioning to preAuthenticateWithProvider')
          transition(
            getPreAuthenticateWithProvider({
              ...context,
              nextStep: getFetchClassesFromProvider(context),
            }),
          )
        } else {
          debug('something else went wrong; got code %s', code)
          transition(
            getProviderError({ ...context, nextStep: getFetchClassesFromProvider(context) }),
          )
        }
      },
    })
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        direction={'left'}
      />
    )
  },
} satisfies SetupStep<FetchClassesFromProviderContext>
export const getFetchClassesFromProvider = getStepFactory<FetchClassesFromProviderContext>(
  'fetchClassesFromProvider',
)
