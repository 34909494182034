import { PlusOutlined } from '@ant-design/icons'
import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import {
  useSelectedClassId,
  useTeacherOrStudentSelectedClass,
} from '@thesisedu/feature-classes-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Block, Body, BodyLarge, DATE_FORMATS, PageHeader, styled } from '@thesisedu/web'
import { Button, Empty, List } from 'antd'
import { set } from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  AnnouncementFragment,
  AnnouncementsForClassDocument,
  AnnouncementsForClassQuery,
  AnnouncementsForClassQueryVariables,
} from './schema'
import { AnnouncementsClassFragment } from './types'

const Announcement = styled.div`
  padding: 10px 0;
  > .unread a p {
    font-weight: bold !important;
  }
`

export function AnnouncementsList() {
  const { cls } = useTeacherOrStudentSelectedClass<AnnouncementsClassFragment>(true)
  const classId = useSelectedClassId(true)
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  return (
    <div>
      <PageHeader title={'Announcements'}>
        {viewer?.group === 'TEACHER' && cls?.canCreateAnnouncement ? (
          <Button type={'primary'} onClick={() => navigate('create')} icon={<PlusOutlined />}>
            Create Announcement
          </Button>
        ) : null}
      </PageHeader>
      <InfiniteQuery<
        AnnouncementFragment,
        AnnouncementsForClassQuery,
        AnnouncementsForClassQueryVariables
      >
        document={AnnouncementsForClassDocument}
        getResult={r => (r?.node?.__typename === 'Class' ? r.node.announcements : undefined)}
        setResult={(p, n) => set(cloneDeep(p), 'node.announcements', n)}
        queryOpts={{ fetchPolicy: 'cache-and-network' }}
        variables={{ classId }}
        children={({ data }) => {
          const announcements =
            data?.node?.__typename === 'Class'
              ? data.node.announcements.edges.map(edge => edge.node)
              : []
          if (announcements.length) {
            return (
              <List itemLayout={'vertical'}>
                {announcements.map(announcement => (
                  <List.Item key={announcement.id} style={{ padding: 0 }}>
                    <Announcement>
                      <div
                        className={
                          viewer?.group !== 'TEACHER' && !announcement.isRead ? 'unread' : ''
                        }
                      >
                        <Link to={announcement.id}>
                          <BodyLarge>{announcement.name}</BodyLarge>
                        </Link>
                        <Body color={'@text-color-secondary'}>
                          Posted {moment(announcement.createdAt).fromNow()} (
                          {moment(announcement.createdAt).format(DATE_FORMATS.FULL)})
                          {viewer?.group === 'TEACHER'
                            ? ` · ${pluralize('view', announcement.studentViews || 0, true)}`
                            : null}
                        </Body>
                      </div>
                    </Announcement>
                  </List.Item>
                ))}
              </List>
            )
          } else {
            return (
              <Block marginTop={'@size-l'}>
                <Empty description={'There are currently no announcements'}>
                  {viewer?.group === 'TEACHER' && cls?.canCreateAnnouncement ? (
                    <Button
                      type={'primary'}
                      onClick={() => navigate('create')}
                      icon={<PlusOutlined />}
                    >
                      Create Announcement
                    </Button>
                  ) : null}
                </Empty>
              </Block>
            )
          }
        }}
      />
    </div>
  )
}
