import { Select } from '@thesisedu/ui'
import { AppWindow } from '@thesisedu/ui/icons'
import React from 'react'

import { PrefixContainer, Indicator } from './styled'

export interface ScreenRecordingSelectProps {
  value?: boolean
  onChange?: (value?: boolean) => void
  disabled?: boolean
}
export function ScreenRecordingSelect({ value, onChange, disabled }: ScreenRecordingSelectProps) {
  return (
    <Select
      value={value ? 'enable' : 'disable'}
      onValueChange={value => {
        onChange?.(value === 'enable')
      }}
      defaultValue={'disable'}
      disabled={disabled}
      prefix={
        <PrefixContainer>
          <AppWindow />
          <Indicator $color={disabled || !value ? 'red' : 'green'} />
        </PrefixContainer>
      }
    >
      <Select.Item value={'enable'}>Enable Screen Sharing</Select.Item>
      <Select.Item value={'disable'}>No Screen Sharing</Select.Item>
    </Select>
  )
}
