import { UserMenuItemsHook } from '@thesisedu/feature-users-react'
import { Dropdown } from '@thesisedu/ui'
import { Mail } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DirectMessagesReactFeature } from '../DirectMessagesReactFeature'

function MessagesUserItem({ viewer }: UserMenuItemsHook['context']) {
  const navigate = useNavigate()
  if (!viewer?.group) return null
  return (
    <Dropdown.Item
      onClick={() => navigate(`/${viewer.group.toLowerCase().replace(/_/g, '-')}/messages`)}
      icon={<Mail />}
    >
      Messages
    </Dropdown.Item>
  )
}

export default function (feature: DirectMessagesReactFeature) {
  feature.hookManager.registerMutateHook<UserMenuItemsHook>(
    'feature-users-react:user-menu',
    (items, context) => {
      if (context.viewer?.group) {
        return [...items, <MessagesUserItem {...context} key={'messages'} />]
      } else {
        return items
      }
    },
  )
}
