import { AssignmentRubricInput } from '../schema'

export const DEFAULT_RUBRIC: AssignmentRubricInput = {
  categories: [
    {
      name: 'Category One',
      description: '',
      totalPoints: 8,
      id: 'one',
      weight: 1,
      presets: [
        { id: 'exc', name: 'Distiguished', points: 8, description: '', weight: 0 },
        { id: 'good', name: 'Proficient', points: 6, description: '', weight: 1 },
        { id: 'fine', name: 'Developing', points: 4, description: '', weight: 2 },
        { id: 'bad', name: 'Beginning', points: 2, description: '', weight: 3 },
      ],
    },
  ],
}
