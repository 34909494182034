import { AppstoreFilled, CalendarOutlined, MenuOutlined } from '@ant-design/icons'
import { Col, Empty, Radio } from 'antd'
import React, { useEffect, useState } from 'react'

import { FragmentCalendarView } from './FragmentCalendarView'
import { FragmentGridView } from './FragmentGridView'
import { FragmentListView } from './FragmentListView'
import { FragmentListCommonProps, FragmentListMode } from './types'
import { Block } from '../Block'
import { BlockSpin } from '../LoadingIndicators'
import { Row } from '../Row'

export interface FragmentListProps<T> extends FragmentListCommonProps<T> {
  filters?: React.ReactNode
  emptyContent?: React.ReactNode
  forceMode?: FragmentListMode
  loading?: boolean
}
export function FragmentList<T>({
  sections,
  filters,
  emptyContent = <Empty />,
  forceMode,
  getRowData,
  tableProps,
  loading,
  ...otherProps
}: FragmentListProps<T>) {
  const listMode = new URLSearchParams(window.location.search).get('view')
  const [selectedMode, setSelectedMode] = useState<FragmentListMode>(FragmentListMode.GRID)
  const initialMount = React.useRef(true)

  useEffect(() => {
    if (forceMode !== undefined) {
      setSelectedMode(forceMode)
    }
  }, [forceMode])
  useEffect(() => {
    // We have to wait until after the first mount, otherwise we'll end up in a loop calling `.refetch()` over and over again.
    if (!initialMount.current) {
      if (otherProps.refetch) {
        otherProps.refetch(null)
      }
    }
    initialMount.current = false
  }, [selectedMode])
  useEffect(() => {
    _setSelectedMode(
      listMode !== undefined ? (Number(listMode) as FragmentListMode) : FragmentListMode.GRID,
    )
  }, [listMode])

  const _setSelectedMode = (fragmentView: FragmentListMode) => {
    const params = new URLSearchParams(window.location.search)
    params.set('view', fragmentView.toString())
    const stringParams = params.toString()
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}${stringParams ? `?${stringParams}` : ''}`,
    )
    setSelectedMode(fragmentView)
  }

  const modeSelector = (
    <Radio.Group
      value={selectedMode}
      buttonStyle={'solid'}
      onChange={e => _setSelectedMode(e.target.value)}
    >
      <Radio.Button value={FragmentListMode.GRID}>
        <AppstoreFilled /> Grid
      </Radio.Button>
      <Radio.Button value={FragmentListMode.CALENDAR}>
        <CalendarOutlined /> Calendar
      </Radio.Button>
      {getRowData ? (
        <Radio.Button value={FragmentListMode.LIST}>
          <MenuOutlined /> List
        </Radio.Button>
      ) : null}
    </Radio.Group>
  )
  let content: React.ReactNode = null
  if (selectedMode === FragmentListMode.GRID) {
    content = <FragmentGridView sections={sections} {...otherProps} />
  } else if (selectedMode === FragmentListMode.LIST) {
    content = (
      <FragmentListView
        sections={sections}
        getRowData={getRowData}
        tableProps={tableProps}
        {...otherProps}
      />
    )
  } else if (selectedMode === FragmentListMode.CALENDAR) {
    content = (
      <FragmentCalendarView
        headerLeft={filters}
        sections={sections}
        {...otherProps}
        headerRight={forceMode !== undefined ? undefined : modeSelector}
      />
    )
  }
  return (
    <>
      {selectedMode !== FragmentListMode.CALENDAR && (filters || forceMode === undefined) ? (
        <Block marginBottom={'@size-m'}>
          <Row align={'middle'}>
            <Col xs={24} md={16}>
              {filters}
            </Col>
            {forceMode !== undefined ? null : (
              <Col xs={24} md={8} style={{ textAlign: 'right' }}>
                {modeSelector}
              </Col>
            )}
          </Row>
        </Block>
      ) : null}
      {loading ? (
        <BlockSpin />
      ) : (sections.length && sections.some(sec => sec.items.length)) ||
        selectedMode !== FragmentListMode.GRID ? (
        content
      ) : (
        emptyContent
      )}
    </>
  )
}
