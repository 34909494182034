import { useClassStudents, useSelectedClass } from '@thesisedu/feature-classes-react'
import { Table } from 'antd'
import pluralize from 'pluralize'
import React from 'react'

import { ReportSummary, ReportSummaryItem } from './ReportSummary'
import { useSegmentTimeOnTaskQuery } from '../queries/useSegmentTimeOnTaskQuery'

export interface TimeOnTaskRow extends ReportSummaryItem {
  minutes: number
}
export interface SegmentTimeOnTaskReportProps {
  segmentId: string
}
export function SegmentTimeOnTaskReport({ segmentId }: SegmentTimeOnTaskReportProps) {
  const cls = useSelectedClass(true)
  const { students, loading: studentsLoading } = useClassStudents()
  const { data, loading: reportLoading } = useSegmentTimeOnTaskQuery({
    variables: {
      classId: cls.id,
      input: {
        rawSegmentId: segmentId,
      },
    },
  })

  const loading = studentsLoading || reportLoading
  const timeOnTasks = data?.node?.__typename === 'Class' ? data.node.timeOnTask.timeOnTasks : []
  const combined = timeOnTasks
    .filter(tot => students?.find(s => s.user.id === tot.studentUserId))
    .map(tot => ({
      student: students.find(s => s.user.id === tot.studentUserId)!,
      minutes: tot.timeOnTaskMinutes,
    }))

  return (
    <>
      <ReportSummary<TimeOnTaskRow>
        rows={combined}
        metric={{ key: 'minutes', name: 'Total Time Viewing Content' }}
        loading={loading}
      >
        <Table.Column<TimeOnTaskRow>
          dataIndex={'minutes'}
          title={'Total Time Viewing Content'}
          render={minutes => pluralize('minute', minutes, true)}
        />
      </ReportSummary>
    </>
  )
}
