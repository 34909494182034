import * as ReactContextMenu from '@radix-ui/react-context-menu'
import React from 'react'

export interface ContextMenuControlledTriggerProps {
  activeCoordinates?: [number, number]
}
export function ContextMenuControlledTrigger({
  activeCoordinates,
}: ContextMenuControlledTriggerProps) {
  const triggerRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (activeCoordinates && triggerRef.current) {
      triggerRef.current.dispatchEvent(
        new PointerEvent('contextmenu', {
          clientX: activeCoordinates[0],
          clientY: activeCoordinates[1],
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )
    }
  }, [activeCoordinates])
  return <ReactContextMenu.Trigger ref={triggerRef} />
}
