import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { RecordViewModal } from '@thesisedu/feature-media-react'
import { AntIconWrapper } from '@thesisedu/web'
import { Button } from 'antd'
import { VideoCamera } from 'iconoir-react'
import React from 'react'

import { hasValue } from '../../../editor/useHasValue'
import { SheetMusicPlugin } from '../../../media/SheetMusicPlugin'

export interface DragDropRecordButtonProps {
  abc: string
  onComplete: (mediaId: string) => void
  questionName: string
  mediaId?: string
}
export function DragDropRecordButton({
  abc,
  questionName,
  onComplete,
  mediaId,
}: DragDropRecordButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const classId = useSelectedClassId(true)
  return (
    <>
      <RecordViewModal
        visible={visible}
        onVisibleChange={setVisible}
        classId={classId}
        label={`Performance for ${questionName}`}
        skipReview
        waitForUpload
        onCreated={result => {
          if (result.media) {
            onComplete(result.media.media.id)
          }
        }}
        onProcessed={result => {
          if (result.media) {
            setVisible(false)
          }
        }}
      >
        <SheetMusicPlugin abc={abc} />
      </RecordViewModal>
      <Button
        size={'large'}
        type={'primary'}
        icon={
          <AntIconWrapper>
            <VideoCamera />
          </AntIconWrapper>
        }
        onClick={() => setVisible(true)}
        disabled={!hasValue(abc)}
      >
        {mediaId ? 'Record a New Performance' : 'Record your Performance'}
      </Button>
    </>
  )
}
