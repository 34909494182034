import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import { TrialRequestSignupPage } from '../../TrialRequestSignupPage'
import { TrialsWebFeature } from '../../TrialsWebFeature'

export function WelcomePage() {
  const { signupAuthProviders, welcomeComponent } = useFeature(TrialsWebFeature).options
  const WelcomeComponent = React.useMemo(welcomeComponent, [])
  return (
    <TrialRequestSignupPage authProviders={signupAuthProviders}>
      <WelcomeComponent />
    </TrialRequestSignupPage>
  )
}
