import { PromptContextValue } from '@thesisedu/react'
import React from 'react'

import { SubmissionGradeHeaderProps } from '../grading/SubmissionGradeHeader'
import { FullAssignment } from '../grading/types'

export interface GradingModalContextValue extends PromptContextValue {
  assignment: FullAssignment
  selectedTab: string
  setSelectedTab: (key: string) => void
  renderHeader?: (props: SubmissionGradeHeaderProps) => React.ReactElement
}
export const GradingModalContext = React.createContext<GradingModalContextValue | undefined>(
  undefined,
)

export interface GradingModalContextProviderProps extends GradingModalContextValue {}
export function GradingModalContextProvider({
  children,
  ...value
}: React.PropsWithChildren<GradingModalContextProviderProps>) {
  return <GradingModalContext.Provider value={value} children={children} />
}

export function useGradingModalContext(): GradingModalContextValue | undefined
export function useGradingModalContext(require: false): GradingModalContextValue | undefined
export function useGradingModalContext(require: true): GradingModalContextValue
export function useGradingModalContext(require?: boolean): GradingModalContextValue | undefined {
  const context = React.useContext(GradingModalContext)
  if (!context && require) {
    throw new Error('GradingModalContext is required, yet not provided.')
  }
  return context
}
