import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { TextNode } from 'lexical'

import { ImageNode } from './ImageNode'
import { StyledMarkNode } from './StyledMarkNode'
import { TableNode } from './TableNode'
import { modifyLinkNode } from './modifyLinkNode'

// directly update function(s) on LinkNode prototype
modifyLinkNode()

export const CORE_NODES: any[] = [
  HorizontalRuleNode,
  TextNode,
  AutoLinkNode,
  LinkNode,
  ListNode,
  ListItemNode,
  HeadingNode,
  QuoteNode,
  TableNode,
  ImageNode,
  StyledMarkNode,
]
