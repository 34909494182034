import {
  $createHorizontalRuleNode,
  $isHorizontalRuleNode,
} from '@lexical/react/LexicalHorizontalRuleNode'
import { Minus } from '@thesisedu/ui/icons'
import React from 'react'

import { ElementsGroup, useElementsMenuItem } from '../../ui/ElementsMenu/ElementsMenuContext'

export function HorizontalRulePlugin() {
  useElementsMenuItem({
    identifier: 'hr',
    title: 'Horizontal Rule',
    weight: 34,
    group: ElementsGroup.Layout,
    isSelected: $isHorizontalRuleNode,
    onCommit(element) {
      return element.replace($createHorizontalRuleNode())
    },
    noReplace: true,
    icon: <Minus />,
  })

  return null
}
