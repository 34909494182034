import { ReactWindowScroller } from '@thesisedu/react'
import React from 'react'

import { OutlineList, OutlineListImperativeHandle, OutlineListProps } from './OutlineList'

export interface OutlineWindowListProps extends Omit<OutlineListProps, 'height'> {}
function _OutlineWindowList(
  props: OutlineWindowListProps,
  listRef: React.ForwardedRef<OutlineListImperativeHandle>,
) {
  return (
    <ReactWindowScroller>
      {({ onScroll, outerRef, ref, style }) => (
        <OutlineList
          {...props}
          height={window.innerHeight}
          ref={listRef}
          listProps={{
            ...props.listProps,
            onScroll,
            outerRef,
            ref,
            style: {
              ...style,
              overflow: 'visible',
            },
          }}
        />
      )}
    </ReactWindowScroller>
  )
}
export const OutlineWindowList = React.forwardRef(_OutlineWindowList)
