import { Theme as NativeTheme } from '@thesisedu/native'
import Case from 'case'
import { transparentize } from 'polished'
import React from 'react'
import { ThemeContext, ThemeProps } from 'styled-components'

import { warn } from './log'
import { Color, Size } from './types'
import {
  Body,
  BodyExtraSmall,
  BodyLarge,
  BodyLarger,
  BodySmall,
  TypographyProps,
} from './typography'

type WebTheme = Record<string, any>
function isNativeTheme(theme: NativeTheme | WebTheme): theme is NativeTheme {
  return typeof (theme as NativeTheme).size === 'object'
}

function convertSizeToNativeTheme(size: string): keyof NativeTheme['size'] {
  return size.replace('@size-', '') as keyof NativeTheme['size']
}
export function sizeToNumber(size: string): number {
  return parseFloat(size.replace('px', ''))
}
function convertColorToNativeTheme(color: string): keyof NativeTheme['colors'] {
  if (color === '@primary-color') return 'primary'
  if (color === '@primary-color-light') return 'primaryLight'
  if (color === '@text-color-secondary') return 'textSecondary'
  if (color === '@border-color-base') return 'borderColor'
  return Case.camel(color.replace('@', '')) as keyof NativeTheme['colors']
}

/**
 * Given a theme and a web-theme size name, gets the proper size depending
 * on if the passed theme is a web theme or a native theme.
 */
export function getSize(theme: NativeTheme | WebTheme, size: string) {
  if (size === '@border-radius-base') {
    return getBorderRadius(theme)
  } else if (size === '@border-radius-large') {
    return getLargeBorderRadius(theme)
  }
  return isNativeTheme(theme) ? theme.size[convertSizeToNativeTheme(size)] : theme[size]
}

/**
 * Gets the border radius size from the passed theme.
 */
export function getBorderRadius(theme: NativeTheme | WebTheme) {
  return isNativeTheme(theme) ? theme.borderRadius : theme['@border-radius-base']
}

/**
 * Gets the large border radius size from the passed theme.
 */
export function getLargeBorderRadius(theme: NativeTheme | WebTheme) {
  return isNativeTheme(theme) ? theme.borderRadiusLarge : theme['@border-radius-large']
}

export interface ColorOpts {
  ghost?: boolean | number
}

/**
 * Given a theme and a web-theme color name, gets the proper color depending
 * on if the passed theme is a web theme or a native theme.
 */
export function getColor(
  theme: NativeTheme | WebTheme,
  color: string | Color,
  _suppress?: boolean,
  opts?: ColorOpts,
) {
  const normalColor = color.startsWith('@') ? color : `@${color}`
  if (isNativeTheme(theme)) {
    const colorName = convertColorToNativeTheme(normalColor)
    if (!theme.colors[colorName] && !_suppress) {
      warn('color %s does not exist in native theme', colorName)
    }
    return opts?.ghost
      ? transparentize(opts.ghost === true ? 0.35 : opts.ghost, theme.colors[colorName])
      : theme.colors[colorName]
  } else {
    return opts?.ghost
      ? transparentize(opts.ghost === true ? 0.35 : opts.ghost, theme[normalColor])
      : theme[normalColor]
  }
}

export function size(size: string) {
  return (props: ThemeProps<NativeTheme | WebTheme>) => getSize(props.theme, size)
}
export function color(color: string | Color, opts?: ColorOpts) {
  return (props: ThemeProps<NativeTheme | WebTheme>) =>
    getColor(props.theme, color, undefined, opts)
}

/** Gets the current styled components theme. */
export function useTheme() {
  return React.useContext(ThemeContext) as Record<string, any>
}

const sizeMap: Record<Size, (props: TypographyProps) => React.ReactElement | null> = {
  default: Body,
  small: BodySmall,
  xsmall: BodyExtraSmall,
  large: BodyLarge,
  xlarge: BodyLarger,
}
export function typographyFromSize(size: Size) {
  return sizeMap[size]
}
