import { FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'

import { hasFeaturePreview } from './useFeaturePreview'

export class FeaturePreviewReactFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-feature-preview-react'
  public static path = __dirname
  public static requires: string[] = []

  public hasFeaturePreview = hasFeaturePreview
}

export const featurePreviewReactFeature = (opts: object = {}): FeatureUse => [
  FeaturePreviewReactFeature,
  opts,
]
