import { NavigationListPulseHeader } from '@thesisedu/react/devtools'
import { Button, HSpaced } from '@thesisedu/ui'
import React from 'react'

export const ListHeaderContext = React.createContext<{
  loading: boolean
  current: number | null
  totalCount: number | null
  onRefresh: () => void
}>({
  loading: true,
  current: null,
  totalCount: null,
  onRefresh() {},
})

export function ListHeader() {
  const { loading, current, totalCount, onRefresh } = React.useContext(ListHeaderContext)
  return (
    <NavigationListPulseHeader
      loading={loading}
      children={
        loading ? (
          totalCount ? (
            `Loading ${current} / ${totalCount}`
          ) : (
            'Loading...'
          )
        ) : (
          <HSpaced space={'xs'} style={{ flex: 1 }}>
            <span>Found {current} items.</span>
            <Button
              style={{ marginLeft: 'auto' }}
              size={'extraSmall'}
              variant={'chromeless'}
              children={'Refresh'}
              onPress={onRefresh}
            />
          </HSpaced>
        )
      }
    />
  )
}
