import { ImportStudentModeResource } from '@thesisedu/feature-classes-react'
import { UserScan } from '@thesisedu/react/icons'
import React from 'react'

import { ExpandedContent, MainContent } from '../ImportStudentID'
import { StudentIdReactFeature } from '../StudentIdReactFeature'

export default function (feature: StudentIdReactFeature) {
  feature.resources.addResource<ImportStudentModeResource>({
    type: 'ImportStudentMode',
    identifier: 'student-id',
    ExpandedContentComponent: ExpandedContent,
    MainContentComponent: MainContent,
    title: 'Use Student IDs',
    icon: <UserScan />,
    group: 'Legacy',
    weight: 100,
    widths: {
      expanded: 600,
    },
  })
}
