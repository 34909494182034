import { MutationTuple } from '@apollo/client'
import { Format } from '@thesisedu/feature-utils'
import { Modal } from '@thesisedu/react'
import { EyeAlt } from '@thesisedu/react/icons'
import React from 'react'

import { clearAnswers } from './answerStore'
import { assignmentAllowsForfeit } from './forfeit'
import { useStudentForfeitAssignment } from './useStudentForfeitAssignment'
import { SubmissionContextValue } from '../contexts/SubmissionContext'
import { useAssignmentQueryVariables } from '../queries/useAssignmentQueryVariables'
import { useRetrySubmissionMutation } from '../queries/useRetrySubmissionMutation'
import {
  AssignmentWithRemainingAttemptsFragment,
  BasicAssignmentFragment,
  BasicAssignmentSubmissionFragment,
  RetrySubmissionMutation,
  RetrySubmissionMutationVariables,
} from '../schema'

export interface UseRetryAssignmentOpts {
  assignmentId: string
  classId: string
  studentId?: string
  assignment?: AssignmentWithRemainingAttemptsFragment &
    Pick<BasicAssignmentFragment, 'revealAnswers' | 'maxAttempts' | 'revealAttempts'>
  submission?: BasicAssignmentSubmissionFragment
  onSubmissionCreated?: SubmissionContextValue['onSubmissionCreated']
}
export function useRetryAssignment({
  assignmentId,
  classId,
  studentId,
  assignment,
  submission,
  onSubmissionCreated,
}: UseRetryAssignmentOpts): [
  (submission: BasicAssignmentSubmissionFragment) => void,
  () => void,
  MutationTuple<RetrySubmissionMutation, RetrySubmissionMutationVariables>[1],
] {
  const [retry, mutation] = useRetrySubmissionMutation({
    onCompleted(data) {
      const submission = data.retrySubmission.assignmentSubmission
      onSubmissionCreated?.(submission.id)
    },
    refetchQueries: ['assignmentSubmissions'],
  })
  const [forfeit] = useStudentForfeitAssignment()
  const assignmentVariables = useAssignmentQueryVariables(submission?.classId)
  const variables: RetrySubmissionMutationVariables | undefined =
    submission && assignment
      ? {
          input: {
            classId: submission.classId,
            id: assignment.id,
          },
          classId: submission.classId,
          ...assignmentVariables,
        }
      : undefined

  const promptRetry = () => {
    Modal.confirm({
      title: 'Are you sure you want to retry this assignment?',
      okText: 'Yes, retry assignment.',
      okButtonProps: { disabled: !assignment?.id },
      cancelText: 'No, go back.',
      onOk: () => {
        if (assignment?.id && submission && variables) {
          clearAnswers(submission.studentId, classId, assignmentId, undefined)
          return retry({ variables })
        }
      },
    })
  }

  return [
    submittedSubmission => {
      if (!submittedSubmission || !assignment || !studentId || !variables) return
      const isReviewed = submittedSubmission.reviewed
      const remainingAttempts = assignment.remainingAttempts
      const isPerfectGrade = false
      const shouldPromptRetry =
        isReviewed && remainingAttempts && remainingAttempts > 0 && !isPerfectGrade
      if (shouldPromptRetry) {
        const canForfeit = assignmentAllowsForfeit(assignment)
        const handle = Modal.confirm({
          title: 'Do you want to retry this assignment?',
          width: 600,
          content: (
            <>
              <p>
                On this assignment, your highest grade was:
                <br />
                <strong style={{ fontSize: 20 }}>
                  {Format.number(submittedSubmission.grade, 'decimalPercentage')}
                </strong>
              </p>
              <p>
                You may attempt this assignment {remainingAttempts}
                &nbsp;more {remainingAttempts === 1 ? 'time' : 'times'}.
              </p>
              {canForfeit ? (
                <p>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      handle.destroy()
                      return forfeit(assignment.id, submittedSubmission.classId)
                    }}
                  >
                    <EyeAlt /> Forfeit the rest of my attempts and show me the answers.
                  </a>
                </p>
              ) : null}
            </>
          ),
          okText: 'Yes, retry assignment.',
          cancelText: 'No, continue.',
          onOk: () => {
            clearAnswers(studentId, classId, assignmentId, undefined)
            return retry({ variables })
          },
        })
      }
    },
    promptRetry,
    mutation,
  ]
}
