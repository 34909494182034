import React from 'react'

import { ThemeColorVariant } from './types'

export interface ThemeContextValue {
  colorVariant: ThemeColorVariant
  setColorVariant: (variant: ThemeColorVariant) => void
}
export const ThemeContext = React.createContext<ThemeContextValue | undefined>(undefined)

export function useThemeContext(): ThemeContextValue | undefined
export function useThemeContext(require: false): ThemeContextValue | undefined
export function useThemeContext(require: true): ThemeContextValue
export function useThemeContext(require?: boolean): ThemeContextValue | undefined {
  const context = React.useContext(ThemeContext)
  if (!context && require) {
    throw new Error('ThemeContext is required, yet not provided.')
  }
  return context
}

export interface ForwardThemeContextValue {
  colorVariant?: ThemeColorVariant
}
export const ForwardThemeContext = React.createContext<ForwardThemeContextValue | undefined>(
  undefined,
)

export function useForwardThemeContext(): ForwardThemeContextValue | undefined
export function useForwardThemeContext(require: false): ForwardThemeContextValue | undefined
export function useForwardThemeContext(require: true): ForwardThemeContextValue
export function useForwardThemeContext(require?: boolean): ForwardThemeContextValue | undefined {
  const context = React.useContext(ForwardThemeContext)
  if (!context && require) {
    throw new Error('ForwardThemeContext is required, yet not provided.')
  }
  return context
}
