import { AssignmentEditorField, EditAssignmentForm } from '@thesisedu/feature-assignments-react'
import {
  SectionIconField,
  SectionLabelField,
  SectionNameField,
  SectionTagsField,
  SegmentProps,
  SimpleEditableSegment,
  StudentContentField,
  TeacherContentField,
} from '@thesisedu/feature-courses-react'
import { Divider, Form } from 'antd'
import React from 'react'

import { AssignmentTemplateId } from './AssignmentTemplateId'
import { SimpleSegmentCalculationField } from './SimpleSegmentCalculationField'
import { useSegmentAssignmentEdit } from './useSegmentAssignmentEdit'
import { RubricTypeSelect } from '../../RubricTypeSelect'

export function AssignmentSimpleEdit(props: SegmentProps) {
  const { onChange, assignment, loading } = useSegmentAssignmentEdit(props)
  return (
    <SimpleEditableSegment
      {...props}
      onChange={async (originalSegment, changes) => {
        await onChange(originalSegment, changes)
      }}
      typeLabel={'Assignment'}
      rightContent={({ form }) => (
        <>
          <AssignmentTemplateId templateId={props.segment.config.templateId} />
          <SectionLabelField initialValue={props.segment.config.label} />
          <Form.Item
            name={['config', 'templateId']}
            initialValue={props.segment.config.templateId}
            noStyle
          >
            <input type={'hidden'} />
          </Form.Item>
          <EditAssignmentForm form={form} disableInput={loading} value={assignment} hideName />
          <Form.Item
            name={['config', 'rubricType']}
            label={'Rubric Type'}
            initialValue={props.segment.config.rubricType}
            rules={[
              { required: true, message: 'You must select a rubric type for the assignment.' },
            ]}
          >
            <RubricTypeSelect />
          </Form.Item>
          <SectionIconField initialValue={props.segment.config.icon} />
          <Form.Item
            name={['assignment', 'configuration', 'calculation']}
            initialValue={assignment?.configuration?.calculation}
            label={'Quiz Vault'}
            extra={
              <span>
                If this is enabled, you will not configure this assignment's questions here.
                Instead, you will add them from existing assignments whenever you add this
                assignment to your class and enable it.
              </span>
            }
          >
            <SimpleSegmentCalculationField />
          </Form.Item>
          <SectionTagsField initialValue={props.segment.tagSummary || props.segment.tags} />
        </>
      )}
      children={({ typeLabel }) => (
        <>
          <SectionNameField typeLabel={typeLabel} initialValue={props.segment.name} />
          <StudentContentField
            segmentId={props.segment.id}
            initialValue={props.segment.config.studentContent || []}
          />
          <Divider />
          <Form.Item
            name={['assignment', 'configuration']}
            label={'Questions'}
            initialValue={assignment?.configuration || props.segment.assignment?.configuration}
          >
            <AssignmentEditorField assignment={assignment} />
          </Form.Item>
          <Divider />
          <TeacherContentField
            segmentId={props.segment.id}
            initialValue={props.segment.config.teacherContent || []}
          />
        </>
      )}
    />
  )
}
