import React from 'react'

import { SearchContext } from './NavigationList'
import { Reset } from '../Reset'
import { styled } from '../styled'
import { color, size } from '../theme'

export interface NavigationListSearchProps {
  placeholder?: string
  right?: React.ReactElement
}
export function NavigationListSearch() {
  const {
    setSearch,
    search,
    props: { placeholder = 'Search...', right = null } = {},
  } = React.useContext(SearchContext)
  return (
    <Container key={'search-container'}>
      <SearchInput
        key={'search-input'}
        value={search}
        onChange={e => {
          setSearch(e.target.value)
        }}
        placeholder={placeholder}
        type={'search'}
        autoCapitalize={'none'}
      />
      {right ? <RightContainer children={right} /> : null}
    </Container>
  )
}

const Container = styled.div`
  background: ${color('@gray-1')};
  display: flex;
  align-items: stretch;
  gap: ${size('@size-xs')};
  height: 30px;
`
const RightContainer = styled.div`
  padding: 0 ${size('@size-xs')} 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const SearchInput = styled(Reset.Input)`
  padding: 0 ${size('@size-xs')};
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: ${color('@text-color')};
  background: ${color('@gray-1')};
  border-bottom: solid 1px ${color('@border-color-base')};
  flex: 1;
`
