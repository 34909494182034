import { Body, HSpaced, ModalNavigationItem, Space, Tooltip } from '@thesisedu/react'
import { HelpCircle } from '@thesisedu/react/icons'
import React from 'react'

import { getStudentClassTabName } from './helpers'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { BasicAssignmentSubmissionFragment } from '../schema'

export interface GradingModalSidebarInProgressAttemptListItemProps {
  submission: BasicAssignmentSubmissionFragment
  noSubmissionIdInTabName?: boolean
}
export function GradingModalSidebarInProgressAttemptListItem({
  submission,
  noSubmissionIdInTabName,
}: GradingModalSidebarInProgressAttemptListItemProps) {
  const { selectedTab, setSelectedTab } = useGradingModalContext(true)
  // We force the usage of the submission ID for in-progress attempts because the no-submission-id
  // slot is reserved for the latest *submitted* attempt.
  const tabName = getStudentClassTabName(
    submission.studentId,
    submission.classId,
    noSubmissionIdInTabName ? undefined : submission.id,
  )

  return (
    <ModalNavigationItem
      onClick={() => {
        setSelectedTab(tabName)
      }}
      selected={selectedTab === tabName}
    >
      <HSpaced align={'center'} style={{ width: '100%' }}>
        <Body color={'@text-color-secondary'}>1 in-progress attempt...</Body>
        <Space />
        <Tooltip title={'Information about in-progress attempts.'}>
          <Body color={'@text-color-secondary'}>
            <HelpCircle />
          </Body>
        </Tooltip>
      </HSpaced>
    </ModalNavigationItem>
  )
}
