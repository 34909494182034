import { InfoCircleOutlined } from '@ant-design/icons'
import { useMutateHook } from '@thesisedu/feature-react'
import { Block, Body, FontWeight, styled, BodyLarge, CapitalSmall } from '@thesisedu/web'
import { Popover } from 'antd'
import React from 'react'

import { SegmentInfoCoverage } from './SegmentInfoCoverage'
import { SegmentInfoDays } from './SegmentInfoDays'
import { SegmentType } from '../schema'
import {
  CoursesHooks,
  Segment,
  SegmentInfoPopoverItemsPayload,
  SegmentInfoPopvoerItemsContext,
} from '../types'

export interface SegmentInfoPopoverProps {
  segment: Segment
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
}
export function SegmentInfoPopover({ segment, visible, onVisibleChange }: SegmentInfoPopoverProps) {
  const items = useMutateHook<SegmentInfoPopoverItemsPayload, SegmentInfoPopvoerItemsContext>(
    CoursesHooks.SegmentInfoPopoverItems,
    [],
    { segment },
  )
  return (
    <Popover
      trigger={['hover']}
      placement={'rightTop'}
      visible={visible}
      onVisibleChange={onVisibleChange}
      overlayInnerStyle={{ maxWidth: 350 }}
      content={
        <>
          <CapitalSmall color={'@text-color-secondary'}>
            {segment.type === SegmentType.Topic ? 'Topic' : segment.config.label || 'Lesson'}
          </CapitalSmall>
          <Block marginTop={'@size-xxs'}>
            <BodyLarge weight={FontWeight.SemiBold}>{segment.name}</BodyLarge>
          </Block>
          <InfoContainer>
            <SegmentInfoDays segment={segment} />
            <SegmentInfoCoverage segment={segment} />
            {items}
          </InfoContainer>
        </>
      }
    >
      <InfoCircleOutlined className={'info-popover-btn'} />
    </Popover>
  )
}

const InfoContainer = styled.div`
  margin-top: ${props => props.theme['@size-s']};
  > :not(:last-child) {
    margin-bottom: ${props => props.theme['@size-xs']} !important;
  }
`

export interface SegmentInfoItemProps {
  icon: React.ReactElement
}
export function SegmentInfoItem({ icon, children }: React.PropsWithChildren<SegmentInfoItemProps>) {
  return (
    <Body>
      <InfoItemContainer>
        {icon}
        {children}
      </InfoItemContainer>
    </Body>
  )
}
const InfoItemContainer = styled.div`
  vertical-align: center;
  line-height: 1;
  color: ${props => props.theme['@text-color']};
  svg {
    fill: ${props => props.theme['@gray-5']};
    width: ${props => props.theme['@size-s']};
    height: ${props => props.theme['@size-s']};
    margin-right: ${props => props.theme['@size-xs']};
    display: inline-block;
  }
`
