import { Block } from '@thesisedu/ui'
import React from 'react'
import { createPortal } from 'react-dom'

import { useCourseViewerModalHeaderContext } from '../contexts/CourseViewerModalHeaderContext'

export function CourseViewerSegmentHeaderPortal() {
  const { headerPortalElement } = useCourseViewerModalHeaderContext(false) ?? {}
  return <Block left={'s'} ref={headerPortalElement} />
}

export interface CourseViewerSegmentHeaderPortalContentProps {
  children: React.ReactNode
}
export function CourseViewerSegmentHeaderPortalContent({
  children,
}: CourseViewerSegmentHeaderPortalContentProps) {
  const { headerPortalElement } = useCourseViewerModalHeaderContext(false) ?? {}
  return headerPortalElement?.current ? createPortal(children, headerPortalElement.current) : null
}
