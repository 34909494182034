import { Operation, PossibleTypesMap } from '@apollo/client'
import { FeatureResource, MutateHook } from '@thesisedu/feature'
import { ProgressResultPayload } from '@thesisedu/feature/dist/services/BackgroundJobService'
import { FragmentDefinitionNode, GraphQLFormattedError } from 'graphql'

import { EnrichedBackgroundJob } from './background'
import { BackgroundJobStatus } from './schema'

export { PreloadQueryResource } from './preload'

export enum ApolloReactHooks {
  ExpectedError = 'feature-apollo-react:expected-error',
}
export type ExpectedErrorPayload = GraphQLFormattedError & {
  shouldRetry: boolean
  extensions?: { [key: string]: any }
}
export interface ExpectedErrorContext {
  code: string
  operation?: Operation
}

export interface ApolloReactPersistence {
  disabled?: boolean
  persistKey: string
  schemaVersion: string
  schemaVersionKey: string
  hideBehindPreview?: boolean
  debug?: boolean
}
export interface ApolloReactOptions {
  host: string
  authenticationKey: string
  possibleTypes?: PossibleTypesMap
  persistence?: ApolloReactPersistence
}

export type FragmentModifyFn = (
  fragment: Readonly<FragmentDefinitionNode>,
  context: MutateFragmentContext,
) => FragmentDefinitionNode

export type MutateFragmentPayload = FragmentDefinitionNode
export interface MutateFragmentContext {
  additionalDefinitions: FragmentDefinitionNode[]
}

export type MutateCompletedMutation = MutateHook<
  'apollo-react:completed-mutation',
  undefined,
  { data: any }
>

export interface BackgroundJobReporterProps<
  Payload extends Record<string, any> = any,
  ResultPayload extends Record<string, any> = any,
> {
  job: EnrichedBackgroundJob<Payload, ResultPayload>
  children: React.ReactElement
  /** This should be called whenever the job should be removed from the queue. */
  onClose: () => void
}

export interface BackgroundJobReporterResource<
  Payload extends Record<string, any> = any,
  ResultPayload extends Record<string, any> = any,
> extends FeatureResource {
  type: 'BackgroundJobReporter'
  /* This should match the background job resource identifier from the server. */
  identifier: string
  humanLabel: (
    payload?: Payload,
    resultPayload?: ResultPayload,
    progress?: ProgressResultPayload,
    status?: BackgroundJobStatus,
  ) => string
  /** If you would like to have more control over how progress is rendered, use this prop. */
  render?: (props: BackgroundJobReporterProps<Payload, ResultPayload>) => React.ReactElement
  /** This is run after the background job is complete. */
  afterComplete?: (payload?: Payload, resultPayload?: ResultPayload) => void | Promise<void>
}
