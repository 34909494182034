import React from 'react'

export type AProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  isText?: boolean
}
/** @deprecated */
export function A(props: AProps) {
  return <a {...props} />
}
