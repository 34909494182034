import { styled, s } from '@thesisedu/ui'
import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { RecordButtonCountdownState } from './RecordButtonCountdownState'
import { RecordButtonDefaultState } from './RecordButtonDefaultState'
import { RecordButtonPausedState } from './RecordButtonPausedState'
import { RecordButtonPrepareState } from './RecordButtonPrepareState'
import { RecordButtonRecordingState } from './RecordButtonRecordingState'
import { RecordButtonStoppingState } from './RecordButtonStoppingState'
import { RecordFooterButton } from './RecordFooterButton'
import { useDetailedRecordStateContext } from '../DetailedRecordStateContext'
import { RecordingState } from '../RecordStateContext'

const stateWidths: Record<RecordingState['type'] | 'default', number> = {
  countdown: 250,
  default: 200,
  preparing: 200,
  stopping: 50,
  recording: 50,
  paused: 50,
}

export function RecordButton() {
  const { loading, state, setState, streams } = useDetailedRecordStateContext()
  const hasStreams = streams.audioDeviceId || streams.videoDeviceId || streams.hasDesktopRecording

  return (
    <motion.div animate={{ width: stateWidths[state?.type ?? 'default'] }}>
      <_RecordButton
        variant={'primary'}
        status={'danger'}
        loading={loading}
        disabled={!hasStreams}
        onPress={async () => {
          if (!state) {
            setState({ type: 'preparing' })
          } else if (state?.type === 'recording') {
            setState({ type: 'stopping' })
          } else if (state?.type === 'countdown') {
            setState(undefined)
          }
        }}
      >
        <AnimatePresence exitBeforeEnter initial={false}>
          <ButtonContent
            key={state?.type ?? 'default'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {state?.type === 'preparing' ? (
              <RecordButtonPrepareState />
            ) : state?.type === 'countdown' ? (
              <RecordButtonCountdownState />
            ) : state?.type === 'recording' ? (
              <RecordButtonRecordingState />
            ) : state?.type === 'stopping' ? (
              <RecordButtonStoppingState />
            ) : state?.type === 'paused' ? (
              <RecordButtonPausedState />
            ) : (
              <RecordButtonDefaultState />
            )}
          </ButtonContent>
        </AnimatePresence>
      </_RecordButton>
    </motion.div>
  )
}

const _RecordButton = styled(RecordFooterButton)`
  padding: 0 ${s.size('s')};
  gap: ${s.size('xs')};
  width: 100%;
`
const ButtonContent = styled(motion.div)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${s.size('xs')};
`
