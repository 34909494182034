import { DndContext } from '@dnd-kit/core'
import React from 'react'

import { PlacementDrawerDraggableOutlineItem } from './PlacementDrawerDraggableOutlineItem'
import { PlacementDrawerInnerContextProvider } from './PlacementDrawerInnerContext'
import { PlacementDrawerPlacingSegment } from './PlacementDrawerPlacingSegment'
import { DragDropOutlineContext } from '../../outline/DragDropOutlineContext'
import { OutlineItem, isItemWithSegment } from '../../outline/types'
import { useDragDropOutline } from '../../outline/useDragDropOutline'
import { BuiltSegmentFragment } from '../../schema'
import { SplitViewDraggingOverContext } from '../SplitViewDraggingOverContext'
import { SplitViewOutlineItemContext } from '../SplitViewOutlineItemContext'
import { CurrentClassPane } from '../panes/CurrentClassPane'
import { OnDragEndOpts, PlacementPaneImperativeHandle } from '../types'

export interface PlacementDrawerWithSegmentProps {
  segment: BuiltSegmentFragment
  classId: string
  onCancel: () => void
}
export function PlacementDrawerWithSegment({
  onCancel,
  segment,
  classId,
}: PlacementDrawerWithSegmentProps) {
  const outlineItems = React.useRef<Record<string, OutlineItem>>({})
  const contentRef = React.useRef<PlacementPaneImperativeHandle>(null)
  const [selectedTermId, setSelectedTermId] = React.useState<string | undefined>(undefined)
  const [draggableKey, setDraggableKey] = React.useState(0)
  const { dndContextProps, activeId, offsetLeft, overlayPortal, draggingOverList } =
    useDragDropOutline({
      activationDistance: false,
      async onDragEnd({ moving, over }) {
        const movingItem = outlineItems.current[moving.id]
        const movingSegment = isItemWithSegment(movingItem) ? movingItem.segment : undefined
        if (movingSegment) {
          const opts: OnDragEndOpts = {
            movingSegment,
            overId: over.id.toString(),
          }
          contentRef.current?.onDragEnd(opts)
        }
      },
      renderPortalItem() {
        return <span />
      },
    })
  return (
    <SplitViewDraggingOverContext.Provider value={{ draggingOverList }}>
      <PlacementDrawerInnerContextProvider updateDraggableKey={() => setDraggableKey(k => k + 1)}>
        <SplitViewOutlineItemContext.Provider
          value={React.useMemo(
            () => ({
              items: outlineItems,
            }),
            [],
          )}
        >
          <DragDropOutlineContext.Provider
            value={{
              offsetLeft,
              draggingId: activeId?.toString(),
            }}
          >
            <DndContext {...dndContextProps} autoScroll={false}>
              {overlayPortal}
              <PlacementDrawerPlacingSegment
                segment={segment}
                key={`${selectedTermId ?? 'no-term'}-${draggableKey}`}
              />
              <CurrentClassPane
                classId={classId}
                onClose={onCancel}
                draggableComponent={PlacementDrawerDraggableOutlineItem}
                ref={contentRef}
                selectedTermId={selectedTermId}
                onSelectedTermChanged={setSelectedTermId}
                forceCollapseIds={[segment.built.id]}
              />
            </DndContext>
          </DragDropOutlineContext.Provider>
        </SplitViewOutlineItemContext.Provider>
      </PlacementDrawerInnerContextProvider>
    </SplitViewDraggingOverContext.Provider>
  )
}
