import { MutationHookOptions } from '@apollo/client'

import { onMutationError } from '../onMutationError'
import {
  RestoreNodeMutation,
  RestoreNodeMutationVariables,
  useRestoreNodeMutation as useMutation,
} from '../schema'

export interface RestoreNodeMutationOpts
  extends MutationHookOptions<RestoreNodeMutation, RestoreNodeMutationVariables> {
  errorMessage?: string
}
export const useRestoreNodeMutation = ({
  errorMessage = 'There was an error restoring that.',
  ...opts
}: RestoreNodeMutationOpts) => {
  return useMutation({
    onError: onMutationError('There was an error restoring that.'),
    ...opts,
  })
}
