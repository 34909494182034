import { Tooltip } from '@thesisedu/ui'
import { Collapse, Expand } from '@thesisedu/ui/icons'
import React from 'react'

import { RecordFooterButton } from './states/record/footer/RecordFooterButton'

export interface FullscreenContextValue {
  fullscreen: boolean
  setFullscreen: (fullscreen: boolean) => void
}
export const FullscreenContext = React.createContext<FullscreenContextValue | undefined>(undefined)

export function useFullscreenContext(): FullscreenContextValue | undefined
export function useFullscreenContext(require: false): FullscreenContextValue | undefined
export function useFullscreenContext(require: true): FullscreenContextValue
export function useFullscreenContext(require?: boolean): FullscreenContextValue | undefined {
  const context = React.useContext(FullscreenContext)
  if (!context && require) {
    throw new Error('FullscreenContext is required, yet not provided.')
  }
  return context
}

export function FullscreenFooterButton() {
  const { fullscreen, setFullscreen } = useFullscreenContext(true)
  return (
    <Tooltip title={fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}>
      <RecordFooterButton
        icon={fullscreen ? <Collapse /> : <Expand />}
        onPress={() => setFullscreen(!fullscreen)}
      />
    </Tooltip>
  )
}
