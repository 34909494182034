import { FeatureResourceManager } from '@thesisedu/feature'

import { CustomOrCopy } from './CustomOrCopy'
import { Lock } from './Lock'
import { MarkComplete } from './MarkComplete'
import { Schedule } from './Schedule'
import { SegmentIndicatorResource } from '../../outline/SegmentIndicators'

const RESOURCES: SegmentIndicatorResource[] = [Schedule, Lock, MarkComplete, CustomOrCopy]
export function addSegmentIndicators(resources: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resources.addResource(resource)
  }
}
