import { useMediaPlaybackContext } from '@thesisedu/react'
import { HSpaced } from '@thesisedu/ui'
import React from 'react'

import { ConfirmFooterButton } from './ConfirmFooterButton'
import { DiscardFooterButton } from './DiscardFooterButton'
import { MediaPlayer } from '../../MediaPlayer'
import { StreamsPreview } from '../record/StreamsPreview'
import { getSelectDevice } from '../selectDevice'
import { RecordState, RecordStateProps, getStateFactory } from '../types'
import { getUpload } from '../upload'

export interface ReviewContext {
  desktop?: Blob
  stream?: Blob
  streamHasVideo?: boolean
  durationInMS: number
}
function Review({ context, onTransition }: RecordStateProps<ReviewContext>) {
  const { setDuration } = useMediaPlaybackContext()
  React.useEffect(() => {
    setDuration(context.durationInMS / 1000)
  }, [context.durationInMS])
  return (
    <div style={{ flex: 1 }}>
      <MediaPlayer
        extraFooterItems={[
          <HSpaced space={'xs'} left={'xs'} key={'actions'}>
            <DiscardFooterButton onDiscard={() => onTransition(getSelectDevice({}))} />
            <ConfirmFooterButton onUpload={() => onTransition(getUpload(context))} />
          </HSpaced>,
        ]}
      >
        {/* We cannot set autoPlay to false here, otherwise the screenshare review step will never play. */}
        <StreamsPreview stream={context.stream} autoPlay muted={false} />
      </MediaPlayer>
    </div>
  )
}

export default {
  Component: Review,
  beforeTransition({ context, skipReview }) {
    if (skipReview) {
      return getUpload(context)
    }
  },
} satisfies RecordState<ReviewContext>
export const getReview = getStateFactory<ReviewContext>('review')
