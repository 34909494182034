import { ArrowDown } from '@sammarks/icons'
import { styled } from '@thesisedu/web'
import React from 'react'

import { useClassSelector } from './ClassContext'
import { useClassSelectorModalContext } from './ClassSelectorModal'

const Wrapper = styled.span`
  opacity: 0.9;
  transition: opacity 0.1s linear;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`
const IconContainer = styled.span`
  color: inherit;
  margin-left: ${props => props.theme['@size-xs']};
  display: inline-block;
`

export interface ClassSelectorTextProps {
  classIds?: string[]
  className?: string
}
export const ClassSelectorText: React.FC<React.PropsWithChildren<ClassSelectorTextProps>> = ({
  children,
  classIds,
  className,
}) => {
  const { selectedClassId, classes: _classes, loading } = useClassSelector()
  const classes = classIds?.length ? _classes.filter(cls => classIds.includes(cls.id)) : _classes
  const { selectClass } = useClassSelectorModalContext(true)
  if (!loading && !classes.length) return null
  const selectedClass = classes.find(cls => cls.id === selectedClassId)?.name || '--'
  return (
    <Wrapper
      className={className}
      onClick={e => {
        e.preventDefault()
        selectClass()
      }}
    >
      {children || selectedClass}
      <IconContainer style={{ display: 'inline', lineHeight: 1 }}>
        <ArrowDown />
      </IconContainer>
    </Wrapper>
  )
}
