import * as React from 'react'

function SvgG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <defs>
        <radialGradient
          id={'g_svg__a'}
          cx={'50.041%'}
          cy={'50%'}
          r={'56.74%'}
          fx={'50.041%'}
          fy={'50%'}
          gradientTransform={'matrix(.84308 0 0 1 .079 0)'}
        >
          <stop offset={'21%'} stopColor={'#069796'} />
          <stop offset={'33%'} stopColor={'#069796'} />
          <stop offset={'33%'} stopColor={'#069796'} />
        </radialGradient>
      </defs>
      <g fill={'none'}>
        <path
          fill={'#069796'}
          d={
            'M255.052 194.616a64.99 64.99 0 01.848 14.662 81.191 81.191 0 01-2.714 18.46 53.536 53.536 0 01-14.97 24.677c-8.442 8.186-18.482 13.833-29.05 18.65a157.126 157.126 0 01-42.423 12.244 241.326 241.326 0 01-31.445 2.668c-2.425.05-4.84 0-7.225 0a244.502 244.502 0 01-36.086-2.179c-21.167-2.838-41.206-9.115-59.588-20.149a73.638 73.638 0 01-20.089-17.08 53.772 53.772 0 01-10.977-25.197 89.415 89.415 0 01-1.208-20.11A52.015 52.015 0 0112.55 170.39c3.672-4.408 8.073-7.996 12.464-11.644a25.466 25.466 0 004.48-4.378A7.822 7.822 0 0031.352 150a31.723 31.723 0 012.585-11.084c5.928-14.453 10.528-29.445 16.965-43.687a87.833 87.833 0 0116.566-24.887c.828-.86 1.686-1.729 2.585-2.538 2.854-2.719 5.987-5.167 8.831-7.996 4.192-4.128 9.57-6.417 15.07-8.316a96.352 96.352 0 0125.507-4.767 115.74 115.74 0 0129.6 1.909c6.696 1.35 13.282 3.168 19.21 6.606 11.456 6.637 21.516 14.853 28.86 26.077a122.715 122.715 0 0111.557 22.757c4.56 11.844 8.922 23.748 13.522 35.571a29.311 29.311 0 012.226 9.625 9.62 9.62 0 003.233 6.437c2.894 2.868 6.227 5.277 9.301 7.995 9.35 8.336 15.648 18.51 18.083 30.914z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.923 210.807a73.283 73.283 0 0017.963 32.713c7.694 7.916 17.654 13.992 28.611 15.991a43.157 43.157 0 0018.023-.63c2.316-.59 1.328-4.177-.998-3.588-21.396 5.408-41.694-8.655-52.242-26.495a72.913 72.913 0 01-7.734-18.98c-.6-2.309-4.182-1.33-3.583 1l-.04-.01zm234.149.51c-5.468 21.159-21.136 41.888-43.67 45.975-5.427.982-11 .788-16.346-.57-2.326-.589-3.314 3-.998 3.579a44.917 44.917 0 0031.345-3.908c10.429-5.277 18.961-13.993 24.95-23.987a76.473 76.473 0 008.342-20.13c.599-2.308-2.994-3.298-3.583-.999l-.04.04z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.075 52.252h25.538v10.025h-25.538z'} />
        <path
          fill={'url(#g_svg__a)'}
          d={
            'M131.116 3.128c-17.264 0-31.358 11.834-31.358 26.476 0 10.554 7.426 19.937 18.655 24.188a36.357 36.357 0 0012.746 2.269 37.16 37.16 0 009.871-1.328c12.713-3.544 21.514-13.609 21.514-25.129 0-14.658-14.135-26.476-31.428-26.476z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.116 5.268c16.187 0 29.288 10.953 29.288 24.336 0 10.494-8.108 19.767-19.945 23.067a35.02 35.02 0 01-9.302 1.25 34.213 34.213 0 01-11.994-2.133c-10.435-3.951-17.265-12.58-17.265-22.184 0-13.369 13.062-24.336 29.218-24.336z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M129.904 11.285c-5.022 0-9.303 1.761-12.808 5.271-3.503 3.494-5.263 7.768-5.263 12.783 0 4.912 1.73 9.14 5.173 12.649 3.447 3.497 7.607 5.254 12.445 5.254 4.962 0 9.075-1.823 12.294-5.45 1.426-1.625 2.442-3.374 3.046-5.248.566-1.853.868-3.941.91-6.273l.006-.58v-1.748h-15.059v6.015h8.219l-.04.215c-.382 1.795-1.358 3.362-2.944 4.719-1.857 1.574-3.936 2.356-6.26 2.356-2.744 0-5.295-1.056-7.673-3.19-2.527-2.285-3.784-5.177-3.784-8.72 0-3.438 1.13-6.275 3.399-8.547 2.273-2.318 5.068-3.47 8.426-3.47 3.608 0 6.676 1.62 9.248 4.903l.68.888 4.54-4.356-.4-.46c-2.14-2.453-4.329-4.237-6.57-5.344-2.358-1.113-4.885-1.667-7.585-1.667z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.771 165.442h.17c.08 18.44-14.27 33.452-32.164 33.741-17.963-.12-32.404-14.991-32.493-33.432h.18c0 6.657 14.21 11.994 31.824 11.994h.768c17.624-.2 31.745-5.637 31.715-12.303z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.353 125.003c0 4.416-3.574 7.996-7.984 7.996-4.409 0-7.983-3.58-7.983-7.996s3.574-7.996 7.983-7.996c4.41 0 7.984 3.58 7.984 7.996zm59.378 0c0 4.416-3.574 7.996-7.983 7.996-4.41 0-7.984-3.58-7.984-7.996s3.574-7.996 7.984-7.996c4.409 0 7.983 3.58 7.983 7.996zM99.132 94.99c3.03-.67 6.176-.577 9.161.27a.79.79 0 00.599-1.45c-6.511-3.665-14.731-1.865-19.12 4.188a.79.79 0 00.094 1.05.787.787 0 001.053.019 18.63 18.63 0 018.213-4.078zm61.603-.7a18.676 18.676 0 017.844 4.737.79.79 0 101.228-1c-3.878-6.387-11.915-8.856-18.702-5.746a.79.79 0 00.47 1.489 18.728 18.728 0 019.16.52zm-30.966 57.829a10.338 10.338 0 014.99 1.249.439.439 0 00.499-.72 8.194 8.194 0 00-10.888 0 .44.44 0 00.5.72 10.396 10.396 0 014.899-1.25z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgG = React.memo(SvgG)
export default MemoSvgG
