import { useContext, useEffect, useState } from 'react'

import { InteractionsContext } from './InteractionsContext'
import { debug } from './log'
import { InteractionArgument } from './types'

export type UseInteractionReturn = [() => void, () => Promise<void>]

export function useSingleInteraction<T extends InteractionArgument = InteractionArgument>() {
  const manager = useContext(InteractionsContext)
  return (interaction: Omit<T, 'periodEnd' | 'periodStart'>) => {
    if (manager) {
      const flushedPromise = new Promise<void>(resolve => {
        manager.once('interactionsRecorded', () => {
          resolve()
        })
      })
      manager.singleInteraction(interaction)
      if (interaction.options?.flushImmediately) {
        debug('flushImmediately was passed; flushing immediately')
        manager.flush()
      }
      return flushedPromise
    }
  }
}

export function useInteraction<T extends InteractionArgument = InteractionArgument>(
  interaction: T,
): UseInteractionReturn {
  const manager = useContext(InteractionsContext)
  const [started, setStarted] = useState(false)
  if (manager) {
    return [
      () => {
        if (!started) {
          setStarted(true)
          manager.startInteraction(interaction)
        }
      },
      async () => {
        const flushedPromise = new Promise<void>(resolve => {
          manager.once('interactionsRecorded', () => {
            resolve()
          })
        })
        manager.stopInteraction(interaction.type)
        setStarted(false)
        return flushedPromise
      },
    ]
  } else {
    return [() => false, () => Promise.resolve()]
  }
}

export const useMountInteraction = (interaction: InteractionArgument) => {
  const [start, stop] = useInteraction(interaction)
  useEffect(() => {
    start()
    return () => {
      stop()
    }
  }, [])
}
