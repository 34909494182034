import React from 'react'

import { Star } from '../../icons'
import { getColor, useTheme } from '../theme'
import { Color } from '../typography'

export interface RatingProps {
  stars: number
  hideText?: boolean
}
/** @deprecated */
export function Rating({ stars, hideText }: RatingProps) {
  const filledStars = Math.floor(stars)
  return (
    <Color color={'@orange'}>
      <RatingStar filled={filledStars >= 1} />
      <RatingStar filled={filledStars >= 2} />
      <RatingStar filled={filledStars >= 3} />
      <RatingStar filled={filledStars >= 4} />
      <RatingStar filled={filledStars >= 5} />
      {hideText ? null : (
        <strong style={{ display: 'inline-block', marginLeft: '0.5em' }}>{stars.toFixed(1)}</strong>
      )}
    </Color>
  )
}

export interface RatingStarProps {
  filled?: boolean
}
export function RatingStar({ filled }: RatingStarProps) {
  const theme = useTheme()
  return (
    <Star
      style={{
        fill: filled ? getColor(theme, '@orange') : undefined,
        color: getColor(theme, filled ? '@orange' : '@text-color-secondary'),
      }}
    />
  )
}
