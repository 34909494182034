import React from 'react'

import { SimpleList } from './SimpleList'
// @ts-ignore
import { ReactComponent as A } from '../../../../assets/sketch/branding/bell/a.svg'
// @ts-ignore
import { ReactComponent as B } from '../../../../assets/sketch/branding/bell/b.svg'
// @ts-ignore
import { ReactComponent as C } from '../../../../assets/sketch/branding/bell/c.svg'
// @ts-ignore
import { ReactComponent as C2 } from '../../../../assets/sketch/branding/bell/c2.svg'
// @ts-ignore
import { ReactComponent as D } from '../../../../assets/sketch/branding/bell/d.svg'
// @ts-ignore
import { ReactComponent as E } from '../../../../assets/sketch/branding/bell/e.svg'
// @ts-ignore
import { ReactComponent as F } from '../../../../assets/sketch/branding/bell/f.svg'
// @ts-ignore
import { ReactComponent as G } from '../../../../assets/sketch/branding/bell/g.svg'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function SimpleBellsContent(props: ModeContentsProps) {
  return <SimpleList items={[C, D, E, F, G, A, B, C2]} {...props} />
}

export const SimpleBells: Mode = {
  id: 'SimpleBells',
  name: 'Bells',
  icon: require('../../../../assets/sketch/branding/bell/c.svg').default,
  large: true,
  types: [KeyboardType.Note8],
  contents: SimpleBellsContent,
  setInstrument: 'bells',
}
