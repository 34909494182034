import { Drawer } from 'antd'

import { styled } from './styledTypes'

export const FlexDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
    > .ant-drawer-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
`
