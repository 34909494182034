import { AuthRoutesHook } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { CanvasReactFeature } from '../../CanvasReactFeature'
import { CanvasPage } from '../../web/CanvasPage'

export default function (feature: CanvasReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [...routes, <Route key={'canvas'} path={'canvas'} element={<CanvasPage />} />]
    },
  )
}
