import React from 'react'

import { styled, s, Text, Text$ } from '../..'
import { useForegroundColor } from '../../style/useForegroundColor'

export interface TagProps extends Text$.TextProps {
  icon?: React.ReactElement
  children: React.ReactElement | string
  color?: s.ColorAlias
  monoColor?: keyof s.Colors
}
export function Tag({
  icon,
  children,
  color = 'element',
  monoColor,
  style,
  level = 's',
  className,
  ...rest
}: TagProps) {
  const foregroundColor = useForegroundColor(color)
  return (
    <Container
      {...rest}
      className={className}
      style={{
        ...style,
        display: 'inline-flex',
        color: monoColor ? s.color(`${monoColor}.text`) : foregroundColor,
        backgroundColor: monoColor ? s.color(`${monoColor}.element`) : s.color(color),
        fontSize: level ? s.size(`font.${level}`) : 'inherit',
      }}
    >
      {icon ? (
        <Text as={'span'} {...rest} level={null} style={{ flexShrink: 0 }} children={icon} />
      ) : null}
      <Text as={'span'} {...rest} level={null} children={children} />
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${s.var('radii.1')};
  padding: 0.25em 0.75em;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5em;
`
