import { useResource, CustomEmojiResource, useCustomEmojis } from '@thesisedu/feature-react'
import { Markdown } from '@thesisedu/web'
import { findAndReplace } from 'mdast-util-find-and-replace'
import React from 'react'

export const ExtensibleMarkdown: typeof Markdown = props => {
  const customEmojiPlugin = useCustomEmojiRemarkPlugin()
  return (
    <Markdown
      {...props}
      plugins={[...(Array.isArray(props.plugins) ? props.plugins : []), customEmojiPlugin] as any}
      components={
        {
          ...props.components,
          'custom-emoji': CustomEmojiRenderer,
        } as any
      }
    />
  )
}

interface CustomEmojiRendererProps {
  // This will include the ::
  children: string
}
function CustomEmojiRenderer({ children }: CustomEmojiRendererProps) {
  return useCustomEmojis(children)
}

function useCustomEmojiRemarkPlugin() {
  const resources = useResource<CustomEmojiResource>('CustomEmoji')
  const matches = new Set<string>()
  for (const resource of resources) {
    for (const code of resource.codes) {
      matches.add(code)
    }
  }
  const allEmojiMatcher = new RegExp(`:(${[...matches].join('|')}):`, 'ig')
  return () => (ast: any) => {
    // @ts-ignore types might be funky on this...
    findAndReplace(ast, allEmojiMatcher, match => ({
      type: 'text',
      value: match,
      data: {
        hName: 'custom-emoji',
        hChildren: [{ type: 'text', value: match }],
      },
    }))
    return ast
  }
}
