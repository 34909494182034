import { useRecordViewContext } from '@thesisedu/feature-media-react'
import React from 'react'

import { RestartButton } from './RestartButton'
import { SheetMusic } from './SheetMusic'
import { SheetMusicPluginProvider } from './SheetMusicPluginContext'
import { SheetMusicViewerProps } from '../../viewer/SheetMusicViewer'

export interface SheetMusicPluginProps extends SheetMusicViewerProps {}
export function SheetMusicPlugin(rest: SheetMusicPluginProps) {
  const recordViewContext = useRecordViewContext(false)
  if (recordViewContext) {
    return (
      <SheetMusicPluginProvider>
        <RestartButton />
        <SheetMusic {...rest} />
      </SheetMusicPluginProvider>
    )
  } else return null
}
