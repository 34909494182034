import { AvatarProps } from 'antd/es/avatar'
import React from 'react'

import { styled } from './styledTypes'

export interface MultiAvatarProps {
  size?: number
}
export function MultiAvatar({ children, size = 32 }: React.PropsWithChildren<MultiAvatarProps>) {
  return (
    <Container style={{ width: size, height: size, padding: size / 8 }}>
      {React.Children.map(children, (child, index) => {
        if (index > 3) return null
        return React.cloneElement<AvatarProps>(child as any, {
          size: size / 3,
          key: index,
        })
      })}
    </Container>
  )
}

const Container = styled.div`
  background: ${props => props.theme['@gray-2']};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    margin: 1px;
  }
`
