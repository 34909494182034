import { onMutationError } from '@thesisedu/feature-apollo-react'

import {
  AssignmentForSegmentDocument,
  useCreateAssociatedClassAssignmentMutation as useMutation,
} from '../schema'

export const useCreateAssociatedClassAssignmentMutation: typeof useMutation = opts => {
  const [mutate, rest] = useMutation({
    onError: onMutationError('There was an error creating that assignment.'),
    ...opts,
  })

  return [
    mutateOpts =>
      mutate({
        ...mutateOpts,
        refetchQueries: [
          {
            query: AssignmentForSegmentDocument,
            variables: {
              classId: mutateOpts!.variables!.input.id,
              segmentId: mutateOpts!.variables!.input.segmentId,
            },
          },
        ],
      }),
    rest,
  ]
}
