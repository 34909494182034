export const GRADE_CELL_WIDTH = 175
export const GRADE_CELL_HEIGHT = 50
export const NAME_COLUMN_WIDTH = 175
export const AVERAGE_GRADE_COLUMN_WIDTH = 90
export const ASSIGNMENT_ROW_HEIGHT = 160
export const ASSIGNMENT_ROW_HEIGHT_NO_DECORATIONS = 75

export const GRID_ROW_HEIGHTS = [ASSIGNMENT_ROW_HEIGHT, GRADE_CELL_HEIGHT]
export const GRID_ROW_HEIGHTS_NO_DECORATIONS = [
  ASSIGNMENT_ROW_HEIGHT_NO_DECORATIONS,
  GRADE_CELL_HEIGHT,
]
export const GRID_COLUMN_WIDTHS = [NAME_COLUMN_WIDTH, AVERAGE_GRADE_COLUMN_WIDTH, GRADE_CELL_WIDTH]
export const GRID_COLUMN_WIDTHS_NO_GRADE_COLUMN = [NAME_COLUMN_WIDTH, GRADE_CELL_WIDTH]
export const STUCK_COLUMNS = 2
export const getColumnOffset = (hasGradeColumn?: boolean) =>
  hasGradeColumn ? STUCK_COLUMNS : STUCK_COLUMNS - 1
export const STUCK_ROWS = 1
export const ROW_OFFSET = STUCK_ROWS + 1

export function gridRowHeight(hasDecorations: boolean, index: number) {
  const heights = hasDecorations ? GRID_ROW_HEIGHTS : GRID_ROW_HEIGHTS_NO_DECORATIONS
  return heights[index] === undefined ? heights[heights.length - 1] : heights[index]
}
export function gridColumnWidth(hasGradeColumn: boolean, index: number) {
  const widths = hasGradeColumn ? GRID_COLUMN_WIDTHS : GRID_COLUMN_WIDTHS_NO_GRADE_COLUMN
  return widths[index] === undefined ? widths[widths.length - 1] : widths[index]
}
