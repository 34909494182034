import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { debug } from './log'
import { useViewerQuery } from './schema'

export interface ProtectionOptions {
  allowedGroups?: (string | undefined)[]
  redirect?: string
}

export const useProtection = (opts: ProtectionOptions) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { allowedGroups = [], redirect = '/403' } = opts
  const { data, loading } = useViewerQuery()
  const viewer = data?.viewer
  React.useEffect(() => {
    if (!loading) {
      if (allowedGroups.length === 0 && viewer?.group) {
        const cancel = setTimeout(() => {
          debug('expecting anonymous path, but viewer has group. redirecting to %s', redirect)
          navigate(redirect)
        }, 250)
        return () => clearInterval(cancel)
      } else if (allowedGroups.length && !allowedGroups.includes(viewer?.group || undefined)) {
        const cancel = setTimeout(() => {
          debug('viewer is not a member of groups %O, redirecting to %s', allowedGroups, redirect)
          if (viewer) {
            debug('without redirect')
            navigate(redirect)
          } else {
            debug('with redirect')
            navigate(`${redirect}?redirect=${encodeURIComponent(window.location.pathname)}`)
          }
        }, 250)
        return () => clearInterval(cancel)
      }
    }
  }, [viewer, loading, location])
}
