import { AssignmentQuestions } from '@thesisedu/feature-assignments-react/dist/questions/AssignmentQuestions'
import { CourseQuestion } from '@thesisedu/feature-course-types'
import { Form } from '@thesisedu/ui'
import React from 'react'

export interface QuestionModalProps {
  question?: CourseQuestion
  onClose: () => void
}
export function QuestionModal({ question, onClose }: QuestionModalProps) {
  const form = Form.useForm()
  return (
    <Form.Modal
      form={form}
      visible={!!question}
      onVisibleChange={v => {
        if (!v) onClose()
      }}
      title={'View Question'}
      width={900}
      style={{ maxWidth: '90vw' }}
    >
      <AssignmentQuestions
        editorId={question?.id || 'no-question'}
        questions={question ? [question] : []}
        disabled
      />
    </Form.Modal>
  )
}
