import * as React from 'react'

function SvgNoteSixteenth(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 137 260'} {...props}>
      <g fillRule={'evenodd'}>
        <path
          d={
            'M82.647 0h16.104v.517l.079 1.054.095 1.081.102 1.036.129 1.218.103.908.117.983.132 1.055.227 1.71.172 1.22.19 1.284.207 1.342.11.693.237 1.426.256 1.48.276 1.528.298 1.575c.463 2.397 1 4.944 1.622 7.6l.427 1.785c3.3 13.504 8.784 29.483 17.794 42.77l.606.881c19.659 28.245 16.35 68.98 2.984 82.854l.207-.505.278-.724.174-.475.195-.548.213-.62.229-.688c.04-.12.079-.244.12-.37l.246-.79.257-.855.131-.451.266-.95c.134-.49.27-1.003.404-1.538l.27-1.098c3.038-12.768 5.494-36.4-8.83-59.407l-.503-.81-.984-1.605a237.87 237.87 0 01-5.717-9.9l-.78-1.444c-.383-.717-.757-1.427-1.122-2.13l-.717-1.396-.694-1.376c-.34-.684-.672-1.36-.995-2.03l-.635-1.33c-.208-.441-.412-.879-.613-1.313l-.59-1.295-.287-.642-.558-1.269-.538-1.253a149.65 149.65 0 01-.516-1.236l-.497-1.22c-.162-.404-.321-.806-.477-1.205l-.459-1.189-.44-1.174-.42-1.16-.404-1.145-.387-1.13-.37-1.118-.353-1.104c-.115-.366-.227-.73-.337-1.092l-.322-1.078v.43c-1.019-2.686-1.962-5.476-2.869-8.317l-.063 173.385c.578 15.236-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1-7.612-16.644 6.771-39.552 32.125-51.168 17.75-8.132 36.173-8.574 48.238-2.396-.192-1.903-.3-3.644-.302-5.076l.102-10.092.086-14.02.063-20.798.02-20.867-.019-28.878-.178-70.58L82.647 0z'
          }
        />
        <path
          d={
            'M119.93 129.146c-20.373-29.254-23.18-72.37-23.18-72.65V56h-6.124l.055 25.059c2.165 6.995 4.195 14.164 6.678 20.708v-.43c3.302 11.258 9.076 24.557 19.715 41.644 21.064 33.835 5.84 69.019 5.84 69.019 13.367-13.874 16.675-54.61-2.984-82.854'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteSixteenth = React.memo(SvgNoteSixteenth)
export default MemoSvgNoteSixteenth
