import { HTMLProps, s, styled } from '@thesisedu/ui'
import React from 'react'

import { useMetronomeContext } from './MetronomeContext'

export interface IndicatorProps extends HTMLProps<HTMLDivElement> {}
export function Indicator(props: IndicatorProps) {
  const { indicatorRef } = useMetronomeContext(true)
  return (
    <_Indicator
      {...props}
      ref={element => {
        indicatorRef.current = element
      }}
    />
  )
}

const _Indicator = styled.div`
  border-radius: 9999px;
  width: ${s.size('xxs')};
  height: ${s.size('l')};
  display: block;
  transition: background 0.1s linear;
  background: ${s.color('secondary')};
`
