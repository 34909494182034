import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import React from 'react'

import { AuthMSALReactOptions } from './types'

export class AuthMSALReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-auth-msal-react'
  static path = __dirname
  static requires = []
  public options!: AuthMSALReactOptions

  public reactResources() {
    require('./hooks/routes').default(this)
    require('./resources/SAMLButton').default(this)
    const { AuthProviderHandler } = require('./web/AuthProviderHandler')
    const { ColorMicrosoft } = require('@sammarks/icons')
    this.resources.addResource<AuthProviderHandlerResource>({
      type: 'AuthProviderHandler',
      identifier: 'msal',
      name: 'Microsoft',
      buttonIcon: <ColorMicrosoft />,
      headerLogoPath: require('../assets/microsoft.svg').default,
      squareLogoPath: require('../assets/square.svg').default,
      render: payload => <AuthProviderHandler {...payload} />,
    })
  }
}

export const authMSALReactFeature: ReactUse<AuthMSALReactOptions> = opts => [
  AuthMSALReactFeature,
  opts,
]
