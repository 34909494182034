import { FeatureResourceManager } from '@thesisedu/feature'

import DefaultGridRenderer from './DefaultGridRenderer'
import VideoGridRenderer from './VideoGridRenderer'
import { SegmentGridRendererResource } from '../../explore/grid/types'

const RESOURCES: SegmentGridRendererResource[] = [DefaultGridRenderer, VideoGridRenderer]
export function addSegmentGridRendererResources(resourceManager: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resourceManager.addResource(resource)
  }
}
