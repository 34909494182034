import { ClassContextHook } from '@thesisedu/feature-classes-react'
import React from 'react'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'
import { GradingModalVisibilityContextProvider } from '../contexts/GradingModalVisibilityContext'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<ClassContextHook>(
    'classes-react:class-context',
    (children, { group }) => {
      if (group === 'TEACHER') {
        return <GradingModalVisibilityContextProvider children={children} />
      } else return children
    },
  )
}
