import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { useIds } from '@thesisedu/feature-react'
import { useFeature } from '@thesisedu/feature-web'
import { usePageHeadPrefix } from '@thesisedu/web'
import { message, Spin } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingContainer } from './LoadingContainer'
import UsersWebFeature from './UsersWebFeature'
import { HOOKS } from './constants'
import { useCoreViewerQuery, useViewerQuery } from './schema'
import { Viewer } from './types'

export const ViewerContext = React.createContext<Viewer | undefined>(undefined)
export const ViewerContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { data, error, loading } = useViewerQuery()
  const { coreAuthKey } = useImpersonation()
  const coreViewerQuery = useCoreViewerQuery({
    context: {
      headers: {
        Authorization: `Bearer ${coreAuthKey}`,
      },
      customBatchKey: 'session-viewer',
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !coreAuthKey,
  })
  React.useEffect(() => {
    if (coreAuthKey) {
      coreViewerQuery.refetch()
    }
  }, [coreAuthKey])
  const feature = useFeature<UsersWebFeature>(UsersWebFeature.package)
  const viewer = data?.viewer ? data.viewer : undefined
  const coreViewer = coreViewerQuery.data?.viewer ? coreViewerQuery.data.viewer : undefined

  useIds(viewer ? [{ id: viewer.id, label: 'Viewer' }] : [])
  useIds(coreViewer ? [{ id: coreViewer.id, label: 'Core Viewer' }] : [])

  // Prefix all page titles with the user we're impersonating.
  usePageHeadPrefix(
    viewer && coreViewer && coreViewer.group === 'ADMIN'
      ? `${viewer.firstName}${
          viewer.lastName && viewer.lastName.length > 0 ? ' ' + viewer.lastName[0] : ''
        }`
      : undefined,
  )

  const reportingViewer = coreViewer || viewer
  useEffect(() => {
    if (reportingViewer) {
      feature.services.error.setUserContext(reportingViewer)
    } else {
      feature.services.error.clearUserContext()
    }
  }, [reportingViewer])
  useEffect(() => {
    feature.hookManager.hook<Viewer | undefined>(feature, HOOKS.UserUpdated, reportingViewer)
  }, [reportingViewer, feature])
  if (loading || coreViewerQuery?.loading || (!data && !error)) {
    return (
      <LoadingContainer>
        <Spin size={'large'} />
      </LoadingContainer>
    )
  } else {
    return (
      <ViewerContext.Provider value={{ ...viewer, coreViewer } as Viewer} children={children} />
    )
  }
}

function useViewerContext(require: true): Viewer
function useViewerContext(require: false | undefined): Viewer | undefined
function useViewerContext(require?: boolean): Viewer | undefined {
  const navigate = useNavigate()
  const context = useContext(ViewerContext)
  if (!context?.group && require) {
    message.warn({
      key: 'SESSION-EXPIRED',
      content: 'Your session has expired, please login again.',
    })
    navigate(`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`)
    return {} as any // We are about to redirect...
  }
  return context
}

export { useViewerContext }
