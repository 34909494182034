import { Format } from '@thesisedu/feature-utils'
import { Block, BodyLarge, BodySmall, HSpaced, Reset, Space, VSpaced } from '@thesisedu/react'
import { NavArrowDown, NavArrowRight } from '@thesisedu/react/icons'
import React from 'react'

import { GeneratedAssignmentProposedSubmissionsGrid } from './GeneratedAssignmentProposedSubmissionsGrid'
import { GeneratedAssignmentSwitch } from './GeneratedAssignmentSwitch'
import { GradeAssignmentLink } from '../grading/GradeAssignmentLink'
import { BasicAssignmentFragment } from '../schema'

export interface GeneratedAssignmentsListItemProps {
  assignment: BasicAssignmentFragment
  averageGrade?: number | null
  totalSubmissions?: number | null
}
export function GeneratedAssignmentsListItem({
  assignment,
  averageGrade,
  totalSubmissions,
}: GeneratedAssignmentsListItemProps) {
  const [submissionsVisible, setSubmissionsVisible] = React.useState(false)
  return (
    <div>
      <HSpaced>
        <div>
          <BodyLarge>{assignment.name}</BodyLarge>
          <HSpaced space={'@size-xs'}>
            {averageGrade !== null && averageGrade !== undefined ? (
              <BodySmall>
                <strong>{Format.number(averageGrade, 'percentage')}</strong> average
              </BodySmall>
            ) : null}
            {totalSubmissions ? (
              <Reset.Button
                onClick={() => {
                  setSubmissionsVisible(v => !v)
                }}
              >
                <BodySmall color={'@primary-color'}>
                  {submissionsVisible ? <NavArrowDown /> : <NavArrowRight />}
                  {submissionsVisible ? 'Hide' : 'Show'} {totalSubmissions} automatically proposed
                  grade{totalSubmissions === 1 ? '' : 's'}
                </BodySmall>
              </Reset.Button>
            ) : (
              <BodySmall color={'@text-color-secondary'}>No one has completed this yet.</BodySmall>
            )}
          </HSpaced>
        </div>
        <Space />
        <VSpaced space={'@size-xxs'} align={'flex-end'}>
          <GeneratedAssignmentSwitch assignmentId={assignment.id} enabled={!assignment.excluded} />
          {assignment.excluded ? (
            <BodySmall color={'@text-color-secondary'}>View in gradebook</BodySmall>
          ) : (
            <GradeAssignmentLink assignment={{ id: assignment.id }}>
              <BodySmall>View in gradebook</BodySmall>
            </GradeAssignmentLink>
          )}
        </VSpaced>
      </HSpaced>
      {totalSubmissions && submissionsVisible ? (
        <Block marginTop={'@size-xs'}>
          <GeneratedAssignmentProposedSubmissionsGrid assignmentId={assignment.id} />
        </Block>
      ) : null}
    </div>
  )
}
