import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { useStatePropFallback } from '@thesisedu/feature-react'
import { FontWeight, Markdown, styled, StyledComponent } from '@thesisedu/web'
import { Popover } from 'antd'
import React from 'react'

export const AnswerIconContainer: StyledComponent<'div'> = styled.div`
  position: absolute;
  font-size: ${props => props.theme['@size-m']};
  height: ${props => props.theme['@size-m']};
  line-height: 1;
  top: calc(${props => props.theme['@size-m']} / -3);
  right: calc(${props => props.theme['@size-m']} / -3);
  background: white;
  border-radius: 50%;
  z-index: 3;
`
export const AnswerWrapper: StyledComponent<'div'> = styled.div`
  position: relative;
  &.correct {
    box-shadow: 0 0 0 3px ${props => props.theme['@green']};
    &:not(:last-child) {
      margin-bottom: ${props => props.theme['@size-s']};
    }
    ${AnswerIconContainer} {
      color: ${props => props.theme['@green']};
    }
  }
  &.incorrect {
    box-shadow: 0 0 0 3px ${props => props.theme['@red']};
    &:not(:last-child) {
      margin-bottom: ${props => props.theme['@size-s']};
    }
    ${AnswerIconContainer} {
      color: ${props => props.theme['@red']};
    }
  }
`

export interface AnswerTooltipContentProps {
  children: React.ReactElement | React.ReactElement[] | string
  isCorrect?: boolean
  className?: string
}
export function AnswerTooltipContent({
  children,
  isCorrect,
  className,
}: AnswerTooltipContentProps) {
  const Icon = isCorrect ? CheckCircleFilled : CloseCircleFilled
  return (
    <ContentContainer className={className}>
      <TooltipTitle isCorrect={isCorrect}>
        <Icon />
      </TooltipTitle>
      <TooltipContent>
        {typeof children === 'string' ? <Markdown>{children}</Markdown> : children}
      </TooltipContent>
    </ContentContainer>
  )
}

export interface AnswerWrapperWithTooltipProps {
  tooltip?: string | React.ReactElement
  isCorrect?: boolean
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
  className?: string
}
export function AnswerWrapperWithTooltip({
  tooltip,
  isCorrect,
  children,
  visible: _visible,
  onVisibleChange,
  className,
}: React.PropsWithChildren<AnswerWrapperWithTooltipProps>) {
  const [visible, setVisible] = useStatePropFallback(_visible, onVisibleChange, true)
  const content = (
    <AnswerWrapper className={[isCorrect ? 'correct' : 'incorrect', className].join(' ')}>
      {children}
      <AnswerIconContainer>
        {isCorrect ? <CheckCircleFilled /> : <CloseCircleFilled />}
      </AnswerIconContainer>
    </AnswerWrapper>
  )
  return tooltip ? (
    <Popover
      trigger={['hover']}
      placement={'right'}
      visible={visible}
      onVisibleChange={setVisible}
      content={<AnswerTooltipContent children={tooltip} isCorrect={isCorrect} />}
      children={content}
    />
  ) : (
    content
  )
}

const ContentContainer = styled.div`
  margin: -16px -18px;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix.
  width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const TooltipTitle = styled.div<{ isCorrect?: boolean }>`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  background: ${props => props.theme[props.isCorrect ? '@green' : '@red']};
  color: ${props => props.theme['@white']};
  font-size: ${props => props.theme['@font-size-lg']};
  font-weight: ${FontWeight.SemiBold};
`
const TooltipContent = styled.div`
  padding: ${props => props.theme['@size-s']};
  flex: 1;
  overflow-y: auto;
`
