import { fromGlobalId } from '@thesisedu/feature-utils'
import { useLocation } from '@thesisedu/react'
import React from 'react'

import { IDEntry, useIDsContext } from './IDsContext'

export function useIds(entries: IDEntry[]) {
  const oldEntries = React.useRef<IDEntry[]>([])
  const { registerID, removeID } = useIDsContext(false) || {}
  React.useEffect(() => {
    if (registerID && removeID) {
      const addedEntries = entries.filter(
        entry =>
          !oldEntries.current.some(
            oldEntry => oldEntry.id === entry.id && oldEntry.label === entry.label,
          ),
      )
      const removedEntries = oldEntries.current.filter(
        oldEntry =>
          !entries.some(entry => oldEntry.id === entry.id && oldEntry.label === entry.label),
      )
      for (const added of addedEntries) {
        registerID(added)
      }
      for (const removed of removedEntries) {
        removeID(removed.id, removed.label)
      }
      oldEntries.current = entries
    }
  }, [entries.map(entry => `${entry.id}${entry.label}`).join('')])
  React.useEffect(() => {
    return () => {
      if (registerID && removeID) {
        for (const entry of oldEntries.current) {
          removeID(entry.id, entry.label)
        }
      }
    }
  }, [])
}

export function LocationIDReporter() {
  const location = useLocation()
  const [segmentIds, setSegmentIds] = React.useState<string[]>([])
  React.useEffect(() => {
    // We purposefully introduce a bit of a delay. Otherwise it doesn't work on the
    // first page load.
    setSegmentIds(
      location.pathname.split('/').filter(segment => {
        return !!fromGlobalId(segment)?.id
      }),
    )
  }, [location])
  useIds(segmentIds.map(segment => ({ id: segment, label: 'Path Segment' })))
  return null
}
