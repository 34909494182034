import { FeatureResource } from '@thesisedu/feature'

import { AssignmentConfiguration, OptionsConfig, Question } from './assignments'
import { MatchOption } from './match'

export type AssignmentGrader = (
  submittedAnswer: string | string[] | any,
  question: Question,
  assignmentConfiguration: AssignmentConfiguration,
  assignmentTemplateId: string | undefined,
) => number
export interface AssignmentGraders {
  [questionType: string]: AssignmentGrader
}

export type AssignmentGraderCanGrade = (
  submittedAnswer: string | string[] | any | undefined,
  question: Question,
  assignmentTemplateId: string | number | undefined,
) => boolean

/** Defines a new assignment grader, where the identifier is the question type. */
export interface AssignmentGraderResource extends FeatureResource {
  type: 'AssignmentGrader'
  grader: AssignmentGrader
  canGradeQuestion?: AssignmentGraderCanGrade
}

interface AssignmentOptions {
  [questionType: string]: (question: Question) => string[]
}

const firstOrSelf = (val: string | string[]): string => {
  if (Array.isArray(val)) {
    return val[0]
  } else {
    return val as string
  }
}

const enforceArray = (val: string | string[]): string[] => {
  if (Array.isArray(val)) {
    return val as string[]
  } else {
    return [val as string]
  }
}

const defaultAssignmentOptions = (question: Question<OptionsConfig>) =>
  (question.config?.options || []).map(opt => opt.name)
export const ASSIGNMENT_OPTIONS: AssignmentOptions = {
  MultipleChoice: defaultAssignmentOptions,
  CheckboxSelect: defaultAssignmentOptions,
}

export const ASSIGNMENT_GRADERS: AssignmentGraders = {
  MultipleChoice: (submissionData, question: Question<OptionsConfig>) => {
    const correctAnswers = question
      .config!.options!.filter(option => option.correct)
      .map(option => option.name.trim().toLowerCase())
    return correctAnswers.includes(
      firstOrSelf(submissionData ?? [])
        ?.toString()
        .trim()
        .toLowerCase(),
    )
      ? 1
      : 0
  },
  CheckboxSelect: (submissionData, question: Question<OptionsConfig>) => {
    const correctAnswers = question
      .config!.options!.filter(option => option.correct)
      .map(option => option.name.trim().toLowerCase())
    const submissionAnswers = enforceArray(submissionData)
      .filter(Boolean)
      .map(answer => answer.trim().toLowerCase())
      .filter(Boolean)
    return correctAnswers.every(answer => submissionAnswers.includes(answer)) &&
      correctAnswers.length === submissionAnswers.length
      ? 1
      : 0
  },
  Match: (submissionData: MatchOption[], question) => {
    const config = question.config as OptionsConfig<MatchOption[]> | undefined
    const options = (config?.options || config?.answer || []) as MatchOption[]
    const correctMatches = submissionData.filter(answer => {
      return options.some(
        opt => opt.destination === answer.destination && opt.source === answer.source,
      )
    })
    return options.length ? correctMatches.length / options.length : 0
  },
  Group: (submissionData: MatchOption[], question) => {
    const config = question.config as OptionsConfig<MatchOption[]> | undefined
    const options = (config?.options || config?.answer || []) as MatchOption[]
    const correctMatches = submissionData.filter(answer => {
      return options.some(
        opt => opt.destination === answer.destination && opt.source === answer.source,
      )
    })
    return options.length ? correctMatches.length / options.length : 0
  },
}

export enum MediaType {
  Video = 'VIDEO',
  Audio = 'AUDIO',
}
export interface AvailableTools {
  metronome?: boolean
  pitch?: boolean
}
export interface PerformanceConfig {
  vodId?: string
  disableVod?: boolean
  maxLengthSeconds?: number
  mediaType?: MediaType[]
  tools?: AvailableTools
}
