import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App'

// This is a fix for our react-router-dom fork to get it building.
declare global {
  interface ImportMeta {
    env: any
  }
}

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(<App />)
