import { SummaryCells } from './summary'
import { ReportDimensionResult } from '../../execute/extensibleTypes'
import { useReportResources } from '../../execute/input'
import { RunReportQuery } from '../../execute/types'
import { GetExportHeaderFn } from '../../types'

export function getDimensionResults(data: RunReportQuery['runReport']): ReportDimensionResult[] {
  if (data.dimensionSummaries.length) {
    return data.dimensionSummaries.map(summary => summary.dimensions)
  } else {
    return data.results?.map(r => r.dimensions) || []
  }
}

export interface DimensionResultFromSummary {
  depth: number
  id: string
  summaryItem: any
}
export function getDimensionResultsFromSummary(
  summary: SummaryCells,
): DimensionResultFromSummary[] {
  return summary.flatRows.map(flatRow => ({
    depth: flatRow.depth,
    summaryItem: flatRow.item,
    id: flatRow.id,
  }))
}

export interface DimensionGetHeaderCallbacks {
  [dimensionIdentifier: string]: {
    resource?: GetExportHeaderFn<any>
    summary?: GetExportHeaderFn<any>
  }
}
export function useDimensionGetHeaderCallbacks(): DimensionGetHeaderCallbacks {
  const { dimensions } = useReportResources()
  return dimensions.reduce(
    (acc, dimension) => ({
      ...acc,
      [dimension.identifier]: {
        resource: dimension.useGetExportHeader ? dimension.useGetExportHeader() : undefined,
        summary: dimension.summary?.useGetExportHeader
          ? dimension.summary.useGetExportHeader()
          : undefined,
      },
    }),
    {},
  )
}
