import { MultiStudentSelect, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Modal, Button } from '@thesisedu/ui'
import React from 'react'

import { useCourseContext } from './CourseContext'
import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { overrideSegment } from '../helpers'

export interface EnableForStudentsContextValue {
  enableForStudents: (segmentId: string, enable?: boolean) => void
}
export const EnableForStudentsContext = React.createContext<
  EnableForStudentsContextValue | undefined
>(undefined)

export function EnableForStudentsContextProvider({ children }: React.PropsWithChildren<object>) {
  const [segmentId, setSegmentId] = React.useState<string | null>(null)
  const [selectedStudentIds, setSelectedStudentIds] = React.useState<string[] | undefined>(
    undefined,
  )
  const selectedClassId = useSelectedClassId()
  const [updateClass, { loading }] = useUpdateClassMutation(selectedClassId || '', {
    onCompleted: () => {
      setSegmentId(null)
    },
  })
  const { segmentMetadata } = useCourseContext(true)
  React.useEffect(() => {
    const segment = segmentMetadata?.segments?.find(s => s.id === segmentId)
    setSelectedStudentIds(segment?.enabledStudentIds)
  }, [segmentId])
  const value = React.useMemo<EnableForStudentsContextValue>(
    () => ({
      enableForStudents: segmentId => setSegmentId(segmentId),
    }),
    [],
  )
  return (
    <>
      <Modal
        title={'Enable for Students'}
        visible={!!segmentId}
        onVisibleChange={v => {
          if (!v) setSegmentId(null)
        }}
      >
        <MultiStudentSelect
          classId={selectedClassId || ''}
          value={selectedStudentIds}
          onChange={setSelectedStudentIds}
        />
        <Modal.Footer>
          <Modal.CloseButton>Cancel</Modal.CloseButton>
          <Button
            variant={'primary'}
            loading={loading}
            onPress={() => {
              if (segmentId) {
                return updateClass({
                  segmentMetadata: overrideSegment(
                    segmentId,
                    { visibleOverride: false, enabledStudentIds: selectedStudentIds || [] },
                    segmentMetadata,
                  ),
                })
              }
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <EnableForStudentsContext.Provider value={value} children={children} />
    </>
  )
}

export function useEnableForStudentsContext(): EnableForStudentsContextValue | undefined
export function useEnableForStudentsContext(
  require: false,
): EnableForStudentsContextValue | undefined
export function useEnableForStudentsContext(require: true): EnableForStudentsContextValue
export function useEnableForStudentsContext(
  require?: boolean,
): EnableForStudentsContextValue | undefined {
  const context = React.useContext(EnableForStudentsContext)
  if (!context && require) {
    throw new Error('EnableForStudentsContext is required, yet not provided.')
  }
  return context
}
