import { Button, Button$ } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { InviteStudentModal } from './invite/InviteStudentModal'
import { useTeacherClass } from '../useClass'

export interface AddStudentsButtonProps extends Partial<Button$.ButtonProps> {
  classId: string
}
export function AddStudentsButton({ classId, ...props }: AddStudentsButtonProps) {
  const { cls } = useTeacherClass(classId, false)
  const [visible, setVisible] = React.useState(false)
  if (cls?.canInvite) {
    return (
      <>
        <InviteStudentModal
          onClose={() => setVisible(false)}
          classId={visible ? cls.id : undefined}
        />
        <Button
          variant={'primary'}
          icon={<Plus />}
          children={'Add Students'}
          {...props}
          onPress={() => setVisible(true)}
        />
      </>
    )
  } else {
    return null
  }
}
