import { getSegmentName } from '@thesisedu/feature-course-types'
import { Select } from '@thesisedu/ui'
import React from 'react'

import { CourseConfiguration } from '../types'

export interface TermSelectProps {
  value?: string
  onChange?: (value: string) => void
  configuration: CourseConfiguration | undefined
}
export function TermSelect({ value, onChange, configuration }: TermSelectProps) {
  return (
    <Select aria-label={'Term'} value={value} onValueChange={onChange} style={{ width: 250 }}>
      {configuration?.segments.map(segment => (
        <Select.Item key={segment.id} value={segment.id}>
          {getSegmentName(segment)}
        </Select.Item>
      ))}
    </Select>
  )
}
