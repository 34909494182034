import { TourEditorButton } from '@thesisedu/react-tours'
import { VSpaced, Form } from '@thesisedu/ui'
import React from 'react'

export function TourSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <Form.TextField name={'name'} label={'Name'} required />
      <Form.Field name={'tour'} label={'Tour'} required>
        <TourEditorButton />
      </Form.Field>
      <Form.Checkbox
        name={'allowRepeats'}
        label={'Allow students to complete the tour multiple times.'}
      />
      <Form.TextField
        name={'startPage'}
        placeholder={'/simulation'}
        description={'Relative to the current class.'}
      />
    </VSpaced>
  )
}
