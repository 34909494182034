import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import { useFlag } from '@thesisedu/react'
import { styled, VSpaced, PageHeader, HSpaced, Space } from '@thesisedu/web'
import { Button, Tooltip } from 'antd'
import React from 'react'
import ReactDOM from 'react-dom'
import { useParams } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import { ClassGradesTable, ClassGradesTableProps } from './ClassGradesTable'
import { CreateAssignmentButton } from './CreateAssignmentButton'
import { GradingModalVisibilityContextProvider } from './contexts/GradingModalVisibilityContext'
import { ExportGradesButton } from './grading/ExportGradesButton'
import { OrderDirection } from './schema'
import { AssignmentFilters, Filters } from './table/AssignmentFilters'
import { AssignmentTableSettingsButton } from './table/AssignmentTableSettings'

export interface ClassGradesRouteProps {
  tableProps?: Partial<ClassGradesTableProps>
  createButton?: React.ReactElement
  hideHeader?: boolean
  hideFullscreen?: boolean
}

type SortFilters = Pick<Filters, 'orderBy' | 'orderDirection'>

export function ClassGradesRoute({
  createButton,
  tableProps,
  hideHeader,
  hideFullscreen,
}: ClassGradesRouteProps) {
  const { classId } = useParams()

  const [sortFilters, setSortFilters] = useFlag<SortFilters>(
    `${classId || 'noclass'}-sort-filters`,
    {
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Asc,
    },
  )

  const [_filters, setFilters] = React.useState<Filters | undefined>()
  const filters = {
    ..._filters,
    orderBy: sortFilters.orderBy,
    orderDirection: sortFilters.orderDirection,
  }

  const [name, setName] = React.useState<string | undefined>()
  const classIdsWithFilter = classId ? [classId] : filters?.classIds || []
  const classIdWithFilter = classId
    ? classId
    : filters?.classIds?.length === 1
    ? filters.classIds[0]
    : undefined

  // Fixed-mode helpers and portal setup.
  const contentContainer = React.useRef<HTMLDivElement>(null)
  const outerContainer = React.useRef<HTMLDivElement>(null)
  const [isFixed, setIsFixed] = React.useState(false)
  const fixedRef = React.useRef<HTMLDivElement>(document.createElement('div'))
  React.useEffect(() => {
    if (contentContainer.current && outerContainer.current) {
      // Get the position of the content container on the page.
      // @ts-ignore ResizeObserver is not typed.
      const ro = new ResizeObserver(() => {
        if (contentContainer.current && outerContainer.current) {
          const bounding = contentContainer.current.getBoundingClientRect()

          outerContainer.current.style.left = `${bounding.left + window.scrollX}px`
          outerContainer.current.style.top = `${bounding.top + window.scrollY}px`
          outerContainer.current.style.width = `${bounding.width}px`
        }
      })
      // Sync the height of the grade content with the placeholder on the page.
      // @ts-ignore ResizeObserver is not typed.
      const outerRo = new ResizeObserver(() => {
        if (outerContainer.current && contentContainer.current) {
          const outerBounding = outerContainer.current.getBoundingClientRect()
          contentContainer.current.style.height = `${outerBounding.height}px`
        }
      })

      // Attach the fixed container to the body.
      fixedRef.current!.className = 'feature-assignments-react-grade-container'
      document.body.appendChild(fixedRef.current)
      ro.observe(contentContainer.current)
      outerRo.observe(outerContainer.current)
      return () => {
        ro.disconnect()
        outerRo.disconnect()
        document.body.removeChild(fixedRef.current)
      }
    }
  }, [])

  const gradeContent = (
    <>
      {isFixed || hideHeader ? null : <PageHeader title={'Grades'} />}
      <VSpacedContainer space={'@size-m'}>
        {isFixed ? <FixedGlobalStyles /> : null}
        <HSpaced style={{ alignSelf: 'stretch' }} align={'flex-start'}>
          <div style={{ overflowX: 'auto', flex: 1 }}>
            <AssignmentFilters
              classId={classId}
              onChange={(filters, name) => {
                setFilters(filters)
                setName(name)

                if (filters.orderBy && filters.orderDirection) {
                  setSortFilters({
                    orderBy: filters.orderBy,
                    orderDirection: filters.orderDirection,
                  })
                }
              }}
              filters={filters}
              name={name}
            />
          </div>
          <Space />
          {hideFullscreen ? null : (
            <Tooltip
              title={isFixed ? 'Exit Fullscreen' : 'Fullscreen'}
              placement={'bottom'}
              mouseEnterDelay={2}
            >
              <Button
                icon={isFixed ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                onClick={() => setIsFixed(i => !i)}
              />
            </Tooltip>
          )}
          <AssignmentTableSettingsButton />
          <ExportGradesButton filters={{ classIds: classIdsWithFilter }} />
          {createButton ? createButton : <CreateAssignmentButton classId={classIdWithFilter} />}
        </HSpaced>
        <GradingModalVisibilityContextProvider>
          <ClassGradesTable
            filters={{
              ...filters,
              classIds: classIdsWithFilter,
            }}
            name={name}
            {...tableProps}
          />
        </GradingModalVisibilityContextProvider>
      </VSpacedContainer>
    </>
  )

  const content = (
    <OuterContainer ref={outerContainer} className={isFixed ? 'fullscreen' : ''}>
      {gradeContent}
    </OuterContainer>
  )

  const portal = ReactDOM.createPortal(content, fixedRef.current)
  if (hideFullscreen) {
    return gradeContent
  } else {
    return (
      <>
        <DefaultGlobalStyles />
        {portal}
        <ContentContainerPlaceholder ref={contentContainer} />
      </>
    )
  }
}

const VSpacedContainer = styled(VSpaced)`
  flex: 1;
  > div:last-child {
    flex: 1;
  }
`
const OuterContainer = styled.div`
  position: absolute;
  &.fullscreen {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 100% !important;
    background: ${props => props.theme['@layout-body-background']};
    z-index: 10;
    padding: ${props => props.theme['@size-m']};
    .grade-grid-container {
      height: calc(100vh - 100px);
    }
  }
`
const ContentContainerPlaceholder = styled.div`
  width: 100%;
`
const FixedGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden !important;
  }
  .intercom-lightweight-app, #fc_frame {
    display: none !important;
  }
`
const DefaultGlobalStyles = createGlobalStyle`
  .feature-assignments-react-grade-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 10;
    > div {
      pointer-events: all;
      &.fullscreen {
        background: white;
      }
    }
  }
`
