import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'

import { $isWidgetNode, WidgetNode } from './WidgetNode'

export interface WidgetNumberConfig extends BaseWidgetConfig {
  startingNumber?: number | string
}
export function getWidgetNumber<Config extends WidgetNumberConfig>(
  config: Config,
  node: WidgetNode<any, Config>,
): number {
  const parsedNumber = parseFloat(config.startingNumber?.toString() || 'none')
  if (!isNaN(parsedNumber)) return parsedNumber

  // Get the first prior sibling with a valid number.
  const identifier = node.getType()
  const previousSibling = node.getPreviousSibling()
  if ($isWidgetNode(previousSibling, identifier)) {
    return getWidgetNumber(previousSibling.getConfig(), previousSibling) + 1
  } else {
    return 1
  }
}
