import { useResource } from '@thesisedu/feature-react'
import { ArrowUp } from '@thesisedu/react/icons'
import { AntIconWrapper, Body } from '@thesisedu/web'
import React from 'react'

import { Report, ReportProps } from './Report'
import { dimensionsSupportMultipleMetrics } from '../execute/input'
import { ReportDimensionResource, RunReportOpts } from '../types'

function normalizeReportOpts(opts: RunReportOpts): RunReportOpts {
  return {
    dimensions: opts.dimensions.filter(Boolean),
    metrics: opts.metrics.filter(Boolean),
    filters: opts.filters?.filter(Boolean),
  }
}

export function ReportValidation(props: ReportProps) {
  const report = normalizeReportOpts(props.report)
  const dimensionResources = useResource<ReportDimensionResource<object, object>>('ReportDimension')
  const showSelectMetric =
    report.dimensions.length > 1 &&
    !dimensionsSupportMultipleMetrics(dimensionResources, report.dimensions)
  if (!report.metrics.length && showSelectMetric) {
    return <Body color={'@text-color-secondary'}>Select a metric from the options above.</Body>
  } else if (!report.dimensions.length) {
    return (
      <Body color={'@text-color-secondary'}>
        <AntIconWrapper children={<ArrowUp />} /> Select a dimension!
      </Body>
    )
  } else {
    return <Report {...props} />
  }
}
