import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { useUpdateClassMutation } from '@thesisedu/feature-classes-react/dist/queries/useUpdateClassMutation'
import { Block, Body, BodySmall, H3Alternate, LargeBlock, styled } from '@thesisedu/web'
import React from 'react'
import Markdown from 'react-markdown'
import { Link } from 'react-router-dom'

import { CourseList } from '../CourseList'
import { useClassCourseQuery } from '../schema'

const CourseHeader = styled.div<{ color: string }>`
  background: ${props => props.theme[`@${props.color}`]};
  border-top-left-radius: ${props => props.theme['@border-radius-large']};
  border-top-right-radius: ${props => props.theme['@border-radius-large']};
  padding: ${props => props.theme['@padding-lg']};
  color: white;
  display: flex;
  align-items: center;
  img {
    width: 100px;
    max-width: 30%;
  }
  > div:last-child {
    margin-left: ${props => props.theme['@size-s']};
  }
  a,
  h3 {
    color: white !important;
  }
`
const Content = styled.div`
  padding: ${props => props.theme['@padding-lg']};
`

export function CourseOverview() {
  const cls = useSelectedClass(true)
  const { data, loading } = useClassCourseQuery({
    variables: { id: cls.id },
  })
  const [update, { loading: updateLoading }] = useUpdateClassMutation()

  const version = data?.node?.__typename === 'Class' ? data.node.courseVersion : undefined
  let content = (
    <Body color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
      Loading course information...
    </Body>
  )

  if (version) {
    const { filename } = getFileInfo(version.course.basicConfiguration.icon)
    content = (
      <>
        <CourseHeader color={version.course.basicConfiguration.color || 'blue'}>
          <img src={filename} alt={version.course.label || version.course.name} />
          <div>
            <Block marginBottom={'@size-s'}>
              <H3Alternate isBlock={false}>
                {version.course.label || version.course.name}
              </H3Alternate>
              <BodySmall style={{ opacity: 0.75 }}>Version: {version.name}</BodySmall>
            </Block>
            <Body>
              <Link to={'../course/outline'}>View Course &rarr;</Link>
              <br />
              <Link to={'../course/edit'}>Course Structure &rarr;</Link>
            </Body>
          </div>
        </CourseHeader>
        <Content>
          <Markdown children={version.course.basicConfiguration.longDescription} skipHtml />
        </Content>
      </>
    )
  } else if (!loading && !updateLoading) {
    content = (
      <Content>
        <Block marginBottom={'@size-s'}>
          <Body color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
            <ExclamationCircleOutlined /> You haven't selected a course for this class.
          </Body>
        </Block>
        <CourseList
          onCourseSelected={course => {
            update({
              variables: {
                input: {
                  id: cls.id,
                  patch: {
                    courseId: course.id,
                  } as any,
                },
              },
            })
          }}
        />
      </Content>
    )
  }

  return <LargeBlock style={{ padding: 0 }}>{content}</LargeBlock>
}
