import { GradeTreeRecordSummary, GradeColumnGrade } from '@thesisedu/feature-assignments-react'
import { Segment } from '@thesisedu/feature-courses-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { styled } from '@thesisedu/web'
import React from 'react'

import { useCourseAssignmentsContext } from '../contexts/CourseAssignmentsContext'

export interface SegmentRightContentProps {
  segment: Segment
}
export function SegmentRightContent({ segment }: SegmentRightContentProps) {
  const viewer = useViewerContext(false)
  const { segmentGradeRecords } = useCourseAssignmentsContext(false) || {}
  if (viewer?.group === 'STUDENT') {
    const record = segmentGradeRecords?.[segment.id]
    return record ? (
      <Container>
        <span className={'addendum'}>
          <GradeTreeRecordSummary record={record} forceRelativeDates />
        </span>
        <GradeColumnGrade record={record} />
      </Container>
    ) : null
  } else {
    return null
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  .addendum {
    margin-right: ${props => props.theme['@size-s']};
    * {
      color: ${props => props.theme['@text-color-secondary']} !important;
    }
  }
  .grade {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row-reverse;
    width: 175px;
    > :not(:last-child) {
      margin-left: ${props => props.theme['@size-xs']};
    }
  }
  .grade-inner {
    background: ${props => props.theme['@background-color-base']};
    color: ${props => props.theme['@white']} !important;
    font-weight: bold;
    font-size: ${props => props.theme['@font-size-lg']};
    padding: ${props => props.theme['@size-xxs']} 0;
    width: 80px;
    text-align: center;
    border-radius: ${props => props.theme['@border-radius-base']};
    > *:not(:last-child) {
      margin-right: ${props => props.theme['@size-xs']};
    }
    &.grade-color-green {
      background: ${props => props.theme['@green']};
    }
    &.grade-color-red {
      background: ${props => props.theme['@red']};
    }
    &.grade-color-blue {
      background: ${props => props.theme['@blue']};
    }
    &.grade-color-orange {
      background: ${props => props.theme['@orange']};
    }
  }
`
