import { CheckOutlined, DeleteOutlined } from '@ant-design/icons'
import { styled } from '@thesisedu/web'
import { Button, Popconfirm } from 'antd'
import React from 'react'
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd'

import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { DropArea } from './styled'
import { DropArea as TDropArea } from './types'

export interface DropAreaEditPairing {
  isSelected?: boolean
  onSelected: (selected: boolean) => void
}
export interface DropAreaEditProps {
  dropArea: TDropArea
  onChange: (dropArea: TDropArea) => void
  onDelete: () => void
  onClick?: () => void
  pairing?: DropAreaEditPairing
}
export function DropAreaEdit({
  dropArea,
  onChange,
  onClick,
  onDelete,
  pairing,
}: DropAreaEditProps) {
  const { width = 0, height = 0 } = useGraphicDragDropContext(true)
  return (
    <DropArea
      as={Rnd}
      onClick={onClick}
      size={{ width: dropArea.width * width, height: dropArea.height * height }}
      position={{ x: dropArea.x * width, y: dropArea.y * height }}
      onDragStop={
        ((event, d) => {
          onChange({
            ...dropArea,
            x: d.x / width,
            y: d.y / height,
          })
        }) as RndDragCallback
      }
      onResizeStop={
        ((event, direction, ref, delta, position) => {
          onChange({
            ...dropArea,
            width: parseFloat(ref.style.width) / width,
            height: parseFloat(ref.style.height) / height,
            x: position.x / width,
            y: position.y / height,
          })
        }) as RndResizeCallback
      }
    >
      <ActionsContainer>
        {pairing ? (
          <Button
            type={pairing.isSelected ? 'primary' : 'default'}
            icon={<CheckOutlined />}
            onClick={() => {
              pairing.onSelected(!pairing.isSelected)
            }}
          />
        ) : (
          <Popconfirm
            title={'Are you sure you want to delete this drop area?'}
            okText={'Yes, delete'}
            cancelText={'No, keep'}
            okButtonProps={{ danger: true }}
            onConfirm={() => {
              onDelete()
            }}
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        )}
      </ActionsContainer>
    </DropArea>
  )
}

const ActionsContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-xs']};
  right: ${props => props.theme['@size-xs']};
`
