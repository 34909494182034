import React from 'react'

import { CheckCircle, DeleteCircle, InfoEmpty, WarningCircle } from '../../icons'
import { ColorAlias } from '../style/variables'

export type Status = 'info' | 'warning' | 'error' | 'success'
export const StatusColors: {
  [key in Status]: ColorAlias
} = {
  info: 'blue',
  warning: 'orange',
  error: 'red',
  success: 'green',
}
export const StatusIcons: {
  [key in Status]: React.ReactElement
} = {
  info: <InfoEmpty />,
  warning: <WarningCircle />,
  error: <DeleteCircle />,
  success: <CheckCircle />,
}
