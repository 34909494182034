import {
  addTeacherPermission,
  ClassTeacherPermissionResource,
} from '@thesisedu/feature-classes-react'

import { CoursesReactFeature } from '../../CoursesReactFeature'

const resources: Omit<ClassTeacherPermissionResource, 'type' | 'group' | 'identifier'>[] = [
  {
    label: 'Enable and disable content',
    permissions: ['UPDATE_CLASS', 'MANAGE_CLASS_SEGMENT_ENABLED'],
  },
  {
    label: 'Lock and unlock content',
    permissions: ['UPDATE_CLASS', 'MANAGE_CLASS_SEGMENT_LOCKED'],
  },
  {
    label: 'Change course structure',
    permissions: ['UPDATE_CLASS', 'MANAGE_CLASS_SEGMENT_STRUCTURE'],
  },
  {
    label: 'Copy content from this class',
    permissions: ['COPY_SEGMENT_SETTINGS', 'COPY_COURSE_CONTENT_FROM'],
  },
  {
    label: 'Copy content to this class',
    permissions: ['COPY_SEGMENT_SETTINGS', 'COPY_COURSE_CONTENT_TO'],
  },
]

export default function (feature: CoursesReactFeature) {
  for (const resource of resources) {
    addTeacherPermission(feature, 'Courses', resource.label, resource.permissions)
  }
  addTeacherPermission(feature, 'Classes', 'Sync content to LMS', [
    'SYNC_SEGMENT',
    'FORCE_RESYNC_MATERIAL_SYNC',
  ])
}
