import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { $isFloatNode, FloatAlignment, FloatNode, normalizeFloatWidth } from '../../nodes/FloatNode'
import { setResizeLine } from '../../utils/resizeLine'
import { useResizableElement } from '../../utils/useResizableElement'

const FLOAT_OFFSET = 32

interface ResizeFloatContext {
  minX: number
  maxX: number
  alignment: FloatAlignment
  currentWidth: number
}
export function useResizeFloatNode(anchorElement: HTMLElement) {
  const [editor] = useLexicalComposerContext()
  const { children } = useResizableElement<FloatNode, ResizeFloatContext>({
    editor,
    anchorElement,
    filterNode(node) {
      return $isFloatNode(node)
    },
    getLineAlignment(element, node) {
      const alignment = editor.getEditorState().read(() => node.getAlignment() === 'right')
        ? 'left'
        : 'right'
      return {
        alignment,
        offset: alignment === 'left' ? FLOAT_OFFSET : 0,
      }
    },
    beforeDrag({ element, node }) {
      const parent = element?.parentElement
      if (!parent) return false
      const { alignment, width } = editor.getEditorState().read(() => {
        return {
          alignment: node.getAlignment(),
          width: node.getWidth(),
        }
      })
      const { left: parentLeft, width: parentWidth } = parent.getBoundingClientRect()
      return {
        alignment,
        minX: parentLeft,
        maxX: parentLeft + parentWidth,
        currentWidth: width,
      }
    },
    onMove(e, { line, element, node, context }) {
      const { minX, maxX, alignment } = context
      let newRawWidth = (e.clientX - minX) / (maxX - minX)
      if (alignment === 'right') newRawWidth = 1 - newRawWidth
      const newWidthPercentage = normalizeFloatWidth(newRawWidth)
      if (newWidthPercentage !== context.currentWidth) {
        editor.update(() => {
          context.currentWidth = newWidthPercentage
          node.setWidth(newWidthPercentage)
        })
        setTimeout(() => {
          setResizeLine(line, element, anchorElement, {
            alignment: alignment === 'right' ? 'left' : 'right',
            offset: alignment === 'right' ? FLOAT_OFFSET : 0,
          })
        })
      }
    },
  })

  return children
}
