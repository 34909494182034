import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { usePersistedState } from '@thesisedu/feature-classes-react'
import { useFeature } from '@thesisedu/feature-react'
import { ShareFilterButton } from '@thesisedu/feature-sharing-react'
import { TagSelect } from '@thesisedu/feature-tags-react'
import { cloneDeep, set } from 'lodash'
import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { LessonsList, LessonsListProps } from '../LessonsList'
import { LessonsReactFeature } from '../LessonsReactFeature'
import {
  LessonFragment,
  TeacherLessonsDocument,
  TeacherLessonsQuery,
  TeacherLessonsQueryVariables,
  SharingType,
} from '../schema'

interface GenericChangeArgs<T extends string | SharingType[]> {
  item: T | undefined
  setState: (value: T | undefined) => void
  setLocalStorage: (value: T | undefined) => void
  name: string
}

export interface TeacherLessonListProps extends Partial<LessonsListProps> {
  noParams?: boolean
}
export const TeacherLessonsList: React.FC<React.PropsWithChildren<TeacherLessonListProps>> = ({
  noParams,
  ...props
}) => {
  const { disableSharing } = useFeature(LessonsReactFeature).options
  const [selectedTagId, setSelectedTagId] = useState<string>()
  const [sharingTypes, setSharingTypes] = useState<SharingType[] | undefined>(undefined)
  const [params, _setParams] = useSearchParams()
  const setParams: typeof _setParams = (...args) => {
    if (!noParams) {
      _setParams(...args)
    }
  }
  const tagsFilterQueryString = params.get('tag')
  const sharingTypeFilterQueryString = params.get('sharing')
  const [localTagFilter, setLocalTagFilter] = usePersistedState<string | undefined>({
    defaultValue: undefined,
    key: 'tagFilter',
  })
  const [localSharingTypeFilter, setLocalSharingTypeFilter] = usePersistedState<
    SharingType[] | undefined
  >({
    defaultValue: undefined,
    key: 'sharingTypeFilter',
  })

  const variables = {
    tagIds: selectedTagId ? [selectedTagId] : undefined,
    sharingTypes,
  }

  function handleChange<T extends string | SharingType[]>(opts: GenericChangeArgs<T>): void {
    const { item, setState, setLocalStorage, name } = opts

    if (!item) {
      setState(undefined)
      setLocalStorage(undefined)
      params.delete(name)
      setParams(params)
    } else {
      setState(item)
      setLocalStorage(item)
      setParams({
        ...params,
        [name]: JSON.stringify(item),
      })
    }
  }

  useEffect(() => {
    if (!tagsFilterQueryString && !sharingTypeFilterQueryString) {
      if (localSharingTypeFilter) {
        setSharingTypes(localSharingTypeFilter)
      }
      if (localTagFilter) {
        setSelectedTagId(localTagFilter)
      }
    } else {
      if (tagsFilterQueryString) {
        setSelectedTagId(JSON.parse(tagsFilterQueryString))
      }
      if (sharingTypeFilterQueryString) {
        setSharingTypes(JSON.parse(sharingTypeFilterQueryString) as SharingType[])
      }
    }
  }, [])

  useEffect(() => {
    if (selectedTagId) {
      setParams({
        ...params,
        tag: JSON.stringify(selectedTagId),
      })
      setLocalTagFilter(selectedTagId)
    }
    if (sharingTypes) {
      setParams({
        ...params,
        sharing: JSON.stringify(sharingTypes),
      })
      setLocalSharingTypeFilter(sharingTypes)
    }
  }, [selectedTagId, sharingTypes])

  return (
    <InfiniteQuery<LessonFragment, TeacherLessonsQuery, TeacherLessonsQueryVariables>
      document={TeacherLessonsDocument}
      getResult={d => d?.viewer?.teacher?.lessons}
      setResult={(previous, result) =>
        set<any>(cloneDeep(previous), 'viewer.teacher.lessons', result)
      }
      variables={variables}
      children={({ data, refetch, loading, loadingMore }) => (
        <LessonsList
          lessons={data?.viewer?.teacher?.lessons.edges.map(edge => edge.node) || []}
          loading={loading && !loadingMore}
          refetch={after =>
            refetch({
              ...variables,
              after,
            })
          }
          filters={
            <>
              {disableSharing ? null : (
                <span style={{ display: 'inline-block', marginRight: '0.5em' }}>
                  <ShareFilterButton
                    label={'Lessons'}
                    sharingTypes={sharingTypes}
                    onSharingTypesChanged={item =>
                      handleChange({
                        item,
                        setState: setSharingTypes,
                        setLocalStorage: setLocalSharingTypeFilter,
                        name: 'sharing',
                      })
                    }
                  />
                </span>
              )}
              <span style={{ display: 'inline-block', marginRight: '0.5em' }}>
                <TagSelect
                  value={selectedTagId}
                  onChange={item =>
                    handleChange({
                      item,
                      setState: setSelectedTagId,
                      setLocalStorage: setLocalTagFilter,
                      name: 'tag',
                    })
                  }
                  selectLabel={'Filter by Tag'}
                  selectedLabel={'Filtering by Tag:'}
                />
              </span>
            </>
          }
          {...props}
        />
      )}
    />
  )
}
