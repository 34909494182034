import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Button, message, Popconfirm } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useDeleteAnnouncementMutation } from './queries/useDeleteAnnouncementMutation'
import { AnnouncementsForClassDocument } from './schema'

export interface DeleteAnnouncementProps {
  announcementId: string
}
export function DeleteAnnouncement({ announcementId }: DeleteAnnouncementProps) {
  const navigate = useNavigate()
  const classId = useSelectedClassId(true)
  const [deleteAnnouncement, { loading }] = useDeleteAnnouncementMutation({
    variables: { id: announcementId },
    onCompleted: () => {
      message.success('Announcement deleted.')
      navigate('..')
    },
  })

  return (
    <Popconfirm
      title={'Are you sure you want to delete this announcement?'}
      okText={'Yes, delete announcement.'}
      okButtonProps={{ danger: true }}
      cancelText={'No, keep.'}
      onConfirm={() => {
        deleteAnnouncement({
          refetchQueries: [{ query: AnnouncementsForClassDocument, variables: { classId } }],
        })
      }}
    >
      <Button type={'link'} danger loading={loading}>
        Delete
      </Button>
    </Popconfirm>
  )
}
