import { AssignmentGrader } from '@thesisedu/feature-assignments-core'

export interface Placeable {
  x: number
  y: number
  width: number
  height: number
}

export interface DropArea extends Placeable {
  id: string
}

export interface Draggable extends Placeable {
  id: string
  /* Currently used as the alt tag on the image */
  title?: string
  imagePath?: string
  /* Defaults to 1 time, so no looping. */
  gifPlaybackLoopTimes?: number
  audioPath?: string
}

export interface DragDropPair {
  dropAreaId: string
  draggableId: string
}

export interface BackgroundOptions {
  path: string
  noGrayscale?: boolean
}

export interface GraphicDragDropConfig {
  background?: BackgroundOptions
  instructions?: string
  dropAreas?: DropArea[]
  draggables?: Draggable[]
  dragDropPairs?: DragDropPair[]
}

export interface GraphicDragDropValue {
  [draggableId: string]: string | undefined
}

export const GraphicDragDropGrader: AssignmentGrader = (
  submissionData: GraphicDragDropValue,
  question,
) => {
  const config = question.config as GraphicDragDropConfig | undefined
  const correctMatches = config?.dragDropPairs?.filter(ddPair => {
    return submissionData[ddPair.draggableId] === ddPair.dropAreaId
  })
  const uniqueDraggables = config?.dragDropPairs?.reduce<Set<string>>((acc, pair) => {
    acc.add(pair.draggableId)
    return acc
  }, new Set<string>())
  return uniqueDraggables?.size ? (correctMatches?.length || 0) / uniqueDraggables.size : 0
}
