import { useClassGeneratedProvidersQuery } from '../schema'

export function useClassGeneratedProviders(classId?: string) {
  const { data, loading } = useClassGeneratedProvidersQuery({
    variables: {
      id: classId || '',
    },
    skip: !classId,
  })
  const generatedProviders =
    data?.node?.__typename === 'Class' ? data.node.generatedProviders : undefined

  return classId
    ? {
        generatedProviders: generatedProviders || [],
        loading,
      }
    : { generatedProviders: [], loading: false }
}
