import { BodyLarge, styled, Tooltip } from '@thesisedu/react'
import { isEqual } from 'lodash'
import React from 'react'

import { DeleteSavedReportButton } from './DeleteSavedReportButton'
import { RenameSavedReportButton } from './RenameSavedReportButton'
import { SaveAsReportButton } from './SaveAsReportButton'
import { UpdateSavedReportButton } from './UpdateSavedReportButton'
import { RunReportOpts } from '../reporting/types'
import { SavedReportFragment } from '../schema'

export interface SavedReportActionsProps {
  savedReport: SavedReportFragment
  report: RunReportOpts
}
export function SavedReportActions({ savedReport, report }: SavedReportActionsProps) {
  const reportUpdated = !isEqual(normalizeValues(savedReport.report), normalizeValues(report))
  return (
    <>
      <ReportNameContainer>{savedReport.name}</ReportNameContainer>
      <Tooltip title={'Rename Report'}>
        <RenameSavedReportButton savedReport={savedReport} children={''} />
      </Tooltip>
      <Tooltip title={'Delete Saved Report'}>
        <DeleteSavedReportButton savedReportId={savedReport.id} />
      </Tooltip>
      {reportUpdated ? (
        <>
          <UpdateSavedReportButton report={report} savedReportId={savedReport.id} />
          <SaveAsReportButton report={report} />
        </>
      ) : null}
    </>
  )
}

const ReportNameContainer = styled(BodyLarge)`
  font-weight: bold;
  max-width: 50vw;
`

export const normalizeValues = (obj: Record<string, any>) => {
  const result: any = {}
  Object.keys(obj).forEach(key => {
    if (!(obj[key] == null)) result[key] = obj[key]
  })
  return result
}
