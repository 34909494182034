import { Empty } from '@thesisedu/react'
import { styled, Block, LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { SelectedStandardSet } from './StandardSelect'
import { StandardSetListItem } from './StandardSetListItem'
import { useAnyoneStandardSets } from './useAnyoneStandardSets'

export interface StandardSetSelectListProps {
  onChange?: (value?: SelectedStandardSet) => void
  value?: SelectedStandardSet
}
export function StandardSetSelectList({ value, onChange }: StandardSetSelectListProps) {
  const { loading, standardSets: _standardSets } = useAnyoneStandardSets() ?? {}
  const standardSets = _standardSets?.edges.map(edge => edge.node) ?? []
  if (loading) {
    return (
      <Block vertical horizontal>
        <LoadingIndicator.Labeled size={'s'} label={'Loading standards...'} />
      </Block>
    )
  } else if (standardSets.length) {
    return (
      <Container>
        {standardSets.map(standardSet => {
          return (
            <StandardSetListItem
              standardSet={standardSet}
              key={standardSet.id}
              selected={value?.id === standardSet.id}
              onClick={() => {
                const { id, name } = standardSet
                onChange?.({ id, name })
              }}
            />
          )
        })}
      </Container>
    )
  } else {
    return <Empty description={'Could not find any standard sets in your license.'} />
  }
}

const Container = styled.div`
  flex: 1;
`
