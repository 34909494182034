import { Row as AntRow } from 'antd'
import { RowProps } from 'antd/lib/row'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const Row: React.FC<React.PropsWithChildren<RowProps>> = props => {
  const theme = useContext(ThemeContext)
  return (
    <AntRow
      gutter={{
        xs: parseInt(theme['@size-xs'].replace('px'), 10),
        md: parseInt(theme['@size-s'].replace('px'), 10),
        lg: parseInt(theme['@size-m'].replace('px'), 10),
      }}
      {...props}
    />
  )
}
