import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { H2, styled, BodyCapital } from '@thesisedu/web'
import React from 'react'

import { useCourseContext } from '..'

const Container = styled.div<{ color: string }>`
  padding: ${props => props.theme['@size-l']};
  background: ${props => props.theme[`@${props.color}`]};
  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: calc(${props => props.theme['@heading-2-size']} * 1.4);
    max-height: calc(${props => props.theme['@heading-2-size']} * 1.4 * 2);
  }
`

export const CourseModalPosition: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { name } = useSelectedClass(false) || {}
  const { courseVersion } = useCourseContext(false) || {}
  if (!courseVersion) return null
  const color = courseVersion.course.basicConfiguration.color || 'blue'
  return (
    <Container color={color} className={'course-modal-position'}>
      <div className={'inner'}>
        <BodyCapital color={'@white'} style={{ opacity: 0.45, marginBottom: 10 }}>
          Currently Viewing
        </BodyCapital>
        <H2 isBlock={false} color={'@white'} data-testid={'CurrentlyViewingClassName'}>
          {name}
        </H2>
      </div>
    </Container>
  )
}
