import { UserRoutesHook, UserLeftItemsHook, UserTopItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { Book } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { ContentManagerOuter } from './ContentManagerOuter'
import { Courses } from './Courses'
import { Explore } from './explore'
import { CoursesReactFeature } from '../../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [...routes, <Route key={'courses'} path={'courses/*'} element={<Courses />} />]
      } else if (group === 'TEACHER') {
        return [
          ...routes,
          <Route key={'my-content'} path={'my-content/*'} element={<ContentManagerOuter />} />,
          <Route key={'explore'} path={'explore/*'} element={<Explore />} />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserTopItemsHook>(
    'feature-users-react:top-items',
    (items, { group }) => {
      if (group === 'TEACHER') {
        return [
          ...items,
          {
            weight: 2,
            element: (
              <Shell.TopMenuItem key={'explore'} name={'Explore'} path={'/teacher/explore'} />
            ),
          },
        ]
      } else return items
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            weight: 0,
            element: (
              <Shell.LeftMenuItem
                name={'Courses'}
                icon={<AntIconWrapper children={<Book />} />}
                path={'/admin/courses'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
