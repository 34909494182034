import { useObjectRef } from '@react-aria/utils'
import React from 'react'

import { TextField, TextFieldProps } from './TextField'
import { styled, var as v } from '../../style'

export interface TextAreaFieldProps extends TextFieldProps<'textarea'> {}
function _TextAreaField(props: TextAreaFieldProps, _ref: React.ForwardedRef<HTMLTextAreaElement>) {
  const ref = useObjectRef(_ref)
  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto'
      ref.current.style.height = ref.current.scrollHeight + 'px'
    }
  }, [props.value])
  return <StyledTextField {...props} ref={ref} inputElementType={'textarea'} />
}
export const TextAreaField = React.forwardRef(_TextAreaField)

const StyledTextField = styled(TextField)`
  height: auto;
  textarea {
    flex: 1;
    min-height: 40px;
    max-height: 200px;
    line-height: ${v('lineHeights.default')};
    margin-right: calc(var(--horizontal-padding) * -1);
    padding: var(--vertical-padding) var(--horizontal-padding) var(--vertical-padding) 0;
  }
`
