import { ErrorService, ErrorServiceTypes } from '@thesisedu/feature'
import { MutateSentryReplaySampleRate } from '@thesisedu/feature-sentry-web'
import { UsersReactFeature } from '@thesisedu/feature-users-react'

import { ClassesReactFeature } from '../ClassesReactFeature'
import { debug } from '../log'

const GROUP_REQUEST_RATIOS = {
  TEACHER: 1,
  STUDENT: 100,
  ADMIN: 2,
}

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ErrorServiceTypes.MutateTransactionSampleRate>(
    'transaction-sample-rate',
    (rate, { userGroup }) => {
      if (userGroup) {
        return ErrorService.adjustSampleRate(rate, userGroup, GROUP_REQUEST_RATIOS)
      } else return rate
    },
  )
  feature.hookManager.registerMutateHook<MutateSentryReplaySampleRate>(
    'feature-sentry-web:replay-sample-rate',
    async rates => {
      const userFeature = feature.root.requireFeature<UsersReactFeature>(UsersReactFeature.package)
      const user = await userFeature.getAuthenticatedUser()
      if (user?.group) {
        debug('adjusting replay sample rate based on user group %s', user.group)
        return {
          error: ErrorService.adjustSampleRate(rates.error, user.group, GROUP_REQUEST_RATIOS),
          session: ErrorService.adjustSampleRate(rates.session, user.group, GROUP_REQUEST_RATIOS),
        }
      } else {
        return rates
      }
    },
  )
}
