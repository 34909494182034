import {
  closestCenter,
  DndContext,
  DndContextProps,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CourseQuestion } from '@thesisedu/feature-course-types'
import { useScrollableContainerContext } from '@thesisedu/react'
import { ReactWindowScroller } from '@thesisedu/react/dist/grid/web/WindowScroller'
import { Empty } from 'antd'
import { orderBy } from 'lodash'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { FixedSizeList as List, areEqual, ListChildComponentProps } from 'react-window'

import { QuestionModal } from './QuestionModal'
import { useSegmentCalculationContext } from './SegmentCalculationContext'
import { VaultQuestion } from './VaultQuestion'
import { debug } from '../../log'

interface VaultQuestionListContextValue {
  setViewQuestion: (question: CourseQuestion) => void
}
const VaultQuestionListContext = React.createContext<VaultQuestionListContextValue>({
  setViewQuestion: () => {},
})

export interface VaultQuestionListProps {
  questions: CourseQuestion[]
}
export function VaultQuestionList({ questions: _questions }: VaultQuestionListProps) {
  const context = useSegmentCalculationContext(true)
  const questions = orderBy(_questions, 'weight', 'asc')
  const [viewQuestion, setViewQuestion] = useState<CourseQuestion>()
  const { dndContextProps, overlayPortal } = useSortableVaultQuestionList({
    onDragEnd: (movingId, overId) => {
      debug(`changing order of '${movingId}'`)
      console.info(movingId, overId)
      const sourceIndex = questions.findIndex(i => i.id === movingId)
      const destinationIndex = questions.findIndex(i => i.id === overId)
      const newQuestions = arrayMove(questions, sourceIndex, destinationIndex)
      const newIndex = newQuestions.findIndex(i => i.id === movingId)
      const previousWeight = newQuestions[newIndex - 1]?.weight || questions[0]?.weight || 0
      const nextWeight = newQuestions[newIndex + 1]?.weight
      const newWeight =
        previousWeight === nextWeight
          ? previousWeight - 1
          : nextWeight !== undefined
          ? (previousWeight + nextWeight) / 2
          : (newQuestions[newQuestions.length - 1]?.weight || 0) + 10
      debug('previousWeight', previousWeight)
      debug('nextWeight', nextWeight)
      debug('weight', newWeight)
      context.updateOverrides([{ id: movingId.toString(), weight: newWeight }])
    },
    renderPortalItem: id => (
      <VaultQuestion
        question={questions.find(q => q.id === id)!}
        isDragging
        onViewQuestion={() => {}}
        style={{
          height: 100,
        }}
      />
    ),
  })

  if (questions.length <= 0) {
    return <Empty style={{ margin: '30px 0' }} description={'No questions!'} />
  }

  return (
    <>
      <QuestionModal question={viewQuestion} onClose={() => setViewQuestion(undefined)} />
      <VaultQuestionListContext.Provider value={{ setViewQuestion }}>
        <DndContext {...dndContextProps}>
          <SortableContext items={questions} strategy={verticalListSortingStrategy}>
            <ReactWindowScroller>
              {({ ref, outerRef, style, onScroll }) => (
                <List
                  itemCount={questions.length}
                  itemData={questions}
                  itemKey={(index, data) => data[index].id}
                  itemSize={100}
                  height={window.innerHeight}
                  width={'100%'}
                  children={VaultQuestionListItem}
                  style={style}
                  onScroll={onScroll}
                  outerRef={outerRef}
                  ref={ref}
                />
              )}
            </ReactWindowScroller>
          </SortableContext>
          {overlayPortal}
        </DndContext>
      </VaultQuestionListContext.Provider>
    </>
  )
}

const VaultQuestionListItem = React.memo(
  ({ data: questions, index, style }: ListChildComponentProps) => {
    const question = questions[index]
    const { setViewQuestion } = React.useContext(VaultQuestionListContext)
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: question.id,
      transition: null, // These don't work well with react-window.
    })
    return (
      <VaultQuestion
        question={question}
        onViewQuestion={setViewQuestion}
        draggerProps={listeners}
        ref={setNodeRef}
        style={{
          ...style,
          opacity: isDragging ? 0 : 1,
          transform: CSS.Transform.toString(transform),
          transition,
        }}
        {...attributes}
      />
    )
  },
  areEqual,
)

interface SortableVaultQuestionListOpts {
  onDragEnd: (movingId: number | string, overId: number | string) => void
  renderPortalItem: (id: number | string) => React.ReactElement
}
interface SortableVaultQuestionListResult {
  dndContextProps: DndContextProps
  overlayPortal: React.ReactPortal
}
function useSortableVaultQuestionList({
  onDragEnd,
  renderPortalItem,
}: SortableVaultQuestionListOpts): SortableVaultQuestionListResult {
  const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null)
  const { target } = useScrollableContainerContext(false) || {}
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  React.useEffect(() => {
    if (activeId) {
      document.body.style.cursor = 'grabbing'
      return () => {
        document.body.style.cursor = ''
      }
    }
  }, [!!activeId])

  return {
    dndContextProps: {
      sensors,
      collisionDetection: closestCenter,
      onDragStart({ active }) {
        setActiveId(active.id)
      },
      onDragEnd({ over, active }) {
        setActiveId(null)
        if (over && active.id !== over.id) {
          onDragEnd(active.id, over.id)
        }
      },
      onDragCancel() {
        setActiveId(null)
      },
    },
    // dropAnimation is currently disabled because it keeps animating back to the original position.
    overlayPortal: createPortal(
      <DragOverlay dropAnimation={null}>
        {activeId ? renderPortalItem(activeId) : null}
      </DragOverlay>,
      target?.current || document.body,
    ),
  }
}
