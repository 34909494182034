import { FeatureReactRoot, ReactFeature } from '..'
import { debug } from '../log'

export async function installFeatures(root: FeatureReactRoot<any>): Promise<void> {
  require('../resources/devtools/ids').default(root)
  await Promise.all(
    Object.keys(root.features).map(async featureKey => {
      const feature = root.features[featureKey] as ReactFeature
      if (feature.getDevToolsInstaller) {
        const installer = await feature.getDevToolsInstaller()
        if (installer) {
          debug('installing DevTools for %s', featureKey)
          installer(root)
        }
      }
    }),
  )
}
