import { nanoid } from 'nanoid'
import seedrandom from 'seedrandom'

export function get(seed: string) {
  return seedrandom(seed)
}

export function int(minimum: number, maximum: number) {
  return Math.floor(Math.random() * (maximum - minimum + 1) + minimum)
}

export function id(length = 10) {
  return nanoid(length)
}

export function alphaNumericId(maxLength = 10) {
  return nanoid(maxLength).replace(/[^\dA-Za-z]/g, '')
}
