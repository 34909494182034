import { useDateSegment } from '@react-aria/datepicker'
import React from 'react'
import { DateFieldState, DateSegment as RSDateSegment } from 'react-stately'

import { styled, s } from '../..'

export interface DateSegmentProps {
  segment: RSDateSegment
  state: DateFieldState
}
export function DateSegment({ segment, state }: DateSegmentProps) {
  const ref = React.useRef<HTMLDivElement>(null)
  const { segmentProps } = useDateSegment(segment, state, ref)

  return (
    <_DateSegment
      {...segmentProps}
      ref={ref}
      style={
        segment.text === ', '
          ? { color: 'transparent' }
          : segment.isPlaceholder || !segment.isEditable
          ? { color: 'var(--placeholder-color)' }
          : undefined
      }
    >
      {segment.text}
    </_DateSegment>
  )
}

const _DateSegment = styled.div`
  padding: 0 2px;
  font-variant-numeric: tabular-nums;
  text-align: end;
  caret-color: transparent;
  &:focus {
    color: white !important;
    background: ${s.color('primary')};
    border-radius: 2px;
    outline: none;
  }
`
