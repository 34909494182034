import React, { useState } from 'react'

import { FragmentListCommonProps } from './types'
import { Calendar } from '../Calendar'

type RequiredFields = 'renderCalendarDate'
export type FragmentCalendarViewProps<T> = Pick<FragmentListCommonProps<T>, RequiredFields> &
  Partial<Omit<FragmentListCommonProps<T>, RequiredFields>> & {
    headerRight?: React.ReactElement
    headerLeft?: React.ReactNode
    monthSelected?: (endOfMonthTimestamp: string) => Promise<void> | void
    loading?: boolean
  }
export function FragmentCalendarView<T>({
  headerLeft,
  headerRight,
  refetch,
  loading: _loading,
  monthSelected,
  renderCalendarDate,
}: FragmentCalendarViewProps<T>) {
  const [stateLoading, setLoading] = useState(false)
  const loading = _loading || stateLoading
  return (
    <Calendar
      headerLeft={headerLeft}
      headerRight={headerRight}
      loading={loading}
      onPanelChange={async date => {
        if (refetch || monthSelected) {
          const monthString = date.clone().endOf('month').format()
          setLoading(true)
          try {
            if (refetch) {
              await refetch(btoa(`0_*_${JSON.stringify(monthString)}`))
            } else if (monthSelected) {
              await monthSelected(monthString)
            }
          } catch {
            // Do nothing...
          }
          setLoading(false)
        }
      }}
      dateCellRender={renderCalendarDate}
    />
  )
}
