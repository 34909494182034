import React from 'react'

import { MetronomeContextValue } from './types'

export const MetronomeContext = React.createContext<MetronomeContextValue | undefined>(undefined)

export function useMetronomeContext(): MetronomeContextValue | undefined
export function useMetronomeContext(require: false): MetronomeContextValue | undefined
export function useMetronomeContext(require: true): MetronomeContextValue
export function useMetronomeContext(require?: boolean): MetronomeContextValue | undefined {
  const context = React.useContext(MetronomeContext)
  if (!context && require) {
    throw new Error('MetronomeContext is required, yet not provided.')
  }
  return context
}
