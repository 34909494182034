import { MediaType, PerformanceConfig } from '@thesisedu/feature-assignments-core'
import {
  RecordViewProps,
  RecordOrViewWithSidebar,
  BookmarksPlugin,
  PipMediaPlugin,
  PitchPlugin,
  MetronomePlugin,
  CommentsPlugin,
} from '@thesisedu/feature-media-react'
import { isStorybook } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { DynamicVideoView } from '@thesisedu/feature-video-on-demand-react'
import { Form } from '@thesisedu/react'
import React from 'react'

import { useSubmissionContext, useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { useOptions } from '../../../useOptions'

const DEFAULT_MAX_DURATION = 60 * 10 // 10 minutes.
export interface PerformanceRecordFieldProps
  extends Partial<Omit<RecordViewProps, 'value' | 'onChange'>> {
  config?: PerformanceConfig
  name: string
  value?: string
  onChange?: (value?: string) => void
}
function _PerformanceRecordField(
  { name, config, value, onChange, children, ...rest }: PerformanceRecordFieldProps,
  _: React.ForwardedRef<HTMLDivElement>,
) {
  const { classId } = useSubmissionContext(false) || {}
  const { performanceFieldAllowsBookmarks } = useOptions()
  return (
    <RecordOrViewWithSidebar
      value={value}
      onChange={onChange}
      label={`Answer for '${name}'`}
      skipReview={!isStorybook}
      classId={classId}
      maxDurationSeconds={config?.maxLengthSeconds ?? DEFAULT_MAX_DURATION}
      hideVideo={config?.mediaType && !config.mediaType.includes(MediaType.Video)}
      hideAudio={config?.mediaType && !config.mediaType.includes(MediaType.Audio)}
      {...rest}
    >
      <CommentsPlugin />
      {performanceFieldAllowsBookmarks ? <BookmarksPlugin /> : null}
      {config?.vodId ? (
        <PipMediaPlugin>
          <DynamicVideoView vodId={config.vodId} />
        </PipMediaPlugin>
      ) : null}
      {config?.tools?.pitch ? <PitchPlugin /> : null}
      {config?.tools?.metronome ? <MetronomePlugin /> : null}
      {children}
    </RecordOrViewWithSidebar>
  )
}
export const PerformanceRecordField = React.forwardRef(_PerformanceRecordField)

export function PerformanceContent(props: QuestionProps<PerformanceConfig>) {
  const { group } = useViewerContext(false) ?? {}
  return (
    <Form.Item
      name={props.question.id}
      required={'This question is required.'}
      initialValue={useSubmissionData(props.question.id)}
    >
      <PerformanceRecordField
        config={props.question.config}
        name={props.question.name}
        disabled={
          props.disabled
            ? group === 'TEACHER'
              ? 'Students will be able to record here.'
              : true
            : false
        }
      />
    </Form.Item>
  )
}
