import React, { useState } from 'react'

import { LicenseInvalidModal } from './LicenseInvalidModal'
import { LicenseStatusModal } from './LicenseStatusModal'

export interface LicenseInvalidContextValue {
  showLicenseInvalid: (title: string, classId?: string) => void
}

export const LicenseInvalidContext = React.createContext<LicenseInvalidContextValue>({
  showLicenseInvalid: () => false,
})
export const LicenseInvalidProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [classId, setClassId] = useState<string>()
  const [title, setTitle] = useState<string>()
  const value: LicenseInvalidContextValue = {
    showLicenseInvalid: (title, classId) => {
      setClassId(classId || undefined)
      setTitle(title)
    },
  }
  return (
    <>
      <LicenseStatusModal />
      <LicenseInvalidModal title={title} classId={classId} onCancel={() => setTitle(undefined)} />
      <LicenseInvalidContext.Provider value={value} children={children} />
    </>
  )
}
