import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { MusicDoubleNote } from '@thesisedu/react/icons'
import { Form, Checkbox } from 'antd'
import React from 'react'

export const ENABLE_FREE_COMPOSE = 'enableFreeCompose'
function Field({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, ENABLE_FREE_COMPOSE]}
      valuePropName={'checked'}
      extra={
        'When this is checked, students will be allowed (from the student homepage) to create ' +
        'their own compositions and share them with you. You will be able to view their ' +
        'submissions on the Compositions tab when viewing the class.'
      }
    >
      <Checkbox>Allow free compose.</Checkbox>
    </Form.Item>
  )
}

const resource: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: ENABLE_FREE_COMPOSE,
  group: 'Compose',
  groupIcon: <MusicDoubleNote />,
  renderField: Field,
}
export default resource
