import h from 'hastscript'
import React from 'react'
import ReactMarkdown, { ReactMarkdownOptions } from 'react-markdown'
import raw from 'rehype-raw'
import html from 'rehype-stringify'
import directive from 'remark-directive'
import gfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import styled from 'styled-components'
import unified from 'unified'
import visit from 'unist-util-visit'
import vfile from 'vfile'

function correctMarkdown(value: string): string {
  // This is to fix deeply-nested lists rendering as code blocks instead.
  return value.replace(/ {4}(?=\d)/g, '     ')
}

function plainText(this: any) {
  function compiler(tree: any) {
    const parts: string[] = []
    visit<any>(tree, 'text', node => {
      parts.push(node.value)
    })
    return parts.join('')
  }

  Object.assign(this, { Compiler: compiler })
}

const VALID_TAG_NAMES =
  'a,abbr,address,area,article,aside,audio,b,base,bdi,bdo,blockquote,body,br,button,canvas,caption,cite,code,col,colgroup,data,datalist,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,head,header,hgroup,hr,html,i,iframe,img,input,ins,kbd,label,legend,li,link,main,map,mark,menu,meta,meter,nav,noscript,object,ol,optgroup,option,output,p,param,picture,pre,progress,q,rp,rt,ruby,s,samp,script,section,select,slot,small,source,span,strong,style,sub,summary,sup,table,tbody,td,template,textarea,tfoot,th,thead,time,title,tr,track,u,ul,var,video,wbr'.split(
    ',',
  )
function htmlDirectives() {
  return transform
  function transform(tree: any) {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], ondirective)
  }
  function ondirective(node: any) {
    const data = node.data || (node.data = {})
    const hast = h(node.name, node.attributes)
    const tagName = (hast as any).tagName
    if (VALID_TAG_NAMES.includes(tagName)) {
      data.hName = (hast as any).tagName
      data.hProperties = (hast as any).properties
    } else {
      data.hName = 'span'
      const attrString = Object.keys(node.attributes || {})
        .reduce<string[]>((acc, attributeKey) => {
          return [...acc, [attributeKey, node.attributes[attributeKey]].join('=')]
        }, [])
        .join(',')
      data.hChildren = [
        {
          type: 'text',
          value: `:${node.name}${attrString ? `{${attrString}}` : ''}`,
        },
      ]
    }
  }
}

function placeholderDirective() {
  return transform
  function transform(tree: any) {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], (node: any) => {
      if (node.name === 'placeholder') {
        const data = node.data || (node.data = {})
        data.hName = 'placeholder'
        data.hProperties = {
          name: node.attributes.name,
        }
      }
    })
  }
}

function placeholderReplaceDirective(placeholders: Record<string, any>) {
  return function () {
    return transform
    function transform(tree: any) {
      visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], (node: any) => {
        if (node.name === 'placeholder') {
          const data = node.data || (node.data = {})
          data.hName = 'span'
          data.hChildren = [
            {
              type: 'text',
              value: placeholders[node.attributes.name],
            },
          ]
        }
      })
    }
  }
}

export function HTMLOrMarkdown({ children, ...props }: ReactMarkdownOptions) {
  if (children.includes('<p>') && children.includes('</p>') && children.startsWith('<')) {
    return <div dangerouslySetInnerHTML={{ __html: children }} />
  } else {
    return <Markdown children={children} {...props} />
  }
}

export function markdownToHtml(markdown: string) {
  const processor = unified()
    .use(remarkParse as any)
    .use([directive, htmlDirectives, gfm] as any)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(raw)
    .use(html)
  const file = vfile(markdown)
  return String(processor.processSync(file))
}

export function markdownToPlainText(markdown: string, placeholders?: Record<string, any>) {
  const processor = unified()
    .use(remarkParse as any)
    .use([directive, htmlDirectives, placeholderReplaceDirective(placeholders || {}), gfm] as any)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(raw)
    .use(plainText)
  const file = vfile(markdown)
  return String(processor.processSync(file))
}

export interface MarkdownProps extends ReactMarkdownOptions {
  inline?: boolean
  placeholders?: Record<string, any>
}
export function Markdown({ children, inline, placeholders, ...props }: MarkdownProps) {
  if (typeof props.plugins === 'function' && process.env.NODE_ENV === 'development') {
    console.warn('Markdown "plugins" prop cannot be a function.')
  }
  return (
    <ReactMarkdown
      skipHtml={false}
      {...props}
      components={
        {
          ...props.components,
          ...(inline
            ? {
                p: ({ node, ...props }: any) => <span {...props} />,
              }
            : {}),
          placeholder: ({ name, ...rest }: any) => {
            if (placeholders?.[name]) {
              return <span {...rest} children={placeholders[name]} />
            } else {
              return <NoPlaceholder {...rest}>No Placeholder</NoPlaceholder>
            }
          },
        } as any
      }
      children={correctMarkdown(children)}
      plugins={
        [
          directive,
          htmlDirectives,
          placeholderDirective,
          ...(Array.isArray(props.plugins) ? props.plugins : []),
          gfm,
        ] as any
      }
      rehypePlugins={[raw] as any}
      linkTarget={'_blank'}
    />
  )
}

const NoPlaceholder = styled.span`
  background: ${props => props.theme['@gray-2']};
  color: ${props => props.theme['@red']};
  border-radius: ${props => props.theme['@border-radius-base']};
  display: inline-block;
  padding: 0 ${props => props.theme['@size-xxs']};
  font-weight: bold;
`
