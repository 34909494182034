import { HSpaced, NumberField, Select, Text } from '@thesisedu/ui'
import React from 'react'

enum NumGroupsMode {
  Groups = 'groups',
  StudentsPerGroup = 'students-per-group',
}
interface Info {
  label: string
  selectLabel: string
  toInputValue: (value: number, numStudents: number) => number
  fromInputValue: (value: number, numStudents: number) => number
}
const InfoMap: Record<NumGroupsMode, Info> = {
  [NumGroupsMode.Groups]: {
    label: 'Number of Groups',
    selectLabel: 'groups',
    toInputValue: value => value,
    fromInputValue: value => value,
  },
  [NumGroupsMode.StudentsPerGroup]: {
    label: 'Number of Students per Group',
    selectLabel: 'students per group',
    toInputValue: (value, numStudents) => Math.round(numStudents / value),
    fromInputValue: (value, numStudents) => Math.round(numStudents / value),
  },
}
export interface NumGroupsFieldProps {
  value?: number
  onChange?: (value: number) => void
  numStudents: number
}
function _NumGroupsField(
  { value, onChange, numStudents }: NumGroupsFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const [mode, setMode] = React.useState<NumGroupsMode>(NumGroupsMode.Groups)
  return (
    <HSpaced ref={ref} space={'xs'}>
      <Text>Generate</Text>
      <NumberField
        aria-label={InfoMap[mode].label}
        style={{ width: 60 }}
        value={value ? InfoMap[mode].toInputValue(value, numStudents) : undefined}
        onChange={value => {
          onChange?.(InfoMap[mode].fromInputValue(value, numStudents))
        }}
      />
      <Select<NumGroupsMode>
        aria-label={'Generate Count Mode'}
        value={mode}
        onValueChange={setMode}
        style={{ flex: 1 }}
      >
        {Object.values(NumGroupsMode).map(mode => (
          <Select.Item key={mode} value={mode}>
            {InfoMap[mode].selectLabel}
          </Select.Item>
        ))}
      </Select>
    </HSpaced>
  )
}
export const NumGroupsField = React.forwardRef(_NumGroupsField)
