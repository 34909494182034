import { ZonedDateTime, getLocalTimeZone } from '@internationalized/date'
import { AriaDatePickerProps, DateValue, useDatePicker } from '@react-aria/datepicker'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { useDatePickerState } from 'react-stately'

import { Calendar } from './Calendar'
import { ClearButton } from './ClearButton'
import { InnerDateField, InnerDateFieldProps } from './DateField'
import { parse, parseTime } from './parse'
import { Popover, Button } from '../..'
import { Calendar as CalendarIcon } from '../../../icons'

function getPlaceholderValue(defaultTime?: string) {
  const date = new Date()
  const parsedDefaultTime = defaultTime ? parseTime(defaultTime) : undefined
  return parsedDefaultTime
    ? new ZonedDateTime(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        getLocalTimeZone(),
        0,
        parsedDefaultTime.hour,
        parsedDefaultTime.minute,
        parsedDefaultTime.second,
      )
    : undefined
}

export interface DatePickerFieldProps
  extends Omit<InnerDateFieldProps, 'value' | 'onChange'>,
    Omit<AriaDatePickerProps<DateValue>, 'value' | 'onChange'> {
  value?: string | null
  onChange?: (value: string | null) => void
  defaultTime?: string
}
function _DatePickerField(
  { value, onChange, defaultTime = '12:00', ...props }: DatePickerFieldProps,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const state = useDatePickerState({
    value: value ? parse(value) : null,
    onChange: value => {
      onChange?.(value?.toDate('UTC').toISOString() ?? null)
    },
    placeholderValue: defaultTime ? getPlaceholderValue(defaultTime) : undefined,
    ...props,
  })
  const ref = useObjectRef(_ref)
  const { groupProps, fieldProps, buttonProps, dialogProps, calendarProps } = useDatePicker(
    props,
    state,
    ref,
  )

  return (
    <InnerDateField
      {...props}
      {...(fieldProps as InnerDateFieldProps)}
      __groupProps={groupProps}
      ref={ref}
      suffix={
        <>
          <ClearButton {...props} value={value} onChange={onChange} />
          <Popover.Container open={state.isOpen} onOpenChange={state.setOpen}>
            <Popover.Trigger>
              <Button
                {...buttonProps}
                size={props.size}
                icon={<CalendarIcon />}
                style={{ padding: 4 }}
              />
            </Popover.Trigger>
            <Popover.Content {...dialogProps} hideClose width={300}>
              <Calendar {...calendarProps} />
            </Popover.Content>
          </Popover.Container>
        </>
      }
    />
  )
}
export const DatePickerField = React.forwardRef(_DatePickerField)
