import { CheckOutlined, PlusOutlined } from '@ant-design/icons'
import {
  CLASS_STUDENTS_REFETCH_QUERIES,
  ExpandedContentComponentProps,
} from '@thesisedu/feature-classes-react'
import { ProgressModal, styled } from '@thesisedu/web'
import { Button, Form, Input } from 'antd'
import { chunk } from 'lodash'
import React, { useEffect, useState } from 'react'

import { ImportStudentIDPayload } from '../ImportStudentID'
import { useCreateStudentMutation } from '../queries/useCreateStudentMutation'

const CREATE_CHUNK_SIZE = 5
export function AddManual({
  class: cls,
  onClose,
}: ExpandedContentComponentProps<ImportStudentIDPayload>) {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [current, setCurrent] = useState(0)
  const [total, setTotal] = useState(0)
  const [create] = useCreateStudentMutation()
  useEffect(() => {
    form.setFieldsValue({
      students: new Array(5).fill('').map(() => ({ firstName: '', lastName: '' })),
    })
  }, [])
  return (
    <>
      <ProgressModal
        current={Math.min(current, total)}
        total={total}
        visible={visible}
        buildLeftLabel={() => 'Creating students...'}
        buildRightLabel={(current, total) =>
          current === total ? 'Complete' : `${current + 1} / ${total} complete`
        }
      />
      <Form
        form={form}
        onFinish={async values => {
          const students = (values.students as { firstName?: string; lastName?: string }[]).filter(
            student => student.firstName?.trim() || student.lastName?.trim(),
          )
          setTotal(students.length)
          setCurrent(0)
          setVisible(true)
          const chunks = chunk(students, CREATE_CHUNK_SIZE)
          try {
            for (let i = 0; i < chunks.length; i++) {
              const chunk = chunks[i]
              const isLastChunk = i === chunks.length - 1
              await Promise.all(
                chunk.map(async (student, index) => {
                  const isLastStudent = isLastChunk && index === chunk.length - 1
                  setCurrent(c => c + 1)
                  const result = await create({
                    variables: {
                      input: {
                        firstName: student.firstName,
                        lastName: student.lastName,
                        classIds: [cls.id],
                      },
                    },
                    refetchQueries: isLastStudent ? CLASS_STUDENTS_REFETCH_QUERIES : undefined,
                    awaitRefetchQueries: isLastStudent,
                  })
                  if (!result?.data?.createStudent.student.id) {
                    throw new Error('did not create student')
                  }
                }),
              )
            }
          } catch {
            setVisible(false)
            return
          }
          setCurrent(students.length)
          setTimeout(() => {
            setVisible(false)
            onClose()
          }, 1000)
        }}
      >
        <Container>
          <Form.List name={'students'}>
            {(items, { add }) => (
              <>
                {items.map(item => (
                  <div className={'student'} key={item.key}>
                    <div>
                      <Input.Group compact>
                        <Form.Item
                          noStyle
                          name={[item.name, 'firstName']}
                          fieldKey={[item.fieldKey!, 'firstName']}
                        >
                          <Input style={{ width: '50%' }} placeholder={'First'} />
                        </Form.Item>
                        <Form.Item
                          noStyle
                          name={[item.name, 'lastName']}
                          fieldKey={[item.fieldKey!, 'lastName']}
                        >
                          <Input style={{ width: '50%' }} placeholder={'Last'} />
                        </Form.Item>
                      </Input.Group>
                    </div>
                  </div>
                ))}
                <div className={'footer'}>
                  <Button icon={<PlusOutlined />} onClick={add}>
                    Add more students
                  </Button>
                  <Button type={'primary'} htmlType={'submit'} icon={<CheckOutlined />}>
                    Create Students
                  </Button>
                </div>
              </>
            )}
          </Form.List>
        </Container>
      </Form>
    </>
  )
}

const Container = styled.div`
  .footer {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    button:last-child {
      margin-left: auto;
    }
  }
  .student {
    display: flex;
    align-items: center;
    margin: 5px 0;
    &:first-child {
      margin-top: 0;
    }
    > * {
      margin: 0 5px;
      width: 100%;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`
