import { SAMLButtonIcon, getSAMLButton } from '@thesisedu/feature-users-react'
import React from 'react'

import { useCanvasLogin } from './useCanvasLogin'
// @ts-ignore
import icon from '../../assets/icon.svg'
import { useCanvasIntegrationSearchQuery, useCanvasScopesQuery } from '../schema'

export const CanvasLoginButton = getSAMLButton({
  icon: <SAMLButtonIcon src={icon} alt={'Canvas Icon'} />,
  label: 'Canvas',
  identifier: 'canvas',
  useLoginHook: useCanvasLogin,
  useIntegrationSearch: ({ name, skip }) => {
    const { loading, data } = useCanvasIntegrationSearchQuery({
      variables: {
        input: { name },
      },
      skip,
    })
    return {
      loading,
      results:
        data?.canvasIntegrationSearch.map(item => ({
          id: item.integrationId,
          name: item.name,
          siteName: item.siteName,
          signInOpts: {
            consumerKey: item.consumerKey,
            url: item.url,
            id: item.integrationId,
          },
        })) ?? [],
    }
  },
  useScopes: () => useCanvasScopesQuery().data?.canvasScopes ?? [],
})
