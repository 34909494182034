import { RevealAnswers } from '@thesisedu/feature-assignments-core'
import { useViewerContext } from '@thesisedu/feature-users-react'
import React from 'react'

import {
  useAssignmentViewerContext,
  useAssignmentViewerFormContext,
} from './AssignmentViewerFormContext'
import { QuestionGradeComment } from './QuestionGradeComment'
import { useSubmissionContext } from '../../contexts/SubmissionContext'
import { AssignmentQuestions, AssignmentQuestionsProps } from '../AssignmentQuestions'

export interface FormQuestionsProps extends Partial<AssignmentQuestionsProps> {}
export function FormQuestions(props: FormQuestionsProps) {
  const { classId, configuration, assignment, templateId } = useAssignmentViewerContext()
  const viewer = useViewerContext(true)
  const {
    submission,
    onFieldsChange,
    studentId,
    groups: { areAnswersShared, isGroupLeader },
  } = useSubmissionContext(true)
  const {
    form,
    timeLimitReached,
    setCompletedFields,
    getCompletedFields,
    setMergedSubmissionData,
  } = useAssignmentViewerFormContext(true)
  const disableFields =
    !!submission?.submittedAt ||
    viewer.group !== 'STUDENT' ||
    (areAnswersShared && !isGroupLeader) ||
    (!!submission && !submission.canEdit) ||
    timeLimitReached ||
    !studentId
  // The questions for an assignment won't change unless the assignment changes, or unless
  // the question order changes.
  const questions = React.useMemo(
    () => (configuration.questions || []).filter(q => !q.disabled),
    [assignment.id, configuration.questions?.map(q => q.id).join(',')],
  )
  return (
    <AssignmentQuestions
      editorId={`assignment-${templateId}`}
      disabled={disableFields}
      questions={questions}
      widgets={configuration.widgets} // TODO: Widgets between questions should be removed for disabled questions.
      showGradeFeedback={assignment.revealAnswers === RevealAnswers.RealTime}
      resetQuestion={id => {
        const values = form.getValues()
        delete values[id]
        onFieldsChange(
          {
            id: assignment.id,
            classId,
            submissionData: values,
          },
          true,
        )
        setCompletedFields(getCompletedFields(values))
        setTimeout(() => {
          setMergedSubmissionData(values)
          form.resetField(id)
        }, 10)
      }}
      renderGradeComment={question => (
        <QuestionGradeComment question={question} submission={submission} assignment={assignment} />
      )}
      {...props}
    />
  )
}
