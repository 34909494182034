import { Image as AntImage } from 'antd'
import { ImageProps as AntImageProps } from 'antd/es/image'
import React from 'react'
import styled from 'styled-components'

export function useImageLoadCallback(src: string, onLoad?: () => void, onError?: () => void) {
  React.useEffect(() => {
    if (onLoad || onError) {
      const image = new window.Image()
      image.src = src
      if (onLoad) image.onload = onLoad
      if (onError) image.onerror = onError
    }
  }, [src])
}

export enum ImageLoadResult {
  Loading,
  Loaded,
  Error,
}
export function useImageLoad(src: string): ImageLoadResult {
  const [loading, setLoading] = React.useState<ImageLoadResult>(ImageLoadResult.Loading)
  useImageLoadCallback(
    src,
    () => setLoading(ImageLoadResult.Loaded),
    () => setLoading(ImageLoadResult.Error),
  )
  React.useEffect(() => {
    setLoading(ImageLoadResult.Loading)
  }, [src])

  return loading
}

export enum ImageFallbackSize {
  Small,
  Medium,
  Large,
}
type ImageSizeMap = {
  [size in ImageFallbackSize]: string
}
const imageSizeMap: ImageSizeMap = {
  [ImageFallbackSize.Small]:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 256 256"><defs/><defs><path id="a" d="M128 44c-46.388 0-84 37.612-84 84 0 46.387 37.612 84 84 84 46.387 0 84-37.613 84-84 0-46.388-37.613-84-84-84zm-6 43.5c0-.825.675-1.5 1.5-1.5h9c.825 0 1.5.675 1.5 1.5v51c0 .825-.675 1.5-1.5 1.5h-9c-.825 0-1.5-.675-1.5-1.5v-51zm6 82.5a9.002 9.002 0 010-18 9.002 9.002 0 010 18z"/></defs><g fill="none" fill-rule="evenodd"><path fill="[BACKGROUND]" d="M0 0h256v256H0z"/><use fill="[ICON]" fill-rule="nonzero" xlink:href="#a"/></g></svg>',
  [ImageFallbackSize.Medium]:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 256 256"><defs/><defs><path id="a" d="M128 64c-35.343 0-64 28.657-64 64s28.657 64 64 64 64-28.657 64-64-28.657-64-64-64zm-4.571 33.143c0-.629.514-1.143 1.142-1.143h6.858c.628 0 1.142.514 1.142 1.143V136c0 .629-.514 1.143-1.142 1.143h-6.858A1.146 1.146 0 01123.43 136V97.143zM128 160a6.859 6.859 0 010-13.714A6.859 6.859 0 01128 160z"/></defs><g fill="none" fill-rule="evenodd"><path fill="[BACKGROUND]" d="M0 0h256v256H0z"/><use fill="[ICON]" fill-rule="nonzero" xlink:href="#a"/></g></svg>',
  [ImageFallbackSize.Large]:
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 256 256"><defs/><defs><path id="a" d="M128 96c-17.671 0-32 14.329-32 32s14.329 32 32 32 32-14.329 32-32-14.329-32-32-32zm-2.286 16.571c0-.314.257-.571.572-.571h3.428c.315 0 .572.257.572.571V132a.573.573 0 01-.572.571h-3.428a.573.573 0 01-.572-.571v-19.429zM128 144a3.43 3.43 0 010-6.857 3.43 3.43 0 010 6.857z"/></defs><g fill="none" fill-rule="evenodd"><path fill="[BACKGROUND]" d="M0 0h256v256H0z"/><use fill="[ICON]" fill-rule="nonzero" xlink:href="#a"/></g></svg>',
}

export interface ImageProps extends AntImageProps {
  fallbackSize?: ImageFallbackSize
  transparentPreview?: boolean
}

export function Image({
  fallbackSize = ImageFallbackSize.Small,
  transparentPreview,
  ...props
}: ImageProps) {
  const content = (
    <AntImage
      placeholder={!props.src}
      fallback={imageSizeMap[fallbackSize]}
      {...props}
      preview={
        transparentPreview
          ? {
              ...(typeof props.preview === 'object' ? props.preview : {}),
              maskClassName: 'transparent-preview',
            }
          : props.preview || false
      }
    />
  )

  if (transparentPreview) {
    return <TransparentPreviewContainer children={content} />
  } else return content
}

const TransparentPreviewContainer = styled.div`
  .transparent-preview {
    opacity: 0 !important;
  }
`
