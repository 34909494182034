import { Announcements } from '@thesisedu/feature-announcements-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { DirectMessagesRoute } from '@thesisedu/feature-direct-messages-react'
import { styled, Body } from '@thesisedu/web'
import { Tabs } from 'antd'
import React from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'

export function Messages() {
  return (
    <Container>
      <Body isBlock>
        <Link to={'/student'}>&larr; Back to Prodigies Academy</Link>
      </Body>
      <DirectMessagesRoute />
    </Container>
  )
}

const Container = styled.div`
  width: 1100px;
  margin: 100px auto;
  max-width: 95vw;
`

export function ClassMessages() {
  const classId = useSelectedClassId(true)
  return <DirectMessagesRoute listProps={{ filters: { classIds: [classId] } }} />
}

export function AnnouncementsMessages() {
  const classId = useSelectedClassId(true)
  const location = useLocation()
  const navigate = useNavigate()
  const segments = location.pathname.split('/')
  const clsIndex = segments.indexOf(classId)
  const selectedKey = segments[clsIndex + 1]
  return (
    <Tabs
      activeKey={selectedKey}
      onChange={key => {
        navigate(`${segments.slice(0, clsIndex + 1).join('/')}/${key}`)
      }}
    >
      <Tabs.TabPane key={'messages'} tab={'Messages'}>
        <ClassMessages />
      </Tabs.TabPane>
      <Tabs.TabPane key={'announcements'} tab={'Announcements'}>
        <Announcements />
      </Tabs.TabPane>
    </Tabs>
  )
}
