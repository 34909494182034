import { Button, styled, s, Block, Text } from '@thesisedu/ui'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  min-height: 80vh;
  padding: 16px;
`
const BodyBackground = createGlobalStyle`
  body {
    background: ${s.color('subtle')};
  }
`

const Page = styled.div`
  padding: ${props => props.theme['@size-m']};
  width: 100%;
  min-height: 40vh;
  background: ${s.color('background')};
  border-radius: ${s.var('radii.2')};
  margin: 5vh 0 ${props => props.theme['@size-xxl']} 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`

export interface ErrorPageProps {
  title: string
  technical: string
  description: string
  footer?: React.ReactElement
  action?: string | React.ReactElement
  onActionClick?: () => void
}
export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  title,
  technical,
  description,
  footer,
  action,
  onActionClick,
}) => (
  <Container>
    <BodyBackground />
    <Page>
      <TechnicalContainer color={'secondary'}>{technical}</TechnicalContainer>
      <Text level={'h1'} top={'s'}>
        {title}
      </Text>
      <Block top={'m'}>
        <Text level={'l'} bottom>
          {description}
        </Text>
        {action && onActionClick ? (
          <Button variant={'primary'} onPress={onActionClick} children={action} />
        ) : null}
      </Block>
      {footer ? <Block top={'s'}>{footer}</Block> : null}
    </Page>
  </Container>
)

const TechnicalContainer = styled(Text)`
  background: ${s.color('element')};
  color: ${s.color('secondary')};
  font-family: ${s.var('font.code')};
  border-radius: ${s.var('radii.1')};
  border: solid 1px ${s.color('border')};
  padding: ${s.size('xxs')} ${s.size('xs')};
`
