import { MatchAnswer } from '@thesisedu/feature-assignments-core'
import { Reset } from '@thesisedu/react'
import { Cancel } from '@thesisedu/react/icons'
import { Body, BodySmall, HSpaced, styled, Tooltip } from '@thesisedu/web'
import classnames from 'classnames'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { AnswerWrapper } from './Answer'
import { DragDropFieldAffix } from './DragDropFieldAffixStyles'
import { QuestionInstructions } from './QuestionInstructions'
import { QuestionTextMediaView } from '../../questions/QuestionTextMediaField'

export const DragDropDropdownValidator = async (
  sources: string[],
  destinations: string[],
  value?: MatchAnswer[],
): Promise<string | undefined> => {
  const duplicates = sources.filter((val, idx) => sources.indexOf(val) !== idx)
  if (duplicates.length) {
    return 'Each answer can only be used once'
  }
  if (value?.length !== sources.length) {
    return 'This question is required.'
  }
}

interface SourceCounts {
  [source: string]: number
}

const SOURCES_DROPPABLE = 'sources'
export interface DragDropFieldProps {
  sources: string[]
  destinations: string[]
  value?: MatchAnswer[]
  onChange?: (value: MatchAnswer[]) => void
  disabled?: boolean
  allowMultipleInDestinations?: boolean
}
export function DragDropField({
  sources,
  destinations,
  value,
  onChange,
  disabled,
  allowMultipleInDestinations,
}: DragDropFieldProps) {
  const sourceCounts = sources.reduce<SourceCounts>((acc, source) => {
    if (!acc[source]) acc[source] = 0
    acc[source]++
    return acc
  }, {})
  const wordbank = Object.keys(sourceCounts).reduce<string[]>((acc, source) => {
    const valueCount = (value || []).filter(v => v.source === source).length
    const result: string[] = []
    for (let i = 0; i < sourceCounts[source] - valueCount; i++) {
      result.push(source)
    }
    return [...acc, ...result]
  }, [])
  return (
    <QuestionInstructions instructions={'Drag and drop to create matches below.'}>
      <DragDropContext
        onDragEnd={result => {
          if (!result.destination || !onChange) return
          const { droppableId: toDestination } = result.destination
          const { index, droppableId: fromDestination } = result.source
          let source: string
          if (fromDestination === SOURCES_DROPPABLE) {
            source = wordbank[index]
          } else {
            const valuesWithDestination = (value || []).filter(
              v => v.destination === fromDestination,
            )
            const v = valuesWithDestination[index]
            if (v) {
              source = v.source
            } else {
              console.warn('Cannot find source draggable value')
              return
            }
          }
          const without = (value || []).filter(
            v => !(v.source === source && v.destination === fromDestination),
          )
          if (toDestination === SOURCES_DROPPABLE) {
            onChange(without)
          } else {
            onChange([...without, { source, destination: toDestination }])
          }
        }}
      >
        <HSpaced align={'stretch'} space={'@size-s'}>
          <DragDropFieldAffix>
            <Droppable droppableId={SOURCES_DROPPABLE}>
              {provided => (
                <SourceContainer ref={provided.innerRef}>
                  {wordbank.map((source, index) => (
                    <Draggable draggableId={source} index={index} key={source}>
                      {(provided, snapshot) => (
                        <Source
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classnames({
                            dragging: snapshot.isDragging,
                            disabled,
                          })}
                        >
                          <Body useDiv>
                            <QuestionTextMediaView value={source} />
                          </Body>
                        </Source>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </SourceContainer>
              )}
            </Droppable>
          </DragDropFieldAffix>
          <DestinationContainer>
            {destinations.map(destination => {
              const dropValues = value?.filter(v => v.destination === destination) || []
              const firstDropValue =
                !allowMultipleInDestinations && dropValues.length === 1 ? dropValues[0] : undefined
              return (
                <Droppable
                  droppableId={destination}
                  key={destination}
                  isDropDisabled={!!dropValues.length && !allowMultipleInDestinations}
                >
                  {(provided, snapshot) => (
                    <Destination
                      ref={provided.innerRef}
                      className={classnames({
                        dropping: snapshot.isDraggingOver,
                        hasValue: !!dropValues.length,
                        multiple: allowMultipleInDestinations,
                      })}
                    >
                      <DestinationHeader>
                        <Body useDiv>
                          <DestinationText
                            destination={destination}
                            value={firstDropValue?.source}
                          />
                        </Body>
                        {dropValues.length && !disabled ? (
                          <Tooltip title={'Clear Answer'}>
                            <Reset.Button
                              onClick={e => {
                                e.preventDefault()
                                if (onChange) {
                                  const without = (value || []).filter(v => {
                                    return !dropValues.some(
                                      dropValue =>
                                        dropValue.source === v.source &&
                                        dropValue.destination === v.destination,
                                    )
                                  })
                                  onChange(without)
                                }
                              }}
                            >
                              <Body useDiv color={'@text-color-secondary'}>
                                <Cancel />
                              </Body>
                            </Reset.Button>
                          </Tooltip>
                        ) : null}
                      </DestinationHeader>
                      <DestinationFooter>
                        <DestinationPlaceholder>
                          <BodySmall color={'@text-color-secondary'}>Drop here</BodySmall>
                        </DestinationPlaceholder>
                        {dropValues.map((dropValue, index) => (
                          <Draggable
                            draggableId={dropValue.source}
                            index={index}
                            key={dropValue.source}
                          >
                            {(provided, snapshot) => (
                              <Source
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={classnames({
                                  dragging: snapshot.isDragging,
                                  disabled,
                                })}
                              >
                                <Body useDiv>
                                  <QuestionTextMediaView value={dropValue.source} />
                                </Body>
                              </Source>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </DestinationFooter>
                    </Destination>
                  )}
                </Droppable>
              )
            })}
          </DestinationContainer>
        </HSpaced>
      </DragDropContext>
    </QuestionInstructions>
  )
}

export interface DestinationTextProps {
  destination: string
  value?: string
}
export function DestinationText({ destination, value }: DestinationTextProps) {
  const components = destination.split(':blank:').map(v => v.trim())
  if (components.length > 1) {
    const children = components.reduce<React.ReactElement[]>((acc, component, index) => {
      const destination =
        index === components.length - 1
          ? []
          : [<DestinationBlank children={value} key={`${index}-blank`} />]
      if (component) {
        return [...acc, <span key={index} children={component} />, ...destination]
      } else {
        return [...acc, ...destination]
      }
    }, [])
    return <>{children}</>
  } else {
    return <QuestionTextMediaView value={destination} />
  }
}

export const Source: any = styled(AnswerWrapper)`
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xs']};
  width: 100%;
  transition:
    color 0.25s linear,
    background 0.25s linear,
    box-shadow 0.25s ease-in-out;
  box-shadow: 0 0 0 0 ${props => props.theme['@primary-color']};
  z-index: 2;
  position: relative;
  &:hover,
  &.dragging {
    color: ${props => props.theme['@primary-color']};
    background: ${props => props.theme['@primary-color-light']} !important;
  }
  &.dragging {
    box-shadow: 0 0 0 3px ${props => props.theme['@primary-color']};
  }
  &:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-xs']};
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.75;
  }
  &.correct {
    color: ${props => props.theme['@green']} !important;
    background: ${props => props.theme['@green-light']} !important;
  }
  &.incorrect {
    color: ${props => props.theme['@red']} !important;
    background: ${props => props.theme['@red-light']} !important;
  }
`
export const SourceContainer: any = styled.div`
  padding: ${props => props.theme['@size-s']};
  padding-right: 0;
  flex: 0.4;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  > div:not(${Source}) {
    width: auto !important;
  }
`
export const DestinationContainer: any = styled.div`
  flex: 0.6;
  padding: ${props => props.theme['@size-s']};
  padding-left: 0;
  flex-shrink: 0;
`
export const DestinationHeader: any = styled.div`
  padding: ${props => props.theme['@size-xs']};
  display: flex;
  align-items: center;
  width: 100%;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
  > :first-child {
    min-width: 0;
    flex: 1;
  }
`
export const DestinationFooter: any = styled.div`
  transition: background 0.25s linear;
  background: ${props => props.theme['@gray-1']};
  min-height: ${props => props.theme['@size-l']};
  border-bottom-left-radius: ${props => props.theme['@border-radius-base']};
  border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  position: relative;
  > div:not(${Source}) {
    margin: 0 !important;
  }
`
export const DestinationPlaceholder: any = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
`
export const DestinationBlank: any = styled.span`
  display: inline-block;
  height: 1.75em;
  padding: ${props => props.theme['@size-xs']};
  line-height: 1;
  vertical-align: bottom;
  border-radius: ${props => props.theme['@border-radius-base']};
  transition:
    background 0.25s linear,
    color 0.25s linear;
  background: ${props => props.theme['@gray-2']};
  color: ${props => props.theme['@gray-7']};
  &:not(:first-child) {
    margin-left: 0.4em;
  }
  &:not(:last-child) {
    margin-right: 0.4em;
  }
  &:empty {
    width: 75px;
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@text-color']};
  }
`
export const Destination: any = styled(AnswerWrapper)`
  border: solid 1px ${props => props.theme['@border-color-base']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition:
    box-shadow 0.25s ease-in-out,
    border 0.25s linear;
  box-shadow: 0 0 0 0 ${props => props.theme['@primary-color']};
  &:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-xs']};
  }
  &.dropping {
    box-shadow: 0 0 0 3px ${props => props.theme['@primary-color']};
    ${DestinationFooter} {
      background: ${props => props.theme['@primary-color-light']};
    }
    ${DestinationBlank} {
      background: ${props => props.theme['@primary-color-light']};
    }
  }
  &.hasValue {
    border: solid 1px ${props => props.theme['@gray-2']};
    ${DestinationFooter} {
      ${Source} {
        background: ${props => props.theme['@gray-2']};
        color: ${props => props.theme['@gray-7']};
      }
      ${DestinationPlaceholder} {
        display: none;
      }
    }
  }
  &.correct {
    ${DestinationFooter} ${Source}, ${DestinationBlank} {
      background: ${props => props.theme['@green-light']};
      color: ${props => props.theme['@green']};
    }
  }
  &.incorrect {
    ${DestinationFooter} ${Source}, ${DestinationBlank} {
      background: ${props => props.theme['@red-light']};
      color: ${props => props.theme['@red']};
    }
  }
  &.multiple {
    ${DestinationHeader} {
      text-align: center;
      background: ${props => props.theme['@gray-1']};
    }
    ${DestinationFooter} {
      background: none;
      padding: ${props => props.theme['@size-s']};
      min-height: ${props => props.theme['@size-xxl']};
    }
    &.hasValue ${DestinationHeader} {
      background: ${props => props.theme['@gray-2']};
    }
  }
`
