import { TableProps, Table as AntTable } from 'antd'
import React from 'react'

import { size } from '../common'
import { styled } from '../styled'

export function Table<RecordType extends object>(props: TableProps<RecordType>) {
  return (
    <Wrapper>
      <AntTable<RecordType> pagination={false} {...props} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-table-thead > tr > th {
    background: transparent;
    font-weight: bold;
    font-size: ${size('@size-s')};
    padding-top: ${props => props.theme['@size-xs']} !important;
    padding-bottom: ${props => props.theme['@size-xs']} !important;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .ant-table-tbody > tr > td {
    background: transparent !important;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .ant-table-sticky-header {
    background: ${props => props.theme['@gray-0']};
  }
`

export { TableProps } from 'antd'
