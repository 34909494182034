import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { keyframes } from 'styled-components'

import { Attribution } from './Attribution'
import { s, styled } from '../../'
import { MinusCircle } from '../../../icons'

export enum ImageState {
  Initial,
  Loading,
  Loaded,
  Failed,
}

export type ImageAttributionSide = 'top' | 'bottom' | 'left' | 'right'
export type ImageAttributionAlign = 'start' | 'end'
export type ImageAttributionPlacement = 'inside' | 'outside'
export interface ImageAttribution {
  text: React.ReactNode
  side: ImageAttributionSide
  align: ImageAttributionAlign
  placement: ImageAttributionPlacement
}
export interface BaseImageProps {
  /** To help with showing a placeholder for the image before it loads. */
  dimensions?: [number, number]
  /** To help with showing a placeholder for the image before it loads. */
  dominantColor?: string
  /** The URL to the image. */
  src: string
  /** The alt text for the image. */
  alt?: string
  /** An optional caption to display below the image. */
  caption?: React.ReactElement | string
  /** Optional attribution to give for the image. */
  attribution?: ImageAttribution
  /** The border-radius for the image. */
  radius?: keyof s.Theme['radii'] | null
  /** If you want to load the image immediately on page load, specify that here. */
  eager?: boolean
  /** Any overlay you'd like to display on top of the image. */
  overlay?: React.ReactElement
  /** @internal Force the image state to a specific value. */
  __state?: ImageState
  style?: React.CSSProperties
  className?: string
}
export interface ImageOrVideoWrapperProps extends BaseImageProps {
  children: React.ReactElement<
    React.HTMLAttributes<HTMLImageElement | HTMLVideoElement>,
    'img' | 'video'
  >
  loadProp?: 'onLoad' | 'onCanPlay'
  containerStyle?: React.CSSProperties
}
export function ImageOrVideoWrapper({
  children,
  attribution,
  dimensions,
  dominantColor,
  style,
  className,
  caption,
  radius = null,
  overlay,
  __state,
  loadProp = 'onLoad',
  containerStyle,
  ...rest
}: ImageOrVideoWrapperProps) {
  const [_state, setState] = React.useState<ImageState>(ImageState.Loading)
  const [loadAnimationDone, setLoadAnimationDone] = React.useState(true)
  const state = __state !== undefined ? __state : _state
  React.useEffect(() => {
    setState(ImageState.Loading)
  }, [rest.src])
  React.useEffect(() => {
    if (state === ImageState.Loaded) {
      const handle = setTimeout(() => {
        setLoadAnimationDone(true)
      }, 500)
      return () => clearTimeout(handle)
    } else if (state === ImageState.Loading) {
      setLoadAnimationDone(false)
    }
  }, [state])

  const isLoading =
    state === ImageState.Loading || (state === ImageState.Loaded && !loadAnimationDone)
  const isLoadingOut = isLoading && state !== ImageState.Loading
  const isError = state === ImageState.Failed
  const isVector = rest.src.split('?')[0]?.endsWith('.svg')

  return (
    <Container className={className} style={style} $isVector={!!isVector}>
      <div style={{ position: 'relative' }}>
        <ImageContainer
          style={
            {
              borderRadius: radius !== null ? s.var(`radii.${radius}`) : undefined,
              background:
                state === ImageState.Loaded
                  ? 'transparent'
                  : dominantColor || s.color('gray.subtle'),
              transitionDuration: state === ImageState.Loaded ? '0.5s' : undefined,
              width: isVector
                ? '100%'
                : state !== ImageState.Loaded && dimensions
                ? dimensions[0]
                : undefined,
              ...containerStyle,
            } as any
          }
        >
          {isLoading || isError ? (
            <OverlayContainer $animated={isLoading} className={isLoadingOut ? 'out' : ''}>
              {isError ? <MinusCircle /> : undefined}
            </OverlayContainer>
          ) : null}
          <AnimatePresence>{overlay}</AnimatePresence>
          {React.cloneElement(children, {
            ...rest,
            style: {
              ...(dimensions && state !== ImageState.Loaded
                ? {
                    width: '100%',
                    aspectRatio: `${dimensions[0]}/${dimensions[1]}`,
                  }
                : state === ImageState.Failed
                ? {
                    minWidth: 200,
                    minHeight: 125,
                  }
                : undefined),
              background: 'transparent',
              opacity: state === ImageState.Loaded || state === ImageState.Initial ? 1 : 0,
            },
            onError: () => {
              setState(ImageState.Failed)
            },
            [loadProp]: () => {
              setState(ImageState.Loaded)
            },
          })}
        </ImageContainer>
        {attribution && (attribution.placement !== 'outside' || attribution.side !== 'bottom') ? (
          <Attribution {...attribution} children={attribution.text} radius={radius} />
        ) : null}
      </div>
      {attribution?.placement === 'outside' && attribution?.side === 'bottom' ? (
        <Attribution {...attribution} children={attribution.text} radius={radius} />
      ) : null}
      {caption ? (
        <CaptionContainer
          style={{
            padding: `0 ${radius === null ? '0' : s.var(`radii.${radius}`)}`,
            marginTop: `calc(${s.var(`radii.${radius || '1'}`)} * 0.75)`,
          }}
          children={caption}
        />
      ) : null}
    </Container>
  )
}

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement>, BaseImageProps {}
function _Image(props: ImageProps, ref: React.ForwardedRef<HTMLImageElement>) {
  return (
    <ImageOrVideoWrapper {...props}>
      <img ref={ref} loading={props.eager ? undefined : 'lazy'} />
    </ImageOrVideoWrapper>
  )
}

export const Image = React.forwardRef(_Image)

const ImageContainer = styled.div`
  position: relative;
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  transition: background 0s ease;
  > img,
  video {
    width: inherit;
    max-width: 100%;
    display: block;
    opacity: 1;
    transition: opacity 0.5s ease;
  }
`
export const overlayAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 75% 100%;
  }
  0% {
    background-position: 0% 0%;
  }
`
const OverlayContainer = styled.div<{ $animated?: boolean }>`
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: linear-gradient(
    160deg,
    ${s.var('color.overlayWhite.4')} 10%,
    ${s.var('color.overlayBlack.11')} 100%
  );
  background-size: 200% 200%;
  animation: ${overlayAnimation} 5s ease infinite;
  animation-play-state: ${props => (props.$animated ? 'running' : 'paused')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${s.size('7')};
  color: ${s.var('color.overlayBlack.8')};
  transition: opacity 0s ease;
  opacity: 0.35;
  box-shadow: inset 0 0 ${s.size('4')} ${s.var('color.overlayBlack.4')};
  &.out {
    transition-duration: 0.5s;
    opacity: 0;
  }
  > svg {
    animation: ${s.animations.slideUpAndFade} 0.4s ${s.var('curves.exponential')};
  }
`
const Container = styled.div<{ $isVector: boolean }>`
  width: ${props => (props.$isVector ? '100%' : 'min-content')};
  max-width: 100%;
  position: relative;
`
const CaptionContainer = styled.div`
  font-size: ${s.size('1')};
  color: ${s.color('gray.secondary')};
  line-height: ${s.var('lineHeights.default')};
  margin-top: ${s.size('0.1')};
`
