import { useViewerContext } from '@thesisedu/feature-users-react'
import { Form, Input, useTheme, VSpaced } from '@thesisedu/react'
import { Mail } from '@thesisedu/react/icons'
import React from 'react'

import { SignupChildProps } from './TrialRequestSignupPage'

export interface FormValues {
  email: string
  password: string
}
export function TrialRequestEmailSignup({ onComplete }: SignupChildProps) {
  const viewer = useViewerContext(true)
  const theme = useTheme()
  const form = Form.useForm<FormValues>()
  React.useEffect(() => {
    if (viewer.email) {
      form.reset({
        email: viewer.email!,
      })
    }
  }, [viewer])

  return (
    <Form
      form={form}
      onFinish={values => {
        return onComplete(values)
      }}
    >
      <VSpaced align={'stretch'} space={'@size-m'}>
        <Form.Item name={'email'} label={'Email Address'}>
          <Input
            disabled
            style={{ background: 'transparent', padding: 0, color: theme['@text-color'] }}
          />
        </Form.Item>
        <Form.Item
          name={'password'}
          label={'Password'}
          required={{ value: true, message: 'A password is required.' }}
          minLength={{ value: 8, message: 'Your password must be at least 8 characters long.' }}
        >
          <Input type={'password'} placeholder={'Create your Password'} />
        </Form.Item>
        <Form.Submit type={'default'} icon={<Mail />} children={'Create Password & Login'} />
      </VSpaced>
    </Form>
  )
}
