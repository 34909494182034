import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateSubscriptionMutation as useMutation } from '../schema'

export const useCreateSubscriptionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that subscription.'),
    refetchQueries: ['subscriptions'],
    ...opts,
  })
