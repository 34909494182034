import { LoadingOutlined } from '@ant-design/icons'
import {
  CalculatedAssignmentViewer,
  useAssignmentTemplateAndConfiguration,
  AssignmentViewerContext,
} from '@thesisedu/feature-assignments-react'
import { Segment } from '@thesisedu/feature-courses-react'
import { ErrorIndicator } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { useAssignmentForSegment } from './useAssignmentForSegment'

export interface CalculatedAssignmentViewerWrapperProps {
  segment: Segment
  teacherView?: boolean
}

export function CalculatedAssignmentViewerWrapper({
  teacherView,
  segment,
}: CalculatedAssignmentViewerWrapperProps) {
  const {
    assignment,
    classId,
    loading: assignmentLoading,
    error: assignmentError,
  } = useAssignmentForSegment(segment.id)

  const {
    configuration,
    template,
    loading: templateLoading,
    error: templateError,
  } = useAssignmentTemplateAndConfiguration(segment.config?.templateId, assignment)

  if (templateLoading || assignmentLoading) {
    return <Result icon={<LoadingOutlined size={75} />} title={'Preparing assignment...'} />
  } else if (!template || !configuration?.questions || templateError || assignmentError)
    return <ErrorIndicator />

  return (
    <AssignmentViewerContext.Provider
      value={{
        assignment,
        templateId: template.id,
        teacherView,
        configuration,
        classId,
      }}
      children={<CalculatedAssignmentViewer />}
    />
  )
}
