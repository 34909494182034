import {
  ClassConfigurationWithAssignmentSettings,
  DEFAULT_GRADING_MODE,
} from '@thesisedu/feature-assignments-core'
import { BrochureLink } from '@thesisedu/feature-react'
import { Text, LoadingIndicator, HSpaced, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { ChangeGradingModeButton } from './ChangeGradingModeModal'
import { GRADING_MODE_MAP } from './modes'
import { useClassAssignmentCategoriesQuery } from '../schema'

export interface GradingModeOverviewProps {
  classId: string
}
export function GradingModeOverview({ classId }: GradingModeOverviewProps) {
  const { data, loading } = useClassAssignmentCategoriesQuery({
    variables: {
      classId,
    },
  })
  const config: ClassConfigurationWithAssignmentSettings | undefined =
    data?.node?.__typename === 'Class' ? data.node.configuration : undefined
  const gradingMode = config?.gradingMode ?? DEFAULT_GRADING_MODE
  if (config) {
    return (
      <HSpaced align={'flex-start'}>
        <VSpaced align={'flex-start'}>
          <Text level={'l'}>
            Grades are being calculated using{' '}
            <strong>{GRADING_MODE_MAP[gradingMode].gradedSuffix}.</strong>
          </Text>
          <Text children={GRADING_MODE_MAP[gradingMode].description} />
        </VSpaced>
        <VSpaced align={'flex-end'} style={{ flexShrink: 0 }}>
          <ChangeGradingModeButton
            classId={classId}
            gradingMode={gradingMode}
            configuration={config}
          />
          <BrochureLink to={'support/portal/grade-calculation'} target={'_blank'}>
            More Info on Grading
          </BrochureLink>
        </VSpaced>
      </HSpaced>
    )
  } else if (loading) {
    return <LoadingIndicator.Labeled size={'s'} label={'Loading grading settings...'} />
  } else {
    return null
  }
}
