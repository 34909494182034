import { useFeatureRoot } from '@thesisedu/feature-react'
import { SAMLButtons } from '@thesisedu/feature-users-react/dist/web/routes/SAMLButtons'
import { RegisterForm } from '@thesisedu/feature-users-web'
import { Block, Body, H1, H1Subtitle, Link } from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import React from 'react'

export function RegisterStudent() {
  const { name } = useFeatureRoot().appOptions
  return (
    <>
      <H1 isBlock={false}>Welcome to {name}</H1>
      <Block marginBottom={'@size-l'}>
        <H1Subtitle isBlock={false}>Create an account to start learning.</H1Subtitle>
      </Block>
      <RegisterForm />
      <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
        <SAMLButtons />
      </Block>
      <Link to={'../../login'}>
        <Body color={'@text-color-secondary'}>
          <ArrowLeft /> Go Back
        </Body>
      </Link>
    </>
  )
}
