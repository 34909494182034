import { MaybeElement } from './nodes'
import { EditorValue } from './types'

export function find(value: EditorValue | MaybeElement[], id: string): MaybeElement | null {
  function findNode(node: MaybeElement, id: string): MaybeElement | null {
    if (node.id === id) {
      return node
    } else if (node.children) {
      for (const child of node.children) {
        const found = findNode(child, id)
        if (found) return found
      }
    }
    return null
  }

  if (Array.isArray(value)) {
    return value.find(v => findNode(v, id)) || null
  } else return findNode(value.root, id)
}
