import { ReactFeature } from '@thesisedu/feature-react'
import { MutateValidTagTypes } from '@thesisedu/feature-tags-react'

export default function addValidTagTypes(feature: ReactFeature) {
  feature.hookManager.registerMutateHook<MutateValidTagTypes>(
    'feature-tags-react:valid-tag-types',
    types => {
      return types.filter(type => !type.type.startsWith('SS_'))
    },
  )
}
