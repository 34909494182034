import React from 'react'

import { EditorProps } from './lexical/Editor'
import { debug } from './log'

export function useDefaultValueKey(id: string, defaultValue?: EditorProps['defaultValue']) {
  return React.useMemo(() => {
    debug('updating defaultValueKey for %s', id)
    return Math.random().toString()
  }, [defaultValue])
}
