import { useResource } from '@thesisedu/feature-react'
import { Form, Select } from '@thesisedu/ui'
import React from 'react'

import { CoverPageResource } from './types'

export interface CoverPageConfiguration<Options extends object = object> {
  type: string
  options: Options
}

export interface CoverPageSettingsProps {
  prefix: string
}
export function CoverPageSettings(props: CoverPageSettingsProps) {
  const { prefix } = props
  const resources = useResource<CoverPageResource>('CoverPage')
  const { form } = Form.useFormContext()
  const selectedType = form.watch(`${prefix}.type`)
  const selectedResource = resources.find(r => r.identifier === selectedType)
  return (
    <>
      <Form.Select
        name={`${prefix}.type`}
        label={'Cover Page'}
        placeholder={'Select a cover page template'}
      >
        {resources.map(resource => {
          return (
            <Select.Item
              key={resource.identifier}
              value={resource.identifier!}
              children={resource.label}
            />
          )
        })}
      </Form.Select>
      {selectedResource?.renderForm({
        ...props,
        prefix: `${prefix}.options`,
      })}
    </>
  )
}
