import { MediaGridItem } from '@thesisedu/feature-media-react/search'
import { SearchResultGridItem } from '@thesisedu/feature-search-react'
import { DATE_FORMATS } from '@thesisedu/react'
import { TopRightIcon, TopRightIconProps } from '@thesisedu/react/dist/grid'
import { styled, s, HSpaced, Text, Block } from '@thesisedu/ui'
import { Star } from 'iconoir-react'
import { omit } from 'lodash'
import moment from 'moment'
import React from 'react'

import { AssignmentSubmissionActionsDropdown } from './AssignmentSubmissionActionsDropdown'
import { SubmissionsGridAssignment } from './AssignmentSubmissionsGrid'
import { SubmissionWithMediaFragment } from './types'
import { Grade } from '../Grade'
import { AssignmentThumbnail } from '../grid'

export interface AssignmentSubmissionGridItemProps {
  assignment: SubmissionsGridAssignment
  item: SubmissionWithMediaFragment
  index: number
  onUpdate: () => void
}
export function AssignmentSubmissionGridItem({
  assignment,
  item,
  index,
  onUpdate,
}: AssignmentSubmissionGridItemProps) {
  const topRightIconProps: TopRightIconProps | undefined = item.submittedAt
    ? {
        icon: <Star />,
        color: '@blue',
        tooltip: `Submitted ${moment(item.submittedAt).format(DATE_FORMATS.FULL)}`,
      }
    : undefined
  const description = item.gradedAt ? (
    <Text level={null} color={'blue'}>
      Graded {moment(item.gradedAt).format(DATE_FORMATS.FULL)}
    </Text>
  ) : item.submittedAt ? (
    <Text level={null} color={'blue'}>
      Submitted {moment(item.submittedAt).format(DATE_FORMATS.FULL)}
    </Text>
  ) : (
    `Created ${moment(item.createdAt).format(DATE_FORMATS.FULL)}`
  )
  const title = (
    <HSpaced>
      <Text>Attempt {index}</Text>
      <Block left>
        <AssignmentSubmissionActionsDropdown submission={item} onUpdate={onUpdate} />
      </Block>
    </HSpaced>
  )
  const topLeft =
    item.gradedAt && item.grade != null ? (
      <GradeContainer>
        <Grade grade={item.grade} hideProgress />
      </GradeContainer>
    ) : null
  if (item.media) {
    return (
      <MediaGridItem
        media={item.media}
        title={title}
        topRightIcon={topRightIconProps}
        description={description}
      >
        {topLeft}
      </MediaGridItem>
    )
  } else {
    return (
      <SearchResultGridItem
        title={title}
        description={description}
        image={
          <AssignmentThumbnail assignment={omit(assignment, ['dueAt', 'openAt'])} compact>
            {topLeft}
            {topRightIconProps ? <TopRightIcon {...topRightIconProps} /> : null}
          </AssignmentThumbnail>
        }
      />
    )
  }
}

const GradeContainer = styled.div`
  position: absolute;
  bottom: ${s.size('xs')};
  left: ${s.size('xs')};
`
