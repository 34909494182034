import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { VideoCamera } from '@thesisedu/ui/icons'
import React from 'react'

import { PerformanceElement } from './PerformanceElement'
import { PerformanceConfig } from './common'
import MediaReactFeature from '../../../MediaReactFeature'

/**
 * This is named Performance internally to remain consistent with the
 * assignments feature and the question type there. It will default to
 * "Recording" as the name in all cases, though.
 */

export const definition = {
  identifier: 'Performance',
  title: 'Recording',
  icon: <VideoCamera />,
  weight: 20,
  group: SimpleWidget.ElementsGroup.Media,
  defaultConfig: { version: 1, mediaId: null },
  draggerUsesFirstChild: true,
  isDynamic: true,
  settingsDropdownItems: [
    {
      identifier: 'alignment',
      weight: 0,
      element: SimpleWidget.AlignmentSetting,
    },
  ],
  element: PerformanceElement,
} satisfies SimpleWidget.SimpleWidgetDefinition<PerformanceConfig, 'Performance'>

export default function (feature: MediaReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(definition))
}
