import { SegmentGenerateData } from '@thesisedu/feature-course-types'
import {
  ReactPDFGeneratorProps,
  ReactPDFGeneratorResource,
  DefaultPageStyle,
  ContentPage,
} from '@thesisedu/feature-pdf-generation-react'
import { useResource, useFeatureRoot } from '@thesisedu/feature-react'
import { Legacy } from '@thesisedu/feature-widgets-react'
import { PageHead, styled } from '@thesisedu/web'
import React from 'react'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { ReadOnlyEditor } from '../editor/Editor'
import { CoverPageResource } from '../pdf_generation/types'
import { PDFGenerationWidget } from '../resources/widgets/PDFGeneration/common'
import { SegmentContext } from '../segment/Segment'

export function SegmentGenerateComponent({
  data,
  onReady,
}: ReactPDFGeneratorProps<SegmentGenerateData>) {
  const root = useFeatureRoot()
  const coverResources = useResource<CoverPageResource>('CoverPage')
  const widget = data.pdfGeneratorWidget as PDFGenerationWidget
  const config = Legacy.getConfig(widget)
  const coverPageResource = config?.coverPage
    ? coverResources.find(r => r.identifier === config.coverPage!.type)
    : undefined
  React.useEffect(() => {
    // Wait for any images to load.
    setTimeout(() => {
      onReady()
    }, 5000)
  }, [])
  return (
    <SegmentContext.Provider value={{ id: data.segmentId }}>
      <>
        <PageHead title={data.segment.name} />
        <DefaultPageStyle
          product={root.appOptions.name}
          domain={root.appOptions.domain}
          hasCoverPage={!!coverPageResource && !!config?.coverPage}
        />
        {coverPageResource && config?.coverPage
          ? coverPageResource.renderContent({
              options: config.coverPage.options,
              ...data,
            })
          : null}
        <StyledContentPage>
          <ReadOnlyEditor
            id={widget.id}
            defaultValue={Legacy.getBlockValueFromAnyValue(data.widgetContents)}
          />
        </StyledContentPage>
      </>
    </SegmentContext.Provider>
  )
}

const StyledContentPage = styled(ContentPage)`
  .widget-container {
    max-width: none !important;
  }
`

export function addPdfGeneratorResource(feature: CoursesReactFeature) {
  feature.resources.addResource<ReactPDFGeneratorResource<SegmentGenerateData>>({
    type: 'ReactPDFGenerator',
    identifier: 'segment',
    generateComponent: SegmentGenerateComponent,
  })
}
