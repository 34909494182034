import { LexicalNestedComposer } from '@lexical/react/LexicalNestedComposer'
import { LexicalEditor } from 'lexical'
import React from 'react'

import { EditorPlugins, NestedEditorPluginsProvider, PluginLevel } from './EditorPlugins'
import { useEditorContext } from './context/EditorContext'
import { NestedEditorPlugin, NestedEditorPluginProps } from './plugins/NestedEditorPlugin'
import { error } from '../log'

export interface NestedEditorProps extends Partial<NestedEditorPluginProps> {
  level: PluginLevel
  editor: LexicalEditor
  skipCollabChecks?: true | undefined
  placeholder?: React.ReactElement | string | false
  minHeight?: number
  /**
   * If this is true, the toolbar will be shown and will stick to the top of
   * the page. If this is enabled, the floating text format toolbar will be
   * disabled.
   */
  showToolbar?: boolean
}
export function NestedEditor({
  level,
  editor,
  hostNodeKey,
  placeholder,
  skipCollabChecks,
  forwardFocusToParent = level <= PluginLevel.Bare,
  advanceToNextOnTab = level <= PluginLevel.Bare,
  children,
  minHeight,
  showToolbar,
}: React.PropsWithChildren<NestedEditorProps>) {
  const { readOnly } = useEditorContext(true)
  const toolbarHostRef = React.useRef<HTMLDivElement>(null)
  if (!editor) {
    error(
      'NestedEditor editor cannot be found. Did you forget to set nestedEditorKeys on your widget?',
    )
    throw new Error('NestedEditor editor cannot be found.')
  }
  React.useEffect(() => {
    editor.setEditable(!readOnly)
  }, [readOnly])
  return (
    <>
      {showToolbar ? <div style={{ display: 'unset' }} ref={toolbarHostRef} /> : null}
      <div style={{ position: 'relative', minHeight }}>
        <LexicalNestedComposer initialEditor={editor} skipCollabChecks={skipCollabChecks}>
          <NestedEditorPluginsProvider>
            <EditorPlugins
              level={level}
              placeholder={placeholder}
              toolbarHost={showToolbar ? toolbarHostRef : undefined}
            />
            {hostNodeKey ? (
              <NestedEditorPlugin
                hostNodeKey={hostNodeKey}
                forwardFocusToParent={forwardFocusToParent}
                advanceToNextOnTab={advanceToNextOnTab}
              />
            ) : null}
            {children}
          </NestedEditorPluginsProvider>
        </LexicalNestedComposer>
      </div>
    </>
  )
}
