import { Cell } from './Cell'
import { Checkbox } from './Checkbox'
import { Column, ColumnGroup } from './Column'
import { DragButton } from './DragButton'
import { ImperativeTable as ImperativeTableC } from './ImperativeTable'
import { Row } from './Row'
import { Table } from './Table'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import { useAutoSortedImperativeTable } from './useAutoSortedImperativeTable'
import { useDragAndDrop } from './useDragAndDrop'
import { withSubComponents } from '../..'

export * from './Cell'
export * from './Column'
export * from './Row'
export * from './Table'
export * from './TableBody'
export * from './TableHeader'
export * from './Checkbox'
export {
  ImperativeTableColumn,
  ImperativeTableProps,
  ImperativeTableRecord,
  ImperativeTableRenderCellProps,
} from './ImperativeTable'
export * from './useAutoSortedImperativeTable'

export default withSubComponents(Table, {
  Checkbox,
  DragButton,
  Cell,
  Column,
  ColumnGroup,
  Row,
  Body: TableBody,
  Header: TableHeader,
  useDragAndDrop,
})

export const ImperativeTable = withSubComponents(ImperativeTableC, {
  useAutoSort: useAutoSortedImperativeTable,
})
