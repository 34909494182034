import { styled } from '@thesisedu/web'
import React from 'react'

import { CommentBulkActions } from './CommentBulkActions'
import { CommentModerationFilterButtons } from './CommentModerationFilterButtons'
import { CommentsTable } from './CommentsTable'
import { CommentModerationFilters } from './types'
import { CommentApproval } from '../schema'

export function CommentModeration() {
  const [filters, setFilters] = React.useState<CommentModerationFilters>({
    approvals: [CommentApproval.Unmoderated],
  })
  const [selectedComments, setSelectedComments] = React.useState<string[]>([])
  return (
    <Container>
      <HeaderContainer>
        <CommentBulkActions selectedIds={selectedComments} />
        <CommentModerationFilterButtons filters={filters} onChange={setFilters} />
      </HeaderContainer>
      <CommentsTable
        filters={filters}
        rowSelection={{
          selectedRowKeys: selectedComments,
          onChange: keys => setSelectedComments(keys.map(i => i.toString())),
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 75vh;
  > :last-child {
    flex: 1;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-m']};
  > * {
    margin-right: ${props => props.theme['@size-s']};
  }
`
