import { useFeatureRoot } from '@thesisedu/feature-react'
import React from 'react'

import { UpdateAvailableToast } from './UpdateAvailableToast'
import { reloadPageWithoutCache } from './reloadPageWithoutCache'
import { setupCheckForUpdates } from './setup'
import { FeatureWeb } from '../FeatureWeb'

export function CheckForUpdates() {
  const root = useFeatureRoot() as FeatureWeb
  const release = root.appOptions.release
  const [hasUpdate, setHasUpdate] = React.useState(false)
  React.useEffect(() => {
    if (release) {
      setupCheckForUpdates(release, () => setHasUpdate(true))
    }
  }, [])

  if (hasUpdate) {
    return <UpdateAvailableToast onUpdate={reloadPageWithoutCache} />
  } else {
    return null
  }
}
