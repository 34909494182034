import { ClassConfigurationWithAssignmentSettings } from '@thesisedu/feature-assignments-core'
import { useUpdateClassMutation } from '@thesisedu/feature-classes-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Table } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { CategoryMapRow } from './CategoryMapRow'
import { AssignmentCategoryMapFragment, BasicAssignmentCategoryFragment } from '../schema'

export interface CategoryMapTableProps {
  categories: BasicAssignmentCategoryFragment[]
  classConfiguration?: ClassConfigurationWithAssignmentSettings
  classId: string
  categoryMap: AssignmentCategoryMapFragment[]
  onSavingChange: (saving: boolean) => void
}
export function CategoryMapTable({
  categories,
  categoryMap,
  classId,
  classConfiguration,
  onSavingChange,
}: CategoryMapTableProps) {
  const { name: appName, icon } = useFeatureRoot().appOptions
  const [update, { loading }] = useUpdateClassMutation({
    refetchQueries: ['classAssignmentCategories'],
    awaitRefetchQueries: true,
  })
  return (
    <Table data-testid={'CategoryMapTable'}>
      <Table.Header>
        <Table.Column
          isRowHeader
          icon={
            <img src={icon()} style={{ width: '1em', height: '1em' }} alt={`${appName} Logo`} />
          }
          style={{ width: '50%' }}
        >
          {appName} Category
        </Table.Column>
        <Table.Column icon={<ArrowRight />} style={{ width: '50%' }}>
          Mapped to
        </Table.Column>
      </Table.Header>
      <Table.Body>
        {categoryMap.map(({ name, mappedTo }) => {
          return (
            <CategoryMapRow
              key={name}
              categories={categories}
              categoryName={name}
              mappedTo={mappedTo ?? undefined}
              loading={loading}
              onMappedToChange={async mappedTo => {
                onSavingChange(true)
                try {
                  await update({
                    variables: {
                      input: {
                        id: classId,
                        patch: {
                          configuration: {
                            ...classConfiguration,
                            assignmentCategoryMap: {
                              ...classConfiguration?.assignmentCategoryMap,
                              [name]: mappedTo,
                            },
                          } satisfies ClassConfigurationWithAssignmentSettings,
                        },
                      },
                    },
                  })
                } finally {
                  onSavingChange(false)
                }
              }}
            />
          )
        })}
      </Table.Body>
    </Table>
  )
}
