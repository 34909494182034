import { DeepLinkResource } from '@thesisedu/feature-react'

import { CoursesReactFeature } from '../../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  const { SegmentDeepLinkRemapper } = require('./DeepLinkRemapper')
  feature.resources.addResource<DeepLinkResource>({
    type: 'DeepLink',
    identifier: 'segment',
    params: [
      { identifier: 'classId', required: true },
      { identifier: 'id', required: true },
    ],
    Component: SegmentDeepLinkRemapper,
  })
}
