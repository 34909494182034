import { TagsFilter } from '@thesisedu/feature-tags-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { FilterButton, styled } from '@thesisedu/web'
import { Input } from 'antd'
import React from 'react'

import { FilterOperation, SegmentType, TagFilterInput as TagSearchInput } from '../schema'

const Container = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: ${props => props.theme['@size-xs']};
    white-space: nowrap;
  }
  > input {
    flex-grow: 1;
  }
`

export enum SegmentUserFilter {
  Me = 'me',
  BusinessU = 'businessu',
}
export interface SegmentFilterValues {
  type?: SegmentType
  user: SegmentUserFilter
  tags: TagSearchInput[]
  tagsOp: FilterOperation
  name?: string
  hiddenFromTeachers?: boolean
}

export interface SegmentFiltersProps {
  filters: SegmentFilterValues
  onChange: (filters: SegmentFilterValues) => any
  hideCreator?: boolean
  hideGroup?: boolean
  hideType?: boolean
  hideTags?: boolean
  hideHidden?: boolean
  tagProps?: object
}
export const SegmentFilters: React.FC<React.PropsWithChildren<SegmentFiltersProps>> = ({
  filters,
  onChange,
  hideCreator,
  hideGroup,
  hideType,
  hideTags,
  hideHidden,
  tagProps,
}) => {
  const viewer = useViewerContext(false)
  const isAdmin = viewer?.group === 'ADMIN'
  return (
    <Container>
      {!hideType ? (
        <FilterButton
          label={'Type'}
          options={[
            { label: 'Section', value: 'Section' },
            { label: 'Assignment', value: 'Assignment' },
            ...(hideGroup ? [] : [{ label: 'Group', value: 'Group' }]),
          ]}
          onChange={type => onChange({ ...filters, type: (type as SegmentType) || undefined })}
          value={filters.type}
        />
      ) : null}
      {isAdmin && !hideHidden ? (
        <FilterButton
          label={'Hidden'}
          options={[
            { value: 'no', label: 'No' },
            { value: 'yes', label: 'Yes' },
          ]}
          onChange={hiddenFromTeachers =>
            onChange({ ...filters, hiddenFromTeachers: hiddenFromTeachers === 'yes' })
          }
          value={filters.hiddenFromTeachers ? 'yes' : 'no'}
        />
      ) : null}
      {!hideCreator && (
        <FilterButton
          label={'Creator'}
          options={[
            { label: 'Created by me', value: 'me' },
            { label: 'Created by BusinessU', value: 'businessu' },
          ]}
          onChange={user => {
            if (user) {
              onChange({ ...filters, user: user as SegmentUserFilter })
            }
          }}
          value={filters.user}
        />
      )}
      {!hideTags ? (
        <TagsFilter
          tags={filters.tags as any[]}
          tagsOp={filters.tagsOp}
          onChange={(tags, tagsOp) =>
            onChange({ ...filters, tags, tagsOp: tagsOp as FilterOperation })
          }
          {...tagProps}
        />
      ) : null}
      <Input.Search
        placeholder={isAdmin ? 'Search Content...' : 'Search My Content...'}
        value={filters.name}
        allowClear
        onChange={e => {
          onChange({
            ...filters,
            name: e.target.value,
          })
        }}
      />
    </Container>
  )
}
