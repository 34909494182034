import { isEqualString } from '@thesisedu/feature-utils'
import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { assignmentToFormValues } from './assignmentToFormValues'
import { FullAssignment, SettingsFormValues } from './types'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { debug } from '../log'

function normalizeAssignment({ syncs, ...restValues }: SettingsFormValues) {
  return restValues
}

export interface UseAssignmentSettingsFormDirtyStateResult {
  onSaved: () => void
}
export function useAssignmentSettingsFormDirtyState(
  form: Form$.FormInstance<SettingsFormValues>,
  assignment: FullAssignment,
): UseAssignmentSettingsFormDirtyStateResult {
  const { addPrompt, removePrompt } = useGradingModalContext(true)
  const allValues = form.watch()
  const isDirty = !isEqualString(
    normalizeAssignment(assignmentToFormValues(assignment)),
    normalizeAssignment(allValues),
  )
  React.useEffect(() => {
    if (isDirty) {
      try {
        debug(
          'adding prompt for assignment settings: %O -> %O',
          JSON.stringify(assignmentToFormValues(assignment)),
          JSON.stringify(allValues),
        )
      } catch {}
      addPrompt?.('assignment-settings', 'You have unsaved changes to your assignment settings.')
    } else {
      removePrompt?.('assignment-settings')
    }
  }, [isDirty])
  React.useEffect(() => {
    return () => {
      removePrompt?.('assignment-settings')
    }
  }, [])
  return {
    onSaved() {
      removePrompt?.('assignment-settings')
    },
  }
}
