import React from 'react'

import { styled, s } from '../../..'
import { withSubComponents } from '../../../utils/withSubComponents'
import { LabelStyles } from '../../Dropdown'
import { Text, TextProps } from '../../Text'

export interface VerticalNavigationItemProps extends React.HTMLProps<HTMLDivElement> {
  title: string
  icon?: React.ReactElement
  selected?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  as?: string
}
function _VerticalNavigationItem(
  {
    title,
    icon,
    selected,
    disabled,
    style,
    className,
    as,
    children,
    ...rest
  }: VerticalNavigationItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <Container
      style={style}
      className={className}
      $selected={selected}
      $disabled={disabled}
      ref={ref}
      as={as as any}
      {...rest}
    >
      {icon ? <Icon children={icon} /> : null}
      <Title children={title} />
    </Container>
  )
}
const VerticalNavigationItem = React.forwardRef(_VerticalNavigationItem)

function _Title(props: TextProps, ref: React.ForwardedRef<HTMLDivElement>) {
  return <Text {...props} as={'div'} ref={ref} truncate />
}
const Title = React.forwardRef(_Title)

const { css } = s
export const SelectedStyles = css`
  --navigation-item-background: ${s.color('element')};
  --navigation-item-foreground: ${s.color('primary')};
  --navigation-item-wrapped-background: ${s.color('primary')};
  --navigation-item-wrapped-foreground: white;
`
const Container = styled.div<{ $disabled?: boolean; $selected?: boolean }>`
  --navigation-item-background: transparent;
  --navigation-item-foreground: ${s.color('text')};
  --navigation-item-wrapped-background: ${s.color('element')};
  --navigation-item-wrapped-foreground: var(--navigation-item-foreground);
  &:hover {
    --navigation-item-background: ${s.color('element')};
    --navigation-item-wrapped-background: ${s.color('hoverElement')};
  }
  ${s.ifs(props => !!props.$selected)} {
    ${SelectedStyles}
  }
  transition:
    background 0.1s linear,
    color 0.1s linear,
    opacity 0.1s linear;
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  cursor: pointer;
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('xs')} ${s.size('s')};
  background: var(--navigation-item-background);
  color: var(--navigation-item-foreground);
  pointer-events: ${props => (props.$disabled ? 'none' : 'auto')};
`
const Icon = styled.div`
  transition:
    background 0.1s linear,
    color 0.1s linear;
  background: var(--navigation-item-wrapped-background);
  color: var(--navigation-item-wrapped-foreground);
  border-radius: ${s.var('radii.1')};
  width: ${s.size('m')};
  height: ${s.size('m')};
  font-size: ${s.size('s')};
  display: flex;
  align-items: center;
  justify-content: center;
`
const Label = styled.div`
  ${LabelStyles}
  font-size: ${s.size('font.s')};
  padding-left: ${s.size('s')};
`

export default withSubComponents(VerticalNavigationItem, {
  Container,
  Title,
  Icon,
  Label,
})
