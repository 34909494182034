import { useResource } from '@thesisedu/feature-react'
import { H3Alternate, styled } from '@thesisedu/web'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { ClassConfigurationFieldResource } from './types'

export enum HiddenMode {
  IncludeHidden,
  OnlyHidden,
}
interface GroupMap {
  [groupName: string]: ClassConfigurationFieldResource[]
}
export interface ClassConfigurationProps {
  fieldPrefix: string[]
  showHidden?: HiddenMode
  classId?: string
  fields?: ClassConfigurationFieldResource[]
}
export function ClassConfiguration({
  fieldPrefix,
  showHidden,
  classId,
  fields: _fields,
}: ClassConfigurationProps) {
  const resourceFields = useResource<ClassConfigurationFieldResource>('ClassConfigurationField')
  const fields = (_fields || resourceFields).filter(field => {
    const onlyHidden = showHidden === HiddenMode.OnlyHidden
    const includeHidden = showHidden === HiddenMode.IncludeHidden || onlyHidden
    const isHidden = field.hidden
    return (includeHidden || !isHidden) && (!onlyHidden || isHidden)
  })
  const groups = groupBy(fields, 'group') as GroupMap
  const ungroupedFields = fields.filter(f => !f.group)
  return (
    <>
      <GroupContainer>
        {ungroupedFields.map(ungroupedField => {
          const Field = ungroupedField.renderField
          return (
            <Field key={ungroupedField.identifier} fieldPrefix={fieldPrefix} classId={classId} />
          )
        })}
      </GroupContainer>
      {(Object.keys(groups) as string[]).filter(Boolean).map(groupName => (
        <GroupContainer key={groupName || 'ungrouped'}>
          {Object.keys(groups).length === 1 ? null : <H3Alternate>{groupName}</H3Alternate>}
          {orderBy(groups[groupName], 'weight', 'asc').map(groupedField => {
            const Field = groupedField.renderField
            return (
              <Field key={groupedField.identifier} fieldPrefix={fieldPrefix} classId={classId} />
            )
          })}
        </GroupContainer>
      ))}
    </>
  )
}

const GroupContainer = styled.div`
  margin: ${props => props.theme['@size-m']} 0;
`
