import { getFragmentDefinitions } from '@apollo/client/utilities'
import { addFragmentReference, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import {
  DistrictConfigurationFieldProps,
  DistrictConfigurationFieldResource,
} from '@thesisedu/feature-districts-web'
import { Modal, Button } from '@thesisedu/react'
import { Home } from '@thesisedu/react/icons'
import React from 'react'

import { SiteConfiguration } from '../../SiteConfiguration'
import { SitesWebFeature } from '../../SitesWebFeature'
import { DistrictConfigurationWithSiteConfigurationFragmentDoc } from '../../schema'

function Field({ fieldPrefix }: DistrictConfigurationFieldProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Modal visible={visible} onCancel={() => setVisible(false)} title={'Default Site Settings'}>
        <SiteConfiguration fieldPrefix={[...fieldPrefix, 'defaultSiteConfiguration']} />
      </Modal>
      <Button
        children={'Edit Default Site Settings'}
        icon={<Home />}
        onClick={() => {
          setVisible(true)
        }}
      />
    </>
  )
}

export default function (feature: SitesWebFeature) {
  useModifiedFragment(feature.hookManager, 'DistrictConfiguration', (fragment, context) => {
    return addFragmentReference(
      fragment,
      getFragmentDefinitions(DistrictConfigurationWithSiteConfigurationFragmentDoc),
      context,
    )
  })
  feature.resources.addResource<DistrictConfigurationFieldResource>({
    type: 'DistrictConfigurationField',
    identifier: 'defaultSiteConfiguration',
    renderField: Field,
  })
}
