import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { Button, Button$, Dropdown } from '@thesisedu/ui'
import { AddFolder, BookmarkBook, GridAdd, Plus, User, ViewGrid } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CreateGroupModal, CreateGroupModalProps } from '../custom_content/CreateGroupModal'
import { CourseIcon } from '../explore/CourseSelect'
import { useNavigateToSplitView } from '../placement/useNavigateToSplitView'
import { OrderDirection, useListCoursesQuery } from '../schema'
import { CreateSegmentButton } from '../segment/CreateSegmentButton'
import { ClassFragmentWithPermissions } from '../types'

export interface AddContentButtonProps extends Button$.ButtonProps {
  topics?: CreateGroupModalProps['topics']
  termId?: string
}
export function AddContentButton({ topics, termId, ...buttonProps }: AddContentButtonProps) {
  const currentClass = useTeacherSelectedClass<ClassFragmentWithPermissions>(false)?.cls
  const [visible, setVisible] = React.useState(false)
  const [isTopic, setIsTopic] = React.useState(false)
  const { data, loading: loadingCourses } = useListCoursesQuery({
    variables: {
      first: 50,
      orderBy: 'weight',
      orderDirection: OrderDirection.Asc,
      isByoc: false,
    },
  })
  const courses = data?.courses.edges.map(edge => edge.node) ?? []
  const navigate = useNavigate()
  const navigateToSplitView = useNavigateToSplitView()
  const hasTopics = (termId === undefined && topics === undefined) || !!topics?.length
  if (currentClass && !currentClass.canChangeCourseStructure) return null
  return (
    <>
      {termId ? (
        <CreateGroupModal
          termId={termId}
          isTopic={isTopic}
          topics={topics || []}
          onClose={() => setVisible(false)}
          visible={visible}
        />
      ) : null}
      {hasTopics ? (
        <CreateSegmentButton
          children={'Add Content'}
          icon={<Plus />}
          {...buttonProps}
          linkRoot={'/teacher/my-content/custom-content/'}
          groupItems={
            termId
              ? [
                  <Dropdown.Item
                    key={'additional-topic'}
                    icon={<GridAdd />}
                    onClick={() => {
                      setVisible(true)
                      setIsTopic(true)
                    }}
                  >
                    Topic
                  </Dropdown.Item>,
                  ...(topics?.length
                    ? [
                        <Dropdown.Item
                          key={'additional-group'}
                          icon={<AddFolder />}
                          onClick={() => {
                            setVisible(true)
                            setIsTopic(false)
                          }}
                        >
                          Group
                        </Dropdown.Item>,
                      ]
                    : []),
                ]
              : undefined
          }
          additionalItems={[
            <Dropdown.Item
              onClick={() => {
                if (currentClass) {
                  navigateToSplitView({
                    classId: currentClass.id,
                    pane: 'my-content',
                  })
                } else {
                  navigate('/teacher/my-content/custom-content')
                }
              }}
              key={'link-my-content'}
              icon={<User />}
            >
              My Content
            </Dropdown.Item>,
            <Dropdown.Item
              onClick={() => navigate('/teacher/explore')}
              key={'link-explore'}
              icon={<ViewGrid />}
            >
              Explore
            </Dropdown.Item>,
            loadingCourses ? (
              <Dropdown.Item
                loading
                key={'explore-courses'}
                icon={<BookmarkBook />}
                children={'Loading courses...'}
              />
            ) : (
              <Dropdown.SubMenu key={'explore-courses'}>
                <Dropdown.SubMenu.Trigger
                  icon={<BookmarkBook />}
                  children={'Courses...'}
                  disabled={!currentClass}
                />
                <Dropdown.SubMenu.Content>
                  {courses.map(course => {
                    const iconFilename = getFileInfo(course.basicConfiguration?.icon ?? '')
                      ?.filename
                    return (
                      <Dropdown.Item
                        key={course.id}
                        children={course.label ?? course.name}
                        onClick={() => {
                          if (currentClass) {
                            navigateToSplitView({
                              classId: currentClass.id,
                              pane: course.id,
                            })
                          }
                        }}
                        icon={
                          iconFilename ? (
                            <CourseIcon
                              src={iconFilename}
                              alt={`Icon for ${course.label ?? course.name}`}
                            />
                          ) : undefined
                        }
                      />
                    )
                  })}
                </Dropdown.SubMenu.Content>
              </Dropdown.SubMenu>
            ),
          ]}
        />
      ) : (
        <Button
          variant={'primary'}
          icon={<Plus />}
          onPress={() => {
            setVisible(true)
            setIsTopic(true)
          }}
        >
          Add Topic
        </Button>
      )}
    </>
  )
}
