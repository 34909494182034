import { questionSettingsValueToAssignment } from './questionSettingsValueToAssignment'
import { QuestionSettingsValue } from './types'
import { useGradingModalContext } from '../../contexts/GradingModalContext'
import { FullAssignment } from '../../grading/types'
import { useUpdateAssignmentMutation } from '../../queries/useUpdateAssignmentMutation'

export interface UseQuestionSettingsSubmitOpts {
  assignment: FullAssignment | undefined
}
export function useQuestionSettingsSubmit({ assignment }: UseQuestionSettingsSubmitOpts) {
  const { removePrompt } = useGradingModalContext(false) ?? {}
  const [update] = useUpdateAssignmentMutation({
    onCompleted() {
      removePrompt?.('question-settings')
    },
  })
  return async (values: QuestionSettingsValue) => {
    if (!assignment) return
    await update({
      variables: {
        input: {
          id: assignment.id,
          patch: questionSettingsValueToAssignment(values, assignment),
        },
      },
    })
  }
}
