import { RevealAnswers } from '@thesisedu/feature-assignments-core'
import { Form, Switch, VisuallyHidden } from '@thesisedu/ui'
import React from 'react'

import { useOptions } from '../useOptions'

export function AssignmentAttemptsField() {
  const { form } = Form.useFormContext()
  const maxAttempts = form.watch('maxAttempts')
  const revealAnswers = form.watch('revealAnswers')
  const multipleAttemptsEnabled = maxAttempts && maxAttempts > 1
  const { dontHideMaxAttempts } = useOptions()
  const hideMaxAttempts = !dontHideMaxAttempts && revealAnswers === RevealAnswers.RealTime

  if (hideMaxAttempts) return null

  return (
    <>
      <Switch
        checked={multipleAttemptsEnabled}
        onChange={checked => {
          form.setValue('maxAttempts', checked ? 2 : 1)
        }}
        label={'Allow Multiple Attempts'}
        data-testid={'AllowMultipleAttemptsSwitch'}
      />
      {multipleAttemptsEnabled ? (
        <Form.NumberField name={'maxAttempts'} label={'Max attempts'} min={1} max={50} required />
      ) : (
        <VisuallyHidden>
          <Form.Field aria-label={'Max Attempts'} name={'maxAttempts'} initialValue={1}>
            <input type={'hidden'} />
          </Form.Field>
        </VisuallyHidden>
      )}
    </>
  )
}
