import React from 'react'

export const useEffectAfterMount: typeof React.useEffect = (effect, deps) => {
  const initialMount = React.useRef(true)
  React.useEffect(() => {
    if (!initialMount.current) {
      return effect()
    }
    initialMount.current = false
  }, deps)
}
