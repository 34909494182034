import { useResource } from '@thesisedu/feature-react'

import { GeneratedAssignmentProviderReactResource } from './types'
import { BasicAssignmentFragment } from '../schema'

export function useGeneratedAssignmentReactResource(assignment: BasicAssignmentFragment) {
  return useResource<GeneratedAssignmentProviderReactResource>(
    'GeneratedAssignmentProviderReact',
    assignment.generated?.provider || 'NONE-PROVIDER',
  )
}
