import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import React from 'react'

import { AuthGoogleReactOptions } from './types'

export class AuthGoogleReactFeature extends ReactFeature {
  public static package = '@thesisedu/feature-auth-google-react'
  public static path = __dirname
  public static requires: string[] = []
  public options: AuthGoogleReactOptions

  constructor(options: AuthGoogleReactOptions, deps: FeatureDependencies) {
    super(options, deps)
    this.options = options
  }

  public reactResources() {
    require('./hooks/wrap').default(this)
    require('./resources/SAMLButton').default(this)
    const { ColorGoogle } = require('@sammarks/icons')
    this.resources.addResource<AuthProviderHandlerResource>({
      type: 'AuthProviderHandler',
      identifier: 'google',
      name: 'Google',
      headerLogoPath: require('../assets/google.svg').default,
      squareLogoPath: require('../assets/square.svg').default,
      buttonIcon: <ColorGoogle />,
      render: payload => {
        const { AuthProviderHandler } = require('./web/AuthProviderHandler')
        return <AuthProviderHandler {...payload} />
      },
      preAuthenticateScreen: () => {
        const { PreAuthenticateScreen } = require('./web/PreAuthenticateScreen')
        return <PreAuthenticateScreen />
      },
    })
  }
}

export const authGoogleReactFeature = (
  opts: AuthGoogleReactOptions,
): FeatureUse<AuthGoogleReactOptions> => [AuthGoogleReactFeature, opts]
