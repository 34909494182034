import { notification } from 'antd'
import React from 'react'

import { getExtension } from './fetchAndAddUpload'
import { Upload } from './types'
import { MediaType } from '../schema'

export function showUploadAddedNotification(
  mediaType: MediaType,
  blobOrPath: Pick<Upload, 'fileBlob' | 'filePath'>,
) {
  notification.info({
    message: 'Upload Started...',
    description: (
      <div>
        Your upload has started. Would you like to{' '}
        <a
          href={
            blobOrPath.fileBlob ? URL.createObjectURL(blobOrPath.fileBlob) : blobOrPath.filePath
          }
          download={`recording.${getExtension(mediaType)}`}
        >
          save it to your computer as well?
        </a>
      </div>
    ),
  })
}
