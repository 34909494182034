import { addExpectedErrorHandler } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { UsersReactFeature } from '../UsersReactFeature'
import { debug } from '../log'
import { globalAuthenticate } from '../saml/ProviderAuthContext'

let ignoreSamlErrors = false
export function useIgnoreSamlErrors() {
  React.useEffect(() => {
    ignoreSamlErrors = true
    return () => {
      ignoreSamlErrors = false
    }
  }, [])
}

export default function addExpectedErrorHandlers(feature: UsersReactFeature) {
  addExpectedErrorHandler(feature, async (payload, context) => {
    const provider = payload.extensions?.provider as string | undefined
    if (
      (context.code === 'ASSOCIATED_ACCOUNT_IS_INVALID_ERROR' ||
        context.code === 'NOT_AUTHENTICATED_WITH_PROVIDER_ERROR') &&
      provider
    ) {
      if (ignoreSamlErrors) {
        debug('ignoreSamlErrors is true; ignoring this error inside the global handler')
        return payload
      }
      debug(
        'operation failed with error %s. authenticating with provider %s...',
        context.code,
        provider,
      )
      try {
        await globalAuthenticate(provider, { hadError: true })
      } finally {
        debug('authentication may have been successful. trying the original operation again')
        return { ...payload, shouldRetry: true }
      }
    }
  })
}
