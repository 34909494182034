import { useFeatureRoot } from '@thesisedu/feature-react'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { MediaPlaybackProgress, PlayerProps } from '@thesisedu/react'
import React from 'react'

import { VideoViewer } from './VideoViewer'
import { VideoOnDemandViewerResource } from './types'

export type DynamicVideoViewProps = Pick<Partial<PlayerProps>, 'children'> & {
  vodId: string
  allowSeek?: boolean
  onProgress?: (progress: MediaPlaybackProgress) => void
}
export function DynamicVideoView({ vodId, ...props }: DynamicVideoViewProps) {
  const { type } = fromGlobalId(vodId, true)
  const root = useFeatureRoot()
  if (type === 'VideoOnDemand') {
    return <VideoViewer vodId={vodId} {...props} />
  } else {
    const resource = root.deps.resources.getResource<VideoOnDemandViewerResource>(
      'VideoOnDemandViewer',
      type,
    )
    if (resource) {
      const { Component } = resource
      return <Component vodId={vodId} {...props} />
    } else {
      throw new Error(`Unknown video type: '${type}'`)
    }
  }
}
