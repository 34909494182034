const BLOCK_SIZE = 5 // Only visit every 5 pixels

export function findDominantColor(element: HTMLImageElement) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (!context) return null

  const height = element.naturalHeight || element.offsetHeight || element.height
  canvas.height = height
  const width = element.naturalWidth || element.offsetWidth || element.width
  canvas.width = width

  context.drawImage(element, 0, 0)

  let data
  try {
    data = context.getImageData(0, 0, width, height)
  } catch {
    return null
  }

  const length = data.data.length
  let i = -4
  let count = 0
  const rgb = { r: 0, g: 0, b: 0 }
  while ((i += BLOCK_SIZE * 4) < length) {
    ++count
    rgb.r += data.data[i]
    rgb.g += data.data[i + 1]
    rgb.b += data.data[i + 2]
  }

  rgb.r = ~~(rgb.r / count)
  rgb.g = ~~(rgb.g / count)
  rgb.b = ~~(rgb.b / count)

  return rgb
}
