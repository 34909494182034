import { ApolloClient, DocumentNode, useApolloClient } from '@apollo/client'
import React from 'react'

import { SelectButtonProps } from './types'
import { useFeatureRoot } from '../feature'
import { warn } from '../log'
import { modifyQueryDocument } from '../queries'
import { Node } from '../schema'

async function _fetchAllItemNames(
  client: ApolloClient<any>,
  document: DocumentNode,
  ids: string[],
) {
  return Promise.all(
    ids.map(async id => {
      const result = await client.query({
        query: document,
        variables: { id },
      })
      return result.data?.node
    }),
  )
}

export interface UseItemNamesOpts<Item extends Node>
  extends Pick<SelectButtonProps<Item, any, any>, 'nodeDocument' | 'value' | 'getItemName'> {}
export function useItemNames<Item extends Node>({
  nodeDocument,
  value = [],
  getItemName,
}: UseItemNamesOpts<Item>) {
  const root = useFeatureRoot()
  const [itemNames, setItemNames] = React.useState<string[]>([])
  const client = useApolloClient()
  React.useEffect(() => {
    if (value.length && nodeDocument && root) {
      const modifiedDocument = modifyQueryDocument(nodeDocument, root.deps.hookManager)
      _fetchAllItemNames(client, modifiedDocument, value)
        .then(nodes => {
          setItemNames(nodes.map(node => getItemName(node)))
        })
        .catch(err => {
          warn('error fetching item names')
          warn(err)
        })
    } else {
      setItemNames(value)
    }
  }, [value.join(',')])

  return { itemNames, loading: !itemNames.length && value.length }
}
