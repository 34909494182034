import { Icon as LegacyIcon } from '@ant-design/compatible'
import { toGlobalId } from '@thesisedu/feature-utils'
import { styled } from '@thesisedu/web'
import React from 'react'

import { OutlineItemProps } from './OutlineItem'
import { UseInClassButton } from '../../explore/UseInClassButton'
import { getIconForSegment, isTeacherInfoSegment } from '../../helpers'
import { SegmentName } from '../../segment/SegmentName'
import { useSegmentPreviewContext } from '../../segment/SegmentPreviewContext'
import { OutlineSearchWrapper } from '../OutlineSearchContext'
import { SegmentOutlineItem } from '../types'

export const SMALL_OUTLINE_SEGMENT_HEIGHT = 50

export interface SegmentOutlineItemProps extends OutlineItemProps {
  item: SegmentOutlineItem
  useInClass?: boolean
}
export function Segment({ useInClass, item, children }: SegmentOutlineItemProps) {
  const isTeacherInfo = isTeacherInfoSegment(item.segment)
  const icon = getIconForSegment(item.segment)
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const { setPreviewSegmentId } = useSegmentPreviewContext(false) || {}
  return (
    <Container segmentIds={[item.segment.id]} className={dropdownVisible ? 'dropdown-visible' : ''}>
      {/* @ts-ignore react 18 support */}
      <LegacyIcon type={icon} theme={'outlined'} className={'icon'} />
      <SegmentName
        segment={item.segment}
        isTeacherInfo={isTeacherInfo}
        onClick={
          useInClass && setPreviewSegmentId
            ? () => {
                setPreviewSegmentId(toGlobalId('Segment', item.id), id => (
                  <UseInClassButton
                    rawId={id}
                    variant={'primary'}
                    onClick={() => setPreviewSegmentId(undefined)}
                  />
                ))
              }
            : undefined
        }
      />
      {useInClass ? (
        <UseInClassButton
          rawId={item.id}
          variant={'chromeless'}
          size={'small'}
          children={'Use in Class'}
          visible={dropdownVisible}
          onVisibleChange={setDropdownVisible}
          className={'use-in-class'}
        />
      ) : null}
      {children}
    </Container>
  )
}

const Container = styled(OutlineSearchWrapper)`
  margin: 10px 0;
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
  .icon {
    line-height: calc(${props => props.theme['@font-size-base']} * 1.4);
    font-size: ${props => props.theme['@size-s']};
  }
  .segment-name {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: calc(${props => props.theme['@font-size-base']} * 1.4);
    max-height: calc(${props => props.theme['@font-size-base']} * 1.4 * 2);
  }
  .use-in-class {
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s linear;
  }
  &:hover .use-in-class,
  &.dropdown-visible .use-in-class {
    opacity: 1;
    pointer-events: all;
  }
`
