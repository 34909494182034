import { onMutationError } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { debug, warn } from './log'
import {
  NotificationChannelInfoFragment,
  NotificationPreferenceFragment,
  useNotificationChannelsQuery,
  useUpdateNotificationPreferencesMutation,
} from './schema'
import { PreferencesEditorProps } from './types'

export interface Update {
  disabledChannels: string[]
}
interface UpdateMap {
  [type: string]: Update
}

export interface UsePreferencesEditor {
  updatePreference: (type: string, update: Update) => void
  loading: boolean
  preferences: NotificationPreferenceFragment[]
  channels: NotificationChannelInfoFragment[]
}

export const SAVE_TIMEOUT = 1500 // 1.5 seconds.
export function usePreferencesEditor({
  preferences,
  jwt,
  loading,
}: PreferencesEditorProps): UsePreferencesEditor {
  const { data, loading: channelsLoading } = useNotificationChannelsQuery()
  const [update, { loading: mutateLoading }] = useUpdateNotificationPreferencesMutation({
    onError: onMutationError('There was an error updating your notification preferences.'),
  })
  const [preferencesState, setPreferencesState] = React.useState<NotificationPreferenceFragment[]>(
    [],
  )
  const updateMapRef = React.useRef<UpdateMap>({})
  const saveTimeoutRef = React.useRef<any>()
  React.useEffect(() => {
    setPreferencesState(preferences)
  }, [preferences])
  const updatePreference = React.useCallback<UsePreferencesEditor['updatePreference']>(
    (type, updates) => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current)
      }
      updateMapRef.current[type] = updates
      setPreferencesState(state =>
        state.map(preference => {
          if (preference.notificationType === type) {
            return { ...preference, ...updates }
          } else {
            return preference
          }
        }),
      )
      saveTimeoutRef.current = setTimeout(async () => {
        debug('timeout expired. updating preferences %O', updateMapRef.current)
        const oldUpdateMap = updateMapRef.current
        updateMapRef.current = {}
        try {
          const result = await update({
            variables: {
              input: {
                jwt,
                preferences: Object.keys(oldUpdateMap).map(key => ({
                  notificationType: key,
                  ...oldUpdateMap[key],
                })),
              },
            },
          })
          if (result?.data?.updateNotificationPreferences.user.id) {
            debug('update complete')
          } else {
            warn('update failed without error')
            updateMapRef.current = {
              ...oldUpdateMap,
              ...updateMapRef.current,
            }
          }
        } catch (err: any) {
          warn('update failed')
          warn(err)
          updateMapRef.current = {
            ...oldUpdateMap,
            ...updateMapRef.current,
          }
        }
      }, SAVE_TIMEOUT)
    },
    [update],
  )

  const allPreference = preferencesState.find(pref => pref.notificationType === 'all')
  return {
    loading: loading || mutateLoading || channelsLoading,
    updatePreference,
    preferences: allPreference
      ? preferencesState.map(preference => ({
          ...preference,
          disabledChannels: [
            ...new Set([...allPreference.disabledChannels, ...preference.disabledChannels]),
          ],
        }))
      : preferencesState,
    channels: data?.notificationChannels || [],
  }
}
