import { useClassSelector } from '@thesisedu/feature-classes-react'
import { useCreateClassMutation } from '@thesisedu/feature-classes-react/dist/queries/useCreateClassMutation'
import { Modal, BodyLarge } from '@thesisedu/web'
import { message, Button, Form, Input } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CreateClassInput, useClassNamesQuery } from '../schema'

export interface CreateClassModalProps {
  courseId?: string
  courseName?: string
  onClose: () => void
}
export function CreateClassModal({ courseId, courseName, onClose }: CreateClassModalProps) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { data, loading } = useClassNamesQuery()
  const { setSelectedClassId } = useClassSelector()
  const [createClass, { loading: creating }] = useCreateClassMutation({
    onCompleted: data => {
      message.success('Class created successfully!')
      const classId = data.createClass.class.id
      setSelectedClassId(classId)
      navigate(`/teacher/classes/${classId}`)
      onClose()
    },
  })
  const classNames = data?.viewer?.teacher?.classes.edges.map(edge => edge.node.name) || []
  return (
    <Modal
      title={'Create Class'}
      visible={!!courseId && !!courseName}
      onCancel={onClose}
      width={600}
      style={{ maxWidth: '90vw' }}
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          Cancel
        </Button>,
        <Button key={'submit'} type={'primary'} onClick={form.submit} loading={loading || creating}>
          Create Class
        </Button>,
      ]}
    >
      <BodyLarge isBlock>
        You are creating a class with the <strong>{courseName}</strong> course.
      </BodyLarge>
      <Form
        form={form}
        onFinish={values => {
          createClass({
            variables: {
              input: {
                name: values.name,
                courseId,
              } as CreateClassInput,
            },
          })
        }}
        layout={'vertical'}
      >
        <Form.Item
          label={'Class Name'}
          name={'name'}
          rules={[
            { required: true, message: 'A class name is required.' },
            {
              validator: async (_, value) => {
                if (
                  value &&
                  classNames.some(c => value.trim().toUpperCase() === c.trim().toUpperCase())
                ) {
                  throw new Error('That class name is already in use. Please choose another.')
                }
              },
            },
          ]}
        >
          <Input
            size={'large'}
            placeholder={courseName ? `Period 1 - ${courseName}` : 'Period 1'}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
