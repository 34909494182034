import {
  AntIconWrapper,
  BodyLarge,
  BodySmallTransparent,
  Button,
  ButtonProps,
  Div,
  FontWeight,
  getSize,
  HSpaced,
  LoadingIndicator,
  Space,
  styled,
} from '@thesisedu/react'
import { NavArrowRight } from 'iconoir-react'
import React from 'react'

export interface CreateSubmissionButtonButtonProps extends Omit<Partial<ButtonProps>, 'onClick'> {
  disabled?: boolean
  loading?: boolean
  onClick: () => any
  icon: React.ReactElement
  title: string
  subtitle?: string
}
export function CreateSubmissionButtonButton({
  disabled,
  loading,
  onClick,
  icon,
  title,
  subtitle,
  ...rest
}: CreateSubmissionButtonButtonProps) {
  return (
    <CreateButton disabled={disabled} block {...rest} onClick={onClick}>
      <HSpaced>
        <IconContainer useDiv>
          {loading ? <LoadingIndicator /> : <AntIconWrapper children={icon} />}
        </IconContainer>
        <Div>
          <BodyLarge weight={FontWeight.Bold} children={title} />
          <BodySmallTransparent children={subtitle} />
        </Div>
        <Space />
        <BodySmallTransparent>
          <NavArrowRight />
        </BodySmallTransparent>
      </HSpaced>
    </CreateButton>
  )
}

const IconContainer = styled(BodyLarge)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const CreateButton = styled(Button)`
  height: auto;
  padding: ${props => getSize(props.theme, '@size-s')};
  text-align: left;
  border-radius: ${props => getSize(props.theme, '@border-radius-large')};
  &[disabled] {
    background: ${props => props.theme['@gray-0']} !important;
  }
  .ant-btn-loading-icon {
    display: none;
  }
`
