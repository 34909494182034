import { InputNumberProps, InputNumber } from 'antd'
import React from 'react'

const LOCALE = 'en-us'

const currencyFormatter = new Intl.NumberFormat(LOCALE, {
  style: 'currency',
  currency: 'USD',
})

const currencyParser = (val: any) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0'
    }

    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(LOCALE).format(1111).replace(/1/g, '')
    const decimal = new Intl.NumberFormat(LOCALE).format(1.1).replace(/1/g, '')
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '')
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.')
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^\d.]/g, '')
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length
    const needsDigitsAppended = digitsAfterDecimalCount > 2

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2)
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal
  } catch (error) {
    console.error(error)
  }
}

export function CurrencyInput(props: InputNumberProps) {
  return (
    <InputNumber
      precision={2}
      style={{
        width: 200,
        ...props.style,
      }}
      {...props}
      formatter={value => currencyFormatter.format(value as any)}
      parser={currencyParser}
    />
  )
}
