import { Text } from '@thesisedu/ui'
import React from 'react'

import { UserFragment } from '../../schema'

export interface RosteringStudentProps {
  user: Partial<Pick<UserFragment, 'name' | 'firstName' | 'lastName' | 'username' | 'email'>>
}
export function RosteringStudent({ user }: RosteringStudentProps) {
  const name =
    user.name ??
    (user.firstName || user.lastName
      ? [user.firstName, user.lastName].filter(Boolean).join(' ')
      : user.username)
  return (
    <div style={{ minWidth: 0 }}>
      <Text level={'s'} truncate>
        {name}
      </Text>
      {user.email ? (
        <Text level={'xs'} color={'secondary'} truncate>
          {user.email}
        </Text>
      ) : null}
    </div>
  )
}
