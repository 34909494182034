import {
  onMutationError,
  useDeleteNodeMutation as useMutation,
} from '@thesisedu/feature-apollo-react'

export const useDeleteAnnouncementMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error deleting that announcement.'),
    ...opts,
  })
