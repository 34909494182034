import { useImpersonate } from '@thesisedu/feature-users-web'
import { Dropdown, LoadingIndicator, useToast } from '@thesisedu/ui'
import { EyeAlt } from '@thesisedu/ui/icons'
import React from 'react'

import {
  StudentActionProps,
  StudentActionResource,
} from '../../teacher/students/StudentActionsDropdown'
import { useStudentUser } from '../../useStudentUsers'

function Action({ students }: StudentActionProps) {
  const student = students.length === 1 ? students[0] : undefined
  const studentUser = useStudentUser(student?.studentId ?? '', student?.classId ?? '', !student)
  const { impersonate, canImpersonate } = useImpersonate()
  const toast = useToast()
  if (studentUser.student && canImpersonate) {
    return (
      <Dropdown.Item
        icon={<EyeAlt />}
        onClick={async () => {
          if (!studentUser.student) return
          const update = toast({
            title: 'Impersonating student...',
            icon: <LoadingIndicator />,
          })
          try {
            await impersonate({ variables: { userId: studentUser.student.user.id } })
          } finally {
            update()
          }
        }}
      >
        Impersonate
      </Dropdown.Item>
    )
  } else return null
}

export default {
  type: 'StudentAction',
  identifier: 'Impersonate',
  weight: -100,
  render: Action,
} satisfies StudentActionResource
