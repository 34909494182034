import { DynamicVideoField } from '@thesisedu/feature-video-on-demand-react'
import { Form, VSpaced } from '@thesisedu/ui'
import React from 'react'

export function VideoSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <Form.Field name={'vodId'} label={'Video'}>
        <DynamicVideoField />
      </Form.Field>
      <Form.TextField
        name={'inlineLabel'}
        placeholder={'Watch this in action.'}
        label={'Inline Label'}
        description={'Only visible when Display Mode is Inline'}
      />
    </VSpaced>
  )
}
