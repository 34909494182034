import { getFragmentDefinitions } from '@apollo/client/utilities'
import { addFragmentReference, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import {
  SiteConfigurationFieldResource,
  SiteDistrictConfigurationField,
} from '@thesisedu/feature-sites-web'
import { omitDeep } from '@thesisedu/react'
import { LoadingIndicator, styled, s, VSpaced, Checkbox } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import {
  ClassSyncProviderConfigurationFragment,
  ClassSyncProviderFeature,
  ClassSyncSiteConfigurationFragmentDoc,
  useClassSyncProvidersQuery,
} from '../../schema'

interface ClassSyncProvidersFieldProps {
  value?: ClassSyncProviderConfigurationFragment[]
  onChange?: (value: ClassSyncProviderConfigurationFragment[]) => void
}
function ClassSyncProvidersField({ value, onChange: _onChange }: ClassSyncProvidersFieldProps) {
  const onChange: typeof _onChange = _onChange
    ? val => {
        _onChange(
          omitDeep(
            val.filter(v => {
              const hasAllFeatures =
                v.enabledFeatures &&
                v.enabledFeatures?.length >= Object.values(ClassSyncProviderFeature).length
              return !hasAllFeatures
            }),
            ['__typename'],
          ),
        )
      }
    : undefined
  const { data, loading } = useClassSyncProvidersQuery()
  const providers = data?.classSyncProviders ?? []
  return (
    <Container>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VSpaced>
          {providers.map(provider => {
            const entry = value?.find(v => v.providerIdentifier === provider.identifier)
            const features: ClassSyncProviderFeature[] = [ClassSyncProviderFeature.Rostering]
            if (provider.supportsAssignmentSync)
              features.push(ClassSyncProviderFeature.GradeMaterialSync)
            const hasSomeFeatures =
              entry?.enabledFeatures &&
              entry.enabledFeatures.length < features.length &&
              entry.enabledFeatures?.length
            const update = (enabledFeatures: ClassSyncProviderFeature[] | null) => {
              if (!onChange) return
              if (enabledFeatures === null) {
                onChange((value ?? []).filter(v => v.providerIdentifier !== provider.identifier))
              } else {
                let hadExisting = false
                const newValue = (value ?? []).map(v => {
                  if (v.providerIdentifier === provider.identifier) {
                    hadExisting = true
                    return { ...v, enabledFeatures }
                  } else return v
                })
                if (!hadExisting) {
                  newValue.push({
                    providerIdentifier: provider.identifier,
                    enabledFeatures,
                  })
                }
                onChange(newValue)
              }
            }
            return (
              <VSpaced space={'xs'} key={provider.identifier}>
                <Checkbox
                  checked={
                    hasSomeFeatures
                      ? 'indeterminate'
                      : entry
                      ? !!entry.enabledFeatures?.length
                      : true
                  }
                  onCheckedChange={checked => update(checked ? null : [])}
                  label={provider.name}
                />
                <FeatureCheckbox
                  entry={entry}
                  feature={ClassSyncProviderFeature.Rostering}
                  label={'Rostering'}
                  update={update}
                />
                <FeatureCheckbox
                  entry={entry}
                  feature={ClassSyncProviderFeature.GradeMaterialSync}
                  label={'Grade & Material Sync'}
                  update={update}
                />
              </VSpaced>
            )
          })}
        </VSpaced>
      )}
    </Container>
  )
}

interface FeatureCheckboxProps {
  feature: ClassSyncProviderFeature
  label: string
  entry?: ClassSyncProviderConfigurationFragment
  update: (features: ClassSyncProviderFeature[] | null) => void
}
function FeatureCheckbox({ feature, label, entry, update }: FeatureCheckboxProps) {
  return (
    <Checkbox
      style={{ marginLeft: s.size('s') }}
      checked={!entry?.enabledFeatures || entry?.enabledFeatures?.includes(feature)}
      onCheckedChange={checked =>
        update(
          checked
            ? [...new Set([...(entry?.enabledFeatures ?? []), feature])]
            : (entry?.enabledFeatures ?? Object.values(ClassSyncProviderFeature)).filter(
                ft => ft !== feature,
              ),
        )
      }
      label={label}
    />
  )
}

const Container = styled.div`
  border: solid 1px ${s.color('border')};
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('s')};
  max-height: 200px;
  overflow-y: auto;
`

export default function (feature: ClassSyncReactFeature) {
  useModifiedFragment(feature.hookManager, 'SiteConfiguration', (fragment, context) => {
    return addFragmentReference(
      fragment,
      getFragmentDefinitions(ClassSyncSiteConfigurationFragmentDoc),
      context,
    )
  })
  feature.resources.addResource<SiteConfigurationFieldResource>({
    type: 'SiteConfigurationField',
    identifier: 'classSyncProviders',
    group: 'Integrations',
    renderField({ fieldPrefix, district }) {
      return (
        <SiteDistrictConfigurationField
          name={[...fieldPrefix, 'classSyncProviders']}
          label={'Enabled Class Sync Integrations'}
          district={district}
        >
          <ClassSyncProvidersField />
        </SiteDistrictConfigurationField>
      )
    },
  })
}
