import { CloudDownloadOutlined } from '@ant-design/icons'
import { useReportsContext } from '@thesisedu/feature-reports-react'
import { Button, Dropdown, Tooltip } from '@thesisedu/ui'
import { Percentage } from '@thesisedu/ui/icons'
import React from 'react'

import { useRunGradeReportMutation, AssignmentFiltersInput } from '../schema'

export interface ExportGradesButtonProps {
  filters?: AssignmentFiltersInput
}
export function ExportGradesButton({ filters }: ExportGradesButtonProps) {
  const { trackReport } = useReportsContext(true)
  const [runGradeReport, { loading }] = useRunGradeReportMutation({
    onCompleted: data => {
      const reportJob = data?.runGradeReport.reportJob
      if (reportJob) {
        trackReport(reportJob.id, 'grades', reportJob.downloadUrl)
      }
    },
  })
  return (
    <Tooltip title={'Export Data'} delayDuration={1}>
      <Dropdown.Container>
        <Dropdown.ManualTrigger>
          <Button icon={<CloudDownloadOutlined />} loading={loading} />
        </Dropdown.ManualTrigger>
        <Dropdown.Menu side={'bottom'} align={'end'}>
          <Dropdown.Item
            icon={<Percentage />}
            onClick={async () =>
              await runGradeReport({
                variables: {
                  input: {
                    classIds: filters?.classIds,
                  },
                },
              })
            }
            children={'Percentages'}
          />
          <Dropdown.Item
            icon={<span>#</span>}
            onClick={async () =>
              await runGradeReport({
                variables: {
                  input: {
                    classIds: filters?.classIds,
                    showAsPoints: true,
                  },
                },
              })
            }
            children={'Points'}
          />
        </Dropdown.Menu>
      </Dropdown.Container>
    </Tooltip>
  )
}
