import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { Form, Checkbox } from 'antd'
import React from 'react'

export const HIDE_KEYBOARD_FIELD = 'hideKeyboard'
function Field({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, HIDE_KEYBOARD_FIELD]}
      valuePropName={'checked'}
      extra={
        'When this is checked, the universal pop-up keyboard will not be displayed to students.'
      }
    >
      <Checkbox>Disable universal pop-up keyboard.</Checkbox>
    </Form.Item>
  )
}

const resource: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: HIDE_KEYBOARD_FIELD,
  group: 'Compose',
  renderField: Field,
}
export default resource
