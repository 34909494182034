import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-web'
import { Input, Form, Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { clearAndResetStore } from './LoginForm'
import UsersWebFeature from './UsersWebFeature'
import { authAndRedirect } from './authAndRedirect'
import { error } from './log'
import { useRegisterMutation, UserFragment, UserInput } from './schema'

export interface RegisterFormProps {
  submitText?: string
  onRegister?: (user: UserFragment) => Promise<void>
  defaultValues?: Partial<UserInput>
  hideEduWarning?: boolean
}
export const RegisterForm: React.FC<React.PropsWithChildren<RegisterFormProps>> = ({
  submitText = 'Register',
  onRegister,
  defaultValues,
  hideEduWarning,
}) => {
  const apolloFeature = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const usersFeature = useFeature<UsersWebFeature>(UsersWebFeature.package)
  const [emailWarning, setEmailWarning] = useState<string>('')
  useEffect(() => {
    if (defaultValues) {
      form.setFieldsValue(defaultValues)
    }
  }, [defaultValues, form])
  const [register, { loading }] = useRegisterMutation({
    onCompleted: async data => {
      const { user } = data.register
      localStorage.setItem(apolloFeature.authenticationKey, data.register.jwt)
      await clearAndResetStore(apolloFeature.client)
      message.success(
        <span>
          Welcome to {usersFeature.appOptions.name} <strong>{user.name || user.username}!</strong>
        </span>,
      )
      if (onRegister) {
        await onRegister(user)
      }
      await authAndRedirect(navigate, usersFeature, apolloFeature)
    },
    onError: err => {
      if (err.graphQLErrors[0]?.extensions?.code === 'NAME_TAKEN_ERROR') {
        message.error('It looks like that email address has already been taken.')
      } else {
        error('error registering')
        error(err)
        message.error('There was an error creating your account.')
      }
    },
  })

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={values => {
        register({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
            },
          },
        })
      }}
    >
      <Form.Item name={'firstName'} label={'First Name'}>
        <Input size={'large'} />
      </Form.Item>
      <Form.Item name={'lastName'} label={'Last Name'}>
        <Input size={'large'} />
      </Form.Item>
      <Form.Item
        name={'email'}
        label={'Email Address'}
        validateStatus={emailWarning ? 'warning' : undefined}
        help={emailWarning || undefined}
        hasFeedback={!!emailWarning}
        rules={[
          { required: true, type: 'email', message: 'A valid email address is required.' },
          {
            validator: (rule, value) => {
              if (!hideEduWarning && value && value.toString().match(/\.(edu|us)$/i)) {
                setEmailWarning('Talk to your instructor before creating an account for school.')
              } else {
                setEmailWarning('')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <Input size={'large'} placeholder={'user@example.com'} type={'email'} />
      </Form.Item>
      <Form.Item
        name={'password'}
        label={'Create a Password'}
        rules={[
          { required: true, message: 'You must create a password.' },
          { min: 8, message: 'Your password must be at least 8 characters long.' },
        ]}
      >
        <Input size={'large'} type={'password'} data-sentry-ignore />
      </Form.Item>
      <Button
        loading={loading}
        block
        size={'large'}
        type={'primary'}
        children={submitText}
        htmlType={'submit'}
      />
    </Form>
  )
}
