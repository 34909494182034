import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React, { useState } from 'react'

import { CreateTeacherModal, CreateTeacherModalProps } from './CreateTeacherModal'

export type CreateTeacherButtonProps = Partial<CreateTeacherModalProps> &
  Pick<CreateTeacherModalProps, 'siteId'> & {
    buttonProps?: ButtonProps
  }
export const CreateTeacherButton: React.FC<React.PropsWithChildren<CreateTeacherButtonProps>> = ({
  buttonProps,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CreateTeacherModal onCancel={() => setVisible(false)} visible={visible} {...props} />
      <Button type={'primary'} onClick={() => setVisible(true)} {...buttonProps}>
        Create Teacher
      </Button>
    </>
  )
}
