import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { OutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentType } from '../../schema'
import { ScheduleSegmentMenuItem } from '../../segment/ScheduleSegmentButton'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function ScheduleSegment({ segment }: SegmentActionComponentProps) {
  const { enabledSegmentIds } = React.useContext(OutlineSegmentContext) || {}
  const isEnabled = enabledSegmentIds
    ? enabledSegmentIds.includes(segment.id)
    : segment.visibleOverride === true
  return isEnabled ? null : <ScheduleSegmentMenuItem segmentId={segment.id} />
}

export const ScheduleSegmentAction: SegmentActionResource = {
  identifier: 'schedule',
  type: 'SegmentAction',
  component: ScheduleSegment,
  group: 'Enable & Disable',
  weight: 102,
  filter: segment => {
    return NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}
