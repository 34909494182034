import React from 'react'

import { RecordStateContextValue } from './RecordStateContext'
import { UseRecordStreams } from './useRecordStreams'
import { Streams } from '../types'

export interface DetailedRecordStateContextValue extends RecordStateContextValue {
  loading?: boolean
  updateStreams: UseRecordStreams['updateStreams']
  streams: Streams
  onError: (e: any) => void
}
export const DetailedRecordStateContext = React.createContext<
  DetailedRecordStateContextValue | undefined
>(undefined)

export function useDetailedRecordStateContext(): DetailedRecordStateContextValue {
  const context = React.useContext(DetailedRecordStateContext)
  if (!context) {
    throw new Error('DetailedRecordStateContext is required, yet not provided.')
  }
  return context
}
