import { useFeatureRoot, useMutateHook } from '@thesisedu/feature-react'
import { LoginForm, useProtection } from '@thesisedu/feature-users-web'
import { Block, Body, BodyLarge, H1, H1Subtitle, Link, useTheme, VSpaced } from '@thesisedu/react'
import { ArrowRight } from '@thesisedu/react/icons'
import React from 'react'

import { SAMLButtons } from './SAMLButtons'
import { LoginLinksHook } from '../../types'

export function Login() {
  useProtection({ allowedGroups: [], redirect: '/' })
  const theme = useTheme()
  const appOptions = useFeatureRoot().appOptions
  const links = useMutateHook<LoginLinksHook>(
    'feature-users-react:login-links',
    [
      <Link to={'../register'} key={'register'}>
        <BodyLarge color={'@primary-color'}>
          Create an Account <ArrowRight />
        </BodyLarge>
      </Link>,
      <Link to={'../request-password-reset'} key={'reset-password'}>
        <Body color={'@text-color-secondary'}>
          Forgot password <ArrowRight />
        </Body>
      </Link>,
    ],
    undefined,
  )
  return (
    <>
      <H1 style={{ marginBottom: 0 }}>Welcome back</H1>
      <H1Subtitle style={{ marginBottom: theme['@size-l'] }}>
        Login to your {appOptions.name} account
      </H1Subtitle>
      <LoginForm submitText={`Login to ${appOptions.name}`} />
      <Block marginTop={'@size-s'}>
        <VSpaced space={'@size-xs'} align={'stretch'}>
          <SAMLButtons />
        </VSpaced>
      </Block>
      <Block marginTop={'@size-l'}>
        <VSpaced space={'@size-m'} align={'flex-start'} children={links} />
      </Block>
    </>
  )
}
