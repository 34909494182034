import { DeleteOutlined } from '@ant-design/icons'
import { Block, Body, HSpaced } from '@thesisedu/web'
import { Button, Form, FormItemProps } from 'antd'
import React from 'react'

export interface OverrideFieldProps extends FormItemProps {
  overrideFieldName: string[]
  overrideInitialValue?: any
  children: React.ReactElement
}
export function OverrideField({
  overrideFieldName,
  overrideInitialValue,
  children,
  name,
  label,
  ...rest
}: React.PropsWithChildren<OverrideFieldProps>) {
  return (
    <>
      <Form.Item label={label} required={rest.rules?.some(r => (r as any).required)}>
        <>
          <Form.Item name={name} {...rest} noStyle>
            {children}
          </Form.Item>
          <Block marginTop={'@size-xs'}>
            <Form.Item name={overrideFieldName} noStyle initialValue={overrideInitialValue}>
              <OverrideFieldField originalField={children} placeholder={'Student override'} />
            </Form.Item>
          </Block>
        </>
      </Form.Item>
    </>
  )
}

export interface OverrideFieldFieldProps {
  value?: string | null
  onChange?: (value?: string | null) => void
  originalField: React.ReactElement
  placeholder?: string
}
export function OverrideFieldField({
  placeholder,
  value,
  onChange,
  originalField,
}: OverrideFieldFieldProps) {
  if (value !== null && value !== undefined) {
    return (
      <HSpaced>
        <div style={{ flex: 1 }}>
          {React.cloneElement(originalField, { value, onChange, placeholder })}
        </div>
        <Button
          type={'link'}
          size={'small'}
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            if (onChange) {
              onChange(null)
            }
          }}
        />
      </HSpaced>
    )
  } else {
    return (
      <Body>
        <a
          onClick={e => {
            e.preventDefault()
            if (onChange) {
              onChange('')
            }
          }}
        >
          Override for Students
        </a>
      </Body>
    )
  }
}
