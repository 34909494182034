import { CourseViewExploreGrid } from '@thesisedu/feature-course-views-react'
import { ExploreLookingForResource } from '@thesisedu/feature-courses-react'
import { GridTagFilter } from '@thesisedu/feature-courses-react/dist/explore/grid'
import { FeatureWeb } from '@thesisedu/feature-web'
import { PageHead } from '@thesisedu/react'
import { Play } from '@thesisedu/react/icons'
import { PageHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'

function VideoVault() {
  useBreadcrumb({ title: 'Video Vault' })
  return (
    <>
      <PageHead title={'Video Vault - Explore'} />
      <PageHeader title={'Video Vault'} />
      <CourseViewExploreGrid courseViewId={'video-vault'} searchPlaceholder={'Search Videos'}>
        <GridTagFilter label={'Course'} tagType={'COURSE'} />
        <GridTagFilter label={'# of Notes'} tagType={'NUM_NOTES'} />
        <GridTagFilter label={'Instrument'} tagType={'INSTRUMENT'} />
        <GridTagFilter label={'Concept / Objective'} tagType={'CONCEPT_OBJECTIVE'} />
        <GridTagFilter label={'Notation Style'} tagType={'NOTATION_STYLE'} />
        <GridTagFilter label={'Notes'} tagType={'NOTES'} />
        <GridTagFilter label={'Genre'} tagType={'GENRE'} />
        <GridTagFilter label={'Key'} tagType={'KEY'} />
        <GridTagFilter label={'Vocal Model'} tagType={'VOCAL_MODEL'} />
      </CourseViewExploreGrid>
    </>
  )
}

export default function (web: FeatureWeb) {
  web.deps.resources.addResource<ExploreLookingForResource>({
    type: 'ExploreLookingFor',
    identifier: 'videos',
    icon: <Play />,
    title: 'Videos',
    weight: -2,
    page: <VideoVault />,
  })
}
