import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled, Image, Body } from '@thesisedu/web'
import React from 'react'

import { CourseFragment } from '../schema'

export interface CoursePreviewHeaderProps {
  course: CourseFragment
}
export function CoursePreviewHeader({ course }: CoursePreviewHeaderProps) {
  const { filename } = getFileInfo(course.basicConfiguration.icon)
  return (
    <Container>
      {filename ? <Image width={96} src={filename} alt={course.label || course.name} /> : null}
      <Body>
        {course.basicConfiguration.longDescription || course.basicConfiguration.shortDescription}
      </Body>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  top: 0;
  > :first-child:not(:last-child) {
    flex-shrink: 0;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
