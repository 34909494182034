import { Empty } from '@thesisedu/react'
import { Block, Form, HSpaced, LoadingIndicator, Text, VSpaced, useToast } from '@thesisedu/ui'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DeleteRubricTemplateButton } from './DeleteRubricTemplateButton'
import { FormVariables, RubricTemplateForm } from './RubricTemplateForm'
import { DEFAULT_RUBRIC } from './default'
import { transformRubric } from './helpers'
import { useCreateRubricTemplateMutation } from '../queries/useCreateRubricTemplateMutation'
import { useUpdateRubricTemplateMutation } from '../queries/useUpdateRubricTemplateMutation'
import { useRubricTemplateQuery } from '../schema'

export interface EditRubricTemplatePageProps {
  rubricTemplateId: string
}
export function EditRubricTemplatePage({ rubricTemplateId }: EditRubricTemplatePageProps) {
  const { data, loading } = useRubricTemplateQuery({ variables: { id: rubricTemplateId } })
  const navigate = useNavigate()
  const form = Form.useForm<FormVariables>()
  const toast = useToast()
  const [save, { loading: saveLoading }] = useUpdateRubricTemplateMutation({
    onCompleted: () => {
      navigate('..')
      toast.success('Template saved!')
    },
  })
  const template = data?.node?.__typename === 'RubricTemplate' ? data.node : undefined
  React.useEffect(() => {
    if (template) {
      form.reset({
        name: template.name,
        rubric: template.rubric ? transformRubric(template.rubric) : undefined,
      })
    }
  }, [template])
  if (template) {
    return (
      <VSpaced align={'stretch'} space={'l'}>
        <HSpaced align={'flex-end'}>
          <Block right>
            <Link to={'..'}>
              <Text color={'secondary'}>Back to List</Text>
            </Link>
            <Text level={'h2'}>Edit Rubric Template</Text>
          </Block>
          <DeleteRubricTemplateButton
            templateId={rubricTemplateId}
            children={'Delete Template'}
            variant={'ghost'}
            onComplete={() => {
              navigate('..')
            }}
          />
          <Form.StandaloneSubmit
            form={form}
            children={'Save Changes'}
            loading={saveLoading}
            style={{ alignSelf: 'flex-end' }}
            onFinish={async values => {
              return save({
                variables: {
                  input: {
                    id: rubricTemplateId,
                    patch: values,
                  },
                },
              })
            }}
          />
        </HSpaced>
        <RubricTemplateForm form={form} />
      </VSpaced>
    )
  } else if (loading) {
    return <LoadingIndicator.Labeled label={'Loading template...'} />
  } else {
    return <Empty description={'That template could not be found.'} />
  }
}

export function CreateRubricTemplatePage() {
  const navigate = useNavigate()
  const form = Form.useForm<FormVariables>({
    defaultValues: {
      rubric: DEFAULT_RUBRIC,
    },
  })
  const toast = useToast()
  const [create, { loading }] = useCreateRubricTemplateMutation({
    refetchQueries: ['rubricTemplates'],
    onCompleted: result => {
      navigate(`../${result.createRubricTemplate.rubricTemplate.id}`)
      toast.success('Template created!')
    },
  })
  return (
    <VSpaced align={'stretch'} space={'l'}>
      <HSpaced align={'flex-end'}>
        <Block right>
          <Link to={'..'}>
            <Text color={'secondary'}>Back to List</Text>
          </Link>
          <Text level={'h2'}>Create Rubric Template</Text>
        </Block>
        <Form.StandaloneSubmit
          form={form}
          children={'Save Template'}
          loading={loading}
          style={{ alignSelf: 'flex-end' }}
          onFinish={async values => {
            return create({
              variables: {
                input: values,
              },
            })
          }}
        />
      </HSpaced>
      <RubricTemplateForm form={form} />
    </VSpaced>
  )
}
