import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react'
import React from 'react'

import {
  AssignmentTemplateDocument,
  AssignmentTemplateFragment,
  AssignmentTemplatesDocument,
  AssignmentTemplatesQuery,
  AssignmentTemplatesQueryVariables,
} from '../schema'

export type AssignmentTemplateSelectButtonProps = Partial<
  SingleSelectButtonProps<
    AssignmentTemplateFragment,
    AssignmentTemplatesQuery,
    AssignmentTemplatesQueryVariables
  >
>
export function AssignmentTemplateSelectButton(props: AssignmentTemplateSelectButtonProps) {
  return (
    <SingleSelectButton<
      AssignmentTemplateFragment,
      AssignmentTemplatesQuery,
      AssignmentTemplatesQueryVariables
    >
      filterVariableName={'name'}
      selectLabel={'Select a Template'}
      maxSelection={1}
      getItemName={item => item.name}
      document={AssignmentTemplatesDocument}
      nodeDocument={AssignmentTemplateDocument}
      resultPath={'assignmentTemplates'}
      {...props}
    />
  )
}
