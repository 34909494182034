import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Button, Form, message, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React from 'react'

import { TeacherForm } from './TeacherForm'
import { TeacherFragment, TeachersForSiteDocument, useCreateTeacherMutation } from './schema'

export interface CreateTeacherModalProps extends Partial<ModalProps> {
  onCancel: () => void
  siteId: string
  onCreated?: (teacher: TeacherFragment) => void
}
export const CreateTeacherModal: React.FC<React.PropsWithChildren<CreateTeacherModalProps>> = ({
  onCancel: _onCancel,
  siteId,
  onCreated,
  ...props
}) => {
  const [form] = Form.useForm()
  const onCancel = () => {
    form.resetFields()
    _onCancel()
  }
  const [createTeacher, { loading }] = useCreateTeacherMutation({
    refetchQueries: [{ query: TeachersForSiteDocument, variables: { siteId } }],
    awaitRefetchQueries: true,
    onError: onMutationError(
      'There was an error creating that teacher. Their email probably already exists.',
      ['NAME_TAKEN_ERROR'],
      code => {
        if (code === 'NAME_TAKEN_ERROR') {
          message.error('That email has already been taken.')
        }
      },
    ),
    onCompleted: data => {
      message.success('Teacher created successfully!')
      onCancel()
      if (onCreated) {
        onCreated(data.createTeacher.teacher)
      }
    },
  })

  return (
    <Modal
      title={'Create Teacher'}
      {...props}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} type={'primary'} onClick={form.submit} loading={loading}>
          Create Teacher
        </Button>,
      ]}
    >
      <TeacherForm
        form={form}
        onFinish={values =>
          createTeacher({
            variables: {
              input: {
                ...values,
                noEmail: values.noEmail,
                siteId,
              },
            },
          })
        }
      />
    </Modal>
  )
}
