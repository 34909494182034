import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'
import { Form, Modal } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { WidgetNode } from './WidgetNode'
import { SimpleWidgetDefinition } from './createWidgetResource'
import { useNodeState } from '../../../lexical/ui/SettingsDropdown/useNodeState'

export interface WidgetSettingsModalProps<Config extends BaseWidgetConfig>
  extends Pick<SimpleWidgetDefinition<Config, any>, 'title' | 'identifier'> {
  settingsModal: Required<SimpleWidgetDefinition<Config, any>>['settingsModal']
  node: WidgetNode<any, Config>
  visible?: boolean
  onClose: () => void
}
export function WidgetSettingsModal<
  Config extends BaseWidgetConfig,
  SettingsConfig extends Partial<Config>,
>({
  settingsModal: SettingsModal,
  visible = true,
  node,
  title,
  onClose,
}: WidgetSettingsModalProps<Config>) {
  const [editor] = useLexicalComposerContext()
  const [config, setConfig] = useNodeState(
    editor,
    node,
    node => node.getConfig() as unknown as SettingsConfig,
    (node, value) => node.patchConfig(value),
  )
  const form = Form.useForm<SettingsConfig>()

  return (
    <Form.Modal
      title={`${title} Settings`}
      form={form}
      visible={visible}
      onVisibleChange={visible => {
        if (!visible) {
          onClose()
        }
      }}
      defaultValue={config}
      onFinish={values => {
        setConfig(values)
      }}
    >
      <SettingsModal />
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Check />} children={'Save Settings'} />
      </Modal.Footer>
    </Form.Modal>
  )
}
