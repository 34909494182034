import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useNavigate } from '@thesisedu/react'
import { Button, Button$, Form, Modal } from '@thesisedu/ui'
import { SaveActionFloppy } from '@thesisedu/ui/icons'
import React from 'react'

import { RunReportOpts } from '../reporting/types'
import { useCreateSavedReportMutation, CreateSavedReportMutation } from '../schema'

export interface CreateSavedReportButtonProps extends Partial<Button$.ButtonProps> {
  report: RunReportOpts
  onCompleted?: (report: CreateSavedReportMutation) => void
}
export function CreateSavedReportButton({
  report,
  onCompleted,
  ...buttonProps
}: CreateSavedReportButtonProps) {
  const navigate = useNavigate()
  const form = Form.useForm<{ name: string }>()
  const [create] = useCreateSavedReportMutation({
    onError: onMutationError('There was an error saving the report.'),
    onCompleted: onCompleted
      ? onCompleted
      : data => {
          const savedReport = data.createSavedReport.savedReport
          navigate(`../saved/${savedReport.id}`)
        },
  })

  return (
    <Form.Modal
      form={form}
      trigger={
        <Button
          children={'Save Report'}
          variant={'chromeless'}
          icon={<SaveActionFloppy />}
          {...buttonProps}
        />
      }
      title={'Save Report'}
      onFinish={async values => {
        const trimmedName = values.name?.trim()
        if (trimmedName) {
          await create({
            variables: {
              input: {
                name: values.name.trim(),
                report,
              },
            },
          })
        }
      }}
    >
      <Form.TextField
        name={'name'}
        aria-label={'Name'}
        placeholder={'Name your saved report...'}
        autoComplete={'off'}
      />
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Save Report'} icon={<SaveActionFloppy />} disableUntilDirty />
      </Modal.Footer>
    </Form.Modal>
  )
}
