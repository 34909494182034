import { Empty } from '@thesisedu/react'
import { styled, LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { ParentListItem } from './ParentListItem'
import { InternalStandardSelectValue } from './StandardSelect'
import { TagListItem } from './TagListItem'
import { TagsTreeItemFragment, useStandardSetQuery } from '../schema'

export interface StandardSetTreeListProps {
  standardSetId: string
  value?: InternalStandardSelectValue[]
  onChange?: (value?: InternalStandardSelectValue[]) => void
  selectedLevels: string[]
  onSelectedLevelsChange: (selectedLevels: string[]) => void
}
export function StandardSetTreeList({
  standardSetId,
  value,
  onChange,
  selectedLevels,
  onSelectedLevelsChange: setSelectedLevels,
}: StandardSetTreeListProps) {
  const { data, loading } = useStandardSetQuery({
    variables: { id: standardSetId },
  })
  const standardSet = data?.node?.__typename === 'StandardSet' ? data.node : undefined
  const selectedLevelStandards = React.useMemo(() => {
    const result: (TagsTreeItemFragment & { children?: TagsTreeItemFragment[] })[] = []
    if (standardSet) {
      for (const selectedLevel of selectedLevels) {
        const parent = result[result.length - 1]?.children ?? standardSet.tagsTree
        const matchingTag = parent.find(item => item.tag.id === selectedLevel)
        if (matchingTag) result.push(matchingTag)
      }
    }

    return result
  }, [selectedLevels])

  if (loading) {
    return (
      <CenterContainer>
        <LoadingIndicator.Labeled size={'s'} label={'Loading standards...'} />
      </CenterContainer>
    )
  } else if (standardSet) {
    const lastStandardChildren: (TagsTreeItemFragment & { children?: TagsTreeItemFragment[] })[] =
      selectedLevelStandards[selectedLevelStandards.length - 1]?.children ?? standardSet.tagsTree
    return (
      <>
        {selectedLevelStandards.map((selectedLevelStandard, depth) => {
          return (
            <ParentListItem
              key={selectedLevelStandard.tag.id}
              depth={depth}
              tag={selectedLevelStandard}
              onSelect={() => {
                setSelectedLevels(selectedLevels.slice(0, depth))
              }}
            />
          )
        })}
        {lastStandardChildren?.length ? (
          <Container>
            {lastStandardChildren.map(child => {
              return (
                <TagListItem
                  tag={child.tag}
                  key={child.tag.id}
                  value={value?.some(v => v.id === child.tag.id)}
                  hasChildren={!!child.children?.length}
                  onChange={selected => {
                    if (child.children?.length && selected) {
                      setSelectedLevels([...selectedLevels, child.tag.id])
                    } else {
                      const { id, name, type } = child.tag
                      const without = value?.filter(v => v.id !== id) ?? []
                      onChange?.([...without, ...(selected ? [{ id, name, type }] : [])])
                    }
                  }}
                />
              )
            })}
          </Container>
        ) : (
          <CenterContainer>
            <Empty description={'No standards found.'} />
          </CenterContainer>
        )}
      </>
    )
  } else {
    return (
      <CenterContainer>
        <Empty description={'No standards found'} />
      </CenterContainer>
    )
  }
}

const CenterContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`
const Container = styled.div`
  flex: 1;
  overflow-y: auto;
`
