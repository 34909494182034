import { LikeOutlined } from '@ant-design/icons'
import { WebContext } from '@thesisedu/feature-web'
import { Result } from 'antd'
import React, { useContext } from 'react'

export const ViewerTrialMessage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { featureWeb } = useContext(WebContext)!
  return (
    <Result title={`Thanks for trying ${featureWeb.appOptions.name}`} icon={<LikeOutlined />}>
      <p>
        This content is not accessible during your free trial period. Once your site license is
        active, you will be able to access this content.
      </p>
      <p>
        If you have questions, please reach out to{' '}
        <a href={`mailto:sales@${featureWeb.appOptions.domain}`}>
          sales@{featureWeb.appOptions.domain}
        </a>
      </p>
    </Result>
  )
}
