import { useSubmissionData, QuestionProps } from '@thesisedu/feature-assignments-react'
import { MediaView } from '@thesisedu/feature-media-react'
import { Empty } from 'antd'
import React from 'react'

import { SheetMusicPerformanceConfig } from './types'

export function SheetMusicPerformanceAnswer(props: QuestionProps<SheetMusicPerformanceConfig>) {
  const data = useSubmissionData(props.question.id)
  return data ? <MediaView mediaId={data} /> : <Empty description={'No submission.'} />
}
