import { ValueAtPath } from '@thesisedu/feature-types'
import { get } from 'lodash'

import { DEFAULT_THEME } from './defaults'
import { Theme, ThemePath } from './types'

export function setTheme(theme: Theme) {
  ;(global as any).ftuiTheme = theme
}

export function getTheme(): Readonly<Theme> {
  return (global as any).ftuiTheme || DEFAULT_THEME
}

export function getAliases(): Readonly<Theme['aliases']> {
  return getTheme().aliases
}

/**
 * It's recommended to use CSS variables instead of getting theme values directly.
 * You should only use this if you do not have access to the CSS variables.
 */
export function getThemeValue<T extends ThemePath>(path: T): ValueAtPath<Theme, T> {
  return get(getTheme(), path)
}
