import { sprintf as coreSprintf } from 'sprintf-js'

import { notNil } from '../notNil'

export interface FormatPluralOpts {
  plural?: string
  hideCount?: boolean
}
export function formatPlural(
  singular: string,
  count: number,
  { plural = `${singular}s`, hideCount }: FormatPluralOpts = {},
) {
  return [hideCount ? null : count, count === 1 ? singular : plural].filter(notNil).join(' ')
}

export function sprintf(format: string, ...args: any[]) {
  return coreSprintf(format.replace(/%O/g, '%o'), ...args)
}
