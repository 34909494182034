import { CourseQuestion, getSegmentName } from '@thesisedu/feature-course-types'
import { useSegmentCache, SegmentLink, Dragger } from '@thesisedu/feature-courses-react'
import { TruncatedText } from '@thesisedu/react'
import { Check, Cancel, WarningCircle, Star, CpuWarning } from '@thesisedu/react/icons'
import { styled, StyledThemeContext } from '@thesisedu/web'
import { Switch, Tag } from 'antd'
import classnames from 'classnames'
import React, { useContext, useState } from 'react'

import { useSegmentCalculationContext } from './SegmentCalculationContext'

export interface VaultQuestionProps extends Record<string, any> {
  question: CourseQuestion
  isDragging?: boolean
  onViewQuestion: (question: CourseQuestion) => void
  draggerProps?: Record<string, any>
}
export const VaultQuestion = React.forwardRef<HTMLDivElement, VaultQuestionProps>(
  ({ question, isDragging, onViewQuestion, draggerProps, ...rest }, ref) => {
    const context = useSegmentCalculationContext(true)
    const theme = useContext(StyledThemeContext)
    const [loading, setLoading] = useState(false)
    const [hovering, setHovering] = useState(false)
    const segmentCache = useSegmentCache()
    const segment = question.segmentId ? segmentCache[question.segmentId] : undefined
    const performanceIndicator = segment?.tags?.PERFORMANCE_INDICATOR?.[0]

    const coveredTag = question.isCovered ? (
      <Tag color={'blue'}>
        <Check /> Covered
      </Tag>
    ) : (
      <Tag color={'red'}>
        <WarningCircle /> Not Covered
      </Tag>
    )
    const recommendedTag =
      question.config && question.config.isRecommended ? (
        <Tag color={'orange'}>
          <Star /> Recommended
        </Tag>
      ) : null
    const nonAutoGradeableTag = question.autograde ? null : (
      <Tag color={'red'}>
        <CpuWarning /> Not Auto-Gradeable
      </Tag>
    )

    return (
      <Container ref={ref} {...rest}>
        <ContainerInner
          hasPerformanceIndicator={!!performanceIndicator}
          className={classnames({
            hovering,
            dragging: isDragging,
            notCovered: !question.isCovered,
            recommended: question.config && question.config.isRecommended,
          })}
        >
          <Dragger
            {...draggerProps}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          />
          <div className={'name'}>
            <TruncatedText numberOfLines={performanceIndicator ? 1 : 2}>
              <strong>
                <a
                  onClick={e => {
                    e.preventDefault()
                    onViewQuestion(question)
                  }}
                >
                  {question.name} {recommendedTag} {nonAutoGradeableTag}
                </a>
              </strong>
            </TruncatedText>
            {segment ? (
              <SegmentLink id={segment.id}>
                {coveredTag} {getSegmentName(segment)} &rarr;
              </SegmentLink>
            ) : null}
          </div>
          {performanceIndicator ? (
            <div className={'standard'}>
              <span>{performanceIndicator}</span>
              <strong>Performance Indicator</strong>
            </div>
          ) : null}
          <div className={'actions'}>
            <Switch
              onChange={checked => {
                const disabled = !checked
                setLoading(true)
                context
                  .updateOverrides([{ id: question.id, disabled }])
                  .then(() => {
                    setLoading(false)
                  })
                  .catch(() => {
                    setLoading(false)
                  })
              }}
              loading={loading}
              checked={!question.disabled}
              checkedChildren={<Check width={'1.5em'} height={'1.5em'} />}
              unCheckedChildren={<Cancel width={'1.5em'} height={'1.5em'} />}
              style={{
                backgroundColor: !question.disabled ? theme['@green'] : theme['@red'],
              }}
            />
          </div>
        </ContainerInner>
      </Container>
    )
  },
)

const Container = styled.div`
  padding: ${props => props.theme['@size-xxs']} 0;
  display: flex;
`
const ContainerInner = styled.div<{ hasPerformanceIndicator: boolean }>`
  flex: 1;
  display: flex;
  align-items: flex-start;
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-xs']}
    ${props => props.theme['@size-xs']} ${props => props.theme['@size-xxs']};
  transition: background 0.1s linear;
  background: transparent;
  > .name {
    margin-left: 5px;
    width: ${props => (props.hasPerformanceIndicator ? 60 : 100)}%;
    line-height: 1.4;
    strong {
      > a {
        color: ${props => props.theme['@text-color']};
      }
      display: block;
    }
    > a {
      display: inline-block;
      font-size: 12px;
      margin-top: 5px;
    }
  }
  > .standard {
    max-width: 250px;
    width: 30%;
    margin: 0 5px;
    font-size: 12px;
    line-height: 1.2;
    > strong {
      display: block;
      margin-top: 5px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 10px;
      color: ${props => props.theme['@text-color-secondary']};
    }
  }
  > .actions {
    margin-left: auto;
    padding-left: ${props => props.theme['@size-xs']};
  }
  &.recommended {
    background: ${props => props.theme['@orange-light']};
  }
  &.notCovered {
    background: ${props => props.theme['@red-light']};
  }
  &.hovering,
  &.dragging {
    background: ${props => props.theme['@gray-1']};
  }
`
