import { TrialRequestCampaignFragment } from '../schema'

export function hasWelcomeMessage(campaign?: Pick<TrialRequestCampaignFragment, 'welcomeMessage'>) {
  return (
    campaign?.welcomeMessage?.background ||
    campaign?.welcomeMessage?.message ||
    campaign?.welcomeMessage?.title
  )
}

export function getCampaignDismissedFlag(campaign: string) {
  return `trials-campaign-${campaign}`
}
