import { EditOutlined } from '@ant-design/icons'
import {
  ClassConfiguration,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { useResource } from '@thesisedu/feature-react'
import { Modal } from '@thesisedu/web'
import { Button, Form, message } from 'antd'
import React from 'react'

import { useUpdateCourseMutation } from '../queries/useUpdateCourseMutation'
import { CourseFragment } from '../schema'

export interface EditCourseClassConfigurationModalProps {
  course: CourseFragment
  visible?: boolean
  onClose: () => void
}
export function EditCourseClassConfigurationModal({
  course,
  visible,
  onClose,
}: EditCourseClassConfigurationModalProps) {
  const [update, { loading }] = useUpdateCourseMutation({
    onCompleted: () => {
      message.success('Default settings updated successfully!')
      onClose()
    },
  })
  const [form] = Form.useForm()
  React.useEffect(() => {
    if (visible) {
      form.setFieldsValue(course)
    }
  }, [visible])
  return (
    <Modal
      title={'Default Class Settings'}
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          Cancel
        </Button>,
        <Button key={'save'} type={'primary'} onClick={form.submit} loading={loading}>
          Save Changes
        </Button>,
      ]}
      width={600}
      visible={visible}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          update({
            variables: {
              input: {
                id: course.id,
                patch: {
                  classConfiguration: values.classConfiguration,
                },
              },
            },
          })
        }}
      >
        <ClassConfiguration fieldPrefix={['classConfiguration']} />
      </Form>
    </Modal>
  )
}

export interface EditCourseClassConfigurationButtonProps
  extends Pick<EditCourseClassConfigurationModalProps, 'course'> {}
export function EditCourseClassConfigurationButton(props: EditCourseClassConfigurationButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const resources = useResource<ClassConfigurationFieldResource>('ClassConfigurationField')
  return resources.length ? (
    <>
      <EditCourseClassConfigurationModal
        {...props}
        visible={visible}
        onClose={() => setVisible(false)}
      />
      <Button icon={<EditOutlined />} onClick={() => setVisible(true)}>
        Edit Default Class Settings
      </Button>
    </>
  ) : null
}
