import React, { useContext, useMemo } from 'react'

import { SegmentDecorationFragment, useClassDecorationsQuery } from '../schema'

export interface SegmentMap {
  [segmentId: string]: SegmentDecorationFragment
}
export interface SegmentDecorationsContextValue {
  segments: SegmentMap
}
export const SegmentDecorationsContext = React.createContext<SegmentDecorationsContextValue>({
  segments: {},
})

export interface SegmentDecorationsContextProviderProps {
  classId: string
}
export function SegmentDecorationsContextProvider({
  children,
  classId,
}: React.PropsWithChildren<SegmentDecorationsContextProviderProps>) {
  const { data } = useClassDecorationsQuery({
    variables: {
      classId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  })
  const decorations = data?.node?.__typename === 'Class' ? data.node.segmentDecorations : undefined
  const contextValue = useMemo(() => {
    const segments =
      decorations?.reduce<SegmentMap>(
        (acc, decoratedSegment) => ({
          ...acc,
          [decoratedSegment.segmentId]: decoratedSegment,
        }),
        {},
      ) || {}
    return { segments }
  }, [decorations])

  return <SegmentDecorationsContext.Provider value={contextValue} children={children} />
}

export function useSegmentDecoration<T = SegmentDecorationFragment>(
  segmentId: string,
): T | undefined {
  return useContext(SegmentDecorationsContext).segments[segmentId] as unknown as T | undefined
}

export function useSegmentDecorations<T = SegmentDecorationFragment>() {
  return useContext(SegmentDecorationsContext).segments as Record<string, T | null | undefined>
}
