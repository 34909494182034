import { useResource } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import { Tooltip, HSpaced, Text, Button } from '@thesisedu/ui'
import { CheckCircle, MinusCircle } from '@thesisedu/ui/icons'
import { groupBy } from 'lodash'
import React from 'react'

import { LinkedAccountFragment, UserFragment } from '../schema'
import { Logo } from '../setup/Rostering/RosteringHeader'
import { SetupModal } from '../setup/SetupModal'
import { FetchClassStudentsFromProviderContext } from '../setup/steps/fetchClassStudentsFromProvider'
import { TransitionOpts } from '../setup/types'
import { ClassSyncProviderResource } from '../types'
import { ClassSyncItem, useClassSyncSummary } from '../useClassSyncSummary'

interface ConnectedAccountProps {
  linkedAccount: PickedLinkedAccount
}
function ConnectedAccount({ linkedAccount }: ConnectedAccountProps) {
  const resource = useResource<AuthProviderHandlerResource>(
    'AuthProviderHandler',
    linkedAccount.provider,
    true,
  )
  const textColor: 'green' | 'red' = linkedAccount.authorized ? 'green' : 'red'
  return (
    <Tooltip
      contentProps={{ align: 'start' }}
      title={
        <div style={{ textAlign: 'left' }}>
          <Text>{linkedAccount.providerName}</Text>
          <Text level={'s'} color={'secondary'}>
            {linkedAccount.label}
          </Text>
          <HSpaced space={'xs'} top={'xs'}>
            <Text level={'xs'} color={textColor}>
              {linkedAccount.authorized ? <CheckCircle /> : <MinusCircle />}
            </Text>
            <Text level={'xs'} color={textColor}>
              {linkedAccount.authorized
                ? 'Has logged in with this account'
                : 'Has not logged in with this account'}
            </Text>
          </HSpaced>
        </div>
      }
    >
      <Logo src={resource.squareLogoPath} alt={`Logo for ${linkedAccount.providerName}`} />
    </Tooltip>
  )
}

interface DisconnectedAccountProps {
  userId: string
  syncId: string
  provider: ClassSyncItem
}
function DisconnectedAccount({ provider, syncId, userId }: DisconnectedAccountProps) {
  const resource = useResource<ClassSyncProviderResource>(
    'ClassSyncProvider',
    provider.providerIdentifier,
    true,
  )
  const [setupVisible, setSetupVisible] = React.useState(false)
  return (
    <>
      <SetupModal
        visible={setupVisible}
        onVisibleChange={setSetupVisible}
        defaultStep={
          {
            context: {
              provider: {
                ...provider,
                identifier: provider.providerIdentifier,
                name: provider.providerName,
                requiresSetup: false,
              },
              highlightUserIds: [userId],
              exitAfterRostering: true,
              syncId,
            },
            step: 'fetchClassStudentsFromProvider',
          } satisfies TransitionOpts<FetchClassStudentsFromProviderContext>
        }
      />
      <Tooltip
        contentProps={{ align: 'start' }}
        title={
          <div style={{ textAlign: 'left' }}>
            <Text>
              {provider.providerName} - {provider.label}
            </Text>
            <Text level={'s'} color={'secondary'}>
              Not Connected. Click on the logo to connect.
            </Text>
          </div>
        }
      >
        <Button style={{ padding: 0 }} variant={'chromeless'} onPress={() => setSetupVisible(true)}>
          <Logo
            src={resource.logos.square}
            alt={`Logo for ${provider.providerName}`}
            style={{ opacity: 0.5 }}
          />
        </Button>
      </Tooltip>
    </>
  )
}

type PickedLinkedAccount = Pick<
  LinkedAccountFragment,
  'authorized' | 'provider' | 'providerName' | 'label'
>
export interface StudentAccountSummaryProps {
  linkedAccounts: PickedLinkedAccount[]
  user: UserFragment
  classId: string
  hideCreate?: boolean
}
export function StudentAccountSummary({
  linkedAccounts,
  user,
  classId,
  hideCreate,
}: StudentAccountSummaryProps) {
  const { syncs, canAttach } = useClassSyncSummary(classId)
  const uniqueSyncs = Object.values(
    groupBy(
      syncs.filter(sync => !!sync.authProviderId),
      sync => sync.authProviderId,
    ),
  ).flat()
  const emptySyncs =
    hideCreate || !canAttach
      ? []
      : uniqueSyncs.filter(
          sync =>
            sync.authProviderId &&
            !linkedAccounts.some(linkedAccount => linkedAccount.provider === sync.authProviderId),
        )
  const linkedAccountsWithSyncs = linkedAccounts.filter(linkedAccount => {
    return uniqueSyncs.some(sync => sync.authProviderId === linkedAccount.provider)
  })
  return (
    <HSpaced space={'xs'}>
      {linkedAccountsWithSyncs.map(linkedAccount => {
        return <ConnectedAccount key={linkedAccount.provider} linkedAccount={linkedAccount} />
      })}
      {emptySyncs.map(emptySync => {
        return (
          <DisconnectedAccount
            key={emptySync.providerId}
            provider={emptySync}
            userId={user.id}
            syncId={emptySync.syncId}
          />
        )
      })}
    </HSpaced>
  )
}
