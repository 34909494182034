import { Body, styled } from '@thesisedu/web'
import React from 'react'

import { UploadStatusIcon } from './UploadStatusIcon'
import { UploadStatusLabel } from './UploadStatusLabel'
import { Upload } from './types'

const Container = styled.div`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  cursor: pointer;
  display: flex;
  align-items: center;
`
const RightContainer = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
`

export interface UploadListItemProps {
  upload: Upload
  onClick: () => any
}
export const UploadListItem: React.FC<React.PropsWithChildren<UploadListItemProps>> = ({
  upload,
  onClick,
}) => (
  <Container onClick={onClick}>
    <div>
      <Body>{upload.label}</Body>
      <UploadStatusLabel upload={upload} />
    </div>
    <RightContainer>
      <UploadStatusIcon upload={upload} />
    </RightContainer>
  </Container>
)
