import { StyledThemeContext as ThemeContext } from '@thesisedu/web'
import React, { useContext, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'

export interface PathTextFromPeaksResult {
  bound: number
  path: string
}
export const getPathTextFromPeaks = (peaks: number[], offset: number): PathTextFromPeaksResult => {
  let pathText = ''

  const bound = peaks.reduce<number>((max, peak) => {
    if (Math.abs(peak) > max) {
      return Math.abs(peak)
    } else {
      return max
    }
  }, 1)

  // Prepare the minimums.
  for (let i = 0; i < peaks.length / 2; i++) {
    const min = peaks[2 * i + 1]
    if (pathText) {
      pathText += `V${min + bound}`
    } else {
      pathText += `M${offset} ${min + bound}`
    }
    pathText += 'h1'
  }

  // Prepare the maximums.
  for (let i = peaks.length / 2 - 1; i >= 0; i--) {
    const max = peaks[2 * i]
    pathText += `V${max + bound}h-1`
  }

  // Close the loop.
  pathText += 'Z'
  return { bound, path: pathText }
}

export interface TrimSettings {
  seconds: number
  duration: number
}
export interface WaveformViewProps {
  peaks: number[]
  percentage?: number
  scaleX?: number
  trimSeconds?: number
  trimDuration?: number
  limitSamples?: number
}
export const WaveformView = React.memo<WaveformViewProps>(
  ({ peaks, percentage, scaleX = 1, trimSeconds, trimDuration, limitSamples }) => {
    const theme = useContext(ThemeContext)
    const samplesToRemove =
      trimSeconds && trimDuration ? (trimSeconds / trimDuration) * (peaks.length / 2) : 0
    const rightBound = Math.max(limitSamples || (peaks.length * scaleX) / 2, 1)
    const { bound, path } = useMemo(
      () => getPathTextFromPeaks(peaks, samplesToRemove * -1),
      [peaks, samplesToRemove],
    )
    const boundMin = bound * 0.05
    const [maskuuid] = useState(uuid())
    return (
      <svg preserveAspectRatio={'none'} viewBox={`0 0 ${rightBound} ${bound * 2}`}>
        <mask id={maskuuid}>
          <path d={path} fill={'white'} />
          <path
            d={`M0 ${bound - boundMin / 2}v${boundMin}h${rightBound}v-${boundMin}Z`}
            fill={'white'}
          />
        </mask>
        <rect
          x={0}
          y={0}
          width={rightBound}
          height={bound * 2}
          fill={'currentColor'}
          mask={`url(#${maskuuid})`}
        />
        <rect
          x={0}
          y={0}
          width={(percentage || 0) * rightBound}
          height={bound * 2}
          fill={theme['@primary-color']}
          style={{ transition: 'width 0.5s linear' }}
          mask={`url(#${maskuuid})`}
        />
      </svg>
    )
  },
)
