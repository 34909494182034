import { ReportDimensionTableHeaderProps } from '@thesisedu/feature-reports-react'
import { BodySmall, styled } from '@thesisedu/web'
import React from 'react'

import { ReportStandardSummaryItem } from '../Standards'

export function useGetStandardHeader() {
  return ({ result }: ReportDimensionTableHeaderProps<ReportStandardSummaryItem>) => {
    return result.tag.name
  }
}

export function StandardHeader({
  result,
}: ReportDimensionTableHeaderProps<ReportStandardSummaryItem>) {
  return <Name>{result.tag.name}</Name>
}

const Name = styled(BodySmall)`
  overflow: hidden;
  text-overflow: ellipsis;
`
