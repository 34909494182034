import React from 'react'

import { useDragDropOutlineContext } from './DragDropOutlineContext'

const AUTO_NAVIGATE_DELAY = 1000
export type NavigateCallback = (hoverId: string, draggableId: string) => number | undefined
export interface UseHoverNavigateResult {
  onMouseEnter: (id: string) => void
  onMouseLeave: (id: string) => void
}
export function useHoverNavigate(callback: NavigateCallback): UseHoverNavigateResult {
  const autoNavigateTimeout = React.useRef<any>()
  const hoveringOver = React.useRef<string>()
  const startScroll = React.useRef<number>()
  const { draggingId } = useDragDropOutlineContext(false) || {}
  React.useEffect(() => {
    if (draggingId) {
      const listener = () => {
        if (hoveringOver.current) {
          window.scrollTo(0, startScroll.current || 0)
        }
      }
      window.addEventListener('scroll', listener, { passive: true })
      return () => {
        window.removeEventListener('scroll', listener)
      }
    }
  }, [!!draggingId])

  return {
    onMouseEnter: id => {
      if (draggingId) {
        hoveringOver.current = id
        startScroll.current = window.scrollY
        if (autoNavigateTimeout.current) {
          clearTimeout(autoNavigateTimeout.current)
        }
        autoNavigateTimeout.current = setTimeout(() => {
          if (draggingId) {
            const result = callback(id, draggingId)
            if (result !== undefined) {
              startScroll.current = result
              window.scrollTo(0, result)
            }
          }
        }, AUTO_NAVIGATE_DELAY)
      }
    },
    onMouseLeave: id => {
      if (hoveringOver.current === id) {
        hoveringOver.current = undefined
      }
      if (autoNavigateTimeout.current) {
        clearTimeout(autoNavigateTimeout.current)
        autoNavigateTimeout.current = undefined
      }
    },
  }
}
