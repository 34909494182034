import {
  QuestionAssignmentViewer,
  QuestionSettingsProvider,
  useSupportsRealTimeGrading,
} from '@thesisedu/feature-assignments-react'
import { getRealTimeGradingFlag } from '@thesisedu/feature-assignments-react/dist/questions/QuestionAssignmentRealTimeGradingSampleViewer'
import { useIsSegmentPreview } from '@thesisedu/feature-courses-react'
import { DismissablePopover, setFlag, useFlag } from '@thesisedu/react'
import { Button, HSpaced, Switch, Tooltip, VSpaced } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { QuestionSettingsSaveChanges } from './QuestionSettingsSaveChanges'
import { useAssignmentForSegment } from './useAssignmentForSegment'
import { FullAssignmentSubmissionFragment } from '../../schema'

export interface AssignmentViewerProps {
  templateId: string
  segmentId: string
  teacherView?: boolean
  forceShowAssignment?: boolean
  /**
   * If this is true, an interactive review switch will be displayed above the
   * assignment form and, once toggled one, allow teachers to work through the
   * assignment with real-time grading turned on.
   */
  enableInteractiveReview?: boolean
  submission?: FullAssignmentSubmissionFragment
}
export function AssignmentViewer({
  forceShowAssignment,
  templateId,
  segmentId,
  teacherView,
  enableInteractiveReview: _enableInteractiveReview,
  submission,
}: AssignmentViewerProps) {
  const { assignment, assignmentResult, classId } = useAssignmentForSegment(segmentId)
  const isPreview = useIsSegmentPreview(segmentId)
  const supportsRealTimeGrading = useSupportsRealTimeGrading(assignment) ?? true
  const [_isInteractiveReview, setIsInteractiveReview] = useFlag(
    `interactive-review-enabled:${segmentId}`,
    false,
  )
  const isInteractiveReview = supportsRealTimeGrading && _isInteractiveReview
  const enableInteractiveReview = supportsRealTimeGrading && _enableInteractiveReview

  let content = (
    <QuestionAssignmentViewer
      classId={classId || 'no-class-id'} // We won't have a class ID when exploring content.
      templateId={templateId}
      assignment={assignment || undefined}
      forceShowAssignment={forceShowAssignment}
      loading={assignmentResult.loading}
      onFirstSubmissionCreated={() => {
        assignmentResult.refetch()
      }}
      teacherView={
        isInteractiveReview && enableInteractiveReview ? 'real-time-grading' : teacherView
      }
      submission={submission}
    />
  )

  if (enableInteractiveReview) {
    content = (
      <VSpaced space={'l'}>
        <DismissablePopover.NewFeature
          dismissableKey={'course-assignments:interactive-review'}
          title={'New feature: Interactive Review'}
          description={'Work through assignments as a teacher with real-time grading turned on.'}
          expiresAt={'2024-03-01'}
          contentProps={{ side: 'right', align: 'start' }}
        >
          <HSpaced>
            <Switch
              label={'Interactive Review'}
              checked={isInteractiveReview}
              onChange={setIsInteractiveReview}
            />
            {isInteractiveReview ? (
              <Tooltip
                title={
                  'This will clear your answers and disable interactive review mode, for a fresh assignment.'
                }
              >
                <Button
                  variant={'ghost'}
                  icon={<Trash />}
                  status={'danger'}
                  children={'Reset Interactive Review'}
                  onPress={() => {
                    setFlag(getRealTimeGradingFlag(templateId), {})
                    /**
                     * We have to do this because the form does not like having its values
                     * reset completely while it is mounted. Since this feature isn't really
                     * _that_ important, we use this hack to workaround that instead of
                     * creating some truly janky state management code to get the form to
                     * properly reset.
                     */
                    setIsInteractiveReview(false)
                  }}
                />
              </Tooltip>
            ) : null}
          </HSpaced>
        </DismissablePopover.NewFeature>
        {content}
      </VSpaced>
    )
  }

  return teacherView && !isPreview ? (
    <QuestionSettingsProvider assignment={assignment}>
      <QuestionSettingsSaveChanges assignment={assignment} />
      {content}
    </QuestionSettingsProvider>
  ) : (
    content
  )
}
