import { Icon } from '@ant-design/compatible'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { getSegmentName } from '@thesisedu/feature-course-types'
import { useMutateHook } from '@thesisedu/feature-react'
import { ThumbnailLoadingContent } from '@thesisedu/feature-video-on-demand-react/dist/VideoThumbnail'
import { FontWeight, styled, StyledComponent, useImageLoad, ImageLoadResult } from '@thesisedu/web'
import React from 'react'

import { SegmentGridRendererResource, SegmentGridItemProps } from '../../explore/grid/types'
import { getIconForSegment } from '../../helpers'
import { MutateDefaultSegmentThumbnail } from '../../types'

function DefaultGridRenderer({ segment, width }: SegmentGridItemProps) {
  const thumbnail: string | undefined =
    segment.config?.thumbnail?.square || segment.config?.thumbnail?.landscape
  const defaultThumbnail = useMutateHook<MutateDefaultSegmentThumbnail>(
    'feature-courses-react:default-segment-thumbnail',
    <ImagePlaceholder>
      <div>
        <IconContainer>
          {/* @ts-ignore react 18 support */}
          <Icon type={getIconForSegment(segment)} theme={'outlined'} />
        </IconContainer>
      </div>
    </ImagePlaceholder>,
    { segment },
  )
  let image = defaultThumbnail
  if (thumbnail) {
    const aspectRatio = thumbnail === segment.config?.thumbnail?.landscape ? 16 / 9 : 1
    image = <SegmentThumbnailView url={thumbnail} aspectRatio={aspectRatio} width={width} />
  }
  return (
    <>
      {image}
      <SegmentGridTitle>{getSegmentName(segment)}</SegmentGridTitle>
      <SegmentGridSubtitle>{segment.config?.label || segment.type}</SegmentGridSubtitle>
    </>
  )
}

export interface SegmentThumbnailViewProps {
  url: string
  aspectRatio?: number
  width: number
}

function SegmentThumbnailView({ url, aspectRatio = 1, width }: SegmentThumbnailViewProps) {
  const { filename, label } = getFileInfo(url)
  const imageLoadState = useImageLoad(filename)
  return (
    <ThumbnailLoadingContent
      loading={imageLoadState === ImageLoadResult.Loading}
      error={imageLoadState === ImageLoadResult.Error}
      aspectRatio={aspectRatio}
    >
      <ImageContainer aspectRatio={aspectRatio} width={width}>
        <img src={filename} alt={label} />
      </ImageContainer>
    </ThumbnailLoadingContent>
  )
}
const ImagePlaceholder = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix.
  background-image: linear-gradient(
    146deg,
    ${props => props.theme['@gray-1']} 8%,
    ${props => props.theme['@gray-2']} 100%
  );
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
interface ImageContainerProps {
  width: number
  aspectRatio: number
}
export const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix
  position: relative;
  img {
    margin: 0 auto;
    max-height: ${props => props.width * props.aspectRatio}px;
    display: block;
    max-width: 100%;
  }
`
const IconContainer = styled.div`
  color: ${props => props.theme['@gray-5']};
  font-size: ${props => props.theme['@size-l']};
  width: ${props => props.theme['@size-xxl']};
  height: ${props => props.theme['@size-xxl']};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.theme['@gray-5']};
  color: white;
  svg {
    display: block;
  }
`
export const SegmentGridTitle: StyledComponent<'div'> = styled.div`
  margin-top: ${props => props.theme['@size-s']};
  font-weight: ${FontWeight.SemiBold};
  color: ${props => props.theme['@text-color']};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: ${props => props.theme['@font-size-lg']};
  line-height: calc(${props => props.theme['@font-size-lg']} * 1.4);
  max-height: calc(${props => props.theme['@font-size-lg']} * 1.4 * 2);
`
export const SegmentGridSubtitle: StyledComponent<'div'> = styled.div`
  color: ${props => props.theme['@text-color-secondary']};
  font-size: ${props => props.theme['@font-size-sm']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const resource: SegmentGridRendererResource = {
  type: 'SegmentGridRenderer',
  identifier: 'DefaultGridRenderer',
  height: ({ width, segment }) => {
    const aspectRatio = segment.config?.thumbnail?.square ? 1 : 9 / 16
    return width * aspectRatio + 125
  },
  // Forcefully use the DefaultGridRenderer for segments with thumbnails.
  weight: segment => (Object.keys(segment.config?.thumbnail ?? {}).length ? -Infinity : 1000),
  Component: DefaultGridRenderer,
}
export default resource
