import { useResource } from '@thesisedu/feature-react'
import { styled, s, Modal } from '@thesisedu/ui'
import React from 'react'

import { LoadingStep } from './LoadingStep'
import { PreAuthenticateScreen } from './PreAuthenticateScreen'
import { AuthenticateOpts } from './ProviderAuthContext'
import { ProviderError } from './ProviderError'
import { AuthProviderHandlerResource } from '../types'

export interface AuthProviderWrapperProps extends AuthenticateOpts {
  identifier: string
  onAuth: () => void | Promise<void>
  onError: (err: any) => void
  associating?: boolean
}
export function AuthProviderWrapper({
  identifier,
  onAuth,
  onError,
  associating,
  headless,
  hadError,
}: AuthProviderWrapperProps) {
  const resource = useResource<AuthProviderHandlerResource>('AuthProviderHandler', identifier)
  if (!resource) throw new Error(`Cannot find auth provider '${identifier}'`)
  const [preState, setPreState] = React.useState<'error' | 'preAuth' | undefined>(
    hadError ? 'error' : resource.preAuthenticateScreen && !headless ? 'preAuth' : undefined,
  )

  React.useEffect(() => {
    if (!preState) {
      const handle = setTimeout(() => {
        onAuth()
      }, 1000)
      return () => {
        clearTimeout(handle)
      }
    }
  }, [preState])

  if (headless) return null
  return (
    <StyledModal
      visible
      onVisibleChange={v => {
        if (!v) onError(undefined)
      }}
      noClose={preState !== 'error'}
      width={550}
    >
      {preState === 'error' ? (
        <ProviderError
          providerName={resource.name}
          providerLogo={resource.squareLogoPath}
          onNext={() => {
            setPreState(resource.preAuthenticateScreen ? 'preAuth' : undefined)
          }}
        />
      ) : preState === 'preAuth' ? (
        <LoadingStep
          providerName={resource.name}
          providerLogo={resource.squareLogoPath}
          state={'connect'}
          title={`Connect to ${resource.name}`}
          message={'Before you connect your account, review the steps below.'}
          action={
            <PreAuthenticateScreen
              providerId={resource.identifier}
              onContinue={() => {
                setPreState(undefined)
              }}
            />
          }
        />
      ) : (
        <LoadingStep
          providerName={resource.name}
          providerLogo={resource.squareLogoPath}
          direction={associating ? 'left' : 'right'}
          title={`Logging in with ${resource.name}`}
          message={associating ? 'Finishing up...' : 'Follow the steps in the dialog that appears.'}
        />
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  padding-top: ${s.size('xl')};
  padding-bottom: ${s.size('xl')};
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`
