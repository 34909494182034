import { DATE_FORMATS } from '@thesisedu/react'
import moment from 'moment'

import { FullAssignmentSubmissionFragment } from '../schema'

export function getSubmissionSubtitle(
  submission?: Pick<FullAssignmentSubmissionFragment, 'submittedAt' | 'createdAt'>,
): string {
  const verb = submission?.submittedAt ? 'Submitted' : 'Created'
  const field = submission?.submittedAt || submission?.createdAt
  return submission ? `${verb} ${moment(field).format(DATE_FORMATS.FULL)}` : 'Not Started'
}
