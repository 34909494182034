import * as ReactSelect from '@radix-ui/react-select'
import React from 'react'

import { styled, s } from '../../'
import { NavArrowDown, NavArrowUp } from '../../../icons'
import { wrapPortal } from '../../style/wrapPortal'
import { DropdownContentStyles } from '../Dropdown'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusRing } from '../FocusRing/FocusRing'
import Text from '../Text'
import { _InputContainer, _variants } from '../TextField'

export interface SelectProps<Value extends string = string>
  extends ExtendedBareFieldProps,
    Omit<ReactSelect.SelectProps, 'disabled' | 'defaultValue' | 'value' | 'onValueChange'>,
    Pick<ReactSelect.SelectContentProps, 'position'> {
  style?: React.CSSProperties
  className?: string
  placeholder?: string
  disabled?: boolean
  size?: s.SharedVariants.Size.Type
  children: React.ReactNode
  prefix?: React.ReactElement
  portalContainer?: ReactSelect.SelectPortalProps['container']
  defaultValue?: Value
  value?: Value
  onValueChange?: (value: Value) => void
}
function _Select<Value extends string = string>(
  {
    style,
    className,
    placeholder,
    disabled,
    size = s.SharedVariants.Size.defaultValue,
    children,
    prefix,
    portalContainer,
    ...props
  }: SelectProps<Value>,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const {
    bareFieldProps,
    rest: { position, ...rest },
  } = getBareFieldProps(props)
  const contentProps = { position }
  return (
    <ReactSelect.Root {...rest} disabled={disabled}>
      <BareField {...bareFieldProps} style={style}>
        <FocusRing>
          <SelectTrigger
            ref={ref}
            aria-label={typeof props.label === 'string' ? props.label : undefined}
            data-testid={(props as any)['data-testid']}
            className={s.variants<typeof _variants>(
              className,
              {
                disabled,
                error: !!props.error,
              },
              size,
            )}
          >
            {prefix}
            <Text truncate level={null}>
              <ReactSelect.Value placeholder={placeholder} />
            </Text>
            <SelectIcon>
              <NavArrowDown />
            </SelectIcon>
          </SelectTrigger>
        </FocusRing>
      </BareField>
      <SelectPortal container={portalContainer}>
        <SelectContent {...contentProps}>
          <SelectScrollUpButton>
            <NavArrowUp />
          </SelectScrollUpButton>
          <SelectViewport>{children}</SelectViewport>
          <SelectScrollDownButton>
            <NavArrowDown />
          </SelectScrollDownButton>
        </SelectContent>
      </SelectPortal>
    </ReactSelect.Root>
  )
}
export const Select = React.forwardRef(_Select)

const { css } = s
const _Trigger = s.styledWithVariants(
  _InputContainer,
  'SelectTrigger',
  css`
    display: flex;
    align-items: center;
    gap: ${s.size('xs')};
    &:hover:not([data-disabled]) {
      background: ${s.color('gray.hoverElement')};
    }
    &[data-placeholder] {
      color: ${s.color('secondary')};
    }
  `,
  {},
)
function _SelectTrigger(
  props: ReactSelect.SelectTriggerProps & React.ComponentProps<typeof _Trigger>,
  ref: React.ForwardedRef<HTMLElement>,
) {
  return <_Trigger as={ReactSelect.SelectTrigger} {...props} ref={ref} />
}
const SelectTrigger = React.forwardRef(_SelectTrigger)

const SelectIcon = styled(ReactSelect.SelectIcon)`
  margin-left: auto;
  color: var(--text-color, ${s.color('gray.secondary')});
`
const SelectPortal = wrapPortal(ReactSelect.Portal)
const SelectContent = styled(ReactSelect.Content)`
  ${DropdownContentStyles}
  max-height: var(--radix-select-content-available-height);
  overflow: hidden;
`
const SelectViewport = styled(ReactSelect.Viewport)`
  padding: ${s.size('xxs')};
`

const ScrollButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${s.size('l')};
  background: ${s.color('gray.background')};
  color: ${s.color('gray.secondary')};
  cursor: default;
`
const SelectScrollUpButton = styled(ReactSelect.ScrollUpButton)`
  ${ScrollButtonStyle}
`
const SelectScrollDownButton = styled(ReactSelect.ScrollDownButton)`
  ${ScrollButtonStyle}
`
