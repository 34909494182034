import { GeneratePDFButton, useIsGeneratingPdf } from '@thesisedu/feature-pdf-generation-react'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { s, styled } from '@thesisedu/ui'
import React from 'react'

import { PDFGenerationConfig } from './common'
import { useCourseContext } from '../../../contexts/CourseContext'
import { useCourseEditorReadContext } from '../../../contexts/CourseEditorContext'
import { useSegmentContext } from '../../../segment/Segment'

export function PDFGenerationElement({
  config,
  id,
}: SimpleWidget.SimpleWidgetProps<PDFGenerationConfig>) {
  const { id: segmentId } = useSegmentContext(true)
  const isGeneratingPdf = useIsGeneratingPdf()
  const editorContext = useCourseEditorReadContext(false)
  const courseContext = useCourseContext(false)
  const courseVersionId = editorContext?.courseVersionId || courseContext?.courseVersion?.id
  if (isGeneratingPdf || !courseVersionId) return null

  return (
    <Container>
      <GeneratePDFButton
        requestData={{ segmentId, widgetId: id, courseVersionId }}
        buttonProps={{ children: 'Download as PDF' }}
        type={'segment'}
      />
    </Container>
  )
}

const Container = styled.div`
  text-align: right;
  margin-bottom: ${s.size('s')};
`
