import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { BookmarkButton } from './BookmarkButton'
import { useBookmarksContext } from './BookmarksContext'
import { useRecordStateContext } from '../../record/states/record/RecordStateContext'
import { RecordFooterButtonStyles } from '../../record/states/record/footer/RecordFooterButton'

export function BookmarkFooterButton() {
  const { state, setState } = useRecordStateContext(true)
  const { draftBookmark } = useBookmarksContext(true)
  return (
    <Button
      disabled={state?.type !== 'recording'}
      $active={!!draftBookmark}
      getCurrentTimeSeconds={() => {
        return state?.type === 'recording' ? (Date.now() - state.startTime) / 1000 : null
      }}
      onPause={() => {
        setState({ type: 'paused' })
      }}
      onResume={() => {
        // It should automatically recalculate the start time.
        setState({ type: 'recording', startTime: 0 })
      }}
    />
  )
}

const Button = styled(BookmarkButton)<{ $active: boolean }>`
  ${RecordFooterButtonStyles}
  ${s.ifs(props => props.$active)} {
    --accent-color: ${s.color('orange')};
    --accent-hover-color: ${s.color('orange.hoverSolid')};
    --accent-active-color: ${s.color('orange.activeSolid')};
    svg {
      fill: currentColor;
    }
  }
`
