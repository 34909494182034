import { AssignmentOverrides } from '@thesisedu/feature-assignments-core'
import { useIds } from '@thesisedu/feature-react'
import React from 'react'

import { useGradingModalSubmissionContext } from './GradingModalSubmissionContext'
import { useAnswerFocus } from './useAnswerFocus'
import {
  AssignmentWithRemainingAttemptsFragment,
  BasicAssignmentFragment,
  BasicAssignmentSubmissionFragment,
  SaveAssignmentSubmissionInput,
  SubmitAssignmentInput,
} from '../schema'
import { QuestionProps } from '../types'

export interface GroupSettings {
  groupMemberIds: string[]
  groupLeader?: string
  isGroupLeader?: boolean
  areAnswersShared?: boolean
}
export interface SubmissionContextValue {
  submissionData?: Record<string, any>
  realTimeSubmissionData?: Record<string, any>
  questionMetadata?: Record<string, Record<string, any>>
  overrides?: AssignmentOverrides | null
  /**
   * This overrides the answerView prop on the questions viewer.
   * It should not be used inside the question components.
   */
  forcefullyShowAnswers?: boolean
  /**
   * This is true if the assignment submission has been submitted and
   * graded, and therefore we should reveal the incorrect answers, but
   * not the correct answers themselves.
   */
  revealIncorrectAnswers?: boolean
  assignment: BasicAssignmentFragment & AssignmentWithRemainingAttemptsFragment
  studentId?: string
  classId: string
  /** Returns the submission ID once saved. */
  onSaveSubmission: (
    input: SaveAssignmentSubmissionInput,
    includeConfiguration?: boolean,
  ) => Promise<string | undefined>
  /**
   * Cancels the save debounce callback when values are changed. Use this
   * when you want to prevent the assignment from automatically saving.
   */
  cancelValuesDebounce: () => void
  onFieldsChange: (input: SaveAssignmentSubmissionInput, setImmediately?: boolean) => void
  /** The submission currently being worked on, if there is one. */
  submission?: Omit<BasicAssignmentSubmissionFragment, 'submissionData'>
  onRetry: () => void
  onSubmit: (input: SubmitAssignmentInput) => Promise<any>
  groups: GroupSettings
  /** True if the assignment is being saved or submitted. */
  loading?: boolean
  /** Override the submit button message. */
  submitMessage?: string
  /**
   * If this is passed, it's presented immediately to the right of the submit button.
   * The submit button is also marked with the default style rather than the primary style.
   */
  submitAlternative?: React.ReactElement
  /** If this is true, the submit button is not shown. */
  hideSubmit?: boolean
  /** If this is true, the confirmation before submitting the assignment is skipped. */
  skipSubmitConfirmation?: boolean
  /** Called whenever a new submission is created. */
  onSubmissionCreated?: (submissionId: string) => void
}
export const SubmissionContext = React.createContext<SubmissionContextValue | undefined>(undefined)

export interface SubmissionContextProviderProps {
  value: SubmissionContextValue
}
export function SubmissionContextProvider({
  value,
  children,
}: React.PropsWithChildren<SubmissionContextProviderProps>) {
  const existing = useSubmissionContext(false)
  const gradingModalContext = useGradingModalSubmissionContext(false)
  const gradingModalSubmission = gradingModalContext?.submission
  useIds(value.submission?.id ? [{ id: value.submission.id, label: 'Submission Id' }] : [])
  return (
    <SubmissionContext.Provider
      value={{
        ...existing,
        ...value,
        questionMetadata:
          existing?.questionMetadata ||
          value.questionMetadata ||
          gradingModalSubmission?.questionMetadata ||
          undefined,
        submission:
          existing?.submission || gradingModalSubmission || value.submission
            ? ({
                ...gradingModalSubmission,
                ...existing?.submission,
                ...value.submission,
              } as any)
            : undefined,
      }}
      children={children}
    />
  )
}

type RequiredFields = 'assignment' | 'classId'
export interface StubSubmissionContextProviderProps {
  children: React.ReactNode
  value: Pick<SubmissionContextValue, RequiredFields> &
    Omit<Partial<SubmissionContextValue>, RequiredFields>
}
export function StubSubmissionContextProvider({
  children,
  value,
}: StubSubmissionContextProviderProps) {
  return (
    <SubmissionContextProvider
      value={{
        cancelValuesDebounce() {},
        groups: { groupMemberIds: [] },
        onFieldsChange() {},
        onRetry() {},
        onSaveSubmission() {
          throw new Error(
            'onSaveSubmission is not implemented inside StubSubmissionContextProvider.',
          )
        },
        onSubmit() {
          throw new Error('onSubmit is not implemented inside StubSubmissionContextProvider.')
        },
        ...value,
      }}
      children={children}
    />
  )
}

export function useHasSubmission() {
  return useSubmissionContext(false)?.submission != null
}
export function useSubmissionData(id: string, defaultValue?: any) {
  const context = useSubmissionContext(false)
  if (context) {
    const { submissionData, realTimeSubmissionData } = context
    return realTimeSubmissionData?.[id] || submissionData?.[id] || defaultValue
  } else {
    return undefined
  }
}

export function useQuestionMetadata(id: string) {
  const { questionMetadata } = useSubmissionContext(false) || {}
  return questionMetadata?.[id]
}

export function useSubmissionProps({
  disabled,
  question,
}: Pick<QuestionProps, 'disabled' | 'question'>) {
  const { assignment } = useSubmissionContext(false) || {}
  const answerFocus = useAnswerFocus({
    assignmentId: assignment?.id,
    metadata: {
      questionId: question.id,
      type: question.type,
      name: question.name,
      assignmentId: assignment?.id,
    },
  })
  return React.useMemo(
    () => ({
      ...answerFocus,
      disabled,
    }),
    [disabled, answerFocus.onBlur, answerFocus.onFocus],
  )
}

export function useSubmissionContext(): SubmissionContextValue | undefined
export function useSubmissionContext(require: false): SubmissionContextValue | undefined
export function useSubmissionContext(require: true): SubmissionContextValue
export function useSubmissionContext(require?: boolean): SubmissionContextValue | undefined {
  const context = React.useContext(SubmissionContext)
  if (!context && require) {
    throw new Error('SubmissionContext is required, yet not provided.')
  }
  return context
}
