import { useState } from 'react'

import { warn } from './log'
import { ClassSyncFragment, useAllClassSyncsQuery } from './schema'
import { MinimalAssignmentSyncFragment, SyncAssignmentActionsProps } from './types'
import { useAssignmentClassSyncProviders } from './useClassSyncSummary'

export interface SyncAssignmentActionItem {
  providerName: string
  providerIdentifier: string
  classNameOnProvider: string
  /** This is the class sync ID. */
  syncId: string
  isSynced: boolean
  needsStudents: boolean
  sync: MinimalAssignmentSyncFragment | undefined
}

export interface UseSyncAssignmentActions {
  onItemSelected: (syncId: string) => void
  loadingId?: string | null
  loadingSyncs?: boolean
  items: SyncAssignmentActionItem[]
}

interface MessageProps {
  onError: (message: string, prominent?: boolean) => void
  onSuccess: (message: string) => void
}
type ClassSyncItem = ClassSyncFragment & { classId: string }
export function useSyncAssignmentActions({
  syncs,
  onSync,
  assignedClassIds,
  classIds,
  onError,
  onSuccess,
}: SyncAssignmentActionsProps & MessageProps): UseSyncAssignmentActions {
  const { data, loading: loadingSyncs } = useAllClassSyncsQuery()
  const providers = useAssignmentClassSyncProviders()
  const classSyncs =
    data?.viewer?.teacher?.classes.edges.reduce<ClassSyncItem[]>((acc, classEdge) => {
      const classId = classEdge.node.id
      if (classIds && !classIds.includes(classId)) {
        return acc
      } else {
        return [
          ...acc,
          ...classEdge.node.syncs.edges.map(edge => ({
            ...edge.node,
            classId: classEdge.node.id,
          })),
        ]
      }
    }, []) || []
  const [loadingId, setLoadingId] = useState<string | null>(null)
  let items: SyncAssignmentActionItem[] = syncs
    .map(sync => {
      const classSync = classSyncs.find(s => s.id === sync.classSync.id)
      if (classSync) {
        const provider = providers.find(p => p.identifier === classSync.provider)
        if (!provider) return null
        return {
          providerName: provider.name,
          providerIdentifier: provider.identifier,
          classNameOnProvider: sync.classSync.label,
          syncId: sync.classSync.id,
          isSynced: true,
          needsStudents: !assignedClassIds.includes(classSync.classId),
          sync,
        } satisfies SyncAssignmentActionItem
      } else {
        return null
      }
    })
    .filter(Boolean) as SyncAssignmentActionItem[]

  const unusedSyncs = classSyncs.filter(
    classSync => !items.some(item => item.syncId === classSync.id),
  )
  items = [
    ...items,
    ...(unusedSyncs
      .map(unusedSync => {
        const provider = providers.find(p => p.identifier === unusedSync.provider)
        if (provider) {
          return {
            providerName: provider.name,
            providerIdentifier: provider.identifier,
            classNameOnProvider: unusedSync.label,
            syncId: unusedSync.id,
            isSynced: false,
            needsStudents: !assignedClassIds.includes(unusedSync.classId),
            sync: undefined,
          } satisfies SyncAssignmentActionItem
        } else {
          return null
        }
      })
      .filter(Boolean) as SyncAssignmentActionItem[]),
  ]

  return {
    loadingId,
    loadingSyncs,
    items,
    onItemSelected: async syncId => {
      const sync = items.find(i => i.syncId === syncId)
      setLoadingId(syncId)
      try {
        await onSync(syncId)
      } catch (err: any) {
        if ((err.graphQLErrors || [])[0]?.extensions?.code === 'NO_CONNECTED_STUDENTS_ERROR') {
          onError(
            `Cannot sync that content. None of the assigned students are connected to ${
              sync?.providerName || 'the provider'
            }. Please assign the content to more students and make sure they are connected to ${
              sync?.providerName || 'the provider'
            } before continuing.`,
            true,
          )
        } else {
          warn('unknown error when syncing assignment')
          warn(err)
          onError('There was an error syncing that content.')
        }
      } finally {
        setLoadingId(null)
      }
    },
  }
}
