import { styled } from './styledTypes'

export const DefinitionList = styled.ul`
  list-style: none none;
  padding: 0;
  > li {
    margin: 0 0 20px 0;
    > strong {
      font-size: 125%;
      line-height: 1.3;
    }
    > p {
      margin-top: 5px;
      color: ${props => props.theme['@gray-5']};
    }
  }
`
