import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'

import { styled, s } from '../../'
import Button, * as Button$ from '../Button'
import HSpaced from '../HSpaced'

export interface ModalFooterProps {
  children: React.ReactNode
}
export function ModalFooter({ children }: ModalFooterProps) {
  return <_Footer children={children} space={'0.75'} justify={'flex-end'} />
}

export interface ModalCloseButtonProps extends Button$.ButtonProps {}
export function ModalCloseButton(props: ModalCloseButtonProps) {
  return (
    <Dialog.Close asChild>
      <Button children={'Cancel'} {...props} />
    </Dialog.Close>
  )
}

const _Footer = styled(HSpaced)`
  margin-top: auto;
  padding-top: ${s.size('4')};
`
