import {
  QuestionSettingsSubmit,
  QuestionSettingsSubmitProps,
} from '@thesisedu/feature-assignments-react'
import { CourseViewerSegmentHeaderPortalContent } from '@thesisedu/feature-courses-react'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

export function QuestionSettingsSaveChanges(props: QuestionSettingsSubmitProps) {
  return (
    <CourseViewerSegmentHeaderPortalContent>
      <QuestionSettingsSubmit {...props} children={'Save Changes'} icon={<Check />} />
    </CourseViewerSegmentHeaderPortalContent>
  )
}
