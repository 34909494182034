import { FileField } from '@thesisedu/feature-attachments-react'
import { useResource } from '@thesisedu/feature-react'
import { Block, H2, H2Subtitle, Row } from '@thesisedu/web'
import { Form, FormProps, Col, Input } from 'antd'
import React from 'react'

import { EventTypeSelect } from './EventTypeSelect'
import { Badge } from '../Badge'
import { BadgeInput } from '../schema'
import { BadgeEventTypeResource } from '../types'

export interface BadgeFormValues extends BadgeInput {}

export interface BadgeFormProps extends FormProps<BadgeFormValues> {
  badge?: Partial<BadgeInput>
}
export function BadgeForm({ badge: _badge, ...props }: BadgeFormProps) {
  const [badge, setBadge] = React.useState<BadgeInput>(_badge || {})
  React.useEffect(() => {
    if (_badge) {
      setBadge(_badge)
    }
  }, [_badge])
  const eventResource = useResource<BadgeEventTypeResource<any>>(
    'BadgeEventType',
    badge.event || 'no-event',
  )
  const ConfigComponent = eventResource?.configComponent
  return (
    <Form
      {...props}
      layout={'vertical'}
      onValuesChange={(_, values) => {
        setBadge(values)
        if (props.onValuesChange) {
          props.onValuesChange(_, values)
        }
      }}
    >
      <Row gutter={48}>
        <Col xs={0} xxl={6}>
          <Block style={{ marginTop: 115 }}>
            <Badge badge={badge} />
          </Block>
        </Col>
        <Col xs={24} md={12} xxl={9}>
          <Block marginBottom={'@size-l'}>
            <H2 isBlock={false}>Step 1: Badge Details</H2>
            <H2Subtitle>
              Edit information about your badge and how it's presented to users.
            </H2Subtitle>
          </Block>
          <Form.Item
            name={'name'}
            label={'Name'}
            rules={[{ required: true, message: 'A name is required.' }]}
            extra={'For internal use only.'}
          >
            <Input size={'large'} />
          </Form.Item>
          <Form.Item
            name={'image'}
            label={'Image'}
            rules={[{ required: true, message: 'An image is required.' }]}
            extra={'Only SVGs are accepted.'}
          >
            <FileField uploadProps={{ accept: 'image/svg+xml', isPublic: true }} />
          </Form.Item>
          <Form.Item
            name={'label'}
            label={'Label'}
            rules={[{ required: true, message: 'A label is required.' }]}
            extra={'Shown to users.'}
          >
            <Input size={'large'} />
          </Form.Item>
          <Form.Item
            name={'description'}
            label={'Description'}
            rules={[{ required: true, message: 'A description is required.' }]}
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder={'Supports **markdown.**'}
            />
          </Form.Item>
          <Form.Item
            name={'awardedText'}
            label={'Awarded Text'}
            rules={[{ required: true, message: 'Awarded text is required.' }]}
            extra={'This is the message shown to users when they receive the badge.'}
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 4 }}
              placeholder={
                "Congratulations, you're awesome! Here's your badge. Supports **markdown.**"
              }
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xxl={9}>
          <Block marginBottom={'@size-l'}>
            <H2 isBlock={false}>Step 2: Configure Award</H2>
            <H2Subtitle>How will users earn this badge?</H2Subtitle>
          </Block>
          <Form.Item
            name={'event'}
            rules={[{ required: true, message: 'An event type is required.' }]}
          >
            <EventTypeSelect />
          </Form.Item>
          {ConfigComponent ? <ConfigComponent prefix={'eventConfig'} /> : null}
        </Col>
      </Row>
    </Form>
  )
}
