import { Question } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { QuestionHeaderProps } from './QuestionHeader'
import { QuestionProps } from '../types'

export interface AssignmentQuestionsContextValue {
  questionHeaderProps?: (
    question: Question,
  ) => Pick<QuestionHeaderProps, 'rightContent' | 'totalQuestions'>
  questionProps: (
    question: Question,
  ) => Pick<QuestionProps, 'disabled' | 'answerView' | 'showGradeFeedback'>
  questionFooter: (question: Question) => React.ReactElement | null
}
export const AssignmentQuestionsContext = React.createContext<
  AssignmentQuestionsContextValue | undefined
>(undefined)

export function useAssignmentQuestionsContext(): AssignmentQuestionsContextValue | undefined
export function useAssignmentQuestionsContext(
  require: false,
): AssignmentQuestionsContextValue | undefined
export function useAssignmentQuestionsContext(require: true): AssignmentQuestionsContextValue
export function useAssignmentQuestionsContext(
  require?: boolean,
): AssignmentQuestionsContextValue | undefined {
  const context = React.useContext(AssignmentQuestionsContext)
  if (!context && require) {
    throw new Error('AssignmentQuestionsContext is required, yet not provided.')
  }
  return context
}
