import { Dropdown as AntDropdown, DropDownProps, Menu } from 'antd'
import React from 'react'

export type DropdownTrigger = 'click' | 'hover' | 'contextMenu'
export interface DropdownMenuOption<Value> {
  label: string
  value: Value
  icon?: React.ReactElement
  onClick?: () => void | Promise<void>
  disabled?: boolean
  danger?: boolean
}

export type DropdownDividerOption = 'divider'

export type DropdownOption<Value> = DropdownMenuOption<Value> | DropdownDividerOption

export function getSelectableMenuOptions<Value>(
  options: DropdownOption<Value>[],
): DropdownMenuOption<Value>[] {
  return options.filter(opt => opt !== 'divider') as DropdownMenuOption<Value>[]
}

export interface DropdownProps<Value> {
  options: DropdownOption<Value>[]
  selectedOptions?: (DropdownMenuOption<Value> | undefined)[]
  onOptionSelected?: (option: DropdownMenuOption<Value>) => void
  disabled?: boolean
  children: React.ReactElement
  overlayTitle?: string
  values?: string[]
  placement?: DropDownProps['placement']
  trigger?: DropdownTrigger[]
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}
export function Dropdown<Value>({
  children,
  options,
  selectedOptions = [],
  onOptionSelected,
  disabled,
  placement,
  visible,
  onVisibleChange,
  trigger = ['click'],
}: DropdownProps<Value>) {
  const selectableOptions = getSelectableMenuOptions(options)
  const stringifiedOptions = selectedOptions.map(opt => JSON.stringify(opt?.value || ''))
  return (
    <AntDropdown
      disabled={disabled}
      placement={placement}
      visible={visible}
      onVisibleChange={onVisibleChange}
      overlay={
        <Menu
          selectedKeys={stringifiedOptions}
          onClick={({ key, domEvent }) => {
            domEvent.preventDefault()
            const selectedOption = selectableOptions.find(
              opt => opt.value === JSON.parse(key as string),
            )
            if (selectedOption?.onClick) {
              selectedOption.onClick()
            }
            if (onOptionSelected) {
              if (selectedOption) {
                onOptionSelected(selectedOption)
              }
            }
          }}
        >
          {options.map((option, index) => {
            if (option === 'divider') {
              return <Menu.Divider key={`divider-${index}`} />
            } else {
              return (
                <Menu.Item
                  key={JSON.stringify(option.value)}
                  disabled={option.disabled}
                  danger={option.danger}
                >
                  {option.icon} {option.label}
                </Menu.Item>
              )
            }
          })}
        </Menu>
      }
      trigger={trigger}
      children={children}
    />
  )
}
