import { AriaButtonProps, useButton } from '@react-aria/button'
import { useHover } from '@react-aria/interactions'
import { useObjectRef, mergeProps } from '@react-aria/utils'
import React from 'react'
import { css } from 'styled-components'

import { styled, s } from '../../'
import { StyleProps } from '../../sharedTypes'
import { animate } from '../../utils/animate'
import { FocusRing } from '../FocusRing/FocusRing'
import { LoadingIndicator } from '../LoadingIndicator'

export interface ButtonProps<T extends React.ElementType = 'button'>
  extends AriaButtonProps<T>,
    StyleProps {
  variant?: ButtonVariant | ButtonVariant[]
  size?: ButtonSize
  status?: ButtonStatus
  loading?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  slot?: string
  /**
   * The icon from `@thesisedu/ui/icons` to use for the button. You can also add more icons
   * by passing them directly inside the children prop.
   */
  icon?: React.ReactElement
  /**
   * This element is usually an icon, and will be displayed to the right of the button in
   * a much smaller font.
   */
  rightDecorator?: React.ReactElement | null
  /**
   * If this is true, the button will not call `event.preventDefault()` when clicked.
   */
  noPreventDefault?: boolean
  /** @deprecated */
  onPointerEnter?: (e: any) => any
  /** @deprecated */
  onPointerDown?: (e: any) => any
  /** @deprecated */
  onPointerMove?: (e: any) => any
  /** @deprecated */
  onPointerLeave?: (e: any) => any
  /** @deprecated use onPress instead */
  onClick?: (e: any) => any
}
function Button(props: ButtonProps, _ref: React.ForwardedRef<HTMLElement>) {
  const {
    noPreventDefault,
    variant = 'default',
    size = s.SharedVariants.Size.defaultValue,
    children,
    loading,
    status = 'none',
    icon,
    rightDecorator,
    style,
    slot,
    disabled: _disabled,
    className,
    onPointerEnter,
    onPointerDown,
    onPointerMove,
    onPointerLeave,
    onClick,
    ...rest
  } = s.useStyleContext(props)
  const ref = useObjectRef(_ref)
  const disabled = loading || _disabled
  const iconOnly = !children && (!!icon || loading)
  const { buttonProps, isPressed } = useButton(
    {
      ...rest,
      isDisabled: disabled,
      onPress(e) {
        if (onClick) onClick(e)
        if (rest.onPress) rest.onPress(e)
      },
    },
    ref,
  )
  const { hoverProps, isHovered } = useHover({ isDisabled: disabled })

  React.useEffect(() => {
    if (isPressed && ref.current) {
      animate(
        ref.current,
        {
          opacity: [0.25, 0],
          boxShadow: ['0 0 0 0 var(--accent-color)', '0 0 0 0.75em var(--accent-color)'],
        },
        {
          easing: 'exponential',
          duration: 1500,
          pseudoElement: '::before',
        },
      )
    }
  }, [isPressed])

  let buttonChildren = typeof children === 'string' ? <ButtonText>{children}</ButtonText> : children
  let _icon = icon
  if (loading) {
    _icon = <LoadingIndicator />
  }
  if (_icon) {
    buttonChildren = (
      <>
        {_icon}
        {buttonChildren}
      </>
    )
  }
  if (rightDecorator) {
    buttonChildren = (
      <>
        {buttonChildren}
        <RightDecoratorContainer $hasText={!iconOnly}>{rightDecorator}</RightDecoratorContainer>
      </>
    )
  }

  return (
    <FocusRing>
      <_Button
        ref={ref}
        aria-label={typeof children === 'string' ? children : undefined}
        {...mergeProps(
          {
            onPointerEnter: disabled ? undefined : onPointerEnter,
            onPointerDown: disabled ? undefined : onPointerDown,
            onPointerMove: disabled ? undefined : onPointerMove,
            onPointerLeave,
          },
          buttonProps,
          hoverProps,
        )}
        children={buttonChildren}
        className={s.variants<typeof _variants>(
          className,
          {
            hover: isHovered,
            active: isPressed,
            disabled,
            iconOnly,
          },
          size,
          variant,
          status || s.SharedVariants.Size.defaultValue,
        )}
        style={style}
        slot={slot}
      />
    </FocusRing>
  )
}

const _ResetButton = styled.button`
  font-family: inherit;
  font-size: 100%;
  line-height: ${s.var('lineHeights.default')};
  margin: 0;
  overflow: visible;
  text-transform: none;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  background: none;
  display: block;
`
const RightDecoratorContainer = styled.div<{ $hasText: boolean }>`
  width: 0;
  overflow: visible;
  font-size: ${s.size('0.75')};
  flex-shrink: 0;
  ${props => (props.$hasText ? '' : `transform: translateX(calc(-1 * ${s.size('0.5')}));`)}
`

const ButtonStates = {
  hover: css`
    --accent-color: var(--accent-hover-color);
    --text-color: var(--text-hover-color);
  `,
  active: css`
    --accent-color: var(--accent-active-color);
    --text-color: var(--text-active-color);
    transform: scale(0.97);
  `,
  disabled: css`
    opacity: 0.5;
    cursor: default;
  `,
  iconOnly: css`
    padding-left: var(--vertical-padding);
    padding-right: var(--vertical-padding);
    svg {
      width: 1.2em;
      height: 1.2em;
    }
  `,
} satisfies s.Variants
const primary = css`
  --accent-default-color: var(--status-default-color, ${s.color('primary.solid')});
  --accent-hover-color: var(--status-hover-color, ${s.color('primary.hoverSolid')});
  --accent-active-color: var(--status-active-color, ${s.color('primary.activeSolid')});
  --text-default-color: white;
  --text-hover-color: white;
  --text-active-color: white;
`
const ButtonVariants = {
  primary,
  ghost: css`
    --accent-default-color: transparent;
    --accent-hover-color: ${s.color('gray.element')};
    --accent-active-color: ${s.color('gray.hoverElement')};
  `,
  selected: css`
    --text-default-color: var(--status-default-color, ${s.color('primary.solid')});
    --text-hover-color: var(--status-hover-color, ${s.color('primary.hoverSolid')});
    --text-active-color: var(--status-active-color, ${s.color('primary.activeSolid')});
    background: var(--accent-hover-color);
  `,
  chromeless: css`
    ${primary}
    padding: 0;
    background: transparent;
    --text-default-color: var(--accent-default-color);
    --text-hover-color: var(--accent-hover-color);
    --text-active-color: var(--accent-active-color);
    &::before {
      box-shadow: none !important;
    }
  `,
} satisfies s.Variants
export type ButtonVariant = s.VariantString<typeof ButtonVariants, 'default'>

const ButtonStatuses = {
  danger: css`
    --status-default-color: ${s.color('red.solid')};
    --status-hover-color: ${s.color('red.hoverSolid')};
    --status-active-color: ${s.color('red.activeSolid')};
  `,
} satisfies s.Variants
export type ButtonStatus = s.VariantString<typeof ButtonStatuses, 'none'>

export type ButtonSize = s.SharedVariants.Size.Type

const _variants = s.merge(
  ButtonVariants,
  s.SharedVariants.Size.Variants,
  ButtonStates,
  ButtonStatuses,
)
export type ButtonVariants = typeof ButtonVariants
const _Button = s.styledWithVariants(
  _ResetButton,
  '_Button',
  css`
    --accent-default-color: ${s.color('gray.element')};
    --accent-hover-color: ${s.color('gray.hoverElement')};
    --accent-active-color: ${s.color('gray.active')};
    --accent-color: var(--accent-default-color);
    --text-default-color: var(--status-default-color, ${s.color('gray.text')});
    --text-hover-color: var(--status-hover-color, ${s.color('gray.textHover')});
    --text-active-color: var(--status-active-color, ${s.color('gray.textActive')});
    --text-color: var(--text-default-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--horizontal-padding) / 2);
    border-radius: ${s.var('radii.1')};
    background: var(--accent-color);
    color: var(--text-color);
    padding: var(--vertical-padding) var(--horizontal-padding);
    transform: scale(1);
    overflow: hidden;
    transition:
      background 0.1s linear,
      color 0.1s linear,
      transform 0.25s ${s.var('curves.exponential')};
    cursor: pointer;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
      inset: 0;
      border-radius: inherit;
      box-shadow: 0 0 0 0 var(--accent-color);
    }
    > svg,
    > span {
      vertical-align: middle;
    }
    > svg {
      flex-shrink: 0;
    }
  `,
  _variants,
)
const ButtonText = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ForwardButton = React.forwardRef(Button)
export { ForwardButton as Button }
