import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { styled } from '@thesisedu/web'
import React from 'react'

import { BadgeGridItem } from './BadgeGridItem'
import {
  BadgeFragment,
  UserBadgesQuery,
  UserBadgesQueryVariables,
  UserBadgesDocument,
} from '../schema'

const NUM_PLACEHOLDERS = 6
export function UserBadges() {
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <>
      <ListContainer ref={containerRef}>
        <InfiniteQuery<BadgeFragment, UserBadgesQuery, UserBadgesQueryVariables>
          document={UserBadgesDocument}
          resultPath={'viewer.badges'}
          infiniteScrollerProps={{
            scrollableTarget: () => containerRef.current,
          }}
          children={({ data }) => {
            const badges = data?.viewer?.badges.edges
            const placeholders: React.ReactElement[] = []
            for (let i = 0; i < NUM_PLACEHOLDERS; i++) {
              placeholders.push(<BadgeGridItem key={`placeholder-${i}`} />)
            }
            return (
              <>
                {badges?.map(badge => (
                  <BadgeGridItem
                    key={badge.node.id}
                    badge={badge.node}
                    awardedAt={badge.awardedAt}
                  />
                ))}
                {placeholders}
              </>
            )
          }}
        />
      </ListContainer>
    </>
  )
}

export const ListContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-items: flex-start;
  padding: calc(${props => props.theme['@size-s']} * 2);
  padding-right: ${props => props.theme['@size-s']};
  .badge-grid-item {
    flex-basis: calc(33.33333333% - ${props => props.theme['@size-s']});
    margin: 0 ${props => props.theme['@size-s']} ${props => props.theme['@size-s']} 0;
  }
`
