import { Dropdown } from '@thesisedu/ui'
import { GridAdd } from '@thesisedu/ui/icons'
import React from 'react'

import { useCopySegmentToClasses } from './useCopySegmentToClasses'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function CopySegmentToAllClassesItem({ segment }: SegmentActionComponentProps) {
  const [visible, setVisible] = React.useState(false)
  const { copy, otherClasses, courseName, progressModal, disabled } = useCopySegmentToClasses()
  return (
    <>
      {progressModal}
      <Dropdown.Item
        icon={<GridAdd />}
        onClick={async () => {
          setVisible(true)
          try {
            await copy(segment)
          } finally {
            setVisible(false)
          }
        }}
        disabled={!otherClasses.length || disabled}
        forceMount={visible}
      >
        {otherClasses.length ? (
          <span>Copy to all classes</span>
        ) : (
          <span>
            Cannot copy. No other classes with <br />
            {courseName || 'this course'}
          </span>
        )}
      </Dropdown.Item>
    </>
  )
}

export const CopySegmentToAllClassesAction: SegmentActionResource = {
  identifier: 'copy-segment-to-all-classes',
  type: 'SegmentAction',
  component: CopySegmentToAllClassesItem,
  group: 'Settings',
  weight: 200,
  handler: () => {},
}
