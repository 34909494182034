import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createNodeSelection,
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from 'lexical'
import { useEffect } from 'react'

import { useTableElements } from './elements'
import { $createTableNodeWithDimensions } from '../../nodes/Table/TableNode'

export type InsertTableCommandPayload = Readonly<{
  columns: string
  rows: string
  includeHeaders?: boolean
}>

export const INSERT_NEW_TABLE_COMMAND: LexicalCommand<InsertTableCommandPayload> = createCommand(
  'INSERT_NEW_TABLE_COMMAND',
)

export function TablePlugin(): JSX.Element | null {
  useTableElements()
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand<InsertTableCommandPayload>(
      INSERT_NEW_TABLE_COMMAND,
      ({ columns, rows, includeHeaders }) => {
        const selection = $getSelection()

        if (!$isRangeSelection(selection)) {
          return true
        }

        const focus = selection.focus
        const focusNode = focus.getNode()

        if (focusNode !== null) {
          const tableNode = $createTableNodeWithDimensions(
            Number(rows),
            Number(columns),
            includeHeaders,
          )

          if ($isRootOrShadowRoot(focusNode)) {
            const target = focusNode.getChildAtIndex(focus.offset)

            if (target !== null) {
              target.insertBefore(tableNode)
            } else {
              focusNode.append(tableNode)
            }

            tableNode.insertBefore($createParagraphNode())
          } else {
            const topLevelNode = focusNode.getTopLevelElementOrThrow()
            topLevelNode.insertAfter(tableNode)
          }

          const nodeSelection = $createNodeSelection()
          nodeSelection.add(tableNode.getKey())
          $setSelection(nodeSelection)
        }

        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}
