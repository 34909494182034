import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Form, H2, VSpaced, Input } from '@thesisedu/react'
import { Check } from '@thesisedu/react/icons'
import { message } from 'antd'
import React from 'react'

import { useUpdateUserMutation } from '../queries/useUpdateUserMutation'
import { Viewer } from '../types'

export interface ChangeProfileProps {
  viewer: Viewer
}
export function ChangeProfile({ viewer }: ChangeProfileProps) {
  const form = Form.useForm<Partial<Viewer>>()
  const [update] = useUpdateUserMutation({
    onError: onMutationError('There was an error updating your profile.'),
    onCompleted() {
      message.success('Profile updated successfully!')
    },
  })
  React.useEffect(() => {
    form.reset(viewer)
  }, [viewer])

  return (
    <Form
      form={form}
      onFinish={async values => {
        await update({
          variables: {
            input: {
              id: viewer.id,
              patch: {
                firstName: values.firstName,
                lastName: values.lastName,
              },
            },
          },
        })
      }}
    >
      <H2>Profile</H2>
      <VSpaced space={'@size-m'} align={'stretch'}>
        <Form.Item
          name={'firstName'}
          label={'First Name'}
          required={{ value: true, message: 'A first name is required.' }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'lastName'}
          label={'Last Name'}
          required={{ value: true, message: 'A last name is required.' }}
        >
          <Input />
        </Form.Item>
        <Form.Submit icon={<Check />} disableUntilDirty style={{ alignSelf: 'flex-start' }}>
          Save Profile
        </Form.Submit>
      </VSpaced>
    </Form>
  )
}
