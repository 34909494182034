import { InfiniteQuerySubscribeToMoreOpts } from '@thesisedu/feature-apollo-react'
import { orderBy } from 'lodash'

import { CommentAddedDocument, CommentAddedSubscriptionVariables, CommentFragment } from './schema'

export function getSubscriptionProps(
  commentableId: string,
): InfiniteQuerySubscribeToMoreOpts<CommentFragment, CommentAddedSubscriptionVariables> {
  return {
    document: CommentAddedDocument,
    resultPath: 'commentAdded',
    variables: {
      input: {
        id: commentableId,
      },
    },
    addItem(existingEdges, newItem) {
      const result = [...existingEdges, { node: newItem }]
      return orderBy(result, r => r.node.createdAt, 'asc')
    },
  }
}
