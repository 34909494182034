import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { useParams } from 'react-router-dom'

import { CoursePreview as InternalCoursePreview } from '../../../explore/CoursePreview'

export function CoursePreview() {
  const { courseId } = useParams()
  Shell.useShellProps(
    'course-preview',
    {
      smallWidth: true,
    },
    [],
  )
  return <InternalCoursePreview courseId={courseId} />
}
