import { fromGlobalId } from '@thesisedu/feature-utils'
import { Button, styled, AntIconWrapper, Block } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import { Empty } from 'antd'
import React from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { CreateStandardSet } from './CreateStandardSet'
import { DeleteStandardSetButton } from './DeleteStandardSetButton'
import { EditStandardSet } from './EditStandardSet'
import { ImportStandardSetButton } from './ImportStandardSetButton'
import { StandardSetsList } from './StandardSetsList'

function useSelectedId() {
  const location = useLocation()
  const segments = location.pathname.split('/')
  const potential = segments[segments.length - 1]
  return fromGlobalId(potential)?.type === 'StandardSet' ? potential : undefined
}

export function ManageStandardSets() {
  const navigate = useNavigate()
  const selectedId = useSelectedId()
  return (
    <Container>
      <Left>
        <Block marginBottom={'@size-s'}>
          <Button
            size={'small'}
            icon={<AntIconWrapper children={<Plus />} />}
            onClick={() => navigate('create')}
          >
            Create Standard Set
          </Button>
        </Block>
        <StandardSetsList
          onStandardSetClick={standardSet => navigate(standardSet.id)}
          selectedItemId={selectedId}
        />
      </Left>
      <Right>
        <Routes>
          <Route
            path={'/create'}
            element={<CreateStandardSet onCreated={standardSet => navigate(standardSet.id)} />}
          />
          <Route path={'/:standardSetId'} element={<EditStandardSetPage />} />
          <Route path={'/'} element={<EmptyPage />} />
        </Routes>
      </Right>
    </Container>
  )
}

function EmptyPage() {
  const navigate = useNavigate()
  return (
    <Empty description={'Select a standard set from the left to get started, or create one.'}>
      <Button
        size={'large'}
        icon={<AntIconWrapper children={<Plus />} />}
        type={'primary'}
        onClick={() => navigate('create')}
      >
        Create a Standard Set
      </Button>
    </Empty>
  )
}

function EditStandardSetPage() {
  const { standardSetId } = useParams()
  const navigate = useNavigate()
  return (
    <EditStandardSet
      key={standardSetId}
      standardSetId={standardSetId}
      actions={
        <>
          <ImportStandardSetButton standardSetId={standardSetId} />
          <DeleteStandardSetButton standardSetId={standardSetId} onDeleted={() => navigate('..')} />
        </>
      }
    />
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
`
const Left = styled.div`
  width: 25%;
  margin-right: ${props => props.theme['@size-l']};
  display: flex;
  flex-direction: column;
  > div:last-child {
    flex: 1;
  }
`
const Right = styled.div`
  flex: 1;
`
