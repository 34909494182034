import { InputNumber, InputNumberProps } from 'antd'
import { isNil } from 'lodash'
import React from 'react'

export interface SecondsInputProps extends InputNumberProps {
  value?: number
  hideSuffix?: boolean
}
export const SecondsInput = React.forwardRef<any, SecondsInputProps>(
  ({ value, onChange, hideSuffix, ...props }, ref) => {
    return (
      <>
        <InputNumber
          value={!isNil(value) ? value / 60 : undefined}
          onChange={val => {
            if (onChange) {
              onChange(!isNil(val) ? parseFloat(val.toString()) * 60 : '')
            }
          }}
          min={0}
          ref={ref}
          style={hideSuffix ? { width: '100%' } : undefined}
          {...props}
        />
        {hideSuffix ? null : <span>&nbsp;minutes</span>}
      </>
    )
  },
)
