import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useMutateHook } from '@thesisedu/feature-react'

import { UPDATE_ASSIGNMENT_QUERIES } from './constants'
import { UpdateAssignmentRefetchQueriesHook } from '..'
import { useUpdateAssignmentMutation as useMutation } from '../schema'

export const useUpdateAssignmentMutation: typeof useMutation = opts => {
  const refetchQueries = useMutateHook<UpdateAssignmentRefetchQueriesHook>(
    'feature-assignments-react:update-assignment-refetch-queries',
    UPDATE_ASSIGNMENT_QUERIES,
    undefined,
  )
  return useMutation({
    onError: onMutationError('There was an error updating your assignment.'),
    refetchQueries,
    ...opts,
  })
}
