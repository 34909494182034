import { Body } from '@thesisedu/web'
import React from 'react'

import { useLicenseStatusQuery, LicenseType } from '../schema'

export const LicenseStatus: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { data, loading } = useLicenseStatusQuery()
  const license = data?.viewer?.teacher?.site.license
  if (license && license.type !== LicenseType.StudentPay) {
    return (
      <Body
        color={loading ? '@text-color-secondary' : '@text-color'}
        style={{ display: 'inline-block' }}
      >
        {license.usedSeats || 0} / {license.seats} seats in license
      </Body>
    )
  } else {
    return null
  }
}
