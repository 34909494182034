import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined'
import { useClasses } from '@thesisedu/feature-classes-react'
import { Body, Block } from '@thesisedu/web'
import { Button, message, Modal } from 'antd'
import React from 'react'

import { useUpdatePracticeMutation } from '../queries/useUpdatePracticeMutation'

export interface MovePracticeModalProps {
  practiceId: string
  visible?: boolean
  onCancel: () => void
}
export const MovePracticeModal: React.FC<React.PropsWithChildren<MovePracticeModalProps>> = ({
  practiceId,
  visible,
  onCancel,
}) => {
  const { classes } = useClasses()
  const [updatePractice, { loading }] = useUpdatePracticeMutation({
    onCompleted: () => {
      message.success('Practice moved successfully!')
    },
  })
  return (
    <Modal
      title={'Move Practice'}
      footer={[
        <Button key={'cancel'} onClick={() => onCancel()}>
          Cancel
        </Button>,
      ]}
      visible={visible}
      onCancel={onCancel}
    >
      <Body isBlock>Click on a course to move the practice to that course.</Body>
      {classes.map(cls => (
        <Block key={cls.id} marginBottom={'@size-s'}>
          <Button
            type={'link'}
            size={'small'}
            loading={loading}
            onClick={() => {
              updatePractice({
                variables: {
                  input: {
                    id: practiceId,
                    patch: {
                      classId: cls.id,
                    },
                  },
                },
                refetchQueries: ['studentPractices'],
                awaitRefetchQueries: true,
              })
            }}
          >
            {cls.name} <ArrowRightOutlined />
          </Button>
        </Block>
      ))}
    </Modal>
  )
}
