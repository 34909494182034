import { onMutationError } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper, Button, ButtonProps } from '@thesisedu/react'
import { SaveActionFloppy } from '@thesisedu/react/icons'
import React from 'react'

import { RunReportOpts } from '../reporting/types'
import { useUpdateSavedReportMutation } from '../schema'

export interface UpdateSavedReportButtonProps extends Partial<ButtonProps> {
  savedReportId: string
  report: RunReportOpts
}
export function UpdateSavedReportButton({
  savedReportId,
  report,
  ...rest
}: UpdateSavedReportButtonProps) {
  const [update, { loading }] = useUpdateSavedReportMutation({
    onError: onMutationError('There was an error saving your changes.'),
  })

  return (
    <Button
      type={'link'}
      children={'Save Changes'}
      size={'small'}
      icon={<AntIconWrapper children={<SaveActionFloppy />} />}
      {...rest}
      loading={loading}
      onClick={() => {
        return update({
          variables: {
            input: {
              id: savedReportId,
              report,
            },
          },
        })
      }}
    />
  )
}
