import { getSegmentName } from '@thesisedu/feature-course-types'
import { BodySmall, styled } from '@thesisedu/web'
import React, { useContext, useMemo } from 'react'

import { useCourseContext } from '../contexts/CourseContext'
import { SegmentContext } from '../contexts/SegmentContext'
import { getSegmentParents } from '../helpers'
import { Segment } from '../types'

const Container = styled(BodySmall)`
  > a {
    color: inherit;
  }
`

export interface SegmentParentsProps {
  segment: Segment
  includeName?: boolean
}
export const SegmentParents: React.FC<React.PropsWithChildren<SegmentParentsProps>> = ({
  segment,
  includeName,
}) => {
  const cls = useCourseContext(true)
  const segments = useContext(SegmentContext)
  const parentSegments = useMemo<Segment[]>(() => {
    const parentIds = getSegmentParents(cls.bakedCourseConfiguration.segments, segment.id)
    return parentIds
      .filter(parentId => parentId !== segment.id)
      .map(parentId => segments[parentId])
      .filter(Boolean)
  }, [segment.id, cls])
  const children = parentSegments.reduce<React.ReactElement[]>((acc, parentSegment, index) => {
    const toAdd = []
    if (index > 0) {
      toAdd.push(<span key={`${parentSegment.id}-sep`}> &rarr; </span>)
    }
    toAdd.push(<span key={parentSegment.id}>{parentSegment.name}</span>)
    return [...acc, ...toAdd]
  }, [])

  if (includeName) {
    children.push(<span key={'name-sep'}> &rarr; </span>)
    children.push(<span key={'name'}>{getSegmentName(segment)}</span>)
  }

  return children.length ? (
    <Container data-testid={'SegmentParents'} color={'@text-color-secondary'}>
      {children}
    </Container>
  ) : null
}
