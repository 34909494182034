import { isVisibleToStudent } from '@thesisedu/feature-course-types'
import { useLicenseIsTrial } from '@thesisedu/feature-licenses-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { SegmentNotEnabledMessage, SegmentNotFoundMessage } from './SegmentNotFoundMessage'
import { ViewerTrialMessage } from './ViewerTrialMessage'
import { SegmentCompletion } from './completion/SegmentCompletion'
import { SegmentCompletionHandlerContextProvider } from './completion/useSegmentCompletion'
import { useSegment } from '../contexts/SegmentContext'
import { SegmentGates } from '../segment/SegmentGates'

export interface CourseViewerSegmentWrapperProps {
  children: React.ReactElement | null
  segmentId: string
  /** This is displayed beneath the content, regardless of validation status. */
  footer?: React.ReactElement
}
export function CourseViewerSegmentWrapper({
  segmentId,
  children,
  footer,
}: CourseViewerSegmentWrapperProps) {
  const viewer = useViewerContext(false)
  const segment = useSegment(segmentId)
  const isTrial = useLicenseIsTrial()
  let content
  if (segment) {
    if (isTrial && segment.config.excludeInTrial) {
      content = <ViewerTrialMessage />
    } else if (
      viewer?.group === 'STUDENT' &&
      (!isVisibleToStudent(segment) || !segment.visibleOverride)
    ) {
      content = <SegmentNotEnabledMessage scheduledAt={segment.scheduledAt} />
    } else {
      content = (
        <SegmentGates segment={segment}>
          <SegmentCompletionHandlerContextProvider contentKey={segment.id}>
            <>
              {children}
              <SegmentCompletion segment={segment} marginTop weight={Infinity} />
            </>
          </SegmentCompletionHandlerContextProvider>
        </SegmentGates>
      )
    }
  } else {
    content = <SegmentNotFoundMessage />
  }

  return (
    <>
      {content}
      {footer}
    </>
  )
}
