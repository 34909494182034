import { Grid } from '@thesisedu/ui'
import React from 'react'

import { AddGroup } from './AddGroup'
import { Group } from './Group'
import { useGroupsContext } from './GroupsContext'

export function GroupsGrid() {
  const { groups } = useGroupsContext(true)
  return (
    <Grid gap={'s'} columnWidth={300}>
      {Object.keys(groups).map(groupKey => {
        return <Group key={groupKey} groupKey={groupKey} />
      })}
      <AddGroup />
    </Grid>
  )
}
