import { EmbeddedFileProps, EmbeddedFileResource } from '@thesisedu/feature-react'
import React from 'react'

import { FeatureWeb } from '../FeatureWeb'

export function ImageRenderer({ path }: EmbeddedFileProps) {
  return <img src={path} />
}

export function SoundRenderer({ path }: EmbeddedFileProps) {
  return <audio controls src={path} style={{ width: '100%', minWidth: 300, height: 50 }} />
}

export function VideoRenderer({ path }: EmbeddedFileProps) {
  return <video controls src={path} style={{ width: '100%', minWidth: 500, height: 'auto' }} />
}

export function addEmbeddedRenderers(root: FeatureWeb) {
  root.deps.resources.addResource<EmbeddedFileResource>({
    type: 'EmbeddedFile',
    identifier: 'images',
    extensions: [
      'png',
      'bmp',
      'jpg',
      'jpeg',
      'gif',
      'svg',
      'apng',
      'avif',
      'jfif',
      'pjpeg',
      'pjp',
      'webp',
    ],
    render: ImageRenderer,
  })
  root.deps.resources.addResource<EmbeddedFileResource>({
    type: 'EmbeddedFile',
    identifier: 'sound',
    extensions: ['wav', 'mp3', 'aac', 'ogg', 'weba', 'flac'],
    render: SoundRenderer,
  })
  root.deps.resources.addResource<EmbeddedFileResource>({
    type: 'EmbeddedFile',
    identifier: 'video',
    extensions: ['mp4', 'webm', '3gp', 'mov'],
    render: VideoRenderer,
  })
}
