import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { Button, HSpaced, VSpaced } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ImportFromLMS } from './ImportFromLMS'
import { PresetsRoutesProps } from './PresetsRoutes'
import { PresetsTable } from './PresetsTable'
import {
  BasicClassGroupPresetFragment,
  ClassGroupPresetsDocument,
  ClassGroupPresetsQuery,
  ClassGroupPresetsQueryVariables,
} from '../../schema'

export function ListPresetsPage({ classId }: PresetsRoutesProps) {
  const navigate = useNavigate()

  return (
    <VSpaced>
      <HSpaced justify={'flex-end'} space={'xs'}>
        <ImportFromLMS classId={classId} />
        <Button
          icon={<Plus />}
          children={'Create Preset'}
          variant={'primary'}
          onPress={() => {
            navigate('create')
          }}
        />
      </HSpaced>
      <InfiniteQuery<
        BasicClassGroupPresetFragment,
        ClassGroupPresetsQuery,
        ClassGroupPresetsQueryVariables
      >
        document={ClassGroupPresetsDocument}
        resultPath={'node.groupPresets'}
        variables={{ classId }}
        children={({ data }) => {
          const presets =
            data?.node?.__typename === 'Class'
              ? data.node.groupPresets.edges.map(edge => edge.node)
              : []
          return (
            <PresetsTable
              classId={classId}
              presets={presets}
              onViewPreset={presetId => {
                navigate(presetId)
              }}
            />
          )
        }}
      />
    </VSpaced>
  )
}
