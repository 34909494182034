// Mostly from npm fast-deep-equal. Copied here to reduce dependencies, and with a few modifications.
export function isEqual(a: any, b: any): boolean {
  if (a === b) return true
  if (
    (typeof a === 'number' || typeof a === 'string') &&
    (typeof b === 'number' || typeof b === 'string')
  )
    return a.toString() === b.toString()
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    if (Array.isArray(a) !== Array.isArray(b)) return false
    let length: number, i: number
    if (Array.isArray(a)) {
      length = a.length
      if (length !== b.length) return false
      for (i = length; i-- !== 0; ) {
        if (!isEqual(a[i], b[i])) return false
      }
      return true
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags
    if (a.constructor === Date) return a.toString() === b.toString()

    const keys = Object.keys(a)
    length = keys.length
    if (length !== Object.keys(b).length) return false

    for (i = length; i-- !== 0; ) {
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false
    }

    for (i = length; i-- !== 0; ) {
      const key = keys[i]
      if (!isEqual(a[key], b[key])) return false
    }

    return true
  }

  // True if both NaN, false otherwise.
  // eslint-disable-next-line no-self-compare
  return a !== a && b !== b
}
