import { styled, StyledComponent } from '@thesisedu/web'

export const ChoiceEditorContainer: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
`
