import React from 'react'

import { CustomEmojiResource } from './types'
import { useResource } from './useFeatureRoot'

export interface ExtensibleTextProps {
  value: string
}
export function ExtensibleText({ value }: ExtensibleTextProps) {
  return useCustomEmojis(value)
}

const TOKEN = '::^::'
function replaceWithEmoji(
  textSegments: (string | React.ReactElement)[],
  render: CustomEmojiResource['render'],
  code: string,
): (string | React.ReactElement)[] {
  const result: (string | React.ReactElement)[] = []
  for (const segment of textSegments) {
    if (typeof segment === 'string') {
      // Replace with a regex first to make the code case-insensitive.
      const subSegments = segment.replace(new RegExp(`:${code}:`, 'ig'), TOKEN).split(TOKEN)
      if (subSegments.length > 1) {
        for (let i = 0; i < subSegments.length; i++) {
          const isLast = i === subSegments.length - 1
          const subsegment = subSegments[i]
          result.push(subsegment)
          if (!isLast) {
            const Component = render
            result.push(<Component code={code} />)
          }
        }
      } else {
        result.push(segment)
      }
    } else {
      result.push(segment)
    }
  }

  return result
}

function mergeSeparatedStrings(
  items: (string | React.ReactElement)[],
): (string | React.ReactElement)[] {
  const result: (string | React.ReactElement)[] = []
  for (let i = 0; i < items.length; i++) {
    // If the previous item was a string, merge the two...
    if (typeof result[i - 1] === 'string' && typeof items[i] === 'string') {
      result[i - 1] = [result[i - 1], items[i]].join('')
    } else {
      result[i] = items[i]
    }
  }

  return result
}

export function useCustomEmojis(text: string): React.ReactElement {
  const resources = useResource<CustomEmojiResource>('CustomEmoji')
  let workingSegments: (string | React.ReactElement)[] = [text]
  for (const resource of resources) {
    for (const code of resource.codes) {
      workingSegments = replaceWithEmoji(workingSegments, resource.render, code)
    }
  }

  // Merge items together that are both strings.
  const merged = mergeSeparatedStrings(workingSegments)

  // Filter to remove any empty strings...
  const filtered = merged.filter(Boolean)

  // Wrap all of the items in fragments...
  return filtered.length > 0 ? (
    filtered.slice(1).reduce<React.ReactElement>(
      (acc, item) => {
        return (
          <>
            {acc}
            {item}
          </>
        )
      },
      typeof merged[0] === 'string' ? <>{merged[0]}</> : merged[0],
    )
  ) : (
    <></>
  )
}
