import { eventFiles } from '@lexical/rich-text'
import { mergeRegister } from '@lexical/utils'
import { useStateAndFreshRef } from '@thesisedu/feature-react'
import { DraggableNode } from '@thesisedu/feature-widgets-core'
import { styled, s, Dropdown } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import {
  $createParagraphNode,
  $createTextNode,
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
} from 'lexical'
import React from 'react'
import { createPortal } from 'react-dom'

import { DROP_FILE_COMMAND } from './commands'
import { DRAG_DATA_EDITOR, DRAG_DATA_FORMAT } from './constants'
import { getBlockElement } from './getBlockElement'
import { isOnMenu } from './isOnMenu'
import { modifyDrop } from './modifyDrop'
import { setDragImage } from './setDragImage'
import { setMenuPosition } from './setMenuPosition'
import { DraggableGlobalStyles } from './styles'
import { hideTargetLine, setTargetLine } from './targetLine'
// @ts-ignore
import draggableBlockMenuIcon from '../../../../assets/draggable-block-menu.svg'
import { debug } from '../../../log'
import { useSelectedElementContext } from '../../context/SelectedElementContext'
import { SettingsDropdownMenuContent } from '../../ui/SettingsDropdown/SettingsDropdownMenuContent'
import { $getDOMNode } from '../../utils/getDomNode'
import { getSelectedElement } from '../../utils/getSelectedElement'
import { isHTMLElement } from '../../utils/guard'

/**
 * This is used to make sure we are not allowing elements to be dragged between
 * editors. We would use the dataTransfer on the drag event, but that doesn't
 * show up in the dragover event, where we need it to make sure we are showing
 * the overlays properly.
 */
let draggingFromEditorKey: string | null = null
document.addEventListener('dragend', () => {
  draggingFromEditorKey = null
})

export interface DraggableBlockElement {
  element: HTMLElement
  node: DraggableNode
  key: string
}

export function useDraggableBlockMenu(
  editor: LexicalEditor,
  anchorElement: HTMLElement,
  isEditable: boolean,
  noAdd: boolean,
) {
  const scrollerElement = anchorElement.parentElement
  const menuRef = React.useRef<HTMLDivElement>(null)
  const targetLineRef = React.useRef<HTMLDivElement>(null)
  const [draggableBlockElement, setDraggableBlockElement] =
    React.useState<DraggableBlockElement | null>(null)
  const [open, openRef, setOpen] = useStateAndFreshRef(false)

  // Update the selectedElementContext whenever the selection changes.
  const selectedElementContext = useSelectedElementContext(false)
  React.useEffect(() => {
    if (!draggableBlockElement && selectedElementContext) {
      selectedElementContext.setElement(undefined)
    }
  }, [!!draggableBlockElement])

  // Set the draggable block element whenever the mouse moves.
  React.useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      // Ignore move events when the popover is visible.
      if (openRef.current) return
      // Ignore move events whenever we are dragging columns around.
      if (document.body.style.cursor) return

      const target = event.target
      if (!isHTMLElement(target)) {
        setDraggableBlockElement(null)
        return
      }

      if (isOnMenu(target)) return

      const result = getBlockElement(menuRef.current, editor, event)
      const node = result ? editor.getEditorState().read(() => $getNodeByKey(result.key)) : null
      if (result && node) {
        setDraggableBlockElement({
          element: result.element,
          key: result.key,
          node,
        })
        if (selectedElementContext && result) {
          selectedElementContext.setElement(node)
        }
      } else {
        setDraggableBlockElement(null)
      }
    }

    function onMouseLeave() {
      // Ignore move events when the popover is visible.
      if (openRef.current) return

      setDraggableBlockElement(null)
    }

    scrollerElement?.addEventListener('mousemove', onMouseMove)
    scrollerElement?.addEventListener('mouseleave', onMouseLeave)
    return () => {
      scrollerElement?.removeEventListener('mousemove', onMouseMove)
      scrollerElement?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [scrollerElement, anchorElement, editor])

  // Adjust the menu position whenever the draggable block element changes.
  React.useEffect(() => {
    if (menuRef.current) {
      setMenuPosition(draggableBlockElement, menuRef.current, anchorElement)
    }
    if (selectedElementContext) {
      selectedElementContext.setElement(draggableBlockElement?.node)
    }
  }, [anchorElement, draggableBlockElement])

  // Respond to delete events whenever the menu is open.
  React.useEffect(() => {
    if (draggableBlockElement && open) {
      const listener = (event: KeyboardEvent) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
          editor.update(() => {
            draggableBlockElement.node.remove()
          })
          setOpen(false)
          setDraggableBlockElement(null)
          window.removeEventListener('keydown', listener)
        }
      }
      window.addEventListener('keydown', listener)
      return () => {
        window.removeEventListener('keydown', listener)
      }
    }
  }, [open])

  // Register the drag / drop events for actually moving things around.
  const dragDataRef = React.useRef<string | null>(null)
  React.useEffect(() => {
    function onDragover(event: DragEvent): boolean {
      event.preventDefault()
      const { clientY } = event

      if (draggingFromEditorKey && editor.getKey() !== draggingFromEditorKey) {
        hideTargetLine(targetLineRef.current)
        return false
      }

      const draggedNodes = (dragDataRef.current || '')
        .split(',')
        .map(key => $getNodeByKey<DraggableNode>(key))
        .filter(Boolean) as DraggableNode[]

      const targetResult = getBlockElement(menuRef.current, editor, event)
      let targetBlockElement = targetResult?.element || null
      const targetLineElement = targetLineRef.current
      if (targetResult === null || targetBlockElement === null || targetLineElement === null)
        return false
      const {
        rect,
        beforeAfter,
        targetBlockElement: modifiedElement,
      } = modifyDrop(editor, draggedNodes, clientY, targetResult)
      targetBlockElement = modifiedElement

      // Don't do anything if the target is a child of any of the dragged nodes.
      const isChildOfDragged = draggedNodes.some(node => {
        // Best way to do this is via the DOM nodes.
        const domNode = $getDOMNode(editor, node)
        return domNode && domNode.contains(targetBlockElement)
      })
      if (isChildOfDragged) {
        hideTargetLine(targetLineElement)
      } else {
        setTargetLine(targetLineElement, targetBlockElement, rect, beforeAfter, anchorElement)
      }

      // Prevent default event to be able to trigger onDrop events.
      event.preventDefault()
      return true
    }

    function onDrop(event: DragEvent): boolean {
      const [isFileTransfer, files] = eventFiles(event)
      const { dataTransfer, clientY, target } = event
      const dragData = dataTransfer?.getData(DRAG_DATA_FORMAT) || ''
      const dragEditorKey = dataTransfer?.getData(DRAG_DATA_EDITOR) || ''
      hideTargetLine(targetLineRef.current)
      draggingFromEditorKey = null
      let draggedNodes: DraggableNode[] = []
      if (isFileTransfer) {
        debug('dropped file; calling DROP_FILE_COMMAND')
        const handled = editor.dispatchCommand(DROP_FILE_COMMAND, {
          files,
          nodes: draggedNodes,
        })
        if (handled) {
          debug('...was handled')
          event.preventDefault()
        } else return false
      } else {
        draggedNodes = dragData
          .split(',')
          .map(key => $getNodeByKey<DraggableNode>(key))
          .filter(Boolean) as DraggableNode[]
      }
      if (!draggedNodes.length) return false
      if (!isHTMLElement(target)) return false
      if (dragEditorKey && dragEditorKey !== editor.getKey()) return false
      const targetResult = getBlockElement(menuRef.current, editor, event)
      let targetBlockElement = targetResult?.element || null
      const targetLineElement = targetLineRef.current
      if (!targetResult || targetBlockElement === null || targetLineElement === null) return false

      // Don't do anything if the target is a child of any of the dragged nodes.
      const isChildOfDragged = draggedNodes.some(node => {
        // Best way to do this is via the DOM nodes.
        const domNode = $getDOMNode(editor, node)
        return domNode && domNode.contains(target)
      })
      if (isChildOfDragged) return false

      const { beforeAfter, targetBlockElement: modifiedElement } = modifyDrop(
        editor,
        draggedNodes,
        clientY,
        targetResult,
      )
      targetBlockElement = modifiedElement

      const targetNode = $getNearestNodeFromDOMNode(targetBlockElement) as DraggableNode | null
      if (!targetNode) return false
      const firstDragged = draggedNodes[0]
      if (targetNode === firstDragged) return false
      const shouldInsertAfter = beforeAfter === 1
      let _draggedNodes = draggedNodes
      if (firstDragged?.beforeDrop) {
        _draggedNodes = firstDragged.beforeDrop(draggedNodes, targetNode, shouldInsertAfter)
      }
      if (targetNode?.beforeDropOn) {
        _draggedNodes = targetNode.beforeDropOn(_draggedNodes, shouldInsertAfter)
      }
      if (shouldInsertAfter) _draggedNodes.reverse()
      for (const draggedNode of _draggedNodes) {
        const parent = targetNode.getParent()
        if (shouldInsertAfter) {
          targetNode.insertAfter(draggedNode)
        } else {
          targetNode.insertBefore(draggedNode)
        }
        if (parent && !parent.getChildrenSize()) {
          parent.remove(false)
        }
      }
      setDraggableBlockElement(null)

      return true
    }

    return mergeRegister(
      editor.registerUpdateListener(() => {
        setDraggableBlockElement(null)
      }),
      editor.registerCommand(
        DRAGOVER_COMMAND,
        event => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DROP_COMMAND,
        event => {
          return onDrop(event)
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_MODIFIER_COMMAND,
        event => {
          if (event.key === '/') {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const element = getSelectedElement(selection)
              if (element) {
                const domElement = $getDOMNode(editor, element)
                setDraggableBlockElement({
                  element: domElement,
                  key: element.getKey(),
                  node: element,
                })
                setOpen(true)
              }
            }
          }
          return false
        },
        COMMAND_PRIORITY_NORMAL,
      ),
    )
  }, [anchorElement, editor])

  // When the popover is visible, show a background behind the target element.
  React.useEffect(() => {
    if (draggableBlockElement && open) {
      draggableBlockElement.element.classList.add('ft-widgets-selected')
      return () => {
        draggableBlockElement.element.classList.add('leaving')
        setTimeout(() => {
          draggableBlockElement.element.classList.remove('ft-widgets-selected', 'leaving')
        }, 250)
      }
    }
  }, [open])

  return createPortal(
    <>
      <DraggableGlobalStyles />
      <DraggableBlockMenuOuter ref={menuRef}>
        {noAdd ? null : (
          <DraggableBlockMenu
            style={{ cursor: 'pointer', color: 'black' }}
            onClick={() => {
              if (draggableBlockElement) {
                editor.update(() => {
                  const node = $createParagraphNode()
                  const text = $createTextNode('/')
                  node.append(text)
                  draggableBlockElement.node.insertAfter(node)
                  text.select()
                })
              }
            }}
          >
            <Plus width={'1.25em'} height={'1.25em'} />
          </DraggableBlockMenu>
        )}
        <DraggableBlockMenu
          draggable
          onClick={event => {
            if (event.target !== event.currentTarget || noAdd) return
            setOpen(true)
          }}
          onDragStart={event => {
            const dataTransfer = event.dataTransfer
            if (!dataTransfer || !draggableBlockElement) return
            const { node, element: _element } = draggableBlockElement
            const element = node.modifyMenuDOMElement
              ? node.modifyMenuDOMElement(_element)
              : _element
            setDragImage(dataTransfer, element)
            let nodeKey = ''
            editor.update(() => {
              const node = $getNearestNodeFromDOMNode(
                draggableBlockElement.element,
              ) as DraggableNode | null
              if (node) {
                let resultNodes = [node]
                if (node.getDraggableNode) {
                  const r = node.getDraggableNode()
                  if (Array.isArray(r)) resultNodes = r
                  else resultNodes = [r]
                }
                nodeKey = resultNodes.map(n => n.getKey()).join(',')
              }
            })
            dragDataRef.current = nodeKey
            draggingFromEditorKey = editor.getKey()
            dataTransfer.setData(DRAG_DATA_FORMAT, nodeKey)
            dataTransfer.setData(DRAG_DATA_EDITOR, editor.getKey())
          }}
          onDragEnd={() => {
            hideTargetLine(targetLineRef.current)
          }}
        >
          {noAdd ? (
            <DraggableBlockMenuInner />
          ) : (
            <Dropdown.Container
              open={open}
              onOpenChange={o => {
                if (!o) setOpen(o)
              }}
            >
              <Dropdown.ManualTrigger>
                <DraggableBlockMenuInner />
              </Dropdown.ManualTrigger>
              {draggableBlockElement ? (
                <SettingsDropdownMenuContent editor={editor} node={draggableBlockElement.node} />
              ) : null}
            </Dropdown.Container>
          )}
        </DraggableBlockMenu>
      </DraggableBlockMenuOuter>
      <DraggableBlockTargetLine ref={targetLineRef} />
    </>,
    anchorElement,
  )
}

const DraggableBlockMenuOuter = styled.div`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  display: flex;
  align-items: flex-start;
  transform-origin: 100% 0;
  z-index: calc(${s.var('zIndices.overlays')} + 10);
  padding: ${s.size('xxs')};
  box-sizing: content-box;
  background: ${s.color('gray.background')};
  border-radius: ${s.var('radii.1')};
`
const DraggableBlockMenu = styled.div`
  pointer-events: all;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 1px;
  height: var(--line-height, 30px);
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  &:hover {
    background-color: ${s.color('gray.element')};
  }
  > svg {
    opacity: 0.3;
    pointer-events: none;
    display: block;
  }
`
const DraggableBlockMenuInner = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.3;
  background-image: url(${draggableBlockMenuIcon});
  pointer-events: none;
`
const DraggableBlockTargetLine = styled.div`
  pointer-events: none;
  border-radius: 2px;
  background: ${s.color('blue.solid')};
  height: 4px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: transform;
  z-index: ${s.var('zIndices.overlays')};
`
