import { Typography } from 'antd'
import { omit } from 'lodash'
import React, { useContext } from 'react'

import { styled, ThemeContext } from './styledTypes'
import 'antd/lib/typography/style'

export enum FontWeight {
  Light = 300,
  Normal = 400,
  SemiBold = 500,
  Bold = 600,
  ExtraBold = 800,
}

type Theme = { [key: string]: any }
export interface TypographyProps
  extends Omit<React.HTMLProps<HTMLParagraphElement>, 'size' | 'type'> {
  color?: string
  size?: string
  style?: any
  weight?: FontWeight
  code?: boolean
  isBlock?: boolean
  useDiv?: boolean
  truncate?: boolean
}

export const generateStyle = (theme: Theme, props: TypographyProps): object => ({
  color: props.color
    ? theme[props.color.startsWith('@') ? props.color : `@${props.color}`]
    : undefined,
  fontSize: props.size ? theme[props.size] : undefined,
  fontWeight: props.weight,
  fontFamily: props.code ? theme['@code-family'] : undefined,
  marginBottom: props.isBlock ? undefined : 0,
  ...(props.truncate
    ? {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    : {}),
  ...props.style,
})

const PROPS_BLACKLIST = [
  'code',
  'color',
  'size',
  'style',
  'weight',
  'isBlock',
  'useDiv',
  'truncate',
]
const Header: React.FC<
  React.PropsWithChildren<TypographyProps & { level: 4 | 3 | 2 | 1 | undefined }>
> = ({ level, ...props }) => {
  const theme = useContext(ThemeContext)
  return (
    <Typography.Title
      level={level}
      style={generateStyle(theme, { isBlock: true, ...props })}
      {...(omit(props, PROPS_BLACKLIST) as any)}
    />
  )
}
const Item: React.FC<React.PropsWithChildren<TypographyProps>> = props => {
  const theme = useContext(ThemeContext)
  if (!props.useDiv && props.dangerouslySetInnerHTML) {
    console.warn(
      'You should not be rendering a <p> element while also using dangerouslySetInnerHTML. ' +
        'Use the useDiv property on your typography element instead.',
    )
  }
  if (props.useDiv) {
    return <div style={generateStyle(theme, props)} {...omit(props, PROPS_BLACKLIST)} />
  } else {
    return <p style={generateStyle(theme, props)} {...omit(props, PROPS_BLACKLIST)} />
  }
}

export const H1: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Header level={1} {...props} />
)
export const H2: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Header level={2} {...props} />
)
export const H3: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Header level={3} {...props} />
)
export const H4: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Header level={4} {...props} />
)

export const H1Subtitle: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item color={'@text-color-secondary'} size={'@size-m'} {...props} />
)
export const H2Subtitle: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item color={'@text-color-secondary'} size={'@size-s'} {...props} />
)

export const BodyLarge: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item size={'@font-size-lg'} {...props} />
)
export const BodyLarger: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item size={'@font-size-larger'} {...props} />
)
export const Body: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item size={'@font-size-base'} {...props} />
)
export const BodySmall: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item size={'@font-size-sm'} {...props} />
)
export const BodySmaller: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Item size={'@font-size-xs'} {...props} />
)

export const Capital: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Body
    size={'@font-size-sm'}
    color={'@text-color-secondary'}
    {...props}
    style={{
      ...props.style,
      fontWeight: 800,
      textTransform: 'uppercase',
      letterSpacing: '1px',
      lineHeight: 1,
    }}
  />
)
export const CapitalBold: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Capital color={'@text-color'} {...props} />
)
export const BodyCapital: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Capital size={'@font-size-base'} {...props} />
)
export const BodySmallTransparent: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <BodySmall color={'@white'} {...props} style={{ ...props.style, opacity: 0.45 }} />
)

export const CapitalSmall: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <Capital size={'@font-size-xs'} {...props} style={{ ...props.style, letterSpacing: '0.75px' }} />
)

export const H3Alternate: React.FC<React.PropsWithChildren<TypographyProps>> = props => (
  <H3
    {...props}
    style={{
      ...props.style,
      textTransform: 'none',
      fontSize: 22,
      lineHeight: '26px',
      letterSpacing: '-0.5px',
    }}
  />
)

export interface ColorProps {
  color: string
}
export const Color = styled.span<ColorProps>`
  color: ${props => props.theme[props.color]};
`
