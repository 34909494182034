import { onMutationError } from '@thesisedu/feature-apollo-react'
import { BlockEditor, EditorValue, Legacy } from '@thesisedu/feature-widgets-react'
import { Block } from '@thesisedu/react'
import { BlockSpin, NotFound, PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import { Button, Input, message } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CopyTemplateButton } from './CopyTemplateButton'
import { DeleteWidgetTemplateButton } from './DeleteWidgetTemplateButton'
import { useUpdateWidgetTemplateMutation, useWidgetTemplateQuery } from '../schema'

export interface EditWidgetTemplateProps {
  id: string
}
export function EditWidgetTemplate({ id }: EditWidgetTemplateProps) {
  const navigate = useNavigate()
  const [name, setName] = React.useState('')
  const [widgets, setWidgets] = React.useState<Legacy.Widget[]>([])
  const updatedValueRef = React.useRef<EditorValue | null>(null)
  const { data, loading } = useWidgetTemplateQuery({
    variables: { id },
  })
  const [update, { loading: updating }] = useUpdateWidgetTemplateMutation({
    onError: onMutationError('There was an error saving your template.'),
    onCompleted: () => {
      message.success('Template saved!')
      navigate('..')
    },
  })
  const widgetTemplate = data?.node?.__typename === 'WidgetTemplate' ? data.node : undefined
  React.useEffect(() => {
    if (widgetTemplate) {
      setName(widgetTemplate.name)
      setWidgets(widgetTemplate.widgets || [])
    }
  }, [widgetTemplate])
  useBreadcrumb({ title: widgetTemplate?.name || '--' }, widgetTemplate?.id || 'none')
  const defaultValue = React.useMemo(() => {
    return Legacy.getBlockValueFromAnyValue(widgets)
  }, [widgets])

  if (loading) {
    return <BlockSpin />
  } else if (widgetTemplate) {
    return (
      <>
        <PageHead title={`Editing ${widgetTemplate.name} - Widget Templates`} />
        <PageHeader
          title={
            <Input
              size={'large'}
              placeholder={'My Template'}
              value={name}
              onChange={e => setName(e.target.value)}
              style={{ width: '40vw' }}
            />
          }
        >
          <DeleteWidgetTemplateButton
            widgetTemplateId={widgetTemplate.id}
            afterDeleted={() => navigate('..')}
          />
          <CopyTemplateButton
            template={widgetTemplate}
            onCopied={newTemplate => {
              navigate(`../${newTemplate.id}`)
            }}
          />
          <Button
            type={'primary'}
            children={'Save Changes'}
            onClick={() => {
              if (updatedValueRef.current) {
                update({
                  variables: {
                    input: {
                      id,
                      patch: {
                        name,
                        widgets: updatedValueRef.current.root.children,
                      },
                    },
                  },
                })
              }
            }}
            disabled={!name}
            loading={updating}
          />
        </PageHeader>
        <Block marginTop={'@size-l'}>
          <BlockEditor
            id={id}
            defaultValue={defaultValue}
            onChange={serialized => {
              updatedValueRef.current = serialized
            }}
          />
        </Block>
      </>
    )
  } else {
    return <NotFound description={'That template could not be found.'} />
  }
}
