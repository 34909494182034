import { useClassValidityQuery } from './schema'

export function useClassValidity() {
  const { data, loading } = useClassValidityQuery()
  const areAnyValid = data?.viewer?.student?.classes.edges.some(
    edge => edge.node.hasValidSubscription,
  )
  return {
    anyValid: areAnyValid === undefined || areAnyValid === null ? true : areAnyValid,
    loading,
  }
}
