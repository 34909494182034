import { GetNavigationOpts } from '@thesisedu/feature-courses-react'
import React from 'react'

import { CourseViewerNavigationFooter } from './course_viewer/CourseViewerNavigationFooter'
import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'

export function getNavigationFooter(
  this: CourseAssignmentsReactFeature,
  opts: GetNavigationOpts,
): React.ReactNode | null {
  return <CourseViewerNavigationFooter />
}
