import { ConfigProvider } from 'antd'
import fscreen from 'fscreen'
import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'

// From: https://github.com/snakesilk/react-fullscreen/blob/master/src/index.tsx
export interface FullscreenHandle {
  active: boolean
  enter: () => Promise<void>
  exit: () => Promise<void>
  node: React.MutableRefObject<HTMLDivElement | null>
}
export function useFullscreenHandle(): FullscreenHandle {
  const [active, setActive] = React.useState(false)
  const node = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const handleChange = () => {
      setActive(fscreen.fullscreenElement === node.current)
    }
    fscreen.addEventListener('fullscreenchange', handleChange)
    return () => fscreen.removeEventListener('fullscreenchange', handleChange)
  }, [])

  const enter = React.useCallback(async () => {
    try {
      if (fscreen.fullscreenElement) {
        await fscreen.exitFullscreen()
        if (node.current) {
          await fscreen.requestFullscreen(node.current)
        }
      } else if (node.current) {
        await fscreen.requestFullscreen(node.current)
      }
    } catch (err: any) {
      console.warn('error opening fullscreen')
      console.warn(err)
    }
  }, [])

  const exit = React.useCallback(async () => {
    if (fscreen.fullscreenElement === node.current) {
      await fscreen.exitFullscreen()
    }
  }, [])

  return React.useMemo(() => ({ active, enter, exit, node }), [active, enter, exit])
}

export enum FullscreenMode {
  Fullscreen,
  Inline,
}
export interface FullscreenProps {
  fullscreen?: boolean | FullscreenMode
  onChange: (fullscreen: boolean | FullscreenMode) => void
  children: React.ReactElement | null
}
export function Fullscreen({ children, fullscreen, onChange }: FullscreenProps) {
  const fixedRef = React.useRef<HTMLDivElement>(document.createElement('div'))
  const wasFullscreenRef = React.useRef(
    fullscreen === true || fullscreen === FullscreenMode.Fullscreen,
  )
  const { node, enter, exit, active } = useFullscreenHandle()
  React.useEffect(() => {
    if (wasFullscreenRef.current && !active) {
      onChange(false)
    }
  }, [active])
  React.useEffect(() => {
    wasFullscreenRef.current = fullscreen === true || fullscreen === FullscreenMode.Fullscreen
    if (wasFullscreenRef.current) {
      enter()
    } else {
      exit()
    }
  }, [fullscreen])
  React.useEffect(() => {
    if (fullscreen === FullscreenMode.Inline) {
      fixedRef.current.className = 'feature-web-fixed-container'
      document.body.appendChild(fixedRef.current)
      return () => {
        document.body.removeChild(fixedRef.current)
      }
    }
  }, [fullscreen === FullscreenMode.Inline])
  const content = (
    <div ref={node}>
      <ConfigProvider getPopupContainer={() => node.current || document.body}>
        {children}
        {fullscreen === FullscreenMode.Inline ? <FixedGlobalStyles /> : null}
      </ConfigProvider>
    </div>
  )
  return fullscreen === FullscreenMode.Inline
    ? ReactDOM.createPortal(content, fixedRef.current)
    : content
}

const FixedGlobalStyles = createGlobalStyle`
  body {
    overflow: hidden !important;
  }
  .intercom-lightweight-app, #fc_frame {
    display: none !important;
  }
  .feature-web-fixed-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 10000; // This should be above anything antd produces.
    overflow-y: auto;
    background: ${props => (props.theme as any)['@layout-body-background']};
    > div {
      pointer-events: all;
    }
  }
`
