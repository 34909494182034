import { TeacherContextHook } from '@thesisedu/feature-teachers-react'
import React from 'react'

import { ClassContextProvider } from '../ClassContext'
import { ClassSelectorModalContextProvider } from '../ClassSelectorModal'
import ClassesReactFeature from '../ClassesReactFeature'
import { StudentSelectorProvider } from '../student_selector/StudentSelectorContext'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<TeacherContextHook>(
    'teachers-react:teacher-context',
    children => {
      return (
        <ClassContextProvider>
          <ClassSelectorModalContextProvider>
            <StudentSelectorProvider>{children}</StudentSelectorProvider>
          </ClassSelectorModalContextProvider>
        </ClassContextProvider>
      )
    },
  )
}
