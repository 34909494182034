import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { UserAvatar } from '@thesisedu/feature-attachments-react'
import {
  Body,
  VSpaced,
  LoadingIndicator,
  BodySmall,
  Block,
  Tooltip,
  styledContainer,
  getSize,
} from '@thesisedu/react'
import pluralize from 'pluralize'
import React from 'react'

import {
  LessonStudentViewershipDocument,
  LessonStudentViewershipQuery,
  LessonStudentViewershipQueryVariables,
  useLessonStudentViewershipQuery,
} from '../schema'

export interface LessonViewershipProps {
  lessonId: string
}
export function LessonViewership({ lessonId }: LessonViewershipProps) {
  const scrollContainer = React.useRef<HTMLDivElement>(null)
  const { data } = useLessonStudentViewershipQuery({ variables: { lessonId } })
  const totalStudents = data?.node?.__typename === 'Lesson' ? data.node.students.totalCount : 0
  const studentsViewed = data?.node?.__typename === 'Lesson' ? data.node.studentsViewed || 0 : 0
  return (
    <VSpaced align={'stretch'}>
      <Body color={'@text-color-secondary'}>
        {studentsViewed} / {totalStudents} students have viewed this Lesson
      </Body>
      <ScrollContainer ref={scrollContainer}>
        <InfiniteQuery<any, LessonStudentViewershipQuery, LessonStudentViewershipQueryVariables>
          document={LessonStudentViewershipDocument}
          variables={{
            first: 50,
            lessonId,
          }}
          resultPath={'node.students'}
          children={({ data, loading }) => {
            const students = data?.node?.__typename === 'Lesson' ? data.node.students.edges : []
            if (students.length) {
              return (
                <ItemsContainer>
                  {students.map(s => (
                    <Tooltip
                      title={`${s.node.user.name}${
                        s.viewedTimes > 0
                          ? ` - Viewed ${pluralize('time', s.viewedTimes, true)}, ${pluralize(
                              'minute',
                              s.viewedMinutes,
                              true,
                            )} total`
                          : ''
                      }`}
                      key={s.node.id}
                    >
                      <AvatarContainer viewed={s.viewedTimes > 0}>
                        <UserAvatar shape={'square'} user={s.node.user} />
                      </AvatarContainer>
                    </Tooltip>
                  ))}
                </ItemsContainer>
              )
            } else if (loading) {
              return <LoadingIndicator block />
            } else {
              return (
                <Block marginTop={'@size-m'}>
                  <BodySmall color={'@text-color-secondary'}>
                    No students have viewed this lesson just yet.
                  </BodySmall>
                </Block>
              )
            }
          }}
        />
      </ScrollContainer>
    </VSpaced>
  )
}

const ScrollContainer = styledContainer`
  max-height: 300px;
  overflow-y: auto;
`
const AvatarContainer = styledContainer<{ viewed: boolean }>`
  margin-right: ${props => getSize(props.theme, '@size-xxs')};
  margin-bottom: ${props => getSize(props.theme, '@size-xxs')};
  opacity: ${props => (props.viewed ? 1 : 0.5)};
`
const ItemsContainer = styledContainer`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: ${props => getSize(props.theme, '@size-xs')};
  margin-right: -${props => getSize(props.theme, '@size-xxs')};
  margin-bottom: -${props => getSize(props.theme, '@size-xxs')};
`
