import { useIds } from '@thesisedu/feature-react'
import { HlsPlayer } from '@thesisedu/react'
import React from 'react'

import { warn } from './log'
import { VideoViewProps } from './types'

export function DefaultVideoView({ playbackData, playerProps }: VideoViewProps) {
  const defaultPlaybackData =
    playbackData.__typename === 'DefaultVodPlaybackData' ? playbackData : undefined
  useIds(defaultPlaybackData ? [{ id: defaultPlaybackData.hlsUrl, label: 'Video on Demand' }] : [])
  if (defaultPlaybackData) {
    return <HlsPlayer playerProps={playerProps} hlsUrl={defaultPlaybackData.hlsUrl} />
  } else {
    warn(
      'Passed PlaybackData is not the default data. Are you missing a custom video view resource?',
    )
    return null
  }
}
