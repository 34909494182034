import { useIds } from '@thesisedu/feature-react'
import { createPromptContext, ScrollableContainerProvider } from '@thesisedu/react'
import {
  Block,
  BodyCapital,
  BodySmall,
  Capital,
  DATE_FORMATS,
  H1,
  H2,
  HSpaced,
  Modal,
  Space,
  styled,
  Transition,
} from '@thesisedu/web'
import { ConfigProvider } from 'antd'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

import { GradingModalNavigation } from './GradingModalNavigation'
import { GradingModalSidebarAttempts } from './GradingModalSidebarAttempts'
import { GradingModalSubmissions } from './GradingModalSubmissions'
import { guessAssignmentCategoryName } from './helpers'
import { useGradingPage } from './useGradingPage'
import {
  GradingModalContextProvider,
  GradingModalContextValue,
} from '../contexts/GradingModalContext'
import { useAssignmentQueryVariables } from '../queries/useAssignmentQueryVariables'
import { useAssignmentQuery } from '../schema'

// Backwards compatibility.
export * from './helpers'

const GradingModalPromptContext = createPromptContext()

export interface GradingModalProps {
  visible?: boolean
  onClose: () => void
  assignment: GradingModalContextValue['assignment']
  defaultTab?: string
}
export function GradingModal({
  visible,
  onClose,
  defaultTab = 'settings',
  assignment: _assignment,
}: GradingModalProps) {
  useIds([{ id: _assignment.id, label: 'Grading Modal' }])
  const promptContextResult = GradingModalPromptContext.usePromptContextProvider()
  const [selectedTab, _setSelectedTab] = React.useState<string>(defaultTab)
  const setSelectedTab = (tab: string) => {
    promptContextResult.context.checkForPrompts(() => {
      _setSelectedTab(tab)
    })
  }
  const assignmentQuery = useAssignmentQuery({
    variables: { id: _assignment.id, ...useAssignmentQueryVariables() },
    fetchPolicy: 'cache-only',
  })
  const assignmentQueryAssignment =
    assignmentQuery.data?.node?.__typename === 'Assignment' ? assignmentQuery.data.node : undefined
  const assignment = assignmentQueryAssignment || _assignment
  React.useEffect(() => {
    setSelectedTab(defaultTab)
  }, [defaultTab])
  const { navigationItems, studentTab, title, tabContent, headerRightContent } = useGradingPage(
    assignment,
    selectedTab,
  )
  const assignmentComponents: string[] = []
  if (assignment?.totalPoints !== undefined) {
    assignmentComponents.push(pluralize('point', assignment.totalPoints, true))
  }
  if (assignment?.dueAt) {
    assignmentComponents.push(`Due ${moment(assignment.dueAt).format(DATE_FORMATS.FULL)}`)
  }
  const rightContainerRef = React.useRef<HTMLDivElement>(null)

  // Only show the category name if they're all the same, or a class is specified.
  const categoryName = guessAssignmentCategoryName(assignment, studentTab?.classId)
  if (categoryName) {
    assignmentComponents.push(categoryName)
  }

  React.useEffect(() => {
    return () => onClose()
  }, [])

  return (
    <StyledModal
      bodyStyle={{ minHeight: 400, padding: 0 }}
      visible={visible}
      onCancel={() => {
        promptContextResult.context.checkForPrompts(() => {
          onClose()
        })
      }}
      destroyOnClose={false}
      forceRender
    >
      {promptContextResult.children}
      <GradingModalContextProvider
        assignment={assignment}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        {...promptContextResult.context}
      >
        <Container data-testid={'GradingModal'}>
          <ContainerLeft>
            <Header>
              <BodyCapital color={'@white'} style={{ opacity: 0.45, marginBottom: 10 }}>
                Currently Grading
              </BodyCapital>
              <H2 isBlock={false} color={'@white'}>
                {assignment.name}
              </H2>
            </Header>
            <NavigationContainer paddingTop={'@size-s'} paddingBottom={'@size-s'}>
              <GradingModalNavigation items={navigationItems} />
              <GradingModalSidebarAttempts assignment={assignment} studentTab={studentTab} />
              <Block marginLeft={'@size-m'} marginTop={'@size-l'} marginRight={'@size-m'}>
                <Capital isBlock>Submissions</Capital>
              </Block>
              <GradingModalSubmissions />
            </NavigationContainer>
          </ContainerLeft>
          <ContainerRight ref={rightContainerRef}>
            <ConfigProvider getTargetContainer={() => rightContainerRef.current || document.body}>
              <ScrollableContainerProvider targetRef={rightContainerRef}>
                <Transition state={selectedTab} type={'fade-up'}>
                  <Block
                    marginBottom={'@size-m'}
                    style={{ position: 'relative', zIndex: 11, background: 'white' }}
                  >
                    <HSpaced align={'flex-end'}>
                      <div>
                        <BodySmall color={'@text-color-secondary'}>
                          {assignmentComponents.join(' - ')}
                        </BodySmall>
                        <H1 isBlock={false}>{title}</H1>
                      </div>
                      <Space />
                      {headerRightContent}
                    </HSpaced>
                  </Block>
                  {tabContent}
                </Transition>
              </ScrollableContainerProvider>
            </ConfigProvider>
          </ContainerRight>
        </Container>
      </GradingModalContextProvider>
    </StyledModal>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`
const ContainerLeft = styled.div`
  width: 300px;
  background: ${props => props.theme['@background-color-base']};
  overflow: hidden;
  flex-direction: column;
  display: flex;
  align-items: stretch;
  h2 {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: calc(${props => props.theme['@heading-2-size']} * 1.4);
    max-height: calc(${props => props.theme['@heading-2-size']} * 1.4 * 2);
  }
`
const ContainerRight = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-m']}
    ${props => props.theme['@size-l']} ${props => props.theme['@size-l']};
  flex: 1;
  min-height: 0;
`
const Header = styled.div`
  background: ${props => props.theme['@primary-color']};
  padding: ${props => props.theme['@size-l']};
`
const StyledModal = styled(Modal)`
  top: 0;
  width: 100% !important;
  max-width: none;
  margin: 0;
  padding-bottom: 0;
  .ant-modal-body {
    height: var(--app-height);
  }
  .ant-modal-content {
    border-radius: 0 !important;
    overflow: hidden;
    transform: translateZ(0); // Safari hack to make the border radius work.
  }
  > .ant-modal-content > .ant-modal-close {
    right: ${props => props.theme['@size-m']} !important;
  }
`
const NavigationContainer = styled(Block)`
  flex: 1;
  overflow-y: auto;
`
