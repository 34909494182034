import { s } from '@thesisedu/ui'

import { ALL_MARKS } from './marks'

export const MarkStyles = s.css`
  mark {
    padding: 0;
  }
  ${ALL_MARKS.map(
    mark => s.css`
    mark.${mark.identifier} {
      ${mark.style}
    }
  `,
  )}
`
