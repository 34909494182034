import { AuthRoutesHook, UserRoutesHook, UserTopItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { AcceptInvitePage } from './AcceptInvitePage'
import { Classes } from './classes'
import { StudentClasses } from './classes/StudentClasses'
import ClassesReactFeature from '../../ClassesReactFeature'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [
        ...routes,
        <Route key={'accept-invite'} path={'accept-invite'} element={<AcceptInvitePage />} />,
      ]
    },
  )
  feature.hookManager.registerMutateHook<UserTopItemsHook>(
    'feature-users-react:top-items',
    (items, { group }) => {
      if (group === 'TEACHER' || group === 'STUDENT') {
        return [
          {
            weight: 0,
            element: (
              <Shell.TopMenuItem
                key={'classes'}
                name={'Classes'}
                path={`/${group.toLowerCase()}/classes`}
              />
            ),
          },
          ...items,
        ]
      } else return items
    },
  )
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        return [
          <Route key={'root'} path={'/'} element={<Navigate to={'classes'} replace />} />,
          <Route key={'classes'} path={'classes/*'} element={<Classes />} />,
          ...routes,
        ]
      } else if (group === 'STUDENT') {
        return [
          <Route key={'root'} path={'/'} element={<Navigate to={'classes'} replace />} />,
          <Route key={'classes'} path={'classes/*'} element={<StudentClasses />} />,
          ...routes,
        ]
      } else return routes
    },
  )
}
