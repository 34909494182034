import { LoadingOutlined } from '@ant-design/icons'
import { ColorClever } from '@sammarks/icons'
import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { ApolloError } from '@thesisedu/feature-apollo-react/apollo'
import { BrochureLink, useFeature } from '@thesisedu/feature-react'
import { loginUser, UsersWebFeature, useViewerContext } from '@thesisedu/feature-users-web'
import { Block, BodySmall } from '@thesisedu/web'
import { Result, notification } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CleverReactFeature } from '../CleverReactFeature'
import { error } from '../log'
import { useLoginWithCleverMutation, useAssociateCleverMutation, UserFragment } from '../schema'

export function CleverAuthRoute() {
  const viewer = useViewerContext(false)
  const feature = useFeature(CleverReactFeature)
  const usersFeature = useFeature(UsersWebFeature)
  const apolloFeature = useFeature(ApolloReactFeature)
  const [errorMessage, setErrorMessage] = React.useState('')
  const navigate = useNavigate()
  function onError(err: ApolloError) {
    const code = err.graphQLErrors?.[0]?.extensions?.code as string | undefined
    if (code === 'CLEVER_USER_TYPE_MISMATCH_ERROR') {
      const otherGroup = viewer?.group === 'TEACHER' ? 'student' : 'teacher'
      const group = viewer?.group === 'TEACHER' ? 'teacher' : 'student'
      setErrorMessage(
        `It looks like you tried to login with a ${otherGroup} Clever user, but your current account is a ${group}. Either logout and log back in or try logging in with a different Clever user.`,
      )
    } else if (code === 'USER_ACCOUNT_NOT_FOUND_ERROR') {
      const accountType = (err.graphQLErrors[0].extensions?.account as any)?.data?.type as
        | string
        | undefined
      const user = err.graphQLErrors[0].extensions?.user as UserFragment
      if (accountType === 'teacher') {
        let email = user?.email
        if (email?.includes('teacher@clever.')) email = ''
        navigate(
          `/auth/trial-request?email=${email}&firstName=${user?.firstName}&lastName=${user?.lastName}`,
        )
        notification.open({
          message: 'Welcome, Clever user!',
          description: `In order to get started using ${feature.appOptions.name}, register for a free trial using the
            form to the left and a member of our sales team will be back with you as soon as possible.`,
          icon: (
            <span className={'anticon'}>
              <ColorClever />
            </span>
          ),
          placement: 'topRight',
          duration: null,
        })
      } else {
        setErrorMessage(
          "We couldn't find a user for that Clever user. Talk to your teacher and have them add your Clever user to their class.",
        )
      }
    } else {
      error(err)
      setErrorMessage('An unknown error occurred.')
    }
  }
  async function onComplete(user: UserFragment, jwt?: string) {
    const _jwt = jwt || localStorage.getItem(apolloFeature.options.authenticationKey)
    if (_jwt) {
      await loginUser({
        navigate,
        usersFeature,
        apolloFeature,
        jwt: _jwt,
        user,
      }).catch(onError)
    }
  }
  const [loginWithClever] = useLoginWithCleverMutation({
    onError,
    onCompleted: async data => {
      await onComplete(data.loginWithClever.user, data.loginWithClever.jwt)
    },
  })
  const [associateWithClever] = useAssociateCleverMutation({
    onError,
    onCompleted: async data => {
      await onComplete(data.associateClever.user)
    },
  })
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    if (code) {
      const redirectUri = window.location.origin + window.location.pathname
      if (viewer?.group && viewer.group !== 'ANONYMOUS') {
        associateWithClever({
          variables: {
            input: { code, redirectUri },
          },
        })
      } else {
        loginWithClever({
          variables: {
            input: { code, redirectUri },
          },
        })
      }
    } else {
      setErrorMessage(
        'The response we got back from Clever appears to be invalid. Please try again.',
      )
    }
  }, [])

  if (errorMessage) {
    return (
      <Result status={'error'} title={"Something's not right."} subTitle={errorMessage}>
        <BodySmall color={'@text-color-secondary'}>
          Contact <BrochureLink to={'support'}>{feature.appOptions.name} Support</BrochureLink> if
          you have any questions.
        </BodySmall>
      </Result>
    )
  } else {
    return (
      <Block marginBottom={'@size-xl'}>
        <Result icon={<LoadingOutlined size={75} />} title={'Logging in with Clever...'} />
      </Block>
    )
  }
}
