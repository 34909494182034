import { BlockSpin, Body, BodySmall, Modal, styled, Block, HSpaced } from '@thesisedu/web'
import { Button, ButtonProps, Collapse, Empty } from 'antd'
import pluralize from 'pluralize'
import React from 'react'

import {
  MultiStudentSelectStudentCheckbox,
  MultiStudentSelectStudentList,
} from './SimpleMultiStudentSelect'
import { useStudentGroups } from './useStudentGroups'

export const Wrapper = styled.div`
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-extra {
    margin-bottom: 2px;
  }
  .ant-collapse-header > span {
    display: inline-block;
    max-width: calc(100% - 80px) !important;
  }
`

export interface MultiStudentSelectProps {
  value?: string[]
  onChange?: (selectedStudentIds?: string[]) => void
  classId: string
  single?: boolean
}
export function MultiStudentSelect({ value, onChange, classId, single }: MultiStudentSelectProps) {
  const { groupedStudents, sortedKeys, loading } = useStudentGroups(classId)
  const allStudentIds = React.useMemo(
    () =>
      sortedKeys.reduce((studentIds, currentGroup) => {
        const currentGroupStudentIds = groupedStudents[currentGroup].map(cs => cs.id)
        return studentIds.concat(currentGroupStudentIds)
      }, [] as string[]),
    [classId, value],
  )
  const allSelected = allStudentIds.length === value?.length

  if (sortedKeys?.length) {
    return (
      <Wrapper>
        {sortedKeys.length > 1 && (
          <Block marginBottom={'@size-s'}>
            <HSpaced justify={'flex-start'}>
              <Button
                key={'Select All'}
                size={'middle'}
                onClick={() => {
                  if (!onChange) return
                  onChange(allSelected ? undefined : allStudentIds)
                }}
              >
                {allSelected ? `Deselect` : 'Select'} All
              </Button>
            </HSpaced>
          </Block>
        )}
        <Collapse defaultActiveKey={Object.keys(groupedStudents)}>
          {sortedKeys.map(studentGroupName => {
            const students = groupedStudents[studentGroupName]
            return (
              <Collapse.Panel
                key={studentGroupName}
                collapsible={'header'} // Weird typing issue...?
                header={
                  <span>
                    <Body>{studentGroupName}</Body>
                    <BodySmall color={'@text-color-secondary'}>
                      {pluralize('student', students.length, true)}
                    </BodySmall>
                  </span>
                }
                extra={
                  single ? undefined : (
                    <MultiStudentSelectStudentCheckbox
                      value={value}
                      onChange={onChange}
                      students={students}
                    />
                  )
                }
              >
                <MultiStudentSelectStudentList
                  value={value}
                  onChange={onChange}
                  students={students}
                  single={single}
                />
              </Collapse.Panel>
            )
          })}
        </Collapse>
      </Wrapper>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No students'} />
  }
}

export interface MultiStudentSelectButtonProps extends MultiStudentSelectProps {
  buttonProps?: Partial<ButtonProps>
}
export function MultiStudentSelectButton({ buttonProps, ...rest }: MultiStudentSelectButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const label = rest.value?.length
    ? `${pluralize('student', rest.value.length, true)} selected`
    : 'Select Students'
  return (
    <>
      <Modal
        title={'Select Students'}
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={[
          <Button
            key={'save'}
            type={'primary'}
            onClick={() => setVisible(false)}
            children={'Save'}
          />,
        ]}
      >
        <MultiStudentSelect {...rest} />
      </Modal>
      <Button children={label} {...buttonProps} onClick={() => setVisible(true)} />
    </>
  )
}
