import React from 'react'

import { StandardSetExploreFilter } from './StandardSetExploreFilter'
import { useExploreFullStandardSetsQuery } from '../schema'

export function StandardSetExploreFilters() {
  const { data } = useExploreFullStandardSetsQuery()
  const standardSets = data?.viewer?.teacher?.site.standardSets.edges.map(edge => edge.node)
  return (
    <>{standardSets?.map(standardSet => <StandardSetExploreFilter standardSet={standardSet} />)}</>
  )
}
