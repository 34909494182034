import React from 'react'

import { WarningCircle } from '../../../icons'
import HSpaced from '../HSpaced'
import Text, * as Text$ from '../Text'

export interface FieldErrorProps extends Text$.TextProps {}
export function FieldError({ children, ...props }: FieldErrorProps) {
  return (
    <HSpaced space={'xxs'} top={'xs'}>
      <Text level={'s'} as={'div'} color={'red'}>
        <WarningCircle />
      </Text>
      <Text level={'s'} color={'red'} {...props}>
        <strong>Error:</strong> {children}
      </Text>
    </HSpaced>
  )
}
