import React from 'react'

import { Reset } from '../Reset'
import { styled } from '../styled'
import { size, color } from '../theme'

export interface CellActionProps extends React.HTMLProps<HTMLButtonElement> {}
export function CellAction(props: CellActionProps) {
  return <Button {...(props as any)} />
}

const Button = styled(Reset.Button)`
  font-size: ${size('@font-size-xs')};
  color: ${color('gray-5')};
  transition: color 0.1s linear;
  &:hover {
    color: ${color('gray-7')};
  }
`
