import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Button } from 'antd'
import React from 'react'

import { useOptions } from '../options'
import { useRemoveTeacherFromClassMutation } from '../queries/useRemoveTeacherFromClassMutation'
import { TeamTeachingEdgeFragment, ClassRole } from '../schema'

export interface RemoveFromClassButtonProps {
  classId: string
  teacher: TeamTeachingEdgeFragment
  onRemove?: () => void
}
export function RemoveFromClassButton({ classId, teacher, onRemove }: RemoveFromClassButtonProps) {
  const options = useOptions()
  const [remove, { loading }] = useRemoveTeacherFromClassMutation({
    onCompleted: () => {
      if (onRemove) {
        onRemove()
      }
    },
  })
  const isOwner = teacher.role === ClassRole.Owner
  return (
    <Popconfirm
      disabled={isOwner}
      okButtonProps={{ danger: true }}
      okText={'Yes, remove.'}
      cancelText={'No, keep.'}
      title={
        <span>
          Are you sure you want to remove{' '}
          <strong>{teacher.node.user.name || teacher.node.user.username}?</strong>
        </span>
      }
      onConfirm={async () => {
        return remove({
          variables: {
            input: {
              id: classId,
              teacherId: teacher.node.id,
            },
          },
        })
      }}
    >
      <Button danger loading={loading} icon={<DeleteOutlined />}>
        Remove from {options.classNameCapitalized}
      </Button>
    </Popconfirm>
  )
}
