import { Slider } from './Slider'
import * as Elements from './elements'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './elements'
export * from './Slider'

export default withSubComponents(Slider, {
  ...Elements,
})
