import { ExclamationCircleFilled } from '@ant-design/icons/lib'
import React from 'react'
import styled from 'styled-components'

import { H2, Body } from './Typography'

export interface ErrorIndicatorProps {
  isCompact?: boolean
}

const Container = styled.div<ErrorIndicatorProps>`
  display: flex;
  max-width: 400px;
  margin: 0 auto;
  padding: ${props => (props.isCompact ? '10px' : '30px')};
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${props => (props.isCompact ? 'center' : 'flex-start')};
  svg {
    width: ${props => (props.isCompact ? '20px' : '50px')};
    margin: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-m']} 0 0;
    color: #ff0000;
    fill: #ff0000;
  }
  > div {
    margin: 0;
    flex-grow: 1;
  }
  h2 {
    margin-bottom: ${props => props.theme['@size-xs']};
  }
`
export const ErrorIndicator: React.FC<React.PropsWithChildren<ErrorIndicatorProps>> = ({
  isCompact,
}) => {
  const content = isCompact ? (
    <ExclamationCircleFilled />
  ) : (
    <>
      <ExclamationCircleFilled />
      <div>
        <H2>Oh dear!</H2>
        <Body>
          Something went very wrong. Try again, and if the issue keeps happening, contact support.
        </Body>
      </div>
    </>
  )
  return <Container children={content} />
}
