import { useMutateHook } from '@thesisedu/feature-react'
import { groupBy } from 'lodash'

import {
  StudentWithGroup,
  StudentClassGroupingsPayload,
  StudentClassGroupingsContext,
  ClassHooks,
} from '../types'
import { useTeacherClass } from '../useClass'

const UNGROUPED_KEY = 'Ungrouped Students'
export const ALL_STUDENTS = 'All Students'

export function useStudentGroups(classId: string) {
  const { cls, loading } = useTeacherClass(classId, false)
  let studentsWithGroups: StudentWithGroup[] | undefined = cls?.students.edges.map(edge => ({
    ...edge.node,
    group: UNGROUPED_KEY,
    classId,
  }))

  // Mutate to allow other features the ability to declare their own groups for individal
  // classes.
  studentsWithGroups = useMutateHook<StudentClassGroupingsPayload, StudentClassGroupingsContext>(
    ClassHooks.StudentClassGroupings,
    studentsWithGroups || [],
    {
      class: cls || undefined,
    },
  )

  if (cls && studentsWithGroups?.length) {
    const groupedStudents = groupBy(studentsWithGroups, 'group')
    if (Object.keys(groupedStudents).length === 1 && groupedStudents[UNGROUPED_KEY]) {
      groupedStudents[ALL_STUDENTS] = groupedStudents[UNGROUPED_KEY]
      delete groupedStudents[UNGROUPED_KEY]
    }
    const sortedKeys = Object.keys(groupedStudents).sort((a, b) => {
      if (b === ALL_STUDENTS || b === UNGROUPED_KEY) return -1
      else if (a === ALL_STUDENTS || a === UNGROUPED_KEY) return 1
      else return a.localeCompare(b)
    })

    return { groupedStudents, sortedKeys, loading }
  } else {
    return { groupedStudents: {}, sortedKeys: [], loading }
  }
}
