import React from 'react'

import { CopyToClipboardButton } from '../CopyToClipboardButton'
import { BodyExtraSmall, BodySmall, HSpaced, Space } from '../common'
import { styledContainer as styled, styled as styledComponent } from '../styled'
import { color, size } from '../theme'

export interface CellProps {
  title: string
  rightActions?: React.ReactElement | React.ReactElement[]
  children: React.ReactElement | React.ReactElement[]
}
export function Cell({ title, rightActions, children }: CellProps) {
  return (
    <Container>
      <Header>
        <BodyExtraSmall color={'@text-color-secondary'}>{title}</BodyExtraSmall>
        {rightActions ? (
          <>
            <Space />
            {rightActions}
          </>
        ) : null}
      </Header>
      {children}
    </Container>
  )
}

export interface CellFieldProps {
  label: string | React.ReactElement
  value: string
}
export function CellField({ label, value }: CellFieldProps) {
  return (
    <HSpaced space={'@size-xs'}>
      <CellLabel>{label}</CellLabel>
      <CellValue value={value} />
      <CellCopyButton text={value} />
    </HSpaced>
  )
}

export interface CellLabelProps {
  children: React.ReactElement | string
}
export function CellLabel({ children }: CellLabelProps) {
  return <Label children={children} />
}

export interface CellValueProps {
  value: string
}
export function CellValue({ value }: CellValueProps) {
  return (
    <BodySmall
      code
      onClick={e => {
        const range = document.createRange()
        range.selectNode(e.target as Node)
        window.getSelection()?.removeAllRanges()
        window.getSelection()?.addRange(range)
      }}
      title={value}
      style={{
        minWidth: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value}
    </BodySmall>
  )
}

export interface CellCopyButtonProps {
  text: string
}
export function CellCopyButton({ text }: CellCopyButtonProps) {
  return (
    <BodySmall color={'@text-color-secondary'} style={{ flexShrink: 0 }}>
      <CopyToClipboardButton text={text} />
    </BodySmall>
  )
}

export interface ScrollContentProps {
  children: React.ReactElement | React.ReactElement[]
  height?: number
}
export function ScrollContent({ children, height = 200 }: ScrollContentProps) {
  return <ScrollContainer children={children} style={{ height }} />
}

const ScrollContainer = styled`
  margin: -${size('@size-xs')};
  overflow: auto;
  padding: ${size('@size-xs')};
`
const Container = styled`
  border-radius: ${size('@border-radius-base')};
  border: solid 1px ${color('@border-color-base')};
  padding: ${size('@size-xs')};
  margin-bottom: ${size('@size-xs')};
`
const Header = styled`
  margin: -${size('@size-xs')};
  margin-bottom: ${size('@size-xs')};
  padding: ${size('@size-xxs')};
  background: ${color('gray-1')};
  border-top-left-radius: ${size('@border-radius-base')};
  border-top-right-radius: ${size('@border-radius-base')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${size('@size-xs')};
`
const Label = styledComponent(BodySmall)`
  color: ${color('@text-color-secondary')} !important;
  flex-shrink: 0;
  width: 55px;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
