import React from 'react'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'

type DropFunction = OnDragEndResponder
export interface NestedDragDropContextValue {
  registerDropFunction: (name: string, callback: DropFunction) => void
  removeDropFunction: (name: string) => void
}
export const NestedDragDropContext = React.createContext<NestedDragDropContextValue | undefined>(
  undefined,
)

export interface HandlerNameContextValue {
  name: string
}
export const HandlerNameContext = React.createContext<HandlerNameContextValue | undefined>(
  undefined,
)

interface DropFunctions {
  [key: string]: DropFunction
}
export interface NestedDragDropProviderProps {
  onDragEnd: DropFunction
  name: string
  children: React.ReactElement
}
export function NestedDragDropProvider({ onDragEnd, name, children }: NestedDragDropProviderProps) {
  const existing = useNestedDragDropContext(false)
  const registeredDropFunctions = React.useRef<DropFunctions>({})
  const registerDropFunction: NestedDragDropContextValue['registerDropFunction'] =
    existing?.registerDropFunction ||
    ((name, f) => {
      if (!registeredDropFunctions.current) {
        registeredDropFunctions.current = {}
      }
      registeredDropFunctions.current[name] = f
    })
  const removeDropFunction: NestedDragDropContextValue['removeDropFunction'] =
    existing?.removeDropFunction ||
    (name => {
      delete registeredDropFunctions.current[name]
    })
  React.useEffect(() => {
    registerDropFunction(name, onDragEnd)
    return () => removeDropFunction(name)
  }, [onDragEnd, name])
  let content = children
  if (!existing) {
    content = (
      <NestedDragDropContext.Provider value={{ registerDropFunction, removeDropFunction }}>
        <DragDropContext
          onDragEnd={(result, ...args) => {
            if (result.destination) {
              try {
                const parsed = JSON.parse(result.destination.droppableId)
                const parsedSource = JSON.parse(result.source.droppableId)
                if (
                  parsed.droppableId &&
                  parsed.handler &&
                  registeredDropFunctions.current[parsed.handler]
                ) {
                  result.destination.droppableId = parsed.droppableId
                  result.source.droppableId = parsedSource.droppableId
                  registeredDropFunctions.current[parsed.handler](result, ...args)
                }
              } catch {}
            }
          }}
          children={children}
        />
      </NestedDragDropContext.Provider>
    )
  }

  return <HandlerNameContext.Provider value={{ name }} children={content} />
}

export function getDroppableId(droppableId: string, handlerName: string) {
  return JSON.stringify({
    droppableId,
    handler: handlerName,
  })
}

export function useDroppableId(droppableId: string, handlerName?: string) {
  const { name } = React.useContext(HandlerNameContext) || {}
  if (!handlerName && !name)
    throw new Error('handlerName not passed, and not inside HandlerNameContext.')
  return getDroppableId(droppableId, handlerName || name!)
}

export function useNestedDragDropContext(): NestedDragDropContextValue | undefined
export function useNestedDragDropContext(require: false): NestedDragDropContextValue | undefined
export function useNestedDragDropContext(require: true): NestedDragDropContextValue
export function useNestedDragDropContext(
  require?: boolean,
): NestedDragDropContextValue | undefined {
  const context = React.useContext(NestedDragDropContext)
  if (!context && require) {
    throw new Error('NestedDragDropContext is required, yet not provided.')
  }
  return context
}
