import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { $getSelection, $isRangeSelection, $isTextNode, LexicalEditor } from 'lexical'
import React from 'react'
import { createPortal } from 'react-dom'

import { TextFormatFloatingToolbar } from '../../ui/TextFormatToolbar/TextFormatFloatingToolbar'
import { useGetActiveTextFormatToolbarItems } from '../../ui/TextFormatToolbar/useTextFormatToolbarItems'
import { getSelectedNode } from '../../utils/getSelectedNode'

export function useTextFormatToolbarState(editor: LexicalEditor) {
  const [isText, setIsText] = React.useState(false)
  const getActiveItems = useGetActiveTextFormatToolbarItems({ editor })
  const [activeItems, setActiveItems] = React.useState<Record<string, boolean | string[]>>({})

  const updatePopup = React.useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isComposing()) return
      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = getSelectedNode(selection)

      // Update formats...
      setActiveItems(getActiveItems(selection))

      // Update isText...
      setIsText($isTextNode(node) && selection.getTextContent() !== '')
    })
  }, [editor, getActiveItems])

  React.useEffect(() => {
    document.addEventListener('selectionchange', updatePopup)
    return () => {
      document.removeEventListener('selectionchange', updatePopup)
    }
  }, [updatePopup])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        // TODO: This might cause typing to slow down...
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      }),
    )
  }, [editor, updatePopup])

  return { isText, activeItems }
}

function useFloatingTextFormatToolbar(editor: LexicalEditor, anchorElement: HTMLElement) {
  const { isText, activeItems } = useTextFormatToolbarState(editor)

  if (!isText) return null

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElement={anchorElement}
      activeItems={activeItems}
    />,
    anchorElement,
  )
}

export interface FloatingTextFormatToolbarPluginProps {
  anchorElement?: HTMLElement
}
export function FloatingTextFormatToolbarPlugin({
  anchorElement = document.body,
}: FloatingTextFormatToolbarPluginProps) {
  const [editor] = useLexicalComposerContext()
  return useFloatingTextFormatToolbar(editor, anchorElement)
}
