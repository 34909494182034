import { Block, BodySmall, LoadingIndicator, VSpaced } from '@thesisedu/react'
import { LogViewer } from '@thesisedu/react/devtools'
import React from 'react'

import { error } from '../../log'
import { DetailedLogFragment } from '../../schema'

enum State {
  Idle,
  Loading,
  Error,
}

export async function getLogContent(signedUrl: string): Promise<string | null> {
  try {
    const result = await fetch(signedUrl)
    const blob = await result.blob()
    // @ts-ignore DecompressionStream is not in the standard lib yet
    const ds = new DecompressionStream('gzip')
    const decompressedStream = blob.stream().pipeThrough(ds)
    return new Response(decompressedStream).text()
  } catch (err) {
    error('error loading log contents')
    error(err)
    return null
  }
}

export interface ViewDetailedLogProps {
  detailedLog: Pick<DetailedLogFragment, 'signedUrl'>
  defaultSearch?: string
}
export function ViewDetailedLog({ detailedLog, defaultSearch }: ViewDetailedLogProps) {
  const [text, setText] = React.useState<string | null>(null)
  const [state, setState] = React.useState(State.Idle)
  React.useEffect(() => {
    async function loadFile() {
      setState(State.Loading)
      const result = await getLogContent(detailedLog.signedUrl)
      if (result) {
        setText(result)
        setState(State.Idle)
      } else {
        setState(State.Error)
      }
    }

    loadFile().catch(err => {
      error(err)
    })
  }, [detailedLog.signedUrl])

  if (state === State.Loading) {
    return (
      <Block paddingTop={'@size-s'} paddingBottom={'@size-s'} style={{ width: '100%' }}>
        <VSpaced align={'center'} space={'@size-xs'} style={{ width: '100%' }}>
          <LoadingIndicator size={'@size-l'} />
          <BodySmall color={'@text-color-secondary'}>Loading logs...</BodySmall>
        </VSpaced>
      </Block>
    )
  } else if (state === State.Error || !text) {
    return <LogViewer text={'Error loading log.'} />
  } else {
    return <LogViewer text={text} defaultSearch={defaultSearch} />
  }
}
