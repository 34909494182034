import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { ClassApprovalTable } from './ClassApprovalTable'
import {
  ClassPendingStudentClassesDocument,
  ClassPendingStudentClassesQuery,
  ClassPendingStudentClassesQueryVariables,
  PendingStudentClassFragment,
  PendingStudentClassState,
} from '../schema'

export interface ClassApprovalPageRef {
  refresh: () => Promise<void>
}
export interface ClassApprovalPageProps {
  classId: string
  state?: PendingStudentClassState
}
function _ClassApprovalPage(
  { classId, state }: ClassApprovalPageProps,
  ref: React.ForwardedRef<ClassApprovalPageRef>,
) {
  const refreshRef = React.useRef<() => Promise<void>>()
  React.useImperativeHandle(
    ref,
    () => ({
      async refresh() {
        await refreshRef.current?.()
      },
    }),
    [],
  )
  return (
    <InfiniteQuery<
      PendingStudentClassFragment,
      ClassPendingStudentClassesQuery,
      ClassPendingStudentClassesQueryVariables
    >
      document={ClassPendingStudentClassesDocument}
      resultPath={'node.pendingStudentClasses'}
      noLoading
      variables={{
        first: 32,
        classId,
        states: state ? [state] : [],
      }}
      children={({ data, loadingFresh, refetch }) => {
        refreshRef.current = async () => {
          await refetch()
        }
        const records =
          data?.node?.__typename === 'Class'
            ? data.node.pendingStudentClasses.edges.map(edge => edge.node)
            : undefined
        return <ClassApprovalTable records={records ?? []} loading={loadingFresh} />
      }}
    />
  )
}
export const ClassApprovalPage = React.forwardRef(_ClassApprovalPage)
