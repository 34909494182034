import { PluginLevel } from '@thesisedu/feature-widgets-react'
import { Form } from '@thesisedu/react'
import React from 'react'

import { useSubmissionData, useSubmissionProps } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'
import { BLOCKS_DEBOUNCE, LegacyEditorWrapper } from '../blocks'

export function LongFormContent(props: QuestionProps) {
  return (
    <Form.Item
      name={props.question.id}
      required={'This question is required.'}
      initialValue={useSubmissionData(props.question.id)}
    >
      <QuestionInstructions instructions={'Type your answer in the box below.'} padded>
        <LegacyEditorWrapper
          {...useSubmissionProps(props)}
          onChangeDebounce={BLOCKS_DEBOUNCE}
          level={PluginLevel.Simple}
          minHeight={100}
          placeholder={'Start typing your answer here...'}
          showToolbar
        />
      </QuestionInstructions>
    </Form.Item>
  )
}
