/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type ConfirmationInput = {};

export type DetailedLogsForUserInput = {
  handler?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jwt: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FreeUsernameInput = {
  username: Scalars['String']['input'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Permission {
  CreateUser = 'CREATE_USER',
  FreeUsername = 'FREE_USERNAME',
  GeneratePdf = 'GENERATE_PDF',
  Impersonate = 'IMPERSONATE',
  ListUser = 'LIST_USER',
  Login = 'LOGIN',
  ManageLogs = 'MANAGE_LOGS',
  Register = 'REGISTER',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  SearchUsers = 'SEARCH_USERS',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdateUser = 'UPDATE_USER',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserName = 'VIEW_USER_NAME'
}

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignedUploadInput = {
  extension: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type UnlinkAccountInput = {
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type UpdateUserDetailedLoggingInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UploadRequestMode {
  Basic = 'BASIC',
  FormData = 'FORM_DATA'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type BasicGeneratedPdfFragment = (
  {
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratedPdfUrlFragment = (
  {
  url?: string | null,
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratedPdfDataFragment = (
  {
  data: any,
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratePdfMutation_generatePdf_GeneratedPDFPayload_generatedPdf_GeneratedPDF = (
  {
  url?: string | null,
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratePdfMutation_generatePdf_GeneratedPDFPayload = (
  {
  generatedPdf: GeneratePdfMutation_generatePdf_GeneratedPDFPayload_generatedPdf_GeneratedPDF
}
  & {
  __typename?: 'GeneratedPDFPayload'
}
);

export type GeneratePdfMutation_Mutation = (
  {
  generatePdf: GeneratePdfMutation_generatePdf_GeneratedPDFPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type GeneratePdfMutationVariables = Exact<{
  input: GeneratePdfInput;
}>;


export type GeneratePdfMutation = GeneratePdfMutation_Mutation;

export type GeneratedPdfQuery_generatedPdf_GeneratedPDF = (
  {
  data: any,
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratedPdfQuery_Query = (
  {
  generatedPdf: GeneratedPdfQuery_generatedPdf_GeneratedPDF
}
  & {
  __typename?: 'Query'
}
);


export type GeneratedPdfQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type GeneratedPdfQuery = GeneratedPdfQuery_Query;

export type GeneratedPdfUrlQuery_generatedPdf_GeneratedPDF = (
  {
  url?: string | null,
  id: string,
  type: string,
  status: GeneratedPdfStatus
}
  & {
  __typename?: 'GeneratedPDF'
}
);

export type GeneratedPdfUrlQuery_Query = (
  {
  generatedPdf: GeneratedPdfUrlQuery_generatedPdf_GeneratedPDF
}
  & {
  __typename?: 'Query'
}
);


export type GeneratedPdfUrlQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
}>;


export type GeneratedPdfUrlQuery = GeneratedPdfUrlQuery_Query;

export const BasicGeneratedPdfFragmentDoc = gql`
    fragment BasicGeneratedPDF on GeneratedPDF {
  id
  type
  status
}
    `;
export const GeneratedPdfUrlFragmentDoc = gql`
    fragment GeneratedPDFUrl on GeneratedPDF {
  ...BasicGeneratedPDF
  url
}
    ${BasicGeneratedPdfFragmentDoc}`;
export const GeneratedPdfDataFragmentDoc = gql`
    fragment GeneratedPDFData on GeneratedPDF {
  ...BasicGeneratedPDF
  data
}
    ${BasicGeneratedPdfFragmentDoc}`;
export const GeneratePdfDocument = gql`
    mutation generatePdf($input: GeneratePDFInput!) {
  generatePdf(input: $input) {
    generatedPdf {
      ...GeneratedPDFUrl
    }
  }
}
    ${GeneratedPdfUrlFragmentDoc}`;
export type GeneratePdfMutationFn = ApolloReactCommon.MutationFunction<GeneratePdfMutation, GeneratePdfMutationVariables>;

/**
 * __useGeneratePdfMutation__
 *
 * To run a mutation, you first call `useGeneratePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePdfMutation, { data, loading, error }] = useGeneratePdfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratePdfMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<GeneratePdfMutation, GeneratePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<GeneratePdfMutation, GeneratePdfMutationVariables>(GeneratePdfDocument, options);
      }
export type GeneratePdfMutationHookResult = ReturnType<typeof useGeneratePdfMutation>;
export type GeneratePdfMutationResult = ApolloReactCommon.MutationResult<GeneratePdfMutation>;
export type GeneratePdfMutationOptions = ApolloReactCommon.BaseMutationOptions<GeneratePdfMutation, GeneratePdfMutationVariables>;
export const GeneratedPdfDocument = gql`
    query generatedPdf($id: ID!, $token: String) {
  generatedPdf(id: $id, token: $token) {
    ...GeneratedPDFData
  }
}
    ${GeneratedPdfDataFragmentDoc}`;

/**
 * __useGeneratedPdfQuery__
 *
 * To run a query within a React component, call `useGeneratedPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedPdfQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGeneratedPdfQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GeneratedPdfQuery, GeneratedPdfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GeneratedPdfQuery, GeneratedPdfQueryVariables>(GeneratedPdfDocument, options);
      }
export function useGeneratedPdfLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GeneratedPdfQuery, GeneratedPdfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GeneratedPdfQuery, GeneratedPdfQueryVariables>(GeneratedPdfDocument, options);
        }
export type GeneratedPdfQueryHookResult = ReturnType<typeof useGeneratedPdfQuery>;
export type GeneratedPdfLazyQueryHookResult = ReturnType<typeof useGeneratedPdfLazyQuery>;
export type GeneratedPdfQueryResult = ApolloReactCommon.QueryResult<GeneratedPdfQuery, GeneratedPdfQueryVariables>;
export const GeneratedPdfUrlDocument = gql`
    query generatedPdfUrl($id: ID!, $token: String) {
  generatedPdf(id: $id, token: $token) {
    ...GeneratedPDFUrl
  }
}
    ${GeneratedPdfUrlFragmentDoc}`;

/**
 * __useGeneratedPdfUrlQuery__
 *
 * To run a query within a React component, call `useGeneratedPdfUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedPdfUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedPdfUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGeneratedPdfUrlQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>(GeneratedPdfUrlDocument, options);
      }
export function useGeneratedPdfUrlLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>(GeneratedPdfUrlDocument, options);
        }
export type GeneratedPdfUrlQueryHookResult = ReturnType<typeof useGeneratedPdfUrlQuery>;
export type GeneratedPdfUrlLazyQueryHookResult = ReturnType<typeof useGeneratedPdfUrlLazyQuery>;
export type GeneratedPdfUrlQueryResult = ApolloReactCommon.QueryResult<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}
