import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { TeacherFragmentDoc, useUpdateTeacherMutation as useMutation } from '../schema'

export const useUpdateTeacherMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that teacher.'),
    update: updateMutation({
      fragment: TeacherFragmentDoc,
      fragmentName: 'Teacher',
      path: 'updateTeacher.teacher',
    }),
    ...opts,
  })
