/**
 * From: https://github.com/sindresorhus/yocto-queue/blob/main/index.js
 * We converted this to Typescript and made minor modifications.
 */

class Node<T> {
  value: T
  next?: Node<T>

  constructor(value: T) {
    this.value = value
  }
}

export class Queue<T> {
  #head?: Node<T>
  #tail?: Node<T>
  #size!: number

  constructor() {
    this.clear()
  }

  enqueue(value: T) {
    const node = new Node(value)

    if (this.#head && this.#tail) {
      this.#tail.next = node
      this.#tail = node
    } else {
      this.#head = node
      this.#tail = node
    }

    this.#size++
  }

  dequeue() {
    const current = this.#head
    if (!current) {
      return
    }

    this.#head = current.next
    this.#size--
    return current.value
  }

  clear() {
    this.#head = undefined
    this.#tail = undefined
    this.#size = 0
  }

  get size() {
    return this.#size
  }

  *[Symbol.iterator]() {
    let current = this.#head

    while (current) {
      yield current.value
      current = current.next
    }
  }
}
