import { DismissablePopover } from '@thesisedu/react'
import { Button, Checkbox, Form, HSpaced, Text } from '@thesisedu/ui'
import { callAttention } from '@thesisedu/web'
import React from 'react'

import { AssignmentGradingMode } from '../schema'

interface _AssignmentGradingModeFieldProps {
  value?: AssignmentGradingMode
  onChange?: (value: AssignmentGradingMode) => void
  hasQuestions: boolean
}
function _AssignmentGradingModeField({
  hasQuestions,
  value,
  onChange,
}: _AssignmentGradingModeFieldProps) {
  const isRubric = !hasQuestions || value === AssignmentGradingMode.Rubric
  const { form } = Form.useFormContext()
  if (!hasQuestions) return null // Teachers cannot change this option if there are no questions configured.
  return (
    <DismissablePopover.NewFeature
      dismissableKey={'assignments-react:grading-mode'}
      expiresAt={'2024-02-15'}
      title={'Redesigned: Custom Rubrics'}
      description={
        <Text level={'xs'}>
          Use rubric-based grading for any assignment, with support for automatically creating
          rubrics based on related state and national standards.
        </Text>
      }
      contentProps={{ side: 'left', align: 'start' }}
    >
      <Checkbox
        checked={isRubric}
        onCheckedChange={checked => {
          const newValue = checked ? AssignmentGradingMode.Rubric : AssignmentGradingMode.Questions
          onChange?.(newValue)
          if (newValue === AssignmentGradingMode.Rubric && hasQuestions) {
            // Clear the rubric whenever changing grading mode.
            form.setValue('rubric', { categories: [] })
          }
        }}
        aria-label={'Use a Custom Rubric'}
        data-testid={'CustomRubricCheckbox'}
        label={
          <HSpaced align={'baseline'}>
            <span>Use a Custom Rubric</span>
            {isRubric ? (
              <Button
                variant={'chromeless'}
                style={{ marginLeft: 'auto' }}
                size={'small'}
                onPress={() => {
                  const element = document.getElementById('custom-rubric')
                  element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                  setTimeout(() => {
                    if (element) callAttention(element)
                  }, 500)
                }}
              >
                Edit rubric
              </Button>
            ) : null}
          </HSpaced>
        }
        description={
          <>
            By default, this assignment is graded <strong>per question.</strong> If this is checked,
            you will be able to configure a custom rubric and grade the entire assignment with that
            rubric instead of one question at a time. Please note, this will disable auto-grading.
          </>
        }
      />
    </DismissablePopover.NewFeature>
  )
}

export type AssignmentGradingModeFieldProps = Omit<
  _AssignmentGradingModeFieldProps,
  'value' | 'onChange'
>
export function AssignmentGradingModeField(props: AssignmentGradingModeFieldProps) {
  return (
    <Form.Field name={'gradingMode'} aria-label={'Grading Mode'}>
      <_AssignmentGradingModeField {...props} />
    </Form.Field>
  )
}
