import { Modal, styled, s } from '@thesisedu/ui'
import React from 'react'

import { RecordView, RecordViewProps } from './RecordView'

export interface RecordViewModalProps extends RecordViewProps {
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
}
export function RecordViewModal({ visible, onVisibleChange, ...rest }: RecordViewModalProps) {
  return (
    <s.ThemeProvider colorVariant={'dark'}>
      <StyledModal visible={visible} onVisibleChange={onVisibleChange}>
        <RecordView {...rest} />
      </StyledModal>
    </s.ThemeProvider>
  )
}

const StyledModal = styled(Modal)`
  aspect-ratio: 16 / 9;
  width: calc(min(90vh * (16 / 9), 90vw)) !important;
  max-width: 90vw;
  max-height: 90vh;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  > div {
    flex: 1;
  }
`
