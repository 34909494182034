import { Body } from '@thesisedu/web'
import { message, Modal, Form, Button, Input } from 'antd'
import React from 'react'

import { useClassSelector } from './ClassContext'
import { useOptions } from './options'
import { useJoinClassMutation } from './queries/useJoinClassMutation'

export interface JoinClassModalProps {
  visible?: boolean
  onClose: () => void
}
export const JoinClassModal: React.FC<React.PropsWithChildren<JoinClassModalProps>> = ({
  visible,
  onClose: _onClose,
}) => {
  const [form] = Form.useForm()
  const options = useOptions()
  const { setSelectedClassId } = useClassSelector()
  const onClose = () => {
    form.resetFields()
    _onClose()
  }
  const [joinClass, { loading }] = useJoinClassMutation({
    onCompleted: data => {
      message.success('Joined class successfully!')
      setSelectedClassId(data.joinClass.class.id)
      onClose()
    },
  })
  return (
    <Modal
      visible={visible}
      title={`Join ${options.classNameCapitalized}`}
      onCancel={onClose}
      footer={[
        <Button key={'cancel'} onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button key={'join'} type={'primary'} onClick={() => form.submit()} loading={loading}>
          Join {options.classNameCapitalized}
        </Button>,
      ]}
    >
      <Body isBlock>
        Ask your instructor for their {options.className} code, and enter it in the box below to
        join the {options.className}.
      </Body>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          joinClass({
            variables: {
              input: {
                classId: values.classId,
              },
            },
          })
        }}
      >
        <Form.Item
          name={'classId'}
          label={`${options.classNameCapitalized} Code`}
          rules={[{ required: true, message: `A ${options.className} code is required.` }]}
        >
          <Input placeholder={'ABC12'} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
