import { styled } from '@thesisedu/ui'
import React from 'react'

import { useMediaPlaybackContext } from './MediaPlaybackContext'

const HOVER_TIMEOUT = 5000

export interface EventsContainerProps {
  setFooterVisible: (visible: boolean) => void
  setOverFooter: (overFooter: boolean) => void
  footerVisible: boolean
}
export function EventsContainer({ setFooterVisible, setOverFooter }: EventsContainerProps) {
  const hideTimeoutRef = React.useRef<any>()
  const { onSetPlaying } = useMediaPlaybackContext()
  return (
    <_EventsContainer
      onMouseEnter={() => {
        setFooterVisible(true)
      }}
      onMouseLeave={() => {
        clearInterval(hideTimeoutRef.current)
        hideTimeoutRef.current = undefined
        setFooterVisible(false)
      }}
      onMouseMove={() => {
        setFooterVisible(true)
        setOverFooter(false)
        if (hideTimeoutRef.current) {
          clearInterval(hideTimeoutRef.current)
        }
        hideTimeoutRef.current = setTimeout(() => {
          setFooterVisible(false)
        }, HOVER_TIMEOUT)
      }}
      onTouchEnd={() => {
        setTimeout(() => {
          setFooterVisible(false)
          setOverFooter(false)
        }, HOVER_TIMEOUT)
      }}
      onClick={() => {
        clearInterval(hideTimeoutRef.current)
        hideTimeoutRef.current = undefined
        onSetPlaying(p => !p)
      }}
    />
  )
}

export const _EventsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`
