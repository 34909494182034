import { styled, LargeBlock, H1, BodyLarge } from '@thesisedu/web'
import React from 'react'

// @ts-ignore asset imports
import LeftImage from './assets/confetti-left.svg'
// @ts-ignore asset imports
import RightImage from './assets/confetti-right.svg'

export interface BannerProps {
  title: string
  description: string
}
export function Banner({ title, description, children }: React.PropsWithChildren<BannerProps>) {
  return (
    <Container style={{ textAlign: 'center' }}>
      <div className={'content'}>
        <H1 children={title} />
        <BodyLarge isBlock children={description} />
        {children}
      </div>
      <img className={'left confetti'} src={LeftImage} alt={''} />
      <img className={'right confetti'} src={RightImage} alt={''} />
    </Container>
  )
}

const Container = styled(LargeBlock)`
  text-align: center;
  padding: ${props => props.theme['@size-xxl']} ${props => props.theme['@size-m']};
  position: relative;
  overflow: hidden;
  .content {
    max-width: 70%;
    min-width: 300px;
    margin: 0 auto;
  }
  .confetti {
    position: absolute;
    top: 0;
    bottom: 0;
    width: auto;
    pointer-events: none;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
`
