import { Legacy } from '@thesisedu/feature-widgets-core'

import { Segment } from './courseConfiguration'

export function getSegmentVodId(segment: Segment): string | undefined {
  const studentWidgets = Legacy.flatten(segment.config?.studentContent)
  const videoWidget = studentWidgets.find(w => w.type === 'Video' && Legacy.getConfig(w)?.vodId)
  return videoWidget ? Legacy.getConfig(videoWidget)?.vodId : undefined
}

export function getSegmentTourId(segment: Segment): string | undefined {
  const studentWidgets = Legacy.flatten(segment.config?.studentContent)
  const tourWidget = studentWidgets.find(w => w.type === 'Tour')
  return tourWidget?.id
}

export function getSegmentName(segment: Pick<Segment, 'prefix' | 'name'>): string {
  const joiner = segment.name.startsWith('-') ? '' : ' '
  return [segment.prefix, segment.name].filter(Boolean).join(joiner)
}
