import { useMediaNodeQuery } from '../queries/useMediaNodeQuery'
import { MediaFragment } from '../schema'

export interface UseMediaViewMediaOpts {
  mediaId: string
  media?: MediaFragment
}
export interface UseMediaViewMedia {
  media?: MediaFragment
  loading?: boolean
}
export function useMediaViewMedia({
  mediaId,
  media: _media,
}: UseMediaViewMediaOpts): UseMediaViewMedia {
  const { data, loading } = useMediaNodeQuery({
    variables: { id: mediaId },
    skip: !!_media,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const media = _media || (data?.node?.__typename === 'Media' ? data.node : undefined)
  return { media, loading }
}
