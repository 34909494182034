import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'
import { Dropdown } from '@thesisedu/ui'
import { Settings } from '@thesisedu/ui/icons'
import React from 'react'

import { WidgetNode } from './WidgetNode'
import { SETTINGS_WEIGHT } from './WidgetSettings'
import { WidgetSettingsModal, WidgetSettingsModalProps } from './WidgetSettingsModal'
import { useSettingsDropdownItem } from '../../../lexical/ui/SettingsDropdown/SettingsDropdownContext'

export function WidgetSettingsModalDropdownItem<
  Config extends BaseWidgetConfig,
  SettingsConfig extends Partial<Config>,
>(props: Omit<WidgetSettingsModalProps<Config>, 'onClose' | 'node'>) {
  const { identifier, title } = props
  const [visibleNode, setVisibleNode] = React.useState<WidgetNode<any, Config> | undefined>(
    undefined,
  )
  useSettingsDropdownItem({
    identifier: `widget-${identifier}-other`,
    weight: SETTINGS_WEIGHT,
    group: identifier,
    filter(editor, node) {
      return node.getType() === identifier
    },
    element: ({ node }) => {
      return (
        <Dropdown.Item onClick={() => setVisibleNode(node)} icon={<Settings />}>
          {title} Settings...
        </Dropdown.Item>
      )
    },
  })

  if (visibleNode) {
    return (
      <WidgetSettingsModal<Config, SettingsConfig>
        {...props}
        onClose={() => setVisibleNode(undefined)}
        node={visibleNode}
      />
    )
  } else {
    return null
  }
}
