import { styled } from '@thesisedu/ui'
import React from 'react'

export function RecordButtonRecordingState() {
  return <RecordIcon />
}

const RecordIcon = styled.div`
  width: 20px;
  height: 20px;
  border: solid 2px currentColor;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    width: 0.5em;
    height: 0.5em;
    border-radius: 2px;
    background: currentColor;
  }
`
