import { Modal, Modal$, ProgressBar } from '@thesisedu/ui'
import React from 'react'

export type BuildFunc = (current: number, total: number) => any
export interface ProgressModalProps extends Omit<Modal$.ModalProps, 'children'> {
  current: number
  total: number
  buildLeftLabel?: BuildFunc
  buildRightLabel?: BuildFunc
  indeterminate?: boolean
}
export const ProgressModal: React.FC<React.PropsWithChildren<ProgressModalProps>> = ({
  visible,
  current,
  total,
  buildLeftLabel,
  buildRightLabel,
  indeterminate,
  ...modalProps
}) => {
  return (
    <Modal visible={visible} onVisibleChange={() => {}} noClose {...modalProps}>
      <ProgressBar
        maxValue={total}
        value={current}
        style={{ width: '100%' }}
        label={buildLeftLabel?.(current, total)}
        valueLabel={buildRightLabel?.(current, total)}
      />
    </Modal>
  )
}
