import { ClockCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { FontWeight, DATE_FORMATS, Body } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { GradeTreeRecord, isAssignment } from './translateGradeTree'

export interface GradeTreeRecordSummaryProps {
  record: GradeTreeRecord
  /**
   * This is used inside the outline view. If this is true, due dates are forced to
   * the relative dates, since the due date is already visible somewhere else.
   */
  forceRelativeDates?: boolean
}
export function GradeTreeRecordSummary({
  record,
  forceRelativeDates,
}: GradeTreeRecordSummaryProps) {
  if (record.overdue) {
    const message = isAssignment(record, 'overdue')
      ? `Overdue! Due ${moment(record.dueAt).fromNow()}`
      : `${record.overdue} ${record.overdue === 1 ? 'is' : 'are'} overdue.`
    return (
      <Body color={'@red'} weight={FontWeight.Bold} style={{ display: 'inline' }}>
        <ExclamationCircleOutlined /> {message}
      </Body>
    )
  } else if (record.dueToday) {
    const message = isAssignment(record, 'dueToday')
      ? `Due today! Due at ${moment(record.dueAt).format(DATE_FORMATS.TIME)}`
      : `${record.dueToday} ${record.dueToday === 1 ? 'is' : 'are'} due today.`
    return (
      <Body color={'@orange'} weight={FontWeight.Bold} style={{ display: 'inline' }}>
        <ExclamationCircleOutlined /> {message}
      </Body>
    )
  } else if (record.todo) {
    const message = isAssignment(record, 'todo')
      ? `Not completed. ${
          record.dueAt
            ? `Due ${
                forceRelativeDates
                  ? moment(record.dueAt).fromNow()
                  : moment(record.dueAt).format(DATE_FORMATS.FULL)
              }`
            : ''
        }`
      : `${record.todo} ${record.todo === 1 ? 'is' : 'are'} not completed.`
    return (
      <Body color={'@orange'} weight={FontWeight.Bold} style={{ display: 'inline' }}>
        <ClockCircleOutlined /> {message}
      </Body>
    )
  } else if (record.notGraded) {
    const message = isAssignment(record, 'notGraded')
      ? 'Submitted, but not graded.'
      : `${record.notGraded} ${record.notGraded === 1 ? 'is' : 'are'} submitted, but not graded.`
    return (
      <Body color={'@blue'} weight={FontWeight.Bold} style={{ display: 'inline' }}>
        <ClockCircleOutlined /> {message}
      </Body>
    )
  } else if (record.late) {
    const message = isAssignment(record, 'late')
      ? 'Submitted late.'
      : `${record.late} ${record.late === 1 ? 'was' : 'were'} submitted late.`
    return (
      <Body color={'@orange'} weight={FontWeight.Bold} style={{ display: 'inline' }}>
        <ExclamationCircleOutlined /> {message}
      </Body>
    )
  } else {
    return null
  }
}
