import { useFeatureRoot } from '@thesisedu/feature-react'
import { Text, styled, s } from '@thesisedu/ui'
import React from 'react'

export function PreAuthenticateScreen() {
  const appName = useFeatureRoot().appOptions.name
  const signInSteps = require('../../assets/sign-in-steps-small.gif').default
  return (
    <div style={{ textAlign: 'center' }}>
      <Text bottom>
        <Num>1.</Num> Click on <strong>Sign in with Google</strong> below.
      </Text>
      <Text bottom>
        <Num>2.</Num> In the window that appears,{' '}
        <strong>
          check all of the boxes on the page,{' '}
          <Text level={null} color={'orange'} inline>
            highlighted below.
          </Text>
        </strong>{' '}
      </Text>
      <Image src={signInSteps} alt={'The Google authentication screen with checkboxes.'} />
      <Text top bottom>
        <Num>3.</Num> Click on <strong>Continue</strong> at the bottom of the page.
      </Text>
      <Text level={'s'} style={{ textAlign: 'center' }} top bottom>
        {appName}'s use and transfer to any other app of information received from Google APIs will
        adhere to{' '}
        <a
          href={'https://developers.google.com/terms/api-services-user-data-policy'}
          target={'_blank'}
        >
          Google API Services User Data Policy,
        </a>{' '}
        including the Limited Use requirements.
      </Text>
    </div>
  )
}

const Num = styled.div`
  background: ${s.color('element')};
  padding: ${s.size('xxs')} ${s.size('xs')};
  border-radius: ${s.var('radii.1')};
  display: inline-block;
  margin-right: ${s.size('xs')};
`
const Image = styled.img`
  margin: 0 auto ${s.size('s')} auto;
  max-height: 200px;
  background: ${s.color('element')};
  border-radius: ${s.var('radii.2')};
  border: solid 3px ${s.color('element')};
  overflow: hidden;
`
