import { ClassStudentPair, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Form, HSpaced, Text, VSpaced } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { GroupsEditor } from './GroupsEditor'
import { IndividualAssignment } from './IndividualAssignment'
import { WorkStarted } from './WorkStarted'
import { GroupsAssignment } from './types'
import { useAssignmentGroupsFormDirtyState } from './useAssignmentGroupsFormDirtyState'
import { AssignmentSubmissionTypeSelect } from '../edit/AssignmentSubmissionTypeSelect'
import { useUpdateAssignmentMutation } from '../queries/useUpdateAssignmentMutation'
import { SubmissionType } from '../schema'

export type FormValues = Pick<GroupsAssignment, 'submissionType' | 'groups'>
export interface AssignmentGroupsProps {
  assignment: GroupsAssignment
  assignedStudents: ClassStudentPair[]
  hasSubmissions?: boolean
}
export function AssignmentGroups({
  assignment,
  assignedStudents,
  hasSubmissions,
}: AssignmentGroupsProps) {
  const [updateAssignment] = useUpdateAssignmentMutation()
  const form = Form.useForm<FormValues>()
  const { onSaved } = useAssignmentGroupsFormDirtyState(form)
  const classId = useSelectedClassId(true)
  React.useEffect(() => {
    form.reset({
      groups: assignment.groups,
      submissionType: assignment.submissionType,
    })
  }, [assignment])
  let content
  const isGroupsAssignment = assignment.submissionType !== SubmissionType.Individual
  const onAssignmentChange = async (changes: Partial<GroupsAssignment>) => {
    await updateAssignment({
      variables: {
        input: {
          id: assignment.id,
          patch: changes,
        },
      },
    })
    onSaved()
  }
  if (isGroupsAssignment) {
    content = (
      <Form.Field name={'groups'} required aria-label={'Groups Editor'}>
        <GroupsEditor students={assignedStudents} classId={classId} />
      </Form.Field>
    )
  } else {
    content = <IndividualAssignment onAssignmentChange={onAssignmentChange} />
  }

  return (
    <Form
      form={form}
      onFinish={async values => {
        await onAssignmentChange(values)
      }}
    >
      <HSpaced justify={'space-between'} bottom={'m'} align={'flex-end'}>
        <Text level={'h2'}>Manage Groups</Text>
        {isGroupsAssignment ? (
          <HSpaced space={'xs'}>
            <Form.Field name={'submissionType'} aria-label={'Submission Type'} required>
              <AssignmentSubmissionTypeSelect separateIndividual />
            </Form.Field>
            <Form.Submit children={'Save Changes'} disableUntilDirty icon={<Check />} />
          </HSpaced>
        ) : null}
      </HSpaced>
      <VSpaced space={'m'}>
        {content}
        {hasSubmissions ? <WorkStarted /> : null}
      </VSpaced>
    </Form>
  )
}
