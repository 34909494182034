import { Select, Select$ } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { AddCategoryModal } from './class/AddCategoryButton'
import { useClassAssignmentCategoriesQuery } from './schema'

const ADD_VALUE = 'add'
export interface AssignmentCategorySelectProps
  extends Omit<Select$.SelectProps, 'onValueChange' | 'children'> {
  classId: string
  possibleValues?: string[]
  onChange?: (newValue: string, oldValue?: string) => void
  hideCategoryIds?: string[]
}
function _AssignmentCategorySelect(
  {
    classId,
    possibleValues,
    value: _value,
    onChange,
    hideCategoryIds,
    ...props
  }: AssignmentCategorySelectProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  const { data, loading } = useClassAssignmentCategoriesQuery({ variables: { classId } })
  const [addVisible, setAddVisible] = React.useState(false)
  let categories =
    data?.node?.__typename === 'Class'
      ? data.node.assignmentCategories.edges.map(edge => edge.node) || []
      : [] || []
  if (hideCategoryIds?.length) {
    categories = categories.filter(cat => !hideCategoryIds.includes(cat.id))
  }
  const canAddCategory =
    data?.node?.__typename === 'Class' ? data.node.canManageAssignmentCategories : false
  const value = possibleValues
    ? categories.find(cat => possibleValues.includes(cat.id))?.id
    : _value
  return (
    <>
      {canAddCategory ? (
        <AddCategoryModal
          onCancel={() => setAddVisible(false)}
          classId={classId}
          visible={addVisible}
          totalPoints={categories.reduce<number>((sum, cat) => sum + cat.gradeWeight * 100, 0)}
          onCreated={category => {
            if (onChange) {
              onChange(category.id, value || undefined)
            }
          }}
        />
      ) : null}
      <Select
        {...props}
        ref={ref}
        data-testid={'AssignmentCategorySelect'}
        aria-label={'Select a Grading Category'}
        value={value}
        disabled={loading || props.disabled}
        placeholder={loading ? 'Loading categories...' : props.placeholder}
        onValueChange={newValue => {
          if (newValue === ADD_VALUE) {
            setAddVisible(true)
          } else if (onChange && newValue) {
            onChange(newValue, value || undefined)
          }
        }}
      >
        {categories.map(cat => (
          <Select.Item children={cat.name} value={cat.id} key={cat.id} />
        ))}
        {canAddCategory ? (
          <>
            <Select.Separator />
            <Select.Item value={ADD_VALUE} icon={<Plus />}>
              Add Category...
            </Select.Item>
          </>
        ) : null}
      </Select>
    </>
  )
}
export const AssignmentCategorySelect = React.forwardRef(_AssignmentCategorySelect)
