import { styled, Button, Tooltip, getColor, getSize } from '@thesisedu/react'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { CommentComposersOptions, useCommentComposers } from './useCommentComposers'

const StyledButton = styled(Button)`
  color: ${props => getColor(props.theme, '@text-color')};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    font-size: ${props => getSize(props.theme, '@size-s')} !important;
  }
`

export interface CustomComposeButtonsProps extends CommentComposersOptions {
  buttonProps?: Partial<ButtonProps>
}
export function CustomComposeButtons({ buttonProps, ...opts }: CustomComposeButtonsProps) {
  const { elements, onOptionSelected, options } = useCommentComposers(opts)
  if (options.length) {
    return (
      <>
        {elements}
        {options.map(option => {
          return (
            <Tooltip key={option.key} title={option.name}>
              <StyledButton
                type={'link'}
                shape={'circle'}
                size={'small'}
                icon={option.icon}
                {...buttonProps}
                onClick={() => onOptionSelected(option.key)}
              />
            </Tooltip>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
