import { ApolloClient, DocumentNode, QueryOptions } from '@apollo/client'

export interface LoadAllResultsVariables {
  after?: string | null
}
interface InnerQueryResult<ResultFragment> {
  totalCount: number
  pageInfo: {
    hasNextPage?: boolean | null | undefined
    [key: string]: any
  }
  edges: {
    cursor: string
    node: ResultFragment
  }[]
}
export interface LoadAllResultsProgress<ResultFragment> {
  items: ResultFragment[]
  totalCount: number
}
export interface LoadAllResultsOpts<
  ResultFragment,
  Variables extends LoadAllResultsVariables,
  QueryResult,
> extends Pick<QueryOptions, 'fetchPolicy' | 'context'> {
  client: ApolloClient<any>
  document: DocumentNode
  variables?: Omit<Variables, 'after'>
  getResult: (result: QueryResult) => InnerQueryResult<ResultFragment>
  progress?: (opts: LoadAllResultsProgress<ResultFragment>) => void
}
export interface LoadAllResultsResult<ResultFragment> extends Promise<ResultFragment[]> {
  cancel: () => void
}
export function loadAllResults<
  ResultFragment,
  Variables extends LoadAllResultsVariables,
  QueryResult,
>({
  client,
  document,
  variables,
  getResult,
  progress,
  ...otherOpts
}: LoadAllResultsOpts<
  ResultFragment,
  Variables,
  QueryResult
>): LoadAllResultsResult<ResultFragment> {
  let canceled = false
  async function start() {
    const items: ResultFragment[] = []
    let hasNextPage = true
    let after: string | null = null
    while (hasNextPage && !canceled) {
      const queryResult = await client.query<QueryResult>({
        ...otherOpts,
        query: document,
        variables: {
          ...variables,
          after,
        },
      })
      const result = getResult(queryResult.data)
      hasNextPage = !!result.pageInfo.hasNextPage
      after = result.edges[result.edges.length - 1].cursor
      items.push(...result.edges.map(edge => edge.node))
      progress?.({
        items,
        totalCount: result.totalCount,
      })
      // Wait a bit in between page loads to prevent stress on the server...
      await new Promise(resolve => setTimeout(resolve, 250))
    }

    return items
  }

  const promise = start() as Promise<ResultFragment[]> & { cancel: () => void }
  promise.cancel = () => {
    canceled = true
  }

  return promise
}
