import React from 'react'
import { createPortal } from 'react-dom'

import { useRecordFooterContext } from '../../record/states/record/footer/RecordFooterContext'

export interface FooterItemProps {
  weight: number
  showBeforeRecording?: boolean
  children: React.ReactNode
}
export function FooterItem({ weight, showBeforeRecording, children }: FooterItemProps) {
  const id = React.useId()
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  const { addItem, removeItem } = useRecordFooterContext(false) ?? {}
  React.useEffect(() => {
    if (addItem && removeItem) {
      addItem({
        identifier: id,
        weight,
        showBeforeRecording,
        children: <div ref={c => setContainer(c)} />,
      })
      return () => removeItem(id)
    }
  }, [weight, id, showBeforeRecording])

  return container ? createPortal(children, container) : null
}
