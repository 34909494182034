import { ArrowRightOutlined, CheckCircleFilled } from '@ant-design/icons'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { styled, Body, media, Block, DATE_FORMATS, BodySmall } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { useCompletedTasksContext } from './contexts/CompletedTasksContext'
import { UserTaskListDisplay, useUserTaskListContext } from './contexts/UserTaskListContext'
import { warn } from './log'
import { BasicUserTaskFragment } from './schema'
import { ForwardedUserTaskProps, UserTaskResource } from './types'

const SmallUserTaskContainer = styled.div`
  padding: ${props => props.theme['@size-xs']} 0;
  color: ${props => props.theme['@text-color']};
  display: flex;
  align-items: center;
  &:hover {
    color: ${props => props.theme['@primary-color']};
  }
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
`
const Center = styled(Body)`
  margin: 0 auto 0 ${props => props.theme['@size-xs']};
  padding-right: ${props => props.theme['@size-xs']};
  min-width: 0;
  flex-grow: 1;
`

export interface UserTaskProps extends ForwardedUserTaskProps {
  href?: string
  icon: React.ReactElement
  completeOnClick?: boolean
}
export function SmallUserTask({
  href,
  icon,
  children,
  onClick,
}: React.PropsWithChildren<UserTaskProps>) {
  const content = (
    <SmallUserTaskContainer onClick={onClick}>
      {icon}
      <Center>{children}</Center>
      <ArrowRightOutlined />
    </SmallUserTaskContainer>
  )
  if (href) {
    return <Link to={href}>{content}</Link>
  } else return content
}

const LargeTaskContainer = styled.div`
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
  color: ${props => props.theme['@text-color']};
  transition:
    color 0.1s linear,
    background 0.1s linear;
  padding: 15px;
  margin: 5px 0;
  font-size: 18px;
  display: flex;
  align-items: center;
  line-height: 1;
  width: 100%;
  &:hover {
    background: ${props => props.theme['@gray-2']};
  }
  > span[role='img']:first-child {
    width: 24px;
    font-size: 24px;
    margin-right: 10px;
  }
  &.complete {
    opacity: 0.75;
    > span[role='img']:first-child {
      color: ${props => props.theme['@green']};
      width: 24px;
      font-size: 24px;
      margin-right: 10px;
    }
    .due {
      display: none;
    }
  }
  > .arrow {
    margin-left: auto;
    font-size: 14px;
  }
  > .content {
    margin-right: 15px;
  }
  ${media.md} {
    width: auto;
  }
`
export function LargeUserTask({
  icon,
  children,
  task,
  onClick,
}: React.PropsWithChildren<UserTaskProps>) {
  const completedAt = (task as BasicUserTaskFragment).completedAt
  return (
    <LargeTaskContainer className={completedAt ? 'complete' : ''} onClick={onClick}>
      {completedAt ? <CheckCircleFilled className={'complete'} /> : icon}
      <div className={'content'}>
        {completedAt ? (
          <>
            <Block marginBottom={'@size-xxs'}>
              <Body>{children}</Body>
            </Block>
            <BodySmall color={'@text-color-secondary'}>
              Completed {moment(completedAt).format(DATE_FORMATS.FULL)}
            </BodySmall>
          </>
        ) : (
          <span>{children}</span>
        )}
      </div>
      <ArrowRightOutlined className={'arrow'} />
    </LargeTaskContainer>
  )
}

export function UserTaskListItem(props: ForwardedUserTaskProps) {
  const root = useFeatureRoot()!
  const taskResources = root.deps.resources.getResourcesForType<UserTaskResource>('UserTask')
  const resource = taskResources.find(r => r.identifier === props.task.__typename)
  if (resource) {
    return resource.render(props)
  } else {
    warn('invalid user task type:', props.task.__typename)
    return null
  }
}

export function UserTask({
  task,
  onClick: _onClick,
  ...props
}: React.PropsWithChildren<UserTaskProps>) {
  const { display } = useUserTaskListContext(false) || {}
  const { completeTask } = useCompletedTasksContext(true)
  const onClick = props.completeOnClick
    ? () => {
        completeTask(task as BasicUserTaskFragment)
        _onClick()
      }
    : _onClick
  if (display === UserTaskListDisplay.Large) {
    return <LargeUserTask {...props} task={task} onClick={onClick} />
  } else if (display === UserTaskListDisplay.Small) {
    return <SmallUserTask {...props} task={task} onClick={onClick} />
  } else {
    return null
  }
}
