import { Icon as LegacyIcon } from '@ant-design/compatible'
import { InfoCircleFilled, ClockCircleFilled } from '@ant-design/icons'
import { useTeacherOrStudentSelectedClass } from '@thesisedu/feature-classes-react'
import { SolidIcon } from '@thesisedu/react'
import { Check } from '@thesisedu/react/icons'
import { styled, StyledThemeContext } from '@thesisedu/web'
import { Progress, Tooltip } from 'antd'
import pluralize from 'pluralize'
import React, { useContext } from 'react'

import { SegmentDecorationsContext } from '../contexts/SegmentDecorationsContext'
import { getIconForSegment } from '../helpers'
import { useSegmentProgress } from '../segment/useSegmentProgress'
import { Segment, SegmentProgress } from '../types'

const Container = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: -20%;
    right: -10%;
    width: 50%;
    height: 50%;
    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export interface SegmentDynamicIconProps {
  segment: Segment
  size?: number
}
export function SegmentDynamicIcon({ segment, size = 14 }: SegmentDynamicIconProps) {
  const decorationContext = useContext(SegmentDecorationsContext)
  const { cls: classContext } = useTeacherOrStudentSelectedClass(false)
  const theme = useContext(StyledThemeContext)
  const decoration = decorationContext?.segments[segment.id] || null
  const totalStudents = classContext?.students?.totalCount || 0
  let iconType = getIconForSegment(segment)
  const iconStyle: any = {
    fontSize: size,
    color: theme['@text-color-secondary'],
  }
  let iconTheme: any
  const progress = useSegmentProgress(segment)
  if (progress === SegmentProgress.COMPLETE) {
    iconType = 'check-circle'
    iconStyle.color = theme['@green']
    iconTheme = 'filled'
  }

  if (decoration?.startedCount && totalStudents && progress !== SegmentProgress.COMPLETE) {
    const percentage = Math.min(1, decoration.startedCount / totalStudents) * 100
    return (
      <Tooltip
        title={`${pluralize('student', decoration.startedCount, true)} started this content.`}
      >
        {Math.round(percentage) >= 100 ? (
          <SolidIcon style={{ fontSize: size }} color={'@green'} children={<Check />} />
        ) : (
          <Progress
            type={'circle'}
            percent={Math.min(1, decoration.startedCount / totalStudents) * 100}
            width={size}
            showInfo={false}
            strokeColor={theme[decoration.startedCount >= totalStudents ? '@green' : '@blue']}
            strokeWidth={16 * (16 / size)}
          />
        )}
      </Tooltip>
    )
  } else {
    let otherIcon: React.ReactElement | null = null
    let otherTooltip: string | null = null
    if (progress === SegmentProgress.IN_PROGRESS) {
      otherIcon = <InfoCircleFilled style={{ color: theme['@blue'] }} className={'icon'} />
      otherTooltip = 'In Progress'
    } else if (progress === SegmentProgress.OVERDUE) {
      otherIcon = <ClockCircleFilled style={{ color: theme['@red'] }} className={'icon'} />
      otherTooltip = 'Overdue'
    }
    const content = (
      <Container>
        {/* @ts-ignore react 18 support */}
        <LegacyIcon type={iconType} theme={iconTheme} style={iconStyle} />
        {otherIcon}
      </Container>
    )
    if (otherTooltip) {
      return (
        <Tooltip title={otherTooltip} mouseEnterDelay={1}>
          {content}
        </Tooltip>
      )
    } else {
      return content
    }
  }
}
