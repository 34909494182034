import * as React from 'react'

function SvgNoteE(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#F2EA02'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={1.732}
          d={
            'M78 60.346L177 53l-5.563 45.37-44.998 1.966-.181 8.613 32.629-2.353-3.648 31.376-29.964 2.354-.363 9.855 51.312-4.837-.906 49.483L79.371 200z'
          }
        />
        <path
          fill={'#F1E902'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteE = React.memo(SvgNoteE)
export default MemoSvgNoteE
