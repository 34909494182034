import { Format } from '@thesisedu/feature-utils'
import { styled, s, Block, Text, Image, LoadingIndicator } from '@thesisedu/ui'
import { MinusCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { DEFAULT_ALIGNMENT, LinkEmbedAlignment } from './common'
import { usePageMetadata } from './usePageMetadata'
import { SimpleWidget } from '../utils'

export interface LinkEmbedWithUrlProps {
  url: string
  alignment?: LinkEmbedAlignment
}
export function LinkEmbedWithUrl({ url, alignment = DEFAULT_ALIGNMENT }: LinkEmbedWithUrlProps) {
  const { loading, metadata } = usePageMetadata(url)

  return (
    <CardContainer
      as={'a'}
      href={url}
      target={'_blank'}
      rel={'noopener noreferrer'}
      $alignment={alignment}
    >
      {loading ? (
        <SimpleWidget.StatusContainer>
          <LoadingIndicator />
        </SimpleWidget.StatusContainer>
      ) : metadata ? (
        <>
          {metadata.image ? (
            <Image src={metadata.image} alt={`Image for ${metadata.title}`} />
          ) : null}
          <MetaContainer>
            <Text level={'l'} weight={'bold'}>
              {metadata.title}
            </Text>
            {metadata.author || metadata.date ? (
              <Text level={'s'} color={'secondary'}>
                {[
                  metadata.date ? `Posted ${Format.date(metadata.date, 'dateTime')}` : '',
                  metadata.author,
                ]
                  .filter(Boolean)
                  .join(' - ')}
              </Text>
            ) : null}
            {metadata.description ? (
              <Block top={'xs'}>
                <Text style={{ marginBottom: 0 }}>{metadata.description}</Text>
              </Block>
            ) : null}
            <div className={'meta-footer'}>
              <Text level={'s'} color={'secondary'} className={'meta-link'}>
                {url}
              </Text>
              {metadata.publisher ? (
                <>
                  <div className={'publisher-spacer'} />
                  {metadata.logo ? (
                    <img
                      src={metadata.logo}
                      alt={metadata.publisher}
                      className={'publisher-logo'}
                    />
                  ) : (
                    <Text level={'s'} color={'secondary'}>
                      {metadata.publisher}
                    </Text>
                  )}
                </>
              ) : null}
            </div>
          </MetaContainer>
        </>
      ) : (
        <SimpleWidget.StatusContainer>
          <MinusCircle />
        </SimpleWidget.StatusContainer>
      )}
    </CardContainer>
  )
}

const CardContainer = styled(SimpleWidget.HoverWidgetContainer)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 450px;
`
const MetaContainer = styled.div`
  display: flex;
  min-height: 100px;
  flex-direction: column;
  align-items: stretch;
  padding: ${s.size('0.9')} ${s.size('1')};
  .meta-footer {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    padding-top: ${s.size('xs')};
    > :not(:last-child) {
      margin-right: ${s.size('xs')};
    }
    .meta-link {
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .publisher-logo {
      height: ${s.size('m')};
    }
    .publisher-spacer {
      margin-left: auto;
    }
  }
`
