import { MutateDefaultSegmentThumbnail } from '@thesisedu/feature-courses-react'
import { FeatureWeb } from '@thesisedu/feature-web'

export default function addHook(root: FeatureWeb) {
  root.deps.hookManager.registerMutateHook<MutateDefaultSegmentThumbnail>(
    'feature-courses-react:default-segment-thumbnail',
    (existing, { segment }) => {
      // TODO: Implement custom thumbnail.
      return existing
    },
  )
}
