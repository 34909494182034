import { MutationHookOptions, QueryResult } from '@apollo/client'

import {
  BuiltSegmentQuery,
  BuiltSegmentQueryVariables,
  FetchSegmentQuery,
  FetchSegmentQueryVariables,
  useFetchSegmentQuery as ogUseFetchSegmentQuery,
  useBuiltSegmentQuery as ogUseBuiltSegmentQuery,
} from '../schema'

export const useFetchSegmentQuery = (
  segmentId: string | 'create',
  opts: MutationHookOptions<FetchSegmentQuery, FetchSegmentQueryVariables> = {},
): QueryResult<FetchSegmentQuery, FetchSegmentQueryVariables> => {
  return ogUseFetchSegmentQuery({
    variables: { id: segmentId || '' },
    skip: !segmentId || segmentId === 'create',
    ...opts,
  })
}

export const useFetchBuiltSegmentQuery = (
  segmentId?: string | 'create',
  opts: MutationHookOptions<BuiltSegmentQuery, BuiltSegmentQueryVariables> = {},
): QueryResult<BuiltSegmentQuery, BuiltSegmentQueryVariables> => {
  return ogUseBuiltSegmentQuery({
    variables: { id: segmentId || '' },
    skip: !segmentId || segmentId === 'create',
    ...opts,
  })
}
