import { AuthProviderHandlerResource, PreAuthenticateScreen } from '@thesisedu/feature-users-react'
import React from 'react'

import {
  AuthenticateWithProviderContext,
  getAuthenticateWithProvider,
} from './authenticateWithProvider'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, getStepFactory } from '../types'

export default {
  centerVertically: true,
  size: {
    width: StepSizes.loading.width,
    height: 800,
  },
  Component({ transition, context }) {
    if (!context.provider.authProviderId) return null
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        state={'connect'}
        title={`Connect to ${context.provider.name}`}
        message={'Before you connect your account, review the steps below.'}
        action={
          <PreAuthenticateScreen
            providerId={context.provider.authProviderId}
            onContinue={() => {
              transition(getAuthenticateWithProvider(context))
            }}
          />
        }
      />
    )
  },
  onTransitionTo({ feature, context }) {
    if (context.provider.authProviderId) {
      const authResource = feature.resources.getResource<AuthProviderHandlerResource>(
        'AuthProviderHandler',
        context.provider.authProviderId,
      )
      if (!authResource?.preAuthenticateScreen) {
        return getAuthenticateWithProvider(context)
      }
    }

    return null
  },
} satisfies SetupStep<AuthenticateWithProviderContext>
export const getPreAuthenticateWithProvider = getStepFactory<AuthenticateWithProviderContext>(
  'preAuthenticateWithProvider',
)
