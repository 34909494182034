import { Feature, FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import ApolloReactFeature, { useModifiedFragment, addField } from '@thesisedu/feature-apollo-react'
import { HOOKS } from '@thesisedu/feature-users-web'
import { Viewer } from '@thesisedu/feature-users-web/dist/types'
import moment from 'moment'

import { debug, warn } from './log'

export interface IntercomWebOptions {
  appId?: string
}
type IntercomViewer = Viewer & {
  intercomHash: string
}

export class IntercomWebFeature extends Feature {
  public static package: string = '@thesisedu/feature-intercom-web'
  static path = __dirname
  static requires: string[] = []
  public readonly options!: IntercomWebOptions

  constructor(options: IntercomWebOptions, deps: FeatureDependencies) {
    super(options, deps)
    if (typeof window !== 'undefined') {
      this.settings.app_id = this.options.appId
      this.bootIntercom()
    }
    require('./hooks/replay').default(this)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'User', fragment => {
      return addField(fragment, 'intercomHash')
    })
    this.hookManager.registerHook<IntercomViewer | undefined>(
      HOOKS.UserUpdated,
      async (feature, viewer) => {
        try {
          const apolloFeature = this.root.getFeature<ApolloReactFeature>(ApolloReactFeature.package)
          const isImpersonating = !!apolloFeature?.isUsingSessionAuth()
          const shouldUseIntercom = !isImpersonating && viewer?.group !== 'STUDENT'
          debug('updating viewer with intercom:', viewer, isImpersonating)
          debug('..shouldUseIntercom %O', shouldUseIntercom)
          this.intercom('shutdown')
          if (viewer && shouldUseIntercom) {
            debug('viewer found. registering identified user.')
            this.settings.email = viewer.email || undefined
            this.settings.user_id = viewer.id || undefined
            this.settings.user_hash = viewer.intercomHash || undefined
            this.settings.name = viewer.name || viewer.username
            this.settings.signed_up_at = moment(viewer.createdAt).unix() || undefined
          } else {
            debug('viewer is gone. logging out')
            this.settings.email = undefined
            this.settings.user_id = undefined
            this.settings.user_hash = undefined
            this.settings.name = undefined
            this.settings.signed_up_at = undefined
          }

          if (shouldUseIntercom) {
            this.intercom('boot', this.settings)
          } else {
            debug('impersonating; not booting intercom')
          }
        } catch (err: any) {
          warn('error updating intercom user')
          warn(err)
        }
      },
    )
  }

  bootIntercom() {
    if (typeof window !== 'undefined' && this.options.appId) {
      if (!(window as any).Intercom) {
        ;(function (w: any, d: any, id: any, s?: any, x?: any) {
          debug('booting intercom')
          function i() {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments)
          }
          ;(i as any).q = []
          i.c = function (args: any) {
            ;(i as any).q.push(args)
          }
          ;(w as any).Intercom = i
          s = d.createElement('script')
          s.async = 1
          s.src = 'https://widget.intercom.io/widget/' + id
          d.head.appendChild(s)
        })(window, document, this.options.appId)
      }
      if ((window as any).Intercom) {
        ;(window as any).Intercom('boot', this.settings)
      }
    }
  }

  get intercom() {
    if (typeof window !== 'undefined') {
      return (window as any).Intercom
    } else {
      throw new Error('running in environment without window')
    }
  }

  get settings() {
    if (typeof window === 'undefined') {
      throw new Error('running in environment without window')
    } else {
      if (!(window as any).intercomSettings) {
        ;(window as any).intercomSettings = {}
      }
      return (window as any).intercomSettings
    }
  }
}

export default IntercomWebFeature
export const intercomWebFeature = (options: IntercomWebOptions = {}): FeatureUse => [
  IntercomWebFeature,
  options,
]
