import { SearchResultGridItem } from '@thesisedu/feature-search-react'
import { BaseGridItem, IconContainer, ImagePlaceholder } from '@thesisedu/react/dist/grid'
import { styled, s, LoadingIndicator } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

export const CREATE_SUBMISSION_GRID_ITEM: TCreateSubmissionGridItem = { id: 'create' }
export function isCreateSubmissionGridItem(item: BaseGridItem): item is TCreateSubmissionGridItem {
  return item.id === 'create'
}
export interface TCreateSubmissionGridItem extends BaseGridItem {
  id: 'create'
}
export interface CreateSubmissionGridItemProps {
  remainingAttempts: number
  loading?: boolean
}
export function CreateSubmissionGridItem({
  remainingAttempts,
  loading,
}: CreateSubmissionGridItemProps) {
  return (
    <DisabledContainer $disabled={remainingAttempts <= 0 || loading}>
      <SearchResultGridItem
        title={'Create Attempt'}
        description={`${remainingAttempts} remaining`}
        image={
          <ImagePlaceholder>
            <IconContainer
              background={'@primary-color'}
              children={loading ? <LoadingIndicator /> : <Plus />}
            />
          </ImagePlaceholder>
        }
      />
    </DisabledContainer>
  )
}

const DisabledContainer = styled.div<{ $disabled?: boolean }>`
  transition: opacity 0.1s linear;
  ${s.ifs(props => props.$disabled)} {
    opacity: 0.5;
    pointer-events: none;
    filter: saturate(-10%);
  }
`
