import React from 'react'

import { s } from '../../'
import { HTMLProps } from '../../sharedTypes'
import { AnySize } from '../../style/variables'
import { BlockSize, getSpace as getBlockSpace } from '../Block'

const AsOverrideMap: Partial<Record<keyof s.FontSizeAliases, keyof JSX.IntrinsicElements>> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
}
const WeightOverrideMap: Partial<Record<keyof s.FontSizeAliases, TextProps['weight']>> = {
  h1: 'bold',
  h2: 'bold',
  h3: 'bold',
  h4: 'bold',
}
interface FontSpace {
  top: AnySize
  bottom: AnySize
}
const SizeOverride: Record<keyof s.FontSizeAliases, FontSpace> = {
  h1: { top: '6', bottom: '1' },
  h2: { top: '5', bottom: '1' },
  h3: { top: '4', bottom: '0.75' },
  h4: { top: '3', bottom: '0.75' },
  xs: { top: '0.1', bottom: '0.1' },
  s: { top: '0.25', bottom: '0.25' },
  m: { top: '0.75', bottom: '0.75' },
  l: { top: '1', bottom: '1' },
  xl: { top: '2', bottom: '2' },
}

function getSpace(
  space: AnySize | boolean | undefined,
  level: keyof s.FontSizeAliases,
  side: keyof FontSpace,
): string | undefined {
  if (space === true) {
    return s.size(SizeOverride[level][side])
  } else if (space) {
    return s.size(space)
  } else {
    return undefined
  }
}

export interface TextProps<Element extends HTMLElement = HTMLElement> extends HTMLProps<Element> {
  /**
   * The level of text to render. The default value is "m" for medium. Set to null
   * to inherit the font size.
   */
  level?: keyof s.FontSizeAliases | null
  /**
   * The font family to use for the text.
   */
  font?: keyof s.Fonts
  /**
   * The HTML element to render this text as. The default value of this depends
   * on the selected level.
   */
  as?: keyof JSX.IntrinsicElements
  /**
   * The color of the text. Defaults to unset, which means it inherits the color
   * from the parent.
   */
  color?: s.ColorAlias
  /**
   * The font weight of the text.
   */
  weight?: keyof s.FontWeights
  /**
   * Whether or not to truncate the text. If this is true, line wrapping will be
   * disabled and the text will be truncated with an ellipsis. If this is a number,
   * it will be used as the max number of lines to display before truncating.
   */
  truncate?: boolean | number
  /**
   * The top margin of the text. If true, the default value depends on the selected
   * level. If false, no margin is added.
   */
  top?: AnySize | boolean
  /**
   * The bottom margin of the text. If true, the default value depends on the selected
   * level. If false, no margin is added.
   */
  bottom?: AnySize | boolean
  /** The left margin of the text. */
  left?: BlockSize
  /** The right margin of the text. */
  right?: BlockSize
  /**
   * If this is true, the text will be rendered inline instead of as a block.
   */
  inline?: boolean
}
function _Text<Element extends HTMLElement = HTMLElement>(
  {
    level = 'm',
    inline,
    as = level ? AsOverrideMap[level] || (inline ? 'span' : 'p') : inline ? 'span' : 'p',
    color,
    weight = level ? WeightOverrideMap[level] : undefined,
    truncate,
    top,
    bottom,
    left,
    right,
    font,
    ...rest
  }: TextProps<Element>,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const truncateStyles: React.CSSProperties = {}
  if (truncate) {
    truncateStyles.overflow = 'hidden'
    truncateStyles.textOverflow = 'ellipsis'
    if (truncate !== true) {
      truncateStyles.display = '-webkit-box'
      truncateStyles.WebkitLineClamp = truncate
      truncateStyles.WebkitBoxOrient = 'vertical'
    } else {
      truncateStyles.whiteSpace = 'nowrap'
    }
  }
  return React.createElement(as, {
    ref,
    ...rest,
    style: {
      display: inline ? 'inline-block' : 'block',
      marginTop: level ? getSpace(top, level, 'top') || 0 : 0,
      marginBottom: level ? getSpace(bottom, level, 'bottom') || 0 : 0,
      marginLeft: left != null ? getBlockSpace(left) : undefined,
      marginRight: right != null ? getBlockSpace(right) : undefined,
      fontSize: level ? s.size(`font.${level}`) : 'inherit',
      fontWeight: weight ? s.var(`fontWeight.${weight}`) : 'inherit',
      fontFamily: font ? s.var(`font.${font}`) : undefined,
      color: color ? s.color(color) : undefined,
      ...truncateStyles,
      ...rest.style,
    },
  }) as React.ReactElement
}
export const Text = React.forwardRef(_Text) as <Element extends HTMLElement = HTMLElement>(
  props: TextProps<Element> & { ref?: React.ForwardedRef<Element> },
) => ReturnType<typeof _Text>
