import { useMutateHook } from '@thesisedu/feature-react'
import { Shell } from '@thesisedu/feature-web'
import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ContentArea } from './ContentArea'
import { CoursePreview } from './CoursePreview'
import { ExploreHome } from './ExploreHome'
import { Search } from './Search'
import { useLookingForResources } from '../../../explore/useLookingForResources'
import { ExploreRoutesHook } from '../../../types'

export function Explore() {
  useBreadcrumb({ title: 'Explore' })
  Shell.useShellProps('explore', { hideBreadcrumb: true }, [])
  const lookingForResources = useLookingForResources()
  const routes = useMutateHook<ExploreRoutesHook>('courses-react:explore-routes', [], undefined)
  return (
    <Routes>
      <Route path={'/courses/:courseId/*'} element={<CoursePreview />} />
      <Route path={'/content-area/:tagId'} element={<ContentArea />} />
      <Route path={'/search'} element={<Search />} />
      {routes}
      {lookingForResources.map(resource => {
        return (
          <Route
            path={`/${resource.identifier}/*`}
            key={resource.identifier}
            element={resource.page}
          />
        )
      })}
      <Route path={'/'} element={<ExploreHome />} />
    </Routes>
  )
}
