import { motion } from 'framer-motion'
import React from 'react'

import { useSegmentedControlContext } from './context'
import { styled, s } from '../..'
import { Button, ButtonProps } from '../Button'

export interface ItemProps extends ButtonProps {
  selected?: boolean
}
function _Item(
  { selected: _selected, ...rest }: ItemProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const selected = !!_selected || (rest as any)['data-state'] === 'active'
  const { id } = useSegmentedControlContext()
  return (
    <ItemContainer>
      <__Item {...rest} ref={ref} />
      {selected ? (
        <ActiveIndicator layoutId={id} transition={{ type: 'spring', duration: 0.3 }} />
      ) : null}
    </ItemContainer>
  )
}
export const Item = React.forwardRef(_Item)

const { css } = s
export const SelectedItemStyles = css`
  --text-color: var(--segmented-control-selected-color, var(--text-active-color));
`
const ItemContainer = styled.div`
  position: relative;
  min-width: 80px;
`
const __Item = styled(Button)`
  --accent-color: transparent;
  --accent-hover-color: transparent;
  --accent-active-color: transparent;
  padding: var(--vertical-padding) calc(var(--horizontal-padding) * 1.5);
  flex: 1;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
  &[data-state='active'] {
    ${SelectedItemStyles}
  }
`
const ActiveIndicator = styled(motion.div)`
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 0;
  background: var(--segmented-control-selected-background, ${s.color('subtle')});
  border-radius: ${s.var('radii.1')};
`
