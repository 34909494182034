import { StudentAvatar, useCachedStudentUserCallback } from '@thesisedu/feature-classes-react'
import {
  Block,
  Body,
  FontWeight,
  Space,
  styled,
  useTheme,
  AntIconWrapper,
  Input,
  Empty,
  getSize,
  getColor,
  getBorderRadius,
  isNative,
  BodySmall,
  HSpaced,
  ModalNavigationItem,
  BodyExtraSmall,
  Tooltip,
  Div,
} from '@thesisedu/react'
import { Search as SearchIcon, Group, NavArrowRight, User } from '@thesisedu/react/icons'
import React from 'react'

import { GradeModalSubmissionNavigationItemSubtitle } from './GradeModalGroupsNavigation'
import { getStudentClassTabName } from './helpers'
import { useGradeDisplayFn } from './useGradeDisplay'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { getAssignmentCellMode } from '../helpers'
import { useGradeIncompleteLateAsZero } from '../resources/class_configuration/GradeIncompleteLateAsZero'
import { BasicAssignmentSubmissionFragment, useBasicAssignmentSubmissionQuery } from '../schema'
import { AssignmentCellMode } from '../table/types'

export const ALL_STUDENTS_TAB = 'all-students'
interface StudentWithSubmission {
  studentId: string
  classId: string
  submission?: BasicAssignmentSubmissionFragment
  isForfeited?: boolean
  user?: {
    name?: string | null
    username: string
    id: string
  }
}
export interface GradingModalSubmissionsProps {
  isStandalone?: boolean
  hideSearch?: boolean
  hideAll?: boolean
}
export function GradingModalSubmissions({
  isStandalone,
  hideSearch,
  hideAll,
}: GradingModalSubmissionsProps) {
  const [filter, setFilter] = React.useState<string>('')
  const { assignment, setSelectedTab, selectedTab } = useGradingModalContext(true)
  const getStudent = useCachedStudentUserCallback()
  const filteredStudents = assignment.students.edges
    .map<StudentWithSubmission>(edge => ({
      studentId: edge.node.id,
      classId: edge.classId,
      user: getStudent(edge.node.id, edge.classId)?.user,
      isForfeited: !!edge.forfeitAttempts,
      submission: (assignment.submissions || assignment.currentSubmissions).edges.find(
        submissionEdge => submissionEdge.node.studentId === edge.node.id,
      )?.node,
    }))
    .filter(student => {
      const filterMatches =
        !filter ||
        student.user?.name?.toLowerCase().includes(filter.toLowerCase()) ||
        student.user?.username.toLowerCase().includes(filter.toLowerCase())
      return filterMatches
    })
  const SearchC = isStandalone ? Input : Search
  const content = filteredStudents.length ? (
    filteredStudents.map((student, index) => {
      return (
        <GradingModalSubmissionItemWithSubmission
          key={student.studentId}
          classId={student.classId}
          index={index}
          label={student.user?.name || student.user?.username || 'Anonymous'}
          isStandalone={isStandalone}
          isForfeited={student.isForfeited}
          icon={
            student.user ? (
              <StudentAvatar user={student.user} size={isStandalone && !isNative ? 24 : 32} />
            ) : (
              <User
                width={isStandalone && !isNative ? 24 : 32}
                height={isStandalone && !isNative ? 24 : 32}
              />
            )
          }
          onSelected={() =>
            setSelectedTab(getStudentClassTabName(student.studentId, student.classId))
          }
          selected={selectedTab.startsWith(
            getStudentClassTabName(student.studentId, student.classId),
          )}
          mode={getAssignmentCellMode(assignment, student.submission)}
          submission={student.submission}
          totalPoints={assignment.totalPoints}
          subtitle={
            <GradeModalSubmissionNavigationItemSubtitle
              assignment={assignment}
              studentId={student.studentId}
              submission={student.submission}
            />
          }
        />
      )
    })
  ) : (
    <Empty description={'No assigned students!'} />
  )
  return (
    <>
      {hideSearch ? null : (
        <Block marginBottom={isStandalone ? '@size-m' : undefined}>
          <SearchC
            placeholder={'Find a student...'}
            value={filter}
            onChange={e => setFilter(e.target.value)}
            suffix={<AntIconWrapper children={<SearchIcon />} />}
          />
        </Block>
      )}
      {hideAll ? null : (
        <GradingModalSubmissionItem
          index={0}
          label={'All Students'}
          icon={<Group width={isStandalone ? 24 : 32} height={isStandalone ? 24 : 32} />}
          onSelected={() => {
            setSelectedTab(ALL_STUDENTS_TAB)
          }}
          selected={selectedTab === ALL_STUDENTS_TAB}
          mode={AssignmentCellMode.Graded}
          boldPercentage
          percentage={
            assignment.submissions?.averageGrade === null ||
            assignment.submissions?.averageGrade === undefined
              ? undefined
              : assignment.submissions.averageGrade
          }
          totalPoints={assignment.totalPoints}
          isStandalone={isStandalone}
        />
      )}
      {content}
    </>
  )
}

const Search = styled(Input)`
  background: transparent;
  padding-left: ${props => getSize(props.theme, '@size-m')};
  padding-right: ${props => getSize(props.theme, '@size-m')};
  outline: none !important;
  box-shadow: none !important;
`

export interface GradingModalSubmissionItemWithSubmissionProps
  extends Omit<GradingModalSubmissionItemProps, 'isLate' | 'percentage'> {
  submission?: BasicAssignmentSubmissionFragment
  classId: string
}
export function GradingModalSubmissionItemWithSubmission({
  submission: _submission,
  ...props
}: GradingModalSubmissionItemWithSubmissionProps) {
  const { data } = useBasicAssignmentSubmissionQuery({
    variables: { id: _submission?.id || '' },
    fetchPolicy: 'cache-only', // We don't want this to ever execute.
    skip: !_submission?.id,
  })
  const submission = data?.node?.__typename === 'AssignmentSubmission' ? data.node : _submission

  return (
    <GradingModalSubmissionItem
      isLate={!!submission?.isLate}
      percentage={submission?.grade !== null ? submission?.grade : undefined}
      {...props}
    />
  )
}

export interface GradingModalSubmissionItemProps {
  classId?: string
  label: string
  icon: React.ReactElement
  percentage?: number
  totalPoints?: number
  boldPercentage?: boolean
  selected?: boolean
  onSelected: () => void
  isLate?: boolean
  isForfeited?: boolean
  mode: AssignmentCellMode
  subtitle?: React.ReactElement
  isStandalone?: boolean
  index: number
}
export function GradingModalSubmissionItem({
  classId,
  label,
  icon,
  percentage,
  totalPoints,
  boldPercentage,
  selected,
  onSelected,
  isLate,
  isForfeited,
  mode,
  subtitle,
  isStandalone,
  index,
}: GradingModalSubmissionItemProps) {
  const theme = useTheme()
  const displayGrade = useGradeDisplayFn()
  const useGradeIncompleteAsZero = useGradeIncompleteLateAsZero(classId)
  let rightContent: React.ReactElement | null = null
  if (mode === AssignmentCellMode.Graded && percentage !== undefined) {
    rightContent = (
      <RightContentContainer className={'right-content'} color={'@gray-4'}>
        {displayGrade({ percentage, totalPoints, hideSuffix: true })}
      </RightContentContainer>
    )
  } else if (mode === AssignmentCellMode.Ungraded) {
    rightContent = (
      <RightContentContainer
        className={'right-content'}
        color={isNative ? '@gray-4' : '@red'}
        weight={FontWeight.Bold}
      >
        Ungraded
      </RightContentContainer>
    )
  } else if (mode === AssignmentCellMode.Started) {
    rightContent = (
      <RightContentContainer className={'right-content'} color={'@gray-4'}>
        Started
      </RightContentContainer>
    )
  } else if (mode === AssignmentCellMode.Missing && useGradeIncompleteAsZero) {
    rightContent = (
      <RightContentContainer className={'right-content'} color={'@red'}>
        <div>Missing</div>
        <BodyExtraSmall color={'@text-color-secondary'}>Graded as 0%</BodyExtraSmall>
      </RightContentContainer>
    )
  } else if (mode === AssignmentCellMode.Missing) {
    rightContent = (
      <RightContentContainer className={'right-content'} color={'@red'}>
        Missing
      </RightContentContainer>
    )
  }

  const danger = mode === AssignmentCellMode.Ungraded
  return (
    <ItemContainer
      danger={danger}
      selected={selected}
      standalone={isStandalone}
      onClick={onSelected}
      index={index}
      data-testid={`GradingModalSubmissionItem ${label}`}
    >
      {icon
        ? React.cloneElement(icon, {
            style: danger
              ? {
                  backgroundColor: getColor(theme, '@red'),
                }
              : undefined,
          })
        : null}
      <Div>
        <Body color={danger && isNative ? '@red' : undefined}>{label}</Body>
        <HSpaced align={'baseline'} space={'@size-xxs'}>
          {subtitle}
          {isLate ? <BodyExtraSmall color={'@red'}>Late</BodyExtraSmall> : null}
          {isForfeited ? (
            <Tooltip
              title={
                'The student has chosen to give up the rest of their attempts in order to see the answers early. Click on the student to edit this.'
              }
            >
              <BodyExtraSmall color={'@text-color-secondary'}>Forfeited Attempts</BodyExtraSmall>
            </Tooltip>
          ) : null}
        </HSpaced>
      </Div>
      <Space />
      {rightContent}
      {isStandalone ? (
        <NavArrowRight
          width={getSize(theme, '@size-s')}
          height={getSize(theme, '@size-s')}
          color={
            danger && isNative ? getColor(theme, '@red') : getColor(theme, '@text-color-secondary')
          }
        />
      ) : null}
    </ItemContainer>
  )
}

const RightContentContainer = styled(isNative ? BodySmall : Body)`
  text-align: right;
`
const ItemContainer = styled(ModalNavigationItem)<{
  danger?: boolean
  standalone?: boolean
  selected?: boolean
}>`
  ${props =>
    props.danger && !isNative
      ? `
    background: ${getColor(props.theme, '@red-light')} !important;
    color: ${getColor(props.theme, '@red')} !important;
  `
      : ''}
  ${props =>
    props.standalone && !isNative
      ? `
    padding: ${getSize(props.theme, '@size-xs')};
    border-radius: ${getBorderRadius(props.theme)};
    &:hover {
      background: ${getColor(props.theme, '@gray-1')};
    }
  `
      : ''}
  ${props =>
    props.selected
      ? `
    background: ${getColor(props.theme, '@gray-2')};
    color: ${getColor(props.theme, '@primary-color')};
  `
      : ''}
`
