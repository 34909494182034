import { SegmentMetadata } from '@thesisedu/feature-course-types'
import { useFreshRef } from '@thesisedu/feature-react'
import { mergeCollections, toGlobalId } from '@thesisedu/feature-utils'
import { Button, useToast } from '@thesisedu/ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SplitViewPendingChanges } from './SplitViewPendingChangesContext'
import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { debug } from '../log'
import { SegmentType } from '../schema'
import { useCreateOrUpdateSegmentMutation } from '../segment/CreateEditSegment'

export interface UseSaveChangesToClassOpts {
  classId: string
  onClose: () => void
  segmentMetadata?: SegmentMetadata
}
export function useSaveChangesToClass({
  classId,
  onClose,
  segmentMetadata,
}: UseSaveChangesToClassOpts): (changes: SplitViewPendingChanges) => Promise<any> {
  const segmentMetadataRef = useFreshRef(segmentMetadata)
  const toast = useToast()
  const navigate = useNavigate()
  const [updateSegment] = useCreateOrUpdateSegmentMutation()
  const [updateClass] = useUpdateClassMutation(classId, {
    onError() {
      toast({ title: 'There was an error saving your changes.', status: 'error' })
    },
  })
  return async changes => {
    debug('saving bulk drag / drop changes to class %s', classId)

    // First, create the required segments.
    for (const { isCreated, ...segment } of changes.createdSegments) {
      debug('updating segment %s: %O', segment.id, segment)
      await updateSegment({
        variables: {
          input: {
            ...segment,
            type: segment.type as SegmentType,
            id: toGlobalId('Segment', segment.id),
          },
        },
      })
    }

    const existingMetadata = segmentMetadataRef.current
    const newSegmentMetadata = {
      segments: mergeCollections(existingMetadata?.segments ?? [], changes.metadataOverrides),
      structureOverrides: mergeCollections(
        existingMetadata?.structureOverrides ?? [],
        changes.structureOverrides,
      ),
    }
    debug('saving metadata: %O', newSegmentMetadata)
    await updateClass({
      segmentMetadata: newSegmentMetadata,
    })

    const segmentId =
      changes.structureOverrides.length === 1 ? changes.structureOverrides[0].id : undefined
    toast({
      title: 'Class updated!',
      status: 'success',
      actionAlt: segmentId ? 'View in Class' : 'View Class',
      action: (
        <Button
          children={segmentId ? 'View in Class' : 'View Class'}
          onPress={() => {
            let suffix = ''
            if (segmentId) {
              const params = new URLSearchParams({
                highlight: segmentId,
              })
              suffix = `?${params.toString()}`
            }
            navigate(`/teacher/classes/${classId}/course/outline${suffix}`)
          }}
        />
      ),
    })
    onClose()
  }
}
