import { MediaGridItemProps } from '@thesisedu/feature-media-react/search'
import { ShareIos } from '@thesisedu/ui/icons'
import React from 'react'

import { SharingSearchResultFragment } from '../schema'

export interface UseMediaGridItemPropsOpts {
  sharing?: SharingSearchResultFragment | null
}

export function useMediaGridItemProps({
  sharing,
}: UseMediaGridItemPropsOpts): Partial<MediaGridItemProps> {
  return sharing?.sharedFrom
    ? {
        topRightIcon: {
          icon: <ShareIos />,
          color: '@blue',
          tooltip: `Shared from ${sharing.sharedFrom.name || sharing.sharedFrom.username}`,
        },
      }
    : {}
}
