import { AssignmentOverrides } from './assignments'
import { PartialAssignment } from './models'

/** The key is the name of the category; the value is the global ID of the AssignmentCategory */
export interface AssignmentCategoryMap {
  [categoryName: string]: string | null | undefined
}
export interface InternalIdAssignmentCategoryMap {
  [categoryName: string]: string | number | null | undefined
}

export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS',
}
export const DEFAULT_GRADING_MODE = ClassGradingMode.WeightedAverage

export interface ClassConfigurationWithAssignmentSettings {
  [key: string]: any
  defaultAssignmentSettings?: DefaultAssignmentSettings
  assignmentCategoryMap?: AssignmentCategoryMap
  gradingMode?: ClassGradingMode
  gradeIncompleteLateAsZero?: boolean
}

export interface DefaultAssignmentSettings extends PartialAssignment {
  /** The key is the global GraphQL ID for the category. */
  categoryDefaultSettings?: Record<string, PartialAssignment>
  configuration?: AssignmentOverrides
}
