import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  DistrictFragment,
  DistrictFragmentDoc,
  UpdateDistrictMutation,
  useUpdateDistrictMutation as useMutation,
} from '../schema'

export const useUpdateDistrictMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error updating that district.'),
    update: updateMutation<UpdateDistrictMutation, DistrictFragment>({
      path: 'updateDistrict.district',
      fragment: DistrictFragmentDoc,
    }),
    ...opts,
  })
}
