import { styled, Modal } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'
import { keyframes } from 'styled-components'

import { Badge } from './Badge'
import { BadgeFragment } from './schema'

export interface BadgeAwardModalProps {
  visible?: boolean
  onCancel: () => void
  badge: BadgeFragment
}
// TODO: Add animation for the badge...
export function BadgeAwardModal({ visible, onCancel, badge }: BadgeAwardModalProps) {
  return (
    <Modal visible={visible} onCancel={onCancel} width={350} closable={false}>
      <OuterContainer>
        <Container>
          <Badge badge={badge} noBorder awarded />
        </Container>
        <ButtonContainer>
          <Button
            onClick={() => onCancel()}
            type={'primary'}
            icon={<span>👍&nbsp;&nbsp;</span>}
            size={'large'}
          >
            Keep going!
          </Button>
        </ButtonContainer>
      </OuterContainer>
    </Modal>
  )
}

const OuterAnimation = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const OuterContainer = styled.div`
  animation: ${OuterAnimation} 0.75s linear;
`
const Container = styled.div`
  padding-top: ${props => props.theme['@size-xl']};
`
const ButtonAnimation = keyframes`
  0% {
    opacity: 0;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ButtonContainer = styled.div`
  margin: ${props => props.theme['@size-s']} auto;
  text-align: center;
  animation: ${ButtonAnimation} 6s linear;
`
