import { onMutationError } from '@thesisedu/feature-apollo-react'
import { File } from '@thesisedu/feature-attachments-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import {
  LoadingIndicator,
  PlayerProps,
  Block,
  Color,
  BodySmall,
  styled,
  NotFound,
  H2,
  Body,
} from '@thesisedu/react'
import { useToast, Button } from '@thesisedu/ui'
import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'

import { DefaultVideoView } from './DefaultVideoView'
import { EditVideoLink } from './EditVideoModal'
import { useVideoOnDemandQuery } from './queries/useVideoOnDemandQuery'
import { VideoOnDemandStatus, useFinishUploadingVideoOnDemandMutation } from './schema'
import { VodReactProviderResource } from './types'

const LOADING_STATUSES = [VideoOnDemandStatus.Uploading, VideoOnDemandStatus.Processing]
export type VideoViewerProps = Partial<PlayerProps> & {
  vodId: string
  showPlaceholder?: boolean
}
export function VideoViewer({
  vodId,
  showPlaceholder,
  children,
  ...playerProps
}: React.PropsWithChildren<VideoViewerProps>) {
  const [shouldPoll, setShouldPoll] = useState(false)
  const [resubmitDisabled, setResubmitDisabled] = useState(false)
  const toast = useToast()
  const root = useFeatureRoot()
  const { data, loading } = useVideoOnDemandQuery({
    variables: { id: vodId },
    pollInterval: shouldPoll ? 3000 : undefined,
  })
  const vod = data?.node?.__typename === 'VideoOnDemand' ? data.node : undefined
  useEffect(() => {
    if (vod?.status.status) {
      setShouldPoll(LOADING_STATUSES.includes(vod.status.status))
    }
  }, [vod?.status.status])

  const [finishUpload, { loading: finishUploadLoading }] = useFinishUploadingVideoOnDemandMutation({
    onCompleted: () => {
      toast({ title: 'Video resubmitted', status: 'success' })
      setResubmitDisabled(true)
    },
    onError: onMutationError('There was an error resubmitting the video.'),
  })

  if (loading && !vod) {
    return (
      <Block paddingTop={'@size-l'} paddingBottom={'@size-l'}>
        <LoadingIndicator block />
      </Block>
    )
  } else if (vod?.status.status && LOADING_STATUSES.includes(vod.status.status)) {
    return (
      <NotReadyContainer>
        {vod.status.status !== VideoOnDemandStatus.Uploading ? (
          <Progress type={'circle'} percent={vod.status.percentage} className={'progress'} />
        ) : (
          <Color
            color={'@primary-color'}
            style={{ marginRight: '0.25em', marginLeft: 10, fontSize: '5em' }}
          >
            <LoadingIndicator />
          </Color>
        )}
        <div className={'content'}>
          <H2 style={{ marginTop: 0 }}>We're working on it...</H2>
          <Body className={'description'}>
            Your video is not ready yet! When the video is ready, it will automatically appear right
            here.
          </Body>
          <BodySmall color={'@text-color-secondary'}>
            Current status:{' '}
            {vod.status.status === VideoOnDemandStatus.Uploading
              ? 'Queued'
              : vod.status.status[0] + vod.status.status.toLowerCase().slice(1)}
          </BodySmall>
          {new Date(vod.updatedAt) < new Date(Date.now() - 1000 * 60 * 30) ? (
            <Button
              style={{ marginTop: 10 }}
              variant={'primary'}
              disabled={resubmitDisabled}
              loading={finishUploadLoading}
              onPress={async () => {
                finishUpload({
                  variables: {
                    input: {
                      vodId: vod.id,
                    },
                  },
                })
              }}
            >
              Resubmit Video
            </Button>
          ) : null}
        </div>
      </NotReadyContainer>
    )
  } else if (vod?.status.status === VideoOnDemandStatus.Processed && vod?.status.playbackData) {
    if (showPlaceholder) {
      return (
        <File
          noLink
          filename={'test.vod'}
          label={vod.name}
          children={<EditVideoLink vodId={vod.id} />}
        />
      )
    } else {
      const resource = root.deps.resources.getResource<VodReactProviderResource>(
        'VodReactProvider',
        vod.provider.identifier,
      )
      const ViewComponent = resource?.ViewComponent || DefaultVideoView
      return (
        <PlayerWrapper>
          <ViewComponent
            playerProps={{
              ...playerProps,
              duration: vod.status.duration || undefined,
            }}
            playbackData={vod.status.playbackData}
          />
          {children}
        </PlayerWrapper>
      )
    }
  } else {
    return <NotFound description={'That video could not be found.'} />
  }
}

const PlayerWrapper = styled.div`
  position: relative;
  .player-container:not(.is-fullscreen) {
    border-radius: ${props => props.theme['@border-radius-large']};
    overflow: hidden;
    transform: translateZ(0); // Safari hack to make the border radius work.
  }
  .player-outer .player {
    display: flex !important;
    align-items: center;
    justify-content: center;
    transform: scale(1.01); // This gets rid of the black bar at the right side of the video.
    > div {
      max-width: calc(100vh * 1.7777);
    }
  }
`

const NotReadyContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px auto;
  max-width: 600px;
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-large']};
  padding: 20px;
  .progress {
    margin-left: 10px;
  }
  .content {
    flex-grow: 1;
    margin-left: 20px;
    line-height: 1.3;
    > p {
      margin: 0;
    }
    > h2 {
      font-size: 25px;
      font-weight: bold;
      letter-spacing: -1px;
      margin-bottom: 10px;
    }
    > p.description {
      font-size: 16px;
      font-weight: 300;
    }
    > p.status {
      color: ${props => props.theme['@text-color-secondary']};
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
`
