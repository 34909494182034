import { RecordViewContextValue } from '../RecordViewContext'

export interface RecordStateProps<Context = object> {
  context: Context
  onTransition: (opts: TransitionOpts<any>) => void
}
export interface RecordState<Context = object> {
  Component: (props: RecordStateProps<Context>) => React.ReactElement | null
  beforeTransition?: (
    props: Omit<RecordStateProps<Context>, 'onTransition'> & RecordViewContextValue,
  ) => TransitionOpts<any> | undefined | void
}

export interface Streams {
  audioDeviceId?: string
  videoDeviceId?: string
  hasDesktopRecording?: boolean
  stream?: MediaStream
}

export interface TransitionOpts<Context extends object = object> {
  state: string
  context: Context
}
export function getStateFactory<Context extends object>(state: string) {
  return (context: Context): TransitionOpts => {
    return { state, context }
  }
}

export { MediaType } from '../../schema'
