import { fromGlobalId } from '@thesisedu/feature-utils'
import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { PlacementArrowDirection, PlacementSplitView } from '../../../placement/PlacementSplitView'
import { CurrentClassPane } from '../../../placement/panes/CurrentClassPane'
import { ExplorePane } from '../../../placement/panes/ExplorePane'
import { MyContentPane } from '../../../placement/panes/MyContentPane'

export function SplitViewRoute() {
  Shell.useShellProps(
    'split-view',
    {
      noLeftMenu: true,
      hideBreadcrumb: true,
      hideTopBar: true,
      noScroll: true,
      noPadding: true,
    },
    [],
  )
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()
  const pane = searchParams.get('pane') ?? undefined
  const rightTerm = searchParams.get('rightTerm') ?? undefined
  const returnPath = searchParams.get('return') ?? undefined
  const { type } = fromGlobalId(pane ?? '') ?? {}
  const onCancel = () => navigate(returnPath ?? location.pathname.replace('/split', ''))
  let left = <ExplorePane />
  let right = <CurrentClassPane onClose={onCancel} />
  let arrowDirection: PlacementArrowDirection | undefined = undefined
  if (type === 'Course') {
    left = <ExplorePane defaultCourseId={pane} />
  } else if (pane === 'my-content') {
    left = <MyContentPane />
  } else if (pane && rightTerm) {
    // Assume they want to open two different terms inside the same class.
    arrowDirection = PlacementArrowDirection.LeftRight
    left = (
      <CurrentClassPane
        selectedTermId={pane}
        onSelectedTermChanged={() => {}}
        hideTermSelect
        justify={'left'}
        onClose={onCancel}
      />
    )
    right = (
      <CurrentClassPane
        selectedTermId={rightTerm}
        onSelectedTermChanged={() => {}}
        includeAllTermsInCreateTermSelect
        hideTermSelect
        onClose={onCancel}
      />
    )
  }

  return (
    <PlacementSplitView
      left={left}
      right={right}
      arrowDirection={arrowDirection}
      onCancel={onCancel}
    />
  )
}
