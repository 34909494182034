import { ReactFeature } from '@thesisedu/feature-react'
import {
  addSearch,
  SearchResultGridItem,
  SearchResultListItem,
  SearchResultNode,
} from '@thesisedu/feature-search-react'
import { IconContainer, ImagePlaceholder } from '@thesisedu/react/dist/grid'
import { DATE_FORMATS } from '@thesisedu/web'
import { Group } from 'iconoir-react'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

interface ClassResultNode extends SearchResultNode {
  className: string
  classCreatedAt: string
  classStudents?: {
    totalCount: number
  }
}

const FRAGMENT = `
  ... on Class {
    className: name
    classCreatedAt: createdAt
    classStudents: students {
      totalCount
    }
  }
`

export default function addSearchResources(feature: ReactFeature) {
  addSearch<ClassResultNode>(feature, FRAGMENT, 'Class', {
    getUniversalLink: item => `classes/${item.id}`,
    getDate: item => item.classCreatedAt,
    renderGrid: ({ item }) => (
      <SearchResultGridItem
        title={item.className}
        description={
          item.classStudents
            ? pluralize('student', item.classStudents?.totalCount || 0, true)
            : moment(item.classCreatedAt).format(DATE_FORMATS.DATE)
        }
        image={
          <ImagePlaceholder>
            <IconContainer children={<Group />} />
          </ImagePlaceholder>
        }
      />
    ),
    renderList: ({ item, onClick }) => (
      <SearchResultListItem
        onClick={onClick}
        title={item.className}
        subtitle={
          item.classStudents
            ? pluralize('student', item.classStudents?.totalCount || 0, true)
            : moment(item.classCreatedAt).format(DATE_FORMATS.DATE)
        }
        type={'Class'}
        thumbnail={
          <ImagePlaceholder>
            <div>
              <IconContainer children={<Group />} />
            </div>
          </ImagePlaceholder>
        }
      />
    ),
  })
}
