import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

import { SegmentDraggable } from './SegmentDraggable'
import { SegmentDraggableContent, SegmentDraggableContentProps } from './SegmentDraggableContent'
import { DetachContext } from '../contexts/DetachContext'
import {
  useSegmentDragDropContext,
  SegmentDragDropContext,
} from '../contexts/SegmentDragDropContext'
import { ReferenceSegment } from '../types'

const Container = styled.div`
  > .segment-draggable {
    background: ${props => transparentize(0.96, props.theme['@blue'])};
    > .right > div:first-child .edit-link {
      color: ${props => transparentize(0.5, props.theme['@blue'])};
    }
  }
`

export interface ReferenceSegmentDraggableProps extends SegmentDraggableContentProps {
  referenceSegment: ReferenceSegment
}
export function ReferenceSegmentDraggable({
  referenceSegment,
  ...props
}: ReferenceSegmentDraggableProps) {
  const dragDropContext = useSegmentDragDropContext(true)
  return (
    <Container>
      <DetachContext.Provider value={{ allowDetach: true }}>
        <SegmentDraggable {...props} segment={referenceSegment}>
          <SegmentDragDropContext.Provider value={{ ...dragDropContext, disableMove: true }}>
            <SegmentDraggableContent disableDelete {...props} />
          </SegmentDragDropContext.Provider>
        </SegmentDraggable>
      </DetachContext.Provider>
    </Container>
  )
}
