import { AuthRequiredPayload, AuthProviderWrapper } from '@thesisedu/feature-users-react'
import React from 'react'

import { useMSALAssociate } from './useMSALLogin'
import { useMsalScopesQuery } from '../schema'

export interface AuthProviderInnerProps extends AuthRequiredPayload {
  scopes: string[]
}
function _AuthProviderInner({ onSuccess, onError, scopes, ...opts }: AuthProviderInnerProps) {
  const { signIn, associating } = useMSALAssociate({ scopes, onSuccess, onError })
  React.useEffect(() => {
    signIn({})
  }, [])
  return (
    <AuthProviderWrapper
      identifier={'msal'}
      onAuth={() => signIn({})}
      onError={onError}
      associating={associating}
      {...opts}
    />
  )
}
export const AuthProviderInner = React.memo(_AuthProviderInner, () => false)

export function AuthProviderHandler(props: AuthRequiredPayload) {
  const { data } = useMsalScopesQuery()
  const scopes = data?.msalScopes
  return scopes?.length ? <AuthProviderInner scopes={scopes} {...props} /> : null
}
