import { Col } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { FragmentListCommonProps } from './types'
import { Block } from '../Block'
import { Row } from '../Row'
import { H3 } from '../Typography'

export const SectionContainer = styled.div`
  margin-top: ${props => props.theme['@size-l']};
`

export function FragmentGridView<T>({
  sections,
  itemKey,
  renderGridItem,
}: FragmentListCommonProps<T>) {
  const results = sections.map(({ title, titleProps, items, key }) => (
    <SectionContainer key={key}>
      {title ? (
        <Block marginBottom={'@size-m'}>
          <H3 isBlock={false} {...titleProps}>
            {title}
          </H3>
        </Block>
      ) : null}
      <Row>
        {items.map(item => (
          <Col
            key={typeof itemKey === 'function' ? itemKey(item) : (item[itemKey] as any)}
            xs={24}
            md={12}
            lg={8}
            xxl={6}
          >
            <Block marginBottom={'@size-s'}>{renderGridItem(item)}</Block>
          </Col>
        ))}
      </Row>
    </SectionContainer>
  ))
  return <>{results}</>
}
