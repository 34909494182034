import { DateValue, useRangeCalendar } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { RangeCalendarStateOptions, useRangeCalendarState } from 'react-stately'

import { CalendarTitle, __Calendar } from './Calendar'
import { CalendarGrid } from './CalendarGrid'
import { createCalendar } from './createCalendar'
import { Button, HSpaced } from '../..'
import { NavArrowLeft, NavArrowRight } from '../../../icons'

export interface RangeCalendarProps<T extends DateValue>
  extends Omit<RangeCalendarStateOptions<T>, 'locale' | 'createCalendar'> {}
function _RangeCalendar<T extends DateValue>(
  props: RangeCalendarProps<T>,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { locale } = useLocale()
  const state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
  })
  const ref = useObjectRef(_ref)
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(
    props,
    state,
    ref,
  )
  return (
    <__Calendar {...calendarProps} ref={ref}>
      <HSpaced space={'xs'}>
        <Button size={'small'} icon={<NavArrowLeft />} {...prevButtonProps} />
        <CalendarTitle level={'l'} weight={'bold'}>
          {title}
        </CalendarTitle>
        <Button size={'small'} icon={<NavArrowRight />} {...nextButtonProps} />
      </HSpaced>
      <CalendarGrid state={state} />
    </__Calendar>
  )
}
export const RangeCalendar = React.forwardRef(_RangeCalendar)
