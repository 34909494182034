import { useFeatureRoot } from '@thesisedu/feature-react'
import React from 'react'

import { getFetchClassStudentsFromProvider } from './fetchClassStudentsFromProvider'
import { getFetchClassesFromProvider } from './fetchClassesFromProvider'
import { getProviderError } from './providerError'
import { SiteClassSyncProviderFragment, useAttachClassSyncMutation } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, getStepFactory } from '../types'

export interface AttachProviderClassContext {
  provider: SiteClassSyncProviderFragment
  providerClassId: string
  providerClassName: string
}
export default {
  size: StepSizes.loading,
  hideClose: true,
  animationGroup: 'loading',
  Component({ context, cls, transition }) {
    const appName = useFeatureRoot().appOptions.name
    const [attachSync] = useAttachClassSyncMutation({
      variables: {
        input: {
          provider: context.provider.identifier,
          id: cls.id,
          label: context.providerClassName,
          providerId: context.providerClassId,
        },
      },
      onError() {
        transition(getProviderError({ ...context, nextStep: getFetchClassesFromProvider(context) }))
      },
      onCompleted(data) {
        const syncId = data.attachClassSync.class.syncs.edges.find(
          edge => edge.node.providerId === context.providerClassId,
        )?.node.id
        if (!syncId) {
          throw new Error('Cannot find sync ID in response')
        }
        transition(
          getFetchClassStudentsFromProvider({
            provider: context.provider,
            syncId,
          }),
        )
      },
    })
    React.useEffect(() => {
      attachSync()
    }, [])
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        title={'Attaching Class'}
        message={
          <>
            We are connecting your <strong>{context.providerClassName}</strong> class from Google
            Classroom to your <strong>{cls.name}</strong> class from {appName}.
          </>
        }
        direction={'right'}
      />
    )
  },
} satisfies SetupStep<AttachProviderClassContext>
export const getAttachProviderClassStep =
  getStepFactory<AttachProviderClassContext>('attachProviderClass')
