import React from 'react'

import { OutlineItem } from './types'
import { GroupSegment } from '../types'

export interface OutlineListContextValue {
  items: OutlineItem[]
  selectedTerm: GroupSegment
  highlightedItemIds: string[]
}
export const OutlineListContext = React.createContext<OutlineListContextValue | undefined>(
  undefined,
)

export interface OutlineListContextProviderProps {
  items: OutlineItem[]
  selectedTerm: GroupSegment
  highlightedItemIds: string[]
}
export function OutlineListContextProvider({
  children,
  items,
  selectedTerm,
  highlightedItemIds,
}: React.PropsWithChildren<OutlineListContextProviderProps>) {
  const existing = useOutlineListContext(false)
  return (
    <OutlineListContext.Provider
      value={{
        ...existing,
        selectedTerm,
        items,
        highlightedItemIds: [...(existing?.highlightedItemIds ?? []), ...highlightedItemIds],
      }}
      children={children}
    />
  )
}

export function useOutlineListContext(): OutlineListContextValue | undefined
export function useOutlineListContext(require: false): OutlineListContextValue | undefined
export function useOutlineListContext(require: true): OutlineListContextValue
export function useOutlineListContext(require?: boolean): OutlineListContextValue | undefined {
  const context = React.useContext(OutlineListContext)
  if (!context && require) {
    throw new Error('OutlineListContext is required, yet not provided.')
  }
  return context
}
