import { NavArrowRight, PageSearch } from '@thesisedu/react/icons'
import { AntIconWrapper, Block, BodyLarge, H3Alternate, HSpaced, styled } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

import { StandardSetState } from './StandardSetState'
import { StandardSetBasicTagsFragment, StandardSetFragment } from '../schema'

export interface StandardSetWidgetProps {
  standardSet: StandardSetBasicTagsFragment & StandardSetFragment
  allLink: string
}
export function StandardSetWidget({ standardSet, allLink }: StandardSetWidgetProps) {
  return (
    <Container>
      <Block marginBottom={'@size-xs'}>
        <HSpaced align={'center'}>
          <H3Alternate>{standardSet.name} Standards</H3Alternate>
          {standardSet.state ? (
            <StateImageContainer>
              <StandardSetState state={standardSet.state} />
            </StateImageContainer>
          ) : null}
        </HSpaced>
      </Block>
      <Link to={allLink}>
        <ListItem noBorder>
          <BodyLarge color={'@text-color-secondary'}>
            Show all {standardSet.name} Standards
          </BodyLarge>
          <BodyLarge color={'@text-color-secondary'}>
            <AntIconWrapper children={<PageSearch />} />
          </BodyLarge>
        </ListItem>
      </Link>
      <Block marginTop={'@size-xxs'}>
        {standardSet.basicTree.map((item, index) => (
          <Link to={`${allLink}#${item.tag.id}`} key={item.tag.id}>
            <ListItem noBorder={index === standardSet.basicTree.length - 1}>
              <BodyLarge color={'@text-color'}>{item.tag.name}</BodyLarge>
              <BodyLarge color={'@text-color-secondary'}>
                <AntIconWrapper children={<NavArrowRight />} />
              </BodyLarge>
            </ListItem>
          </Link>
        ))}
      </Block>
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => props.theme['@gray-1']};
  padding: ${props => props.theme['@size-l']};
`
const ListItem = styled.div<{ noBorder?: boolean }>`
  margin-right: -${props => props.theme['@size-l']};
  padding: ${props => props.theme['@size-xs']} 0;
  padding-right: ${props => props.theme['@size-l']};
  display: flex;
  align-items: center;
  * {
    transition: color 0.1s linear;
  }
  &:hover {
    * {
      color: ${props => props.theme['@gray-7']} !important;
    }
  }
  ${props => (props.noBorder ? '&.noop' : '&')} {
    border-bottom: 1px solid ${props => props.theme['@border-color-base']};
  }
  > :last-child {
    margin-left: auto;
  }
`
const StateImageContainer = styled.div`
  margin-left: auto;
  color: ${props => props.theme['@gray-5']};
  font-size: ${props => props.theme['@size-l']};
  &:empty {
    display: none;
  }
`
