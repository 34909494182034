import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { StandardSetListItem } from './StandardSetListItem'
import {
  StandardSetFragment,
  StandardSetsDocument,
  StandardSetsQuery,
  StandardSetsQueryVariables,
} from '../schema'

export interface StandardSetsListProps {
  onStandardSetClick: (standardSet: StandardSetFragment) => void
  selectedItemId?: string
}
export function StandardSetsList({ selectedItemId, onStandardSetClick }: StandardSetsListProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <div ref={containerRef} style={{ minHeight: 300 }}>
      <InfiniteQuery<StandardSetFragment, StandardSetsQuery, StandardSetsQueryVariables>
        document={StandardSetsDocument}
        resultPath={'standardSets'}
        infiniteScrollerProps={{
          scrollableTarget: () => containerRef.current,
        }}
        children={({ data }) => {
          const standardSets = data?.standardSets.edges.map(edge => edge.node) || []
          return (
            <>
              {standardSets.map(standardSet => (
                <StandardSetListItem
                  standardSet={standardSet}
                  key={standardSet.id}
                  onClick={() => onStandardSetClick(standardSet)}
                  isSelected={selectedItemId === standardSet.id}
                />
              ))}
            </>
          )
        }}
      />
    </div>
  )
}
