import { useViewerContext } from '@thesisedu/feature-users-web'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Block } from '@thesisedu/ui'
import React from 'react'

import { SegmentPreview } from './SegmentPreview'

type ActionsFn = (apiId: string) => React.ReactElement | undefined
export interface SegmentPreviewContextValue {
  setPreviewSegmentId: (apiId: string | undefined, actions?: ActionsFn) => void
  previewSegmentId?: string
  actionsRef: React.RefObject<ActionsFn | undefined>
}
export const SegmentPreviewContext = React.createContext<SegmentPreviewContextValue | undefined>(
  undefined,
)

export function SegmentPreviewProvider({ children }: React.PropsWithChildren<object>) {
  const [previewSegmentId, _setPreviewSegmentId] = React.useState<string | undefined>(undefined)
  const [previewActions, setPreviewActions] = React.useState<React.ReactElement | undefined>(
    undefined,
  )
  const actionsFunc = React.useRef<ActionsFn | undefined>(undefined)
  const viewer = useViewerContext(false)
  React.useEffect(() => {
    const handle = () => {
      const params = new URLSearchParams(window.location.search)
      _setPreviewSegmentId(params.get('preview') || undefined)
    }
    window.addEventListener('popstate', handle)
    handle()
    return () => {
      window.removeEventListener('popstate', handle)
    }
  }, [])
  React.useEffect(() => {
    if (!previewSegmentId) {
      setPreviewActions(undefined)
    }
    const params = new URLSearchParams(window.location.search)
    if (previewSegmentId) {
      params.set('preview', previewSegmentId)
    } else if (params.has('preview')) {
      params.delete('preview')
    } else return
    const stringParams = params.toString()
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}${stringParams ? `?${stringParams}` : ''}`,
    )
  }, [previewSegmentId])
  return (
    <SegmentPreviewContext.Provider
      value={{
        actionsRef: actionsFunc,
        previewSegmentId,
        setPreviewSegmentId: (id, actions) => {
          _setPreviewSegmentId(id)
          if (actions && id) {
            actionsFunc.current = actions
            setPreviewActions(actions(id))
          } else if (actionsFunc.current && id) {
            setPreviewActions(actionsFunc.current(id))
          } else {
            setPreviewActions(undefined)
          }
        },
      }}
    >
      <>
        {viewer?.group === 'TEACHER' ? (
          <SegmentPreview
            segmentDatabaseId={previewSegmentId}
            actions={() => <Block left>{previewActions}</Block>}
            onClose={() => _setPreviewSegmentId(undefined)}
          />
        ) : null}
        {children}
      </>
    </SegmentPreviewContext.Provider>
  )
}

export function useSegmentPreviewContext(): SegmentPreviewContextValue | undefined
export function useSegmentPreviewContext(require: false): SegmentPreviewContextValue | undefined
export function useSegmentPreviewContext(require: true): SegmentPreviewContextValue
export function useSegmentPreviewContext(
  require?: boolean,
): SegmentPreviewContextValue | undefined {
  const context = React.useContext(SegmentPreviewContext)
  if (!context && require) {
    throw new Error('SegmentPreviewContext is required, yet not provided.')
  }
  return context
}

export function useIsSegmentPreview(rawSegmentId?: string) {
  return (
    useSegmentPreviewContext(false)?.previewSegmentId === toGlobalId('Segment', rawSegmentId || '')
  )
}
