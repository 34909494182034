import { ClassLeftNavHook, ClassRoutesHook } from '@thesisedu/feature-classes-react'
import { Shell } from '@thesisedu/feature-web'
import { BellNotification } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { Announcements } from '../../Announcements'
import { AnnouncementsReactFeature } from '../../AnnouncementsReactFeature'

export default function (feature: AnnouncementsReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>('classes-react:class-routes', routes => {
    return [
      ...routes,
      <Route key={'announcements'} path={'announcements/*'} element={<Announcements />} />,
    ]
  })
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { group }) => {
      return [
        ...items,
        {
          group: group === 'TEACHER' ? 'Students' : 'Communications',
          weight: 13,
          element: (
            <Shell.LeftMenuItem
              key={'announcements'}
              name={'Announcements'}
              icon={<BellNotification />}
              path={'announcements'}
            />
          ),
        },
      ]
    },
  )
}
