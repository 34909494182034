import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { SITE_TYPES } from './constants'

export const TypeSelector = React.forwardRef<any, SelectProps<any>>((props, ref) => (
  <Select placeholder={'Select a type'} {...props} ref={ref}>
    {(Object.keys(SITE_TYPES) as (keyof typeof SITE_TYPES)[]).map(type => (
      <Select.Option key={type} value={type}>
        {SITE_TYPES[type]}
      </Select.Option>
    ))}
  </Select>
))
