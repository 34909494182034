import { AssignmentCategoryMapFragment, BasicAssignmentCategoryFragment } from '../schema'

export interface HasDefaultCategoriesOpts {
  categoryMap: AssignmentCategoryMapFragment[]
  categories: BasicAssignmentCategoryFragment[]
}
export function hasDefaultCategories({
  categoryMap,
  categories,
}: HasDefaultCategoriesOpts): boolean {
  return categoryMap.every(({ name, mappedTo }) => {
    const category = categories.find(cat => cat.id === mappedTo)
    return category?.name === name
  })
}
