import { useMutateHook } from '@thesisedu/feature-react'
import { styled, StyledComponent, VSpaced } from '@thesisedu/web'
import { Button } from 'antd'
import React, { useState } from 'react'

import { TagsEditorModal, TagsEditorModalProps } from './TagsEditorModal'
import { MutateTagsEditorAdditionalEditors } from './types'

export const Container: StyledComponent<'div'> = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 10px;
  }
  > button {
    margin-left: auto;
  }
`

export interface TagsEditorProps extends Omit<TagsEditorModalProps, 'visible' | 'onClose'> {}
export function TagsEditor({ value, onChange, types, ...rest }: TagsEditorProps) {
  const [visible, setVisible] = useState(false)
  const totalTags = value
    ? Object.keys(value)
        .filter(k => types.some(type => type.type === k))
        .reduce((total, key) => total + value[key].length, 0)
    : 0
  const totalTagsSuffix = totalTags === 1 ? 'tag' : 'tags'
  const additionalEditors = useMutateHook<MutateTagsEditorAdditionalEditors>(
    'feature-tags:tags-editor-additional-editors',
    [],
    undefined,
  )

  return (
    <VSpaced align={'stretch'}>
      <Container>
        <TagsEditorModal
          {...rest}
          value={value}
          onChange={onChange}
          types={types}
          visible={visible}
          onClose={() => setVisible(false)}
        />
        <span>
          {totalTags} {totalTagsSuffix}
        </span>
        <Button onClick={() => setVisible(true)}>Edit Tags &rarr;</Button>
      </Container>
      {additionalEditors.map((AdditionalEditor, index) => (
        <AdditionalEditor key={index} value={value} onChange={onChange} />
      ))}
    </VSpaced>
  )
}
