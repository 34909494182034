import { ClassStudentPair } from '@thesisedu/feature-classes-react'
import { useFlag } from '@thesisedu/react'
import { Button$, Checkbox, Form, Text, VSpaced, useToast } from '@thesisedu/ui'
import { MagicWand } from '@thesisedu/ui/icons'
import React from 'react'

import { NumGroupsField } from './NumGroupsField'
import {
  GenerateGroupModeInfoFragment,
  GenerateLeaderModeInfoFragment,
  GeneratedGroupsConfigurationInput,
  useGeneratedGroupsConfigurationLazyQuery,
} from '../../schema'
import { GroupsEditorProps } from '../GroupsEditor'

type FormValues = Omit<GeneratedGroupsConfigurationInput, 'students'>
export interface GenerateGroupsConfigurationProps {
  onChange: GroupsEditorProps['onChange']
  students: ClassStudentPair[]
  groupModes: GenerateGroupModeInfoFragment[]
  leaderModes: GenerateLeaderModeInfoFragment[]
  submitButtonProps?: Partial<Button$.ButtonProps>
}
export function GenerateGroupsConfiguration({
  onChange,
  students,
  groupModes,
  leaderModes,
  submitButtonProps,
}: GenerateGroupsConfigurationProps) {
  const [generate] = useGeneratedGroupsConfigurationLazyQuery()
  const toast = useToast()
  const [defaultValues, setDefaultValues, { loading }] = useFlag<FormValues>('generate-groups', {
    numGroups: 5,
    groupMode: groupModes[0]?.mode,
    leaderMode: leaderModes[0]?.mode,
  })
  const form = Form.useForm<FormValues>()
  React.useEffect(() => {
    if (!loading) {
      form.reset(defaultValues)
    }
  }, [loading])

  return (
    <Form
      style={{ width: '100%' }}
      form={form}
      onFinish={async values => {
        setDefaultValues(values)
        const result = await generate({
          variables: {
            input: {
              ...values,
              students,
            },
          },
        })
        if (result.data?.generatedGroupsConfiguration.groups) {
          onChange?.(result.data.generatedGroupsConfiguration.groups)
        } else {
          toast.error('There was an error generating your groups.')
        }
      }}
    >
      <VSpaced space={'m'}>
        <Form.Field name={'numGroups'} aria-label={'Number of Groups'} required>
          <NumGroupsField numStudents={students.length} />
        </Form.Field>
        <Form.CheckboxGroup name={'groupMode'} aria-label={'Group Generation Mode'} required>
          {groupModes.map(mode => (
            <Checkbox
              key={mode.mode}
              value={mode.mode}
              align={'flex-start'}
              label={
                <>
                  <Text>{mode.name}</Text>
                  {mode.description ? (
                    <Text level={'s'} color={'secondary'}>
                      {mode.description}
                      {mode.summary ? <strong> {mode.summary}</strong> : null}
                    </Text>
                  ) : null}
                </>
              }
            />
          ))}
        </Form.CheckboxGroup>
        <Form.CheckboxGroup name={'leaderMode'} aria-label={'Leader Selection Mode'} required>
          {leaderModes.map(mode => (
            <Checkbox
              key={mode.mode}
              value={mode.mode}
              align={'flex-start'}
              label={
                <>
                  <Text>{mode.name}</Text>
                  {mode.description ? (
                    <Text level={'s'} color={'secondary'}>
                      {mode.description}
                      {mode.summary ? <strong> {mode.summary}</strong> : null}
                    </Text>
                  ) : null}
                </>
              }
            />
          ))}
        </Form.CheckboxGroup>
        <Form.Submit
          variant={'chromeless'}
          style={{ width: '100%' }}
          children={'Generate Groups'}
          icon={<MagicWand />}
          {...submitButtonProps}
        />
      </VSpaced>
    </Form>
  )
}
