import { PlatformImport } from '@thesisedu/feature-course-types'
import { Format } from '@thesisedu/feature-utils'
import { Modal, Modal$, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

export interface ImportResultModalProps extends Partial<Modal$.ModalProps> {
  result: PlatformImport.ImportSegmentsSummary
}
export function ImportResultModal({ result, ...rest }: ImportResultModalProps) {
  return (
    <Modal
      status={'success'}
      title={`Updated ${Format.plural('segment', result.updatedSegments)}`}
      {...rest}
    >
      <VSpaced space={'m'}>
        {result.missingTags.length ? (
          <VSpaced space={'xxs'}>
            <Text weight={'bold'}>{Format.plural('missing tag', result.missingTags.length)}:</Text>
            <Text level={'xs'}>{result.missingTags.join(', ')}</Text>
          </VSpaced>
        ) : null}
        {result.tagsCreated.length ? (
          <VSpaced space={'xxs'}>
            <Text weight={'bold'}>{Format.plural('created tag', result.tagsCreated.length)}:</Text>
            <Text level={'xs'}>{result.tagsCreated.join(', ')}</Text>
          </VSpaced>
        ) : null}
        {result.unmatchedSegmentNames.length ? (
          <VSpaced space={'xxs'}>
            <Text weight={'bold'} color={'orange'}>
              {Format.plural('unmatched segment name', result.unmatchedSegmentNames.length)}:
            </Text>
            <Text level={'xs'} color={'orange'}>
              {result.unmatchedSegmentNames.join(', ')}
            </Text>
          </VSpaced>
        ) : null}
      </VSpaced>
    </Modal>
  )
}
