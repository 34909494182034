import React from 'react'

export interface StaffContextValue {
  extraMargin: number
}
export const StaffContext = React.createContext<StaffContextValue | undefined>(undefined)

export function useStaffContext(): StaffContextValue | undefined
export function useStaffContext(require: false): StaffContextValue | undefined
export function useStaffContext(require: true): StaffContextValue
export function useStaffContext(require?: boolean): StaffContextValue | undefined {
  const context = React.useContext(StaffContext)
  if (!context && require) {
    throw new Error('StaffContext is required, yet not provided.')
  }
  return context
}
