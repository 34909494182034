import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { useRefreshAuth, useViewerContext } from '@thesisedu/feature-users-react'
import { useUpdateUserMutation } from '@thesisedu/feature-users-web/dist/schema'
import {
  Block,
  BodyLarge,
  BodySmall,
  color,
  ContentBlock,
  Divider,
  HSpaced,
  Result,
  size,
  styled,
} from '@thesisedu/react'
import { MinusCircle } from 'iconoir-react'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { TrialRequestConnectSignup, useHasProviders } from './TrialRequestConnectSignup'
import { TrialRequestEmailSignup, FormValues } from './TrialRequestEmailSignup'

export interface SignupChildProps {
  onComplete: (patch?: FormValues) => Promise<void>
}

export interface TrialRequestSignupPageProps {
  authProviders?: string[]
  children: React.ReactElement
}
export function TrialRequestSignupPage({ authProviders, children }: TrialRequestSignupPageProps) {
  const viewer = useViewerContext(false)
  const { refreshAuth } = useRefreshAuth()
  const { appOptions } = useFeatureRoot()
  const hasProviders = useHasProviders(authProviders)
  const [updateUser] = useUpdateUserMutation({
    onError: onMutationError('There was an error finishing your registration.'),
  })
  const onComplete = async (patch?: FormValues) => {
    if (!viewer) throw new Error('Viewer has not been set.')
    const result = await updateUser({
      variables: {
        input: {
          id: viewer.id,
          patch: patch || {},
        },
      },
    })
    if (result.data?.updateUser.user) {
      await refreshAuth()
    }
  }

  if (viewer?.id) {
    return (
      <ContentContainer>
        <GlobalStyle />
        <HSpaced align={'flex-start'} space={'@size-l'}>
          <div style={{ flex: 1 }}>
            <BodyLarge color={'@text-color-secondary'}>Welcome to</BodyLarge>
            <Logo src={appOptions.logo()} alt={`${appOptions.name} Logo`} />
            <Block marginTop={'@size-l'}>{children}</Block>
          </div>
          <ContentBlock style={{ width: 400 }}>
            <TrialRequestEmailSignup onComplete={onComplete} />
            {hasProviders ? (
              <>
                <Block marginTop={'@size-l'} marginBottom={'@size-l'}>
                  <Divider>
                    <BodySmall color={'@text-color-secondary'}>or</BodySmall>
                  </Divider>
                </Block>
                <TrialRequestConnectSignup onComplete={onComplete} authProviders={authProviders} />
              </>
            ) : null}
          </ContentBlock>
        </HSpaced>
      </ContentContainer>
    )
  } else {
    return (
      <ContentContainer>
        <GlobalStyle />
        <ContentBlock>
          <Result
            status={'error'}
            icon={<MinusCircle />}
            title={'We ran into a problem.'}
            subTitle={`It looks like we cannot find your user from your trial request. Please contact ${appOptions.name} support.`}
          />
        </ContentBlock>
      </ContentContainer>
    )
  }
}

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 100px 0 ${size('@size-xl')} 0;
  width: 900px;
  max-width: 90vw;
`
const Logo = styled.img`
  height: 75px;
`
const GlobalStyle = createGlobalStyle`
  body {
    background: ${color('@gray-1')};
  }
`
