import React from 'react'

import { debug } from '../../../log'
import { UploadRequestMode, useUpdateMediaMutation } from '../../../schema'
import { UploadManagerContext } from '../../../upload/UploadManagerContext'
import { getContentType, getExtension, addUpload } from '../../../upload/fetchAndAddUpload'
import { UploadStatus } from '../../../upload/types'
import { useMediaUpdatesContext } from '../../MediaUpdatesContext'
import { useRecordViewContext } from '../../RecordViewContext'
import { GetMediaUploadResponse, GetMediaUploadUrl } from '../../types'
import { MediaType } from '../types'

export interface FinishUploadOpts {
  mediaType: MediaType
  getMediaUploadUrl: GetMediaUploadUrl
  contentType?: string
  extension?: string
  /** The main recording. */
  data: Blob
  /**
   * Pass this if there was a screen recording. If there was only a screen
   * recording, it should be passed as the data instead of here.
   */
  screenData?: Blob
}
export type FinishUploadCallback = (opts: FinishUploadOpts) => Promise<boolean>
export interface FinishUpload {
  finishUpload: FinishUploadCallback
  loading?: boolean
}
export interface UseFinishUploadOpts {
  setUploadId: (uploadId: string | undefined) => void
  onError?: (err: any) => void
}
export function useFinishUpload({ setUploadId, onError }: UseFinishUploadOpts): FinishUpload {
  const { mediaUpdates } = useMediaUpdatesContext(true)
  const { onProcessed, waitForUpload, onCreated, label } = useRecordViewContext(true)
  const [loading, setLoading] = React.useState(false)
  const [updateMedia] = useUpdateMediaMutation({
    onError,
  })
  const uploadManager = React.useContext(UploadManagerContext)
  if (!uploadManager) {
    throw new Error('Cannot use RecordScreen outside UploadManagerContext')
  }

  return {
    loading,
    async finishUpload({ data, getMediaUploadUrl, mediaType, contentType, extension, screenData }) {
      debug('finishing upload')
      setUploadId(undefined)
      setLoading(true)
      let result: GetMediaUploadResponse | null = await getMediaUploadUrl({
        mimeType: contentType ?? getContentType(mediaType),
        extension: extension ?? getExtension(mediaType),
        type: mediaType,
        requestMode: UploadRequestMode.Basic,
        source: 'web',
      })
      setLoading(false)
      if (result?.media) {
        debug('media updates', mediaUpdates)
        if (mediaUpdates) {
          debug('initial updates provided, updating media', mediaUpdates)
          const newResult = await updateMedia({
            variables: {
              input: {
                id: result!.media!.media.id,
                patch: mediaUpdates,
              },
            },
          })
          if (newResult.data?.updateMedia.media) {
            debug('patching response', newResult.data!.updateMedia.media)
            result = JSON.parse(JSON.stringify(result))
            result!.media!.media = newResult.data!.updateMedia.media
          }
        }

        await addUpload(label, uploadManager, result!, { fileBlob: data }, status => {
          if (onCreated && waitForUpload && status === UploadStatus.Uploaded) {
            onCreated(result!)
          }
          if (onProcessed && status === UploadStatus.Complete) {
            onProcessed(result!)
          }
        })
        setUploadId(result!.media!.media.id)
        if (!waitForUpload && onCreated) {
          onCreated(result!)
        }
        return true
      } else {
        return false
      }
    },
  }
}
