import { useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { useInteraction } from '@thesisedu/feature-interactions-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { SolidIcon, useNavigate } from '@thesisedu/react'
import { Tour } from '@thesisedu/react-tours'
import { styled, s, Text, Block, LoadingIndicator, Button } from '@thesisedu/ui'
import { ArrowRight, Check, Walking } from '@thesisedu/ui/icons'
import React from 'react'

import { TourConfig } from './common'
import { useTourWidgetCompletionStatus } from './useTourWidgetCompletionStatus'
import { useCourseViewerModalContext } from '../../../contexts/CourseViewerModalContext'
import { useSegment } from '../../../contexts/SegmentContext'
import { useSegmentCompletion } from '../../../course_viewer/completion/SegmentCompletion'
import { useSegmentCompletionHandler } from '../../../course_viewer/completion/useSegmentCompletion'
import { debug, error } from '../../../log'
import { useSegmentContext } from '../../../segment/Segment'

export function TourElement({ id, config, readOnly }: SimpleWidget.SimpleWidgetProps<TourConfig>) {
  const client = useApolloClient()
  const { id: segmentId } = useSegmentContext(true)
  const segment = useSegment(segmentId)
  const { loading, isComplete, onComplete } = useTourWidgetCompletionStatus(segmentId, id)
  const canShowCompletion = useSegmentCompletionHandler()
  const { host: segmentCompletionHost } = useSegmentCompletion({ segment: segment! })
  const selectedClassId = useSelectedClassId()
  const [isTouring, setIsTouring] = React.useState(false)
  const { setHidden = () => {} } = useCourseViewerModalContext(false) || {}
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  const [start, stop] = useInteraction({
    type: 'WIDGET_TOUR',
    reference: selectedClassId,
    metadata: {
      widgetId: id,
      segmentId,
    },
    options: {
      // We only want to mark the interaction as complete if the tour itself is completed.
      discardOnClose: true,
    },
  })
  const canTour = viewer?.group === 'STUDENT'

  const startTour = () => {
    setIsTouring(true)
    setHidden(true, 'tour')
    start()
    if (config.startPage) {
      const normalized = config.startPage.startsWith('/')
        ? config.startPage.slice(1)
        : config.startPage
      debug('navigating to tour start page %s', normalized)
      navigate(`/student/classes/${selectedClassId}/${normalized}`)
    }
  }

  const finishTour = () => {
    setHidden(false, 'tour')
    setIsTouring(false)
    stop()
      .then(() => {
        return client.refetchQueries({
          include: ['userHasCompletedTour'],
        })
      })
      .catch((err: any) => {
        error('error refetching queries after completing tour')
        error(err)
      })
    onComplete()
  }

  let content = (
    <>
      {isTouring ? <Tour tour={config.tour} onFinish={finishTour} /> : null}
      <Container style={readOnly ? undefined : { pointerEvents: 'none' }}>
        {isComplete ? (
          <SolidIcon color={'@green'} style={{ fontSize: 48 }}>
            <Check />
          </SolidIcon>
        ) : (
          <Walking width={48} height={48} />
        )}
        <div>
          <Text
            level={'s'}
            color={isComplete ? 'green' : 'secondary'}
            style={{
              opacity: isComplete ? 0.5 : 1,
            }}
          >
            Walkthrough
          </Text>
          <Text level={'l'}>{config.name}</Text>
        </div>
        <Block left />
        {loading ? (
          <Text level={'l'}>
            <LoadingIndicator />
          </Text>
        ) : !isComplete || config.allowRepeats ? (
          <Button
            variant={'chromeless'}
            onPress={() => {
              startTour()
            }}
            disabled={!canTour}
          >
            <Text level={'l'} color={canTour ? 'primary' : 'secondary'}>
              {canTour ? (
                <>
                  {isComplete ? 'Start Again' : 'Start Walkthrough'} <ArrowRight />
                </>
              ) : (
                "Can't Walkthrough as Teacher"
              )}
            </Text>
          </Button>
        ) : null}
      </Container>
      {canShowCompletion ? (
        <Block top={'l'}>{isComplete && segment ? segmentCompletionHost : null}</Block>
      ) : null}
    </>
  )

  // Completely replace the content with the segment completion.
  if (canShowCompletion && isComplete && segment && !config.allowRepeats && segmentCompletionHost) {
    content = segmentCompletionHost
  }

  return content
}

const Container = styled(SimpleWidget.WidgetContainer)`
  padding: ${s.size('m')};
  display: flex;
  align-items: center;
  gap: ${s.size('m')};
`
