import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import { Form } from '@thesisedu/react'
import React from 'react'

import { CalculatedWarning } from './CalculatedWarning'
import { QuestionContainer } from './QuestionContainer'
import { AssignmentTemplateFragment } from '../schema'

export interface AssignmentEditorProps {
  assignment?: Partial<AssignmentTemplateFragment>
  onChange?: (assignment: Partial<AssignmentTemplateFragment>) => void
}
export function AssignmentEditor({ assignment, onChange }: AssignmentEditorProps) {
  const configuration: AssignmentConfiguration = assignment?.configuration || {}
  const dummyForm = Form.useForm<any>()
  if (configuration.calculation?.identifier) {
    return <CalculatedWarning />
  } else {
    return (
      <Form form={dummyForm}>
        <QuestionContainer
          questions={configuration.questions || []}
          widgets={configuration.widgets}
          onChange={(questions, widgets) => {
            const newConfiguration = {
              ...assignment?.configuration,
              questions,
              widgets,
            }
            if (onChange) {
              onChange({
                ...assignment,
                configuration: newConfiguration,
              })
            }
          }}
          id={assignment?.id || 'new-assignment'}
        />
      </Form>
    )
  }
}
