import { Form, Text } from '@thesisedu/ui'
import React from 'react'

import { ConfigureCellColorsProps } from './ConfigureCellColors'

export function ConfigureStandardDeviation({ value }: ConfigureCellColorsProps) {
  return (
    <Form.NumberField
      name={'stdDistance'}
      label={'# of Deviations'}
      required
      description={
        <>
          Values {value.stdDistance} standard deviations{' '}
          <strong>
            <Text level={null} inline color={'blue'}>
              above
            </Text>
          </strong>{' '}
          the mean will be highlighted in{' '}
          <Text level={null} inline color={'blue'}>
            blue.
          </Text>
          <br />
          Values {value.stdDistance} standard deviations{' '}
          <strong>
            <Text level={null} inline color={'red'}>
              below
            </Text>
          </strong>{' '}
          the mean will be highlighted in{' '}
          <Text level={null} inline color={'red'}>
            red.
          </Text>
        </>
      }
      min={0}
      minValue={0}
      formatOptions={{ maximumFractionDigits: 1 }}
    />
  )
}
