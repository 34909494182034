import { styled } from '@thesisedu/react'

export const PageLoadingContainer: any = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  > div:last-child {
    z-index: 1;
    background: white;
    position: relative;
    min-height: 400px;
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
`
export const PageLoadingContainerBefore: any = styled.div`
  position: absolute;
  text-align: center;
  z-index: -0;
  top: ${props => props.theme['@size-xl']};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`
