import * as Sentry from '@sentry/react'
import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { SentryReplayHandler } from '../SentryReplayHandler'
import { SentryWebFeature } from '../SentryWebFeature'

export default function (feature: SentryWebFeature) {
  feature.hookManager.registerMutateHook<React.ReactElement, undefined>(ReactHooks.WrapApp, app => {
    const AppComponent = Sentry.withProfiler(
      () => (
        <>
          {app}
          <SentryReplayHandler />
        </>
      ),
      {
        name: 'FeatureApp',
      },
    )
    return <AppComponent />
  })
}
