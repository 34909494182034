import { onMutationError, useImpersonation } from '@thesisedu/feature-apollo-react'
import { Block, Body, Empty, LoadingIndicator, Result, VSpaced } from '@thesisedu/react'
import { Clock, SystemRestart } from '@thesisedu/react/icons'
import React from 'react'

import { ViewDetailedLogs } from './ViewDetailedLogs'
import {
  useUserDetailedLoggingEnabledQuery,
  useUpdateUserDetailedLoggingMutation,
} from '../../schema'
import { useViewerContext } from '../../useViewerContext'

export function DetailedLogsTab() {
  const { isImpersonating } = useImpersonation()
  if (isImpersonating) {
    return <DetailedLogsTabIsImpersonating />
  } else {
    return <Empty description={'This is not available in your current authentication scheme.'} />
  }
}

function DetailedLogsTabIsImpersonating() {
  const { withCoreAuth, coreAuthKey, isImpersonating } = useImpersonation()
  const { id } = useViewerContext(true)
  const { data, loading } = useUserDetailedLoggingEnabledQuery(
    withCoreAuth({
      variables: { id },
    }),
  )
  const [updateDetailedLogging, { loading: updateLoading }] = useUpdateUserDetailedLoggingMutation(
    withCoreAuth({
      onError: onMutationError('There was an error updating logging preferences.'),
    }),
  )
  const isEnabled =
    data?.node?.__typename === 'User' ? data?.node.detailedLoggingEnabled : undefined

  if (loading) {
    return <LoadingIndicator block />
  } else if (updateLoading) {
    return (
      <VSpaced style={{ width: '100%' }} align={'center'} space={'@size-xs'}>
        <LoadingIndicator block />
        <Body color={'@text-color-secondary'}>Updating detailed logging preferences...</Body>
      </VSpaced>
    )
  } else if (isEnabled) {
    return <ViewDetailedLogs />
  } else if (coreAuthKey && isImpersonating) {
    return (
      <Empty
        title={
          <>
            Detailed logging helps with <strong>troubleshooting server issues.</strong>
          </>
        }
        description={
          <>
            <div>
              If you enable detailed logging, all requests for enabled users are logged, and are
              accessible to developers in the platform for debugging purposes.
            </div>
            <Block marginTop={'@size-s'}>
              <Body color={'@orange'}>
                <Clock /> If you enable logs, it might take <strong>up to 2 hours</strong> for them
                to fully enable due to the lifetime of Lambda functions.
              </Body>
            </Block>
          </>
        }
        icon={<SystemRestart />}
        action={{
          title: 'Enable Detailed Logging',
          onClick() {
            updateDetailedLogging({
              variables: {
                input: {
                  id,
                  enabled: true,
                },
              },
            })
          },
        }}
      />
    )
  } else {
    return <Result.Warning description={'You need to be impersonating a user to use this.'} />
  }
}
