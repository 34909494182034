import { styled } from './styledTypes'

export interface AspectRatioBoxProps {
  ratio: number
}
export const AspectRatioBox = styled.div<AspectRatioBoxProps>`
  overflow: hidden;
  height: 0;
  padding-top: ${props => props.ratio * 100}%;
  position: relative;
  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
