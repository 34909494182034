import React from 'react'

import { NoteItemContextValue } from './types'

export const NoteItemContext = React.createContext<NoteItemContextValue | undefined>(undefined)

export function useNoteItemContext(): NoteItemContextValue | undefined
export function useNoteItemContext(require: false): NoteItemContextValue | undefined
export function useNoteItemContext(require: true): NoteItemContextValue
export function useNoteItemContext(require?: boolean): NoteItemContextValue | undefined {
  const context = React.useContext(NoteItemContext)
  if (!context && require) {
    throw new Error('NoteItemContext is required, yet not provided.')
  }
  return context
}
