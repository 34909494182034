import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { isVisibleToStudent } from '@thesisedu/feature-course-types'
import { PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'

import { Outline } from './Outline'
import { OutlineSegmentContextProvider } from './OutlineSegmentContext'
import { Segment } from './Segment'
import { OutlineContextProvider } from './context/OutlineContext'
import { useCourseContext } from '../contexts/CourseContext'
import { GroupSegment } from '../types'

export interface StudentOutlineProps {
  hideTitle?: boolean
}
export function StudentOutline({
  children: _origChildren,
  hideTitle,
}: React.PropsWithChildren<StudentOutlineProps>) {
  useBreadcrumb({ title: 'Course' })
  const { bakedCourseConfiguration, courseVersion } = useCourseContext(false) || {}
  const cls = useSelectedClass(true)
  let children
  if (bakedCourseConfiguration && courseVersion) {
    children = (
      <OutlineSegmentContextProvider>
        <OutlineContextProvider
          renderSegment={({ item, index }) => (
            <Segment
              segment={item.segment}
              readOnly
              data-index={index}
              className={'hover-container content-container'}
            />
          )}
        >
          <Outline
            segments={bakedCourseConfiguration.segments as GroupSegment[]}
            courseName={courseVersion.course.label || courseVersion.course.name}
            courseOrClassId={cls.id}
            segmentFilter={segment => {
              return segment.visibleOverride === true && isVisibleToStudent(segment)
            }}
            hideEmptyGroups
          />
        </OutlineContextProvider>
      </OutlineSegmentContextProvider>
    )
  } else {
    children = <Empty description={'There is no course associated with this class.'} />
  }

  return (
    <>
      <PageHead title={`Outline - ${cls.name}`} />
      {hideTitle ? null : <PageHeader title={'Course Outline'} />}
      {_origChildren}
      {children}
    </>
  )
}
