import { BlockSpin, NotFound, BodySmall, styled } from '@thesisedu/web'
import React from 'react'

import { UseInClassButton } from './UseInClassButton'
import { CUSTOM_SEGMENT_HEIGHT, CustomSegment } from '../custom_content/CustomSegment'
import { OutlineSearch } from '../outline/OutlineSearch'
import { OutlineSearchContextProvider } from '../outline/OutlineSearchContext'
import { OutlineTerm } from '../outline/OutlineTerm'
import { OutlineContextProvider } from '../outline/context/OutlineContext'
import { SegmentType, useListBuiltSegmentsQuery } from '../schema'

export interface ExploreTopicProps {
  topicName: string
}
export function ExploreTopic({ topicName }: ExploreTopicProps) {
  const { data, loading } = useListBuiltSegmentsQuery({
    variables: { first: 1, types: [SegmentType.Topic], name: topicName },
  })
  const topic = data?.segments.edges[0]?.node
  if (loading) {
    return <BlockSpin />
  } else if (topic) {
    const segments = topic.built.childSegments || []
    return (
      <OutlineSearchContextProvider segments={segments}>
        <OutlineContextProvider
          renderSegment={props => (
            <CustomSegment
              noMargin
              segment={props.item.segment}
              rawId={props.item.segment.id}
              useInClass
            />
          )}
          segmentContainerProps={() => ({
            style: {
              display: 'flex',
              alignItems: 'center',
            },
          })}
          groupActions={props => <UseInClassButton rawId={props.item.segment.id} children={null} />}
        >
          <div>
            <Header>
              <OutlineSearch
                courseName={topicName}
                placeholder={`Search ${topicName || 'Content'}...`}
                size={'large'}
                style={{ width: '100%' }}
              />
              <UseInClassButton rawId={topic.built.id} size={'large'}>
                Use Topic in Class
              </UseInClassButton>
            </Header>
            <BodySmall isBlock color={'@text-color-secondary'}>
              Click on a piece of content to preview and use it in your class.
            </BodySmall>
            <OutlineTerm segments={segments} segmentHeight={CUSTOM_SEGMENT_HEIGHT} />
          </div>
        </OutlineContextProvider>
      </OutlineSearchContextProvider>
    )
  } else {
    return <NotFound description={'That topic could not be found.'} />
  }
}

const Header = styled.div`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
