import { Modal } from '@thesisedu/react'
import React from 'react'

import { useStudentForfeitAssignmentMutation } from '../queries/useStudentForfeitAssignmentMutation'

type ForfeitFn = (assignmentId: string, classId: string) => Promise<void>

export function useStudentForfeitAssignment(): [ForfeitFn, { loading: boolean }] {
  const [forfeit, { loading }] = useStudentForfeitAssignmentMutation()
  return [
    async (assignmentId, classId) => {
      Modal.confirm({
        title: 'Are you sure you want to show answers?',
        width: 600,
        content: (
          <>
            <p>You still have attempts left on this assignment.</p>
            <p>
              Once you choose to show answers,{' '}
              <strong>you will not be able to attempt this assignment any more.</strong>
            </p>
            <p>Are you sure you would like to continue?</p>
          </>
        ),
        okButtonProps: { danger: true },
        okText: 'Yes, show answers',
        cancelText: 'No, go back',
        async onOk() {
          await forfeit({
            variables: {
              classId,
              input: {
                id: assignmentId,
                classId,
                forfeit: true,
              },
            },
          })
        },
      })
    },
    { loading },
  ]
}
