import { getSegmentName } from '@thesisedu/feature-course-types'
import { Body, BodyExtraSmall, styled } from '@thesisedu/react'
import { s } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { Segment } from '../../types'
import { useOutlineListContext } from '../OutlineListContext'
import { DEPTH_INDENT_PX } from '../types'

export interface DragPreviewOutlineItemProps {
  segment?: Segment
  depth: number
  minimumDepth?: number
  [key: string]: any
}
export const DragPreviewOutlineItem = React.forwardRef(
  (
    { segment: _segment, depth, minimumDepth, ...rest }: DragPreviewOutlineItemProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { selectedTerm } = useOutlineListContext(false) ?? {}
    const segment = _segment || selectedTerm
    if (!segment) return null
    return (
      <Container
        {...rest}
        $disabled={rest.disabled}
        $minimumDepth={minimumDepth ?? 0}
        ref={ref}
        className={`OutlineItem ${rest.className}`}
      >
        <OuterContainer>
          {minimumDepth != null
            ? new Array(depth - minimumDepth)
                .fill('')
                .map((_, i) => <DepthIndent key={i} children={<ArrowRight />} />)
            : null}
          <div>
            <BodyExtraSmall color={'@text-color-secondary'}>Moving to</BodyExtraSmall>
            <Body>{getSegmentName(segment)}</Body>
          </div>
        </OuterContainer>
      </Container>
    )
  },
)

const DepthIndent = styled.div`
  width: calc(${DEPTH_INDENT_PX}px - ${s.size('xxs')});
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${s.size('font.s')};
`
const OuterContainer = styled.div`
  min-height: 40px;
  height: 60px;
  max-height: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
  gap: ${s.size('xxs')};
  > div {
    background: ${props => props.theme['@gray-1']};
    padding: 0 ${props => props.theme['@size-s']};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: ${props => props.theme['@border-radius-base']};
    > * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    > :first-child {
      line-height: 1;
    }
    &:last-child {
      flex: 1;
    }
  }
`
const Container = styled.div<{ $disabled?: boolean; $minimumDepth: number }>`
  padding-left: ${props => props.$minimumDepth * DEPTH_INDENT_PX}px;
  width: 100%;
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  flex: 1;
`
