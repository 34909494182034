import React from 'react'

import { s, styled } from '../../'
import Block, { BlockProps } from '../Block'

export interface HSpacedProps extends Omit<BlockProps, 'wrap'> {
  /** Pass false to not have any space. */
  space?: s.AnySize | false
  align?: 'flex-start' | 'center' | 'flex-end' | 'baseline' | 'stretch'
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'stretch'
  wrap?: boolean
}
function _HSpaced(
  { space, align, justify, wrap, ...rest }: HSpacedProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <__HSpaced
      $space={space}
      $align={align}
      $justify={justify}
      {...rest}
      ref={ref}
      style={{
        flexWrap: wrap ? 'wrap' : 'nowrap',
        ...rest.style,
      }}
    />
  )
}
export const HSpaced = React.forwardRef(_HSpaced)

const __HSpaced = styled(Block)<{
  $space: HSpacedProps['space']
  $align: HSpacedProps['align']
  $justify: HSpacedProps['justify']
}>`
  display: flex;
  align-items: ${props => props.$align || 'center'};
  justify-content: ${props => props.$justify || 'flex-start'};
  gap: ${props => (props.$space === false ? 0 : s.size(props.$space || 's'))};
  > * {
    min-width: 0;
  }
  &:empty {
    display: none;
  }
`
