import React from 'react'
import {
  Collection,
  TableHeader as RATableHeader,
  TableHeaderProps as RATableHeaderProps,
  useTableOptions,
} from 'react-aria-components'

import { Checkbox } from './Checkbox'
import { Column } from './Column'
import { styled } from '../..'

export interface TableHeaderProps<T extends object> extends RATableHeaderProps<T> {}
export function TableHeader<T extends object>({ columns, children }: TableHeaderProps<T>) {
  const { selectionBehavior, selectionMode, allowsDragging } = useTableOptions()

  return (
    <_TableHeader>
      {allowsDragging ? <Column style={{ width: 0 }} /> : null}
      {selectionBehavior === 'toggle' ? (
        <Column style={{ width: 0 }}>{selectionMode === 'multiple' ? <Checkbox /> : null}</Column>
      ) : null}
      <Collection items={columns}>{children}</Collection>
    </_TableHeader>
  )
}

const _TableHeader = styled(RATableHeader)`
  &:after {
    content: '';
    display: table-row;
    height: var(--table-size-xxs);
  }
`
