import { styled, Tooltip } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

export function SegmentNotInLicense() {
  return (
    <Tooltip
      title={
        'This content is not in your license. Click on "Not in License" to reach out to sales to add this content to your license.'
      }
    >
      <Link to={`/teacher/resources`} style={{ pointerEvents: 'all' }}>
        <Container>Not in License</Container>
      </Link>
    </Tooltip>
  )
}

const Container = styled.div`
  margin-left: ${props => props.theme['@size-xxs']};
  display: inline-block;
  color: ${props => props.theme['@gray-6']} !important;
  padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
  background: ${props => props.theme['@gray-2']};
  border-radius: ${props => props.theme['@border-radius-base']};
  font-size: 12px;
`
