import { useCachedStudentUserCallback } from '@thesisedu/feature-classes-react'
import { styled } from '@thesisedu/web'
import React from 'react'

const Container = styled.div`
  display: block !important;
  color: ${props => props.theme['@gray-4']};
  line-height: 1.3;
  margin-bottom: 10px;
  padding: 0 !important;
`

export interface GroupMembersProps {
  groupMemberIds: string[]
  isGroupLeader?: boolean
  groupLeader?: string
  areAnswersShared?: boolean
  classId: string
}
export function GroupMembers({
  groupMemberIds,
  isGroupLeader,
  groupLeader,
  areAnswersShared,
  classId,
}: GroupMembersProps) {
  const getStudent = useCachedStudentUserCallback()

  const studentNames = groupMemberIds.map(groupMemberId => {
    const isLeader = groupMemberId === groupLeader
    const student = getStudent(groupMemberId, classId)
    const studentName = student ? student.user.name || student.user.username : 'Student'
    const leaderText = isLeader ? ' (Leader)' : ''
    return `${studentName}${leaderText}`
  })
  const studentText = `You are working with: ${studentNames.join(', ')}.`
  const sharingText = areAnswersShared
    ? isGroupLeader
      ? 'You are in charge of entering answers for your classmates.'
      : 'Only the group leader can enter answers.'
    : 'Answers are entered separately.'
  return (
    <Container>
      <span>{studentText}</span>
      <br />
      <span>{sharingText}</span>
    </Container>
  )
}
