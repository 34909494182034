import { OutlineItemType, SegmentOutlineItem } from '../outline/types'
import { Segment } from '../types'

export function getForeignOutlineItem(segment: Segment): SegmentOutlineItem {
  return {
    depth: 0,
    id: segment.id,
    parentIds: [],
    place: {
      maximumAllowedDepth: 0,
      parentSegment: segment, // This _might_ cause problems?
    },
    segment,
    type: OutlineItemType.SEGMENT,
    collapsible: false,
    collapsed: false,
    isForeign: true,
  }
}
