import { useStudentUser } from '@thesisedu/feature-classes-react'
import {
  BodyExtraSmall,
  BodySmall,
  Button,
  HSpaced,
  Spacer,
  styled,
  VSpaced,
} from '@thesisedu/react'
import { InfoEmpty } from '@thesisedu/react/icons'
import React from 'react'

import { FullAssignment } from './types'
import { useTeacherForfeitAssignmentMutation } from '../queries/useTeacherForfeitAssignmentMutation'

export interface ForfeitAttemptsWarningProps {
  studentId: string
  classId: string
  assignment: FullAssignment
}
export function ForfeitAttemptsWarning({
  studentId,
  classId,
  assignment,
}: ForfeitAttemptsWarningProps) {
  const [forfeit, { loading }] = useTeacherForfeitAssignmentMutation()
  const didForfeit = assignment.students.edges.some(
    edge => edge.node.id === studentId && edge.classId === classId && edge.forfeitAttempts,
  )
  const name = useStudentUser(studentId, classId).student?.user.firstName

  if (didForfeit) {
    return (
      <Container space={'@size-xs'} align={'stretch'}>
        <BodySmall color={'@text-color'}>
          <InfoEmpty /> {name || 'The student'} chose to forfeit their remaining attempts to see the
          correct answers.
        </BodySmall>
        <BodyExtraSmall color={'@text-color-secondary'}>
          This means they will not be able to attempt this assignment anymore, and they have likely
          already seen the correct answers. You may reset this for them and allow them to continue
          working if you would like.
        </BodyExtraSmall>
        <Spacer vertical={'@size-xs'} />
        <HSpaced justify={'flex-end'}>
          <Button
            size={'small'}
            loading={loading}
            onClick={e => {
              e.preventDefault()
              return forfeit({
                variables: {
                  input: {
                    classId,
                    id: assignment.id,
                    forfeit: false,
                    studentId,
                  },
                },
              })
            }}
          >
            Allow {name || 'Student'} to Continue
          </Button>
        </HSpaced>
      </Container>
    )
  } else {
    return null
  }
}

const Container = styled(VSpaced)`
  background: ${props => props.theme['@gray-2']};
  padding: calc(${props => props.theme['@size-m']} / 2);
  border-radius: ${props => props.theme['@border-radius-base']};
  margin: 0 calc(${props => props.theme['@size-m']} / 2);
  margin-bottom: ${props => props.theme['@size-s']};
  button {
    background: ${props => props.theme['@gray-3']};
    font-size: ${props => props.theme['@font-size-sm']};
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
    height: 24px;
  }
`
