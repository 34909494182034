import { BodyLarge, BodySmall, styled } from '@thesisedu/react'
import pluralize from 'pluralize'
import React from 'react'

import { StandardSetFragment } from '../schema'

export interface StandardSetListItemProps {
  standardSet: StandardSetFragment
  onClick: () => void
  isSelected?: boolean
}
export function StandardSetListItem({
  standardSet,
  onClick,
  isSelected,
}: StandardSetListItemProps) {
  return (
    <Container selected={isSelected} onClick={onClick}>
      <BodyLarge>{standardSet.name}</BodyLarge>
      <BodySmall color={'@text-color-secondary'}>
        {pluralize('tag', standardSet.totalTags.totalCount, true)}
      </BodySmall>
    </Container>
  )
}

const Container = styled.div<{ selected?: boolean }>`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition:
    background 0.25s linear,
    color 0.25s linear;
  cursor: pointer;
  color: ${props => props.theme['@gray-6']};
  &:hover {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
  ${props => (props.selected ? '&' : '&.noop')} {
    background: ${props => props.theme['@primary-color-extra-light']};
    color: ${props => props.theme['@primary-color']};
  }
`
