import { Button, ButtonProps } from 'antd'
import React from 'react'

import { styled } from './styledTypes'

export function ExtraSmallButton(props: Partial<ButtonProps>) {
  return <StyledExtraSmallButton shape={'circle'} size={'small'} {...props} />
}

const StyledExtraSmallButton = styled(Button)`
  height: 24px !important;
  width: 24px !important;
  min-width: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    font-size: 12px !important;
    display: block !important;
    height: 12px;
    width: 12px;
  }
`
