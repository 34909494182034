import { SimpleSheetMusicEditor } from '@thesisedu/feature-sheet-music-react'
import { UserRoutesHook, UserTopItemsHook } from '@thesisedu/feature-users-react'
import { FeatureWeb, Shell } from '@thesisedu/feature-web'
import { Block } from '@thesisedu/react'
import { PageHead, styled } from '@thesisedu/web'
import React from 'react'
import { Route } from 'react-router-dom'

function TeacherCompose() {
  const [value, setValue] = React.useState('')
  return (
    <HeaderContainer>
      <PageHead title={'Compose'} />
      <SimpleSheetMusicEditor value={value} onChange={setValue} />
    </HeaderContainer>
  )
}

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        return [
          ...routes,
          <Route key={'compose'} path={'compose/*'} element={<TeacherCompose />} />,
        ]
      } else return routes
    },
  )
  web.deps.hookManager.registerMutateHook<UserTopItemsHook>(
    'feature-users-react:top-items',
    (items, { group }) => {
      if (group === 'TEACHER') {
        return [
          ...items,
          {
            weight: 2,
            element: (
              <Shell.TopMenuItem key={'compose'} name={'Compose'} path={'/teacher/compose'} />
            ),
          },
        ]
      } else return items
    },
  )
}

const HeaderContainer = styled(Block)`
  .sheet-music-viewer {
    padding-bottom: 100px;
  }
  .sheet-music-controls {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    > div:last-child {
      max-width: 1400px;
      margin: 0 auto;
    }
  }
`
