import { useSelectedClassId, useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { Dropdown, Modal, Text, useToast } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { useUpdateClassMutation } from '../../classes/useUpdateClassMutation'
import { useCourseContext } from '../../contexts/CourseContext'
import {
  ClassFragmentWithPermissions,
  SegmentActionComponentProps,
  SegmentActionResource,
} from '../../types'

export function RemoveCustomSegment({ segment }: SegmentActionComponentProps) {
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canChangeCourseStructure = cls?.canChangeCourseStructure
  const id = useSelectedClassId(true)
  const toast = useToast()
  const [updateClass] = useUpdateClassMutation(id, {
    onCompleted: () => {
      toast({
        title: 'Content removed successfully.',
        status: 'success',
      })
    },
  })
  const { segmentMetadata } = useCourseContext(true)
  return (
    <Modal.Confirm
      title={'Are you sure?'}
      onConfirm={async () => {
        const dismiss = toast({
          title: 'Removing content...',
          loading: true,
        })
        try {
          await updateClass(
            {
              segmentMetadata: {
                segments: (segmentMetadata?.segments || []).filter(s => s.id !== segment.id),
                structureOverrides: (segmentMetadata?.structureOverrides || []).filter(
                  s => s.id !== segment.id,
                ),
              },
            },
            true,
          )
        } finally {
          dismiss()
        }
      }}
      confirm={{ status: 'danger', children: 'Yes, remove' }}
      cancel={{ children: 'No, cancel' }}
      trigger={
        <Dropdown.Item
          disabled={!canChangeCourseStructure}
          icon={<Trash />}
          danger={canChangeCourseStructure} // Danger + disabled does not show the proper styles
        >
          Remove from Class
        </Dropdown.Item>
      }
    >
      <Text>
        This will remove it from your class, but you will still be able to add it back later.
      </Text>
    </Modal.Confirm>
  )
}

export const RemoveCustomSegmentAction: SegmentActionResource = {
  identifier: 'remove-custom-segment',
  type: 'SegmentAction',
  component: RemoveCustomSegment,
  group: 'My Content',
  weight: 499,
  filter: (segment, { status }) => {
    return !!status?.isContentCustom(segment.id)
  },
  handler: () => {},
}
