import { PerformanceConfig } from '@thesisedu/feature-assignments-core'
import {
  MediaViewWithSidebar,
  CommentsPlugin,
  BookmarksPlugin,
} from '@thesisedu/feature-media-react'
import React from 'react'

import { PerformanceContent } from './PerformanceContent'
import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'

export function PerformanceAnswer(props: QuestionProps<PerformanceConfig>) {
  const data = useSubmissionData(props.question.id)
  return data ? (
    <MediaViewWithSidebar mediaId={data} allowDownload defaultSidebarTab={'Comments'}>
      <CommentsPlugin />
      <BookmarksPlugin />
    </MediaViewWithSidebar>
  ) : (
    <PerformanceContent {...props} disabled />
  )
}
