import { styled, Body } from '@thesisedu/web'
import React from 'react'

import { NOTES } from './Note'
import { HEIGHTS } from './types'

export interface NoteTextProps {
  pitch: number
  color: string
}
export function NoteText({ pitch, color }: NoteTextProps) {
  const noteLabel = NOTES[(pitch + 21) % 7]
  return (
    <Container>
      <Body color={color}>{noteLabel}</Body>
    </Container>
  )
}

const Container = styled.div`
  height: ${HEIGHTS.notes}px;
  display: flex;
  align-items: center;
  *,
  p {
    font-size: ${props => props.theme['@size-xl']} !important;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
  }
`
