/* eslint-disable import/order */
import { debug } from '@thesisedu/feature'
import { WebProvider, Routes } from '@thesisedu/feature-web'
import React from 'react'

import web from './feature'
import './App.less'
import { KeyboardButtonContextProvider } from './components/keyboard/KeyboardButtonContext'

debug.enable('*')
const d = debug('prodigies-academy')
d('debug is enabled. features may already be constructed.')

export const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  d(
    'app initializing with environment %s and release %s',
    process.env.REACT_APP_ENVIRONMENT,
    process.env.REACT_APP_RELEASE,
  )
  return (
    <WebProvider featureWeb={web}>
      <div className={'content'}>
        <KeyboardButtonContextProvider>
          <Routes />
        </KeyboardButtonContextProvider>
      </div>
    </WebProvider>
  )
}
