import { InfiniteQuery } from '@thesisedu/feature-apollo-react/dist/InfiniteQuery'
import { AntIconWrapper, Block, DATE_FORMATS, HSpaced } from '@thesisedu/web'
import { Table, Input } from 'antd'
import { Search } from 'iconoir-react'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DeleteWidgetTemplateButton } from './DeleteWidgetTemplateButton'
import {
  WidgetTemplateFragment,
  WidgetTemplatesDocument,
  WidgetTemplatesQuery,
  WidgetTemplatesQueryVariables,
} from '../schema'

export function WidgetTemplateList() {
  const [name, setName] = React.useState('')
  const [filterName, setFilterName] = React.useState<string>(name)
  const timeoutRef = React.useRef<any>()
  return (
    <>
      <Block marginBottom={'@size-s'} marginTop={'@size-l'}>
        <HSpaced justify={'flex-end'}>
          <Input
            suffix={<AntIconWrapper children={<Search />} />}
            placeholder={'Find a Template...'}
            value={name}
            style={{ maxWidth: 300 }}
            onChange={e => {
              setName(e.target.value)
              clearTimeout(timeoutRef.current)
              timeoutRef.current = setTimeout(() => {
                setFilterName(e.target.value)
              }, 500)
            }}
          />
        </HSpaced>
      </Block>
      <InfiniteQuery<WidgetTemplateFragment, WidgetTemplatesQuery, WidgetTemplatesQueryVariables>
        document={WidgetTemplatesDocument}
        resultPath={'widgetTemplates'}
        queryOpts={{
          fetchPolicy: 'cache-and-network',
        }}
        variables={
          filterName
            ? {
                name: filterName,
              }
            : {}
        }
        children={({ data }) => {
          const templates = data?.widgetTemplates.edges.map(edge => edge.node) || []
          return (
            <Table<WidgetTemplateFragment>
              dataSource={templates}
              pagination={false}
              columns={[
                {
                  title: 'Name',
                  dataIndex: ['name'],
                  render: (name, record) => {
                    return <Link to={record.id}>{name}</Link>
                  },
                },
                {
                  title: 'Created',
                  dataIndex: ['createdAt'],
                  render: created => moment(created).format(DATE_FORMATS.FULL),
                },
                {
                  title: 'Actions',
                  render: (_, record) => {
                    return (
                      <HSpaced style={{ display: 'inline-flex' }}>
                        <Link to={record.id}>Edit</Link>
                        <DeleteWidgetTemplateButton widgetTemplateId={record.id} />
                      </HSpaced>
                    )
                  },
                },
              ]}
            />
          )
        }}
      />
    </>
  )
}
