import * as React from 'react'

function SvgC(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#E82627'}
          d={
            'M255.052 194.647a65.104 65.104 0 01.848 14.676 80.53 80.53 0 01-2.714 18.454 53.411 53.411 0 01-14.966 24.682c-8.43 8.187-18.477 13.845-29.043 18.654a156.48 156.48 0 01-42.414 12.246 239.166 239.166 0 01-31.498 2.619c-2.414.05-4.829 0-7.223 0a244.429 244.429 0 01-36.078-2.19c-21.152-2.839-41.186-9.117-59.574-20.143a74.139 74.139 0 01-20.084-17.095 53.735 53.735 0 01-10.976-25.2 89.423 89.423 0 01-1.207-20.074 52.088 52.088 0 0112.501-30.86c3.662-4.409 8.072-7.997 12.452-11.636a25.196 25.196 0 004.48-4.389 7.625 7.625 0 001.855-4.368 32.042 32.042 0 012.584-11.087c5.937-14.455 10.526-29.45 17.022-43.696A87.814 87.814 0 0167.57 70.347c.838-.86 1.686-1.72 2.584-2.529 2.863-2.729 5.986-5.168 8.83-7.997 4.19-4.139 9.578-6.428 15.075-8.328a95.724 95.724 0 0125.502-4.758 114.767 114.767 0 0129.593 1.91c6.694 1.349 13.28 3.168 19.206 6.607 11.454 6.618 21.51 14.855 28.844 26.062a121.643 121.643 0 0111.514 22.782c4.56 11.837 8.92 23.743 13.519 35.569a29.148 29.148 0 012.235 9.637 9.634 9.634 0 003.222 6.438c2.904 2.859 6.236 5.268 9.3 7.997 9.348 8.338 15.644 18.514 18.058 30.91z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.999 210.852a73.217 73.217 0 0017.959 32.72c7.682 7.917 17.65 13.995 28.605 15.994a43.08 43.08 0 0017.959-.63c2.314-.59 1.337-4.178-.998-3.588-21.391 5.408-41.695-8.658-52.24-26.502a72.946 72.946 0 01-7.773-18.934c-.599-2.309-4.17-1.33-3.572 1l.06-.06zm234.066.51c-5.478 21.163-21.142 41.896-43.66 45.985a38.568 38.568 0 01-16.353-.57c-2.315-.59-3.303 3-.998 3.579a44.889 44.889 0 0031.339-3.909c10.426-5.278 18.956-13.995 24.943-23.992a76.148 76.148 0 008.33-20.133c.6-2.31-2.993-3.3-3.571-1l-.03.04z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.117 52.263h25.532V62.29h-25.532z'} />
        <path
          fill={'#E82627'}
          d={
            'M131.165 3.129c-17.26 0-31.351 11.837-31.351 26.481 0 10.557 7.424 19.941 18.65 24.194a36.335 36.335 0 0012.744 2.269c3.331.007 6.653-.44 9.87-1.329 12.709-3.545 21.507-13.611 21.507-25.134 0-14.66-14.131-26.481-31.42-26.481z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.165 5.269c16.183 0 29.28 10.955 29.28 24.341 0 10.497-8.106 19.771-19.94 23.072a34.65 34.65 0 01-9.298 1.25 34.193 34.193 0 01-11.992-2.132c-10.434-3.953-17.261-12.583-17.261-22.19 0-13.372 13.058-24.341 29.21-24.341z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M130.138 11.287c-5.672 0-10.322 2.123-13.9 6.35-2.904 3.448-4.362 7.387-4.362 11.795 0 4.915 1.745 9.13 5.219 12.612 3.491 3.454 7.72 5.186 12.654 5.186 3.115 0 6.098-.762 8.939-2.282l.77-.425v-7.832l-1.05.886a24.773 24.773 0 01-2.284 1.73 12.95 12.95 0 01-1.714.967l-.329.145c-1.171.545-2.584.816-4.267.816-3.256 0-5.985-1.136-8.226-3.422-2.243-2.288-3.359-5.085-3.359-8.425 0-3.385 1.118-6.226 3.363-8.558 2.229-2.343 4.948-3.505 8.2-3.505 2.837 0 5.595 1.074 8.29 3.24l1.376 1.13v-7.69l-.317-.185c-2.889-1.687-5.893-2.533-9.003-2.533z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.803 165.487h.17c.079 18.444-14.268 33.449-32.157 33.739-17.96-.12-32.397-14.995-32.486-33.44h.18c0 6.658 14.197 11.997 31.817 11.997h.768c17.59-.19 31.737-5.639 31.708-12.296z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.427 125.03c0 4.417-3.574 7.997-7.982 7.997s-7.982-3.58-7.982-7.997 3.574-7.998 7.982-7.998 7.982 3.58 7.982 7.998zm59.344 0c0 4.417-3.573 7.997-7.982 7.997-4.408 0-7.981-3.58-7.981-7.997s3.573-7.998 7.981-7.998c4.409 0 7.982 3.58 7.982 7.998zM99.178 95.01a18.781 18.781 0 019.169.27.79.79 0 00.589-1.45c-6.511-3.661-14.726-1.861-19.117 4.188a.79.79 0 00.064 1.084.787.787 0 001.083-.004 18.623 18.623 0 018.212-4.089zm61.589-.7a18.7 18.7 0 017.872 4.788.79.79 0 001.113.115.793.793 0 00.114-1.115c-3.88-6.39-11.918-8.86-18.707-5.748a.79.79 0 00.479 1.49 18.682 18.682 0 019.13.47zm-30.949 57.84a10.36 10.36 0 014.988 1.25.438.438 0 00.49-.72 8.18 8.18 0 00-10.876 0 .44.44 0 00.489.72 10.46 10.46 0 014.909-1.25z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgC = React.memo(SvgC)
export default MemoSvgC
