import { Form$ } from '@thesisedu/ui'

import { _getAssignInput } from './getAssignInput'
import { _getAssignedClassIds } from './getAssignedClassIds'
import { SettingsFormValues } from './types'
import { AssignmentAssignedStudentsFragment, BasicAssignmentFragment } from '../schema'

export function useAssignedClassIds(
  form: Form$.FormInstance<SettingsFormValues>,
  assignment: BasicAssignmentFragment & AssignmentAssignedStudentsFragment,
) {
  const assignmentMode = form.watch('assignmentMode') ?? assignment.assignmentMode
  return _getAssignedClassIds(
    assignmentMode,
    _getAssignInput({
      ...assignment,
      assignmentMode,
    }),
  )
}
