import * as ReactCheckbox from '@radix-ui/react-checkbox'
import { Random } from '@thesisedu/feature-utils'
import React from 'react'

import { styled, s } from '../../'
import { Check } from '../../../icons'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusRing } from '../FocusRing/FocusRing'
import HSpaced from '../HSpaced'
import { HSpacedProps } from '../HSpaced/HSpaced'
import Text from '../Text'

export interface CheckboxProps
  extends Omit<ReactCheckbox.CheckboxProps, 'onChange'>,
    ExtendedBareFieldProps {
  size?: s.SharedVariants.Size.Type
  className?: string
  style?: React.CSSProperties
  onChange?: ReactCheckbox.CheckboxProps['onCheckedChange']
  align?: HSpacedProps['align']
}
function _Checkbox(
  {
    size = s.SharedVariants.Size.defaultValue,
    className,
    style,
    onChange,
    align,
    ...props
  }: CheckboxProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const [fieldId] = React.useState(Random.id())
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const { label, ...restBareField } = bareFieldProps
  return (
    <BareField {...restBareField} style={style}>
      <HSpaced space={'xs'} align={align}>
        <FocusRing>
          <_Root
            onCheckedChange={onChange}
            {...rest}
            id={fieldId}
            ref={ref}
            className={s.variants<typeof _variants>(
              className,
              { danger: !!restBareField.error },
              size,
            )}
          >
            <_Indicator>
              <Check />
            </_Indicator>
          </_Root>
        </FocusRing>
        {label ? (
          <Text<HTMLLabelElement>
            as={'label'}
            htmlFor={fieldId}
            color={props.disabled ? 'secondary' : undefined}
            level={s.SharedVariants.Size.TextLevelMap[size]}
          >
            {label}
          </Text>
        ) : null}
      </HSpaced>
    </BareField>
  )
}
export const Checkbox = React.forwardRef(_Checkbox)

const { css } = s

const status = {
  danger: css`
    --checkbox-color: ${s.color('red')};
  `,
} satisfies s.Variants

const _variants = s.merge(s.SharedVariants.Size.Variants, status)
const _Root = s.styledWithVariants(
  ReactCheckbox.Root,
  'CheckboxRoot',
  css`
    all: unset;
    flex-shrink: 0;
    background: ${s.color('gray.element')};
    width: 1.5em;
    height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${s.var('radii.1')};
    &:hover:not([data-disabled]) {
      background: ${s.color('gray.hoverElement')};
    }
    &[data-disabled] {
      background: ${s.color('gray.subtle')};
    }
  `,
  {
    ...s.SharedVariants.Size.Variants,
    ...status,
  },
)
const _Indicator = styled(ReactCheckbox.Indicator)`
  color: white;
  background: var(--checkbox-color, ${s.color('primary')});
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.1em;
  height: 1.1em;
  border-radius: ${s.var('radii.1')};
  &[data-state='indeterminate'] {
    width: 1em;
    height: 1em;
    > * {
      display: none;
    }
  }
`
