import { Modal, useToast, Button, Dropdown, Button$, Dropdown$ } from '@thesisedu/ui'
import { Archive } from '@thesisedu/ui/icons'
import React from 'react'

import { useArchiveClassMutation } from '../queries/useArchiveClassMutation'
import { useUnarchiveClassMutation } from '../queries/useUnarchiveClassMutation'
import { BasicClassFragment } from '../schema'
import { useTeacherClass } from '../useClass'

export interface UseArchiveClassOpts {
  classId: string
  classFragment?: BasicClassFragment
  onComplete?: () => void
}
export interface UseArchiveClass {
  loading: boolean
  archive: () => any
  unarchive: () => any
  isArchived: boolean
  disabled: boolean
  confirm: React.ReactNode
  forceVisible: boolean
}
export function useArchiveClass({
  classFragment,
  classId,
  onComplete,
}: UseArchiveClassOpts): UseArchiveClass | null {
  const { cls: loadedClass } = useTeacherClass(classId, false)
  const cls = classFragment || loadedClass
  const canUpdate = (cls as any)?.canUpdate
  const isArchived = !!cls?.archivedAt
  const toast = useToast()
  const { confirm, modal, visible } = Modal.useConfirmModal()
  const [archive, { loading: archiveLoading }] = useArchiveClassMutation({
    onCompleted: () => {
      toast({ status: 'success', title: 'Class archived successfully.' })
      if (onComplete) {
        onComplete()
      }
    },
    refetchQueries: ['minimalTeacherClasses'],
    awaitRefetchQueries: !onComplete,
  })
  const [unarchive, { loading: unarchiveLoading }] = useUnarchiveClassMutation({
    onCompleted: () => {
      toast({ status: 'success', title: 'Class restored successfully.' })
      if (onComplete) {
        onComplete()
      }
    },
    refetchQueries: ['minimalTeacherClasses'],
    awaitRefetchQueries: !onComplete,
  })

  return canUpdate
    ? {
        loading: unarchiveLoading || archiveLoading,
        confirm: modal,
        forceVisible: visible,
        archive: () => {
          confirm({
            title: 'Are you sure you want to archive this class?',
            confirm: {
              children: 'Yes, archive class',
              status: 'danger',
            },
            cancel: {
              children: 'No, keep',
            },
            onConfirm() {
              return archive({ variables: { input: { id: classId } } })
            },
          })
        },
        unarchive: () => {
          return unarchive({ variables: { input: { id: classId } } })
        },
        isArchived,
        disabled: !cls,
      }
    : null
}

export interface ArchiveClassButtonProps extends UseArchiveClassOpts {
  buttonProps?: Partial<Button$.ButtonProps>
}
export const ArchiveClassButton: React.FC<React.PropsWithChildren<ArchiveClassButtonProps>> = ({
  buttonProps,
  ...opts
}) => {
  const archiveResult = useArchiveClass(opts)
  if (!archiveResult) return null
  const { isArchived, archive, unarchive, confirm, loading, disabled } = archiveResult

  let children
  if (isArchived) {
    children = (
      <Button
        icon={<Archive />}
        onPress={unarchive}
        loading={loading}
        disabled={disabled}
        children={'Restore Class'}
        {...buttonProps}
      />
    )
  } else {
    children = (
      <Button
        icon={<Archive />}
        onPress={archive}
        loading={loading}
        disabled={disabled}
        status={'danger'}
        children={'Archive Class'}
        {...buttonProps}
      />
    )
  }

  return (
    <>
      {confirm}
      {children}
    </>
  )
}

export type ArchiveClassMenuItemProps = UseArchiveClassOpts & Dropdown$.DropdownMenuItemProps
export function ArchiveClassMenuItem({
  classId,
  classFragment,
  onComplete,
  ...itemProps
}: ArchiveClassMenuItemProps) {
  const archiveResult = useArchiveClass({
    classId,
    classFragment,
    onComplete,
  })
  if (!archiveResult) return null
  const { isArchived, archive, unarchive, loading, disabled, confirm, forceVisible } = archiveResult
  if (isArchived) {
    return (
      <Dropdown.Item
        {...itemProps}
        forceMount={forceVisible}
        onClick={() => unarchive()}
        disabled={disabled}
        loading={loading}
        icon={<Archive />}
      >
        {confirm}
        Restore
      </Dropdown.Item>
    )
  } else {
    return (
      <Dropdown.Item
        {...itemProps}
        forceMount={forceVisible}
        onClick={() => archive()}
        disabled={disabled}
        loading={loading}
        icon={<Archive />}
        danger
      >
        {confirm}
        Archive
      </Dropdown.Item>
    )
  }
}
