import { orderBy } from 'lodash'
import React from 'react'

export interface HeadingScrollContextValue {
  currentHeading?: string
  setCurrentHeading: (heading?: string) => void
}
export const HeadingScrollContext = React.createContext<HeadingScrollContextValue | undefined>(
  undefined,
)

export interface HeadingScrollProviderProps {
  children: React.ReactElement
}
export function HeadingScrollProvider({ children }: HeadingScrollProviderProps) {
  const [currentHeading, setCurrentHeading] = React.useState<string | undefined>(undefined)
  return (
    <HeadingScrollContext.Provider
      value={{ currentHeading, setCurrentHeading }}
      children={children}
    />
  )
}

export function useHeadingScrollContext(): HeadingScrollContextValue | undefined
export function useHeadingScrollContext(require: false): HeadingScrollContextValue | undefined
export function useHeadingScrollContext(require: true): HeadingScrollContextValue
export function useHeadingScrollContext(require?: boolean): HeadingScrollContextValue | undefined {
  const context = React.useContext(HeadingScrollContext)
  if (!context && require) {
    throw new Error('HeadingScrollContext is required, yet not provided.')
  }
  return context
}

export function useHeadingScrollSync(ref: React.RefObject<HTMLDivElement>, key: string) {
  const { setCurrentHeading } = useHeadingScrollContext(false) || {}

  React.useEffect(() => {
    if (ref.current && setCurrentHeading) {
      let headingElements: HTMLElement[] = []

      let handle: any = undefined
      const onScroll = () => {
        if (!headingElements.length) return
        cancelAnimationFrame(handle)
        handle = requestAnimationFrame(() => {
          if (ref.current) {
            const containerHeight = ref.current.getBoundingClientRect().height
            for (const heading of headingElements) {
              const top = heading.getBoundingClientRect().top
              if (top <= containerHeight / 2) {
                setCurrentHeading(heading.getAttribute('id') || '')
                break
              }
            }
          }
        })
      }

      // Wait a little bit of time for the editor to render initially.
      setTimeout(() => {
        if (!ref.current) return
        headingElements = orderBy(
          [
            // We have to do this because this is technically allowed, but TypeScript doesn't know it.
            ...(ref.current.querySelectorAll('h1, h2, h3, h4, h5, h6') as unknown as HTMLElement[]),
          ].filter(element => !!element.getAttribute('id')),
          'offsetTop',
          'desc',
        )
        onScroll()
      }, 600)

      ref.current.addEventListener('scroll', onScroll)
      return () => {
        ref.current?.removeEventListener('scroll', onScroll)
      }
    }
  }, [ref, key])
}
