import { BlurProps } from '@thesisedu/native'
import { styled, ThemeContext } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

import { useTheme } from '../theme'

/** @deprecated */
export function Blur({ forceDark }: BlurProps) {
  const { dark } = React.useContext(ThemeContext) || {}
  const theme = useTheme()
  return <Container background={forceDark ? (dark || theme)['@gray-1'] : theme['@gray-1']} />
}
const Container = styled.div<{ background: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(4px);
  background: ${props => transparentize(0.5, props.background)};
`

export { BlurProps } from '@thesisedu/native'
