import { EditOutlined } from '@ant-design/icons'

import { MediumFormAnswer } from './MediumFormAnswer'
import { MediumFormContent } from './MediumFormContent'
import { MediumFormEdit } from './MediumFormEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'MediumForm',
  defaults: {
    name: 'Question',
    config: {
      answer: null,
    },
  },
  icon: EditOutlined,
  label: 'Medium-Form Response',
  group: 'Short Answer',
  renderAnswerView: MediumFormAnswer,
  renderContent: MediumFormContent,
  renderEdit: MediumFormEdit,
} as QuestionTypeResource
