import { useTeacherOrStudentSelectedClass } from '@thesisedu/feature-classes-react'
import { useMutateHook, useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'

import { MutateSegmentNavigation } from './types'
import { useSegmentDecoration } from '../contexts/SegmentDecorationsContext'
import {
  SegmentNavigationItemsContextValue,
  useSegmentNavigationItemsContext,
} from '../contexts/SegmentNavigationItemsContext'
import {
  ClassFragmentWithPermissions,
  Segment,
  SegmentNavigation,
  SegmentTypeResource,
} from '../types'

export function useSegmentNavigationItems(): Pick<
  SegmentNavigationItemsContextValue,
  'navigationItems' | 'loading' | 'error'
> {
  return useSegmentNavigationItemsContext(true)
}

export function useDefaultSegmentNavigationItems(
  segment: Segment | undefined,
  isPreview: boolean,
): SegmentNavigation {
  const resource = useResource<SegmentTypeResource>('SegmentType', segment?.type ?? 'none')
  const viewer = useViewerContext(false)
  const { cls } = useTeacherOrStudentSelectedClass<ClassFragmentWithPermissions>(false)

  const decoration = useSegmentDecoration(segment?.id ?? 'none')

  let items: SegmentNavigation = []
  if (resource?.getNavigation && segment) {
    items = resource.getNavigation({
      cls,
      segment,
      viewer,
      isPreview,
      decoration,
    })
  }

  return useMutateHook<MutateSegmentNavigation>('courses-react:mutate-segment-navigation', items, {
    segment,
    isPreview,
    viewer,
  })
}
