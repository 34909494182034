import { useResource } from '@thesisedu/feature-react'
import { BodyLarger } from '@thesisedu/react'
import React from 'react'

import { ReportMetricInput } from '../../schema'
import { getSelectedResources } from '../execute/input'
import { ReportDimensionResource, ReportMetricResource, SelectedDimension } from '../types'

export interface LargeReportInputSummaryProps {
  metric?: ReportMetricInput
  dimensions: SelectedDimension[]
}
export function LargeReportInputSummary({ metric, dimensions }: LargeReportInputSummaryProps) {
  const dimensionResources = useResource<ReportDimensionResource<any, any>>('ReportDimension')
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  const selectedDimensions = getSelectedResources(dimensionResources, dimensions)
  const selectedMetrics = getSelectedResources(metricResources, metric ? [metric] : [])
  const summary = selectedDimensions[0]?.shortLabel
    ? selectedDimensions[0].shortLabel(dimensions[0].options, dimensions[0].context)
    : ''
  const metricSummary = selectedMetrics[0]?.shortLabel || 'all metrics'
  const secondSummaryText = selectedDimensions[1]?.shortLabel
    ? selectedDimensions[1].shortLabel(dimensions[1].options, dimensions[1].context)
    : null
  const secondSummaryX = secondSummaryText ? (
    <>
      &nbsp;for each <strong>{secondSummaryText}.</strong>
    </>
  ) : null
  return (
    <BodyLarger>
      I want to compare <strong children={summary} />, measuring <strong children={metricSummary} />
      {secondSummaryX || '.'}
    </BodyLarger>
  )
}
