import { Modal } from '@thesisedu/ui'
import React from 'react'
import { Prompt } from 'react-router-dom'

import { debug } from '../log'

export interface PromptContextValue {
  addPrompt: (name: string, message: string) => void
  removePrompt: (name: string) => void
  clearPrompts: () => void
  checkForPrompts: (callback: () => void) => void
}

type UsePromptContextFunction =
  | (() => PromptContextValue | undefined)
  | ((require: false) => PromptContextValue | undefined)
  | ((require: true) => PromptContextValue)
interface RegisteredPrompt {
  name: string
  message: string
}

export interface UsePromptContextProviderResult {
  children: React.ReactElement
  context: PromptContextValue
}
export interface CreatePromptContextResult {
  PromptContext: React.Context<PromptContextValue | undefined>
  usePromptContext: UsePromptContextFunction
  usePromptContextProvider: () => UsePromptContextProviderResult
}
export function createPromptContext(): CreatePromptContextResult {
  const PromptContext = React.createContext<PromptContextValue | undefined>(undefined)
  function usePromptContext(require?: boolean) {
    const context = React.useContext(PromptContext)
    if (require && !context) {
      throw new Error('usePromptContext must be used within a PromptContextProvider')
    }
    return context
  }
  function usePromptContextProvider(): UsePromptContextProviderResult {
    const [enablePrompt, setEnablePrompt] = React.useState(false)
    const promptsRef = React.useRef<RegisteredPrompt[]>([])
    const { confirm, modal } = Modal.useConfirmModal()
    return {
      context: React.useMemo(() => {
        return {
          addPrompt(name, message) {
            const existingIndex = promptsRef.current.findIndex(i => i.name === name)
            if (existingIndex > -1) {
              debug('updating existing prompt %s', name)
              promptsRef.current[existingIndex].message = message
            } else {
              debug('adding prompt %s', name)
              promptsRef.current.push({ name, message })
            }
            setEnablePrompt(!!promptsRef.current.length)
          },
          removePrompt(name) {
            debug('removing prompt %s', name)
            promptsRef.current = promptsRef.current.filter(p => p.name !== name)
            setEnablePrompt(!!promptsRef.current.length)
          },
          clearPrompts() {
            promptsRef.current = []
            setEnablePrompt(false)
          },
          checkForPrompts(callback) {
            if (promptsRef.current.length) {
              const message = promptsRef.current[0].message
              confirm({
                title: 'Are you sure?',
                children: message,
                confirm: { children: 'Yes, leave', variant: 'primary', status: 'danger' },
                cancel: { children: 'No, go back' },
                onConfirm() {
                  setEnablePrompt(false)
                  promptsRef.current = []
                  setTimeout(() => {
                    callback()
                  }, 1)
                },
              })
            } else {
              callback()
            }
          },
        }
      }, []),
      children: (
        <>
          {modal}
          <Prompt
            when={enablePrompt}
            message={promptsRef.current[0]?.message || 'You will lose any unsaved changes.'}
          />
        </>
      ),
    }
  }
  return {
    PromptContext,
    usePromptContext,
    usePromptContextProvider,
  }
}
