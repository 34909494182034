import { useFeatureRoot } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { Result } from '@thesisedu/react'
import {
  HSpaced,
  LoadingIndicator,
  Text,
  VSpaced,
  styled,
  s,
  Modal,
  Button,
  Tooltip,
} from '@thesisedu/ui'
import { ArrowRight, CheckCircle, Clock, HelpCircle, WarningTriangle } from '@thesisedu/ui/icons'
import React from 'react'

import {
  BasicClassSyncHistoryFragment,
  ClassSyncHistoryStatus,
  useClassSyncHistoryNodeQuery,
  useSyncHistoryOverviewQuery,
} from '../schema'

const TooltipContext = React.createContext<
  { setForceVisible: (key: string, v: boolean) => void } | undefined
>(undefined)

type StatusMap = Record<
  ClassSyncHistoryStatus | 'none',
  { color: s.ColorAlias; icon: React.ReactElement }
>
const Statuses: StatusMap = {
  [ClassSyncHistoryStatus.Success]: {
    color: 'green',
    icon: <CheckCircle />,
  },
  [ClassSyncHistoryStatus.Error]: {
    color: 'red',
    icon: <WarningTriangle />,
  },
  [ClassSyncHistoryStatus.NotFound]: {
    color: 'orange',
    icon: <HelpCircle />,
  },
  none: {
    color: 'secondary',
    icon: <Clock />,
  },
}

interface StatusIconProps {
  status: ClassSyncHistoryStatus | undefined
}
function StatusIcon({ status }: StatusIconProps) {
  return (
    <IconContainer style={{ background: s.color(Statuses[status ?? 'none'].color) }}>
      {Statuses[status ?? 'none'].icon}
    </IconContainer>
  )
}

function _getStatusDescriptionText(item: BasicClassSyncHistoryFragment | undefined | null) {
  switch (item?.status) {
    case ClassSyncHistoryStatus.Success:
      return `Synced automatically ${Format.relativeDate(item.completedAt)}`
    case ClassSyncHistoryStatus.Error || ClassSyncHistoryStatus.NotFound:
      return `Sync failed ${Format.relativeDate(item.completedAt)}`
    default:
      return 'Not synced yet'
  }
}

interface ErrorDetailsProps {
  itemId: string
}
function ErrorDetails({ itemId }: ErrorDetailsProps) {
  const { data, loading } = useClassSyncHistoryNodeQuery({
    variables: { id: itemId },
    fetchPolicy: 'no-cache',
  })
  const productName = useFeatureRoot().appOptions.name
  const history = data?.node?.__typename === 'ClassSyncHistory' ? data.node : undefined
  const details =
    history?.detail?.__typename === 'ClassSyncHistoryErrorDetail' ? history.detail : undefined
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading detailed information...'} />
  } else if (history && details) {
    return (
      <VSpaced space={'m'}>
        <Text>
          We ran into an error when attempting to sync this content. The error details are below. In
          some cases, your IT team might be able to help you diagnose this issue. In most cases
          though, you'll need to send this information to {productName} Support so they can help
          look into this issue.
        </Text>
        <ErrorContainer>
          <Text level={'l'}>
            {details.name}: {details.message}
          </Text>
          <Text
            as={'pre'}
            level={'s'}
            font={'code'}
            style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: 400 }}
          >
            {details.stack}
          </Text>
          {details.responseData ? (
            <>
              <Text level={'l'} top={'m'}>
                Response Status: {details.responseStatus}
              </Text>
              <Text
                as={'pre'}
                level={'s'}
                font={'code'}
                style={{ overflowX: 'auto', overflowY: 'auto', maxHeight: 400 }}
              >
                {JSON.stringify(details.responseData, undefined, 2)}
              </Text>
            </>
          ) : null}
        </ErrorContainer>
      </VSpaced>
    )
  } else {
    return <Result.Error description={'Could not load detailed error information.'} />
  }
}

const ErrorContainer = styled.div`
  color: ${s.color('red')};
  background: ${s.color('red.element')};
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('s')};
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: ${s.size('xs')};
  * {
    font: ${s.var('font.code')};
    color: ${s.color('red')} !important;
  }
`

interface StatusDescriptionProps {
  item: BasicClassSyncHistoryFragment | undefined | null
}
function StatusDescription({ item }: StatusDescriptionProps) {
  const { setForceVisible } = React.useContext(TooltipContext)!
  const [visible, _setVisible] = React.useState(false)
  const setVisible = (v: boolean) => {
    setForceVisible(item?.id ?? 'no-id', v)
    _setVisible(v)
  }
  const text = _getStatusDescriptionText(item)
  return (
    <HSpaced>
      <Text level={'xs'} color={'secondary'} style={{ marginRight: 'auto' }}>
        {text}
      </Text>
      {item?.status === ClassSyncHistoryStatus.Error ? (
        <s.ThemeProvider colorVariant={'light'}>
          <Modal
            title={'Error Details'}
            visible={visible}
            onVisibleChange={setVisible}
            trigger={
              <Button
                variant={'chromeless'}
                size={'extraSmall'}
                style={{ color: 'white', whiteSpace: 'nowrap', flexShrink: 0 }}
              >
                View Details <ArrowRight />
              </Button>
            }
          >
            <ErrorDetails itemId={item.id} />
          </Modal>
        </s.ThemeProvider>
      ) : null}
    </HSpaced>
  )
}

export interface SyncItemHistoryTooltipProps {
  id: string
  children: React.ReactElement
}
export function SyncItemHistoryTooltip({ id, children }: SyncItemHistoryTooltipProps) {
  const [visible, setVisible] = React.useState(false)
  const [visibleKeys, setVisibleKeys] = React.useState<string[]>([])
  return (
    <TooltipContext.Provider
      value={{
        setForceVisible(key, v) {
          setVisibleKeys(keys => {
            if (v) {
              return [...new Set([...keys, key])]
            } else {
              return keys.filter(k => k !== key)
            }
          })
        },
      }}
    >
      <Tooltip
        title={<Content id={id} />}
        open={visible || visibleKeys.length > 0}
        onOpenChange={setVisible}
        contentProps={{ side: 'bottom', align: 'center' }}
        children={children}
      />
    </TooltipContext.Provider>
  )
}

interface ContentProps extends Omit<SyncItemHistoryTooltipProps, 'children'> {}
function Content({ id }: ContentProps) {
  const { data, loading } = useSyncHistoryOverviewQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  })
  const supportsGrades = data?.node?.__typename === 'AssignmentSync'
  const history =
    data?.node?.__typename === 'AssignmentSync' || data?.node?.__typename === 'MaterialSync'
      ? data.node
      : undefined
  if (loading) {
    return <LoadingIndicator.InlineLabeled label={'Loading sync status...'} />
  } else if (history) {
    const gradeSync = history.latestGradeSync?.edges[0]?.node
    const metadataSync = history.latestMetadataSync?.edges[0]?.node
    return (
      <VSpaced space={'xs'} style={{ textAlign: 'left', minWidth: 300, lineHeight: 1.3 }}>
        <HSpaced space={'xs'}>
          <StatusIcon status={metadataSync?.status} />
          <div>
            <Text level={'s'}>Metadata</Text>
            <StatusDescription item={metadataSync} />
          </div>
        </HSpaced>
        {supportsGrades ? (
          <HSpaced space={'xs'}>
            <StatusIcon status={gradeSync?.status} />
            <div>
              <Text level={'s'}>Grades</Text>
              <StatusDescription item={gradeSync} />
            </div>
          </HSpaced>
        ) : null}
      </VSpaced>
    )
  } else {
    return (
      <Text level={'s'} color={'secondary'}>
        There was an error loading the sync history.
      </Text>
    )
  }
}

const IconContainer = styled.div`
  border-radius: ${s.var('radii.1')};
  width: 1.5em;
  height: 1.5em;
  font-size: ${s.size('font.m')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`
