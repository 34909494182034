import { LexicalEditor } from 'lexical'

import { $isImageNode, Alignment, ImageNode } from '../../nodes/Image/ImageNode'
import { setResizeLine } from '../../utils/resizeLine'
import { useResizableElement } from '../../utils/useResizableElement'

interface ResizeImageContext {
  minX: number
  maxX: number
  naturalWidth?: number
  containerWidth: number
  imageWidth: number
  alignment: Alignment
  currentWidth?: ImageNode['__width']
}
export function useResizableImage(editor: LexicalEditor, anchorElement: HTMLElement) {
  const { children } = useResizableElement<ImageNode, ResizeImageContext>({
    editor,
    anchorElement,
    filterNode(node) {
      return $isImageNode(node)
    },
    getLineAlignment(element, node) {
      return {
        alignment: editor.getEditorState().read(() => node.getAlignment() === 'right')
          ? 'left'
          : 'right',
      }
    },
    getLineElement(element) {
      return element.firstChild as HTMLElement | null
    },
    beforeDrag({ element, node }) {
      const parent = element?.parentElement
      const child = element?.firstChild as HTMLElement
      const image = child?.querySelector('img')
      if (!parent || !child) return false
      const { alignment, width: currentWidth } = editor.getEditorState().read(() => {
        return {
          alignment: node.getAlignment(),
          width: node.getWidth(),
        }
      })
      const { width } = parent.getBoundingClientRect()
      const { width: childWidth, left: childLeft } = child.getBoundingClientRect()
      const isVector = image?.src?.split('?')[0]?.endsWith('.svg')
      return {
        alignment,
        minX: alignment === 'center' ? childLeft + childWidth / 2 : childLeft,
        maxX: childLeft + childWidth,
        containerWidth: width,
        naturalWidth: isVector ? 10000 : image?.naturalWidth,
        imageWidth: childWidth,
        currentWidth,
      }
    },
    onMove(e, { line, element, node, context }) {
      const { minX, maxX, naturalWidth, containerWidth, imageWidth, alignment } = context
      let newRawWidth = (e.clientX - minX) / (maxX - minX)
      if (alignment === 'right') newRawWidth = 1 - newRawWidth
      const newWidthPercentage = Math.max(0.1, Math.round(newRawWidth * 25) / 25)
      const newWidth = newWidthPercentage * imageWidth
      const widthValue =
        newWidth >= containerWidth || (naturalWidth && newWidth >= naturalWidth) ? 'auto' : newWidth
      if (widthValue && widthValue !== context.currentWidth) {
        editor.update(() => {
          context.currentWidth = widthValue
          node.setWidth(widthValue)
        })
        setTimeout(() => {
          setResizeLine(line, element.firstChild as HTMLElement, anchorElement, {
            alignment: alignment === 'right' ? 'left' : 'right',
          })
        })
      }
    },
  })

  return children
}
