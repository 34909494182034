import { useViewerContext } from '@thesisedu/feature-users-react'
import { BodySmall, DATE_FORMATS, isNative, styledContainer } from '@thesisedu/react'
import { IconContainer, ImagePlaceholder } from '@thesisedu/react/dist/grid'
import { HelpCircle } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

import { BasicAssignmentFragment } from './schema'

export interface GridAssignment
  extends Pick<BasicAssignmentFragment, 'id' | 'dueAt' | 'openAt' | 'createdAt'> {
  students?: {
    totalGraded: number
    totalSubmitted: number
  }
}

// TODO: Get the correct icon.
export function useAssignmentIcon(assignment: GridAssignment) {
  return <HelpCircle />
}

export function useAssignmentColor({ dueAt, openAt }: GridAssignment) {
  if (dueAt && moment(dueAt).isBefore(moment())) {
    return '@red'
  } else if (openAt && moment(openAt).isAfter(moment())) {
    return '@text-color-secondary'
  } else if (dueAt) {
    return '@orange'
  } else {
    return '@text-color-secondary'
  }
}

export interface AssignmentSubtitleProps {
  assignment: GridAssignment
}
export function AssignmentSubtitle({ assignment }: AssignmentSubtitleProps) {
  const { dueAt, openAt, createdAt, students } = assignment
  const viewer = useViewerContext(false)
  const ungraded =
    viewer?.group === 'TEACHER' && students
      ? students.totalSubmitted - students.totalGraded
      : undefined
  const color = useAssignmentColor(assignment)
  const dateFormat = isNative ? DATE_FORMATS.TEXT_SHORT : DATE_FORMATS.FULL
  if (ungraded) {
    return <BodySmall color={'@orange'}>{ungraded} ungraded</BodySmall>
  } else if (dueAt && moment(dueAt).isBefore(moment())) {
    return <BodySmall color={color}>Was due {moment(dueAt).format(dateFormat)}</BodySmall>
  } else if (openAt && moment(openAt).isAfter(moment())) {
    return <BodySmall color={color}>Opens {moment(openAt).format(dateFormat)}</BodySmall>
  } else if (dueAt) {
    return <BodySmall color={color}>Due {moment(dueAt).format(dateFormat)}</BodySmall>
  } else {
    return <BodySmall color={color}>Created {moment(createdAt).format(dateFormat)}</BodySmall>
  }
}

export interface AssignmentThumbnailProps {
  assignment: GridAssignment
  compact?: boolean
}
export function AssignmentThumbnail({
  assignment,
  compact,
  children,
}: React.PropsWithChildren<AssignmentThumbnailProps>) {
  const icon = useAssignmentIcon(assignment)
  return (
    <Container>
      <ImagePlaceholder
        containerStyle={{
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
        }}
      >
        <IconContainer background={useAssignmentColor(assignment)} children={icon} />
      </ImagePlaceholder>
      {children}
    </Container>
  )
}

const Container = styledContainer`
  position: relative;
`
