import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  PracticeFragment,
  PracticeFragmentDoc,
  UpdatePracticeMutation,
  useUpdatePracticeMutation as useMutation,
} from '../schema'

export const useUpdatePracticeMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that practice.'),
    update: updateMutation<UpdatePracticeMutation, PracticeFragment>({
      fragmentName: 'Practice',
      fragment: PracticeFragmentDoc,
      path: 'updatePractice.practice',
    }),
    ...opts,
  })
