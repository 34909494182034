import { HSpaced, NumberField, SegmentedControl, Select, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { useMetronomeContext } from './MetronomeContext'
import { Note, Tone } from './types'

const MIN_BPM = 20
const MAX_BPM = 360
export function Settings() {
  const { update, bpm, emphasizedNote, tones, note } = useMetronomeContext(true)
  const bpmLabel = React.useId()
  return (
    <VSpaced space={'xs'}>
      <HSpaced space={'xs'}>
        <NumberField
          aria-labelledby={bpmLabel}
          value={bpm}
          onChange={value => update({ bpm: value })}
          style={{ width: 75, flex: 1 }}
          minValue={MIN_BPM}
          maxValue={MAX_BPM}
        />
        <Text id={bpmLabel}>BPM</Text>
        <Select
          value={tones.join('|')}
          onValueChange={value => update({ tones: value.split('|') as [Tone, Tone] })}
          style={{ flex: 1 }}
        >
          <Select.Item value={[Tone.A4, Tone.A3].join('|')}>Tone A</Select.Item>
          <Select.Item value={[Tone.B4, Tone.B3].join('|')}>Tone B</Select.Item>
          <Select.Item value={[Tone.C4, Tone.C3].join('|')}>Tone C</Select.Item>
          <Select.Item value={[Tone.D4, Tone.D3].join('|')}>Tone D</Select.Item>
        </Select>
      </HSpaced>
      <HSpaced space={'xs'}>
        <Select
          value={emphasizedNote.toString()}
          onValueChange={v => update({ emphasizedNote: parseFloat(v) })}
          style={{ flex: 1 }}
        >
          {[2, 3, 4, 6, 12].map(index => (
            <Select.Item key={index} value={index.toString()}>
              {index}
            </Select.Item>
          ))}
        </Select>
        <Text>/</Text>
        <SegmentedControl size={'small'}>
          {[Note.Quarter, Note.Eighth].map(n => {
            return (
              <SegmentedControl.Item
                key={n}
                selected={note === n}
                onPress={() => update({ note: n })}
                children={n.replace('n', '')}
              />
            )
          })}
        </SegmentedControl>
      </HSpaced>
      <Text level={'s'} color={'secondary'}>
        Click to enable / disable.
      </Text>
    </VSpaced>
  )
}
