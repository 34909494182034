import { Option } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { MultipleChoiceChoice } from './MultipleChoiceChoice'
import { QuestionInstructions } from './QuestionInstructions'

export interface MultipleChoiceFieldProps {
  options: Option[]
  value?: string[] | string
  onChange?: (value: string[] | string) => void
  multiple?: boolean
  instructions?: string
  disabled?: boolean
}
export function MultipleChoiceField({
  instructions = 'Select an answer from the options below.',
  options,
  value,
  multiple,
  onChange,
  disabled,
}: MultipleChoiceFieldProps) {
  return (
    <QuestionInstructions instructions={instructions}>
      {options.map((option, index) => (
        <MultipleChoiceChoice
          key={option.id}
          option={option}
          index={index}
          selected={Array.isArray(value) ? value.includes(option.name) : value === option.name}
          disabled={disabled}
          readOnly
          onClick={() => {
            if (multiple) {
              const _value = (value || []) as string[]
              const without = (_value || []).filter(v => v !== option.name)
              if (onChange) {
                if (without.length === _value.length) {
                  onChange([...without, option.name])
                } else onChange(without)
              }
            } else if (onChange) {
              onChange(option.name)
            }
          }}
        />
      ))}
    </QuestionInstructions>
  )
}
