import React, { useState } from 'react'

import { UploadModal } from './UploadModal'

export interface UploadModalContextValue {
  showUploads: () => void
}
export const UploadModalContext = React.createContext<UploadModalContextValue | null>(null)

export const UploadModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const context: UploadModalContextValue = {
    showUploads: () => setVisible(true),
  }
  return (
    <>
      <UploadModal onClose={() => setVisible(false)} visible={visible} />
      <UploadModalContext.Provider value={context} children={children} />
    </>
  )
}
