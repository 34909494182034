import { useMinimalClass } from '../ClassContext'

export function useClassName(classId: string) {
  const { cls } = useMinimalClass(classId)
  if (cls) {
    return cls.name
  } else {
    return '...'
  }
}
