import { Select, Radio, Checkbox } from 'antd'
import { RadioGroupProps } from 'antd/es/radio'
import { SelectProps } from 'antd/es/select'
import Case from 'case'
import React, { useContext } from 'react'

import { StyledThemeContext } from './index'

// FIXME typing in this file is going to be horrendous until this is fixed:
//   https://github.com/microsoft/TypeScript/issues/30611#issuecomment-552686629

export type Enum = string | number
export type EnumLabels<T extends string | number> = {
  [key in T]?: string | React.ReactElement
}
export type EnumObject<T extends string | number> = {
  [key in T]: string | number
}

const key = (e: any, key: Enum) => e[key]

export interface EnumSelectProps<T extends Enum> extends SelectProps<T> {
  enum: any
  labels?: EnumLabels<T>
}

export function EnumSelect<T extends Enum>({
  enum: _enum,
  labels = {},
  ...props
}: EnumSelectProps<T>) {
  return (
    <Select<any> {...props}>
      {(Object.keys(_enum) as T[]).map(enumKey => (
        <Select.Option key={enumKey} value={key(_enum, enumKey)}>
          {labels[key(_enum, enumKey) as T] || Case.title(enumKey as string)}
        </Select.Option>
      ))}
    </Select>
  )
}

export interface EnumRadioSelectProps<T extends Enum> extends RadioGroupProps {
  enum: any
  isVertical?: boolean
  labels?: EnumLabels<T>
}
export function EnumRadioSelect<T extends Enum>({
  enum: _enum,
  isVertical,
  labels = {},
  ...props
}: EnumRadioSelectProps<T>) {
  const theme = useContext(StyledThemeContext)
  const style = isVertical
    ? { display: 'block', height: theme['@size-l'], lineHeight: theme['@size-l'], marginLeft: 0 }
    : {}
  const content = (
    <Radio.Group {...props}>
      {(Object.keys(_enum) as T[]).map(enumKey => (
        <Radio key={enumKey} value={key(_enum, enumKey)} style={style}>
          {labels[key(_enum, enumKey) as T] || Case.title(enumKey as string)}
        </Radio>
      ))}
    </Radio.Group>
  )
  return <>{content}</>
}

export interface EnumCheckboxSelectProps<T extends Enum> {
  enum: any
  value?: string[]
  onChange?: (value: string[]) => void
  isVertical?: boolean
  labels?: EnumLabels<T>
}
export function EnumCheckboxSelect<T extends Enum>({
  enum: _enum,
  value,
  onChange,
  isVertical,
  labels = {},
}: EnumCheckboxSelectProps<T>) {
  const theme = useContext(StyledThemeContext)
  const style = isVertical
    ? { display: 'block', height: theme['@size-l'], lineHeight: theme['@size-l'], marginLeft: 0 }
    : {}
  const content = (Object.keys(_enum) as T[]).map(enumKey => (
    <Checkbox
      key={enumKey}
      checked={value?.includes(key(_enum, enumKey))}
      style={style}
      onChange={e => {
        if (onChange) {
          const without = (value || []).filter(i => i !== key(_enum, enumKey))
          if (e.target.checked) {
            onChange([...without, key(_enum, enumKey)])
          } else {
            onChange(without)
          }
        }
      }}
    >
      {labels[key(_enum, enumKey) as T] || Case.title(enumKey as string)}
    </Checkbox>
  ))
  return <>{content}</>
}
