import {
  ReportDimensionOptionsLabelProps,
  ReportDimensionOptionsProps,
} from '@thesisedu/feature-reports-react'
import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { ReportStandardsSummaryInput, useExploreStandardSetsQuery } from '../../../schema'
import { StandardDimensionContext } from '../Standards'

export function StandardOptionsWrapper({
  value,
  context,
  onConfirm,
  children,
  isInDropdown,
  ...rest
}: ReportDimensionOptionsProps<ReportStandardsSummaryInput, StandardDimensionContext>) {
  const { data, loading } = useExploreStandardSetsQuery()
  const selectedId = value?.standardSetId
  const standardSets = data?.viewer?.teacher?.site.standardSets.edges.map(edge => edge.node)
  const menuItems = loading
    ? [
        <Dropdown.Item key={'loading'} disabled>
          Loading Standards...
        </Dropdown.Item>,
      ]
    : standardSets?.length
    ? standardSets.map(standardSet => (
        <Dropdown.Item.Checkbox
          key={standardSet.id}
          onClick={() => {
            onConfirm({ standardSetId: standardSet.id }, { standardSetName: standardSet.name })
          }}
          checked={selectedId === standardSet.id}
        >
          {standardSet.name}
        </Dropdown.Item.Checkbox>
      ))
    : [
        <Dropdown.Item key={'none'} disabled>
          No Standards Available
        </Dropdown.Item>,
      ]

  if (isInDropdown) {
    return (
      <Dropdown.SubMenu key={'standards'}>
        <Dropdown.SubMenu.Trigger {...children.props} />
        <Dropdown.SubMenu.Content children={menuItems} />
      </Dropdown.SubMenu>
    )
  } else {
    return (
      <Dropdown.Container>
        <Dropdown.ManualTrigger>{children}</Dropdown.ManualTrigger>
        <Dropdown.Menu>{menuItems}</Dropdown.Menu>
      </Dropdown.Container>
    )
  }
}

export function StandardOptionsLabel({
  value,
  context,
}: ReportDimensionOptionsLabelProps<ReportStandardsSummaryInput, StandardDimensionContext>) {
  const { standardSetName } = context || {}
  return <>{standardSetName || 'Select Standards'}</>
}
