import { Dropdown } from '@thesisedu/ui'
import { NavArrowDown } from '@thesisedu/ui/icons'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { useRosteringContext } from './RosteringContext'
import { ClassSyncRosterActionFragment } from '../../schema'

export interface ProviderStudentDropdownProps {
  value?: string
  onChange: (student: Required<ClassSyncRosterActionFragment>['providerStudent'] | null) => void
}
export function ProviderStudentDropdown({ value, onChange }: ProviderStudentDropdownProps) {
  const { providerStudents } = useRosteringContext(true)
  const groups = groupBy(
    orderBy(providerStudents, student => student.userInfo.lastName),
    'group',
  )
  return (
    <Dropdown.Container>
      <Dropdown.Button
        icon={<NavArrowDown />}
        variant={'ghost'}
        size={'small'}
        rightDecorator={null}
      />
      <Dropdown.SearchableMenu placeholder={'Find a student...'} side={'bottom'} align={'end'}>
        {Object.keys(groups).reduce<React.ReactElement[]>((acc, groupKey) => {
          const students = groups[groupKey]
          const studentItems = students.map(student => {
            return (
              <Dropdown.Item.Checkbox
                key={student.id}
                children={[student.userInfo.firstName, student.userInfo.lastName]
                  .filter(Boolean)
                  .join(' ')}
                checked={value === student.id}
                onCheckedChange={checked => {
                  onChange(checked ? student : null)
                }}
              />
            )
          })
          if (groupKey !== 'undefined' && groupKey !== 'null') {
            return [
              ...acc,
              <Dropdown.Item.Label key={groupKey}>{groupKey}</Dropdown.Item.Label>,
              ...studentItems,
            ]
          } else return [...acc, ...studentItems]
        }, [])}
      </Dropdown.SearchableMenu>
    </Dropdown.Container>
  )
}
