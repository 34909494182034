import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  BasicCourseVersionFragmentDoc,
  usePublishCourseVersionMutation as useMutation,
} from '../schema'

export const usePublishCourseVersionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error publishing that course version.'),
    ...opts,
    update: updateMutation({
      fragment: BasicCourseVersionFragmentDoc,
      fragmentName: 'BasicCourseVersion',
      path: 'publishCourseVersion.courseVersion',
    }),
  })
