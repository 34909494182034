import { InteractionProps } from '@thesisedu/feature-interactions-react'
import { InteractionListItem } from '@thesisedu/feature-interactions-react/web'
import Case from 'case'
import React from 'react'

import { useSegmentNavigate } from '../segment/SegmentLink'
import { useSegmentResource } from '../segment/useSegmentResource'

export function ViewSegment({ interaction }: InteractionProps) {
  const segmentType = useSegmentResource(interaction.metadata.type)
  const nav = useSegmentNavigate({ id: interaction.metadata.id })
  return (
    <InteractionListItem fragment={interaction} linkProps={{ onClick: nav }}>
      {interaction.user.name || interaction.user.username} viewed{' '}
      <strong>{segmentType.label || Case.title(interaction.metadata.type)}</strong>{' '}
      {interaction.metadata.name}
    </InteractionListItem>
  )
}
