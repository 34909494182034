import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import React from 'react'
import styled from 'styled-components'

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
`

export interface TableActionsProps {
  overlay?: React.ReactElement
}

const TableActionItem = styled.div`
  display: flex;
  cursor: pointer;
`

export const TableActions: React.FC<React.PropsWithChildren<TableActionsProps>> = ({ overlay }) => {
  if (!overlay) return null

  return (
    <ActionContainer>
      <Dropdown overlay={overlay} arrow placement={'bottomRight'}>
        <TableActionItem>
          Actions <CaretDownOutlined style={{ fontSize: 20 }} />
        </TableActionItem>
      </Dropdown>
    </ActionContainer>
  )
}
