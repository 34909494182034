import { Tooltip } from '@thesisedu/ui'
import React from 'react'

import { SkipNext, SkipPrev } from '../../../icons'
import { useMediaPlaybackContext } from '../MediaPlaybackContext'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

const DELTA = 10
export function SkipForward({ compact }: PlayerFooterProps) {
  const { duration, onSeek, playedSeconds, allowSeek } = useMediaPlaybackContext()
  if (duration && allowSeek) {
    return (
      <Tooltip title={'Skip forward 10 seconds'}>
        <ControlContainer
          compact={compact !== undefined}
          onClick={() => onSeek(Math.min(playedSeconds + DELTA, duration))}
        >
          <SkipNext />
        </ControlContainer>
      </Tooltip>
    )
  } else {
    return null
  }
}

export function SkipBackward({ compact }: PlayerFooterProps) {
  const { duration, onSeek, playedSeconds } = useMediaPlaybackContext()
  if (duration) {
    return (
      <Tooltip title={'Skip backward 10 seconds'}>
        <ControlContainer
          compact={compact !== undefined}
          onClick={() => onSeek(Math.max(playedSeconds - DELTA, 0))}
        >
          <SkipPrev />
        </ControlContainer>
      </Tooltip>
    )
  } else {
    return null
  }
}
