import React from 'react'

export interface WebSettings {
  playbackSpeeds?: number[]
}
export const WebSettingsContext = React.createContext<WebSettings>({})

export interface WebSettingsProviderProps {
  settings?: WebSettings
  children: React.ReactElement
}
export function WebSettingsProvider({ settings = {}, children }: WebSettingsProviderProps) {
  return <WebSettingsContext.Provider value={settings} children={children} />
}

export function useWebSettings() {
  return React.useContext(WebSettingsContext)
}
