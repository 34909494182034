import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import NumberField, * as NumberField$ from '../NumberField'

function _FormNumberField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<NumberField$.NumberFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <NumberField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormNumberField = React.forwardRef(_FormNumberField)
