import { BrochureLink, useResource } from '@thesisedu/feature-react'
import { NotAllowed } from '@thesisedu/react'
import { Text, SplitModal, Block, styled, Button, s, Modal } from '@thesisedu/ui'
import { NavArrowLeft } from '@thesisedu/ui/icons'
import { motion, AnimatePresence } from 'framer-motion'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { useOptions } from '../../options'
import { ClassFragment } from '../../schema'
import { ImportPayload, ImportStudentModeResource } from '../../types'
import { useTeacherClass } from '../../useClass'

const UNGROUPED = 'ungrouped'

interface ExpandedMode {
  payload: ImportPayload
  identifier: string
}
interface _InviteStudentModalProps {
  cls: ClassFragment
  onClose: () => void
}
function _InviteStudentModal({ cls, onClose }: _InviteStudentModalProps) {
  const options = useOptions()
  const [expandedMode, setExpandedMode] = React.useState<ExpandedMode | undefined>(undefined)
  const resources = orderBy(
    useResource<ImportStudentModeResource>('ImportStudentMode'),
    'weight',
    'asc',
  )
  const grouped = groupBy(resources, r => r.group ?? UNGROUPED)
  return (
    <SplitModal
      title={
        <>
          <Text level={'s'} color={'secondary'}>
            Adding Students to
          </Text>
          <Text level={'l'} weight={'bold'}>
            {cls.name}
          </Text>
        </>
      }
      defaultValue={resources[0]?.identifier}
      style={{ height: 650 }}
      visible
      onVisibleChange={v => {
        if (!v) onClose()
      }}
    >
      {cls && !cls.canInvite ? (
        <Block paddingHorizontal={'m'} paddingVertical={'m'}>
          <NotAllowed message={'You are not allowed to invite students to this class.'} />
        </Block>
      ) : cls ? (
        <>
          <SplitModal.Navigation>
            {Object.keys(grouped).map(group => {
              return (
                <React.Fragment key={group}>
                  {group === UNGROUPED ? null : (
                    <SplitModal.Navigation.Item.Label children={group} />
                  )}
                  {grouped[group].map(mode => {
                    return (
                      <SplitModal.Navigation.Item
                        key={mode.identifier}
                        value={mode.identifier}
                        title={typeof mode.title === 'function' ? mode.title(options) : mode.title}
                        icon={mode.icon}
                      />
                    )
                  })}
                </React.Fragment>
              )
            })}
          </SplitModal.Navigation>
          <SplitModal.Content>
            {resources.map(resource => {
              const contentProps = {
                initial: { opacity: 0, scale: 0.99 },
                animate: { opacity: 1, scale: 1 },
                exit: { opacity: 0, scale: 0.99 },
                transition: { duration: 0.2 },
              }
              const helpMessage = (
                <Modal.Footer>
                  <Text
                    level={'s'}
                    color={'secondary'}
                    style={{ textAlign: 'center', margin: '0 auto' }}
                  >
                    <strong>Need more help?</strong> Feel free to{' '}
                    <BrochureLink to={'contact'}>reach out to {options.name} support</BrochureLink>{' '}
                    at any time.
                  </Text>
                </Modal.Footer>
              )
              let content
              let width: number | undefined = undefined
              if (
                expandedMode &&
                expandedMode.identifier === resource.identifier &&
                resource.ExpandedContentComponent
              ) {
                width = resource.widths?.expanded
                content = (
                  <TabContent key={'expanded'} {...contentProps}>
                    <Block bottom={'xs'} style={{ marginTop: `calc(-1 * ${s.size('s')})` }}>
                      <Button
                        size={'small'}
                        icon={<NavArrowLeft />}
                        aria-label={'Go Back'}
                        onPress={() => setExpandedMode(undefined)}
                      />
                    </Block>
                    <resource.ExpandedContentComponent
                      onBack={() => setExpandedMode(undefined)}
                      onClose={onClose}
                      class={cls}
                      payload={expandedMode.payload}
                    />
                    {helpMessage}
                  </TabContent>
                )
              } else {
                width = resource.widths?.main
                content = (
                  <TabContent key={'main'} {...contentProps}>
                    <resource.MainContentComponent
                      class={cls}
                      onModeSelected={payload =>
                        setExpandedMode({ identifier: resource.identifier, payload })
                      }
                    />
                    {helpMessage}
                  </TabContent>
                )
              }
              return (
                <SplitModal.Content.Item
                  key={resource.identifier}
                  value={resource.identifier}
                  width={width}
                >
                  <AnimatePresence exitBeforeEnter initial={false}>
                    {content}
                  </AnimatePresence>
                </SplitModal.Content.Item>
              )
            })}
          </SplitModal.Content>
        </>
      ) : null}
    </SplitModal>
  )
}

const TabContent = styled(motion.div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

export interface InviteStudentModalProps {
  classId?: string
  onClose: () => void
}
export function InviteStudentModal({ classId, onClose }: InviteStudentModalProps) {
  const { cls } = useTeacherClass(classId || '', false)
  if (cls) {
    return <_InviteStudentModal cls={cls} onClose={onClose} />
  } else return null
}
