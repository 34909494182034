import { ThemeProvider, styled } from '@thesisedu/web'
import React, { useEffect, useState } from 'react'

const PianoContainer = styled.div`
  display: inline-flex;
  margin: 0 auto;
`
const OctaveContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: stretch;
  height: 100px;
`
const KeyBox = styled.div<{ leftMargin?: number }>`
  width: 20px;
  height: 100%;
  margin-left: ${props => (props.leftMargin || 0) * 20}px;
  border: solid 1px ${props => props.theme['@border-color-split']};
  border-bottom-left-radius: ${props => props.theme['@border-radius-base']};
  border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
  cursor: pointer;
  -webkit-user-drag: none;
  transition: background 0.1s linear;
  background: white;
  &:hover {
    background: ${props => props.theme['@border-color-split']};
  }
  &.active {
    background: ${props => props.theme['@text-color-secondary']};
  }
`
const SharpContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  display: flex;
  align-items: flex-start;
  pointer-events: none;
`
const SharpBox = styled(KeyBox)`
  width: 20px;
  background: ${props => props.theme['@background-color-dark']};
  pointer-events: all;
  &:hover {
    background: ${props => props.theme['@background-color-black']};
  }
  &.active {
    background: black;
  }
`

export interface KeyProps extends PianoCommonProps {
  octave: number
  midi: number
}
export const Key: React.FC<React.PropsWithChildren<KeyProps>> = ({
  octave,
  midi,
  onActive,
  activeKey,
}) => {
  return (
    <KeyBox
      className={activeKey === octave * 12 + midi ? 'active' : ''}
      onMouseEnter={() => onActive(octave * 12 + midi)}
    />
  )
}

export interface SharpProps extends PianoCommonProps {
  octave: number
  midi: number
  spacerLeft?: number
}
export const Sharp: React.FC<React.PropsWithChildren<SharpProps>> = ({
  octave,
  midi,
  spacerLeft,
  onActive,
  activeKey,
}) => {
  return (
    <SharpBox
      className={activeKey === octave * 12 + midi ? 'active' : ''}
      leftMargin={spacerLeft}
      onMouseEnter={() => onActive(octave * 12 + midi)}
    />
  )
}

export interface OctaveProps extends PianoCommonProps {
  octave: number
}
export const Octave: React.FC<React.PropsWithChildren<OctaveProps>> = ({ octave, ...props }) => {
  const keys = [0, 2, 4, 5, 7, 9, 11]
  return (
    <OctaveContainer>
      {keys.map(key => (
        <Key key={key} midi={key} octave={octave} {...props} />
      ))}
      <SharpContainer>
        <Sharp midi={1} octave={octave} spacerLeft={0.5} {...props} />
        <Sharp midi={3} octave={octave} {...props} />
        <Sharp midi={6} octave={octave} spacerLeft={1} {...props} />
        <Sharp midi={8} octave={octave} {...props} />
        <Sharp midi={10} octave={octave} {...props} />
      </SharpContainer>
    </OctaveContainer>
  )
}

export interface PianoCommonProps {
  onActive: (midiKey: number) => void
  activeKey: number | null
}
export interface PianoProps {
  onKeyChange: (midiKey: number | null) => void
}
export const Piano: React.FC<React.PropsWithChildren<PianoProps>> = ({ onKeyChange }) => {
  const [activeKey, setActiveKey] = useState<number | null>(null)
  const [isActive, setIsActive] = useState<boolean>(false)
  useEffect(() => {
    if (!isActive) {
      onKeyChange(null)
    }
  }, [isActive])
  useEffect(() => {
    if (isActive) {
      onKeyChange(activeKey)
    }
  }, [activeKey, isActive])
  const commonProps: PianoCommonProps = {
    onActive: setActiveKey,
    activeKey: isActive ? activeKey : null,
  }
  return (
    <ThemeProvider theme={'light'}>
      <PianoContainer
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onMouseLeave={() => setIsActive(false)}
      >
        <Octave octave={4} {...commonProps} />
      </PianoContainer>
    </ThemeProvider>
  )
}
