import { CreateClassContentHook } from '@thesisedu/feature-classes-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { Block, BodySmall } from '@thesisedu/react'
import { getFlag, setFlag } from '@thesisedu/web'
import React from 'react'

import { Banner } from '../Banner'

const BANNER_FLAG = 'pa-create-class-banner'
function WelcomeMessage() {
  const [visible, _setVisible] = React.useState(!getFlag(BANNER_FLAG))
  const setVisible = (visible: boolean) => {
    if (!visible) {
      setFlag(BANNER_FLAG, true)
    }
    _setVisible(visible)
  }
  if (!visible) return null
  return (
    <Block marginTop={'@size-l'}>
      <Banner
        title={'Two Great Ways to Teach'}
        description={
          'Create a new class connected to a content-rich, standards-aligned Prodigies Academy ' +
          'course or start with a blank class using the Build Your Own Course option. ' +
          'Either option allows you to add custom content, move content or pull in your ' +
          'licensed Prodigies Academy content.'
        }
      >
        <a
          href={'#'}
          onClick={e => {
            e.preventDefault()
            setVisible(false)
          }}
        >
          <BodySmall color={'@text-color-secondary'}>Hide this message.</BodySmall>
        </a>
      </Banner>
    </Block>
  )
}

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<CreateClassContentHook>(
    'classes-react:create-class',
    content => {
      return [<WelcomeMessage key={'bu-welcome'} />, ...content]
    },
  )
}
