import { warn } from '../log'

export interface NumberFormatConfig extends Intl.NumberFormatOptions {
  extends?: PresetNumberFormat | PresetNumberFormat[]
  removeCurrencySign?: boolean
}
export type PresetNumberFormat =
  | 'number'
  | 'scientific'
  | 'decimal'
  | 'abbreviated'
  | 'currency'
  | 'decimalCurrency'
  | 'abbreviatedCurrency'
  | 'accounting'
  | 'financial'
  | 'percentage'
  | 'decimalPercentage'
export type NumberFormat = NumberFormatConfig | PresetNumberFormat

export const PRESET_FORMATS: Record<PresetNumberFormat, NumberFormatConfig> = {
  number: { maximumFractionDigits: 0 },
  scientific: { notation: 'scientific', maximumFractionDigits: 2 },
  decimal: { style: 'decimal', maximumFractionDigits: 2 },
  abbreviated: { notation: 'compact', compactDisplay: 'short' },
  currency: { extends: 'number', style: 'currency', currency: 'USD' },
  decimalCurrency: {
    extends: ['currency', 'decimal'],
    style: 'currency',
    currencySign: 'accounting',
  },
  abbreviatedCurrency: { extends: 'abbreviated', style: 'currency', currency: 'USD' },
  accounting: { extends: 'decimalCurrency', currencySign: 'accounting' },
  financial: { extends: 'accounting', removeCurrencySign: true },
  percentage: { style: 'percent' },
  decimalPercentage: { extends: ['decimal', 'percentage'] },
}

function _resolveConfig(config: NumberFormatConfig): Omit<NumberFormatConfig, 'extends'> {
  if (config.extends) {
    const arrayExtends: PresetNumberFormat[] = Array.isArray(config.extends)
      ? config.extends
      : [config.extends]
    const finalConfig = arrayExtends.reduce<Intl.NumberFormatOptions>((acc, ext) => {
      const extendsConfig = _resolveConfig(PRESET_FORMATS[ext])
      if (!extendsConfig) {
        throw new Error(`Extends preset: '${ext}' is invalid.`)
      }
      return { ...acc, ...extendsConfig }
    }, {})
    return {
      ...finalConfig,
      ...config,
    }
  } else return config
}

// Re-use the instances for performance: https://blog.david-reess.de/posts/hBEx9w-on-number-formatting-and-performance
interface PresetInstance {
  format: Intl.NumberFormat
  resolvedConfig: Omit<NumberFormatConfig, 'extends'>
}
const PRESET_INSTANCES = (Object.keys(PRESET_FORMATS) as PresetNumberFormat[]).reduce<
  Partial<Record<PresetNumberFormat, PresetInstance>>
>((acc, format) => {
  const resolvedConfig = _resolveConfig(PRESET_FORMATS[format])
  return {
    ...acc,
    [format]: { format: new Intl.NumberFormat(undefined, resolvedConfig), resolvedConfig },
  }
}, {}) as Record<PresetNumberFormat, PresetInstance>
const CACHE = new Map<string, Intl.NumberFormat>()

export function presetExists(preset: string): preset is PresetNumberFormat {
  return (PRESET_FORMATS as any)[preset] !== undefined
}

export function formatNumber(number: number, format: NumberFormat): string
export function formatNumber(number: number | undefined | null, format: NumberFormat): string | null
export function formatNumber(
  number: number | undefined | null,
  format: NumberFormat,
): string | null {
  if (number !== undefined && number !== null) {
    let result
    let removeCurrencySign = false
    if (typeof format === 'string') {
      if (!PRESET_INSTANCES[format]) {
        throw new Error(`Format preset: '${format}' is invalid.`)
      }
      result = PRESET_INSTANCES[format].format.format(number)
      removeCurrencySign = PRESET_INSTANCES[format].resolvedConfig.removeCurrencySign === true
    } else {
      const resolvedConfig = _resolveConfig(format)
      const cacheKey = JSON.stringify(resolvedConfig)
      removeCurrencySign = resolvedConfig.removeCurrencySign === true
      if (!CACHE.has(cacheKey)) {
        CACHE.set(cacheKey, new Intl.NumberFormat(undefined, resolvedConfig))
      }
      if (CACHE.size > 200) {
        warn('Number format cache is growing too large. Consider using a preset format.')
      }
      result = CACHE.get(cacheKey)!.format(number)
    }
    return removeCurrencySign ? result.replace('$', '') : result
  } else return null
}
