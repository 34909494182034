import { Toolbar, ToolbarButton, Separator } from './Toolbar'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './Toolbar'

const convenience = withSubComponents(Toolbar, {
  Button: ToolbarButton,
  Separator,
})
export default convenience
