import { useFileUrl } from '@thesisedu/feature-attachments-react'
import { useIsGeneratingPdf } from '@thesisedu/feature-pdf-generation-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { HSpaced, LoadingIndicator, Text } from '@thesisedu/ui'
import { MinusCircle, ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'
import ReactResizeDetector from 'react-resize-detector'

import { PDFDisplay } from './PDFDisplay'
import { PDFConfigWithFile } from './common'

export interface PDFViewProps extends PDFConfigWithFile {}
export function PDFView({ file, pageCount, startPage, allowDownloads }: PDFViewProps) {
  const isGeneratingPdf = useIsGeneratingPdf()
  const viewer = useViewerContext(false)
  const { url, loading, error } = useFileUrl(file)

  if (isGeneratingPdf) return null

  if (loading || error || !url) {
    return (
      <SimpleWidget.WidgetContainer>
        <SimpleWidget.StatusContainer>
          {loading ? <LoadingIndicator /> : <MinusCircle />}
        </SimpleWidget.StatusContainer>
      </SimpleWidget.WidgetContainer>
    )
  } else {
    return (
      <ReactResizeDetector
        handleWidth
        refreshMode={'debounce'}
        refreshRate={1000}
        render={({ width }) => (
          <>
            {allowDownloads && viewer?.group !== 'STUDENT' ? (
              <Text
                bottom={'m'}
                color={'primary'}
                as={'a'}
                href={url}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <HSpaced space={'xxs'}>
                  <span>Download PDF</span>
                  <ArrowRight />
                </HSpaced>
              </Text>
            ) : null}
            <PDFDisplay
              filename={url}
              startPage={startPage || undefined}
              numPages={pageCount || undefined}
              width={width}
            />
          </>
        )}
      />
    )
  }
}
