import { styledContainer as styled } from '.'

/** @deprecated */
export const SolidIcon = styled<{ shape?: 'rounded' | 'circle'; color?: string }>`
  background: ${props => (props.color ? props.theme[props.color] : 'currentColor')};
  border-radius: ${props =>
    props.shape === 'rounded' ? props.theme['@border-radius-base'] : '50%'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  > * {
    color: ${props => props.theme['@gray-0']};
    width: 0.75em;
    height: 0.75em;
  }
`
