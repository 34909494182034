import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Form } from '@thesisedu/react'
import { AntIconWrapper, PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import { Plus } from 'iconoir-react'
import React from 'react'

import { TagForm } from './TagForm'
import { CreateTagInput, TagFragment, useCreateTagMutation } from '../schema'

export interface CreateTagProps {
  onCreated?: (tag: TagFragment) => void
}
export function CreateTag({ onCreated }: CreateTagProps) {
  useBreadcrumb({ title: 'Create Tag' })
  const [create, { loading }] = useCreateTagMutation({
    onError: onMutationError('There was an error creating your tag.'),
    onCompleted: data => {
      if (onCreated) {
        onCreated(data.createTag.tag)
      }
    },
  })
  const form = Form.useForm<CreateTagInput>()

  return (
    <Form
      form={form}
      onFinish={async values => {
        await create({
          variables: {
            input: values,
          },
        })
      }}
    >
      <PageHead title={'Create Tag'} />
      <PageHeader title={'Create Tag'}>
        <Form.Submit
          type={'primary'}
          icon={<AntIconWrapper children={<Plus />} />}
          children={'Create Tag'}
          loading={loading}
        />
      </PageHeader>
      <TagForm />
    </Form>
  )
}
