import { PluginLevel } from '@thesisedu/feature-widgets-react'
import { Row, Markdown } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'
import { LegacyEditorWrapper } from '../blocks'

export function NotesBoxAnswer(props: QuestionProps) {
  const data = useSubmissionData(props.question.id)
  return (
    <Row>
      {data ? (
        <Col xs={24} md={props.question.config?.sample ? 12 : 24}>
          <QuestionInstructions instructions={'Take notes using the box below.'} padded>
            <LegacyEditorWrapper disabled value={data} level={PluginLevel.Simple} minHeight={100} />
          </QuestionInstructions>
        </Col>
      ) : null}
      {props.question.config?.sample ? (
        <Col xs={24} md={data ? 12 : 24}>
          <QuestionInstructions instructions={'Sample Answer'} padded>
            <Markdown children={props.question.config.sample} skipHtml />
          </QuestionInstructions>
        </Col>
      ) : null}
    </Row>
  )
}
