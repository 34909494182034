import { s } from '@thesisedu/ui'
import resolve from 'object-reference'
import { transparentize } from 'polished'

const colors = {
  '@blue': '#009596',
  '@blue-light': '#EBFFFF',
  '@red': '#EB2627',
  '@red-light': '#FFEBEB',
  '@green': '#6BBE46',
  '@green-light': '#f6faf5',
  '@orange': '#F89721',
  '@orange-light': '#FFF6EB',
  '@white': '#FFFFFF',
  '@pink': '#EB6BA2',
  '@purple': '#634683',
  '@yellow': '#F5EB0B',
  '@blue-2': '#2A75AE',
  '@plum': '#945580',
  '@poppy': '#DE4D2A',
  '@gold': '#FACE5E',
  '@mint': '#5DB67A',
  '@brown': '#512D1C',

  '@gray-0': '#FFF',
  '@gray-1': '#F2F4F7',
  '@gray-2': '#E6EBF5',
  '@gray-3': '#D8DFF0',
  '@gray-4': '#8D98B3',
  '@gray-5': '#626F8C',
  '@gray-6': '#2A3859',
  '@gray-7': '#19223F',
}

const sizes = {
  '@size-xxs': '4px',
  '@size-xs': '8px',
  '@size-s': '16px',
  '@size-xm': '18px',
  '@size-m': '22px',
  '@size-l': '32px',
  '@size-xl': '48px',
  '@size-xxl': '64px',
}

const themeBase = () => ({
  '@font-family': "'Nunito', sans-serif;",
  '@code-family':
    "'Hasklig', 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",

  '@shadow-color': transparentize(0.85, colors['@gray-4']),
  '@shadow-small': `0.5px 1px 2px ${transparentize(0.45, colors['@gray-4'])}`,
  '@shadow-medium': `2px 2px 6px ${transparentize(0.65, colors['@gray-4'])}`,
  '@shadow-large': `0 4px 20px 0 rgba(98, 111, 140, 0.25)`,
  '@shadow-large-pressed': `0 2px 10px 0 rgba(98, 111, 140, 0.25)`,
  '@shadow-large-hover': `0 5px 25px 0 rgba(98, 111, 140, 0.4)`,

  '@component-background': '$.@gray-0',
  '@background-color-light': transparentize(0.5, colors['@gray-1']),
  '@background-color-base': '$.@gray-1',
  '@background-color-dark': '$.@gray-6',
  '@background-color-black': '$.@gray-7',
  '@text-color-dark': '$.@gray-1',
  '@text-color-secondary-dark': '$.@gray-5',
  '@input-bg': '$.@gray-1',
  '@picker-bg': '$.@gray-1',
  '@btn-default-bg': '$.@gray-1',
  '@select-background': '$.@gray-1',
  '@item-hover-bg': '$.@gray-1',
  '@primary-color': '$.@blue',
  '@primary-color-light': '$.@blue-light',
  '@primary-color-extra-light': '$.@blue-light',
  '@text-color': '$.@gray-6',
  '@text-color-secondary': '$.@gray-5',
  '@heading-color': '$.@gray-7',
  '@border-color-base': '$.@gray-2',
  '@border-color-split': transparentize(0.25, colors['@gray-2']),
  '@border-radius-base': '$.@size-xxs',
  '@border-radius-medium': '$.@size-xs',
  '@border-radius-large': '$.@size-s',
  '@border-width-base': '0px',
  '@border-color-dark': '$.@gray-3',

  '@layout-header-background': 'transparent',
  '@layout-body-background': 'white',

  '@menu-bg': 'transparent',
  '@menu-item-active-bg': '$.@gray-1',
  '@menu-inline-toplevel-item-height': '$.@size-xl',
  '@dropdown-line-height': '$.@size-l',

  '@tabs-horizontal-margin': `0 ${sizes['@size-m']} 0 0`,
  '@tabs-horizontal-padding': '@padding-sm 0',
  '@tabs-horizontal-padding-lg': '@padding-md 0',
  '@tabs-horizontal-padding-sm': '@padding-xs 0',
  '@tabs-ink-bar-color': 'transparent',

  '@modal-mask-bg': transparentize(0.5, colors['@gray-6']),

  '@checkbox-size': '$.@size-m',
  '@checkbox-check-bg': '$.@gray-1',

  '@page-header-padding': '0',
  '@page-header-padding-vertical': '0',
  '@page-header-back-color': '@text-color-secondary',

  '@collapse-header-padding': `${sizes['@size-xs']} 0`,
  '@collapse-header-bg': '@component-background',
  '@collapse-content-padding': `${sizes['@size-xxs']} ${sizes['@size-s']} ${sizes['@size-m']} ${sizes['@size-s']}`,

  '@avatar-bg': '$.@gray-5',

  '@modal-footer-padding-vertical': '$.@size-s',
  '@modal-body-padding': '$.@size-m',
  '@modal-footer-padding-horizontal': '$.@size-m',

  '@heading-1-size': '$.@size-xl',
  '@heading-2-size': '$.@size-l',
  '@heading-3-size': '$.@size-s',

  '@font-size-base': '$.@size-s',
  '@font-size-lg': '$.@size-xm',
  '@font-size-larger': '21px',
  '@font-size-sm': '14px',
  '@font-size-xs': '12px',

  '@badge-font-size': '12px',

  '@padding-lg': '$.@size-m',
  '@padding-md': '$.@size-xm',
  '@padding-sm': '$.@size-s',
  '@padding-xs': '$.@size-xs',

  '@form-item-margin-bottom': '$.@size-l',

  '@input-height-base': '@size-xl - @size-xs',
  '@input-height-lg': '@input-height-base + @size-xs',
  '@input-height-sm': '@input-height-base - @size-xs',
  '@btn-height-base': '@input-height-base',
  '@btn-height-lg': '@input-height-lg',
  '@btn-height-sm': '@input-height-sm',

  '@switch-height': '26px',
  '@switch-sm-height': '20px',
  '@switch-min-width': '50px',
  '@switch-sm-min-width': '38px',

  '@line-height-base': '1.5',

  '@PageHeader-topMargin': '$.@size-xxs',
})

export const theme = resolve({
  ...colors,
  ...sizes,
  ...themeBase(),
})

const originalColors = { ...colors }
colors['@gray-0'] = originalColors['@gray-7']
colors['@gray-1'] = originalColors['@gray-6']
colors['@gray-2'] = originalColors['@gray-5']
colors['@gray-3'] = originalColors['@gray-4']
colors['@gray-4'] = originalColors['@gray-3']
colors['@gray-5'] = originalColors['@gray-2']
colors['@gray-6'] = originalColors['@gray-1']
colors['@gray-7'] = originalColors['@gray-0']

export const darkTheme = resolve({
  ...colors,
  ...sizes,
  ...themeBase(),
})

export const uiTheme = s.mergeTheme({
  font: {
    default: "'Nunito', sans-serif;",
    code: "'Hasklig', 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
  },
  color: {
    special: {
      gradientSecondary: 'hsl(180, 93%, 33%)',
    },
    light: {
      gray: [
        'hsl(226, 43%, 100%)',
        'hsl(226, 43%, 99%)',
        'hsl(226, 43%, 98%)',
        'hsl(226, 38%, 95%)',
        'hsl(226, 32%, 91%)',
        'hsl(226, 27%, 86%)',
        'hsl(226, 22%, 79%)',
        'hsl(226, 16%, 63%)',
        'hsl(226, 11%, 54%)',
        'hsl(226, 43%, 21%)',
        'hsl(226, 43%, 15%)',
        'hsl(226, 43%, 8%)',
      ],
      blue: [
        'hsl(180, 125%, 100%)',
        'hsl(180, 113%, 99%)',
        'hsl(180, 100%, 96%)',
        'hsl(180, 100%, 90%)',
        'hsl(180, 100%, 82%)',
        'hsl(180, 100%, 72%)',
        'hsl(180, 100%, 60%)',
        'hsl(180, 100%, 46%)',
        'hsl(180, 100%, 29%)',
        'hsl(180, 100%, 27%)',
        'hsl(180, 100%, 21%)',
        'hsl(180, 100%, 10%)',
      ],
      green: [
        'hsl(102, 48%, 100%)',
        'hsl(102, 48%, 99%)',
        'hsl(102, 48%, 97%)',
        'hsl(102, 42%, 93%)',
        'hsl(102, 36%, 88%)',
        'hsl(102, 30%, 81%)',
        'hsl(102, 24%, 72%)',
        'hsl(102, 18%, 62%)',
        'hsl(102, 48%, 51%)',
        'hsl(102, 48%, 46%)',
        'hsl(102, 48%, 33%)',
        'hsl(102, 48%, 10%)',
      ],
      orange: [
        'hsl(33, 118%, 100%)',
        'hsl(33, 106%, 99%)',
        'hsl(33, 94%, 97%)',
        'hsl(33, 94%, 94%)',
        'hsl(33, 94%, 89%)',
        'hsl(33, 94%, 82%)',
        'hsl(33, 94%, 75%)',
        'hsl(33, 94%, 66%)',
        'hsl(33, 94%, 55%)',
        'hsl(33, 94%, 50%)',
        'hsl(33, 94%, 35%)',
        'hsl(33, 94%, 10%)',
      ],
      red: [
        'hsl(360, 83%, 100%)',
        'hsl(360, 83%, 99%)',
        'hsl(360, 83%, 97%)',
        'hsl(360, 73%, 94%)',
        'hsl(360, 62%, 89%)',
        'hsl(360, 52%, 82%)',
        'hsl(360, 42%, 74%)',
        'hsl(360, 31%, 65%)',
        'hsl(360, 83%, 54%)',
        'hsl(360, 83%, 49%)',
        'hsl(360, 83%, 34%)',
        'hsl(360, 83%, 10%)',
      ],
    },
    dark: {
      gray: [
        'hsl(226, 43%, 0%)',
        'hsl(226, 43%, 4%)',
        'hsl(226, 43%, 9%)',
        'hsl(226, 43%, 13%)',
        'hsl(226, 43%, 17%)',
        'hsl(226, 43%, 21%)',
        'hsl(226, 43%, 26%)',
        'hsl(226, 43%, 30%)',
        'hsl(226, 43%, 34%)',
        'hsl(226, 29%, 94%)',
        'hsl(226, 14%, 100%)',
        'hsl(226, 0%, 100%)',
      ],
      blue: [
        'hsl(180, 100%, 0%)',
        'hsl(180, 100%, 0%)',
        'hsl(180, 100%, 2%)',
        'hsl(180, 100%, 4%)',
        'hsl(180, 100%, 7%)',
        'hsl(180, 100%, 11%)',
        'hsl(180, 100%, 16%)',
        'hsl(180, 100%, 22%)',
        'hsl(180, 100%, 29%)',
        'hsl(180, 67%, 53%)',
        'hsl(180, 33%, 76%)',
        'hsl(180, 0%, 100%)',
      ],
      green: [
        'hsl(102, 48%, 0%)',
        'hsl(102, 48%, 1%)',
        'hsl(102, 48%, 3%)',
        'hsl(102, 48%, 7%)',
        'hsl(102, 48%, 13%)',
        'hsl(102, 48%, 20%)',
        'hsl(102, 48%, 29%)',
        'hsl(102, 48%, 39%)',
        'hsl(102, 48%, 51%)',
        'hsl(102, 32%, 67%)',
        'hsl(102, 16%, 84%)',
        'hsl(102, 0%, 100%)',
      ],
      orange: [
        'hsl(33, 94%, 0%)',
        'hsl(33, 94%, 1%)',
        'hsl(33, 94%, 3%)',
        'hsl(33, 94%, 8%)',
        'hsl(33, 94%, 14%)',
        'hsl(33, 94%, 21%)',
        'hsl(33, 94%, 31%)',
        'hsl(33, 94%, 42%)',
        'hsl(33, 94%, 55%)',
        'hsl(33, 63%, 70%)',
        'hsl(33, 31%, 85%)',
        'hsl(33, 0%, 100%)',
      ],
      red: [
        'hsl(360, 83%, 0%)',
        'hsl(360, 83%, 1%)',
        'hsl(360, 83%, 3%)',
        'hsl(360, 83%, 8%)',
        'hsl(360, 83%, 14%)',
        'hsl(360, 83%, 21%)',
        'hsl(360, 83%, 30%)',
        'hsl(360, 83%, 41%)',
        'hsl(360, 83%, 54%)',
        'hsl(360, 55%, 69%)',
        'hsl(360, 28%, 85%)',
        'hsl(360, 0%, 100%)',
      ],
    },
  },
})
