import { Form, Input } from '@thesisedu/react'
import { Row, VSpaced } from '@thesisedu/web'
import { IconSelect } from '@thesisedu/web/dist/IconSelect'
import { Col } from 'antd'
import React from 'react'

import { TagTypeSelect } from './TagTypeSelect'

export function TagForm() {
  return (
    <VSpaced align={'stretch'}>
      <Form.Item
        name={'name'}
        required={{ value: true, message: 'A tag name is required.' }}
        label={'Name'}
      >
        <Input size={'large'} />
      </Form.Item>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item name={'type'} label={'Type'}>
            <TagTypeSelect />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={'config.icon'} label={'Icon'}>
            <IconSelect />
          </Form.Item>
        </Col>
      </Row>
    </VSpaced>
  )
}
