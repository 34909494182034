import React from 'react'

import { RecordViewProps, RecordView } from './record/RecordView'
import { RecordViewWithSidebar } from './record/RecordViewWithSidebar'
import { MediaView } from './view/MediaView'
import { MediaViewWithSidebar } from './view/MediaViewWithSidebar'

export interface RecordOrViewProps extends RecordViewProps {
  value?: string
  onChange?: (mediaId?: string) => void
  children?: React.ReactNode
  withSidebar?: boolean
}
export function RecordOrView({
  withSidebar,
  value,
  onChange,
  children,
  ...rest
}: RecordOrViewProps) {
  const [processingMediaId, setProcessingMediaId] = React.useState<string | undefined>(undefined)
  const ViewComponent = withSidebar ? MediaViewWithSidebar : MediaView
  const RecordComponent = withSidebar ? RecordViewWithSidebar : RecordView
  if (value && value !== processingMediaId) {
    return <ViewComponent mediaId={value} children={children} />
  } else {
    return (
      <RecordComponent
        {...rest}
        children={children}
        onCreated={result => {
          setProcessingMediaId(result.media?.media.id)
          onChange?.(result.media?.media.id)
        }}
        onProcessed={() => {
          setProcessingMediaId(undefined)
        }}
      />
    )
  }
}
