import React from 'react'

export interface TableFilterContextValue {
  name: string | null
  setName: (name: string | null) => void
}
export const TableFilterContext = React.createContext<TableFilterContextValue | undefined>(
  undefined,
)

export function useTableFilterContext(): TableFilterContextValue | undefined
export function useTableFilterContext(require: false): TableFilterContextValue | undefined
export function useTableFilterContext(require: true): TableFilterContextValue
export function useTableFilterContext(require?: boolean): TableFilterContextValue | undefined {
  const context = React.useContext(TableFilterContext)
  if (!context && require) {
    throw new Error('TableFilterContext is required, yet not provided.')
  }
  return context
}
