import { DeepLinkResource, ReactFeature, ReactUse } from '@thesisedu/feature-react'

import { SheetMusicCompositionDeepLinkRemapper } from './DeepLinkRemapper'
import { InstrumentResource, KeyboardComponent } from './types'

export interface SoundEffects {
  play: (sound: string) => void
}
export interface SheetMusicReactFeatureOptions {
  getSoundEffects: () => SoundEffects
  getKeyboard: () => KeyboardComponent
  instruments: Omit<InstrumentResource, 'type'>[]
}

export class SheetMusicReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-sheet-music-react'
  static path = ''
  static requires: string[] = []
  public options!: SheetMusicReactFeatureOptions
  public soundEffects!: SoundEffects
  public keyboard!: KeyboardComponent

  public reactResources() {
    require('./hooks/classRoutes').default(this)

    this.keyboard = this.options.getKeyboard()
    this.soundEffects = this.options.getSoundEffects()
    for (const instrument of this.options.instruments) {
      this.resources.addResource<InstrumentResource>({
        type: 'Instrument',
        ...instrument,
      })
    }
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'sheet-music-composition',
      params: [{ identifier: 'id', required: true }],
      Component: SheetMusicCompositionDeepLinkRemapper,
    })
    const { addClassConfigurationFields } = require('./resources/classConfiguration')
    addClassConfigurationFields(this)
    const coursesFeature = this.root.getFeature('@thesisedu/feature-courses-react')
    if (coursesFeature) {
      require('./resources/Widgets').default(this)
    }
    const courseAssignmentsFeature = this.root.getFeature(
      '@thesisedu/feature-course-assignments-react',
    )
    if (courseAssignmentsFeature) {
      const { addQuestionTypes } = require('./resources/questionTypes/index')
      addQuestionTypes(this)
      const { addAssignmentGraders } = require('@thesisedu/feature-sheet-music-core')
      addAssignmentGraders(this)
    }
  }
}

export const sheetMusicReactFeature: ReactUse<SheetMusicReactFeatureOptions> = (
  opts: SheetMusicReactFeatureOptions,
) => [SheetMusicReactFeature, opts]
