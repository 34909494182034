import { useBulkActionsWithModal } from '@thesisedu/feature-apollo-react'
import { Text, Dropdown, Modal, VSpaced } from '@thesisedu/ui'
import { MinusCircle, Refresh } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentBulkActionItemProps } from './AssignmentBulkActions'
import { refetchQueries } from './refetchQueries'
import {
  ResetAssignmentSubmissionsDocument,
  ResetAssignmentSubmissionsMutationVariables,
} from '../../schema'

export function ResetSubmissions({ selections, assignment }: AssignmentBulkActionItemProps) {
  const [_run, { modal }] = useBulkActionsWithModal()
  const run = React.useCallback(
    async (andClear: boolean) => {
      await _run<ResetAssignmentSubmissionsMutationVariables>({
        message: andClear ? 'Resetting submissions and clearing answers' : 'Resetting submissions',
        mutation: ResetAssignmentSubmissionsDocument,
        refetchQueries: refetchQueries(assignment.id),
        variables: selections.map(selection => ({
          input: {
            id: assignment.id,
            studentId: selection.studentId,
            preserveLatestSubmission: !andClear,
          },
        })),
      })
    },
    [_run],
  )

  return (
    <>
      {modal}
      <Dropdown.SubMenu>
        <Dropdown.SubMenu.Trigger
          danger
          disabled={!assignment.canReset}
          icon={<Refresh />}
          children={'Reset Submissions'}
        />
        <Dropdown.SubMenu.Content>
          <DropdownItem assignment={assignment} andClear onConfirm={() => run(true)} />
          <DropdownItem assignment={assignment} onConfirm={() => run(false)} />
        </Dropdown.SubMenu.Content>
      </Dropdown.SubMenu>
    </>
  )
}

interface DropdownItemProps {
  andClear?: boolean
  onConfirm: () => Promise<void>
  assignment: AssignmentBulkActionItemProps['assignment']
}
function DropdownItem({ onConfirm, andClear, assignment }: DropdownItemProps) {
  const timeLimitMessage = assignment.timeLimit ? (
    <Text>
      <strong>This assignment has a time limit.</strong> The student will have{' '}
      {Math.round(assignment.timeLimit / 60)} minutes from now to complete this assignment.
    </Text>
  ) : null
  return (
    <Modal.Confirm
      trigger={
        <Dropdown.Item
          danger
          disabled={!assignment.canReset}
          icon={andClear ? <MinusCircle /> : <Refresh />}
          children={andClear ? 'Reset and Clear Answers' : 'Unsubmit'}
        />
      }
      title={'Are you sure?'}
      width={600}
      confirm={{ children: 'Yes, reset', status: 'danger' }}
      onConfirm={onConfirm}
    >
      {andClear ? (
        <VSpaced>
          <Text>
            The student's submissions will be reset, and grades / comments cleared.{' '}
            <strong>Their existing answers will also be cleared.</strong>
          </Text>
          <Text>Are you sure you would like to continue?</Text>
        </VSpaced>
      ) : (
        <VSpaced>
          <Text>
            The student's submissions will be reset, and grades / comments cleared.{' '}
            <strong>Their existing answers will be preserved.</strong>
          </Text>
          {timeLimitMessage}
          <Text>Are you sure you would like to continue?</Text>
        </VSpaced>
      )}
    </Modal.Confirm>
  )
}
