import { Button, ButtonProps } from '@thesisedu/react'
import React from 'react'

import {
  useAssignmentViewerContext,
  useAssignmentViewerFormContext,
} from './AssignmentViewerFormContext'
import { useSubmissionContext } from '../../contexts/SubmissionContext'

export interface FormSaveButtonProps extends Partial<ButtonProps> {}
export function FormSaveButton({ disabled: _disabled, ...rest }: FormSaveButtonProps) {
  const { classId, assignment } = useAssignmentViewerContext()
  const {
    onSaveSubmission,
    loading,
    groups: { areAnswersShared, isGroupLeader },
  } = useSubmissionContext(true)
  const { form, actionsDisabled, timeLimitReached } = useAssignmentViewerFormContext(true)
  const disabled =
    _disabled || actionsDisabled || timeLimitReached || (areAnswersShared && !isGroupLeader)
  return (
    <Button
      onClick={() => {
        return onSaveSubmission({
          id: assignment.id,
          classId,
          submissionData: form.getValues(),
        })
      }}
      loading={loading}
      children={'Save Answers'}
      data-testid={'FormSaveButton'}
      {...rest}
      disabled={disabled}
    />
  )
}
