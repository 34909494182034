import { Button, Tooltip } from '@thesisedu/ui'
import { Download } from '@thesisedu/ui/icons'
import React from 'react'

export interface DownloadFooterItemProps {
  url: string
}
export function DownloadFooterItem({ url }: DownloadFooterItemProps) {
  return (
    <Tooltip title={'Download'}>
      <Button
        variant={'chromeless'}
        children={<Download />}
        onPress={() => {
          window.open(url)
        }}
        style={{ color: 'white' }}
      />
    </Tooltip>
  )
}
