import ArrowLeftOutlined from '@ant-design/icons/lib/icons/ArrowLeftOutlined'
import { ErrorPage } from '@thesisedu/web'
import React from 'react'

export interface Error403Props {
  onBack: () => void
}
export function Error403({ onBack }: Error403Props) {
  return (
    <ErrorPage
      title={'Access denied.'}
      technical={'Error 403'}
      description={
        "Uh oh. It looks like you don't have access to view this page. Maybe you're not logged in?"
      }
      action={
        <>
          <ArrowLeftOutlined />
          <span>Go Back</span>
        </>
      }
      onActionClick={onBack}
    />
  )
}
