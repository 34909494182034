import { useResource } from '@thesisedu/feature-react'
import { VSpaced, HSpaced } from '@thesisedu/ui'
import { orderBy } from 'lodash'
import React from 'react'

import { SAMLButtonPlacement, SAMLButtonResource } from '../../types'

export interface SAMLButtonsProps {
  onRegister?: () => void | Promise<void>
  placement: SAMLButtonPlacement
}
export function SAMLButtons(props: Omit<SAMLButtonsProps, 'placement'>) {
  const buttons = useResource<SAMLButtonResource>('SAMLButton')
  const sorted = orderBy(buttons, 'weight', 'asc')
  const prominentButtons: SAMLButtonResource[] = []
  const secondaryButtons: SAMLButtonResource[] = []
  for (const button of sorted) {
    const placement = button.placement ?? SAMLButtonPlacement.Prominent
    if (placement === SAMLButtonPlacement.Prominent) {
      prominentButtons.push(button)
    } else if (placement === SAMLButtonPlacement.Secondary) {
      secondaryButtons.push(button)
    }
  }
  return (
    <VSpaced space={'xs'} align={'stretch'}>
      {prominentButtons.map(item => {
        const Button = item.component
        return <Button key={item.identifier} placement={SAMLButtonPlacement.Prominent} {...props} />
      })}
      {secondaryButtons.length ? (
        <HSpaced space={'xs'} justify={'stretch'}>
          {secondaryButtons.map(button => {
            const Button = button.component
            return (
              <Button
                key={button.identifier}
                placement={SAMLButtonPlacement.Secondary}
                {...props}
              />
            )
          })}
        </HSpaced>
      ) : null}
    </VSpaced>
  )
}
