import { useMutateHook } from '@thesisedu/feature-react'
import { useNavigate } from '@thesisedu/react'
import { VSpaced, Text, Block, styled, s } from '@thesisedu/ui'
import React from 'react'

import { WithTeacherClass, WithTeacherClassChildProps } from '../../WithClass'
import { ArchiveClassButton } from '../../teacher/ArchiveClassButton'
import { BasicClassSettings } from '../../teacher/BasicClassSettings'
import { ClassDangerZoneItemsHook, ClassSettingsGeneralItemsHook } from '../../types'

function _GeneralSettings({ cls }: WithTeacherClassChildProps) {
  const navigate = useNavigate()
  const dangerZoneItems = useMutateHook<ClassDangerZoneItemsHook>(
    'classes-react:danger-zone-items',
    [],
    {
      cls,
    },
  )
  const generalItems = useMutateHook<ClassSettingsGeneralItemsHook>(
    'classes-react:settings-general-items',
    [],
    { cls },
  )

  return (
    <>
      <Block bottom={'xl'}>
        <BasicClassSettings cls={cls} />
      </Block>
      {generalItems.length ? <VSpaced bottom={'m'}>{generalItems}</VSpaced> : null}
      <DangerZoneContainer>
        <Text level={'h3'} color={'red'}>
          Danger Zone
        </Text>
        {dangerZoneItems}
        <DangerZoneItem
          title={'Archive this class.'}
          description={
            <>
              If you archive this class, it will no longer show up in your list of classes, and you
              will not be able to interact with it. You will still be able to view the class for
              reference purposes.
            </>
          }
          action={
            <ArchiveClassButton classId={cls.id} onComplete={() => navigate('/teacher/classes')} />
          }
        />
      </DangerZoneContainer>
    </>
  )
}

export function GeneralSettings() {
  return <WithTeacherClass selected render={_GeneralSettings} />
}

export interface DangerZoneItemProps {
  title: React.ReactElement | string
  description: React.ReactElement | string
  action: React.ReactElement
}
export function DangerZoneItem({ title, description, action }: DangerZoneItemProps) {
  return (
    <DangerZoneItemContainer>
      <VSpaced align={'flex-start'} space={'xxs'}>
        <Text weight={'bold'}>{title}</Text>
        <Text color={'secondary'} level={'s'}>
          {description}
        </Text>
      </VSpaced>
      <div style={{ flexShrink: 0 }}>{action}</div>
    </DangerZoneItemContainer>
  )
}

const DangerZoneContainer = styled.div`
  border: solid 1px ${s.color('red')};
  border-radius: ${s.var('radii.2')};
  padding: ${s.size('m')};
  background: ${s.color('background')};
`
const DangerZoneItemContainer = styled.div`
  margin: ${s.size('s')} calc(-1 * ${s.size('m')});
  padding: ${s.size('s')} ${s.size('m')};
  border-bottom: solid 1px ${s.color('border')};
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  gap: ${s.size('l')};
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`
