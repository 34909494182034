import { AuthRequiredPayload, AuthProviderWrapper } from '@thesisedu/feature-users-react'
import React from 'react'

import { useCanvasAssociate } from './useCanvasLogin'
import { useCanvasScopesQuery } from '../schema'

export interface AuthProviderInnerProps extends AuthRequiredPayload {
  scopes: string[]
}
function _AuthProviderInner({ onSuccess, onError, scopes, ...opts }: AuthProviderInnerProps) {
  const { signIn, associating } = useCanvasAssociate({ scopes, onSuccess, onError })
  return (
    <AuthProviderWrapper
      identifier={'canvas'}
      onAuth={signIn}
      associating={associating}
      onError={onError}
      {...opts}
    />
  )
}
export const AuthProviderInner = React.memo(_AuthProviderInner, () => false)

export function AuthProviderHandler(props: AuthRequiredPayload) {
  const { data } = useCanvasScopesQuery({ fetchPolicy: 'network-only' })
  const scopes = data?.canvasScopes
  return scopes?.length ? <AuthProviderInner scopes={scopes} {...props} /> : null
}
