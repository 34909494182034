import { Modal, Markdown, Button, Tooltip, ClickableContainer, Body } from '@thesisedu/react'
import { Message } from '@thesisedu/react/icons'
import React, { useState } from 'react'

export interface CommentViewerProps {
  comment: string
}
export function CommentViewer({ comment }: CommentViewerProps) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <ClickableContainer onClick={() => setVisible(true)} data-testid={'CommentViewer'}>
        <Tooltip title={'View Teacher Comments'}>
          <Body color={'@blue'}>
            View Comments <Message />
          </Body>
        </Tooltip>
      </ClickableContainer>
      <Modal
        title={'Teacher Comments'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <Markdown skipHtml children={comment} />
      </Modal>
    </>
  )
}
