import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { GradingModalNavigationItemsHook } from '../../node'

export default function (feature: AssignmentsReactFeature) {
  // Hide the groups tab for generated assignments.
  feature.hookManager.registerMutateHook<GradingModalNavigationItemsHook>(
    'feature-assignments-react:grading-modal-navigation-items',
    (items, { assignment }) => {
      if (assignment.generated) {
        return items.filter(item => item.key !== 'groups')
      }
      return items
    },
  )
}
