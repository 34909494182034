import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'

import ResetCustomizations from './ResetCustomizations'
import disableStudentOverrides from './disableStudentOverrides'
import disableVideoSeekLimitation from './disableVideoSeekLimitation'
import { CoursesReactFeature } from '../../CoursesReactFeature'

const RESOURCES: ClassConfigurationFieldResource[] = [
  disableVideoSeekLimitation,
  disableStudentOverrides,
]
export function addClassConfigurationFields(feature: CoursesReactFeature) {
  for (const resource of RESOURCES) {
    feature.resources.addResource(resource)
  }
  ResetCustomizations(feature)
}
