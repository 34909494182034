import { Paths } from '@thesisedu/feature-types'
import { get } from 'lodash'

import { getAliases } from './theme'
import { _themePathToVariable, _themePathToVariableName } from './themePathToVariableName'
import {
  ColorAliases,
  ColorScale,
  ColorScaleAliases,
  Size,
  SizeAliases,
  Theme,
  ThemeColorVariant,
  ThemePath,
  Aliases,
  FontSizeAliases,
} from './types'

export type ColorAlias =
  | `${keyof ColorAliases | 'overlayBlack' | 'overlayWhite'}.${keyof ColorScaleAliases}`
  | keyof ColorAliases
  | keyof ColorScaleAliases
export function colorToThemePath(path: ColorAlias, colorVariant?: ThemeColorVariant): ThemePath
export function colorToThemePath(path: string, colorVariant?: ThemeColorVariant): ThemePath
export function colorToThemePath(
  path: string,
  colorVariant: ThemeColorVariant = 'light',
): ThemePath {
  const [colorAlias, colorScaleAlias] = path.split('.') as [
    keyof ColorAliases,
    keyof ColorScaleAliases,
  ]
  const aliases = getAliases()
  const aliasIsScale =
    !colorScaleAlias && aliases.colorScales[colorAlias as keyof ColorScaleAliases] !== undefined
  let color = aliases.colors[colorAlias] || colorAlias
  let colorScale = aliases.colorScales[colorScaleAlias || 'solid']
  if (aliasIsScale) {
    color = 'gray'
    colorScale = aliases.colorScales[colorAlias as keyof ColorScaleAliases]
  }

  return `color.${colorVariant}.${color}.${colorScale}` as ThemePath
}

export function colorToVariable(path: ColorAlias): string
export function colorToVariable(path: string): string {
  return _themePathToVariable(`color.${path}`)
}

export type SizeAlias = Paths<SizeAliases>
export type AnySize = Paths<SizeAliases> | Paths<Size>

export function sizeToThemePath(path: AnySize): ThemePath
export function sizeToThemePath(path: SizeAlias): ThemePath
export function sizeToThemePath(path: Paths<Size>): ThemePath
export function sizeToThemePath(path: AnySize): ThemePath {
  const aliases = getAliases()
  const aliasPath = get(aliases.sizes, path)
  if (typeof aliasPath === 'string') {
    return `size.${aliasPath}` as ThemePath
  } else {
    return `size.${path}` as ThemePath
  }
}

export function sizeToVariable(path: AnySize): string
export function sizeToVariable(path: SizeAlias): string
export function sizeToVariable(path: Paths<Size>): string
export function sizeToVariable(path: AnySize): string {
  return _themePathToVariable(`size.${path}`)
}

interface InnerThemeObject {
  [key: string | number]: string | number | InnerThemeObject
}
function _getAllVariablesAndValues(
  theme: InnerThemeObject,
  parentKeys: string[] = [],
  noPrefix?: boolean,
): Record<string, string> {
  let result: Record<string, string> = {}
  const keys = Array.isArray(theme) ? theme.map((_, i) => i) : Object.keys(theme)
  for (const key of keys) {
    const value = theme[key]
    if (value === undefined || value === null) continue
    if (typeof value === 'number') {
      const variable = _themePathToVariableName([...parentKeys, key].join('.'), noPrefix)
      result[variable] = `${value}px`
    } else if (typeof value === 'string') {
      const variable = _themePathToVariableName([...parentKeys, key].join('.'), noPrefix)
      result[variable] = value
    } else if (Array.isArray(value) || typeof value === 'object') {
      result = {
        ...result,
        ..._getAllVariablesAndValues(value, [...parentKeys, key.toString()], noPrefix),
      }
    }
  }

  return result
}

export function variablesToCss(variables: Record<string, string>): string {
  return Object.keys(variables)
    .map(key => {
      const value = variables[key]
      return `${key}: ${value};`
    })
    .join('\n')
}

function getAllColorThemeValues(scale: ColorScale, aliases: Aliases): InnerThemeObject {
  return {
    ...scale.reduce((acc, scaleItem, index) => {
      return { ...acc, [index.toString()]: scaleItem }
    }, {}),
    ...Object.entries(aliases.colorScales).reduce((acc, [key, value]) => {
      return { ...acc, [key]: scale[value] }
    }, {}),
  }
}

function getAllSizeThemeValues(
  size: Theme['size'],
  aliases: Partial<SizeAliases> | FontSizeAliases,
): InnerThemeObject {
  return {
    ...size,
    ...Object.entries(aliases).reduce((acc, [key, value]) => {
      return { ...acc, [key]: get(size, value) }
    }, {}),
  }
}

const DEFAULT_SCALE: number = 8
export function getColorVariablesAndValues(
  theme: Theme,
  colorVariant: ThemeColorVariant,
  noPrefix?: boolean,
): Record<string, string> {
  const {
    color: { overlayBlack, overlayWhite, special, ...restColor },
    aliases,
  } = theme
  return {
    ..._getAllVariablesAndValues(
      {
        overlayBlack: getAllColorThemeValues(overlayBlack, aliases),
        overlayWhite: getAllColorThemeValues(overlayWhite, aliases),
        ...Object.entries(restColor[colorVariant]).reduce((acc, [color, scale]) => {
          return { ...acc, [color]: getAllColorThemeValues(scale, aliases) }
        }, {}),
        ...Object.entries(aliases.colors).reduce((acc, [alias, colorName]) => {
          return {
            ...acc,
            [alias]: getAllColorThemeValues(
              restColor[colorVariant][colorName as keyof Theme['color']['light']],
              aliases,
            ),
          }
        }, {}),
      } as any,
      ['color'],
      noPrefix,
    ),
    ..._getAllVariablesAndValues(special, ['color', 'special'], noPrefix),
    ..._getAllVariablesAndValues(
      {
        overlayBlack: overlayBlack[DEFAULT_SCALE],
        overlayWhite: overlayWhite[DEFAULT_SCALE],
        ...Object.entries(aliases.colorScales).reduce((acc, [scaleAlias, scaleValue]) => {
          return { ...acc, [scaleAlias]: restColor[colorVariant]['gray'][scaleValue] }
        }, {}),
        ...Object.entries(restColor[colorVariant]).reduce((acc, [color, scale]) => {
          return { ...acc, [color]: scale[DEFAULT_SCALE] }
        }, {}),
        ...Object.entries(aliases.colors).reduce((acc, [alias, colorName]) => {
          return {
            ...acc,
            [alias]:
              restColor[colorVariant][colorName as keyof Theme['color']['light']][DEFAULT_SCALE],
          }
        }, {}),
      },
      ['color'],
      noPrefix,
    ),
  }
}

export function getSizeVariablesAndValues(
  theme: Theme,
  noPrefix?: boolean,
): Record<string, string> {
  const { font, ...rest } = theme.aliases.sizes
  return {
    ..._getAllVariablesAndValues(
      {
        ...getAllSizeThemeValues(theme.size, rest),
      },
      ['size'],
      noPrefix,
    ),
    ..._getAllVariablesAndValues(
      getAllSizeThemeValues(theme.size, font),
      ['size', 'font'],
      noPrefix,
    ),
  }
}

export function getAllVariablesAndValues(
  theme: Theme,
  colorVariant: ThemeColorVariant,
): Record<string, string> {
  const { aliases, color, size, ...restTheme } = theme
  const colorVariables = getColorVariablesAndValues(theme, colorVariant, true)
  const sizeVariables = getSizeVariablesAndValues(theme, true)
  return _getAllVariablesAndValues({
    ...restTheme,
    ...colorVariables,
    ...sizeVariables,
  } as any)
}
