import type { Stripe } from '@stripe/stripe-js'
// importing from /pure makes it so we don't request stripe until this function is called
import { loadStripe } from '@stripe/stripe-js/pure'
import { Format } from '@thesisedu/feature-utils'
import { useFeature } from '@thesisedu/feature-web'
import { BlockSpin, Body, BodyLarge, BodySmall, Row, Block } from '@thesisedu/web'
import { Button, Col, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import pluralize from 'pluralize'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import LicensesReactFeature from './LicensesReactFeature'
import {
  CheckoutSessionFragment,
  CheckoutSessionType,
  useCreateCheckoutSessionMutation,
} from './schema'

export const LicenseInvalidContent: React.FC<React.PropsWithChildren<LicenseInvalidModalProps>> = ({
  classId,
}) => {
  const [stripe, setStripe] = useState<Stripe>()
  const location = useLocation()
  const feature = useFeature<LicensesReactFeature>(LicensesReactFeature.package)
  useEffect(() => {
    loadStripe(feature.options.stripeKey).then(st => {
      if (st) {
        setStripe(st)
      }
    })
  }, [feature])
  const [createCheckoutSession, { loading }] = useCreateCheckoutSessionMutation()
  const [checkoutSessions, setCheckoutSessions] = useState<CheckoutSessionFragment[]>()
  const [license, setLicense] = useState<{ payUpFrontMonths?: number | null }>()
  useEffect(() => {
    if (classId) {
      createCheckoutSession({
        variables: {
          input: {
            id: classId,
            successUrl: `${window.location.origin}${location.pathname}?licenseStatus=success`,
            cancelUrl: `${window.location.origin}${location.pathname}?licenseStatus=cancel`,
          },
        },
      })
        .then(result => {
          if (result.data?.createCheckoutSession.sessions.length) {
            setCheckoutSessions(result.data!.createCheckoutSession.sessions)
            setLicense(result.data!.createCheckoutSession.license)
          } else {
            setCheckoutSessions(undefined)
            setLicense(undefined)
          }
        })
        .catch(() => {
          setCheckoutSessions(undefined)
          setLicense(undefined)
        })
    }
  }, [classId])

  let modalContent
  if (loading) {
    modalContent = <BlockSpin />
  } else if (classId && checkoutSessions) {
    modalContent = (
      <>
        <BodyLarge isBlock>You'll need to subscribe in order to continue.</BodyLarge>
        <Body isBlock>
          Your instructor requires you to pay for your Collabra account before you can begin
          recording. Choose from one of our flexible ways to pay below, and you'll be practicing in
          no time!
        </Body>
        <Block marginBottom={'@size-l'}>
          <Row>
            {checkoutSessions!.map(session => (
              <Col xs={24} md={12} key={session.id}>
                <Button
                  size={'large'}
                  type={'primary'}
                  loading={!stripe}
                  block
                  onClick={() => {
                    if (stripe) {
                      stripe.redirectToCheckout({
                        sessionId: session.id,
                      })
                    }
                  }}
                >
                  <BodyLarge>
                    {Format.number(session.amountInCents / 100, 'decimalCurrency')}
                    {session.type === CheckoutSessionType.Monthly ? (
                      <BodySmall style={{ display: 'inline' }}>&nbsp;/mo</BodySmall>
                    ) : (
                      <BodySmall style={{ display: 'inline' }}>
                        &nbsp;
                        {license && license.payUpFrontMonths
                          ? `for ${pluralize('month', license.payUpFrontMonths, true)}`
                          : 'up front'}
                      </BodySmall>
                    )}
                  </BodyLarge>
                </Button>
              </Col>
            ))}
          </Row>
        </Block>
        <BodySmall color={'@text-color-secondary'}>
          When you click one of the buttons above, you will be taken to Stripe, our payment partner.
        </BodySmall>
      </>
    )
  } else if (classId) {
    modalContent = (
      <>
        <BodyLarge isBlock>Uh oh!</BodyLarge>
        <Body>
          It looks like something went wrong. This is likely because your Collabra license is out of
          seats or expired. Talk to your instructor and have them reach out to Collabra support to
          resolve the issue.
        </Body>
      </>
    )
  } else {
    modalContent = (
      <>
        <BodyLarge isBlock>
          It looks like you have exceeded your quota for private recordings.
        </BodyLarge>
        <Body>You'll need to delete some practices in order to upload more.</Body>
      </>
    )
  }

  return modalContent
}

export interface LicenseInvalidModalProps extends Pick<ModalProps, 'onCancel' | 'title'> {
  classId?: string
}
export const LicenseInvalidModal: React.FC<React.PropsWithChildren<LicenseInvalidModalProps>> = ({
  onCancel,
  title,
  ...props
}) => {
  return (
    <Modal title={title} visible={!!title} onCancel={onCancel} footer={null}>
      <LicenseInvalidContent {...props} />
    </Modal>
  )
}
