import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { styled, usePortalContainer } from '@thesisedu/ui'
import React from 'react'

import { useEditorContext } from './context/EditorContext'
import { SelectedElementProvider } from './context/SelectedElementContext'
import { SharedHistoryContext, useSharedHistoryContext } from './context/SharedHistoryContext'
import { CalloutPlugin } from './plugins/CalloutPlugin'
import { DraggableBlockPlugin } from './plugins/DraggableBlockPlugin'
import { EmptyBlockAddMenuPlugin } from './plugins/EmptyBlockAddMenuPlugin'
import { EmptyEditorPlugin } from './plugins/EmptyEditorPlugin'
import { FilePlugin } from './plugins/FilePlugin'
import { FloatPlugin } from './plugins/FloatPlugin'
import { FloatingTextFormatToolbarPlugin } from './plugins/FloatingTextFormatToolbarPlugin'
import { HeadingIdentifiersPlugin } from './plugins/HeadingIdentifiersPlugin'
import { HorizontalRulePlugin } from './plugins/HorizontalRulePlugin'
import { ImagePlugin } from './plugins/ImagePlugin'
import { LayoutPlugin } from './plugins/LayoutPlugin'
import { LinkEditorPlugin } from './plugins/LinkEditorPlugin'
import { LinkPlugin } from './plugins/LinkPlugin'
import { ListMaxIndentLevelPlugin } from './plugins/ListMaxIndentLevelPlugin'
import { ListPlugin } from './plugins/ListPlugin'
import { MarkPlugin } from './plugins/MarkPlugin'
import { MarkdownShortcutPlugin } from './plugins/MarkdownShortcutPlugin'
import { ReadOnlyPlugin } from './plugins/ReadOnlyPlugin'
import { RichTextPlugin } from './plugins/RichTextPlugin'
import { TablePlugin } from './plugins/TablePlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import { ValidateNodesPlugin } from './plugins/ValidateNodesPlugin'
import { ElementsMenuProvider } from './ui/ElementsMenu/ElementsMenuContext'
import { PlaceholderContainer } from './ui/PlaceholderContainer'
import { SettingsDropdownProvider } from './ui/SettingsDropdown/SettingsDropdownContext'
import { TextFormatToolbarProvider } from './ui/TextFormatToolbar/TextFormatToolbarContext'
import { WidgetResource } from '../types'

export enum PluginLevel {
  Bare = 0,
  Simple = 1,
  Full = 2,
}

export interface EditorPluginsProps {
  level?: PluginLevel
  placeholder?: React.ReactElement | string | false
  toolbarHost?: React.RefObject<HTMLDivElement>
}
export function EditorPlugins({
  toolbarHost,
  level = PluginLevel.Full,
  placeholder,
}: EditorPluginsProps) {
  const { readOnly, noAdd, anchorRef, editorsRef } = useEditorContext(true)
  const portalContainer = usePortalContainer()
  const { historyState } = useSharedHistoryContext()
  const viewer = useViewerContext(false)
  const isAdmin = viewer?.group === 'ADMIN' || viewer?.group === 'CONTENT_MANAGER'
  const [editor] = useLexicalComposerContext()
  const anchor = anchorRef.current || document.body
  const resources = useResource<WidgetResource>('Widget')
  React.useEffect(() => {
    editorsRef.current.add(editor)
    return () => {
      editorsRef.current.delete(editor)
    }
  }, [])
  const content = (
    <>
      {toolbarHost && !readOnly ? <ToolbarPlugin host={toolbarHost} /> : null}
      <RichTextPlugin
        contentEditable={<Editable />}
        placeholder={
          placeholder === false ? null : (
            <PlaceholderContainer>
              {placeholder || <span>Start typing...</span>}
            </PlaceholderContainer>
          )
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ValidateNodesPlugin />
      {level >= PluginLevel.Bare ? (
        <>
          <LinkPlugin />
          <MarkPlugin />
          {readOnly ? null : (
            <>
              <HistoryPlugin externalHistoryState={historyState} />
              <MarkdownShortcutPlugin />
              {toolbarHost ? null : <FloatingTextFormatToolbarPlugin anchorElement={anchor} />}
              <LinkEditorPlugin anchorElement={anchor} />
              <EmptyEditorPlugin />
            </>
          )}
        </>
      ) : null}
      {level >= PluginLevel.Simple ? (
        <>
          <ListPlugin />
          {readOnly ? null : (
            <>
              <ImagePlugin anchorElement={anchor} />
              <FilePlugin />
              <HorizontalRulePlugin />
              <ListMaxIndentLevelPlugin />
              <DraggableBlockPlugin noAdd={noAdd} anchorElement={portalContainer} />
              {noAdd ? null : <EmptyBlockAddMenuPlugin />}
              <TabIndentationPlugin />
              <TablePlugin />
              {resources
                .filter(r => r.plugin)
                .map(({ identifier, plugin }) => {
                  const Plugin = plugin!
                  return <Plugin editor={editor} key={identifier} />
                })}
            </>
          )}
        </>
      ) : null}
      {level >= PluginLevel.Full && !readOnly ? (
        <>
          <LayoutPlugin anchorElement={anchor} />
          {isAdmin ? <FloatPlugin anchorElement={anchor} /> : null}
          <CalloutPlugin />
        </>
      ) : null}
      {readOnly ? (
        <>
          <HeadingIdentifiersPlugin />
          <ReadOnlyPlugin />
        </>
      ) : null}
    </>
  )

  return content
}

export function EditorPluginsProvider({ children }: React.PropsWithChildren<object>) {
  return (
    <SharedHistoryContext>
      <SelectedElementProvider>
        <ElementsMenuProvider>
          <TextFormatToolbarProvider>
            <SettingsDropdownProvider>{children}</SettingsDropdownProvider>
          </TextFormatToolbarProvider>
        </ElementsMenuProvider>
      </SelectedElementProvider>
    </SharedHistoryContext>
  )
}

export function NestedEditorPluginsProvider({ children }: React.PropsWithChildren<object>) {
  return (
    <SelectedElementProvider>
      <ElementsMenuProvider>
        <TextFormatToolbarProvider>
          <SettingsDropdownProvider>{children}</SettingsDropdownProvider>
        </TextFormatToolbarProvider>
      </ElementsMenuProvider>
    </SelectedElementProvider>
  )
}

const Editable = styled(ContentEditable)`
  outline: none;
`
