import { styled } from '@thesisedu/ui'

export const PlaceholderContainer = styled.div`
  position: absolute;
  opacity: 0.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  user-select: none;
  overflow: hidden;
  pointer-events: none;
  display: inline-block;
`
