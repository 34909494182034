import React from 'react'

import { styled, s } from '../../'
import { HTMLProps } from '../../sharedTypes'

export interface OverlayProps extends HTMLProps<HTMLDivElement> {}
export function Overlay(props: OverlayProps) {
  return <_Overlay {...props} />
}

export const overlayShow = s.keyframes`
  0% {
    opacity: 0;
    backdrop-filter: none;
  }
  100% {
    opacity: 1;
    backdrop-filter: blur(${s.var('blur.0')}) saturate(70%);
  }
`

export const OverlayStyles = s.css`
  background: ${s.color('overlayBlack.element')};
  backdrop-filter: blur(${s.var('blur.0')}) saturate(70%);
  animation: ${overlayShow} 0.25s ${s.var('curves.exponential')};
  z-index: ${s.var('zIndices.overlays')};
`
const _Overlay = styled.div`
  ${OverlayStyles}
`
