import { GradeTreeRecord, useGradeTree } from '@thesisedu/feature-assignments-react'
import {
  GradeTreeAssignmentExt,
  translateGradeTreeAssignment,
} from '@thesisedu/feature-assignments-react/dist/reporting/translateGradeTree'
import { useGradeIncompleteLateAsZero } from '@thesisedu/feature-assignments-react/dist/resources/class_configuration/GradeIncompleteLateAsZero'
import { BasicAssignmentFragment } from '@thesisedu/feature-assignments-react/dist/schema'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { SegmentProgress } from '@thesisedu/feature-courses-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import moment from 'moment'
import React, { useContext } from 'react'

import { isSegmentConfiguration } from '../helpers'

interface SegmentProgressMap {
  [segmentId: string]: SegmentProgress
}
interface SegmentGradeRecordMap {
  [segmentId: string]: GradeTreeRecord
}
export interface CourseAssignmentsContextValue {
  segmentProgress: SegmentProgressMap
  segmentGradeRecords: SegmentGradeRecordMap
}
export const CourseAssignmentsContext = React.createContext<CourseAssignmentsContextValue>({
  segmentProgress: {},
  segmentGradeRecords: {},
})

export function CourseAssignmentsContextProvider({ children }: React.PropsWithChildren<object>) {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'STUDENT') {
    return <CourseAssignmentsContextStudentProvider children={children} />
  } else {
    return <>{children}</>
  }
}

export function CourseAssignmentsContextStudentProvider({
  children,
}: React.PropsWithChildren<object>) {
  const classId = useSelectedClassId(true)
  const { categories = [] } = useGradeTree(classId) || {}
  const segmentProgress: SegmentProgressMap = {}
  const segmentGrades: SegmentGradeRecordMap = {}
  const incompleteAsZero = useGradeIncompleteLateAsZero(classId)
  if (categories.length) {
    for (const childGroup of categories) {
      for (const _assignment of childGroup.assignments) {
        const assignment = _assignment as GradeTreeAssignmentExt & BasicAssignmentFragment
        const rawConfig = assignment.rawConfiguration || {}
        const segmentId = isSegmentConfiguration(rawConfig) ? rawConfig.segmentId : undefined
        if (segmentId) {
          segmentGrades[segmentId] = translateGradeTreeAssignment(_assignment, !!incompleteAsZero)
          segmentProgress[segmentId] = assignment.submissions[0]?.submittedAt
            ? SegmentProgress.COMPLETE
            : assignment.dueAt && moment(assignment.dueAt).isBefore(moment())
            ? SegmentProgress.OVERDUE
            : assignment.submissions?.length
            ? SegmentProgress.IN_PROGRESS
            : SegmentProgress.NOT_STARTED
        }
      }
    }
  }
  return (
    <CourseAssignmentsContext.Provider
      value={{ segmentProgress, segmentGradeRecords: segmentGrades }}
      children={children}
    />
  )
}

export function useCourseAssignmentsContext(): CourseAssignmentsContextValue | undefined
export function useCourseAssignmentsContext(
  require: false,
): CourseAssignmentsContextValue | undefined
export function useCourseAssignmentsContext(require: true): CourseAssignmentsContextValue
export function useCourseAssignmentsContext(
  require?: boolean,
): CourseAssignmentsContextValue | undefined {
  const context = useContext(CourseAssignmentsContext)
  if (!context && require) {
    throw new Error('CourseAssignmentsContext is required, yet not provided.')
  }
  return context
}
