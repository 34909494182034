import * as React from 'react'

function SvgNoteG(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#069796'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={2.118}
          d={
            'M158.74 112.308a16.855 16.855 0 00-7.995-8.89 26.792 26.792 0 00-12.329-2.72 37.318 37.318 0 00-13.86 2.876 45.38 45.38 0 00-13.133 8.086 43.134 43.134 0 00-9.81 12.752 37.018 37.018 0 00-3.868 17.08 27.594 27.594 0 002.803 14.099 18.155 18.155 0 007.553 7.62 19.594 19.594 0 0010.148 2.073 29.702 29.702 0 0010.98-2.592 36.134 36.134 0 009.81-6.453 25.918 25.918 0 006.567-9.253l-25.54 4.847 2.881-29.106 78.436-9.875a60.044 60.044 0 01-.986 22.367 91.791 91.791 0 01-7.216 20.19 129.96 129.96 0 01-9.629 16.458 121.181 121.181 0 01-8.357 11.04c-2.207 2.593-3.452 3.889-3.686 4.122a63.69 63.69 0 01-15.729 11.326 86.013 86.013 0 01-41.84 9.615 68.245 68.245 0 01-21.594-3.965 59.481 59.481 0 01-18.973-11.223 55.519 55.519 0 01-14.145-19.516 77.302 77.302 0 01-7.008-26.876 84.115 84.115 0 012.154-25.918 82.87 82.87 0 019.81-23.61 94.962 94.962 0 0116.17-20.035 99.197 99.197 0 0121.154-15.188 91.186 91.186 0 0124.632-8.967c.26 0 1.53-.156 3.867-.467a63.187 63.187 0 0110.174 0 73.185 73.185 0 0114.924 2.592 83.121 83.121 0 0117.987 7.568 87.767 87.767 0 0119.259 14.617l-33.612 35.326z'
          }
        />
        <path
          fill={'#009696'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteG = React.memo(SvgNoteG)
export default MemoSvgNoteG
