import { styled, s } from '@thesisedu/ui'
import { Mic } from '@thesisedu/ui/icons'
import React from 'react'

import { useAudioStreamLevels } from './useAudioStreamLevels'

const MIN_HEIGHT = 36
export interface CompactAudioIndicatorProps {
  stream: MediaStream
  maxHeight?: number
}
export function CompactAudioIndicator({ stream, maxHeight = 100 }: CompactAudioIndicatorProps) {
  const hasAudio = stream.getAudioTracks().length > 0
  const indicatorRef = React.useRef<HTMLDivElement>(null)
  useAudioStreamLevels({
    stream,
    onLevel(level) {
      if (indicatorRef.current) {
        indicatorRef.current.style.height = `${MIN_HEIGHT + (maxHeight - MIN_HEIGHT) * level}px`
      }
    },
  })
  if (hasAudio) {
    return (
      <Indicator ref={indicatorRef}>
        <Mic />
      </Indicator>
    )
  } else return null
}

const Indicator = styled.div`
  width: 24px;
  min-height: ${MIN_HEIGHT}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 4px;
  border-radius: 9999px;
  background: ${s.color('green')};
  color: white;
  font-size: 16px;
`
