import React from 'react'

export interface PlacingSegment {
  classId: string
  rawSegmentId: string
}
export interface PlacementDrawerContextValue {
  setPlacing: (placing?: PlacingSegment) => void
}
export const PlacementDrawerContext = React.createContext<PlacementDrawerContextValue | undefined>(
  undefined,
)

export function usePlacementDrawerContext(): PlacementDrawerContextValue | undefined
export function usePlacementDrawerContext(require: false): PlacementDrawerContextValue | undefined
export function usePlacementDrawerContext(require: true): PlacementDrawerContextValue
export function usePlacementDrawerContext(
  require?: boolean,
): PlacementDrawerContextValue | undefined {
  const context = React.useContext(PlacementDrawerContext)
  if (!context && require) {
    throw new Error('PlacementDrawerContext is required, yet not provided.')
  }
  return context
}
