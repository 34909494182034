import { BackgroundJobStatus } from '@thesisedu/feature'
import {
  BackgroundJobReporterProps,
  BackgroundJobReporterResource,
} from '@thesisedu/feature-apollo-react'
import { PlatformImport } from '@thesisedu/feature-course-types'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'
import { ImportResultModal } from '../../import/ImportResultModal'

function ReporterRenderer({
  job,
  onClose,
  children,
}: BackgroundJobReporterProps<Record<string, any>, PlatformImport.ImportSegmentsSummary>) {
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (job.status === BackgroundJobStatus.Success && !!job.resultPayload?.missingTags) {
      setVisible(true)
    } else if (job.status === BackgroundJobStatus.Error) {
      setTimeout(() => {
        onClose()
      }, 3000)
    }
  }, [job.status])

  if (job.resultPayload?.missingTags) {
    return (
      <>
        <ImportResultModal
          result={job.resultPayload}
          visible={visible}
          onVisibleChange={v => {
            setVisible(v)
            if (!v) {
              onClose()
            }
          }}
        />
        {children}
      </>
    )
  } else return children
}

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<
    BackgroundJobReporterResource<Record<string, any>, PlatformImport.ImportSegmentsSummary>
  >({
    type: 'BackgroundJobReporter',
    identifier: 'importSegments',
    humanLabel: () => 'Importing content...',
    render: ReporterRenderer,
  })
}
