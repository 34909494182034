import React from 'react'

import { useSidebarTabs } from './useSidebarTabs'

export interface SelectedSidebarTabContextValue {
  selectedTab?: string
  setSelectedTab: (selectedTab?: string) => void
}
export const SelectedSidebarTabContext = React.createContext<
  SelectedSidebarTabContextValue | undefined
>(undefined)

export interface SelectedSidebarTabProviderProps {
  children: React.ReactNode
  defaultSidebarTab?: string
}
export function SelectedSidebarTabProvider({
  defaultSidebarTab,
  children,
}: SelectedSidebarTabProviderProps) {
  const tabs = useSidebarTabs()
  const [selectedTab, setSelectedTab] = React.useState<string | undefined>(
    defaultSidebarTab ?? tabs[0]?.title,
  )
  React.useEffect(() => {
    setSelectedTab(defaultSidebarTab ?? tabs[0]?.title)
  }, [tabs.length, defaultSidebarTab])

  return (
    <SelectedSidebarTabContext.Provider
      value={{ selectedTab, setSelectedTab }}
      children={children}
    />
  )
}

export function useSelectedSidebarTabContext(): SelectedSidebarTabContextValue | undefined
export function useSelectedSidebarTabContext(
  require: false,
): SelectedSidebarTabContextValue | undefined
export function useSelectedSidebarTabContext(require: true): SelectedSidebarTabContextValue
export function useSelectedSidebarTabContext(
  require?: boolean,
): SelectedSidebarTabContextValue | undefined {
  const context = React.useContext(SelectedSidebarTabContext)
  if (!context && require) {
    throw new Error('SelectedSidebarTabContext is required, yet not provided.')
  }
  return context
}
