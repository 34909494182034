import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { GoogleClassroomApiDisabledHandler } from './GoogleClassroomApiDisabledExpectedError'
import { GoogleClassroomReactFeature } from '../GoogleClassroomReactFeature'

export default function addExpectedErrorHook(feature: GoogleClassroomReactFeature) {
  feature.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
    return (
      <>
        <GoogleClassroomApiDisabledHandler />
        {children}
      </>
    )
  })
}
