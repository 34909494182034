import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { useState } from 'react'

import {
  ClassSyncProviderFeature,
  ClassSyncsDocument,
  useClassSyncsQuery,
  useTeacherSiteClassSyncProvidersQuery,
} from './schema'

export interface ClassSyncItem {
  label: string
  syncId: string
  providerName: string
  providerIdentifier: string
  providerId: string
  authProviderId?: string
  authProviderName?: string
  deleting?: boolean
  canDelete?: boolean
}
interface EmptySyncItem {
  providerName: string
  providerId: string
}

export interface ClassSyncSummary {
  syncs: ClassSyncItem[]
  emptySyncs: EmptySyncItem[]
  loading?: boolean
  creatingProviderId?: string
  setCreatingProviderId: (creatingProviderId: string | undefined) => void
  deleteSync: (syncId: string) => Promise<void>
  canAttach?: boolean
}

export function useClassSyncSummary(classId: string): ClassSyncSummary {
  const [creatingProviderId, setCreatingProviderId] = useState<string | undefined>()
  const [deletingId, setDeletingId] = useState<string | undefined>()
  const providers = useClassSyncProviders()
  const { data, loading } = useClassSyncsQuery({ variables: { id: classId } })
  const [deleteNode] = useDeleteNodeMutation({
    errorMessage: 'There was an error removing that sync.',
    refetchQueries: [{ query: ClassSyncsDocument, variables: { id: classId } }],
    awaitRefetchQueries: true,
  })
  const syncs: ClassSyncItem[] =
    data?.node?.__typename === 'Class'
      ? data.node.syncs.edges.map<ClassSyncItem>(edge => ({
          label: edge.node.label,
          providerName:
            providers.find(p => p.identifier === edge.node.provider)?.name || edge.node.provider,
          providerIdentifier: edge.node.provider,
          providerId: edge.node.providerId,
          authProviderId:
            providers.find(p => p.identifier === edge.node.provider)?.authProviderId || undefined,
          authProviderName:
            providers.find(p => p.identifier === edge.node.provider)?.authProviderName || undefined,
          syncId: edge.node.id,
          canDelete: edge.node.canDelete,
          deleting: deletingId === edge.node.id,
        }))
      : []
  const canCreate = data?.node?.__typename === 'Class' ? data.node.canCreateClassSync : undefined
  const canAttach =
    data?.node?.__typename === 'Class' ? data.node.canAttachStudentAccounts : undefined
  const emptySyncs = canCreate
    ? providers
        .filter(provider => !syncs.some(sync => sync.providerId === provider.identifier))
        .map<EmptySyncItem>(provider => ({
          providerName: provider.name,
          providerId: provider.identifier,
        }))
    : []
  return {
    creatingProviderId,
    setCreatingProviderId,
    loading,
    syncs,
    emptySyncs,
    canAttach,
    async deleteSync(syncId) {
      setDeletingId(syncId)
      try {
        await deleteNode({ variables: { id: syncId } })
      } finally {
        setDeletingId(undefined)
      }
    },
  }
}

export function useClassSyncProviders() {
  const { data } = useTeacherSiteClassSyncProvidersQuery()
  return data?.viewer?.teacher?.site.classSyncProviders ?? []
}

export function useAssignmentClassSyncProviders() {
  return useClassSyncProviders().filter(
    provider => provider.supportedFeatures?.includes(ClassSyncProviderFeature.GradeMaterialSync),
  )
}
