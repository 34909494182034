import { HSpaced, Block, Body, BodyLarge, DATE_FORMATS, FontWeight } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { usePastAttempt } from './usePastAttempt'
import { Grade } from '../Grade'
import { useGradingModalContext } from '../contexts/GradingModalContext'

export interface AssignmentStudentHeaderLeftProps {
  studentId: string
  submissionId?: string
}
export function AssignmentStudentHeaderLeft({
  studentId,
  submissionId,
}: AssignmentStudentHeaderLeftProps) {
  const { assignment } = useGradingModalContext(true)
  const { submission: pastSubmission } = usePastAttempt(submissionId)
  const submission = submissionId
    ? pastSubmission
    : (assignment.submissions || assignment.currentSubmissions).edges.find(
        sub => sub.node.studentId === studentId,
      )?.node
  if (submission?.submittedAt) {
    const hasGrade =
      submission.reviewed && submission.grade !== undefined && submission.grade !== null
    const hasFooter = submission.isLate || hasGrade
    return (
      <div style={{ textAlign: 'right' }}>
        <Body>Submitted {moment(submission.submittedAt).format(DATE_FORMATS.FULL)}</Body>
        {hasFooter ? (
          <Block marginTop={'@size-xxs'}>
            <HSpaced justify={'flex-start'}>
              {submission.isLate ? <Body color={'@red'}>Late</Body> : null}
              {submission?.reviewed &&
              submission?.grade !== undefined &&
              submission?.grade !== null ? (
                <BodyLarge weight={FontWeight.Bold} useDiv>
                  <Grade
                    hideProgress
                    grade={submission.grade}
                    style={{ paddingTop: 0 }}
                    totalPoints={assignment.totalPoints}
                  />
                </BodyLarge>
              ) : null}
            </HSpaced>
          </Block>
        ) : null}
      </div>
    )
  } else {
    return null
  }
}
