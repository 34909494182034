import { ApolloConsumer } from '@apollo/client'
import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { CommentComposerResource } from '@thesisedu/feature-comments-react'
import { ReactFeature, ReactHooks, isInNode } from '@thesisedu/feature-react'
import { SiteFormItemResource } from '@thesisedu/feature-sites-web'
import { Camera } from '@thesisedu/react/icons'
import React from 'react'

import { MediaCommentMetadata } from './types'
import { UploadManagerProvider } from './upload/UploadManagerContext'
import { UploadManager } from './upload/manager'

export interface MediaReactOptions {
  getKeyboard?: () => React.FC<React.PropsWithChildren<any>>
  audioConstraint?: MediaTrackConstraints
  enablePitchTool?: boolean
  enableMetronomeTool?: boolean
}

export class MediaReactFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-media-react'
  static path = ''
  static requires: string[] = []
  public options!: MediaReactOptions
  public keyboard?: React.FC<React.PropsWithChildren<any>>

  constructor(options: object, deps: FeatureDependencies) {
    super(options, deps)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Site', fragment => addField(fragment, 'region'))
    this.resources.addResource<SiteFormItemResource>({
      type: 'SiteFormItem',
      identifier: 'media-site-form-region',
      render: () => {
        const { SiteFormExtension } = require('./SiteFormExtension')
        return <SiteFormExtension />
      },
    })
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, async children => {
      const { UploadModalProvider } = require('./upload/UploadModalContext')
      return (
        <ApolloConsumer>
          {client => (
            <UploadManagerProvider uploadManager={new UploadManager(client, this)}>
              <UploadModalProvider children={children} />
            </UploadManagerProvider>
          )}
        </ApolloConsumer>
      )
    })
  }

  public reactResources() {
    if (this.options.getKeyboard) {
      this.keyboard = this.options.getKeyboard()
    }
    this.resources.addResource<CommentComposerResource<MediaCommentMetadata>>({
      type: 'CommentComposer',
      identifier: 'media',
      name: 'Video or Audio',
      icon: <Camera />,
      ComposeComponent: require('./comments/ComposeMediaComment').ComposeMediaComment,
      DisplayComponent: require('./comments/DisplayMediaComment').DisplayMediaComment,
    })

    require('./hooks/UserMenu').default(this)
  }

  prepareEarly() {
    if (this.root.getFeature('@thesisedu/feature-widgets-react') && !isInNode()) {
      require('./resources/Widget/Performance').default(this)
      if (this.options.enableMetronomeTool) {
        require('./resources/Widget/Metronome').default(this)
      }
    }
  }
}

export default MediaReactFeature
export const mediaReactFeature = (
  options: MediaReactOptions = {},
): FeatureUse<MediaReactOptions> => [MediaReactFeature, options]
