import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { Editor, ReadOnlyEditor } from '@thesisedu/feature-courses-react'
import { InteractionsContext } from '@thesisedu/feature-interactions-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { styled } from '@thesisedu/react'
import { ErrorIndicator, useBreadcrumb, PageHeader, PageHead } from '@thesisedu/web'
import { Button, Form, Input, message, Skeleton } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteAnnouncement } from './DeleteAnnouncement'
import { useCreateAnnouncementMutation } from './queries/useCreateAnnouncementMutation'
import { useUpdateAnnouncementMutation } from './queries/useUpdateAnnouncementMutation'
import { AnnouncementsForClassDocument, useAnnouncementQuery } from './schema'

export interface AnnouncementProps {
  onDirty?: (dirty: boolean) => void
  announcementId: string | 'create'
}
export function Announcement({ onDirty, announcementId }: AnnouncementProps) {
  const navigate = useNavigate()
  const isCreate = announcementId === 'create'
  const { data, loading } = useAnnouncementQuery({
    variables: { id: announcementId },
    skip: isCreate,
  })
  const title = isCreate
    ? 'Create'
    : data?.node?.__typename === 'Announcement'
    ? data.node.name
    : '--'
  useBreadcrumb({ title }, title)
  const viewer = useViewerContext(false)
  const manager = useContext(InteractionsContext)
  const cls = useSelectedClass(true)
  useEffect(() => {
    if (viewer?.group === 'STUDENT' && manager) {
      manager.singleInteraction({
        type: 'VIEWED_ANNOUNCEMENT',
        reference: announcementId,
      })
      manager.flush()
    }
  }, [viewer?.group, announcementId])
  const [update, { loading: updateLoading }] = useUpdateAnnouncementMutation({
    onCompleted: () => {
      message.success('Announcement updated!')
    },
  })
  const [create, { loading: createLoading }] = useCreateAnnouncementMutation({
    onCompleted: data => {
      message.success('Announcement created!')
      navigate(`../${data.createAnnouncement.announcement.id}`)
    },
  })
  const announcement = data?.node?.__typename === 'Announcement' ? data.node : undefined
  const [form] = Form.useForm()

  if (announcement || isCreate) {
    const canUpdate = viewer?.group === 'TEACHER' && (announcement?.canUpdate || isCreate)
    const canDelete = announcement?.canDelete
    return (
      <Form
        onValuesChange={() => {
          if (onDirty) {
            onDirty(true)
          }
        }}
        form={form}
        onFinish={values => {
          if (onDirty) {
            onDirty(false)
          }
          if (isCreate) {
            create({
              variables: {
                input: {
                  id: cls.id,
                  ...values,
                  configuration: {
                    ...values.configuration,
                    description: values.configuration.description || [],
                  },
                },
              },
              refetchQueries: [
                { query: AnnouncementsForClassDocument, variables: { classId: cls.id } },
              ],
            })
          } else {
            update({
              variables: {
                input: {
                  id: announcementId,
                  patch: values,
                },
              },
            })
          }
        }}
      >
        <div>
          <PageHead title={`${announcement?.name || 'View Announcement'} - ${cls.name}`} />
          <PageHeader
            title={
              canUpdate ? (
                <Form.Item
                  style={{ margin: 0, width: '100%', minWidth: 500 }}
                  name={'name'}
                  initialValue={announcement ? announcement.name : null}
                  rules={[{ required: true, message: 'A name is required.' }]}
                >
                  <Input
                    size={'large'}
                    placeholder={'Click here to type an announcement name...'}
                  />
                </Form.Item>
              ) : (
                announcement?.name || ''
              )
            }
            onBack={() => navigate('../')}
          >
            {canDelete && announcement ? (
              <DeleteAnnouncement announcementId={announcement.id} />
            ) : null}
            {canUpdate ? (
              <Button
                loading={updateLoading || createLoading}
                onClick={() => form.submit()}
                type={'primary'}
              >
                {isCreate ? 'Create Announcement' : 'Save Changes'}
              </Button>
            ) : null}
          </PageHeader>
          <AnnouncementContainer>
            {canUpdate ? (
              <Form.Item
                name={['configuration', 'description']}
                initialValue={announcement?.configuration?.description}
              >
                <Editor noPadding id={`announcement-${isCreate ? 'create' : announcement!.id}`} />
              </Form.Item>
            ) : (
              <ReadOnlyEditor
                id={`announcement-${isCreate ? 'create' : announcement!.id}`}
                defaultValue={announcement?.configuration?.description || []}
              />
            )}
          </AnnouncementContainer>
        </div>
      </Form>
    )
  } else if (loading) {
    return <Skeleton active />
  } else {
    return <ErrorIndicator />
  }
}

const AnnouncementContainer = styled.div`
  max-width: 800px;
  margin: ${props => props.theme['@size-l']} auto 0 auto;
`
