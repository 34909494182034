import styled from 'styled-components'

export const CalendarCard = styled.div`
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@background-color-dark']};
  cursor: pointer;
  padding: ${props => props.theme['@size-xs']};
  color: ${props => props.theme['@text-color-dark']};
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: ${props => props.theme['@size-xxs']};
`
export const CalendarCardRight = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
`
