import { TransitionOpts } from './types'

export const DEFAULT_STATE: TransitionOpts<any> = {
  state: 'selectDevice',
  context: {},
}

export const DEFAULT_STATE_DISABLED: TransitionOpts<any> = {
  state: 'disabled',
  context: {},
}
