import {
  flattenSegments,
  getSegmentName,
  isVisibleToStudent,
} from '@thesisedu/feature-course-types'
import { styled, s, Text } from '@thesisedu/ui'
import React from 'react'

import { HeaderCoverageIndicator } from './HeaderCoverageIndicator'
import { OutlineItemProps } from './OutlineItem'
import { SegmentOutlineItemProps } from './Segment'
import { useSegment } from '../../contexts/SegmentContext'
import { CollapseButton } from '../CollapseButton'
import { useOutlineListContext } from '../OutlineListContext'
import { OutlineSearchWrapper } from '../OutlineSearchContext'
import { useOutlineCollapseContext } from '../context/OutlineCollapseContext'
import { useOutlineContext } from '../context/OutlineContext'
import { isItemWithSegment, OutlineItem, SegmentOutlineItem } from '../types'

export function Header(props: OutlineItemProps) {
  const { item, index, children, ...otherProps } = props
  const segment =
    useSegment(isItemWithSegment(item) ? item.segment.id : 'dummy-id') ||
    (isItemWithSegment(item) ? item.segment : undefined)
  let content
  let coverage: number | undefined = undefined
  const classes: string[] = [`depth-${item.depth}`, 'type-header']
  const { items } = useOutlineListContext(false) || {}
  const { setParentCollapsed, expandedParentIds } = useOutlineCollapseContext(false) || {}
  const collapsed = expandedParentIds && segment && !expandedParentIds.includes(segment.id)
  const itemsRef = React.useRef<OutlineItem[] | undefined>(items)
  React.useEffect(() => {
    itemsRef.current = items
  }, [items])
  const { groupActions } = useOutlineContext(false) || {}
  const [overlayVisible, setOverlayVisible] = React.useState(false)
  const actions =
    !!(item as SegmentOutlineItem).segment && groupActions
      ? groupActions({
          ...(props as SegmentOutlineItemProps),
          overlayVisible,
          setOverlayVisible,
        })
      : undefined
  const isDark = item.depth === 1
  if (isDark && segment) {
    const validSegments = flattenSegments([segment]).filter(seg => isVisibleToStudent(seg))
    const enabledSegments = validSegments.filter(seg => seg.visibleOverride)
    if (validSegments.length && enabledSegments.length)
      coverage = enabledSegments.length / validSegments.length
  }
  let label = isItemWithSegment(item) ? item.segment.config.label || 'Group' : 'Group'
  if (label === 'Implementation Guide') label = 'Lesson'
  const smallHeader = item.depth === 1 ? label : ''
  const nameWithLabel =
    isItemWithSegment(item) && segment
      ? segment.config.label && !smallHeader
        ? `${segment.config.label}: ${getSegmentName(segment)}`
        : getSegmentName(segment)
      : !isItemWithSegment(item)
      ? item.name
      : ''

  if (overlayVisible) {
    classes.push('overlay-visible')
  }
  if (item.depth === 0) {
    content = (
      <Text
        level={'h2'}
        bottom={false}
        color={'textEmphasis'}
        children={segment ? segment.name : !isItemWithSegment(item) ? item.name : ''}
      />
    )
    classes.push('is-bordered')
  } else if (item.depth === 1) {
    content = <Text level={'h3'} color={'textEmphasis'} bottom={false} children={nameWithLabel} />
  } else if (item.depth === 2) {
    content = <Text level={'l'} color={'textEmphasis'} weight={'bold'} children={nameWithLabel} />
  } else if (item.depth >= 3) {
    content = <Text color={'textEmphasis'} weight={'bold'} children={nameWithLabel} />
  }
  if (!isItemWithSegment(item)) classes.push('no-segment')
  if (props.className) classes.push(props.className)
  const innerContent = (
    <div className={'content-container'}>
      {item.collapsible ? <CollapseButton collapsed={collapsed} /> : null}
      <TruncateWrapper>
        {smallHeader ? <Text color={'secondary'} children={smallHeader} /> : null}
        {content}
      </TruncateWrapper>
      {actions ? <div className={'actions'} children={actions} /> : null}
    </div>
  )
  return (
    <Container
      {...otherProps}
      data-index={index}
      className={classes.join(' ')}
      segmentIds={segment ? [segment.id] : undefined}
      onClick={
        item.collapsible && setParentCollapsed && segment
          ? (e: any) => {
              e.preventDefault()
              if (!(e.target as any).closest('.dropdown-menu')) {
                setParentCollapsed(segment.id, !collapsed)
              }
            }
          : undefined
      }
    >
      <div style={{ position: 'relative' }} className={'outer-container'}>
        {isDark ? (
          <DarkContentContainer style={{ opacity: coverage || !item.collapsible ? 1 : 0.75 }}>
            {innerContent}
            {item.collapsible ? <HeaderCoverageIndicator coverage={coverage} /> : null}
          </DarkContentContainer>
        ) : (
          innerContent
        )}
        {children}
      </div>
    </Container>
  )
}

export const DEPTH_PADDINGS: s.SizeAlias[] = ['xl', 'm', 'm', 's']
export function getDepthPaddingThemeKey(depth: number): s.SizeAlias {
  return DEPTH_PADDINGS[depth] || DEPTH_PADDINGS[DEPTH_PADDINGS.length - 1]
}

export const DarkContentContainer = styled.div`
  background: ${s.color('element')};
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('s')} 0;
  border-left: solid ${s.var('radii.1')} ${s.color('border')};
  margin: 1px; // For the border.
  transition: opacity 0.25s linear;
  position: relative;
  .content-container {
    padding: 0 ${s.size('xs')};
    > :first-child {
      margin-left: ${s.size('xs')};
    }
  }
`
const Container = styled(OutlineSearchWrapper)`
  .content-container {
    position: relative;
    display: flex;
    align-items: center;
    > :not(:last-child) {
      margin-right: ${s.size('xs')};
    }
  }
  &.is-bordered {
    border-bottom: solid 1px ${s.color('border')};
  }
  &.depth-0 {
    &:not([data-index='0']) {
      padding-top: ${s.size(getDepthPaddingThemeKey(0))};
    }
    padding-bottom: ${s.size('xs')};
  }
  &.depth-1 {
    padding-top: ${s.size(getDepthPaddingThemeKey(1))};
    padding-bottom: ${s.size('xs')};
  }
  &.depth-2 {
    padding-top: ${s.size(getDepthPaddingThemeKey(2))};
  }
  &.depth-3,
  &.depth-4,
  &.depth-5,
  &.depth-6 {
    padding-top: ${s.size(getDepthPaddingThemeKey(3))};
  }
  &.no-segment.searchEnabled {
    display: none;
  }
  .actions {
    margin-left: auto;
    padding-left: ${s.size('s')};
  }
`

const TruncateWrapper = styled.div`
  overflow: hidden;
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
