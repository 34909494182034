import { BadgeEventTypeProps } from '@thesisedu/feature-badges-react'
import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import { Block, StyledThemeContext } from '@thesisedu/web'
import { Form } from 'antd'
import React from 'react'

import { SegmentSelect } from '../../segment/SegmentSelect'
import { SegmentSummary } from '../../segment/SegmentSummary'

export interface SegmentBadgeConfigFieldProps {
  value?: string[]
  onChange?: (value?: string[]) => void
}
export function SegmentBadgeConfigField({ value, onChange }: SegmentBadgeConfigFieldProps) {
  const theme = React.useContext(StyledThemeContext)
  return (
    <>
      {value?.length ? (
        <Block marginBottom={'@size-m'}>
          {value.map(selectedSegment => (
            <SegmentSummary
              key={selectedSegment}
              segmentDatabaseId={toGlobalId('Segment', selectedSegment)}
              onAct={() => {
                if (onChange) {
                  onChange(value.filter(v => v !== selectedSegment))
                }
              }}
              action={'Remove'}
              actionProps={{
                style: {
                  color: theme['@red'],
                },
              }}
            />
          ))}
        </Block>
      ) : null}
      <SegmentSelect
        filterProps={{
          hideHidden: true,
          hideType: true,
        }}
        onSegmentSelected={segment => {
          const segmentId = fromGlobalId(segment.id, true).id
          const without = value?.filter(ss => ss !== segmentId) || []
          if (onChange) {
            onChange([...without, segmentId])
          }
        }}
      />
    </>
  )
}

export function SegmentBadgeConfig({ prefix }: BadgeEventTypeProps) {
  return (
    <Form.Item
      name={[prefix, 'selectedSegmentIds']}
      initialValue={[]}
      rules={[
        {
          validator: (_, value) => {
            if (!value?.length) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('At least one segment is required.')
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}
    >
      <SegmentBadgeConfigField />
    </Form.Item>
  )
}
