import { PageHead } from '@thesisedu/react'
import React from 'react'

import { PreferencesEditor } from './PreferencesEditor'
import { useViewerNotificationPreferencesQuery } from '../schema'

export function UserPreferencesEditor() {
  const { data, loading } = useViewerNotificationPreferencesQuery()
  return (
    <>
      <PageHead title={'Notification Preferences'} />
      <PreferencesEditor
        preferences={data?.viewer?.notificationPreferences || []}
        loading={loading}
      />
    </>
  )
}
