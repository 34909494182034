import {
  EyeOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
} from '@ant-design/icons'
import { Format } from '@thesisedu/feature-utils'
import { BodyExtraSmall, BodySmall, Tooltip } from '@thesisedu/react'
import { Clock, MoreVert } from '@thesisedu/react/icons'
import { Button, Dropdown } from '@thesisedu/ui'
import { FontWeight, styled } from '@thesisedu/web'
import { InputNumber } from 'antd'
import { transparentize } from 'polished'
import React from 'react'
import { keyframes } from 'styled-components'

import { AssignmentCellMissing } from './AssignmentCellMissing'
import { AssignmentCellMode } from './types'
import { GRADE_CELL_HEIGHT } from '../constants'
import { useGradesTableContext } from '../contexts/GradesTableContext'

export enum LoadingMode {
  Success = 'success',
  Error = 'error',
}
export interface AssignmentCellProps {
  readOnly?: boolean
  id?: string
  classId?: string
  mode: AssignmentCellMode
  percentage?: number | null
  points?: number | null
  totalPoints: number
  isLate?: boolean
  actions?: React.ReactElement
  onSave?: (percentage: number, points: number) => void
  loading?: boolean | LoadingMode
  onClick?: () => void
}
export function AssignmentCell({
  readOnly: _readOnly,
  id,
  classId,
  mode,
  percentage,
  points,
  totalPoints,
  isLate,
  actions,
  onSave,
  onClick,
  loading,
}: AssignmentCellProps) {
  const readOnly = _readOnly || (!id && !onClick && !actions)
  const hasPercentage = percentage !== null && percentage !== undefined
  const hasPoints = points !== null && points !== undefined
  let content: React.ReactElement | null = null
  const [dLoading, setDLoading] = React.useState<boolean | LoadingMode | undefined>(loading)
  React.useEffect(() => {
    if (loading) {
      setDLoading(loading)
    } else {
      const timeout = setTimeout(() => {
        setDLoading(loading)
      }, 250)
      return () => clearTimeout(timeout)
    }
  }, [loading])
  const [actionsVisible, setActionsVisible] = React.useState(false)
  const { editingId, setEditingId } = useGradesTableContext(false) || {}
  const [editingPercentage, setEditingPercentage] = React.useState<number | null | undefined>(
    percentage,
  )
  const [editingPoints, setEditingPoints] = React.useState<number | null | undefined>(points)
  const [focusField, setFocusField] = React.useState<'points' | 'percentage'>('percentage')

  // We need to use a ref here because the onCancel() callback is not updated
  // every time the state updates, so we need to make sure the refs always have
  // the correct value, and we don't want to update the context every time we
  // change a character.
  const fieldsRef = React.useRef<[number | null | undefined, number | null | undefined]>()
  React.useEffect(() => {
    fieldsRef.current = [editingPercentage, editingPoints]
  }, [editingPercentage, editingPoints])

  const onCancel = React.useCallback(() => {
    if (fieldsRef.current && onSave) {
      const [percentage, points] = fieldsRef.current
      if (
        percentage !== null &&
        percentage !== undefined &&
        points !== null &&
        points !== undefined
      ) {
        onSave(percentage, points)
      }
    }
  }, [onSave])

  React.useEffect(() => {
    setEditingPercentage(percentage)
    setEditingPoints(points)
  }, [percentage, points])
  const editing = id && editingId === id
  const containerClasses: string[] = []
  if (!readOnly) containerClasses.push('editable')
  if (actionsVisible) containerClasses.push('actions-visible')
  if (editing) containerClasses.push('editing')
  if (loading) containerClasses.push('loading')
  if (mode === AssignmentCellMode.Graded && hasPercentage) {
    if (!editing) {
      content = (
        <>
          <div
            className={'edit-box'}
            onClick={
              !readOnly && id && setEditingId
                ? e => {
                    e.stopPropagation()
                    setFocusField('percentage')
                    setEditingId(id, onCancel)
                  }
                : undefined
            }
          >
            {Format.number(percentage, 'decimalPercentage')}
          </div>
          {hasPoints ? (
            <div
              className={'edit-box secondary'}
              onClick={
                !readOnly && id && setEditingId
                  ? e => {
                      e.stopPropagation()
                      setFocusField('points')
                      setEditingId(id, onCancel)
                    }
                  : undefined
              }
            >
              {points} / {parseFloat(totalPoints.toFixed(2))}
            </div>
          ) : null}
        </>
      )
    } else if (!readOnly && editing) {
      const numberProps = {
        className: 'edit-box',
        size: 'small' as any,
        width: 40,
        onPressEnter: setEditingId
          ? () => {
              // This will trigger the onSave callback through the context.
              setEditingId(undefined)
            }
          : undefined,
        onFocus: ((e: FocusEvent) => {
          const element = e.target as HTMLInputElement
          // We split against "/" because we only want to select the
          // first part in the points field.
          const value = element.value.split('/')[0].trim()
          element.setSelectionRange(0, value.length)
        }) as any, // FocusEvent typing above doesn't agree with antd.
      }
      content = (
        <>
          <InputNumber
            {...numberProps}
            autoFocus={focusField === 'percentage'}
            value={
              editingPercentage !== null && editingPercentage !== undefined
                ? editingPercentage * 100
                : undefined
            }
            onKeyDown={e => {
              if (e.key === 'Escape' && setEditingId) {
                setEditingId(undefined, undefined)
              }
            }}
            onChange={value => {
              if (value === null || value === undefined) {
                setEditingPercentage(null)
              } else {
                const parsed = parseFloat(value.toString())
                const newValue = isNaN(parsed) ? undefined : parsed / 100
                setEditingPercentage(newValue)
                if (newValue !== undefined) {
                  setEditingPoints(Math.round(newValue * totalPoints))
                }
              }
            }}
            precision={2}
          />
          {hasPoints ? (
            <InputNumber<string | number>
              {...numberProps}
              value={
                editingPoints !== null && editingPoints !== undefined ? editingPoints : undefined
              }
              autoFocus={focusField === 'points'}
              formatter={value => `${value} / ${totalPoints}`}
              parser={value => (value === undefined ? '' : value.split('/')[0].trim())}
              onKeyDown={e => {
                if (e.key === 'Escape' && setEditingId) {
                  setEditingId(undefined, undefined)
                }
              }}
              onChange={value => {
                if (value === null || value === undefined) {
                  setEditingPoints(null)
                } else {
                  const parsed = parseFloat(value.toString())
                  const newValue = isNaN(parsed) ? undefined : parsed
                  setEditingPoints(newValue)
                  if (newValue !== undefined) {
                    setEditingPercentage(newValue / totalPoints)
                  }
                }
              }}
              precision={2}
            />
          ) : null}
        </>
      )
    }
  } else if (mode === AssignmentCellMode.Started) {
    content = <BodySmall color={'@text-color-secondary'}>Started</BodySmall>
  } else if (mode === AssignmentCellMode.NotStarted) {
    content = null
  } else if (mode === AssignmentCellMode.Missing && classId) {
    content = <AssignmentCellMissing classId={classId} />
  } else if (mode === AssignmentCellMode.Ungraded) {
    content = (
      <BodySmall weight={FontWeight.SemiBold} color={'@red'}>
        Ungraded
      </BodySmall>
    )
    containerClasses.push('danger')
  }
  return (
    <Container className={containerClasses.join(' ')} data-id={id}>
      {content}
      {(actions && !readOnly) || onClick ? (
        <div className={'actions'}>
          {actions && !readOnly ? (
            <Dropdown.Container open={actionsVisible} onOpenChange={setActionsVisible}>
              <Dropdown.ManualTrigger>
                <ActionButton children={<MoreVert />} />
              </Dropdown.ManualTrigger>
              <Dropdown.Menu side={'bottom'} align={'end'}>
                {actions}
              </Dropdown.Menu>
            </Dropdown.Container>
          ) : null}
          {onClick ? <ActionButton children={<EyeOutlined />} onClick={onClick} /> : null}
        </div>
      ) : null}
      {isLate ? (
        <Tooltip title={'Submitted Late'}>
          <BodyExtraSmall className={'late'}>
            <Clock />
          </BodyExtraSmall>
        </Tooltip>
      ) : null}
      {dLoading ? (
        <div className={`loading ${dLoading}`}>
          {dLoading === LoadingMode.Success ? (
            <CheckCircleFilled />
          ) : dLoading === LoadingMode.Error ? (
            <CloseCircleFilled />
          ) : (
            <LoadingOutlined />
          )}
        </div>
      ) : null}
    </Container>
  )
}

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
const Container = styled.div`
  position: relative;
  padding: ${props => props.theme['@size-s']};
  background: transparent;
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .edit-box {
    font-weight: 500;
    margin-right: ${props => props.theme['@size-xxs']};
    padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
    border-radius: ${props => props.theme['@border-radius-base']};
    background: transparent;
    transition: background 0.1s linear;
    font-size: ${props => props.theme['@font-size-sm']};
    &.secondary {
      color: ${props => props.theme['@gray-4']};
      font-size: ${props => props.theme['@font-size-xs']};
    }
  }
  .loading {
    animation: 0.25s ${fadeIn} linear;
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: ${props => transparentize(0.25, props.theme['@gray-2'])};
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.1s linear;
    font-size: ${props => props.theme['@size-m']};
    color: ${props => props.theme['@text-color-secondary']};
    &.success {
      color: ${props => props.theme['@green']};
    }
    &.error {
      color: ${props => props.theme['@red']};
    }
  }
  .late {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
    padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
    color: ${props => props.theme['@red']};
    background: ${props => props.theme['@red-light']};
  }
  .actions {
    background: ${props => props.theme['@gray-2']};
    position: absolute;
    inset: 0 0 0 auto;
    pointer-events: none;
    transition: opacity 0.1s linear;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    > button {
      border-radius: 0;
      line-height: 1;
      background: transparent;
      box-shadow: none;
      outline: none;
      font-size: ${props => props.theme['@font-size-sm']};
    }
  }
  &.danger {
    background: ${props => props.theme['@red-light']};
    .actions {
      background: ${props => props.theme['@red']};
      > button {
        color: white;
      }
    }
  }
  &.loading .loading {
    opacity: 1;
  }
  &.actions-visible,
  &:hover {
    &:not(.editing) .actions {
      pointer-events: all;
      opacity: 1;
    }
  }
  &.editable {
    div.edit-box {
      cursor: pointer;
    }
    &:hover,
    &.actions-visible,
    &.editing {
      &:not(.danger) {
        background: ${props => props.theme['@gray-1']};
      }
      .edit-box {
        background: ${props => props.theme['@gray-2']};
      }
    }
    &.editing {
      background: ${props => props.theme['@blue-light']};
      .late {
        display: none;
      }
    }
  }
`
const ActionButton = styled(Button)`
  font-size: ${props => props.theme['@font-size-sm']};
  width: ${GRADE_CELL_HEIGHT / 2}px;
  height: ${GRADE_CELL_HEIGHT / 2}px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme['@text-color']};
  opacity: 0.5;
  transition: opacity 0.1s linear;
  &:hover {
    opacity: 1;
  }
`
