import React from 'react'

import { useFeatureRoot } from './useFeatureRoot'

export interface BrochureLinkProps extends Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> {
  to: string
}
export function BrochureLink({ to, ...props }: BrochureLinkProps) {
  const root = useFeatureRoot()
  return (
    <a rel={'noreferrer nofollow'} {...props} href={`https://${root.appOptions.domain}/${to}`} />
  )
}
