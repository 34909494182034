import { Block } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

import { CreateTeacherButton } from './CreateTeacherButton'
import { SiteTeachersTable } from './TeachersTable'

export interface SiteTeachersProps {
  siteId: string
  isEmbedded?: boolean
}
export function SiteTeachers({ siteId, isEmbedded }: SiteTeachersProps) {
  return (
    <>
      <Block marginBottom={'@size-s'} marginLeft={isEmbedded ? '@size-l' : undefined}>
        <CreateTeacherButton siteId={siteId} buttonProps={{ type: 'link', icon: <Plus /> }} />
      </Block>
      <SiteTeachersTable siteId={siteId} />
    </>
  )
}
