import { Change, diffItems, applyDiff } from '@thesisedu/feature'

import {
  SegmentMetadata,
  SegmentMetadataSegment,
  SegmentStructureOverride,
} from './courseConfiguration'

export interface SegmentMetadataDiff {
  segments?: Change<SegmentMetadataSegment>[]
  structureOverrides?: Change<SegmentStructureOverride>[]
}
export function diffSegmentMetadata(
  from: SegmentMetadata,
  to: SegmentMetadata,
): SegmentMetadataDiff | null {
  const segments = diffItems(from.segments, to.segments, { order: true })
  const structureOverrides = diffItems(from.structureOverrides, to.structureOverrides, {
    order: true,
  })
  if (segments.length || structureOverrides.length) {
    return { segments, structureOverrides }
  } else return null
}

export function applySegmentMetadataDiff(
  existing: SegmentMetadata,
  changes: SegmentMetadataDiff,
): SegmentMetadata {
  return {
    segments: applyDiff(existing.segments || [], changes.segments || []),
    structureOverrides: applyDiff(
      existing.structureOverrides || [],
      changes.structureOverrides || [],
    ),
  }
}
