import { LoadingOutlined } from '@ant-design/icons'
import {
  FullAssignment,
  PartialSubmissionProvider,
  useAssignmentQueryVariables,
  AssignmentReports,
  AssignmentGroups,
} from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import {
  SegmentProps,
  ReadOnlyEditor,
  SegmentGates,
  SegmentContent,
  useForcedSegmentCompletionHandler,
  useSegmentCompletion,
  useSegmentNavigationContext,
} from '@thesisedu/feature-courses-react'
import { useMountInteraction } from '@thesisedu/feature-interactions-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { Legacy } from '@thesisedu/feature-widgets-react'
import { Block, PageHead } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { AssignmentGradingModalContent } from './AssignmentGradingModalContent'
import { AssignmentInstructions } from './AssignmentInstructions'
import { AssignmentManageAttempts } from './AssignmentManageAttempts'
import { AssignmentSettings } from './AssignmentSettings'
import { AssignmentSubmission } from './AssignmentSubmission'
import { AssignmentViewer } from './AssignmentViewer'
import { CalculatedAssignmentViewerWrapper } from './CalculatedAssignmentViewerWrapper'
import { useAttemptNavigationItems } from './useAttemptNavigationItems'
import { SegmentCalculationSegmentIdContext } from '../../resources/assignment_calculation/SegmentCalculationContext'
import { useAssignmentForSegmentQuery } from '../../schema'

export enum AssignmentNavigationIds {
  Groups = 'groups',
  Reports = 'reports',
  Settings = 'settings',
  Vault = 'vault',
}

export function useSegmentAssignment(segmentId: string) {
  const viewer = useViewerContext(true)
  const selectedClassId = useSelectedClassId(false)
  const shouldFetchAssignment = viewer?.group !== 'ADMIN' && selectedClassId
  const { data, loading } = useAssignmentForSegmentQuery({
    variables: {
      classId: selectedClassId || '',
      segmentId,
      ...useAssignmentQueryVariables(selectedClassId || undefined),
    },
    skip: !shouldFetchAssignment,
  })
  const assignment = data?.node?.__typename === 'Class' ? data.node.assignmentForSegment : undefined
  const isComplete = assignment?.currentSubmissions?.edges.some(edge => !!edge.node.submittedAt)
  return { assignment, loading, isComplete }
}

export function AssignmentContent({ segment, selectedNavigationId }: SegmentProps) {
  useForcedSegmentCompletionHandler()
  const { host: segmentCompletionHost } = useSegmentCompletion({ segment })
  const viewer = useViewerContext(true)
  const selectedClassId = useSelectedClassId()
  const { setSelectedNavigationItemId } = useSegmentNavigationContext(false) ?? {}
  const { assignment, loading, isComplete } = useSegmentAssignment(segment.id)
  const canManageAttempts =
    assignment?.maxAttempts &&
    assignment?.maxAttempts > 1 &&
    assignment?.configuration?.studentsManageAttempts &&
    viewer?.group === 'STUDENT'
  const navigationGlobalId = fromGlobalId(selectedNavigationId ?? '')
  const submissionId =
    navigationGlobalId?.type === 'AssignmentSubmission' ? selectedNavigationId : undefined
  const canUpdate = assignment?.canUpdate
  useAttemptNavigationItems({
    assignmentId: assignment?.id,
    canManageAttempts,
    classId: selectedClassId,
  })
  useMountInteraction({
    type: 'VIEW_SEGMENT',
    reference: selectedClassId,
    metadata: {
      id: segment.id,
      type: segment.type,
      name: segment.name,
      label: segment.config.label,
      days: segment.config.days,
      classId: selectedClassId,
    },
  })
  useMountInteraction({
    type: 'VIEW_ASSIGNMENT',
    reference: segment.config.templateId,
    metadata: {
      id: segment.id,
      type: segment.type,
      name: segment.name,
      label: segment.config?.label,
      days: segment.config?.days,
      rubricType: segment.config?.rubricType,
      classId: selectedClassId,
    },
  })
  const student = React.useCallback(() => {
    return (
      <SegmentGates segment={segment}>
        <>
          <AssignmentInstructions segment={segment} readOnly={!canUpdate} />
          {canManageAttempts && assignment && selectedClassId ? (
            <AssignmentManageAttempts assignment={assignment} classId={selectedClassId} />
          ) : segment.config?.templateId ? (
            <AssignmentViewer
              templateId={segment.config.templateId}
              segmentId={segment.id}
              forceShowAssignment={viewer.group !== 'STUDENT'}
              enableInteractiveReview={viewer.group !== 'STUDENT'}
            />
          ) : null}
          {isComplete ? segmentCompletionHost : null}
        </>
      </SegmentGates>
    )
  }, [segment.id, segment.config, isComplete, canUpdate, canManageAttempts])
  const teacher = React.useCallback(() => {
    return (
      <>
        {!Legacy.anyEditorValueIsEmpty(segment.config?.teacherContent) ? (
          <Block marginBottom={'@size-l'}>
            <ReadOnlyEditor
              id={`teacherContent-${segment.id}`}
              defaultValue={segment.config.teacherContent}
            />
          </Block>
        ) : null}
        {segment.config?.templateId ? (
          <AssignmentViewer
            segmentId={segment.id}
            teacherView
            forceShowAssignment
            templateId={segment.config.templateId}
          />
        ) : null}
      </>
    )
  }, [segment.id, segment.config])

  let content: React.ReactElement | null
  if (loading) {
    content = <Result icon={<LoadingOutlined size={75} />} title={'Loading your assignment...'} />
  } else {
    let segmentContentChild: React.ReactElement | null = null
    if (selectedNavigationId === AssignmentNavigationIds.Groups && assignment) {
      segmentContentChild = (
        <AssignmentGroups
          assignment={assignment}
          assignedStudents={assignment.students.edges.map(edge => ({
            studentId: edge.node.id,
            classId: edge.classId,
          }))}
          hasSubmissions={!!assignment.submissions?.edges.length}
        />
      )
    } else if (selectedNavigationId === AssignmentNavigationIds.Reports && assignment) {
      segmentContentChild = <AssignmentReports assignment={assignment} />
    } else if (
      selectedNavigationId === AssignmentNavigationIds.Settings &&
      segment.config.templateId
    ) {
      segmentContentChild = (
        <AssignmentSettings
          assignment={assignment as FullAssignment | undefined}
          segmentId={segment.id}
          templateId={segment.config.templateId}
        />
      )
    } else if (selectedNavigationId === AssignmentNavigationIds.Vault) {
      segmentContentChild = <CalculatedAssignmentViewerWrapper segment={segment} teacherView />
    } else if (selectedNavigationId) {
      segmentContentChild = <AssignmentGradingModalContent selectedTab={selectedNavigationId} />
    }

    content = (
      <SegmentContent
        segment={segment}
        selectedNavigationId={selectedNavigationId}
        student={student}
        teacher={teacher}
      >
        {/* This is down here because the children of SegmentContent is the selected navigation ID fallback. */}
        {submissionId && assignment && segment.config.templateId ? (
          <AssignmentSubmission
            templateId={segment.config.templateId}
            segmentId={segment.id}
            forceShowAssignment={viewer.group !== 'STUDENT'}
            assignmentId={assignment.id}
            submissionId={submissionId}
          />
        ) : (
          segmentContentChild
        )}
      </SegmentContent>
    )
  }

  return (
    <>
      <PageHead title={segment.name} />
      <PartialSubmissionProvider
        onSubmissionCreated={
          canManageAttempts
            ? submissionId => {
                setSelectedNavigationItemId?.(submissionId)
              }
            : undefined
        }
      >
        <SegmentCalculationSegmentIdContext.Provider
          value={{ segmentId: segment.id }}
          children={content}
        />
      </PartialSubmissionProvider>
    </>
  )
}
