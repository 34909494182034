import { onMutationError } from '@thesisedu/feature-apollo-react'

import { UPDATE_ASSIGNMENT_QUERIES } from './constants'
import { useCreateAssignmentMutation as useMutation } from '../schema'

export const useCreateAssignmentMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that assignment.'),
    refetchQueries: ['assignments', ...UPDATE_ASSIGNMENT_QUERIES],
    awaitRefetchQueries: true,
    ...opts,
  })
