import { Button } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import { styled, LargeCard } from '@thesisedu/web'
import React from 'react'

import { UserAccountItem } from './UserAccountItem'
import { LinkedAccountFragment } from '../../schema'
import { AuthProviderHandlerResource } from '../../types'
import { ProviderAuthContext } from '../ProviderAuthContext'

export interface UserAccountProviderProps {
  provider: AuthProviderHandlerResource
  accounts?: LinkedAccountFragment[]
  refetchAccounts: () => void
}
export function UserAccountProvider({
  provider,
  accounts = [],
  refetchAccounts,
}: UserAccountProviderProps) {
  const { authenticate } = React.useContext(ProviderAuthContext)
  return (
    <LargeCard style={{ minHeight: 350, width: 350 }}>
      <img src={provider.headerLogoPath} alt={provider.name} style={{ width: '100%' }} />
      <ContentContainer>
        {accounts.map(account => (
          <UserAccountItem
            key={account.providerId}
            account={account}
            onRefresh={() => {
              authenticate(provider.identifier!).finally(() => {
                refetchAccounts()
              })
            }}
          />
        ))}
        <AddContainer>
          <Button
            variant={['chromeless', 'primary']}
            icon={<Plus />}
            onPress={() => {
              authenticate(provider.identifier!).finally(() => {
                refetchAccounts()
              })
            }}
          >
            Add {provider.name} Account
          </Button>
        </AddContainer>
      </ContentContainer>
    </LargeCard>
  )
}

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-top: ${props => props.theme['@size-m']};
  }
`
const ContentContainer = styled.div`
  padding: ${props => props.theme['@size-m']} ${props => props.theme['@size-s']};
  > :not(:first-child) {
    margin-top: ${props => props.theme['@size-s']};
  }
`
