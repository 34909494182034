import { Checkbox, Form } from 'antd'
import React from 'react'

import { useOptions } from './options'

export function SiteFormExtension() {
  const options = useOptions()
  return (
    <Form.Item
      name={'disableCourseCodes'}
      valuePropName={'checked'}
      label={`${options.classNameCapitalized} Codes`}
      extra={
        `If ${options.className} codes are disabled, teachers are not allowed to invite students via ${options.classNameCapitalized} ` +
        `Code and students are not allowed to join this ${options.className} without first being invited.`
      }
    >
      <Checkbox>Disable {options.className} codes for this site.</Checkbox>
    </Form.Item>
  )
}
