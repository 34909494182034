import { SavedReport } from '@thesisedu/feature-reports-react/dist/hooks/routes/Reports'
import { UserRoutesHook } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { StudentReportRedirect } from './StudentReportRedirect'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export default function (feature: CourseReportsReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        return [
          // We have to put this here, because otherwise we will override it.
          <Route
            key={'saved-report'}
            path={'reports/saved/:savedReportId'}
            element={<SavedReport />}
          />,
          <Route
            key={'report-student-teacher-redirect'}
            path={'reports/:classId/:studentId'}
            element={<StudentReportRedirect />}
          />,
          ...routes,
        ]
      } else {
        return routes
      }
    },
  )
}
