import {
  addSearch,
  SearchResultGridItem,
  SearchResultListItem,
  SearchResultNode,
} from '@thesisedu/feature-search-react'
import { DATE_FORMATS } from '@thesisedu/react'
import { IconContainer, ImagePlaceholder } from '@thesisedu/react/dist/grid'
import { User } from 'iconoir-react'
import moment from 'moment'
import React from 'react'

import { StudentsReactFeature } from '../StudentsReactFeature'

interface StudentResultNode extends SearchResultNode {
  studentUser: {
    id: string
    name?: string | null
    username: string
    createdAt: string
  }
}

export default function addSearchResources(feature: StudentsReactFeature) {
  addSearch<StudentResultNode>(
    feature,
    '... on Student { studentUser: user { id name username createdAt } }',
    'Student',
    {
      getUniversalLink: item => `students/${item.id}`, // TODO: Add handler for this.
      getDate: item => item.studentUser.createdAt,
      renderGrid: ({ item }) => (
        <SearchResultGridItem
          title={item.studentUser.name || item.studentUser.username}
          description={moment(item.studentUser.createdAt).format(DATE_FORMATS.DATE)}
          image={
            <ImagePlaceholder>
              <IconContainer children={<User />} />
            </ImagePlaceholder>
          }
        />
      ),
      renderList: ({ item, onClick }) => (
        <SearchResultListItem
          onClick={onClick}
          title={item.studentUser.name || item.studentUser.username}
          subtitle={moment(item.studentUser.createdAt).format(DATE_FORMATS.DATE)}
          type={'Student'}
          thumbnail={
            <ImagePlaceholder>
              <IconContainer children={<User />} />
            </ImagePlaceholder>
          }
        />
      ),
    },
  )
}
