import React, { useContext } from 'react'

export enum UserTaskListDisplay {
  Large,
  Small,
}
export interface UserTaskListContextValue {
  display: UserTaskListDisplay
}

export const UserTaskListContext = React.createContext<UserTaskListContextValue | undefined>(
  undefined,
)

export function UserTaskListContextProvider({
  children,
  ...value
}: React.PropsWithChildren<UserTaskListContextValue>) {
  return <UserTaskListContext.Provider value={value} children={children} />
}

export function useUserTaskListContext(): UserTaskListContextValue | undefined
export function useUserTaskListContext(require: false): UserTaskListContextValue | undefined
export function useUserTaskListContext(require: true): UserTaskListContextValue
export function useUserTaskListContext(require?: boolean): UserTaskListContextValue | undefined {
  const context = useContext(UserTaskListContext)
  if (!context && require) {
    throw new Error('UserTaskListContext is required, yet not provided.')
  }
  return context
}
