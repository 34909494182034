import {
  BasicClassManager as OGBasicClassManager,
  ClassManager as OGClassManager,
  ClassManagerProps,
} from '@thesisedu/feature-classes-react'
import { StyledThemeContext } from '@thesisedu/web'
import React from 'react'

export const BLOCK_SPACING = '@size-m'
export function CourseClassManager(props: ClassManagerProps) {
  const theme = React.useContext(StyledThemeContext)
  return (
    <OGClassManager
      {...props}
      style={{
        ...props.style,
        margin: `-${theme[BLOCK_SPACING]}`,
      }}
    />
  )
}

export function CourseBasicClassManager(props: ClassManagerProps) {
  const theme = React.useContext(StyledThemeContext)
  return (
    <OGBasicClassManager
      {...props}
      style={{
        ...props.style,
        margin: `-${theme[BLOCK_SPACING]}`,
      }}
    />
  )
}
