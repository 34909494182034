import { useResource } from '@thesisedu/feature-react'
import { useImpersonation, useViewerContext } from '@thesisedu/feature-users-web'
import { NotFoundPage } from '@thesisedu/feature-web'
import {
  BasicNavLink,
  Block,
  HSpaced,
  LoadingIndicator,
  PageHead,
  Result,
  size,
  styled,
  VSpaced,
} from '@thesisedu/react'
import { AddUser, Settings } from '@thesisedu/react/icons'
import { PageHeader } from '@thesisedu/web'
import Case from 'case'
import { groupBy, orderBy } from 'lodash'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { GeneralSettings } from './GeneralSettings'
import { TeamTeaching } from './TeamTeaching'
import { ClassConfiguration } from '../../ClassConfiguration'
import { ClassConfigurationWithSave } from '../../ClassConfigurationWithSave'
import { ClassConfigurationFieldResource } from '../../types'
import { useTeacherSelectedClass } from '../../useClass'

export function ClassSettingsRoute() {
  const { cls, error } = useTeacherSelectedClass(true)
  const viewer = useViewerContext(false)
  const { isImpersonating } = useImpersonation()
  const resources = useResource<ClassConfigurationFieldResource>('ClassConfigurationField').filter(
    resource => !resource.filter || resource.filter(viewer, isImpersonating),
  )
  const groups = groupBy(resources, 'group')
  const orderedGroupKeys = orderBy(Object.keys(groups), group => {
    return groups[group].reduce((acc, groupItem) => {
      const weight = groupItem.weight || Infinity
      return weight < acc ? weight : acc
    }, Infinity)
  })

  let content = <LoadingIndicator block />
  if (error || !cls) {
    content = <Result.Error description={'There was an error loading your class.'} />
  } else {
    content = (
      <Block marginTop={'@size-m'}>
        <HSpaced align={'flex-start'} space={'@size-l'}>
          <VSpaced align={'stretch'} space={'@size-xs'} style={{ width: 230 }}>
            <BasicNavLink to={'general'} icon={<Settings />} children={'General'} />
            {orderedGroupKeys.map(group => {
              const groupIcon = groups[group].find(g => g.groupIcon)?.groupIcon || <Settings />
              const groupName = group
              return (
                <BasicNavLink
                  key={group}
                  to={Case.kebab(groupName)}
                  icon={groupIcon}
                  children={groupName}
                />
              )
            })}
            {cls.isOwn ? (
              <BasicNavLink
                to={'teachers'}
                icon={<AddUser />}
                children={'Teachers & Permissions'}
              />
            ) : null}
          </VSpaced>
          <div style={{ flex: 1 }}>
            <Routes>
              <Route path={'/'} element={<Navigate to={'general'} replace />} />
              <Route path={'general'} element={<GeneralSettings />} />
              {Object.keys(groups).map(group => {
                const skipForm = groups[group].every(item => item.skipForm)
                return (
                  <Route
                    key={group}
                    path={`/${Case.kebab(group)}/*`}
                    element={
                      <ConfigurationContainer $size={skipForm ? '@size-m' : undefined}>
                        {skipForm ? (
                          <ClassConfiguration
                            fieldPrefix={[]}
                            classId={cls.id}
                            fields={groups[group]}
                          />
                        ) : (
                          <ClassConfigurationWithSave class={cls} fields={groups[group]} />
                        )}
                      </ConfigurationContainer>
                    }
                  />
                )
              })}
              <Route path={'teachers/*'} element={<TeamTeaching classId={cls.id} />} />
              <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
          </div>
        </HSpaced>
      </Block>
    )
  }

  return (
    <>
      <PageHead title={'Class Settings'} />
      <PageHeader title={'Class Settings'} />
      {content}
    </>
  )
}

const ConfigurationContainer = styled.div<{ $size?: string }>`
  margin-top: -${props => size(props.$size ?? '@size-xl')};
`
