import React from 'react'

export interface PasteContextValue {
  addPasteListener: (listener: React.ClipboardEventHandler) => void
  removePasteListener: (listener: React.ClipboardEventHandler) => void
}
export const PasteContext = React.createContext<PasteContextValue | undefined>(undefined)

export interface PasteContextProviderProps {
  children: React.ReactElement<{ onPaste: React.ClipboardEventHandler }>
}
export function PasteContextProvider({ children }: PasteContextProviderProps) {
  const listenersMap = React.useRef<React.ClipboardEventHandler[]>([])
  const contextValue = React.useMemo<PasteContextValue>(
    () => ({
      addPasteListener: listener => {
        listenersMap.current.push(listener)
      },
      removePasteListener: listener => {
        listenersMap.current = listenersMap.current.filter(l => l !== listener)
      },
    }),
    [],
  )
  return (
    <PasteContext.Provider value={contextValue}>
      {React.cloneElement<{ onPaste: React.ClipboardEventHandler }>(children, {
        onPaste: (...args) => {
          for (const listener of listenersMap.current) {
            listener(...args)
          }
        },
      })}
    </PasteContext.Provider>
  )
}

export function usePasteContext(): PasteContextValue | undefined
export function usePasteContext(require: false): PasteContextValue | undefined
export function usePasteContext(require: true): PasteContextValue
export function usePasteContext(require?: boolean): PasteContextValue | undefined {
  const context = React.useContext(PasteContext)
  if (!context && require) {
    throw new Error('PasteContext is required, yet not provided.')
  }
  return context
}
