import { Button, ButtonProps, message, Popconfirm } from '@thesisedu/react'
import { PopconfirmProps } from 'antd/es/popconfirm'
import React from 'react'

import { useAutoGradeAssignmentSubmissionMutation } from '../queries/useAutoGradeAssignmentSubmissionMutation'

export interface AutoGradeSubmissionButtonProps extends Partial<ButtonProps> {
  submissionId: string
  placement?: PopconfirmProps['placement']
}
export function AutoGradeSubmissionButton({
  submissionId,
  placement,
  ...rest
}: AutoGradeSubmissionButtonProps) {
  const [reGrade, { loading }] = useAutoGradeAssignmentSubmissionMutation({
    onCompleted: () => {
      message.success('Assignment re-graded successfully!')
    },
  })
  return (
    <Popconfirm
      title={'Are you sure? Grades for automatically-gradeable questions will be overridden.'}
      okText={'Yes, re-grade.'}
      okButtonProps={{ danger: true }}
      placement={placement}
      onConfirm={() => {
        return reGrade({
          variables: {
            input: {
              id: submissionId,
            },
          },
        })
      }}
    >
      <Button
        children={'Auto-Grade Again'}
        danger
        {...rest}
        loading={loading}
        data-testid={'AutoGradeSubmissionButton'}
      />
    </Popconfirm>
  )
}
