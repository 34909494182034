import React from 'react'

export type Accidental = 'sharp' | 'flat' | 'natural'
export interface SimpleSheetMusicEditorContextValue {
  onDrop: (
    duration: number | (number | undefined)[],
    pitch: number | (number | undefined)[] | undefined,
    accidental: Accidental | (Accidental | undefined)[] | undefined,
    afterPos: number,
    replace?: number,
    maxAvailableDuration?: number,
  ) => void
  onRemove: (afterPos: number, length: number) => void
  isShift: boolean
  useAdvancedControls?: boolean
}
export const SimpleSheetMusicEditorContext = React.createContext<
  SimpleSheetMusicEditorContextValue | undefined
>(undefined)

export function useSimpleSheetMusicEditorContext(): SimpleSheetMusicEditorContextValue | undefined
export function useSimpleSheetMusicEditorContext(
  require: false,
): SimpleSheetMusicEditorContextValue | undefined
export function useSimpleSheetMusicEditorContext(require: true): SimpleSheetMusicEditorContextValue
export function useSimpleSheetMusicEditorContext(
  require?: boolean,
): SimpleSheetMusicEditorContextValue | undefined {
  const context = React.useContext(SimpleSheetMusicEditorContext)
  if (!context && require) {
    throw new Error('SimpleSheetMusicEditorContext is required, yet not provided.')
  }
  return context
}
