import React from 'react'
import ReactDOM from 'react-dom'

export type PortalRef = React.RefObject<HTMLDivElement> | string

interface PortalContextValue {
  portalRefsRef: React.MutableRefObject<Record<string, React.RefObject<HTMLDivElement>>>
}
const PortalContext = React.createContext<PortalContextValue | undefined>(undefined)

export function PortalProvider({ children }: React.PropsWithChildren<any>) {
  const portalRefsRef = React.useRef<Record<string, React.RefObject<HTMLDivElement>>>({})
  return <PortalContext.Provider value={{ portalRefsRef }} children={children} />
}

export interface PortalProps {
  portal: PortalRef
  portalKey?: string
}
export function Portal({ portal, children, portalKey }: React.PropsWithChildren<PortalProps>) {
  const portalContext = React.useContext(PortalContext)
  if (typeof portal !== 'string' && portal.current) {
    return ReactDOM.createPortal(children, portal.current, portalKey)
  } else if (typeof portal === 'string' && portalContext?.portalRefsRef.current[portal]?.current) {
    return ReactDOM.createPortal(
      children,
      portalContext.portalRefsRef.current[portal].current!,
      portalKey,
    )
  } else {
    return null
  }
}

interface PortalRegisterHostProps {
  portal: string
  className?: string
}
function PortalRegisterHost({ portal, className }: PortalRegisterHostProps) {
  const ref = React.useRef<HTMLDivElement>(null)
  const portalContext = React.useContext(PortalContext)
  if (portalContext) {
    portalContext.portalRefsRef.current[portal] = ref
  }
  React.useEffect(() => {
    if (portalContext) {
      delete portalContext.portalRefsRef.current[portal]
    }
  }, [])
  return <div className={className} ref={ref} />
}

export interface PortalHostProps {
  portal: PortalRef
  className?: string
}
export function PortalHost({ portal, ...rest }: PortalHostProps) {
  if (typeof portal !== 'string') {
    return <div ref={portal} {...rest} />
  } else if (typeof portal === 'string') {
    return <PortalRegisterHost portal={portal} {...rest} />
  } else {
    return null
  }
}
