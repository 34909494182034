import React from 'react'

// Source: https://usehooks.com/useOnScreen/

/**
 * Determines if the passed element is visible in the viewport or not.
 * @param ref A reference to the container DOM element.
 * @param rootMargin Offset margin. For example, "-300px" means at least 300px of the element needs
 *                   to be on the screen.
 * @param debounce The trailing debounce, in milliseconds, so that we don't keep triggering. Defaults to 500.
 * @returns A boolean, indicating if the element is on-screen or not.
 */
export function useOnScreen<T extends Element>(
  ref: React.MutableRefObject<T | null>,
  rootMargin: string = '0px',
  debounce: number = 500,
): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = React.useState<boolean>(false)
  // Prevent anything from happening after we're unmounted.
  React.useEffect(() => {
    return () => {
      shouldWait.current = true
    }
  }, [])
  const shouldWait = React.useRef(false)
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!shouldWait.current) {
          // Update our state when observer callback fires
          setIntersecting(entry.isIntersecting)
          shouldWait.current = true
          setTimeout(() => {
            shouldWait.current = false
          }, debounce)
        }
      },
      {
        rootMargin,
      },
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref.current]) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}

export interface OnScreenProps {
  children: React.ReactElement
  rootMargin?: string
  debounce?: number
}
export function OnScreen({ children, rootMargin, debounce }: OnScreenProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const onScreen = useOnScreen(containerRef, rootMargin, debounce)
  return <div ref={containerRef}>{onScreen ? children : null}</div>
}
