import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { ImageNode } from '@thesisedu/feature-widgets-core/dist/editorNodes/ImageNode'

import { debug } from '../../../../log'
import { Validator } from '../types'

function dataToFile(dataUri: string) {
  const byteString = atob(dataUri.split(',')[1])
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([arrayBuffer], { type: mimeString })
  const extension = mimeString.split('/')[1] || 'png'

  return new File([blob], `uploaded-file.${extension}`, { type: mimeString })
}

const IMAGE_URL_BLACKLIST = ['google']

export default {
  node: ImageNode,
  validate(node, editor) {
    if (node.getSrc()?.startsWith('data:') && !node.getUploadFile()) {
      node.setUploadFile({
        editor,
        file: dataToFile(node.getSrc()!),
      })
    }

    const src = node.getSrc()
    const { filename } = getFileInfo(src)
    let host: string | undefined = undefined
    try {
      host = filename ? new URL(filename).host : undefined
    } catch {
      debug('could not parse host from %s', filename)
    }
    if (
      src &&
      host &&
      IMAGE_URL_BLACKLIST.some(item => {
        return host?.includes(item)
      })
    ) {
      debug('replacing image url %s with blob', src)
      node.setSrc(undefined)
      fetch(src)
        .then(result => {
          return result.blob().then(blob => {
            return { blob, mimeType: result.headers.get('content-type') }
          })
        })
        .then(({ blob, mimeType }) => {
          if (mimeType) {
            const extension = mimeType.split('/')[1] || 'png'
            const file = new File([blob], `uploaded-file.${extension}`, {
              type: `image/${extension}`,
            })
            debug('fetch complete; updating image from %s', src)
            editor.update(() => {
              node.setUploadFile({
                editor,
                file,
              })
            })
          } else {
            debug('could not detect mime type; removing image')
            editor.update(() => {
              node.remove()
            })
          }
        })
    }
  },
} satisfies Validator<ImageNode>
