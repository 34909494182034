import { useResource } from '@thesisedu/feature-react'
import { HSpaced, LoadingIndicator, Text, s, styled } from '@thesisedu/ui'
import { OpenNewWindow } from '@thesisedu/ui/icons'
import React from 'react'

import { ReportMetricResource } from '../../../types'
import { getFormattedMetric } from '../../getFormattedMetric'
import { useReportGridContext } from '../Context'
import { isMetrics } from '../axes'
import { useReportGridColorContext } from '../colors/ColorContext'
import { getCellColors } from '../colors/getCellColors'

export interface ValueCellProps {
  rawRow: number
  rawColumn: number
  rawVisibleRow: number
  style: any
}
export function ValueCell({ rawRow, rawColumn, rawVisibleRow, style }: ValueCellProps) {
  const row = rawRow - 1
  const column = rawColumn - 1
  const { cells, extents, visibleRows, flatSummaryRows, data, responsibility } =
    useReportGridContext(true)
  const [clickLoading, setClickLoading] = React.useState(false)
  const metricsAreColumns = isMetrics(responsibility.col)
  const colorContext = useReportGridColorContext(true)
  const summaryRow = flatSummaryRows?.[rawRow - 1]
  const isCollapsible = !!summaryRow?.children?.length
  const isLastRow = rawVisibleRow === visibleRows
  const isLastColumn = rawColumn === extents.numColumns
  const hasMultipleCells = extents.numColumns > 1
  const cell = cells[column]?.[row]
  const metric = useResource<ReportMetricResource>('ReportMetric', cell?.metricIdentifier)
  if (cell) {
    if (!metric) throw new Error(`Invalid metric for cell: '${cell.metricIdentifier}'`)
    const { text, background } = hasMultipleCells
      ? getCellColors(
          cell,
          metricsAreColumns ? summaryRow?.summaries || data.allMetrics : data.allMetrics,
          metric,
          colorContext.opts,
        )
      : { text: undefined, background: undefined }
    const content = (
      <Text style={{ color: text }} level={'s'}>
        {getFormattedMetric(cell.metricValue, metric)}
      </Text>
    )
    const onClick = metric.getOnClick && cell.item ? metric.getOnClick(cell.item) : undefined
    return (
      <CellContainer
        data-is-last-row={isLastRow}
        data-is-last-column={isLastColumn}
        data-is-collapsible={isCollapsible}
        style={{
          ...style,
          background,
        }}
      >
        {onClick ? (
          <a
            onClick={async e => {
              e.preventDefault()
              setClickLoading(true)
              try {
                await onClick()
              } finally {
                setClickLoading(false)
              }
            }}
          >
            <HSpaced space={'xxs'}>
              {content}
              <Text
                level={'xs'}
                style={background ? { color: text } : undefined}
                color={background ? undefined : 'secondary'}
              >
                {clickLoading ? <LoadingIndicator /> : <OpenNewWindow />}
              </Text>
            </HSpaced>
          </a>
        ) : (
          content
        )}
      </CellContainer>
    )
  } else {
    return null
  }
}

const CellContainer = styled.div`
  padding: ${s.size('xs')};
  border-bottom: solid 1px ${s.color('border')};
  border-right: solid 1px ${s.color('border')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${s.color('background')};
  &[data-is-collapsible='true'] {
    background: ${s.color('element')};
  }
  &[data-is-last-row~='true'] {
    border-bottom: none;
  }
  &[data-is-last-column='true'] {
    border-right: none;
  }
`
