import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { ExtraSmallButton, StyledThemeContext } from '@thesisedu/web'
import React from 'react'

import { SegmentIndicatorProps, SegmentIndicatorResource } from '../../outline/SegmentIndicators'
import { useMarkAsComplete, useMarkedAsComplete } from '../segment_actions/MarkCompleteAction'

function MarkCompleteIndicator({ segmentId }: SegmentIndicatorProps) {
  const markAsComplete = useMarkAsComplete()
  const isComplete = useMarkedAsComplete([segmentId])
  const theme = React.useContext(StyledThemeContext)
  if (!isComplete) return null
  return (
    <ExtraSmallButton
      title={isComplete ? 'Marked as Complete' : 'Mark as Complete'}
      onClick={() => {
        markAsComplete([segmentId], !isComplete)
      }}
      icon={isComplete ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
      style={{
        background: theme[isComplete ? '@green-light' : '@gray-2'],
        color: theme[isComplete ? '@green' : '@gray-6'],
      }}
    />
  )
}

export const MarkComplete: SegmentIndicatorResource = {
  type: 'SegmentIndicator',
  identifier: 'MarkComplete',
  component: MarkCompleteIndicator,
}
