import { Button } from '@thesisedu/react'
import { Star } from '@thesisedu/react/icons'
import React from 'react'

import { useUseAssignmentSubmissionMutation } from '../queries/useUseAssignmentSubmissionMutation'

export interface UseAssignmentSubmissionButtonProps {
  assignmentId: string
  assignmentSubmissionId: string
}
export function UseAssignmentSubmissionButton({
  assignmentId,
  assignmentSubmissionId,
}: UseAssignmentSubmissionButtonProps) {
  const [use, { loading }] = useUseAssignmentSubmissionMutation({
    variables: {
      input: {
        id: assignmentId,
        submissionId: assignmentSubmissionId,
      },
    },
  })

  return (
    <Button
      loading={loading}
      onClick={e => {
        e.preventDefault()
        return use()
      }}
      icon={<Star />}
    >
      Use this Attempt
    </Button>
  )
}
