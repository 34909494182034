import { DeleteOutlined } from '@ant-design/icons'
import { Dragger } from '@sammarks/icons'
import { Option } from '@thesisedu/feature-assignments-core'
import { styled, Block } from '@thesisedu/web'
import { NestedDragDropProvider, getDroppableId } from '@thesisedu/web/react-beautiful-dnd'
import { Button } from 'antd'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'

import { MultipleChoiceChoice } from './MultipleChoiceChoice'
import { MultipleChoiceLetterMode } from './MultipleChoiceLetter'
import { QuestionInstructions } from './QuestionInstructions'
import { QuestionTextMediaField } from '../../questions/QuestionTextMediaField'

export interface MultipleChoiceEditorProps {
  options?: Option[]
  onChange?: (options: Option[]) => void
  instructions?: string
  children?: React.ReactElement | null
}
export function MultipleChoiceEditor({
  instructions = 'Edit your answers using the fields below, and click on the letter of the correct answer(s).',
  options,
  onChange,
  children,
}: MultipleChoiceEditorProps) {
  const [autoFocusId, setAutoFocusId] = React.useState<string | null>(null)
  const addOption = () => {
    if (onChange) {
      const newId = uuid()
      onChange([
        ...(options || []),
        {
          id: newId,
          name: '',
          correct: false,
        },
      ])
      setAutoFocusId(newId)
    }
  }
  const [nestedId] = React.useState(uuid())
  const nestName = `multiple-choice-editor-${nestedId}`
  return (
    <QuestionInstructions instructions={instructions}>
      {children}
      <NestedDragDropProvider
        name={nestName}
        onDragEnd={result => {
          if (result.destination && onChange) {
            const newOptions = Array.from(options || [])
            const [reorderedItem] = newOptions.splice(result.source.index, 1)
            newOptions.splice(result.destination.index, 0, reorderedItem)
            onChange(newOptions)
          }
        }}
      >
        <Droppable droppableId={getDroppableId('multiple-choice', nestName)} type={nestName}>
          {provided => (
            <div ref={provided.innerRef}>
              {(options || []).map((option, index) => {
                const update = (changes: Partial<Option>) => {
                  if (onChange) {
                    onChange(
                      (options || []).map(opt => {
                        if (opt.id === option.id) {
                          return { ...opt, ...changes }
                        } else {
                          return opt
                        }
                      }),
                    )
                  }
                }
                return (
                  <Draggable draggableId={`${nestName}${option.id}`} index={index} key={option.id}>
                    {provided => (
                      <DraggableContainer ref={provided.innerRef} {...provided.draggableProps}>
                        <MultipleChoiceChoice
                          option={option}
                          index={index}
                          mode={option.correct ? MultipleChoiceLetterMode.Correct : undefined}
                          leftContent={
                            <span className={'dragger'} {...provided.dragHandleProps}>
                              <Dragger />
                            </span>
                          }
                          onChange={correct => {
                            if (onChange && correct) {
                              onChange(
                                (options || []).map(opt => {
                                  if (opt.id === option.id) {
                                    return { ...opt, correct: true }
                                  } else {
                                    return opt
                                  }
                                }),
                              )
                            } else {
                              update({ correct })
                            }
                          }}
                        >
                          <Block marginLeft={'@size-xs'} style={{ flex: 1 }}>
                            <QuestionTextMediaField
                              value={option.name}
                              onChange={name => update({ name })}
                              inputProps={{
                                autoFocus: autoFocusId === option.id,
                                onPressEnter: () => addOption(),
                                placeholder: 'Add your answer here...',
                              }}
                            />
                          </Block>
                          <Block marginLeft={'@size-xs'}>
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                if (onChange) {
                                  onChange((options || []).filter(o => o.id !== option.id))
                                }
                              }}
                            />
                          </Block>
                        </MultipleChoiceChoice>
                      </DraggableContainer>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              <MultipleChoiceChoice
                isAdd
                option={{
                  id: 'add',
                  name: `Add ${options?.length ? 'another' : 'the first'} answer...`,
                  correct: false,
                }}
                index={1}
                onClick={addOption}
              />
            </div>
          )}
        </Droppable>
      </NestedDragDropProvider>
    </QuestionInstructions>
  )
}

const DraggableContainer = styled.div`
  .dragger {
    display: inline-block;
    color: ${props => props.theme['@text-color-secondary']};
    padding: ${props => props.theme['@size-xs']};
    margin-left: -${props => props.theme['@size-xs']};
    svg {
      display: block;
    }
  }
`
