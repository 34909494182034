import { useApolloClient } from '@apollo/client'
import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'
import { Button, Button$ } from '@thesisedu/ui'
import { LoadActionFloppy } from '@thesisedu/ui/icons'
import React from 'react'

import { PresetSelectModal } from './PresetSelectModal'
import {
  ClassGroupPresetDocument,
  ClassGroupPresetQuery,
  ClassGroupPresetQueryVariables,
} from '../../schema'

export interface LoadFromPresetButtonProps extends Partial<Button$.ButtonProps> {
  classId: string
  onChange: (groups: GroupsConfiguration) => void
}
export function LoadFromPresetButton({ classId, onChange, ...rest }: LoadFromPresetButtonProps) {
  const [loading, setLoading] = React.useState(false)
  const client = useApolloClient()
  return (
    <PresetSelectModal
      classId={classId}
      onSelected={async preset => {
        setLoading(true)
        try {
          const result = await client.query<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>({
            query: ClassGroupPresetDocument,
            variables: {
              id: preset.id,
            },
          })
          const fullPreset =
            result.data.node?.__typename === 'ClassGroupPreset' ? result.data.node : undefined
          if (fullPreset) {
            onChange?.(fullPreset.configuration)
          }
        } finally {
          setLoading(false)
        }
      }}
      trigger={
        <Button
          loading={loading}
          icon={<LoadActionFloppy />}
          children={'Load from Preset'}
          {...rest}
        />
      }
    />
  )
}
