import { useFreshRef } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { Text, styled, s } from '@thesisedu/ui'
import { Clock, Pause } from '@thesisedu/ui/icons'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { RecordFooterCell } from './RecordFooterCell'
import { debug } from '../../../../log'
import { useRecordViewContext } from '../../../RecordViewContext'
import { useDetailedRecordStateContext } from '../DetailedRecordStateContext'

export function RecordTimer() {
  const { maxDurationSeconds } = useRecordViewContext(true)
  const maxDurationSecondsRef = useFreshRef(maxDurationSeconds)
  const { state, setState } = useDetailedRecordStateContext()
  const recordingStartTime = state?.type === 'recording' ? state.startTime : undefined
  const currentTimeRef = React.useRef<HTMLDivElement>(null)
  const isRecording = state?.type === 'recording'
  const isPaused = state?.type === 'paused'
  React.useEffect(() => {
    if (isRecording && recordingStartTime) {
      const handle = setInterval(() => {
        const currentTimeMS = Date.now() - recordingStartTime
        const currentTimeSeconds = currentTimeMS / 1000
        const formattedTime = Format.duration(currentTimeSeconds)
        if (currentTimeRef.current) {
          currentTimeRef.current.innerText = formattedTime ?? ''
        }

        if (maxDurationSecondsRef.current && currentTimeSeconds > maxDurationSecondsRef.current) {
          debug('time limit reached; stopping recording')
          setState({ type: 'stopping' })
        }
      }, 250)
      return () => clearInterval(handle)
    }
  }, [isRecording, recordingStartTime])
  return (
    <AnimatePresence initial={false}>
      {isRecording || isPaused || maxDurationSeconds ? (
        <RecordFooterCell
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {isPaused ? <Pause /> : <Clock />}
          <AnimatePresence initial={false}>
            {isRecording || isPaused ? (
              <RecordingTimeContainer
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
              >
                <Text level={'s'} ref={currentTimeRef}>
                  00:00
                </Text>
                {maxDurationSeconds ? (
                  <Text level={'s'} color={'secondary'}>
                    /
                  </Text>
                ) : null}
              </RecordingTimeContainer>
            ) : null}
          </AnimatePresence>
          {maxDurationSeconds ? (
            <Text
              level={'s'}
              color={isRecording ? 'secondary' : undefined}
              style={{ lineHeight: 1 }}
            >
              {Format.duration(maxDurationSeconds)}
            </Text>
          ) : null}
        </RecordFooterCell>
      ) : null}
    </AnimatePresence>
  )
}

const RecordingTimeContainer = styled(motion.div)`
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: ${s.size('xxs')};
  * {
    line-height: 1 !important;
  }
`
