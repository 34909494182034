import { Feature } from '@thesisedu/feature'
import { useContext } from 'react'

import { WebContext } from './WebProvider'

export const useFeature = <T extends Feature>(name: string): T => {
  const webContext = useContext(WebContext)
  if (!webContext || !webContext.featureWeb) {
    throw new Error(`Could not find feature '${name}'`)
  }
  return webContext.featureWeb.requireFeature<T>(name)
}
