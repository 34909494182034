import { ArrowRightOutlined } from '@ant-design/icons'
import { UserAvatar } from '@thesisedu/feature-attachments-react'
import { CommentPlainText } from '@thesisedu/feature-comments-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import {
  styled,
  HSpaced,
  BodyLarge,
  Body,
  BodySmall,
  FontWeight,
  MultiAvatar,
  DATE_FORMATS,
} from '@thesisedu/web'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { getRecipients } from '../helpers'
import { DirectMessageThreadFragment } from '../schema'

export interface DirectMessageThreadItemProps {
  thread: DirectMessageThreadFragment
  selected?: boolean
  onClick?: () => void
}
export function DirectMessageThreadItem({ thread }: DirectMessageThreadItemProps) {
  const viewer = useViewerContext(false)
  let avatar
  if (thread.recipients.length === 0) {
    avatar = null
  } else if (thread.recipients.length === 1) {
    avatar = <UserAvatar size={48} user={thread.recipients[0]} />
  } else {
    avatar = (
      <MultiAvatar size={48}>
        {thread.recipients.slice(0, 3).map(user => (
          <UserAvatar user={user} key={user.id} />
        ))}
        {thread.recipients.length < 4 ? (
          <></>
        ) : thread.recipients.length === 4 ? (
          <UserAvatar user={thread.recipients[3]} key={thread.recipients[3].id} />
        ) : (
          <UserAvatar
            user={
              {
                username: `${(thread.recipients.length - 3)
                  .toString()
                  .padStart(2, '+')
                  .split('')
                  .join(' ')}`,
              } as any
            }
            key={'others'}
          />
        )}
      </MultiAvatar>
    )
  }

  const unread = !!thread.comments.unread
  const lastComment = thread.comments.edges[0]?.node
  const lastCommentMine = lastComment && viewer?.id === lastComment?.user.id

  const date = thread.comments.edges[0]?.node.createdAt || thread.createdAt

  return (
    <Container to={`./${thread.id}`} activeClassName={'selected'}>
      <HSpaced align={'flex-start'}>
        {avatar}
        <div style={{ flex: 1, minWidth: 0 }}>
          {unread ? <UnreadIndicator /> : null}
          <HSpaced align={'center'} space={'@size-xs'}>
            <BodyLarge
              className={'title'}
              weight={FontWeight.Bold}
              color={'@text-color'}
              style={{ flex: 1 }}
            >
              {getRecipients(thread)}
            </BodyLarge>
            <BodySmall
              color={'@text-color-secondary'}
              title={moment(date).format(DATE_FORMATS.FULL)}
              style={{ textAlign: 'right' }}
            >
              {moment(date).fromNow()}
            </BodySmall>
          </HSpaced>
          {lastComment ? (
            <Body
              color={lastCommentMine ? '@text-color-secondary' : '@text-color'}
              className={'contents'}
            >
              {lastCommentMine ? <ArrowRightOutlined className={'mine-icon'} /> : null}
              <CommentPlainText {...lastComment} />
            </Body>
          ) : null}
        </div>
      </HSpaced>
    </Container>
  )
}

const Container = styled(NavLink)`
  display: block;
  cursor: pointer;
  transition:
    background 0.25s linear,
    opacity 0.25s linear;
  opacity: 0.75;
  padding: ${props => props.theme['@size-xs']};
  position: relative;
  border-radius: ${props => props.theme['@border-radius-base']};
  &:hover,
  &.selected {
    background: ${props => props.theme['@background-color-base']};
    opacity: 1;
  }
  > div > :first-child {
    // The avatar
    flex-shrink: 0;
  }
  .title {
    min-width: 0;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .contents {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: calc(${props => props.theme['@font-size-base']} * 1.4);
    max-height: calc(${props => props.theme['@font-size-base']} * 1.4 * 2);
    .mine-icon {
      display: inline-block;
      margin-right: ${props => props.theme['@size-xs']};
    }
    > * {
      display: inline;
      margin-bottom: 0;
      font-weight: normal;
      font-size: ${props => props.theme['@font-size-base']};
      line-height: ${props => props.theme['@line-height-base']};
      letter-spacing: 0;
      color: inherit;
      &::after {
        content: ' ';
        display: inline;
      }
    }
    > *:nth-of-type(n + 2) {
      display: none;
    }
  }
`
const UnreadIndicator = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-xs']};
  left: ${props => props.theme['@size-xs']};
  width: ${props => props.theme['@size-s']};
  height: ${props => props.theme['@size-s']};
  border-radius: 50%;
  background: ${props => props.theme['@primary-color']};
  border: solid 1px white;
`
