import { MediaBookmarkFragment } from '../../schema'

export function getCurrentBookmark(
  bookmarks: MediaBookmarkFragment[],
  currentTimeSeconds: number | null,
): MediaBookmarkFragment | null {
  if (currentTimeSeconds == null) return null
  return (
    bookmarks.find(bookmark => {
      return (
        bookmark.startSeconds <= currentTimeSeconds && currentTimeSeconds <= bookmark.endSeconds
      )
    }) ?? null
  )
}
