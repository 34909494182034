import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'

import { CreateSubscriptionModal, CreateSubscriptionModalProps } from './CreateSubscriptionModal'

export function CreateSubscriptionButton(props: Partial<CreateSubscriptionModalProps>) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <CreateSubscriptionModal onCancel={() => setVisible(false)} visible={visible} {...props} />
      <Button type={'primary'} onClick={() => setVisible(true)} icon={<PlusOutlined />}>
        Create Subscription
      </Button>
    </>
  )
}
