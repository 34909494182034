import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'

export interface LicensesReactFeatureOptions {
  stripeKey: string
  showStudentPay?: boolean
}
export class LicensesReactFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-licenses-react'
  static path = ''
  static requires: string[] = []
  public readonly options!: LicensesReactFeatureOptions

  constructor(options: Partial<LicensesReactFeatureOptions>, deps: FeatureDependencies) {
    super(
      {
        stripeKey: 'test-key',
        ...options,
      },
      deps,
    )
  }

  public reactResources() {
    require('./hooks/SiteContent').default(this)
    require('./hooks/UpdateSiteRefetchQueries').default(this)
    require('./resources').default.call(this)
  }
}

export default LicensesReactFeature
export const licensesReactFeature = (
  options: Partial<LicensesReactFeatureOptions> = {},
): FeatureUse => [LicensesReactFeature, options]
