import { DndContext } from '@dnd-kit/core'
import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'
import { ClassStudentPair } from '@thesisedu/feature-classes-react'
import { useMultiSortable } from '@thesisedu/react'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { Actions } from './Actions'
import { GroupsContext, GroupsContextValue } from './GroupsContext'
import { GroupsEditorProps } from './GroupsEditor'
import { GroupsGrid } from './GroupsGrid'
import { StudentDraggableContent } from './StudentDraggable'
import { UngroupedStudents } from './UngroupedStudents'
import { GroupsStudent } from './types'
import { validateGroups } from './validateGroups'

export interface WithGroupsProps extends GroupsEditorProps {}
export function WithGroups({
  students,
  value,
  hideSavePreset,
  onChange: _onChange,
  classId,
}: WithGroupsProps) {
  const onChange = React.useCallback(
    (groups: GroupsConfiguration) => {
      return _onChange?.(validateGroups(groups))
    },
    [_onChange],
  )

  const contextValue = React.useMemo<GroupsContextValue>(() => {
    const groupedStudents: Record<string, GroupsStudent[]> = {}
    const groupedStudentIds = new Set<string>()
    for (const groupKey of Object.keys(value ?? {})) {
      groupedStudents[groupKey] = []
      for (const studentId of value?.[groupKey].students ?? []) {
        const student = students.find(student => student.studentId === studentId)
        if (student) {
          groupedStudentIds.add(studentId)
          groupedStudents[groupKey].push(student)
        }
      }
    }
    return {
      groups: value ?? {},
      onChange: newValue => onChange?.(newValue),
      students: groupedStudents,
      ungroupedStudents: students.filter(student => !groupedStudentIds.has(student.studentId)),
      classId,
    }
  }, [value, onChange, students, classId])

  const { contextProps, overlay } = useMultiSortable({
    items: Object.keys(value ?? {}).reduce<Record<string, string[]>>(
      (acc, key) => {
        acc[key] = value?.[key].students ?? []
        return acc
      },
      {
        ungrouped: contextValue.ungroupedStudents.map(s => s.studentId),
      },
    ),
    setItems({ ungrouped, ...items }) {
      onChange?.(
        Object.keys(value ?? {}).reduce<GroupsConfiguration>((acc, groupKey) => {
          acc[groupKey] = {
            ...value![groupKey],
            students: items[groupKey] ?? [],
          }
          return acc
        }, {}),
      )
    },
    renderDragOverlay(itemId) {
      const pair = students.find(s => s.studentId === itemId)
      if (!pair) throw new Error('Cannot find student.')
      return renderSortableItemDragOverlay(pair)
    },
  })

  return (
    <DndContext {...contextProps}>
      <GroupsContext.Provider value={contextValue}>
        <UngroupedStudents />
        <VSpaced top={'m'}>
          <Actions hideSavePreset={hideSavePreset} />
          <GroupsGrid />
        </VSpaced>
        {overlay}
      </GroupsContext.Provider>
    </DndContext>
  )
}

function renderSortableItemDragOverlay(student: ClassStudentPair) {
  return <StudentDraggableContent student={student} dragOverlay />
}
