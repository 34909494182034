import { prefersReducedMotion } from './prefersReducedMotion'
import { s } from '../'

export interface AnimateOptions extends Omit<KeyframeAnimationOptions, 'easing'> {
  easing?: keyof s.Curves
}
export function animate(
  element: HTMLElement,
  keyframes: Keyframe[] | PropertyIndexedKeyframes | null,
  {
    easing = 'ease',
    duration = easing === 'exponential' ? 1500 : 500,
    ...options
  }: AnimateOptions = {},
) {
  if (prefersReducedMotion()) return
  element.animate(keyframes, {
    ...options,
    duration,
    easing: s.getThemeValue(`curves.${easing}`),
  })
}
