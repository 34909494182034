import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import { LoadingIndicator } from '@thesisedu/react'
import { Text } from '@thesisedu/ui'
import { Block, getFlag, HSpaced, setFlag, Space } from '@thesisedu/web'
import { Checkbox } from 'antd'
import React from 'react'

import { AssignmentQuestions } from './AssignmentQuestions'
import { IncompleteQuestionWarning } from './IncompleteQuestionWarning'
import { QuestionAssignmentSubmissionGradeHeader } from './QuestionAssignmentSubmissionGradeHeader'
import { QuestionGradeComment } from './form/QuestionGradeComment'
import { useGroupsContextValue } from './useSubmissionContextValue'
import { AssignmentViewerContextProvider } from '../contexts/AssignmentViewerContext'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import {
  GradingModalSubmissionContextProvider,
  SubmissionValues,
} from '../contexts/GradingModalSubmissionContext'
import { SubmissionContextProvider } from '../contexts/SubmissionContext'
import { AssignmentSubmissionProps } from '../grading/AssignmentSubmission'
import { JumpToRubricButton } from '../grading/JumpToRubricButton'
import { SubmissionGradeAll } from '../grading/SubmissionGradeAll'
import { SubmissionGradeContent } from '../grading/SubmissionGradeContent'
import { SubmissionGradeHeader } from '../grading/SubmissionGradeHeader'
import { SubmissionGradeItemGrade } from '../grading/SubmissionGradeItemGrade'
import { SubmissionGradeRubric } from '../grading/SubmissionGradeRubric'
import {
  AssignmentGradingMode,
  AssignmentRubricFragment,
  useAssignmentSubmissionConfigurationQuery,
} from '../schema'
import { GradesMap } from '../types'

const HIDE_SUGGESTED_ANSWERS_FLAG = 'feature-assignments:hide-suggested-answers'

function getGradedCount(
  grades?: GradesMap | null,
  rubric?: AssignmentRubricFragment | null,
): number {
  return (rubric?.categories || []).filter(
    q => grades?.[q.categoryId] !== null && grades?.[q.categoryId] !== undefined,
  ).length
}

export function QuestionAssignmentSubmission({ submission }: AssignmentSubmissionProps) {
  const { assignment } = useGradingModalContext(true)
  const submissionConfigurationQuery = useAssignmentSubmissionConfigurationQuery({
    variables: {
      submissionId: submission.id,
    },
  })
  const submissionConfiguration =
    submissionConfigurationQuery.data?.node?.__typename === 'AssignmentSubmission'
      ? submissionConfigurationQuery.data.node.assignmentConfiguration
      : undefined
  const assignmentConfiguration = assignment.configuration?.questions
    ? assignment.configuration
    : undefined
  const configuration: AssignmentConfiguration = submissionConfiguration || assignmentConfiguration
  if (!configuration) {
    throw new Error('Cannot use QuestionAssignmentSubmission without assignment configuration.')
  }

  // Graded count calculations + updating.
  const [gradedCount, setGradedCount] = React.useState<number>(
    getGradedCount(submission.grades, assignment.rubric),
  )
  const [gradedAll, setGradedAll] = React.useState<boolean>(
    submission?.grades?.['all'] !== undefined &&
      submission?.grades?.['all'] !== null &&
      Object.keys(submission?.grades || {}).filter(
        key => submission?.grades[key] !== undefined && submission?.grades[key] !== null,
      ).length <= 1,
  )
  React.useEffect(() => {
    setGradedCount(getGradedCount(submission.grades, assignment.rubric))
    setGradedAll(
      submission?.grades?.['all'] !== undefined &&
        submission?.grades?.['all'] !== null &&
        Object.keys(submission?.grades || {}).filter(
          key => submission?.grades[key] !== undefined && submission?.grades[key] !== null,
        ).length <= 1,
    )
  }, [submission, configuration.questions.length])
  const onValuesChange = React.useCallback(
    (values: SubmissionValues) => {
      setGradedCount(getGradedCount(values.grades, assignment.rubric))
      setGradedAll(
        values.grades?.['all'] !== undefined &&
          values.grades?.['all'] !== null &&
          Object.keys(values.grades || {}).filter(
            key => values.grades[key] !== undefined && values.grades[key] !== null,
          ).length <= 1,
      )
    },
    [configuration.questions.length],
  )

  // Other states and flags.
  const [showSuggestedAnswers, setShowSuggestedAnswers] = React.useState(
    !getFlag(HIDE_SUGGESTED_ANSWERS_FLAG),
  )

  // Rendering content.
  const questionIds = configuration.questions.map(q => q.id)
  const remainingCategories =
    assignment.rubric?.categories.filter(cat => !questionIds.includes(cat.categoryId)) || []
  const questions = (configuration?.questions || []).filter(q => !q.disabled)
  const groups = useGroupsContextValue(assignment, submission.studentId)

  if (submissionConfigurationQuery.loading) {
    return <LoadingIndicator block />
  }

  return (
    <GradingModalSubmissionContextProvider
      assignment={assignment}
      submission={submission}
      onValuesChange={onValuesChange}
    >
      <AssignmentViewerContextProvider
        value={{
          classId: submission.classId,
          // TODO: Let's hope we don't need this.
          templateId: assignment.assignmentTemplateId || '',
          configuration,
        }}
      >
        <SubmissionContextProvider
          value={{
            assignment,
            cancelValuesDebounce: () => {},
            classId: submission.classId,
            onFieldsChange: () => {},
            onRetry: () => {},
            onSaveSubmission: () => Promise.resolve(''),
            onSubmit: () => Promise.resolve(),
            submissionData: submission.submissionData,
            questionMetadata: submission.questionMetadata,
            studentId: submission.studentId,
            submission,
            groups,
          }}
        >
          <SubmissionGradeContent
            header={
              <>
                <SubmissionGradeHeader submission={submission} studentId={submission.studentId} />
                {gradedAll ? null : (
                  <QuestionAssignmentSubmissionGradeHeader
                    gradedCount={gradedCount}
                    questionCount={
                      assignment.rubric?.categories.length || configuration?.questions.length || 0
                    }
                  />
                )}
              </>
            }
          >
            <HSpaced align={'flex-end'}>
              {submission.deletedAt ? null : assignment.gradingMode ===
                AssignmentGradingMode.Rubric ? (
                configuration.questions.length ? (
                  <JumpToRubricButton />
                ) : null
              ) : (
                <SubmissionGradeAll />
              )}
              <Space />
              <Checkbox
                checked={showSuggestedAnswers}
                onChange={e => {
                  setShowSuggestedAnswers(e.target.checked)
                  setFlag(HIDE_SUGGESTED_ANSWERS_FLAG, !e.target.checked)
                }}
              >
                Show Suggested Answers &amp; Explanations
              </Checkbox>
            </HSpaced>
            <Block marginTop={'@size-l'}>
              <AssignmentQuestions
                editorId={`QuestionAssignmentSubmission-${assignment.id}`}
                questions={questions}
                widgets={configuration?.widgets}
                questionProps={{ answerView: { showExplanation: showSuggestedAnswers } }}
                renderGradeComment={
                  submission.deletedAt || !submission.canGrade
                    ? question => (
                        <QuestionGradeComment
                          question={question}
                          submission={submission}
                          assignment={assignment}
                        />
                      )
                    : question => (
                        <IncompleteQuestionWarning question={question} submission={submission} />
                      )
                }
                questionFooter={
                  submission.deletedAt || !submission.canGrade
                    ? undefined
                    : question => (
                        <Block marginTop={'@size-s'}>
                          <SubmissionGradeItemGrade rubricId={question.id} />
                        </Block>
                      )
                }
              />
            </Block>
            {remainingCategories?.length ? (
              <>
                {configuration.questions?.length ? (
                  <Text level={'h2'} bottom>
                    Rubric
                  </Text>
                ) : null}
                <SubmissionGradeRubric
                  rubric={{ categories: remainingCategories }}
                  disabled={!!submission.deletedAt || !submission.canGrade}
                />
              </>
            ) : null}
          </SubmissionGradeContent>
        </SubmissionContextProvider>
      </AssignmentViewerContextProvider>
    </GradingModalSubmissionContextProvider>
  )
}
