import React from 'react'

import { getProviderError } from './providerError'
import { getRosterStudents } from './rosterStudents'
import { debug } from '../../log'
import { SiteClassSyncProviderFragment, useClassSyncRosteringQuery } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, getStepFactory } from '../types'

export interface FetchClassStudentsFromProviderContext {
  provider: SiteClassSyncProviderFragment
  syncId: string
  highlightUserIds?: string[]
  exitAfterRostering?: boolean
}
export default {
  size: StepSizes.loading,
  animationGroup: 'loading',
  hideClose: true,
  Component({ context, transition }) {
    useClassSyncRosteringQuery({
      variables: {
        id: context.syncId,
      },
      fetchPolicy: 'network-only',
      onError() {
        transition(
          getProviderError({ ...context, nextStep: getFetchClassStudentsFromProvider(context) }),
        )
      },
      onCompleted(data) {
        const result = data.node?.__typename === 'ClassSync' ? data.node : undefined
        const updates = result?.rostering.updates
        if (result && updates) {
          transition(
            getRosterStudents({
              ...context,
              providerClassName: result.label,
              updates,
            }),
          )
        } else {
          debug('cannot find updates in response; transitioning to error')
          transition(
            getProviderError({ ...context, nextStep: getFetchClassStudentsFromProvider(context) }),
          )
        }
      },
    })
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        title={'Finding Students'}
        direction={'left'}
        message={<>We are asking {context.provider.name} for a list of students in your class.</>}
      />
    )
  },
} satisfies SetupStep<FetchClassStudentsFromProviderContext>
export const getFetchClassStudentsFromProvider =
  getStepFactory<FetchClassStudentsFromProviderContext>('fetchClassStudentsFromProvider')
