import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export class VideoOnDemandReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-video-on-demand-react'
  static path = ''
  static requires = []

  public reactResources() {
    const { addVideoThumbnailTypes } = require('./resources/VideoThumbnailType')
    addVideoThumbnailTypes(this.resources)
  }
}

export const videoOnDemandReactFeature: ReactUse = (opts = {}) => [VideoOnDemandReactFeature, opts]
