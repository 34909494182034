import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Body, Button, Space, HSpaced, Popconfirm } from '@thesisedu/react'
import { AddToCart, Check, Clock, WarningCircle } from '@thesisedu/react/icons'
import React from 'react'

import {
  useGenerateVodCaptionsMutation,
  useVideoOnDemandQuery,
  VideoOnDemandCaptionStatus,
} from './schema'

const PRICE_PER_MINUTE = 5

export interface GenerateCaptionsButtonProps {
  vodId: string
}
export function GenerateCaptionsButton({ vodId }: GenerateCaptionsButtonProps) {
  const [shouldPoll, setShouldPoll] = React.useState(false)
  const { data } = useVideoOnDemandQuery({
    variables: {
      id: vodId,
    },
    pollInterval: shouldPoll ? 60000 : undefined,
  })
  const [generate, { loading }] = useGenerateVodCaptionsMutation({
    onError: onMutationError('There was an error requesting your captions.'),
  })

  const vod =
    data?.node?.__typename === 'VideoOnDemand' && data.node.status.duration ? data.node : undefined
  React.useEffect(() => {
    setShouldPoll(
      !!vod?.captionStatus &&
        vod.captionStatus !== VideoOnDemandCaptionStatus.Success &&
        vod.captionStatus !== VideoOnDemandCaptionStatus.Failed,
    )
  }, [vod?.captionStatus])
  if (!vod) return null

  let icon: React.ReactElement | null = null
  let label: string | null = null
  let color: string | null = null
  if (vod.captionStatus === VideoOnDemandCaptionStatus.Success) {
    icon = <Check />
    label = 'Captioned'
    color = '@green'
  } else if (vod.captionStatus === VideoOnDemandCaptionStatus.Failed) {
    icon = <WarningCircle />
    label = 'Error Captioning'
    color = '@red'
  } else if (vod.captionStatus) {
    icon = <Clock />
    label = 'Captioning...'
    color = '@blue'
  }

  const hasCaptions = !!vod.captionStatus
  const priceEstimate = Math.ceil((vod.status.duration || 0) / 60) * PRICE_PER_MINUTE
  const priceEstimateMessage = (
    <span>
      This will cost <strong>about ${priceEstimate}.</strong>
    </span>
  )
  return (
    <HSpaced>
      <Popconfirm
        title={
          <>
            {hasCaptions ? (
              <span>
                You have already ordered captions. If you order captions again, you may be
                double-billed.&nbsp;
              </span>
            ) : null}
            {priceEstimateMessage}
            <span>&nbsp;Are you sure?</span>
          </>
        }
        okButtonProps={hasCaptions ? { danger: true } : undefined}
        okText={hasCaptions ? 'Yes, order captions again' : 'Order Captions'}
        cancelText={'No, go back'}
        onConfirm={async () => {
          await generate({
            variables: {
              input: {
                id: vodId,
              },
            },
          })
        }}
      >
        <Button loading={loading} icon={<AddToCart />} children={'Order Captions'} />
      </Popconfirm>
      <Space />
      {icon && label && color ? (
        <Body color={color}>
          {icon} {label}
        </Body>
      ) : null}
    </HSpaced>
  )
}
