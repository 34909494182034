import React from 'react'

import {
  SelectedSidebarTabProvider,
  SelectedSidebarTabProviderProps,
} from './SelectedSidebarTabContext'

export interface SidebarTab {
  title: string
  weight: number
  children: React.ReactElement
  icon: React.ReactElement
}
export interface SidebarContextValue {
  tabs: SidebarTab[]
  addTab: (tab: SidebarTab) => void
  removeTab: (title: string) => void
}
export const SidebarContext = React.createContext<SidebarContextValue | undefined>(undefined)

export interface SidebarProviderProps extends SelectedSidebarTabProviderProps {
  children: React.ReactNode
}
export function SidebarProvider({ children, ...rest }: SidebarProviderProps) {
  const [tabs, setTabs] = React.useState<SidebarTab[]>([])
  return (
    <SidebarContext.Provider
      value={React.useMemo(
        () => ({
          tabs,
          addTab(tab) {
            setTabs(tabs => {
              let hadExisting = false
              const newTabs = tabs.map(existingTab => {
                if (existingTab.title === tab.title) {
                  hadExisting = true
                  return tab
                }
                return existingTab
              })
              return hadExisting ? newTabs : [...newTabs, tab]
            })
          },
          removeTab(title) {
            setTabs(tabs => tabs.filter(tab => tab.title !== title))
          },
        }),
        [tabs, setTabs],
      )}
    >
      <SelectedSidebarTabProvider children={children} {...rest} />
    </SidebarContext.Provider>
  )
}

export function useSidebarContext(): SidebarContextValue | undefined
export function useSidebarContext(require: false): SidebarContextValue | undefined
export function useSidebarContext(require: true): SidebarContextValue
export function useSidebarContext(require?: boolean): SidebarContextValue | undefined {
  const context = React.useContext(SidebarContext)
  if (!context && require) {
    throw new Error('SidebarContext is required, yet not provided.')
  }
  return context
}
