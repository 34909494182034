import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'

export const StatusSelect = React.forwardRef<any, SelectProps<any>>((props, ref) => (
  <Select {...props} ref={ref}>
    <Select.Option value={'ACTIVE'}>Active</Select.Option>
    <Select.Option value={'INACTIVE'}>Inactive</Select.Option>
  </Select>
))
