import { ClockCircleOutlined } from '@ant-design/icons'
import { Feature } from '@thesisedu/feature'
import { GradingModalReportItemsHook, ReportItemProps } from '@thesisedu/feature-assignments-react'
import { SegmentTimeOnTaskReport } from '@thesisedu/feature-courses-react'
import React from 'react'

import { isSegmentConfiguration } from '../helpers'

export function TimeOnTaskReport({ assignment }: ReportItemProps) {
  const config = assignment.rawConfiguration
  if (config && isSegmentConfiguration(config)) {
    return <SegmentTimeOnTaskReport segmentId={config.segmentId} />
  } else {
    return null
  }
}

export default function addReportItems(feature: Feature) {
  feature.hookManager.registerMutateHook<GradingModalReportItemsHook>(
    'feature-assignments-react:grading-modal-report-items',
    (items, { props }) => {
      const config = props.assignment.rawConfiguration
      if (config && isSegmentConfiguration(config)) {
        return [
          ...items,
          {
            key: 'time-on-task',
            label: 'Time on Task',
            icon: <ClockCircleOutlined />,
            component: TimeOnTaskReport,
          },
        ]
      } else return items
    },
  )
}
