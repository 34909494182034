import { AssignInput, AssignmentStudentAssignmentMode } from '../schema'

export function _getAssignedClassIds(
  assignmentMode: AssignmentStudentAssignmentMode,
  assign?: AssignInput,
): string[] | undefined {
  if (assignmentMode === AssignmentStudentAssignmentMode.AllStudents) {
    return assign?.assignedClasses?.filter(Boolean)
  } else if (assignmentMode === AssignmentStudentAssignmentMode.SpecificStudents) {
    return [...new Set(assign?.assignedStudents?.map(as => as.classId))]
  }
}
