import { LoadingOutlined } from '@ant-design/icons'
import { styled, ErrorIndicator, Modal } from '@thesisedu/web'
import React from 'react'
import { useDebounce } from 'use-debounce'

import { SegmentDaysContextProvider } from '../contexts/DaysContext'
import { SegmentContextProvider } from '../contexts/SegmentContext'
import { SegmentNavigationProvider } from '../contexts/SegmentNavigationContext'
import { CourseViewerSegment } from '../course_viewer/CourseViewerSegment'
import { CourseViewerSegmentHeader } from '../course_viewer/CourseViewerSegmentHeader'
import { SegmentCompletionHandlerContextProvider } from '../course_viewer/completion/useSegmentCompletion'
import { BuiltSegmentFragment, useBuiltSegmentQuery } from '../schema'

export interface SegmentPreviewProps {
  segmentDatabaseId?: string
  onClose?: () => void
  actions?: (segment: BuiltSegmentFragment) => React.ReactElement | undefined
  isModal?: boolean
}
export const SegmentPreview: React.FC<React.PropsWithChildren<SegmentPreviewProps>> = ({
  segmentDatabaseId: _segmentDatabaseId,
  onClose,
  actions,
  isModal = true,
}) => {
  // Debounce visible so we don't clear the contents while the modal is closing.
  const [segmentDatabaseId] = useDebounce(_segmentDatabaseId, 500, { leading: true })
  const { data, loading } = useBuiltSegmentQuery({
    variables: {
      id: segmentDatabaseId || '',
    },
    skip: !segmentDatabaseId,
  })

  let content = <ErrorIndicator />
  if (loading) {
    content = <LoadingOutlined />
  } else if (data?.node?.__typename === 'Segment' && data.node.built) {
    let segmentContent = (
      <SegmentNavigationProvider segment={data.node.built} isPreview>
        <SegmentCompletionHandlerContextProvider contentKey={data.node.built.id}>
          <>
            <CourseViewerSegmentHeader
              segment={data.node.built}
              hideParents
              actions={actions ? actions(data.node.built) || <span /> : <span />}
              isPreview
            />
            <CourseViewerSegment segment={data.node.built} />
          </>
        </SegmentCompletionHandlerContextProvider>
      </SegmentNavigationProvider>
    )
    if (data.node.built?.childSegments?.length) {
      segmentContent = (
        <SegmentContextProvider courseConfiguration={{ segments: data.node.built.childSegments }}>
          <SegmentDaysContextProvider
            courseConfiguration={{ segments: data.node.built.childSegments }}
          >
            {segmentContent}
          </SegmentDaysContextProvider>
        </SegmentContextProvider>
      )
    }
    content = (
      <ContentWrapper>
        <div>{segmentContent}</div>
      </ContentWrapper>
    )
  }

  if (isModal) {
    return (
      <Modal
        width={'90%'}
        style={{ top: '5vh', maxWidth: 1024 }}
        bodyStyle={{ height: '90vh', minHeight: 400, padding: 0 }}
        visible={!!_segmentDatabaseId}
        onCancel={onClose}
        children={content}
        destroyOnClose
      />
    )
  } else {
    return content
  }
}

const ContentWrapper = styled.div`
  border-radius: ${props => props.theme['@border-radius-large']};
  overflow: hidden;
  display: flex;
  max-height: 100%;
  > div {
    overflow-y: auto;
    width: 100%;
    // Undo the negative margins from inside the segment components.
    padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-m']}
      ${props => props.theme['@size-l']} ${props => props.theme['@size-l']};
  }
`
