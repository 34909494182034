import { useFloatElements } from './elements'
import { useFloatSettings } from './settings'
import { useResizeFloatNode } from './useResizeFloatNode'

export interface FloatPluginProps {
  anchorElement?: HTMLElement
}
export function FloatPlugin({ anchorElement = document.body }: FloatPluginProps) {
  useFloatElements()
  useFloatSettings()

  return useResizeFloatNode(anchorElement)
}
