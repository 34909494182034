import { removeEdge, onMutationError } from '@thesisedu/feature-apollo-react'

import { UPDATE_GRADE_QUERIES } from './constants'
import { useResetAssignmentSubmissionsMutation as useMutation } from '../schema'

export const useResetAssignmentSubmissionsMutation: typeof useMutation = opts => {
  const [mutate, rest] = useMutation({
    onError: onMutationError('There was an error resetting the submissions.'),
    refetchQueries: UPDATE_GRADE_QUERIES,
    ...opts,
  })

  return [
    opts =>
      mutate({
        ...opts,
        update: (proxy, result, options) => {
          if (opts?.update) {
            opts.update(proxy, result, options)
          }
          if (opts?.variables) {
            const deletedIds = result.data?.resetAssignmentSubmissions.submissionIds || []
            for (const deletedId of deletedIds) {
              removeEdge(proxy, {
                removeId: deletedId,
                field: 'submissions',
                id: `Assignment:${opts.variables.input.id}`,
              })
              removeEdge(proxy, {
                removeId: deletedId,
                field: 'submissions',
                id: `Student:${opts.variables.input.studentId}`,
              })
            }
          }
        },
      }),
    rest,
  ]
}
