import { Block, BodyLarge, styledContainer } from '@thesisedu/react'
import React from 'react'

import { UploadStatusIcon } from './UploadStatusIcon'
import { STATUS_LABEL_PROPS } from './UploadStatusLabel'
import { PartialUpload } from './types'

const OuterContainer = styledContainer`
  position: relative;
  padding-top: 60%;
  width: 100%;
`
const Container = styledContainer`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContainerInner = styledContainer`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export interface LargeUploadProps {
  upload: PartialUpload
  background?: string
}
export const LargeUpload: React.FC<React.PropsWithChildren<LargeUploadProps>> = ({
  upload,
  background,
}) => {
  return (
    <OuterContainer>
      <Container>
        <ContainerInner>
          <Block marginBottom={'@size-s'}>
            <UploadStatusIcon upload={upload} background={background} />
          </Block>
          <BodyLarge {...STATUS_LABEL_PROPS(upload)} />
        </ContainerInner>
      </Container>
    </OuterContainer>
  )
}
