import { Button, HSpaced } from '@thesisedu/ui'
import { DeleteCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { SelectButtonChildProps } from './types'

export function DefaultSelectButtonChild({
  hasValue,
  disabled,
  label,
  hasAddButton,
  onClear,
  onClick,
}: SelectButtonChildProps) {
  const buttonType = hasValue ? 'primary' : undefined
  return (
    <HSpaced
      style={{ display: 'inline-flex', width: 'max-content' }}
      right={hasAddButton ? 'xs' : undefined}
    >
      <Button
        variant={buttonType}
        disabled={disabled}
        onPress={onClick}
        style={{ whiteSpace: 'nowrap', minWidth: 0 }}
      >
        {label}
      </Button>
      {hasValue ? (
        <Button
          variant={buttonType}
          onPress={onClear}
          icon={<DeleteCircle />}
          disabled={disabled}
        />
      ) : null}
    </HSpaced>
  )
}
