import { ArrowDownOutlined, LinkOutlined } from '@ant-design/icons'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { Modal, ModalProps, Body, BodyLarge, styled } from '@thesisedu/web'
import { Select, Tooltip, Menu, Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import copy from 'copy-to-clipboard'
import React from 'react'

import { useClasses } from './ClassContext'

const LinkContainer = styled(BodyLarge)`
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s linear;
  color: ${props => props.theme[props.disabled ? '@text-color-secondary' : '@primary-color']};
  margin: ${props => props.theme['@size-m']} 0 !important;
`

export interface GenerateLinkModalProps extends Partial<ModalProps> {
  universalPath: string
  classId?: string
  promptClassId?: boolean
  requireClassId?: boolean
  description?: string
}
export function GenerateLinkModal({
  universalPath,
  classId,
  promptClassId,
  requireClassId,
  description,
  ...modalProps
}: GenerateLinkModalProps) {
  const [fullPath, setFullPath] = React.useState(universalPath)
  const [selectedClassId, setSelectedClassId] = React.useState(classId)
  const [tooltipTitle, setTooltipTitle] = React.useState('Click to copy')
  const { classes } = useClasses()
  const root = useFeatureRoot()!
  React.useEffect(() => {
    if (selectedClassId) {
      setFullPath(`${universalPath}?classId=${selectedClassId}`)
    } else {
      setFullPath(universalPath)
    }
  }, [universalPath, selectedClassId])
  React.useEffect(() => {
    if (tooltipTitle === 'Copied!') {
      const handle = setTimeout(() => {
        setTooltipTitle('Click to copy')
      }, 1000)
      return () => clearTimeout(handle)
    }
  }, [tooltipTitle])
  return (
    <Modal
      title={'Generate Shareable Link'}
      footer={null}
      width={700}
      style={{ maxWidth: '90vw' }}
      zIndex={1051}
      {...modalProps}
    >
      {!requireClassId || selectedClassId ? (
        <>
          <Body isBlock>
            {description ||
              `Click on the link below to copy your link. You can then paste this link to students,
            other teachers, or any other ${root.appOptions.name} users that have access to this
            content.
            `}
          </Body>
          <Tooltip title={tooltipTitle}>
            <LinkContainer
              onClick={() => {
                copy(
                  `${window.location.origin}/${
                    fullPath.startsWith('/') ? fullPath.slice(1) : fullPath
                  }`,
                )
                setTooltipTitle('Copied!')
              }}
            >
              {`${window.location.origin}/${
                fullPath.startsWith('/') ? fullPath.slice(1) : fullPath
              }`}
            </LinkContainer>
          </Tooltip>
        </>
      ) : (
        <>
          <Body isBlock>Before generating your link, select a class from the list below.</Body>
          <LinkContainer disabled>
            Select a class first <ArrowDownOutlined />
          </LinkContainer>
        </>
      )}
      {promptClassId ? (
        <Select<string>
          value={selectedClassId}
          onChange={e => setSelectedClassId(e)}
          options={classes.map(cls => ({ key: cls.id, label: cls.name, value: cls.id }))}
          placeholder={'Select a class'}
          style={{ width: '100%' }}
          dropdownStyle={{ zIndex: 1052 }}
        />
      ) : null}
    </Modal>
  )
}

export type GenerateLinkActProps = Omit<GenerateLinkModalProps, 'visible' | 'onCancel'>

export type GenerateLinkButtonProps = Partial<ButtonProps> &
  Pick<GenerateLinkActProps, 'universalPath' | 'classId' | 'promptClassId' | 'requireClassId'> & {
    otherActProps?: Partial<GenerateLinkActProps>
  }
export function GenerateLinkButton({
  otherActProps,
  universalPath,
  classId,
  promptClassId,
  requireClassId,
  ...buttonProps
}: GenerateLinkButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <GenerateLinkModal
        universalPath={universalPath}
        classId={classId}
        promptClassId={promptClassId}
        requireClassId={requireClassId}
        {...otherActProps}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <Button
        icon={<LinkOutlined />}
        children={'Get Link'}
        {...buttonProps}
        onClick={() => setVisible(true)}
      />
    </>
  )
}

export type GenerateLinkMenuItemProps = Partial<MenuItemProps> &
  Pick<GenerateLinkActProps, 'universalPath' | 'classId' | 'promptClassId' | 'requireClassId'> & {
    otherActProps?: Partial<GenerateLinkActProps>
  }
export function GenerateLinkMenuItem({
  otherActProps,
  universalPath,
  classId,
  promptClassId,
  requireClassId,
  ...menuItemProps
}: GenerateLinkMenuItemProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <GenerateLinkModal
        universalPath={universalPath}
        classId={classId}
        promptClassId={promptClassId}
        requireClassId={requireClassId}
        {...otherActProps}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
      <Menu.Item
        key={'getLink'}
        {...menuItemProps}
        onClick={() => {
          setVisible(true)
        }}
      >
        <LinkOutlined />
        <span>Get Link</span>
      </Menu.Item>
    </>
  )
}
