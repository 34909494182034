import { LoadingOutlined } from '@ant-design/icons'
import { Block, BodyLarge } from '@thesisedu/react'
import React from 'react'

export function ReportLoadingIndicator() {
  return (
    <Block marginTop={'@size-l'} marginBottom={'@size-l'} style={{ textAlign: 'center' }}>
      <BodyLarge color={'@text-color-secondary'}>
        <LoadingOutlined />
        &nbsp;&nbsp; Fetching data...
      </BodyLarge>
    </Block>
  )
}
