import { styled, StyledComponent, Tooltip } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

export interface TopRightIconProps {
  color?: string
  icon: React.ReactElement
  tooltip?: string
}
export function TopRightIcon({ color = '@gray-7', icon, tooltip }: TopRightIconProps) {
  const content = <TopRightIconContainer color={color}>{icon}</TopRightIconContainer>
  if (tooltip) {
    return <Tooltip title={tooltip} children={content} />
  } else return content
}

export const TopLeftIconContainer: StyledComponent<'div'> = styled.div`
  border-top-left-radius: ${props => props.theme['@border-radius-base']};
  border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => transparentize(0.25, props.theme['@gray-7'])};
  color: ${props => props.theme['@gray-0']};
  font-size: calc(${props => props.theme['@size-s']} * 0.9);
  padding: 0.75em;
  position: absolute;
  top: 0;
  left: 0;
  > * {
    display: block;
  }
`
const TopRightIconContainer = styled.div<{ color: string }>`
  border-top-right-radius: ${props => props.theme['@border-radius-base']};
  border-bottom-left-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => transparentize(0.25, props.theme[props.color])};
  color: ${props => props.theme['@gray-0']};
  font-size: calc(${props => props.theme['@size-s']} * 0.9);
  padding: 0.75em;
  position: absolute;
  top: 0;
  right: 0;
  > * {
    display: block;
  }
`
