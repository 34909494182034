import { Format } from '@thesisedu/feature-utils'
import { styled, s, Text, Button, Block } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { PRESET_MIN_WIDTH_PX } from './CriterionPresetEditor'
import { useRubricGraderContext } from './RubricGraderContext'
import { RubricGraderPresetComments } from './RubricGraderPresetComments'
import { AssignmentRubricPresetInput } from '../schema'

function normalizeNumber(number: number | null | undefined) {
  return number != null ? parseFloat(number.toFixed(2)) : undefined
}

export interface RubricGraderPresetProps {
  preset: AssignmentRubricPresetInput
  categoryId: string
  totalPoints: number
}
export function RubricGraderPreset({ preset, categoryId, totalPoints }: RubricGraderPresetProps) {
  const { disabled, value, onChange } = useRubricGraderContext(true)
  const presetGrade = preset.points / totalPoints
  const isSelected = normalizeNumber(value?.grades?.[categoryId]) === normalizeNumber(presetGrade)
  return (
    <Container
      data-testid={`RubricGraderPreset ${preset.name}`}
      data-selected={isSelected}
      disabled={disabled}
      onPress={() => {
        const { [categoryId]: existing, ...grades } = value?.grades ?? {}
        onChange({
          ...value,
          grades: isSelected
            ? grades
            : {
                ...grades,
                [categoryId]: presetGrade,
              },
        })
      }}
    >
      <AnimatePresence initial={false}>
        {isSelected ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, type: 'tween' }}
          >
            <CheckedContainer>
              <Check />
            </CheckedContainer>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <Block paddingRight={'m'}>
        <Text bottom level={'s'} weight={'bold'} color={isSelected ? 'primary' : undefined}>
          {preset.name}
        </Text>
      </Block>
      {preset.description ? (
        <Text level={'xs'} color={isSelected ? 'primary' : undefined}>
          {preset.description}
        </Text>
      ) : null}
      {isSelected ? <RubricGraderPresetComments categoryId={categoryId} /> : null}
      <Block top paddingTop={'xs'}>
        <Text level={'xs'} color={isSelected ? 'primary.secondary' : 'secondary'}>
          {preset.points} / {Format.plural('point', totalPoints)}
        </Text>
      </Block>
    </Container>
  )
}

const Container = styled(Button)`
  flex: 1;
  min-width: ${PRESET_MIN_WIDTH_PX}px;
  padding: ${s.size('xs')} !important;
  cursor: pointer;
  transition: background 0.1s linear;
  background: transparent !important;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0 !important;
  opacity: 1 !important;
  &:not(:last-child) {
    border-right: solid 1px ${s.color('subtleBorder')};
  }
  &:active:not(:disabled) {
    background: ${s.color('element')} !important;
    transform: none;
  }
  &[data-selected='true'] {
    background: ${s.color('primary.subtle')} !important;
  }
`
const CheckedContainer = styled.div`
  position: absolute;
  top: ${s.size('xs')};
  right: ${s.size('xs')};
  background: ${s.color('primary')};
  border-radius: ${s.var('radii.1')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: ${s.size('m')};
  height: ${s.size('m')};
  font-size: ${s.size('s')};
`
