import { debug } from '../../../log'

export interface DurationResult {
  duration: number | null
  hasVideoTrack: boolean
}
export async function getFileDurationInSeconds(file: File): Promise<DurationResult> {
  debug('getting file duration')
  const video = document.createElement('video')
  video.src = URL.createObjectURL(file)
  await new Promise<void>(resolve => {
    video.onloadedmetadata = () => {
      debug('loaded metadata')
      resolve()
    }
    video.onerror = err => {
      debug('error loading file %O', err)
      resolve()
    }
  })
  const duration = isNaN(video.duration) ? null : video.duration
  const hasVideoTrack = video.videoWidth > 0 && video.videoHeight > 0
  debug('duration is %d', duration)
  debug('hasVideoTrack %O', hasVideoTrack)
  URL.revokeObjectURL(video.src)
  video.remove()
  return { duration, hasVideoTrack }
}
