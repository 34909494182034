import { orderBy } from 'lodash'
import React from 'react'

import { PlaybackFooterLocation, usePlaybackFooterContext } from './PlaybackFooterContext'

export function usePlaybackFooterItems(location: PlaybackFooterLocation): React.ReactElement[] {
  const { items } = usePlaybackFooterContext(true)
  return orderBy(items, 'weight', 'asc')
    .filter(item => item.location === location)
    .map(item => React.cloneElement(item.children, { key: item.identifier }))
}
