import { NewTeacher, NewStudent, ListClassesContentHook } from '@thesisedu/feature-classes-react'
import { Dropdown, Button, Tooltip } from '@thesisedu/ui'
import { AddUser, User } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { ClassDraggable } from '../classes/ClassDraggable'
import { CourseBasicClassManager, CourseClassManager } from '../classes/CourseClassManager'

function StudentsItem({ classId }: { classId: string }) {
  const navigate = useNavigate()
  return (
    <Dropdown.Item onClick={() => navigate(`/teacher/classes/${classId}/students`)} icon={<User />}>
      Students
    </Dropdown.Item>
  )
}

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<ListClassesContentHook>(
    'classes-react:list-classes',
    (content, { group }) => {
      if (group === 'TEACHER') {
        return [
          <CourseClassManager
            key={'courses'}
            emptyContent={<NewTeacher />}
            draggableProps={{
              settingsLink: id => `/teacher/classes/${id}/settings`,
            }}
            renderDraggable={props => (
              <ClassDraggable
                {...props}
                footerItems={
                  <React.Fragment key={'invite'}>
                    {(props.class as any).canInvite ? (
                      <Tooltip title={'Invite Students'}>
                        <Button
                          size={'large'}
                          variant={'ghost'}
                          icon={<AddUser />}
                          onPress={props.onInvite}
                        />
                      </Tooltip>
                    ) : null}
                  </React.Fragment>
                }
                overlayItems={[<StudentsItem key={'students'} classId={props.class.id} />]}
              />
            )}
          />,
        ]
      } else if (group === 'STUDENT') {
        return [
          <CourseBasicClassManager
            key={'classes'}
            emptyContent={<NewStudent />}
            renderDraggable={props => <ClassDraggable {...props} readOnly />}
          />,
        ]
      } else {
        return content
      }
    },
  )
}
