import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { useUpdateVideoOnDemandMutation as useMutation, VideoOnDemandFragmentDoc } from '../schema'

export const useUpdateVideoOnDemandMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that video.'),
    update: updateMutation({
      fragment: VideoOnDemandFragmentDoc,
      fragmentName: 'VideoOnDemand',
      path: 'updateVideoOnDemand.videoOnDemand',
    }),
    ...opts,
  })
