import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import Select, * as Select$ from '../Select'

function _FormSelect<TFieldValues extends FieldValues, FieldName extends FieldPath<TFieldValues>>(
  { children, ...props }: ConnectedFormFieldProps<Select$.SelectProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  return (
    <FormFieldConnector {...props} changePropName={'onValueChange'}>
      <Select children={children} ref={ref} />
    </FormFieldConnector>
  )
}
export const FormSelect = React.forwardRef(_FormSelect)
