import React from 'react'

import { StudentSelectorOverlay, StudentSelectorOverlayProps } from './StudentSelectorOverlay'
import { error } from '../log'
import { ClassStudentPair } from '../types'

type CancelResult = 'cancelled'
interface SelectedResult {
  students: ClassStudentPair[]
}
type SelectStudentsResult = CancelResult | SelectedResult
type ResultPromise = Promise<SelectStudentsResult>
type ResultCallback = (result: SelectStudentsResult) => Promise<void>

type OverlayProps = Partial<
  Omit<StudentSelectorOverlayProps, 'onSave' | 'onCancel' | 'visible' | 'onChange' | 'value'>
>
export interface SelectStudentOptions extends OverlayProps {
  initialValue?: ClassStudentPair[]
  /** Use this to perform promise-related actions and show a loading indicator inside the selector. */
  callback?: (result: SelectedResult) => Promise<void>
}

export interface StudentSelectorContextValue {
  selectStudents: (options: SelectStudentOptions) => ResultPromise
}
export const StudentSelectorContext = React.createContext<StudentSelectorContextValue | undefined>(
  undefined,
)

export interface StudentSelectorProviderProps
  extends Omit<
    StudentSelectorOverlayProps,
    'visible' | 'onSave' | 'onCancel' | 'value' | 'onChange' | 'saveText'
  > {}
export function StudentSelectorProvider({
  children,
  ...rest
}: React.PropsWithChildren<StudentSelectorProviderProps>) {
  const [currentOpts, setCurrentOpts] = React.useState<
    (OverlayProps & { callback: ResultCallback }) | undefined
  >(undefined)
  const [selectedStudentPairs, setSelectedStudentPairs] = React.useState<ClassStudentPair[]>([])
  return (
    <>
      <StudentSelectorOverlay
        {...rest}
        {...currentOpts}
        visible={!!currentOpts}
        onSave={async () => {
          if (currentOpts?.callback) {
            await currentOpts.callback({ students: selectedStudentPairs })
          }
          setCurrentOpts(undefined)
        }}
        onCancel={() => {
          if (currentOpts?.callback) {
            currentOpts.callback('cancelled')
          }
          setCurrentOpts(undefined)
        }}
        value={selectedStudentPairs}
        onChange={setSelectedStudentPairs}
      />
      <StudentSelectorContext.Provider
        value={{
          selectStudents: ({ initialValue, callback, ...restOpts }) => {
            return new Promise<SelectStudentsResult>((resolve, reject) => {
              setSelectedStudentPairs(initialValue || [])
              setCurrentOpts({
                ...restOpts,
                callback: async result => {
                  try {
                    if (callback && result !== 'cancelled') {
                      await callback(result)
                    }
                    resolve(result)
                  } catch (err: any) {
                    error('error occurred when selecting students')
                    error(err)
                    reject(err)
                  }
                },
              })
            })
          },
        }}
        children={children}
      />
    </>
  )
}

export function useStudentSelectorContext(): StudentSelectorContextValue | undefined
export function useStudentSelectorContext(require: false): StudentSelectorContextValue | undefined
export function useStudentSelectorContext(require: true): StudentSelectorContextValue
export function useStudentSelectorContext(
  require?: boolean,
): StudentSelectorContextValue | undefined {
  const context = React.useContext(StudentSelectorContext)
  if (!context && require) {
    throw new Error('StudentSelectorContext is required, yet not provided.')
  }
  return context
}
