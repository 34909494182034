import { gql, ApolloClient, useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import { useResource } from '@thesisedu/feature-react'
import { groupBy, pick } from 'lodash'
import React from 'react'

import * as Input from './input'
import { getAllMetricSummaries, RunReportQuery, RunReportQueryVariables } from './types'
import { debug } from '../../log'
import {
  ReportDimensionResource,
  ReportMetricResource,
  ReportMetricSummaryResource,
  RunReportOpts,
} from '../types'

export function getNormalizedMetricResourcesArr(
  metricResources: ReportMetricResource[],
  metrics: RunReportOpts['metrics'],
) {
  return metrics.length ? Input.getSelectedResources(metricResources, metrics) : metricResources
}

export function getNormalizedMetricResources(
  metricResources: ReportMetricResource[],
  metrics: RunReportOpts['metrics'],
): Record<string, ReportMetricResource[]> {
  const selectedResources = getNormalizedMetricResourcesArr(metricResources, metrics)
  return groupBy(selectedResources, 'summarization')
}

interface InternalRunReportOpts {
  dimensionResources: ReportDimensionResource<object, object>[]
  metricResources: ReportMetricResource[]
  metricSummaryResources: ReportMetricSummaryResource[]
  client: ApolloClient<any>
}
export async function runReportQuery(
  { metrics, dimensions, filters }: RunReportOpts,
  { dimensionResources, metricResources, metricSummaryResources, client }: InternalRunReportOpts,
) {
  const selectedMetricResources = getNormalizedMetricResources(metricResources, metrics)
  const allSelectedMetricResources = getNormalizedMetricResourcesArr(metricResources, metrics)

  debug('generating query document for report metrics %O and dimensions %O', metrics, dimensions)
  const selectedDimensionResources = Input.getSelectedResources(dimensionResources, dimensions)
  const summary = Input.getSummaryInfo(selectedDimensionResources, { metrics, dimensions })
  const reportDimensionResult = Input.getDimensionFragmentNames(selectedDimensionResources)
    .map(({ key, name }) => `${key} { ...${name} }`)
    .join('\n')
  const reportMetricSummaries = Object.keys(selectedMetricResources)
    .map(summarization => {
      return `
      ${summarization} {
        ${[...new Set(selectedMetricResources[summarization].map(r => r.metricKey))].join('\n')}
      }
    `
    })
    .join('\n')
  const allMetricSummaries = getAllMetricSummaries(
    allSelectedMetricResources,
    metricSummaryResources,
  )
  const summaries = Input.getDimensionSummaries(
    selectedDimensionResources,
    reportDimensionResult,
    allMetricSummaries,
  )
  const fragments = Input.getDimensionFragments(selectedDimensionResources)
  const resultSelection = Input.getResultSelection(
    summary,
    reportDimensionResult,
    reportMetricSummaries,
    allMetricSummaries,
  )

  // You CANNOT use fragments in the query below that change; the selections will be
  // cached by Apollo and GraphQL as fragments are not supposed to change.
  const queryString = `
    query runReportQuery($input: RunReportInput!) {
      runReport(input: $input) {
        ${resultSelection || ''}
        ${summaries || ''}
        allMetrics {
          ${getAllMetricSummaries(allSelectedMetricResources, metricSummaryResources)}
        }
        dimensionSummaries {
          dimensions {
            ${reportDimensionResult}
          }
          metrics {
            ${getAllMetricSummaries(allSelectedMetricResources, metricSummaryResources)}
          }
        }
      }
    }

    ${fragments}
  `
  debug('resulting query')
  debug(queryString)
  const queryDocument = gql(queryString)

  return client.query<RunReportQuery, RunReportQueryVariables>({
    query: queryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        metrics: [
          ...new Set(
            Object.keys(selectedMetricResources).reduce<string[]>((acc, key) => {
              return selectedMetricResources[key].reduce((acc, resource) => {
                return [...acc, resource.metricKey]
              }, acc)
            }, []),
          ),
        ].map(key => ({ [key]: {} })),
        dimensions: dimensions.map(dim =>
          Input.getDimensionInputFromSelected(dimensionResources, dim),
        ),
        filters,
      },
    },
  })
}

export function useRunReportQuery(report: RunReportOpts) {
  const dimensionResources = useResource<ReportDimensionResource<object, object>>('ReportDimension')
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  const metricSummaryResources = useResource<ReportMetricSummaryResource>('ReportMetricSummary')
  const client = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const [reportData, setReportData] = React.useState<RunReportQuery['runReport'] | undefined>(
    undefined,
  )
  const reportString = JSON.stringify(pick(report, ['dimensions', 'metrics', 'filters']))
  const [ranReport, setRanReport] = React.useState<string>(reportString)
  const [error, setError] = React.useState<any | undefined>(undefined)

  React.useEffect(() => {
    setLoading(true)
    setError(undefined)
    setReportData(undefined)
    setRanReport(reportString)
    runReportQuery(report, { dimensionResources, metricResources, metricSummaryResources, client })
      .then(result => {
        if (result.error || result.errors) {
          setError(result.error || result.errors)
        } else if (result.data) {
          const newReportData = result.data.runReport
          setReportData(newReportData)
        }
      })
      .catch(err => {
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [reportString])

  /**
   * If we use just the regular loading state from above, the report result grid will
   * re-render with the new options and the old data for a split second before the effect
   * is run and setLoading(true) is called. This will cause errors as the reporting
   * components require the structure of the result data and the passed opts be in sync.
   *
   * To get around this, we simply force the loading state to true for the 1 render
   * cycle before setLoading(true) is called, as long as the previously-ran report options
   * don't match the new report options.
   */
  const reportsAreSame = ranReport === reportString
  return { loading: loading || !reportsAreSame, reportData, error }
}
