import { Form } from '@thesisedu/ui'
import React from 'react'

import { AssignmentSettingsProps } from './AssignmentSettings'
import { AssignmentSettingsOptions } from './types'
import { AssignmentEditorField } from '../edit/AssignmentEditorField'
import { AssignmentSettingsRubricField } from '../rubric/AssignmentSettingsRubricField'
import { AssignmentGradingMode } from '../schema'

export interface AssignmentSettingsContentProps extends AssignmentSettingsProps {
  SettingsContent?: AssignmentSettingsOptions['SettingsContent']
}
export function AssignmentSettingsContent({
  SettingsContent,
  ...rest
}: AssignmentSettingsContentProps) {
  const { assignment } = rest
  const { form } = Form.useFormContext(true)
  const gradingMode = form.watch('gradingMode')
  if (SettingsContent) {
    return <SettingsContent {...rest} />
  } else if (gradingMode === AssignmentGradingMode.Questions && !assignment.assignmentTemplateId) {
    return (
      <Form.Field name={'configuration'} aria-label={'Assignment'}>
        <AssignmentEditorField />
      </Form.Field>
    )
  } else if (gradingMode === AssignmentGradingMode.Rubric) {
    return (
      <Form.Field name={'rubric'} aria-label={'Custom Rubric'}>
        <AssignmentSettingsRubricField />
      </Form.Field>
    )
  } else return null
}
