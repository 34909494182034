import { FileField } from '@thesisedu/feature-attachments-react'
import { VSpaced, Form } from '@thesisedu/ui'
import React from 'react'

export function PDFSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <Form.Field name={'file'} label={'PDF File'}>
        <FileField uploadProps={{ accept: 'application/pdf' }} />
      </Form.Field>
      <Form.NumberField
        name={'startPage'}
        label={'Start Page'}
        description={'Defaults to the first page.'}
      />
      <Form.NumberField
        name={'pageCount'}
        label={'Page Count'}
        description={'How many pages do you want to display? Defaults to all pages.'}
      />
    </VSpaced>
  )
}
