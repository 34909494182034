import { QuestionTypeResource } from '@thesisedu/feature-assignments-react'
import { ReactFeature } from '@thesisedu/feature-react'

import { GraphicDragDropConfig, GraphicDragDropValue } from './GraphicDragDrop/types'

export function addQuestionTypes(feature: ReactFeature) {
  feature.resources.addResource<QuestionTypeResource<GraphicDragDropConfig>>({
    type: 'QuestionType',
    identifier: 'GraphicDragDrop',
    label: 'Graphic Drag / Drop',
    group: 'Drag and Drop',
    defaults: {
      name: '',
      config: {},
    },
    renderAnswerView: require('./GraphicDragDrop/GraphicDragDropAnswer').GraphicDragDropAnswer,
    renderContent: require('./GraphicDragDrop/GraphicDragDropContent').GraphicDragDropContent,
    renderEdit: require('./GraphicDragDrop/GraphicDragDropEdit').GraphicDragDropEdit,
    isQuestionComplete: (question, value: GraphicDragDropValue) => {
      const uniqueDraggables = question.config?.dragDropPairs?.reduce<Set<string>>((acc, pair) => {
        acc.add(pair.draggableId)
        return acc
      }, new Set<string>())
      const answerLength = Object.keys(value || {}).filter(k => value?.[k]).length
      // If we have the answer with us, check more accurately. Otherwise, assume only one
      // draggable must be used (since we won't be in reveal answer mode anyway).
      return uniqueDraggables?.size ? answerLength >= uniqueDraggables.size : answerLength > 0
    },
  })
}
