import * as Core from '@thesisedu/feature-assignments-core'
import { useFreshRef } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Random } from '@thesisedu/feature-utils'
import { SerializedWidget, WeightedWidget } from '@thesisedu/feature-widgets-core'
import { BlockEditor, Legacy } from '@thesisedu/feature-widgets-react'
import { Block } from '@thesisedu/react'
import { cloneDeep } from 'lodash'
import React from 'react'

import { AddQuestionButton } from './AddQuestionButton'
import { QuestionContainerContext } from './QuestionContainerContext'
import { WrapEditContent } from './WrapEditContent'
import { getCombinedItems } from './getCombinedItems'
import { useOnAdd } from './useOnAdd'
import { useQuestionListContext } from '../contexts/QuestionListContext'
import { QuestionWidgetProvider } from '../resources/Widgets/Question/QuestionWidgetContext'
import { QuestionWidget } from '../resources/Widgets/Question/common'

export interface QuestionContainerProps {
  questions: Core.Question[]
  widgets?: Legacy.WeightedAnyEditorValue
  id: string
  onChange?: (questions: Core.Question[], widgets: WeightedWidget[]) => void
}
export function QuestionContainer({ questions, widgets, id, onChange }: QuestionContainerProps) {
  const canAddWidgets = useViewerContext(false)?.group === 'ADMIN'
  const onAdd = useOnAdd({ questions, onChange, widgets })
  const sortedItems = getCombinedItems(questions, widgets)
  const existingListContext = useQuestionListContext(false)
  const questionsRef = useFreshRef(questions)
  return (
    <QuestionWidgetProvider questions={questions}>
      <QuestionContainerContext.Provider
        value={{
          onAdd,
          onChange(id, changes) {
            if (onChange) {
              const newQuestions = questionsRef.current.map(q =>
                q.id === id ? { ...q, ...changes } : q,
              )
              onChange(
                newQuestions,
                sortedItems.root.children.filter(
                  child => child.type !== 'Question',
                ) as WeightedWidget[],
              )
            }
          },
          onRemove(id) {
            if (onChange) {
              const newQuestions = questionsRef.current.filter(q => q.id !== id)
              onChange(
                newQuestions,
                sortedItems.root.children.filter(
                  child => child.type !== 'Question',
                ) as WeightedWidget[],
              )
            }
          },
          onClone(id) {
            if (onChange) {
              const newQuestions = questionsRef.current.flatMap(q =>
                q.id === id
                  ? [
                      q,
                      {
                        ...cloneDeep(q),
                        id: Random.id(),
                        weight: (q.weight || 0) + 0.01,
                      },
                    ]
                  : q,
              )
              onChange(
                newQuestions,
                sortedItems.root.children.filter(
                  child => child.type !== 'Question',
                ) as WeightedWidget[],
              )
            }
          },
        }}
      >
        <WrapEditContent questionTypes={questions.map(q => q.type)}>
          <>
            {sortedItems?.root?.children?.length ? (
              <BlockEditor
                id={`assignment-editor-${id}`}
                defaultValue={sortedItems}
                defaultValueKey={sortedItems.root.children.length.toString()}
                noAdd={!canAddWidgets}
                minHeight={100}
                onChangeDebounce={500} // TODO: If we improve performance elsewhere, we can remove this.
                onChange={
                  onChange
                    ? newCombined => {
                        const children = (newCombined.root.children || []).map((child, index) => ({
                          ...(child as SerializedWidget<string>),
                          weight: index,
                        }))
                        const newWidgets = children.filter(w => w.type !== 'Question')
                        const newQuestions = children
                          .filter(w => w.type === 'Question')
                          .map<Core.Question>(_w => {
                            const w = _w as WeightedWidget<QuestionWidget>
                            const question = questionsRef.current.find(q => q.id === w.id)
                            if (!question) throw new Error(`Cannot find question '${w.id}'`)
                            return {
                              ...question,
                              weight: w.weight,
                              id: w.id,
                            }
                          })
                        onChange(newQuestions, newWidgets)
                      }
                    : undefined
                }
              />
            ) : null}
            {existingListContext?.singleQuestionMode ? undefined : (
              <Block marginTop={'@size-m'} style={{ textAlign: 'center' }}>
                <AddQuestionButton onAdd={onAdd} />
              </Block>
            )}
          </>
        </WrapEditContent>
      </QuestionContainerContext.Provider>
    </QuestionWidgetProvider>
  )
}
