import { AssignmentFlagResource, AssignmentFlagsProps } from '@thesisedu/feature-assignments-react'
import { Form } from '@thesisedu/ui'
import React from 'react'

import { HIDE_KEYBOARD_FIELD } from '../class_configuration/hideKeyboardField'

function Field({ prefix }: AssignmentFlagsProps) {
  return (
    <Form.Checkbox
      name={[...(prefix || []), HIDE_KEYBOARD_FIELD].join('.')}
      description={
        <span>
          When this is checked, the universal pop-up keyboard will not be displayed to students{' '}
          <strong>while viewing this assignment.</strong>
        </span>
      }
      label={'Disable universal pop-up keyboard'}
    />
  )
}
const resource: AssignmentFlagResource = {
  type: 'AssignmentFlag',
  identifier: HIDE_KEYBOARD_FIELD,
  render: Field,
}
export default resource
