import { styled, s } from '@thesisedu/ui'
import React from 'react'

export interface ElementsMenuItemProps {
  selected?: boolean
  title: string
  icon: React.ReactElement
  onClick: (e: React.MouseEvent) => void
}
export function ElementsMenuItem({ selected, title, icon, onClick }: ElementsMenuItemProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (containerRef.current && selected) {
      const container = containerRef.current.parentElement
      if (container && [...(container.classList as any)].some(cls => cls.startsWith('appear-'))) {
        containerRef.current.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
        })
      }
    }
  }, [selected])
  return (
    <Container selected={selected} onClick={onClick} ref={containerRef}>
      {icon}
      <span>{title}</span>
    </Container>
  )
}

const Container = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${s.var('size.0.5')};
  font-size: ${s.var('size.0.9')};
  transition:
    background 0.1s linear,
    color 0.1s linear;
  border-radius: ${s.var('radii.1')};
  background: ${props => s.color(props.selected ? 'gray.element' : 'gray.background')};
  color: ${props => s.color(props.selected ? 'primary.text' : 'gray.text')};
  padding: ${s.var('size.0.25')} ${s.var('size.0.5')};
  cursor: pointer;
  svg {
    display: block;
  }
  &:hover {
    background: ${s.color('gray.element')};
    color: ${s.color('primary.text')};
  }
  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
export const GroupMenuItem = styled(Container)`
  pointer-events: none;
  font-size: ${s.size('font.xs')};
  color: ${s.color('secondary')};
  padding-left: calc(${s.var('size.0.5')} + ${s.var('size.0.9')} + ${s.var('size.0.5')});
  &:not(:first-child) {
    padding-top: ${s.size('s')};
  }
`
