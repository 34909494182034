import { styled, s } from '@thesisedu/ui'
import { EditorThemeClasses } from 'lexical'

import { PlaceholderContainer } from './PlaceholderContainer'
import { ReadonlyTableStyles, TableStyles } from './table'
import { MarkStyles } from '../plugins/MarkPlugin/styles'

const { css } = s
const DisabledStyles = css`
  pointer-events: none;
`
const BorderedStyle = css`
  border: 1px solid ${s.color('gray.border')};
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('s')};
  > ${PlaceholderContainer} {
    inset: ${s.size('s')};
  }
`

export const ThemeContainer = styled.div<{
  $isEditable?: boolean
  $isDisabled?: boolean
  $minHeight?: number
  $bordered?: boolean
}>`
  --lexical-indent-base-value: calc(${s.size('2')} + ${s.size('0.5')});

  font-size: var(--lexical-font-size, ${s.size('2')});
  line-height: var(--lexical-line-height, ${s.var('lineHeights.spaced')});
  position: relative;
  flex: 1;
  ${MarkStyles}
  ${TableStyles}
  min-height: ${props => (props.$isEditable ? props.$minHeight || 500 : 0)}px;
  ${props => (props.$isEditable ? '' : ReadonlyTableStyles)}
  ${props => (props.$isDisabled ? DisabledStyles : '')}
  ${props => (props.$bordered ? BorderedStyle : '')}
  .fteditor {
    &-root {
      position: relative;
      z-index: 3;
      > :first-child {
        margin-top: 0 !important;
      }
      > :last-child {
        margin-bottom: 0 !important;
      }
      ~ ${PlaceholderContainer} {
        display: none;
      }
      &.empty,
      &:empty {
        ~ ${PlaceholderContainer} {
          display: block;
        }
      }
    }
    &-list-item {
      margin: ${s.size('1')} 0;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-top: 0;
      }
    }
    &-list {
      margin-top: ${s.size('1')};
      margin-bottom: ${s.size('1')};
      li {
        margin: ${s.size('0.5')} 0;
        padding-left: ${s.size('0.5')};
        position: relative;
      }
    }
    &-nested-list,
    &-list .fteditor-list {
      margin: 0 !important;
    }
    &-unordered-list {
      list-style: none none;
      margin: ${s.size('1')} 0;
      padding-left: 0;
      > li {
        padding-left: calc(${s.size('2')} + ${s.size('0.5')});
        &:not(.fteditor-nested-list-item)::before {
          display: block;
          content: ' ';
          position: absolute;
          top: 0.75em;
          left: ${s.size('0.25')};
          width: ${s.size('0.5')};
          height: ${s.size('0.5')};
          background: ${s.color('gray.border')};
          border-radius: 50%;
        }
        > p,
        > div {
          margin: ${s.size('0.5')} 0;
        }
      }
    }
    &-ordered-list {
      margin: ${s.size('1')} 0;
      padding-left: ${s.size('2')};
      li {
        &::marker {
          color: ${s.color('gray.secondary')};
        }
        > p,
        > div {
          margin: ${s.size('0.5')} 0;
        }
      }
    }
    &-nested-list-item {
      list-style-type: none;
    }
    &-ol-depth-1 {
      list-style-type: decimal;
    }
    &-ol-depth-2 {
      list-style-type: lower-alpha;
    }
    &-ol-depth-3 {
      list-style-type: lower-roman;
    }
    &-ol-depth-4 {
      list-style-type: decimal;
    }
    &-ol-depth-5 {
      list-style-type: lower-alpha;
    }
    &-underline {
      text-decoration: underline;
    }
    &-strikethrough {
      text-decoration: line-through;
    }
    &-underline-strikethrough {
      text-decoration: underline line-through;
    }
    &-highlight {
      background: ${s.color('orange.element')};
      color: ${s.color('orange.text')};
      padding: ${s.size('0.1')} ${s.size('0.25')};
      border-radius: ${s.var('radii.0')};
    }
    &-code {
      background: ${s.color('gray.element')};
      border-radius: ${s.var('radii.0')};
      padding: ${s.size('0.1')} ${s.size('0.25')};
      font-size: 0.9em;
      color: ${s.color('gray.text')};
    }
    &-quote {
      padding: ${s.size('0.5')} ${s.size('1')};
      padding-right: 0;
      color: ${s.color('gray.solid')};
      border-left: 4px solid ${s.color('gray.element')};
    }
    &-heading {
      font-weight: bold;
      margin-bottom: ${s.size('0.5')};
      letter-spacing: ${s.var('letterSpacings.-1')};
      text-transform: none;
      &-level-1 {
        margin-top: ${s.size('6')};
        font-size: ${s.size('6')};
        color: ${s.color('gray.textEmphasis')};
      }
      &-level-2 {
        margin-top: ${s.size('5')};
        font-size: ${s.size('5')};
        color: ${s.color('gray.text')};
      }
      &-level-3 {
        margin-top: ${s.size('4')};
        font-size: ${s.size('4')};
        color: ${s.color('primary.solid')};
      }
      &-level-4 {
        margin-top: ${s.size('3')};
        font-size: ${s.size('3')};
        color: ${s.color('gray.secondary')};
      }
    }
    &-image,
    &-file,
    &-callout {
      margin: ${s.size('1')} 0;
    }
    &-float {
      &.float-alignment-right {
        padding: 0;
        margin-left: ${s.size('2')};
      }
      &.float-alignment-left {
        margin-right: ${s.size('2')};
      }
      > :first-child {
        margin-top: 0;
      }
      > :last-child {
        margin-bottom: 0;
      }
      br:last-child {
        display: none;
      }
      p {
        min-height: ${s.size('6')};
      }
    }
    &-editor-placeholder > * {
      display: none;
    }
    ${props => (props.$isEditable ? '&' : '&.noop')} {
      &-editor-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 75px;
        background: ${s.color('gray.element')};
        border-radius: ${s.var('radii.2')};
        color: ${s.color('gray.secondary')};
        text-align: center;
        font-size: ${s.size('font.s')};
        content: attr(data-placeholder);
        margin: ${s.size('1')} 0;
        > * {
          display: block;
          margin: 0 !important;
        }
      }
      &-float {
        padding: ${s.size('1')} 0;
      }
    }
  }
  hr {
    padding: ${s.size('1')} 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: ${s.color('gray.element')};
      border-radius: ${s.var('radii.0')};
    }
  }
`

export const theme: EditorThemeClasses = {
  root: 'fteditor-root',
  list: {
    listitem: 'fteditor-list-item',
    ul: 'fteditor-list fteditor-unordered-list',
    ol: 'fteditor-list fteditor-ordered-list',
    nested: {
      listitem: 'fteditor-nested-list-item',
      list: 'fteditor-nested-list',
    },
    olDepth: [
      'fteditor-ol-depth-1',
      'fteditor-ol-depth-2',
      'fteditor-ol-depth-3',
      'fteditor-ol-depth-4',
      'fteditor-ol-depth-5',
    ],
  },
  quote: 'fteditor-quote',
  heading: {
    h1: 'fteditor-heading fteditor-heading-level-1',
    h2: 'fteditor-heading fteditor-heading-level-2',
    h3: 'fteditor-heading fteditor-heading-level-3',
    h4: 'fteditor-heading fteditor-heading-level-4',
  },
  text: {
    underline: 'fteditor-underline',
    strikethrough: 'fteditor-strikethrough',
    code: 'fteditor-code',
    underlineStrikethrough: 'fteditor-underline-strikethrough',
    highlight: 'fteditor-highlight',
  },
  image: 'fteditor-image',
  table: 'fteditor-table',
  tableAddColumns: 'fteditor-tableAddColumns',
  tableAddRows: 'fteditor-tableAddRows',
  tableCell: 'fteditor-tableCell',
  tableCellActionButton: 'fteditor-tableCellActionButton',
  tableCellActionButtonContainer: 'fteditor-tableCellActionButtonContainer',
  tableCellEditing: 'fteditor-tableCellEditing',
  tableCellHeader: 'fteditor-tableCellHeader',
  tableCellPrimarySelected: 'fteditor-tableCellPrimarySelected',
  tableCellResizer: 'fteditor-tableCellResizer',
  tableCellSelected: 'fteditor-tableCellSelected',
  tableCellSortedIndicator: 'fteditor-tableCellSortedIndicator',
  tableResizeRuler: 'fteditor-tableCellResizeRuler',
  tableSelected: 'fteditor-tableSelected',
}
