export * from './FieldDescription'
export * from './FieldError'
export * from './FieldLabel'
export * from './Field'

import { Field } from './Field'
import { FieldDescription } from './FieldDescription'
import { FieldError } from './FieldError'
import { FieldLabel } from './FieldLabel'
import { withSubComponents } from '../../utils/withSubComponents'

const Convenience = withSubComponents(Field, {
  Description: FieldDescription,
  Error: FieldError,
  Label: FieldLabel,
})
export default Convenience
