import { useFeature } from '@thesisedu/feature-react'
import { Mic } from '@thesisedu/ui/icons'
import React from 'react'

import {
  SelectMediaSourceFooterButton,
  SelectMediaSourceFooterButtonProps,
} from './SelectMediaSourceFooterButton'
import MediaReactFeature from '../../../../MediaReactFeature'

export interface SelectAudioSourceFooterButtonProps
  extends Pick<SelectMediaSourceFooterButtonProps, 'value' | 'onChange' | 'disabled'> {}
export function SelectAudioSourceFooterButton(props: SelectAudioSourceFooterButtonProps) {
  const audioConstraint = useFeature(MediaReactFeature).options.audioConstraint ?? true
  return (
    <SelectMediaSourceFooterButton
      {...props}
      icon={<Mic />}
      label={'Audio'}
      constraints={{ audio: audioConstraint }}
      type={'audioinput'}
    />
  )
}
