import { Block, Body, Button, HSpaced, useNavigate } from '@thesisedu/react'
import React from 'react'

import { useLookingForResources } from './useLookingForResources'

export function ExploreLookingFor() {
  const resources = useLookingForResources()
  const navigate = useNavigate()
  return (
    <HSpaced space={'@size-xs'} align={'center'} justify={'center'}>
      <Block marginRight={'@size-xs'}>
        <Body color={'@text-color-secondary'}>I'm looking for...</Body>
      </Block>
      {resources.map(resource => {
        return (
          <Button
            key={resource.identifier}
            icon={resource.icon}
            children={resource.title}
            onClick={() => {
              navigate(resource.identifier)
            }}
          />
        )
      })}
    </HSpaced>
  )
}
