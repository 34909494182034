import {
  ApolloReactHooks,
  ExpectedErrorPayload,
  ExpectedErrorContext,
} from '@thesisedu/feature-apollo-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Text, Modal, Block } from '@thesisedu/ui'
import { WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

export function ExpectedErrorHandler() {
  const root = useFeatureRoot()
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    root.deps.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
      ApolloReactHooks.ExpectedError,
      (err, context) => {
        if (context?.code === 'NO_CANVAS_CREDENTIALS_CONFIGURED_ERROR') {
          setVisible(true)
          err.shouldRetry = false
        }
        return err
      },
    )
  }, [])
  return (
    <Modal title={'Administrator approval required'} onVisibleChange={setVisible} visible={visible}>
      <Block top={'l'}>
        <Text level={'l'} color={'red'} bottom>
          <WarningCircle style={{ fontSize: 48 }} />
        </Text>
      </Block>
      <Text level={'l'} bottom>
        <strong>
          We need some information from your district IT administrator before you can continue.
        </strong>
      </Text>
      <Text bottom>
        In order to use Canvas features like grade sync and student import, your district IT
        administrator will need to approve {root.appOptions.name} for use. To start the process,
        reach out to your district IT administrator and have them contact{' '}
        <a href={`mailto:support@${root.appOptions.domain}`}>{root.appOptions.name} Support.</a>
      </Text>
      <Modal.Footer>
        <Modal.CloseButton children={'OK'} />
      </Modal.Footer>
    </Modal>
  )
}
