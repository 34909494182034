import {
  GradingModalVisibilityContextChildrenHook,
  useGradingModalVisibilityContext,
} from '@thesisedu/feature-assignments-react'
import { useCourseViewerModalContext } from '@thesisedu/feature-courses-react'
import React from 'react'

import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'

function HideCourseViewerModalWhenGrading() {
  const { setHidden } = useCourseViewerModalContext(false) || {}
  const { isVisible } = useGradingModalVisibilityContext(true)
  React.useEffect(() => {
    if (setHidden) {
      setHidden(isVisible, 'grading-modal')
    }
  }, [isVisible])
  return null
}

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<GradingModalVisibilityContextChildrenHook>(
    'feature-assignments-react:grading-modal-visibility-context-children',
    children => {
      return [
        ...children,
        <HideCourseViewerModalWhenGrading key={'hide-course-viewer-model-when-grading'} />,
      ]
    },
  )
}
