import { IFeatureHookManager as FeatureHookManager } from '@thesisedu/feature'
import { modifyQueryDocument } from '@thesisedu/feature-apollo-react'
import { EventEmitter } from 'events'
import { DocumentNode } from 'graphql'
import { omit } from 'lodash'
import moment from 'moment'

import { debug, warn } from './log'
import { RecordInteractionsPayloadFragment, RecordInteractionsDocument } from './schema'
import { ApolloClient, InteractionArgument, PendingInteraction, InteractionInput } from './types'

export declare interface InteractionsManager {
  on(
    event: 'interactionsRecorded',
    listener: (result: RecordInteractionsPayloadFragment & { [key: string]: any }) => void,
  ): this
}

const FLUSH_INTERVAL_SECS = 10 // Wait at least 10 seconds before flushing again.
export class InteractionsManager extends EventEmitter {
  private client: ApolloClient
  public pendingInteractions: PendingInteraction[]
  private _pausedInteractions?: PendingInteraction[]
  private _flushPromise?: Promise<void>
  private _lastFlush?: number
  private _waitAndFlushTimeout?: any
  private _recordDocument?: DocumentNode
  private _hookManager: FeatureHookManager

  constructor(hookManager: FeatureHookManager, client: any, initialState?: PendingInteraction[]) {
    super()
    this._hookManager = hookManager
    this.client = client
    this.pendingInteractions = initialState || []
    this.waitAndFlush()
    debug('interactions initialized')
  }

  waitAndFlush() {
    if (this._waitAndFlushTimeout) {
      return
    }
    const toFlush = this.pendingInteractions.filter(i => i.periodEnd)
    if (toFlush.length) {
      const toWait = Math.max(0, FLUSH_INTERVAL_SECS - (moment().unix() - (this._lastFlush || 0)))
      debug('waiting %d seconds before flushing...', toWait)
      this._waitAndFlushTimeout = setTimeout(() => {
        this._waitAndFlushTimeout = undefined
        this.flush().catch(err => {
          warn('error flushing')
          warn(err)
        })
      }, toWait * 1000)
    }
  }

  async _flush() {
    clearTimeout(this._waitAndFlushTimeout)
    this._waitAndFlushTimeout = null
    const toFlush = this.pendingInteractions.filter(i => i.periodEnd)
    if (toFlush.length > 0) {
      debug('flushing %d', toFlush.length)
      try {
        if (!this._recordDocument) {
          this._recordDocument = modifyQueryDocument(RecordInteractionsDocument, this._hookManager)
        }
        const result = await this.client.mutate({
          mutation: this._recordDocument!,
          variables: {
            input: {
              interactions: toFlush
                .map(f => omit(f, ['lastActivity', 'options']))
                .filter(interaction => interaction.type) as InteractionInput[],
            },
          },
        })

        // If the result has come back with something, notify the helpers...
        if (result?.data?.recordInteractions) {
          this.emit('interactionsRecorded', result.data.recordInteractions)
        }

        // Remove all interactions that we just flushed.
        this.pendingInteractions = this.pendingInteractions.filter(i => !toFlush.some(f => f === i))

        // If there are more, wait and flush again.
        this._lastFlush = moment().unix()
        if (this.pendingInteractions.length) {
          this.waitAndFlush()
        }
      } catch (err: any) {
        warn('error recording interactions')
        warn(err)
      }
    }
  }

  async flush() {
    if (this._flushPromise) {
      return this._flushPromise
    }
    this._flushPromise = this._flush()
    await this._flushPromise
    this._flushPromise = undefined
  }

  _stopInteractions(toStop: PendingInteraction[]) {
    for (const interaction of toStop) {
      interaction.periodEnd = moment().format()
    }
    this.waitAndFlush()
    return toStop
  }

  _discardInteractions(toDiscard: PendingInteraction[]) {
    for (const interaction of toDiscard) {
      this.pendingInteractions = this.pendingInteractions.filter(
        pendingInteraction => pendingInteraction !== interaction,
      )
    }

    return []
  }

  _stopInteraction(type: string) {
    debug('stopping %s', type)
    const pendingInteractions = this.pendingInteractions
    const toStop = pendingInteractions.filter(i => i.type === type)
    return this._stopInteractions(toStop)
  }

  singleInteraction(interaction: InteractionArgument) {
    debug('recording single interaction', interaction.type, interaction.reference, interaction)
    const pendingInteractions = this.pendingInteractions
    pendingInteractions.push({
      periodStart: moment().format(),
      periodEnd: moment().format(),
      ...interaction,
    })
    this.waitAndFlush()
  }

  startInteraction(interaction: Pick<InteractionArgument, 'type' | 'reference' | 'metadata'>) {
    this._stopInteraction(interaction.type)
    debug('starting interaction', interaction.type, interaction.reference, interaction)
    const pendingInteractions = this.pendingInteractions
    pendingInteractions.push({
      periodStart: moment().format(),
      ...interaction,
    })
  }

  stopInteraction(type: string) {
    return this._stopInteraction(type)
  }

  stopAllInteractions(shouldDiscard?: 'discard' | 'ignore') {
    debug('stop all (discard %s)', shouldDiscard)
    const pendingInteractions = this.pendingInteractions
    const toDiscard: InteractionArgument[] = []
    const toStop: InteractionArgument[] = []
    for (const pendingInteraction of pendingInteractions) {
      if (pendingInteraction.periodEnd) continue
      if (pendingInteraction.options?.discardOnClose && shouldDiscard === 'discard') {
        toDiscard.push(pendingInteraction)
      } else if (pendingInteraction.options?.discardOnClose && shouldDiscard === 'ignore') {
        continue
      } else {
        toStop.push(pendingInteraction)
      }
    }
    this.waitAndFlush()
    this._discardInteractions(toDiscard)
    return this._stopInteractions(toStop)
  }

  _resetPausedInteractions() {
    this._pausedInteractions = []
  }

  pauseInteractions() {
    debug('pause')
    this._pausedInteractions = (this._pausedInteractions || []).concat(
      this.stopAllInteractions('ignore'),
    )
  }

  resumeInteractions() {
    debug('resume')
    if ((this._pausedInteractions || []).some(Boolean)) {
      debug('pausedInteractions', this._pausedInteractions)
      for (let i = 0; i < this._pausedInteractions!.length; i++) {
        this.startInteraction(this._pausedInteractions![i])
      }
    }
    this._pausedInteractions = []
  }
}
