import { SegmentMetadataSegment, SegmentStructureOverride } from '@thesisedu/feature-course-types'
import React from 'react'

import { Segment } from '../types'

export type CreatedSegment = Pick<Segment, 'id' | 'name' | 'type' | 'config'> & { isCreated: true }
export interface SplitViewPendingChanges {
  structureOverrides: SegmentStructureOverride[]
  metadataOverrides: SegmentMetadataSegment[]
  createdSegments: CreatedSegment[]
}
export interface SplitViewPendingChangesContextValue {
  foreignSegmentsRef: React.MutableRefObject<Record<string, Segment>>
  changes: SplitViewPendingChanges
  addedSegmentIds: string[]
  changedSegmentIds: string[]
  setChanges: (changes: Omit<SplitViewPendingChanges, 'createdSegments'>) => void
  addChanges: (changes: Omit<SplitViewPendingChanges, 'createdSegments'>) => void
  addSegment: (segment: CreatedSegment) => void
  removeChanges: (segmentId: string) => void
}
export const SplitViewPendingChangesContext = React.createContext<
  SplitViewPendingChangesContextValue | undefined
>(undefined)

export function useSplitViewPendingChangesContext(): SplitViewPendingChangesContextValue | undefined
export function useSplitViewPendingChangesContext(
  require: false,
): SplitViewPendingChangesContextValue | undefined
export function useSplitViewPendingChangesContext(
  require: true,
): SplitViewPendingChangesContextValue
export function useSplitViewPendingChangesContext(
  require?: boolean,
): SplitViewPendingChangesContextValue | undefined {
  const context = React.useContext(SplitViewPendingChangesContext)
  if (!context && require) {
    throw new Error('SplitViewPendingChangesContext is required, yet not provided.')
  }
  return context
}
