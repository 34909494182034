import { fromGlobalId } from '@thesisedu/feature-utils'
import { GridContainer, VSpaced } from '@thesisedu/react'
import { Cell, CellField } from '@thesisedu/react/devtools'
import React from 'react'

import { FeatureReactRoot } from '../../FeatureReactRoot'
import { IDEntry, useIDsContext } from '../../IDsContext'
import { DevToolsTabResource } from '../../devtools/types'

interface IDGridItemProps {
  entry: IDEntry
}
function IDGridItem({ entry }: IDGridItemProps) {
  const { type, id } = fromGlobalId(entry.id) ?? {}
  return (
    <Cell title={entry.label}>
      <VSpaced space={'@size-xs'} align={'stretch'}>
        <CellField label={'ID'} value={entry.id} />
        <CellField label={'Type'} value={type ?? 'Unknown'} />
        <CellField label={'ID Num'} value={id ?? 'Unknown'} />
      </VSpaced>
    </Cell>
  )
}

export function IDs() {
  const { currentIdsRef } = useIDsContext(false) || {}
  return (
    <GridContainer columnSize={250} gap={'@size-xs'}>
      {currentIdsRef?.current?.map(currentId => (
        <IDGridItem key={`${currentId.id}-${currentId.label}`} entry={currentId} />
      ))}
    </GridContainer>
  )
}

export default function (root: FeatureReactRoot<any>) {
  root.deps.resources.addResource<DevToolsTabResource>({
    type: 'DevToolsTab',
    identifier: 'ids',
    title: 'IDs',
    component: IDs,
  })
}
