import { getWeeksInMonth } from '@internationalized/date'
import { AriaCalendarGridProps, useCalendarGrid } from '@react-aria/calendar'
import { useLocale } from '@react-aria/i18n'
import React from 'react'
import { CalendarState, RangeCalendarState } from 'react-stately'

import { CalendarCell } from './CalendarCell'

export interface CalendarGridProps extends AriaCalendarGridProps {
  state: CalendarState | RangeCalendarState
}
export function CalendarGrid({ state, ...props }: CalendarGridProps) {
  const { locale } = useLocale()
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state)
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale)

  return (
    <table {...gridProps} cellSpacing={0} cellPadding={0}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map(weekIndex => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) =>
                date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />,
              )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
