import { Body, BodySmall, HSpaced } from '@thesisedu/web'
import React from 'react'

import { ReportMetricResource } from '../../types'

export interface MetricRowHeaderProps {
  metric: ReportMetricResource
}
export function MetricRowHeader({ metric }: MetricRowHeaderProps) {
  return (
    <HSpaced space={'@size-xxs'}>
      <Body>{metric.icon}</Body>
      <BodySmall>{metric.title}</BodySmall>
    </HSpaced>
  )
}
