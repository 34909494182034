import { getDefaultEditorValue, EditorValue, Nodes, isEmpty } from '@thesisedu/feature-widgets-core'

export interface WidgetHeaderGroup {
  header: string | null
  value: EditorValue
}

export function splitWidgetsBasedOnHeaders(value: EditorValue): WidgetHeaderGroup[] {
  const groups: WidgetHeaderGroup[] = [{ header: null, value: getDefaultEditorValue([]) }]
  const rootChildren = value.root.children || []
  for (const child of rootChildren) {
    if (Nodes.is(child, 'heading') && child.tag === 'h1') {
      const textChildren = child.children.filter(child => Nodes.is(child, 'text')) as Nodes.Text[]
      const header = textChildren.map(child => child.text).join('')
      groups.push({
        header,
        value: getDefaultEditorValue([child]),
      })
    } else {
      groups[groups.length - 1].value.root.children.push(child)
    }
  }

  // Go back and move floats.
  for (let i = 0; i < groups.length; i++) {
    const lastItem = groups[i].value.root.children[groups[i].value.root.children.length - 1]
    if (lastItem?.type === 'float' && groups[i + 1]) {
      groups[i].value.root.children.pop()
      groups[i + 1].value.root.children.unshift(lastItem)
    }
  }

  return groups.filter(group => !isEmpty(group.value, group.header ? 2 : 1))
}
