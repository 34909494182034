import { Block, PageHead } from '@thesisedu/react'
import React from 'react'
import styled from 'styled-components'

import { ChangePassword } from './ChangePassword'
import { ChangeProfile } from './ChangeProfile'
import { useViewerContext } from '../ViewerContext'

const Container = styled.div`
  width: 100%;
  max-width: 500px;
`

export const UserSettingsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const viewer = useViewerContext(false)
  if (viewer) {
    return (
      <Container>
        <PageHead title={'User Settings'} />
        {viewer.group === 'TEACHER' || viewer.group === 'ADMIN' ? (
          <Block marginBottom={'@size-l'}>
            <ChangeProfile viewer={viewer} />
          </Block>
        ) : null}
        <ChangePassword viewer={viewer} />
      </Container>
    )
  } else {
    return null
  }
}
