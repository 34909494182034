import { Body, styled } from '@thesisedu/web'
import React from 'react'

import { Beam, BeamProps, getPitchOffset } from './Beam'
import { STAFF_COLOR, TuneNoteItem } from './types'
import { useSVGAutoSize } from './useSVGAutoSize'
import { Accidental } from '../editor/SimpleContext'
import AccidentalFlatSVG from '../svg/AccidentalFlat'
import AccidentalSharpSVG from '../svg/AccidentalSharp'
import EighthNoteSVG from '../svg/NoteEighth'
import InverseEighthNoteSVG from '../svg/NoteEighthInverse'
import HalfNoteSVG from '../svg/NoteHalf'
import InverseHalfNoteSVG from '../svg/NoteHalfInverse'
import NoteNoStem from '../svg/NoteNoStem'
import InverseNoteNoStem from '../svg/NoteNoStemInverse'
import QuarterNoteSVG from '../svg/NoteQuarter'
import InverseQuarterNoteSVG from '../svg/NoteQuarterInverse'
import SixteenthNoteSVG from '../svg/NoteSixteenth'
import InverseSixteenthNoteSVG from '../svg/NoteSixteenthInverse'
import WholeNoteSVG from '../svg/NoteWhole'
import WholeNoteSVGInverse from '../svg/NoteWholeInverse'

function isOffStaff(pitch: number) {
  return (pitch < 2 || pitch > 10) && pitch % 2 === 0
}
function getExtraLedgers(pitch: number) {
  return pitch <= -2 ? Math.ceil(pitch / 2) : 0
}

export const NOTES = 'cdefgab'
export const COLORS = ['@red', '@orange', '@yellow', '@green', '@blue', '@purple', '@pink']
export const ACCIDENTAL_COLORS = ['@poppy', '@gold', '', '@mint', '@blue-2', '@plum', '']
export function getNoteColor(pitch: number, accidental?: 'sharp' | 'flat' | 'natural') {
  return accidental && accidental !== 'natural'
    ? ACCIDENTAL_COLORS[(pitch + 21 + (accidental === 'flat' ? -1 : 0)) % 7]
    : COLORS[(pitch + 21) % 7]
}

interface NoteMap {
  [duration: number]: React.FC<React.PropsWithChildren<any>>
}
function dotNotes(map: NoteMap): NoteMap {
  return (Object.keys(map) as string[]).reduce((acc, duration) => {
    const parsed = parseFloat(duration)
    return {
      ...acc,
      [parsed]: map[parsed],
      [parsed * 1.5]: map[parsed],
    }
  }, {})
}
const _NOTE_COMPONENTS: NoteMap = {
  0.0625: SixteenthNoteSVG,
  0.125: EighthNoteSVG,
  0.25: QuarterNoteSVG,
  0.5: HalfNoteSVG,
  0.75: HalfNoteSVG,
  1: WholeNoteSVG,
  20: NoteNoStem,
}
export const DOTTED_NOTES = Object.keys(_NOTE_COMPONENTS).map(key => parseFloat(key) * 1.5)
const _INVERSE_NOTE_COMPONENTS: NoteMap = {
  0.0625: InverseSixteenthNoteSVG,
  0.125: InverseEighthNoteSVG,
  0.25: InverseQuarterNoteSVG,
  0.5: InverseHalfNoteSVG,
  0.75: InverseHalfNoteSVG,
  1: WholeNoteSVGInverse,
  20: InverseNoteNoStem,
}
const NOTE_COMPONENTS = dotNotes(_NOTE_COMPONENTS)
const INVERSE_NOTE_COMPONENTS = dotNotes(_INVERSE_NOTE_COMPONENTS)
interface AccidentalMap {
  sharp: React.FC<React.PropsWithChildren<any>>
  flat: React.FC<React.PropsWithChildren<any>>
}
const ACCIDENTAL_MAP: AccidentalMap = {
  sharp: AccidentalSharpSVG,
  flat: AccidentalFlatSVG,
}
export interface NoteProps {
  note: TuneNoteItem
  beam?: BeamProps
  inBeam?: {
    maxPitch: number
    minPitch: number
  }
  forceInverse?: boolean
}
export function Note({ note, beam, forceInverse, inBeam }: NoteProps) {
  const pitch = note.pitches[0].pitch
  const { maxPitch = 0, minPitch = 0 } = inBeam || {}
  const accidental = note.pitches[0].accidental
  const autoSizeParentRef = useSVGAutoSize(!!inBeam)
  const inverse =
    forceInverse !== undefined
      ? forceInverse
      : beam?.inverse !== undefined
      ? beam.inverse
      : pitch >= 6
  const noteLabel = NOTES[(pitch + 21) % 7]
  const filled = note.duration <= 0.25 * 1.5
  const color = getNoteColor(pitch, accidental)
  const isYellow = color === '@yellow'
  const isDotted = DOTTED_NOTES.includes(note.duration)
  const NoteComponent = (inverse ? INVERSE_NOTE_COMPONENTS : NOTE_COMPONENTS)[
    inBeam ? 20 : note.duration
  ]
  const AccidentalComponent = accidental ? ACCIDENTAL_MAP[accidental] : undefined
  const extraLedgerOffsets: number[] = []
  const extraLedgers = getExtraLedgers(pitch)
  for (
    let i = 0;
    extraLedgers > 0 ? i < extraLedgers : i > extraLedgers;
    extraLedgers > 0 ? i++ : i--
  ) {
    extraLedgerOffsets.push(i + (extraLedgers > 0 ? 1 : -1))
  }
  if (pitch % 2 !== 0 && pitch <= -1) {
    extraLedgerOffsets.push(0)
  }
  if (!NoteComponent) {
    console.warn(
      "Invalid duration found! %s is not a valid duration. Make sure you're editing the ABC correctly.",
      note.duration,
    )
  }
  return (
    <NoteContainer
      accidental={accidental}
      pitch={pitch}
      inverse={inverse}
      duration={note.duration}
      compact={!!inBeam}
      className={'scale'}
    >
      {note.duration >= 0.5 ? <SpreadContainer pitch={pitch} accidental={accidental} /> : null}
      {inBeam ? (
        <NoteStem
          accidental={accidental}
          pitch={pitch}
          inverse={inverse}
          maxPitch={maxPitch}
          minPitch={minPitch}
          duration={note.duration}
          hasBeam={!!beam}
        />
      ) : null}
      <div
        className={`note-container-inner ${isDotted ? 'dotted' : ''}`}
        ref={autoSizeParentRef}
        style={note.duration <= 0.125 && !inBeam ? { right: SINGLE_8TH_RIGHT_OFFSET } : undefined}
      >
        {extraLedgerOffsets.map(offset => (
          <ExtraLedger offset={offset} isEven={pitch % 2 === 0} key={offset} />
        ))}
        {isOffStaff(pitch) ? <NoteCenterBar /> : null}
        {NoteComponent ? <NoteComponent /> : null}
        <div className={'text-container'}>
          {AccidentalComponent ? (
            <AccidentalContainer accidentalType={accidental} children={<AccidentalComponent />} />
          ) : null}
          <Body color={filled && !isYellow ? '@white' : '@text-color'}>{noteLabel}</Body>
        </div>
      </div>
      {beam ? <Beam {...beam} /> : undefined}
    </NoteContainer>
  )
}
const NoteCenterBar = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 150%;
  margin: -2px -25% 0 -25%;
  height: 4px;
  background: ${props => props.theme[STAFF_COLOR]};
  z-index: -1;
`
const ExtraLedger = styled(NoteCenterBar)<{ offset: number; isEven: boolean }>`
  top: ${props => (props.isEven ? 50 : 0) + 100 * props.offset}%;
`
const SpreadContainer = styled.div<{ pitch: number; accidental?: Accidental }>`
  position: absolute;
  height: 25%;
  left: 18px;
  bottom: ${props => (props.pitch - 3) * 12.5}%;
  right: ${props => props.theme['@size-m']};
  border: solid 3px ${props => props.theme[getNoteColor(props.pitch, props.accidental)]};
  background: white;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  z-index: -2;
`
export const NOTE_WIDTH = 40
export const STEM_WIDTH = 5
export const NOTE_LEFT_PADDING = Math.floor(NOTE_WIDTH / 4)
export const NOTE_LEFT_PADDING_INV = Math.floor(NOTE_WIDTH / 5)
export const STEM_OFFSET = NOTE_WIDTH + NOTE_LEFT_PADDING
export const STEM_INV_OFFSET = 0.75 + NOTE_LEFT_PADDING_INV
export const STEM_8TH_OFFSET = NOTE_WIDTH + NOTE_LEFT_PADDING - STEM_WIDTH
export const STEM_8TH_INV_OFFSET = NOTE_LEFT_PADDING_INV + Math.floor(STEM_WIDTH / 2) - 0.75
export const STEM_WITH_ACCIDENTAL_OFFSET = NOTE_WIDTH + STEM_WIDTH
export const STEM_INV_WITH_ACCIDENTAL_OFFSET = 2
export const SINGLE_8TH_RIGHT_OFFSET = 15
const STEM_TOP_PERCENTAGE_DEFAULT = 39
const STEM_BOTTOM_PERCENTAGE_DEFAULT = -3
export function getStemLeftOffset(inverse: boolean, duration: number, accidental: boolean) {
  if (inverse) {
    return duration >= 0.25
      ? accidental
        ? STEM_INV_OFFSET + 24
        : STEM_INV_OFFSET
      : STEM_8TH_INV_OFFSET
  } else {
    return duration >= 0.25 ? (accidental ? STEM_OFFSET + 48 : STEM_OFFSET) : STEM_8TH_OFFSET
  }
}
const NoteStem = styled.div<{
  pitch: number
  accidental?: Accidental
  inverse?: boolean
  maxPitch: number
  minPitch: number
  duration: number
  hasBeam: boolean
}>`
  background: ${props => props.theme[getNoteColor(props.pitch, props.accidental)]};
  position: absolute;
  left: ${props => getStemLeftOffset(!!props.inverse, props.duration, !!props.accidental)}px;
  border-radius: ${props => (props.inverse ? '10px 10px 0 0' : '0 0 10px 10px')};
  width: ${STEM_WIDTH}px;
  top: ${props =>
    props.inverse
      ? `calc(${(10 - props.pitch) * 12.5}% + 4px);`
      : `calc(${STEM_TOP_PERCENTAGE_DEFAULT}% - ${getPitchOffset(false, props, true)}px);`};
  bottom: ${props =>
    props.inverse
      ? `calc(${STEM_BOTTOM_PERCENTAGE_DEFAULT}% - ${getPitchOffset(true, props)}px);`
      : `calc(${(props.pitch - 3) * 12.5}% + 16px);`};
`
function getNoteWidth(duration: number, inverse: boolean | undefined, compact: boolean) {
  return duration <= 0.125 && !compact && !inverse ? NOTE_WIDTH * 1.5 : NOTE_WIDTH
}
const NoteContainer = styled.div<{
  pitch: number
  accidental?: Accidental
  inverse?: boolean
  duration: number
  compact: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: ${props => (props.compact ? 'space-evenly' : 'flex-start')}; */
  padding-left: ${props =>
    props.compact ? (props.inverse ? NOTE_LEFT_PADDING_INV : NOTE_LEFT_PADDING) : 0}px;
  align-items: flex-end;
  position: relative;
  z-index: 3;
  width: ${NOTE_WIDTH}px;
  @media screen and (max-width: 1024px) {
    justify-content: flex-start;
  }
  .note-container-inner {
    height: 25%;
    position: relative;
    bottom: ${props => (props.pitch - 3) * 12.5}%;
    display: flex;
    align-items: ${props => (props.inverse ? 'flex-start' : 'flex-end')};
    &.dotted:before {
      display: block;
      content: ' ';
      position: absolute;
      ${props => (props.inverse ? 'top' : 'bottom')}: 50%;
      margin-${props => (props.inverse ? 'top' : 'bottom')}: -5px;
      right: -15px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: ${props => props.theme[getNoteColor(props.pitch, props.accidental)]};
    }
    .text-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${NOTE_WIDTH}px;
      z-index: 3;
      p {
        text-align: center;
        font-size: ${props => props.theme['@size-l']};
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;
      }
    }
  }
  svg {
    height: 350%;
    position: relative;
    z-index: 2;
    path:not([fill~='#FFF']) {
      fill: ${props => props.theme[getNoteColor(props.pitch, props.accidental)]};
    }
    ${props => (props.duration <= 0.125 && !props.compact ? '&' : '&.noop')} {
      height: auto;
      width: ${props => getNoteWidth(props.duration, props.inverse, props.compact)}px;
    }
  }
  ${NoteCenterBar} {
    width: calc(${props => getNoteWidth(props.duration, props.inverse, props.compact)}px * 1.5);
    margin-left: -${props => getNoteWidth(props.duration, props.inverse, props.compact) * 0.25}px;
    margin-right: -${props => getNoteWidth(props.duration, props.inverse, props.compact) * 0.25}px;
  }
`
const AccidentalContainer = styled.div<{ accidentalType: 'flat' | 'sharp' | undefined }>`
  position: absolute;
  left: 0;
  margin-left: ${props => (props.accidentalType === 'flat' ? -15 : -18)}px;
  bottom: 3px;
  svg {
    width: ${props => (props.accidentalType === 'flat' ? 16 : 18)}px;
    height: ${props => (props.accidentalType === 'sharp' ? 26 + 'px' : 'auto')};
    display: block;
    path:not([fill~='#FFF']) {
      fill: ${props => props.theme['@brown']};
    }
  }
`
