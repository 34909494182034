import { onMutationError } from '@thesisedu/feature-apollo-react'

import { UPDATE_GRADE_QUERIES } from './constants'
import { useUpdateAssignmentCategoryMutation as useMutation } from '../schema'

export const useUpdateAssignmentCategoryMutation: typeof useMutation = opts => {
  const [mutate, rest] = useMutation({
    onError: onMutationError('There was an error updating that category.'),
    ...opts,
    refetchQueries: ['assignments'],
    update(cache, { data }) {
      const classId = data?.updateAssignmentCategory.assignmentCategory.classId
      if (classId) {
        cache.evict({
          id: cache.identify({ __typename: 'Class', id: classId }),
          fieldName: 'assignmentCategoriesMap',
        })
        cache.evict({
          id: cache.identify({ __typename: 'Class', id: classId }),
          fieldName: 'averageGrade',
        })
        cache.evict({
          id: cache.identify({ __typename: 'ClassStudentsEdge' }),
          fieldName: 'grade',
        })
      }
    },
  })

  return [
    mutateOpts =>
      mutate({
        ...mutateOpts,
        refetchQueries: mutateOpts?.variables?.input.patch.gradeWeight
          ? UPDATE_GRADE_QUERIES
          : undefined,
      }),
    rest,
  ]
}
