import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'

import { validateEditor } from './validate'

const VALIDATE_TIMEOUT = 1000
export function ValidateNodesPlugin() {
  const [lexical] = useLexicalComposerContext()

  React.useEffect(() => {
    let timeoutHandle: any = null

    return lexical.registerUpdateListener(() => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
      }

      timeoutHandle = setTimeout(() => {
        validateEditor(lexical)
      }, VALIDATE_TIMEOUT)
    })
  }, [])

  return null
}
