import {
  RenderSearchItemProps,
  SearchResultListItem,
  SearchResultNode,
} from '@thesisedu/feature-search-react'
import { Markdown } from '@thesisedu/web'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface BadgeSearchResult extends SearchResultNode {
  badgeName: string
  badgeDescription: string
}
export function SearchResult({ item }: RenderSearchItemProps<BadgeSearchResult>) {
  const navigate = useNavigate()
  return (
    <SearchResultListItem
      title={item.badgeName}
      subtitle={<Markdown children={item.badgeDescription} />}
      type={'Badge'}
      onClick={() => {
        navigate(`/admin/badges/${item.id}`)
      }}
    />
  )
}
