import { MaybeElement } from './nodes'
import { EditorValue } from './types'

export type NodeFilter<Child, Parent> = (node: MaybeElement) => boolean
export function filter(
  value: EditorValue,
  walker: NodeFilter<MaybeElement, MaybeElement>,
): EditorValue {
  const newValue: EditorValue = {
    root: {
      ...value.root,
      children: [],
    },
  }

  const traverse = (node: MaybeElement, parent: MaybeElement) => {
    if (walker(node)) {
      const newNode: MaybeElement = {
        ...node,
        children: [],
      }
      parent.children!.push(newNode)
      for (const child of node.children || []) {
        traverse(child, newNode)
      }
    }
  }

  for (const child of value.root.children) {
    traverse(child, newValue.root)
  }

  return newValue
}
