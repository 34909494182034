import { isStorybook } from '@thesisedu/feature-react'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Text, styled } from '@thesisedu/ui'
import React from 'react'

import { PerformanceConfig } from './common'
import { RecordOrView } from '../../../RecordOrView'

const WIDGET_MAX_DURATION = 60 * 10 // 10 minutes.

export function PerformanceElement({
  config,
  node,
  editor,
  readOnly,
}: SimpleWidget.SimpleWidgetProps<PerformanceConfig>) {
  const { alignment } = config
  const [mediaId, setMediaId] = SimpleWidget.useWidgetConfig(editor, node, 'mediaId')
  let content
  if (mediaId || !readOnly) {
    content = (
      <RecordOrView
        value={mediaId ?? undefined}
        onChange={setMediaId}
        label={'Widget Recording'}
        skipReview={!isStorybook}
        maxDurationSeconds={WIDGET_MAX_DURATION}
      />
    )
  } else {
    content = (
      <Text level={'s'} color={'secondary'} style={{ textAlign: 'center' }}>
        There is no content associated with this recording.
      </Text>
    )
  }

  return <Container $alignment={alignment}>{content}</Container>
}

const Container = styled(SimpleWidget.WidgetContainer)`
  aspect-ratio: 16 / 9;
  width: 100%;
  max-width: 800px;
`
