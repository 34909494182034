import {
  styled,
  media,
  BodyLarge,
  FontWeight,
  Body,
  BodySmall,
  AntIconWrapper,
} from '@thesisedu/web'
import { DeleteCircle, Megaphone, WarningCircle } from 'iconoir-react'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { ApproveTrialRequestButton } from './ApproveTrialRequestButton'
import { ApproveTrialRequestModalProps } from './ApproveTrialRequestModal'
import { IgnoreTrialRequestButton } from './IgnoreTrialRequestButton'
import {
  CantApproveTrialRequestReason,
  LicenseFragment,
  LicenseType,
  TrialRequestFragment,
} from './schema'

export interface TrialRequestsListProps {
  requests: TrialRequestFragment[]
  approveProps?: Partial<ApproveTrialRequestModalProps>
}
export function TrialRequestsList({ requests, approveProps }: TrialRequestsListProps) {
  return (
    <Container>
      {requests.map(request => (
        <TrialRequest key={request.id} request={request} approveProps={approveProps} />
      ))}
    </Container>
  )
}

export interface TrialRequestProps {
  request: TrialRequestFragment
  approveProps?: Partial<ApproveTrialRequestModalProps>
}
export function TrialRequest({ request, approveProps }: TrialRequestProps) {
  return (
    <RequestContainer>
      <NameContainer>
        <BodyLarge>{[request.firstName, request.lastName].filter(Boolean).join(' ')}</BodyLarge>
        <BodySmall>
          <a href={`mailto:${request.email}`}>{request.email}</a>
        </BodySmall>
        {request.phone ? (
          <BodySmall>
            <a href={`tel:${request.phone}`}>{request.phone}</a>
          </BodySmall>
        ) : null}
        {request.trialRequestCampaign?.identifier ? (
          <BodySmall color={'@green'}>
            <AntIconWrapper children={<Megaphone />} />
            <span style={{ marginLeft: 5 }}>{request.trialRequestCampaign.identifier}</span>
          </BodySmall>
        ) : null}
      </NameContainer>
      <LocationContainer>
        <Body>{request.siteName}</Body>
        <BodySmall color={'@text-color-secondary'}>
          {[request.city, request.state, request.country].filter(Boolean).join(', ')}
        </BodySmall>
      </LocationContainer>
      <ActionsContainer>
        <TrialRequestWarning
          cantApproveReason={request.cantApproveReason}
          existingLicense={request.existingLicense}
        />
        <ApproveTrialRequestButton
          id={request.id}
          existingRequest={request}
          {...approveProps}
          buttonProps={{
            disabled: !!request.cantApproveReason,
          }}
        />
        <IgnoreTrialRequestButton id={request.id} />
      </ActionsContainer>
    </RequestContainer>
  )
}

const getExpires = (license?: LicenseFragment | null) => {
  if (license && moment(license.expiresAt).isBefore(moment())) {
    return `Expired ${moment(license.expiresAt).format('MM/DD/YYYY')}`
  } else if (license && moment(license.expiresAt).isAfter(moment())) {
    return `Expires ${moment(license.expiresAt).format('MM/DD/YYYY')}`
  } else return ''
}

export interface TrialRequestWarningProps {
  cantApproveReason?: CantApproveTrialRequestReason | null
  existingLicense?: LicenseFragment | null
  large?: boolean
}
export function TrialRequestWarning({
  cantApproveReason,
  existingLicense,
  large,
}: TrialRequestWarningProps) {
  if (cantApproveReason || existingLicense) {
    let title: string = ''
    let description: string | React.ReactElement = ''
    let largeDetail: React.ReactElement | null = null
    if (cantApproveReason === CantApproveTrialRequestReason.BelongsToNonTrialLicense) {
      title = 'Existing license'
      description = getExpires(existingLicense)
      largeDetail = (
        <>
          <BodyLarge weight={FontWeight.Bold} isBlock>
            You cannot approve this trial because it already belongs to an existing non-trial
            license.
          </BodyLarge>
          <Body>
            If you would like to approve it, please edit the existing user and change their email to
            something else. Then, you may come back here and approve the trial request.
          </Body>
        </>
      )
    } else if (cantApproveReason === CantApproveTrialRequestReason.DeletedUserExists) {
      title = 'Existing deleted user'
      description = <Link to={'/admin/users'}>Free username to approve</Link>
      largeDetail = (
        <>
          <BodyLarge weight={FontWeight.Bold} isBlock>
            You can't approve this trial because a deleted user with this email address already
            exists.
          </BodyLarge>
          <Body>
            This typically means this user had an active license and account in the past, but it has
            since been deleted.
          </Body>
          <Body>
            <Link to={'/admin/users'}>Free their username first,</Link> and then come back here to
            approve their request.
          </Body>
        </>
      )
    } else if (existingLicense?.type === LicenseType.Trial) {
      title = 'Existing trial'
      description = getExpires(existingLicense)
      largeDetail = (
        <>
          <BodyLarge weight={FontWeight.Bold} isBlock>
            You may approve this trial request, but please read the following first:
          </BodyLarge>
          <Body>
            If you approve this trial request, this user's courses will be reset to the
            currently-configured trial courses, a new deal will be created in the CRM, and the user
            will receive another invite email. If this user has existing classes, they will not be
            deleted.
          </Body>
          <Body>
            They will be able to login to the same account, with the same credentials they've used
            before. If they have forgotten those, they will be able to reset them using the
            automated email sent once you approve this request.
          </Body>
        </>
      )
    }
    return (
      <WarningContainer
        color={cantApproveReason ? '@red' : '@orange'}
        className={large ? 'large' : ''}
      >
        {cantApproveReason ? <DeleteCircle /> : <WarningCircle />}
        {large ? (
          <div>{largeDetail}</div>
        ) : (
          <div>
            <Body>{title}</Body>
            <BodySmall>{description}</BodySmall>
          </div>
        )}
      </WarningContainer>
    )
  } else {
    return null
  }
}

const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: ${props => props.theme['@size-m']};
  padding-bottom: ${props => props.theme['@size-m']};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  width: 100%;
  ${media.lg} {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: ${props => props.theme['@size-m']};
    padding-bottom: 0;
    border-bottom: none;
  }
`
const ContentContainer = styled.div`
  > * {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    max-width: 100%;
  }
`
const NameContainer = styled(ContentContainer)`
  width: 100%;
  ${media.lg} {
    width: 250px;
  }
`
const LocationContainer = styled(ContentContainer)`
  width: 100%;
  margin: ${props => props.theme['@size-s']} 0 0 0;
  ${media.lg} {
    width: 300px;
    margin: 0 0 0 ${props => props.theme['@size-s']};
  }
`
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: ${props => props.theme['@size-m']} 0 0 0;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
  ${media.lg} {
    width: auto;
    margin: 0 0 0 auto;
    > :first-child {
      margin-right: ${props => props.theme['@size-s']};
    }
  }
`
const WarningContainer = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${props => props.theme[props.color]};
  margin-right: auto;
  svg {
    width: ${props => props.theme['@size-m']};
    height: ${props => props.theme['@size-m']};
    margin: 0 ${props => props.theme['@size-xs']} 0 0;
    display: none;
  }
  ${media.lg} {
    margin-right: 0;
    > svg {
      order: 2;
      margin: 0 0 0 ${props => props.theme['@size-xs']};
      display: block;
    }
    > div {
      order: 1;
      text-align: right;
    }
  }
  &.large > div {
    margin: 0;
    text-align: left;
    > p:not(:last-child) {
      margin-bottom: ${props => props.theme['@size-xs']} !important;
    }
    > p:first-child {
      margin-bottom: ${props => props.theme['@size-s']} !important;
    }
  }
`
const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`
