import { styled, s, Button } from '@thesisedu/ui'
import { Undo } from '@thesisedu/ui/icons'
import React from 'react'

import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'

enum ChangeMode {
  Added,
  Changed,
}
interface ChangeModeDetail {
  color: s.ColorAlias
  label: string
}
const ChangeModeMap: Record<ChangeMode, ChangeModeDetail> = {
  [ChangeMode.Added]: {
    color: 'green',
    label: 'Added',
  },
  [ChangeMode.Changed]: {
    color: 'blue',
    label: 'Moved',
  },
}

export interface SplitViewDraggableChangeProps {
  id: string
}
export function SplitViewDraggableChange({ id }: SplitViewDraggableChangeProps) {
  const { addedSegmentIds, changedSegmentIds, removeChanges } =
    useSplitViewPendingChangesContext(true)
  const changeMode = addedSegmentIds.includes(id)
    ? ChangeMode.Added
    : changedSegmentIds.includes(id)
    ? ChangeMode.Changed
    : null
  if (changeMode != null) {
    const info = ChangeModeMap[changeMode]
    return (
      <Container $color={info.color}>
        <Reset
          $color={info.color}
          children={info.label}
          size={'small'}
          icon={<Undo />}
          onPress={() => {
            removeChanges(id)
          }}
        />
      </Container>
    )
  } else return null
}

const Container = styled.div<{ $color: s.ColorAlias }>`
  border-radius: ${s.var('radii.1')};
  position: absolute;
  inset: 0;
  border: solid 3px ${props => s.color(props.$color)};
  pointer-events: none;
`
const Reset = styled(Button)<{ $color: s.ColorAlias }>`
  pointer-events: all;
  width: 100px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${props => s.color(props.$color)};
  border-radius: 0;
  border-bottom-left-radius: ${s.var('radii.1')};
  position: absolute;
  transform: none !important;
  top: 0;
  right: 0;
`
