import { useExploreGridContext } from '@thesisedu/feature-courses-react/dist/explore/grid'
import {
  Container,
  Content,
  Header,
  ActiveIndicator,
} from '@thesisedu/feature-courses-react/dist/explore/grid/GridTagFilter'
import { BodyLarge, useStateAndDebounce } from '@thesisedu/react'
import { Block, Space, Tree } from '@thesisedu/web'
import { Input } from 'antd'
import { DataNode } from 'antd/lib/tree'
import { Plus, Minus } from 'iconoir-react'
import React from 'react'

import { FullStandardSetFragment, TagsTreeFragment, TagsTreeItemFragment } from '../schema'

type TreeItem = TagsTreeItemFragment & { children?: TreeItem[] }
function _getTreeData(tree: TreeItem[], parents?: string[]): DataNode[] {
  return tree.map(item => {
    return {
      key: _tagToKey(item.tag.name, parents || []),
      title: item.tag.name,
      checkable: !item.children?.length,
      children: item.children?.length
        ? _getTreeData(item.children, [...(parents || []), item.tag.id])
        : undefined,
    }
  })
}
function _getAllKeys(tree: DataNode[]): string[] {
  let result: string[] = []
  for (const item of tree) {
    result = [...result, item.key.toString(), ..._getAllKeys(item.children || [])]
  }
  return result
}

function _matchTagName(name: string, query: string) {
  return name.toLowerCase().replace(/ /g, '').includes(query.toLowerCase().replace(/ /g, ''))
}
function _filterTree(tree: TreeItem[], query: string): TreeItem[] {
  return tree
    .map(item => {
      const matches = _matchTagName(item.tag.name, query)
      const result = {
        ...item,
        children: item.children?.length ? _filterTree(item.children, query) : [],
      }
      if (matches || result.children?.length) {
        return result
      } else {
        return null
      }
    })
    .filter(Boolean) as TagsTreeFragment[]
}

const PARENT_SEP = ':-:'
const TAG_SEP = ':!:'
function _tagToKey(tagName: string, parents: string[]) {
  return [parents.join(PARENT_SEP), tagName].join(TAG_SEP)
}

function _keyToTagInfo(key: string): [string[], string] {
  const [parents, name] = key.split(TAG_SEP)
  return [parents.split(PARENT_SEP), name]
}

const SEARCH_DEBOUNCE = 500
export interface StandardSetExploreFilterProps {
  standardSet: FullStandardSetFragment
}
export function StandardSetExploreFilter({ standardSet }: StandardSetExploreFilterProps) {
  const [expanded, setExpanded] = React.useState(false)
  const [dNameFilter, nameFilter, setNameFilter] = useStateAndDebounce('', SEARCH_DEBOUNCE)
  const { selectedTags, setSelectedTags } = useExploreGridContext(true)
  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([])
  const filteredTree = React.useMemo(
    () =>
      dNameFilter.trim()
        ? _filterTree(standardSet.tagsTree, dNameFilter.trim())
        : standardSet.tagsTree,
    [dNameFilter],
  )
  const treeData = _getTreeData(filteredTree)
  React.useEffect(() => {
    if (dNameFilter.trim()) {
      const allKeys = _getAllKeys(treeData)
      setExpandedKeys(allKeys)
    } else {
      setExpandedKeys([])
    }
  }, [dNameFilter])
  if (!standardSet.tagsTree.length) return null
  const tagType = standardSet.tagsTree[0].tag.type
  if (!tagType) return null
  const standardSetSelectedTags = selectedTags.filter(tag => tag.type === tagType)
  const isActive = standardSetSelectedTags.length > 0
  return (
    <Container>
      <Header
        onClick={e => {
          e.preventDefault()
          setExpanded(ex => !ex)
        }}
      >
        <BodyLarge>{standardSet.name} Standards</BodyLarge>
        <Space />
        {isActive ? <ActiveIndicator /> : null}
        {expanded ? <Minus /> : <Plus />}
      </Header>
      <Content className={expanded ? 'expanded' : ''}>
        <div>
          <Input.Search
            size={'small'}
            placeholder={`Find ${standardSet.name} Standard`}
            value={nameFilter}
            onChange={e => setNameFilter(e.target.value)}
          />
          <Block marginTop={'@size-xs'}>
            <Tree
              checkable
              compact
              selectable={false}
              expandedKeys={expandedKeys}
              onExpand={keys => setExpandedKeys(keys.map(k => k.toString()))}
              treeData={treeData}
              checkedKeys={standardSetSelectedTags.map(tag =>
                _tagToKey(tag.name || '', tag.path || []),
              )}
              onCheck={checkedKeys => {
                if (!Array.isArray(checkedKeys)) return
                setSelectedTags(st => {
                  const without = st.filter(st => st.type !== tagType)
                  return [
                    ...without,
                    ...checkedKeys.map(checkedKey => {
                      const [parents, name] = _keyToTagInfo(checkedKey.toString())
                      return {
                        type: tagType,
                        name,
                        path: parents,
                      }
                    }),
                  ]
                })
              }}
            />
          </Block>
        </div>
      </Content>
    </Container>
  )
}
