import {
  ArrowRightOutlined,
  DownOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons'
import { toGlobalId } from '@thesisedu/feature-utils'
import { styled } from '@thesisedu/web'
import { Dropdown, Menu, Popconfirm } from 'antd'
import React, { HTMLProps, useState } from 'react'

import { Segment as SegmentComponent } from './Segment'
import { CopyTagsToChildren } from './actions/CopyTagsToChildren'
import { DetachSegmentButton } from './actions/DetachSegmentButton'
import { useSegmentResource } from './useSegmentResource'
import {
  useCourseEditorReadContext,
  useCourseEditorStatusContext,
} from '../contexts/CourseEditorContext'
import { useDetach } from '../contexts/DetachContext'
import { useSegmentDragDropContext } from '../contexts/SegmentDragDropContext'
import { useSegmentOverrideContext } from '../contexts/SegmentOverrideContext'
import { isTeacherInfoSegment } from '../helpers'
import { SegmentType } from '../schema'
import { Segment } from '../types'

const Container = styled.div`
  padding: 5px 10px 5px 0;
  a.edit-link {
    display: block;
  }
  a.edit-link,
  > .actions > a,
  > .left-actions > a {
    color: ${props => props.theme['@gray-6']};
    &:not(.loading):hover {
      color: ${props => props.theme['@blue']};
    }
    &.delete:hover {
      color: ${props => props.theme['@red']};
    }
    &.enable:hover {
      color: ${props => props.theme['@green']};
    }
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  > .left-actions {
    display: block;
    float: left;
    margin: 0 5px 0 0;
  }
  > .actions {
    display: block;
    margin: 0 0 0 10px;
    float: right;
  }
`

export type HoverProps = Pick<HTMLProps<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'>
export interface SegmentDraggableProps {
  segment: Segment
  id: string
  onChange: (segment: Segment, updates: Partial<Segment>) => void
  onDelete: (segment: Segment) => void
  expandLevels?: number
  index?: number
  highlighted?: boolean
  parentDisabled?: boolean
  disableDelete?: boolean
}
export interface SegmentDraggableContentProps extends SegmentDraggableProps {
  hoverProps?: HoverProps
}
export function SegmentDraggableContent({
  segment,
  onChange,
  onDelete,
  hoverProps,
  parentDisabled,
  expandLevels,
  disableDelete,
}: SegmentDraggableContentProps) {
  const detachContext = useDetach()
  const overrideContext = useSegmentOverrideContext()
  const dragContext = useSegmentDragDropContext(true)
  const { courseVersionId } = useCourseEditorReadContext(true)
  const { enableReferences, loadingSegmentIds } = useCourseEditorStatusContext(true)
  const isOverrideMode = !!overrideContext
  const [expanded, setExpanded] = useState<boolean>(!!expandLevels && expandLevels > 0)
  const hasChildren = useSegmentResource(segment.type).hasChildren
  const isTeacherInfo = isTeacherInfoSegment(segment)

  let leftActions: React.ReactElement[] | null = null
  if (hasChildren) {
    leftActions = [
      <a
        key={'expand'}
        onClick={() => setExpanded(e => !e)}
        title={expanded ? 'Collapse' : 'Expand'}
      >
        {expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
      </a>,
    ]
  }

  const otherActions: React.ReactElement[] = []
  let otherActionsContainer: React.ReactElement | null = null

  // If we're not in the context of a teacher and the element has children, show the
  // "copy tags to children" control in the admin interface.
  if (!isOverrideMode && hasChildren) {
    otherActions.push(
      <Menu.Item key={'copy-tags'}>
        <CopyTagsToChildren segment={segment} />
      </Menu.Item>,
    )
  }

  // If we're in a reference, add the detach item.
  if (!isOverrideMode && detachContext && segment.type !== SegmentType.Reference) {
    otherActions.push(
      <Menu.Item key={'detach'}>
        <DetachSegmentButton
          segmentDatabaseId={toGlobalId('Segment', segment.id)}
          courseVersionId={courseVersionId}
        />
      </Menu.Item>,
    )
  }

  let moveAction: React.ReactElement | null = null
  if (dragContext.movingSegmentId) {
    if (dragContext.movingSegmentId === segment.id) {
      moveAction = (
        <a
          key={'cancel-move'}
          className={'move'}
          onClick={() => dragContext.setMovingSegment(undefined)}
        >
          Cancel
        </a>
      )
    } else if (dragContext.movingSegmentId && hasChildren) {
      const { movingSegmentChildren } = dragContext
      if (!movingSegmentChildren.includes(segment.id)) {
        const segmentId = dragContext.movingSegmentId
        moveAction = (
          <a
            key={'move-here'}
            className={'move-here'}
            onClick={() => dragContext.moveSegmentToList(segment.id, segmentId)}
          >
            Move Here
          </a>
        )
      }
    }
  } else {
    const isReferenceSegment = enableReferences && !!segment.referenceSegment
    if (!isOverrideMode && !isReferenceSegment && !dragContext.disableMove) {
      otherActions.push(
        <Menu.Item key={'move'}>
          <a onClick={() => dragContext.setMovingSegment(segment.id)}>
            <ArrowRightOutlined /> Move
          </a>
        </Menu.Item>,
      )
    } else if (isOverrideMode && overrideContext?.rePlaceSegment) {
      const replace = overrideContext.rePlaceSegment
      otherActions.push(
        <Menu.Item key={'move'}>
          <a onClick={() => replace(segment)}>
            <ArrowRightOutlined /> Move
          </a>
        </Menu.Item>,
      )
    }
  }

  let disableAction: React.ReactElement | null = null
  if (!isTeacherInfo) {
    if (loadingSegmentIds?.includes(segment.id)) {
      disableAction = (
        <a key={'loading'} className={'loading'}>
          Loading...
        </a>
      )
    } else if (segment.visibleOverride === true) {
      disableAction = (
        <a
          key={'disable'}
          className={'delete'}
          onClick={() => onChange(segment, { visibleOverride: false })}
        >
          Disable
        </a>
      )
    } else {
      disableAction = (
        <a
          key={'enable'}
          className={'enable'}
          onClick={() => onChange(segment, { visibleOverride: true })}
        >
          Enable
        </a>
      )
    }
  }

  if (otherActions.length) {
    otherActionsContainer = (
      <Dropdown key={'other-actions'} overlay={<Menu>{otherActions}</Menu>} trigger={['click']}>
        <a>
          Actions <DownOutlined />
        </a>
      </Dropdown>
    )
  }

  const actions = [
    !dragContext.disableMove ? moveAction : null,
    !isOverrideMode && !disableDelete ? (
      <Popconfirm
        key={'delete'}
        title={'Are you sure?'}
        onConfirm={onDelete ? () => onDelete(segment) : undefined}
        okType={'danger'}
        okText={'Yes, delete'}
      >
        <a key={'delete'} className={'delete'}>
          Delete
        </a>
      </Popconfirm>
    ) : null,
    isOverrideMode ? disableAction : null,
    otherActionsContainer,
  ].filter(Boolean) as React.ReactElement[]

  return (
    <Container>
      <div className={'actions'} {...hoverProps}>
        {actions}
      </div>
      <div className={'left-actions'} {...hoverProps}>
        {leftActions}
      </div>
      <SegmentComponent
        segment={segment}
        expanded={expanded}
        disabled={isOverrideMode && segment.visibleOverride !== true && !parentDisabled}
        expandLevels={expandLevels !== undefined ? expandLevels - 1 : undefined}
        onChange={onChange}
      />
    </Container>
  )
}
