import * as icons from '@ant-design/icons'
import {
  media,
  styled,
  H3Alternate,
  BodyLarge,
  BlockSpin,
  FontWeight,
  Body,
  BodySmall,
  Block,
  StyledThemeContext,
} from '@thesisedu/web'
import { Checkbox, message } from 'antd'
import { groupBy } from 'lodash'
import React from 'react'

import { debug } from '../log'
import { NotificationPreferenceFragment } from '../schema'
import { PreferencesEditorProps } from '../types'
import { UsePreferencesEditor, usePreferencesEditor } from '../usePreferencesEditor'

type PreferencesEditorItemProps = Pick<UsePreferencesEditor, 'updatePreference' | 'channels'> &
  NotificationPreferenceFragment & { allPreference?: NotificationPreferenceFragment }
export function PreferencesEditorItem({
  updatePreference,
  disabledChannels,
  channels,
  notificationType,
  name,
  description,
  allPreference,
}: PreferencesEditorItemProps) {
  return (
    <RowContainer>
      <div>
        <BodyLarge>{name}</BodyLarge>
        <Body color={'@text-color-secondary'}>{description}</Body>
      </div>
      {channels.map(channel => (
        <div key={channel.channel}>
          <Checkbox
            checked={!disabledChannels.includes(channel.channel)}
            disabled={!!allPreference?.disabledChannels.includes(channel.channel)}
            onChange={e => {
              updatePreference(notificationType, {
                disabledChannels: [
                  ...disabledChannels.filter(chan => chan !== channel.channel),
                  ...(e.target.checked ? [] : [channel.channel]),
                ],
              })
            }}
          />
        </div>
      ))}
    </RowContainer>
  )
}

export function PreferencesEditor(props: PreferencesEditorProps) {
  const { loading, preferences, channels, ...common } = usePreferencesEditor(props)
  const theme = React.useContext(StyledThemeContext)
  const hasSetRef = React.useRef<boolean>(false)
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const disable = params.get('disable')
    const channel = params.get('channel')
    if (disable && channel && !loading && !hasSetRef.current) {
      const existing = preferences.find(pref => pref.notificationType === disable)
      debug('found default disable %s with channel %s, disabling', disable, channel)
      common.updatePreference(disable, {
        disabledChannels: [...new Set<string>([channel, ...(existing?.disabledChannels || [])])],
      })
      hasSetRef.current = true
      message.success('Preferences updated successfully!')
    }
  }, [loading])

  if (loading && !channels.length) {
    return <BlockSpin />
  } else {
    const grouped = groupBy(
      preferences.filter(pref => pref.notificationType !== 'all'),
      'group',
    )
    const allPreference = preferences.find(pref => pref.notificationType === 'all')
    return (
      <>
        <HeaderContainer>
          <LoadingContainer loading={loading}>
            <icons.LoadingOutlined />
            <BodySmall color={'@text-color-secondary'}>Saving...</BodySmall>
          </LoadingContainer>
          {channels.map(channel => {
            const Icon = (icons as any)[channel.iconName] as React.FC<React.PropsWithChildren<any>>
            return (
              <HeaderChannel key={channel.channel}>
                <Icon style={{ fontSize: 24 }} twoToneColor={theme['@primary-color']} />
                <Block marginLeft={'@size-xs'}>
                  <BodyLarge weight={FontWeight.Bold}>{channel.name}</BodyLarge>
                </Block>
              </HeaderChannel>
            )
          })}
        </HeaderContainer>
        {allPreference ? (
          <PreferencesEditorItem
            {...allPreference}
            description={'Enable / disable all notifications for your devices.'}
            {...common}
            channels={channels}
          />
        ) : null}
        {Object.keys(grouped).map(group => (
          <React.Fragment key={group || 'ungrouped'}>
            {group ? (
              <GroupContainer>
                <div>
                  <H3Alternate isBlock={false}>{group}</H3Alternate>
                </div>
              </GroupContainer>
            ) : null}
            {grouped[group].map(preference => (
              <PreferencesEditorItem
                key={preference.notificationType}
                {...preference}
                {...common}
                channels={channels}
                allPreference={allPreference}
              />
            ))}
          </React.Fragment>
        ))}
      </>
    )
  }
}

const CUTOFF = media.lg
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    padding: ${props => props.theme['@padding-sm']};
    &:first-child {
      flex: 1;
    }
    &:not(:first-child) {
      width: 48px;
      ${CUTOFF} {
        width: 150px;
      }
    }
  }
`
const GroupContainer = styled(RowContainer)`
  margin-top: ${props => props.theme['@size-m']};
  border-bottom: solid 1px ${props => props.theme['@border-color-split']};
`
const HeaderContainer = styled(RowContainer)`
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  position: sticky;
  top: 0;
  background-color: ${props => props.theme['@gray-0']};
  z-index: 1;
`
const HeaderChannel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > :last-child {
    display: none;
    ${CUTOFF} {
      display: block;
    }
  }
`
const LoadingContainer = styled.div<{ loading?: boolean }>`
  opacity: ${props => (props.loading ? 1 : 0)};
  transition: opacity 0.25s linear;
  color: ${props => props.theme['@text-color-secondary']};
  display: flex;
  align-items: center;
  pointer-events: none;
  > :first-child {
    font-size: ${props => props.theme['@size-xm']};
  }
  > :last-child {
    margin-left: ${props => props.theme['@size-xs']};
  }
`
