import { PageHead } from '@thesisedu/web'
import React from 'react'

import { SegmentContent } from './SegmentContent'
import { SegmentProps } from '../types'

export function DefaultSegmentContent(props: SegmentProps) {
  return (
    <>
      <PageHead title={props.segment.name} />
      <SegmentContent {...props} />
    </>
  )
}
