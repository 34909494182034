import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

export function RecordButtonPrepareState() {
  return (
    <>
      <LoadingIndicator />
      <span>Getting ready...</span>
    </>
  )
}
