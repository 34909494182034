import React from 'react'

import { getFetchClassesFromProvider } from './fetchClassesFromProvider'
import { getProviderError } from './providerError'
import { getRosterComplete } from './rosterComplete'
import { RosterStudentsContext } from './rosterStudents'
import { SiteClassSyncProviderFragment } from '../../schema'
import { RosteringProgress } from '../Rostering/RosteringProgress'
import { SetupStep, StepSizes, getStepFactory } from '../types'

export interface RosterStudentsProgressContext extends RosterStudentsContext {
  provider: SiteClassSyncProviderFragment
  jobId: string
}
export default {
  size: StepSizes.loading,
  animationGroup: 'loading',
  hideClose: true,
  Component({ transition, context, cls }) {
    return (
      <RosteringProgress
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        classId={cls.id}
        jobId={context.jobId}
        onError={() => {
          transition(
            getProviderError({
              provider: context.provider,
              nextStep: getFetchClassesFromProvider({ provider: context.provider }),
            }),
          )
        }}
        onSuccess={() => {
          if (context.exitAfterRostering) {
            transition(null)
          } else {
            transition(
              getRosterComplete({
                provider: context.provider,
              }),
            )
          }
        }}
      />
    )
  },
} satisfies SetupStep<RosterStudentsProgressContext>
export const getRosterStudentsProgress =
  getStepFactory<RosterStudentsProgressContext>('rosterStudentsProgress')
