export * from './Image'
export * from './ImageOverlay'
export * from './ImageLoadingOverlay'
export * from './Video'

import { Image } from './Image'
import { ImageLoadingOverlay } from './ImageLoadingOverlay'
import { ImageOverlay } from './ImageOverlay'
import { Video } from './Video'
import { withSubComponents } from '../../utils/withSubComponents'

const Convenience = withSubComponents(Image, {
  Video,
  Overlay: ImageOverlay,
  LoadingOverlay: ImageLoadingOverlay,
})
export default Convenience
