import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateClassGroupPresetMutation as useMutation } from '../schema'

export const useCreateClassGroupPresetMutation: typeof useMutation = opts => {
  const [create, rest] = useMutation({
    onError: onMutationError('There was an error creating that preset.'),
    ...opts,
  })
  return [
    async opts =>
      create({
        ...opts,
        async update(cache, ...rest) {
          await opts?.update?.(cache, ...rest)
          const classId = opts?.variables?.input.id
          if (classId) {
            cache.evict({
              id: cache.identify({
                __typename: 'Class',
                id: classId,
              }),
              fieldName: 'groupPresets',
            })
          }
        },
      }),
    rest,
  ]
}
