import { UserRoutesHook, UserLeftItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { Building } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { DistrictsRoute } from './DistrictsRoute'
import { DistrictsWebFeature } from '../../DistrictsWebFeature'

export default function (feature: DistrictsWebFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          <Route key={'districts'} path={'districts/*'} element={<DistrictsRoute />} />,
          ...routes,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, context) => {
      if (context.group !== 'ADMIN') return items
      return [
        ...items,
        {
          group: 'top',
          element: (
            <Shell.LeftMenuItem
              name={'Districts'}
              icon={<AntIconWrapper children={<Building />} />}
              path={'/admin/districts'}
            />
          ),
          weight: 0,
        },
      ]
    },
  )
}
