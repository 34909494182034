import { styled } from '@thesisedu/web'
import React from 'react'

import { StandardSetWidget } from './StandardSetWidget'
import { StandardSetFragment, useExploreStandardSetsQuery } from '../schema'

export interface ExploreStandardSetsProps {
  getAllLink: (standardSet: StandardSetFragment) => string
}
export function ExploreStandardSets({ getAllLink }: ExploreStandardSetsProps) {
  const { data } = useExploreStandardSetsQuery()
  const standards = data?.viewer?.teacher?.site.standardSets.edges.map(edge => edge.node) || []
  if (standards.length) {
    return (
      <Container>
        {standards.map(set => (
          <StandardSetWidget standardSet={set} allLink={getAllLink(set)} key={set.id} />
        ))}
      </Container>
    )
  } else {
    return null
  }
}

const Container = styled.div`
  display: grid;
  gap: ${props => props.theme['@size-l']};
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  margin: ${props => props.theme['@size-l']} 0;
`
