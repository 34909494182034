import { UserRoutesHook, UserLeftItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { TaskList } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { StandardSets } from './StandardSets'
import { StandardSetsReactFeature } from '../../StandardSetsReactFeature'

export default function (feature: StandardSetsReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...routes,
          <Route key={'standard-sets'} path={'standard-sets/*'} element={<StandardSets />} />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            weight: 2,
            element: (
              <Shell.LeftMenuItem
                name={'Standard Sets'}
                icon={<AntIconWrapper children={<TaskList />} />}
                path={'/admin/standard-sets'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
