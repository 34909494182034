import { s } from '../../'

const { css } = s
export const ItemStyles = css`
  all: unset;
  font-size: ${s.var('size.0.9')};
  line-height: 1;
  color: ${s.color('gray.text')};
  border-radius: ${s.var('radii.1')};
  display: flex;
  align-items: center;
  min-height: 2em;
  padding: ${s.var('size.0.5')} ${s.var('size.0.5')};
  box-sizing: border-box;
  position: relative;
  padding-left: 2em;
  user-select: none;

  &[data-disabled] {
    color: ${s.color('gray.textDisabled')};
    --menu-item-color: ${s.color('gray.textDisabled')};
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: ${s.color('gray.element')};
    color: ${s.color('primary.solid')};
    --menu-item-color: ${s.color('primary.secondary')};
  }

  &[data-danger] {
    color: ${s.color('red.text')};
    --menu-item-color: ${s.color('red.secondary')};
    &[data-highlighted] {
      background-color: ${s.color('red.element')};
    }
    &[data-disabled] {
      color: ${s.color('red.border')};
      --menu-item-color: ${s.color('red.subtle')};
      background-color: transparent;
    }
  }
`
