import { MailOutlined } from '@ant-design/icons'
import { useUnreadThreads } from '@thesisedu/feature-direct-messages-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { styled, Body } from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'
import { Link } from 'react-router-dom'

import { ClassTaskSummaryProviderProps } from '../types'

const Container = styled(Body)`
  display: flex;
  align-items: center;
  > .icon {
    font-size: ${props => props.theme['@size-l']};
    margin-right: ${props => props.theme['@size-xs']};
  }
`

export function UnreadDirectMessagesTask({ classId }: ClassTaskSummaryProviderProps) {
  const unreadThreads = useUnreadThreads({ filters: { classIds: [classId] } })
  const viewer = useViewerContext(false)
  if (unreadThreads) {
    return (
      <Link to={`/${viewer?.group.toLowerCase() || 'student'}/classes/${classId}/messages`}>
        <Container color={'@text-color-secondary'}>
          <MailOutlined className={'icon'} />
          {pluralize('unread message', unreadThreads, true)}
        </Container>
      </Link>
    )
  } else {
    return null
  }
}
