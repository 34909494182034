import { useMutateHook } from '@thesisedu/feature-react'

import { FullAssignment } from './types'
import { CanDeleteAssignmentHook } from '../types'

export function useCanDeleteAssignment(assignment: FullAssignment) {
  const _canDeleteAssignment = useMutateHook<CanDeleteAssignmentHook>(
    'feature-assignments-react:can-delete-assignment',
    true,
    { assignment },
  )
  return assignment.canDelete && _canDeleteAssignment
}
