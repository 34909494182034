import { Form, VSpaced } from '@thesisedu/react'
import React from 'react'

import { QuickGradeField } from './QuickGradeField'
import { SubmissionGradeContent } from './SubmissionGradeContent'
import { SubmissionGradeHeader } from './SubmissionGradeHeader'
import { SubmissionGradeItemGrade } from './SubmissionGradeItemGrade'
import { SubmissionGradeRubric } from './SubmissionGradeRubric'
import { useSaveAssignmentSubmissionGrade } from './useSaveAssignmentSubmissionGrade'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { GradingModalSubmissionContextProvider } from '../contexts/GradingModalSubmissionContext'
import { GeneratedAssignmentSubmissionDirtyStatus } from '../generated/GeneratedAssignmentSubmissionDirtyStatus'
import { useGeneratedAssignmentReactResource } from '../generated/useGeneratedAssignmentReactResource'
import { AssignmentGradingMode, FullAssignmentSubmissionFragment } from '../schema'

export interface AssignmentSubmissionProps {
  submission: FullAssignmentSubmissionFragment
}
export interface MaybeAssignmentSubmissionProps extends Partial<AssignmentSubmissionProps> {
  studentId: string
  classId: string
}
export function AssignmentSubmission(props: MaybeAssignmentSubmissionProps) {
  const { submission, studentId, classId } = props
  const { assignment } = useGradingModalContext(true)
  const { onSave, loading } = useSaveAssignmentSubmissionGrade({
    studentId,
    classId,
    assignmentId: assignment.id,
    submission,
  })
  const generatedResource = useGeneratedAssignmentReactResource(assignment)
  let content

  if (assignment.gradingMode === AssignmentGradingMode.Rubric && assignment.rubric) {
    content = (
      <SubmissionGradeRubric rubric={assignment.rubric} disabled={!!submission?.deletedAt} />
    )
  } else if (generatedResource) {
    // TODO: generatedResource compatibility with viewing multiple attempts...?
    const RenderSubmission = generatedResource.renderAssignmentSubmission
    content = (
      <VSpaced space={'@size-m'} align={'stretch'}>
        <GeneratedAssignmentSubmissionDirtyStatus
          assignmentId={assignment.id}
          studentId={studentId}
          classId={classId}
        />
        <RenderSubmission assignment={assignment} {...props} />
        <SubmissionGradeItemGrade
          rubricId={'all'}
          gradeContent={
            <Form.Item name={'grades.all'}>
              <QuickGradeField totalPoints={assignment.totalPoints} />
            </Form.Item>
          }
        />
      </VSpaced>
    )
  } else {
    content = (
      <SubmissionGradeItemGrade
        rubricId={'all'}
        gradeContent={
          <Form.Item name={'grades.all'}>
            <QuickGradeField totalPoints={assignment.totalPoints} />
          </Form.Item>
        }
      />
    )
  }

  return (
    <GradingModalSubmissionContextProvider
      assignment={assignment}
      submission={submission}
      onSave={onSave}
      loading={loading}
    >
      <SubmissionGradeContent
        header={
          <SubmissionGradeHeader
            submission={submission}
            studentId={studentId}
            {...generatedResource?.submissionHeaderOptions}
          />
        }
      >
        {content}
      </SubmissionGradeContent>
    </GradingModalSubmissionContextProvider>
  )
}
