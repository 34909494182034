export interface DateFormatConfig extends Intl.DateTimeFormatOptions {
  extends?: PresetDateFormat | PresetDateFormat[]
}
export type PresetDateFormat =
  /** MMMM D, YYYY h:mm a */
  | 'dateTime'
  /** MMMM D, YYYY */
  | 'date'
  /** MM/DD/YYYY */
  | 'shortDate'
  /** MM/DD/YYYY h:mm A */
  | 'shortDateTime'
  /** MM/DD/YYYY hh:mm:ss */
  | 'shortDateTimeWithSeconds'
  /** h:mm a */
  | 'time'
  /** h:mm:ss a */
  | 'timeWithSeconds'
export type DateFormat = DateFormatConfig | PresetDateFormat

export const PRESET_FORMATS: Record<PresetDateFormat, DateFormatConfig> = {
  dateTime: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  date: { year: 'numeric', month: 'long', day: 'numeric' },
  shortDate: { year: 'numeric', month: '2-digit', day: '2-digit' },
  shortDateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  shortDateTimeWithSeconds: { extends: 'shortDateTime', hour12: false, second: 'numeric' },
  time: { hour: 'numeric', minute: 'numeric' },
  timeWithSeconds: { extends: 'time', second: 'numeric' },
}

function _resolveConfig(config: DateFormatConfig): Intl.DateTimeFormatOptions {
  if (config.extends) {
    const arrayExtends: PresetDateFormat[] = Array.isArray(config.extends)
      ? config.extends
      : [config.extends]
    const finalConfig = arrayExtends.reduce<Intl.DateTimeFormatOptions>((acc, ext) => {
      const extendsConfig = _resolveConfig(PRESET_FORMATS[ext])
      if (!extendsConfig) {
        throw new Error(`Extends preset: '${ext}' is invalid.`)
      }
      return { ...acc, ...extendsConfig }
    }, {})
    return {
      ...finalConfig,
      ...config,
    }
  } else return config
}

export function getFormatter(format: DateFormatConfig): Intl.DateTimeFormat {
  return new Intl.DateTimeFormat(undefined, _resolveConfig(format))
}

// Re-use the instances for performance: https://blog.david-reess.de/posts/hBEx9w-on-number-formatting-and-performance
const PRESET_INSTANCES = (Object.keys(PRESET_FORMATS) as PresetDateFormat[]).reduce<
  Partial<Record<PresetDateFormat, Intl.DateTimeFormat>>
>((acc, format) => {
  return {
    ...acc,
    [format]: getFormatter(PRESET_FORMATS[format]),
  }
}, {}) as Record<PresetDateFormat, Intl.DateTimeFormat>

export function formatDate(date: Date | string, format: DateFormat): string
export function formatDate(
  date: Date | string | undefined | null,
  format: DateFormat,
): string | null
export function formatDate(
  date: Date | string | undefined | null,
  format: DateFormat,
): string | null {
  if (date !== undefined && date !== null) {
    const _date = typeof date === 'string' ? new Date(date) : date
    try {
      if (typeof format === 'string') {
        if (!PRESET_INSTANCES[format]) {
          throw new Error(`Format preset: '${format}' is invalid.`)
        }
        return PRESET_INSTANCES[format].format(_date)
      } else {
        // This is not as performant as the route above.
        return new Intl.DateTimeFormat(undefined, _resolveConfig(format)).format(_date)
      }
    } catch {
      return null
    }
  } else return null
}

const relativeFormatter = new Intl.RelativeTimeFormat(undefined, {
  numeric: 'auto',
})
const ONE_MINUTE = 60
const ONE_HOUR = ONE_MINUTE * 60
const ONE_DAY = ONE_HOUR * 24
const ONE_WEEK = ONE_DAY * 7
const ONE_MONTH = ONE_DAY * 30
const ONE_YEAR = ONE_DAY * 365

export function formatRelativeDate(date: Date | string, dateFormat: DateFormat = 'dateTime') {
  const now = new Date()
  const normalizedDate = date instanceof Date ? date : new Date(date)
  const diffInSeconds = Math.floor((normalizedDate.getTime() - now.getTime()) / 1000)
  const absDiff = Math.abs(diffInSeconds)

  if (absDiff < ONE_MINUTE) {
    return relativeFormatter.format(diffInSeconds, 'second')
  } else if (absDiff < ONE_HOUR) {
    return relativeFormatter.format(Math.floor(diffInSeconds / ONE_MINUTE), 'minute')
  } else if (absDiff < ONE_DAY) {
    return relativeFormatter.format(Math.floor(diffInSeconds / ONE_HOUR), 'hour')
  } else if (absDiff < ONE_WEEK) {
    return relativeFormatter.format(Math.floor(diffInSeconds / ONE_DAY), 'day')
  } else if (absDiff < ONE_MONTH) {
    return relativeFormatter.format(Math.floor(diffInSeconds / ONE_WEEK), 'week')
  } else if (absDiff < ONE_YEAR) {
    return relativeFormatter.format(Math.floor(diffInSeconds / ONE_MONTH), 'month')
  } else {
    return formatDate(date, dateFormat)
  }
}
