import { styled } from '@thesisedu/web'
import React from 'react'

import { KeyboardProps } from './Keyboard'
// @ts-ignore
import { ReactComponent as Rest } from '../../assets/sketch/sheet-music/rest-quarter.svg'

export type RestButtonProps = Pick<KeyboardProps, 'wrapNote'>
export function RestButton({ wrapNote: WrapNote }: RestButtonProps) {
  if (WrapNote) {
    return (
      <WrapNote pitch={undefined}>
        <RestDraggable children={<Rest />} />
      </WrapNote>
    )
  } else {
    return null
  }
}

const RestDraggable = styled.div`
  border-radius: ${props => props.theme['@border-radius-base']};
  cursor: pointer;
  background: ${props => props.theme['@background-color-base']};
  padding: ${props => props.theme['@padding-sm']};
  width: ${props => props.theme['@size-xl']};
  transition: background 0.1s linear;
  &:hover {
    background: ${props => props.theme['@gray-2']};
  }
  svg {
    height: ${props => props.theme['@size-xl']};
    width: auto;
    margin: 0 auto;
    display: block;
  }
`
