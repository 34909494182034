import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { AssignGrade } from './AssignGrade'
import { AutoGrade } from './AutoGrade'
import { ResetSubmissions } from './ResetSubmissions'
import { FullAssignment } from '../types'

const ACTIONS: React.FC<React.PropsWithChildren<AssignmentBulkActionItemProps>>[] = [
  AssignGrade,
  AutoGrade,
  ResetSubmissions,
]

export interface BulkActionSelection {
  studentId: string
  classId: string
  groupName?: string
  submissionId?: string
}
export type AssignmentBulkActionItemProps = AssignmentBulkActionsProps
export interface AssignmentBulkActionsProps {
  selections: BulkActionSelection[]
  assignment: FullAssignment
  totalPoints: number
}
export function AssignmentBulkActions(props: AssignmentBulkActionsProps) {
  return (
    <Dropdown.Container>
      <Dropdown.Button children={'Bulk Actions'} disabled={!props.selections.length} />
      <Dropdown.Menu side={'bottom'} align={'start'}>
        {ACTIONS.map((ActionComponent, index) => (
          <ActionComponent key={index} {...props} />
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
