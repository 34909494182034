import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'

import { useEditorContext } from '../../context/EditorContext'

export function useOnChangePropagate() {
  const [editor] = useLexicalComposerContext()
  const { onChangeRef } = useEditorContext(true)
  React.useLayoutEffect(() => {
    // Trigger the parent editor to update whenever this editor updates.
    return editor.registerUpdateListener(() => {
      if (editor._parentEditor && onChangeRef.current) {
        onChangeRef.current(editor._parentEditor.getEditorState().toJSON())
      }
    })
  }, [editor])
}
