import React from 'react'

import { OverlayProvider } from './OverlayContext'
import { Toast$, Tooltip$ } from '../'
import { UseToastProvider } from '../components/Toast/useToast'
import { Theme } from '../style'
import { ThemeProvider } from '../style/ThemeProvider'
import { DEFAULT_THEME } from '../style/defaults'

export interface UIProviderProps {
  children: React.ReactElement
  theme?: Theme
}
export function UIProvider({ children, theme = DEFAULT_THEME }: UIProviderProps) {
  return (
    <ThemeProvider isRoot theme={theme}>
      <OverlayProvider>
        <Tooltip$.Provider>
          <Toast$.Provider>
            <UseToastProvider>{children}</UseToastProvider>
            <Toast$.Viewport />
          </Toast$.Provider>
        </Tooltip$.Provider>
      </OverlayProvider>
    </ThemeProvider>
  )
}
