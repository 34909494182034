import { PreloadQueryResource } from '@thesisedu/feature-apollo-react'
import { FeatureReactRoot } from '@thesisedu/feature-react'

import { getSearchQueryDocument } from './getSearchQueryDocument'
import { OrderDirection, SearchQueryVariables } from './schema'

export interface GetPreloadResourceOpts extends SearchQueryVariables {
  required?: boolean
}
export function getPreloadResource(
  root: FeatureReactRoot<any>,
  identifier: string,
  { required, ...variables }: GetPreloadResourceOpts,
): PreloadQueryResource<SearchQueryVariables> {
  return {
    type: 'PreloadQuery',
    identifier,
    document: () => {
      return getSearchQueryDocument(root)
    },
    variables: {
      first: 32,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Desc,
      query: undefined,
      ...variables,
    },
    required,
  }
}
