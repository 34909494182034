import { useApolloClient } from '@apollo/client'
import React from 'react'

import {
  loadAllResults,
  LoadAllResultsOpts,
  LoadAllResultsResult,
  LoadAllResultsVariables,
} from './loadAllResults'

export interface UseLoadAllResults<ResultFragment> {
  loading: boolean
  current: number | null
  totalCount: number | null
  items: ResultFragment[]
}
export function useLazyLoadAllResults<
  ResultFragment,
  Variables extends LoadAllResultsVariables,
  QueryResult,
>({
  document,
  getResult,
  variables: _variables,
  ...opts
}: Omit<LoadAllResultsOpts<ResultFragment, Variables, QueryResult>, 'client' | 'onProgress'>): [
  (variables?: Omit<Variables, 'after'>) => LoadAllResultsResult<ResultFragment>,
  UseLoadAllResults<ResultFragment>,
] {
  const client = useApolloClient()
  const [result, setResult] = React.useState<UseLoadAllResults<ResultFragment>>({
    loading: true,
    current: null,
    totalCount: null,
    items: [],
  })
  const resultRef = React.useRef<LoadAllResultsResult<ResultFragment>>()
  React.useEffect(() => {
    return () => resultRef.current?.cancel()
  }, [])

  return [
    variables => {
      resultRef.current?.cancel()
      setResult({
        loading: true,
        totalCount: null,
        current: null,
        items: [],
      })
      resultRef.current = loadAllResults<ResultFragment, Variables, QueryResult>({
        ...opts,
        client,
        document,
        getResult,
        variables: {
          ..._variables,
          ...variables,
        } as Omit<Variables, 'after'>, // This type isn't _technically_ correct.
        progress({ items, totalCount }) {
          setResult({
            items,
            current: items.length,
            totalCount,
            loading: true,
          })
        },
      })

      resultRef.current.then(r => {
        setResult({
          items: r,
          current: r.length,
          totalCount: r.length,
          loading: false,
        })
      })

      return resultRef.current
    },
    result,
  ]
}

export function useLoadAllResults<
  ResultFragment,
  Variables extends LoadAllResultsVariables,
  QueryResult,
>(
  opts: Omit<LoadAllResultsOpts<ResultFragment, Variables, QueryResult>, 'client' | 'onProgress'>,
): UseLoadAllResults<ResultFragment> & {
  loadAll: (variables?: Omit<Variables, 'after'>) => LoadAllResultsResult<ResultFragment>
} {
  const [loadAll, result] = useLazyLoadAllResults<ResultFragment, Variables, QueryResult>(opts)
  React.useEffect(() => {
    if (!(opts as any).skip) {
      loadAll()
    }
  }, [
    opts.document,
    JSON.stringify(opts.variables),
    JSON.stringify(opts.context),
    (opts as any).skip,
  ])

  return { ...result, loadAll }
}
