import { ApolloReactFeature } from '@thesisedu/feature-apollo-react'
import {
  DeepLinkResolvedContext,
  DeepLinkResolvedPayload,
  ReactFeature,
  ReactFeatureDependencies,
  ReactHooks,
  ReactUse,
} from '@thesisedu/feature-react'

import { debug, warn } from './log'
import { updateNotificationsMutation } from './queries/useReadNotificationsMutation'
import {
  ReadNotificationsDocument,
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables,
} from './schema'

export class NotificationDbReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-notification-db-react'
  static path = __dirname
  static requires = []

  constructor(options: object, deps: ReactFeatureDependencies) {
    super(options, deps)
    this.hookManager.registerMutateHook<DeepLinkResolvedPayload, DeepLinkResolvedContext>(
      ReactHooks.DeepLinkResolved,
      async (resolution, context) => {
        const apollo = this.root.requireFeature<ApolloReactFeature>(ApolloReactFeature.package)
        debug('marking notification read for path %s', context!.state.cleanPath)
        apollo.client
          .mutate<ReadNotificationsMutation, ReadNotificationsMutationVariables>({
            mutation: ReadNotificationsDocument,
            variables: {
              input: {
                pathNotifications: [{ universalPath: context!.state.cleanPath, read: true }],
              },
            },
            update: updateNotificationsMutation,
          })
          .catch(err => {
            warn('error marking notification read for path %s', context!.state.cleanPath)
            warn(err)
          })
        // TODO: Call the read notification with the path, but don't wait for the promise.
        return resolution
      },
    )
  }

  public reactResources() {
    require('./hooks/shellTopRight').default(this)
  }
}

export const notificationDbReactFeature: ReactUse = opts => [NotificationDbReactFeature, opts]
