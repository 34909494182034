import React from 'react'

import { useDevToolsContext } from '.'
import { useInternalDevToolsContext } from './context'
import { DevToolsTabResource } from './types'
import { useResource } from '..'

export function useTab(identifier: string, data?: Record<string, any>) {
  const { setTabDisabled } = useDevToolsContext(false) || {}
  React.useEffect(() => {
    if (setTabDisabled) {
      setTabDisabled(identifier, false, data)
      return () => {
        setTabDisabled(identifier, true, data)
      }
    }
  }, [data])
}

export function getTabDisabled(
  state: Record<string, boolean | Record<string, any>>,
  tab: DevToolsTabResource,
) {
  return tab.disableDefault ? state[tab.identifier] !== false : !!state[tab.identifier]
}

export function useTabData<Data extends Record<string, any>>(identifier: string): Data | undefined {
  const { tabData } = useInternalDevToolsContext(true)
  return tabData[identifier]
}

export function useDevToolsTabs() {
  const tabs = useResource<DevToolsTabResource>('DevToolsTab')
  const { tabEnabledState } = useInternalDevToolsContext(true)
  const validTabs = tabs.filter(tab => !getTabDisabled(tabEnabledState, tab))
  const [activeKey, setActiveKey] = React.useState(validTabs[0]?.identifier || '')
  const realActiveKey =
    activeKey && validTabs.some(tab => tab.identifier === activeKey) ? activeKey : ''
  React.useEffect(() => {
    const validTabs = tabs.filter(tab => !getTabDisabled(tabEnabledState, tab))
    if (!realActiveKey && validTabs.length) {
      setActiveKey(validTabs[0].identifier)
    }
  }, [tabEnabledState])

  return {
    activeKey: realActiveKey,
    setActiveKey,
    tabs: tabs.map(tab => ({
      ...tab,
      disabled: getTabDisabled(tabEnabledState, tab),
    })),
    CurrentTab: tabs.find(tab => tab.identifier === activeKey)?.component,
  }
}
