import * as React from 'react'

function SvgA(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#7A569F'}
          d={
            'M255.048 194.75a64.654 64.654 0 01.852 14.643 80.331 80.331 0 01-2.723 18.437 53.3 53.3 0 01-14.92 24.678c-8.438 8.226-18.498 13.82-29.041 18.631a156.897 156.897 0 01-42.41 12.226 239.622 239.622 0 01-31.497 2.612c-2.425.052-4.84 0-7.194 0a245.812 245.812 0 01-36.08-2.18c-21.158-2.838-41.187-9.11-59.602-20.123a74 74 0 01-20.09-17.069 53.753 53.753 0 01-11.006-25.16 88.976 88.976 0 01-1.212-20.083 51.964 51.964 0 0112.496-30.847c3.668-4.401 8.067-8.01 12.455-11.63a25.05 25.05 0 004.48-4.37 7.755 7.755 0 001.86-4.37 31.707 31.707 0 012.58-11.064c5.929-14.395 10.522-29.408 17.017-43.639A87.755 87.755 0 0167.537 70.62c.843-.863 1.685-1.727 2.59-2.54 2.856-2.714 5.98-5.14 8.827-7.989 4.193-4.113 9.567-6.416 15.065-8.319a96.544 96.544 0 0125.506-4.75 115.097 115.097 0 0129.585 1.902c6.7 1.347 13.287 3.167 19.216 6.602 11.448 6.621 21.509 14.827 28.846 26.045a121.784 121.784 0 0111.52 22.776c4.552 11.825 8.909 23.711 13.513 35.526a29.176 29.176 0 012.23 9.614 9.606 9.606 0 003.226 6.427c2.898 2.858 6.228 5.264 9.3 7.969 9.383 8.349 15.672 18.477 18.087 30.868z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.576 211.049c5.826 22.539 22.875 44.76 46.942 48.924a43.71 43.71 0 0018.178-.648c2.867-.72 1.655-5.141-1.233-4.442-21.087 5.326-41.105-8.494-51.587-26.025a72.403 72.403 0 01-7.86-19.033c-.74-2.869-5.19-1.645-4.44 1.224zm234.062.257c-5.426 20.924-20.85 41.397-43.16 45.592a38.256 38.256 0 01-16.34-.524c-2.877-.73-4.11 3.712-1.223 4.442a45.52 45.52 0 0031.713-3.97c10.492-5.315 19.073-14.127 25.074-24.184a77.237 77.237 0 008.334-20.133c.74-2.869-3.7-4.113-4.44-1.223h.042z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.13 52.554h25.526v10.015H117.13z'} />
        <path
          fill={'#7A569F'}
          d={
            'M131.24 3.265c-17.391 0-31.575 11.877-31.575 26.657 0 10.67 7.475 20.142 18.759 24.374a36.57 36.57 0 0012.787 2.283c3.353.002 6.694-.45 9.929-1.342 12.802-3.563 21.674-13.696 21.674-25.315 0-14.78-14.184-26.657-31.575-26.657z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.24 5.845c16.056 0 28.994 10.834 28.994 24.077 0 10.38-8.037 19.56-19.784 22.829a34.657 34.657 0 01-9.24 1.248 33.987 33.987 0 01-11.884-2.12c-10.327-3.874-17.08-12.432-17.08-21.957 0-13.243 12.937-24.077 28.993-24.077z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M113.437 47.039l17.716-38.075 17.09 38.075h-7.198l-3.499-8.104h-13.277l-3.714 8.104h-7.118zm17.6-23.066l-3.971 8.694h7.761l-3.79-8.694z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.808 165.61h.175c.082 18.416-14.264 33.398-32.155 33.686-17.911-.114-32.4-14.972-32.483-33.388h.175c0 6.653 14.202 11.949 31.856 12.02h.76c17.583-.226 31.703-5.665 31.672-12.318z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.426 125.21a7.99 7.99 0 01-4.931 7.382 7.981 7.981 0 01-8.703-1.736 7.993 7.993 0 01-1.724-8.71 7.985 7.985 0 017.384-4.925c2.117 0 4.146.842 5.642 2.34a7.982 7.982 0 012.332 5.65zm59.345 0a7.99 7.99 0 01-4.931 7.382 7.981 7.981 0 01-8.703-1.736 7.993 7.993 0 01-1.724-8.71 7.985 7.985 0 017.384-4.925c2.117 0 4.147.842 5.642 2.34a7.982 7.982 0 012.332 5.65zM99.188 95.237a18.825 18.825 0 019.156.267.78.78 0 00.596-1.44c-6.507-3.66-14.723-1.866-19.114 4.175a.792.792 0 001.151 1.028 18.626 18.626 0 018.21-4.03zm61.585-.71a18.886 18.886 0 017.851 4.74.78.78 0 001.223-.966c-3.877-6.382-11.916-8.848-18.702-5.738a.792.792 0 00.472 1.491c3.045-.6 6.19-.438 9.156.473zm-30.952 57.767a10.53 10.53 0 014.943 1.255.442.442 0 00.504-.72 8.217 8.217 0 00-10.883 0 .442.442 0 00.493.72 10.395 10.395 0 014.943-1.255z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgA = React.memo(SvgA)
export default MemoSvgA
