import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { ApolloClient, useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import React from 'react'

import { error } from '../../log'
import * as schema from '../../schema'
import { useViewerContext } from '../../useViewerContext'

async function _getMoreLogs(
  client: ApolloClient<any>,
  input: schema.DetailedLogsForUserInput,
  lastKey?: string,
) {
  const result = await client.query<
    schema.ListUserDetailedLogsQuery,
    schema.ListUserDetailedLogsQueryVariables
  >({
    query: schema.ListUserDetailedLogsDocument,
    variables: {
      input,
      first: 1000,
      after: lastKey,
    },
  })
  return result.data.userDetailedLogs
}

export function useUserDetailedLogs() {
  const client = useApolloClient()
  const { coreAuthKey } = useImpersonation()
  const user = useViewerContext(true)
  const [detailedLogs, setDetailedLogs] = React.useState<schema.DetailedLogFragment[]>([])
  const [fetchingInitial, setFetchingInitial] = React.useState(true)

  // Query...
  React.useEffect(() => {
    let shouldContinueFetching = true
    if (coreAuthKey) {
      const run = async () => {
        const input: schema.DetailedLogsForUserInput = {
          id: user.id,
          jwt: coreAuthKey,
        }
        let hasNextPage = true
        let lastKey: string | undefined = undefined
        while (shouldContinueFetching && hasNextPage) {
          const result: schema.ListUserDetailedLogsQuery_userDetailedLogs_DetailedLogsConnection =
            await _getMoreLogs(client, input, lastKey)
          setDetailedLogs(l => [...l, ...result.logs])
          hasNextPage = result.hasMore
          lastKey = result.lastKey || undefined
        }

        setFetchingInitial(false)
      }
      run().catch(err => {
        error('error when fetching initial logs')
        error(err)
      })

      return () => {
        shouldContinueFetching = false
      }
    }
  }, [!!coreAuthKey, user.id])

  // Subscription...
  schema.useUserDetailedLogsSubscription({
    variables: {
      input: {
        id: user.id,
        jwt: coreAuthKey || '',
      },
    },
    skip: !coreAuthKey,
    onSubscriptionData({ subscriptionData }) {
      const log = subscriptionData.data?.userDetailedLogs
      if (log) {
        setDetailedLogs(l => [...l, log])
      }
    },
  })

  return { detailedLogs, fetchingInitial }
}
