import React from 'react'

import { MediaFragment } from '../schema'

export interface MediaContextValue {
  media: MediaFragment
}
export const MediaContext = React.createContext<MediaContextValue | undefined>(undefined)

export interface MediaProviderProps {
  children: React.ReactNode
  media: MediaFragment
}
export function MediaProvider({ children, media }: MediaProviderProps) {
  return <MediaContext.Provider value={{ media }} children={children} />
}

export function useMediaContext(): MediaContextValue | undefined
export function useMediaContext(require: false): MediaContextValue | undefined
export function useMediaContext(require: true): MediaContextValue
export function useMediaContext(require?: boolean): MediaContextValue | undefined {
  const context = React.useContext(MediaContext)
  if (!context && require) {
    throw new Error('MediaContext is required, yet not provided.')
  }
  return context
}
