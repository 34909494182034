import { WebContext } from '@thesisedu/feature-web'
import { useContext } from 'react'

import { SegmentTypeResource } from '../types'

export function useSegmentResources() {
  const { featureWeb } = useContext(WebContext)!
  return featureWeb.deps.resources.getResourcesForType<SegmentTypeResource>('SegmentType')
}

export function useSegmentResource(type: string) {
  const { featureWeb } = useContext(WebContext)!
  const resource = featureWeb.deps.resources.getResource<SegmentTypeResource>('SegmentType', type)
  if (!resource) {
    throw new Error(`Cannot find resource for type ${type}`)
  }
  return resource
}
