import { FileField } from '@thesisedu/feature-attachments-react'
import { Button, Drawer, Form, Input, InputNumber } from 'antd'
import React from 'react'

import { Draggable } from './types'

export interface DraggableOptionsDrawerProps {
  draggable: Draggable
  onChange: (draggable: Draggable) => void
  visible?: boolean
  onClose?: () => void
}
export function DraggableOptionsDrawer({
  visible,
  onClose,
  draggable,
  onChange,
}: DraggableOptionsDrawerProps) {
  const [form] = Form.useForm<Draggable>()
  React.useEffect(() => {
    form.setFieldsValue(draggable)
  }, [draggable])
  return (
    <Drawer visible={visible} onClose={form.submit} width={400} title={'Draggable Settings'}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          onChange({
            ...draggable,
            ...values,
          })
          if (onClose) onClose()
        }}
      >
        <Form.Item name={'imagePath'} label={'Image'}>
          <FileField
            uploadProps={{
              accept: 'image/*',
              isPublic: true,
              maxFileSizeBytes: 2e6, // 2MB
              uploadMessage: 'Attach a new image here.',
            }}
          />
        </Form.Item>
        <Form.Item
          name={'title'}
          label={'Title'}
          extra={'This is used as the alternate text for the image.'}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={'gifPlaybackLoopTimes'}
          label={'GIF Playback Loop'}
          extra={'How many times should the GIF playback loop when clicked / picked up?'}
        >
          <InputNumber precision={0} min={0} />
        </Form.Item>
        <Form.Item
          name={'audioPath'}
          label={'Audio'}
          extra={'This will play when the item is picked up.'}
        >
          <FileField
            uploadProps={{
              accept: 'audio/mpeg',
              isPublic: true,
              maxFileSizeBytes: 2e6, // 2MB
              uploadMessage: 'Attach a new sound here.',
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} onClick={form.submit}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}
