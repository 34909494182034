import { LoadingOutlined } from '@ant-design/icons'
import { useDeepLink } from '@thesisedu/feature-react'
import { IconContainer as GridIconContainer } from '@thesisedu/react/dist/grid'
import {
  styled,
  Block,
  BodyLarge,
  AntIconWrapper as Aiw,
  BodySmall,
  FontWeight,
} from '@thesisedu/web'
import { message, Empty } from 'antd'
import { NavArrowRight } from 'iconoir-react'
import React from 'react'

import { error } from '../log'
import { SearchResultNode, RenderList } from '../types'
import { useSearchResultRenderer, useSearchResultLinkHandler } from '../useSearchResultRenderer'

export interface SearchResultListProps {
  results: SearchResultNode[]
  onClick?: () => void
}
export function SearchResultList({ results, onClick }: SearchResultListProps) {
  const { navigate, linkHandler } = useDeepLink({ onError: message.error })
  if (results.length) {
    return (
      <>
        {linkHandler}
        {results.map(result => (
          <SearchResultListItemRenderer
            item={result}
            key={result.id}
            onClick={async path => {
              await navigate(path)
              if (onClick) {
                onClick()
              }
            }}
          />
        ))}
      </>
    )
  } else {
    return <Empty description={'No results found!'} />
  }
}

interface SearchResultListItemRendererProps {
  item: SearchResultNode
  onClick: (path: string) => Promise<void> | void
}
function SearchResultListItemRenderer({ item, onClick }: SearchResultListItemRendererProps) {
  const Renderer = useSearchResultRenderer(
    'renderList',
    item.__typename,
  ) as RenderList<SearchResultNode>
  const getLink = useSearchResultLinkHandler(item.__typename)
  return Renderer ? (
    <Renderer
      item={item}
      onClick={async () => {
        if (onClick && getLink) {
          const path = getLink(item)
          if (path) {
            await onClick(path)
          }
        }
      }}
    />
  ) : null
}

export interface SearchResultListItemProps {
  title: string
  type: string
  subtitle?: string | React.ReactElement
  thumbnail?: React.ReactElement
  onClick: () => void | Promise<void>
}
export function SearchResultListItem({
  title,
  type,
  subtitle,
  thumbnail,
  onClick,
}: SearchResultListItemProps) {
  const [loading, setLoading] = React.useState(false)
  return (
    <Container
      onClick={async () => {
        setLoading(true)
        try {
          await onClick()
        } catch (err) {
          error('error when clicking on search result')
          error(err)
        } finally {
          setLoading(false)
        }
      }}
      className={loading ? 'loading' : undefined}
    >
      {thumbnail ? <ThumbnailContainer children={thumbnail} /> : null}
      <ContentContainer>
        <BodySmall weight={FontWeight.Bold}>{type}</BodySmall>
        <Block marginTop={'@size-xxs'}>
          <BodyLarge style={{ lineHeight: 1.4 }}>{title}</BodyLarge>
        </Block>
        {subtitle ? <BodySmall color={'@text-color-secondary'}>{subtitle}</BodySmall> : null}
      </ContentContainer>
      <IconContainer>
        {loading ? <LoadingOutlined /> : <Aiw children={<NavArrowRight />} />}
      </IconContainer>
    </Container>
  )
}

const IconContainer = styled.div`
  font-size: ${props => props.theme['@size-xm']};
  color: ${props => props.theme['@primary-color']};
  transition: color 0.25s linear;
`
const ContentContainer = styled.div`
  min-width: 0;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.25s linear;
  opacity: 1;
  margin: ${props => props.theme['@size-m']} 0;
  &.loading {
    opacity: 0.5;
    pointer-events: none;
    ${IconContainer} {
      color: ${props => props.theme['@text-color-secondary']};
    }
  }
  > div:last-child {
    margin-left: auto;
  }
  > * > * {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
const ThumbnailContainer = styled.div`
  width: 135px;
  flex-shrink: 0;
  margin-right: ${props => props.theme['@size-s']};
  ${GridIconContainer.toString()} {
    width: ${props => props.theme['@size-xl']};
    height: ${props => props.theme['@size-xl']};
    font-size: ${props => props.theme['@size-m']};
  }
`
