import { NotFound, PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom'

import { CreateTemplateButton } from './CreateTemplateButton'
import { EditWidgetTemplate } from './EditWidgetTemplate'
import { WidgetTemplateList } from './WidgetTemplateList'

export function WidgetTemplatesRoute() {
  useBreadcrumb({ title: 'Templates' })
  return (
    <Routes>
      <Route path={'/:widgetTemplateId'} element={<Edit />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}

function List() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Widget Templates'} />
      <PageHeader title={'Widget Templates'}>
        <CreateTemplateButton afterCreate={template => navigate(template.id)} />
      </PageHeader>
      <WidgetTemplateList />
    </>
  )
}

function Edit() {
  const { widgetTemplateId } = useParams()
  if (widgetTemplateId) {
    return <EditWidgetTemplate id={widgetTemplateId} />
  } else {
    return <NotFound description={'That widget template could not be found.'} />
  }
}
