import { Tooltip, Button, s, Button$ } from '@thesisedu/ui'
import React from 'react'

export interface TextFormatToolbarButtonProps {
  active?: boolean
  disabled?: boolean
  label: string
  ariaLabel: string
  icon: React.ReactElement
  variant?: Button$.ButtonVariant
  onClick?: () => void
}
function _TextFormatToolbarButton(
  {
    active,
    disabled,
    label,
    ariaLabel,
    icon,
    onClick,
    variant,
    ...rest
  }: TextFormatToolbarButtonProps,
  ref?: React.ForwardedRef<any>,
) {
  return (
    <Tooltip title={label} {...rest} ref={ref}>
      <Button
        variant={s.variants({
          ghost: !variant,
          selected: active,
          ...(variant ? { [variant]: true } : {}),
        })}
        disabled={disabled}
        onPress={onClick}
        icon={icon}
        aria-label={ariaLabel}
      />
    </Tooltip>
  )
}
export const TextFormatToolbarButton = React.forwardRef(_TextFormatToolbarButton)
