import React from 'react'

import { ReportDisplayMode } from './ReportActions'
import { ReportResultGraph } from './graph/ReportResultGraph'
import { ReportResultGrid } from './grid/ReportResultGrid'
import { ReportGridColorProvider } from './grid/colors/ColorContext'
import { RunReportQuery } from '../execute/types'
import { RunReportOpts } from '../types'

export interface ReportResultsProps {
  report: RunReportOpts
  onReportChange: (report: RunReportOpts, noNewTab?: boolean) => void
  data: RunReportQuery['runReport']
}
export function ReportResults(props: ReportResultsProps) {
  const displayMode = props.report.displayMode || ReportDisplayMode.Grid
  return (
    <ReportGridColorProvider>
      {displayMode === ReportDisplayMode.Grid ? (
        <ReportResultGrid {...props} />
      ) : (
        <ReportResultGraph
          {...props}
          graphConfig={props.report.graphOptions}
          onGraphConfigChange={graphConfig => {
            props.onReportChange(
              {
                ...props.report,
                graphOptions: graphConfig,
              },
              true,
            )
          }}
        />
      )}
    </ReportGridColorProvider>
  )
}
