import React from 'react'

import { SubmissionContextValue } from './SubmissionContext'

export interface PartialSubmissionContextValue
  extends Pick<SubmissionContextValue, 'onSubmissionCreated'> {}
export const PartialSubmissionContext = React.createContext<
  PartialSubmissionContextValue | undefined
>(undefined)

export interface PartialSubmissionProviderProps extends PartialSubmissionContextValue {
  children: React.ReactNode
}
export function PartialSubmissionProvider({ children, ...value }: PartialSubmissionProviderProps) {
  return <PartialSubmissionContext.Provider value={value} children={children} />
}

export function usePartialSubmissionContext(): PartialSubmissionContextValue | undefined
export function usePartialSubmissionContext(
  require: false,
): PartialSubmissionContextValue | undefined
export function usePartialSubmissionContext(require: true): PartialSubmissionContextValue
export function usePartialSubmissionContext(
  require?: boolean,
): PartialSubmissionContextValue | undefined {
  const context = React.useContext(PartialSubmissionContext)
  if (!context && require) {
    throw new Error('PartialSubmissionContext is required, yet not provided.')
  }
  return context
}
