import { Feature } from '@thesisedu/feature'
import { DirectMessageUserSourceResource } from '@thesisedu/feature-direct-messages-react'

import { useUsers } from './useDirectMessageUsers'

export default function add(feature: Feature) {
  feature.resources.addResource<DirectMessageUserSourceResource>({
    type: 'DirectMessageUserSource',
    identifier: 'assignments-user-source',
    useUsers,
  })
}
