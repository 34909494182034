import { MediaGridItem, MediaListItem } from '@thesisedu/feature-media-react/search'
import { ReactFeature } from '@thesisedu/feature-react'
import {
  addSearch,
  RenderSearchItemProps,
  RenderSearchListItemProps,
  SearchResultCalendarItem,
} from '@thesisedu/feature-search-react'
import { useMediaGridItemProps } from '@thesisedu/feature-sharing-react/search'
import { MicSpeaking } from '@thesisedu/react/icons'
import React from 'react'

import { PracticeSearchResultFragmentDoc, PracticeSearchResultFragment } from '../schema'

function getPracticeName(item: PracticeSearchResultFragment) {
  return item.practiceName
    ? `${item.practiceName} by ${
        item.practiceStudent.user.name || item.practiceStudent.user.username
      }`
    : item.practiceStudent.user.name || item.practiceStudent.user.username
}

type PracticeResultNode = PracticeSearchResultFragment
function PracticeGridItem({ item }: RenderSearchItemProps<PracticeResultNode>) {
  return (
    <MediaGridItem
      media={item.practiceMedia}
      title={getPracticeName(item)}
      topLeftIcon={<MicSpeaking />}
      {...useMediaGridItemProps({ sharing: item.practiceSharing })}
    />
  )
}

function PracticeListItem({ onClick, item }: RenderSearchListItemProps<PracticeResultNode>) {
  return (
    <MediaListItem
      media={item.practiceMedia}
      title={getPracticeName(item)}
      type={'Practice'}
      onClick={onClick}
    />
  )
}

export default function addSearchResources(feature: ReactFeature) {
  addSearch(feature, PracticeSearchResultFragmentDoc, 'Practice', {
    renderGrid: PracticeGridItem,
    renderList: PracticeListItem,
    renderCalendar: ({ item, onClick }) => (
      <SearchResultCalendarItem
        title={item.practiceStudent.user.name || item.practiceStudent.user.username}
        type={'Practice'}
        onClick={onClick}
        color={'@red'}
      />
    ),
    getDate: item => item.practiceMedia.createdAt,
    getUniversalLink: item => `practices/${item.id}`,
  })
}
