import { useGetMediaUploadUrlMutation } from '../../../schema'
import { useRecordViewContext } from '../../RecordViewContext'

export interface UseGetMediaUploadUrlOpts {
  onError?: (err: any) => void
}
export function useGetMediaUploadUrl({ onError }: UseGetMediaUploadUrlOpts = {}) {
  const { classId, getMediaUploadUrl } = useRecordViewContext(true)
  const [getMediaUploadUrlMutate] = useGetMediaUploadUrlMutation({
    onError,
  })
  return (
    getMediaUploadUrl ??
    (async input => {
      try {
        const result = await getMediaUploadUrlMutate({
          variables: {
            input: {
              classId,
              ...input,
            },
          },
        })
        if (result?.data?.getMediaUploadUrl) {
          return {
            media: result.data.getMediaUploadUrl,
          }
        } else {
          return null
        }
      } catch (errs: any) {
        if ((errs.graphQLErrors || [])[0]?.extensions.code === 'LICENSE_INVALID_ERROR') {
          return {
            errors: errs.graphQLErrors,
          }
        } else {
          onError?.(errs)
          return null
        }
      }
    })
  )
}
