import { Dropdown } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { useClasses } from '../../ClassContext'
import { debug } from '../../log'
import { useInviteStudentMutation } from '../../queries/useInviteStudentMutation'
import { useTeacherClassLazyQuery } from '../../schema'
import {
  StudentActionProps,
  StudentActionResource,
} from '../../teacher/students/StudentActionsDropdown'

function InviteAction({ onAct, students }: StudentActionProps<Payload>) {
  const { classes, loading, error } = useClasses()
  const uniqueClassIds = [...new Set(students.map(s => s.classId))]
  const singleClassId = uniqueClassIds.length === 1 ? uniqueClassIds[0] : null
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<Plus />}>Invite to...</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        {loading || error ? (
          <Dropdown.Item disabled>
            {loading ? 'Loading classes...' : "Can't load classes"}
          </Dropdown.Item>
        ) : (
          classes
            .filter(cls => cls.id !== singleClassId && cls.canInvite && !cls.archivedAt)
            .map(cls => {
              return (
                <Dropdown.Item
                  key={cls.id}
                  onClick={() => {
                    onAct({ targetClassId: cls.id })
                  }}
                >
                  {cls.name}
                </Dropdown.Item>
              )
            })
        )}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

interface Payload {
  targetClassId: string
}
export default {
  type: 'StudentAction',
  identifier: 'Invite',
  actingLabel: 'Inviting students...',
  render: InviteAction,
  useAct({ payload }) {
    const [invite] = useInviteStudentMutation()
    const [fetchClass] = useTeacherClassLazyQuery()
    return async ({ studentId, classId }) => {
      debug('inviting student %s to %s', studentId, payload.targetClassId)
      const classResult = await fetchClass({ variables: { id: classId } })
      const cls = classResult.data?.node?.__typename === 'Class' ? classResult.data.node : undefined
      const student = cls?.students.edges.find(edge => edge.node.id === studentId)
      if (student) {
        debug('...with username %s', student.node.user.username)
        try {
          await invite({
            variables: {
              input: {
                id: payload.targetClassId,
                email: student.node.user.username,
              },
            },
          })
        } catch {
          // This means the student was already invited, so we're ignoring
          // the error.
        }
      } else {
        debug('...could not find student, skipping')
      }
    }
  },
} satisfies StudentActionResource<Payload>
