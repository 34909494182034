import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateDirectMessageThreadMutation as useMutation } from '../schema'

export const useCreateDirectMessageThreadMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that thread.'),
    refetchQueries: ['directMessageThreads'],
    ...opts,
  })
