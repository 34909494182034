import { UserSettingsPageResource } from '@thesisedu/feature-users-react'
import { PageHead } from '@thesisedu/react'
import { VerifiedUser } from '@thesisedu/react/icons'
import React from 'react'

import { TeacherID } from '../../TeacherID'
import { TeachersWebFeature } from '../../TeachersWebFeature'

function TeacherSettings() {
  return (
    <>
      <PageHead title={'Teacher'} />
      <TeacherID />
    </>
  )
}

export default function (feature: TeachersWebFeature) {
  feature.resources.addResource<UserSettingsPageResource>({
    type: 'UserSettingsPage',
    identifier: 'teachers',
    title: 'Teacher',
    icon: <VerifiedUser />,
    weight: 2,
    filter: viewer => viewer?.group === 'TEACHER',
    component: TeacherSettings,
  })
}
