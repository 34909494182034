import * as RadixAvatar from '@radix-ui/react-avatar'
import React from 'react'

import { HTMLProps, s, styled } from '../..'

export interface AvatarProps extends Omit<HTMLProps<HTMLDivElement>, 'size'> {
  size?: s.AnySize | number
  src?: string
  alt?: string
  children?: string
}
export function Avatar({ size = 32, src, alt, children, ...rest }: AvatarProps) {
  return (
    <Root
      {...rest}
      style={{ ...rest.style, fontSize: typeof size === 'string' ? s.size(size) : `${size}px` }}
    >
      {src ? <Image src={src} alt={alt} /> : null}
      {children ? <Fallback>{children}</Fallback> : null}
    </Root>
  )
}

const Root = styled(RadixAvatar.Root)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border-radius: 0.1em;
  background: ${s.color('element')};
  width: 1em;
  height: 1em;
`
const Fallback = styled(RadixAvatar.Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${s.color('element')};
  color: ${s.color('text')};
  letter-spacing: 0.05em;
  font-size: 0.5em;
  font-weight: bold;
  line-height: 1;
`
const Image = styled(RadixAvatar.Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`
