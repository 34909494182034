import { useStudentUser } from '@thesisedu/feature-classes-react'
import { useIds } from '@thesisedu/feature-react'
import { Empty, LoadingIndicator, NotFound } from '@thesisedu/react'
import { Clock, InfoEmpty, MinusCircle } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentSubmission } from './AssignmentSubmission'
import { FullAssignment } from './types'
import { usePastAttempt } from './usePastAttempt'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { useSubmitAssignmentMutation } from '../queries/useSubmitAssignmentMutation'
import { useUseAssignmentSubmissionMutation } from '../queries/useUseAssignmentSubmissionMutation'
import { QuestionAssignmentSubmission } from '../questions/QuestionAssignmentSubmission'
import {
  BasicAssignmentSubmissionFragment,
  FullAssignmentSubmissionFragment,
  OrderDirection,
  useAllAssignmentSubmissionsQuery,
  useAssignmentSubmissionQuery,
} from '../schema'

export interface AssignmentStudentProps {
  studentId: string
  classId: string
  submissionId?: string
}
export function AssignmentStudent(props: AssignmentStudentProps) {
  const { studentId, classId, submissionId } = props
  useIds([
    { id: studentId, label: 'Submission StudentId' },
    { id: classId, label: 'Submission ClassId' },
    ...(submissionId ? [{ id: submissionId, label: 'Submission Id' }] : []),
  ])
  const { assignment } = useGradingModalContext(true)
  const { submission: loadedSubmission, loading } = usePastAttempt(submissionId)
  const currentSubmission = (assignment.submissions || assignment.currentSubmissions).edges.find(
    sub => sub.node.studentId === studentId && sub.node.classId === classId && !sub.node.deletedAt,
  )?.node

  // We have to query for the submission here so we can get the submission data if we don't
  // have it already.
  const submissionQueryResult = useAssignmentSubmissionQuery({
    variables: { id: currentSubmission?.id ?? 'none' },
    skip: !!submissionId || !currentSubmission,
  })
  const loadedCurrentSubmission =
    submissionQueryResult.data?.node?.__typename === 'AssignmentSubmission'
      ? submissionQueryResult.data.node
      : undefined

  if (submissionId && !loadedSubmission) {
    if (loading) {
      return <LoadingIndicator block />
    } else {
      return <NotFound />
    }
  } else if (!submissionId && currentSubmission && submissionQueryResult.loading) {
    return <LoadingIndicator block />
  }

  return (
    <AssignmentStudentWithSubmission
      {...props}
      assignment={assignment}
      submission={submissionId ? loadedSubmission : loadedCurrentSubmission ?? currentSubmission}
    />
  )
}

interface AssignmentStudentWithSubmissionProps extends AssignmentStudentProps {
  submission?: FullAssignmentSubmissionFragment
  assignment: FullAssignment
}
function AssignmentStudentWithSubmission({
  submission,
  assignment,
  studentId,
  classId,
}: AssignmentStudentWithSubmissionProps) {
  const { student: studentUser } = useStudentUser(studentId, classId)
  const studentFirstName = studentUser?.user.firstName
  if (submission?.submittedAt) {
    if (assignment.configuration?.questions?.length && submission) {
      return <QuestionAssignmentSubmission submission={submission} />
    } else {
      return (
        <AssignmentSubmission submission={submission} studentId={studentId} classId={classId} />
      )
    }
  } else if (submission?.deletedAt) {
    return (
      <NoLongerEdit
        submission={submission}
        studentFirstName={studentFirstName}
        assignmentId={assignment.id}
      />
    )
  } else if (submission) {
    return <NotSubmitted submission={submission} studentFirstName={studentFirstName} />
  } else {
    return (
      <NotStarted
        studentId={studentId}
        classId={classId}
        assignment={assignment}
        studentFirstName={studentFirstName}
      />
    )
  }
}

interface NotStartedProps {
  studentId: string
  classId: string
  assignment: FullAssignment
  studentFirstName?: string | null
}
function NotStarted({ studentId, classId, assignment, studentFirstName }: NotStartedProps) {
  const { data, loading } = useAllAssignmentSubmissionsQuery({
    variables: {
      id: assignment.id,
      first: 20,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Desc,
      classId,
      studentId,
    },
  })
  const latestWorkingSubmission =
    data?.node?.__typename === 'Assignment'
      ? data.node.submissions.edges.find(edge => !edge.node.deletedAt)?.node
      : undefined

  if (latestWorkingSubmission) {
    return <NotSubmitted submission={latestWorkingSubmission} studentFirstName={studentFirstName} />
  } else if (loading) {
    return <LoadingIndicator block />
  } else {
    return (
      <>
        <AssignmentSubmission studentId={studentId} classId={classId} />
        <Empty
          icon={<InfoEmpty />}
          title={`${studentFirstName || 'This student'} has not started this assignment yet.`}
          description={
            <>
              Wait for {studentFirstName || 'them'} to start the assignment, or you can go ahead and
              assign them a grade using the fields above.
            </>
          }
        />
      </>
    )
  }
}

interface NotSubmittedProps {
  submission: BasicAssignmentSubmissionFragment
  studentFirstName?: string | null
}
function NotSubmitted({ submission, studentFirstName }: NotSubmittedProps) {
  const [submit] = useSubmitAssignmentMutation({
    variables: {
      classId: submission.classId,
      input: {
        id: submission.id,
        force: true,
      },
    },
    refetchQueries: ['allAssignmentSubmissions'],
    awaitRefetchQueries: true,
  })
  return (
    <Empty
      icon={<Clock />}
      title={`${studentFirstName || 'This student'} has not submitted this attempt yet.`}
      description={
        <>
          Wait for {studentFirstName || 'them'} to finish their attempt and submit it, and then you
          will be able to grade it.{' '}
          {submission.canSubmit ? (
            <>
              If you would like to grade it anyway, or drop it completely in favor of another
              attempt, you may submit the attempt for {studentFirstName || 'them'}.
            </>
          ) : null}
        </>
      }
      action={
        submission.canSubmit
          ? {
              title: `Submit Attempt for ${studentFirstName || 'Them'}`,
              async onClick() {
                await submit()
              },
            }
          : undefined
      }
    />
  )
}

interface NoLongerEditProps {
  studentFirstName?: string | null
  assignmentId: string
  submission: BasicAssignmentSubmissionFragment
}
function NoLongerEdit({ studentFirstName, submission, assignmentId }: NoLongerEditProps) {
  const [use] = useUseAssignmentSubmissionMutation({
    variables: {
      input: {
        id: assignmentId,
        submissionId: submission.id,
      },
    },
  })
  return (
    <Empty
      icon={<MinusCircle />}
      title={`${studentFirstName || 'This student'} can no longer edit this attempt.`}
      description={
        <>
          Another, previously submitted, attempt is currently being used as the student's
          submission. {studentFirstName || 'The student'} had already started work on this attempt,
          but no longer has access to it.{' '}
          {submission.canReset ? (
            <>You may allow {studentFirstName || 'them'} to continue working on this attempt.</>
          ) : null}
        </>
      }
      action={
        submission.canReset
          ? {
              title: `Allow ${studentFirstName || 'Them'} to Continue Working`,
              async onClick() {
                await use()
              },
            }
          : undefined
      }
    />
  )
}
