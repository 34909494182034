import { Dropdown } from '@thesisedu/ui'
import { AlignLeft, AlignCenter, AlignRight } from '@thesisedu/ui/icons'
import React from 'react'

import { SettingsDropdownEnumItem } from './SettingsDropdownEnumItem'

export type Alignment = 'left' | 'center' | 'right'
const IconMap: Record<Alignment, typeof AlignLeft> = {
  left: AlignLeft,
  center: AlignCenter,
  right: AlignRight,
}

export interface AlignmentSettingProps {
  alignment: Alignment
  onChange: (alignment: Alignment) => void
}
export function AlignmentSetting({ alignment, onChange }: AlignmentSettingProps) {
  const Icon = IconMap[alignment]
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<Icon />}>Alignment</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        {(Object.keys(IconMap) as Alignment[]).map(a => {
          return (
            <SettingsDropdownEnumItem
              value={a}
              key={a}
              selected={alignment === a}
              label={a[0].toUpperCase() + a.slice(1)}
              onChange={onChange}
            />
          )
        })}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}
