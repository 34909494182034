import { Format, notNil } from '@thesisedu/feature-utils'
import { Text, HSpaced } from '@thesisedu/ui'
import React from 'react'

import { Container } from './RubricEditor'
import { RubricGraderContext } from './RubricGraderContext'
import { RubricGraderCriterion } from './RubricGraderCriterion'
import { AssignmentRubricInput } from '../schema'

export interface RubricGraderValue {
  grades?: Record<string, number>
  comments?: Record<string, string>
}
export interface RubricGraderProps {
  rubric: AssignmentRubricInput
  value?: RubricGraderValue
  onChange: (value: RubricGraderValue) => void
  disabled?: boolean
}
export function RubricGrader({ rubric, disabled, value, onChange }: RubricGraderProps) {
  const maxPresets = Math.max(...rubric.categories.map(category => category.presets?.length ?? 0))
  const totalCategories = rubric.categories.length
  const totalGraded = Object.values(value?.grades ?? {}).filter(notNil).length
  const totalPoints = rubric.categories.reduce((acc, category) => acc + category.totalPoints, 0)
  const gradePoints = rubric.categories.reduce((acc, category) => {
    const categoryPoints = category.totalPoints
    const gradePercentage = value?.grades?.[category.id] ?? 0
    const gradePoints = gradePercentage * categoryPoints
    return acc + gradePoints
  }, 0)
  return (
    <RubricGraderContext.Provider
      value={React.useMemo(() => ({ disabled, value, onChange }), [disabled, value, onChange])}
    >
      <HSpaced justify={'space-between'} bottom={'xs'}>
        <Text level={'xs'} color={'secondary'}>
          {totalGraded} / {totalCategories} graded. {gradePoints} /{' '}
          {Format.plural('point', totalPoints)} (
          {Format.number(totalPoints === 0 ? 0 : gradePoints / totalPoints, 'decimalPercentage')})
        </Text>
      </HSpaced>
      <Container id={'custom-rubric'} data-testid={'RubricGrader'}>
        {rubric.categories.map(category => {
          return (
            <RubricGraderCriterion maxPresets={maxPresets} criterion={category} key={category.id} />
          )
        })}
      </Container>
    </RubricGraderContext.Provider>
  )
}
