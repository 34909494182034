import { message } from '@thesisedu/react'
import React from 'react'

import { SubmissionValues } from '../contexts/GradingModalSubmissionContext'
import { error } from '../log'
import { useGradeAssignmentSubmissionMutation } from '../queries/useGradeAssignmentSubmissionMutation'
import { BasicAssignmentSubmissionFragment, useSaveAssignmentSubmissionMutation } from '../schema'

export interface UseSaveAssignmentSubmissionGradeOpts {
  studentId: string
  classId: string
  assignmentId: string
  submission?: BasicAssignmentSubmissionFragment
}
export function useSaveAssignmentSubmissionGrade({
  studentId,
  classId,
  assignmentId,
  submission,
}: UseSaveAssignmentSubmissionGradeOpts) {
  const [save, { loading: saveLoading }] = useSaveAssignmentSubmissionMutation({
    refetchQueries: ['allAssignmentSubmissions'],
    awaitRefetchQueries: true,
  })
  const [grade, { loading: gradeLoading }] = useGradeAssignmentSubmissionMutation({
    onCompleted: () => {
      message.success('Grade saved successfully!')
    },
  })

  const onSave = React.useCallback(
    async (values: SubmissionValues) => {
      let createdSubmission = submission

      // First, create the submission if it does not already exist, and submit it.
      if (!createdSubmission) {
        try {
          const saveResult = await save({
            variables: {
              classId,
              includeConfiguration: false,
              input: {
                id: assignmentId,
                studentId,
                submit: true,
                submissionData: {},
                classId,
              },
            },
          })
          createdSubmission = saveResult?.data?.saveAssignmentSubmission.assignmentSubmission
        } catch (err: any) {
          error('error creating assignment submission')
          error(err)
        }
      }

      // Now, if the created submission is still there, grade it.
      if (createdSubmission) {
        await grade({
          refetch: true,
          variables: {
            input: {
              id: createdSubmission.id,
              grades: values.grades,
              comments: values.comments || {},
            },
          },
        })
      }
    },
    [studentId, submission],
  )

  return {
    onSave,
    loading: saveLoading || gradeLoading,
  }
}
