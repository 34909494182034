import { Text, s } from '@thesisedu/ui'
import React from 'react'

import { ListItem } from './StandardSelect'
import { TagsTreeItemFragment } from '../schema'

const INDENT_PX = 5
export interface ParentListItemProps {
  tag: TagsTreeItemFragment
  depth: number
  onSelect: () => void
}
export function ParentListItem({ tag, depth, onSelect }: ParentListItemProps) {
  return (
    <ListItem
      onClick={onSelect}
      paddingVertical={'xxs'}
      style={{
        paddingLeft: `calc(${s.size('xs')} + ${depth * INDENT_PX}px)`,
      }}
    >
      <Text level={'xs'} style={{ minWidth: 0 }} children={tag.tag.name} />
    </ListItem>
  )
}
