import { useFeatureRoot } from '@thesisedu/feature-react'
import { Link } from '@thesisedu/react'
import React from 'react'

import { FeatureWeb } from '../FeatureWeb'

export function Logo(props: any) {
  const root = useFeatureRoot() as FeatureWeb
  return (
    <Link to={'/'}>
      <img src={root.appOptions.logo()} alt={root.appOptions.name} {...props} />
    </Link>
  )
}

export function Icon(props: any) {
  const root = useFeatureRoot() as FeatureWeb
  return (
    <Link to={'/'}>
      <img src={root.appOptions.icon()} alt={root.appOptions.name} {...props} />
    </Link>
  )
}
