import { useResource } from '@thesisedu/feature-react'
import { Block, GroupedNavigation } from '@thesisedu/react'
import { PageHeader } from '@thesisedu/web'
import { orderBy } from 'lodash'
import React from 'react'

import { UserSettingsPageResource } from '../types'
import { useViewerContext } from '../useViewerContext'

export function UserSettings() {
  const viewer = useViewerContext(false)
  const sortedPages = orderBy(
    useResource<UserSettingsPageResource>('UserSettingsPage'),
    'weight',
    'asc',
  ).filter(resource => !resource.filter || resource.filter(viewer))

  return (
    <>
      <PageHeader title={'Settings'} />
      <Block marginTop={'@size-m'}>
        <GroupedNavigation groups={sortedPages} />
      </Block>
    </>
  )
}
