import { ClassDraggableOverlayItemsHook } from '@thesisedu/feature-classes-react'
import { Dropdown } from '@thesisedu/ui'
import { AppNotification } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { AnnouncementsReactFeature } from '../AnnouncementsReactFeature'

function AnnouncementsItem({ classId, group }: { classId: string; group: string }) {
  const navigate = useNavigate()
  return (
    <Dropdown.Item
      onClick={() => navigate(`/${group.toLowerCase()}/classes/${classId}/announcements`)}
      icon={<AppNotification />}
    >
      Announcements
    </Dropdown.Item>
  )
}

export default function (feature: AnnouncementsReactFeature) {
  feature.hookManager.registerMutateHook<ClassDraggableOverlayItemsHook>(
    'classes-react:class-draggable-overlay-items',
    (items, props) => {
      return [
        ...items,
        <AnnouncementsItem key={'announcements'} classId={props.class.id} group={props.group} />,
      ]
    },
  )
}
