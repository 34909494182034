import { BlockSpin, styled } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'

import { UserTaskListItem } from './UserTask'
import { getTaskKey } from './contexts/CompletedTasksContext'
import { UserTaskListContextProvider, UserTaskListDisplay } from './contexts/UserTaskListContext'
import { UserTaskFilters } from './types'
import { useUserTasks } from './useUserTasks'

const Container = styled.div`
  transition: opacity 0.25s linear;
`

export interface LargeUserTasksListProps {
  empty?: React.ReactElement
  filters?: UserTaskFilters
  first?: number
}
export function LargeUserTasksList({ empty, filters, first = 50 }: LargeUserTasksListProps) {
  const { loading, tasks, refreshing } = useUserTasks({
    filters,
    variables: { input: filters, first },
  })

  let content
  if (loading) {
    content = <BlockSpin />
  } else if (tasks?.length) {
    content = (
      <Container style={{ opacity: refreshing ? 0.5 : 1 }}>
        {tasks.map(task => {
          return <UserTaskListItem onClick={() => {}} task={task} key={getTaskKey(task)} />
        })}
      </Container>
    )
  } else {
    content = empty || <Empty description={'Nothing left to do!'} />
  }

  return (
    <UserTaskListContextProvider display={UserTaskListDisplay.Large}>
      {content}
    </UserTaskListContextProvider>
  )
}
