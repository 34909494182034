import { s } from '@thesisedu/ui'
import { transparentize } from 'polished'

// @ts-ignore
import Worker from './screencastWorker.worker'
import { debug } from '../../../log'
import { stopStreams } from '../selectDevice/stopStreams'

export interface SetupScreencastCanvasOpts {
  desktopStream: MediaStream
  stream: MediaStream
}
export async function setupScreencastCanvas({
  desktopStream,
  stream,
}: SetupScreencastCanvasOpts): Promise<MediaStream> {
  debug('setting up screencast canvas')
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!
  let mergedStream: MediaStream | undefined = undefined

  // Find the desktop resolution.
  const { frameRate } = desktopStream.getVideoTracks()[0].getSettings()
  if (!frameRate) throw new Error('Cannot get resolution of desktop stream.')
  const desktopVideo = document.createElement('video')
  desktopVideo.muted = true
  const dimensionsPromise = new Promise<{ width: number; height: number }>((resolve, reject) => {
    desktopVideo.addEventListener('loadedmetadata', () => {
      resolve({
        width: desktopVideo.videoWidth,
        height: desktopVideo.videoHeight,
      })
    })
    desktopVideo.addEventListener('error', reject)
  })
  desktopVideo.srcObject = desktopStream
  desktopVideo.play()
  const { width: desktopWidth, height: desktopHeight } = await dimensionsPromise
  debug('desktop resolution is %dx%d', desktopWidth, desktopHeight)

  // Calculate the recording resolution (force 16:9 aspect ratio; it should fit rather than fill).
  let width: number, height: number
  const desktopRatio = desktopWidth / desktopHeight
  if (desktopRatio > 16 / 9) {
    width = desktopWidth
    height = width / (16 / 9)
  } else {
    height = desktopHeight
    width = height * (16 / 9)
  }
  canvas.width = width
  canvas.height = height
  debug('recording resolution is %dx%d', width, height)

  // Find the coordinates of where to render the desktop stream.
  const desktopX = (width - desktopWidth) / 2
  const desktopY = (height - desktopHeight) / 2

  // Find the stream resolution.
  const {
    width: streamWidth,
    height: streamHeight,
    frameRate: streamFrameRate,
  } = stream.getVideoTracks()[0].getSettings()
  if (!streamWidth || !streamHeight || !streamFrameRate)
    throw new Error('Cannot get resolution of camera stream.')
  const cornerHeight = height * 0.25
  const cornerWidth = cornerHeight * (streamWidth / streamHeight)
  const cornerPadding = height * 0.05
  const cornerRadius = height * 0.01
  const mergedFrameRate = Math.min(frameRate, streamFrameRate)
  debug('stream resolution is %dx%d', streamWidth, streamHeight)
  debug('framerate is %d', mergedFrameRate)

  // Create the video elements and start them.
  debug('creating video elements')
  const video = document.createElement('video')
  video.srcObject = stream
  video.muted = true
  video.play()

  // Draw the video streams onto the canvas.
  // We have to do this inside a worker to get around browsers throttling calls to setTimeout()
  // and requestAnimationFrame(). We have a basic worker that runs the setTimeout() and handles
  // the timing. It posts a message back to the main process whenever we need to draw.
  const worker = new Worker()
  let cleanedUp = false
  const draw = () => {
    // If we are no longer recording, stop drawing.
    if (mergedStream?.getTracks()[0]?.readyState === 'ended' && !cleanedUp) {
      debug('cleaning up screencast canvas')
      worker.postMessage('stop')
      stopStreams({ stream })
      stopStreams({ stream: desktopStream })
      audioContext.close()
      canvas.remove()
      cleanedUp = true
    }

    const gradient = ctx.createLinearGradient(0, 0, width * 0.25, height)
    gradient.addColorStop(0, s.getThemeValue('color.dark.gray.0'))
    gradient.addColorStop(1, s.getThemeValue('color.dark.gray.2'))
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, width, height)
    ctx.drawImage(desktopVideo, desktopX, desktopY, desktopWidth, desktopHeight)
    ctx.beginPath()
    ctx.save()
    ctx.roundRect(
      width - cornerWidth - cornerPadding,
      height - cornerHeight - cornerPadding,
      cornerWidth,
      cornerHeight,
      cornerRadius,
    )
    ctx.shadowOffsetY = cornerRadius * 0.75
    ctx.shadowBlur = cornerRadius * 1.5
    ctx.shadowColor = transparentize(
      0.6,
      s.getThemeValue(s.colorToThemePath('gray.background', 'dark')).toString(),
    )
    ctx.fillStyle = s.getThemeValue(s.colorToThemePath('gray.background', 'dark')).toString()
    ctx.fill()
    ctx.save()
    ctx.beginPath()
    ctx.roundRect(
      width - cornerWidth - cornerPadding,
      height - cornerHeight - cornerPadding,
      cornerWidth,
      cornerHeight,
      cornerRadius,
    )
    ctx.clip()
    ctx.drawImage(
      video,
      width - cornerWidth - cornerPadding,
      height - cornerHeight - cornerPadding,
      cornerWidth,
      cornerHeight,
    )
    ctx.restore()
  }
  worker.postMessage({ fps: mergedFrameRate })
  worker.onmessage = draw

  // Merge the audio streams.
  debug('creating audio context')
  const audioContext = new AudioContext()
  const audioSource = audioContext.createMediaStreamSource(stream)
  const audioDestination = audioContext.createMediaStreamDestination()
  if (desktopStream.getAudioTracks().length > 0) {
    const desktopAudioSource = audioContext.createMediaStreamSource(desktopStream)
    desktopAudioSource.connect(audioDestination)
  }
  audioSource.connect(audioDestination)
  const mergedAudioTrack = audioDestination.stream.getAudioTracks()[0]

  // Create the final stream.
  debug('creating the final merged stream')
  mergedStream = canvas.captureStream(mergedFrameRate)
  mergedStream.addTrack(mergedAudioTrack)

  return mergedStream
}
