import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_NORMAL } from 'lexical'
import React from 'react'

import { $createImageNode } from '../../nodes/Image/ImageNode'
import { DROP_FILE_COMMAND } from '../DraggableBlockPlugin/commands'

export function getImageFiles(files: File[]): File[] {
  return files.filter(file => file.type.startsWith('image'))
}

export function useDropImage() {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    editor.registerCommand(
      DROP_FILE_COMMAND,
      ({ files, nodes }) => {
        const imageFiles = getImageFiles(files)
        if (imageFiles.length > 0) {
          for (const file of imageFiles) {
            nodes.push(
              $createImageNode({
                uploadFile: {
                  editor,
                  file,
                },
              }),
            )
          }
          return true
        } else return false
      },
      COMMAND_PRIORITY_NORMAL,
    )
  }, [editor])
}
