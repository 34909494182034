import { HSpaced } from '@thesisedu/web'
import React from 'react'

import { Graph } from './Graph'
import { getDefaultGraphConfiguration, GraphConfig, GraphConfiguration } from './GraphConfig'
import { ReportGraphExport } from './ReportGraphExport'
import { useReportResources } from '../../execute/input'
import { ReportActions } from '../ReportActions'
import { ReportResultsProps } from '../ReportResults'

export interface ReportResultGraphProps extends ReportResultsProps {
  graphConfig?: GraphConfiguration
  onGraphConfigChange: (config: GraphConfiguration) => void
}
export function ReportResultGraph({
  graphConfig: _graphConfig,
  onGraphConfigChange: setGraphConfig,
  ...props
}: ReportResultGraphProps) {
  const resources = useReportResources()
  const graphConfig =
    _graphConfig || getDefaultGraphConfiguration({ ...props, metrics: resources.metrics })
  return (
    <>
      <ReportActions {...props}>
        <ReportGraphExport {...props} />
      </ReportActions>
      <HSpaced align={'flex-start'} space={'@size-l'} style={{ flex: 1, minHeight: 0 }}>
        <div style={{ width: 300 }}>
          <GraphConfig opts={graphConfig} onChange={setGraphConfig} {...props} />
        </div>
        <div style={{ flex: 1, height: '100%' }}>
          <Graph {...props} config={graphConfig} />
        </div>
      </HSpaced>
    </>
  )
}
