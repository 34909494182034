import { Feature } from '@thesisedu/feature'

import { ExpectedErrorPayload, ExpectedErrorContext, ApolloReactHooks } from './types'

export function addExpectedErrorHandler(
  feature: Feature,
  handler: (
    error: ExpectedErrorPayload,
    context: ExpectedErrorContext,
  ) => Promise<void | ExpectedErrorPayload> | void | ExpectedErrorPayload,
) {
  feature.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
    ApolloReactHooks.ExpectedError,
    async (payload, context) => {
      const result = await handler(payload, context!)
      return result || payload
    },
  )
}
