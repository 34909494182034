import { UserRoutesHook, UserLeftItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper, Block } from '@thesisedu/react'
import { Search as SearchIcon } from '@thesisedu/react/icons'
import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Route } from 'react-router-dom'

import { SearchPage } from '../../SearchPage'
import { SearchReactFeature } from '../../SearchReactFeature'

function Search() {
  useBreadcrumb({ title: 'Search' })
  return (
    <Block marginTop={'@size-xl'}>
      <SearchPage />
    </Block>
  )
}

export default function (feature: SearchReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [...routes, <Route key={'search'} path={'search/*'} element={<Search />} />]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'bottom',
            weight: 40000,
            element: (
              <Shell.LeftMenuItem
                name={'Search'}
                icon={<AntIconWrapper children={<SearchIcon />} />}
                path={'/admin/search'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
