import React from 'react'
import { createPortal } from 'react-dom'

import { SidebarTab, useSidebarContext } from '../../sidebar/SidebarContext'

export interface SidebarTabProps extends Omit<SidebarTab, 'children'> {
  children: React.ReactNode
}
export function SidebarTab({ children, ...rest }: SidebarTabProps) {
  const context = useSidebarContext(false)
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  React.useEffect(() => {
    if (context) {
      context.addTab({ children: <div ref={c => setContainer(c)} />, ...rest })
      return () => context.removeTab(rest.title)
    }
  }, [!!context, rest.weight])

  return container ? createPortal(children, container) : null
}
