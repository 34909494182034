import React from 'react'

import { CellColorOpts, DEFAULT_CELL_COLOR_OPTS } from './getCellColors'

export interface ReportGridColorContextValue {
  opts: CellColorOpts
  setOpts: (opts: CellColorOpts) => void
}
export const ReportGridColorContext = React.createContext<ReportGridColorContextValue | undefined>(
  undefined,
)

export function ReportGridColorProvider({ children }: React.PropsWithChildren<object>) {
  const [opts, setOpts] = React.useState(DEFAULT_CELL_COLOR_OPTS)
  return <ReportGridColorContext.Provider value={{ opts, setOpts }} children={children} />
}

export function useReportGridColorContext(): ReportGridColorContextValue | undefined
export function useReportGridColorContext(require: false): ReportGridColorContextValue | undefined
export function useReportGridColorContext(require: true): ReportGridColorContextValue
export function useReportGridColorContext(
  require?: boolean,
): ReportGridColorContextValue | undefined {
  const context = React.useContext(ReportGridColorContext)
  if (!context && require) {
    throw new Error('ReportGridColorContext is required, yet not provided.')
  }
  return context
}
