import { IFeatureResourceManager } from '@thesisedu/feature'
import { addCustomEmoji, CustomEmojiRenderProps } from '@thesisedu/feature-react'
import { styled, StyledThemeContext } from '@thesisedu/web'
import React from 'react'

import SoundEffects from '../SoundEffects'
import * as Bells from '../assets/sketch/branding/bell'
import { ParticleStyle, showParticles } from '../hooks/student/components/Particle'
import { RingAnimation } from '../hooks/student/components/PinBells'

interface BellsMap {
  [codeFragment: string]: React.FC<React.PropsWithChildren<any>>
}
const BELLS: BellsMap = {
  a: Bells.A,
  b: Bells.B,
  c: Bells.C,
  d: Bells.D,
  e: Bells.E,
  f: Bells.F,
  g: Bells.G,
  'hi-c': Bells.HighC,
}
interface BellColors {
  [codeFrament: string]: string
}
const COLORS: BellColors = {
  a: '@purple',
  b: '@pink',
  c: '@red',
  d: '@orange',
  e: '@yellow',
  f: '@green',
  g: '@blue',
  'hi-c': '@red',
}
export function BellEmoji({ code }: CustomEmojiRenderProps) {
  const codeKey = code.replace('bell-', '')
  const BellComponent = BELLS[codeKey]
  const theme = React.useContext(StyledThemeContext)
  const containerRef = React.useRef<HTMLSpanElement | null>(null)
  const timeoutRef = React.useRef<any>()
  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])
  if (BellComponent) {
    return (
      <BellContainer
        ref={containerRef}
        onClick={() => {
          if (!timeoutRef.current && containerRef.current) {
            const currentElement = containerRef.current
            currentElement.classList.add('playing')
            showParticles(currentElement, {
              color: theme[COLORS[codeKey]],
              radius: 50,
              minSize: 3,
              maxSize: 15,
              duration: 1500,
            })
            SoundEffects.play(`bell.${codeKey}`)
            timeoutRef.current = setTimeout(() => {
              currentElement.classList.remove('playing')
              timeoutRef.current = null
            }, 1000)
          }
        }}
      >
        <ParticleStyle />
        <BellComponent />
      </BellContainer>
    )
  } else {
    return <span />
  }
}
const BellContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  svg {
    height: 1.5em;
  }
  &.playing svg {
    animation: ${RingAnimation} 4s ease-in-out;
  }
`

export function addCustomEmojis(resources: IFeatureResourceManager) {
  addCustomEmoji(
    resources,
    ['bell-a', 'bell-b', 'bell-c', 'bell-d', 'bell-e', 'bell-f', 'bell-g', 'bell-hi-c'],
    BellEmoji,
  )
}
