import { styled, HSpaced, Transition, StyledThemeContext } from '@thesisedu/web'
import React from 'react'
import { keyframes } from 'styled-components'

import { showParticles } from './Particle'
import SoundEffects from '../../../SoundEffects'
import * as Bells from '../../../assets/sketch/branding/bell'
// @ts-ignore
import { ReactComponent as EmptyBell } from '../../../assets/sketch/branding/bell/empty.svg'

const BELLS = [Bells.C, Bells.D, Bells.E, Bells.F, Bells.G, Bells.A, Bells.B]
const SOUNDS = ['tada.c', 'tada.d', 'tada.e', 'tada.f', 'tada.g', 'tada.a', 'tada.b']
const COLORS = ['@red', '@orange', '@yellow', '@green', '@blue', '@purple', '@pink']

export interface PinBellItem {
  segmentId: string
  enabled: boolean
}
export interface PinBellsProps {
  items: PinBellItem[]
  segmentId?: string
  onClick: (index: number) => void
}
export function PinBells({ segmentId, items, onClick }: PinBellsProps) {
  const bellContainerRefs = React.useRef<HTMLElement[]>([])
  const didMount = React.useRef<boolean>(false)
  const theme = React.useContext(StyledThemeContext)
  React.useEffect(() => {
    const index = items.findIndex(i => i.segmentId === segmentId)
    if (index > -1 && bellContainerRefs.current[index]) {
      const outerContainer = bellContainerRefs.current[index].parentElement!.parentElement!
      outerContainer.scrollTo({
        left:
          bellContainerRefs.current[index].parentElement!.offsetLeft -
          outerContainer.clientWidth / 2,
        behavior: 'smooth',
      })
    }
  }, [segmentId])
  React.useEffect(() => {
    if (didMount.current && items.length > 1 && !items.filter(i => !i.enabled).length) {
      const timeout = setTimeout(() => {
        // Animate all of the bells as a final celebration.
        SoundEffects.play('tada.all')
        for (let i = 0; i < bellContainerRefs.current.length; i++) {
          setTimeout(() => {
            showParticles(bellContainerRefs.current[i], { color: theme[COLORS[i]], duration: 2000 })
          }, i * 100)
        }
      }, 1500)
      return () => clearTimeout(timeout)
    }
    didMount.current = true
  }, [items.filter(i => !i.enabled).length])
  return (
    <Container space={'@size-s'}>
      {items.map(({ segmentId, enabled }, index) => {
        return (
          <Transition type={'bell'} state={enabled ? 'y' : 'n'} key={index}>
            {enabled ? (
              <span
                onClick={() => onClick(index)}
                ref={element => {
                  if (element) {
                    bellContainerRefs.current[index] = element
                  }
                }}
              >
                <Bell index={index} />
              </span>
            ) : (
              <span
                className={'empty'}
                onClick={() => onClick(index)}
                ref={element => {
                  if (element) {
                    bellContainerRefs.current[index] = element
                  }
                }}
              >
                <EmptyBell />
              </span>
            )}
          </Transition>
        )
      })}
    </Container>
  )
}

export interface BellProps {
  index: number
}
export function Bell({ index }: BellProps) {
  const theme = React.useContext(StyledThemeContext)
  const BellComponent = BELLS[index % BELLS.length]
  const sound = SOUNDS[index % SOUNDS.length]
  const color = theme[COLORS[index % COLORS.length]]
  const elementRef = React.useRef<HTMLElement | null>(null)
  React.useEffect(() => {
    const parent = elementRef.current?.parentElement?.parentElement
    if (parent && !parent.classList.contains('bell-appear')) {
      const timeout = setTimeout(() => {
        if (elementRef.current) {
          SoundEffects.play(sound)
          showParticles(elementRef.current, { color, duration: 2000 })
        }
      }, 250)
      return () => clearTimeout(timeout)
    }
  }, [])
  return <BellComponent ref={elementRef} />
}

export const RingAnimation = keyframes`
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`
const Container = styled(HSpaced)`
  max-width: 500px;
  overflow-x: auto;
  position: relative;
  > :nth-child(odd) svg {
    transform: rotate(-8deg);
  }
  > :nth-child(even) svg {
    transform: rotate(8deg);
  }
  svg {
    width: 52px;
  }
  span {
    display: block;
    cursor: pointer;
  }
  .bell-enter {
    transition: opacity 0.25s linear;
    opacity: 0;
    &-active {
      opacity: 1;
    }
  }
  .bell-exit {
    transition: opacity 0.25s linear;
    opacity: 1;
    &-active {
      opacity: 0;
    }
  }
  .bell-enter-done:not(.bell-appear-done) span:not(.empty) {
    animation: ${RingAnimation} 4s ease-in-out;
  }
`
