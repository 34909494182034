import { MapCallback, mapDeep, mapDeepInPlace } from './mapDeep'

export type FilterCallback = (...args: Parameters<MapCallback>) => boolean

export function filterValuesDeep<T extends Record<string, any>>(
  base: T,
  callback: FilterCallback,
): T {
  return mapDeep(base, (value, info) => {
    return callback(value, info) ? value : info.REMOVE
  })
}

export function filterValuesDeepInPlace<T extends Record<string, any>>(
  base: T,
  callback: FilterCallback,
) {
  mapDeepInPlace(base, (value, info) => {
    return callback(value, info) ? value : info.REMOVE
  })
}
