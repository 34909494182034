import { styled, BodySmall, Space, Body, HSpaced } from '@thesisedu/web'
import React from 'react'

export interface AssignmentPropertyProps {
  name: string
  value: string | React.ReactElement
  style?: any
}
export function AssignmentProperty({ name, value }: AssignmentPropertyProps) {
  return (
    <Property>
      <BodySmall color={'@text-color-secondary'}>{name}</BodySmall>
      <Space />
      <Body>{value}</Body>
    </Property>
  )
}

const Property = styled(HSpaced)`
  padding: calc(${props => props.theme['@size-s']} * 0.9) 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
  * {
    line-height: 1;
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
