import { UserRoutesHook, UserTopItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import React from 'react'
import { Route } from 'react-router-dom'

import { Reports } from './Reports'
import { ReportsReactFeature } from '../../ReportsReactFeature'

export default function (feature: ReportsReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'TEACHER') {
        return [...routes, <Route key={'reports'} path={'reports/*'} element={<Reports />} />]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserTopItemsHook>(
    'feature-users-react:top-items',
    (items, { group }) => {
      if (group === 'TEACHER') {
        return [
          ...items,
          {
            weight: 1,
            element: (
              <Shell.TopMenuItem name={'Reports'} path={'/teacher/reports'} key={'reports'} />
            ),
          },
        ]
      } else return items
    },
  )
}
