import { DeleteOutlined, ReloadOutlined, SoundOutlined } from '@ant-design/icons'
import { UploadButton, useFileField } from '@thesisedu/feature-attachments-react'
import { HSpaced } from '@thesisedu/web'
import { Dropdown, Menu, Button, Tooltip } from 'antd'
import { Resizable } from 're-resizable'
import React from 'react'

import { ImageWithAudioView } from './ImageWithAudioView'

interface Size {
  width: number
  height: number
}
export interface ImageWithAudioPayload {
  type: 'imageWithAudio'
  imagePath?: string
  audioPath?: string
  size?: Size
}

export interface ImageWithAudioEditProps {
  value?: ImageWithAudioPayload
  onChange: (value?: ImageWithAudioPayload) => void
  style?: any
}
export function ImageWithAudioEdit({ value, onChange, style }: ImageWithAudioEditProps) {
  const [disableSizeCheck, setDisableSizeCheck] = React.useState(!!value?.size)
  const [imageAspectRatio, setImageAspectRatio] = React.useState<boolean | number>(true)
  const fileFieldProps = useFileField({
    onChange: audioValue => {
      onChange({
        ...value,
        type: 'imageWithAudio',
        audioPath: audioValue,
      })
    },
  })
  const replaceImageFieldProps = useFileField({
    onChange: imageValue => {
      onChange({
        ...value,
        type: 'imageWithAudio',
        imagePath: imageValue,
      })
    },
  })
  return (
    <HSpaced style={style}>
      {value ? (
        <Resizable
          defaultSize={value?.size}
          lockAspectRatio={imageAspectRatio}
          maxHeight={500}
          maxWidth={1000}
          onResizeStart={() => {
            setDisableSizeCheck(true)
          }}
          onResizeStop={(_, dir, ref) => {
            if (onChange) {
              onChange({
                ...value,
                size: {
                  width: ref.clientWidth,
                  height: ref.clientHeight,
                },
              })
            }
          }}
        >
          <ImageWithAudioView
            value={value}
            disableSizeCheck={disableSizeCheck}
            style={{}}
            onLoad={e => {
              const image = e.target as HTMLImageElement
              if (image.naturalHeight && image.naturalWidth) {
                setImageAspectRatio(image.naturalWidth / image.naturalHeight)
              }
            }}
          />
        </Resizable>
      ) : null}
      {value?.audioPath ? null : (
        <UploadButton
          buttonProps={{ children: '', icon: <SoundOutlined />, title: 'Add Audio on Click' }}
          isPublic
          accept={'audio/mpeg'}
          {...fileFieldProps}
        />
      )}
      <UploadButton
        buttonProps={{
          type: 'link',
          children: '',
          icon: <ReloadOutlined />,
          title: 'Replace Image',
        }}
        isPublic
        accept={'image/*'}
        {...replaceImageFieldProps}
      />
      {value?.audioPath ? (
        <Dropdown
          trigger={['hover']}
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  if (onChange) {
                    onChange({
                      imagePath: value.imagePath,
                      type: 'imageWithAudio',
                    })
                  }
                }}
              >
                Remove Audio
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  if (onChange) {
                    onChange(undefined)
                  }
                }}
                danger
              >
                Remove Audio &amp; Image
              </Menu.Item>
            </Menu>
          }
        >
          <Button icon={<DeleteOutlined />} danger type={'link'} />
        </Dropdown>
      ) : (
        <Tooltip title={'Remove media'} mouseEnterDelay={1}>
          <Button
            icon={<DeleteOutlined />}
            danger
            type={'link'}
            onClick={() => {
              if (onChange) {
                onChange(undefined)
              }
            }}
          />
        </Tooltip>
      )}
    </HSpaced>
  )
}
