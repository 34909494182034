import { CourseClassContextHook } from '@thesisedu/feature-courses-react'
import React from 'react'

import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'
import { CourseAssignmentsContextProvider } from '../contexts/CourseAssignmentsContext'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<CourseClassContextHook>(
    'courses-react:course-class-context',
    (children, { group }) => {
      if (group === 'STUDENT') {
        return <CourseAssignmentsContextProvider children={children} />
      } else {
        return children
      }
    },
  )
}
