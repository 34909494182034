import { CommentsPlugin, MediaViewWithSidebar } from '@thesisedu/feature-media-react'
import { Block, BlockSpin, NotFound } from '@thesisedu/web'
import React from 'react'

import { useSheetMusicCompositionQuery } from '../schema'
import { SheetMusicState } from '../types'
import { SafeSheetMusicViewer } from '../viewer/SafeSheetMusicViewer'

export interface ViewSheetMusicCompositionProps {
  compId: string
}
export function ViewSheetMusicComposition({ compId }: ViewSheetMusicCompositionProps) {
  const { data, loading } = useSheetMusicCompositionQuery({ variables: { id: compId } })
  const comp = data?.node?.__typename === 'SheetMusicComposition' ? data.node : undefined
  if (comp) {
    return (
      <>
        {comp.media ? (
          <MediaViewWithSidebar mediaId={comp.media.id}>
            <CommentsPlugin />
          </MediaViewWithSidebar>
        ) : null}
        <Block marginTop={'@size-l'}>
          <SafeSheetMusicViewer
            title={comp.name}
            abc={comp.abc}
            playbackContainerProps={{
              defaultSelectedInstrumentKey: (comp.state as SheetMusicState | undefined)
                ?.selectedInstrument,
              bpm: (comp.state as SheetMusicState | undefined)?.bpm,
            }}
          />
        </Block>
      </>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'We could not find that composition.'} />
  }
}

export function useSheetMusicCompName(id: string) {
  const { data } = useSheetMusicCompositionQuery({ variables: { id } })
  return data?.node?.__typename === 'SheetMusicComposition' ? data.node.name : ''
}
