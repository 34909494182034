import { $wrapNodeInElement } from '@lexical/utils'
import { css } from '@thesisedu/react'
import {
  $createParagraphNode,
  ElementNode,
  LexicalNode,
  NodeKey,
  Spread,
  SerializedElementNode,
} from 'lexical'

export const ColumnNodeStyles = css`
  .column-node {
  }
`

export type SerializedColumnNodeV1 = Spread<
  {
    type: 'column'
    width: number | null
    version: 1
  },
  SerializedElementNode
>

export function normalizeWidth(width: number | null, numSiblingsExcludingSelf: number) {
  if (width !== null) {
    // Minimum of 0.1, maximum of 1 - (0.1 * numSiblings)
    return Math.min(1 - numSiblingsExcludingSelf * 0.1, Math.max(0.1, Math.round(width * 10) / 10))
  } else {
    return null
  }
}

export function widthFromElement(element: HTMLElement) {
  if (
    element.style.width === 'auto' ||
    !element.style.width ||
    !element.style.width.includes('%')
  ) {
    return null
  } else {
    const full = parseFloat(element.style.width.replace('%', ''))
    return Math.round(full / 10) / 10
  }
}

export class ColumnNode extends ElementNode {
  __width: number | null

  constructor(width?: number | null, key?: NodeKey) {
    super(key)
    this.__width = width === undefined ? null : width
  }

  static getType(): string {
    return 'column'
  }

  static clone(node: ColumnNode): ColumnNode {
    return new ColumnNode(node.__width, node.__key)
  }

  static importJSON({ width }: SerializedColumnNodeV1): ColumnNode {
    const node = new ColumnNode()
    node.__width = width
    return node
  }

  exportJSON(): SerializedColumnNodeV1 {
    return {
      ...super.exportJSON(),
      type: 'column',
      width: this.__width,
      version: 1,
    }
  }

  setWidth(width: number | null) {
    const self = this.getWritable()
    self.__width = width
  }

  getWidth(): number | null {
    const self = this.getLatest()
    return self.__width
  }

  refreshWidth(siblingsExcludingSelf: number) {
    const normalized = normalizeWidth(this.__width, siblingsExcludingSelf)
    if (normalized !== this.__width) {
      this.setWidth(normalized)
    }
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.classList.add('column-node')
    this.__updateWidth(dom)
    return dom
  }

  __updateWidth(dom: HTMLElement) {
    const normalized = normalizeWidth(this.__width, (this.getParent()?.getChildrenSize() || 1) - 1)
    if (normalized === null) {
      dom.style.width = 'auto'
      dom.style.flex = '1'
    } else {
      dom.style.width = `${normalized * 100}%`
      dom.style.flex = ''
    }
  }

  updateDOM(prevNode: ColumnNode, dom: HTMLElement): boolean {
    this.__updateWidth(dom)
    return false
  }
}

export function $createColumnNode(): ColumnNode {
  const node = new ColumnNode()
  node.append($createParagraphNode())
  return node
}

export function $wrapInColumnNode(node: LexicalNode): ElementNode {
  return $wrapNodeInElement(node, () => new ColumnNode())
}

export function $isColumnNode(node: any): node is ColumnNode {
  return node instanceof ColumnNode
}
