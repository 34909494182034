import { StudentsPageActionsHook } from '@thesisedu/feature-classes-react'
import { Button } from '@thesisedu/ui'
import { CloudSync } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import { SetupModal } from '../../setup/SetupModal'

export default function (feature: ClassSyncReactFeature) {
  feature.hookManager.registerMutateHook<StudentsPageActionsHook>(
    'classes-react:students-page-actions',
    (actions, { cls }) => {
      if ((cls as any).canCreateClassSync) {
        return [
          <SetupModal
            key={'attach-class-sync'}
            trigger={<Button icon={<CloudSync />} children={'Attach Class from LMS'} />}
          />,
          ...actions,
        ]
      } else return actions
    },
  )
}
