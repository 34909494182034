import { VSpaced, Form } from '@thesisedu/ui'
import React from 'react'

import { SimpleWidget } from '../utils'

export function DiscussionGuideItemSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <Form.TextField
        name={'subtitle'}
        label={'Subtitle'}
        placeholder={'Slide 6'}
        description={'What text do you want to appear above the question?'}
      />
      <SimpleWidget.StartingNumberField />
    </VSpaced>
  )
}
