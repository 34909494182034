import { Form } from '@thesisedu/ui'
import React from 'react'

import { useGradingModalContext } from '../contexts/GradingModalContext'
import { AssignmentGradingMode } from '../schema'

export interface AssignmentTotalPointsFieldProps {
  questionSettingsTab?: string
}
export function AssignmentTotalPointsField({
  questionSettingsTab,
}: AssignmentTotalPointsFieldProps) {
  const { setSelectedTab } = useGradingModalContext(false) ?? {}
  const { form } = Form.useFormContext(true)
  const gradingMode = form.watch('gradingMode')
  return (
    <Form.NumberField
      name={'totalPoints'}
      label={'Total points'}
      labelRight={
        gradingMode === AssignmentGradingMode.Rubric ? undefined : questionSettingsTab &&
          setSelectedTab ? (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault()
              setSelectedTab(questionSettingsTab)
            }}
          >
            Edit points per question
          </a>
        ) : undefined
      }
      minValue={0}
      required
      formatOptions={{
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }}
    />
  )
}
