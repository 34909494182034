import { Feature, FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import React from 'react'

export class InteractionsWebFeature extends Feature {
  public static package: string = '@thesisedu/feature-interactions-react'
  public static path = __dirname
  public static requires: string[] = []

  constructor(opts: object, deps: FeatureDependencies) {
    super(opts, deps)
    this.hookManager.registerMutateHook<React.ReactElement>(
      'feature-web:wrap-app',
      async children => {
        const { WebInteractionsProvider } = require('./WebInteractionsProvider')
        return <WebInteractionsProvider children={children} />
      },
    )
  }
}

export const interactionsWebFeature = (opts: object = {}): FeatureUse => [
  InteractionsWebFeature,
  opts,
]
