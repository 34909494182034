import { WebRoutesHook } from '@thesisedu/feature-web'
import React from 'react'
import { Route } from 'react-router-dom'

import { Teacher } from './Teacher'
import { TeachersWebFeature } from '../../TeachersWebFeature'

export default function (feature: TeachersWebFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [<Route path={'teacher/*'} key={'teacher'} element={<Teacher />} />, ...routes]
  })
}
