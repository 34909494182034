import { Text } from '@thesisedu/ui'
import { NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

export function BreadcrumbSeparator() {
  return (
    <Text color={'secondary'}>
      <NavArrowRight />
    </Text>
  )
}
