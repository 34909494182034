import React from 'react'

import { StandardSetGridGroup } from './StandardSetGridGroup'
import { StandardSetChildProps } from './StandardSetPage'

export function StandardSetGrid({ standardSet, focusTagId }: StandardSetChildProps) {
  const [expandedTagIds, setExpandedTagIds] = React.useState<string[]>(
    focusTagId ? [focusTagId] : [],
  )
  React.useEffect(() => {
    setExpandedTagIds(focusTagId ? [focusTagId] : [])
  }, [focusTagId])
  return (
    <>
      {standardSet.segmentsTree.tree.map(treeItem => (
        <StandardSetGridGroup
          key={treeItem.tag.id}
          treeItem={treeItem}
          expanded={expandedTagIds.includes(treeItem.tag.id)}
          onExpandedChange={expanded => {
            setExpandedTagIds(ids => {
              const without = ids.filter(id => id !== treeItem.tag.id)
              return expanded ? [...without, treeItem.tag.id] : without
            })
          }}
          scrollIntoView={focusTagId === treeItem.tag.id}
        />
      ))}
    </>
  )
}
