import { Feature, ErrorService, FeatureResource, MutateHook } from '@thesisedu/feature'

import { useReactContext } from './contexts/ReactContext'

export function useFeatureRoot() {
  return useReactContext(true).root
}

type FeatureWithPackage = Feature
export function useFeature<Feature extends FeatureWithPackage>(ft: {
  new (...args: any[]): Feature
}): Feature {
  const root = useFeatureRoot()
  return root.requireFeature((ft as any).package)
}

export function useResource<Resource extends FeatureResource>(type: Resource['type']): Resource[]
export function useResource<Resource extends FeatureResource>(
  type: Resource['type'],
  identifier: string,
): Resource | null
export function useResource<Resource extends FeatureResource>(
  type: Resource['type'],
  identifier: string,
  require: true,
): Resource
export function useResource<Resource extends FeatureResource>(
  type: Resource['type'],
  identifier: string,
  require?: false,
): Resource
export function useResource<Resource extends FeatureResource>(
  type: Resource['type'],
  identifier?: string,
  require?: boolean,
): Resource | Resource[] | null {
  const root = useFeatureRoot()
  if (identifier) {
    const resource = root.deps.resources.getResource<Resource>(type, identifier)
    if (require && !resource) throw new Error(`Cannot find ${type} resource '${identifier}'`)
    return resource
  } else {
    return root.deps.resources.getResourcesForType<Resource>(type)
  }
}

export function useMutateHook<Hook extends MutateHook<any, any, any>>(
  name: Hook['identifier'],
  payload: Hook['payload'],
  context: Hook['context'],
): Hook['payload']
export function useMutateHook<Payload, Context = undefined>(
  name: string,
  payload: Payload,
  context: Context,
): Payload
export function useMutateHook<Payload, Context = undefined>(
  name: string,
  payload: Payload,
  context: Context,
): Payload {
  const root = useFeatureRoot()
  return root.deps.hookManager.mutateHookSync<Payload, Context>(name, payload, context)
}

export function useAsyncMutateCallback<Hook extends MutateHook<any, any, any>>(
  name: Hook['identifier'],
): (payload: Hook['payload'], context: Hook['context']) => Promise<Hook['payload']>
export function useAsyncMutateCallback<Payload, Context = undefined>(
  name: string,
): (payload: Payload, context: Context) => Promise<Payload>
export function useAsyncMutateCallback<Payload, Context = undefined>(name: string) {
  const root = useFeatureRoot()
  return (payload: Payload, context: Context) => {
    return root.deps.hookManager.mutateHook<Payload, Context>(name, payload, context)
  }
}

export function useSyncMutateCallback<Hook extends MutateHook<any, any, any>>(
  name: Hook['identifier'],
): (payload: Hook['payload'], context: Hook['context']) => Hook['payload']
export function useSyncMutateCallback<Payload, Context = undefined>(
  name: string,
): (payload: Payload, context: Context) => Payload
export function useSyncMutateCallback<Payload, Context = undefined>(name: string) {
  const root = useFeatureRoot()
  return (payload: Payload, context: Context) => {
    return root.deps.hookManager.mutateHookSync<Payload, Context>(name, payload, context)
  }
}

export function useErrorService(): ErrorService {
  const root = useFeatureRoot()
  return root.deps.services.error
}
