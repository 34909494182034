import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu'
import { fuzzySearch } from '@thesisedu/feature-utils'
import React, { startTransition } from 'react'

import { DropdownMenu } from './DropdownMenu'
import { DropdownMenuItem } from './DropdownMenuItem'
import { Search } from '../../../icons'
import { Block } from '../Block'
import { Text } from '../Text'

interface SearchEntry {
  childKey: string
  searchTerms: string[]
}

export interface SearchableDropdownMenuProps extends DropdownMenuContentProps {
  placeholder?: string
}
export function SearchableDropdownMenu({
  children,
  placeholder = 'Search menu...',
  ...rest
}: SearchableDropdownMenuProps) {
  const [visibleKeys, setVisibleKeys] = React.useState<string[] | null>(null)
  const [search, setSearch] = React.useState('')
  const searchEntries = React.useMemo<SearchEntry[]>(() => {
    return React.Children.toArray(children)
      .map(child => {
        if (React.isValidElement(child) && typeof child.key === 'string') {
          return {
            childKey: child.key,
            searchTerms: [
              child.props.textValue ??
                (typeof child.props.children === 'string' ? child.props.children : ''),
            ],
          }
        } else return null
      })
      .filter(Boolean) as SearchEntry[]
  }, [children])
  React.useEffect(() => {
    startTransition(() => {
      if (search.trim()) {
        setVisibleKeys(fuzzySearch(searchEntries, ['searchTerms'], search).map(r => r.childKey))
      } else {
        setVisibleKeys(null)
      }
    })
  }, [search])

  const matchingChildren = visibleKeys
    ? React.Children.toArray(children).filter(child => {
        if (React.isValidElement(child) && typeof child.key === 'string') {
          return visibleKeys.includes(child.key)
        } else return true
      })
    : children

  return (
    <DropdownMenu {...rest}>
      <DropdownMenuItem.TextField
        autoFocus
        value={search}
        onChange={setSearch}
        placeholder={placeholder}
        aria-label={placeholder}
        suffix={<Search />}
      />
      {!Array.isArray(matchingChildren) || matchingChildren.length ? (
        matchingChildren
      ) : (
        <Block top={'xs'}>
          <Text style={{ textAlign: 'center' }} level={'s'} color={'secondary'}>
            No results
          </Text>
        </Block>
      )}
    </DropdownMenu>
  )
}
