import { Block, BlockSpin, NotFound, PageHeader, Row } from '@thesisedu/web'
import { Affix, Col } from 'antd'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CourseVersionEditorLoader } from './CourseVersionEditorLoader'
import { CourseVersionHeader } from './CourseVersionHeader'
import { CourseVersions } from './CourseVersions'
import { DeleteCourseButton } from './DeleteCourseButton'
import { EditCourseClassConfigurationButton } from './EditCourseClassConfigurationModal'
import { EditCourseButton } from './EditCourseModal'
import { useCourseQuery } from '../schema'

export interface ViewCoursePageProps {
  courseId: string
}
export function ViewCoursePage({ courseId }: ViewCoursePageProps) {
  const { data, loading } = useCourseQuery({ variables: { id: courseId } })
  const { courseVersionId } = useParams()
  const navigate = useNavigate()
  if (loading) {
    return <BlockSpin />
  } else if (data?.node?.__typename === 'Course') {
    const course = data.node
    return (
      <>
        <Block marginBottom={'@size-l'}>
          <PageHeader onBack={() => navigate('/admin/courses')} title={course.name}>
            <DeleteCourseButton courseId={courseId} />
            <EditCourseClassConfigurationButton course={course} />
            <EditCourseButton course={course} />
          </PageHeader>
        </Block>
        <Row>
          <Col xs={24} md={8} lg={7} xl={6}>
            <Affix offsetTop={30}>
              <div>
                <CourseVersions
                  courseId={course.id}
                  onVersionSelected={version => {
                    navigate(`${courseVersionId ? '..' : '.'}/${version}`)
                  }}
                  selectedVersionId={courseVersionId}
                />
              </div>
            </Affix>
          </Col>
          {courseVersionId ? (
            <Col xs={0} md={16} lg={17} xl={18}>
              <CourseVersionHeader courseVersionId={courseVersionId} />
              <CourseVersionEditorLoader courseVersionId={courseVersionId} />
            </Col>
          ) : null}
        </Row>
      </>
    )
  } else {
    return <NotFound description={'That course could not be found.'} />
  }
}
