import {
  GroupsStudentDraggableContext,
  GroupsStudentDraggableHook,
} from '@thesisedu/feature-assignments-react'
import { useClassAndStudentGradesQuery } from '@thesisedu/feature-assignments-react/dist/schema'
import { useReportPath } from '@thesisedu/feature-reports-react'
import { Format } from '@thesisedu/feature-utils'
import { HSpaced, LoadingIndicator, ProgressBar, Text, Tooltip, VSpaced } from '@thesisedu/ui'
import { OpenInWindow } from '@thesisedu/ui/icons'
import React from 'react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import { useStudentGroupEfficacyReportQuery } from '../../schema'

function LeaderEfficacyLabel({ classId, studentId, loadedStudent }: GroupsStudentDraggableContext) {
  const { loading, path } = useReportPath({
    dimensions: [
      {
        identifier: 'OneStudent',
        options: {
          studentPairs: [{ classId, studentId }],
        },
        context: {
          fullClassNames: [],
          firstName: loadedStudent?.user.name ?? loadedStudent?.user.username ?? 'Student',
        },
      },
      {
        identifier: 'Lessons',
        options: {},
        context: {},
      },
    ],
    metrics: [
      {
        grade: {},
      },
      {
        numAttempts: {},
      },
    ],
    filters: [
      {
        isLeader: true,
      },
    ],
  })

  const content = (
    <HSpaced space={'xxs'}>
      <Text level={'xs'} color={'secondary'}>
        Leader Efficacy
      </Text>
      <Text level={'xs'} color={'secondary'}>
        {loading ? <LoadingIndicator /> : <OpenInWindow />}
      </Text>
    </HSpaced>
  )

  if (path) {
    return <a href={path} target={'_blank'} rel={'noopener noreferrer'} children={content} />
  } else {
    return content
  }
}

interface GroupsTooltipContentProps extends GroupsStudentDraggableContext {}
function GroupsTooltipContent({ classId, studentId, loadedStudent }: GroupsTooltipContentProps) {
  const { data } = useClassAndStudentGradesQuery({
    variables: {
      id: classId,
    },
  })
  const { data: report, loading: reportLoading } = useStudentGroupEfficacyReportQuery({
    variables: { classId, studentId },
    fetchPolicy: 'network-only',
  })
  const grade =
    data?.node?.__typename === 'Class'
      ? data.node.students.edges.find(edge => edge.node.id === studentId)?.grade
      : undefined
  const timesAsLeader = report?.runReport.allMetrics.count.grade || 0
  const leaderEfficacy = report?.runReport.allMetrics.average.grade

  if (reportLoading) {
    return <LoadingIndicator />
  }

  return (
    <VSpaced space={'m'}>
      <HSpaced justify={'space-between'}>
        <div>
          <Text weight={'bold'}>
            {loadedStudent?.user.name ?? loadedStudent?.user.username ?? '--'}
          </Text>
          <Text level={'xs'} color={'secondary'}>
            {Format.plural('time', timesAsLeader)} as leader
          </Text>
        </div>
        {grade != null ? (
          <div style={{ textAlign: 'right' }}>
            <Text weight={'bold'}>{Format.number(grade, 'percentage')}</Text>
            <Text level={'xs'} color={'secondary'}>
              Class Grade
            </Text>
          </div>
        ) : null}
      </HSpaced>
      {leaderEfficacy != null ? (
        <ProgressBar.Container
          value={leaderEfficacy}
          label={
            <LeaderEfficacyLabel
              studentId={studentId}
              classId={classId}
              loadedStudent={loadedStudent}
            />
          }
          minValue={0}
          maxValue={1}
        >
          <ProgressBar.BarSegments>
            <ProgressBar.BarSegment color={'blue'} opacity={0.25} minValue={0} />
            <ProgressBar.BarSegment color={'blue'} opacity={0.5} minValue={0.25} />
            <ProgressBar.BarSegment color={'blue'} opacity={0.75} minValue={0.5} />
            <ProgressBar.BarSegment color={'blue'} minValue={0.75} />
          </ProgressBar.BarSegments>
          <HSpaced justify={'space-between'} top={'xs'}>
            <ProgressBar.Label level={'s'} color={'secondary'} />
            <ProgressBar.ValueLabel level={'l'} weight={'bold'} color={'primary'} />
          </HSpaced>
        </ProgressBar.Container>
      ) : null}
    </VSpaced>
  )
}

interface GroupsTooltipProps extends GroupsStudentDraggableContext {
  children: React.ReactElement
}
function GroupsTooltip({ children, ...rest }: GroupsTooltipProps) {
  return (
    <Tooltip
      contentProps={{ side: 'bottom', align: 'start', style: { width: 300, textAlign: 'left' } }}
      children={children}
      colorVariant={'inherit'}
      title={<GroupsTooltipContent {...rest} />}
    />
  )
}

export default function (feature: CourseReportsReactFeature) {
  feature.hookManager.registerMutateHook<GroupsStudentDraggableHook>(
    'assignments-react:groups-student-draggable',
    (content, context) => {
      return <GroupsTooltip children={content} {...context} />
    },
  )
}
