import { Shell } from '@thesisedu/feature-web'
import React from 'react'

import { ChangelogButton } from '../ChangelogButton'
import { UpdatesReactFeature } from '../UpdatesReactFeature'

export default function (feature: UpdatesReactFeature) {
  feature.hookManager.registerMutateHook<Shell.TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    items => {
      return [
        ...items,
        {
          weight: 10,
          element: <ChangelogButton key={'changelog'} />,
        },
      ]
    },
  )
}
