import React from 'react'
import { v4 as uuid } from 'uuid'

/**
 * The purpose of the wrapper below is to create a unique identifier for each component
 * that might be handling a segment completion event. We only want to display one of
 * them on the page at a time, so we have a shared context (provider created inside
 * CourseViewerSegment). This shared context stores a ref pointing to the identifier for
 * the current component handling the completion event.
 *
 * If that identifier has not been set, or is equal to the current handler's ID, then
 * we'll continue with the render. Else, we'll do nothing to avoid rendering the same
 * on each page.
 *
 * The context value is reset every time the Segment ID (or contentKey) changes.
 */

export interface SegmentCompletionHandlerContextValue {
  completionHandled: Handled
  setCompletionHandled: React.Dispatch<React.SetStateAction<Handled>>
  contentKey: string
}
export const SegmentCompletionHandlerContext = React.createContext<
  SegmentCompletionHandlerContextValue | undefined
>(undefined)

export interface Handled {
  [contentKey: string]: { key: string; weight: number } | null | undefined
}
export interface SegmentCompletionHandlerContextProviderProps {
  children: React.ReactElement | null
  contentKey: string
}
export function SegmentCompletionHandlerContextProvider({
  children,
  contentKey,
}: SegmentCompletionHandlerContextProviderProps) {
  const [handled, setHandled] = React.useState<Handled>({})
  React.useEffect(() => {
    setHandled(current => {
      // Remove any other keys not associated with the current content key.
      for (const key of Object.keys(current)) {
        if (key !== contentKey) {
          delete current[key]
        }
      }

      return current
    })
  }, [contentKey])
  return (
    <SegmentCompletionHandlerContext.Provider
      value={{ completionHandled: handled, setCompletionHandled: setHandled, contentKey }}
      children={children}
    />
  )
}

export function useSegmentCompletionHandlerContext():
  | SegmentCompletionHandlerContextValue
  | undefined
export function useSegmentCompletionHandlerContext(
  require: false,
): SegmentCompletionHandlerContextValue | undefined
export function useSegmentCompletionHandlerContext(
  require: true,
): SegmentCompletionHandlerContextValue
export function useSegmentCompletionHandlerContext(
  require?: boolean,
): SegmentCompletionHandlerContextValue | undefined {
  const context = React.useContext(SegmentCompletionHandlerContext)
  if (!context && require) {
    throw new Error('SegmentCompletionHandlerContext is required, yet not provided.')
  }
  return context
}

export interface SegmentCompletionHandlerWrapperProps {
  children: React.ReactElement | null
  /**
   * This is the weight of your completion implementation. Lower weights
   * are given preference over higher weights. Defaults to 0.
   */
  weight?: number
}
export function SegmentCompletionHandlerWrapper({
  children,
  weight,
}: SegmentCompletionHandlerWrapperProps) {
  if (useSegmentCompletionHandler({ weight })) {
    return children
  } else {
    return null
  }
}

export interface UseSegmentCompletionHandlerOpts {
  /**
   * This is the weight of your completion implementation. Lower weights
   * are given preference over higher weights. Defaults to 0.
   */
  weight?: number
}
export function useSegmentCompletionHandler({ weight = 0 }: UseSegmentCompletionHandlerOpts = {}) {
  const { setCompletionHandled, completionHandled, contentKey } =
    useSegmentCompletionHandlerContext(false) || {}
  const [wrapperKey] = React.useState(uuid())
  const shouldShow = React.useMemo(() => {
    if (contentKey && setCompletionHandled && completionHandled) {
      const hasCurrent = !!completionHandled[contentKey]
      const currentMatches = completionHandled[contentKey]?.key === wrapperKey
      const ourWeightIsLower =
        completionHandled[contentKey] && completionHandled[contentKey]!.weight > weight
      if (!hasCurrent || currentMatches || ourWeightIsLower) {
        if (!currentMatches) {
          setCompletionHandled(handled => {
            return {
              ...handled,
              [contentKey]: { key: wrapperKey, weight },
            }
          })
        }
        return true
      }
    }
    return false
  }, [contentKey, weight, wrapperKey, completionHandled, setCompletionHandled])

  const [shouldShowState, setShouldShowState] = React.useState(false)
  React.useEffect(() => {
    if (shouldShow) {
      const timeout = setTimeout(() => {
        setShouldShowState(true)
      }, 250)
      return () => clearTimeout(timeout)
    } else {
      setShouldShowState(false)
    }
  }, [shouldShow])

  // Give up ownership if we are unmounting, to let other components take over.
  React.useEffect(() => {
    return () => {
      if (shouldShow && setCompletionHandled && contentKey) {
        setCompletionHandled(handled => {
          return {
            ...handled,
            [contentKey]: undefined,
          }
        })
      }
    }
  }, [])

  return shouldShowState
}

/** @deprecated use useSegmentCompletionHandler() with a weight instead */
export function useForcedSegmentCompletionHandler() {
  return useSegmentCompletionHandler({ weight: -Infinity })
}
