import { Form, Empty } from '@thesisedu/react'
import { Block } from '@thesisedu/ui'
import React from 'react'

import { AssignmentQuestions } from './AssignmentQuestions'
import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'

export function QuestionAssignmentReadOnlyViewer() {
  const { configuration, templateId } = useAssignmentViewerContext(true)
  const form = Form.useForm<Record<string, any>>()
  const questions = configuration.questions?.filter(q => !q.disabled)
  if (questions?.length) {
    return (
      <Form form={form}>
        <AssignmentQuestions
          editorId={`assignment-${templateId}`}
          questions={questions}
          widgets={configuration.widgets as any}
          disabled
        />
      </Form>
    )
  } else {
    return (
      <Block top={'l'} bottom={'l'}>
        <Empty description={'This assignment has no questions.'} />
      </Block>
    )
  }
}
