import {
  CheckOutlined,
  CopyOutlined,
  FormatPainterOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons'
import {
  QuestionAdditionalFields,
  QuestionInstructions,
  QuestionProps,
} from '@thesisedu/feature-assignments-react'
import { styled, AntIconWrapper, useResizeObserver } from '@thesisedu/web'
import { Button, Input, ConfigProvider } from 'antd'
import { DragHandGesture, AddFrame } from 'iconoir-react'
import React from 'react'
import FullScreen from 'react-full-screen'
import { v4 as uuid } from 'uuid'

import { Background } from './Background'
import { BackgroundOptionsDrawer } from './BackgroundOptionsDrawer'
import { DraggableEdit } from './DraggableEdit'
import { DropAreaEdit } from './DropAreaEdit'
import { GraphicDragDropContext } from './GraphicDragDropContext'
import { Container, FullscreenContainer, ItemContainer } from './styled'
import { DEFAULT_INSTRUCTIONS, DRAGGABLE_DEFAULTS, GraphicDragDropConfig } from './types'

export function GraphicDragDropEdit(props: QuestionProps<GraphicDragDropConfig>) {
  const { onChange: _onChange, question } = props
  const { width, height, ref } = useResizeObserver()
  const [backOptionsVisible, setBackOptionsVisible] = React.useState(false)
  const [pairingDraggableId, setPairingDraggableId] = React.useState<string | undefined>(undefined)
  const [isCloning, setIsCloning] = React.useState(false)
  const [fullscreen, setFullscreen] = React.useState(false)
  const fullscreenRef = React.useRef(false)
  React.useEffect(() => {
    fullscreenRef.current = fullscreen
  }, [fullscreen])
  const [size, setSize] = React.useState<{ width: number; height: number } | undefined>(undefined)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const onChange: typeof _onChange = changes => {
    if (_onChange) {
      if (changes.config?.dragDropPairs) {
        // Automatically re-calculate total points based on the # of draggables.
        const uniqueDraggables = changes.config.dragDropPairs.reduce<Set<string>>((acc, pair) => {
          acc.add(pair.draggableId)
          return acc
        }, new Set<string>())
        _onChange({
          ...changes,
          totalPoints: uniqueDraggables.size || 1,
        })
      } else {
        _onChange(changes)
      }
    }
  }

  return (
    <>
      <QuestionInstructions
        instructions={
          <Input
            placeholder={DEFAULT_INSTRUCTIONS}
            value={question.config?.instructions}
            style={{ width: '100%' }}
            onChange={e => {
              if (onChange) {
                onChange({
                  config: {
                    ...question.config,
                    instructions: e.target.value,
                  },
                })
              }
            }}
          />
        }
      >
        <FullScreen enabled={fullscreen} onChange={setFullscreen}>
          <FullscreenContainer enabled={fullscreen} className={'editor'} ref={containerRef}>
            <ConfigProvider
              getPopupContainer={() =>
                fullscreenRef.current ? containerRef.current || document.body : document.body
              }
            >
              <BackgroundOptionsDrawer
                visible={backOptionsVisible}
                onClose={() => setBackOptionsVisible(false)}
                value={question.config?.background}
                onChange={background => {
                  if (onChange) {
                    onChange({
                      config: {
                        ...question.config,
                        background,
                      },
                    })
                  }
                }}
              />
              <Header>
                <Button
                  icon={<AntIconWrapper children={<DragHandGesture />} />}
                  onClick={() => {
                    if (onChange) {
                      onChange({
                        config: {
                          ...question.config,
                          draggables: [
                            ...(question.config?.draggables || []),
                            {
                              id: uuid(),
                              x: 0,
                              y: 0,
                              width: 0.25,
                              height: 0.25,
                              ...DRAGGABLE_DEFAULTS,
                            },
                          ],
                        },
                      })
                    }
                  }}
                >
                  Draggable
                </Button>
                <Button
                  icon={<AntIconWrapper children={<AddFrame />} />}
                  onClick={() => {
                    if (onChange) {
                      onChange({
                        config: {
                          ...question.config,
                          dropAreas: [
                            ...(question.config?.dropAreas || []),
                            { id: uuid(), x: 0, y: 0, width: 0.25, height: 0.25 },
                          ],
                        },
                      })
                    }
                  }}
                >
                  Drop Zone
                </Button>
                <Button
                  icon={<CopyOutlined />}
                  type={isCloning ? 'primary' : 'default'}
                  onClick={() => {
                    setIsCloning(c => !c)
                  }}
                >
                  {isCloning ? 'Done' : 'Clone'}
                </Button>
                {pairingDraggableId ? (
                  <Button
                    icon={<CheckOutlined />}
                    type={'primary'}
                    onClick={() => {
                      setPairingDraggableId(undefined)
                    }}
                  />
                ) : null}
                <Button
                  style={{ marginLeft: 'auto' }}
                  icon={fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                  onClick={() => setFullscreen(f => !f)}
                />
                <Button
                  icon={<FormatPainterOutlined />}
                  onClick={() => {
                    setBackOptionsVisible(true)
                  }}
                >
                  Background
                </Button>
              </Header>
              <Container ref={ref} width={size?.width} height={size?.height}>
                <Background
                  background={question.config?.background}
                  onSize={(width, height) => setSize({ width, height })}
                />
                <GraphicDragDropContext.Provider value={{ width, height }}>
                  <ItemContainer>
                    {question.config?.dropAreas?.map(dropArea => (
                      <DropAreaEdit
                        key={dropArea.id}
                        dropArea={dropArea}
                        onClick={
                          isCloning
                            ? () => {
                                const newId = uuid()
                                onChange({
                                  config: {
                                    ...question.config,
                                    dropAreas: question.config!.dropAreas!.concat({
                                      ...dropArea,
                                      id: newId,
                                      x: dropArea.x + 0.05,
                                      y: dropArea.y + 0.05,
                                    }),
                                    dragDropPairs: question.config?.dragDropPairs
                                      ? [
                                          ...question.config?.dragDropPairs,
                                          ...question.config.dragDropPairs
                                            .filter(p => p.dropAreaId === dropArea.id)
                                            .map(d => ({
                                              ...d,
                                              dropAreaId: newId,
                                            })),
                                        ]
                                      : undefined,
                                  },
                                })
                              }
                            : undefined
                        }
                        pairing={
                          pairingDraggableId
                            ? {
                                isSelected: !!question.config?.dragDropPairs?.find(
                                  ddp =>
                                    ddp.draggableId === pairingDraggableId &&
                                    ddp.dropAreaId === dropArea.id,
                                ),
                                onSelected: selected => {
                                  if (onChange) {
                                    const without =
                                      question.config?.dragDropPairs?.filter(
                                        ddp =>
                                          ddp.draggableId !== pairingDraggableId ||
                                          ddp.dropAreaId !== dropArea.id,
                                      ) || []
                                    onChange({
                                      config: {
                                        ...question.config,
                                        dragDropPairs: selected
                                          ? [
                                              ...without,
                                              {
                                                draggableId: pairingDraggableId,
                                                dropAreaId: dropArea.id,
                                              },
                                            ]
                                          : without,
                                      },
                                    })
                                  }
                                },
                              }
                            : undefined
                        }
                        onDelete={() => {
                          if (onChange) {
                            onChange({
                              config: {
                                ...question.config,
                                dropAreas: question.config?.dropAreas?.filter(
                                  d => d.id !== dropArea.id,
                                ),
                                dragDropPairs: question.config?.dragDropPairs?.filter(
                                  dp => dp.dropAreaId !== dropArea.id,
                                ),
                              },
                            })
                          }
                        }}
                        onChange={dropArea => {
                          if (onChange) {
                            onChange({
                              config: {
                                ...question.config,
                                dropAreas: question.config?.dropAreas?.map(da => {
                                  return da.id === dropArea.id ? dropArea : da
                                }),
                              },
                            })
                          }
                        }}
                      />
                    ))}
                    {question.config?.draggables?.map(draggable => (
                      <DraggableEdit
                        key={draggable.id}
                        draggable={draggable}
                        onClick={
                          isCloning
                            ? () => {
                                const newId = uuid()
                                onChange({
                                  config: {
                                    ...question.config,
                                    draggables: question.config!.draggables!.concat({
                                      ...draggable,
                                      id: newId,
                                      x: draggable.x + 0.05,
                                      y: draggable.y + 0.05,
                                    }),
                                    dragDropPairs: question.config?.dragDropPairs
                                      ? [
                                          ...question.config?.dragDropPairs,
                                          ...question.config.dragDropPairs
                                            .filter(p => p.draggableId === draggable.id)
                                            .map(d => ({
                                              ...d,
                                              draggableId: newId,
                                            })),
                                        ]
                                      : undefined,
                                  },
                                })
                              }
                            : undefined
                        }
                        pairingMode={
                          pairingDraggableId
                            ? {
                                isPairing: pairingDraggableId === draggable.id,
                                isPaired: question.config?.dragDropPairs?.some(
                                  ddp => ddp.draggableId === draggable.id,
                                ),
                              }
                            : undefined
                        }
                        onPair={() => {
                          setPairingDraggableId(draggable.id)
                        }}
                        onDelete={() => {
                          if (onChange) {
                            onChange({
                              config: {
                                ...question.config,
                                draggables: question.config?.draggables?.filter(
                                  d => d.id !== draggable.id,
                                ),
                                dragDropPairs: question.config?.dragDropPairs?.filter(
                                  dp => dp.draggableId !== draggable.id,
                                ),
                              },
                            })
                          }
                        }}
                        onChange={draggable => {
                          if (onChange) {
                            onChange({
                              config: {
                                ...question.config,
                                draggables: question.config?.draggables?.map(dr => {
                                  return dr.id === draggable.id ? draggable : dr
                                }),
                              },
                            })
                          }
                        }}
                      />
                    ))}
                  </ItemContainer>
                </GraphicDragDropContext.Provider>
              </Container>
            </ConfigProvider>
          </FullscreenContainer>
        </FullScreen>
      </QuestionInstructions>
      <QuestionAdditionalFields {...(props as QuestionProps<any>)} />
    </>
  )
}

const Header = styled.div`
  padding: ${props => props.theme['@padding-sm']};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  display: flex;
  align-items: center;
  height: 75px;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
