import styled from 'styled-components'

export const Card = styled.div<{ hovering?: boolean }>`
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@background-color-base']};
  cursor: pointer;
  transform: ${props => (props.hovering ? 'scale(1.02)' : 'scale(1)')};
  transition: transform 0.25s ease-in-out;
  position: relative;
  .footer-right {
    position: relative;
    left: ${props => (props.hovering ? props.theme['@size-xxs'] : '0')};
    transition: left 0.25s ease-in-out;
  }
  &:hover {
    transform: scale(1.02);
    .footer-right {
      left: ${props => props.theme['@size-xxs']};
    }
  }
`

export const ActionContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-s']};
  right: ${props => props.theme['@size-s']};
`

export const LargeCard = styled.div`
  background: ${props => props.theme['@gray-0']};
  border-radius: ${props => props.theme['@border-radius-large']};
  overflow: hidden;
  box-shadow: ${props => props.theme['@shadow-large']};
`
