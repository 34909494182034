import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useViewerContext } from '@thesisedu/feature-users-web'
import {
  AntIconWrapper,
  styled,
  BodyLarge,
  HSpaced,
  Modal,
  BodySmall,
  Block,
  Key,
} from '@thesisedu/web'
import { Button, Input } from 'antd'
import { Search, NavArrowRight } from 'iconoir-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchResultList } from './SearchResultList'
import { usePrefixItems } from './usePrefixItems'
import { OrderDirection, SearchQuery, SearchQueryVariables } from '../schema'
import { SearchResultNode } from '../types'
import { useSearchQueryDocument } from '../useSearchQuery'
import { useValidRenderers } from '../useSearchResultRenderer'

export interface QuickSearchProps {
  visible?: boolean
  onClose: () => void
  limit?: number
}
export function QuickSearch({ visible, onClose, limit = 5 }: QuickSearchProps) {
  const [query, setQuery] = React.useState('')
  const [dbQuery, setDbQuery] = React.useState(query)
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  const validProviders = useValidRenderers('renderList')
  const { data, loading } = useQuery<SearchQuery, SearchQueryVariables>(useSearchQueryDocument(), {
    variables: {
      query: dbQuery,
      first: 5,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Desc,
      filters: {
        providers: validProviders,
      },
    },
    skip: !dbQuery,
    fetchPolicy: 'cache-and-network',
  })
  React.useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        setDbQuery(query)
      }, 500)
      return () => clearTimeout(timeout)
    } else {
      setDbQuery('')
    }
  }, [query])
  React.useEffect(() => {
    if (!visible) {
      setQuery('')
    }
  }, [visible])
  const prefixItems = usePrefixItems(query, 'renderList', visible, Math.ceil(limit / 2))
  const nodes = (data?.search.edges
    .map(edge => edge.node)
    .filter(node => !prefixItems.some(prefixItem => prefixItem.id === node.id)) ||
    []) as SearchResultNode[]
  const results = [...prefixItems, ...nodes].slice(0, limit)
  const resultContainerRef = React.useRef<HTMLDivElement>(null)
  const showAllResults = () => {
    navigate(`/${viewer?.group.toLowerCase() || 'user'}/search?query=${encodeURIComponent(query)}`)
    onClose()
  }
  let content
  if (query && results.length) {
    content = (
      <>
        <SearchResultList results={results} onClick={() => onClose()} />
        {results.length >= limit ? (
          <Button
            type={'link'}
            style={{ margin: '0 auto', display: 'block' }}
            onClick={showAllResults}
          >
            View All Results <AntIconWrapper children={<NavArrowRight />} />
          </Button>
        ) : null}
      </>
    )
  } else if (dbQuery && dbQuery === query && !loading) {
    content = (
      <Block marginTop={'@size-m'}>
        <BodySmall color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
          No results found.
        </BodySmall>
      </Block>
    )
  } else {
    content = null
  }
  return (
    <Modal
      closeIcon={' '}
      lightMask
      onCancel={onClose}
      destroyOnClose
      visible={visible}
      width={700}
      bodyStyle={{ maxWidth: '90vw' }}
    >
      <ContentContainer>
        <FieldContainer>
          <SearchInput
            size={'large'}
            placeholder={'Search for content...'}
            autoFocus
            value={query}
            onChange={e => setQuery(e.target.value)}
            onPressEnter={showAllResults}
          />
          <BodyLarge color={'@text-color-secondary'}>
            {loading || dbQuery !== query ? (
              <LoadingOutlined style={{ display: 'block' }} />
            ) : (
              <Search style={{ display: 'block' }} />
            )}
          </BodyLarge>
        </FieldContainer>
        {content || query ? (
          <ResultContainer ref={resultContainerRef}>{content}</ResultContainer>
        ) : (
          <Block marginTop={'@size-s'}>
            <BodySmall
              style={{ textAlign: 'center', display: 'block' }}
              color={'@text-color-secondary'}
            >
              <strong>Hint!</strong> Press <Key isCtrl /> + <Key>K</Key> to open quick search.
            </BodySmall>
          </Block>
        )}
      </ContentContainer>
    </Modal>
  )
}

const FieldContainer = styled(HSpaced)`
  padding-top: ${props => props.theme['@size-m']};
`
const ResultContainer = styled.div`
  max-height: 75vh;
  overflow-y: auto;
`
const SearchInput = styled(Input)`
  box-shadow: none !important;
  background: transparent;
  outline: none !important;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`
const ContentContainer = styled.div`
  padding: 0 ${props => props.theme['@size-s']};
`
