import { useSavedReportQuery } from '../schema'

export function useSavedReport(savedReportId: string) {
  const query = useSavedReportQuery({
    variables: {
      id: savedReportId,
    },
  })
  const savedReport = query.data?.node?.__typename === 'SavedReport' ? query.data.node : undefined
  return { ...query, savedReport }
}
