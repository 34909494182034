import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form'
import React, { useState } from 'react'

export interface ControllableFormProps {
  formRef: React.Ref<ControllableForm | null>
}

interface Store {
  [key: string]: any
}
export interface ControllableForm {
  form: FormInstance
  isLoading: boolean
  submit: () => Promise<void>
}
type UseControllableForm = (callback: (values: Store) => Promise<any>) => ControllableForm
export const useControllableForm: UseControllableForm = submitCallback => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const submit = async () => {
    let values: Store
    try {
      values = await form.validateFields()
    } catch {
      // Fields are invalid, do nothing.
      return
    }
    setIsLoading(true)
    await submitCallback(values)
    setIsLoading(false)
  }

  return {
    form,
    isLoading,
    submit,
  }
}
