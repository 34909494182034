import React from 'react'

import { useProgressBarContext } from './context'
import { styled, s, HTMLProps, VSpaced, Text } from '../..'
import { HSpaced, HSpacedProps } from '../HSpaced/HSpaced'

export interface BarSegmentProps extends HTMLProps<HTMLDivElement> {
  selected?: boolean
  minValue?: number
  maxValue?: number
  label?: string
  opacity?: number
  color: s.ColorAlias
}
export function BarSegment({
  selected: _selected,
  minValue,
  maxValue,
  label,
  color,
  opacity = 1,
  ...rest
}: BarSegmentProps) {
  const { value } = useProgressBarContext(false) ?? {}
  let selected = false
  if (_selected) {
    selected = true
  } else if (value != null && minValue != null && maxValue != null) {
    selected = value >= minValue && value <= maxValue
  } else if (value != null && minValue != null) {
    selected = value > minValue
  } else if (value != null && maxValue != null) {
    selected = value <= maxValue
  }

  if (label && selected) {
    return (
      <VSpaced {...rest} align={'center'} space={'xs'} style={{ flex: 1, ...rest.style }}>
        <Indicator
          style={{ opacity: selected ? opacity : opacity * 0.15, background: s.color(color) }}
        />
        <Text level={'s'} color={color}>
          {label}
        </Text>
      </VSpaced>
    )
  } else {
    return (
      <Indicator
        {...rest}
        style={{
          opacity: selected ? opacity : opacity * 0.15,
          background: s.color(color),
          ...rest.style,
        }}
      />
    )
  }
}

export interface BarSegmentsProps extends HSpacedProps {
  children: React.ReactNode
}
export function BarSegments({ children, ...rest }: BarSegmentsProps) {
  return <HSpaced space={'xs'} align={'flex-start'} {...rest} children={children} />
}

const Indicator = styled.div`
  transition: opacity 0.1s linear;
  flex: 1;
  border-radius: 2px;
  height: 4px;
  min-height: 4px;
  width: 100%;
`
