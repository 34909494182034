import { useResource } from '@thesisedu/feature-react'
import { HSpaced } from '@thesisedu/ui'
import React from 'react'

import { RubricEditorProps } from './RubricEditor'
import { RubricEditorTemplatesAction } from './RubricEditorTemplatesAction'
import { RubricEditorActionResource } from './types'

export interface RubricEditorActionsProps extends RubricEditorProps {}
export function RubricEditorActions(props: RubricEditorActionsProps) {
  const resources = useResource<RubricEditorActionResource>('RubricEditorAction')
  return (
    <HSpaced space={'xs'}>
      {resources.map(({ identifier, component: Component }) => (
        <Component key={identifier} {...props} />
      ))}
      <RubricEditorTemplatesAction {...props} />
    </HSpaced>
  )
}
