import { SyncAssignment } from '@thesisedu/feature-class-sync-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React from 'react'

import { useSyncAssignmentActionsProps } from './useSyncAssignmentActionsProps'

export interface SegmentSyncSettingsFieldProps {
  segmentId: string
}
export function SegmentSyncSettingsField({ segmentId }: SegmentSyncSettingsFieldProps) {
  const classId = useSelectedClassId(true)
  const props = useSyncAssignmentActionsProps({ rawSegmentId: segmentId, classId })
  return <SyncAssignment {...props} />
}
