import { Question } from '@thesisedu/feature-assignments-core'
import { Tooltip, Text, HSpaced } from '@thesisedu/ui'
import { DeleteCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { FullAssignmentSubmissionFragment } from '../schema'

export interface IncompleteQuestionWarningProps {
  question: Question
  submission: FullAssignmentSubmissionFragment
}
export function IncompleteQuestionWarning({
  question,
  submission,
}: IncompleteQuestionWarningProps) {
  const hasSubmissionData = question.id in (submission.submissionData ?? {})
  if (!hasSubmissionData) {
    return (
      <Tooltip
        title={'The student did not complete this question.'}
        contentProps={{ side: 'left' }}
      >
        <HSpaced space={'xxs'}>
          <Text color={'orange'}>
            <DeleteCircle />
          </Text>
          <Text color={'orange'}>Incomplete</Text>
        </HSpaced>
      </Tooltip>
    )
  } else return null
}
