import React from 'react'

import { DropdownContextValue, useDropdownContext } from './DropdownContext'

export interface UseDropdownMenuForceMount {
  className: string
  forceMount?: boolean
  ref: React.RefObject<HTMLDivElement>
}
export function useDropdownMenuForceMount(
  context?: DropdownContextValue,
): UseDropdownMenuForceMount {
  const existingContext = useDropdownContext(false)
  const { forceMount: contextForceMount } = context ?? existingContext ?? {}
  const contentRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    setTimeout(() => {
      if (contentRef.current) {
        const calculatedStyle = getComputedStyle(contentRef.current)
        contentRef.current.parentElement!.style.zIndex = calculatedStyle.zIndex
      }
    }, 1)
  }, [contextForceMount])

  return {
    ref: contentRef,
    className: contextForceMount ? 'force-mount' : '',
    forceMount: contextForceMount,
  }
}
