import { flattenSegments, isVisibleToStudent } from '@thesisedu/feature-course-types'
import { Format } from '@thesisedu/feature-utils'
import { Body, StyledThemeContext, styled } from '@thesisedu/web'
import { Progress } from 'antd'
import React from 'react'

import { useOutlineSegmentContext } from './OutlineSegmentContext'
import { Segment } from '../types'

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: ${props => props.theme['@size-xs']};
  }
`

export interface SegmentInfoCoverageProps {
  segment: Segment
}
export function SegmentInfoCoverage({ segment }: SegmentInfoCoverageProps) {
  const theme = React.useContext(StyledThemeContext)
  const { enabledSegmentIds } = useOutlineSegmentContext()
  const validSegmentIds: string[] = flattenSegments([segment])
    .filter(s => {
      return isVisibleToStudent(s)
    })
    .map(s => s.id)
  const enabled = validSegmentIds.reduce<number>(
    (acc, id) => acc + (enabledSegmentIds.includes(id) ? 1 : 0),
    0,
  )
  const percentage = enabled / validSegmentIds.length
  return (
    <ProgressContainer>
      <Progress
        type={'circle'}
        showInfo={false}
        strokeColor={theme['@primary-color']}
        trailColor={theme['@gray-3']}
        strokeWidth={16}
        strokeLinecap={'butt'}
        width={16}
        percent={percentage * 100}
      />
      <Body>{Format.number(percentage, 'percentage')} covered</Body>
    </ProgressContainer>
  )
}
