import { DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import { Body, styled } from '@thesisedu/web'
import { Popconfirm, Button } from 'antd'
import Case from 'case'
import React, { HTMLProps } from 'react'

import { Question } from '../questions/Question'
import { QuestionTextMediaField } from '../questions/QuestionTextMediaField'
import { useQuestionResource } from '../questions/useQuestionResource'
import { QuestionProps } from '../types'

export type HoverProps = Pick<HTMLProps<HTMLDivElement>, 'onMouseEnter' | 'onMouseLeave'>
export interface QuestionDraggableProps extends QuestionProps {
  index: number
  totalQuestions: number
}
export function QuestionDraggable({ index, totalQuestions, ...props }: QuestionDraggableProps) {
  const resource = useQuestionResource(props.question.type)

  return (
    <Container>
      <div className={'header'}>
        <Body color={'@text-color-secondary'}>
          Question {index + 1} of {totalQuestions}
        </Body>
        <Body className={'question-type'} color={'@text-color-secondary'}>
          {resource.icon ? <resource.icon /> : null}&nbsp;&nbsp;
          {Case.title(resource.label || props.question.type)}
        </Body>
        <Popconfirm
          title={'Are you sure?'}
          onConfirm={props.onDelete}
          okType={'danger'}
          okText={'Yes, delete'}
          placement={'topRight'}
        >
          <Button icon={<DeleteOutlined />} size={'small'} />
        </Popconfirm>
        {props.onClone ? (
          <Button icon={<CopyOutlined />} onClick={props.onClone} size={'small'} />
        ) : null}
      </div>
      <div className={'name-container'}>
        <QuestionTextMediaField
          value={props.question.name}
          onChange={value => {
            if (props.onChange) {
              props.onChange({ name: value })
            }
          }}
          textAreaProps={{
            placeholder: 'Question Name',
            autoSize: { minRows: 1, maxRows: 3 },
          }}
        />
      </div>
      <Question {...props} />
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${props => props.theme['@border-radius-base']};
  margin: ${props => props.theme['@size-l']} 0;
  background: white;
  &:first-child {
    margin-top: 0;
  }
  > .header {
    display: flex;
    align-items: center;
    margin: 0 0 ${props => props.theme['@size-xs']};
    > * {
      line-height: 1;
    }
    > .dragger {
      background: white;
      font-size: ${props => props.theme['@size-m']};
      color: ${props => props.theme['@gray-4']};
    }
    > .question-type {
      margin-left: auto !important;
      margin-right: ${props => props.theme['@size-s']} !important;
    }
    > :not(:last-child) {
      margin-right: ${props => props.theme['@size-xs']};
    }
  }
  > .name-container {
    input,
    textarea {
      width: 100%;
    }
  }
`
