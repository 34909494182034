import { Progress } from 'antd'
import React from 'react'

import { Body } from './Typography'
import { styled, ThemeContext as StyledThemeContext } from './styledTypes'

export interface ProgressOverlayProps {
  current: number
  total: number
}
export function ProgressOverlay({ current, total }: ProgressOverlayProps) {
  const loaded = current >= total || !total
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (!loaded) {
      const handle = setTimeout(() => {
        setVisible(true)
      }, 250)
      return clearTimeout(handle)
    } else {
      setVisible(false)
    }
  }, [loaded])
  const theme = React.useContext(StyledThemeContext)
  return (
    <Container className={visible ? '' : 'loaded'}>
      <Body color={'@text-color-secondary'}>
        Loaded {current} / {total}
      </Body>
      <Progress
        showInfo={false}
        strokeColor={theme['@text-color-secondary']}
        percent={total ? (current / total) * 100 : 0}
        style={{ width: 400 }}
      />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.25s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: ${props => props.theme['@white']};
  padding: ${props => props.theme['@padding-lg']};
  &.loaded {
    pointer-events: none;
    opacity: 0;
  }
`
