import React from 'react'

import { LargeConfigurationButton } from './LargeConfigurationButton'
import { ReportMetricInput } from '../../schema'
import { ReportMetricResource } from '../types'

export interface LargeMetricButtonProps {
  metric: ReportMetricResource
  value?: ReportMetricInput | null
  onChange: (input: ReportMetricInput | null) => void
}
export function LargeMetricButton({ metric, value, onChange }: LargeMetricButtonProps) {
  return (
    <LargeConfigurationButton
      title={metric.title}
      icon={metric.icon}
      description={metric.description}
      selected={!!value}
      onClick={() => {
        if (onChange) {
          onChange({ [metric.identifier]: {} })
        }
      }}
    />
  )
}
