import { ImportOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, message } from 'antd'
import { DropDownProps } from 'antd/es/dropdown'
import React from 'react'

import { useAvailableGradeCategoryImportsQuery } from '../schema'

const LOADING_KEY = 'import-grade-category-loading'

export interface ImportAssignmentCategoryButtonProps extends Omit<DropDownProps, 'overlay'> {
  classId: string
  onImport: (sourceId: string) => Promise<void>
}

export function ImportAssignmentCategoryButton({
  classId,
  onImport,
  ...props
}: ImportAssignmentCategoryButtonProps) {
  const [importing, setImporting] = React.useState(false)
  const { data, loading } = useAvailableGradeCategoryImportsQuery({
    variables: {
      id: classId,
    },
  })
  const availableGradeCategoryImports =
    data?.node?.__typename === 'Class' ? data.node.availableGradeCategoryImports : undefined
  if (availableGradeCategoryImports?.length || loading) {
    const availableGradeCategoryContent = availableGradeCategoryImports
      ? availableGradeCategoryImports.map(category => {
          return (
            <Menu.Item
              key={category.sourceId}
              onClick={async () => {
                setImporting(true)
                message.loading({
                  content: 'Importing grade categories...',
                  key: LOADING_KEY,
                })
                try {
                  await onImport(category.sourceId)
                } finally {
                  message.destroy(LOADING_KEY)
                  setImporting(false)
                }
              }}
            >
              {category.name}
            </Menu.Item>
          )
        })
      : null
    return (
      <Dropdown
        overlay={<Menu>{availableGradeCategoryContent}</Menu>}
        arrow
        placement={'bottomRight'}
      >
        <Button loading={loading || importing} {...props}>
          <ImportOutlined />
          Import Categories
        </Button>
      </Dropdown>
    )
  } else {
    return null
  }
}
