import React from 'react'

export interface WrapperChildrenProps {
  width: string | any
  height: string | any
  [key: string]: any
}
export interface AntIconWrapperProps {
  children: React.ReactElement<WrapperChildrenProps>
  className?: string
}
/** @deprecated */
export function AntIconWrapper({ children, className }: AntIconWrapperProps) {
  return (
    <span className={['anticon', className].filter(Boolean).join(' ')}>
      {React.cloneElement(children, {
        width: '1em',
        height: '1em',
      })}
    </span>
  )
}
