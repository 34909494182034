import { ScrollableContainerProvider } from '@thesisedu/ui'
import React from 'react'
import FullScreen from 'react-full-screen'
import { useHotkeys } from 'react-hotkeys-hook'

import { _EventsContainer } from './EventsContainer'
import { useMediaPlaybackContext } from './MediaPlaybackContext'
import { Container } from './PlayerCaption'
import { styledContainer } from '../styled'

let ConfigProvider: React.FC<any>
try {
  ConfigProvider = require('antd').ConfigProvider
} catch {
  ConfigProvider = ({ children }) => <>{children}</>
}

export interface PlayerWrapperProps {
  children: React.ReactElement
  containerRef: React.RefObject<any>
}
export function PlayerWrapper({ children, containerRef }: PlayerWrapperProps) {
  const { fullscreen, setFullscreen, onSetPlaying } = useMediaPlaybackContext()
  useHotkeys('space', () => {
    onSetPlaying(p => !p)
  })
  return (
    <FullScreen enabled={fullscreen} onChange={setFullscreen}>
      <ScrollableContainerProvider targetRef={containerRef}>
        <ConfigProvider
          getPopupContainer={() =>
            fullscreen ? containerRef.current || document.body : document.body
          }
          children={children}
        />
      </ScrollableContainerProvider>
    </FullScreen>
  )
}

export interface FooterWrapperProps {
  setOverFooter: (overFooter: boolean) => void
  footerVisible?: boolean
  children: React.ReactElement
}
export function FooterWrapper({ setOverFooter, footerVisible, children }: FooterWrapperProps) {
  return (
    <FooterWrapperStyle
      style={{ pointerEvents: 'all' }}
      onMouseEnter={() => setOverFooter(true)}
      onMouseLeave={e => {
        const relatedTarget = e.relatedTarget as HTMLElement
        // For elements rendered into the footer as a portal, we need to make sure they
        // truly aren't a child of the footer before we set overFooter to false.
        if (
          !(relatedTarget?.closest && relatedTarget?.closest(FooterWrapperStyle)) ||
          relatedTarget?.classList.contains(_EventsContainer.toString().replace('.', ''))
        ) {
          setOverFooter(false)
        }
      }}
      children={children}
      data-footer-visible={footerVisible ? 'true' : undefined}
    />
  )
}

const FooterWrapperStyle = styledContainer`
  &[data-footer-visible='true'] ${Container} {
    transform: translateY(-60px) !important;
  }
`
