import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  TeamTeachingClassFragmentDoc,
  useRemoveTeacherFromClassMutation as useMutation,
} from '../schema'

export const useRemoveTeacherFromClassMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error removing the teacher.'),
    update: updateMutation({
      fragment: TeamTeachingClassFragmentDoc,
      fragmentName: 'TeamTeachingClass',
      path: 'removeTeacherFromClass.class',
    }),
    ...opts,
  })
