import { BrochureLink, useFeature, useFeatureRoot } from '@thesisedu/feature-react'
import { Shell } from '@thesisedu/feature-web'
import { BodySmall, styled } from '@thesisedu/react'
import { OpenNewWindow } from '@thesisedu/react/icons'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { UsersReactFeature } from '../../UsersReactFeature'

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => (props.theme as any)['@gray-1']} !important;
  }
`
const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  padding: 16px;
`

const Page = styled.div`
  border-radius: ${props => props.theme['@size-s']};
  padding: ${props => props.theme['@size-m']};
  background: ${props => props.theme['@gray-0']};
  width: 100%;
  min-height: 70vh;
  margin: ${props => props.theme['@size-m']} 0 ${props => props.theme['@size-xxl']} 0;
  display: flex;
  flex-direction: column;
`

const Logo = styled(Shell.Icon)`
  width: ${props => props.theme['@size-l']};
`
const LogoContainer = styled(BrochureLink)`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-l']};
  > :last-child {
    margin-left: auto;
    padding-left: 0.5em;
  }
`

function DefaultAuthWrapper({ children }: AuthWrapperProps) {
  const { name } = useFeatureRoot().appOptions
  return (
    <PageContainer>
      <GlobalStyle />
      <Page>
        <LogoContainer to={'/'}>
          <Logo />
          <BodySmall>
            {name} Home <OpenNewWindow />
          </BodySmall>
        </LogoContainer>
        <div style={{ marginTop: 'auto' }}>{children}</div>
      </Page>
    </PageContainer>
  )
}

export interface AuthWrapperProps {
  children: React.ReactElement
}
export function AuthWrapper({ children }: AuthWrapperProps) {
  const feature = useFeature(UsersReactFeature)
  if (feature.options.getAuthWrapper) {
    const Component = feature.options.getAuthWrapper()
    return <Component>{children}</Component>
  } else return <DefaultAuthWrapper>{children}</DefaultAuthWrapper>
}
