import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import { MediaView } from '../../view/MediaView'
import { SidebarTab } from '../api/SidebarTab'

export interface PipMediaPluginProps {
  children?: React.ReactElement
  mediaId?: string
  title?: string
}
export function PipMediaPlugin({ children: _children, mediaId, title }: PipMediaPluginProps) {
  let children = _children
  if (mediaId) {
    children = <MediaView mediaId={mediaId} />
  }

  if (children) {
    return (
      <SidebarTab icon={<Play />} title={title ?? 'Media'} weight={-100}>
        <div children={children} style={{ width: '100%' }} />
      </SidebarTab>
    )
  } else return null
}
