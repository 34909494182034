import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'

export function EmptyEditorPlugin() {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    return editor.registerTextContentListener(text => {
      const rootElement = editor.getRootElement()
      const hasText = !!text.trim()
      if (rootElement) {
        if (hasText) {
          rootElement.classList.remove('empty')
        } else {
          rootElement.classList.add('empty')
        }
      }
    })
  }, [editor])

  return null
}
