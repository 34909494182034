import React from 'react'

import { Enlarge, Reduce } from '../../../icons'
import { useMediaPlaybackContext } from '../MediaPlaybackContext'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

export function Fullscreen({ compact }: PlayerFooterProps) {
  const { duration, setFullscreen, fullscreen } = useMediaPlaybackContext()
  if (duration) {
    const IconComponent = fullscreen ? Reduce : Enlarge
    return (
      <ControlContainer compact={compact !== undefined} onClick={() => setFullscreen(f => !f)}>
        <IconComponent />
      </ControlContainer>
    )
  } else {
    return null
  }
}
