import React from 'react'

import { BaseWidgetConfig, SettingsDropdownProps, useWidgetConfig } from './simple'
import { Alignment } from './styled'
import { AlignmentSetting } from '../../../lexical/ui/SettingsDropdown/AlignmentSetting'

interface AlignmentConfig {
  alignment?: Alignment
}
export function WidgetAlignmentSetting<Config extends AlignmentConfig & BaseWidgetConfig>({
  editor,
  node,
}: SettingsDropdownProps<Config>) {
  const [alignment, setAlignment] = useWidgetConfig(editor, node, 'alignment')
  return <AlignmentSetting alignment={alignment || 'center'} onChange={setAlignment} />
}
