import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { Switch, SwitchProps, Tooltip } from '@thesisedu/react'
import React, { useState } from 'react'

import { EnableTutorialPopover } from './EnableTutorialPopover'
import { ClassFragmentWithPermissions } from '../types'

export interface EnableSwitchProps extends Partial<SwitchProps> {
  isPartiallyEnabled?: boolean
}
export function EnableSwitch({ isPartiallyEnabled, ...props }: EnableSwitchProps) {
  return <Switch {...props} color={isPartiallyEnabled ? '@blue' : undefined} />
}

export interface SegmentEnableProps {
  isEnabled?: boolean
  isPartiallyEnabled?: boolean
  setSegmentEnable: (segmentId: string, enableed: boolean) => Promise<void>
  segmentId: string
  disabled?: boolean
}
const _SegmentEnable: React.FC<React.PropsWithChildren<SegmentEnableProps>> = ({
  isEnabled,
  isPartiallyEnabled,
  setSegmentEnable,
  segmentId,
  disabled: _disabled,
}) => {
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const disabled = _disabled || !cls?.canEnableSegments
  const [loading, setLoading] = useState(false)
  const children = (
    <EnableTutorialPopover>
      <EnableSwitch
        onChange={enabled => {
          setLoading(true)
          setSegmentEnable(segmentId, enabled).finally(() => setLoading(false))
        }}
        loading={loading}
        checked={isEnabled || isPartiallyEnabled}
        isPartiallyEnabled={isPartiallyEnabled}
        disabled={disabled}
        data-testid={'enable-switch'}
      />
    </EnableTutorialPopover>
  )

  if (isPartiallyEnabled) {
    return (
      <Tooltip
        title={'This content is only enabled for certain students. Click to disable for all.'}
        placement={'topRight'}
        css
        mouseEnterDelay={0}
      >
        {children}
      </Tooltip>
    )
  } else if (!cls?.canEnableSegments) {
    return (
      <Tooltip
        css
        placement={'topRight'}
        title={'You do not have permission to enable or disable content.'}
        mouseEnterDelay={0}
      >
        {children}
      </Tooltip>
    )
  } else {
    return children
  }
}

export const SegmentEnable = React.memo(_SegmentEnable)
