import { HistoryState, createEmptyHistoryState } from '@lexical/react/LexicalHistoryPlugin'
import React from 'react'

type ContextShape = {
  historyState?: HistoryState
}
const Context: React.Context<ContextShape> = React.createContext({})

export function SharedHistoryContext({ children }: React.PropsWithChildren<object>) {
  const historyContext = React.useMemo(() => ({ historyState: createEmptyHistoryState() }), [])
  return <Context.Provider value={historyContext} children={children} />
}

export function useSharedHistoryContext(): ContextShape {
  return React.useContext(Context)
}
