import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { SegmentMetadata } from '@thesisedu/feature-course-types'
import React, { useContext, useMemo, useRef } from 'react'

import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { useCourseContext } from '../contexts/CourseContext'
import { overrideSegment, toggleSegments } from '../helpers'
import {
  AfterSegmentEnableChangedHook,
  CoursesHooks,
  SegmentEnableRefetchQueriesContext,
  SegmentEnableRefetchQueriesPayload,
} from '../types'

export interface OutlineSegmentContextValue {
  lockedSegmentIds: string[]
  setSegmentLock: (segmentId: string, locked: boolean) => Promise<void>
  enabledSegmentIds: string[]
  partiallyEnabledSegmentIds: string[]
  setSegmentEnabled: (segmentId: string | string[], enabled: boolean) => Promise<void>
}
export const OutlineSegmentContext = React.createContext<OutlineSegmentContextValue | null>(null)

export const useOutlineSegmentContext = (): OutlineSegmentContextValue => {
  const context = useContext(OutlineSegmentContext)
  if (!context) {
    throw new Error('cannot render without OutlineSegmentContext')
  }
  return context
}

export const OutlineSegmentContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const cls = useSelectedClass(true)
  const root = useFeatureRoot()!
  const { segmentMetadata = {} } = useCourseContext(true)
  const [updateClass] = useUpdateClassMutation(cls.id)
  const metadataRef = useRef<SegmentMetadata>(segmentMetadata)
  const handlers = useMemo<Partial<OutlineSegmentContextValue>>(
    () => ({
      async setSegmentLock(segmentId, locked) {
        await updateClass({
          segmentMetadata: overrideSegment(segmentId, { isLocked: locked }, metadataRef.current),
        })
      },
      async setSegmentEnabled(segmentId, enabled) {
        await updateClass(
          {
            segmentMetadata: toggleSegments(
              Array.isArray(segmentId) ? segmentId : [segmentId],
              metadataRef.current,
              enabled,
            ),
          },
          false,
          false,
          {
            async onCompleted() {
              await root.deps.hookManager.mutateHook<AfterSegmentEnableChangedHook>(
                'feature-courses-react:after-segment-enable-changed',
                undefined,
                {
                  segmentId,
                  classId: cls.id,
                  enabled,
                },
              )
            },
            refetchQueries: root.deps.hookManager.mutateHookSync<
              SegmentEnableRefetchQueriesPayload,
              SegmentEnableRefetchQueriesContext
            >(CoursesHooks.SegmentEnableRefetchQueries, [], {
              segmentId,
              classId: cls.id,
              enabled,
            }),
          },
        )
      },
    }),
    [updateClass],
  )
  const context = useMemo(() => {
    metadataRef.current = segmentMetadata
    const lockedSegmentIds =
      segmentMetadata.segments?.reduce<string[]>((acc, segment) => {
        if (segment.isLocked) return [...acc, segment.id]
        else return acc
      }, []) || []
    const enabledSegmentIds =
      segmentMetadata.segments?.filter(s => s.visibleOverride === true).map(s => s.id) || []
    const partiallyEnabledSegmentIds =
      segmentMetadata.segments?.filter(s => s.enabledStudentIds?.length).map(s => s.id) || []
    return {
      lockedSegmentIds,
      enabledSegmentIds,
      partiallyEnabledSegmentIds,
    }
  }, [updateClass, segmentMetadata])
  return (
    <OutlineSegmentContext.Provider
      value={
        {
          ...context,
          ...handlers,
        } as OutlineSegmentContextValue
      }
      children={children}
    />
  )
}
