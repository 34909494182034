import { useStateAndFreshRef } from '@thesisedu/feature-react'
import { ElementNode, LexicalEditor, LexicalNode } from 'lexical'
import React from 'react'
import ReactDOM from 'react-dom'

import { getResizableElement, GetResizableElementOpts } from './getResizableElement'
import { isHTMLElement } from './guard'
import {
  hideResizeLine,
  ResizeLine,
  setResizeLine,
  SetResizeLineOpts,
  startDrag,
  stopDrag,
} from './resizeLine'

export interface DragOpts<Node extends LexicalNode = ElementNode> {
  node: Node
  element: HTMLElement
  line: HTMLElement
}
export interface MoveDragOpts<
  Node extends LexicalNode = ElementNode,
  Context extends object = object,
> extends DragOpts<Node> {
  context: Context
}
export interface LineAlignment {
  alignment: 'left' | 'right'
  offset?: number
}
export interface UseResizableElement {
  children: React.ReactElement
  resizeLineRef: React.RefObject<HTMLDivElement>
}
export interface UseResizableElementOpts<
  Node extends LexicalNode = ElementNode,
  Context extends object = object,
> {
  editor: LexicalEditor
  anchorElement: HTMLElement
  filterNode: GetResizableElementOpts['filterNode']
  beforeDrag?: (opts: DragOpts<Node>) => Context | false
  onMove: (e: MouseEvent, opts: MoveDragOpts<Node, Context>) => void
  getLineAlignment?: (element: HTMLElement, node: Node) => LineAlignment
  getLineElement?: SetResizeLineOpts['getElement']
}
export function useResizableElement<
  Node extends LexicalNode = ElementNode,
  Context extends object = object,
>({
  editor,
  anchorElement,
  filterNode,
  beforeDrag,
  onMove,
  getLineAlignment,
  getLineElement,
}: UseResizableElementOpts<Node, Context>): UseResizableElement {
  const prevIndexRef = React.useRef(Infinity)
  const resizeLineRef = React.useRef<HTMLDivElement>(null)
  const lineAlignmentRef = React.useRef<LineAlignment>({ alignment: 'right' })
  const scrollerElement = anchorElement.parentElement
  const [resizableElement, resizableElementRef, setResizableElement] =
    useStateAndFreshRef<HTMLElement | null>(null)
  const resizableNodeRef = React.useRef<Node | null>(null)
  const isDraggingRef = React.useRef(false)

  // Find the resizableElement whenever the mouse moves.
  React.useEffect(() => {
    function onMouseMove(event: MouseEvent) {
      if (isDraggingRef.current) return

      const target = event.target
      if (!isHTMLElement(target)) {
        setResizableElement(null)
        return
      }

      const result = getResizableElement(editor, event, {
        prevIndex: prevIndexRef.current,
        offset: lineAlignmentRef.current.offset,
        filterNode,
      })
      if (result && result.blockNode) {
        setResizableElement(result.blockElement)
        resizableNodeRef.current = result.blockNode
        if (getLineAlignment) {
          lineAlignmentRef.current = getLineAlignment(result.blockElement, result.blockNode)
        }
      } else {
        setResizableElement(null)
        resizableNodeRef.current = null
      }
    }

    function onMouseLeave() {
      if (isDraggingRef.current) return
      setResizableElement(null)
    }

    scrollerElement?.addEventListener('mousemove', onMouseMove)
    scrollerElement?.addEventListener('mouseleave', onMouseLeave)
    return () => {
      scrollerElement?.removeEventListener('mousemove', onMouseMove)
      scrollerElement?.removeEventListener('mouseleave', onMouseLeave)
    }
  }, [scrollerElement, anchorElement, editor])

  // Adjust the resize line position whenever the resizable element changes.
  React.useEffect(() => {
    if (resizableElement && resizeLineRef.current) {
      setResizeLine(resizeLineRef.current, resizableElement, anchorElement, {
        alignment: lineAlignmentRef.current.alignment,
        offset: lineAlignmentRef.current.offset,
        getElement: getLineElement,
      })
    } else {
      hideResizeLine(resizeLineRef.current)
    }
  }, [anchorElement, resizableElement])

  const children = ReactDOM.createPortal(
    <ResizeLine
      ref={resizeLineRef}
      onMouseDown={() => {
        const node = resizableNodeRef.current
        const element = resizableElementRef.current
        const line = resizeLineRef.current
        if (!node || !element || !line) return

        let context = {}
        if (beforeDrag) {
          context = beforeDrag({ node, element, line })
        }
        if (!context) return

        isDraggingRef.current = true
        startDrag(line)
        document.body.style.cursor = 'col-resize'
        const move = (e: MouseEvent) => {
          e.preventDefault()
          onMove(e, { node, element, line, context: context as Context })
        }
        const up = () => {
          isDraggingRef.current = false
          stopDrag(line)
          window.removeEventListener('mousemove', move)
          window.removeEventListener('mouseup', up)
          document.body.style.cursor = ''
        }
        window.addEventListener('mousemove', move)
        window.addEventListener('mouseup', up)
      }}
    >
      <div />
    </ResizeLine>,
    anchorElement,
  )

  return {
    children,
    resizeLineRef,
  }
}
