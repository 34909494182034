import { getFragmentDefinitions } from '@apollo/client/utilities'
import { addFragmentReference, useModifiedFragment } from '@thesisedu/feature-apollo-react'

import BackgroundJobReporter from './BackgroundJobReporter'
import SegmentSyncIndicator from './SegmentSyncIndicator'
import SyncAction from './SyncAction'
import { CoursesReactFeature } from '../../CoursesReactFeature'
import { SegmentDecorationWithAssignmentSyncFragmentDoc } from '../../schema'

export default function (feature: CoursesReactFeature) {
  useModifiedFragment(feature.hookManager, 'SegmentDecoration', (fragment, context) => {
    return addFragmentReference(
      fragment,
      getFragmentDefinitions(SegmentDecorationWithAssignmentSyncFragmentDoc),
      context,
    )
  })
  SegmentSyncIndicator(feature)
  SyncAction(feature)
  BackgroundJobReporter(feature)
}
