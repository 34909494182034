import {
  useMinimalTeacherClassesLazyQuery,
  useTeacherClassLazyQuery,
} from '@thesisedu/feature-classes-react/dist/schema'
import { useBackgroundJob } from '@thesisedu/feature-users-react'
import { BackgroundJobStatus } from '@thesisedu/feature-users-react/dist/schema'
import { ProgressBar$, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { LoadingStep } from '../Loading/LoadingStep'

export interface RosteringProgressProps {
  jobId: string
  providerIdentifier: string
  providerName: string
  classId: string
  onError: () => void
  onSuccess: () => void
}
export function RosteringProgress({
  classId,
  providerIdentifier,
  providerName,
  jobId,
  onError,
  onSuccess,
}: RosteringProgressProps) {
  const { progress, status } = useBackgroundJob(jobId) ?? {}
  const [fetchStudentsAndTeachers, { loading: refetchingClass }] = useTeacherClassLazyQuery({
    variables: { id: classId },
    fetchPolicy: 'cache-and-network',
  })
  const [fetchAllClasses] = useMinimalTeacherClassesLazyQuery({
    fetchPolicy: 'cache-and-network',
  })
  React.useEffect(() => {
    if (status === BackgroundJobStatus.Error) {
      onError()
    } else if (status === BackgroundJobStatus.Success) {
      Promise.all([fetchStudentsAndTeachers(), fetchAllClasses()]).finally(() => {
        onSuccess()
      })
    }
  }, [status])

  return (
    <LoadingStep
      providerIdentifier={providerIdentifier}
      providerName={providerName}
      direction={'left'}
      title={'Updating Students...'}
      message={'This should only take a few moments.'}
      action={
        <ProgressBar$.Container
          aria-label={'Updating Students'}
          maxValue={progress?.total ?? 100}
          value={refetchingClass ? progress?.total ?? 100 : progress?.current ?? 0}
          valueLabel={
            refetchingClass
              ? 'Finishing up'
              : progress && progress.current > 1
              ? `${Math.round(progress.current - 1)} / ${progress.total - 1} students updated`
              : 'Getting ready'
          }
        >
          <VSpaced space={'xs'} style={{ width: 400 }}>
            <ProgressBar$.Bar />
            <ProgressBar$.ValueLabel level={'xs'} color={'secondary'} />
          </VSpaced>
        </ProgressBar$.Container>
      }
    />
  )
}
