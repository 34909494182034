import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React, { useContext } from 'react'

import { useClassImplementationGuideReportQuery } from '../schema'

interface GradeReportMap {
  [implementationGuide: string]: number | null | undefined
}
export interface ImplementationGuideContextValue {
  gradeReport: GradeReportMap
  loading: boolean
}
export const ImplementationGuideContext = React.createContext<
  ImplementationGuideContextValue | undefined
>(undefined)

export const ImplementationGuideContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const classId = useSelectedClassId(true)
  const { data, loading } = useClassImplementationGuideReportQuery({
    variables: { classId },
  })
  const gradeReport = (
    data?.classImplementationGuideReport.implementationGuides || []
  ).reduce<GradeReportMap>((acc, ig) => {
    return { ...acc, [ig.implementationGuide]: ig.grade }
  }, {})
  const context: ImplementationGuideContextValue = {
    gradeReport,
    loading,
  }
  return <ImplementationGuideContext.Provider value={context} children={children} />
}

export const useImplementationGuideContext = () => {
  return useContext(ImplementationGuideContext)
}
