import { useImpersonation } from '@thesisedu/feature-apollo-react'
import { ViewDetailedLog } from '@thesisedu/feature-users-react/dist/devtools/DetailedLogs/ViewDetailedLog'
import { Result } from '@thesisedu/react'
import { styled, s, LoadingIndicator } from '@thesisedu/ui'
import React from 'react'
import { ObjectInspector } from 'react-inspector'

import { useClassSyncHistoryNodeQuery } from '../schema'

export interface ViewSyncHistoryItemProps {
  id: string
}
export function ViewSyncHistoryItem({ id }: ViewSyncHistoryItemProps) {
  const { coreAuthKey } = useImpersonation()
  const { data, loading } = useClassSyncHistoryNodeQuery({
    context: {
      headers: {
        Authorization: `Bearer ${coreAuthKey}`,
      },
    },
    variables: {
      id,
    },
    skip: !coreAuthKey,
    fetchPolicy: 'no-cache',
  })
  const history = data?.node?.__typename === 'ClassSyncHistory' ? data.node : undefined

  return (
    <Container>
      <Left>
        {history ? (
          <ObjectInspector data={history} name={'history'} />
        ) : loading ? (
          <LoadingIndicator.Labeled label={'Loading history...'} />
        ) : (
          <Result.Error description={'There was an error loading that history.'} />
        )}
      </Left>
      <Right>
        {history?.logUrl ? <ViewDetailedLog detailedLog={{ signedUrl: history.logUrl }} /> : null}
      </Right>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  min-height: 0;
  height: 100%;
`
const Left = styled.div`
  padding: ${s.size('s')};
  width: 250px;
  display: flex;
  overflow: auto;
  border-right: solid 1px ${s.color('border')};
  max-height: 100%;
  min-height: 0;
`
const Right = styled.div`
  flex: 1;
`
