import { ApolloClient, useApolloClient } from '@apollo/client'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { CommentList, PostComment } from '@thesisedu/feature-comments-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import {
  HSpaced,
  Space,
  Block,
  styled,
  Body,
  H3Alternate,
  NotFound,
  BlockSpin,
  BodySmall,
} from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteThreadButton } from './DeleteThreadButton'
import { RemoveFromThreadButton } from './RemoveFromThreadButton'
import { getRecipients } from '../helpers'
import {
  CommentFragment,
  DirectMessageThreadDocument,
  DirectMessageThreadFragment,
  DirectMessageThreadFragmentDoc,
  DirectMessageThreadQuery,
  DirectMessageThreadQueryResult,
  DirectMessageThreadQueryVariables,
  useDirectMessageThreadQuery,
} from '../schema'

function _updateThreadFragment(client: ApolloClient<any>, threadId: string) {
  const common = {
    fragment: DirectMessageThreadFragmentDoc,
    fragmentName: 'DirectMessageThread',
    id: `DirectMessageThread:${threadId}`,
  }
  const fragment = client.readFragment<DirectMessageThreadFragment>(common)
  if (fragment) {
    client.writeFragment<DirectMessageThreadFragment>({
      ...common,
      data: {
        ...fragment,
        comments: {
          ...fragment.comments,
          unread: 0,
        },
      },
    })
  }
}

export interface DirectMessageThreadProps {
  threadId: string
}
export function DirectMessageThread({ threadId }: DirectMessageThreadProps) {
  const viewer = useViewerContext(false)
  const refetchRef = React.useRef<DirectMessageThreadQueryResult['refetch']>()
  const navigate = useNavigate()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const client = useApolloClient()
  const { data } = useDirectMessageThreadQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: threadId,
    },
  })
  const thread = data?.node?.__typename === 'DirectMessageThread' ? data.node : undefined
  React.useEffect(() => {
    if (viewer?.id) {
      return () => {
        setTimeout(() => {
          client.cache.modify({
            id: `User:${viewer.id}`,
            fields: {
              directMessageThreads(cached, { INVALIDATE }) {
                return INVALIDATE
              },
            },
          })
        }, 5000) // Wait for interactions to post.
      }
    }
  }, [])
  return (
    <Container>
      <div className={'comments'} ref={containerRef}>
        <InfiniteQuery<CommentFragment, DirectMessageThreadQuery, DirectMessageThreadQueryVariables>
          resultPath={'node.allComments'}
          document={DirectMessageThreadDocument}
          variables={{ id: threadId }}
          queryOpts={{
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
            pollInterval: 15 * 1000, // Poll every 15 seconds for new messages.
          }}
          infiniteScrollerProps={{
            scrollableTarget: containerRef.current || undefined,
          }}
          children={({ data, loading, refetch }) => {
            refetchRef.current = refetch
            const thread = data?.node?.__typename === 'DirectMessageThread' ? data.node : undefined
            if (thread) {
              const isOwner = viewer?.id === thread.user.id && viewer?.group === 'TEACHER'
              if (thread.comments.unread) {
                // We have to call this every time the comments are marked as unread, because
                // the interaction may not have pushed yet.
                _updateThreadFragment(client, thread.id)
              }
              return (
                <>
                  <Block marginBottom={'@size-m'}>
                    <HSpaced>
                      <div>
                        <Body color={'@text-color-secondary'}>
                          Thread with {getRecipients(thread)}
                        </Body>
                        <H3Alternate isBlock>{thread.subject}</H3Alternate>
                      </div>
                      <Space />
                      {isOwner ? (
                        <DeleteThreadButton
                          threadId={thread.id}
                          onCompleted={() => navigate('..')}
                        />
                      ) : null}
                    </HSpaced>
                  </Block>
                  <CommentList
                    commentableId={threadId}
                    comments={thread.allComments.edges.map(edge => edge.node)}
                    onCommentDeleted={() => {
                      refetch({ id: threadId })
                    }}
                    additionalActions={
                      viewer?.group === 'TEACHER'
                        ? comment => {
                            if (viewer?.id === comment.user.id) return undefined
                            if (thread?.user.id === comment.user.id) return undefined
                            // Only remove students from threads.
                            if (comment.user.group !== 'STUDENT') return undefined
                            // You can't remove the last-remaining member of the thread.
                            if (thread?.recipients.length === 1) return undefined
                            if (thread?.recipients.find(r => r.id === comment.user.id)) {
                              return [
                                <RemoveFromThreadButton
                                  userId={comment.user.id}
                                  threadId={thread.id}
                                  key={'remove-from-thread'}
                                />,
                              ]
                            } else {
                              return [
                                <BodySmall
                                  style={{ opacity: 0.75, display: 'inline' }}
                                  key={'remove-from-thread'}
                                >
                                  Removed from Thread
                                </BodySmall>,
                              ]
                            }
                          }
                        : undefined
                    }
                    style={'longform'}
                    useRichText
                    allowDeletingAll={viewer?.group === 'TEACHER'}
                  />
                </>
              )
            } else if (loading) {
              return <BlockSpin />
            } else {
              return <NotFound description={'That thread could not be found.'} />
            }
          }}
        />
      </div>
      <div className={'bottom'}>
        <PostComment
          entityId={threadId}
          useRichText
          submitUnderneath
          buttonProps={{
            children: `Send to ${pluralize(
              'other',
              Math.max(1, thread?.recipients.length ? thread.recipients.length : 1),
              true,
            )}`,
          }}
          placeholder={'Contribute to the conversation.'}
          onPosted={async () => {
            if (refetchRef.current) {
              await refetchRef.current({ id: threadId })
            }
          }}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 350px);
  min-height: 600px;
  > .comments {
    overflow-y: auto;
    flex-grow: 1;
    margin-bottom: ${props => props.theme['@size-m']};
    .comment-list-container > div:not(:last-child) .comment-content {
      border-bottom: solid 1px ${props => props.theme['@border-color-base']};
      margin-bottom: ${props => props.theme['@size-s']};
    }
  }
  > .bottom {
    .ck-content {
      max-height: 40vh;
    }
  }
`
