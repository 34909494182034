export const QUERY_DOC_BASE = `
query search(
  $query: String
  $first: Int = 32
  $after: String
  $filters: SearchFilterInput
  $orderBy: String
  $orderDirection: OrderDirection
) {
  search(
    query: $query
    after: $after
    first: $first
    filters: $filters
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        __typename
        id
        ADDITIONAL_FRAGMENTS
      }
    }
  }
}
`
