import { useFeature } from '@thesisedu/feature-web'
import { Result, SolidIcon } from '@thesisedu/react'
import { Rocket } from '@thesisedu/react/icons'
import React from 'react'

import { TrialsWebFeature } from './TrialsWebFeature'

export const TrialRequestSent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const feature = useFeature<TrialsWebFeature>(TrialsWebFeature.package)
  return (
    <div style={{ marginTop: 'auto', marginBottom: 100 }}>
      <Result.Success
        icon={
          <div style={{ fontSize: 96 }}>
            <SolidIcon color={'@primary-color'} children={<Rocket />} />
          </div>
        }
        title={'Trial Request Sent!'}
        description={
          <>
            Please check your inbox for a "Welcome to {feature.root.appOptions.name}" email from{' '}
            <a href={`mailto:support@${feature.root.appOptions.rootDomain}`}>
              support@{feature.root.appOptions.rootDomain}
            </a>{' '}
            with your login instructions.
          </>
        }
        action={{
          onClick() {
            window.open(`https://${feature.root.appOptions.rootDomain}`, '_self')
          },
          title: `Back to ${feature.root.appOptions.name}`,
        }}
      />
    </div>
  )
}
