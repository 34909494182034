import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { getTaskKey, useCompletedTasksContext } from './contexts/CompletedTasksContext'
import {
  BasicUserTaskFragment,
  UserTasksQuery,
  UserTasksQueryVariables,
  useUserTasksQuery,
} from './schema'
import { UserTaskFilters } from './types'

export interface UserTasksOpts
  extends Partial<ApolloReactCommon.QueryHookOptions<UserTasksQuery, UserTasksQueryVariables>> {
  filters?: UserTaskFilters
}
export interface TasksResult {
  loading?: boolean
  refreshing: boolean
  tasks: BasicUserTaskFragment[]
}
export function useUserTasks({ filters, ...opts }: UserTasksOpts): TasksResult {
  const [refreshing, setRefreshing] = useState(false)
  const { completed } = filters || {}
  const { data, loading, refetch } = useUserTasksQuery({
    variables: {
      input: filters,
    },
    ...opts,
  })
  const {
    data: incompleteData,
    loading: incompleteLoading,
    refetch: incompleteRefetch,
  } = useUserTasksQuery({
    skip: !completed,
    ...opts,
  })
  useEffect(() => {
    setRefreshing(true)
    refetch().finally(() => {
      setRefreshing(false)
    })
    if (completed) {
      incompleteRefetch().finally(() => {
        setRefreshing(false)
      })
    }
  }, [])
  const tasks = data?.viewer?.tasks as BasicUserTaskFragment[] | undefined
  const incompleteTasks = incompleteData?.viewer?.tasks as BasicUserTaskFragment[] | undefined
  const { completeTasks } = useCompletedTasksContext(true)

  let filteredTasks: BasicUserTaskFragment[] = []
  if (tasks?.length) {
    filteredTasks =
      completed !== undefined
        ? tasks.filter(task => {
            if (completed) {
              return !!task.completedAt || completeTasks.includes(getTaskKey(task))
            } else {
              return !task.completedAt && !completeTasks.includes(getTaskKey(task))
            }
          })
        : tasks
  }
  if (completed === true) {
    filteredTasks = [
      ...(incompleteTasks?.filter(task => {
        return (
          !filteredTasks.some(t => getTaskKey(t) === getTaskKey(task)) &&
          completeTasks.includes(getTaskKey(task))
        )
      }) || []),
      ...filteredTasks,
    ]
  }

  const resultTasks = filteredTasks.map(task => {
    if (completeTasks.includes(getTaskKey(task)) && !task.completedAt) {
      return { ...task, completedAt: moment.utc().format() }
    } else {
      return task
    }
  })

  return {
    loading: (loading || incompleteLoading) && tasks === undefined,
    refreshing: loading || incompleteLoading || refreshing,
    tasks: completed === true ? orderBy(resultTasks, 'completedAt', 'desc') : resultTasks,
  }
}
