import { useFeatureRoot } from '@thesisedu/feature-react'
import { Form } from '@thesisedu/react'
import { Cpu, UndoAction } from '@thesisedu/react/icons'
import { Block, HSpaced, Space, Tooltip } from '@thesisedu/web'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { AssignmentStudentHeaderLeft } from './AssignmentStudentHeaderLeft'
import { AutoGradeSubmissionButton } from './AutoGradeSubmissionButton'
import { NextStudentButton, PreviousStudentButton } from './NextStudentButton'
import { ResetSubmissionsButton } from './ResetSubmissionsButton'
import { SubmissionGradeAllButton } from './SubmissionGradeAll'
import { UseAssignmentSubmissionButton } from './UseAssignmentSubmissionButton'
import { isAssignmentAutoGraded } from './isAssignmentAutoGraded'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { useGradingModalSubmissionContext } from '../contexts/GradingModalSubmissionContext'
import { AssignmentGradingMode, BasicAssignmentSubmissionFragment } from '../schema'

export interface SubmissionGradeHeaderProps {
  saveButtonProps?: Partial<ButtonProps>
  submission?: BasicAssignmentSubmissionFragment
  studentId?: string
  skipContextCheck?: boolean
  noReset?: boolean
  noGradeAll?: boolean
}
export function SubmissionGradeHeader(props: SubmissionGradeHeaderProps) {
  const { saveButtonProps, studentId, submission, skipContextCheck, noReset, noGradeAll } = props
  if (!submission && !studentId) throw new Error('Either studentId or submission must be provided.')
  const root = useFeatureRoot()
  const { assignment, renderHeader } = useGradingModalContext(true)
  const isAutoGraded = isAssignmentAutoGraded(root, assignment.configuration || undefined, 'some')
  const isRubricMode = assignment.gradingMode === AssignmentGradingMode.Rubric
  const { loading, canSave } = useGradingModalSubmissionContext(true)
  const isDeleted = !!submission?.deletedAt
  const isSubmitted = !!submission?.submittedAt
  if (renderHeader && !skipContextCheck) {
    const Component = renderHeader
    return <Component {...props} skipContextCheck />
  }
  return (
    <Block marginBottom={'@size-xs'} data-testid={'SubmissionGradeHeader'}>
      <HSpaced>
        <AssignmentStudentHeaderLeft
          studentId={studentId || submission!.studentId}
          submissionId={submission?.id}
        />
        <Space />
        <PreviousStudentButton studentId={studentId || submission!.studentId} />
        {submission ? (
          <>
            {noReset || isDeleted || !submission.canReset ? null : (
              <Tooltip title={'Reset Assignment'} placement={'bottom'}>
                <ResetSubmissionsButton
                  assignmentId={assignment.id}
                  studentId={submission.studentId}
                  children={null}
                  danger={false}
                  icon={<UndoAction />}
                />
              </Tooltip>
            )}
            {isAutoGraded && !isRubricMode && !isDeleted && submission.canGrade ? (
              <Tooltip title={'Auto-Grade Again'} placement={'bottom'}>
                <AutoGradeSubmissionButton
                  submissionId={submission.id}
                  children={null}
                  danger={false}
                  icon={<Cpu />}
                  placement={'bottom'}
                />
              </Tooltip>
            ) : null}
            {noGradeAll || isDeleted || !submission.canGrade ? null : <SubmissionGradeAllButton />}
          </>
        ) : null}
        {isDeleted && isSubmitted && submission.canReset ? (
          <UseAssignmentSubmissionButton
            assignmentId={assignment.id}
            assignmentSubmissionId={submission.id}
          />
        ) : (
          <Form.Submit type={'primary'} loading={loading} {...saveButtonProps} disabled={!canSave}>
            {submission ? 'Save Grade' : 'Create Grade'}
          </Form.Submit>
        )}
        <NextStudentButton studentId={studentId || submission!.studentId} />
      </HSpaced>
    </Block>
  )
}
