import { InfiniteQuery, InfiniteQueryChildrenOpts } from '@thesisedu/feature-apollo-react'
import { get } from 'lodash'
import React from 'react'

import { SearchQuery, SearchQueryVariables } from '../schema'
import {
  CommonSearchProps,
  SearchResultNode,
  ResourceRenderMode,
  infiniteQueryProps,
} from '../types'
import { useSearchQueryDocument } from '../useSearchQuery'
import { useValidRenderers } from '../useSearchResultRenderer'

export type InfiniteSearchProps<Variables extends SearchQueryVariables = SearchQueryVariables> =
  CommonSearchProps<Variables> & {
    children: (
      nodes: SearchResultNode[],
      opts: InfiniteQueryChildrenOpts<SearchResultNode, SearchQuery, Variables>,
    ) => React.ReactElement
    mode: ResourceRenderMode
  }
export function InfiniteSearch<Variables extends SearchQueryVariables = SearchQueryVariables>({
  mode,
  children,
  query,
  filters,
  orderBy,
  orderDirection,
  variables,
  searchDocument: customSearchDocument,
  queryOpts,
}: InfiniteSearchProps<Variables>) {
  const searchDocument = useSearchQueryDocument()
  const validRenderers = useValidRenderers(mode)
  const props = infiniteQueryProps(customSearchDocument)
  const resultPath = props.resultPath || 'search'
  return (
    <InfiniteQuery<SearchResultNode, SearchQuery, SearchQueryVariables>
      document={searchDocument}
      resultPath={'search'}
      noLoading
      {...props}
      queryOpts={queryOpts}
      variables={{
        ...variables,
        query,
        orderBy,
        orderDirection,
        filters: {
          ...filters,
          providers: validRenderers.filter(
            provider => !filters?.providers || filters.providers.includes(provider),
          ),
        },
      }}
      children={result => {
        const { data } = result
        const nodes =
          (get(data, resultPath) as SearchQuery['search'] | undefined)?.edges.map(
            edge => edge.node,
          ) || []
        // Types here are weird with Variables.
        return children(nodes, result as any)
      }}
    />
  )
}
