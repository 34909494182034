import { Color, parseColor } from './parseColor'

function toSRGB(color: number) {
  return color / 255.0
}
function toRGB(color: number) {
  if (color <= 0.03928) {
    return color / 12.92
  } else {
    return Math.pow((color + 0.055) / 1.055, 2.4)
  }
}

function relative([r, g, b]: number[]) {
  if (r == null || g == null || b == null) return 0.0
  return r * 0.2126 + g * 0.7152 + b * 0.0722
}

export function luminance(color: Color) {
  return relative(color.map(toSRGB).map(toRGB))
}

export function ratio(foreground: string, background: string) {
  const foregroundL = luminance(parseColor(foreground)) + 0.05
  const backgroundL = luminance(parseColor(background)) + 0.05
  return Number(
    (foregroundL > backgroundL ? foregroundL / backgroundL : backgroundL / foregroundL).toFixed(2),
  )
}

export function best(first: string, second: string, background: string) {
  const firstRatio = ratio(first, background)
  const secondRatio = ratio(second, background)
  return firstRatio > secondRatio ? first : second
}
