import { Message } from '@thesisedu/ui/icons'
import React from 'react'

import { DiscussionGuideItemElement } from './DiscussionGuideItemElement'
import { DiscussionGuideItemSettingsModal } from './DiscussionGuideItemSettingsModal'
import { DiscussionGuideItemConfig } from './common'
import { WidgetsReactFeature } from '../../../WidgetsReactFeature'
import { SimpleWidget } from '../utils'

export const definition = {
  identifier: 'DiscussionGuideItem',
  title: 'Discussion Guide Item',
  icon: <Message />,
  weight: 24,
  group: SimpleWidget.ElementsGroup.TextBlocks,
  defaultConfig: { version: 1 },
  nestedEditorKeys: ['title', 'content'],
  hidden(context) {
    return !context.group || context.group === 'STUDENT'
  },
  element: DiscussionGuideItemElement,
  settingsModal: DiscussionGuideItemSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<DiscussionGuideItemConfig, 'DiscussionGuideItem'>

export default function (feature: WidgetsReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(definition))
}
