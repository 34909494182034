import { WidgetConverter } from './types'
import * as Legacy from '..'

interface SpaceWidgetConfig {
  size: '@size-s' | '@size-m' | '@size-l' | '@size-xl'
  clearFloat?: 'left' | 'right' | 'both'
}

export default {
  weight: 0,
  label: 'space',
  identifier: 'Space',
  convert(value) {
    if (value.config?.clearFloat) {
      return [{ type: 'ClearFloat', version: 1 }]
    } else return []
  },
} satisfies WidgetConverter<Legacy.Widget<SpaceWidgetConfig>>
