import { BodySmall, LoadingIndicator } from '@thesisedu/react'
import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React from 'react'

import { useUserGroupsQuery } from '../schema'

export const GroupSelect = React.forwardRef<any, SelectProps<any>>((props, ref) => {
  const { data, loading } = useUserGroupsQuery()

  return (
    <>
      {loading ? (
        <BodySmall>
          <LoadingIndicator />
        </BodySmall>
      ) : (
        <Select {...props} ref={ref}>
          {data?.userGroups.map(group => {
            return <Select.Option value={group.name}>{group.label}</Select.Option>
          })}
        </Select>
      )}
    </>
  )
})
