import { VideoCamera } from '@thesisedu/ui/icons'
import React from 'react'

import { SelectMediaSource, SelectMediaSourceProps } from './SelectMediaSource'

export interface SelectVideoSourceProps
  extends Pick<SelectMediaSourceProps, 'value' | 'onChange' | 'disabled'> {}
export function SelectVideoSource(props: SelectVideoSourceProps) {
  return (
    <SelectMediaSource
      {...props}
      icon={<VideoCamera />}
      label={'Camera'}
      constraints={{ video: true }}
      type={'videoinput'}
    />
  )
}
