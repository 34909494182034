import { FullAssignment } from '../grading/types'
import {
  AssignmentSubmissionWithConfigurationFragment,
  BasicAssignmentSubmissionFragment,
  useAssignmentQuery,
  useAssignmentSubmissionQuery,
} from '../schema'

export function useSubmissionQuery(
  assignmentId: string,
  submissionId: string,
): {
  loading: boolean
  assignment?: FullAssignment
  submission?: BasicAssignmentSubmissionFragment & AssignmentSubmissionWithConfigurationFragment
} {
  const cachedAssignmentResult = useAssignmentQuery({
    variables: {
      id: assignmentId,
    },
    fetchPolicy: 'cache-only',
  })
  const cachedAssignment =
    cachedAssignmentResult.data?.node?.__typename === 'Assignment'
      ? cachedAssignmentResult.data.node
      : undefined
  const cachedSubmission = (
    cachedAssignment?.currentSubmissions || cachedAssignment?.submissions
  )?.edges.find(edge => edge.node.id === submissionId)?.node
  const assignmentResult = useAssignmentQuery({
    variables: {
      id: assignmentId,
    },
    skip: !!cachedAssignment,
  })
  const submissionResult = useAssignmentSubmissionQuery({
    variables: {
      id: submissionId,
      withConfiguration: true,
    },
    skip: !!cachedSubmission,
  })

  const assignment = cachedAssignment
    ? cachedAssignment
    : assignmentResult.data?.node?.__typename === 'Assignment'
    ? assignmentResult.data.node
    : undefined
  const submission = cachedSubmission
    ? cachedSubmission
    : submissionResult.data?.node?.__typename === 'AssignmentSubmission'
    ? submissionResult.data.node
    : undefined
  const loading = assignmentResult.loading || submissionResult.loading

  return { assignment, submission, loading }
}
