import React from 'react'

import { GradeTreeRecordSummary } from './GradeTreeRecordSummary'
import { GradeTreeRecord } from './translateGradeTree'

export interface NameColumnProps {
  record: GradeTreeRecord
  renderName?: (record: GradeTreeRecord, children: React.ReactElement) => React.ReactElement
}
export function NameColumn({ record, renderName }: NameColumnProps) {
  const content = (
    <>
      <span>{record.name}</span>
      <span style={{ marginLeft: 10 }}>
        <GradeTreeRecordSummary record={record} />
      </span>
    </>
  )

  return renderName ? renderName(record, content) : content
}
