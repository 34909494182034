import React from 'react'

import { StandardSetListGroup } from './StandardSetListGroup'
import { StandardSetChildProps } from './StandardSetPage'

export function StandardSetList({ standardSet, focusTagId }: StandardSetChildProps) {
  return (
    <>
      {standardSet.segmentsTree.tree.map(treeItem => (
        <StandardSetListGroup
          key={treeItem.tag.id}
          treeItem={treeItem}
          scrollIntoView={focusTagId === treeItem.tag.id}
        />
      ))}
    </>
  )
}
