import { AntIconWrapper as Aiw, Button, ButtonProps, Text, Bold } from '@thesisedu/react'
import { Group } from '@thesisedu/react/icons'
import React from 'react'

import { useStudentSelectorContext } from './StudentSelectorContext'
import { useStudentFilterHumanValue } from './useStudentFilterHumanValue'
import { ClassStudentPair } from '../types'

export interface StudentFilterButtonProps extends Omit<ButtonProps, 'value' | 'onChange'> {
  value?: ClassStudentPair[]
  onChange: (pairs: ClassStudentPair[]) => void
  emptyLabel?: string
  valuePrefix?: string
  saveText?: string
}
export function StudentFilterButton({
  value,
  onChange,
  saveText = 'Save Filter',
  valuePrefix = 'Showing',
  emptyLabel = 'Filter Classes & Students',
  ...rest
}: StudentFilterButtonProps) {
  const hasValue = !!value?.length
  const humanValue = useStudentFilterHumanValue(value || [])
  const selectorContext = useStudentSelectorContext(false)

  if (selectorContext) {
    return (
      <Button
        type={hasValue ? 'primary' : 'default'}
        icon={<Aiw children={<Group />} />}
        {...rest}
        onClick={async () => {
          const selectedStudents = await selectorContext.selectStudents({
            initialValue: value,
            saveText,
          })
          if (selectedStudents !== 'cancelled') {
            onChange(selectedStudents.students)
          }
        }}
      >
        {hasValue ? (
          <Text>
            <Bold>{valuePrefix}</Bold>
            <Text>{` ${humanValue}`}</Text>
          </Text>
        ) : (
          emptyLabel
        )}
      </Button>
    )
  } else {
    return null
  }
}
