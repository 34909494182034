import { useMutateHook } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { s, styled } from '@thesisedu/ui'
import { ArrowLeft } from '@thesisedu/ui/icons'
import React from 'react'

import { CourseModalPosition } from './CourseModalPosition'
import { CourseViewerModalRightContent } from './CourseViewerModalRightContent'
import { CourseViewerSegment } from './CourseViewerSegment'
import { CourseViewerSegmentProgressHeader } from './CourseViewerSegmentProgressHeader'
import { SegmentNavigationVerticalFooter } from './SegmentNavigationVerticalFooter'
import { SegmentNavigationVerticalTabs } from './SegmentNavigationVerticalTabs'
import {
  Container,
  ContainerLeft,
  ExpandCollapseButton,
  ExpandCollapseContainer,
  PaddedContainer,
  StyledModal,
} from './styled'
import { MutateCourseViewerContext } from './types'
import { useCourseViewerModalContext } from '../contexts/CourseViewerModalContext'
import { CourseViewerModalHeaderProvider } from '../contexts/CourseViewerModalHeaderContext'
import { HeadingScrollProvider } from '../contexts/HeadingScrollContext'
import { useSegment } from '../contexts/SegmentContext'
import { SegmentNavigationProvider } from '../contexts/SegmentNavigationContext'

export function IndividualCourseViewerModal() {
  const { segmentId, setSegmentId, hidden } = useCourseViewerModalContext(true)
  const [collapsed, setCollapsed] = React.useState(false)
  React.useEffect(() => {
    setCollapsed(false)
  }, [segmentId])
  const segment = useSegment(segmentId)
  const viewer = useViewerContext(false)
  const children = (
    <Container data-testid={'CourseViewerModal'}>
      <ContainerLeft className={collapsed ? 'collapsed' : ''}>
        <ExpandCollapseContainer>
          <ExpandCollapseButton className={'button'} onClick={() => setCollapsed(c => !c)}>
            <ArrowLeft />
          </ExpandCollapseButton>
        </ExpandCollapseContainer>
        <div className={'content'}>
          <CourseModalPosition />
          <CourseModalBottom>
            <SegmentNavigationVerticalTabs />
            <SegmentNavigationVerticalFooter segment={segment} />
          </CourseModalBottom>
        </div>
      </ContainerLeft>
      <CourseViewerModalRightContent padded={false}>
        {segment ? (
          <>
            <CourseViewerSegmentProgressHeader segment={segment} />
            <PaddedContainer style={{ paddingTop: 0 }}>
              <CourseViewerSegment segment={segment} />
            </PaddedContainer>
          </>
        ) : null}
      </CourseViewerModalRightContent>
    </Container>
  )

  return (
    <StyledModal
      bodyStyle={{ minHeight: 400, padding: 0 }}
      visible={!!segmentId && !hidden}
      onCancel={() => {
        setSegmentId(undefined)
      }}
      keyboard={false}
      destroyOnClose
    >
      <CourseViewerModalHeaderProvider>
        <SegmentNavigationProvider segment={segment}>
          {useMutateHook<MutateCourseViewerContext>(
            'courses-react:course-viewer-context',
            <HeadingScrollProvider>{children}</HeadingScrollProvider>,
            {
              viewer,
              segment,
            },
          )}
        </SegmentNavigationProvider>
      </CourseViewerModalHeaderProvider>
    </StyledModal>
  )
}

const CourseModalBottom = styled.div`
  overflow-y: auto;
  flex: 1;
  padding: ${s.size('s')} 0;
`
