import { ArrowRightOutlined } from '@ant-design/icons'
import { JoinClassModal } from '@thesisedu/feature-classes-react/dist/JoinClassModal'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { styled, H1, BodyLarge } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

// @ts-ignore asset paths are not supported
import logo from './assets/academy-light-logo.svg'

const Container = styled.div`
  text-align: left;
  max-width: 600px;
  margin: 10vh auto ${props => props.theme['@size-xxl']} auto;
`
const LogoImage = styled.img`
  height: ${props => props.theme['@size-l']};
  margin-bottom: ${props => props.theme['@size-m']};
`
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme['@size-xl']};
  > :first-child {
    margin-right: auto;
  }
`

export const NewTeacher: React.FC<React.PropsWithChildren<unknown>> = () => {
  const viewer = useViewerContext(true)
  const navigate = useNavigate()
  return (
    <Container>
      <LogoImage src={logo} alt={'Prodigies Academy'} />
      <H1>Welcome to Prodigies Academy, {viewer.firstName || viewer.username}!</H1>
      <BodyLarge>
        We're glad you're here! To get started, create your first class by clicking the button
        below.
      </BodyLarge>
      <BottomContainer>
        <BodyLarge style={{ lineHeight: 1 }} />
        <Button
          type={'primary'}
          size={'large'}
          onClick={() => {
            navigate('/teacher/classes/create')
          }}
        >
          Create your first class
          <ArrowRightOutlined />
        </Button>
      </BottomContainer>
    </Container>
  )
}

export function NewStudent() {
  const viewer = useViewerContext(true)
  const [joinVisible, setJoinVisible] = React.useState(false)
  return (
    <Container>
      <JoinClassModal visible={joinVisible} onClose={() => setJoinVisible(false)} />
      <LogoImage src={logo} alt={'Prodigies Academy'} />
      <H1>Welcome to Prodigies Academy, {viewer.firstName || viewer.username}!</H1>
      <BodyLarge>
        We're glad to have you! Once you get a class code from your teacher, use the button below to
        join their class.
      </BodyLarge>
      <BottomContainer>
        <BodyLarge style={{ lineHeight: 1 }}>Let's start learning!</BodyLarge>
        <Button
          type={'primary'}
          size={'large'}
          onClick={() => {
            setJoinVisible(true)
          }}
        >
          Join your first class
          <ArrowRightOutlined />
        </Button>
      </BottomContainer>
    </Container>
  )
}
