import { Group } from '@thesisedu/feature-assignments-core'

import { GroupsContextValue } from './GroupsContext'

export function onChangeGroup(
  groups: GroupsContextValue['groups'],
  onChange: GroupsContextValue['onChange'],
  groupKey: string,
  updates: Partial<Group>,
) {
  if (!groups[groupKey]) throw new Error('Cannot find existing group inside onChangeGroup.')
  return onChange({
    ...groups,
    [groupKey]: {
      ...groups[groupKey],
      ...updates,
    },
  })
}
