import { useMediaPlaybackContext } from '@thesisedu/react'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { BookmarkButton } from './BookmarkButton'
import { useBookmarksContext } from './BookmarksContext'
import { getCurrentBookmark } from './getCurrentBookmark'
import { useBookmarks } from './useBookmarks'

export function PlayerBookmarkButton() {
  const { playedSeconds, onSetPlaying, isPlaying } = useMediaPlaybackContext()
  const { draftBookmark } = useBookmarksContext(true)
  const bookmarks = useBookmarks()
  const activeBookmark = getCurrentBookmark(bookmarks, playedSeconds)
  const wasPlayingRef = React.useRef(false)
  return (
    <Button
      getCurrentTimeSeconds={() => playedSeconds}
      $active={!!draftBookmark || !!activeBookmark}
      variant={'chromeless'}
      activeBookmark={activeBookmark ?? undefined}
      onPause={() => {
        wasPlayingRef.current = isPlaying
        onSetPlaying(false)
      }}
      onResume={() => {
        if (wasPlayingRef.current) onSetPlaying(true)
      }}
    />
  )
}

const Button = styled(BookmarkButton)<{ $active?: boolean }>`
  color: ${props => (props.$active ? s.color('orange') : 'white')};
  ${s.ifs(props => !!props.$active)} svg {
    fill: currentColor;
  }
`
