import * as React from 'react'

function SvgNoteF(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#69B945'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={2.118}
          d={
            'M81 63.164L180 56l-5.553 46.552-45.148 1.836-1.963 13.991 37.606-2.327 2.583 37.629-37.787 2.353-1.782 45.88L82.291 203z'
          }
        />
        <path
          fill={'#68B545'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteF = React.memo(SvgNoteF)
export default MemoSvgNoteF
