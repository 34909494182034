import { useFeatureRoot } from '@thesisedu/feature-react'
import React from 'react'

import { error as errorLog } from './log'
import { ApolloReactHooks, ExpectedErrorContext, ExpectedErrorPayload } from './types'

export type ExpectedErrorListenerError = Omit<ExpectedErrorPayload, 'shouldRetry'>
export interface UseExpectedErrorListenerOpts {
  error: string
}
export interface UseExpectedErrorListenerResult {
  error?: ExpectedErrorListenerError
  onClose: () => void
}
export function useExpectedErrorListener({
  error: errorCode,
}: UseExpectedErrorListenerOpts): UseExpectedErrorListenerResult {
  const root = useFeatureRoot()
  const [error, setError] = React.useState<ExpectedErrorListenerError | undefined>(undefined)
  React.useEffect(() => {
    root.deps.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
      ApolloReactHooks.ExpectedError,
      (err, context) => {
        if (context.code === errorCode) {
          setError(err)
        }
        return err
      },
    )
    return () => {
      errorLog('Attempting to unmount useExpectedErrorListener(); this should never happen')
    }
  }, [])

  return {
    error,
    onClose: () => setError(undefined),
  }
}
