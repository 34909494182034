import { HSpaced, Text } from '@thesisedu/ui'
import { WarningTriangle } from '@thesisedu/ui/icons'
import React from 'react'

import { SyncItemHistoryTooltip } from './SyncItemHistoryTooltip'
import { ClassSyncHistoryStatus } from '../schema'
import { SyncAssignmentActionItem } from '../useSyncAssignmentActions'

export interface SyncAssignmentItemSummaryProps {
  item: SyncAssignmentActionItem
}
export function SyncAssignmentItemSummary({ item }: SyncAssignmentItemSummaryProps) {
  if (item.isSynced) {
    const latestAttempt = item.sync?.latestAttempt?.edges?.[0]?.node
    let children
    if (latestAttempt && latestAttempt?.status !== ClassSyncHistoryStatus.Success) {
      children = (
        <HSpaced space={'xs'}>
          <Text level={'s'} color={'red'}>
            <WarningTriangle />
          </Text>
          <Text level={'s'} color={'red'}>
            Last sync failed
          </Text>
        </HSpaced>
      )
    } else {
      children = (
        <Text level={'s'} color={'secondary'}>
          Synced with {item.providerName}
        </Text>
      )
    }

    return item.sync ? <SyncItemHistoryTooltip id={item.sync.id} children={children} /> : children
  } else {
    return (
      <Text level={'s'} color={'secondary'}>
        Not Synced
      </Text>
    )
  }
}
