import { styled, s, HSpaced } from '@thesisedu/ui'
import { NavArrowLeft } from '@thesisedu/ui/icons'
import React from 'react'

import { DeviceButtons } from './DeviceButtons'
import { RecordButton } from './RecordButton'
import { RecordFooterButton } from './RecordFooterButton'
import { RecordTimer } from './RecordTimer'
import { usePrepareForRecordFooterItems, useRecordFooterItems } from './useRecordFooterItems'
import { FullscreenFooterButton } from '../../../RecordViewFullscreen'
import { useDetailedRecordStateContext } from '../DetailedRecordStateContext'

export interface RecordFooterProps {
  onBack: () => void
}
export function RecordFooter({ onBack }: RecordFooterProps) {
  const { state } = useDetailedRecordStateContext()
  const footerItems = useRecordFooterItems()
  const prepareFooterItems = usePrepareForRecordFooterItems()
  return (
    <Container>
      {state ? (
        <HSpaced space={'xxs'}>
          <FullscreenFooterButton />
          {footerItems.map(footerItem => {
            return React.cloneElement(footerItem.children, { key: footerItem.identifier })
          })}
        </HSpaced>
      ) : (
        <HSpaced space={'xxs'}>
          <RecordFooterButton
            icon={<NavArrowLeft />}
            aria-label={'Back to Recording Setup'}
            onPress={onBack}
          />
          <FullscreenFooterButton />
          <DeviceButtons />
          {prepareFooterItems.map(footerItem => {
            return React.cloneElement(footerItem.children, { key: footerItem.identifier })
          })}
        </HSpaced>
      )}
      <RecordButton />
      <RecordTimer />
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${s.var('radii.1')};
  background: ${s.opacity(s.color('subtle'), 0.8)};
  backdrop-filter: blur(4px);
  padding: ${s.size('xs')};
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  z-index: 3;
`
