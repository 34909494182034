import { Button, ButtonProps, message } from '@thesisedu/react'
import { Refresh } from '@thesisedu/react/icons'
import { Form } from '@thesisedu/ui'
import React from 'react'

import { assignmentToFormValues } from '../grading/assignmentToFormValues'
import { useResetGeneratedAssignmentMutation } from '../queries/useResetGeneratedAssignmentMutation'

export interface ResetGeneratedAssignmentButtonProps extends Partial<ButtonProps> {
  assignmentId: string
}
export function ResetGeneratedAssignmentButton({
  assignmentId,
  ...rest
}: ResetGeneratedAssignmentButtonProps) {
  const { form } = Form.useFormContext(false) ?? {}
  const [reset, { loading }] = useResetGeneratedAssignmentMutation({
    variables: {
      input: {
        id: assignmentId,
      },
    },
    onCompleted(data) {
      message.success('Assignment reset successfully.')
      if (form) {
        form.reset(assignmentToFormValues(data.resetGeneratedAssignment.assignment))
      }
    },
  })

  return (
    <Button
      children={'Reset Assignment'}
      icon={<Refresh />}
      {...rest}
      loading={loading}
      onClick={e => {
        e.preventDefault()
        reset()
      }}
    />
  )
}
