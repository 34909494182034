import {
  ClassStudent,
  useCachedStudentUserCallback,
  useClasses,
} from '@thesisedu/feature-classes-react'
import { UserFragment } from '@thesisedu/feature-classes-react/dist/schema'
import { LoadingIndicator } from '@thesisedu/react'
import { BodySmall, H3, styled } from '@thesisedu/web'
import { Empty } from 'antd'
import pluralize from 'pluralize'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { LessonFragment } from '../schema'

const Container = styled.div`
  columns: auto 300px;
  column-gap: ${props => props.theme['@size-m']};
  text-align: center;
  > div {
    text-align: left;
    margin-bottom: ${props => props.theme['@size-m']};
  }
`
const StudentsMetrics = styled.div`
  display: flex;
  > p {
    margin-right: 10px;
  }
`

interface ClassIds {
  [classId: string]: {
    className: string
    classId: string
    students: {
      studentId: string
      studentUser: UserFragment
      viewedTimes: number
      viewedMinutes: number
    }[]
  }
}

export interface LessonAssignedStudentsProps {
  fragment: LessonFragment
}
export const LessonAssignedStudents: React.FC<
  React.PropsWithChildren<LessonAssignedStudentsProps>
> = ({ fragment }) => {
  const assignmentEdges = fragment.students.edges || []
  const navigate = useNavigate()
  const getStudent = useCachedStudentUserCallback()
  const { classes, loading } = useClasses()
  if (loading) {
    return <LoadingIndicator block />
  } else if (!classes.length) {
    return null
  }
  const classIds = assignmentEdges.reduce<ClassIds>((acc, edge) => {
    const student = getStudent(edge.node.id, edge.class?.id || '')
    const classId = edge.class?.id
    const cls = classes.find(cls => cls.id === edge.class?.id)
    if (!student || !cls || !classId) return acc
    const result = { ...acc }
    if (!result[classId]) {
      result[classId] = {
        students: [],
        classId,
        className: cls.name,
      }
    }
    result[classId].students.push({
      studentId: student.id,
      studentUser: student.user,
      viewedTimes: edge.viewedTimes,
      viewedMinutes: edge.viewedMinutes,
    })
    return result
  }, {})

  let content: React.ReactElement = (
    <Empty description={'This lesson is not assigned to any students.'} />
  )
  if (Object.keys(classIds).length) {
    content = (
      <Container>
        {Object.keys(classIds).map(clsId => {
          const cls = classIds[clsId]
          return (
            <div key={clsId} style={{ breakInside: 'avoid' }}>
              <H3>{cls.className}</H3>
              {cls.students.map(student => (
                <ClassStudent
                  studentId={student.studentId}
                  classId={clsId}
                  key={student.studentId}
                  metadata={
                    <>
                      {student.viewedTimes > 0 ? (
                        <StudentsMetrics>
                          <BodySmall color={'@text-color-secondary'}>
                            Viewed {pluralize('time', student.viewedTimes, true)},{' '}
                            {pluralize('minute', student.viewedMinutes, true)} total.
                          </BodySmall>
                        </StudentsMetrics>
                      ) : (
                        <BodySmall color={'@text-color-secondary'}>Hasn't viewed yet</BodySmall>
                      )}
                    </>
                  }
                  onClick={() => {
                    navigate(`./practices?studentId=${student.studentId}&classId=${clsId}`)
                  }}
                />
              ))}
            </div>
          )
        })}
      </Container>
    )
  }

  return content
}
