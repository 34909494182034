import * as React from 'react'

function SvgNoteNoStemInverse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <path
        fillRule={'evenodd'}
        d={
          'M97.427 24.835c0 15.243-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1C-4.02 41.637 10.363 18.73 35.717 7.113 53.467-1.018 71.89-1.46 83.955 4.718 92.869 9.6 97.427 15.72 97.427 24.835z'
        }
      />
    </svg>
  )
}

const MemoSvgNoteNoStemInverse = React.memo(SvgNoteNoStemInverse)
export default MemoSvgNoteNoStemInverse
