import { Button } from 'antd'
import React, { useState } from 'react'

import { EditLessonModal, EditLessonModalProps } from './EditLessonModal'

type EditLessonButtonProps = Pick<EditLessonModalProps, 'lesson'> &
  Partial<Omit<EditLessonModalProps, 'lesson'>>
export const EditLessonButton: React.FC<React.PropsWithChildren<EditLessonButtonProps>> = props => {
  const [visible, setVisible] = useState(false)
  if (props.lesson.sharing.sharedFrom || !props.lesson.canUpdate) return null
  return (
    <>
      <EditLessonModal {...props} visible={visible} onCancel={() => setVisible(false)} />
      <Button type={'primary'} onClick={() => setVisible(true)}>
        Edit Lesson
      </Button>
    </>
  )
}
