import { addTeacherPermission } from '@thesisedu/feature-classes-react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  addTeacherPermission(feature, 'Classes', 'Manage class settings', [
    'MANAGE_ASSIGNMENT_CATEGORIES',
    'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
    'IMPORT_GRADE_CATEGORIES',
  ])
  addTeacherPermission(feature, 'Classes', 'Manage groups and presets', [
    'MANAGE_CLASS_GROUP_PRESETS',
    'DELETE_CLASS_GROUP_PRESET',
    'RESTORE_CLASS_GROUP_PRESET',
    'VIEW_CLASS_GROUP_PRESET',
  ])
  addTeacherPermission(feature, 'Classes', 'Sync content to LMS', [
    'SYNC_ASSIGNMENT',
    'FORCE_RESYNC_ASSIGNMENT_SYNC',
  ])
  addTeacherPermission(feature, 'Assignments', 'Manage assignment settings', [
    'MANAGE_ASSIGNMENTS',
    'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
    'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  ])
  addTeacherPermission(feature, 'Assignments', 'Grade assignment submissions', [
    'SAVE_ASSIGNMENT_SUBMISSION',
    'SUBMIT_ASSIGNMENT',
    'GRADE_ASSIGNMENT_SUBMISSION',
    'RESET_ASSIGNMENT_SUBMISSION',
  ])
}
