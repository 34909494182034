import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { DecoratorNode, ElementNode, LexicalNode } from 'lexical'
import React from 'react'

export interface ElementsMenuElementProps {
  selected?: boolean
  onClick: (e: React.MouseEvent) => void
}

export enum ElementsGroup {
  Text = 'Text',
  Media = 'Media',
  TextBlocks = 'Text Blocks',
  Layout = 'Layout',
  Other = 'Other',
}

export interface ElementsMenuItem {
  icon: React.ReactElement
  title: string
  onCommit: (element: ElementNode) => ElementNode | DecoratorNode<any> | void
  isSelected: (element: LexicalNode | undefined) => boolean
  identifier: string
  weight: number
  group: ElementsGroup | string
  noReplace?: boolean
}

export interface ElementsMenuContextValue {
  items: ElementsMenuItem[]
  registerItem: (item: ElementsMenuItem) => void
  removeItem: (item: ElementsMenuItem) => void
}
export const ElementsMenuContext = React.createContext<ElementsMenuContextValue | undefined>(
  undefined,
)

export function ElementsMenuProvider({ children }: React.PropsWithChildren<object>) {
  const [editor] = useLexicalComposerContext()
  const [items, setItems] = React.useState<ElementsMenuItem[]>([])
  const contextValue = React.useMemo<ElementsMenuContextValue>(() => {
    return {
      items,
      registerItem(item) {
        setItems(items => {
          if (items.some(i => i.identifier === item.identifier)) {
            return items
          }
          return [...items, item]
        })
      },
      removeItem(item) {
        setItems(items => {
          return editor.getEditorState().read(() => {
            return items.filter(i => i.identifier !== item.identifier)
          })
        })
      },
    }
  }, [items])

  return <ElementsMenuContext.Provider value={contextValue} children={children} />
}

export function useElementsMenuContext(): ElementsMenuContextValue | undefined
export function useElementsMenuContext(require: false): ElementsMenuContextValue | undefined
export function useElementsMenuContext(require: true): ElementsMenuContextValue
export function useElementsMenuContext(require?: boolean): ElementsMenuContextValue | undefined {
  const context = React.useContext(ElementsMenuContext)
  if (!context && require) {
    throw new Error('ElementsMenuContext is required, yet not provided.')
  }
  return context
}

export function useElementsMenuItem(item: ElementsMenuItem) {
  const context = useElementsMenuContext(false)
  React.useEffect(() => {
    if (context) {
      context.registerItem(item)
      return () => context.removeItem(item)
    }
  }, [])
}
