import { $generateNodesFromSerializedNodes, $insertGeneratedNodes } from '@lexical/clipboard'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  PASTE_COMMAND,
  $getSelection,
  $isRangeSelection,
  TextNode,
  $createTextNode,
  COMMAND_PRIORITY_HIGH,
  $isDecoratorNode,
  $isElementNode,
  $isRootNode,
} from 'lexical'
import React from 'react'

import { debug } from '../../../log'
import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function usePrePasteCleanup() {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (payload: ClipboardEvent) => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) return false
        const blockElement = $getSelectedBlockElement(selection)
        let handled = false
        if (blockElement) {
          const lexicalData = payload.clipboardData?.getData('application/x-lexical-editor')

          let textNode: TextNode | undefined = undefined
          editor.update(
            () => {
              try {
                // Remove all selected text.
                debug('[before paste] removing selection')
                try {
                  selection.removeText()
                } catch {
                  debug('[before paste] ...failed to remove selection')
                }

                // Make sure there is text in the current element.
                if (!blockElement.getTextContentSize()) {
                  debug('[before paste] ensuring current node has text content')
                  textNode = $createTextNode(' ')
                  blockElement.append(textNode)
                  textNode.select(1)
                }
              } catch (err: any) {
                debug('[before paste] error occurred: %O', err)
              }
            },
            {
              discrete: true,
            },
          )

          if (lexicalData) {
            const parsed = JSON.parse(lexicalData)
            if (parsed.namespace && parsed.nodes) {
              debug('valid lexical data detected in paste; handling paste ourselves')
              editor.update(() => {
                const nodes = $generateNodesFromSerializedNodes(parsed.nodes)
                $insertGeneratedNodes(editor, nodes, selection)
              })
              handled = true
            }
          }

          setTimeout(() => {
            editor.update(() => {
              if (textNode) {
                debug('[after paste] removing empty space from text node')
                const content = textNode.getTextContent()
                if (content.startsWith(' ')) {
                  textNode.setTextContent(content.replace(/^ /, ''))
                }
                const parent = textNode.getParent()

                if (!content?.trim()) {
                  debug('[after paste] removing text node because there was no text content pasted')
                  textNode.remove()
                }

                if (parent) {
                  for (const child of parent.getChildren()) {
                    if ($isElementNode(child) || $isDecoratorNode(child)) {
                      debug(
                        '[after paste] moving child %s above paragraph because its an element',
                        child.getType(),
                      )
                      parent.insertBefore(child)
                    }
                  }
                }

                if (!textNode.getTextContent().trim()) {
                  debug('[after paste] removing text node because there was no text content pasted')
                  textNode.remove()
                }
              }

              debug('[after paste] removing empty paragraphs after pasted content')
              const blockElement = $getSelectedBlockElement()
              if (blockElement && !blockElement.getTextContent() && !$isRootNode(blockElement)) {
                editor.update(
                  () => {
                    blockElement.remove()
                  },
                  { discrete: true },
                )
              }
            })
          })
        }

        return handled
      },
      COMMAND_PRIORITY_HIGH,
    )
  })
}
