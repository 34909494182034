import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'
import { useEffectAfterMount } from '@thesisedu/react'
import React from 'react'

import { WidgetSettingsModalDropdownItem } from './WidgetSettingsModalDropdownItem'
import { SimpleWidgetDefinition } from './createWidgetResource'
import { useSettingsDropdownItem } from '../../../lexical/ui/SettingsDropdown/SettingsDropdownContext'

export const SETTINGS_WEIGHT = -20

export interface WidgetSettingsProps<Config extends BaseWidgetConfig>
  extends Pick<
    SimpleWidgetDefinition<Config, any>,
    'settingsDropdownItems' | 'settingsModal' | 'identifier' | 'title'
  > {}
export function WidgetSettings<
  Config extends BaseWidgetConfig,
  SettingsConfig extends Partial<Config>,
>({ settingsDropdownItems = [], settingsModal, identifier, title }: WidgetSettingsProps<Config>) {
  useEffectAfterMount(() => {
    throw new Error('#settingsDropdownItems() nor #settingsModal() should change on simple widgets')
  }, [!!settingsModal, settingsDropdownItems.length])

  for (const dropdownItem of settingsDropdownItems) {
    /**
     * The error message above should make sure we always throw an error every
     * time we would call these hooks in a different order, so we can safely
     * ignore the rules of hooks here, as we are technically still following
     * them.
     */
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSettingsDropdownItem({
      ...dropdownItem,
      identifier: `widget-${identifier}-${dropdownItem.identifier}`,
      weight: dropdownItem.weight + SETTINGS_WEIGHT,
      group: identifier,
      filter(editor, node) {
        return node.getType() === identifier
      },
    })
  }

  if (settingsModal) {
    return (
      <WidgetSettingsModalDropdownItem<Config, SettingsConfig>
        settingsModal={settingsModal}
        title={title}
        identifier={identifier}
      />
    )
  } else return null
}
