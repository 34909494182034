import { Form } from '@thesisedu/ui'
import React from 'react'

import { QuestionSettingsContext } from './QuestionSettingsContext'
import { questionSettingsValueFromAssignment } from './questionSettingsValueFromAssignment'
import { QuestionSettingsValue } from './types'
import { useQuestionSettingsSubmit } from './useQuestionSettingsSubmit'
import { useGradingModalContext } from '../../contexts/GradingModalContext'
import { FullAssignment } from '../../grading/types'
import { debug } from '../../log'

export interface QuestionSettingsProviderProps {
  children: React.ReactNode
  assignment: FullAssignment | undefined
}
export function QuestionSettingsProvider({ children, assignment }: QuestionSettingsProviderProps) {
  const form = Form.useForm<QuestionSettingsValue>()
  React.useEffect(() => {
    if (assignment) {
      const values = questionSettingsValueFromAssignment(assignment)
      debug('reloading question settings for assignment: %O', values)
      form.reset(values)
    }
  }, [assignment?.totalPoints])
  const handleSubmit = useQuestionSettingsSubmit({ assignment })
  const { addPrompt, removePrompt } = useGradingModalContext(false) ?? {}
  React.useEffect(() => {
    if (form.formState.isDirty) {
      addPrompt?.('question-settings', 'You have unsaved changes to your question settings.')
      return () => removePrompt?.('question-settings')
    }
  }, [form.formState.isDirty])

  return assignment ? (
    <Form form={form} onFinish={handleSubmit}>
      <QuestionSettingsContext.Provider value={{ form }} children={children} />
    </Form>
  ) : (
    <>{children}</>
  )
}
