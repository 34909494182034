import { Select, Select$ } from '@thesisedu/ui'
import React from 'react'

import { SubmissionType } from '../schema'

export type AssignmentSubmissionTypeSelectProps = Omit<
  Select$.SelectProps<SubmissionType>,
  'children' | 'onValueChange'
> & {
  /** If this is true, the individual option is rendered separately. */
  separateIndividual?: boolean
  /** If this is true, the individual option is hidden entirely. */
  hideIndividual?: boolean
  onChange?: Select$.SelectProps<SubmissionType>['onValueChange']
}
function _AssignmentSubmissionTypeSelect(
  { separateIndividual, hideIndividual, onChange, ...props }: AssignmentSubmissionTypeSelectProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  return (
    <Select
      placeholder={'Select a type'}
      defaultValue={hideIndividual ? SubmissionType.Group : SubmissionType.Individual}
      {...props}
      onValueChange={onChange}
      ref={ref}
    >
      <Select.Item value={SubmissionType.Group}>Group (Shared Submissions)</Select.Item>
      <Select.Item value={SubmissionType.GroupIndividual}>
        Group (Individual Submissions)
      </Select.Item>
      {hideIndividual ? null : (
        <>
          {separateIndividual ? <Select.Separator /> : null}
          <Select.Item danger={separateIndividual} value={SubmissionType.Individual}>
            Individual
          </Select.Item>
        </>
      )}
    </Select>
  )
}
export const AssignmentSubmissionTypeSelect = React.forwardRef(_AssignmentSubmissionTypeSelect)
