import { ApolloReactFeature, onMutationError } from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-react'
import { UsersWebFeature, loginUser } from '@thesisedu/feature-users-web'
import { useNavigate } from 'react-router-dom'

import { useRefreshAuthMutation } from './schema'

export function useRefreshAuth() {
  const apolloFeature = useFeature(ApolloReactFeature)
  const usersFeature = useFeature(UsersWebFeature)
  const navigate = useNavigate()
  const [refreshAuth, { loading }] = useRefreshAuthMutation({
    onError: onMutationError('There was an error logging you in.'),
    async onCompleted(data) {
      const { user, jwt, initialPath } = data.refreshAuth
      await loginUser({
        user,
        jwt,
        apolloFeature,
        usersFeature,
        navigate,
        initialPath: initialPath || undefined,
      })
    },
  })

  return { refreshAuth, loading }
}
