import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { Button, Image } from '@thesisedu/ui'
import { AddMediaImage } from '@thesisedu/ui/icons'
import { LexicalEditor, NodeKey } from 'lexical'
import React from 'react'

import { ImagePayload } from './ImageNode'
import { findDominantColor } from './findDominantColor'
import { ImageUploadFile, useImageUploadFile } from './useImageUploadFile'
import { PluginLevel } from '../../EditorPlugins'
import { NestedEditor } from '../../NestedEditor'
import { useEditorContext } from '../../context/EditorContext'

export interface ImageComponentProps extends ImagePayload {
  caption: LexicalEditor
  nodeKey: NodeKey
  onUpdateDimensionsAndColor: (
    editor: LexicalEditor,
    dimensions: [number, number],
    primaryColor: string | null,
  ) => void
  afterUpload: (editor: LexicalEditor, src?: string) => void
  uploadFile?: ImageUploadFile
}
export function ImageComponent({
  src: _src,
  altText,
  attribution,
  caption,
  radius,
  dimensions,
  primaryColor,
  nodeKey,
  showCaption,
  uploadFile: _uploadFile,
  onUpdateDimensionsAndColor,
  afterUpload,
}: ImageComponentProps) {
  const { readOnly } = useEditorContext(false) || {}
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const [uploadFile, setUploadFile] = React.useState<ImageUploadFile | undefined>(_uploadFile)
  React.useEffect(() => {
    setUploadFile(_uploadFile)
  }, [_uploadFile])
  React.useEffect(() => {
    if (_src) setUploadFile(undefined)
  }, [_src])
  const [editor] = useLexicalComposerContext()
  const imageRef = React.useRef<HTMLImageElement>(null)
  const uploadFileResult = useImageUploadFile({
    uploadFile: readOnly ? undefined : uploadFile,
    nodeKey,
    afterUpload: (...args) => afterUpload(editor, ...args),
  })
  const src = uploadFile ? uploadFileResult.src : _src
  const { filename } = getFileInfo(src)

  if (filename) {
    return (
      <Image
        ref={imageRef}
        src={filename}
        style={{
          maxWidth: '100%',
        }}
        alt={altText}
        radius={radius}
        attribution={attribution ?? undefined}
        dimensions={dimensions || undefined}
        dominantColor={primaryColor || undefined}
        overlay={uploadFileResult.loading ? <Image.LoadingOverlay /> : undefined}
        caption={
          showCaption ? (
            <NestedEditor
              hostNodeKey={nodeKey}
              editor={caption}
              level={PluginLevel.Bare}
              placeholder={<span>Enter your caption here.</span>}
            />
          ) : undefined
        }
        onLoad={() => {
          if (imageRef.current && !dimensions && !readOnly) {
            const { naturalWidth, naturalHeight } = imageRef.current
            if (naturalWidth && naturalHeight) {
              const newDimensions: [number, number] = [naturalWidth, naturalHeight]
              const dominantColor = findDominantColor(imageRef.current)
              onUpdateDimensionsAndColor(
                editor,
                newDimensions,
                dominantColor
                  ? `rgb(${dominantColor.r}, ${dominantColor.g}, ${dominantColor.b})`
                  : null,
              )
            }
          }
        }}
      />
    )
  } else {
    return (
      <>
        <input
          ref={fileInputRef}
          type={'file'}
          accept={'image/*'}
          style={{ display: 'none' }}
          onChange={e => {
            if (e.target.files && e.target.files.length === 1) {
              setUploadFile({
                editor,
                file: e.target.files[0],
              })
            }
          }}
        />
        <Button
          style={{
            width: '100%',
          }}
          icon={<AddMediaImage />}
          onPress={() => {
            if (fileInputRef.current) {
              fileInputRef.current.click()
            }
          }}
        >
          Click here to upload an image...
        </Button>
      </>
    )
  }
}
