import { LoadingIndicator, Result } from '@thesisedu/react'
import { Block, HSpaced, styled } from '@thesisedu/web'
import { Collapse, Empty } from 'antd'
import React from 'react'

import {
  SelectClassCheckbox,
  StudentSelectorCollapseHeader,
  StudentSelectorCollapsePanel,
} from './StudentSelectorCollapsePanel'
import { ClassStudentPair } from '../types'
import { useAllStudentsQuery } from '../useAllStudentsQuery'

export interface StudentSelectorProps {
  value?: ClassStudentPair[]
  onChange: (pairs: ClassStudentPair[]) => void
  empty?: React.ReactElement
  single?: boolean
  classesOnly?: boolean
  visible?: boolean
}
export function StudentSelector({
  value,
  onChange,
  empty,
  single,
  classesOnly,
  visible,
}: StudentSelectorProps) {
  const { classes, loading, error } = useAllStudentsQuery(!visible)

  if (loading && !classes?.length) {
    return <LoadingIndicator block />
  } else if (error) {
    return <Result.Error description={'There was an error fetching your students.'} />
  } else if (classes?.length) {
    if (classesOnly) {
      return (
        <>
          {classes.map(cls => (
            <Block marginBottom={'@size-xs'} key={cls.id}>
              <HSpaced align={'center'}>
                <StudentSelectorCollapseHeader cls={cls} />
                <SelectClassCheckbox cls={cls} selectedPairs={value} onChange={onChange} />
              </HSpaced>
            </Block>
          ))}
        </>
      )
    } else {
      return (
        <CollapseWrapper>
          <Collapse>
            {classes.map(cls => (
              <StudentSelectorCollapsePanel
                key={cls.id}
                cls={cls}
                value={value}
                onChange={onChange}
                single={single}
              />
            ))}
          </Collapse>
        </CollapseWrapper>
      )
    }
  } else {
    return empty ? empty : <Empty description={"You don't have any students."} />
  }
}

const CollapseWrapper = styled.div`
  .ant-collapse-content-box {
    padding-right: 0;
  }
  .ant-collapse-extra {
    margin-bottom: 2px;
  }
  .ant-collapse-header {
    display: flex !important;
    align-items: center;
    padding: ${props => props.theme['@size-xs']} 0 !important;
    > .anticon {
      position: static !important;
      margin-right: ${props => props.theme['@size-xs']};
      padding: 0 !important;
    }
  }
  .ant-collapse-extra .ant-checkbox {
    display: block;
  }
`
