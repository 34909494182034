import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { SegmentGroupView } from './SegmentGroupView'
import { SegmentStandards } from './SegmentStandards'
import { fromNavigationKey } from '../contexts/SegmentNavigationContext'
import { NavigationKey, useDefaultNavigationKey } from '../course_viewer/getSegmentNavigationItems'
import { useSegmentNavigationItems } from '../course_viewer/useSegmentNavigationItems'
import { ReadOnlyEditor, ReadOnlyEditorProps } from '../editor/Editor'
import { SegmentProps } from '../types'

export interface SegmentContentChildProps {
  selectedChild?: string[]
}

export interface SegmentContentProps
  extends Pick<SegmentProps, 'segment' | 'selectedNavigationId'> {
  teacher?: (props: SegmentContentChildProps) => React.ReactElement
  student?: (props: SegmentContentChildProps) => React.ReactElement
  editorProps?: Partial<ReadOnlyEditorProps>
  children?: React.ReactElement | null
}
export function SegmentContent({
  teacher: Teacher,
  student: Student,
  segment,
  selectedNavigationId,
  editorProps,
  children,
}: SegmentContentProps) {
  const { navigationItems } = useSegmentNavigationItems()
  const defaultKey = useDefaultNavigationKey(segment)
  const [parent, ...rest] = fromNavigationKey(selectedNavigationId || defaultKey)
  const SelectedNavigationItemRender = navigationItems.find(i => i.id === parent)?.renderContent
  let content
  if (parent === NavigationKey.Teacher) {
    if (Teacher) {
      content = <Teacher selectedChild={rest} />
    } else {
      content = (
        <ReadOnlyEditor
          id={`${segment.id}-teacher`}
          defaultValue={segment.config.teacherContent}
          readOnlyPlaceholder={'No teacher content is available.'}
          {...editorProps}
        />
      )
    }
  } else if (parent === NavigationKey.Student) {
    if (Student) {
      content = <Student selectedChild={rest} />
    } else {
      content = (
        <ReadOnlyEditor
          id={`${segment.id}-student`}
          defaultValue={segment.config.studentContent}
          readOnlyPlaceholder={'No student content is available.'}
          {...editorProps}
        />
      )
    }
  } else if (parent === NavigationKey.Standards) {
    content = <SegmentStandards segment={segment} />
  } else if (parent === NavigationKey.Children) {
    content = <SegmentGroupView segment={segment} />
  } else if (SelectedNavigationItemRender) {
    content = <SelectedNavigationItemRender segment={segment} />
  } else {
    content = children
  }

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <motion.div
        initial={{ opacity: 0, scale: 0.99 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.99 }}
        transition={{ duration: 0.2 }}
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
        key={selectedNavigationId || defaultKey}
      >
        {content}
      </motion.div>
    </AnimatePresence>
  )
}
