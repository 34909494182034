import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React from 'react'

import { useUserHasCompletedTourQuery } from '../../../schema'
import { useSegmentViewedDecorationUpdater } from '../../../segment/useSegmentViewedDecorationUpdater'

export function useTourWidgetCompletionStatus(segmentId: string, widgetId: string) {
  const [overrideComplete, setOverrideComplete] = React.useState(false)
  const selectedClassId = useSelectedClassId()
  const callback = useSegmentViewedDecorationUpdater(segmentId, false)
  const { data, loading } = useUserHasCompletedTourQuery({
    variables: {
      classId: selectedClassId || 'no-class',
      widgetId,
    },
  })
  return {
    loading,
    isComplete: !!data?.viewer?.hasCompletedTour || overrideComplete,
    onComplete() {
      callback()
      setOverrideComplete(true)
    },
  }
}
