import { FormInstance } from '@thesisedu/react'
import React from 'react'

import {
  AssignmentViewerContextValue,
  useAssignmentViewerContext as ogUseAssignmentViewerContext,
} from '../../contexts/AssignmentViewerContext'
import { ViewingAssignment } from '../../grading/types'

interface AssignmentViewerFormContextValue {
  completedFields: number
  getCompletedFields: (values: Record<string, any>) => number
  setCompletedFields: (value: number) => void
  form: FormInstance<Record<string, any>>
  shouldDisplayRetry: boolean
  timeLimitReached: boolean
  disableFormSubmit?: boolean
  setTimeLimitReached: (reached: boolean) => void
  setMergedSubmissionData: (submissionData: Record<string, any>) => void
  actionsDisabled: boolean
}
export const AssignmentViewerFormContext = React.createContext<
  AssignmentViewerFormContextValue | undefined
>(undefined)

export function useAssignmentViewerFormContext(): AssignmentViewerFormContextValue | undefined
export function useAssignmentViewerFormContext(
  require: false,
): AssignmentViewerFormContextValue | undefined
export function useAssignmentViewerFormContext(require: true): AssignmentViewerFormContextValue
export function useAssignmentViewerFormContext(
  require?: boolean,
): AssignmentViewerFormContextValue | undefined {
  const context = React.useContext(AssignmentViewerFormContext)
  if (!context && require) {
    throw new Error('AssignmentViewerFormContext is required, yet not provided.')
  }
  return context
}

export interface AssignmentViewerFormAssignmentViewerContext extends AssignmentViewerContextValue {
  assignment: ViewingAssignment
}
export function useAssignmentViewerContext() {
  return ogUseAssignmentViewerContext(true) as AssignmentViewerFormAssignmentViewerContext
}
