import { ApolloClient, ApolloProvider } from '@apollo/client'
import React from 'react'

import { useAutoRefresh } from './useAutoRefresh'

export interface ApolloReactProviderProps {
  clientPromise: Promise<ApolloClient<any>>
}
export function ApolloReactProvider({
  clientPromise,
  children,
}: React.PropsWithChildren<ApolloReactProviderProps>) {
  const [client, setClient] = React.useState<ApolloClient<any> | undefined>(undefined)
  useAutoRefresh()
  React.useEffect(() => {
    clientPromise.then(resolvedClient => {
      setClient(resolvedClient)
    })
  }, [clientPromise])

  return client ? <ApolloProvider client={client} children={children} /> : null
}
