import { ClassGradingMode } from '../schema'

export type GradingModeMap = {
  [gradingMode in ClassGradingMode]: {
    label: string
    gradedSuffix: string
    description: string
  }
}

export const GRADING_MODE_MAP: GradingModeMap = {
  [ClassGradingMode.WeightedPoints]: {
    label: 'Points-Based with Weighted Categories',
    gradedSuffix: 'points-based grading, with weighted categories',
    description:
      'Assignments inside a category are weighted based on their total points. Across ' +
      'categories, grades are calculated using a weighted-average with category weights ' +
      'determined by their percentages.',
  },
  [ClassGradingMode.PurePoints]: {
    label: 'Points-Based',
    gradedSuffix: 'purely points-based grading',
    description:
      'Assignments inside the entire course are weighted based on their total points. ' +
      'Categories are purely informational, and do not affect any student grades.',
  },
  [ClassGradingMode.WeightedAverage]: {
    label: 'Weighted Average (Legacy)',
    gradedSuffix: 'weighted averages (legacy)',
    description:
      'Assignments inside a category are not weighted. Instead, the percentage grade of ' +
      'each assignment submission is averaged together. Across categories, grades are ' +
      'calculated using a weighted-average.',
  },
}
