import { styled, s } from '@thesisedu/ui'

export const PrefixContainer = styled.div`
  height: 24px;
  font-size: ${s.size('s')};
  background: ${s.color('subtle')};
  border-radius: ${s.var('radii.1')};
  display: flex;
  align-items: center;
  gap: ${s.size('xxs')};
  padding: 0 ${s.size('xs')};
`
export const Indicator = styled.div<{ $color: 'green' | 'red' | 'blue' | 'orange' }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${props => s.color(props.$color)};
`
