import { Button$, Dropdown } from '@thesisedu/ui'
import { ShareIos } from '@thesisedu/ui/icons'
import React from 'react'

import { SHARE_FILTER_OPTS } from './constants'
import { SharingType } from './schema'
import { ShareFilterButtonProps } from './types'

export function ShareFilterButton({
  sharingTypes,
  onSharingTypesChanged,
  label,
  ...props
}: ShareFilterButtonProps & Partial<Button$.ButtonProps>) {
  const buttonLabel = (
    SHARE_FILTER_OPTS.find(i => i.value === (sharingTypes ? sharingTypes.join('|') : undefined)) ||
    SHARE_FILTER_OPTS[0]
  ).label.replace('Content', label || 'Content')
  return (
    <Dropdown.Container>
      <Dropdown.Button
        children={`Viewing ${buttonLabel}`}
        variant={sharingTypes?.length ? 'primary' : undefined}
        icon={<ShareIos />}
        {...props}
      />
      <Dropdown.Menu>
        {SHARE_FILTER_OPTS.map(opt => {
          return (
            <Dropdown.Item.Checkbox
              key={opt.value}
              children={opt.label.replace('Content', label || 'Content')}
              checked={opt.value === (sharingTypes ? sharingTypes.join('|') : undefined)}
              onCheckedChange={checked => {
                if (checked) {
                  onSharingTypesChanged(
                    opt.value === 'none' ? undefined : (opt.value.split('|') as SharingType[]),
                  )
                }
              }}
            />
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
