import { Block, LoadingIndicator, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { InfiniteQuery } from './InfiniteQuery'
import { getSetResult, RequiredVariables } from './loadMore'
import { InfiniteQueryProps } from './useInfiniteQuery'

type ItemsFn<T> = (items: T[]) => T[]
interface BaseInfiniteQueryListProps<Item> {
  noMore?: boolean | React.ReactNode
  renderItem: (item: Item) => React.ReactElement | null
  prependItems?: Item[] | ItemsFn<Item>
  appendItems?: Item[] | ItemsFn<Item>
}
export type InfiniteQueryListProps<
  Item,
  Data,
  Variables extends RequiredVariables,
> = BaseInfiniteQueryListProps<Item> & Omit<InfiniteQueryProps<Item, Data, Variables>, 'children'>

export function InfiniteQueryList<Item, Data, Variables extends RequiredVariables>({
  renderItem,
  noMore,
  prependItems,
  appendItems,
  ...infiniteQueryProps
}: InfiniteQueryListProps<Item, Data, Variables>) {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const { getResult } = getSetResult<Data, Variables>(infiniteQueryProps)
  return (
    <div ref={containerRef}>
      <InfiniteQuery
        {...infiniteQueryProps}
        infiniteScrollerProps={{
          ...infiniteQueryProps.infiniteScrollerProps,
          scrollableTarget: containerRef.current || undefined,
        }}
        children={({ data, loading }) => {
          const result = getResult(data)
          const hasMore = result?.pageInfo.hasNextPage
          const nodes = getResult(data)?.edges.map(edge => edge.node) || []
          const prepend = typeof prependItems === 'function' ? prependItems(nodes) : prependItems
          const append = typeof appendItems === 'function' ? appendItems(nodes) : appendItems
          const allItems = [...(prepend || []), ...nodes, ...(append || [])]
          return (
            <VSpaced space={'xxs'}>
              {allItems.map(item => renderItem(item))}
              {loading && hasMore ? (
                <Block top={'s'}>
                  <LoadingIndicator />
                </Block>
              ) : null}
              {!hasMore ? (
                noMore === true ? (
                  <Text top={'s'} level={'s'} color={'secondary'} style={{ textAlign: 'center' }}>
                    No more items.
                  </Text>
                ) : (
                  noMore
                )
              ) : null}
            </VSpaced>
          )
        }}
      />
    </div>
  )
}
