import React from 'react'

import { Body } from './Typography'
import { styled } from './styledTypes'

export interface FormItemProps {
  label: string | React.ReactElement
  extra?: string | React.ReactElement
}
export function FormItem({ children, label, extra }: React.PropsWithChildren<FormItemProps>) {
  return (
    <Container>
      <LabelContainer>{label}</LabelContainer>
      {children}
      {extra ? <ExtraContainer children={extra} /> : null}
    </Container>
  )
}

const Container = styled.label`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-s']};
  }
  &:not(:first-child) {
    margin-top: ${props => props.theme['@size-s']};
  }
`
const LabelContainer = styled(Body)`
  margin-bottom: ${props => props.theme['@size-xs']} !important;
`
const ExtraContainer = styled(Body)`
  color: ${props => props.theme['@text-color-secondary']};
  margin-top: ${props => props.theme['@size-xxs']};
`
