import { InteractionsContext } from '@thesisedu/feature-interactions-react'
import React from 'react'

export const FOCUSED_ASSIGNMENT_ANSWER = 'FOCUSED_ASSIGNMENT_ANSWER'
export interface UseAnswerFocus {
  assignmentId?: string
  metadata?: any
}
export function useAnswerFocus({ assignmentId, metadata }: UseAnswerFocus) {
  const manager = React.useContext(InteractionsContext)
  return React.useMemo(
    () => ({
      onFocus: () => {
        if (manager) {
          manager.startInteraction({
            type: FOCUSED_ASSIGNMENT_ANSWER,
            reference: assignmentId,
            metadata,
          })
        }
      },
      onBlur: () => {
        if (manager) {
          manager.stopInteraction(FOCUSED_ASSIGNMENT_ANSWER)
        }
      },
    }),
    [manager],
  )
}
