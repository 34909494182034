import { cloneDeep } from '@apollo/client/utilities/common/cloneDeep'
import { Question } from '@thesisedu/feature-assignments-core'
import { useFreshRef } from '@thesisedu/feature-react'
import { WeightedWidget } from '@thesisedu/feature-widgets-core'
import { max, orderBy } from 'lodash'
import { v4 as uuid } from 'uuid'

import { QuestionContainerProps } from './QuestionContainer'
import { QUESTION_DEFAULTS } from './constants'
import { useQuestionResources } from '../questions/useQuestionResource'

export function useOnAdd({
  onChange,
  questions,
  widgets,
}: Pick<QuestionContainerProps, 'onChange' | 'questions' | 'widgets'>) {
  const resources = useQuestionResources()
  const onChangeRef = useFreshRef(onChange)
  const questionsRef = useFreshRef(questions)

  return (questionType: string, afterId?: string) => {
    const resource = resources.find(r => r.identifier === questionType)
    if (!resource) throw new Error(`Invalid question type: ${questionType}`)
    if (!onChangeRef.current) return
    const questions = questionsRef.current // Override for this scope.
    const onChange = onChangeRef.current // Override for this scope.
    let weight
    const maximumWeight =
      max([
        ...questions.map(question => question.weight),
        ...(widgets?.map(w => w.weight) || []),
      ]) || 0
    if (afterId) {
      console.info('adding after %s', afterId)
      const questionIndex = questions.findIndex(q => q.id === afterId)
      const nextQuestion = questions[questionIndex + 1]
      if (questionIndex >= 0 && nextQuestion) {
        weight = ((questions[questionIndex].weight || 0) + (nextQuestion.weight || 0)) / 2
      } else {
        weight = maximumWeight + 1
      }
    } else {
      weight = maximumWeight + 1
    }
    const newQuestion = cloneDeep({
      ...QUESTION_DEFAULTS,
      type: questionType,
      totalPoints: 1,
      ...resource.defaults,
    }) as Question
    newQuestion.weight = weight
    newQuestion.id = uuid()

    // Reorder the questions and widgets.
    const _questions = [...questions, newQuestion]
    const combined = orderBy([..._questions, ...(widgets || [])], 'weight', 'asc').map(
      (i, index) => ({ ...i, weight: index }),
    )
    const newQuestions = combined.filter(i => _questions.some(q => q.id === i.id))
    const newWidgets = combined.filter(i => !_questions.some(q => q.id === i.id))

    // Separate back out and call onChange()
    onChange(newQuestions as Question[], newWidgets as WeightedWidget[])
  }
}
