import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { MusicDoubleNote } from '@thesisedu/react/icons'
import React from 'react'

import { SheetMusicElement } from './SheetMusicElement'
import { SheetMusicConfig } from './common'

export default {
  identifier: 'SheetMusic',
  title: 'Sheet Music',
  icon: <MusicDoubleNote />,
  defaultConfig: {
    version: 1,
    abc: '',
    title: '',
    chordOpacity: 0.35,
    solfegeOffset: 4,
  },
  group: SimpleWidget.ElementsGroup.Media,
  weight: 100,
  element: SheetMusicElement,
} satisfies SimpleWidget.SimpleWidgetDefinition<SheetMusicConfig, 'SheetMusic'>
