import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { AnnouncementFragmentDoc, useCreateAnnouncementMutation as useMutation } from '../schema'

export const useCreateAnnouncementMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that announcement.'),
    update: updateMutation({
      fragment: AnnouncementFragmentDoc,
      fragmentName: 'Announcement',
      path: 'createAnnouncement.announcement',
    }),
    ...opts,
  })
