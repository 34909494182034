import { MediaPlaybackContextProvider, Result } from '@thesisedu/react'
import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { MediaViewProps } from './MediaView'
import { MediaViewContext, useMediaViewContext } from './MediaViewContext'
import { useMediaViewMedia } from './useMediaViewMedia'
import { MediaProvider } from '../record/MediaContext'
import { PlaybackFooterContextProvider } from '../record/PlaybackFooterContext'

export interface MediaViewProvidersProps extends Omit<MediaViewProps, 'children'> {
  children: React.ReactNode
}
export function MediaViewProviders({
  children,
  noContextInheritance,
  ...props
}: MediaViewProvidersProps) {
  const existing = useMediaViewContext(false)
  if (existing && !noContextInheritance) {
    return <>{children}</>
  } else {
    return <NewMediaViewProviders children={children} {...props} />
  }
}

function NewMediaViewProviders({ children, ...props }: MediaViewProvidersProps) {
  const { media, loading } = useMediaViewMedia({ mediaId: props.mediaId })
  if (media) {
    return (
      <MediaProvider media={media}>
        <MediaPlaybackContextProvider>
          <PlaybackFooterContextProvider>
            <MediaViewContext.Provider value={props} children={children} />
          </PlaybackFooterContextProvider>
        </MediaPlaybackContextProvider>
      </MediaProvider>
    )
  } else if (loading) {
    return <LoadingIndicator.Labeled label={'Loading media...'} />
  } else {
    return <Result.Error description={'Could not load media'} />
  }
}
