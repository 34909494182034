import ApolloReactFeature, { BackgroundJobReporterResource } from '@thesisedu/feature-apollo-react'

import { CoursesReactFeature } from '../../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'copyTagsToChildren',
    humanLabel: () => 'Copying tags to children...',
    async afterComplete() {
      const apolloFeature = feature.root.requireFeature<ApolloReactFeature>(
        ApolloReactFeature.package,
      )
      await apolloFeature.client.refetchQueries({
        include: ['course', 'courseVersion'],
      })
    },
  })
}
