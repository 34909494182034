import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { styled } from '../styled'
import { color, getColor, size } from '../theme'

export interface BasicNavigationItemProps {
  icon?: React.ReactElement
  children: React.ReactElement | null | string
  selected?: boolean
}
export function BasicNavigationItem({ icon, children, selected }: BasicNavigationItemProps) {
  return (
    <Container selected={selected}>
      {icon ? <IconContainer selected={selected}>{icon}</IconContainer> : null}
      {children}
    </Container>
  )
}

export function BasicNavLink({
  icon,
  children,
  selected,
  ...rest
}: BasicNavigationItemProps & NavLinkProps) {
  return (
    <Container selected={selected} as={NavLink} activeClassName={'selected'} {...rest}>
      {icon ? <IconContainer selected={selected}>{icon}</IconContainer> : null}
      {children}
    </Container>
  )
}

const IconContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${size('@border-radius-base')};
  width: ${size('@size-m')};
  height: ${size('@size-m')};
  font-size: ${size('@size-s')};
  background: ${props => getColor(props.theme, props.selected ? '@primary-color' : '@gray-1')};
  color: ${props => getColor(props.theme, props.selected ? '@gray-0' : '@gray-5')};
  transition:
    background 0.1s linear,
    color 0.1s linear;
  svg {
    display: block;
  }
`
const Container = styled.div<{ selected?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  gap: ${size('@size-xs')};
  transition:
    background 0.1s linear,
    color 0.1s linear;
  border-radius: ${size('@border-radius-base')};
  cursor: pointer;
  padding: 0 ${size('@size-xs')};
  font-size: ${size('@font-size-base')};
  color: ${props => getColor(props.theme, props.selected ? '@primary-color' : '@gray-6')};
  background: ${props => getColor(props.theme, props.selected ? '@gray-1' : 'transparent')};
  &:hover {
    background: ${color('@gray-1')};
    color: ${color('@gray-6')};
  }
  * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &.selected {
    color: ${color('@primary-color')};
    background: ${color('@gray-1')};
    ${IconContainer} {
      background: ${color('@primary-color')};
      color: ${color('@gray-0')};
    }
  }
`
