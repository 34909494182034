import { MediaList, MediaListProps, MediaTableListItem } from '@thesisedu/feature-media-react'
import { ShareIndicator } from '@thesisedu/feature-sharing-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { formatTime, DATE_FORMATS } from '@thesisedu/web'
import { Empty } from 'antd'
import { TableProps } from 'antd/es/table'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

import { LessonActions } from './LessonActions'
import { BasicLessonFragment, LessonFragment } from './schema'

type Fragment = BasicLessonFragment | LessonFragment
export interface LessonsListProps extends Partial<MediaListProps<Fragment>> {
  lessons: Fragment[]
  onLessonClick?: (lessonId: string, lesson: Fragment) => any
  refetch: (after: string | null) => Promise<any>
  action?: (item: Fragment) => React.ReactElement | undefined
}

export const LessonsList: React.FC<React.PropsWithChildren<LessonsListProps>> = ({
  lessons,
  refetch,
  onLessonClick,
  action,
  ...props
}) => {
  const viewer = useViewerContext(true)
  const getSubtitle = (item: Fragment): string | React.ReactElement => {
    const posted = `Posted ${moment(item.createdAt).format(DATE_FORMATS.TEXT)}`
    if ((item as LessonFragment).students) {
      return (
        <>
          {posted}
          <br />
          Assigned to {pluralize('student', (item as LessonFragment).students.totalCount, true)}
          {(item as LessonFragment).students.totalCount > 0 ? (
            <>
              <br />
              {`${item.studentsViewed} / ${
                (item as LessonFragment).students.totalCount
              } students viewed`}
            </>
          ) : (
            ''
          )}
          {item.media.durationInSeconds ? (
            <>
              <br />
              {formatTime(item.media.durationInSeconds?.toString())} long
            </>
          ) : (
            ''
          )}
        </>
      )
    } else {
      return posted
    }
  }

  const columns: TableProps<MediaTableListItem>['columns'] = [
    {
      title: 'Posted On',
      dataIndex: ['posted'],
      key: 'posted',
      render: (_, record) => {
        const date = record.posted
        return moment(date).format(DATE_FORMATS.FULL)
      },
    },
    {
      title: 'Students Assigned',
      dataIndex: ['assignedStudents'],
      key: 'assignedStudents',
    },
  ]

  return (
    <MediaList<Fragment>
      items={lessons}
      emptyContent={<Empty description={'No lessons yet!'} />}
      refetch={refetch}
      getItemProps={item => ({
        label: item.name,
        subtitle: getSubtitle(item),
        posted: moment(item.createdAt).format(DATE_FORMATS.TEXT),
        assignedStudents: pluralize('student', (item as LessonFragment).students?.totalCount, true),
        onClick: () => (onLessonClick ? onLessonClick(item.id, item) : false),
        actions:
          viewer.group === 'TEACHER' ? (
            <LessonActions lesson={item as LessonFragment} onRefetch={() => refetch(null)} />
          ) : action ? (
            action(item)
          ) : undefined,
        meta: <ShareIndicator shareable={item} />,
        useBasicActions: typeof action === 'function',
      })}
      tableProps={{
        columns,
      }}
      {...props}
    />
  )
}
