import { Dropdown } from '@thesisedu/ui'
import { ClipboardCheck, Folder, SaveFloppyDisk } from '@thesisedu/ui/icons'
import React from 'react'

import { RubricTemplateSelectModal } from './RubricTemplateSelectModal'
import { SaveAsTemplateModal } from './SaveAsTemplateModal'
import { transformRubric } from './helpers'
import { RubricEditorActionProps } from './types'

export function RubricEditorTemplatesAction({ onChange, value }: RubricEditorActionProps) {
  return (
    <Dropdown.Container>
      <Dropdown.Button children={'Rubric Templates'} icon={<ClipboardCheck />} />
      <Dropdown.Menu align={'end'} side={'bottom'}>
        <RubricTemplateSelectModal
          trigger={<Dropdown.Item children={'Load from template...'} icon={<Folder />} />}
          onSelected={template => {
            const transformed = transformRubric(template.rubric)
            onChange?.(transformed)
          }}
        />
        <SaveAsTemplateModal
          value={value}
          trigger={
            <Dropdown.Item
              children={'Save as template...'}
              icon={<SaveFloppyDisk />}
              disabled={!value}
            />
          }
        />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
