import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { MatchConfig, MatchOption } from '@thesisedu/feature-assignments-core'
import { HSpaced, styled } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { QuestionTextMediaField } from '../../../questions/QuestionTextMediaField'
import { QuestionProps } from '../../../types'
import { MatchGroupQuestionAdditionalFields } from '../MatchGroupQuestionAdditionalFields'
import { MultipleChoiceChoice } from '../MultipleChoiceChoice'
import { QuestionInstructions } from '../QuestionInstructions'
import { ChoiceEditorContainer } from '../styled'

export function MatchEdit(props: QuestionProps<MatchConfig>) {
  const { onChange, question } = props
  const { options = [] } = question.config || {}
  const uniqueDestinations = new Set(options.map(opt => opt.destination))
  const uniqueSources = [...new Set(options.map(opt => opt.source) || [])]
  const hasDuplicateDestinations = uniqueDestinations.size !== options.length
  const hasDuplicateSources = uniqueSources.length !== options.length
  const addOption = () => {
    if (onChange) {
      const newId = uuid()
      const newOptions = [
        ...(options || []),
        {
          id: newId,
          source: '',
          destination: '',
        },
      ]
      onChange({
        config: {
          ...question.config,
          options: newOptions,
        },
        totalPoints: newOptions.length,
      })
    }
  }
  return (
    <>
      <QuestionInstructions instructions={'Enter your matches using the fields below.'}>
        {hasDuplicateDestinations ? (
          <WarningMessage>
            <ExclamationCircleOutlined /> You cannot have two matches with the same name.
          </WarningMessage>
        ) : null}
        {hasDuplicateSources ? (
          <WarningMessage>
            <ExclamationCircleOutlined /> You cannot have two items on the left side with the same
            name.
          </WarningMessage>
        ) : null}
        {(options || []).map(option => {
          const update = (changes: Partial<MatchOption>) => {
            if (onChange) {
              onChange({
                config: {
                  ...question.config,
                  options: (options || []).map(opt => {
                    if (opt.id === option.id) {
                      return { ...opt, ...changes }
                    } else {
                      return opt
                    }
                  }),
                },
                totalPoints: (options || []).length || 1,
              })
            }
          }
          return (
            <MatchOptionEditor
              key={option.id}
              option={option}
              onChange={update}
              onDelete={() => {
                if (onChange) {
                  const newOptions = (options || []).filter(opt => opt.id !== option.id)
                  onChange({
                    config: {
                      ...question.config,
                      options: newOptions,
                    },
                    totalPoints: newOptions.length || 1,
                  })
                }
              }}
            />
          )
        })}
        <MultipleChoiceChoice
          isAdd
          option={{
            id: 'add',
            name: `Add ${options?.length ? 'another' : 'the first'} match...`,
            correct: false,
          }}
          index={1}
          onClick={addOption}
        />
      </QuestionInstructions>
      <MatchGroupQuestionAdditionalFields {...(props as QuestionProps<any>)} />
    </>
  )
}

export interface MatchOptionEditorProps {
  onChange: (changes: Partial<MatchOption>) => void
  onDelete: () => void
  option: MatchOption
}
export function MatchOptionEditor({ option, onDelete, onChange }: MatchOptionEditorProps) {
  return (
    <AnswerContainer>
      <HSpaced align={'flex-start'} style={{ width: '100%' }}>
        <QuestionTextMediaField
          value={option.source}
          onChange={source => onChange({ source })}
          style={{ flex: 1 }}
          textAreaProps={{
            autoSize: { minRows: 1, maxRows: 3 },
            placeholder: 'This',
          }}
        />
        <QuestionTextMediaField
          value={option.destination}
          onChange={destination => onChange({ destination })}
          style={{ flex: 1 }}
          textAreaProps={{
            autoSize: { minRows: 1, maxRows: 3 },
            placeholder: 'That. Use :blank: to add fill-in-the-blank.',
          }}
        />
        <Button icon={<DeleteOutlined />} onClick={onDelete} />
      </HSpaced>
    </AnswerContainer>
  )
}

const AnswerContainer = styled(ChoiceEditorContainer)``
export const WarningMessage: any = styled.div`
  background: ${props => props.theme['@orange-light']};
  padding: ${props => props.theme['@size-s']};
  color: ${props => props.theme['@orange']};
`
