import React from 'react'

import { s } from '../../'
import { styledWithVariants } from '../../style'
import { IconAliases } from '../../utils/icons'

export const iconMap: Record<CalloutVariant, React.ComponentType> = {
  info: IconAliases.Info,
  success: IconAliases.Success,
  warning: IconAliases.Warning,
  error: IconAliases.Error,
}

export interface CalloutProps {
  variant?: CalloutVariant
  icon?: React.ReactElement
  className?: string
}
export function Callout({
  className,
  variant: _variant = 'info',
  icon,
  children,
}: React.PropsWithChildren<CalloutProps>) {
  const Icon = iconMap[_variant]
  const variant = s.variants(className, {}, _variant)
  return (
    <Container className={variant}>
      <TopLeftIcon className={variant}>{icon ? icon : <Icon />}</TopLeftIcon>
      {children}
    </Container>
  )
}

const { css } = s
const CalloutVariants = {
  info: css`
    --subtle-color: ${s.color('blue.subtle')};
    --accent-color: ${s.color('blue.solid')};
  `,
  success: css`
    --subtle-color: ${s.color('green.subtle')};
    --accent-color: ${s.color('green.solid')};
  `,
  warning: css`
    --subtle-color: ${s.color('orange.subtle')};
    --accent-color: ${s.color('orange.solid')};
  `,
  error: css`
    --subtle-color: ${s.color('red.subtle')};
    --accent-color: ${s.color('red.solid')};
  `,
} satisfies s.Variants
export type CalloutVariant = s.VariantString<typeof CalloutVariants>

const Container = styledWithVariants(
  'div',
  'CalloutContainer',
  css`
    --subtle-color: ${s.color('gray.subtle')};
    --accent-color: ${s.color('gray.solid')};
    background: var(--subtle-color);
    padding: ${s.size('4')} ${s.size('6')};
    border-left: solid ${s.var('radii.1')} var(--accent-color);
    position: relative;
    border-radius: ${s.var('radii.1')};
    *:last-child {
      margin-bottom: 0 !important;
    }
  `,
  CalloutVariants,
)
const TopLeftIcon = styledWithVariants(
  'div',
  'TopLeftIcon',
  css`
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    background: ${s.color('gray.background')};
    width: ${s.size('7')};
    height: ${s.size('7')};
    font-size: ${s.size('5')};
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--accent-color);
  `,
  CalloutVariants,
)
