import { BodySmall, H2, TruncatedText, Markdown } from '@thesisedu/react'
import pluralize from 'pluralize'
import React from 'react'

import { guessAssignmentCategoryName } from '../grading/helpers'
import { FullAssignment } from '../grading/types'

export interface AssignmentInfoProps {
  assignment: FullAssignment
  subtitle: string
}
export function AssignmentInfo({ assignment, subtitle }: AssignmentInfoProps) {
  const categoryName = guessAssignmentCategoryName(assignment)
  return (
    <>
      <BodySmall color={'@text-color-secondary'}>{subtitle}</BodySmall>
      <H2>{assignment.name}</H2>
      <TruncatedText numberOfLines={3} useDiv isBlock>
        <Markdown children={assignment.description || ''} />
      </TruncatedText>
      <BodySmall color={'@text-color-secondary'}>
        {pluralize('point', assignment.totalPoints, true)}
        {categoryName ? <>&nbsp;&nbsp;-&nbsp;&nbsp;{categoryName}</> : null}
      </BodySmall>
    </>
  )
}
