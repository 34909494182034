import { Text, Button, HSpaced, VSpaced, Checkbox, styled, Form, Popover, s } from '@thesisedu/ui'
import { NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { RevealAnswers } from '../schema'

interface RevealAnswerInfo {
  label: string
  explanation: React.ReactElement
}
const NO_VALUE = 'none'
type RevealAnswerInfoMap = Record<RevealAnswers | 'none', RevealAnswerInfo>
const INFO: RevealAnswerInfoMap = {
  none: {
    label: "Don't Reveal",
    explanation: <>Answers will never be revealed to students.</>,
  },
  [RevealAnswers.AfterSubmit]: {
    label: 'After Finished',
    explanation: (
      <>
        Answers will be revealed to students after they are finished with the assignment. This is
        either when they are out of attempts, or when they choose to see the answers (and forfeit
        the rest of their attempts).
      </>
    ),
  },
  [RevealAnswers.RealTime]: {
    label: 'Immediately',
    explanation: (
      <>Answers will be revealed to students in real time, as they complete the assignment.</>
    ),
  },
}

interface RevealAnswersFieldProps {
  value?: RevealAnswers
  onChange?: (value?: RevealAnswers | null) => void
  currentMaxAttempts: number
  prefix?: string[]
}
function RevealAnswersField({
  value,
  onChange,
  currentMaxAttempts,
  prefix,
}: RevealAnswersFieldProps) {
  return (
    <VSpaced space={'s'} align={'stretch'} data-testid={'RevealAnswersField'}>
      {Object.keys(INFO).map(key => {
        const info = INFO[key as keyof typeof INFO]
        let content
        if (key === RevealAnswers.AfterSubmit) {
          content = (
            <RevealAnswerAfterFinished prefix={prefix} currentMaxAttempts={currentMaxAttempts} />
          )
        } else {
          content = <BasicRevealAnswerInfo info={info} />
        }

        return (
          <div key={key}>
            <Checkbox
              checked={key === NO_VALUE ? !value : key === value}
              align={'flex-start'}
              onChange={checked => {
                if (onChange && checked) {
                  onChange(key === NO_VALUE ? null : (key as RevealAnswers))
                }
              }}
              label={content}
              aria-label={info.label}
              data-testid={`RevealAnswersField ${key}`}
            />
          </div>
        )
      })}
    </VSpaced>
  )
}

interface BasicRevealAnswerInfoProps {
  info: RevealAnswerInfo
}
function BasicRevealAnswerInfo({ info }: BasicRevealAnswerInfoProps) {
  return (
    <VSpaced style={{ flex: 1 }} align={'flex-start'} space={'xs'}>
      <Text>{info.label}</Text>
      <Text level={'s'}>{info.explanation}</Text>
    </VSpaced>
  )
}

interface RevealAnswerAfterFinishedProps {
  currentMaxAttempts: number
  prefix?: string[]
}
function RevealAnswerAfterFinished({ currentMaxAttempts, prefix }: RevealAnswerAfterFinishedProps) {
  const info = INFO[RevealAnswers.AfterSubmit]
  const inputRef = React.useRef<HTMLInputElement>(null)
  return (
    <VSpaced style={{ flex: 1 }} align={'flex-start'} space={'xs'}>
      {currentMaxAttempts === 1 || !currentMaxAttempts ? (
        <Text>{info.label}</Text>
      ) : (
        <HSpaced space={'xs'} align={'center'}>
          <Text>After</Text>
          <div
            onPointerDown={e => {
              e.stopPropagation()
              inputRef.current?.focus()
            }}
          >
            <Form.NumberField
              name={prefix ? [...prefix, 'revealAttempts'].join('.') : 'revealAttempts'}
              aria-label={'Reveal After N Attempts'}
              ref={inputRef}
              minValue={1}
              size={'small'}
              maxValue={currentMaxAttempts || 1}
              style={{ width: 75 }}
              placeholder={currentMaxAttempts.toString()}
            />
          </div>
          <Text>Attempts</Text>
        </HSpaced>
      )}
      <Text level={'s'}>{info.explanation}</Text>
    </VSpaced>
  )
}

export interface RevealAnswersFieldsProps {
  prefix?: string[]
}
export function RevealAnswersFields({ prefix }: RevealAnswersFieldsProps) {
  const { form } = Form.useFormContext()
  const currentMaxAttempts = form.watch('maxAttempts')
  return (
    <VSpaced space={'xs'} align={'stretch'}>
      <Text>Reveal Answers</Text>
      <Popover.Container>
        <Popover.Trigger asChild>
          <StyledButton data-testid={'RevealAnswersFields'}>
            <div style={{ flex: 1 }}>
              <Form.Field
                aria-label={'Reveal Answers Configuration'}
                name={prefix ? [...prefix, 'revealAnswers'].join('.') : 'revealAnswers'}
              >
                <RevealAnswerSummary currentMaxAttempts={currentMaxAttempts} prefix={prefix} />
              </Form.Field>
            </div>
          </StyledButton>
        </Popover.Trigger>
        <Popover.Content width={400}>
          <Form.Field
            aria-label={'Reveal Answers'}
            name={prefix ? [...prefix, 'revealAnswers'].join('.') : 'revealAnswers'}
          >
            <RevealAnswersField currentMaxAttempts={currentMaxAttempts} prefix={prefix} />
          </Form.Field>
        </Popover.Content>
      </Popover.Container>
    </VSpaced>
  )
}

interface RevealAnswerSummaryProps {
  value?: RevealAnswers
  prefix?: string[]
  currentMaxAttempts: number
}
function RevealAnswerSummary({ value, prefix, currentMaxAttempts }: RevealAnswerSummaryProps) {
  return (
    <HSpaced align={'center'}>
      {value === RevealAnswers.AfterSubmit ? (
        <RevealAnswerAfterFinished currentMaxAttempts={currentMaxAttempts} prefix={prefix} />
      ) : (
        <BasicRevealAnswerInfo info={INFO[value || NO_VALUE]} />
      )}
      <Text color={'secondary'}>
        <NavArrowRight />
      </Text>
    </HSpaced>
  )
}

const StyledButton = styled(Button)`
  height: auto;
  white-space: normal;
  text-align: left;
  padding: ${s.size('s')};
  .ant-input-number {
    background: white;
  }
`
