import { BookOutlined } from '@ant-design/icons'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { UserTask, UserTaskProps } from '@thesisedu/feature-user-tasks-react'
import React from 'react'

import { SECTION_LABEL_VERBS } from './constants'
import { useSegment } from './contexts/SegmentContext'
import { ViewSegmentUserTaskFragment } from './schema'
import { SegmentLink } from './segment/SegmentLink'

export function ViewSegmentUserTask(props: UserTaskProps<ViewSegmentUserTaskFragment>) {
  const segment = useSegment(props.task.payload.segmentId)
  const classId = useSelectedClassId(true)
  if (segment && classId === props.task.payload.classId) {
    return (
      <SegmentLink id={props.task.payload.segmentId}>
        <UserTask {...props} icon={<BookOutlined />} completeOnClick>
          {SECTION_LABEL_VERBS[segment.config.label || ''] || 'Read'}{' '}
          <strong>{segment.name}</strong>
        </UserTask>
      </SegmentLink>
    )
  } else {
    return null
  }
}
