import { cloneDeep } from '@apollo/client/utilities'
import { WebContext } from '@thesisedu/feature-web'
import { useDroppableId } from '@thesisedu/web/react-beautiful-dnd'
import classnames from 'classnames'
import { orderBy } from 'lodash'
import React, { useContext, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { v4 as uuid } from 'uuid'

import { AddSegmentButton } from './AddSegmentButton'
import { ReferenceSegmentDraggable } from './ReferenceSegmentDraggable'
import { SegmentDraggable } from './SegmentDraggable'
import {
  CreateSegmentPayload,
  useCourseEditorStatusContext,
  useCourseEditorWriteContext,
} from '../contexts/CourseEditorContext'
import { SegmentListContextProvider } from '../contexts/SegmentListContext'
import { useSegmentOverrideContext } from '../contexts/SegmentOverrideContext'
import { warn } from '../log'
import { OutlineSearchWrapper } from '../outline/OutlineSearchContext'
import { Segment, SegmentOverrideWithChanges, SegmentType, SegmentTypeResource } from '../types'

const recursivelyVisibleOverrideSegments = (
  overrides: SegmentOverrideWithChanges[],
  visibleOverride: boolean,
  segment: Segment,
) => {
  let resultOverrides: SegmentOverrideWithChanges[] = [
    ...overrides,
    { id: segment.id, changes: { visibleOverride } },
  ]
  if (segment.childSegments) {
    resultOverrides = segment.childSegments.reduce((_resultOverrides, childSegment) => {
      return recursivelyVisibleOverrideSegments(_resultOverrides, visibleOverride, childSegment)
    }, resultOverrides)
  }
  return resultOverrides
}

export interface SegmentListProps {
  parentSegment?: Segment
  id: string
  addTypes?: SegmentType[]
  segments: Segment[]
  expandLevels?: number
}
export function SegmentList({
  parentSegment,
  id,
  addTypes = [],
  segments,
  expandLevels,
}: SegmentListProps) {
  const { featureWeb } = useContext(WebContext)!
  const [createdSegmentId, setCreatedSegmentId] = useState<string>()
  const { onSegmentsOverride } = useSegmentOverrideContext() || {}
  const isOverrideMode = !!onSegmentsOverride
  const { onCreateSegment, onDeleteSegment, onSegmentChange } = useCourseEditorWriteContext() || {}
  const { enableReferences, expandedSegmentIds, highlightedSegmentId } =
    useCourseEditorStatusContext() || {}
  const allSegmentTypes =
    featureWeb.deps.resources.getResourcesForType<SegmentTypeResource>('SegmentType')
  const availableTypes = addTypes || allSegmentTypes.map(type => type.type)
  const sortedSegments = orderBy(segments, 'weight')

  const onDelete = (segment: Segment) => {
    if (onDeleteSegment) {
      onDeleteSegment(segment.id)
    } else {
      warn('cannot delete segment when onDeleteSegment is not provided')
    }
  }
  const segmentChange = (originalSegment: Segment, updates: Partial<Segment>) => {
    if (onSegmentsOverride) {
      if (
        updates.visibleOverride !== undefined &&
        updates.visibleOverride !== originalSegment.visibleOverride
      ) {
        let overrides: SegmentOverrideWithChanges[] = [{ id: originalSegment.id, changes: updates }]
        if (originalSegment.childSegments) {
          overrides = recursivelyVisibleOverrideSegments(
            overrides,
            updates.visibleOverride,
            originalSegment,
          )
        }
        onSegmentsOverride(overrides)
      }
    } else if (onSegmentChange) {
      onSegmentChange({ ...originalSegment, ...updates })
    } else {
      warn('modifications to segment not made; onSegmentsOverride nor onSegmentChange present')
    }
  }

  return (
    <div>
      <Droppable droppableId={useDroppableId(id)} type={id}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={classnames({
              'is-dragging': snapshot.isDraggingOver,
            })}
            {...provided.droppableProps}
          >
            <SegmentListContextProvider
              onSegmentAcknowledged={() => setCreatedSegmentId(undefined)}
              createdSegmentId={createdSegmentId}
            >
              {sortedSegments.map((segment, index) => {
                const isReference = enableReferences && !!segment.referenceSegment
                const props = {
                  segment,
                  index,
                  parentDisabled:
                    parentSegment && parentSegment.type !== 'Root'
                      ? parentSegment.visibleOverride !== true
                      : undefined,
                  id,
                  onChange: segmentChange,
                  onDelete,
                  expandLevels: expandedSegmentIds?.includes(segment.id) ? 1 : expandLevels,
                  highlighted: highlightedSegmentId === segment.id,
                }
                return (
                  <OutlineSearchWrapper
                    segmentIds={[segment.id, ...(segment.childSegments || []).map(s => s.id)]}
                    key={segment.id}
                  >
                    {isReference && segment.referenceSegment ? (
                      <ReferenceSegmentDraggable
                        {...props}
                        referenceSegment={segment.referenceSegment}
                      />
                    ) : (
                      <SegmentDraggable {...props} />
                    )}
                  </OutlineSearchWrapper>
                )
              })}
            </SegmentListContextProvider>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!isOverrideMode ? (
        <AddSegmentButton
          availableTypes={availableTypes}
          onAdd={segmentType => {
            if (parentSegment && onCreateSegment) {
              const newSegment = cloneDeep({
                type: segmentType,
                config: {},
                weight: 0,
                ...allSegmentTypes.find(type => type.identifier === segmentType)!.defaults,
              }) as CreateSegmentPayload
              newSegment.id = uuid()
              setCreatedSegmentId(newSegment.id)
              onCreateSegment(newSegment, parentSegment.id)
            } else {
              warn('cannot create a new segment without a parentSegmentId')
            }
          }}
        />
      ) : null}
    </div>
  )
}
