import { Paths } from '@thesisedu/feature-types'
import { FlattenSimpleInterpolation } from 'styled-components'

export type ColorScale = [
  /** 1 - App Background */
  string,
  /** 2 - Subtle Background */
  string,
  /** 3 - UI Element Background */
  string,
  /** 4 - Hovered UI Element Background */
  string,
  /** 5 - Active / Selected UI Element Background */
  string,
  /** 6 - Subtle borders and separators */
  string,
  /** 7 - UI Element Border and Focus Rings */
  string,
  /** 8 - Hovered UI element border */
  string,
  /**
   * 9 - Solid backgrounds
   * This is the starting point for all colors.
   */
  string,
  /** 10 - Hovered solid backgrounds */
  string,
  /** 11 - Low-contrast text */
  string,
  /** 12 - High-contrast text */
  string,
]

export interface Colors {
  gray: ColorScale
  blue: ColorScale
  green: ColorScale
  orange: ColorScale
  red: ColorScale
}

/** These values are all pixel values. */
export interface Size {
  '0': {
    '1': number
    '25': number
    '5': number
    '75': number
    '9': number
  }
  '1': number
  '2': number
  '3': number
  '4': number
  '5': number
  '6': number
  '7': number
  '8': number
}

export interface Fonts {
  default: string
  serif: string
  code: string
}

export interface FontWeights {
  light: string
  normal: string
  medium: string
  bold: string
  extraBold: string
}

export interface LineHeights {
  default: string
  spaced: string
}

export interface Shadows {
  '0': string
  '1': string
  '2': string
}

export interface LetterSpacings {
  '-3': string
  '-2': string
  '-1': string
  '0': string
  '1': string
  '2': string
  '3': string
}

export interface Borders {
  split: string
  default: string
}

export interface ZIndices {
  notifications: string
  devTools: string
  overlays: string
  modals: string
  stickyHeaders: string
  dropdown: string
  forceMountedDropdown: string
}

/** The pixel values, as numbers. */
export interface Radii {
  '0': number
  '1': number
  '2': number
  '3': number
}

/** The pixel values, as numbers. */
export interface Blurs {
  '0': number
  '1': number
}

export interface Curves {
  exponential: string
  linear: string
  easeIn: string
  easeOut: string
  ease: string
}

export interface ColorAliases {
  primary: keyof Colors
  gray: keyof Colors
  blue: keyof Colors
  green: keyof Colors
  orange: keyof Colors
  red: keyof Colors
}
export interface ColorScaleAliases {
  background: number
  subtle: number
  element: number
  hoverElement: number
  active: number
  subtleBorder: number
  border: number
  hoverBorder: number
  solid: number
  hoverSolid: number
  text: number
  highContrast: number

  secondary: number
  textHover: number
  textEmphasis: number
  textActive: number
  textDisabled: number
  activeSolid: number
}
export interface SizeAliases {
  xxs: Paths<Size>
  xs: Paths<Size>
  s: Paths<Size>
  m: Paths<Size>
  l: Paths<Size>
  xl: Paths<Size>
  font: FontSizeAliases
}
export interface FontSizeAliases {
  xs: Paths<Size>
  s: Paths<Size>
  m: Paths<Size>
  l: Paths<Size>
  xl: Paths<Size>
  h1: Paths<Size>
  h2: Paths<Size>
  h3: Paths<Size>
  h4: Paths<Size>
}

export interface Aliases {
  colors: ColorAliases
  colorScales: ColorScaleAliases
  sizes: SizeAliases
}

export interface Theme {
  color: {
    light: Colors
    dark: Colors
    overlayBlack: ColorScale
    overlayWhite: ColorScale
    special: {
      gradientSecondary: string
    }
  }
  size: Size
  radii: Radii
  font: Fonts
  fontWeight: FontWeights
  lineHeights: LineHeights
  letterSpacings: LetterSpacings
  borders: Borders
  shadow: Shadows
  zIndices: ZIndices
  curves: Curves
  aliases: Aliases
  blur: Blurs
}

export type NonAliasTheme = Omit<Theme, 'aliases'>
export type ThemePath = Paths<NonAliasTheme>

export type ThemeColorVariant = keyof Omit<
  Theme['color'],
  'overlayBlack' | 'overlayWhite' | 'special'
>

export interface Variants {
  [key: string]: FlattenSimpleInterpolation
}
export const NONE_VARIANTS = ['none', 'default']
export type NoneVariant = 'none' | 'default'
export type VariantString<
  T extends Variants,
  N extends NoneVariant | undefined = undefined,
> = N extends undefined ? keyof T : keyof T | N
