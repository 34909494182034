import React from 'react'

import { AntIconWrapper } from './AntIconWrapper'
import { Button, ButtonProps } from './Button'
import { MinusCircle } from '../../icons'
import { error } from '../log'
import { media } from '../responsive'
import { styledContainer as styled } from '../styled'
import { getColor, getSize } from '../theme'
import { BodyLarge } from '../typography'

export function EmptyMessage({ children }: React.PropsWithChildren<object>) {
  return <EmptyMessageContainer children={children} />
}

interface IconProps {
  children: React.ReactElement
  noWrapper?: boolean
}
function Icon({ children, noWrapper }: IconProps) {
  const icon = noWrapper ? children : <AntIconWrapper children={children} />
  return <IconContainer children={icon} />
}

function Title({ children }: React.PropsWithChildren<object>) {
  return (
    <BodyLarge useDiv style={{ lineHeight: 1.5 }}>
      {children}
    </BodyLarge>
  )
}

function Description({ children }: React.PropsWithChildren<object>) {
  return <DescriptionContainer color={'@text-color-secondary'}>{children}</DescriptionContainer>
}

interface EmptyMessageActionProps extends ButtonProps {}
function Action(props: EmptyMessageActionProps) {
  const child = React.Children.toArray(props.children)[0]
  const [loading, setLoading] = React.useState(false)
  if (React.isValidElement(child)) {
    return (
      <ActionContainer>
        {React.cloneElement<any>(child, {
          type: 'link',
          variant: 'chromeless',
          size: 'large',
        })}
      </ActionContainer>
    )
  } else {
    return (
      <ActionContainer>
        <Button
          {...props}
          type={'link'}
          size={'large'}
          loading={loading}
          onClick={async e => {
            e.preventDefault()
            try {
              setLoading(true)
              if (props.onClick) {
                await props.onClick(e)
              }
            } catch (err) {
              error(err)
            } finally {
              setLoading(false)
            }
          }}
        />
      </ActionContainer>
    )
  }
}

EmptyMessage.Icon = Icon
EmptyMessage.Title = Title
EmptyMessage.Description = Description
EmptyMessage.Action = Action

const EmptyMessageContainer = styled`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  max-width: 90%;
  margin: ${props => getSize(props.theme, '@size-m')} auto;
  text-align: center;
  ${media.lg} {
    margin: ${props => getSize(props.theme, '@size-xl')} auto;
  }
`
const IconContainer = styled`
  font-size: ${props => getSize(props.theme, '@size-xxl')};
  color: ${props => getColor(props.theme, '@text-color-secondary')};
  margin-bottom: ${props => getSize(props.theme, '@size-m')};
`
const DescriptionContainer = styled`
  margin: ${props => getSize(props.theme, '@size-s')} 0;
  line-height: 1.5;
`
const ActionContainer = styled`
  margin-top: ${props => getSize(props.theme, '@size-m')};
`

interface EmptyAction {
  onClick: () => void
  title: string
}
export interface EmptyProps {
  description: string | React.ReactElement
  icon?: React.ReactElement
  title?: string | React.ReactElement
  action?: React.ReactElement | EmptyAction
}
export function Empty({ description, icon = <MinusCircle />, title, action }: EmptyProps) {
  return (
    <EmptyMessage>
      {icon ? <EmptyMessage.Icon>{icon}</EmptyMessage.Icon> : null}
      <EmptyMessage.Title>{title || description}</EmptyMessage.Title>
      {title ? <EmptyMessage.Description>{description}</EmptyMessage.Description> : null}
      {action ? (
        React.isValidElement(action) ? (
          <EmptyMessage.Action children={action} />
        ) : (
          <EmptyMessage.Action onClick={(action as EmptyAction).onClick}>
            {(action as EmptyAction).title}
          </EmptyMessage.Action>
        )
      ) : null}
    </EmptyMessage>
  )
}
