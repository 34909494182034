import { BodySmall } from '@thesisedu/web'
import { Popconfirm, Button, message } from 'antd'
import React from 'react'

import { useRemoveDirectMessageThreadRecipientsMutation } from '../queries/useRemoveDirectMessageThreadRecipientsMutation'

export interface RemoveFromThreadButtonProps {
  userId: string
  threadId: string
}
export function RemoveFromThreadButton({ userId, threadId }: RemoveFromThreadButtonProps) {
  const [remove, { loading }] = useRemoveDirectMessageThreadRecipientsMutation({
    onCompleted: () => {
      message.success('Removed from thread successfully!')
    },
  })

  return (
    <Popconfirm
      title={"Are you sure? You won't be able to add them back."}
      okText={'Yes, remove'}
      okButtonProps={{ danger: true }}
      cancelText={'No, keep'}
      onConfirm={() => {
        return remove({
          variables: {
            input: {
              id: threadId,
              userIds: [userId],
            },
          },
        })
      }}
    >
      <Button
        type={'link'}
        size={'small'}
        style={{ padding: 0, height: 'auto' }}
        danger
        loading={loading}
      >
        <BodySmall>Remove from Thread</BodySmall>
      </Button>
    </Popconfirm>
  )
}
