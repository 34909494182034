import { EditOutlined } from '@ant-design/icons'

import { OpenEndedAnswer } from './OpenEndedAnswer'
import { OpenEndedContent } from './OpenEndedContent'
import { OpenEndedEdit } from './OpenEndedEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'OpenEnded',
  defaults: {
    name: 'Question',
    config: {
      answer: null,
    },
  },
  icon: EditOutlined,
  label: 'Short-Form Response',
  group: 'Short Answer',
  renderAnswerView: OpenEndedAnswer,
  renderContent: OpenEndedContent,
  renderEdit: OpenEndedEdit,
} as QuestionTypeResource
