import { LexicalEditor, RangeSelection } from 'lexical'
import React from 'react'

export interface TextFormatToolbarItemProps<Active extends boolean | string[] = boolean> {
  editor: LexicalEditor
  active: Active
  disabled?: boolean
}
export interface TextFormatToolbarItem {
  element: (props: TextFormatToolbarItemProps<any>) => React.ReactElement | null
  getActive: (editor: LexicalEditor, selection: RangeSelection) => boolean | string[]
  group?: string
  identifier: string
  weight?: number
}

export interface TextFormatToolbarContextValue {
  items: TextFormatToolbarItem[]
  registerItem: (item: TextFormatToolbarItem) => void
  removeItem: (item: TextFormatToolbarItem) => void
}
export const TextFormatToolbarContext = React.createContext<
  TextFormatToolbarContextValue | undefined
>(undefined)

export function TextFormatToolbarProvider({ children }: React.PropsWithChildren<object>) {
  const [items, setItems] = React.useState<TextFormatToolbarItem[]>([])
  const contextValue = React.useMemo<TextFormatToolbarContextValue>(() => {
    return {
      items,
      registerItem(item) {
        setItems(items => {
          if (items.some(i => i.identifier === item.identifier)) {
            return items
          }
          return [...items, item]
        })
      },
      removeItem(item) {
        setItems(items => {
          return items.filter(i => i.identifier !== item.identifier)
        })
      },
    }
  }, [items])

  return <TextFormatToolbarContext.Provider value={contextValue} children={children} />
}

export function useTextFormatToolbarContext(): TextFormatToolbarContextValue | undefined
export function useTextFormatToolbarContext(
  require: false,
): TextFormatToolbarContextValue | undefined
export function useTextFormatToolbarContext(require: true): TextFormatToolbarContextValue
export function useTextFormatToolbarContext(
  require?: boolean,
): TextFormatToolbarContextValue | undefined {
  const context = React.useContext(TextFormatToolbarContext)
  if (!context && require) {
    throw new Error('TextFormatToolbarContext is required, yet not provided.')
  }
  return context
}

export function useTextFormatToolbarItem(item: TextFormatToolbarItem) {
  const context = useTextFormatToolbarContext(false)
  React.useEffect(() => {
    if (context) {
      context.registerItem(item)
      return () => context.removeItem(item)
    }
  }, [])
}
