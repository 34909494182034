import { WithTeacherClass, WithTeacherClassChildProps } from '@thesisedu/feature-classes-react'
import { useMutateHook } from '@thesisedu/feature-react'
import { PageHeader, PageHead, useBreadcrumb } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'

import { DragDropOutline } from './DragDropOutline'
import { Outline } from './Outline'
import { OutlineListImperativeHandle } from './OutlineList'
import { OutlineSegmentContextProvider } from './OutlineSegmentContext'
import { Segment } from './Segment'
import { SegmentActions } from './SegmentActions'
import { OutlineContextProvider } from './context/OutlineContext'
import { useOutlineHighlightOnMount } from './useOutlineHighlightOnMount'
import { useCourseContext } from '../contexts/CourseContext'
import { useSelectedTermId } from '../placement/useSelectedTermId'
import {
  GroupSegment,
  OutlineContextsPayload,
  CoursesHooks,
  ClassFragmentWithPermissions,
  CourseTeacherOutlineHeaderActionsHook,
} from '../types'

interface TeacherOutlineProps
  extends React.PropsWithChildren<WithTeacherClassChildProps<ClassFragmentWithPermissions>> {}
function _TeacherOutline({ children: _origChildren, cls }: TeacherOutlineProps) {
  const { bakedCourseConfiguration, courseVersion } = useCourseContext(false) || {}
  const headerActions = useMutateHook<CourseTeacherOutlineHeaderActionsHook>(
    'courses-react:teacher-outline-header-actions',
    [],
    { cls },
  )
  const [selectedTermId, setSelectedTermId] = useSelectedTermId(bakedCourseConfiguration)
  const outlineRef = React.useRef<OutlineListImperativeHandle>(null)
  useOutlineHighlightOnMount({
    listRef: outlineRef,
    selectedTermId,
    setSelectedTermId,
  })

  let children
  if (bakedCourseConfiguration && courseVersion) {
    const OutlineComponent = cls.canChangeCourseStructure ? DragDropOutline : Outline
    children = (
      <OutlineSegmentContextProvider>
        <OutlineContextProvider
          renderSegment={({ item, index }) => (
            <Segment
              segment={item.segment}
              className={'hover-container content-container'}
              data-index={index}
            />
          )}
          groupActions={({ item, setOverlayVisible, overlayVisible }) => (
            <SegmentActions
              segment={item.segment}
              onOpenChange={setOverlayVisible}
              open={overlayVisible}
            />
          )}
        >
          <OutlineComponent
            segments={bakedCourseConfiguration.segments as GroupSegment[]}
            courseName={courseVersion.course.label || courseVersion.course.name}
            courseOrClassId={cls.id}
            showAddContent
            showTermActions
            ref={outlineRef}
            selectedTermId={selectedTermId}
            setSelectedTermId={setSelectedTermId}
            topicsListProps={{
              highlightDisabled: true,
              showPopover: true,
              showEmptyGroups: true,
            }}
            collapseDepth={2}
          />
        </OutlineContextProvider>
      </OutlineSegmentContextProvider>
    )
  } else {
    children = (
      <Empty
        description={'There is no course associated with this class. Please contact support.'}
      />
    )
  }
  return (
    <>
      <PageHead title={`Course - ${cls.name}`} />
      <PageHeader title={'Course Outline'}>{headerActions}</PageHeader>
      {_origChildren}
      {useMutateHook<OutlineContextsPayload>(CoursesHooks.OutlineContexts, children, undefined)}
    </>
  )
}

export function TeacherOutline(props: React.PropsWithChildren<object>) {
  useBreadcrumb({ title: 'Outline' })
  return (
    <WithTeacherClass
      selected
      render={React.useCallback(
        (p: WithTeacherClassChildProps<ClassFragmentWithPermissions>) => (
          <_TeacherOutline {...p} {...props} />
        ),
        [],
      )}
    />
  )
}
