import { Body } from '@thesisedu/web'
import React from 'react'

import { GradeAssignmentLink } from '../../grading/GradeAssignmentLink'

export interface ReviewAssignmentUserTaskProps {
  task: {
    payload: {
      assignmentId: string
      name: string
    }
  }
  onClick?: () => void
}
export function ReviewAssignmentUserTask({ task, onClick }: ReviewAssignmentUserTaskProps) {
  return (
    <GradeAssignmentLink assignment={{ id: task.payload.assignmentId }} onClick={onClick}>
      <Body useDiv>
        Grade <strong>{task.payload.name}</strong>
      </Body>
    </GradeAssignmentLink>
  )
}
