import { FileField } from '@thesisedu/feature-attachments-react'
import { Form } from '@thesisedu/react'
import React from 'react'

import { useSubmissionData, useSubmissionProps } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'

export function FileUploadContent(props: QuestionProps) {
  const { disabled } = useSubmissionProps(props)
  return (
    <Form.Item
      name={props.question.id}
      required={'This question is required.'}
      initialValue={useSubmissionData(props.question.id)}
    >
      <QuestionInstructions instructions={'Upload a file using the field below.'} padded>
        <FileField disabled={disabled} />
      </QuestionInstructions>
    </Form.Item>
  )
}
