import { AssignmentCalculation } from '@thesisedu/feature-assignments-core'
import { Checkbox } from '@thesisedu/react'
import React from 'react'

export interface SimpleSegmentCalculationFieldProps {
  value?: AssignmentCalculation
  onChange?: (value?: AssignmentCalculation) => void
}
export function SimpleSegmentCalculationField({
  value,
  onChange,
}: SimpleSegmentCalculationFieldProps) {
  return (
    <Checkbox
      checked={value?.identifier === 'segment'}
      onChange={e => {
        if (onChange) {
          onChange(e.target.checked ? { identifier: 'segment' } : undefined)
        }
      }}
    >
      This is a Quiz Vault assignment.
    </Checkbox>
  )
}
