import { AuthRoutesHook } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { MSTeamsReactFeature } from '../MSTeamsReactFeature'
import { TenantPage } from '../tenant/TenantPage'

export default function (feature: MSTeamsReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [
        ...routes,
        <Route key={'microsoft-tenant'} path={'microsoft-tenant'} element={<TenantPage />} />,
      ]
    },
  )
}
