import { FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { addSearch } from '@thesisedu/feature-search-react'
import React from 'react'

export interface SitesWebOptions {}
export class SitesWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-sites-web'
  static path = ''
  static requires: string[] = []
  public options!: SitesWebOptions

  reactResources() {
    require('./hooks/routes').default(this)
    require('./resources/DistrictConfiguration/defaultSiteConfiguration').default(this)
    require('./resources/deepLink').default(this)
    const { SearchResult } = require('./SearchResult')
    addSearch(this, '...on Site { siteName: name, city, state }', 'Site', {
      renderList: props => <SearchResult {...props} searchResultPath={'/admin/sites/:id'} />,
    })
  }
}

export const sitesWebFeature = (opts: Partial<SitesWebOptions> = {}): FeatureUse => [
  SitesWebFeature,
  opts,
]
