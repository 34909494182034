import { Icon as LegacyIcon } from '@ant-design/compatible'
import { EyeOutlined } from '@ant-design/icons'
import { DotMenu } from '@sammarks/icons'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import {
  Body,
  DATE_FORMATS,
  StyledThemeContext,
  BodyLarge,
  BodySmall,
  styled,
} from '@thesisedu/web'
import { Dropdown, Menu } from 'antd'
import moment from 'moment'
import React from 'react'

import { UseInClassButton } from '../explore/UseInClassButton'
import { getIconForSegment } from '../helpers'
import { OutlineSearchWrapper } from '../outline/OutlineSearchContext'
import { SegmentName } from '../segment/SegmentName'
import { useSegmentPreviewContext } from '../segment/SegmentPreviewContext'
import { Segment } from '../types'

export const CUSTOM_SEGMENT_HEIGHT = 65

export interface CustomSegmentProps {
  segment: Pick<Segment, 'name' | 'type' | 'config'> & {
    updatedAt?: string
    built?: Segment
    originalId?: string | null
  }
  rawId: string
  useInClass?: boolean
  onClick?: null | (() => void)
  actionMenuItems?: React.ReactElement[]
  noMargin?: boolean
}
export function CustomSegment({
  actionMenuItems,
  onClick,
  useInClass,
  segment,
  rawId,
  noMargin,
}: CustomSegmentProps) {
  const icon = getIconForSegment(segment)
  const root = useFeatureRoot()
  const theme = React.useContext(StyledThemeContext)
  const [dropdownVisible, setDropdownVisible] = React.useState(false)
  const [actionVisible, setActionVisible] = React.useState(false)
  const { setPreviewSegmentId } = useSegmentPreviewContext(false) || {}
  const previewSegment = () => {
    if (setPreviewSegmentId) {
      setPreviewSegmentId(
        toGlobalId('Segment', rawId),
        useInClass
          ? rawId => (
              <UseInClassButton
                rawId={rawId}
                variant={'primary'}
                onClick={() => setPreviewSegmentId(undefined)}
              />
            )
          : undefined,
      )
    }
  }
  const classes = [dropdownVisible || actionVisible ? 'dropdown-visible' : '']
  if (noMargin) classes.push('no-margin')
  return (
    <Container segmentIds={[rawId]} className={classes.join(' ')}>
      {/* @ts-ignore react 18 support */}
      <LegacyIcon
        type={icon}
        theme={'outlined'}
        style={{ fontSize: theme['@size-m'], lineHeight: theme['@line-height-base'] }}
      />
      <div>
        <BodyLarge>
          <SegmentName
            segment={{ ...segment, id: rawId }}
            onClick={
              onClick === null
                ? undefined
                : onClick
                ? onClick
                : useInClass && setPreviewSegmentId
                ? previewSegment
                : undefined
            }
          />
        </BodyLarge>
        <BodySmall color={'@text-color-secondary'}>
          {segment.type} &mdash; Updated {moment(segment.updatedAt).format(DATE_FORMATS.FULL)}
          {segment?.originalId ? (
            <>
              {' '}
              &mdash; <strong> Copied from {root.appOptions.name}</strong>
            </>
          ) : null}
        </BodySmall>
      </div>
      {useInClass ? (
        <div className={'use-in-class'}>
          <UseInClassButton
            rawId={rawId}
            variant={'chromeless'}
            children={'Use in Class'}
            visible={dropdownVisible}
            onVisibleChange={setDropdownVisible}
          />
        </div>
      ) : null}
      {actionMenuItems?.length ? (
        <div className={`actions ${useInClass ? 'with-use-in-class' : ''}`}>
          <Dropdown
            visible={actionVisible}
            onVisibleChange={setActionVisible}
            placement={'bottomRight'}
            arrow
            overlay={
              <Menu>
                <Menu.Item
                  key={'preview'}
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setActionVisible(false)
                    previewSegment()
                  }}
                >
                  Preview
                </Menu.Item>
                {actionMenuItems}
              </Menu>
            }
            trigger={['click']}
          >
            <Body color={'@text-color-secondary'} style={{ cursor: 'pointer' }}>
              <DotMenu style={{ display: 'block' }} />
            </Body>
          </Dropdown>
        </div>
      ) : null}
    </Container>
  )
}

const Container = styled(OutlineSearchWrapper)`
  display: flex;
  align-items: center;
  width: 100%;
  &:not(.no-margin) {
    margin: ${props => props.theme['@size-m']} 0;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
  .use-in-class,
  .actions {
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s linear;
    &.with-use-in-class {
      margin-left: 0;
    }
  }
  &:hover .use-in-class,
  &:hover .actions,
  &.dropdown-visible .use-in-class,
  &.dropdown-visible .actions {
    opacity: 1;
    pointer-events: all;
  }
`
