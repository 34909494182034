import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { useModifiedFragment, addFragmentReference } from '@thesisedu/feature-apollo-react'
import { ReactFeature } from '@thesisedu/feature-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import { WrapAppContext } from '@thesisedu/feature-web/dist/types'
import React from 'react'

import { CompletedTasksContextProvider } from './contexts/CompletedTasksContext'
import { UserTaskResource } from './types'

export class UserTasksReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-user-tasks-react'
  static path = ''
  static requires = []

  constructor(options: object, deps: FeatureDependencies) {
    super(options, deps)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'UserTask', (fragment, context) => {
      const resources = this.resources.getResourcesForType<UserTaskResource>('UserTask')
      let result = fragment
      for (const resource of resources) {
        result = addFragmentReference(result, resource.fragment, context)
      }

      return result
    })
  }

  reactResources() {
    this.hookManager.registerMutateHook<React.ReactElement, WrapAppContext>(
      WRAP_APP_HOOK,
      async children => {
        return <CompletedTasksContextProvider children={children} />
      },
    )
  }
}

export const userTasksReactFeature = (opts: object = {}): FeatureUse => [
  UserTasksReactFeature,
  opts,
]
