import { HSpaced, styled } from '@thesisedu/web'
import { Popover } from 'antd'
import { transparentize } from 'polished'
import React from 'react'

import { DragErrorModal } from './DragErrorModal'
import { ITEM_NOTES, TOP_ITEM_NOTES } from './Piano'
// @ts-ignore
import { ReactComponent as ASharp } from '../../../../assets/sketch/branding/bell/a-sharp.svg'
// @ts-ignore
import { ReactComponent as A } from '../../../../assets/sketch/branding/bell/a.svg'
// @ts-ignore
import { ReactComponent as A0Sharp } from '../../../../assets/sketch/branding/bell/a0-sharp.svg'
// @ts-ignore
import { ReactComponent as A0 } from '../../../../assets/sketch/branding/bell/a0.svg'
// @ts-ignore
import { ReactComponent as B } from '../../../../assets/sketch/branding/bell/b.svg'
// @ts-ignore
import { ReactComponent as B0 } from '../../../../assets/sketch/branding/bell/b0.svg'
// @ts-ignore
import { ReactComponent as CSharp } from '../../../../assets/sketch/branding/bell/c-sharp.svg'
// @ts-ignore
import { ReactComponent as C } from '../../../../assets/sketch/branding/bell/c.svg'
// @ts-ignore
import { ReactComponent as C2Sharp } from '../../../../assets/sketch/branding/bell/c2-sharp.svg'
// @ts-ignore
import { ReactComponent as C2 } from '../../../../assets/sketch/branding/bell/c2.svg'
// @ts-ignore
import { ReactComponent as DSharp } from '../../../../assets/sketch/branding/bell/d-sharp.svg'
// @ts-ignore
import { ReactComponent as D } from '../../../../assets/sketch/branding/bell/d.svg'
// @ts-ignore
import { ReactComponent as D2Sharp } from '../../../../assets/sketch/branding/bell/d2-sharp.svg'
// @ts-ignore
import { ReactComponent as D2 } from '../../../../assets/sketch/branding/bell/d2.svg'
// @ts-ignore
import { ReactComponent as E } from '../../../../assets/sketch/branding/bell/e.svg'
// @ts-ignore
import { ReactComponent as E2 } from '../../../../assets/sketch/branding/bell/e2.svg'
// @ts-ignore
import { ReactComponent as FSharp } from '../../../../assets/sketch/branding/bell/f-sharp.svg'
// @ts-ignore
import { ReactComponent as F } from '../../../../assets/sketch/branding/bell/f.svg'
// @ts-ignore
import { ReactComponent as F2Sharp } from '../../../../assets/sketch/branding/bell/f2-sharp.svg'
// @ts-ignore
import { ReactComponent as F2 } from '../../../../assets/sketch/branding/bell/f2.svg'
// @ts-ignore
import { ReactComponent as GSharp } from '../../../../assets/sketch/branding/bell/g-sharp.svg'
// @ts-ignore
import { ReactComponent as G } from '../../../../assets/sketch/branding/bell/g.svg'
// @ts-ignore
import { ReactComponent as G0Sharp } from '../../../../assets/sketch/branding/bell/g0-sharp.svg'
// @ts-ignore
import { ReactComponent as G0 } from '../../../../assets/sketch/branding/bell/g0.svg'
// @ts-ignore
import { ReactComponent as G2 } from '../../../../assets/sketch/branding/bell/g2.svg'
// @ts-ignore
import { ReactComponent as AccidentalFlat } from '../../../../assets/sketch/sheet-music/accidental-flat.svg'
// @ts-ignore
import { ReactComponent as AccidentalSharp } from '../../../../assets/sketch/sheet-music/accidental-sharp.svg'
import { useKeyboardContext } from '../../KeyboardContext'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

const PITCH_INDEX_OFFSET = -3
const BELLS: React.FC<React.PropsWithChildren<any>>[] = [
  G0,
  A0,
  B0,
  C,
  D,
  E,
  F,
  G,
  A,
  B,
  C2,
  D2,
  E2,
  F2,
  G2,
]
const TOP_BELLS: (React.FC<React.PropsWithChildren<any>> | null)[] = [
  G0Sharp,
  A0Sharp,
  null,
  CSharp,
  DSharp,
  null,
  FSharp,
  GSharp,
  ASharp,
  null,
  C2Sharp,
  D2Sharp,
  null,
  F2Sharp,
]
const TOP_COLORS: string[] = ['@blue-2', '@plum', '', '@poppy', '@gold', '', '@mint']
const BOTTOM_COLORS: string[] = [
  '@red',
  '@orange',
  '@yellow',
  '@green',
  '@blue',
  '@purple',
  '@pink',
]

export function AdvancedBellsContent({ wrapNote: WrapNote }: ModeContentsProps) {
  const { playKey } = useKeyboardContext(true)
  const [showAccidentalDragError, setShowAccidentalDragError] = React.useState(false)
  return (
    <Container>
      <DragErrorModal
        visible={showAccidentalDragError}
        onClose={() => setShowAccidentalDragError(false)}
      />
      <TopContainer>
        {TOP_BELLS.map((TopBell, index) => {
          if (TopBell) {
            const sharpContents = (
              <AccidentalContainer
                color={TOP_COLORS[(index + 21) % 7]}
                children={<AccidentalSharp />}
              />
            )
            const flatContents = (
              <AccidentalContainer
                color={TOP_COLORS[(index + 21) % 7]}
                children={<AccidentalFlat />}
              />
            )
            return (
              <BellContainer
                key={index}
                color={TOP_COLORS[(index + 21) % 7]}
                onMouseDown={
                  TOP_ITEM_NOTES[index] ? () => playKey(TOP_ITEM_NOTES[index]!) : undefined
                }
              >
                {WrapNote ? (
                  <Popover
                    trigger={['hover']}
                    content={
                      <HSpaced space={'@size-xs'}>
                        <BellContainer color={TOP_COLORS[(index + 21) % 7]}>
                          {WrapNote ? (
                            <WrapNote
                              pitch={index + PITCH_INDEX_OFFSET}
                              accidental={'sharp'}
                              children={sharpContents}
                            />
                          ) : (
                            sharpContents
                          )}
                        </BellContainer>
                        <BellContainer color={TOP_COLORS[(index + 21) % 7]}>
                          {WrapNote ? (
                            <WrapNote
                              pitch={index + PITCH_INDEX_OFFSET + 1}
                              accidental={'flat'}
                              children={flatContents}
                            />
                          ) : (
                            flatContents
                          )}
                        </BellContainer>
                      </HSpaced>
                    }
                  >
                    <div draggable onDragStart={() => setShowAccidentalDragError(true)}>
                      <TopBell />
                    </div>
                  </Popover>
                ) : (
                  <TopBell />
                )}
              </BellContainer>
            )
          } else {
            return <span className={'spacer'} />
          }
        })}
      </TopContainer>
      <BottomContainer>
        {BELLS.map((BottomBell, index) => (
          <BellContainer
            key={index}
            color={BOTTOM_COLORS[(index - 3 + 21) % 7]}
            onMouseDown={() => playKey(ITEM_NOTES[index])}
          >
            {WrapNote ? (
              <WrapNote pitch={index + PITCH_INDEX_OFFSET} children={<BottomBell />} />
            ) : (
              <BottomBell />
            )}
          </BellContainer>
        ))}
      </BottomContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: ${props => props.theme['@size-s']} 0;
`
const BellsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.5;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xxs']};
  }
  > * {
    display: block;
    width: ${props => props.theme['@size-xxl']};
  }
`
const TopContainer = styled(BellsContainer)`
  margin: 0 calc(${props => props.theme['@size-xxl']} / 2);
`
const BottomContainer = styled(BellsContainer)``
const BellContainer = styled.div<{ color: string }>`
  cursor: pointer;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s linear;
  opacity: 0.85;
  &:hover {
    transform: scale(1.05);
    opacity: 1;
    svg,
    img {
      filter: drop-shadow(0 2px 10px ${props => transparentize(0.5, props.theme[props.color])});
    }
  }
  svg,
  img {
    width: 100%;
    height: auto;
    display: block;
    transition: filter 0.25s ease-in-out;
  }
`
const AccidentalContainer = styled.div<{ color: string }>`
  padding: ${props => props.theme['@padding-sm']};
  svg {
    width: ${props => props.theme['@size-m']};
    path {
      fill: ${props => props.theme[props.color]};
    }
  }
`

export const AdvancedBells: Mode = {
  id: 'AdvancedBells',
  name: 'Bells',
  icon: require('../../../../assets/sketch/branding/bell/c.svg').default,
  large: false,
  types: [KeyboardType.Note25],
  contents: AdvancedBellsContent,
}
