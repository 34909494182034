import * as React from 'react'

function SvgNoteC(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#ED3131'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={2.45}
          d={
            'M156.09 115.713c-6.426-6.792-12.078-11.156-16.904-13.067a19.473 19.473 0 00-12.516-1.343 16.62 16.62 0 00-8.852 6.275 48.734 48.734 0 00-5.832 9.658 73.733 73.733 0 00-3.923 12.912c0 .232.258 1.782.8 4.648a32.704 32.704 0 003.742 9.58 18.07 18.07 0 008.413 7.747c3.63 1.722 8.585 1.48 14.865-.723 6.28-2.203 14.022-7.506 23.226-15.907L194 172.704a35.087 35.087 0 01-9.316 10.562 81.31 81.31 0 01-15.742 9.838 109.584 109.584 0 01-20.027 7.437 101.001 101.001 0 01-22.271 3.41 76.73 76.73 0 01-22.4-2.506 56.219 56.219 0 01-20.31-10.02 69.322 69.322 0 01-19.846-22.904 70.09 70.09 0 01-7.742-25.823 78.085 78.085 0 012.039-26.495A115.888 115.888 0 0167.7 91.31a104.097 104.097 0 0114.116-20.943A83.864 83.864 0 0198.49 55.545a70.532 70.532 0 0113.497-5.785 76.835 76.835 0 0114.761-2.582 55.76 55.76 0 0118.426 1.601 73.81 73.81 0 0120.646 9.038 89.543 89.543 0 0121.574 19.42l-31.303 38.476z'
          }
        />
        <path
          fill={'#EC2224'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteC = React.memo(SvgNoteC)
export default MemoSvgNoteC
