import { ReactFeature } from '@thesisedu/feature-react'
import Case from 'case'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { addSearch } from '../addSearch'
import { SearchResultListItem } from '../results/SearchResultList'
import { SearchResultNode } from '../types'

interface UserSearchResult extends SearchResultNode {
  username: string
  name?: string
  group: string
}

interface SearchResultProps {
  item: UserSearchResult
}
function SearchResult({ item }: SearchResultProps) {
  const navigate = useNavigate()
  return (
    <SearchResultListItem
      title={item.name || item.username}
      subtitle={
        <span>
          {Case.title(item.group)} &mdash; {item.username}
        </span>
      }
      type={'User'}
      onClick={() => navigate(`/admin/users/${item.id}`)}
    />
  )
}

export default function addSearchResources(feature: ReactFeature) {
  addSearch<UserSearchResult>(feature, '...on User { username, name, group }', 'User', {
    renderList: SearchResult,
    getUniversalLink: item => `/users/${item.id}`,
  })
}
