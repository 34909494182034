import { LoadingIndicator, Result } from '@thesisedu/react'
import { InfiniteScroller } from '@thesisedu/web'
import React from 'react'

import { RequiredVariables } from './loadMore'
import { InfiniteQueryProps, useInfiniteQuery } from './useInfiniteQuery'

export function InfiniteQuery<Item, Data, Variables extends RequiredVariables>(
  props: InfiniteQueryProps<Item, Data, Variables>,
) {
  const { noLoading, infiniteScrollerProps, children } = props
  const { error, loadingMore, queryResult, loadMore, result, loadingFresh, refetch } =
    useInfiniteQuery<Item, Data, Variables>(props)
  if (error) {
    return <Result.Error description={'It looks like something went wrong getting your results.'} />
  } else if (loadingFresh && !noLoading) {
    return <LoadingIndicator block />
  }

  return (
    <InfiniteScroller
      loadMore={loadMore}
      hasMore={!!result?.pageInfo.hasNextPage}
      {...infiniteScrollerProps}
      children={children({
        ...queryResult,
        result,
        loading: queryResult.loading || loadingMore,
        loadingMore,
        loadingFresh,
        loadMore,
        refetch,
      })}
    />
  )
}
