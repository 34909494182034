import { OnboardingBanner } from '@thesisedu/feature-class-sync-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Block } from '@thesisedu/ui'
import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { TeacherOutline } from '../../../outline/TeacherOutline'

export function CourseRoute() {
  useBreadcrumb({ title: 'Course' })
  const classId = useSelectedClassId(true)
  return (
    <Routes>
      <Route
        path={'/outline/*'}
        element={
          <>
            <Block bottom={'l'}>
              <OnboardingBanner classId={classId} />
            </Block>
            <TeacherOutline />
          </>
        }
      />
      <Route path={'/'} element={<Navigate to={'outline'} replace />} />
    </Routes>
  )
}
