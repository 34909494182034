import React from 'react'

import { ClassesReactFeature } from '../ClassesReactFeature'
import { MessagesMenuItem } from '../integration/MessagesMenuItem'
import { ClassDraggableOverlayItemsHook } from '../types'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ClassDraggableOverlayItemsHook>(
    'classes-react:class-draggable-overlay-items',
    (items, props) => {
      return [
        ...items,
        <MessagesMenuItem
          classId={props.class.id}
          path={`/${props.group.toLowerCase()}/classes/${props.class.id}/messages`}
          key={'messages'}
          disabled={props.group === 'TEACHER' && !(props.class as any).canMessageStudents}
        />,
      ]
    },
  )
}
