import { ApolloClient, ApolloError, DocumentNode, useApolloClient } from '@apollo/client'
import React from 'react'

import { removeMutationSelections } from './removeMutationSelections'

export type BulkActionEntry<Variables extends object, Data extends object> = Variables & {
  onComplete?: (data: Data) => void
}
export interface UseBulkActionsOpts<Variables extends object, Data extends object = object> {
  mutation: DocumentNode
  variables: BulkActionEntry<Variables, Data>[]
  onProgress?: (current: number, total: number) => void | Promise<void>
  onError?: (err: ApolloError) => void
  refetchQueries?: string[] | ((client: ApolloClient<any>) => Promise<void>)
}
export type UseBulkActions = <Variables extends object, Data extends object = object>(
  opts: UseBulkActionsOpts<Variables, Data>,
) => Promise<Data[]>
export function useBulkActions(): UseBulkActions {
  const client = useApolloClient()
  return React.useCallback(
    async ({ mutation: _mutation, variables, onProgress, onError, refetchQueries }) => {
      const mutation = removeMutationSelections(_mutation)
      const total = variables.length
      const results: any[] = []
      try {
        for (let i = 0; i < variables.length; i++) {
          const { onComplete, ...variable } = variables[i]
          const result = await client.mutate<any, object>({
            mutation,
            variables: variable,
          })
          if (result.data) {
            onComplete?.(result.data)
            results.push(result.data)
          }
          onProgress?.(i + 1, total)
        }
      } catch (err: unknown) {
        if (err instanceof ApolloError) {
          onError?.(err)
        }
        throw err
      } finally {
        if (refetchQueries && Array.isArray(refetchQueries)) {
          await client.refetchQueries({
            include: refetchQueries,
          })
        } else if (refetchQueries) {
          await refetchQueries(client)
        }
      }

      return results
    },
    [client],
  )
}
