import { H2, H2Subtitle } from '@thesisedu/react'
import React from 'react'

import { ManageTeamTeachingModalContent } from '../../team/ManageTeamTeachingModal'

export interface TeamTeachingProps {
  classId: string
}
export function TeamTeaching({ classId }: TeamTeachingProps) {
  return (
    <>
      <H2 isBlock={false}>Team Teaching</H2>
      <H2Subtitle isBlock>
        Invite other teachers to work with you in your class, and control what they have access to.
      </H2Subtitle>
      <ManageTeamTeachingModalContent classId={classId} />
    </>
  )
}
