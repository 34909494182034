import { useExpectedErrorListener } from '@thesisedu/feature-apollo-react'
import { Segment } from '@thesisedu/feature-course-types'
import { Modal, Text } from '@thesisedu/ui'
import React from 'react'

export function SegmentChildAlreadyExistsHandler() {
  const { error, onClose } = useExpectedErrorListener({
    error: 'SEGMENT_CHILD_ALREADY_EXISTS_ERROR',
  })

  const builtSegments = error?.extensions?.segments || []
  if (error && builtSegments.length) {
    return (
      <Modal
        visible
        onVisibleChange={onClose}
        title={'That content already exists in this class'}
        width={600}
      >
        <Text bottom>
          Some parts of the content you are trying to use in this class are already in this class:
        </Text>
        <ul>
          {builtSegments.map((s: Segment) => (
            <li key={s.id}>
              {s.config?.label ? (
                <>
                  <strong>{s.config.label}:</strong> <span>{s.name}</span>
                </>
              ) : (
                <strong>{s.name}</strong>
              )}
            </li>
          ))}
        </ul>
        <Modal.Footer>
          <Modal.CloseButton />
        </Modal.Footer>
      </Modal>
    )
  } else return null
}
