import { $isAutoLinkNode, $isLinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import React from 'react'
import ReactDOM from 'react-dom'

import { useLinkEditorToolbarButton } from './TextFormatToolbar'
import { FloatingLinkEditor } from '../../ui/FloatingLinkEditor/FloatingLinkEditor'
import { getSelectedNode } from '../../utils/getSelectedNode'

export interface LinkEditorPluginProps {
  anchorElement?: HTMLElement
}
export function LinkEditorPlugin({ anchorElement = document.body }: LinkEditorPluginProps) {
  useLinkEditorToolbarButton()
  const [editor] = useLexicalComposerContext()
  const [isLink, setIsLink] = React.useState(false)

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const linkParent = $findMatchingParent(node, $isLinkNode)
      const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode)

      // We don't want the menu to open for automatic links.
      if (linkParent !== null && autoLinkParent === null) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }
    }
  }, [])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar()
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [editor, updateToolbar])

  return isLink
    ? ReactDOM.createPortal(
        <FloatingLinkEditor
          editor={editor}
          anchorElement={anchorElement}
          isLink={isLink}
          setIsLink={setIsLink}
        />,
        anchorElement,
      )
    : null
}
