import { AnyEditorValue, BasicSerializedWidget } from './types'
import { flatten as flattenBlocks } from '../flatten'

function flattenWidgets(widgets: any[]): any[] {
  let result: any[] = []
  for (const widget of widgets) {
    result.push(widget)
    if (widget.childGroups) {
      for (const childGroupKey of Object.keys(widget.childGroups)) {
        result = result.concat(flattenWidgets(widget.childGroups[childGroupKey]))
      }
    }
  }

  return result
}

export function flatten(value: AnyEditorValue | undefined | null): BasicSerializedWidget[] {
  if (Array.isArray(value)) {
    return flattenWidgets(value)
  } else if (value) {
    return flattenBlocks(value)
  } else {
    return []
  }
}
