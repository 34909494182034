/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Time: any;
};

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String'];
};

export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload';
  /** The current user. */
  user: User;
};

/** An announcement posted by a teacher for students in a class. */
export type Announcement = Node & {
  __typename?: 'Announcement';
  /** Whether or not the current user can delete this announcement. */
  canDelete: Scalars['Boolean'];
  /** Whether or not the current user can update this announcement. */
  canUpdate: Scalars['Boolean'];
  /** The class the announcement belongs to. */
  class: Class;
  /** The configuration for the announcement, containing the description. */
  configuration?: Maybe<AnnouncementConfiguration>;
  /** The date the announcement was first created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether or not the announcement has been read by the current user. */
  isRead: Scalars['Boolean'];
  /** The name of the announcement. */
  name: Scalars['String'];
  /** The number of students that have viewed this announcement. */
  studentViews?: Maybe<Scalars['Int']>;
  /** The date the announcement was last updated. */
  updatedAt: Scalars['DateTime'];
};

export type AnnouncementConfiguration = {
  __typename?: 'AnnouncementConfiguration';
  /** The description (or body content) of the announcement. */
  description?: Maybe<Array<Scalars['JSON']>>;
};

export type AnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AnnouncementPayload = {
  __typename?: 'AnnouncementPayload';
  announcement: Announcement;
};

export type AnnouncementsConnection = Connection & {
  __typename?: 'AnnouncementsConnection';
  edges: Array<AnnouncementsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AnnouncementsEdge = Edge & {
  __typename?: 'AnnouncementsEdge';
  cursor: Scalars['String'];
  node: Announcement;
};

/** Represents an answered question. */
export type AnsweredQuestion = Node & {
  __typename?: 'AnsweredQuestion';
  /** Whether or not the current user can comment on this entity. */
  canComment: Scalars['Boolean'];
  /** Gets all comments associated with this entity. */
  comments: CommentsConnection;
  /** The content displayed to the users underneath the video. */
  content?: Maybe<AnsweredQuestionContent>;
  /** The contributors that helped answer the question */
  contributors: AnsweredQuestionContributorsConnection;
  /** The date the question was first created */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether or not the current question is the featured question */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Whether or not the current user likes this content. */
  isLiked: Scalars['Boolean'];
  /** The number of users who have liked this content. */
  likes: Scalars['Int'];
  /** The name of the question (the question itself) */
  name: Scalars['String'];
  /** The date the question was last published */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** The reason the question was rejected, if it was. */
  rejectedReason?: Maybe<Scalars['String']>;
  /** The loaded segment */
  segment?: Maybe<Segment>;
  /** The ID of the segment associated with this question, available for teachers to add to their classes */
  segmentId?: Maybe<Scalars['String']>;
  /** The current status of the question */
  status: AnsweredQuestionStatus;
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON'];
  /** Gets all tags associated with this entity. */
  tags: TagsConnection;
  /** The thumbnail assigned to the question's video answer */
  thumbnail: Attachment;
  /** The date the question was last updated */
  updatedAt: Scalars['DateTime'];
  /** The user that asked the question */
  user: User;
  /** The ID of the user that asked the question */
  userId: Scalars['ID'];
  /** The number of times this question has been viewed. */
  views: Scalars['Int'];
  /** The loaded video */
  vod?: Maybe<VideoOnDemand>;
  /** The video-on-demand ID of the answer for the question, if available */
  vodId?: Maybe<Scalars['ID']>;
};


/** Represents an answered question. */
export type AnsweredQuestionCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** Represents an answered question. */
export type AnsweredQuestionContributorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** Represents an answered question. */
export type AnsweredQuestionTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AnsweredQuestionContent = {
  __typename?: 'AnsweredQuestionContent';
  widgets?: Maybe<Array<Scalars['JSON']>>;
};

export type AnsweredQuestionContentInput = {
  widgets?: InputMaybe<Array<Scalars['JSON']>>;
};

/** Represents a contributor for answered questions */
export type AnsweredQuestionContributor = Node & {
  __typename?: 'AnsweredQuestionContributor';
  /** The detailed bio for the contributor, supports Markdown */
  bio?: Maybe<Scalars['String']>;
  /** The company of the contributor */
  company?: Maybe<Scalars['String']>;
  /** The date the contributor was first created */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Any questions the contributor has participated in */
  questions: AnsweredQuestionsConnection;
  /** The title of the contributor */
  title?: Maybe<Scalars['String']>;
  /** The date the contributor was last updated */
  updatedAt: Scalars['DateTime'];
  /** The loaded user itself */
  user: User;
  /** The ID of the user associated with the contributor account */
  userId: Scalars['ID'];
};


/** Represents a contributor for answered questions */
export type AnsweredQuestionContributorQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type AnsweredQuestionContributorPayload = {
  __typename?: 'AnsweredQuestionContributorPayload';
  answeredQuestionContributor: AnsweredQuestionContributor;
};

export type AnsweredQuestionContributorsConnection = Connection & {
  __typename?: 'AnsweredQuestionContributorsConnection';
  edges: Array<AnsweredQuestionContributorsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AnsweredQuestionContributorsEdge = Edge & {
  __typename?: 'AnsweredQuestionContributorsEdge';
  cursor: Scalars['String'];
  node: AnsweredQuestionContributor;
};

export enum AnsweredQuestionCreator {
  Me = 'ME'
}

export type AnsweredQuestionInput = {
  content?: InputMaybe<AnsweredQuestionContentInput>;
  contributorIds?: InputMaybe<Array<Scalars['ID']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  postName?: InputMaybe<Scalars['String']>;
  rejectedReason?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AnsweredQuestionStatus>;
  thumbnail?: InputMaybe<Scalars['JSON']>;
  updateTags?: InputMaybe<Scalars['JSON']>;
  vodId?: InputMaybe<Scalars['ID']>;
};

export type AnsweredQuestionPayload = {
  __typename?: 'AnsweredQuestionPayload';
  answeredQuestion: AnsweredQuestion;
};

/** The possible statuses for an answered question */
export enum AnsweredQuestionStatus {
  /** The question has been accepted, but we haven't started responding yet */
  Accepted = 'ACCEPTED',
  /** The question has just been submitted */
  New = 'NEW',
  /** We have completed our response and published it */
  Published = 'PUBLISHED',
  /** The question has been denied, for whatever reason */
  Rejected = 'REJECTED',
  /** We are preparing our response */
  Responding = 'RESPONDING'
}

export type AnsweredQuestionsConnection = Connection & {
  __typename?: 'AnsweredQuestionsConnection';
  edges: Array<AnsweredQuestionsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AnsweredQuestionsEdge = Edge & {
  __typename?: 'AnsweredQuestionsEdge';
  cursor: Scalars['String'];
  node: AnsweredQuestion;
};

/** Filters available when querying for answered questions. */
export type AnsweredQuestionsFilters = {
  /** Filter based on questions created by specific contributors. */
  contributorIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter based on who created the question. */
  creator?: InputMaybe<AnsweredQuestionCreator>;
  /** Filter based on the status of the question. */
  status?: InputMaybe<Array<AnsweredQuestionStatus>>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
};

export type ApproveCommentInput = {
  id: Scalars['ID'];
};

export type ApproveTrialRequestInput = {
  approval: TrialRequestApproval;
  id: Scalars['ID'];
  noEmail?: Scalars['Boolean'];
};

export type ApproveTrialRequestPayload = {
  __typename?: 'ApproveTrialRequestPayload';
  license: License;
  site: Site;
  teacher: Teacher;
};

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID'];
};

/**
 * Specify either assignedStudents or assignedClasses, depending on the assignment mode for the
 * assignment. You may not use assignedStudents if the mode is ALL_STUDENTS.
 */
export type AssignInput = {
  assignedClasses?: InputMaybe<Array<Scalars['ID']>>;
  assignedStudents?: InputMaybe<Array<ClassStudentInput>>;
};

/** An assignment recorded in the gradebook for a class. */
export type Assignment = Node & {
  __typename?: 'Assignment';
  /** All assignment categories associated with this assignment. */
  assignmentCategories: AssignmentCategoriesConnection;
  /**
   * The associated assignment category.
   * @deprecated Use 'assignmentCategories' instead.
   */
  assignmentCategory?: Maybe<AssignmentCategory>;
  /**
   * The ID of the associated assignment category.
   * @deprecated Use 'assignmentCategories' instead.
   */
  assignmentCategoryId?: Maybe<Scalars['ID']>;
  /** The student assignment mode. */
  assignmentMode: AssignmentStudentAssignmentMode;
  /** The assignment template itself. */
  assignmentTemplate?: Maybe<AssignmentTemplate>;
  /** The ID of the template this assignment comes from. */
  assignmentTemplateId?: Maybe<Scalars['ID']>;
  /** Whether or not the current user can delete this assignment. */
  canDelete: Scalars['Boolean'];
  /** Whether or not the current user can grade this assignment. */
  canGrade: Scalars['Boolean'];
  /** Whether or not the current user can reset submissions for this assignment. */
  canReset: Scalars['Boolean'];
  /** Whether or not the current user can submit this assignment. */
  canSubmit: Scalars['Boolean'];
  /** Whether or not the current user can sync this assignment. */
  canSync: Scalars['Boolean'];
  /** Whether or not the current user can update this assignment. */
  canUpdate: Scalars['Boolean'];
  /** The classes associated with the assignment through assigned students. */
  classes: AssignmentClassesConnection;
  /** Either the raw or generated assignment configuration. */
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  /** An optional description for the assignment. */
  description?: Maybe<Scalars['String']>;
  /** The date the assignment closes and all submissions are due. */
  dueAt?: Maybe<Scalars['DateTime']>;
  /** Whether or not the assignment is excluded from the gradebook. */
  excluded?: Maybe<Scalars['Boolean']>;
  /** Information about the assignment's generation status. */
  generated?: Maybe<Scalars['JSON']>;
  /** Gets the generated submission metadata given a class ID / student ID pair. */
  generatedSubmission?: Maybe<AssignmentSubmission>;
  /** Gets the average grade for each rubric category. */
  gradesByCategory: GradesByCategory;
  /** Gets the average grade for each question. */
  gradesByQuestion: GradesByQuestion;
  /** True if the teacher is not allowed to change the grading mode / rubric. */
  gradingConfigurationLocked: Scalars['Boolean'];
  /** The grading mode for this assignment. */
  gradingMode: AssignmentGradingMode;
  /** The groups configuration, if this is a groups assignment. */
  groups?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** The maximum number of attempts allowed for this assignment. */
  maxAttempts: Scalars['Int'];
  /** The name of the assignment. */
  name: Scalars['String'];
  /** The date the assignment first opens for student submissions. */
  openAt?: Maybe<Scalars['DateTime']>;
  /** The number of remaining attempts for the current student. */
  remainingAttempts?: Maybe<Scalars['Int']>;
  /** Whether or not to reveal answers to students, and when to do it. */
  revealAnswers?: Maybe<RevealAnswers>;
  /**
   * The number of attempts allowed before answers are revealed, only if revealAnswers is set
   * to AFTER_N_ATTEMPTS
   */
  revealAttempts?: Maybe<Scalars['Int']>;
  /** An optional grading rubric for this assignment. */
  rubric?: Maybe<AssignmentRubric>;
  /** The created date of the earliest valid submission. */
  startedAt?: Maybe<Scalars['DateTime']>;
  /** All students assigned to this assignment. */
  students: AssignmentStudentsConnection;
  /** Gets a summary of the submissions for this assignment. */
  submissionSummary?: Maybe<AssignmentSubmissionSummary>;
  /** The type of assignment. Be it individual, group, etc. */
  submissionType: SubmissionType;
  /** All submissions for this assignment. */
  submissions: AssignmentSubmissionsConnection;
  /** The submitted date of the latest submitted submission. */
  submittedAt?: Maybe<Scalars['DateTime']>;
  /** Gets the currently-enabled syncs for the assignment. */
  syncs: AssignmentSyncsConnection;
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON'];
  /** Gets all tags associated with this entity. */
  tags: TagsConnection;
  /** The teacher that owns the assignment. */
  teacher: Teacher;
  /** The ID of the teacher that owns the assignment. */
  teacherId: Scalars['ID'];
  /** The time limit for this assignment, in seconds. */
  timeLimit?: Maybe<Scalars['Float']>;
  /** The total number of points to earn on an assignment. */
  totalPoints: Scalars['Float'];
  /** The type of assignment. Enables special functionality. */
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentAssignmentCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentConfigurationArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  raw?: InputMaybe<Scalars['Boolean']>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentGeneratedSubmissionArgs = {
  classId: Scalars['ID'];
  studentId: Scalars['ID'];
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentRemainingAttemptsArgs = {
  classId: Scalars['ID'];
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentStartedAtArgs = {
  classId: Scalars['ID'];
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
  submitted?: InputMaybe<Scalars['Boolean']>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentSubmittedAtArgs = {
  classId: Scalars['ID'];
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentSyncsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** An assignment recorded in the gradebook for a class. */
export type AssignmentTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type AssignmentCategoriesConnection = Connection & {
  __typename?: 'AssignmentCategoriesConnection';
  edges: Array<AssignmentCategoriesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentCategoriesEdge = Edge & {
  __typename?: 'AssignmentCategoriesEdge';
  cursor: Scalars['String'];
  node: AssignmentCategory;
};

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategory = Node & {
  __typename?: 'AssignmentCategory';
  /** The assignments associated with the category. */
  assignments: AssignmentsConnection;
  /** The class the category belongs to. */
  class: Class;
  /** The ID of the class the category belongs to. */
  classId: Scalars['ID'];
  /** The date the category was first created. */
  createdAt: Scalars['DateTime'];
  /** The description of the category. */
  description?: Maybe<Scalars['String']>;
  /** Gets the calculated grade for this category, if there is one. */
  grade?: Maybe<Scalars['Float']>;
  /** The weight of the category, with respect to grade calculations. */
  gradeWeight: Scalars['Float'];
  id: Scalars['ID'];
  /** The name of the category. */
  name: Scalars['String'];
  /** The weight of the category, with respect to the order it's displayed to the teacher. */
  orderWeight: Scalars['Float'];
  /** Gets the total earned points for this category, if there is one. */
  points?: Maybe<Scalars['Float']>;
  /** Gets the total available points for this category, if there is one. */
  totalPoints?: Maybe<Scalars['Float']>;
  /** The date the category was last updated. */
  updatedAt: Scalars['DateTime'];
};


/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
};


/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryGradeArgs = {
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
};


/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryPointsArgs = {
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
};


/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryTotalPointsArgs = {
  studentIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type AssignmentCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  gradeWeight?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  orderWeight?: InputMaybe<Scalars['Float']>;
};

export type AssignmentCategoryPayload = {
  __typename?: 'AssignmentCategoryPayload';
  assignmentCategory: AssignmentCategory;
};

export type AssignmentClassConfiguration = {
  __typename?: 'AssignmentClassConfiguration';
  assignmentCategories?: Maybe<Array<AssignmentCategory>>;
  class: Class;
  classId: Scalars['ID'];
  gradingMode: ClassGradingMode;
  id: Scalars['ID'];
};

export type AssignmentClassConfigurationInput = {
  gradingMode?: InputMaybe<ClassGradingMode>;
};

export type AssignmentClassConfigurationPayload = {
  __typename?: 'AssignmentClassConfigurationPayload';
  assignmentClassConfiguration: AssignmentClassConfiguration;
};

export type AssignmentClassesConnection = Connection & {
  __typename?: 'AssignmentClassesConnection';
  edges: Array<AssignmentClassesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentClassesEdge = Edge & {
  __typename?: 'AssignmentClassesEdge';
  cursor: Scalars['String'];
  node: Class;
};

export enum AssignmentExcludedFilter {
  Both = 'BOTH',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export type AssignmentFiltersInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  dueDate?: InputMaybe<DueDateFilterInput>;
  /** What exclusion status of assignments should be included? */
  excluded?: InputMaybe<AssignmentExcludedFilter>;
  /**
   * If this is true, only generated assignments are included. This is the same as passing
   * all available generated providers to the generatedProviders option.
   */
  generated?: InputMaybe<Scalars['Boolean']>;
  /** The identifiers for generated-assignment providers to filter on. */
  generatedProviders?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<TagsFilter>;
  ungraded?: InputMaybe<Scalars['Boolean']>;
};

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC'
}

export type AssignmentInput = {
  assign?: InputMaybe<AssignInput>;
  assignedStudentIds?: InputMaybe<Array<Scalars['ID']>>;
  assignmentCategoryIds?: InputMaybe<Array<Scalars['ID']>>;
  assignmentMode?: InputMaybe<AssignmentStudentAssignmentMode>;
  assignmentTemplateId?: InputMaybe<Scalars['ID']>;
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  dueAt?: InputMaybe<Scalars['DateTime']>;
  excluded?: InputMaybe<Scalars['Boolean']>;
  gradingMode?: InputMaybe<AssignmentGradingMode>;
  groups?: InputMaybe<Scalars['JSON']>;
  maxAttempts?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  openAt?: InputMaybe<Scalars['DateTime']>;
  revealAnswers?: InputMaybe<RevealAnswers>;
  revealAttempts?: InputMaybe<Scalars['Int']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
  submissionType?: InputMaybe<SubmissionType>;
  timeLimit?: InputMaybe<Scalars['Float']>;
  totalPoints?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type AssignmentPayload = {
  __typename?: 'AssignmentPayload';
  assignment: Assignment;
};

export type AssignmentRubric = {
  __typename?: 'AssignmentRubric';
  categories: Array<AssignmentRubricCategory>;
};

export type AssignmentRubricCategory = {
  __typename?: 'AssignmentRubricCategory';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  presets?: Maybe<Array<AssignmentRubricPreset>>;
  totalPoints: Scalars['Float'];
  weight?: Maybe<Scalars['Float']>;
};

export type AssignmentRubricCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  presets?: InputMaybe<Array<AssignmentRubricPresetInput>>;
  totalPoints: Scalars['Float'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type AssignmentRubricInput = {
  categories: Array<AssignmentRubricCategoryInput>;
};

export type AssignmentRubricPreset = {
  __typename?: 'AssignmentRubricPreset';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  points: Scalars['Float'];
  weight: Scalars['Float'];
};

export type AssignmentRubricPresetInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  points: Scalars['Float'];
  weight: Scalars['Float'];
};

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS'
}

export type AssignmentStudentsConnection = Connection & {
  __typename?: 'AssignmentStudentsConnection';
  edges: Array<AssignmentStudentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  totalGraded: Scalars['Int'];
  totalSubmitted: Scalars['Int'];
};

export type AssignmentStudentsEdge = Edge & {
  __typename?: 'AssignmentStudentsEdge';
  class: Class;
  classId: Scalars['ID'];
  cursor: Scalars['String'];
  /** If this is true, the student has forfeited future attempts for this assignment. */
  forfeitAttempts?: Maybe<Scalars['Boolean']>;
  isMissing: Scalars['Boolean'];
  node: Student;
};

/** A student submission for an assignment. */
export type AssignmentSubmission = Node & {
  __typename?: 'AssignmentSubmission';
  /** The associated assignment. */
  assignment: Assignment;
  /** The configuration from the assignment, modified with the overrides. */
  assignmentConfiguration?: Maybe<Scalars['JSON']>;
  /** The associated assignment ID. */
  assignmentId: Scalars['ID'];
  /** Whether or not the current user can grade this submission. */
  canGrade: Scalars['Boolean'];
  /** Whether or not the current user can reset this submission. */
  canReset: Scalars['Boolean'];
  /** Whether or not the current user can submit this submission. */
  canSubmit: Scalars['Boolean'];
  /** The class the submission is associated with. */
  class: Class;
  /** The ID of the class the submission is associated with. */
  classId: Scalars['ID'];
  /** Key represents the rubric ID, value represents the comment. */
  comments?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  /** The date the submission was deleted, if it's deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Additional metadata associated with this generated submission, if it is one. */
  generated?: Maybe<Scalars['JSON']>;
  /** The calculated grade for the assignment. */
  grade?: Maybe<Scalars['Float']>;
  /** The date the submission was graded */
  gradedAt?: Maybe<Scalars['DateTime']>;
  /** Key represents the rubric ID, value represents the grade (as a percentage of totalPoints). */
  grades?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** Whether or not the assignment is late. */
  isLate?: Maybe<Scalars['Boolean']>;
  /** Configuration overrides from the parent assignment. */
  overrides?: Maybe<Scalars['JSON']>;
  /** Additional metadata associated with individual questions inside this submission. The key is the question ID. */
  questionMetadata?: Maybe<Scalars['JSON']>;
  /** Whether the submission has been reviewed by the teacher. */
  reviewed?: Maybe<Scalars['Boolean']>;
  /** The submitting student. */
  student: Student;
  /** The ID of the submitting student. */
  studentId: Scalars['ID'];
  /** Any open-ended data associated with this submission. */
  submissionData?: Maybe<Scalars['JSON']>;
  /** The date the submission was submitted */
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type AssignmentSubmissionAndAssignmentPayload = {
  __typename?: 'AssignmentSubmissionAndAssignmentPayload';
  assignment: Assignment;
  assignmentSubmission: AssignmentSubmission;
};

export type AssignmentSubmissionPayload = {
  __typename?: 'AssignmentSubmissionPayload';
  assignmentSubmission: AssignmentSubmission;
};

export type AssignmentSubmissionSummary = {
  __typename?: 'AssignmentSubmissionSummary';
  /** The average total grade for all submissions. */
  averageGrade?: Maybe<Scalars['Float']>;
  /** Reporting for each question contained within the assignment. */
  questions: Array<AssignmentSubmissionSummaryQuestion>;
};

export type AssignmentSubmissionSummaryAnswerDistribution = {
  __typename?: 'AssignmentSubmissionSummaryAnswerDistribution';
  /** The value for the answer. */
  answer: Scalars['String'];
  /** The percentage of students that chose this answer. */
  percentage: Scalars['Float'];
};

export type AssignmentSubmissionSummaryQuestion = {
  __typename?: 'AssignmentSubmissionSummaryQuestion';
  /** If the question is auto-gradeable, the distribution of answers for the question. */
  answerDistribution?: Maybe<Array<AssignmentSubmissionSummaryAnswerDistribution>>;
  /** The average grade for the question. */
  averageGrade: Scalars['Float'];
  /** The ID of the question. */
  id: Scalars['String'];
};

export type AssignmentSubmissionsConnection = Connection & {
  __typename?: 'AssignmentSubmissionsConnection';
  averageGrade?: Maybe<Scalars['Float']>;
  averagePoints?: Maybe<Scalars['Float']>;
  edges: Array<AssignmentSubmissionsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentSubmissionsEdge = Edge & {
  __typename?: 'AssignmentSubmissionsEdge';
  cursor: Scalars['String'];
  node: AssignmentSubmission;
};

export type AssignmentSync = Node & {
  __typename?: 'AssignmentSync';
  class: Class;
  classSync: ClassSync;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  provider: Scalars['String'];
  providerId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AssignmentSyncsConnection = Connection & {
  __typename?: 'AssignmentSyncsConnection';
  edges: Array<AssignmentSyncsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentSyncsEdge = Edge & {
  __typename?: 'AssignmentSyncsEdge';
  cursor: Scalars['String'];
  node: AssignmentSync;
};

/** An assignment template is used to configure the same options for multiple assignments. */
export type AssignmentTemplate = Node & {
  __typename?: 'AssignmentTemplate';
  /** The question configuration for this template. */
  configuration?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  /** The defaults to set on any created assignments that use this template. */
  defaults?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  /** The name of the assignment template. */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The user that owns this template, if not an administrator. */
  user?: Maybe<User>;
  /** The ID of the user that owns this template, if not an administrator. */
  userId?: Maybe<Scalars['ID']>;
};

export type AssignmentTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  configurationChanges?: InputMaybe<Scalars['JSON']>;
  defaults?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
};

export type AssignmentTemplatePayload = {
  __typename?: 'AssignmentTemplatePayload';
  assignmentTemplate: AssignmentTemplate;
};

export type AssignmentTemplatesConnection = Connection & {
  __typename?: 'AssignmentTemplatesConnection';
  edges: Array<AssignmentTemplatesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentTemplatesEdge = Edge & {
  __typename?: 'AssignmentTemplatesEdge';
  cursor: Scalars['String'];
  node: AssignmentTemplate;
};

export type AssignmentsConnection = Connection & {
  __typename?: 'AssignmentsConnection';
  edges: Array<AssignmentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AssignmentsEdge = Edge & {
  __typename?: 'AssignmentsEdge';
  cursor: Scalars['String'];
  node: Assignment;
};

export type AssociateCanvasPayload = {
  __typename?: 'AssociateCanvasPayload';
  user: User;
};

export type AssociateCleverPayload = {
  __typename?: 'AssociateCleverPayload';
  user: User;
};

export type AssociateGooglePayload = {
  __typename?: 'AssociateGooglePayload';
  user: User;
};

export type AssociateMsalPayload = {
  __typename?: 'AssociateMSALPayload';
  user: User;
};

export type AssociateSchoologyPayload = {
  __typename?: 'AssociateSchoologyPayload';
  user: User;
};

export type AssociatedClassAssignmentInput = {
  /** The class ID. */
  id: Scalars['ID'];
  overrides?: InputMaybe<AssignmentInput>;
  segmentId: Scalars['String'];
  templateId: Scalars['ID'];
};

export type AttachClassSyncInput = {
  /** The ID of the class to sync. */
  id: Scalars['ID'];
  /** The name of the class on the provider. */
  label: Scalars['String'];
  /** The identifier for the provider. */
  provider: Scalars['String'];
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String'];
};

export type AttachClassSyncStudentAccountInput = {
  /** The current class ID to attach the student to. */
  id: Scalars['ID'];
  provider: Scalars['String'];
  providerId: Scalars['String'];
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: InputMaybe<Scalars['ID']>;
  userInfo: BasicUserInput;
};

export type AttachClassSyncStudentAccountPayload = {
  __typename?: 'AttachClassSyncStudentAccountPayload';
  student?: Maybe<Student>;
};

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String'];
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String'];
};

/** An attachment represents any piece of uploaded data, attached to any other node. */
export type Attachment = {
  __typename?: 'Attachment';
  /** The current field values for the attachment, to store in either a database or cache. */
  field?: Maybe<StoredAttachment>;
  /** The unique ID of the attachment, created using the parent's ID. */
  id: Scalars['ID'];
  /**
   * Gets the upload URL for the current attachment, after verifying permissions, returning the
   * updated attachment.
   */
  uploadUrl: AttachmentUploadUrlResult;
  /** The signed URL to download the attachment. */
  url?: Maybe<SignedFetchResult>;
};


/** An attachment represents any piece of uploaded data, attached to any other node. */
export type AttachmentUploadUrlArgs = {
  input: AttachmentUploadInput;
};

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The original name of the file. */
  name: Scalars['String'];
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput;
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float'];
};

export type AttachmentUploadUrlResult = {
  __typename?: 'AttachmentUploadUrlResult';
  /** The updated attachment, for updating local stores. */
  attachment: Attachment;
  /** The signed upload result, used to upload the file. */
  upload: SignedUploadResult;
};

export type AutoGradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID'];
};

export enum AutoGradingStatus {
  /** Automatic grading failed for this question. */
  Failed = 'FAILED',
  /** The question has been graded already. */
  Graded = 'GRADED',
  /** The question is currently being graded. */
  Grading = 'GRADING'
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String'];
};

export type BackgroundJob = {
  __typename?: 'BackgroundJob';
  /** The ID of the background job. */
  id: Scalars['ID'];
  /** The original payload used for the job. */
  payload?: Maybe<Scalars['JSON']>;
  /** If the job supports progress, the current progress for the job. */
  progress?: Maybe<BackgroundJobProgress>;
  /** The pending result payload for the job. */
  resultPayload?: Maybe<Scalars['JSON']>;
  /** The current status of the job. */
  status: BackgroundJobStatus;
  /** The identifier for the related background job resource. */
  type: Scalars['String'];
};

export type BackgroundJobProgress = {
  __typename?: 'BackgroundJobProgress';
  current: Scalars['Int'];
  total: Scalars['Int'];
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type BasicUser = {
  __typename?: 'BasicUser';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type BasicUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type BasicUserTask = UserTask & {
  __typename?: 'BasicUserTask';
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
};

export type BeginSchoologyAuthenticationPayload = {
  __typename?: 'BeginSchoologyAuthenticationPayload';
  token: Scalars['String'];
  tokenSecret: Scalars['String'];
};

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID'];
};

export type CancelLicenseSubscriptionInput = {
  id: Scalars['ID'];
};

/** Reasons a trial request might not be able to be approved. */
export enum CantApproveTrialRequestReason {
  /** This user already belongs to a non-trial license. */
  BelongsToNonTrialLicense = 'BELONGS_TO_NON_TRIAL_LICENSE',
  /** A deleted user already exists with this email address. */
  DeletedUserExists = 'DELETED_USER_EXISTS'
}

export type CanvasAuthCodeInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type CanvasAuthentication = {
  __typename?: 'CanvasAuthentication';
  consumerKey: Scalars['String'];
  url: Scalars['String'];
};

export type CanvasCredentialsTokenResponse = {
  __typename?: 'CanvasCredentialsTokenResponse';
  token: Scalars['String'];
};

export type CanvasGroupSet = {
  __typename?: 'CanvasGroupSet';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CanvasGroupSetGroup = {
  __typename?: 'CanvasGroupSetGroup';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  leaderStudentId: Scalars['ID'];
  memberStudentIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CanvasGroupSetGroups = {
  __typename?: 'CanvasGroupSetGroups';
  groups: Array<CanvasGroupSetGroup>;
};

export type CanvasGroupSets = {
  __typename?: 'CanvasGroupSets';
  groupSets: Array<CanvasGroupSet>;
};

export type CanvasHealthInput = {
  consumerKey?: InputMaybe<Scalars['String']>;
  redirectUri: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CanvasHealthPayload = {
  __typename?: 'CanvasHealthPayload';
  error?: Maybe<Scalars['String']>;
  errorDescription?: Maybe<Scalars['String']>;
  healthy: Scalars['Boolean'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  amountInCents: Scalars['Int'];
  id: Scalars['String'];
  type: CheckoutSessionType;
};

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT'
}

/** A class is a collection of students. */
export type Class = Node & {
  __typename?: 'Class';
  /** Gets all announcements associated with this class. */
  announcements: AnnouncementsConnection;
  /** The time the class was archived. */
  archivedAt?: Maybe<Scalars['DateTime']>;
  /** Gets the assignment-specific configuration for this class. */
  assignmentConfiguration?: Maybe<AssignmentClassConfiguration>;
  /** Gets the assignment related to a segment ID. */
  assignmentForSegment?: Maybe<Assignment>;
  /**
   * Gets all assignments for the current class.
   * @deprecated Use 'teacher.assignments' with a class filter instead.
   */
  assignments: AssignmentsConnection;
  /** Gets all available grade category imports for the current class. */
  availableGradeCategoryImports: Array<GradeCategoryImportSource>;
  availableGroupImportGroups: GroupImportGroups;
  availableGroupImports: Array<GroupImport>;
  /** The average grade for all students in this class. */
  averageGrade?: Maybe<Scalars['Float']>;
  /** The merged & baked course configuration. */
  bakedCourseConfiguration?: Maybe<Scalars['JSON']>;
  /** If the current user can attach student accounts for this class. */
  canAttachStudentAccounts: Scalars['Boolean'];
  /** Whether or not the current user can change the course structure. */
  canChangeCourseStructure: Scalars['Boolean'];
  /** Whether or not the user can copy segments from this class. */
  canCopySegmentsFrom: Scalars['Boolean'];
  /** Whether or not the user can copy segments from another class to this class. */
  canCopySegmentsTo: Scalars['Boolean'];
  /** If the current user can create an announcement in this class. */
  canCreateAnnouncement: Scalars['Boolean'];
  /** If the current user can create assignments and assign students to them from this class. */
  canCreateAssignment: Scalars['Boolean'];
  /** If the current user can create a new class sync for this class. */
  canCreateClassSync: Scalars['Boolean'];
  /** Whether or not the current user can enable / disable / schedule segments. */
  canEnableSegments: Scalars['Boolean'];
  /** Whether or not the current user can invite new students to this class. */
  canInvite: Scalars['Boolean'];
  /** Whether or not the current user can lock segments in this class. */
  canLockSegments: Scalars['Boolean'];
  /** If the current user can update the assignment categories for this class. */
  canManageAssignmentCategories: Scalars['Boolean'];
  /** If the current user can manage the assignment configuration for this class. */
  canManageAssignmentClassConfiguration: Scalars['Boolean'];
  canMessageStudents: Scalars['Boolean'];
  /** Whether or not the current user can remove students from this class. */
  canRemoveStudents: Scalars['Boolean'];
  /** Whether or not the current user can sync segments in this class. */
  canSyncSegments: Scalars['Boolean'];
  /** Whether or not the current user can update this class. */
  canUpdate: Scalars['Boolean'];
  /** If the current user can update the default groups configuration for this class. */
  canUpdateDefaultGroups: Scalars['Boolean'];
  /** Whether or not the current user can update this class's configuration settings. */
  canUpdateSettings: Scalars['Boolean'];
  /** @deprecated Use the new common Class Sync API instead. */
  canvasGroupSetGroups?: Maybe<CanvasGroupSetGroups>;
  /** @deprecated Use the new common Class Sync API instead. */
  canvasGroupSets?: Maybe<CanvasGroupSets>;
  /** The public identifier for the class. Used to add students. */
  classId?: Maybe<Scalars['String']>;
  /** Additional configuration related to the class. */
  configuration?: Maybe<Scalars['JSON']>;
  /**
   * Gets the content visited report for the current class.
   * @deprecated Use the course-reports feature instead.
   */
  contentVisited: ContentVisitedResult;
  /** The merged course configuration. */
  courseConfiguration?: Maybe<Scalars['JSON']>;
  /** The course version that the class uses. */
  courseVersion?: Maybe<CourseVersion>;
  /** The time the class was first created. */
  createdAt: Scalars['DateTime'];
  /** The time the class was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The description for the class. */
  description?: Maybe<Scalars['String']>;
  /** The identifiers for generated assignment providers that are available in this class. */
  generatedProviders?: Maybe<Array<Scalars['String']>>;
  /** The default groups configuration for the class. */
  groups?: Maybe<Scalars['JSON']>;
  /** Whether or not the current user has a valid subscription to this class. */
  hasValidSubscription: Scalars['Boolean'];
  /** An identifier for the class. */
  id: Scalars['ID'];
  /** Gets interactions belonging to this class. */
  interactions: InteractionsConnection;
  /** Whether or not the current user owns this class. */
  isOwn: Scalars['Boolean'];
  /** The subscriptions associated with the class. */
  licenseSubscriptions: LicenseSubscriptionsConnection;
  /** The name of the class. */
  name: Scalars['String'];
  /**
   * Retrieves the scheduled segments of a class
   * @deprecated scheduledSegments has been merged into segmentMetadata
   */
  scheduledSegments?: Maybe<Scalars['JSON']>;
  /** Gets the automatically calculated segment decorations for the current class. */
  segmentDecorations?: Maybe<Array<SegmentDecoration>>;
  /** Additional segment information associated with the course. */
  segmentMetadata?: Maybe<Scalars['JSON']>;
  /** The site that owns the class. */
  site: Site;
  /** The students that belong to the class. */
  students: ClassStudentsConnection;
  /** Gets the currently-active syncs for the class. */
  syncs: ClassSyncsConnection;
  /** The teachers that are related to the class. */
  teachers: ClassTeachersConnection;
  /** Gets the time on task report for the specified segments. */
  timeOnTask: TimeOnTaskResult;
  /** The last time the class was updated. */
  updatedAt: Scalars['DateTime'];
  /** Gets the user tasks for the current user, for the current class. */
  userTasks: Array<UserTask>;
  /** The weight of this class. */
  weight: Scalars['Float'];
};


/** A class is a collection of students. */
export type ClassAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassAssignmentForSegmentArgs = {
  segmentId: Scalars['String'];
};


/** A class is a collection of students. */
export type ClassAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AssignmentFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassAvailableGroupImportGroupsArgs = {
  input: AvailableGroupImportGroupsInput;
};


/** A class is a collection of students. */
export type ClassCanvasGroupSetGroupsArgs = {
  groupSetId: Scalars['String'];
};


/** A class is a collection of students. */
export type ClassInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  types?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


/** A class is a collection of students. */
export type ClassLicenseSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassSyncsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A class is a collection of students. */
export type ClassTimeOnTaskArgs = {
  input: TimeOnTaskInput;
};


/** A class is a collection of students. */
export type ClassUserTasksArgs = {
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<UserTasksFilterInput>;
};

export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS'
}

export type ClassImplementationGuideReport = {
  __typename?: 'ClassImplementationGuideReport';
  implementationGuides: Array<ClassImplementationGuideReportItem>;
};

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID'];
};

export type ClassImplementationGuideReportItem = {
  __typename?: 'ClassImplementationGuideReportItem';
  grade?: Maybe<Scalars['Float']>;
  implementationGuide: Scalars['String'];
};

export type ClassInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  courseId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  segmentMetadata?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Float']>;
};

/** The payload returned after mutating a Class. */
export type ClassPayload = {
  __typename?: 'ClassPayload';
  /** The created or updated Class. */
  class: Class;
};

export type ClassReportInput = {
  classIds: Array<Scalars['ID']>;
  igNames?: InputMaybe<Array<Scalars['String']>>;
};

export type ClassReportResult = {
  __typename?: 'ClassReportResult';
  entries: Array<ClassReportResultItem>;
};

export type ClassReportResultItem = {
  __typename?: 'ClassReportResultItem';
  averageTimeOnTask?: Maybe<Scalars['Float']>;
  classId: Scalars['ID'];
  className: Scalars['String'];
  contentVisited?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  studentId?: Maybe<Scalars['ID']>;
  studentName?: Maybe<Scalars['String']>;
  timeOnTask?: Maybe<Scalars['Float']>;
};

export enum ClassRole {
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type ClassStudentInput = {
  classId: Scalars['ID'];
  studentId: Scalars['ID'];
};

/** Class -> Students */
export type ClassStudentsConnection = Connection & {
  __typename?: 'ClassStudentsConnection';
  edges: Array<ClassStudentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClassStudentsEdge = Edge & {
  __typename?: 'ClassStudentsEdge';
  cursor: Scalars['String'];
  /** The grade for the student in this class. */
  grade?: Maybe<Scalars['Float']>;
  node: Student;
};

export type ClassSync = Node & {
  __typename?: 'ClassSync';
  /** Whether or not the current user can delete this class sync. */
  canDelete: Scalars['Boolean'];
  class: Class;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  provider: Scalars['String'];
  providerId: Scalars['String'];
  /** Gets all students related to this class sync. */
  students: Array<ClassSyncStudent>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type ClassSyncProvider = {
  __typename?: 'ClassSyncProvider';
  authProviderId?: Maybe<Scalars['String']>;
  authProviderName?: Maybe<Scalars['String']>;
  identifier: Scalars['String'];
  name: Scalars['String'];
  supportsAssignmentSync: Scalars['Boolean'];
};

export type ClassSyncProviderClass = {
  __typename?: 'ClassSyncProviderClass';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type ClassSyncStudent = {
  __typename?: 'ClassSyncStudent';
  group?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  userInfo: BasicUser;
};

export type ClassSyncsConnection = Connection & {
  __typename?: 'ClassSyncsConnection';
  edges: Array<ClassSyncsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClassSyncsEdge = Edge & {
  __typename?: 'ClassSyncsEdge';
  cursor: Scalars['String'];
  node: ClassSync;
};

export type ClassTeachersConnection = Connection & {
  __typename?: 'ClassTeachersConnection';
  edges: Array<ClassTeachersEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClassTeachersEdge = Edge & {
  __typename?: 'ClassTeachersEdge';
  cursor: Scalars['String'];
  node: Teacher;
  permissions: Array<Scalars['String']>;
  role: ClassRole;
};

export type ClassesConnection = Connection & {
  __typename?: 'ClassesConnection';
  edges: Array<ClassesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClassesEdge = Edge & {
  __typename?: 'ClassesEdge';
  cursor: Scalars['String'];
  node: Class;
};

export type ClassesReportDimensionInput = {
  classIds: Array<Scalars['ID']>;
};

export type ClassesReportDimensionResult = {
  __typename?: 'ClassesReportDimensionResult';
  class: Class;
};

export type Comment = Node & {
  __typename?: 'Comment';
  /** The date the comment was approved. */
  approvedAt?: Maybe<Scalars['DateTime']>;
  /** The comment itself. */
  comment: Scalars['String'];
  /** If available, the name of the content the comment is associated with. */
  contentName?: Maybe<Scalars['String']>;
  /** The date the comment was first created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether or not the current user likes this content. */
  isLiked: Scalars['Boolean'];
  /** The number of users who have liked this content. */
  likes: Scalars['Int'];
  /** Any additional metadata associated with the comment. Depends on the use-case. */
  metadata?: Maybe<Scalars['JSON']>;
  /** The associated node. */
  node: Node;
  /** The ID of the associated node. */
  nodeId: Scalars['ID'];
  /** The date the comment was rejected. */
  rejectedAt?: Maybe<Scalars['DateTime']>;
  /** The reason the comment was rejected. */
  rejectedReason?: Maybe<Scalars['String']>;
  /** The date the comment was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The user that posted the comment. */
  user: User;
};

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED'
}

export type CommentInput = {
  comment: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
};

export type CommentOnNodeInput = {
  /** The actual text of the comment. */
  comment: Scalars['String'];
  /** The ID of the node to comment on. */
  id: Scalars['ID'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  /** The created comment. */
  comment: Comment;
};

export type CommentsConnection = Connection & {
  __typename?: 'CommentsConnection';
  edges: Array<CommentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  unread?: Maybe<Scalars['Int']>;
};

export type CommentsEdge = Edge & {
  __typename?: 'CommentsEdge';
  cursor: Scalars['String'];
  node: Comment;
};

export type CompleteAssignmentUserTask = UserTask & {
  __typename?: 'CompleteAssignmentUserTask';
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  payload: CompleteAssignmentUserTaskPayload;
};

export type CompleteAssignmentUserTaskPayload = {
  __typename?: 'CompleteAssignmentUserTaskPayload';
  assignmentId: Scalars['ID'];
  assignmentName: Scalars['String'];
  classId: Scalars['ID'];
  dueAt?: Maybe<Scalars['DateTime']>;
  segmentId?: Maybe<Scalars['String']>;
};

/** The relay-compliant Connection interface. Contains all connection information. */
export type Connection = {
  /** An array of all of the resulting edges in this connection. */
  edges: Array<Edge>;
  /** Contains information about the current page and if there are other pages available. */
  pageInfo: PageInfo;
  /** The total count of all results in this current connection. */
  totalCount: Scalars['Int'];
};

export type ContentVisited = {
  __typename?: 'ContentVisited';
  contentVisited: Scalars['Float'];
  studentUserId: Scalars['ID'];
};

export type ContentVisitedReportMetricInput = {
  place?: InputMaybe<Scalars['Boolean']>;
};

export type ContentVisitedResult = {
  __typename?: 'ContentVisitedResult';
  average: Scalars['Float'];
  contentVisited: Array<ContentVisited>;
};

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID'];
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID'];
};

export type CopySegmentSettingsInput = {
  /** The IDs of the classes to copy this information to. */
  destinationIds: Array<Scalars['ID']>;
  /** The ID of the class to copy from. */
  id: Scalars['ID'];
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: InputMaybe<Array<Scalars['String']>>;
};

export type CopySegmentSettingsPayload = {
  __typename?: 'CopySegmentSettingsPayload';
  /** The modified classes. */
  classes: Array<Class>;
};

/** A course is a collection of segments and information relating to curriculum. */
export type Course = Node & {
  __typename?: 'Course';
  /** Basic configuration, for display on the homepage or in a catalog. */
  basicConfiguration?: Maybe<Scalars['JSON']>;
  /** Defaults used when creating new classes with this course. */
  classConfiguration?: Maybe<Scalars['JSON']>;
  /** The time the course was originally created. */
  createdAt: Scalars['DateTime'];
  /** The ID of the course. */
  id: Scalars['ID'];
  /** Whether or not the course is a build-your-own-course. */
  isByoc?: Maybe<Scalars['Boolean']>;
  /** Whether or not the course is hidden from public view by default. */
  isHidden?: Maybe<Scalars['Boolean']>;
  /** The override label for the course, displayed to teachers and students. */
  label?: Maybe<Scalars['String']>;
  /** Gets the latest published course version. */
  latestPublishedVersion?: Maybe<CourseVersion>;
  /** The name of the course. */
  name: Scalars['String'];
  /** The short code for a course. Max length is 10 characters. */
  shortCode?: Maybe<Scalars['String']>;
  /** The state a course is associated with. */
  state?: Maybe<Scalars['String']>;
  /** The time the course was last updated. */
  updatedAt: Scalars['DateTime'];
  /** Gets all versions for the course. */
  versions: CourseVersionsConnection;
};


/** A course is a collection of segments and information relating to curriculum. */
export type CourseVersionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CourseInput = {
  classConfiguration?: InputMaybe<Scalars['JSON']>;
  configuration?: InputMaybe<Scalars['JSON']>;
  isByoc?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  shortCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** The payload returned when creating or updating a course. */
export type CoursePayload = {
  __typename?: 'CoursePayload';
  course: Course;
};

export type CourseVersion = Node & {
  __typename?: 'CourseVersion';
  /** Gets all segment labels present inside this course version. */
  allLabels: Array<Scalars['String']>;
  /** The classes that use this course. */
  classes: ClassesConnection;
  /** The full configuration for this course version. */
  configuration?: Maybe<Scalars['JSON']>;
  /** The course this version is associated with. */
  course: Course;
  /** The time this version was originally created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The name of the version. */
  name: Scalars['String'];
  /** The time this version was published. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** The root segment ID for the configuration. */
  rootSegmentId: Scalars['String'];
  /** The default rubric configuration for the course version. */
  rubric?: Maybe<Scalars['JSON']>;
  /** The time this version was last updated. */
  updatedAt: Scalars['DateTime'];
};


export type CourseVersionClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type CourseVersionInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CourseVersionPayload = {
  __typename?: 'CourseVersionPayload';
  courseVersion: CourseVersion;
};

export type CourseVersionsConnection = Connection & {
  __typename?: 'CourseVersionsConnection';
  edges: Array<CourseVersionsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CourseVersionsEdge = Edge & {
  __typename?: 'CourseVersionsEdge';
  cursor: Scalars['String'];
  node: CourseVersion;
};

export type CoursesConnection = Connection & {
  __typename?: 'CoursesConnection';
  edges: Array<CoursesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CoursesEdge = Edge & {
  __typename?: 'CoursesEdge';
  cursor: Scalars['String'];
  node: Course;
};

export type CreateAnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateAnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  user: RegisterInput;
};

export type CreateAssignmentCategoryInput = {
  category: AssignmentCategoryInput;
  /** The class ID */
  id: Scalars['ID'];
};

export type CreateAssignmentInput = {
  assignment: AssignmentInput;
};

export type CreateCheckoutSessionInput = {
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String'];
  /** The class to create a checkout session for. */
  id: Scalars['ID'];
  /** The URL to redirect to on success. */
  successUrl: Scalars['String'];
};

export type CreateCheckoutSessionPayload = {
  __typename?: 'CreateCheckoutSessionPayload';
  license: License;
  sessions: Array<CheckoutSession>;
};

export type CreateClassInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  courseId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  siteId?: InputMaybe<Scalars['ID']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type CreateClassWithPlacedContentInput = {
  class: ClassInput;
  segmentId: Scalars['ID'];
};

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID'];
  /** The name of the new version to create. */
  name: Scalars['String'];
};

export type CreateDirectMessageThreadInput = {
  /** The message to send. */
  message: Scalars['String'];
  /** An array of user IDs to send the message to. */
  recipientIds: Array<Scalars['ID']>;
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: InputMaybe<Scalars['String']>;
  /** The subject of the thread. */
  subject: Scalars['String'];
};

export type CreateDirectMessageThreadPayload = {
  __typename?: 'CreateDirectMessageThreadPayload';
  /** The first-created comment. */
  comment: Comment;
  /** The thread the message belongs to. */
  directMessageThread: DirectMessageThread;
};

export type CreateSavedReportInput = {
  name: Scalars['String'];
  report: Scalars['JSON'];
};

export type CreateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type CreateStudentInput = {
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateVideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  provider: Scalars['String'];
  /** This is only required if the provider requires it. */
  signedUploadInput?: InputMaybe<SignedUploadInput>;
  sizeInBytes: Scalars['Int'];
};

export type CreateVideoOnDemandPayload = {
  __typename?: 'CreateVideoOnDemandPayload';
  /** The payload used for uploading the video. */
  payload: VodUploadPayload;
  /**
   * The URL to upload the video to.
   * @deprecated Use payload instead.
   */
  uploadUrl: Scalars['String'];
  /** The created videoOnDemand */
  videoOnDemand: VideoOnDemand;
};

export type CustomVodPlaybackData = {
  __typename?: 'CustomVodPlaybackData';
  data: Scalars['JSON'];
};

export type CustomVodUploadPayload = {
  __typename?: 'CustomVodUploadPayload';
  data: Scalars['JSON'];
};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']>;
  before?: InputMaybe<Scalars['DateTime']>;
};

export type DefaultVodPlaybackData = {
  __typename?: 'DefaultVodPlaybackData';
  hlsUrl: Scalars['String'];
};

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID'];
};

export type DeleteNodeResponse = {
  __typename?: 'DeleteNodeResponse';
  /** The ID of the deleted node. */
  id: Scalars['ID'];
  /** The number of rows deleted. */
  numDeleted: Scalars['Int'];
};

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']>;
};

export type DeleteSegmentPayload = {
  __typename?: 'DeleteSegmentPayload';
  /** The ID of the segment that was deleted. */
  segmentId: Scalars['ID'];
};

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID'];
};

export type DetachSegmentPayload = {
  __typename?: 'DetachSegmentPayload';
  /** The reloaded course version associated with the segment. */
  courseVersion: CourseVersion;
};

/** Represents a detailed request log stored inside S3. */
export type DetailedLog = {
  __typename?: 'DetailedLog';
  /** The name of the log, without any path names. */
  filename: Scalars['String'];
  /** The global ID for the log (just the filename, in GraphQL format). */
  id: Scalars['ID'];
  /** A signed URL to download the contents of the log file. */
  signedUrl: Scalars['String'];
  /** The size of the log file, in bytes. */
  sizeInBytes: Scalars['Float'];
  /** The tags on the log. */
  tags: Array<Scalars['String']>;
};

export type DetailedLogsConnection = {
  __typename?: 'DetailedLogsConnection';
  hasMore: Scalars['Boolean'];
  lastKey?: Maybe<Scalars['String']>;
  logs: Array<DetailedLog>;
};

export type DetailedLogsForUserInput = {
  /** Filter by the name of the function handler, rather than displaying all handlers. */
  handler?: InputMaybe<Scalars['String']>;
  /** The ID of the user to get logs for. */
  id: Scalars['ID'];
  /** The JWT of the administration user. */
  jwt: Scalars['String'];
  /** Any additional tags to filter by. */
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** A direct message thread represents a collection of messages sent from one user to another. */
export type DirectMessageThread = Node & {
  __typename?: 'DirectMessageThread';
  /** Whether or not the current user can comment on this entity. */
  canComment: Scalars['Boolean'];
  /** Gets all comments associated with this entity. */
  comments: CommentsConnection;
  /** The time the thread was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The IDs of the users that belong to the thread. */
  recipientIds: Array<Scalars['ID']>;
  /** The users that belong to the thread. */
  recipients: Array<User>;
  /** An optional label to use to override the recipients. */
  recipientsLabel?: Maybe<Scalars['String']>;
  /** The subject of the thread. */
  subject: Scalars['String'];
  /** The last time the thread was updated. */
  updatedAt: Scalars['DateTime'];
  /** The user that created the thread. */
  user: User;
  /** The ID of the user that created the thread. */
  userId: Scalars['ID'];
};


/** A direct message thread represents a collection of messages sent from one user to another. */
export type DirectMessageThreadCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type DirectMessageThreadPayload = {
  __typename?: 'DirectMessageThreadPayload';
  /** The thread just updated. */
  directMessageThread: DirectMessageThread;
};

export type DirectMessageThreadsConnection = Connection & {
  __typename?: 'DirectMessageThreadsConnection';
  edges: Array<DirectMessageThreadsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
  /** The total number of unread messages inside each of the returned threads. */
  unread?: Maybe<Scalars['Int']>;
};

export type DirectMessageThreadsEdge = Edge & {
  __typename?: 'DirectMessageThreadsEdge';
  cursor: Scalars['String'];
  node: DirectMessageThread;
};

export type DirectMessageThreadsFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Other features are supposed to add to this later... */
  noop?: InputMaybe<Scalars['String']>;
};

/** A district is an organization of other models. */
export type District = Node & {
  __typename?: 'District';
  /** The city the district is in. */
  city?: Maybe<Scalars['String']>;
  /** The primary point of contact's email for the district. */
  contactEmail?: Maybe<Scalars['String']>;
  /** The primary point of contact's name for the district. */
  contactName?: Maybe<Scalars['String']>;
  /** The time the district was created. */
  createdAt: Scalars['DateTime'];
  /** The ID of the district. */
  id: Scalars['ID'];
  /** The name of the district. */
  name: Scalars['String'];
  /** Gets all sites inside this district. */
  sites: SitesConnection;
  /** The 2-character state code for the state the district is in. */
  state?: Maybe<Scalars['String']>;
  /** The last time the district was updated. */
  updatedAt: Scalars['DateTime'];
};


/** A district is an organization of other models. */
export type DistrictSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type DistrictInput = {
  city?: InputMaybe<Scalars['String']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  contactName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

/** The payload returned when updating a district. */
export type DistrictPayload = {
  __typename?: 'DistrictPayload';
  district: District;
};

export type DistrictsConnection = Connection & {
  __typename?: 'DistrictsConnection';
  edges: Array<DistrictsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DistrictsEdge = Edge & {
  __typename?: 'DistrictsEdge';
  cursor: Scalars['String'];
  node: District;
};

export type DueDateFilterInput = {
  /** Defaults to the current date if not specified. */
  date?: InputMaybe<Scalars['DateTime']>;
  type: DueDateFilterType;
};

export enum DueDateFilterType {
  After = 'AFTER',
  Before = 'BEFORE'
}

/** The relay-compliant Edge interface. Represents a single result in a connection. */
export type Edge = {
  /** An identifier for the current node. Use this in the `after` argument for pagination. */
  cursor: Scalars['String'];
  /** The actual resulting node. */
  node: Node;
};

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FinishUploadingVideoOnDemandInput = {
  /** The ID of the video on demand you just finished uploading. */
  vodId: Scalars['ID'];
};

export type ForfeitAssignmentInput = {
  classId: Scalars['ID'];
  forfeit: Scalars['Boolean'];
  /** The ID of the assignment to forfeit. */
  id: Scalars['ID'];
  /** If modifying as a teacher, the ID of the student to modify. */
  studentId?: InputMaybe<Scalars['ID']>;
};

export type GenerateCanvasCredentialsTokenInput = {
  districtId?: InputMaybe<Scalars['ID']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID'];
};

export type GenerateMicrosoftTenantTokenResponse = {
  __typename?: 'GenerateMicrosoftTenantTokenResponse';
  token: Scalars['String'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON'];
  type: Scalars['String'];
};

export type GenerateStudentsInput = {
  count: Scalars['Int'];
  /** The ID of the class to generate students for. */
  id: Scalars['ID'];
};

/** The payload returned after generating students. */
export type GenerateStudentsPayload = {
  __typename?: 'GenerateStudentsPayload';
  /** The number of student screated. */
  createdStudents: Scalars['Int'];
};

export type GenerateVodCaptionsInput = {
  /** The ID of the video to generate captions for. */
  id: Scalars['ID'];
};

/** Represents a dynamically-generated PDF. */
export type GeneratedPdf = Node & {
  __typename?: 'GeneratedPDF';
  createdAt: Scalars['DateTime'];
  /** The data to use with the generated PDF. */
  data: Scalars['JSON'];
  id: Scalars['ID'];
  /** The current generation status. */
  status: GeneratedPdfStatus;
  /** The type of PDF being generated */
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The URL to the generated PDF. */
  url?: Maybe<Scalars['String']>;
};

export type GeneratedPdfPayload = {
  __typename?: 'GeneratedPDFPayload';
  generatedPdf: GeneratedPdf;
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type GradeAssignmentSubmissionInput = {
  comments: Scalars['JSON'];
  grades: Scalars['JSON'];
  /** The ID of the submission to grade. */
  id: Scalars['ID'];
};

export type GradeCategoryImportSource = {
  __typename?: 'GradeCategoryImportSource';
  name: Scalars['String'];
  sourceId: Scalars['String'];
};

export type GradeReportMetricInput = {
  place?: InputMaybe<Scalars['Boolean']>;
};

export type GradesByCategory = {
  __typename?: 'GradesByCategory';
  categories: Array<GradesByCategoryCategory>;
};

export type GradesByCategoryCategory = {
  __typename?: 'GradesByCategoryCategory';
  averageGrade: Scalars['Float'];
  categoryId: Scalars['String'];
};

export type GradesByQuestion = {
  __typename?: 'GradesByQuestion';
  questions: Array<GradesByQuestionQuestion>;
};

export type GradesByQuestionQuestion = {
  __typename?: 'GradesByQuestionQuestion';
  averageGrade: Scalars['Float'];
  questionId: Scalars['String'];
};

export type GroupImport = {
  __typename?: 'GroupImport';
  createdAt?: Maybe<Scalars['DateTime']>;
  importId: Scalars['String'];
  name: Scalars['String'];
};

export type GroupImportGroup = {
  __typename?: 'GroupImportGroup';
  createdAt?: Maybe<Scalars['DateTime']>;
  groupId: Scalars['String'];
  leaderStudentId?: Maybe<Scalars['ID']>;
  memberStudentIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type GroupImportGroups = {
  __typename?: 'GroupImportGroups';
  groups: Array<GroupImportGroup>;
};

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID'];
  /** The ID of the source to use. */
  sourceId: Scalars['String'];
};

export type ImportStudentsInput = {
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String'];
  /** The ID of the class to import the students to. */
  id: Scalars['ID'];
};

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String'];
};

/** Represents a user interacting with the application in a way we would like to track. */
export type Interaction = Node & {
  __typename?: 'Interaction';
  /** The ID of the interaction. */
  id: Scalars['ID'];
  /** Any metadata associated with the interaction. */
  metadata?: Maybe<Scalars['JSON']>;
  /** The time the interaction ended. */
  periodEnd: Scalars['DateTime'];
  /** The time the interaction began. */
  periodStart: Scalars['DateTime'];
  /** The reference to any node being interacted on. */
  reference?: Maybe<Scalars['ID']>;
  /** The target user itself. */
  targetUser?: Maybe<User>;
  /** The ID of the target user. */
  targetUserId?: Maybe<Scalars['ID']>;
  /** The type of interaction that occurred. */
  type: Scalars['String'];
  /** The user that performed the interaction. */
  user: User;
};

export type InteractionInput = {
  metadata?: InputMaybe<Scalars['JSON']>;
  periodEnd: Scalars['DateTime'];
  periodStart: Scalars['DateTime'];
  reference?: InputMaybe<Scalars['ID']>;
  targetUserId?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};

export type InteractionsConnection = Connection & {
  __typename?: 'InteractionsConnection';
  edges: Array<InteractionsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type InteractionsEdge = Edge & {
  __typename?: 'InteractionsEdge';
  cursor: Scalars['String'];
  node: Interaction;
};

export enum InviteResult {
  AddedToClass = 'ADDED_TO_CLASS',
  InvitedToPlatform = 'INVITED_TO_PLATFORM'
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID'];
  /** The student ID to import. */
  studentId: Scalars['String'];
  /** The teacher ID to import. */
  teacherId: Scalars['String'];
};

export type InviteStudentInput = {
  /** The email address of the student to invite. */
  email: Scalars['String'];
  /** The ID of the class to invite the student to. */
  id: Scalars['ID'];
};

export type InviteStudentPayload = {
  __typename?: 'InviteStudentPayload';
  class: Class;
  invited: InviteResult;
};

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID'];
  /** Any default permissions to apply to the teacher. */
  permissions?: InputMaybe<Array<Scalars['String']>>;
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID'];
};

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String'];
};

/** A license represents an agreement between the company and a site. */
export type License = Node & {
  __typename?: 'License';
  /** The courses associated with the license. */
  courses?: Maybe<Scalars['JSON']>;
  /** The date the license was first created. */
  createdAt: Scalars['DateTime'];
  /** The date the license expires. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** The monthly price for STUDENT_PAY licenses, in cents. */
  monthlyPriceInCents?: Maybe<Scalars['Int']>;
  /** The number of months paying up front affords. */
  payUpFrontMonths?: Maybe<Scalars['Int']>;
  /** The pay-up-front price for STUDENT_PAY licenses. */
  payUpFrontPriceInCents?: Maybe<Scalars['Int']>;
  /** The price of the license (metadata) */
  price?: Maybe<Scalars['Int']>;
  /** The Stripe product ID for the license. */
  productId?: Maybe<Scalars['String']>;
  /** The number of seats available on the license. */
  seats?: Maybe<Scalars['Int']>;
  /** The site the license is associated with. */
  site: Site;
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON'];
  /** Gets all tags associated with this entity. */
  tags: TagsConnection;
  /** The trial request associated with the license. */
  trialRequest?: Maybe<TrialRequest>;
  /** The type of license. */
  type: LicenseType;
  /** The date the license was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The number of seats used on the license. */
  usedSeats?: Maybe<Scalars['Int']>;
};


/** A license represents an agreement between the company and a site. */
export type LicenseTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LicenseInput = {
  courses?: InputMaybe<Scalars['JSON']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  monthlyPriceInCents?: InputMaybe<Scalars['Int']>;
  payUpFrontMonths?: InputMaybe<Scalars['Int']>;
  payUpFrontPriceInCents?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  productId?: InputMaybe<Scalars['String']>;
  seats?: InputMaybe<Scalars['Int']>;
  siteId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<LicenseType>;
};

export type LicensePayload = {
  __typename?: 'LicensePayload';
  license: License;
};

/** A subscription represents a billing agreement between the company and individual users. */
export type LicenseSubscription = Node & {
  __typename?: 'LicenseSubscription';
  /** The class the subscription is associated with for STUDENT_PAY */
  class?: Maybe<Class>;
  /** The date the subscription was first created. */
  createdAt: Scalars['DateTime'];
  /** The date the subscription expires. */
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** A boolean indicating whether or not the subscription is currently active. */
  isValid?: Maybe<Scalars['Boolean']>;
  /** The license the subscription is associated with for STUDENT_PAY */
  license?: Maybe<License>;
  /** The subscriptions associated with the license. */
  licenseSubscriptions: LicenseSubscriptionsConnection;
  /** Any additional metadata, used for reporting purposes mostly. */
  metadata?: Maybe<Scalars['JSON']>;
  /** A timestamp indicating the next time the subscription should be checked for validity. */
  nextValidityCheck?: Maybe<Scalars['DateTime']>;
  /** The quota bytes for this subscription (only applies to INDIVIDUAL). */
  quota?: Maybe<Scalars['Float']>;
  /** The IAP receipt from either Apple or Google. */
  receipt?: Maybe<Scalars['String']>;
  /** The subscription size, if it is an INDIVIDUAL subscription. */
  size?: Maybe<LicenseSubscriptionSize>;
  /** The stripe subscription ID, if one is used. */
  subscriptionId?: Maybe<Scalars['String']>;
  /** The type of subscription. */
  type: LicenseSubscriptionType;
  /** The date the subscription was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The currently-used bytes for this subscription (only applies to INDIVIDUAL). */
  used?: Maybe<Scalars['Float']>;
  /** The user that owns the subscription. */
  user: User;
};


/** A subscription represents a billing agreement between the company and individual users. */
export type LicenseSubscriptionLicenseSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type LicenseSubscriptionInput = {
  classId?: InputMaybe<Scalars['ID']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  isValid?: InputMaybe<Scalars['Boolean']>;
  licenseId?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  nextValidityCheck?: InputMaybe<Scalars['DateTime']>;
  size?: InputMaybe<LicenseSubscriptionSize>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LicenseSubscriptionType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type LicenseSubscriptionPayload = {
  __typename?: 'LicenseSubscriptionPayload';
  subscription: LicenseSubscription;
};

export enum LicenseSubscriptionSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum LicenseSubscriptionType {
  Individual = 'INDIVIDUAL',
  StudentPay = 'STUDENT_PAY'
}

export type LicenseSubscriptionsConnection = Connection & {
  __typename?: 'LicenseSubscriptionsConnection';
  edges: Array<LicenseSubscriptionsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LicenseSubscriptionsEdge = Edge & {
  __typename?: 'LicenseSubscriptionsEdge';
  cursor: Scalars['String'];
  node: LicenseSubscription;
};

export enum LicenseType {
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
  Trial = 'TRIAL'
}

export type LicensesConnection = Connection & {
  __typename?: 'LicensesConnection';
  edges: Array<LicensesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type LicensesEdge = Edge & {
  __typename?: 'LicensesEdge';
  cursor: Scalars['String'];
  node: License;
};

export type LikeNodeInput = {
  /** The ID of the node to like. */
  id: Scalars['ID'];
};

/** Represents an active linked authentication account for a user. */
export type LinkedAccount = {
  __typename?: 'LinkedAccount';
  /**
   * Indicates if the linked account is "fully authorized." This typically means we have a valid
   * refresh token as the user, and are able to actually make requests to the API.
   */
  authorized?: Maybe<Scalars['Boolean']>;
  /** A combined identifier representing the linked account (includes the provider and provider ID). */
  id: Scalars['ID'];
  /** The date this account has been invalid since, if it is invalid. */
  invalidSince?: Maybe<Scalars['DateTime']>;
  /** The human-readable label for this account on the provider. */
  label: Scalars['String'];
  /** The identifier for the provider. */
  provider: Scalars['String'];
  /** The ID of the user on the provider. */
  providerId: Scalars['String'];
  /** The human-readable name for the provider. */
  providerName: Scalars['String'];
};

export type ListSyncProviderClassesInput = {
  provider: Scalars['String'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  /** If specified, the user should be redirected to this path instead of the default. */
  initialPath?: Maybe<Scalars['String']>;
  /** The user's JWT used for authentication. */
  jwt: Scalars['String'];
  /** The user itself. */
  user: User;
};

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput;
};

export type LoginWithCleverInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type LoginWithGoogleInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type LoginWithMsalInput = {
  authCode?: InputMaybe<MsalAuthCodeInput>;
  tokens?: InputMaybe<MsalTokensInput>;
};

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput;
};

export type MsalAuthCodeInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type MsalTokensInput = {
  accessToken: Scalars['String'];
  expiresAt: Scalars['String'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type MergeStudentInput = {
  /** The user ID to merge into the specified user. */
  fromUserId: Scalars['ID'];
  /** The user to merge the other account into. */
  id: Scalars['ID'];
};

export type MoveStudentClassInput = {
  /**
   * Whether or not to force the move. This typically means some warnings were given, and
   * you acknowledge them and would like to continue anyway.
   */
  force?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the source class. */
  fromClassId: Scalars['ID'];
  /** The ID of the student. */
  id: Scalars['ID'];
  /** The ID of the destination class. */
  toClassId: Scalars['ID'];
};

export type MoveStudentClassPayload = {
  __typename?: 'MoveStudentClassPayload';
  /** The class the student was moved from. */
  fromClass: Class;
  /** The class the student was moved to. */
  toClass: Class;
};

export type MoveTeacherInput = {
  id: Scalars['ID'];
  toSite: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts a student invite. */
  acceptInvite: AcceptInvitePayload;
  /** Approves a comment. */
  approveComment: CommentPayload;
  /** Approves a trial request. */
  approveTrialRequest: ApproveTrialRequestPayload;
  /** Archives the specified class. */
  archiveClass: ClassPayload;
  /** Associates a Canvas account with the current user. */
  associateCanvas: AssociateCanvasPayload;
  /** Associates a new Clever account with the existing user. */
  associateClever: AssociateCleverPayload;
  /** Associates a Google account with the current user. */
  associateGoogle: AssociateGooglePayload;
  /** Associates a MSAL account with the current user. */
  associateMSAL: AssociateMsalPayload;
  /** Associates a Schoology account with the current user. */
  associateSchoology: AssociateSchoologyPayload;
  /** Attaches a class with a third-party class to enable syncing. */
  attachClassSync: ClassPayload;
  /** Attaches a student account from an existing class sync. */
  attachClassSyncStudentAccount: AttachClassSyncStudentAccountPayload;
  /**
   * Generates a signed URL for uploading a new file, used when uploading on a create form.
   *
   * **Do not use this when uploading for an existing node.** There is a much easier way, using
   * the `Attachment` type. This method requires you to send the bucket and path to the server
   * yourself.
   */
  attachFile: Attachment;
  /**
   * Attach a file without associating it with an existing node.
   *
   * *This is a very powerful feature and should be restricted to those who are trusted.*
   */
  attachGenericFile: SignedUploadResult;
  /** Automatically grades an existing assignment submission. */
  autoGradeAssignmentSubmission: AssignmentSubmissionPayload;
  /** Gets the token required for redirecting the user to authenticate with Schoology. */
  beginSchoologyAuthentication: BeginSchoologyAuthenticationPayload;
  /**
   * Bulk-updates all assignments inside a class with the same values. Potential use cases are
   * automatically updating the assigned students to the current set of students in a class.
   */
  bulkUpdateAssignmentsInClass: SuccessResponse;
  /** Cancels an existing subscription. */
  cancelLicenseSubscription: SuccessResponse;
  /** Posts a new comment on a specific node that supports it. */
  commentOnNode: CommentPayload;
  /** Copies the assignment categories from one owned class to the other. */
  copyAssignmentCategories: AssignmentClassConfigurationPayload;
  /** Copies segment settings from one class to others. */
  copySegmentSettings: CopySegmentSettingsPayload;
  /** Creates a new announcement. */
  createAnnouncement: AnnouncementPayload;
  /** Creates a new answered question contributor. */
  createAnsweredQuestionContributor: AnsweredQuestionContributorPayload;
  /** Creates a new assignment. */
  createAssignment: AssignmentPayload;
  /** Creates a new assignment category for one of this user's classes. */
  createAssignmentCategory: AssignmentCategoryPayload;
  /** Creates a new assignment template. */
  createAssignmentTemplate: AssignmentTemplatePayload;
  /** Creates a new associated class assignment. */
  createAssociatedClassAssignment: AssignmentPayload;
  /** Creates a new checkout session (for a STUDENT_PAY subscription). */
  createCheckoutSession: CreateCheckoutSessionPayload;
  /** Creates a new Class. */
  createClass: ClassPayload;
  /** Creates a class with the passed content already placed. */
  createClassWithPlacedContent: ClassPayload;
  /** Create a new course. */
  createCourse: CoursePayload;
  /** Creates a new course version. */
  createCourseVersion: CourseVersionPayload;
  /** Creates a new direct message thread. */
  createDirectMessageThread: CreateDirectMessageThreadPayload;
  /** Creates a new district. */
  createDistrict: DistrictPayload;
  /** Creates a new INDIVIDUAL subscription, from an in-app-purchase. */
  createIndividualSubscription: LicenseSubscriptionPayload;
  /** Creates a new license. */
  createLicense: LicensePayload;
  /** Creates a new subscription. */
  createLicenseSubscription: LicenseSubscriptionPayload;
  /** Create or update a segment. */
  createOrUpdateSegment: SegmentPayload;
  /** Creates a new rubric template for the current user. */
  createRubricTemplate: RubricTemplatePayload;
  /** Creates a new saved report for the current user. */
  createSavedReport: SavedReportPayload;
  /** Creates a new site. */
  createSite: SitePayload;
  /** Creates a new standard set. */
  createStandardSet: StandardSetPayload;
  /** Creates a new student. */
  createStudent: StudentPayload;
  /** Creates a new tag. */
  createTag: TagPayload;
  /** Creates a new teacher. */
  createTeacher: TeacherPayload;
  /** Creates a new trial request campaign. */
  createTrialRequestCampaign: TrialRequestCampaignPayload;
  /** Creates a new user. */
  createUser: UserPayload;
  /** Creates a new video on demand. */
  createVideoOnDemand: CreateVideoOnDemandPayload;
  /** Creates a new widget template. */
  createWidgetTemplate: WidgetTemplatePayload;
  /**
   * Deletes an existing attachment. This also deletes the file permanently from the storage
   * service.
   */
  deleteAttachment: Attachment;
  /** Deletes an existing node. */
  deleteNode: DeleteNodeResponse;
  /** Deletes a segment. */
  deleteSegment: DeleteSegmentPayload;
  /** Detaches a reference segment from its children. */
  detachSegment: DetachSegmentPayload;
  /** Finishes the upload for a new video on demand. */
  finishUploadingVideoOnDemand: SuccessResponse;
  /** Marks the current assignment as forfeited; answers are revealed, but no more attempts are allowed. */
  forfeitAssignment: AssignmentPayload;
  /** Generates a new token for registering Canvas credentials. */
  generateCanvasCredentialsToken: CanvasCredentialsTokenResponse;
  /** Generates a new tenant record JWT for a given site ID. */
  generateMicrosoftTenantToken: GenerateMicrosoftTenantTokenResponse;
  /** Generates a new PDF with the requested data and payload. */
  generatePdf: GeneratedPdfPayload;
  /** Generates the specified number of students. */
  generateStudents: GenerateStudentsPayload;
  generateVodCaptions: VideoOnDemandPayload;
  /** The first step when importing standard sets: gets the URL to upload the CSV to. */
  getStandardSetImportUrl: SignedUploadResult;
  /** Grades an existing assignment submission. */
  gradeAssignmentSubmission: AssignmentSubmissionPayload;
  /** Ignores a trial request. */
  ignoreTrialRequest: TrialRequestPayload;
  /** Impersonate another user. */
  impersonate: LoginResponse;
  /** Imports grading categories from the available provider */
  importGradeCategories: ClassPayload;
  /** Imports the list of students from the attached spreadsheet. */
  importStudents: GenerateStudentsPayload;
  /** Invites a student to a class. Either adds an existing student to the class, or sends an email invitation. */
  inviteStudent: InviteStudentPayload;
  /** Invites an existing Student ID / Teacher ID pair. */
  inviteStudentID: ClassPayload;
  /** Invites an existing teacher to help manage an existing class. */
  inviteTeacherToClass: ClassPayload;
  /** Called from a student account. Joins a class by class code. */
  joinClass: ClassPayload;
  /** Likes a node on behalf of the user. */
  likeNode: SuccessResponse;
  /** Logs in an existing user. */
  login: LoginResponse;
  /** Logs in as the student user related to the teacher. */
  loginAsStudent: LoginResponse;
  /** Logs the user in with a Clever account. */
  loginWithClever: LoginResponse;
  /** Logs the user in with a Google account, creating one if it does not exist. */
  loginWithGoogle: LoginResponse;
  /** Logs the user in with a MSAL account, creating one if it does not exist. */
  loginWithMSAL: LoginResponse;
  /** Merges two student accounts together. */
  mergeStudent: SuccessResponse;
  /** Moves a student from one class to another. */
  moveStudentClass: MoveStudentClassPayload;
  /** Moves a teacher and their dependencies to a new site. */
  moveTeacher: TeacherPayload;
  /** Publishes an existing course version. */
  publishCourseVersion: CourseVersionPayload;
  /** Marks notifications as read / unread. */
  readNotifications: ReadNotificationsPayload;
  /** Records the passed Canvas credentials for the passed token. */
  recordCanvasCredentials: SuccessResponse;
  /** Records a collection of interactions for the current user. */
  recordInteractions: RecordInteractionsPayload;
  /** Records the Microsoft Tenant ID given a JWT and a tenant ID. */
  recordMicrosoftTenant: SuccessResponse;
  /** Registers a new user. */
  register: LoginResponse;
  /** Rejects a comment. */
  rejectComment: CommentPayload;
  /** Removes a set of recipients from a direct message thread. */
  removeDirectMessageThreadRecipients: DirectMessageThreadPayload;
  /** Removes a student from an existing class. Does not remove the student. */
  removeStudentFromClass: ClassPayload;
  /** Removes an existing teacher from an existing class. */
  removeTeacherFromClass: ClassPayload;
  /** Requests a password reset for a user. */
  requestResetPassword: SuccessResponse;
  /** Resets assignment submissions for a given student and assignment. */
  resetAssignmentSubmissions: ResetAssignmentSubmissionsPayload;
  /** Resets a generated assignment to its default generated configuration. */
  resetGeneratedAssignment: AssignmentPayload;
  /** Resets an assignment submission to its default generated configuration. */
  resetGeneratedAssignmentSubmission: AssignmentSubmissionPayload;
  /** Resets a user's password. */
  resetPassword: SuccessResponse;
  /** Restores an already-deleted node. */
  restoreNode: RestoreNodeResponse;
  /** Retries the specified submission. */
  retrySubmission: AssignmentSubmissionPayload;
  /** Runs a grade report, with optional classId overrides. */
  runGradeReport: ReportJobPayload;
  /** Saves submission data for the specified assignment ID. */
  saveAssignmentSubmission: AssignmentSubmissionAndAssignmentPayload;
  /** The second step when importing standard sets: starts the import job. */
  startStandardSetImport: StartStandardSetImportPayload;
  /** Uploads captions to an existing Video on Demand. */
  storeVodCaptions: VideoOnDemandPayload;
  /** Submits a new answered question. */
  submitAnsweredQuestion: SuccessResponse;
  /** Submits the specified assignment. */
  submitAssignment: AssignmentSubmissionPayload;
  /** Enables class-sync for an assignment. */
  syncAssignment: AssignmentPayload;
  syncSegment: ClassPayload;
  /** Tests Canvas health (this is a workaround because Canvas does not follow OAuth2 spec and redirect on errors). */
  testCanvasHealth: CanvasHealthPayload;
  /** Requests a new trial. */
  trialSignup: TrialRequestPayload;
  /** Unarchives the specified class. */
  unarchiveClass: ClassPayload;
  /** Unlikes a node on behalf of the user. */
  unlikeNode: SuccessResponse;
  /** Unlinks the specified LinkedAccount from the user's account. */
  unlinkAccount: UserPayload;
  /** Updates an existing announcement. */
  updateAnnouncement: AnnouncementPayload;
  /** Updates an existing answered question. */
  updateAnsweredQuestion: AnsweredQuestionPayload;
  /** Updates an existing answered question contributor. */
  updateAnsweredQuestionContributor: AnsweredQuestionContributorPayload;
  /** Updates an existing assignment. */
  updateAssignment: AssignmentPayload;
  /** Updates an existing assignment category. */
  updateAssignmentCategory: AssignmentCategoryPayload;
  /** Updates the assignment configuration for a class. */
  updateAssignmentClassConfiguration: AssignmentClassConfigurationPayload;
  /** Updates an existing assignment template. */
  updateAssignmentTemplate: AssignmentTemplatePayload;
  /** Updates an existing Class. */
  updateClass: ClassPayload;
  /** Updates an existing comment. */
  updateComment: CommentPayload;
  /** Updates an existing course. */
  updateCourse: CoursePayload;
  /** Updates an existing course version. */
  updateCourseVersion: CourseVersionPayload;
  /** Updates an existing district. */
  updateDistrict: DistrictPayload;
  /** Updates an existing license. */
  updateLicense: LicensePayload;
  /** Updates an existing subscription. */
  updateLicenseSubscription: LicenseSubscriptionPayload;
  /** Updates tags on a specific node that supports it. */
  updateNodeTags: UpdateNodeTagsPayload;
  /** Updates the current user's (or specified JWT) notification preferences. */
  updateNotificationPreferences: UserPayload;
  /** Updates an existing rubric template. */
  updateRubricTemplate: RubricTemplatePayload;
  /** Updates an existing saved report. */
  updateSavedReport: SavedReportPayload;
  /** Updates the completion status for the passed segment and student pairs. */
  updateSegmentCompletion: ClassPayload;
  /** Updates an existing site. */
  updateSite: SitePayload;
  /** Updates the current site-wide banner message. */
  updateSiteWideBanner?: Maybe<SiteWideBanner>;
  /** Updates an existing standard set. */
  updateStandardSet: StandardSetPayload;
  /** Updates an existing Student. */
  updateStudent: StudentPayload;
  /** Updates an existing tag. */
  updateTag: TagPayload;
  /** Updates an existing teacher. */
  updateTeacher: TeacherPayload;
  /** Updates the permissions on an existing teacher inside an existing class. */
  updateTeacherClassPermissions: ClassPayload;
  /** Updates an existing trial request campaign. */
  updateTrialRequestCampaign: TrialRequestCampaignPayload;
  /** Updates an existing user. */
  updateUser: UserPayload;
  /** Updates detailed logging settings for a user. */
  updateUserDetailedLogging: UserPayload;
  /** Updates an existing video on demand. */
  updateVideoOnDemand: VideoOnDemandPayload;
  /** Updates an existing widget template. */
  updateWidgetTemplate: WidgetTemplatePayload;
  /**
   * Uses the passed assignment submission as the "primary" submission.
   * Undeletes the passed submission and deletes the other submissions for the student / class
   * / assignment combination.
   */
  useAssignmentSubmission: AssignmentPayload;
};


export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


export type MutationApproveCommentArgs = {
  input: ApproveCommentInput;
};


export type MutationApproveTrialRequestArgs = {
  input: ApproveTrialRequestInput;
};


export type MutationArchiveClassArgs = {
  input: ArchiveClassInput;
};


export type MutationAssociateCanvasArgs = {
  input: LoginWithCanvasInput;
};


export type MutationAssociateCleverArgs = {
  input: LoginWithCleverInput;
};


export type MutationAssociateGoogleArgs = {
  input: LoginWithGoogleInput;
};


export type MutationAssociateMsalArgs = {
  input: LoginWithMsalInput;
};


export type MutationAssociateSchoologyArgs = {
  input: LoginWithSchoologyInput;
};


export type MutationAttachClassSyncArgs = {
  input: AttachClassSyncInput;
};


export type MutationAttachClassSyncStudentAccountArgs = {
  input: AttachClassSyncStudentAccountInput;
};


export type MutationAttachFileArgs = {
  input: AttachFileInput;
};


export type MutationAttachGenericFileArgs = {
  input: SignedUploadInput;
};


export type MutationAutoGradeAssignmentSubmissionArgs = {
  input: AutoGradeAssignmentSubmissionInput;
};


export type MutationBulkUpdateAssignmentsInClassArgs = {
  input: BulkUpdateAssignmentsInClassInput;
};


export type MutationCancelLicenseSubscriptionArgs = {
  input: CancelLicenseSubscriptionInput;
};


export type MutationCommentOnNodeArgs = {
  input: CommentOnNodeInput;
};


export type MutationCopyAssignmentCategoriesArgs = {
  input: CopyAssignmentCategoriesInput;
};


export type MutationCopySegmentSettingsArgs = {
  input: CopySegmentSettingsInput;
};


export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput;
};


export type MutationCreateAnsweredQuestionContributorArgs = {
  input: CreateAnsweredQuestionContributorInput;
};


export type MutationCreateAssignmentArgs = {
  input: CreateAssignmentInput;
};


export type MutationCreateAssignmentCategoryArgs = {
  input: CreateAssignmentCategoryInput;
};


export type MutationCreateAssignmentTemplateArgs = {
  input: AssignmentTemplateInput;
};


export type MutationCreateAssociatedClassAssignmentArgs = {
  input: AssociatedClassAssignmentInput;
};


export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationCreateClassWithPlacedContentArgs = {
  input: CreateClassWithPlacedContentInput;
};


export type MutationCreateCourseArgs = {
  input: CourseInput;
};


export type MutationCreateCourseVersionArgs = {
  input: CreateCourseVersionInput;
};


export type MutationCreateDirectMessageThreadArgs = {
  input: CreateDirectMessageThreadInput;
};


export type MutationCreateDistrictArgs = {
  input: DistrictInput;
};


export type MutationCreateIndividualSubscriptionArgs = {
  input: IndividualSubscriptionInput;
};


export type MutationCreateLicenseArgs = {
  input: LicenseInput;
};


export type MutationCreateLicenseSubscriptionArgs = {
  input: LicenseSubscriptionInput;
};


export type MutationCreateOrUpdateSegmentArgs = {
  input: SegmentInput;
};


export type MutationCreateRubricTemplateArgs = {
  input: RubricTemplateInput;
};


export type MutationCreateSavedReportArgs = {
  input: CreateSavedReportInput;
};


export type MutationCreateSiteArgs = {
  input: SiteInput;
};


export type MutationCreateStandardSetArgs = {
  input: CreateStandardSetInput;
};


export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationCreateTeacherArgs = {
  input: TeacherInput;
};


export type MutationCreateTrialRequestCampaignArgs = {
  input: TrialRequestCampaignInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateVideoOnDemandArgs = {
  input: CreateVideoOnDemandInput;
};


export type MutationCreateWidgetTemplateArgs = {
  input: WidgetTemplateInput;
};


export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput;
};


export type MutationDeleteNodeArgs = {
  id: Scalars['ID'];
  permanent?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput;
};


export type MutationDetachSegmentArgs = {
  input: DetachSegmentInput;
};


export type MutationFinishUploadingVideoOnDemandArgs = {
  input: FinishUploadingVideoOnDemandInput;
};


export type MutationForfeitAssignmentArgs = {
  input: ForfeitAssignmentInput;
};


export type MutationGenerateCanvasCredentialsTokenArgs = {
  input: GenerateCanvasCredentialsTokenInput;
};


export type MutationGenerateMicrosoftTenantTokenArgs = {
  input: GenerateMicrosoftTenantTokenInput;
};


export type MutationGeneratePdfArgs = {
  input: GeneratePdfInput;
};


export type MutationGenerateStudentsArgs = {
  input: GenerateStudentsInput;
};


export type MutationGenerateVodCaptionsArgs = {
  input: GenerateVodCaptionsInput;
};


export type MutationGetStandardSetImportUrlArgs = {
  input: SignedUploadInput;
};


export type MutationGradeAssignmentSubmissionArgs = {
  input: GradeAssignmentSubmissionInput;
};


export type MutationIgnoreTrialRequestArgs = {
  id: Scalars['ID'];
};


export type MutationImpersonateArgs = {
  userId: Scalars['ID'];
};


export type MutationImportGradeCategoriesArgs = {
  input: ImportGradeCategoriesInput;
};


export type MutationImportStudentsArgs = {
  input: ImportStudentsInput;
};


export type MutationInviteStudentArgs = {
  input: InviteStudentInput;
};


export type MutationInviteStudentIdArgs = {
  input: InviteStudentIdInput;
};


export type MutationInviteTeacherToClassArgs = {
  input: InviteTeacherToClassInput;
};


export type MutationJoinClassArgs = {
  input: JoinClassInput;
};


export type MutationLikeNodeArgs = {
  input: LikeNodeInput;
};


export type MutationLoginArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginWithCleverArgs = {
  input: LoginWithCleverInput;
};


export type MutationLoginWithGoogleArgs = {
  input: LoginWithGoogleInput;
};


export type MutationLoginWithMsalArgs = {
  input: LoginWithMsalInput;
};


export type MutationMergeStudentArgs = {
  input: MergeStudentInput;
};


export type MutationMoveStudentClassArgs = {
  input: MoveStudentClassInput;
};


export type MutationMoveTeacherArgs = {
  input: MoveTeacherInput;
};


export type MutationPublishCourseVersionArgs = {
  input: PublishCourseVersionInput;
};


export type MutationReadNotificationsArgs = {
  input: ReadNotificationsInput;
};


export type MutationRecordCanvasCredentialsArgs = {
  input: RecordCanvasCredentialsInput;
};


export type MutationRecordInteractionsArgs = {
  input: RecordInteractionsInput;
};


export type MutationRecordMicrosoftTenantArgs = {
  input: RecordMicrosoftTenantInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRejectCommentArgs = {
  input: RejectCommentInput;
};


export type MutationRemoveDirectMessageThreadRecipientsArgs = {
  input: RemoveDirectMessageThreadRecipientsInput;
};


export type MutationRemoveStudentFromClassArgs = {
  input: RemoveStudentFromClassInput;
};


export type MutationRemoveTeacherFromClassArgs = {
  input: RemoveTeacherFromClassInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResetAssignmentSubmissionsArgs = {
  input: ResetAssignmentSubmissionsInput;
};


export type MutationResetGeneratedAssignmentArgs = {
  input: ResetGeneratedAssignmentInput;
};


export type MutationResetGeneratedAssignmentSubmissionArgs = {
  input: ResetGeneratedAssignmentSubmissionInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRestoreNodeArgs = {
  id: Scalars['ID'];
};


export type MutationRetrySubmissionArgs = {
  input: RetrySubmissionInput;
};


export type MutationRunGradeReportArgs = {
  input: RunGradeReportInput;
};


export type MutationSaveAssignmentSubmissionArgs = {
  input: SaveAssignmentSubmissionInput;
};


export type MutationStartStandardSetImportArgs = {
  input: StartStandardSetImportInput;
};


export type MutationStoreVodCaptionsArgs = {
  input: StoreVodCaptionsInput;
};


export type MutationSubmitAnsweredQuestionArgs = {
  input: SubmitAnsweredQuestionInput;
};


export type MutationSubmitAssignmentArgs = {
  input: SubmitAssignmentInput;
};


export type MutationSyncAssignmentArgs = {
  input: SyncAssignmentInput;
};


export type MutationSyncSegmentArgs = {
  input: SyncSegmentInput;
};


export type MutationTestCanvasHealthArgs = {
  input: CanvasHealthInput;
};


export type MutationTrialSignupArgs = {
  input: TrialSignupInput;
};


export type MutationUnarchiveClassArgs = {
  input: UnarchiveClassInput;
};


export type MutationUnlikeNodeArgs = {
  input: UnlikeNodeInput;
};


export type MutationUnlinkAccountArgs = {
  input: UnlinkAccountInput;
};


export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput;
};


export type MutationUpdateAnsweredQuestionArgs = {
  input: UpdateAnsweredQuestionInput;
};


export type MutationUpdateAnsweredQuestionContributorArgs = {
  input: UpdateAnsweredQuestionContributorInput;
};


export type MutationUpdateAssignmentArgs = {
  input: UpdateAssignmentInput;
};


export type MutationUpdateAssignmentCategoryArgs = {
  input: UpdateAssignmentCategoryInput;
};


export type MutationUpdateAssignmentClassConfigurationArgs = {
  input: UpdateAssignmentClassConfigurationInput;
};


export type MutationUpdateAssignmentTemplateArgs = {
  input: UpdateAssignmentTemplateInput;
};


export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput;
};


export type MutationUpdateCourseVersionArgs = {
  input: UpdateCourseVersionInput;
};


export type MutationUpdateDistrictArgs = {
  input: UpdateDistrictInput;
};


export type MutationUpdateLicenseArgs = {
  input: UpdateLicenseInput;
};


export type MutationUpdateLicenseSubscriptionArgs = {
  input: UpdateLicenseSubscriptionInput;
};


export type MutationUpdateNodeTagsArgs = {
  input: UpdateNodeTagsInput;
};


export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdatePreferencesInput;
};


export type MutationUpdateRubricTemplateArgs = {
  input: UpdateRubricTemplateInput;
};


export type MutationUpdateSavedReportArgs = {
  input: UpdateSavedReportInput;
};


export type MutationUpdateSegmentCompletionArgs = {
  input: UpdateSegmentCompletionInput;
};


export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput;
};


export type MutationUpdateSiteWideBannerArgs = {
  input: UpdateSiteWideBannerInput;
};


export type MutationUpdateStandardSetArgs = {
  input: UpdateStandardSetInput;
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTeacherArgs = {
  input: UpdateTeacherInput;
};


export type MutationUpdateTeacherClassPermissionsArgs = {
  input: UpdateTeacherClassPermissionsInput;
};


export type MutationUpdateTrialRequestCampaignArgs = {
  input: UpdateTrialRequestCampaignInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserDetailedLoggingArgs = {
  input: UpdateUserDetailedLoggingInput;
};


export type MutationUpdateVideoOnDemandArgs = {
  input: UpdateVideoOnDemandInput;
};


export type MutationUpdateWidgetTemplateArgs = {
  input: UpdateWidgetTemplateInput;
};


export type MutationUseAssignmentSubmissionArgs = {
  input: UseAssignmentSubmissionInput;
};

/** The node interface is a fundamental interface that all concrete types inherit from. */
export type Node = {
  /** The generic relay-compliant identifier for this node. When decoded, contains the type name and numeric ID. */
  id: Scalars['ID'];
};

/** A notification represents a notification sent to a user. */
export type Notification = Node & {
  __typename?: 'Notification';
  /** The date the notification was sent. */
  createdAt: Scalars['DateTime'];
  /** The description provided with the notification. Supports markdown. */
  description: Scalars['String'];
  /** Any extra information provided with the notification. Supports markdown. */
  extra?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Whether or not the notification is read. */
  isRead?: Maybe<Scalars['Boolean']>;
  /** An option link passed with the notification. */
  link?: Maybe<NotificationLink>;
  /** The title of the notification. */
  title: Scalars['String'];
  /** The date the notification was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The user the notification was sent to. */
  user: User;
  /** The ID of the user the notification was sent to. */
  userId: Scalars['ID'];
};

export type NotificationChannelInfo = {
  __typename?: 'NotificationChannelInfo';
  channel: Scalars['String'];
  iconName: Scalars['String'];
  name: Scalars['String'];
};

export type NotificationLink = {
  __typename?: 'NotificationLink';
  instructions?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  universalPath: Scalars['String'];
};

export type NotificationPreference = {
  __typename?: 'NotificationPreference';
  description: Scalars['String'];
  disabledChannels: Array<Scalars['String']>;
  group: Scalars['String'];
  name: Scalars['String'];
  notificationType: Scalars['String'];
};

export type NotificationsConnection = Connection & {
  __typename?: 'NotificationsConnection';
  edges: Array<NotificationsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type NotificationsEdge = Edge & {
  __typename?: 'NotificationsEdge';
  cursor: Scalars['String'];
  node: Notification;
};

export type NumAttemptsMetricInput = {
  place?: InputMaybe<Scalars['Boolean']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
};

export type PageMetadata = {
  __typename?: 'PageMetadata';
  author?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  publisher?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum Permission {
  AcceptInvite = 'ACCEPT_INVITE',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  CancelLicenseSubscription = 'CANCEL_LICENSE_SUBSCRIPTION',
  CancelOwnLicenseSubscription = 'CANCEL_OWN_LICENSE_SUBSCRIPTION',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  CopyCourseContentFrom = 'COPY_COURSE_CONTENT_FROM',
  CopyCourseContentTo = 'COPY_COURSE_CONTENT_TO',
  CopyOwnCourseContentFrom = 'COPY_OWN_COURSE_CONTENT_FROM',
  CopyOwnCourseContentTo = 'COPY_OWN_COURSE_CONTENT_TO',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateAssignmentTemplate = 'CREATE_ASSIGNMENT_TEMPLATE',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateClass = 'CREATE_CLASS',
  CreateCourse = 'CREATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  CreateDistrict = 'CREATE_DISTRICT',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateLicense = 'CREATE_LICENSE',
  CreateLicenseSubscription = 'CREATE_LICENSE_SUBSCRIPTION',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateOwnAssignment = 'CREATE_OWN_ASSIGNMENT',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  CreateSavedReport = 'CREATE_SAVED_REPORT',
  CreateSite = 'CREATE_SITE',
  CreateStudent = 'CREATE_STUDENT',
  CreateTeacher = 'CREATE_TEACHER',
  CreateUser = 'CREATE_USER',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  DeleteAnsweredQuestion = 'DELETE_ANSWERED_QUESTION',
  DeleteAnsweredQuestionContributor = 'DELETE_ANSWERED_QUESTION_CONTRIBUTOR',
  DeleteClass = 'DELETE_CLASS',
  DeleteComment = 'DELETE_COMMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteLicenseSubscription = 'DELETE_LICENSE_SUBSCRIPTION',
  DeleteOwnAnnouncement = 'DELETE_OWN_ANNOUNCEMENT',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  DeleteOwnClassSync = 'DELETE_OWN_CLASS_SYNC',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  DeleteOwnDirectMessageThread = 'DELETE_OWN_DIRECT_MESSAGE_THREAD',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  DeleteOwnLicenseSubscription = 'DELETE_OWN_LICENSE_SUBSCRIPTION',
  DeleteOwnRubricTemplate = 'DELETE_OWN_RUBRIC_TEMPLATE',
  DeleteOwnSavedReport = 'DELETE_OWN_SAVED_REPORT',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  DeleteSavedReport = 'DELETE_SAVED_REPORT',
  DeleteSegment = 'DELETE_SEGMENT',
  DeleteSite = 'DELETE_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteStandardSet = 'DELETE_STANDARD_SET',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteTag = 'DELETE_TAG',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  DeleteTrialRequestCampaign = 'DELETE_TRIAL_REQUEST_CAMPAIGN',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteWidgetTemplate = 'DELETE_WIDGET_TEMPLATE',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  GeneratePdf = 'GENERATE_PDF',
  GetPageMetadata = 'GET_PAGE_METADATA',
  GradeAssignmentSubmission = 'GRADE_ASSIGNMENT_SUBMISSION',
  GradeOwnAssignmentSubmission = 'GRADE_OWN_ASSIGNMENT_SUBMISSION',
  Impersonate = 'IMPERSONATE',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  InviteStudent = 'INVITE_STUDENT',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  JoinClass = 'JOIN_CLASS',
  ListAnsweredQuestions = 'LIST_ANSWERED_QUESTIONS',
  ListClass = 'LIST_CLASS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ListClassLicenseSubscriptions = 'LIST_CLASS_LICENSE_SUBSCRIPTIONS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  ListClassSyncStudents = 'LIST_CLASS_SYNC_STUDENTS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListComments = 'LIST_COMMENTS',
  ListCourse = 'LIST_COURSE',
  ListDistrict = 'LIST_DISTRICT',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  ListInteractions = 'LIST_INTERACTIONS',
  ListLicenses = 'LIST_LICENSES',
  ListLicenseSubscriptions = 'LIST_LICENSE_SUBSCRIPTIONS',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListOwnClassLicenseSubscriptions = 'LIST_OWN_CLASS_LICENSE_SUBSCRIPTIONS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListOwnClassSyncStudents = 'LIST_OWN_CLASS_SYNC_STUDENTS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  ListOwnUserLicenseSubscriptions = 'LIST_OWN_USER_LICENSE_SUBSCRIPTIONS',
  ListSegments = 'LIST_SEGMENTS',
  ListSite = 'LIST_SITE',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListStudent = 'LIST_STUDENT',
  ListTags = 'LIST_TAGS',
  ListTeacher = 'LIST_TEACHER',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ListUser = 'LIST_USER',
  ListUserLicenseSubscriptions = 'LIST_USER_LICENSE_SUBSCRIPTIONS',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  Login = 'LOGIN',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ManageAnsweredQuestions = 'MANAGE_ANSWERED_QUESTIONS',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageAssignmentCategories = 'MANAGE_ASSIGNMENT_CATEGORIES',
  ManageAssignmentClassConfiguration = 'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageAssignmentTemplates = 'MANAGE_ASSIGNMENT_TEMPLATES',
  ManageBanner = 'MANAGE_BANNER',
  ManageClassSegmentEnabled = 'MANAGE_CLASS_SEGMENT_ENABLED',
  ManageClassSegmentLocked = 'MANAGE_CLASS_SEGMENT_LOCKED',
  ManageClassSegmentStructure = 'MANAGE_CLASS_SEGMENT_STRUCTURE',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageLogs = 'MANAGE_LOGS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  ManageOwnAssignmentCategories = 'MANAGE_OWN_ASSIGNMENT_CATEGORIES',
  ManageOwnAssignmentClassConfiguration = 'MANAGE_OWN_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageOwnClassSegmentEnabled = 'MANAGE_OWN_CLASS_SEGMENT_ENABLED',
  ManageOwnClassSegmentLocked = 'MANAGE_OWN_CLASS_SEGMENT_LOCKED',
  ManageOwnClassSegmentStructure = 'MANAGE_OWN_CLASS_SEGMENT_STRUCTURE',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageStandardSets = 'MANAGE_STANDARD_SETS',
  ManageTrialRequestCampaigns = 'MANAGE_TRIAL_REQUEST_CAMPAIGNS',
  ManageWidgetTemplates = 'MANAGE_WIDGET_TEMPLATES',
  MergeAccounts = 'MERGE_ACCOUNTS',
  MessageOwnUser = 'MESSAGE_OWN_USER',
  MessageUser = 'MESSAGE_USER',
  ModerateComments = 'MODERATE_COMMENTS',
  MoveTeacher = 'MOVE_TEACHER',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  Register = 'REGISTER',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  RequestTrial = 'REQUEST_TRIAL',
  ResetAssignmentSubmission = 'RESET_ASSIGNMENT_SUBMISSION',
  ResetOwnAssignmentSubmission = 'RESET_OWN_ASSIGNMENT_SUBMISSION',
  ResetPassword = 'RESET_PASSWORD',
  RestoreAnsweredQuestion = 'RESTORE_ANSWERED_QUESTION',
  RestoreAnsweredQuestionContributor = 'RESTORE_ANSWERED_QUESTION_CONTRIBUTOR',
  RestoreClass = 'RESTORE_CLASS',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreLicenseSubscription = 'RESTORE_LICENSE_SUBSCRIPTION',
  RestoreOwnAnnouncement = 'RESTORE_OWN_ANNOUNCEMENT',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  RestoreOwnLicenseSubscription = 'RESTORE_OWN_LICENSE_SUBSCRIPTION',
  RestoreOwnRubricTemplate = 'RESTORE_OWN_RUBRIC_TEMPLATE',
  RestoreOwnSavedReport = 'RESTORE_OWN_SAVED_REPORT',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  RestoreSavedReport = 'RESTORE_SAVED_REPORT',
  RestoreSegment = 'RESTORE_SEGMENT',
  RestoreSite = 'RESTORE_SITE',
  RestoreStandardSet = 'RESTORE_STANDARD_SET',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreTag = 'RESTORE_TAG',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RestoreTrialRequestCampaign = 'RESTORE_TRIAL_REQUEST_CAMPAIGN',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreWidgetTemplate = 'RESTORE_WIDGET_TEMPLATE',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  RunReport = 'RUN_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunStudentReport = 'RUN_STUDENT_REPORT',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  SaveOwnAssignmentSubmission = 'SAVE_OWN_ASSIGNMENT_SUBMISSION',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  Search = 'SEARCH',
  SearchAnsweredQuestions = 'SEARCH_ANSWERED_QUESTIONS',
  SearchAnsweredQuestionContributors = 'SEARCH_ANSWERED_QUESTION_CONTRIBUTORS',
  SearchCourses = 'SEARCH_COURSES',
  SearchDistricts = 'SEARCH_DISTRICTS',
  SearchSites = 'SEARCH_SITES',
  SearchUsers = 'SEARCH_USERS',
  SubmitAnsweredQuestion = 'SUBMIT_ANSWERED_QUESTION',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  SyncAssignment = 'SYNC_ASSIGNMENT',
  SyncOwnAssignment = 'SYNC_OWN_ASSIGNMENT',
  SyncOwnSegment = 'SYNC_OWN_SEGMENT',
  SyncSegment = 'SYNC_SEGMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  UpdateAssignmentTemplate = 'UPDATE_ASSIGNMENT_TEMPLATE',
  UpdateClass = 'UPDATE_CLASS',
  UpdateClassSettings = 'UPDATE_CLASS_SETTINGS',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateCourse = 'UPDATE_COURSE',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateLicense = 'UPDATE_LICENSE',
  UpdateLicenseSubscription = 'UPDATE_LICENSE_SUBSCRIPTION',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateOwnAssignmentTemplate = 'UPDATE_OWN_ASSIGNMENT_TEMPLATE',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  UpdateOwnClassSettings = 'UPDATE_OWN_CLASS_SETTINGS',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  UpdateOwnSavedReport = 'UPDATE_OWN_SAVED_REPORT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdateSavedReport = 'UPDATE_SAVED_REPORT',
  UpdateSite = 'UPDATE_SITE',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateTags = 'UPDATE_TAGS',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  UpdateUser = 'UPDATE_USER',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  UseInReport = 'USE_IN_REPORT',
  UseOwnInReport = 'USE_OWN_IN_REPORT',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  ViewAnsweredQuestion = 'VIEW_ANSWERED_QUESTION',
  ViewAnsweredQuestionContributor = 'VIEW_ANSWERED_QUESTION_CONTRIBUTOR',
  ViewAssignmentAnswers = 'VIEW_ASSIGNMENT_ANSWERS',
  ViewAssignmentReport = 'VIEW_ASSIGNMENT_REPORT',
  ViewAssignmentTemplate = 'VIEW_ASSIGNMENT_TEMPLATE',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewClass = 'VIEW_CLASS',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  ViewComment = 'VIEW_COMMENT',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewLicense = 'VIEW_LICENSE',
  ViewLicenseSubscription = 'VIEW_LICENSE_SUBSCRIPTION',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnAnnouncement = 'VIEW_OWN_ANNOUNCEMENT',
  ViewOwnAssignment = 'VIEW_OWN_ASSIGNMENT',
  ViewOwnAssignmentReport = 'VIEW_OWN_ASSIGNMENT_REPORT',
  ViewOwnAssignmentSubmission = 'VIEW_OWN_ASSIGNMENT_SUBMISSION',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewOwnClassSync = 'VIEW_OWN_CLASS_SYNC',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewOwnCourse = 'VIEW_OWN_COURSE',
  ViewOwnDirectMessageThread = 'VIEW_OWN_DIRECT_MESSAGE_THREAD',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  ViewOwnLicenseSubscription = 'VIEW_OWN_LICENSE_SUBSCRIPTION',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewOwnRubricTemplate = 'VIEW_OWN_RUBRIC_TEMPLATE',
  ViewOwnSavedReport = 'VIEW_OWN_SAVED_REPORT',
  ViewOwnSegment = 'VIEW_OWN_SEGMENT',
  ViewOwnSite = 'VIEW_OWN_SITE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ViewOwnTag = 'VIEW_OWN_TAG',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ViewQuestionSegment = 'VIEW_QUESTION_SEGMENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewSavedReport = 'VIEW_SAVED_REPORT',
  ViewSegment = 'VIEW_SEGMENT',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  ViewSite = 'VIEW_SITE',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ViewStandardSet = 'VIEW_STANDARD_SET',
  ViewStudent = 'VIEW_STUDENT',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewTag = 'VIEW_TAG',
  ViewTeacher = 'VIEW_TEACHER',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  ViewTrialRequestCampaign = 'VIEW_TRIAL_REQUEST_CAMPAIGN',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserName = 'VIEW_USER_NAME',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewWidgetTemplate = 'VIEW_WIDGET_TEMPLATE'
}

export type PreferencesInput = {
  /** The name of the channels to disable. */
  disabledChannels: Array<Scalars['String']>;
  /** The identifier for the type of notification. */
  notificationType: Scalars['String'];
};

export type PublishCourseVersionInput = {
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** Gets all answered question contributors in the system. */
  answeredQuestionContributors: AnsweredQuestionContributorsConnection;
  /** Lists all answered questions currently in the system. */
  answeredQuestions: AnsweredQuestionsConnection;
  /** Gets all assignment templates registered in the system. */
  assignmentTemplates: AssignmentTemplatesConnection;
  canvasScopes: Array<Scalars['String']>;
  /** Runs an implementation guide report for the specified class. */
  classImplementationGuideReport: ClassImplementationGuideReport;
  /**
   * Generates a report on the class-level for specific classes and implementation guides.
   * @deprecated Use the new reporting functionality instead.
   */
  classReport: ClassReportResult;
  /** Gets information about the currently available class-sync providers. */
  classSyncProviders: Array<ClassSyncProvider>;
  /** Gets all classes in the system. */
  classes: ClassesConnection;
  /** Gets all comments in the system. */
  comments: CommentsConnection;
  /** Gets all courses currently in the system. */
  courses: CoursesConnection;
  /** The default rubric types to use for new assignments. */
  defaultRubricTypes: Array<Scalars['String']>;
  /** Gets all districts. */
  districts: DistrictsConnection;
  /** Loads an existing Generated PDF by ID, enforcing permissions and accepting the admin token. */
  generatedPdf: GeneratedPdf;
  /** Given a generic path, generates a fetch URL for it. */
  genericFetchUrl: SignedFetchResult;
  googleScopes: Array<Scalars['String']>;
  /** Gets a list of all subscriptions in the system. */
  licenseSubscriptions: LicenseSubscriptionsConnection;
  /** Gets a list of all licenses in the system. */
  licenses: LicensesConnection;
  /** Gets the third-party classes available to the current user. */
  listSyncProviderClasses: Array<ClassSyncProviderClass>;
  msalScopes: Array<Scalars['String']>;
  /** Fetch a specific entity. */
  node?: Maybe<Node>;
  /** The notification channels available to be configured for the user. */
  notificationChannels: Array<NotificationChannelInfo>;
  /** Gets the metadata for the specified page URL. */
  pageMetadata?: Maybe<PageMetadata>;
  /** Runs a report. */
  runReport: Report;
  /** Searches for nodes in the service. */
  search: SearchResultsConnection;
  /** Gets all segments available to the current user. */
  segments: SegmentsConnection;
  siteWideBanner?: Maybe<SiteWideBanner>;
  /** Gets all sites. */
  sites: SitesConnection;
  /** Gets the status of an existing standard set import job. */
  standardSetImportJob?: Maybe<StandardSetImportJobStatus>;
  standardSets: StandardSetsConnection;
  /**
   * Generates a report on the student-level for each implementation guide.
   * @deprecated Use the new reporting functionality instead.
   */
  studentReport: StudentReportResult;
  /** Gets all students in the system. */
  students: StudentsConnection;
  /** Lists all tag types. */
  tagTypes: Array<Scalars['String']>;
  /** Lists all tags. */
  tags: TagsConnection;
  /** The teachers inside the system. */
  teachers: TeachersConnection;
  trialRequestCampaigns: TrialRequestCampaignsConnection;
  trialRequests: TrialRequestsConnection;
  /** Gets all existing detailed logs for the specified user. */
  userDetailedLogs: DetailedLogsConnection;
  /** Get information about the currently-registered user groups. */
  userGroups: Array<UserGroupInfo>;
  /** Gets the notification preferences for the user associated with the specified JWT. */
  userNotificationPreferences: Array<NotificationPreference>;
  /** Gets all users currently registered in the system. */
  users: UsersConnection;
  /** Lists all current video on demand providers. */
  videoOnDemandProviders: Array<VideoOnDemandProvider>;
  /** Lists all current video on demands. */
  videosOnDemand: VideoOnDemandConnection;
  /** Gets the current user. */
  viewer?: Maybe<User>;
  widgetTemplates: WidgetTemplatesConnection;
};


export type QueryAnsweredQuestionContributorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryAnsweredQuestionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AnsweredQuestionsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryAssignmentTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isCalculated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryClassImplementationGuideReportArgs = {
  input: ClassImplementationGuideReportInput;
};


export type QueryClassReportArgs = {
  input: ClassReportInput;
};


export type QueryClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  approval?: InputMaybe<Array<InputMaybe<CommentApproval>>>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryCoursesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isByoc?: InputMaybe<Scalars['Boolean']>;
  isHidden?: InputMaybe<Scalars['Boolean']>;
  isLicensed?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryDistrictsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryGeneratedPdfArgs = {
  id: Scalars['ID'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGenericFetchUrlArgs = {
  path: Scalars['String'];
};


export type QueryLicenseSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  classId?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryLicensesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryListSyncProviderClassesArgs = {
  input: ListSyncProviderClassesInput;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPageMetadataArgs = {
  url: Scalars['String'];
};


export type QueryRunReportArgs = {
  input: RunReportInput;
};


export type QuerySearchArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<SearchFilterInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  query?: InputMaybe<Scalars['String']>;
};


export type QuerySegmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
  types?: InputMaybe<Array<SegmentType>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerySitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryStandardSetImportJobArgs = {
  identifier: Scalars['String'];
};


export type QueryStandardSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryStudentReportArgs = {
  input: StudentReportInput;
};


export type QueryStudentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<TagFilters>;
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  types?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryTrialRequestCampaignsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryTrialRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  status?: InputMaybe<TrialRequestStatus>;
};


export type QueryUserDetailedLogsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  input: DetailedLogsForUserInput;
};


export type QueryUserNotificationPreferencesArgs = {
  jwt: Scalars['String'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryVideosOnDemandArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type QueryWidgetTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type ReadNotificationInput = {
  id: Scalars['ID'];
  read: Scalars['Boolean'];
};

export type ReadNotificationPathInput = {
  read: Scalars['Boolean'];
  universalPath: Scalars['String'];
};

export type ReadNotificationsInput = {
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: InputMaybe<Scalars['Boolean']>;
  /** The notifications to update. */
  notifications?: InputMaybe<Array<ReadNotificationInput>>;
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: InputMaybe<Array<ReadNotificationPathInput>>;
};

export type ReadNotificationsPayload = {
  __typename?: 'ReadNotificationsPayload';
  /** The notifications read / unread by the mutation. */
  notifications: Array<Notification>;
};

export type RecordCanvasCredentialsInput = {
  consumerKey: Scalars['String'];
  consumerSecret: Scalars['String'];
  token: Scalars['String'];
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String'];
};

export type RecordInteractionsInput = {
  interactions: Array<InteractionInput>;
};

export type RecordInteractionsPayload = {
  __typename?: 'RecordInteractionsPayload';
  numRecorded: Scalars['Int'];
};

export type RecordMicrosoftTenantInput = {
  tenantId: Scalars['String'];
  token: Scalars['String'];
};

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
};

export type RejectCommentInput = {
  id: Scalars['ID'];
  /** The reason the comment was rejected. */
  reason: Scalars['String'];
};

export type RelatedTeacherSite = {
  __typename?: 'RelatedTeacherSite';
  site: Site;
  teachers: Array<Teacher>;
};

export type RelatedTeachers = {
  __typename?: 'RelatedTeachers';
  sites: Array<RelatedTeacherSite>;
};

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID'];
  /** An array of user IDs to remove from the thread. */
  userIds: Array<Scalars['ID']>;
};

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID'];
  /** The ID of the student to remove. */
  studentId: Scalars['ID'];
};

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID'];
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID'];
};

export type Report = {
  __typename?: 'Report';
  /** A summary of all metrics for all results returned. */
  allMetrics: ReportMetricSummaries;
  /** A summary of remaining dimensions, if more than 1 is provided. */
  dimensionSummaries: Array<ReportDimensionSummary>;
  /** An array of the raw report results. */
  results: Array<ReportResultItem>;
  /** Additional summaries of the report items. */
  summaries: ReportSummaries;
};

export type ReportDimensionInput = {
  classes?: InputMaybe<ClassesReportDimensionInput>;
  segment?: InputMaybe<SegmentReportDimensionInput>;
  students?: InputMaybe<StudentsReportDimensionInput>;
};

export type ReportDimensionResult = {
  __typename?: 'ReportDimensionResult';
  classes?: Maybe<ClassesReportDimensionResult>;
  segment?: Maybe<SegmentReportDimensionResult>;
  students?: Maybe<StudentsReportDimensionResult>;
};

export type ReportDimensionSummary = {
  __typename?: 'ReportDimensionSummary';
  /** The dimension values for the current summary. */
  dimensions: ReportDimensionResult;
  /** The metric values for the dimension. */
  metrics: ReportMetricSummaries;
};

export type ReportJob = Node & {
  __typename?: 'ReportJob';
  /** The signed URL to the result, if it's available. */
  downloadUrl?: Maybe<Scalars['String']>;
  /** The job ID, in the format "Report[jobType]:[id]" */
  id: Scalars['ID'];
};

export type ReportJobPayload = {
  __typename?: 'ReportJobPayload';
  reportJob?: Maybe<ReportJob>;
};

export type ReportMetricInput = {
  contentVisited?: InputMaybe<ContentVisitedReportMetricInput>;
  grade?: InputMaybe<GradeReportMetricInput>;
  numAttempts?: InputMaybe<NumAttemptsMetricInput>;
  timeOnTask?: InputMaybe<TimeOnTaskReportMetricInput>;
};

export type ReportMetricResult = {
  __typename?: 'ReportMetricResult';
  contentVisited?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  numAttempts?: Maybe<Scalars['Float']>;
  timeOnTask?: Maybe<Scalars['Float']>;
};

export type ReportMetricSummaries = {
  __typename?: 'ReportMetricSummaries';
  all: Array<ReportMetricResult>;
  average: ReportMetricResult;
  max: ReportMetricResult;
  min: ReportMetricResult;
  std: ReportMetricResult;
  sum: ReportMetricResult;
};

export type ReportResultItem = {
  __typename?: 'ReportResultItem';
  /** The dimension values for the current result item. */
  dimensions: ReportDimensionResult;
  /** The summary of metric values for the current result item. */
  metrics: ReportMetricSummaries;
};

export type ReportSegmentSummary = {
  __typename?: 'ReportSegmentSummary';
  segments: Array<ReportSegmentSummaryItem>;
};

export type ReportSegmentSummaryItem = {
  __typename?: 'ReportSegmentSummaryItem';
  children: Array<ReportSegmentSummaryItem>;
  items: Array<ReportResultItem>;
  segment: Segment;
  summary: ReportMetricSummaries;
};

export type ReportStandardsSummary = {
  __typename?: 'ReportStandardsSummary';
  standards: Array<ReportStandardsSummaryItem>;
};

export type ReportStandardsSummaryInput = {
  standardSetId: Scalars['ID'];
};

export type ReportStandardsSummaryItem = {
  __typename?: 'ReportStandardsSummaryItem';
  children: Array<ReportStandardsSummaryItem>;
  items: Array<ReportResultItem>;
  summary: ReportMetricSummaries;
  tag: Tag;
};

export type ReportSummaries = {
  __typename?: 'ReportSummaries';
  segments: ReportSegmentSummary;
  standards: ReportStandardsSummary;
};


export type ReportSummariesStandardsArgs = {
  input: ReportStandardsSummaryInput;
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String'];
};

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the class to reset submissions for, optionally. */
  classId?: InputMaybe<Scalars['ID']>;
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID'];
  /**
   * If this is true, the latest submission (with its answers) is preserved.
   * The grade will still be reset, the created date will be reset to now, and the
   * submitted status will be reset.
   */
  preserveLatestSubmission?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID'];
};

export type ResetAssignmentSubmissionsPayload = {
  __typename?: 'ResetAssignmentSubmissionsPayload';
  /** An array of submission IDs that were deleted. */
  submissionIds: Array<Scalars['ID']>;
};

export type ResetGeneratedAssignmentInput = {
  /** The ID of the assignment to reset. */
  id: Scalars['ID'];
};

export type ResetGeneratedAssignmentSubmissionInput = {
  /** The ID of the class associated with the submission. */
  classId: Scalars['ID'];
  /** The ID of the assignment. */
  id: Scalars['ID'];
  /** The ID of the student associated with the submission. */
  studentId: Scalars['ID'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String'];
  password: Scalars['String'];
};

export type RestoreNodeResponse = {
  __typename?: 'RestoreNodeResponse';
  /** The ID of the restored node. */
  id: Scalars['ID'];
  /** The number of rows restored. */
  numRestored: Scalars['Int'];
};

export type RetrySubmissionInput = {
  /** The ID of the class to retry the submission in. */
  classId: Scalars['ID'];
  /** The ID of the Assignment */
  id: Scalars['ID'];
};

export enum RevealAnswers {
  /** Answers are revealed to students after they are finished, or after revealAttempts. */
  AfterSubmit = 'AFTER_SUBMIT',
  /** Answers are revealed to students in real time. */
  RealTime = 'REAL_TIME'
}

export type ReviewAssignmentUserTask = UserTask & {
  __typename?: 'ReviewAssignmentUserTask';
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  payload: ReviewAssignmentUserTaskPayload;
};

export type ReviewAssignmentUserTaskPayload = {
  __typename?: 'ReviewAssignmentUserTaskPayload';
  assignmentId: Scalars['ID'];
  classId: Scalars['ID'];
  dueAt?: Maybe<Scalars['DateTime']>;
  excluded?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/**
 * A rubric template allows users to quickly define rubrics and use them in all
 * of their assignments.
 */
export type RubricTemplate = Node & {
  __typename?: 'RubricTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The name of the configuration, created by the teacher */
  name: Scalars['String'];
  /** The rubric configuration */
  rubric: AssignmentRubric;
  updatedAt: Scalars['DateTime'];
  /** The user that owns the template. */
  user: User;
  /** The ID of the user that owns the template. */
  userId: Scalars['ID'];
};

export type RubricTemplateInput = {
  name?: InputMaybe<Scalars['String']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
};

export type RubricTemplatePayload = {
  __typename?: 'RubricTemplatePayload';
  rubricTemplate: RubricTemplate;
};

export type RubricTemplatesConnection = Connection & {
  __typename?: 'RubricTemplatesConnection';
  edges: Array<RubricTemplatesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RubricTemplatesEdge = Edge & {
  __typename?: 'RubricTemplatesEdge';
  cursor: Scalars['String'];
  node: RubricTemplate;
};

export type RunGradeReportInput = {
  /** Optional Class IDs to filter on. */
  classIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RunReportInput = {
  /** Any dimensions to filter on inside the report. */
  dimensions: Array<ReportDimensionInput>;
  /** Any metrics to display inside the report. */
  metrics: Array<ReportMetricInput>;
};

/**
 * Input required for creating or updating assignment submissions.
 * These are used by both teachers and students.
 */
export type SaveAssignmentSubmissionInput = {
  /** The ID of the class to create or update the submission for. */
  classId: Scalars['ID'];
  /** If this is true, a new submission will be created instead of updating the existing one. */
  createNew?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the assignment to save submission data for. */
  id: Scalars['ID'];
  /** Any overrides to save back to the submission. */
  overrides?: InputMaybe<Scalars['JSON']>;
  /** The ID of the student (defaults to the current user). */
  studentId?: InputMaybe<Scalars['ID']>;
  /** The submission data to save. */
  submissionData: Scalars['JSON'];
  /** Whether or not to immediately submit after saving. */
  submit?: InputMaybe<Scalars['Boolean']>;
  /** If this is specified, this will attempt to update the specified submission ID instead. */
  updateId?: InputMaybe<Scalars['ID']>;
};

export type SavedReport = Node & {
  __typename?: 'SavedReport';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The name of the saved report. */
  name: Scalars['String'];
  /** The report configuration. */
  report: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
  /** The user that owns the saved report. */
  user: User;
};

export type SavedReportPayload = {
  __typename?: 'SavedReportPayload';
  savedReport: SavedReport;
};

export type SavedReportsConnection = Connection & {
  __typename?: 'SavedReportsConnection';
  edges: Array<SavedReportsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SavedReportsEdge = Edge & {
  __typename?: 'SavedReportsEdge';
  cursor: Scalars['String'];
  node: SavedReport;
};

export type SchoologyTokenInput = {
  token: Scalars['String'];
  tokenSecret: Scalars['String'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']>>;
  students?: InputMaybe<Array<ClassStudentInput>>;
  tags?: InputMaybe<TagsFilter>;
};

export type SearchResultsConnection = Connection & {
  __typename?: 'SearchResultsConnection';
  edges: Array<SearchResultsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SearchResultsEdge = Edge & {
  __typename?: 'SearchResultsEdge';
  cursor: Scalars['String'];
  node: Node;
};

export type Segment = Node & {
  __typename?: 'Segment';
  /** The configuration for this segment, minus its content and any other large configuration pieces. */
  basicConfig?: Maybe<Scalars['JSON']>;
  /** The full built segment object. */
  built?: Maybe<Scalars['JSON']>;
  /** The configuration for the segment. */
  config?: Maybe<Scalars['JSON']>;
  /** The date the segment was first created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** An optional index for the segment. Typically formatted like "1-1". Max length 25 characters. */
  index?: Maybe<Scalars['String']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: Maybe<Scalars['Boolean']>;
  /** The name of the segment. */
  name: Scalars['String'];
  /** An optional prefix (only included in very specific contexts; use the built segment in most cases). */
  prefix?: Maybe<Scalars['String']>;
  /** Gets the tree of standards for the current segment, under the selected class. */
  standards: Array<StandardsTreeItem>;
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON'];
  /** Gets all tags associated with this entity. */
  tags: TagsConnection;
  /** The type of segment. */
  type: SegmentType;
  /** The date the segment was last updated. */
  updatedAt: Scalars['DateTime'];
  /** The user that created the segment. If empty, BusinessU owns it. */
  user?: Maybe<User>;
};


export type SegmentStandardsArgs = {
  classId: Scalars['ID'];
};


export type SegmentTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SegmentCompletionInput = {
  /** The new complete status of these segments. */
  complete: Scalars['Boolean'];
  /** The IDs of the segments to update, in GraphQL format. */
  segmentIds: Array<Scalars['ID']>;
  /** The IDs of the students to update with these segment IDs, in GraphQL format. */
  studentIds: Array<Scalars['ID']>;
};

export type SegmentDecoration = {
  __typename?: 'SegmentDecoration';
  /** The date the assignment associated with this segment is due, if set. */
  assignmentDueDate?: Maybe<Scalars['DateTime']>;
  /** The ID of the assignment associated with this segment. */
  assignmentId?: Maybe<Scalars['ID']>;
  /** The assignment syncs related to this segment. */
  assignmentSyncs?: Maybe<Array<AssignmentSync>>;
  /** The IDs of the students who have completed this segment. */
  completedStudentIds?: Maybe<Array<Scalars['ID']>>;
  /** The universal ID for the segment being viewed. */
  id: Scalars['ID'];
  /** Whether or not the segment has been previously completed (students only). */
  isComplete?: Maybe<Scalars['Boolean']>;
  /** Whether or not the segment has been previously viewed (students only). */
  isViewed?: Maybe<Scalars['Boolean']>;
  /** The ID for the segment being decorated. */
  segmentId: Scalars['String'];
  /** The total count of students who have started the segment. */
  startedCount?: Maybe<Scalars['Int']>;
};

export type SegmentInput = {
  /** The configuration for the segment. */
  config?: InputMaybe<Scalars['JSON']>;
  /** The ID of the segment. */
  id: Scalars['ID'];
  /** The index for the segment. */
  index?: InputMaybe<Scalars['String']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: InputMaybe<Scalars['Boolean']>;
  /** The name of the segment. */
  name?: InputMaybe<Scalars['String']>;
  /** The type of segment to create or update. */
  type?: InputMaybe<SegmentType>;
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']>;
  /** The new tags to set for the segment. */
  updateTags?: InputMaybe<Scalars['JSON']>;
};

export type SegmentPayload = {
  __typename?: 'SegmentPayload';
  /** The created or updated segment. */
  segment: Segment;
};

export type SegmentReportDimensionInput = {
  place?: InputMaybe<Scalars['Boolean']>;
};

export type SegmentReportDimensionResult = {
  __typename?: 'SegmentReportDimensionResult';
  segment: Segment;
};

export enum SegmentType {
  /** eslint-disable-next-line @graphql-eslint/naming-convention */
  Assignment = 'Assignment',
  Group = 'Group',
  Reference = 'Reference',
  Root = 'Root',
  Section = 'Section',
  Term = 'Term',
  Topic = 'Topic'
}

export type SegmentsConnection = Connection & {
  __typename?: 'SegmentsConnection';
  edges: Array<SegmentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SegmentsEdge = Edge & {
  __typename?: 'SegmentsEdge';
  cursor: Scalars['String'];
  node: Segment;
};

/** The response returned when fetching files from the storage service. */
export type SignedFetchResult = {
  __typename?: 'SignedFetchResult';
  /** The bucket the file belongs to. */
  bucket: Scalars['String'];
  /** Whether or not the signed URL is cacheable. */
  isCacheable?: Maybe<Scalars['Boolean']>;
  /** The path to the file. */
  path: Scalars['String'];
  /** A dictionary of headers required to be sent with the request. */
  requireHeaders?: Maybe<Scalars['JSON']>;
  /** The signed URL to download the file. */
  signedUrl: Scalars['String'];
};

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String'];
  /** Whether or not the upload should be public. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** The mime-type of the file. */
  mimeType: Scalars['String'];
  /** An optional namespace to prefix the file with. */
  namespace?: InputMaybe<Scalars['String']>;
  /** The request mode to use when uploading. */
  requestMode?: InputMaybe<UploadRequestMode>;
};

/** The response returned when getting a signed upload URL. */
export type SignedUploadResult = {
  __typename?: 'SignedUploadResult';
  /** Any additional data to include with the request. */
  data?: Maybe<Scalars['JSON']>;
  /** The extension of the file created. */
  extension: Scalars['String'];
  /** The path to the file. */
  path: Scalars['String'];
  /** The signed URL to use when uploading the file. */
  signedUrl: Scalars['String'];
};

/** A site represents a school using BusinessU. */
export type Site = Node & {
  __typename?: 'Site';
  /** The city the site is located in. */
  city?: Maybe<Scalars['String']>;
  /** Gets all classes for the site. */
  classes: ClassesConnection;
  /** Extra configuration values provided by other features. */
  configuration?: Maybe<Scalars['JSON']>;
  /** The date the site was first created. */
  createdAt: Scalars['DateTime'];
  /** Whether or not course codes are disabled for this site. */
  disableCourseCodes?: Maybe<Scalars['Boolean']>;
  /** The district the site is located in. */
  district?: Maybe<District>;
  /** The ID of the site. */
  id: Scalars['ID'];
  /** The license for the site. */
  license?: Maybe<License>;
  /** The name of the site. */
  name: Scalars['String'];
  /** Any additional notes provided by an administrator. */
  note?: Maybe<Scalars['String']>;
  /** Gets all standard sets associated with this site. */
  standardSets: SiteStandardSetsConnection;
  /** The state the site is located in. */
  state?: Maybe<Scalars['String']>;
  /** The teachers inside the site. */
  teachers: TeachersConnection;
  /** The type of site. */
  type: Scalars['String'];
  /** The last time the site was updated. */
  updatedAt: Scalars['DateTime'];
};


/** A site represents a school using BusinessU. */
export type SiteClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A site represents a school using BusinessU. */
export type SiteStandardSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A site represents a school using BusinessU. */
export type SiteTeachersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type SiteInput = {
  city?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<Scalars['JSON']>;
  disableCourseCodes?: InputMaybe<Scalars['Boolean']>;
  districtId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  standardSetIds?: InputMaybe<Array<Scalars['ID']>>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** The payload returned when updating a site. */
export type SitePayload = {
  __typename?: 'SitePayload';
  site: Site;
};

export type SiteStandardSetsConnection = Connection & {
  __typename?: 'SiteStandardSetsConnection';
  edges: Array<SiteStandardSetsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SiteStandardSetsEdge = Edge & {
  __typename?: 'SiteStandardSetsEdge';
  cursor: Scalars['String'];
  generated?: Maybe<Scalars['Boolean']>;
  node: StandardSet;
};

export type SiteWideBanner = {
  __typename?: 'SiteWideBanner';
  message: Scalars['String'];
  /** The user groups the banner shows up for. */
  userGroups: Array<Scalars['String']>;
};

export type SiteWideBannerInput = {
  message: Scalars['String'];
  /** The user groups the message should show up for. */
  userGroups: Array<Scalars['String']>;
};

export type SitesConnection = Connection & {
  __typename?: 'SitesConnection';
  edges: Array<SitesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SitesEdge = Edge & {
  __typename?: 'SitesEdge';
  cursor: Scalars['String'];
  node: Site;
};

export type StandardSet = Node & {
  __typename?: 'StandardSet';
  /** The full PDF download of the standard. */
  attachment: Attachment;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The human-friendly name of the standard set. */
  name: Scalars['String'];
  /** Gets the tree of all tags inside this StandardSet, with embedded segments. */
  segmentsTree: StandardSetSegmentsTree;
  /** The sites that use this standard set. */
  sites: SitesConnection;
  /** The 2-character state code the set corresponds with. Used for automatic site association. */
  state?: Maybe<Scalars['String']>;
  /** The tags created by this standard set. */
  tags: TagsConnection;
  /** Gets the tree of all tags inside this StandardSet. */
  tagsTree: Array<TagsTreeItem>;
  updatedAt: Scalars['DateTime'];
};


export type StandardSetSegmentsTreeArgs = {
  depth?: InputMaybe<Scalars['Int']>;
};


export type StandardSetSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type StandardSetTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


export type StandardSetTagsTreeArgs = {
  depth?: InputMaybe<Scalars['Int']>;
};

export type StandardSetImportJobStatus = {
  __typename?: 'StandardSetImportJobStatus';
  resultPayload?: Maybe<StandardSetImportJobStatusPayload>;
  status?: Maybe<BackgroundJobStatus>;
};

export type StandardSetImportJobStatusPayload = {
  __typename?: 'StandardSetImportJobStatusPayload';
  current: Scalars['Int'];
  total: Scalars['Int'];
};

export type StandardSetPayload = {
  __typename?: 'StandardSetPayload';
  standardSet: StandardSet;
};

export type StandardSetSegmentsTree = {
  __typename?: 'StandardSetSegmentsTree';
  segments: Array<Segment>;
  tree: Array<TagsTreeItemWithSegments>;
};

export type StandardSetTagInput = {
  name: Scalars['String'];
  parents?: InputMaybe<Array<Scalars['String']>>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type StandardSetsConnection = Connection & {
  __typename?: 'StandardSetsConnection';
  edges: Array<StandardSetsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StandardSetsEdge = Edge & {
  __typename?: 'StandardSetsEdge';
  cursor: Scalars['String'];
  node: StandardSet;
};

export type StandardsTreeItem = {
  __typename?: 'StandardsTreeItem';
  /** The standard set itself. */
  standardSet: StandardSet;
  /** The tag tree associated with the standard set. */
  tree: Array<TagsTreeItem>;
};

export type StartStandardSetImportInput = {
  csvPath: Scalars['String'];
  standardSetId: Scalars['ID'];
};

export type StartStandardSetImportPayload = {
  __typename?: 'StartStandardSetImportPayload';
  identifier: Scalars['String'];
};

export type StoreVodCaptionInput = {
  /** The WebVTT contents of the caption file. */
  fileContents: Scalars['String'];
  /** The language code for the caption file. */
  language: Scalars['String'];
};

export type StoreVodCaptionsInput = {
  /** The new captions to store (they will be added to the existing ones) */
  captions: Array<StoreVodCaptionInput>;
  /** The ID of the Video on Demand to store captions for. */
  id: Scalars['ID'];
};

export type StoredAttachment = {
  __typename?: 'StoredAttachment';
  bucket: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  sizeInBytes: Scalars['Float'];
};

/** A student is a member of a class. */
export type Student = Node & {
  __typename?: 'Student';
  /** Gets all assignment submissions by this student. */
  assignmentSubmissions: AssignmentSubmissionsConnection;
  /** Gets all assignments assigned to this specific student. */
  assignments: AssignmentsConnection;
  /** Gets all classes for the student. */
  classes: StudentClassesConnection;
  /** The time the student was first created. */
  createdAt: Scalars['DateTime'];
  /** The time the student was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The ID of the student. */
  id: Scalars['ID'];
  /** Gets an overview report for the current student. */
  overview: StudentOverview;
  /** The last time the student was updated. */
  updatedAt: Scalars['DateTime'];
  /** The user object associated with the student. */
  user: User;
};


/** A student is a member of a class. */
export type StudentAssignmentSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A student is a member of a class. */
export type StudentAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A student is a member of a class. */
export type StudentClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A student is a member of a class. */
export type StudentOverviewArgs = {
  classId: Scalars['ID'];
};

/** Student -> Classes */
export type StudentClassesConnection = Connection & {
  __typename?: 'StudentClassesConnection';
  edges: Array<StudentClassesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StudentClassesEdge = Edge & {
  __typename?: 'StudentClassesEdge';
  cursor: Scalars['String'];
  node: Class;
};

export type StudentGroupPerformance = {
  __typename?: 'StudentGroupPerformance';
  averageGradeWhenLeader?: Maybe<Scalars['Float']>;
  timesAsLeader: Scalars['Int'];
};

export type StudentInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type StudentMastery = {
  __typename?: 'StudentMastery';
  mastery: Array<StudentMasteryItem>;
};

export type StudentMasteryItem = {
  __typename?: 'StudentMasteryItem';
  grade: Scalars['Float'];
  rubricType: Scalars['String'];
};

export type StudentOverview = {
  __typename?: 'StudentOverview';
  averageSessionLength?: Maybe<Scalars['Float']>;
  averageTimeOnTask?: Maybe<Scalars['Float']>;
  contentVisited?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  groupPerformance: StudentGroupPerformance;
  masteryReport: StudentMastery;
  totalTimeOnTask?: Maybe<Scalars['Float']>;
};

/** The payload returned after mutating a Student. */
export type StudentPayload = {
  __typename?: 'StudentPayload';
  /** The created or updated Student. */
  student: Student;
};

export type StudentReportAllResultItem = {
  __typename?: 'StudentReportAllResultItem';
  averageTimeOnTask?: Maybe<Scalars['Float']>;
  contentVisited?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  label: Scalars['String'];
  timeOnTask?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type: StudentReportResultType;
};

export type StudentReportIg = {
  __typename?: 'StudentReportIG';
  grade?: Maybe<Scalars['Float']>;
  implementationGuide: Scalars['String'];
  items: Array<StudentReportResultsItem>;
};

export type StudentReportInput = {
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID'];
  /** The ID of the student to run the report on. */
  id: Scalars['ID'];
};

export type StudentReportResult = {
  __typename?: 'StudentReportResult';
  allResults: Array<StudentReportAllResultItem>;
  implementationGuides: Array<StudentReportIg>;
};

export enum StudentReportResultType {
  Assignment = 'ASSIGNMENT',
  Section = 'SECTION'
}

export type StudentReportResultsItem = {
  __typename?: 'StudentReportResultsItem';
  assignment?: Maybe<Assignment>;
  averageTimeOnTask?: Maybe<Scalars['Float']>;
  contentVisited?: Maybe<Scalars['Float']>;
  grade?: Maybe<Scalars['Float']>;
  isContentVisited?: Maybe<Scalars['Boolean']>;
  segmentId?: Maybe<Scalars['String']>;
  submission?: Maybe<AssignmentSubmission>;
  timeOnTask?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type: StudentReportResultType;
};

export type StudentsConnection = Connection & {
  __typename?: 'StudentsConnection';
  edges: Array<StudentsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type StudentsEdge = Edge & {
  __typename?: 'StudentsEdge';
  cursor: Scalars['String'];
  node: Student;
};

export type StudentsReportDimensionInput = {
  studentPairs: Array<ClassStudentInput>;
};

export type StudentsReportDimensionResult = {
  __typename?: 'StudentsReportDimensionResult';
  class: Class;
  student: Student;
};

export enum SubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL'
}

export type SubmitAnsweredQuestionInput = {
  /** The question being asked. */
  name: Scalars['String'];
};

export type SubmitAssignmentInput = {
  /** Whether or not to force the submission. */
  force?: InputMaybe<Scalars['Boolean']>;
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID'];
  /** Any submission data to add. */
  submissionData?: InputMaybe<Scalars['JSON']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Just a placeholder field until we add more.
   * @deprecated This is a placeholder field.
   */
  node?: Maybe<Node>;
  /** Get all detailed logs for the specified user. */
  userDetailedLogs?: Maybe<DetailedLog>;
};


export type SubscriptionNodeArgs = {
  id: Scalars['ID'];
};


export type SubscriptionUserDetailedLogsArgs = {
  input: DetailedLogsForUserInput;
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID'];
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SyncSegmentInput = {
  /** The global segment ID (not the raw one). */
  id: Scalars['ID'];
  /** The class syncs to use to sync this assignment. */
  syncIds: Array<Scalars['ID']>;
};

/** A tag represents a way to categorize content within the system. */
export type Tag = Node & {
  __typename?: 'Tag';
  /** Gets the children for this tag, if available. */
  children: TagsConnection;
  /** Additional configuration fields for this tag. */
  config?: Maybe<TagConfiguration>;
  /** The date the tag was created. */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** Whether or not the tag has content available in the current user's license. */
  inLicense: Scalars['Boolean'];
  /** The name of the tag. */
  name: Scalars['String'];
  /** The paths (in the form of tag IDs) to this tag. */
  paths?: Maybe<Array<Maybe<Array<Scalars['ID']>>>>;
  /** Gets the standard set that owns this tag. */
  standardSet?: Maybe<StandardSet>;
  /** The type of the tag. */
  type?: Maybe<Scalars['String']>;
  /** The date the tag was updated. */
  updatedAt: Scalars['DateTime'];
  /** The user that owns the tag. */
  user?: Maybe<User>;
  /** An optional weight for a tag, used for sorting. */
  weight?: Maybe<Scalars['Float']>;
};


/** A tag represents a way to categorize content within the system. */
export type TagChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export type TagConfigInput = {
  icon?: InputMaybe<Scalars['String']>;
};

export type TagConfiguration = {
  __typename?: 'TagConfiguration';
  /** The name of an optional icon to associate with the tag. */
  icon?: Maybe<Scalars['String']>;
};

export type TagFilterInput = {
  /** The name of the tag. */
  name?: InputMaybe<Scalars['String']>;
  /** The path of parent tags to find all children for. */
  path?: InputMaybe<Array<Scalars['ID']>>;
  /** The type of tag. */
  type: Scalars['String'];
};

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type TagFilters = {
  /** Whether or not to filter on tags that have content available in the current user's license. */
  inLicense?: InputMaybe<Scalars['Boolean']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TagPayload = {
  __typename?: 'TagPayload';
  tag: Tag;
};

export type TagsConnection = Connection & {
  __typename?: 'TagsConnection';
  edges: Array<TagsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TagsEdge = Edge & {
  __typename?: 'TagsEdge';
  cursor: Scalars['String'];
  node: Tag;
};

export type TagsFilter = {
  operation: TagFilterOperation;
  tags: Array<TagFilterInput>;
};

export type TagsTreeItem = {
  __typename?: 'TagsTreeItem';
  children: Array<TagsTreeItem>;
  tag: Tag;
};

export type TagsTreeItemWithSegments = {
  __typename?: 'TagsTreeItemWithSegments';
  children: Array<TagsTreeItemWithSegments>;
  /** The segment IDs associated with this tag. */
  segmentIds: Array<Scalars['ID']>;
  tag: Tag;
};

/** A teacher is a physical instructor at a site. */
export type Teacher = Node & {
  __typename?: 'Teacher';
  /** Gets all assignments this teacher is associated with. */
  assignments: AssignmentsConnection;
  /** Gets all classes for the teacher. */
  classes: TeacherClassesConnection;
  /** The time the teacher was created. */
  createdAt: Scalars['DateTime'];
  /** The ID of the teacher. */
  id: Scalars['ID'];
  /** The teachers related to the current teacher. */
  relatedTeachers?: Maybe<RelatedTeachers>;
  /** The site the teacher belongs to. */
  site: Site;
  /** The ID of the site the teacher belongs to. */
  siteId: Scalars['ID'];
  /** The student user associated with the teacher. */
  studentUser?: Maybe<User>;
  /** The public-facing ID of the teacher. */
  teacherId: Scalars['String'];
  /** The last time the teacher was updated. */
  updatedAt: Scalars['DateTime'];
  /** The associated user the Teacher will use to login with. */
  user: User;
};


/** A teacher is a physical instructor at a site. */
export type TeacherAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<AssignmentFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A teacher is a physical instructor at a site. */
export type TeacherClassesArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A teacher is a physical instructor at a site. */
export type TeacherRelatedTeachersArgs = {
  showSelf?: InputMaybe<Scalars['Boolean']>;
};

export type TeacherClassesConnection = Connection & {
  __typename?: 'TeacherClassesConnection';
  edges: Array<TeacherClassesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TeacherClassesEdge = Edge & {
  __typename?: 'TeacherClassesEdge';
  cursor: Scalars['String'];
  node: Class;
  permissions: Array<Scalars['String']>;
  role: ClassRole;
};

export type TeacherInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  noEmail?: Scalars['Boolean'];
  password?: InputMaybe<Scalars['String']>;
  siteId?: InputMaybe<Scalars['ID']>;
};

/** The payload returned after mutating a teacher. */
export type TeacherPayload = {
  __typename?: 'TeacherPayload';
  /** The created or updated teacher. */
  teacher: Teacher;
};

export type TeachersConnection = Connection & {
  __typename?: 'TeachersConnection';
  edges: Array<TeachersEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TeachersEdge = Edge & {
  __typename?: 'TeachersEdge';
  cursor: Scalars['String'];
  node: Teacher;
};

export type TimeOnTask = {
  __typename?: 'TimeOnTask';
  studentUserId: Scalars['ID'];
  timeOnTaskMinutes: Scalars['Float'];
};

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: InputMaybe<Scalars['String']>;
};

export type TimeOnTaskReportMetricInput = {
  place?: InputMaybe<Scalars['Boolean']>;
};

export type TimeOnTaskResult = {
  __typename?: 'TimeOnTaskResult';
  timeOnTasks: Array<TimeOnTask>;
  totalMinutes: Scalars['Float'];
};

/** Represents a single request for a trial. */
export type TrialRequest = Node & {
  __typename?: 'TrialRequest';
  /** If administrators cannot approve this trial request, this will be populated with the reason. */
  cantApproveReason?: Maybe<CantApproveTrialRequestReason>;
  /** The city the site is located in for the trial request. */
  city?: Maybe<Scalars['String']>;
  /** The country the trial request is coming from. */
  country?: Maybe<Scalars['String']>;
  /** The time the trial request was originally created. */
  createdAt: Scalars['DateTime'];
  /** The email of the individual requesting the trial. */
  email: Scalars['String'];
  /** If a license already exists for this user, shows the license. */
  existingLicense?: Maybe<License>;
  /** The first name of the individual requesting the trial. */
  firstName: Scalars['String'];
  /** The ID of the trial request. */
  id: Scalars['ID'];
  /** The last name of the individual requesting the trial. */
  lastName: Scalars['String'];
  /** The phone number of the teacher who submitted the trial request. */
  phone?: Maybe<Scalars['String']>;
  /** The name of the site we'll want to create. */
  siteName: Scalars['String'];
  /** The shortcode for the state the site is located in for the trial request. */
  state?: Maybe<Scalars['String']>;
  /** The status of the trial request. */
  status: TrialRequestStatus;
  /** The trial request campaign the trial request was created with. */
  trialRequestCampaign?: Maybe<TrialRequestCampaign>;
  /** The last time the trial request was updated. */
  updatedAt: Scalars['DateTime'];
};

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY'
}

export type TrialRequestCampaign = Node & {
  __typename?: 'TrialRequestCampaign';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The utm_campaign identifier. */
  identifier: Scalars['String'];
  /** If specified, the initial path to redirect new teacher accounts to. */
  targetPath?: Maybe<Scalars['String']>;
  /** Gets the trial requests associated with this campaign. */
  trialRequests: TrialRequestsConnection;
  updatedAt: Scalars['DateTime'];
  /** If specified, the welcome message to present to teachers while they're on a trial. */
  welcomeMessage?: Maybe<TrialRequestCampaignWelcomeMessage>;
};


export type TrialRequestCampaignTrialRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  status?: InputMaybe<TrialRequestStatus>;
};

export type TrialRequestCampaignInput = {
  /** The campaign name */
  identifier?: InputMaybe<Scalars['String']>;
  /** The target path for the campaign */
  targetPath?: InputMaybe<Scalars['String']>;
  /** The welcome message */
  welcomeMessage?: InputMaybe<TrialRequestCampaignWelcomeMessageInput>;
};

export type TrialRequestCampaignPayload = {
  __typename?: 'TrialRequestCampaignPayload';
  trialRequestCampaign: TrialRequestCampaign;
};

export type TrialRequestCampaignWelcomeMessage = {
  __typename?: 'TrialRequestCampaignWelcomeMessage';
  /** The path to the background image of the welcome message. */
  background?: Maybe<Scalars['String']>;
  /** The description of the welcome mesage. Supports Markdown. */
  message?: Maybe<Scalars['String']>;
  /** The title of the welcome message. */
  title?: Maybe<Scalars['String']>;
};

export type TrialRequestCampaignWelcomeMessageInput = {
  background?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TrialRequestCampaignsConnection = Connection & {
  __typename?: 'TrialRequestCampaignsConnection';
  edges: Array<TrialRequestCampaignsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TrialRequestCampaignsEdge = Edge & {
  __typename?: 'TrialRequestCampaignsEdge';
  cursor: Scalars['String'];
  node: TrialRequestCampaign;
};

export type TrialRequestPayload = {
  __typename?: 'TrialRequestPayload';
  trialRequest: TrialRequest;
};

export enum TrialRequestStatus {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
  Ignored = 'IGNORED',
  Open = 'OPEN'
}

export type TrialRequestsConnection = Connection & {
  __typename?: 'TrialRequestsConnection';
  edges: Array<TrialRequestsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TrialRequestsEdge = Edge & {
  __typename?: 'TrialRequestsEdge';
  cursor: Scalars['String'];
  node: TrialRequest;
};

export type TrialSignupInput = {
  /** The identifier for the trial request campaign to associate. */
  campaign?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  siteName: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
};

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID'];
};

export type UnlikeNodeInput = {
  /** The ID of the node to unlike. */
  id: Scalars['ID'];
};

export type UnlinkAccountInput = {
  /** The identifier for the provider. */
  provider: Scalars['String'];
  /** The user's specific ID related to the provider. */
  providerId: Scalars['String'];
};

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID'];
  patch: AnnouncementInput;
};

export type UpdateAnsweredQuestionContributorInput = {
  id: Scalars['ID'];
  patch: AnsweredQuestionContributorInput;
};

export type UpdateAnsweredQuestionInput = {
  id: Scalars['ID'];
  patch: AnsweredQuestionInput;
};

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID'];
  patch: AssignmentCategoryInput;
};

export type UpdateAssignmentClassConfigurationInput = {
  /** The ID of the class. */
  id: Scalars['ID'];
  patch: AssignmentClassConfigurationInput;
};

export type UpdateAssignmentInput = {
  /** The ID of the assignment to update. */
  id: Scalars['ID'];
  patch: AssignmentInput;
};

export type UpdateAssignmentTemplateInput = {
  id: Scalars['ID'];
  patch: AssignmentTemplateInput;
};

export type UpdateClassInput = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  patch: ClassInput;
  segmentMetadataChanges?: InputMaybe<Scalars['JSON']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  patch: CommentInput;
};

export type UpdateCourseInput = {
  id: Scalars['ID'];
  patch: CourseInput;
};

export type UpdateCourseVersionInput = {
  id: Scalars['ID'];
  patch: CourseVersionInput;
};

export type UpdateDistrictInput = {
  id: Scalars['ID'];
  patch: DistrictInput;
};

export type UpdateLicenseInput = {
  id: Scalars['ID'];
  noEmail?: InputMaybe<Scalars['Boolean']>;
  patch: LicenseInput;
};

export type UpdateLicenseSubscriptionInput = {
  id: Scalars['ID'];
  patch: LicenseSubscriptionInput;
};

export type UpdateNodeTagsInput = {
  id: Scalars['ID'];
  tags: Array<Scalars['String']>;
};

export type UpdateNodeTagsPayload = {
  __typename?: 'UpdateNodeTagsPayload';
  node: Node;
};

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: InputMaybe<Scalars['String']>;
  /** The user's current preferences. */
  preferences: Array<PreferencesInput>;
};

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID'];
  patch: RubricTemplateInput;
};

export type UpdateSavedReportInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  report?: InputMaybe<Scalars['JSON']>;
};

export type UpdateSegmentCompletionInput = {
  /** The ID of the class to update the completion in. */
  id: Scalars['ID'];
  /** The completion updates to perform. */
  updates: Array<SegmentCompletionInput>;
};

export type UpdateSiteInput = {
  id: Scalars['ID'];
  patch: SiteInput;
};

export type UpdateSiteWideBannerInput = {
  message?: InputMaybe<SiteWideBannerInput>;
};

export type UpdateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type UpdateStudentInput = {
  id: Scalars['ID'];
  patch: StudentInput;
};

export type UpdateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  /** The ID of the tag to update. */
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID'];
  /** The new permissions to assign to the teacher. */
  permissions: Array<Scalars['String']>;
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID'];
};

export type UpdateTeacherInput = {
  id: Scalars['ID'];
  patch: TeacherInput;
};

export type UpdateTrialRequestCampaignInput = {
  id: Scalars['ID'];
  patch: TrialRequestCampaignInput;
};

export type UpdateUserDetailedLoggingInput = {
  /** Whether or not to enable detailed logging for this user. */
  enabled: Scalars['Boolean'];
  /** The ID of the user to update. */
  id: Scalars['ID'];
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  patch: UserInput;
  /** If this is passed, the user's last activity is updated to the current time. */
  updateLastActivity?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID'];
  patch: VideoOnDemandInput;
};

export type UpdateWidgetTemplateInput = {
  id: Scalars['ID'];
  patch: WidgetTemplateInput;
};

export enum UploadRequestMode {
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA'
}

export type UseAssignmentSubmissionInput = {
  /** The ID of the assignment. */
  id: Scalars['ID'];
  /** The ID of the submission to use. */
  submissionId: Scalars['ID'];
};

/** A user registered on the website. */
export type User = Node & {
  __typename?: 'User';
  /** The profile image associated with the user. */
  avatar: Attachment;
  /** Gets all background jobs for the current user. */
  backgroundJobs: Array<BackgroundJob>;
  /** Whether or not the current user can send a message to this user. */
  canMessage: Scalars['Boolean'];
  /** Whether or not the current user can update this user. */
  canUpdate: Scalars['Boolean'];
  /** Gets the base url & consumer key from the assoicated district or site */
  canvasAuthentication: CanvasAuthentication;
  /** The contributor represented by the current user. */
  contributor?: Maybe<AnsweredQuestionContributor>;
  /** The date the user was created at. */
  createdAt: Scalars['DateTime'];
  /** Gets all comments created by the current user. */
  createdComments: CommentsConnection;
  /** The stripe customer ID for the user. */
  customerId?: Maybe<Scalars['String']>;
  /** Determines if detailed logging is enabled for this user or not. */
  detailedLoggingEnabled: Scalars['Boolean'];
  /** Gets direct message threads the current user owns or belongs to. */
  directMessageThreads: DirectMessageThreadsConnection;
  /** The email address of the user. */
  email?: Maybe<Scalars['String']>;
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>;
  /** The group the user belongs to. */
  group: Scalars['String'];
  /** Returns whether or not the current user has completed a tour. */
  hasCompletedTour: Scalars['Boolean'];
  /** Returns whether or not the current user has watched a video-on-demand video. */
  hasWatchedVod: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Lists all interactions targeting the current user. */
  interactions: InteractionsConnection;
  /** The hash used to authenticate the intercom client. */
  intercomHash: Scalars['String'];
  /** The last time the user was active in the system. */
  lastActivity?: Maybe<Scalars['DateTime']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>;
  /** The subscriptions associated with the user. */
  licenseSubscriptions: LicenseSubscriptionsConnection;
  /** Gets the linked accounts for the current user. */
  linkedAccounts: Array<LinkedAccount>;
  /** The user's full name (automatically calculated) */
  name?: Maybe<Scalars['String']>;
  /** The notification preferences for the current user. */
  notificationPreferences: Array<NotificationPreference>;
  /** Gets notifications for the current user. */
  notifications: NotificationsConnection;
  /** The primary contact phone number for the user. */
  phone?: Maybe<Scalars['String']>;
  /** The anonymous name for the current user. */
  pseudonym: Scalars['String'];
  /** Gets all rubric templates for the specified user. */
  rubricTemplates: RubricTemplatesConnection;
  /** Lists all saved reports for the current user. */
  savedReports: SavedReportsConnection;
  /** The user's current status. */
  status: UserStatus;
  /** The student associated with the user. */
  student?: Maybe<Student>;
  /** The quota bytes for the user's current individual subscription. */
  subscriptionQuota?: Maybe<Scalars['Float']>;
  /** The currently-used bytes for the user's current individual subscription. */
  subscriptionUsage?: Maybe<Scalars['Float']>;
  /** Gets all tags for the current user. */
  tags: TagsConnection;
  /** Gets the required tasks for the current user. */
  tasks: Array<UserTask>;
  /** The optional teacher associated with the user. */
  teacher?: Maybe<Teacher>;
  /** The number of unread notifications for the current user. */
  unreadNotifications: Scalars['Int'];
  /** The date the user was last updated. */
  updatedAt: Scalars['DateTime'];
  /**
   * The username, also used for authentication.
   * eslint-disable-next-line @graphql-eslint/no-typename-prefix
   */
  username: Scalars['String'];
};


/** A user registered on the website. */
export type UserCreatedCommentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserDirectMessageThreadsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DirectMessageThreadsFilters>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserHasCompletedTourArgs = {
  classId: Scalars['ID'];
  widgetId: Scalars['String'];
};


/** A user registered on the website. */
export type UserHasWatchedVodArgs = {
  id: Scalars['ID'];
};


/** A user registered on the website. */
export type UserInteractionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  afterDate?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  types?: InputMaybe<Array<Scalars['String']>>;
};


/** A user registered on the website. */
export type UserLicenseSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
  type?: InputMaybe<LicenseSubscriptionType>;
};


/** A user registered on the website. */
export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserRubricTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserSavedReportsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserTagsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<OrderDirection>;
};


/** A user registered on the website. */
export type UserTasksArgs = {
  completed?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['Int']>;
  input?: InputMaybe<UserTasksFilterInput>;
};

export enum UserGroup {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  ContentManager = 'CONTENT_MANAGER',
  Contributor = 'CONTRIBUTOR',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER'
}

export type UserGroupInfo = {
  __typename?: 'UserGroupInfo';
  /** The human label for the group. */
  label: Scalars['String'];
  /** The internal name of the group. */
  name: Scalars['String'];
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']>;
};

/** The payload returned when updating a user. */
export type UserPayload = {
  __typename?: 'UserPayload';
  user: User;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserTask = {
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
};

export type UserTasksFilterInput = {
  /** An optional class ID to filter the user tasks on. */
  classId?: InputMaybe<Scalars['ID']>;
  completed?: InputMaybe<Scalars['Boolean']>;
};

export type UsersConnection = Connection & {
  __typename?: 'UsersConnection';
  edges: Array<UsersEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type UsersEdge = Edge & {
  __typename?: 'UsersEdge';
  cursor: Scalars['String'];
  node: User;
};

export type VideoOnDemand = Node & {
  __typename?: 'VideoOnDemand';
  /** The current status of the captioning for this video, if it has been initiated. */
  captionStatus?: Maybe<VideoOnDemandCaptionStatus>;
  /** The time the video was created. */
  createdAt: Scalars['DateTime'];
  /** The time the video was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The description entered for the video. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The name of the video. */
  name: Scalars['String'];
  /** Metadata about the provider that's hosting this video. */
  provider: VideoOnDemandProvider;
  /** The current processing status of the video. */
  status: VideoOnDemandProcessingStatus;
  /** The thumbnail data associated with the video. */
  thumbnailData?: Maybe<Scalars['JSON']>;
  /** The time the video was last updated. */
  updatedAt: Scalars['DateTime'];
};

export enum VideoOnDemandCaptionStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type VideoOnDemandConnection = Connection & {
  __typename?: 'VideoOnDemandConnection';
  edges: Array<VideoOnDemandEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VideoOnDemandEdge = Edge & {
  __typename?: 'VideoOnDemandEdge';
  cursor: Scalars['String'];
  node: VideoOnDemand;
};

export type VideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  thumbnailData?: InputMaybe<Scalars['JSON']>;
};

export type VideoOnDemandPayload = {
  __typename?: 'VideoOnDemandPayload';
  /** The updated videoOnDemand */
  videoOnDemand: VideoOnDemand;
};

export type VideoOnDemandProcessingStatus = {
  __typename?: 'VideoOnDemandProcessingStatus';
  /** The duration of the video. */
  duration?: Maybe<Scalars['Int']>;
  /** The current percentage of the processing. */
  percentage: Scalars['Int'];
  /** Data used for playing back this video on clients. */
  playbackData?: Maybe<VodPlaybackData>;
  /**
   * The signed ID for the video, for playback.
   * @deprecated Cloudflare Stream moved to an individual provider instead.
   */
  signedId?: Maybe<Scalars['String']>;
  /** The current processing status for the video. */
  status: VideoOnDemandStatus;
};

export type VideoOnDemandProvider = {
  __typename?: 'VideoOnDemandProvider';
  /** The internal identifier for the provider. */
  identifier: Scalars['String'];
  /** The label for the provider. */
  label: Scalars['String'];
};

export enum VideoOnDemandStatus {
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING'
}

export type ViewSegmentUserTask = UserTask & {
  __typename?: 'ViewSegmentUserTask';
  completedAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  identifier: Scalars['String'];
  payload: ViewSegmentUserTaskPayload;
};

export type ViewSegmentUserTaskPayload = {
  __typename?: 'ViewSegmentUserTaskPayload';
  classId: Scalars['ID'];
  segmentId: Scalars['String'];
};

export type VodPlaybackData = CustomVodPlaybackData | DefaultVodPlaybackData;

export type VodUploadPayload = CustomVodUploadPayload | SignedUploadResult;

export type WidgetTemplate = Node & {
  __typename?: 'WidgetTemplate';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** The name of the template. */
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  /** The widgets inside the template. */
  widgets?: Maybe<Array<Scalars['JSON']>>;
};

export type WidgetTemplateInput = {
  name?: InputMaybe<Scalars['String']>;
  widgets?: InputMaybe<Array<Scalars['JSON']>>;
};

export type WidgetTemplatePayload = {
  __typename?: 'WidgetTemplatePayload';
  widgetTemplate: WidgetTemplate;
};

export type WidgetTemplatesConnection = Connection & {
  __typename?: 'WidgetTemplatesConnection';
  edges: Array<WidgetTemplatesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WidgetTemplatesEdge = Edge & {
  __typename?: 'WidgetTemplatesEdge';
  cursor: Scalars['String'];
  node: WidgetTemplate;
};

export type TrialRequestFragment = { __typename?: 'TrialRequest', id: string, firstName: string, lastName: string, email: string, city?: string | null, state?: string | null, country?: string | null, phone?: string | null, siteName: string, status: TrialRequestStatus, cantApproveReason?: CantApproveTrialRequestReason | null, createdAt: any, updatedAt: any, existingLicense?: { __typename?: 'License', id: string, productId?: string | null, expiresAt?: any | null, createdAt: any, updatedAt: any, price?: number | null, seats?: number | null, type: LicenseType, monthlyPriceInCents?: number | null, payUpFrontMonths?: number | null, payUpFrontPriceInCents?: number | null, site: { __typename?: 'Site', id: string, name: string } } | null, trialRequestCampaign?: { __typename?: 'TrialRequestCampaign', id: string, identifier: string } | null };

export type TrialRequestCampaignFragment = { __typename?: 'TrialRequestCampaign', id: string, identifier: string, targetPath?: string | null, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null, trialRequests: { __typename?: 'TrialRequestsConnection', totalCount: number } };

export type LicenseFragment = { __typename?: 'License', id: string, productId?: string | null, expiresAt?: any | null, createdAt: any, updatedAt: any, price?: number | null, seats?: number | null, type: LicenseType, monthlyPriceInCents?: number | null, payUpFrontMonths?: number | null, payUpFrontPriceInCents?: number | null, site: { __typename?: 'Site', id: string, name: string } };

export type LicenseSubscriptionFragment = { __typename?: 'LicenseSubscription', id: string, type: LicenseSubscriptionType, size?: LicenseSubscriptionSize | null, expiresAt?: any | null, isValid?: boolean | null, nextValidityCheck?: any | null, subscriptionId?: string | null, metadata?: any | null, quota?: number | null, used?: number | null, user: { __typename?: 'User', id: string, name?: string | null, username: string }, class?: { __typename?: 'Class', id: string, name: string } | null, license?: { __typename?: 'License', id: string, site: { __typename?: 'Site', id: string, name: string } } | null };

export type ApproveTrialRequestMutationVariables = Exact<{
  input: ApproveTrialRequestInput;
}>;


export type ApproveTrialRequestMutation = { __typename?: 'Mutation', approveTrialRequest: { __typename?: 'ApproveTrialRequestPayload', site: { __typename?: 'Site', id: string } } };

export type CreateTrialRequestCampaignMutationVariables = Exact<{
  input: TrialRequestCampaignInput;
}>;


export type CreateTrialRequestCampaignMutation = { __typename?: 'Mutation', createTrialRequestCampaign: { __typename?: 'TrialRequestCampaignPayload', trialRequestCampaign: { __typename?: 'TrialRequestCampaign', id: string, identifier: string, targetPath?: string | null, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null, trialRequests: { __typename?: 'TrialRequestsConnection', totalCount: number } } } };

export type IgnoreTrialRequestMutationVariables = Exact<{
  trialRequestId: Scalars['ID'];
}>;


export type IgnoreTrialRequestMutation = { __typename?: 'Mutation', ignoreTrialRequest: { __typename?: 'TrialRequestPayload', trialRequest: { __typename?: 'TrialRequest', id: string, firstName: string, lastName: string, email: string, city?: string | null, state?: string | null, country?: string | null, phone?: string | null, siteName: string, status: TrialRequestStatus, cantApproveReason?: CantApproveTrialRequestReason | null, createdAt: any, updatedAt: any, existingLicense?: { __typename?: 'License', id: string, productId?: string | null, expiresAt?: any | null, createdAt: any, updatedAt: any, price?: number | null, seats?: number | null, type: LicenseType, monthlyPriceInCents?: number | null, payUpFrontMonths?: number | null, payUpFrontPriceInCents?: number | null, site: { __typename?: 'Site', id: string, name: string } } | null, trialRequestCampaign?: { __typename?: 'TrialRequestCampaign', id: string, identifier: string } | null } } };

export type TrialRequestCampaignQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TrialRequestCampaignQuery = { __typename?: 'Query', node?: { __typename?: 'Announcement' } | { __typename?: 'AnsweredQuestion' } | { __typename?: 'AnsweredQuestionContributor' } | { __typename?: 'Assignment' } | { __typename?: 'AssignmentCategory' } | { __typename?: 'AssignmentSubmission' } | { __typename?: 'AssignmentSync' } | { __typename?: 'AssignmentTemplate' } | { __typename?: 'Class' } | { __typename?: 'ClassSync' } | { __typename?: 'Comment' } | { __typename?: 'Course' } | { __typename?: 'CourseVersion' } | { __typename?: 'DirectMessageThread' } | { __typename?: 'District' } | { __typename?: 'GeneratedPDF' } | { __typename?: 'Interaction' } | { __typename?: 'License' } | { __typename?: 'LicenseSubscription' } | { __typename?: 'Notification' } | { __typename?: 'ReportJob' } | { __typename?: 'RubricTemplate' } | { __typename?: 'SavedReport' } | { __typename?: 'Segment' } | { __typename?: 'Site' } | { __typename?: 'StandardSet' } | { __typename?: 'Student' } | { __typename?: 'Tag' } | { __typename?: 'Teacher' } | { __typename?: 'TrialRequest' } | { __typename?: 'TrialRequestCampaign', id: string, identifier: string, targetPath?: string | null, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null, trialRequests: { __typename?: 'TrialRequestsConnection', totalCount: number } } | { __typename?: 'User' } | { __typename?: 'VideoOnDemand' } | { __typename?: 'WidgetTemplate' } | null };

export type TrialRequestCampaignsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type TrialRequestCampaignsQuery = { __typename?: 'Query', trialRequestCampaigns: { __typename?: 'TrialRequestCampaignsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage?: boolean | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TrialRequestCampaignsEdge', node: { __typename?: 'TrialRequestCampaign', id: string, identifier: string, targetPath?: string | null, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null, trialRequests: { __typename?: 'TrialRequestsConnection', totalCount: number } } }> } };

export type TrialRequestQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TrialRequestQuery = { __typename?: 'Query', node?: { __typename?: 'Announcement' } | { __typename?: 'AnsweredQuestion' } | { __typename?: 'AnsweredQuestionContributor' } | { __typename?: 'Assignment' } | { __typename?: 'AssignmentCategory' } | { __typename?: 'AssignmentSubmission' } | { __typename?: 'AssignmentSync' } | { __typename?: 'AssignmentTemplate' } | { __typename?: 'Class' } | { __typename?: 'ClassSync' } | { __typename?: 'Comment' } | { __typename?: 'Course' } | { __typename?: 'CourseVersion' } | { __typename?: 'DirectMessageThread' } | { __typename?: 'District' } | { __typename?: 'GeneratedPDF' } | { __typename?: 'Interaction' } | { __typename?: 'License' } | { __typename?: 'LicenseSubscription' } | { __typename?: 'Notification' } | { __typename?: 'ReportJob' } | { __typename?: 'RubricTemplate' } | { __typename?: 'SavedReport' } | { __typename?: 'Segment' } | { __typename?: 'Site' } | { __typename?: 'StandardSet' } | { __typename?: 'Student' } | { __typename?: 'Tag' } | { __typename?: 'Teacher' } | { __typename?: 'TrialRequest', id: string, firstName: string, lastName: string, email: string, city?: string | null, state?: string | null, country?: string | null, phone?: string | null, siteName: string, status: TrialRequestStatus, cantApproveReason?: CantApproveTrialRequestReason | null, createdAt: any, updatedAt: any, existingLicense?: { __typename?: 'License', id: string, productId?: string | null, expiresAt?: any | null, createdAt: any, updatedAt: any, price?: number | null, seats?: number | null, type: LicenseType, monthlyPriceInCents?: number | null, payUpFrontMonths?: number | null, payUpFrontPriceInCents?: number | null, site: { __typename?: 'Site', id: string, name: string } } | null, trialRequestCampaign?: { __typename?: 'TrialRequestCampaign', id: string, identifier: string } | null } | { __typename?: 'TrialRequestCampaign' } | { __typename?: 'User' } | { __typename?: 'VideoOnDemand' } | { __typename?: 'WidgetTemplate' } | null };

export type TrialRequestsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  status: TrialRequestStatus;
}>;


export type TrialRequestsQuery = { __typename?: 'Query', trialRequests: { __typename?: 'TrialRequestsConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage?: boolean | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TrialRequestsEdge', node: { __typename?: 'TrialRequest', id: string, firstName: string, lastName: string, email: string, city?: string | null, state?: string | null, country?: string | null, phone?: string | null, siteName: string, status: TrialRequestStatus, cantApproveReason?: CantApproveTrialRequestReason | null, createdAt: any, updatedAt: any, existingLicense?: { __typename?: 'License', id: string, productId?: string | null, expiresAt?: any | null, createdAt: any, updatedAt: any, price?: number | null, seats?: number | null, type: LicenseType, monthlyPriceInCents?: number | null, payUpFrontMonths?: number | null, payUpFrontPriceInCents?: number | null, site: { __typename?: 'Site', id: string, name: string } } | null, trialRequestCampaign?: { __typename?: 'TrialRequestCampaign', id: string, identifier: string } | null } }> } };

export type TrialSignupMutationVariables = Exact<{
  input: TrialSignupInput;
}>;


export type TrialSignupMutation = { __typename?: 'Mutation', trialSignup: { __typename?: 'TrialRequestPayload', trialRequest: { __typename?: 'TrialRequest', id: string } } };

export type UpdateTrialRequestCampaignMutationVariables = Exact<{
  input: UpdateTrialRequestCampaignInput;
}>;


export type UpdateTrialRequestCampaignMutation = { __typename?: 'Mutation', updateTrialRequestCampaign: { __typename?: 'TrialRequestCampaignPayload', trialRequestCampaign: { __typename?: 'TrialRequestCampaign', id: string, identifier: string, targetPath?: string | null, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null, trialRequests: { __typename?: 'TrialRequestsConnection', totalCount: number } } } };

export type ViewerCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerCampaignQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, teacher?: { __typename?: 'Teacher', id: string, site: { __typename?: 'Site', id: string, license?: { __typename?: 'License', id: string, trialRequest?: { __typename?: 'TrialRequest', id: string, trialRequestCampaign?: { __typename?: 'TrialRequestCampaign', id: string, identifier: string, welcomeMessage?: { __typename?: 'TrialRequestCampaignWelcomeMessage', background?: string | null, title?: string | null, message?: string | null } | null } | null } | null } | null } } | null } | null };

export const LicenseFragmentDoc = gql`
    fragment License on License {
  id
  productId
  site {
    id
    name
  }
  expiresAt
  createdAt
  updatedAt
  price
  seats
  type
  monthlyPriceInCents
  payUpFrontMonths
  payUpFrontPriceInCents
}
    `;
export const TrialRequestFragmentDoc = gql`
    fragment TrialRequest on TrialRequest {
  id
  firstName
  lastName
  email
  city
  state
  country
  phone
  siteName
  status
  existingLicense {
    ...License
  }
  trialRequestCampaign {
    id
    identifier
  }
  cantApproveReason
  createdAt
  updatedAt
}
    ${LicenseFragmentDoc}`;
export const TrialRequestCampaignFragmentDoc = gql`
    fragment TrialRequestCampaign on TrialRequestCampaign {
  id
  identifier
  targetPath
  welcomeMessage {
    background
    title
    message
  }
  trialRequests {
    totalCount
  }
}
    `;
export const LicenseSubscriptionFragmentDoc = gql`
    fragment LicenseSubscription on LicenseSubscription {
  id
  type
  size
  user {
    id
    name
    username
  }
  class {
    id
    name
  }
  license {
    id
    site {
      id
      name
    }
  }
  expiresAt
  isValid
  nextValidityCheck
  subscriptionId
  metadata
  quota
  used
}
    `;
export const ApproveTrialRequestDocument = gql`
    mutation approveTrialRequest($input: ApproveTrialRequestInput!) {
  approveTrialRequest(input: $input) {
    site {
      id
    }
  }
}
    `;
export type ApproveTrialRequestMutationFn = ApolloReactCommon.MutationFunction<ApproveTrialRequestMutation, ApproveTrialRequestMutationVariables>;

/**
 * __useApproveTrialRequestMutation__
 *
 * To run a mutation, you first call `useApproveTrialRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTrialRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTrialRequestMutation, { data, loading, error }] = useApproveTrialRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveTrialRequestMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ApproveTrialRequestMutation, ApproveTrialRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ApproveTrialRequestMutation, ApproveTrialRequestMutationVariables>(ApproveTrialRequestDocument, options);
      }
export type ApproveTrialRequestMutationHookResult = ReturnType<typeof useApproveTrialRequestMutation>;
export type ApproveTrialRequestMutationResult = ApolloReactCommon.MutationResult<ApproveTrialRequestMutation>;
export type ApproveTrialRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveTrialRequestMutation, ApproveTrialRequestMutationVariables>;
export const CreateTrialRequestCampaignDocument = gql`
    mutation createTrialRequestCampaign($input: TrialRequestCampaignInput!) {
  createTrialRequestCampaign(input: $input) {
    trialRequestCampaign {
      ...TrialRequestCampaign
    }
  }
}
    ${TrialRequestCampaignFragmentDoc}`;
export type CreateTrialRequestCampaignMutationFn = ApolloReactCommon.MutationFunction<CreateTrialRequestCampaignMutation, CreateTrialRequestCampaignMutationVariables>;

/**
 * __useCreateTrialRequestCampaignMutation__
 *
 * To run a mutation, you first call `useCreateTrialRequestCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrialRequestCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrialRequestCampaignMutation, { data, loading, error }] = useCreateTrialRequestCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrialRequestCampaignMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateTrialRequestCampaignMutation, CreateTrialRequestCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateTrialRequestCampaignMutation, CreateTrialRequestCampaignMutationVariables>(CreateTrialRequestCampaignDocument, options);
      }
export type CreateTrialRequestCampaignMutationHookResult = ReturnType<typeof useCreateTrialRequestCampaignMutation>;
export type CreateTrialRequestCampaignMutationResult = ApolloReactCommon.MutationResult<CreateTrialRequestCampaignMutation>;
export type CreateTrialRequestCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTrialRequestCampaignMutation, CreateTrialRequestCampaignMutationVariables>;
export const IgnoreTrialRequestDocument = gql`
    mutation ignoreTrialRequest($trialRequestId: ID!) {
  ignoreTrialRequest(id: $trialRequestId) {
    trialRequest {
      ...TrialRequest
    }
  }
}
    ${TrialRequestFragmentDoc}`;
export type IgnoreTrialRequestMutationFn = ApolloReactCommon.MutationFunction<IgnoreTrialRequestMutation, IgnoreTrialRequestMutationVariables>;

/**
 * __useIgnoreTrialRequestMutation__
 *
 * To run a mutation, you first call `useIgnoreTrialRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreTrialRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreTrialRequestMutation, { data, loading, error }] = useIgnoreTrialRequestMutation({
 *   variables: {
 *      trialRequestId: // value for 'trialRequestId'
 *   },
 * });
 */
export function useIgnoreTrialRequestMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<IgnoreTrialRequestMutation, IgnoreTrialRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<IgnoreTrialRequestMutation, IgnoreTrialRequestMutationVariables>(IgnoreTrialRequestDocument, options);
      }
export type IgnoreTrialRequestMutationHookResult = ReturnType<typeof useIgnoreTrialRequestMutation>;
export type IgnoreTrialRequestMutationResult = ApolloReactCommon.MutationResult<IgnoreTrialRequestMutation>;
export type IgnoreTrialRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<IgnoreTrialRequestMutation, IgnoreTrialRequestMutationVariables>;
export const TrialRequestCampaignDocument = gql`
    query trialRequestCampaign($id: ID!) {
  node(id: $id) {
    ...TrialRequestCampaign
  }
}
    ${TrialRequestCampaignFragmentDoc}`;

/**
 * __useTrialRequestCampaignQuery__
 *
 * To run a query within a React component, call `useTrialRequestCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialRequestCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialRequestCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrialRequestCampaignQuery(baseOptions: ApolloReactCommon.QueryHookOptions<TrialRequestCampaignQuery, TrialRequestCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TrialRequestCampaignQuery, TrialRequestCampaignQueryVariables>(TrialRequestCampaignDocument, options);
      }
export function useTrialRequestCampaignLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TrialRequestCampaignQuery, TrialRequestCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TrialRequestCampaignQuery, TrialRequestCampaignQueryVariables>(TrialRequestCampaignDocument, options);
        }
export type TrialRequestCampaignQueryHookResult = ReturnType<typeof useTrialRequestCampaignQuery>;
export type TrialRequestCampaignLazyQueryHookResult = ReturnType<typeof useTrialRequestCampaignLazyQuery>;
export type TrialRequestCampaignQueryResult = ApolloReactCommon.QueryResult<TrialRequestCampaignQuery, TrialRequestCampaignQueryVariables>;
export const TrialRequestCampaignsDocument = gql`
    query trialRequestCampaigns($first: Int = 32, $after: String) {
  trialRequestCampaigns(
    first: $first
    after: $after
    orderBy: "createdAt"
    orderDirection: DESC
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...TrialRequestCampaign
      }
    }
  }
}
    ${TrialRequestCampaignFragmentDoc}`;

/**
 * __useTrialRequestCampaignsQuery__
 *
 * To run a query within a React component, call `useTrialRequestCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialRequestCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialRequestCampaignsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useTrialRequestCampaignsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<TrialRequestCampaignsQuery, TrialRequestCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TrialRequestCampaignsQuery, TrialRequestCampaignsQueryVariables>(TrialRequestCampaignsDocument, options);
      }
export function useTrialRequestCampaignsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TrialRequestCampaignsQuery, TrialRequestCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TrialRequestCampaignsQuery, TrialRequestCampaignsQueryVariables>(TrialRequestCampaignsDocument, options);
        }
export type TrialRequestCampaignsQueryHookResult = ReturnType<typeof useTrialRequestCampaignsQuery>;
export type TrialRequestCampaignsLazyQueryHookResult = ReturnType<typeof useTrialRequestCampaignsLazyQuery>;
export type TrialRequestCampaignsQueryResult = ApolloReactCommon.QueryResult<TrialRequestCampaignsQuery, TrialRequestCampaignsQueryVariables>;
export const TrialRequestDocument = gql`
    query trialRequest($id: ID!) {
  node(id: $id) {
    ...TrialRequest
  }
}
    ${TrialRequestFragmentDoc}`;

/**
 * __useTrialRequestQuery__
 *
 * To run a query within a React component, call `useTrialRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialRequestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrialRequestQuery(baseOptions: ApolloReactCommon.QueryHookOptions<TrialRequestQuery, TrialRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TrialRequestQuery, TrialRequestQueryVariables>(TrialRequestDocument, options);
      }
export function useTrialRequestLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TrialRequestQuery, TrialRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TrialRequestQuery, TrialRequestQueryVariables>(TrialRequestDocument, options);
        }
export type TrialRequestQueryHookResult = ReturnType<typeof useTrialRequestQuery>;
export type TrialRequestLazyQueryHookResult = ReturnType<typeof useTrialRequestLazyQuery>;
export type TrialRequestQueryResult = ApolloReactCommon.QueryResult<TrialRequestQuery, TrialRequestQueryVariables>;
export const TrialRequestsDocument = gql`
    query trialRequests($first: Int = 32, $after: String, $status: TrialRequestStatus!) {
  trialRequests(
    first: $first
    after: $after
    status: $status
    orderBy: "createdAt"
    orderDirection: DESC
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...TrialRequest
      }
    }
  }
}
    ${TrialRequestFragmentDoc}`;

/**
 * __useTrialRequestsQuery__
 *
 * To run a query within a React component, call `useTrialRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTrialRequestsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<TrialRequestsQuery, TrialRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TrialRequestsQuery, TrialRequestsQueryVariables>(TrialRequestsDocument, options);
      }
export function useTrialRequestsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TrialRequestsQuery, TrialRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TrialRequestsQuery, TrialRequestsQueryVariables>(TrialRequestsDocument, options);
        }
export type TrialRequestsQueryHookResult = ReturnType<typeof useTrialRequestsQuery>;
export type TrialRequestsLazyQueryHookResult = ReturnType<typeof useTrialRequestsLazyQuery>;
export type TrialRequestsQueryResult = ApolloReactCommon.QueryResult<TrialRequestsQuery, TrialRequestsQueryVariables>;
export const TrialSignupDocument = gql`
    mutation trialSignup($input: TrialSignupInput!) {
  trialSignup(input: $input) {
    trialRequest {
      id
    }
  }
}
    `;
export type TrialSignupMutationFn = ApolloReactCommon.MutationFunction<TrialSignupMutation, TrialSignupMutationVariables>;

/**
 * __useTrialSignupMutation__
 *
 * To run a mutation, you first call `useTrialSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrialSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trialSignupMutation, { data, loading, error }] = useTrialSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrialSignupMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<TrialSignupMutation, TrialSignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<TrialSignupMutation, TrialSignupMutationVariables>(TrialSignupDocument, options);
      }
export type TrialSignupMutationHookResult = ReturnType<typeof useTrialSignupMutation>;
export type TrialSignupMutationResult = ApolloReactCommon.MutationResult<TrialSignupMutation>;
export type TrialSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<TrialSignupMutation, TrialSignupMutationVariables>;
export const UpdateTrialRequestCampaignDocument = gql`
    mutation updateTrialRequestCampaign($input: UpdateTrialRequestCampaignInput!) {
  updateTrialRequestCampaign(input: $input) {
    trialRequestCampaign {
      ...TrialRequestCampaign
    }
  }
}
    ${TrialRequestCampaignFragmentDoc}`;
export type UpdateTrialRequestCampaignMutationFn = ApolloReactCommon.MutationFunction<UpdateTrialRequestCampaignMutation, UpdateTrialRequestCampaignMutationVariables>;

/**
 * __useUpdateTrialRequestCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateTrialRequestCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrialRequestCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrialRequestCampaignMutation, { data, loading, error }] = useUpdateTrialRequestCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrialRequestCampaignMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateTrialRequestCampaignMutation, UpdateTrialRequestCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateTrialRequestCampaignMutation, UpdateTrialRequestCampaignMutationVariables>(UpdateTrialRequestCampaignDocument, options);
      }
export type UpdateTrialRequestCampaignMutationHookResult = ReturnType<typeof useUpdateTrialRequestCampaignMutation>;
export type UpdateTrialRequestCampaignMutationResult = ApolloReactCommon.MutationResult<UpdateTrialRequestCampaignMutation>;
export type UpdateTrialRequestCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTrialRequestCampaignMutation, UpdateTrialRequestCampaignMutationVariables>;
export const ViewerCampaignDocument = gql`
    query viewerCampaign {
  viewer {
    id
    teacher {
      id
      site {
        id
        license {
          id
          trialRequest {
            id
            trialRequestCampaign {
              id
              identifier
              welcomeMessage {
                background
                title
                message
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useViewerCampaignQuery__
 *
 * To run a query within a React component, call `useViewerCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerCampaignQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerCampaignQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ViewerCampaignQuery, ViewerCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ViewerCampaignQuery, ViewerCampaignQueryVariables>(ViewerCampaignDocument, options);
      }
export function useViewerCampaignLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ViewerCampaignQuery, ViewerCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ViewerCampaignQuery, ViewerCampaignQueryVariables>(ViewerCampaignDocument, options);
        }
export type ViewerCampaignQueryHookResult = ReturnType<typeof useViewerCampaignQuery>;
export type ViewerCampaignLazyQueryHookResult = ReturnType<typeof useViewerCampaignLazyQuery>;
export type ViewerCampaignQueryResult = ApolloReactCommon.QueryResult<ViewerCampaignQuery, ViewerCampaignQueryVariables>;