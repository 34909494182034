import {
  AssignmentWithRemainingAttemptsFragment,
  BasicAssignmentFragment,
  RevealAnswers,
} from '../schema'

export function assignmentAllowsForfeit(
  assignment: Pick<
    BasicAssignmentFragment & AssignmentWithRemainingAttemptsFragment,
    'revealAnswers' | 'remainingAttempts' | 'maxAttempts' | 'revealAttempts'
  >,
) {
  const revealAfterSubmit = assignment.revealAnswers === RevealAnswers.AfterSubmit
  const numAttempts = assignment.maxAttempts - (assignment.remainingAttempts || 0)
  const alreadyRevealed = assignment.revealAttempts && assignment.revealAttempts <= numAttempts
  return revealAfterSubmit && !alreadyRevealed
}
