import { FileField } from '@thesisedu/feature-attachments-react'
import { TagsEditor } from '@thesisedu/feature-tags-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Result } from '@thesisedu/react'
import { LoadingIndicator } from '@thesisedu/ui'
import { BodySmall, Block } from '@thesisedu/web'
import { Button, Checkbox, Form, Typography } from 'antd'
import { FormProps } from 'antd/es/form'
import React from 'react'

import { useHydratedSegment } from './useHydratedSegment'
import { SegmentProps } from '../types'
import { useTagsEditorProps } from '../useOptions'

const DEBOUNCE = 3000

export function EditableSegmentContent(props: EditableSegmentContentProps) {
  const { segment, loading, error } = useHydratedSegment(props.segment)
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading content...'} size={'l'} />
  } else if (error) {
    return <Result.Error description={'There was an error loading that content.'} />
  } else {
    return <_EditableSegmentContent {...props} segment={segment ?? props.segment} />
  }
}

export interface ChildrenOpts {
  form: FormProps['form']
  submitFields: React.ReactElement | null
  childrenFields: React.ReactElement | null
  segment: SegmentProps['segment']
}
export interface EditableSegmentContentProps extends SegmentProps {
  onCancelClick?: () => void
  children: (opts: ChildrenOpts) => React.ReactElement
}
function _EditableSegmentContent({
  onChange,
  onCancelClick,
  segment,
  loading,
  children,
  onDirty,
  form: _form,
  autoSave,
}: EditableSegmentContentProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = _form ? [_form] : Form.useForm()
  const saveTimeout = React.useRef<any>()
  const viewer = useViewerContext(false)
  const editorProps = useTagsEditorProps(segment.type)
  const submitFields = (
    <>
      {viewer?.group === 'ADMIN' ? (
        <>
          <Form.Item
            label={'Always Enabled'}
            name={['config', 'alwaysEnabled']}
            initialValue={segment.config.alwaysEnabled}
            valuePropName={'checked'}
            extra={
              'If this is checked, this segment (and its parents) will automatically be enabled, and the teacher will not be able to disable them.'
            }
          >
            <Checkbox>Always Enabled</Checkbox>
          </Form.Item>
          <Form.Item label={'Tags'} name={'updateTags'} initialValue={segment.tags}>
            <TagsEditor {...editorProps} />
          </Form.Item>
          <Form.Item
            label={'Thumbnail - Square'}
            name={['config', 'thumbnail', 'square']}
            initialValue={segment.config?.thumbnail?.square}
          >
            <FileField uploadProps={{ isPublic: true, maxFileSizeBytes: 256e3 }} />
          </Form.Item>
          <Form.Item
            label={'Thumbnail - 16:9'}
            name={['config', 'thumbnail', 'landscape']}
            initialValue={segment.config?.thumbnail?.landscape}
          >
            <FileField uploadProps={{ isPublic: true, maxFileSizeBytes: 256e3 }} />
          </Form.Item>

          <Form.Item
            label={'Exclude in Trial'}
            name={['config', 'excludeInTrial']}
            initialValue={segment.config?.excludeInTrial}
            valuePropName={'checked'}
          >
            <Checkbox>Excluded in Trial</Checkbox>
          </Form.Item>
          <Form.Item
            label={'Hidden in Search'}
            name={'isHiddenInSearch'}
            initialValue={segment.isHiddenInSearch}
            valuePropName={'checked'}
          >
            <Checkbox>Segment is hidden in search</Checkbox>
          </Form.Item>
          <Form.Item
            label={'Is Copyable'}
            name={['config', 'isCopyable']}
            initialValue={segment.config?.isCopyable}
            valuePropName={'checked'}
          >
            <Checkbox>Segment is Copyable</Checkbox>
          </Form.Item>
        </>
      ) : null}
      {autoSave ? null : (
        <Form.Item>
          {onCancelClick ? (
            <Button onClick={onCancelClick} style={{ marginRight: 10 }}>
              Cancel
            </Button>
          ) : null}
          <Button type={'primary'} loading={loading} htmlType={'submit'}>
            Save
          </Button>
        </Form.Item>
      )}
      {viewer?.group === 'ADMIN' ? (
        <Block marginTop={'@size-m'}>
          <Typography.Paragraph code copyable style={{ marginBottom: 4 }}>
            {segment.id}
          </Typography.Paragraph>
          <BodySmall color={'@text-color-secondary'}>Segment ID</BodySmall>
        </Block>
      ) : null}
    </>
  )

  return (
    <Form
      form={form}
      onFinish={values => {
        clearTimeout(saveTimeout.current)
        if (onChange) {
          const formattedValues = { ...values, index: values.index || null }
          onChange(segment, formattedValues)
        }
      }}
      layout={'vertical'}
      onValuesChange={changedValues => {
        if (autoSave) {
          clearTimeout(saveTimeout.current)
          saveTimeout.current = setTimeout(() => {
            form.submit()
          }, DEBOUNCE)
        }
        if (onDirty) {
          onDirty()
        }
      }}
    >
      {children({
        form,
        childrenFields: null,
        submitFields,
        segment,
      })}
    </Form>
  )
}
