import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { LicensesQuery, LicensesQueryVariables, LicensesDocument, LicenseFragment } from './schema'

export type LicenseSelectProps = Partial<
  SingleSelectButtonProps<LicenseFragment, LicensesQuery, LicensesQueryVariables>
>
export function LicenseSelect(props: LicenseSelectProps) {
  return (
    <SingleSelectButton<LicenseFragment, LicensesQuery, LicensesQueryVariables>
      filterVariableName={'name'}
      selectLabel={'Select a License'}
      maxSelection={1}
      getItemName={item => item.site.name}
      getItemSubtitle={item => item.type}
      document={LicensesDocument}
      getResult={data => data?.licenses}
      setResult={(previous, result) => ({
        ...previous,
        licenses: {
          ...previous.licenses,
          ...result,
        },
      })}
      {...props}
    />
  )
}
