import { Format } from '@thesisedu/feature-utils'
import { Well, Text } from '@thesisedu/ui'
import React from 'react'

import { useSegmentUseInUnownedClassesQuery } from '../../schema'

export interface UseInClassesWarningProps {
  globalSegmentId: string
}
export function UseInClassesWarning({ globalSegmentId }: UseInClassesWarningProps) {
  const { data } = useSegmentUseInUnownedClassesQuery({
    variables: {
      segmentId: globalSegmentId,
    },
  })
  const use = data?.node?.__typename === 'Segment' ? data?.node?.useInUnownedClasses : undefined
  if (use && use > 0) {
    return (
      <Well
        vertical={'m'}
        status={'warning'}
        title={`This content is used in ${Format.plural('other class', use, {
          plural: 'other classes',
        })} that you don't own or belong to.`}
      >
        <Text color={'secondary'}>
          This content could be in another class you don't own because you used class templates to
          share your class with someone, you were a team teacher in another class and added your
          content to it, or another reason. Be careful when editing it, as any changes made here
          will automatically be reflected in all classes that are using this content.
        </Text>
      </Well>
    )
  } else return null
}
