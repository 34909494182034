import React from 'react'

import { Piano } from './Piano'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function AdvancedColorsContent(props: ModeContentsProps) {
  return <Piano colorNames {...props} />
}

export const AdvancedColors: Mode = {
  id: 'AdvancedColors',
  name: 'Colors',
  icon: require('../../../../assets/sketch/sheet-music/color-red.svg').default,
  large: false,
  types: [KeyboardType.Note25],
  contents: AdvancedColorsContent,
}
