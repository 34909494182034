import { TuneNoteBase, TuneMeter } from '@thesisedu/feature-sheet-music-core'
import React from 'react'

export * from '@thesisedu/feature-sheet-music-core'

export interface AdditionalNoteContent {
  render: (noteItem: TuneNoteBase) => React.ReactElement | null
  height: number
}

export interface NoteItemContextValue {
  noteTopContent?: AdditionalNoteContent
}

export interface CommonSheetMusicViewerProps {
  showChords?: boolean
  chordOpacity?: number
  showSolfege?: boolean
  solfegeOffset?: number
  showLyrics?: boolean
  showNote?: boolean
  noClef?: boolean
  meter: TuneMeter
}

export const STAFF_COLOR = '@gray-3'
export const HEIGHTS = {
  staff: 125,
  chord: 75,
  notes: 75,
  solfege: 100,
  lyrics: 40,
}
