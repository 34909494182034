import Hls, { Level, MediaPlaylist } from 'hls.js'
import React from 'react'

import { debug, warn } from '../log'

const LEVEL_HEIGHTS = [240, 360, 480, 720, 720, 1080, 1080, 1440, 1440, 2160, 2160]

export interface HlsVideoProps extends React.HTMLProps<HTMLVideoElement> {
  src: string
  onLevels?: (levels: Level[]) => void
  onCurrentLevelChange?: (currentLevel: number) => void
  currentLevel?: number
  onSubtitleTracks?: (tracks: MediaPlaylist[]) => void
  onSubtitleTrackChange?: (currentSubtitleTrack: number) => void
  currentSubtitleTrack?: number
  onSubtitleChange?: (text?: string, previousText?: string) => void
  autoLevelBasedOnSizeCapPadding?: number
  startLevel?: number
}
export const HlsVideo = React.forwardRef(
  (
    {
      src,
      onLevels,
      startLevel = 3, // Start at 720p instead of 1080p, because some videos are only 720p
      currentLevel,
      onCurrentLevelChange,
      onSubtitleTracks,
      onSubtitleTrackChange,
      onSubtitleChange,
      currentSubtitleTrack = -1,
      autoLevelBasedOnSizeCapPadding = 0,
      ...videoProps
    }: HlsVideoProps,
    ref: React.ForwardedRef<HTMLVideoElement>,
  ) => {
    const videoRef = React.useRef<HTMLVideoElement | null>(null)
    const hlsRef = React.useRef<Hls | null>(null)
    const cuesRef = React.useRef<Record<string, VTTCue[]>>({})
    const currentCueText = React.useRef<string | undefined>(undefined)
    function _refreshCurrentCue() {
      if (videoRef.current) {
        const currentTime = videoRef.current.currentTime
        const cues = cuesRef.current[`subtitles${hlsRef.current?.subtitleTrack}`] || []
        const currentCueIndex = cues.findIndex(
          cue => currentTime >= cue.startTime && currentTime < cue.endTime,
        )
        const currentCue = currentCueIndex > -1 ? cues[currentCueIndex] : undefined
        const previousCue = currentCueIndex > 0 ? cues[currentCueIndex - 1] : undefined
        if (currentCue?.text !== currentCueText.current) {
          currentCueText.current = currentCue?.text
          if (onSubtitleChange) {
            onSubtitleChange(currentCueText.current, previousCue?.text)
          }
        }
      } else {
        currentCueText.current = undefined
        if (onSubtitleChange) {
          onSubtitleChange(currentCueText.current)
        }
      }
    }

    React.useEffect(() => {
      if (currentLevel !== undefined && hlsRef.current) {
        if (hlsRef.current.currentLevel !== currentLevel) {
          hlsRef.current.currentLevel = currentLevel
        }
      }
    }, [currentLevel])
    React.useEffect(() => {
      if (hlsRef.current) {
        if (hlsRef.current.subtitleTrack !== currentSubtitleTrack) {
          hlsRef.current.subtitleTrack = currentSubtitleTrack
        }
      }
    }, [currentSubtitleTrack])
    React.useEffect(() => {
      function _initPlayer() {
        if (hlsRef.current) {
          hlsRef.current.destroy()
        }
        const newHls = new Hls({
          abrEwmaDefaultEstimate: 5000000, // 5mbps
          enableWorker: true, // We might have to disable this.
          startLevel,
          renderTextTracksNatively: false,
        })
        if (videoRef.current) {
          debug('attaching to video player')
          newHls.attachMedia(videoRef.current)

          videoRef.current.addEventListener('timeupdate', () => {
            _refreshCurrentCue()
          })
        }
        newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
          debug('loading source')
          newHls.loadSource(src)
          newHls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (onLevels) {
              onLevels(newHls.levels)
            }
            if (onCurrentLevelChange) {
              onCurrentLevelChange(newHls.currentLevel)
            }
          })
          newHls.on(Hls.Events.MANIFEST_LOADED, (event, data) => {
            if (onSubtitleTracks) {
              const addedTracks = new Set<string>()
              const uniqueSubtitleTracks = newHls.subtitleTracks.filter((track, index, tracks) => {
                if (!addedTracks.has(track.name)) {
                  addedTracks.add(track.name)
                  return true
                } else return false
              })
              onSubtitleTracks(uniqueSubtitleTracks)
            }
            if (onSubtitleTrackChange) {
              onSubtitleTrackChange(newHls.subtitleTrack)
            }
          })
          newHls.on(Hls.Events.CUES_PARSED, (event, data) => {
            cuesRef.current[data.track] = data.cues
            _refreshCurrentCue()
          })
          newHls.on(Hls.Events.SUBTITLE_TRACK_SWITCH, (event, data) => {
            _refreshCurrentCue()
            if (onSubtitleTrackChange) {
              onSubtitleTrackChange(data.id)
            }
          })
          newHls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
            if (onCurrentLevelChange) {
              onCurrentLevelChange(data.level)
            }
          })
        })
        newHls.on(Hls.Events.ERROR, (event, data) => {
          warn('HLS Error')
          warn(data)
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                newHls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                newHls.recoverMediaError()
                break
              default:
                _initPlayer()
                break
            }
          }
        })

        hlsRef.current = newHls
      }

      if (Hls.isSupported()) {
        _initPlayer()
      }

      let observer: ResizeObserver | null = null
      if (videoRef.current) {
        observer = new ResizeObserver(entries => {
          const media = entries[0]
          if (media?.contentRect && hlsRef.current) {
            const height = media.contentRect.height
            let levelIndex =
              LEVEL_HEIGHTS.findIndex(levelHeight => levelHeight > height) +
              autoLevelBasedOnSizeCapPadding
            if (LEVEL_HEIGHTS[levelIndex + 1] === LEVEL_HEIGHTS[levelIndex]) {
              levelIndex++ // If there is a 60fps version, use that.
            }
            hlsRef.current.autoLevelCapping = levelIndex
            debug('capping to level %d', levelIndex)
          }
        })
        observer.observe(videoRef.current)
      }

      return () => {
        if (hlsRef.current) {
          hlsRef.current.destroy()
        }
        if (observer) {
          observer.disconnect()
        }
      }
    }, [src, videoRef, autoLevelBasedOnSizeCapPadding])

    function refHandler(c: HTMLVideoElement | null) {
      videoRef.current = c
      if (typeof ref === 'function') {
        ref(c)
      } else if (ref) {
        ref.current = c
      }
    }

    // eslint-disable-next-line react/jsx-no-bind
    if (Hls.isSupported()) return <video ref={refHandler} {...videoProps} />
    // eslint-disable-next-line react/jsx-no-bind
    return <video ref={refHandler} src={src} {...videoProps} />
  },
)
