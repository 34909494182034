import { mergeCollections } from '@thesisedu/feature-utils'
import { Button, useToast } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useUpdateClassMutation } from '../../classes/useUpdateClassMutation'
import { error } from '../../log'
import { useClassCourseQuery } from '../../schema'
import { useSplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'

export interface PlacementDrawerSaveButtonProps {
  classId: string
  onClose: () => void
}
export function PlacementDrawerSaveButton({ classId, onClose }: PlacementDrawerSaveButtonProps) {
  const toast = useToast()
  const navigate = useNavigate()
  const { data, loading } = useClassCourseQuery({ variables: { id: classId } })
  const { changes, addedSegmentIds, changedSegmentIds } = useSplitViewPendingChangesContext(true)
  const [updateClass] = useUpdateClassMutation(classId)
  const hasChanges = addedSegmentIds.length > 0 || changedSegmentIds.length > 0
  const classCourse = data?.node?.__typename === 'Class' ? data.node : undefined
  const segmentMetadataRef = React.useRef(classCourse?.segmentMetadata)
  segmentMetadataRef.current = classCourse?.segmentMetadata

  return (
    <Button
      children={'Save Changes'}
      variant={'primary'}
      icon={<Check />}
      loading={loading}
      disabled={!classCourse || !hasChanges}
      onPress={async () => {
        const update = toast({
          title: 'Adding content to your class...',
          loading: true,
        })
        try {
          const existingMetadata = segmentMetadataRef.current
          const prom = updateClass({
            segmentMetadata: {
              segments: mergeCollections(
                existingMetadata?.segments ?? [],
                changes.metadataOverrides,
              ),
              structureOverrides: mergeCollections(
                existingMetadata?.structureOverrides ?? [],
                changes.structureOverrides,
              ),
            },
          })
          onClose()
          await prom
          const segmentId =
            changes.structureOverrides.length === 1 ? changes.structureOverrides[0].id : undefined
          update({
            title: 'Content added to your class!',
            status: 'success',
            actionAlt: segmentId ? 'View in Class' : undefined,
            action: segmentId ? (
              <Button
                children={'View in Class'}
                onPress={() => {
                  const params = new URLSearchParams({
                    highlight: segmentId,
                  })
                  navigate(`/teacher/classes/${classId}/course/outline?${params.toString()}`)
                }}
              />
            ) : undefined,
          })
        } catch (err: any) {
          error('error saving changes to class %O', err)
          update({
            title: 'Error adding content to your class',
            status: 'error',
          })
        }
      }}
    />
  )
}
