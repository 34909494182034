import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { MultiplePagesAdd } from '@thesisedu/ui/icons'
import React from 'react'

import { PDFSettingsModal } from './PDFSettingsModal'
import { PDFView } from './PDFView'
import { PDFConfig } from './common'

export function PDFElement({ config, node }: SimpleWidget.SimpleWidgetProps<PDFConfig>) {
  if (config.file) {
    return <PDFView {...config} file={config.file} />
  } else {
    return (
      <SimpleWidget.WidgetEmptyState
        title={'PDF'}
        identifier={'PDF'}
        settingsModal={PDFSettingsModal}
        node={node}
        buttonLabel={'Upload a PDF...'}
        icon={<MultiplePagesAdd />}
      />
    )
  }
}
