import React from 'react'

import { getPreAuthenticateWithProvider } from './preAuthenticateWithProvider'
import { SiteClassSyncProviderFragment } from '../../schema'
import { ProviderError } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, TransitionOpts, getStepFactory } from '../types'

export interface ProviderErrorContext {
  provider: SiteClassSyncProviderFragment
  nextStep: TransitionOpts
}
export default {
  size: {
    width: StepSizes.loading.width,
    height: 400,
  },
  hideClose: false,
  centerVertically: true,
  Component({ transition, context }) {
    return (
      <ProviderError
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        onNext={() => {
          transition(getPreAuthenticateWithProvider(context))
        }}
      />
    )
  },
} satisfies SetupStep<ProviderErrorContext>
export const getProviderError = getStepFactory<ProviderErrorContext>('providerError')
