import { LoadingOutlined } from '@ant-design/icons'
import { useForwardedRef } from '@thesisedu/react'
import { styled } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { OutlineFilters } from './OutlineFilters'
import { OutlineListImperativeHandle } from './OutlineList'
import { OutlineParts } from './OutlineParts'
import { OutlineSearchContext, OutlineSearchContextProvider } from './OutlineSearchContext'
import { TopicsListProps } from './TopicsList'
import { OutlineCollapseProvider } from './context/OutlineCollapseContext'
import { getTermHasStudentContent } from './helpers'
import { OutlineItem } from './types'
import { useExpandedParentIds } from './useExpandedParentIds'
import { OutlineDataOpts, useOutlineData } from './useOutlineData'

export interface UseOutlineResult {
  children: React.ReactElement
  items: OutlineItem[]
}
export function useOutline(
  props: OutlineProps,
  _ref?:
    | React.RefObject<OutlineListImperativeHandle>
    | React.ForwardedRef<OutlineListImperativeHandle>,
): UseOutlineResult {
  const {
    segments,
    courseOrClassId,
    headerContentHeight = 0,
    segmentFilter,
    segmentHeight,
    hideEmptyGroups,
    topicsListProps,
    expandedParentIds: _expandedParentIds,
    onExpandedParentIdsChange,
    forceRenderIds,
  } = props
  // Workaround because we need to support both refs and forwarded refs.
  const ref = Object.keys(_ref ?? {}).includes('current')
    ? (_ref as React.RefObject<OutlineListImperativeHandle>)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useForwardedRef(_ref)

  // Handle expanded state with prop fallback.
  const localExpandedState = useExpandedParentIds(courseOrClassId)
  let expandedState: [string[], (v: string[]) => void, { loading: boolean }]
  if (onExpandedParentIdsChange !== undefined) {
    expandedState = [_expandedParentIds || [], onExpandedParentIdsChange, { loading: false }]
  } else {
    expandedState = localExpandedState
  }
  const [expandedParentIds, setExpandedParentIds] = expandedState

  const data = useOutlineData({
    ...props,
    expandedParentIds,
  })
  const hasChildren = hideEmptyGroups
    ? getTermHasStudentContent(data.selectedTerm)
    : !!data.selectedTerm?.childSegments?.length
  const hasSearchContext = !!React.useContext(OutlineSearchContext)
  const contentRightContainer = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [data.selectedTermId])
  let displayChild: React.ReactElement = <div />
  if (data.selectedTerm && !hasChildren) {
    displayChild = <OutlineParts.Empty data={data} />
  } else if (data.selectedTerm) {
    displayChild = (
      <OutlineContentContainer>
        <OutlineContentLeft offset={95 + headerContentHeight} className={'outline-content-left'}>
          <OutlineParts.Topics
            {...topicsListProps}
            data={data}
            segmentFilter={segmentFilter}
            segmentHeight={segmentHeight}
            onSegmentClicked={id => {
              setTimeout(() => {
                if (ref.current) {
                  ref.current.highlightSegment(id)
                }
              }, 50)
            }}
          />
        </OutlineContentLeft>
        <OutlineContentRight ref={contentRightContainer}>
          <OutlineParts.WindowList
            data={data}
            segmentHeight={segmentHeight}
            forceRenderIds={forceRenderIds}
            ref={ref}
          />
        </OutlineContentRight>
      </OutlineContentContainer>
    )
  }
  let contextChildren = (
    <>
      <OutlineFilters
        {...props}
        setSelectedTermId={data.setSelectedTermId}
        selectedTermId={data.selectedTermId}
      />
      <OutlineContainer>
        <OutlineContainerBefore>
          <Result icon={<LoadingOutlined size={75} />} title={'Loading course...'} />
        </OutlineContainerBefore>
        {displayChild}
      </OutlineContainer>
    </>
  )
  if (!hasSearchContext) {
    contextChildren = (
      <OutlineSearchContextProvider segments={segments || []}>
        {contextChildren}
      </OutlineSearchContextProvider>
    )
  }

  return {
    items: data.items,
    children: (
      <OutlineCollapseProvider
        children={contextChildren}
        expandedParentIds={expandedParentIds}
        setExpandedParentIds={setExpandedParentIds}
      />
    ),
  }
}

export interface OutlineProps extends OutlineDataOpts {
  courseName: string
  filterContent?: React.ReactElement
  headerContent?: React.ReactElement
  headerContentHeight?: number
  showAddContent?: boolean
  showTermActions?: boolean
  segmentHeight?: number
  topicsListProps?: Partial<TopicsListProps>
  onExpandedParentIdsChange?: (parentIds: string[]) => void
  forceRenderIds?: string[]
}
function _Outline(props: OutlineProps, ref: React.ForwardedRef<OutlineListImperativeHandle>) {
  const { children } = useOutline(props, ref)
  return children
}
export const Outline = React.forwardRef(_Outline)

const OutlineContainer = styled.div`
  position: relative;
  > div:last-child {
    z-index: 1;
    background: white;
    position: relative;
    min-height: 400px;
  }
`
const OutlineContainerBefore = styled.div`
  position: absolute;
  text-align: center;
  z-index: -0;
  top: ${props => props.theme['@size-xl']};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`
const OutlineContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
`
const OutlineContentLeft = styled.div<{ offset: number }>`
  min-width: 300px;
  margin-right: ${props => props.theme['@size-l']};
  flex: 0;
  overflow-y: auto;
  height: calc(100vh - ${props => props.offset}px - 50px);
  position: sticky;
  top: ${props => props.offset}px;
`
const OutlineContentRight = styled.div`
  flex: 1;
  align-self: stretch;
  position: relative;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`
