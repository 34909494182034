import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { QuestionSettingsValue } from './types'

export interface QuestionSettingsContextValue {
  form: Form$.FormInstance<QuestionSettingsValue>
}
export const QuestionSettingsContext = React.createContext<
  QuestionSettingsContextValue | undefined
>(undefined)

export function useQuestionSettingsContext(): QuestionSettingsContextValue | undefined
export function useQuestionSettingsContext(require: false): QuestionSettingsContextValue | undefined
export function useQuestionSettingsContext(require: true): QuestionSettingsContextValue
export function useQuestionSettingsContext(
  require?: boolean,
): QuestionSettingsContextValue | undefined {
  const context = React.useContext(QuestionSettingsContext)
  if (!context && require) {
    throw new Error('QuestionSettingsContext is required, yet not provided.')
  }
  return context
}
