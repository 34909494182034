import { useDroppable } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { ClassStudentPair } from '@thesisedu/feature-classes-react'
import { TextField, VSpaced, Well, s, styled } from '@thesisedu/ui'
import React from 'react'

import { DeleteGroupButton } from './DeleteGroupButton'
import { useGroupsContext } from './GroupsContext'
import { LeaderCheckbox } from './LeaderCheckbox'
import { StudentDraggable } from './StudentDraggable'
import { onChangeGroup } from './onChangeGroup'

export interface GroupProps {
  groupKey: string
}
export function Group({ groupKey }: GroupProps) {
  const { groups, onChange, students } = useGroupsContext(true)
  const { setNodeRef } = useDroppable({
    id: groupKey,
  })
  const group = groups[groupKey]
  const groupStudents = students[groupKey] as ClassStudentPair[] | null | undefined
  if (!group) throw new Error('Cannot find group.')
  return (
    <Well
      data-testid={'Group'}
      ref={setNodeRef}
      title={
        <TextField
          size={'small'}
          value={group.name}
          aria-label={'Group Name'}
          style={{ flex: 1 }}
          onChange={v => {
            onChangeGroup(groups, onChange, groupKey, { name: v })
          }}
          placeholder={'Group Name'}
        />
      }
      actions={<DeleteGroupButton groupKey={groupKey} />}
    >
      <Container space={'xs'}>
        <SortableContext
          items={groupStudents?.map(student => student.studentId) ?? []}
          strategy={verticalListSortingStrategy}
        >
          {groupStudents?.map(student => {
            return (
              <StudentDraggable
                key={student.studentId}
                student={student}
                right={<LeaderCheckbox groupKey={groupKey} studentId={student.studentId} />}
              />
            )
          }) ?? null}
        </SortableContext>
      </Container>
    </Well>
  )
}
const Container = styled(VSpaced)`
  min-height: 100px;
  position: relative;
  &::before {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${s.color('secondary')};
    font-size: ${s.size('font.s')};
    text-align: center;
    content: 'This group is empty';
    opacity: 0;
    pointer-events: none;
  }
  &:empty {
    display: flex;
    &::before {
      opacity: 1;
    }
  }
`
