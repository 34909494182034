import { Accidental } from './SimpleContext'

export type TupleOf<T, N extends number> = number extends N ? T[] : _TupleOf<T, N, []>
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>

export enum DragItemType {
  Note = 'note',
}
export interface DragItem {
  pitch?: number | (number | undefined)[]
  accidental?: Accidental | (Accidental | undefined)[]
  /** This is here in the event you are dragging a group of items */
  duration?: (number | undefined)[]
  /** This is for rendering the drag item inside the custom layer. */
  element?: React.ReactElement
  /** The width of the item being dragged. */
  width?: number
  /** The height of the item being dragged. */
  height?: number
}

export const COLORS = ['@red', '@orange', '@yellow', '@green', '@blue', '@purple', '@pink', '@red']
export const BELLS: TupleOf<React.FC<React.PropsWithChildren<unknown>>, 8> = [
  require('../svg/C').default,
  require('../svg/D').default,
  require('../svg/E').default,
  require('../svg/F').default,
  require('../svg/G').default,
  require('../svg/A').default,
  require('../svg/B').default,
  require('../svg/C2').default,
]
export const SOLFEGE: TupleOf<React.FC<React.PropsWithChildren<unknown>>, 8> = [
  require('../svg/SolfegeDo').default,
  require('../svg/SolfegeRe').default,
  require('../svg/SolfegeMi').default,
  require('../svg/SolfegeFa').default,
  require('../svg/SolfegeSol').default,
  require('../svg/SolfegeLa').default,
  require('../svg/SolfegeTi').default,
  require('../svg/SolfegeHiDo').default,
]
export const SCALE: TupleOf<React.FC<React.PropsWithChildren<unknown>>, 8> = [
  require('../svg/Number1').default,
  require('../svg/Number2').default,
  require('../svg/Number3').default,
  require('../svg/Number4').default,
  require('../svg/Number5').default,
  require('../svg/Number6').default,
  require('../svg/Number7').default,
  require('../svg/Number8').default,
]
export const NOTES: TupleOf<React.FC<React.PropsWithChildren<unknown>>, 8> = [
  require('../svg/NoteC').default,
  require('../svg/NoteD').default,
  require('../svg/NoteE').default,
  require('../svg/NoteF').default,
  require('../svg/NoteG').default,
  require('../svg/NoteA').default,
  require('../svg/NoteB').default,
  require('../svg/NoteHic').default,
]
