import { getSelectedResources, ReportResources } from '../../execute/input'
import { getNormalizedMetricResourcesArr } from '../../execute/useRunReportQuery'
import { ReportDimensionResource, ReportMetricResource, RunReportOpts } from '../../types'

export function isMetrics(
  item: ReportDimensionResource<any, any> | ReportMetricResource[],
): item is ReportMetricResource[] {
  return Array.isArray(item)
}

export type AxesResponsibility = Record<
  'row' | 'col',
  ReportDimensionResource<any, any> | ReportMetricResource[]
>
export function getAxesResponsibility(
  resources: ReportResources,
  opts: RunReportOpts,
): AxesResponsibility {
  let row: ReportDimensionResource<any, any> | ReportMetricResource[]
  let col: ReportDimensionResource<any, any> | ReportMetricResource[]
  const metricResources = getNormalizedMetricResourcesArr(resources.metrics, opts.metrics)
  const dimensionResources = getSelectedResources(resources.dimensions, opts.dimensions)
  if (dimensionResources.length === 1) {
    row = metricResources
    col = dimensionResources[0]
  } else if (dimensionResources.length === 2) {
    if (dimensionResources[0].hasMultipleValues) {
      row = dimensionResources[1]
      col = dimensionResources[0]
    } else {
      row = dimensionResources[1]
      col = metricResources
    }
  } else {
    throw new Error('Invalid metric / dimension configuration discovered.')
  }

  return { row, col }
}

export function getTopLeftHeader(responsibility: AxesResponsibility) {
  if (isMetrics(responsibility.row)) {
    return 'Metric'
  } else {
    return responsibility.row.title
  }
}
