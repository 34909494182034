import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/es/date-picker'
import React from 'react'

export interface DateFilterProps {
  beforeDatePickerProps?: Partial<DatePickerProps>
  afterDatePickerProps?: Partial<DatePickerProps>
  suffix?: React.ReactNode
}

export const DateFilter: React.FC<React.PropsWithChildren<DateFilterProps>> = ({
  beforeDatePickerProps,
  afterDatePickerProps,
  suffix,
}) => {
  return (
    <>
      <DatePicker placeholder={'After Date'} {...(afterDatePickerProps as any)} />
      <span>&nbsp;&nbsp;&nbsp;&mdash;&nbsp;&nbsp;&nbsp;</span>
      <DatePicker placeholder={'Before Date'} {...(beforeDatePickerProps as any)} />
      {suffix}
    </>
  )
}
