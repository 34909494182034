import { ClassSyncProviderResource } from '@thesisedu/feature-class-sync-react'
import { ReactFeature, ReactHooks, ReactUse } from '@thesisedu/feature-react'
import React from 'react'

export class MSTeamsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-ms-teams-react'
  static path = __dirname
  static requires = []

  public reactResources() {
    require('./hooks/routes').default(this)
    require('./hooks/SiteActions').default(this)
    const { ExpectedErrorHandler } = require('./ExpectedErrorHandler')
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => (
      <>
        <ExpectedErrorHandler />
        {children}
      </>
    ))
    this.resources.addResource<ClassSyncProviderResource>({
      type: 'ClassSyncProvider',
      identifier: 'ms-teams',
      logos: {
        horizontal: require('../assets/large.svg').default,
        square: require('../assets/icon.svg').default,
      },
    })
  }
}

export const msTeamsReactFeature: ReactUse = opts => [MSTeamsReactFeature, opts]
