import React from 'react'
// @ts-ignore
import { useDebounce } from 'use-debounce'

import { AddListItem } from './AddListItem'
import { SelectButtonContentProps } from './types'
import { RequiredVariables } from '../loadMore'
import { Node } from '../schema'

const FILTER_DEBOUNCE = 500

export function useSelectButtonContent<
  Item extends Node,
  Data,
  Variables extends RequiredVariables,
>(props: SelectButtonContentProps<Item, Data, Variables>) {
  const {
    variables: _variables,
    filterVariableName,
    onChange,
    value = [],
    maxSelection = Infinity,
    getItemValue = item => item.id,
  } = props
  const [filter, setFilter] = React.useState<string | undefined>()
  const [activeFilter] = useDebounce(filter, FILTER_DEBOUNCE)
  const [variables, setVariables] = React.useState<any>(_variables)
  React.useEffect(() => {
    setVariables(() => {
      if (typeof filterVariableName === 'function') {
        return filterVariableName(activeFilter, _variables)
      } else {
        return { ..._variables, [filterVariableName]: activeFilter }
      }
    })
  }, [_variables, activeFilter])
  const onItemSelected = (item: Item) => {
    if (!onChange) return
    if (value.some(sitem => sitem === getItemValue(item))) {
      onChange(
        value.filter(sitem => sitem !== getItemValue(item)),
        [],
      )
    } else if (value.length >= maxSelection) {
      onChange([...value.slice(1), getItemValue(item)], [item])
    } else {
      onChange([...value, getItemValue(item)], [item])
    }
  }

  return {
    onItemSelected,
    setFilter,
    filter,
    variables,
    getItemValue,
  }
}

export type AddListItemT = 'add'
export type ContentListItem<Item extends Node> = Item | AddListItemT
export interface UseRenderItemOpts<Item extends Node>
  extends Pick<SelectButtonContentProps<Item, any, any>, 'onAddFromSearch' | 'maxSelection'> {
  filter?: string
}
export function useRenderItem<Item extends Node>(
  renderItem: (item: Item) => React.ReactElement,
  { onAddFromSearch, maxSelection, filter }: UseRenderItemOpts<Item>,
) {
  return (item: ContentListItem<Item>) => {
    if (item === 'add') {
      if (filter && onAddFromSearch) {
        return <AddListItem {...{ onAddFromSearch, maxSelection, filter }} />
      } else return null
    } else return renderItem(item)
  }
}

export interface UseAddListItemOpts<Item extends Node, Data, Variables extends RequiredVariables>
  extends Pick<SelectButtonContentProps<Item, Data, Variables>, 'onAddFromSearch' | 'getItemName'> {
  filter?: string
}
export interface UseAddListItem<Item extends Node> {
  getAddItem: (resultItems: Item[]) => AddListItemT | null
}
export function useAddListItem<Item extends Node, Data, Variables extends RequiredVariables>({
  onAddFromSearch,
  getItemName,
  filter,
}: UseAddListItemOpts<Item, Data, Variables>): UseAddListItem<Item> {
  return {
    getAddItem(resultItems): AddListItemT | null {
      if (onAddFromSearch && filter) {
        if (
          !resultItems.some(item => {
            const itemName = getItemName(item)
            return itemName.toLowerCase().trim() === filter.toLowerCase().trim()
          })
        ) {
          return 'add'
        } else {
          return null
        }
      } else return null
    },
  }
}
