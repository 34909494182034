import { flattenSegments, getSegmentName } from '@thesisedu/feature-course-types'
import { useScrollableContainerContext } from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import { Body, Capital, styled } from '@thesisedu/web'
import { Empty } from 'antd'
import classnames from 'classnames'
import React from 'react'

import { OutlineSearchWrapper } from './OutlineSearchContext'
import { SegmentInfoPopover } from './SegmentInfoPopover'
import { getOutlineItemHeight, OutlineItem, SegmentOutlineItem } from './types'
import { useHoverNavigate } from './useHoverNavigate'
import { NON_GROUP_TYPES } from '../constants'
import { getSegmentIds } from '../helpers'
import { SegmentType } from '../schema'
import { GroupSegment, Segment } from '../types'

function getClosestElement(
  scrollY: number,
  items: OutlineItem[],
  depth: number,
  segmentHeight?: number,
) {
  let lastItem: OutlineItem | undefined = undefined
  let totalHeight: number = 0
  for (let i = 0; i < items.length; i++) {
    const item = items[i]
    if (totalHeight > scrollY) break
    totalHeight += getOutlineItemHeight(item, i, segmentHeight)
    if (item.depth === depth && !!(item as SegmentOutlineItem).segment) lastItem = item
  }
  return lastItem
}

export function getScrollHeight(items: OutlineItem[], id: string, segmentHeight?: number) {
  const itemIndex = items.findIndex(i => i.id === id)
  return items
    .slice(0, itemIndex)
    .reduce<number>((sum, i, index) => sum + getOutlineItemHeight(i, index, segmentHeight), 0)
}

export interface TopicsListProps {
  selectedTerm: GroupSegment
  segmentFilter?: (segment: Segment) => boolean
  segmentHeight?: number
  showPopover?: boolean
  showEmptyGroups?: boolean
  highlightDisabled?: boolean
  items: OutlineItem[]
  onSegmentClicked?: (topicId: string) => void
}
export function TopicsList({
  selectedTerm,
  showPopover,
  highlightDisabled,
  segmentFilter,
  segmentHeight,
  showEmptyGroups,
  items,
  onSegmentClicked,
}: TopicsListProps) {
  const [expandedTopicIds, setExpandedTopicIds] = React.useState<string[]>([])
  const [popoverVisibleId, setPopoverVisibleId] = React.useState<string | null>(null)
  const [selectedId, setSelectedId] = React.useState<string | null>(null)
  const [selectedChildId, setSelectedChildId] = React.useState<string | null>(null)
  const { target: scrollTarget } = useScrollableContainerContext(false) || {}
  const scrollTargetElement = scrollTarget?.current || window
  const topics = (selectedTerm.childSegments || []).filter(segment => {
    if (NON_GROUP_TYPES.includes(segment.type as SegmentType)) return false
    if (!showEmptyGroups && !segment.childSegments?.length) return false
    if (segmentFilter) {
      const children = flattenSegments([segment]) as Segment[]
      return children.some(segmentFilter)
    } else {
      return true
    }
  })
  const { onMouseEnter, onMouseLeave } = useHoverNavigate(id => {
    if (setSelectedId) {
      setSelectedId(id)
      return getScrollHeight(items, id, segmentHeight)
    } else {
      return undefined
    }
  })
  const animationFrameRef = React.useRef<any>()
  const calculate = React.useCallback(() => {
    if (!animationFrameRef.current) {
      animationFrameRef.current = requestAnimationFrame(() => {
        const scrollY = scrollTarget?.current ? scrollTarget.current.scrollTop : window.scrollY
        const newSelected = getClosestElement(scrollY, items, 0, segmentHeight)
        const newChildSelected = getClosestElement(scrollY, items, 1, segmentHeight)
        if (newSelected) setSelectedId(newSelected.id)
        if (newChildSelected) setSelectedChildId(newChildSelected.id)
        animationFrameRef.current = undefined
      })
    }
  }, [])
  React.useEffect(() => {
    calculate()
    scrollTargetElement.addEventListener('scroll', calculate, { passive: true })
    return () => scrollTargetElement.removeEventListener('scroll', calculate)
  }, [])
  return (
    <>
      <HeaderContainer>
        <Capital>Topics</Capital>
      </HeaderContainer>
      {topics.length ? (
        topics.map(topic => (
          <OutlineSearchWrapper key={topic.id} segmentIds={getSegmentIds([topic])}>
            <TopicListItem
              onClick={() => {
                setSelectedId(topic.id)
                if (onSegmentClicked) onSegmentClicked(topic.id)
              }}
              onMouseEnter={() => onMouseEnter(topic.id)}
              onMouseLeave={() => onMouseLeave(topic.id)}
              className={classnames({
                active: selectedId === topic.id,
                expanded: expandedTopicIds.includes(topic.id),
                enabled: !highlightDisabled || topic.visibleOverride,
                popover: popoverVisibleId === topic.id,
              })}
            >
              <div
                className={'arrow-button'}
                onClick={e => {
                  e.stopPropagation()
                  setExpandedTopicIds(ids =>
                    ids.includes(topic.id) ? ids.filter(id => id !== topic.id) : [...ids, topic.id],
                  )
                }}
              >
                <NavArrowRight className={'arrow'} />
              </div>
              <Body>{getSegmentName(topic)}</Body>
              {showPopover ? (
                <SegmentInfoPopover
                  visible={popoverVisibleId === topic.id}
                  onVisibleChange={v => {
                    setPopoverVisibleId(v ? topic.id : null)
                  }}
                  segment={topic}
                />
              ) : null}
            </TopicListItem>
            <TopicListChildren collapsed={!expandedTopicIds.includes(topic.id)}>
              {topic.childSegments
                ?.filter(segment => {
                  if (NON_GROUP_TYPES.includes(segment.type as SegmentType)) return false
                  if (!showEmptyGroups && !segment.childSegments?.length) return false
                  if (segmentFilter) {
                    const children = flattenSegments([segment]) as Segment[]
                    return children.some(segmentFilter)
                  } else {
                    return true
                  }
                })
                .map(child => (
                  <TopicChildListItem
                    key={child.id}
                    onClick={() => {
                      setSelectedId(topic.id)
                      setSelectedChildId(child.id)
                      if (onSegmentClicked) onSegmentClicked(topic.id)
                    }}
                    onMouseEnter={() => onMouseEnter(child.id)}
                    onMouseLeave={() => onMouseLeave(child.id)}
                    className={classnames({
                      active: selectedChildId === child.id,
                      enabled: !highlightDisabled || child.visibleOverride,
                      popover: popoverVisibleId === child.id,
                    })}
                  >
                    <Body>{getSegmentName(child)}</Body>
                    {showPopover ? (
                      <SegmentInfoPopover
                        visible={popoverVisibleId === child.id}
                        onVisibleChange={v => {
                          setPopoverVisibleId(v ? child.id : null)
                        }}
                        segment={child}
                      />
                    ) : null}
                  </TopicChildListItem>
                ))}
            </TopicListChildren>
          </OutlineSearchWrapper>
        ))
      ) : (
        <Empty
          description={'There are no topics in this term.'}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  margin: 0 0 ${props => props.theme['@size-xs']} ${props => props.theme['@size-xs']};
`

const TopicListChildren = styled.div<{ collapsed?: boolean }>`
  transition: ${props =>
    props.collapsed
      ? 'max-height 0s ease-in-out 0.25s, margin-bottom 0s ease-in-out 0.25s, opacity 0.1s linear'
      : 'max-height 0s ease-in-out, margin-bottom 0s ease-in-out, opacity 0.1s linear 0.1s;'};
  overflow-y: auto;
  max-height: ${props => (props.collapsed ? '0px' : '10000px')};
  margin-bottom: ${props => (props.collapsed ? '0px' : props.theme['@size-xl'])};
  opacity: ${props => (props.collapsed ? 0 : 1)};
  margin-left: ${props => props.theme['@size-s']};
`
const TopicListItem = styled.div`
  margin-top: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition:
    color 0.1s linear,
    background 0.1s linear;
  background: transparent;
  overflow: hidden;
  width: 100%;
  text-align: left;
  line-height: 1.2;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.theme['@gray-4']};
  &.enabled {
    color: ${props => props.theme['@gray-5']};
  }
  p {
    margin-top: 4px;
    padding: ${props => props.theme['@size-xs']} 0;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
  .info-popover-btn {
    margin-left: auto;
    margin-right: ${props => props.theme['@size-xs']};
    svg {
      fill: ${props => props.theme['@text-color-secondary']};
      opacity: 0;
      transition: opacity 0.1s linear;
    }
  }
  .arrow-button {
    transition:
      background 0.1s linear,
      color 0.1s linear;
    background: transparent;
    color: ${props => props.theme['@text-color-secondary']};
    align-self: stretch;
    padding: ${props => props.theme['@size-xs']};
    display: flex;
    align-items: center;
    justify-content: center;
    .arrow {
      transition: transform 0.25s ease-in-out;
      transform: rotate(0deg);
      font-size: ${props => props.theme['@size-s']};
    }
    &:hover {
      background: ${props => props.theme['@gray-2']};
      color: ${props => props.theme['@gray-7']};
    }
  }
  &:hover,
  &.active {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
  &:hover .info-popover-btn svg,
  &.popover .info-popover-btn svg {
    opacity: 1;
  }
  &.active {
    color: ${props => props.theme['@primary-color']};
  }
  &.expanded .arrow {
    transform: rotate(90deg);
  }
`

const TopicChildListItem = styled(TopicListItem)`
  padding: ${props => props.theme['@size-xs']};
  p {
    padding: 0;
  }
  > :last-child {
    margin-right: 0;
  }
  &:hover,
  &.active {
    background: transparent;
  }
`
