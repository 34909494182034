import { DeepLinkResource } from '@thesisedu/feature-react'
import { UserGroupPrefixDeepLinkRemapper } from '@thesisedu/feature-users-react'

import { SitesWebFeature } from '../SitesWebFeature'

export default function (feature: SitesWebFeature) {
  feature.resources.addResource<DeepLinkResource>({
    type: 'DeepLink',
    identifier: 'sites',
    params: [{ identifier: 'id', required: false }],
    Component: UserGroupPrefixDeepLinkRemapper,
  })
}
