import { SolidIcon, Text } from '@thesisedu/ui'
import { Check, NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { InternalStandardSelectValue, ListItem } from './StandardSelect'

export interface TagListItemProps {
  tag: InternalStandardSelectValue
  value?: boolean
  onChange?: (value: boolean) => void
  hasChildren: boolean
  footer?: React.ReactNode
}
export function TagListItem({ tag, footer, hasChildren, value, onChange }: TagListItemProps) {
  return (
    <ListItem onClick={() => onChange?.(!value)} data-testid={`TagListItem ${tag.name}`}>
      <div>
        <Text level={'s'} style={{ minWidth: 0 }}>
          {tag.name}
        </Text>
        {footer}
      </div>
      {value ? (
        <SolidIcon background={'green'} level={'s'} icon={<Check />} left />
      ) : hasChildren ? (
        <Text level={'s'} color={'secondary'} left>
          <NavArrowRight />
        </Text>
      ) : null}
    </ListItem>
  )
}
