import { useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import { NO_CLASS_OPTION, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { useSingleInteraction } from '@thesisedu/feature-interactions-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Block } from '@thesisedu/web'
import React from 'react'

import { SegmentCompletionHandlerWrapper } from './useSegmentCompletion'
import { useSegmentCompletionHost } from './useSegmentCompletionHost'
import { useHydratedSegment } from '../../segment/useHydratedSegment'
import { Segment } from '../../types'

export function useMarkSegmentComplete(segmentId: string) {
  const record = useSingleInteraction()
  const selectedClassId = useSelectedClassId()
  const client = useApolloClient()

  return async () => {
    await record({
      type: 'COMPLETE_SEGMENT',
      metadata: {
        id: segmentId,
      },
      reference: selectedClassId === NO_CLASS_OPTION ? undefined : selectedClassId,
      options: {
        flushImmediately: true,
      },
    })
    await client.refetchQueries({
      include: ['classDecorations'],
    })
  }
}

export function HostWrapper({ segment }: SegmentCompletionOpts) {
  const Host = useSegmentCompletionHost()
  const markComplete = useMarkSegmentComplete(segment.id)
  if (Host) {
    return <Host segment={segment} onMarkComplete={markComplete} />
  } else {
    return null
  }
}

export interface SegmentCompletionOpts {
  segment: Segment
}
export interface UseSegmentCompletion {
  host: React.ReactElement | null
}
export function useSegmentCompletion({
  segment,
}: Partial<SegmentCompletionOpts>): UseSegmentCompletion {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'STUDENT' && segment) {
    return {
      host: <HostWrapper segment={segment} />,
    }
  } else {
    return {
      host: null,
    }
  }
}

export interface SegmentCompletionProps extends SegmentCompletionOpts {
  marginTop?: boolean
  weight?: number
}
export function SegmentCompletion({ marginTop, weight, ...opts }: SegmentCompletionProps) {
  const { host } = useSegmentCompletion(opts)
  const { error, loading } = useHydratedSegment(opts.segment)
  let content
  if (error || loading) {
    content = null
  } else if (host && marginTop) {
    content = (
      <Block marginTop={'@size-l'} marginLeft={'@size-l'} marginRight={'@size-l'}>
        {host}
      </Block>
    )
  } else {
    content = host
  }

  return content ? <SegmentCompletionHandlerWrapper children={content} weight={weight} /> : null
}
