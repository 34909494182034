import { PlusOutlined } from '@ant-design/icons'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { BrochureLink } from '@thesisedu/feature-react'
import { Body, BodyLarge, setFlag, DefinitionList, Block, PageHeader } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

import { AddContentButton } from '../outline/AddContentButton'
import { CourseFlags } from '../types'

export function ContentManagerIntro() {
  const { appOptions } = useFeatureRoot()!
  return (
    <>
      <PageHeader title={'My Content'} />
      <Block marginTop={'@size-l'}>
        <BodyLarge isBlock>
          The {appOptions.name} Content Manager allows you to{' '}
          <strong>create your own custom readings or assignments</strong> or{' '}
          <strong>use content from another course.</strong> We highly recommend you read this entire
          page to get the most out of the Content Manager.
        </BodyLarge>
      </Block>
      <Body isBlock>
        Since creating content for the {appOptions.name} LMS can get complicated, there are a few
        concepts you'll need to be familiar with:
      </Body>
      <DefinitionList>
        <li>
          <strong>Segments</strong>
          <p>
            Also known as <strong>content,</strong> a segment represents a single "thing" to do
            inside {appOptions.name}. A segment is placed inside a course, can contain other
            segments underneath it (making it a <strong>group</strong>), and is defined as a "type"
            of content. It could be a Section (something the student reads), or an Assignment
            (something the student does), or something else.
          </p>
        </li>
        <li>
          <strong>Tags</strong>
          <p>
            We use tags to help <strong>categorize</strong> segments and document what course
            they're designed to be used with, what standards the content covers, among other things.
            When searching through your own content or finding content in another course, you'll be
            able to search using these tags.
          </p>
        </li>
        <li>
          <strong>Sections and Assignments</strong>
          <p>
            A <strong>Section is something the student reads, or consumes.</strong> It could be a
            PowerPoint or video you would like the students to watch, or it could be a PDF you've
            uploaded for the students to read. It could also be your own custom piece of content
            created using our editor feature. An{' '}
            <strong>Assignment is something the student does.</strong> You can create an assignment
            with multiple choice or free-response questions, and can create your own custom
            instructions at the top of the page for the student to read.
          </p>
        </li>
        <li>
          <strong>Days &amp; Minutes</strong>
          <p>
            Each segment has a certain number of <strong>content days (or minutes)</strong>{' '}
            associated with it. This is how we automatically present your schedule to you and keep
            track of your progress throughout the semester. When you're creating your custom
            content, you'll need to enter about how many minutes you expect the content to take in
            order to complete it.
          </p>
        </li>
        <li>
          <strong>Content Placement</strong>
          <p>
            When you select content, you'll need to <strong>place</strong> it in your class. Placing
            content is the act of <strong>inserting a new piece of content into your class,</strong>{' '}
            making it available to your students. When content is placed, it will appear in your
            schedule, grade view, etc. just like everything else. When you want to remove a segment,
            you'll need to <strong>un-place</strong> it.
          </p>
        </li>
      </DefinitionList>
      <Body isBlock>
        If something above doesn't make sense, feel free to check out our knowledgebase article on
        the Content Manager&nbsp;
        <BrochureLink to={'support/portal/content-manager'}>here,</BrochureLink>
        {' or '}
        <Link target={'_blank'} to={'/teacher/resources'}>
          contact our support team.
        </Link>
      </Body>
      <Body isBlock>
        Now that you've got the basics down, click the{' '}
        <strong>
          <PlusOutlined /> Add Content
        </strong>
        &nbsp;button below to either create your own content, or choose content from another course
        to pull into this class.
      </Body>
      <Block marginTop={'@size-m'}>
        <AddContentButton
          size={'large'}
          variant={'primary'}
          onPress={() => {
            setFlag(CourseFlags.ContentManagerIntroductionShown, true)
          }}
        />
      </Block>
    </>
  )
}
