import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { PageHead, useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { AnnouncementRoute } from './AnnouncementRoute'
import { AnnouncementsList } from './AnnouncementsList'

export function Announcements() {
  const cls = useSelectedClass(true)
  useBreadcrumb({ title: 'Announcements' })
  return (
    <>
      <PageHead title={`Announcements - ${cls.name}`} />
      <Routes>
        <Route path={'/:announcementId'} element={<AnnouncementRoute />} />
        <Route path={'/'} element={<AnnouncementsList />} />
      </Routes>
    </>
  )
}
