import { SegmentedControl } from '@thesisedu/ui'
import React from 'react'

import { ReportDimensionResource } from '../../types'

export interface DimensionTypeSelectProps {
  dimensions: ReportDimensionResource<any, any>[]
  value?: string
  onChange?: (value: string) => void
}
export function DimensionTypeSelect({ dimensions, value, onChange }: DimensionTypeSelectProps) {
  return (
    <SegmentedControl style={{ width: '100%' }} size={'small'}>
      {dimensions.map(dimension => (
        <SegmentedControl.Item
          key={dimension.identifier}
          selected={value === dimension.identifier}
          onPress={() => {
            if (onChange) {
              onChange(dimension.identifier)
            }
          }}
          children={dimension.singular}
          icon={dimension.icon}
        />
      ))}
    </SegmentedControl>
  )
}
