import React from 'react'

export { useNavigate, Link, useLocation, Prompt } from 'react-router-dom'

export interface ExternalLinkProps {
  href: string
  target?: string
  style?: any
}
export function ExternalLink(props: React.PropsWithChildren<ExternalLinkProps>) {
  return <a {...props} />
}
