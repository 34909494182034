import { $unwrapMarkNode } from '@lexical/mark'
import { $getAllMarkNodes, $wrapSelectionInStyledMarkNode } from '@thesisedu/feature-widgets-core'
import { styled, Dropdown } from '@thesisedu/ui'
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical'
import React from 'react'

import { MarkType } from './types'
import { TextFormatToolbarButton } from '../../ui/TextFormatToolbar/TextFormatToolbarButton'

const markTypeStyledElements: Record<string, React.ComponentType<React.PropsWithChildren<any>>> = {}
function getMarkTypeStyledElement(
  type: MarkType,
): React.ComponentType<React.PropsWithChildren<any>> {
  if (!markTypeStyledElements[type.identifier]) {
    markTypeStyledElements[type.identifier] = styled.span`
      ${type.style}
    `
  }

  return markTypeStyledElements[type.identifier]
}

export interface MarkToolbarItemProps {
  activeIds?: string[]
  editor: LexicalEditor
  label: string
  icon: React.ReactElement
  markTypes: MarkType[]
}
export function MarkToolbarItem({
  icon,
  label,
  activeIds,
  editor,
  markTypes,
}: MarkToolbarItemProps) {
  const active = activeIds && markTypes.some(markType => activeIds.includes(markType.identifier))
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <TextFormatToolbarButton icon={icon} label={label} active={active} ariaLabel={label} />
      </Dropdown.ManualTrigger>
      <Dropdown.Menu>
        {markTypes.map(markType => {
          const Styled = getMarkTypeStyledElement(markType)
          return (
            <Dropdown.Item.Checkbox
              key={markType.identifier}
              checked={activeIds?.includes(markType.identifier)}
              onCheckedChange={checked => {
                editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    if (checked) {
                      $wrapSelectionInStyledMarkNode(
                        selection,
                        selection.isBackward(),
                        markType.identifier,
                      )
                    } else {
                      const markNodes = $getAllMarkNodes(selection)
                      for (const node of markNodes) {
                        node.deleteClass(markType.identifier)
                        if (!node.getClasses().length) {
                          $unwrapMarkNode(node)
                        }
                      }
                    }
                  }
                })
              }}
            >
              <Styled>{markType.label}</Styled>
            </Dropdown.Item.Checkbox>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
