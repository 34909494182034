import { MutationHookOptions } from '@apollo/client'

import { onMutationError } from '../onMutationError'
import {
  DeleteNodeMutation,
  DeleteNodeMutationVariables,
  useDeleteNodeMutation as useMutation,
} from '../schema'

export interface DeleteNodeMutationOpts
  extends MutationHookOptions<DeleteNodeMutation, DeleteNodeMutationVariables> {
  errorMessage?: string
}
export const useDeleteNodeMutation = ({
  errorMessage = 'There was an error deleting that.',
  ...opts
}: DeleteNodeMutationOpts) => {
  return useMutation({
    onError: onMutationError(errorMessage),
    ...opts,
  })
}
