import { useResource } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { Dropdown, s } from '@thesisedu/ui'
import { Filter } from '@thesisedu/ui/icons'
import React from 'react'

import { ReportFilterResource, RunReportOpts } from '../types'

export interface FilterDropdownButtonProps {
  value: RunReportOpts
  onChange: (partialValue: Partial<RunReportOpts>) => void
}
export function FilterDropdownButton({ value, onChange }: FilterDropdownButtonProps) {
  const resources = useResource<ReportFilterResource<unknown>>('ReportFilter')
  const validResources = resources.filter(resource => {
    return !resource.shouldShow || resource.shouldShow({ opts: value })
  })
  if (validResources.length) {
    const hasValue = (value.filters?.length ?? 0) > 0
    return (
      <Dropdown.Container>
        <Dropdown.Button
          icon={<Filter />}
          children={hasValue ? Format.plural('Active Filter', value.filters!.length) : 'Filter'}
          style={{ color: hasValue ? s.color('primary') : undefined }}
        />
        <Dropdown.Menu>
          {validResources.map(resource => {
            const Resource = resource.render
            const filterValue = value.filters?.find(
              filter => Object.keys(filter)[0] === resource.identifier,
            )?.[resource.identifier]
            return (
              <Resource
                hasValue={filterValue != null}
                value={filterValue}
                onChange={newValue => {
                  const without =
                    value.filters?.filter(
                      filter => Object.keys(filter)[0] !== resource.identifier,
                    ) ?? []
                  onChange({
                    filters:
                      newValue !== undefined
                        ? [...without, { [resource.identifier]: newValue }]
                        : without,
                  })
                }}
              />
            )
          })}
        </Dropdown.Menu>
      </Dropdown.Container>
    )
  } else return null
}
