import { useApolloClient, ApolloClient } from '@apollo/client'
import { orderBy } from 'lodash'
import React from 'react'

import { ResourceRenderMode, SearchResultResource } from '..'
import { SearchQuery } from '../schema'
import { SearchResultNode } from '../types'
import { useValidRendererResources } from '../useSearchResultRenderer'

/**
 * The minimum percentage the result must match the query in order for it to be
 * included.
 *
 * Let's assume this is 0.5, meaning 50% difference maximum. If we input "test" and
 * the name is "test practice" that's a difference of 9. Total length is 13. So the
 * "matching" number is 13 - 9 = 4. The length of "test" is 4. 4 / 4 = 1, so this passes
 * as it's greater than 0.5.
 */
const MINIMUM_MATCH_PERCENT = 0.9

/** The amount of fragments to process in a single batch. */
const BATCH_SIZE = 50

/** These fields are not included in search filtering. */
const EXCLUDED_FIELD_NAMES = ['id', 'createdAt', '__typename']

function getAllStrings(obj: Record<string, any>): string[] {
  let result: string[] = []
  for (const key of Object.keys(obj).filter(k => !EXCLUDED_FIELD_NAMES.includes(k))) {
    if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
      result.push(obj[key].toString())
    } else if (Array.isArray(obj[key])) {
      for (const item of obj[key]) {
        if (typeof item === 'string' || typeof item === 'number') {
          result.push(item.toString())
        } else if (typeof item === 'object') {
          result = [...result, ...getAllStrings(item)]
        }
      }
    } else if (typeof obj[key] === 'object' && obj[key]) {
      result = [...result, ...getAllStrings(obj[key])]
    }
  }

  return result
}

// From: https://github.com/trekhleb/javascript-algorithms/blob/master/src/algorithms/string/longest-common-substring/longestCommonSubstring.js
function longestCommonSubstring(string1: string, string2: string) {
  // Convert strings to arrays to treat unicode symbols length correctly.
  // For example:
  // '𐌵'.length === 2
  // [...'𐌵'].length === 1
  const s1 = [...string1]
  const s2 = [...string2]

  // Init the matrix of all substring lengths to use Dynamic Programming approach.
  const substringMatrix = Array(s2.length + 1)
    .fill(null)
    .map(() => {
      return Array(s1.length + 1).fill(null)
    })

  // Fill the first row and first column with zeros to provide initial values.
  for (let columnIndex = 0; columnIndex <= s1.length; columnIndex += 1) {
    substringMatrix[0][columnIndex] = 0
  }

  for (let rowIndex = 0; rowIndex <= s2.length; rowIndex += 1) {
    substringMatrix[rowIndex][0] = 0
  }

  // Build the matrix of all substring lengths to use Dynamic Programming approach.
  let longestSubstringLength = 0
  let longestSubstringColumn = 0
  let longestSubstringRow = 0

  for (let rowIndex = 1; rowIndex <= s2.length; rowIndex += 1) {
    for (let columnIndex = 1; columnIndex <= s1.length; columnIndex += 1) {
      if (s1[columnIndex - 1] === s2[rowIndex - 1]) {
        substringMatrix[rowIndex][columnIndex] = substringMatrix[rowIndex - 1][columnIndex - 1] + 1
      } else {
        substringMatrix[rowIndex][columnIndex] = 0
      }

      // Try to find the biggest length of all common substring lengths
      // and to memorize its last character position (indices)
      if (substringMatrix[rowIndex][columnIndex] > longestSubstringLength) {
        longestSubstringLength = substringMatrix[rowIndex][columnIndex]
        longestSubstringColumn = columnIndex
        longestSubstringRow = rowIndex
      }
    }
  }

  if (longestSubstringLength === 0) {
    // Longest common substring has not been found.
    return ''
  }

  // Detect the longest substring from the matrix.
  let longestSubstring = ''

  while (substringMatrix[longestSubstringRow][longestSubstringColumn] > 0) {
    longestSubstring = s1[longestSubstringColumn - 1] + longestSubstring
    longestSubstringRow -= 1
    longestSubstringColumn -= 1
  }

  return longestSubstring
}

function compareResult(query: string, resultItem: string) {
  const segments = query.toLowerCase().split(' ')
  const matches = segments.reduce((acc, segment) => {
    return acc + longestCommonSubstring(segment, resultItem).length
  }, 0)
  return matches / query.replace(/ /g, '').length
}

function getFragmentMatch(query: string, fragment: SearchResultNode) {
  const allStringValues = getAllStrings(fragment)
  return Math.max(
    ...allStringValues.map(stringValue => {
      return compareResult(query.toLowerCase(), stringValue.toLowerCase())
    }),
  )
}

interface SearchResultMatch {
  node: SearchResultNode
  match: number
}
function findMatchingItemsInCacheBatch(
  { client, allRefs, query, fragmentResources }: FindOpts,
  start: number,
  length: number,
): SearchResultMatch[] | null {
  const searchRefs = allRefs.slice(start, start + length)
  if (!searchRefs.length) return null
  return searchRefs
    .map<SearchResultMatch | null>(searchRef => {
      const [typename] = searchRef.split(':')
      const resource = fragmentResources.find(r => r.identifier === typename)
      if (resource) {
        const loadedFragment = client.cache.readFragment<SearchResultNode>({
          fragment: resource.fragment,
          fragmentName: resource.fragmentName,
          id: searchRef,
        })
        if (loadedFragment) {
          const closestMatch = getFragmentMatch(query, loadedFragment)
          if (closestMatch >= MINIMUM_MATCH_PERCENT) {
            return {
              node: loadedFragment,
              match: closestMatch,
            }
          } else {
            return null
          }
        } else {
          return null
        }
      } else {
        return null
      }
    })
    .filter(Boolean) as SearchResultMatch[]
}

interface FindOpts {
  client: ApolloClient<object>
  allRefs: string[]
  query: string
  fragmentResources: SearchResultResource<SearchResultNode>[]
}
interface CancelRef {
  cancel: () => void
}
function _findMatchingItemsInCache(
  opts: FindOpts,
  onUpdate: (items: SearchResultNode[]) => void,
  start: number,
  currentMatches: SearchResultMatch[] = [],
  cancelRef: CancelRef,
  limit: number = 10,
) {
  const handle = setTimeout(() => {
    const resultMatches = findMatchingItemsInCacheBatch(opts, start, BATCH_SIZE)
    if (resultMatches !== null) {
      const rematchedCurrentMatches = currentMatches
        .map(currentMatch => {
          return {
            ...currentMatch,
            match: getFragmentMatch(opts.query, currentMatch.node),
          }
        })
        .filter(rematched => rematched.match >= MINIMUM_MATCH_PERCENT)
      const allMatches = [
        ...rematchedCurrentMatches,
        ...resultMatches.filter(
          result =>
            !rematchedCurrentMatches.some(rematched => rematched.node.id === result.node.id),
        ),
      ]
      const newMatches = orderBy(allMatches, 'match', 'desc').slice(0, limit) as SearchResultMatch[]
      const newIDs = newMatches.map(item => item.node.id).join(':')
      const oldIDs = currentMatches.map(item => item.node.id).join(':')
      let updated = currentMatches
      if (newIDs !== oldIDs) {
        onUpdate(newMatches.map(match => match.node))
        updated = newMatches
      }
      _findMatchingItemsInCache(opts, onUpdate, start + BATCH_SIZE, updated, cancelRef, limit)
    }
  }, 0)
  cancelRef.cancel = () => clearTimeout(handle)
}
function findMatchingItemsInCache(
  opts: FindOpts,
  currentMatching: SearchResultNode[],
  onUpdate: (items: SearchResultNode[]) => void,
  limit: number = 10,
): CancelRef {
  const cancelRef = { cancel: () => {} }
  _findMatchingItemsInCache(
    opts,
    onUpdate,
    0,
    currentMatching.map(node => ({ match: 1, node })),
    cancelRef,
    limit,
  )
  return cancelRef
}

interface NodeWithRef {
  __ref: string
}

const ROOT_QUERY_CACHE_KEY = 'ROOT_QUERY'
export function usePrefixItems(
  query: string,
  mode: ResourceRenderMode,
  visible: boolean | undefined,
  limit: number = 10,
) {
  const client = useApolloClient()
  const [matchingItems, setMatchingItems] = React.useState<SearchResultNode[]>([])
  const fragmentResources = useValidRendererResources(mode)
  const cachedRefs = React.useMemo(() => {
    const allExtracted = client.cache.extract() as Record<string, any>
    const extractedCache = allExtracted[ROOT_QUERY_CACHE_KEY] || ({} as Record<string, any>)
    const searchQueryKeys = Object.keys(extractedCache).filter(key => key.startsWith('search('))
    const allRefs = new Set<string>()
    for (const searchQueryKey of searchQueryKeys) {
      const queryResult = extractedCache[searchQueryKey] as SearchQuery['search']
      for (const edge of queryResult.edges) {
        const ref = (edge.node as unknown as NodeWithRef).__ref
        if (ref) {
          allRefs.add(ref)
        }
      }
    }

    return [...allRefs]
  }, [!!visible])
  React.useEffect(() => {
    if (query) {
      const cancelRef = findMatchingItemsInCache(
        {
          query,
          client,
          allRefs: cachedRefs,
          fragmentResources,
        },
        matchingItems,
        setMatchingItems,
        limit,
      )
      return () => {
        cancelRef.cancel()
      }
    } else {
      setMatchingItems([])
    }
  }, [query])

  return matchingItems
}
