import { DeleteOutlined } from '@ant-design/icons'
import { DotMenu } from '@sammarks/icons'
import { isNoteItem, TuneNoteBase } from '@thesisedu/feature-sheet-music-core'
import { styled } from '@thesisedu/web'
import { Dropdown, Menu } from 'antd'
import React from 'react'

import { MultiDropBoxProps } from './DropBox'
import { useSimpleSheetMusicEditorContext } from './SimpleContext'
import { DOTTED_NOTES, getNoteColor } from '../viewer/Note'

export interface NoteActionsProps extends Pick<MultiDropBoxProps, 'startPos' | 'replace'> {
  dropdownVisible?: boolean
  onDropdownVisibleChange: (visible: boolean) => void
  note: TuneNoteBase
  remainingDuration: number
}
export function NoteActions({
  startPos,
  replace,
  note,
  dropdownVisible,
  onDropdownVisibleChange,
  remainingDuration,
}: NoteActionsProps) {
  const { useAdvancedControls, onRemove, onDrop } = useSimpleSheetMusicEditorContext(true)
  if (useAdvancedControls) {
    const selectedKeys: string[] = []
    if (isNoteItem(note)) {
      if (note.pitches[0]?.accidental) selectedKeys.push(note.pitches[0].accidental)
    }
    if (DOTTED_NOTES.includes(note.duration)) selectedKeys.push('dotted')
    return (
      <DeleteContainer className={'actions-container'}>
        <Dropdown
          visible={dropdownVisible}
          onVisibleChange={onDropdownVisibleChange}
          overlayStyle={{ width: 150 }}
          overlay={
            <Menu selectedKeys={selectedKeys}>
              {isNoteItem(note) ? (
                <>
                  <Menu.Item
                    key={'sharp'}
                    disabled={!getNoteColor(note.pitches[0]!.pitch, 'sharp')}
                    onClick={() => {
                      if (isNoteItem(note)) {
                        onDrop(
                          note.duration,
                          note.pitches[0]!.pitch,
                          selectedKeys.includes('sharp') ? undefined : 'sharp',
                          startPos,
                          replace,
                        )
                      }
                    }}
                  >
                    Sharp
                  </Menu.Item>
                  <Menu.Item
                    key={'flat'}
                    disabled={!getNoteColor(note.pitches[0]!.pitch, 'flat')}
                    onClick={() => {
                      if (isNoteItem(note)) {
                        onDrop(
                          note.duration,
                          note.pitches[0]!.pitch,
                          selectedKeys.includes('flat') ? undefined : 'flat',
                          startPos,
                          replace,
                        )
                      }
                    }}
                  >
                    Flat
                  </Menu.Item>
                </>
              ) : null}
              <Menu.Item
                key={'dotted'}
                disabled={
                  (!selectedKeys.includes('dotted') &&
                    note.duration * 1.5 > remainingDuration + note.duration) ||
                  note.duration <= 0.125
                }
                onClick={() => {
                  onDrop(
                    selectedKeys.includes('dotted') ? note.duration * (2 / 3) : note.duration * 1.5,
                    isNoteItem(note) ? note.pitches[0]!.pitch : undefined,
                    isNoteItem(note) ? note.pitches[0]!.accidental : undefined,
                    startPos,
                    replace,
                  )
                }}
              >
                Dotted
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                danger
                onClick={() => {
                  if (replace) {
                    onRemove(startPos, replace)
                  }
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <EditButton>
            <DotMenu />
          </EditButton>
        </Dropdown>
      </DeleteContainer>
    )
  } else {
    return (
      <DeleteContainer className={'actions-container'}>
        <DeleteButton
          onClick={e => {
            e.preventDefault()
            if (replace) {
              onRemove(startPos, replace)
            }
          }}
        >
          <DeleteOutlined />
        </DeleteButton>
      </DeleteContainer>
    )
  }
}

const DeleteContainer = styled.div`
  position: absolute;
  top: -50px;
  height: 50px;
  left: 0;
  right: 0;
  padding-right: ${props => props.theme['@size-s']};
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  transform: translateY(10%);
  opacity: 0;
  z-index: 9;
  pointer-events: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &:hover {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
`
const DeleteButton = styled.button`
  outline: none;
  border: none;
  color: white;
  background: ${props => props.theme['@red']};
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const EditButton = styled(DeleteButton)`
  background: ${props => props.theme['@gray-2']};
  color: ${props => props.theme['@gray-7']};
`
