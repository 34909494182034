import { LoadingIndicator, styled } from '@thesisedu/ui'
import React, { Suspense } from 'react'

import { LoomEmbed } from './LoomEmbed'
import { SimpleWidget } from '../utils'

const ReactPlayer = React.lazy(() => import('react-player'))

export interface VideoEmbedWithUrlProps {
  url: string
  alignment?: SimpleWidget.Alignment
}

export function VideoEmbedWithUrl({ url, alignment = 'center' }: VideoEmbedWithUrlProps) {
  const isLoomUrl = url.includes('loom.com/share')
  return (
    <Container $alignment={alignment}>
      {isLoomUrl ? (
        <LoomEmbed url={url} />
      ) : (
        <Suspense
          fallback={
            <SimpleWidget.StatusContainer>
              <LoadingIndicator />
            </SimpleWidget.StatusContainer>
          }
        >
          <div style={{ width: '100%', aspectRatio: '16/9' }}>
            <ReactPlayer url={url} width={'100%'} height={'100%'} controls />
          </div>
        </Suspense>
      )}
    </Container>
  )
}

const Container = styled(SimpleWidget.WidgetContainer)`
  width: 100%;
  max-width: 800px;
`
