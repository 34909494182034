import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { RateLimitErrorWatcher } from './expectedError/RateLimitedError'
import { ApolloReactFeature } from '../ApolloReactFeature'
import { ConfirmationHandler } from '../confirmation/ConfirmationHandler'

export default function (feature: ApolloReactFeature) {
  feature.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
    return (
      <>
        {children}
        <ConfirmationHandler />
        <RateLimitErrorWatcher />
      </>
    )
  })
}
