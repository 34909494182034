import React from 'react'

import { debug } from '../../../log'
import { GetStreamsOpts, useGetStreamsCallback } from '../selectDevice/getStreams'
import { stopStreams } from '../selectDevice/stopStreams'
import { Streams } from '../types'

export interface UseRecordStreamsOpts {
  defaultStreams: Streams
}
export interface UseRecordStreams {
  loading: boolean
  updateStreams: (opts: Omit<GetStreamsOpts, 'options'>) => Promise<void>
  streams: Streams
}
export function useRecordStreams({ defaultStreams }: UseRecordStreamsOpts): UseRecordStreams {
  const [streams, setStreams] = React.useState<Streams>(defaultStreams)
  const streamsRef = React.useRef(streams)
  streamsRef.current = streams
  const [getStreams, { loading }] = useGetStreamsCallback()
  React.useEffect(() => {
    return () => stopStreams(streamsRef.current)
  }, [])

  return {
    loading,
    streams,
    async updateStreams(opts) {
      debug('updating streams %O', opts)
      const audioChanged = opts.audioDeviceId !== streams.audioDeviceId
      const videoChanged = opts.videoDeviceId !== streams.videoDeviceId
      if (audioChanged && videoChanged) {
        stopStreams(streams)
      } else if (audioChanged) {
        stopStreams(streams, 'audio')
      } else if (videoChanged) {
        stopStreams(streams, 'video')
      }
      try {
        setStreams(await getStreams(opts))
      } catch {
        debug('error updating streams; falling back to old value %O', streams)
        setStreams(await getStreams(streams))
      }
    },
  }
}
