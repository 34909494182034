import ApolloReactFeature, { BackgroundJobReporterResource } from '@thesisedu/feature-apollo-react'

import { ClassSyncReactFeature } from '../ClassSyncReactFeature'
import { MutateSyncAssignmentRefetchQueries, MutateSyncMaterialRefetchQueries } from '../types'

export default function (feature: ClassSyncReactFeature) {
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'syncAssignment',
    humanLabel(payload) {
      const name = payload?.name
      return name ? `Syncing assignment ${payload?.name}...` : 'Syncing assignment...'
    },
    async afterComplete() {
      const refetchQueries =
        await feature.hookManager.mutateHook<MutateSyncAssignmentRefetchQueries>(
          'class-sync-react:sync-assignment-refetch',
          [],
          undefined,
        )
      if (refetchQueries.length) {
        const apolloFeature = feature.root.requireFeature<ApolloReactFeature>(
          ApolloReactFeature.package,
        )
        await apolloFeature.client.refetchQueries({
          include: refetchQueries,
        })
      }
    },
  })
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'syncMaterial',
    humanLabel(payload) {
      const name = payload?.name
      return name ? `Syncing material ${payload?.name}...` : 'Syncing material...'
    },
    async afterComplete() {
      const refetchQueries = await feature.hookManager.mutateHook<MutateSyncMaterialRefetchQueries>(
        'class-sync-react:sync-material-refetch',
        [],
        undefined,
      )
      if (refetchQueries.length) {
        const apolloFeature = feature.root.requireFeature<ApolloReactFeature>(
          ApolloReactFeature.package,
        )
        await apolloFeature.client.refetchQueries({
          include: refetchQueries,
        })
      }
    },
  })
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'syncAssignmentGrade',
    humanLabel() {
      return 'Syncing assignment grade...'
    },
  })
}
