import { Modal, Typography } from 'antd'
import React from 'react'

import { debug } from './log'

interface ErrorGuidance {
  [errorType: string]: string | undefined
}
const ERROR_GUIDANCES: ErrorGuidance = {
  unauthorized_client:
    'This likely means our integration is not properly setup with your district. Please have a district IT ' +
    'administrator reach out to support.',
  invalid_client:
    'This likely means our integration is not properly setup with your district. Please have a district IT ' +
    'administrator reach out to support.',
  temporarily_unavailable:
    "This likely means we're having trouble contacting your integration. Please wait a few minutes and try again.",
  invalid_scope:
    'This likely means our integration is not properly setup with your district. Specifically, we appear to be missing ' +
    'a few scopes. Please have a district IT administrator review our support documentation for this integration and ' +
    'make sure their configuration is correct. Alternatively, they can reach out to support and our support team will ' +
    'be happy to help.',
}

type IWindowProps = {
  authorizeUrl: string
  clientId: string
  scopes: string[]
  redirectUri: string
  title: string
  width: number
  height: number
  params?: Record<string, string>
  additionalParams?: Record<string, string>
}

type OnCode = (code: string, params: URLSearchParams) => void

export interface OAuthError {
  error: string
  description?: string
}
export interface IPopupProps extends IWindowProps {
  onClose: () => void
  onCode?: OnCode
  onError?: (err: OAuthError) => void
}

export function defaultErrorHandler(err: OAuthError) {
  Modal.error({
    title: 'There seems to be a problem with this integration.',
    content: (
      <div style={{ marginTop: 16 }}>
        <p>
          When trying to authenticate, we ran into a{' '}
          <Typography.Text code>{err.error}</Typography.Text> error.
        </p>
        {ERROR_GUIDANCES[err.error] ? <p>{ERROR_GUIDANCES[err.error]}</p> : null}
        {err.description ? (
          <>
            <p>
              Additionally, the following information came back from the integrating partner and
              might be helpful:
            </p>
            <p style={{ opacity: 0.75 }}>{err.description}</p>
          </>
        ) : null}
        <p>
          Sometimes simply trying again fixes the issue. If the issue persists, please reach out to
          support and we'll be glad to help.
        </p>
      </div>
    ),
    width: 600,
    bodyStyle: { maxWidth: '90vw' },
  })
}

function getPopupUrl({
  params: _params,
  clientId,
  scopes,
  redirectUri,
  additionalParams,
  authorizeUrl,
}: IWindowProps) {
  const params = new URLSearchParams(
    _params || {
      client_id: clientId,
      scope: scopes.join(' '),
      redirect_uri: redirectUri,
      response_type: 'code',
      ...additionalParams,
    },
  )
  return `${authorizeUrl}?${params.toString()}`
}

const createPopup = (props: IWindowProps) => {
  const { title, height, width } = props
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5
  const externalPopup = window.open(
    getPopupUrl(props),
    title,
    `width=${width},height=${height},left=${left},top=${top}`,
  )
  return externalPopup
}

export function useOAuthPopup({
  onCode,
  onClose,
  onError = defaultErrorHandler,
  ...modalProps
}: IPopupProps) {
  const [externalWindow, setExternalWindow] = React.useState<Window | null>()
  const intervalRef = React.useRef<number>()
  const onCodeRef = React.useRef<typeof onCode>(onCode)
  const onErrorRef = React.useRef<typeof onError>(onError)

  const clearTimer = () => {
    window.clearInterval(intervalRef.current)
  }

  const onContainerClick = ({
    onCode,
    ..._modalProps
  }: Partial<IWindowProps & { onCode: OnCode }> = {}) => {
    if (onCode) onCodeRef.current = onCode
    setExternalWindow(createPopup({ ...modalProps, ..._modalProps }))
  }

  React.useEffect(() => {
    onCodeRef.current = onCode
  }, [onCode])
  React.useEffect(() => {
    onErrorRef.current = onError
  }, [onError])

  React.useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        let success = false
        try {
          const currentUrl = externalWindow.location.href
          const params = new URL(currentUrl).searchParams
          debug('checking for code: %O', params)
          const code = params.get('code') || params.get('oauth_token')
          const error = params.get('error')
          if (code) {
            debug('found code')
            if (onCodeRef.current) {
              debug('calling onCodeRef with code %s and params %O', code, params)
              onCodeRef.current(code, params)
              if (externalWindow) externalWindow.close()
              success = true
            }
          } else if (error) {
            const oauthError: OAuthError = {
              error,
              description: params.get('error_description') || undefined,
            }
            if (onErrorRef.current) {
              onErrorRef.current(oauthError)
              setExternalWindow(null)
            }
          } else {
            return
          }

          clearTimer()
        } catch {
        } finally {
          if ((!externalWindow || externalWindow.closed) && !success) {
            onClose()
            clearTimer()
          }
        }
      }, 700)
      return () => {
        if (externalWindow) externalWindow.close()
        if (onClose) onClose()
      }
    }
  }, [externalWindow])

  return {
    onContainerClick,
    closePopup: () => {
      setExternalWindow(null)
    },
  }
}
