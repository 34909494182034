import React from 'react'

export interface ScrollableContainerContextValue {
  target?: React.RefObject<HTMLElement>
}
export const ScrollableContainerContext = React.createContext<
  ScrollableContainerContextValue | undefined
>(undefined)
export type PortalContainerContextValue = ScrollableContainerContextValue
export const PortalContainerContext = React.createContext<PortalContainerContextValue | undefined>(
  undefined,
)

export interface ScrollableContainerProviderProps {
  targetRef?: React.RefObject<HTMLElement>
  portalRef?: React.RefObject<HTMLElement>
  children: React.ReactElement
}
export function ScrollableContainerProvider({
  targetRef: _targetRef,
  portalRef,
  children,
}: ScrollableContainerProviderProps) {
  const targetRef = _targetRef ?? { current: document.body }
  return (
    <PortalContainerContext.Provider value={{ target: portalRef ?? targetRef }}>
      <ScrollableContainerContext.Provider value={{ target: targetRef }} children={children} />
    </PortalContainerContext.Provider>
  )
}

export function useScrollableContainerContext(): ScrollableContainerContextValue | undefined
export function useScrollableContainerContext(
  require: false,
): ScrollableContainerContextValue | undefined
export function useScrollableContainerContext(require: true): ScrollableContainerContextValue
export function useScrollableContainerContext(
  require?: boolean,
): ScrollableContainerContextValue | undefined {
  const context = React.useContext(ScrollableContainerContext)
  if (!context && require) {
    throw new Error('ScrollableContainerContext is required, yet not provided.')
  }
  return context
}

export function usePortalContainerContext(): PortalContainerContextValue | undefined
export function usePortalContainerContext(require: false): PortalContainerContextValue | undefined
export function usePortalContainerContext(require: true): PortalContainerContextValue
export function usePortalContainerContext(
  require?: boolean,
): PortalContainerContextValue | undefined {
  const context = React.useContext(PortalContainerContext)
  if (!context && require) {
    throw new Error('PortalContainerContext is required, yet not provided.')
  }
  return context
}

/**
 * Gets the container to attach portal elements to. This could house dropdowns,
 * popovers, modals, etc.
 */
export function usePortalContainer() {
  const { target } = usePortalContainerContext(false) || {}
  const body = typeof document !== 'undefined' ? document.body : undefined
  return target?.current || body
}
