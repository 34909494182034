import { useResource } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import { ProviderAuthContext } from '@thesisedu/feature-users-react/dist/saml/ProviderAuthContext'
import { useViewerLinkedAccountsQuery } from '@thesisedu/feature-users-react/dist/schema'
import { AntIconWrapper, Button, VSpaced } from '@thesisedu/react'
import React from 'react'

import { SignupChildProps } from './TrialRequestSignupPage'

export function useHasProviders(providers?: string[]) {
  return !!useResource<AuthProviderHandlerResource>('AuthProviderHandler').filter(
    p => !providers || providers.includes(p.identifier!),
  ).length
}

export interface TrialRequestConnectSignupProps extends SignupChildProps {
  authProviders?: string[]
}
export function TrialRequestConnectSignup({
  onComplete,
  authProviders,
}: TrialRequestConnectSignupProps) {
  const { authenticate } = React.useContext(ProviderAuthContext)
  const { refetch } = useViewerLinkedAccountsQuery()
  const filteredProviders = useResource<AuthProviderHandlerResource>('AuthProviderHandler').filter(
    p => !authProviders || authProviders.includes(p.identifier!),
  )
  const [loading, setLoading] = React.useState(false)
  return (
    <VSpaced align={'stretch'} space={'@size-s'}>
      {filteredProviders.map(provider => (
        <Button
          block
          icon={provider.buttonIcon ? <AntIconWrapper children={provider.buttonIcon} /> : undefined}
          key={provider.identifier}
          children={`Login with ${provider.name}`}
          loading={loading}
          onClick={async () => {
            setLoading(true)
            try {
              await authenticate(provider.identifier!)
              const result = await refetch()
              const hasAccount = result.data.viewer?.linkedAccounts.find(
                linkedAccount => linkedAccount.provider === provider.identifier,
              )
              if (hasAccount) {
                onComplete()
              }
            } finally {
              setLoading(false)
            }
          }}
        />
      ))}
    </VSpaced>
  )
}
