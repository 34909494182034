import React from 'react'
import styled, { css } from 'styled-components'

import {
  ForwardThemeContext,
  ThemeContext,
  ThemeContextValue,
  useForwardThemeContext,
} from './ThemeContext'
import { createGlobalStyle } from './styled'
import { getTheme, setTheme } from './theme'
import { Theme, ThemeColorVariant } from './types'
import {
  getAllVariablesAndValues,
  getColorVariablesAndValues,
  variablesToCss,
  colorToVariable,
} from './variables'

const GlobalStyle = createGlobalStyle<{ variables: Record<string, string> }>`
  :root {
    ${props => variablesToCss(props.variables)}
  }
`

export function getVariantCss(colorVariant: ThemeColorVariant, theme: Theme = getTheme()) {
  const variables = getColorVariablesAndValues(theme, colorVariant)
  return css`
    ${variablesToCss(variables)}
    color: ${colorToVariable('text')};
  `
}

const LocalStyle = styled.div<{ $theme: Theme; $colorVariant: ThemeColorVariant }>`
  ${props => getVariantCss(props.$colorVariant, props.$theme)}
`

export interface ThemeProviderProps {
  isRoot?: boolean
  theme?: Theme
  colorVariant?: ThemeColorVariant
  children: React.ReactElement
  /**
   * In some cases you want to completely re-declare all of the CSS variables. One
   * example is when using PagedJS and updating the styled-components properties
   * to not inject CSS at the top of the document. If that's the case, set this
   * to true and all of the variables will be re-declared in this block.
   */
  forceGlobalStyle?: boolean
}
export function ThemeProvider({
  theme: _theme,
  isRoot,
  colorVariant: _colorVariant = 'light',
  forceGlobalStyle,
  children,
}: ThemeProviderProps) {
  const [colorVariant, setColorVariant] = React.useState(_colorVariant)
  if (isRoot) {
    if (!_theme) throw new Error('You cannot pass isRoot without also passing a theme.')
    setTheme(_theme)
  }
  const theme = isRoot ? _theme : getTheme()
  if (!theme) throw new Error('Could not find theme.')
  const contextValue = React.useMemo<ThemeContextValue>(() => {
    return {
      colorVariant,
      setColorVariant,
    }
  }, [colorVariant])

  const content = (
    <ForwardThemeContext.Provider value={React.useMemo(() => ({ colorVariant }), [colorVariant])}>
      {children}
    </ForwardThemeContext.Provider>
  )
  if (isRoot || forceGlobalStyle) {
    return (
      <>
        <GlobalStyle variables={getAllVariablesAndValues(theme, colorVariant)} />
        <ThemeContext.Provider value={contextValue} children={content} />
      </>
    )
  } else {
    return <LocalStyle $theme={theme} $colorVariant={colorVariant} children={content} />
  }
}

export interface ForwardThemeProps {
  children: React.ReactElement | React.ReactElement[] | null
}
function _ForwardTheme({ children }: ForwardThemeProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { colorVariant } = useForwardThemeContext(false) || {}
  if (colorVariant) {
    const theme = getTheme()
    return <LocalStyle $theme={theme} $colorVariant={colorVariant} children={children} ref={ref} />
  } else return <>{children}</>
}
export const ForwardTheme = React.forwardRef(_ForwardTheme)
