import { styled, s, Text, Button, HSpaced } from '@thesisedu/ui'
import { Settings } from '@thesisedu/ui/icons'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { Container } from './Container'
import Icon from './Icon'
import { Indicator } from './Indicator'
import { useMetronomeContext } from './MetronomeContext'
import { Popover } from './Popover'

function BlockMetronomeContent() {
  const { update, playing, emphasizedNote, note, bpm } = useMetronomeContext(true)
  return (
    <BlockContainer
      onPress={() => {
        update({ playing: !playing })
      }}
    >
      <HSpaced space={'xxs'}>
        <Indicator />
        <Text>
          <Icon width={'40px'} height={'40px'} style={{ display: 'block' }} />
        </Text>
      </HSpaced>
      <div style={{ width: 150 }}>
        <AnimatePresence exitBeforeEnter initial>
          {playing ? (
            <motion.div key={'playing'} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Text>
                {emphasizedNote} / {note[0]}
              </Text>
              <Text level={'xs'} color={'secondary'}>
                {bpm} BPM
              </Text>
            </motion.div>
          ) : (
            <motion.div key={'not-playing'} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Text>Metronome</Text>
              <Text level={'xs'} color={'secondary'}>
                Click to Start
              </Text>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Popover>
        <Button aria-label={'Metronome Settings'} icon={<Settings />} variant={'ghost'} />
      </Popover>
    </BlockContainer>
  )
}

export function BlockMetronome() {
  return (
    <Container>
      <BlockMetronomeContent />
    </Container>
  )
}

const BlockContainer = styled(Button)`
  border-radius: ${s.var('radii.1')};
  background: ${s.color('element')};
  padding: ${s.size('s')};
  transition: background 0.1s linear;
  display: inline-flex;
  align-items: center;
  gap: ${s.size('s')};
  cursor: pointer;
  &:hover {
    background: ${s.color('hoverElement')};
  }
`
