import { Format } from '@thesisedu/feature-utils'
import { Skeleton } from 'antd'
import Statistic, { StatisticProps } from 'antd/es/statistic/Statistic'
import React from 'react'

export interface LoadableStatisticProps extends StatisticProps {
  loading?: boolean
}
export const LoadableStatistic: React.FC<React.PropsWithChildren<LoadableStatisticProps>> = ({
  loading,
  formatter,
  ...props
}) => (
  <Statistic
    formatter={value => {
      if (loading) {
        return <Skeleton.Input active style={{ width: '150px' }} />
      } else {
        if (typeof formatter === 'function') {
          return formatter(value)
        } else if (!isNaN(parseFloat(value.toString()))) {
          return Format.number(parseFloat(value.toString()), 'number')
        } else {
          return formatter || value
        }
      }
    }}
    {...props}
  />
)
