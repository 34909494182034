import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { PDFConfig } from './common'

export function AllowDownloadSetting({
  editor,
  node,
}: SimpleWidget.SettingsDropdownProps<PDFConfig>) {
  const [allowDownloads, setAllowDownloads] = SimpleWidget.useWidgetConfig(
    editor,
    node,
    'allowDownloads',
  )
  return (
    <Dropdown.Item.Checkbox
      checked={allowDownloads}
      onCheckedChange={checked => setAllowDownloads(!!checked)}
      children={'Allow Downloads'}
    />
  )
}
