import { Shell } from '@thesisedu/feature-web'
import { PageHead, Block, BodyLarger } from '@thesisedu/react'
import React from 'react'
import { Routes, Route, useParams, useNavigate } from 'react-router-dom'

import { LargeConfiguration } from '../../reporting/config/LargeConfiguration'
import { ReportPage } from '../../reporting/result/ReportPage'
import { LargeSavedReportsList } from '../../saved/LargeSavedReportsList'
import { SavedReportPage } from '../../saved/SavedReportPage'
import { useHasSavedReports } from '../../saved/useHasSavedReports'
import { useSavedReport } from '../../saved/useSavedReport'

function Start() {
  const navigate = useNavigate()
  const hasSavedReports = useHasSavedReports()
  const [hasFirstDimension, setHasFirstDimension] = React.useState(false)
  Shell.useShellProps(
    'report-start',
    {
      alternateBackground: true,
    },
    [],
  )
  return (
    <>
      <PageHead title={'Reports'} />
      <Block marginTop={'@size-xl'}>
        <LargeConfiguration
          onChange={opts => setHasFirstDimension(!!opts.dimensions.length)}
          onRedirect={packed => {
            navigate(packed)
          }}
        />
      </Block>
      {hasSavedReports && !hasFirstDimension ? (
        <>
          <BodyLarger style={{ textAlign: 'center' }}>...or pick a saved report.</BodyLarger>
          <Block marginTop={'@size-m'}>
            <LargeSavedReportsList reportLink={savedReport => `saved/${savedReport.id}`} />
          </Block>
        </>
      ) : null}
    </>
  )
}

function Report() {
  const { report } = useParams()
  Shell.useShellProps(
    'report-view',
    {
      noScroll: true,
    },
    [],
  )
  return (
    <>
      <PageHead title={'View Report'} />
      <ReportPage report={report} />
    </>
  )
}

export function SavedReport() {
  const { savedReportId } = useParams()
  const { savedReport } = useSavedReport(savedReportId)
  Shell.useShellProps(
    'saved-report',
    {
      noScroll: true,
    },
    [],
  )
  return (
    <>
      <PageHead title={`${savedReport?.name || 'Saved Report'} - Reports`} />
      <SavedReportPage savedReportId={savedReportId} onReportChange={() => {}} />
    </>
  )
}

export function Reports() {
  return (
    <Routes>
      <Route path={'/:report'} element={<Report />} />
      <Route path={'/saved/:savedReportId'} element={<SavedReport />} />
      <Route path={'/'} element={<Start />} />
    </Routes>
  )
}
