import { ApolloClient } from '@apollo/client'
import React from 'react'

import { AttachmentWriteFragment } from '../schema'
import { AttachmentFieldVariables, AttachmentWriteProps, UploadProps } from '../types'

export interface CommonAttachmentWriteProps {
  client: ApolloClient<any>
  UploadComponent: React.FC<React.PropsWithChildren<UploadProps>>
}
export function CommonAttachmentWrite<
  Result,
  Variables extends AttachmentFieldVariables = AttachmentFieldVariables,
>({
  client,
  UploadComponent,
  ...props
}: AttachmentWriteProps<Result, Variables> & CommonAttachmentWriteProps) {
  return (
    <UploadComponent
      getWriteFragment={async input => {
        const result = await client.query<Result, Variables>({
          query: props.writeQueryDocument,
          variables: {
            ...props.variables,
            attachmentWriteInput: input,
          } as Variables,
          fetchPolicy: 'no-cache',
        })
        if (!result.data) {
          throw new Error('getting attachment failed')
        }
        return props.getAttachment(result.data) as AttachmentWriteFragment
      }}
    />
  )
}
