import { useErrorService, useFreshRef } from '@thesisedu/feature-react'
import React from 'react'

import { debug } from '../../../log'
import { stopStreams } from '../selectDevice/stopStreams'
import { Streams } from '../types'

export interface UseRecordingOnFinishedOpts {
  stream?: Blob
  durationInMS: number
}
export enum RecordingState {
  Idle,
  Recording,
  StoppingRecording,
  Paused,
  Error,
}

export interface UseRecordingOpts {
  streams: Streams
  onFinished?: (opts: UseRecordingOnFinishedOpts) => void | Promise<void>
  onError?: (e: any) => void
}
export interface UseRecording {
  state: RecordingState
  recordingStartTime: number | null
  startRecording: () => void
  stopRecording: () => void
  pauseRecording: () => void
  resumeRecording: () => void
}
export function useRecording({
  streams,
  onFinished,
  onError: _onError,
}: UseRecordingOpts): UseRecording {
  const [state, setState] = React.useState(RecordingState.Idle)
  const stateRef = useFreshRef(state)
  const [recordingStartTime, setRecordingStartTime] = React.useState<number | null>(null)
  const recordingStartTimeRef = useFreshRef(recordingStartTime)
  const pausedTimeRef = React.useRef<number | null>(null)
  const recorderRef = React.useRef<MediaRecorder | null>(null)
  const chunksRef = React.useRef<Blob[]>([])
  const audioOnly = !streams.videoDeviceId && streams.audioDeviceId
  const errorService = useErrorService()

  const stopAndClearRecordings = React.useCallback(
    (state: RecordingState = RecordingState.Idle) => {
      recorderRef.current?.stop()
      recorderRef.current = null
      setState(state)
      setRecordingStartTime(null)
    },
    [],
  )

  const onError = (e: any) => {
    debug('error while recording: %O', e)
    errorService.reportError(e)
    stopAndClearRecordings(RecordingState.Error)
    _onError?.(e)
  }

  // Stop recording whenever we're unmounting.
  React.useEffect(() => {
    return () => {
      stopAndClearRecordings()
    }
  }, [])

  return {
    state,
    recordingStartTime,
    startRecording: React.useCallback(() => {
      debug('starting recording')

      stopAndClearRecordings()

      // Create the new recorders.
      if (streams.stream) {
        chunksRef.current = []
        const rec = new MediaRecorder(streams.stream, {
          mimeType: audioOnly ? 'audio/webm' : 'video/webm;codecs=h264',
          audioBitsPerSecond: 128000,
          videoBitsPerSecond: 4000000, // 4mbps
        })
        rec.ondataavailable = e => chunksRef.current.push(e.data)
        rec.onerror = onError
        recorderRef.current = rec
      }

      // Start the recordings.
      try {
        recorderRef.current?.start()
      } catch (err: any) {
        debug('error starting recording stream %O', err)
        onError(err)
        return stopAndClearRecordings(RecordingState.Error)
      }
      setState(RecordingState.Recording)
      setRecordingStartTime(Date.now())
    }, [audioOnly, streams]),
    stopRecording: React.useCallback(async () => {
      if (stateRef.current !== RecordingState.Recording || !recordingStartTimeRef.current) return

      debug('stopping recording')
      const durationInMS = Date.now() - recordingStartTimeRef.current
      debug('duration %d', durationInMS)
      setState(RecordingState.StoppingRecording)

      // Stop recording.
      recorderRef.current?.stop()

      // Wait a tick for the data to be available.
      await new Promise(r => setTimeout(r, 100))

      // Call the finished callback.
      await onFinished?.({
        stream: chunksRef.current?.length
          ? new Blob(chunksRef.current, { type: audioOnly ? 'audio/webm' : 'video/webm' })
          : undefined,
        durationInMS,
      })

      recorderRef.current = null
      setState(RecordingState.Idle)
      setRecordingStartTime(null)
      if (streams.stream) {
        stopStreams(streams)
      }
    }, [onFinished, audioOnly, streams]),
    pauseRecording: React.useCallback(() => {
      debug('pausing recording')
      recorderRef.current?.pause()
      setState(RecordingState.Paused)
      pausedTimeRef.current = Date.now()
    }, []),
    resumeRecording: React.useCallback(() => {
      debug('resuming recording')
      if (!pausedTimeRef.current || !recordingStartTimeRef.current)
        throw new Error('Cannot resume when there is no pausedTimeRef or recordingStartTime values')
      const timeDelta = Date.now() - pausedTimeRef.current
      recorderRef.current?.resume()
      setRecordingStartTime(recordingStartTimeRef.current + timeDelta)
      setState(RecordingState.Recording)
      pausedTimeRef.current = null
    }, []),
  }
}
