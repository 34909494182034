import {
  ArrowRightOutlined,
  AudioOutlined,
  EllipsisOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import {
  ActionContainer,
  Body,
  BodyLarge,
  Card,
  styled,
  StyledThemeContext as ThemeContext,
} from '@thesisedu/web'
import { Button, Dropdown, Badge, Tooltip } from 'antd'
import React, { useContext, useState } from 'react'

import { MediaStatus, MediaType } from '../schema'
import { MediaPreviewProps } from '../types'
import { UploadStatusLabel } from '../upload/UploadStatusLabel'
import { convertStatus } from '../upload/convertStatus'

const CardInnerContainer = styled.div`
  .ant-badge {
    display: block;
  }
  .ant-badge-dot {
    width: ${props => props.theme['@size-s']};
    height: ${props => props.theme['@size-s']};
  }
`

const BoxContainer = styled.div`
  padding-top: 75%;
  height: 0;
  position: relative;
`

const MetaContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-s']};
  left: ${props => props.theme['@size-s']};
`

const BoxInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme['@text-color-secondary']};
  background-size: cover;
  background-position: center center;
  border-top-left-radius: ${props => props.theme['@border-radius-base']};
  border-top-right-radius: ${props => props.theme['@border-radius-base']};
`

const FooterContainer = styled.div`
  background: ${props => props.theme['@background-color-dark']};
  color: ${props => props.theme['@text-color-dark']};
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom-left-radius: ${props => props.theme['@border-radius-base']};
  border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
  .label:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-xs']} !important;
  }
`

const FooterRight = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
`

export const MediaPreview: React.FC<React.PropsWithChildren<MediaPreviewProps>> = ({
  label,
  subtitle,
  mediaNode,
  meta,
  actions,
  useBasicActions,
  onClick,
  hideThumb,
  badgeProps,
  toolTipTitle,
}) => {
  const theme = useContext(ThemeContext)
  const [overlayVisible, setOverlayVisible] = useState(false)
  const status = (
    <UploadStatusLabel
      upload={{
        ...mediaNode,
        currentStatus: convertStatus(mediaNode.status),
      }}
    />
  )
  const thumbnail = hideThumb ? null : mediaNode.thumbnail
  const actionButton = actions ? (
    useBasicActions ? (
      actions
    ) : (
      <Dropdown
        overlay={actions}
        placement={'bottomRight'}
        visible={overlayVisible}
        onVisibleChange={setOverlayVisible}
      >
        <Button icon={<MenuOutlined />} shape={'circle'} size={'large'} />
      </Dropdown>
    )
  ) : null
  return (
    <Card
      hovering={overlayVisible}
      onClick={() => {
        if (!overlayVisible && onClick) {
          onClick()
        }
      }}
    >
      <CardInnerContainer>
        <Tooltip placement={'rightTop'} title={toolTipTitle} trigger={toolTipTitle ? 'hover' : ''}>
          <Badge {...badgeProps} dot={mediaNode.hasUnreadComments || !!badgeProps?.dot}>
            <BoxContainer>
              <BoxInner style={{ backgroundImage: thumbnail ? `url(${thumbnail})` : undefined }}>
                {meta ? <MetaContainer>{meta}</MetaContainer> : null}
                {actionButton ? <ActionContainer children={actionButton} /> : null}
                {mediaNode.type === MediaType.Audio ? (
                  <AudioOutlined style={{ fontSize: theme['@size-l'] }} />
                ) : thumbnail ? null : (
                  <EllipsisOutlined style={{ fontSize: theme['@size-l'] }} />
                )}
              </BoxInner>
            </BoxContainer>
            <FooterContainer>
              <div>
                <BodyLarge className={'label'}>{label}</BodyLarge>
                {mediaNode.status !== MediaStatus.Complete ? status : null}
                {subtitle && mediaNode.status === MediaStatus.Complete ? (
                  <Body color={'@text-color-secondary-dark'}>{subtitle}</Body>
                ) : null}
              </div>
              <FooterRight className={'footer-right'}>
                <ArrowRightOutlined />
              </FooterRight>
            </FooterContainer>
          </Badge>
        </Tooltip>
      </CardInnerContainer>
    </Card>
  )
}
