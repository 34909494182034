import type { SerializedListItemNode, SerializedListNode } from '@lexical/list'
import type { ListNodeTagType } from '@lexical/list/LexicalListNode'
import type { SerializedHeadingNode, SerializedQuoteNode } from '@lexical/rich-text'
import type {
  SerializedElementNode,
  SerializedLexicalNode,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedRootNode,
  SerializedTextNode,
} from 'lexical'

import { SerializedFileNode } from './editorNodes/FileNode'
import type { SerializedImageNode } from './editorNodes/ImageNode'
import type { SerializedStyledMarkNode } from './editorNodes/StyledMarkNode'

export type {
  SerializedHeadingNode as Heading,
  SerializedQuoteNode as Quote,
} from '@lexical/rich-text'
export type { SerializedListNode as List, SerializedListItemNode as ListItem } from '@lexical/list'
export type { SerializedStyledMarkNode as Mark } from './editorNodes/StyledMarkNode'
export type { SerializedImageNode as Image } from './editorNodes/ImageNode'
export type {
  SerializedElementNode as Element,
  SerializedLexicalNode as Node,
  SerializedLineBreakNode as LineBreak,
  SerializedTextNode as Text,
  SerializedParagraphNode as Paragraph,
  SerializedRootNode as Root,
} from 'lexical'
export { SerializedWidget } from './WidgetNode'

interface Nodes {
  heading: SerializedHeadingNode
  quote: SerializedQuoteNode
  element: SerializedElementNode
  node: SerializedLexicalNode
  linebreak: SerializedLineBreakNode
  text: SerializedTextNode
  paragraph: SerializedParagraphNode
  list: SerializedListNode
  listitem: SerializedListItemNode
  root: SerializedRootNode
  mark: SerializedStyledMarkNode
  Image: SerializedImageNode
}

export type MaybeElement = SerializedLexicalNode & Partial<SerializedElementNode> & { id?: string }

export function is<K extends keyof Nodes, T extends SerializedLexicalNode = Nodes[K]>(
  node: SerializedLexicalNode | null | undefined,
  type: K,
): node is T {
  return node?.type === type
}

const BASE = {
  format: '' as any,
  indent: 0,
  version: 1 as 1,
  direction: null,
}
export function createText(text: string): SerializedTextNode {
  return {
    ...BASE,
    detail: 0,
    format: 0,
    mode: 'normal',
    style: '',
    type: 'text',
    text,
  }
}
export interface CreateListItemOpts {
  label: string
  zeroBasedIndex: number
  depth: number
}
export function createListItem({
  label,
  zeroBasedIndex,
  depth,
}: CreateListItemOpts): Omit<SerializedListItemNode, 'checked'> {
  return {
    ...BASE,
    type: 'listitem',
    indent: depth,
    value: zeroBasedIndex + 1,
    children: [createText(label)],
  }
}
export interface CreateListOpts {
  depth?: number
  start?: number
  tag?: ListNodeTagType
  listType?: SerializedListNode['listType']
  children?: MaybeElement[]
}
export function createList({
  depth = 0,
  tag = 'ul',
  start = 1,
  listType = 'bullet',
  children = [],
}: CreateListOpts): SerializedListNode {
  return {
    ...BASE,
    listType,
    start,
    tag,
    type: 'list',
    indent: depth,
    children,
  }
}
export interface CreateListAsListItemOpts extends CreateListOpts {
  zeroBasedIndex: number
}
export function createListAsListItem({
  zeroBasedIndex,
  depth = 0,
  ...rest
}: CreateListAsListItemOpts) {
  const listItem = createListItem({ zeroBasedIndex, label: '', depth })
  listItem.children = [createList({ depth, ...rest })]
  return listItem
}

export function createFile(fields?: Partial<SerializedFileNode>): SerializedFileNode {
  return {
    type: 'File',
    version: 1,
    ...fields,
  }
}
