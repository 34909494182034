import { AntIconWrapper, Button } from '@thesisedu/react'
import { NavArrowDown } from '@thesisedu/react/icons'
import React from 'react'

import { ReportDimensionResource } from '../types'

export interface DimensionOptionsButtonProps<Options> {
  dimension: ReportDimensionResource<Options, object>
  options?: Options
  context?: object
  onChange?: (options: Options, context: object, overrideIdentifier?: string) => void
}
export function DimensionOptionsButton({
  dimension,
  options,
  context,
  onChange,
}: DimensionOptionsButtonProps<object>) {
  const LabelComponent = dimension.renderOptionsLabel
  const WrapperComponent = dimension.renderOptionsWrapper
  if (!LabelComponent || !WrapperComponent) return null
  return (
    <WrapperComponent
      value={options}
      context={context}
      onConfirm={(val, context, identifier) => {
        if (onChange) {
          onChange(val, context, identifier)
        }
      }}
    >
      <Button
        children={
          <>
            &nbsp;
            <LabelComponent value={options} context={context} />
          </>
        }
        icon={<AntIconWrapper children={<NavArrowDown />} />}
      />
    </WrapperComponent>
  )
}
