import { toGlobalId } from '@thesisedu/feature-utils'
import { pick } from 'lodash'

import { convertChildSegments } from './convertChildSegments'
import { INPUT_FIELDS } from '../constants'
import { CourseEditorWriteContextValue } from '../contexts/CourseEditorContext'
import { debug } from '../log'
import { SegmentInput } from '../schema'
import { CreateOrUpdateSegment } from '../types'

export const getOnSegmentChange =
  (
    courseVersionId: string,
    createOrUpdateSegment: CreateOrUpdateSegment,
  ): CourseEditorWriteContextValue['onSegmentChange'] =>
  async (segment, isSimple = false) => {
    debug('segment changed')
    debug(segment)
    const updated = convertChildSegments(segment)
    const input: SegmentInput = {
      ...pick(updated, INPUT_FIELDS),
      id: toGlobalId('Segment', updated.id),
    }
    if (!isSimple) {
      input.updateCourseVersionId = courseVersionId
    }
    await createOrUpdateSegment({
      variables: { input },
      optimisticResponse: {
        __typename: 'Mutation',
        createOrUpdateSegment: {
          __typename: 'SegmentPayload',
          segment: {
            __typename: 'Segment',
            id: toGlobalId('Segment', updated.id),
            built: updated,
          },
        },
      },
    })
  }
