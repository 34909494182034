import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { Dropdown } from '@thesisedu/ui'
import { EyeEmpty, EyeOff, Group, User } from '@thesisedu/ui/icons'
import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { useEnableForStudentsContext } from '../../contexts/EnableForStudentsContext'
import { useOutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentType } from '../../schema'
import {
  ClassFragmentWithPermissions,
  SegmentActionComponentProps,
  SegmentActionResource,
} from '../../types'

function EnableFor({ segment }: SegmentActionComponentProps) {
  const context = useEnableForStudentsContext(false)
  const { enabledSegmentIds, setSegmentEnabled } = useOutlineSegmentContext()
  const enabled = !!enabledSegmentIds.includes(segment.id)
  const action = enabled ? 'Disable' : 'Enable'
  const icon = enabled ? <EyeOff /> : <EyeEmpty />
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canEnableSegments = cls?.canEnableSegments
  if (!context) return null
  return enabled ? (
    <>
      <Dropdown.Item
        icon={icon}
        disabled={!canEnableSegments}
        onClick={() => {
          setSegmentEnabled(segment.id, !enabled)
        }}
      >
        Disable
      </Dropdown.Item>
      <Dropdown.Item
        icon={<User />}
        onClick={() => {
          context.enableForStudents(segment.id)
        }}
      >
        Enable for some students...
      </Dropdown.Item>
    </>
  ) : (
    <>
      <Dropdown.SubMenu>
        <Dropdown.SubMenu.Trigger
          children={`${action} for...`}
          icon={icon}
          disabled={!canEnableSegments}
        />
        <Dropdown.SubMenu.Content>
          <Dropdown.Item
            icon={<Group />}
            onClick={() => {
              setSegmentEnabled(segment.id, !enabled)
            }}
          >
            All students
          </Dropdown.Item>
          <Dropdown.Item
            icon={<User />}
            onClick={() => {
              context.enableForStudents(segment.id)
            }}
          >
            Some students...
          </Dropdown.Item>
        </Dropdown.SubMenu.Content>
      </Dropdown.SubMenu>
    </>
  )
}

export const EnableForAction: SegmentActionResource = {
  identifier: 'enable-for',
  type: 'SegmentAction',
  component: EnableFor,
  group: 'Enable & Disable',
  weight: 101,
  filter: segment => {
    return NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}
