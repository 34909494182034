import { ClientRect, closestCenter, CollisionDetection } from '@dnd-kit/core'
import React from 'react'

function moveRect(rect: ClientRect, x: number, y: number): ClientRect {
  return {
    bottom: rect.bottom - y,
    height: rect.height,
    left: rect.left + x,
    right: rect.right - x,
    top: rect.top + y,
    width: rect.width,
  }
}

export interface UseOffsetClosestCenterOpts {
  forceOverIdRef?: React.MutableRefObject<string | undefined | null>
}
export function useOffsetClosestCenter({
  forceOverIdRef,
}: UseOffsetClosestCenterOpts = {}): CollisionDetection {
  return React.useCallback(args => {
    if (forceOverIdRef?.current) {
      return [{ id: forceOverIdRef.current }]
    } else {
      return closestCenter({
        ...args,
        collisionRect: moveRect(
          // We have to reset the collisionRect left / top to the pointer position because it
          // appears to be calculating it incorrectly in some cases.
          {
            ...args.collisionRect,
            left: args.pointerCoordinates?.x ?? args.collisionRect.left,
            top: args.pointerCoordinates?.y ?? args.collisionRect.top,
          },
          0,
          -30,
        ),
      })
    }
  }, [])
}
