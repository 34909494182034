import React from 'react'

import { ImageOverlay } from './ImageOverlay'
import { styled, s } from '../..'
import { LoadingIndicator } from '../LoadingIndicator'

export function ImageLoadingOverlay() {
  return (
    <ImageOverlay animated>
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    </ImageOverlay>
  )
}

const LoadingContainer = styled.div`
  position: absolute;
  top: ${s.size('1')};
  right: ${s.size('1')};
  background: ${s.var('color.overlayBlack.10')};
  border-radius: ${s.var('radii.1')};
  color: ${s.var('color.overlayWhite.11')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${s.size('6')};
  height: ${s.size('6')};
`
