import { SAMLButtonResource } from '@thesisedu/feature-users-react'

import { AuthMSALReactFeature } from '../AuthMSALReactFeature'
import { MSALLoginButton } from '../web/MSALLoginButton'

export default function (feature: AuthMSALReactFeature) {
  feature.resources.addResource<SAMLButtonResource>({
    type: 'SAMLButton',
    identifier: 'msal',
    weight: 4,
    component: MSALLoginButton,
  })
}
