import { Body, FontWeight } from '@thesisedu/web'
import { Tooltip } from 'antd'
import React from 'react'

import { CommentApproval, CommentFragment } from '../schema'

type CommentStateMap = {
  [key in CommentApproval]: string
}
const colorMap: CommentStateMap = {
  APPROVED: '@green',
  REJECTED: '@red',
  UNMODERATED: '@text-color-secondary',
}
const textMap: CommentStateMap = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  UNMODERATED: 'Unmoderated',
}

export interface CommentStatusProps {
  comment: Pick<CommentFragment, 'approvedAt' | 'rejectedAt' | 'rejectedReason'>
}
export function CommentStatus({ comment }: CommentStatusProps) {
  let commentState: CommentApproval
  if (comment.approvedAt && !comment.rejectedAt) {
    commentState = CommentApproval.Approved
  } else if (comment.rejectedAt && !comment.approvedAt) {
    commentState = CommentApproval.Rejected
  } else {
    commentState = CommentApproval.Unmoderated
  }
  const content = (
    <Body
      style={{ display: 'inline' }}
      weight={FontWeight.Bold}
      color={colorMap[commentState]}
      children={textMap[commentState]}
    />
  )
  if (commentState === CommentApproval.Rejected) {
    return <Tooltip title={comment.rejectedReason} children={content} />
  } else {
    return content
  }
}
