import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { ExtraSmallButton } from '@thesisedu/web'
import React, { useState } from 'react'

import { ClassFragmentWithPermissions } from '../types'

export interface SegmentLockProps {
  isLocked?: boolean
  setSegmentLock: (segmentId: string, locked: boolean) => Promise<void>
  segmentId: string
  style?: any
}
const _SegmentLock: React.FC<React.PropsWithChildren<SegmentLockProps>> = ({
  isLocked,
  setSegmentLock,
  segmentId,
  style,
}) => {
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canLockSegments = cls?.canLockSegments
  const [loading, setLoading] = useState(false)
  return (
    <ExtraSmallButton
      title={isLocked ? 'Unlock Segment' : 'Lock Segment'}
      onClick={() => {
        setLoading(true)
        setSegmentLock(segmentId, !isLocked).finally(() => setLoading(false))
      }}
      loading={loading}
      type={isLocked ? 'primary' : undefined}
      danger
      style={canLockSegments ? style : { ...style, pointerEvents: 'none' }}
      icon={isLocked ? <LockOutlined /> : <UnlockOutlined />}
    />
  )
}

export const SegmentLock = React.memo(_SegmentLock)
