import { s, Button, Modal } from '@thesisedu/ui'
import { MagicWand } from '@thesisedu/ui/icons'
import React from 'react'

import { LoadGenerateGroupsConfiguration } from './LoadGenerateGroupsConfiguration'
import { useGroupsContext } from '../GroupsContext'

export function GenerateGroupsButton() {
  const { onChange, students, ungroupedStudents } = useGroupsContext(true)
  const [visible, setVisible] = React.useState(false)
  return (
    <Modal
      title={'Generate Groups'}
      trigger={<Button icon={<MagicWand />} children={'Generate Groups'} />}
      width={450}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <LoadGenerateGroupsConfiguration
        onChange={(...args) => {
          setVisible(false)
          onChange?.(...args)
        }}
        students={[...Object.values(students), ...ungroupedStudents].flat()}
        submitButtonProps={{
          style: { width: 'auto', marginTop: s.size('m'), marginLeft: 'auto' },
          variant: 'primary',
        }}
      />
    </Modal>
  )
}
