import { ReactFeature, ReactUse, ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

export class SearchReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-search-react'
  static path = ''
  static requires = []

  public reactResources() {
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, async children => {
      const { QuickSearchProvider } = require('./results/QuickSearchContext')
      return <QuickSearchProvider children={children} />
    })
    require('./resources/search').default(this)

    require('./hooks/shellTopRight').default(this)
    require('./hooks/routes').default(this)
  }
}

export const searchReactFeature: ReactUse<object> = opts => [SearchReactFeature, opts]
