import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { useIds } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { getItem, isNative, LoadingIndicator, storeItem, useNavigate } from '@thesisedu/react'
import React from 'react'

import { NO_CLASS_OPTION } from './constants'
import { debug } from './log'
import {
  MinimalClassFragment,
  StudentClassesEdgeFragment,
  useMinimalStudentClassesQuery,
  useMinimalTeacherClassesQuery,
} from './schema'

const STORAGE_KEY = 'selected-class-id'

export interface ClassContextValue {
  classes: MinimalClassFragment[]
  loading?: boolean
  error?: boolean
  selectedClassId?: string
  setSelectedClassId: (selectedClassId: string) => void
  disableSelector?: boolean
  setDisableSelector: (disable: boolean) => void
}
export const ClassContext = React.createContext<ClassContextValue>({
  classes: [],
  setSelectedClassId: () => false,
  setDisableSelector: () => false,
})

export interface MinimalClassClass extends MinimalClassFragment {
  /** Only available for student users. */
  edge?: Omit<StudentClassesEdgeFragment, 'node'>
}
interface MinimalClassesQuery {
  classes: MinimalClassClass[]
  loading?: boolean
  error?: boolean
}
function useMinimalClassesQuery(skip?: boolean): MinimalClassesQuery {
  const viewer = useViewerContext(false)
  const studentClasses = useMinimalStudentClassesQuery({
    skip: viewer?.group !== 'STUDENT' || skip,
  })
  const teacherClasses = useMinimalTeacherClassesQuery({
    skip: viewer?.group !== 'TEACHER' || skip,
  })
  const teacherArchivedClasses = useMinimalTeacherClassesQuery({
    skip: viewer?.group !== 'TEACHER' || skip,
    variables: { deleted: true },
    context: {
      customBatchKey: 'archived-classes',
    },
  })

  // Record the IDs so we can see them inside DevTools.
  const teacherId = teacherClasses?.data?.viewer?.teacher?.id
  const studentId = studentClasses?.data?.viewer?.student?.id
  useIds([
    ...(teacherId ? [{ id: teacherId, label: 'Teacher' }] : []),
    ...(studentId ? [{ id: studentId, label: 'Student' }] : []),
  ])

  if (viewer?.group === 'TEACHER') {
    const activeClasses =
      teacherClasses.data?.viewer?.teacher?.classes.edges.map(edge => edge.node) || []
    const archivedClasses =
      teacherArchivedClasses.data?.viewer?.teacher?.classes.edges.map(edge => edge.node) || []
    return {
      classes: [...activeClasses, ...archivedClasses],
      loading: teacherClasses.loading,
      error: !!teacherClasses.error,
    }
  } else if (viewer?.group === 'STUDENT') {
    return {
      classes:
        studentClasses.data?.viewer?.student?.classes.edges.map(edge => {
          return {
            ...edge.node,
            edge,
          }
        }) || [],
      loading: studentClasses.loading,
      error: !!studentClasses.error,
    }
  } else {
    return {
      classes: [],
      error: true,
      loading: false,
    }
  }
}

export function ClassContextProvider({ children }: React.PropsWithChildren<object>) {
  const [disableSelector, setDisableSelector] = React.useState(false)
  const root = useFeatureRoot()!
  const oldClassId = React.useRef<string | null>()
  const navigate = useNavigate()
  const { classes, loading, error } = useMinimalClassesQuery()
  const [selectedClassId, _setSelectedClassId] = React.useState<string>(
    classes.length ? classes[0].id : NO_CLASS_OPTION,
  )
  useIds(
    selectedClassId !== NO_CLASS_OPTION ? [{ id: selectedClassId, label: 'Selected Class' }] : [],
  )
  React.useEffect(() => {
    getItem<string>(STORAGE_KEY).then(value => {
      if (value) {
        _setSelectedClassId(value)
      }
    })
  }, [])
  function setSelectedClassId(classId: string) {
    _setSelectedClassId(classId)
    storeItem(STORAGE_KEY, classId)
  }

  // On page load, default to a class instead of the personal course.
  React.useEffect(() => {
    if (classes.length > 0) {
      if (!selectedClassId || selectedClassId === NO_CLASS_OPTION) {
        setSelectedClassId(classes.find(cls => !cls.archivedAt)?.id || NO_CLASS_OPTION)
      }
    }
  }, [classes.length > 0])

  // When the list of classes updates, update the selected class.
  React.useEffect(() => {
    if (loading || isNative || error) return
    const params = new URLSearchParams(window.location.search)
    const classId = params.get('classId')
    if (!classId) {
      if (selectedClassId) {
        if (
          selectedClassId !== NO_CLASS_OPTION &&
          !classes.some(cls => cls.id === selectedClassId)
        ) {
          const classId = classes.find(cls => !cls.archivedAt)?.id || NO_CLASS_OPTION
          debug(
            'selected class found, but not present in the classes list, changing to %s',
            classId,
          )
          setSelectedClassId(classId)
        }
      } else if (classes.length) {
        const classId = classes.find(cls => !cls.archivedAt)?.id || NO_CLASS_OPTION
        debug('no selected class found, defaulting to %s', classId)
        setSelectedClassId(classId)
      }
    }
  }, [classes, selectedClassId, setSelectedClassId, loading, error])

  // When the list of classes updates (but not when the selected class updates), select
  // the first class that is not archived (if the currently-selected class is archived).
  // But wait a while before doing it, because sometimes this query flickers.
  React.useEffect(() => {
    if (loading || error) return
    if (
      selectedClassId &&
      selectedClassId !== NO_CLASS_OPTION &&
      !classes.some(cls => cls.id === selectedClassId && !cls.archivedAt)
    ) {
      const classId = classes.find(cls => !cls.archivedAt)?.id || NO_CLASS_OPTION
      debug('new classes list found, setting class id to the first option: %s', classId)
      setSelectedClassId(classId)
    }
  }, [classes])

  // Automatically set the classId based on the default classId passed when
  // loading the page.
  React.useEffect(() => {
    if (isNative) return
    const params = new URLSearchParams(window.location.search)
    const classId = params.get('classId')
    if (classId?.trim()) {
      setSelectedClassId(classId)
      return
    }

    // Now, let's try to get the class ID from the URL.
    const matches = window.location.pathname.match(/(classes|reports)\/([\d=A-Za-z-]+)/)
    if (matches?.length) {
      const newClassId = matches[2]
      if (newClassId === NO_CLASS_OPTION || fromGlobalId(matches[2])?.type === 'Class') {
        debug('setting initial selected class to %s from url', matches[2])
        setSelectedClassId(matches[2])
      }
    }
  }, [])

  // Navigate to the different Class ID if it doesn't match the URL.
  React.useEffect(() => {
    if (
      oldClassId.current &&
      oldClassId.current !== selectedClassId &&
      selectedClassId &&
      !isNative &&
      window.location.pathname.includes(oldClassId.current)
    ) {
      debug('navigating to new selected class id %s', selectedClassId)
      navigate(window.location.pathname.replace(oldClassId.current, selectedClassId))
    }
    oldClassId.current = selectedClassId
  }, [selectedClassId])

  if (loading) {
    return <LoadingIndicator block />
  }
  return (
    <ClassContext.Provider
      value={{
        loading,
        error,
        classes,
        selectedClassId,
        setSelectedClassId,
        disableSelector,
        setDisableSelector,
      }}
    >
      {root.deps.hookManager.mutateHookSync<React.ReactNode>(
        'feature-classes:class-context',
        children,
        undefined,
        { reverse: true },
      )}
    </ClassContext.Provider>
  )
}

/**
 * Hook to get the current list of classes for either a teacher or a student. This
 * hook uses the ClassContext by default, but will fall back to the Apollo query
 * if no context is present.
 *
 * Therefore, this hook is safe to use inside the ClassContext, or outside it.
 */
export interface UseClassesOpts {
  /** Defaults to true. */
  archived?: boolean
}
export function useClasses({ archived = true }: UseClassesOpts = {}): MinimalClassesQuery {
  const context = React.useContext(ClassContext)
  const queryResult = useMinimalClassesQuery(!!context.classes.length)
  const classes = context.classes?.length ? context.classes : queryResult.classes
  const filteredClasses = !archived ? classes.filter(cls => !cls.archivedAt) : classes
  return context.classes?.length
    ? { classes: filteredClasses, loading: context.loading, error: context.error }
    : { ...queryResult, classes: filteredClasses }
}

export interface MinimalClassQuery extends Omit<MinimalClassesQuery, 'classes'> {
  cls?: MinimalClassFragment
}
export function useMinimalClass(classId: string): MinimalClassQuery {
  const result = useClasses()
  return {
    ...result,
    cls: result.classes.find(cls => cls.id === classId),
  }
}

/**
 * Takes the context value and filters the selectedClassId to make sure it
 * is not the personal class identifier. This is guaranteed to return either
 * a class ID if one is selected, or null.
 */
export function getSelectedClassId(context: ClassContextValue): string | null {
  const { selectedClassId } = context
  if (!selectedClassId || selectedClassId === NO_CLASS_OPTION) return null
  else return selectedClassId
}

/**
 * This gets the ID of the currently-selected class.
 */
export function useSelectedClassId(require: true): string
export function useSelectedClassId(require?: boolean): string | null
export function useSelectedClassId(require?: boolean): string | null {
  const selectedClassId = getSelectedClassId(React.useContext(ClassContext))
  if (!selectedClassId && require) {
    throw new Error('A selected class ID is required, yet not found.')
  }
  return selectedClassId
}

/**
 * If you need access to the selected class anywhere in the system, use this
 * hook. If you pass true for require, it will throw an error if there is
 * no selected class, so be careful when using that option.
 */
export function useSelectedClass(require: false): MinimalClassFragment | null
export function useSelectedClass(require: true): MinimalClassFragment
export function useSelectedClass(require?: boolean): MinimalClassFragment | null {
  const context = React.useContext(ClassContext)
  const oldValue = React.useRef<MinimalClassFragment | undefined>()
  const selectedClassId = getSelectedClassId(context)
  const cls = selectedClassId
    ? context.classes.find(cls => cls.id === selectedClassId) || oldValue.current
    : oldValue.current
  if (!cls && require) {
    debug('uh oh, cannot find class %s!', selectedClassId)
    debug('context', context)
    debug('cls', cls)
    throw new Error('useSelectedClass() is required, but no selected class found.')
  }

  oldValue.current = cls
  return cls || null
}

export function useClassSelector() {
  const { classes, selectedClassId, setSelectedClassId, disableSelector, loading, error } =
    React.useContext(ClassContext)
  return { classes, selectedClassId, setSelectedClassId, disableSelector, loading, error }
}

export function useDisableClassSelector(disable: boolean) {
  const { setDisableSelector } = React.useContext(ClassContext)
  React.useEffect(() => {
    setDisableSelector(disable)
  }, [disable])
  React.useEffect(() => {
    return () => {
      setDisableSelector(false)
    }
  }, [])
}
