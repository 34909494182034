import { Form, Text } from '@thesisedu/ui'
import React from 'react'

import { useQuestionSettingsContext } from './QuestionSettingsContext'

export interface QuestionTotalPointsProps {
  questionId: string
}
export function QuestionTotalPoints({ questionId }: QuestionTotalPointsProps) {
  const { form } = useQuestionSettingsContext(true)
  return (
    <Form.NumberField
      control={form.control}
      name={[questionId, 'totalPoints'].join('.')}
      label={
        <Text level={'s'} color={'secondary'} style={{ textAlign: 'end' }}>
          Total Points
        </Text>
      }
      aria-label={'Total Points'}
      style={{ width: 100, alignItems: 'flex-end' }}
      min={0}
      size={'small'}
      formatOptions={{
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }}
    />
  )
}

export function MaybeQuestionTotalPoints(props: QuestionTotalPointsProps) {
  const hasSettings = !!useQuestionSettingsContext(false)
  return hasSettings ? <QuestionTotalPoints {...props} /> : null
}
