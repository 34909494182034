import React from 'react'

import { DateFieldProps } from './DateField'
import { RangeValue } from './DateRangePickerField'
import { s } from '../..'
import { DeleteCircle } from '../../../icons'
import Button from '../Button'

export interface ClearButtonProps {
  onChange?: (value: any | null) => void
  value?: string | RangeValue | null
  size?: DateFieldProps['size']
  disabled?: boolean
}
export function ClearButton({
  onChange,
  value,
  size = s.SharedVariants.Size.defaultValue,
  disabled,
}: ClearButtonProps) {
  return value ? (
    <Button
      size={size}
      icon={<DeleteCircle />}
      style={{ padding: 4 }}
      disabled={disabled}
      onPress={() => {
        onChange?.(null)
      }}
    />
  ) : null
}
