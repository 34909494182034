import { FeatureResource } from '@thesisedu/feature'
import { useResource } from '@thesisedu/feature-react'
import { HSpaced, styled } from '@thesisedu/web'
import { orderBy } from 'lodash'
import React from 'react'

export interface SegmentIndicatorProps {
  segmentId: string
}
export interface SegmentIndicatorResource extends FeatureResource {
  type: 'SegmentIndicator'
  component: (props: SegmentIndicatorProps) => React.ReactElement | null
  weight?: number
}

export interface SegmentIndicatorsProps extends SegmentIndicatorProps {}
export function SegmentIndicators(props: SegmentIndicatorsProps) {
  const indicators = orderBy(
    useResource<SegmentIndicatorResource>('SegmentIndicator'),
    item => item.weight || 0,
  )
  return (
    <Container space={'@size-xxs'}>
      {indicators.map(indicator => {
        const IndicatorComponent = indicator.component
        return <IndicatorComponent {...props} key={indicator.identifier} />
      })}
    </Container>
  )
}

const Container = styled(HSpaced)`
  margin-right: ${props => props.theme['@size-xs']};
  &:empty {
    display: none;
  }
`
