import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  AssignmentEditorField,
  EditAssignmentForm,
  CopyFromTemplateButton,
} from '@thesisedu/feature-assignments-react'
import {
  IconSelect,
  SegmentProps,
  EditableSegment,
  DaysInput,
  Editor,
  OverrideField,
} from '@thesisedu/feature-courses-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Block, BlockSpin, Row } from '@thesisedu/web'
import { AutoComplete, Checkbox, Col, Form, Input, Tabs } from 'antd'
import React from 'react'

import { AssignmentDefaults } from './AssignmentDefaults'
import { AssignmentTemplateId } from './AssignmentTemplateId'
import { useSegmentAssignmentEdit } from './useSegmentAssignmentEdit'
import { RubricTypeSelect } from '../../RubricTypeSelect'
import { useOptions } from '../../useOptions'

export function AssignmentEdit(props: SegmentProps) {
  const { assignmentLabels, assignmentNamePresets, labelPlaceholder } = useOptions()
  const viewer = useViewerContext(true)
  const isAdmin = viewer.group === 'ADMIN'
  const { onChange, assignment, loading } = useSegmentAssignmentEdit(props)
  return (
    <EditableSegment
      {...props}
      loading={loading || props.loading}
      onChange={async (originalSegment, values) => {
        await onChange(originalSegment, values)
      }}
    >
      {({ submitFields, form, segment }) =>
        loading ? (
          <BlockSpin />
        ) : (
          <Row>
            <Col xs={24} md={8} lg={6}>
              <AssignmentTemplateId templateId={segment.config.templateId} />
              <OverrideField
                label={'Label'}
                name={['config', 'label']}
                initialValue={segment.config.label}
                overrideFieldName={['config', 'studentLabel']}
                overrideInitialValue={segment.config.studentLabel}
              >
                <AutoComplete
                  dataSource={assignmentLabels}
                  filterOption={(inputValue, option) => {
                    return (
                      option?.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    )
                  }}
                  placeholder={labelPlaceholder}
                />
              </OverrideField>
              <Form.Item
                name={['config', 'rubricType']}
                label={'Rubric Type'}
                initialValue={segment.config.rubricType}
                rules={[
                  { required: true, message: 'You must select a rubric type for the assignment.' },
                ]}
              >
                <RubricTypeSelect />
              </Form.Item>
              <OverrideField
                label={'Name'}
                name={'name'}
                initialValue={segment.name}
                overrideFieldName={['config', 'studentName']}
                overrideInitialValue={segment.config.studentName}
                rules={[{ required: true, message: 'An assignment name is required.' }]}
              >
                {assignmentNamePresets.length ? (
                  <AutoComplete
                    dataSource={assignmentNamePresets}
                    filterOption={(inputValue, option) => {
                      return (
                        option?.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                        -1
                      )
                    }}
                  />
                ) : (
                  <Input />
                )}
              </OverrideField>
              {assignment?.id ? (
                <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
                  <CopyFromTemplateButton assignmentTemplateId={assignment.id} />
                </Block>
              ) : null}
              <EditAssignmentForm form={form} disableInput={loading} value={assignment} hideName />
              <Form.Item
                name={['assignment', 'configuration', 'excludeAllFromQuizVault']}
                initialValue={assignment?.configuration?.excludeAllFromQuizVault}
                valuePropName={'checked'}
                extra={
                  <span>
                    If this is checked, all questions inside this assignment will be excluded from
                    quiz vault, regardless of their individual configuration.
                  </span>
                }
              >
                <Checkbox>Exclude all questions from Quiz Vault</Checkbox>
              </Form.Item>
              <Form.Item
                name={['config', 'templateId']}
                initialValue={segment.config.templateId}
                noStyle
              >
                <input type={'hidden'} />
              </Form.Item>
              {isAdmin ? (
                <Form.Item
                  extra={'Hide this content from both teachers and students.'}
                  name={['config', 'isHidden']}
                  initialValue={segment.config.isHidden}
                  valuePropName={'checked'}
                >
                  <Checkbox>Hidden</Checkbox>
                </Form.Item>
              ) : null}
              {form?.getFieldValue(['config', 'isHidden']) !== true ? (
                <Form.Item
                  name={['config', 'days']}
                  label={'Minutes'}
                  extra={'How many minutes should be reserved for this content?'}
                  initialValue={segment.config.days}
                  rules={[
                    {
                      required: true,
                      message: 'You must specify a number of minutes. It can be 0.',
                    },
                  ]}
                >
                  <DaysInput />
                </Form.Item>
              ) : null}
              <Form.Item
                name={['config', 'icon']}
                label={'Icon'}
                extra={'This is the icon displayed to teachers and students for this assignment.'}
                initialValue={segment.config.icon}
              >
                <IconSelect />
              </Form.Item>
              {submitFields}
            </Col>
            <Col xs={24} md={16} lg={18}>
              <Tabs defaultActiveKey={'assignment'}>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <QuestionCircleOutlined /> Assignment
                    </span>
                  }
                  key={'assignment'}
                >
                  <Form.Item
                    name={['assignment', 'configuration']}
                    initialValue={assignment?.configuration}
                    noStyle
                  >
                    <AssignmentEditorField assignment={assignment} />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <UserOutlined /> Student Instructions
                    </span>
                  }
                  key={'student'}
                >
                  <Form.Item
                    name={['config', 'studentContent']}
                    initialValue={segment.config.studentContent}
                    noStyle
                  >
                    <Editor id={`${segment.id}-student`} />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <InfoCircleOutlined /> Teacher Notes
                    </span>
                  }
                  key={'teacher'}
                >
                  <Form.Item
                    name={['config', 'teacherContent']}
                    initialValue={segment.config.teacherContent}
                    noStyle
                  >
                    <Editor id={`${segment.id}-teacher`} />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <SettingOutlined /> Default Settings & Rubric
                    </span>
                  }
                  key={'default-settings'}
                >
                  <Form.Item name={['assignment', 'defaults']} initialValue={assignment?.defaults}>
                    <AssignmentDefaults />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        )
      }
    </EditableSegment>
  )
}
