import { useViewerContext } from '@thesisedu/feature-users-web'
import { LoadingIndicator } from '@thesisedu/react'
import { getFlag } from '@thesisedu/web'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ContentBrowser } from './ContentBrowser'
import { ContentManagerIntro } from './ContentManagerIntro'
import { CreateEditContent } from './CreateEditContent'
import { useTotalBuiltSegmentsQuery } from '../schema'
import { CourseFlags } from '../types'

export function ContentManager() {
  const introFlag = !!getFlag(CourseFlags.ContentManagerIntroductionShown)

  const viewer = useViewerContext(true)

  const { data, loading } = useTotalBuiltSegmentsQuery({
    variables: {
      userIds: [viewer.id],
    },
  })

  const shouldShowIntro = !loading && data?.segments.totalCount === 0 && !introFlag

  return (
    <Routes>
      <Route path={'/create/:segmentType'} element={<CreateEditContent />} />
      <Route path={'/:segmentId'} element={<CreateEditContent />} />
      <Route
        path={'/'}
        element={
          !loading ? (
            shouldShowIntro ? (
              <ContentManagerIntro />
            ) : (
              <ContentBrowser />
            )
          ) : (
            <LoadingIndicator block />
          )
        }
      />
    </Routes>
  )
}
