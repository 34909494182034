import { Slider, styled } from '@thesisedu/ui'
import React from 'react'

import { useMediaPlaybackContext } from './MediaPlaybackContext'

const SliderContainer = styled.div<{ $loadedPercentage: number }>`
  flex-grow: 1;
  position: relative;
  ${Slider.Root} {
    width: 100%;
    ${Slider.Track}, ${Slider.Range} {
      transition: transform 0.25s ease-in-out;
    }
    ${Slider.Track} {
      background: rgba(255, 255, 255, 30%);
      &::before {
        display: block;
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: ${props => props.$loadedPercentage * 100}%;
        border-radius: 9999px;
        background: rgba(255, 255, 255, 30%);
      }
    }
    ${Slider.Range} {
      background: rgba(255, 255, 255, 90%);
    }
    ${Slider.Thumb} {
      transition:
        transform 0.25s ease-in-out,
        opacity 0.25s ease-in-out;
      background: white;
      transform: scale(0.75);
      box-shadow: none;
      opacity: 0;
      z-index: 100;
    }
    &:hover {
      ${Slider.Track}, ${Slider.Range} {
        transform: scaleY(1.5);
      }
      ${Slider.Thumb} {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
`

const DEBOUNCE = 500
export function Track({ children }: React.PropsWithChildren<object>) {
  const { loadedPercentage, duration, onSeek, allowSeek, playedSeconds } = useMediaPlaybackContext()
  const [localValue, setLocalValue] = React.useState<number>(0)
  const timeout = React.useRef<any>()
  React.useEffect(() => {
    if (!timeout.current) {
      setLocalValue(playedSeconds)
    }
  }, [playedSeconds])
  return (
    <SliderContainer $loadedPercentage={loadedPercentage} className={'track'}>
      {duration ? (
        <>
          <Slider
            onValueChange={values => {
              const value = values[0]
              setLocalValue(value)
              if (timeout.current) {
                clearInterval(timeout.current)
                timeout.current = undefined
              }
              timeout.current = setTimeout(() => {
                onSeek(value)
                timeout.current = undefined
              }, DEBOUNCE)
            }}
            max={duration}
            min={0}
            step={0.25}
            value={[localValue]}
            disabled={!allowSeek}
          />
          <DecoratorContainer>{children}</DecoratorContainer>
        </>
      ) : null}
    </SliderContainer>
  )
}

const DecoratorContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 3px;
  margin-top: -1.5px;
  pointer-events: none;
`
