import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Block, Body, ExternalLink, VSpaced } from '@thesisedu/react'
import React from 'react'

import { useQuestionResources } from '../questions/useQuestionResource'

export interface AssignmentInvalidGateProps {
  configuration?: AssignmentConfiguration | null
  assignmentId: string
}
export function AssignmentInvalidGate({
  configuration,
  assignmentId,
  children,
}: React.PropsWithChildren<AssignmentInvalidGateProps>) {
  const root = useFeatureRoot()
  const questionResources = useQuestionResources()
  const allQuestionsValid = (configuration?.questions || [])
    .filter(q => !q.disabled)
    .every(q => questionResources.some(r => r.identifier === q.type))

  if (allQuestionsValid) {
    return <>{children}</>
  } else {
    return (
      <Block marginTop={'@size-l'} marginBottom={'@size-l'}>
        <VSpaced align={'center'} space={'@size-l'}>
          <Body color={'@text-color-secondary'} style={{ width: '90%', textAlign: 'center' }}>
            You cannot complete this assignment on your device. To complete this assignment, view it
            on the {root.appOptions.name} website.
          </Body>
          <ExternalLink
            href={`${root.appOptions.frontend}/assignment/${assignmentId}`}
            target={'_blank'}
          >
            <Body color={'@primary-color'}>View on {root.appOptions.rootDomain}.</Body>
          </ExternalLink>
        </VSpaced>
      </Block>
    )
  }
}
