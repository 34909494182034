import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import {
  TagFragment,
  SearchTagsQuery,
  SearchTagsQueryVariables,
  SearchTagsDocument,
} from '@thesisedu/feature-tags-react/dist/schema'
import { set } from 'lodash'
import React from 'react'
import { useDebounce } from 'use-debounce'

import { ExploreListItem } from './ExploreListItem'
import { ExploreWidget } from './ExploreWidget'

const SEARCH_TIMEOUT = 1000
export interface ExploreTagsWidgetProps {
  tagTypes: string[]
  title: string
  placeholder: string
  pathRoot: string
}
export function ExploreTagsWidget({
  pathRoot,
  tagTypes,
  title,
  placeholder,
}: ExploreTagsWidgetProps) {
  const [nameFilter, setNameFilter] = React.useState('')
  const [dNameFilter] = useDebounce(nameFilter, SEARCH_TIMEOUT)
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  return (
    <ExploreWidget
      title={title}
      nameFilter={nameFilter}
      setNameFilter={setNameFilter}
      placeholder={placeholder}
    >
      <InfiniteQuery<TagFragment, SearchTagsQuery, SearchTagsQueryVariables>
        document={SearchTagsDocument}
        variables={{
          types: tagTypes,
          name: dNameFilter || undefined,
          first: 32,
        }}
        getResult={data => data?.tags}
        setResult={(p, n) => set(cloneDeep(p), 'tags', n)}
        infiniteScrollerProps={{ scrollableTarget: containerRef.current || undefined }}
        children={({ data }) => (
          <>
            {data?.tags.edges.map(edge => {
              const { inLicense } = edge.node as any
              return (
                <ExploreListItem
                  key={edge.node.id}
                  title={edge.node.name}
                  path={`${pathRoot}/${edge.node.id}`}
                  disabled={!inLicense}
                  rightText={inLicense ? undefined : 'Not in License'}
                />
              )
            }) || null}
          </>
        )}
      />
    </ExploreWidget>
  )
}
