import * as Sentry from '@sentry/react'
import { ERROR_PAGE_PROPS_HOOK } from '@thesisedu/feature-web'
import { ErrorPageProps } from '@thesisedu/web'

import { SentryWebFeature } from '../SentryWebFeature'

export default function (feature: SentryWebFeature) {
  feature.hookManager.registerMutateHook<Partial<ErrorPageProps>, Error>(
    ERROR_PAGE_PROPS_HOOK,
    async props => {
      return {
        ...props,
        description:
          "Something didn't go as planned. We're already on it, but your input can help " +
          'us resolve this faster. Could you let us know what you were up to when the issue ' +
          'occurred?',
        action: 'Report Feedback',
        onActionClick: () => {
          Sentry.showReportDialog({ eventId: Sentry.lastEventId() })
        },
      }
    },
  )
}
