import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { DATE_FORMATS, BodySmall, styled } from '@thesisedu/web'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'

import {
  CoursesHooks,
  Segment,
  SegmentDescriptionsContext,
  SegmentDescriptionsPayload,
} from '../types'

export interface SegmentDescriptionsProps {
  segment: Segment
}
export function SegmentDescriptions({ segment }: SegmentDescriptionsProps) {
  const viewer = useViewerContext(false)
  const root = useFeatureRoot()!
  const defaults: React.ReactElement[] = []
  if (segment.userId === viewer?.id) {
    defaults.push(<React.Fragment key={'custom'}>Custom</React.Fragment>)
    if (segment.updatedAt) {
      defaults.push(
        <React.Fragment key={'custom-updated'}>
          Last updated {moment(segment.updatedAt).format(DATE_FORMATS.FULL)}
        </React.Fragment>,
      )
    }
  }
  if (segment.config.days && viewer?.group === 'TEACHER') {
    defaults.push(
      <React.Fragment key={'days'}>
        <strong>Est:&nbsp;</strong>
        {pluralize('minute', segment.config.days * 60, true)}
      </React.Fragment>,
    )
  }
  const children = root.deps.hookManager.mutateHookSync<
    SegmentDescriptionsPayload,
    SegmentDescriptionsContext
  >(CoursesHooks.SegmentDescriptions, defaults, { segment })
  const filtered = children.filter(Boolean)

  return (
    <DescriptionsContainer color={'@text-color-secondary'}>
      {filtered.slice(1).reduce<React.ReactElement>((acc, child) => {
        return (
          <>
            {acc}&nbsp;-&nbsp;{child}
          </>
        )
      }, filtered[0])}
    </DescriptionsContainer>
  )
}

const DescriptionsContainer = styled(BodySmall)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
