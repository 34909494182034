import { omitDeep } from '@thesisedu/react'
import { pick } from 'lodash'

import { useUpdateMediaMutation } from '../../queries/useUpdateMediaMutation'
import { useMediaContext } from '../../record/MediaContext'
import { useMediaUpdatesContext } from '../../record/MediaUpdatesContext'
import { MediaInput } from '../../schema'

const INPUT_KEYS = ['metadata', 'status']

export type UpdateMediaCallback = (
  callback: (existing: Partial<MediaInput>) => Partial<MediaInput>,
) => void | Promise<any>
export function useUpdateMedia(): [UpdateMediaCallback, { loading: boolean }] {
  const { media } = useMediaContext(false) ?? {}
  const [updateMedia, { loading }] = useUpdateMediaMutation()
  const { setMediaUpdates } = useMediaUpdatesContext(false) ?? {}
  return [
    setMediaUpdates ??
      (callback => {
        if (!media)
          throw new Error(
            'Trying to update media inside #useUpdateMedia() when there is no useMediaUpdatesContext, and no media context',
          )
        const updated = callback(media)
        return updateMedia({
          variables: {
            input: {
              id: media.id,
              patch: pick(omitDeep(updated, ['__typename']), INPUT_KEYS),
            },
          },
        })
      }),
    { loading },
  ]
}
