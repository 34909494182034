import { EditDistrictRightContentHook } from '@thesisedu/feature-districts-web'
import { DistrictSitesTable } from '@thesisedu/feature-sites-web'
import React from 'react'

import { SiteTeachers } from '../SiteTeachers'
import { TeachersWebFeature } from '../TeachersWebFeature'

export default function (feature: TeachersWebFeature) {
  feature.hookManager.registerMutateHook<EditDistrictRightContentHook>(
    'districts-web:right-content',
    (content, context) => {
      return [
        <DistrictSitesTable
          key={'teacher-sites'}
          districtId={context.districtId}
          expandable={{
            expandedRowRender: record => <SiteTeachers siteId={record.id} isEmbedded />,
          }}
        />,
        ...content,
      ]
    },
  )
}
