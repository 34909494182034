import { MutationHookOptions, MutationTuple } from '@apollo/client'
import {
  findSegment,
  getSegmentParents as rawGetSegmentParents,
} from '@thesisedu/feature-course-types'
import { toGlobalId } from '@thesisedu/feature-utils'

import { debug } from '../log'
import {
  CourseVersionFragment,
  CourseVersionFragmentDoc,
  CreateOrUpdateSegmentMutation,
  CreateOrUpdateSegmentMutationVariables,
  SegmentWithHydrationDataFragment,
  useCreateOrUpdateSegmentMutation as useMutation,
  SegmentWithHydrationDataFragmentDoc,
} from '../schema'
import { CourseConfiguration, Segment } from '../types'

const _updateSegmentList = (
  segments: Segment[],
  builtSegment: Segment,
  parents: string[],
  index: number,
): Segment[] => {
  return segments.map(segment => {
    if (segment.id === builtSegment.id) {
      return { ...segment, ...builtSegment }
    } else if (parents[index] === segment.id) {
      return {
        ...segment,
        childSegments: segment.childSegments
          ? _updateSegmentList(segment.childSegments, builtSegment, parents, index + 1)
          : undefined,
      }
    } else {
      return segment
    }
  })
}
const generateUpdatedSegment = (
  configuration: CourseConfiguration,
  builtSegment: Segment,
): CourseConfiguration => {
  debug('generating updated segment %s %O', builtSegment.id, builtSegment)
  const parents = rawGetSegmentParents(configuration.segments, builtSegment.id)
  debug('- parents are', parents)
  return {
    ...configuration,
    segments: _updateSegmentList(configuration.segments, builtSegment, parents, 0),
  }
}

export const useCreateOrUpdateSegmentMutation = (
  opts: MutationHookOptions<CreateOrUpdateSegmentMutation, CreateOrUpdateSegmentMutationVariables>,
  courseVersionId: string,
): MutationTuple<any, any> =>
  useMutation({
    ...opts,
    update: (proxy, mutationResult) => {
      const builtSegment = mutationResult.data?.createOrUpdateSegment.segment.built
      if (builtSegment) {
        // Let's think about this:
        // - If we just set the referenced segment, the builtSegment will not exist inside the
        //   configuration, and we should replace the referenceSegment ID with the builtSegment.
        // - If we just changed the referenced segment, the builtSegment will not exist inside
        //   the configuration, and we should replace any segment with the same referenceSegment
        //   with the builtSegment.
        //
        // So, with these two in mind, we should first try to find the segment below like we do,
        // and then try findSegment() again with the reference segment ID, and pass the references
        // setting to true. That should take care of it.

        const segmentFragment = proxy.readFragment<SegmentWithHydrationDataFragment>({
          fragment: SegmentWithHydrationDataFragmentDoc,
          fragmentName: 'SegmentWithHydrationData',
          id: `Segment:${toGlobalId('Segment', builtSegment.id)}`,
        })
        if (segmentFragment) {
          proxy.writeFragment<SegmentWithHydrationDataFragment>({
            fragment: SegmentWithHydrationDataFragmentDoc,
            fragmentName: 'SegmentWithHydrationData',
            id: `Segment:${toGlobalId('Segment', builtSegment.id)}`,
            data: {
              ...segmentFragment,
              hydrationData: builtSegment,
            },
          })
        }

        const fragment = proxy.readFragment<CourseVersionFragment>({
          fragment: CourseVersionFragmentDoc,
          fragmentName: 'CourseVersion',
          id: `CourseVersion:${courseVersionId}`,
        })
        if (fragment) {
          const configuration = fragment.configuration
          let newConfiguration = fragment.configuration
          if (builtSegment.id === fragment.rootSegmentId) {
            newConfiguration = {
              ...configuration,
              segments: builtSegment.childSegments,
            }
          } else {
            let segment = findSegment(configuration.segments, builtSegment.id)
            if (!segment && builtSegment.referenceSegment && builtSegment.referenceSegment.id) {
              // See message above for why this block is here.
              segment = findSegment(configuration.segments, builtSegment.referenceSegment.id, true)
            }
            if (segment) {
              newConfiguration = generateUpdatedSegment(configuration, builtSegment)
            }
          }
          proxy.writeFragment<CourseVersionFragment>({
            fragment: CourseVersionFragmentDoc,
            fragmentName: 'CourseVersion',
            id: `CourseVersion:${courseVersionId}`,
            data: {
              ...fragment,
              configuration: newConfiguration,
            },
          })
        }
      }
    },
  })
