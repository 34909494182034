import { useApolloClient } from '@apollo/client'
import { updateMutation } from '@thesisedu/feature-apollo-react'
import { useOAuthPopup } from '@thesisedu/web'
import { message } from 'antd'

import { debug } from '../log'
import {
  useAssociateSchoologyMutation,
  UserFragmentDoc,
  BeginSchoologyAuthenticationMutation,
  BeginSchoologyAuthenticationDocument,
  useLoginWithSchoologyMutation,
} from '../schema'
import { UseSchoologyAssociateProps, UseSchoologyAuthProps, UseSchoologyLoginProps } from '../types'

export const useSchoologyLogin = (props: UseSchoologyLoginProps) => {
  const [loginWithSchoology, { loading: loggingIn }] = useLoginWithSchoologyMutation({
    onError: props.onError,
    onCompleted: data => {
      debug('sign in complete, calling onSuccess')
      props.onSuccess(data.loginWithSchoology)
    },
  })

  return {
    ...useSchoologyAuth({
      ...props,
      onSuccess: (token, tokenSecret) => {
        loginWithSchoology({
          variables: {
            input: {
              token: {
                token,
                tokenSecret,
              },
            },
          },
        })
      },
    }),
    loggingIn,
  }
}

export function useSchoologyAssociate(props: UseSchoologyAssociateProps) {
  const [associate, { loading: associating }] = useAssociateSchoologyMutation({
    onError: props.onError,
    update: updateMutation({
      fragment: UserFragmentDoc,
      fragmentName: 'User',
      path: 'assocateSchoology.user',
    }),
    onCompleted: () => {
      debug('associate complete, calling onSuccess')
      props.onSuccess()
    },
  })

  return {
    ...useSchoologyAuth({
      ...props,
      onSuccess: (token, tokenSecret) => {
        associate({
          variables: {
            input: { token: { token, tokenSecret } },
          },
        })
      },
      onCancel: () => props.onError(null),
    }),
    associating,
  }
}

export function useSchoologyAuth({ onCancel, onSuccess, onError }: UseSchoologyAuthProps) {
  const redirectUri = `${window.location.origin}/auth/schoology-redirect`
  const client = useApolloClient()
  const signIn = useOAuthPopup({
    title: 'Sign in with Schoology',
    width: 450,
    height: 600,
    authorizeUrl: 'https://app.schoology.com/oauth/authorize',
    redirectUri: '',
    clientId: '',
    scopes: [],
    onClose: () => {
      if (onCancel) {
        onCancel()
      }
    },
  }).onContainerClick
  return {
    signIn: async () => {
      try {
        const requestTokens = (
          await client.mutate<BeginSchoologyAuthenticationMutation>({
            mutation: BeginSchoologyAuthenticationDocument,
            variables: {},
          })
        )?.data?.beginSchoologyAuthentication
        if (requestTokens) {
          signIn({
            params: {
              oauth_token: requestTokens.token,
              return_url: redirectUri,
            },
            onCode: (code, params) => {
              const token = params.get('oauth_token')
              if (token && requestTokens.tokenSecret) {
                onSuccess(token, requestTokens.tokenSecret)
              }
            },
          })
        } else {
          message.error('Uh oh! Something went wrong trying to fetch your credentials')
          onError(null)
        }
      } catch (err: any) {
        message.error('Uh oh! Something went wrong trying to fetch your credentials')
        onError(err)
      }
    },
  }
}
