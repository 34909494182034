import { useFeature } from '@thesisedu/feature-react'
import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import LicensesReactFeature from './LicensesReactFeature'
import { LicenseType } from './schema'

export const TypeSelect = React.forwardRef<any, SelectProps<string>>((props, ref) => {
  const { options } = useFeature(LicensesReactFeature)
  return (
    <Select<string> placeholder={'Select a type'} {...props} ref={ref}>
      <Select.Option value={LicenseType.Site}>Site</Select.Option>
      <Select.Option value={LicenseType.Trial}>Trial</Select.Option>
      {options.showStudentPay ? (
        <Select.Option value={LicenseType.StudentPay}>Student Pay</Select.Option>
      ) : null}
    </Select>
  )
})
