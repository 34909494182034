import { useViewerContext } from '@thesisedu/feature-users-web'
import { Body, Block } from '@thesisedu/web'
import React from 'react'

import { BasicLessonFragment } from './schema'
import { LessonAttachmentField } from './teacher/LessonAttachmentField'

export interface LessonSummaryProps {
  lesson: BasicLessonFragment
}
export const LessonSummary: React.FC<React.PropsWithChildren<LessonSummaryProps>> = ({
  lesson,
  children,
}) => {
  const viewer = useViewerContext(false)
  return (
    <>
      {lesson.tags.edges.length > 0 ? (
        <Block marginBottom={'@size-m'}>
          <Body>Tagged with:</Body>
          <ul className={'typo'}>
            {lesson.tags.edges.map(edge => (
              <li key={edge.node.id}>{edge.node.name}</li>
            ))}
          </ul>
        </Block>
      ) : null}
      <LessonAttachmentField
        id={lesson.id}
        fragment={lesson.attachment}
        readOnly={viewer?.group !== 'TEACHER' || !!lesson.sharing.sharedFrom}
      />
      {children}
    </>
  )
}
