import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { useErrorService } from '@thesisedu/feature-react'
import { isEqual } from '@thesisedu/feature-utils'
import { EditorValue, isEmpty } from '@thesisedu/feature-widgets-core'
import { Text } from '@thesisedu/ui'
import React from 'react'

import {
  EditorPlugins,
  EditorPluginsProps,
  EditorPluginsProvider,
  PluginLevel,
} from './EditorPlugins'
import { EditorProvider } from './context/EditorContext'
import { NodeStyles, useEditorNodes } from './nodes'
import { AddParagraphOnemptyClickPlugin } from './plugins/AddParagraphOnEmptyClickPlugin'
import { CallbacksPlugin } from './plugins/CallbacksPlugin'
import { DefaultValuePlugin } from './plugins/DefaultValuePlugin'
import { theme, ThemeContainer } from './ui/theme'
import { useOnChange } from './utils/useOnChange'

export interface EditorProps {
  /**
   * The initial value of the editor. This is updated inside the editor every time this prop
   * changes. Because of this, TAKE EXTREME CARE to make sure this prop value does not
   * frequently change.
   *
   * Also take note that this WILL call onChange whenever this is updated.
   */
  defaultValue?: EditorValue
  /**
   * When you change the defaultValueKey, the defaultValue will be loaded into the editor
   * state. This gives you some more control over when exactly the defaultValue will be
   * re-loaded into the editor.
   */
  defaultValueKey?: string
  /**
   * This text will be displayed when the editor is empty and in editable mode.
   */
  placeholder?: EditorPluginsProps['placeholder']
  /**
   * This text will be displayed when the editor is empty and in read-only mode.
   */
  readOnlyPlaceholder?: React.ReactElement | string
  /**
   * Called whenever the editor changes. This is called after every single
   * edit, so is called pretty frequently. Avoid doing complex interactions
   * in this callback or updating a bunch of state, as that will slow the
   * application down.
   */
  onChange?: (state: EditorValue) => void
  /**
   * If you only want to call onChange after a debounce period, specify that
   * period here. This is useful if you want to avoid calling onChange too
   * frequently, as it causes a lot of re-renders.
   */
  onChangeDebounce?: number
  /**
   * Called whenever the editor is focused.
   */
  onFocus?: () => void
  /**
   * Called whenever the editor loses focus.
   */
  onBlur?: () => void
  /**
   * Set this to true if you don't want users to be able to edit anything;
   * only view.
   */
  readOnly?: boolean
  /**
   * Disables interaction with the editor.
   */
  disabled?: boolean
  /**
   * Set this to true if you don't want users creating new blocks, but they
   * can edit existing ones.
   */
  noAdd?: boolean
  /**
   * If this is true, all dynamic widgets will be hidden. This is useful
   * for PDF generation or rendering widgets as images.
   */
  noDynamicWidgets?: boolean
  /**
   * A unique ID for the editor; to separate it from other editors on the page.
   */
  id: string
  /**
   * The level of plugins to include with this editor. Defaults to all plugins
   * available, which is reserved mostly for administrators.
   */
  level?: PluginLevel
  /**
   * The minimum height of the editor, when in editable mode. Defaults to
   * 500px.
   */
  minHeight?: number
  /**
   * Render a border around the editor to make it more obvious it's a field.
   */
  bordered?: boolean
  /**
   * If this is true, the toolbar will be shown and will stick to the top of
   * the page. If this is enabled, the floating text format toolbar will be
   * disabled.
   */
  showToolbar?: boolean
  /**
   * Any additional plugins to install inside the editor.
   */
  children?: React.ReactNode
}
function _Editor({
  id,
  defaultValue,
  defaultValueKey,
  noDynamicWidgets,
  placeholder,
  readOnlyPlaceholder,
  onChange: _onChange,
  onFocus,
  onBlur,
  onChangeDebounce,
  noAdd,
  readOnly,
  disabled,
  level,
  minHeight,
  bordered,
  showToolbar,
  children,
}: EditorProps) {
  const errorService = useErrorService()
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const nodes = useEditorNodes()
  const onChange = useOnChange({ onChange: _onChange, onChangeDebounce, defaultValue })
  const toolbarHostRef = React.useRef<HTMLDivElement>(null)
  const empty = readOnly && isEmpty(defaultValue)
  if (empty && readOnlyPlaceholder) {
    return (
      <Text level={'l'} color={'secondary'}>
        {readOnlyPlaceholder}
      </Text>
    )
  } else {
    return (
      <>
        {showToolbar ? <div style={{ display: 'unset' }} ref={toolbarHostRef} /> : null}
        <ThemeContainer
          ref={anchorRef}
          $isEditable={!readOnly}
          $isDisabled={!!disabled}
          $minHeight={minHeight}
          $bordered={bordered}
        >
          <EditorProvider
            readOnly={readOnly || disabled}
            noAdd={noAdd}
            anchorRef={anchorRef}
            onChange={onChange}
            noDynamicWidgets={noDynamicWidgets}
          >
            <LexicalComposer
              key={readOnly ? 'read-only-editor' : 'editable-editor'}
              initialConfig={{
                namespace: id,
                theme,
                nodes,
                editable: !readOnly,
                onError(err) {
                  console.error(err)
                  errorService.reportError(err, {
                    editorNamespace: id,
                    readOnly,
                  })
                },
              }}
            >
              <EditorPluginsProvider>
                <>
                  <NodeStyles />
                  <EditorPlugins
                    placeholder={readOnly ? false : placeholder}
                    level={level}
                    toolbarHost={showToolbar ? toolbarHostRef : undefined}
                  />
                  <DefaultValuePlugin
                    defaultValue={defaultValue}
                    defaultValueKey={defaultValueKey}
                  />
                  {readOnly ? null : (
                    <OnChangePlugin
                      onChange={(editorState, editor) => {
                        editorState.read(() => {
                          if (onChange) {
                            const state = editorState.toJSON()
                            const rootElement = editor.getRootElement()
                            if (rootElement) {
                              if (isEmpty(state)) rootElement.classList.add('empty')
                              else rootElement.classList.remove('empty')
                            }
                            onChange(state)
                          }
                        })
                      }}
                    />
                  )}
                  {readOnly || disabled ? null : (
                    <>
                      <CallbacksPlugin onFocus={onFocus} onBlur={onBlur} />
                      {anchorRef.current ? (
                        <AddParagraphOnemptyClickPlugin anchorElement={anchorRef.current} />
                      ) : null}
                    </>
                  )}
                  {children}
                </>
              </EditorPluginsProvider>
            </LexicalComposer>
          </EditorProvider>
        </ThemeContainer>
      </>
    )
  }
}
export const Editor = React.memo(_Editor, (prev, next) => {
  return (
    prev.id === next.id &&
    prev.readOnly === next.readOnly &&
    prev.noAdd === next.noAdd &&
    prev.noDynamicWidgets === next.noDynamicWidgets &&
    prev.placeholder === next.placeholder &&
    prev.readOnlyPlaceholder === next.readOnlyPlaceholder &&
    prev.onChange === next.onChange &&
    isEqual(prev.defaultValue, next.defaultValue) &&
    prev.defaultValueKey === next.defaultValueKey
  )
})
