import { reduceSegments } from '@thesisedu/feature-course-types'
import { FilterButton, FilterButtonProps, FilterMenuItem } from '@thesisedu/react'
import React from 'react'

import { useClassCourseQuery } from '../schema'

interface FilterIGCommonProps {
  classId: string
  value?: string
  onChange: (ig: string | undefined) => void
  segmentType?: string
}
function useFilterIG({ classId, value, onChange, segmentType }: FilterIGCommonProps) {
  const { loading, data } = useClassCourseQuery({ variables: { id: classId } })
  const segments =
    data?.node?.__typename === 'Class' ? data.node.bakedCourseConfiguration?.segments : undefined
  if (!segments || !segments.length) return null
  const igs: string[] = Array.from(
    reduceSegments<Set<string>>(
      segments,
      (acc, segment) => {
        if (segmentType && segment.type !== segmentType) return acc
        if (segment.visibleOverride && segment.tags?.IMPLEMENTATION_GUIDE) {
          segment.tags.IMPLEMENTATION_GUIDE.forEach((ig: string) => acc.add(ig))
        }
        return acc
      },
      new Set(),
    ),
  )

  return {
    label: 'Lesson',
    value,
    disabled: loading || !igs.length,
    onChange,
    options: igs.map(ig => ({ label: ig, value: ig })),
  }
}

export interface FilterIGProps
  extends Omit<Partial<FilterButtonProps<string>>, 'options' | 'value' | 'onChange'>,
    FilterIGCommonProps {}

export function FilterIG({
  classId,
  value,
  onChange,
  segmentType,
  ...filterButtonProps
}: FilterIGProps) {
  const props = useFilterIG({ classId, value, onChange, segmentType })
  return props ? <FilterButton<string> {...props} {...filterButtonProps} /> : null
}

export interface FilterIGMenuProps extends FilterIGCommonProps {
  [key: string]: any
}
export function FilterIGMenu({
  classId,
  value,
  onChange,
  segmentType,
  ...rest
}: FilterIGMenuProps) {
  const props = useFilterIG({ classId, value, onChange, segmentType })
  return props ? <FilterMenuItem<string> {...props} {...rest} /> : null
}
