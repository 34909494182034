import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  AssignLessonMutation,
  LessonFragment,
  LessonFragmentDoc,
  useAssignLessonMutation as useMutation,
} from '../schema'

export const useAssignLessonMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error assigning that lesson.'),
    update: updateMutation<AssignLessonMutation, LessonFragment>({
      fragment: LessonFragmentDoc,
      fragmentName: 'Lesson',
      path: 'assignLesson.lesson',
    }),
    ...opts,
  })
