import { Tree as AntTree, TreeProps as AntTreeProps } from 'antd'
import { NavArrowRight } from 'iconoir-react'
import React from 'react'

import { styled } from './styledTypes'

export interface TreeProps extends AntTreeProps {
  compact?: boolean
}
export function Tree({ compact, ...rest }: TreeProps) {
  return (
    <Container compact={compact}>
      <AntTree switcherIcon={<NavArrowRight />} {...rest} />
    </Container>
  )
}

const Container = styled.div<{ compact?: boolean }>`
  ${props => (props.compact ? '&' : '&.noop')} {
    .ant-tree-indent-unit {
      width: 8px;
    }
  }
  .ant-tree-treenode {
    padding: 0 0 ${props => props.theme['@size-xs']} 0;
  }
  .ant-tree-switcher {
    transition:
      color 0.1s linear,
      transform 0.25s cubic-bezier(0.16, 1, 0.3, 1);
    transform: rotate(0deg);
    &.ant-tree-switcher_open {
      transform: rotate(90deg);
    }
    &.ant-tree-switcher-noop {
      width: 8px;
    }
  }
`
