import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { useMutateHook } from '@thesisedu/feature-react'

import {
  BasicAssignmentSubmissionFragmentDoc,
  useSubmitAssignmentMutation as useMutation,
} from '../schema'
import { SubmitAssignmentRefetchQueriesHook } from '../types'

export const useSubmitAssignmentMutation: typeof useMutation = opts => {
  const refetchQueries = useMutateHook<SubmitAssignmentRefetchQueriesHook>(
    'feature-assignments-react:submit-assignment-refetch-queries',
    [
      'classAssignmentCategoriesWithGrades',
      'assignmentSubmissionConfiguration',
      ...((opts?.refetchQueries || []) as any),
    ],
    undefined,
  )
  return useMutation({
    onError: onMutationError('There was an error submitting your assignment.'),
    ...opts,
    refetchQueries,
    update: (...args) => {
      updateMutation({
        fragment: BasicAssignmentSubmissionFragmentDoc,
        fragmentName: 'BasicAssignmentSubmission',
        path: 'submitAssignment.assignmentSubmission',
      })(...args)
      if (opts?.update) {
        opts.update(...args)
      }
    },
  })
}
