import React, { useContext } from 'react'

export interface SegmentListContextValue {
  createdSegmentId?: string
  acknowledgeCreatedSegmentId: () => void
}
export const SegmentListContext = React.createContext<SegmentListContextValue | undefined>(
  undefined,
)

export interface SegmentListContextProviderProps {
  onSegmentAcknowledged: () => void
  createdSegmentId?: string
}
export function SegmentListContextProvider({
  onSegmentAcknowledged,
  createdSegmentId,
  children,
}: React.PropsWithChildren<SegmentListContextProviderProps>) {
  return (
    <SegmentListContext.Provider
      value={{
        createdSegmentId,
        acknowledgeCreatedSegmentId: onSegmentAcknowledged,
      }}
      children={children}
    />
  )
}

export function useSegmentListContext(): SegmentListContextValue | undefined
export function useSegmentListContext(require: false): SegmentListContextValue | undefined
export function useSegmentListContext(require: true): SegmentListContextValue
export function useSegmentListContext(require?: boolean): SegmentListContextValue | undefined {
  const context = useContext(SegmentListContext)
  if (!context && require) {
    throw new Error('SegmentListContext is required, yet not provided.')
  }
  return context
}
