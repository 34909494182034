import { Button, Popover, SegmentedControl, Tooltip } from '@thesisedu/ui'
import { Table2Columns, GraphUp, Settings } from '@thesisedu/ui/icons'
import React from 'react'

import { ActionsContainer } from './ActionsContext'
import { ReportResultsProps } from './ReportResults'
import { useReportGridColorContext } from './grid/colors/ColorContext'
import { ConfigureCellColors } from './grid/colors/ConfigureCellColors'

export enum ReportDisplayMode {
  Grid,
  Graph,
}

export interface ReportActionsProps extends Pick<ReportResultsProps, 'report' | 'onReportChange'> {
  children?: React.ReactElement
}
export function ReportActions({ children, report, onReportChange }: ReportActionsProps) {
  const displayMode = report.displayMode || ReportDisplayMode.Grid
  const setDisplayMode = (displayMode: ReportDisplayMode) => {
    onReportChange(
      {
        ...report,
        displayMode,
      },
      true,
    )
  }
  const { opts, setOpts } = useReportGridColorContext(true)
  return (
    <ActionsContainer>
      {displayMode === ReportDisplayMode.Grid ? (
        <Popover.Container>
          <Popover.Trigger>
            <Tooltip title={'Report Settings'}>
              <Button icon={<Settings />} />
            </Tooltip>
          </Popover.Trigger>
          <Popover.Content side={'bottom'} align={'end'} style={{ width: 300 }}>
            <ConfigureCellColors value={opts} onChange={setOpts} />
          </Popover.Content>
        </Popover.Container>
      ) : null}
      <SegmentedControl>
        <Tooltip title={'Table View'}>
          <SegmentedControl.Item
            icon={<Table2Columns />}
            selected={displayMode === ReportDisplayMode.Grid}
            onPress={() => setDisplayMode(ReportDisplayMode.Grid)}
          />
        </Tooltip>
        <Tooltip title={'Graph View'}>
          <SegmentedControl.Item
            icon={<GraphUp />}
            selected={displayMode === ReportDisplayMode.Graph}
            onPress={() => setDisplayMode(ReportDisplayMode.Graph)}
          />
        </Tooltip>
      </SegmentedControl>
      {children}
    </ActionsContainer>
  )
}
