import { s } from '@thesisedu/ui'
import React from 'react'

import { MicSpeaking } from '../../icons'
import { isNative } from '../isNative'
import { styled, styledContainer } from '../styled'

export interface AudioOverlayProps {
  isCompact?: boolean
}
export function AudioOverlay({ isCompact }: AudioOverlayProps) {
  return (
    <Container>
      {isCompact ? null : (
        <StyledMic
          width={s.getThemeValue('size.8')}
          height={s.getThemeValue('size.8')}
          color={s.getThemeValue('color.light.gray.3')}
        />
      )}
    </Container>
  )
}

const StyledMic = styled(MicSpeaking)`
  ${isNative
    ? ''
    : `
    max-width: 10vw;
    max-height: 10vw;
  `}
  margin-top: calc(-1 * ${s.size('l')});
`
const Container = styledContainer`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${s.color('element')};
`
