import { keyframes } from 'styled-components'

export const appearSlideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(110%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const appearSlideRightAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

export const appearSlideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-110%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const appearSlideLeftAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(110%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`
