import Bowser from 'bowser'

export function isValidBrowserVersion(): boolean {
  if (typeof window === 'undefined') return false
  const parser = Bowser.getParser(window.navigator.userAgent)
  return !!parser.satisfies({
    chrome: '>=112', // 5 most recent versions
    chromium: '>=112', // 5 most recent versions
    edge: '>=112', // 5 most recent versions
    safari: '>=16', // 2 most recent versions
    firefox: '>=112', // 5 most recent versions
  })
}
