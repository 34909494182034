import { UserLeftItemsHook, UserRoutesHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { Home } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { SitesRoute } from './SitesRoute'
import { SitesWebFeature } from '../../SitesWebFeature'

export default function (feature: SitesWebFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, context) => {
      if (context.group !== 'ADMIN') return routes
      return [<Route key={'sites'} path={'sites/*'} element={<SitesRoute />} />, ...routes]
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, context) => {
      if (context.group !== 'ADMIN') return items
      return [
        ...items,
        {
          weight: 0.5,
          group: 'top',
          element: (
            <Shell.LeftMenuItem
              key={'sites'}
              icon={<Home />}
              name={'Sites'}
              path={'/admin/sites'}
            />
          ),
        },
      ]
    },
  )
}
