import { s } from '@thesisedu/ui'
import React from 'react'

import { ClickableContainer } from '../dom/ClickableContainer'
import { isNative } from '../isNative'
import { styled } from '../styled'

export interface ControlContainerProps {
  children: React.ReactElement
  compact: boolean
  onClick?: () => void
  style?: any
  padded?: boolean
}
function _ControlContainer(
  { children, compact, onClick, style, padded, ...props }: ControlContainerProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const iconSize = compact ? s.size('s') : `calc(${s.size('s')} + ${s.size('xxs')})`
  return (
    <__ControlContainer
      compact={compact}
      padded={padded}
      onClick={onClick}
      style={style}
      {...props}
      ref={ref}
    >
      {React.cloneElement(children, {
        color: 'white',
        style: {
          width: iconSize,
          height: iconSize,
          display: isNative ? undefined : 'block',
        },
      })}
    </__ControlContainer>
  )
}
export const ControlContainer = React.forwardRef(_ControlContainer)

const __ControlContainer = styled(ClickableContainer)<{ compact: boolean; padded?: boolean }>`
  opacity: ${isNative ? 1 : 0.75};
  transition: opacity 0.1s linear;
  color: white;
  cursor: pointer;
  padding: ${props => (props.padded ? `0px ${s.size(props.compact ? 'xxs' : 's')}` : null)};
`
