import React from 'react'
import {
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { styled } from '../styled'
import { VSpaced } from '../styled/Block'
import { Body, BodySmall } from '../typography'

export type FormItemProps<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, FieldName>, 'rules'> &
  UseControllerProps<TFieldValues, FieldName>['rules'] & {
    name: FieldName
    label?: string | React.ReactElement
    children: React.ReactElement<
      ControllerRenderProps<TFieldValues, FieldName> & Record<string, any>
    >
    initialValue?: UseControllerProps<TFieldValues, FieldName>['defaultValue']
    disabled?: boolean
    valuePropName?: string
    extra?: string | React.ReactElement
    style?: any
  }
/** @deprecated */
export function FormItem<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>({
  name,
  label,
  children,
  defaultValue,
  disabled,
  initialValue,
  style,
  extra,
  valuePropName,
  ...rules
}: FormItemProps<TFieldValues, FieldName>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: initialValue ? initialValue : defaultValue,
    disabled,
    rules: {
      ...rules,
      required: rules?.required === true ? 'This field is required.' : rules?.required,
    },
  })
  const fieldError = error?.message
  const fieldOnChangeRef = React.useRef(field.onChange)
  fieldOnChangeRef.current = field.onChange
  const cloned = React.useMemo(
    () =>
      React.cloneElement(children, {
        ...field,
        ...(valuePropName ? { [valuePropName]: field.value } : {}),
        onChangeText: (value?: string) => fieldOnChangeRef.current(value),
        'data-input': field.name,
        disabled: field.disabled || children.props.disabled,
      }),
    [children, field.value, field.name],
  )
  if (label || fieldError || extra) {
    return (
      <Container
        align={'stretch'}
        space={'@size-xs'}
        style={style}
        data-field={field.name}
        data-testid={`FormItem ${field.name}`}
      >
        {label ? (
          <Body style={{ alignSelf: 'flex-start' }} data-testid={'label'}>
            {label}
          </Body>
        ) : null}
        {cloned}
        {fieldError ? (
          <BodySmall style={{ alignSelf: 'flex-start' }} color={'@red'} data-testid={'error'}>
            {fieldError}
          </BodySmall>
        ) : null}
        {extra ? (
          <BodySmall color={'@text-color-secondary'} useDiv data-testid={'extra'}>
            {extra}
          </BodySmall>
        ) : null}
      </Container>
    )
  } else return cloned
}

const Container = styled(VSpaced)`
  > button {
    align-self: flex-start;
  }
`
