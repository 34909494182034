import { GoogleOAuthProvider } from '@react-oauth/google'
import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { AuthGoogleReactFeature } from '../AuthGoogleReactFeature'

export default function (feature: AuthGoogleReactFeature) {
  feature.hookManager.registerMutateHook<React.ReactElement, undefined>(
    ReactHooks.WrapApp,
    children => {
      return <GoogleOAuthProvider clientId={feature.options.clientId} children={children} />
    },
  )
}
