import { getFileInfo } from '@thesisedu/feature-attachments-react'
import {
  BodyLarge,
  BodySmall,
  H1,
  LargeBlock,
  Markdown,
  styled,
  getFlag,
  setFlag,
} from '@thesisedu/web'
import React from 'react'

import { getCampaignDismissedFlag } from './helpers'
// @ts-ignore asset imports
import LeftImage from '../../assets/confetti-left.svg'

export interface CampaignBannerProps {
  title?: string | null
  message?: string | null
  background?: string | null
  campaign: string
  isPreview?: boolean
}
export function CampaignBanner({
  title,
  message,
  background,
  campaign,
  isPreview,
}: CampaignBannerProps) {
  const { filename } = getFileInfo(background || undefined)
  const dismissedFlag = getCampaignDismissedFlag(campaign)
  const [dismissed, setDismissed] = React.useState(isPreview ? false : !!getFlag(dismissedFlag))
  if (dismissed) return null
  return (
    <Container>
      <LeftConfettiImage src={LeftImage} />
      {filename ? <RightImage src={filename} /> : null}
      {title || message ? (
        <div style={filename ? { width: '60%' } : undefined}>
          {title ? <H1 children={title} /> : null}
          {message ? (
            <BodyLarge useDiv isBlock>
              <Markdown children={message} />
            </BodyLarge>
          ) : null}
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault()
              if (!isPreview) {
                setFlag(dismissedFlag, true)
                setDismissed(true)
              }
            }}
          >
            <BodySmall color={'@text-color-secondary'}>Hide this message.</BodySmall>
          </a>
        </div>
      ) : null}
    </Container>
  )
}

const Container = styled(LargeBlock)`
  position: relative;
  overflow: hidden;
  padding: ${props => props.theme['@size-xl']} ${props => props.theme['@size-l']};
  display: flex;
`
const RightImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: 100%;
  pointer-events: none;
`
const LeftConfettiImage = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: auto;
  pointer-events: none;
  height: 100%;
`
