import { Tooltip as WebTooltip, TooltipProps as WebTooltipProps } from '@thesisedu/web'
import React from 'react'

import { styled } from '../styled'
import { ZIndex } from '../styled/ZIndex'
import { color, size } from '../theme'

export interface TooltipProps extends WebTooltipProps {
  css?: boolean
  children: React.ReactElement
}
export function Tooltip({ css, ...rest }: TooltipProps) {
  if (css) {
    return <CSSTooltip {...rest} />
  } else {
    return <WebTooltip {...rest} />
  }
}

function CSSTooltip({ title, placement = 'top', children, mouseEnterDelay = 0.5 }: TooltipProps) {
  if (typeof title !== 'string') throw new Error('CSSTooltip only supports string titles')
  return (
    <StyledCSSTooltip
      aria-label={title}
      data-tooltip-placement={placement}
      children={children}
      delayShow={mouseEnterDelay}
    />
  )
}

const StyledCSSTooltip = styled.div<{ delayShow: number }>`
  &[aria-label][data-tooltip-placement],
  &[aria-label][class*='tooltip'] {
    position: relative;
    &::after {
      background-color: ${color('gray-7')};
      border-radius: ${size('@border-radius-base')};
      color: ${color('gray-0')};
      content: attr(aria-label);
      font-size: ${size('@font-size-sm')};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      padding: 0.5em 1em;
      text-indent: 0;
      text-shadow: none;
      max-width: 300px;
      width: max-content;
      z-index: ${ZIndex.Overlays};
    }
    &::before {
      content: '';
      z-index: ${ZIndex.Overlays - 1};
      border: 0.3125rem solid transparent;
      height: 0;
      width: 0;
    }
    &::after,
    &::before {
      transition: all 120ms ease-out 120ms;
      box-sizing: border-box;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      transition-delay: var(--tooltip-delay-hide, 0s);
      @media (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }
    &:hover::before,
    &:hover::after,
    &:focus::before,
    &:focus::after {
      opacity: 1;
      transition-delay: ${props => props.delayShow}s;
    }
    &[data-tooltip-placement='top'] {
      &::before {
        border-top-color: ${color('gray-7')};
      }
      &::after {
        margin-bottom: calc(0.3125rem * 2);
      }
      &::after,
      &::before {
        bottom: calc(100% - 0.3125rem / 2);
        left: 50%;
        transform: translate(-50%, 6px);
        transform-origin: top;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(-50%, 0);
      }
    }
    &[data-tooltip-placement='topLeft'] {
      &::before {
        border-top-color: ${color('gray-7')};
      }
      &::after {
        left: 0;
        margin-bottom: calc(0.3125rem * 2);
      }
      &::before {
        left: calc(0.3125rem / 1);
      }
      &::after,
      &::before {
        bottom: calc(100% - 0.3125rem / 2);
        transform: translate(0, 6px);
        transform-origin: top;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0);
      }
    }
    &[data-tooltip-placement='topRight'] {
      &::before {
        border-top-color: ${color('gray-7')};
      }
      &::after {
        right: 0;
        margin-bottom: calc(0.3125rem * 2);
      }
      &::before {
        right: calc(0.3125rem / 1);
      }
      &::after,
      &::before {
        bottom: calc(100% - 0.3125rem / 2);
        transform: translate(0, 6px);
        transform-origin: top;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0);
      }
    }
    &[data-tooltip-placement='bottom'] {
      &::before {
        border-bottom-color: ${color('gray-7')};
      }
      &::after {
        margin-top: calc(0.3125rem * 2);
      }
      &::after,
      &::before {
        left: 50%;
        top: calc(100% - 0.3125rem / 2);
        transform: translate(-50%, -6px);
        transform-origin: bottom;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(-50%, 0);
      }
    }
    &[data-tooltip-placement='bottomLeft'] {
      &::before {
        border-bottom-color: ${color('gray-7')};
      }
      &::after {
        left: 0;
        margin-top: calc(0.3125rem * 2);
      }
      &::before {
        left: calc(0.3125rem / 1);
      }
      &::after,
      &::before {
        top: calc(100% - 0.3125rem / 2);
        transform: translate(0, -6px);
        transform-origin: bottom;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0);
      }
    }
    &[data-tooltip-placement='bottomRight'] {
      &::before {
        border-bottom-color: ${color('gray-7')};
      }
      &::after {
        right: 0;
        margin-top: calc(0.3125rem * 2);
      }
      &::before {
        right: calc(0.3125rem / 1);
      }
      &::after,
      &::before {
        top: calc(100% - 0.3125rem / 2);
        transform: translate(0, -6px);
        transform-origin: bottom;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0);
      }
    }
    &[data-tooltip-placement='left'] {
      &::before {
        border-left-color: ${color('gray-7')};
      }
      &::after {
        margin-right: calc(0.3125rem * 2);
      }
      &::after,
      &::before {
        top: 50%;
        right: calc(100% - 0.3125rem / 2);
        transform: translate(6px, -50%);
        transform-origin: left;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0, -50%);
      }
    }
    &[data-tooltip-placement='right'] {
      &::before {
        border-right-color: ${color('gray-7')};
      }
      &::after {
        margin-left: calc(0.3125rem * 2);
      }
      &::after,
      &::before {
        top: 50%;
        left: calc(100% - 0.3125rem / 2);
        transform: translate(-6px, -50%);
        transform-origin: right;
      }
      &:hover::after,
      &:hover::before,
      &:focus::after,
      &:focus::before {
        transform: translate(0, -50%);
      }
    }
  }
`
