export * from './WidgetTemplatesReactFeature'
export { WidgetTemplatesRoute } from './manage/WidgetTemplatesRoute'
export { WidgetTemplateSelect, WidgetTemplateSelectProps } from './manage/WidgetTemplateSelect'
export {
  WidgetTemplateDocument,
  WidgetTemplateQuery,
  WidgetTemplateQueryVariables,
  BasicWidgetTemplateFragment,
  WidgetTemplateFragment,
} from './schema'

export { WidgetTemplatesReactFeature as default } from './WidgetTemplatesReactFeature'
