import { s } from '@thesisedu/ui'
import { styled } from '@thesisedu/web/dist/styledTypes'

export const styledContainer = styled.div

export { styled } from '@thesisedu/web/dist/styledTypes'
export const css = s.css

export function ifs(t: any) {
  return t ? '&' : '&.noop'
}
