import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { SegmentLocked } from './SegmentLocked'
import { SegmentNotInLicensePage } from './SegmentNotInLicensePage'
import { Segment } from '../types'

export interface SegmentGatesProps {
  segment: Segment
}
export function SegmentGates({ children, segment }: React.PropsWithChildren<SegmentGatesProps>) {
  const viewer = useViewerContext(false)
  let content = <SegmentNotInLicenseGate segment={segment}>{children}</SegmentNotInLicenseGate>
  if (viewer?.group !== 'TEACHER') {
    content = <SegmentLockedGate segment={segment} children={content} />
  }

  return content
}

export function SegmentNotInLicenseGate({
  children,
  segment,
}: React.PropsWithChildren<SegmentGatesProps>) {
  if (segment.notInLicense) {
    return <SegmentNotInLicensePage />
  } else return <>{children}</>
}

export function SegmentLockedGate({
  children,
  segment,
}: React.PropsWithChildren<SegmentGatesProps>) {
  if (segment.isLocked) {
    return <SegmentLocked />
  } else return <>{children}</>
}
