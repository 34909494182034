import React from 'react'

export interface RecordFooterItem {
  identifier: string
  weight: number
  children: React.ReactElement
  showBeforeRecording?: boolean
}

export interface RecordFooterContextValue {
  items: RecordFooterItem[]
  addItem: (item: RecordFooterItem) => void
  removeItem: (identifier: string) => void
}
export const RecordFooterContext = React.createContext<RecordFooterContextValue | undefined>(
  undefined,
)

export interface RecordFooterContextProviderProps {
  children: React.ReactNode
}
export function RecordFooterContextProvider({ children }: RecordFooterContextProviderProps) {
  const [items, setItems] = React.useState<RecordFooterItem[]>([])
  return (
    <RecordFooterContext.Provider
      value={React.useMemo(
        () => ({
          items,
          addItem(item) {
            setItems(items => {
              let hadExisting = false
              const newItems = items.map(existingItem => {
                if (existingItem.identifier === item.identifier) {
                  hadExisting = true
                  return item
                }
                return existingItem
              })
              return hadExisting ? newItems : [...newItems, item]
            })
          },
          removeItem(identifier) {
            setItems(items => items.filter(item => item.identifier !== identifier))
          },
        }),
        [items, setItems],
      )}
      children={children}
    />
  )
}

export function useRecordFooterContext(): RecordFooterContextValue | undefined
export function useRecordFooterContext(require: false): RecordFooterContextValue | undefined
export function useRecordFooterContext(require: true): RecordFooterContextValue
export function useRecordFooterContext(require?: boolean): RecordFooterContextValue | undefined {
  const context = React.useContext(RecordFooterContext)
  if (!context && require) {
    throw new Error('RecordFooterContext is required, yet not provided.')
  }
  return context
}
