import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled'
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined'
import { styled } from '@thesisedu/web'
import { Tooltip } from 'antd'
import React, { useContext, useEffect } from 'react'

import { UploadManagerStateContext } from './UploadManagerContext'
import { UploadModalContext, UploadModalContextValue } from './UploadModalContext'
import { UploadStatus } from './types'

const Container = styled.div`
  transition: opacity 0.25s linear;
  opacity: 0;
  pointer-events: none;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
  .og {
    color: ${props => props.theme['@orange']} !important;
  }
`

export function UploadMiniStatus() {
  const managerState = useContext(UploadManagerStateContext)
  const modalContext = useContext<UploadModalContextValue | null>(UploadModalContext)

  let content: React.ReactElement | null = null
  let tooltipTitle: string | null = null
  const inProgressUploads = managerState?.uploads.filter(
    upl =>
      upl.currentStatus !== UploadStatus.Complete &&
      upl.currentStatus !== UploadStatus.Processing &&
      upl.currentStatus !== UploadStatus.Failed,
  )
  const processingUploads = managerState?.uploads.filter(
    upl => upl.currentStatus === UploadStatus.Processing,
  )
  const failedUploads = managerState?.uploads.filter(
    upl => upl.currentStatus === UploadStatus.Failed,
  )

  useEffect(() => {
    if (inProgressUploads?.length || failedUploads?.length) {
      const listener = (e: WindowEventMap['beforeunload']) => {
        e.preventDefault()
        e.returnValue =
          'You have in-progress uploads. If you close the page, you will potentially lose ' +
          'your recordings. Are you sure you want to do this?'
      }
      window.addEventListener('beforeunload', listener)
      return () => {
        window.removeEventListener('beforeunload', listener)
      }
    }
  }, [inProgressUploads?.length || failedUploads?.length])

  if (inProgressUploads?.length) {
    content = <LoadingOutlined style={{ fontSize: 24 }} />
    tooltipTitle = `Uploading: ${inProgressUploads.length} to go`
  } else if (processingUploads?.length) {
    content = <LoadingOutlined style={{ fontSize: 24 }} />
    tooltipTitle = 'Uploads are processing... You may safely close this page.'
  } else if (failedUploads?.length) {
    content = <ExclamationCircleFilled style={{ fontSize: 24 }} className={'og'} />
    tooltipTitle = 'Some uploads have failed'
  }

  return (
    <Container className={content && tooltipTitle ? 'visible' : ''}>
      {content && tooltipTitle ? (
        <Tooltip title={tooltipTitle} mouseEnterDelay={1}>
          <a
            onClick={e => {
              e.preventDefault()
              if (modalContext) {
                modalContext.showUploads()
              }
            }}
          >
            {content}
          </a>
        </Tooltip>
      ) : null}
    </Container>
  )
}
