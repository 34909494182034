import { Option } from '@thesisedu/feature-assignments-core'
import { styled } from '@thesisedu/web'
import classnames from 'classnames'
import React from 'react'

import {
  MultipleChoiceLetter,
  MultipleChoiceLetterMode,
  MultipleChoiceLetterProps,
} from './MultipleChoiceLetter'
import { ChoiceEditorContainer } from './styled'
import { QuestionTextMediaView } from '../../questions/QuestionTextMediaField'

export interface MultipleChoiceChoiceProps extends MultipleChoiceLetterProps {
  selected?: boolean
  onChange?: (selected: boolean) => void
  option: Option
  rightContent?: React.ReactElement
  leftContent?: React.ReactElement
  containerRef?: React.RefObject<HTMLDivElement>
  onClick?: () => void
  disabled?: boolean
}
export function MultipleChoiceChoice({
  selected,
  onChange,
  option,
  mode = selected ? MultipleChoiceLetterMode.Selected : MultipleChoiceLetterMode.Default,
  children,
  rightContent,
  leftContent,
  onClick,
  disabled,
  isAdd,
  ...props
}: React.PropsWithChildren<MultipleChoiceChoiceProps>) {
  return (
    <Container
      onClick={onClick}
      data-testid={`MultipleChoiceChoice ${option.name}`}
      className={classnames({
        selectable: !disabled && ((!children && onChange) || onClick),
        disabled,
        'is-add': isAdd,
        'mode-selected': props.readOnly && mode === MultipleChoiceLetterMode.Selected,
        'mode-correct': props.readOnly && mode === MultipleChoiceLetterMode.Correct,
        'mode-incorrect': props.readOnly && mode === MultipleChoiceLetterMode.Incorrect,
      })}
    >
      {leftContent}
      <MultipleChoiceLetter
        {...props}
        isAdd={isAdd}
        mode={mode}
        onChange={onClick ? undefined : onChange}
      />
      {children || (
        <div className={'content'}>
          <QuestionTextMediaView value={option.name} />
        </div>
      )}
      {rightContent ? (
        <>
          <div className={'right-content-spacer'} />
          {rightContent}
        </>
      ) : null}
    </Container>
  )
}

const Container = styled(ChoiceEditorContainer)`
  transition:
    background 0.1s linear,
    color 0.1s linear;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.selectable {
    cursor: pointer;
  }
  .content {
    margin-left: ${props => props.theme['@size-m']};
  }
  &.is-add .content {
    color: ${props => props.theme['@text-color-secondary']};
  }
  .right-content-spacer {
    margin-left: auto;
  }
  &.mode-selected,
  &.selectable:hover {
    background: ${props => props.theme['@gray-1']};
  }
  &.mode-correct {
    background: ${props => props.theme['@blue-light']};
    color: ${props => props.theme['@blue']};
  }
  &.mode-incorrect {
    background: ${props => props.theme['@red-light']};
    color: ${props => props.theme['@red']};
  }
`
