import { styled } from '@thesisedu/web'
import { sum } from 'lodash'
import React from 'react'

import { Bar } from './Bar'
import { getMaxMinPitchAndDuration, enforceBeamingLength, getAveragePitch } from './Beam'
import { MeasureMeterBackground } from './MeasureMeterBackground'
import { NoteProps } from './Note'
import { NoteItem, NoteItemProps } from './NoteItem'
import { useNoteItemContext } from './NoteItemContext'
import { StaffContext } from './StaffContext'
import {
  CommonSheetMusicViewerProps,
  HEIGHTS,
  STAFF_COLOR,
  TuneStaff,
  isNoteItem,
  TuneNoteItem,
} from './types'
// @ts-ignore
import treble from '../../assets/treble.svg'

function findLastIndex<T>(
  array: T[],
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

export const MEASURE_MIN_WIDTH = 300

export interface StaffProps extends CommonSheetMusicViewerProps {
  staff: TuneStaff
}
export function Staff({ staff, ...common }: StaffProps) {
  const { noteTopContent } = useNoteItemContext(false) || {}
  const minPitch = staff.voices[0].reduce<number>((min, voice) => {
    if (voice.el_type === 'note' && isNoteItem(voice) && voice.pitches[0].pitch < min) {
      return voice.pitches[0].pitch
    } else return min
  }, Infinity)
  const extraMargin = Math.max(0, (minPitch - 3) * -1 * 12.5) + 10
  let inBeam: NoteProps['inBeam'] | undefined = undefined
  let inInverseBeam = false
  let remainingDuration = common.meter.num / common.meter.den
  const numMeasures = staff.voices[0].filter(v => v.el_type === 'bar').length
  return (
    <StaffContext.Provider value={{ extraMargin }}>
      <OuterStaffContainer
        {...common}
        extraMargin={extraMargin}
        className={'outer-staff-container'}
        topPadding={noteTopContent?.height}
      >
        <StaffContainer style={{ minWidth: numMeasures * MEASURE_MIN_WIDTH }}>
          {common.noClef ? null : <TrebleItem src={treble} />}
          <RestContainer>
            <MeasureMeterBackground meter={common.meter} numMeasures={numMeasures} />
            {staff.voices[0].map((voice, index) => {
              const enforcedBeamLengthVoices = enforceBeamingLength(staff.voices[0], common.meter)
              if (voice.el_type === 'note') {
                const additionalProps: Partial<NoteItemProps> = {}
                let compact = !!inBeam
                if (isNoteItem(voice) && voice.startBeam) {
                  const notes: TuneNoteItem[] = []
                  for (let i = index; i < enforcedBeamLengthVoices.length; i++) {
                    const item = enforcedBeamLengthVoices[i]
                    if (item.el_type === 'note' && isNoteItem(item)) {
                      notes.push(item)
                      if (item.endBeam) {
                        break
                      }
                    }
                  }
                  const averagePitch = getAveragePitch(notes)
                  const inverse = averagePitch >= 6
                  compact = true
                  inInverseBeam = inverse
                  additionalProps.beam = {
                    notes,
                    duration: voice.duration,
                    inverse,
                  }
                  const { maxPitch, minPitch } = getMaxMinPitchAndDuration(notes)
                  inBeam = { maxPitch, minPitch }
                }
                remainingDuration -= voice.duration
                // We want totalRemainingDuration to be the amount of available time at the end of the current measure.
                const totalRemainingDuration = staff.voices[0].reduce((acc, _voice, _index) => {
                  if (_index > index && _voice.el_type === 'note') {
                    return acc - _voice.duration
                  } else {
                    return acc
                  }
                }, remainingDuration)
                const result = (
                  <NoteItem
                    voice={voice}
                    {...common}
                    key={index}
                    {...additionalProps}
                    compact={compact}
                    inBeam={inBeam}
                    forceInverse={compact ? inInverseBeam : undefined}
                    remainingDuration={totalRemainingDuration}
                  />
                )
                if (isNoteItem(voice) && voice.endBeam) {
                  inBeam = undefined
                  inInverseBeam = false
                }
                return result
              } else if (voice.el_type === 'bar') {
                const lastBar = findLastIndex(
                  staff.voices[0],
                  (voice, i) => voice.el_type === 'bar' && i < index,
                )
                const durationInBar = staff.voices[0].reduce<number>((sum, voice, i) => {
                  if (i > lastBar && i < index && voice.el_type === 'note') {
                    return sum + voice.duration
                  } else return sum
                }, 0)
                remainingDuration = common.meter.num / common.meter.den
                return (
                  <Bar
                    bar={voice}
                    key={index}
                    remainingRoom={common.meter.num / common.meter.den - durationInBar}
                    {...common}
                  />
                )
              } else {
                return <span key={index} />
              }
            })}
          </RestContainer>
        </StaffContainer>
      </OuterStaffContainer>
    </StaffContext.Provider>
  )
}

const RestContainer = styled.span`
  display: block;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: ${HEIGHTS.staff}px;
`
const StaffContainer = styled.span`
  position: relative;
  border-bottom: solid 2px ${props => props.theme[STAFF_COLOR]};
  display: flex;
  align-items: stretch;
  width: 100%;
  height: ${HEIGHTS.staff}px;
  justify-content: space-between;
  > * {
    z-index: 1;
  }
  &:before {
    content: ' ';
    z-index: 0;
    display: block;
    border-top: solid 2px ${props => props.theme[STAFF_COLOR]};
    border-bottom: solid 2px ${props => props.theme[STAFF_COLOR]};
    height: calc(25% + 2px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  &:after {
    content: ' ';
    z-index: 0;
    display: block;
    border-top: solid 2px ${props => props.theme[STAFF_COLOR]};
    border-bottom: solid 2px ${props => props.theme[STAFF_COLOR]};
    height: calc(25% + 2px);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
`
const OuterStaffContainer = styled.div<
  CommonSheetMusicViewerProps & { extraMargin: number; topPadding?: number; bottomPadding?: number }
>`
  margin: 0 0
    calc(
      ${props => props.theme['@size-xl']} + ${props => (props.extraMargin / 100) * HEIGHTS.staff}px
    )
    0;
  padding-top: ${props => sum([props.showChords ? HEIGHTS.chord : 0, props.topPadding || 0])}px;
  padding-bottom: ${props =>
    sum([
      props.showNote ? HEIGHTS.notes : 0,
      props.showSolfege ? HEIGHTS.solfege : 0,
      props.showLyrics ? HEIGHTS.lyrics : 0,
      props.bottomPadding || 0,
    ])}px;
`
const TrebleItem = styled.img`
  position: relative;
  margin-left: ${props => props.theme['@size-xs']};
  margin-right: ${props => props.theme['@size-s']};
  top: -24%;
  height: 166%;
`
