import {
  AttachmentReadFragment,
  AttachmentReadFragmentDoc,
  useDeleteAttachmentMutation as useMutation,
} from '../schema'

export const useDeleteAttachmentMutation: typeof useMutation = opts =>
  useMutation({
    ...opts,
    update: (proxy, mutationResult) => {
      if (mutationResult.data?.deleteAttachment.id) {
        proxy.writeFragment<AttachmentReadFragment>({
          id: `Attachment:${mutationResult.data.deleteAttachment.id}`,
          fragment: AttachmentReadFragmentDoc,
          fragmentName: 'AttachmentRead',
          data: { ...mutationResult.data.deleteAttachment },
        })
      }
    },
  })
