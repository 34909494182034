import { updateMutation } from '@thesisedu/feature-apollo-react'
import { ApolloError } from '@thesisedu/feature-apollo-react/apollo'
import { useFeature } from '@thesisedu/feature-react'
import { useOAuthPopup } from '@thesisedu/web'
import { message } from 'antd'

import { CleverReactFeature } from '../CleverReactFeature'
import { debug } from '../log'
import { useAssociateCleverMutation, UserFragmentDoc, useLoginWithCleverMutation } from '../schema'
import { UseCleverAuthProps, UseCleverAssociateProps, UseCleverLoginProps } from '../types'

function loginErrorHandler(onError: UseCleverLoginProps['onError'], errors: ApolloError) {
  if (errors.graphQLErrors[0]?.extensions?.code === 'USER_ACCOUNT_NOT_FOUND_ERROR') {
    message.error(
      "We couldn't find an account matching that Clever account. Reach out to your teacher and have them add you.",
    )
  } else {
    onError(errors)
  }
}

export function useCleverLogin(props: UseCleverLoginProps) {
  const [loginWithClever, { loading: loggingIn }] = useLoginWithCleverMutation({
    onError: err => {
      loginErrorHandler(props.onError, err)
      authResult.closePopup()
    },
    onCompleted: data => {
      debug('sign in complete, calling onSuccess')
      props.onSuccess(data.loginWithClever)
    },
  })

  const authResult = useCleverAuth({
    ...props,
    onSuccess: (code, redirectUri) => {
      loginWithClever({
        variables: {
          input: {
            code,
            redirectUri,
          },
        },
      })
    },
  })

  return {
    ...authResult,
    loggingIn,
  }
}

export function useCleverAssociate(props: UseCleverAssociateProps) {
  const [associate, { loading: associating }] = useAssociateCleverMutation({
    onError: err => {
      loginErrorHandler(props.onError, err)
      authResult.closePopup()
    },
    update: updateMutation({
      fragment: UserFragmentDoc,
      fragmentName: 'User',
      path: 'associateClever.user',
    }),
    onCompleted: () => {
      debug('associate complete, calling onSuccess')
      props.onSuccess()
    },
  })

  const authResult = useCleverAuth({
    ...props,
    onSuccess: (code, redirectUri) => {
      associate({
        variables: {
          input: {
            code,
            redirectUri,
          },
        },
      })
    },
    onCancel: () => props.onError(null),
  })

  return {
    ...authResult,
    associating,
  }
}

export function useCleverAuth({ onCancel, onSuccess }: UseCleverAuthProps) {
  const feature = useFeature(CleverReactFeature)
  const redirectUri = `${window.location.origin}/auth/clever-redirect`
  const { onContainerClick, closePopup } = useOAuthPopup({
    redirectUri,
    title: 'Sign in with Clever',
    width: 450,
    height: 600,
    authorizeUrl: 'https://clever.com/oauth/authorize',
    additionalParams:
      process.env.NODE_ENV !== 'production'
        ? {
            district_id: '5b2ad81a709e300001e2cd7a',
          }
        : undefined,
    clientId: feature.options.consumerKey,
    scopes: [], // These aren't used.
    onClose: () => {
      if (onCancel) {
        onCancel()
      }
    },
    onCode: code => {
      onSuccess(code, redirectUri)
    },
  })
  return {
    signIn: onContainerClick,
    closePopup,
  }
}
