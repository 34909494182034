import { ExploreGridFiltersHook } from '@thesisedu/feature-courses-react'
import { GridTagFilter } from '@thesisedu/feature-courses-react/dist/explore/grid'
import { FeatureWeb } from '@thesisedu/feature-web'
import React from 'react'

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<ExploreGridFiltersHook>(
    'courses-react:explore-grid-filters',
    filters => {
      return [
        <GridTagFilter key={'topic'} label={'Topic'} tagType={'TOPIC'} />,
        <GridTagFilter key={'standard'} label={'Standard'} tagType={'STANDARD'} />,
      ]
    },
  )
}
