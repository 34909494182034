import { ClassGrade } from '@thesisedu/feature-assignments-react'
import { ClassSelectorText, useSelectedClass } from '@thesisedu/feature-classes-react'
import { StudentGradesTable } from '@thesisedu/feature-course-assignments-react'
import { useUserStudentQuery } from '@thesisedu/feature-students-react'
import { Block, BlockSpin, PageHead, SplitHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'

export function Grades() {
  const cls = useSelectedClass(true)
  const { data } = useUserStudentQuery()
  useBreadcrumb({ title: 'Grades' })
  const userStudentId = data?.viewer?.student?.id
  return (
    <>
      <PageHead title={`Grades - ${cls.name}`} />
      <SplitHeader
        smallText={<ClassSelectorText>{cls.name}</ClassSelectorText>}
        largeText={'Grades'}
      />
      {userStudentId ? (
        <>
          <Block marginBottom={'@size-s'}>
            <ClassGrade classId={cls.id} />
          </Block>
          <StudentGradesTable studentId={userStudentId} classId={cls.id} />
        </>
      ) : (
        <BlockSpin />
      )}
    </>
  )
}
