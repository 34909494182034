import { useClassConfiguration } from '@thesisedu/feature-classes-react'
import { InteractionsContext } from '@thesisedu/feature-interactions-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { DynamicVideoView, DynamicVideoViewProps } from '@thesisedu/feature-video-on-demand-react'
import React from 'react'

import { useUserHasWatchedVideoQuery } from '../../../schema'
import { useSegmentContext } from '../../../segment/Segment'
import { useSegmentViewedDecorationUpdater } from '../../../segment/useSegmentViewedDecorationUpdater'
import { DISABLE_VIDEO_SEEK_LIMITATION } from '../../class_configuration/disableVideoSeekLimitation'

const COMPLETE_INTERACTION_PCT = 0.9

export interface VideoViewProps extends Partial<Omit<DynamicVideoViewProps, 'vodId' | 'children'>> {
  vodId: string
  children?: (props: { isComplete?: boolean }) => React.ReactElement | null
}
export function VideoView({ vodId, children: _children, ...rest }: VideoViewProps) {
  const viewer = useViewerContext(false)
  // Restriction is only in place for students.
  const enforceSeek =
    useClassConfiguration<boolean>(DISABLE_VIDEO_SEEK_LIMITATION) && viewer?.group === 'STUDENT'
  const [allowSeek, setAllowSeek] = React.useState(!enforceSeek)
  const [isComplete, setIsComplete] = React.useState(false)
  const { data } = useUserHasWatchedVideoQuery({
    variables: { id: vodId || '' },
    skip: !vodId || !enforceSeek,
  })
  React.useEffect(() => {
    if (enforceSeek) {
      setAllowSeek(!!data?.viewer?.hasWatchedVod)
      if (data?.viewer?.hasWatchedVod) {
        callback()
      }
    }
  }, [data])
  const { id = 'no-segment' } = useSegmentContext(false) || {}
  const callback = useSegmentViewedDecorationUpdater(id, false)
  const manager = React.useContext(InteractionsContext)
  const children = _children ? _children({ isComplete }) : undefined
  return (
    <DynamicVideoView
      {...rest}
      vodId={vodId}
      allowSeek={allowSeek}
      onProgress={progress => {
        // Once the video reaches 90% watched, record the interaction and
        // enable seeking.
        if (manager && progress.played > COMPLETE_INTERACTION_PCT && !allowSeek) {
          manager.singleInteraction({
            type: 'VIEWED_VIDEO',
            reference: vodId,
          })
          setAllowSeek(true)
        }
        if (manager && progress.played >= 0.999) {
          callback()
          setIsComplete(true)
        }
      }}
      children={children || <span />}
    />
  )
}
