import { SegmentGridItem } from '@thesisedu/feature-courses-react/dist/explore/grid'
import { NavArrowRight } from '@thesisedu/react/icons'
import { Space, Body, H2, HSpaced, styled, H3Alternate } from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'

import { useExploreStandardSetsContext } from './ExploreStandardSetsContext'
import { MinimalSegmentFragment, TagsTreeItemWithSegmentsFragment } from '../schema'

export interface SegmentTree extends TagsTreeItemWithSegmentsFragment {
  children?: SegmentTree[]
}

function __countSegments(tree: SegmentTree, segmentIds: Set<string>) {
  for (const segmentId of tree.segmentIds) {
    segmentIds.add(segmentId)
  }
  for (const child of tree.children || []) {
    __countSegments(child, segmentIds)
  }

  return [...segmentIds]
}

function _countSegments(tree: SegmentTree): number {
  const set = new Set<string>()
  __countSegments(tree, set)
  return set.size
}

interface LeafAndParent extends SegmentTree {
  parents: SegmentTree[]
}
function __getLeavesAndParents(
  tree: SegmentTree,
  running: LeafAndParent[],
  parents: SegmentTree[],
) {
  if (tree.children?.length) {
    for (const child of tree.children) {
      __getLeavesAndParents(child, running, [...parents, child])
    }
  } else {
    running.push({
      ...tree,
      parents: parents.slice(0, -1),
    })
  }
}
function _getLeavesAndParents(tree: SegmentTree) {
  const running: LeafAndParent[] = []
  __getLeavesAndParents(tree, running, [])
  return running
}

export interface StandardSetGridGroupProps {
  scrollIntoView?: boolean
  expanded?: boolean
  onExpandedChange?: (expanded?: boolean) => void
  treeItem: SegmentTree
}
export function StandardSetGridGroup({
  scrollIntoView,
  treeItem,
  expanded,
  onExpandedChange,
}: StandardSetGridGroupProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const segmentCount = _countSegments(treeItem)
  React.useEffect(() => {
    if (containerRef.current && scrollIntoView) {
      containerRef.current.scrollIntoView()
    }
  }, [scrollIntoView])

  const leavesAndParents = _getLeavesAndParents(treeItem)
  const { segments, openSegment } = useExploreStandardSetsContext(true)

  return (
    <Container>
      <HSpaced
        align={'center'}
        style={segmentCount > 0 ? { cursor: 'pointer' } : { pointerEvents: 'none' }}
        onClick={onExpandedChange ? () => onExpandedChange(!expanded) : undefined}
      >
        <IconContainer expanded={expanded} hasLessons={segmentCount > 0}>
          <NavArrowRight />
        </IconContainer>
        <H2 isBlock={false} color={segmentCount > 0 ? '@gray-7' : '@text-color-secondary'}>
          {treeItem.tag.name}
        </H2>
        <Space />
        <Body color={'@text-color-secondary'}>{pluralize('Lesson', segmentCount, true)}</Body>
      </HSpaced>
      <ContentContainer expanded={expanded} ref={containerRef}>
        {leavesAndParents
          .map(leaf => {
            const parentNames = leaf.parents.map(parent => parent.tag.name).join(' → ')
            const leafSegments = leaf.segmentIds
              .map(segmentId => {
                return segments.find(s => s.id === segmentId)
              })
              .filter(Boolean) as MinimalSegmentFragment[]
            if (leafSegments.length) {
              return (
                <LeafContainer key={leaf.tag.id}>
                  {parentNames ? (
                    <Body style={{ lineHeight: 1.8 }} color={'@text-color-secondary'}>
                      {parentNames}
                    </Body>
                  ) : null}
                  <H3Alternate>{leaf.tag.name}</H3Alternate>
                  {leafSegments.length ? (
                    <SegmentGridContainer>
                      {leafSegments.map(leafSegment => (
                        <SegmentGridItem
                          segment={leafSegment}
                          key={leafSegment.id}
                          onClick={() => openSegment(leafSegment.id)}
                        />
                      ))}
                    </SegmentGridContainer>
                  ) : null}
                </LeafContainer>
              )
            } else {
              return null
            }
          })
          .filter(Boolean)}
      </ContentContainer>
    </Container>
  )
}

const SegmentGridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme['@size-m']};
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
`
const LeafContainer = styled.div`
  margin-bottom: ${props => props.theme['@size-l']};
`
const IconContainer = styled.div<{ expanded?: boolean; hasLessons: boolean }>`
  font-size: ${props => props.theme['@size-l']};
  width: ${props => props.theme['@size-l']};
  height: ${props => props.theme['@size-l']};
  transition: transform 0.25s ease-in-out;
  transform: ${props => (props.expanded ? 'rotate(90deg)' : 'rotate(0deg)')};
  transform-origin: 50% 50%;
  color: ${props => props.theme['@text-color']};
  margin-right: ${props => props.theme['@size-xs']};
  opacity: ${props => (props.hasLessons ? 1 : 0)};
  svg {
    display: block;
    width: 1em;
    height: 1em;
  }
`
const ContentContainer = styled.div<{ expanded?: boolean }>`
  opacity: ${props => (props.expanded ? 1 : 0)};
  padding-left: calc(${props => props.theme['@size-xs']} + ${props => props.theme['@size-l']});
  transition:
    opacity 0.25s linear,
    max-height 0s linear ${props => (props.expanded ? '0s' : '0.25s')};
  max-height: ${props => (props.expanded ? 100000 : 0)}px;
  overflow: hidden;
  > :first-child {
    margin-top: ${props => props.theme['@size-l']};
  }
`
const Container = styled.div`
  border: solid 2px ${props => props.theme['@border-color-base']};
  border-radius: ${props => props.theme['@border-radius-large']};
  padding: ${props => props.theme['@size-m']};
  &:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-m']};
  }
`
