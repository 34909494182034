import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { LoadingIndicator, LoadingIndicatorProps } from './LoadingIndicator'
import { VSpaced, Text, Block, styled, s } from '../..'

export interface LabeledLoadingIndicatorProps extends Omit<LoadingIndicatorProps, 'size'> {
  label: React.ReactElement | React.ReactElement[] | string
  style?: any
  className?: string
  size?: keyof s.FontSizeAliases
  visible?: boolean
}
export function LabeledLoadingIndicator({
  label,
  style,
  className,
  size = 'l',
  visible = true,
  ...rest
}: LabeledLoadingIndicatorProps) {
  return (
    <LoadingIndicatorContainer visible={visible}>
      <Block
        vertical={'m'}
        horizontal
        style={{ textAlign: 'center', ...style }}
        className={className}
      >
        <VSpaced space={'xxs'} align={'center'}>
          <Text level={size} color={'secondary'} as={'div'}>
            <LoadingIndicator {...rest} size={'1.5em'} />
          </Text>
          <Text level={size} color={'secondary'} as={'div'}>
            {label}
          </Text>
        </VSpaced>
      </Block>
    </LoadingIndicatorContainer>
  )
}

export function InlineLabeledLoadingIndicator({
  label,
  style,
  className,
  size = 's',
  visible = true,
  ...rest
}: LabeledLoadingIndicatorProps) {
  return (
    <LoadingIndicatorContainer visible={visible}>
      <InlineContainer $size={size} style={style} className={className}>
        <span>
          <LoadingIndicator {...rest} size={'1.15em'} />
        </span>
        <span>{label}</span>
      </InlineContainer>
    </LoadingIndicatorContainer>
  )
}

interface LoadingIndicatorContainerProps {
  children: React.ReactNode
  visible?: boolean
}
function LoadingIndicatorContainer({ children, visible = true }: LoadingIndicatorContainerProps) {
  return (
    <AnimatePresence initial={false}>
      {visible ? (
        <motion.div initial={{ opacity: 0 }} exit={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

const InlineContainer = styled.div<{ $size: keyof s.FontSizeAliases }>`
  display: inline-flex;
  align-items: center;
  font-size: ${props => s.size(`font.${props.$size}`)};
  gap: 0.5em;
  margin: 0 auto;
  line-height: 1;
  color: ${s.color('secondary')};
  transition: opacity 0.1s linear;
`
