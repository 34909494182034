import { useResource } from '@thesisedu/feature-react'
import { s } from '@thesisedu/ui'
import { BlockSpin, NotFound } from '@thesisedu/web'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'

import { useGeneratedPdfQuery } from './schema'
import { ReactPDFGeneratorResource } from './types'

export interface GeneratePDFPageContextValue {
  isGeneratingPdf: boolean
}
export const GeneratePDFPageContext = React.createContext<GeneratePDFPageContextValue>({
  isGeneratingPdf: false,
})

export function useIsGeneratingPdf() {
  return React.useContext(GeneratePDFPageContext).isGeneratingPdf
}

export function GeneratePDFPage() {
  const { id } = useParams()
  const [params] = useSearchParams()
  const token = params.get('token')
  const { data, loading } = useGeneratedPdfQuery({
    variables: {
      id,
      token,
    },
  })
  const generatedPdf = data?.generatedPdf
  const resource = useResource<ReactPDFGeneratorResource<object>>(
    'ReactPDFGenerator',
    generatedPdf?.type || 'none',
  )

  if (generatedPdf && resource) {
    // StyleSheetManager is required because in production, styled-components creates the styles
    // in a way that is not friendly to being retrieved using .textContent (which pagedjs uses
    // to fetch the styles).
    // We should only need it here because theoretically all of the styles underneath this node
    // are the only ones we really care about.
    return (
      <GeneratePDFPageContext.Provider value={{ isGeneratingPdf: true }}>
        {/* @ts-ignore not upgrading dependency; children types are missing. */}
        <StyleSheetManager disableCSSOMInjection>
          <s.ThemeProvider forceGlobalStyle>
            <resource.generateComponent
              {...generatedPdf}
              onReady={() => {
                // Give the page some time to settle...
                setTimeout(() => {
                  require('../pagedjs')
                  // This is only used on the server-side of things. Let's call it whenever
                  // the page is ready to be rendered.
                  if ((window as any).PagedConfig?.featurePdfOnReady) {
                    ;(window as any).PagedConfig.featurePdfOnReady()
                  }
                }, 500)
              }}
            />
          </s.ThemeProvider>
        </StyleSheetManager>
      </GeneratePDFPageContext.Provider>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'That content could not be found.'} />
  }
}
