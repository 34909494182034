import { useResource } from '@thesisedu/feature-react'

import { QuestionTypeResource } from '../types'

export function useQuestionResources() {
  return useResource<QuestionTypeResource>('QuestionType')
}

export function useQuestionResource(type: string) {
  const resource = useResource<QuestionTypeResource>('QuestionType', type)
  if (!resource) {
    throw new Error(`Cannot find resource for type ${type}`)
  }
  return resource
}
