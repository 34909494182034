import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import React from 'react'
import { DragSourceMonitor } from 'react-dnd'
import { DndProvider } from 'react-dnd-multi-backend'

export interface WebDndProviderProps {
  children: React.ReactElement
}
export function WebDndProvider({ children }: WebDndProviderProps) {
  return <DndProvider options={HTML5toTouch} children={children} />
}

function getScrollParent(node: (Node & ParentNode) | null): HTMLElement | null {
  const overflowY = node instanceof HTMLElement && window.getComputedStyle(node).overflowY
  const isScrollable =
    !overflowY.toString().includes('hidden') && !overflowY.toString().includes('visible')

  if (!node) {
    return null
  } else if (
    node instanceof HTMLElement &&
    isScrollable &&
    node.scrollHeight >= node.clientHeight
  ) {
    return node
  }

  return getScrollParent(node.parentNode) || document.body
}

const SCROLL_ZONE_PX = 100
const SCROLL_DELTA = 8

/**
 * Adds scrolling support to react-dnd through a helpful hook.
 * @param containerRef A React Ref pointing to the container of the drag / drop area.
 * @returns Two functions: one to call on onCollect, and another on onDrop.
 */
export function useScrollIfRequired(
  containerRef: React.RefObject<HTMLElement>,
): [(monitor: DragSourceMonitor) => void, () => void] {
  const animationFrame = React.useRef<any>(null)
  const cancelHandler = React.useRef<() => void>()
  return [
    React.useCallback((monitor: DragSourceMonitor) => {
      if (!monitor.isDragging()) return
      const scrollParent = getScrollParent(containerRef.current)
      const scrollParentRect = scrollParent?.getBoundingClientRect()
      const handler = () => {
        cancelAnimationFrame(animationFrame.current)
        animationFrame.current = requestAnimationFrame(() => {
          if (scrollParentRect) {
            const offset = monitor.getClientOffset()
            const topOffset = offset ? offset.y - scrollParentRect.y : undefined
            const isAtBottom = topOffset && topOffset > scrollParentRect.height - SCROLL_ZONE_PX
            const isAtTop = topOffset && topOffset < SCROLL_ZONE_PX
            if (scrollParent) {
              if (isAtBottom) {
                scrollParent.scrollTop += SCROLL_DELTA
              }
              if (isAtTop) {
                scrollParent.scrollTop -= SCROLL_DELTA
              }
            }
          }
        })
      }
      if (containerRef.current) {
        containerRef.current.addEventListener('drag', handler)
        cancelHandler.current = () => {
          containerRef.current?.removeEventListener('drag', handler)
        }
      }
    }, []),
    React.useCallback(() => {
      if (cancelHandler.current) {
        cancelHandler.current()
      }
    }, []),
  ]
}
