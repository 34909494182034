import { useFreshRef } from '@thesisedu/feature-react'
import { useFlag } from '@thesisedu/react'
import { LoadingIndicator, Select } from '@thesisedu/ui'
import React from 'react'

import { NoPermissionModal } from './NoPermissionModal'
import { PrefixContainer, Indicator } from './styled'
import { UseMediaDevicesOpts, useMediaDevices } from './useMediaDevices'

const NONE_DEVICE = '--none--'

export interface SelectMediaSourceProps {
  value?: string
  onChange?: (value?: string) => void
  disabled?: boolean
  type: UseMediaDevicesOpts['type']
  constraints: UseMediaDevicesOpts['constraints']
  icon: React.ReactElement
  label: string
}
export function SelectMediaSource({
  label,
  type,
  constraints,
  icon,
  value,
  onChange: _onChange,
  disabled,
}: SelectMediaSourceProps) {
  const [defaultDeviceId, setDefaultDeviceId] = useFlag<string | undefined>(
    `media:default-device:${type}`,
    undefined,
  )
  const onChange: typeof _onChange = val => {
    if (val) {
      setDefaultDeviceId(val)
    }
    _onChange?.(val)
  }
  const [open, setOpen] = React.useState(false)
  const [noPermissionVisible, setNoPermissionVisible] = React.useState(false)
  const { devices, refreshDevices, state } = useMediaDevices({
    constraints,
    type,
    refreshDevicesOnMount: true,
  })
  const stateRef = useFreshRef(state)
  React.useEffect(() => {
    if (_onChange) {
      const defaultDevice = defaultDeviceId
        ? devices.find(d => d.id === defaultDeviceId)
        : undefined
      _onChange(defaultDevice?.id ?? devices[0]?.id)
    }
  }, [devices.length])

  const errorState = state === 'error' || state === 'noPermission' || state === 'unsupported'
  const placeholders: Partial<Record<typeof state, string>> = {
    loading: `Loading ${label} Devices...`,
    error: `No ${label} Devices`,
    noPermission: `Click to Grant ${label} Permission`,
    unsupported: `No ${label} Devices`,
  }

  return (
    <>
      <NoPermissionModal
        icon={icon}
        label={label}
        visible={noPermissionVisible}
        onVisibleChange={visible => {
          setNoPermissionVisible(visible)
          if (!visible) {
            // Forcefully refresh devices again...
            refreshDevices(true)
          }
        }}
      />
      <Select
        value={value ?? (state === 'idle' ? NONE_DEVICE : undefined)}
        open={open}
        onOpenChange={async open => {
          if (state === 'noPermission' && open) {
            await refreshDevices(true)

            // If we still don't have permission, show the no permission modal.
            if (stateRef.current === 'noPermission') {
              setNoPermissionVisible(true)
            }
          } else {
            setOpen(open)
          }
        }}
        onValueChange={value => {
          onChange?.(value === NONE_DEVICE ? undefined : value)
        }}
        disabled={state === 'loading' || state === 'unsupported' || disabled}
        prefix={
          <PrefixContainer>
            {state === 'loading' ? <LoadingIndicator /> : icon}
            <Indicator
              $color={
                state === 'loading'
                  ? 'blue'
                  : state === 'noPermission'
                  ? 'orange'
                  : disabled || errorState || !value
                  ? 'red'
                  : 'green'
              }
            />
          </PrefixContainer>
        }
        placeholder={placeholders[state] ?? `Select a ${label} Device...`}
      >
        {state === 'idle' ? (
          <>
            {devices.map(device => {
              return (
                <Select.Item value={device.id} key={device.id}>
                  {device.label}
                </Select.Item>
              )
            })}
            <Select.Separator />
            <Select.Item value={NONE_DEVICE}>No {label}</Select.Item>
          </>
        ) : (
          []
        )}
      </Select>
    </>
  )
}
