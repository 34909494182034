import { CourseQuestion } from '@thesisedu/feature-course-types'
import { Body, BodyLarge, FontWeight } from '@thesisedu/react'
import { Block, Callout } from '@thesisedu/web'
import React from 'react'

export interface VaultAutoGradeWarningProps {
  questions: CourseQuestion[]
}
export function VaultAutoGradeWarning({ questions }: VaultAutoGradeWarningProps) {
  const hasNonAutoGradeable = questions.some(q => !q.autograde && !q.disabled)
  if (hasNonAutoGradeable) {
    return (
      <Block marginTop={'@size-l'} marginBottom={'@size-s'}>
        <Callout type={'warning'}>
          <BodyLarge isBlock weight={FontWeight.Bold}>
            Some of your selected questions are not auto-gradeable.
          </BodyLarge>
          <Body>
            This means when students submit this assignment, the system will not automatically grade
            it and you will have to manually review each submission.
          </Body>
        </Callout>
      </Block>
    )
  } else {
    return null
  }
}
