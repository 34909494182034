import { Format } from '@thesisedu/feature-utils'
import { styled, Body, BodyLarge, FontWeight } from '@thesisedu/web'
import { Progress } from 'antd'
import React from 'react'

import { AssignmentAssignedStudentsFragment, AssignmentSubmissionsFragment } from '../schema'

export interface AssignmentProgressProps {
  assignment: AssignmentSubmissionsFragment & AssignmentAssignedStudentsFragment
}
export function AssignmentProgress({ assignment }: AssignmentProgressProps) {
  const averageGrade = assignment.submissions?.averageGrade || 0
  const totalStudents = assignment.students.edges.length
  const completed = assignment.submissions?.edges.filter(edge => edge.node.submittedAt).length || 0
  const realCompleted = Math.min(completed, totalStudents)
  return (
    <Container>
      <div className={'top'}>
        <Body>
          Average grade:{' '}
          <BodyLarge weight={FontWeight.Bold} style={{ display: 'inline' }}>
            {Format.number(averageGrade, 'decimalPercentage')}
          </BodyLarge>
        </Body>
        <Body className={'right'}>
          <strong>{realCompleted}</strong> out of <strong>{totalStudents}</strong> completed
        </Body>
      </div>
      <Progress
        showInfo={false}
        percent={totalStudents > 0 ? (realCompleted * 100) / totalStudents : 0}
      />
    </Container>
  )
}

const Container = styled.div`
  > .top {
    display: flex;
    align-items: center;
    .right {
      margin-left: auto;
      text-align: right;
    }
    > :first-child {
      margin-right: 5px;
    }
  }
  .ant-progress {
    line-height: 1;
  }
`
