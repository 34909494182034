import { onMutationError } from '@thesisedu/feature-apollo-react'
import { MultiUserSelect } from '@thesisedu/feature-users-web'
import { Modal, Modal$, Form, useToast } from '@thesisedu/ui'
import { Trophy } from '@thesisedu/ui/icons'
import React from 'react'

import { AwardBadgeInput, useAwardBadgeMutation } from '../schema'

export interface AwardBadgeModalProps extends Partial<Modal$.ModalProps> {
  badgeId: string
}
export function AwardBadgeModal({ badgeId, ...rest }: AwardBadgeModalProps) {
  const toast = useToast()
  const form = Form.useForm<Omit<AwardBadgeInput, 'id'>>()
  const [award] = useAwardBadgeMutation({
    onError: onMutationError('There was an error awarding that badge.'),
    onCompleted() {
      toast({ title: 'Badge awarded', status: 'success' })
    },
  })
  return (
    <Form.Modal
      {...rest}
      form={form}
      title={'Award Badge'}
      onFinish={async values => {
        await award({
          variables: {
            input: {
              id: badgeId,
              ...values,
            },
          },
        })
      }}
    >
      <Form.Field name={'userIds'} label={'User IDs'} required>
        <MultiUserSelect maxSelection={20} />
      </Form.Field>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Trophy />} children={'Award Badge'} />
      </Modal.Footer>
    </Form.Modal>
  )
}
