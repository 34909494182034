import { SAMLButtonPlacement, SAMLButtonResource } from '@thesisedu/feature-users-react'

import { CleverReactFeature } from '../CleverReactFeature'
import { CleverLoginButton } from '../web/CleverLoginButton'

export default function (feature: CleverReactFeature) {
  feature.resources.addResource<SAMLButtonResource>({
    type: 'SAMLButton',
    identifier: 'clever',
    weight: 6,
    component: CleverLoginButton,
    placement: feature.options.noSAMLButton
      ? SAMLButtonPlacement.Secondary
      : SAMLButtonPlacement.Prominent,
  })
}
