import {
  SiteConfigurationFieldProps,
  SiteConfigurationFieldResource,
} from '@thesisedu/feature-sites-web'
import { Modal } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { ClassConfiguration, HiddenMode } from '../../ClassConfiguration'

export const DEFAULT_CLASS_CONFIGURATION = 'defaultClassConfiguration'

function Field({ fieldPrefix }: SiteConfigurationFieldProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Modal
        title={'Default Class Settings'}
        visible={visible}
        onCancel={() => setVisible(false)}
        width={600}
      >
        <ClassConfiguration
          fieldPrefix={[...fieldPrefix, DEFAULT_CLASS_CONFIGURATION]}
          showHidden={HiddenMode.IncludeHidden}
        />
      </Modal>
      <Button onClick={() => setVisible(true)}>Edit Default Class Settings</Button>
    </>
  )
}

const field: SiteConfigurationFieldResource = {
  type: 'SiteConfigurationField',
  identifier: DEFAULT_CLASS_CONFIGURATION,
  renderField: Field,
  group: 'Classes',
}
export default field
