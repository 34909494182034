import {
  StarFilled,
  StarOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  IssuesCloseOutlined,
} from '@ant-design/icons'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Body, BodySmall, styled, Block } from '@thesisedu/web'
import { Tooltip, InputNumber, Dropdown, Menu, Button } from 'antd'
import React from 'react'

import { AddQuestionButton } from '../../edit/AddQuestionButton'
import { QuestionTextMediaField } from '../../questions/QuestionTextMediaField'
import { QuestionProps } from '../../types'

export interface AdditionalField {
  label: string
  field: string
  icon: React.ReactElement
  contents: React.ReactElement
}
export interface QuestionAdditionalFieldsProps extends QuestionProps<any> {
  fields?: AdditionalField[]
  useExplanation?: boolean
  useSampleAnswer?: boolean
  additionalMenuItems?: React.ReactElement[]
  selectedKeys?: string[]
}

export function QuestionAdditionalFields({
  fields = [],
  useExplanation,
  useSampleAnswer,
  onChange,
  onAddAfter,
  question,
  additionalMenuItems,
  selectedKeys,
}: QuestionAdditionalFieldsProps) {
  const viewer = useViewerContext(false)
  const isAdmin = viewer?.group === 'ADMIN'
  if (useExplanation) {
    fields.push({
      icon: <InfoCircleOutlined />,
      label: 'Explanation',
      field: 'explanation',
      contents: (
        <>
          <Body isBlock>Explanation</Body>
          <QuestionTextMediaField
            value={question.config?.explanation || ''}
            onChange={v => {
              if (onChange) {
                onChange({
                  config: {
                    ...question.config,
                    explanation: v,
                  },
                })
              }
            }}
            textAreaProps={{
              autoSize: { minRows: 2, maxRows: 6 },
            }}
          />
          <BodySmall isBlock color={'@text-color-secondary'}>
            This will only be displayed as a reference to the teacher. It should explain why the
            answer selected is correct.
          </BodySmall>
        </>
      ),
    })
  }
  if (useSampleAnswer) {
    fields.push({
      icon: <InfoCircleOutlined />,
      label: 'Sample Answer',
      field: 'answer',
      contents: (
        <>
          <Body isBlock>Sample Answer</Body>
          <QuestionTextMediaField
            value={question.config?.answer || ''}
            onChange={v => {
              if (onChange) {
                onChange({
                  config: {
                    ...question.config,
                    answer: v,
                  },
                })
              }
            }}
            textAreaProps={{
              autoSize: { minRows: 2, maxRows: 6 },
            }}
          />
          <BodySmall isBlock color={'@text-color-secondary'}>
            This will only be displayed as a reference to the teacher when grading submissions.
          </BodySmall>
        </>
      ),
    })
  }

  const [enabledFields, setEnabledFields] = React.useState<string[]>(
    fields.filter(field => !!question.config?.[field.field]).map(field => field.label),
  )
  const mergedSelectedKeys = [...enabledFields, ...(selectedKeys || [])]
  if (question.disableGrading) mergedSelectedKeys.push('disableGrading')
  if (question.config?.isRecommended) mergedSelectedKeys.push('isRecommended')
  if (question.config?.excludeFromQuizVault) mergedSelectedKeys.push('excludeFromQuizVault')

  return (
    <>
      <Header>
        <InputNumber
          style={{ width: 100 }}
          precision={0}
          value={question.totalPoints}
          onChange={e => {
            if (onChange) {
              onChange({
                totalPoints: e ? parseFloat(e?.toString() || '') : undefined,
              })
            }
          }}
        />
        <Body>points</Body>
        <div className={'spacer'} />
        {onAddAfter ? (
          <Tooltip title={'Add question after this'} mouseEnterDelay={1}>
            <AddQuestionButton noText onAdd={onAddAfter} />
          </Tooltip>
        ) : null}
        <Dropdown
          trigger={['click']}
          placement={'bottomLeft'}
          arrow
          overlay={
            <Menu
              selectedKeys={mergedSelectedKeys}
              onClick={({ key }) => {
                if (key === 'disableGrading') {
                  if (onChange) {
                    onChange({
                      disableGrading: !question.disableGrading,
                    })
                  }
                } else if (key === 'excludeFromQuizVault') {
                  if (onChange) {
                    onChange({
                      config: {
                        ...question.config,
                        excludeFromQuizVault: !question.config?.excludeFromQuizVault,
                      },
                    })
                  }
                } else if (key === 'isRecommended') {
                  if (onChange) {
                    onChange({
                      config: {
                        ...question.config,
                        isRecommended: !question.config?.isRecommended,
                      },
                    })
                  }
                } else {
                  const field = fields.find(f => f.label === key.toString())
                  if (field && onChange) {
                    const { [field.field]: f, ...rest } = question.config
                    onChange({
                      config: rest,
                    })
                    setEnabledFields(f => {
                      if (f.includes(key.toString())) {
                        return f.filter(f => f !== key.toString())
                      } else {
                        return [...f, key.toString()]
                      }
                    })
                  }
                }
              }}
              multiple
            >
              {fields?.length ? <Menu.ItemGroup title={'Show'} /> : null}
              {fields.map(field => (
                <Menu.Item key={field.label}>
                  {field.icon} {field.label}
                </Menu.Item>
              ))}
              {fields.length ? <Menu.Divider /> : null}
              <Menu.Item key={'disableGrading'}>
                <IssuesCloseOutlined /> Disable Grading
              </Menu.Item>
              {isAdmin ? (
                <>
                  <Menu.Item key={'excludeFromQuizVault'}>
                    {question.config?.excludeFromQuizVault ? (
                      <EyeInvisibleOutlined />
                    ) : (
                      <EyeOutlined />
                    )}{' '}
                    Excluded from Quiz Vault
                  </Menu.Item>
                  <Menu.Item key={'isRecommended'}>
                    {question.config?.isRecommended ? <StarFilled /> : <StarOutlined />} Recommended
                  </Menu.Item>
                </>
              ) : null}
              {additionalMenuItems}
            </Menu>
          }
        >
          <Button icon={<SettingOutlined />} />
        </Dropdown>
      </Header>
      {fields
        .filter(f => enabledFields.includes(f.label))
        .map(field => (
          <Block marginTop={'@size-s'}>{field.contents}</Block>
        ))}
    </>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.theme['@size-xs']};
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
  > .spacer {
    margin-left: auto;
  }
`
