import { styled, StyledComponent, HSpaced } from '@thesisedu/web'

export const CustomGroupContainer: StyledComponent<typeof HSpaced> = styled(HSpaced)`
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => props.theme['@background-color-base']};
  padding: ${props => props.theme['@padding-md']};
  button {
    background: ${props => props.theme['@gray-2']};
  }
`

export const CustomGroupsContainer: StyledComponent<typeof HSpaced> = styled(HSpaced)`
  flex-wrap: wrap;
  justify-content: flex-start;
  > * {
    margin-bottom: ${props => props.theme['@size-s']};
  }
`

export const StaffContainer: StyledComponent<'div'> = styled.div`
  zoom: 0.6;
  margin-bottom: -${props => props.theme['@size-s']};
  pointer-events: none;
`
