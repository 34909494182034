import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useCreateAssignmentCategoryMutation as useMutation } from '../schema'

export const useCreateAssignmentCategoryMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error creating that category.'),
    refetchQueries: ['assignmentClassConfiguration'],
    awaitRefetchQueries: true,
    ...opts,
  })
