import { useSelectedClassId } from '@thesisedu/feature-classes-react'

import { StudentPracticesDocument, useCreatePracticeMutation as useMutation } from '../schema'

export const useCreatePracticeMutation: typeof useMutation = opts => {
  const selectedClassId = useSelectedClassId()
  return useMutation({
    refetchQueries: [{ query: StudentPracticesDocument, variables: { classId: selectedClassId } }],
    ...opts,
  })
}
