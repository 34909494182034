import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { media, styled } from '@thesisedu/web'
import { Button, Dropdown, Menu } from 'antd'
import React from 'react'

import { useClassSelector } from './ClassContext'
import { JoinClassModal } from './JoinClassModal'
import { NO_CLASS_OPTION } from './constants'
import { useOptions } from './options'
import { ClassHooks, NoClassContentContext, NoClassContentPayload } from './types'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-m']};
`
const ContainerCenter = styled.div`
  margin: 0 auto 0 0;
  text-align: left;
  ${media.lg} {
    margin: 0 auto 0 auto;
    text-align: center;
  }
  flex-grow: 1;
`
const ContainerRight = styled.div`
  width: 30vw;
  text-align: right;
`
const ContainerLeft = styled.div`
  width: 30vw;
  text-align: left;
`

export interface BlockClassSelectorProps {
  noClassOption?: string
  leftContent?: React.ReactElement
}
export const BlockClassSelector: React.FC<React.PropsWithChildren<BlockClassSelectorProps>> = ({
  noClassOption,
  leftContent,
}) => {
  const root = useFeatureRoot()!
  const { classes, selectedClassId, setSelectedClassId, disableSelector } = useClassSelector()
  const options = useOptions()
  const [visible, setVisible] = React.useState(false)
  const [joinVisible, setJoinVisible] = React.useState(false)
  const selectedClass = classes.find(cls => cls.id === selectedClassId)
  const className = selectedClass ? selectedClass.name : noClassOption ? noClassOption : '--'

  const menu = (
    <Menu
      onClick={({ key }) => {
        setSelectedClassId(key.toString())
        setVisible(false)
      }}
    >
      {classes
        .filter(cls => !cls.archivedAt)
        .map(cls => (
          <Menu.Item key={cls.id}>
            <span>{cls.name}</span>
          </Menu.Item>
        ))}
      {noClassOption ? (
        <Menu.Item key={NO_CLASS_OPTION}>
          <span>
            {noClassOption}{' '}
            {root.deps.hookManager.mutateHookSync<NoClassContentPayload, NoClassContentContext>(
              ClassHooks.NoClassContent,
              null,
              {},
            )}
          </span>
        </Menu.Item>
      ) : null}
    </Menu>
  )

  return (
    <Container>
      <JoinClassModal visible={joinVisible} onClose={() => setJoinVisible(false)} />
      <ContainerLeft>{leftContent}</ContainerLeft>
      <ContainerCenter>
        <Dropdown
          disabled={disableSelector}
          overlay={menu}
          visible={visible}
          onVisibleChange={setVisible}
          trigger={['click']}
        >
          <Button type={'primary'}>
            <strong>Selected {options.classNameCapitalized}:&nbsp;</strong>
            {className}
            <DownOutlined />
          </Button>
        </Dropdown>
      </ContainerCenter>
      <ContainerRight>
        <Button type={'link'} size={'small'} onClick={() => setJoinVisible(true)}>
          Join Another {options.classNameCapitalized}
        </Button>
      </ContainerRight>
    </Container>
  )
}
