import { fromGlobalId } from '@thesisedu/feature-utils'
import React from 'react'

import { ReactFeature } from './ReactFeature'
import {
  GetMetadataCallback,
  ModelMetadataResource,
  ModelMetadataResult,
  PartialFragment,
} from './types'
import { useFeatureRoot } from './useFeatureRoot'

export interface ModelMetadataOpts {
  instance?: PartialFragment<any>
}
export function useModelMetadata({ instance }: ModelMetadataOpts): ModelMetadataResult | undefined {
  const root = useFeatureRoot()
  const linkResources =
    root.deps.resources.getResourcesForType<ModelMetadataResource>('ModelMetadata')
  if (instance) {
    const { id } = instance
    const { type } = fromGlobalId(id, true)
    return linkResources.find(r => r.identifier === type)?.getMetadata(instance)
  } else {
    return undefined
  }
}

export interface ModelMetadataProps extends ModelMetadataOpts {
  includeAAn?: boolean
}
export function ModelMetadata({ includeAAn, ...props }: ModelMetadataProps) {
  const metadata = useModelMetadata(props)
  const prefix = includeAAn
    ? metadata?.name
      ? metadata.nameStartsWithVowel
        ? 'an '
        : 'a '
      : ''
    : ''
  if (metadata?.detail) {
    return (
      <span>
        {prefix}
        <strong>{metadata.name}</strong> {metadata.detail}
      </span>
    )
  } else if (metadata?.name) {
    return (
      <span>
        {prefix}
        {metadata.name}
      </span>
    )
  } else {
    return <span>{includeAAn ? 'a piece of ' : ''}content</span>
  }
}

export function addModelMetadata<T extends object = any>(
  feature: ReactFeature,
  graphqlType: string,
  callback: GetMetadataCallback<T>,
) {
  feature.resources.addResource<ModelMetadataResource>({
    type: 'ModelMetadata',
    identifier: graphqlType,
    getMetadata: callback,
  })
}
