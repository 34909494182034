import { WidgetConverter } from './types'
import * as Legacy from '..'

export interface GroupWidget extends Legacy.Widget {
  childGroups?: {
    children?: Legacy.Widget[]
  }
}

export default {
  weight: 0,
  label: 'group',
  identifier: 'Group',
  convert(value, opts) {
    const children = value.childGroups?.children ?? []
    return children.flatMap(widget => Legacy.convertWidget(widget, opts))
  },
} satisfies WidgetConverter<GroupWidget>
