import { LoadingOutlined } from '@ant-design/icons'
import { ErrorIndicator } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useFetchSegmentQuery } from '../queries/useFetchSegmentQuery'
import { CreateEditSegment } from '../segment/CreateEditSegment'

export function CreateEditContent() {
  const { segmentId } = useParams()
  const { data, loading, error } = useFetchSegmentQuery(segmentId)

  if (error) return <ErrorIndicator />
  if (loading)
    return <Result icon={<LoadingOutlined size={75} />} title={'Loading your content...'} />

  return (
    <CreateEditSegment segment={data?.node?.__typename === 'Segment' ? data.node : undefined} />
  )
}
