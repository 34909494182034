import { styled } from '@thesisedu/web'
import React from 'react'

import { DOTTED_NOTES } from './Note'
import { TuneRestItem } from './types'
// @ts-ignore
import restEighth from '../../assets/rest-eighth.svg'
// @ts-ignore
import restHalf from '../../assets/rest-half-whole.svg'
// @ts-ignore
import restQuarter from '../../assets/rest-quarter.svg'

interface RestMap {
  [duration: string]: string
}
const durationRestMap: RestMap = {
  '0.125': restEighth,
  '0.25': restQuarter,
  '0.5': restHalf,
  '0.75': restHalf,
  '1': restHalf,
}
const offsetMap: RestMap = {
  '0.5': '-5%',
  '0.75': '-5%',
  '1': '-17%',
}

export interface RestProps {
  note: TuneRestItem
}
export function Rest({ note }: RestProps) {
  const rest = durationRestMap[note.duration.toString()]
  const offset = offsetMap[note.duration.toString()]
  const isDotted = DOTTED_NOTES.includes(note.duration)
  return rest ? (
    <RestContainer
      duration={note.duration}
      dotted={isDotted}
      className={'scale'}
      style={offset ? { transform: `translateY(${offset})` } : undefined}
    >
      <div>
        <img src={rest} alt={'Rest'} />
      </div>
    </RestContainer>
  ) : null
}
const RestContainer = styled.div<{ duration: number; dotted?: boolean }>`
  flex: ${props => props.duration * 4};
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: relative;
    display: block;
    height: 75%;
    ${props => (props.dotted ? '&:before' : '&.noop')} {
      display: block;
      content: ' ';
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: -15px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: ${props => props.theme['@brown']};
    }
  }
  img {
    height: 100%;
    display: block;
  }
`
