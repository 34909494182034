import { styled } from '@thesisedu/ui'
import React from 'react'

export function RecordButtonDefaultState() {
  return (
    <>
      <RecordIcon />
      <span>Start Recording</span>
    </>
  )
}

const RecordIcon = styled.div`
  width: 1em;
  height: 1em;
  border: solid 2px currentColor;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background: currentColor;
  }
`
