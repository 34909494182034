import { ClassSyncProviderResource } from '@thesisedu/feature-class-sync-react'
import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface GoogleClassroomReactFeatureOptions {}

export class GoogleClassroomReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-google-classroom-react'
  static path = ''
  static requires: string[] = []
  public options!: GoogleClassroomReactFeatureOptions

  public reactResources() {
    this.resources.addResource<ClassSyncProviderResource>({
      type: 'ClassSyncProvider',
      identifier: 'google',
      logos: {
        square: require('../assets/icon.svg').default,
        horizontal: require('../assets/large.svg').default,
      },
    })
    require('./hooks/expectedError').default(this)
  }
}

export const googleClassroomReactFeature: ReactUse = (opts: GoogleClassroomReactFeatureOptions) => [
  GoogleClassroomReactFeature,
  opts,
]
