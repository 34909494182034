import moment from 'moment'

export enum TimeOfDay {
  Evening = 'evening',
  Afternoon = 'afternoon',
  Morning = 'morning',
}

export function getTimeOfDay(): TimeOfDay {
  const time = moment()
  if (time.hour() >= 18) return TimeOfDay.Evening
  else if (time.hour() >= 12) return TimeOfDay.Afternoon
  else return TimeOfDay.Morning
}
