import React from 'react'

import { DownloadFooterItem } from './DownloadFooterItem'
import { MediaViewProviders } from './MediaViewProviders'
import { useMediaContext } from '../record/MediaContext'
import { MediaPlayer } from '../record/MediaPlayer'

export interface MediaViewProps {
  mediaId: string
  allowDownload?: boolean
  noContextInheritance?: boolean
  children?: React.ReactNode
}
export function MediaView({ children, ...props }: MediaViewProps) {
  return (
    <MediaViewProviders {...props}>
      <MediaViewInner {...props} />
      {children}
    </MediaViewProviders>
  )
}

function MediaViewInner({ allowDownload }: Omit<MediaViewProps, 'children'>) {
  const { media } = useMediaContext(true)
  const [path, setPath] = React.useState(media?.path)
  React.useEffect(() => {
    setPath(media?.path)
  }, [!!media?.path])
  if (path) {
    return (
      <MediaPlayer
        extraFooterItems={
          allowDownload ? [<DownloadFooterItem key={'download'} url={path} />] : undefined
        }
      >
        <video src={path} />
      </MediaPlayer>
    )
  } else return null
}
