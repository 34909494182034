import { useControllableForm } from '@thesisedu/web'
import { useEffect } from 'react'

import { useUpdateDistrictMutation } from './queries/useUpdateDistrictMutation'
import { useDistrictQuery } from './schema'

export const useEditDistrictForm = (districtId: string) => {
  const { data } = useDistrictQuery({ variables: { id: districtId } })
  const [updateDistrict] = useUpdateDistrictMutation()
  const result = useControllableForm(values =>
    updateDistrict({
      variables: {
        input: {
          id: districtId,
          patch: values,
        },
      },
    }),
  )
  useEffect(() => {
    if (data?.node) {
      result.form.setFieldsValue(data.node)
    }
  }, [data, result])

  return result
}
