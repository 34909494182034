import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { Empty, Skeleton, SkeletonText } from '@thesisedu/react'
import { styled, Block, Body, HSpaced, Space, BodySmall, BodyLarger } from '@thesisedu/web'
import { BookmarkBook, NavArrowRight } from 'iconoir-react'
import React from 'react'
import { Link } from 'react-router-dom'

import { CourseFragment, OrderDirection, useListCoursesQuery } from '../schema'

export function NewExploreCoursesWidget() {
  const { data, loading } = useListCoursesQuery({
    variables: {
      first: 50,
      orderBy: 'weight',
      orderDirection: OrderDirection.Asc,
      isByoc: false,
    },
  })
  const courses = data?.courses.edges.map(edge => edge.node) || []
  const coursesWithCallout = courses.filter(course => !!course.basicConfiguration?.calloutText)
  const sortedCourses = [
    ...coursesWithCallout,
    ...courses.filter(course => !course.basicConfiguration?.calloutText),
  ]
  return (
    <>
      <Block marginBottom={'@size-s'} marginLeft={'@size-xs'} marginRight={'@size-xs'}>
        <HSpaced align={'center'}>
          <Body color={'@text-color-secondary'}>Courses in your license</Body>
          <Space />
          <Body>
            <a href={'/teacher/resources'} target={'_blank'}>
              Get More Courses
            </a>
          </Body>
        </HSpaced>
      </Block>
      {courses.length || loading ? (
        <GridContainer>
          {loading
            ? new Array(12).fill('').map((_, index) => <CourseListItemLoading key={index} />)
            : sortedCourses.map(course => <CourseListItem course={course} key={course.id} />)}
        </GridContainer>
      ) : (
        <Empty description={'There are no courses in your license.'} />
      )}
    </>
  )
}

const PREFIXES = ['Principles of', 'Introduction to']
function splitCoursePreText(name: string): [string | null, string] {
  for (const prefix of PREFIXES) {
    if (name.startsWith(prefix)) {
      return [prefix, name.replace(`${prefix} `, '')]
    }
  }

  return [null, name]
}

interface CourseListItemProps {
  course: CourseFragment
}
function CourseListItem({ course }: CourseListItemProps) {
  const { filename } = getFileInfo(course.basicConfiguration.icon)
  const name = course.label || course.name
  const specialText: string | undefined | null = course.basicConfiguration.calloutText
  const [smallText, largeText] = specialText ? [null, name] : splitCoursePreText(name)
  return (
    <Link to={`courses/${course.id}`}>
      <ListItemContainer>
        {filename ? (
          <ListItemImageContainer>
            <img src={filename} alt={`Image for ${name}`} />
          </ListItemImageContainer>
        ) : (
          <ListItemImageContainer>
            <BookmarkBook />
          </ListItemImageContainer>
        )}
        <ListItemContentContainer>
          {smallText || specialText ? (
            <BodySmall color={specialText ? '@primary-color' : '@text-color-secondary'}>
              {specialText || smallText}
            </BodySmall>
          ) : null}
          <BodyLarger>{largeText}</BodyLarger>
        </ListItemContentContainer>
        <BodySmall color={'@text-color-secondary'}>
          <NavArrowRight />
        </BodySmall>
      </ListItemContainer>
    </Link>
  )
}

function CourseListItemLoading() {
  return (
    <ListItemContainer style={{ pointerEvents: 'none' }}>
      <ListItemImageContainer>
        <Skeleton fill />
      </ListItemImageContainer>
      <ListItemContentContainer>
        <SkeletonText as={BodySmall} />
        <SkeletonText as={BodyLarger} />
      </ListItemContentContainer>
      <BodySmall color={'@text-color-secondary'}>
        <NavArrowRight />
      </BodySmall>
    </ListItemContainer>
  )
}

const ListItemImageContainer = styled.div`
  height: 50px;
  width: 50px;
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@gray-2']};
  color: ${props => props.theme['@gray-5']};
  font-size: ${props => props.theme['@size-m']};
  overflow: hidden;
  flex-shrink: 0;
  transform: translateZ(0);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`
const ListItemContentContainer = styled.div`
  margin-left: ${props => props.theme['@size-s']};
  margin-right: ${props => props.theme['@size-xs']};
  flex: 1;
  min-width: 0;
  > * {
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:first-child {
    margin-left: 0;
  }
`
const ListItemContainer = styled.div`
  background: transparent;
  padding: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  transition: background 0.1s linear;
  display: flex;
  align-items: center;
  * {
    color: ${props => props.theme['@gray-6']};
    transition: color 0.1s linear;
  }
  &:hover {
    background: ${props => props.theme['@gray-1']};
    * {
      color: ${props => props.theme['@gray-7']};
    }
  }
`
const GridContainer = styled.div`
  display: grid;
  gap: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-l']};
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
`
