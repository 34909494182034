import {
  ClassSelectorText,
  NO_CLASS_OPTION,
  SelectedClassSync,
  useSelectedClassId,
} from '@thesisedu/feature-classes-react'
import { Body, LoadingIndicator, NotFound } from '@thesisedu/react'
import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { useParams } from 'react-router-dom'

import { ClassRouteWithClass } from './ClassRouteWithClass'
import { useClassCourseQuery } from '../../../schema'

function ClassName() {
  return (
    <Body color={'@primary-color'} style={{ display: 'inline' }}>
      <ClassSelectorText />
    </Body>
  )
}

export interface ClassRouteProps {
  group: string
}
export function ClassRoute({ group }: ClassRouteProps) {
  useBreadcrumb({ title: <ClassName /> })
  const params = useParams()
  const _selectedClassId = useSelectedClassId()
  const selectedClassId = params.classId || _selectedClassId
  const { data, loading } = useClassCourseQuery({
    variables: { id: selectedClassId || '' },
    skip: !selectedClassId || selectedClassId === NO_CLASS_OPTION,
  })
  const cls = data?.node?.__typename === 'Class' ? data.node : undefined
  const idsMatch = selectedClassId === cls?.id && selectedClassId === _selectedClassId
  let content: React.ReactElement | null
  if (loading || !idsMatch) {
    content = <LoadingIndicator block />
  } else if (cls) {
    content = <ClassRouteWithClass cls={cls} group={group} />
  } else {
    content = <NotFound description={'That class could not be found.'} />
  }

  return (
    <>
      <SelectedClassSync />
      {content}
    </>
  )
}
