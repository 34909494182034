import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Block, styled, s } from '@thesisedu/ui'
import React from 'react'

import { useQuestionWidgetQuestion } from './QuestionWidgetContext'
import { QuestionConfig } from './common'
import { useQuestionListContext } from '../../../contexts/QuestionListContext'
import { useQuestionContainerContext } from '../../../edit/QuestionContainerContext'
import { QuestionDraggable } from '../../../edit/QuestionDraggable'
import { useAssignmentQuestionsContext } from '../../../questions/AssignmentQuestionsContext'
import { Question as QuestionC } from '../../../questions/Question'
import { QuestionHeader } from '../../../questions/QuestionHeader'

export function QuestionElement({
  editor,
  id,
  node,
  readOnly,
}: SimpleWidget.SimpleWidgetProps<QuestionConfig>) {
  const { question, questionIndex, totalQuestions } = useQuestionWidgetQuestion(id)
  const existingListContext = useQuestionListContext(false)
  const editContext = useQuestionContainerContext(false)
  const readOnlyContext = useAssignmentQuestionsContext(false)
  if (question) {
    const questionProps = {
      className: 'no-widget-styles',
      question,
    }
    if (readOnly) {
      const headerProps = readOnlyContext?.questionHeaderProps
        ? readOnlyContext.questionHeaderProps(question)
        : undefined
      return (
        <Container
          bottom={'xl'}
          className={'question-container'}
          data-testid={`QuestionIndex ${questionIndex || 0}`}
        >
          {headerProps || !readOnlyContext ? (
            <QuestionHeader
              index={questionIndex || 0}
              name={question.name}
              totalQuestions={totalQuestions}
              {...headerProps}
            />
          ) : null}
          <QuestionC
            readOnly
            containerId={editor._config.namespace}
            {...questionProps}
            {...readOnlyContext?.questionProps(question)}
          />
          {readOnlyContext?.questionFooter(question)}
        </Container>
      )
    } else if (editContext) {
      return (
        <QuestionDraggable
          containerId={editor._config.namespace}
          index={questionIndex || 0}
          className={'no-widget-styles'}
          question={question}
          totalQuestions={totalQuestions}
          onAddAfter={
            existingListContext?.singleQuestionMode
              ? undefined
              : questionType => editContext.onAdd(questionType, question.id)
          }
          onChange={changes => editContext.onChange(question.id, changes)}
          onDelete={() => {
            editContext.onRemove(question.id)
          }}
          onClone={() => {
            editContext.onClone(question.id)
          }}
        />
      )
    } else return null
  } else return null
}

const Container = styled(Block)`
  font-size: ${s.size('font.m')};
  line-height: ${s.var('lineHeights.default')};
  .fteditor-root > * {
    max-width: none !important;
  }
`
