import { InMemoryCache } from '@apollo/client'
import { FeaturePreviewReactFeature } from '@thesisedu/feature-feature-preview-react'
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist'
import localForage from 'localforage'

import { ApolloReactFeature } from './ApolloReactFeature'
import { PREVIEW_PERSISTED_CACHE } from './constants'

export async function persist(feature: ApolloReactFeature, cache: InMemoryCache) {
  const options = feature.options.persistence
  if (options && !options.disabled) {
    if (options.hideBehindPreview) {
      const previewFeature = feature.root.getFeature<FeaturePreviewReactFeature>(
        '@thesisedu/feature-feature-preview-react',
      )
      if (!previewFeature || !(await previewFeature.hasFeaturePreview(PREVIEW_PERSISTED_CACHE))) {
        return
      }
    }
    const persistor = new CachePersistor({
      cache,
      storage: new LocalForageWrapper(localForage),
      maxSize: false,
      debug: options.debug,
      key: options.persistKey,
    })
    const currentVersion = localStorage.getItem(options.schemaVersionKey)
    if (currentVersion === options.schemaVersion) {
      // If the schema version matches, restore the cached data.
      await persistor.restore()
    } else {
      // If the schema version does not match, remove the cached data and reset the version.
      await persistor.purge()
      localStorage.setItem(options.schemaVersionKey, options.schemaVersion)
    }
  }
}
