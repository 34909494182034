/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String']['input'];
};

export enum AccountRegion {
  UsEast_1 = 'US_EAST_1',
  UsWest_1 = 'US_WEST_1'
}

export type AnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AnsweredQuestionContentInput = {
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type AnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum AnsweredQuestionCreator {
  Me = 'ME'
}

export type AnsweredQuestionInput = {
  content?: InputMaybe<AnsweredQuestionContentInput>;
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postName?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AnsweredQuestionStatus>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
  vodId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible statuses for an answered question */
export enum AnsweredQuestionStatus {
  /** The question has been accepted, but we haven't started responding yet */
  Accepted = 'ACCEPTED',
  /** The question has just been submitted */
  New = 'NEW',
  /** We have completed our response and published it */
  Published = 'PUBLISHED',
  /** The question has been denied, for whatever reason */
  Rejected = 'REJECTED',
  /** We are preparing our response */
  Responding = 'RESPONDING'
}

/** Filters available when querying for answered questions. */
export type AnsweredQuestionsFilters = {
  /** Filter based on questions created by specific contributors. */
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter based on who created the question. */
  creator?: InputMaybe<AnsweredQuestionCreator>;
  /** Filter based on the status of the question. */
  status?: InputMaybe<Array<AnsweredQuestionStatus>>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
};

export type ApproveCommentInput = {
  id: Scalars['ID']['input'];
};

export type ApproveTrialRequestInput = {
  approval: TrialRequestApproval;
  id: Scalars['ID']['input'];
  noEmail?: Scalars['Boolean']['input'];
};

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID']['input'];
};

/**
 * Specify either assignedStudents or assignedClasses, depending on the assignment mode for the
 * assignment. You may not use assignedStudents if the mode is ALL_STUDENTS.
 */
export type AssignInput = {
  assignedClasses?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedStudents?: InputMaybe<Array<ClassStudentInput>>;
};

export type AssignLessonInput = {
  assignments: Array<LessonAssociationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssignmentAutoGradeMode {
  AllQuestions = 'ALL_QUESTIONS',
  None = 'NONE',
  SomeQuestions = 'SOME_QUESTIONS'
}

export type AssignmentCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gradeWeight?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentClassConfigurationInput = {
  gradingMode?: InputMaybe<ClassGradingMode>;
};

export enum AssignmentExcludedFilter {
  Both = 'BOTH',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum AssignmentFeature {
  Attempts = 'ATTEMPTS',
  Groups = 'GROUPS'
}

export type AssignmentFiltersInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dueDate?: InputMaybe<DueDateFilterInput>;
  /** What exclusion status of assignments should be included? */
  excluded?: InputMaybe<AssignmentExcludedFilter>;
  /**
   * If this is true, only generated assignments are included. This is the same as passing
   * all available generated providers to the generatedProviders option.
   */
  generated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The identifiers for generated-assignment providers to filter on. */
  generatedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  missing?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<TagsFilter>;
  ungraded?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC'
}

export type AssignmentInput = {
  assign?: InputMaybe<AssignInput>;
  assignmentCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignmentMode?: InputMaybe<AssignmentStudentAssignmentMode>;
  assignmentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueAtOffset?: InputMaybe<Scalars['Int']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  gradingMode?: InputMaybe<AssignmentGradingMode>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openAt?: InputMaybe<Scalars['DateTime']['input']>;
  openAtOffset?: InputMaybe<Scalars['Int']['input']>;
  revealAnswers?: InputMaybe<RevealAnswers>;
  revealAttempts?: InputMaybe<Scalars['Int']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
  submissionType?: InputMaybe<SubmissionType>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  totalPoints?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentRubricCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  presets?: InputMaybe<Array<AssignmentRubricPresetInput>>;
  totalPoints: Scalars['Float']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentRubricInput = {
  categories: Array<AssignmentRubricCategoryInput>;
};

export type AssignmentRubricPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS'
}

export type AssignmentTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  configurationChanges?: InputMaybe<Scalars['JSON']['input']>;
  defaults?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AssociatedClassAssignmentInput = {
  /** The class ID. */
  id: Scalars['ID']['input'];
  overrides?: InputMaybe<AssignmentInput>;
  segmentId: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type AttachClassSyncInput = {
  /** The ID of the class to sync. */
  id: Scalars['ID']['input'];
  /** The name of the class on the provider. */
  label: Scalars['String']['input'];
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String']['input'];
};

export type AttachClassSyncStudentAccountInput = {
  /** The current class ID to attach the student to. */
  id: Scalars['ID']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  userInfo: BasicUserInput;
};

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String']['input'];
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String']['input'];
};

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The original name of the file. */
  name: Scalars['String']['input'];
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput;
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float']['input'];
};

export type AutoGradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export enum AutoGradingStatus {
  /** Automatic grading failed for this question. */
  Failed = 'FAILED',
  /** The question has been graded already. */
  Graded = 'GRADED',
  /** The question is currently being graded. */
  Grading = 'GRADING'
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String']['input'];
};

export type AwardBadgeInput = {
  /** The ID of the badge to award. */
  id: Scalars['ID']['input'];
  /** The IDs of the users to award the badge to. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export enum BadgeEventType {
  Badge = 'badge',
  Interaction = 'interaction',
  Segment = 'segment'
}

export type BadgeInput = {
  awardedText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<BadgeEventType>;
  eventConfig?: InputMaybe<Scalars['JSON']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BasicUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BuiltSegmentInput = {
  /**
   * If this is false, the studentContent and teacherContent are not included for this
   * segment. See Segment.hydrated for how to fetch the hydrated segment if this is
   * false.
   */
  hydrated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID']['input'];
};

export type CancelLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
};

/** Reasons a trial request might not be able to be approved. */
export enum CantApproveTrialRequestReason {
  /** This user already belongs to a non-trial license. */
  BelongsToNonTrialLicense = 'BELONGS_TO_NON_TRIAL_LICENSE',
  /** A deleted user already exists with this email address. */
  DeletedUserExists = 'DELETED_USER_EXISTS'
}

export type CanvasAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type CanvasHealthInput = {
  consumerKey?: InputMaybe<Scalars['String']['input']>;
  redirectUri: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CanvasIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT'
}

/** This is deprecated; use the class configuration instead. */
export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS'
}

export type ClassGroupPresetInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  submissionType?: InputMaybe<GroupSubmissionType>;
};

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID']['input'];
};

export type ClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segmentMetadata?: InputMaybe<Scalars['JSON']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ClassReportInput = {
  classIds: Array<Scalars['ID']['input']>;
  igNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ClassRole {
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type ClassStudentInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type ClassSyncHistoryFilters = {
  /**
   * If you want to filter the results to match only specific class
   * sync IDs.
   */
  classSyncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<ClassSyncHistoryStatus>>;
  /**
   * If you want to filter the results to match only specific assignment
   * or material sync IDs.
   */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Array<ClassSyncHistoryType>>;
};

export enum ClassSyncHistoryStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export enum ClassSyncHistoryType {
  /** The sync created or updated an assignment without touching the grades. */
  Basic = 'BASIC',
  /** The sync was in response to a grade change and only updated the grade. */
  OnlyGrades = 'ONLY_GRADES',
  /** This was an assignment submit event. */
  Submit = 'SUBMIT',
  /** The sync either did not exist or touched both the basic metadata and grades. */
  WithGrades = 'WITH_GRADES'
}

export type ClassSyncProviderConfigurationInput = {
  enabledFeatures?: InputMaybe<Array<ClassSyncProviderFeature>>;
  providerIdentifier: Scalars['String']['input'];
};

export enum ClassSyncProviderFeature {
  GradeMaterialSync = 'GRADE_MATERIAL_SYNC',
  Rostering = 'ROSTERING'
}

export type ClassSyncRosterActionInput = {
  mode: ClassSyncRosterActionMode;
  providerStudentId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Types of actions to take when rostering students. */
export enum ClassSyncRosterActionMode {
  /** Import the student from the provider into Feature. */
  Import = 'IMPORT',
  /** Match a provider student with an existing Feature student. */
  Match = 'MATCH',
  /** Remove an existing Feature student from the class. */
  Remove = 'REMOVE'
}

export type ClassTemplatesFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sharingTypes?: InputMaybe<Array<SharingType>>;
};

export type ClassesReportDimensionInput = {
  classIds: Array<Scalars['ID']['input']>;
};

export type CommentAddedInput = {
  /** The ID of the entity to listen to for new comments. */
  id: Scalars['ID']['input'];
};

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED'
}

export type CommentInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentOnNodeInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** The ID of the node to comment on. */
  id: Scalars['ID']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentsFilterInput = {
  /** If this is true, only shows comments for the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is passed, filters comments for those made by the specified user ID. */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ConfirmationInput = {
  clearGradesWhenChangingAssignmentRubric?: InputMaybe<Scalars['Boolean']['input']>;
  overwriteClassTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentVisitedReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID']['input'];
};

export type CopySegmentAsOwnInput = {
  /** The ID of the class the teacher is working in. */
  id: Scalars['ID']['input'];
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
};

export type CopySegmentSettingsInput = {
  /** The IDs of the classes to copy this information to. */
  destinationIds: Array<Scalars['ID']['input']>;
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CopyTagsToChildrenInput = {
  /** The ID of the course version you are currently viewing. */
  courseVersionId: Scalars['ID']['input'];
  /** The ID of the segment to copy the tags to its children. */
  id: Scalars['ID']['input'];
};

export type CourseInput = {
  classConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isByoc?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CourseVersionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CourseViewWeightInput = {
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
  /** The new weight for that segment. */
  weight: Scalars['Float']['input'];
};

export type CreateAnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateAnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: RegisterInput;
};

export type CreateAssignmentCategoryInput = {
  category: AssignmentCategoryInput;
  /** The class ID */
  id: Scalars['ID']['input'];
};

export type CreateAssignmentInput = {
  assignment: AssignmentInput;
};

export type CreateCheckoutSessionInput = {
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String']['input'];
  /** The class to create a checkout session for. */
  id: Scalars['ID']['input'];
  /** The URL to redirect to on success. */
  successUrl: Scalars['String']['input'];
};

export type CreateClassFromTemplateInput = {
  /** The template to create a class from. */
  id: Scalars['ID']['input'];
  /** The name of the class. */
  name: Scalars['String']['input'];
};

export type CreateClassGroupPresetInput = {
  /** The ID of the class to associate the preset with. */
  id: Scalars['ID']['input'];
  preset: ClassGroupPresetInput;
};

export type CreateClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateClassWithPlacedContentInput = {
  class: ClassInput;
  segmentId: Scalars['ID']['input'];
};

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID']['input'];
  /** The name of the new version to create. */
  name: Scalars['String']['input'];
};

export type CreateDirectMessageThreadInput = {
  /** The block-based message to send. */
  blockMessage: Scalars['JSON']['input'];
  /** An array of user IDs to send the message to. */
  recipientIds: Array<Scalars['ID']['input']>;
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the thread. */
  subject: Scalars['String']['input'];
};

export type CreateDiscussionThreadInput = {
  /** The ID of the discussion to create the thread in. */
  id: Scalars['ID']['input'];
  thread: DiscussionThreadInput;
};

export type CreateSavedReportInput = {
  name: Scalars['String']['input'];
  report: Scalars['JSON']['input'];
};

export type CreateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type CreateStudentInput = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  /** This is only required if the provider requires it. */
  signedUploadInput?: InputMaybe<SignedUploadInput>;
  sizeInBytes: Scalars['Int']['input'];
};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteAssignmentCategoryInput = {
  /** The ID of the assignment category to delete. */
  id: Scalars['ID']['input'];
  /**
   * The ID of a category inside the same class to move all assignments to that
   * belong to the category being deleted.
   */
  replacementCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID']['input'];
};

export type DetailedLogsForUserInput = {
  /** Filter by the name of the function handler, rather than displaying all handlers. */
  handler?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to get logs for. */
  id: Scalars['ID']['input'];
  /** The JWT of the administration user. */
  jwt: Scalars['String']['input'];
  /** Any additional tags to filter by. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DirectMessageThreadsFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Other features are supposed to add to this later... */
  noop?: InputMaybe<Scalars['String']['input']>;
};

export type DiscussionThreadInput = {
  content: Scalars['JSON']['input'];
};

export type DiscussionThreadsFilterInput = {
  /**
   * If selected, only returns discussion threads either created by the passed user, or with
   * comments from the passed user.
   */
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If this is true, only shows discussion threads created by the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistrictConfigurationInput = {
  /** Provide default values for all sites inside this district. */
  defaultSiteConfiguration?: InputMaybe<SiteConfigurationInput>;
};

export type DistrictInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<DistrictConfigurationInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DueDateFilterInput = {
  /** Defaults to the current date if not specified. */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  type: DueDateFilterType;
};

export enum DueDateFilterType {
  After = 'AFTER',
  Before = 'BEFORE'
}

export enum DuplicateUserPendingStudentClassResolution {
  /**
   * These are not the same students, so the student should be accepted into
   * the class but should not be merged into the matchedStudentId.
   */
  Accept = 'ACCEPT',
  /**
   * Ignore this request; the student should not be added to the class and the
   * students should not be merged.
   */
  Decline = 'DECLINE',
  /**
   * These are actually the same students, and the current student should be
   * merged into the matchedStudentId, and then the current student should be
   * deleted.
   */
  Merge = 'MERGE'
}

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FinishUploadingVideoOnDemandInput = {
  /** The ID of the video on demand you just finished uploading. */
  vodId: Scalars['ID']['input'];
};

export type ForceResyncAssignmentSyncInput = {
  /** The ID of the assignment sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForceResyncMaterialSyncInput = {
  /** The ID of the material sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForfeitAssignmentInput = {
  classId: Scalars['ID']['input'];
  forfeit: Scalars['Boolean']['input'];
  /** The ID of the assignment to forfeit. */
  id: Scalars['ID']['input'];
  /** If modifying as a teacher, the ID of the student to modify. */
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type FreeUsernameInput = {
  /** The username or email address you would like to free. */
  username: Scalars['String']['input'];
};

export type GenerateCanvasCredentialsTokenInput = {
  districtId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GenerateGroupMode {
  MaximumCollaboration = 'MAXIMUM_COLLABORATION',
  Random = 'RANDOM'
}

export enum GenerateLeaderMode {
  LeadershipBoost = 'LEADERSHIP_BOOST',
  Random = 'RANDOM'
}

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID']['input'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type GenerateStudentsInput = {
  count: Scalars['Int']['input'];
  /** The ID of the class to generate students for. */
  id: Scalars['ID']['input'];
};

export type GenerateVodCaptionsInput = {
  /** The ID of the video to generate captions for. */
  id: Scalars['ID']['input'];
};

export type GeneratedGroupsConfigurationInput = {
  /** The logic to use when grouping students. */
  groupMode: GenerateGroupMode;
  /** The logic to use when choosing leaders. */
  leaderMode: GenerateLeaderMode;
  /** The number of groups to generate. */
  numGroups: Scalars['Int']['input'];
  /** The students to include in the groups. */
  students: Array<ClassStudentInput>;
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type GradeAssignmentSubmissionInput = {
  comments: Scalars['JSON']['input'];
  grades: Scalars['JSON']['input'];
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export type GradeReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupSubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL'
}

export type ImportCourseViewWeightsInput = {
  /** The ID of the course view to update weights for. */
  courseViewId: Scalars['ID']['input'];
  /**
   * If this is true, the update will be done in the foreground
   * instead of via a background job.
   */
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new weights to import. */
  weights: Array<CourseViewWeightInput>;
};

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID']['input'];
  /** The ID of the source to use. */
  sourceId: Scalars['String']['input'];
};

export type ImportSegmentsInput = {
  /** The contents of the CSV to use for imports. */
  csvContent: Scalars['String']['input'];
  /**
   * If this is true, new tags will not be created. So if you specify
   * a tag that has not yet been registered in the system, it won't
   * be added to that content.
   */
  dontCreateTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** How should we process tags when importing? */
  tagsMode: ImportSegmentsTagsMode;
  /**
   * If this is true, the segment ID column is required, and the label
   * of the segment will be updated when imported.
   */
  updateLabel?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If this is true, the segment ID column is required, and the name
   * of the segment will be updated when imported.
   */
  updateName?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ImportSegmentsTagsMode {
  /** Tags will be appended to the existing tags. */
  Append = 'APPEND',
  /** No tags will be imported. */
  Ignore = 'IGNORE',
  /**
   * The existing tags (in the specified types) will be replaced completely.
   * If you pass a "COURSE" tag of "Course A" and the content already has a
   * "COURSE" tag of "Course B", the "Course B" tag will be removed.
   * If the content already had an "IMPLEMENTATION_GUIDE" tag but you didn't
   * specify a new one in the sheet, the existing one will be kept.
   */
  Replace = 'REPLACE'
}

export type ImportStudentsInput = {
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String']['input'];
  /** The ID of the class to import the students to. */
  id: Scalars['ID']['input'];
};

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String']['input'];
};

export type InteractionInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
  reference?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export enum InviteResult {
  AddedToClass = 'ADDED_TO_CLASS',
  InvitedToPlatform = 'INVITED_TO_PLATFORM'
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID']['input'];
  /** The student ID to import. */
  studentId: Scalars['String']['input'];
  /** The teacher ID to import. */
  teacherId: Scalars['String']['input'];
};

export type InviteStudentInput = {
  /** The email address of the student to invite. */
  email: Scalars['String']['input'];
  /** The ID of the class to invite the student to. */
  id: Scalars['ID']['input'];
};

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID']['input'];
  /** Any default permissions to apply to the teacher. */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID']['input'];
};

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String']['input'];
};

export type LessonAssociationInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LessonInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LessonsAssignedStudentPairInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LicenseInput = {
  courses?: InputMaybe<Scalars['JSON']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  monthlyPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontMonths?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<LicenseType>;
};

export type LicenseSubscriptionInput = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  nextValidityCheck?: InputMaybe<Scalars['DateTime']['input']>;
  size?: InputMaybe<LicenseSubscriptionSize>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LicenseSubscriptionType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum LicenseSubscriptionSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum LicenseSubscriptionType {
  Individual = 'INDIVIDUAL',
  StudentPay = 'STUDENT_PAY'
}

export enum LicenseType {
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
  Trial = 'TRIAL'
}

export type LikeNodeInput = {
  /** The ID of the node to like. */
  id: Scalars['ID']['input'];
};

export type ListSyncProviderClassesInput = {
  /** If you would like to have a class automatically suggested, pass in the class ID. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  provider: Scalars['String']['input'];
};

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput;
  /** An optional Canvas integration to use, for anonymous access. */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginWithCleverInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithGoogleInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithMsalInput = {
  authCode?: InputMaybe<MsalAuthCodeInput>;
  tokens?: InputMaybe<MsalTokensInput>;
};

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput;
};

export type MsalAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type MsalIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export type MsalTokensInput = {
  accessToken: Scalars['String']['input'];
  expiresAt: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type MediaBookmarkInput = {
  endSeconds: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startSeconds: Scalars['Float']['input'];
};

export type MediaInput = {
  metadata?: InputMaybe<MediaMetadataInput>;
  status?: InputMaybe<MediaStatus>;
};

export type MediaMetadataInput = {
  bookmarks?: InputMaybe<Array<MediaBookmarkInput>>;
};

export enum MediaStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED'
}

export enum MediaType {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type MediaUploadUrlInput = {
  /** An optional class to associate the media with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  extension: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  region?: InputMaybe<AccountRegion>;
  requestMode?: InputMaybe<UploadRequestMode>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type MoveStudentClassInput = {
  /**
   * Whether or not to force the move. This typically means some warnings were given, and
   * you acknowledge them and would like to continue anyway.
   */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the source class. */
  fromClassId: Scalars['ID']['input'];
  /** The ID of the student. */
  id: Scalars['ID']['input'];
  /** The ID of the destination class. */
  toClassId: Scalars['ID']['input'];
};

export type MoveTeacherInput = {
  id: Scalars['ID']['input'];
  toSite: Scalars['ID']['input'];
};

export type NumAttemptsMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PendingStudentClassReason {
  DuplicateUser = 'DUPLICATE_USER',
  /** Don't use this. */
  Placeholder = 'PLACEHOLDER'
}

export enum PendingStudentClassState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type PendingStudentClassesFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reasons?: InputMaybe<Array<PendingStudentClassReason>>;
  states?: InputMaybe<Array<PendingStudentClassState>>;
  studentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum Permission {
  AcceptInvite = 'ACCEPT_INVITE',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  AssignLesson = 'ASSIGN_LESSON',
  AssignOwnLesson = 'ASSIGN_OWN_LESSON',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  CancelLicenseSubscription = 'CANCEL_LICENSE_SUBSCRIPTION',
  CancelOwnLicenseSubscription = 'CANCEL_OWN_LICENSE_SUBSCRIPTION',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnDiscussionThread = 'COMMENT_ON_OWN_DISCUSSION_THREAD',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  CopyCourseContentFrom = 'COPY_COURSE_CONTENT_FROM',
  CopyCourseContentTo = 'COPY_COURSE_CONTENT_TO',
  CopyOwnCourseContentFrom = 'COPY_OWN_COURSE_CONTENT_FROM',
  CopyOwnCourseContentTo = 'COPY_OWN_COURSE_CONTENT_TO',
  CopyOwnSegmentAsOwn = 'COPY_OWN_SEGMENT_AS_OWN',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  CopySegmentAsOwn = 'COPY_SEGMENT_AS_OWN',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CreateAllRubricTemplate = 'CREATE_ALL_RUBRIC_TEMPLATE',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateAssignmentTemplate = 'CREATE_ASSIGNMENT_TEMPLATE',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateClass = 'CREATE_CLASS',
  CreateClassFromOwnTemplate = 'CREATE_CLASS_FROM_OWN_TEMPLATE',
  CreateClassFromSharedTemplate = 'CREATE_CLASS_FROM_SHARED_TEMPLATE',
  CreateClassFromTemplate = 'CREATE_CLASS_FROM_TEMPLATE',
  CreateCourse = 'CREATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  CreateDiscussionThread = 'CREATE_DISCUSSION_THREAD',
  CreateDistrict = 'CREATE_DISTRICT',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateLesson = 'CREATE_LESSON',
  CreateLicense = 'CREATE_LICENSE',
  CreateLicenseSubscription = 'CREATE_LICENSE_SUBSCRIPTION',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateOwnAssignment = 'CREATE_OWN_ASSIGNMENT',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  CreateOwnDiscussionThread = 'CREATE_OWN_DISCUSSION_THREAD',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  CreatePractice = 'CREATE_PRACTICE',
  CreateSavedReport = 'CREATE_SAVED_REPORT',
  CreateSite = 'CREATE_SITE',
  CreateStudent = 'CREATE_STUDENT',
  CreateTeacher = 'CREATE_TEACHER',
  CreateUser = 'CREATE_USER',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  DeleteAnsweredQuestion = 'DELETE_ANSWERED_QUESTION',
  DeleteAnsweredQuestionContributor = 'DELETE_ANSWERED_QUESTION_CONTRIBUTOR',
  DeleteClass = 'DELETE_CLASS',
  DeleteClassGroupPreset = 'DELETE_CLASS_GROUP_PRESET',
  DeleteClassTemplate = 'DELETE_CLASS_TEMPLATE',
  DeleteComment = 'DELETE_COMMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteDiscussion = 'DELETE_DISCUSSION',
  DeleteDiscussionThread = 'DELETE_DISCUSSION_THREAD',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteLesson = 'DELETE_LESSON',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteLicenseSubscription = 'DELETE_LICENSE_SUBSCRIPTION',
  DeleteMedia = 'DELETE_MEDIA',
  DeleteOwnAnnouncement = 'DELETE_OWN_ANNOUNCEMENT',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  DeleteOwnClassGroupPreset = 'DELETE_OWN_CLASS_GROUP_PRESET',
  DeleteOwnClassSync = 'DELETE_OWN_CLASS_SYNC',
  DeleteOwnClassTemplate = 'DELETE_OWN_CLASS_TEMPLATE',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  DeleteOwnDirectMessageThread = 'DELETE_OWN_DIRECT_MESSAGE_THREAD',
  DeleteOwnDiscussion = 'DELETE_OWN_DISCUSSION',
  DeleteOwnDiscussionThread = 'DELETE_OWN_DISCUSSION_THREAD',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  DeleteOwnLesson = 'DELETE_OWN_LESSON',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  DeleteOwnLicenseSubscription = 'DELETE_OWN_LICENSE_SUBSCRIPTION',
  DeleteOwnMedia = 'DELETE_OWN_MEDIA',
  DeleteOwnPractice = 'DELETE_OWN_PRACTICE',
  DeleteOwnRubricTemplate = 'DELETE_OWN_RUBRIC_TEMPLATE',
  DeleteOwnSavedReport = 'DELETE_OWN_SAVED_REPORT',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  DeletePractice = 'DELETE_PRACTICE',
  DeleteSavedReport = 'DELETE_SAVED_REPORT',
  DeleteSegment = 'DELETE_SEGMENT',
  DeleteSite = 'DELETE_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteStandardSet = 'DELETE_STANDARD_SET',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteTag = 'DELETE_TAG',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  DeleteTrialRequestCampaign = 'DELETE_TRIAL_REQUEST_CAMPAIGN',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteWidgetTemplate = 'DELETE_WIDGET_TEMPLATE',
  DownloadLesson = 'DOWNLOAD_LESSON',
  DownloadOwnLesson = 'DOWNLOAD_OWN_LESSON',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  ForceResyncAssignmentSync = 'FORCE_RESYNC_ASSIGNMENT_SYNC',
  ForceResyncMaterialSync = 'FORCE_RESYNC_MATERIAL_SYNC',
  ForceResyncOwnAssignmentSync = 'FORCE_RESYNC_OWN_ASSIGNMENT_SYNC',
  ForceResyncOwnMaterialSync = 'FORCE_RESYNC_OWN_MATERIAL_SYNC',
  FreeUsername = 'FREE_USERNAME',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  GeneratePdf = 'GENERATE_PDF',
  GetPageMetadata = 'GET_PAGE_METADATA',
  GradeAssignmentSubmission = 'GRADE_ASSIGNMENT_SUBMISSION',
  GradeOwnAssignmentSubmission = 'GRADE_OWN_ASSIGNMENT_SUBMISSION',
  Impersonate = 'IMPERSONATE',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  InviteStudent = 'INVITE_STUDENT',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  JoinClass = 'JOIN_CLASS',
  ListAnsweredQuestions = 'LIST_ANSWERED_QUESTIONS',
  ListBadges = 'LIST_BADGES',
  ListBadgeUsers = 'LIST_BADGE_USERS',
  ListClass = 'LIST_CLASS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ListClassLicenseSubscriptions = 'LIST_CLASS_LICENSE_SUBSCRIPTIONS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  ListClassSyncStudents = 'LIST_CLASS_SYNC_STUDENTS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListClassTemplates = 'LIST_CLASS_TEMPLATES',
  ListComments = 'LIST_COMMENTS',
  ListCourse = 'LIST_COURSE',
  ListCourseViews = 'LIST_COURSE_VIEWS',
  ListDistrict = 'LIST_DISTRICT',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  ListInteractions = 'LIST_INTERACTIONS',
  ListLesson = 'LIST_LESSON',
  ListLessonPractices = 'LIST_LESSON_PRACTICES',
  ListLicenses = 'LIST_LICENSES',
  ListLicenseSubscriptions = 'LIST_LICENSE_SUBSCRIPTIONS',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListOwnClassLicenseSubscriptions = 'LIST_OWN_CLASS_LICENSE_SUBSCRIPTIONS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListOwnClassSyncStudents = 'LIST_OWN_CLASS_SYNC_STUDENTS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ListOwnLessonPractices = 'LIST_OWN_LESSON_PRACTICES',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  ListOwnStudentLessons = 'LIST_OWN_STUDENT_LESSONS',
  ListOwnStudentPractices = 'LIST_OWN_STUDENT_PRACTICES',
  ListOwnTeacherLessons = 'LIST_OWN_TEACHER_LESSONS',
  ListOwnTeacherPractices = 'LIST_OWN_TEACHER_PRACTICES',
  ListOwnUserBadges = 'LIST_OWN_USER_BADGES',
  ListOwnUserLicenseSubscriptions = 'LIST_OWN_USER_LICENSE_SUBSCRIPTIONS',
  ListPractice = 'LIST_PRACTICE',
  ListRawClassSyncProviders = 'LIST_RAW_CLASS_SYNC_PROVIDERS',
  ListSegments = 'LIST_SEGMENTS',
  ListSharedLessonPractices = 'LIST_SHARED_LESSON_PRACTICES',
  ListSite = 'LIST_SITE',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListStudent = 'LIST_STUDENT',
  ListStudentLessons = 'LIST_STUDENT_LESSONS',
  ListStudentPractices = 'LIST_STUDENT_PRACTICES',
  ListTags = 'LIST_TAGS',
  ListTeacher = 'LIST_TEACHER',
  ListTeacherLessons = 'LIST_TEACHER_LESSONS',
  ListTeacherPractices = 'LIST_TEACHER_PRACTICES',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ListUser = 'LIST_USER',
  ListUserBadges = 'LIST_USER_BADGES',
  ListUserLicenseSubscriptions = 'LIST_USER_LICENSE_SUBSCRIPTIONS',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  Login = 'LOGIN',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ManageAnsweredQuestions = 'MANAGE_ANSWERED_QUESTIONS',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageAssignmentCategories = 'MANAGE_ASSIGNMENT_CATEGORIES',
  ManageAssignmentClassConfiguration = 'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageAssignmentTemplates = 'MANAGE_ASSIGNMENT_TEMPLATES',
  ManageBadges = 'MANAGE_BADGES',
  ManageBanner = 'MANAGE_BANNER',
  ManageClassGroupPresets = 'MANAGE_CLASS_GROUP_PRESETS',
  ManageClassSegmentEnabled = 'MANAGE_CLASS_SEGMENT_ENABLED',
  ManageClassSegmentLocked = 'MANAGE_CLASS_SEGMENT_LOCKED',
  ManageClassSegmentStructure = 'MANAGE_CLASS_SEGMENT_STRUCTURE',
  ManageCourseViews = 'MANAGE_COURSE_VIEWS',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageLogs = 'MANAGE_LOGS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  ManageOwnAssignmentCategories = 'MANAGE_OWN_ASSIGNMENT_CATEGORIES',
  ManageOwnAssignmentClassConfiguration = 'MANAGE_OWN_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageOwnClassGroupPresets = 'MANAGE_OWN_CLASS_GROUP_PRESETS',
  ManageOwnClassSegmentEnabled = 'MANAGE_OWN_CLASS_SEGMENT_ENABLED',
  ManageOwnClassSegmentLocked = 'MANAGE_OWN_CLASS_SEGMENT_LOCKED',
  ManageOwnClassSegmentStructure = 'MANAGE_OWN_CLASS_SEGMENT_STRUCTURE',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  ManageOwnPendingClassStudents = 'MANAGE_OWN_PENDING_CLASS_STUDENTS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ManagePendingClassStudents = 'MANAGE_PENDING_CLASS_STUDENTS',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageStandardSets = 'MANAGE_STANDARD_SETS',
  ManageTrialRequestCampaigns = 'MANAGE_TRIAL_REQUEST_CAMPAIGNS',
  ManageWidgetTemplates = 'MANAGE_WIDGET_TEMPLATES',
  MessageOwnUser = 'MESSAGE_OWN_USER',
  MessageUser = 'MESSAGE_USER',
  ModerateComments = 'MODERATE_COMMENTS',
  MoveTeacher = 'MOVE_TEACHER',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  RefreshMediaUrl = 'REFRESH_MEDIA_URL',
  RefreshOwnMediaUrl = 'REFRESH_OWN_MEDIA_URL',
  Register = 'REGISTER',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  RequestInformationForClassSyncSetup = 'REQUEST_INFORMATION_FOR_CLASS_SYNC_SETUP',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  RequestTrial = 'REQUEST_TRIAL',
  ResetAssignmentSubmission = 'RESET_ASSIGNMENT_SUBMISSION',
  ResetOwnAssignmentSubmission = 'RESET_OWN_ASSIGNMENT_SUBMISSION',
  ResetPassword = 'RESET_PASSWORD',
  RestoreAnsweredQuestion = 'RESTORE_ANSWERED_QUESTION',
  RestoreAnsweredQuestionContributor = 'RESTORE_ANSWERED_QUESTION_CONTRIBUTOR',
  RestoreClass = 'RESTORE_CLASS',
  RestoreClassGroupPreset = 'RESTORE_CLASS_GROUP_PRESET',
  RestoreClassTemplate = 'RESTORE_CLASS_TEMPLATE',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreDiscussion = 'RESTORE_DISCUSSION',
  RestoreDiscussionThread = 'RESTORE_DISCUSSION_THREAD',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreLesson = 'RESTORE_LESSON',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreLicenseSubscription = 'RESTORE_LICENSE_SUBSCRIPTION',
  RestoreMedia = 'RESTORE_MEDIA',
  RestoreOwnAnnouncement = 'RESTORE_OWN_ANNOUNCEMENT',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  RestoreOwnClassGroupPreset = 'RESTORE_OWN_CLASS_GROUP_PRESET',
  RestoreOwnClassTemplate = 'RESTORE_OWN_CLASS_TEMPLATE',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  RestoreOwnDiscussion = 'RESTORE_OWN_DISCUSSION',
  RestoreOwnDiscussionThread = 'RESTORE_OWN_DISCUSSION_THREAD',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  RestoreOwnLesson = 'RESTORE_OWN_LESSON',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  RestoreOwnLicenseSubscription = 'RESTORE_OWN_LICENSE_SUBSCRIPTION',
  RestoreOwnMedia = 'RESTORE_OWN_MEDIA',
  RestoreOwnPractice = 'RESTORE_OWN_PRACTICE',
  RestoreOwnRubricTemplate = 'RESTORE_OWN_RUBRIC_TEMPLATE',
  RestoreOwnSavedReport = 'RESTORE_OWN_SAVED_REPORT',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  RestorePractice = 'RESTORE_PRACTICE',
  RestoreSavedReport = 'RESTORE_SAVED_REPORT',
  RestoreSegment = 'RESTORE_SEGMENT',
  RestoreSite = 'RESTORE_SITE',
  RestoreStandardSet = 'RESTORE_STANDARD_SET',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreTag = 'RESTORE_TAG',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RestoreTrialRequestCampaign = 'RESTORE_TRIAL_REQUEST_CAMPAIGN',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreWidgetTemplate = 'RESTORE_WIDGET_TEMPLATE',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  RosterStudentsFromClassSync = 'ROSTER_STUDENTS_FROM_CLASS_SYNC',
  RosterStudentsFromOwnClassSync = 'ROSTER_STUDENTS_FROM_OWN_CLASS_SYNC',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  RunReport = 'RUN_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunStudentReport = 'RUN_STUDENT_REPORT',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  SaveClassTemplate = 'SAVE_CLASS_TEMPLATE',
  SaveOwnAssignmentSubmission = 'SAVE_OWN_ASSIGNMENT_SUBMISSION',
  SaveOwnClassTemplate = 'SAVE_OWN_CLASS_TEMPLATE',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  Search = 'SEARCH',
  SearchAnsweredQuestions = 'SEARCH_ANSWERED_QUESTIONS',
  SearchAnsweredQuestionContributors = 'SEARCH_ANSWERED_QUESTION_CONTRIBUTORS',
  SearchBadges = 'SEARCH_BADGES',
  SearchCourses = 'SEARCH_COURSES',
  SearchDistricts = 'SEARCH_DISTRICTS',
  SearchSites = 'SEARCH_SITES',
  SearchUsers = 'SEARCH_USERS',
  ShareNode = 'SHARE_NODE',
  ShareOwnNode = 'SHARE_OWN_NODE',
  SubmitAnsweredQuestion = 'SUBMIT_ANSWERED_QUESTION',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitMedia = 'SUBMIT_MEDIA',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  SubmitOwnMedia = 'SUBMIT_OWN_MEDIA',
  SyncAssignment = 'SYNC_ASSIGNMENT',
  SyncOwnAssignment = 'SYNC_OWN_ASSIGNMENT',
  SyncOwnSegment = 'SYNC_OWN_SEGMENT',
  SyncSegment = 'SYNC_SEGMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  UpdateAssignmentTemplate = 'UPDATE_ASSIGNMENT_TEMPLATE',
  UpdateClass = 'UPDATE_CLASS',
  UpdateClassSettings = 'UPDATE_CLASS_SETTINGS',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateCourse = 'UPDATE_COURSE',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  UpdateDiscussionThread = 'UPDATE_DISCUSSION_THREAD',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateLesson = 'UPDATE_LESSON',
  UpdateLicense = 'UPDATE_LICENSE',
  UpdateLicenseSubscription = 'UPDATE_LICENSE_SUBSCRIPTION',
  UpdateMedia = 'UPDATE_MEDIA',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateOwnAssignmentTemplate = 'UPDATE_OWN_ASSIGNMENT_TEMPLATE',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  UpdateOwnClassSettings = 'UPDATE_OWN_CLASS_SETTINGS',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  UpdateOwnDiscussionThread = 'UPDATE_OWN_DISCUSSION_THREAD',
  UpdateOwnLesson = 'UPDATE_OWN_LESSON',
  UpdateOwnMedia = 'UPDATE_OWN_MEDIA',
  UpdateOwnPractice = 'UPDATE_OWN_PRACTICE',
  UpdateOwnSavedReport = 'UPDATE_OWN_SAVED_REPORT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdatePractice = 'UPDATE_PRACTICE',
  UpdateSavedReport = 'UPDATE_SAVED_REPORT',
  UpdateSite = 'UPDATE_SITE',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateTags = 'UPDATE_TAGS',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  UpdateUser = 'UPDATE_USER',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  UploadMedia = 'UPLOAD_MEDIA',
  UseInReport = 'USE_IN_REPORT',
  UseOwnInReport = 'USE_OWN_IN_REPORT',
  UsePracticeIsViewed = 'USE_PRACTICE_IS_VIEWED',
  ViewAllRubricTemplates = 'VIEW_ALL_RUBRIC_TEMPLATES',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  ViewAnsweredQuestion = 'VIEW_ANSWERED_QUESTION',
  ViewAnsweredQuestionContributor = 'VIEW_ANSWERED_QUESTION_CONTRIBUTOR',
  ViewAssignmentAnswers = 'VIEW_ASSIGNMENT_ANSWERS',
  ViewAssignmentReport = 'VIEW_ASSIGNMENT_REPORT',
  ViewAssignmentSync = 'VIEW_ASSIGNMENT_SYNC',
  ViewAssignmentTemplate = 'VIEW_ASSIGNMENT_TEMPLATE',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewClass = 'VIEW_CLASS',
  ViewClassGroupPreset = 'VIEW_CLASS_GROUP_PRESET',
  ViewClassIdsWithSegment = 'VIEW_CLASS_IDS_WITH_SEGMENT',
  ViewClassPractices = 'VIEW_CLASS_PRACTICES',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  ViewClassSyncHistory = 'VIEW_CLASS_SYNC_HISTORY',
  ViewClassTemplate = 'VIEW_CLASS_TEMPLATE',
  ViewComment = 'VIEW_COMMENT',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewDiscussion = 'VIEW_DISCUSSION',
  ViewDiscussionThread = 'VIEW_DISCUSSION_THREAD',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewLesson = 'VIEW_LESSON',
  ViewLessonAssignments = 'VIEW_LESSON_ASSIGNMENTS',
  ViewLessonStudentViews = 'VIEW_LESSON_STUDENT_VIEWS',
  ViewLicense = 'VIEW_LICENSE',
  ViewLicenseSubscription = 'VIEW_LICENSE_SUBSCRIPTION',
  ViewMaterialSync = 'VIEW_MATERIAL_SYNC',
  ViewMedia = 'VIEW_MEDIA',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnAnnouncement = 'VIEW_OWN_ANNOUNCEMENT',
  ViewOwnAssignment = 'VIEW_OWN_ASSIGNMENT',
  ViewOwnAssignmentReport = 'VIEW_OWN_ASSIGNMENT_REPORT',
  ViewOwnAssignmentSubmission = 'VIEW_OWN_ASSIGNMENT_SUBMISSION',
  ViewOwnAssignmentSync = 'VIEW_OWN_ASSIGNMENT_SYNC',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  ViewOwnClassGroupPreset = 'VIEW_OWN_CLASS_GROUP_PRESET',
  ViewOwnClassPractices = 'VIEW_OWN_CLASS_PRACTICES',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewOwnClassSync = 'VIEW_OWN_CLASS_SYNC',
  ViewOwnClassSyncHistory = 'VIEW_OWN_CLASS_SYNC_HISTORY',
  ViewOwnClassTemplate = 'VIEW_OWN_CLASS_TEMPLATE',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewOwnCourse = 'VIEW_OWN_COURSE',
  ViewOwnDirectMessageThread = 'VIEW_OWN_DIRECT_MESSAGE_THREAD',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ViewOwnDiscussion = 'VIEW_OWN_DISCUSSION',
  ViewOwnDiscussionThread = 'VIEW_OWN_DISCUSSION_THREAD',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewOwnLesson = 'VIEW_OWN_LESSON',
  ViewOwnLessonAssignments = 'VIEW_OWN_LESSON_ASSIGNMENTS',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  ViewOwnLicenseSubscription = 'VIEW_OWN_LICENSE_SUBSCRIPTION',
  ViewOwnMaterialSync = 'VIEW_OWN_MATERIAL_SYNC',
  ViewOwnMedia = 'VIEW_OWN_MEDIA',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ViewOwnPractice = 'VIEW_OWN_PRACTICE',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewOwnRubricTemplate = 'VIEW_OWN_RUBRIC_TEMPLATE',
  ViewOwnSavedReport = 'VIEW_OWN_SAVED_REPORT',
  ViewOwnSegment = 'VIEW_OWN_SEGMENT',
  ViewOwnSite = 'VIEW_OWN_SITE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ViewOwnTag = 'VIEW_OWN_TAG',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserMedia = 'VIEW_OWN_USER_MEDIA',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  ViewOwnUseInUnownedClasses = 'VIEW_OWN_USE_IN_UNOWNED_CLASSES',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ViewPractice = 'VIEW_PRACTICE',
  ViewQuestionSegment = 'VIEW_QUESTION_SEGMENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewSavedReport = 'VIEW_SAVED_REPORT',
  ViewSegment = 'VIEW_SEGMENT',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  ViewSharedClassTemplate = 'VIEW_SHARED_CLASS_TEMPLATE',
  ViewSharedLesson = 'VIEW_SHARED_LESSON',
  ViewSharedPractice = 'VIEW_SHARED_PRACTICE',
  ViewSite = 'VIEW_SITE',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ViewStandardSet = 'VIEW_STANDARD_SET',
  ViewStudent = 'VIEW_STUDENT',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewTag = 'VIEW_TAG',
  ViewTeacher = 'VIEW_TEACHER',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  ViewTrialRequestCampaign = 'VIEW_TRIAL_REQUEST_CAMPAIGN',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserMedia = 'VIEW_USER_MEDIA',
  ViewUserName = 'VIEW_USER_NAME',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewUseInUnownedClasses = 'VIEW_USE_IN_UNOWNED_CLASSES',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewWidgetTemplate = 'VIEW_WIDGET_TEMPLATE'
}

export type PracticeInput = {
  /** Optionally the class you would like to associate the practice with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  lessonId?: InputMaybe<Scalars['ID']['input']>;
  media: MediaUploadUrlInput;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PracticePatch = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PreferencesInput = {
  /** The name of the channels to disable. */
  disabledChannels: Array<Scalars['String']['input']>;
  /** The identifier for the type of notification. */
  notificationType: Scalars['String']['input'];
};

export type PrepareCourseViewSampleSpreadsheetInput = {
  /** The ID of the course view to prepare the spreadsheet for. */
  courseViewId: Scalars['ID']['input'];
};

export type PublishCourseVersionInput = {
  id: Scalars['ID']['input'];
};

export type ReadNotificationInput = {
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};

export type ReadNotificationPathInput = {
  read: Scalars['Boolean']['input'];
  universalPath: Scalars['String']['input'];
};

export type ReadNotificationsInput = {
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: InputMaybe<Scalars['Boolean']['input']>;
  /** The notifications to update. */
  notifications?: InputMaybe<Array<ReadNotificationInput>>;
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: InputMaybe<Array<ReadNotificationPathInput>>;
};

export type RecordCanvasCredentialsInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  token: Scalars['String']['input'];
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String']['input'];
};

export type RecordInteractionsInput = {
  interactions: Array<InteractionInput>;
};

export type RecordMicrosoftTenantInput = {
  tenantId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RefreshMediaUploadUrlInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RejectCommentInput = {
  id: Scalars['ID']['input'];
  /** The reason the comment was rejected. */
  reason: Scalars['String']['input'];
};

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID']['input'];
  /** An array of user IDs to remove from the thread. */
  userIds: Array<Scalars['ID']['input']>;
};

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID']['input'];
  /** The ID of the student to remove. */
  studentId: Scalars['ID']['input'];
};

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID']['input'];
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID']['input'];
};

export type ReportDimensionInput = {
  classes?: InputMaybe<ClassesReportDimensionInput>;
  segment?: InputMaybe<SegmentReportDimensionInput>;
  students?: InputMaybe<StudentsReportDimensionInput>;
};

export type ReportFilterInput = {
  isLeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportMetricInput = {
  contentVisited?: InputMaybe<ContentVisitedReportMetricInput>;
  grade?: InputMaybe<GradeReportMetricInput>;
  numAttempts?: InputMaybe<NumAttemptsMetricInput>;
  timeOnTask?: InputMaybe<TimeOnTaskReportMetricInput>;
};

export type ReportStandardsSummaryInput = {
  standardSetId: Scalars['ID']['input'];
};

export type RequestInformationForClassSyncSetupInput = {
  /** The identifier for the class sync provider to request information for. */
  providerIdentifier: Scalars['String']['input'];
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the class to reset submissions for, optionally. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID']['input'];
  /**
   * If this is true, the latest submission (with its answers) is preserved.
   * The grade will still be reset, the created date will be reset to now, and the
   * submitted status will be reset.
   */
  preserveLatestSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentInput = {
  /** The ID of the assignment to reset. */
  id: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentSubmissionInput = {
  /** The ID of the class associated with the submission. */
  classId: Scalars['ID']['input'];
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the student associated with the submission. */
  studentId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ResolveDuplicateUserPendingStudentClassInput = {
  /** The ID of the PendingStudentClass record. */
  id: Scalars['ID']['input'];
  /** The resolution to apply. */
  resolution: DuplicateUserPendingStudentClassResolution;
};

export type RetrySubmissionInput = {
  /** The ID of the class to retry the submission in. */
  classId: Scalars['ID']['input'];
  /** The ID of the Assignment */
  id: Scalars['ID']['input'];
};

export enum RevealAnswers {
  /** Answers are revealed to students after they are finished, or after revealAttempts. */
  AfterSubmit = 'AFTER_SUBMIT',
  /** Answers are revealed to students in real time. */
  RealTime = 'REAL_TIME'
}

export type RosterStudentsFromClassSyncInput = {
  actions: Array<ClassSyncRosterActionInput>;
  /** The ID of the class sync to roster students from. */
  id: Scalars['ID']['input'];
};

export type RubricTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
};

export type RunGradeReportInput = {
  /** Optional Class IDs to filter on. */
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Optional if we want to format based on points instead of percentages. */
  showAsPoints?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RunReportInput = {
  /** Any dimensions to filter on inside the report. */
  dimensions: Array<ReportDimensionInput>;
  /** Any filters to apply to the report. */
  filters?: InputMaybe<Array<ReportFilterInput>>;
  /** Any metrics to display inside the report. */
  metrics: Array<ReportMetricInput>;
};

/**
 * Input required for creating or updating assignment submissions.
 * These are used by both teachers and students.
 */
export type SaveAssignmentSubmissionInput = {
  /** The ID of the class to create or update the submission for. */
  classId: Scalars['ID']['input'];
  /** If this is true, a new submission will be created instead of updating the existing one. */
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment to save submission data for. */
  id: Scalars['ID']['input'];
  /** Any overrides to save back to the submission. */
  overrides?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the student (defaults to the current user). */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** The submission data to save. */
  submissionData: Scalars['JSON']['input'];
  /** Whether or not to immediately submit after saving. */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is specified, this will attempt to update the specified submission ID instead. */
  updateId?: InputMaybe<Scalars['ID']['input']>;
};

export type SaveClassTemplateInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the class to save as a template. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SchoologyTokenInput = {
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<ClassStudentInput>>;
  tags?: InputMaybe<TagsFilter>;
};

export type SegmentCompletionInput = {
  /** The new complete status of these segments. */
  complete: Scalars['Boolean']['input'];
  /** The IDs of the segments to update, in GraphQL format. */
  segmentIds: Array<Scalars['ID']['input']>;
  /** The IDs of the students to update with these segment IDs, in GraphQL format. */
  studentIds: Array<Scalars['ID']['input']>;
};

export type SegmentInput = {
  /** The configuration for the segment. */
  config?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the segment. */
  id: Scalars['ID']['input'];
  /** The index for the segment. */
  index?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the segment. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of segment to create or update. */
  type?: InputMaybe<SegmentType>;
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
  /** The new tags to set for the segment. */
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
};

export type SegmentReportDimensionInput = {
  /** This is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SegmentType {
  /** eslint-disable-next-line @graphql-eslint/naming-convention */
  Assignment = 'Assignment',
  Group = 'Group',
  Reference = 'Reference',
  Root = 'Root',
  Section = 'Section',
  Term = 'Term',
  Topic = 'Topic'
}

export type SegmentsFilters = {
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
  types?: InputMaybe<Array<SegmentType>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShareNodeInput = {
  /** The ID of the node to share or unshare. */
  id: Scalars['ID']['input'];
  /** For sharing, whether this list of user ids will replace the existing shares. */
  replace?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the users to share or unshare with. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum SharingType {
  IveShared = 'IVE_SHARED',
  Own = 'OWN',
  SharedWithMe = 'SHARED_WITH_ME'
}

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String']['input'];
  /** Whether or not the upload should be public. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The mime-type of the file. */
  mimeType: Scalars['String']['input'];
  /** An optional namespace to prefix the file with. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The request mode to use when uploading. */
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type SiteConfigurationInput = {
  /** Pass providers here to override their configuration. */
  classSyncProviders?: InputMaybe<Array<ClassSyncProviderConfigurationInput>>;
  defaultClassConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  /** The standard set IDs enabled for this site. */
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<SiteConfigurationInput>;
  disableCourseCodes?: InputMaybe<Scalars['Boolean']['input']>;
  districtId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<AccountRegion>;
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SiteWideBannerInput = {
  message: Scalars['String']['input'];
  /** The user groups the message should show up for. */
  userGroups: Array<Scalars['String']['input']>;
};

export type StandardSetTagInput = {
  name: Scalars['String']['input'];
  parents?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type StartStandardSetImportInput = {
  csvPath: Scalars['String']['input'];
  standardSetId: Scalars['ID']['input'];
};

export type StoreVodCaptionInput = {
  /** The WebVTT contents of the caption file. */
  fileContents: Scalars['String']['input'];
  /** The language code for the caption file. */
  language: Scalars['String']['input'];
};

export type StoreVodCaptionsInput = {
  /** The new captions to store (they will be added to the existing ones) */
  captions: Array<StoreVodCaptionInput>;
  /** The ID of the Video on Demand to store captions for. */
  id: Scalars['ID']['input'];
};

export type StudentFilterInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type StudentInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type StudentReportInput = {
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID']['input'];
  /** The ID of the student to run the report on. */
  id: Scalars['ID']['input'];
};

export enum StudentReportResultType {
  Assignment = 'ASSIGNMENT',
  Section = 'SECTION'
}

export type StudentsReportDimensionInput = {
  studentPairs: Array<ClassStudentInput>;
};

export enum SubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL'
}

export type SubmitAnsweredQuestionInput = {
  /** The question being asked. */
  name: Scalars['String']['input'];
};

export type SubmitAssignmentInput = {
  /** Whether or not to force the submission. */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID']['input'];
  /** Any submission data to add. */
  submissionData?: InputMaybe<Scalars['JSON']['input']>;
};

export type SubmitMediaInput = {
  id: Scalars['ID']['input'];
};

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID']['input'];
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SyncSegmentInput = {
  /** The global segment ID (not the raw one). */
  id: Scalars['ID']['input'];
  /** The class syncs to use to sync this assignment. */
  syncIds: Array<Scalars['ID']['input']>;
};

export type TagConfigInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

export type TagFilterInput = {
  /** The name of the tag. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The path of parent tags to find all children for. */
  path?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The type of tag. */
  type: Scalars['String']['input'];
};

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type TagFilters = {
  /** Whether or not to filter on tags that have content available in the current user's license. */
  inLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TagsFilter = {
  operation: TagFilterOperation;
  tags: Array<TagFilterInput>;
};

export type TeacherInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  noEmail?: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: InputMaybe<Scalars['String']['input']>;
};

export type TimeOnTaskReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY'
}

export type TrialRequestCampaignInput = {
  /** The campaign name */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The target path for the campaign */
  targetPath?: InputMaybe<Scalars['String']['input']>;
  /** The welcome message */
  welcomeMessage?: InputMaybe<TrialRequestCampaignWelcomeMessageInput>;
};

export type TrialRequestCampaignWelcomeMessageInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum TrialRequestStatus {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
  Ignored = 'IGNORED',
  Open = 'OPEN'
}

export type TrialSignupInput = {
  /** The identifier for the trial request campaign to associate. */
  campaign?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siteName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID']['input'];
};

export type UnlikeNodeInput = {
  /** The ID of the node to unlike. */
  id: Scalars['ID']['input'];
};

export type UnlinkAccountInput = {
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The user's specific ID related to the provider. */
  providerId: Scalars['String']['input'];
};

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID']['input'];
  patch: AnnouncementInput;
};

export type UpdateAnsweredQuestionContributorInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionContributorInput;
};

export type UpdateAnsweredQuestionInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionInput;
};

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID']['input'];
  patch: AssignmentCategoryInput;
};

export type UpdateAssignmentClassConfigurationInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentClassConfigurationInput;
};

export type UpdateAssignmentInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the assignment to update. */
  id: Scalars['ID']['input'];
  patch: AssignmentInput;
};

export type UpdateAssignmentTemplateInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentTemplateInput;
};

export type UpdateBadgeInput = {
  id: Scalars['ID']['input'];
  patch: BadgeInput;
};

export type UpdateClassGroupPresetInput = {
  id: Scalars['ID']['input'];
  patch: ClassGroupPresetInput;
};

export type UpdateClassInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  patch: ClassInput;
  segmentMetadataChanges?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID']['input'];
  patch: CommentInput;
};

export type UpdateCourseInput = {
  id: Scalars['ID']['input'];
  patch: CourseInput;
};

export type UpdateCourseVersionInput = {
  id: Scalars['ID']['input'];
  patch: CourseVersionInput;
};

export type UpdateDiscussionThreadInput = {
  /** The ID of the discussion thread to update. */
  id: Scalars['ID']['input'];
  patch: DiscussionThreadInput;
};

export type UpdateDistrictInput = {
  id: Scalars['ID']['input'];
  patch: DistrictInput;
};

export type UpdateLessonInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<LessonInput>;
};

export type UpdateLicenseInput = {
  id: Scalars['ID']['input'];
  noEmail?: InputMaybe<Scalars['Boolean']['input']>;
  patch: LicenseInput;
};

export type UpdateLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
  patch: LicenseSubscriptionInput;
};

export type UpdateMediaInput = {
  id: Scalars['ID']['input'];
  patch: MediaInput;
};

export type UpdateNodeTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdatePracticeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<PracticePatch>;
};

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: InputMaybe<Scalars['String']['input']>;
  /** The user's current preferences. */
  preferences: Array<PreferencesInput>;
};

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID']['input'];
  patch: RubricTemplateInput;
};

export type UpdateSavedReportInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  report?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSegmentCompletionInput = {
  /** The ID of the class to update the completion in. */
  id: Scalars['ID']['input'];
  /** The completion updates to perform. */
  updates: Array<SegmentCompletionInput>;
};

export type UpdateSiteInput = {
  id: Scalars['ID']['input'];
  patch: SiteInput;
};

export type UpdateSiteWideBannerInput = {
  message?: InputMaybe<SiteWideBannerInput>;
};

export type UpdateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type UpdateStudentInput = {
  id: Scalars['ID']['input'];
  patch: StudentInput;
};

export type UpdateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  /** The ID of the tag to update. */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID']['input'];
  /** The new permissions to assign to the teacher. */
  permissions: Array<Scalars['String']['input']>;
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID']['input'];
};

export type UpdateTeacherInput = {
  id: Scalars['ID']['input'];
  patch: TeacherInput;
};

export type UpdateTrialRequestCampaignInput = {
  id: Scalars['ID']['input'];
  patch: TrialRequestCampaignInput;
};

export type UpdateUserDetailedLoggingInput = {
  /** Whether or not to enable detailed logging for this user. */
  enabled: Scalars['Boolean']['input'];
  /** The ID of the user to update. */
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  /** If this is passed, the user's last activity is updated to the current time. */
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID']['input'];
  patch: VideoOnDemandInput;
};

export type UpdateWidgetTemplateInput = {
  id: Scalars['ID']['input'];
  patch: WidgetTemplateInput;
};

export enum UploadRequestMode {
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA'
}

export type UseAssignmentSubmissionInput = {
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the submission to use. */
  submissionId: Scalars['ID']['input'];
};

export enum UserGroup {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  ContentManager = 'CONTENT_MANAGER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserTasksFilterInput = {
  /** An optional class ID to filter the user tasks on. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoOnDemandCaptionStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type VideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnailData?: InputMaybe<Scalars['JSON']['input']>;
};

export enum VideoOnDemandStatus {
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING'
}

export type WidgetTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type ClassCourseFragment_Class_courseVersion_CourseVersion_course_Course = (
  {
  id: string,
  name: string,
  label?: string | null,
  basicConfiguration?: any | null,
  isByoc?: boolean | null
}
  & {
  __typename?: 'Course'
}
);

export type ClassCourseFragment_Class_courseVersion_CourseVersion = (
  {
  id: string,
  name: string,
  course: ClassCourseFragment_Class_courseVersion_CourseVersion_course_Course
}
  & {
  __typename?: 'CourseVersion'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount = (
  {
  id: string,
  provider: string,
  providerName: string,
  label: string,
  authorized?: boolean | null
}
  & {
  __typename?: 'LinkedAccount'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean,
  linkedAccounts: Array<ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount>
}
  & {
  __typename?: 'User'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type ClassCourseFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  pageInfo: ClassCourseFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo,
  edges: Array<ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type ClassCourseFragment_Class_teachers_ClassTeachersConnection = (
  {
  totalCount: number
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type ClassCourseFragment = (
  {
  id: string,
  bakedCourseConfiguration?: any | null,
  segmentMetadata?: any | null,
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  courseVersion?: ClassCourseFragment_Class_courseVersion_CourseVersion | null,
  students: ClassCourseFragment_Class_students_ClassStudentsConnection,
  teachers: ClassCourseFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type BasicClassCourseFragment_Class_courseVersion_CourseVersion = (
  {
  id: string,
  name: string,
  course: ClassCourseFragment_Class_courseVersion_CourseVersion_course_Course
}
  & {
  __typename?: 'CourseVersion'
}
);

export type BasicClassCourseFragment = (
  {
  id: string,
  courseVersion?: BasicClassCourseFragment_Class_courseVersion_CourseVersion | null
}
  & {
  __typename?: 'Class'
}
);

export type ViewSegmentUserTaskFragment_ViewSegmentUserTask_payload_ViewSegmentUserTaskPayload = (
  {
  classId: string,
  segmentId: string
}
  & {
  __typename?: 'ViewSegmentUserTaskPayload'
}
);

export type ViewSegmentUserTaskFragment = (
  {
  completedAt?: any | null,
  date?: any | null,
  identifier: string,
  payload: ViewSegmentUserTaskFragment_ViewSegmentUserTask_payload_ViewSegmentUserTaskPayload
}
  & {
  __typename?: 'ViewSegmentUserTask'
}
);

export type ClassCourseVersionFragment = (
  {
  id: string,
  name: string,
  course: ClassCourseFragment_Class_courseVersion_CourseVersion_course_Course
}
  & {
  __typename?: 'CourseVersion'
}
);

export type SegmentDecorationFragment = (
  {
  id: string,
  segmentId: string,
  startedCount?: number | null,
  isViewed?: boolean | null,
  isComplete?: boolean | null,
  completedStudentIds?: Array<string> | null
}
  & {
  __typename?: 'SegmentDecoration'
}
);

export type CourseFragment = (
  {
  id: string,
  name: string,
  label?: string | null,
  shortCode?: string | null,
  isHidden?: boolean | null,
  isByoc?: boolean | null,
  basicConfiguration?: any | null,
  classConfiguration?: any | null,
  createdAt: any,
  updatedAt: any,
  state?: string | null
}
  & {
  __typename?: 'Course'
}
);

export type BasicCourseVersionFragment = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type CourseVersionFragment = (
  {
  rootSegmentId: string,
  configuration?: any | null,
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type SegmentFragment_Segment_user_User = (
  {
  id: string,
  name?: string | null
}
  & {
  __typename?: 'User'
}
);

export type SegmentFragment = (
  {
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type BuiltSegmentFragment = (
  {
  built?: any | null,
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type HydratedSegmentFragment = (
  {
  built?: any | null,
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type SegmentWithHydrationDataFragment = (
  {
  id: string,
  hydrationData?: any | null
}
  & {
  __typename?: 'Segment'
}
);

export type MinimalSegmentFragment = (
  {
  id: string,
  name: string,
  basicConfig?: any | null,
  type: SegmentType,
  prefix?: string | null,
  tagSummary: any
}
  & {
  __typename?: 'Segment'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection = (
  {
  edges: Array<SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync = (
  {
  id: string,
  classSync: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_classSync_ClassSync,
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'AssignmentSync'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_materialSyncs_MaterialSync_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_materialSyncs_MaterialSync = (
  {
  id: string,
  classSync: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_materialSyncs_MaterialSync_classSync_ClassSync,
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'MaterialSync'
}
);

export type SegmentDecorationWithAssignmentSyncFragment = (
  {
  assignmentSyncs?: Array<SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync> | null,
  materialSyncs?: Array<SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_materialSyncs_MaterialSync> | null
}
  & {
  __typename?: 'SegmentDecoration'
}
);

export type BasicAssignmentSyncFragment = (
  {
  id: string,
  classSync: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_classSync_ClassSync,
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'AssignmentSync'
}
);

export type BasicMaterialSyncFragment = (
  {
  id: string,
  classSync: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_materialSyncs_MaterialSync_classSync_ClassSync,
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'MaterialSync'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge_node_ClassSync = (
  {
  id: string,
  provider: string,
  providerId: string,
  label: string,
  canDelete: boolean
}
  & {
  __typename?: 'ClassSync'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge = (
  {
  node: ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge_node_ClassSync
}
  & {
  __typename?: 'ClassSyncsEdge'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection = (
  {
  edges: Array<ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge>
}
  & {
  __typename?: 'ClassSyncsConnection'
}
);

export type ClassWithSyncsFragment = (
  {
  id: string,
  canCreateClassSync: boolean,
  canAttachStudentAccounts: boolean,
  syncs: ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassSyncFragment = (
  {
  id: string,
  provider: string,
  providerId: string,
  label: string,
  canDelete: boolean
}
  & {
  __typename?: 'ClassSync'
}
);

export type BasicClassSyncHistoryFragment = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type ListClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type ListClassSyncHistoryFragment = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any,
  classSync: ListClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync = (
  {
  id: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_materialSync_MaterialSync = (
  {
  id: string
}
  & {
  __typename?: 'MaterialSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_assignmentSync_AssignmentSync = (
  {
  id: string
}
  & {
  __typename?: 'AssignmentSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistoryErrorDetail = (
  {
  userId: string,
  stack: string,
  message: string,
  name: string,
  responseData?: any | null,
  responseStatus?: string | null
}
  & {
  __typename: 'ClassSyncHistoryErrorDetail'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistorySuccessDetail = (
  {
  userId: string
}
  & {
  __typename: 'ClassSyncHistorySuccessDetail'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail = DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistoryErrorDetail | DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistorySuccessDetail;

export type DetailedClassSyncHistoryFragment = (
  {
  startedAt: any,
  completedAt: any,
  type: ClassSyncHistoryType,
  status: ClassSyncHistoryStatus,
  logUrl?: string | null,
  id: string,
  classSync: DetailedClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync,
  materialSync?: DetailedClassSyncHistoryFragment_ClassSyncHistory_materialSync_MaterialSync | null,
  assignmentSync?: DetailedClassSyncHistoryFragment_ClassSyncHistory_assignmentSync_AssignmentSync | null,
  detail?: DetailedClassSyncHistoryFragment_ClassSyncHistory_detail | null
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryLatestAttemptFragment = (
  {
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename?: 'AssignmentSync' | 'ClassSync' | 'MaterialSync'
}
);

export type AssignmentSyncFragment_AssignmentSync_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type AssignmentSyncFragment = (
  {
  id: string,
  provider: string,
  providerId: string,
  classSync: AssignmentSyncFragment_AssignmentSync_classSync_ClassSync,
  latestAttempt?: SegmentDecorationWithAssignmentSyncFragment_SegmentDecoration_assignmentSyncs_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'AssignmentSync'
}
);

export type GroupImportFragment = (
  {
  importId: string,
  name: string,
  createdAt?: any | null
}
  & {
  __typename?: 'GroupImport'
}
);

export type GroupImportGroupFragment = (
  {
  groupId: string,
  name: string,
  createdAt?: any | null,
  leaderStudentId?: string | null,
  memberStudentIds: Array<string>
}
  & {
  __typename?: 'GroupImportGroup'
}
);

export type GradeCategoryImportSourceFragment = (
  {
  sourceId: string,
  name: string
}
  & {
  __typename?: 'GradeCategoryImportSource'
}
);

export type ClassSyncProviderConfigurationFragment = (
  {
  providerIdentifier: string,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null
}
  & {
  __typename?: 'ClassSyncProviderConfiguration'
}
);

export type ClassSyncSiteConfigurationFragment_SiteConfiguration_classSyncProviders_ClassSyncProviderConfiguration = (
  {
  providerIdentifier: string,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null
}
  & {
  __typename?: 'ClassSyncProviderConfiguration'
}
);

export type ClassSyncSiteConfigurationFragment = (
  {
  classSyncProviders?: Array<ClassSyncSiteConfigurationFragment_SiteConfiguration_classSyncProviders_ClassSyncProviderConfiguration> | null
}
  & {
  __typename?: 'SiteConfiguration'
}
);

export type SiteClassSyncProviderFragment = (
  {
  identifier: string,
  name: string,
  authProviderId?: string | null,
  authProviderName?: string | null,
  supportedFeatures?: Array<ClassSyncProviderFeature> | null,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null,
  requiresSetup: boolean
}
  & {
  __typename?: 'SiteClassSyncProvider'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student = (
  {
  id: string,
  user: ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent_userInfo_BasicUser = (
  {
  firstName: string,
  lastName: string,
  email: string
}
  & {
  __typename?: 'BasicUser'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent = (
  {
  id: string,
  group?: string | null,
  userInfo: ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent_userInfo_BasicUser
}
  & {
  __typename?: 'ClassSyncStudent'
}
);

export type ClassSyncRosterActionFragment = (
  {
  mode?: ClassSyncRosterActionMode | null,
  student?: ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student | null,
  providerStudent?: ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent | null
}
  & {
  __typename?: 'ClassSyncRosterAction'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection = (
  {
  edges: Array<SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection = (
  {
  edges: Array<SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type SyncHistoryOverviewFragment = (
  {
  latestMetadataSync?: SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection | null,
  latestGradeSync?: SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection | null
}
  & {
  __typename?: 'AssignmentSync' | 'ClassSync' | 'MaterialSync'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string,
  email?: string | null
}
  & {
  __typename?: 'User'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student = (
  {
  id: string,
  user: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata = (
  {
  matchedStudent: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student
}
  & {
  __typename?: 'DuplicateUserPendingStudentClassMetadata'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment = (
  {
  duplicateUserMetadata?: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata | null
}
  & {
  __typename?: 'PendingStudentClass'
}
);

export type DuplicateUserPendingStudentClassMetadataFragment = (
  {
  matchedStudent: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student
}
  & {
  __typename?: 'DuplicateUserPendingStudentClassMetadata'
}
);

export type ClassSyncProviderClassFragment = (
  {
  id: string,
  name: string,
  isSuggested?: boolean | null,
  isAttached?: boolean | null
}
  & {
  __typename?: 'ClassSyncProviderClass'
}
);

export type UserFragment = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type MinimalClassFragment = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type BasicClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean
}
  & {
  __typename: 'Class'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type AdminClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  teachers: AdminClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassFragment = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassCourseFragment_Class_students_ClassStudentsConnection,
  teachers: ClassCourseFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassStudentEdgeFragment = (
  {
  node: ClassCourseFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassesEdgeFragment_StudentClassesEdge_node_Class = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type StudentClassesEdgeFragment = (
  {
  node: StudentClassesEdgeFragment_StudentClassesEdge_node_Class
}
  & {
  __typename?: 'StudentClassesEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  edges: Array<StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type StudentClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  students: StudentClassFragment_Class_students_ClassStudentsConnection,
  teachers: StudentClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string,
  email?: string | null
}
  & {
  __typename?: 'User'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student = (
  {
  id: string,
  user: PendingStudentClassFragment_PendingStudentClass_student_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type PendingStudentClassFragment = (
  {
  id: string,
  state: PendingStudentClassState,
  reason: PendingStudentClassReason,
  createdAt: any,
  approvedAt?: any | null,
  declinedAt?: any | null,
  student: PendingStudentClassFragment_PendingStudentClass_student_Student
}
  & {
  __typename?: 'PendingStudentClass'
}
);

export type BasicCourseVersionQuery_node_O0sj02F2u7szupzxfNHghbW0iPn6U1LCmDSDlEJK4 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type BasicCourseVersionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type BasicCourseVersionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type BasicCourseVersionQuery_node_CourseVersion = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type BasicCourseVersionQuery_node = BasicCourseVersionQuery_node_O0sj02F2u7szupzxfNHghbW0iPn6U1LCmDSDlEJK4 | BasicCourseVersionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | BasicCourseVersionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | BasicCourseVersionQuery_node_CourseVersion;

export type BasicCourseVersionQuery_Query = (
  {
  node?: BasicCourseVersionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type BasicCourseVersionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BasicCourseVersionQuery = BasicCourseVersionQuery_Query;

export type BuiltSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type BuiltSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI = (
  {
  id: string
}
  & {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type BuiltSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type BuiltSegmentQuery_node_Segment = (
  {
  classIdsWithSegment?: Array<string> | null,
  id: string,
  built?: any | null,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename: 'Segment'
}
);

export type BuiltSegmentQuery_node = BuiltSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | BuiltSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI | BuiltSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | BuiltSegmentQuery_node_Segment;

export type BuiltSegmentQuery_Query = (
  {
  node?: BuiltSegmentQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type BuiltSegmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BuiltSegmentQuery = BuiltSegmentQuery_Query;

export type ListBuiltSegmentsQuery_segments_SegmentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  hasPreviousPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ListBuiltSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge_node_Segment = (
  {
  built?: any | null,
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type ListBuiltSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge = (
  {
  cursor: string,
  node: ListBuiltSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge_node_Segment
}
  & {
  __typename?: 'SegmentsEdge'
}
);

export type ListBuiltSegmentsQuery_segments_SegmentsConnection = (
  {
  totalCount: number,
  pageInfo: ListBuiltSegmentsQuery_segments_SegmentsConnection_pageInfo_PageInfo,
  edges: Array<ListBuiltSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge>
}
  & {
  __typename?: 'SegmentsConnection'
}
);

export type ListBuiltSegmentsQuery_Query = (
  {
  segments: ListBuiltSegmentsQuery_segments_SegmentsConnection
}
  & {
  __typename?: 'Query'
}
);


export type ListBuiltSegmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  types?: InputMaybe<Array<SegmentType> | SegmentType>;
  name?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput> | TagFilterInput>;
  tagsOp?: InputMaybe<FilterOperation>;
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<SegmentsFilters>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type ListBuiltSegmentsQuery = ListBuiltSegmentsQuery_Query;

export type ClassCourseQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassCourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassCourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassCourseQuery_node_Class = (
  {
  id: string,
  bakedCourseConfiguration?: any | null,
  segmentMetadata?: any | null,
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  courseVersion?: ClassCourseFragment_Class_courseVersion_CourseVersion | null,
  students: ClassCourseFragment_Class_students_ClassStudentsConnection,
  teachers: ClassCourseFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassCourseQuery_node = ClassCourseQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassCourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassCourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassCourseQuery_node_Class;

export type ClassCourseQuery_Query = (
  {
  node?: ClassCourseQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassCourseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassCourseQuery = ClassCourseQuery_Query;

export type ClassDecorationsQuery_node_Class_segmentDecorations_SegmentDecoration = (
  {
  id: string,
  segmentId: string,
  startedCount?: number | null,
  isViewed?: boolean | null,
  isComplete?: boolean | null,
  completedStudentIds?: Array<string> | null
}
  & {
  __typename?: 'SegmentDecoration'
}
);

export type ClassDecorationsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassDecorationsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassDecorationsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassDecorationsQuery_node_Class = (
  {
  id: string,
  segmentDecorations?: Array<ClassDecorationsQuery_node_Class_segmentDecorations_SegmentDecoration> | null
}
  & {
  __typename?: 'Class'
}
);

export type ClassDecorationsQuery_node = ClassDecorationsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassDecorationsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassDecorationsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassDecorationsQuery_node_Class;

export type ClassDecorationsQuery_Query = (
  {
  node?: ClassDecorationsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassDecorationsQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
}>;


export type ClassDecorationsQuery = ClassDecorationsQuery_Query;

export type ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class = (
  {
  id: string,
  name: string
}
  & {
  __typename?: 'Class'
}
);

export type ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge = (
  {
  node: ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class
}
  & {
  __typename?: 'TeacherClassesEdge'
}
);

export type ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection = (
  {
  edges: Array<ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge>
}
  & {
  __typename?: 'TeacherClassesConnection'
}
);

export type ClassNamesQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  classes: ClassNamesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type ClassNamesQuery_viewer_User = (
  {
  id: string,
  teacher?: ClassNamesQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type ClassNamesQuery_Query = (
  {
  viewer?: ClassNamesQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClassNamesQuery = ClassNamesQuery_Query;

export type ClassesWithSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type ClassesWithSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI = (
  {
  id: string
}
  & {
  __typename?: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassesWithSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassesWithSegmentQuery_node_Segment = (
  {
  name: string,
  classIdsWithSegment?: Array<string> | null,
  id: string
}
  & {
  __typename?: 'Segment'
}
);

export type ClassesWithSegmentQuery_node = ClassesWithSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | ClassesWithSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI | ClassesWithSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassesWithSegmentQuery_node_Segment;

export type ClassesWithSegmentQuery_Query = (
  {
  node?: ClassesWithSegmentQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassesWithSegmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassesWithSegmentQuery = ClassesWithSegmentQuery_Query;

export type CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse_class_Class = (
  {
  segmentMetadata?: any | null,
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassCourseFragment_Class_students_ClassStudentsConnection,
  teachers: ClassCourseFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse_segment_Segment = (
  {
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse = (
  {
  bakedConfigurationParent: any,
  class: CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse_class_Class,
  segment: CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse_segment_Segment
}
  & {
  __typename?: 'CopySegmentAsOwnResponse'
}
);

export type CopySegmentAsOwnMutation_Mutation = (
  {
  copySegmentAsOwn: CopySegmentAsOwnMutation_copySegmentAsOwn_CopySegmentAsOwnResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type CopySegmentAsOwnMutationVariables = Exact<{
  input: CopySegmentAsOwnInput;
}>;


export type CopySegmentAsOwnMutation = CopySegmentAsOwnMutation_Mutation;

export type CopySegmentSettingsMutation_copySegmentSettings_CopySegmentSettingsPayload_classes_Class = (
  {
  id: string
}
  & {
  __typename?: 'Class'
}
);

export type CopySegmentSettingsMutation_copySegmentSettings_CopySegmentSettingsPayload = (
  {
  classes: Array<CopySegmentSettingsMutation_copySegmentSettings_CopySegmentSettingsPayload_classes_Class>
}
  & {
  __typename?: 'CopySegmentSettingsPayload'
}
);

export type CopySegmentSettingsMutation_Mutation = (
  {
  copySegmentSettings: CopySegmentSettingsMutation_copySegmentSettings_CopySegmentSettingsPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CopySegmentSettingsMutationVariables = Exact<{
  input: CopySegmentSettingsInput;
}>;


export type CopySegmentSettingsMutation = CopySegmentSettingsMutation_Mutation;

export type CopyTagsToChildrenMutation_copyTagsToChildren_SuccessResponse = (
  {
  success: boolean
}
  & {
  __typename?: 'SuccessResponse'
}
);

export type CopyTagsToChildrenMutation_Mutation = (
  {
  copyTagsToChildren: CopyTagsToChildrenMutation_copyTagsToChildren_SuccessResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type CopyTagsToChildrenMutationVariables = Exact<{
  input: CopyTagsToChildrenInput;
}>;


export type CopyTagsToChildrenMutation = CopyTagsToChildrenMutation_Mutation;

export type CourseQuery_node_Course_latestPublishedVersion_CourseVersion = (
  {
  rootSegmentId: string,
  configuration?: any | null,
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type CourseQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type CourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type CourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type CourseQuery_node_Course = (
  {
  id: string,
  name: string,
  label?: string | null,
  shortCode?: string | null,
  isHidden?: boolean | null,
  isByoc?: boolean | null,
  basicConfiguration?: any | null,
  classConfiguration?: any | null,
  createdAt: any,
  updatedAt: any,
  state?: string | null,
  latestPublishedVersion?: CourseQuery_node_Course_latestPublishedVersion_CourseVersion | null
}
  & {
  __typename?: 'Course'
}
);

export type CourseQuery_node = CourseQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug | CourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | CourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | CourseQuery_node_Course;

export type CourseQuery_Query = (
  {
  node?: CourseQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type CourseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CourseQuery = CourseQuery_Query;

export type CourseVersionQuery_node_O0sj02F2u7szupzxfNHghbW0iPn6U1LCmDSDlEJK4 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type CourseVersionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type CourseVersionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type CourseVersionQuery_node_CourseVersion = (
  {
  rootSegmentId: string,
  configuration?: any | null,
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type CourseVersionQuery_node = CourseVersionQuery_node_O0sj02F2u7szupzxfNHghbW0iPn6U1LCmDSDlEJK4 | CourseVersionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | CourseVersionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | CourseVersionQuery_node_CourseVersion;

export type CourseVersionQuery_Query = (
  {
  node?: CourseVersionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type CourseVersionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CourseVersionQuery = CourseVersionQuery_Query;

export type CourseVersionsQuery_node_Course_versions_CourseVersionsConnection_edges_CourseVersionsEdge_node_CourseVersion = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type CourseVersionsQuery_node_Course_versions_CourseVersionsConnection_edges_CourseVersionsEdge = (
  {
  node: CourseVersionsQuery_node_Course_versions_CourseVersionsConnection_edges_CourseVersionsEdge_node_CourseVersion
}
  & {
  __typename?: 'CourseVersionsEdge'
}
);

export type CourseVersionsQuery_node_Course_versions_CourseVersionsConnection = (
  {
  totalCount: number,
  edges: Array<CourseVersionsQuery_node_Course_versions_CourseVersionsConnection_edges_CourseVersionsEdge>
}
  & {
  __typename?: 'CourseVersionsConnection'
}
);

export type CourseVersionsQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type CourseVersionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type CourseVersionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type CourseVersionsQuery_node_Course = (
  {
  id: string,
  versions: CourseVersionsQuery_node_Course_versions_CourseVersionsConnection
}
  & {
  __typename: 'Course'
}
);

export type CourseVersionsQuery_node = CourseVersionsQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug | CourseVersionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | CourseVersionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | CourseVersionsQuery_node_Course;

export type CourseVersionsQuery_Query = (
  {
  node?: CourseVersionsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type CourseVersionsQueryVariables = Exact<{
  courseId: Scalars['ID']['input'];
}>;


export type CourseVersionsQuery = CourseVersionsQuery_Query;

export type CreateClassWithPlacedContentMutation_createClassWithPlacedContent_ClassPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassCourseFragment_Class_students_ClassStudentsConnection,
  teachers: ClassCourseFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type CreateClassWithPlacedContentMutation_createClassWithPlacedContent_ClassPayload = (
  {
  class: CreateClassWithPlacedContentMutation_createClassWithPlacedContent_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type CreateClassWithPlacedContentMutation_Mutation = (
  {
  createClassWithPlacedContent: CreateClassWithPlacedContentMutation_createClassWithPlacedContent_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateClassWithPlacedContentMutationVariables = Exact<{
  input: CreateClassWithPlacedContentInput;
}>;


export type CreateClassWithPlacedContentMutation = CreateClassWithPlacedContentMutation_Mutation;

export type CreateCourseMutation_createCourse_CoursePayload_course_Course = (
  {
  id: string,
  name: string,
  label?: string | null,
  shortCode?: string | null,
  isHidden?: boolean | null,
  isByoc?: boolean | null,
  basicConfiguration?: any | null,
  classConfiguration?: any | null,
  createdAt: any,
  updatedAt: any,
  state?: string | null
}
  & {
  __typename?: 'Course'
}
);

export type CreateCourseMutation_createCourse_CoursePayload = (
  {
  course: CreateCourseMutation_createCourse_CoursePayload_course_Course
}
  & {
  __typename?: 'CoursePayload'
}
);

export type CreateCourseMutation_Mutation = (
  {
  createCourse: CreateCourseMutation_createCourse_CoursePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateCourseMutationVariables = Exact<{
  input: CourseInput;
}>;


export type CreateCourseMutation = CreateCourseMutation_Mutation;

export type CreateCourseVersionMutation_createCourseVersion_CourseVersionPayload_courseVersion_CourseVersion = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type CreateCourseVersionMutation_createCourseVersion_CourseVersionPayload = (
  {
  courseVersion: CreateCourseVersionMutation_createCourseVersion_CourseVersionPayload_courseVersion_CourseVersion
}
  & {
  __typename?: 'CourseVersionPayload'
}
);

export type CreateCourseVersionMutation_Mutation = (
  {
  createCourseVersion: CreateCourseVersionMutation_createCourseVersion_CourseVersionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateCourseVersionMutationVariables = Exact<{
  input: CreateCourseVersionInput;
}>;


export type CreateCourseVersionMutation = CreateCourseVersionMutation_Mutation;

export type CreateOrUpdateSegmentMutation_createOrUpdateSegment_SegmentPayload_segment_Segment = (
  {
  id: string,
  built?: any | null
}
  & {
  __typename?: 'Segment'
}
);

export type CreateOrUpdateSegmentMutation_createOrUpdateSegment_SegmentPayload = (
  {
  segment: CreateOrUpdateSegmentMutation_createOrUpdateSegment_SegmentPayload_segment_Segment
}
  & {
  __typename?: 'SegmentPayload'
}
);

export type CreateOrUpdateSegmentMutation_Mutation = (
  {
  createOrUpdateSegment: CreateOrUpdateSegmentMutation_createOrUpdateSegment_SegmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateOrUpdateSegmentMutationVariables = Exact<{
  input: SegmentInput;
}>;


export type CreateOrUpdateSegmentMutation = CreateOrUpdateSegmentMutation_Mutation;

export type DeleteSegmentMutation_deleteSegment_DeleteSegmentPayload = (
  {
  segmentId: string
}
  & {
  __typename?: 'DeleteSegmentPayload'
}
);

export type DeleteSegmentMutation_Mutation = (
  {
  deleteSegment: DeleteSegmentMutation_deleteSegment_DeleteSegmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type DeleteSegmentMutationVariables = Exact<{
  input: DeleteSegmentInput;
}>;


export type DeleteSegmentMutation = DeleteSegmentMutation_Mutation;

export type DetachSegmentMutation_detachSegment_DetachSegmentPayload_courseVersion_CourseVersion = (
  {
  rootSegmentId: string,
  configuration?: any | null,
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type DetachSegmentMutation_detachSegment_DetachSegmentPayload = (
  {
  courseVersion: DetachSegmentMutation_detachSegment_DetachSegmentPayload_courseVersion_CourseVersion
}
  & {
  __typename?: 'DetachSegmentPayload'
}
);

export type DetachSegmentMutation_Mutation = (
  {
  detachSegment: DetachSegmentMutation_detachSegment_DetachSegmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type DetachSegmentMutationVariables = Exact<{
  input: DetachSegmentInput;
}>;


export type DetachSegmentMutation = DetachSegmentMutation_Mutation;

export type FetchSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
};

export type FetchSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI = {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type FetchSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type FetchSegmentQuery_node_Segment = (
  {
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename: 'Segment'
}
);

export type FetchSegmentQuery_node = FetchSegmentQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | FetchSegmentQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI | FetchSegmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | FetchSegmentQuery_node_Segment;

export type FetchSegmentQuery_Query = (
  {
  node?: FetchSegmentQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type FetchSegmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FetchSegmentQuery = FetchSegmentQuery_Query;

export type ImportSegmentsMutation_importSegments_SuccessResponse = (
  {
  success: boolean
}
  & {
  __typename?: 'SuccessResponse'
}
);

export type ImportSegmentsMutation_Mutation = (
  {
  importSegments: ImportSegmentsMutation_importSegments_SuccessResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type ImportSegmentsMutationVariables = Exact<{
  input: ImportSegmentsInput;
}>;


export type ImportSegmentsMutation = ImportSegmentsMutation_Mutation;

export type ListCoursesQuery_courses_CoursesConnection_pageInfo_PageInfo = (
  {
  endCursor?: string | null,
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge_node_Course_latestPublishedVersion_CourseVersion = (
  {
  id: string,
  allLabels: Array<string>
}
  & {
  __typename?: 'CourseVersion'
}
);

export type ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge_node_Course = (
  {
  id: string,
  name: string,
  label?: string | null,
  shortCode?: string | null,
  isHidden?: boolean | null,
  isByoc?: boolean | null,
  basicConfiguration?: any | null,
  classConfiguration?: any | null,
  createdAt: any,
  updatedAt: any,
  state?: string | null,
  latestPublishedVersion?: ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge_node_Course_latestPublishedVersion_CourseVersion | null
}
  & {
  __typename?: 'Course'
}
);

export type ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge = (
  {
  cursor: string,
  node: ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge_node_Course
}
  & {
  __typename?: 'CoursesEdge'
}
);

export type ListCoursesQuery_courses_CoursesConnection = (
  {
  totalCount: number,
  pageInfo: ListCoursesQuery_courses_CoursesConnection_pageInfo_PageInfo,
  edges: Array<ListCoursesQuery_courses_CoursesConnection_edges_CoursesEdge>
}
  & {
  __typename?: 'CoursesConnection'
}
);

export type ListCoursesQuery_Query = (
  {
  courses: ListCoursesQuery_courses_CoursesConnection
}
  & {
  __typename?: 'Query'
}
);


export type ListCoursesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  isLicensed?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  isByoc?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  withVersionLabels?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListCoursesQuery = ListCoursesQuery_Query;

export type PublishCourseVersionMutation_publishCourseVersion_CourseVersionPayload_courseVersion_CourseVersion = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type PublishCourseVersionMutation_publishCourseVersion_CourseVersionPayload = (
  {
  courseVersion: PublishCourseVersionMutation_publishCourseVersion_CourseVersionPayload_courseVersion_CourseVersion
}
  & {
  __typename?: 'CourseVersionPayload'
}
);

export type PublishCourseVersionMutation_Mutation = (
  {
  publishCourseVersion: PublishCourseVersionMutation_publishCourseVersion_CourseVersionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type PublishCourseVersionMutationVariables = Exact<{
  input: PublishCourseVersionInput;
}>;


export type PublishCourseVersionMutation = PublishCourseVersionMutation_Mutation;

export type SegmentHydrationQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type SegmentHydrationQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI = (
  {
  id: string
}
  & {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SegmentHydrationQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SegmentHydrationQuery_node_Segment = (
  {
  id: string,
  hydrationData?: any | null
}
  & {
  __typename: 'Segment'
}
);

export type SegmentHydrationQuery_node = SegmentHydrationQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | SegmentHydrationQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI | SegmentHydrationQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SegmentHydrationQuery_node_Segment;

export type SegmentHydrationQuery_Query = (
  {
  node?: SegmentHydrationQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SegmentHydrationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SegmentHydrationQuery = SegmentHydrationQuery_Query;

export type SegmentTimeOnTaskQuery_node_Class_timeOnTask_TimeOnTaskResult_timeOnTasks_TimeOnTask = (
  {
  studentUserId: string,
  timeOnTaskMinutes: number
}
  & {
  __typename?: 'TimeOnTask'
}
);

export type SegmentTimeOnTaskQuery_node_Class_timeOnTask_TimeOnTaskResult = (
  {
  totalMinutes: number,
  timeOnTasks: Array<SegmentTimeOnTaskQuery_node_Class_timeOnTask_TimeOnTaskResult_timeOnTasks_TimeOnTask>
}
  & {
  __typename?: 'TimeOnTaskResult'
}
);

export type SegmentTimeOnTaskQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type SegmentTimeOnTaskQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SegmentTimeOnTaskQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SegmentTimeOnTaskQuery_node_Class = (
  {
  id: string,
  timeOnTask: SegmentTimeOnTaskQuery_node_Class_timeOnTask_TimeOnTaskResult
}
  & {
  __typename?: 'Class'
}
);

export type SegmentTimeOnTaskQuery_node = SegmentTimeOnTaskQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | SegmentTimeOnTaskQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | SegmentTimeOnTaskQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SegmentTimeOnTaskQuery_node_Class;

export type SegmentTimeOnTaskQuery_Query = (
  {
  node?: SegmentTimeOnTaskQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SegmentTimeOnTaskQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  input: TimeOnTaskInput;
}>;


export type SegmentTimeOnTaskQuery = SegmentTimeOnTaskQuery_Query;

export type SegmentUseInUnownedClassesQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type SegmentUseInUnownedClassesQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI = (
  {
  id: string
}
  & {
  __typename?: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SegmentUseInUnownedClassesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SegmentUseInUnownedClassesQuery_node_Segment = (
  {
  useInUnownedClasses?: number | null,
  id: string
}
  & {
  __typename?: 'Segment'
}
);

export type SegmentUseInUnownedClassesQuery_node = SegmentUseInUnownedClassesQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | SegmentUseInUnownedClassesQuery_node_aEvP468Mx68Md8jNPcHZWYyNU5Qpvpl2STYG6dyHjhI | SegmentUseInUnownedClassesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SegmentUseInUnownedClassesQuery_node_Segment;

export type SegmentUseInUnownedClassesQuery_Query = (
  {
  node?: SegmentUseInUnownedClassesQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SegmentUseInUnownedClassesQueryVariables = Exact<{
  segmentId: Scalars['ID']['input'];
}>;


export type SegmentUseInUnownedClassesQuery = SegmentUseInUnownedClassesQuery_Query;

export type ListSegmentsQuery_segments_SegmentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  hasPreviousPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ListSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge_node_Segment = (
  {
  built?: any | null,
  id: string,
  name: string,
  type: SegmentType,
  config?: any | null,
  index?: string | null,
  tagSummary: any,
  isHiddenInSearch?: boolean | null,
  updatedAt: any,
  originalId?: string | null,
  user?: SegmentFragment_Segment_user_User | null
}
  & {
  __typename?: 'Segment'
}
);

export type ListSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge = (
  {
  cursor: string,
  node: ListSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge_node_Segment
}
  & {
  __typename?: 'SegmentsEdge'
}
);

export type ListSegmentsQuery_segments_SegmentsConnection = (
  {
  totalCount: number,
  pageInfo: ListSegmentsQuery_segments_SegmentsConnection_pageInfo_PageInfo,
  edges: Array<ListSegmentsQuery_segments_SegmentsConnection_edges_SegmentsEdge>
}
  & {
  __typename?: 'SegmentsConnection'
}
);

export type ListSegmentsQuery_Query = (
  {
  segments: ListSegmentsQuery_segments_SegmentsConnection
}
  & {
  __typename?: 'Query'
}
);


export type ListSegmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  types?: InputMaybe<Array<SegmentType> | SegmentType>;
  name?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput> | TagFilterInput>;
  tagsOp?: InputMaybe<FilterOperation>;
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  filters?: InputMaybe<SegmentsFilters>;
}>;


export type ListSegmentsQuery = ListSegmentsQuery_Query;

export type SimpleCourseQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type SimpleCourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SimpleCourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SimpleCourseQuery_node_Course = (
  {
  name: string,
  id: string
}
  & {
  __typename: 'Course'
}
);

export type SimpleCourseQuery_node = SimpleCourseQuery_node_zZpuywoSK6zBDugpdwQBRWUoeTAPJ02n8nAIfkiug | SimpleCourseQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | SimpleCourseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SimpleCourseQuery_node_Course;

export type SimpleCourseQuery_Query = (
  {
  node?: SimpleCourseQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SimpleCourseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SimpleCourseQuery = SimpleCourseQuery_Query;

export type SyncSegmentMutation_syncSegment_ClassPayload_class_Class_segmentDecorations_SegmentDecoration = (
  {
  id: string,
  segmentId: string,
  startedCount?: number | null,
  isViewed?: boolean | null,
  isComplete?: boolean | null,
  completedStudentIds?: Array<string> | null
}
  & {
  __typename?: 'SegmentDecoration'
}
);

export type SyncSegmentMutation_syncSegment_ClassPayload_class_Class = (
  {
  id: string,
  segmentDecorations?: Array<SyncSegmentMutation_syncSegment_ClassPayload_class_Class_segmentDecorations_SegmentDecoration> | null
}
  & {
  __typename?: 'Class'
}
);

export type SyncSegmentMutation_syncSegment_ClassPayload = (
  {
  class: SyncSegmentMutation_syncSegment_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type SyncSegmentMutation_Mutation = (
  {
  syncSegment: SyncSegmentMutation_syncSegment_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type SyncSegmentMutationVariables = Exact<{
  input: SyncSegmentInput;
}>;


export type SyncSegmentMutation = SyncSegmentMutation_Mutation;

export type TotalBuiltSegmentsQuery_segments_SegmentsConnection = (
  {
  totalCount: number
}
  & {
  __typename?: 'SegmentsConnection'
}
);

export type TotalBuiltSegmentsQuery_Query = (
  {
  segments: TotalBuiltSegmentsQuery_segments_SegmentsConnection
}
  & {
  __typename?: 'Query'
}
);


export type TotalBuiltSegmentsQueryVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type TotalBuiltSegmentsQuery = TotalBuiltSegmentsQuery_Query;

export type UpdateClassWithCourseMutation_updateClass_ClassPayload_class_Class = (
  {
  id: string,
  bakedCourseConfiguration?: any | null,
  segmentMetadata?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type UpdateClassWithCourseMutation_updateClass_ClassPayload = (
  {
  class: UpdateClassWithCourseMutation_updateClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type UpdateClassWithCourseMutation_Mutation = (
  {
  updateClass: UpdateClassWithCourseMutation_updateClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateClassWithCourseMutationVariables = Exact<{
  input: UpdateClassInput;
  refetchCourseConfiguration: Scalars['Boolean']['input'];
}>;


export type UpdateClassWithCourseMutation = UpdateClassWithCourseMutation_Mutation;

export type UpdateCourseMutation_updateCourse_CoursePayload_course_Course = (
  {
  id: string,
  name: string,
  label?: string | null,
  shortCode?: string | null,
  isHidden?: boolean | null,
  isByoc?: boolean | null,
  basicConfiguration?: any | null,
  classConfiguration?: any | null,
  createdAt: any,
  updatedAt: any,
  state?: string | null
}
  & {
  __typename?: 'Course'
}
);

export type UpdateCourseMutation_updateCourse_CoursePayload = (
  {
  course: UpdateCourseMutation_updateCourse_CoursePayload_course_Course
}
  & {
  __typename?: 'CoursePayload'
}
);

export type UpdateCourseMutation_Mutation = (
  {
  updateCourse: UpdateCourseMutation_updateCourse_CoursePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateCourseMutationVariables = Exact<{
  input: UpdateCourseInput;
}>;


export type UpdateCourseMutation = UpdateCourseMutation_Mutation;

export type UpdateCourseVersionMutation_updateCourseVersion_CourseVersionPayload_courseVersion_CourseVersion = (
  {
  id: string,
  name: string,
  publishedAt?: any | null
}
  & {
  __typename?: 'CourseVersion'
}
);

export type UpdateCourseVersionMutation_updateCourseVersion_CourseVersionPayload = (
  {
  courseVersion: UpdateCourseVersionMutation_updateCourseVersion_CourseVersionPayload_courseVersion_CourseVersion
}
  & {
  __typename?: 'CourseVersionPayload'
}
);

export type UpdateCourseVersionMutation_Mutation = (
  {
  updateCourseVersion: UpdateCourseVersionMutation_updateCourseVersion_CourseVersionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateCourseVersionMutationVariables = Exact<{
  input: UpdateCourseVersionInput;
}>;


export type UpdateCourseVersionMutation = UpdateCourseVersionMutation_Mutation;

export type UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload_class_Class_segmentDecorations_SegmentDecoration = (
  {
  id: string,
  segmentId: string,
  startedCount?: number | null,
  isViewed?: boolean | null,
  isComplete?: boolean | null,
  completedStudentIds?: Array<string> | null
}
  & {
  __typename?: 'SegmentDecoration'
}
);

export type UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload_class_Class = (
  {
  id: string,
  segmentDecorations?: Array<UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload_class_Class_segmentDecorations_SegmentDecoration> | null
}
  & {
  __typename?: 'Class'
}
);

export type UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload = (
  {
  class: UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type UpdateSegmentCompletionMutation_Mutation = (
  {
  updateSegmentCompletion: UpdateSegmentCompletionMutation_updateSegmentCompletion_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateSegmentCompletionMutationVariables = Exact<{
  input: UpdateSegmentCompletionInput;
}>;


export type UpdateSegmentCompletionMutation = UpdateSegmentCompletionMutation_Mutation;

export type UserHasCompletedTourQuery_viewer_User = (
  {
  id: string,
  hasCompletedTour: boolean
}
  & {
  __typename?: 'User'
}
);

export type UserHasCompletedTourQuery_Query = (
  {
  viewer?: UserHasCompletedTourQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type UserHasCompletedTourQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  widgetId: Scalars['String']['input'];
}>;


export type UserHasCompletedTourQuery = UserHasCompletedTourQuery_Query;

export type UserHasWatchedVideoQuery_viewer_User = (
  {
  id: string,
  hasWatchedVod: boolean
}
  & {
  __typename?: 'User'
}
);

export type UserHasWatchedVideoQuery_Query = (
  {
  viewer?: UserHasWatchedVideoQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type UserHasWatchedVideoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserHasWatchedVideoQuery = UserHasWatchedVideoQuery_Query;

export const MinimalClassFragmentDoc = gql`
    fragment MinimalClass on Class {
  id
  name
  weight
  classId
  canInvite
  canUpdate
  isOwn
  archivedAt
}
    `;
export const BasicClassFragmentDoc = gql`
    fragment BasicClass on Class {
  ...MinimalClass
  __typename
  id
  name
  description
  weight
  classId
  configuration
  createdAt
  updatedAt
  archivedAt
}
    ${MinimalClassFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  firstName
  lastName
  username
  group
  status
  createdAt
  updatedAt
  lastActivity
  canUpdate
}
    `;
export const ClassStudentEdgeFragmentDoc = gql`
    fragment ClassStudentEdge on ClassStudentsEdge {
  node {
    id
    user {
      ...User
      linkedAccounts {
        id
        provider
        providerName
        label
        authorized
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const ClassFragmentDoc = gql`
    fragment Class on Class {
  ...BasicClass
  isOwn
  canInvite
  canUpdate
  canRemoveStudents
  canUpdateSettings
  students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...ClassStudentEdge
    }
  }
  teachers {
    totalCount
  }
}
    ${BasicClassFragmentDoc}
${ClassStudentEdgeFragmentDoc}`;
export const ClassCourseVersionFragmentDoc = gql`
    fragment ClassCourseVersion on CourseVersion {
  id
  name
  course {
    id
    name
    label
    basicConfiguration
    isByoc
  }
}
    `;
export const ClassCourseFragmentDoc = gql`
    fragment ClassCourse on Class {
  ...Class
  id
  bakedCourseConfiguration
  segmentMetadata
  courseVersion {
    ...ClassCourseVersion
  }
}
    ${ClassFragmentDoc}
${ClassCourseVersionFragmentDoc}`;
export const BasicClassCourseFragmentDoc = gql`
    fragment BasicClassCourse on Class {
  id
  courseVersion {
    ...ClassCourseVersion
  }
}
    ${ClassCourseVersionFragmentDoc}`;
export const ViewSegmentUserTaskFragmentDoc = gql`
    fragment ViewSegmentUserTask on ViewSegmentUserTask {
  completedAt
  date
  identifier
  payload {
    classId
    segmentId
  }
}
    `;
export const SegmentDecorationFragmentDoc = gql`
    fragment SegmentDecoration on SegmentDecoration {
  id
  segmentId
  startedCount
  isViewed
  isComplete
  completedStudentIds
}
    `;
export const CourseFragmentDoc = gql`
    fragment Course on Course {
  id
  name
  label
  shortCode
  isHidden
  isByoc
  basicConfiguration
  classConfiguration
  createdAt
  updatedAt
  state
}
    `;
export const BasicCourseVersionFragmentDoc = gql`
    fragment BasicCourseVersion on CourseVersion {
  id
  name
  publishedAt
}
    `;
export const CourseVersionFragmentDoc = gql`
    fragment CourseVersion on CourseVersion {
  ...BasicCourseVersion
  rootSegmentId
  configuration
}
    ${BasicCourseVersionFragmentDoc}`;
export const SegmentFragmentDoc = gql`
    fragment Segment on Segment {
  id
  name
  type
  config
  index
  tagSummary
  isHiddenInSearch
  updatedAt
  user {
    id
    name
  }
  originalId
}
    `;
export const BuiltSegmentFragmentDoc = gql`
    fragment BuiltSegment on Segment {
  ...Segment
  built
}
    ${SegmentFragmentDoc}`;
export const HydratedSegmentFragmentDoc = gql`
    fragment HydratedSegment on Segment {
  ...Segment
  built(input: {hydrated: true})
}
    ${SegmentFragmentDoc}`;
export const SegmentWithHydrationDataFragmentDoc = gql`
    fragment SegmentWithHydrationData on Segment {
  id
  hydrationData
}
    `;
export const MinimalSegmentFragmentDoc = gql`
    fragment MinimalSegment on Segment {
  id
  name
  basicConfig
  type
  prefix
  tagSummary
}
    `;
export const BasicClassSyncHistoryFragmentDoc = gql`
    fragment BasicClassSyncHistory on ClassSyncHistory {
  id
  status
  completedAt
}
    `;
export const SyncHistoryLatestAttemptFragmentDoc = gql`
    fragment SyncHistoryLatestAttempt on WithClassSyncHistory {
  latestAttempt: history(first: 1, orderBy: "completedAt", orderDirection: DESC) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const BasicAssignmentSyncFragmentDoc = gql`
    fragment BasicAssignmentSync on AssignmentSync {
  __typename
  ...SyncHistoryLatestAttempt
  id
  classSync {
    id
    label
  }
}
    ${SyncHistoryLatestAttemptFragmentDoc}`;
export const BasicMaterialSyncFragmentDoc = gql`
    fragment BasicMaterialSync on MaterialSync {
  __typename
  ...SyncHistoryLatestAttempt
  id
  classSync {
    id
    label
  }
}
    ${SyncHistoryLatestAttemptFragmentDoc}`;
export const SegmentDecorationWithAssignmentSyncFragmentDoc = gql`
    fragment SegmentDecorationWithAssignmentSync on SegmentDecoration {
  assignmentSyncs {
    ...BasicAssignmentSync
  }
  materialSyncs {
    ...BasicMaterialSync
  }
}
    ${BasicAssignmentSyncFragmentDoc}
${BasicMaterialSyncFragmentDoc}`;
export const ClassSyncFragmentDoc = gql`
    fragment ClassSync on ClassSync {
  id
  provider
  providerId
  label
  canDelete
}
    `;
export const ClassWithSyncsFragmentDoc = gql`
    fragment ClassWithSyncs on Class {
  id
  canCreateClassSync
  canAttachStudentAccounts
  syncs(first: 32) {
    edges {
      node {
        ...ClassSync
      }
    }
  }
}
    ${ClassSyncFragmentDoc}`;
export const ListClassSyncHistoryFragmentDoc = gql`
    fragment ListClassSyncHistory on ClassSyncHistory {
  ...BasicClassSyncHistory
  classSync {
    id
    label
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const DetailedClassSyncHistoryFragmentDoc = gql`
    fragment DetailedClassSyncHistory on ClassSyncHistory {
  ...BasicClassSyncHistory
  classSync {
    id
  }
  materialSync {
    id
  }
  assignmentSync {
    id
  }
  startedAt
  completedAt
  type
  status
  detail {
    __typename
    ... on BaseClassSyncHistoryDetail {
      userId
    }
    ... on ClassSyncHistoryErrorDetail {
      stack
      message
      name
      responseData
      responseStatus
    }
  }
  logUrl
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const AssignmentSyncFragmentDoc = gql`
    fragment AssignmentSync on AssignmentSync {
  ...SyncHistoryLatestAttempt
  __typename
  id
  provider
  providerId
  classSync {
    id
    label
  }
}
    ${SyncHistoryLatestAttemptFragmentDoc}`;
export const GroupImportFragmentDoc = gql`
    fragment GroupImport on GroupImport {
  importId
  name
  createdAt
}
    `;
export const GroupImportGroupFragmentDoc = gql`
    fragment GroupImportGroup on GroupImportGroup {
  groupId
  name
  createdAt
  leaderStudentId
  memberStudentIds
}
    `;
export const GradeCategoryImportSourceFragmentDoc = gql`
    fragment GradeCategoryImportSource on GradeCategoryImportSource {
  sourceId
  name
}
    `;
export const ClassSyncProviderConfigurationFragmentDoc = gql`
    fragment ClassSyncProviderConfiguration on ClassSyncProviderConfiguration {
  providerIdentifier
  enabledFeatures
}
    `;
export const ClassSyncSiteConfigurationFragmentDoc = gql`
    fragment ClassSyncSiteConfiguration on SiteConfiguration {
  classSyncProviders {
    ...ClassSyncProviderConfiguration
  }
}
    ${ClassSyncProviderConfigurationFragmentDoc}`;
export const SiteClassSyncProviderFragmentDoc = gql`
    fragment SiteClassSyncProvider on SiteClassSyncProvider {
  identifier
  name
  authProviderId
  authProviderName
  supportedFeatures
  enabledFeatures
  requiresSetup
}
    `;
export const ClassSyncRosterActionFragmentDoc = gql`
    fragment ClassSyncRosterAction on ClassSyncRosterAction {
  mode
  student {
    id
    user {
      ...User
    }
  }
  providerStudent {
    id
    group
    userInfo {
      firstName
      lastName
      email
    }
  }
}
    ${UserFragmentDoc}`;
export const SyncHistoryOverviewFragmentDoc = gql`
    fragment SyncHistoryOverview on WithClassSyncHistory {
  latestMetadataSync: history(
    first: 1
    orderBy: "completedAt"
    orderDirection: DESC
    filters: {type: [BASIC, WITH_GRADES]}
  ) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
  latestGradeSync: history(
    first: 1
    orderBy: "completedAt"
    orderDirection: DESC
    filters: {type: [WITH_GRADES, ONLY_GRADES]}
  ) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const DuplicateUserPendingStudentClassMetadataFragmentDoc = gql`
    fragment DuplicateUserPendingStudentClassMetadata on DuplicateUserPendingStudentClassMetadata {
  matchedStudent {
    id
    user {
      id
      name
      username
      email
    }
  }
}
    `;
export const WithDuplicateUserPendingStudentClassMetadataFragmentDoc = gql`
    fragment WithDuplicateUserPendingStudentClassMetadata on PendingStudentClass {
  duplicateUserMetadata {
    ...DuplicateUserPendingStudentClassMetadata
  }
}
    ${DuplicateUserPendingStudentClassMetadataFragmentDoc}`;
export const ClassSyncProviderClassFragmentDoc = gql`
    fragment ClassSyncProviderClass on ClassSyncProviderClass {
  id
  name
  isSuggested
  isAttached
}
    `;
export const AdminClassFragmentDoc = gql`
    fragment AdminClass on Class {
  ...BasicClass
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const StudentClassesEdgeFragmentDoc = gql`
    fragment StudentClassesEdge on StudentClassesEdge {
  node {
    ...MinimalClass
  }
}
    ${MinimalClassFragmentDoc}`;
export const StudentClassFragmentDoc = gql`
    fragment StudentClass on Class {
  ...BasicClass
  students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const PendingStudentClassFragmentDoc = gql`
    fragment PendingStudentClass on PendingStudentClass {
  id
  student {
    id
    user {
      id
      name
      username
      email
    }
  }
  state
  reason
  createdAt
  approvedAt
  declinedAt
}
    `;
export const BasicCourseVersionDocument = gql`
    query basicCourseVersion($id: ID!) {
  node(id: $id) {
    ...BasicCourseVersion
  }
}
    ${BasicCourseVersionFragmentDoc}`;

/**
 * __useBasicCourseVersionQuery__
 *
 * To run a query within a React component, call `useBasicCourseVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicCourseVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicCourseVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasicCourseVersionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<BasicCourseVersionQuery, BasicCourseVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<BasicCourseVersionQuery, BasicCourseVersionQueryVariables>(BasicCourseVersionDocument, options);
      }
export function useBasicCourseVersionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<BasicCourseVersionQuery, BasicCourseVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<BasicCourseVersionQuery, BasicCourseVersionQueryVariables>(BasicCourseVersionDocument, options);
        }
export type BasicCourseVersionQueryHookResult = ReturnType<typeof useBasicCourseVersionQuery>;
export type BasicCourseVersionLazyQueryHookResult = ReturnType<typeof useBasicCourseVersionLazyQuery>;
export type BasicCourseVersionQueryResult = ApolloReactCommon.QueryResult<BasicCourseVersionQuery, BasicCourseVersionQueryVariables>;
export const BuiltSegmentDocument = gql`
    query builtSegment($id: ID!) {
  node(id: $id) {
    __typename
    id
    ...HydratedSegment
    ... on Segment {
      classIdsWithSegment
    }
  }
}
    ${HydratedSegmentFragmentDoc}`;

/**
 * __useBuiltSegmentQuery__
 *
 * To run a query within a React component, call `useBuiltSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuiltSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuiltSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuiltSegmentQuery(baseOptions: ApolloReactCommon.QueryHookOptions<BuiltSegmentQuery, BuiltSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<BuiltSegmentQuery, BuiltSegmentQueryVariables>(BuiltSegmentDocument, options);
      }
export function useBuiltSegmentLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<BuiltSegmentQuery, BuiltSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<BuiltSegmentQuery, BuiltSegmentQueryVariables>(BuiltSegmentDocument, options);
        }
export type BuiltSegmentQueryHookResult = ReturnType<typeof useBuiltSegmentQuery>;
export type BuiltSegmentLazyQueryHookResult = ReturnType<typeof useBuiltSegmentLazyQuery>;
export type BuiltSegmentQueryResult = ApolloReactCommon.QueryResult<BuiltSegmentQuery, BuiltSegmentQueryVariables>;
export const ListBuiltSegmentsDocument = gql`
    query listBuiltSegments($first: Int = 16, $after: String, $userIds: [ID!], $types: [SegmentType!], $name: String, $labels: [String!], $tags: [TagFilterInput!], $tagsOp: FilterOperation, $hiddenFromTeachers: Boolean, $filters: SegmentsFilters, $orderBy: String = "name", $orderDirection: OrderDirection = ASC) {
  segments(
    first: $first
    after: $after
    userIds: $userIds
    types: $types
    name: $name
    labels: $labels
    tags: $tags
    tagsOp: $tagsOp
    orderBy: $orderBy
    orderDirection: $orderDirection
    hiddenFromTeachers: $hiddenFromTeachers
    filters: $filters
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
    edges {
      cursor
      node {
        ...HydratedSegment
      }
    }
  }
}
    ${HydratedSegmentFragmentDoc}`;

/**
 * __useListBuiltSegmentsQuery__
 *
 * To run a query within a React component, call `useListBuiltSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBuiltSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBuiltSegmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      userIds: // value for 'userIds'
 *      types: // value for 'types'
 *      name: // value for 'name'
 *      labels: // value for 'labels'
 *      tags: // value for 'tags'
 *      tagsOp: // value for 'tagsOp'
 *      hiddenFromTeachers: // value for 'hiddenFromTeachers'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useListBuiltSegmentsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ListBuiltSegmentsQuery, ListBuiltSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ListBuiltSegmentsQuery, ListBuiltSegmentsQueryVariables>(ListBuiltSegmentsDocument, options);
      }
export function useListBuiltSegmentsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ListBuiltSegmentsQuery, ListBuiltSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ListBuiltSegmentsQuery, ListBuiltSegmentsQueryVariables>(ListBuiltSegmentsDocument, options);
        }
export type ListBuiltSegmentsQueryHookResult = ReturnType<typeof useListBuiltSegmentsQuery>;
export type ListBuiltSegmentsLazyQueryHookResult = ReturnType<typeof useListBuiltSegmentsLazyQuery>;
export type ListBuiltSegmentsQueryResult = ApolloReactCommon.QueryResult<ListBuiltSegmentsQuery, ListBuiltSegmentsQueryVariables>;
export const ClassCourseDocument = gql`
    query classCourse($id: ID!) {
  node(id: $id) {
    ...ClassCourse
  }
}
    ${ClassCourseFragmentDoc}`;

/**
 * __useClassCourseQuery__
 *
 * To run a query within a React component, call `useClassCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassCourseQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassCourseQuery, ClassCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassCourseQuery, ClassCourseQueryVariables>(ClassCourseDocument, options);
      }
export function useClassCourseLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassCourseQuery, ClassCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassCourseQuery, ClassCourseQueryVariables>(ClassCourseDocument, options);
        }
export type ClassCourseQueryHookResult = ReturnType<typeof useClassCourseQuery>;
export type ClassCourseLazyQueryHookResult = ReturnType<typeof useClassCourseLazyQuery>;
export type ClassCourseQueryResult = ApolloReactCommon.QueryResult<ClassCourseQuery, ClassCourseQueryVariables>;
export const ClassDecorationsDocument = gql`
    query classDecorations($classId: ID!) {
  node(id: $classId) {
    ... on Class {
      id
      segmentDecorations {
        ...SegmentDecoration
      }
    }
  }
}
    ${SegmentDecorationFragmentDoc}`;

/**
 * __useClassDecorationsQuery__
 *
 * To run a query within a React component, call `useClassDecorationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassDecorationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassDecorationsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassDecorationsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassDecorationsQuery, ClassDecorationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassDecorationsQuery, ClassDecorationsQueryVariables>(ClassDecorationsDocument, options);
      }
export function useClassDecorationsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassDecorationsQuery, ClassDecorationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassDecorationsQuery, ClassDecorationsQueryVariables>(ClassDecorationsDocument, options);
        }
export type ClassDecorationsQueryHookResult = ReturnType<typeof useClassDecorationsQuery>;
export type ClassDecorationsLazyQueryHookResult = ReturnType<typeof useClassDecorationsLazyQuery>;
export type ClassDecorationsQueryResult = ApolloReactCommon.QueryResult<ClassDecorationsQuery, ClassDecorationsQueryVariables>;
export const ClassNamesDocument = gql`
    query classNames {
  viewer {
    id
    teacher {
      id
      classes(first: 1000, includeDeleted: true) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClassNamesQuery__
 *
 * To run a query within a React component, call `useClassNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassNamesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ClassNamesQuery, ClassNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassNamesQuery, ClassNamesQueryVariables>(ClassNamesDocument, options);
      }
export function useClassNamesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassNamesQuery, ClassNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassNamesQuery, ClassNamesQueryVariables>(ClassNamesDocument, options);
        }
export type ClassNamesQueryHookResult = ReturnType<typeof useClassNamesQuery>;
export type ClassNamesLazyQueryHookResult = ReturnType<typeof useClassNamesLazyQuery>;
export type ClassNamesQueryResult = ApolloReactCommon.QueryResult<ClassNamesQuery, ClassNamesQueryVariables>;
export const ClassesWithSegmentDocument = gql`
    query classesWithSegment($id: ID!) {
  node(id: $id) {
    id
    ... on Segment {
      name
      classIdsWithSegment
    }
  }
}
    `;

/**
 * __useClassesWithSegmentQuery__
 *
 * To run a query within a React component, call `useClassesWithSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesWithSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesWithSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassesWithSegmentQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassesWithSegmentQuery, ClassesWithSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassesWithSegmentQuery, ClassesWithSegmentQueryVariables>(ClassesWithSegmentDocument, options);
      }
export function useClassesWithSegmentLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassesWithSegmentQuery, ClassesWithSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassesWithSegmentQuery, ClassesWithSegmentQueryVariables>(ClassesWithSegmentDocument, options);
        }
export type ClassesWithSegmentQueryHookResult = ReturnType<typeof useClassesWithSegmentQuery>;
export type ClassesWithSegmentLazyQueryHookResult = ReturnType<typeof useClassesWithSegmentLazyQuery>;
export type ClassesWithSegmentQueryResult = ApolloReactCommon.QueryResult<ClassesWithSegmentQuery, ClassesWithSegmentQueryVariables>;
export const CopySegmentAsOwnDocument = gql`
    mutation copySegmentAsOwn($input: CopySegmentAsOwnInput!) {
  copySegmentAsOwn(input: $input) {
    class {
      ...Class
      segmentMetadata
    }
    segment {
      ...Segment
    }
    bakedConfigurationParent
  }
}
    ${ClassFragmentDoc}
${SegmentFragmentDoc}`;
export type CopySegmentAsOwnMutationFn = ApolloReactCommon.MutationFunction<CopySegmentAsOwnMutation, CopySegmentAsOwnMutationVariables>;

/**
 * __useCopySegmentAsOwnMutation__
 *
 * To run a mutation, you first call `useCopySegmentAsOwnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySegmentAsOwnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySegmentAsOwnMutation, { data, loading, error }] = useCopySegmentAsOwnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySegmentAsOwnMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CopySegmentAsOwnMutation, CopySegmentAsOwnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CopySegmentAsOwnMutation, CopySegmentAsOwnMutationVariables>(CopySegmentAsOwnDocument, options);
      }
export type CopySegmentAsOwnMutationHookResult = ReturnType<typeof useCopySegmentAsOwnMutation>;
export type CopySegmentAsOwnMutationResult = ApolloReactCommon.MutationResult<CopySegmentAsOwnMutation>;
export type CopySegmentAsOwnMutationOptions = ApolloReactCommon.BaseMutationOptions<CopySegmentAsOwnMutation, CopySegmentAsOwnMutationVariables>;
export const CopySegmentSettingsDocument = gql`
    mutation copySegmentSettings($input: CopySegmentSettingsInput!) {
  copySegmentSettings(input: $input) {
    classes {
      id
    }
  }
}
    `;
export type CopySegmentSettingsMutationFn = ApolloReactCommon.MutationFunction<CopySegmentSettingsMutation, CopySegmentSettingsMutationVariables>;

/**
 * __useCopySegmentSettingsMutation__
 *
 * To run a mutation, you first call `useCopySegmentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopySegmentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copySegmentSettingsMutation, { data, loading, error }] = useCopySegmentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopySegmentSettingsMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CopySegmentSettingsMutation, CopySegmentSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CopySegmentSettingsMutation, CopySegmentSettingsMutationVariables>(CopySegmentSettingsDocument, options);
      }
export type CopySegmentSettingsMutationHookResult = ReturnType<typeof useCopySegmentSettingsMutation>;
export type CopySegmentSettingsMutationResult = ApolloReactCommon.MutationResult<CopySegmentSettingsMutation>;
export type CopySegmentSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CopySegmentSettingsMutation, CopySegmentSettingsMutationVariables>;
export const CopyTagsToChildrenDocument = gql`
    mutation copyTagsToChildren($input: CopyTagsToChildrenInput!) {
  copyTagsToChildren(input: $input) {
    success
  }
}
    `;
export type CopyTagsToChildrenMutationFn = ApolloReactCommon.MutationFunction<CopyTagsToChildrenMutation, CopyTagsToChildrenMutationVariables>;

/**
 * __useCopyTagsToChildrenMutation__
 *
 * To run a mutation, you first call `useCopyTagsToChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyTagsToChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyTagsToChildrenMutation, { data, loading, error }] = useCopyTagsToChildrenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyTagsToChildrenMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CopyTagsToChildrenMutation, CopyTagsToChildrenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CopyTagsToChildrenMutation, CopyTagsToChildrenMutationVariables>(CopyTagsToChildrenDocument, options);
      }
export type CopyTagsToChildrenMutationHookResult = ReturnType<typeof useCopyTagsToChildrenMutation>;
export type CopyTagsToChildrenMutationResult = ApolloReactCommon.MutationResult<CopyTagsToChildrenMutation>;
export type CopyTagsToChildrenMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyTagsToChildrenMutation, CopyTagsToChildrenMutationVariables>;
export const CourseDocument = gql`
    query course($id: ID!) {
  node(id: $id) {
    ...Course
    ... on Course {
      latestPublishedVersion {
        ...CourseVersion
      }
    }
  }
}
    ${CourseFragmentDoc}
${CourseVersionFragmentDoc}`;

/**
 * __useCourseQuery__
 *
 * To run a query within a React component, call `useCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseQuery(baseOptions: ApolloReactCommon.QueryHookOptions<CourseQuery, CourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<CourseQuery, CourseQueryVariables>(CourseDocument, options);
      }
export function useCourseLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<CourseQuery, CourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<CourseQuery, CourseQueryVariables>(CourseDocument, options);
        }
export type CourseQueryHookResult = ReturnType<typeof useCourseQuery>;
export type CourseLazyQueryHookResult = ReturnType<typeof useCourseLazyQuery>;
export type CourseQueryResult = ApolloReactCommon.QueryResult<CourseQuery, CourseQueryVariables>;
export const CourseVersionDocument = gql`
    query courseVersion($id: ID!) {
  node(id: $id) {
    ...CourseVersion
  }
}
    ${CourseVersionFragmentDoc}`;

/**
 * __useCourseVersionQuery__
 *
 * To run a query within a React component, call `useCourseVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseVersionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<CourseVersionQuery, CourseVersionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<CourseVersionQuery, CourseVersionQueryVariables>(CourseVersionDocument, options);
      }
export function useCourseVersionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<CourseVersionQuery, CourseVersionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<CourseVersionQuery, CourseVersionQueryVariables>(CourseVersionDocument, options);
        }
export type CourseVersionQueryHookResult = ReturnType<typeof useCourseVersionQuery>;
export type CourseVersionLazyQueryHookResult = ReturnType<typeof useCourseVersionLazyQuery>;
export type CourseVersionQueryResult = ApolloReactCommon.QueryResult<CourseVersionQuery, CourseVersionQueryVariables>;
export const CourseVersionsDocument = gql`
    query courseVersions($courseId: ID!) {
  node(id: $courseId) {
    ... on Course {
      id
      __typename
      versions(first: 100, orderBy: "name", orderDirection: ASC) {
        totalCount
        edges {
          node {
            ...BasicCourseVersion
          }
        }
      }
    }
  }
}
    ${BasicCourseVersionFragmentDoc}`;

/**
 * __useCourseVersionsQuery__
 *
 * To run a query within a React component, call `useCourseVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseVersionsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseVersionsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<CourseVersionsQuery, CourseVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<CourseVersionsQuery, CourseVersionsQueryVariables>(CourseVersionsDocument, options);
      }
export function useCourseVersionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<CourseVersionsQuery, CourseVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<CourseVersionsQuery, CourseVersionsQueryVariables>(CourseVersionsDocument, options);
        }
export type CourseVersionsQueryHookResult = ReturnType<typeof useCourseVersionsQuery>;
export type CourseVersionsLazyQueryHookResult = ReturnType<typeof useCourseVersionsLazyQuery>;
export type CourseVersionsQueryResult = ApolloReactCommon.QueryResult<CourseVersionsQuery, CourseVersionsQueryVariables>;
export const CreateClassWithPlacedContentDocument = gql`
    mutation createClassWithPlacedContent($input: CreateClassWithPlacedContentInput!) {
  createClassWithPlacedContent(input: $input) {
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type CreateClassWithPlacedContentMutationFn = ApolloReactCommon.MutationFunction<CreateClassWithPlacedContentMutation, CreateClassWithPlacedContentMutationVariables>;

/**
 * __useCreateClassWithPlacedContentMutation__
 *
 * To run a mutation, you first call `useCreateClassWithPlacedContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassWithPlacedContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassWithPlacedContentMutation, { data, loading, error }] = useCreateClassWithPlacedContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassWithPlacedContentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateClassWithPlacedContentMutation, CreateClassWithPlacedContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateClassWithPlacedContentMutation, CreateClassWithPlacedContentMutationVariables>(CreateClassWithPlacedContentDocument, options);
      }
export type CreateClassWithPlacedContentMutationHookResult = ReturnType<typeof useCreateClassWithPlacedContentMutation>;
export type CreateClassWithPlacedContentMutationResult = ApolloReactCommon.MutationResult<CreateClassWithPlacedContentMutation>;
export type CreateClassWithPlacedContentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClassWithPlacedContentMutation, CreateClassWithPlacedContentMutationVariables>;
export const CreateCourseDocument = gql`
    mutation createCourse($input: CourseInput!) {
  createCourse(input: $input) {
    course {
      ...Course
    }
  }
}
    ${CourseFragmentDoc}`;
export type CreateCourseMutationFn = ApolloReactCommon.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = ApolloReactCommon.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const CreateCourseVersionDocument = gql`
    mutation createCourseVersion($input: CreateCourseVersionInput!) {
  createCourseVersion(input: $input) {
    courseVersion {
      ...BasicCourseVersion
    }
  }
}
    ${BasicCourseVersionFragmentDoc}`;
export type CreateCourseVersionMutationFn = ApolloReactCommon.MutationFunction<CreateCourseVersionMutation, CreateCourseVersionMutationVariables>;

/**
 * __useCreateCourseVersionMutation__
 *
 * To run a mutation, you first call `useCreateCourseVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseVersionMutation, { data, loading, error }] = useCreateCourseVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseVersionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateCourseVersionMutation, CreateCourseVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateCourseVersionMutation, CreateCourseVersionMutationVariables>(CreateCourseVersionDocument, options);
      }
export type CreateCourseVersionMutationHookResult = ReturnType<typeof useCreateCourseVersionMutation>;
export type CreateCourseVersionMutationResult = ApolloReactCommon.MutationResult<CreateCourseVersionMutation>;
export type CreateCourseVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCourseVersionMutation, CreateCourseVersionMutationVariables>;
export const CreateOrUpdateSegmentDocument = gql`
    mutation createOrUpdateSegment($input: SegmentInput!) {
  createOrUpdateSegment(input: $input) {
    segment {
      id
      built(input: {hydrated: true})
    }
  }
}
    `;
export type CreateOrUpdateSegmentMutationFn = ApolloReactCommon.MutationFunction<CreateOrUpdateSegmentMutation, CreateOrUpdateSegmentMutationVariables>;

/**
 * __useCreateOrUpdateSegmentMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateSegmentMutation, { data, loading, error }] = useCreateOrUpdateSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateSegmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateOrUpdateSegmentMutation, CreateOrUpdateSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateOrUpdateSegmentMutation, CreateOrUpdateSegmentMutationVariables>(CreateOrUpdateSegmentDocument, options);
      }
export type CreateOrUpdateSegmentMutationHookResult = ReturnType<typeof useCreateOrUpdateSegmentMutation>;
export type CreateOrUpdateSegmentMutationResult = ApolloReactCommon.MutationResult<CreateOrUpdateSegmentMutation>;
export type CreateOrUpdateSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrUpdateSegmentMutation, CreateOrUpdateSegmentMutationVariables>;
export const DeleteSegmentDocument = gql`
    mutation deleteSegment($input: DeleteSegmentInput!) {
  deleteSegment(input: $input) {
    segmentId
  }
}
    `;
export type DeleteSegmentMutationFn = ApolloReactCommon.MutationFunction<DeleteSegmentMutation, DeleteSegmentMutationVariables>;

/**
 * __useDeleteSegmentMutation__
 *
 * To run a mutation, you first call `useDeleteSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSegmentMutation, { data, loading, error }] = useDeleteSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSegmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<DeleteSegmentMutation, DeleteSegmentMutationVariables>(DeleteSegmentDocument, options);
      }
export type DeleteSegmentMutationHookResult = ReturnType<typeof useDeleteSegmentMutation>;
export type DeleteSegmentMutationResult = ApolloReactCommon.MutationResult<DeleteSegmentMutation>;
export type DeleteSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>;
export const DetachSegmentDocument = gql`
    mutation detachSegment($input: DetachSegmentInput!) {
  detachSegment(input: $input) {
    courseVersion {
      ...CourseVersion
    }
  }
}
    ${CourseVersionFragmentDoc}`;
export type DetachSegmentMutationFn = ApolloReactCommon.MutationFunction<DetachSegmentMutation, DetachSegmentMutationVariables>;

/**
 * __useDetachSegmentMutation__
 *
 * To run a mutation, you first call `useDetachSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachSegmentMutation, { data, loading, error }] = useDetachSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDetachSegmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<DetachSegmentMutation, DetachSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<DetachSegmentMutation, DetachSegmentMutationVariables>(DetachSegmentDocument, options);
      }
export type DetachSegmentMutationHookResult = ReturnType<typeof useDetachSegmentMutation>;
export type DetachSegmentMutationResult = ApolloReactCommon.MutationResult<DetachSegmentMutation>;
export type DetachSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DetachSegmentMutation, DetachSegmentMutationVariables>;
export const FetchSegmentDocument = gql`
    query fetchSegment($id: ID!) {
  node(id: $id) {
    __typename
    ...Segment
  }
}
    ${SegmentFragmentDoc}`;

/**
 * __useFetchSegmentQuery__
 *
 * To run a query within a React component, call `useFetchSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSegmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchSegmentQuery(baseOptions: ApolloReactCommon.QueryHookOptions<FetchSegmentQuery, FetchSegmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<FetchSegmentQuery, FetchSegmentQueryVariables>(FetchSegmentDocument, options);
      }
export function useFetchSegmentLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<FetchSegmentQuery, FetchSegmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<FetchSegmentQuery, FetchSegmentQueryVariables>(FetchSegmentDocument, options);
        }
export type FetchSegmentQueryHookResult = ReturnType<typeof useFetchSegmentQuery>;
export type FetchSegmentLazyQueryHookResult = ReturnType<typeof useFetchSegmentLazyQuery>;
export type FetchSegmentQueryResult = ApolloReactCommon.QueryResult<FetchSegmentQuery, FetchSegmentQueryVariables>;
export const ImportSegmentsDocument = gql`
    mutation importSegments($input: ImportSegmentsInput!) {
  importSegments(input: $input) {
    success
  }
}
    `;
export type ImportSegmentsMutationFn = ApolloReactCommon.MutationFunction<ImportSegmentsMutation, ImportSegmentsMutationVariables>;

/**
 * __useImportSegmentsMutation__
 *
 * To run a mutation, you first call `useImportSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importSegmentsMutation, { data, loading, error }] = useImportSegmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportSegmentsMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ImportSegmentsMutation, ImportSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ImportSegmentsMutation, ImportSegmentsMutationVariables>(ImportSegmentsDocument, options);
      }
export type ImportSegmentsMutationHookResult = ReturnType<typeof useImportSegmentsMutation>;
export type ImportSegmentsMutationResult = ApolloReactCommon.MutationResult<ImportSegmentsMutation>;
export type ImportSegmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportSegmentsMutation, ImportSegmentsMutationVariables>;
export const ListCoursesDocument = gql`
    query listCourses($first: Int = 32, $after: String, $orderBy: String, $orderDirection: OrderDirection, $isLicensed: Boolean, $isHidden: Boolean, $isByoc: Boolean, $name: String, $withVersionLabels: Boolean = false) {
  courses(
    first: $first
    after: $after
    orderBy: $orderBy
    orderDirection: $orderDirection
    isLicensed: $isLicensed
    isHidden: $isHidden
    isByoc: $isByoc
    name: $name
  ) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      cursor
      node {
        ...Course
        latestPublishedVersion @include(if: $withVersionLabels) {
          id
          allLabels
        }
      }
    }
  }
}
    ${CourseFragmentDoc}`;

/**
 * __useListCoursesQuery__
 *
 * To run a query within a React component, call `useListCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCoursesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      isLicensed: // value for 'isLicensed'
 *      isHidden: // value for 'isHidden'
 *      isByoc: // value for 'isByoc'
 *      name: // value for 'name'
 *      withVersionLabels: // value for 'withVersionLabels'
 *   },
 * });
 */
export function useListCoursesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ListCoursesQuery, ListCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ListCoursesQuery, ListCoursesQueryVariables>(ListCoursesDocument, options);
      }
export function useListCoursesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ListCoursesQuery, ListCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ListCoursesQuery, ListCoursesQueryVariables>(ListCoursesDocument, options);
        }
export type ListCoursesQueryHookResult = ReturnType<typeof useListCoursesQuery>;
export type ListCoursesLazyQueryHookResult = ReturnType<typeof useListCoursesLazyQuery>;
export type ListCoursesQueryResult = ApolloReactCommon.QueryResult<ListCoursesQuery, ListCoursesQueryVariables>;
export const PublishCourseVersionDocument = gql`
    mutation publishCourseVersion($input: PublishCourseVersionInput!) {
  publishCourseVersion(input: $input) {
    courseVersion {
      ...BasicCourseVersion
    }
  }
}
    ${BasicCourseVersionFragmentDoc}`;
export type PublishCourseVersionMutationFn = ApolloReactCommon.MutationFunction<PublishCourseVersionMutation, PublishCourseVersionMutationVariables>;

/**
 * __usePublishCourseVersionMutation__
 *
 * To run a mutation, you first call `usePublishCourseVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishCourseVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishCourseVersionMutation, { data, loading, error }] = usePublishCourseVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishCourseVersionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<PublishCourseVersionMutation, PublishCourseVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<PublishCourseVersionMutation, PublishCourseVersionMutationVariables>(PublishCourseVersionDocument, options);
      }
export type PublishCourseVersionMutationHookResult = ReturnType<typeof usePublishCourseVersionMutation>;
export type PublishCourseVersionMutationResult = ApolloReactCommon.MutationResult<PublishCourseVersionMutation>;
export type PublishCourseVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishCourseVersionMutation, PublishCourseVersionMutationVariables>;
export const SegmentHydrationDocument = gql`
    query segmentHydration($id: ID!) {
  node(id: $id) {
    __typename
    id
    ...SegmentWithHydrationData
  }
}
    ${SegmentWithHydrationDataFragmentDoc}`;

/**
 * __useSegmentHydrationQuery__
 *
 * To run a query within a React component, call `useSegmentHydrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentHydrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentHydrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSegmentHydrationQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SegmentHydrationQuery, SegmentHydrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SegmentHydrationQuery, SegmentHydrationQueryVariables>(SegmentHydrationDocument, options);
      }
export function useSegmentHydrationLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SegmentHydrationQuery, SegmentHydrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SegmentHydrationQuery, SegmentHydrationQueryVariables>(SegmentHydrationDocument, options);
        }
export type SegmentHydrationQueryHookResult = ReturnType<typeof useSegmentHydrationQuery>;
export type SegmentHydrationLazyQueryHookResult = ReturnType<typeof useSegmentHydrationLazyQuery>;
export type SegmentHydrationQueryResult = ApolloReactCommon.QueryResult<SegmentHydrationQuery, SegmentHydrationQueryVariables>;
export const SegmentTimeOnTaskDocument = gql`
    query segmentTimeOnTask($classId: ID!, $input: TimeOnTaskInput!) {
  node(id: $classId) {
    id
    ... on Class {
      timeOnTask(input: $input) {
        timeOnTasks {
          studentUserId
          timeOnTaskMinutes
        }
        totalMinutes
      }
    }
  }
}
    `;

/**
 * __useSegmentTimeOnTaskQuery__
 *
 * To run a query within a React component, call `useSegmentTimeOnTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentTimeOnTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentTimeOnTaskQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSegmentTimeOnTaskQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SegmentTimeOnTaskQuery, SegmentTimeOnTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SegmentTimeOnTaskQuery, SegmentTimeOnTaskQueryVariables>(SegmentTimeOnTaskDocument, options);
      }
export function useSegmentTimeOnTaskLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SegmentTimeOnTaskQuery, SegmentTimeOnTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SegmentTimeOnTaskQuery, SegmentTimeOnTaskQueryVariables>(SegmentTimeOnTaskDocument, options);
        }
export type SegmentTimeOnTaskQueryHookResult = ReturnType<typeof useSegmentTimeOnTaskQuery>;
export type SegmentTimeOnTaskLazyQueryHookResult = ReturnType<typeof useSegmentTimeOnTaskLazyQuery>;
export type SegmentTimeOnTaskQueryResult = ApolloReactCommon.QueryResult<SegmentTimeOnTaskQuery, SegmentTimeOnTaskQueryVariables>;
export const SegmentUseInUnownedClassesDocument = gql`
    query segmentUseInUnownedClasses($segmentId: ID!) {
  node(id: $segmentId) {
    id
    ... on Segment {
      useInUnownedClasses
    }
  }
}
    `;

/**
 * __useSegmentUseInUnownedClassesQuery__
 *
 * To run a query within a React component, call `useSegmentUseInUnownedClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentUseInUnownedClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentUseInUnownedClassesQuery({
 *   variables: {
 *      segmentId: // value for 'segmentId'
 *   },
 * });
 */
export function useSegmentUseInUnownedClassesQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SegmentUseInUnownedClassesQuery, SegmentUseInUnownedClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SegmentUseInUnownedClassesQuery, SegmentUseInUnownedClassesQueryVariables>(SegmentUseInUnownedClassesDocument, options);
      }
export function useSegmentUseInUnownedClassesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SegmentUseInUnownedClassesQuery, SegmentUseInUnownedClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SegmentUseInUnownedClassesQuery, SegmentUseInUnownedClassesQueryVariables>(SegmentUseInUnownedClassesDocument, options);
        }
export type SegmentUseInUnownedClassesQueryHookResult = ReturnType<typeof useSegmentUseInUnownedClassesQuery>;
export type SegmentUseInUnownedClassesLazyQueryHookResult = ReturnType<typeof useSegmentUseInUnownedClassesLazyQuery>;
export type SegmentUseInUnownedClassesQueryResult = ApolloReactCommon.QueryResult<SegmentUseInUnownedClassesQuery, SegmentUseInUnownedClassesQueryVariables>;
export const ListSegmentsDocument = gql`
    query listSegments($first: Int = 16, $after: String, $userIds: [ID!], $types: [SegmentType!], $name: String, $labels: [String!], $tags: [TagFilterInput!], $tagsOp: FilterOperation, $hiddenFromTeachers: Boolean, $filters: SegmentsFilters) {
  segments(
    first: $first
    after: $after
    filters: $filters
    userIds: $userIds
    types: $types
    name: $name
    labels: $labels
    tags: $tags
    tagsOp: $tagsOp
    hiddenFromTeachers: $hiddenFromTeachers
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
    edges {
      cursor
      node {
        ...BuiltSegment
      }
    }
  }
}
    ${BuiltSegmentFragmentDoc}`;

/**
 * __useListSegmentsQuery__
 *
 * To run a query within a React component, call `useListSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSegmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      userIds: // value for 'userIds'
 *      types: // value for 'types'
 *      name: // value for 'name'
 *      labels: // value for 'labels'
 *      tags: // value for 'tags'
 *      tagsOp: // value for 'tagsOp'
 *      hiddenFromTeachers: // value for 'hiddenFromTeachers'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListSegmentsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ListSegmentsQuery, ListSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ListSegmentsQuery, ListSegmentsQueryVariables>(ListSegmentsDocument, options);
      }
export function useListSegmentsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ListSegmentsQuery, ListSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ListSegmentsQuery, ListSegmentsQueryVariables>(ListSegmentsDocument, options);
        }
export type ListSegmentsQueryHookResult = ReturnType<typeof useListSegmentsQuery>;
export type ListSegmentsLazyQueryHookResult = ReturnType<typeof useListSegmentsLazyQuery>;
export type ListSegmentsQueryResult = ApolloReactCommon.QueryResult<ListSegmentsQuery, ListSegmentsQueryVariables>;
export const SimpleCourseDocument = gql`
    query simpleCourse($id: ID!) {
  node(id: $id) {
    __typename
    id
    ... on Course {
      name
    }
  }
}
    `;

/**
 * __useSimpleCourseQuery__
 *
 * To run a query within a React component, call `useSimpleCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimpleCourseQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SimpleCourseQuery, SimpleCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SimpleCourseQuery, SimpleCourseQueryVariables>(SimpleCourseDocument, options);
      }
export function useSimpleCourseLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SimpleCourseQuery, SimpleCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SimpleCourseQuery, SimpleCourseQueryVariables>(SimpleCourseDocument, options);
        }
export type SimpleCourseQueryHookResult = ReturnType<typeof useSimpleCourseQuery>;
export type SimpleCourseLazyQueryHookResult = ReturnType<typeof useSimpleCourseLazyQuery>;
export type SimpleCourseQueryResult = ApolloReactCommon.QueryResult<SimpleCourseQuery, SimpleCourseQueryVariables>;
export const SyncSegmentDocument = gql`
    mutation syncSegment($input: SyncSegmentInput!) {
  syncSegment(input: $input) {
    class {
      id
      segmentDecorations {
        ...SegmentDecoration
      }
    }
  }
}
    ${SegmentDecorationFragmentDoc}`;
export type SyncSegmentMutationFn = ApolloReactCommon.MutationFunction<SyncSegmentMutation, SyncSegmentMutationVariables>;

/**
 * __useSyncSegmentMutation__
 *
 * To run a mutation, you first call `useSyncSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncSegmentMutation, { data, loading, error }] = useSyncSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncSegmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<SyncSegmentMutation, SyncSegmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<SyncSegmentMutation, SyncSegmentMutationVariables>(SyncSegmentDocument, options);
      }
export type SyncSegmentMutationHookResult = ReturnType<typeof useSyncSegmentMutation>;
export type SyncSegmentMutationResult = ApolloReactCommon.MutationResult<SyncSegmentMutation>;
export type SyncSegmentMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncSegmentMutation, SyncSegmentMutationVariables>;
export const TotalBuiltSegmentsDocument = gql`
    query totalBuiltSegments($userIds: [ID!]) {
  segments(userIds: $userIds) {
    totalCount
  }
}
    `;

/**
 * __useTotalBuiltSegmentsQuery__
 *
 * To run a query within a React component, call `useTotalBuiltSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalBuiltSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalBuiltSegmentsQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useTotalBuiltSegmentsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<TotalBuiltSegmentsQuery, TotalBuiltSegmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TotalBuiltSegmentsQuery, TotalBuiltSegmentsQueryVariables>(TotalBuiltSegmentsDocument, options);
      }
export function useTotalBuiltSegmentsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TotalBuiltSegmentsQuery, TotalBuiltSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TotalBuiltSegmentsQuery, TotalBuiltSegmentsQueryVariables>(TotalBuiltSegmentsDocument, options);
        }
export type TotalBuiltSegmentsQueryHookResult = ReturnType<typeof useTotalBuiltSegmentsQuery>;
export type TotalBuiltSegmentsLazyQueryHookResult = ReturnType<typeof useTotalBuiltSegmentsLazyQuery>;
export type TotalBuiltSegmentsQueryResult = ApolloReactCommon.QueryResult<TotalBuiltSegmentsQuery, TotalBuiltSegmentsQueryVariables>;
export const UpdateClassWithCourseDocument = gql`
    mutation updateClassWithCourse($input: UpdateClassInput!, $refetchCourseConfiguration: Boolean!) {
  updateClass(input: $input) {
    class {
      id
      bakedCourseConfiguration @include(if: $refetchCourseConfiguration)
      segmentMetadata
    }
  }
}
    `;
export type UpdateClassWithCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>;

/**
 * __useUpdateClassWithCourseMutation__
 *
 * To run a mutation, you first call `useUpdateClassWithCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassWithCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassWithCourseMutation, { data, loading, error }] = useUpdateClassWithCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      refetchCourseConfiguration: // value for 'refetchCourseConfiguration'
 *   },
 * });
 */
export function useUpdateClassWithCourseMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>(UpdateClassWithCourseDocument, options);
      }
export type UpdateClassWithCourseMutationHookResult = ReturnType<typeof useUpdateClassWithCourseMutation>;
export type UpdateClassWithCourseMutationResult = ApolloReactCommon.MutationResult<UpdateClassWithCourseMutation>;
export type UpdateClassWithCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClassWithCourseMutation, UpdateClassWithCourseMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation updateCourse($input: UpdateCourseInput!) {
  updateCourse(input: $input) {
    course {
      ...Course
    }
  }
}
    ${CourseFragmentDoc}`;
export type UpdateCourseMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = ApolloReactCommon.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const UpdateCourseVersionDocument = gql`
    mutation updateCourseVersion($input: UpdateCourseVersionInput!) {
  updateCourseVersion(input: $input) {
    courseVersion {
      ...BasicCourseVersion
    }
  }
}
    ${BasicCourseVersionFragmentDoc}`;
export type UpdateCourseVersionMutationFn = ApolloReactCommon.MutationFunction<UpdateCourseVersionMutation, UpdateCourseVersionMutationVariables>;

/**
 * __useUpdateCourseVersionMutation__
 *
 * To run a mutation, you first call `useUpdateCourseVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseVersionMutation, { data, loading, error }] = useUpdateCourseVersionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseVersionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateCourseVersionMutation, UpdateCourseVersionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateCourseVersionMutation, UpdateCourseVersionMutationVariables>(UpdateCourseVersionDocument, options);
      }
export type UpdateCourseVersionMutationHookResult = ReturnType<typeof useUpdateCourseVersionMutation>;
export type UpdateCourseVersionMutationResult = ApolloReactCommon.MutationResult<UpdateCourseVersionMutation>;
export type UpdateCourseVersionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCourseVersionMutation, UpdateCourseVersionMutationVariables>;
export const UpdateSegmentCompletionDocument = gql`
    mutation updateSegmentCompletion($input: UpdateSegmentCompletionInput!) {
  updateSegmentCompletion(input: $input) {
    class {
      id
      segmentDecorations {
        ...SegmentDecoration
      }
    }
  }
}
    ${SegmentDecorationFragmentDoc}`;
export type UpdateSegmentCompletionMutationFn = ApolloReactCommon.MutationFunction<UpdateSegmentCompletionMutation, UpdateSegmentCompletionMutationVariables>;

/**
 * __useUpdateSegmentCompletionMutation__
 *
 * To run a mutation, you first call `useUpdateSegmentCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSegmentCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSegmentCompletionMutation, { data, loading, error }] = useUpdateSegmentCompletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSegmentCompletionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateSegmentCompletionMutation, UpdateSegmentCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateSegmentCompletionMutation, UpdateSegmentCompletionMutationVariables>(UpdateSegmentCompletionDocument, options);
      }
export type UpdateSegmentCompletionMutationHookResult = ReturnType<typeof useUpdateSegmentCompletionMutation>;
export type UpdateSegmentCompletionMutationResult = ApolloReactCommon.MutationResult<UpdateSegmentCompletionMutation>;
export type UpdateSegmentCompletionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSegmentCompletionMutation, UpdateSegmentCompletionMutationVariables>;
export const UserHasCompletedTourDocument = gql`
    query userHasCompletedTour($classId: ID!, $widgetId: String!) {
  viewer {
    id
    hasCompletedTour(classId: $classId, widgetId: $widgetId)
  }
}
    `;

/**
 * __useUserHasCompletedTourQuery__
 *
 * To run a query within a React component, call `useUserHasCompletedTourQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasCompletedTourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasCompletedTourQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      widgetId: // value for 'widgetId'
 *   },
 * });
 */
export function useUserHasCompletedTourQuery(baseOptions: ApolloReactCommon.QueryHookOptions<UserHasCompletedTourQuery, UserHasCompletedTourQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<UserHasCompletedTourQuery, UserHasCompletedTourQueryVariables>(UserHasCompletedTourDocument, options);
      }
export function useUserHasCompletedTourLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<UserHasCompletedTourQuery, UserHasCompletedTourQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<UserHasCompletedTourQuery, UserHasCompletedTourQueryVariables>(UserHasCompletedTourDocument, options);
        }
export type UserHasCompletedTourQueryHookResult = ReturnType<typeof useUserHasCompletedTourQuery>;
export type UserHasCompletedTourLazyQueryHookResult = ReturnType<typeof useUserHasCompletedTourLazyQuery>;
export type UserHasCompletedTourQueryResult = ApolloReactCommon.QueryResult<UserHasCompletedTourQuery, UserHasCompletedTourQueryVariables>;
export const UserHasWatchedVideoDocument = gql`
    query userHasWatchedVideo($id: ID!) {
  viewer {
    id
    hasWatchedVod(id: $id)
  }
}
    `;

/**
 * __useUserHasWatchedVideoQuery__
 *
 * To run a query within a React component, call `useUserHasWatchedVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasWatchedVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasWatchedVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserHasWatchedVideoQuery(baseOptions: ApolloReactCommon.QueryHookOptions<UserHasWatchedVideoQuery, UserHasWatchedVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<UserHasWatchedVideoQuery, UserHasWatchedVideoQueryVariables>(UserHasWatchedVideoDocument, options);
      }
export function useUserHasWatchedVideoLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<UserHasWatchedVideoQuery, UserHasWatchedVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<UserHasWatchedVideoQuery, UserHasWatchedVideoQueryVariables>(UserHasWatchedVideoDocument, options);
        }
export type UserHasWatchedVideoQueryHookResult = ReturnType<typeof useUserHasWatchedVideoQuery>;
export type UserHasWatchedVideoLazyQueryHookResult = ReturnType<typeof useUserHasWatchedVideoLazyQuery>;
export type UserHasWatchedVideoQueryResult = ApolloReactCommon.QueryResult<UserHasWatchedVideoQuery, UserHasWatchedVideoQueryVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}

declare module '@thesisedu/feature-users-react' {
  export interface PermissionsMap extends Record<`${Permission}`, true> {}
}
