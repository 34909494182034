import { Button } from '@thesisedu/react'
import React from 'react'

import { SiteStandardSetsModal, SiteStandardSetsModalProps } from './SiteStandardSetsModal'

export interface SiteStandardSetsFieldProps
  extends Omit<SiteStandardSetsModalProps, 'visible' | 'onClose'> {}
export function SiteStandardSetsField({ ...rest }: SiteStandardSetsFieldProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <SiteStandardSetsModal {...rest} visible={visible} onClose={() => setVisible(false)} />
      <Button onClick={() => setVisible(true)}>Edit Standard Sets</Button>
    </>
  )
}
