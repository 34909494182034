import { NavArrowRight } from '@thesisedu/react/icons'
import { styled, s, Text, LoadingIndicator } from '@thesisedu/ui'
import path from 'path'
import React from 'react'

import { getExtensionIcon } from './ExtensionIconMap'
import { getFileInfo } from './info'
import { useGenericFetchUrlLazyQuery } from './schema'

export * from './info'

export interface FileProps {
  filename: string
  label?: string
  noLink?: boolean
}
export function File({
  filename: _filename,
  label: _label,
  children,
  noLink,
}: React.PropsWithChildren<FileProps>) {
  const { filename, label: fileLabel } = getFileInfo(_filename)
  const shouldFetch = !filename.startsWith('http') && !filename.startsWith('data:')
  const [load, { data, loading }] = useGenericFetchUrlLazyQuery({
    variables: {
      path: filename,
    },
  })
  const loadedFilename = data?.genericFetchUrl.signedUrl
  React.useEffect(() => {
    if (loadedFilename) {
      fetch(loadedFilename)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.blob()
        })
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = blobUrl
          link.download = fileLabel || filename
          link.click()
          return blobUrl
        })
        .then(url => URL.revokeObjectURL(url))
        .catch(error => {
          console.error('There was a problem downloading the file:', error)
        })
    }
  }, [loadedFilename])
  const label = _label || fileLabel
  const extension =
    path.extname(filename.split('?')[0]).slice(1) || path.extname(label || '').slice(1)
  const Icon = getExtensionIcon(extension)
  const linkPath = shouldFetch || noLink ? undefined : filename
  return (
    <Container
      as={linkPath ? ('a' as any) : 'div'}
      href={linkPath}
      target={linkPath ? '_blank' : undefined}
      download={linkPath ? fileLabel || path.basename(filename.split('?')[0]) : undefined}
      onClick={
        shouldFetch
          ? (e: MouseEvent) => {
              e.preventDefault()
              if (shouldFetch) {
                load()
              }
            }
          : undefined
      }
    >
      <ContainerLeft>
        <Text level={'l'} weight={'bold'}>
          <Icon />
        </Text>
      </ContainerLeft>
      <ContainerRight>
        <div>
          <Text level={'l'}>{label}</Text>
          {children}
        </div>
        <IconContainer>{loading ? <LoadingIndicator /> : <NavArrowRight />}</IconContainer>
      </ContainerRight>
    </Container>
  )
}

export function LargeFile(props: FileProps) {
  return <File {...props} />
}

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: ${s.var('radii.2')};
  border: solid 1px ${s.color('gray.border')};
  background: ${s.color('gray.element')};
  color: ${s.color('gray.text')};
  min-height: 50px;
  width: max-content;
  transition:
    color 0.1s linear,
    background 0.1s linear,
    border 0.1s linear;
  &:hover {
    background: ${s.color('gray.hoverElement')};
    color: ${s.color('primary.text')};
    border-color: ${s.color('primary.border')};
  }
`
const ContainerLeft = styled.div`
  background: ${s.color('gray.border')};
  padding: 0 ${s.size('s')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${s.color('gray.text')};
  align-self: stretch;
`
const ContainerRight = styled.div`
  padding: ${s.size('xxs')} ${s.size('s')};
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
`
const IconContainer = styled.div`
  margin-left: auto;
  font-size: ${s.size('s')};
  color: ${s.color('secondary')};
`
