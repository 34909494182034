import { getColor, getSize, styledContainer as styled } from '@thesisedu/react'
import { darken } from 'polished'
import React from 'react'

export interface HeaderCoverageIndicatorProps {
  coverage?: number
}
export function HeaderCoverageIndicator({ coverage }: HeaderCoverageIndicatorProps) {
  return (
    <Container style={{ opacity: coverage ? 1 : 0 }}>
      <Inner style={{ height: `${(coverage || 0) * 100}%` }} />
    </Container>
  )
}

const Container = styled`
  transition: opacity 0.25s linear;
  position: absolute;
  top: 0;
  left: -${props => getSize(props.theme, '@border-radius-base')};
  bottom: 0;
  width: ${props => getSize(props.theme, '@border-radius-base')};
  background: ${props => darken(0.15, getColor(props.theme, '@green-light'))};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: stretch;
  border-top-left-radius: ${props => getSize(props.theme, '@border-radius-base')};
  border-bottom-left-radius: ${props => getSize(props.theme, '@border-radius-base')};
  overflow: hidden;
  transform: translateZ(0); // safari fix
`
const Inner = styled`
  background: ${props => getColor(props.theme, '@green')};
  width: 100%;
  transition: height 0.5s cubic-bezier(0.16, 1, 0.3, 1);
`
