import { useApolloClient } from '@apollo/client'
import React from 'react'

import { Upload } from './Upload'
import { CommonAttachmentWrite } from './common/CommonAttachmentWrite'
import { AttachmentFieldVariables, AttachmentWriteProps } from './types'

export function AttachmentWrite<
  Result,
  Variables extends AttachmentFieldVariables = AttachmentFieldVariables,
>(props: AttachmentWriteProps<Result, Variables>) {
  const client = useApolloClient()
  return (
    <CommonAttachmentWrite<Result, Variables> {...props} client={client} UploadComponent={Upload} />
  )
}
