import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

import { useKeyboardContext } from '../../KeyboardContext'
import { ModeContentsProps } from '../types'

export type TupleOf<T, N extends number> = number extends N ? T[] : _TupleOf<T, N, []>
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N
  ? R
  : _TupleOf<T, N, [T, ...R]>

const COLORS = ['@red', '@orange', '@yellow', '@green', '@blue', '@purple', '@pink', '@red']
const NOTES = ['C4', 'D4', 'E4', 'F4', 'G4', 'A4', 'B4', 'C5']

export interface SimpleListProps extends ModeContentsProps {
  items: TupleOf<React.FC<React.PropsWithChildren<any>>, 8>
}
export function SimpleList({ items, wrapNote: WrapNote }: SimpleListProps) {
  const { playKey } = useKeyboardContext(true)
  return (
    <Container>
      {items.map((Item, index) => {
        const rendered = (
          <SVGContainer color={COLORS[index]}>
            <Item />
          </SVGContainer>
        )
        return (
          <ItemContainer key={index} onMouseDown={() => playKey(NOTES[index])}>
            {WrapNote ? <WrapNote pitch={index} children={rendered} /> : rendered}
          </ItemContainer>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: ${props => props.theme['@size-s']} 0;
`
const SVGContainer = styled.div<{ color: string }>`
  cursor: pointer;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s linear,
    filter 0.25s ease-in-out;
  opacity: 0.85;
  &:hover {
    transform: scale(1.05);
    opacity: 1;
    filter: drop-shadow(0 2px 10px ${props => transparentize(0.5, props.theme[props.color])});
  }
  path[fill^='#000'] {
    fill: ${props => props.theme[props.color]};
  }
`
const ItemContainer = styled.div`
  &:not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
  svg,
  img {
    height: 80px;
    width: auto;
    display: block;
  }
`
