import { SerializedLexicalNode } from 'lexical'

import { Paragraph } from './nodes'
import { EditorValue } from './types'

export function getDefaultEditorValue(
  children?: (SerializedLexicalNode & { [key: string]: any })[],
): EditorValue {
  return {
    root: {
      type: 'root',
      version: 1,
      children: children || [
        {
          type: 'paragraph',
          version: 1,
          children: [],
          direction: null,
          format: '',
          indent: 0,
        } as Paragraph,
      ],
      indent: 0,
      format: '',
      direction: null,
    },
  }
}
