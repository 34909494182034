import { Markdown as WebMarkdown } from '@thesisedu/web'
import React from 'react'

import { usePlaceholders } from '../common'

/** @deprecated */
export const Markdown: typeof WebMarkdown = props => {
  const placeholders = usePlaceholders()
  return <WebMarkdown placeholders={placeholders} {...props} />
}

export { markdownToPlainText } from '@thesisedu/web'
