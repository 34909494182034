import React from 'react'

import { s, styled } from '../../'

export interface LoadingIndicatorProps {
  size?: s.AnySize | `${string}em`
  style?: React.CSSProperties
  className?: string
}
export function LoadingIndicator({ size, style, className }: LoadingIndicatorProps) {
  return <LoadingIndicatorElement $size={size} style={style} className={className} />
}

const SpinAnimation = s.keyframes`
  100% {
    transform: rotate(360deg);
  }
`
const LoadingIndicatorElement = styled.div<{ $size?: s.AnySize | `${string}em` }>`
  position: relative;
  display: inline-block;
  --size: ${props =>
    props.$size === undefined
      ? '1em'
      : props.$size.endsWith('em')
      ? props.$size
      : s.size(props.$size as s.AnySize)};
  width: var(--size);
  height: var(--size);
  animation: ${SpinAnimation} 1s infinite linear;
  &::before {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid currentColor calc(var(--size) * 0.2);
    opacity: 0.25;
  }
  &::after {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: 50%;
    width: calc(var(--size) * 0.2);
    height: calc(var(--size) * 0.2);
    margin-left: calc(var(--size) * -0.1);
    border-radius: 50%;
    background: currentColor;
  }
`
