import { useIds } from '@thesisedu/feature-react'

export interface AssignmentTemplateIdProps {
  templateId?: string | null
}
export function AssignmentTemplateId({ templateId }: AssignmentTemplateIdProps) {
  useIds(templateId ? [{ id: templateId, label: 'Segment AssignmentTemplate' }] : [])

  return null
}
