import { $isMarkNode } from '@lexical/mark'
import {
  $isDecoratorNode,
  $isInlineElementOrDecoratorNode,
  $isLineBreakNode,
  $isTextNode,
  ParagraphNode,
} from 'lexical'

import { Validator } from '../types'

export default {
  node: ParagraphNode,
  validate(node) {
    for (const child of node.getChildren().reverse()) {
      if (
        !$isTextNode(child) &&
        !$isMarkNode(child) &&
        !$isLineBreakNode(child) &&
        (!$isInlineElementOrDecoratorNode(child) || $isDecoratorNode(child))
      ) {
        node.insertAfter(child)
      }
    }

    // Remove linebreaks at the end of paragraphs.
    const children = node.getChildren()
    for (let i = 0; i < children.length; i++) {
      if ($isLineBreakNode(children[i])) {
        if (children.slice(i).every(child => $isLineBreakNode(child))) {
          children[i].remove()
        }
      }
    }
  },
} satisfies Validator<ParagraphNode>
