import { ArrowRightOutlined } from '@ant-design/icons'
import { styled, StyledThemeContext } from '@thesisedu/web'
import { Skeleton, Tag } from 'antd'
import { transparentize } from 'polished'
import React, { useContext } from 'react'

import { BasicCourseVersionFragment } from '../schema'

const Link = styled.a`
  &:hover > div {
    background: ${props => transparentize(0.9, props.theme['@blue'])};
    color: ${props => props.theme['@blue']};
    cursor: pointer;
  }
`
const Container = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  transition:
    background 0.1s linear,
    color 0.1s linear;
  background: ${props =>
    props.isSelected ? transparentize(0.9, props.theme['@blue']) : 'transparent'};
  color: ${props => props.theme[props.isSelected ? '@blue' : '@text-color']};
`
const Top = styled.div`
  margin-right: auto;
  justify-content: flex-start;
  > strong {
    font-size: 125%;
    display: block;
  }
`
const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0 5px 0;
`

export interface CourseVersionProps {
  onClick?: () => void
  version?: BasicCourseVersionFragment
  isLatest?: boolean
  isSelected?: boolean
}
export const CourseVersion: React.FC<React.PropsWithChildren<CourseVersionProps>> = ({
  onClick,
  version,
  isLatest,
  isSelected,
}) => {
  const theme = useContext(StyledThemeContext)
  const title = version ? (
    version.name
  ) : (
    <Skeleton
      active
      paragraph={false}
      title={{
        style: { margin: '5px 0', height: 30, width: '90%' },
      }}
    />
  )
  let publishedLabel = version?.publishedAt ? <Tag color={theme['@blue']}>Published</Tag> : null
  if (publishedLabel && isLatest) {
    publishedLabel = <Tag color={theme['@green']}>Published & Default</Tag>
  }
  const content = (
    <Container isSelected={isSelected}>
      <Top>
        <strong>{title}</strong>
      </Top>
      <Bottom>
        {publishedLabel}
        <ArrowRightOutlined />
      </Bottom>
    </Container>
  )

  if (onClick) {
    return <Link onClick={onClick}>{content}</Link>
  } else {
    return content
  }
}
