import { SettingOutlined, LineChartOutlined } from '@ant-design/icons'
import React from 'react'

import { AssignmentReports } from './AssignmentReports'
import { AssignmentSettings, AssignmentSettingsProps } from './AssignmentSettings'
import { AssignmentSubmissions } from './AssignmentSubmissions'
import { NavigationItem } from './types'
import { getBestGuessClassId } from '../helpers'
import { useOptions } from '../useOptions'

function OuterAssignmentSettings(props: AssignmentSettingsProps) {
  const opts = useOptions()
  const propsClassId = opts.allowMultiClassAssignments
    ? props.classId
    : getBestGuessClassId(props.assignment)
  return <AssignmentSettings {...props} classId={propsClassId || undefined} />
}

export const REPORTS_ITEM: NavigationItem = {
  key: 'reports',
  label: 'Reports',
  icon: <LineChartOutlined />,
  render: AssignmentReports,
}
export const DEFAULT_NAVIGATION_ITEMS: NavigationItem[] = [
  {
    key: 'settings',
    label: 'Settings',
    icon: <SettingOutlined />,
    render: OuterAssignmentSettings,
  },
  {
    key: 'all-students',
    label: 'All Students',
    icon: <SettingOutlined />,
    skipItem: true,
    render: AssignmentSubmissions,
  },
]

export const SETTINGS_WHITELIST_FIELDS = [
  'name',
  'dueAt',
  'openAt',
  'assignmentMode',
  'description',
  'assignmentCategoryIds',
  'assign',
  'totalPoints',
  'excluded',
  'rubric',
  'maxAttempts',
  'timeLimit',
  'gradingMode',
  'revealAnswers',
  'revealAttempts',
  'configuration',
  'dueAtOffset',
  'openAtOffset',
]
