import * as ReactDropdown from '@radix-ui/react-dropdown-menu'
import React from 'react'

import { DropdownContext, DropdownContextValue, useDropdownContext } from './DropdownContext'
import {
  CoreDropdownMenuItemProps,
  DropdownMenuItem,
  DropdownMenuItemContent,
  DropdownMenuItemContentProps,
} from './DropdownMenuItem'
import { ItemStyles } from './itemStyles'
import { useDropdownMenuForceMount } from './useDropdownMenuForceMount'
import { styled, s } from '../../'
import { NavArrowRight } from '../../../icons'
import { usePortalContainer } from '../../context/ScrollableContainerContext'
import { wrapPortal } from '../../style/wrapPortal'
import { withSubComponents } from '../../utils/withSubComponents'

export interface DropdownMenuProps extends ReactDropdown.MenuContentProps {
  arrow?: boolean
}
export function DropdownMenu({
  children,
  arrow,
  forceMount,
  className,
  ...rest
}: DropdownMenuProps) {
  const { ref, className: forceClassName } = useDropdownMenuForceMount()
  return (
    <Portal container={usePortalContainer()} forceMount={forceMount}>
      <_DropdownMenuContent
        sideOffset={s.getThemeValue('size.0.5')}
        side={'bottom'}
        align={'start'}
        className={`${className} ${forceClassName}`}
        ref={ref}
        {...rest}
      >
        {children}
        {arrow ? <_DropdownMenuArrow /> : null}
      </_DropdownMenuContent>
    </Portal>
  )
}
const Portal = wrapPortal(ReactDropdown.Portal)

export interface DropdownSubMenuTriggerProps
  extends ReactDropdown.MenuSubTriggerProps,
    DropdownMenuItemContentProps,
    CoreDropdownMenuItemProps {}
function DropdownSubMenuTrigger({ icon, children, danger, ...rest }: DropdownSubMenuTriggerProps) {
  return (
    <_SubTrigger {...rest} data-danger={danger}>
      <DropdownMenuItemContent icon={icon}>
        {children}
        <DropdownMenuItem.Right>
          <NavArrowRight />
        </DropdownMenuItem.Right>
      </DropdownMenuItemContent>
    </_SubTrigger>
  )
}

interface DropdownSubMenuContentProps extends ReactDropdown.DropdownMenuSubContentProps {}
function DropdownSubMenuContent(props: DropdownSubMenuContentProps) {
  const dropdownContext = useDropdownContext(true)
  const [forceMount, setForceMount] = React.useState(false)
  const newContext: DropdownContextValue = {
    forceMount,
    setForceMount(forceMount) {
      setForceMount(forceMount)
      dropdownContext?.setForceMount(forceMount)
    },
  }
  const { ref, className: forceClassName } = useDropdownMenuForceMount(newContext)
  return (
    <ReactDropdown.Portal
      container={usePortalContainer()}
      forceMount={forceMount ? true : undefined}
    >
      <DropdownContext.Provider value={newContext}>
        <_DropdownMenuSubContent
          sideOffset={s.getThemeValue('size.0.5')}
          alignOffset={-1 * s.getThemeValue('size.0.25')}
          ref={ref}
          {...props}
          className={`${props.className} ${forceClassName}`}
        />
      </DropdownContext.Provider>
    </ReactDropdown.Portal>
  )
}

export const DropdownSubMenu = withSubComponents(ReactDropdown.Sub, {
  Trigger: DropdownSubMenuTrigger,
  Content: DropdownSubMenuContent,
})

const _SubTrigger = styled(ReactDropdown.SubTrigger)`
  ${ItemStyles}
  &[data-state="open"] {
    background-color: ${s.color('gray.element')};
  }
`

const { css } = s
export const DropdownContentStyles = css`
  background: ${s.color('gray.background')};
  border-radius: ${s.var('radii.1')};
  padding: ${s.var('size.0.5')};
  box-shadow: ${s.var('shadow.1')};
  z-index: ${s.var('zIndices.dropdown')};
  max-width: 40vw;
  position: relative;
  &.force-mount {
    z-index: ${s.var('zIndices.forceMountedDropdown')};
  }
`
const _ContentStyles = css`
  ${DropdownContentStyles}
  min-width: 220px;
  max-height: var(--radix-dropdown-menu-content-available-height);
  overflow-y: auto;
  animation-duration: 400ms;
  animation-timing-function: ${s.var('curves.exponential')};
  will-change: transform, opacity;
  &[data-state='open'] {
    &[data-side='top'] {
      animation-name: ${s.animations.slideDownAndFade};
    }
    &[data-side='right'] {
      animation-name: ${s.animations.slideLeftAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${s.animations.slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${s.animations.slideRightAndFade};
    }
  }
`
const _DropdownMenuContent = styled(ReactDropdown.Content)`
  ${_ContentStyles}
`
const _DropdownMenuSubContent = styled(ReactDropdown.SubContent)`
  ${_ContentStyles}
`
const _DropdownMenuArrow = styled(ReactDropdown.Arrow)`
  fill: ${s.color('gray.background')};
`
