import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Result } from '@thesisedu/react'
import { WarningCircle } from '@thesisedu/react/icons'
import { Text, Button, Modal, useToast } from '@thesisedu/ui'
import React from 'react'

import { MoveStudentClassError, MoveStudentErrors } from './MoveStudentError'
import { useClasses } from '../ClassContext'
import { SingleClassSelect } from '../ClassSelectorList'
import { useOptions } from '../options'
import { useMoveStudentClassMutation } from '../schema'
import { ClassStudentPair } from '../types'
import { useStudentUser } from '../useStudentUsers'

export interface MoveStudentModalProps {
  students: ClassStudentPair[]
  visible?: boolean
  onClose: () => void
}
export function MoveStudentModal({ students, visible, onClose }: MoveStudentModalProps) {
  const options = useOptions()
  if (students.length !== 1) throw new Error('MoveStudentModal does not support bulk actions')
  const { studentId, classId } = students[0]
  const { student } = useStudentUser(studentId, classId)
  const { classes } = useClasses()
  const [targetClassId, setTargetClassId] = React.useState<string | undefined>()
  const targetClass = classes.find(cls => cls.id === targetClassId)
  const [errors, setErrors] = React.useState<MoveStudentClassError[] | undefined>(undefined)
  const hasErrors = !!errors?.length
  const toast = useToast()
  const [moveStudent, { loading }] = useMoveStudentClassMutation({
    onCompleted() {
      setErrors(undefined)
      onClose()
    },
    onError: onMutationError(
      'There was an error moving that student.',
      ['MOVE_STUDENT_CLASS_WARNINGS', 'STUDENT_NOT_IN_FROM_CLASS'],
      (code, error) => {
        if (code === 'MOVE_STUDENT_CLASS_WARNINGS') {
          const errors = error.graphQLErrors[0]?.extensions?.errors || []
          if (!errors.length) throw new Error('Got MOVE_STUDENT_CLASS_WARNINGS, but no errors.')
          setErrors(errors)
        } else if (code === 'STUDENT_NOT_IN_FROM_CLASS') {
          toast({
            title: "Can't Move Student",
            description: `It looks like ${
              student?.user.name ?? 'a student'
            } is not in this class. You can't move students from a class they are not in.`,
            status: 'error',
          })
        }
      },
    ),
  })
  return (
    <Modal
      title={'Move Student'}
      width={600}
      visible={visible}
      onVisibleChange={visible => {
        if (!visible) {
          onClose()
          setErrors(undefined)
        }
      }}
    >
      <Text bottom level={'l'}>
        You are about to move <strong>{student?.user.name || 'a student'}</strong> to{' '}
        {targetClass ? <strong>{targetClass.name}</strong> : <>another {options.className}</>}.
      </Text>
      {hasErrors ? (
        <>
          <Text color={'orange'} bottom>
            <strong>
              <WarningCircle /> Please review the items below and confirm if you would like to
              continue moving this student.
            </strong>
          </Text>
          {targetClass && student ? (
            <MoveStudentErrors errors={errors} toClass={targetClass} studentUser={student.user} />
          ) : (
            <Result.Error
              title={'Cannot find the class or student you want to move to.'}
              description={'Please refresh the page and try again.'}
            />
          )}
        </>
      ) : (
        <>
          <Text bottom>Which class would you like to move the student to?</Text>
          <SingleClassSelect
            value={targetClassId}
            onChange={setTargetClassId}
            filterClasses={cls => {
              return cls.id !== classId && cls.canInvite
            }}
          />
        </>
      )}
      <Modal.Footer>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        <Button
          variant={'primary'}
          loading={loading}
          disabled={!targetClassId}
          status={hasErrors ? 'danger' : undefined}
          onPress={() => {
            if (targetClassId) {
              moveStudent({
                variables: {
                  input: {
                    fromClassId: classId,
                    id: studentId,
                    toClassId: targetClassId,
                    force: hasErrors,
                  },
                },
              })
            }
          }}
        >
          {hasErrors ? 'Move Anyway' : 'Move'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
