import React from 'react'

export interface CourseViewerModalHeaderContextValue {
  headerPortalElement: React.RefObject<HTMLDivElement>
  setCustomHeader: (header: React.ReactElement | null) => void
  customHeader: React.ReactElement | null
}
export const CourseViewerModalHeaderContext = React.createContext<
  CourseViewerModalHeaderContextValue | undefined
>(undefined)

export interface CourseViewerModalHeaderProviderProps {
  children: React.ReactNode
}
export function CourseViewerModalHeaderProvider({
  children,
}: CourseViewerModalHeaderProviderProps) {
  const headerPortalElement = React.useRef<HTMLDivElement>(null)
  const [customHeader, setCustomHeader] = React.useState<React.ReactElement | null>(null)
  return (
    <CourseViewerModalHeaderContext.Provider
      value={{ headerPortalElement, customHeader, setCustomHeader }}
      children={children}
    />
  )
}

export function useCourseViewerModalHeaderContext(): CourseViewerModalHeaderContextValue | undefined
export function useCourseViewerModalHeaderContext(
  require: false,
): CourseViewerModalHeaderContextValue | undefined
export function useCourseViewerModalHeaderContext(
  require: true,
): CourseViewerModalHeaderContextValue
export function useCourseViewerModalHeaderContext(
  require?: boolean,
): CourseViewerModalHeaderContextValue | undefined {
  const context = React.useContext(CourseViewerModalHeaderContext)
  if (!context && require) {
    throw new Error('CourseViewerModalHeaderContext is required, yet not provided.')
  }
  return context
}
