import React from 'react'

import { styled } from './styledTypes'

const isMac = typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Mac OS X') !== -1

export interface KeyProps {
  isCtrl?: boolean
  children?: React.ReactElement | null
  [key: string]: any
}
export function Key({ isCtrl, children, ...rest }: any) {
  return <Container children={isCtrl ? (isMac ? '⌘' : 'ctrl') : children} {...rest} />
}
const Container = styled.span`
  padding: 0 ${props => props.theme['@size-xs']};
  border: ${props => props.theme['@gray-1']};
  background: ${props => props.theme['@gray-1']};
  border-radius: 0.1em;
  box-shadow: ${props => props.theme['@shadow-small']};
  margin: 0 0.25em;
  display: inline-block;
`
