import { BodySmall, styled, H3Alternate, BodyLarge } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

import { LargeBoxButton, LargeDarkTransparentButtonBox } from './styled'

export interface SmallNavButtonProps {
  subtitle: string
  title: string
  linkTo?: string
  onClick?: () => void
  color: string
  rightContent: React.ReactElement
  dark?: boolean
  placement?: 'left' | 'right'
}
export function SmallNavButton({
  subtitle,
  title,
  linkTo,
  color,
  rightContent,
  dark,
  onClick,
  placement = 'right',
}: SmallNavButtonProps) {
  const nonInteractive = !linkTo && !onClick
  const CC = dark ? DarkContainer : Container
  const leftContent = (
    <div>
      <BodySmall color={'@text-color-secondary'}>{subtitle}</BodySmall>
      <H3Alternate isBlock={false} color={color}>
        {title}
      </H3Alternate>
    </div>
  )
  const content = (
    <CC style={nonInteractive ? { pointerEvents: 'none' } : undefined}>
      {placement === 'left' ? (
        <>
          {rightContent}
          {leftContent}
        </>
      ) : (
        <>
          {leftContent}
          {rightContent}
        </>
      )}
    </CC>
  )

  if (nonInteractive) {
    return <div children={content} />
  } else if (linkTo) {
    return <Link to={linkTo} children={content} />
  } else {
    return <div onClick={onClick} style={{ cursor: 'pointer' }} children={content} />
  }
}

const Container = styled(LargeBoxButton)`
  border-radius: ${props => props.theme['@border-radius-medium']};
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-m']};
  > :first-child {
    padding-right: ${props => props.theme['@size-s']};
  }
  > :last-child {
    margin-left: auto;
    width: ${props => props.theme['@size-xl']};
  }
`
const DarkContainer = styled(LargeDarkTransparentButtonBox)`
  border-radius: ${props => props.theme['@border-radius-medium']};
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-m']};
  > :last-child {
    padding-left: ${props => props.theme['@size-s']};
  }
  > :first-child {
    width: ${props => props.theme['@size-xl']};
  }
`

export interface TinyNavButtonProps {
  linkTo: string
  text: string | React.ReactElement
  style?: any
}
export function TinyNavButton({ linkTo, text, style }: TinyNavButtonProps) {
  return (
    <Link to={linkTo} style={style}>
      <TinyContainer>
        <BodyLarge color={'@text-color-secondary'}>{text}</BodyLarge>
      </TinyContainer>
    </Link>
  )
}

const TinyContainer = styled(LargeBoxButton)`
  border-radius: ${props => props.theme['@border-radius-medium']};
  padding: ${props => props.theme['@size-s']};
  margin-bottom: ${props => props.theme['@size-m']};
`
