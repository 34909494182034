import React from 'react'

import { List, ListProps } from './List'
import { Trigger } from './Trigger'
import { withSubComponents } from '../../utils/withSubComponents'
import SegmentedControl, { ItemProps, SegmentedControlProps } from '../SegmentedControl'

export interface SegmentedControlListProps
  extends ListProps,
    Omit<SegmentedControlProps, 'children' | 'loop'> {
  children: React.ReactElement<ItemProps>[] | React.ReactElement<ItemProps>
}
function _SegmentedControlList({ children, ...rest }: SegmentedControlListProps) {
  return (
    <List asChild {...rest}>
      <SegmentedControl {...rest}>{children}</SegmentedControl>
    </List>
  )
}

export interface SegmentedControlListItemProps extends ItemProps {
  value: string
}
function _SegmentedControlListItem(
  { value, ...rest }: SegmentedControlListItemProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <Trigger asChild value={value} disabled={rest.disabled}>
      <SegmentedControl.Item {...rest} ref={ref} />
    </Trigger>
  )
}
export const SegmentedControlListItem = React.forwardRef(_SegmentedControlListItem)

export const SegmentedControlList = withSubComponents(_SegmentedControlList, {
  Item: SegmentedControlListItem,
})
