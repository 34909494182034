import { Widgets } from '@thesisedu/feature-course-types'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { s } from '@thesisedu/ui'
import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import definition from '.'
import { BlockView } from './BlockView'
import { InlineView } from './InlineView'

export function VideoElement({
  config,
  node,
}: SimpleWidget.SimpleWidgetProps<Widgets.VideoConfig>) {
  const { vodId, displayMode } = config
  let content
  if (vodId && displayMode === Widgets.VideoWidgetDisplayMode.Inline) {
    content = <InlineView {...config} vodId={vodId} />
  } else if (vodId) {
    content = <BlockView {...config} vodId={vodId} />
  } else {
    content = (
      <SimpleWidget.WidgetEmptyState
        {...definition}
        node={node}
        buttonLabel={'Select or upload a video...'}
        icon={<Play />}
      />
    )
  }

  return (
    <>
      <GlobalStyle />
      {content}
    </>
  )
}

const { createGlobalStyle } = s
const GlobalStyle = createGlobalStyle`
  .fteditor-video-inline + * {
    margin-top: 0 !important;
  }
`
