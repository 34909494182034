import { useFeatureRoot } from '@thesisedu/feature-react'
import { Rocket } from '@thesisedu/react/icons'
import { Toast, s, styled } from '@thesisedu/ui'
import React from 'react'

import { UpdateButton } from './UpdateButton'
import { FeatureWeb } from '../FeatureWeb'

export interface UpdateAvailableToastProps {
  onUpdate: () => void
}
export function UpdateAvailableToast({ onUpdate }: UpdateAvailableToastProps) {
  const root = useFeatureRoot() as FeatureWeb
  return (
    <StyledRoot open onOpenChange={() => {}}>
      <Toast.Icon>
        <Rocket width={32} height={32} />
      </Toast.Icon>
      <Toast.Title>Refresh to update {root.appOptions.name}.</Toast.Title>
      <Toast.Description>You may run into unexpected errors until you refresh.</Toast.Description>
      <Toast.Action altText={'Refresh the page to update'} asChild>
        <UpdateButton onUpdate={onUpdate} size={'small'} />
      </Toast.Action>
    </StyledRoot>
  )
}

const StyledRoot = styled(Toast.Root)`
  --toast-text-secondary: ${s.color('orange')} !important;
`
