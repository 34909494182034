import { Segment, SegmentConfig } from '@thesisedu/feature-course-types'
import { useResource } from '@thesisedu/feature-react'
import { styled } from '@thesisedu/web'
import React from 'react'

import { getSegmentGridRendererResource } from './SegmentGrid'
import { SegmentGridRendererResource } from './types'
import { getSegmentRawId } from '../../helpers'
import { MinimalSegmentFragment } from '../../schema'
import { UseInClassButton } from '../UseInClassButton'

export interface SegmentGridItemProps {
  segment: Omit<MinimalSegmentFragment, 'type' | 'tagSummary'> & {
    type: string
    config?: SegmentConfig
  } & Partial<Pick<MinimalSegmentFragment, 'tagSummary'>>
  resource?: SegmentGridRendererResource
  width?: number
  onClick?: (segment: Segment) => void
}
export function SegmentGridItem({
  segment,
  resource: _resource,
  width,
  onClick,
}: SegmentGridItemProps) {
  const resources = useResource<SegmentGridRendererResource>('SegmentGridRenderer')
  const resource = _resource || getSegmentGridRendererResource(resources, segment)
  const [overlayVisible, setOverlayVisible] = React.useState(false)
  if (resource) {
    const { Component } = resource
    return (
      <ContentContainer
        style={onClick ? { cursor: 'pointer' } : {}}
        onClick={onClick ? () => onClick(segment) : undefined}
        className={overlayVisible ? 'overlay-visible' : ''}
      >
        <Component segment={segment} width={width || 0} />
        <ActionsContainer onClick={e => e.stopPropagation()}>
          <UseInClassButton
            rawId={getSegmentRawId(segment.id)}
            children={null}
            variant={'primary'}
            visible={overlayVisible}
            onVisibleChange={setOverlayVisible}
          />
        </ActionsContainer>
      </ContentContainer>
    )
  } else {
    return null
  }
}

const ActionsContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-s']};
  right: ${props => props.theme['@size-s']};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
const ContentContainer = styled.div`
  position: relative;
  ${ActionsContainer} {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s linear;
  }
  &:hover ${ActionsContainer}, &.overlay-visible ${ActionsContainer} {
    opacity: 1;
    pointer-events: all;
  }
`
