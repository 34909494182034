import { InfiniteQueryProps } from '@thesisedu/feature-apollo-react'

import { AssignmentSubmissionsGridProps } from './AssignmentSubmissionsGrid'
import {
  BasicAssignmentSubmissionFragment,
  AssignmentSubmissionsQuery,
  AssignmentSubmissionsQueryVariables,
  AssignmentSubmissionsDocument,
  OrderDirection,
} from '../schema'

export function _useInfiniteQueryProps({
  classId,
  assignment,
}: AssignmentSubmissionsGridProps): Omit<
  InfiniteQueryProps<
    BasicAssignmentSubmissionFragment,
    AssignmentSubmissionsQuery,
    AssignmentSubmissionsQueryVariables
  >,
  'children'
> {
  return {
    document: AssignmentSubmissionsDocument,
    variables: {
      first: 16,
      studentClassId: classId,
      id: assignment.id,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Asc,
      useCurrentSubmissions: true,
    },
    resultPath: 'node.submissions',
  }
}
