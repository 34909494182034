import { Form } from '@thesisedu/ui'
import React from 'react'

export function StartingNumberField() {
  return (
    <Form.TextField
      name={'startingNumber'}
      label={'Starting Number'}
      placeholder={'4'}
      pattern={{
        value: /\d+/,
        message: 'Must be a valid number',
      }}
    />
  )
}
