import { Body } from '@thesisedu/web'
import { Select } from 'antd'
import React from 'react'

import { QuestionProps } from '../../../types'
import { QuestionAdditionalFields } from '../QuestionAdditionalFields'
import { QuestionInstructions } from '../QuestionInstructions'
import { FILE_UPLOAD_TYPES } from '../constants'

export function FileUploadEdit(props: QuestionProps) {
  return (
    <>
      <QuestionInstructions
        padded
        instructions={'Use the fields below to configure your file upload.'}
      >
        <Body>Type</Body>
        <Select
          value={props.question.config?.type}
          onChange={({ value }) => {
            if (props.onChange) {
              props.onChange({
                config: {
                  ...props.question.config,
                  type: value,
                },
              })
            }
          }}
        >
          {Object.keys(FILE_UPLOAD_TYPES).map(uploadType => (
            <Select.Option key={uploadType} value={uploadType}>
              {FILE_UPLOAD_TYPES[uploadType].label}
            </Select.Option>
          ))}
        </Select>
      </QuestionInstructions>
      <QuestionAdditionalFields {...props} />
    </>
  )
}
