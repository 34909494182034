import { HSpaced, LoadingIndicator, Text, s, styled } from '@thesisedu/ui'
import { formatTime } from '@thesisedu/web/dist/time'
import React from 'react'

import { useMediaPlaybackContext } from './MediaPlaybackContext'
import { PlayerFooterContainer } from './PlayerFooterContainer'
import { Track } from './Track'
import { Fullscreen } from './controls/Fullscreen'
import { PlaybackSpeed } from './controls/PlaybackSpeed'
import { SkipBackward, SkipForward } from './controls/Skip'
import { Volume } from './controls/Volume'
import { Pause, Play } from '../../icons'
import { ClickableContainer } from '../dom/ClickableContainer'
import { isNative } from '../isNative'

export enum FooterControl {
  SkipBackward = 'skip-backward',
  SkipForward = 'skip-forward',
  Fullscreen = 'fullscreen',
  PlaybackSpeed = 'playback-speed',
  Volume = 'volume',
}
type FooterControlMap = {
  [key in FooterControl]: React.FC<React.PropsWithChildren<PlayerFooterProps>>
}
export const BUILTIN_CONTROLS: FooterControlMap = {
  [FooterControl.SkipBackward]: SkipBackward,
  [FooterControl.SkipForward]: SkipForward,
  [FooterControl.Fullscreen]: Fullscreen,
  [FooterControl.PlaybackSpeed]: PlaybackSpeed,
  [FooterControl.Volume]: Volume,
}
export enum PlayerFooterCompactMode {
  Compact,
  UltraCompact,
}
export interface PlayerFooterProps {
  beforeFooter?: React.ReactElement
  decorators?: React.ReactElement
  controls?: (FooterControl | React.ReactElement)[]
  compact?: PlayerFooterCompactMode
  hideFullscreen?: boolean
  visible?: boolean
}

export function PlayerFooter({
  beforeFooter,
  decorators,
  controls = [
    FooterControl.SkipBackward,
    FooterControl.SkipForward,
    FooterControl.PlaybackSpeed,
    FooterControl.Volume,
    FooterControl.Fullscreen,
  ],
  compact,
  hideFullscreen,
  visible,
}: PlayerFooterProps) {
  const { isPlaying, onSetPlaying, isBuffering, duration, playedSeconds } =
    useMediaPlaybackContext()
  let PlayStatusIcon
  if (isPlaying && !isBuffering) {
    PlayStatusIcon = Pause
  } else if (isBuffering) {
    PlayStatusIcon = LoadingIndicator
  } else {
    PlayStatusIcon = Play
  }
  return (
    <>
      {beforeFooter}
      <PlayerFooterContainer compact={compact !== undefined} visible={visible}>
        <PlayStatusContainer
          compact={compact}
          onClick={() => {
            if (!isBuffering) {
              onSetPlaying(p => !p)
            }
          }}
          isBuffering={isBuffering}
        >
          <PlayStatusIcon
            width={s.getThemeValue(compact !== undefined ? 'size.2' : 'size.4')}
            height={s.getThemeValue(compact !== undefined ? 'size.2' : 'size.4')}
            color={'white'}
          />
        </PlayStatusContainer>
        {compact === PlayerFooterCompactMode.Compact ? null : <Track>{decorators}</Track>}
        {duration && compact !== PlayerFooterCompactMode.UltraCompact ? (
          <TextContainer compact={compact !== undefined} style={{ lineHeight: isNative ? 1.4 : 1 }}>
            {`${formatTime(playedSeconds.toString())} / ${formatTime(duration.toString())}`}
          </TextContainer>
        ) : null}
        <HSpaced space={compact ? 'xxs' : 's'} left={'s'}>
          {compact !== PlayerFooterCompactMode.UltraCompact
            ? controls
                .filter(control => {
                  if (React.isValidElement(control) || !hideFullscreen) return true
                  return control !== FooterControl.Fullscreen
                })
                .filter(control => {
                  if (React.isValidElement(control)) return true
                  return (
                    compact === undefined ||
                    (control !== FooterControl.SkipBackward &&
                      control !== FooterControl.SkipForward &&
                      control !== FooterControl.PlaybackSpeed)
                  )
                })
                .map((control, index) => {
                  if (React.isValidElement<any>(control)) {
                    return React.cloneElement<any>(control, {
                      key: index,
                      isCompact: compact !== undefined,
                    })
                  } else {
                    const Control = BUILTIN_CONTROLS[control]
                    return <Control key={control} compact={compact} />
                  }
                })
            : null}
        </HSpaced>
      </PlayerFooterContainer>
    </>
  )
}

const PlayStatusContainer = styled(ClickableContainer)<{
  isBuffering?: boolean
  compact: PlayerFooterCompactMode
}>`
  font-size: ${props => s.size(props.compact ? 'm' : 'l')};
  cursor: ${props => (props.isBuffering ? undefined : 'pointer')};
  opacity: 0.8;
  transition: opacity 0.1s linear;
  width: ${props => s.size(props.compact ? 'm' : 'l')};
  height: ${props => s.size(props.compact ? 'm' : 'l')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props =>
    s.size(
      props.compact === PlayerFooterCompactMode.Compact
        ? 'xxs'
        : props.compact === PlayerFooterCompactMode.UltraCompact
        ? 's'
        : 'm',
    )};
  ${props =>
    isNative
      ? ''
      : `
    &:hover {
      opacity: ${props.isBuffering ? 0.8 : 1};
    }
  `}
`
const TextContainer = styled(Text)<{ compact: boolean }>`
  margin-left: ${props => (props.compact ? 'auto' : s.size('s'))} !important;
  margin-right: ${props => (props.compact ? 'auto' : 0)} !important;
  font-size: ${s.size('font.s')};
  opacity: 0.75;
  color: white;
  &:not(.nothing) {
    ${props => (isNative ? '' : `font-family: ${s.var('font.default')} !important;`)}
    ${props => (isNative ? '' : `font-size: ${s.size('font.s')} !important;`)}
    margin-left: ${props => (props.compact ? 'auto' : s.size('s'))} !important;
    margin-right: ${props => (props.compact ? 'auto' : 0)} !important;
  }
`
