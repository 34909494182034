import { Block, H2, HSpaced, Space, styled, Button, ButtonProps } from '@thesisedu/react'
import { AppNotification } from '@thesisedu/react/icons'
import { Badge, Popover } from 'antd'
import { random } from 'lodash'
import React from 'react'

import { MarkAllNotifications } from './MarkNotificationRead'
import { UserNotifications } from './UserNotifications'
import { useUnreadNotificationsQuery } from './schema'

const POLL_INTERVAL =
  process.env.REACT_APP_ENVIRONMENT === 'development' ? undefined : 60 * 5 * 1000 // 5 minutes
export interface UserNotificationsPopoverProps {
  header?: React.ReactElement
  button?: React.ReactElement
  buttonProps?: Partial<ButtonProps>
}
export function UserNotificationsPopover({
  header,
  button,
  buttonProps,
}: UserNotificationsPopoverProps) {
  const [visible, setVisible] = React.useState(false)
  const [refetchKey, setRefetchKey] = React.useState(random(0, 1000000).toString())
  const didMountRef = React.useRef(false)
  const { data, refetch } = useUnreadNotificationsQuery({
    pollInterval: POLL_INTERVAL,
  })
  React.useEffect(() => {
    if (visible && didMountRef.current) {
      setRefetchKey(random(0, 1000000).toString())
      refetch()
    }
    didMountRef.current = true
  }, [visible])
  const unreadNotifications = data?.viewer?.unreadNotifications || 0
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <Popover
      placement={'bottomRight'}
      visible={visible}
      onVisibleChange={setVisible}
      content={
        <ContentContainer>
          {header}
          <Block marginBottom={'@size-s'} className={'header'}>
            <HSpaced>
              <H2 isBlock={false}>Notifications</H2>
              <Space />
              <MarkAllNotifications read />
            </HSpaced>
          </Block>
          <ScrollContainer ref={containerRef}>
            <UserNotifications
              infiniteScrollerProps={{
                scrollableTarget: containerRef.current || undefined,
              }}
              refetchKey={refetchKey}
              onClick={() => {
                setVisible(false)
              }}
            />
          </ScrollContainer>
        </ContentContainer>
      }
      trigger={['click']}
    >
      <Badge dot={unreadNotifications > 0}>
        {button ? button : <Button icon={<AppNotification />} {...buttonProps} />}
      </Badge>
    </Popover>
  )
}

const ScrollContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
`
const ContentContainer = styled.div`
  width: 450px;
  max-width: 90vw;
  margin: -16px -18px;
  .header {
    padding: 0 ${props => props.theme['@size-s']};
    margin-top: ${props => props.theme['@size-s']};
  }
`
