import { styledContainer as styled } from '../common'
import { getSize } from '../theme'

/** @deprecated */
export const Grid = styled<{ columnSize?: number; gap?: string; rowGap?: string }>`
  display: grid;
  row-gap: ${props => getSize(props.theme, props.rowGap || props.gap || '@size-l')};
  column-gap: ${props => getSize(props.theme, props.gap || '@size-l')};
  grid-template-columns: repeat(auto-fill, minmax(${props => props.columnSize || 360}px, 1fr));
`
