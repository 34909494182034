import { WidgetConverter } from './types'
import { convertPlainText } from './util/text'
import * as Legacy from '..'

interface DiscussionGuideItemWidgetConfig {
  content?: string
  title: string
  subtitle?: string
  numberOverride?: string
}

export default {
  weight: 0,
  label: 'discussionGuideItem',
  identifier: 'DiscussionGuideItem',
  convert(value) {
    return [
      {
        type: 'DiscussionGuideItem',
        version: 1,
        id: value.id,
        ...value.config,
        startingNumber: value.config?.numberOverride,
        title: convertPlainText(value.config?.title || ''),
        content: convertPlainText(value.config?.content || ''),
      },
    ]
  },
} satisfies WidgetConverter<Legacy.Widget<DiscussionGuideItemWidgetConfig>>
