import { Text, Form, useToast, Modal, VSpaced, Modal$ } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { useCreateRubricTemplateMutation } from '../queries/useCreateRubricTemplateMutation'
import { AssignmentRubricInput } from '../schema'

interface FormValues {
  name: string
}
export interface SaveAsTemplateModalProps extends Pick<Modal$.ModalProps, 'trigger'> {
  value?: AssignmentRubricInput | null
}
export function SaveAsTemplateModal({ value, trigger }: SaveAsTemplateModalProps) {
  const form = Form.useForm<FormValues>()
  const toast = useToast()
  const [create, { loading }] = useCreateRubricTemplateMutation({
    onCompleted: () => {
      toast.success(
        'Rubric template saved! You may now use this rubric as a template on other assignments.',
      )
    },
  })
  return (
    <Form.Modal
      title={'Save Rubric Template'}
      form={form}
      trigger={trigger}
      onFinish={async values => {
        if (value) {
          await create({
            variables: {
              input: {
                rubric: value,
                name: values.name,
              },
            },
          })
        }
      }}
    >
      <VSpaced space={'m'}>
        <Text>
          To save your template, give it a name and then click <strong>Create Template.</strong>
        </Text>
        <Form.TextField
          name={'name'}
          label={'Name'}
          required
          placeholder={'My Rubric Template'}
          size={'large'}
          autoComplete={'off'}
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Create Template'} loading={loading} icon={<Check />} />
      </Modal.Footer>
    </Form.Modal>
  )
}
