import { getSegmentName, Segment, walkSegments } from '@thesisedu/feature-course-types'
import Tree, { DataNode, TreeProps } from 'antd/lib/tree'
import React from 'react'

import { useCourseContext } from '../contexts/CourseContext'

function getTreeDataForSegments(
  segments: Segment[],
  addedSegmentIds: Set<string> = new Set<string>(),
): DataNode[] {
  return segments
    .map(segment => {
      if (addedSegmentIds.has(segment.id)) return null
      addedSegmentIds.add(segment.id)
      return {
        title: getSegmentName(segment),
        key: segment.id,
        children: segment.childSegments
          ? getTreeDataForSegments(segment.childSegments, addedSegmentIds)
          : [],
      }
    })
    .filter(Boolean) as DataNode[]
}

function useExpandedIds(segments: Segment[], autoExpandDepth: number) {
  const expandIds: string[] = []
  walkSegments(segments, (segment, depth) => {
    if (depth < autoExpandDepth) {
      expandIds.push(segment.id)
    }
  })
  return expandIds
}

export interface SegmentTreeProps extends Partial<TreeProps> {
  segments?: Segment[]
  value?: string[]
  onChange?: (segmentIds: string[]) => void
  autoExpandDepth?: number
}
export function SegmentTree({
  segments: _segments,
  value,
  onChange,
  autoExpandDepth = 2,
  ...rest
}: SegmentTreeProps) {
  const { bakedCourseConfiguration } = useCourseContext(false) || {}
  const segments = _segments || bakedCourseConfiguration?.segments || []
  const treeData = getTreeDataForSegments(segments)
  const expandedIds = useExpandedIds(segments, autoExpandDepth)
  return (
    <Tree
      height={400}
      {...rest}
      checkable
      selectable={false}
      treeData={treeData}
      defaultExpandedKeys={expandedIds}
      checkedKeys={value}
      onCheck={keys => {
        if (onChange && Array.isArray(keys)) {
          onChange(keys.map(k => k.toString()))
        }
      }}
    />
  )
}
