import React from 'react'

import { RecordViewProps } from './RecordView'

export interface RecordViewContextValue extends RecordViewProps {}
export const RecordViewContext = React.createContext<RecordViewContextValue | undefined>(undefined)

export function useRecordViewContext(): RecordViewContextValue | undefined
export function useRecordViewContext(require: false): RecordViewContextValue | undefined
export function useRecordViewContext(require: true): RecordViewContextValue
export function useRecordViewContext(require?: boolean): RecordViewContextValue | undefined {
  const context = React.useContext(RecordViewContext)
  if (!context && require) {
    throw new Error('RecordViewContext is required, yet not provided.')
  }
  return context
}
