import { SAMLButtonPlacement, SAMLButtonResource } from '@thesisedu/feature-users-react'

import { SchoologyReactFeature } from '../SchoologyReactFeature'
import { SchoologyLoginButton } from '../web/SchoologyLoginButton'

export default function (feature: SchoologyReactFeature) {
  feature.resources.addResource<SAMLButtonResource>({
    type: 'SAMLButton',
    identifier: 'Schoology',
    weight: 7,
    component: SchoologyLoginButton,
    placement: SAMLButtonPlacement.Secondary,
  })
}
