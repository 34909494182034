import { useFeatureRoot } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { VideoUpload } from './VideoUpload'
import { VideoOnDemandViewerResource } from './types'

export interface DynamicVideoFieldProps {
  value?: string
  onChange?: (value?: string) => void
}

export const useShouldRenderDynamicVideoField = () => {
  const viewer = useViewerContext(false)
  const isTeacher = viewer?.group === 'TEACHER'
  const root = useFeatureRoot()
  const resources =
    root.deps.resources.getResourcesForType<VideoOnDemandViewerResource>('VideoOnDemandViewer')
  const resource = resources.find(r => viewer && r.shouldUseEditor(viewer))
  return !!resource || !isTeacher
}

export function DynamicVideoField(props: DynamicVideoFieldProps) {
  const viewer = useViewerContext(false)
  const isTeacher = viewer?.group === 'TEACHER'
  const root = useFeatureRoot()
  const resources =
    root.deps.resources.getResourcesForType<VideoOnDemandViewerResource>('VideoOnDemandViewer')
  const resource = resources.find(r => viewer && r.shouldUseEditor(viewer))
  if (resource) {
    const { Editor } = resource
    return <Editor {...props} />
  } else if (!isTeacher) {
    return <VideoUpload {...props} />
  } else {
    return <p>You are not allowed to do this.</p>
  }
}
