import { Button, ButtonProps, AntIconWrapper } from '@thesisedu/react'
import { ThumbsUp } from '@thesisedu/react/icons'
import React from 'react'

import { useApproveCommentMutation } from '../queries/useApproveCommentMutation'

export interface ApproveCommentButtonProps extends ButtonProps {
  commentId: string
}
export function ApproveCommentButton({ commentId, ...props }: ApproveCommentButtonProps) {
  const [approve, { loading }] = useApproveCommentMutation({
    variables: { input: { id: commentId } },
  })
  return (
    <Button
      type={'link'}
      icon={<AntIconWrapper children={<ThumbsUp />} />}
      loading={loading}
      onClick={() => approve()}
      {...props}
    />
  )
}
