import { useFeatureRoot, useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { AntIconWrapper, Bold, Form, Modal, Text } from '@thesisedu/react'
import { WarningCircle } from '@thesisedu/react/icons'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React from 'react'

import { AssignmentViewerFormContext } from './AssignmentViewerFormContext'
import { useAssignmentViewerContext } from '../../contexts/AssignmentViewerContext'
import { SubmissionContextProvider, useSubmissionContext } from '../../contexts/SubmissionContext'
import { isAssignmentAutoGraded } from '../../grading/isAssignmentAutoGraded'
import { debug, error } from '../../log'
import { RevealAnswers } from '../../schema'
import { QuestionTypeResource } from '../../types'
import { StartAssignmentButton, StartRandomizedAssignmentButton } from '../StartAssignmentButton'
import { mergeAnswers } from '../answerStore'

export function isFieldCompleteDefault(values: Record<string, any>, field: string) {
  return values[field] && !isEmpty(values[field])
}

export interface AssignmentViewerFormFormProps {
  disableFormSubmit?: boolean
  disableLocalStorage?: boolean
}
export function AssignmentViewerFormForm({
  disableFormSubmit,
  disableLocalStorage,
  children,
}: React.PropsWithChildren<AssignmentViewerFormFormProps>) {
  const root = useFeatureRoot()
  const { forceShowAssignment, classId, configuration } = useAssignmentViewerContext(true)
  const submissionContext = useSubmissionContext(true)
  const {
    assignment,
    submission,
    loading,
    submissionData: submissionSubmissionData,
    cancelValuesDebounce,
    onSubmit,
    onFieldsChange: _onFieldsChange,
    onSaveSubmission,
    groups: { groupLeader, isGroupLeader },
    studentId,
    skipSubmitConfirmation,
  } = submissionContext
  const viewer = useViewerContext(true)
  const submissionId = submission?.id
  const form = Form.useForm<Record<string, any>>()
  const [rawSubmissionData, setRawSubmissionData] = React.useState({})
  const onFieldsChange: typeof _onFieldsChange = (input, ...args) => {
    setRawSubmissionData(input.submissionData)
    _onFieldsChange(input, ...args)
  }
  const [timeLimitReached, setTimeLimitReached] = React.useState(
    assignment.timeLimit && submission
      ? moment().diff(submission.createdAt, 'seconds') >= assignment.timeLimit
      : false,
  )

  // Update the time limit whenever the class assignment or submission are updated.
  React.useEffect(() => {
    setTimeLimitReached(
      assignment.timeLimit && submission
        ? moment().diff(submission.createdAt, 'seconds') >= assignment.timeLimit
        : false,
    )
  }, [submission?.createdAt, assignment.timeLimit])

  const values = submissionSubmissionData || {}
  const questionResources = useResource<QuestionTypeResource>('QuestionType')
  function getCompletedFields(values: Record<string, any>): number {
    return Object.keys(values).filter(key => {
      const question = (configuration?.questions || []).find(q => q.id === key)
      if (!question) return false
      const resource = questionResources.find(r => r.identifier === question?.type)
      if (resource?.isQuestionComplete) {
        return resource.isQuestionComplete(question, values[key])
      } else {
        return isFieldCompleteDefault(values, key)
      }
    }).length
  }
  const [completedFields, setCompletedFields] = React.useState(getCompletedFields(values))
  React.useEffect(() => {
    if (studentId) {
      let mergedValues
      if (disableLocalStorage) {
        mergedValues = submissionSubmissionData || {}
      } else {
        mergedValues = mergeAnswers(
          studentId,
          classId,
          assignment.id,
          submissionId,
          submissionSubmissionData || {},
          submission?.updatedAt,
        )
      }
      const formValues = form.getValues()
      const emptyFormValues =
        Object.keys(formValues || {}).filter(k => !!formValues[k]).length === 0
      const emptyMergedValues = !mergedValues || Object.keys(mergedValues).length === 0
      if (emptyMergedValues && !emptyFormValues) {
        debug('resetting fields because emptyMergedValues is true and the form has values')
        form.reset()
      } else {
        for (const key of Object.keys(mergedValues || {})) {
          form.setValue(key, mergedValues[key])
        }
      }
      setCompletedFields(getCompletedFields(mergedValues))
      if (emptyMergedValues && Object.keys(rawSubmissionData).length > 0) {
        setRawSubmissionData({})
      }
    }
  }, [studentId])

  // We're storing the merged submission data in state so we can update it inside the
  // resetFields callback below.
  const [mergedSubmissionData, setMergedSubmissionData] = React.useState(submissionSubmissionData)
  React.useEffect(() => {
    if (studentId) {
      setMergedSubmissionData(
        disableLocalStorage
          ? submissionSubmissionData || {}
          : mergeAnswers(
              studentId,
              classId,
              assignment.id,
              submissionId,
              submissionSubmissionData || {},
              submission?.updatedAt,
            ),
      )
    }
  }, [submissionSubmissionData, studentId])

  // Complex field calculations...
  const actionsDisabled = viewer.group !== 'STUDENT' || !!submission?.submittedAt || !studentId
  let showAnswers =
    submission?.submittedAt &&
    assignment.revealAnswers &&
    (!assignment.remainingAttempts ||
      assignment.remainingAttempts <= 0 ||
      isAssignmentAutoGraded(root, configuration))
  const revealIncorrectAnswers = showAnswers
  if (assignment.revealAnswers === RevealAnswers.AfterSubmit && assignment.revealAttempts != null) {
    const attemptsMade =
      assignment.remainingAttempts != null
        ? assignment.maxAttempts - assignment.remainingAttempts
        : undefined
    if (attemptsMade != null) {
      showAnswers = showAnswers && attemptsMade >= assignment.revealAttempts
    }
  }
  const shouldDisplayRetry =
    submission?.submittedAt &&
    submission?.grade !== null &&
    submission?.grade !== undefined &&
    assignment.remainingAttempts &&
    assignment.remainingAttempts > 0

  // If forceShowAssignment is true, we don't want to handle the special cases and want to
  // just render the assignment directly. This is usually present inside a teacher view of
  // some sort.
  if (!forceShowAssignment && assignment.timeLimit && !submission?.createdAt) {
    return (
      <StartAssignmentButton
        loading={loading}
        onStartAssignment={() => {
          return onSaveSubmission(
            {
              id: assignment.id,
              classId,
              submissionData: {},
            },
            true,
          )
        }}
        timeLimit={assignment.timeLimit}
        isGroupLeader={groupLeader ? !!isGroupLeader : undefined}
      />
    )
  } else if (!forceShowAssignment && configuration?.isRandomized && !submission?.createdAt) {
    return (
      <StartRandomizedAssignmentButton
        loading={loading}
        onStartAssignment={() => {
          return onSaveSubmission({
            id: assignment.id,
            classId,
            submissionData: {},
          })
        }}
      />
    )
  } else {
    const _submitAnswers = (values: Record<string, any>, wasNotComplete: boolean = false) => {
      if (cancelValuesDebounce) {
        cancelValuesDebounce()
      }
      let content: string | React.ReactElement =
        'Once you submit your assignment, you will no longer be able to edit it.'
      if (wasNotComplete) {
        content = (
          <>
            <Text>{content}</Text>
            <br />
            <br />
            <Text>
              <Bold>It also looks like you have not completed this assignment.&nbsp;</Bold>
              <Text>
                You are still able to submit the assignment, but you won't get credit for questions
                you have left blank.
              </Text>
            </Text>
          </>
        )
      }
      const onOk = async () => {
        const submissionId = await onSaveSubmission({
          id: assignment.id,
          classId,
          submissionData: values,
        })
        if (submissionId) {
          return onSubmit({
            id: submissionId,
            force: wasNotComplete,
          })
        }
      }
      if (skipSubmitConfirmation) {
        onOk().catch(err => {
          error('error submitting %O', err)
        })
      } else {
        Modal.confirm({
          title: 'Are you ready to submit your assignment?',
          content,
          icon: wasNotComplete ? <AntIconWrapper children={<WarningCircle />} /> : undefined,
          okText: 'Yes, submit assignment.',
          okType: 'primary',
          okButtonProps: { danger: wasNotComplete },
          cancelText: 'No, go back.',
          width: 500,
          onOk,
        })
      }
    }

    return (
      <Form
        form={form}
        onFinish={
          disableFormSubmit
            ? undefined
            : values => {
                return _submitAnswers(values)
              }
        }
        onFinishFailed={
          disableFormSubmit
            ? undefined
            : values => {
                return _submitAnswers(values, true)
              }
        }
        onValuesChange={values => {
          onFieldsChange({
            id: assignment.id,
            classId,
            submissionData: values,
          })
          setCompletedFields(getCompletedFields(values))
        }}
      >
        <AssignmentViewerFormContext.Provider
          value={{
            completedFields,
            getCompletedFields,
            setCompletedFields,
            form,
            disableFormSubmit,
            setTimeLimitReached,
            shouldDisplayRetry,
            timeLimitReached,
            setMergedSubmissionData: data => {
              setMergedSubmissionData(data)
              setRawSubmissionData(data)
            },
            actionsDisabled,
          }}
        >
          <SubmissionContextProvider
            value={{
              ...submissionContext,
              submissionData: mergedSubmissionData,
              realTimeSubmissionData:
                studentId && !disableLocalStorage
                  ? mergeAnswers(
                      studentId,
                      classId,
                      assignment.id,
                      submissionId,
                      rawSubmissionData,
                      submission?.updatedAt,
                    )
                  : mergedSubmissionData,
              forcefullyShowAnswers: showAnswers,
              revealIncorrectAnswers,
            }}
            children={children}
          />
        </AssignmentViewerFormContext.Provider>
      </Form>
    )
  }
}
