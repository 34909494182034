import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'
import us from 'us'

export interface StateSelectProps extends SelectProps<any> {
  allOption?: boolean
  internationalOption?: boolean
}

export const StateSelect = React.forwardRef<any, StateSelectProps>(
  ({ allOption, internationalOption, ...props }, ref) => (
    <Select
      showSearch
      optionFilterProp={'children'}
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().includes(input.toLowerCase())
      }
      placeholder={'Select a State'}
      {...props}
      ref={ref}
    >
      {Object.keys(us.states).map((key: string) => (
        <Select.Option key={us.states[key].abbr} value={us.states[key].abbr}>
          {us.states[key].name}
        </Select.Option>
      ))}
      {allOption ? (
        <Select.Option key={'all'} value={'all'}>
          All
        </Select.Option>
      ) : null}
      {internationalOption ? (
        <Select.Option key={'international'} value={'international'}>
          International
        </Select.Option>
      ) : null}
    </Select>
  ),
)
