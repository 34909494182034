import { QuestionCircleOutlined } from '@ant-design/icons'
import {
  ShareSheetMusicButton,
  SimpleSheetMusicEditor,
  ViewerSheetMusicCompositionsList,
  useSheetMusicCompName,
  ViewSheetMusicComposition,
} from '@thesisedu/feature-sheet-music-react'
import {
  Image,
  Modal,
  styled,
  H2,
  PageHead,
  PageHeader,
  HSpaced,
  getFlag,
  setFlag,
  BodyLarge,
} from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

// @ts-ignore
import composeTool from '../../assets/compose-tool.gif'
// @ts-ignore
import composeKid from '../../assets/sketch/website/compose-kid.svg'

const HELP_FLAG = 'prodigies-academy:compose-help'

export function Compose() {
  const [value, setValue] = React.useState('')
  const [helpVisible, setHelpVisible] = React.useState(!getFlag(HELP_FLAG))
  return (
    <>
      <Modal
        visible={helpVisible}
        onCancel={() => {
          setHelpVisible(false)
          setFlag(HELP_FLAG, true)
        }}
        footer={null}
        width={600}
        style={{ maxWidth: '90vw' }}
        bodyStyle={{ padding: 60 }}
        closable={false}
      >
        <H2 style={{ textAlign: 'center', paddingTop: 60 }}>How to use the Compose Tool</H2>
        <HelpImageContainer>
          <Image src={composeTool} alt={'Compose Instructions'} />
        </HelpImageContainer>
        <BodyLarge style={{ textAlign: 'center' }} isBlock>
          To get started with the Compose Tool,{' '}
          <strong>drag and drop from the keyboard below.</strong>
        </BodyLarge>
        <Button
          style={{ margin: '0 auto', display: 'block' }}
          type={'primary'}
          size={'large'}
          onClick={() => {
            setHelpVisible(false)
            setFlag(HELP_FLAG, true)
          }}
        >
          Start Composing!
        </Button>
      </Modal>
      <GlobalOverride />
      <PageHead title={'Compose'} />
      <HeaderContainer space={'@size-xl'}>
        <img src={composeKid} alt={'Compose Child'} />
        <PageHeader
          title={
            <HSpaced align={'baseline'}>
              <div>Compose</div>
              <BodyLarge color={'@primary-color'}>
                <a
                  onClick={e => {
                    e.preventDefault()
                    setHelpVisible(true)
                  }}
                >
                  <QuestionCircleOutlined />
                </a>
              </BodyLarge>
            </HSpaced>
          }
          subtitle={'Create a colorful composition and write your own song.'}
        >
          <Link to={'shared'} style={{ width: 175, textAlign: 'right' }}>
            ...or view compositions you've shared &rarr;
          </Link>
        </PageHeader>
      </HeaderContainer>
      <SimpleSheetMusicEditor
        value={value}
        onChange={setValue}
        viewerProps={{ actions: <ShareSheetMusicButton abc={value} /> }}
      />
    </>
  )
}

const HelpImageContainer = styled.div`
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-large']};
  margin: 0 auto ${props => props.theme['@size-m']} auto;
  width: 80%;
  padding: ${props => props.theme['@padding-md']};
  > div,
  img {
    border-radius: ${props => props.theme['@border-radius-base']};
    display: block;
  }
`

export function SharedCompose() {
  const navigate = useNavigate()
  const { classId } = useParams()
  return (
    <>
      <GlobalOverride />
      <PageHead title={'Shared Compositions'} />
      <HeaderContainer space={'@size-xl'}>
        <img src={composeKid} alt={'Compose Child'} />
        <PageHeader
          title={'Shared Compositions'}
          subtitle={"Compositions you've shared with your teacher."}
          onBack={() => navigate('..')}
        />
      </HeaderContainer>
      <ViewerSheetMusicCompositionsList classIds={[classId]} onClick={navigate} />
    </>
  )
}

export function ViewSharedCompose() {
  const navigate = useNavigate()
  const { sheetMusicCompositionId } = useParams()
  const name = useSheetMusicCompName(sheetMusicCompositionId)
  return (
    <>
      <GlobalOverride />
      <PageHead title={`${name} - Shared Compositions`} />
      <HeaderContainer space={'@size-xl'}>
        <img src={composeKid} alt={'Compose Child'} />
        <PageHeader
          title={'Shared Compositions'}
          subtitle={"Compositions you've shared with your teacher."}
          onBack={() => navigate('..')}
        />
      </HeaderContainer>
      <ViewSheetMusicComposition compId={sheetMusicCompositionId} />
    </>
  )
}

const GlobalOverride = createGlobalStyle`
  .main-content {
    overflow: visible !important; // This is to fix position: sticky inside the editor.
  }
  .sheet-music-editor {
    display: flex;
    flex-direction: column;
  }
  .sheet-music-viewer {
    flex: 1;
  }
`
const HeaderContainer = styled(HSpaced)`
  margin: -${props => props.theme['@size-l']};
  margin-bottom: ${props => props.theme['@size-l']};
  background: ${props => props.theme['@primary-color-light']};
  padding: ${props => props.theme['@size-l']};
  border-top-left-radius: ${props => props.theme['@border-radius-large']};
  border-top-right-radius: ${props => props.theme['@border-radius-large']};
  img {
    height: 175px;
    margin-bottom: -${props => props.theme['@size-l']};
    margin-left: ${props => props.theme['@size-l']};
  }
  > div {
    margin-top: ${props => props.theme['@size-l']};
    width: 100%;
    h1 {
      color: ${props => props.theme['@primary-color']};
    }
    p {
      color: ${props => props.theme['@primary-color']} !important;
      opacity: 0.6;
      margin-left: ${props => props.theme['@size-xxs']};
    }
  }
`
