import { SAMLButtonPlacement, SAMLButtonResource } from '@thesisedu/feature-users-react'

import { CanvasReactFeature } from '../CanvasReactFeature'
import { CanvasLoginButton } from '../web/CanvasLoginButton'

export default function (feature: CanvasReactFeature) {
  feature.resources.addResource<SAMLButtonResource>({
    type: 'SAMLButton',
    identifier: 'canvas',
    weight: 5,
    component: CanvasLoginButton,
    placement: SAMLButtonPlacement.Secondary,
  })
}
