import { MutateHook } from '@thesisedu/feature'
import { useFeatureRoot, useMutateHook } from '@thesisedu/feature-react'
import { useStudentUserId } from '@thesisedu/feature-teachers-react'
import { Format } from '@thesisedu/feature-utils'
import { Empty, Result } from '@thesisedu/react'
import { Block, ImperativeTable, Table$ } from '@thesisedu/ui'
import { Clock, MinusCircle, User } from '@thesisedu/ui/icons'
import { orderBy } from 'lodash'
import React from 'react'

import { ActionsCell } from './ActionsCell'
import { NameCell } from './NameCell'
import { StudentActionsDropdown } from './StudentActionsDropdown'
import { ClassFragment, ClassStudentEdgeFragment } from '../../schema'
import { useTeacherClass } from '../../useClass'
import { InviteStudentModal } from '../invite/InviteStudentModal'

export interface StudentTableStudent extends ClassStudentEdgeFragment {
  id: string
  isTeacher?: boolean
  classId: string
}

export interface StudentsTableProps {
  classId: string
}
export function StudentsTable({ classId }: StudentsTableProps) {
  const { cls, loading, error } = useTeacherClass(classId, false)
  const appOptions = useFeatureRoot().appOptions
  const [inviteVisible, setInviteVisible] = React.useState(false)
  const [selectedStudentIds, setSelectedStudentIds] = React.useState<string[]>([])
  const studentUserId = useStudentUserId()
  const students = orderBy(
    cls?.students.edges.map(edge => ({
      ...edge,
      id: edge.node.id,
      classId,
      isTeacher: edge.node.user.id === studentUserId,
    })) ?? [],
    a => (a.isTeacher ? 0 : 1),
  )
  const columns = useMutateHook<StudentsTableColumnsHook>(
    'classes-react:students-table-columns',
    [
      {
        id: 'name',
        getValue: item => item.node.user.name ?? item.node.user.username,
        renderCell: NameCell,
        isRowHeader: true,
        icon: <User />,
        title: 'Name',
      },
      {
        id: 'lastActivity',
        icon: <Clock />,
        title: 'Last Activity',
        getValue: item => item.node.user.lastActivity,
        renderCell: ({ item }) => Format.date(item.node.user.lastActivity, 'dateTime'),
      },
      {
        id: 'actions',
        allowsSorting: false,
        style: { width: 0 },
        renderCell: ActionsCell,
      },
    ],
    { cls },
  )
  const tableProps = ImperativeTable.useAutoSort({
    columns,
    items: students,
  })

  if (error) {
    return <Result.Error description={'There was an error loading your students.'} />
  } else if (cls) {
    return (
      <div>
        {inviteVisible ? (
          <InviteStudentModal onClose={() => setInviteVisible(false)} classId={cls.id} />
        ) : null}
        <Block bottom={'s'} style={{ width: '100%' }}>
          <StudentActionsDropdown
            students={students
              .filter(s => selectedStudentIds.includes(s.id))
              .map(s => ({ studentId: s.id, classId: s.classId }))}
            children={'Bulk Actions'}
            disabled={!selectedStudentIds.length}
            size={'small'}
          />
        </Block>
        <ImperativeTable
          aria-label={'Students'}
          loading={loading}
          selectedKeys={selectedStudentIds}
          style={{ width: '100%' }}
          onSelectionChange={selection => {
            setSelectedStudentIds(
              selection === 'all' ? students.map(s => s.id) : [...selection].map(s => s.toString()),
            )
          }}
          selectionMode={'multiple'}
          loadingMessage={'Getting your students...'}
          renderEmptyState={() => {
            return (
              <Empty
                icon={<MinusCircle />}
                title={
                  <>
                    Your class doesn't have any <strong>students.</strong>
                  </>
                }
                description={
                  <>
                    Roster students from your LMS automatically, or create them manually inside{' '}
                    {appOptions.name}. Once you create some students, they'll appear here.
                  </>
                }
                action={{
                  onClick() {
                    setInviteVisible(true)
                  },
                  title: 'Add Students',
                }}
              />
            )
          }}
          {...tableProps}
        />
      </div>
    )
  } else {
    return null
  }
}

export type StudentsTableColumnsHook = MutateHook<
  'classes-react:students-table-columns',
  Table$.SortedImperativeTableColumn<StudentTableStudent>[],
  { cls: ClassFragment | null }
>
