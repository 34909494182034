import { useMutateHook } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import {
  H2,
  BodySmall,
  H3Alternate,
  BodyLarge,
  Transition,
  Modal,
  styled,
  AntIconWrapper,
  Key,
} from '@thesisedu/web'
import { Button, Dropdown, Menu } from 'antd'
import { NavArrowRight, EmptyPage, Plus, ArrowLeft } from 'iconoir-react'
import { orderBy, partition } from 'lodash'
import React from 'react'

import { PasteContextProvider } from './PasteContext'
import { TagEditor } from './TagEditor'
import { LooseTagSummary, MutateValidTagTypes, TagType } from './types'

function getTagTypesWithValue(value?: LooseTagSummary): string[] {
  return Object.keys(value || {}).filter(key => !!value?.[key]?.length)
}

export interface TagsEditorModalProps {
  types: TagType[]
  alwaysShowTagTypes?: string[]
  value?: LooseTagSummary
  onChange?: (value: LooseTagSummary) => void
  visible?: boolean
  onClose?: () => void
}
export function TagsEditorModal({
  types,
  alwaysShowTagTypes,
  value,
  onChange,
  visible,
  onClose,
}: TagsEditorModalProps) {
  const isAdmin = useViewerContext(false)?.group === 'ADMIN'
  const [tagTypesWithValue, setTagTypesWithValue] = React.useState(getTagTypesWithValue(value))
  React.useEffect(() => {
    if (visible) {
      setTagTypesWithValue(getTagTypesWithValue(value))
    }
  }, [visible])
  let [validTagTypes, remainingTypes] = partition(
    types,
    type => tagTypesWithValue.includes(type.type) || alwaysShowTagTypes?.includes(type.type),
  )
  validTagTypes = useMutateHook<MutateValidTagTypes>(
    'feature-tags-react:valid-tag-types',
    validTagTypes,
    undefined,
  )
  const [selectedTagType, setSelectedTagType] = React.useState<string | null>(
    validTagTypes[0]?.type || null,
  )
  const selectedTagTypeObject = validTagTypes.find(tt => tt.type === selectedTagType)
  const addProps: Omit<AddTypeDropdownProps, 'children'> = {
    remainingTypes,
    onSelected: type => {
      setTagTypesWithValue(t => [...t, type])
      setSelectedTagType(type)
    },
  }
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      bodyStyle={{ padding: 0, maxWidth: '90vw' }}
      width={900}
    >
      <PasteContextProvider>
        <ContentContainer>
          <LeftContainer>
            <Header>
              <H2>Edit Tags</H2>
            </Header>
            <NavItemsContainer>
              {orderBy(validTagTypes, 'label', 'asc').map(type => {
                const count = (value?.[type.type] || []).length
                return (
                  <TypeMenuItem
                    label={type.label}
                    key={type.type}
                    selected={selectedTagType === type.type}
                    count={count}
                    onClick={() => setSelectedTagType(type.type)}
                  />
                )
              })}
              <AddTypeDropdown {...addProps}>
                <TypeMenuItem label={'Add Type...'} isAdd />
              </AddTypeDropdown>
            </NavItemsContainer>
          </LeftContainer>
          <TagContainer>
            <Transition
              type={'fade-scale'}
              state={selectedTagType || 'none'}
              containerProps={{ style: { height: '100%' } }}
            >
              {selectedTagTypeObject && selectedTagType ? (
                <TagContainerInner>
                  <H3Alternate>{selectedTagTypeObject?.label} Tags</H3Alternate>
                  <TagEditorContainer>
                    <TagEditor
                      value={(value ? value[selectedTagTypeObject.type] || [] : []).map(name => ({
                        type: selectedTagTypeObject.type,
                        name,
                      }))}
                      type={selectedTagTypeObject.type}
                      typeLabel={selectedTagTypeObject.label}
                      searchProps={{
                        noAdd: !isAdmin,
                      }}
                      onChange={tags => {
                        if (onChange) {
                          onChange({
                            ...value,
                            [selectedTagTypeObject.type]: tags.map(tag => tag.name),
                          })
                        }
                      }}
                    />
                  </TagEditorContainer>
                  <FooterContainer>
                    <BodySmall color={'@text-color-secondary'}>
                      <strong>Hint!</strong> Press <Key isCtrl /> + <Key>V</Key> to add tags in
                      bulk.
                    </BodySmall>
                  </FooterContainer>
                </TagContainerInner>
              ) : (
                <NoTypesSelected typeDropdownProps={addProps} value={value} />
              )}
            </Transition>
          </TagContainer>
        </ContentContainer>
      </PasteContextProvider>
    </Modal>
  )
}

interface NoTypesSelectedProps extends Pick<TagsEditorModalProps, 'value'> {
  typeDropdownProps: Omit<AddTypeDropdownProps, 'children'>
}
function NoTypesSelected({ value, typeDropdownProps }: NoTypesSelectedProps) {
  const hasValue = getTagTypesWithValue(value)?.length
  return (
    <NoTypesSelectedContainer>
      <H2 color={'@text-color-secondary'}>{hasValue ? <ArrowLeft /> : <EmptyPage />}</H2>
      <BodyLarge color={'@text-color-secondary'} isBlock>
        {hasValue ? (
          <span>Select a tag type from the menu at the left to get started.</span>
        ) : (
          <span>
            You haven't added any tags yet. To get started, add a tag by using the button below.
          </span>
        )}
      </BodyLarge>
      {hasValue ? null : (
        <AddTypeDropdown {...typeDropdownProps}>
          <Button
            type={'primary'}
            icon={
              <AntIconWrapper>
                <Plus />
              </AntIconWrapper>
            }
          >
            Add Tags
          </Button>
        </AddTypeDropdown>
      )}
    </NoTypesSelectedContainer>
  )
}
const NoTypesSelectedContainer = styled.div`
  margin: ${props => props.theme['@size-l']} auto;
  text-align: center;
  max-width: 500px;
`

export interface AddTypeDropdownProps {
  children: React.ReactElement
  remainingTypes: TagType[]
  onSelected: (type: string) => void
}
export function AddTypeDropdown({ onSelected, children, remainingTypes }: AddTypeDropdownProps) {
  const sortedTypes = orderBy(remainingTypes, 'label', 'asc')
  if (sortedTypes.length) {
    return (
      <Dropdown
        trigger={['click']}
        placement={'topCenter'}
        overlay={
          <Menu
            onClick={({ key }) => {
              onSelected(key.toString())
            }}
          >
            {sortedTypes.map(remainingType => (
              <Menu.Item key={remainingType.type}>{remainingType.label}</Menu.Item>
            ))}
          </Menu>
        }
        children={children}
      />
    )
  } else {
    return null
  }
}

export interface TypeMenuItemProps {
  label: string
  selected?: boolean
  count?: number
  isAdd?: boolean
  onClick?: () => void
}
export function TypeMenuItem({ label, selected, count, isAdd, onClick }: TypeMenuItemProps) {
  return (
    <TagTypeContainer
      className={selected ? 'active' : isAdd ? 'primary' : ''}
      onClick={e => {
        e.preventDefault()
        if (onClick) {
          onClick()
        }
      }}
    >
      <BodyLarge>{label}</BodyLarge>
      {count ? <NumberContainer>{count}</NumberContainer> : null}
      <IconContainer>{isAdd ? <Plus /> : <NavArrowRight />}</IconContainer>
    </TagTypeContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: row;
  height: 600px;
  max-height: calc(var(--app-height) - 200px);
  border-radius: ${props => props.theme['@border-radius-large']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix.
`
const LeftContainer = styled.div`
  background: ${props => props.theme['@gray-1']};
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const TagContainer = styled.div`
  flex: 1;
  padding: ${props => props.theme['@size-l']};
  margin-top: ${props => props.theme['@size-l']};
`
const TagEditorContainer = styled.div`
  flex: 1;
`
const FooterContainer = styled.div`
  text-align: center;
  margin: ${props => props.theme['@size-l']} auto;
  color: ${props => props.theme['@text-color-secondary']};
`
const TagContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
`
const NavItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: ${props => props.theme['@size-l']};
`
const Header = styled.div`
  padding: ${props => props.theme['@size-xl']} ${props => props.theme['@size-l']} 0
    ${props => props.theme['@size-l']};
`
const NumberContainer = styled(BodySmall)`
  display: inline-block;
  background: ${props => props.theme['@gray-2']};
  transition: background 0.25s linear;
  color: ${props => props.theme['@gray-5']};
  line-height: 1;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
`
const IconContainer = styled.span`
  display: block;
  font-size: 14px;
  * {
    display: block;
  }
`
const TagTypeContainer = styled.a`
  background: transparent;
  color: ${props => props.theme['@gray-5']};
  transition:
    background 0.25s linear,
    color 0.25s linear;
  display: flex;
  align-items: center;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-m']}
    ${props => props.theme['@size-xs']} ${props => props.theme['@size-l']};
  min-height: ${props => props.theme['@size-xl']};
  > p:first-child {
    margin-right: auto;
    color: inherit !important;
  }
  ${IconContainer} {
    color: ${props => props.theme['@text-color-secondary']};
    margin-left: ${props => props.theme['@size-xs']};
  }
  &.active,
  &:hover {
    background: ${props => props.theme['@gray-2']};
    color: ${props => props.theme['@gray-7']};
    ${IconContainer} {
      color: ${props => props.theme['@gray-5']};
    }
    ${NumberContainer} {
      background: ${props => props.theme['@gray-3']};
      color: ${props => props.theme['@gray-6']};
    }
  }
  &.primary {
    color: ${props => props.theme['@primary-color']};
    ${IconContainer} {
      color: ${props => props.theme['@primary-color']};
    }
  }
`

export const Styled = {
  ContentContainer,
  LeftContainer,
  TagContainer,
  TagEditorContainer,
  FooterContainer,
  TagContainerInner,
  NavItemsContainer,
  Header,
  NumberContainer,
  IconContainer,
  TagTypeContainer,
  NoTypesSelectedContainer,
} as Record<string, React.FC<React.PropsWithChildren<any>>>
