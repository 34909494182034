import { Tooltip, Reset } from '@thesisedu/react'
import { AddSquare, MinusSquare } from '@thesisedu/react/icons'
import { BodySmall } from '@thesisedu/web'
import React from 'react'

import { useReportGridContext } from './Context'

export function CollapseExpandAll() {
  const { expandedRowIds, setExpandedRowIds, flatSummaryRows } = useReportGridContext(true)
  const expanded = expandedRowIds.length > 0
  return (
    <Tooltip title={expanded ? 'Collapse All' : 'Expand All'}>
      <Reset.Button
        onClick={e => {
          e.preventDefault()
          if (expanded) {
            setExpandedRowIds([])
          } else {
            setExpandedRowIds(flatSummaryRows?.filter(r => !!r.children).map(i => i.id) || [])
          }
        }}
      >
        <BodySmall color={'@text-color-secondary'}>
          {expanded ? <MinusSquare /> : <AddSquare />}
        </BodySmall>
      </Reset.Button>
    </Tooltip>
  )
}
