import { WidgetConverter } from './types'
import { convertPlainText } from './util/text'
import * as Legacy from '..'

interface DefinitionWidgetConfig {
  word: string
  definition: string
}

export default {
  weight: 0,
  label: 'definition',
  identifier: 'Definition',
  convert(value) {
    return [
      {
        type: 'Definition',
        version: 1,
        id: value.id,
        word: convertPlainText(value.config?.word || ''),
        definition: convertPlainText(value.config?.definition || ''),
      },
    ]
  },
} satisfies WidgetConverter<Legacy.Widget<DefinitionWidgetConfig>>
