import { Button, Form, message, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React from 'react'

import { DistrictForm } from './DistrictForm'
import { useCreateDistrictMutation } from './queries/useCreateDistrictMutation'
import { DistrictFragment } from './schema'

export interface CreateDistrictModalProps extends Partial<ModalProps> {
  onCancel: () => void
  onCreated?: (district: DistrictFragment) => void
}
export const CreateDistrictModal: React.FC<
  React.PropsWithChildren<CreateDistrictModalProps>
> = props => {
  const [form] = Form.useForm()
  const onCancel = () => {
    form.resetFields()
    props.onCancel()
  }
  const [createDistrict, { loading }] = useCreateDistrictMutation({
    onCompleted: data => {
      message.success('District created successfully!')
      onCancel()
      if (props.onCreated) {
        props.onCreated(data.createDistrict.district)
      }
    },
  })

  return (
    <Modal
      title={'Create District'}
      {...props}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} type={'primary'} onClick={form.submit} loading={loading}>
          Create District
        </Button>,
      ]}
    >
      <DistrictForm
        form={form}
        onFinish={values =>
          createDistrict({
            variables: { input: values },
          })
        }
      />
    </Modal>
  )
}
