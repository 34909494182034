import React from 'react'

import { s } from '../..'
import { HTMLProps } from '../../sharedTypes'

export interface GridProps extends HTMLProps<HTMLDivElement> {
  columnWidth?: number
  gap?: s.AnySize
  rowGap?: s.AnySize
  columnGap?: s.AnySize
}
export function Grid({
  columnWidth = 360,
  gap = 'l',
  rowGap = gap,
  columnGap = gap,
  ...rest
}: GridProps) {
  return (
    <div
      {...rest}
      style={{
        display: 'grid',
        rowGap: s.size(rowGap),
        columnGap: s.size(columnGap),
        gridTemplateColumns: `repeat(auto-fill, minmax(${columnWidth}px, 1fr))`,
        ...rest.style,
      }}
    />
  )
}
