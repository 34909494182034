import {
  SelectButton,
  SelectButtonProps,
  SingleSelectButton,
  SingleSelectButtonProps,
} from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import {
  UserDocument,
  UserFragment,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
} from '../schema'

export type UserSelectProps = Partial<
  SingleSelectButtonProps<UserFragment, UsersQuery, UsersQueryVariables>
>
export function UserSelect(props: UserSelectProps) {
  return (
    <SingleSelectButton<UserFragment, UsersQuery, UsersQueryVariables>
      filterVariableName={'name'}
      selectLabel={'Select a User'}
      maxSelection={1}
      getItemName={item => item.name || item.username}
      nodeDocument={UserDocument}
      document={UsersDocument}
      getResult={data => data?.users}
      setResult={(previous, result) => ({
        ...previous,
        users: {
          ...previous.users,
          ...result,
        },
      })}
      {...props}
    />
  )
}

export type MultiUserSelectProps = Partial<
  SelectButtonProps<UserFragment, UsersQuery, UsersQueryVariables>
>
export function MultiUserSelect(props: MultiUserSelectProps) {
  return (
    <SelectButton<UserFragment, UsersQuery, UsersQueryVariables>
      filterVariableName={'name'}
      selectLabel={'Select Users'}
      getItemName={item => item.name || item.username}
      nodeDocument={UserDocument}
      document={UsersDocument}
      getResult={data => data?.users}
      setResult={(previous, result) => ({
        ...previous,
        users: {
          ...previous.users,
          ...result,
        },
      })}
      {...props}
    />
  )
}
