import { getFileExtension, mergeFileInfo } from '@thesisedu/feature-attachments-react'
import { LexicalEditor } from 'lexical'
import path from 'path'
import React from 'react'

import { warn } from '../../../log'
import { useLexicalUpload } from '../../utils/upload'

export interface UploadFile {
  editor: LexicalEditor
  file: File
}

export interface UseUploadFileOpts {
  uploadFile?: UploadFile
  afterUpload: (file?: string) => void
}
export interface UseUploadFile {
  loading: boolean
  file?: string
}
export function useUploadFile({ uploadFile, afterUpload }: UseUploadFileOpts) {
  const doUpload = useLexicalUpload('file')
  const [loading, setLoading] = React.useState(false)
  const [file, setFile] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    if (!uploadFile) return
    setLoading(true)
    let shouldContinue = true

    const extension = path.extname(uploadFile.file.name) || getFileExtension(uploadFile.file)
    if (!extension) return
    doUpload({
      contents: uploadFile.file,
      name: uploadFile.file.name,
      extension,
      mimeType: uploadFile.file.type,
    })
      .then(uploadedPath => {
        if (shouldContinue && uploadedPath) {
          const normalPath = mergeFileInfo(uploadedPath, uploadFile.file.name)
          setFile(normalPath)
          afterUpload(normalPath)
        }
      })
      .catch(err => {
        warn('error uploading file')
        warn(err)
      })
      .finally(() => {
        setLoading(false)
        afterUpload()
      })

    return () => {
      shouldContinue = false
      setLoading(false)
    }
  }, [uploadFile])

  return { loading, file }
}
