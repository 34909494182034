import { CaretDownOutlined } from '@ant-design/icons'
import {
  styled,
  Body,
  CapitalSmall,
  H3Alternate,
  HSpaced,
  Space,
  VSpaced,
  BodySmall,
  DATE_FORMATS,
  FontWeight,
} from '@thesisedu/web'
import { Popover, Button } from 'antd'
import moment from 'moment'
import React from 'react'

import { useGradingTeacherClassTaskContext } from '../contexts/GradingTeacherClassTaskContext'
import { GradeAssignmentLink } from '../grading/GradeAssignmentLink'
import { ReviewAssignmentUserTask } from '../resources/user_tasks/ReviewAssignmentUserTask'

export interface ClassSummaryPopoverProps {
  classId: string
}
export function ClassSummaryPopover({ classId }: ClassSummaryPopoverProps) {
  const { classes } = useGradingTeacherClassTaskContext(false) || {}
  const {
    assignmentsToGrade,
    assignmentsToGradeIsMax,
    assignmentsDueSoonIsMax,
    assignmentsDueSoon,
  } = classes?.find(cls => cls.classId === classId) || {}
  const [visible, setVisible] = React.useState(false)

  if (assignmentsToGrade?.length || assignmentsDueSoon?.length) {
    const toGradeText = assignmentsToGrade?.length
      ? `${assignmentsToGrade.length}${assignmentsToGradeIsMax ? '+' : ''} to grade`
      : null
    const dueSoonText = assignmentsDueSoon?.length
      ? `${assignmentsDueSoon.length}${assignmentsDueSoonIsMax ? '+' : ''} due soon`
      : null
    return (
      <Popover
        trigger={['click']}
        placement={'bottomRight'}
        visible={visible}
        onVisibleChange={setVisible}
        content={
          <ContentContainer>
            {assignmentsToGrade?.length ? (
              <>
                <BorderHeader>Ungraded</BorderHeader>
                <VSpaced align={'stretch'}>
                  {assignmentsToGrade.map(assignment => (
                    <ReviewAssignmentUserTask
                      key={assignment.id}
                      task={{ payload: { assignmentId: assignment.id, name: assignment.name } }}
                      onClick={() => setVisible(false)}
                    />
                  ))}
                </VSpaced>
              </>
            ) : null}
            {assignmentsDueSoon?.length ? (
              <>
                <BorderHeader>Due Soon</BorderHeader>
                <VSpaced align={'stretch'}>
                  {assignmentsDueSoon.map(assignment => (
                    <GradeAssignmentLink
                      assignment={{ id: assignment.id }}
                      onClick={() => setVisible(false)}
                    >
                      <Body weight={FontWeight.SemiBold}>{assignment.name}</Body>
                      <BodySmall color={'@text-color-secondary'}>
                        Due {moment(assignment.dueAt).format(DATE_FORMATS.FULL)}
                      </BodySmall>
                    </GradeAssignmentLink>
                  ))}
                </VSpaced>
              </>
            ) : null}
          </ContentContainer>
        }
      >
        <Button size={'large'}>
          <HSpaced>
            <div style={{ textAlign: 'left' }}>
              <CapitalSmall>Assignment Summary</CapitalSmall>
              <Body children={[toGradeText, dueSoonText].filter(Boolean).join(', ')} />
            </div>
            <Space />
            <CaretDownOutlined />
          </HSpaced>
        </Button>
      </Popover>
    )
  } else {
    return null
  }
}

const BorderHeader = styled(H3Alternate)`
  padding-bottom: ${props => props.theme['@size-xs']};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  margin-bottom: ${props => props.theme['@size-s']};
  &:not(:first-child) {
    margin-top: ${props => props.theme['@size-m']};
  }
`
const ContentContainer = styled.div`
  width: 300px;
  max-width: 90vw;
`
