import { getInputIdentifier } from '../../execute/input'
import { ReportMetricResource, RunReportOpts } from '../../types'

export function getMetricSummaryFromOpts(
  opts: RunReportOpts,
  metricResources: ReportMetricResource[],
) {
  if (opts.metrics.length === 1) {
    const metricId = getInputIdentifier(opts.metrics[0])
    const resource = metricResources.find(r => r.identifier === metricId)
    return resource?.summarization || 'average'
  } else {
    return 'average'
  }
}
