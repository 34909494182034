import React, { useContext } from 'react'

import { Segment, SegmentOverrideWithChanges } from '../types'

export interface SegmentOverrideContextValue {
  onSegmentsOverride: (overrides: SegmentOverrideWithChanges[]) => void
  rePlaceSegment?: (segment: Segment) => void
}
export const SegmentOverrideContext = React.createContext<SegmentOverrideContextValue | undefined>(
  undefined,
)

export function useSegmentOverrideContext(): SegmentOverrideContextValue | undefined
export function useSegmentOverrideContext(require: false): SegmentOverrideContextValue | undefined
export function useSegmentOverrideContext(require: true): SegmentOverrideContextValue
export function useSegmentOverrideContext(
  require?: boolean,
): SegmentOverrideContextValue | undefined {
  const context = useContext(SegmentOverrideContext)
  if (!context && require) {
    throw new Error('SegmentOverrideContext is required, yet not provided.')
  }
  return context
}
