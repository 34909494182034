import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useImageLoad, ImageLoadResult, styled, StyledComponent } from '@thesisedu/web'
import React from 'react'
import { keyframes } from 'styled-components'

export interface GridImageProps {
  src: string
  alt: string
}
export function GridImage({ src, alt }: GridImageProps) {
  const loadState = useImageLoad(src)
  const [includePlaceholder, setIncludePlaceholder] = React.useState(true)
  const shouldAnimateRef = React.useRef(false)
  // If the image loads before 250ms have passed, show it immediately, without animating.
  React.useEffect(() => {
    const interval = setTimeout(() => {
      shouldAnimateRef.current = true
    }, 250)
    return () => clearInterval(interval)
  }, [])
  React.useEffect(() => {
    if (loadState === ImageLoadResult.Loaded) {
      if (shouldAnimateRef.current) {
        setTimeout(() => {
          setIncludePlaceholder(false)
        }, 250)
      } else {
        setIncludePlaceholder(false)
      }
    }
  }, [loadState])
  return (
    <LoadContainer>
      {includePlaceholder ? (
        <ImagePlaceholder>
          <IconContainer background={loadState === ImageLoadResult.Error ? '@orange' : undefined}>
            {loadState === ImageLoadResult.Error ? (
              <ExclamationCircleOutlined />
            ) : (
              <LoadingOutlined />
            )}
          </IconContainer>
        </ImagePlaceholder>
      ) : null}
      {loadState === ImageLoadResult.Loaded ? (
        <ImageContainer>
          <div>
            <img src={src} alt={alt} />
          </div>
        </ImageContainer>
      ) : null}
    </LoadContainer>
  )
}
const FadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
export const ImageContainer: StyledComponent<'div'> = styled.div`
  width: 100%;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix
  position: relative;
  background: ${props => props.theme['@gray-7']};
  &::before {
    display: block;
    content: ' ';
    padding-bottom: 56.25%;
    width: 100%;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
  }
`
const LoadContainer = styled.div`
  position: relative;
  > ${ImageContainer}:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: ${FadeIn} 0.25s linear;
  }
`
export interface ImagePlaceholderProps {
  containerStyle?: Record<string, any>
  [other: string]: any
}
export function ImagePlaceholder({
  children,
  containerStyle,
  ...props
}: React.PropsWithChildren<ImagePlaceholderProps>) {
  return (
    <_ImagePlaceholder {...props}>
      <div style={containerStyle}>{children}</div>
    </_ImagePlaceholder>
  )
}
const _ImagePlaceholder: StyledComponent<'div'> = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  transform: translateZ(0); // Safari fix.
  background-image: linear-gradient(
    146deg,
    ${props => props.theme['@gray-1']} 8%,
    ${props => props.theme['@gray-2']} 100%
  );
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: ${props => props.theme['@size-l']};
    align-items: center;
    justify-content: center;
  }
`
export const IconContainer: React.FC<
  React.PropsWithChildren<{ background?: string }>
> = styled.div<{
  background?: string
}>`
  color: ${props => props.theme['@gray-5']};
  font-size: ${props => props.theme['@size-l']};
  width: ${props => props.theme['@size-xxl']};
  height: ${props => props.theme['@size-xxl']};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme[props.background || '@gray-5']};
  color: white;
  svg {
    display: block;
  }
`
