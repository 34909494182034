import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { EditDistrict } from './Edit'
import { ListDistricts } from './List'

export function DistrictsRoute() {
  useBreadcrumb({ title: 'Districts' })
  return (
    <Routes>
      <Route path={'/'} element={<ListDistricts />} />
      <Route path={':districtId'} element={<EditDistrict />} />
    </Routes>
  )
}
