import * as ReactSelect from '@radix-ui/react-select'

import { Select } from './Select'
import { Label, SelectItem, Separator } from './SelectItem'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './Select'
export * from './SelectItem'

export default withSubComponents(Select, {
  Item: SelectItem,
  Separator,
  Label,
  Group: ReactSelect.Group,
})
