import { PageHead } from '@thesisedu/react'
import { PageHeader } from '@thesisedu/web'
import React from 'react'

import { ExploreGrid } from '../../../explore/grid'
import { SegmentType } from '../../../schema'

export function Search() {
  return (
    <>
      <PageHead title={'Search - Explore'} />
      <PageHeader title={'Search'} />
      <ExploreGrid
        searchPlaceholder={'Search Content'}
        variables={{
          types: [SegmentType.Group],
          labels: ['Lesson', 'Project', 'Ep.'],
        }}
      />
    </>
  )
}
