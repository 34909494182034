import { error } from './log'

export async function copyToClipboard(
  content: string,
  parentWindow: Window = window,
): Promise<boolean> {
  try {
    const permissionResult = await parentWindow.navigator.permissions.query({
      name: 'clipboard-write' as any,
    })
    // Try reading the clipboard to trigger permissions, so we can "enable" the paste button.
    // (the paste button does not ask for permissions by default, as it would do so on launch,
    // and that is annoying).
    await parentWindow.navigator.clipboard.readText()
    if (permissionResult.state === 'granted') {
      await parentWindow.navigator.clipboard.writeText(content)
    }

    return true
  } catch (err: any) {
    error('error copying to clipboard')
    error(err)
    return false
  }
}
