import { LargeFile } from '@thesisedu/feature-attachments-react'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'

export function FileUploadAnswer(props: QuestionProps) {
  const data = useSubmissionData(props.question.id)
  let content
  if (data) {
    content = <LargeFile filename={data} />
  } else {
    content = null
  }

  return (
    <QuestionInstructions instructions={'Upload a file using the field below.'} padded>
      {content}
    </QuestionInstructions>
  )
}
