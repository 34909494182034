import { useGradingModalVisibilityContext } from '@thesisedu/feature-assignments-react'
import {
  GradingModalNavigationItem,
  GradingModalNavigationItemProps,
} from '@thesisedu/feature-assignments-react/dist/grading/GradingModalNavigation'
import { AssignmentWithConfigurationFragment } from '@thesisedu/feature-assignments-react/dist/schema'
import { useSegmentNavigate } from '@thesisedu/feature-courses-react'
import React from 'react'

import { isSegmentConfiguration } from '../../helpers'

export interface GradeModalAssignmentNavigationItemProps extends GradingModalNavigationItemProps {
  assignment: AssignmentWithConfigurationFragment
}
export function GradeModalAssignmentNavigationItem({
  item,
  assignment,
}: GradeModalAssignmentNavigationItemProps) {
  const config = assignment.configuration || {}
  const segmentId = isSegmentConfiguration(config) ? config.segmentId : undefined
  const navigate = useSegmentNavigate({ id: segmentId || '' })
  const { setGradingAssignment } = useGradingModalVisibilityContext() || {}
  return (
    <GradingModalNavigationItem
      item={item}
      onClick={
        segmentId
          ? () => {
              if (setGradingAssignment) {
                setGradingAssignment(undefined)
              }
              navigate()
            }
          : undefined
      }
    />
  )
}
