import { combineSegmentMetadata, getSegmentName } from '@thesisedu/feature-course-types'
import { Button, Dropdown, Form, Modal, Select, VSpaced } from '@thesisedu/ui'
import { AddFolder, Check, GridAdd, NavArrowDown, Plus } from '@thesisedu/ui/icons'
import { cloneDeep, orderBy } from 'lodash'
import React from 'react'

import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { useSplitViewRefsContext } from './SplitViewRefsContext'
import { useCreateSegment } from './useCreateSegment'
import { OutlineListImperativeHandle } from '../outline/OutlineList'
import { SegmentType } from '../schema'
import { GroupSegment } from '../types'

enum CreateMode {
  Topic = 'topic',
  Group = 'group',
}
const CreateModeName: Record<CreateMode, string> = {
  [CreateMode.Topic]: 'Topic',
  [CreateMode.Group]: 'Group',
}

const BEGINNING = 'beginning'
const END = 'end'
interface TopicSelectProps {
  selectedTerm: GroupSegment
  otherTerms?: GroupSegment[]
  label?: string
  showBeginningEnd?: boolean
}
function TopicSelect({
  selectedTerm,
  otherTerms,
  label = 'After Topic',
  showBeginningEnd = true,
}: TopicSelectProps) {
  const allTerms = [selectedTerm, ...(otherTerms ?? [])]
  const termName = allTerms.length > 1 ? selectedTerm.name : 'Term'
  const topics = orderBy([selectedTerm, ...(otherTerms ?? [])], 'weight', 'asc').flatMap(
    term => term.childSegments ?? [],
  )
  if (!showBeginningEnd && !topics.length) return null
  return (
    <Form.Select
      name={'segmentId'}
      label={label}
      initialValue={showBeginningEnd ? END : undefined}
      required
      placeholder={'Select a Topic...'}
    >
      {showBeginningEnd ? (
        <>
          <Select.Item value={BEGINNING}>Beginning of {termName}</Select.Item>
          <Select.Item value={END}>End of {termName}</Select.Item>
        </>
      ) : null}
      {topics.length && showBeginningEnd ? <Select.Separator /> : null}
      {topics.length ? (
        <>
          {topics.map(topic => {
            return (
              <Select.Item key={topic.id} value={topic.id}>
                {getSegmentName(topic)}
              </Select.Item>
            )
          })}
        </>
      ) : null}
    </Form.Select>
  )
}

export interface AddGroupsAndTopicsButtonProps
  extends Omit<AddGroupsAndTopicsModalProps, 'createMode' | 'onCreateModeChange'> {}
export function AddGroupsAndTopicsButton(props: AddGroupsAndTopicsButtonProps) {
  const [createMode, setCreateMode] = React.useState<CreateMode | null>(null)
  return (
    <>
      <AddGroupsAndTopicsModal
        {...props}
        createMode={createMode}
        onCreateModeChange={setCreateMode}
      />
      <Dropdown.Container>
        <Dropdown.Button icon={<Plus />} rightDecorator={<NavArrowDown />}>
          Add Groups &amp; Topics
        </Dropdown.Button>
        <Dropdown.Menu>
          <Dropdown.Item
            icon={<GridAdd />}
            children={'Topic'}
            onClick={() => setCreateMode(CreateMode.Topic)}
          />
          <Dropdown.Item
            icon={<AddFolder />}
            children={'Group'}
            onClick={() => setCreateMode(CreateMode.Group)}
          />
        </Dropdown.Menu>
      </Dropdown.Container>
    </>
  )
}

export interface AddTopicButtonProps
  extends Omit<AddGroupsAndTopicsModalProps, 'createMode' | 'onCreateModeChange'> {}
export function AddTopicButton(props: AddTopicButtonProps) {
  const [createMode, setCreateMode] = React.useState<CreateMode | null>(null)
  return (
    <>
      <AddGroupsAndTopicsModal
        {...props}
        createMode={createMode}
        onCreateModeChange={setCreateMode}
      />
      <Button
        style={{ margin: '0 auto' }}
        icon={<Plus />}
        children={'Create a Topic to Add Content'}
        onPress={() => setCreateMode(CreateMode.Topic)}
        variant={'primary'}
      />
    </>
  )
}

export interface AddGroupsAndTopicsModalProps {
  selectedTerm: GroupSegment
  otherTerms?: GroupSegment[]
  listRef: React.RefObject<OutlineListImperativeHandle>
  createMode?: CreateMode | null
  onCreateModeChange: (createMode: CreateMode | null) => void
}
export function AddGroupsAndTopicsModal({
  createMode,
  onCreateModeChange: setCreateMode,
  selectedTerm: _selectedTerm,
  otherTerms,
  listRef,
}: AddGroupsAndTopicsModalProps) {
  const { leftRef, rightRef } = useSplitViewRefsContext(false) ?? {}
  const { changes } = useSplitViewPendingChangesContext(true)
  const selectedTerm = React.useMemo(() => {
    const cloned = cloneDeep(_selectedTerm)
    combineSegmentMetadata(changes.metadataOverrides, [cloned])
    return cloned
  }, [_selectedTerm, changes])
  const { createSegment } = useCreateSegment({ selectedTerm })
  const form = Form.useForm<{ name: string; segmentId?: string }>()
  const modeName = createMode ? CreateModeName[createMode] : 'Item'
  return (
    <Form.Modal
      visible={!!createMode}
      form={form}
      onVisibleChange={v => {
        if (!v) setCreateMode(null)
      }}
      onFinish={values => {
        let parentId: string | undefined = undefined
        let weight: number | undefined = undefined
        if (
          createMode === CreateMode.Group &&
          values.segmentId &&
          values.segmentId !== BEGINNING &&
          values.segmentId !== END
        ) {
          parentId = values.segmentId
        } else if (createMode === CreateMode.Topic && values.segmentId) {
          if (values.segmentId === BEGINNING) {
            weight = (selectedTerm.childSegments?.[0]?.weight ?? 0) - 1
          } else if (values.segmentId === END) {
            const greatestWeight = Math.max(
              selectedTerm.childSegments?.length ?? 0,
              selectedTerm.childSegments?.[selectedTerm.childSegments.length - 1]?.weight ?? 0,
            )
            weight = greatestWeight + 1
          } else {
            const matchingTerm = [selectedTerm, ...(otherTerms ?? [])].find(term => {
              return term.childSegments?.some(child => child.id === values.segmentId)
            })
            const previousTopicIndex = matchingTerm?.childSegments?.findIndex(
              c => c.id === values.segmentId,
            )
            if (matchingTerm && previousTopicIndex != null && previousTopicIndex !== -1) {
              const previousTopic = matchingTerm.childSegments![previousTopicIndex]
              const nextTopic = matchingTerm.childSegments![previousTopicIndex + 1]
              const previousWeight = previousTopic.weight ?? 0
              const nextWeight = nextTopic?.weight ?? previousWeight + 1
              if (previousTopic) {
                parentId = matchingTerm.id
                weight = previousWeight + (nextWeight - previousWeight) / 2
              }
            }
          }
        }
        const result = createSegment(
          {
            name: values.name,
            type: createMode === CreateMode.Topic ? SegmentType.Topic : SegmentType.Group,
            weight,
          },
          parentId,
        )
        setCreateMode(null)
        setTimeout(() => {
          // Use the refs from the split view if they exist, otherwise use the list ref.
          if (leftRef || rightRef) {
            leftRef?.current?.highlightSegment(result.id)
            rightRef?.current?.highlightSegment(result.id)
          } else {
            listRef.current?.highlightSegment(result.id)
          }
        }, 10)
      }}
      title={`Create ${modeName}`}
    >
      <VSpaced space={'m'}>
        <Form.TextField
          name={'name'}
          required
          label={'Name'}
          size={'large'}
          placeholder={`My Custom ${modeName}`}
          autoComplete={'off'}
        />
        <TopicSelect
          selectedTerm={selectedTerm}
          otherTerms={otherTerms}
          showBeginningEnd={createMode === CreateMode.Topic}
          label={createMode === CreateMode.Group ? 'In Topic' : 'After Topic'}
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Check />}>Create {modeName}</Form.Submit>
      </Modal.Footer>
    </Form.Modal>
  )
}
