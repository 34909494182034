import { Block, LoadingIndicator, Modal } from '@thesisedu/ui'
import React from 'react'

import { useBackgroundJob } from './UserBackgroundJobContext'
import { BackgroundJobFragment, BackgroundJobStatus } from '../schema'

export interface BackgroundJobModalProps {
  backgroundJobId?: string
  onComplete?: (job: BackgroundJobFragment) => void | Promise<void>
  onError?: (job: BackgroundJobFragment) => void
  message: string
}
export function BackgroundJobModal({
  backgroundJobId,
  onComplete,
  onError,
  message,
}: BackgroundJobModalProps) {
  const backgroundJob = useBackgroundJob(backgroundJobId ?? '--')
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    if (backgroundJob?.status === BackgroundJobStatus.Error) {
      onError?.(backgroundJob)
    } else if (backgroundJob?.status === BackgroundJobStatus.Success) {
      if (onComplete) {
        setLoading(true)
        new Promise(resolve => resolve(onComplete(backgroundJob))).then(() => setLoading(false))
      }
    }
  }, [backgroundJob?.status])

  if (
    (backgroundJob &&
      backgroundJob.status !== BackgroundJobStatus.Success &&
      backgroundJob.status !== BackgroundJobStatus.Error) ||
    loading
  ) {
    return (
      <Modal noClose visible onVisibleChange={() => {}} data-testid={'BackgroundJobModal'}>
        <Block paddingVertical={'l'}>
          <LoadingIndicator.Labeled size={'l'} label={message} />
        </Block>
      </Modal>
    )
  } else return null
}
