import { AxisTickProps } from '@nivo/axes'
import { BarDatum } from '@nivo/bar'
import React from 'react'

import { ResponsiveBarProps, Bar } from './ResponsiveBar'
import { getNivoTheme } from './theme'
import { StyledThemeContext } from '..'

function CustomTick(tick: AxisTickProps<any>) {
  const theme = React.useContext(StyledThemeContext)
  const graphTheme = getNivoTheme(theme)
  return (
    <g transform={`translate(${tick.x + 5},${tick.y - 10})`}>
      <text style={graphTheme.axis?.ticks?.text}>{tick.value}</text>
    </g>
  )
}

export function HorizontalBar<T extends BarDatum = any>(props: ResponsiveBarProps<T>) {
  return (
    <Bar<T>
      layout={'horizontal'}
      {...props}
      axisLeft={{
        ...props.axisLeft,
        renderTick: CustomTick,
      }}
      padding={0.7}
    />
  )
}
