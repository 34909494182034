import { IFeatureResourceManager } from '@thesisedu/feature'

import { CustomEmojiResource } from './types'

export const isInNode = () => typeof global !== 'undefined' && !!(global as any).featureIsInNode

/**
 * Adds support for a custom emoji
 * @param feature the current feature
 * @param codes the codes to respond to, without the :
 * @param render the component to render to replace the code
 */
export function addCustomEmoji(
  resources: IFeatureResourceManager,
  codes: string[],
  render: CustomEmojiResource['render'],
) {
  resources.addResource<CustomEmojiResource>({
    type: 'CustomEmoji',
    identifier: codes.join('-'),
    codes,
    render,
  })
}
