import { ReportSummaries, ReportMetricResult, ReportDimensionResult } from './extensibleTypes'
import { RunReportInput } from '../../schema'
import { ReportMetricResource, ReportMetricSummaryResource } from '../types'

export function getAllMetricSummaries(
  allMetricResources: ReportMetricResource[],
  summaries: ReportMetricSummaryResource[],
): string {
  const allResult = [...new Set(allMetricResources.map(r => r.metricKey))].join('\n')
  return `
    average { ${allResult} }
    count { ${allResult} }
    sum { ${allResult} }
    min { ${allResult} }
    max { ${allResult} }
    std { ${allResult} }
    ${summaries.map(summary => `${summary.identifier} { ${allResult} }`).join('\n')}
  `
}

export type NoneDimensionInput = 'none'

export interface RunReportQueryVariables {
  input: RunReportInput
}

export interface AllMetricSummariesFragment {
  average: ReportMetricResult
  count: ReportMetricResult
  sum: ReportMetricResult
  min: ReportMetricResult
  max: ReportMetricResult
  std: ReportMetricResult
  [key: string]: ReportMetricResult
}
export type PartialMetricSummariesFragment = Partial<AllMetricSummariesFragment>
export interface DimensionSummaryFragment {
  dimensions: ReportDimensionResult
  metrics: AllMetricSummariesFragment
}
export interface ReportResultItemFragment {
  dimensions: ReportDimensionResult
  metrics: PartialMetricSummariesFragment
}

export interface RunReportQuery {
  runReport: {
    allMetrics: AllMetricSummariesFragment
    dimensionSummaries: DimensionSummaryFragment[]
    results?: ReportResultItemFragment[]
    summaries?: ReportSummaries
  }
}
