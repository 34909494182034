import React from 'react'

import { AntOverrides } from './AntOverrides'
import { Reset } from './Reset'
import { RichText } from './RichText'
import { Transitions } from './Transitions'
import { CallAttentionGlobalStyle } from '../useCallAttention'

export function GlobalStyles() {
  return (
    <>
      <Reset />
      <AntOverrides />
      <Transitions />
      <RichText />
      <CallAttentionGlobalStyle />
    </>
  )
}
