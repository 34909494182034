import { color, size } from '@thesisedu/react'
import { createGlobalStyle, keyframes } from 'styled-components'

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
export const DraggableGlobalStyles: any = createGlobalStyle`
  .ft-widgets-selected {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      transition: background 0.1s linear;
      animation: ${opacityAnimation} 0.1s linear;
      inset: -${size('@size-xxs')};
      border-radius: ${size('@border-radius-base')};
      background: ${color('@primary-color', { ghost: 0.9 })};
    }
    &.leaving::before {
      background: transparent;
    }
  }
`
