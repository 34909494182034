import * as React from 'react'

function SvgSolfegeHighFi(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns={'http://www.w3.org/2000/svg'}
      xmlnsXlink={'http://www.w3.org/1999/xlink'}
      viewBox={'0 0 256 256'}
      {...props}
    >
      <defs>
        <path id={'solfege-high-fi_svg__a'} d={'M0 0h230v229.995H0z'} />
      </defs>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M238.493 128c0 61.023-49.469 110.493-110.493 110.493-61.024 0-110.493-49.47-110.493-110.494S66.976 17.506 128 17.506c61.024 0 110.493 49.47 110.493 110.493'
          }
        />
        <g transform={'translate(13 13)'}>
          <mask id={'solfege-high-fi_svg__b'} fill={'#fff'}>
            <use xlinkHref={'#solfege-high-fi_svg__a'} />
          </mask>
          <path
            fill={'#63BF83'}
            d={
              'M115 9.012C56.557 9.012 9.013 56.555 9.013 114.999c0 58.443 47.544 105.986 105.987 105.986S220.987 173.442 220.987 115C220.987 56.555 173.443 9.012 115 9.012m0 220.983c-63.412 0-115-51.588-115-114.996 0-63.408 51.588-115 115-115s115 51.592 115 115-51.588 114.996-115 114.996'
            }
            mask={'url(#solfege-high-fi_svg__b)'}
          />
        </g>
        <path
          fill={'#63BF83'}
          d={
            'M104.03 172.786h-5.523l2.563-13.77h2.96c0-2.882.373-5.499 1.12-7.847.749-2.348 1.884-4.335 3.405-5.963 1.521-1.629 3.454-2.896 5.806-3.805 2.348-.906 5.147-1.36 8.405-1.36.909 0 1.721.068 2.444.2.72.132 1.479.254 2.28.361l2.084 13.291h-2.724c-1.761 0-3.11.455-4.045 1.36-.934.906-1.4 2.163-1.4 3.763h6.884v13.77h-6.884l.48 31.708-17.933 1.442.078-33.15z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M99.996 171.549h5.276l-.082 33.042 15.442-1.238-.483-31.804h6.905v-11.294h-6.884v-1.238c0-1.94.595-3.505 1.772-4.654 1.17-1.134 2.821-1.707 4.908-1.707h1.274l-1.714-10.957a42.076 42.076 0 01-1.425-.24 12.646 12.646 0 00-2.22-.182c-3.085 0-5.762.43-7.957 1.277-2.165.842-3.966 2.016-5.348 3.498-1.388 1.485-2.44 3.336-3.124 5.494-.71 2.216-1.067 4.729-1.067 7.47v1.24h-3.172l-2.1 11.293zm2.713 35.727l.08-33.254h-5.771l3.025-16.244h2.77c.093-2.523.487-4.868 1.156-6.987.802-2.51 2.04-4.671 3.68-6.432 1.643-1.758 3.752-3.14 6.264-4.11 2.481-.963 5.46-1.446 8.853-1.446.966 0 1.861.072 2.666.222a50.03 50.03 0 002.227.35l.91.122 2.451 15.632h-4.17c-1.421 0-2.491.344-3.182 1.01-.44.43-.738.973-.895 1.64h6.754v16.243h-6.862l.476 31.615-20.432 1.64z'
          }
        />
        <path
          fill={'#63BF83'}
          d={
            'M134.054 159.737l17.855-1.439-.88 46.198-16.975 1.439v-46.198zm-.078-20.257l18.338-.802-.963 14.093-17.458 1.282.083-14.573z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M135.204 140.665l-.068 12.056 15.052-1.106.795-11.637-15.779.687zm-2.556 14.719l.097-17.089 20.894-.913-1.124 16.545-19.867 1.457zm2.642 5.494v43.707l14.522-1.228.83-43.713-15.352 1.234zm-2.473 46.406v-48.69l20.36-1.642-.93 48.682-19.43 1.65z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M58.795 88.177v27.444h15.56c1.267 0 4.854 4.643 15.199 6.755 10.345 2.112 22.802-.315 24.28-.526 1.475-.212 5.301-35.291 4.245-36.77-1.052-1.474-16.068-.705-18.391-3.658-2.324-2.956 7.388-10.767 6.543-19.634-.845-8.866-5.91-6.965-7.81-6.754-1.901.211-1.479 9.922-6.544 14.776-5.065 4.854-16.043 17.1-18.789 17.944-2.745.845-14.293.423-14.293.423'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M60.409 114.01h13.945c.881 0 1.586.494 2.753 1.31 2.023 1.417 5.792 4.052 12.768 5.477 4.167.848 11.52 1.467 22.924-.416 1.353-4.41 4.18-29.495 3.855-34.213-1.056-.243-3.476-.437-5.316-.58-6.719-.537-11.247-1.045-12.918-3.171-1.73-2.198-.004-5.073 1.994-8.402 2.061-3.44 4.631-7.717 4.213-12.073-.247-2.581-.902-4.35-1.89-5.101-.963-.741-2.302-.505-3.365-.322-.104.018-.204.032-.297.046-.24.562-.534 1.808-.773 2.842-.827 3.53-1.958 8.355-5.312 11.57-1.536 1.474-3.62 3.63-5.853 5.945-7.016 7.256-11.43 11.709-13.574 12.375-2.355.723-9.725.633-13.154.54v24.173zm38.62 10.842c-3.598 0-6.87-.297-9.794-.895-7.636-1.56-11.763-4.446-13.979-5.995-.44-.308-.991-.698-1.174-.766l-16.899.039V86.508l1.672.057c4.353.169 11.945.204 13.756-.35 1.564-.523 8.226-7.417 12.203-11.534 2.27-2.344 4.388-4.539 5.946-6.035 2.663-2.548 3.669-6.84 4.399-9.976.683-2.917 1.177-5.026 3.082-5.24l.58-.09c1.324-.233 3.79-.659 5.874.938 1.772 1.353 2.799 3.758 3.143 7.356.515 5.412-2.478 10.402-4.661 14.042-.934 1.554-2.491 4.15-2.223 4.743.927 1.182 7.488 1.7 10.642 1.951 5.144.412 6.966.609 7.796 1.772 1.722 2.409-2.566 35.09-3.798 37.965-.34.79-.855 1.242-1.535 1.338l-.566.09c-5.272.877-10.105 1.317-14.465 1.317z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M123.668 83.837c-2.584-1.597-18.259-1.715-20.478.644-2.216 2.355-1.267 8.036 2.112 9.106 3.379 1.07 15.94.645 18.366 0 2.427-.644 2.427-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M114.223 83.826c-4.542 0-9.263.454-10.194 1.442-.83.884-1.11 2.66-.68 4.317.383 1.482 1.245 2.57 2.301 2.907 3.147.995 15.46.58 17.726-.018.186-.05.648-.616.863-2.044.372-2.52-.326-5.087-1.174-5.616-1.038-.638-4.872-.988-8.842-.988m-.69 11.558c-3.555 0-6.991-.193-8.577-.698-1.843-.587-3.243-2.234-3.837-4.521-.641-2.46-.154-5 1.235-6.475 2.738-2.918 19.021-2.624 21.92-.834 2.034 1.256 2.675 5.014 2.245 7.907-.326 2.19-1.231 3.586-2.556 3.937-1.457.387-6.031.684-10.43.684'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M123.554 93.858c-2.745-1.597-19.408-1.715-21.763.644-2.356 2.355-1.346 8.036 2.244 9.106 3.59 1.07 16.938.645 19.52 0 2.58-.644 2.58-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M113.538 93.858c-4.868 0-9.933.462-10.932 1.457-.862.862-1.163 2.53-.755 4.152.394 1.55 1.328 2.685 2.513 3.036 3.365 1.009 16.502.587 18.91-.015.27-.064.77-.712.974-2.169.326-2.284-.283-4.89-1.274-5.466-1.11-.644-5.194-.995-9.436-.995m-.755 11.551c-3.762 0-7.399-.197-9.078-.698-1.965-.587-3.493-2.334-4.088-4.675-.612-2.42-.075-4.91 1.36-6.35 2.9-2.896 20.096-2.599 23.157-.823 2.1 1.22 2.803 4.922 2.398 7.782-.326 2.276-1.285 3.73-2.699 4.08-1.543.383-6.39.684-11.05.684'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M121.42 104.252c-2.627-1.597-18.578-1.715-20.833.644-2.255 2.355-1.288 8.036 2.148 9.106 3.436 1.07 16.215.645 18.685 0 2.47-.644 2.47-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M111.818 104.247c-4.635 0-9.453.455-10.398 1.443-.852.888-1.135 2.606-.705 4.274.39 1.5 1.27 2.599 2.362 2.942 3.214.992 15.754.584 18.055-.018.197-.053.68-.63.895-2.083.365-2.502-.347-5.047-1.203-5.57-1.06-.64-4.96-.988-9.006-.988m-.705 11.555c-3.612 0-7.109-.197-8.723-.698-1.858-.58-3.318-2.287-3.905-4.568-.63-2.448-.133-4.975 1.27-6.44 2.782-2.906 19.326-2.612 22.265-.83 2.051 1.246 2.706 4.987 2.284 7.875-.326 2.213-1.242 3.623-2.592 3.977-1.475.387-6.124.684-10.599.684'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M119.18 114.379c-2.362-1.396-16.69-1.5-18.717.562-2.026 2.062-1.16 7.03 1.93 7.968 3.085.938 14.568.562 16.788 0 2.219-.562 2.219-7.217 0-8.53'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M110.99 114.493c-4.306 0-8.913.447-9.708 1.256-.716.727-.955 2.148-.605 3.537.33 1.289 1.096 2.23 2.048 2.52 2.878.866 14.11.508 16.172-.014.13-.036.54-.512.72-1.75.304-2.11-.301-4.246-1.024-4.672-1.013-.601-4.217-.877-7.603-.877m-1.07 10.13c-3.254 0-6.408-.172-7.865-.616-1.728-.522-3.078-2.076-3.608-4.152-.555-2.17-.086-4.417 1.192-5.724 2.52-2.563 17.468-2.316 20.128-.748v.004c1.88 1.11 2.495 4.428 2.13 6.984-.294 2.033-1.156 3.329-2.434 3.65-1.328.34-5.513.602-9.543.602'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M49.093 85.75v32.824s8.169.096 8.376 0c8.552-4.106 1.07-32.843 1.07-32.843l-9.446.018z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M49.232 118.433c3.204.043 7.908.075 8.194.007 8.08-3.877 1.49-30.659 1.006-32.57l-9.2.017v32.546zm5.728.325c-1.794 0-4.16-.025-5.867-.046h-.14V85.608l9.697-.018.029.104c.018.072 1.88 7.284 2.674 14.905 1.07 10.28-.219 16.37-3.827 18.098-.09.043-1.149.061-2.566.061z'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M58.59 117.53l-8.504 1.088c.54-5.87 1.872-23.543-.673-31.812l8.49-.322c1.02 3.53 3.93 15.721.688 31.045m3.303-27.165c-.103-.114.076-.154.061-.297-.043-.465-.29-.998-.598-1.543l-.028.2c.028-.228.04-.457.032-.686-.007-.018-.01-.036-.018-.054a2.363 2.363 0 01-.204-.988c-.397-1.54-.716-2.448-.766-2.592a1.492 1.492 0 00-1.453-.991l-11.741.447a1.482 1.482 0 00-1.242.77 1.531 1.531 0 00-.004 1.475c3.197 5.892 1.815 26.603 1.01 34.088a1.532 1.532 0 00.837 1.532c.254.121.54.172.827.132l11.39-1.453a1.497 1.497 0 001.264-1.16 77.87 77.87 0 00.426-2.008c.068-.01.154-.025.236-.04.086-1.052.311-2.09.523-3.164l-.2.032c1.538-10.076.658-18.441-.352-23.7'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M49.6 86.94c2.473 8.34 1.141 25.984.64 31.518l8.233-1.052c3.175-15.12.33-27.251-.676-30.78l-8.198.314zm.332 31.84l.015-.175c.486-5.348 1.89-23.45-.666-31.754l-.054-.176 8.78-.336.03.104c.984 3.41 3.948 15.739.69 31.117l-.021.096-.097.015-8.677 1.11zm9.042-35.226h-.05L47.184 84a1.34 1.34 0 00-1.121.695c-.236.422-.24.923-.007 1.346 3.214 5.92 1.829 26.667 1.027 34.163a1.376 1.376 0 00.759 1.392c.229.111.48.158.748.122l11.39-1.453a1.365 1.365 0 001.146-1.053c.136-.605.275-1.26.425-2.004l.018-.093.226-.04c.082-.909.258-1.793.444-2.73l.028-.144v.004l-.193.032.029-.194c1.535-10.03.666-18.377-.344-23.617-.09-.136-.003-.255.029-.297.021-.033.028-.043.028-.054-.039-.426-.279-.934-.508-1.353l-.118-.015a4.41 4.41 0 00.029-.665c-.008-.004-.011-.015-.011-.029a2.462 2.462 0 01-.208-.999 36.047 36.047 0 00-.759-2.563 1.35 1.35 0 00-1.267-.898zm-10.56 38.458a1.614 1.614 0 01-1.131-.48 1.657 1.657 0 01-.48-1.356c.803-7.474 2.188-28.142-.994-34.002a1.67 1.67 0 01.007-1.614 1.613 1.613 0 011.353-.838l11.744-.447h.061c.684 0 1.289.43 1.532 1.08.083.251.394 1.164.77 2.607l.003.035c0 .33.065.645.194.928l.01.046a.073.073 0 01.011.04c.007.171.007.329-.003.472.275.487.562 1.056.605 1.568a.364.364 0 01-.08.243h.004l.011.043c1.006 5.23 1.883 13.549.38 23.557l.014-.003.2-.03-.11.538c-.194.966-.376 1.875-.452 2.806l-.01.108-.247.046a81.7 81.7 0 01-.408 1.915 1.642 1.642 0 01-1.382 1.27l-11.39 1.454a2.165 2.165 0 01-.211.014z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M99.497 75.839c-2.044 2.648-2.144 5.355-3.154 8.433-1.324 4.048-4.771 6.68-8.054 9.128-1.503 1.12-.036 3.676 1.486 2.545 2.874-2.14 5.784-4.32 7.764-7.367 2.287-3.519 1.908-7.89 4.503-11.254 1.156-1.5-1.403-2.967-2.545-1.485'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M199.209 88.177v27.444h-15.56c-1.268 0-4.855 4.643-15.2 6.755-10.344 2.112-22.801-.315-24.28-.526-1.474-.212-5.3-35.291-4.245-36.77 1.053-1.474 16.069-.705 18.392-3.658 2.323-2.956-7.388-10.767-6.544-19.634.845-8.866 5.91-6.965 7.811-6.754 1.9.211 1.478 9.922 6.543 14.776 5.065 4.854 16.044 17.1 18.79 17.944 2.745.845 14.293.423 14.293.423'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M145.204 120.381c11.4 1.88 18.753 1.264 22.923.416 6.973-1.425 10.743-4.06 12.765-5.477 1.17-.816 1.88-1.31 2.76-1.31h13.946V89.837c-3.422.093-10.803.183-13.159-.54-2.144-.666-6.557-5.12-13.573-12.375-2.234-2.316-4.317-4.47-5.853-5.946-3.35-3.214-4.481-8.04-5.308-11.569-.244-1.03-.534-2.28-.777-2.842-.093-.014-.193-.028-.297-.046-1.063-.183-2.398-.416-3.365.322-.988.752-1.643 2.52-1.89 5.1-.415 4.357 2.151 8.635 4.213 12.07 2.001 3.333 3.723 6.208 1.99 8.406-1.668 2.13-6.196 2.634-12.915 3.171-1.84.143-4.26.337-5.315.584-.326 4.714 2.502 29.8 3.855 34.21zm13.77 4.471c-4.36 0-9.192-.44-14.464-1.317l-.57-.09c-.676-.096-1.192-.547-1.532-1.338-1.231-2.875-5.52-35.556-3.794-37.965.83-1.163 2.653-1.36 7.793-1.772 3.153-.25 9.715-.77 10.638-1.947.272-.594-1.285-3.193-2.22-4.75-2.183-3.637-5.172-8.627-4.66-14.04.344-3.597 1.371-6.002 3.143-7.355 2.083-1.597 4.55-1.17 5.874-.938l.58.09c1.904.214 2.398 2.323 3.082 5.244.734 3.132 1.736 7.424 4.4 9.972 1.556 1.496 3.675 3.69 5.945 6.035 3.977 4.117 10.638 11.01 12.203 11.534 1.81.558 9.392.511 13.76.35l1.667-.057v30.727h-17.167c.09.029-.465.419-.91.73-2.215 1.546-6.342 4.432-13.974 5.992-2.924.598-6.196.895-9.793.895z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M134.335 83.837c2.585-1.597 18.26-1.715 20.479.644 2.215 2.355 1.267 8.036-2.112 9.106-3.38 1.07-15.94.645-18.367 0-2.427-.644-2.427-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M143.78 83.826c-3.97 0-7.804.35-8.842.988-.848.53-1.546 3.096-1.174 5.616.215 1.428.676 1.994.862 2.047 2.266.595 14.573 1.01 17.73.015 1.052-.337 1.915-1.425 2.298-2.907.433-1.657.15-3.433-.68-4.317-.93-.988-5.652-1.442-10.195-1.442m.688 11.558c-4.396 0-8.97-.297-10.428-.68-1.324-.355-2.23-1.75-2.555-3.941-.43-2.893.21-6.651 2.244-7.907 2.896-1.787 19.183-2.084 21.921.834 1.389 1.478 1.876 4.02 1.235 6.475-.594 2.287-1.994 3.937-3.834 4.52-1.589.506-5.025.699-8.583.699'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M134.45 93.858c2.745-1.597 19.408-1.715 21.763.644 2.355 2.355 1.346 8.036-2.244 9.106-3.59 1.07-16.939.645-19.52 0-2.58-.644-2.58-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M144.468 93.858c-4.246 0-8.33.35-9.44.995-.991.576-1.6 3.182-1.274 5.466.204 1.457.705 2.105.974 2.17 2.409.597 15.542 1.023 18.914.014 1.181-.355 2.12-1.486 2.513-3.036.408-1.621.107-3.29-.755-4.152-1-.995-6.068-1.457-10.932-1.457m.751 11.551c-4.66 0-9.507-.3-11.05-.684-1.414-.35-2.373-1.804-2.699-4.08-.404-2.86.297-6.562 2.399-7.782 3.064-1.783 20.26-2.073 23.16.823 1.435 1.44 1.972 3.93 1.36 6.35-.595 2.341-2.123 4.088-4.092 4.675-1.679.501-5.316.698-9.078.698'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M136.583 104.252c2.628-1.597 18.578-1.715 20.833.644 2.255 2.355 1.289 8.036-2.147 9.106-3.437 1.07-16.216.645-18.686 0-2.47-.644-2.47-8.25 0-9.75'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M137.18 105.236c-.856.523-1.568 3.064-1.203 5.57.214 1.453.694 2.03.895 2.083 2.301.602 14.837 1.017 18.055.015 1.092-.34 1.976-1.443 2.362-2.946.43-1.665.147-3.38-.701-4.267-1.772-1.854-17.136-1.836-19.409-.455m9.708 10.563c-4.47 0-9.12-.297-10.595-.68-1.35-.354-2.27-1.764-2.596-3.98-.422-2.885.237-6.626 2.288-7.875 2.939-1.783 19.48-2.076 22.268.834 1.4 1.464 1.897 3.991 1.267 6.432-.587 2.284-2.047 3.992-3.909 4.571-1.614.502-5.108.698-8.723.698'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M138.823 114.379c2.363-1.396 16.692-1.5 18.718.562 2.026 2.062 1.16 7.03-1.93 7.968-3.085.938-14.569.562-16.788 0-2.22-.562-2.22-7.217 0-8.53'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M139.406 115.37c-.72.43-1.324 2.563-1.02 4.672.179 1.238.59 1.714.72 1.75 2.061.519 13.298.891 16.172.014.952-.29 1.718-1.235 2.047-2.523.355-1.386.111-2.807-.605-3.537-1.42-1.446-15.005-1.743-17.314-.376m8.677 9.253c-4.027 0-8.215-.26-9.543-.6-1.278-.323-2.14-1.619-2.434-3.656-.365-2.552.25-5.87 2.13-6.98l.003-.003c2.653-1.557 17.608-1.815 20.124.748 1.278 1.306 1.747 3.55 1.196 5.72-.533 2.076-1.883 3.633-3.612 4.156-1.457.444-4.61.615-7.864.615'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M208.91 85.75v32.824s-8.168.096-8.376 0c-8.551-4.106-1.07-32.843-1.07-32.843l9.446.018z'
          }
        />
        <path
          fill={'#64BF83'}
          d={
            'M199.573 85.87c-.483 1.908-7.073 28.694 1.02 32.578.269.054 4.99.025 8.176-.014V85.888l-9.196-.017zm3.469 32.89c-1.421 0-2.477-.015-2.567-.061-3.608-1.73-4.893-7.822-3.819-18.102.791-7.621 2.656-14.83 2.674-14.902l.025-.104 9.693.018v33.104h-.14a517.78 517.78 0 01-5.866.046z'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M200.102 86.483l8.49.323c-2.548 8.268-1.213 25.94-.676 31.81l-8.502-1.087c-3.243-15.324-.336-27.513.688-31.046m-4.342 27.58c-.065-.01-.136-.02-.2-.031.21 1.073.436 2.115.518 3.164.083.014.172.029.24.043.129.662.269 1.328.422 2.004a1.505 1.505 0 001.264 1.16l11.394 1.457a1.48 1.48 0 001.228-.426c.322-.322.483-.78.433-1.242-.802-7.485-2.184-28.196 1.013-34.084.25-.462.247-1.02-.007-1.479a1.488 1.488 0 00-1.239-.77l-11.74-.447a1.493 1.493 0 00-1.454.992c-.05.143-.369 1.052-.77 2.591.004.33-.057.666-.204.988-.003.018-.01.036-.014.054-.007.229 0 .458.028.687l-.025-.2c-.307.547-.558 1.077-.597 1.543-.015.143.164.182.06.297-1.013 5.258-1.893 13.623-.35 23.7'
          }
        />
        <path
          fill={'#512D1D'}
          d={
            'M199.528 117.405l8.236 1.052c-.504-5.534-1.836-23.177.641-31.518l-8.2-.315c-1.006 3.533-3.852 15.654-.677 30.78zm8.544 1.374l-.172-.021-8.601-1.103-.022-.096c-3.253-15.385-.293-27.706.688-31.114l.032-.107.11.004 8.674.333-.057.175c-2.56 8.304-1.156 26.406-.666 31.754l.014.175zm-11.862-1.703l.225.039.018.093c.15.745.29 1.4.426 2.005a1.37 1.37 0 001.142 1.052l11.394 1.453a1.28 1.28 0 00.744-.121 1.41 1.41 0 00.365-.258c.297-.297.44-.713.398-1.135-.806-7.492-2.191-28.235 1.027-34.163a1.414 1.414 0 00-.004-1.346 1.36 1.36 0 00-1.127-.694l-11.741-.448h-.05c-.566 0-1.067.358-1.26.902-.086.215-.398 1.15-.763 2.56a2.613 2.613 0 01-.214 1.027c-.015.243-.004.462.025.666l-.119.014c-.225.419-.468.92-.508 1.357 0 .01.007.021.029.05.032.046.114.164.025.297-1.006 5.244-1.876 13.588-.344 23.621l.029.19-.19-.032.04.211c.182.913.354 1.775.433 2.66zm13.38 4.936c-.072 0-.143-.007-.211-.015l-11.394-1.453a1.638 1.638 0 01-1.378-1.27 63.537 63.537 0 01-.412-1.916c-.014-.003-.021-.007-.035-.007l-.208-.036-.01-.11c-.076-.906-.251-1.794-.438-2.735l-.118-.609.204.036c-1.503-10.008-.622-18.33.383-23.56l.015-.044c-.047-.068-.09-.14-.079-.243.05-.523.344-1.11.601-1.568a4.57 4.57 0 01-.007-.447.31.31 0 01.029-.1 2.29 2.29 0 00.193-.938l.004-.036c.444-1.704.773-2.595.773-2.602a1.625 1.625 0 011.59-1.085l11.74.447c.57.025 1.078.337 1.357.838.279.508.279 1.113.003 1.614-3.182 5.864-1.797 26.536-.991 34.002a1.665 1.665 0 01-.92 1.672 1.545 1.545 0 01-.691.165z'
          }
        />
        <path
          fill={'#502D1B'}
          d={
            'M155.963 77.325c2.595 3.365 2.212 7.732 4.503 11.255 1.98 3.042 4.89 5.222 7.764 7.363 1.521 1.134 2.99-1.421 1.486-2.542-3.258-2.427-6.79-5.08-8.058-9.128-.96-3.067-1.12-5.802-3.15-8.433-1.142-1.482-3.705-.014-2.545 1.485'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgSolfegeHighFi = React.memo(SvgSolfegeHighFi)
export default MemoSvgSolfegeHighFi
