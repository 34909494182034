import { LoadingOutlined, MinusOutlined } from '@ant-design/icons'
import { Modal } from '@thesisedu/web'
import { Modal as AntModal, message, Result } from 'antd'
import React from 'react'

import { useDetachSegmentMutation } from '../../queries/useDetachSegmentMutation'

export interface DetachSegmentButtonProps {
  segmentDatabaseId: string
  courseVersionId: string
}
export function DetachSegmentButton({
  segmentDatabaseId,
  courseVersionId,
}: DetachSegmentButtonProps) {
  const [detach, { loading }] = useDetachSegmentMutation({
    onCompleted: () => {
      message.success('Segment detached successfully!')
    },
  })
  return (
    <>
      <Modal footer={null} visible={loading} closable={false}>
        <Result icon={<LoadingOutlined size={64} />} title={'Detaching segment...'} />
      </Modal>
      <a
        onClick={() => {
          AntModal.confirm({
            title: 'Are you sure you want to detach?',
            content: 'This action cannot be undone. Are you sure you want to continue?',
            okText: 'Yes, detach the segment.',
            okType: 'danger',
            cancelText: 'No, go back.',
            onOk: () => {
              detach({
                variables: {
                  input: {
                    id: segmentDatabaseId,
                    updateCourseVersionId: courseVersionId,
                  },
                },
              })
            },
          })
        }}
      >
        <MinusOutlined /> Detach from Reference
      </a>
    </>
  )
}
