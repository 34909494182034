import React from 'react'

import { $isImageNode, ImageNode } from '../../../nodes/Image/ImageNode'
import { AlignmentSetting } from '../../../ui/SettingsDropdown/AlignmentSetting'
import {
  useSettingsDropdownItem,
  SettingsDropdownElementProps,
} from '../../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../../ui/SettingsDropdown/useNodeState'

function ImageAlignmentElement({ editor, node }: SettingsDropdownElementProps<ImageNode>) {
  const [alignment, setAlignment] = useNodeState(
    editor,
    node,
    node => node.getAlignment(),
    (node, value) => node.setAlignment(value),
  )
  if (alignment === undefined) return null
  return <AlignmentSetting alignment={alignment} onChange={setAlignment} />
}

export function useImageAlignmentSetting() {
  useSettingsDropdownItem({
    identifier: 'image-alignment',
    group: 'image',
    weight: -9,
    filter(editor, node) {
      return $isImageNode(node)
    },
    element: ImageAlignmentElement,
  })
}
