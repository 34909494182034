import { Format } from '@thesisedu/feature-utils'

import { MetricFormat, ReportMetricResource } from '../types'

export function getFormattedMetric(
  value: number | null | undefined,
  resource: ReportMetricResource,
  short?: boolean,
): string | null {
  if (value === null || value === undefined) return null
  for (const key of short ? ['shortFormat', 'format'] : ['format']) {
    const metricKey: MetricFormat | undefined = resource[key as 'shortFormat' | 'format']
    if (!metricKey) continue
    if (metricKey === 'percent') {
      return Format.number(value, 'percentage')
    } else if (metricKey === 'number') {
      return Format.number(value, 'number')
    } else {
      return metricKey(value)
    }
  }
  return null
}
