import { useFeatureRoot } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { LockedBook } from '@thesisedu/react/icons'
import { BodyLarge, AntIconWrapper } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export function SegmentNotInLicensePage() {
  const viewer = useViewerContext(false)
  const name = useFeatureRoot().appOptions.name
  return (
    <Result
      title={'This content is not in your license.'}
      icon={<AntIconWrapper children={<LockedBook />} />}
    >
      <BodyLarge>
        This content is not currently available in your license.
        {viewer?.group === 'TEACHER' ? (
          <>
            {' '}
            To add this content to your license, reach out to{' '}
            <Link to={'/teacher/resources'}>{name} Support.</Link>
          </>
        ) : null}
      </BodyLarge>
    </Result>
  )
}
