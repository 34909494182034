export * from './types'
export * from './useForm'
export * from './Form'
export * from './FormSubmit'
export * from './FormTextField'
export * from './FormFieldConnector'
export * from './FormModal'

import { useFieldArray } from 'react-hook-form'

import { Form, useFormSubmit, useFormContext } from './Form'
import { FormCheckbox, FormCheckboxGroup } from './FormCheckbox'
import {
  FormDateField,
  FormDatePickerField,
  FormDateRangePickerField,
  FormTimeField,
} from './FormDateField'
import { FormField } from './FormField'
import { FormFileField } from './FormFileField'
import { FormModal } from './FormModal'
import { FormNumberField } from './FormNumberField'
import { FormSelect } from './FormSelect'
import { FormSubmit, StandaloneFormSubmit } from './FormSubmit'
import { FormSwitch } from './FormSwitch'
import { FormTextField, FormTextAreaField, FormArrayTextAreaField } from './FormTextField'
import { useForm, useControlledForm } from './useForm'
import { withSubComponents } from '../../utils/withSubComponents'

const Convenience = withSubComponents(Form, {
  useFormSubmit,
  useFormContext,
  useForm,
  useControlledForm,
  useFieldArray,
  Submit: FormSubmit,
  StandaloneSubmit: StandaloneFormSubmit,
  TextField: FormTextField,
  TextAreaField: FormTextAreaField,
  ArrayTextAreaField: FormArrayTextAreaField,
  NumberField: FormNumberField,
  FileField: FormFileField,
  Checkbox: FormCheckbox,
  CheckboxGroup: FormCheckboxGroup,
  Switch: FormSwitch,
  Select: FormSelect,
  Field: FormField,
  Modal: FormModal,
  DateField: FormDateField,
  DatePickerField: FormDatePickerField,
  DateRangePickerField: FormDateRangePickerField,
  TimeField: FormTimeField,
})

export default Convenience
