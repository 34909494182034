import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { createEditor } from 'lexical'
import React from 'react'

import { EditorProps } from './Editor'
import { NestedEditor, NestedEditorProps } from './NestedEditor'
import { EditorContext, useEditorContext } from './context/EditorContext'
import { CallbacksPlugin } from './plugins/CallbacksPlugin'
import { DefaultValuePlugin } from './plugins/DefaultValuePlugin'
import { useOnChange } from './utils/useOnChange'

export interface ControlledNestedEditorProps extends Omit<NestedEditorProps, 'editor'> {
  /**
   * The value of the editor. It is named value here instead of defaultValue, since
   * the component is meant to be used in a "more controlled" context (ideally on
   * things like assignment questions that are inside a form). Performance suffers
   * a bit, but we introduce a debounce before calling `onChange`, just like the old
   * rich text editor did, to help with this.
   */
  value?: EditorProps['defaultValue']
  onChange?: EditorProps['onChange']
  onChangeDebounce?: EditorProps['onChangeDebounce']
  onFocus?: EditorProps['onFocus']
  onBlur?: EditorProps['onBlur']
  disabled?: boolean
}
export function ControlledNestedEditor({
  value,
  disabled,
  onChange: _onChange,
  onChangeDebounce,
  onFocus,
  onBlur,
  ...rest
}: ControlledNestedEditorProps) {
  const onChange = useOnChange({ onChange: _onChange, onChangeDebounce, defaultValue: value })
  const editorRef = React.useRef(
    createEditor({
      editable: !disabled,
    }),
  )

  /**
   * Override the editor context to reset the read only state of the editor, at least
   * for internal components. We'll need to use a plugin to forcefully set the
   * editable state for the nested editor.
   */
  const contextValue = useEditorContext(true)
  return (
    <EditorContext.Provider value={{ ...contextValue, readOnly: !!disabled }}>
      <NestedEditor editor={editorRef.current} {...rest}>
        <>
          <DefaultValuePlugin defaultValue={value} />
          <OnChangePlugin
            onChange={editorState => {
              editorState.read(() => {
                if (onChange) {
                  onChange(editorState.toJSON())
                }
              })
            }}
          />
          {disabled ? null : <CallbacksPlugin onFocus={onFocus} onBlur={onBlur} />}
        </>
      </NestedEditor>
    </EditorContext.Provider>
  )
}
