/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String']['input'];
};

export enum AccountRegion {
  UsEast_1 = 'US_EAST_1',
  UsWest_1 = 'US_WEST_1'
}

export type AnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AnsweredQuestionContentInput = {
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type AnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum AnsweredQuestionCreator {
  Me = 'ME'
}

export type AnsweredQuestionInput = {
  content?: InputMaybe<AnsweredQuestionContentInput>;
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postName?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AnsweredQuestionStatus>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
  vodId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible statuses for an answered question */
export enum AnsweredQuestionStatus {
  /** The question has been accepted, but we haven't started responding yet */
  Accepted = 'ACCEPTED',
  /** The question has just been submitted */
  New = 'NEW',
  /** We have completed our response and published it */
  Published = 'PUBLISHED',
  /** The question has been denied, for whatever reason */
  Rejected = 'REJECTED',
  /** We are preparing our response */
  Responding = 'RESPONDING'
}

/** Filters available when querying for answered questions. */
export type AnsweredQuestionsFilters = {
  /** Filter based on questions created by specific contributors. */
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter based on who created the question. */
  creator?: InputMaybe<AnsweredQuestionCreator>;
  /** Filter based on the status of the question. */
  status?: InputMaybe<Array<AnsweredQuestionStatus>>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
};

export type ApproveCommentInput = {
  id: Scalars['ID']['input'];
};

export type ApproveTrialRequestInput = {
  approval: TrialRequestApproval;
  id: Scalars['ID']['input'];
  noEmail?: Scalars['Boolean']['input'];
};

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID']['input'];
};

/**
 * Specify either assignedStudents or assignedClasses, depending on the assignment mode for the
 * assignment. You may not use assignedStudents if the mode is ALL_STUDENTS.
 */
export type AssignInput = {
  assignedClasses?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedStudents?: InputMaybe<Array<ClassStudentInput>>;
};

export type AssignLessonInput = {
  assignments: Array<LessonAssociationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssignmentAutoGradeMode {
  AllQuestions = 'ALL_QUESTIONS',
  None = 'NONE',
  SomeQuestions = 'SOME_QUESTIONS'
}

export type AssignmentCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gradeWeight?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentClassConfigurationInput = {
  gradingMode?: InputMaybe<ClassGradingMode>;
};

export enum AssignmentExcludedFilter {
  Both = 'BOTH',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum AssignmentFeature {
  Attempts = 'ATTEMPTS',
  Groups = 'GROUPS'
}

export type AssignmentFiltersInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dueDate?: InputMaybe<DueDateFilterInput>;
  /** What exclusion status of assignments should be included? */
  excluded?: InputMaybe<AssignmentExcludedFilter>;
  /**
   * If this is true, only generated assignments are included. This is the same as passing
   * all available generated providers to the generatedProviders option.
   */
  generated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The identifiers for generated-assignment providers to filter on. */
  generatedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  missing?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<TagsFilter>;
  ungraded?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC'
}

export type AssignmentInput = {
  assign?: InputMaybe<AssignInput>;
  assignmentCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignmentMode?: InputMaybe<AssignmentStudentAssignmentMode>;
  assignmentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueAtOffset?: InputMaybe<Scalars['Int']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  gradingMode?: InputMaybe<AssignmentGradingMode>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openAt?: InputMaybe<Scalars['DateTime']['input']>;
  openAtOffset?: InputMaybe<Scalars['Int']['input']>;
  revealAnswers?: InputMaybe<RevealAnswers>;
  revealAttempts?: InputMaybe<Scalars['Int']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
  submissionType?: InputMaybe<SubmissionType>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  totalPoints?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentRubricCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  presets?: InputMaybe<Array<AssignmentRubricPresetInput>>;
  totalPoints: Scalars['Float']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentRubricInput = {
  categories: Array<AssignmentRubricCategoryInput>;
};

export type AssignmentRubricPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS'
}

export type AssignmentTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  configurationChanges?: InputMaybe<Scalars['JSON']['input']>;
  defaults?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AssociatedClassAssignmentInput = {
  /** The class ID. */
  id: Scalars['ID']['input'];
  overrides?: InputMaybe<AssignmentInput>;
  segmentId: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type AttachClassSyncInput = {
  /** The ID of the class to sync. */
  id: Scalars['ID']['input'];
  /** The name of the class on the provider. */
  label: Scalars['String']['input'];
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String']['input'];
};

export type AttachClassSyncStudentAccountInput = {
  /** The current class ID to attach the student to. */
  id: Scalars['ID']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  userInfo: BasicUserInput;
};

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String']['input'];
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String']['input'];
};

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The original name of the file. */
  name: Scalars['String']['input'];
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput;
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float']['input'];
};

export type AutoGradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export enum AutoGradingStatus {
  /** Automatic grading failed for this question. */
  Failed = 'FAILED',
  /** The question has been graded already. */
  Graded = 'GRADED',
  /** The question is currently being graded. */
  Grading = 'GRADING'
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String']['input'];
};

export type AwardBadgeInput = {
  /** The ID of the badge to award. */
  id: Scalars['ID']['input'];
  /** The IDs of the users to award the badge to. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export enum BadgeEventType {
  Badge = 'badge',
  Interaction = 'interaction',
  Segment = 'segment'
}

export type BadgeInput = {
  awardedText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<BadgeEventType>;
  eventConfig?: InputMaybe<Scalars['JSON']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BasicUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BuiltSegmentInput = {
  /**
   * If this is false, the studentContent and teacherContent are not included for this
   * segment. See Segment.hydrated for how to fetch the hydrated segment if this is
   * false.
   */
  hydrated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID']['input'];
};

export type CancelLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
};

/** Reasons a trial request might not be able to be approved. */
export enum CantApproveTrialRequestReason {
  /** This user already belongs to a non-trial license. */
  BelongsToNonTrialLicense = 'BELONGS_TO_NON_TRIAL_LICENSE',
  /** A deleted user already exists with this email address. */
  DeletedUserExists = 'DELETED_USER_EXISTS'
}

export type CanvasAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type CanvasHealthInput = {
  consumerKey?: InputMaybe<Scalars['String']['input']>;
  redirectUri: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CanvasIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT'
}

/** This is deprecated; use the class configuration instead. */
export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS'
}

export type ClassGroupPresetInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  submissionType?: InputMaybe<GroupSubmissionType>;
};

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID']['input'];
};

export type ClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segmentMetadata?: InputMaybe<Scalars['JSON']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ClassReportInput = {
  classIds: Array<Scalars['ID']['input']>;
  igNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ClassRole {
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type ClassStudentInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type ClassSyncHistoryFilters = {
  /**
   * If you want to filter the results to match only specific class
   * sync IDs.
   */
  classSyncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<ClassSyncHistoryStatus>>;
  /**
   * If you want to filter the results to match only specific assignment
   * or material sync IDs.
   */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Array<ClassSyncHistoryType>>;
};

export enum ClassSyncHistoryStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export enum ClassSyncHistoryType {
  /** The sync created or updated an assignment without touching the grades. */
  Basic = 'BASIC',
  /** The sync was in response to a grade change and only updated the grade. */
  OnlyGrades = 'ONLY_GRADES',
  /** This was an assignment submit event. */
  Submit = 'SUBMIT',
  /** The sync either did not exist or touched both the basic metadata and grades. */
  WithGrades = 'WITH_GRADES'
}

export type ClassSyncProviderConfigurationInput = {
  enabledFeatures?: InputMaybe<Array<ClassSyncProviderFeature>>;
  providerIdentifier: Scalars['String']['input'];
};

export enum ClassSyncProviderFeature {
  GradeMaterialSync = 'GRADE_MATERIAL_SYNC',
  Rostering = 'ROSTERING'
}

export type ClassSyncRosterActionInput = {
  mode: ClassSyncRosterActionMode;
  providerStudentId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Types of actions to take when rostering students. */
export enum ClassSyncRosterActionMode {
  /** Import the student from the provider into Feature. */
  Import = 'IMPORT',
  /** Match a provider student with an existing Feature student. */
  Match = 'MATCH',
  /** Remove an existing Feature student from the class. */
  Remove = 'REMOVE'
}

export type ClassTemplatesFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sharingTypes?: InputMaybe<Array<SharingType>>;
};

export type ClassesReportDimensionInput = {
  classIds: Array<Scalars['ID']['input']>;
};

export type CommentAddedInput = {
  /** The ID of the entity to listen to for new comments. */
  id: Scalars['ID']['input'];
};

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED'
}

export type CommentInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentOnNodeInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** The ID of the node to comment on. */
  id: Scalars['ID']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentsFilterInput = {
  /** If this is true, only shows comments for the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is passed, filters comments for those made by the specified user ID. */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ConfirmationInput = {
  clearGradesWhenChangingAssignmentRubric?: InputMaybe<Scalars['Boolean']['input']>;
  overwriteClassTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentVisitedReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID']['input'];
};

export type CopySegmentAsOwnInput = {
  /** The ID of the class the teacher is working in. */
  id: Scalars['ID']['input'];
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
};

export type CopySegmentSettingsInput = {
  /** The IDs of the classes to copy this information to. */
  destinationIds: Array<Scalars['ID']['input']>;
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CopyTagsToChildrenInput = {
  /** The ID of the course version you are currently viewing. */
  courseVersionId: Scalars['ID']['input'];
  /** The ID of the segment to copy the tags to its children. */
  id: Scalars['ID']['input'];
};

export type CourseInput = {
  classConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isByoc?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CourseVersionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CourseViewWeightInput = {
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
  /** The new weight for that segment. */
  weight: Scalars['Float']['input'];
};

export type CreateAnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateAnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: RegisterInput;
};

export type CreateAssignmentCategoryInput = {
  category: AssignmentCategoryInput;
  /** The class ID */
  id: Scalars['ID']['input'];
};

export type CreateAssignmentInput = {
  assignment: AssignmentInput;
};

export type CreateCheckoutSessionInput = {
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String']['input'];
  /** The class to create a checkout session for. */
  id: Scalars['ID']['input'];
  /** The URL to redirect to on success. */
  successUrl: Scalars['String']['input'];
};

export type CreateClassFromTemplateInput = {
  /** The template to create a class from. */
  id: Scalars['ID']['input'];
  /** The name of the class. */
  name: Scalars['String']['input'];
};

export type CreateClassGroupPresetInput = {
  /** The ID of the class to associate the preset with. */
  id: Scalars['ID']['input'];
  preset: ClassGroupPresetInput;
};

export type CreateClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateClassWithPlacedContentInput = {
  class: ClassInput;
  segmentId: Scalars['ID']['input'];
};

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID']['input'];
  /** The name of the new version to create. */
  name: Scalars['String']['input'];
};

export type CreateDirectMessageThreadInput = {
  /** The block-based message to send. */
  blockMessage: Scalars['JSON']['input'];
  /** An array of user IDs to send the message to. */
  recipientIds: Array<Scalars['ID']['input']>;
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the thread. */
  subject: Scalars['String']['input'];
};

export type CreateDiscussionThreadInput = {
  /** The ID of the discussion to create the thread in. */
  id: Scalars['ID']['input'];
  thread: DiscussionThreadInput;
};

export type CreateSavedReportInput = {
  name: Scalars['String']['input'];
  report: Scalars['JSON']['input'];
};

export type CreateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type CreateStudentInput = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  /** This is only required if the provider requires it. */
  signedUploadInput?: InputMaybe<SignedUploadInput>;
  sizeInBytes: Scalars['Int']['input'];
};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteAssignmentCategoryInput = {
  /** The ID of the assignment category to delete. */
  id: Scalars['ID']['input'];
  /**
   * The ID of a category inside the same class to move all assignments to that
   * belong to the category being deleted.
   */
  replacementCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID']['input'];
};

export type DetailedLogsForUserInput = {
  /** Filter by the name of the function handler, rather than displaying all handlers. */
  handler?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to get logs for. */
  id: Scalars['ID']['input'];
  /** The JWT of the administration user. */
  jwt: Scalars['String']['input'];
  /** Any additional tags to filter by. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DirectMessageThreadsFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Other features are supposed to add to this later... */
  noop?: InputMaybe<Scalars['String']['input']>;
};

export type DiscussionThreadInput = {
  content: Scalars['JSON']['input'];
};

export type DiscussionThreadsFilterInput = {
  /**
   * If selected, only returns discussion threads either created by the passed user, or with
   * comments from the passed user.
   */
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If this is true, only shows discussion threads created by the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistrictConfigurationInput = {
  /** Provide default values for all sites inside this district. */
  defaultSiteConfiguration?: InputMaybe<SiteConfigurationInput>;
};

export type DistrictInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<DistrictConfigurationInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DueDateFilterInput = {
  /** Defaults to the current date if not specified. */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  type: DueDateFilterType;
};

export enum DueDateFilterType {
  After = 'AFTER',
  Before = 'BEFORE'
}

export enum DuplicateUserPendingStudentClassResolution {
  /**
   * These are not the same students, so the student should be accepted into
   * the class but should not be merged into the matchedStudentId.
   */
  Accept = 'ACCEPT',
  /**
   * Ignore this request; the student should not be added to the class and the
   * students should not be merged.
   */
  Decline = 'DECLINE',
  /**
   * These are actually the same students, and the current student should be
   * merged into the matchedStudentId, and then the current student should be
   * deleted.
   */
  Merge = 'MERGE'
}

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FinishUploadingVideoOnDemandInput = {
  /** The ID of the video on demand you just finished uploading. */
  vodId: Scalars['ID']['input'];
};

export type ForceResyncAssignmentSyncInput = {
  /** The ID of the assignment sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForceResyncMaterialSyncInput = {
  /** The ID of the material sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForfeitAssignmentInput = {
  classId: Scalars['ID']['input'];
  forfeit: Scalars['Boolean']['input'];
  /** The ID of the assignment to forfeit. */
  id: Scalars['ID']['input'];
  /** If modifying as a teacher, the ID of the student to modify. */
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type FreeUsernameInput = {
  /** The username or email address you would like to free. */
  username: Scalars['String']['input'];
};

export type GenerateCanvasCredentialsTokenInput = {
  districtId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GenerateGroupMode {
  MaximumCollaboration = 'MAXIMUM_COLLABORATION',
  Random = 'RANDOM'
}

export enum GenerateLeaderMode {
  LeadershipBoost = 'LEADERSHIP_BOOST',
  Random = 'RANDOM'
}

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID']['input'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type GenerateStudentsInput = {
  count: Scalars['Int']['input'];
  /** The ID of the class to generate students for. */
  id: Scalars['ID']['input'];
};

export type GenerateVodCaptionsInput = {
  /** The ID of the video to generate captions for. */
  id: Scalars['ID']['input'];
};

export type GeneratedGroupsConfigurationInput = {
  /** The logic to use when grouping students. */
  groupMode: GenerateGroupMode;
  /** The logic to use when choosing leaders. */
  leaderMode: GenerateLeaderMode;
  /** The number of groups to generate. */
  numGroups: Scalars['Int']['input'];
  /** The students to include in the groups. */
  students: Array<ClassStudentInput>;
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type GradeAssignmentSubmissionInput = {
  comments: Scalars['JSON']['input'];
  grades: Scalars['JSON']['input'];
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export type GradeReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupSubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL'
}

export type ImportCourseViewWeightsInput = {
  /** The ID of the course view to update weights for. */
  courseViewId: Scalars['ID']['input'];
  /**
   * If this is true, the update will be done in the foreground
   * instead of via a background job.
   */
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new weights to import. */
  weights: Array<CourseViewWeightInput>;
};

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID']['input'];
  /** The ID of the source to use. */
  sourceId: Scalars['String']['input'];
};

export type ImportSegmentsInput = {
  /** The contents of the CSV to use for imports. */
  csvContent: Scalars['String']['input'];
  /**
   * If this is true, new tags will not be created. So if you specify
   * a tag that has not yet been registered in the system, it won't
   * be added to that content.
   */
  dontCreateTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** How should we process tags when importing? */
  tagsMode: ImportSegmentsTagsMode;
  /**
   * If this is true, the segment ID column is required, and the label
   * of the segment will be updated when imported.
   */
  updateLabel?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If this is true, the segment ID column is required, and the name
   * of the segment will be updated when imported.
   */
  updateName?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ImportSegmentsTagsMode {
  /** Tags will be appended to the existing tags. */
  Append = 'APPEND',
  /** No tags will be imported. */
  Ignore = 'IGNORE',
  /**
   * The existing tags (in the specified types) will be replaced completely.
   * If you pass a "COURSE" tag of "Course A" and the content already has a
   * "COURSE" tag of "Course B", the "Course B" tag will be removed.
   * If the content already had an "IMPLEMENTATION_GUIDE" tag but you didn't
   * specify a new one in the sheet, the existing one will be kept.
   */
  Replace = 'REPLACE'
}

export type ImportStudentsInput = {
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String']['input'];
  /** The ID of the class to import the students to. */
  id: Scalars['ID']['input'];
};

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String']['input'];
};

export type InteractionInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
  reference?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export enum InviteResult {
  AddedToClass = 'ADDED_TO_CLASS',
  InvitedToPlatform = 'INVITED_TO_PLATFORM'
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID']['input'];
  /** The student ID to import. */
  studentId: Scalars['String']['input'];
  /** The teacher ID to import. */
  teacherId: Scalars['String']['input'];
};

export type InviteStudentInput = {
  /** The email address of the student to invite. */
  email: Scalars['String']['input'];
  /** The ID of the class to invite the student to. */
  id: Scalars['ID']['input'];
};

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID']['input'];
  /** Any default permissions to apply to the teacher. */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID']['input'];
};

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String']['input'];
};

export type LessonAssociationInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LessonInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LessonsAssignedStudentPairInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LicenseInput = {
  courses?: InputMaybe<Scalars['JSON']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  monthlyPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontMonths?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<LicenseType>;
};

export type LicenseSubscriptionInput = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  nextValidityCheck?: InputMaybe<Scalars['DateTime']['input']>;
  size?: InputMaybe<LicenseSubscriptionSize>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LicenseSubscriptionType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum LicenseSubscriptionSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum LicenseSubscriptionType {
  Individual = 'INDIVIDUAL',
  StudentPay = 'STUDENT_PAY'
}

export enum LicenseType {
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
  Trial = 'TRIAL'
}

export type LikeNodeInput = {
  /** The ID of the node to like. */
  id: Scalars['ID']['input'];
};

export type ListSyncProviderClassesInput = {
  /** If you would like to have a class automatically suggested, pass in the class ID. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  provider: Scalars['String']['input'];
};

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput;
  /** An optional Canvas integration to use, for anonymous access. */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginWithCleverInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithGoogleInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithMsalInput = {
  authCode?: InputMaybe<MsalAuthCodeInput>;
  tokens?: InputMaybe<MsalTokensInput>;
};

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput;
};

export type MsalAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type MsalIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export type MsalTokensInput = {
  accessToken: Scalars['String']['input'];
  expiresAt: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type MediaBookmarkInput = {
  endSeconds: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startSeconds: Scalars['Float']['input'];
};

export type MediaInput = {
  metadata?: InputMaybe<MediaMetadataInput>;
  status?: InputMaybe<MediaStatus>;
};

export type MediaMetadataInput = {
  bookmarks?: InputMaybe<Array<MediaBookmarkInput>>;
};

export enum MediaStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED'
}

export enum MediaType {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type MediaUploadUrlInput = {
  /** An optional class to associate the media with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  extension: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  region?: InputMaybe<AccountRegion>;
  requestMode?: InputMaybe<UploadRequestMode>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type MoveStudentClassInput = {
  /**
   * Whether or not to force the move. This typically means some warnings were given, and
   * you acknowledge them and would like to continue anyway.
   */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the source class. */
  fromClassId: Scalars['ID']['input'];
  /** The ID of the student. */
  id: Scalars['ID']['input'];
  /** The ID of the destination class. */
  toClassId: Scalars['ID']['input'];
};

export type MoveTeacherInput = {
  id: Scalars['ID']['input'];
  toSite: Scalars['ID']['input'];
};

export type NumAttemptsMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PendingStudentClassReason {
  DuplicateUser = 'DUPLICATE_USER',
  /** Don't use this. */
  Placeholder = 'PLACEHOLDER'
}

export enum PendingStudentClassState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type PendingStudentClassesFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reasons?: InputMaybe<Array<PendingStudentClassReason>>;
  states?: InputMaybe<Array<PendingStudentClassState>>;
  studentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum Permission {
  AcceptInvite = 'ACCEPT_INVITE',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  AssignLesson = 'ASSIGN_LESSON',
  AssignOwnLesson = 'ASSIGN_OWN_LESSON',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  CancelLicenseSubscription = 'CANCEL_LICENSE_SUBSCRIPTION',
  CancelOwnLicenseSubscription = 'CANCEL_OWN_LICENSE_SUBSCRIPTION',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnDiscussionThread = 'COMMENT_ON_OWN_DISCUSSION_THREAD',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  CopyCourseContentFrom = 'COPY_COURSE_CONTENT_FROM',
  CopyCourseContentTo = 'COPY_COURSE_CONTENT_TO',
  CopyOwnCourseContentFrom = 'COPY_OWN_COURSE_CONTENT_FROM',
  CopyOwnCourseContentTo = 'COPY_OWN_COURSE_CONTENT_TO',
  CopyOwnSegmentAsOwn = 'COPY_OWN_SEGMENT_AS_OWN',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  CopySegmentAsOwn = 'COPY_SEGMENT_AS_OWN',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CreateAllRubricTemplate = 'CREATE_ALL_RUBRIC_TEMPLATE',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateAssignmentTemplate = 'CREATE_ASSIGNMENT_TEMPLATE',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateClass = 'CREATE_CLASS',
  CreateClassFromOwnTemplate = 'CREATE_CLASS_FROM_OWN_TEMPLATE',
  CreateClassFromSharedTemplate = 'CREATE_CLASS_FROM_SHARED_TEMPLATE',
  CreateClassFromTemplate = 'CREATE_CLASS_FROM_TEMPLATE',
  CreateCourse = 'CREATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  CreateDiscussionThread = 'CREATE_DISCUSSION_THREAD',
  CreateDistrict = 'CREATE_DISTRICT',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateLesson = 'CREATE_LESSON',
  CreateLicense = 'CREATE_LICENSE',
  CreateLicenseSubscription = 'CREATE_LICENSE_SUBSCRIPTION',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateOwnAssignment = 'CREATE_OWN_ASSIGNMENT',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  CreateOwnDiscussionThread = 'CREATE_OWN_DISCUSSION_THREAD',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  CreatePractice = 'CREATE_PRACTICE',
  CreateSavedReport = 'CREATE_SAVED_REPORT',
  CreateSite = 'CREATE_SITE',
  CreateStudent = 'CREATE_STUDENT',
  CreateTeacher = 'CREATE_TEACHER',
  CreateUser = 'CREATE_USER',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  DeleteAnsweredQuestion = 'DELETE_ANSWERED_QUESTION',
  DeleteAnsweredQuestionContributor = 'DELETE_ANSWERED_QUESTION_CONTRIBUTOR',
  DeleteClass = 'DELETE_CLASS',
  DeleteClassGroupPreset = 'DELETE_CLASS_GROUP_PRESET',
  DeleteClassTemplate = 'DELETE_CLASS_TEMPLATE',
  DeleteComment = 'DELETE_COMMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteDiscussion = 'DELETE_DISCUSSION',
  DeleteDiscussionThread = 'DELETE_DISCUSSION_THREAD',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteLesson = 'DELETE_LESSON',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteLicenseSubscription = 'DELETE_LICENSE_SUBSCRIPTION',
  DeleteMedia = 'DELETE_MEDIA',
  DeleteOwnAnnouncement = 'DELETE_OWN_ANNOUNCEMENT',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  DeleteOwnClassGroupPreset = 'DELETE_OWN_CLASS_GROUP_PRESET',
  DeleteOwnClassSync = 'DELETE_OWN_CLASS_SYNC',
  DeleteOwnClassTemplate = 'DELETE_OWN_CLASS_TEMPLATE',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  DeleteOwnDirectMessageThread = 'DELETE_OWN_DIRECT_MESSAGE_THREAD',
  DeleteOwnDiscussion = 'DELETE_OWN_DISCUSSION',
  DeleteOwnDiscussionThread = 'DELETE_OWN_DISCUSSION_THREAD',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  DeleteOwnLesson = 'DELETE_OWN_LESSON',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  DeleteOwnLicenseSubscription = 'DELETE_OWN_LICENSE_SUBSCRIPTION',
  DeleteOwnMedia = 'DELETE_OWN_MEDIA',
  DeleteOwnPractice = 'DELETE_OWN_PRACTICE',
  DeleteOwnRubricTemplate = 'DELETE_OWN_RUBRIC_TEMPLATE',
  DeleteOwnSavedReport = 'DELETE_OWN_SAVED_REPORT',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  DeletePractice = 'DELETE_PRACTICE',
  DeleteSavedReport = 'DELETE_SAVED_REPORT',
  DeleteSegment = 'DELETE_SEGMENT',
  DeleteSite = 'DELETE_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteStandardSet = 'DELETE_STANDARD_SET',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteTag = 'DELETE_TAG',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  DeleteTrialRequestCampaign = 'DELETE_TRIAL_REQUEST_CAMPAIGN',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteWidgetTemplate = 'DELETE_WIDGET_TEMPLATE',
  DownloadLesson = 'DOWNLOAD_LESSON',
  DownloadOwnLesson = 'DOWNLOAD_OWN_LESSON',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  ForceResyncAssignmentSync = 'FORCE_RESYNC_ASSIGNMENT_SYNC',
  ForceResyncMaterialSync = 'FORCE_RESYNC_MATERIAL_SYNC',
  ForceResyncOwnAssignmentSync = 'FORCE_RESYNC_OWN_ASSIGNMENT_SYNC',
  ForceResyncOwnMaterialSync = 'FORCE_RESYNC_OWN_MATERIAL_SYNC',
  FreeUsername = 'FREE_USERNAME',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  GeneratePdf = 'GENERATE_PDF',
  GetPageMetadata = 'GET_PAGE_METADATA',
  GradeAssignmentSubmission = 'GRADE_ASSIGNMENT_SUBMISSION',
  GradeOwnAssignmentSubmission = 'GRADE_OWN_ASSIGNMENT_SUBMISSION',
  Impersonate = 'IMPERSONATE',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  InviteStudent = 'INVITE_STUDENT',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  JoinClass = 'JOIN_CLASS',
  ListAnsweredQuestions = 'LIST_ANSWERED_QUESTIONS',
  ListBadges = 'LIST_BADGES',
  ListBadgeUsers = 'LIST_BADGE_USERS',
  ListClass = 'LIST_CLASS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ListClassLicenseSubscriptions = 'LIST_CLASS_LICENSE_SUBSCRIPTIONS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  ListClassSyncStudents = 'LIST_CLASS_SYNC_STUDENTS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListClassTemplates = 'LIST_CLASS_TEMPLATES',
  ListComments = 'LIST_COMMENTS',
  ListCourse = 'LIST_COURSE',
  ListCourseViews = 'LIST_COURSE_VIEWS',
  ListDistrict = 'LIST_DISTRICT',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  ListInteractions = 'LIST_INTERACTIONS',
  ListLesson = 'LIST_LESSON',
  ListLessonPractices = 'LIST_LESSON_PRACTICES',
  ListLicenses = 'LIST_LICENSES',
  ListLicenseSubscriptions = 'LIST_LICENSE_SUBSCRIPTIONS',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListOwnClassLicenseSubscriptions = 'LIST_OWN_CLASS_LICENSE_SUBSCRIPTIONS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListOwnClassSyncStudents = 'LIST_OWN_CLASS_SYNC_STUDENTS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ListOwnLessonPractices = 'LIST_OWN_LESSON_PRACTICES',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  ListOwnStudentLessons = 'LIST_OWN_STUDENT_LESSONS',
  ListOwnStudentPractices = 'LIST_OWN_STUDENT_PRACTICES',
  ListOwnTeacherLessons = 'LIST_OWN_TEACHER_LESSONS',
  ListOwnTeacherPractices = 'LIST_OWN_TEACHER_PRACTICES',
  ListOwnUserBadges = 'LIST_OWN_USER_BADGES',
  ListOwnUserLicenseSubscriptions = 'LIST_OWN_USER_LICENSE_SUBSCRIPTIONS',
  ListPractice = 'LIST_PRACTICE',
  ListRawClassSyncProviders = 'LIST_RAW_CLASS_SYNC_PROVIDERS',
  ListSegments = 'LIST_SEGMENTS',
  ListSharedLessonPractices = 'LIST_SHARED_LESSON_PRACTICES',
  ListSite = 'LIST_SITE',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListStudent = 'LIST_STUDENT',
  ListStudentLessons = 'LIST_STUDENT_LESSONS',
  ListStudentPractices = 'LIST_STUDENT_PRACTICES',
  ListTags = 'LIST_TAGS',
  ListTeacher = 'LIST_TEACHER',
  ListTeacherLessons = 'LIST_TEACHER_LESSONS',
  ListTeacherPractices = 'LIST_TEACHER_PRACTICES',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ListUser = 'LIST_USER',
  ListUserBadges = 'LIST_USER_BADGES',
  ListUserLicenseSubscriptions = 'LIST_USER_LICENSE_SUBSCRIPTIONS',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  Login = 'LOGIN',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ManageAnsweredQuestions = 'MANAGE_ANSWERED_QUESTIONS',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageAssignmentCategories = 'MANAGE_ASSIGNMENT_CATEGORIES',
  ManageAssignmentClassConfiguration = 'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageAssignmentTemplates = 'MANAGE_ASSIGNMENT_TEMPLATES',
  ManageBadges = 'MANAGE_BADGES',
  ManageBanner = 'MANAGE_BANNER',
  ManageClassGroupPresets = 'MANAGE_CLASS_GROUP_PRESETS',
  ManageClassSegmentEnabled = 'MANAGE_CLASS_SEGMENT_ENABLED',
  ManageClassSegmentLocked = 'MANAGE_CLASS_SEGMENT_LOCKED',
  ManageClassSegmentStructure = 'MANAGE_CLASS_SEGMENT_STRUCTURE',
  ManageCourseViews = 'MANAGE_COURSE_VIEWS',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageLogs = 'MANAGE_LOGS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  ManageOwnAssignmentCategories = 'MANAGE_OWN_ASSIGNMENT_CATEGORIES',
  ManageOwnAssignmentClassConfiguration = 'MANAGE_OWN_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageOwnClassGroupPresets = 'MANAGE_OWN_CLASS_GROUP_PRESETS',
  ManageOwnClassSegmentEnabled = 'MANAGE_OWN_CLASS_SEGMENT_ENABLED',
  ManageOwnClassSegmentLocked = 'MANAGE_OWN_CLASS_SEGMENT_LOCKED',
  ManageOwnClassSegmentStructure = 'MANAGE_OWN_CLASS_SEGMENT_STRUCTURE',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  ManageOwnPendingClassStudents = 'MANAGE_OWN_PENDING_CLASS_STUDENTS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ManagePendingClassStudents = 'MANAGE_PENDING_CLASS_STUDENTS',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageStandardSets = 'MANAGE_STANDARD_SETS',
  ManageTrialRequestCampaigns = 'MANAGE_TRIAL_REQUEST_CAMPAIGNS',
  ManageWidgetTemplates = 'MANAGE_WIDGET_TEMPLATES',
  MessageOwnUser = 'MESSAGE_OWN_USER',
  MessageUser = 'MESSAGE_USER',
  ModerateComments = 'MODERATE_COMMENTS',
  MoveTeacher = 'MOVE_TEACHER',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  RefreshMediaUrl = 'REFRESH_MEDIA_URL',
  RefreshOwnMediaUrl = 'REFRESH_OWN_MEDIA_URL',
  Register = 'REGISTER',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  RequestInformationForClassSyncSetup = 'REQUEST_INFORMATION_FOR_CLASS_SYNC_SETUP',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  RequestTrial = 'REQUEST_TRIAL',
  ResetAssignmentSubmission = 'RESET_ASSIGNMENT_SUBMISSION',
  ResetOwnAssignmentSubmission = 'RESET_OWN_ASSIGNMENT_SUBMISSION',
  ResetPassword = 'RESET_PASSWORD',
  RestoreAnsweredQuestion = 'RESTORE_ANSWERED_QUESTION',
  RestoreAnsweredQuestionContributor = 'RESTORE_ANSWERED_QUESTION_CONTRIBUTOR',
  RestoreClass = 'RESTORE_CLASS',
  RestoreClassGroupPreset = 'RESTORE_CLASS_GROUP_PRESET',
  RestoreClassTemplate = 'RESTORE_CLASS_TEMPLATE',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreDiscussion = 'RESTORE_DISCUSSION',
  RestoreDiscussionThread = 'RESTORE_DISCUSSION_THREAD',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreLesson = 'RESTORE_LESSON',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreLicenseSubscription = 'RESTORE_LICENSE_SUBSCRIPTION',
  RestoreMedia = 'RESTORE_MEDIA',
  RestoreOwnAnnouncement = 'RESTORE_OWN_ANNOUNCEMENT',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  RestoreOwnClassGroupPreset = 'RESTORE_OWN_CLASS_GROUP_PRESET',
  RestoreOwnClassTemplate = 'RESTORE_OWN_CLASS_TEMPLATE',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  RestoreOwnDiscussion = 'RESTORE_OWN_DISCUSSION',
  RestoreOwnDiscussionThread = 'RESTORE_OWN_DISCUSSION_THREAD',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  RestoreOwnLesson = 'RESTORE_OWN_LESSON',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  RestoreOwnLicenseSubscription = 'RESTORE_OWN_LICENSE_SUBSCRIPTION',
  RestoreOwnMedia = 'RESTORE_OWN_MEDIA',
  RestoreOwnPractice = 'RESTORE_OWN_PRACTICE',
  RestoreOwnRubricTemplate = 'RESTORE_OWN_RUBRIC_TEMPLATE',
  RestoreOwnSavedReport = 'RESTORE_OWN_SAVED_REPORT',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  RestorePractice = 'RESTORE_PRACTICE',
  RestoreSavedReport = 'RESTORE_SAVED_REPORT',
  RestoreSegment = 'RESTORE_SEGMENT',
  RestoreSite = 'RESTORE_SITE',
  RestoreStandardSet = 'RESTORE_STANDARD_SET',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreTag = 'RESTORE_TAG',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RestoreTrialRequestCampaign = 'RESTORE_TRIAL_REQUEST_CAMPAIGN',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreWidgetTemplate = 'RESTORE_WIDGET_TEMPLATE',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  RosterStudentsFromClassSync = 'ROSTER_STUDENTS_FROM_CLASS_SYNC',
  RosterStudentsFromOwnClassSync = 'ROSTER_STUDENTS_FROM_OWN_CLASS_SYNC',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  RunReport = 'RUN_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunStudentReport = 'RUN_STUDENT_REPORT',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  SaveClassTemplate = 'SAVE_CLASS_TEMPLATE',
  SaveOwnAssignmentSubmission = 'SAVE_OWN_ASSIGNMENT_SUBMISSION',
  SaveOwnClassTemplate = 'SAVE_OWN_CLASS_TEMPLATE',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  Search = 'SEARCH',
  SearchAnsweredQuestions = 'SEARCH_ANSWERED_QUESTIONS',
  SearchAnsweredQuestionContributors = 'SEARCH_ANSWERED_QUESTION_CONTRIBUTORS',
  SearchBadges = 'SEARCH_BADGES',
  SearchCourses = 'SEARCH_COURSES',
  SearchDistricts = 'SEARCH_DISTRICTS',
  SearchSites = 'SEARCH_SITES',
  SearchUsers = 'SEARCH_USERS',
  ShareNode = 'SHARE_NODE',
  ShareOwnNode = 'SHARE_OWN_NODE',
  SubmitAnsweredQuestion = 'SUBMIT_ANSWERED_QUESTION',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitMedia = 'SUBMIT_MEDIA',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  SubmitOwnMedia = 'SUBMIT_OWN_MEDIA',
  SyncAssignment = 'SYNC_ASSIGNMENT',
  SyncOwnAssignment = 'SYNC_OWN_ASSIGNMENT',
  SyncOwnSegment = 'SYNC_OWN_SEGMENT',
  SyncSegment = 'SYNC_SEGMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  UpdateAssignmentTemplate = 'UPDATE_ASSIGNMENT_TEMPLATE',
  UpdateClass = 'UPDATE_CLASS',
  UpdateClassSettings = 'UPDATE_CLASS_SETTINGS',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateCourse = 'UPDATE_COURSE',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  UpdateDiscussionThread = 'UPDATE_DISCUSSION_THREAD',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateLesson = 'UPDATE_LESSON',
  UpdateLicense = 'UPDATE_LICENSE',
  UpdateLicenseSubscription = 'UPDATE_LICENSE_SUBSCRIPTION',
  UpdateMedia = 'UPDATE_MEDIA',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateOwnAssignmentTemplate = 'UPDATE_OWN_ASSIGNMENT_TEMPLATE',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  UpdateOwnClassSettings = 'UPDATE_OWN_CLASS_SETTINGS',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  UpdateOwnDiscussionThread = 'UPDATE_OWN_DISCUSSION_THREAD',
  UpdateOwnLesson = 'UPDATE_OWN_LESSON',
  UpdateOwnMedia = 'UPDATE_OWN_MEDIA',
  UpdateOwnPractice = 'UPDATE_OWN_PRACTICE',
  UpdateOwnSavedReport = 'UPDATE_OWN_SAVED_REPORT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdatePractice = 'UPDATE_PRACTICE',
  UpdateSavedReport = 'UPDATE_SAVED_REPORT',
  UpdateSite = 'UPDATE_SITE',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateTags = 'UPDATE_TAGS',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  UpdateUser = 'UPDATE_USER',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  UploadMedia = 'UPLOAD_MEDIA',
  UseInReport = 'USE_IN_REPORT',
  UseOwnInReport = 'USE_OWN_IN_REPORT',
  UsePracticeIsViewed = 'USE_PRACTICE_IS_VIEWED',
  ViewAllRubricTemplates = 'VIEW_ALL_RUBRIC_TEMPLATES',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  ViewAnsweredQuestion = 'VIEW_ANSWERED_QUESTION',
  ViewAnsweredQuestionContributor = 'VIEW_ANSWERED_QUESTION_CONTRIBUTOR',
  ViewAssignmentAnswers = 'VIEW_ASSIGNMENT_ANSWERS',
  ViewAssignmentReport = 'VIEW_ASSIGNMENT_REPORT',
  ViewAssignmentSync = 'VIEW_ASSIGNMENT_SYNC',
  ViewAssignmentTemplate = 'VIEW_ASSIGNMENT_TEMPLATE',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewClass = 'VIEW_CLASS',
  ViewClassGroupPreset = 'VIEW_CLASS_GROUP_PRESET',
  ViewClassIdsWithSegment = 'VIEW_CLASS_IDS_WITH_SEGMENT',
  ViewClassPractices = 'VIEW_CLASS_PRACTICES',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  ViewClassSyncHistory = 'VIEW_CLASS_SYNC_HISTORY',
  ViewClassTemplate = 'VIEW_CLASS_TEMPLATE',
  ViewComment = 'VIEW_COMMENT',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewDiscussion = 'VIEW_DISCUSSION',
  ViewDiscussionThread = 'VIEW_DISCUSSION_THREAD',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewLesson = 'VIEW_LESSON',
  ViewLessonAssignments = 'VIEW_LESSON_ASSIGNMENTS',
  ViewLessonStudentViews = 'VIEW_LESSON_STUDENT_VIEWS',
  ViewLicense = 'VIEW_LICENSE',
  ViewLicenseSubscription = 'VIEW_LICENSE_SUBSCRIPTION',
  ViewMaterialSync = 'VIEW_MATERIAL_SYNC',
  ViewMedia = 'VIEW_MEDIA',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnAnnouncement = 'VIEW_OWN_ANNOUNCEMENT',
  ViewOwnAssignment = 'VIEW_OWN_ASSIGNMENT',
  ViewOwnAssignmentReport = 'VIEW_OWN_ASSIGNMENT_REPORT',
  ViewOwnAssignmentSubmission = 'VIEW_OWN_ASSIGNMENT_SUBMISSION',
  ViewOwnAssignmentSync = 'VIEW_OWN_ASSIGNMENT_SYNC',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  ViewOwnClassGroupPreset = 'VIEW_OWN_CLASS_GROUP_PRESET',
  ViewOwnClassPractices = 'VIEW_OWN_CLASS_PRACTICES',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewOwnClassSync = 'VIEW_OWN_CLASS_SYNC',
  ViewOwnClassSyncHistory = 'VIEW_OWN_CLASS_SYNC_HISTORY',
  ViewOwnClassTemplate = 'VIEW_OWN_CLASS_TEMPLATE',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewOwnCourse = 'VIEW_OWN_COURSE',
  ViewOwnDirectMessageThread = 'VIEW_OWN_DIRECT_MESSAGE_THREAD',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ViewOwnDiscussion = 'VIEW_OWN_DISCUSSION',
  ViewOwnDiscussionThread = 'VIEW_OWN_DISCUSSION_THREAD',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewOwnLesson = 'VIEW_OWN_LESSON',
  ViewOwnLessonAssignments = 'VIEW_OWN_LESSON_ASSIGNMENTS',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  ViewOwnLicenseSubscription = 'VIEW_OWN_LICENSE_SUBSCRIPTION',
  ViewOwnMaterialSync = 'VIEW_OWN_MATERIAL_SYNC',
  ViewOwnMedia = 'VIEW_OWN_MEDIA',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ViewOwnPractice = 'VIEW_OWN_PRACTICE',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewOwnRubricTemplate = 'VIEW_OWN_RUBRIC_TEMPLATE',
  ViewOwnSavedReport = 'VIEW_OWN_SAVED_REPORT',
  ViewOwnSegment = 'VIEW_OWN_SEGMENT',
  ViewOwnSite = 'VIEW_OWN_SITE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ViewOwnTag = 'VIEW_OWN_TAG',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserMedia = 'VIEW_OWN_USER_MEDIA',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  ViewOwnUseInUnownedClasses = 'VIEW_OWN_USE_IN_UNOWNED_CLASSES',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ViewPractice = 'VIEW_PRACTICE',
  ViewQuestionSegment = 'VIEW_QUESTION_SEGMENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewSavedReport = 'VIEW_SAVED_REPORT',
  ViewSegment = 'VIEW_SEGMENT',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  ViewSharedClassTemplate = 'VIEW_SHARED_CLASS_TEMPLATE',
  ViewSharedLesson = 'VIEW_SHARED_LESSON',
  ViewSharedPractice = 'VIEW_SHARED_PRACTICE',
  ViewSite = 'VIEW_SITE',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ViewStandardSet = 'VIEW_STANDARD_SET',
  ViewStudent = 'VIEW_STUDENT',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewTag = 'VIEW_TAG',
  ViewTeacher = 'VIEW_TEACHER',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  ViewTrialRequestCampaign = 'VIEW_TRIAL_REQUEST_CAMPAIGN',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserMedia = 'VIEW_USER_MEDIA',
  ViewUserName = 'VIEW_USER_NAME',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewUseInUnownedClasses = 'VIEW_USE_IN_UNOWNED_CLASSES',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewWidgetTemplate = 'VIEW_WIDGET_TEMPLATE'
}

export type PracticeInput = {
  /** Optionally the class you would like to associate the practice with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  lessonId?: InputMaybe<Scalars['ID']['input']>;
  media: MediaUploadUrlInput;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PracticePatch = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PreferencesInput = {
  /** The name of the channels to disable. */
  disabledChannels: Array<Scalars['String']['input']>;
  /** The identifier for the type of notification. */
  notificationType: Scalars['String']['input'];
};

export type PrepareCourseViewSampleSpreadsheetInput = {
  /** The ID of the course view to prepare the spreadsheet for. */
  courseViewId: Scalars['ID']['input'];
};

export type PublishCourseVersionInput = {
  id: Scalars['ID']['input'];
};

export type ReadNotificationInput = {
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};

export type ReadNotificationPathInput = {
  read: Scalars['Boolean']['input'];
  universalPath: Scalars['String']['input'];
};

export type ReadNotificationsInput = {
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: InputMaybe<Scalars['Boolean']['input']>;
  /** The notifications to update. */
  notifications?: InputMaybe<Array<ReadNotificationInput>>;
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: InputMaybe<Array<ReadNotificationPathInput>>;
};

export type RecordCanvasCredentialsInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  token: Scalars['String']['input'];
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String']['input'];
};

export type RecordInteractionsInput = {
  interactions: Array<InteractionInput>;
};

export type RecordMicrosoftTenantInput = {
  tenantId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RefreshMediaUploadUrlInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RejectCommentInput = {
  id: Scalars['ID']['input'];
  /** The reason the comment was rejected. */
  reason: Scalars['String']['input'];
};

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID']['input'];
  /** An array of user IDs to remove from the thread. */
  userIds: Array<Scalars['ID']['input']>;
};

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID']['input'];
  /** The ID of the student to remove. */
  studentId: Scalars['ID']['input'];
};

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID']['input'];
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID']['input'];
};

export type ReportDimensionInput = {
  classes?: InputMaybe<ClassesReportDimensionInput>;
  segment?: InputMaybe<SegmentReportDimensionInput>;
  students?: InputMaybe<StudentsReportDimensionInput>;
};

export type ReportFilterInput = {
  isLeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportMetricInput = {
  contentVisited?: InputMaybe<ContentVisitedReportMetricInput>;
  grade?: InputMaybe<GradeReportMetricInput>;
  numAttempts?: InputMaybe<NumAttemptsMetricInput>;
  timeOnTask?: InputMaybe<TimeOnTaskReportMetricInput>;
};

export type ReportStandardsSummaryInput = {
  standardSetId: Scalars['ID']['input'];
};

export type RequestInformationForClassSyncSetupInput = {
  /** The identifier for the class sync provider to request information for. */
  providerIdentifier: Scalars['String']['input'];
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the class to reset submissions for, optionally. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID']['input'];
  /**
   * If this is true, the latest submission (with its answers) is preserved.
   * The grade will still be reset, the created date will be reset to now, and the
   * submitted status will be reset.
   */
  preserveLatestSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentInput = {
  /** The ID of the assignment to reset. */
  id: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentSubmissionInput = {
  /** The ID of the class associated with the submission. */
  classId: Scalars['ID']['input'];
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the student associated with the submission. */
  studentId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ResolveDuplicateUserPendingStudentClassInput = {
  /** The ID of the PendingStudentClass record. */
  id: Scalars['ID']['input'];
  /** The resolution to apply. */
  resolution: DuplicateUserPendingStudentClassResolution;
};

export type RetrySubmissionInput = {
  /** The ID of the class to retry the submission in. */
  classId: Scalars['ID']['input'];
  /** The ID of the Assignment */
  id: Scalars['ID']['input'];
};

export enum RevealAnswers {
  /** Answers are revealed to students after they are finished, or after revealAttempts. */
  AfterSubmit = 'AFTER_SUBMIT',
  /** Answers are revealed to students in real time. */
  RealTime = 'REAL_TIME'
}

export type RosterStudentsFromClassSyncInput = {
  actions: Array<ClassSyncRosterActionInput>;
  /** The ID of the class sync to roster students from. */
  id: Scalars['ID']['input'];
};

export type RubricTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
};

export type RunGradeReportInput = {
  /** Optional Class IDs to filter on. */
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Optional if we want to format based on points instead of percentages. */
  showAsPoints?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RunReportInput = {
  /** Any dimensions to filter on inside the report. */
  dimensions: Array<ReportDimensionInput>;
  /** Any filters to apply to the report. */
  filters?: InputMaybe<Array<ReportFilterInput>>;
  /** Any metrics to display inside the report. */
  metrics: Array<ReportMetricInput>;
};

/**
 * Input required for creating or updating assignment submissions.
 * These are used by both teachers and students.
 */
export type SaveAssignmentSubmissionInput = {
  /** The ID of the class to create or update the submission for. */
  classId: Scalars['ID']['input'];
  /** If this is true, a new submission will be created instead of updating the existing one. */
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment to save submission data for. */
  id: Scalars['ID']['input'];
  /** Any overrides to save back to the submission. */
  overrides?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the student (defaults to the current user). */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** The submission data to save. */
  submissionData: Scalars['JSON']['input'];
  /** Whether or not to immediately submit after saving. */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is specified, this will attempt to update the specified submission ID instead. */
  updateId?: InputMaybe<Scalars['ID']['input']>;
};

export type SaveClassTemplateInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the class to save as a template. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SchoologyTokenInput = {
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<ClassStudentInput>>;
  tags?: InputMaybe<TagsFilter>;
};

export type SegmentCompletionInput = {
  /** The new complete status of these segments. */
  complete: Scalars['Boolean']['input'];
  /** The IDs of the segments to update, in GraphQL format. */
  segmentIds: Array<Scalars['ID']['input']>;
  /** The IDs of the students to update with these segment IDs, in GraphQL format. */
  studentIds: Array<Scalars['ID']['input']>;
};

export type SegmentInput = {
  /** The configuration for the segment. */
  config?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the segment. */
  id: Scalars['ID']['input'];
  /** The index for the segment. */
  index?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the segment. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of segment to create or update. */
  type?: InputMaybe<SegmentType>;
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
  /** The new tags to set for the segment. */
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
};

export type SegmentReportDimensionInput = {
  /** This is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SegmentType {
  /** eslint-disable-next-line @graphql-eslint/naming-convention */
  Assignment = 'Assignment',
  Group = 'Group',
  Reference = 'Reference',
  Root = 'Root',
  Section = 'Section',
  Term = 'Term',
  Topic = 'Topic'
}

export type SegmentsFilters = {
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
  types?: InputMaybe<Array<SegmentType>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShareNodeInput = {
  /** The ID of the node to share or unshare. */
  id: Scalars['ID']['input'];
  /** For sharing, whether this list of user ids will replace the existing shares. */
  replace?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the users to share or unshare with. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum SharingType {
  IveShared = 'IVE_SHARED',
  Own = 'OWN',
  SharedWithMe = 'SHARED_WITH_ME'
}

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String']['input'];
  /** Whether or not the upload should be public. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The mime-type of the file. */
  mimeType: Scalars['String']['input'];
  /** An optional namespace to prefix the file with. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The request mode to use when uploading. */
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type SiteConfigurationInput = {
  /** Pass providers here to override their configuration. */
  classSyncProviders?: InputMaybe<Array<ClassSyncProviderConfigurationInput>>;
  defaultClassConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  /** The standard set IDs enabled for this site. */
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<SiteConfigurationInput>;
  disableCourseCodes?: InputMaybe<Scalars['Boolean']['input']>;
  districtId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<AccountRegion>;
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SiteWideBannerInput = {
  message: Scalars['String']['input'];
  /** The user groups the message should show up for. */
  userGroups: Array<Scalars['String']['input']>;
};

export type StandardSetTagInput = {
  name: Scalars['String']['input'];
  parents?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type StartStandardSetImportInput = {
  csvPath: Scalars['String']['input'];
  standardSetId: Scalars['ID']['input'];
};

export type StoreVodCaptionInput = {
  /** The WebVTT contents of the caption file. */
  fileContents: Scalars['String']['input'];
  /** The language code for the caption file. */
  language: Scalars['String']['input'];
};

export type StoreVodCaptionsInput = {
  /** The new captions to store (they will be added to the existing ones) */
  captions: Array<StoreVodCaptionInput>;
  /** The ID of the Video on Demand to store captions for. */
  id: Scalars['ID']['input'];
};

export type StudentFilterInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type StudentInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type StudentReportInput = {
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID']['input'];
  /** The ID of the student to run the report on. */
  id: Scalars['ID']['input'];
};

export enum StudentReportResultType {
  Assignment = 'ASSIGNMENT',
  Section = 'SECTION'
}

export type StudentsReportDimensionInput = {
  studentPairs: Array<ClassStudentInput>;
};

export enum SubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL'
}

export type SubmitAnsweredQuestionInput = {
  /** The question being asked. */
  name: Scalars['String']['input'];
};

export type SubmitAssignmentInput = {
  /** Whether or not to force the submission. */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID']['input'];
  /** Any submission data to add. */
  submissionData?: InputMaybe<Scalars['JSON']['input']>;
};

export type SubmitMediaInput = {
  id: Scalars['ID']['input'];
};

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID']['input'];
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SyncSegmentInput = {
  /** The global segment ID (not the raw one). */
  id: Scalars['ID']['input'];
  /** The class syncs to use to sync this assignment. */
  syncIds: Array<Scalars['ID']['input']>;
};

export type TagConfigInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

export type TagFilterInput = {
  /** The name of the tag. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The path of parent tags to find all children for. */
  path?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The type of tag. */
  type: Scalars['String']['input'];
};

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type TagFilters = {
  /** Whether or not to filter on tags that have content available in the current user's license. */
  inLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TagsFilter = {
  operation: TagFilterOperation;
  tags: Array<TagFilterInput>;
};

export type TeacherInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  noEmail?: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: InputMaybe<Scalars['String']['input']>;
};

export type TimeOnTaskReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY'
}

export type TrialRequestCampaignInput = {
  /** The campaign name */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The target path for the campaign */
  targetPath?: InputMaybe<Scalars['String']['input']>;
  /** The welcome message */
  welcomeMessage?: InputMaybe<TrialRequestCampaignWelcomeMessageInput>;
};

export type TrialRequestCampaignWelcomeMessageInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum TrialRequestStatus {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
  Ignored = 'IGNORED',
  Open = 'OPEN'
}

export type TrialSignupInput = {
  /** The identifier for the trial request campaign to associate. */
  campaign?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siteName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID']['input'];
};

export type UnlikeNodeInput = {
  /** The ID of the node to unlike. */
  id: Scalars['ID']['input'];
};

export type UnlinkAccountInput = {
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The user's specific ID related to the provider. */
  providerId: Scalars['String']['input'];
};

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID']['input'];
  patch: AnnouncementInput;
};

export type UpdateAnsweredQuestionContributorInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionContributorInput;
};

export type UpdateAnsweredQuestionInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionInput;
};

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID']['input'];
  patch: AssignmentCategoryInput;
};

export type UpdateAssignmentClassConfigurationInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentClassConfigurationInput;
};

export type UpdateAssignmentInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the assignment to update. */
  id: Scalars['ID']['input'];
  patch: AssignmentInput;
};

export type UpdateAssignmentTemplateInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentTemplateInput;
};

export type UpdateBadgeInput = {
  id: Scalars['ID']['input'];
  patch: BadgeInput;
};

export type UpdateClassGroupPresetInput = {
  id: Scalars['ID']['input'];
  patch: ClassGroupPresetInput;
};

export type UpdateClassInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  patch: ClassInput;
  segmentMetadataChanges?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID']['input'];
  patch: CommentInput;
};

export type UpdateCourseInput = {
  id: Scalars['ID']['input'];
  patch: CourseInput;
};

export type UpdateCourseVersionInput = {
  id: Scalars['ID']['input'];
  patch: CourseVersionInput;
};

export type UpdateDiscussionThreadInput = {
  /** The ID of the discussion thread to update. */
  id: Scalars['ID']['input'];
  patch: DiscussionThreadInput;
};

export type UpdateDistrictInput = {
  id: Scalars['ID']['input'];
  patch: DistrictInput;
};

export type UpdateLessonInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<LessonInput>;
};

export type UpdateLicenseInput = {
  id: Scalars['ID']['input'];
  noEmail?: InputMaybe<Scalars['Boolean']['input']>;
  patch: LicenseInput;
};

export type UpdateLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
  patch: LicenseSubscriptionInput;
};

export type UpdateMediaInput = {
  id: Scalars['ID']['input'];
  patch: MediaInput;
};

export type UpdateNodeTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdatePracticeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<PracticePatch>;
};

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: InputMaybe<Scalars['String']['input']>;
  /** The user's current preferences. */
  preferences: Array<PreferencesInput>;
};

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID']['input'];
  patch: RubricTemplateInput;
};

export type UpdateSavedReportInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  report?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSegmentCompletionInput = {
  /** The ID of the class to update the completion in. */
  id: Scalars['ID']['input'];
  /** The completion updates to perform. */
  updates: Array<SegmentCompletionInput>;
};

export type UpdateSiteInput = {
  id: Scalars['ID']['input'];
  patch: SiteInput;
};

export type UpdateSiteWideBannerInput = {
  message?: InputMaybe<SiteWideBannerInput>;
};

export type UpdateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type UpdateStudentInput = {
  id: Scalars['ID']['input'];
  patch: StudentInput;
};

export type UpdateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  /** The ID of the tag to update. */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID']['input'];
  /** The new permissions to assign to the teacher. */
  permissions: Array<Scalars['String']['input']>;
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID']['input'];
};

export type UpdateTeacherInput = {
  id: Scalars['ID']['input'];
  patch: TeacherInput;
};

export type UpdateTrialRequestCampaignInput = {
  id: Scalars['ID']['input'];
  patch: TrialRequestCampaignInput;
};

export type UpdateUserDetailedLoggingInput = {
  /** Whether or not to enable detailed logging for this user. */
  enabled: Scalars['Boolean']['input'];
  /** The ID of the user to update. */
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  /** If this is passed, the user's last activity is updated to the current time. */
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID']['input'];
  patch: VideoOnDemandInput;
};

export type UpdateWidgetTemplateInput = {
  id: Scalars['ID']['input'];
  patch: WidgetTemplateInput;
};

export enum UploadRequestMode {
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA'
}

export type UseAssignmentSubmissionInput = {
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the submission to use. */
  submissionId: Scalars['ID']['input'];
};

export enum UserGroup {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  ContentManager = 'CONTENT_MANAGER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserTasksFilterInput = {
  /** An optional class ID to filter the user tasks on. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoOnDemandCaptionStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type VideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnailData?: InputMaybe<Scalars['JSON']['input']>;
};

export enum VideoOnDemandStatus {
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING'
}

export type WidgetTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type CheckoutSessionFragment = (
  {
  id: string,
  type: CheckoutSessionType,
  amountInCents: number
}
  & {
  __typename?: 'CheckoutSession'
}
);

export type CancelSubscriptionMutation_cancelLicenseSubscription_SuccessResponse = (
  {
  success: boolean
}
  & {
  __typename?: 'SuccessResponse'
}
);

export type CancelSubscriptionMutation_Mutation = (
  {
  cancelLicenseSubscription: CancelSubscriptionMutation_cancelLicenseSubscription_SuccessResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type CancelSubscriptionMutationVariables = Exact<{
  input: CancelLicenseSubscriptionInput;
}>;


export type CancelSubscriptionMutation = CancelSubscriptionMutation_Mutation;

export type ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge_node_Class = (
  {
  id: string,
  hasValidSubscription: boolean
}
  & {
  __typename?: 'Class'
}
);

export type ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge = (
  {
  node: ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge_node_Class
}
  & {
  __typename?: 'StudentClassesEdge'
}
);

export type ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection = (
  {
  edges: Array<ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge>
}
  & {
  __typename?: 'StudentClassesConnection'
}
);

export type ClassValidityQuery_viewer_User_student_Student = (
  {
  id: string,
  classes: ClassValidityQuery_viewer_User_student_Student_classes_StudentClassesConnection
}
  & {
  __typename?: 'Student'
}
);

export type ClassValidityQuery_viewer_User = (
  {
  id: string,
  student?: ClassValidityQuery_viewer_User_student_Student | null
}
  & {
  __typename?: 'User'
}
);

export type ClassValidityQuery_Query = (
  {
  viewer?: ClassValidityQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassValidityQueryVariables = Exact<{ [key: string]: never; }>;


export type ClassValidityQuery = ClassValidityQuery_Query;

export type CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload_license_License = (
  {
  id: string,
  payUpFrontMonths?: number | null
}
  & {
  __typename?: 'License'
}
);

export type CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload_sessions_CheckoutSession = (
  {
  id: string,
  type: CheckoutSessionType,
  amountInCents: number
}
  & {
  __typename?: 'CheckoutSession'
}
);

export type CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload = (
  {
  license: CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload_license_License,
  sessions: Array<CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload_sessions_CheckoutSession>
}
  & {
  __typename?: 'CreateCheckoutSessionPayload'
}
);

export type CreateCheckoutSessionMutation_Mutation = (
  {
  createCheckoutSession: CreateCheckoutSessionMutation_createCheckoutSession_CreateCheckoutSessionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateCheckoutSessionMutationVariables = Exact<{
  input: CreateCheckoutSessionInput;
}>;


export type CreateCheckoutSessionMutation = CreateCheckoutSessionMutation_Mutation;

export type CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site = (
  {
  id: string,
  name: string
}
  & {
  __typename?: 'Site'
}
);

export type CreateLicenseMutation_createLicense_LicensePayload_license_License = (
  {
  id: string,
  productId?: string | null,
  expiresAt?: any | null,
  createdAt: any,
  updatedAt: any,
  price?: number | null,
  seats?: number | null,
  type: LicenseType,
  monthlyPriceInCents?: number | null,
  payUpFrontMonths?: number | null,
  payUpFrontPriceInCents?: number | null,
  site: CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type CreateLicenseMutation_createLicense_LicensePayload = (
  {
  license: CreateLicenseMutation_createLicense_LicensePayload_license_License
}
  & {
  __typename?: 'LicensePayload'
}
);

export type CreateLicenseMutation_Mutation = (
  {
  createLicense: CreateLicenseMutation_createLicense_LicensePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateLicenseMutationVariables = Exact<{
  input: LicenseInput;
}>;


export type CreateLicenseMutation = CreateLicenseMutation_Mutation;

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User = (
  {
  id: string,
  name?: string | null,
  username: string
}
  & {
  __typename?: 'User'
}
);

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class = (
  {
  id: string,
  name: string
}
  & {
  __typename?: 'Class'
}
);

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License_site_Site = (
  {
  id: string,
  name: string
}
  & {
  __typename?: 'Site'
}
);

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License = (
  {
  id: string,
  site: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload = (
  {
  subscription: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription
}
  & {
  __typename?: 'LicenseSubscriptionPayload'
}
);

export type CreateSubscriptionMutation_Mutation = (
  {
  createLicenseSubscription: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateSubscriptionMutationVariables = Exact<{
  input: LicenseSubscriptionInput;
}>;


export type CreateSubscriptionMutation = CreateSubscriptionMutation_Mutation;

export type LicenseFragment = (
  {
  id: string,
  productId?: string | null,
  expiresAt?: any | null,
  createdAt: any,
  updatedAt: any,
  price?: number | null,
  seats?: number | null,
  type: LicenseType,
  monthlyPriceInCents?: number | null,
  payUpFrontMonths?: number | null,
  payUpFrontPriceInCents?: number | null,
  site: CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type LicenseSubscriptionFragment = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type LicensesQuery_licenses_LicensesConnection_pageInfo_PageInfo = (
  {
  endCursor?: string | null,
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type LicensesQuery_licenses_LicensesConnection_edges_LicensesEdge_node_License = (
  {
  id: string,
  productId?: string | null,
  expiresAt?: any | null,
  createdAt: any,
  updatedAt: any,
  price?: number | null,
  seats?: number | null,
  type: LicenseType,
  monthlyPriceInCents?: number | null,
  payUpFrontMonths?: number | null,
  payUpFrontPriceInCents?: number | null,
  site: CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type LicensesQuery_licenses_LicensesConnection_edges_LicensesEdge = (
  {
  node: LicensesQuery_licenses_LicensesConnection_edges_LicensesEdge_node_License
}
  & {
  __typename?: 'LicensesEdge'
}
);

export type LicensesQuery_licenses_LicensesConnection = (
  {
  pageInfo: LicensesQuery_licenses_LicensesConnection_pageInfo_PageInfo,
  edges: Array<LicensesQuery_licenses_LicensesConnection_edges_LicensesEdge>
}
  & {
  __typename?: 'LicensesConnection'
}
);

export type LicensesQuery_Query = (
  {
  licenses: LicensesQuery_licenses_LicensesConnection
}
  & {
  __typename?: 'Query'
}
);


export type LicensesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type LicensesQuery = LicensesQuery_Query;

export type SiteLicenseQuery_node_Site_license_License = (
  {
  id: string,
  productId?: string | null,
  expiresAt?: any | null,
  createdAt: any,
  updatedAt: any,
  price?: number | null,
  seats?: number | null,
  type: LicenseType,
  monthlyPriceInCents?: number | null,
  payUpFrontMonths?: number | null,
  payUpFrontPriceInCents?: number | null,
  site: CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type SiteLicenseQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type SiteLicenseQuery_node_NXEzKG3Yr3QXWb5HM5oZowIYZ1jn6fWcy5x6YdIY4 = (
  {
  id: string
}
  & {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SiteLicenseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SiteLicenseQuery_node_Site = (
  {
  id: string,
  license?: SiteLicenseQuery_node_Site_license_License | null
}
  & {
  __typename: 'Site'
}
);

export type SiteLicenseQuery_node = SiteLicenseQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | SiteLicenseQuery_node_NXEzKG3Yr3QXWb5HM5oZowIYZ1jn6fWcy5x6YdIY4 | SiteLicenseQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SiteLicenseQuery_node_Site;

export type SiteLicenseQuery_Query = (
  {
  node?: SiteLicenseQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SiteLicenseQueryVariables = Exact<{
  siteId: Scalars['ID']['input'];
}>;


export type SiteLicenseQuery = SiteLicenseQuery_Query;

export type SubscriptionQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type SubscriptionQuery_node_sU2XdDivl98leJ7Ki3vlKlI4TxJLocdteekSbKgg = (
  {
  id: string
}
  & {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type SubscriptionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type SubscriptionQuery_node_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename: 'LicenseSubscription'
}
);

export type SubscriptionQuery_node = SubscriptionQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | SubscriptionQuery_node_sU2XdDivl98leJ7Ki3vlKlI4TxJLocdteekSbKgg | SubscriptionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | SubscriptionQuery_node_LicenseSubscription;

export type SubscriptionQuery_Query = (
  {
  node?: SubscriptionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type SubscriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type SubscriptionQuery = SubscriptionQuery_Query;

export type SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge = (
  {
  node: SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription
}
  & {
  __typename?: 'LicenseSubscriptionsEdge'
}
);

export type SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection = (
  {
  pageInfo: SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo,
  edges: Array<SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge>
}
  & {
  __typename?: 'LicenseSubscriptionsConnection'
}
);

export type SubscriptionsQuery_Query = (
  {
  licenseSubscriptions: SubscriptionsQuery_licenseSubscriptions_LicenseSubscriptionsConnection
}
  & {
  __typename?: 'Query'
}
);


export type SubscriptionsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type SubscriptionsQuery = SubscriptionsQuery_Query;

export type UpdateLicenseMutation_updateLicense_LicensePayload_license_License = (
  {
  id: string,
  productId?: string | null,
  expiresAt?: any | null,
  createdAt: any,
  updatedAt: any,
  price?: number | null,
  seats?: number | null,
  type: LicenseType,
  monthlyPriceInCents?: number | null,
  payUpFrontMonths?: number | null,
  payUpFrontPriceInCents?: number | null,
  site: CreateLicenseMutation_createLicense_LicensePayload_license_License_site_Site
}
  & {
  __typename?: 'License'
}
);

export type UpdateLicenseMutation_updateLicense_LicensePayload = (
  {
  license: UpdateLicenseMutation_updateLicense_LicensePayload_license_License
}
  & {
  __typename?: 'LicensePayload'
}
);

export type UpdateLicenseMutation_Mutation = (
  {
  updateLicense: UpdateLicenseMutation_updateLicense_LicensePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateLicenseMutationVariables = Exact<{
  input: UpdateLicenseInput;
}>;


export type UpdateLicenseMutation = UpdateLicenseMutation_Mutation;

export type UpdateSubscriptionMutation_updateLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type UpdateSubscriptionMutation_updateLicenseSubscription_LicenseSubscriptionPayload = (
  {
  subscription: UpdateSubscriptionMutation_updateLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription
}
  & {
  __typename?: 'LicenseSubscriptionPayload'
}
);

export type UpdateSubscriptionMutation_Mutation = (
  {
  updateLicenseSubscription: UpdateSubscriptionMutation_updateLicenseSubscription_LicenseSubscriptionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateSubscriptionMutationVariables = Exact<{
  input: UpdateLicenseSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = UpdateSubscriptionMutation_Mutation;

export type UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge = (
  {
  node: UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription
}
  & {
  __typename?: 'LicenseSubscriptionsEdge'
}
);

export type UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection = (
  {
  pageInfo: UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo,
  edges: Array<UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge>
}
  & {
  __typename?: 'LicenseSubscriptionsConnection'
}
);

export type UserSubscriptionsQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
}
);

export type UserSubscriptionsQuery_node_twf7k8eD4IZsZ49BQertZ3GTPMG6vrVocYxFsi2sQ = (
  {
  id: string
}
  & {
  __typename: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag'
}
);

export type UserSubscriptionsQuery_node_kzK6aKNZDBymaBOxVHFnfzNjhXQTw5mfD0Qcxf0yw = (
  {
  id: string
}
  & {
  __typename: 'Teacher' | 'TrialRequest' | 'TrialRequestCampaign' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type UserSubscriptionsQuery_node_User = (
  {
  id: string,
  licenseSubscriptions: UserSubscriptionsQuery_node_User_licenseSubscriptions_LicenseSubscriptionsConnection
}
  & {
  __typename: 'User'
}
);

export type UserSubscriptionsQuery_node = UserSubscriptionsQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | UserSubscriptionsQuery_node_twf7k8eD4IZsZ49BQertZ3GTPMG6vrVocYxFsi2sQ | UserSubscriptionsQuery_node_kzK6aKNZDBymaBOxVHFnfzNjhXQTw5mfD0Qcxf0yw | UserSubscriptionsQuery_node_User;

export type UserSubscriptionsQuery_Query = (
  {
  node?: UserSubscriptionsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type UserSubscriptionsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserSubscriptionsQuery = UserSubscriptionsQuery_Query;

export type ViewerLicenseQuery_viewer_User_teacher_Teacher_site_Site_license_License = (
  {
  id: string,
  type: LicenseType,
  expiresAt?: any | null
}
  & {
  __typename?: 'License'
}
);

export type ViewerLicenseQuery_viewer_User_teacher_Teacher_site_Site = (
  {
  id: string,
  license?: ViewerLicenseQuery_viewer_User_teacher_Teacher_site_Site_license_License | null
}
  & {
  __typename?: 'Site'
}
);

export type ViewerLicenseQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  site: ViewerLicenseQuery_viewer_User_teacher_Teacher_site_Site
}
  & {
  __typename?: 'Teacher'
}
);

export type ViewerLicenseQuery_viewer_User = (
  {
  id: string,
  teacher?: ViewerLicenseQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type ViewerLicenseQuery_Query = (
  {
  viewer?: ViewerLicenseQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type ViewerLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerLicenseQuery = ViewerLicenseQuery_Query;

export type ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription = (
  {
  id: string,
  type: LicenseSubscriptionType,
  size?: LicenseSubscriptionSize | null,
  expiresAt?: any | null,
  isValid?: boolean | null,
  nextValidityCheck?: any | null,
  subscriptionId?: string | null,
  metadata?: any | null,
  quota?: number | null,
  used?: number | null,
  user: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_user_User,
  class?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_class_Class | null,
  license?: CreateSubscriptionMutation_createLicenseSubscription_LicenseSubscriptionPayload_subscription_LicenseSubscription_license_License | null
}
  & {
  __typename?: 'LicenseSubscription'
}
);

export type ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge = (
  {
  node: ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge_node_LicenseSubscription
}
  & {
  __typename?: 'LicenseSubscriptionsEdge'
}
);

export type ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection = (
  {
  totalCount: number,
  pageInfo: ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_pageInfo_PageInfo,
  edges: Array<ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection_edges_LicenseSubscriptionsEdge>
}
  & {
  __typename?: 'LicenseSubscriptionsConnection'
}
);

export type ViewerSubscriptionsQuery_viewer_User = (
  {
  id: string,
  licenseSubscriptions: ViewerSubscriptionsQuery_viewer_User_licenseSubscriptions_LicenseSubscriptionsConnection
}
  & {
  __typename?: 'User'
}
);

export type ViewerSubscriptionsQuery_Query = (
  {
  viewer?: ViewerSubscriptionsQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type ViewerSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerSubscriptionsQuery = ViewerSubscriptionsQuery_Query;

export type ViewerUsageQuery_viewer_User = (
  {
  id: string,
  subscriptionUsage?: number | null,
  subscriptionQuota?: number | null
}
  & {
  __typename?: 'User'
}
);

export type ViewerUsageQuery_Query = (
  {
  viewer?: ViewerUsageQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type ViewerUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerUsageQuery = ViewerUsageQuery_Query;

export const CheckoutSessionFragmentDoc = gql`
    fragment CheckoutSession on CheckoutSession {
  id
  type
  amountInCents
}
    `;
export const LicenseFragmentDoc = gql`
    fragment License on License {
  id
  productId
  site {
    id
    name
  }
  expiresAt
  createdAt
  updatedAt
  price
  seats
  type
  monthlyPriceInCents
  payUpFrontMonths
  payUpFrontPriceInCents
}
    `;
export const LicenseSubscriptionFragmentDoc = gql`
    fragment LicenseSubscription on LicenseSubscription {
  id
  type
  size
  user {
    id
    name
    username
  }
  class {
    id
    name
  }
  license {
    id
    site {
      id
      name
    }
  }
  expiresAt
  isValid
  nextValidityCheck
  subscriptionId
  metadata
  quota
  used
}
    `;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($input: CancelLicenseSubscriptionInput!) {
  cancelLicenseSubscription(input: $input) {
    success
  }
}
    `;
export type CancelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ClassValidityDocument = gql`
    query classValidity {
  viewer {
    id
    student {
      id
      classes(first: 1000) {
        edges {
          node {
            id
            hasValidSubscription
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClassValidityQuery__
 *
 * To run a query within a React component, call `useClassValidityQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassValidityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassValidityQuery({
 *   variables: {
 *   },
 * });
 */
export function useClassValidityQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ClassValidityQuery, ClassValidityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassValidityQuery, ClassValidityQueryVariables>(ClassValidityDocument, options);
      }
export function useClassValidityLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassValidityQuery, ClassValidityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassValidityQuery, ClassValidityQueryVariables>(ClassValidityDocument, options);
        }
export type ClassValidityQueryHookResult = ReturnType<typeof useClassValidityQuery>;
export type ClassValidityLazyQueryHookResult = ReturnType<typeof useClassValidityLazyQuery>;
export type ClassValidityQueryResult = ApolloReactCommon.QueryResult<ClassValidityQuery, ClassValidityQueryVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation createCheckoutSession($input: CreateCheckoutSessionInput!) {
  createCheckoutSession(input: $input) {
    license {
      id
      payUpFrontMonths
    }
    sessions {
      id
      type
      amountInCents
    }
  }
}
    `;
export type CreateCheckoutSessionMutationFn = ApolloReactCommon.MutationFunction<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
      }
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = ApolloReactCommon.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const CreateLicenseDocument = gql`
    mutation createLicense($input: LicenseInput!) {
  createLicense(input: $input) {
    license {
      ...License
    }
  }
}
    ${LicenseFragmentDoc}`;
export type CreateLicenseMutationFn = ApolloReactCommon.MutationFunction<CreateLicenseMutation, CreateLicenseMutationVariables>;

/**
 * __useCreateLicenseMutation__
 *
 * To run a mutation, you first call `useCreateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicenseMutation, { data, loading, error }] = useCreateLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLicenseMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateLicenseMutation, CreateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateLicenseMutation, CreateLicenseMutationVariables>(CreateLicenseDocument, options);
      }
export type CreateLicenseMutationHookResult = ReturnType<typeof useCreateLicenseMutation>;
export type CreateLicenseMutationResult = ApolloReactCommon.MutationResult<CreateLicenseMutation>;
export type CreateLicenseMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLicenseMutation, CreateLicenseMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($input: LicenseSubscriptionInput!) {
  createLicenseSubscription(input: $input) {
    subscription {
      ...LicenseSubscription
    }
  }
}
    ${LicenseSubscriptionFragmentDoc}`;
export type CreateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const LicensesDocument = gql`
    query licenses($first: Int = 32, $after: String, $name: String) {
  licenses(first: $first, after: $after, name: $name) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...License
      }
    }
  }
}
    ${LicenseFragmentDoc}`;

/**
 * __useLicensesQuery__
 *
 * To run a query within a React component, call `useLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicensesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLicensesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
      }
export function useLicensesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<LicensesQuery, LicensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<LicensesQuery, LicensesQueryVariables>(LicensesDocument, options);
        }
export type LicensesQueryHookResult = ReturnType<typeof useLicensesQuery>;
export type LicensesLazyQueryHookResult = ReturnType<typeof useLicensesLazyQuery>;
export type LicensesQueryResult = ApolloReactCommon.QueryResult<LicensesQuery, LicensesQueryVariables>;
export const SiteLicenseDocument = gql`
    query siteLicense($siteId: ID!) {
  node(id: $siteId) {
    __typename
    id
    ... on Site {
      license {
        ...License
      }
    }
  }
}
    ${LicenseFragmentDoc}`;

/**
 * __useSiteLicenseQuery__
 *
 * To run a query within a React component, call `useSiteLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteLicenseQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteLicenseQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SiteLicenseQuery, SiteLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SiteLicenseQuery, SiteLicenseQueryVariables>(SiteLicenseDocument, options);
      }
export function useSiteLicenseLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SiteLicenseQuery, SiteLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SiteLicenseQuery, SiteLicenseQueryVariables>(SiteLicenseDocument, options);
        }
export type SiteLicenseQueryHookResult = ReturnType<typeof useSiteLicenseQuery>;
export type SiteLicenseLazyQueryHookResult = ReturnType<typeof useSiteLicenseLazyQuery>;
export type SiteLicenseQueryResult = ApolloReactCommon.QueryResult<SiteLicenseQuery, SiteLicenseQueryVariables>;
export const SubscriptionDocument = gql`
    query subscription($id: ID!) {
  node(id: $id) {
    __typename
    id
    ...LicenseSubscription
  }
}
    ${LicenseSubscriptionFragmentDoc}`;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
      }
export function useSubscriptionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, options);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionQueryResult = ApolloReactCommon.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const SubscriptionsDocument = gql`
    query subscriptions($after: String) {
  licenseSubscriptions(
    first: 32
    after: $after
    orderBy: "updatedAt"
    orderDirection: DESC
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...LicenseSubscription
      }
    }
  }
}
    ${LicenseSubscriptionFragmentDoc}`;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsQueryResult = ApolloReactCommon.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const UpdateLicenseDocument = gql`
    mutation updateLicense($input: UpdateLicenseInput!) {
  updateLicense(input: $input) {
    license {
      ...License
    }
  }
}
    ${LicenseFragmentDoc}`;
export type UpdateLicenseMutationFn = ApolloReactCommon.MutationFunction<UpdateLicenseMutation, UpdateLicenseMutationVariables>;

/**
 * __useUpdateLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicenseMutation, { data, loading, error }] = useUpdateLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLicenseMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateLicenseMutation, UpdateLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateLicenseMutation, UpdateLicenseMutationVariables>(UpdateLicenseDocument, options);
      }
export type UpdateLicenseMutationHookResult = ReturnType<typeof useUpdateLicenseMutation>;
export type UpdateLicenseMutationResult = ApolloReactCommon.MutationResult<UpdateLicenseMutation>;
export type UpdateLicenseMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLicenseMutation, UpdateLicenseMutationVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($input: UpdateLicenseSubscriptionInput!) {
  updateLicenseSubscription(input: $input) {
    subscription {
      ...LicenseSubscription
    }
  }
}
    ${LicenseSubscriptionFragmentDoc}`;
export type UpdateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, options);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const UserSubscriptionsDocument = gql`
    query userSubscriptions($userId: ID!, $first: Int = 32, $after: String) {
  node(id: $userId) {
    id
    __typename
    ... on User {
      licenseSubscriptions(first: $first, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...LicenseSubscription
          }
        }
      }
    }
  }
}
    ${LicenseSubscriptionFragmentDoc}`;

/**
 * __useUserSubscriptionsQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useUserSubscriptionsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(UserSubscriptionsDocument, options);
      }
export function useUserSubscriptionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>(UserSubscriptionsDocument, options);
        }
export type UserSubscriptionsQueryHookResult = ReturnType<typeof useUserSubscriptionsQuery>;
export type UserSubscriptionsLazyQueryHookResult = ReturnType<typeof useUserSubscriptionsLazyQuery>;
export type UserSubscriptionsQueryResult = ApolloReactCommon.QueryResult<UserSubscriptionsQuery, UserSubscriptionsQueryVariables>;
export const ViewerLicenseDocument = gql`
    query viewerLicense {
  viewer {
    id
    teacher {
      id
      site {
        id
        license {
          id
          type
          expiresAt
        }
      }
    }
  }
}
    `;

/**
 * __useViewerLicenseQuery__
 *
 * To run a query within a React component, call `useViewerLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerLicenseQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ViewerLicenseQuery, ViewerLicenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ViewerLicenseQuery, ViewerLicenseQueryVariables>(ViewerLicenseDocument, options);
      }
export function useViewerLicenseLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ViewerLicenseQuery, ViewerLicenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ViewerLicenseQuery, ViewerLicenseQueryVariables>(ViewerLicenseDocument, options);
        }
export type ViewerLicenseQueryHookResult = ReturnType<typeof useViewerLicenseQuery>;
export type ViewerLicenseLazyQueryHookResult = ReturnType<typeof useViewerLicenseLazyQuery>;
export type ViewerLicenseQueryResult = ApolloReactCommon.QueryResult<ViewerLicenseQuery, ViewerLicenseQueryVariables>;
export const ViewerSubscriptionsDocument = gql`
    query viewerSubscriptions {
  viewer {
    id
    licenseSubscriptions(first: 100) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...LicenseSubscription
        }
      }
    }
  }
}
    ${LicenseSubscriptionFragmentDoc}`;

/**
 * __useViewerSubscriptionsQuery__
 *
 * To run a query within a React component, call `useViewerSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerSubscriptionsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ViewerSubscriptionsQuery, ViewerSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ViewerSubscriptionsQuery, ViewerSubscriptionsQueryVariables>(ViewerSubscriptionsDocument, options);
      }
export function useViewerSubscriptionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ViewerSubscriptionsQuery, ViewerSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ViewerSubscriptionsQuery, ViewerSubscriptionsQueryVariables>(ViewerSubscriptionsDocument, options);
        }
export type ViewerSubscriptionsQueryHookResult = ReturnType<typeof useViewerSubscriptionsQuery>;
export type ViewerSubscriptionsLazyQueryHookResult = ReturnType<typeof useViewerSubscriptionsLazyQuery>;
export type ViewerSubscriptionsQueryResult = ApolloReactCommon.QueryResult<ViewerSubscriptionsQuery, ViewerSubscriptionsQueryVariables>;
export const ViewerUsageDocument = gql`
    query viewerUsage {
  viewer {
    id
    subscriptionUsage
    subscriptionQuota
  }
}
    `;

/**
 * __useViewerUsageQuery__
 *
 * To run a query within a React component, call `useViewerUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerUsageQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ViewerUsageQuery, ViewerUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ViewerUsageQuery, ViewerUsageQueryVariables>(ViewerUsageDocument, options);
      }
export function useViewerUsageLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ViewerUsageQuery, ViewerUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ViewerUsageQuery, ViewerUsageQueryVariables>(ViewerUsageDocument, options);
        }
export type ViewerUsageQueryHookResult = ReturnType<typeof useViewerUsageQuery>;
export type ViewerUsageLazyQueryHookResult = ReturnType<typeof useViewerUsageLazyQuery>;
export type ViewerUsageQueryResult = ApolloReactCommon.QueryResult<ViewerUsageQuery, ViewerUsageQueryVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}
