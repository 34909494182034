import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  CreateDistrictMutation,
  DistrictFragment,
  DistrictFragmentDoc,
  DistrictsDocument,
  useCreateDistrictMutation as useMutation,
} from '../schema'

export const useCreateDistrictMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error creating that district.'),
    refetchQueries: [{ query: DistrictsDocument }],
    update: updateMutation<CreateDistrictMutation, DistrictFragment>({
      path: 'createDistrict.district',
      fragment: DistrictFragmentDoc,
    }),
    ...opts,
  })
}
