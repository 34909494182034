import { styled } from '@thesisedu/web'
import React from 'react'

export interface SubmissionGradeContentProps {
  header: React.ReactElement
}
export function SubmissionGradeContent({
  header,
  children,
}: React.PropsWithChildren<SubmissionGradeContentProps>) {
  return (
    <Container>
      <div className={'header'} children={header} />
      <div className={'content'} children={children} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  margin: 0 -${props => props.theme['@size-l']};
  > .header {
    background: ${props => props.theme['@white']};
    border-top-right-radius: ${props => props.theme['@border-radius-large']};
    padding: 0 ${props => props.theme['@size-l']};
    z-index: 10;
    position: sticky;
    padding-top: calc(${props => props.theme['@size-l']} + ${props => props.theme['@size-m']});
    top: -${props => props.theme['@size-l']};
    margin-top: calc(-${props => props.theme['@size-l']} - ${props => props.theme['@size-m']});
  }
  > .content {
    flex: 1;
    overflow-y: auto;
    padding: ${props => props.theme['@size-l']};
  }
`
