import { ApolloClient } from '@apollo/client'
import { ApolloReactFeature } from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-web'
import { Input, Form, Button, message } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import UsersWebFeature from './UsersWebFeature'
import { authAndRedirect } from './authAndRedirect'
import { error, warn, debug } from './log'
import { useLoginMutation, UserFragment } from './schema'

const CLEAR_TIMEOUT_MS = 1000
export async function clearAndResetStore(client: ApolloClient<any>) {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const handle = setTimeout(() => {
        debug('clearing and resetting store timed out; continuing anyway')
        resolve()
      }, CLEAR_TIMEOUT_MS)

      debug('clearing store')
      await client.clearStore()
      try {
        debug('resetting store')
        await client.resetStore()
      } catch (err: any) {
        warn('error resetting store')
        warn(err)
      }

      clearTimeout(handle)
      resolve()
    } catch (err: any) {
      reject(err)
    }
  })
}

export interface LoginUserOpts {
  navigate: (route: string) => any
  usersFeature: UsersWebFeature
  apolloFeature: ApolloReactFeature
  jwt: string
  user: UserFragment
  initialPath?: string
}
export const loginUser = async ({
  navigate,
  usersFeature,
  apolloFeature,
  jwt,
  user,
  initialPath,
}: LoginUserOpts) => {
  const search = window.location.search
  localStorage.setItem(apolloFeature.authenticationKey, jwt)
  await clearAndResetStore(apolloFeature.client)
  message.success(
    <span>
      Welcome back <strong>{user.name || user.username}!</strong>
    </span>,
  )
  await authAndRedirect(navigate, usersFeature, apolloFeature, user, search, initialPath)
}

export interface Credentials {
  username: string
  password: string
}
export interface LoginFormProps {
  submitText?: string
  copy?: {
    usernameName?: string
    usernamePlaceholder?: string
    usernameRequired?: string
    passwordName?: string
    passwordPlaceholder?: string
    passwordRequired?: string
  }
  usernameIsEmail?: boolean
  modifyCredentials?: (credentials: Credentials) => Credentials
}
export const LoginForm: React.FC<React.PropsWithChildren<LoginFormProps>> = ({
  submitText = 'Login',
  copy = {},
  modifyCredentials,
  usernameIsEmail = true,
}) => {
  const {
    usernameName = 'Email Address',
    usernamePlaceholder = 'user@example.com',
    usernameRequired = 'An email address is required',
    passwordName = 'Password',
    passwordPlaceholder = '',
    passwordRequired = 'A password is required',
  } = copy
  const apolloFeature = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  const usersFeature = useFeature<UsersWebFeature>(UsersWebFeature.package)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [login, { loading }] = useLoginMutation({
    onCompleted: async data => {
      const { user, jwt, initialPath } = data.login
      await loginUser({
        user,
        jwt,
        apolloFeature,
        usersFeature,
        navigate,
        initialPath: initialPath || undefined,
      })
    },
    onError: err => {
      if (err.graphQLErrors[0]?.extensions?.code === 'INVALID_CREDENTIALS_ERROR') {
        message.error('Your password is invalid or your user could not be found.')
      } else {
        error('error logging in')
        error(err)
        message.error('There was an error logging you in.')
      }
    },
  })

  return (
    <Form
      hideRequiredMark
      form={form}
      layout={'vertical'}
      onFinish={values => {
        const modifiedValues = modifyCredentials ? modifyCredentials(values) : values
        login({
          variables: {
            username: modifiedValues.username,
            password: modifiedValues.password,
          },
        })
      }}
    >
      <Form.Item
        name={'username'}
        label={usernameName}
        rules={[{ required: true, message: usernameRequired }]}
      >
        <Input
          size={'large'}
          placeholder={usernamePlaceholder}
          type={usernameIsEmail ? 'email' : undefined}
          data-testid={'LoginUsername'}
        />
      </Form.Item>
      <Form.Item
        name={'password'}
        label={passwordName}
        rules={[{ required: true, message: passwordRequired }]}
      >
        <Input
          data-testid={'LoginPassword'}
          data-sentry-ignore
          size={'large'}
          type={'password'}
          placeholder={passwordPlaceholder}
        />
      </Form.Item>
      <Button
        loading={loading}
        block
        size={'large'}
        type={'primary'}
        children={submitText}
        htmlType={'submit'}
        data-testid={'LoginSubmit'}
      />
    </Form>
  )
}
