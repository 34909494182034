import { Div } from '@thesisedu/react'
import React from 'react'

import { useQuestionSettingsContext } from './settings/QuestionSettingsContext'
import { useQuestionResource } from './useQuestionResource'
import { useSubmissionContext } from '../contexts/SubmissionContext'
import { QuestionProps } from '../types'

export function Question(props: QuestionProps) {
  const resource = useQuestionResource(props.question.type)
  const { forcefullyShowAnswers, revealIncorrectAnswers } = useSubmissionContext(false) || {}
  const hasSettings = !!useQuestionSettingsContext(false)
  let QuestionComponent: React.FC<React.PropsWithChildren<QuestionProps>>
  const extraProps: Partial<QuestionProps> = {}
  if (hasSettings && resource.renderSettings) {
    QuestionComponent = resource.renderSettings
  } else if (props.answerView) {
    if (!resource.renderAnswerView) return null
    QuestionComponent = resource.renderAnswerView
  } else if (props.readOnly) {
    if ((forcefullyShowAnswers || revealIncorrectAnswers) && resource.renderAnswerView) {
      QuestionComponent = resource.renderAnswerView
      if (forcefullyShowAnswers) {
        extraProps.answerView = { showExplanation: true }
      } else if (revealIncorrectAnswers) {
        extraProps.answerView = { hideCorrectAnswer: true }
      }
    } else {
      QuestionComponent = resource.renderContent
    }
  } else {
    QuestionComponent = resource.renderEdit
  }

  return (
    <Div
      className={['widget-container', props.className].filter(Boolean).join(' ')}
      data-testid={`Question ${props.question.name}`}
    >
      <QuestionComponent {...props} {...extraProps} />
    </Div>
  )
}
