import { fromGlobalId } from '@thesisedu/feature-utils'

import { ReactFeature } from './ReactFeature'
import { ModelLinkResource } from './types'
import { useFeatureRoot } from './useFeatureRoot'

export interface ModelLinkOpts {
  id?: string
}
export function useModelLink({ id }: ModelLinkOpts) {
  const root = useFeatureRoot()
  const linkResources = root.deps.resources.getResourcesForType<ModelLinkResource>('ModelLink')
  if (id) {
    const { type } = fromGlobalId(id, true)
    return linkResources.find(r => r.identifier === type)?.path.replace(/\[id]/g, id)
  } else {
    return undefined
  }
}

export function addModelLink(feature: ReactFeature, graphqlType: string, path: string) {
  feature.resources.addResource<ModelLinkResource>({
    type: 'ModelLink',
    identifier: graphqlType,
    path,
  })
}
