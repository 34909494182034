import { PipMediaPlugin } from '@thesisedu/feature-media-react'
import { CreatePractice } from '@thesisedu/feature-practices-react'
import React from 'react'

import { useStudentLessonQuery } from '../schema'

export interface CreatePracticeWithLessonProps {
  lessonId?: string
}
export const CreatePracticeWithLesson: React.FC<
  React.PropsWithChildren<CreatePracticeWithLessonProps>
> = ({ lessonId }) => {
  const { data: lessonData } = useStudentLessonQuery({
    variables: { id: lessonId! },
    skip: !lessonId,
  })
  const lesson = lessonData?.node?.__typename === 'Lesson' ? lessonData.node : undefined

  return (
    <CreatePractice
      extraCreateInput={{
        lessonId,
      }}
    >
      {lesson?.media.id ? <PipMediaPlugin mediaId={lesson.media.id} title={'Lesson'} /> : null}
    </CreatePractice>
  )
}
