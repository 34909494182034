import React from 'react'

export function useFreshRef<T>(value: T) {
  const ref = React.useRef(value)
  // Update the value every time the component renders.
  ref.current = value
  return ref
}

export function useStateAndFreshRef<T>(
  defaultValue: T,
): [T, React.RefObject<T>, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState<T>(defaultValue)
  const ref = React.useRef<T>(defaultValue)
  return [
    state,
    ref,
    React.useCallback(setterOrValue => {
      setState(current => {
        let newValue
        if (typeof setterOrValue === 'function') {
          // FIXME this is because T could be a function.
          newValue = (setterOrValue as any)(current)
        } else {
          newValue = setterOrValue
        }
        ref.current = newValue
        return newValue
      })
    }, []),
  ]
}
