import { useResource } from '@thesisedu/feature-react'
import { H3Alternate, styled } from '@thesisedu/web'
import { groupBy } from 'lodash'
import React from 'react'

import { DistrictConfigurationFieldResource } from './types'

export interface DistrictConfigurationProps {
  fieldPrefix: string[]
  districtId?: string
}
export function DistrictConfiguration({ fieldPrefix, districtId }: DistrictConfigurationProps) {
  const fields = useResource<DistrictConfigurationFieldResource>('DistrictConfigurationField')
  const groups = groupBy(fields, 'group') as Record<string, DistrictConfigurationFieldResource[]>
  return (
    <>
      {Object.keys(groups).map(groupName => (
        <GroupContainer key={groupName || 'ungrouped'}>
          {groupName !== 'undefined' && groupName ? <H3Alternate>{groupName}</H3Alternate> : null}
          {groups[groupName].map(groupedField => {
            const Field = groupedField.renderField
            return (
              <Field
                key={groupedField.identifier}
                districtId={districtId}
                fieldPrefix={fieldPrefix}
              />
            )
          })}
        </GroupContainer>
      ))}
    </>
  )
}

const GroupContainer = styled.div`
  margin: ${props => props.theme['@size-m']} 0;
`
