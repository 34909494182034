import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface CourseViewsReactFeatureOptions {}

export class CourseViewsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-course-views-react'
  static path = ''
  static requires: string[] = []
  public options!: CourseViewsReactFeatureOptions

  public reactResources() {
    require('./hooks/routes/manageViews').default(this)
    require('./resources/jobs').default(this)
  }
}

export const courseViewsReactFeature: ReactUse = (opts: CourseViewsReactFeatureOptions) => [
  CourseViewsReactFeature,
  opts,
]
