import { Empty } from '@thesisedu/react'
import { Group } from '@thesisedu/react/icons'
import React from 'react'

import { InviteStudentModal } from './invite/InviteStudentModal'
import { useSelectedClass } from '../ClassContext'

export interface EmptyStudentsProps {
  addendum?: React.ReactElement
}
export function EmptyStudents({ addendum }: EmptyStudentsProps) {
  const [visible, setVisible] = React.useState(false)
  const classId = useSelectedClass(true).id
  return (
    <>
      <InviteStudentModal
        classId={visible ? classId : undefined}
        onClose={() => setVisible(false)}
      />
      <Empty
        title={
          <span>
            Add <strong>Students</strong> to get started.
          </span>
        }
        icon={<Group />}
        description={
          <>
            Invite students either through a connection with your LMS, or by adding them manually.
            {addendum ? <>&nbsp;{addendum}</> : null}
          </>
        }
        action={{
          onClick() {
            setVisible(true)
          },
          title: 'Add your first Students',
        }}
      />
    </>
  )
}
