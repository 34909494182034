import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { CloudDownload } from '@thesisedu/ui/icons'
import React from 'react'

import { PDFGenerationElement } from './PDFGenerationElement'
import { PDFGenerationSettingsModal } from './PDFGenerationSettingsModal'
import { PDFGenerationConfig } from './common'

export default {
  identifier: 'PDFGeneration',
  title: 'PDF Generation',
  icon: <CloudDownload />,
  weight: 43,
  group: SimpleWidget.ElementsGroup.Other,
  defaultConfig: { version: 1 },
  hidden({ group }) {
    return group !== 'ADMIN'
  },
  element: PDFGenerationElement,
  settingsModal: PDFGenerationSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<PDFGenerationConfig, 'PDFGeneration'>
