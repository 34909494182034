import { ReportMetricSummaryResource } from '@thesisedu/feature-reports-react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export default function (feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportMetricSummaryResource>({
    type: 'ReportMetricSummary',
    identifier: 'weighted',
    label: 'Weighted',
    associatedMetrics: ['grade'],
  })
}
