import { MenuOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { BodySmall, CalendarCard, CalendarCardRight, styled } from '@thesisedu/web'
import { Badge, Button, Dropdown, Tooltip } from 'antd'
import React, { useState } from 'react'

import { MediaPreviewProps } from '../types'
const OuterContainer = styled.div`
  padding: ${props => props.theme['@size-xs']};
  .ant-badge-dot {
    width: ${props => props.theme['@size-xs']};
    height: ${props => props.theme['@size-xs']};
  }
`
export const SmallMediaPreview: React.FC<React.PropsWithChildren<MediaPreviewProps>> = ({
  label,
  actions,
  onClick,
  useBasicActions,
  badgeProps,
  mediaNode,
  toolTipTitle,
}) => {
  const [overlayVisible, setOverlayVisible] = useState(false)
  const actionButton =
    actions && !useBasicActions ? (
      <Dropdown
        overlay={actions}
        placement={'bottomRight'}
        visible={overlayVisible}
        onVisibleChange={setOverlayVisible}
      >
        <Button type={'link'} icon={<MenuOutlined />} size={'small'} style={{ color: 'white' }} />
      </Dropdown>
    ) : null
  return (
    <OuterContainer>
      <Tooltip placement={'rightTop'} title={toolTipTitle} trigger={toolTipTitle ? 'hover' : ''}>
        <Badge {...badgeProps} dot={mediaNode.hasUnreadComments || !!badgeProps?.dot}>
          <CalendarCard
            onClick={() => {
              if (!overlayVisible && onClick) {
                onClick()
              }
            }}
          >
            <div>
              <BodySmall style={{ fontSize: '12px' }}>{label}</BodySmall>
            </div>
            <CalendarCardRight>{actionButton || <ArrowRightOutlined />}</CalendarCardRight>
          </CalendarCard>
        </Badge>
      </Tooltip>
    </OuterContainer>
  )
}
