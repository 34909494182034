import { CaretDownOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons'
import { ProgressModal, Modal, Body } from '@thesisedu/web'
import { Dropdown, Button, Menu, Form, Input } from 'antd'
import { chunk } from 'lodash'
import pluralize from 'pluralize'
import React from 'react'

import { useApproveCommentMutation } from '../queries/useApproveCommentMutation'
import { useRejectCommentMutation } from '../queries/useRejectCommentMutation'

const ACT_CHUNK_SIZE = 5
export interface CommentBulkActionsProps {
  selectedIds?: string[]
}
export function CommentBulkActions({ selectedIds }: CommentBulkActionsProps) {
  const [approve] = useApproveCommentMutation()
  const [reject] = useRejectCommentMutation()
  const [current, setCurrent] = React.useState(0)
  const [visible, setVisible] = React.useState(false)
  const [reasonVisible, setReasonVisible] = React.useState(false)
  const [form] = Form.useForm()
  const perform = async (act: (id: string) => Promise<any>) => {
    setVisible(true)
    setCurrent(0)
    try {
      const chunks = chunk(selectedIds, ACT_CHUNK_SIZE)
      for (let i = 0; i < chunks.length; i++) {
        const chunk = chunks[i]
        await Promise.all(
          chunk.map(async id => {
            setCurrent(c => c + 1)
            await act(id)
          }),
        )
      }
    } catch {
      setVisible(false)
      return
    }
    setTimeout(() => {
      setVisible(false)
    }, 1000)
  }
  return (
    <>
      <ProgressModal
        visible={visible}
        current={current}
        total={selectedIds?.length || 0}
        buildLeftLabel={() => 'Acting on comments...'}
        buildRightLabel={(current, total) =>
          current === total ? 'Complete' : `${current + 1} / ${total} complete`
        }
      />
      <Modal
        title={'Reject Comments'}
        visible={reasonVisible}
        onCancel={() => setReasonVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setReasonVisible(false)}>
            Cancel
          </Button>,
          <Button type={'primary'} danger onClick={form.submit}>
            Reject {pluralize('Comment', selectedIds?.length || 0, true)}
          </Button>,
        ]}
      >
        <Body isBlock>You are rejecting several comments.</Body>
        <Form
          layout={'vertical'}
          form={form}
          onFinish={async ({ reason }) => {
            setReasonVisible(false)
            await perform(async id => {
              await reject({
                variables: {
                  input: { id, reason },
                },
              })
            })
          }}
        >
          <Form.Item
            name={'reason'}
            label={'What is your reason?'}
            rules={[{ required: true, message: 'A reason is required.' }]}
          >
            <Input.TextArea
              placeholder={'This comment violates our comment guidelines.'}
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Dropdown
        overlay={
          <Menu
            onClick={async ({ key }) => {
              if (selectedIds?.length) {
                if (key === 'approve') {
                  await perform(async id => approve({ variables: { input: { id } } }))
                } else if (key === 'reject') {
                  setReasonVisible(true)
                }
              }
            }}
          >
            <Menu.Item key={'approve'}>
              <LikeOutlined /> Approve
            </Menu.Item>
            <Menu.Item key={'reject'}>
              <DislikeOutlined /> Reject
            </Menu.Item>
          </Menu>
        }
        disabled={!selectedIds?.length}
      >
        <Button>
          Bulk Actions <CaretDownOutlined />
        </Button>
      </Dropdown>
    </>
  )
}
