import React from 'react'

import { useOutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentIndicatorProps, SegmentIndicatorResource } from '../../outline/SegmentIndicators'
import { SegmentLock } from '../../outline/SegmentLock'

function LockIndicator({ segmentId }: SegmentIndicatorProps) {
  const { lockedSegmentIds, setSegmentLock } = useOutlineSegmentContext()
  const isLocked = lockedSegmentIds.includes(segmentId)
  return isLocked ? (
    <SegmentLock setSegmentLock={setSegmentLock} segmentId={segmentId} isLocked={isLocked} />
  ) : null
}

export const Lock: SegmentIndicatorResource = {
  type: 'SegmentIndicator',
  identifier: 'Lock',
  component: LockIndicator,
  weight: 100,
}
