import { VSpaced } from '@thesisedu/ui'
import React from 'react'

import { CoverPageSettings } from '../../../pdf_generation/CoverPageSettings'

export function PDFGenerationSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <CoverPageSettings prefix={'coverPage'} />
    </VSpaced>
  )
}
