import { ClassSelect } from '@thesisedu/feature-classes-react'
import { UserSelect } from '@thesisedu/feature-users-web'
import { Row, StyledThemeContext as ThemeContext } from '@thesisedu/web'
import { Col, DatePicker, Input, Checkbox } from 'antd'
import Form, { FormInstance, FormProps } from 'antd/es/form'
import React, { useContext } from 'react'

import { LicenseSelect } from './LicenseSelect'
import { SubscriptionSizeSelect } from './SubscriptionSizeSelect'
import { SubscriptionTypeSelect } from './SubscriptionTypeSelect'

export interface SubscriptionFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
  showUserClass?: boolean
}
export const SubscriptionForm: React.FC<React.PropsWithChildren<SubscriptionFormProps>> = ({
  form,
  onFinish,
  showUserClass,
}) => {
  const theme = useContext(ThemeContext)
  return (
    <Form form={form} onFinish={onFinish} layout={'vertical'}>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            name={'type'}
            label={'Type'}
            rules={[{ required: true, message: 'A type is required.' }]}
          >
            <SubscriptionTypeSelect />
          </Form.Item>
          <Form.Item name={'size'} label={'Size'}>
            <SubscriptionSizeSelect allowClear />
          </Form.Item>
          {showUserClass ? (
            <>
              <Form.Item name={'user'} label={'User'}>
                <UserSelect />
              </Form.Item>
              <Form.Item name={'class'} label={'Class'}>
                <ClassSelect />
              </Form.Item>
              <Form.Item name={'license'} label={'License'}>
                <LicenseSelect />
              </Form.Item>
            </>
          ) : null}
        </Col>
        <Col xs={24} md={12}>
          <Form.Item name={'expiresAt'} label={'Expiration'}>
            <DatePicker allowClear />
          </Form.Item>
          <Form.Item name={'isValid'} label={'Is Valid'} valuePropName={'checked'}>
            <Checkbox>Is Valid</Checkbox>
          </Form.Item>
          <Form.Item name={'nextValidityCheck'} label={'Next Validity Check'}>
            <DatePicker allowClear />
          </Form.Item>
          <Form.Item
            name={'subscriptionId'}
            label={'Subscription ID'}
            extra={
              'This is not allowed to be empty. You can type some dummy-text in here if you would like.'
            }
          >
            <Input style={{ fontFamily: theme['@code-family'] }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
