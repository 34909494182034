export * from './Modal'
export * from './Footer'
export * as Split from './SplitModal'
export { default as SplitModal } from './SplitModal'
import { ConfirmModal } from './ConfirmModal'
import { ModalFooter, ModalCloseButton } from './Footer'
import { Modal } from './Modal'
import { useConfirmModal } from './useConfirmModal'
import { withSubComponents } from '../../utils/withSubComponents'

const Convenience = withSubComponents(Modal, {
  Footer: ModalFooter,
  CloseButton: ModalCloseButton,
  Confirm: ConfirmModal,
  useConfirmModal,
})

export default Convenience
