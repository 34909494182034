import { useMutateHook } from '@thesisedu/feature-react'
import { useProtection } from '@thesisedu/feature-users-web'
import { Shell, NotFoundPage } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { Home, User } from '@thesisedu/react/icons'
import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { AdminDashboard } from './AdminDashboard'
import { AdminUsers } from './AdminUsers'
import { UserRoutesHook, UserLeftItemsHook } from '../types'
import { useViewerContext } from '../useViewerContext'
import { MyContent } from '../web/MyContent'
import { UserSettings } from '../web/UserSettings'

export function Admin() {
  useProtection({ allowedGroups: ['ADMIN'], redirect: '/' })
  const viewer = useViewerContext(false)
  useBreadcrumb({ title: <AntIconWrapper children={<Home />} /> })
  const leftItems = useMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    [
      {
        group: 'top',
        weight: 2,
        element: (
          <Shell.LeftMenuItem
            key={'users'}
            name={'Users'}
            icon={<AntIconWrapper children={<User />} />}
            path={'/admin/users'}
          />
        ),
      },
    ],
    { viewer, group: 'ADMIN' },
  )
  const routes = useMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    [
      <Route key={'home'} path={'/'} element={<Navigate to={'dashboard'} />} />,
      <Route key={'/dashboard'} path={'/dashboard'} element={<AdminDashboard />} />,
      <Route key={'/users/*'} path={'/users/*'} element={<AdminUsers />} />,
      <Route key={'/my-content/*'} path={'/my-content/*'} element={<MyContent />} />,
      <Route key={'/settings/*'} path={'/settings/*'} element={<UserSettings />} />,
    ],
    { viewer, group: 'ADMIN' },
  )
  if (!viewer) return null
  return (
    <Shell.Shell
      topItems={[
        {
          weight: 0,
          element: <Shell.TopMenuItem key={'admin'} name={'Administration'} path={'/admin'} />,
        },
        {
          weight: 1,
          element: (
            <Shell.TopMenuItem key={'my-content'} name={'My Content'} path={'/admin/my-content'} />
          ),
        },
      ]}
      leftItems={leftItems}
    >
      <Routes>
        {routes}
        <Route key={'404'} path={'*'} element={<NotFoundPage />} />
      </Routes>
    </Shell.Shell>
  )
}
