import { Input } from 'antd'
import { InputProps } from 'antd/es/input'
import React from 'react'
import ReactDateTimePicker, { DatetimepickerProps } from 'react-datetime'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { StyledPopover } from './AntStyled'

export interface DateTimePickerProps extends Omit<DatetimepickerProps, 'inputProps'> {
  inputProps?: Partial<InputProps>
  disabled?: boolean
}
export function DateTimePicker({
  inputProps,
  disabled,
  closeOnSelect,
  ...props
}: DateTimePickerProps) {
  require('react-datetime/css/react-datetime.css')
  const [open, setOpen] = React.useState(false)
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  const canClose = React.useRef(false)
  React.useEffect(() => {
    if (open) {
      canClose.current = false
      const handle = setTimeout(() => {
        canClose.current = true
      }, 500)
      return () => clearTimeout(handle)
    } else {
      canClose.current = false
    }
  }, [open])
  return (
    <Pop
      content={
        <PopoverPicker
          className={'rdtPicker'}
          ref={c => setContainer(c)}
          onClickCapture={() => {
            /**
             * This is some fun BS; because we're rendering the contents in a portal (which we have to do
             * so the date picker appears in a different layer and can therefore appear above the Tour mask
             * like all of the other Ant overlays), the click events inside here are triggering the Ant
             * popover to close. So we need to (upon capturing a click event) tell Ant that it actually can't
             * close the overlay, and then reset that an instant later.
             */
            canClose.current = false
            requestAnimationFrame(() => {
              canClose.current = true
            })
          }}
        />
      }
      visible={open && !disabled}
      onVisibleChange={v => {
        if (canClose.current && !v) {
          setOpen(v)
        } else if (v) {
          setOpen(v)
        }
      }}
      trigger={['click']}
    >
      <Wrapper>
        <ReactDateTimePicker
          renderInput={p => <Input {...inputProps} {...p} onFocus={() => setOpen(true)} />}
          {...props}
          onChange={(...args) => {
            if (props.onChange) {
              props.onChange(...args)
            }
            if (closeOnSelect) {
              setOpen(false)
            }
          }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderView={(mode, renderDefault) => {
            if (container) {
              return createPortal(renderDefault(), container)
            } else {
              return <></>
            }
          }}
        />
      </Wrapper>
    </Pop>
  )
}

const Pop = styled(StyledPopover)`
  .ant-popover-inner-content {
    padding: ${props => props.theme['@size-xxs']};
  }
`
const PopoverPicker = styled.div`
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  margin-top: 0 !important;
  position: static !important;
  display: block !important;
`
const Wrapper = styled.div`
  align-self: flex-start;
  .rdtPicker {
    box-shadow: none;
    border: none;
    padding: 0;
    margin-top: 0;
  }
`
