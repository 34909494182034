import { useApolloClient, useModifiedQueryDocument } from '@thesisedu/feature-apollo-react/apollo'
import { RequireFields } from '@thesisedu/feature-types'
import React from 'react'

import {
  StudentClassDocument,
  StudentClassQuery,
  TeacherClassDocument,
  UserFragment,
  ClassStudentsAndTeachersDocument,
  ClassStudentsAndTeachersQuery,
  StudentClassQueryVariables,
  TeacherClassQueryVariables,
  ClassStudentsAndTeachersQueryVariables,
  TeacherClassQuery,
  ClassStudentEdgeFragment,
} from './schema'
import { useTeacherOrStudentClass } from './useClass'

export interface Student {
  id: string
  user: UserFragment
  edge?: Partial<Omit<ClassStudentEdgeFragment, 'node'>>
}

export interface StudentUserResult {
  student: Student | null
  error?: boolean
  loading?: boolean
}
export function useStudentUser(
  studentId: string,
  classId: string,
  skip = false,
): StudentUserResult {
  const { cls, error, loading } = useTeacherOrStudentClass(skip ? null : classId, false)
  const edge = cls?.students.edges.find(edge => edge.node.id === studentId)
  return {
    student: edge ? fromEdge(edge as ClassStudentEdgeFragment) : null,
    error,
    loading,
  }
}

function fromEdge({ node, ...restEdge }: RequireFields<ClassStudentEdgeFragment, 'node'>): Student {
  return { edge: restEdge, ...node }
}

export type CachedStudentUserCallback = (
  studentId: string,
  classId: string,
) => StudentUserResult['student']
export function useCachedStudentUserCallback(): CachedStudentUserCallback {
  const client = useApolloClient()
  const modified = {
    student: useModifiedQueryDocument(StudentClassDocument),
    teacher: useModifiedQueryDocument(TeacherClassDocument),
    studentsAndTeachers: useModifiedQueryDocument(ClassStudentsAndTeachersDocument),
  }
  return React.useCallback((studentId, classId) => {
    const studentClassQuery = client.cache.readQuery<StudentClassQuery, StudentClassQueryVariables>(
      {
        query: modified.student,
        variables: { id: classId },
      },
    )
    const studentClassResult =
      studentClassQuery?.node?.__typename === 'Class'
        ? studentClassQuery.node.students.edges.find(edge => edge.node.id === studentId)
        : undefined
    if (studentClassResult) return fromEdge(studentClassResult as ClassStudentEdgeFragment)

    const teacherClassQuery = client.cache.readQuery<TeacherClassQuery, TeacherClassQueryVariables>(
      {
        query: modified.teacher,
        variables: { id: classId },
      },
    )
    const teacherClassResult =
      teacherClassQuery?.node?.__typename === 'Class'
        ? teacherClassQuery.node.students.edges.find(edge => edge.node.id === studentId)
        : undefined
    if (teacherClassResult) return fromEdge(teacherClassResult)

    const allQuery = client.cache.readQuery<
      ClassStudentsAndTeachersQuery,
      ClassStudentsAndTeachersQueryVariables
    >({
      query: modified.studentsAndTeachers,
      variables: { id: classId },
    })
    const allResult =
      allQuery?.node?.__typename === 'Class'
        ? allQuery.node.students.edges.find(edge => edge.node.id === studentId)
        : undefined
    if (allResult) return fromEdge(allResult)

    return null
  }, [])
}
