import { SegmentName } from '@thesisedu/feature-courses-react/dist/segment/SegmentName'
import { Block, Body, H2, HSpaced, styled } from '@thesisedu/web'
import React from 'react'

import { useExploreStandardSetsContext } from './ExploreStandardSetsContext'
import { SegmentTree } from './StandardSetGridGroup'
import { MinimalSegmentFragment } from '../schema'

interface TreeWithDepth extends SegmentTree {
  depth: number
}
function _flattenItems(tree: SegmentTree, depth = 0): TreeWithDepth[] {
  let result: TreeWithDepth[] = [{ ...tree, depth }]
  for (const child of tree.children || []) {
    result = [...result, ..._flattenItems(child, depth + 1)]
  }

  return result
}

function _flattenList(trees: SegmentTree[]): TreeWithDepth[] {
  return trees.reduce<TreeWithDepth[]>((acc, tree) => {
    return [...acc, ..._flattenItems(tree)]
  }, [])
}

export interface StandardSetListGroupProps {
  treeItem: SegmentTree
  scrollIntoView?: boolean
}
export function StandardSetListGroup({ treeItem, scrollIntoView }: StandardSetListGroupProps) {
  const flat = _flattenList(treeItem.children || [])
  const { segments, openSegment } = useExploreStandardSetsContext(true)
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (containerRef.current && scrollIntoView) {
      containerRef.current.scrollIntoView()
    }
  }, [scrollIntoView])
  return (
    <Block marginBottom={'@size-l'} ref={containerRef}>
      <H2>{treeItem.tag.name}</H2>
      {flat.map(item => {
        const spacers = new Array(item.depth).fill('').map((_, index) => <Spacer key={index} />)
        const itemSegments = item.segmentIds
          .map(segmentId => {
            return segments.find(s => s.id === segmentId)
          })
          .filter(Boolean) as MinimalSegmentFragment[]
        return (
          <div key={item.tag.id}>
            <ListItem align={'flex-start'}>
              {spacers}
              <Body>{item.tag.name}</Body>
            </ListItem>
            {itemSegments.map(itemSegment => {
              const spacers = new Array(item.depth + 1)
                .fill('')
                .map((_, index) => <Spacer key={index} />)
              return (
                <ListItem align={'flex-start'}>
                  {spacers}
                  <SegmentName
                    segment={{
                      ...itemSegment,
                      config: itemSegment.basicConfig,
                    }}
                    onClick={() => openSegment(itemSegment.id)}
                  />
                </ListItem>
              )
            })}
          </div>
        )
      })}
    </Block>
  )
}

const ListItem = styled(HSpaced)`
  margin: ${props => props.theme['@size-xs']} 0;
`
const Spacer = styled.span`
  display: block;
  height: 2px;
  width: ${props => props.theme['@size-s']};
  margin-right: ${props => props.theme['@size-xxs']};
  margin-top: calc(${props => props.theme['@font-size-base']} * 0.75);
  background: ${props => props.theme['@gray-3']};
  flex-shrink: 0;
`
