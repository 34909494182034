import { UserOutlined } from '@ant-design/icons'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Body } from '@thesisedu/web'
import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import { useRequestResetPasswordMutation } from './schema'

export const RequestResetPasswordForm: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [sent, setSent] = useState(false)
  const [requestReset, { loading }] = useRequestResetPasswordMutation({
    onError: onMutationError('There was an error requesting your password reset.'),
    onCompleted: () => {
      setSent(true)
    },
  })
  const [form] = Form.useForm()
  useEffect(() => {
    try {
      const params = new URLSearchParams(window.location.search)
      if (params.has('email')) {
        form.setFieldsValue({ usernameOrEmail: params.get('email') })
      }
    } catch {
      // Swallow...
    }
  }, [])
  if (sent) {
    return (
      <Body>
        Your password reset instructions have been sent to your email on file. Please check your
        spam folders to make sure you get the instructions. Thanks!
      </Body>
    )
  } else {
    return (
      <Form
        layout={'vertical'}
        hideRequiredMark
        form={form}
        onFinish={values => {
          requestReset({
            variables: {
              input: {
                usernameOrEmail: values.usernameOrEmail,
              },
            },
          })
        }}
      >
        <Form.Item
          name={'usernameOrEmail'}
          rules={[{ required: true, message: 'A username or email address is required.' }]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={'Username or Email Address'}
            size={'large'}
            type={'email'}
          />
        </Form.Item>
        <Button block type={'primary'} size={'large'} onClick={form.submit} loading={loading}>
          Request Password Reset
        </Button>
      </Form>
    )
  }
}
