import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'

export const useDeleteRubricTemplateMutation: typeof useDeleteNodeMutation = opts => {
  const [mutate, rest] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that rubric template.',
  })

  return [
    mutateOpts =>
      mutate({
        ...mutateOpts,
        update: proxy => {
          const templateId = mutateOpts?.variables?.id
          if (templateId) {
            proxy.evict({
              id: `RubricTemplate:${templateId}`,
            })
            proxy.gc()
          }
        },
      }),
    rest,
  ]
}
