import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Body, styled } from '@thesisedu/web'
import React from 'react'

const Container = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  .right {
    margin-left: auto;
  }
`

export interface CourseEditorHeaderProps {
  loading?: boolean
  error?: boolean
  help?: string
}
export function CourseEditorHeader({
  loading,
  error,
  help = 'Drag segments around below, or create new segments at the bottom.',
}: CourseEditorHeaderProps) {
  let status = <span>&nbsp;</span>
  if (loading) {
    status = <Body color={'@text-color-secondary'}>Saving</Body>
  } else if (error) {
    status = (
      <Body color={'@red'}>
        <ExclamationCircleOutlined /> Error saving
      </Body>
    )
  }
  return (
    <Container>
      <Body color={'@text-color-secondary'}>{help}</Body>
      <div className={'right'}>{status}</div>
    </Container>
  )
}
