import { WebContext } from '@thesisedu/feature-web'
import moment from 'moment'
import React, { useContext } from 'react'

import { InteractionFragment } from './schema'
import { InteractionTypeResource } from './types'

export interface InteractionListProps {
  interactions: InteractionFragment[]
  unreadAfterDate?: string
}
export function InteractionList({ interactions, unreadAfterDate }: InteractionListProps) {
  const { featureWeb } = useContext(WebContext)!
  const resources =
    featureWeb.deps.resources.getResourcesForType<InteractionTypeResource>('InteractionType')

  const children = interactions
    .map(interaction => {
      const renderer = resources.find(rsc => rsc.identifier === interaction.type)
      const isUnread = unreadAfterDate
        ? moment(interaction.periodStart).isSameOrAfter(unreadAfterDate)
        : false
      if (renderer) {
        return <renderer.handler interaction={{ ...interaction, isUnread }} key={interaction.id} />
      } else {
        return null
      }
    })
    .filter(Boolean)

  return <>{children}</>
}
