import { AxesResponsibility, isMetrics } from './axes'

const LEFT_COL_BASE_WIDTH = 300
const LEFT_COL_STATS_WIDTH = 100
const FIRST_ROW_BASE_HEIGHT = 60
const FIRST_ROW_STATS_HEIGHT = 40
const DEFAULT_COL_WIDTH = 90
const METRIC_COL_WIDTH = 150
const DEFAULT_ROW_HEIGHT = 40

export interface GridSizes {
  firstRowHeight: number
  firstColumnWidth: number
  rowHeight: number
  columnWidth: number
  rowHeightFn: (index: number) => number
  columnWidthFn: (index: number) => number
}
export function getGridSizes({ row, col }: AxesResponsibility): GridSizes {
  const leftColumnIncludesStats = !isMetrics(col)
  const firstColumnWidth =
    LEFT_COL_BASE_WIDTH + (leftColumnIncludesStats ? LEFT_COL_STATS_WIDTH : 0)
  const firstRowIncludesStats = !isMetrics(row)
  const firstRowHeight =
    FIRST_ROW_BASE_HEIGHT + (firstRowIncludesStats ? FIRST_ROW_STATS_HEIGHT : 0)
  const rowHeight = DEFAULT_ROW_HEIGHT
  const columnWidth = isMetrics(col) ? METRIC_COL_WIDTH : col.columnWidth || DEFAULT_COL_WIDTH

  return {
    firstRowHeight,
    firstColumnWidth,
    rowHeight,
    rowHeightFn: index => (index === 0 ? firstRowHeight : rowHeight),
    columnWidth,
    columnWidthFn: index => (index === 0 ? firstColumnWidth : columnWidth),
  }
}
