import { styled } from '@thesisedu/web'
import React from 'react'

import { SavedReportsList, SavedReportsListProps, ScrollContainer } from './SavedReportsList'

export interface LargeSavedReportsListProps extends SavedReportsListProps {}
export function LargeSavedReportsList(props: LargeSavedReportsListProps) {
  return (
    <Container>
      <SavedReportsList {...props} />
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => props.theme['@gray-0']};
  padding: ${props => props.theme['@size-l']};
  width: 100%;
  max-width: 980px;
  margin: 0 auto;
  ${ScrollContainer} {
    margin-left: -${props => props.theme['@size-l']};
    margin-right: -${props => props.theme['@size-l']};
    padding-left: ${props => props.theme['@size-l']};
    padding-right: ${props => props.theme['@size-l']};
  }
`
