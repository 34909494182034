import { FeatureUse } from '@thesisedu/feature'
import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { ReactFeature, ReactFeatureDependencies } from '@thesisedu/feature-react'

export class ClassSyncReactFeature extends ReactFeature {
  public static package = '@thesisedu/feature-class-sync-react'
  static path = ''
  static requires: string[] = []

  reactResources() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'MinimalClass', fragment => {
      return addField(fragment, 'canCreateClassSync')
    })

    require('./resources/import').default(this)
    require('./hooks/expectedError').default(this)
    require('./hooks/approval').default(this)
    require('./hooks/students/pageActions').default(this)
    require('./hooks/students/header').default(this)
    require('./hooks/students/columns').default(this)
    require('./resources/background_job_reporters').default(this)
    require('./resources/ClassTeacherPermission').default(this)
    require('./resources/SiteConfiguration/classSyncProviders').default(this)
  }

  public getDevToolsInstaller() {
    return import('./devtools').then(tools => tools.install)
  }
}

export const classSyncReactFeature = (
  opts: object = {},
): FeatureUse<object, ReactFeatureDependencies> => [ClassSyncReactFeature, opts]
