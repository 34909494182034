import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { QuickSearch } from './QuickSearch'

interface QuickSearchContextValue {
  showQuickSearch: () => void
}
export const QuickSearchContext = React.createContext<QuickSearchContextValue>({
  showQuickSearch: () => {},
})

export function QuickSearchProvider({ children }: React.PropsWithChildren<object>) {
  const [visible, setVisible] = React.useState(false)
  useHotkeys('ctrl+k, cmd+k', () => {
    setVisible(true)
  })
  return (
    <QuickSearchContext.Provider value={{ showQuickSearch: () => setVisible(true) }}>
      <QuickSearch visible={visible} onClose={() => setVisible(false)} />
      {children}
    </QuickSearchContext.Provider>
  )
}

export function useQuickSearchContext(): QuickSearchContextValue | undefined
export function useQuickSearchContext(require: false): QuickSearchContextValue | undefined
export function useQuickSearchContext(require: true): QuickSearchContextValue
export function useQuickSearchContext(require?: boolean): QuickSearchContextValue | undefined {
  const context = React.useContext(QuickSearchContext)
  if (!context && require) {
    throw new Error('QuickSearchContext is required, yet not provided.')
  }
  return context
}
