import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface TagsReactFeatureOptions {}

export class TagsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-tags-react'
  static path = ''
  static requires: string[] = []
  public options!: TagsReactFeatureOptions

  public reactResources() {
    require('./hooks/routes').default(this)
  }
}

export const tagsReactFeature: ReactUse = (opts: TagsReactFeatureOptions) => [
  TagsReactFeature,
  opts,
]
