import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { styled } from '@thesisedu/web'
import { Dropdown, Menu, Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import Case from 'case'
import { groupBy } from 'lodash'
import React from 'react'

import { useQuestionResources } from '../questions/useQuestionResource'

const Container = styled.div``

export interface AddQuestionButtonProps extends Partial<ButtonProps> {
  onAdd: (questionType: string) => void
  noText?: boolean
}
export function AddQuestionButton({ onAdd, noText, ...buttonProps }: AddQuestionButtonProps) {
  const viewer = useViewerContext(false)
  const resources = useQuestionResources()
  let content: React.ReactElement
  const availableTypes = (
    viewer?.group === 'TEACHER' ? resources.filter(r => !r.hiddenForTeachers) : resources
  ).map(r => r.identifier)
  if (availableTypes.length === 1) {
    const availableType = availableTypes[0]
    const questionType = resources.find(r => r.identifier === availableType)
    if (!questionType) throw new Error(`Question type '${questionType}' is invalid.`)
    content = (
      <Button
        icon={questionType.icon ? <questionType.icon /> : <PlusOutlined />}
        {...buttonProps}
        onClick={() => onAdd(availableType)}
      >
        {noText ? null : <span>Add {Case.title(availableType)}</span>}
      </Button>
    )
  } else {
    const items = availableTypes.map(type => {
      const questionType = resources.find(r => r.identifier === type)
      if (!questionType) throw new Error(`Question type '${questionType}' is invalid.`)
      return {
        icon: questionType.icon || PlusOutlined,
        label: questionType.label || Case.title(type),
        type,
        group: questionType.group || 'Ungrouped',
      }
    })
    const groupedItems = groupBy(items, 'group')
    const menuItems = Object.keys(groupedItems).reduce<React.ReactElement[]>((acc, key, index) => {
      const groupItems = groupedItems[key]
      return [
        ...acc,
        ...(index !== 0 ? [<Menu.Divider key={`divider-${key}`} />] : []),
        ...groupItems.map(item => (
          <Menu.Item key={item.type}>
            <item.icon /> {item.label}
          </Menu.Item>
        )),
      ]
    }, [])
    content = (
      <Dropdown
        arrow
        placement={'bottomLeft'}
        overlay={
          <Menu
            onClick={({ key }) => {
              onAdd(key.toString())
            }}
          >
            {menuItems}
          </Menu>
        }
        trigger={['click']}
      >
        <Button icon={<PlusOutlined />} type={'primary'} {...buttonProps}>
          {noText ? null : (
            <span>
              Add Question <CaretDownOutlined />
            </span>
          )}
        </Button>
      </Dropdown>
    )
  }

  return <Container>{content}</Container>
}
