import { Form } from 'antd'
import React from 'react'

import { BadgeIDsSelect } from '../admin/BadgeSelect'
import { BadgeEventTypeProps } from '../types'

export function BadgeBadgeEventConfig({ prefix }: BadgeEventTypeProps) {
  return (
    <Form.Item
      name={[prefix, 'badgeIds']}
      label={'Badges'}
      extra={'Select the other badges required before earning this one.'}
      rules={[{ required: true, message: 'You must select some badges.' }]}
    >
      <BadgeIDsSelect />
    </Form.Item>
  )
}
