import { useMutateHook } from '@thesisedu/feature-react'
import { TagDescriptions } from '@thesisedu/feature-tags-react'
import { ContentWrapper } from '@thesisedu/feature-widgets-react'
import { H2 } from '@thesisedu/web'
import React from 'react'

import { MutateSegmentStandardsHeader, Segment } from '../types'
import { useTagTypes } from '../useOptions'

const STANDARD_TAG = 'STANDARD'

export interface SegmentStandardsProps {
  segment: Segment
}
export function SegmentStandards({ segment }: SegmentStandardsProps) {
  const header = useMutateHook<MutateSegmentStandardsHeader>(
    'feature-courses-react:segment-standards-header',
    [],
    { segment },
  )
  const TAG_TYPES = useTagTypes()
  const { [STANDARD_TAG]: standardTags, ...otherTags } = segment.tags || {}
  return (
    <>
      {header}
      {standardTags?.length ? (
        <ContentWrapper>
          <H2>Standards</H2>
          <ul>
            {standardTags.map(standardTag => (
              <li key={standardTag}>{standardTag}</li>
            ))}
          </ul>
          <H2>Additional Information</H2>
        </ContentWrapper>
      ) : null}
      <TagDescriptions
        types={TAG_TYPES}
        tagSummary={otherTags}
        style={{ marginBottom: 30 }}
        title={''}
      />
    </>
  )
}
