import { packOpts } from '@thesisedu/feature-reports-react/dist/reporting/config/storage'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export function StudentReportRedirect() {
  const { classId, studentId } = useParams()
  const navigate = useNavigate()
  React.useEffect(() => {
    packOpts({
      dimensions: [
        {
          identifier: 'OneStudent',
          options: {
            studentPairs: [{ classId, studentId }],
          },
          context: { fullClassNames: [], firstName: 'Student' },
        },
        { identifier: 'Lessons', options: {}, context: {} },
      ],
      metrics: [],
    })
      .then(packed => {
        navigate(`../../${packed}`, { replace: true })
      })
      .catch(err => {
        console.error('error navigating to student')
        console.error(err)
        navigate('../..', { replace: true })
      })
  }, [])

  return null
}
