import {
  MoveStudentErrorResource,
  MoveStudentErrorMessageProps,
  MoveStudentClassError,
} from '@thesisedu/feature-classes-react'
import { getSegmentName } from '@thesisedu/feature-course-types'
import { useSegmentCache } from '@thesisedu/feature-courses-react'
import { Body, BodySmall } from '@thesisedu/react'
import React from 'react'

import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'

interface SegmentIdsNotPresentInTargetClassError extends MoveStudentClassError {
  type: 'SegmentIdsNotPresentInTargetClass'
  segmentIds: string[]
}

function MessageComponent({
  error,
  toClass,
  studentUser,
}: MoveStudentErrorMessageProps<SegmentIdsNotPresentInTargetClassError>) {
  const segmentCache = useSegmentCache()
  const segments = error.segmentIds.map(segmentId => segmentCache[segmentId]).filter(Boolean)
  return (
    <>
      <Body isBlock>
        The following content is not enabled in <strong>{toClass.name}.</strong>
      </Body>
      <ul>
        {segments.map(segment => {
          return (
            <li key={segment.id}>
              <BodySmall useDiv>{getSegmentName(segment)}</BodySmall>
            </li>
          )
        })}
      </ul>
      <Body isBlock>
        If you continue without enabling this content in {toClass.name},{' '}
        {studentUser.name || studentUser.username} will lose their submissions for these
        assignments.
      </Body>
      <Body>
        <strong>
          To fix this, don't move the student yet. First, enable the content above in {toClass.name}
          , and then try to move the student again.
        </strong>
      </Body>
    </>
  )
}

export default function (feature: CourseAssignmentsReactFeature) {
  feature.resources.addResource<MoveStudentErrorResource<SegmentIdsNotPresentInTargetClassError>>({
    type: 'MoveStudentError',
    identifier: 'SegmentIdsNotPresentInTargetClass',
    messageComponent: MessageComponent,
  })
}
