import { AxesResponsibility, isMetrics } from './axes'
import { getDimensionResults } from './dimensions'
import { SummaryCells } from './summary'
import { RunReportQuery } from '../../execute/types'

export interface GridExtents {
  numRows: number
  numColumns: number
}
export function getGridExtents(
  data: RunReportQuery['runReport'],
  responsibilities: AxesResponsibility,
  summaryResult?: SummaryCells,
): GridExtents {
  let numRows
  const dimensionResults = getDimensionResults(data)
  if (isMetrics(responsibilities.row)) {
    numRows = responsibilities.row.length
  } else if (summaryResult) {
    numRows = summaryResult.flatRows.length
  } else {
    numRows = dimensionResults.length
  }
  let numColumns
  if (isMetrics(responsibilities.col)) {
    numColumns = responsibilities.col.length
  } else {
    numColumns = dimensionResults.length
  }

  return {
    numRows,
    numColumns,
  }
}
