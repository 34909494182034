import moment from 'moment'

import {
  AssignmentSubmissionsFragment,
  BasicAssignmentFragment,
  BasicAssignmentSubmissionFragment,
  FullAssignmentSubmissionFragment,
} from './schema'
import { AssignmentCellMode } from './table/types'

export function getAssignmentCellMode(
  assignment: Pick<BasicAssignmentFragment, 'dueAt'>,
  submission?: BasicAssignmentSubmissionFragment,
): AssignmentCellMode {
  if (submission?.gradedAt) {
    return AssignmentCellMode.Graded
  } else if (submission?.submittedAt) {
    return AssignmentCellMode.Ungraded
  } else if (submission) {
    return AssignmentCellMode.Started
  } else if (assignment.dueAt && moment(assignment.dueAt).isBefore(moment())) {
    return AssignmentCellMode.Missing
  } else {
    return AssignmentCellMode.NotStarted
  }
}

/**
 * Guesses at the class ID for an assignment. In most cases, this should work fine.
 *
 * @param assignment the assignment to guess the class ID for
 * @returns the class id, if one was found
 */
export function getBestGuessClassId(assignment: BasicAssignmentFragment): string | null {
  return assignment.assignmentCategories.edges[0]?.node.classId || null
}

/**
 * Gets a valid assignment submission (usually just the first), given an assignment
 * with submissions already loaded.
 *
 * This is meant to be called from a student account. Otherwise, it'll just pull any
 * random submission which might not be the one you're looking for.
 *
 * @param assignment the assignment
 * @param classId the class ID to fetch the submission for
 * @returns the first submission (likely the students)
 */
export function getAssignmentSubmission(
  assignment: AssignmentSubmissionsFragment,
  classId: string,
): FullAssignmentSubmissionFragment | null {
  return (
    (assignment.currentSubmissions || assignment.submissions).edges.find(
      edge => edge.node.classId === classId,
    )?.node || null
  )
}
