import { useFeature } from '@thesisedu/feature-react'
import { MovingParticles } from '@thesisedu/feature-users-react/dist/saml/MovingParticles'
import { FeatureWebApplicationOptions } from '@thesisedu/feature-web'
import { useFlag } from '@thesisedu/react'
import { styled, s, Button, HSpaced, VSpaced, Text, Grid, Block } from '@thesisedu/ui'
import {
  ArrowDown,
  Book,
  Cancel,
  CloudSync,
  DoubleCheck,
  MoreHorizCircle,
  User,
} from '@thesisedu/ui/icons'
import React from 'react'

import { OnboardingProviders } from './OnboardingProviders'
import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import { useClassSyncSummary } from '../../useClassSyncSummary'
import { SetupModal, SetupModalProps } from '../SetupModal'

function getFlagName(classId: string) {
  return `class-sync-hide-onboarding-${classId}`
}

export interface OnboardingContextValue {
  setSetupProps: (props: SetupModalProps) => void
}
export const OnboardingContext = React.createContext<OnboardingContextValue | undefined>(undefined)

export function useOnboardingContext(): OnboardingContextValue | undefined
export function useOnboardingContext(require: false): OnboardingContextValue | undefined
export function useOnboardingContext(require: true): OnboardingContextValue
export function useOnboardingContext(require?: boolean): OnboardingContextValue | undefined {
  const context = React.useContext(OnboardingContext)
  if (!context && require) {
    throw new Error('OnboardingContext is required, yet not provided.')
  }
  return context
}

export interface OnboardingBannerProps {
  classId: string
  onClose: () => void
}
export function OnboardingBanner({ classId, onClose }: OnboardingBannerProps) {
  const appOptions = useFeature(ClassSyncReactFeature).appOptions as FeatureWebApplicationOptions
  const [setupProps, setSetupProps] = React.useState<SetupModalProps | undefined>(undefined)
  const icon = appOptions.darkIcon ? appOptions.darkIcon() : appOptions.icon()
  const { syncs, loading, canAttach } = useClassSyncSummary(classId)
  let content
  if (!syncs.length && !loading && canAttach) {
    content = (
      <Container>
        <s.ThemeProvider colorVariant={'dark'}>
          <>
            <CloseButton
              onPress={onClose}
              variant={'ghost'}
              icon={<Cancel />}
              size={'extraSmall'}
            />
            <HSpaced space={'l'}>
              <VSpaced space={'s'} align={'center'}>
                <LeftImage src={icon} alt={`${appOptions.name} Logo`} />
                <MovingParticles
                  direction={'down'}
                  style={{
                    width: 10,
                    height: 36,
                    '--particle-color': 'rgba(255, 255, 255, 0.8)',
                    '--particle-shadow-color': 'rgba(255, 255, 255, 0.2)',
                  }}
                />
                <Text level={null} style={{ fontSize: 36 }}>
                  <CloudSync />
                </Text>
              </VSpaced>
              <div style={{ maxWidth: 460 }}>
                <Text level={'h3'}>Connect {appOptions.name} to your LMS</Text>
                <Text level={'s'} bottom={'s'} style={{ opacity: 0.75 }}>
                  Use our LMS integrations to roster students, sync assignments, transfer grades,
                  and more.
                </Text>
                <Grid columnWidth={210} rowGap={'xs'} columnGap={'s'}>
                  <HSpaced space={'xs'}>
                    <Text>
                      <DoubleCheck />
                    </Text>
                    <Text>Automatic Grade Sync</Text>
                  </HSpaced>
                  <HSpaced space={'xs'}>
                    <Text>
                      <User />
                    </Text>
                    <Text>Student Rostering</Text>
                  </HSpaced>
                  <HSpaced space={'xs'}>
                    <Text>
                      <Book />
                    </Text>
                    <Text>Sync Assignments</Text>
                  </HSpaced>
                  <HSpaced space={'xs'}>
                    <Text>
                      <MoreHorizCircle />
                    </Text>
                    <Text>And more...</Text>
                  </HSpaced>
                </Grid>
              </div>
              <Block left right paddingLeft={'s'} style={{ maxWidth: 400 }}>
                <VSpaced space={'xs'} align={'flex-start'}>
                  <HSpaced space={'xs'} style={{ opacity: 0.75 }}>
                    <Text>
                      <ArrowDown />
                    </Text>
                    <Text>Click on your LMS provider to get started.</Text>
                  </HSpaced>
                  <OnboardingProviders />
                </VSpaced>
              </Block>
            </HSpaced>
          </>
        </s.ThemeProvider>
      </Container>
    )
  } else {
    content = null
  }

  return (
    <>
      {setupProps ? (
        <SetupModal
          {...setupProps}
          visible
          onVisibleChange={v => {
            if (!v) setSetupProps(undefined)
          }}
        />
      ) : undefined}
      <OnboardingContext.Provider value={{ setSetupProps }}>{content}</OnboardingContext.Provider>
    </>
  )
}
const Container = styled.div`
  background: linear-gradient(
    252deg,
    ${s.var('color.special.gradientSecondary')} 0%,
    ${s.color('primary.solid')} 100%
  );
  border-radius: ${s.var('radii.2')};
  position: relative;
  padding: ${s.size('m')} ${s.size('l')};
  * {
    color: white !important;
  }
`
const LeftImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: ${s.var('radii.1')};
  overflow: hidden;
`
const CloseButton = styled(Button)`
  position: absolute;
  top: ${s.size('xxs')};
  right: ${s.size('xxs')};
`

export function OnboardingBannerHideWrapper({ classId }: Omit<OnboardingBannerProps, 'onClose'>) {
  const [hideOnboarding, setHideOnboarding, { loading: loadingHideOnboarding }] = useFlag(
    getFlagName(classId),
    false,
  )
  const shouldHideOnboarding = hideOnboarding || loadingHideOnboarding

  return shouldHideOnboarding ? null : (
    <OnboardingBanner classId={classId} onClose={() => setHideOnboarding(true)} />
  )
}
