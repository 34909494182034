import { Tabs } from 'antd'
import React from 'react'

import { FullAssignment } from './types'
import { useAssignmentReports } from '../reporting/useAssignmentReports'

export interface AssignmentReportsProps {
  assignment: FullAssignment
}
export function AssignmentReports({ assignment }: AssignmentReportsProps) {
  const reports = useAssignmentReports({ assignment })
  return (
    <Tabs size={'large'}>
      {reports.map(report => (
        <Tabs.TabPane
          key={report.key}
          tab={
            <>
              {report.icon}
              {report.label}
            </>
          }
        >
          <report.component assignment={assignment} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
