import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import { UsersReactFeature } from '../UsersReactFeature'
import { debug, warn } from '../log'
import { AuthProviderHandlerResource } from '../types'

export interface AuthenticateOpts {
  /** If the authentication should happen in the background, pass true here. */
  headless?: boolean
  /** If the authentication modal is being triggered from an authentication error, pass true here. */
  hadError?: boolean
}
export enum AuthenticateResult {
  Successful,
  Canceled,
}
export type Authenticate = (
  provider: string,
  opts?: AuthenticateOpts,
) => Promise<AuthenticateResult>
export interface ProviderAuthContextValue {
  authenticate: Authenticate
}
export const ProviderAuthContext = React.createContext<ProviderAuthContextValue>({
  authenticate: provider => Promise.resolve(AuthenticateResult.Canceled),
})

// This global authenticate callback is here so we can call these authentication
// methods outside the context of React. Hacky, sure.
type GlobalAuthenticate = Authenticate
let globalAuthenticateCallback: GlobalAuthenticate | null = null
export async function globalAuthenticate(provider: string, opts?: AuthenticateOpts) {
  if (globalAuthenticateCallback) {
    return globalAuthenticateCallback(provider, opts)
  }
}

export interface AuthRequiredPayload extends AuthenticateOpts {
  onSuccess: () => void
  onError: (error: any) => void
  provider: string
}
export interface ProviderAuthContextProviderProps {
  children: any
}
export function ProviderAuthContextProvider({ children }: ProviderAuthContextProviderProps) {
  const [payload, setPayload] = React.useState<AuthRequiredPayload | undefined>()
  const feature = useFeature(UsersReactFeature)
  const value = React.useMemo<ProviderAuthContextValue>(
    () => ({
      async authenticate(provider, opts) {
        return new Promise<AuthenticateResult>((resolve, reject) => {
          setPayload({
            ...opts,
            provider,
            onSuccess: () => {
              debug('successfully authenticated with provider')
              setPayload(undefined)
              resolve(AuthenticateResult.Successful)
            },
            onError: err => {
              if (err) {
                warn('error authenticating with provider')
                warn(err)
                setPayload(undefined)
                reject(err)
              } else {
                // No error means they closed the dialog.
                setPayload(undefined)
                resolve(AuthenticateResult.Canceled)
              }
            },
          })
        })
      },
    }),
    [],
  )
  React.useEffect(() => {
    globalAuthenticateCallback = value.authenticate
  }, [value])

  let content: React.ReactElement | null = null
  if (payload) {
    const resource = feature.resources.getResource<AuthProviderHandlerResource>(
      'AuthProviderHandler',
      payload.provider,
    )
    if (resource) {
      content = resource.render(payload)
    }
  }

  return (
    <>
      {content}
      <ProviderAuthContext.Provider value={value} children={children} />
    </>
  )
}
