export function getBase64Contents(contents: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (reader.result && typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject(new Error('cannot find contents'))
      }
    }
    reader.readAsDataURL(contents)
  })
}
