import { AuthRoutesHook } from '@thesisedu/feature-users-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { LoginStudent } from './LoginStudent'
import { StudentIdReactFeature } from '../../StudentIdReactFeature'

export default function (feature: StudentIdReactFeature) {
  feature.hookManager.registerMutateHook<AuthRoutesHook>(
    'feature-users-react:auth-routes',
    routes => {
      return [...routes, <Route key={'student'} path={'student'} element={<LoginStudent />} />]
    },
  )
}
