import { getSegmentName } from '@thesisedu/feature-course-types'
import { getSegmentRawId } from '@thesisedu/feature-courses-react/dist/helpers'
import { useSegmentPreviewContext } from '@thesisedu/feature-courses-react/dist/segment/SegmentPreviewContext'
import { ReportDimensionTableHeaderProps } from '@thesisedu/feature-reports-react'
import { BodySmall, styled } from '@thesisedu/web'
import React from 'react'

import { SegmentType } from '../../../schema'
import { ReportSegmentSummaryItem } from '../Lessons'
import '../types'

export function useGetSegmentHeader() {
  return ({ result }: ReportDimensionTableHeaderProps<ReportSegmentSummaryItem>) => {
    return getSegmentName(result.segment)
  }
}

export function SegmentHeader({
  result,
}: ReportDimensionTableHeaderProps<ReportSegmentSummaryItem>) {
  const { setPreviewSegmentId } = useSegmentPreviewContext(false) || {}
  const rawId = getSegmentRawId(result.segment.id)
  const classIds = [
    ...new Set(
      result.items.map(
        item => item.dimensions.classes?.class.id || item.dimensions.students?.class.id,
      ),
    ),
  ]
  const child = <Name>{getSegmentName(result.segment)}</Name>
  const isLinkable =
    result.segment.type === SegmentType.Assignment || result.segment.type === SegmentType.Section
  if (classIds.length === 1 && isLinkable) {
    return (
      <a
        href={`/teacher/classes/${classIds[0]}/course/outline?viewSegment=${rawId}`}
        target={'_blank'}
        rel={'noopener noreferrer'}
        children={child}
      />
    )
  } else if (setPreviewSegmentId && isLinkable) {
    return (
      <a
        href={'#'}
        onClick={e => {
          e.preventDefault()
          setPreviewSegmentId(result.segment.id)
        }}
        children={child}
      />
    )
  } else {
    return child
  }
}

const Name = styled(BodySmall)`
  overflow: hidden;
  text-overflow: ellipsis;
`
