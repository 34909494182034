import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { useNavigate } from '@thesisedu/react'
import { Button, Button$, Modal } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

export interface DeleteSavedReportButtonProps extends Partial<Button$.ButtonProps> {
  savedReportId: string
}
export function DeleteSavedReportButton({ savedReportId, ...rest }: DeleteSavedReportButtonProps) {
  const navigate = useNavigate()
  const [deleteSavedReport] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that saved report.',
    refetchQueries: ['savedReports', 'hasSavedReports'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate('../../')
    },
  })

  return (
    <Modal.Confirm
      title={'Are you sure you want to delete this saved report?'}
      confirm={{ children: 'Yes, delete', status: 'danger' }}
      cancel={{ children: 'No, keep' }}
      onConfirm={() => {
        return deleteSavedReport({
          variables: {
            id: savedReportId,
          },
        })
      }}
      trigger={
        <Button icon={<Trash />} status={'danger'} variant={'ghost'} size={'small'} {...rest} />
      }
    />
  )
}
