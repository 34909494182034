import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { message } from 'antd'

import { useOptions } from '../options'
import {
  ClassFragment,
  ClassFragmentDoc,
  InviteStudentMutation,
  LicenseStatusDocument,
  useInviteStudentMutation as useMutation,
} from '../schema'

export const useInviteStudentMutation: typeof useMutation = opts => {
  const options = useOptions()
  return useMutation({
    onError: error => {
      if (error.graphQLErrors[0]?.extensions?.code === 'NOT_A_STUDENT') {
        message.error('That email address is associated with someone who is not a student.')
      } else if (error.graphQLErrors[0]?.extensions?.code === 'STUDENT_ALREADY_IN_CLASS') {
        message.error(`That student is already in this ${options.className}.`)
      } else if (error.graphQLErrors[0]?.extensions?.code === 'LICENSE_INVALID_ERROR') {
        message.error('Cannot invite student. You do not have any more seats on your license.')
      } else {
        onMutationError('There was an error inviting that student')(error)
      }
    },
    update: updateMutation<InviteStudentMutation, ClassFragment>({
      path: 'inviteStudent.class',
      fragment: ClassFragmentDoc,
      fragmentName: 'Class',
    }),
    refetchQueries: [{ query: LicenseStatusDocument }],
    ...opts,
  })
}
