import * as React from 'react'

function SvgNumber5(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#009696'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#009696'}
          fillRule={'nonzero'}
          d={
            'M83.034 199.874c14.642 1.136 27.327.757 38.055-1.136 10.729-1.893 19.659-4.733 26.79-8.52 7.132-3.786 12.938-8.172 17.418-13.158 4.481-4.986 7.857-10.003 10.13-15.051 2.271-5.05 3.88-9.782 4.827-14.2.947-4.418 1.42-7.889 1.42-10.413v-4.165c-.505-7.573-2.966-14.01-7.384-19.312-4.417-5.3-9.845-9.466-16.282-12.495-6.437-3.03-13.442-4.86-21.015-5.49-7.573-.632-14.768 0-21.584 1.892l4.734-13.442 57.555.568-.946-37.676L86.82 51.63l-6.437 81.6c2.903-1.261 6.248-2.397 10.034-3.407a80.043 80.043 0 0111.36-2.177 82.98 82.98 0 0111.076-.568c3.597.063 6.752.441 9.466 1.136 2.714.694 4.796 1.767 6.248 3.218 1.451 1.452 1.925 3.376 1.42 5.775 0 .126-.316 1.104-.947 2.934s-2.051 4.07-4.26 6.721c-2.209 2.65-5.301 5.018-9.277 7.1-3.976 2.083-9.34 3.408-16.093 3.976-6.752.568-15.051-.22-24.896-2.367l8.52 44.303z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M115.41 97.826l4.733-13.442 57.555.568-.946-37.676L86.82 51.63l-6.437 81.6c2.902-1.26 6.248-2.395 10.034-3.407a80.498 80.498 0 0111.36-2.177 83.34 83.34 0 0111.076-.568c3.597.065 6.75.443 9.466 1.135 2.713.696 4.795 1.77 6.248 3.22 1.45 1.452 1.922 3.378 1.42 5.774 0 .127-.317 1.106-.947 2.934-.633 1.831-2.053 4.07-4.26 6.721-2.21 2.65-5.301 5.017-9.277 7.1-3.976 2.083-9.342 3.408-16.093 3.976-6.753.568-15.051-.219-24.896-2.367l8.52 44.303c14.64 1.136 27.325.757 38.054-1.136 10.727-1.893 19.658-4.733 26.79-8.52 7.13-3.786 12.936-8.17 17.418-13.158 4.48-4.985 7.857-10.002 10.13-15.052 2.271-5.046 3.88-9.78 4.827-14.2.947-4.416 1.42-7.886 1.42-10.412v-4.165c-.506-7.573-2.967-14.01-7.384-19.312-4.42-5.3-9.845-9.466-16.282-12.495-6.437-3.03-13.442-4.858-21.015-5.49-7.573-.63-14.768 0-21.584 1.892z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber5 = React.memo(SvgNumber5)
export default MemoSvgNumber5
