import pluralize from 'pluralize'

import { useClasses } from '../ClassContext'
import { ClassStudentPair } from '../types'
import { useStudentUser } from '../useStudentUsers'

export function useStudentFilterHumanValue(pairs: ClassStudentPair[]) {
  const singlePair = pairs.length === 1 ? pairs[0] : null
  const studentUserResult = useStudentUser(
    singlePair?.studentId || '',
    singlePair?.classId || '',
    !singlePair,
  )
  const classesResult = useClasses()
  const classIds = [...new Set(pairs.map(p => p.classId))]

  if (singlePair) {
    if (studentUserResult.loading) {
      return '...'
    } else if (studentUserResult.error) {
      return '!!'
    } else if (studentUserResult.student) {
      return studentUserResult.student.user.name || studentUserResult.student.user.username
    }
  } else if (classIds.length === 1) {
    const cls = classesResult.classes.find(cls => cls.id === classIds[0])
    if (classesResult.loading) {
      return '...'
    } else if (classesResult.error) {
      return '!!'
    } else if (cls) {
      return `${pluralize('student', pairs.length, true)} in ${cls.name}`
    } else {
      return pluralize('student', pairs.length, true)
    }
  } else {
    return pluralize('student', pairs.length, true)
  }
}
