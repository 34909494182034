import {
  MediaGridItem,
  DurationOverlay,
  MediaListItem,
} from '@thesisedu/feature-media-react/search'
import { ReactFeature } from '@thesisedu/feature-react'
import {
  addSearch,
  RenderSearchItemProps,
  RenderSearchListItemProps,
  SearchResultCalendarItem,
} from '@thesisedu/feature-search-react'
import { useMediaGridItemProps } from '@thesisedu/feature-sharing-react/search'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Block, BodySmall, getColor, getSize, sizeToNumber, useTheme } from '@thesisedu/react'
import { Tv, Play } from '@thesisedu/react/icons'
import React from 'react'

import { LessonSearchResultFragmentDoc, LessonSearchResultFragment } from '../schema'

type LessonResultNode = LessonSearchResultFragment
function LessonGridItem({ item }: RenderSearchItemProps<LessonResultNode>) {
  const theme = useTheme()
  const viewer = useViewerContext(false)
  return (
    <MediaGridItem
      media={item.lessonMedia}
      title={item.lessonTitle}
      topLeftIcon={<Tv />}
      {...useMediaGridItemProps({ sharing: item.lessonSharing })}
    >
      {item.lessonStudentsViewed !== null &&
      item.lessonStudentsViewed !== undefined &&
      item.lessonStudents?.totalCount &&
      viewer?.group === 'TEACHER' ? (
        <DurationOverlay style={{ left: sizeToNumber(getSize(theme, '@size-xs')), right: 'auto' }}>
          <Block marginRight={'@size-xxs'}>
            <Play
              width={getSize(theme, '@size-s')}
              height={getSize(theme, '@size-s')}
              color={getColor(theme, '@gray-0')}
            />
          </Block>
          <BodySmall color={'@gray-0'} lineHeight={1.4}>
            {item.lessonStudentsViewed} / {item.lessonStudents.totalCount}
          </BodySmall>
        </DurationOverlay>
      ) : null}
    </MediaGridItem>
  )
}

function LessonListItem({ item, onClick }: RenderSearchListItemProps<LessonResultNode>) {
  return (
    <MediaListItem
      onClick={onClick}
      media={item.lessonMedia}
      title={item.lessonTitle}
      type={'Lesson'}
    />
  )
}

export default function addSearchResources(feature: ReactFeature) {
  addSearch(feature, LessonSearchResultFragmentDoc, 'Lesson', {
    renderGrid: LessonGridItem,
    renderList: LessonListItem,
    renderCalendar: ({ item, onClick }) => (
      <SearchResultCalendarItem
        title={item.lessonTitle}
        type={'Lesson'}
        onClick={onClick}
        color={'@blue'}
      />
    ),
    getDate: item => item.lessonMedia.createdAt,
    getUniversalLink: item => `lessons/${item.id}`,
  })
}
