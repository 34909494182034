import { Dropdown, Text } from '@thesisedu/ui'
import { Check, RefreshDouble } from '@thesisedu/ui/icons'
import { $createParagraphNode, $getRoot, LexicalEditor, LexicalNode } from 'lexical'
import React from 'react'

import { commitElement, useGroupedElementsMenuItems } from './useElementsMenuItems'
import { debug } from '../../../log'
import { useSelectedElementContext } from '../../context/SelectedElementContext'
import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function useElementsMenuDropdownItems(editor: LexicalEditor, element?: LexicalNode) {
  const items = useGroupedElementsMenuItems(undefined, item => (element ? !item.noReplace : true))

  return editor.getEditorState().read(() =>
    Object.keys(items).map(group => {
      return (
        <React.Fragment key={group}>
          <Dropdown.Item.Label>{group}</Dropdown.Item.Label>
          {items[group].map(item => {
            let isSelected = false
            try {
              isSelected = element ? item.isSelected(element) : false
            } catch (err: any) {
              debug('error occurred when checking isSelected state. reporting false: %O', err)
              // Do nothing...
            }
            return (
              <Dropdown.Item
                icon={
                  isSelected ? (
                    <Text level={null} color={'primary'} children={<Check />} />
                  ) : (
                    item.icon
                  )
                }
                key={item.identifier}
                onClick={() => {
                  if (element) {
                    commitElement(editor, item, element)
                  } else {
                    const selectedElement = editor
                      .getEditorState()
                      .read(() => $getSelectedBlockElement())

                    // This code is likely called from the toolbar, so we have to make sure
                    // we shift focus back to the toolbar after clicking on one of these
                    // items. We wait a few ms for the focus to be taken away first.
                    setTimeout(() => {
                      editor.update(() => {
                        // Get the selected element, or add to the end.
                        const newElement = $createParagraphNode()
                        if (selectedElement) {
                          selectedElement.insertAfter(newElement)
                        } else {
                          const root = $getRoot()
                          root.append(newElement)
                        }
                        newElement.select()
                        editor.focus()
                        commitElement(editor, item, newElement)
                      })
                    }, 10)
                  }
                }}
              >
                <Text level={null} color={isSelected ? 'primary' : undefined}>
                  {item.title}
                </Text>
              </Dropdown.Item>
            )
          })}
        </React.Fragment>
      )
    }),
  )
}

export interface ElementsDropdownSubMenuProps {
  editor: LexicalEditor
}
export function ElementsDropdownSubMenu({ editor }: ElementsDropdownSubMenuProps) {
  const { element } = useSelectedElementContext(false) || {}
  const items = useElementsMenuDropdownItems(editor, element)
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<RefreshDouble />} children={'Change to'} />
      <Dropdown.SubMenu.Content>{items}</Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}
