import { styled } from '@thesisedu/ui'
import { LexicalEditor } from 'lexical'
import React from 'react'

import { useTextFormatToolbarItems } from './useTextFormatToolbarItems'

export interface TextFormatToolbarProps {
  editor: LexicalEditor
  activeItems: Record<string, string[] | boolean>
  disabled?: boolean
}
export function TextFormatToolbar({ editor, activeItems, disabled }: TextFormatToolbarProps) {
  const { groups } = useTextFormatToolbarItems({ editor, activeItems, disabled })

  return (
    <>
      {groups.map((group, index) => {
        return <Group key={index}>{group}</Group>
      })}
    </>
  )
}

const Group = styled.div`
  display: flex;
  align-items: center;
`
