import { MinimalClassFragment } from '@thesisedu/feature-classes-react'

import { SetupNavigationGroup } from './Navigation/SetupNavigation'
import { ClassSyncReactFeature } from '../ClassSyncReactFeature'

export interface TransitionOpts<Context extends object = object> {
  step: string
  context: Context
}
export interface SetupStepProps<Context = object> {
  cls: MinimalClassFragment
  context: Context
  transition: (opts: TransitionOpts | null) => void
  feature: ClassSyncReactFeature
}
export interface SetupStepSize {
  width: number
  height: number
}
export interface SetupStep<Context = object> {
  Component: (props: SetupStepProps<Context>) => React.ReactElement | null
  /** Fired when this step is transitioned to. You can use this to redirect the step to a different one. */
  onTransitionTo?: (opts: Omit<SetupStepProps<Context>, 'transition'>) => TransitionOpts | null
  size: { width: number; height: number; noMaxHeight?: boolean }
  /** Steps are not animated in-between if they belong to the same animation group. */
  animationGroup?: 'loading' | string
  centerVertically?: boolean
  /** If this is true, the modal is not closable. */
  hideClose?: boolean
  /** The navigation group the step belongs to. The navigation will only be shown if this has a value. */
  group?: SetupNavigationGroup
}
export type SetupSteps = Record<string, SetupStep<any>>

export const StepSizes = {
  loading: { width: 550, height: 300 },
} satisfies Record<string, SetupStepSize>

export function getStepFactory<Context extends object>(step: string) {
  return (context: Context): TransitionOpts => {
    return {
      step,
      context,
    }
  }
}
