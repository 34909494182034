import { useSubmissionQuery } from '@thesisedu/feature-assignments-react'
import { Result } from '@thesisedu/react'
import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { AssignmentViewer, AssignmentViewerProps } from './AssignmentViewer'

export interface AssignmentSubmissionProps extends AssignmentViewerProps {
  assignmentId: string
  submissionId: string
}
export function AssignmentSubmission({
  assignmentId,
  submissionId,
  ...rest
}: AssignmentSubmissionProps) {
  const { submission, loading } = useSubmissionQuery(assignmentId, submissionId)
  if (submission) {
    return <AssignmentViewer {...rest} submission={submission} />
  } else if (loading) {
    return <LoadingIndicator.Labeled label={'Loading submission...'} />
  } else {
    return <Result.Error description={'There was an error loading your submission.'} />
  }
}
