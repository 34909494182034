import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable'
import { getNewWeight } from '@thesisedu/feature-utils'
import { Result, Empty } from '@thesisedu/react'
import { LoadingIndicator, styled, s, SegmentedControl, useToast, Grid } from '@thesisedu/ui'
import { orderBy } from 'lodash'
import React from 'react'

import { ClassDraggable, ClassDraggableProps } from './ClassDraggable'
import { ClassManagerItem } from './ClassManagerItem'
import { InviteStudentModal } from './invite/InviteStudentModal'
import { useClasses } from '../ClassContext'
import { debug } from '../log'
import { updateOpts, useUpdateClassMutation } from '../queries/useUpdateClassMutation'
import { MinimalClassFragment, useMinimalTeacherClassesQuery } from '../schema'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${s.size('s')};
  > :last-child:not(:first-child) {
    margin-left: auto;
  }
`

export interface ClassManagerProps {
  draggableProps?: Partial<ClassDraggableProps>
  renderDraggable?: (props: ClassDraggableProps & { key: string }) => React.ReactElement
  emptyContent?: React.ReactElement
  style?: any
}
export const ClassManager: React.FC<React.PropsWithChildren<ClassManagerProps>> = ({
  draggableProps,
  renderDraggable,
  emptyContent,
  style,
}) => {
  const toast = useToast()
  const [showArchive, setShowArchive] = React.useState(false)
  const { data, loading, error } = useMinimalTeacherClassesQuery({
    variables: { deleted: showArchive },
  })
  const [updateClass] = useUpdateClassMutation()
  const [inviteClass, setInviteClass] = React.useState<MinimalClassFragment | undefined>()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading classes...'} />
  } else if (error) {
    return <Result.Error description={'There was an error loading your classes.'} />
  }
  const classes = orderBy(data?.viewer?.teacher?.classes.edges.map(edge => edge.node) || [], [
    'weight',
    'ASC',
  ])
  if (classes.length || !emptyContent || showArchive) {
    return (
      <>
        <Header>
          <SegmentedControl>
            <SegmentedControl.Item selected={!showArchive} onPress={() => setShowArchive(false)}>
              Active Classes
            </SegmentedControl.Item>
            <SegmentedControl.Item selected={showArchive} onPress={() => setShowArchive(true)}>
              Archived Classes
            </SegmentedControl.Item>
          </SegmentedControl>
        </Header>
        <InviteStudentModal classId={inviteClass?.id} onClose={() => setInviteClass(undefined)} />
        {classes.length ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={async event => {
              const movingId = event.active.id
              const movingClass = classes.find(cls => cls.id === movingId)
              const movingIndex = classes.findIndex(c => c.id === movingId)
              const overId = event.over?.id
              const overIndex = classes.findIndex(c => c.id === overId)
              if (
                movingId &&
                overId &&
                movingIndex != null &&
                overIndex != null &&
                movingIndex !== overIndex &&
                movingClass
              ) {
                const newWeight = getNewWeight({
                  collection: classes,
                  draggingIds: [movingId.toString()],
                  dropPosition: { fromIndex: movingIndex, toIndex: overIndex },
                  targetId: overId.toString(),
                  field: 'weight',
                })?.[movingId]
                if (newWeight != null) {
                  debug('updating class...')
                  const updatePromise = updateClass(
                    updateOpts(movingClass, {
                      weight: newWeight,
                    }),
                  )
                  const update = toast({
                    loading: true,
                    title: 'Saving class order',
                  })
                  try {
                    await updatePromise
                    update({ status: 'success', loading: false, title: 'Order updated!' })
                  } finally {
                    update()
                  }
                } else {
                  debug('not updating class; could not find new weight')
                }
              }
            }}
          >
            <SortableContext items={classes} strategy={rectSortingStrategy}>
              <Grid columnWidth={350} gap={'m'}>
                {classes.map((cls, index) => {
                  return (
                    <ClassManagerItem
                      class={cls}
                      key={cls.id}
                      index={index}
                      renderDraggable={renderDraggable}
                      setInviteClass={setInviteClass}
                      draggableProps={draggableProps}
                    />
                  )
                })}
              </Grid>
            </SortableContext>
          </DndContext>
        ) : (
          <Empty description={'You have no classes.'} />
        )}
      </>
    )
  } else {
    return emptyContent
  }
}

export function BasicClassManager({
  renderDraggable,
  emptyContent,
  draggableProps,
  style,
}: ClassManagerProps) {
  const { classes, loading, error } = useClasses()
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading classes...'} />
  } else if (error) {
    return <Result.Error description={'There was an error loading your classes.'} />
  } else if (classes.length) {
    return (
      <Grid columnWidth={350} gap={'m'}>
        {classes.map(cls => {
          const props: ClassDraggableProps & { key: string } = {
            class: cls,
            index: 0,
            key: cls.id,
            onInvite: () => {},
            ...draggableProps,
          }
          if (renderDraggable) {
            return renderDraggable(props)
          } else {
            return <ClassDraggable {...props} />
          }
        })}
      </Grid>
    )
  } else if (emptyContent) {
    return emptyContent
  } else {
    return <Empty description={'You have no classes.'} />
  }
}
