import { useObjectRef } from '@react-aria/utils'
import React from 'react'

import { Button, Button$, HSpaced, HTMLProps } from '../..'
import { CloudUpload } from '../../../icons'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field'

export interface FileFieldProps
  extends ExtendedBareFieldProps,
    Button$.ButtonProps,
    Pick<HTMLProps<HTMLInputElement>, 'accept'> {
  value?: File
  onChange?: (file?: File) => void
}
function _FileField(
  { style, className, value, onChange, accept, ...props }: FileFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const inputProps = { accept }
  const { bareFieldProps, rest: buttonProps } = getBareFieldProps(props)
  const fileFieldRef = useObjectRef(ref)
  const fileFieldId = React.useId()
  return (
    <>
      <BareField {...bareFieldProps} style={style} className={className}>
        <Button
          {...buttonProps}
          onPress={() => {
            fileFieldRef.current?.click()
          }}
          aria-haspopup={'dialog'}
          aria-controls={fileFieldId}
        >
          {props.children ?? (
            <HSpaced justify={'space-between'} style={{ flex: 1 }}>
              <span>{value ? value.name : 'Choose File to Upload'}</span>
              <CloudUpload />
            </HSpaced>
          )}
        </Button>
      </BareField>
      <input
        {...inputProps}
        type={'file'}
        style={{ display: 'none' }}
        ref={fileFieldRef}
        id={fileFieldId}
        onChange={e => {
          const files = e.target.files
          if (files?.length && onChange) {
            onChange(files[0])
          }
        }}
      />
    </>
  )
}
export const FileField = React.forwardRef(_FileField)
