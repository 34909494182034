import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { Form } from 'antd'
import React from 'react'

import { ClassGeneratedProvidersField } from '../../generated/ClassGeneratedProvidersField'
import { useClassGeneratedProviders } from '../../generated/useClassGeneratedProviders'

function Field({ fieldPrefix, classId }: ClassConfigurationFieldProps) {
  const { generatedProviders } = useClassGeneratedProviders(classId)
  const hasGeneratedProviders = !!generatedProviders.length
  return (
    <div id={'automatically-add-generated-assignments'}>
      {hasGeneratedProviders && classId ? (
        <Form.Item
          id={'automatically-add-generated-assignments'}
          name={[...fieldPrefix, ENABLE_GENERATED_ASSIGNMENT_PROVIDERS]}
          label={'Automatically Add Generated Assignments'}
          extra={
            <>
              If any of these are checked, automatically-generated assignments will be added to your
              gradebook as they are created. This means students will automatically be graded as
              they do work.
            </>
          }
        >
          <ClassGeneratedProvidersField classId={classId} />
        </Form.Item>
      ) : null}
    </div>
  )
}

export const ENABLE_GENERATED_ASSIGNMENT_PROVIDERS = 'enableGeneratedAssignmentProviders'
const config: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: ENABLE_GENERATED_ASSIGNMENT_PROVIDERS,
  group: 'Assignments & Grading',
  renderField: Field,
}
export default config
