import * as ReactTooltip from '@radix-ui/react-tooltip'
import React from 'react'

import { styled, s } from '../../'
import { usePortalContainer } from '../../context/ScrollableContainerContext'
import { ThemeProvider } from '../../style/ThemeProvider'
import { useOverlay } from '../../utils/useOverlay'

export interface TooltipProps
  extends Pick<
    ReactTooltip.TooltipProps,
    'defaultOpen' | 'delayDuration' | 'open' | 'onOpenChange' | 'disableHoverableContent'
  > {
  title: React.ReactElement | string
  children: ReactTooltip.TooltipContentProps['children']
  colorVariant?: s.ThemeColorVariant | 'inherit'
  contentProps?: Partial<ReactTooltip.TooltipContentProps>
  noArrow?: boolean
}
function _Tooltip(
  {
    children,
    title,
    colorVariant = 'dark',
    defaultOpen,
    delayDuration,
    open: _open,
    onOpenChange,
    disableHoverableContent,
    noArrow,
    contentProps,
    ...rest
  }: TooltipProps,
  ref?: React.ForwardedRef<any>,
) {
  const [open, setOpen] = useOverlay({ open: _open, onOpenChange, weight: 'tooltip' })
  const content = (
    <_TooltipContent sideOffset={s.getThemeValue('size.0.5')} {...contentProps}>
      {title}
      {noArrow ? null : <_TooltipArrow />}
    </_TooltipContent>
  )
  return (
    <ReactTooltip.Root
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={setOpen}
      disableHoverableContent={disableHoverableContent}
      delayDuration={delayDuration}
    >
      <ReactTooltip.Trigger {...rest} asChild ref={ref}>
        {children}
      </ReactTooltip.Trigger>
      <ReactTooltip.Portal container={usePortalContainer()}>
        {colorVariant === 'inherit' ? (
          content
        ) : (
          <ThemeProvider children={content} colorVariant={colorVariant} />
        )}
      </ReactTooltip.Portal>
    </ReactTooltip.Root>
  )
}
export const Tooltip = React.forwardRef(_Tooltip)

const _TooltipContent = styled(ReactTooltip.Content)`
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('0.5')} ${s.size('0.75')};
  font-size: ${s.size('0.9')};
  line-height: ${s.var('lineHeights.default')};
  color: ${s.color('text')};
  background: ${s.color('background')};
  box-shadow: ${s.var('shadow.1')};
  max-width: 300px;
  text-align: center;
  user-select: none;
  animation-duration: 400ms;
  z-index: ${s.var('zIndices.overlays')};
  animation-timing-function: ${s.var('curves.exponential')};
  will-change: transform, opacity;
  &[data-state='delayed-open'] {
    &[data-side='top'] {
      animation-name: ${s.animations.slideDownAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${s.animations.slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${s.animations.slideRightAndFade};
    }
    &[data-side='right'] {
      animation-name: ${s.animations.slideLeftAndFade};
    }
  }
`

const _TooltipArrow = styled(ReactTooltip.Arrow)`
  fill: ${s.color('gray.background')};
`

export const Provider = ReactTooltip.Provider
