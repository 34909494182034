import { Color } from './parseColor'

const PARSED_COLOR_CACHE: Record<string, Color> = {}

export function parseColor(color: string): Color {
  if (!PARSED_COLOR_CACHE[color]) {
    const element = document.createElement('div')
    element.style.color = color
    document.body.appendChild(element)
    const computedColor = window.getComputedStyle(element).color
    document.body.removeChild(element)
    // eslint-disable-next-line unicorn/no-unsafe-regex
    const match = computedColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(0\.\d+))?\)$/)
    if (!match) {
      console.warn('cannot parse color %s', color)
      throw new Error('Cannot parse color')
    }
    PARSED_COLOR_CACHE[color] = [
      parseInt(match[1], 10),
      parseInt(match[2], 10),
      parseInt(match[3], 10),
      match[4] ? parseInt(match[4], 10) : 255,
    ]
  }

  if (!PARSED_COLOR_CACHE[color]) {
    console.warn('cannot parse color %s', color)
    throw new Error('Cannot parse color')
  }

  return PARSED_COLOR_CACHE[color]
}
