import { AriaProgressBarProps, ProgressBarAria } from '@react-aria/progress'
import React from 'react'

export interface ProgressBarContextValue
  extends ProgressBarAria,
    Pick<AriaProgressBarProps, 'label' | 'isIndeterminate'> {
  minValue: number
  maxValue: number
  value: number
}
export const ProgressBarContext = React.createContext<ProgressBarContextValue | undefined>(
  undefined,
)

export function useProgressBarContext(): ProgressBarContextValue | undefined
export function useProgressBarContext(require: false): ProgressBarContextValue | undefined
export function useProgressBarContext(require: true): ProgressBarContextValue
export function useProgressBarContext(require?: boolean): ProgressBarContextValue | undefined {
  const context = React.useContext(ProgressBarContext)
  if (!context && require) {
    throw new Error('ProgressBarContext is required, yet not provided.')
  }
  return context
}
