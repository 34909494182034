import { CheckOutlined } from '@ant-design/icons'
import { ExpandedContentComponentProps } from '@thesisedu/feature-classes-react'
import { Block, Body, styled } from '@thesisedu/web'
import { Button, Form, InputNumber } from 'antd'
import React, { useState } from 'react'

import { ImportStudentIDPayload } from '../ImportStudentID'
import { useGenerateStudentsMutation } from '../queries/useGenerateStudentsMutation'

export function Generate({
  onClose,
  class: cls,
}: ExpandedContentComponentProps<ImportStudentIDPayload>) {
  const [generate, { loading }] = useGenerateStudentsMutation({
    onError: () => {
      onClose()
    },
    onCompleted: () => {
      onClose()
    },
  })
  const [form] = Form.useForm<{ count: number }>()
  const [generateCount, setGenerateCount] = useState(10)
  return (
    <>
      <Body>
        To generate students, specify how many you want to generate and then click the generate
        button below. You may only generate 50 students at a time.
      </Body>
      <Form
        form={form}
        onFinish={values => {
          generate({
            variables: {
              input: {
                id: cls.id,
                count: values.count,
              },
            },
          })
        }}
        onValuesChange={values => {
          setGenerateCount(parseFloat(values.count) || 0)
        }}
      >
        <GenerateInput>
          <span>Generate</span>
          <Form.Item
            noStyle
            name={'count'}
            rules={[
              { required: true, message: 'You must specify a number of students to generate.' },
            ]}
            initialValue={10}
          >
            <InputNumber min={0} max={50} size={'large'} />
          </Form.Item>
          <span>students</span>
        </GenerateInput>
        <Block marginTop={'@size-s'} style={{ textAlign: 'center' }}>
          <Button
            size={'large'}
            type={'primary'}
            htmlType={'submit'}
            icon={<CheckOutlined />}
            loading={loading}
          >
            Generate {generateCount || 0} Students
          </Button>
        </Block>
      </Form>
    </>
  )
}

const GenerateInput = styled.div`
  margin-top: ${props => props.theme['@size-m']};
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    margin: 0 5px;
  }
`
