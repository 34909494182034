import { HSpaced, styled, s } from '@thesisedu/ui'
import { LexicalEditor } from 'lexical'
import React from 'react'

import { AddButton } from './AddButton'
import { useTextFormatToolbarState } from '../../plugins/FloatingTextFormatToolbarPlugin/index'
import { TextFormatToolbar } from '../TextFormatToolbar/TextFormatToolbar'

export interface ToolbarProps {
  editor: LexicalEditor
  focused?: boolean
}
export function Toolbar({ editor, focused }: ToolbarProps) {
  const { activeItems } = useTextFormatToolbarState(editor)
  return (
    <Container space={'xs'} bottom={'s'} $focused={focused} className={'fteditor-toolbar'}>
      <AddButton editor={editor} />
      <TextFormatToolbar activeItems={activeItems} editor={editor} />
    </Container>
  )
}

const Container = styled(HSpaced)<{ $focused?: boolean }>`
  background: ${s.color('subtle')};
  border-radius: ${s.var('radii.2')};
  padding: ${s.size('xxs')};
  position: sticky;
  top: ${s.size('s')};
  z-index: ${s.var('zIndices.stickyHeaders')};
  opacity: ${props => (props.$focused ? 1 : 0.5)};
  transition: opacity 0.1s linear;
  pointer-events: ${props => (props.$focused ? 'all' : 'none')};
  &:focus,
  &:focus-within,
  &:has(button[data-state='open']) {
    opacity: 1;
    pointer-events: all;
  }
`
