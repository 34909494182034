import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import React from 'react'

import { MetronomeElement } from './MetronomeElement'
import MediaReactFeature from '../../../MediaReactFeature'
import MetronomeIcon from '../../../metronome/Icon'

export interface MetronomeWidgetConfig {
  version: 1
}

const definition = {
  identifier: 'Metronome',
  title: 'Metronome',
  icon: <MetronomeIcon />,
  weight: 44,
  group: SimpleWidget.ElementsGroup.Other,
  defaultConfig: { version: 1 },
  element: MetronomeElement,
} satisfies SimpleWidget.SimpleWidgetDefinition<MetronomeWidgetConfig, 'Metronome'>

export default function (feature: MediaReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(definition))
}
