import { getPreviewDomain, PREVIEW_BRANCH_PREFIX } from '@thesisedu/feature/dist/preview'
import { deploy as webDeploy } from '@thesisedu/web-deploy'

import { FeatureWeb } from './FeatureWeb'

export function getStageDomain(domain: string, stage: string) {
  const domainPrefix = stage.startsWith(PREVIEW_BRANCH_PREFIX)
    ? `${getPreviewDomain(stage)}-app`
    : stage === 'production'
    ? 'app'
    : 'qa-app'
  return `${domainPrefix}.${domain}`
}

export async function deploy(this: FeatureWeb, stage: string) {
  await webDeploy(
    {
      acmCertificateId: this.appOptions.acmCertificateId,
      domain: getStageDomain(this.appOptions.domain, stage),
      rootDomain: this.appOptions.domain,
      hostedZoneId: this.appOptions.hostedZoneId,
      terraformOrg: this.appOptions.terraformOrg,
      region: this.appOptions.region,
      workspacePrefix: this.appOptions.name.toLowerCase().replace(/ /g, '-'),
      basicAuth:
        stage === 'production'
          ? undefined
          : {
              username: 'thesisedu',
              password: 'please refresh',
            },
      isSPA: true,
      outputFolder: 'build',
    },
    stage,
  )
}
