import { useResource } from '@thesisedu/feature-react'
import { Body, BodySmall, Checkbox, Empty, LoadingIndicator, VSpaced } from '@thesisedu/react'
import React from 'react'

import { GeneratedAssignmentProviderReactResource } from './types'
import { useClassGeneratedProviders } from './useClassGeneratedProviders'

export interface ClassGeneratedProvidersFieldProps {
  value?: string[]
  onChange?: (value: string[]) => void
  classId: string
}
export function ClassGeneratedProvidersField({
  value,
  onChange,
  classId,
}: ClassGeneratedProvidersFieldProps) {
  const { generatedProviders, loading } = useClassGeneratedProviders(classId)
  const reactResources = useResource<GeneratedAssignmentProviderReactResource>(
    'GeneratedAssignmentProviderReact',
  )

  if (loading) {
    return <LoadingIndicator block />
  } else if (generatedProviders.length) {
    return (
      <VSpaced space={'@size-xs'} align={'stretch'}>
        {generatedProviders.map(provider => {
          const reactResource = reactResources.find(r => r.identifier === provider)
          const checked = value?.includes(provider)
          if (!reactResource) {
            throw new Error(`Cannot find React resource for '${provider}'`)
          }
          return (
            <Checkbox
              key={provider}
              checked={checked}
              onChange={e => {
                if (onChange) {
                  const without = (value || []).filter(v => v !== provider)
                  onChange(e.target.checked ? [...without, provider] : without)
                }
              }}
            >
              <Body>{reactResource.label}</Body>
              <BodySmall color={'@text-color-secondary'}>{reactResource.description}</BodySmall>
            </Checkbox>
          )
        })}
      </VSpaced>
    )
  } else {
    return <Empty description={'No generated assignment types are supported for this class.'} />
  }
}
