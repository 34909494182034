import { useStudentUserId } from '@thesisedu/feature-teachers-react'
import { Result, Empty, LoadingIndicator } from '@thesisedu/react'
import { orderBy } from 'lodash'
import React from 'react'

import { ClassStudent } from './ClassStudent'
import { filterStudents } from '../filterStudents'
import { ClassStudentEdgeFragment } from '../schema'
import { useTeacherClass, useTeacherSelectedClass } from '../useClass'

export function useClassStudents(): {
  loading: boolean
  error: boolean
  students: ClassStudentEdgeFragment['node'][]
} {
  const { cls, error, loading } = useTeacherSelectedClass(true)
  const students = cls?.students.edges.map(edge => edge.node) || []
  return { loading, students, error }
}

export interface ClassStudentsProps {
  classId: string
  nameFilter?: string
  onSelected?: (id: string) => void
  selectedStudentId?: string
  emptyText?: string
}
export function ClassStudents({
  classId,
  nameFilter,
  onSelected,
  selectedStudentId,
  emptyText,
}: ClassStudentsProps) {
  const { cls, error, loading } = useTeacherClass(classId, true)
  const studentUserId = useStudentUserId()
  if (loading) {
    return <LoadingIndicator block />
  } else if (error) {
    return <Result.Error description={'There was an error loading that class.'} />
  }

  const students = cls?.students.edges.map(edge => edge.node) || []

  const children = filterStudents(
    orderBy(students, node => (node.user.id === studentUserId ? 0 : 1)),
    nameFilter,
  ).map(student => (
    <ClassStudent
      studentId={student.id}
      classId={classId}
      key={student.id}
      noLink={!onSelected}
      rightContent={onSelected ? undefined : <span />}
      onClick={onSelected ? () => onSelected(student.id) : undefined}
      highlighted={selectedStudentId === student.id}
    />
  ))

  if (!children.length && emptyText) {
    return <Empty description={emptyText} />
  }

  return <>{children}</>
}
