import { Shell } from '@thesisedu/feature-web'
import { PageHead } from '@thesisedu/react'
import { PageHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { useParams, Routes, Route } from 'react-router-dom'

import { CreateCourseButton } from '../../admin/CreateCourseModal'
import { CoursesTable } from '../../admin/ListCourses'
import { ViewCoursePage } from '../../admin/ViewCoursePage'
import { EditSegmentRoute } from '../../admin/segment/EditSegmentRoute'
import { ImportButton } from '../../import/ImportButton'
import { useCourseQuery } from '../../schema'

export interface CourseNameProps {
  courseId: string
}
export function CourseName({ courseId }: CourseNameProps) {
  const { data } = useCourseQuery({ variables: { id: courseId } })
  return <span>{data?.node?.__typename === 'Course' ? data.node.name : '...'}</span>
}

function EditSegment() {
  Shell.useShellProps(
    'edit-segment',
    {
      hideLeftMenu: true,
    },
    [],
  )
  return <EditSegmentRoute />
}

export function EditCourse() {
  const { courseId } = useParams()
  useBreadcrumb({ title: <CourseName courseId={courseId} /> })
  return (
    <>
      <PageHead title={'Edit Course'} />
      <Routes>
        <Route path={'/'} element={<ViewCoursePage courseId={courseId} />} />
        <Route path={':courseVersionId'} element={<ViewCoursePage courseId={courseId} />} />
        <Route path={':courseVersionId/:segmentId'} element={<EditSegment />} />
      </Routes>
    </>
  )
}

export function Courses() {
  useBreadcrumb({ title: 'Courses' })
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <>
            <PageHead title={'Courses'} />
            <PageHeader title={'Courses'}>
              <ImportButton />
              <CreateCourseButton />
            </PageHeader>
            <CoursesTable />
          </>
        }
      />
      <Route path={':courseId/*'} element={<EditCourse />} />
    </Routes>
  )
}
