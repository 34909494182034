import { useFeatureRoot, useResource } from '@thesisedu/feature-react'
import { styled, s, Text } from '@thesisedu/ui'
import { Link } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassSyncProviderResource } from '../../types'

export const ICON_SIZE_PX = 32

export interface RosteringHeaderProps {
  className: string
  providerClassName: string
  providerIdentifier: string
  providerName: string
}
export function RosteringHeader({
  className,
  providerClassName,
  providerIdentifier,
  providerName,
}: RosteringHeaderProps) {
  const appOptions = useFeatureRoot().appOptions
  const provider = useResource<ClassSyncProviderResource>('ClassSyncProvider', providerIdentifier)
  if (!provider) throw new Error(`Cannot find provider '${providerIdentifier}'`)
  return (
    <Container>
      <ProductLogo src={appOptions.icon()} alt={`${appOptions.name} Logo`} />
      <Text truncate style={{ maxWidth: '35%' }}>
        {className}
      </Text>
      <CenterContainer>
        <CenterDot />
        <CenterDot />
        <CenterIcon>
          <Link />
        </CenterIcon>
        <CenterDot />
        <CenterDot />
      </CenterContainer>
      <Text truncate style={{ maxWidth: '35%' }}>
        {providerClassName}
      </Text>
      <Logo src={provider.logos.square} alt={`${providerName} logo`} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  margin-bottom: ${s.size('s')};
`
const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${s.size('xxs')};
  margin: auto;
`
const CenterDot = styled.div`
  width: ${s.size('xxs')};
  height: ${s.size('xxs')};
  border-radius: 50%;
  background: ${s.color('active')};
`
const CenterIcon = styled.div`
  font-size: ${s.size('font.s')};
  color: ${s.color('text')};
`
export const Logo = styled.img`
  background: ${s.color('element')};
  border-radius: ${s.var('radii.1')};
  overflow: hidden;
  width: ${ICON_SIZE_PX}px;
  height: ${ICON_SIZE_PX}px;
`
const ProductLogo = styled(Logo)`
  padding: ${s.size('xxs')};
`
