import { TeacherSelectorModal } from '@thesisedu/feature-teachers-react'
import { Dropdown, useToast } from '@thesisedu/ui'
import { ShareIos } from '@thesisedu/ui/icons'
import React from 'react'

import { ShareActionItemProps } from './types'
import { useShareActionItem } from './useShareActionItem'

export function ShareActionItem({ shareable, ...props }: ShareActionItemProps) {
  const toast = useToast()
  const { teacherModalProps, messageReg, messageBold, disabled } = useShareActionItem(
    { shareable },
    () => toast.success('Content shared successfully!'),
  )
  return (
    <TeacherSelectorModal
      {...teacherModalProps}
      trigger={
        <Dropdown.Item disabled={disabled} {...props} icon={<ShareIos />}>
          <span>
            {messageReg}
            {messageBold ? <strong>{` ${messageBold}`}</strong> : null}
          </span>
        </Dropdown.Item>
      }
    />
  )
}
