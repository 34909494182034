import { getFragmentDefinitions } from '@apollo/client/utilities'
import { FeatureResource } from '@thesisedu/feature'
import { useResource } from '@thesisedu/feature-react'

import { ReportMetricInput, ReportDimensionInput } from '../../schema'
import {
  ReportMetricResource,
  ReportDimensionResource,
  SelectedDimension,
  RunReportOpts,
} from '../types'

export function getInputIdentifier(input: ReportDimensionInput | ReportMetricInput): string {
  if (Object.keys(input).length !== 1) {
    throw new Error('Invalid input for dimension or metric.')
  }
  return Object.keys(input)[0]
}

export function getDimensionInputFromSelected(
  resources: ReportDimensionResource<object, object>[],
  selected: SelectedDimension,
): ReportDimensionInput {
  const resource = resources.find(r => r.identifier === selected.identifier)
  if (!resource) {
    throw new Error(`Cannot find Dimension: '${selected.identifier}'`)
  }
  const input = resource.getInput
    ? resource.getInput(selected.options, selected.context)
    : selected.options
  return {
    [resource.inputKey]: input,
  }
}

export function dimensionsSupportMultipleMetrics(
  resources: ReportDimensionResource<object, object>[],
  selections: SelectedDimension[],
): boolean {
  const dimensionResources = getSelectedResources(resources, selections)
  return dimensionResources.length === 1 || !dimensionResources[0].hasMultipleValues
}

export function getSelectedResources(
  resources: ReportMetricResource[],
  input: ReportMetricInput[],
): ReportMetricResource[]
export function getSelectedResources(
  resources: ReportDimensionResource<object, object>[],
  input: SelectedDimension[],
): ReportDimensionResource<object, object>[]
export function getSelectedResources(
  resources: FeatureResource[],
  input: SelectedDimension[] | ReportMetricInput[],
): FeatureResource[] {
  return input
    .map(item => {
      if (!item) return null
      let identifier: string
      if ((item as SelectedDimension).identifier) {
        identifier = (item as SelectedDimension).identifier
      } else {
        if (Object.keys(item).length !== 1) {
          throw new Error(`Invalid ${resources[0]?.type || 'resource'} found.`)
        }
        identifier = Object.keys(item)[0]
      }
      const resource = resources.find(r => r.identifier === identifier)
      if (!resource) {
        throw new Error(`Cannot find ${resources[0]?.type || 'resource'}: ${identifier}`)
      }
      return resource
    })
    .filter(Boolean) as FeatureResource[]
}

export function getDimensionFragments(
  selectedDimensions: ReportDimensionResource<object, object>[],
): string {
  const resultItems: string[] = []
  for (const dim of selectedDimensions) {
    resultItems.push(dim.fragment.loc!.source.body)
    if (dim.summary?.queryPieceFragments?.length) {
      for (const document of dim.summary.queryPieceFragments) {
        resultItems.push(document.loc!.source.body)
      }
    }
  }
  return resultItems.join('\n')
}

export function getDimensionFragmentNames(
  dimensions: ReportDimensionResource<object, object>[],
): { key: string; name: string }[] {
  return dimensions
    .map(dimension => {
      const fragmentDefinitions = getFragmentDefinitions(dimension.fragment)
      const name = fragmentDefinitions[0]?.name.value
      return name ? { key: dimension.inputKey, name } : null
    })
    .filter(Boolean) as { key: string; name: string }[]
}

export function getSummaryInfo(
  dimensions: ReportDimensionResource<object, object>[],
  opts: RunReportOpts,
): InternalSummaryInfo | null {
  const dimensionWithSummary = dimensions.find(dim => dim.summary)
  if (dimensionWithSummary?.summary) {
    const dimensionOpt = opts.dimensions.find(
      dim => dim.identifier === dimensionWithSummary.identifier,
    )
    if (!dimensionOpt)
      throw new Error('Cannot find dimensionOpt for ' + dimensionWithSummary.identifier)
    return {
      key: dimensionWithSummary.summary.summariesKey,
      identifier: dimensionWithSummary.identifier,
      getQueryPiece: dimensionWithSummary.summary.getQueryPiece,
      input: dimensionWithSummary.summary.getInput
        ? dimensionWithSummary.summary.getInput(dimensionOpt.options, dimensionOpt.context)
        : undefined,
    }
  } else {
    return null
  }
}

interface InternalSummaryInfo {
  identifier: string
  key: string
  input?: Record<string, any>
  getQueryPiece: (
    reportDimensionResult: string,
    reportMetricSummaries: string,
    allMetricSummaries: string,
  ) => string
}
export function getResultSelection(
  summary: InternalSummaryInfo | null,
  reportDimensionResult: string,
  reportMetricSummaries: string,
  allMetricSummaries: string,
) {
  if (summary) {
    const key = summary.input
      ? `
      ${summary.key}(input: {
        ${Object.keys(summary.input)
          .map(inputKey => `${inputKey}: ${JSON.stringify(summary.input![inputKey])}`)
          .join('\n')}
      })
    `
      : summary.key
    return `
      summaries {
        ${key} {
          ${summary.getQueryPiece(reportDimensionResult, reportMetricSummaries, allMetricSummaries)}
        }
      }
    `
  } else {
    return `
      results {
        dimensions {
          ${reportDimensionResult}
        }
        metrics {
          ${reportMetricSummaries}
        }
      }
    `
  }
}

export function getDimensionSummaries(
  dimensions: ReportDimensionResource<object, object>[],
  reportDimensionResult: string,
  allMetricSummaries: string,
): string | null {
  const hasMultipleDimensions = dimensions.length > 1
  const hasDimensionsWithSummary = dimensions.some(d => d.hasMultipleValues)
  if (hasMultipleDimensions && hasDimensionsWithSummary) {
    return `
      dimensionSummaries {
        dimensions {
          ${reportDimensionResult}
        }
        metrics {
          ${allMetricSummaries}
        }
      }
    `
  } else {
    return null
  }
}

export interface ReportResources {
  dimensions: ReportDimensionResource<any, any>[]
  metrics: ReportMetricResource[]
}
export function useReportResources(): ReportResources {
  return {
    dimensions: useResource<ReportDimensionResource<any, any>>('ReportDimension'),
    metrics: useResource<ReportMetricResource>('ReportMetric'),
  }
}
