import { Feature, FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import React from 'react'

export enum BrowserType {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Opera = 'Opera',
  Yandex = 'Yandex',
  Safari = 'Safari',
  InternetExplorer = 'Internet Explorer',
  Edge = 'Edge',
  Chromium = 'Chromium',
  IE = 'IE',
  MobileSafari = 'Mobile Safari',
  EdgeChromium = 'Edge Chromium',
  ChromeHeadless = 'Chrome Headless',
  MIUI = 'MIUI Browser',
}
export interface BrowserDetectOptions {
  supportedBrowsers: BrowserType[]
  supportLink: string
}

export type RequiredBrowserDetectOptions = Partial<BrowserDetectOptions>

export class BrowserDetectWebFeature extends Feature {
  static package = '@thesisedu/feature-browser-detect-web'
  static path = __dirname
  static requires = []
  public options: BrowserDetectOptions

  constructor(opts: RequiredBrowserDetectOptions, deps: FeatureDependencies) {
    super(opts, deps)
    this.options = {
      supportedBrowsers: [
        BrowserType.Chrome,
        BrowserType.Firefox,
        BrowserType.EdgeChromium,
        BrowserType.Chromium,
        BrowserType.ChromeHeadless,
        BrowserType.MobileSafari,
        BrowserType.Opera,
        BrowserType.Safari,
      ],
      supportLink: `https://${this.appOptions.domain}/support/portal/system-and-browser-requirements`,
      ...opts,
    }
    this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, async children => {
      const {
        browserName: _browserName,
        CustomView,
        isEdgeChromium,
        isLegacyEdge,
      } = require('react-device-detect')
      const { Alert } = require('antd')
      // This is a workaround because the library does not currently detect the
      // Edge Chromium type properly.
      const browserName =
        _browserName === BrowserType.Edge && isEdgeChromium && !isLegacyEdge
          ? BrowserType.EdgeChromium
          : _browserName
      return (
        <>
          <CustomView condition={!this.options.supportedBrowsers.includes(browserName)}>
            <Alert
              banner
              type={'error'}
              message={
                <span>
                  Your browser, "{browserName}," is <strong>not supported</strong> by the{' '}
                  {this.appOptions.name} app. To prevent service interruption you should{' '}
                  <strong>upgrade your browser</strong> to one from{' '}
                  <a href={this.options.supportLink} target={'_blank'} rel={'nofollow noreferrer'}>
                    our supported browser list.
                  </a>
                </span>
              }
            />
          </CustomView>
          {children}
        </>
      )
    })
  }
}

export const browserDetectWebFeature = (
  opts: RequiredBrowserDetectOptions = {},
): FeatureUse<RequiredBrowserDetectOptions> => [BrowserDetectWebFeature, opts]
