import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import Checkbox, * as Checkbox$ from '../Checkbox'

function _FormCheckbox<TFieldValues extends FieldValues, FieldName extends FieldPath<TFieldValues>>(
  props: ConnectedFormFieldProps<Checkbox$.CheckboxProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  return (
    <FormFieldConnector {...props} valuePropName={'checked'}>
      <Checkbox ref={ref} />
    </FormFieldConnector>
  )
}
export const FormCheckbox = React.forwardRef(_FormCheckbox)

function _FormCheckboxGroup<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  {
    children,
    ...props
  }: ConnectedFormFieldProps<Checkbox$.CheckboxGroupProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <Checkbox.Group ref={ref} children={children} />
    </FormFieldConnector>
  )
}
export const FormCheckboxGroup = React.forwardRef(_FormCheckboxGroup)
