import { FeatureResourceManager } from '@thesisedu/feature'
import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'

import enableFreeCompose from './enableFreeCompose'
import hideKeyboardField from './hideKeyboardField'

const RESOURCES: ClassConfigurationFieldResource[] = [hideKeyboardField, enableFreeCompose]
export function addClassConfigurationFields(resources: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resources.addResource(resource)
  }
}
