import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useStudentUser } from '@thesisedu/feature-classes-react'
import { useMutateHook } from '@thesisedu/feature-react'
import { HSpaced, HSpaced$, Text, Text$, s, styled } from '@thesisedu/ui'
import { Menu } from '@thesisedu/ui/icons'
import React from 'react'

import { GroupsStudent, GroupsStudentDraggableHook } from './types'

export interface StudentDraggableContentProps extends Omit<HSpaced$.HSpacedProps, 'right'> {
  student: GroupsStudent
  right?: React.ReactElement
  dragOverlay?: boolean
  isDragging?: boolean
  handleProps?: Text$.TextProps & { ref?: React.ForwardedRef<HTMLDivElement> }
}
function _StudentDraggableContent(
  { handleProps, student, right, dragOverlay, isDragging, ...rest }: StudentDraggableContentProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { student: loadedStudent } = useStudentUser(student.studentId, student.classId)
  const content = (
    <Container
      data-testid={'StudentDraggable'}
      justify={'space-between'}
      ref={ref}
      {...rest}
      data-drag-overlay={!!dragOverlay}
      data-is-dragging={!!isDragging}
    >
      <HSpaced space={'xs'}>
        <Text as={'div'} {...handleProps} ref={handleProps?.ref}>
          <Menu />
        </Text>
        <Text>{loadedStudent?.user.name ?? loadedStudent?.user.username ?? '--'}</Text>
      </HSpaced>
      {right}
    </Container>
  )

  return useMutateHook<GroupsStudentDraggableHook>(
    'assignments-react:groups-student-draggable',
    content,
    {
      studentId: student.studentId,
      classId: student.classId,
      loadedStudent,
    },
  )
}
export const StudentDraggableContent = React.forwardRef(_StudentDraggableContent)
const Container = styled(HSpaced)`
  &[data-drag-overlay='true'] {
    background: ${s.color('background')};
    padding: ${s.size('xs')};
    min-width: 300px;
    box-shadow: ${s.var('shadow.1')};
  }
  &[data-is-dragging='true'] {
    background: ${s.color('element')};
    border-radius: ${s.var('radii.1')};
    * {
      opacity: 0 !important;
    }
  }
`

export interface StudentDraggableProps
  extends Omit<StudentDraggableContentProps, 'dragOverlay' | 'isDragging'> {}
export function StudentDraggable({ student, right }: StudentDraggableProps) {
  const { isDragging, listeners, transform, transition, setNodeRef, setActivatorNodeRef } =
    useSortable({
      id: student.studentId,
    })
  return (
    <StudentDraggableContent
      student={student}
      right={right}
      isDragging={isDragging}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Transform.toString(transform),
      }}
      handleProps={{
        ref: setActivatorNodeRef,
        ...listeners,
      }}
    />
  )
}
