import { Button, HSpaced, Popover, Popover$ } from '@thesisedu/ui'
import React from 'react'

import { getFlag, setFlag, useFlag } from '../common'
import { debug, warn } from '../log'

const isE2e = localStorage.getItem('e2e-testing') === 'true'

// This flag is here to make sure we only render one of these on the page, every time
// the page is refreshed. That way the teacher isn't inundated with these popovers.
let didEmbedKeys: string[] = []

// This is to make sure only one popover automatically shows at the same time on the page.
let visiblePopoverKey: string | null = null
let keyTimeout: any = null

// These resetCallbacks are used in the ResetTutorialPopoversButton.
type ResetCallback = () => void
let resetCallbacks: ResetCallback[] = []
const resetFlags = new Set<string>()
export function performResetCallbacks() {
  didEmbedKeys = []
  for (const flag of resetFlags) {
    setFlag(flag, null)
  }
  resetFlags.clear()
  visiblePopoverKey = null
  keyTimeout = null
  for (const callback of resetCallbacks) {
    callback()
  }
}

export interface DismissablePopoverProps extends Omit<Popover$.ContainerProps, 'children'> {
  contentProps?: Popover$.ContentProps
  className?: string
  /**
   * The date this dismissable popover automatically expires at. If this date has
   * passed, a message is logged to the console and nothing is rendered. Use this
   * for setting an expiration date on new feature notifications so they don't show
   * up to the user forever (and thus annoy them).
   */
  expiresAt?: string
  dismissableKey: string
  content: React.ReactNode
  children: React.ReactNode | ((isExpired: boolean, isDismissed: boolean) => React.ReactNode)
  /**
   * If the footer is not shown, the popover is automatically hidden forever whenever
   * it is closed for the first time.
   */
  noFooter?: boolean
  autoShow?: boolean
}
export function DismissablePopover({
  children: _children,
  open,
  onOpenChange,
  content,
  className,
  expiresAt,
  dismissableKey,
  autoShow,
  noFooter,
  contentProps,
  ...rest
}: DismissablePopoverProps) {
  const isExpired = React.useMemo(() => {
    return !!expiresAt && new Date(expiresAt) < new Date()
  }, [expiresAt])
  const [_shouldEmbed, setShouldEmbed] = React.useState(false)
  const [dismissed] = useFlag(dismissableKey, false)
  const shouldEmbed = _shouldEmbed || open
  const [_visible, _setVisible] = React.useState(true)
  const visible = _visible || open
  const setVisible = (visible: boolean) => {
    _setVisible(visible)
    onOpenChange?.(visible)
  }
  const allowNoFooterSetFlagRef = React.useRef(false)

  // Effects to manage shouldEmbed to make sure we don't show all of the popovers
  // at the same time whenever a new page is loaded (as that would be annoying).
  React.useEffect(() => {
    if (visible && shouldEmbed) {
      clearTimeout(keyTimeout)
      keyTimeout = setTimeout(() => {
        visiblePopoverKey = dismissableKey
      }, 100)
    } else if (visiblePopoverKey === dismissableKey) {
      visiblePopoverKey = null
    }
  }, [visible, shouldEmbed, dismissableKey])
  React.useEffect(() => {
    if (!visible) {
      if (noFooter && allowNoFooterSetFlagRef.current) {
        setFlag(dismissableKey, true)
      }
    }
  }, [visible])

  // Automatically show the popover if autoShow is true.
  React.useEffect(() => {
    const callback = async () => {
      const flag = await getFlag(dismissableKey)
      if (autoShow && !isExpired && !didEmbedKeys.includes(dismissableKey) && !flag) {
        if (!visiblePopoverKey) {
          didEmbedKeys.push(dismissableKey)
          setShouldEmbed(true)
          setVisible(true)
          allowNoFooterSetFlagRef.current = true
        } else {
          debug(
            "not showing DismissablePopover with key %s - we're already showing another one with autoShow enabled (%s)",
            dismissableKey,
            visiblePopoverKey,
          )
        }
      }
    }
    resetFlags.add(dismissableKey)
    resetCallbacks.push(callback)
    callback()
    return () => {
      const index = resetCallbacks.indexOf(callback)
      resetCallbacks = resetCallbacks.splice(index, 1)
    }
  }, [autoShow])

  let children = typeof _children === 'function' ? _children(isExpired, dismissed) : _children
  if (isExpired) {
    warn('DismissablePopover %s is expired and should be removed from the codebase', dismissableKey)
    return <>{children}</>
  } else if (process.env.REACT_APP_ENVIRONMENT === 'test' || isE2e) {
    debug('hiding DismissablePopover %s in test environment', dismissableKey)
    return <>{children}</>
  }

  children = (
    <div
      style={{ width: 'fit-content' }}
      onClick={e => {
        e.stopPropagation()
      }}
      onMouseEnter={
        autoShow
          ? undefined
          : async () => {
              allowNoFooterSetFlagRef.current = true
              const flag = await getFlag(dismissableKey)
              const willEmbed =
                !didEmbedKeys.includes(dismissableKey) && !flag && !visiblePopoverKey
              if (willEmbed) {
                didEmbedKeys.push(dismissableKey)
                setShouldEmbed(true)
              }
            }
      }
    >
      {children}
    </div>
  )

  if (shouldEmbed) {
    return (
      <Popover.Container {...rest} open={visible} onOpenChange={setVisible}>
        <Popover.Trigger>
          <div style={{ display: 'inline-block' }}>{children}</div>
        </Popover.Trigger>
        <Popover.Content className={className} {...contentProps}>
          {content}
          {noFooter ? null : (
            <HSpaced justify={'flex-end'} top={'s'}>
              <Button
                variant={'chromeless'}
                size={'small'}
                children={"Don't show again"}
                onPress={async () => {
                  await setFlag(dismissableKey, true)
                  setVisible(false)
                }}
              />
              <Button size={'small'} onPress={() => setVisible(false)} children={'Close'} />
            </HSpaced>
          )}
        </Popover.Content>
      </Popover.Container>
    )
  } else {
    return children
  }
}
