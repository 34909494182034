import { EditorValue, filter, getDefaultEditorValue, Nodes } from '@thesisedu/feature-widgets-react'

import { FormValue } from './StandardSetForm'
import {
  CreateStandardSetInput,
  StandardSetTagInput,
  StandardSetTagsFragment,
  TagsTreeItemFragment,
  UpdateStandardSetInput,
} from '../schema'

interface StandardSetTagInputTree extends StandardSetTagInput {
  children?: StandardSetTagInputTree[]
}

export function treeToTagInput(
  tree: StandardSetTagInputTree[],
  parentNames: string[] = [],
): StandardSetTagInput[] {
  let items: StandardSetTagInput[] = []
  for (const { children, ...i } of tree) {
    items.push({
      ...i,
      parents: parentNames,
    })
    if (children?.length) {
      items = [...items, ...treeToTagInput(children, [...parentNames, i.name])]
    }
  }

  return items
}

function _tagTreeItemsToStandardSetTrees(
  items: (TagsTreeItemFragment & { children?: TagsTreeItemFragment[] })[],
  parentNames: string[] = [],
): StandardSetTagInputTree[] {
  return items.map(item => ({
    name: item.tag.name,
    weight: item.tag.weight,
    parents: parentNames,
    children: item.children
      ? _tagTreeItemsToStandardSetTrees(item.children, [...parentNames, item.tag.name])
      : undefined,
  }))
}

function standardSetToInputTree(standardSet: StandardSetTagsFragment): StandardSetTagInputTree[] {
  return _tagTreeItemsToStandardSetTrees(standardSet.tagsTree)
}

export function valueTreeToEditorValue(tree: StandardSetTagInputTree[]): EditorValue {
  function mapChildren(items: StandardSetTagInputTree[], depth: number = 0): Nodes.MaybeElement[] {
    return items.flatMap((item, index) => {
      const hasChildren = !!item.children?.length
      const listItem = Nodes.createListItem({ label: item.name, zeroBasedIndex: index, depth })
      if (hasChildren) {
        return [
          listItem,
          Nodes.createListAsListItem({
            zeroBasedIndex: index + 1,
            depth,
            children: mapChildren(item.children!, depth + 1),
          }),
        ]
      } else return listItem
    })
  }

  return getDefaultEditorValue([Nodes.createList({ children: mapChildren(tree) })])
}

export function standardSetToEditorValue(standardSet: StandardSetTagsFragment): EditorValue {
  const inputTree = standardSetToInputTree(standardSet)
  return valueTreeToEditorValue(inputTree)
}

const LIST_TYPES = ['root', 'text', 'list', 'listitem', 'paragraph']
function editorToValueTree(editor: EditorValue): StandardSetTagInputTree[] {
  const filteredNodes = filter(editor, node => LIST_TYPES.includes(node.type))
  function processChildren(
    children: Nodes.MaybeElement[],
    parentNames: string[] = [],
  ): StandardSetTagInputTree[] {
    const result: StandardSetTagInputTree[] = []
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      const nextChild = children[i + 1]
      if (child.type === 'listitem') {
        const hasChildren =
          nextChild?.type === 'listitem' && nextChild.children?.[0]?.type === 'list'
        const firstChild = child.children![0] as Nodes.MaybeElement | undefined
        const text = (firstChild?.type === 'paragraph' ? firstChild.children : child.children)
          ?.filter(c => c.type === 'text')
          .map(c => (c as Nodes.Text).text)
          .join('')
        if (text) {
          result.push({
            name: text,
            parents: parentNames,
            children: hasChildren
              ? processChildren(
                  (nextChild.children![0] as Nodes.ListItem).children as Nodes.MaybeElement[],
                  [...parentNames, text],
                )
              : undefined,
            weight: i,
          })
        }
      } else if (child.type === 'list' && i === 0) {
        // We are at the root list.
        result.push(...processChildren(child.children as Nodes.MaybeElement[]))
      }
    }

    return result
  }

  return processChildren(filteredNodes.root.children)
}

export function formValueToInput<
  T extends CreateStandardSetInput | Omit<UpdateStandardSetInput, 'id'>,
>(value: FormValue): T {
  return {
    ...value,
    tags: treeToTagInput(editorToValueTree(value.tags)),
  } as T
}
