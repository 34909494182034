import { s } from '@thesisedu/ui'
import React from 'react'

import { PlacementDrawerDroppedOutlineItem } from './PlacementDrawerDroppedOutlineItem'
import { useDragDropOutlineContext } from '../../outline/DragDropOutlineContext'
import {
  SplitViewDraggableOutlineItem,
  SplitViewDraggableOutlineItemProps,
} from '../SplitViewDraggableOutlineItem'
import { useSplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'

export interface PlacementDrawerDraggableOutlineItemProps
  extends SplitViewDraggableOutlineItemProps {}
export function PlacementDrawerDraggableOutlineItem(
  props: PlacementDrawerDraggableOutlineItemProps,
) {
  const { addedSegmentIds, changedSegmentIds } = useSplitViewPendingChangesContext(true)
  const { draggingId } = useDragDropOutlineContext(true)
  const hasChange =
    addedSegmentIds.includes(props.item.id) || changedSegmentIds.includes(props.item.id)
  if (hasChange && !draggingId) {
    return (
      <s.ThemeProvider colorVariant={'dark'}>
        <PlacementDrawerDroppedOutlineItem {...props} />
      </s.ThemeProvider>
    )
  } else {
    return <SplitViewDraggableOutlineItem {...props} disabled />
  }
}
