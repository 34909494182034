import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { ClassSelectorText, useClasses, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Tooltip } from '@thesisedu/react'
import { styled, H3Alternate, StyledThemeContext } from '@thesisedu/web'
import React from 'react'

import { useCourseContext } from '../../../contexts/CourseContext'

export interface ShellClassHeaderProps {
  color?: string
  icon?: string
  leftMenuCollapsed?: boolean
}
export function ShellClassHeader({ color, icon, leftMenuCollapsed }: ShellClassHeaderProps) {
  const { classes } = useClasses()
  const selectedClassId = useSelectedClassId()
  const cls = classes.find(cls => cls.id === selectedClassId)
  const { bakedCourseConfiguration } = useCourseContext(false) || {}
  const rawIconPath = (bakedCourseConfiguration as any)?.icon || icon
  const iconPath = rawIconPath ? getFileInfo(rawIconPath).filename : undefined
  const theme = React.useContext(StyledThemeContext)
  if (cls) {
    return (
      <Container
        className={leftMenuCollapsed ? 'collapsed' : undefined}
        color={(bakedCourseConfiguration as any)?.color || color}
      >
        <ContainerLeft
          style={
            bakedCourseConfiguration || color
              ? { background: theme[`@${color || (bakedCourseConfiguration as any).color}`] }
              : undefined
          }
        />
        <ClassSelectorText className={'selector-text'}>
          <Tooltip
            title={leftMenuCollapsed ? cls.name : ''}
            placement={'right'}
            mouseEnterDelay={0}
          >
            <CollapsedIconContainer>
              <img src={iconPath} alt={cls.name} />
            </CollapsedIconContainer>
          </Tooltip>
          <H3Alternate isBlock={false}>{cls.name}</H3Alternate>
        </ClassSelectorText>
      </Container>
    )
  } else {
    return null
  }
}

const CollapsedIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.1s linear;
  > img {
    width: 42px;
  }
`
const ContainerLeft = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
  transition: background 0.25s linear;
  background: transparent;
  border-bottom-right-radius: 5px;
`
const Container = styled.div<{ color: string }>`
  position: relative;
  padding: ${props => props.theme['@size-m']} ${props => props.theme['@size-m']}
    ${props => props.theme['@size-xs']} ${props => props.theme['@size-m']};
  transition: background 0.1s linear 0.05s;
  h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    min-width: 0;
    transition: opacity 0.1s linear 0.25s;
  }
  &.collapsed {
    transition-delay: 0.25s;
    background: ${props => props.theme[`@${props.color}`]};
    h3 {
      transition-delay: 0s;
      opacity: 0;
      pointer-events: none;
    }
    ${CollapsedIconContainer} {
      transition-delay: 0.3s;
      opacity: 1;
    }
  }
`
