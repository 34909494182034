import { ReportMetricResource } from '@thesisedu/feature-reports-react'
import { BookmarkBook } from '@thesisedu/react/icons'
import React from 'react'

import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export default function addMetric(feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportMetricResource>({
    type: 'ReportMetric',
    metricKey: 'contentVisited',
    summarization: 'average',
    identifier: 'contentVisited',
    icon: <BookmarkBook />,
    title: 'Content Visited',
    description: 'The percentage of content visited.',
    shortLabel: 'content visited',
    format: 'percent',
    graphAxisMax: 1,
  })
}
