import { Dropdown } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import React from 'react'

import { useCopySegmentToClasses } from './useCopySegmentToClasses'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function CopySegmentToClassItem({ segment }: SegmentActionComponentProps) {
  const [visible, setVisible] = React.useState(false)
  const { copy, otherClasses, progressModal, disabled } = useCopySegmentToClasses()
  if (otherClasses.length) {
    return (
      <Dropdown.SubMenu>
        <Dropdown.SubMenu.Trigger
          icon={<Copy />}
          children={'Copy to class...'}
          disabled={disabled}
        />
        <Dropdown.SubMenu.Content>
          {progressModal}
          {otherClasses.map(cls => (
            <Dropdown.Item
              key={cls.id}
              onClick={async () => {
                setVisible(true)
                try {
                  await copy(segment, [cls.id])
                } finally {
                  setVisible(false)
                }
              }}
              forceMount={visible}
            >
              {cls.name}
            </Dropdown.Item>
          ))}
        </Dropdown.SubMenu.Content>
      </Dropdown.SubMenu>
    )
  } else {
    return null
  }
}

export const CopySegmentToClassAction: SegmentActionResource = {
  identifier: 'copy-segment-to-class',
  type: 'SegmentAction',
  component: CopySegmentToClassItem,
  group: 'Settings',
  weight: 201,
  handler: () => {},
}
