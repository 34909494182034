import { SPACE, VERTICAL_SPACE } from './constants'
import { DraggableBlockElement } from './useDraggableBlockMenu'
import { Rect } from '../../utils/rect'

export function setMenuPosition(
  draggableElement: DraggableBlockElement | null,
  floatingElement: HTMLElement,
  anchorElement: HTMLElement,
) {
  if (!draggableElement) {
    floatingElement.style.opacity = '0'
    floatingElement.style.transform = 'translate(-10000px, -10000px)'
    return
  }

  const { element: _element, node } = draggableElement
  const element = node.modifyMenuDOMElement ? node.modifyMenuDOMElement(_element) : _element
  const targetRect = Rect.fromDOM(element)
  const targetStyle = window.getComputedStyle(element)
  const { width: _markerWidth } = window.getComputedStyle(element, '::marker')
  let markerWidth = parseFloat(_markerWidth.replace('px', ''))
  if (isNaN(markerWidth)) markerWidth = 0
  const floatingElementRect = floatingElement.getBoundingClientRect()
  const anchorElementRect = anchorElement.getBoundingClientRect()

  const top = targetRect.top - anchorElementRect.top - VERTICAL_SPACE
  const left =
    targetRect.left - floatingElementRect.width - markerWidth - SPACE - anchorElementRect.left
  const height = targetRect.height
  floatingElement.style.opacity = '1'
  floatingElement.style.transform = `translate(${left}px, ${top}px)`
  floatingElement.style.height = `${height}px`
  floatingElement.style.setProperty('--line-height', targetStyle.lineHeight)
}
