import { UserSource, UseUsers } from '@thesisedu/feature-direct-messages-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { useParams } from 'react-router-dom'

import { UserFragment } from './schema'
import { useAllStudentsQuery } from './useAllStudentsQuery'

export const useUsers: UseUsers = () => {
  const viewer = useViewerContext(false)
  const { classes: _classes, loading, error } = useAllStudentsQuery()
  const { classId } = useParams()
  if (viewer?.group === 'TEACHER') {
    const classes = _classes?.filter(cls => !classId || cls.id === classId) || []
    const sources: UserSource[] = []
    for (const cls of classes) {
      const classUserIds = cls.students.edges
        .filter(edge => (edge.node.user as any).canMessage)
        .map(edge => edge.node.user.id)
      if (classUserIds.length) {
        sources.push({
          label: `All in ${cls.name}`,
          value: `students-in-${cls.id}`,
          group: cls.name,
          userIds: cls.students.edges.map(edge => edge.node.user.id),
          weight: 0,
        })
      }
      for (const student of cls.students.edges
        .filter(edge => (edge.node.user as any).canMessage)
        .map(edge => edge.node)) {
        sources.push({
          label: student.user.name || student.user.username,
          value: `${cls.id}-${student.user.id}`,
          group: cls.name,
          userIds: [student.user.id],
          weight: 1,
        })
      }
    }
    return { sources, loading, error }
  } else if (viewer?.group === 'STUDENT') {
    const classes = _classes?.filter(cls => !classId || cls.id === classId) || []
    const teachers: UserFragment[] = []
    for (const cls of classes) {
      for (const teacher of cls.teachers.edges.map(edge => edge.node)) {
        if (!teachers.some(t => t.id === teacher.user.id)) {
          teachers.push(teacher.user)
        }
      }
    }
    return {
      sources: teachers.map(teacher => ({
        label: teacher.name || teacher.username,
        value: teacher.id,
        group: 'Teachers',
        userIds: [teacher.id],
        weight: 0,
      })),
      loading,
      error,
    }
  } else {
    return { sources: [], loading: false }
  }
}
