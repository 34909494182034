import React from 'react'

import { useClassStudents } from './ClassStudents'

export interface DynamicStudentNameProps {
  studentId: string
}
export function DynamicStudentName({ studentId }: DynamicStudentNameProps) {
  const { loading, students } = useClassStudents()
  const studentUser = students?.find(student => student.id === studentId)?.user

  if (studentUser) {
    return <span>{studentUser.name || studentUser.username}</span>
  } else if (loading) {
    return <span>...</span>
  } else {
    return <span />
  }
}
