import { useQuery } from '@apollo/client'
import { useDeepLink } from '@thesisedu/feature-react'
import { FragmentCalendarView } from '@thesisedu/web'
import { message } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'

import { SearchResultNode } from '..'
import { OrderDirection, SearchQuery, SearchQueryVariables } from '../schema'
import { CommonSearchProps, RenderCalendar } from '../types'
import { useSearchQueryDocument } from '../useSearchQuery'
import {
  useValidRenderers,
  useValidRendererResources,
  useSearchResultRenderer,
  useSearchResultLinkHandler,
} from '../useSearchResultRenderer'

const MAX_MONTH_ITEMS = 500

export type SearchCalendarProps<Variables extends SearchQueryVariables = SearchQueryVariables> =
  Omit<CommonSearchProps<Variables>, 'orderBy' | 'orderDirection'>
export function SearchCalendar<Variables extends SearchQueryVariables = SearchQueryVariables>({
  filters,
  query,
  variables,
  searchDocument: customSearchDocument,
}: SearchCalendarProps<Variables>) {
  const coreSearchDocument = useSearchQueryDocument()
  const searchDocument = customSearchDocument ? customSearchDocument.document : coreSearchDocument
  const resultPath = customSearchDocument ? customSearchDocument.resultPath : 'search'
  const [endOfMonthString, setEndOfMonthString] = React.useState(moment().endOf('month').format())
  const validProviderResources = useValidRendererResources('renderCalendar')
  const validProviders = useValidRenderers('renderCalendar')
  const { navigate, linkHandler } = useDeepLink({ onError: message.error })
  const { data, loading } = useQuery<SearchQuery, SearchQueryVariables>(searchDocument, {
    variables: {
      ...variables,
      query,
      filters: {
        ...filters,
        providers: filters?.providers
          ? filters.providers.filter(p => validProviders.includes(p))
          : validProviders,
        createdAt: {
          after: moment(endOfMonthString).startOf('month').subtract(1, 'second').format(),
          before: endOfMonthString,
        },
      },
      first: MAX_MONTH_ITEMS,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Asc,
    },
  })

  const result = get(data, resultPath) as SearchQuery['search'] | undefined

  return (
    <>
      {linkHandler}
      <FragmentCalendarView<SearchResultNode>
        monthSelected={setEndOfMonthString}
        loading={loading}
        renderCalendarDate={date => {
          const itemsForDate =
            result?.edges.filter(edge => {
              const edgeResource = validProviderResources.find(
                p => p.identifier === edge.node.__typename,
              )
              if (edgeResource?.renderCalendar && edgeResource?.getDate) {
                return moment(edgeResource.getDate(edge.node)).isSame(date, 'day')
              } else {
                return false
              }
            }) || []
          return itemsForDate.map(item => (
            <SearchResultCalendarItemRenderer
              item={item.node}
              onClick={async path => {
                await navigate(path)
              }}
            />
          ))
        }}
      />
    </>
  )
}

interface SearchResultCalendarItemRendererProps {
  item: SearchResultNode
  onClick: (path: string) => Promise<void> | void
}
function SearchResultCalendarItemRenderer({
  item,
  onClick,
}: SearchResultCalendarItemRendererProps) {
  const Renderer = useSearchResultRenderer(
    'renderCalendar',
    item.__typename,
  ) as RenderCalendar<SearchResultNode>
  const getLink = useSearchResultLinkHandler(item.__typename)
  return Renderer ? (
    <Renderer
      item={item}
      onClick={async () => {
        if (getLink) {
          const path = getLink(item)
          if (path) {
            await onClick(path)
          }
        }
      }}
    />
  ) : null
}
