const base: Intl.NumberFormatOptions = {
  style: 'unit',
  unitDisplay: 'short',
  maximumFractionDigits: 1,
}
const NUMBER_FORMATTERS = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte', 'petabyte'].map(
  unit => {
    return new Intl.NumberFormat(undefined, { ...base, unit })
  },
)
const BYTES_PER_KB = 1000

export function formatFilesize(bytes: number | undefined | null): string | null
export function formatFilesize(bytes: number): string
export function formatFilesize(bytes: number | undefined | null): string | null {
  if (bytes !== undefined && bytes !== null) {
    let size = Math.abs(bytes)
    let formatterIndex = 0
    while (size >= BYTES_PER_KB && formatterIndex < NUMBER_FORMATTERS.length - 1) {
      size /= BYTES_PER_KB
      ++formatterIndex
    }

    return NUMBER_FORMATTERS[formatterIndex].format(size)
  } else {
    return null
  }
}
