import { FeatureRoot } from '@thesisedu/feature'
import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'

import { QuestionTypeResource } from '../types'

/**
 * Checks the assignment configuration to see if it is auto-gradeable.
 *
 * @param root the feature root
 * @param configuration the assignment configuration
 * @returns whether or not the assignment is automatically graded
 */
export function isAssignmentAutoGraded(
  root: FeatureRoot,
  configuration?: AssignmentConfiguration,
  mode: 'every' | 'some' = 'every',
): boolean {
  if (configuration) {
    const questionTypes =
      root.deps.resources.getResourcesForType<QuestionTypeResource>('QuestionType')
    return configuration.questions[mode](q => {
      const resource = questionTypes.find(qt => qt.identifier === q.type)
      return resource?.isAutoGraded && resource.isAutoGraded(q)
    })
  } else {
    return false
  }
}
