import React from 'react'

import { useSubmissionContext } from '../contexts/SubmissionContext'
import { debug } from '../log'
import {
  useAssignmentViewerContext,
  useAssignmentViewerFormContext,
} from '../questions/form/AssignmentViewerFormContext'

export function AutoSave() {
  const { onSaveSubmission, classId, assignment, submissionData } = useSubmissionContext(true)
  const { configuration } = useAssignmentViewerContext()
  const { completedFields, form } = useAssignmentViewerFormContext(true)
  const totalQuestions = configuration.questions.length
  React.useEffect(() => {
    const submissionDataKeys = Object.keys(submissionData || {}).filter(k => !!submissionData?.[k])
    if (completedFields >= totalQuestions && !submissionDataKeys.length) {
      debug('fields are complete; automatically saving...')
      onSaveSubmission({
        id: assignment.id,
        classId,
        submissionData: form.getValues(),
      })
    }
  }, [completedFields, totalQuestions])
  return null
}
