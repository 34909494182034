import { FocusRing as AriaFocusRing, AriaFocusRingProps } from '@react-aria/focus'
import React from 'react'

import { styled, s } from '../../'

export interface FocusRingProps extends AriaFocusRingProps {
  children: React.ReactElement
  className?: string
  style?: React.CSSProperties
}
function _FocusRing({ children, className, ...rest }: FocusRingProps) {
  return <AriaFocusRing {...rest} children={children} focusRingClass={className} />
}

const { css } = s
export const FocusStyle = css`
  outline: 2px solid var(--focus-ring-color, ${s.color('primary.solid')}) !important;
  outline-offset: ${s.var('size.0.1')};
`
const _StyledFocusRing = styled(_FocusRing)`
  ${FocusStyle}
`

export function FocusRing({ className, children, style, ...rest }: FocusRingProps) {
  return (
    <_StyledFocusRing
      {...rest}
      children={React.cloneElement(children, {
        className: [children.props.className, className].filter(Boolean).join(' '),
        style: { ...children.props.style, ...style },
      })}
    />
  )
}
