import { useResource } from '@thesisedu/feature-react'
import { Dropdown, HSpaced, Text } from '@thesisedu/ui'
import { Cpu } from '@thesisedu/ui/icons'
import React from 'react'

import { ReportMetricInput } from '../../schema'
import { getInputIdentifier, getSelectedResources } from '../execute/input'
import { ReportMetricResource } from '../types'

export interface MetricDropdownButtonProps {
  metric?: ReportMetricInput
  onChange?: (metric: ReportMetricInput) => void
}
export function MetricDropdownButton({ metric, onChange }: MetricDropdownButtonProps) {
  const metrics = useResource<ReportMetricResource>('ReportMetric')
  const selectedMetric = metric ? getSelectedResources(metrics, [metric])[0] : undefined
  const metricKey = metric ? getInputIdentifier(metric) : undefined
  return (
    <Dropdown.Container>
      <Dropdown.Button icon={selectedMetric?.icon ?? <Cpu />}>
        <Text color={selectedMetric ? undefined : 'secondary'}>
          {selectedMetric?.title ?? 'Select a Metric'}
        </Text>
      </Dropdown.Button>
      <Dropdown.Menu>
        {metrics.map(m => {
          return (
            <Dropdown.Item.Checkbox
              checked={metricKey === m.identifier}
              onCheckedChange={checked => {
                if (checked) onChange?.({ [m.identifier]: {} })
              }}
            >
              <HSpaced space={'xs'}>
                <span>{m.icon}</span>
                <span>{m.title}</span>
              </HSpaced>
            </Dropdown.Item.Checkbox>
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}

export interface MetricTypeSelectProps {
  value?: string
  onChange?: (metric: string) => void
}
export function MetricTypeSelect({ value, onChange }: MetricTypeSelectProps) {
  return (
    <MetricDropdownButton
      metric={value ? { [value]: {} } : undefined}
      onChange={metric => {
        if (onChange) {
          onChange(getInputIdentifier(metric))
        }
      }}
    />
  )
}
