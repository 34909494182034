import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { InfiniteQueryProps, InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { BlockSpin } from '@thesisedu/web'
import { Empty } from 'antd'
import { set, cloneDeep } from 'lodash'
import React from 'react'

import { InteractionList, InteractionListProps } from './InteractionList'
import {
  InteractionFragment,
  ViewerInteractionsQueryVariables,
  ViewerInteractionsQuery,
  ViewerInteractionsDocument,
} from './schema'
import { InteractionTypeResource } from './types'

export function useInteractionTypes() {
  const root = useFeatureRoot()!
  const resources =
    root.deps.resources.getResourcesForType<InteractionTypeResource>('InteractionType')
  return resources.map(resource => resource.identifier)
}

export interface ViewerInteractionsListProps
  extends Partial<
    InfiniteQueryProps<
      InteractionFragment,
      ViewerInteractionsQuery,
      ViewerInteractionsQueryVariables
    >
  > {
  types?: string[]
  interactionListProps?: Partial<InteractionListProps>
}
export function ViewerInteractionsList({
  types: _types,
  interactionListProps,
  ...props
}: ViewerInteractionsListProps) {
  const defaultTypes = useInteractionTypes()
  const types = _types || defaultTypes
  return (
    <InfiniteQuery<InteractionFragment, ViewerInteractionsQuery, ViewerInteractionsQueryVariables>
      document={ViewerInteractionsDocument}
      getResult={d => d?.viewer?.interactions}
      setResult={(p, n) => set(cloneDeep(p), 'viewer.interactions', n)}
      variables={{ types }}
      queryOpts={{ fetchPolicy: 'cache-and-network' }}
      {...props}
      children={({ data, loading }) => {
        const interactions = data?.viewer?.interactions.edges.map(edge => edge.node)
        if (interactions) {
          return <InteractionList interactions={interactions} {...interactionListProps} />
        } else if (loading) {
          return <BlockSpin />
        } else {
          return <Empty description={'No new activity!'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      }}
    />
  )
}
