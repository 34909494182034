import { Segment } from '../types'

export enum OutlineItemType {
  SEGMENT = 'segment',
  HEADER = 'header',
}
export interface OutlineItemPlace {
  /**
   * This is the parent of the segment, in this position. Sometimes, if the segment
   * is a group, this will be the segment itself because if someone
   * is hovering over this, we want them to place their content _inside_ this group.
   */
  parentSegment: Segment
  /**
   * This is the real, actual, parent of the segment. Only specified if it is different
   * from parentSegment.
   */
  trueParentSegment?: Segment
  /**
   * The maximum allowed depth for this outline item. If this is 0, that means the item
   * currently being moved cannot have any children. If this is 1, it can have 1 level
   * of children. If it is negative, the item cannot be dropped here at all, no matter
   * how many children.
   */
  maximumAllowedDepth: number
}
interface OutlineItemCommon {
  type: OutlineItemType
  depth: number
  id: string
  parentIds: string[]
  place: OutlineItemPlace
  collapsed?: boolean
  collapsible?: boolean
  isForeign?: boolean
}
export type SegmentOutlineItem = { segment: Segment } & OutlineItemCommon
export type NameOutlineItem = { name: string } & OutlineItemCommon
export type OutlineItem = SegmentOutlineItem | NameOutlineItem

type OutlineItemHeightMap = {
  [type in OutlineItemType]: number | number[]
}
export const DEPTH_INDENT_PX = 30
const SEGMENT_HEIGHT_PX = 60
const OUTLINE_ITEM_HEIGHT_MAP: OutlineItemHeightMap = {
  [OutlineItemType.SEGMENT]: SEGMENT_HEIGHT_PX,
  [OutlineItemType.HEADER]: [130, 130, 49, 40],
}
const FIRST_HEADER_HEIGHT = 60

export function getOutlineItemHeight(
  item: Pick<OutlineItem, 'type' | 'depth' | 'id'>,
  index: number,
  segmentHeight?: number,
): number {
  if (item.type === OutlineItemType.SEGMENT && segmentHeight !== undefined) return segmentHeight
  const height = OUTLINE_ITEM_HEIGHT_MAP[item.type]
  if (index === 0 && item.type === OutlineItemType.HEADER) return FIRST_HEADER_HEIGHT
  if (Array.isArray(height)) {
    // Default to the last item if the array is not long enough.
    return height[item.depth] !== undefined ? height[item.depth] : height[height.length - 1]
  } else {
    return height
  }
}

export function isItemWithSegment(item: OutlineItem): item is SegmentOutlineItem {
  return !!(item as SegmentOutlineItem).segment
}
