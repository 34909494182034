import React from 'react'
import styledComponents, {
  ThemedStyledInterface,
  StyledComponent as StyledStyledComponent,
  ThemeContext as StyledThemeContext,
  ThemedCssFunction,
  css as styledCss,
} from 'styled-components'

import { Theme } from './theme'

export const styled: ThemedStyledInterface<Theme> = styledComponents as ThemedStyledInterface<Theme>
export const css: ThemedCssFunction<Theme> = styledCss as ThemedCssFunction<Theme>
export const ThemeContext: React.Context<Theme> = StyledThemeContext as React.Context<Theme>

export type StyledComponent<
  Component extends keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>,
> = StyledStyledComponent<Component, Theme>

export function sif(condition: any) {
  return condition ? '&' : '&.noop'
}
