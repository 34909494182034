import { updateMutation } from '@thesisedu/feature-apollo-react'
import { UserTagsDocument } from '@thesisedu/feature-tags-react/dist/schema'

import {
  LessonFragment,
  LessonFragmentDoc,
  UpdateLessonTagsMutation,
  useUpdateLessonTagsMutation as useMutation,
} from '../schema'

export const useUpdateLessonTagsMutation: typeof useMutation = opts =>
  useMutation({
    update: updateMutation<UpdateLessonTagsMutation, LessonFragment>({
      fragment: LessonFragmentDoc,
      fragmentName: 'Lesson',
      path: 'updateNodeTags.node',
    }),
    refetchQueries: [{ query: UserTagsDocument }],
    ...opts,
  })
