import { flattenSegments } from '@thesisedu/feature-course-types'
import { DrawerProps } from 'antd/es/drawer'
import React, { useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { EditableSegmentContent, EditableSegmentContentProps } from './EditableSegmentContent'
import { EditableSegmentDrawer } from './EditableSegmentDrawer'
import { SegmentChildren } from './SegmentChildren'
import { SegmentListProps } from './SegmentList'
import { SegmentName, SegmentNameProps } from './SegmentName'
import { useSegmentResource } from './useSegmentResource'
import { WEEK_SIZE_DAYS } from '../constants'
import { useCourseEditorReadContext } from '../contexts/CourseEditorContext'
import { useSegmentDaysContext } from '../contexts/DaysContext'
import { SegmentContext } from '../contexts/SegmentContext'
import { useSegmentListContext } from '../contexts/SegmentListContext'
import { useSegmentOverrideContext } from '../contexts/SegmentOverrideContext'
import { getSegmentParents, isTeacherInfoSegment } from '../helpers'
import { warn } from '../log'

export interface EditableSegmentProps extends EditableSegmentContentProps {
  nameProps?: Partial<SegmentNameProps>
  addTypes?: SegmentListProps['addTypes']
  drawerProps?: DrawerProps
}
export function EditableSegment(props: EditableSegmentProps) {
  if (props.useStandaloneEditing) {
    return <EditableSegmentContent {...props} />
  } else {
    return <EditableSegmentName {...props} />
  }
}

export function EditableSegmentName({ nameProps, disabled, ...props }: EditableSegmentProps) {
  const [editingKey, setEditingKey] = useState<string | undefined>()
  const isOverrideMode = !!useSegmentOverrideContext(false)
  const { createdSegmentId, acknowledgeCreatedSegmentId } = useSegmentListContext(false) || {}
  const resource = useSegmentResource(props.segment.type)
  const segments = useContext(SegmentContext)
  const { courseConfiguration } = useCourseEditorReadContext(true)
  const days = useSegmentDaysContext(true)
  let onWeek: number | undefined = undefined
  let onDay: number | undefined = undefined
  const parents = getSegmentParents(courseConfiguration.segments, props.segment.id)
  const term = parents[0] ? segments[parents[0]] : undefined
  if (term) {
    const day = days.termDays[term.id]?.findIndex(day => {
      return day.find(segment => segment.id === props.segment.id)
    })
    if (day !== undefined && day !== -1) {
      onWeek = Math.floor(day / WEEK_SIZE_DAYS)
      onDay = day - onWeek * WEEK_SIZE_DAYS + 1
    }
  }
  const flatSegments = flattenSegments([props.segment])
  const segmentDays = flatSegments.reduce((totalDays, segment) => {
    if (!segment || !segment.config || !segment.config.days) return totalDays
    return totalDays + segment.config.days
  }, 0)
  return (
    <>
      <SegmentName
        segment={props.segment}
        onClick={
          !isOverrideMode
            ? () => {
                setEditingKey(uuid())
              }
            : undefined
        }
        disabled={disabled}
        days={segmentDays}
        onWeek={onWeek !== undefined ? onWeek + 1 : undefined}
        onDay={onDay}
        isTeacherInfo={isTeacherInfoSegment(props.segment)}
        {...nameProps}
      />
      {resource.hasChildren ? <SegmentChildren {...props} /> : null}
      {!isOverrideMode ? (
        <EditableSegmentDrawer
          {...props}
          drawerProps={{
            ...props.drawerProps,
            title: (
              <span>
                Edit <strong>{props.segment.name}</strong> {props.segment.type}
              </span>
            ),
            width: '90vw',
          }}
          onClose={() => {
            if (acknowledgeCreatedSegmentId) {
              acknowledgeCreatedSegmentId()
            } else {
              warn(
                'acknowledgeCreatedSegmentId() is not found, are you missing SegmentListContext?',
              )
            }
            setEditingKey(undefined)
          }}
          key={editingKey}
          visible={!!editingKey || createdSegmentId === props.segment.id}
        />
      ) : null}
    </>
  )
}
