import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import { Modal } from '@thesisedu/react'
import { styled } from '@thesisedu/web'
import { Tooltip } from 'antd'
import React from 'react'

import { useOptions } from '../options'
import { useRemoveStudentFromClassMutation } from '../queries/useRemoveStudentFromClassMutation'

const DeleteContainer = styled.div`
  padding: 2px;
  cursor: pointer;
  color: ${props => props.theme['@red']};
  transition: opacity 0.1s linear;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`

export function useRemoveStudent() {
  const [removeStudent] = useRemoveStudentFromClassMutation()
  const options = useOptions()
  return async (studentId: string, classId: string, noConfirm?: boolean) => {
    const remove = () =>
      removeStudent({
        variables: {
          input: {
            id: classId,
            studentId,
          },
        },
      })
    if (noConfirm) {
      return remove()
    } else {
      Modal.confirm({
        title: 'Are you sure you want to remove this student?',
        okText: 'Yes, remove',
        okButtonProps: { danger: true },
        cancelText: `No, keep in ${options.className}`,
        width: 500,
        onOk: remove,
      })
    }
  }
}

export interface RemoveStudentButtonProps {
  classId: string
  studentId: string
}
export function RemoveStudentButton({ classId, studentId }: RemoveStudentButtonProps) {
  const removeStudent = useRemoveStudent()
  return (
    <Tooltip title={'Remove Student'} mouseEnterDelay={1} placement={'topRight'}>
      <DeleteContainer onClick={() => removeStudent(studentId, classId)}>
        <DeleteOutlined />
      </DeleteContainer>
    </Tooltip>
  )
}
