import { LoadingIndicator, s } from '@thesisedu/ui'
import React from 'react'

function _getIdFromUrl(url: string): string {
  const urlParts = new URL(url).pathname.split('/')
  return urlParts[urlParts.length - 1]
}

interface LoomEmbedProps {
  url: string
}

export function LoomEmbed({ url }: LoomEmbedProps) {
  const [loomAspectRatio, setLoomAspectRatio] = React.useState<string | undefined>()

  React.useEffect(() => {
    async function getLoomEmbedMetaData() {
      const baseUrl = 'https://www.loom.com/v1/oembed?url='
      const loomMetaData = await fetch(`${baseUrl}${url}`).then(response => response.json())
      if (loomMetaData?.width && loomMetaData?.height) {
        setLoomAspectRatio(`${loomMetaData.width} / ${loomMetaData.height}`)
      }
    }
    getLoomEmbedMetaData()
  }, [])

  const loomUrl = `https://www.loom.com/embed/${_getIdFromUrl(url)}`

  if (loomAspectRatio === undefined) {
    return <LoadingIndicator />
  }
  return (
    <iframe
      allowFullScreen
      style={{
        aspectRatio: loomAspectRatio,
        border: 'none',
        width: '100%',
        borderRadius: s.var('radii.2'),
        display: 'block',
      }}
      src={loomUrl}
      allow={'autoplay'}
    />
  )
}
