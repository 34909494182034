import { Form } from '@thesisedu/react'
import { TextAreaField } from '@thesisedu/ui'
import React from 'react'

import { useSubmissionData, useSubmissionProps } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'

export function OpenEndedContent(props: QuestionProps) {
  return (
    <Form.Item
      name={props.question.id}
      required={{ value: true, message: 'This question is required.' }}
      initialValue={useSubmissionData(props.question.id)}
    >
      <QuestionInstructions instructions={'Type your answer in the box below.'} padded>
        <TextAreaField {...useSubmissionProps(props)} />
      </QuestionInstructions>
    </Form.Item>
  )
}
