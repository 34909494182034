import React from 'react'
import { Route } from 'react-router-dom'

import { CourseRoute } from './CourseRoute'
import { SplitViewRoute } from './SplitViewRoute'
import { CoursesReactFeature } from '../../../CoursesReactFeature'
import { StudentOutline } from '../../../outline/StudentOutline'
import { CourseClassRoutesHook } from '../../../types'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<CourseClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls, group }) => {
      if (group === 'TEACHER') {
        return [
          <Route key={'course-split'} path={'course/split'} element={<SplitViewRoute />} />,
          <Route key={'course'} path={'course/*'} element={<CourseRoute />} />,
          ...routes,
        ]
      } else if (group === 'STUDENT') {
        return [<Route key={'course'} path={'course'} element={<StudentOutline />} />, ...routes]
      } else return routes
    },
  )
}
