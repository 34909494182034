import { DynamicStudentName } from '@thesisedu/feature-classes-react'
import { CommaDelimitedList, Body, Space, HSpaced, styled } from '@thesisedu/web'
import React from 'react'

import { useSubmissionContext } from '../contexts/SubmissionContext'

export interface QuestionAssignmentSubmissionGradeHeaderProps {
  gradedCount: number
  questionCount: number
}
export function QuestionAssignmentSubmissionGradeHeader({
  gradedCount,
  questionCount,
}: QuestionAssignmentSubmissionGradeHeaderProps) {
  const { groups } = useSubmissionContext(true)
  return (
    <div>
      <HSpaced>
        <Body>
          Graded {gradedCount} / {questionCount} questions
        </Body>
        <Space />
        {groups.groupMemberIds.length ? (
          <Body style={{ textAlign: 'right' }}>
            <strong>Group members:</strong>{' '}
            <CommaDelimitedList>
              {groups.groupMemberIds.map(member => {
                if (groups.groupLeader === member) {
                  return (
                    <span key={member}>
                      <DynamicStudentName studentId={member} /> (Leader)
                    </span>
                  )
                } else {
                  return <DynamicStudentName key={member} studentId={member} />
                }
              })}
            </CommaDelimitedList>
          </Body>
        ) : null}
      </HSpaced>
      <Progress percentage={questionCount > 0 ? gradedCount / questionCount : 0} />
    </div>
  )
}

const Progress = styled.div<{ percentage: number }>`
  margin: ${props => props.theme['@size-xs']} -${props => props.theme['@size-l']} 0 -${props =>
      props.theme['@size-l']};
  background: ${props => props.theme['@background-color-base']};
  &::before {
    content: ' ';
    display: block;
    transition:
      width 0.25s ease-in-out,
      background 0.25s linear;
    height: ${props => props.theme['@size-xxs']};
    width: ${props => Math.min(100, props.percentage * 100)}%;
    background: ${props => props.theme[props.percentage >= 1 ? '@green' : '@primary-color']};
  }
`
