import { IssuesCloseOutlined } from '@ant-design/icons'
import { UserTask, UserTaskProps } from '@thesisedu/feature-user-tasks-react'
import { DATE_FORMATS, styled } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { CompleteAssignmentUserTaskFragment } from '../../schema'

export function CompleteAssignmentUserTask(
  props: UserTaskProps<CompleteAssignmentUserTaskFragment>,
) {
  const payload = props.task.payload
  let dueAt
  const momentDueAt = moment(payload.dueAt)
  if (payload.dueAt && momentDueAt.isValid()) {
    if (momentDueAt.isSame(moment(), 'day')) {
      dueAt = (
        <span className={'due due-today'}>
          Due {momentDueAt.fromNow()}! ({momentDueAt.format(DATE_FORMATS.FULL)})
        </span>
      )
    } else {
      dueAt = (
        <span className={'due'}>
          Due {momentDueAt.fromNow()} ({momentDueAt.format(DATE_FORMATS.FULL)})
        </span>
      )
    }
  }

  return (
    <Link to={`/assignment/${payload.assignmentId}`}>
      <TaskContainer>
        <UserTask {...props} icon={<IssuesCloseOutlined />}>
          Complete <strong>{payload.assignmentName}</strong> {dueAt}
        </UserTask>
      </TaskContainer>
    </Link>
  )
}

const TaskContainer = styled.div`
  .due {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    color: ${props => props.theme['@gray-5']};
    &.due-today {
      font-weight: bold;
      color: ${props => props.theme['@red']};
    }
  }
`
