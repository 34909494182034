import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { AssignmentEditor, AssignmentEditorProps } from './AssignmentEditor'
import { AssignmentTemplateFragment } from '../schema'

export interface AssignmentEditorFieldProps
  extends Omit<Partial<AssignmentEditorProps>, 'onChange'> {
  onChange?: (configuration: AssignmentConfiguration) => void
  value?: AssignmentConfiguration
  assignment?: AssignmentTemplateFragment
}
export function AssignmentEditorField({
  onChange,
  value,
  assignment,
  ...props
}: AssignmentEditorFieldProps) {
  return (
    <AssignmentEditor
      assignment={{
        ...assignment,
        configuration: {
          ...assignment?.configuration,
          ...value,
        },
      }}
      onChange={newAssignment => {
        if (onChange) {
          onChange(newAssignment.configuration)
        }
      }}
      {...props}
    />
  )
}
