import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { ViewingAssignment } from '../grading/types'

export interface AssignmentViewerContextValue {
  assignment?: ViewingAssignment
  /** The ID of the template currently being used. */
  templateId: string
  /** Are we in teacher view mode? */
  teacherView?: boolean
  /**
   * If this is true, we won't handle any special cases with the assignment (like timed assignments,
   * calculated assignments, etc), and we just show the assignment. This typically applies only to
   * a teacher view.
   */
  forceShowAssignment?: boolean
  /** The assignment configuration to use. This can be from the submission, the assignment, or the template. */
  configuration: AssignmentConfiguration
  /** What class are we currently viewing the assignment in? */
  classId: string
}

export const AssignmentViewerContext = React.createContext<
  AssignmentViewerContextValue | undefined
>(undefined)
export interface AssignmentViewerContextProviderProps {
  value: AssignmentViewerContextValue
  children: React.ReactElement
}
export function AssignmentViewerContextProvider({
  value,
  children,
}: AssignmentViewerContextProviderProps) {
  const existing = useAssignmentViewerContext(false)
  return <AssignmentViewerContext.Provider value={{ ...existing, ...value }} children={children} />
}
export interface AssignmentViewerContextPartialProviderProps {
  value: Partial<AssignmentViewerContextValue>
  children: React.ReactElement
}
export function AssignmentViewerContextPartialProvider({
  value,
  children,
}: AssignmentViewerContextPartialProviderProps) {
  const existing = useAssignmentViewerContext(true)
  return <AssignmentViewerContext.Provider value={{ ...existing, ...value }} children={children} />
}

export function useAssignmentViewerContext(): AssignmentViewerContextValue | undefined
export function useAssignmentViewerContext(require: false): AssignmentViewerContextValue | undefined
export function useAssignmentViewerContext(require: true): AssignmentViewerContextValue
export function useAssignmentViewerContext(
  require?: boolean,
): AssignmentViewerContextValue | undefined {
  const context = React.useContext(AssignmentViewerContext)
  if (!context && require) {
    throw new Error('AssignmentViewerContext is required, yet not provided.')
  }
  return context
}
