import { SegmentActionResource } from '@thesisedu/feature-courses-react'

import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'
import { SegmentType } from '../../schema'
import { AssignmentSegmentAction } from '../../segments/AssignmentSegmentActions'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.resources.addResource<SegmentActionResource>({
    type: 'SegmentAction',
    identifier: 'assignment-settings',
    component: AssignmentSegmentAction,
    group: '',
    weight: 0,
    handler: () => {},
    filter: segment => segment.type === SegmentType.Assignment,
  })
}
