import { AttachmentFormField } from '@thesisedu/feature-attachments-react'
import { StateSelect } from '@thesisedu/feature-districts-web'
import { EditorValue } from '@thesisedu/feature-widgets-react'
import { Form, Input, VSpaced } from '@thesisedu/react'
import { Color, Row } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'

import { TagsField } from './TagsField'
import { CreateStandardSetInput, UpdateStandardSetInput } from '../schema'

export type FormValue = (
  | Omit<CreateStandardSetInput, 'tags'>
  | Omit<UpdateStandardSetInput, 'id' | 'tags'>
) & { tags: EditorValue }
export function StandardSetForm() {
  const { form } = Form.useReactFormContext<FormValue>()
  const name = form.watch('name')
  const endsWithStandards = name?.toLowerCase().includes('standard')
  return (
    <VSpaced align={'stretch'} space={'@size-m'}>
      <Form.Item
        name={'name'}
        label={'Name'}
        required={{ value: true, message: 'A name is required.' }}
        extra={
          endsWithStandards ? (
            <Color color={'@orange'}>
              You'll likely not want this to include the word "Standard." If the name of this is
              "KY" it will be displayed in the platform as "KY Standards," so if the name is "KY
              Standards" it will be displayed in the platform as "KY Standards Standards."
            </Color>
          ) : undefined
        }
      >
        <Input size={'large'} placeholder={'Kentucky State Standards'} style={{ width: '100%' }} />
      </Form.Item>
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            name={'state'}
            label={'State'}
            extra={
              <span>
                If you would like this standard set to automatically be associated with certain
                sites, select the state here. If a state is selected, sites matching this state will
                automatically be associated with this standard set.
              </span>
            }
          >
            <StateSelect allOption />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name={'attachment'}
            label={'Attachment'}
            extra={
              'This is displayed as a link to teachers when viewing standards inside the Explore tab.'
            }
          >
            <AttachmentFormField
              type={'StandardSet'}
              camelField={'attachment'}
              uploadMessage={'Click or drag file here to upload an attachment'}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name={'tags'}>
        <TagsField />
      </Form.Item>
    </VSpaced>
  )
}
