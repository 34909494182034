import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react'
import React from 'react'

import {
  TagDocument,
  TagFragment,
  UserTagsDocument,
  UserTagsQuery,
  UserTagsQueryVariables,
} from './schema'

export type TagSelectProps = Partial<
  SingleSelectButtonProps<TagFragment, UserTagsQuery, UserTagsQueryVariables>
>
export const TagSelect: React.FC<React.PropsWithChildren<TagSelectProps>> = props => (
  <SingleSelectButton<TagFragment, UserTagsQuery, UserTagsQueryVariables>
    filterVariableName={'name'}
    maxSelection={1}
    getItemName={item => item.name}
    getResult={data => data?.viewer?.tags}
    setResult={(previous, result) => ({
      ...previous,
      viewer: {
        ...previous.viewer!,
        tags: {
          ...previous.viewer!.tags,
          ...result,
        },
      },
    })}
    document={UserTagsDocument}
    nodeDocument={TagDocument}
    selectLabel={'Select a Tag'}
    {...props}
  />
)
