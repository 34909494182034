import { Dropdown } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { EditStudentModal } from '../../teacher/EditStudentModal'
import {
  StudentActionConfigureProps,
  StudentActionProps,
  StudentActionResource,
} from '../../teacher/students/StudentActionsDropdown'
import { useTeacherClass } from '../../useClass'

function Action({ onConfigure, students }: StudentActionProps) {
  const student = students.length === 1 ? students[0] : undefined
  const { cls } = useTeacherClass(student?.classId ?? '', false)
  const fullStudent = cls?.students.edges.find(edge => edge.node.id === student?.studentId)
  if (fullStudent?.node.user.canUpdate) {
    return (
      <Dropdown.Item icon={<Edit />} onClick={onConfigure}>
        Rename
      </Dropdown.Item>
    )
  } else return null
}

function Configure({ onClose, students }: StudentActionConfigureProps) {
  const student = students.length === 1 ? students[0] : undefined
  const { cls } = useTeacherClass(student?.classId ?? '', false)
  const fullStudent = cls?.students.edges.find(edge => edge.node.id === student?.studentId)
  if (fullStudent && cls) {
    return (
      <EditStudentModal
        context={{
          class: cls,
          edge: fullStudent,
          refetchQueries: ['teacherClass'],
        }}
        visible
        onVisibleChange={visible => {
          if (!visible) onClose()
        }}
      />
    )
  } else return null
}

export default {
  type: 'StudentAction',
  identifier: 'Rename',
  render: Action,
  renderConfigure: Configure,
} satisfies StudentActionResource
