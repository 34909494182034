import React from 'react'

import { INITIAL_STATE, UploadManager } from './manager'
import { UploadManagerListener, UploadManagerState } from './types'

export const UploadManagerContext = React.createContext<UploadManager | null>(null)
export const UploadManagerStateContext = React.createContext<UploadManagerState>(INITIAL_STATE)

export interface UploadManagerProviderProps {
  uploadManager: UploadManager
}
export const UploadManagerProvider: React.FC<
  React.PropsWithChildren<UploadManagerProviderProps>
> = ({ uploadManager, children }) => {
  const [managerState, setManagerState] = React.useState(INITIAL_STATE)
  const listener = React.useCallback<UploadManagerListener>(
    state => setManagerState({ ...state }),
    [setManagerState],
  )
  React.useEffect(() => {
    uploadManager.addListener(listener)
    return () => {
      uploadManager.removeListener(listener)
    }
  }, [uploadManager])

  return (
    <UploadManagerContext.Provider value={uploadManager}>
      <UploadManagerStateContext.Provider value={managerState} children={children} />
    </UploadManagerContext.Provider>
  )
}
