import { Form, VSpaced } from '@thesisedu/react'
import React from 'react'

import { DimensionTypeSelect } from './DimensionTypeSelect'
import { DimensionValueSelect } from './DimensionValueSelect'
import { MetricTypeSelect } from '../../config/MetricDropdownButton'
import { getInputIdentifier, useReportResources } from '../../execute/input'
import { ReportMetricResource } from '../../types'
import { ReportResultsProps } from '../ReportResults'
import { getAxesResponsibility, isMetrics } from '../grid/axes'

export interface GraphConfiguration {
  selectedMetricKey?: string
  selectedDimensionIdentifier?: string
  selectedDimensionValue?: any
}
export function getDefaultGraphConfiguration({
  report,
  metrics,
}: Pick<ReportResultsProps, 'report'> & { metrics: ReportMetricResource[] }): GraphConfiguration {
  return {
    selectedMetricKey: report.metrics.length
      ? getInputIdentifier(report.metrics[0])
      : metrics[0].identifier,
    selectedDimensionIdentifier: report.dimensions[0]?.identifier,
  }
}

export interface GraphConfigProps extends ReportResultsProps {
  opts: GraphConfiguration
  onChange: (opts: GraphConfiguration) => void
}
export function GraphConfig({ opts, onChange, report, data }: GraphConfigProps) {
  const resources = useReportResources()
  const { row, col } = getAxesResponsibility(resources, report)
  const form = Form.useControlledForm<GraphConfiguration>({
    value: opts,
    onChange,
  })
  const selectedDimensionIdentifier = form.watch('selectedDimensionIdentifier')

  let child: React.ReactElement
  if (isMetrics(row) || isMetrics(col)) {
    child = (
      <>
        <Form.Item
          name={'selectedMetricKey'}
          label={'Metric'}
          required={{ value: true, message: 'Select a metric to display.' }}
        >
          <MetricTypeSelect />
        </Form.Item>
      </>
    )
  } else if (!isMetrics(row) && !isMetrics(col)) {
    const selectedDimension = resources.dimensions.find(
      d => d.identifier === selectedDimensionIdentifier,
    )
    child = (
      <>
        <Form.Item
          name={'selectedDimensionIdentifier'}
          required={{ value: true, message: 'Select a dimension to display.' }}
          label={'Showing'}
        >
          <DimensionTypeSelect dimensions={[row, col]} />
        </Form.Item>
        {selectedDimension ? (
          <Form.Item
            name={'selectedDimensionValue'}
            required={{
              value: true,
              message: `Select a ${selectedDimension?.singular.toLowerCase() || 'value'} to view.`,
            }}
          >
            <DimensionValueSelect dimension={selectedDimension} report={report} data={data} />
          </Form.Item>
        ) : null}
      </>
    )
  } else {
    return null
  }

  return (
    <Form form={form}>
      <VSpaced space={'@size-xs'} align={'stretch'}>
        {child}
      </VSpaced>
    </Form>
  )
}
