import { useFreshRef } from '@thesisedu/feature-react'
import { styled, LightOverlay, LargeBlock, Easings } from '@thesisedu/web'
import React from 'react'
import { createPortal } from 'react-dom'

import { StudentSelector, StudentSelectorProps } from './StudentSelector'
import {
  StudentSelectorOverlayHeader,
  useStudentSelectorOverlayHeader,
} from './StudentSelectorOverlayHeader'

export interface StudentSelectorOverlayProps extends StudentSelectorProps {
  visible?: boolean
  onSave: () => void | Promise<void>
  onCancel: () => void
  saveText?: string
  title?: string
}
export function StudentSelectorOverlay({
  visible,
  onSave,
  onCancel,
  saveText = 'Save',
  title,
  single,
  ...rest
}: StudentSelectorOverlayProps) {
  const header = useStudentSelectorOverlayHeader()
  const portalElementRef = React.useRef(document.createElement('div'))
  const onSaveRef = useFreshRef(onSave)
  React.useEffect(() => {
    document.body.appendChild(portalElementRef.current)
    return () => {
      document.body.removeChild(portalElementRef.current)
    }
  }, [])

  const content = (
    <Container className={visible ? 'visible' : ''}>
      <Overlay
        onClick={() => {
          if (!header.saveLoading) {
            onCancel()
          }
        }}
      />
      <PopupContainer>
        <StudentSelectorOverlayHeader
          {...header}
          title={title}
          onSave={onSave}
          onCancel={onCancel}
          saveText={saveText}
          single={single}
        />
        <PopupContent>
          <StudentSelector
            visible={visible}
            single={single}
            {...rest}
            onChange={pairs => {
              if (single && pairs.length) {
                rest.onChange([pairs[pairs.length - 1]])
                setTimeout(() => {
                  // Wait for a re-render to get the state to update.
                  onSaveRef.current()
                }, 1)
              } else {
                rest.onChange(pairs)
              }
            }}
          />
        </PopupContent>
      </PopupContainer>
    </Container>
  )
  return createPortal(content, portalElementRef.current)
}

const PopupContent = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 0 -${props => props.theme['@size-l']};
  padding: 0 ${props => props.theme['@size-l']};
`
const PopupContainer = styled(LargeBlock)`
  position: absolute;
  top: ${props => props.theme['@size-l']};
  left: ${props => props.theme['@size-l']};
  bottom: ${props => props.theme['@size-l']};
  padding: ${props => props.theme['@size-l']};
  width: 500px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: transform 0.5s ${Easings.Exponential};
  transform: translateX(calc(-100% - ${props => props.theme['@size-l']} - 100px));
`
const Overlay = styled(LightOverlay)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.25s linear;
`
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
  &.visible {
    pointer-events: all;
    ${PopupContainer} {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 1;
    }
  }
`
