import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, Button$, Modal, useToast } from '@thesisedu/ui'
import React from 'react'

import { UPDATE_ASSIGNMENT_QUERIES, UPDATE_GRADE_QUERIES } from '../queries/constants'

export interface DeleteAssignmentButtonProps extends Partial<Button$.ButtonProps> {
  assignmentId: string
  onDelete?: () => void
}
export function DeleteAssignmentButton({
  assignmentId,
  onDelete,
  ...rest
}: DeleteAssignmentButtonProps) {
  const toast = useToast()
  const [del, { loading }] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that assignment.',
    onCompleted: () => {
      toast({ title: 'Assignment deleted successfully!', status: 'success' })
      if (onDelete) onDelete()
    },
    refetchQueries: [...UPDATE_ASSIGNMENT_QUERIES, ...UPDATE_GRADE_QUERIES],
    awaitRefetchQueries: true,
  })
  return (
    <Modal.Confirm
      title={'Are you sure?'}
      confirm={{ status: 'danger', children: 'Yes, delete' }}
      cancel={{ children: 'No, keep' }}
      onConfirm={() => {
        return del({
          variables: {
            id: assignmentId,
          },
        })
      }}
      trigger={
        <Button loading={loading} children={'Delete Assignment'} status={'danger'} {...rest} />
      }
    />
  )
}
