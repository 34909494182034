import {
  AssignmentAttemptsField,
  AssignmentFlags,
  AssignmentTimeLimitField,
  AssignmentGradingModeField,
  AssignmentSettingsRubricField,
} from '@thesisedu/feature-assignments-react'
import { Block, Form, VSpaced } from '@thesisedu/ui'
import { Row } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'

import { AssignmentGradingMode, AssignmentInput } from '../../schema'

export interface AssignmentDefaultsProps {
  value?: Partial<AssignmentInput>
  onChange?: (value: Partial<AssignmentInput>) => void
}
export function AssignmentDefaults({ value, onChange }: AssignmentDefaultsProps) {
  const form = Form.useControlledForm<Partial<AssignmentInput>>({
    value: value ?? {},
    onChange: onChange ?? (() => {}),
  })
  return (
    <Form form={form}>
      <Row>
        <Col xs={24} md={12}>
          <VSpaced space={'m'}>
            <AssignmentAttemptsField />
            <AssignmentTimeLimitField />
          </VSpaced>
        </Col>
        <Col xs={24} md={12}>
          <VSpaced space={'m'}>
            <AssignmentFlags />
            <AssignmentGradingModeField hasQuestions />
          </VSpaced>
        </Col>
      </Row>
      <Block top={'l'}>
        {value?.gradingMode === AssignmentGradingMode.Rubric ? (
          <Form.Field name={'rubric'} aria-label={'Custom Rubric'}>
            <AssignmentSettingsRubricField />
          </Form.Field>
        ) : null}
      </Block>
    </Form>
  )
}
