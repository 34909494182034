import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  DirectMessageThreadFragmentDoc,
  useRemoveDirectMessageThreadRecipientsMutation as useMutation,
} from '../schema'

export const useRemoveDirectMessageThreadRecipientsMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error removing that person from the thread.'),
    ...opts,
    update: updateMutation({
      fragment: DirectMessageThreadFragmentDoc,
      fragmentName: 'DirectMessageThread',
      path: 'removeDirectMessageThreadRecipients.directMessageThread',
    }),
  })
