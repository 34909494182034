import { Tooltip, Button, Modal, Text } from '@thesisedu/ui'
import { NavArrowLeft } from '@thesisedu/ui/icons'
import React from 'react'

export interface DiscardFooterButtonProps {
  onDiscard: () => void
}
export function DiscardFooterButton({ onDiscard }: DiscardFooterButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Modal.Confirm
        visible={visible}
        onVisibleChange={setVisible}
        title={'Are you sure?'}
        confirm={{ children: 'Yes, discard.', status: 'danger' }}
        cancel={{ children: 'No, keep.' }}
        onConfirm={onDiscard}
      >
        <Text>
          Are you sure you want to discard your recording?{' '}
          <Text level={null} color={'red'} weight={'bold'} inline>
            If you discard your recording, it will be gone forever.
          </Text>
        </Text>
      </Modal.Confirm>
      <Tooltip title={'Discard recording and go back'}>
        <Button
          size={'small'}
          aria-label={'Discard recording and go back'}
          icon={<NavArrowLeft />}
          onPress={() => {
            setVisible(true)
          }}
        />
      </Tooltip>
    </>
  )
}
