import { useFeature } from '@thesisedu/feature-react'

import { useSegmentCompletionContext } from './SegmentCompletionContext'
import { CoursesReactFeature } from '../../CoursesReactFeature'

export function useSegmentCompletionHost() {
  const context = useSegmentCompletionContext(false)
  const feature = useFeature(CoursesReactFeature)

  return context?.host ? context.host : feature.completionHost
}
