import { useFeatureRoot } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Format } from '@thesisedu/feature-utils'
import { Text, Tooltip } from '@thesisedu/ui'
import moment from 'moment'
import React from 'react'

import { CommentProps } from './types'
import { DeleteCommentLink } from '../DeleteCommentLink'
import { UpdateCommentLink } from '../UpdateCommentLink'
import { ApproveCommentButton } from '../moderation/ApproveCommentButton'
import { RejectCommentButton } from '../moderation/RejectCommentButton'
import { UserFragment } from '../schema'
import { CommentComposerResource, CommentWithMetadata } from '../types'

export interface UseComment {
  metadataSegments: (React.ReactElement | string)[]
  actionLinks?: (React.ReactElement | string)[]
  sideContent?: React.ReactElement
  customContent?: React.ReactElement
  isOwn: boolean
  needsApproval: boolean
  user?: Pick<UserFragment, 'id' | 'name' | 'username'>
}
export interface UseCommentOptions extends CommentProps {
  shorthandPosted?: boolean
}
export function useComment({
  comment,
  renderMetadata,
  renderSideContent,
  hideNames,
  isSelected,
  shorthandPosted,
  onCommentDeleted,
  useRichText,
  allowDeletingAll,
  additionalActions,
}: UseCommentOptions): UseComment {
  const viewer = useViewerContext(false)
  const resources =
    useFeatureRoot().deps.resources.getResourcesForType<CommentComposerResource>('CommentComposer')
  const isAdmin = viewer?.group === 'ADMIN'
  const isOwn = comment.user.id === viewer?.id
  const metadata = renderMetadata ? renderMetadata(comment) : undefined
  const user = !hideNames || isOwn ? comment.user : undefined
  const requiresApproval = (isOwn || isAdmin) && comment.approvedAt !== comment.createdAt
  const isApproved = !!comment.approvedAt
  const isRejected = !!comment.rejectedAt
  const posted = shorthandPosted
    ? moment(comment.createdAt)
        .fromNow(false)
        .replace(/a few seconds ago/i, 'now')
        .replace(/^a/, '1')
        .replace(/ year(s)?/, 'y')
        .replace(/ month(s)?/, 'mo')
        .replace(/ day(s)?/, 'd')
        .replace(/ hour(s)?/, 'h')
        .replace(/ minute(s)?/, 'm')
        .replace(/ second(s)?/, 's')
        .replace(' ago', '')
    : moment(comment.createdAt).fromNow()
  const metadataSegments = [
    metadata,
    <Tooltip title={Format.date(comment.createdAt, 'dateTime')} key={'posted'}>
      <Text level={'xs'} color={'secondary'}>
        {posted}
      </Text>
    </Tooltip>,
  ].filter(Boolean)
  if (isOwn || isAdmin) {
    if (requiresApproval && isRejected) {
      metadataSegments.push(
        <Tooltip title={comment.rejectedReason || ''} key={'rejected'}>
          <Text level={'xs'} color={'red'} inline>
            Rejected
          </Text>
        </Tooltip>,
      )
    } else if (requiresApproval && !isApproved) {
      metadataSegments.push(
        <Text level={'xs'} color={'orange'} inline key={'waiting-approval'}>
          Waiting for Approval
        </Text>,
      )
    }
  }

  const customComposer =
    comment.metadata?.customType &&
    resources.find(resource => resource.identifier === comment.metadata.customType)
  const approvalEditable = !comment.approvedAt || !requiresApproval
  const allowEdit = !customComposer || customComposer.allowEdit
  const sideContent = renderSideContent ? renderSideContent(comment) : undefined
  const canDelete = (isOwn && approvalEditable) || isAdmin || allowDeletingAll

  const actionLinks = []
  if (((isOwn && approvalEditable) || isAdmin) && allowEdit) {
    actionLinks.push(
      <UpdateCommentLink
        key={'update-comment'}
        commentId={comment.id}
        originalMessage={comment.comment}
        originalBlockMessage={comment.blockComment}
        useRichText={useRichText}
      />,
    )
  }
  if (canDelete) {
    actionLinks.push(
      <DeleteCommentLink commentId={comment.id} onDeleted={onCommentDeleted} key={'delete'} />,
    )
  }
  if (isAdmin) {
    actionLinks.push(<ApproveCommentButton commentId={comment.id} size={'small'} key={'approve'} />)
    actionLinks.push(<RejectCommentButton comment={comment} size={'small'} key={'reject'} />)
  }

  if (additionalActions) {
    for (const action of additionalActions(comment) || []) {
      actionLinks.push(action)
    }
  }

  let customContent: React.ReactElement | undefined = undefined
  if (customComposer) {
    customContent = (
      <customComposer.DisplayComponent
        comment={comment as CommentWithMetadata}
        isOwn={isOwn}
        isSelected={isSelected}
      />
    )
  }

  return {
    customContent,
    metadataSegments: metadataSegments.filter(Boolean) as (React.ReactElement | string)[],
    actionLinks,
    sideContent,
    isOwn,
    needsApproval: !isApproved && requiresApproval,
    user,
  }
}
