import { StudentTableStudent, StudentsTableColumnsHook } from '@thesisedu/feature-classes-react'
import { Table$ } from '@thesisedu/ui'
import { CloudSync } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'
import { StudentAccountSummary } from '../../web/StudentAccountSummary'

function ConnectedAccountsCell({
  item,
}: Table$.ImperativeTableRenderCellProps<StudentTableStudent>) {
  return (
    <StudentAccountSummary
      classId={item.classId}
      linkedAccounts={item.node.user.linkedAccounts}
      user={item.node.user}
    />
  )
}

export default function (feature: ClassSyncReactFeature) {
  feature.hookManager.registerMutateHook<StudentsTableColumnsHook>(
    'classes-react:students-table-columns',
    (columns, { cls }) => {
      return [
        ...columns.slice(0, -1),
        {
          id: 'connected-accounts',
          allowsSorting: false,
          title: 'Connected Accounts',
          icon: <CloudSync />,
          renderCell: ConnectedAccountsCell,
        },
        columns[columns.length - 1],
      ]
    },
  )
}
