import { useMediaPlaybackContext } from '@thesisedu/react'
import { PlayerCaption } from '@thesisedu/react/dist/player/PlayerCaption'
import React from 'react'

import { useCurrentBookmark } from './useBookmarks'

export function PlayerBookmarkBeforeFooter() {
  const currentBookmark = useCurrentBookmark(useMediaPlaybackContext().playedSeconds)
  return <PlayerCaption captions={currentBookmark?.name ? [currentBookmark.name] : []} />
}
