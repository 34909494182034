import { ReferenceSegmentConfig } from '@thesisedu/feature-course-types'
import { toGlobalId } from '@thesisedu/feature-utils'
import { HSpaced, useTheme, VSpaced, Button } from '@thesisedu/react'
import { Check } from '@thesisedu/react/icons'
import { Row } from '@thesisedu/web'
import { Col, Divider, Form, Input } from 'antd'
import React from 'react'

import { EditableSegment } from '../../segment/EditableSegment'
import { SegmentSelectField } from '../../segment/SegmentSelectField'
import { SegmentProps } from '../../types'

export function ReferenceEdit(props: SegmentProps<ReferenceSegmentConfig>) {
  return (
    <EditableSegment {...props}>
      {({ submitFields, segment }) => (
        <Row>
          <Col xs={24} md={8} lg={6}>
            <Form.Item
              label={'Name'}
              name={'name'}
              initialValue={segment.name}
              rules={[{ required: true, message: 'A reference name is required.' }]}
            >
              <Input />
            </Form.Item>
            <Divider />
            <Form.Item
              label={'Name Override'}
              name={['config', 'referenceOverrides', 'name']}
              initialValue={segment.config.referenceOverrides?.name}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={'Index Override'}
              name={['config', 'referenceOverrides', 'index']}
              initialValue={segment.config.referenceOverrides?.index}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value && value.toString().includes(' ')) {
                      callback('Index cannot contain spaces.')
                    } else callback()
                  },
                },
              ]}
            >
              <Input style={{ width: 100 }} placeholder={'1-1'} />
            </Form.Item>
            <Divider />
            {submitFields}
          </Col>
          <Col xs={24} md={16} lg={18}>
            <Form.Item name={['config', 'referenceId']} initialValue={segment.config.referenceId}>
              <ReferenceSegmentSelectField blacklistSegmentId={segment.id} />
            </Form.Item>
          </Col>
        </Row>
      )}
    </EditableSegment>
  )
}

export interface ReferenceSegmentSelectFieldProps {
  value?: string
  onChange?: (value?: string | null | undefined) => void
  blacklistSegmentId: string
}
export function ReferenceSegmentSelectField({
  value,
  onChange,
  blacklistSegmentId,
}: ReferenceSegmentSelectFieldProps) {
  const theme = useTheme()
  const [segmentId, setSegmentId] = React.useState(value)
  React.useEffect(() => {
    setSegmentId(value)
  }, [value])
  return (
    <VSpaced align={'stretch'} space={'@size-m'}>
      <HSpaced>
        <Input
          size={'large'}
          style={{ fontFamily: theme['@code-family'] }}
          value={segmentId}
          onChange={e => {
            setSegmentId(e.target.value)
          }}
          placeholder={'Type Segment ID manually...'}
        />
        <Button
          size={'large'}
          icon={<Check />}
          type={'primary'}
          disabled={!segmentId || segmentId === value}
          onClick={e => {
            e.preventDefault()
            if (onChange && segmentId) {
              onChange(segmentId)
            }
          }}
        />
      </HSpaced>
      <SegmentSelectField
        value={value}
        onChange={onChange}
        segmentListProps={{ blacklistIds: [toGlobalId('Segment', blacklistSegmentId)] }}
      />
    </VSpaced>
  )
}
