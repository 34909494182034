import { ApolloReactFeature } from '../../ApolloReactFeature'
import { addExpectedErrorHandler } from '../../addExpectedErrorHandler'
import { debug } from '../../log'

export default function (feature: ApolloReactFeature) {
  addExpectedErrorHandler(feature, (payload, context) => {
    if (context.code === 'CLIENT_REQUIRES_UPDATE_ERROR') {
      debug('client requires update; refreshing')
      if (typeof window !== 'undefined') {
        window.location.reload()
      } else {
        debug('could not find window; not doing anything')
      }
    }
  })
}
