import React from 'react'

import Block from '../Block'
import Text from '../Text'

export interface FieldLabelRightProps {
  children: React.ReactNode
}
export function FieldLabelRight({ children }: FieldLabelRightProps) {
  return (
    <Block left>
      <Text level={'s'} as={'div'} color={'secondary'} children={children} />
    </Block>
  )
}
