import { LexicalEditor, LexicalNode } from 'lexical'

export function $getDOMNode(editor: LexicalEditor, key: LexicalNode): HTMLElement
export function $getDOMNode(editor: LexicalEditor, key: string): HTMLElement
export function $getDOMNode(editor: LexicalEditor, key: string | LexicalNode) {
  if (typeof key === 'string') {
    return editor.getElementByKey(key)
  } else {
    return editor.getElementByKey(key.getKey())
  }
}
