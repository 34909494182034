import { Input } from 'antd'
import React from 'react'

import { useExploreGridContext } from './ExploreGridContext'

export interface GridSearchProps {
  value: string
  onChange: (value: string) => void
}
export function GridSearch({ value, onChange }: GridSearchProps) {
  const {
    configuration: { searchPlaceholder },
  } = useExploreGridContext(true)
  return (
    <Input.Search
      placeholder={searchPlaceholder || 'Search Content'}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  )
}
