import { QuestionOverride } from '@thesisedu/feature-assignments-core'

import { QuestionSettingsValue } from './types'
import { FullAssignment } from '../../grading/types'

export function questionSettingsValueFromAssignment(
  assignment: FullAssignment,
): QuestionSettingsValue {
  const questions = assignment.configuration?.questions ?? []
  const overrides = assignment.rawConfiguration?.questions as QuestionOverride[] | undefined
  return questions.reduce<QuestionSettingsValue>((acc, question) => {
    const settings = overrides?.find(override => override.id === question.id)?.settings
    const rubricQuestion = assignment.rubric?.categories.find(cat => cat.categoryId === question.id)
    return {
      ...acc,
      [question.id]: {
        ...settings,
        totalPoints: rubricQuestion?.totalPoints ?? question.totalPoints ?? 0,
      },
    }
  }, {})
}
