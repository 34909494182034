import { Skeleton } from 'antd'
import React from 'react'

import { CourseVersionEditorProps } from './CourseVersionEditor'

const CourseVersionEditor = React.lazy(() => import('./CourseVersionEditor'))
export const CourseVersionEditorLoader: React.FC<
  React.PropsWithChildren<CourseVersionEditorProps>
> = props => (
  <React.Suspense fallback={<Skeleton active />}>
    <CourseVersionEditor {...props} />
  </React.Suspense>
)
