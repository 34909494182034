import {
  GradingModalSidebarAttempts,
  GradingModalSubmissions,
  getStudentClassFromTabName,
  useGradingModalContext,
} from '@thesisedu/feature-assignments-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { VSpaced } from '@thesisedu/ui'
import React from 'react'

export function CourseViewerNavigationFooter() {
  const gradingModalContext = useGradingModalContext(false)
  const viewer = useViewerContext(false)
  if (gradingModalContext && viewer?.group === 'TEACHER') {
    const studentTab = getStudentClassFromTabName(gradingModalContext.selectedTab || '')
    return (
      <VSpaced space={'m'}>
        <GradingModalSidebarAttempts
          assignment={gradingModalContext.assignment}
          studentTab={studentTab}
        />
        <div>
          <GradingModalSubmissions />
        </div>
      </VSpaced>
    )
  } else return null
}
