import { AriaNumberFieldProps, useNumberField } from '@react-aria/numberfield'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { NumberFieldStateOptions, useNumberFieldState } from 'react-stately'

import { styled, s } from '../../'
import { NavArrowDown, NavArrowUp } from '../../../icons'
import { Button } from '../Button/Button'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusRing } from '../FocusRing/FocusRing'
import { _Input, _variants, TextFieldVariant } from '../TextField/TextField'

export interface NumberFieldProps
  extends ExtendedBareFieldProps,
    Omit<AriaNumberFieldProps, 'isDisabled'>,
    Omit<NumberFieldStateOptions, 'locale' | 'isDisabled'> {
  className?: string
  style?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  prefix?: React.ReactElement
  suffix?: React.ReactElement
  disabled?: boolean
  variant?: TextFieldVariant
}
function _NumberField(
  {
    className,
    style,
    size = s.SharedVariants.Size.defaultValue,
    prefix,
    suffix,
    disabled,
    variant,
    ...props
  }: NumberFieldProps,
  _ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const ref = useObjectRef(_ref)
  const state = useNumberFieldState({ ...rest, isDisabled: disabled, locale: 'en-US' })
  const { inputProps, incrementButtonProps, decrementButtonProps, groupProps, ...aria } =
    useNumberField(props, state, ref)

  return (
    <BareField {...bareFieldProps} style={style} __aria={aria}>
      <FocusRing isTextInput>
        <Container {...groupProps}>
          <_NumberInput
            {...inputProps}
            prefix={prefix}
            suffix={suffix}
            value={inputProps.value || ''}
            disabled={disabled}
            ref={ref}
            className={s.variants<typeof _variants>(
              className,
              {
                disabled,
                error: !!props.error,
              },
              size,
              variant,
            )}
          />
          <StepperButton
            {...incrementButtonProps}
            disabled={incrementButtonProps.isDisabled}
            icon={<NavArrowUp />}
            size={'extraSmall'}
            variant={'ghost'}
            status={props.error ? 'danger' : undefined}
            style={{ top: 0 }}
          />
          <StepperButton
            {...decrementButtonProps}
            disabled={decrementButtonProps.isDisabled}
            icon={<NavArrowDown />}
            size={'extraSmall'}
            variant={'ghost'}
            status={props.error ? 'danger' : undefined}
            style={{ bottom: 0 }}
          />
        </Container>
      </FocusRing>
    </BareField>
  )
}

export const NumberField = React.forwardRef(_NumberField)

const { css } = s
const _NumberInput = s.styledWithVariants(
  _Input,
  'NumberInput',
  css`
    padding-right: calc(${s.size('2')} + ${s.size('xs')});
  `,
  {},
)
const Container = styled.div`
  position: relative;
  max-width: 100%;
  button {
    opacity: 0;
    transition: opacity 0.1s linear;
  }
  &:hover,
  &:focus,
  &:focus-within {
    button {
      opacity: 1;
    }
  }
`
const StepperButton = styled(Button)`
  position: absolute;
  right: 0;
  width: ${s.size('2')};
  height: 50%;
`
