import { useClasses, useSelectedClassId } from '@thesisedu/feature-classes-react'
import { TagTree } from '@thesisedu/feature-tags-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Block, H2 } from '@thesisedu/react'
import React from 'react'

import { useSegmentStandardsQuery } from '../schema'

export interface SegmentStandardsProps {
  segmentId: string
}
export function SegmentStandards({ segmentId }: SegmentStandardsProps) {
  // We only need the class for the site ID, so we can just use any class if we don't
  // have one already.
  const selectedClassId = useSelectedClassId()
  const { classes } = useClasses()
  let validClass = classes.find(cls => cls.id === selectedClassId)
  if (!validClass) validClass = classes[0]
  const { data } = useSegmentStandardsQuery({
    variables: {
      segmentId: toGlobalId('Segment', segmentId),
      classId: validClass?.id || '',
    },
    skip: !validClass,
  })
  const items = data?.node?.__typename === 'Segment' ? data.node.standards : undefined

  if (items && validClass) {
    return (
      <>
        {items
          .filter(item => item.tree.length)
          .map(item => (
            <Block marginBottom={'@size-m'} key={item.standardSet.id}>
              <H2>{item.standardSet.name} Standards</H2>
              <TagTree tree={item.tree} />
            </Block>
          ))}
      </>
    )
  } else {
    return null
  }
}
