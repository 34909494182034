import { Modal, Modal$ } from '@thesisedu/ui'
import React from 'react'

import { PresetSelect, PresetSelectProps } from './PresetSelect'

export interface PresetSelectModalProps
  extends PresetSelectProps,
    Pick<Modal$.ModalProps, 'trigger'> {}
export function PresetSelectModal({ trigger, onSelected, ...rest }: PresetSelectModalProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <Modal
      title={'Select a Groups Preset'}
      trigger={trigger}
      visible={visible}
      onVisibleChange={setVisible}
      style={{ height: 400 }}
    >
      <PresetSelect
        {...rest}
        onSelected={(...args) => {
          setVisible(false)
          onSelected(...args)
        }}
      />
    </Modal>
  )
}
