import { ExploreHomeContentHook } from '@thesisedu/feature-courses-react'
import React from 'react'

import { StandardSetsReactFeature } from '../StandardSetsReactFeature'
import { ExploreStandardSets } from '../explore/ExploreStandardSets'

export default function (feature: StandardSetsReactFeature) {
  feature.hookManager.registerMutateHook<ExploreHomeContentHook>(
    'courses-react:explore-home-content',
    items => {
      return [...items, <ExploreStandardSets getAllLink={set => `standards/${set.id}`} />]
    },
  )
}
