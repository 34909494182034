import { TagField } from '@thesisedu/feature-tags-react'
import { Form, Input, FormInstance, VSpaced } from '@thesisedu/react'
import { FormProps } from '@thesisedu/react/dist/form/Form'
import React from 'react'

import { LessonAttachmentFormField } from './LessonAttachmentField'
import { LessonFormValues } from './useCreateLesson'

export interface LessonFormFieldsProps {
  showAttachment?: boolean
}
export function LessonFormFields({ showAttachment }: LessonFormFieldsProps) {
  return (
    <VSpaced align={'stretch'} space={'@size-m'}>
      <Form.Item name={'name'} label={'Name'} required={'A lesson name is required.'}>
        <Input size={'large'} placeholder={'My First Lesson'} />
      </Form.Item>
      <Form.Item name={'tags'} label={'Tags'}>
        <TagField />
      </Form.Item>
      {showAttachment ? (
        <Form.Item name={'attachment'} label={'Attachment'}>
          <LessonAttachmentFormField />
        </Form.Item>
      ) : null}
    </VSpaced>
  )
}

export interface LessonFormProps {
  form: FormInstance<LessonFormValues>
  onFinish: FormProps<LessonFormValues>['onFinish']
  showAttachment?: boolean
}
export const LessonForm: React.FC<React.PropsWithChildren<LessonFormProps>> = ({
  form,
  onFinish,
  showAttachment,
}) => (
  <Form form={form} onFinish={onFinish}>
    <LessonFormFields showAttachment={showAttachment} />
  </Form>
)
