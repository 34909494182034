import { ClassConfigurationFieldResource } from '@thesisedu/feature-classes-react'
import { SegmentMetadata } from '@thesisedu/feature-course-types'
import { Block, Button, Checkbox, HSpaced, Modal, Text, VSpaced } from '@thesisedu/ui'
import { Trash, Wrench } from '@thesisedu/ui/icons'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'
import { useUpdateClassMutation } from '../../classes/useUpdateClassMutation'
import { useCourseContext } from '../../contexts/CourseContext'
import { debug } from '../../log'

enum ResetMode {
  StructureOverrides = 'structure',
  SegmentWeights = 'weights',
  EnabledSegments = 'enabled',
}
const ALL_RESET_MODES: ResetMode[] = [
  ResetMode.StructureOverrides,
  ResetMode.SegmentWeights,
  ResetMode.EnabledSegments,
]

type ResetModeHandler = (metadata: SegmentMetadata) => SegmentMetadata
const HANDLERS: Record<ResetMode, ResetModeHandler> = {
  [ResetMode.StructureOverrides]: ({ structureOverrides, ...restMetadata }) => {
    return restMetadata
  },
  [ResetMode.SegmentWeights]: metadata => {
    return {
      ...metadata,
      segments: metadata.segments?.map(({ weight, ...rest }) => rest),
    }
  },
  [ResetMode.EnabledSegments]: metadata => {
    return {
      ...metadata,
      segments: metadata.segments?.map(
        ({ visibleOverride, enabledStudentIds, scheduledAt, ...rest }) => rest,
      ),
    }
  },
}

export interface ResetCheckboxProps {
  mode: ResetMode
  resetModes: ResetMode[]
  setResetModes: (modes: ResetMode[]) => void
  title: string
  description: string
}
export function ResetCheckbox({
  mode,
  resetModes,
  setResetModes,
  title,
  description,
}: ResetCheckboxProps) {
  return (
    <label>
      <HSpaced space={'xs'} align={'flex-start'}>
        <Checkbox
          checked={resetModes.includes(mode)}
          onCheckedChange={checked => {
            if (checked) setResetModes([...new Set<ResetMode>([...resetModes, mode])])
            else setResetModes(resetModes.filter(m => m !== mode))
          }}
        />
        <div>
          <Text weight={'bold'}>{title}</Text>
          <Text level={'s'} color={'secondary'}>
            {description}
          </Text>
        </div>
      </HSpaced>
    </label>
  )
}

interface ResetCustomizationsInClassButtonProps {
  classId: string
}
function ResetCustomizationsInClassButton({ classId }: ResetCustomizationsInClassButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const [updateClass, { loading }] = useUpdateClassMutation(classId, {
    onCompleted() {
      setVisible(false)
    },
  })
  const { segmentMetadata } = useCourseContext(true)
  const [resetModes, setResetModes] = React.useState(ALL_RESET_MODES)
  const common = { resetModes, setResetModes }
  return (
    <Modal
      visible={visible}
      onVisibleChange={setVisible}
      trigger={<Button children={'Reset Customizations'} status={'danger'} />}
      title={'Reset Customizations'}
    >
      <Text bottom={'s'}>Which customizations would you like to reset?</Text>
      <VSpaced space={'xs'}>
        <ResetCheckbox
          {...common}
          mode={ResetMode.StructureOverrides}
          title={'Structure Overrides'}
          description={'Placed content, reparenting segments'}
        />
        <ResetCheckbox
          {...common}
          mode={ResetMode.SegmentWeights}
          title={'Segment Weights'}
          description={'Re-ordering content'}
        />
        <ResetCheckbox
          {...common}
          mode={ResetMode.EnabledSegments}
          title={'Enabled Segments'}
          description={'Enabled, scheduled, enabled for some students, etc.'}
        />
      </VSpaced>
      <Text top={'m'} weight={'bold'}>
        Make sure you are{' '}
        <Text inline color={'red'}>
          absolutely sure
        </Text>{' '}
        you want to make these changes. Once you click the reset button, these changes{' '}
        <Text inline color={'red'}>
          cannot be undone.
        </Text>
      </Text>
      <Modal.Footer>
        <Modal.CloseButton />
        <Button
          variant={'primary'}
          status={'danger'}
          icon={<Trash />}
          loading={loading}
          disabled={!resetModes.length || !segmentMetadata}
          onPress={() => {
            if (segmentMetadata) {
              let draftMetadata = segmentMetadata
              for (const mode of resetModes) {
                debug('applying mode %s', mode)
                draftMetadata = HANDLERS[mode](draftMetadata)
              }
              updateClass({ segmentMetadata: draftMetadata }, true, true)
            } else {
              debug('not applying updates, since there is no segment metadata')
            }
          }}
          children={'Reset Customizations'}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<ClassConfigurationFieldResource>({
    type: 'ClassConfigurationField',
    identifier: 'resetCustomizations',
    group: 'Maintenance',
    groupIcon: <Wrench />,
    weight: 1000,
    filter(viewer, isImpersonating) {
      return !!isImpersonating
    },
    renderField({ classId }) {
      if (classId) {
        return (
          <Block bottom={'l'}>
            <ResetCustomizationsInClassButton classId={classId} />
          </Block>
        )
      } else return null
    },
  })
}
