import {
  isMatchClientConfig,
  MatchAnswer as MatchAnswerType,
  MatchClientConfig,
  matchToClientConfig,
} from '@thesisedu/feature-assignments-core'
import React from 'react'

import { useHasSubmission, useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { DragDropAnswerView } from '../DragDropAnswerView'

export function MatchAnswer({ question, answerView }: QuestionProps<MatchClientConfig>) {
  const hasSubmission = useHasSubmission()
  const answer = useSubmissionData(question.id) as MatchAnswerType[] | undefined
  const config = question.config
  if (!config) return null
  const clientConfig = isMatchClientConfig(config) ? config : matchToClientConfig(config, false)
  const showExplanation = answerView && typeof answerView === 'object' && answerView.showExplanation
  return (
    <DragDropAnswerView
      selectedAnswers={hasSubmission ? answer ?? [] : answer}
      destinations={clientConfig.destinations || []}
      answer={clientConfig.answer}
      hideCorrectAnswers={showExplanation === false}
    />
  )
}
