import { styled } from './styledTypes'

export const CommaDelimitedList = styled.span`
  > :not(:first-child):not(:empty)::before {
    content: ', ';
  }
  > :empty + :not(:empty)::before {
    content: ' ';
  }
  > :not(:empty) ~ :empty + :not(:empty)::before {
    content: ', ';
  }
`

export const MiddotDelimitedList = styled.span`
  > :not(:first-child):not(:empty)::before {
    content: '·';
    margin: 0 0.25em;
  }
  > :empty + :not(:empty)::before {
    content: ' ';
  }
  > :not(:empty) ~ :empty + :not(:empty)::before {
    content: '·';
    margin: 0 0.25em;
  }
  > * {
    display: inline-block !important;
  }
`
