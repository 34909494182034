import { TagsEditor } from '@thesisedu/feature-tags-react'
import { Input, Form, AutoComplete } from 'antd'
import { FormItemProps } from 'antd/es/form/FormItem'
import React from 'react'

import { IconSelect } from '../../IconSelect'
import { SIMPLE_ICONS } from '../../constants'
import { Editor } from '../../editor/Editor'
import { useLabelPlaceholder, useSectionLabels, useTagsEditorProps } from '../../useOptions'

export function SectionLabelField(props: Partial<FormItemProps>) {
  const SECTION_LABELS = useSectionLabels()
  const LABEL_PLACEHOLDER = useLabelPlaceholder()
  return (
    <Form.Item label={'Label'} name={['config', 'label']} {...props}>
      <AutoComplete
        dataSource={SECTION_LABELS}
        filterOption={(inputValue, option) =>
          option?.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        placeholder={LABEL_PLACEHOLDER}
      />
    </Form.Item>
  )
}

export function SectionIconField(props: Partial<FormItemProps>) {
  return (
    <Form.Item label={'Icon'} name={['config', 'icon']} {...props}>
      <IconSelect whitelist={SIMPLE_ICONS} />
    </Form.Item>
  )
}

export interface SectionNameFieldProps extends Partial<FormItemProps> {
  typeLabel: string
}
export function SectionNameField({ typeLabel, ...props }: SectionNameFieldProps) {
  return (
    <Form.Item
      label={`${typeLabel} Name`}
      name={'name'}
      rules={[{ required: true, message: `${typeLabel} name is required.` }]}
      {...props}
    >
      <Input placeholder={'Title'} />
    </Form.Item>
  )
}

export function SectionTagsField(props: FormItemProps) {
  const editorProps = useTagsEditorProps()
  return (
    <Form.Item label={'Tags'} name={'updateTags'} {...props}>
      <TagsEditor {...editorProps} />
    </Form.Item>
  )
}

export interface ContentFieldProps extends Partial<FormItemProps> {
  segmentId: string
}
export function StudentContentField({ segmentId, ...props }: ContentFieldProps) {
  return (
    <Form.Item name={['config', 'studentContent']} {...props}>
      <Editor
        id={`${segmentId}-student`}
        placeholder={'Add student instructions / information here.'}
        minHeight={50}
        noPadding
        showToolbar
      />
    </Form.Item>
  )
}

export function TeacherContentField({ segmentId, ...props }: ContentFieldProps) {
  return (
    <Form.Item name={['config', 'teacherContent']} {...props}>
      <Editor
        id={`${segmentId}-student`}
        placeholder={'Add any teacher notes here.'}
        minHeight={50}
        noPadding
        showToolbar
      />
    </Form.Item>
  )
}
