import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { CreatePresetPage } from './CreatePresetPage'
import { EditPresetPage } from './EditPresetPage'
import { ListPresetsPage } from './ListPresetsPage'

export interface PresetsRoutesProps {
  classId: string
}
export function PresetsRoutes(props: PresetsRoutesProps) {
  return (
    <Routes>
      <Route path={'/create'} element={<CreatePresetPage {...props} />} />
      <Route path={'/:presetId'} element={<EditPresetPage {...props} />} />
      <Route path={'/'} element={<ListPresetsPage {...props} />} />
    </Routes>
  )
}
