import { useFeature, useResource } from '@thesisedu/feature-react'

import CoursesReactFeature, { SegmentType, SegmentTypeResource } from './index'

export function useTagsEditorProps(segmentType?: SegmentType) {
  const segmentResource = useResource<SegmentTypeResource>('SegmentType', segmentType || 'na')
  const feature = useFeature(CoursesReactFeature)
  return {
    types: [...feature.tagTypes, ...(segmentResource?.additionalTagTypes || [])],
    alwaysShowTagTypes: [
      ...(feature.options.alwaysShowTagTypes || []),
      ...(segmentResource?.additionalTagTypes || []).map(tagType => tagType.type),
    ],
  }
}

export function useTagTypes() {
  return useTagsEditorProps().types
}

export function useSectionLabels() {
  const feature = useFeature(CoursesReactFeature)
  return feature.sectionLabels
}

export function useLabelPlaceholder() {
  const feature = useFeature(CoursesReactFeature)
  return feature.labelPlaceholder
}
