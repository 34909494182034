export enum Note {
  Quarter = '4n',
  Eighth = '8n',
}
export enum Tone {
  A4 = 'a4',
  A3 = 'a3',
  B4 = 'b4',
  B3 = 'b3',
  C4 = 'c4',
  C3 = 'c3',
  D4 = 'd4',
  D3 = 'd3',
}

export interface MetronomeContextValue {
  visible: boolean
  playing: boolean
  bpm: number
  note: Note
  emphasizedNote: number
  tones: [Tone, Tone]
  indicatorRef: React.MutableRefObject<HTMLElement | null>
  update: (changes: Partial<Omit<MetronomeContextValue, 'update'>>) => void
}
