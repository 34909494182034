import { useFreshRef } from '@thesisedu/feature-react'
import { Block, HSpaced } from '@thesisedu/ui'
import { isEqual } from 'lodash'
import React from 'react'

import { ActionsContext } from './ActionsContext'
import { ReportValidation } from './ReportValidation'
import { SmallConfiguration } from '../config/SmallConfiguration'
import { packOpts } from '../config/storage'
import { getInputIdentifier } from '../execute/input'
import { RunReportOpts } from '../types'

export interface ConfigurationRightProps {
  report: RunReportOpts
}
export interface UnpackedReportPageProps {
  report: RunReportOpts
  onReportPacked: (packed: string) => void
  ConfigurationRight?: React.FC<React.PropsWithChildren<ConfigurationRightProps>>
}
export function UnpackedReportPage({
  report,
  onReportPacked,
  ConfigurationRight,
}: UnpackedReportPageProps) {
  const [opts, setOpts] = React.useState(report)
  const optsRef = useFreshRef(opts)
  const optsTimeout = React.useRef<any>()
  const actionsHost = React.useRef<HTMLDivElement>(null)
  const updateOpts = (_newOpts: RunReportOpts, newTab?: boolean) => {
    clearTimeout(optsTimeout.current)
    const changedReport = !isEqual(_newOpts.dimensions, optsRef.current?.dimensions)
    const newOpts: RunReportOpts = {
      ..._newOpts,
      graphOptions: changedReport
        ? undefined
        : {
            ..._newOpts.graphOptions,
            selectedMetricKey:
              _newOpts.metrics.length === 1
                ? getInputIdentifier(_newOpts.metrics[0])
                : _newOpts.graphOptions?.selectedMetricKey,
          },
    }
    if (!newTab) {
      setOpts(newOpts)
    }
    // Debounce here so we don't end up updating the URL in quick succession.
    optsTimeout.current = setTimeout(() => {
      packOpts(newOpts).then(packed => {
        const segments = window.location.pathname.toString().split('/')
        segments.pop()
        if (newTab) {
          window.open(window.origin + [...segments, packed].join('/'), '_blank')
        } else {
          onReportPacked(packed)
        }
      })
    }, 500)
  }
  return (
    <>
      <Block bottom={'s'}>
        <HSpaced>
          <SmallConfiguration value={opts} onChange={updateOpts} />
          {ConfigurationRight ? <ConfigurationRight report={opts} /> : null}
          <div ref={actionsHost} style={{ marginLeft: 'auto' }} />
        </HSpaced>
      </Block>
      <ActionsContext.Provider value={{ hostRef: actionsHost }}>
        <ReportValidation
          report={opts}
          onReportChange={(opts, noNewTab) => {
            updateOpts(opts, !noNewTab)
          }}
        />
      </ActionsContext.Provider>
    </>
  )
}
