import { Upload } from '@thesisedu/feature-attachments-react'
import { message } from 'antd'
import React from 'react'

import { useCreateVideoOnDemandMutation } from './queries/useCreateVideoOnDemandMutation'
import { useFinishUploadingVideoOnDemandMutation } from './schema'
import { VideoUploadProps } from './types'

export function DefaultVideoUpload({ name, description, provider, onComplete }: VideoUploadProps) {
  const [createVod] = useCreateVideoOnDemandMutation()
  const [finishUpload] = useFinishUploadingVideoOnDemandMutation()
  const vodIdRef = React.useRef<string | undefined>(undefined)
  return (
    <Upload
      forceBasicMode
      getWriteFragment={async input => {
        const result = await createVod({
          variables: {
            input: {
              name,
              provider,
              description,
              sizeInBytes: input.sizeInBytes,
              signedUploadInput: input.signedUploadInput,
            },
          },
        })
        const createResult = result?.data?.createVideoOnDemand
        const signedResult =
          createResult?.payload?.__typename === 'SignedUploadResult'
            ? createResult.payload
            : undefined
        if (createResult && signedResult) {
          vodIdRef.current = createResult.videoOnDemand.id
          const { signedUploadData, ...upload } = signedResult
          return {
            uploadUrl: {
              upload: {
                ...upload,
                data: signedUploadData,
              },
            },
          }
        } else {
          return null
        }
      }}
      onMinimalUploaded={async () => {
        if (vodIdRef.current) {
          const finishResult = await finishUpload({
            variables: {
              input: {
                vodId: vodIdRef.current,
              },
            },
          })
          if (finishResult?.data?.finishUploadingVideoOnDemand.success) {
            onComplete(vodIdRef.current)
            return
          }
        }
        // We returned already above if we were successful.
        message.error('There was an error completing your upload.')
      }}
      accept={'video/*'}
    />
  )
}
