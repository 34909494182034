import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Popconfirm, Button, ButtonProps } from 'antd'
import React from 'react'

export interface DeleteTagButtonProps extends Partial<ButtonProps> {
  tagId: string
  onDeleted?: () => void
}
export function DeleteTagButton({ tagId, onDeleted, ...rest }: DeleteTagButtonProps) {
  const [deleteTag, { loading }] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that tag.',
    onCompleted: () => {
      if (onDeleted) {
        onDeleted()
      }
    },
    update: cache => {
      cache.evict({
        id: `Tag:${tagId}`,
      })
    },
  })

  return (
    <Popconfirm
      title={'Are you sure?'}
      placement={'topLeft'}
      okText={'Yes, delete tag.'}
      okButtonProps={{ danger: true }}
      cancelText={'No, keep.'}
      onConfirm={() => {
        return deleteTag({
          variables: {
            id: tagId,
          },
        })
      }}
    >
      <Button type={'link'} danger loading={loading} {...rest}>
        Delete
      </Button>
    </Popconfirm>
  )
}
