import assignmentSettings from './AssignmentSettings'
import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'

export function addSegmentActions(feature: CourseAssignmentsReactFeature) {
  assignmentSettings(feature)
  const syncFeature = feature.root.getFeature('@thesisedu/feature-class-sync-react')
  if (syncFeature) {
    const { AssignmentSyncAction } = require('./AssignmentSync')
    feature.resources.addResource(AssignmentSyncAction)
  }
}
