import { useResource } from '@thesisedu/feature-react'
import { Empty } from '@thesisedu/react'
import { LoadingIndicator, Button, styled, Text, Grid, s } from '@thesisedu/ui'
import React from 'react'

import { RequiresSetupModal } from './RequiresSetupModal'
import { SiteClassSyncProviderFragment, useTeacherSiteClassSyncProvidersQuery } from '../../schema'
import { ClassSyncProviderResource } from '../../types'

export interface ProviderGridProps {
  onProviderSelected: (
    provider: SiteClassSyncProviderFragment & { resource: ClassSyncProviderResource },
  ) => void
}
export function ProviderGrid({ onProviderSelected }: ProviderGridProps) {
  const providerResources = useResource<ClassSyncProviderResource>('ClassSyncProvider')
  const { data, loading } = useTeacherSiteClassSyncProvidersQuery()
  const providers = data?.viewer?.teacher?.site?.classSyncProviders ?? []
  const [modalProvider, setModalProvider] = React.useState<{
    identifier: string
    name: string
  } | null>(null)
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading Providers'} />
  } else if (providers.length) {
    return (
      <>
        {modalProvider ? (
          <RequiresSetupModal
            visible
            onVisibleChange={v => {
              if (!v) setModalProvider(null)
            }}
            providerIdentifier={modalProvider.identifier}
            providerName={modalProvider.name}
          />
        ) : null}
        <Grid columnWidth={250} gap={'s'}>
          {providers.map(provider => {
            const resource = providerResources.find(r => r.identifier === provider.identifier)
            if (!resource) throw new Error(`Cannot find provider resource '${provider.identifier}'`)
            return (
              <ProviderButton
                onPress={() => {
                  if (provider.requiresSetup) {
                    setModalProvider({
                      identifier: provider.identifier,
                      name: provider.name,
                    })
                  } else {
                    onProviderSelected({ resource, ...provider })
                  }
                }}
              >
                <img
                  style={{ height: 60 }}
                  src={resource.logos.square}
                  alt={`Logo for ${provider.name}`}
                />
                <div>
                  <Text>{provider.name}</Text>
                  {provider.requiresSetup ? (
                    <Text level={'xs'} color={'red'}>
                      Requires admin setup
                    </Text>
                  ) : null}
                </div>
              </ProviderButton>
            )
          })}
        </Grid>
      </>
    )
  } else {
    return <Empty description={'No providers available.'} />
  }
}

const ProviderButton = styled(Button)`
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  gap: ${s.size('s')};
  justify-content: stretch;
  text-align: left;
`
