import { EditOutlined } from '@ant-design/icons'
import { Modal } from '@thesisedu/web'
import { Button, Form, message } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React, { useEffect, useState } from 'react'

import { CourseForm } from './CourseForm'
import { useUpdateCourseMutation } from '../queries/useUpdateCourseMutation'
import { CourseFragment } from '../schema'

export interface EditCourseModalProps {
  course: CourseFragment
  visible?: boolean
  onCancel: () => void
}
export function EditCourseModal({ course, visible, onCancel }: EditCourseModalProps) {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      ...course,
      configuration: course.basicConfiguration,
    })
  }, [form, course])
  const [updateCourse, { loading }] = useUpdateCourseMutation({
    onCompleted: () => {
      message.success('Course updated successfully.')
      onCancel()
    },
  })
  return (
    <Modal
      title={'Edit Course'}
      onCancel={onCancel}
      visible={visible}
      width={'90vw'}
      style={{
        maxWidth: '1000px',
      }}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} onClick={form.submit} type={'primary'} loading={loading}>
          Save Changes
        </Button>,
      ]}
    >
      <CourseForm
        form={form}
        onFinish={values => {
          return updateCourse({
            variables: {
              input: {
                id: course.id,
                patch: {
                  ...values,
                  configuration: {
                    ...course.basicConfiguration,
                    ...values.configuration,
                  },
                },
              },
            },
          })
        }}
      />
    </Modal>
  )
}

export type EditCourseButtonProps = Pick<EditCourseModalProps, 'course'> & Partial<ButtonProps>
export function EditCourseButton({ course, ...props }: EditCourseButtonProps) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <EditCourseModal course={course} visible={visible} onCancel={() => setVisible(false)} />
      <Button type={'primary'} icon={<EditOutlined />} {...props} onClick={() => setVisible(true)}>
        Edit Course
      </Button>
    </>
  )
}
