import { isSameDay } from '@internationalized/date'
import { AriaCalendarCellProps, useCalendarCell } from '@react-aria/calendar'
import React from 'react'
import { CalendarState, RangeCalendarState } from 'react-stately'

import { styled, s } from '../..'

function _isRangeCalendarState(
  state: CalendarState | RangeCalendarState,
): state is RangeCalendarState {
  return 'highlightedRange' in state
}

export interface CalendarCellProps extends AriaCalendarCellProps {
  state: CalendarState | RangeCalendarState
}
export function CalendarCell({ state, ...props }: CalendarCellProps) {
  const ref = React.useRef<HTMLDivElement>(null)
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
  } = useCalendarCell(props, state, ref)

  const isSelectionStart =
    _isRangeCalendarState(state) && state.highlightedRange
      ? isSameDay(props.date, state.highlightedRange.start)
      : isSelected
  const isSelectionEnd =
    _isRangeCalendarState(state) && state.highlightedRange
      ? isSameDay(props.date, state.highlightedRange.end)
      : isSelected

  return (
    <td {...cellProps}>
      <_Cell
        {...buttonProps}
        hidden={isOutsideVisibleRange}
        className={`${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''} ${
          isUnavailable ? 'unavailable' : ''
        } ${isSelectionStart ? 'selection-start' : ''} ${isSelectionEnd ? 'selection-end' : ''}`}
      >
        {formattedDate}
      </_Cell>
    </td>
  )
}
const _Cell = styled.div`
  cursor: default;
  text-align: center;
  &:hover {
    background: ${s.color('element')};
  }
  &.selected {
    background: ${s.color('primary.hoverElement')};
  }
  &.unavailable {
    color: ${s.color('red')};
  }
  &.disabled {
    color: ${s.color('secondary')};
  }
  &.selection-start {
    border-top-left-radius: ${s.var('radii.0')};
    border-bottom-left-radius: ${s.var('radii.0')};
  }
  &.selection-end {
    border-top-right-radius: ${s.var('radii.0')};
    border-bottom-right-radius: ${s.var('radii.0')};
  }
  &.selection-start,
  &.selection-end {
    background: ${s.color('primary')};
    color: white;
  }
`
