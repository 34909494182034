import { GroupOutlined } from '@ant-design/icons'
import {
  isMatchClientConfig,
  MatchClientConfig,
  MatchConfig,
} from '@thesisedu/feature-assignments-core'

import { GroupAnswer } from './GroupAnswer'
import { GroupContent, isQuestionComplete } from './GroupContent'
import { GroupEdit } from './GroupEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'Group',
  defaults: {
    name: 'Group Question',
    config: {
      options: [],
    },
  },
  icon: GroupOutlined,
  label: 'Group',
  group: 'Drag and Drop',
  renderAnswerView: GroupAnswer,
  renderContent: GroupContent,
  renderEdit: GroupEdit,
  isQuestionComplete,
  usesRealTimeConfirmation: true,
  isAutoGraded: question => {
    const config = question.config
    if (config && isMatchClientConfig(config)) {
      return !!config.destinations.length && !!config.sources.length
    } else if (config && !isMatchClientConfig(config)) {
      return !!config.options?.length
    } else return false
  },
} as QuestionTypeResource<MatchConfig | MatchClientConfig>
