import { EditUserPage, ImpersonateButton, UsersTable } from '@thesisedu/feature-users-web'
import { useUserQuery } from '@thesisedu/feature-users-web/dist/schema'
import { PageHead } from '@thesisedu/react'
import { Button } from '@thesisedu/ui'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'
import { Routes, Route, useParams } from 'react-router-dom'

import { FreeUsernameModal } from '../web/FreeUsernameModal'

export function AdminUsers() {
  useBreadcrumb({ title: 'Users' })
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <>
            <PageHead title={'Users'} />
            <PageHeader title={'Users'}>
              <FreeUsernameModal trigger={<Button children={'Free a Username or Email'} />} />
            </PageHeader>
            <UsersTable />
          </>
        }
      />
      <Route path={':userId'} element={<EditUser />} />
    </Routes>
  )
}

export interface UserNameProps {
  userId: string
}
export function UserName({ userId }: UserNameProps) {
  const { data } = useUserQuery({ variables: { id: userId } })
  return (
    <span>
      {data?.node?.__typename === 'User' ? data?.node.name || data?.node.username : '...'}
    </span>
  )
}

function EditUser() {
  const { userId } = useParams()
  useBreadcrumb({ title: <UserName userId={userId} /> })
  return (
    <>
      <PageHead title={'Edit User'} />
      <EditUserPage
        userId={userId}
        otherActions={user => <ImpersonateButton userId={user.id}>Login as User</ImpersonateButton>}
      />
    </>
  )
}
