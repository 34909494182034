import { Form, Input } from 'antd'
import { FormInstance, FormProps } from 'antd/es/form'
import React from 'react'

import { DistrictConfiguration } from './DistrictConfiguration'
import { StateSelect } from './StateSelect'

export interface DistrictFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
  districtId?: string
}
export const DistrictForm: React.FC<React.PropsWithChildren<DistrictFormProps>> = ({
  form,
  onFinish,
  districtId,
}) => (
  <Form form={form} layout={'vertical'} onFinish={onFinish}>
    <Form.Item
      name={'name'}
      label={'Name'}
      rules={[{ required: true, message: 'A name is required.' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={'city'}
      label={'City'}
      rules={[{ required: true, message: 'A city is required.' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={'state'}
      label={'State'}
      rules={[{ required: true, message: 'A state is required.' }]}
    >
      <StateSelect />
    </Form.Item>
    <Form.Item name={'contactName'} label={'Contact Name'}>
      <Input />
    </Form.Item>
    <Form.Item
      name={'contactEmail'}
      label={'Contact Email'}
      rules={[{ type: 'email', message: 'Must be a valid email.' }]}
    >
      <Input type={'email'} />
    </Form.Item>
    <DistrictConfiguration fieldPrefix={['configuration']} districtId={districtId} />
  </Form>
)
