import { useViewerContext } from '@thesisedu/feature-users-web'
import React, { useContext } from 'react'

import { LicenseType, useViewerLicenseQuery } from './schema'

export interface ViewerLicenseContextValue {
  license?: {
    id: string
    type: LicenseType
  } | null
}
export const ViewerLicenseContext = React.createContext<ViewerLicenseContextValue | undefined>(
  undefined,
)

export function ViewerLicenseContextProvider({ children }: React.PropsWithChildren<object>) {
  const viewer = useViewerContext(false)
  const { data } = useViewerLicenseQuery({
    skip: viewer?.group !== 'TEACHER',
  })
  if (viewer?.group === 'TEACHER') {
    return (
      <ViewerLicenseContext.Provider
        value={{
          license: data?.viewer?.teacher?.site?.license,
        }}
        children={children}
      />
    )
  } else {
    return <>{children}</>
  }
}

export function useLicenseIsTrial() {
  const context = useContext(ViewerLicenseContext)
  return context?.license?.type === LicenseType.Trial
}
