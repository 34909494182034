import { StateSelect, DistrictSelect } from '@thesisedu/feature-districts-web'
import { WebContext } from '@thesisedu/feature-web'
import { Form, Input } from 'antd'
import { FormInstance, FormProps } from 'antd/es/form'
import React, { useContext } from 'react'

import { SiteConfiguration } from './SiteConfiguration'
import { TypeSelector } from './TypeSelector'
import { SiteFormItemResource } from './types'

export interface SiteFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
  siteId?: string
  districtId?: string
}
export const SiteForm: React.FC<React.PropsWithChildren<SiteFormProps>> = ({
  form,
  onFinish,
  siteId,
  districtId,
}) => {
  const context = useContext(WebContext)
  const resources =
    context?.featureWeb.deps.resources
      .getResourcesForType<SiteFormItemResource>('SiteFormItem')
      .map(resource => (
        <React.Fragment key={resource.identifier}>{resource.render()}</React.Fragment>
      )) || []
  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Form.Item
        name={'name'}
        label={'Name'}
        rules={[{ required: true, message: 'A site name is required.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'type'}
        label={'Type'}
        rules={[{ required: true, message: 'A site type is required.' }]}
      >
        <TypeSelector />
      </Form.Item>
      <Form.Item
        name={'city'}
        label={'City'}
        rules={[{ required: true, message: 'A city is required.' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'state'}
        label={'State'}
        rules={[{ required: true, message: 'A state is required.' }]}
      >
        <StateSelect />
      </Form.Item>
      <Form.Item name={'districtId'} label={'District'}>
        <DistrictSelect />
      </Form.Item>
      <Form.Item name={'note'} label={'Notes'}>
        <Input.TextArea rows={6} placeholder={'Add any notes you find useful here.'} />
      </Form.Item>
      {resources}
      <SiteConfiguration fieldPrefix={['configuration']} siteId={siteId} districtId={districtId} />
    </Form>
  )
}
