import React from 'react'

export interface SheetMusicPluginContextValue {
  playing: boolean
  setPlaying: (playing: boolean) => void
  visible: boolean
  setVisible: (visible: boolean) => void
}
export const SheetMusicPluginContext = React.createContext<
  SheetMusicPluginContextValue | undefined
>(undefined)

export interface SheetMusicPluginProviderProps {
  children: React.ReactNode
}
export function SheetMusicPluginProvider({ children }: SheetMusicPluginProviderProps) {
  const [playing, setPlaying] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  return (
    <SheetMusicPluginContext.Provider
      value={{ playing, setPlaying, visible, setVisible }}
      children={children}
    />
  )
}

export function useSheetMusicPluginContext(): SheetMusicPluginContextValue | undefined
export function useSheetMusicPluginContext(require: false): SheetMusicPluginContextValue | undefined
export function useSheetMusicPluginContext(require: true): SheetMusicPluginContextValue
export function useSheetMusicPluginContext(
  require?: boolean,
): SheetMusicPluginContextValue | undefined {
  const context = React.useContext(SheetMusicPluginContext)
  if (!context && require) {
    throw new Error('SheetMusicPluginContext is required, yet not provided.')
  }
  return context
}
