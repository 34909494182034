import { Button, styled, HSpaced, Text, s } from '@thesisedu/ui'
import { AddCircle, MinusCircle, Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { ProviderStudentDropdown } from './ProviderStudentDropdown'
import { ICON_SIZE_PX } from './RosteringHeader'
import { ListItemStatus, RosteringListItemStatus } from './RosteringListItemStatus'
import { RosteringStudent } from './RosteringStudent'
import { ClassSyncRosterActionFragment, ClassSyncRosterActionMode } from '../../schema'

export interface RosteringListItemProps {
  update: ClassSyncRosterActionFragment
  highlighted?: boolean
  onChange: (update: ClassSyncRosterActionFragment) => void
}
export function RosteringListItem({ update, highlighted, onChange }: RosteringListItemProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (highlighted && containerRef.current) {
      containerRef.current.scrollIntoView()
    }
  }, [highlighted])
  let status: ListItemStatus = update.providerStudent
    ? ListItemStatus.Ignore
    : ListItemStatus.Unmatched
  switch (update.mode) {
    case ClassSyncRosterActionMode.Match:
      status = ListItemStatus.Matched
      break
    case ClassSyncRosterActionMode.Import:
      status = ListItemStatus.Import
      break
    case ClassSyncRosterActionMode.Remove:
      status = ListItemStatus.ToRemove
      break
  }
  return (
    <Container
      space={'xs'}
      $ignored={!update.mode && !update.student && !!update.providerStudent}
      $highlighted={highlighted}
      ref={containerRef}
    >
      <SideContainer>
        {update.student ? (
          <>
            <LeftContainer>
              <Button
                icon={<Trash />}
                variant={'ghost'}
                status={update.mode === ClassSyncRosterActionMode.Remove ? 'danger' : undefined}
                size={'small'}
                onPress={() => {
                  onChange({
                    ...update,
                    providerStudent: undefined,
                    mode:
                      update.mode === ClassSyncRosterActionMode.Remove
                        ? undefined
                        : ClassSyncRosterActionMode.Remove,
                  })
                }}
              />
            </LeftContainer>
            <RosteringStudent user={update.student.user} />
          </>
        ) : null}
      </SideContainer>
      <RosteringListItemStatus status={status} />
      <SideContainer style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
        <>
          {update.providerStudent ? (
            <RosteringStudent user={update.providerStudent.userInfo} />
          ) : (
            <Text level={'s'} color={'secondary'}>
              Select Student
            </Text>
          )}
          {update.student ? (
            <ProviderStudentDropdown
              value={update.providerStudent?.id}
              onChange={student => {
                onChange({
                  ...update,
                  providerStudent: student || undefined,
                  mode:
                    student && update.student
                      ? ClassSyncRosterActionMode.Match
                      : student && !update.student
                      ? ClassSyncRosterActionMode.Import
                      : undefined,
                })
              }}
            />
          ) : (
            <Button
              icon={
                update.mode === ClassSyncRosterActionMode.Import ? <MinusCircle /> : <AddCircle />
              }
              variant={'ghost'}
              size={'small'}
              onPress={() => {
                onChange({
                  ...update,
                  mode:
                    update.mode === ClassSyncRosterActionMode.Import
                      ? undefined
                      : ClassSyncRosterActionMode.Import,
                })
              }}
            />
          )}
        </>
      </SideContainer>
    </Container>
  )
}

const Container = styled(HSpaced)<{ $ignored?: boolean; $highlighted?: boolean }>`
  transition: opacity 0.1s linear;
  opacity: ${props => (props.$ignored ? 0.75 : 1)};
  padding: ${s.size('xxs')} 0;
  position: relative;
  ${s.ifs(props => !!props.$highlighted)} {
    &::before {
      position: absolute;
      inset: 0;
      content: '';
      display: block;
      z-index: -1;
      border-radius: ${s.var('radii.1')};
      background: ${s.color('primary.element')};
    }
  }
`
const SideContainer = styled.div`
  flex: 1;
  width: 40%;
  gap: ${s.size('xs')};
  display: flex;
  align-items: center;
`
const LeftContainer = styled.div`
  width: ${ICON_SIZE_PX}px;
  display: flex;
  align-items: center;
  justify-content: center;
`
