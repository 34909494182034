import React from 'react'

import { s } from '../..'
import Block, { BlockSize } from '../Block'
import Text, { TextProps } from '../Text'

export interface SeparatorProps {
  children?: React.ReactElement | string
  orientation?: Orientation
  top?: BlockSize
  bottom?: BlockSize
  left?: BlockSize
  right?: BlockSize
  level?: TextProps['level']
  className?: string
  style?: any
}
function _Separator(
  {
    children,
    orientation = 'horizontal',
    top,
    bottom,
    left,
    right,
    level = 's',
    className,
    style,
  }: SeparatorProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <_Container
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      ref={ref}
      className={s.variants<typeof _Variants>(className, {
        horizontal: orientation === 'horizontal',
        vertical: orientation === 'vertical',
      })}
      style={style}
    >
      {typeof children === 'string' ? (
        <Text level={level} color={'secondary'}>
          {children}
        </Text>
      ) : (
        children
      )}
    </_Container>
  )
}
export const Separator = React.forwardRef(_Separator)

const { css } = s
const Orientations = {
  horizontal: css`
    width: 100%;
    min-height: 2px;
    &::before,
    &::after {
      height: 2px;
    }
  `,
  vertical: css`
    min-width: 2px;
    flex-direction: row;
    align-self: stretch;
    min-height: 100%;
    &::after {
      display: none;
    }
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      z-index: 0;
    }
    > * {
      padding: ${s.size('xs')} 0;
      background: ${s.color('background')};
      z-index: 1;
    }
  `,
} satisfies s.Variants
export type Orientation = keyof typeof Orientations

const _Variants = { ...Orientations }
const _Container = s.styledWithVariants(
  Block,
  'DividerContainer',
  css`
    position: relative;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: ${s.size('s')};
    &::before,
    &::after {
      display: flex;
      content: '';
      background: ${s.color('subtleBorder')};
      border-radius: ${s.var('radii.0')};
      flex: 1;
    }
    &:empty::after {
      display: none;
    }
  `,
  _Variants,
)
