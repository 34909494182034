import { useResource, useDeepLink } from '@thesisedu/feature-react'
import { Grid, message, Empty } from '@thesisedu/react'
import { GridProps } from '@thesisedu/react/dist/grid'
import React from 'react'

import { warn } from '../log'
import { SearchResultNode, SearchResultResource, RenderGrid } from '../types'
import { useSearchResultRenderer } from '../useSearchResultRenderer'

interface SearchResultNodeWithDate extends SearchResultNode {
  date: string
}
export interface SearchResultGridProps extends Partial<GridProps<SearchResultNode>> {
  results?: SearchResultNode[]
  useSections?: boolean
  itemHeightKey: string
  empty?: React.ReactElement
  loadMore: () => Promise<any>
}
export function SearchResultGrid({
  results = [],
  useSections,
  itemHeightKey,
  empty,
  loadMore,
  ...rest
}: SearchResultGridProps) {
  const resources = useResource<SearchResultResource<SearchResultNode>>('SearchResult')
  const { navigate, linkHandler } = useDeepLink({ onError: message.error })
  let items
  if (useSections) {
    const withDate = results
      .map(result => {
        const resource = resources.find(resource => resource.identifier === result.__typename)
        if (resource?.getDate) {
          return { ...result, date: resource.getDate(result) }
        } else return null
      })
      .filter(Boolean) as SearchResultNodeWithDate[]
    items = Grid.generateSectionsByDate(withDate)
  } else {
    items = results
  }
  const itemHeight = React.useCallback<Grid.GridProps<SearchResultNode>['itemHeight']>(
    (itemWidth, item) => {
      const resource = resources.find(r => r.identifier === item.__typename)
      if (resource && resource.renderGridHeight) {
        return resource.renderGridHeight(itemWidth, { item })
      } else {
        return Grid.getGridItemHeight(itemWidth)
      }
    },
    [itemHeightKey],
  )
  return (
    <>
      {linkHandler}
      <Grid.Grid<SearchResultNode>
        items={items}
        itemHeight={itemHeight}
        onClick={async item => {
          const resource = resources.find(r => r.identifier === item.__typename)
          if (resource && resource.getUniversalLink) {
            const link = resource.getUniversalLink(item)
            if (link) {
              await navigate(link)
            }
          } else {
            warn('getClickDestination() is not defined for %s', item.__typename)
          }
        }}
        renderItem={SearchResultGridItemRenderer}
        onEndReached={loadMore}
        empty={empty ? empty : <Empty description={'No results found!'} />}
        {...rest}
      />
    </>
  )
}

export function SearchResultGridItemRenderer({ item }: Grid.RenderGridItemProps<SearchResultNode>) {
  const Renderer = useSearchResultRenderer(
    'renderGrid',
    item.__typename,
  ) as RenderGrid<SearchResultNode>
  return Renderer ? <Renderer item={item} /> : null
}
