import { SubnodeOutlined, DeleteOutlined } from '@ant-design/icons'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled } from '@thesisedu/web'
import { Menu, Dropdown } from 'antd'
import { transparentize } from 'polished'
import React from 'react'
import { Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd'

import { DraggableOptionsDrawer } from './DraggableOptionsDrawer'
import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { DraggableEditContainer } from './styled'
import { Draggable as TDraggable } from './types'

export interface DraggableEditProps {
  draggable: TDraggable
  onChange: (draggable: TDraggable) => void
  onDelete: () => void
  onPair: () => void
  onClick?: () => void
  pairingMode?: {
    isPairing?: boolean
    isPaired?: boolean
  }
}
export function DraggableEdit({
  pairingMode,
  draggable,
  onChange,
  onDelete,
  onPair,
  onClick,
}: DraggableEditProps) {
  const { isPairing, isPaired } = pairingMode || {}
  const { width = 0, height = 0 } = useGraphicDragDropContext(true)
  const { filename } = getFileInfo(draggable.imagePath || '')
  const [ratio, setRatio] = React.useState<number | undefined>(undefined)
  const [settingsVisible, setSettingsVisible] = React.useState(false)
  const overlay = (
    <Menu>
      <Menu.Item
        onClick={() => {
          onPair()
        }}
      >
        <SubnodeOutlined />
        <span>Pair</span>
      </Menu.Item>
      <Menu.Item
        danger
        onClick={() => {
          onDelete()
        }}
      >
        <DeleteOutlined />
        <span>Delete</span>
      </Menu.Item>
    </Menu>
  )
  return (
    <>
      <DraggableOptionsDrawer
        draggable={draggable}
        onChange={onChange}
        visible={settingsVisible}
        onClose={() => {
          setSettingsVisible(false)
        }}
      />
      <DraggableEditContainer
        as={Rnd}
        className={[
          isPairing ? 'pairing' : '',
          pairingMode && !isPaired ? 'not-paired' : '',
          pairingMode ? 'pairing-mode' : '',
        ].join(' ')}
        onClick={
          pairingMode
            ? () => {
                onPair()
              }
            : onClick
        }
        size={{ width: draggable.width * width, height: draggable.height * height }}
        position={{ x: draggable.x * width, y: draggable.y * height }}
        lockAspectRatio={ratio}
        onDragStop={
          ((event, d) => {
            onChange({
              ...draggable,
              x: d.x / width,
              y: d.y / height,
            })
          }) as RndDragCallback
        }
        onResizeStop={
          ((event, direction, ref, delta, position) => {
            onChange({
              ...draggable,
              width: parseFloat(ref.style.width) / width,
              height: parseFloat(ref.style.height) / height,
              x: position.x / width,
              y: position.y / height,
            })
          }) as RndResizeCallback
        }
      >
        {filename ? (
          <>
            <img
              src={filename}
              alt={draggable.title}
              onLoad={e => {
                setRatio(e.currentTarget.naturalWidth / e.currentTarget.naturalHeight)
              }}
            />
            <SettingsContainer>
              <Dropdown.Button
                overlay={overlay}
                children={'Edit'}
                onClick={() => {
                  setSettingsVisible(true)
                }}
              />
            </SettingsContainer>
          </>
        ) : (
          <ImagePlaceholder>
            <Dropdown.Button
              overlay={overlay}
              children={'Add Image'}
              size={'large'}
              onClick={() => {
                setSettingsVisible(true)
              }}
            />
          </ImagePlaceholder>
        )}
      </DraggableEditContainer>
    </>
  )
}

const ImagePlaceholder = styled.div`
  background: ${props => transparentize(0.5, props.theme['@gray-1'])};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`
const SettingsContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-xs']};
  right: ${props => props.theme['@size-xs']};
`
