import { $unwrapMarkNode } from '@lexical/mark'
import { $getAllMarkNodes } from '@thesisedu/feature-widgets-core'
import { Cancel, ColorPicker, TextAlt, TextBox } from '@thesisedu/ui/icons'
import { $getSelection, $isRangeSelection, LexicalEditor, RangeSelection } from 'lexical'
import React from 'react'

import { MarkToolbarItem } from './MarkToolbarItem'
import { BACKGROUND_MARKS, FOREGROUND_MARKS, OTHER_MARKS } from './marks'
import { TextFormatToolbarButton } from '../../ui/TextFormatToolbar/TextFormatToolbarButton'
import {
  TextFormatToolbarItemProps,
  useTextFormatToolbarItem,
} from '../../ui/TextFormatToolbar/TextFormatToolbarContext'

function getActive(classes: string[]) {
  return (editor: LexicalEditor, selection: RangeSelection) => {
    const markNodes = $getAllMarkNodes(selection)
    const allClasses = new Set<string>()
    for (const node of markNodes) {
      for (const cls of node.getClasses()) {
        allClasses.add(cls)
      }
    }

    return [...allClasses].filter(cls => classes.includes(cls))
  }
}

function ForegroundMarkElement({ active, editor }: TextFormatToolbarItemProps<string[]>) {
  return (
    <MarkToolbarItem
      activeIds={active}
      editor={editor}
      icon={<ColorPicker />}
      label={'Text Color'}
      markTypes={FOREGROUND_MARKS}
    />
  )
}
function BackgroundMarkElement({ active, editor }: TextFormatToolbarItemProps<string[]>) {
  return (
    <MarkToolbarItem
      activeIds={active}
      editor={editor}
      icon={<TextBox />}
      label={'Background'}
      markTypes={BACKGROUND_MARKS}
    />
  )
}
function OtherMarkElement({ active, editor }: TextFormatToolbarItemProps<string[]>) {
  return (
    <MarkToolbarItem
      activeIds={active}
      editor={editor}
      icon={<TextAlt />}
      label={'Special'}
      markTypes={OTHER_MARKS}
    />
  )
}
function ClearMarksElement({ editor }: TextFormatToolbarItemProps<string[]>) {
  return (
    <TextFormatToolbarButton
      icon={<Cancel />}
      label={'Clear Marks'}
      ariaLabel={'Clear Marks'}
      onClick={() => {
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const markNodes = $getAllMarkNodes(selection)
            for (const node of markNodes) {
              for (const cls of node.getClasses()) {
                node.deleteClass(cls)
              }
              if (node.getIDs().length === 0) {
                $unwrapMarkNode(node)
              }
            }
          }
        })
      }}
    />
  )
}

export function MarkPlugin() {
  useTextFormatToolbarItem({
    identifier: 'foreground-mark',
    weight: 10,
    group: 'marks',
    getActive: getActive(FOREGROUND_MARKS.map(mk => mk.identifier)),
    element: ForegroundMarkElement,
  })
  useTextFormatToolbarItem({
    identifier: 'background-mark',
    weight: 11,
    group: 'marks',
    getActive: getActive(BACKGROUND_MARKS.map(mk => mk.identifier)),
    element: BackgroundMarkElement,
  })
  useTextFormatToolbarItem({
    identifier: 'other-mark',
    weight: 12,
    group: 'marks',
    getActive: getActive(OTHER_MARKS.map(mk => mk.identifier)),
    element: OtherMarkElement,
  })
  useTextFormatToolbarItem({
    identifier: 'clear-marks',
    weight: 13,
    group: 'marks',
    getActive: () => false,
    element: ClearMarksElement,
  })

  return null
}
