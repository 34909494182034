import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import {
  AntIconWrapper,
  Block,
  Body,
  BodyLarge,
  BodySmall,
  DATE_FORMATS,
  HSpaced,
  Reset,
  Space,
  styled,
} from '@thesisedu/web'
import { Empty, Input } from 'antd'
import { Search } from 'iconoir-react'
import moment from 'moment'
import React from 'react'

import {
  BasicWidgetTemplateFragment,
  WidgetTemplatesDocument,
  WidgetTemplatesQuery,
  WidgetTemplatesQueryVariables,
} from '../schema'

export interface WidgetTemplateSelectProps {
  onSelected: (widgetTemplate: BasicWidgetTemplateFragment) => Promise<void> | void
}
export function WidgetTemplateSelect({ onSelected }: WidgetTemplateSelectProps) {
  const [name, setName] = React.useState('')
  const [dName, setDName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const timeout = React.useRef<any>()
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <>
      <Block marginBottom={'@size-m'}>
        <Input
          size={'large'}
          suffix={<AntIconWrapper children={<Search />} />}
          placeholder={'Find a template...'}
          value={name}
          style={{ width: '100%' }}
          onChange={e => {
            setName(e.target.value)
            clearTimeout(timeout.current)
            timeout.current = setTimeout(() => {
              setDName(e.target.value)
            }, 500)
          }}
        />
      </Block>
      <Container ref={containerRef}>
        <InfiniteQuery<
          BasicWidgetTemplateFragment,
          WidgetTemplatesQuery,
          WidgetTemplatesQueryVariables
        >
          document={WidgetTemplatesDocument}
          queryOpts={{
            fetchPolicy: 'cache-and-network',
          }}
          resultPath={'widgetTemplates'}
          variables={
            dName
              ? {
                  name: dName,
                }
              : {}
          }
          infiniteScrollerProps={{
            scrollableTarget: () => containerRef.current || document.body,
          }}
          children={({ data }) => {
            const templates = data?.widgetTemplates.edges.map(edge => edge.node) || []
            if (templates.length) {
              return (
                <div>
                  {templates.map(template => (
                    <Block marginBottom={'@size-s'} key={template.id}>
                      <HSpaced>
                        <div>
                          <BodyLarge>{template.name}</BodyLarge>
                          <BodySmall color={'@text-color-secondary'}>
                            Created {moment(template.createdAt).format(DATE_FORMATS.FULL)}
                          </BodySmall>
                        </div>
                        <Space />
                        <Reset.Button
                          onClick={async e => {
                            e.preventDefault()
                            setLoading(true)
                            try {
                              await onSelected(template)
                            } finally {
                              setLoading(false)
                            }
                          }}
                          disabled={loading}
                        >
                          <Body useDiv color={loading ? '@text-color-secondary' : '@primary-color'}>
                            {loading ? 'Adding...' : 'Select'}
                          </Body>
                        </Reset.Button>
                      </HSpaced>
                    </Block>
                  ))}
                </div>
              )
            } else {
              return <Empty description={'No templates found!'} />
            }
          }}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: ${props => props.theme['@size-m']};
`
