import { PageHead } from '@thesisedu/react'
import { PageHeader } from '@thesisedu/web'
import React from 'react'

export function AdminDashboard() {
  return (
    <>
      <PageHead title={'Dashboard'} />
      <PageHeader title={'Dashboard'} />
      <p>Dashboard!</p>
    </>
  )
}
