import { Theme } from '@nivo/core'

export function getColors(theme: Record<string, any>, loading?: boolean) {
  if (loading) {
    return [theme['@gray-1'], theme['@gray-2']]
  } else return COLORS
}
export const COLORS = [
  '#3B79E2',
  '#5199EC',
  '#6AB7F4',
  '#93D7F9',
  '#BEF8FE',
  '#C4E8FF',
  '#C5D1FF',
  '#AEB6FC',
  '#8591F7',
]

export function getNivoTheme(theme: Record<string, any>): Theme {
  return {
    background: 'transparent',
    textColor: theme['@text-color'],
    fontSize: 12,
    fontFamily: theme['@font-family'],
    axis: {
      domain: {
        line: {
          stroke: theme['@gray-4'],
          strokeWidth: 1,
        },
      },
      legend: {
        text: {
          fontSize: 12,
          fill: theme['@text-color'],
        },
      },
      ticks: {
        line: {
          stroke: theme['@gray-4'],
          strokeWidth: 1,
        },
        text: {
          fontSize: 12,
          fill: theme['@text-color'],
        },
      },
    },
    grid: {
      line: {
        stroke: theme['@gray-2'],
        strokeWidth: 1,
      },
    },
    tooltip: {
      container: {
        background: theme['@gray-0'],
      },
      basic: {},
      chip: {},
      table: {},
      tableCell: {},
      tableCellValue: {},
    },
  }
}
