import { MutateCourseViewerContext } from '@thesisedu/feature-courses-react'
import React from 'react'

import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'
import { CourseViewerGradingProvider } from '../segments/course_viewer/CourseViewerGradingProvider'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<MutateCourseViewerContext>(
    'courses-react:course-viewer-context',
    (children, { segment }) => {
      return <CourseViewerGradingProvider children={children} segmentId={segment?.id} />
    },
  )
}
