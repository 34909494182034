import { Block, PageHead, useNavigate } from '@thesisedu/react'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'

import { ManageStandardSets } from '../../admin/ManageStandardSets'

export function StandardSets() {
  useBreadcrumb({ title: 'Standard Sets' })
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Standard Sets'} />
      <PageHeader title={'Standard Sets'} onBack={() => navigate('..')} />
      <Block marginTop={'@size-m'}>
        <ManageStandardSets />
      </Block>
    </>
  )
}
