import { useDevToolsTabs } from '@thesisedu/feature-react/devtools'
import { BorderBottom, Maximize } from '@thesisedu/react/icons'
import { PopoutModal, Space, styled, ZIndex } from '@thesisedu/web'
import { ViewMode as PopoutViewMode } from '@thesisedu/web/dist/PopoutModal'
import { ConfigProvider } from 'antd'
import { Cancel, MultiWindow, Settings } from 'iconoir-react'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { createGlobalStyle } from 'styled-components'

import { ViewMode, DevToolsSettings, useDevToolsSettings } from './DevToolsSettings'

export function DevTools() {
  const [visible, setVisible] = React.useState(false)
  useHotkeys('`', () => {
    setVisible(v => !v)
  })
  const { CurrentTab, activeKey, setActiveKey, tabs } = useDevToolsTabs()
  const [showingSettings, setShowingSettings] = React.useState(false)
  const [settings] = useDevToolsSettings()
  const [viewMode, setViewMode] = React.useState<ViewMode>(
    settings?.defaultViewMode || ViewMode.Inline,
  )
  const poppedOut = visible && viewMode === ViewMode.PoppedOut
  const containerRef = React.useRef<HTMLDivElement>(null)
  const content = (
    <>
      <TabContainer>
        <LabelContainer>Feature DevTools</LabelContainer>
        {tabs
          .filter(tab => !tab.disabled)
          .map(tab => (
            <Tab
              key={tab.identifier}
              className={[
                activeKey === tab.identifier && !showingSettings ? 'active' : '',
                tab.disabled ? 'disabled' : '',
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={() => {
                setShowingSettings(false)
                setActiveKey(tab.identifier)
              }}
            >
              {tab.title}
            </Tab>
          ))}
        <Space />
        {poppedOut ? null : (
          <Tab onClick={() => setViewMode(ViewMode.PoppedOut)}>
            <MultiWindow />
          </Tab>
        )}
        {viewMode === ViewMode.FullScreen ? (
          <Tab onClick={() => setViewMode(ViewMode.Inline)}>
            <BorderBottom />
          </Tab>
        ) : viewMode === ViewMode.Inline ? (
          <Tab onClick={() => setViewMode(ViewMode.FullScreen)}>
            <Maximize />
          </Tab>
        ) : null}
        <Tab onClick={() => setShowingSettings(true)} className={showingSettings ? 'active' : ''}>
          <Settings />
        </Tab>
        <Tab
          onClick={() => {
            setVisible(false)
          }}
        >
          <Cancel />
        </Tab>
      </TabContainer>
      <ContentContainer noPadding={tabs.find(t => t.identifier === activeKey)?.noPadding}>
        {showingSettings ? <DevToolsSettings /> : CurrentTab ? <CurrentTab /> : null}
      </ContentContainer>
      {poppedOut ? null : <GlobalStyles />}
    </>
  )
  return poppedOut ? (
    <PopoutModal
      noPadding
      children={
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            height: '100vh',
          }}
        >
          {content}
        </div>
      }
      defaultViewMode={PopoutViewMode.Window}
      visible
      onCancel={() => {
        setVisible(false)
      }}
      defaultWidth={900}
      defaultHeight={400}
      title={'Feature DevTools'}
    />
  ) : visible ? (
    <Container ref={containerRef} fullscreen={viewMode === ViewMode.FullScreen}>
      <ConfigProvider
        getTargetContainer={() => containerRef.current || document.body}
        getPopupContainer={() => containerRef.current || document.body}
        componentSize={'small'}
        children={content}
      />
    </Container>
  ) : null
}

const GlobalStyles = createGlobalStyle`
  #root {
    padding-bottom: 300px;
  }
`
const TabContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
  background: ${props => props.theme['@gray-1']};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  user-select: none;
`
const ContentContainer = styled.div<{ noPadding?: boolean }>`
  padding: ${props => (props.noPadding ? 0 : props.theme['@size-xs'])};
  flex: 1;
  overflow-y: auto;

  table {
    width: 100%;
    border: solid 1px ${props => props.theme['@border-color-base']};
    tr td,
    tr th {
      border-bottom: solid 1px ${props => props.theme['@border-color-split']};
      padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
      font-size: 12px;
      letter-spacing: 0;
    }
    td:not(:last-child) {
      border-right: solid 1px ${props => props.theme['@border-color-split']};
    }
    th {
      background: ${props => props.theme['@gray-1']};
      text-align: left;
      &:not(:last-child) {
        border-right: solid 1px ${props => props.theme['@border-color-base']};
      }
    }
    thead th {
      border-bottom: solid 1px ${props => props.theme['@border-color-base']};
    }
  }
`
const LabelContainer = styled.div`
  pointer-events: none;
  padding: 0 ${props => props.theme['@size-xs']};
  font-size: ${props => props.theme['@font-size-sm']};
  height: ${props => props.theme['@size-l']};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  transition:
    background 0.1s linear,
    color 0.1s linear;
  color: ${props => props.theme['@gray-6']};
  background: transparent;
  font-weight: bold;
`
const Tab = styled(LabelContainer)`
  font-weight: normal;
  cursor: pointer;
  pointer-events: all;
  &:hover,
  &.active {
    background: ${props => props.theme['@gray-2']};
    color: ${props => props.theme['@gray-7']};
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`
const Container = styled.div<{ fullscreen?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${props => (props.fullscreen ? '100vh' : '300px')};
  background: ${props => props.theme['@gray-0']};
  border-top: solid 1px ${props => props.theme['@border-color-base']};
  z-index: ${ZIndex.DevTools};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
