import React from 'react'

export interface UsePlaceholderOpts {
  placeholder: React.ReactElement
  getChildren: () => React.ReactElement
}
export function usePlaceholder({ placeholder, getChildren }: UsePlaceholderOpts) {
  const isPlaceholderRef = React.useRef(true)
  const timeoutRef = React.useRef<any>()
  const [contents, setContents] = React.useState(placeholder)
  React.useEffect(() => {
    clearTimeout(timeoutRef.current)
    if (isPlaceholderRef.current) {
      timeoutRef.current = setTimeout(() => {
        setContents(getChildren())
      })
    } else {
      setContents(getChildren())
    }
  }, [getChildren])

  return contents
}
