import { InfiniteQuery, InfiniteQueryProps } from '@thesisedu/feature-apollo-react'
import { DeepLink } from '@thesisedu/feature-web/dist/web'
import {
  Body,
  FontWeight,
  BodyLarge,
  HSpaced,
  styled,
  Space,
  BodySmall,
  Block,
  Markdown as ReactMarkdown,
} from '@thesisedu/web'
import { Empty } from 'antd'
import moment from 'moment'
import React from 'react'

import { MarkNotificationRead } from './MarkNotificationRead'
import {
  NotificationFragment,
  NotificationsQuery,
  NotificationsQueryVariables,
  NotificationsDocument,
} from './schema'

export interface UserNotificationsProps
  extends Partial<
    InfiniteQueryProps<NotificationFragment, NotificationsQuery, NotificationsQueryVariables>
  > {
  onClick?: () => void
}
export function UserNotifications({ onClick, ...props }: UserNotificationsProps) {
  return (
    <InfiniteQuery<NotificationFragment, NotificationsQuery, NotificationsQueryVariables>
      {...props}
      document={NotificationsDocument}
      resultPath={'viewer.notifications'}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      children={({ data }) => {
        const notifications = data?.viewer?.notifications.edges.map(edge => edge.node)
        if (notifications?.length) {
          return (
            <div>
              {notifications.map(node => (
                <Notification key={node.id} notification={node} onClick={onClick} />
              ))}
            </div>
          )
        } else {
          return (
            <Block marginTop={'@size-m'} marginBottom={'@size-m'}>
              <Empty description={'No notifications!'} />
            </Block>
          )
        }
      }}
    />
  )
}

export interface NotificationProps {
  notification: NotificationFragment
  onClick?: () => void
}
export function Notification({ onClick, notification }: NotificationProps) {
  const title = notification.link ? (
    <DeepLink universalPath={notification.link.universalPath} onClick={onClick}>
      {notification.title}
    </DeepLink>
  ) : (
    notification.title
  )

  return (
    <NotificationContainer className={notification.isRead ? '' : 'unread'}>
      <BodyLarge weight={notification.isRead ? undefined : FontWeight.SemiBold}>{title}</BodyLarge>
      <ReactMarkdown children={notification.description} skipHtml />
      <HSpaced>
        <div>
          {notification.link ? (
            <DeepLink universalPath={notification.link.universalPath} onClick={onClick}>
              <Body>{notification.link.title} &rarr;</Body>
            </DeepLink>
          ) : null}
          <BodySmall color={'@text-color-secondary'}>
            {moment(notification.createdAt).fromNow()}
          </BodySmall>
        </div>
        <Space />
        <div className={'actions'}>
          <MarkNotificationRead notification={notification} />
        </div>
      </HSpaced>
    </NotificationContainer>
  )
}

const NotificationContainer = styled.div`
  padding: ${props => props.theme['@size-s']} ${props => props.theme['@size-s']};
  border-left: solid 3px transparent;
  transition: border 0.25s linear;
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  }
  &.unread {
    border-left-color: ${props => props.theme['@primary-color']};
  }
  .actions {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s linear;
  }
  &:hover .actions {
    opacity: 1;
    pointer-events: all;
  }
`
