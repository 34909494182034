import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'

export const LargeBox: any = styled.div`
  background: ${props => props.theme['@gray-0']};
  box-shadow: ${props => props.theme['@shadow-large']};
  border-radius: ${props => props.theme['@border-radius-large']};
`
export const LargeBoxButton: any = styled(LargeBox)`
  transition:
    box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scale(1);
  &:hover:not(.disabled) {
    transform: scale(1.02);
    box-shadow: ${props => props.theme['@shadow-large-hover']};
  }
  &:active:not(.disabled) {
    transform: scale(0.98);
    box-shadow: ${props => props.theme['@shadow-large-pressed']};
  }
`
export const LargeDarkBox: any = styled.div`
  background: ${props => props.theme['@gray-6']};
  box-shadow: 0 4px 20px 0 rgba(25, 34, 63, 0.75);
  border-radius: ${props => props.theme['@border-radius-large']};
`
export const LargeDarkTransparentBox: any = styled.div`
  background: ${props => transparentize(0.25, props.theme['@gray-6'])};
  box-shadow: 0 4px 20px 0 rgba(25, 34, 63, 0.75);
  border-radius: ${props => props.theme['@border-radius-large']};
  backdrop-filter: blur(5px);
  overflow: hidden;
`
export const LargeDarkTransparentButtonBox: any = styled(LargeDarkTransparentBox)`
  transition:
    box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scale(1);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 25px 0 rgba(25, 34, 63, 0.95);
  }
  &:active {
    transform: scale(0.98);
    box-shadow: 0 2px 10px 0 rgba(25, 34, 63, 0.75);
  }
`
export const ModalMask: any = styled.div`
  background: ${props => transparentize(0.5, props.theme['@gray-6'])};
  backdrop-filter: blur(5px);
`
export const VideoMask: any = styled.div`
  background-image: linear-gradient(180deg, rgba(25, 34, 63, 0.25) 44%, rgba(25, 34, 63, 0.8) 100%);
`
export const VideoMaskDark: any = styled.div`
  background-image: linear-gradient(180deg, rgba(25, 34, 63, 0.85) 11%, #19223f 100%);
`
