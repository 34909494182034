import ImpersonateStudent from './ImpersonateStudent'
import InviteStudent from './InviteStudent'
import MoveStudent from './MoveStudent'
import RemoveStudent from './RemoveStudent'
import RenameStudent from './RenameStudent'
import ClassesReactFeature from '../../ClassesReactFeature'

export default function (feature: ClassesReactFeature) {
  feature.resources.addResource(MoveStudent)
  feature.resources.addResource(RemoveStudent)
  feature.resources.addResource(InviteStudent)
  feature.resources.addResource(ImpersonateStudent)
  feature.resources.addResource(RenameStudent)
}
