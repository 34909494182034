import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React from 'react'

import { LessonsList, LessonsListProps } from '../LessonsList'
import {
  BasicLessonFragment,
  StudentLessonsDocument,
  StudentLessonsQuery,
  StudentLessonsQueryVariables,
} from '../schema'

export const StudentLessonsList: React.FC<
  React.PropsWithChildren<Partial<LessonsListProps>>
> = props => {
  const selectedClass = useSelectedClassId()
  return (
    <InfiniteQuery<BasicLessonFragment, StudentLessonsQuery, StudentLessonsQueryVariables>
      document={StudentLessonsDocument}
      getResult={d => d?.viewer?.student?.lessons}
      setResult={(previous, result) => ({
        ...previous,
        viewer: {
          ...previous.viewer!,
          student: {
            ...previous.viewer!.student!,
            lessons: {
              ...previous.viewer!.student!.lessons,
              ...result,
            },
          },
        },
      })}
      variables={{ classId: selectedClass }}
      children={({ data, refetch }) => (
        <LessonsList
          lessons={data?.viewer?.student?.lessons.edges.map(edge => edge.node) || []}
          refetch={after => refetch({ classId: selectedClass, after })}
          {...props}
        />
      )}
    />
  )
}
