import moment from 'moment'

export const formatRelativeWeek = (week: string): string => {
  const m = moment(week, 'YYYY-ww').startOf('week')
  const lastWeek = moment().subtract(1, 'week')
  if (m.isSame(moment(), 'week') && m.isSame(moment(), 'year')) {
    return 'This Week'
  } else if (m.isSame(lastWeek, 'week') && m.isSame(lastWeek, 'year')) {
    return 'Last Week'
  } else if (m.isSame(moment(), 'year')) {
    return `${m.format('MMMM D')} - ${m.clone().endOf('week').format('MMMM D')}`
  } else {
    return `${m.format('MMMM D, YYYY')} - ${m.clone().endOf('week').format('MMMM D, YYYY')}`
  }
}
