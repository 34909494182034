import { transparentize } from 'polished'
import { createGlobalStyle } from 'styled-components'

export const RichText = createGlobalStyle`
  :root {
    --highlight-orange: ${props => (props.theme as any)['@orange']};
    --highlight-red: ${props => (props.theme as any)['@red']};
    --highlight-green: ${props => (props.theme as any)['@green']};
    --highlight-blue: ${props => (props.theme as any)['@blue']};
  }

  .marker-orange { background: ${props =>
    transparentize(0.7, (props.theme as any)['@orange'])} !important; }
  .marker-red { background: ${props =>
    transparentize(0.7, (props.theme as any)['@red'])} !important; }
  .marker-green { background: ${props =>
    transparentize(0.7, (props.theme as any)['@green'])} !important; }
  .marker-blue { background: ${props =>
    transparentize(0.7, (props.theme as any)['@blue'])} !important; }
  .pen-orange { color: var(--highlight-orange) !important; }
  .pen-red { color: var(--highlight-red) !important; }
  .pen-blue { color: var(--highlight-blue) !important; }
  .pen-green { color: var(--highlight-green) !important; }
  mark {
    background: none;
  }
`
