import { FeatureReactRoot } from '@thesisedu/feature-react'
import { DevToolsTabResource, useTabData } from '@thesisedu/feature-react/devtools'
import React from 'react'

import { ViewAllSyncHistory } from './ViewAllSyncHistory'

export interface ClassSyncViewItemOpts {
  classSyncIds: string[]
  syncIds: string[]
}

export function SyncHistoryTab() {
  const opts = useTabData<ClassSyncViewItemOpts>('syncHistory')
  return opts?.classSyncIds?.length || opts?.syncIds?.length ? (
    <ViewAllSyncHistory {...opts} />
  ) : null
}

export function install(root: FeatureReactRoot<any>) {
  root.deps.resources.addResource<DevToolsTabResource>({
    type: 'DevToolsTab',
    identifier: 'syncHistory',
    title: 'Sync History',
    component: SyncHistoryTab,
    disableDefault: true,
    noPadding: true,
  })
}
