import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
} from '@thesisedu/feature-classes-react'
import { BookmarkBook } from '@thesisedu/react/icons'
import { Form, Checkbox } from 'antd'
import React from 'react'

export const DISABLE_VIDEO_SEEK_LIMITATION = 'disableVideoSeekLimitation'

function Field({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, DISABLE_VIDEO_SEEK_LIMITATION]}
      valuePropName={'checked'}
      extra={
        'When this is enabled, students will be allowed to skip through videos, even if they have ' +
        'not watched it in full at least once.'
      }
    >
      <Checkbox>Allow students to seek without having to watch the entire video first.</Checkbox>
    </Form.Item>
  )
}

const config: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: DISABLE_VIDEO_SEEK_LIMITATION,
  group: 'Courses',
  groupIcon: <BookmarkBook />,
  weight: 1,
  renderField: Field,
}
export default config
