import { useViewerContext } from '@thesisedu/feature-users-web'

import { useSelectedClassId } from './ClassContext'
import {
  ClassFragment,
  StudentClassFragment,
  useStudentClassQuery,
  useTeacherClassQuery,
} from './schema'

export interface UseFullClassResult<Fragment> {
  cls: Fragment | null
  loading: boolean
  error: boolean
}
export function useTeacherClass<F extends ClassFragment = ClassFragment>(
  classId: string | null,
  require: boolean,
): UseFullClassResult<F> {
  const { data, loading, error } = useTeacherClassQuery({
    variables: {
      id: classId || '',
    },
    skip: !classId,
  })

  if (!classId) {
    return {
      cls: null,
      loading: false,
      error: require,
    }
  }

  const cls = data?.node?.__typename === 'Class' ? data.node : null
  return {
    cls: cls as F,
    loading,
    error: error ? true : !cls && require && !loading,
  }
}

export function useTeacherSelectedClass<F extends ClassFragment = ClassFragment>(require: boolean) {
  const selectedClassId = useSelectedClassId(require)
  return useTeacherClass<F>(selectedClassId, require)
}

export function useStudentClass<F extends StudentClassFragment = StudentClassFragment>(
  classId: string | null,
  require: boolean,
): UseFullClassResult<F> {
  const { data, loading, error } = useStudentClassQuery({
    variables: {
      id: classId || '',
    },
    skip: !classId,
  })

  if (!classId) {
    return {
      cls: null,
      loading: false,
      error: require,
    }
  }

  const cls = data?.node?.__typename === 'Class' ? data.node : null
  return {
    cls: cls as F,
    loading,
    error: error ? true : !cls && require && !loading,
  }
}
export function useStudentSelectedClass(require: boolean) {
  const selectedClassId = useSelectedClassId()
  return useStudentClass(selectedClassId, require)
}

export function useTeacherOrStudentClass<
  F extends ClassFragment | StudentClassFragment = ClassFragment | StudentClassFragment,
>(classId: string | null, require: boolean): UseFullClassResult<F> {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'TEACHER') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useTeacherClass(classId, require) as UseFullClassResult<F>
  } else if (viewer?.group === 'STUDENT') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useStudentClass(classId, require) as UseFullClassResult<F>
  } else {
    return {
      cls: null,
      error: true,
      loading: false,
    }
  }
}
export function useTeacherOrStudentSelectedClass<
  F extends ClassFragment | StudentClassFragment = ClassFragment | StudentClassFragment,
>(require: boolean) {
  const selectedClassId = useSelectedClassId(require)
  return useTeacherOrStudentClass<F>(selectedClassId, require)
}
