import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  ClassFragment,
  ClassFragmentDoc,
  LicenseStatusDocument,
  RemoveStudentFromClassMutation,
  useRemoveStudentFromClassMutation as useMutation,
} from '../schema'

export const useRemoveStudentFromClassMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error removing the student from the class.'),
    update: updateMutation<RemoveStudentFromClassMutation, ClassFragment>({
      path: 'removeStudentFromClass.class',
      fragment: ClassFragmentDoc,
      fragmentName: 'Class',
    }),
    refetchQueries: [{ query: LicenseStatusDocument }],
    ...opts,
  })
}
