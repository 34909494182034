import { RequireFields } from '@thesisedu/feature-types'
import { Format } from '@thesisedu/feature-utils'
import {
  Body,
  BodyExtraSmall,
  Color,
  DATE_FORMATS,
  HSpaced,
  ModalNavigationItem,
  Space,
} from '@thesisedu/react'
import { Star } from '@thesisedu/react/icons'
import moment from 'moment'
import React from 'react'

import { getStudentClassTabName } from './helpers'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { BasicAssignmentSubmissionFragment } from '../schema'

export interface GradingModalSidebarAttemptsListItemProps {
  submission: RequireFields<BasicAssignmentSubmissionFragment, 'submittedAt'>
  index: number
}
export function GradingModalSidebarAttemptsListItem({
  submission,
  index,
}: GradingModalSidebarAttemptsListItemProps) {
  const { selectedTab, setSelectedTab } = useGradingModalContext(true)
  const tabName = getStudentClassTabName(
    submission.studentId,
    submission.classId,
    submission.deletedAt ? submission.id : undefined,
  )
  return (
    <ModalNavigationItem
      onClick={() => {
        setSelectedTab(tabName)
      }}
      selected={selectedTab === tabName}
    >
      <HSpaced align={'center'} style={{ width: '100%' }}>
        <div>
          <Body>
            <span>Attempt {index}</span>
            {submission.deletedAt ? null : (
              <Color color={'@orange'}>
                &nbsp;
                <Star style={{ fill: 'currentColor' }} />
              </Color>
            )}
          </Body>
          <BodyExtraSmall color={submission.isLate ? '@red' : '@text-color-secondary'}>
            {submission.isLate ? <strong>Late&nbsp;&nbsp;</strong> : null}
            {moment(submission.submittedAt).format(DATE_FORMATS.TEXT)}
          </BodyExtraSmall>
        </div>
        <Space />
        {submission.grade !== null && submission.grade !== undefined ? (
          <Body color={'@text-color-secondary'}>
            {Format.number(submission.grade, 'decimalPercentage')}
          </Body>
        ) : null}
      </HSpaced>
    </ModalNavigationItem>
  )
}
