import { ProviderAuthContext, AuthenticateResult } from '@thesisedu/feature-users-react'
import React from 'react'

import { SiteClassSyncProviderFragment } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, StepSizes, TransitionOpts, getStepFactory } from '../types'

export interface AuthenticateWithProviderContext {
  provider: SiteClassSyncProviderFragment
  nextStep: TransitionOpts
}

export default {
  animationGroup: 'loading',
  size: StepSizes.loading,
  hideClose: true,
  Component({ transition, context }) {
    const { authenticate } = React.useContext(ProviderAuthContext)
    React.useEffect(() => {
      const handle = setTimeout(async () => {
        if (context.provider.authProviderId) {
          const result = await authenticate(context.provider.authProviderId, {
            headless: true,
          })
          if (result === AuthenticateResult.Successful) {
            transition(context.nextStep)
          } else {
            // Cancel the process.
            transition(null)
          }
        }
      }, 500)
      return () => clearTimeout(handle)
    }, [])
    return (
      <LoadingStep
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        direction={'right'}
        title={`Logging in with ${context.provider.name}`}
      />
    )
  },
} satisfies SetupStep<AuthenticateWithProviderContext>
export const getAuthenticateWithProvider = getStepFactory<AuthenticateWithProviderContext>(
  'authenticateWithProvider',
)
