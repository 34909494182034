import { Typography } from 'antd'
import React from 'react'

export interface EditableTextProps {
  children: string
  style?: any
  onChange: (text: string) => void
}
export function EditableText({ children, style, onChange }: EditableTextProps) {
  return <Typography.Paragraph children={children} style={style} editable={{ onChange }} />
}
