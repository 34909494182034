import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'
import { Routes as RouterRoutes, Route, useLocation } from 'react-router-dom'

import { NotFoundPage } from '../NotFoundPage'
import { WebRoutesHook } from '../types'

export function Routes() {
  const location = useLocation()
  const oldPathnameRef = React.useRef<string>()
  const routes = useMutateHook<WebRoutesHook>('feature-web:routes', [], undefined)
  window.history.scrollRestoration = 'manual'
  React.useEffect(() => {
    // Only scroll when the pathname changes, not the query string.
    if (location.pathname !== oldPathnameRef.current) {
      window.scrollTo(0, 0)
    }
    oldPathnameRef.current = location.pathname
  }, [location])

  return (
    <RouterRoutes>
      {routes}
      <Route path={'*'} element={<NotFoundPage />} />
    </RouterRoutes>
  )
}
