import { LockOutlined } from '@ant-design/icons'
import { BodyLarge } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

export function SegmentLocked() {
  return (
    <Result title={'This content is locked.'} icon={<LockOutlined />}>
      <BodyLarge>
        Your teacher has locked this content for viewing. If you would like to view this content or
        work on this assignment, please let your teacher know so they can unlock it.
      </BodyLarge>
    </Result>
  )
}
