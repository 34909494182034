import { PageHead, PageHeader } from '@thesisedu/web'
import { Form, Button, message } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { BadgeForm } from './BadgeForm'
import { useCreateBadgeMutation } from '../schema'

export function CreateBadgePage() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [createBadge, { loading }] = useCreateBadgeMutation({
    onCompleted: data => {
      message.success('Badge created!')
      navigate(`../${data.createBadge.badge.id}`)
    },
  })
  return (
    <>
      <PageHead title={'Create Badge'} />
      <PageHeader title={'Create Badge'} onBack={() => navigate('..')}>
        <Button type={'primary'} onClick={() => form.submit()} loading={loading}>
          Create Badge
        </Button>
      </PageHeader>
      <BadgeForm
        form={form}
        onFinish={values => {
          createBadge({
            variables: {
              input: {
                eventConfig: {},
                ...values,
              },
            },
          })
        }}
      />
    </>
  )
}
