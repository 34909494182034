import React from 'react'

import { AssignmentSubmissionActionProps } from './AssignmentSubmissionActions'
import { ResetSubmissionsDropdownItems } from '../grading/ResetSubmissionsDropdownItems'

export function ResetAssignmentSubmissionAction({
  assignment,
  studentId,
  submission,
}: AssignmentSubmissionActionProps) {
  return (
    <ResetSubmissionsDropdownItems
      assignment={assignment}
      studentId={studentId}
      assignmentId={assignment.id}
      classId={submission?.classId}
    />
  )
}
