import { QuestionOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { DATE_FORMATS } from '@thesisedu/web'
import { Result } from 'antd'
import moment from 'moment'
import React from 'react'

export const SegmentNotFoundMessage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Result icon={<QuestionOutlined size={64} />} title={'Segment not found'}>
    We couldn't find that segment anywhere. Please try selecting a different one from the list at
    the left
  </Result>
)

export interface SegmentNotEnabledMessageProps {
  scheduledAt?: string
}
export const SegmentNotEnabledMessage: React.FC<
  React.PropsWithChildren<SegmentNotEnabledMessageProps>
> = ({ scheduledAt }) => (
  <Result icon={<ClockCircleOutlined size={64} />} title={'Not yet!'}>
    You don't have access to this content yet.
    {scheduledAt
      ? ` Come back on ${moment(scheduledAt).format(DATE_FORMATS.FULL)} to view this content.`
      : ''}
  </Result>
)
