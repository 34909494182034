import { Shell } from '@thesisedu/feature-web'
import { BodySmall } from '@thesisedu/react'
import { AddUser, Settings, User } from '@thesisedu/react/icons'
import React from 'react'

import { JoinClassLeftNavItem } from './JoinClassLeftNavItem'
import { ClassesReactFeature } from '../ClassesReactFeature'
import { useOptions } from '../options'
import { ClassFragment } from '../schema'
import { ClassId } from '../teacher/ClassId'
import { InviteStudentModal } from '../teacher/invite/InviteStudentModal'
import { ClassLeftNavHook } from '../types'

interface InviteNavItemProps {
  cls: ClassFragment
  [key: string]: any
}
function InviteNavItem({ cls, ...rest }: InviteNavItemProps) {
  const [visible, setVisible] = React.useState(false)
  const { showClassCodeOnInviteButton } = useOptions()
  const hasStudents = !!(cls as any)?.students?.edges?.length
  return (
    <>
      <InviteStudentModal
        classId={visible ? cls.id : undefined}
        onClose={() => setVisible(false)}
      />
      <Shell.LeftMenuItem
        {...rest}
        name={showClassCodeOnInviteButton ? 'Invite' : 'Invite Students'}
        icon={<AddUser />}
        highlighted={!hasStudents}
        path={'students#invite'}
        stopRightContentClick
        rightContent={
          showClassCodeOnInviteButton ? (
            <ClassId noWrap code={cls.classId || undefined}>
              <BodySmall>{cls.classId}</BodySmall>
            </ClassId>
          ) : undefined
        }
        onClick={() => setVisible(true)}
      />
    </>
  )
}

interface StudentsNavItemProps {
  cls: ClassFragment
  [key: string]: any
}
function StudentsNavItem({ cls, ...rest }: StudentsNavItemProps) {
  const studentCount = (cls as any)?.students?.edges?.length || 0
  const hasStudents = !!studentCount
  return (
    <>
      <Shell.LeftMenuItem
        {...rest}
        name={'Students'}
        icon={<User />}
        path={'students'}
        highlighted={!hasStudents}
        rightContent={<BodySmall>{studentCount}</BodySmall>}
      />
    </>
  )
}

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { cls, group }) => {
      if (group === 'TEACHER') {
        return [
          ...items,
          {
            group: 'Students',
            weight: 10,
            element: <StudentsNavItem key={'students'} cls={cls} />,
          },
          ...(cls.canInvite
            ? [
                {
                  group: 'Students',
                  weight: 11,
                  element: <InviteNavItem cls={cls} key={'invite'} />,
                },
              ]
            : []),
          ...(cls.canUpdateSettings
            ? [
                {
                  group: 'bottom',
                  weight: 10000,
                  element: (
                    <Shell.LeftMenuItem
                      key={'settings'}
                      path={'settings'}
                      icon={<Settings />}
                      name={'Class Settings'}
                    />
                  ),
                },
              ]
            : []),
        ]
      } else if (group === 'STUDENT') {
        return [
          ...items,
          {
            group: 'bottom',
            weight: 10000,
            element: <JoinClassLeftNavItem key={'join-class'} />,
          },
        ]
      } else return items
    },
  )
}
