import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { HelpCircle } from '@thesisedu/react/icons'
import React from 'react'

import { QuestionElement } from './QuestionElement'
import { QuestionConfig } from './common'

export default {
  identifier: 'Question',
  title: 'Question',
  icon: <HelpCircle />,
  weight: Infinity,
  group: SimpleWidget.ElementsGroup.Other,
  defaultConfig: { version: 1 },
  hidden() {
    return true
  },
  wrapperClassName() {
    return 'assignment-question'
  },
  element: QuestionElement,
} satisfies SimpleWidget.SimpleWidgetDefinition<QuestionConfig, 'Question'>
