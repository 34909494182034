import { Form, Modal, Toast$, useToast, Button, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { VideoThumbnailField } from '.'
import { useUpdateVideoOnDemandMutation } from './queries/useUpdateVideoOnDemandMutation'
import { useVideoOnDemandLazyQuery } from './schema'

const LOAD_MESSAGE_TIMEOUT = 250
function useLazyLoadVod(vodId: string, visible: boolean) {
  const toast = useToast()
  const hideLoadingRef = React.useRef<Toast$.UpdateOrCloseToast | null>(null)
  const [loadVod, { data, loading }] = useVideoOnDemandLazyQuery({
    variables: {
      id: vodId,
    },
    fetchPolicy: 'cache-and-network',
    onError: () => {
      toast({ title: 'There was an error loading that video.', status: 'error' })
    },
  })
  const loadingTimeoutRef = React.useRef<any>()
  React.useEffect(() => {
    if (visible) {
      loadingTimeoutRef.current = setTimeout(() => {
        hideLoadingRef.current = toast({
          title: 'Loading video...',
          loading: true,
          timeout: null,
        })
      }, LOAD_MESSAGE_TIMEOUT)
      loadVod()
    }
  }, [visible])
  const vod = data?.node?.__typename === 'VideoOnDemand' ? data.node : undefined
  React.useEffect(() => {
    if (!loading || vod) {
      clearInterval(loadingTimeoutRef.current)
      if (hideLoadingRef.current) {
        hideLoadingRef.current()
        hideLoadingRef.current = null
      }
    }
  }, [loading, !!vod])
  return vod
}

export interface EditVideoModalProps {
  vodId: string
  visible?: boolean
  onCancel: () => void
}
export function EditVideoModal({ vodId, visible: _visible, onCancel }: EditVideoModalProps) {
  const [visible, setVisible] = React.useState(false)
  const toast = useToast()
  const [update, { loading }] = useUpdateVideoOnDemandMutation({
    onCompleted: () => {
      toast({ title: 'Changes saved!', status: 'success' })
      setVisible(false)
    },
  })
  const vod = useLazyLoadVod(vodId, !!_visible)
  const form = Form.useForm()
  React.useEffect(() => {
    if (!_visible) setVisible(false)
  }, [_visible])
  React.useEffect(() => {
    if (vod && _visible) {
      setVisible(true)
    }
  }, [vod, _visible])

  return (
    <Form.Modal
      form={form}
      title={'Edit Video'}
      visible={visible}
      onVisibleChange={v => {
        if (!v) onCancel()
      }}
      width={600}
      onFinish={values => {
        update({
          variables: {
            input: {
              id: vodId,
              patch: values,
            },
          },
        })
      }}
    >
      <VSpaced space={'m'}>
        <Form.Field name={'thumbnailData'} label={'Thumbnail'} initialValue={vod?.thumbnailData}>
          <VideoThumbnailField />
        </Form.Field>
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Save Changes'} loading={loading} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export interface EditVideoButtonProps {
  vodId: string
}
export function EditVideoButton({ vodId }: EditVideoButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <EditVideoModal vodId={vodId} visible={visible} onCancel={() => setVisible(false)} />
      <Button onPress={() => setVisible(true)}>Edit Video</Button>
    </>
  )
}

export function EditVideoLink({ vodId }: EditVideoButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <EditVideoModal vodId={vodId} visible={visible} onCancel={() => setVisible(false)} />
      <a
        onClick={e => {
          e.preventDefault()
          setVisible(true)
        }}
      >
        Edit Settings
      </a>
    </>
  )
}
