import { BlockEditor, EditorValue, PluginLevel } from '@thesisedu/feature-widgets-react'
import {
  AntIconWrapper,
  HSpaced,
  styledContainer,
  getColor,
  getSize,
  Button,
  ButtonProps,
  Input,
  Div,
  Block,
} from '@thesisedu/react'
import { ArrowRight } from '@thesisedu/react/icons'
import { TextAreaProps } from 'antd/es/input'
import React from 'react'

import { CustomComposeButtons } from './CustomComposeButtons'
import { useCommentOnNodeMutation } from './queries/useCommentOnNodeMutation'

const Footer = styledContainer`
  color: ${props => getColor(props.theme, '@text-color-secondary')};
  text-align: right;
  font-size: ${props => getSize(props.theme, 'size-s')};
  margin-top: ${props => getSize(props.theme, '@size-xxs')};
`
const StyledForm = styledContainer<{ submitUnderneath?: boolean }>`
  flex-direction: ${props => (props.submitUnderneath ? 'column' : 'row')};
  align-items: ${props => (props.submitUnderneath ? 'flex-start' : 'center')};
  display: flex;
  flex-shrink: 0;
  ${props =>
    props.submitUnderneath
      ? `
    > :last-child {
      margin-left: 0;
      margin-top: ${getSize(props.theme, '@size-xs')};
    }
  `
      : null}
`

export interface PostCommentProps extends Partial<TextAreaProps> {
  onPosted?: () => Promise<any>
  entityId: string
  metadata?: object
  footer?: React.ReactElement
  useRichText?: boolean
  placeholder?: string
  submitUnderneath?: boolean
  buttonProps?: Partial<ButtonProps>
}
export const PostComment: React.FC<React.PropsWithChildren<PostCommentProps>> = ({
  onPosted,
  entityId,
  metadata,
  footer,
  useRichText,
  placeholder,
  submitUnderneath,
  buttonProps,
  ...props
}) => {
  const [content, setContent] = React.useState<string | undefined>('')
  const [blockContent, setBlockContent] = React.useState<EditorValue | undefined>(undefined)
  const [defaultValueKey, setDefaultValueKey] = React.useState(Math.random().toString())
  const [comment, { loading }] = useCommentOnNodeMutation({
    onCompleted: () => {
      if (onPosted) {
        onPosted()
      }
    },
  })
  const onFinish = () => {
    if (content || blockContent) {
      comment({
        variables: {
          input: {
            id: entityId,
            comment: content || '',
            blockComment: blockContent,
            metadata,
          },
        },
      }).then(() => {
        setContent('')
        setBlockContent(undefined)
        setDefaultValueKey(Math.random().toString())
      })
    }
  }
  return (
    <StyledForm
      style={{ marginBottom: footer ? 24 : 0 }}
      submitUnderneath={submitUnderneath}
      data-testid={'PostComment'}
    >
      <Div
        style={{
          flex: 1,
          marginBottom: footer ? -24 : 0,
          marginRight: submitUnderneath ? 0 : 16,
          width: submitUnderneath ? '100%' : undefined,
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {useRichText ? (
          <BlockEditor
            id={'post-comment'}
            disabled={loading}
            placeholder={placeholder || 'Post a comment...'}
            onChange={setBlockContent}
            key={defaultValueKey}
            level={PluginLevel.Simple}
            minHeight={50}
            bordered
          />
        ) : (
          <Input.TextArea
            id={'post-comment'}
            disabled={loading}
            autoSize={{ minRows: 1, maxRows: 5 }}
            placeholder={placeholder || 'Post a comment...'}
            style={{ marginBottom: 0, width: '100%' }}
            onPressEnter={() => onFinish()}
            value={content}
            onChange={e => setContent(e.target.value)}
            {...props}
          />
        )}
        {footer ? <Footer>{footer}</Footer> : null}
      </Div>
      <Block marginLeft={'@size-xs'}>
        <HSpaced align={'center'}>
          <CustomComposeButtons
            onCreate={input => {
              return comment({
                variables: {
                  input: {
                    id: entityId,
                    ...input,
                    metadata: {
                      ...metadata,
                      ...input.metadata,
                    },
                  },
                },
              })
            }}
          />
          <Button
            type={'primary'}
            onClick={onFinish}
            shape={submitUnderneath ? undefined : 'circle'}
            icon={<AntIconWrapper children={<ArrowRight />} />}
            data-testid={'PostCommentButton'}
            {...buttonProps}
          />
        </HSpaced>
      </Block>
    </StyledForm>
  )
}
