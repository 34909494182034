import React from 'react'

export interface CurrentOverlay {
  weight: number
  onClose: () => void
}
export interface OverlayContextValue {
  overlayRef: React.MutableRefObject<CurrentOverlay | null>
}
export const OverlayContext = React.createContext<OverlayContextValue | undefined>(undefined)

export interface OverlayProviderProps {
  children: React.ReactElement
}
export function OverlayProvider({ children }: OverlayProviderProps) {
  const overlayRef = React.useRef<CurrentOverlay | null>(null)
  return <OverlayContext.Provider value={{ overlayRef }}>{children}</OverlayContext.Provider>
}

export function useOverlayContext(): OverlayContextValue | undefined
export function useOverlayContext(require: false): OverlayContextValue | undefined
export function useOverlayContext(require: true): OverlayContextValue
export function useOverlayContext(require?: boolean): OverlayContextValue | undefined {
  const context = React.useContext(OverlayContext)
  if (!context && require) {
    throw new Error('OverlayContext is required, yet not provided.')
  }
  return context
}
