import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Button, Button$, Form, Modal } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { SavedReportFragment, useUpdateSavedReportMutation } from '../schema'

export interface RenameSavedReportButtonProps extends Partial<Button$.ButtonProps> {
  savedReport: SavedReportFragment
}
export function RenameSavedReportButton({ savedReport, ...rest }: RenameSavedReportButtonProps) {
  const form = Form.useForm<{ name: string }>({
    defaultValues: { name: savedReport.name },
  })
  const [update] = useUpdateSavedReportMutation({
    onError: onMutationError('There was an error updating your saved report.'),
  })
  React.useEffect(() => {
    form.reset({ name: savedReport.name })
  }, [savedReport.name])
  return (
    <Form.Modal
      title={'Rename Saved Report'}
      form={form}
      onFinish={async values => {
        const trimmedName = values.name?.trim()
        if (trimmedName) {
          await update({
            variables: {
              input: {
                id: savedReport.id,
                name: trimmedName,
              },
            },
          })
        }
      }}
      trigger={
        <Button variant={'ghost'} size={'small'} icon={<Edit />} children={'Rename'} {...rest} />
      }
    >
      <Form.TextField
        name={'name'}
        label={'Saved Report Name'}
        placeholder={'Name your saved report...'}
        required
        autoComplete={'off'}
      />
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Save Changes'} />
      </Modal.Footer>
    </Form.Modal>
  )
}
