import { EditOutlined } from '@ant-design/icons'

import { NotesBoxAnswer } from './NotesBoxAnswer'
import { NotesBoxContent } from './NotesBoxContent'
import { NotesBoxEdit } from './NotesBoxEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'NotesBox',
  defaults: {
    name: 'Question',
    config: {
      sample: null,
    },
  },
  icon: EditOutlined,
  label: 'Notes',
  group: 'Short Answer',
  renderAnswerView: NotesBoxAnswer,
  renderContent: NotesBoxContent,
  renderEdit: NotesBoxEdit,
} as QuestionTypeResource
