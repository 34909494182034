import { Button, HSpaced, Text } from '@thesisedu/ui'
import { Star } from '@thesisedu/ui/icons'
import React from 'react'

import { useGroupsContext } from './GroupsContext'
import { onChangeGroup } from './onChangeGroup'

export interface LeaderCheckboxProps {
  groupKey: string
  studentId: string
}
export function LeaderCheckbox({ groupKey, studentId }: LeaderCheckboxProps) {
  const { groups, onChange } = useGroupsContext(true)
  const isLeader = groups[groupKey]?.leader === studentId
  return (
    <Button
      variant={'chromeless'}
      onPress={() => {
        onChangeGroup(groups, onChange, groupKey, { leader: isLeader ? undefined : studentId })
      }}
      aria-label={'Toggle Leader'}
    >
      <HSpaced space={'xxs'}>
        {isLeader ? (
          <Text color={'orange'} level={'s'}>
            Leader
          </Text>
        ) : null}
        <Text color={'orange'} level={'s'}>
          <Star style={isLeader ? { fill: 'currentColor' } : undefined} />
        </Text>
      </HSpaced>
    </Button>
  )
}
