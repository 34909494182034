import React from 'react'

import { packOpts } from './reporting/config/storage'
import { RunReportOpts } from './reporting/types'

export interface UseReportPath {
  path: string | null
  loading: boolean
}
export function useReportPath(opts: RunReportOpts): UseReportPath {
  const [loading, setLoading] = React.useState(true)
  const [path, setPath] = React.useState<string | null>(null)
  React.useEffect(() => {
    setPath(null)
    setLoading(true)
    packOpts(opts)
      .then(packed => {
        setPath(`/teacher/reports/${packed}`)
      })
      .finally(() => setLoading(false))
  }, [JSON.stringify(opts)])

  return { loading, path }
}
