/**
 *
 * @param a Date
 * @param b Date
 * @param unit Unit of time to return the difference in
 * @returns Date a - Date b in the specified unit of time, rounded to the nearest whole number
 */
export function diffDate(
  a: Date,
  b: Date,
  unit: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' | 'months' | 'years',
): number {
  const aTime = a.getTime()
  const bTime = b.getTime()
  const diff = aTime - bTime

  let result = 0

  switch (unit) {
    case 'seconds':
      result = diff / 1000
      break
    case 'minutes':
      result = diff / 1000 / 60
      break
    case 'hours':
      result = diff / 1000 / 60 / 60
      break
    case 'days':
      result = diff / 1000 / 60 / 60 / 24
      break
    case 'weeks':
      result = diff / 1000 / 60 / 60 / 24 / 7
      break
    case 'months':
      result = (a.getUTCFullYear() - b.getUTCFullYear()) * 12 + (a.getUTCMonth() - b.getUTCMonth())
      break
    case 'years':
      result = diff / 1000 / 60 / 60 / 24 / 365
      break
    default:
      throw new Error(`Unknown unit of time: ${unit}`)
  }

  return diff < 0 ? Math.ceil(result) : Math.floor(result)
}

/**
 *
 * @param dateToAdd Date to add units to
 * @param unitsToAdd Number of units to add
 * @param units Unit of time to add
 * @returns Date with units added
 */
export function dateAdd(
  dateToAdd: Date,
  unitsToAdd: number,
  units: 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years' = 'days',
) {
  const date = new Date(dateToAdd)
  switch (units) {
    case 'seconds':
      date.setSeconds(date.getSeconds() + unitsToAdd)
      break
    case 'minutes':
      date.setMinutes(date.getMinutes() + unitsToAdd)
      break
    case 'hours':
      date.setHours(date.getHours() + unitsToAdd)
      break
    case 'days':
      date.setDate(date.getDate() + unitsToAdd)
      break
    case 'months':
      date.setMonth(date.getMonth() + unitsToAdd)
      break
    case 'years':
      date.setFullYear(date.getFullYear() + unitsToAdd)
      break
  }
  return date
}
