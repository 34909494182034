import { UserLeftItemsHook, UserRoutesHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { ClipboardCheck } from '@thesisedu/ui/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'
import { RubricTemplateRoutes } from '../rubric/RubricTemplateRoutes'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...routes,
          <Route
            key={'rubric-templates'}
            path={'rubric-templates/*'}
            element={<RubricTemplateRoutes />}
          />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            weight: 0,
            element: (
              <Shell.LeftMenuItem
                name={'Rubric Templates'}
                icon={<AntIconWrapper children={<ClipboardCheck />} />}
                path={'/admin/rubric-templates'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
