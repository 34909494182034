import { ApolloReactOptions } from './types'

export const DEFAULT_OPTIONS: ApolloReactOptions = {
  host: 'http://localhost:3000/graphql',
  authenticationKey: 'feature-auth',
}

export const PREVIEW_PERSISTED_CACHE = 'feature-apollo-persisted-cache'
export const MUTATE_FRAGMENT_HOOK_PREFIX = 'apollo-web:mutate-fragment'
export const getMutateFragmentHook = (fragmentName: string) =>
  `${MUTATE_FRAGMENT_HOOK_PREFIX}:${fragmentName}`

export const SESSION_AUTH_KEY = 'sessionAuth'
