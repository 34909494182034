import { useClassStudents } from '@thesisedu/feature-classes-react'
import { HSpaced, Form, VSpaced, LoadingIndicator } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { DeletePresetButton } from './DeletePresetButton'
import { AssignmentSubmissionTypeSelect } from '../../edit/AssignmentSubmissionTypeSelect'
import { GroupsEditor } from '../GroupsEditor'

export interface PresetFormProps {
  presetId?: string
  onDeletePreset?: () => void
  classId: string
}
export function PresetForm({ presetId, classId, onDeletePreset }: PresetFormProps) {
  const { students, loading } = useClassStudents()
  const studentPairs = students.map(s => ({ classId, studentId: s.id }))
  return (
    <VSpaced space={'l'}>
      <HSpaced justify={'space-between'}>
        <Form.TextField
          name={'name'}
          aria-label={'Name'}
          placeholder={'Preset Name'}
          style={{ flex: 1, maxWidth: 400 }}
          required
          autoComplete={'off'}
        />
        <HSpaced space={'xs'}>
          {presetId ? <DeletePresetButton presetId={presetId} onDelete={onDeletePreset} /> : null}
          <Form.Field name={'submissionType'} aria-label={'Submission Type'} required>
            <AssignmentSubmissionTypeSelect hideIndividual />
          </Form.Field>
          <Form.Submit
            children={presetId ? 'Save Changes' : 'Create Preset'}
            icon={<Check />}
            disableUntilDirty
          />
        </HSpaced>
      </HSpaced>
      {loading ? (
        <LoadingIndicator.Labeled label={'Loading editor...'} />
      ) : (
        <Form.Field name={'configuration'} aria-label={'Groups Configuration'} required>
          <GroupsEditor students={studentPairs} hideSavePreset classId={classId} />
        </Form.Field>
      )}
    </VSpaced>
  )
}
