import { AriaTimeFieldProps, TimeValue, useTimeField } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { TimeFieldStateOptions, useTimeFieldState } from 'react-stately'

import { ClearButton } from './ClearButton'
import {
  DateFieldVariant,
  _DateFieldContainer,
  _DateFieldValueContainer,
  _variants,
} from './DateField'
import { DateSegment } from './DateSegment'
import { parseTime } from './parse'
import { s } from '../..'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { HSpacedProps } from '../HSpaced/HSpaced'

export interface TimeFieldProps
  extends ExtendedBareFieldProps,
    Omit<AriaTimeFieldProps<TimeValue>, 'isDisabled' | 'value' | 'onChange'>,
    Omit<TimeFieldStateOptions<TimeValue>, 'locale' | 'value' | 'onChange'> {
  className?: string
  style?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  disabled?: boolean
  variant?: DateFieldVariant
  prefix?: React.ReactElement
  suffix?: React.ReactElement
  value?: string | null
  onChange?: (value: string | null) => void
  __groupProps?: HSpacedProps
}
function _TimeField(
  {
    className,
    style,
    disabled,
    size = s.SharedVariants.Size.defaultValue,
    variant,
    prefix,
    suffix,
    __groupProps,
    value,
    onChange,
    ...props
  }: TimeFieldProps,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const ref = useObjectRef(_ref)
  const { locale } = useLocale()
  const state = useTimeFieldState({
    ...rest,
    value: parseTime(value) ?? null,
    onChange: value => {
      onChange?.(value ? value.toString() : null)
    },
    locale,
  })
  const { fieldProps, ...aria } = useTimeField(
    {
      ...bareFieldProps,
      ...rest,
      isDisabled: disabled,
    },
    state,
    ref,
  )

  return (
    <BareField {...bareFieldProps} style={style} __aria={aria}>
      <_DateFieldContainer
        {...__groupProps}
        className={s.variants<typeof _variants>(
          className,
          {
            disabled,
            error: !!props.error,
            allArePlaceholders: state.segments.every(
              segment => segment.isPlaceholder || !segment.isEditable,
            ),
          },
          size,
          variant,
        )}
        style={style}
        space={'xs'}
        ref={ref}
      >
        {prefix}
        <_DateFieldValueContainer {...fieldProps}>
          {state.segments.map((segment, i) => (
            <DateSegment key={i} segment={segment} state={state} />
          ))}
          {state.validationState === 'invalid' ? (
            <span aria-hidden={'true'}>Invalid Date</span>
          ) : null}
        </_DateFieldValueContainer>
        <ClearButton {...props} value={value} onChange={onChange} />
        {suffix}
      </_DateFieldContainer>
    </BareField>
  )
}
export const TimeField = React.forwardRef(_TimeField)
