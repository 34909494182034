import { Text, Well } from '@thesisedu/ui'
import React from 'react'

export function WorkStarted() {
  return (
    <Well status={'warning'} title={'Careful! Work has started.'}>
      <Text color={'secondary'}>
        Students assigned to this assignment have already begun working. Please know that if you
        change groups in the middle of a group activity or project, they will get credit for being
        in the final group, and not any other group(s) they have been a part of during this
        activity.
      </Text>
    </Well>
  )
}
