import { FeatureRoot } from '@thesisedu/feature'
import { WebContext } from '@thesisedu/feature-web'
import { useContext } from 'react'

export * from '@thesisedu/feature-web'

export const useFeatureRoot = (): FeatureRoot | undefined => {
  const context = useContext(WebContext)
  return context?.featureWeb
}
