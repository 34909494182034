import { Dropdown } from '@thesisedu/ui'
import { DiceFive } from '@thesisedu/ui/icons'
import React from 'react'

import { shuffle } from './shuffle'
import { useGroupsContext } from '../GroupsContext'

export function ShuffleButton() {
  const { groups, onChange } = useGroupsContext(true)
  return (
    <Dropdown.Container>
      <Dropdown.Button icon={<DiceFive />} children={'Shuffle'} />
      <Dropdown.Menu side={'bottom'} align={'end'}>
        <Dropdown.Item
          children={'Groups Only'}
          onClick={() => onChange?.(shuffle(groups, { groups: true, leaders: false }))}
        />
        <Dropdown.Item
          children={'Leaders Only'}
          onClick={() => onChange?.(shuffle(groups, { groups: false, leaders: true }))}
        />
        <Dropdown.Item
          children={'Both Groups and Leaders'}
          onClick={() => onChange?.(shuffle(groups, { groups: true, leaders: true }))}
        />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
