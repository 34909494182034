import { BodySmall, styledContainer as styled, getSize, getColor } from '@thesisedu/react'
import React from 'react'

export interface QuestionInstructionsProps {
  instructions: string | React.ReactElement
  padded?: boolean
  containerStyle?: any
  children?: React.ReactElement | any[] | null
  [key: string]: any
}
export function QuestionInstructions({
  instructions,
  padded,
  containerStyle,
  children: _children,
  ...props
}: QuestionInstructionsProps) {
  const children =
    (props?.value || props?.onChange) && _children && !Array.isArray(_children)
      ? React.cloneElement(_children, {
          ...props,
          ..._children.props,
        })
      : _children
  return (
    <Container
      style={containerStyle}
      className={['question-instructions', props.className].join(' ')}
    >
      <Header className={'header'}>
        <BodySmall>{instructions}</BodySmall>
      </Header>
      {padded ? <Content className={'content'} children={children} /> : children}
    </Container>
  )
}

const Header = styled`
  background: ${props => getColor(props.theme, '@background-color-base')};
  border-bottom: solid 1px ${props => getColor(props.theme, '@border-color-base')};
  padding: ${props => getSize(props.theme, '@size-xs')} ${props => getSize(props.theme, '@size-s')};
`
const Content = styled`
  padding: ${props => getSize(props.theme, '@size-s')};
`
const Container = styled`
  border-radius: ${props => getSize(props.theme, '@border-radius-base')};
  border: solid 1px ${props => getColor(props.theme, '@border-color-base')};
  margin-top: ${props => getSize(props.theme, '@size-s')};
`
