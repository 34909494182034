import { useHash } from '@thesisedu/web'
import React from 'react'

import { SegmentNavigationItemsContext } from './SegmentNavigationItemsContext'
import { useDefaultNavigationKey } from '../course_viewer/getSegmentNavigationItems'
import { useDefaultSegmentNavigationItems } from '../course_viewer/useSegmentNavigationItems'
import { isSegmentHydrated, useHydratedSegment } from '../segment/useHydratedSegment'
import { Segment, SegmentNavigationItem } from '../types'

export interface SegmentNavigationContextValue {
  selectedNavigationItemId?: string
  setSelectedNavigationItemId: (navigationItemId: string | undefined) => void
}
export const SegmentNavigationContext = React.createContext<
  SegmentNavigationContextValue | undefined
>(undefined)

export function useSegmentNavigationContext(): SegmentNavigationContextValue | undefined
export function useSegmentNavigationContext(
  require: false,
): SegmentNavigationContextValue | undefined
export function useSegmentNavigationContext(require: true): SegmentNavigationContextValue
export function useSegmentNavigationContext(
  require?: boolean,
): SegmentNavigationContextValue | undefined {
  const context = React.useContext(SegmentNavigationContext)
  if (!context && require) {
    throw new Error('SegmentNavigationContext is required, yet not provided.')
  }
  return context
}

const SEP = ':-:'
export function toNavigationKey(parts: string[]) {
  return parts.join(SEP)
}
export function fromNavigationKey(key: string) {
  return key.split(SEP)
}

export interface SegmentNavigationProviderProps {
  children: React.ReactNode
  segment?: Segment
  isPreview?: boolean
}
export function SegmentNavigationProvider({
  children,
  segment: _segment,
  isPreview,
}: SegmentNavigationProviderProps) {
  const { segment, loading, error } = useHydratedSegment(_segment)
  const defaultKey = useDefaultNavigationKey(segment)
  const [selectedNavigationItemId, setSelectedNavigationItemId] = useHash()
  const defaultItems = useDefaultSegmentNavigationItems(segment, !!isPreview)
  const [navigationItems, setNavigationItems] = React.useState<SegmentNavigationItem[]>([])
  React.useEffect(() => {
    setNavigationItems(defaultItems)
  }, [segment?.id, isSegmentHydrated(segment)])
  return (
    <SegmentNavigationItemsContext.Provider
      value={{ loading, error: !!error, navigationItems, setNavigationItems }}
    >
      <SegmentNavigationContext.Provider
        children={children}
        value={{
          selectedNavigationItemId: selectedNavigationItemId || defaultKey,
          setSelectedNavigationItemId,
        }}
      />
    </SegmentNavigationItemsContext.Provider>
  )
}
