import { PageHead } from '@thesisedu/react'
import { CloudSync } from '@thesisedu/react/icons'
import React from 'react'

import { UsersReactFeature } from '../../UsersReactFeature'
import { UserAccountsPage } from '../../saml/settings/UserAccountsPage'
import { UserSettingsPageResource } from '../../types'

export default function (feature: UsersReactFeature) {
  feature.resources.addResource<UserSettingsPageResource>({
    type: 'UserSettingsPage',
    identifier: 'connected-accounts',
    icon: <CloudSync />,
    title: 'Connected Accounts',
    weight: 1,
    component: () => (
      <>
        <PageHead title={'Connected Accounts'} />
        <UserAccountsPage />
      </>
    ),
  })
}
