import { CloudUploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { useErrorService } from '@thesisedu/feature-web'
import { UploadFile } from '@thesisedu/native-upload'
import { StyledThemeContext as ThemeContext } from '@thesisedu/web'
import { Button, message, Progress, Upload as AntUpload } from 'antd'
import { ButtonProps } from 'antd/es/button'
import ImgCrop from 'antd-img-crop'
import React from 'react'

import { useUpload } from './common/useUpload'
import { debug } from './log'
import { UploadProps } from './types'

export interface UploadButtonProps extends UploadProps {
  buttonProps?: Partial<ButtonProps>
}
export function UploadButton({ cropProps, buttonProps, ...props }: UploadButtonProps) {
  const { uploadAction, beforeUpload, loading, uploadFile, setLoading } = useUpload(
    useErrorService,
    {
      ...props,
      onError: err => message.error(err),
    },
  )
  const theme = React.useContext(ThemeContext)

  const content = (
    <AntUpload
      name={'file'}
      showUploadList={false}
      action={uploadAction}
      beforeUpload={beforeUpload}
      accept={props.accept}
      disabled={props.disabled}
      customRequest={options => {
        const { file, onError, onProgress, onSuccess, withCredentials } = options
        uploadFile(file as UploadFile, {
          withCredentials,
          onUploadProgress: ({ total, loaded }) => {
            const opts = {
              percent: parseFloat(Math.round((loaded / total) * 100).toFixed(2)),
            }
            ;(onProgress as any)(opts, file)
          },
        })
          .then(() => (onSuccess as any)({ status: 200 }, file))
          .catch(onError)
        return { abort: () => false }
      }}
      onChange={info => {
        debug('status is %s', info.file.status)
        if (info.file.status === 'error') {
          message.error('There was an error uploading that file.')
          setLoading(false)
        }
      }}
    >
      <Button
        icon={
          loading && loading !== true ? (
            <Progress
              type={'circle'}
              percent={loading}
              width={buttonProps?.size === 'large' ? 20 : 18}
              showInfo={false}
              strokeWidth={parseFloat(theme['@size-xs'].replace('px', ''))}
              strokeColor={theme['@primary-color']}
            />
          ) : loading === true ? (
            <LoadingOutlined spin />
          ) : (
            <CloudUploadOutlined />
          )
        }
        children={loading ? 'Uploading...' : 'Upload File'}
        disabled={!!loading}
        {...buttonProps}
      />
    </AntUpload>
  )

  if (cropProps) {
    // @ts-ignore not upgrading dependency; children types are missing.
    return <ImgCrop {...cropProps} children={content} />
  } else {
    return content
  }
}
