import { $getRoot, LexicalEditor } from 'lexical'

import { Indeterminate, Upward, Downward } from './constants'
import { Point } from '../../utils/point'
import { Rect } from '../../utils/rect'

let prevIndex = 0

function getTopLevelNodeKeys(editor: LexicalEditor): string[] {
  return editor.getEditorState().read(() => $getRoot().getChildrenKeys())
}

function getCurrentIndex(keysLength: number): number {
  if (keysLength === 0) return Infinity
  if (prevIndex >= 0 && prevIndex < keysLength) return prevIndex
  return Math.floor(keysLength / 2)
}

export function getDragResizableBlockElement(
  editor: LexicalEditor,
  event: MouseEvent,
): { blockElement: HTMLElement; blockKey: string } | null {
  const elementNodeKeys = getTopLevelNodeKeys(editor)

  let blockElement: HTMLElement | null = null
  let blockKey: string | null = null

  editor.getEditorState().read(() => {
    let index = getCurrentIndex(elementNodeKeys.length)
    let direction = Indeterminate

    while (index >= 0 && index < elementNodeKeys.length) {
      const key = elementNodeKeys[index]
      const element = editor.getElementByKey(key)
      if (element === null) break
      const point = new Point(event.x, event.y)
      const domRect = Rect.fromDOM(element)
      const { marginTop, marginBottom, marginLeft, marginRight } = window.getComputedStyle(element)

      const rect = domRect.generateNewRect({
        bottom: domRect.bottom + parseFloat(marginBottom),
        left: domRect.left - parseFloat(marginLeft),
        right: domRect.right + parseFloat(marginRight),
        top: domRect.top - parseFloat(marginTop),
      })

      const {
        result,
        reason: { isOnTopSide, isOnBottomSide, isOnLeftSide, isOnRightSide },
      } = rect.contains(point)

      if (result) {
        blockElement = element
        blockKey = key
        prevIndex = index
        break
      }

      if (direction === Indeterminate) {
        if (isOnTopSide || isOnLeftSide) {
          direction = Upward
        } else if (isOnBottomSide || isOnRightSide) {
          direction = Downward
        } else {
          // Stop searching block element.
          direction = Infinity
        }
      }

      index += direction
    }
  })

  return blockElement && blockKey ? { blockElement, blockKey } : null
}
