import { SyncAssignment, SyncAssignmentActionsProps } from '@thesisedu/feature-class-sync-react'
import { useToast } from '@thesisedu/ui'
import React from 'react'

import { useSyncAssignmentMutation } from '../queries/useSyncAssignmentMutation'
import {
  AssignmentAssignedStudentsFragment,
  AssignmentWithSyncFragment,
  BasicAssignmentFragment,
  useAssignmentQuery,
} from '../schema'

interface SyncAssignmentActionsResult {
  loading?: boolean
  props?: SyncAssignmentActionsProps
}
export type AssignmentSyncAssignment = Pick<
  BasicAssignmentFragment,
  'id' | 'assignmentCategories'
> &
  Partial<AssignmentWithSyncFragment> &
  AssignmentAssignedStudentsFragment
export function useSyncAssignmentActionsProps(
  assignmentOrId?: string | AssignmentSyncAssignment,
  additionalOpts?: Parameters<typeof useSyncAssignmentMutation>[0],
): SyncAssignmentActionsResult {
  const [sync] = useSyncAssignmentMutation(additionalOpts)
  const toast = useToast()
  const { data, loading } = useAssignmentQuery({
    variables: {
      id: typeof assignmentOrId === 'string' ? assignmentOrId : 'dummy',
    },
    skip: typeof assignmentOrId !== 'string' || !assignmentOrId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  })

  const resultAssignment =
    typeof assignmentOrId === 'string'
      ? data?.node?.__typename === 'Assignment'
        ? (data.node as AssignmentSyncAssignment)
        : undefined
      : assignmentOrId
  if (resultAssignment?.syncs && resultAssignment?.canSync) {
    const assignedClassIds = [...new Set(resultAssignment.students.edges.map(edge => edge.classId))]
    const classIds = [
      ...new Set(resultAssignment.assignmentCategories.edges.map(edge => edge.node.classId)),
    ]
    return {
      loading,
      props: {
        onSync: async classSyncId => {
          const update = toast({
            title: 'Syncing assignment...',
            loading: true,
          })
          try {
            await sync({
              variables: { input: { id: resultAssignment.id, syncIds: [classSyncId] } },
            })
          } finally {
            update()
          }
        },
        syncs: resultAssignment.syncs.edges.map(edge => edge.node),
        assignedClassIds,
        classIds,
      },
    }
  } else {
    return { loading }
  }
}

export interface AssignmentSyncsProps {
  assignment: AssignmentSyncAssignment
  mutateOpts?: Parameters<typeof useSyncAssignmentMutation>[0]
}
export function AssignmentSyncs({ assignment, mutateOpts }: AssignmentSyncsProps) {
  const { props } = useSyncAssignmentActionsProps(assignment, mutateOpts)
  if (assignment.syncs && props) {
    return <SyncAssignment {...props} disabled={!assignment.canSync} />
  } else {
    return null
  }
}
