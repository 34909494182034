import { AriaDateFieldOptions, DateValue, useDateField } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { DateFieldStateOptions, useDateFieldState } from 'react-stately'

import { _DateFieldValueContainer } from './DateField'
import { DateSegment } from './DateSegment'
import { createCalendar } from './createCalendar'

export interface BareDateFieldProps<T extends DateValue>
  extends AriaDateFieldOptions<T>,
    Omit<DateFieldStateOptions<T>, 'createCalendar' | 'locale'> {
  style?: React.CSSProperties
}
function _BareDateField<T extends DateValue>(
  { style, ...rest }: BareDateFieldProps<T>,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { locale } = useLocale()
  const ref = useObjectRef(_ref)
  const state = useDateFieldState<T>({
    ...rest,
    hideTimeZone: rest.hideTimeZone !== false,
    locale,
    createCalendar,
  })
  const { fieldProps } = useDateField<T>(rest, state, ref)

  return (
    <_DateFieldValueContainer {...fieldProps} style={style}>
      {state.segments.map((segment, i) => (
        <DateSegment key={i} segment={segment} state={state} />
      ))}
      {state.validationState === 'invalid' ? <span aria-hidden={'true'}>Invalid Date</span> : null}
    </_DateFieldValueContainer>
  )
}
export const BareDateField = React.forwardRef(_BareDateField)
