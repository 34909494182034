import { MyContentPageResource } from '@thesisedu/feature-users-react'
import { AddFolder } from '@thesisedu/ui/icons'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'
import { ContentManager } from '../../custom_content/ContentManager'

export default function (feature: CoursesReactFeature) {
  feature.resources.addResource<MyContentPageResource>({
    type: 'MyContentPage',
    identifier: 'custom-content',
    icon: <AddFolder />,
    title: 'Custom Content',
    weight: -100,
    filter: viewer => viewer?.group === 'TEACHER',
    component: ContentManager,
  })
}
