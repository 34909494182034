import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-web'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { error, warn } from './log'

export interface LogoutOptions {
  apolloFeature: ApolloReactFeature
}
export const logoutUser = ({ apolloFeature }: LogoutOptions) => {
  if (apolloFeature.isUsingSessionAuth()) {
    window.close()
    return Promise.resolve()
  } else {
    localStorage.removeItem(apolloFeature.authenticationKey)
    return apolloFeature.client.resetStore().catch(err => {
      error('error resetting store')
      error(err)
    })
  }
}

export const Logout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate()
  const apolloFeature = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  useEffect(() => {
    logoutUser({ apolloFeature })
      .catch(err => {
        warn('error when logging out', err)
      })
      .then(() => {
        navigate('/auth/login')
      })
  }, [])
  return <p>Logging out...</p>
}
