import { useAssignmentViewerContext } from '@thesisedu/feature-assignments-react'
import { SegmentCalculationOptions } from '@thesisedu/feature-course-types'
import { TagFilter } from '@thesisedu/feature-tags-react'
import { FilterButton } from '@thesisedu/react'
import { Dropdown } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import { styled } from '@thesisedu/web'
import { Button, Input, Tooltip } from 'antd'
import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { ResetConfirmModal } from './ResetConfirmModal'
import { EditorFilters } from './SegmentCalculationInnerEditor'
import { ASSIGNMENT_KIND_TAG, ResetOption } from '../../types'

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  > * {
    margin-top: ${props => props.theme['@size-xs']} !important;
  }
  > button:not(:last-child) {
    margin-right: 5px;
  }
  > span {
    margin-right: 5px;
    flex-grow: 1;
  }
`

type FilterMap = {
  [item in keyof EditorFilters]?: string
}
const FILTERS: FilterMap = {
  disabled: 'Disabled',
  isCovered: 'Covered',
  isRecommended: 'Recommended',
}
const AUTO_GRADE_FILTERS: FilterMap = {
  autograde: 'Auto-Gradeable',
}

export interface VaultFiltersProps {
  filters: EditorFilters
  onFiltersChange: (filters: EditorFilters) => void
  isRecommendedPreset?: boolean
  configuration: SegmentCalculationOptions
}
export function VaultFilters({
  isRecommendedPreset,
  filters,
  onFiltersChange,
  configuration,
}: VaultFiltersProps) {
  const { assignment } = useAssignmentViewerContext(true)
  const [name, setName] = useState(filters.name)
  const [dName] = useDebounce(name, 500)
  useEffect(() => {
    onFiltersChange({
      ...filters,
      name: dName,
    })
  }, [dName])

  const currentFilters = configuration.includeNonAutoGradedQuestions
    ? { ...FILTERS, ...AUTO_GRADE_FILTERS }
    : FILTERS
  const filtersSet = (Object.keys(filters) as (keyof EditorFilters)[]).some(filterKey => {
    return filters[filterKey] !== undefined && filters[filterKey] !== null
  })

  const resetOptions: ResetOption[] = [
    {
      title: 'Are you sure?',
      onConfirm: {
        ...assignment!.rawConfiguration,
        questions: [],
      },
      confirm: { status: 'danger', children: 'Yes, reset' },
      triggerText: 'Disable all questions',
      modalText: 'You will lose all customizations.',
    },
    {
      title: 'Are you sure?',
      onConfirm: {
        ...assignment!.rawConfiguration,
        calculation: omit(assignment!.rawConfiguration!.calculation, ['calculatedPreset']),
        questions: [],
      },
      confirm: { status: 'danger', children: 'Yes, reset' },
      triggerText: 'Start over and choose another preset',
      modalText:
        'You will lose all customizations and be able to re-configure this assignment from scratch.',
    },
  ]

  return (
    <>
      <Container style={{ flexWrap: 'wrap' }}>
        {(Object.keys(currentFilters) as (keyof EditorFilters)[]).map(filterName => {
          const disabled = filterName === 'isRecommended' && isRecommendedPreset
          const button = (
            <FilterButton<boolean | string>
              disabled={disabled}
              value={filters[filterName]}
              options={[
                {
                  label: `Show ${currentFilters[filterName]!.toLowerCase()} questions only`,
                  value: true,
                },
                {
                  label: `Hide ${currentFilters[filterName]!.toLowerCase()} questions`,
                  value: false,
                },
              ]}
              onChange={value => {
                onFiltersChange({
                  ...filters,
                  [filterName]: value,
                })
              }}
              label={currentFilters[filterName]!}
            />
          )
          if (disabled) {
            return (
              <Tooltip
                title={
                  'If you want to view questions that are not ' +
                  'recommended, click the reset button to the right and choose a ' +
                  'preset other than Recommended or Recommended and Covered'
                }
                key={filterName}
                mouseEnterDelay={1}
              >
                <span>{button}</span>
              </Tooltip>
            )
          } else {
            return button
          }
        })}
        <TagFilter
          tagType={ASSIGNMENT_KIND_TAG}
          label={'Assignment Kind'}
          valueKind={'name'}
          value={filters.assignmentKindName || undefined}
          onChange={name => {
            onFiltersChange({
              ...filters,
              assignmentKindName: name,
            })
          }}
        />
        {filtersSet ? (
          <Button
            onClick={() => {
              onFiltersChange({})
            }}
            type={'link'}
          >
            Reset Filters
          </Button>
        ) : null}
      </Container>
      <Container>
        <Input.Search
          placeholder={'Search by name or standard...'}
          value={name || ''}
          onChange={e => setName(e.target.value)}
          allowClear
          style={{ minWidth: 200 }}
        />
        <Dropdown.Container>
          <Dropdown.Button
            icon={<Trash />}
            status={'danger'}
            children={'Reset'}
            style={{ flexShrink: 0 }}
          />
          <Dropdown.Menu align={'end'}>
            {resetOptions.map((config, index) => (
              <ResetConfirmModal key={index} config={config} />
            ))}
          </Dropdown.Menu>
        </Dropdown.Container>
      </Container>
    </>
  )
}
