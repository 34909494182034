import { DeleteOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { GenerateLinkMenuItem } from '@thesisedu/feature-classes-react'
import { useFeature } from '@thesisedu/feature-react'
import { ShareActionItem } from '@thesisedu/feature-sharing-react'
import { Menu, message, Modal } from 'antd'
import { MenuProps } from 'antd/es/menu'
import React from 'react'

import { LessonsReactFeature } from './LessonsReactFeature'
import { useDeleteLessonMutation } from './queries/useDeleteLessonMutation'
import { LessonFragment } from './schema'

export interface LessonActionsProps extends MenuProps {
  lesson: LessonFragment
  onDeleted?: () => void
  onRefetch?: () => void
}
export const LessonActions: React.FC<React.PropsWithChildren<LessonActionsProps>> = ({
  lesson,
  onRefetch,
  onDeleted,
  ...props
}) => {
  const { disableLinkGeneration, disableSharing } = useFeature(LessonsReactFeature).options
  const [deleteLesson] = useDeleteLessonMutation({
    onCompleted: () => {
      message.success('Lesson deleted successfully!')
      if (onDeleted) {
        onDeleted()
      }
    },
  })
  return (
    <Menu
      {...props}
      onClick={({ key }) => {
        if (key === 'download') {
          if (lesson.media.path) {
            window.open(lesson.media.path, '_blank')
          }
        } else if (key === 'delete') {
          Modal.confirm({
            title: 'Are you sure?',
            type: 'warning',
            icon: <ExclamationCircleOutlined />,
            content: (
              <span>
                You are about to delete the <strong>{lesson.name}</strong> lesson. Are you sure you
                want to do this?
              </span>
            ),
            okText: 'Yes, delete.',
            okButtonProps: { danger: true },
            cancelText: 'No, keep.',
            onOk: () => {
              return deleteLesson({
                variables: {
                  id: lesson.id,
                },
              }).then(() => {
                if (onRefetch) {
                  onRefetch()
                }
              })
            },
          })
        }
      }}
    >
      {lesson.sharing.sharedFrom || !lesson.canDelete ? null : (
        <Menu.Item key={'delete'}>
          <DeleteOutlined />
          <span>Delete Lesson</span>
        </Menu.Item>
      )}
      {lesson.media.path && !lesson.sharing.sharedFrom && lesson.canDownload ? (
        <Menu.Item key={'download'}>
          <DownloadOutlined />
          <span>Download</span>
        </Menu.Item>
      ) : null}
      {disableLinkGeneration ? null : (
        <GenerateLinkMenuItem universalPath={`lessons/${lesson.id}`} promptClassId requireClassId />
      )}
      {lesson.canUpdate && !disableSharing ? <ShareActionItem shareable={lesson} /> : null}
    </Menu>
  )
}
