import { findSegment } from '@thesisedu/feature-course-types'

import { getMaximumDepth } from './generate'
import { OutlineItem, isItemWithSegment } from './types'
import { NON_GROUP_TYPES } from '../constants'
import { getSegmentParents } from '../helpers'
import { SegmentType } from '../schema'
import { GroupSegment, Segment } from '../types'

export interface ValidPlacementOpts {
  unsortedTerm: Segment | GroupSegment
  /** If the segment you are dragging is not inside the unsortedTerm, pass it here. */
  draggingSegment?: Segment
  outlineItems: OutlineItem[]
  overItemId: string
  draggingItemId: string
}

export interface ValidPlacementSummary {
  depth: number
  parentSegment: Segment
}

/**
 * Given the outline items, an over item ID, a dragging item ID, and the unsorted term,
 * determines whether the current placement is valid, and if so, returns the new parent
 * and the target depth.
 *
 * By "unsorted term," we mean a term that is not affected by the current ordering
 * operation.
 */
export function getValidPlacement({
  outlineItems,
  overItemId,
  draggingItemId,
  unsortedTerm,
  draggingSegment: _draggingSegment,
}: ValidPlacementOpts): ValidPlacementSummary | null {
  const draggingSegment =
    _draggingSegment ?? (findSegment([unsortedTerm], draggingItemId) as Segment | null)
  if (!draggingSegment) return null
  const overSegment = findSegment([unsortedTerm], overItemId) as Segment | null
  if (!overSegment) return null
  const overOutlineItemIndex = outlineItems.findIndex(item => item.id === overItemId)
  const overOutlineItem = outlineItems[overOutlineItemIndex]
  if (!overOutlineItem) return null
  const draggingDepth = getMaximumDepth([draggingSegment])
  const maximumAllowedDepth = overOutlineItem?.place.maximumAllowedDepth

  const overParentItem = overOutlineItem.place.parentSegment
  const depth = overOutlineItem.depth

  const isSameAsParent = overParentItem.id === overOutlineItem.id
  if (isSameAsParent && draggingDepth < maximumAllowedDepth) {
    return {
      depth: depth + 1,
      parentSegment: overParentItem,
    }
  } else if (
    draggingDepth <= maximumAllowedDepth &&
    (overOutlineItem.collapsed ||
      (isItemWithSegment(overOutlineItem) &&
        NON_GROUP_TYPES.includes(overOutlineItem.segment.type as SegmentType)))
  ) {
    return {
      depth,
      parentSegment: overOutlineItem.place.trueParentSegment || overOutlineItem.place.parentSegment,
    }
  } else if (draggingDepth === 0 && maximumAllowedDepth === 0) {
    return {
      depth,
      parentSegment: overOutlineItem.place.parentSegment,
    }
  } else {
    // Next, we need to see if we are at the end of a level where we can actually drop this thing.
    let nextOutlineItem: OutlineItem | undefined = outlineItems[overOutlineItemIndex + 1]
    if (nextOutlineItem && nextOutlineItem.id === draggingItemId) nextOutlineItem = undefined
    // depthDifference must support null to support the end of the term.
    const depthDifference = nextOutlineItem ? overOutlineItem.depth - nextOutlineItem.depth : null
    if (depthDifference === null || depthDifference > 0) {
      const firstValidAncestorDelta = Math.min(
        depthDifference === null ? Infinity : -1 * depthDifference,
        maximumAllowedDepth - draggingDepth - 1,
      )

      // Make sure the next item is equal to or less than that depth.
      // This prevents dropping a topic at the end of an expanded lesson.
      if (
        nextOutlineItem &&
        // The +1 takes into account the term.
        nextOutlineItem.depth > overOutlineItem.depth + firstValidAncestorDelta + 1
      ) {
        return null
      }

      const firstValidAncestorId =
        overOutlineItem.parentIds[overOutlineItem.parentIds.length + firstValidAncestorDelta]
      const firstValidAncestor = firstValidAncestorId
        ? (findSegment([unsortedTerm], firstValidAncestorId) as Segment | null)
        : undefined
      if (firstValidAncestor) {
        return {
          depth: depth + firstValidAncestorDelta + 1,
          parentSegment: firstValidAncestor,
        }
      } else return null
    } else {
      return null
    }
  }
}

export interface ValidPlacementSummaryWithOffset extends ValidPlacementSummary {
  minimumDepth: number
}
export function getValidPlacementWithOffset(
  opts: ValidPlacementOpts & { offsetLeft: number | null },
): ValidPlacementSummaryWithOffset | null {
  const placement = getValidPlacement(opts)
  const { offsetLeft, unsortedTerm, outlineItems, overItemId } = opts
  if (placement) {
    if (offsetLeft !== null) {
      // Get the current outline item, and the next one, so we can see what minimum depth we
      // support. We don't want to allow someone to place something at the Semester level in
      // the middle of a lesson.
      const overOutlineItemIndex = outlineItems.findIndex(item => item.id === overItemId)
      const overOutlineItem = outlineItems[overOutlineItemIndex]
      if (!overOutlineItem) return null
      const nextOutlineItem = outlineItems[overOutlineItemIndex + 1]
      const depthDifference = nextOutlineItem
        ? Math.max(0, overOutlineItem.depth - nextOutlineItem.depth)
        : null
      const maxDepth = placement.depth
      const minDepth = depthDifference !== null ? Math.max(0, placement.depth - depthDifference) : 0

      const newDepth = Math.max(Math.min(offsetLeft, maxDepth), minDepth)
      const depthDelta = placement.depth - newDepth
      if (depthDelta > 0) {
        const parentParentIds = getSegmentParents([unsortedTerm], placement.parentSegment.id)
        const targetParentId = parentParentIds[parentParentIds.length - depthDelta]
        if (targetParentId) {
          const targetParent = findSegment([unsortedTerm], targetParentId)
          if (targetParent) {
            return {
              depth: newDepth,
              minimumDepth: minDepth,
              parentSegment: targetParent as Segment,
            }
          } else return { ...placement, minimumDepth: minDepth }
        } else return { ...placement, minimumDepth: minDepth }
      } else {
        return { ...placement, minimumDepth: minDepth }
      }
    } else return { ...placement, minimumDepth: 0 }
  } else return null
}
