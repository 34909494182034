import { SAMLButtonResource } from '@thesisedu/feature-users-react'

import { AuthGoogleReactFeature } from '../AuthGoogleReactFeature'
import { GoogleLoginButton } from '../web/GoogleLoginButton'

export default function (feature: AuthGoogleReactFeature) {
  feature.resources.addResource<SAMLButtonResource>({
    type: 'SAMLButton',
    identifier: 'google',
    weight: 4,
    component: GoogleLoginButton,
  })
}
