import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { SelectableButton } from '@thesisedu/react'
import { Download, List, ViewGrid } from '@thesisedu/react/icons'
import {
  AntIconWrapper,
  Tooltip,
  BlockSpin,
  NotFound,
  PageHead,
  PageHeader,
  styled,
  Block,
} from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { ExploreStandardSetsContextProvider } from './ExploreStandardSetsContext'
import { StandardSetGrid } from './StandardSetGrid'
import { StandardSetList } from './StandardSetList'
import { StandardSetState } from './StandardSetState'
import {
  StandardSetFragment,
  StandardSetSegmentsFragment,
  useExploreStandardSetQuery,
} from '../schema'

export enum ViewMode {
  Grid,
  List,
}

export interface StandardSetChildProps {
  standardSet: StandardSetFragment & StandardSetSegmentsFragment
  focusTagId?: string
}

export interface StandardSetPageProps {
  standardSetId: string
}
export function StandardSetPage({ standardSetId }: StandardSetPageProps) {
  const focusTagId = React.useRef(
    window.location.hash ? window.location.hash.slice(1) : undefined,
  ).current
  const [viewMode, setViewMode] = React.useState(ViewMode.List)
  const { data, loading } = useExploreStandardSetQuery({
    variables: {
      id: standardSetId,
    },
  })
  const standardSet = data?.node?.__typename === 'StandardSet' ? data.node : undefined

  if (standardSet) {
    const attachmentFileName = getFileInfo(standardSet.attachment.url?.signedUrl)?.filename
    const childProps: StandardSetChildProps = {
      standardSet,
      focusTagId,
    }
    return (
      <>
        <PageHead title={`${standardSet.name} Standards - Explore`} />
        {standardSet.state ? (
          <IconWrapper>
            <StandardSetState state={standardSet.state} />
          </IconWrapper>
        ) : null}
        <PageHeader title={`${standardSet.name} Standards`}>
          <Button.Group>
            <SelectableButton
              icon={<AntIconWrapper children={<List />} />}
              children={'List View'}
              selected={viewMode === ViewMode.List}
              onClick={() => setViewMode(ViewMode.List)}
            />
            <SelectableButton
              icon={<AntIconWrapper children={<ViewGrid />} />}
              children={'Grid View'}
              selected={viewMode === ViewMode.Grid}
              onClick={() => setViewMode(ViewMode.Grid)}
            />
            {attachmentFileName ? (
              <Tooltip title={'Download Crosswalk'}>
                <Button
                  icon={<AntIconWrapper children={<Download />} />}
                  onClick={() => {
                    window.open(attachmentFileName, '_blank')
                  }}
                />
              </Tooltip>
            ) : null}
          </Button.Group>
        </PageHeader>
        <Block marginTop={'@size-l'}>
          <ExploreStandardSetsContextProvider segments={standardSet.segmentsTree.segments}>
            {viewMode === ViewMode.Grid ? (
              <StandardSetGrid {...childProps} />
            ) : (
              <StandardSetList {...childProps} />
            )}
          </ExploreStandardSetsContextProvider>
        </Block>
      </>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'That standard set could not be found.'} />
  }
}

const IconWrapper = styled.div`
  width: 128px;
  height: 128px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.theme['@size-l']};
  background: ${props => props.theme['@gray-1']};
  color: ${props => props.theme['@gray-4']};
  font-size: 90px;
  &:empty {
    display: none;
  }
`
