import { Result } from '@thesisedu/react'
import { HSpaced, LoadingIndicator, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { SegmentContextProvider } from '../../contexts/SegmentContext'
import { CourseSelect } from '../../explore/CourseSelect'
import { TermSelect } from '../../outline/TermSelect'
import { useCourseQuery } from '../../schema'
import { BreadcrumbSeparator } from '../BreadcrumbSeparator'
import { PlacementOutline } from '../PlacementOutline'
import { PlacementPaneImperativeHandle } from '../types'
import { useSelectedTermId } from '../useSelectedTermId'

export interface ExplorePaneProps {
  defaultCourseId?: string
}
function _ExplorePane(
  { defaultCourseId }: ExplorePaneProps,
  ref: React.ForwardedRef<PlacementPaneImperativeHandle>,
) {
  const [selectedCourseId, setSelectedCourseId] = React.useState<string | undefined>(
    defaultCourseId,
  )
  const { data, loading } = useCourseQuery({
    variables: { id: selectedCourseId ?? '' },
    skip: !selectedCourseId,
  })
  const course = data?.node?.__typename === 'Course' ? data.node : undefined
  const configuration = course?.latestPublishedVersion?.configuration
  const [selectedTermId, setSelectedTermId] = useSelectedTermId(configuration)
  return (
    <VSpaced space={'s'} style={{ flex: 1 }}>
      <HSpaced>
        <CourseSelect
          style={{ width: 250 }}
          value={selectedCourseId}
          onValueChange={setSelectedCourseId}
          placeholder={'Select a Course...'}
        />
        {selectedCourseId && configuration ? (
          <>
            <BreadcrumbSeparator />
            <TermSelect
              value={selectedTermId}
              onChange={setSelectedTermId}
              configuration={configuration}
            />
          </>
        ) : null}
      </HSpaced>
      {loading ? (
        <LoadingIndicator.Labeled label={'Loading course...'} />
      ) : configuration && selectedCourseId ? (
        <SegmentContextProvider courseConfiguration={configuration}>
          <PlacementOutline
            configuration={configuration}
            courseOrClassId={selectedCourseId}
            selectedTermId={selectedTermId}
            setSelectedTermId={setSelectedTermId}
            droppableDisabled
            sortableId={`explore-${selectedCourseId}-${selectedTermId}`}
            ref={ref}
          />
        </SegmentContextProvider>
      ) : selectedCourseId ? (
        <Result.Error description={'There was an error loading that course.'} />
      ) : null}
    </VSpaced>
  )
}
export const ExplorePane = React.forwardRef(_ExplorePane)
