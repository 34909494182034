import { styled, s, Text, Avatar, Block } from '@thesisedu/ui'
import { NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { SiteTeacherProps } from './types'

const Container = styled.div`
  padding: ${s.size('xs')} 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`
const RightContainer = styled.div`
  margin-left: auto;
  padding-left: ${s.size('s')};
  color: ${s.color('text')};
`

export const SiteTeacher: React.FC<React.PropsWithChildren<SiteTeacherProps>> = ({
  user,
  rightContent,
  onClick,
}) => {
  const name = (user.name || user.username)
    .split(' ')
    .map(item => item[0])
    .join('')
  return (
    <Container onClick={onClick}>
      <Avatar>{name}</Avatar>
      <Block left={'xs'}>
        <Text>{user.name || user.username}</Text>
      </Block>
      <RightContainer>{rightContent || <NavArrowRight />}</RightContainer>
    </Container>
  )
}
