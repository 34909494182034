import { styled } from '@thesisedu/react'
import { transparentize } from 'polished'
import React from 'react'

import { VideoView, VideoViewProps } from './VideoView'
import { useSegmentCompletion } from '../../../course_viewer/completion/SegmentCompletion'
import { useSegmentCompletionHandler } from '../../../course_viewer/completion/useSegmentCompletion'
import { useSegmentContext } from '../../../segment/Segment'
import { Segment } from '../../../types'

export interface VideoViewWithCompletionProps extends Omit<VideoViewProps, 'children'> {}
export function VideoViewWithCompletion(props: VideoViewWithCompletionProps) {
  const segment = useSegmentContext(false)
  const canShowCompletion = useSegmentCompletionHandler()
  const { host: segmentCompletionHost } = useSegmentCompletion({
    segment: segment as Segment | undefined,
  })
  return (
    <VideoView {...props}>
      {({ isComplete }) =>
        canShowCompletion ? (
          <CompletionWrapper visible={isComplete}>
            {isComplete && segment ? segmentCompletionHost : null}
          </CompletionWrapper>
        ) : (
          <span />
        )
      }
    </VideoView>
  )
}

const CompletionWrapper = styled.div<{ visible?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transition: opacity 0.25s linear;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  &:empty {
    pointer-events: none;
  }
  > div {
    height: 100%;
    .segment-completion-container {
      height: 100%;
      background: ${props => transparentize(0.15, props.theme['@gray-6'])};
      backdrop-filter: blur(5px);
      margin: 0 !important;
    }
  }
`
