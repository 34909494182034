import {
  isMatchClientConfig,
  MatchAnswer,
  MatchClientConfig,
  matchToClientConfig,
} from '@thesisedu/feature-assignments-core'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { DragDropAnswerView } from '../DragDropAnswerView'

export function GroupAnswer({ question }: QuestionProps<MatchClientConfig>) {
  const answer = useSubmissionData(question.id) as MatchAnswer[] | undefined
  const config = question.config
  if (!config) return null
  const clientConfig = isMatchClientConfig(config) ? config : matchToClientConfig(config, false)
  return (
    <DragDropAnswerView
      selectedAnswers={answer}
      answer={clientConfig.answer}
      destinations={clientConfig.destinations || []}
      allowMultipleInDestinations
    />
  )
}
