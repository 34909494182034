import { FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { addSearch } from '@thesisedu/feature-search-react'
import React from 'react'

export interface DistrictsWebOptions {}
export class DistrictsWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-districts-web'
  static path = ''
  static requires: string[] = []
  public options!: DistrictsWebOptions

  reactResources() {
    require('./hooks/routes').default(this)
    const { SearchResult } = require('./SearchResult')
    addSearch(this, '...on District { districtName: name }', 'District', {
      renderList: props => <SearchResult {...props} searchResultPath={'/admin/districts/:id'} />,
    })
  }
}

export const districtsWebFeature = (opts: Partial<DistrictsWebOptions> = {}): FeatureUse => [
  DistrictsWebFeature,
  opts,
]
