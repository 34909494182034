import { MinimalClassFragment } from '@thesisedu/feature-classes-react'
import { normalizeString, notNil } from '@thesisedu/feature-utils'
import { Block, Button, HSpaced, Text, VSpaced } from '@thesisedu/ui'
import { orderBy } from 'lodash'
import React from 'react'

import { RosteringContext, RosteringContextValue } from './RosteringContext'
import { RosteringGroupHeader } from './RosteringGroupHeader'
import { RosteringHeader, RosteringHeaderProps } from './RosteringHeader'
import { RosteringListItem } from './RosteringListItem'
import {
  ClassSyncRosterActionFragment,
  ClassSyncRosterActionInput,
  ClassSyncRosterActionMode,
} from '../../schema'

export interface RosteringProps extends Omit<RosteringHeaderProps, 'className'> {
  defaultUpdates: ClassSyncRosterActionFragment[]
  cls: MinimalClassFragment
  highlightUserIds?: string[]
  loading?: boolean
  onComplete: (updates: ClassSyncRosterActionInput[]) => void
  noImport?: boolean
}
export function Rostering({
  defaultUpdates,
  cls,
  loading,
  highlightUserIds,
  onComplete,
  noImport,
  ...headerProps
}: RosteringProps) {
  const [_updates, setUpdates] = React.useState(defaultUpdates)
  const providerStudents = React.useMemo(() => {
    const result: RosteringContextValue['providerStudents'] = []
    const seen = new Set<string>()
    for (const update of defaultUpdates) {
      if (update.providerStudent && !seen.has(update.providerStudent.id)) {
        seen.add(update.providerStudent.id)
        result.push(update.providerStudent)
      }
    }

    return result
  }, [defaultUpdates])

  const updates = React.useMemo<typeof _updates>(() => {
    if (noImport) {
      return _updates.filter(
        update =>
          (update.mode || update.student) && update.mode !== ClassSyncRosterActionMode.Import,
      )
    } else return _updates
  }, [_updates, noImport])

  const importCount = updates.filter(up => up.mode === ClassSyncRosterActionMode.Import).length
  const hasImports = !!importCount
  const hasMatches = updates.some(up => up.mode === ClassSyncRosterActionMode.Match)
  const removeCount = updates.filter(up => up.mode === ClassSyncRosterActionMode.Remove).length
  const allImports = updates.every(up => up.mode === ClassSyncRosterActionMode.Import)
  const saveText =
    allImports || (hasImports && !hasMatches)
      ? 'Import Students'
      : hasImports && hasMatches
      ? 'Save Matches and Import'
      : hasMatches
      ? 'Save Matches'
      : 'Continue'

  const groupedUpdates = React.useMemo<
    { updates: ClassSyncRosterActionFragment[]; group?: string }[]
  >(() => {
    const groups: Record<string | 'ungrouped', ClassSyncRosterActionFragment[]> = {}
    for (const update of updates) {
      const group = update.providerStudent?.group ?? 'ungrouped'
      if (!groups[group]) groups[group] = []
      groups[group].push(update)
    }

    const ungrouped: ClassSyncRosterActionFragment[] = groups['ungrouped']
    if (Object.keys(groups).filter(group => group !== 'ungrouped').length === 1)
      return [{ updates }]
    const restGroups = Object.keys(groups)
      .map(groupKey => {
        if (groupKey !== 'ungrouped') return { group: groupKey, updates: groups[groupKey] }
        else return null
      })
      .filter(notNil)
    return [...restGroups, ...(ungrouped?.length ? [{ updates: ungrouped }] : [])]
  }, [updates])
  const onChange = React.useCallback(
    (newUpdate: ClassSyncRosterActionFragment) => {
      setUpdates(ups => {
        const missingProviderStudentIds = new Set(providerStudents.map(s => s.id))
        const updates = ups
          .map(up => {
            const upId = up.student?.id ?? up.providerStudent?.id
            const newUpId = newUpdate.student?.id ?? newUpdate.providerStudent?.id
            let result
            if (upId === newUpId) {
              if (newUpdate.providerStudent)
                missingProviderStudentIds.delete(newUpdate.providerStudent.id)
              result = newUpdate
            } else if (
              newUpdate.providerStudent &&
              up.providerStudent?.id === newUpdate.providerStudent?.id
            ) {
              missingProviderStudentIds.delete(newUpdate.providerStudent.id)
              // Remove any duplicate provider students.
              result = up.student
                ? {
                    ...up,
                    providerStudent: undefined,
                    mode: up.mode === ClassSyncRosterActionMode.Match ? undefined : up.mode,
                  }
                : undefined
            } else {
              if (up.providerStudent) missingProviderStudentIds.delete(up.providerStudent.id)
              result = up
            }

            // Remove any "import" operations where the email matches that of a removed
            // student.
            if (result && result.mode === ClassSyncRosterActionMode.Remove) {
              const email = normalizeString(result.student?.user.email ?? '')
              const matchingId = providerStudents.find(
                student => normalizeString(student.userInfo.email) === email,
              )?.id
              if (matchingId) missingProviderStudentIds.delete(matchingId)
            }

            return result
          })
          .filter(Boolean) as ClassSyncRosterActionFragment[]
        return orderBy(
          [
            ...updates,
            ...[...missingProviderStudentIds].map(missingId => {
              const entry = providerStudents.find(s => s.id === missingId)
              if (!entry) throw new Error(`Cannot find provider student '${missingId}'`)
              return {
                mode: ClassSyncRosterActionMode.Import,
                providerStudent: entry,
              }
            }),
          ] as ClassSyncRosterActionFragment[],
          update => {
            if (update.student) {
              return `0-${update.student.user?.lastName ?? update.student.user?.username ?? ''}`
            } else {
              return `0-${update.providerStudent?.userInfo.lastName ?? ''}-1`
            }
          },
          'asc',
        )
      })
    },
    [setUpdates],
  )

  return (
    <RosteringContext.Provider value={{ providerStudents }}>
      <VSpaced space={false} style={{ flex: 1, minHeight: 0 }}>
        <RosteringHeader {...headerProps} className={cls.name} />
        <VSpaced space={'xxs'} align={'stretch'} style={{ overflow: 'auto' }}>
          {groupedUpdates.map(group => {
            return (
              <Block top={'m'} key={group.group ?? 'ungrouped'}>
                {group.group ? (
                  <RosteringGroupHeader
                    onChange={onChange}
                    updates={group.updates}
                    group={group.group}
                  />
                ) : null}
                {group.updates.map(update => {
                  return (
                    <RosteringListItem
                      key={update.student?.id ?? update.providerStudent?.id}
                      update={update}
                      highlighted={highlightUserIds?.includes(update.student?.user?.id ?? '')}
                      onChange={onChange}
                    />
                  )
                })}
              </Block>
            )
          })}
        </VSpaced>
        <HSpaced top={'s'}>
          {importCount || removeCount ? (
            <Text style={{ marginRight: 'auto' }} level={'s'}>
              You are about to{' '}
              {importCount ? (
                <>
                  <Text level={null} color={'green'} weight={'bold'} inline>
                    import {importCount} student{importCount === 1 ? '' : 's'}&nbsp;
                  </Text>
                  <Text
                    as={'a'}
                    onClick={e => {
                      e.preventDefault()
                      setUpdates(updates => {
                        return updates.map(up => {
                          if (up.mode === ClassSyncRosterActionMode.Import) {
                            return {
                              ...up,
                              mode: undefined,
                            }
                          } else {
                            return up
                          }
                        })
                      })
                    }}
                    level={null}
                    color={'secondary'}
                    inline
                  >
                    (click here to not import any students)
                  </Text>
                </>
              ) : null}
              {importCount && removeCount ? ' and ' : null}
              {removeCount ? (
                <Text level={null} color={'red'} weight={'bold'} inline>
                  remove {removeCount} student{removeCount === 1 ? '' : 's'}
                </Text>
              ) : null}
            </Text>
          ) : null}
          <Button
            variant={'primary'}
            children={saveText}
            style={{ whiteSpace: 'nowrap', flexShrink: 0, marginLeft: 'auto' }}
            loading={loading}
            onPress={() => {
              onComplete(
                updates
                  .filter(update => !!update.mode)
                  .map<ClassSyncRosterActionInput>(up => {
                    return {
                      mode: up.mode!,
                      providerStudentId: up.providerStudent?.id,
                      studentId: up.student?.id,
                    }
                  }),
              )
            }}
          />
        </HSpaced>
      </VSpaced>
    </RosteringContext.Provider>
  )
}
