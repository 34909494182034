import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import {
  QuestionInstructions,
  QuestionProps,
  useSubmissionData,
} from '@thesisedu/feature-assignments-react'
import {
  BookmarksPlugin,
  CommentsPlugin,
  MediaViewWithSidebar,
} from '@thesisedu/feature-media-react'
import { Block, BodySmall, styled } from '@thesisedu/web'
import { Slider } from 'antd'
import React from 'react'

import {
  getAnnotatedAnswerRanges,
  INSTRUCTIONS,
  SheetMusicDragDropConfig,
  SheetMusicDragDropAnswer as TSheetMusicDragDropAnswer,
} from './types'
import { SafeSheetMusicViewer } from '../../../viewer/SafeSheetMusicViewer'
import { NoteItemContextValue } from '../../../viewer/types'

const SHORTER_STYLE: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}

export function SheetMusicDragDropAnswer(props: QuestionProps<SheetMusicDragDropConfig>) {
  const submission: TSheetMusicDragDropAnswer | undefined = useSubmissionData(props.question.id)
  const hideAnswer =
    !props.answerView ||
    (typeof props.answerView === 'object' && props.answerView.hideCorrectAnswer)
  const answer = props.question.config?.answer
  const submissionAbc = typeof submission === 'string' ? submission : submission?.abc
  const mediaId = typeof submission !== 'string' ? submission?.mediaId : undefined
  const submissionLonger = submissionAbc && answer && submissionAbc.length > answer.length
  const [answerOpacity, setAnswerOpacity] = React.useState(submission ? 0 : 1)
  React.useEffect(() => {
    setAnswerOpacity(submission ? 0 : 1)
  }, [submission])
  const noteItemContexts = React.useMemo<
    [NoteItemContextValue | undefined, NoteItemContextValue | undefined]
  >(() => {
    const annotationResult =
      submissionAbc && answer ? getAnnotatedAnswerRanges(answer, submissionAbc) : undefined
    if (annotationResult) {
      return [
        {
          noteTopContent: {
            height: 32,
            render: () => null, // This is to make sure it lines up with the submission content.
          },
        },
        {
          noteTopContent: {
            height: 32,
            render: noteItem => {
              const correct = annotationResult.submissionAnnotations.find(anno => {
                return anno.startChar === noteItem.startChar && anno.endChar === noteItem.endChar
              })?.correct
              if (correct) {
                return (
                  <IndicatorContainer color={'@green'}>
                    <CheckCircleFilled />
                  </IndicatorContainer>
                )
              } else if (correct === false) {
                return (
                  <IndicatorContainer color={'@red'}>
                    <CloseCircleFilled />
                  </IndicatorContainer>
                )
              } else {
                return null
              }
            },
          },
        },
      ]
    } else {
      return [undefined, undefined]
    }
  }, [submission, answer])
  return (
    <QuestionInstructions instructions={INSTRUCTIONS} padded>
      {answer && submission && !hideAnswer ? (
        <Block marginBottom={'@size-s'} style={{ maxWidth: 300 }}>
          <BodySmall color={'@text-color-secondary'}>
            Correct Answer Visibility&nbsp;&nbsp;&nbsp;
            <a
              href={'#'}
              onClick={e => {
                e.preventDefault()
                setAnswerOpacity(answerOpacity === 1 ? 0 : 1)
              }}
            >
              {answerOpacity === 1 ? 'Hide' : 'Show'}
            </a>
          </BodySmall>
          <Slider
            value={answerOpacity}
            onChange={setAnswerOpacity}
            min={0}
            max={1}
            step={0.01}
            tooltipVisible={false}
          />
        </Block>
      ) : null}
      <SheetMusicContainer>
        {answer && !hideAnswer ? (
          <SheetMusicAnswerContainer
            style={{
              ...(submissionLonger ? SHORTER_STYLE : undefined),
              opacity: answerOpacity,
              // No playback allowed if there is a submission
              pointerEvents: submission ? 'none' : undefined,
            }}
          >
            <SafeSheetMusicViewer abc={answer} noteItemContext={noteItemContexts[0]} />
          </SheetMusicAnswerContainer>
        ) : null}
        {submission && submissionAbc ? (
          <SheetMusicSubmissionContainer
            style={!answer || hideAnswer || submissionLonger ? undefined : SHORTER_STYLE}
          >
            <SafeSheetMusicViewer
              abc={submissionAbc}
              playbackContainerProps={{
                defaultSelectedInstrumentKey:
                  typeof submission === 'object' ? submission.selectedInstrument : undefined,
              }}
              noteItemContext={noteItemContexts[1]}
            />
          </SheetMusicSubmissionContainer>
        ) : null}
      </SheetMusicContainer>
      {mediaId ? (
        <Block marginTop={'@size-m'}>
          <MediaViewWithSidebar mediaId={mediaId}>
            <CommentsPlugin />
            <BookmarksPlugin />
          </MediaViewWithSidebar>
        </Block>
      ) : null}
    </QuestionInstructions>
  )
}

const IndicatorContainer = styled.div<{ color: string }>`
  color: ${props => props.theme[props.color]};
  font-size: 24px;
  padding-left: ${props => props.theme['@size-xs']};
`
const SheetMusicContainer = styled.div`
  position: relative;
  z-index: 9;
`
const SheetMusicAnswerContainer = styled.div`
  z-index: 6;
  position: relative;
`
const SheetMusicSubmissionContainer = styled.div`
  position: relative;
  z-index: 5;
`
