import { useViewerContext } from '@thesisedu/feature-users-web'
import { HSpaced } from '@thesisedu/react'
import { Button } from '@thesisedu/ui'
import { MoreVert } from '@thesisedu/ui/icons'
import React from 'react'

import { isTeacherInfoSegment } from '../helpers'
import { SegmentActions } from '../outline/SegmentActions'
import { SegmentLockEnable } from '../outline/SegmentLockEnable'
import { Segment } from '../types'

export interface CourseViewerSegmentHeaderActionsProps {
  actions?: React.ReactElement
  segment: Segment
}
export function CourseViewerSegmentHeaderActions({
  actions,
  segment,
}: CourseViewerSegmentHeaderActionsProps) {
  const viewer = useViewerContext(false)
  const children = actions ? (
    actions
  ) : viewer?.group === 'TEACHER' ? (
    <>
      {!isTeacherInfoSegment(segment) && !segment.config.alwaysEnabled ? (
        <SegmentLockEnable segmentId={segment.id} disabled={segment.notInLicense} />
      ) : null}
      <SegmentActions segment={segment}>
        <Button size={'small'} icon={<MoreVert />} />
      </SegmentActions>
    </>
  ) : null
  return children ? <HSpaced space={'@size-xs'} children={children} /> : null
}
