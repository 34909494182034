import React from 'react'

import { ImageAttribution, ImageProps } from './Image'
import { styled, s } from '../..'

export interface AttributionProps extends Omit<ImageAttribution, 'text'> {
  children: React.ReactNode
  radius: ImageProps['radius']
}
export function Attribution({ align, placement, side, radius = null, children }: AttributionProps) {
  return (
    <AttributionContainer
      data-align={align}
      data-placement={placement}
      data-side={side}
      style={{ '--radius': radius !== null ? s.var(`radii.${radius}`) : '0' } as any}
    >
      <div children={children} />
    </AttributionContainer>
  )
}

const AttributionContainer = styled.div`
  position: absolute;
  display: flex;
  overflow: visible;
  pointer-events: none;
  > div {
    padding: ${s.size('xs')} var(--radius);
    font-size: ${s.size('font.xs')};
    color: ${s.color('secondary')};
    line-height: 1.2;
    display: inline-block;
  }
  &[data-side='left'],
  &[data-side='right'] {
    > div {
      padding: var(--radius) ${s.size('xs')};
    }
  }
  &[data-placement='inside'] {
    overflow: hidden;
    height: auto;
    display: flex;
    flex-direction: column;
    &[data-align='start'] {
      align-items: flex-start;
      &[data-side='left'] {
        align-items: flex-end;
      }
    }
    &[data-align='end'] {
      align-items: flex-end;
      &[data-side='left'] {
        align-items: flex-start;
      }
    }
    &[data-side='top'] {
      border-top-left-radius: var(--radius);
      border-top-right-radius: var(--radius);
    }
    &[data-side='bottom'] {
      border-bottom-left-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
    &[data-side='right'] {
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
    &[data-side='left'] {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
      > div {
        transform: rotate(180deg);
      }
    }
    > div {
      background: ${s.opacity(
        s.getThemeValue(s.colorToThemePath('element', 'dark')).toString(),
        0.65,
      )};
      color: ${s.getThemeValue(s.colorToThemePath('text', 'dark'))};
      width: fit-content;
      max-width: 100%;
    }
  }
  &[data-align='start'] {
    text-align: left;
  }
  &[data-align='end'] {
    text-align: right;
  }
  &[data-side='top'],
  &[data-side='bottom'] {
    left: 0;
    right: 0;
  }
  &[data-side='left'],
  &[data-side='right'] {
    top: 0;
    bottom: 0;
    writing-mode: vertical-rl;
  }
  &[data-placement='outside'] {
    &[data-side='top'],
    &[data-side='bottom'] {
      height: 0;
      flex-direction: column;
    }
    &[data-side='right'],
    &[data-side='left'] {
      width: 0;
      flex-direction: row;
      > div {
        width: fit-content;
      }
      &[data-side='left'] {
        &[data-align='start'] {
          justify-content: flex-end;
        }
        > div {
          transform: rotate(180deg);
          transform-origin: center center;
        }
      }
      &[data-side='right'] {
        &[data-align='end'] {
          justify-content: flex-end;
        }
        > div {
          transform: translateX(100%);
        }
      }
    }
  }
  &[data-side='top'] {
    &[data-placement='outside'] {
      flex-direction: column-reverse;
    }
    top: 0;
  }
  &[data-side='bottom'] {
    bottom: 0;
    &[data-placement='outside'] {
      position: static;
      height: auto;
      > div {
        padding-bottom: 0;
      }
    }
  }
  &[data-side='left'] {
    left: 0;
  }
  &[data-side='right'] {
    right: 0;
  }
`
