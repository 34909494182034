import { BuiltSegmentFragment } from '../../schema'
import { useSplitViewOutlineItemContext } from '../SplitViewOutlineItemContext'
import { useSplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'
import { getForeignOutlineItem } from '../getForeignOutlineItem'

export function usePlacementDrawerForeignSegment(segment: BuiltSegmentFragment) {
  const { foreignSegmentsRef } = useSplitViewPendingChangesContext(true)
  const { items } = useSplitViewOutlineItemContext(true)
  if (!foreignSegmentsRef.current[segment.built.id]) {
    foreignSegmentsRef.current[segment.built.id] = segment.built
  }
  if (!items.current[segment.built.id]) {
    items.current[segment.built.id] = getForeignOutlineItem(segment.built)
  }
}
