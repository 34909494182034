import { media, styled, Modal, ModalProps } from '@thesisedu/web'

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`
export const ContainerLeft = styled.div`
  width: 300px;
  transition: width 0.25s ease-in-out;
  overflow-x: visible;
  position: relative;
  background: ${props => props.theme['@background-color-base']};
  > div.content {
    ${media.md} {
      display: flex;
      height: 100%;
    }
    width: 300px;
    flex-direction: column;
    align-items: stretch;
    > .course-modal-position {
      width: 300px;
      transition:
        width 0.25s ease-in-out,
        padding-left 0.25s ease-in-out,
        padding-right 0.25s ease-in-out;
      overflow: hidden;
      > .inner {
        width: calc(300px - 2 * ${props => props.theme['@size-l']});
      }
    }
    > :not(.course-modal-position),
    > .course-modal-position * {
      opacity: 1;
      transition: opacity 0.1s linear 0.25s;
    }
  }
  &:hover .button {
    opacity: 1;
  }
  &.collapsed {
    transition-delay: 0.1s;
    width: ${props => props.theme['@size-m']};
    .button {
      transform: rotate(180deg);
      opacity: 1;
    }
    > div.content {
      pointer-events: none;
      > .course-modal-position {
        transition-delay: 0.1s;
        width: ${props => props.theme['@size-m']};
        padding-left: 0;
        padding-right: 0;
      }
      > :not(.course-modal-position),
      > .course-modal-position * {
        transition-delay: 0s;
        opacity: 0 !important;
      }
    }
  }
`
export const ExpandCollapseContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-m']};
  width: auto !important;
  right: 0;
  bottom: 0;
  z-index: 12;
  margin-right: -14px;
  height: fit-content;
  &:hover .button {
    opacity: 1;
  }
`
export const ExpandCollapseButton = styled.button`
  transition:
    color 0.1s linear,
    background 0.1s linear,
    transform 0.25s ease-in-out,
    opacity 0.1s linear;
  border: none;
  outline: none;
  opacity: 0;
  background: ${props => props.theme['@gray-3']};
  color: ${props => props.theme['@gray-5']};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.theme['@shadow-small']};
  width: 28px;
  height: 28px;
  font-size: ${props => props.theme['@size-s']};
  border-radius: 50%;
  &:hover {
    background: ${props => props.theme['@gray-4']};
    color: ${props => props.theme['@gray-7']};
  }
`
export const PaddedContainer = styled.div`
  padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-m']}
    ${props => props.theme['@size-l']} ${props => props.theme['@size-l']};
`
export const ContainerRight = styled(PaddedContainer)`
  overflow-y: auto;
  flex: 1;
  min-height: 0;
`

export const StyledModal: React.FC<React.PropsWithChildren<ModalProps>> = styled(Modal)`
  top: 0;
  width: 100% !important;
  max-width: none;
  margin: 0;
  padding-bottom: 0;
  .ant-modal-body {
    height: var(--app-height);
  }
  .ant-modal-content {
    border-radius: 0 !important;
    overflow: hidden;
    transform: translateZ(0); // Safari hack to make the border radius work.
  }
  > .ant-modal-content > .ant-modal-close {
    right: ${props => props.theme['@size-m']} !important;
  }
`
