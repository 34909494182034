import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import {
  BookmarksPlugin,
  MetronomePlugin,
  PitchPlugin,
  RecordView,
  RecordViewProps,
} from '@thesisedu/feature-media-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCreatePracticeMutation } from '../queries/useCreatePracticeMutation'
import { PracticeFragment } from '../schema'

export interface CreatePracticeProps extends Partial<RecordViewProps> {
  extraCreateInput?: object
  dontNavigate?: boolean
}
export const CreatePractice: React.FC<React.PropsWithChildren<CreatePracticeProps>> = ({
  extraCreateInput,
  dontNavigate,
  ...props
}) => {
  const classId = useSelectedClassId()
  const navigate = useNavigate()
  const [createPractice] = useCreatePracticeMutation()

  return (
    <RecordView
      label={'Practice'}
      onCreated={result => {
        if (result.practice && !dontNavigate) {
          const practice = result.practice as PracticeFragment
          navigate(`/student/practices/${practice.id}`)
        }
      }}
      classId={classId || undefined}
      {...props}
      getMediaUploadUrl={async input => {
        try {
          const result = await createPractice({
            variables: {
              input: {
                classId,
                media: input,
                ...extraCreateInput,
              },
            },
          })
          if (result?.data?.createPractice) {
            return {
              media: result.data.createPractice.uploadPayload,
              practice: result.data.createPractice.practice,
            }
          } else {
            return null
          }
        } catch (errs: any) {
          if ((errs.graphQLErrors || [])[0]?.extensions?.code === 'LICENSE_INVALID_ERROR') {
            return {
              errors: errs.graphQLErrors,
            }
          } else {
            onMutationError('There was an error creating that practice.')(errs)
            return null
          }
        }
      }}
    >
      <BookmarksPlugin />
      <MetronomePlugin />
      <PitchPlugin />
    </RecordView>
  )
}
