import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Walking } from '@thesisedu/ui/icons'
import React from 'react'

import { TourElement } from './TourElement'
import { TourSettingsModal } from './TourSettingsModal'
import { TourConfig } from './common'

export default {
  identifier: 'Tour',
  title: 'Tour',
  icon: <Walking />,
  weight: 42,
  group: SimpleWidget.ElementsGroup.Other,
  defaultConfig: { version: 1, tour: { steps: [] }, name: 'My Tour' },
  hidden({ group }) {
    return group !== 'ADMIN'
  },
  element: TourElement,
  settingsModal: TourSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<TourConfig, 'Tour'>
