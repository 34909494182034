import { ClassRouteHook } from '@thesisedu/feature-classes-react'
import React from 'react'

import { ClassRoute } from './ClassRoute'
import { CoursesReactFeature } from '../../../CoursesReactFeature'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<ClassRouteHook>(
    'classes-react:class-route',
    (existing, { group }) => <ClassRoute group={group} />,
  )
}
