import { useCachedStudentUserCallback } from '@thesisedu/feature-classes-react'
import { Format } from '@thesisedu/feature-utils'
import {
  BodySmall,
  Empty,
  FontWeight,
  GridContainer,
  HSpaced,
  LoadingIndicator,
  Space,
  styled,
} from '@thesisedu/react'
import React from 'react'

import { useAssignmentSubmissionsQuery } from '../schema'

export interface GeneratedAssignmentProposedSubmissionsGridProps {
  assignmentId: string
}
export function GeneratedAssignmentProposedSubmissionsGrid({
  assignmentId,
}: GeneratedAssignmentProposedSubmissionsGridProps) {
  const getStudent = useCachedStudentUserCallback()
  const { data, loading } = useAssignmentSubmissionsQuery({
    variables: {
      id: assignmentId,
      first: 300,
    },
  })
  const submissions =
    data?.node?.__typename === 'Assignment'
      ? data.node.submissions?.edges.map(edge => edge.node)
      : undefined

  if (loading) {
    return <LoadingIndicator block />
  } else if (submissions?.length) {
    return (
      <GridContainer columnSize={200} gap={'@size-s'} rowGap={'@size-xs'}>
        {submissions.map(submission => {
          const student = getStudent(submission.studentId, submission.classId)
          if (!student) return null
          return (
            <HSpaced space={'@size-xs'} key={submission.id}>
              <StudentName>{student.user.name || student.user.username}</StudentName>
              <Space />
              {submission.grade !== null && submission.grade !== undefined ? (
                <BodySmall weight={FontWeight.Bold}>
                  {Format.number(submission.grade, 'percentage')}
                </BodySmall>
              ) : null}
            </HSpaced>
          )
        })}
      </GridContainer>
    )
  } else {
    return <Empty description={'No proposed grades yet.'} />
  }
}

const StudentName = styled(BodySmall)`
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`
