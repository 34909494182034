import { Popover, useStatePropFallback } from '@thesisedu/ui'
import React from 'react'

import { DefaultSelectButtonChild } from './DefaultSelectButtonChild'
import { SelectButtonContent } from './SelectButtonContent'
import { SelectButtonProps } from './types'
import { useItemNames } from './useItemNames'
import { RequiredVariables } from '../loadMore'
import { Node } from '../schema'

export function SelectButton<Item extends Node, Data, Variables extends RequiredVariables>({
  selectedLabel = 'Selected',
  selectLabel = 'Select',
  disabled,
  addButton,
  children = DefaultSelectButtonChild,
  nodeDocument,
  open,
  onOpenChange,
  ...contentProps
}: SelectButtonProps<Item, Data, Variables>) {
  const [popoverVisible, setPopoverVisible] = useStatePropFallback(open, onOpenChange, false)
  const { itemNames, loading } = useItemNames({
    nodeDocument,
    getItemName: contentProps.getItemName,
    value: contentProps.value,
  })

  const label =
    itemNames?.length || loading ? (
      <>
        <strong style={{ marginRight: '0.5em' }}>{selectedLabel}</strong>
        <span>{itemNames.length ? itemNames.join(', ') : '--'}</span>
      </>
    ) : (
      selectLabel
    )
  const hasValue = (contentProps.value || [])
    .filter(Boolean)
    .some(val => Object.keys(val).length > 0)

  return (
    <>
      <Popover.Container open={popoverVisible} onOpenChange={setPopoverVisible}>
        <Popover.Content title={selectLabel} side={'bottom'} width={400}>
          <SelectButtonContent<Item, Data, Variables> {...contentProps} />
        </Popover.Content>
        <Popover.Trigger>
          {children({
            onClick: () => setPopoverVisible(true),
            onClear: () => (contentProps.onChange ? contentProps.onChange([], []) : undefined),
            hasValue,
            label,
            itemNames,
            disabled,
            hasAddButton: !!addButton,
          })}
        </Popover.Trigger>
      </Popover.Container>
      {addButton}
    </>
  )
}
