import { useAssignmentViewerContext } from '@thesisedu/feature-assignments-react'
import {
  CalculatedPreset,
  SegmentCalculationOptions,
  CourseQuestion,
} from '@thesisedu/feature-course-types'
import { useSegmentCache } from '@thesisedu/feature-courses-react'
import { Affix, HSpaced, styled } from '@thesisedu/web'
import { Popconfirm } from 'antd'
import pluralize from 'pluralize'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { useSegmentCalculationContext } from './SegmentCalculationContext'
import { SourceSegmentSelect } from './SourceSegmentSelect'
import { VaultAutoGradeWarning } from './VaultAutoGradeWarning'
import { VaultFilters } from './VaultFilters'
import { VaultQuestionList } from './VaultQuestionList'
import { VaultSettingsButton } from './VaultSettings'
import { ASSIGNMENT_KIND_TAG } from '../../types'

export interface EditorFilters {
  disabled?: boolean | null
  isCovered?: boolean | null
  isRecommended?: boolean | null
  autograde?: boolean | null
  name?: string | null
  assignmentKindName?: string | null
}

export function SegmentCalculationInnerEditor() {
  const { configuration, assignment } = useAssignmentViewerContext(true)
  const { updateOverrides, updateConfiguration } = useSegmentCalculationContext(true)
  const segmentCache = useSegmentCache()
  const calculationConfig = configuration.calculation as SegmentCalculationOptions | undefined
  if (!calculationConfig?.calculatedPreset) {
    throw new Error(
      'Cannot use SegmentCalculationInnerEditor without calculationConfig or a missing preset',
    )
  }
  const isRecommendedPreset = [
    CalculatedPreset.Recommended,
    CalculatedPreset.RecommendedCovered,
  ].includes(calculationConfig.calculatedPreset)
  const [filters, setFilters] = React.useState<EditorFilters>({
    disabled: calculationConfig.calculatedPreset !== CalculatedPreset.Manual ? false : null,
    isCovered: null,
    isRecommended: isRecommendedPreset ? true : null,
    autograde: null,
    name: null,
  })
  const enabledCount =
    configuration.questions.filter(question => question.disabled === false).length || 0
  const segmentIds = [
    ...new Set(configuration.questions.map((q: CourseQuestion) => q.segmentId)),
  ].filter(Boolean) as string[]
  const questions = React.useMemo(() => {
    const questions = configuration.questions as CourseQuestion[]
    return (
      questions
        .filter(question => {
          if (filters.disabled === true) return question.disabled === true
          else if (filters.disabled === false) return !question.disabled
          else return true
        })
        .filter(question => {
          if (filters.isCovered === true) return question.isCovered === true
          else if (filters.isCovered === false) return !question.isCovered
          else return true
        })
        .filter(question => {
          const isRecommended = question.config?.isRecommended
          if (filters.isRecommended === true) return isRecommended === true
          else if (filters.isRecommended === false) return !isRecommended
          else return true
        })
        .filter(question => {
          const isAutoGraded = question.autograde
          if (filters.autograde === true) return isAutoGraded === true
          else if (filters.autograde === false) return !isAutoGraded
          else return true
        })
        .filter(question => {
          if (filters.assignmentKindName) {
            const assignmentKindTags = question.segmentId
              ? segmentCache[question.segmentId]?.tags?.[ASSIGNMENT_KIND_TAG] || []
              : []
            return assignmentKindTags.includes(filters.assignmentKindName)
          } else return true
        })
        .filter(question => {
          if (filters.name) {
            const matchesName =
              question.name.toLowerCase().indexOf(filters.name.toLowerCase()) !== -1
            const segmentTags = question.segmentId
              ? segmentCache[question.segmentId]?.tags || {}
              : {}
            const matchesTag = Object.keys(segmentTags).reduce<boolean>((matches, tagKey) => {
              return (
                matches ||
                segmentTags[tagKey].reduce<boolean>((innerMatches, tagName) => {
                  return (
                    innerMatches ||
                    tagName.toLowerCase().indexOf(filters.name!.toLowerCase()) !== -1
                  )
                }, matches)
              )
            }, false)
            return matchesName || matchesTag
          } else return true
        }) || []
    )
  }, [configuration, filters])

  const onOverrideAll = (disabled: boolean) => {
    updateOverrides(
      questions.map(question => {
        return { id: question.id, disabled }
      }),
    )
  }
  const onRandomizeAll = () => {
    updateOverrides(
      questions.map(question => {
        return { id: question.id, weight: Math.random() }
      }),
    )
  }

  return (
    <>
      <AffixGlobal />
      <Affix offsetTop={'@size-xl'}>
        <HeaderContainer>
          <EditorSummary>
            {pluralize('question', questions.length, true)} found{' '}
            <SourceSegmentSelect
              segmentIds={segmentIds}
              config={calculationConfig}
              onConfigChange={config =>
                updateConfiguration({
                  ...assignment!.rawConfiguration,
                  calculation: config,
                })
              }
            >
              from{' '}
              {pluralize(
                'assignment',
                calculationConfig?.segmentIds?.length
                  ? calculationConfig.segmentIds.length
                  : segmentIds.length,
                true,
              )}
              .
            </SourceSegmentSelect>{' '}
            <a
              onClick={e => {
                e.preventDefault()
                onOverrideAll(false)
              }}
            >
              Enable All
            </a>{' '}
            &middot;{' '}
            <a
              onClick={e => {
                e.preventDefault()
                onOverrideAll(true)
              }}
            >
              Disable All
            </a>{' '}
            &middot;{' '}
            <Popconfirm
              title={'If you randomize all, you will lose your custom order.'}
              okText={'Yes, randomize.'}
              cancelText={'No, keep.'}
              okButtonProps={{ danger: true }}
              onConfirm={onRandomizeAll}
            >
              <a>Randomize</a>
            </Popconfirm>
            <div className={'right'}>
              {enabledCount} question{enabledCount === 1 ? '' : 's'} selected
            </div>
          </EditorSummary>
          <HSpaced space={'@size-xs'} style={{ flexWrap: 'wrap' }} align={'flex-end'}>
            <VaultFilters
              filters={filters}
              onFiltersChange={setFilters}
              configuration={calculationConfig}
            />
            <VaultSettingsButton
              config={calculationConfig}
              onConfigChange={config =>
                updateConfiguration({
                  ...assignment!.rawConfiguration,
                  calculation: config,
                })
              }
            />
          </HSpaced>
        </HeaderContainer>
      </Affix>
      <VaultAutoGradeWarning questions={configuration.questions as CourseQuestion[]} />
      <VaultQuestionList questions={questions} />
    </>
  )
}

const EditorSummary = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme['@text-color-secondary']};
  font-size: ${props => props.theme['@font-size-sm']};
  > .right {
    margin-left: auto;
  }
  > a {
    margin: 0 ${props => props.theme['@size-xxs']};
    color: ${props => props.theme['@primary-color']};
  }
`
const HeaderContainer = styled.div`
  padding: 0 ${props => props.theme['@size-l']} ${props => props.theme['@size-s']}
    ${props => props.theme['@size-l']};
  margin: 0 -${props => props.theme['@size-l']};
`
const AffixGlobal = createGlobalStyle`
  .ant-affix ${HeaderContainer} {
    margin-top: -${props => props.theme['@size-xl']};
    padding-top: ${props => props.theme['@size-xl']};
    background: white;
  }
`
