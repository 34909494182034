import { Question } from '@thesisedu/feature-assignments-core'
import { VSpaced } from '@thesisedu/react'
import { isNil } from 'lodash'
import React from 'react'

import { Grade } from '../../Grade'
import { ViewingAssignment } from '../../grading/types'
import { FullAssignmentSubmissionFragment } from '../../schema'
import { CommentViewer } from '../CommentViewer'

export interface QuestionGradeCommentProps {
  assignment: ViewingAssignment
  submission?: FullAssignmentSubmissionFragment
  question: Question
}
export function QuestionGradeComment({
  submission,
  question,
  assignment,
}: QuestionGradeCommentProps) {
  const grade = submission?.grades?.[question.id]
  const showGrade = !isNil(grade)
  const comment = submission?.comments?.[question.id]
  if (showGrade || comment) {
    return (
      <VSpaced space={'@size-xxs'} align={'flex-end'}>
        {showGrade ? (
          <Grade
            hideProgress
            grade={grade}
            totalPoints={
              assignment.rubric?.categories.find(c => c.categoryId === question.id)?.totalPoints
            }
          />
        ) : null}
        {comment ? <CommentViewer comment={comment} /> : null}
      </VSpaced>
    )
  } else return null
}
