import { useFeatureRoot } from '@thesisedu/feature-react'
import { useTheme } from '@thesisedu/react'
import { Page, Copy } from '@thesisedu/react/icons'
import { ExtraSmallButton } from '@thesisedu/web'
import React from 'react'

import { useCourseViewerModalContext } from '../../contexts/CourseViewerModalContext'
import { useSegment } from '../../contexts/SegmentContext'
import { SegmentIndicatorProps, SegmentIndicatorResource } from '../../outline/SegmentIndicators'

function CustomOrCopyIndicator({ segmentId }: SegmentIndicatorProps) {
  const { setSegmentId } = useCourseViewerModalContext(false) || {}
  const theme = useTheme()
  const name = useFeatureRoot().appOptions.name
  const segment = useSegment(segmentId)
  if (segment?.userId) {
    return (
      <ExtraSmallButton
        title={segment?.originalId ? `Copied from ${name}` : 'Custom Content'}
        onClick={() => {
          if (setSegmentId) {
            setSegmentId(segmentId, 'edit')
          }
        }}
        icon={segment?.originalId ? <Copy /> : <Page />}
        style={{
          background: segment?.originalId ? theme['@primary-light-color'] : theme['@gray-1'],
          color: segment?.originalId ? theme['@primary-color'] : theme['@gray-6'],
        }}
      />
    )
  } else {
    return null
  }
}

export const CustomOrCopy: SegmentIndicatorResource = {
  type: 'SegmentIndicator',
  identifier: 'CustomOrCopy',
  component: CustomOrCopyIndicator,
}
