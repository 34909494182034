import React from 'react'
import { useParams } from 'react-router-dom'

import { useClassSelector } from './ClassContext'

export function SelectedClassSync() {
  const { selectedClassId, setSelectedClassId } = useClassSelector()
  const params = useParams()
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        params.classId &&
        window.location.pathname.includes(params.classId) &&
        selectedClassId !== params.classId &&
        params.classId
      ) {
        setSelectedClassId(params.classId)
      }
    }, 250) // Debounce the change.
    return () => {
      clearInterval(timeout)
    }
  }, [params])

  return null
}
