import { s } from '@thesisedu/ui'
import { mix, readableColor } from 'polished'

import { AllMetricSummariesFragment, ReportMetricResource } from '../../../types'
import { ReportCell } from '../cells/getReportCells'

export enum CellColorMode {
  StandardDeviation = 'std',
  Percent = 'percent',
  Static = 'static',
  Heatmap = 'heatmap',
}
export const DEFAULT_CELL_COLOR_OPTS: CellColorOpts = {
  stdDistance: 1,
  mode: CellColorMode.StandardDeviation,
  percent: 10,
  above: 90,
  below: 10,
}
export interface CellColorOpts {
  mode: CellColorMode
  stdDistance: number
  percent: number
  above: number
  below: number
}
export interface CellColors {
  text?: string
  background?: string
}
export function getCellColors(
  cell: ReportCell,
  allResults: AllMetricSummariesFragment,
  metric: ReportMetricResource,
  { stdDistance, percent, above, below, mode }: CellColorOpts = DEFAULT_CELL_COLOR_OPTS,
): CellColors {
  let textColor = s.color('text')
  let backgroundColor: string | undefined = undefined
  if (cell.metricValue !== null && cell.metricValue !== undefined) {
    const std = allResults.std[metric.metricKey]
    const mean = allResults.average[metric.metricKey]
    const max = allResults.max[metric.metricKey]
    const min = allResults.min[metric.metricKey]
    if (mode === CellColorMode.StandardDeviation) {
      if (std !== undefined && std !== null && mean !== undefined && mean !== null && std > 0) {
        if (cell.metricValue >= mean + std * stdDistance) {
          textColor = s.color('blue')
        } else if (cell.metricValue <= mean - std * stdDistance) {
          textColor = s.color('red')
        }
      }
    } else if (mode === CellColorMode.Percent) {
      if (max !== undefined && max !== null && min !== undefined && min !== null && max !== min) {
        const delta = (max - min) * (percent / 100)
        if (cell.metricValue >= max - delta) {
          textColor = s.color('blue')
        } else if (cell.metricValue <= min + delta) {
          textColor = s.color('red')
        }
      }
    } else if (mode === CellColorMode.Static) {
      if (cell.metricValue >= above) {
        textColor = s.color('blue')
      } else if (cell.metricValue <= below) {
        textColor = s.color('red')
      }
    } else if (
      mode === CellColorMode.Heatmap &&
      max !== undefined &&
      max !== null &&
      min !== undefined &&
      min !== null &&
      max !== min
    ) {
      const percentage = (cell.metricValue - min) / (max - min)
      if (percentage >= 0.5) {
        backgroundColor = mix(
          (percentage - 0.5) * 2,
          s.getThemeValue(s.colorToThemePath('blue')).toString(),
          s.getThemeValue(s.colorToThemePath('background')).toString(),
        )
      } else {
        backgroundColor = mix(
          percentage * 2,
          s.getThemeValue(s.colorToThemePath('background')).toString(),
          s.getThemeValue(s.colorToThemePath('red')).toString(),
        )
      }
      try {
        textColor = readableColor(
          backgroundColor,
          s.getThemeValue(s.colorToThemePath('text')).toString(),
          s.getThemeValue(s.colorToThemePath('background')).toString(),
          true,
        )
      } catch {
        textColor = s.color('text')
      }
    }
  }

  return { text: textColor, background: backgroundColor }
}
