import { useMutateHook } from '@thesisedu/feature-react'
import { Dropdown } from '@thesisedu/ui'
import { LogOut, Settings } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { UserMenuItemsHook } from '../types'
import { useViewerContext } from '../useViewerContext'

export function UserMenu() {
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  const items = useMutateHook<UserMenuItemsHook>('feature-users-react:user-menu', [], { viewer })
  if (viewer) {
    return (
      <Dropdown.Container>
        <Dropdown.Button
          children={viewer.name || viewer.username}
          style={{ whiteSpace: 'nowrap', flexShrink: '0' }}
        />
        <Dropdown.Menu side={'bottom'} align={'end'} style={{ minWidth: 225 }}>
          {items.length ? (
            <>
              {items}
              <Dropdown.Item.Separator />
            </>
          ) : null}
          <Dropdown.Item
            onClick={() => navigate(`/${viewer.group?.toLowerCase()}/settings`)}
            icon={<Settings />}
          >
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={() => navigate('/auth/logout')} icon={<LogOut />}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown.Container>
    )
  } else {
    return null
  }
}
