import { Star } from '@thesisedu/ui/icons'
import React from 'react'

import { BookmarkFooterButton } from './BookmarkFooterButton'
import { BookmarksProvider } from './BookmarksContext'
import { BookmarksTab } from './BookmarksTab'
import { PlayerBookmarkBeforeFooter } from './PlayerBookmarkBeforeFooter'
import { PlayerBookmarkButton } from './PlayerBookmarkButton'
import { PlayerBookmarkTrackDecorator } from './PlayerBookmarkTrackDecorator'
import { PlaybackFooterLocation } from '../../record/PlaybackFooterContext'
import { FooterItem } from '../api/FooterItem'
import { PlaybackFooterItem } from '../api/PlaybackFooterItem'
import { SidebarTab } from '../api/SidebarTab'

export function BookmarksPlugin() {
  return (
    <BookmarksProvider>
      <FooterItem weight={0}>
        <BookmarkFooterButton />
      </FooterItem>
      <PlaybackFooterItem weight={0} location={PlaybackFooterLocation.Toolbar}>
        <PlayerBookmarkButton />
      </PlaybackFooterItem>
      <PlaybackFooterItem weight={0} location={PlaybackFooterLocation.Track}>
        <PlayerBookmarkTrackDecorator />
      </PlaybackFooterItem>
      <PlaybackFooterItem weight={0} location={PlaybackFooterLocation.BeforeFooter}>
        <PlayerBookmarkBeforeFooter />
      </PlaybackFooterItem>
      <SidebarTab weight={0} icon={<Star />} title={'Bookmarks'}>
        <BookmarksTab />
      </SidebarTab>
    </BookmarksProvider>
  )
}
