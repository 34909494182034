import { $wrapNodeInElement } from '@lexical/utils'
import { css } from '@thesisedu/react'
import { Callout, Callout$ } from '@thesisedu/ui'
import { Coin } from '@thesisedu/ui/icons'
import { ElementNode, LexicalNode, Spread, SerializedElementNode } from 'lexical'
import React from 'react'
import ReactDOM from 'react-dom'

export const CalloutNodeStyles = css`
  .Callout-node {
  }
`

export const CUSTOM_ICONS = {
  Transaction: <Coin />,
}

export type SerializedCalloutNodeV1 = Spread<
  {
    variant: Callout$.CalloutVariant
    customIcon?: keyof typeof CUSTOM_ICONS
    type: 'Callout'
    version: 1
  },
  SerializedElementNode
>

export class CalloutNode extends ElementNode {
  __variant: Callout$.CalloutVariant
  __customIcon?: keyof typeof CUSTOM_ICONS

  static getType(): string {
    return 'Callout'
  }

  static clone(node: CalloutNode): CalloutNode {
    return new CalloutNode(node.__variant, node.__customIcon, node.__key)
  }

  constructor(
    variant: Callout$.CalloutVariant,
    customIcon?: keyof typeof CUSTOM_ICONS,
    key?: string,
  ) {
    super(key)
    this.__variant = variant
    this.__customIcon = customIcon
  }

  setVariant(variant: Callout$.CalloutVariant) {
    const self = this.getWritable()
    self.__variant = variant
  }

  getVariant(): Callout$.CalloutVariant {
    const self = this.getLatest()
    return self.__variant
  }

  setCustomIcon(customIcon?: keyof typeof CUSTOM_ICONS) {
    const self = this.getWritable()
    self.__customIcon = customIcon
  }

  getCustomIcon(): keyof typeof CUSTOM_ICONS | undefined {
    const self = this.getLatest()
    return self.__customIcon
  }

  static importJSON({ variant, customIcon }: SerializedCalloutNodeV1): CalloutNode {
    return $createCalloutNode(variant, customIcon)
  }

  exportJSON(): SerializedCalloutNodeV1 {
    return {
      ...super.exportJSON(),
      variant: this.__variant,
      customIcon: this.__customIcon,
      type: 'Callout',
      version: 1,
    }
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    ReactDOM.flushSync(() => {
      ReactDOM.render(
        <Callout
          className={'fteditor-callout'}
          icon={this.__customIcon ? CUSTOM_ICONS[this.__customIcon] : undefined}
          variant={this.__variant}
        />,
        dom,
      )
    })
    if (!dom.children[0]) throw new Error('did not get first child')
    return dom.children[0] as HTMLElement
  }

  updateDOM(prevNode: CalloutNode, dom: HTMLElement): boolean {
    return prevNode.__variant !== this.__variant || prevNode.__customIcon !== this.__customIcon
  }
}

export function $createCalloutNode(
  variant: Callout$.CalloutVariant,
  customIcon?: keyof typeof CUSTOM_ICONS,
): CalloutNode {
  const node = new CalloutNode(variant, customIcon)
  return node
}

export function $wrapInCalloutNode(
  node: LexicalNode,
  variant: Callout$.CalloutVariant,
): ElementNode {
  return $wrapNodeInElement(node, () => new CalloutNode(variant))
}

export function $isCalloutNode(node: any): node is CalloutNode {
  return node instanceof CalloutNode
}
