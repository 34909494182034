import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

import { AuthMSALReactNativeOptions } from './types'

export class AuthMSALReactNativeFeature extends ReactFeature {
  static package = '@thesisedu/feature-auth-msal-react/native'
  static path = __dirname
  static requires = []
  public options!: AuthMSALReactNativeOptions

  public get redirectUri() {
    const { Platform } = require('react-native')
    return Platform.OS === 'ios' ? this.options.redirectUris.ios : this.options.redirectUris.android
  }
}

export const authMSALReactNativeFeature: ReactUse<AuthMSALReactNativeOptions> = opts => [
  AuthMSALReactNativeFeature,
  opts,
]
