import { RichTextPlugin as LexicalRichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import React from 'react'

import { useRichTextElementsMenuItems } from './ElementsMenu'
import { useRichTextToolbarItems } from './Toolbar'
import { usePrePasteCleanup } from './usePrePasteCleanup'

export const RichTextPlugin: typeof LexicalRichTextPlugin = props => {
  useRichTextElementsMenuItems()
  useRichTextToolbarItems()
  usePrePasteCleanup()

  return <LexicalRichTextPlugin {...props} />
}
