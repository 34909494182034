import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { isVisibleToStudent } from '@thesisedu/feature-course-types'
import { Dropdown } from '@thesisedu/ui'
import { CheckCircle, DeleteCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { useUpdateClassMutation } from '../../classes/useUpdateClassMutation'
import { useCourseContext } from '../../contexts/CourseContext'
import { overrideSegment } from '../../helpers'
import { SegmentType } from '../../schema'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function useMarkedAsComplete(segmentIds: string[]) {
  const { segmentMetadata = {} } = useCourseContext(true)
  return segmentIds.every(segmentId => {
    return segmentMetadata.segments?.some(
      override => override.id === segmentId && override.forceReportAsViewed,
    )
  })
}

export function useMarkAsComplete() {
  const cls = useSelectedClass(true)
  const [update] = useUpdateClassMutation(cls.id)
  const { segmentMetadata = {} } = useCourseContext(true)
  return async (segmentIds: string[], complete: boolean) => {
    let updatedMetadata = segmentMetadata
    for (const segmentId of segmentIds) {
      updatedMetadata = overrideSegment(
        segmentId,
        { forceReportAsViewed: complete },
        updatedMetadata,
      )
    }

    await update({ segmentMetadata: updatedMetadata }, false, false)
  }
}

function MarkComplete({ segment }: SegmentActionComponentProps) {
  const markAsComplete = useMarkAsComplete()
  const isComplete = useMarkedAsComplete([segment.id])
  return (
    <Dropdown.Item
      icon={isComplete ? <DeleteCircle /> : <CheckCircle />}
      onClick={() => {
        markAsComplete([segment.id], !isComplete)
      }}
    >
      {isComplete ? "Don't Mark as Complete" : 'Mark as Complete'}
    </Dropdown.Item>
  )
}

export const MarkCompleteAction: SegmentActionResource = {
  identifier: 'mark-complete',
  type: 'SegmentAction',
  component: MarkComplete,
  group: 'Enable & Disable',
  weight: 104,
  filter: segment => {
    return segment.type === SegmentType.Section && isVisibleToStudent(segment)
  },
  handler: () => {},
}
