import { Link } from '@thesisedu/ui/icons'
import React from 'react'

import { definition } from '.'
import { LinkEmbedWithUrl } from './LinkEmbedWithUrl'
import { LinkEmbedConfig } from './common'
import { SimpleWidget } from '../utils'

export function LinkEmbedElement({
  config,
  node,
}: SimpleWidget.SimpleWidgetProps<LinkEmbedConfig>) {
  const { url, alignment } = config
  if (url) {
    return <LinkEmbedWithUrl url={url} alignment={alignment} />
  } else {
    return (
      <SimpleWidget.WidgetEmptyState<LinkEmbedConfig>
        {...definition}
        node={node}
        buttonLabel={'Click to add a URL...'}
        icon={<Link />}
      />
    )
  }
}
