import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import {
  DATE_FORMATS,
  media,
  ResponsiveType,
  styled,
  StyledThemeContext,
  useResponsive,
} from '@thesisedu/web'
import { Progress } from 'antd'
import moment from 'moment'
import React from 'react'

import { AssignmentActionsTimer } from './AssignmentActionsTimer'
import { RetryAssignmentButton } from './RetryAssignmentButton'
import { FormSubmitButton } from './form'
import { useAssignmentViewerFormContext } from './form/AssignmentViewerFormContext'
import { FormSaveButton } from './form/FormSaveButton'
import { useSubmissionContext } from '..'
import { GroupMembers } from '../groups/GroupMembers'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px 0;
  background: ${props => props.theme['@white']};
  box-shadow: 0 0 5px ${props => props.theme['@white']};
  .progress {
    line-height: 1;
    margin-bottom: 10px;
    span.status,
    span.due {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      display: block;
      color: ${props => props.theme['@gray-4']};
    }
    .ant-progress {
      line-height: 1;
    }
  }
  .actions {
    display: flex;
    white-space: nowrap;
    > button:not(:last-child) {
      margin-right: 10px;
    }
  }

  ${media.lg} {
    flex-direction: row;
    align-items: flex-end;
    .progress {
      flex-grow: 1;
      margin-right: 30px;
      margin-bottom: 0;
    }
    .actions {
      margin-left: auto;
    }
  }
`
const ProgressHeader = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: flex-end;
  > div:last-child {
    margin-left: auto;
  }
`

export interface AssignmentActionsProps {
  disabled?: boolean
  /** @deprecated use FormSubmitButton instead */
  onSubmitAnswers: () => void
  /** @deprecated use FormSaveButton instead */
  onSaveAnswers: () => void
  displayRetry?: boolean
  configuration: AssignmentConfiguration
  completedFields: number
}
export function AssignmentActions({
  disabled: _disabled,
  displayRetry,
  configuration,
  completedFields,
}: AssignmentActionsProps) {
  const selectedClassId = useSelectedClassId(false)
  const { submission, assignment, groups, classId, submitAlternative } =
    useSubmissionContext(false) || {}
  const { timeLimitReached } = useAssignmentViewerFormContext(false) || {}
  const { groupMemberIds, groupLeader, isGroupLeader, areAnswersShared } = groups || {}
  const theme = React.useContext(StyledThemeContext)
  const totalQuestions = (configuration.questions || []).filter(q => !q.disabled).length
  const showProgress = !submission?.submittedAt
  const isLargeDisplay = useResponsive(ResponsiveType.GreaterThan, 'lg')

  let disabled = _disabled
  let submitDisabled = _disabled
  let status: string | React.ReactElement
  if (displayRetry) {
    status = 'Assignment submitted. You may retry if you are not satisfied with your grade.'
  } else if (submission?.submittedAt) {
    status = 'Assignment submitted. No more changes are allowed.'
  } else if (timeLimitReached) {
    status = 'You have run out of time. No more changes are allowed.'
  } else if (!isGroupLeader && disabled && areAnswersShared && !groupMemberIds?.length) {
    status = (
      <span style={{ color: theme['@orange'] }}>
        You are not assigned to a group yet. Message your teacher and ask them to add you to a
        group.
      </span>
    )
  } else if (submission && !submission.canEdit) {
    disabled = true
    if (!_disabled) submitDisabled = false
    status = 'You are not allowed to edit this submission.'
  } else {
    status = `Completed ${completedFields} out of ${totalQuestions} questions.`
  }

  let primaryAction: React.ReactElement
  if (displayRetry) {
    primaryAction = <RetryAssignmentButton isLargeDisplay={isLargeDisplay} />
  } else {
    primaryAction = (
      <FormSubmitButton
        type={'primary'}
        size={isLargeDisplay ? 'large' : undefined}
        disabled={submitDisabled}
      />
    )
  }

  const dueAt = assignment?.dueAt
  return (
    <Container>
      <div className={'progress'}>
        <ProgressHeader>
          <div>
            {groupMemberIds?.length ? (
              <GroupMembers
                groupMemberIds={groupMemberIds}
                isGroupLeader={isGroupLeader}
                groupLeader={groupLeader}
                areAnswersShared={areAnswersShared}
                classId={classId || selectedClassId || ''}
              />
            ) : null}
            {dueAt && moment(dueAt).isValid() && (
              <span className={'due'}>
                Due {moment(dueAt).fromNow()} ({moment(dueAt).format(DATE_FORMATS.FULL)})
              </span>
            )}
            {status ? <span className={'status'}>{status}</span> : null}
          </div>
          <div>
            <AssignmentActionsTimer />
          </div>
        </ProgressHeader>
        {showProgress ? (
          <Progress percent={(completedFields / totalQuestions) * 100} showInfo={false} />
        ) : null}
      </div>
      <div className={'actions'}>
        <FormSaveButton size={isLargeDisplay ? 'large' : undefined} disabled={disabled} />
        {primaryAction}
        {submitAlternative
          ? React.cloneElement(submitAlternative, {
              size: isLargeDisplay ? 'large' : undefined,
            })
          : null}
      </div>
    </Container>
  )
}
