import { AlignRightBox, PositionAlign } from '@thesisedu/react/icons'
import React from 'react'

import { $createClearFloatNode, $isClearFloatNode } from '../../nodes/ClearFloatNode'
import { $isFloatNode, $wrapInFloatNode } from '../../nodes/FloatNode'
import { useElementsMenuItem, ElementsGroup } from '../../ui/ElementsMenu/ElementsMenuContext'

export function useFloatElements() {
  useElementsMenuItem({
    identifier: 'float',
    title: 'Float',
    icon: <AlignRightBox />,
    weight: 31,
    group: ElementsGroup.Layout,
    isSelected: $isFloatNode,
    onCommit(element) {
      return $wrapInFloatNode(element)
    },
  })
  useElementsMenuItem({
    identifier: 'float-clear',
    title: 'Clear Float',
    icon: <PositionAlign />,
    weight: 32,
    group: ElementsGroup.Layout,
    noReplace: true,
    isSelected: $isClearFloatNode,
    onCommit(element) {
      const node = $createClearFloatNode()
      return element.replace(node, false)
    },
  })
}
