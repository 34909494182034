import { Button, ButtonProps } from 'antd'
import React, { useState } from 'react'

import { ApproveTrialRequestModal, ApproveTrialRequestModalProps } from './ApproveTrialRequestModal'

export interface ApproveTrialRequestButtonProps
  extends Omit<ApproveTrialRequestModalProps, 'visible' | 'onClose'> {
  buttonProps?: Partial<ButtonProps>
}
export function ApproveTrialRequestButton({
  buttonProps,
  ...props
}: ApproveTrialRequestButtonProps) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <ApproveTrialRequestModal {...props} visible={visible} onClose={() => setVisible(false)} />
      <Button
        type={'link'}
        size={'small'}
        onClick={() => setVisible(true)}
        style={{ marginLeft: 'auto' }}
      >
        Approve
      </Button>
    </>
  )
}
