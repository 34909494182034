import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { flattenSegments } from '@thesisedu/feature-course-types'
import { Dropdown } from '@thesisedu/ui'
import { EyeEmpty, EyeOff } from '@thesisedu/ui/icons'
import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { useOutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentType } from '../../schema'
import {
  ClassFragmentWithPermissions,
  SegmentActionComponentProps,
  SegmentActionResource,
} from '../../types'

export interface EnableChildrenProps extends SegmentActionComponentProps {
  disable?: boolean
}
export function EnableChildren({ disable, segment }: EnableChildrenProps) {
  const { setSegmentEnabled } = useOutlineSegmentContext()
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canEnableSegments = cls?.canEnableSegments
  return (
    <Dropdown.Item
      disabled={!canEnableSegments}
      icon={disable ? <EyeOff /> : <EyeEmpty />}
      onClick={() => {
        const flatSegments = flattenSegments([segment])
        setSegmentEnabled(
          flatSegments.map(segment => segment.id),
          !disable,
        )
      }}
    >
      {disable ? 'Disable' : 'Enable'} All
    </Dropdown.Item>
  )
}

export function DisableChildren(props: SegmentActionComponentProps) {
  return <EnableChildren {...props} disable />
}

export const EnableChildrenAction: SegmentActionResource = {
  identifier: 'enable-children',
  type: 'SegmentAction',
  component: EnableChildren,
  group: 'Enable & Disable',
  weight: 100,
  filter: segment => {
    return !NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}

export const DisableChildrenAction: SegmentActionResource = {
  identifier: 'disable-children',
  type: 'SegmentAction',
  component: DisableChildren,
  group: 'Enable & Disable',
  weight: 100,
  filter: segment => {
    return !NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}
