import { useCachedStudentUserCallback } from '@thesisedu/feature-classes-react'
import { Format } from '@thesisedu/feature-utils'
import { styled, LoadableStatistic } from '@thesisedu/web'
import { ResponsiveBar } from '@thesisedu/web/nivo'
import React from 'react'

import { AssignmentSubmissionsFragment } from '../schema'

export interface GradeSummaryProps {
  assignment: AssignmentSubmissionsFragment
}
interface GradeSummaryGrade {
  rawGrade?: number
  grade: string
  name: string
}

export function GradeSummary({ assignment }: GradeSummaryProps) {
  const getStudent = useCachedStudentUserCallback()
  const grades = (assignment.submissions || assignment.currentSubmissions).edges
    .filter(
      edge =>
        edge.node.gradedAt &&
        edge.node.grade !== null &&
        edge.node.grade !== undefined &&
        getStudent(edge.node.studentId, edge.node.classId),
    )
    .map<GradeSummaryGrade>(edge => {
      const student = getStudent(edge.node.studentId, edge.node.classId)!
      return {
        rawGrade: edge.node.grade!,
        grade: (edge.node.grade! * 100).toFixed(2),
        name: student.user.name || student.user.username,
      }
    })
  const grade = assignment.submissions?.averageGrade
  return (
    <Container>
      <LoadableStatistic
        title={'Grade'}
        value={grade !== undefined && grade !== null ? grade : 'N/A'}
        formatter={val => (val !== 'N/A' ? Format.number(grade, 'decimalPercentage') : 'N/A')}
      />
      <div className={'chart'} style={{ width: grades.length * 30, height: 60 }}>
        <ResponsiveBar
          data={grades.map(item => ({ student: item.name, grade: parseFloat(item.grade) }))}
          keys={['grade']}
          indexBy={'student'}
          axisLeft={{ legend: 'Grade' }}
          margin={{ left: 0, top: 0, bottom: 10, right: 0 }}
          layers={['bars'] as any[]}
          isInteractive={false}
          minValue={0}
          maxValue={100}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  > div:first-child {
    min-width: 100px;
  }
  > div.chart {
    margin-left: auto;
    display: flex;
    div {
      max-height: 60px;
      padding-bottom: 0;
    }
  }
`
