import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { useDraggableBlockMenu } from './useDraggableBlockMenu'

export interface DraggableBlockPluginProps {
  anchorElement?: HTMLElement
  noAdd?: boolean
}
export function DraggableBlockPlugin({
  anchorElement = document.body,
  noAdd,
}: DraggableBlockPluginProps) {
  const [editor] = useLexicalComposerContext()
  return useDraggableBlockMenu(editor, anchorElement, editor._editable, !!noAdd)
}
