import { WebContext } from '@thesisedu/feature-web'
import React, { useContext } from 'react'

import { DefaultSegmentContent } from './DefaultSegmentContent'
import { SegmentProps, SegmentTypeResource } from '../types'

export interface SegmentContextValue {
  id: string
}
export const SegmentContext = React.createContext<SegmentContextValue | undefined>(undefined)

export function useSegmentContext(): SegmentContextValue | undefined
export function useSegmentContext(require: false): SegmentContextValue | undefined
export function useSegmentContext(require: true): SegmentContextValue
export function useSegmentContext(require?: boolean): SegmentContextValue | undefined {
  const context = React.useContext(SegmentContext)
  if (!context && require) {
    throw new Error('SegmentContext is required, yet not provided.')
  }
  return context
}

export function Segment(props: SegmentProps) {
  const { featureWeb } = useContext(WebContext)!
  const resource = featureWeb.deps.resources.getResource<SegmentTypeResource>(
    'SegmentType',
    props.segment.type,
  )
  if (resource) {
    let SegmentComponent: React.FC<React.PropsWithChildren<SegmentProps>>
    if (props.readOnly) {
      if (resource.renderContent) {
        SegmentComponent = resource.renderContent
        SegmentComponent.displayName = `${props.segment.type}SegmentContent`
      } else {
        SegmentComponent = DefaultSegmentContent
      }
    } else if (!props.useSimpleEditing) {
      SegmentComponent = resource.renderEdit
      SegmentComponent.displayName = `${props.segment.type}SegmentEdit`
    } else if (props.useSimpleEditing && resource.renderSimpleEdit) {
      SegmentComponent = resource.renderSimpleEdit
      SegmentComponent.displayName = `${props.segment.type}SegmentEditSimple`
    } else {
      return null
    }
    return (
      <SegmentContext.Provider value={{ id: props.segment.id }}>
        <SegmentComponent {...props} />
      </SegmentContext.Provider>
    )
  } else {
    throw new Error(`Segment type '${props.segment.type}' is invalid.`)
  }
}
