import { AntIconWrapper, Button, Dropdown } from '@thesisedu/react'
import { EyeEmpty, NavArrowDown, Undo } from '@thesisedu/react/icons'
import React from 'react'

import { assignmentAllowsForfeit } from './forfeit'
import { useStudentForfeitAssignment } from './useStudentForfeitAssignment'
import { useSubmissionContext } from '../contexts/SubmissionContext'

export interface RetryAssignmentButtonProps {
  isLargeDisplay?: boolean
}
export function RetryAssignmentButton({ isLargeDisplay }: RetryAssignmentButtonProps) {
  const { classId, assignment, onRetry } = useSubmissionContext(false) || {}
  const [forfeit, { loading }] = useStudentForfeitAssignment()
  const allowsForfeit = assignment && classId ? assignmentAllowsForfeit(assignment) : false
  const button = (
    <Button
      onClick={onRetry}
      icon={<AntIconWrapper children={<Undo />} />}
      size={isLargeDisplay ? 'large' : undefined}
      loading={loading}
      data-testid={'RetryAssignmentButton primary'}
    >
      Retry Assignment
    </Button>
  )

  if (allowsForfeit) {
    return (
      <Button.Group size={isLargeDisplay ? 'large' : undefined}>
        {button}
        <Dropdown
          trigger={['click']}
          options={[
            {
              disabled: loading,
              label: "I'm Finished, Show Answers",
              value: 'show-answers',
              icon: <EyeEmpty />,
              async onClick() {
                if (assignment?.id && classId) {
                  await forfeit(assignment.id, classId)
                }
              },
            },
          ]}
        >
          <Button icon={<NavArrowDown />} data-testid={'RetryAssignmentButton dropdown'} />
        </Dropdown>
      </Button.Group>
    )
  } else {
    return button
  }
}
