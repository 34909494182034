import { ErrorServiceTypes } from '@thesisedu/feature'
import { useFeature } from '@thesisedu/feature-react'
import { Button, Modal, Text, Toast, useToast } from '@thesisedu/ui'
import { ElectronicsChip } from '@thesisedu/ui/icons'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { SentryWebFeature } from './SentryWebFeature'
import { debug } from './log'

export function SentryReplayHandler() {
  const feature = useFeature(SentryWebFeature)
  const replay = feature.replay
  const [visible, setVisible] = React.useState(false)
  const [completeVisible, setCompleteVisible] = React.useState(false)
  const [replayId, setReplayId] = React.useState<string | undefined>(undefined)
  const toast = useToast()
  const pressedTimeout = React.useRef<any>(undefined)
  useHotkeys('shift+r', () => {
    if (!replay) return
    if (pressedTimeout.current) {
      // This means they pressed it twice within 500ms.
      const existingReplayId = replay.getReplayId()
      if (existingReplayId === undefined) {
        debug('no existing replay id; showing confirmation modal')
        setVisible(true)
      } else {
        debug('existing replay id; stopping and flushing')
        replay
          .stop()
          .then(() => {
            replay.flush({ continueRecording: false })
          })
          .then(() => {
            setVisible(true)
          })
      }
      pressedTimeout.current = undefined
    } else {
      pressedTimeout.current = setTimeout(() => {
        pressedTimeout.current = undefined
      }, 1000)
    }
  })

  const spanRef = React.useRef<ErrorServiceTypes.Span | undefined>(undefined)
  React.useEffect(() => {
    if (replayId) {
      spanRef.current = feature.services.error.getCurrentSpan()
      if (!spanRef.current) {
        debug('starting new span...')
        spanRef.current = feature.services.error.startSpan({
          name: `Diagnostic Mode: ${replayId}`,
          sampled: true,
        })
      }
      if (spanRef.current) {
        debug('marking current tracing span as sampled')
        spanRef.current.setSampled(true)
        const anyReplay = replay as any
        if (anyReplay._replay?.getContext) {
          const context = anyReplay._replay.getContext()
          if (context.traceIds && spanRef.current.getTraceId) {
            const spanTraceId = spanRef.current.getTraceId()
            if (spanTraceId) {
              debug('attaching trace id %s to replay %s', spanTraceId, replayId)
              context.traceIds.add(spanTraceId)
            }
          }
        }
      } else {
        debug('no transaction to mark as sampled')
      }
    }
  }, [replayId])

  if (!replay) return null
  return (
    <>
      <Modal visible={visible} onVisibleChange={setVisible} title={'Diagnostic Mode'}>
        <Text level={'l'} bottom>
          You are about to enable diagnostic mode.
        </Text>
        <Text bottom>
          This will temporarily send a more detailed level of telemetry data to our engineering team
          to help diagnose the issue you are experiencing.
        </Text>
        <Text bottom>
          Click <strong>Start Diagnostic Mode</strong> below, perform the steps again to show the
          issue to our engineering team, and then click the <strong>Done</strong> button in the
          bottom-right corner of the page.
        </Text>
        <Text bottom>
          <strong>
            If you are using an ad blocker, please disable it for this website and refresh the page
            before using this feature.
          </strong>
        </Text>
        <Modal.Footer>
          <Modal.CloseButton />
          <Button
            variant={'primary'}
            icon={<ElectronicsChip />}
            children={'Start Diagnostic Mode'}
            onPress={async () => {
              setVisible(false)
              debug('force-starting a new replay')
              try {
                replay.startBuffering()
              } catch {
                debug('got an error; a replay must already be in progress')
              }
              const replayId = replay.getReplayId()
              if (replayId) {
                debug('got a replay id; recording')
                setReplayId(replayId)
              } else {
                debug('could not find replay id; cannot start')
                toast({
                  status: 'error',
                  title: 'There was an error starting your diagnostic session.',
                })
              }
            }}
          />
        </Modal.Footer>
      </Modal>
      {replayId ? (
        <>
          <Modal
            visible={completeVisible}
            onVisibleChange={v => {
              setCompleteVisible(v)
              if (!v) {
                setReplayId(undefined)
              }
            }}
            title={'Diagnostic Mode Complete'}
          >
            <Text bottom>Your diagnostic code is:</Text>
            <Text bottom={'m'} font={'code'} level={'l'} weight={'bold'}>
              {replayId}
            </Text>
            <Text>Please give this code to support.</Text>
            <Modal.Footer>
              <Modal.CloseButton children={'OK'} />
            </Modal.Footer>
          </Modal>
          <Toast
            data-sentry-block
            loading
            open={!completeVisible}
            onOpenChange={() => {}}
            title={'Diagnostic Mode Active'}
            description={
              'Please re-create the issue you were experiencing. Click done when you are finished.'
            }
            actionAlt={'Click done when you are finished.'}
            action={
              <Button
                size={'small'}
                children={'Done'}
                onPress={async () => {
                  debug('stopping replay')
                  if (spanRef.current) {
                    debug('stopping transaction')
                    spanRef.current.setSampled(true)
                    spanRef.current.end()
                  }
                  debug('flushing and stopping replay...')
                  await replay.flush({
                    continueRecording: false,
                  })
                  await replay.stop()
                  setCompleteVisible(true)
                }}
              />
            }
          />
        </>
      ) : null}
    </>
  )
}
