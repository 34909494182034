import { Feature } from '@thesisedu/feature'
import { AssignmentDeepLinkHook } from '@thesisedu/feature-assignments-react'

export default function addDeepLinkHandlers(feature: Feature) {
  feature.hookManager.registerMutateHook<AssignmentDeepLinkHook>(
    'feature-assignments-react:assignment-deep-link',
    (link, { assignment, viewer, classId, tab }) => {
      if (assignment.configuration?.segmentId) {
        return `/${viewer.group.toLowerCase()}/classes/${classId}/${
          viewer.group === 'TEACHER'
            ? `course/outline?viewSegment=${assignment.configuration.segmentId}${
                tab ? `#${tab}` : ''
              }`
            : `course?viewSegment=${assignment.configuration.segmentId}${tab ? `#${tab}` : ''}`
        }`
      } else return link
    },
  )
}
