import { ClassDangerZoneItemsHook, DangerZoneItem } from '@thesisedu/feature-classes-react'
import React from 'react'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { CopySettingsButton } from '../classes/CopySettingsButton'
import { useCopySegmentToClasses } from '../resources/segment_actions/useCopySegmentToClasses'

function CopyDangerZone() {
  const { otherClasses } = useCopySegmentToClasses()
  if (otherClasses.length) {
    return (
      <DangerZoneItem
        key={'copy-settings'}
        title={"Copy this class' content to another class."}
        description={
          <>
            You can copy the content from this class to another one of your classes. In doing so,
            you'll overwrite content inside the target class, which could potentially be
            destructive.
          </>
        }
        action={<CopySettingsButton status={'danger'} />}
      />
    )
  } else return null
}

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<ClassDangerZoneItemsHook>(
    'classes-react:danger-zone-items',
    items => {
      return [<CopyDangerZone key={'copy-settings'} />, ...items]
    },
  )
}
