import { ProgressModal } from '@thesisedu/web'
import { message } from 'antd'
import React from 'react'

import { BackgroundJobStatus, useStandardSetImportStatusQuery } from '../schema'

const IMPORT_POLL_INTERVAL_MS = 5 * 1000

export interface ImportStandardSetProgressModalProps {
  identifier: string
  onDone: () => void
}
export function ImportStandardSetProgressModal({
  identifier,
  onDone,
}: ImportStandardSetProgressModalProps) {
  const { data } = useStandardSetImportStatusQuery({
    variables: {
      identifier,
    },
    pollInterval: IMPORT_POLL_INTERVAL_MS,
  })
  const status = data?.standardSetImportJob?.status
  const { current, total } = data?.standardSetImportJob?.resultPayload || {}

  React.useEffect(() => {
    if (status === BackgroundJobStatus.Error) {
      message.error('There was an error importing your data.')
      onDone()
    } else if (status === BackgroundJobStatus.Success) {
      message.success('Standards imported successfully!')
      onDone()
    }
  }, [status])

  return (
    <ProgressModal
      visible
      current={current || 0}
      total={total || 0}
      indeterminate={!total}
      buildLeftLabel={() => 'Importing...'}
      buildRightLabel={(current, total) => {
        if (status === BackgroundJobStatus.Processing && total > 0) {
          return `${current} / ${total}`
        } else {
          return ''
        }
      }}
    />
  )
}
