import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface PdfGenerationReactFeatureOptions {}

export class PdfGenerationReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-pdf-generation-react'
  static path = ''
  static requires: string[] = []
  public options!: PdfGenerationReactFeatureOptions

  public reactResources() {
    require('./hooks/routes').default(this)
  }
}

export const pdfGenerationReactFeature: ReactUse = (opts: PdfGenerationReactFeatureOptions) => [
  PdfGenerationReactFeature,
  opts,
]
