import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Block, Form, HSpaced, LoadingIndicator, NotFound } from '@thesisedu/react'
import React from 'react'

import { FormValue, StandardSetForm } from './StandardSetForm'
import { formValueToInput, standardSetToEditorValue } from './helpers'
import {
  UpdateStandardSetInput,
  useStandardSetQuery,
  useUpdateStandardSetMutation,
} from '../schema'

export interface EditStandardSetProps {
  standardSetId: string
  actions?: React.ReactElement
}
export function EditStandardSet({ standardSetId, actions }: EditStandardSetProps) {
  const form = Form.useForm<FormValue>()
  const { data, loading } = useStandardSetQuery({
    variables: {
      id: standardSetId,
    },
    onCompleted(data) {
      const standardSet = data?.node?.__typename === 'StandardSet' ? data.node : undefined
      if (standardSet) {
        form.setValue('name', standardSet.name)
        form.setValue('state', standardSet.state)
        form.setValue('tags', standardSetToEditorValue(standardSet))
        form.setValue('attachment', standardSet.attachment)
      }
    },
  })
  const [save, { loading: saveLoading }] = useUpdateStandardSetMutation({
    onError: onMutationError('There was an error saving your standard set.'),
  })
  const standardSet = data?.node?.__typename === 'StandardSet' ? data.node : undefined

  if (standardSet) {
    return (
      <Form
        form={form}
        onFinish={values => {
          save({
            variables: {
              input: {
                id: standardSetId,
                ...formValueToInput<Omit<UpdateStandardSetInput, 'id'>>(values),
              },
            },
          })
        }}
      >
        <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
          <HSpaced justify={'flex-end'}>
            {actions}
            <Form.Submit
              type={'primary'}
              loading={saveLoading}
              disableUntilDirty
              children={'Save Changes'}
            />
          </HSpaced>
        </Block>
        <StandardSetForm />
      </Form>
    )
  } else if (loading) {
    return <LoadingIndicator block />
  } else {
    return <NotFound description={'We could not find that standard set.'} />
  }
}
