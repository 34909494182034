import * as React from 'react'

function SvgNumber2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#F79824'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#F79824'}
          fillRule={'nonzero'}
          d={
            'M71.58 200.82l112.272-5.68 1.136-47.899-51.308 5.87a475.129 475.129 0 0025.18-16.946c8.331-5.995 15.336-12.464 21.016-19.406 5.68-6.942 9.277-14.42 10.792-22.435 1.514-8.015-.316-16.63-5.49-25.843-.127-.127-.947-1.357-2.462-3.692-1.515-2.335-3.976-5.175-7.384-8.52-3.408-3.345-7.541-6.532-12.4-9.561-4.86-3.03-10.666-5.238-17.419-6.626-6.753-1.389-14.263-1.326-22.53.189-8.267 1.514-17.513 5.08-27.736 10.697-10.224 5.617-21.205 13.979-32.943 25.086l25.37 36.54c3.66-3.282 8.172-6.816 13.536-10.602 5.365-3.787 10.666-7.1 15.904-9.94 5.238-2.84 10.003-4.828 14.294-5.964 4.292-1.136 7.195-.694 8.71 1.325 1.009 1.389.725 3.345-.853 5.87-1.577 2.524-4.007 5.395-7.289 8.614-3.281 3.219-7.163 6.753-11.643 10.602a3396.5 3396.5 0 00-13.916 12.023 548.39 548.39 0 00-14.01 12.59c-4.544 4.228-8.52 8.393-11.928 12.496-3.408 4.102-5.995 8.046-7.762 11.833-1.767 3.786-2.21 7.257-1.326 10.413l.19 28.967z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M182.716 64.789c-1.515-2.334-3.976-5.174-7.384-8.52-3.408-3.343-7.543-6.532-12.4-9.561-4.861-3.03-10.668-5.236-17.419-6.627-6.754-1.387-14.265-1.325-22.53.19-8.268 1.514-17.513 5.082-27.736 10.697-10.224 5.618-21.205 13.98-32.943 25.086l25.37 36.54c3.659-3.28 8.17-6.816 13.536-10.602 5.364-3.787 10.665-7.1 15.904-9.94 5.236-2.84 10.002-4.828 14.294-5.964 4.29-1.136 7.195-.692 8.71 1.325 1.008 1.39.724 3.346-.853 5.87-1.58 2.526-4.008 5.395-7.289 8.614-3.284 3.219-7.165 6.754-11.643 10.602-4.482 3.852-9.12 7.857-13.916 12.023a546.779 546.779 0 00-14.01 12.59c-4.544 4.23-8.52 8.396-11.928 12.496-3.408 4.103-5.996 8.046-7.762 11.833-1.77 3.786-2.21 7.26-1.326 10.413l.19 28.967 112.271-5.68 1.136-47.9-51.308 5.87a475.355 475.355 0 0025.18-16.946c8.331-5.993 15.336-12.463 21.016-19.406 5.68-6.94 9.277-14.418 10.792-22.435 1.514-8.014-.317-16.628-5.49-25.843-.128-.124-.947-1.355-2.462-3.692z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber2 = React.memo(SvgNumber2)
export default MemoSvgNumber2
