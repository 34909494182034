import { BodySmall } from '@thesisedu/react'
import React from 'react'

import { FullAssignment } from './types'
import { SubmissionType } from '../schema'
import { SubmissionNavigationItemSubtitleProps } from '../types'

export function getGroupName(assignment: FullAssignment, studentId: string) {
  if (
    assignment?.groups &&
    (assignment?.submissionType === SubmissionType.Group ||
      assignment?.submissionType === SubmissionType.GroupIndividual)
  ) {
    const studentGroupKey = Object.keys(assignment.groups).find(key => {
      return assignment.groups[key].students.includes(studentId)
    })
    return studentGroupKey ? assignment.groups[studentGroupKey].name : null
  } else {
    return null
  }
}

export function GradeModalSubmissionNavigationItemSubtitle({
  assignment,
  studentId,
}: SubmissionNavigationItemSubtitleProps) {
  const groupName = getGroupName(assignment, studentId)
  return groupName ? <BodySmall color={'@text-color-secondary'}>{groupName}</BodySmall> : null
}
