import React from 'react'

import { warn, debug } from './log'
import {
  DeepLinkResolvedContext,
  DeepLinkResolvedPayload,
  DeepLinkResource,
  ParamMap,
  ReactHooks,
} from './types'
import { useAsyncMutateCallback, useResource } from './useFeatureRoot'

export interface DeepLinkHandlerProps {
  url: string
  onAccessDenied: (cleanPath: string) => void
  onFound: (path: string) => void
  renderNotFound: () => React.ReactElement
  renderLoading: () => React.ReactElement
  renderNoResource?: () => React.ReactElement
}
export interface DeepLinkResolvedState extends DeepLinkResource {
  cleanPath: string
  paramMap: ParamMap
}

export function DeepLinkHandler({
  url: _url,
  onFound,
  renderNotFound,
  renderLoading,
  onAccessDenied,
  renderNoResource,
}: DeepLinkHandlerProps) {
  const [state, setState] = React.useState<
    'loading' | '404' | 'unresolved' | DeepLinkResolvedState
  >('loading')
  const deepLinkResources = useResource<DeepLinkResource>('DeepLink')
  const mutate = useAsyncMutateCallback<DeepLinkResolvedPayload, DeepLinkResolvedContext>(
    ReactHooks.DeepLinkResolved,
  )
  const [url, setUrl] = React.useState<string>(_url)
  React.useEffect(() => {
    debug('url updating to %s, attempting to resolve', url)
    const schemeSegments = url.split('://')
    if (!schemeSegments.length) {
      warn('url is invalid')
      setState('404')
      return
    }
    const path = schemeSegments[schemeSegments.length - 1]
    const pathWithoutQueryAndHash = path.split('?')[0].split('#')[0]
    let pathSegments = pathWithoutQueryAndHash.split('/').filter(Boolean)

    // If the first segment looks like a domain (contains a .), remove it.
    if (pathSegments[0]?.includes('.')) {
      pathSegments = pathSegments.slice(1)
    }

    const resource = deepLinkResources.find(r => r.identifier === pathSegments[0])
    if (!resource) {
      warn('could not find resource to satisfy identifier %s', pathSegments[0])
      setState('unresolved')
      return
    }
    const params: ParamMap = {}
    for (let i = 0; i < resource.params.length; i++) {
      const param = resource.params[i]
      const segment = pathSegments[i + 1]
      if (param.required && !segment) {
        warn('missing required parameter %s in path %s', param.identifier, pathWithoutQueryAndHash)
        setState('404')
        return
      }
      params[param.identifier] = segment
    }
    setState({
      ...resource,
      cleanPath: pathSegments.join('/'),
      paramMap: params,
    })
  }, [url])

  if (state === '404') {
    return renderNotFound()
  } else if (state === 'loading') {
    return renderLoading()
  } else if (state === 'unresolved') {
    return renderNoResource ? renderNoResource() : renderNotFound()
  } else {
    return (
      <>
        {renderLoading()}
        <state.Component
          url={state.cleanPath}
          params={state.paramMap}
          resolved={async resolution => {
            if (resolution.type === 'NotFound') {
              setState('404')
            } else if (resolution.type === 'AccessDenied') {
              onAccessDenied(state.cleanPath)
            } else if (resolution.type === 'Found') {
              if (url === _url) {
                const finalResolution = await mutate(resolution, { state, resolution })
                if (typeof finalResolution === 'string') {
                  setUrl(finalResolution)
                } else {
                  await onFound(finalResolution.resolvedPath)
                }
              } else {
                await onFound(resolution.resolvedPath)
              }
            }
          }}
        />
      </>
    )
  }
}
