import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { Segment, SegmentProgress } from '@thesisedu/feature-courses-react'
import { useSegmentDecoration } from '@thesisedu/feature-courses-react/dist/contexts/SegmentDecorationsContext'
import { useSegmentProgress } from '@thesisedu/feature-courses-react/dist/segment/useSegmentProgress'
import { StyledThemeContext, DATE_FORMATS } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { cacheSegmentAssignmentId } from '../cache/segmentAssignmentCache'

export interface SegmentDecorationWithDueDate {
  assignmentDueDate?: string
  assignmentId?: string
}

export interface AssignmentSegmentDescriptionProps {
  segment: Segment
}
export function AssignmentSegmentDescription({ segment }: AssignmentSegmentDescriptionProps) {
  const progress = useSegmentProgress(segment)
  const theme = React.useContext(StyledThemeContext)
  const classId = useSelectedClassId(false)
  const decoration = useSegmentDecoration(segment.id) as SegmentDecorationWithDueDate | undefined
  const dueDate = decoration?.assignmentDueDate
    ? moment(decoration.assignmentDueDate).format(DATE_FORMATS.FULL)
    : null

  if (decoration?.assignmentId && classId) {
    cacheSegmentAssignmentId(classId, segment.id, decoration.assignmentId)
  }

  return dueDate ? (
    <span style={progress === SegmentProgress.OVERDUE ? { color: theme['@red'] } : undefined}>
      <strong>Due:</strong> {dueDate}
    </span>
  ) : null
}
