import { useResource } from '@thesisedu/feature-react'
import { GroupedNavigation } from '@thesisedu/react'
import { Text } from '@thesisedu/ui'
import { useBreadcrumb } from '@thesisedu/web'
import { orderBy } from 'lodash'
import React from 'react'

import { MyContentPageResource } from '../types'
import { useViewerContext } from '../useViewerContext'

export function MyContent() {
  const viewer = useViewerContext(false)
  const sortedPages = orderBy(
    useResource<MyContentPageResource>('MyContentPage'),
    'weight',
    'asc',
  ).filter(resource => !resource.filter || resource.filter(viewer))
  useBreadcrumb({ title: 'My Content' })

  return (
    <>
      <Text level={'h1'} bottom={'m'}>
        My Content
      </Text>
      <GroupedNavigation groups={sortedPages} title={'My Content'} />
    </>
  )
}
