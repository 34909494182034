import { getSegmentParents } from '@thesisedu/feature-course-types'
import { useFreshRef } from '@thesisedu/feature-react'
import { VariableSizeList } from '@thesisedu/react-virtual/dist/types'
import React from 'react'

import { OutlineListImperativeHandle } from './OutlineList'
import { useOutlineCollapseContext } from './context/OutlineCollapseContext'
import { OutlineItem } from './types'
import { GroupSegment } from '../types'

export interface UseOutlineListRefOpts {
  listRef: React.RefObject<VariableSizeList>
  items: OutlineItem[]
  selectedTerm: GroupSegment
}
export interface UseOutlineListRefResult {
  highlightedItemIds: string[]
}
export function useOutlineListRef(
  ref: React.ForwardedRef<OutlineListImperativeHandle>,
  { items, listRef, selectedTerm }: UseOutlineListRefOpts,
): UseOutlineListRefResult {
  const { setParentCollapsed } = useOutlineCollapseContext(false) ?? {}
  const [highlightedItemIds, setHighlightedItemIds] = React.useState<string[]>([])
  const highlightTimeouts = React.useRef<any[]>([])
  const itemsRef = useFreshRef(items)
  const selectedTermRef = useFreshRef(selectedTerm)
  React.useImperativeHandle(
    ref,
    () => {
      return {
        highlightSegment(segmentId) {
          const selectedTerm = selectedTermRef.current
          const parents = getSegmentParents([selectedTerm], segmentId)
          for (const parentId of parents) {
            const parentItem = itemsRef.current.find(item => item.id === parentId)
            if (parentItem?.collapsible) {
              setParentCollapsed?.(parentId, false)
            }
          }

          // Wait for the collapsed state to change...
          highlightTimeouts.current.push(
            setTimeout(() => {
              const index = itemsRef.current.findIndex(item => item.id === segmentId)
              if (index === -1) return
              listRef.current?.scrollToItem(index, 'center')
              setHighlightedItemIds(items => {
                return [...new Set([...items, segmentId])]
              })
              highlightTimeouts.current.push(
                setTimeout(() => {
                  setHighlightedItemIds(items => {
                    return items.filter(item => item !== segmentId)
                  })
                }, 5000),
              )
            }, 10),
          )
        },
      }
    },
    [],
  )
  React.useEffect(() => {
    return () => {
      highlightTimeouts.current.forEach(timeout => clearTimeout(timeout))
    }
  }, [])

  return {
    highlightedItemIds,
  }
}
