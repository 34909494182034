import React from 'react'
import { TypedEmitter } from 'tiny-typed-emitter'

import { useDevToolsContext } from '.'
import { DevtoolsEventTypes } from '..'

export class DevtoolsEvents extends TypedEmitter<DevtoolsEventTypes> {}

export function useEvent<T extends keyof DevtoolsEventTypes>(
  event: T,
  handler: DevtoolsEventTypes[T],
  deps: any[],
) {
  const { events } = useDevToolsContext(true)
  React.useEffect(() => {
    events.on(event, handler)
    return () => {
      events.off(event, handler)
    }
  }, deps)
}

export function useEventSender<T extends keyof DevtoolsEventTypes>(event: T) {
  const { events } = useDevToolsContext(false) || {}
  return React.useCallback((...args: Parameters<DevtoolsEventTypes[T]>) => {
    if (events) {
      events.emit(event, ...args)
    }
  }, [])
}
