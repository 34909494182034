import { onMutationError, useImpersonation } from '@thesisedu/feature-apollo-react'
import { Reset } from '@thesisedu/web'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'

import { useViewerContext } from '..'
import { useImpersonateMutation } from '../schema'

export function useImpersonate() {
  const { impersonate: _impersonate, coreAuthKey } = useImpersonation()
  const viewer = useViewerContext(false)
  const [impersonate, { loading }] = useImpersonateMutation({
    context: coreAuthKey
      ? {
          headers: {
            Authorization: `Bearer ${coreAuthKey}`,
          },
        }
      : undefined,
    onCompleted: async data => _impersonate(data.impersonate.jwt),
    onError: onMutationError('There was an error impersonating that user.'),
  })

  return {
    impersonate,
    loading,
    canImpersonate: viewer?.group === 'ADMIN' || viewer?.coreViewer?.group === 'ADMIN',
  }
}

export interface ImpersonateButtonProps extends Partial<ButtonProps> {
  userId: string
  useResetButton?: boolean
}
export const ImpersonateButton: React.FC<React.PropsWithChildren<ImpersonateButtonProps>> = ({
  userId,
  children,
  useResetButton,
  ...props
}) => {
  const { impersonate, loading, canImpersonate } = useImpersonate()

  if (!canImpersonate) return null
  const Component = useResetButton ? Reset.Button : Button
  return (
    <Component
      onClick={() => impersonate({ variables: { userId } })}
      loading={loading}
      {...(props as any)}
    >
      {children || 'Impersonate User'}
    </Component>
  )
}
