import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { SubscriptionsTable } from './SubscriptionsTable'
import {
  LicenseSubscriptionFragment,
  SubscriptionsDocument,
  SubscriptionsQuery,
  SubscriptionsQueryVariables,
} from './schema'

export const AllSubscriptionsTable: React.FC<React.PropsWithChildren<unknown>> = () => (
  <InfiniteQuery<LicenseSubscriptionFragment, SubscriptionsQuery, SubscriptionsQueryVariables>
    document={SubscriptionsDocument}
    getResult={d => d?.licenseSubscriptions}
    setResult={(previous, result) => ({
      ...previous,
      licenseSubscriptions: {
        ...previous.licenseSubscriptions,
        ...result,
      },
    })}
    children={({ data }) => (
      <SubscriptionsTable dataSource={data?.licenseSubscriptions.edges.map(edge => edge.node)} />
    )}
  />
)
