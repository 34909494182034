import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { useColumns } from './Columns'
import { useColumnResize } from './useColumnResize'

export interface LayoutPluginProps {
  anchorElement?: HTMLElement
}
export function LayoutPlugin({ anchorElement = document.body }: LayoutPluginProps = {}) {
  const [editor] = useLexicalComposerContext()
  useColumns()

  return useColumnResize(editor, anchorElement)
}
