import { LexicalEditor, RangeSelection } from 'lexical'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { TextFormatToolbarItem, useTextFormatToolbarContext } from './TextFormatToolbarContext'

export interface UseTextFormatToolbarItemsOpts {
  editor: LexicalEditor
  activeItems: Record<string, boolean | string[]>
  disabled?: boolean
}
export function useTextFormatToolbarItems({
  editor,
  activeItems,
  disabled,
}: UseTextFormatToolbarItemsOpts) {
  const context = useTextFormatToolbarContext(true)
  const items = orderBy(context.items, 'weight', 'asc')

  const groupedBy = groupBy(items, 'group')

  return {
    groups: Object.values(groupedBy).map(group => {
      return group.map(({ identifier, element: Element }) => {
        return (
          <Element
            key={identifier}
            editor={editor}
            active={activeItems[identifier]}
            disabled={disabled}
          />
        )
      })
    }),
    update(selection: RangeSelection): Record<string, boolean | string[]> {
      return items.reduce(_getActive(editor, selection), {})
    },
  }
}

function _getActive(editor: LexicalEditor, selection: RangeSelection) {
  return (acc: Record<string, boolean | string[]>, item: TextFormatToolbarItem) => {
    try {
      return {
        ...acc,
        [item.identifier]: item.getActive(editor, selection),
      }
    } catch {
      return { ...acc, [item.identifier]: false }
    }
  }
}

export interface GetActiveTextFormatToolbarItemsOpts {
  editor: LexicalEditor
}
export function useGetActiveTextFormatToolbarItems({
  editor,
}: GetActiveTextFormatToolbarItemsOpts) {
  const context = useTextFormatToolbarContext(true)
  return React.useCallback(
    (selection: RangeSelection) => {
      return context.items.reduce(_getActive(editor, selection), {})
    },
    [context.items],
  )
}
