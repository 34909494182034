import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any
}

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String']
}

export type AcceptInvitePayload = {
  __typename?: 'AcceptInvitePayload'
  /** The current user. */
  user: User
}

export enum AccountRegion {
  UsEast_1 = 'US_EAST_1',
  UsWest_1 = 'US_WEST_1',
}

/** An announcement posted by a teacher for students in a class. */
export type Announcement = Node & {
  __typename?: 'Announcement'
  id: Scalars['ID']
  /** The name of the announcement. */
  name: Scalars['String']
  /** The configuration for the announcement, containing the description. */
  configuration?: Maybe<AnnouncementConfiguration>
  /** The class the announcement belongs to. */
  class: Class
  /** Whether or not the announcement has been read by the current user. */
  isRead: Scalars['Boolean']
  /** The number of students that have viewed this announcement. */
  studentViews?: Maybe<Scalars['Int']>
  /** The date the announcement was first created. */
  createdAt: Scalars['DateTime']
  /** The date the announcement was last updated. */
  updatedAt: Scalars['DateTime']
}

export type AnnouncementConfiguration = {
  __typename?: 'AnnouncementConfiguration'
  /** The description (or body content) of the announcement. */
  description?: Maybe<Scalars['JSON'][]>
}

export type AnnouncementInput = {
  name?: Maybe<Scalars['String']>
  configuration?: Maybe<Scalars['JSON']>
}

export type AnnouncementPayload = {
  __typename?: 'AnnouncementPayload'
  announcement: Announcement
}

export type AnnouncementsConnection = Connection & {
  __typename?: 'AnnouncementsConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: AnnouncementsEdge[]
}

export type AnnouncementsEdge = Edge & {
  __typename?: 'AnnouncementsEdge'
  node: Announcement
  cursor: Scalars['String']
}

export type ApproveCommentInput = {
  id: Scalars['ID']
}

export type ApproveTrialRequestInput = {
  id: Scalars['ID']
  approval: TrialRequestApproval
  noEmail?: Scalars['Boolean']
}

export type ApproveTrialRequestPayload = {
  __typename?: 'ApproveTrialRequestPayload'
  site: Site
  license: License
  teacher: Teacher
}

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID']
}

export type AssignLessonInput = {
  id?: Maybe<Scalars['ID']>
  assignments: LessonAssociationInput[]
}

export type AssignedLessonStudentsConnection = Connection & {
  __typename?: 'AssignedLessonStudentsConnection'
  edges: AssignedLessonStudentsEdge[]
  totalCount: Scalars['Int']
  pageInfo: PageInfo
}

export type AssignedLessonStudentsEdge = Edge & {
  __typename?: 'AssignedLessonStudentsEdge'
  cursor: Scalars['String']
  node: Student
  class?: Maybe<Class>
  /** The number of times the student has viewed the lesson. */
  viewedTimes: Scalars['Int']
  /** The total amount of minutes the student has spent viewing the lesson. */
  viewedMinutes: Scalars['Int']
}

export type AssignedLessonsConnection = Connection & {
  __typename?: 'AssignedLessonsConnection'
  edges: AssignedLessonsEdge[]
  totalCount: Scalars['Int']
  pageInfo: PageInfo
}

export type AssignedLessonsEdge = Edge & {
  __typename?: 'AssignedLessonsEdge'
  cursor: Scalars['String']
  node: Lesson
  class?: Maybe<Class>
}

/** An assignment recorded in the gradebook for a class. */
export type Assignment = Node & {
  __typename?: 'Assignment'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The name of the assignment. */
  name: Scalars['String']
  /** An optional description for the assignment. */
  description?: Maybe<Scalars['String']>
  /** The date the assignment first opens for student submissions. */
  openAt?: Maybe<Scalars['DateTime']>
  /** The date the assignment closes and all submissions are due. */
  dueAt?: Maybe<Scalars['DateTime']>
  /** The total number of points to earn on an assignment. */
  totalPoints: Scalars['Float']
  /** The ID of the associated class. */
  classId: Scalars['ID']
  /** The associated class. */
  class: Class
  /** The ID of the associated assignment category. */
  assignmentCategoryId?: Maybe<Scalars['ID']>
  /** The associated assignment category. */
  assignmentCategory?: Maybe<AssignmentCategory>
  /** Whether or not the assignment is excluded from the gradebook. */
  excluded?: Maybe<Scalars['Boolean']>
  /** The grading mode for this assignment. */
  gradingMode: AssignmentGradingMode
  /** The student assignment mode. */
  assignmentMode: AssignmentStudentAssignmentMode
  /** True if the teacher is not allowed to change the grading mode / rubric. */
  gradingConfigurationLocked: Scalars['Boolean']
  /** An optional grading rubric for this assignment. */
  rubric?: Maybe<AssignmentRubric>
  /** The ID for a node containing more information about this particular assignment. */
  relatedId?: Maybe<Scalars['ID']>
  /** A node containing more information about this particular assignment. */
  related?: Maybe<Node>
  /** Gets the average grade for each rubric category. */
  gradesByCategory: GradesByCategory
  /** All submissions for this assignment. */
  submissions: AssignmentSubmissionsConnection
  /** All students assigned to this assignment. */
  students: AssignmentStudentsConnection
  /** Gets all tags associated with this entity. */
  tags: TagsConnection
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON']
  /** Gets the currently-enabled syncs for the assignment. */
  syncs: AssignmentSyncsConnection
}

/** An assignment recorded in the gradebook for a class. */
export type AssignmentSubmissionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  studentIds?: Maybe<Scalars['ID'][]>
  submitted?: Maybe<Scalars['Boolean']>
}

/** An assignment recorded in the gradebook for a class. */
export type AssignmentStudentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** An assignment recorded in the gradebook for a class. */
export type AssignmentTagsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** An assignment recorded in the gradebook for a class. */
export type AssignmentSyncsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type AssignmentCategoriesConnection = Connection & {
  __typename?: 'AssignmentCategoriesConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: AssignmentCategoriesEdge[]
}

export type AssignmentCategoriesEdge = Edge & {
  __typename?: 'AssignmentCategoriesEdge'
  cursor: Scalars['String']
  node: AssignmentCategory
}

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategory = Node & {
  __typename?: 'AssignmentCategory'
  id: Scalars['ID']
  /** The date the category was first created. */
  createdAt: Scalars['DateTime']
  /** The date the category was last updated. */
  updatedAt: Scalars['DateTime']
  /** The ID of the class the category belongs to. */
  classId: Scalars['ID']
  /** The class the category belongs to. */
  class: Class
  /** The name of the category. */
  name: Scalars['String']
  /** The description of the category. */
  description?: Maybe<Scalars['String']>
  /** The weight of the category, with respect to the order it's displayed to the teacher. */
  orderWeight: Scalars['Float']
  /** The weight of the category, with respect to grade calculations. */
  gradeWeight: Scalars['Float']
  /** Gets the calculated grade for this category, if there is one. */
  grade?: Maybe<Scalars['Float']>
  /** Gets the total earned points for this category, if there is one. */
  points?: Maybe<Scalars['Float']>
  /** Gets the total available points for this category, if there is one. */
  totalPoints?: Maybe<Scalars['Float']>
  /** The assignments associated with the category. */
  assignments: AssignmentsConnection
}

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryGradeArgs = {
  studentIds?: Maybe<Scalars['ID'][]>
}

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryPointsArgs = {
  studentIds?: Maybe<Scalars['ID'][]>
}

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryTotalPointsArgs = {
  studentIds?: Maybe<Scalars['ID'][]>
}

/** An assignment category is a rubric item used to determine class-level weighting. */
export type AssignmentCategoryAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  studentIds?: Maybe<Scalars['ID'][]>
}

export type AssignmentCategoryInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  orderWeight?: Maybe<Scalars['Float']>
  gradeWeight?: Maybe<Scalars['Float']>
}

export type AssignmentCategoryPayload = {
  __typename?: 'AssignmentCategoryPayload'
  assignmentCategory: AssignmentCategory
}

export type AssignmentClassConfiguration = {
  __typename?: 'AssignmentClassConfiguration'
  id: Scalars['ID']
  classId: Scalars['ID']
  class: Class
  assignmentCategories?: Maybe<AssignmentCategory[]>
  gradingMode: ClassGradingMode
}

export type AssignmentClassConfigurationInput = {
  gradingMode?: Maybe<ClassGradingMode>
}

export type AssignmentClassConfigurationPayload = {
  __typename?: 'AssignmentClassConfigurationPayload'
  assignmentClassConfiguration: AssignmentClassConfiguration
}

export type AssignmentFiltersInput = {
  ungraded?: Maybe<Scalars['Boolean']>
  dueDate?: Maybe<DueDateFilterInput>
  tags?: Maybe<TagsFilter>
  categoryIds?: Maybe<Scalars['ID'][]>
}

export enum AssignmentGradingMode {
  Rubric = 'RUBRIC',
  Questions = 'QUESTIONS',
}

export type AssignmentInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  openAt?: Maybe<Scalars['DateTime']>
  dueAt?: Maybe<Scalars['DateTime']>
  rubric?: Maybe<AssignmentRubricInput>
  excluded?: Maybe<Scalars['Boolean']>
  totalPoints?: Maybe<Scalars['Float']>
  gradingMode?: Maybe<AssignmentGradingMode>
  assignmentMode?: Maybe<AssignmentStudentAssignmentMode>
  assignmentCategoryId?: Maybe<Scalars['ID']>
  assignedStudentIds?: Maybe<Scalars['ID'][]>
}

export type AssignmentPayload = {
  __typename?: 'AssignmentPayload'
  assignment: Assignment
}

export type AssignmentRubric = {
  __typename?: 'AssignmentRubric'
  categories: AssignmentRubricCategory[]
}

export type AssignmentRubricCategory = {
  __typename?: 'AssignmentRubricCategory'
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  totalPoints: Scalars['Float']
  weight?: Maybe<Scalars['Float']>
  presets?: Maybe<AssignmentRubricPreset[]>
}

export type AssignmentRubricCategoryInput = {
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  totalPoints: Scalars['Float']
  weight?: Maybe<Scalars['Float']>
  presets?: Maybe<AssignmentRubricPresetInput[]>
}

export type AssignmentRubricInput = {
  categories: AssignmentRubricCategoryInput[]
}

export type AssignmentRubricPreset = {
  __typename?: 'AssignmentRubricPreset'
  id: Scalars['String']
  points: Scalars['Float']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  weight: Scalars['Float']
}

export type AssignmentRubricPresetInput = {
  id: Scalars['String']
  points: Scalars['Float']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  weight: Scalars['Float']
}

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS',
}

export type AssignmentStudentsConnection = Connection & {
  __typename?: 'AssignmentStudentsConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: AssignmentStudentsEdge[]
}

export type AssignmentStudentsEdge = Edge & {
  __typename?: 'AssignmentStudentsEdge'
  cursor: Scalars['String']
  isMissing: Scalars['Boolean']
  node: Student
}

/** A student submission for an assignment. */
export type AssignmentSubmission = Node & {
  __typename?: 'AssignmentSubmission'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The associated assignment ID. */
  assignmentId: Scalars['ID']
  /** The associated assignment. */
  assignment: Assignment
  /** The ID of the submitting student. */
  studentId: Scalars['ID']
  /** The submitting student. */
  student: Student
  /** Whether the submission has been reviewed by the teacher. */
  reviewed?: Maybe<Scalars['Boolean']>
  /** Key represents the rubric ID, value represents the grade (as a percentage of totalPoints). */
  grades?: Maybe<Scalars['JSON']>
  /** Key represents the rubric ID, value represents the comment. */
  comments?: Maybe<Scalars['JSON']>
  /** The calculated grade for the assignment. */
  grade?: Maybe<Scalars['Float']>
  /** Whether or not the assignment is late. */
  isLate?: Maybe<Scalars['Boolean']>
  /** The ID of another node containing more information about this submission. */
  relatedId?: Maybe<Scalars['ID']>
  /** Another node containing more information about this particular submission. */
  related?: Maybe<Node>
  /** The date the submission was submitted */
  submittedAt?: Maybe<Scalars['DateTime']>
  /** The date the submission was graded */
  gradedAt?: Maybe<Scalars['DateTime']>
}

export type AssignmentSubmissionPayload = {
  __typename?: 'AssignmentSubmissionPayload'
  assignmentSubmission: AssignmentSubmission
}

export type AssignmentSubmissionsConnection = Connection & {
  __typename?: 'AssignmentSubmissionsConnection'
  averageGrade?: Maybe<Scalars['Float']>
  averagePoints?: Maybe<Scalars['Float']>
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: AssignmentSubmissionsEdge[]
}

export type AssignmentSubmissionsEdge = Edge & {
  __typename?: 'AssignmentSubmissionsEdge'
  cursor: Scalars['String']
  node: AssignmentSubmission
}

export type AssignmentSync = Node & {
  __typename?: 'AssignmentSync'
  id: Scalars['ID']
  provider: Scalars['String']
  providerId: Scalars['String']
  classSync: ClassSync
  class: Class
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type AssignmentSyncsConnection = Connection & {
  __typename?: 'AssignmentSyncsConnection'
  pageInfo: PageInfo
  edges: AssignmentSyncsEdge[]
  totalCount: Scalars['Int']
}

export type AssignmentSyncsEdge = Edge & {
  __typename?: 'AssignmentSyncsEdge'
  node: AssignmentSync
  cursor: Scalars['String']
}

export type AssignmentsConnection = Connection & {
  __typename?: 'AssignmentsConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: AssignmentsEdge[]
}

export type AssignmentsEdge = Edge & {
  __typename?: 'AssignmentsEdge'
  cursor: Scalars['String']
  node: Assignment
}

export type AssociateCanvasPayload = {
  __typename?: 'AssociateCanvasPayload'
  user: User
}

export type AssociateCleverPayload = {
  __typename?: 'AssociateCleverPayload'
  user: User
}

export type AssociateGooglePayload = {
  __typename?: 'AssociateGooglePayload'
  user: User
}

export type AssociateMsalPayload = {
  __typename?: 'AssociateMSALPayload'
  user: User
}

export type AssociateSchoologyPayload = {
  __typename?: 'AssociateSchoologyPayload'
  user: User
}

export type AssociatedClassAssignmentInput = {
  segmentId: Scalars['String']
  segmentAssignmentId: Scalars['ID']
  overrides?: Maybe<Scalars['JSON']>
  /** The class ID. */
  id: Scalars['ID']
}

export type AttachClassSyncInput = {
  /** The identifier for the provider. */
  provider: Scalars['String']
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String']
  /** The name of the class on the provider. */
  label: Scalars['String']
  /** The ID of the class to sync. */
  id: Scalars['ID']
}

export type AttachClassSyncStudentAccountInput = {
  provider: Scalars['String']
  providerId: Scalars['String']
  userInfo: BasicUserInput
  /** The current class ID to attach the student to. */
  id: Scalars['ID']
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: Maybe<Scalars['ID']>
}

export type AttachClassSyncStudentAccountPayload = {
  __typename?: 'AttachClassSyncStudentAccountPayload'
  student?: Maybe<Student>
}

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String']
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String']
}

/** An attachment represents any piece of uploaded data, attached to any other node. */
export type Attachment = {
  __typename?: 'Attachment'
  /** The unique ID of the attachment, created using the parent's ID. */
  id: Scalars['ID']
  /**
   * Gets the upload URL for the current attachment, after verifying permissions, returning the
   * updated attachment.
   */
  uploadUrl: AttachmentUploadUrlResult
  /** The signed URL to download the attachment. */
  url?: Maybe<SignedFetchResult>
  /** The current field values for the attachment, to store in either a database or cache. */
  field?: Maybe<StoredAttachment>
}

/** An attachment represents any piece of uploaded data, attached to any other node. */
export type AttachmentUploadUrlArgs = {
  input: AttachmentUploadInput
}

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput
  /** The original name of the file. */
  name: Scalars['String']
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float']
}

export type AttachmentUploadUrlResult = {
  __typename?: 'AttachmentUploadUrlResult'
  /** The signed upload result, used to upload the file. */
  upload: SignedUploadResult
  /** The updated attachment, for updating local stores. */
  attachment: Attachment
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String']
}

export type Badge = Node & {
  __typename?: 'Badge'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The internal name of the badge. */
  name: Scalars['String']
  /** The event type. */
  event: BadgeEventType
  /** The configuration for when the badge is awarded. */
  eventConfig: Scalars['JSON']
  /** The image to display to users for the badge. */
  image: Scalars['String']
  /** The label of the badge, displayed in the user's management interface. */
  label: Scalars['String']
  /** The description of the badge, displayed in the user's management interface. */
  description: Scalars['String']
  /** The text displayed to the user when they earn the badge. */
  awardedText: Scalars['String']
  /** Gets the users that have been awarded this badge. */
  users: UsersConnection
}

export type BadgeUsersArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export enum BadgeEventType {
  Segment = 'segment',
  Interaction = 'interaction',
  Badge = 'badge',
}

export type BadgeInput = {
  name?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  awardedText?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  event?: Maybe<BadgeEventType>
  eventConfig?: Maybe<Scalars['JSON']>
}

export type BadgePayload = {
  __typename?: 'BadgePayload'
  badge: Badge
}

export type BadgesConnection = Connection & {
  __typename?: 'BadgesConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: BadgesEdge[]
}

export type BadgesEdge = Edge & {
  __typename?: 'BadgesEdge'
  node: Badge
  cursor: Scalars['String']
}

export type BasicUser = {
  __typename?: 'BasicUser'
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
}

export type BasicUserInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
}

export type BasicUserTask = UserTask & {
  __typename?: 'BasicUserTask'
  identifier: Scalars['String']
  completedAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['DateTime']>
}

export type BeginSchoologyAuthenticationPayload = {
  __typename?: 'BeginSchoologyAuthenticationPayload'
  token: Scalars['String']
  tokenSecret: Scalars['String']
}

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID']
}

export type CancelSubscriptionInput = {
  id: Scalars['ID']
}

export type CanvasAuthCodeInput = {
  code: Scalars['String']
  redirectUri: Scalars['String']
}

export type CanvasAuthentication = {
  __typename?: 'CanvasAuthentication'
  url: Scalars['String']
  consumerKey: Scalars['String']
}

export type CanvasCredentialsTokenResponse = {
  __typename?: 'CanvasCredentialsTokenResponse'
  token: Scalars['String']
}

export type CanvasGroupSet = {
  __typename?: 'CanvasGroupSet'
  id: Scalars['String']
  name: Scalars['String']
  createdAt: Scalars['DateTime']
}

export type CanvasGroupSetGroup = {
  __typename?: 'CanvasGroupSetGroup'
  id: Scalars['String']
  name: Scalars['String']
  createdAt: Scalars['DateTime']
  leaderStudentId: Scalars['ID']
  memberStudentIds: Scalars['ID'][]
}

export type CanvasGroupSetGroups = {
  __typename?: 'CanvasGroupSetGroups'
  groups: CanvasGroupSetGroup[]
}

export type CanvasGroupSets = {
  __typename?: 'CanvasGroupSets'
  groupSets: CanvasGroupSet[]
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession'
  id: Scalars['String']
  type: CheckoutSessionType
  amountInCents: Scalars['Int']
}

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT',
}

/** A class is a collection of students. */
export type Class = Node & {
  __typename?: 'Class'
  /** An identifier for the class. */
  id: Scalars['ID']
  /** The name of the class. */
  name: Scalars['String']
  /** The description for the class. */
  description?: Maybe<Scalars['String']>
  /** The weight of this class. */
  weight: Scalars['Float']
  /** The public identifier for the class. Used to add students. */
  classId?: Maybe<Scalars['String']>
  /** The site that owns the class. */
  site: Site
  /** Whether or not the current user can invite new students to this class. */
  canInvite: Scalars['Boolean']
  /** Whether or not the current user can update this class. */
  canUpdate: Scalars['Boolean']
  /** Whether or not the current user can remove students from this class. */
  canRemoveStudents: Scalars['Boolean']
  /** Additional configuration related to the class. */
  configuration?: Maybe<Scalars['JSON']>
  /** The teachers that are related to the class. */
  teachers: ClassTeachersConnection
  /** The students that belong to the class. */
  students: ClassStudentsConnection
  /** Gets interactions belonging to this class. */
  interactions: InteractionsConnection
  /** The time the class was first created. */
  createdAt: Scalars['DateTime']
  /** The last time the class was updated. */
  updatedAt: Scalars['DateTime']
  /** The time the class was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>
  /** The time the class was archived. */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The subscriptions associated with the class. */
  subscriptions: SubscriptionsConnection
  /** Whether or not the current user has a valid subscription to this class. */
  hasValidSubscription: Scalars['Boolean']
  /** Additional segment information associated with the course. */
  segmentMetadata?: Maybe<Scalars['JSON']>
  /** The merged course configuration. */
  courseConfiguration?: Maybe<Scalars['JSON']>
  /** The merged & baked course configuration. */
  bakedCourseConfiguration?: Maybe<Scalars['JSON']>
  /** Gets the automatically calculated segment decorations for the current class. */
  segmentDecorations?: Maybe<SegmentDecoration[]>
  /**
   * Retrieves the scheduled segments of a class
   * @deprecated scheduledSegments has been merged into segmentMetadata
   */
  scheduledSegments?: Maybe<Scalars['JSON']>
  /** The course version that the class uses. */
  courseVersion?: Maybe<CourseVersion>
  /** Gets the time on task report for the specified segments. */
  timeOnTask: TimeOnTaskResult
  /** Gets the content visited report for the current class. */
  contentVisited: ContentVisitedResult
  disableCoursePinAutoreset?: Maybe<Scalars['Boolean']>
  /** The default groups configuration for the class. */
  groups?: Maybe<Scalars['JSON']>
  /** Gets a class assignment related to a segment ID. */
  classAssignmentForSegment?: Maybe<ClassAssignment>
  /** Gets all announcements associated with this class. */
  announcements: AnnouncementsConnection
  /** @deprecated Use the new common Class Sync API instead. */
  canvasGroupSets?: Maybe<CanvasGroupSets>
  /** @deprecated Use the new common Class Sync API instead. */
  canvasGroupSetGroups?: Maybe<CanvasGroupSetGroups>
  /** Gets the currently-active syncs for the class. */
  syncs: ClassSyncsConnection
  availableGroupImports: GroupImport[]
  availableGroupImportGroups: GroupImportGroups
  /** Gets all available grade category imports for the current class. */
  availableGradeCategoryImports: GradeCategoryImportSource[]
  /** Gets all assignments for the current class. */
  assignments: AssignmentsConnection
  /** Gets the assignment-specific configuration for this class. */
  assignmentConfiguration?: Maybe<AssignmentClassConfiguration>
  /** The average grade for all students in this class. */
  averageGrade?: Maybe<Scalars['Float']>
  /** Gets a list of all the practices for the class. */
  practices: PracticesConnection
  /** Gets all sheet music compositions created in the current class. */
  sheetMusicCompositions: SheetMusicCompositionsConnection
  /** Gets the user tasks for the current user, for the current class. */
  userTasks: UserTask[]
}

/** A class is a collection of students. */
export type ClassTeachersArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassStudentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassInteractionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  types?: Maybe<Scalars['String'][]>
  userIds?: Maybe<Scalars['ID'][]>
}

/** A class is a collection of students. */
export type ClassSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassTimeOnTaskArgs = {
  input: TimeOnTaskInput
}

/** A class is a collection of students. */
export type ClassClassAssignmentForSegmentArgs = {
  segmentId: Scalars['String']
}

/** A class is a collection of students. */
export type ClassAnnouncementsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassCanvasGroupSetGroupsArgs = {
  groupSetId: Scalars['String']
}

/** A class is a collection of students. */
export type ClassSyncsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassAvailableGroupImportGroupsArgs = {
  input: AvailableGroupImportGroupsInput
}

/** A class is a collection of students. */
export type ClassAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  filters?: Maybe<AssignmentFiltersInput>
}

/** A class is a collection of students. */
export type ClassPracticesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A class is a collection of students. */
export type ClassSheetMusicCompositionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  userIds?: Maybe<Scalars['ID'][]>
}

/** A class is a collection of students. */
export type ClassUserTasksArgs = {
  first?: Maybe<Scalars['Int']>
  input?: Maybe<UserTasksFilterInput>
}

/** A class assignment is an instance of an assignment associated with a class. */
export type ClassAssignment = Node & {
  __typename?: 'ClassAssignment'
  /** The ID for the class assignment. */
  id: Scalars['ID']
  /** The class the class assignment is associated with. */
  class: Class
  /** The underlying grade assignment. */
  assignment: Assignment
  /** The parent assignment. This is only set if this class assignment is not custom. */
  segmentAssignment?: Maybe<SegmentAssignment>
  /** The ID of the parent assignment. This is only set if this class assignment is not custom. */
  segmentAssignmentId?: Maybe<Scalars['ID']>
  /** Gets the filtered configuration for the parent assignment, if available. */
  assignmentConfiguration?: Maybe<Scalars['JSON']>
  /** The type of assignment. Be it individual, group, etc. */
  type: SegmentAssignmentType
  /** The maximum number of attempts allowed for this assignment. */
  maxAttempts: Scalars['Int']
  /** If a time limit is configured for the assignment, the limit in seconds. */
  timeLimit?: Maybe<Scalars['Int']>
  /** The number of remaining attempts for the current student. */
  remainingAttempts?: Maybe<Scalars['Int']>
  /** Configured overrides for the assignment. */
  overrides?: Maybe<Scalars['JSON']>
  /** Gets a summary of the submissions for this assignment. */
  submissionSummary?: Maybe<ClassAssignmentSubmissionSummary>
  /** Gets the average grade for each question. */
  gradesByQuestion: GradesByQuestion
  /** Whether or not answers are revealed once all students complete the assignment. */
  revealAnswers: Scalars['Boolean']
  /** Whether or not answers are revealed in real time. */
  revealAnswersRealTime: Scalars['Boolean']
  /** If the current assignment has submissions that need to be reviewed. */
  hasUnreviewedSubmissions?: Maybe<Scalars['Boolean']>
  /** The submission for the current student for this assignment. */
  submission?: Maybe<ClassAssignmentSubmission>
  /** The segment the class assignment is associated with. Only set if the class assignment is not custom. */
  segmentId?: Maybe<Scalars['String']>
  /** The name of the class assignment. This is only set if the class assignment is custom. */
  name?: Maybe<Scalars['String']>
  /** The groups configuration for the class assignment. */
  groups?: Maybe<Scalars['JSON']>
  /** The date the class assignment was first created. */
  createdAt: Scalars['DateTime']
  /** The date the class assignment was last updated. */
  updatedAt: Scalars['DateTime']
}

/** A class assignment is an instance of an assignment associated with a class. */
export type ClassAssignmentAssignmentConfigurationArgs = {
  includeDisabled?: Maybe<Scalars['Boolean']>
}

export type ClassAssignmentAndSubmissionPayload = {
  __typename?: 'ClassAssignmentAndSubmissionPayload'
  classAssignment: ClassAssignment
  classAssignmentSubmission: ClassAssignmentSubmission
}

export type ClassAssignmentInput = {
  groups?: Maybe<Scalars['JSON']>
  revealAnswers?: Maybe<Scalars['Boolean']>
  revealAnswersRealTime?: Maybe<Scalars['Boolean']>
  type?: Maybe<SegmentAssignmentType>
  maxAttempts?: Maybe<Scalars['Int']>
  timeLimit?: Maybe<Scalars['Int']>
  overrides?: Maybe<Scalars['JSON']>
}

export type ClassAssignmentPayload = {
  __typename?: 'ClassAssignmentPayload'
  classAssignment: ClassAssignment
}

/** A submission a student has made against a class assignment. */
export type ClassAssignmentSubmission = Node & {
  __typename?: 'ClassAssignmentSubmission'
  /** The ID for the submission. */
  id: Scalars['ID']
  /** The student that made the submission. */
  student: Student
  /** The ID of the student that made the submission. */
  studentId: Scalars['ID']
  /** The underlying assignment submission. */
  assignmentSubmission: AssignmentSubmission
  /** The ID of the underlying assignment submission. */
  assignmentSubmissionId: Scalars['ID']
  /** The class assignment the submission is associated with. */
  classAssignment: ClassAssignment
  /** The assignment configuration associated with the ClassAssignment associated with this submission. */
  assignmentConfiguration?: Maybe<Scalars['JSON']>
  /** The ID of the class assignment the submission is associated with. */
  classAssignmentId: Scalars['ID']
  /** The class associated with the submission. */
  class: Class
  /** An object containing submission data. Question ID -> object containing submission data. */
  submissionData?: Maybe<Scalars['JSON']>
  /** The date the submission was created. */
  createdAt: Scalars['DateTime']
  /** The date the submission was last updated. */
  updatedAt: Scalars['DateTime']
}

export type ClassAssignmentSubmissionInput = {
  classAssignmentId: Scalars['ID']
  studentId: Scalars['ID']
  grades?: Maybe<Scalars['JSON']>
  comments?: Maybe<Scalars['JSON']>
  submissionData?: Maybe<Scalars['JSON']>
}

export type ClassAssignmentSubmissionPayload = {
  __typename?: 'ClassAssignmentSubmissionPayload'
  classAssignmentSubmission: ClassAssignmentSubmission
}

export type ClassAssignmentSubmissionSummary = {
  __typename?: 'ClassAssignmentSubmissionSummary'
  /** Reporting for each question contained within the assignment. */
  questions: ClassAssignmentSubmissionSummaryQuestion[]
  /** The average total grade for all submissions. */
  averageGrade?: Maybe<Scalars['Float']>
}

export type ClassAssignmentSubmissionSummaryAnswerDistribution = {
  __typename?: 'ClassAssignmentSubmissionSummaryAnswerDistribution'
  /** The value for the answer. */
  answer: Scalars['String']
  /** The percentage of students that chose this answer. */
  percentage: Scalars['Float']
}

export type ClassAssignmentSubmissionSummaryQuestion = {
  __typename?: 'ClassAssignmentSubmissionSummaryQuestion'
  /** The ID of the question. */
  id: Scalars['String']
  /** The average grade for the question. */
  averageGrade: Scalars['Float']
  /** If the question is auto-gradeable, the distribution of answers for the question. */
  answerDistribution?: Maybe<ClassAssignmentSubmissionSummaryAnswerDistribution[]>
}

export type ClassAssignmentSubmissionsConnection = Connection & {
  __typename?: 'ClassAssignmentSubmissionsConnection'
  edges: ClassAssignmentSubmissionsEdge[]
  totalCount: Scalars['Int']
  pageInfo: PageInfo
}

export type ClassAssignmentSubmissionsEdge = Edge & {
  __typename?: 'ClassAssignmentSubmissionsEdge'
  node: ClassAssignmentSubmission
  cursor: Scalars['String']
}

export type ClassAssignmentsConnection = Connection & {
  __typename?: 'ClassAssignmentsConnection'
  edges: ClassAssignmentsEdge[]
  totalCount: Scalars['Int']
  pageInfo: PageInfo
}

export type ClassAssignmentsEdge = Edge & {
  __typename?: 'ClassAssignmentsEdge'
  node: ClassAssignment
  cursor: Scalars['String']
}

export enum ClassGradingMode {
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS',
  PurePoints = 'PURE_POINTS',
}

export type ClassImplementationGuideReport = {
  __typename?: 'ClassImplementationGuideReport'
  implementationGuides: ClassImplementationGuideReportItem[]
}

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID']
}

export type ClassImplementationGuideReportItem = {
  __typename?: 'ClassImplementationGuideReportItem'
  implementationGuide: Scalars['String']
  grade?: Maybe<Scalars['Float']>
}

export type ClassInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  weight?: Maybe<Scalars['Float']>
  configuration?: Maybe<Scalars['JSON']>
  segmentMetadata?: Maybe<Scalars['JSON']>
  courseId?: Maybe<Scalars['ID']>
  disableCoursePinAutoreset?: Maybe<Scalars['Boolean']>
  groups?: Maybe<Scalars['JSON']>
}

/** The payload returned after mutating a Class. */
export type ClassPayload = {
  __typename?: 'ClassPayload'
  /** The created or updated Class. */
  class: Class
}

export type ClassReportInput = {
  classIds: Scalars['ID'][]
  igNames?: Maybe<Scalars['String'][]>
}

export type ClassReportResult = {
  __typename?: 'ClassReportResult'
  entries: ClassReportResultItem[]
}

export type ClassReportResultItem = {
  __typename?: 'ClassReportResultItem'
  className: Scalars['String']
  studentName?: Maybe<Scalars['String']>
  classId: Scalars['ID']
  studentId?: Maybe<Scalars['ID']>
  contentVisited?: Maybe<Scalars['Float']>
  timeOnTask?: Maybe<Scalars['Float']>
  averageTimeOnTask?: Maybe<Scalars['Float']>
  grade?: Maybe<Scalars['Float']>
}

export enum ClassRole {
  Owner = 'OWNER',
  Member = 'MEMBER',
}

/** Class -> Students */
export type ClassStudentsConnection = Connection & {
  __typename?: 'ClassStudentsConnection'
  pageInfo: PageInfo
  edges: ClassStudentsEdge[]
  totalCount: Scalars['Int']
}

export type ClassStudentsEdge = Edge & {
  __typename?: 'ClassStudentsEdge'
  cursor: Scalars['String']
  node: Student
  /** The grade for the student in this class. */
  grade?: Maybe<Scalars['Float']>
}

export type ClassSync = Node & {
  __typename?: 'ClassSync'
  id: Scalars['ID']
  class: Class
  user: User
  provider: Scalars['String']
  providerId: Scalars['String']
  label: Scalars['String']
  students: ClassSyncStudent[]
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type ClassSyncProvider = {
  __typename?: 'ClassSyncProvider'
  identifier: Scalars['String']
  name: Scalars['String']
  authProviderId?: Maybe<Scalars['String']>
  authProviderName?: Maybe<Scalars['String']>
  supportsAssignmentSync: Scalars['Boolean']
}

export type ClassSyncProviderClass = {
  __typename?: 'ClassSyncProviderClass'
  id: Scalars['String']
  name: Scalars['String']
}

export type ClassSyncStudent = {
  __typename?: 'ClassSyncStudent'
  id: Scalars['String']
  userInfo: BasicUser
}

export type ClassSyncsConnection = Connection & {
  __typename?: 'ClassSyncsConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: ClassSyncsEdge[]
}

export type ClassSyncsEdge = Edge & {
  __typename?: 'ClassSyncsEdge'
  node: ClassSync
  cursor: Scalars['String']
}

export type ClassTeachersConnection = Connection & {
  __typename?: 'ClassTeachersConnection'
  pageInfo: PageInfo
  edges: ClassTeachersEdge[]
  totalCount: Scalars['Int']
}

export type ClassTeachersEdge = Edge & {
  __typename?: 'ClassTeachersEdge'
  cursor: Scalars['String']
  node: Teacher
  role: ClassRole
  permissions: Scalars['String'][]
}

export type ClassesConnection = Connection & {
  __typename?: 'ClassesConnection'
  pageInfo: PageInfo
  edges: ClassesEdge[]
  totalCount: Scalars['Int']
}

export type ClassesEdge = Edge & {
  __typename?: 'ClassesEdge'
  cursor: Scalars['String']
  node: Class
}

export type Comment = Node & {
  __typename?: 'Comment'
  id: Scalars['ID']
  /** The user that posted the comment. */
  user: User
  /** The comment itself. */
  comment: Scalars['String']
  /** Any additional metadata associated with the comment. Depends on the use-case. */
  metadata?: Maybe<Scalars['JSON']>
  /** If available, the name of the content the comment is associated with. */
  contentName?: Maybe<Scalars['String']>
  /** The associated node. */
  node: Node
  /** The ID of the associated node. */
  nodeId: Scalars['ID']
  /** The date the comment was first created. */
  createdAt: Scalars['DateTime']
  /** The date the comment was last updated. */
  updatedAt: Scalars['DateTime']
  /** The date the comment was approved. */
  approvedAt?: Maybe<Scalars['DateTime']>
  /** The date the comment was rejected. */
  rejectedAt?: Maybe<Scalars['DateTime']>
  /** The reason the comment was rejected. */
  rejectedReason?: Maybe<Scalars['String']>
}

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED',
}

export type CommentInput = {
  comment: Scalars['String']
  metadata?: Maybe<Scalars['JSON']>
}

export type CommentOnNodeInput = {
  /** The ID of the node to comment on. */
  id: Scalars['ID']
  /** The actual text of the comment. */
  comment: Scalars['String']
  /** Any additional metadata to include with the comment. */
  metadata?: Maybe<Scalars['JSON']>
}

export type CommentPayload = {
  __typename?: 'CommentPayload'
  /** The created comment. */
  comment: Comment
}

export type CommentsConnection = Connection & {
  __typename?: 'CommentsConnection'
  unread?: Maybe<Scalars['Int']>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: CommentsEdge[]
}

export type CommentsEdge = Edge & {
  __typename?: 'CommentsEdge'
  node: Comment
  cursor: Scalars['String']
}

export type CompleteAssignmentUserTask = UserTask & {
  __typename?: 'CompleteAssignmentUserTask'
  identifier: Scalars['String']
  completedAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['DateTime']>
  payload: CompleteAssignmentUserTaskPayload
}

export type CompleteAssignmentUserTaskPayload = {
  __typename?: 'CompleteAssignmentUserTaskPayload'
  classId: Scalars['ID']
  assignmentId: Scalars['ID']
  assignmentName: Scalars['String']
  dueAt?: Maybe<Scalars['DateTime']>
  segmentId?: Maybe<Scalars['String']>
}

/** The relay-compliant Connection interface. Contains all connection information. */
export type Connection = {
  /** Contains information about the current page and if there are other pages available. */
  pageInfo: PageInfo
  /** An array of all of the resulting edges in this connection. */
  edges: Edge[]
  /** The total count of all results in this current connection. */
  totalCount: Scalars['Int']
}

export type ContentVisited = {
  __typename?: 'ContentVisited'
  studentUserId: Scalars['ID']
  contentVisited: Scalars['Float']
}

export type ContentVisitedResult = {
  __typename?: 'ContentVisitedResult'
  contentVisited: ContentVisited[]
  average: Scalars['Float']
}

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID']
}

export type CopySegmentSettingsInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: Maybe<Scalars['String'][]>
  /** The IDs of the classes to copy this information to. */
  destinationIds: Scalars['ID'][]
}

export type CopySegmentSettingsPayload = {
  __typename?: 'CopySegmentSettingsPayload'
  /** The modified classes. */
  classes: Class[]
}

/** A course is a collection of segments and information relating to curriculum. */
export type Course = Node & {
  __typename?: 'Course'
  /** The ID of the course. */
  id: Scalars['ID']
  /** The name of the course. */
  name: Scalars['String']
  /** The override label for the course, displayed to teachers and students. */
  label?: Maybe<Scalars['String']>
  /** Whether or not the course is hidden from public view by default. */
  isHidden?: Maybe<Scalars['Boolean']>
  /** Whether or not the course is a build-your-own-course. */
  isByoc?: Maybe<Scalars['Boolean']>
  /** Defaults used when creating new classes with this course. */
  classConfiguration?: Maybe<Scalars['JSON']>
  /** Basic configuration, for display on the homepage or in a catalog. */
  basicConfiguration?: Maybe<Scalars['JSON']>
  /** Gets the latest published course version. */
  latestPublishedVersion?: Maybe<CourseVersion>
  /** Gets all versions for the course. */
  versions: CourseVersionsConnection
  /** The time the course was originally created. */
  createdAt: Scalars['DateTime']
  /** The time the course was last updated. */
  updatedAt: Scalars['DateTime']
}

/** A course is a collection of segments and information relating to curriculum. */
export type CourseVersionsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type CourseInput = {
  name?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  isHidden?: Maybe<Scalars['Boolean']>
  isByoc?: Maybe<Scalars['Boolean']>
  configuration?: Maybe<Scalars['JSON']>
  classConfiguration?: Maybe<Scalars['JSON']>
}

/** The payload returned when creating or updating a course. */
export type CoursePayload = {
  __typename?: 'CoursePayload'
  course: Course
}

export type CourseVersion = Node & {
  __typename?: 'CourseVersion'
  id: Scalars['ID']
  /** The name of the version. */
  name: Scalars['String']
  /** The full configuration for this course version. */
  configuration?: Maybe<Scalars['JSON']>
  /** The root segment ID for the configuration. */
  rootSegmentId: Scalars['String']
  /** The classes that use this course. */
  classes: ClassesConnection
  /** The course this version is associated with. */
  course: Course
  /** The time this version was published. */
  publishedAt?: Maybe<Scalars['DateTime']>
  /** The time this version was originally created. */
  createdAt: Scalars['DateTime']
  /** The time this version was last updated. */
  updatedAt: Scalars['DateTime']
  /** The default rubric configuration for the course version. */
  rubric?: Maybe<Scalars['JSON']>
}

export type CourseVersionClassesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type CourseVersionInput = {
  name?: Maybe<Scalars['String']>
}

export type CourseVersionPayload = {
  __typename?: 'CourseVersionPayload'
  courseVersion: CourseVersion
}

export type CourseVersionsConnection = Connection & {
  __typename?: 'CourseVersionsConnection'
  pageInfo: PageInfo
  edges: CourseVersionsEdge[]
  totalCount: Scalars['Int']
}

export type CourseVersionsEdge = Edge & {
  __typename?: 'CourseVersionsEdge'
  cursor: Scalars['String']
  node: CourseVersion
}

export type CoursesConnection = Connection & {
  __typename?: 'CoursesConnection'
  pageInfo: PageInfo
  edges: CoursesEdge[]
  totalCount: Scalars['Int']
}

export type CoursesEdge = Edge & {
  __typename?: 'CoursesEdge'
  cursor: Scalars['String']
  node: Course
}

export type CreateAnnouncementInput = {
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID']
  name: Scalars['String']
  configuration?: Maybe<Scalars['JSON']>
}

export type CreateAssignmentCategoryInput = {
  /** The class ID */
  id: Scalars['ID']
  category: AssignmentCategoryInput
}

export type CreateAssignmentInput = {
  /** The ID of the class to create the assignment underneath. */
  id: Scalars['ID']
  assignment: AssignmentInput
}

/**
 * Input required for creating a new assignment submission.
 * These will be used primarily for teachers creating manual entries.
 */
export type CreateAssignmentSubmissionInput = {
  /** The ID of the assignment to create a submission for. */
  id: Scalars['ID']
  /** The ID of the student to create a submission for. */
  studentId: Scalars['ID']
  /** Whether or not to immediately submit the new submission. */
  submit?: Maybe<Scalars['Boolean']>
}

export type CreateCheckoutSessionInput = {
  /** The class to create a checkout session for. */
  id: Scalars['ID']
  /** The URL to redirect to on success. */
  successUrl: Scalars['String']
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String']
}

export type CreateCheckoutSessionPayload = {
  __typename?: 'CreateCheckoutSessionPayload'
  sessions: CheckoutSession[]
  license: License
}

export type CreateClassInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  weight?: Maybe<Scalars['Float']>
  siteId?: Maybe<Scalars['ID']>
  configuration?: Maybe<Scalars['JSON']>
  courseId?: Maybe<Scalars['ID']>
}

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID']
  /** The name of the new version to create. */
  name: Scalars['String']
}

export type CreateDirectMessageThreadInput = {
  /** An array of user IDs to send the message to. */
  recipientIds: Scalars['ID'][]
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: Maybe<Scalars['String']>
  /** The subject of the thread. */
  subject: Scalars['String']
  /** The message to send. */
  message: Scalars['String']
}

export type CreateDirectMessageThreadPayload = {
  __typename?: 'CreateDirectMessageThreadPayload'
  /** The thread the message belongs to. */
  directMessageThread: DirectMessageThread
  /** The first-created comment. */
  comment: Comment
}

export type CreatePracticeResponse = {
  __typename?: 'CreatePracticeResponse'
  practice: Practice
  uploadPayload: MediaUploadPayload
}

export type CreateStudentInput = {
  username?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  classIds?: Maybe<Scalars['ID'][]>
}

export type CreateVideoOnDemandInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  sizeInBytes: Scalars['Int']
}

export type CreateVideoOnDemandPayload = {
  __typename?: 'CreateVideoOnDemandPayload'
  /** The created videoOnDemand */
  videoOnDemand: VideoOnDemand
  /** The URL to upload the video to. */
  uploadUrl: Scalars['String']
}

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID']
}

export type DeleteNodeResponse = {
  __typename?: 'DeleteNodeResponse'
  /** The ID of the deleted node. */
  id: Scalars['ID']
  /** The number of rows deleted. */
  numDeleted: Scalars['Int']
}

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID']
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: Maybe<Scalars['ID']>
}

export type DeleteSegmentPayload = {
  __typename?: 'DeleteSegmentPayload'
  /** The ID of the segment that was deleted. */
  segmentId: Scalars['ID']
}

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID']
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID']
}

export type DetachSegmentPayload = {
  __typename?: 'DetachSegmentPayload'
  /** The reloaded course version associated with the segment. */
  courseVersion: CourseVersion
}

/** A direct message thread represents a collection of messages sent from one user to another. */
export type DirectMessageThread = Node & {
  __typename?: 'DirectMessageThread'
  id: Scalars['ID']
  /** The ID of the user that created the thread. */
  userId: Scalars['ID']
  /** The user that created the thread. */
  user: User
  /** The users that belong to the thread. */
  recipients: User[]
  /** The IDs of the users that belong to the thread. */
  recipientIds: Scalars['ID'][]
  /** An optional label to use to override the recipients. */
  recipientsLabel?: Maybe<Scalars['String']>
  /** The subject of the thread. */
  subject: Scalars['String']
  /** The time the thread was created. */
  createdAt: Scalars['DateTime']
  /** The last time the thread was updated. */
  updatedAt: Scalars['DateTime']
  /** Gets all comments associated with this entity. */
  comments: CommentsConnection
  /** Whether or not the current user can comment on this entity. */
  canComment: Scalars['Boolean']
}

/** A direct message thread represents a collection of messages sent from one user to another. */
export type DirectMessageThreadCommentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type DirectMessageThreadPayload = {
  __typename?: 'DirectMessageThreadPayload'
  /** The thread just updated. */
  directMessageThread: DirectMessageThread
}

export type DirectMessageThreadsConnection = Connection & {
  __typename?: 'DirectMessageThreadsConnection'
  /** The total number of unread messages inside each of the returned threads. */
  unread?: Maybe<Scalars['Int']>
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: DirectMessageThreadsEdge[]
}

export type DirectMessageThreadsEdge = Edge & {
  __typename?: 'DirectMessageThreadsEdge'
  cursor: Scalars['String']
  node: DirectMessageThread
}

export type DirectMessageThreadsFilters = {
  /** Other features are supposed to add to this later... */
  noop?: Maybe<Scalars['String']>
  classIds?: Maybe<Scalars['ID'][]>
}

/** A district is an organization of other models. */
export type District = Node & {
  __typename?: 'District'
  /** The ID of the district. */
  id: Scalars['ID']
  /** The name of the district. */
  name: Scalars['String']
  /** The city the district is in. */
  city?: Maybe<Scalars['String']>
  /** The 2-character state code for the state the district is in. */
  state?: Maybe<Scalars['String']>
  /** The primary point of contact's name for the district. */
  contactName?: Maybe<Scalars['String']>
  /** The primary point of contact's email for the district. */
  contactEmail?: Maybe<Scalars['String']>
  /** The time the district was created. */
  createdAt: Scalars['DateTime']
  /** The last time the district was updated. */
  updatedAt: Scalars['DateTime']
  /** Gets all sites inside this district. */
  sites: SitesConnection
}

/** A district is an organization of other models. */
export type DistrictSitesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type DistrictInput = {
  name?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  contactName?: Maybe<Scalars['String']>
  contactEmail?: Maybe<Scalars['String']>
}

/** The payload returned when updating a district. */
export type DistrictPayload = {
  __typename?: 'DistrictPayload'
  district: District
}

export type DistrictsConnection = Connection & {
  __typename?: 'DistrictsConnection'
  pageInfo: PageInfo
  edges: DistrictsEdge[]
  totalCount: Scalars['Int']
}

export type DistrictsEdge = Edge & {
  __typename?: 'DistrictsEdge'
  cursor: Scalars['String']
  node: District
}

export type DueDateFilterInput = {
  type: DueDateFilterType
  /** Defaults to the current date if not specified. */
  date?: Maybe<Scalars['DateTime']>
}

export enum DueDateFilterType {
  Before = 'BEFORE',
  After = 'AFTER',
}

/** The relay-compliant Edge interface. Represents a single result in a connection. */
export type Edge = {
  /** An identifier for the current node. Use this in the `after` argument for pagination. */
  cursor: Scalars['String']
  /** The actual resulting node. */
  node: Node
}

export enum FilterOperation {
  And = 'AND',
  Or = 'OR',
}

export type GenerateCanvasCredentialsTokenInput = {
  siteId?: Maybe<Scalars['ID']>
  districtId?: Maybe<Scalars['ID']>
}

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID']
}

export type GenerateMicrosoftTenantTokenResponse = {
  __typename?: 'GenerateMicrosoftTenantTokenResponse'
  token: Scalars['String']
}

export type GeneratePdfInput = {
  type: Scalars['String']
  requestData: Scalars['JSON']
}

export type GenerateStudentsInput = {
  /** The ID of the class to generate students for. */
  id: Scalars['ID']
  count: Scalars['Int']
}

/** The payload returned after generating students. */
export type GenerateStudentsPayload = {
  __typename?: 'GenerateStudentsPayload'
  /** The number of student screated. */
  createdStudents: Scalars['Int']
}

/** Represents a dynamically-generated PDF. */
export type GeneratedPdf = Node & {
  __typename?: 'GeneratedPDF'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The type of PDF being generated */
  type: Scalars['String']
  /** The current generation status. */
  status: GeneratedPdfStatus
  /** The URL to the generated PDF. */
  url?: Maybe<Scalars['String']>
  /** The data to use with the generated PDF. */
  data: Scalars['JSON']
}

export type GeneratedPdfPayload = {
  __typename?: 'GeneratedPDFPayload'
  generatedPdf: GeneratedPdf
}

export enum GeneratedPdfStatus {
  Pending = 'PENDING',
  Done = 'DONE',
  Failed = 'FAILED',
}

export type GradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID']
  grades: Scalars['JSON']
  comments: Scalars['JSON']
}

export type GradeCategoryImportSource = {
  __typename?: 'GradeCategoryImportSource'
  sourceId: Scalars['String']
  name: Scalars['String']
}

export type GradesByCategory = {
  __typename?: 'GradesByCategory'
  categories: GradesByCategoryCategory[]
}

export type GradesByCategoryCategory = {
  __typename?: 'GradesByCategoryCategory'
  categoryId: Scalars['String']
  averageGrade: Scalars['Float']
}

export type GradesByQuestion = {
  __typename?: 'GradesByQuestion'
  questions: GradesByQuestionQuestion[]
}

export type GradesByQuestionQuestion = {
  __typename?: 'GradesByQuestionQuestion'
  questionId: Scalars['String']
  averageGrade: Scalars['Float']
}

export type GroupImport = {
  __typename?: 'GroupImport'
  importId: Scalars['String']
  name: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
}

export type GroupImportGroup = {
  __typename?: 'GroupImportGroup'
  groupId: Scalars['String']
  name: Scalars['String']
  createdAt?: Maybe<Scalars['DateTime']>
  leaderStudentId?: Maybe<Scalars['ID']>
  memberStudentIds: Scalars['ID'][]
}

export type GroupImportGroups = {
  __typename?: 'GroupImportGroups'
  groups: GroupImportGroup[]
}

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID']
  /** The ID of the source to use. */
  sourceId: Scalars['String']
}

export type ImportStudentsInput = {
  /** The ID of the class to import the students to. */
  id: Scalars['ID']
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String']
}

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String']
}

/** Represents a user interacting with the application in a way we would like to track. */
export type Interaction = Node & {
  __typename?: 'Interaction'
  /** The ID of the interaction. */
  id: Scalars['ID']
  /** The type of interaction that occurred. */
  type: Scalars['String']
  /** The reference to any node being interacted on. */
  reference?: Maybe<Scalars['ID']>
  /** Any metadata associated with the interaction. */
  metadata?: Maybe<Scalars['JSON']>
  /** The user that performed the interaction. */
  user: User
  /** The time the interaction began. */
  periodStart: Scalars['DateTime']
  /** The time the interaction ended. */
  periodEnd: Scalars['DateTime']
  /** The ID of the target user. */
  targetUserId?: Maybe<Scalars['ID']>
  /** The target user itself. */
  targetUser?: Maybe<User>
}

export type InteractionInput = {
  type: Scalars['String']
  reference?: Maybe<Scalars['ID']>
  metadata?: Maybe<Scalars['JSON']>
  periodStart: Scalars['DateTime']
  periodEnd: Scalars['DateTime']
  targetUserId?: Maybe<Scalars['ID']>
}

export type InteractionsConnection = Connection & {
  __typename?: 'InteractionsConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: InteractionsEdge[]
}

export type InteractionsEdge = Edge & {
  __typename?: 'InteractionsEdge'
  node: Interaction
  cursor: Scalars['String']
}

export enum InviteResult {
  InvitedToPlatform = 'INVITED_TO_PLATFORM',
  AddedToClass = 'ADDED_TO_CLASS',
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID']
  /** The student ID to import. */
  studentId: Scalars['String']
  /** The teacher ID to import. */
  teacherId: Scalars['String']
}

export type InviteStudentInput = {
  /** The ID of the class to invite the student to. */
  id: Scalars['ID']
  /** The email address of the student to invite. */
  email: Scalars['String']
}

export type InviteStudentPayload = {
  __typename?: 'InviteStudentPayload'
  invited: InviteResult
  class: Class
}

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID']
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID']
  /** Any default permissions to apply to the teacher. */
  permissions?: Maybe<Scalars['String'][]>
}

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String']
}

/** A lesson is media a teacher owns that can be assigned to students. */
export type Lesson = Node &
  ShareableNode & {
    __typename?: 'Lesson'
    id: Scalars['ID']
    /** The name of the lesson. */
    name: Scalars['String']
    /** The media associated with the lesson. */
    media: Media
    /** The teacher that created the lesson. */
    teacher: Teacher
    /** An attachment containing extra details associated with the lesson. */
    attachment: Attachment
    /** The date the lesson was first created. */
    createdAt: Scalars['DateTime']
    /** The date the lesson was last updated. */
    updatedAt: Scalars['DateTime']
    /** Whether or not the current user can assign this lesson. */
    canAssign: Scalars['Boolean']
    /** Whether or not the current user can update this lesson. */
    canUpdate: Scalars['Boolean']
    /** Whether or not the current user can delete this lesson. */
    canDelete: Scalars['Boolean']
    /** Whether or not the current user can download this lesson. */
    canDownload: Scalars['Boolean']
    /** The total number of students assigned to this lesson who have viewed it. */
    studentsViewed?: Maybe<Scalars['Int']>
    /** The students currently assigned to the lesson. */
    students: AssignedLessonStudentsConnection
    /** The practices completed for the lesson. */
    practices: PracticesConnection
    /** Gets all tags associated with this entity. */
    tags: TagsConnection
    /** Gets the tag summary associated with this entity. */
    tagSummary: Scalars['JSON']
    sharing: NodeSharing
  }

/** A lesson is media a teacher owns that can be assigned to students. */
export type LessonStudentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A lesson is media a teacher owns that can be assigned to students. */
export type LessonPracticesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  studentFilters?: Maybe<StudentFilterInput[]>
}

/** A lesson is media a teacher owns that can be assigned to students. */
export type LessonTagsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type LessonAssociationInput = {
  studentId: Scalars['ID']
  classId: Scalars['ID']
}

export type LessonInput = {
  name?: Maybe<Scalars['String']>
  mediaId?: Maybe<Scalars['ID']>
  attachment?: Maybe<Scalars['JSON']>
}

export type LessonPayload = {
  __typename?: 'LessonPayload'
  lesson: Lesson
}

export type LessonsAssignedStudentPairInput = {
  classId: Scalars['ID']
  studentId: Scalars['ID']
}

export type LessonsConnection = Connection & {
  __typename?: 'LessonsConnection'
  edges: LessonsEdge[]
  totalCount: Scalars['Int']
  pageInfo: PageInfo
}

export type LessonsEdge = Edge & {
  __typename?: 'LessonsEdge'
  cursor: Scalars['String']
  node: Lesson
}

/** A license represents an agreement between the company and a site. */
export type License = Node & {
  __typename?: 'License'
  id: Scalars['ID']
  /** The date the license was first created. */
  createdAt: Scalars['DateTime']
  /** The date the license was last updated. */
  updatedAt: Scalars['DateTime']
  /** The site the license is associated with. */
  site: Site
  /** The type of license. */
  type: LicenseType
  /** The date the license expires. */
  expiresAt?: Maybe<Scalars['DateTime']>
  /** The price of the license (metadata) */
  price?: Maybe<Scalars['Int']>
  /** The number of seats available on the license. */
  seats?: Maybe<Scalars['Int']>
  /** The number of seats used on the license. */
  usedSeats?: Maybe<Scalars['Int']>
  /** The monthly price for STUDENT_PAY licenses, in cents. */
  monthlyPriceInCents?: Maybe<Scalars['Int']>
  /** The pay-up-front price for STUDENT_PAY licenses. */
  payUpFrontPriceInCents?: Maybe<Scalars['Int']>
  /** The number of months paying up front affords. */
  payUpFrontMonths?: Maybe<Scalars['Int']>
  /** The Stripe product ID for the license. */
  productId?: Maybe<Scalars['String']>
  /** Gets all tags associated with this entity. */
  tags: TagsConnection
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON']
  /** The courses associated with the license. */
  courses?: Maybe<Scalars['JSON']>
}

/** A license represents an agreement between the company and a site. */
export type LicenseTagsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type LicenseInput = {
  expiresAt?: Maybe<Scalars['DateTime']>
  siteId?: Maybe<Scalars['ID']>
  type?: Maybe<LicenseType>
  price?: Maybe<Scalars['Int']>
  seats?: Maybe<Scalars['Int']>
  monthlyPriceInCents?: Maybe<Scalars['Int']>
  payUpFrontPriceInCents?: Maybe<Scalars['Int']>
  payUpFrontMonths?: Maybe<Scalars['Int']>
  productId?: Maybe<Scalars['String']>
  courses?: Maybe<Scalars['JSON']>
}

export type LicensePayload = {
  __typename?: 'LicensePayload'
  license: License
}

export enum LicenseType {
  Trial = 'TRIAL',
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
}

export type LicensesConnection = Connection & {
  __typename?: 'LicensesConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: LicensesEdge[]
}

export type LicensesEdge = Edge & {
  __typename?: 'LicensesEdge'
  cursor: Scalars['String']
  node: License
}

/** Represents an active linked authentication account for a user. */
export type LinkedAccount = {
  __typename?: 'LinkedAccount'
  /** The identifier for the provider. */
  provider: Scalars['String']
  /** The human-readable name for the provider. */
  providerName: Scalars['String']
  /** The human-readable label for this account on the provider. */
  label: Scalars['String']
}

export type ListSyncProviderClassesInput = {
  provider: Scalars['String']
}

export type LoginResponse = {
  __typename?: 'LoginResponse'
  /** The user's JWT used for authentication. */
  jwt: Scalars['String']
  /** The user itself. */
  user: User
}

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput
}

export type LoginWithCleverInput = {
  code: Scalars['String']
  redirectUri: Scalars['String']
}

export type LoginWithGoogleInput = {
  code: Scalars['String']
  redirectUri: Scalars['String']
}

export type LoginWithMsalInput = {
  authCode?: Maybe<MsalAuthCodeInput>
  tokens?: Maybe<MsalTokensInput>
}

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput
}

export type MsalAuthCodeInput = {
  code: Scalars['String']
  redirectUri: Scalars['String']
}

export type MsalTokensInput = {
  accessToken: Scalars['String']
  idToken: Scalars['String']
  refreshToken: Scalars['String']
  expiresAt: Scalars['String']
}

/** Represents any video or audio file uploaded by clients. */
export type Media = Node & {
  __typename?: 'Media'
  id: Scalars['ID']
  /** The user that created the material. */
  user: User
  /** The name of the bucket the finalized media is located in. */
  bucket?: Maybe<Scalars['String']>
  /** The path to the finalized media. */
  path?: Maybe<Scalars['String']>
  /** The internal processing UUID. */
  uuid?: Maybe<Scalars['String']>
  /** A thumbnail for the media, located at the beginning. Only applicable if a video. */
  thumbnail?: Maybe<Scalars['String']>
  /** The HLS M3U8 playlist for the video, for adaptive streaming. Only applicable if a video. */
  hls?: Maybe<Scalars['String']>
  /** The duration of the media, in seconds, if available. */
  durationInSeconds?: Maybe<Scalars['Int']>
  /** The internal video pipeline status for the media. Only applicable if a video. */
  status: MediaStatus
  /** The type of media uploaded. */
  type: MediaType
  /** Additional metadata related to the media. */
  metadata?: Maybe<MediaMetadata>
  /** The waveform for the media. */
  waveform?: Maybe<Scalars['Int'][]>
  /** Applies if status is MediaStatus.ERROR, additional error details. */
  errorDetails?: Maybe<Scalars['String']>
  /** The date the media was first submitted for processing, if applicable. */
  submittedAt?: Maybe<Scalars['DateTime']>
  /** The date the media processing was finished, if applicable. */
  processedAt?: Maybe<Scalars['DateTime']>
  /** The date the media entry was first created. */
  createdAt: Scalars['DateTime']
  /** The date the media entry was last updated. */
  updatedAt: Scalars['DateTime']
  /** Whether or not the current user viewed unread comments */
  hasUnreadComments: Scalars['Boolean']
  /** Gets all comments associated with this entity. */
  comments: CommentsConnection
  /** Whether or not the current user can comment on this entity. */
  canComment: Scalars['Boolean']
}

/** Represents any video or audio file uploaded by clients. */
export type MediaCommentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type MediaBookmark = {
  __typename?: 'MediaBookmark'
  startSeconds: Scalars['Float']
  endSeconds: Scalars['Float']
  name?: Maybe<Scalars['String']>
}

export type MediaBookmarkInput = {
  startSeconds: Scalars['Float']
  endSeconds: Scalars['Float']
  name?: Maybe<Scalars['String']>
}

export type MediaConnection = Connection & {
  __typename?: 'MediaConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: MediaEdge[]
}

export type MediaEdge = Edge & {
  __typename?: 'MediaEdge'
  cursor: Scalars['String']
  node: Media
}

export type MediaInput = {
  metadata?: Maybe<MediaMetadataInput>
  status?: Maybe<MediaStatus>
}

export type MediaMetadata = {
  __typename?: 'MediaMetadata'
  bookmarks?: Maybe<MediaBookmark[]>
}

export type MediaMetadataInput = {
  bookmarks?: Maybe<MediaBookmarkInput[]>
}

export type MediaPayload = {
  __typename?: 'MediaPayload'
  media: Media
}

export enum MediaStatus {
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Processing = 'PROCESSING',
  Complete = 'COMPLETE',
  Error = 'ERROR',
}

export enum MediaType {
  Video = 'VIDEO',
  Audio = 'AUDIO',
}

export type MediaUploadPayload = {
  __typename?: 'MediaUploadPayload'
  media: Media
  signedUrl: Scalars['String']
  extension: Scalars['String']
  path: Scalars['String']
  data?: Maybe<Scalars['JSON']>
}

export type MediaUploadUrlInput = {
  type: MediaType
  mimeType: Scalars['String']
  extension: Scalars['String']
  requestMode?: Maybe<UploadRequestMode>
  source?: Maybe<Scalars['String']>
  region?: Maybe<AccountRegion>
  /** An optional class to associate the media with. */
  classId?: Maybe<Scalars['ID']>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Deletes an existing node. */
  deleteNode: DeleteNodeResponse
  /** Restores an already-deleted node. */
  restoreNode: RestoreNodeResponse
  /** Marks notifications as read / unread. */
  readNotifications: ReadNotificationsPayload
  /** Creates a new district. */
  createDistrict: DistrictPayload
  /** Updates an existing district. */
  updateDistrict: DistrictPayload
  /** Creates a new site. */
  createSite: SitePayload
  /** Updates an existing site. */
  updateSite: SitePayload
  /** Creates a new teacher. */
  createTeacher: TeacherPayload
  /** Updates an existing teacher. */
  updateTeacher: TeacherPayload
  /** Logs in as the student user related to the teacher. */
  loginAsStudent: LoginResponse
  /** Updates an existing Student. */
  updateStudent: StudentPayload
  /** Creates a new Class. */
  createClass: ClassPayload
  /** Updates an existing Class. */
  updateClass: ClassPayload
  /** Called from a student account. Joins a class by class code. */
  joinClass: ClassPayload
  /** Removes a student from an existing class. Does not remove the student. */
  removeStudentFromClass: ClassPayload
  /** Invites a student to a class. Either adds an existing student to the class, or sends an email invitation. */
  inviteStudent: InviteStudentPayload
  /** Accepts a student invite. */
  acceptInvite: AcceptInvitePayload
  /** Archives the specified class. */
  archiveClass: ClassPayload
  /** Unarchives the specified class. */
  unarchiveClass: ClassPayload
  /** Invites an existing teacher to help manage an existing class. */
  inviteTeacherToClass: ClassPayload
  /** Removes an existing teacher from an existing class. */
  removeTeacherFromClass: ClassPayload
  /** Updates the permissions on an existing teacher inside an existing class. */
  updateTeacherClassPermissions: ClassPayload
  /** Creates a new license. */
  createLicense: LicensePayload
  /** Updates an existing license. */
  updateLicense: LicensePayload
  /** Creates a new subscription. */
  createSubscription: SubscriptionPayload
  /** Updates an existing subscription. */
  updateSubscription: SubscriptionPayload
  /** Creates a new INDIVIDUAL subscription, from an in-app-purchase. */
  createIndividualSubscription: SubscriptionPayload
  /** Creates a new checkout session (for a STUDENT_PAY subscription). */
  createCheckoutSession: CreateCheckoutSessionPayload
  /** Cancels an existing subscription. */
  cancelSubscription: SuccessResponse
  /** Requests a new trial. */
  trialSignup: TrialRequestPayload
  /** Approves a trial request. */
  approveTrialRequest: ApproveTrialRequestPayload
  /** Ignores a trial request. */
  ignoreTrialRequest: TrialRequestPayload
  /** Create a new course. */
  createCourse: CoursePayload
  /** Updates an existing course. */
  updateCourse: CoursePayload
  /** Creates a new course version. */
  createCourseVersion: CourseVersionPayload
  /** Updates an existing course version. */
  updateCourseVersion: CourseVersionPayload
  /** Publishes an existing course version. */
  publishCourseVersion: CourseVersionPayload
  /** Create or update a segment. */
  createOrUpdateSegment: SegmentPayload
  /** Deletes a segment. */
  deleteSegment: DeleteSegmentPayload
  /** Detaches a reference segment from its children. */
  detachSegment: DetachSegmentPayload
  /** Copies segment settings from one class to others. */
  copySegmentSettings: CopySegmentSettingsPayload
  /** Creates a new assignment. */
  createSegmentAssignment: SegmentAssignmentPayload
  /** Updates an existing assignment. */
  updateSegmentAssignment: SegmentAssignmentPayload
  /** Creates a new custom class assignment. */
  createClassAssignment: ClassAssignmentPayload
  /** Creates a new associated class assignment. */
  createAssociatedClassAssignment: ClassAssignmentPayload
  /** Updates an existing class assignment. */
  updateClassAssignment: ClassAssignmentPayload
  /** Creates or updates a class assignment submission grade & teacher response. */
  recordClassAssignmentSubmission: ClassAssignmentSubmissionPayload
  /** Saves submission data to an assignment submission, creating a new one if it does not already exist. */
  saveAssignmentSubmission: ClassAssignmentAndSubmissionPayload
  /** Submits an assignment submission, and returns the graded submission. */
  submitClassAssignment: ClassAssignmentSubmissionPayload
  /** Retries an existing ClassAssignment that already has submissions. */
  retrySubmission: AssignmentSubmissionPayload
  /** Updates tags on a specific node that supports it. */
  updateNodeTags: UpdateNodeTagsPayload
  /** Records a collection of interactions for the current user. */
  recordInteractions: RecordInteractionsPayload
  /** Creates a new announcement. */
  createAnnouncement: AnnouncementPayload
  /** Updates an existing announcement. */
  updateAnnouncement: AnnouncementPayload
  /** Associates a Canvas account with the current user. */
  associateCanvas: AssociateCanvasPayload
  /** Records the passed Canvas credentials for the passed token. */
  recordCanvasCredentials: SuccessResponse
  /** Generates a new token for registering Canvas credentials. */
  generateCanvasCredentialsToken: CanvasCredentialsTokenResponse
  /** Logs the user in with a Clever account. */
  loginWithClever: LoginResponse
  /** Associates a new Clever account with the existing user. */
  associateClever: AssociateCleverPayload
  /** Creates a new student. */
  createStudent: StudentPayload
  /** Generates the specified number of students. */
  generateStudents: GenerateStudentsPayload
  /** Imports the list of students from the attached spreadsheet. */
  importStudents: GenerateStudentsPayload
  /** Invites an existing Student ID / Teacher ID pair. */
  inviteStudentID: ClassPayload
  /** Attaches a class with a third-party class to enable syncing. */
  attachClassSync: ClassPayload
  /** Attaches a student account from an existing class sync. */
  attachClassSyncStudentAccount: AttachClassSyncStudentAccountPayload
  /** Logs the user in with a Google account, creating one if it does not exist. */
  loginWithGoogle: LoginResponse
  /** Associates a Google account with the current user. */
  associateGoogle: AssociateGooglePayload
  /** Logs the user in with a MSAL account, creating one if it does not exist. */
  loginWithMSAL: LoginResponse
  /** Associates a MSAL account with the current user. */
  associateMSAL: AssociateMsalPayload
  /** Creates a new video on demand. */
  createVideoOnDemand: CreateVideoOnDemandPayload
  /** Updates an existing video on demand. */
  updateVideoOnDemand: VideoOnDemandPayload
  /** Records the Microsoft Tenant ID given a JWT and a tenant ID. */
  recordMicrosoftTenant: SuccessResponse
  /** Generates a new tenant record JWT for a given site ID. */
  generateMicrosoftTenantToken: GenerateMicrosoftTenantTokenResponse
  /** Updates the current user's (or specified JWT) notification preferences. */
  updateNotificationPreferences: UserPayload
  /** Posts a new comment on a specific node that supports it. */
  commentOnNode: CommentPayload
  /** Updates an existing comment. */
  updateComment: CommentPayload
  /** Approves a comment. */
  approveComment: CommentPayload
  /** Rejects a comment. */
  rejectComment: CommentPayload
  /** Creates a new assignment. */
  createAssignment: AssignmentPayload
  /** Updates an existing assignment. */
  updateAssignment: AssignmentPayload
  /** Creates a new rubric template for the current user. */
  createRubricTemplate: RubricTemplatePayload
  /** Updates an existing rubric template. */
  updateRubricTemplate: RubricTemplatePayload
  /** Creates a new assignment category for one of this user's classes. */
  createAssignmentCategory: AssignmentCategoryPayload
  /** Updates an existing assignment category. */
  updateAssignmentCategory: AssignmentCategoryPayload
  /** Copies the assignment categories from one owned class to the other. */
  copyAssignmentCategories: AssignmentClassConfigurationPayload
  /** Creates a new assignment submission for a specific student-assignment pair. */
  createAssignmentSubmission: AssignmentSubmissionPayload
  /** Submits the specified assignment. */
  submitAssignment: AssignmentSubmissionPayload
  /** Grades an existing assignment submission. */
  gradeAssignmentSubmission: AssignmentSubmissionPayload
  /** Resets assignment submissions for a given student and assignment. */
  resetAssignmentSubmissions: ResetAssignmentSubmissionsPayload
  /** Updates the assignment configuration for a class. */
  updateAssignmentClassConfiguration: AssignmentClassConfigurationPayload
  /**
   * Bulk-updates all assignments inside a class with the same values. Potential use cases are
   * automatically updating the assigned students to the current set of students in a class.
   */
  bulkUpdateAssignmentsInClass: SuccessResponse
  /** Imports grading categories from the available provider */
  importGradeCategories: ClassPayload
  /** Creates a new direct message thread. */
  createDirectMessageThread: CreateDirectMessageThreadPayload
  /** Removes a set of recipients from a direct message thread. */
  removeDirectMessageThreadRecipients: DirectMessageThreadPayload
  /** Generates a new PDF with the requested data and payload. */
  generatePdf: GeneratedPdfPayload
  /** Gets a signed upload URL for uploading new media. */
  getMediaUploadUrl: MediaUploadPayload
  /** Updates an existing piece of media. */
  updateMedia: MediaPayload
  /** Refreshes an upload URL for an existing Media object. */
  refreshMediaUploadUrl: MediaUploadPayload
  /** Submits an uploaded piece of media for processing. */
  submitMedia: MediaPayload
  /** Creates a new practice. */
  createPractice: CreatePracticeResponse
  /** Updates an existing practice. */
  updatePractice: PracticeResponse
  /** Shares a node with the specified user IDs. */
  shareNodeWithUsers: ShareResponse
  /** Unshares a node with the specified user IDs. */
  unshareNodeWithUsers: ShareResponse
  /** Creates a new badge. */
  createBadge: BadgePayload
  /** Updates an existing badge. */
  updateBadge: BadgePayload
  /** Associates a Schoology account with the current user. */
  associateSchoology: AssociateSchoologyPayload
  /** Gets the token required for redirecting the user to authenticate with Schoology. */
  beginSchoologyAuthentication: BeginSchoologyAuthenticationPayload
  /** Creates a new Sheet Music Composition. */
  createSheetMusicComposition: SheetMusicCompositionPayload
  /** Creates a new lesson. */
  createLesson: LessonPayload
  /** Updates an existing lesson. */
  updateLesson: LessonPayload
  /** Assigns an existing lesson to students. */
  assignLesson: LessonPayload
  /** Logs in an existing user. */
  login: LoginResponse
  /** Registers a new user. */
  register: LoginResponse
  /** Impersonate another user. */
  impersonate: LoginResponse
  /** Creates a new user. */
  createUser: UserPayload
  /** Updates an existing user. */
  updateUser: UserPayload
  /** Resets a user's password. */
  resetPassword: SuccessResponse
  /** Requests a password reset for a user. */
  requestResetPassword: SuccessResponse
  /**
   * Generates a signed URL for uploading a new file, used when uploading on a create form.
   *
   * **Do not use this when uploading for an existing node.** There is a much easier way, using
   * the `Attachment` type. This method requires you to send the bucket and path to the server
   * yourself.
   */
  attachFile: Attachment
  /**
   * Deletes an existing attachment. This also deletes the file permanently from the storage
   * service.
   */
  deleteAttachment: Attachment
  /**
   * Attach a file without associating it with an existing node.
   *
   * *This is a very powerful feature and should be restricted to those who are trusted.*
   */
  attachGenericFile: SignedUploadResult
  /** Enables class-sync for an assignment. */
  syncAssignment: AssignmentPayload
}

export type MutationDeleteNodeArgs = {
  id: Scalars['ID']
  permanent?: Maybe<Scalars['Boolean']>
}

export type MutationRestoreNodeArgs = {
  id: Scalars['ID']
}

export type MutationReadNotificationsArgs = {
  input: ReadNotificationsInput
}

export type MutationCreateDistrictArgs = {
  input: DistrictInput
}

export type MutationUpdateDistrictArgs = {
  input: UpdateDistrictInput
}

export type MutationCreateSiteArgs = {
  input: SiteInput
}

export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput
}

export type MutationCreateTeacherArgs = {
  input: TeacherInput
}

export type MutationUpdateTeacherArgs = {
  input: UpdateTeacherInput
}

export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput
}

export type MutationCreateClassArgs = {
  input: CreateClassInput
}

export type MutationUpdateClassArgs = {
  input: UpdateClassInput
}

export type MutationJoinClassArgs = {
  input: JoinClassInput
}

export type MutationRemoveStudentFromClassArgs = {
  input: RemoveStudentFromClassInput
}

export type MutationInviteStudentArgs = {
  input: InviteStudentInput
}

export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput
}

export type MutationArchiveClassArgs = {
  input: ArchiveClassInput
}

export type MutationUnarchiveClassArgs = {
  input: UnarchiveClassInput
}

export type MutationInviteTeacherToClassArgs = {
  input: InviteTeacherToClassInput
}

export type MutationRemoveTeacherFromClassArgs = {
  input: RemoveTeacherFromClassInput
}

export type MutationUpdateTeacherClassPermissionsArgs = {
  input: UpdateTeacherClassPermissionsInput
}

export type MutationCreateLicenseArgs = {
  input: LicenseInput
}

export type MutationUpdateLicenseArgs = {
  input: UpdateLicenseInput
}

export type MutationCreateSubscriptionArgs = {
  input: SubscriptionInput
}

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput
}

export type MutationCreateIndividualSubscriptionArgs = {
  input: IndividualSubscriptionInput
}

export type MutationCreateCheckoutSessionArgs = {
  input: CreateCheckoutSessionInput
}

export type MutationCancelSubscriptionArgs = {
  input: CancelSubscriptionInput
}

export type MutationTrialSignupArgs = {
  input: TrialSignupInput
}

export type MutationApproveTrialRequestArgs = {
  input: ApproveTrialRequestInput
}

export type MutationIgnoreTrialRequestArgs = {
  id: Scalars['ID']
}

export type MutationCreateCourseArgs = {
  input: CourseInput
}

export type MutationUpdateCourseArgs = {
  input: UpdateCourseInput
}

export type MutationCreateCourseVersionArgs = {
  input: CreateCourseVersionInput
}

export type MutationUpdateCourseVersionArgs = {
  input: UpdateCourseVersionInput
}

export type MutationPublishCourseVersionArgs = {
  input: PublishCourseVersionInput
}

export type MutationCreateOrUpdateSegmentArgs = {
  input: SegmentInput
}

export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput
}

export type MutationDetachSegmentArgs = {
  input: DetachSegmentInput
}

export type MutationCopySegmentSettingsArgs = {
  input: CopySegmentSettingsInput
}

export type MutationCreateSegmentAssignmentArgs = {
  input: SegmentAssignmentInput
}

export type MutationUpdateSegmentAssignmentArgs = {
  input: UpdateSegmentAssignmentInput
}

export type MutationCreateClassAssignmentArgs = {
  input: ClassAssignmentInput
}

export type MutationCreateAssociatedClassAssignmentArgs = {
  input: AssociatedClassAssignmentInput
}

export type MutationUpdateClassAssignmentArgs = {
  input: UpdateClassAssignmentInput
}

export type MutationRecordClassAssignmentSubmissionArgs = {
  input: ClassAssignmentSubmissionInput
}

export type MutationSaveAssignmentSubmissionArgs = {
  input: SaveAssignmentSubmissionInput
}

export type MutationSubmitClassAssignmentArgs = {
  input: SubmitClassAssignmentInput
}

export type MutationRetrySubmissionArgs = {
  input: RetrySubmissionInput
}

export type MutationUpdateNodeTagsArgs = {
  input: UpdateNodeTagsInput
}

export type MutationRecordInteractionsArgs = {
  input: RecordInteractionsInput
}

export type MutationCreateAnnouncementArgs = {
  input: CreateAnnouncementInput
}

export type MutationUpdateAnnouncementArgs = {
  input: UpdateAnnouncementInput
}

export type MutationAssociateCanvasArgs = {
  input: LoginWithCanvasInput
}

export type MutationRecordCanvasCredentialsArgs = {
  input: RecordCanvasCredentialsInput
}

export type MutationGenerateCanvasCredentialsTokenArgs = {
  input: GenerateCanvasCredentialsTokenInput
}

export type MutationLoginWithCleverArgs = {
  input: LoginWithCleverInput
}

export type MutationAssociateCleverArgs = {
  input: LoginWithCleverInput
}

export type MutationCreateStudentArgs = {
  input: CreateStudentInput
}

export type MutationGenerateStudentsArgs = {
  input: GenerateStudentsInput
}

export type MutationImportStudentsArgs = {
  input: ImportStudentsInput
}

export type MutationInviteStudentIdArgs = {
  input: InviteStudentIdInput
}

export type MutationAttachClassSyncArgs = {
  input: AttachClassSyncInput
}

export type MutationAttachClassSyncStudentAccountArgs = {
  input: AttachClassSyncStudentAccountInput
}

export type MutationLoginWithGoogleArgs = {
  input: LoginWithGoogleInput
}

export type MutationAssociateGoogleArgs = {
  input: LoginWithGoogleInput
}

export type MutationLoginWithMsalArgs = {
  input: LoginWithMsalInput
}

export type MutationAssociateMsalArgs = {
  input: LoginWithMsalInput
}

export type MutationCreateVideoOnDemandArgs = {
  input: CreateVideoOnDemandInput
}

export type MutationUpdateVideoOnDemandArgs = {
  input: UpdateVideoOnDemandInput
}

export type MutationRecordMicrosoftTenantArgs = {
  input: RecordMicrosoftTenantInput
}

export type MutationGenerateMicrosoftTenantTokenArgs = {
  input: GenerateMicrosoftTenantTokenInput
}

export type MutationUpdateNotificationPreferencesArgs = {
  input: UpdatePreferencesInput
}

export type MutationCommentOnNodeArgs = {
  input: CommentOnNodeInput
}

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput
}

export type MutationApproveCommentArgs = {
  input: ApproveCommentInput
}

export type MutationRejectCommentArgs = {
  input: RejectCommentInput
}

export type MutationCreateAssignmentArgs = {
  input: CreateAssignmentInput
}

export type MutationUpdateAssignmentArgs = {
  input: UpdateAssignmentInput
}

export type MutationCreateRubricTemplateArgs = {
  input: RubricTemplateInput
}

export type MutationUpdateRubricTemplateArgs = {
  input: UpdateRubricTemplateInput
}

export type MutationCreateAssignmentCategoryArgs = {
  input: CreateAssignmentCategoryInput
}

export type MutationUpdateAssignmentCategoryArgs = {
  input: UpdateAssignmentCategoryInput
}

export type MutationCopyAssignmentCategoriesArgs = {
  input: CopyAssignmentCategoriesInput
}

export type MutationCreateAssignmentSubmissionArgs = {
  input: CreateAssignmentSubmissionInput
}

export type MutationSubmitAssignmentArgs = {
  input: SubmitAssignmentInput
}

export type MutationGradeAssignmentSubmissionArgs = {
  input: GradeAssignmentSubmissionInput
}

export type MutationResetAssignmentSubmissionsArgs = {
  input: ResetAssignmentSubmissionsInput
}

export type MutationUpdateAssignmentClassConfigurationArgs = {
  input: UpdateAssignmentClassConfigurationInput
}

export type MutationBulkUpdateAssignmentsInClassArgs = {
  input: BulkUpdateAssignmentsInClassInput
}

export type MutationImportGradeCategoriesArgs = {
  input: ImportGradeCategoriesInput
}

export type MutationCreateDirectMessageThreadArgs = {
  input: CreateDirectMessageThreadInput
}

export type MutationRemoveDirectMessageThreadRecipientsArgs = {
  input: RemoveDirectMessageThreadRecipientsInput
}

export type MutationGeneratePdfArgs = {
  input: GeneratePdfInput
}

export type MutationGetMediaUploadUrlArgs = {
  input: MediaUploadUrlInput
}

export type MutationUpdateMediaArgs = {
  input: UpdateMediaInput
}

export type MutationRefreshMediaUploadUrlArgs = {
  input: RefreshMediaUploadUrlInput
}

export type MutationSubmitMediaArgs = {
  input: SubmitMediaInput
}

export type MutationCreatePracticeArgs = {
  input: PracticeInput
}

export type MutationUpdatePracticeArgs = {
  input: UpdatePracticeInput
}

export type MutationShareNodeWithUsersArgs = {
  input: ShareNodeInput
}

export type MutationUnshareNodeWithUsersArgs = {
  input: ShareNodeInput
}

export type MutationCreateBadgeArgs = {
  input: BadgeInput
}

export type MutationUpdateBadgeArgs = {
  input: UpdateBadgeInput
}

export type MutationAssociateSchoologyArgs = {
  input: LoginWithSchoologyInput
}

export type MutationCreateSheetMusicCompositionArgs = {
  input: SheetMusicCompositionInput
}

export type MutationCreateLessonArgs = {
  input: LessonInput
}

export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput
}

export type MutationAssignLessonArgs = {
  input: AssignLessonInput
}

export type MutationLoginArgs = {
  name: Scalars['String']
  password: Scalars['String']
}

export type MutationRegisterArgs = {
  input: RegisterInput
}

export type MutationImpersonateArgs = {
  userId: Scalars['ID']
}

export type MutationCreateUserArgs = {
  input: UserInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput
}

export type MutationAttachFileArgs = {
  input: AttachFileInput
}

export type MutationDeleteAttachmentArgs = {
  input: DeleteAttachmentInput
}

export type MutationAttachGenericFileArgs = {
  input: SignedUploadInput
}

export type MutationSyncAssignmentArgs = {
  input: SyncAssignmentInput
}

/** The node interface is a fundamental interface that all concrete types inherit from. */
export type Node = {
  /** The generic relay-compliant identifier for this node. When decoded, contains the type name and numeric ID. */
  id: Scalars['ID']
}

/** Contains sharing information for a specific node. */
export type NodeSharing = {
  __typename?: 'NodeSharing'
  /** The users this node is shared with. */
  sharedWith: User[]
  /** The user that shared this node. */
  sharedFrom?: Maybe<User>
}

/** A notification represents a notification sent to a user. */
export type Notification = Node & {
  __typename?: 'Notification'
  id: Scalars['ID']
  /** The ID of the user the notification was sent to. */
  userId: Scalars['ID']
  /** The user the notification was sent to. */
  user: User
  /** The title of the notification. */
  title: Scalars['String']
  /** The description provided with the notification. Supports markdown. */
  description: Scalars['String']
  /** Any extra information provided with the notification. Supports markdown. */
  extra?: Maybe<Scalars['String']>
  /** An option link passed with the notification. */
  link?: Maybe<NotificationLink>
  /** Whether or not the notification is read. */
  isRead?: Maybe<Scalars['Boolean']>
  /** The date the notification was sent. */
  createdAt: Scalars['DateTime']
  /** The date the notification was last updated. */
  updatedAt: Scalars['DateTime']
}

export type NotificationChannelInfo = {
  __typename?: 'NotificationChannelInfo'
  channel: Scalars['String']
  name: Scalars['String']
  iconName: Scalars['String']
}

export type NotificationLink = {
  __typename?: 'NotificationLink'
  title: Scalars['String']
  universalPath: Scalars['String']
  instructions?: Maybe<Scalars['String']>
}

export type NotificationPreference = {
  __typename?: 'NotificationPreference'
  notificationType: Scalars['String']
  name: Scalars['String']
  description: Scalars['String']
  group: Scalars['String']
  disabledChannels: Scalars['String'][]
}

export type NotificationsConnection = Connection & {
  __typename?: 'NotificationsConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: NotificationsEdge[]
}

export type NotificationsEdge = Edge & {
  __typename?: 'NotificationsEdge'
  cursor: Scalars['String']
  node: Notification
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasPreviousPage?: Maybe<Scalars['Boolean']>
  hasNextPage?: Maybe<Scalars['Boolean']>
  startCursor?: Maybe<Scalars['String']>
  endCursor?: Maybe<Scalars['String']>
}

export type PageMetadata = {
  __typename?: 'PageMetadata'
  author?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['DateTime']>
  description?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  title: Scalars['String']
  publisher?: Maybe<Scalars['String']>
  logo?: Maybe<Scalars['String']>
}

export enum Permission {
  CreateUser = 'CREATE_USER',
  UpdateUser = 'UPDATE_USER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  ResetPassword = 'RESET_PASSWORD',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  Login = 'LOGIN',
  Impersonate = 'IMPERSONATE',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ListUser = 'LIST_USER',
  Register = 'REGISTER',
  ViewUserName = 'VIEW_USER_NAME',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  Search = 'SEARCH',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  CreateDistrict = 'CREATE_DISTRICT',
  UpdateDistrict = 'UPDATE_DISTRICT',
  ListDistrict = 'LIST_DISTRICT',
  ViewSite = 'VIEW_SITE',
  ViewOwnSite = 'VIEW_OWN_SITE',
  DeleteSite = 'DELETE_SITE',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  RestoreSite = 'RESTORE_SITE',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ListSite = 'LIST_SITE',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  CreateSite = 'CREATE_SITE',
  UpdateSite = 'UPDATE_SITE',
  ViewTeacher = 'VIEW_TEACHER',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  ListTeacher = 'LIST_TEACHER',
  CreateTeacher = 'CREATE_TEACHER',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewStudent = 'VIEW_STUDENT',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ListStudent = 'LIST_STUDENT',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  JoinClass = 'JOIN_CLASS',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  InviteStudent = 'INVITE_STUDENT',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  ViewClass = 'VIEW_CLASS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  DeleteClass = 'DELETE_CLASS',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  RestoreClass = 'RESTORE_CLASS',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListClass = 'LIST_CLASS',
  CreateClass = 'CREATE_CLASS',
  UpdateClass = 'UPDATE_CLASS',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  AcceptInvite = 'ACCEPT_INVITE',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  ViewLicense = 'VIEW_LICENSE',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  ViewSubscription = 'VIEW_SUBSCRIPTION',
  ViewOwnSubscription = 'VIEW_OWN_SUBSCRIPTION',
  DeleteSubscription = 'DELETE_SUBSCRIPTION',
  DeleteOwnSubscription = 'DELETE_OWN_SUBSCRIPTION',
  RestoreSubscription = 'RESTORE_SUBSCRIPTION',
  RestoreOwnSubscription = 'RESTORE_OWN_SUBSCRIPTION',
  CreateLicense = 'CREATE_LICENSE',
  UpdateLicense = 'UPDATE_LICENSE',
  CreateSubscription = 'CREATE_SUBSCRIPTION',
  UpdateSubscription = 'UPDATE_SUBSCRIPTION',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  ListLicenses = 'LIST_LICENSES',
  ListSubscriptions = 'LIST_SUBSCRIPTIONS',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ListUserSubscriptions = 'LIST_USER_SUBSCRIPTIONS',
  ListOwnUserSubscriptions = 'LIST_OWN_USER_SUBSCRIPTIONS',
  ListClassSubscriptions = 'LIST_CLASS_SUBSCRIPTIONS',
  ListOwnClassSubscriptions = 'LIST_OWN_CLASS_SUBSCRIPTIONS',
  CancelSubscription = 'CANCEL_SUBSCRIPTION',
  CancelOwnSubscription = 'CANCEL_OWN_SUBSCRIPTION',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RequestTrial = 'REQUEST_TRIAL',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  CreateCourse = 'CREATE_COURSE',
  UpdateCourse = 'UPDATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ListSegments = 'LIST_SEGMENTS',
  ListCourse = 'LIST_COURSE',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewSegment = 'VIEW_SEGMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteSegment = 'DELETE_SEGMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreSegment = 'RESTORE_SEGMENT',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  GetPageMetadata = 'GET_PAGE_METADATA',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  RecordClassAssignmentSubmission = 'RECORD_CLASS_ASSIGNMENT_SUBMISSION',
  UpdateOwnClassAssignment = 'UPDATE_OWN_CLASS_ASSIGNMENT',
  UpdateClassAssignment = 'UPDATE_CLASS_ASSIGNMENT',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateClassAssignment = 'CREATE_CLASS_ASSIGNMENT',
  UpdateOwnAssignment = 'UPDATE_OWN_ASSIGNMENT',
  UpdateAssignment = 'UPDATE_ASSIGNMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  ListSegmentAssignment = 'LIST_SEGMENT_ASSIGNMENT',
  ListOwnClassAssignments = 'LIST_OWN_CLASS_ASSIGNMENTS',
  ListClassAssignments = 'LIST_CLASS_ASSIGNMENTS',
  ViewClassGradeReport = 'VIEW_CLASS_GRADE_REPORT',
  ViewOwnClassGradeReport = 'VIEW_OWN_CLASS_GRADE_REPORT',
  ViewStudentGradeTree = 'VIEW_STUDENT_GRADE_TREE',
  ViewOwnStudentSubmissions = 'VIEW_OWN_STUDENT_SUBMISSIONS',
  ViewStudentSubmissions = 'VIEW_STUDENT_SUBMISSIONS',
  ViewUnreviewedSubmissions = 'VIEW_UNREVIEWED_SUBMISSIONS',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  ViewOwnClassStudentGradeReport = 'VIEW_OWN_CLASS_STUDENT_GRADE_REPORT',
  ViewClassStudentGradeReport = 'VIEW_CLASS_STUDENT_GRADE_REPORT',
  ViewTag = 'VIEW_TAG',
  ViewOwnTag = 'VIEW_OWN_TAG',
  DeleteTag = 'DELETE_TAG',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  RestoreTag = 'RESTORE_TAG',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  UpdateTags = 'UPDATE_TAGS',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  ListTags = 'LIST_TAGS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  ListInteractions = 'LIST_INTERACTIONS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  CreateStudent = 'CREATE_STUDENT',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  RunStudentReport = 'RUN_STUDENT_REPORT',
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  ViewComment = 'VIEW_COMMENT',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  DeleteComment = 'DELETE_COMMENT',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  ModerateComments = 'MODERATE_COMMENTS',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ListComments = 'LIST_COMMENTS',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  GeneratePdf = 'GENERATE_PDF',
  ViewMedia = 'VIEW_MEDIA',
  ViewOwnMedia = 'VIEW_OWN_MEDIA',
  ViewUserMedia = 'VIEW_USER_MEDIA',
  ViewOwnUserMedia = 'VIEW_OWN_USER_MEDIA',
  DeleteMedia = 'DELETE_MEDIA',
  DeleteOwnMedia = 'DELETE_OWN_MEDIA',
  RestoreMedia = 'RESTORE_MEDIA',
  RestoreOwnMedia = 'RESTORE_OWN_MEDIA',
  UpdateMedia = 'UPDATE_MEDIA',
  UpdateOwnMedia = 'UPDATE_OWN_MEDIA',
  UploadMedia = 'UPLOAD_MEDIA',
  RefreshMediaUrl = 'REFRESH_MEDIA_URL',
  RefreshOwnMediaUrl = 'REFRESH_OWN_MEDIA_URL',
  SubmitMedia = 'SUBMIT_MEDIA',
  SubmitOwnMedia = 'SUBMIT_OWN_MEDIA',
  ViewPractice = 'VIEW_PRACTICE',
  ViewOwnPractice = 'VIEW_OWN_PRACTICE',
  DeletePractice = 'DELETE_PRACTICE',
  DeleteOwnPractice = 'DELETE_OWN_PRACTICE',
  RestorePractice = 'RESTORE_PRACTICE',
  RestoreOwnPractice = 'RESTORE_OWN_PRACTICE',
  ListPractice = 'LIST_PRACTICE',
  CreatePractice = 'CREATE_PRACTICE',
  ListStudentPractices = 'LIST_STUDENT_PRACTICES',
  ListOwnStudentPractices = 'LIST_OWN_STUDENT_PRACTICES',
  ViewClassPractices = 'VIEW_CLASS_PRACTICES',
  ViewOwnClassPractices = 'VIEW_OWN_CLASS_PRACTICES',
  ListTeacherPractices = 'LIST_TEACHER_PRACTICES',
  ListOwnTeacherPractices = 'LIST_OWN_TEACHER_PRACTICES',
  UpdatePractice = 'UPDATE_PRACTICE',
  UpdateOwnPractice = 'UPDATE_OWN_PRACTICE',
  UsePracticeIsViewed = 'USE_PRACTICE_IS_VIEWED',
  ShareNode = 'SHARE_NODE',
  ShareOwnNode = 'SHARE_OWN_NODE',
  ListBadgeUsers = 'LIST_BADGE_USERS',
  ListBadges = 'LIST_BADGES',
  ListUserBadges = 'LIST_USER_BADGES',
  ListOwnUserBadges = 'LIST_OWN_USER_BADGES',
  ManageBadges = 'MANAGE_BADGES',
  ListUserSheetMusicCompositions = 'LIST_USER_SHEET_MUSIC_COMPOSITIONS',
  ListOwnUserSheetMusicCompositions = 'LIST_OWN_USER_SHEET_MUSIC_COMPOSITIONS',
  ListClassSheetMusicCompositions = 'LIST_CLASS_SHEET_MUSIC_COMPOSITIONS',
  ListOwnClassSheetMusicCompositions = 'LIST_OWN_CLASS_SHEET_MUSIC_COMPOSITIONS',
  CreateSheetMusicComposition = 'CREATE_SHEET_MUSIC_COMPOSITION',
  ViewSheetMusicComposition = 'VIEW_SHEET_MUSIC_COMPOSITION',
  ViewOwnSheetMusicComposition = 'VIEW_OWN_SHEET_MUSIC_COMPOSITION',
  DeleteSheetMusicComposition = 'DELETE_SHEET_MUSIC_COMPOSITION',
  DeleteOwnSheetMusicComposition = 'DELETE_OWN_SHEET_MUSIC_COMPOSITION',
  RestoreSheetMusicComposition = 'RESTORE_SHEET_MUSIC_COMPOSITION',
  RestoreOwnSheetMusicComposition = 'RESTORE_OWN_SHEET_MUSIC_COMPOSITION',
  ListLesson = 'LIST_LESSON',
  ViewLesson = 'VIEW_LESSON',
  ViewOwnLesson = 'VIEW_OWN_LESSON',
  DeleteLesson = 'DELETE_LESSON',
  DeleteOwnLesson = 'DELETE_OWN_LESSON',
  RestoreLesson = 'RESTORE_LESSON',
  RestoreOwnLesson = 'RESTORE_OWN_LESSON',
  CreateLesson = 'CREATE_LESSON',
  UpdateLesson = 'UPDATE_LESSON',
  UpdateOwnLesson = 'UPDATE_OWN_LESSON',
  AssignLesson = 'ASSIGN_LESSON',
  AssignOwnLesson = 'ASSIGN_OWN_LESSON',
  ViewLessonAssignments = 'VIEW_LESSON_ASSIGNMENTS',
  ViewOwnLessonAssignments = 'VIEW_OWN_LESSON_ASSIGNMENTS',
  ListTeacherLessons = 'LIST_TEACHER_LESSONS',
  ListOwnTeacherLessons = 'LIST_OWN_TEACHER_LESSONS',
  ListStudentLessons = 'LIST_STUDENT_LESSONS',
  ListOwnStudentLessons = 'LIST_OWN_STUDENT_LESSONS',
  ListLessonPractices = 'LIST_LESSON_PRACTICES',
  ListOwnLessonPractices = 'LIST_OWN_LESSON_PRACTICES',
  ListSharedLessonPractices = 'LIST_SHARED_LESSON_PRACTICES',
  ViewLessonStudentViews = 'VIEW_LESSON_STUDENT_VIEWS',
  DownloadLesson = 'DOWNLOAD_LESSON',
  DownloadOwnLesson = 'DOWNLOAD_OWN_LESSON',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
}

/** A practice represents media uploaded by a student, and can be associated with a lesson. */
export type Practice = Node &
  ShareableNode & {
    __typename?: 'Practice'
    id: Scalars['ID']
    /** The optional name of the practice. */
    name?: Maybe<Scalars['String']>
    /** The student that uploaded the media. */
    student: Student
    /** The class the practice is associated with. */
    class?: Maybe<Class>
    /** The actual media associated with the practice. */
    media: Media
    /** The date the practice was first created. */
    createdAt: Scalars['DateTime']
    /** The date the practice was last updated. */
    updatedAt: Scalars['DateTime']
    /** Whether or not the current user has viewed the practice. */
    isViewed?: Maybe<Scalars['Boolean']>
    sharing: NodeSharing
    /** The lesson the practice is associated with. */
    lesson?: Maybe<Lesson>
  }

export type PracticeInput = {
  media: MediaUploadUrlInput
  name?: Maybe<Scalars['String']>
  /** Optionally the class you would like to associate the practice with. */
  classId?: Maybe<Scalars['ID']>
  lessonId?: Maybe<Scalars['ID']>
}

export type PracticePatch = {
  name?: Maybe<Scalars['String']>
  classId?: Maybe<Scalars['ID']>
}

export type PracticeResponse = {
  __typename?: 'PracticeResponse'
  practice: Practice
}

export type PracticesConnection = Connection & {
  __typename?: 'PracticesConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: PracticesEdge[]
}

export type PracticesEdge = Edge & {
  __typename?: 'PracticesEdge'
  cursor: Scalars['String']
  node: Practice
}

export type PreferencesInput = {
  /** The identifier for the type of notification. */
  notificationType: Scalars['String']
  /** The name of the channels to disable. */
  disabledChannels: Scalars['String'][]
}

export type PublishCourseVersionInput = {
  id: Scalars['ID']
}

export type Query = {
  __typename?: 'Query'
  /** Fetch a specific entity. */
  node?: Maybe<Node>
  /** Searches for nodes in the service. */
  search: SearchResultsConnection
  /** Gets all districts. */
  districts: DistrictsConnection
  /** Gets all sites. */
  sites: SitesConnection
  /** The teachers inside the system. */
  teachers: TeachersConnection
  /** Gets all students in the system. */
  students: StudentsConnection
  /** Gets all classes in the system. */
  classes: ClassesConnection
  /** Gets a list of all licenses in the system. */
  licenses: LicensesConnection
  /** Gets a list of all subscriptions in the system. */
  subscriptions: SubscriptionsConnection
  trialRequests: TrialRequestsConnection
  /** Gets all segments available to the current user. */
  segments: SegmentsConnection
  /** Gets all courses currently in the system. */
  courses: CoursesConnection
  /** Gets the metadata for the specified page URL. */
  pageMetadata?: Maybe<PageMetadata>
  /** Gets all segment assignments currently in the system. */
  segmentAssignments: SegmentAssignmentsConnection
  /** Runs an implementation guide report for the specified class. */
  classImplementationGuideReport: ClassImplementationGuideReport
  /** The default rubric types to use for new assignments. */
  defaultRubricTypes: Scalars['String'][]
  /** Lists all tags. */
  tags: TagsConnection
  canvasScopes: Scalars['String'][]
  /** Gets information about the currently available class-sync providers. */
  classSyncProviders: ClassSyncProvider[]
  /** Gets the third-party classes available to the current user. */
  listSyncProviderClasses: ClassSyncProviderClass[]
  googleScopes: Scalars['String'][]
  msalScopes: Scalars['String'][]
  /** Generates a report on the class-level for specific classes and implementation guides. */
  classReport: ClassReportResult
  /** Generates a report on the student-level for each implementation guide. */
  studentReport: StudentReportResult
  /** Lists all current video on demands. */
  videosOnDemand: VideoOnDemandConnection
  /** The notification channels available to be configured for the user. */
  notificationChannels: NotificationChannelInfo[]
  /** Gets the notification preferences for the user associated with the specified JWT. */
  userNotificationPreferences: NotificationPreference[]
  /** Gets all comments in the system. */
  comments: CommentsConnection
  /** Loads an existing Generated PDF by ID, enforcing permissions and accepting the admin token. */
  generatedPdf: GeneratedPdf
  /** Is the current user able to create media? */
  canCreateMedia: Scalars['Boolean']
  /** Gets a list of all the practices in the system. */
  practices: PracticesConnection
  /** All badges in the system. */
  badges: BadgesConnection
  /** Gets all lessons currently in the system. */
  lessons: LessonsConnection
  /** Gets the current user. */
  viewer?: Maybe<User>
  /** Gets all users currently registered in the system. */
  users: UsersConnection
  /** Given a generic path, generates a fetch URL for it. */
  genericFetchUrl: SignedFetchResult
}

export type QueryNodeArgs = {
  id: Scalars['ID']
  includeDeleted?: Maybe<Scalars['Boolean']>
}

export type QuerySearchArgs = {
  query: Scalars['String']
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
}

export type QueryDistrictsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QuerySitesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QueryTeachersArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type QueryStudentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type QueryClassesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QueryLicensesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QuerySubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  classId?: Maybe<Scalars['ID']>
}

export type QueryTrialRequestsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  status?: Maybe<TrialRequestStatus>
}

export type QuerySegmentsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  userIds?: Maybe<Scalars['ID'][]>
  labels?: Maybe<Scalars['String'][]>
  types?: Maybe<SegmentType[]>
  tags?: Maybe<TagSearchInput[]>
  tagsOp?: Maybe<FilterOperation>
  hiddenFromTeachers?: Maybe<Scalars['Boolean']>
}

export type QueryCoursesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  isLicensed?: Maybe<Scalars['Boolean']>
  isHidden?: Maybe<Scalars['Boolean']>
  isByoc?: Maybe<Scalars['Boolean']>
}

export type QueryPageMetadataArgs = {
  url: Scalars['String']
}

export type QuerySegmentAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  isCalculated?: Maybe<Scalars['Boolean']>
}

export type QueryClassImplementationGuideReportArgs = {
  input: ClassImplementationGuideReportInput
}

export type QueryTagsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  types?: Maybe<Scalars['String'][]>
}

export type QueryListSyncProviderClassesArgs = {
  input: ListSyncProviderClassesInput
}

export type QueryClassReportArgs = {
  input: ClassReportInput
}

export type QueryStudentReportArgs = {
  input: StudentReportInput
}

export type QueryVideosOnDemandArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QueryUserNotificationPreferencesArgs = {
  jwt: Scalars['String']
}

export type QueryCommentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  approval?: Maybe<Maybe<CommentApproval>[]>
}

export type QueryGeneratedPdfArgs = {
  id: Scalars['ID']
  token?: Maybe<Scalars['String']>
}

export type QueryCanCreateMediaArgs = {
  classId?: Maybe<Scalars['ID']>
}

export type QueryPracticesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type QueryBadgesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QueryLessonsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

export type QueryGenericFetchUrlArgs = {
  path: Scalars['String']
}

export type ReadNotificationInput = {
  id: Scalars['ID']
  read: Scalars['Boolean']
}

export type ReadNotificationPathInput = {
  universalPath: Scalars['String']
  read: Scalars['Boolean']
}

export type ReadNotificationsInput = {
  /** The notifications to update. */
  notifications?: Maybe<ReadNotificationInput[]>
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: Maybe<ReadNotificationPathInput[]>
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: Maybe<Scalars['Boolean']>
}

export type ReadNotificationsPayload = {
  __typename?: 'ReadNotificationsPayload'
  /** The notifications read / unread by the mutation. */
  notifications: Notification[]
}

export type RecordCanvasCredentialsInput = {
  token: Scalars['String']
  consumerKey: Scalars['String']
  consumerSecret: Scalars['String']
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String']
}

export type RecordInteractionsInput = {
  interactions: InteractionInput[]
}

export type RecordInteractionsPayload = {
  __typename?: 'RecordInteractionsPayload'
  numRecorded: Scalars['Int']
  /** The badges the user just earned with these submitted interactions. */
  awardedBadges: Badge[]
}

export type RecordMicrosoftTenantInput = {
  token: Scalars['String']
  tenantId: Scalars['String']
}

export type RefreshMediaUploadUrlInput = {
  id: Scalars['ID']
  mimeType: Scalars['String']
  requestMode?: Maybe<UploadRequestMode>
}

export type RegisterInput = {
  email: Scalars['String']
  password: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['JSON']>
}

export type RejectCommentInput = {
  id: Scalars['ID']
  /** The reason the comment was rejected. */
  reason: Scalars['String']
}

export type RelatedTeacherSite = {
  __typename?: 'RelatedTeacherSite'
  site: Site
  teachers: Teacher[]
}

export type RelatedTeachers = {
  __typename?: 'RelatedTeachers'
  sites: RelatedTeacherSite[]
}

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID']
  /** An array of user IDs to remove from the thread. */
  userIds: Scalars['ID'][]
}

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID']
  /** The ID of the student to remove. */
  studentId: Scalars['ID']
}

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID']
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID']
}

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']
}

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID']
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID']
}

export type ResetAssignmentSubmissionsPayload = {
  __typename?: 'ResetAssignmentSubmissionsPayload'
  /** An array of submission IDs that were deleted. */
  submissionIds: Scalars['ID'][]
}

export type ResetPasswordInput = {
  jwt: Scalars['String']
  password: Scalars['String']
}

export type RestoreNodeResponse = {
  __typename?: 'RestoreNodeResponse'
  /** The ID of the restored node. */
  id: Scalars['ID']
  /** The number of rows restored. */
  numRestored: Scalars['Int']
}

export type RetrySubmissionInput = {
  /** The ID of the Assignment */
  id: Scalars['ID']
}

export type ReviewAssignmentUserTask = UserTask & {
  __typename?: 'ReviewAssignmentUserTask'
  identifier: Scalars['String']
  completedAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['DateTime']>
  payload: ReviewAssignmentUserTaskPayload
}

export type ReviewAssignmentUserTaskPayload = {
  __typename?: 'ReviewAssignmentUserTaskPayload'
  assignmentId: Scalars['ID']
  classId: Scalars['ID']
  excluded?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  dueAt?: Maybe<Scalars['DateTime']>
}

/**
 * A rubric template allows users to quickly define rubrics and use them in all
 * of their assignments.
 */
export type RubricTemplate = Node & {
  __typename?: 'RubricTemplate'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The ID of the user that owns the template. */
  userId: Scalars['ID']
  /** The user that owns the template. */
  user: User
  /** The rubric configuration */
  rubric: AssignmentRubric
  /** The name of the configuration, created by the teacher */
  name: Scalars['String']
}

export type RubricTemplateInput = {
  rubric?: Maybe<AssignmentRubricInput>
  name?: Maybe<Scalars['String']>
}

export type RubricTemplatePayload = {
  __typename?: 'RubricTemplatePayload'
  rubricTemplate: RubricTemplate
}

export type RubricTemplatesConnection = Connection & {
  __typename?: 'RubricTemplatesConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: RubricTemplatesEdge[]
}

export type RubricTemplatesEdge = Edge & {
  __typename?: 'RubricTemplatesEdge'
  cursor: Scalars['String']
  node: RubricTemplate
}

export type SaveAssignmentSubmissionInput = {
  assignmentId: Scalars['ID']
  submissionData: Scalars['JSON']
}

export type SchoologyTokenInput = {
  token: Scalars['String']
  tokenSecret: Scalars['String']
}

export type SearchResultsConnection = Connection & {
  __typename?: 'SearchResultsConnection'
  pageInfo: PageInfo
  edges: SearchResultsEdge[]
  totalCount: Scalars['Int']
}

export type SearchResultsEdge = Edge & {
  __typename?: 'SearchResultsEdge'
  cursor: Scalars['String']
  node: Node
}

export type Segment = Node & {
  __typename?: 'Segment'
  id: Scalars['ID']
  /** The name of the segment. */
  name: Scalars['String']
  /** The type of segment. */
  type: SegmentType
  /** The configuration for the segment. */
  config?: Maybe<Scalars['JSON']>
  /** The user that created the segment. If empty, BusinessU owns it. */
  user?: Maybe<User>
  /** The full built segment object. */
  built?: Maybe<Scalars['JSON']>
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: Maybe<Scalars['Boolean']>
  /** The date the segment was first created. */
  createdAt: Scalars['DateTime']
  /** The date the segment was last updated. */
  updatedAt: Scalars['DateTime']
  /** Gets all tags associated with this entity. */
  tags: TagsConnection
  /** Gets the tag summary associated with this entity. */
  tagSummary: Scalars['JSON']
}

export type SegmentTagsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A segment assignment is a collection of questions presented to students to report on their progress. */
export type SegmentAssignment = Node & {
  __typename?: 'SegmentAssignment'
  /** The ID of the assignment. */
  id: Scalars['ID']
  /** The configuration for the assignment (contains the questions). */
  configuration?: Maybe<Scalars['JSON']>
  /** The name of the assignment. */
  name: Scalars['String']
  /** The type of assignment. */
  type: SegmentAssignmentType
  /** Default configuration values used when creating associated class assignments. */
  defaults?: Maybe<Scalars['JSON']>
  /** Whether or not the assignment questions are automatically calculated based on context. */
  isCalculated?: Maybe<Scalars['Boolean']>
  /**
   * Whether or not the assignment is automatically graded.
   * @deprecated This does not work for calculated assignments.
   */
  isAutoGraded: Scalars['Boolean']
  /** Gets all class assignments associated with this assignment. */
  classAssignments: ClassAssignmentsConnection
  /** The date the assignment was first created. */
  createdAt: Scalars['DateTime']
  /** The last date the assignment was updated. */
  updatedAt: Scalars['DateTime']
}

/** A segment assignment is a collection of questions presented to students to report on their progress. */
export type SegmentAssignmentClassAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type SegmentAssignmentInput = {
  name?: Maybe<Scalars['String']>
  configuration?: Maybe<Scalars['JSON']>
  configurationChanges?: Maybe<Scalars['JSON']>
  defaults?: Maybe<Scalars['JSON']>
  isCalculated?: Maybe<Scalars['Boolean']>
  type?: Maybe<SegmentAssignmentType>
}

/** The payload returned when creating or updating a assignment. */
export type SegmentAssignmentPayload = {
  __typename?: 'SegmentAssignmentPayload'
  segmentAssignment: SegmentAssignment
}

export enum SegmentAssignmentType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL',
}

export type SegmentAssignmentsConnection = Connection & {
  __typename?: 'SegmentAssignmentsConnection'
  pageInfo: PageInfo
  edges: SegmentAssignmentsEdge[]
  totalCount: Scalars['Int']
}

export type SegmentAssignmentsEdge = Edge & {
  __typename?: 'SegmentAssignmentsEdge'
  cursor: Scalars['String']
  node: SegmentAssignment
}

export type SegmentDecoration = {
  __typename?: 'SegmentDecoration'
  /** The universal ID for the segment being viewed. */
  id: Scalars['ID']
  /** The ID for the segment being decorated. */
  segmentId: Scalars['String']
  /** Whether or not the segment has been previously viewed. */
  isViewed?: Maybe<Scalars['Boolean']>
  /** The total count of students who have started the segment. */
  startedCount?: Maybe<Scalars['Int']>
  /** The date the assignment associated with this segment is due, if set. */
  assignmentDueDate?: Maybe<Scalars['DateTime']>
}

export type SegmentInput = {
  /** The type of segment to create or update. */
  type?: Maybe<SegmentType>
  /** The ID of the segment. */
  id: Scalars['ID']
  /** The name of the segment. */
  name?: Maybe<Scalars['String']>
  /** The configuration for the segment. */
  config?: Maybe<Scalars['JSON']>
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: Maybe<Scalars['ID']>
  /** The new tags to set for the segment. */
  updateTags?: Maybe<TagsInput>
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: Maybe<Scalars['Boolean']>
}

export type SegmentPayload = {
  __typename?: 'SegmentPayload'
  /** The created or updated segment. */
  segment: Segment
}

export enum SegmentType {
  Section = 'Section',
  Group = 'Group',
  Root = 'Root',
  Term = 'Term',
  Topic = 'Topic',
  Reference = 'Reference',
  Assignment = 'Assignment',
}

export type SegmentsConnection = Connection & {
  __typename?: 'SegmentsConnection'
  totalCount: Scalars['Int']
  edges: SegmentsEdge[]
  pageInfo: PageInfo
}

export type SegmentsEdge = Edge & {
  __typename?: 'SegmentsEdge'
  node: Segment
  cursor: Scalars['String']
}

export type ShareNodeInput = {
  /** The ID of the node to share or unshare. */
  id: Scalars['ID']
  /** The ID of the users to share or unshare with. */
  userIds: Scalars['ID'][]
  /** For sharing, whether this list of user ids will replace the existing shares. */
  replace?: Maybe<Scalars['Boolean']>
}

export type ShareResponse = {
  __typename?: 'ShareResponse'
  node: ShareableNode
}

export type ShareableNode = {
  id: Scalars['ID']
  sharing: NodeSharing
}

export enum SharingType {
  Own = 'OWN',
  IveShared = 'IVE_SHARED',
  SharedWithMe = 'SHARED_WITH_ME',
}

export type SheetMusicComposition = Node & {
  __typename?: 'SheetMusicComposition'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  /** The user that created the composition. */
  user: User
  /** The ID of the user. */
  userId: Scalars['ID']
  /** The class the composition was created in. */
  class: Class
  /** The ID of the class. */
  classId: Scalars['ID']
  /** An optional recording associated with the composition. */
  media?: Maybe<Media>
  /** The ID of the media. */
  mediaId?: Maybe<Scalars['ID']>
  /** The name of the composition. */
  name: Scalars['String']
  /** The ABC-notation sheet music. */
  abc: Scalars['String']
  /** Optional state of the editor. */
  state?: Maybe<Scalars['JSON']>
}

export type SheetMusicCompositionInput = {
  name: Scalars['String']
  abc: Scalars['String']
  state?: Maybe<Scalars['JSON']>
  classId: Scalars['ID']
  media?: Maybe<MediaUploadUrlInput>
}

export type SheetMusicCompositionPayload = {
  __typename?: 'SheetMusicCompositionPayload'
  sheetMusicComposition: SheetMusicComposition
  uploadPayload?: Maybe<MediaUploadPayload>
}

export type SheetMusicCompositionsConnection = Connection & {
  __typename?: 'SheetMusicCompositionsConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: SheetMusicCompositionsEdge[]
}

export type SheetMusicCompositionsEdge = Edge & {
  __typename?: 'SheetMusicCompositionsEdge'
  cursor: Scalars['String']
  node: SheetMusicComposition
}

/** The response returned when fetching files from the storage service. */
export type SignedFetchResult = {
  __typename?: 'SignedFetchResult'
  /** The bucket the file belongs to. */
  bucket: Scalars['String']
  /** The path to the file. */
  path: Scalars['String']
  /** Whether or not the signed URL is cacheable. */
  isCacheable?: Maybe<Scalars['Boolean']>
  /** The signed URL to download the file. */
  signedUrl: Scalars['String']
  /** A dictionary of headers required to be sent with the request. */
  requireHeaders?: Maybe<Scalars['JSON']>
}

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String']
  /** The mime-type of the file. */
  mimeType: Scalars['String']
  /** The request mode to use when uploading. */
  requestMode?: Maybe<UploadRequestMode>
  /** Whether or not the upload should be public. */
  isPublic?: Maybe<Scalars['Boolean']>
}

/** The response returned when getting a signed upload URL. */
export type SignedUploadResult = {
  __typename?: 'SignedUploadResult'
  /** The signed URL to use when uploading the file. */
  signedUrl: Scalars['String']
  /** The extension of the file created. */
  extension: Scalars['String']
  /** The path to the file. */
  path: Scalars['String']
  /** Any additional data to include with the request. */
  data?: Maybe<Scalars['JSON']>
}

/** A site represents a school using BusinessU. */
export type Site = Node & {
  __typename?: 'Site'
  /** The ID of the site. */
  id: Scalars['ID']
  /** The name of the site. */
  name: Scalars['String']
  /** The type of site. */
  type: Scalars['String']
  /** The city the site is located in. */
  city?: Maybe<Scalars['String']>
  /** The state the site is located in. */
  state?: Maybe<Scalars['String']>
  /** The district the site is located in. */
  district?: Maybe<District>
  /** Any additional notes provided by an administrator. */
  note?: Maybe<Scalars['String']>
  /** The date the site was first created. */
  createdAt: Scalars['DateTime']
  /** The last time the site was updated. */
  updatedAt: Scalars['DateTime']
  /** The teachers inside the site. */
  teachers: TeachersConnection
  /** Whether or not course codes are disabled for this site. */
  disableCourseCodes?: Maybe<Scalars['Boolean']>
  /** Gets all classes for the site. */
  classes: ClassesConnection
  /** The license for the site. */
  license?: Maybe<License>
  /** The default region site media should upload to. */
  region: AccountRegion
}

/** A site represents a school using BusinessU. */
export type SiteTeachersArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A site represents a school using BusinessU. */
export type SiteClassesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type SiteInput = {
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  districtId?: Maybe<Scalars['ID']>
  disableCourseCodes?: Maybe<Scalars['Boolean']>
  region?: Maybe<AccountRegion>
}

/** The payload returned when updating a site. */
export type SitePayload = {
  __typename?: 'SitePayload'
  site: Site
}

export type SitesConnection = Connection & {
  __typename?: 'SitesConnection'
  pageInfo: PageInfo
  edges: SitesEdge[]
  totalCount: Scalars['Int']
}

export type SitesEdge = Edge & {
  __typename?: 'SitesEdge'
  cursor: Scalars['String']
  node: Site
}

export type StoredAttachment = {
  __typename?: 'StoredAttachment'
  bucket: Scalars['String']
  path: Scalars['String']
  mimeType: Scalars['String']
  sizeInBytes: Scalars['Float']
  name: Scalars['String']
}

/** A student is a member of a class. */
export type Student = Node & {
  __typename?: 'Student'
  /** The ID of the student. */
  id: Scalars['ID']
  /** The user object associated with the student. */
  user: User
  /** The time the student was first created. */
  createdAt: Scalars['DateTime']
  /** The last time the student was updated. */
  updatedAt: Scalars['DateTime']
  /** The time the student was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>
  /** Gets all classes for the student. */
  classes: StudentClassesConnection
  /** Gets an overview report for the current student. */
  overview: StudentOverview
  /** Gets all assignments assigned to this specific student. */
  assignments: AssignmentsConnection
  /** Gets all assignment submissions by this student. */
  assignmentSubmissions: AssignmentSubmissionsConnection
  /** Gets a list of all the practices for the student. */
  practices: PracticesConnection
  /** Gets all lessons assigned to this student. */
  lessons: AssignedLessonsConnection
}

/** A student is a member of a class. */
export type StudentClassesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A student is a member of a class. */
export type StudentOverviewArgs = {
  classId: Scalars['ID']
}

/** A student is a member of a class. */
export type StudentAssignmentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A student is a member of a class. */
export type StudentAssignmentSubmissionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A student is a member of a class. */
export type StudentPracticesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  classId?: Maybe<Scalars['ID']>
}

/** A student is a member of a class. */
export type StudentLessonsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  classId?: Maybe<Scalars['ID']>
}

/** Student -> Classes */
export type StudentClassesConnection = Connection & {
  __typename?: 'StudentClassesConnection'
  pageInfo: PageInfo
  edges: StudentClassesEdge[]
  totalCount: Scalars['Int']
}

export type StudentClassesEdge = Edge & {
  __typename?: 'StudentClassesEdge'
  cursor: Scalars['String']
  node: Class
}

export type StudentFilterInput = {
  classId: Scalars['ID']
  studentId: Scalars['ID']
}

export type StudentGroupPerformance = {
  __typename?: 'StudentGroupPerformance'
  timesAsLeader: Scalars['Int']
  averageGradeWhenLeader?: Maybe<Scalars['Float']>
}

export type StudentInput = {
  username?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type StudentMastery = {
  __typename?: 'StudentMastery'
  mastery: StudentMasteryItem[]
}

export type StudentMasteryItem = {
  __typename?: 'StudentMasteryItem'
  rubricType: Scalars['String']
  grade: Scalars['Float']
}

export type StudentOverview = {
  __typename?: 'StudentOverview'
  grade?: Maybe<Scalars['Float']>
  contentVisited?: Maybe<Scalars['Float']>
  totalTimeOnTask?: Maybe<Scalars['Float']>
  averageTimeOnTask?: Maybe<Scalars['Float']>
  averageSessionLength?: Maybe<Scalars['Float']>
  masteryReport: StudentMastery
  groupPerformance: StudentGroupPerformance
}

/** The payload returned after mutating a Student. */
export type StudentPayload = {
  __typename?: 'StudentPayload'
  /** The created or updated Student. */
  student: Student
}

export type StudentReportAllResultItem = {
  __typename?: 'StudentReportAllResultItem'
  type: StudentReportResultType
  label: Scalars['String']
  contentVisited?: Maybe<Scalars['Float']>
  timeOnTask?: Maybe<Scalars['Float']>
  averageTimeOnTask?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['DateTime']>
  grade?: Maybe<Scalars['Float']>
}

export type StudentReportIg = {
  __typename?: 'StudentReportIG'
  implementationGuide: Scalars['String']
  grade?: Maybe<Scalars['Float']>
  items: StudentReportResultsItem[]
}

export type StudentReportInput = {
  /** The ID of the student to run the report on. */
  id: Scalars['ID']
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID']
}

export type StudentReportResult = {
  __typename?: 'StudentReportResult'
  implementationGuides: StudentReportIg[]
  allResults: StudentReportAllResultItem[]
}

export enum StudentReportResultType {
  Section = 'SECTION',
  Assignment = 'ASSIGNMENT',
}

export type StudentReportResultsItem = {
  __typename?: 'StudentReportResultsItem'
  type: StudentReportResultType
  segmentId?: Maybe<Scalars['String']>
  submission?: Maybe<ClassAssignmentSubmission>
  classAssignment?: Maybe<ClassAssignment>
  isContentVisited?: Maybe<Scalars['Boolean']>
  contentVisited?: Maybe<Scalars['Float']>
  timeOnTask?: Maybe<Scalars['Float']>
  averageTimeOnTask?: Maybe<Scalars['Float']>
  grade?: Maybe<Scalars['Float']>
  timestamp?: Maybe<Scalars['DateTime']>
}

export type StudentsConnection = Connection & {
  __typename?: 'StudentsConnection'
  pageInfo: PageInfo
  edges: StudentsEdge[]
  totalCount: Scalars['Int']
}

export type StudentsEdge = Edge & {
  __typename?: 'StudentsEdge'
  cursor: Scalars['String']
  node: Student
}

export type SubmitAssignmentInput = {
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID']
  /** Whether or not to force the submission. */
  force?: Maybe<Scalars['Boolean']>
}

export type SubmitClassAssignmentInput = {
  assignmentId: Scalars['ID']
  submissionData: Scalars['JSON']
  forceSubmission?: Maybe<Scalars['Boolean']>
}

export type SubmitMediaInput = {
  id: Scalars['ID']
}

/** A subscription represents a billing agreement between the company and individual users. */
export type Subscription = Node & {
  __typename?: 'Subscription'
  id: Scalars['ID']
  /** The date the subscription was first created. */
  createdAt: Scalars['DateTime']
  /** The date the subscription was last updated. */
  updatedAt: Scalars['DateTime']
  /** The date the subscription expires. */
  expiresAt?: Maybe<Scalars['DateTime']>
  /** The user that owns the subscription. */
  user: User
  /** The type of subscription. */
  type: SubscriptionType
  /** The subscription size, if it is an INDIVIDUAL subscription. */
  size?: Maybe<SubscriptionSize>
  /** The license the subscription is associated with for STUDENT_PAY */
  license?: Maybe<License>
  /** The class the subscription is associated with for STUDENT_PAY */
  class?: Maybe<Class>
  /** The stripe subscription ID, if one is used. */
  subscriptionId?: Maybe<Scalars['String']>
  /** The IAP receipt from either Apple or Google. */
  receipt?: Maybe<Scalars['String']>
  /** A boolean indicating whether or not the subscription is currently active. */
  isValid?: Maybe<Scalars['Boolean']>
  /** A timestamp indicating the next time the subscription should be checked for validity. */
  nextValidityCheck?: Maybe<Scalars['DateTime']>
  /** The currently-used bytes for this subscription (only applies to INDIVIDUAL). */
  used?: Maybe<Scalars['Float']>
  /** The quota bytes for this subscription (only applies to INDIVIDUAL). */
  quota?: Maybe<Scalars['Float']>
  /** Any additional metadata, used for reporting purposes mostly. */
  metadata?: Maybe<Scalars['JSON']>
  /** The subscriptions associated with the license. */
  subscriptions: SubscriptionsConnection
}

/** A subscription represents a billing agreement between the company and individual users. */
export type SubscriptionSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

export type SubscriptionInput = {
  userId?: Maybe<Scalars['ID']>
  type?: Maybe<SubscriptionType>
  size?: Maybe<SubscriptionSize>
  licenseId?: Maybe<Scalars['ID']>
  classId?: Maybe<Scalars['ID']>
  subscriptionId?: Maybe<Scalars['String']>
  nextValidityCheck?: Maybe<Scalars['DateTime']>
  isValid?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['JSON']>
  expiresAt?: Maybe<Scalars['DateTime']>
}

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload'
  subscription: Subscription
}

export enum SubscriptionSize {
  Small = 'SMALL',
  Large = 'LARGE',
}

export enum SubscriptionType {
  StudentPay = 'STUDENT_PAY',
  Individual = 'INDIVIDUAL',
}

export type SubscriptionsConnection = Connection & {
  __typename?: 'SubscriptionsConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: SubscriptionsEdge[]
}

export type SubscriptionsEdge = Edge & {
  __typename?: 'SubscriptionsEdge'
  cursor: Scalars['String']
  node: Subscription
}

export type SuccessResponse = {
  __typename?: 'SuccessResponse'
  success: Scalars['Boolean']
}

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID']
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: Maybe<Scalars['ID'][]>
}

/** A tag represents a way to categorize content within the system. */
export type Tag = Node & {
  __typename?: 'Tag'
  id: Scalars['ID']
  /** The user that owns the tag. */
  user?: Maybe<User>
  /** The name of the tag. */
  name: Scalars['String']
  /** The type of the tag. */
  type?: Maybe<Scalars['String']>
  /** The date the tag was created. */
  createdAt: Scalars['DateTime']
  /** The date the tag was updated. */
  updatedAt: Scalars['DateTime']
  /** Whether or not the tag has content available in the current user's license. */
  inLicense: Scalars['Boolean']
}

export type TagFilterInput = {
  /** The type of tag. */
  type: Scalars['String']
  /** The name of the tag. */
  name: Scalars['String']
}

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR',
}

/** Represents filtering by a single tag. */
export type TagSearchInput = {
  /** The type of the tag. */
  type: Scalars['String']
  /** The name of the tag. */
  name: Scalars['String']
}

export type TagsConnection = Connection & {
  __typename?: 'TagsConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: TagsEdge[]
}

export type TagsEdge = Edge & {
  __typename?: 'TagsEdge'
  node: Tag
  cursor: Scalars['String']
}

export type TagsFilter = {
  operation: TagFilterOperation
  tags: TagFilterInput[]
}

export type TagsInput = {
  COURSE?: Maybe<Scalars['String'][]>
  INSTRUCTIONAL_AREA?: Maybe<Scalars['String'][]>
  PERFORMANCE_ELEMENT?: Maybe<Scalars['String'][]>
  PERFORMANCE_INDICATOR?: Maybe<Scalars['String'][]>
  OBJECTIVE?: Maybe<Scalars['String'][]>
  TOPIC?: Maybe<Scalars['String'][]>
  IMPLEMENTATION_GUIDE?: Maybe<Scalars['String'][]>
  LAP?: Maybe<Scalars['String'][]>
  LEARNING_GOAL?: Maybe<Scalars['String'][]>
  CONTENT_AREA?: Maybe<Scalars['String'][]>
  STANDARD?: Maybe<Scalars['String'][]>
  CONCEPT_OBJECTIVE?: Maybe<Scalars['String'][]>
}

/** A teacher is a physical instructor at a site. */
export type Teacher = Node & {
  __typename?: 'Teacher'
  /** The ID of the teacher. */
  id: Scalars['ID']
  /** The public-facing ID of the teacher. */
  teacherId: Scalars['String']
  /** The associated user the Teacher will use to login with. */
  user: User
  /** The site the teacher belongs to. */
  site: Site
  /** The ID of the site the teacher belongs to. */
  siteId: Scalars['ID']
  /** The student user associated with the teacher. */
  studentUser?: Maybe<User>
  /** The teachers related to the current teacher. */
  relatedTeachers?: Maybe<RelatedTeachers>
  /** The time the teacher was created. */
  createdAt: Scalars['DateTime']
  /** The last time the teacher was updated. */
  updatedAt: Scalars['DateTime']
  /** Gets all classes for the teacher. */
  classes: TeacherClassesConnection
  /** Gets a list of all the practices for the teacher. */
  practices: PracticesConnection
  /** Gets all lessons this teacher owns. */
  lessons: LessonsConnection
}

/** A teacher is a physical instructor at a site. */
export type TeacherRelatedTeachersArgs = {
  showSelf?: Maybe<Scalars['Boolean']>
}

/** A teacher is a physical instructor at a site. */
export type TeacherClassesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  deleted?: Maybe<Scalars['Boolean']>
  includeDeleted?: Maybe<Scalars['Boolean']>
  archived?: Maybe<Scalars['Boolean']>
  includeArchived?: Maybe<Scalars['Boolean']>
}

/** A teacher is a physical instructor at a site. */
export type TeacherPracticesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  studentFilters?: Maybe<StudentFilterInput[]>
  beforeDate?: Maybe<Scalars['DateTime']>
  afterDate?: Maybe<Scalars['DateTime']>
  sharingTypes?: Maybe<SharingType[]>
  classIds?: Maybe<Scalars['ID'][]>
}

/** A teacher is a physical instructor at a site. */
export type TeacherLessonsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  tagIds?: Maybe<Scalars['ID'][]>
  assignedStudentIds?: Maybe<Scalars['ID'][]>
  assignedStudentPairs?: Maybe<LessonsAssignedStudentPairInput[]>
  sharingTypes?: Maybe<SharingType[]>
}

export type TeacherClassesConnection = Connection & {
  __typename?: 'TeacherClassesConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: TeacherClassesEdge[]
}

export type TeacherClassesEdge = Edge & {
  __typename?: 'TeacherClassesEdge'
  node: Class
  cursor: Scalars['String']
  role: ClassRole
  permissions: Scalars['String'][]
}

export type TeacherInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  siteId?: Maybe<Scalars['ID']>
  noEmail?: Scalars['Boolean']
}

/** The payload returned after mutating a teacher. */
export type TeacherPayload = {
  __typename?: 'TeacherPayload'
  /** The created or updated teacher. */
  teacher: Teacher
}

export type TeachersConnection = Connection & {
  __typename?: 'TeachersConnection'
  pageInfo: PageInfo
  edges: TeachersEdge[]
  totalCount: Scalars['Int']
}

export type TeachersEdge = Edge & {
  __typename?: 'TeachersEdge'
  cursor: Scalars['String']
  node: Teacher
}

export type TimeOnTask = {
  __typename?: 'TimeOnTask'
  studentUserId: Scalars['ID']
  timeOnTaskMinutes: Scalars['Float']
}

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: Maybe<Scalars['String']>
}

export type TimeOnTaskResult = {
  __typename?: 'TimeOnTaskResult'
  timeOnTasks: TimeOnTask[]
  totalMinutes: Scalars['Float']
}

/** Represents a single request for a trial. */
export type TrialRequest = Node & {
  __typename?: 'TrialRequest'
  /** The ID of the trial request. */
  id: Scalars['ID']
  /** The first name of the individual requesting the trial. */
  firstName: Scalars['String']
  /** The last name of the individual requesting the trial. */
  lastName: Scalars['String']
  /** The email of the individual requesting the trial. */
  email: Scalars['String']
  /** The name of the site we'll want to create. */
  siteName: Scalars['String']
  /** The city the site is located in for the trial request. */
  city: Scalars['String']
  /** The shortcode for the state the site is located in for the trial request. */
  state: Scalars['String']
  /** The status of the trial request. */
  status: TrialRequestStatus
  /** The time the trial request was originally created. */
  createdAt: Scalars['DateTime']
  /** The last time the trial request was updated. */
  updatedAt: Scalars['DateTime']
}

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
}

export type TrialRequestPayload = {
  __typename?: 'TrialRequestPayload'
  trialRequest: TrialRequest
}

export enum TrialRequestStatus {
  Open = 'OPEN',
  Ignored = 'IGNORED',
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
}

export type TrialRequestsConnection = Connection & {
  __typename?: 'TrialRequestsConnection'
  pageInfo: PageInfo
  edges: TrialRequestsEdge[]
  totalCount: Scalars['Int']
}

export type TrialRequestsEdge = Edge & {
  __typename?: 'TrialRequestsEdge'
  cursor: Scalars['String']
  node: TrialRequest
}

export type TrialSignupInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  email: Scalars['String']
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  siteName: Scalars['String']
}

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID']
}

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID']
  patch: AnnouncementInput
}

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID']
  patch: AssignmentCategoryInput
}

export type UpdateAssignmentClassConfigurationInput = {
  /** The ID of the class. */
  id: Scalars['ID']
  patch: AssignmentClassConfigurationInput
}

export type UpdateAssignmentInput = {
  /** The ID of the assignment to update. */
  id: Scalars['ID']
  patch: AssignmentInput
}

export type UpdateBadgeInput = {
  id: Scalars['ID']
  patch: BadgeInput
}

export type UpdateClassAssignmentInput = {
  id: Scalars['ID']
  patch: ClassAssignmentInput
}

export type UpdateClassInput = {
  id: Scalars['ID']
  patch: ClassInput
  force?: Maybe<Scalars['Boolean']>
  segmentMetadataChanges?: Maybe<Scalars['JSON']>
}

export type UpdateCommentInput = {
  id: Scalars['ID']
  patch: CommentInput
}

export type UpdateCourseInput = {
  id: Scalars['ID']
  patch: CourseInput
}

export type UpdateCourseVersionInput = {
  id: Scalars['ID']
  patch: CourseVersionInput
}

export type UpdateDistrictInput = {
  id: Scalars['ID']
  patch: DistrictInput
}

export type UpdateLessonInput = {
  id?: Maybe<Scalars['ID']>
  patch?: Maybe<LessonInput>
}

export type UpdateLicenseInput = {
  id: Scalars['ID']
  noEmail?: Maybe<Scalars['Boolean']>
  patch: LicenseInput
}

export type UpdateMediaInput = {
  id: Scalars['ID']
  patch: MediaInput
}

export type UpdateNodeTagsInput = {
  id: Scalars['ID']
  tags: Scalars['String'][]
}

export type UpdateNodeTagsPayload = {
  __typename?: 'UpdateNodeTagsPayload'
  node: Node
}

export type UpdatePracticeInput = {
  id?: Maybe<Scalars['ID']>
  patch?: Maybe<PracticePatch>
}

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: Maybe<Scalars['String']>
  /** The user's current preferences. */
  preferences: PreferencesInput[]
}

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID']
  patch: RubricTemplateInput
}

export type UpdateSegmentAssignmentInput = {
  id: Scalars['ID']
  patch: SegmentAssignmentInput
}

export type UpdateSiteInput = {
  id: Scalars['ID']
  patch: SiteInput
}

export type UpdateStudentInput = {
  id: Scalars['ID']
  patch: StudentInput
}

export type UpdateSubscriptionInput = {
  id: Scalars['ID']
  patch: SubscriptionInput
}

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID']
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID']
  /** The new permissions to assign to the teacher. */
  permissions: Scalars['String'][]
}

export type UpdateTeacherInput = {
  id: Scalars['ID']
  patch: TeacherInput
}

export type UpdateUserInput = {
  id: Scalars['ID']
  patch: UserInput
}

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID']
  patch: VideoOnDemandInput
}

export enum UploadRequestMode {
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA',
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
}

/** A user registered on the website. */
export type User = Node & {
  __typename?: 'User'
  id: Scalars['ID']
  /** The username, also used for authentication. */
  username: Scalars['String']
  /** The email address of the user. */
  email?: Maybe<Scalars['String']>
  /** The user's full name (automatically calculated) */
  name?: Maybe<Scalars['String']>
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>
  /** The user's current status. */
  status: UserStatus
  /** The group the user belongs to. */
  group: UserGroup
  /** The anonymous name for the current user. */
  pseudonym: Scalars['String']
  /** The last time the user was active in the system. */
  lastActivity?: Maybe<Scalars['DateTime']>
  /** The date the user was created at. */
  createdAt: Scalars['DateTime']
  /** The date the user was last updated. */
  updatedAt: Scalars['DateTime']
  /** Gets the linked accounts for the current user. */
  linkedAccounts: LinkedAccount[]
  /** Gets notifications for the current user. */
  notifications: NotificationsConnection
  /** The number of unread notifications for the current user. */
  unreadNotifications: Scalars['Int']
  /** The optional teacher associated with the user. */
  teacher?: Maybe<Teacher>
  /** The student associated with the user. */
  student?: Maybe<Student>
  /** The stripe customer ID for the user. */
  customerId?: Maybe<Scalars['String']>
  /** The subscriptions associated with the user. */
  subscriptions: SubscriptionsConnection
  /** The currently-used bytes for the user's current individual subscription. */
  subscriptionUsage?: Maybe<Scalars['Float']>
  /** The quota bytes for the user's current individual subscription. */
  subscriptionQuota?: Maybe<Scalars['Float']>
  /** Gets the required tasks for the current user. */
  tasks: UserTask[]
  /** Returns whether or not the current user has watched a video-on-demand video. */
  hasWatchedVod: Scalars['Boolean']
  /** Gets all tags for the current user. */
  tags: TagsConnection
  /** Lists all interactions targeting the current user. */
  interactions: InteractionsConnection
  /** Gets the base url & consumer key from the assoicated district or site */
  canvasAuthentication: CanvasAuthentication
  /** The hash used to authenticate the intercom client. */
  intercomHash: Scalars['String']
  /** The notification preferences for the current user. */
  notificationPreferences: NotificationPreference[]
  /** Gets all comments created by the current user. */
  createdComments: CommentsConnection
  /** Gets all rubric templates for the specified user. */
  rubricTemplates: RubricTemplatesConnection
  /** Gets direct message threads the current user owns or belongs to. */
  directMessageThreads: DirectMessageThreadsConnection
  /** Views all media submitted by a user. */
  media: MediaConnection
  /** All badges this user has earned. */
  badges: UserBadgesConnection
  /** Gets all sheet music compositions created by the current user. */
  sheetMusicCompositions: SheetMusicCompositionsConnection
  /** The profile image associated with the user. */
  avatar: Attachment
}

/** A user registered on the website. */
export type UserNotificationsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A user registered on the website. */
export type UserSubscriptionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  type?: Maybe<SubscriptionType>
}

/** A user registered on the website. */
export type UserTasksArgs = {
  first?: Maybe<Scalars['Int']>
  completed?: Maybe<Scalars['Boolean']>
  input?: Maybe<UserTasksFilterInput>
}

/** A user registered on the website. */
export type UserHasWatchedVodArgs = {
  id: Scalars['ID']
}

/** A user registered on the website. */
export type UserTagsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

/** A user registered on the website. */
export type UserInteractionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  afterDate?: Maybe<Scalars['String']>
  types?: Maybe<Scalars['String'][]>
}

/** A user registered on the website. */
export type UserCreatedCommentsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A user registered on the website. */
export type UserRubricTemplatesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

/** A user registered on the website. */
export type UserDirectMessageThreadsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  filters?: Maybe<DirectMessageThreadsFilters>
}

/** A user registered on the website. */
export type UserMediaArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}

/** A user registered on the website. */
export type UserBadgesArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
}

/** A user registered on the website. */
export type UserSheetMusicCompositionsArgs = {
  first?: Maybe<Scalars['Int']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  after?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
  name?: Maybe<Scalars['String']>
  classIds?: Maybe<Scalars['ID'][]>
}

export type UserBadgesConnection = Connection & {
  __typename?: 'UserBadgesConnection'
  totalCount: Scalars['Int']
  pageInfo: PageInfo
  edges: UserBadgesEdge[]
}

export type UserBadgesEdge = Edge & {
  __typename?: 'UserBadgesEdge'
  node: Badge
  cursor: Scalars['String']
  awardedAt: Scalars['DateTime']
}

export enum UserGroup {
  Admin = 'ADMIN',
  Teacher = 'TEACHER',
  Student = 'STUDENT',
  Anonymous = 'ANONYMOUS',
}

export type UserInput = {
  username?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  status?: Maybe<UserStatus>
  group?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  avatar?: Maybe<Scalars['JSON']>
}

/** The payload returned when updating a user. */
export type UserPayload = {
  __typename?: 'UserPayload'
  user: User
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type UserTask = {
  identifier: Scalars['String']
  completedAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['DateTime']>
}

export type UserTasksFilterInput = {
  completed?: Maybe<Scalars['Boolean']>
  /** An optional class ID to filter the user tasks on. */
  classId?: Maybe<Scalars['ID']>
}

export type UsersConnection = Connection & {
  __typename?: 'UsersConnection'
  pageInfo: PageInfo
  edges: UsersEdge[]
  totalCount: Scalars['Int']
}

export type UsersEdge = Edge & {
  __typename?: 'UsersEdge'
  cursor: Scalars['String']
  node: User
}

export type VideoOnDemand = Node & {
  __typename?: 'VideoOnDemand'
  id: Scalars['ID']
  /** The name of the video. */
  name: Scalars['String']
  /** The description entered for the video. */
  description?: Maybe<Scalars['String']>
  /** The current processing status of the video. */
  status: VideoOnDemandProcessingStatus
  /** The time the video was created. */
  createdAt: Scalars['DateTime']
  /** The time the video was last updated. */
  updatedAt: Scalars['DateTime']
  /** The time the video was deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>
}

export type VideoOnDemandConnection = Connection & {
  __typename?: 'VideoOnDemandConnection'
  pageInfo: PageInfo
  totalCount: Scalars['Int']
  edges: VideoOnDemandEdge[]
}

export type VideoOnDemandEdge = Edge & {
  __typename?: 'VideoOnDemandEdge'
  node: VideoOnDemand
  cursor: Scalars['String']
}

export type VideoOnDemandInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type VideoOnDemandPayload = {
  __typename?: 'VideoOnDemandPayload'
  /** The updated videoOnDemand */
  videoOnDemand: VideoOnDemand
}

export type VideoOnDemandProcessingStatus = {
  __typename?: 'VideoOnDemandProcessingStatus'
  /** The current processing status for the video. */
  status: VideoOnDemandStatus
  /** The current percentage of the processing. */
  percentage: Scalars['Int']
  /** The signed ID for the video, for playback. */
  signedId?: Maybe<Scalars['String']>
  /** The duration of the video. */
  duration?: Maybe<Scalars['Int']>
}

export enum VideoOnDemandStatus {
  Uploading = 'UPLOADING',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Error = 'ERROR',
}

export type ViewSegmentUserTask = UserTask & {
  __typename?: 'ViewSegmentUserTask'
  identifier: Scalars['String']
  completedAt?: Maybe<Scalars['DateTime']>
  date?: Maybe<Scalars['DateTime']>
  payload: ViewSegmentUserTaskPayload
}

export type ViewSegmentUserTaskPayload = {
  __typename?: 'ViewSegmentUserTaskPayload'
  classId: Scalars['ID']
  segmentId: Scalars['String']
}

export type ClassSheetMusicCompositionsQueryVariables = Exact<{
  classId: Scalars['ID']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  userIds?: Maybe<Scalars['ID'][] | Scalars['ID']>
  name?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}>

export type ClassSheetMusicCompositionsQuery = { __typename?: 'Query' } & {
  node?: Maybe<
    | ({ __typename?: 'Announcement' } & Pick<Announcement, 'id'>)
    | ({ __typename?: 'Assignment' } & Pick<Assignment, 'id'>)
    | ({ __typename?: 'AssignmentCategory' } & Pick<AssignmentCategory, 'id'>)
    | ({ __typename?: 'AssignmentSubmission' } & Pick<AssignmentSubmission, 'id'>)
    | ({ __typename?: 'AssignmentSync' } & Pick<AssignmentSync, 'id'>)
    | ({ __typename?: 'Badge' } & Pick<Badge, 'id'>)
    | ({ __typename?: 'Class' } & Pick<Class, 'id'> & {
          sheetMusicCompositions: { __typename?: 'SheetMusicCompositionsConnection' } & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            edges: ({ __typename?: 'SheetMusicCompositionsEdge' } & {
              node: { __typename?: 'SheetMusicComposition' } & SheetMusicCompositionFragment
            })[]
          }
        })
    | ({ __typename?: 'ClassAssignment' } & Pick<ClassAssignment, 'id'>)
    | ({ __typename?: 'ClassAssignmentSubmission' } & Pick<ClassAssignmentSubmission, 'id'>)
    | ({ __typename?: 'ClassSync' } & Pick<ClassSync, 'id'>)
    | ({ __typename?: 'Comment' } & Pick<Comment, 'id'>)
    | ({ __typename?: 'Course' } & Pick<Course, 'id'>)
    | ({ __typename?: 'CourseVersion' } & Pick<CourseVersion, 'id'>)
    | ({ __typename?: 'DirectMessageThread' } & Pick<DirectMessageThread, 'id'>)
    | ({ __typename?: 'District' } & Pick<District, 'id'>)
    | ({ __typename?: 'GeneratedPDF' } & Pick<GeneratedPdf, 'id'>)
    | ({ __typename?: 'Interaction' } & Pick<Interaction, 'id'>)
    | ({ __typename?: 'Lesson' } & Pick<Lesson, 'id'>)
    | ({ __typename?: 'License' } & Pick<License, 'id'>)
    | ({ __typename?: 'Media' } & Pick<Media, 'id'>)
    | ({ __typename?: 'Notification' } & Pick<Notification, 'id'>)
    | ({ __typename?: 'Practice' } & Pick<Practice, 'id'>)
    | ({ __typename?: 'RubricTemplate' } & Pick<RubricTemplate, 'id'>)
    | ({ __typename?: 'Segment' } & Pick<Segment, 'id'>)
    | ({ __typename?: 'SegmentAssignment' } & Pick<SegmentAssignment, 'id'>)
    | ({ __typename?: 'SheetMusicComposition' } & Pick<SheetMusicComposition, 'id'>)
    | ({ __typename?: 'Site' } & Pick<Site, 'id'>)
    | ({ __typename?: 'Student' } & Pick<Student, 'id'>)
    | ({ __typename?: 'Subscription' } & Pick<Subscription, 'id'>)
    | ({ __typename?: 'Tag' } & Pick<Tag, 'id'>)
    | ({ __typename?: 'Teacher' } & Pick<Teacher, 'id'>)
    | ({ __typename?: 'TrialRequest' } & Pick<TrialRequest, 'id'>)
    | ({ __typename?: 'User' } & Pick<User, 'id'>)
    | ({ __typename?: 'VideoOnDemand' } & Pick<VideoOnDemand, 'id'>)
  >
}

export type CreateSheetMusicCompositionMutationVariables = Exact<{
  input: SheetMusicCompositionInput
}>

export type CreateSheetMusicCompositionMutation = { __typename?: 'Mutation' } & {
  createSheetMusicComposition: { __typename?: 'SheetMusicCompositionPayload' } & {
    sheetMusicComposition: { __typename?: 'SheetMusicComposition' } & SheetMusicCompositionFragment
    uploadPayload?: Maybe<{ __typename?: 'MediaUploadPayload' } & MediaUploadPayloadFragment>
  }
}

export type SheetMusicCompositionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type SheetMusicCompositionQuery = { __typename?: 'Query' } & {
  node?: Maybe<
    | { __typename?: 'Announcement' }
    | { __typename?: 'Assignment' }
    | { __typename?: 'AssignmentCategory' }
    | { __typename?: 'AssignmentSubmission' }
    | { __typename?: 'AssignmentSync' }
    | { __typename?: 'Badge' }
    | { __typename?: 'Class' }
    | { __typename?: 'ClassAssignment' }
    | { __typename?: 'ClassAssignmentSubmission' }
    | { __typename?: 'ClassSync' }
    | { __typename?: 'Comment' }
    | { __typename?: 'Course' }
    | { __typename?: 'CourseVersion' }
    | { __typename?: 'DirectMessageThread' }
    | { __typename?: 'District' }
    | { __typename?: 'GeneratedPDF' }
    | { __typename?: 'Interaction' }
    | { __typename?: 'Lesson' }
    | { __typename?: 'License' }
    | { __typename?: 'Media' }
    | { __typename?: 'Notification' }
    | { __typename?: 'Practice' }
    | { __typename?: 'RubricTemplate' }
    | { __typename?: 'Segment' }
    | { __typename?: 'SegmentAssignment' }
    | ({ __typename?: 'SheetMusicComposition' } & SheetMusicCompositionFragment)
    | { __typename?: 'Site' }
    | { __typename?: 'Student' }
    | { __typename?: 'Subscription' }
    | { __typename?: 'Tag' }
    | { __typename?: 'Teacher' }
    | { __typename?: 'TrialRequest' }
    | { __typename?: 'User' }
    | { __typename?: 'VideoOnDemand' }
  >
}

export type ViewerSheetMusicCompositionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  classIds?: Maybe<Scalars['ID'][] | Scalars['ID']>
  name?: Maybe<Scalars['String']>
  orderBy?: Maybe<Scalars['String']>
  orderDirection?: Maybe<OrderDirection>
}>

export type ViewerSheetMusicCompositionsQuery = { __typename?: 'Query' } & {
  viewer?: Maybe<
    { __typename?: 'User' } & {
      sheetMusicCompositions: { __typename?: 'SheetMusicCompositionsConnection' } & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        edges: ({ __typename?: 'SheetMusicCompositionsEdge' } & {
          node: { __typename?: 'SheetMusicComposition' } & SheetMusicCompositionFragment
        })[]
      }
    }
  >
}

export type SheetMusicCompositionFragment = { __typename?: 'SheetMusicComposition' } & Pick<
  SheetMusicComposition,
  'id' | 'name' | 'abc' | 'state' | 'createdAt' | 'classId'
> & {
    media?: Maybe<{ __typename?: 'Media' } & MediaFragment>
    user: { __typename?: 'User' } & UserFragment
  }

export type MediaFragment = { __typename: 'Media' } & Pick<
  Media,
  | 'id'
  | 'status'
  | 'type'
  | 'path'
  | 'thumbnail'
  | 'durationInSeconds'
  | 'createdAt'
  | 'waveform'
  | 'submittedAt'
  | 'processedAt'
  | 'hasUnreadComments'
> & {
    metadata?: Maybe<
      { __typename?: 'MediaMetadata' } & {
        bookmarks?: Maybe<
          ({ __typename?: 'MediaBookmark' } & Pick<
            MediaBookmark,
            'startSeconds' | 'endSeconds' | 'name'
          >)[]
        >
      }
    >
    user: { __typename?: 'User' } & Pick<User, 'id' | 'name' | 'username'>
    comments: { __typename?: 'CommentsConnection' } & Pick<CommentsConnection, 'totalCount'>
  }

export type MediaUploadPayloadFragment = { __typename: 'MediaUploadPayload' } & Pick<
  MediaUploadPayload,
  'signedUrl' | 'path' | 'data'
> & { media: { __typename?: 'Media' } & Pick<Media, 'id' | 'type'> }

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'name'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'username'
  | 'group'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'lastActivity'
>

export const MediaFragmentDoc = gql`
  fragment Media on Media {
    __typename
    id
    status
    type
    path
    thumbnail
    durationInSeconds
    createdAt
    waveform
    metadata {
      bookmarks {
        startSeconds
        endSeconds
        name
      }
    }
    user {
      id
      name
      username
    }
    comments {
      totalCount
    }
    submittedAt
    processedAt
    hasUnreadComments
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    email
    firstName
    lastName
    username
    group
    status
    createdAt
    updatedAt
    lastActivity
  }
`
export const SheetMusicCompositionFragmentDoc = gql`
  fragment SheetMusicComposition on SheetMusicComposition {
    id
    name
    abc
    state
    createdAt
    classId
    media {
      ...Media
    }
    user {
      ...User
    }
  }
  ${MediaFragmentDoc}
  ${UserFragmentDoc}
`
export const MediaUploadPayloadFragmentDoc = gql`
  fragment MediaUploadPayload on MediaUploadPayload {
    __typename
    signedUrl
    path
    data
    media {
      id
      type
    }
  }
`
export const ClassSheetMusicCompositionsDocument = gql`
  query classSheetMusicCompositions(
    $classId: ID!
    $first: Int = 32
    $after: String
    $userIds: [ID!]
    $name: String
    $orderBy: String = "createdAt"
    $orderDirection: OrderDirection = DESC
  ) {
    node(id: $classId) {
      id
      ... on Class {
        sheetMusicCompositions(
          first: $first
          after: $after
          userIds: $userIds
          name: $name
          orderBy: $orderBy
          orderDirection: $orderDirection
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              ...SheetMusicComposition
            }
          }
        }
      }
    }
  }
  ${SheetMusicCompositionFragmentDoc}
`

/**
 * __useClassSheetMusicCompositionsQuery__
 *
 * To run a query within a React component, call `useClassSheetMusicCompositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassSheetMusicCompositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassSheetMusicCompositionsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      userIds: // value for 'userIds'
 *      name: // value for 'name'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useClassSheetMusicCompositionsQuery(
  baseOptions?: ApolloReactCommon.QueryHookOptions<
    ClassSheetMusicCompositionsQuery,
    ClassSheetMusicCompositionsQueryVariables
  >,
) {
  return ApolloReactCommon.useQuery<
    ClassSheetMusicCompositionsQuery,
    ClassSheetMusicCompositionsQueryVariables
  >(ClassSheetMusicCompositionsDocument, baseOptions)
}
export function useClassSheetMusicCompositionsLazyQuery(
  baseOptions?: ApolloReactCommon.LazyQueryHookOptions<
    ClassSheetMusicCompositionsQuery,
    ClassSheetMusicCompositionsQueryVariables
  >,
) {
  return ApolloReactCommon.useLazyQuery<
    ClassSheetMusicCompositionsQuery,
    ClassSheetMusicCompositionsQueryVariables
  >(ClassSheetMusicCompositionsDocument, baseOptions)
}
export type ClassSheetMusicCompositionsQueryHookResult = ReturnType<
  typeof useClassSheetMusicCompositionsQuery
>
export type ClassSheetMusicCompositionsLazyQueryHookResult = ReturnType<
  typeof useClassSheetMusicCompositionsLazyQuery
>
export type ClassSheetMusicCompositionsQueryResult = ApolloReactCommon.QueryResult<
  ClassSheetMusicCompositionsQuery,
  ClassSheetMusicCompositionsQueryVariables
>
export const CreateSheetMusicCompositionDocument = gql`
  mutation createSheetMusicComposition($input: SheetMusicCompositionInput!) {
    createSheetMusicComposition(input: $input) {
      sheetMusicComposition {
        ...SheetMusicComposition
      }
      uploadPayload {
        ...MediaUploadPayload
      }
    }
  }
  ${SheetMusicCompositionFragmentDoc}
  ${MediaUploadPayloadFragmentDoc}
`
export type CreateSheetMusicCompositionMutationFn = ApolloReactCommon.MutationFunction<
  CreateSheetMusicCompositionMutation,
  CreateSheetMusicCompositionMutationVariables
>

/**
 * __useCreateSheetMusicCompositionMutation__
 *
 * To run a mutation, you first call `useCreateSheetMusicCompositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSheetMusicCompositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSheetMusicCompositionMutation, { data, loading, error }] = useCreateSheetMusicCompositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSheetMusicCompositionMutation(
  baseOptions?: ApolloReactCommon.MutationHookOptions<
    CreateSheetMusicCompositionMutation,
    CreateSheetMusicCompositionMutationVariables
  >,
) {
  return ApolloReactCommon.useMutation<
    CreateSheetMusicCompositionMutation,
    CreateSheetMusicCompositionMutationVariables
  >(CreateSheetMusicCompositionDocument, baseOptions)
}
export type CreateSheetMusicCompositionMutationHookResult = ReturnType<
  typeof useCreateSheetMusicCompositionMutation
>
export type CreateSheetMusicCompositionMutationResult = ApolloReactCommon.MutationResult<CreateSheetMusicCompositionMutation>
export type CreateSheetMusicCompositionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSheetMusicCompositionMutation,
  CreateSheetMusicCompositionMutationVariables
>
export const SheetMusicCompositionDocument = gql`
  query sheetMusicComposition($id: ID!) {
    node(id: $id) {
      ...SheetMusicComposition
    }
  }
  ${SheetMusicCompositionFragmentDoc}
`

/**
 * __useSheetMusicCompositionQuery__
 *
 * To run a query within a React component, call `useSheetMusicCompositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSheetMusicCompositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSheetMusicCompositionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSheetMusicCompositionQuery(
  baseOptions?: ApolloReactCommon.QueryHookOptions<
    SheetMusicCompositionQuery,
    SheetMusicCompositionQueryVariables
  >,
) {
  return ApolloReactCommon.useQuery<
    SheetMusicCompositionQuery,
    SheetMusicCompositionQueryVariables
  >(SheetMusicCompositionDocument, baseOptions)
}
export function useSheetMusicCompositionLazyQuery(
  baseOptions?: ApolloReactCommon.LazyQueryHookOptions<
    SheetMusicCompositionQuery,
    SheetMusicCompositionQueryVariables
  >,
) {
  return ApolloReactCommon.useLazyQuery<
    SheetMusicCompositionQuery,
    SheetMusicCompositionQueryVariables
  >(SheetMusicCompositionDocument, baseOptions)
}
export type SheetMusicCompositionQueryHookResult = ReturnType<typeof useSheetMusicCompositionQuery>
export type SheetMusicCompositionLazyQueryHookResult = ReturnType<
  typeof useSheetMusicCompositionLazyQuery
>
export type SheetMusicCompositionQueryResult = ApolloReactCommon.QueryResult<
  SheetMusicCompositionQuery,
  SheetMusicCompositionQueryVariables
>
export const ViewerSheetMusicCompositionsDocument = gql`
  query viewerSheetMusicCompositions(
    $first: Int = 32
    $after: String
    $classIds: [ID!]
    $name: String
    $orderBy: String = "createdAt"
    $orderDirection: OrderDirection = DESC
  ) {
    viewer {
      sheetMusicCompositions(
        first: $first
        after: $after
        classIds: $classIds
        name: $name
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...SheetMusicComposition
          }
        }
      }
    }
  }
  ${SheetMusicCompositionFragmentDoc}
`

/**
 * __useViewerSheetMusicCompositionsQuery__
 *
 * To run a query within a React component, call `useViewerSheetMusicCompositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerSheetMusicCompositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerSheetMusicCompositionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      classIds: // value for 'classIds'
 *      name: // value for 'name'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useViewerSheetMusicCompositionsQuery(
  baseOptions?: ApolloReactCommon.QueryHookOptions<
    ViewerSheetMusicCompositionsQuery,
    ViewerSheetMusicCompositionsQueryVariables
  >,
) {
  return ApolloReactCommon.useQuery<
    ViewerSheetMusicCompositionsQuery,
    ViewerSheetMusicCompositionsQueryVariables
  >(ViewerSheetMusicCompositionsDocument, baseOptions)
}
export function useViewerSheetMusicCompositionsLazyQuery(
  baseOptions?: ApolloReactCommon.LazyQueryHookOptions<
    ViewerSheetMusicCompositionsQuery,
    ViewerSheetMusicCompositionsQueryVariables
  >,
) {
  return ApolloReactCommon.useLazyQuery<
    ViewerSheetMusicCompositionsQuery,
    ViewerSheetMusicCompositionsQueryVariables
  >(ViewerSheetMusicCompositionsDocument, baseOptions)
}
export type ViewerSheetMusicCompositionsQueryHookResult = ReturnType<
  typeof useViewerSheetMusicCompositionsQuery
>
export type ViewerSheetMusicCompositionsLazyQueryHookResult = ReturnType<
  typeof useViewerSheetMusicCompositionsLazyQuery
>
export type ViewerSheetMusicCompositionsQueryResult = ApolloReactCommon.QueryResult<
  ViewerSheetMusicCompositionsQuery,
  ViewerSheetMusicCompositionsQueryVariables
>
