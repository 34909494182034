import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { styled, s, Text, Block, Button } from '@thesisedu/ui'
import { Undo } from '@thesisedu/ui/icons'
import React from 'react'

import { usePlacementDrawerInnerContext } from './PlacementDrawerInnerContext'
import { usePlacementDrawerForeignSegment } from './usePlacementDrawerForeignSegment'
import { Segment } from '../../outline/Segment'
import { BuiltSegmentFragment } from '../../schema'
import { getForeignOutlineItem } from '../getForeignOutlineItem'

export interface PlacementDrawerPlacingSegmentProps {
  segment: BuiltSegmentFragment
}
function _PlacementDrawerPlacingSegment({ segment }: PlacementDrawerPlacingSegmentProps) {
  usePlacementDrawerForeignSegment(segment)
  const { attributes, setNodeRef, node, listeners, isDragging } = useSortable({
    id: segment.built.id,
    transition: null,
    disabled: {
      draggable: false,
      droppable: true,
    },
  })
  const { onMouseDown, ...restListeners } = listeners ?? {}
  const { nodeRef, onMouseDownRef, pickUp } = usePlacementDrawerInnerContext(true)
  React.useEffect(() => {
    nodeRef.current = node.current
    onMouseDownRef.current = onMouseDown
    const handle = setTimeout(() => {
      if (node.current && onMouseDownRef.current) {
        const onMouseDown = onMouseDownRef.current
        const rect = node.current.getBoundingClientRect()
        onMouseDown({
          nativeEvent: {
            defaultPrevented: false,
            type: 'mousedown',
            target: node,
            offsetY: rect.y,
            offsetX: rect.x, // Required for our code to function.
            clientX: rect.x,
            clientY: rect.y,
          },
        })
      }
    }, 200)
    return () => clearTimeout(handle)
  }, [])
  return (
    <s.ThemeProvider colorVariant={'dark'}>
      <Container ref={setNodeRef} {...attributes} {...restListeners}>
        <Block right style={{ minWidth: 0 }}>
          <Text level={'s'} color={'secondary'}>
            You are placing this content:
          </Text>
          <Segment segment={segment.built} noLink readOnly />
        </Block>
        {isDragging ? null : (
          <Button
            icon={<Undo />}
            onPress={() => {
              pickUp()
            }}
            variant={'ghost'}
          />
        )}
      </Container>
    </s.ThemeProvider>
  )
}

export function PlacementDrawerPlacingSegment(props: PlacementDrawerPlacingSegmentProps) {
  const items = [getForeignOutlineItem(props.segment.built)]
  return (
    <SortableContext items={items} id={'placing-content'} strategy={verticalListSortingStrategy}>
      <_PlacementDrawerPlacingSegment {...props} />
    </SortableContext>
  )
}

const Container = styled.div`
  margin: calc(-1 * ${s.size('m')});
  margin-bottom: ${s.size('s')};
  background: ${s.color('element')};
  padding: ${s.size('s')} ${s.size('m')} ${s.size('xs')} ${s.size('m')};
  display: flex;
  align-items: center;
  gap: ${s.size('s')};
`
