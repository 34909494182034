import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { mergeRegister } from '@lexical/utils'
import { TextField, Text, Button, Block } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  GridSelection,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import React from 'react'

import { getSelectedNode } from '../../utils/getSelectedNode'
import { setFloatingElementPosition } from '../../utils/setFloatingElementPosition'
import { sanitizeUrl } from '../../utils/url'
import { FloatingToolbarContainer } from '../TextFormatToolbar/TextFormatFloatingToolbar'

export interface FloatingLinkEditorProps {
  editor: LexicalEditor
  anchorElement: HTMLElement
  setIsLink: (isLink: boolean) => void
  isLink: boolean
}
export function FloatingLinkEditor({
  editor,
  anchorElement,
  setIsLink,
  isLink,
}: FloatingLinkEditorProps) {
  const editorRef = React.useRef<HTMLDivElement | null>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [linkUrl, setLinkUrl] = React.useState('')
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [lastSelection, setLastSelection] = React.useState<
    RangeSelection | GridSelection | NodeSelection | null
  >(null)

  const updateLinkEditor = React.useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL())
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL())
      } else {
        setLinkUrl('')
      }
    }

    const editorElement = editorRef.current
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (editorElement === null) return
    const rootElement = editor.getRootElement()

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRange = nativeSelection.getRangeAt(0)
      let rect
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement
        while (inner.firstElementChild !== null) {
          inner = inner.firstElementChild as HTMLElement
        }
        rect = inner.getBoundingClientRect()
      } else {
        rect = domRange.getBoundingClientRect()
      }

      setFloatingElementPosition(rect, editorElement, anchorElement)
      setLastSelection(selection)
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        setFloatingElementPosition(null, editorElement, anchorElement)
      }
      setLastSelection(null)
      setIsEditMode(false)
      setLinkUrl('')
    }

    return true
  }, [anchorElement, editor])

  React.useEffect(() => {
    const scrollerElement = anchorElement.parentElement
    const update = () => {
      editor.getEditorState().read(() => {
        updateLinkEditor()
      })
    }

    window.addEventListener('resize', update)
    if (scrollerElement) {
      scrollerElement.addEventListener('scroll', update)
    }

    return () => {
      window.removeEventListener('resize', update)
      if (scrollerElement) {
        scrollerElement.removeEventListener('scroll', update)
      }
    }
  }, [anchorElement.parentElement, editor, updateLinkEditor])

  React.useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor()
          return true
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH,
      ),
    )
  }, [editor, updateLinkEditor, setIsLink, isLink])

  React.useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor()
    })
  }, [editor, updateLinkEditor])

  React.useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  return (
    <FloatingToolbarContainer ref={editorRef}>
      {isEditMode ? (
        <TextField
          ref={inputRef}
          value={linkUrl}
          onChange={setLinkUrl}
          style={{ width: 300 }}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === 'Escape') {
              e.preventDefault()
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(linkUrl))
                }
                setIsEditMode(false)
              }
            }
          }}
        />
      ) : (
        <>
          <Block left={'xs'}>
            <Text
              color={'primary'}
              as={'a'}
              href={linkUrl}
              target={'_blank'}
              rel={'noopener noreferrer'}
              style={{ minWidth: 250 }}
            >
              {linkUrl}
            </Text>
          </Block>
          <Button variant={'ghost'} icon={<Edit />} onPress={() => setIsEditMode(true)} />
        </>
      )}
    </FloatingToolbarContainer>
  )
}
