import React from 'react'

import { s, Block, Block$ } from '../..'

export interface SolidIconProps extends Omit<Block$.BlockProps, 'children'> {
  icon: React.ReactElement
  background?: s.ColorAlias
  color?: s.ColorAlias
  monoColor?: keyof s.Colors
  level?: keyof s.FontSizeAliases | number
  variant?: SolidIconVariant
}
export function SolidIcon({
  icon,
  monoColor,
  background = monoColor ? `${monoColor}.element` : 'element',
  color: _color = monoColor ? `${monoColor}.text` : undefined,
  level,
  variant = 'square',
  ...rest
}: SolidIconProps) {
  const color = _color ? s.color(_color) : s.useForegroundColor(background)
  return (
    <_SolidIcon
      {...rest}
      style={{
        fontSize: level ? (typeof level === 'number' ? level : s.size(`font.${level}`)) : undefined,
        background: s.color(background),
        color,
        ...rest.style,
      }}
      className={s.variants(rest.className, {}, variant)}
      children={icon}
    />
  )
}

const { css } = s
const Variants = {
  round: css`
    border-radius: 50%;
  `,
  square: css`
    border-radius: ${s.var('radii.1')};
  `,
} satisfies s.Variants
export type SolidIconVariant = keyof typeof Variants

const _SolidIcon = s.styledWithVariants(
  Block,
  'SolidIcon',
  css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      display: block;
      width: 0.85em;
      height: 0.85em;
    }
    width: 1em;
    height: 1em;
  `,
  Variants,
)
