import {
  Block,
  HSpaced,
  Body,
  LoadingIndicator,
  Empty,
  Checkbox,
  Space,
  AntIconWrapper,
} from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import React from 'react'

import { ClassStudent } from './ClassStudent'
import { MultiStudentSelectProps } from './MultiStudentSelect'
import { useStudentGroups, ALL_STUDENTS } from './useStudentGroups'
import { StudentWithGroup } from '../types'

export function SimpleMultiStudentSelect({
  value,
  classId,
  onChange,
  single,
}: MultiStudentSelectProps) {
  const { groupedStudents, sortedKeys, loading } = useStudentGroups(classId)

  if (sortedKeys?.length) {
    if (sortedKeys.length === 1 && sortedKeys[0] === ALL_STUDENTS) {
      return (
        <MultiStudentSelectStudentList
          value={value}
          onChange={onChange}
          students={groupedStudents[sortedKeys[0]]}
          single={single}
        />
      )
    } else {
      return (
        <>
          {sortedKeys.map((studentGroupName, index) => (
            <Block marginTop={index === 0 ? false : '@size-m'} key={studentGroupName}>
              <Block marginBottom={'@size-xs'}>
                <HSpaced>
                  <Body color={'@text-color-secondary'}>{studentGroupName}</Body>
                  <Space />
                  {single ? null : (
                    <MultiStudentSelectStudentCheckbox
                      value={value}
                      onChange={onChange}
                      students={groupedStudents[studentGroupName]}
                    />
                  )}
                </HSpaced>
              </Block>
              <MultiStudentSelectStudentList
                value={value}
                onChange={onChange}
                students={groupedStudents[studentGroupName]}
                single={single}
              />
            </Block>
          ))}
        </>
      )
    }
  } else if (loading) {
    return <LoadingIndicator block />
  } else {
    return <Empty description={'No students'} />
  }
}

export interface MultiStudentSelectStudentListProps {
  students: StudentWithGroup[]
  value?: string[]
  onChange?: (value: string[]) => void
  single?: boolean
}
export function MultiStudentSelectStudentList({
  students,
  value,
  onChange,
  single,
}: MultiStudentSelectStudentListProps) {
  const onClick = (student: StudentWithGroup) => () => {
    if (!onChange) return
    const without = [...(value || [])].filter(v => v !== student.id)
    if (without.length === (value || []).length) {
      onChange([...without, student.id])
    } else {
      onChange(without)
    }
  }

  return (
    <>
      {students.map(student => (
        <ClassStudent
          studentId={student.id}
          classId={student.classId}
          key={student.id}
          onClick={onClick(student)}
          rightContent={
            single ? (
              <AntIconWrapper children={<NavArrowRight />} />
            ) : (
              <Checkbox checked={value?.includes(student.id)} onChange={onClick(student)} />
            )
          }
        />
      ))}
    </>
  )
}

export interface MultiStudentSelectStudentCheckboxProps {
  students: StudentWithGroup[]
  value?: string[]
  onChange?: (value: string[]) => void
}
export function MultiStudentSelectStudentCheckbox({
  students,
  value,
  onChange,
}: MultiStudentSelectStudentCheckboxProps) {
  const selectedCount = students.filter(s => value?.includes(s.id)).length
  return (
    <Checkbox
      checked={selectedCount === students.length}
      indeterminate={selectedCount > 0 && selectedCount < students.length}
      onClick={e => e.stopPropagation()}
      onChange={e => {
        if (!onChange) return
        const valueWithout = value?.filter(v => !students.some(s => s.id === v)) || []
        if (e.target.checked) {
          onChange([...valueWithout, ...students.map(s => s.id)])
        } else {
          onChange(valueWithout)
        }
      }}
    />
  )
}
