import attachProviderClass from './attachProviderClass'
import authenticateWithProvider from './authenticateWithProvider'
import fetchClassStudentsFromProvider from './fetchClassStudentsFromProvider'
import fetchClassesFromProvider from './fetchClassesFromProvider'
import learnGradeSync from './learnGradeSync'
import listProviderClasses from './listProviderClasses'
import preAuthenticateWithProvider from './preAuthenticateWithProvider'
import providerError from './providerError'
import rosterComplete from './rosterComplete'
import rosterStudents from './rosterStudents'
import rosterStudentsProgress from './rosterStudentsProgress'
import selectProvider from './selectProvider'
import { SetupStep, TransitionOpts } from '../types'

export const DefaultSteps = {
  selectProvider,
  authenticateWithProvider,
  fetchClassesFromProvider,
  listProviderClasses,
  providerError,
  preAuthenticateWithProvider,
  rosterStudents,
  attachProviderClass,
  fetchClassStudentsFromProvider,
  rosterStudentsProgress,
  rosterComplete,
  learnGradeSync,
} satisfies Record<string, SetupStep<any>>

export const DefaultStep: TransitionOpts = {
  step: 'selectProvider',
  context: {},
}

export function useSteps(): Record<string, SetupStep<any>> {
  return DefaultSteps
}
