import { useApolloClient } from '@apollo/client'
import { ErrorService } from '@thesisedu/feature'
import { Format } from '@thesisedu/feature-utils'
import FTNativeUpload, {
  HeadersObject,
  HTTPMethod,
  UploadError,
  UploadFile,
} from '@thesisedu/native-upload'
import { AxiosRequestConfig } from 'axios'
import path from 'path'
import { useEffect, useRef, useState } from 'react'

import { PLATFORM } from './platform'
import { debug, error, warn } from '../log'
import { AttachmentReadFragment, AttachmentReadFragmentDoc, UploadRequestMode } from '../schema'
import { MinimalWriteFragment, UploadProps } from '../types'

export type UseErrorService = () => ErrorService

export interface UseUploadOpts extends UploadProps {
  onError: (error: string) => void
}

export const useUpload = (useErrorService: UseErrorService, opts: UseUploadOpts) => {
  const {
    onError,
    onUploaded,
    onMinimalUploaded,
    getWriteFragment,
    beforeUpload: _beforeUpload,
    isPublic,
    forceBasicMode,
  } = opts
  const client = useApolloClient()
  const [loading, setLoading] = useState<boolean | number>(false)
  const writeFragment = useRef<MinimalWriteFragment>()
  const errorService = useErrorService()
  useEffect(() => {
    if (loading === false) {
      debug('clearing write fragment')
      writeFragment.current = undefined
    }
  }, [!loading])
  const beforeUpload = async (file: UploadFile) => {
    debug('checking file extension: %s', file.name)
    const extension = path.extname(file.name)
    if (!extension) {
      warn('no extension found')
      onError(
        'We could not automatically find the extension of that file. Please rename it and try again.',
      )
      return false
    }
    setLoading(true)
    if (_beforeUpload) {
      const result = await _beforeUpload(file)
      if (result === false) {
        debug('beforeUpload returned false')
        setLoading(false)
        return false
      }
    }
    debug('extension found: %s', extension)
    return true
  }
  const onComplete = () => {
    debug('upload is complete')
    if (writeFragment.current?.uploadUrl.attachment?.id) {
      debug('updating fragment Attachment:%s', writeFragment.current.uploadUrl.attachment.id)
      client.writeFragment<AttachmentReadFragment>({
        fragment: AttachmentReadFragmentDoc,
        fragmentName: 'AttachmentRead',
        id: `Attachment:${writeFragment.current.uploadUrl.attachment.id}`,
        data: { ...writeFragment.current.uploadUrl.attachment },
      })
      if (onUploaded) {
        debug('calling uploaded callback')
        onUploaded(writeFragment.current.uploadUrl.attachment)
      }
    } else if (onMinimalUploaded && writeFragment.current?.uploadUrl.upload.path) {
      debug('calling onMinimalUploaded with path %s', writeFragment.current.uploadUrl.upload.path)
      onMinimalUploaded(writeFragment.current.uploadUrl.upload.path)
    } else if (onMinimalUploaded) {
      warn('cannoot call onMinimalUploaded because writeFragment contains no path')
    } else if (onUploaded) {
      warn('uploaded callback is not supported with MinimalWriteFragment')
    }
    setLoading(false)
  }
  const uploadAction = async (file: UploadFile) => {
    if (opts.maxFileSizeBytes) {
      debug('checking file size')
      if (file.size > opts.maxFileSizeBytes) {
        onError(
          `That file is too large. The maximum size allowed is ${Format.filesize(
            opts.maxFileSizeBytes,
          )}`,
        )
        return ''
      }
    }
    debug('getting signed upload url')
    try {
      const result = await getWriteFragment({
        signedUploadInput: {
          mimeType: file.type,
          extension: path.extname(file.name).substr(1),
          requestMode:
            PLATFORM === 'ios' || forceBasicMode
              ? UploadRequestMode.Basic
              : UploadRequestMode.FormData,
          isPublic,
        },
        name: file.name,
        sizeInBytes: file.size,
      })
      debug('result', result)
      if (result) {
        debug('setting write fragment')
        writeFragment.current = result
        debug('signedUrl', result.uploadUrl.upload.signedUrl)
        return result.uploadUrl.upload.signedUrl
      } else {
        warn('no result')
        onError('There was an error uploading that file. Please try again later.')
        setLoading(false)
        return ''
      }
    } catch (err: any) {
      error('error getting upload url')
      error(err)
      errorService.reportError(err)
      onError('There was an error uploading that file. Please try again later.')
      setLoading(false)
      return ''
    }
  }
  const uploadFile = async (file: UploadFile, opts?: AxiosRequestConfig) => {
    try {
      debug('upload requested...')
      if (writeFragment.current) {
        debug('writeFragment found. preparing FormData...')
        const headers: HeadersObject = {}
        for (const additionalKey of Object.keys(
          writeFragment.current.uploadUrl.upload.data || {},
        )) {
          debug('adding additional key %s', additionalKey)
          headers[additionalKey] = writeFragment.current.uploadUrl.upload.data[additionalKey]
        }
        try {
          await FTNativeUpload.upload(
            writeFragment.current.uploadUrl.upload.signedUrl,
            file,
            forceBasicMode ? HTTPMethod.PUT : HTTPMethod.POST,
            headers,
            (loaded, total) => {
              debug('upload progress', loaded / total)
              setLoading(parseFloat(Math.round((loaded / total) * 100).toFixed(2)))
              if (opts?.onUploadProgress) {
                opts.onUploadProgress({ loaded, total })
              }
            },
          )

          debug('dispatching complete')
          onComplete()
        } catch (err: any) {
          if (err instanceof UploadError && err.originalResponse) {
            errorService.reportError(
              `Error uploading attachment with status code ${err.originalResponse.status}`,
              {
                result: err.originalResponse,
              },
            )
          } else {
            errorService.reportError(err)
          }
          throw err
        }
      } else {
        throw new Error('writeFragment not found')
      }
    } catch (err: any) {
      error('error uploading file')
      error(err)
      throw err
    }
  }

  return { loading, setLoading, uploadFile, beforeUpload, uploadAction }
}
