import { FilePdfOutlined } from '@ant-design/icons'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import { notification, message, Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import {
  useGeneratePdfMutation,
  GeneratedPdfStatus,
  GeneratedPdfUrlDocument,
  GeneratedPdfUrlQuery,
  GeneratedPdfUrlQueryVariables,
} from './schema'

const MESSAGE_KEY = 'generate-pdf'
const ERROR_MESSAGE = 'There was an error generating that content.'
export interface GeneratePDFButtonProps<RequestData extends object> {
  requestData: RequestData
  type: string
  buttonProps?: Partial<ButtonProps>
}
export function GeneratePDFButton<RequestData extends object = object>({
  type,
  requestData,
  buttonProps,
}: GeneratePDFButtonProps<RequestData>) {
  const [generating, setGenerating] = React.useState(false)
  const client = useApolloClient()
  const [generatePdf, { loading }] = useGeneratePdfMutation({
    onError: onMutationError(ERROR_MESSAGE),
    onCompleted: data => {
      const pdf = data.generatePdf.generatedPdf
      if (pdf.status === GeneratedPdfStatus.Done && pdf.url) {
        window.location.href = pdf.url
      } else if (pdf.status === GeneratedPdfStatus.Pending) {
        setGenerating(true)
        message.loading({ content: 'Generating content...', duration: 100, key: MESSAGE_KEY })
        const query = client.watchQuery<GeneratedPdfUrlQuery, GeneratedPdfUrlQueryVariables>({
          query: GeneratedPdfUrlDocument,
          variables: {
            id: pdf.id,
          },
          fetchPolicy: 'network-only',
          pollInterval: 5000,
        })
        const sub = query.subscribe(
          ({ data, error }) => {
            if (data?.generatedPdf?.status === GeneratedPdfStatus.Done && data?.generatedPdf?.url) {
              sub.unsubscribe()
              setGenerating(false)
              message.destroy(MESSAGE_KEY)
              notification.success({
                message: 'PDF Ready for Download',
                description: (
                  <span>
                    Your content is ready for download.{' '}
                    <a href={data.generatedPdf.url} target={'_blank'} rel={'noreferrer'}>
                      Click here to download it.
                    </a>
                  </span>
                ),
              })
              window.open(data.generatedPdf.url)
              query.stopPolling()
            } else if (error || data?.generatedPdf?.status === GeneratedPdfStatus.Failed) {
              query.stopPolling()
              setGenerating(false)
              message.error({ content: ERROR_MESSAGE, key: MESSAGE_KEY, duration: 2 })
              sub.unsubscribe()
            }
          },
          () => {
            query.stopPolling()
            setGenerating(false)
            message.error({ content: ERROR_MESSAGE, key: MESSAGE_KEY, duration: 2 })
          },
        )
      } else {
        message.error(ERROR_MESSAGE)
      }
    },
  })

  return (
    <Button
      children={'Generate PDF'}
      icon={<FilePdfOutlined />}
      loading={loading || generating}
      {...buttonProps}
      onClick={() => {
        generatePdf({
          variables: {
            input: {
              type,
              requestData,
            },
          },
        })
      }}
    />
  )
}
