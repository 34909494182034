import { useApolloClient } from '@apollo/client'
import { Button$, Dropdown, useToast } from '@thesisedu/ui'
import { CloudSync } from '@thesisedu/ui/icons'
import React from 'react'

import {
  useAvailableGroupImportsQuery,
  GroupImportGroupFragment,
  AvailableGroupImportGroupsDocument,
} from '../schema'

export interface ImportGroupsDropdownProps extends Partial<Button$.ButtonProps> {
  classId: string
  onComplete: (groups: GroupImportGroupFragment[]) => Promise<any>
}

export const ImportGroupsDropdown: React.FC<React.PropsWithChildren<ImportGroupsDropdownProps>> = ({
  classId,
  onComplete,
  ...rest
}) => {
  const toast = useToast()
  const client = useApolloClient()
  const [groupImportGroupsLoading, setGroupImportGroupsLoading] = React.useState(false)
  const { data, loading } = useAvailableGroupImportsQuery({
    variables: {
      id: classId,
    },
  })
  const groupSetImports =
    data?.node?.__typename === 'Class' ? data.node.availableGroupImports : undefined
  if (groupSetImports?.length || loading) {
    const groupSetImportsContent = groupSetImports
      ? groupSetImports.map(group => {
          return (
            <Dropdown.Item
              key={group.importId}
              onClick={async () => {
                setGroupImportGroupsLoading(true)
                try {
                  const result = await client.query({
                    query: AvailableGroupImportGroupsDocument,
                    variables: {
                      id: classId,
                      input: { importId: group.importId },
                    },
                  })
                  const groups =
                    result.data.node?.__typename === 'Class'
                      ? result.data.node?.availableGroupImportGroups?.groups
                      : undefined
                  if (groups) {
                    await onComplete(groups)
                    toast.success('Groups imported successfully!')
                  }
                } catch {
                  toast.error('Uh oh! There was an error importing your groups.')
                } finally {
                  setGroupImportGroupsLoading(false)
                }
              }}
            >
              {group.name}
            </Dropdown.Item>
          )
        })
      : null

    if (!groupSetImportsContent?.length) return null
    return (
      <Dropdown.Container>
        <Dropdown.Button
          loading={loading || groupImportGroupsLoading}
          children={'Import From LMS'}
          icon={<CloudSync />}
          {...rest}
        />
        <Dropdown.Menu side={'bottom'} align={'end'}>
          {groupSetImportsContent}
        </Dropdown.Menu>
      </Dropdown.Container>
    )
  } else {
    return null
  }
}
