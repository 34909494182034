import { TuneMeter } from '@thesisedu/feature-sheet-music-core'
import { Body, SimpleSelect, styled, VSpaced } from '@thesisedu/web'
import { Radio } from 'antd'
import React from 'react'

import { MAX_BEAMED_NOTES } from '../viewer/Beam'

export interface MeterFieldProps {
  timeSignature: TuneMeter
  onChange: (meter: TuneMeter) => void
}
export function MeterField({ timeSignature, onChange }: MeterFieldProps) {
  return (
    <VSpaced space={'@size-xs'} align={'stretch'}>
      <Body>Time Signature</Body>
      <Container>
        <SimpleSelect
          value={timeSignature.num.toString()}
          onChange={value => onChange({ ...timeSignature, num: parseFloat(value) })}
          style={{ width: 150 }}
          options={Object.keys(MAX_BEAMED_NOTES)
            .map(m => (m.length >= 3 ? m.toString().slice(0, 2) : m.toString()[0]))
            .filter((i, idx, self) => self.indexOf(i) === idx)}
        />{' '}
        /{' '}
        <Radio.Group
          value={timeSignature.den}
          onChange={e => onChange({ ...timeSignature, den: e.target.value })}
          buttonStyle={'solid'}
        >
          <Radio.Button value={4}>4</Radio.Button>
          <Radio.Button value={8}>8</Radio.Button>
        </Radio.Group>
      </Container>
    </VSpaced>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`
