import { DndContext } from '@dnd-kit/core'
import { styled, Button, HSpaced, Block, s } from '@thesisedu/ui'
import { Cancel } from '@thesisedu/ui/icons'
import React from 'react'

import { SplitViewDraggingOverContext } from './SplitViewDraggingOverContext'
import {
  SplitViewFooterHostContext,
  useSplitViewFooterHostProvider,
} from './SplitViewFooterHostContext'
import { SplitViewOutlineItemContext } from './SplitViewOutlineItemContext'
import { SplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { SplitViewPendingChangesSummary } from './SplitViewPendingChangesSummary'
import { SplitViewRefsContext } from './SplitViewRefsContext'
import { SplitViewSaveChangesButton } from './SplitViewSaveChangesButton'
import { CenterPane } from './panes/CenterPane'
import { OnDragEndOpts, PlacementPaneImperativeHandle } from './types'
import { useSplitViewPendingChanges } from './useSplitViewPendingChanges'
import { DragDropOutlineContext } from '../outline/DragDropOutlineContext'
import { DraggableOutlineItemPreview } from '../outline/items/DraggableOutlineItemPreview'
import { OutlineItem, isItemWithSegment } from '../outline/types'
import { useDragDropOutline } from '../outline/useDragDropOutline'

export enum PlacementArrowDirection {
  Right,
  Left,
  LeftRight,
}
export interface PlacementSplitViewProps {
  left: React.ReactElement
  right: React.ReactElement
  arrowDirection?: PlacementArrowDirection
  onCancel: () => void
}
export function PlacementSplitView({
  left,
  right,
  onCancel,
  arrowDirection = PlacementArrowDirection.Right,
}: PlacementSplitViewProps) {
  const outlineItems = React.useRef<Record<string, OutlineItem>>({})
  const leftRef = React.useRef<PlacementPaneImperativeHandle>(null)
  const rightRef = React.useRef<PlacementPaneImperativeHandle>(null)
  const { dndContextProps, overlayPortal, activeId, offsetLeft, draggingOverList } =
    useDragDropOutline({
      async onDragEnd({ moving, over }) {
        const movingItem = outlineItems.current[moving.id]
        const movingSegment = isItemWithSegment(movingItem) ? movingItem.segment : undefined
        if (movingSegment) {
          const opts: OnDragEndOpts = {
            movingSegment,
            overId: over.id.toString(),
          }
          const matchingSide = over.sortableId
            ? [leftRef, rightRef].find(ref => ref.current?.getSortableId() === over.sortableId)
            : undefined
          matchingSide?.current?.onDragEnd(opts)
        }
      },
      renderPortalItem(id) {
        const item = outlineItems.current[id]
        const segment = item && isItemWithSegment(item) ? item.segment : undefined
        return <DraggableOutlineItemPreview segment={segment} />
      },
    })
  const pendingChanges = useSplitViewPendingChanges()
  const { ref, contextValue } = useSplitViewFooterHostProvider()
  return (
    <SplitViewRefsContext.Provider value={{ leftRef, rightRef }}>
      <SplitViewFooterHostContext.Provider value={contextValue}>
        <SplitViewPendingChangesContext.Provider value={pendingChanges.context}>
          <SplitViewDraggingOverContext.Provider value={{ draggingOverList }}>
            <SplitViewOutlineItemContext.Provider
              value={React.useMemo(
                () => ({
                  items: outlineItems,
                }),
                [],
              )}
            >
              <DragDropOutlineContext.Provider
                value={{
                  offsetLeft,
                  draggingId: activeId?.toString(),
                }}
              >
                <DndContext {...dndContextProps}>
                  {overlayPortal}
                  <Container>
                    <Left>{React.cloneElement(left, { ref: leftRef })}</Left>
                    <CenterPane arrowDirection={arrowDirection} />
                    <Right>{React.cloneElement(right, { ref: rightRef })}</Right>
                    <CloseContainer>
                      <Button
                        icon={<Cancel />}
                        variant={'ghost'}
                        size={'small'}
                        onPress={onCancel}
                      />
                    </CloseContainer>
                    <Footer>
                      <HSpaced>
                        <Button onPress={onCancel}>Cancel</Button>
                        <Block right>
                          <SplitViewPendingChangesSummary />
                        </Block>
                        <div ref={ref} />
                        <SplitViewSaveChangesButton leftRef={leftRef} rightRef={rightRef} />
                      </HSpaced>
                    </Footer>
                  </Container>
                </DndContext>
              </DragDropOutlineContext.Provider>
            </SplitViewOutlineItemContext.Provider>
          </SplitViewDraggingOverContext.Provider>
        </SplitViewPendingChangesContext.Provider>
      </SplitViewFooterHostContext.Provider>
    </SplitViewRefsContext.Provider>
  )
}

const Container = styled.div`
  flex: 1;
  display: grid;
  position: relative;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-rows: 1fr min-content;
  grid-template-areas:
    'left    center  right'
    'footer  footer  footer';
  user-select: none;
  min-height: 0;
`
const Left = styled.div`
  grid-area: left;
  padding: ${s.size('m')};
  padding-bottom: 0;
  display: flex;
  min-height: 0;
`
const Right = styled.div`
  grid-area: right;
  padding: ${s.size('m')};
  padding-bottom: 0;
  display: flex;
  min-height: 0;
`
const Footer = styled.div`
  grid-area: footer;
  padding: ${s.size('xs')} ${s.size('m')};
`
const CloseContainer = styled.div`
  position: absolute;
  top: ${s.size('m')};
  right: ${s.size('s')};
`
