import { MediaList, MediaListProps, MediaTableListItem } from '@thesisedu/feature-media-react'
import { ShareIndicator } from '@thesisedu/feature-sharing-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { formatTime, DATE_FORMATS } from '@thesisedu/web'
import { Empty } from 'antd'
import { TableProps } from 'antd/es/table'
import moment from 'moment'
import React from 'react'

import { PracticeActions } from './PracticeActions'
import { getPracticeLabel } from './getPracticeLabel'
import { PracticeFragment } from './schema'

export interface PracticeListProps extends Partial<MediaListProps<PracticeFragment>> {
  practices: PracticeFragment[]
  onPracticeClick?: (practiceId: string) => any
  refetch: (after: string | null) => Promise<any>
}

export const PracticeList: React.FC<React.PropsWithChildren<PracticeListProps>> = ({
  practices,
  refetch,
  onPracticeClick,
  ...props
}) => {
  const viewer = useViewerContext(false)
  const columns: TableProps<MediaTableListItem>['columns'] = [
    {
      title: 'Student',
      dataIndex: ['student', 'user'],
      render: (_, record) => record.student.user.name || record.student.user.username,
    },
  ]
  return (
    <MediaList<PracticeFragment>
      items={practices}
      emptyContent={<Empty description={'No practices yet!'} />}
      refetch={refetch}
      getItemProps={item => {
        const segments = []
        if (viewer?.group === 'TEACHER') {
          segments.push(item.student.user.name || item.student.user.username)
        }
        if (item.media.durationInSeconds) {
          segments.push(formatTime(item.media.durationInSeconds.toString()))
        }
        segments.push(`Posted ${moment(item.createdAt).format(DATE_FORMATS.TEXT)}`)
        return {
          label: getPracticeLabel(item),
          subtitle: segments.join(' - '),
          onClick: () => (onPracticeClick ? onPracticeClick(item.id) : false),
          actions: <PracticeActions practice={item} onRefetch={() => refetch(null)} />,
          meta: <ShareIndicator shareable={item} />,
          student: item.student,
          badgeProps: {
            dot: !item.isViewed && item.isViewed !== null,
          },
        }
      }}
      tableProps={{
        columns,
      }}
      {...props}
    />
  )
}
