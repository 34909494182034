import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { EditUserFieldsContext, EditUserFieldsPayload, HOOKS } from '@thesisedu/feature-users-web'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import React from 'react'

export class TeachersWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-teachers-react'
  static path = ''
  static requires: string[] = []

  constructor(opts: object, deps: FeatureDependencies) {
    super(opts, deps)
    this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, async children => {
      const { ImpersonateHeader } = require('@thesisedu/feature-users-web')
      return (
        <>
          <ImpersonateHeader
            forGroup={'TEACHER'}
            formatMessage={() => <span>You are currently logged in as your student.</span>}
            closeText={'Back to Teacher Account'}
            type={'info'}
          />
          {children}
        </>
      )
    })
    this.hookManager.registerMutateHook<EditUserFieldsPayload, EditUserFieldsContext>(
      HOOKS.EditUserFields,
      async (value, context) => {
        let content: EditUserFieldsPayload = undefined
        if (context?.user.group === 'TEACHER') {
          const { UserTeacherInfo } = require('./UserTeacherInfo')
          content = <UserTeacherInfo userId={context.user.id} />
        }
        return (
          <>
            {value}
            {content}
          </>
        )
      },
    )
  }

  public reactResources() {
    require('./hooks/userMenu').default(this)
    require('./resources/UserSettingsPage/Teacher').default(this)
    require('./hooks/DistrictRightContent').default(this)
    require('./hooks/SiteContent').default(this)
    require('./hooks/routes').default(this)
  }
}

export const teachersWebFeature = (opts: object = {}): FeatureUse => [TeachersWebFeature, opts]
