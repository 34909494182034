import React from 'react'

import { Container, ContainerProps } from './Container'
import { ItemProps } from './Item'
import { SegmentedControlContext } from './context'

export interface SegmentedControlProps
  extends Omit<ContainerProps, 'children' | 'size'>,
    Pick<ItemProps, 'size'> {
  children: React.ReactElement<ItemProps>[] | React.ReactElement<ItemProps>
}
function _SegmentedControl(
  { children, size, ...rest }: SegmentedControlProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const childrenArr = Array.isArray(children) ? children : [children]
  const id = React.useId()
  return (
    <SegmentedControlContext.Provider value={{ id }}>
      <Container {...rest} ref={ref}>
        {childrenArr.map((child, index) => {
          return React.cloneElement(child, { key: child.key ?? index, size })
        })}
      </Container>
    </SegmentedControlContext.Provider>
  )
}
export const SegmentedControl = React.forwardRef(_SegmentedControl)
