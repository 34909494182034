import { Button } from 'antd'
import React, { useState } from 'react'

import { CreateDistrictModal, CreateDistrictModalProps } from './CreateDistrictModal'

export const CreateDistrictButton: React.FC<
  React.PropsWithChildren<Partial<CreateDistrictModalProps>>
> = props => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CreateDistrictModal onCancel={() => setVisible(false)} visible={visible} {...props} />
      <Button type={'primary'} onClick={() => setVisible(true)}>
        Create District
      </Button>
    </>
  )
}
