import { cloneDeep } from '@apollo/client/utilities'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useClasses } from '@thesisedu/feature-classes-react'
import { findSegment } from '@thesisedu/feature-course-types'
import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import { message } from 'antd'
import { pick } from 'lodash'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { Segment } from './Segment'
import { INPUT_FIELDS } from '../constants'
import { debug } from '../log'
import {
  BuiltSegmentDocument,
  ClassCourseFragmentDoc,
  ClassCourseFragment,
  FetchSegmentDocument,
  SegmentFragment,
  SegmentHydrationDocument,
  useCreateOrUpdateSegmentMutation as useMutation,
} from '../schema'

export interface CreateEditSegmentContextValue {
  isCreateMode?: boolean
}
export const CreateEditSegmentContext = React.createContext<
  CreateEditSegmentContextValue | undefined
>(undefined)

export function useCreateEditSegmentContext(): CreateEditSegmentContextValue | undefined
export function useCreateEditSegmentContext(
  require: false,
): CreateEditSegmentContextValue | undefined
export function useCreateEditSegmentContext(require: true): CreateEditSegmentContextValue
export function useCreateEditSegmentContext(
  require?: boolean,
): CreateEditSegmentContextValue | undefined {
  const context = React.useContext(CreateEditSegmentContext)
  if (!context && require) {
    throw new Error('CreateEditSegmentContext is required, yet not provided.')
  }
  return context
}

export const useCreateOrUpdateSegmentMutation: typeof useMutation = opts => {
  const { classes } = useClasses()
  const [mutate, rest] = useMutation({
    ...opts,
    onError: onMutationError('There was an error saving that content.'),
  })

  return [
    opts => {
      const segmentGlobalId = opts?.variables?.input.id
      return mutate({
        ...opts,
        refetchQueries: [
          segmentGlobalId
            ? { query: FetchSegmentDocument, variables: { id: segmentGlobalId } }
            : undefined,
          segmentGlobalId
            ? { query: BuiltSegmentDocument, variables: { id: segmentGlobalId } }
            : undefined,
          segmentGlobalId
            ? { query: SegmentHydrationDocument, variables: { id: segmentGlobalId } }
            : undefined,
        ].filter(Boolean) as any,
        update: (proxy, mutationData) => {
          if (
            classes?.length &&
            segmentGlobalId &&
            mutationData.data?.createOrUpdateSegment.segment.built
          ) {
            const rawId = fromGlobalId(segmentGlobalId, true).id
            for (const cls of classes) {
              const existingFragment = proxy.readFragment<ClassCourseFragment>({
                fragment: ClassCourseFragmentDoc,
                fragmentName: 'ClassCourse',
                id: `Class:${cls.id}`,
              })
              if (existingFragment?.bakedCourseConfiguration) {
                debug('finding segment %s inside ClassCourse %s...', rawId, cls.id)
                const newConfiguration = cloneDeep(existingFragment.bakedCourseConfiguration)
                const existingSegment = findSegment(newConfiguration.segments, rawId)
                if (existingSegment) {
                  debug('found segment %s inside ClassCourse %s, updating...', rawId, cls.id)
                  Object.assign(
                    existingSegment,
                    mutationData.data.createOrUpdateSegment.segment.built,
                  )
                  proxy.writeFragment<ClassCourseFragment>({
                    fragment: ClassCourseFragmentDoc,
                    fragmentName: 'ClassCourse',
                    id: `Class:${cls.id}`,
                    data: {
                      ...existingFragment,
                      bakedCourseConfiguration: newConfiguration,
                    },
                  })
                }
              }
            }
          }
        },
      })
    },
    rest,
  ]
}

export interface CreateEditSegmentProps {
  segment?: SegmentFragment
  skipRedirect?: boolean
  skipHeader?: boolean
}
export function CreateEditSegment({ segment, skipRedirect, skipHeader }: CreateEditSegmentProps) {
  const navigate = useNavigate()
  const { segmentType } = useParams()
  const [createOrUpdate, { loading }] = useCreateOrUpdateSegmentMutation()
  const isCreateMode = !segment
  let createType: string | null = null
  if (isCreateMode) {
    createType = segmentType || 'Section'
    createType = createType[0].toUpperCase() + createType.slice(1)
  }

  const realSegment = segment
    ? {
        ...segment,
        id: fromGlobalId(segment.id, true).id,
      }
    : {
        type: createType,
        id: uuid(),
        name: '',
        config: {},
      }

  return (
    <CreateEditSegmentContext.Provider value={{ isCreateMode }}>
      <Segment
        segment={realSegment as any}
        useSimpleEditing
        loading={loading}
        onChange={async (originalSegment, values) => {
          const hideLoading = message.loading('Saving content...', 0)
          debug('updating segment with values', values)
          const result = await createOrUpdate({
            variables: {
              input: {
                ...pick(realSegment, INPUT_FIELDS),
                ...pick(values, INPUT_FIELDS),
                id: toGlobalId('Segment', realSegment.id),
              } as any,
            },
          })
          hideLoading()
          if (result && result.data && result.data.createOrUpdateSegment) {
            message.success('Content updated successfully!')
            if (!skipRedirect) navigate(isCreateMode ? '../..' : '..')
          }
        }}
        skipHeader={skipHeader}
      />
    </CreateEditSegmentContext.Provider>
  )
}
