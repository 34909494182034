import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { Contact } from '@thesisedu/web'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  DistrictFragment,
  DistrictsDocument,
  DistrictsQuery,
  DistrictsQueryVariables,
} from './schema'

export const AllDistrictsTable: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <InfiniteQuery<DistrictFragment, DistrictsQuery, DistrictsQueryVariables>
      document={DistrictsDocument}
      getResult={d => d?.districts}
      setResult={(previous, result) => ({
        ...previous,
        districts: {
          ...previous.districts,
          ...result,
        },
      })}
      children={({ data }) => (
        <DistrictsTable dataSource={data?.districts.edges.map(edge => edge.node)} />
      )}
    />
  )
}

export const DistrictsTable: React.FC<
  React.PropsWithChildren<TableProps<DistrictFragment>>
> = props => (
  <Table<DistrictFragment> rowKey={'id'} pagination={false} {...props}>
    <Table.Column<DistrictFragment>
      title={'Name'}
      dataIndex={'name'}
      render={(name, record) => <Link to={`/admin/districts/${record.id}`}>{name}</Link>}
    />
    <Table.Column title={'City'} dataIndex={'city'} />
    <Table.Column title={'State'} dataIndex={'state'} />
    <Table.Column<DistrictFragment>
      title={'Contact'}
      dataIndex={'contactName'}
      render={(_, record) => <Contact name={record.contactName} email={record.contactEmail} />}
    />
    <Table.Column<DistrictFragment>
      dataIndex={'id'}
      onCell={() => ({ style: { textAlign: 'right' } })}
      render={(_, record) => (
        <>
          <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
            Edit &rarr;
          </Link>
        </>
      )}
    />
  </Table>
)
