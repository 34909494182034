import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/es/input-number'
import { isNil } from 'lodash'
import React from 'react'

export interface DaysInputProps extends InputNumberProps {
  value?: number
}
export const DaysInput = React.forwardRef<any, DaysInputProps>(
  ({ value, onChange, ...props }, ref) => {
    return (
      <>
        <InputNumber
          value={!isNil(value) ? value * 60 : undefined}
          onChange={val => {
            if (onChange) {
              onChange(!isNil(val) ? parseFloat(val.toString()) / 60 : '')
            }
          }}
          min={0}
          ref={ref}
          {...props}
        />
        <span>&nbsp;minutes</span>
      </>
    )
  },
)
