import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { DATE_FORMATS } from '@thesisedu/web'
import { Table } from 'antd'
import Case from 'case'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { UserFragment, UsersDocument, UsersQuery, UsersQueryVariables } from '../schema'

export const UsersTable: React.FC<React.PropsWithChildren<unknown>> = () => (
  <InfiniteQuery<UserFragment, UsersQuery, UsersQueryVariables>
    document={UsersDocument}
    getResult={d => d?.users}
    setResult={(previous, result) => ({
      ...previous,
      users: {
        ...previous.users,
        ...result,
      },
    })}
    children={({ data }) => (
      <Table<UserFragment>
        dataSource={data?.users.edges.map(edge => edge.node) || []}
        rowKey={'id'}
        pagination={false}
      >
        <Table.Column<UserFragment>
          title={'Username'}
          dataIndex={'username'}
          render={(name, record) => <Link to={record.id}>{name}</Link>}
        />
        <Table.Column<UserFragment>
          title={'Name'}
          dataIndex={'name'}
          render={(name, record) => <Link to={record.id}>{name}</Link>}
        />
        <Table.Column
          title={'Email'}
          dataIndex={'email'}
          render={email => <a href={`mailto:${email}`}>{email}</a>}
        />
        <Table.Column title={'Group'} dataIndex={'group'} render={Case.title} />
        <Table.Column title={'Status'} dataIndex={'status'} render={Case.title} />
        <Table.Column
          title={'Last Activity'}
          dataIndex={'lastActivity'}
          render={last => (last ? moment(last).format(DATE_FORMATS.FULL) : 'Never')}
        />
        <Table.Column<UserFragment>
          dataIndex={'id'}
          onCell={() => ({
            style: { textAlign: 'right' },
          })}
          render={(_, record) => (
            <>
              <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
                Edit &rarr;
              </Link>
            </>
          )}
        />
      </Table>
    )}
  />
)
