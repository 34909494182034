import {
  AttachmentField,
  AttachmentFormField,
  AttachmentFieldProps,
  AttachmentFormFieldProps,
} from '@thesisedu/feature-attachments-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

import {
  LessonAttachmentDocument,
  LessonAttachmentQuery,
  LessonAttachmentQueryVariables,
} from '../schema'

export type LessonAttachmentFieldProps = Pick<
  AttachmentFieldProps<LessonAttachmentQuery, LessonAttachmentQueryVariables>,
  'fragment' | 'readOnly'
> & { id: string }
export const LessonAttachmentField: React.FC<
  React.PropsWithChildren<LessonAttachmentFieldProps>
> = ({ id, ...props }) => (
  <AttachmentField<LessonAttachmentQuery, LessonAttachmentQueryVariables>
    readWriteQueryDocument={LessonAttachmentDocument}
    getAttachment={result => (result.node?.__typename === 'Lesson' ? result.node.attachment : null)}
    variables={{ id }}
    uploadMessage={'Click or drag file here to upload attachment'}
    maxFileSizeBytes={100000000}
    {...props}
  />
)

export const LessonAttachmentFormField: React.FC<
  React.PropsWithChildren<Omit<AttachmentFormFieldProps, 'type' | 'camelField'>>
> = props => (
  <AttachmentFormField
    type={'Lesson'}
    camelField={'attachment'}
    uploadMessage={
      isNative ? 'Upload an attachment' : 'Click or drag file here to upload attachment'
    }
    maxFileSizeBytes={100000000}
    {...props}
  />
)
