import { updateMutation } from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-react'
import { useOAuthPopup } from '@thesisedu/web'
import React from 'react'

import { AuthMSALReactFeature } from '../AuthMSALReactFeature'
import { debug } from '../log'
import { useAssociateMsalMutation, useLoginWithMsalMutation, UserFragmentDoc } from '../schema'
import { UseMSALAssociateProps, UseMSALAuthProps, UseMSALLoginProps } from '../types'

export function useMSALLogin(props: UseMSALLoginProps) {
  const [loginWithMsal, { loading: loggingIn }] = useLoginWithMsalMutation({
    onError: props.onError,
    onCompleted: data => {
      debug('sign in complete, calling onSuccess')
      props.onSuccess(data.loginWithMSAL)
    },
  })

  return {
    ...useMSALAuth({
      ...props,
      onSuccess: (code, redirectUri, scopes) => {
        loginWithMsal({
          variables: {
            input: { authCode: { code, redirectUri, scopes } },
          },
        })
      },
    }),
    loggingIn,
  }
}

export function useMSALAssociate(props: UseMSALAssociateProps) {
  const [associate, { loading: associating }] = useAssociateMsalMutation({
    onError: props.onError,
    update: updateMutation({
      fragment: UserFragmentDoc,
      fragmentName: 'User',
      path: 'assocateMSAL.user',
    }),
    onCompleted: () => {
      debug('associate complete, calling onSuccess')
      props.onSuccess()
    },
  })

  return {
    ...useMSALAuth({
      ...props,
      onSuccess: (code, redirectUri, scopes) => {
        associate({
          variables: {
            input: { authCode: { code, redirectUri, scopes } },
          },
        })
      },
      onCancel: () => props.onError(null),
    }),
    associating,
  }
}

export interface MSALSignInOpts {
  scopes?: string[]
}

export function useMSALAuth({ scopes, onCancel, onSuccess }: UseMSALAuthProps) {
  const feature = useFeature(AuthMSALReactFeature)
  const redirectUri = `${window.location.origin}/auth/msal-redirect`
  const scopesRef = React.useRef(scopes)
  const { onContainerClick } = useOAuthPopup({
    redirectUri,
    title: 'Sign in with Microsoft',
    width: 450,
    height: 600,
    authorizeUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
    clientId: feature.options.clientId,
    scopes,
    onClose: () => {
      if (onCancel) {
        onCancel()
      }
    },
    onCode: code => {
      onSuccess(code, redirectUri, scopesRef.current)
    },
  })
  return {
    signIn: (opts: MSALSignInOpts) => {
      if (opts.scopes) {
        scopesRef.current = opts.scopes
      }
      onContainerClick(
        opts.scopes
          ? {
              scopes: opts.scopes,
            }
          : undefined,
      )
    },
  }
}
