import { useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { Dropdown } from '@thesisedu/ui'
import { Lock as LockIcon, NoLock } from '@thesisedu/ui/icons'
import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { useOutlineSegmentContext } from '../../outline/OutlineSegmentContext'
import { SegmentType } from '../../schema'
import {
  ClassFragmentWithPermissions,
  SegmentActionComponentProps,
  SegmentActionResource,
} from '../../types'

function Lock({ segment }: SegmentActionComponentProps) {
  const { lockedSegmentIds, setSegmentLock } = useOutlineSegmentContext()
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const canLockSegments = cls?.canLockSegments
  const locked = !!lockedSegmentIds.includes(segment.id)
  const action = locked ? 'Unlock' : 'Lock'
  const icon = locked ? <NoLock /> : <LockIcon />
  return (
    <Dropdown.Item
      icon={icon}
      disabled={!canLockSegments}
      onClick={() => {
        setSegmentLock(segment.id, !locked)
      }}
      children={action}
    />
  )
}

export const LockAction: SegmentActionResource = {
  identifier: 'lock',
  type: 'SegmentAction',
  component: Lock,
  group: 'Enable & Disable',
  weight: 103,
  filter: segment => {
    return NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}
