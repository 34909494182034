const icons = require('iconoir-react')
const React = require('react')

const newIcons = {}

for (const key of Object.keys(icons)) {
  newIcons[key] = props => {
    return React.createElement(icons[key], {
      width: '1em',
      height: '1em',
      className: ['anticon', props.className].filter(Boolean).join(' '),
      ...props,
    })
  }
}

module.exports = newIcons
