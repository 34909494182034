import { Dropdown } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import { LexicalEditor } from 'lexical'
import React from 'react'

import { useElementsMenuDropdownItems } from '../ElementsMenu/ElementsDropdownSubMenu'
import { TextFormatToolbarButton } from '../TextFormatToolbar/TextFormatToolbarButton'

export interface AddButtonProps {
  editor: LexicalEditor
}
export function AddButton({ editor }: AddButtonProps) {
  const dropdownItems = useElementsMenuDropdownItems(editor)
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <TextFormatToolbarButton
          icon={<Plus />}
          variant={'primary'}
          label={'Add Element'}
          ariaLabel={'Click to add element'}
        />
      </Dropdown.ManualTrigger>
      <Dropdown.Menu>{dropdownItems}</Dropdown.Menu>
    </Dropdown.Container>
  )
}
