import { toGlobalId } from '@thesisedu/feature-utils'

import { CourseEditorWriteContextValue } from '../contexts/CourseEditorContext'
import { DeleteSegment } from '../types'

export const getOnDeleteSegment =
  (
    courseVersionId: string,
    deleteSegment: DeleteSegment,
  ): CourseEditorWriteContextValue['onDeleteSegment'] =>
  async (segmentId: string) => {
    await deleteSegment({
      variables: {
        input: {
          id: toGlobalId('Segment', segmentId),
          updateCourseVersionId: courseVersionId,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteSegment: {
          __typename: 'DeleteSegmentPayload',
          segmentId: toGlobalId('Segment', segmentId),
        },
      },
    })
  }
