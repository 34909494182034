import {
  ClassStudentPair,
  useCachedStudentUserCallback,
  useClasses,
  useStudentSelectorContext,
} from '@thesisedu/feature-classes-react'
import { ClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import {
  ReportDimensionOptionsLabelProps,
  ReportDimensionOptionsProps,
  ReportDimensionResource,
} from '@thesisedu/feature-reports-react'
import { Format } from '@thesisedu/feature-utils'
import { oxfordComma } from '@thesisedu/react'
import { Group } from '@thesisedu/react/icons'
import React from 'react'

import { studentGraphOptions } from './OneStudent'
import { StudentHeader, useGetStudentHeader } from './components/StudentHeader'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import {
  StudentsReportDimensionInput,
  StudentsReportDimensionResultFragment,
  StudentsReportDimensionResultFragmentDoc,
} from '../../schema'

function getFullClasses(pairs: ClassStudentPair[], classes: ClassFragment[]) {
  return classes.filter(cls => {
    return (
      cls.students &&
      cls.students.edges.length &&
      cls.students.edges.every(edge => {
        return pairs.some(pair => pair.studentId === edge.node.id && pair.classId === cls.id)
      })
    )
  })
}

export interface StudentsOptionsWrapperProps
  extends ReportDimensionOptionsProps<StudentsReportDimensionInput, StudentsOptionsContext> {
  single?: boolean
}
export function StudentsOptionsWrapper({
  children,
  onConfirm,
  single,
  value,
  isInDropdown,
  ...rest
}: StudentsOptionsWrapperProps) {
  const { classes } = useClasses()
  const getStudent = useCachedStudentUserCallback()
  const { selectStudents } = useStudentSelectorContext(true)
  return React.cloneElement(children, {
    ...rest,
    onClick: () => {
      selectStudents({
        initialValue: value?.studentPairs || [],
        saveText: 'Select',
        single,
        async callback(result) {
          if (result.students.length) {
            const firstStudent = getStudent(
              result.students[0].studentId,
              result.students[0].classId,
            )
            const fullClassNames = getFullClasses(result.students, classes as ClassFragment[]).map(
              cls => cls.name,
            )
            onConfirm(
              {
                studentPairs: result.students,
              },
              {
                fullClassNames,
                firstName: firstStudent?.user.name || firstStudent?.user.username || 'Student',
              },
              result.students.length > 1 ? 'MultipleStudents' : 'OneStudent',
            )
          }
        },
      })
    },
  })
}

export interface StudentsOptionsContext {
  /** The full name of the first student. */
  firstName: string
  fullClassNames?: string[]
}
export interface StudentsOptionsLabelProps
  extends ReportDimensionOptionsLabelProps<StudentsReportDimensionInput, StudentsOptionsContext> {
  single?: boolean
}
export function StudentsOptionsLabel({ value, context, single }: StudentsOptionsLabelProps) {
  const getStudent = useCachedStudentUserCallback()
  let label
  if (value?.studentPairs?.length === 1) {
    const pair = value.studentPairs[0]
    const student = getStudent(pair.studentId, pair.classId)
    label = student?.user.name || student?.user.username || context?.firstName || '1 student'
  } else if (context?.fullClassNames?.length) {
    label =
      context.fullClassNames.length > 1
        ? Format.plural('class', context.fullClassNames.length)
        : context.fullClassNames[0]
  } else if (value?.studentPairs?.length) {
    label = Format.plural('student', value.studentPairs.length)
  } else if (single) {
    label = 'Select a Student'
  } else {
    label = 'Select Students'
  }
  return <>{label}</>
}

export default function addDimension(feature: CourseReportsReactFeature) {
  feature.resources.addResource<
    ReportDimensionResource<
      StudentsReportDimensionInput,
      StudentsReportDimensionResultFragment,
      StudentsOptionsContext
    >
  >({
    type: 'ReportDimension',
    identifier: 'MultipleStudents',
    inputKey: 'students',
    title: 'Multiple Students',
    singular: 'Student',
    description: 'I want to compare multiple students with one another.',
    icon: <Group />,
    hasMultipleValues: true,
    supportedIndices: [0],
    graph: studentGraphOptions,
    shortLabel: ({ studentPairs }, { fullClassNames }) => {
      if (fullClassNames?.length) {
        return `students in my ${oxfordComma(fullClassNames)} ${Format.plural(
          'class',
          fullClassNames.length,
        )}`
      } else {
        return `${studentPairs.length} of my ${Format.plural('student', studentPairs.length)}`
      }
    },
    titleLabel: ({ studentPairs }, { fullClassNames }) => {
      if (fullClassNames?.length) {
        return `Students in ${oxfordComma(fullClassNames)}`
      } else {
        return `${studentPairs.length} ${Format.plural('Student', studentPairs.length)}`
      }
    },
    fragment: StudentsReportDimensionResultFragmentDoc,
    renderTableHeader: StudentHeader,
    useGetExportHeader: useGetStudentHeader,
    renderOptionsWrapper: props => <StudentsOptionsWrapper {...props} />,
    renderOptionsLabel: props => <StudentsOptionsLabel {...props} />,
  })
}
