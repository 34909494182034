import { DatabaseSegment, Segment } from '../types'

export const convertChildSegments = (
  segment: Partial<Omit<Segment, 'id'>> & Pick<Segment, 'id'>,
): Partial<Omit<Segment, 'id'>> & Pick<Segment, 'id'> => {
  if (segment.childSegments && !(segment as DatabaseSegment).config?.childSegments) {
    return {
      ...segment,
      rawSegmentId: segment.id, // TODO: this might be dangerous.
      config: {
        ...segment.config,
        childSegments: (segment.childSegments as Segment['childSegments'])!.map(childSegment => ({
          // Default to the referenceSegment first, and then fallback to the segment ID.
          id: childSegment.referenceSegment ? childSegment.referenceSegment.id : childSegment.id,
          weight: childSegment.weight || 0,
        })),
      },
    }
  } else {
    return segment as DatabaseSegment
  }
}
