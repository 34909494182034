import { addTeacherPermission } from '@thesisedu/feature-classes-react'
import { ReactUse, ReactFeature, DeepLinkResource, isInNode } from '@thesisedu/feature-react'
import { UserGroupPrefixDeepLinkRemapper } from '@thesisedu/feature-users-react'

export interface LessonsOptions {
  disableStudentAssignment?: boolean
  disableLinkGeneration?: boolean
  disableSharing?: boolean
}

export class LessonsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-lessons-react'
  static path = __dirname
  static requires = []
  public options!: LessonsOptions

  public reactResources() {
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'lessons',
      params: [
        {
          identifier: 'id',
          required: false,
        },
      ],
      Component: UserGroupPrefixDeepLinkRemapper,
    })
    addTeacherPermission(
      this,
      'Lessons',
      'View lessons assigned to students, and practices created with these lessons',
      ['VIEW_LESSON', 'LIST_LESSON_PRACTICES', 'VIEW_PRACTICE'],
      [
        {
          identifier: 'Practices-View practices for all students',
          message:
            'Enabling lesson viewing requires teachers be able to view practices. Disable the "View lessons assigned to students" permission first.',
        },
      ],
    )
    addTeacherPermission(this, 'Lessons', 'Assign lessons to students', ['ASSIGN_LESSON'])
    addTeacherPermission(this, 'Lessons', 'Download all lessons assigned to students', [
      'DOWNLOAD_LESSON',
    ])
  }

  public prepareEarly() {
    if (!isInNode()) {
      if (this.root.getFeature('@thesisedu/feature-search-react')) {
        require('./resources/search').default(this)
      }
      if (this.root.getFeature('@thesisedu/feature-video-on-demand-react')) {
        require('./resources/VideoOnDemandViewer').default(this)
      }
    }
  }
}

export const lessonsReactFeature: ReactUse<LessonsOptions> = opts => [LessonsReactFeature, opts]
