import { QuestionTypeResource } from '@thesisedu/feature-assignments-react'
import { ReactFeature } from '@thesisedu/feature-react'
import { SheetMusicDragDropRecordMode } from '@thesisedu/feature-sheet-music-core'
import { AntIconWrapper } from '@thesisedu/web'
import { MusicDoubleNote, Album } from 'iconoir-react'
import React from 'react'

import { SheetMusicDragDropConfig, SheetMusicDragDropAnswer } from './SheetMusicDragDrop/types'
import { SheetMusicPerformanceConfig } from './SheetMusicPerformance/types'
import { hasValue } from '../../editor/useHasValue'

function MusicIcon() {
  return (
    <AntIconWrapper>
      <MusicDoubleNote />
    </AntIconWrapper>
  )
}

function AlbumIcon() {
  return (
    <AntIconWrapper>
      <Album />
    </AntIconWrapper>
  )
}

export function addQuestionTypes(feature: ReactFeature) {
  feature.resources.addResource<QuestionTypeResource<SheetMusicPerformanceConfig>>({
    defaults: {
      name: 'Record yourself playing along with the music.',
      config: {
        viewerProps: {
          abc: '',
        },
      },
    },
    icon: MusicIcon,
    label: 'Sheet Music Performance',
    group: 'Performance',
    type: 'QuestionType',
    identifier: 'SheetMusicPerformance',
    renderAnswerView: require('./SheetMusicPerformance/SheetMusicPerformanceAnswer')
      .SheetMusicPerformanceAnswer,
    renderContent: require('./SheetMusicPerformance/SheetMusicPerformanceContent')
      .SheetMusicPerformanceContent,
    renderEdit: require('./SheetMusicPerformance/SheetMusicPerformanceEdit')
      .SheetMusicPerformanceEdit,
  })
  feature.resources.addResource<QuestionTypeResource<SheetMusicDragDropConfig>>({
    defaults: {
      name: '',
      config: {},
    },
    icon: AlbumIcon,
    label: 'Compose Tool Question',
    group: 'Drag and Drop',
    type: 'QuestionType',
    identifier: 'SheetMusicDragDrop',
    renderAnswerView: require('./SheetMusicDragDrop/SheetMusicDragDropAnswer')
      .SheetMusicDragDropAnswer,
    renderContent: require('./SheetMusicDragDrop/SheetMusicDragDropContent')
      .SheetMusicDragDropContent,
    usesRealTimeConfirmation: true,
    renderEdit: require('./SheetMusicDragDrop/SheetMusicDragDropEdit').SheetMusicDragDropEdit,
    isQuestionComplete: (question, answer: SheetMusicDragDropAnswer) => {
      try {
        const requiresVideo = question.config?.recordMode === SheetMusicDragDropRecordMode.Require
        const abc = typeof answer === 'string' ? answer : answer.abc
        return hasValue(abc) && (!requiresVideo || (typeof answer === 'object' && !!answer.mediaId))
      } catch {
        return false
      }
    },
    isAutoGraded: question => {
      return !!question.config?.answer
    },
  })
}
