import { ArrowRightOutlined } from '@ant-design/icons'
import { Body, styled, Image, ImageFallbackSize } from '@thesisedu/web'
import classnames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

export interface ExploreListItemProps {
  title: string
  image?: {
    url: string
    alt: string
  }
  path: string
  rightText?: string
  disabled?: boolean
}
export function ExploreListItem({ rightText, disabled, title, image, path }: ExploreListItemProps) {
  return (
    <Container>
      <InnerContainer
        className={classnames({
          'with-image': !!image,
          disabled,
        })}
        to={path}
      >
        {image ? (
          <Image
            width={40}
            fallbackSize={ImageFallbackSize.Small}
            src={image.url}
            alt={image.alt}
          />
        ) : null}
        <Body color={disabled ? '@text-color-secondary' : '@text-color'} className={'title'}>
          {title}
        </Body>
        <Body
          color={disabled ? '@text-color-secondary' : '@text-color'}
          style={{ whiteSpace: 'nowrap' }}
        >
          {rightText || <ArrowRightOutlined />}
        </Body>
      </InnerContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: ${props => props.theme['@size-xs']} 0;
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
`
const InnerContainer = styled(Link)`
  padding: 0 ${props => props.theme['@size-xs']};
  min-height: 40px;
  display: flex;
  transition: background 0.1s linear;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  align-items: center;
  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
  &.with-image {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .title {
    padding: ${props => props.theme['@size-xxs']} 0;
  }
  .ant-image {
    border-radius: ${props => props.theme['@border-radius-base']};
    overflow: hidden;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
  > :last-child {
    margin-left: auto;
  }
  &:hover {
    background: ${props => props.theme['@gray-1']};
  }
`
