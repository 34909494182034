import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Form, Modal, Select, VSpaced } from '@thesisedu/ui'
import { Upload } from '@thesisedu/ui/icons'
import React from 'react'

import { FieldsTable } from './FieldsTable'
import { ImportSegmentsInput, ImportSegmentsTagsMode, useImportSegmentsMutation } from '../schema'

type FormValues = Omit<ImportSegmentsInput, 'csvContent'> & {
  csvContent: File
}

export interface ImportModalProps {
  trigger?: React.ReactElement
}
export function ImportModal({ trigger }: ImportModalProps) {
  const form = Form.useForm<FormValues>()
  const [importSegments, { loading }] = useImportSegmentsMutation({
    onError: onMutationError('There was an error importing your content.'),
  })
  return (
    <Form.Modal
      trigger={trigger}
      form={form}
      onFinish={async values => {
        const csvContent = await values.csvContent.text()
        if (csvContent) {
          await importSegments({
            variables: {
              input: {
                ...values,
                csvContent,
              },
            },
          })
        }
      }}
      title={'Import Content'}
    >
      <VSpaced space={'m'}>
        <Form.FileField
          name={'csvContent'}
          required
          label={'Import Data'}
          description={<FieldsTable />}
          accept={'text/csv'}
        />
        <Form.Select
          name={'tagsMode'}
          label={'Tags Import Mode'}
          required
          initialValue={ImportSegmentsTagsMode.Append}
        >
          <Select.Item value={ImportSegmentsTagsMode.Append} children={'Append'} />
          <Select.Item value={ImportSegmentsTagsMode.Ignore} children={'Ignore'} />
          <Select.Item value={ImportSegmentsTagsMode.Replace} children={'Replace'} />
        </Form.Select>
        <VSpaced space={'s'}>
          <Form.Checkbox
            name={'dontCreateTags'}
            label={"Don't create tags when importing"}
            description={
              "If this is checked, new tags are not created. Content is only associated with existing tags. Use this if you know your tags already exist and don't want to create duplicate tags accidentally."
            }
          />
          <Form.Checkbox
            name={'updateName'}
            label={'Update content name when importing'}
            description={'If this is checked, the ID column is required.'}
            initialValue
          />
          <Form.Checkbox
            name={'updateLabel'}
            label={'Update content label when importing'}
            description={'If this is checked, the ID column is required.'}
            initialValue
          />
        </VSpaced>
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Start Import'} icon={<Upload />} loading={loading} />
      </Modal.Footer>
    </Form.Modal>
  )
}
