import { Text, LoadingIndicator, Dropdown } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { ListItem } from './SelectButtonContent'

export interface AddListItemProps {
  onAddFromSearch: (filter: string) => Promise<void>
  filter: string
  maxSelection?: number
}
export function AddListItem({ onAddFromSearch, filter }: AddListItemProps) {
  const [loading, setLoading] = React.useState(false)
  const icon = loading ? <LoadingIndicator /> : <Plus />
  return (
    <ListItem
      onClick={async () => {
        setLoading(true)
        try {
          await onAddFromSearch(filter)
        } finally {
          setLoading(false)
        }
      }}
    >
      <div style={{ lineHeight: 1.5 }}>
        <Text>
          Add <strong>{filter}</strong>
        </Text>
      </div>
      <Dropdown.Item.Right>{icon}</Dropdown.Item.Right>
    </ListItem>
  )
}
