import { StudentOutline } from '@thesisedu/feature-courses-react'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

export function Outline() {
  return (
    <>
      <GlobalStyles />
      <StudentOutline hideTitle />
    </>
  )
}

const GlobalStyles = createGlobalStyle`
  .main-content {
    overflow: visible !important; // This is to fix position: sticky inside the outline.
    padding-top: calc(${props => (props.theme as any)['@size-l']} - ${props =>
      (props.theme as any)['@size-s']});
    > :first-child {
      margin-top: 0;
    }
    .filter-container {
      top: -${props => (props.theme as any)['@size-l']};
    }
    .outline-content-left {
      top: 75px;
      height: calc(100vh - 130px - 50px - ${props => (props.theme as any)['@size-l']});
    }
  }
`
