import { SelectButtonContent } from '@thesisedu/feature-apollo-react'
import React from 'react'

import {
  BasicClassGroupPresetFragment,
  ClassGroupPresetsDocument,
  ClassGroupPresetsQuery,
  ClassGroupPresetsQueryVariables,
} from '../../schema'

export interface PresetSelectProps {
  onSelected: (preset: BasicClassGroupPresetFragment) => void
  classId: string
}
export function PresetSelect({ onSelected, classId }: PresetSelectProps) {
  return (
    <SelectButtonContent<
      BasicClassGroupPresetFragment,
      ClassGroupPresetsQuery,
      ClassGroupPresetsQueryVariables
    >
      filterVariableName={'name'}
      getItemName={r => r.name}
      document={ClassGroupPresetsDocument}
      resultPath={'node.groupPresets'}
      variables={{
        classId,
      }}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      maxSelection={1}
      onChange={(selectedItems, fragments) => {
        if (selectedItems[0] && fragments[0]) {
          onSelected(fragments[0])
        }
      }}
    />
  )
}
