import {
  RubricEditorActionProps,
  RubricEditorActionResource,
} from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { useSegmentCalculationSegmentIdContext } from '@thesisedu/feature-course-assignments-react'
import { Segment, useSegment } from '@thesisedu/feature-courses-react'
import { flattenTagTree } from '@thesisedu/feature-tags-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Button, Form, Modal } from '@thesisedu/ui'
import { ClipboardCheck, Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { StandardSetsReactFeature } from '../../StandardSetsReactFeature'
import {
  AssignmentRubricCategoryInput,
  AssignmentRubricPresetInput,
  useSegmentStandardsQuery,
} from '../../schema'
import {
  StandardSelect,
  StandardSelectProps,
  StandardSelectValue,
} from '../../select/StandardSelect'

function useImportSuggestions(segment?: Segment) {
  const selectedClassId = useSelectedClassId(false)
  const { data } = useSegmentStandardsQuery({
    variables: {
      classId: selectedClassId ?? '',
      segmentId: segment ? toGlobalId('Segment', segment.id) : '',
    },
    skip: !selectedClassId || !segment?.id,
  })
  const suggestions: StandardSelectProps['suggestions'] = []
  const standards = data?.node?.__typename === 'Segment' ? data.node.standards : []
  for (const standard of standards) {
    const leaves = flattenTagTree(standard.tree).filter(tag => !tag.children?.length)
    for (const leaf of leaves) {
      suggestions.push({
        id: leaf.tag.id,
        name: leaf.tag.name,
        standardSet: {
          id: standard.standardSet.id,
          name: standard.standardSet.name,
        },
      })
    }
  }
  return suggestions
}

const DEFAULT_PRESETS: AssignmentRubricPresetInput[] = []
function ImportStandardRubricEditorAction({ value, onChange }: RubricEditorActionProps) {
  const form = Form.useForm<{ standards: StandardSelectValue[] }>()
  const { segmentId } = useSegmentCalculationSegmentIdContext(false) ?? {}
  const segment = useSegment(segmentId)
  const importSuggestions = useImportSuggestions(segment)
  return (
    <Form.Modal
      title={'Import Standard'}
      form={form}
      data-testid={'ImportStandardModal'}
      onFinish={values => {
        const presets = value?.categories[0]?.presets ?? DEFAULT_PRESETS
        const totalPoints = value?.categories[0]?.totalPoints ?? 1
        const maxWeight = Math.max(...(value?.categories ?? []).map(cat => cat.weight ?? 0))
        onChange?.({
          ...value,
          categories: [
            ...(value?.categories ?? []),
            ...values.standards.map<AssignmentRubricCategoryInput>((standard, index) => {
              return {
                id: standard.id,
                totalPoints,
                name: standard.name,
                presets,
                weight: maxWeight + index,
              }
            }),
          ],
        })
      }}
      trigger={
        <Button
          data-testid={'ImportStandards'}
          icon={<ClipboardCheck />}
          children={'Import Standards'}
        />
      }
    >
      <Form.Field name={'standards'} label={'Standards'} required>
        <StandardSelect suggestions={importSuggestions} />
      </Form.Field>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Import Standards'} icon={<Plus />} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export default function (feature: StandardSetsReactFeature) {
  feature.resources.addResource<RubricEditorActionResource>({
    type: 'RubricEditorAction',
    identifier: 'ImportStandardRubricEditorAction',
    component: ImportStandardRubricEditorAction,
  })
}
