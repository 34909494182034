import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'
import { LexicalEditor } from 'lexical'

import { WidgetNode } from './WidgetNode'
import { useNodeState } from '../../../lexical/ui/SettingsDropdown/useNodeState'

export function useWidgetConfig<Config extends BaseWidgetConfig, Field extends keyof Config>(
  editor: LexicalEditor,
  node: WidgetNode<any, Config>,
  field: Field,
) {
  return useNodeState(
    editor,
    node,
    node => {
      try {
        return node.getConfig()[field]
      } catch {
        // Sometimes we get errors in between updates about referencing old nodes. This helps
        // to protect against that.
        return undefined
      }
    },
    (node, value) => {
      node.patchConfig({
        [field]: value,
      } as any)
    },
  )
}
