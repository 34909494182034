import React from 'react'

import { SimpleList } from './SimpleList'
// @ts-ignore
import { ReactComponent as A } from '../../../../assets/sketch/sheet-music/note-a.svg'
// @ts-ignore
import { ReactComponent as B } from '../../../../assets/sketch/sheet-music/note-b.svg'
// @ts-ignore
import { ReactComponent as C } from '../../../../assets/sketch/sheet-music/note-c.svg'
// @ts-ignore
import { ReactComponent as D } from '../../../../assets/sketch/sheet-music/note-d.svg'
// @ts-ignore
import { ReactComponent as E } from '../../../../assets/sketch/sheet-music/note-e.svg'
// @ts-ignore
import { ReactComponent as F } from '../../../../assets/sketch/sheet-music/note-f.svg'
// @ts-ignore
import { ReactComponent as G } from '../../../../assets/sketch/sheet-music/note-g.svg'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function SimpleNotesContent(props: ModeContentsProps) {
  return <SimpleList items={[C, D, E, F, G, A, B, C]} {...props} />
}

export const SimpleNotes: Mode = {
  id: 'SimpleNotes',
  name: 'Notes',
  icon: require('../../../../assets/sketch/sheet-music/note-c.svg').default,
  large: false,
  types: [KeyboardType.Note8],
  contents: SimpleNotesContent,
}
