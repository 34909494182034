import { SimpleSegmentPresetResource } from '@thesisedu/feature-courses-react'

import { AssignmentPreset } from './AssignmentPreset'
import { TestQuizPreset } from './TestQuizPreset'
import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'

const PRESETS: SimpleSegmentPresetResource[] = [AssignmentPreset, TestQuizPreset]

export function addSegmentPresets(feature: CourseAssignmentsReactFeature) {
  for (const preset of PRESETS) {
    feature.resources.addResource<SimpleSegmentPresetResource>(preset)
  }
}
