import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React from 'react'

import { SheetMusicCompositionsList } from './SheetMusicCompositionsList'
import { useNameFilter } from './useNameFilter'
import {
  SheetMusicCompositionFragment,
  ClassSheetMusicCompositionsQuery,
  ClassSheetMusicCompositionsQueryVariables,
  ClassSheetMusicCompositionsDocument,
} from '../schema'

export interface ClassSheetMusicCompositionsListProps {
  onClick: (id: string) => void
}
export function ClassSheetMusicCompositionsList({ onClick }: ClassSheetMusicCompositionsListProps) {
  const [name, filter] = useNameFilter()
  const classId = useSelectedClassId(true)
  return (
    <InfiniteQuery<
      SheetMusicCompositionFragment,
      ClassSheetMusicCompositionsQuery,
      ClassSheetMusicCompositionsQueryVariables
    >
      document={ClassSheetMusicCompositionsDocument}
      resultPath={'node.sheetMusicCompositions'}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      }}
      variables={{ classId }}
      children={({ data, loading, refetch }) => {
        const items =
          data?.node?.__typename === 'Class'
            ? data.node.sheetMusicCompositions.edges.map(edge => edge.node)
            : []
        return (
          <SheetMusicCompositionsList
            filters={filter}
            refetch={after => refetch({ classId, name, after })}
            onClick={onClick}
            comps={items}
            loading={loading}
          />
        )
      }}
    />
  )
}
