import { Comment, CommentDisplayProps } from '@thesisedu/feature-comments-react'
import { styled } from '@thesisedu/react'
import React from 'react'

import { MediaCommentMetadata } from '../types'
import { MediaView } from '../view/MediaView'

const StyledComment = styled(Comment)`
  padding: 0;
  overflow: hidden;
  background: transparent;
  width: 100%;
  position: relative;
  max-width: 500px;
`

export function DisplayMediaComment({
  isOwn,
  isSelected,
  comment,
}: CommentDisplayProps<MediaCommentMetadata>) {
  return (
    <StyledComment isOwn={isOwn} isSelected={isSelected}>
      <MediaView noContextInheritance mediaId={comment.metadata.mediaId} />
    </StyledComment>
  )
}
