import { Empty, Form, useFlag } from '@thesisedu/react'
import { Block } from '@thesisedu/ui'
import React from 'react'

import { AssignmentQuestions } from './AssignmentQuestions'
import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { StubSubmissionContextProvider } from '../contexts/SubmissionContext'

export function getRealTimeGradingFlag(templateId: string) {
  return `interactive-review:${templateId}`
}

export function QuestionAssignmentRealTimeGradingSampleViewer() {
  const { configuration, classId, assignment, templateId } = useAssignmentViewerContext(true)
  const [formValue, setFormValue, { loading }] = useFlag<Record<string, any>>(
    getRealTimeGradingFlag(templateId),
    {},
  )
  const form = Form.useForm<Record<string, any>>()
  React.useEffect(() => {
    form.reset(
      configuration.questions?.reduce<Record<string, any>>((acc, question) => {
        acc[question.id] = formValue[question.id] ?? null
        return acc
      }, {}),
    )
  }, [formValue])
  const questions = configuration.questions?.filter(q => !q.disabled)
  if (questions?.length) {
    return (
      <Form
        form={form}
        onValuesChange={values => {
          if (!loading) {
            const hasValues = Object.values(values).some(v => {
              return v !== null && v !== undefined && Object.keys(v)?.length > 0
            })
            if (hasValues) {
              setFormValue(values)
            }
          }
        }}
      >
        <StubSubmissionContextProvider
          value={{
            submissionData: formValue,
            realTimeSubmissionData: formValue,
            assignment: assignment ?? ({} as any), // This might be dangerous...
            classId,
          }}
        >
          <AssignmentQuestions
            editorId={`assignment-${templateId}`}
            questions={questions}
            widgets={configuration.widgets}
            resetQuestion={id => {
              form.resetField(id)
              setFormValue(value => {
                const newValue = { ...value } as any
                delete newValue[id]
                return newValue
              })
            }}
            showGradeFeedback
          />
        </StubSubmissionContextProvider>
      </Form>
    )
  } else {
    return (
      <Block top={'l'} bottom={'l'}>
        <Empty description={'This assignment has no questions.'} />
      </Block>
    )
  }
}
