import { CalendarOutlined } from '@ant-design/icons'
import { ClassTaskSummaryProviderProps } from '@thesisedu/feature-classes-react'
import pluralize from 'pluralize'
import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './UngradedAssignmentsClassTask'
import { useGradingTeacherClassTaskContext } from '../../contexts/GradingTeacherClassTaskContext'

export function AssignmentsDueSoonClassTask({ classId }: ClassTaskSummaryProviderProps) {
  const { classes } = useGradingTeacherClassTaskContext(false) || {}
  const { assignmentsDueSoon, assignmentsDueSoonIsMax } =
    classes?.find(cls => cls.classId === classId) || {}

  if (assignmentsDueSoon?.length) {
    const areIs = assignmentsDueSoon?.length === 1 ? 'is' : 'are'
    return (
      <Link to={`/teacher/classes/${classId}/grades?dueSoon`}>
        <Container color={'@text-color-secondary'} useDiv>
          <CalendarOutlined className={'icon'} />
          {assignmentsDueSoonIsMax
            ? `${assignmentsDueSoon.length}+ assignments`
            : pluralize('assignment', assignmentsDueSoon.length, true)}{' '}
          {areIs} due soon.
        </Container>
      </Link>
    )
  } else {
    return null
  }
}
