import { Empty } from '@thesisedu/react'
import { styled, Block } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncViewItemOpts } from '.'
import { ClassSyncListItem } from './ClassSyncListItem'
import { SyncListItem } from './SyncListItem'
import {
  Container,
  LeftContainer as _LeftContainer,
  RightContainer,
  ViewSyncHistory,
} from './ViewSyncHistory'

export function ViewAllSyncHistory({ classSyncIds, syncIds }: ClassSyncViewItemOpts) {
  const [selectedProps, setSelectedProps] = React.useState<ClassSyncViewItemOpts | undefined>(
    undefined,
  )
  return (
    <Container>
      <LeftContainer>
        {classSyncIds?.map(classSyncId => {
          const props = { classSyncIds: [classSyncId], syncIds: [] }
          return (
            <ClassSyncListItem
              isSelected={JSON.stringify(selectedProps) === JSON.stringify(props)}
              onClick={() => setSelectedProps(props)}
              classSyncId={classSyncId}
              key={classSyncId}
            />
          )
        })}
        {syncIds?.map(syncId => {
          const props = { classSyncIds, syncIds: [syncId] }
          return (
            <SyncListItem
              isSelected={JSON.stringify(selectedProps) === JSON.stringify(props)}
              onClick={() => setSelectedProps(props)}
              syncId={syncId}
              key={syncId}
            />
          )
        })}
      </LeftContainer>
      <RightContainer>
        {selectedProps ? (
          <ViewSyncHistory {...selectedProps} />
        ) : (
          <Block top={'s'} bottom={'s'}>
            <Empty description={'Select a history record from the left to view more detail.'} />
          </Block>
        )}
      </RightContainer>
    </Container>
  )
}

const LeftContainer = styled(_LeftContainer)`
  width: 200px;
  > * {
    height: 50px;
  }
`
