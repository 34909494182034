import { MutationHookOptions, MutationTuple } from '@apollo/client'
import { filterSegments } from '@thesisedu/feature-course-types'
import { fromGlobalId } from '@thesisedu/feature-utils'

import { debug } from '../log'
import {
  CourseVersionFragment,
  CourseVersionFragmentDoc,
  DeleteSegmentMutation,
  DeleteSegmentMutationVariables,
  useDeleteSegmentMutation as useMutation,
} from '../schema'

export const useDeleteSegmentMutation = (
  opts: MutationHookOptions<DeleteSegmentMutation, DeleteSegmentMutationVariables>,
  courseVersionId: string,
): MutationTuple<any, any> =>
  useMutation({
    ...opts,
    update: (proxy, mutationResult) => {
      const deleteSegment = mutationResult.data?.deleteSegment
      if (deleteSegment) {
        const fragment = proxy.readFragment<CourseVersionFragment>({
          fragment: CourseVersionFragmentDoc,
          fragmentName: 'CourseVersion',
          id: `CourseVersion:${courseVersionId}`,
        })
        if (fragment) {
          const segmentId = fromGlobalId(deleteSegment.segmentId, true).id
          debug('updating deleted segment %s', segmentId)
          const filteredSegments = filterSegments(fragment.configuration.segments, segment => {
            // Regarding references, if we deleted the reference segment, we need to filter
            // based on the segment ID (classic) and the reference segment ID (references).
            // This way, we'll remove segments that were referenced.
            return segment.referenceSegment
              ? segment.referenceSegment.id !== segmentId && segment.id !== segmentId
              : segment.id !== segmentId
          })
          proxy.writeFragment<CourseVersionFragment>({
            fragment: CourseVersionFragmentDoc,
            fragmentName: 'CourseVersion',
            id: `CourseVersion:${courseVersionId}`,
            data: {
              ...fragment,
              configuration: {
                ...fragment.configuration,
                segments: filteredSegments,
              },
            },
          })
        }
      }
    },
  })
