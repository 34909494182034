import callout from './callout'
import content from './content'
import definition from './definition'
import discussionGuideItem from './discussionGuideItem'
import fallback from './fallback'
import float from './float'
import group from './group'
import image from './image'
import link from './link'
import space from './space'
import threeQuarterLeft from './threeQuarterLeft'
import threeQuarterRight from './threeQuarterRight'
import twoColumn from './twoColumn'
import { WidgetConverter } from './types'
import * as Legacy from '..'

export const CONVERTERS: WidgetConverter<Legacy.Widget>[] = [
  content,
  fallback,
  image,
  link,
  twoColumn,
  threeQuarterLeft,
  threeQuarterRight,
  group,
  space,
  definition,
  discussionGuideItem,
  float,
  callout,
].sort((a, b) => a.weight - b.weight)
