import { Button, Button$, Tooltip } from '@thesisedu/ui'
import { HelpCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { performResetCallbacks } from './DismissablePopover'

export function useResetDismissablePopovers() {
  return React.useCallback(() => {
    performResetCallbacks()
  }, [])
}

export function ResetDismissablePopoversButton(props: Button$.ButtonProps) {
  const reset = useResetDismissablePopovers()
  return (
    <Tooltip title={'Show Tutorial Popups'}>
      <Button icon={<HelpCircle />} variant={'ghost'} size={'small'} {...props} onPress={reset} />
    </Tooltip>
  )
}
