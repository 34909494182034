import { CodeOutlined, TrophyOutlined } from '@ant-design/icons'
import { InteractionEventConfig, BadgeEventConfig } from '@thesisedu/feature-badge-types'
import React from 'react'

import { BadgesReactFeature } from '../BadgesReactFeature'
import { BadgeEventTypeResource } from '../types'

export function addBadgeEvents(feature: BadgesReactFeature) {
  const { BadgeBadgeEventConfig } = require('../events/BadgeBadgeEventConfig')
  const { InteractionBadgeEventConfig } = require('../events/InteractionBadgeEventConfig')
  feature.resources.addResource<BadgeEventTypeResource<InteractionEventConfig>>({
    type: 'BadgeEventType',
    identifier: 'interaction',
    label: 'Interactions',
    description:
      'A more advanced option. Configure badges to be awarded whenever any arbitrary interaction is completed a certain number of times.',
    icon: <CodeOutlined />,
    configComponent: InteractionBadgeEventConfig,
    displayWeight: 100,
  })
  feature.resources.addResource<BadgeEventTypeResource<BadgeEventConfig>>({
    type: 'BadgeEventType',
    identifier: 'badge',
    label: 'Badge(s)',
    description: 'Badges are awarded upon earning a specific set of other badges.',
    icon: <TrophyOutlined />,
    configComponent: BadgeBadgeEventConfig,
    displayWeight: 1,
  })
}
