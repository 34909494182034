import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  ApproveTrialRequestMutation,
  TrialRequestFragment,
  TrialRequestFragmentDoc,
  useApproveTrialRequestMutation as useMutation,
} from '../schema'

export const useApproveTrialRequestMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error ignoring that trial request.'),
    update: updateMutation<ApproveTrialRequestMutation, TrialRequestFragment>({
      fragment: TrialRequestFragmentDoc,
      fragmentName: 'TrialRequest',
      path: 'approveTrialRequest.trialRequest',
    }),
    ...opts,
  })
