import { PlusOutlined } from '@ant-design/icons'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Modal } from '@thesisedu/web'
import { Button, Form, message } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React, { useState } from 'react'

import { CourseForm } from './CourseForm'
import { useCreateCourseMutation } from '../schema'

export interface CreateCourseModalProps {
  visible?: boolean
  onCancel: () => void
}
export function CreateCourseModal({ visible, onCancel }: CreateCourseModalProps) {
  const [form] = Form.useForm()
  const [createCourse, { loading }] = useCreateCourseMutation({
    onError: onMutationError('There was an error creating that course.'),
    refetchQueries: ['listCourses'],
    onCompleted: () => {
      form.resetFields()
      message.success('Course created successfully.')
      onCancel()
    },
  })
  return (
    <Modal
      title={'Create Course'}
      visible={visible}
      onCancel={onCancel}
      width={'90vw'}
      style={{
        maxWidth: '1000px',
      }}
      footer={[
        <Button key={'cancel'} onClick={onCancel}>
          Cancel
        </Button>,
        <Button key={'submit'} loading={loading} onClick={() => form.submit()} type={'primary'}>
          Create Course
        </Button>,
      ]}
    >
      <CourseForm
        form={form}
        onFinish={values => {
          return createCourse({
            variables: {
              input: values,
            },
          })
        }}
      />
    </Modal>
  )
}

export function CreateCourseButton(props: Partial<ButtonProps>) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <CreateCourseModal onCancel={() => setVisible(false)} visible={visible} />
      <Button icon={<PlusOutlined />} type={'primary'} {...props} onClick={() => setVisible(true)}>
        Create Course
      </Button>
    </>
  )
}
