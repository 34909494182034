import { EditorValue } from '@thesisedu/feature-widgets-core'

import { Segment, SegmentConfig } from '../courseConfiguration'

export interface UploadImportConfig {
  filepath: string
  /** This is used for delivering the content locally. */
  base64: string
  /** This is used to find an image if it already exists, or if we need to upload a new version. */
  id: string
  label?: string
}
export function isImportConfig(value: any): value is UploadImportConfig {
  return (
    value &&
    typeof value === 'object' &&
    typeof value.filepath === 'string' &&
    typeof value.base64 === 'string' &&
    value.id
  )
}

export interface ImportSegmentConfig
  extends Omit<SegmentConfig, 'studentContent' | 'teacherContent'> {
  studentContent?: EditorValue
  teacherContent?: EditorValue
}
export interface ImportSegment extends Segment<ImportSegmentConfig> {
  childSegments?: ImportSegment[]
  /* The key is the type of tag, and the value is an array of tag names. */
  tags?: {
    [key: string]: string[] | undefined
  }
}

export interface ImportPayload {
  courseName: string
  courseShortCode: string
  courseVersionName: string
  courseVersionShortCode: string
  segments: ImportSegment[]
  /**
   * If this is specified, all segments will be added to the parent segment
   * instead of the root segment of the course version.
   */
  parentSegmentId?: string
}
