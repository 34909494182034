import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Modal, AntIconWrapper, Block } from '@thesisedu/web'
import { Button, message, Input } from 'antd'
import { ShareIos } from 'iconoir-react'
import React from 'react'

import { useHasValue } from '../editor/useHasValue'
import { useCreateSheetMusicCompositionMutation } from '../queries/useCreateSheetMusicCompositionMutation'
import { SheetMusicState } from '../types'

export interface ShareSheetMusicModalProps {
  visible?: boolean
  onCancel?: () => void
  abc: string
  state?: SheetMusicState
}
export function ShareSheetMusicModal({ visible, abc, state, onCancel }: ShareSheetMusicModalProps) {
  const viewer = useViewerContext(true)
  const classId = useSelectedClassId()
  const defaultName = `Composition from ${viewer.name || viewer.username}`
  const [name, setName] = React.useState('')
  React.useEffect(() => {
    if (visible) {
      setName('')
    }
  }, [visible])
  const [create, { loading }] = useCreateSheetMusicCompositionMutation({
    variables: {
      input: {
        name: name || defaultName,
        abc,
        state,
        classId: classId || '',
      },
    },
  })
  const onCreate = React.useCallback(async () => {
    message.loading({ content: 'Sharing...', duration: 1000, key: 'share-comp' })
    try {
      await create()
      message.success('Composition shared!')
      if (onCancel) {
        onCancel()
      }
    } finally {
      message.destroy('share-comp')
    }
  }, [create])
  if (!classId) return null
  return (
    <>
      <Modal title={'Share Composition'} visible={visible} onCancel={onCancel}>
        <Input
          style={{ width: '100%' }}
          size={'large'}
          value={name}
          placeholder={'Name your Composition'}
          onChange={e => setName(e.target.value)}
        />
        <Block marginTop={'@size-s'}>
          <Button
            size={'large'}
            type={'primary'}
            loading={loading}
            block
            onClick={() => onCreate()}
            icon={
              <AntIconWrapper>
                <ShareIos />
              </AntIconWrapper>
            }
          >
            Share with Teacher
          </Button>
        </Block>
      </Modal>
    </>
  )
}

export interface ShareSheetMusicButtonProps {
  abc: string
}
export function ShareSheetMusicButton({ abc }: ShareSheetMusicButtonProps) {
  const hasValue = useHasValue(abc)
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <ShareSheetMusicModal abc={abc} visible={visible} onCancel={() => setVisible(false)} />
      <Button
        disabled={!hasValue}
        onClick={() => setVisible(true)}
        type={'primary'}
        size={'large'}
        icon={
          <span className={'anticon'}>
            <ShareIos width={'1em'} height={'1em'} />
          </span>
        }
      >
        Share
      </Button>
    </>
  )
}
