import React from 'react'

import { styledContainer } from '.'
import { HSpaced, color } from '../common'

export interface DividerProps {
  children?: React.ReactElement
}
/** @deprecated */
export function Divider({ children }: DividerProps) {
  if (children) {
    return (
      <HSpaced space={'@size-s'}>
        <DividerSide />
        {children}
        <DividerSide />
      </HSpaced>
    )
  } else {
    return <DividerSide style={{ height: 1 }} />
  }
}

const DividerSide = styledContainer`
  background: ${color('@gray-2')};
  height: 2px;
  flex: 1;
`
