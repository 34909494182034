import { useUpdateUserMutation } from '@thesisedu/feature-users-web/dist/queries/useUpdateUserMutation'
import { Row } from '@thesisedu/web'
import { Form, Modal, Button, Input, Col, message } from 'antd'
import React, { useEffect } from 'react'

import { StudentMetadataContext } from '../types'

export interface EditStudentModalProps {
  context: StudentMetadataContext
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
}
export function EditStudentModal({ context, visible, onVisibleChange }: EditStudentModalProps) {
  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: () => {
      message.success('User updated successfully!')
      onVisibleChange(false)
    },
    refetchQueries: context.refetchQueries,
    awaitRefetchQueries: true,
  })
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({
      firstName: context.edge.node.user.firstName,
      lastName: context.edge.node.user.lastName,
    })
  }, [context, form])
  return (
    <Modal
      title={'Edit Student'}
      visible={visible}
      onCancel={() => onVisibleChange(false)}
      footer={[
        <Button key={'cancel'} onClick={() => onVisibleChange(false)}>
          Cancel
        </Button>,
        <Button type={'primary'} key={'submit'} onClick={() => form.submit()} loading={loading}>
          Save Changes
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={values => {
          return updateUser({
            variables: {
              input: {
                id: context.edge.node.user.id,
                patch: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
              },
            },
          })
        }}
        layout={'vertical'}
      >
        <Row>
          <Col xs={12}>
            <Form.Item name={'firstName'} label={'First Name'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name={'lastName'} label={'Last Name'}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
