import { useStudentUserId } from '@thesisedu/feature-teachers-react'
import {
  ClickableContainer,
  styled,
  Block,
  Body,
  styledContainer,
  AntIconWrapper,
  Link,
  getSize,
  getColor,
} from '@thesisedu/react'
import { NavArrowRight } from '@thesisedu/react/icons'
import React from 'react'

import { StudentAvatar } from './StudentAvatar'
import { useStudentUser } from '../useStudentUsers'

const Container = styled(ClickableContainer)`
  padding: ${props => getSize(props.theme, '@size-xs')} 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RightContainer = styledContainer<{ highlighted?: boolean }>`
  margin-left: auto;
  padding-left: ${props => getSize(props.theme, '@size-s')};
  color: ${props => getColor(props.theme, props.highlighted ? '@primary-color' : '@text-color')};
`

export interface ClassStudentProps {
  studentId: string
  classId: string
  onClick?: () => void
  noLink?: boolean
  highlighted?: boolean
  metadata?: React.ReactElement
  rightContent?: React.ReactElement
  style?: any
}
export function ClassStudent({
  studentId,
  classId,
  noLink,
  highlighted,
  onClick,
  rightContent,
  metadata,
  style,
}: ClassStudentProps) {
  const { student } = useStudentUser(studentId, classId)
  const studentUserId = useStudentUserId()
  if (!student) return null
  const { user } = student
  const isTeacherUser = user.id === studentUserId
  const content = (
    <Container onClick={onClick} style={{ cursor: onClick ? 'pointer' : undefined, ...style }}>
      <StudentAvatar user={user} isTeacher={isTeacherUser} isHighlighted={highlighted} />
      <Block marginLeft={'@size-xs'}>
        <Body
          color={
            highlighted ? '@primary-color' : isTeacherUser ? '@text-color-secondary' : '@text-color'
          }
        >
          {user.name || user.username}
        </Body>
        {metadata}
      </Block>
      <RightContainer highlighted={highlighted}>
        {rightContent || <AntIconWrapper children={<NavArrowRight />} />}
      </RightContainer>
    </Container>
  )
  return noLink || onClick ? content : <Link to={`/teacher/students/${studentId}`}>{content}</Link>
}
