import { HomeOutlined } from '@ant-design/icons'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { UserSettings } from '@thesisedu/feature-users-react/dist/web/UserSettings'
import { HSpaced, Space, styled, Transition } from '@thesisedu/web'
import { Button } from 'antd'
import { ArrowLeftCircle, ArrowRightCircle } from 'iconoir-react'
import React from 'react'
import { Route, Routes, useNavigate, useLocation, Link } from 'react-router-dom'

import { Compose, SharedCompose, ViewSharedCompose } from './Compose'
import { Grades } from './Grades'
import { AnnouncementsMessages } from './Messages'
import { Outline } from './components/Outline'
import { LargeBox } from './components/styled'
import { BIG_WIDTH } from './constants'

export interface DefaultContentContextValue {
  setPreviousLink: (link?: string) => void
  setNextLink: (link?: string) => void
  setHeaderRight: (headerRight?: React.ReactElement) => void
  navigateNext: () => void
}
export const DefaultContentContext = React.createContext<DefaultContentContextValue | undefined>(
  undefined,
)
export function useDefaultContentContext(): DefaultContentContextValue | undefined
export function useDefaultContentContext(require: false): DefaultContentContextValue | undefined
export function useDefaultContentContext(require: true): DefaultContentContextValue
export function useDefaultContentContext(
  require?: boolean,
): DefaultContentContextValue | undefined {
  const context = React.useContext(DefaultContentContext)
  if (!context && require) {
    throw new Error('DefaultContentContext is required, yet not provided.')
  }
  return context
}

export function DefaultContent() {
  const navigate = useNavigate()
  const location = useLocation()
  const selectedClassId = useSelectedClassId(true)
  const [previousLink, setPreviousLink] = React.useState<string | undefined>(undefined)
  const [nextLink, setNextLink] = React.useState<string | undefined>(undefined)
  const [headerRight, setHeaderRight] = React.useState<React.ReactElement | undefined>(undefined)
  const animationLayerRef = React.useRef<HTMLDivElement>(null)

  const animateAnd = (className: string, callback: () => void) => {
    if (animationLayerRef.current) {
      const beforeNames = animationLayerRef.current.className
      const ref = animationLayerRef.current
      ref.className = `${beforeNames} animate-before`
      setTimeout(() => {
        ref.className = `${beforeNames} ${className}`
        setTimeout(() => {
          callback()
          ref.className = `${beforeNames} ${className}-next`
          setTimeout(() => {
            ref.className = beforeNames
          }, 0)
        }, 1000)
      }, 250)
    }
  }

  // We have to debounce the location because of the transition back to the
  // homepage.
  const [dLocation, setDLocation] = React.useState(location)
  React.useEffect(() => {
    if (
      location.pathname
        .split('/')
        .map((i: any) => i.trim())
        .filter(Boolean).length > 2
    ) {
      setDLocation(location)
    } else {
      const timeout = setTimeout(() => {
        setDLocation(location)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [location])
  const navigateNext = React.useCallback(() => {
    if (nextLink) {
      animateAnd('animate-next', () => {
        navigate(nextLink)
      })
    }
  }, [nextLink])
  return (
    <DefaultContentContext.Provider
      value={{
        setPreviousLink,
        setNextLink,
        setHeaderRight,
        navigateNext,
      }}
    >
      <>
        <Header className={'animate-down'}>
          <Button
            icon={<HomeOutlined />}
            onClick={() => navigate(`/student/classes/${selectedClassId}`)}
          >
            Academy Home
          </Button>
          <Space />
          <Transition type={'fade'} state={headerRight ? 'visible' : 'nope'}>
            {headerRight}
          </Transition>
        </Header>
        <OuterContainer className={'animate-up'} ref={animationLayerRef}>
          <AnimationLayer>
            {previousLink ? (
              <Link
                to={previousLink}
                onClick={(e: any) => {
                  e.preventDefault()
                  animateAnd('animate-prev', () => {
                    navigate(previousLink)
                  })
                }}
              >
                <PrevItem className={'visible'}>
                  <div>
                    <ArrowLeftCircle />
                  </div>
                </PrevItem>
              </Link>
            ) : (
              <PrevItem>
                <div>
                  <ArrowLeftCircle />
                </div>
              </PrevItem>
            )}
            <CurrentItem className={'visible'} />
            {nextLink ? (
              <Link
                to={nextLink}
                onClick={(e: any) => {
                  e.preventDefault()
                  animateAnd('animate-next', () => {
                    navigate(nextLink)
                  })
                }}
              >
                <NextItem className={'visible'}>
                  <div>
                    <ArrowRightCircle />
                  </div>
                </NextItem>
              </Link>
            ) : (
              <NextItem>
                <div>
                  <ArrowRightCircle />
                </div>
              </NextItem>
            )}
          </AnimationLayer>
          <MainContent className={'main-content'}>
            {/* @ts-ignore TODO remove me once upgrading react-router-dom */}
            <Routes location={dLocation}>
              <Route path={'grades'} element={<Grades />} />
              <Route path={'course'} element={<Outline />} />
              <Route path={'compose'} element={<Compose />} />
              <Route path={'compose/shared'} element={<SharedCompose />} />
              <Route path={'messages/*'} element={<AnnouncementsMessages />} />
              <Route path={'announcements/*'} element={<AnnouncementsMessages />} />
              <Route
                path={'compose/shared/:sheetMusicCompositionId'}
                element={<ViewSharedCompose />}
              />
              <Route path={'settings/*'} element={<UserSettings />} />
            </Routes>
          </MainContent>
        </OuterContainer>
      </>
    </DefaultContentContext.Provider>
  )
}

export interface HeaderRightContentProps {
  children: React.ReactElement
  renderKey: string
}
export function HeaderRightContent({ children, renderKey }: HeaderRightContentProps) {
  const { setHeaderRight } = useDefaultContentContext(true)
  React.useEffect(() => {
    setHeaderRight(children)
    return () => {
      setHeaderRight(undefined)
    }
  }, [renderKey])
  return null
}

const MainContent = styled(LargeBox)`
  margin-bottom: ${props => props.theme['@size-xl']};
  box-shadow: none;
  min-height: 100vh;
  padding: ${props => props.theme['@size-l']};
  overflow: hidden;
  width: ${BIG_WIDTH}px;
  max-width: 90vw;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  transition: opacity 0.25s linear;
  display: flex;
  flex-direction: column;
`
const ARROW_SIZE = 110
const ItemBox = styled(LargeBox)`
  width: 7vw;
  position: absolute;
  top: 100px;
  bottom: -45px;
  opacity: 0;
  backdrop-filter: blur(5px);
  transition:
    opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: none;
  > div {
    position: sticky;
    top: 0;
    background: white;
    border-radius: 50%;
    width: ${ARROW_SIZE}px;
    height: ${ARROW_SIZE}px;
    opacity: 1;
    transition: opacity 0.25s linear;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme['@primary-color']};
    font-size: ${ARROW_SIZE / 2.5}px;
  }
`
const PrevItem = styled(ItemBox)`
  left: -3.5vw;
  &.visible {
    opacity: 0.7;
  }
  > div {
    transform: translateX(calc(100% - ${ARROW_SIZE / 1.5}px)) translateY(100px);
  }
`
const NextItem = styled(ItemBox)`
  left: calc(100vw - 3.5vw);
  &.visible {
    opacity: 0.7;
  }
  > div {
    transform: translateX(${-ARROW_SIZE / 2}px) translateY(100px);
  }
`
const CurrentItem = styled(ItemBox)`
  opacity: 1;
  top: 0;
  left: max(calc((100vw - ${BIG_WIDTH}px) / 2), 5vw);
  width: min(90vw, ${BIG_WIDTH}px);
  bottom: 0;
`
const AnimationLayer = styled.div`
  position: absolute;
  width: 100vw;
  top: 0;
  left: calc(-1 * max(calc((100vw - ${BIG_WIDTH}px) / 2), 5vw));
  right: 0;
  bottom: 0;
`
const Header = styled(HSpaced)`
  padding: ${props => props.theme['@size-l']};
  width: ${BIG_WIDTH}px;
  max-width: 90vw;
  margin: 0 auto;
  display: flex !important;
  button {
    opacity: 0.75;
  }
`

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  &.animate-before,
  &.animate-next,
  &.animate-prev {
    ${MainContent} {
      opacity: 0;
      pointer-events: none;
    }
    ${ItemBox} > div {
      opacity: 0;
    }
  }
  &.animate-next,
  &.animate-prev {
    ${ItemBox} {
      transition:
        top 1s ease-in-out,
        left 1s ease-in-out,
        right 1s ease-in-out,
        bottom 1s ease-in-out,
        opacity 1s ease-in-out,
        width 1s ease-in-out;
    }
  }
  &.animate-prev {
    ${CurrentItem} {
      left: calc(100vw - 3.5vw);
      width: 7vw;
      top: 100px;
      opacity: 0.7;
      bottom: -45px;
    }
    ${PrevItem} {
      left: max(calc((100vw - ${BIG_WIDTH}px) / 2), 5vw);
      width: min(90vw, ${BIG_WIDTH}px);
      top: 0;
      opacity: 1;
      bottom: 0;
    }
    ${NextItem} {
      opacity: 0;
      left: 110vw;
    }
  }
  &.animate-next {
    ${CurrentItem} {
      left: -3.5vw;
      width: 7vw;
      top: 100px;
      opacity: 0.7;
      bottom: -45px;
    }
    ${NextItem} {
      left: max(calc((100vw - ${BIG_WIDTH}px) / 2), 5vw);
      width: min(90vw, ${BIG_WIDTH}px);
      top: 0;
      opacity: 1;
      bottom: 0;
    }
    ${PrevItem} {
      opacity: 0;
      left: -10vw;
    }
  }
  &:not(.animate-next):not(.animate-prev) {
    ${NextItem}, ${PrevItem} {
      cursor: pointer;
      &.visible {
        pointer-events: all;
      }
      &:hover {
        transform: scale(1.02);
        opacity: 0.9;
        box-shadow: ${props => props.theme['@shadow-large-hover']};
      }
      &:active {
        transform: scale(0.98);
        opacity: 0.9;
        box-shadow: ${props => props.theme['@shadow-large-pressed']};
      }
    }
  }
  &.animate-next-next,
  &.animate-prev-next {
    ${CurrentItem} {
      opacity: 1;
      transition: none;
    }
  }
  &.animate-next-next ${NextItem} {
    opacity: 0;
    transition: none;
  }
  &.animate-prev-next ${PrevItem} {
    opacity: 0;
    transition: none;
  }
`
