import { useMutateHook } from '@thesisedu/feature-react'

import { useCreateClassGroupPresetMutation } from '../../queries/useCreateClassGroupPresetMutation'
import { GroupsEditImportGroupsHook } from '../../types'

export interface ImportFromLMSProps {
  classId: string
}
export function ImportFromLMS({ classId }: ImportFromLMSProps) {
  const [create] = useCreateClassGroupPresetMutation()
  const importGroups = useMutateHook<GroupsEditImportGroupsHook>(
    'feature-assignments-react:groups-edit-import-groups',
    null,
    {
      classId,
      onComplete: async importedGroups => {
        await create({
          variables: {
            input: {
              id: classId,
              preset: {
                name: 'Imported from LMS',
                configuration: importedGroups,
              },
            },
          },
        })
      },
    },
  )

  return importGroups
}
