import { UserSettingsPageResource } from '@thesisedu/feature-users-react'
import { AppNotification } from '@thesisedu/react/icons'
import React from 'react'

import { NotificationPreferencesReactFeature } from '../../NotificationPreferencesReactFeature'
import { UserPreferencesEditor } from '../../web/UserPreferencesEditor'

export default function (feature: NotificationPreferencesReactFeature) {
  feature.resources.addResource<UserSettingsPageResource>({
    type: 'UserSettingsPage',
    identifier: 'notifications',
    title: 'Notifications',
    icon: <AppNotification />,
    weight: 1,
    component: () => <UserPreferencesEditor />,
  })
}
