import { CalendarOutlined } from '@ant-design/icons'
import { flattenSegments } from '@thesisedu/feature-course-types'
import pluralize from 'pluralize'
import React from 'react'

import { SegmentInfoItem } from './SegmentInfoPopover'
import { SegmentDaysContext } from '../contexts/DaysContext'
import { Segment } from '../types'

export interface SegmentInfoDaysProps {
  segment: Segment
}
export function SegmentInfoDays({ segment }: SegmentInfoDaysProps) {
  const context = React.useContext(SegmentDaysContext)
  if (!context) return null
  const daySum = (flattenSegments([segment]) as Segment[]).reduce<number>((acc, daySegment) => {
    return acc + (daySegment.config.days || 0)
  }, 0)

  return (
    <SegmentInfoItem icon={<CalendarOutlined />}>
      About {pluralize('day', parseFloat(daySum.toFixed(2)), true)}
    </SegmentInfoItem>
  )
}
