import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import { definition } from '.'
import { VideoEmbedWithUrl } from './VideoEmbedWithUrl'
import { VideoEmbedConfig } from './common'
import { SimpleWidget } from '../utils'

export function VideoEmbedElement({
  config,
  node,
}: SimpleWidget.SimpleWidgetProps<VideoEmbedConfig>) {
  const { url, alignment } = config
  if (url) {
    return <VideoEmbedWithUrl url={url} alignment={alignment} />
  } else {
    return (
      <SimpleWidget.WidgetEmptyState
        {...definition}
        node={node}
        buttonLabel={'Click to add a Video...'}
        icon={<Play />}
      />
    )
  }
}
