import { s } from '@thesisedu/ui'
import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'

import { styledContainer, styled } from '../styled'

const MAX_CAPTION_LINE_CHARS = 75
const CAPTION_MAX_WIDTH = 0.9
const FONT_SIZE_FROM_CHAR_WIDTH = 1.75
const DEFAULT_FONT_SIZE = 20
const MAX_FONT_SIZE = 40

function getFontSize(width?: number) {
  if (width) {
    const charWidth = (width * CAPTION_MAX_WIDTH) / MAX_CAPTION_LINE_CHARS
    return Math.min(MAX_FONT_SIZE, FONT_SIZE_FROM_CHAR_WIDTH * charWidth)
  } else return DEFAULT_FONT_SIZE
}

export interface PlayerCaptionProps {
  captions?: (string | React.ReactElement)[]
  footerVisible?: boolean
  playerWidth?: number
}
export function PlayerCaption({ captions, footerVisible, playerWidth }: PlayerCaptionProps) {
  const fontSize = getFontSize(playerWidth)
  return (
    <Container style={footerVisible ? { transform: 'translateY(-60px)' } : undefined}>
      <AnimatePresence>
        {(captions || []).map((caption, index) => {
          const isLast = index === captions!.length - 1
          return (
            <Caption
              key={caption.toString()}
              fontSize={fontSize}
              transition={{
                type: 'spring',
                damping: 30,
                stiffness: 500,
                mass: 1,
              }}
              layout
              initial={{ opacity: 0, y: 20, scale: 0.9 }}
              animate={{ opacity: 1, y: 0, scale: isLast ? 1 : 0.8 }}
              exit={{ opacity: 0, scale: 0.5 }}
            >
              {caption}
            </Caption>
          )
        })}
      </AnimatePresence>
    </Container>
  )
}

export const Container = styledContainer`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: translateY(calc(-1 * ${s.size('s')}));
  z-index: 3;
  pointer-events: none;
`
const Caption = styled(motion.li)<{ fontSize: number }>`
  max-width: 90%;
  text-align: center;
  padding: ${props => props.fontSize / 3}px ${props => props.fontSize}px;
  white-space: normal;
  display: block;
  color: ${s.color('textEmphasis')};
  background: ${s.opacity(s.color('gray.element'), 0.8)};
  border-radius: ${props => Math.round(props.fontSize / 5)}px;
  font-family: ${s.var('font.default')} !important;
  font-size: ${props => props.fontSize}px !important;
  backdrop-filter: blur(5px);
`
