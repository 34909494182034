import React from 'react'

/**
 * Calls the passed callback whenever clicking outside the passed element.
 * @param container a ref to the container element
 * @param callback the callback to call whenever an outside click is done
 */
export function useOutsideClick(
  container: React.RefObject<HTMLElement | null>,
  callback: (event: DocumentEventMap['mousedown']) => void,
) {
  React.useEffect(() => {
    const handler = (event: DocumentEventMap['mousedown']) => {
      if (container.current && !container.current.contains(event.target as any)) {
        callback(event)
      }
    }
    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  }, [container])
}
