import React, { useContext } from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

export interface ThemeContextValue {
  light: Record<string, any>
  dark: Record<string, any>
}
export const ThemeContext = React.createContext<ThemeContextValue | undefined>(undefined)

export interface ThemeProviderProps {
  theme?: keyof ThemeContextValue
}
export const ThemeProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
  theme = 'light',
}) => {
  const context = useContext(ThemeContext)
  if (context) {
    return (
      <StyledThemeProvider
        theme={{
          theme,
          ...context[theme],
        }}
        children={children}
      />
    )
  } else {
    console.warn('trying to set theme without a ThemeContext.Provider. this will have no effect')
    return <>{children}</>
  }
}
