import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { DATE_FORMATS } from '@thesisedu/web'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import { set } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { ApproveCommentButton } from './ApproveCommentButton'
import { CommentStatus } from './CommentStatus'
import { RejectCommentButton } from './RejectCommentButton'
import { CommentModerationFilters } from './types'
import { CommentContentLink } from '../CommentContentLink'
import {
  AdminCommentFragment,
  CommentsDocument,
  CommentsQuery,
  CommentsQueryVariables,
  OrderDirection,
} from '../schema'

function getSortOrder(
  key: string,
  orderBy: string,
  orderDirection: OrderDirection,
): 'ascend' | 'descend' | null {
  if (orderBy === key) {
    return orderDirection === OrderDirection.Desc ? 'descend' : 'ascend'
  } else {
    return null
  }
}

export interface CommentsTableProps extends Partial<TableProps<AdminCommentFragment>> {
  filters?: CommentModerationFilters
}
export function CommentsTable({ filters, ...tableProps }: CommentsTableProps) {
  const [orderBy, setOrderBy] = React.useState<string>('createdAt')
  const [orderDirection, setOrderDirection] = React.useState<OrderDirection>(OrderDirection.Desc)
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <InfiniteQuery<AdminCommentFragment, CommentsQuery, CommentsQueryVariables>
        document={CommentsDocument}
        getResult={d => d?.comments}
        setResult={(p, n) => set(p, 'comments', n)}
        infiniteScrollerProps={{
          scrollableTarget: containerRef.current || undefined,
        }}
        queryOpts={{
          fetchPolicy: 'cache-and-network',
        }}
        variables={{
          orderBy,
          orderDirection,
          approval: filters?.approvals,
        }}
        children={({ data, loading }) => (
          <Table<AdminCommentFragment>
            dataSource={data?.comments.edges.map(edge => edge.node) || []}
            loading={loading}
            pagination={false}
            rowKey={item => item.id}
            {...tableProps}
            onChange={(pagination, filters, sorter, ...args) => {
              if (tableProps?.onChange) {
                tableProps.onChange(pagination, filters, sorter, ...args)
              }
              if (sorter && !Array.isArray(sorter) && sorter.columnKey && sorter.order) {
                setOrderBy(sorter.columnKey.toString())
                setOrderDirection(
                  sorter.order === 'descend' ? OrderDirection.Desc : OrderDirection.Asc,
                )
              }
            }}
          >
            <Table.Column<AdminCommentFragment>
              dataIndex={['id']}
              key={'content-name'}
              title={'Content'}
              render={(_, record) => <CommentContentLink comment={record} />}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['user', 'firstName']}
              key={'user.firstName'}
              title={'First Name'}
              sorter
              sortOrder={getSortOrder('user.firstName', orderBy, orderDirection)}
              render={(item, record) => (
                <Link to={`/admin/users/${record.user.id}`} children={item} />
              )}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['user', 'lastName']}
              key={'user.lastName'}
              title={'Last Name'}
              sorter
              sortOrder={getSortOrder('user.lastName', orderBy, orderDirection)}
              render={(item, record) => (
                <Link to={`/admin/users/${record.user.id}`} children={item} />
              )}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['user', 'username']}
              key={'user.username'}
              title={'Username'}
              sorter
              sortOrder={getSortOrder('user.username', orderBy, orderDirection)}
              render={(item, record) => (
                <Link to={`/admin/users/${record.user.id}`} children={item} />
              )}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['comment']}
              key={'comment'}
              title={'Comment'}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['id']}
              key={'status'}
              title={'Status'}
              render={(_, record) => <CommentStatus comment={record} />}
            />
            <Table.Column<AdminCommentFragment>
              dataIndex={['createdAt']}
              key={'createdAt'}
              title={'Created'}
              sorter
              sortOrder={getSortOrder('createdAt', orderBy, orderDirection)}
              render={item => moment(item).format(DATE_FORMATS.FULL)}
            />
            <Table.Column<AdminCommentFragment>
              key={'actions'}
              title={''}
              dataIndex={['id']}
              render={(id, record) => (
                <>
                  <ApproveCommentButton commentId={id} />
                  <RejectCommentButton comment={record} />
                </>
              )}
            />
          </Table>
        )}
      />
    </div>
  )
}
