import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { message } from 'antd'

import {
  TeamTeachingClassFragmentDoc,
  useInviteTeacherToClassMutation as useMutation,
} from '../schema'

export const useInviteTeacherToClassMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError(
      'There was an error inviting that teacher.',
      ['TEACHER_ALREADY_IN_CLASS_ERROR'],
      () => message.error('You have already invited that teacher.'),
    ),
    update: updateMutation({
      fragment: TeamTeachingClassFragmentDoc,
      fragmentName: 'TeamTeachingClass',
      path: 'inviteTeacherToClass.class',
    }),
    ...opts,
  })
