import { Format } from '@thesisedu/feature-utils'
import { styled, s, TextField, TextAreaField, HSpaced, NumberField, Button } from '@thesisedu/ui'
import { Plus, Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentRubricPresetInput } from '../schema'

export const PRESET_MIN_WIDTH_PX = 150
export interface CriterionPresetEditorProps {
  value: AssignmentRubricPresetInput
  onChange: (value: AssignmentRubricPresetInput) => void
  onAdd: (delta: number) => void
  onDelete: () => void
  totalPoints: number
}
export function CriterionPresetEditor({
  value,
  onChange,
  onAdd,
  onDelete,
  totalPoints,
}: CriterionPresetEditorProps) {
  return (
    <Container data-testid={'CriterionPresetEditor'}>
      <HSpaced space={'xxs'}>
        <TextField
          aria-label={'Score Name'}
          placeholder={'Score'}
          data-testid={'Name'}
          value={value.name}
          onChange={name => onChange({ ...value, name })}
          size={'small'}
          variant={['ghost', 'bold']}
          style={{ flex: 1 }}
          autoComplete={'off'}
        />
        <DeleteButton
          data-testid={'DeleteScore'}
          size={'small'}
          variant={'ghost'}
          status={'danger'}
          icon={<Trash />}
          aria-label={'Delete Score'}
          excludeFromTabOrder // FIXME Accessibility
          onPress={onDelete}
        />
      </HSpaced>
      <TextAreaField
        aria-label={'Score Description'}
        placeholder={'Score Description'}
        data-testid={'Description'}
        value={value.description ?? undefined}
        onChange={description => onChange({ ...value, description })}
        size={'small'}
        variant={'ghost'}
      />
      <NumberField
        style={{ width: 125, marginTop: 'auto' }}
        aria-label={'Points'}
        data-testid={'Points'}
        minValue={0}
        maxValue={totalPoints}
        value={value.points}
        onChange={points => onChange({ ...value, points })}
        size={'small'}
        variant={'ghost'}
        suffix={<span style={{ flexShrink: 0 }}>/ {Format.plural('pt', totalPoints)}</span>}
      />
      <InsertPresetContainer data-before>
        <Button
          variant={'ghost'}
          size={'small'}
          icon={<Plus />}
          data-testid={'InsertScore Before'}
          aria-label={'Insert Score Before'}
          excludeFromTabOrder // FIXME Accessibility
          onPress={() => onAdd(-1)}
        />
      </InsertPresetContainer>
      <InsertPresetContainer data-after>
        <Button
          variant={'ghost'}
          size={'small'}
          icon={<Plus />}
          data-testid={'InsertScore After'}
          aria-label={'Insert Score After'}
          excludeFromTabOrder // FIXME Accessibility
          onPress={() => onAdd(1)}
        />
      </InsertPresetContainer>
    </Container>
  )
}

const DeleteButton = styled(Button)`
  opacity: 0;
  transition: opacity 0.1s linear;
`
const InsertPresetContainer = styled.div`
  opacity: 0;
  transition: opacity 0.1s linear;
  position: absolute;
  top: 50%;
  background: ${s.color('subtle')};
  border-radius: ${s.var('radii.1')};
  z-index: 1;
  &:hover {
    z-index: 2;
  }
  &[data-before] {
    left: 0;
    transform: translateX(-50%) translateY(-50%);
  }
  &[data-after] {
    right: -1px;
    transform: translateX(50%) translateY(-50%);
  }
`
const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: ${s.size('xs')};
  min-width: ${PRESET_MIN_WIDTH_PX}px;
  position: relative;
  &:not(:last-child) {
    border-right: solid 1px ${s.color('subtleBorder')};
  }
  textarea {
    flex: 1;
    resize: none;
  }
  &:hover:not(:focus-within) ${InsertPresetContainer} {
    opacity: 1;
  }
  &:hover ${DeleteButton}, &:focus-within ${DeleteButton} {
    opacity: 1;
  }
`
