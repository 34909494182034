import { Feature } from '@thesisedu/feature'

import { QuestionTypeResource } from '../../types'

const QUESTIONS: QuestionTypeResource[] = [
  require('./CheckboxSelect').default,
  require('./FileUpload').default,
  require('./Group').default,
  require('./LongForm').default,
  require('./Match').default,
  require('./MediumForm').default,
  require('./MultipleChoice').default,
  require('./NotesBox').default,
  require('./OpenEnded').default,
]

export default function addQuestionResources(feature: Feature) {
  for (const question of QUESTIONS) {
    feature.resources.addResource(question)
  }
}
