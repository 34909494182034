/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String']['input'];
};

export enum AccountRegion {
  UsEast_1 = 'US_EAST_1',
  UsWest_1 = 'US_WEST_1'
}

export type AnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AnsweredQuestionContentInput = {
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type AnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum AnsweredQuestionCreator {
  Me = 'ME'
}

export type AnsweredQuestionInput = {
  content?: InputMaybe<AnsweredQuestionContentInput>;
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postName?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AnsweredQuestionStatus>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
  vodId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible statuses for an answered question */
export enum AnsweredQuestionStatus {
  /** The question has been accepted, but we haven't started responding yet */
  Accepted = 'ACCEPTED',
  /** The question has just been submitted */
  New = 'NEW',
  /** We have completed our response and published it */
  Published = 'PUBLISHED',
  /** The question has been denied, for whatever reason */
  Rejected = 'REJECTED',
  /** We are preparing our response */
  Responding = 'RESPONDING'
}

/** Filters available when querying for answered questions. */
export type AnsweredQuestionsFilters = {
  /** Filter based on questions created by specific contributors. */
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter based on who created the question. */
  creator?: InputMaybe<AnsweredQuestionCreator>;
  /** Filter based on the status of the question. */
  status?: InputMaybe<Array<AnsweredQuestionStatus>>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
};

export type ApproveCommentInput = {
  id: Scalars['ID']['input'];
};

export type ApproveTrialRequestInput = {
  approval: TrialRequestApproval;
  id: Scalars['ID']['input'];
  noEmail?: Scalars['Boolean']['input'];
};

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID']['input'];
};

/**
 * Specify either assignedStudents or assignedClasses, depending on the assignment mode for the
 * assignment. You may not use assignedStudents if the mode is ALL_STUDENTS.
 */
export type AssignInput = {
  assignedClasses?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedStudents?: InputMaybe<Array<ClassStudentInput>>;
};

export type AssignLessonInput = {
  assignments: Array<LessonAssociationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssignmentAutoGradeMode {
  AllQuestions = 'ALL_QUESTIONS',
  None = 'NONE',
  SomeQuestions = 'SOME_QUESTIONS'
}

export type AssignmentCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gradeWeight?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentClassConfigurationInput = {
  gradingMode?: InputMaybe<ClassGradingMode>;
};

export enum AssignmentExcludedFilter {
  Both = 'BOTH',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum AssignmentFeature {
  Attempts = 'ATTEMPTS',
  Groups = 'GROUPS'
}

export type AssignmentFiltersInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dueDate?: InputMaybe<DueDateFilterInput>;
  /** What exclusion status of assignments should be included? */
  excluded?: InputMaybe<AssignmentExcludedFilter>;
  /**
   * If this is true, only generated assignments are included. This is the same as passing
   * all available generated providers to the generatedProviders option.
   */
  generated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The identifiers for generated-assignment providers to filter on. */
  generatedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  missing?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<TagsFilter>;
  ungraded?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC'
}

export type AssignmentInput = {
  assign?: InputMaybe<AssignInput>;
  assignmentCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignmentMode?: InputMaybe<AssignmentStudentAssignmentMode>;
  assignmentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueAtOffset?: InputMaybe<Scalars['Int']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  gradingMode?: InputMaybe<AssignmentGradingMode>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openAt?: InputMaybe<Scalars['DateTime']['input']>;
  openAtOffset?: InputMaybe<Scalars['Int']['input']>;
  revealAnswers?: InputMaybe<RevealAnswers>;
  revealAttempts?: InputMaybe<Scalars['Int']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
  submissionType?: InputMaybe<SubmissionType>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  totalPoints?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentRubricCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  presets?: InputMaybe<Array<AssignmentRubricPresetInput>>;
  totalPoints: Scalars['Float']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentRubricInput = {
  categories: Array<AssignmentRubricCategoryInput>;
};

export type AssignmentRubricPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS'
}

export type AssignmentTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  configurationChanges?: InputMaybe<Scalars['JSON']['input']>;
  defaults?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AssociatedClassAssignmentInput = {
  /** The class ID. */
  id: Scalars['ID']['input'];
  overrides?: InputMaybe<AssignmentInput>;
  segmentId: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type AttachClassSyncInput = {
  /** The ID of the class to sync. */
  id: Scalars['ID']['input'];
  /** The name of the class on the provider. */
  label: Scalars['String']['input'];
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String']['input'];
};

export type AttachClassSyncStudentAccountInput = {
  /** The current class ID to attach the student to. */
  id: Scalars['ID']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  userInfo: BasicUserInput;
};

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String']['input'];
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String']['input'];
};

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The original name of the file. */
  name: Scalars['String']['input'];
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput;
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float']['input'];
};

export type AutoGradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export enum AutoGradingStatus {
  /** Automatic grading failed for this question. */
  Failed = 'FAILED',
  /** The question has been graded already. */
  Graded = 'GRADED',
  /** The question is currently being graded. */
  Grading = 'GRADING'
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String']['input'];
};

export type AwardBadgeInput = {
  /** The ID of the badge to award. */
  id: Scalars['ID']['input'];
  /** The IDs of the users to award the badge to. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export enum BadgeEventType {
  Badge = 'badge',
  Interaction = 'interaction',
  Segment = 'segment'
}

export type BadgeInput = {
  awardedText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<BadgeEventType>;
  eventConfig?: InputMaybe<Scalars['JSON']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BasicUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BuiltSegmentInput = {
  /**
   * If this is false, the studentContent and teacherContent are not included for this
   * segment. See Segment.hydrated for how to fetch the hydrated segment if this is
   * false.
   */
  hydrated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID']['input'];
};

export type CancelLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
};

/** Reasons a trial request might not be able to be approved. */
export enum CantApproveTrialRequestReason {
  /** This user already belongs to a non-trial license. */
  BelongsToNonTrialLicense = 'BELONGS_TO_NON_TRIAL_LICENSE',
  /** A deleted user already exists with this email address. */
  DeletedUserExists = 'DELETED_USER_EXISTS'
}

export type CanvasAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type CanvasHealthInput = {
  consumerKey?: InputMaybe<Scalars['String']['input']>;
  redirectUri: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CanvasIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT'
}

/** This is deprecated; use the class configuration instead. */
export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS'
}

export type ClassGroupPresetInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  submissionType?: InputMaybe<GroupSubmissionType>;
};

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID']['input'];
};

export type ClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segmentMetadata?: InputMaybe<Scalars['JSON']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ClassReportInput = {
  classIds: Array<Scalars['ID']['input']>;
  igNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ClassRole {
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type ClassStudentInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type ClassSyncHistoryFilters = {
  /**
   * If you want to filter the results to match only specific class
   * sync IDs.
   */
  classSyncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<ClassSyncHistoryStatus>>;
  /**
   * If you want to filter the results to match only specific assignment
   * or material sync IDs.
   */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Array<ClassSyncHistoryType>>;
};

export enum ClassSyncHistoryStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export enum ClassSyncHistoryType {
  /** The sync created or updated an assignment without touching the grades. */
  Basic = 'BASIC',
  /** The sync was in response to a grade change and only updated the grade. */
  OnlyGrades = 'ONLY_GRADES',
  /** This was an assignment submit event. */
  Submit = 'SUBMIT',
  /** The sync either did not exist or touched both the basic metadata and grades. */
  WithGrades = 'WITH_GRADES'
}

export type ClassSyncProviderConfigurationInput = {
  enabledFeatures?: InputMaybe<Array<ClassSyncProviderFeature>>;
  providerIdentifier: Scalars['String']['input'];
};

export enum ClassSyncProviderFeature {
  GradeMaterialSync = 'GRADE_MATERIAL_SYNC',
  Rostering = 'ROSTERING'
}

export type ClassSyncRosterActionInput = {
  mode: ClassSyncRosterActionMode;
  providerStudentId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Types of actions to take when rostering students. */
export enum ClassSyncRosterActionMode {
  /** Import the student from the provider into Feature. */
  Import = 'IMPORT',
  /** Match a provider student with an existing Feature student. */
  Match = 'MATCH',
  /** Remove an existing Feature student from the class. */
  Remove = 'REMOVE'
}

export type ClassTemplatesFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sharingTypes?: InputMaybe<Array<SharingType>>;
};

export type ClassesReportDimensionInput = {
  classIds: Array<Scalars['ID']['input']>;
};

export type CommentAddedInput = {
  /** The ID of the entity to listen to for new comments. */
  id: Scalars['ID']['input'];
};

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED'
}

export type CommentInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentOnNodeInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** The ID of the node to comment on. */
  id: Scalars['ID']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentsFilterInput = {
  /** If this is true, only shows comments for the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is passed, filters comments for those made by the specified user ID. */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ConfirmationInput = {
  clearGradesWhenChangingAssignmentRubric?: InputMaybe<Scalars['Boolean']['input']>;
  overwriteClassTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentVisitedReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID']['input'];
};

export type CopySegmentAsOwnInput = {
  /** The ID of the class the teacher is working in. */
  id: Scalars['ID']['input'];
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
};

export type CopySegmentSettingsInput = {
  /** The IDs of the classes to copy this information to. */
  destinationIds: Array<Scalars['ID']['input']>;
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CopyTagsToChildrenInput = {
  /** The ID of the course version you are currently viewing. */
  courseVersionId: Scalars['ID']['input'];
  /** The ID of the segment to copy the tags to its children. */
  id: Scalars['ID']['input'];
};

export type CourseInput = {
  classConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isByoc?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CourseVersionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CourseViewWeightInput = {
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
  /** The new weight for that segment. */
  weight: Scalars['Float']['input'];
};

export type CreateAnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateAnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: RegisterInput;
};

export type CreateAssignmentCategoryInput = {
  category: AssignmentCategoryInput;
  /** The class ID */
  id: Scalars['ID']['input'];
};

export type CreateAssignmentInput = {
  assignment: AssignmentInput;
};

export type CreateCheckoutSessionInput = {
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String']['input'];
  /** The class to create a checkout session for. */
  id: Scalars['ID']['input'];
  /** The URL to redirect to on success. */
  successUrl: Scalars['String']['input'];
};

export type CreateClassFromTemplateInput = {
  /** The template to create a class from. */
  id: Scalars['ID']['input'];
  /** The name of the class. */
  name: Scalars['String']['input'];
};

export type CreateClassGroupPresetInput = {
  /** The ID of the class to associate the preset with. */
  id: Scalars['ID']['input'];
  preset: ClassGroupPresetInput;
};

export type CreateClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateClassWithPlacedContentInput = {
  class: ClassInput;
  segmentId: Scalars['ID']['input'];
};

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID']['input'];
  /** The name of the new version to create. */
  name: Scalars['String']['input'];
};

export type CreateDirectMessageThreadInput = {
  /** The block-based message to send. */
  blockMessage: Scalars['JSON']['input'];
  /** An array of user IDs to send the message to. */
  recipientIds: Array<Scalars['ID']['input']>;
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the thread. */
  subject: Scalars['String']['input'];
};

export type CreateDiscussionThreadInput = {
  /** The ID of the discussion to create the thread in. */
  id: Scalars['ID']['input'];
  thread: DiscussionThreadInput;
};

export type CreateSavedReportInput = {
  name: Scalars['String']['input'];
  report: Scalars['JSON']['input'];
};

export type CreateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type CreateStudentInput = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  /** This is only required if the provider requires it. */
  signedUploadInput?: InputMaybe<SignedUploadInput>;
  sizeInBytes: Scalars['Int']['input'];
};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteAssignmentCategoryInput = {
  /** The ID of the assignment category to delete. */
  id: Scalars['ID']['input'];
  /**
   * The ID of a category inside the same class to move all assignments to that
   * belong to the category being deleted.
   */
  replacementCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID']['input'];
};

export type DetailedLogsForUserInput = {
  /** Filter by the name of the function handler, rather than displaying all handlers. */
  handler?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to get logs for. */
  id: Scalars['ID']['input'];
  /** The JWT of the administration user. */
  jwt: Scalars['String']['input'];
  /** Any additional tags to filter by. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DirectMessageThreadsFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Other features are supposed to add to this later... */
  noop?: InputMaybe<Scalars['String']['input']>;
};

export type DiscussionThreadInput = {
  content: Scalars['JSON']['input'];
};

export type DiscussionThreadsFilterInput = {
  /**
   * If selected, only returns discussion threads either created by the passed user, or with
   * comments from the passed user.
   */
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If this is true, only shows discussion threads created by the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistrictConfigurationInput = {
  /** Provide default values for all sites inside this district. */
  defaultSiteConfiguration?: InputMaybe<SiteConfigurationInput>;
};

export type DistrictInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<DistrictConfigurationInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DueDateFilterInput = {
  /** Defaults to the current date if not specified. */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  type: DueDateFilterType;
};

export enum DueDateFilterType {
  After = 'AFTER',
  Before = 'BEFORE'
}

export enum DuplicateUserPendingStudentClassResolution {
  /**
   * These are not the same students, so the student should be accepted into
   * the class but should not be merged into the matchedStudentId.
   */
  Accept = 'ACCEPT',
  /**
   * Ignore this request; the student should not be added to the class and the
   * students should not be merged.
   */
  Decline = 'DECLINE',
  /**
   * These are actually the same students, and the current student should be
   * merged into the matchedStudentId, and then the current student should be
   * deleted.
   */
  Merge = 'MERGE'
}

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FinishUploadingVideoOnDemandInput = {
  /** The ID of the video on demand you just finished uploading. */
  vodId: Scalars['ID']['input'];
};

export type ForceResyncAssignmentSyncInput = {
  /** The ID of the assignment sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForceResyncMaterialSyncInput = {
  /** The ID of the material sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForfeitAssignmentInput = {
  classId: Scalars['ID']['input'];
  forfeit: Scalars['Boolean']['input'];
  /** The ID of the assignment to forfeit. */
  id: Scalars['ID']['input'];
  /** If modifying as a teacher, the ID of the student to modify. */
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type FreeUsernameInput = {
  /** The username or email address you would like to free. */
  username: Scalars['String']['input'];
};

export type GenerateCanvasCredentialsTokenInput = {
  districtId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GenerateGroupMode {
  MaximumCollaboration = 'MAXIMUM_COLLABORATION',
  Random = 'RANDOM'
}

export enum GenerateLeaderMode {
  LeadershipBoost = 'LEADERSHIP_BOOST',
  Random = 'RANDOM'
}

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID']['input'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type GenerateStudentsInput = {
  count: Scalars['Int']['input'];
  /** The ID of the class to generate students for. */
  id: Scalars['ID']['input'];
};

export type GenerateVodCaptionsInput = {
  /** The ID of the video to generate captions for. */
  id: Scalars['ID']['input'];
};

export type GeneratedGroupsConfigurationInput = {
  /** The logic to use when grouping students. */
  groupMode: GenerateGroupMode;
  /** The logic to use when choosing leaders. */
  leaderMode: GenerateLeaderMode;
  /** The number of groups to generate. */
  numGroups: Scalars['Int']['input'];
  /** The students to include in the groups. */
  students: Array<ClassStudentInput>;
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type GradeAssignmentSubmissionInput = {
  comments: Scalars['JSON']['input'];
  grades: Scalars['JSON']['input'];
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export type GradeReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupSubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL'
}

export type ImportCourseViewWeightsInput = {
  /** The ID of the course view to update weights for. */
  courseViewId: Scalars['ID']['input'];
  /**
   * If this is true, the update will be done in the foreground
   * instead of via a background job.
   */
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new weights to import. */
  weights: Array<CourseViewWeightInput>;
};

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID']['input'];
  /** The ID of the source to use. */
  sourceId: Scalars['String']['input'];
};

export type ImportSegmentsInput = {
  /** The contents of the CSV to use for imports. */
  csvContent: Scalars['String']['input'];
  /**
   * If this is true, new tags will not be created. So if you specify
   * a tag that has not yet been registered in the system, it won't
   * be added to that content.
   */
  dontCreateTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** How should we process tags when importing? */
  tagsMode: ImportSegmentsTagsMode;
  /**
   * If this is true, the segment ID column is required, and the label
   * of the segment will be updated when imported.
   */
  updateLabel?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If this is true, the segment ID column is required, and the name
   * of the segment will be updated when imported.
   */
  updateName?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ImportSegmentsTagsMode {
  /** Tags will be appended to the existing tags. */
  Append = 'APPEND',
  /** No tags will be imported. */
  Ignore = 'IGNORE',
  /**
   * The existing tags (in the specified types) will be replaced completely.
   * If you pass a "COURSE" tag of "Course A" and the content already has a
   * "COURSE" tag of "Course B", the "Course B" tag will be removed.
   * If the content already had an "IMPLEMENTATION_GUIDE" tag but you didn't
   * specify a new one in the sheet, the existing one will be kept.
   */
  Replace = 'REPLACE'
}

export type ImportStudentsInput = {
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String']['input'];
  /** The ID of the class to import the students to. */
  id: Scalars['ID']['input'];
};

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String']['input'];
};

export type InteractionInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
  reference?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export enum InviteResult {
  AddedToClass = 'ADDED_TO_CLASS',
  InvitedToPlatform = 'INVITED_TO_PLATFORM'
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID']['input'];
  /** The student ID to import. */
  studentId: Scalars['String']['input'];
  /** The teacher ID to import. */
  teacherId: Scalars['String']['input'];
};

export type InviteStudentInput = {
  /** The email address of the student to invite. */
  email: Scalars['String']['input'];
  /** The ID of the class to invite the student to. */
  id: Scalars['ID']['input'];
};

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID']['input'];
  /** Any default permissions to apply to the teacher. */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID']['input'];
};

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String']['input'];
};

export type LessonAssociationInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LessonInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LessonsAssignedStudentPairInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LicenseInput = {
  courses?: InputMaybe<Scalars['JSON']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  monthlyPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontMonths?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<LicenseType>;
};

export type LicenseSubscriptionInput = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  nextValidityCheck?: InputMaybe<Scalars['DateTime']['input']>;
  size?: InputMaybe<LicenseSubscriptionSize>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LicenseSubscriptionType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum LicenseSubscriptionSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum LicenseSubscriptionType {
  Individual = 'INDIVIDUAL',
  StudentPay = 'STUDENT_PAY'
}

export enum LicenseType {
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
  Trial = 'TRIAL'
}

export type LikeNodeInput = {
  /** The ID of the node to like. */
  id: Scalars['ID']['input'];
};

export type ListSyncProviderClassesInput = {
  /** If you would like to have a class automatically suggested, pass in the class ID. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  provider: Scalars['String']['input'];
};

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput;
  /** An optional Canvas integration to use, for anonymous access. */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginWithCleverInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithGoogleInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithMsalInput = {
  authCode?: InputMaybe<MsalAuthCodeInput>;
  tokens?: InputMaybe<MsalTokensInput>;
};

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput;
};

export type MsalAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type MsalIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export type MsalTokensInput = {
  accessToken: Scalars['String']['input'];
  expiresAt: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type MediaBookmarkInput = {
  endSeconds: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startSeconds: Scalars['Float']['input'];
};

export type MediaInput = {
  metadata?: InputMaybe<MediaMetadataInput>;
  status?: InputMaybe<MediaStatus>;
};

export type MediaMetadataInput = {
  bookmarks?: InputMaybe<Array<MediaBookmarkInput>>;
};

export enum MediaStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED'
}

export enum MediaType {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type MediaUploadUrlInput = {
  /** An optional class to associate the media with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  extension: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  region?: InputMaybe<AccountRegion>;
  requestMode?: InputMaybe<UploadRequestMode>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type MoveStudentClassInput = {
  /**
   * Whether or not to force the move. This typically means some warnings were given, and
   * you acknowledge them and would like to continue anyway.
   */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the source class. */
  fromClassId: Scalars['ID']['input'];
  /** The ID of the student. */
  id: Scalars['ID']['input'];
  /** The ID of the destination class. */
  toClassId: Scalars['ID']['input'];
};

export type MoveTeacherInput = {
  id: Scalars['ID']['input'];
  toSite: Scalars['ID']['input'];
};

export type NumAttemptsMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PendingStudentClassReason {
  DuplicateUser = 'DUPLICATE_USER',
  /** Don't use this. */
  Placeholder = 'PLACEHOLDER'
}

export enum PendingStudentClassState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type PendingStudentClassesFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reasons?: InputMaybe<Array<PendingStudentClassReason>>;
  states?: InputMaybe<Array<PendingStudentClassState>>;
  studentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum Permission {
  AcceptInvite = 'ACCEPT_INVITE',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  AssignLesson = 'ASSIGN_LESSON',
  AssignOwnLesson = 'ASSIGN_OWN_LESSON',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  CancelLicenseSubscription = 'CANCEL_LICENSE_SUBSCRIPTION',
  CancelOwnLicenseSubscription = 'CANCEL_OWN_LICENSE_SUBSCRIPTION',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnDiscussionThread = 'COMMENT_ON_OWN_DISCUSSION_THREAD',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  CopyCourseContentFrom = 'COPY_COURSE_CONTENT_FROM',
  CopyCourseContentTo = 'COPY_COURSE_CONTENT_TO',
  CopyOwnCourseContentFrom = 'COPY_OWN_COURSE_CONTENT_FROM',
  CopyOwnCourseContentTo = 'COPY_OWN_COURSE_CONTENT_TO',
  CopyOwnSegmentAsOwn = 'COPY_OWN_SEGMENT_AS_OWN',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  CopySegmentAsOwn = 'COPY_SEGMENT_AS_OWN',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CreateAllRubricTemplate = 'CREATE_ALL_RUBRIC_TEMPLATE',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateAssignmentTemplate = 'CREATE_ASSIGNMENT_TEMPLATE',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateClass = 'CREATE_CLASS',
  CreateClassFromOwnTemplate = 'CREATE_CLASS_FROM_OWN_TEMPLATE',
  CreateClassFromSharedTemplate = 'CREATE_CLASS_FROM_SHARED_TEMPLATE',
  CreateClassFromTemplate = 'CREATE_CLASS_FROM_TEMPLATE',
  CreateCourse = 'CREATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  CreateDiscussionThread = 'CREATE_DISCUSSION_THREAD',
  CreateDistrict = 'CREATE_DISTRICT',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateLesson = 'CREATE_LESSON',
  CreateLicense = 'CREATE_LICENSE',
  CreateLicenseSubscription = 'CREATE_LICENSE_SUBSCRIPTION',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateOwnAssignment = 'CREATE_OWN_ASSIGNMENT',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  CreateOwnDiscussionThread = 'CREATE_OWN_DISCUSSION_THREAD',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  CreatePractice = 'CREATE_PRACTICE',
  CreateSavedReport = 'CREATE_SAVED_REPORT',
  CreateSite = 'CREATE_SITE',
  CreateStudent = 'CREATE_STUDENT',
  CreateTeacher = 'CREATE_TEACHER',
  CreateUser = 'CREATE_USER',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  DeleteAnsweredQuestion = 'DELETE_ANSWERED_QUESTION',
  DeleteAnsweredQuestionContributor = 'DELETE_ANSWERED_QUESTION_CONTRIBUTOR',
  DeleteClass = 'DELETE_CLASS',
  DeleteClassGroupPreset = 'DELETE_CLASS_GROUP_PRESET',
  DeleteClassTemplate = 'DELETE_CLASS_TEMPLATE',
  DeleteComment = 'DELETE_COMMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteDiscussion = 'DELETE_DISCUSSION',
  DeleteDiscussionThread = 'DELETE_DISCUSSION_THREAD',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteLesson = 'DELETE_LESSON',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteLicenseSubscription = 'DELETE_LICENSE_SUBSCRIPTION',
  DeleteMedia = 'DELETE_MEDIA',
  DeleteOwnAnnouncement = 'DELETE_OWN_ANNOUNCEMENT',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  DeleteOwnClassGroupPreset = 'DELETE_OWN_CLASS_GROUP_PRESET',
  DeleteOwnClassSync = 'DELETE_OWN_CLASS_SYNC',
  DeleteOwnClassTemplate = 'DELETE_OWN_CLASS_TEMPLATE',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  DeleteOwnDirectMessageThread = 'DELETE_OWN_DIRECT_MESSAGE_THREAD',
  DeleteOwnDiscussion = 'DELETE_OWN_DISCUSSION',
  DeleteOwnDiscussionThread = 'DELETE_OWN_DISCUSSION_THREAD',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  DeleteOwnLesson = 'DELETE_OWN_LESSON',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  DeleteOwnLicenseSubscription = 'DELETE_OWN_LICENSE_SUBSCRIPTION',
  DeleteOwnMedia = 'DELETE_OWN_MEDIA',
  DeleteOwnPractice = 'DELETE_OWN_PRACTICE',
  DeleteOwnRubricTemplate = 'DELETE_OWN_RUBRIC_TEMPLATE',
  DeleteOwnSavedReport = 'DELETE_OWN_SAVED_REPORT',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  DeletePractice = 'DELETE_PRACTICE',
  DeleteSavedReport = 'DELETE_SAVED_REPORT',
  DeleteSegment = 'DELETE_SEGMENT',
  DeleteSite = 'DELETE_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteStandardSet = 'DELETE_STANDARD_SET',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteTag = 'DELETE_TAG',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  DeleteTrialRequestCampaign = 'DELETE_TRIAL_REQUEST_CAMPAIGN',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteWidgetTemplate = 'DELETE_WIDGET_TEMPLATE',
  DownloadLesson = 'DOWNLOAD_LESSON',
  DownloadOwnLesson = 'DOWNLOAD_OWN_LESSON',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  ForceResyncAssignmentSync = 'FORCE_RESYNC_ASSIGNMENT_SYNC',
  ForceResyncMaterialSync = 'FORCE_RESYNC_MATERIAL_SYNC',
  ForceResyncOwnAssignmentSync = 'FORCE_RESYNC_OWN_ASSIGNMENT_SYNC',
  ForceResyncOwnMaterialSync = 'FORCE_RESYNC_OWN_MATERIAL_SYNC',
  FreeUsername = 'FREE_USERNAME',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  GeneratePdf = 'GENERATE_PDF',
  GetPageMetadata = 'GET_PAGE_METADATA',
  GradeAssignmentSubmission = 'GRADE_ASSIGNMENT_SUBMISSION',
  GradeOwnAssignmentSubmission = 'GRADE_OWN_ASSIGNMENT_SUBMISSION',
  Impersonate = 'IMPERSONATE',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  InviteStudent = 'INVITE_STUDENT',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  JoinClass = 'JOIN_CLASS',
  ListAnsweredQuestions = 'LIST_ANSWERED_QUESTIONS',
  ListBadges = 'LIST_BADGES',
  ListBadgeUsers = 'LIST_BADGE_USERS',
  ListClass = 'LIST_CLASS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ListClassLicenseSubscriptions = 'LIST_CLASS_LICENSE_SUBSCRIPTIONS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  ListClassSyncStudents = 'LIST_CLASS_SYNC_STUDENTS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListClassTemplates = 'LIST_CLASS_TEMPLATES',
  ListComments = 'LIST_COMMENTS',
  ListCourse = 'LIST_COURSE',
  ListCourseViews = 'LIST_COURSE_VIEWS',
  ListDistrict = 'LIST_DISTRICT',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  ListInteractions = 'LIST_INTERACTIONS',
  ListLesson = 'LIST_LESSON',
  ListLessonPractices = 'LIST_LESSON_PRACTICES',
  ListLicenses = 'LIST_LICENSES',
  ListLicenseSubscriptions = 'LIST_LICENSE_SUBSCRIPTIONS',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListOwnClassLicenseSubscriptions = 'LIST_OWN_CLASS_LICENSE_SUBSCRIPTIONS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListOwnClassSyncStudents = 'LIST_OWN_CLASS_SYNC_STUDENTS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ListOwnLessonPractices = 'LIST_OWN_LESSON_PRACTICES',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  ListOwnStudentLessons = 'LIST_OWN_STUDENT_LESSONS',
  ListOwnStudentPractices = 'LIST_OWN_STUDENT_PRACTICES',
  ListOwnTeacherLessons = 'LIST_OWN_TEACHER_LESSONS',
  ListOwnTeacherPractices = 'LIST_OWN_TEACHER_PRACTICES',
  ListOwnUserBadges = 'LIST_OWN_USER_BADGES',
  ListOwnUserLicenseSubscriptions = 'LIST_OWN_USER_LICENSE_SUBSCRIPTIONS',
  ListPractice = 'LIST_PRACTICE',
  ListRawClassSyncProviders = 'LIST_RAW_CLASS_SYNC_PROVIDERS',
  ListSegments = 'LIST_SEGMENTS',
  ListSharedLessonPractices = 'LIST_SHARED_LESSON_PRACTICES',
  ListSite = 'LIST_SITE',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListStudent = 'LIST_STUDENT',
  ListStudentLessons = 'LIST_STUDENT_LESSONS',
  ListStudentPractices = 'LIST_STUDENT_PRACTICES',
  ListTags = 'LIST_TAGS',
  ListTeacher = 'LIST_TEACHER',
  ListTeacherLessons = 'LIST_TEACHER_LESSONS',
  ListTeacherPractices = 'LIST_TEACHER_PRACTICES',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ListUser = 'LIST_USER',
  ListUserBadges = 'LIST_USER_BADGES',
  ListUserLicenseSubscriptions = 'LIST_USER_LICENSE_SUBSCRIPTIONS',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  Login = 'LOGIN',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ManageAnsweredQuestions = 'MANAGE_ANSWERED_QUESTIONS',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageAssignmentCategories = 'MANAGE_ASSIGNMENT_CATEGORIES',
  ManageAssignmentClassConfiguration = 'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageAssignmentTemplates = 'MANAGE_ASSIGNMENT_TEMPLATES',
  ManageBadges = 'MANAGE_BADGES',
  ManageBanner = 'MANAGE_BANNER',
  ManageClassGroupPresets = 'MANAGE_CLASS_GROUP_PRESETS',
  ManageClassSegmentEnabled = 'MANAGE_CLASS_SEGMENT_ENABLED',
  ManageClassSegmentLocked = 'MANAGE_CLASS_SEGMENT_LOCKED',
  ManageClassSegmentStructure = 'MANAGE_CLASS_SEGMENT_STRUCTURE',
  ManageCourseViews = 'MANAGE_COURSE_VIEWS',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageLogs = 'MANAGE_LOGS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  ManageOwnAssignmentCategories = 'MANAGE_OWN_ASSIGNMENT_CATEGORIES',
  ManageOwnAssignmentClassConfiguration = 'MANAGE_OWN_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageOwnClassGroupPresets = 'MANAGE_OWN_CLASS_GROUP_PRESETS',
  ManageOwnClassSegmentEnabled = 'MANAGE_OWN_CLASS_SEGMENT_ENABLED',
  ManageOwnClassSegmentLocked = 'MANAGE_OWN_CLASS_SEGMENT_LOCKED',
  ManageOwnClassSegmentStructure = 'MANAGE_OWN_CLASS_SEGMENT_STRUCTURE',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  ManageOwnPendingClassStudents = 'MANAGE_OWN_PENDING_CLASS_STUDENTS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ManagePendingClassStudents = 'MANAGE_PENDING_CLASS_STUDENTS',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageStandardSets = 'MANAGE_STANDARD_SETS',
  ManageTrialRequestCampaigns = 'MANAGE_TRIAL_REQUEST_CAMPAIGNS',
  ManageWidgetTemplates = 'MANAGE_WIDGET_TEMPLATES',
  MessageOwnUser = 'MESSAGE_OWN_USER',
  MessageUser = 'MESSAGE_USER',
  ModerateComments = 'MODERATE_COMMENTS',
  MoveTeacher = 'MOVE_TEACHER',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  RefreshMediaUrl = 'REFRESH_MEDIA_URL',
  RefreshOwnMediaUrl = 'REFRESH_OWN_MEDIA_URL',
  Register = 'REGISTER',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  RequestInformationForClassSyncSetup = 'REQUEST_INFORMATION_FOR_CLASS_SYNC_SETUP',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  RequestTrial = 'REQUEST_TRIAL',
  ResetAssignmentSubmission = 'RESET_ASSIGNMENT_SUBMISSION',
  ResetOwnAssignmentSubmission = 'RESET_OWN_ASSIGNMENT_SUBMISSION',
  ResetPassword = 'RESET_PASSWORD',
  RestoreAnsweredQuestion = 'RESTORE_ANSWERED_QUESTION',
  RestoreAnsweredQuestionContributor = 'RESTORE_ANSWERED_QUESTION_CONTRIBUTOR',
  RestoreClass = 'RESTORE_CLASS',
  RestoreClassGroupPreset = 'RESTORE_CLASS_GROUP_PRESET',
  RestoreClassTemplate = 'RESTORE_CLASS_TEMPLATE',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreDiscussion = 'RESTORE_DISCUSSION',
  RestoreDiscussionThread = 'RESTORE_DISCUSSION_THREAD',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreLesson = 'RESTORE_LESSON',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreLicenseSubscription = 'RESTORE_LICENSE_SUBSCRIPTION',
  RestoreMedia = 'RESTORE_MEDIA',
  RestoreOwnAnnouncement = 'RESTORE_OWN_ANNOUNCEMENT',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  RestoreOwnClassGroupPreset = 'RESTORE_OWN_CLASS_GROUP_PRESET',
  RestoreOwnClassTemplate = 'RESTORE_OWN_CLASS_TEMPLATE',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  RestoreOwnDiscussion = 'RESTORE_OWN_DISCUSSION',
  RestoreOwnDiscussionThread = 'RESTORE_OWN_DISCUSSION_THREAD',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  RestoreOwnLesson = 'RESTORE_OWN_LESSON',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  RestoreOwnLicenseSubscription = 'RESTORE_OWN_LICENSE_SUBSCRIPTION',
  RestoreOwnMedia = 'RESTORE_OWN_MEDIA',
  RestoreOwnPractice = 'RESTORE_OWN_PRACTICE',
  RestoreOwnRubricTemplate = 'RESTORE_OWN_RUBRIC_TEMPLATE',
  RestoreOwnSavedReport = 'RESTORE_OWN_SAVED_REPORT',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  RestorePractice = 'RESTORE_PRACTICE',
  RestoreSavedReport = 'RESTORE_SAVED_REPORT',
  RestoreSegment = 'RESTORE_SEGMENT',
  RestoreSite = 'RESTORE_SITE',
  RestoreStandardSet = 'RESTORE_STANDARD_SET',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreTag = 'RESTORE_TAG',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RestoreTrialRequestCampaign = 'RESTORE_TRIAL_REQUEST_CAMPAIGN',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreWidgetTemplate = 'RESTORE_WIDGET_TEMPLATE',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  RosterStudentsFromClassSync = 'ROSTER_STUDENTS_FROM_CLASS_SYNC',
  RosterStudentsFromOwnClassSync = 'ROSTER_STUDENTS_FROM_OWN_CLASS_SYNC',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  RunReport = 'RUN_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunStudentReport = 'RUN_STUDENT_REPORT',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  SaveClassTemplate = 'SAVE_CLASS_TEMPLATE',
  SaveOwnAssignmentSubmission = 'SAVE_OWN_ASSIGNMENT_SUBMISSION',
  SaveOwnClassTemplate = 'SAVE_OWN_CLASS_TEMPLATE',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  Search = 'SEARCH',
  SearchAnsweredQuestions = 'SEARCH_ANSWERED_QUESTIONS',
  SearchAnsweredQuestionContributors = 'SEARCH_ANSWERED_QUESTION_CONTRIBUTORS',
  SearchBadges = 'SEARCH_BADGES',
  SearchCourses = 'SEARCH_COURSES',
  SearchDistricts = 'SEARCH_DISTRICTS',
  SearchSites = 'SEARCH_SITES',
  SearchUsers = 'SEARCH_USERS',
  ShareNode = 'SHARE_NODE',
  ShareOwnNode = 'SHARE_OWN_NODE',
  SubmitAnsweredQuestion = 'SUBMIT_ANSWERED_QUESTION',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitMedia = 'SUBMIT_MEDIA',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  SubmitOwnMedia = 'SUBMIT_OWN_MEDIA',
  SyncAssignment = 'SYNC_ASSIGNMENT',
  SyncOwnAssignment = 'SYNC_OWN_ASSIGNMENT',
  SyncOwnSegment = 'SYNC_OWN_SEGMENT',
  SyncSegment = 'SYNC_SEGMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  UpdateAssignmentTemplate = 'UPDATE_ASSIGNMENT_TEMPLATE',
  UpdateClass = 'UPDATE_CLASS',
  UpdateClassSettings = 'UPDATE_CLASS_SETTINGS',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateCourse = 'UPDATE_COURSE',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  UpdateDiscussionThread = 'UPDATE_DISCUSSION_THREAD',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateLesson = 'UPDATE_LESSON',
  UpdateLicense = 'UPDATE_LICENSE',
  UpdateLicenseSubscription = 'UPDATE_LICENSE_SUBSCRIPTION',
  UpdateMedia = 'UPDATE_MEDIA',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateOwnAssignmentTemplate = 'UPDATE_OWN_ASSIGNMENT_TEMPLATE',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  UpdateOwnClassSettings = 'UPDATE_OWN_CLASS_SETTINGS',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  UpdateOwnDiscussionThread = 'UPDATE_OWN_DISCUSSION_THREAD',
  UpdateOwnLesson = 'UPDATE_OWN_LESSON',
  UpdateOwnMedia = 'UPDATE_OWN_MEDIA',
  UpdateOwnPractice = 'UPDATE_OWN_PRACTICE',
  UpdateOwnSavedReport = 'UPDATE_OWN_SAVED_REPORT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdatePractice = 'UPDATE_PRACTICE',
  UpdateSavedReport = 'UPDATE_SAVED_REPORT',
  UpdateSite = 'UPDATE_SITE',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateTags = 'UPDATE_TAGS',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  UpdateUser = 'UPDATE_USER',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  UploadMedia = 'UPLOAD_MEDIA',
  UseInReport = 'USE_IN_REPORT',
  UseOwnInReport = 'USE_OWN_IN_REPORT',
  UsePracticeIsViewed = 'USE_PRACTICE_IS_VIEWED',
  ViewAllRubricTemplates = 'VIEW_ALL_RUBRIC_TEMPLATES',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  ViewAnsweredQuestion = 'VIEW_ANSWERED_QUESTION',
  ViewAnsweredQuestionContributor = 'VIEW_ANSWERED_QUESTION_CONTRIBUTOR',
  ViewAssignmentAnswers = 'VIEW_ASSIGNMENT_ANSWERS',
  ViewAssignmentReport = 'VIEW_ASSIGNMENT_REPORT',
  ViewAssignmentSync = 'VIEW_ASSIGNMENT_SYNC',
  ViewAssignmentTemplate = 'VIEW_ASSIGNMENT_TEMPLATE',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewClass = 'VIEW_CLASS',
  ViewClassGroupPreset = 'VIEW_CLASS_GROUP_PRESET',
  ViewClassIdsWithSegment = 'VIEW_CLASS_IDS_WITH_SEGMENT',
  ViewClassPractices = 'VIEW_CLASS_PRACTICES',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  ViewClassSyncHistory = 'VIEW_CLASS_SYNC_HISTORY',
  ViewClassTemplate = 'VIEW_CLASS_TEMPLATE',
  ViewComment = 'VIEW_COMMENT',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewDiscussion = 'VIEW_DISCUSSION',
  ViewDiscussionThread = 'VIEW_DISCUSSION_THREAD',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewLesson = 'VIEW_LESSON',
  ViewLessonAssignments = 'VIEW_LESSON_ASSIGNMENTS',
  ViewLessonStudentViews = 'VIEW_LESSON_STUDENT_VIEWS',
  ViewLicense = 'VIEW_LICENSE',
  ViewLicenseSubscription = 'VIEW_LICENSE_SUBSCRIPTION',
  ViewMaterialSync = 'VIEW_MATERIAL_SYNC',
  ViewMedia = 'VIEW_MEDIA',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnAnnouncement = 'VIEW_OWN_ANNOUNCEMENT',
  ViewOwnAssignment = 'VIEW_OWN_ASSIGNMENT',
  ViewOwnAssignmentReport = 'VIEW_OWN_ASSIGNMENT_REPORT',
  ViewOwnAssignmentSubmission = 'VIEW_OWN_ASSIGNMENT_SUBMISSION',
  ViewOwnAssignmentSync = 'VIEW_OWN_ASSIGNMENT_SYNC',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  ViewOwnClassGroupPreset = 'VIEW_OWN_CLASS_GROUP_PRESET',
  ViewOwnClassPractices = 'VIEW_OWN_CLASS_PRACTICES',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewOwnClassSync = 'VIEW_OWN_CLASS_SYNC',
  ViewOwnClassSyncHistory = 'VIEW_OWN_CLASS_SYNC_HISTORY',
  ViewOwnClassTemplate = 'VIEW_OWN_CLASS_TEMPLATE',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewOwnCourse = 'VIEW_OWN_COURSE',
  ViewOwnDirectMessageThread = 'VIEW_OWN_DIRECT_MESSAGE_THREAD',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ViewOwnDiscussion = 'VIEW_OWN_DISCUSSION',
  ViewOwnDiscussionThread = 'VIEW_OWN_DISCUSSION_THREAD',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewOwnLesson = 'VIEW_OWN_LESSON',
  ViewOwnLessonAssignments = 'VIEW_OWN_LESSON_ASSIGNMENTS',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  ViewOwnLicenseSubscription = 'VIEW_OWN_LICENSE_SUBSCRIPTION',
  ViewOwnMaterialSync = 'VIEW_OWN_MATERIAL_SYNC',
  ViewOwnMedia = 'VIEW_OWN_MEDIA',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ViewOwnPractice = 'VIEW_OWN_PRACTICE',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewOwnRubricTemplate = 'VIEW_OWN_RUBRIC_TEMPLATE',
  ViewOwnSavedReport = 'VIEW_OWN_SAVED_REPORT',
  ViewOwnSegment = 'VIEW_OWN_SEGMENT',
  ViewOwnSite = 'VIEW_OWN_SITE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ViewOwnTag = 'VIEW_OWN_TAG',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserMedia = 'VIEW_OWN_USER_MEDIA',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  ViewOwnUseInUnownedClasses = 'VIEW_OWN_USE_IN_UNOWNED_CLASSES',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ViewPractice = 'VIEW_PRACTICE',
  ViewQuestionSegment = 'VIEW_QUESTION_SEGMENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewSavedReport = 'VIEW_SAVED_REPORT',
  ViewSegment = 'VIEW_SEGMENT',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  ViewSharedClassTemplate = 'VIEW_SHARED_CLASS_TEMPLATE',
  ViewSharedLesson = 'VIEW_SHARED_LESSON',
  ViewSharedPractice = 'VIEW_SHARED_PRACTICE',
  ViewSite = 'VIEW_SITE',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ViewStandardSet = 'VIEW_STANDARD_SET',
  ViewStudent = 'VIEW_STUDENT',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewTag = 'VIEW_TAG',
  ViewTeacher = 'VIEW_TEACHER',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  ViewTrialRequestCampaign = 'VIEW_TRIAL_REQUEST_CAMPAIGN',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserMedia = 'VIEW_USER_MEDIA',
  ViewUserName = 'VIEW_USER_NAME',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewUseInUnownedClasses = 'VIEW_USE_IN_UNOWNED_CLASSES',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewWidgetTemplate = 'VIEW_WIDGET_TEMPLATE'
}

export type PracticeInput = {
  /** Optionally the class you would like to associate the practice with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  lessonId?: InputMaybe<Scalars['ID']['input']>;
  media: MediaUploadUrlInput;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PracticePatch = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PreferencesInput = {
  /** The name of the channels to disable. */
  disabledChannels: Array<Scalars['String']['input']>;
  /** The identifier for the type of notification. */
  notificationType: Scalars['String']['input'];
};

export type PrepareCourseViewSampleSpreadsheetInput = {
  /** The ID of the course view to prepare the spreadsheet for. */
  courseViewId: Scalars['ID']['input'];
};

export type PublishCourseVersionInput = {
  id: Scalars['ID']['input'];
};

export type ReadNotificationInput = {
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};

export type ReadNotificationPathInput = {
  read: Scalars['Boolean']['input'];
  universalPath: Scalars['String']['input'];
};

export type ReadNotificationsInput = {
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: InputMaybe<Scalars['Boolean']['input']>;
  /** The notifications to update. */
  notifications?: InputMaybe<Array<ReadNotificationInput>>;
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: InputMaybe<Array<ReadNotificationPathInput>>;
};

export type RecordCanvasCredentialsInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  token: Scalars['String']['input'];
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String']['input'];
};

export type RecordInteractionsInput = {
  interactions: Array<InteractionInput>;
};

export type RecordMicrosoftTenantInput = {
  tenantId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RefreshMediaUploadUrlInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RejectCommentInput = {
  id: Scalars['ID']['input'];
  /** The reason the comment was rejected. */
  reason: Scalars['String']['input'];
};

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID']['input'];
  /** An array of user IDs to remove from the thread. */
  userIds: Array<Scalars['ID']['input']>;
};

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID']['input'];
  /** The ID of the student to remove. */
  studentId: Scalars['ID']['input'];
};

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID']['input'];
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID']['input'];
};

export type ReportDimensionInput = {
  classes?: InputMaybe<ClassesReportDimensionInput>;
  segment?: InputMaybe<SegmentReportDimensionInput>;
  students?: InputMaybe<StudentsReportDimensionInput>;
};

export type ReportFilterInput = {
  isLeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportMetricInput = {
  contentVisited?: InputMaybe<ContentVisitedReportMetricInput>;
  grade?: InputMaybe<GradeReportMetricInput>;
  numAttempts?: InputMaybe<NumAttemptsMetricInput>;
  timeOnTask?: InputMaybe<TimeOnTaskReportMetricInput>;
};

export type ReportStandardsSummaryInput = {
  standardSetId: Scalars['ID']['input'];
};

export type RequestInformationForClassSyncSetupInput = {
  /** The identifier for the class sync provider to request information for. */
  providerIdentifier: Scalars['String']['input'];
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the class to reset submissions for, optionally. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID']['input'];
  /**
   * If this is true, the latest submission (with its answers) is preserved.
   * The grade will still be reset, the created date will be reset to now, and the
   * submitted status will be reset.
   */
  preserveLatestSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentInput = {
  /** The ID of the assignment to reset. */
  id: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentSubmissionInput = {
  /** The ID of the class associated with the submission. */
  classId: Scalars['ID']['input'];
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the student associated with the submission. */
  studentId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ResolveDuplicateUserPendingStudentClassInput = {
  /** The ID of the PendingStudentClass record. */
  id: Scalars['ID']['input'];
  /** The resolution to apply. */
  resolution: DuplicateUserPendingStudentClassResolution;
};

export type RetrySubmissionInput = {
  /** The ID of the class to retry the submission in. */
  classId: Scalars['ID']['input'];
  /** The ID of the Assignment */
  id: Scalars['ID']['input'];
};

export enum RevealAnswers {
  /** Answers are revealed to students after they are finished, or after revealAttempts. */
  AfterSubmit = 'AFTER_SUBMIT',
  /** Answers are revealed to students in real time. */
  RealTime = 'REAL_TIME'
}

export type RosterStudentsFromClassSyncInput = {
  actions: Array<ClassSyncRosterActionInput>;
  /** The ID of the class sync to roster students from. */
  id: Scalars['ID']['input'];
};

export type RubricTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
};

export type RunGradeReportInput = {
  /** Optional Class IDs to filter on. */
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Optional if we want to format based on points instead of percentages. */
  showAsPoints?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RunReportInput = {
  /** Any dimensions to filter on inside the report. */
  dimensions: Array<ReportDimensionInput>;
  /** Any filters to apply to the report. */
  filters?: InputMaybe<Array<ReportFilterInput>>;
  /** Any metrics to display inside the report. */
  metrics: Array<ReportMetricInput>;
};

/**
 * Input required for creating or updating assignment submissions.
 * These are used by both teachers and students.
 */
export type SaveAssignmentSubmissionInput = {
  /** The ID of the class to create or update the submission for. */
  classId: Scalars['ID']['input'];
  /** If this is true, a new submission will be created instead of updating the existing one. */
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment to save submission data for. */
  id: Scalars['ID']['input'];
  /** Any overrides to save back to the submission. */
  overrides?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the student (defaults to the current user). */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** The submission data to save. */
  submissionData: Scalars['JSON']['input'];
  /** Whether or not to immediately submit after saving. */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is specified, this will attempt to update the specified submission ID instead. */
  updateId?: InputMaybe<Scalars['ID']['input']>;
};

export type SaveClassTemplateInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the class to save as a template. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SchoologyTokenInput = {
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<ClassStudentInput>>;
  tags?: InputMaybe<TagsFilter>;
};

export type SegmentCompletionInput = {
  /** The new complete status of these segments. */
  complete: Scalars['Boolean']['input'];
  /** The IDs of the segments to update, in GraphQL format. */
  segmentIds: Array<Scalars['ID']['input']>;
  /** The IDs of the students to update with these segment IDs, in GraphQL format. */
  studentIds: Array<Scalars['ID']['input']>;
};

export type SegmentInput = {
  /** The configuration for the segment. */
  config?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the segment. */
  id: Scalars['ID']['input'];
  /** The index for the segment. */
  index?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the segment. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of segment to create or update. */
  type?: InputMaybe<SegmentType>;
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
  /** The new tags to set for the segment. */
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
};

export type SegmentReportDimensionInput = {
  /** This is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SegmentType {
  /** eslint-disable-next-line @graphql-eslint/naming-convention */
  Assignment = 'Assignment',
  Group = 'Group',
  Reference = 'Reference',
  Root = 'Root',
  Section = 'Section',
  Term = 'Term',
  Topic = 'Topic'
}

export type SegmentsFilters = {
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
  types?: InputMaybe<Array<SegmentType>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShareNodeInput = {
  /** The ID of the node to share or unshare. */
  id: Scalars['ID']['input'];
  /** For sharing, whether this list of user ids will replace the existing shares. */
  replace?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the users to share or unshare with. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum SharingType {
  IveShared = 'IVE_SHARED',
  Own = 'OWN',
  SharedWithMe = 'SHARED_WITH_ME'
}

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String']['input'];
  /** Whether or not the upload should be public. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The mime-type of the file. */
  mimeType: Scalars['String']['input'];
  /** An optional namespace to prefix the file with. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The request mode to use when uploading. */
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type SiteConfigurationInput = {
  /** Pass providers here to override their configuration. */
  classSyncProviders?: InputMaybe<Array<ClassSyncProviderConfigurationInput>>;
  defaultClassConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  /** The standard set IDs enabled for this site. */
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<SiteConfigurationInput>;
  disableCourseCodes?: InputMaybe<Scalars['Boolean']['input']>;
  districtId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<AccountRegion>;
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SiteWideBannerInput = {
  message: Scalars['String']['input'];
  /** The user groups the message should show up for. */
  userGroups: Array<Scalars['String']['input']>;
};

export type StandardSetTagInput = {
  name: Scalars['String']['input'];
  parents?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type StartStandardSetImportInput = {
  csvPath: Scalars['String']['input'];
  standardSetId: Scalars['ID']['input'];
};

export type StoreVodCaptionInput = {
  /** The WebVTT contents of the caption file. */
  fileContents: Scalars['String']['input'];
  /** The language code for the caption file. */
  language: Scalars['String']['input'];
};

export type StoreVodCaptionsInput = {
  /** The new captions to store (they will be added to the existing ones) */
  captions: Array<StoreVodCaptionInput>;
  /** The ID of the Video on Demand to store captions for. */
  id: Scalars['ID']['input'];
};

export type StudentFilterInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type StudentInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type StudentReportInput = {
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID']['input'];
  /** The ID of the student to run the report on. */
  id: Scalars['ID']['input'];
};

export enum StudentReportResultType {
  Assignment = 'ASSIGNMENT',
  Section = 'SECTION'
}

export type StudentsReportDimensionInput = {
  studentPairs: Array<ClassStudentInput>;
};

export enum SubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL'
}

export type SubmitAnsweredQuestionInput = {
  /** The question being asked. */
  name: Scalars['String']['input'];
};

export type SubmitAssignmentInput = {
  /** Whether or not to force the submission. */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID']['input'];
  /** Any submission data to add. */
  submissionData?: InputMaybe<Scalars['JSON']['input']>;
};

export type SubmitMediaInput = {
  id: Scalars['ID']['input'];
};

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID']['input'];
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SyncSegmentInput = {
  /** The global segment ID (not the raw one). */
  id: Scalars['ID']['input'];
  /** The class syncs to use to sync this assignment. */
  syncIds: Array<Scalars['ID']['input']>;
};

export type TagConfigInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

export type TagFilterInput = {
  /** The name of the tag. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The path of parent tags to find all children for. */
  path?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The type of tag. */
  type: Scalars['String']['input'];
};

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type TagFilters = {
  /** Whether or not to filter on tags that have content available in the current user's license. */
  inLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TagsFilter = {
  operation: TagFilterOperation;
  tags: Array<TagFilterInput>;
};

export type TeacherInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  noEmail?: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: InputMaybe<Scalars['String']['input']>;
};

export type TimeOnTaskReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY'
}

export type TrialRequestCampaignInput = {
  /** The campaign name */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The target path for the campaign */
  targetPath?: InputMaybe<Scalars['String']['input']>;
  /** The welcome message */
  welcomeMessage?: InputMaybe<TrialRequestCampaignWelcomeMessageInput>;
};

export type TrialRequestCampaignWelcomeMessageInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum TrialRequestStatus {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
  Ignored = 'IGNORED',
  Open = 'OPEN'
}

export type TrialSignupInput = {
  /** The identifier for the trial request campaign to associate. */
  campaign?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siteName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID']['input'];
};

export type UnlikeNodeInput = {
  /** The ID of the node to unlike. */
  id: Scalars['ID']['input'];
};

export type UnlinkAccountInput = {
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The user's specific ID related to the provider. */
  providerId: Scalars['String']['input'];
};

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID']['input'];
  patch: AnnouncementInput;
};

export type UpdateAnsweredQuestionContributorInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionContributorInput;
};

export type UpdateAnsweredQuestionInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionInput;
};

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID']['input'];
  patch: AssignmentCategoryInput;
};

export type UpdateAssignmentClassConfigurationInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentClassConfigurationInput;
};

export type UpdateAssignmentInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the assignment to update. */
  id: Scalars['ID']['input'];
  patch: AssignmentInput;
};

export type UpdateAssignmentTemplateInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentTemplateInput;
};

export type UpdateBadgeInput = {
  id: Scalars['ID']['input'];
  patch: BadgeInput;
};

export type UpdateClassGroupPresetInput = {
  id: Scalars['ID']['input'];
  patch: ClassGroupPresetInput;
};

export type UpdateClassInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  patch: ClassInput;
  segmentMetadataChanges?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID']['input'];
  patch: CommentInput;
};

export type UpdateCourseInput = {
  id: Scalars['ID']['input'];
  patch: CourseInput;
};

export type UpdateCourseVersionInput = {
  id: Scalars['ID']['input'];
  patch: CourseVersionInput;
};

export type UpdateDiscussionThreadInput = {
  /** The ID of the discussion thread to update. */
  id: Scalars['ID']['input'];
  patch: DiscussionThreadInput;
};

export type UpdateDistrictInput = {
  id: Scalars['ID']['input'];
  patch: DistrictInput;
};

export type UpdateLessonInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<LessonInput>;
};

export type UpdateLicenseInput = {
  id: Scalars['ID']['input'];
  noEmail?: InputMaybe<Scalars['Boolean']['input']>;
  patch: LicenseInput;
};

export type UpdateLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
  patch: LicenseSubscriptionInput;
};

export type UpdateMediaInput = {
  id: Scalars['ID']['input'];
  patch: MediaInput;
};

export type UpdateNodeTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdatePracticeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<PracticePatch>;
};

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: InputMaybe<Scalars['String']['input']>;
  /** The user's current preferences. */
  preferences: Array<PreferencesInput>;
};

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID']['input'];
  patch: RubricTemplateInput;
};

export type UpdateSavedReportInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  report?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSegmentCompletionInput = {
  /** The ID of the class to update the completion in. */
  id: Scalars['ID']['input'];
  /** The completion updates to perform. */
  updates: Array<SegmentCompletionInput>;
};

export type UpdateSiteInput = {
  id: Scalars['ID']['input'];
  patch: SiteInput;
};

export type UpdateSiteWideBannerInput = {
  message?: InputMaybe<SiteWideBannerInput>;
};

export type UpdateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type UpdateStudentInput = {
  id: Scalars['ID']['input'];
  patch: StudentInput;
};

export type UpdateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  /** The ID of the tag to update. */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID']['input'];
  /** The new permissions to assign to the teacher. */
  permissions: Array<Scalars['String']['input']>;
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID']['input'];
};

export type UpdateTeacherInput = {
  id: Scalars['ID']['input'];
  patch: TeacherInput;
};

export type UpdateTrialRequestCampaignInput = {
  id: Scalars['ID']['input'];
  patch: TrialRequestCampaignInput;
};

export type UpdateUserDetailedLoggingInput = {
  /** Whether or not to enable detailed logging for this user. */
  enabled: Scalars['Boolean']['input'];
  /** The ID of the user to update. */
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  /** If this is passed, the user's last activity is updated to the current time. */
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID']['input'];
  patch: VideoOnDemandInput;
};

export type UpdateWidgetTemplateInput = {
  id: Scalars['ID']['input'];
  patch: WidgetTemplateInput;
};

export enum UploadRequestMode {
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA'
}

export type UseAssignmentSubmissionInput = {
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the submission to use. */
  submissionId: Scalars['ID']['input'];
};

export enum UserGroup {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  ContentManager = 'CONTENT_MANAGER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserTasksFilterInput = {
  /** An optional class ID to filter the user tasks on. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoOnDemandCaptionStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type VideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnailData?: InputMaybe<Scalars['JSON']['input']>;
};

export enum VideoOnDemandStatus {
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING'
}

export type WidgetTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type AssignmentTemplateFragment = (
  {
  id: string,
  name: string,
  configuration?: any | null,
  defaults?: any | null,
  createdAt: any,
  updatedAt: any
}
  & {
  __typename: 'AssignmentTemplate'
}
);

export type BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory_presets_AssignmentRubricPreset = (
  {
  points: number,
  name: string,
  description?: string | null,
  weight: number,
  presetId: string
}
  & {
  __typename?: 'AssignmentRubricPreset'
}
);

export type BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory = (
  {
  name?: string | null,
  description?: string | null,
  totalPoints: number,
  weight?: number | null,
  categoryId: string,
  presets?: Array<BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory_presets_AssignmentRubricPreset> | null
}
  & {
  __typename?: 'AssignmentRubricCategory'
}
);

export type BasicAssignmentFragment_Assignment_rubric_AssignmentRubric = (
  {
  categories: Array<BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory>
}
  & {
  __typename?: 'AssignmentRubric'
}
);

export type BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge = (
  {
  node: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoriesEdge'
}
);

export type BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection = (
  {
  edges: Array<BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge>
}
  & {
  __typename?: 'AssignmentCategoriesConnection'
}
);

export type BasicAssignmentFragment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentGradesByCategoryFragment_GradesByCategory_categories_GradesByCategoryCategory = (
  {
  categoryId: string,
  averageGrade: number
}
  & {
  __typename?: 'GradesByCategoryCategory'
}
);

export type AssignmentGradesByCategoryFragment = (
  {
  categories: Array<AssignmentGradesByCategoryFragment_GradesByCategory_categories_GradesByCategoryCategory>
}
  & {
  __typename?: 'GradesByCategory'
}
);

export type RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric = (
  {
  categories: Array<BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory>
}
  & {
  __typename?: 'AssignmentRubric'
}
);

export type RubricTemplateFragment = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type AssignmentRubricFragment = (
  {
  categories: Array<BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory>
}
  & {
  __typename?: 'AssignmentRubric'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection = (
  {
  edges: Array<AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync = (
  {
  id: string,
  provider: string,
  providerId: string,
  classSync: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_classSync_ClassSync,
  latestAttempt?: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'AssignmentSync'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge = (
  {
  node: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync
}
  & {
  __typename?: 'AssignmentSyncsEdge'
}
);

export type AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection = (
  {
  edges: Array<AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge>
}
  & {
  __typename?: 'AssignmentSyncsConnection'
}
);

export type AssignmentWithSyncFragment = (
  {
  id: string,
  canSync: boolean,
  syncs: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentWithRemainingAttemptsFragment = (
  {
  id: string,
  remainingAttempts?: number | null,
  startedAt?: any | null,
  submittedAt?: any | null
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentWithConfigurationFragment = (
  {
  id: string,
  supportedFeatures: Array<AssignmentFeature>,
  configuration?: any | null,
  autoGrading?: AssignmentAutoGradeMode | null
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentWithGroupsFragment = (
  {
  id: string,
  groups?: any | null
}
  & {
  __typename?: 'Assignment'
}
);

export type BasicAssignmentCategoryFragment = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type AssignmentCategoryFragment_AssignmentCategory_totalAssignments_AssignmentsConnection = (
  {
  totalCount: number
}
  & {
  __typename?: 'AssignmentsConnection'
}
);

export type AssignmentCategoryFragment = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number,
  totalAssignments: AssignmentCategoryFragment_AssignmentCategory_totalAssignments_AssignmentsConnection
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string
}
  & {
  __typename?: 'User'
}
);

export type AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge_node_Student = (
  {
  id: string,
  user: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge = (
  {
  isMissing: boolean,
  forfeitAttempts?: boolean | null,
  classId: string,
  node: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge_node_Student
}
  & {
  __typename?: 'AssignmentStudentsEdge'
}
);

export type AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection = (
  {
  edges: Array<AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection_edges_AssignmentStudentsEdge>
}
  & {
  __typename?: 'AssignmentStudentsConnection'
}
);

export type AssignmentAssignedStudentsFragment = (
  {
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  averageGrade?: number | null,
  averagePoints?: number | null,
  edges: Array<AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  submissionData?: any | null,
  grades?: any | null,
  comments?: any | null,
  questionMetadata?: any | null,
  overrides?: any | null,
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection = (
  {
  totalCount: number,
  edges: Array<AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type AssignmentSubmissionsFragment = (
  {
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type BasicAssignmentSubmissionFragment = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentSubmissionWithConfigurationFragment = (
  {
  id: string,
  assignmentConfiguration?: any | null,
  overrides?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type FullAssignmentSubmissionFragment = (
  {
  submissionData?: any | null,
  grades?: any | null,
  comments?: any | null,
  questionMetadata?: any | null,
  overrides?: any | null,
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type SubmittedAssignmentSubmissionFragment = (
  {
  id: string,
  grade?: number | null,
  comments?: any | null,
  isLate?: boolean | null,
  reviewed?: boolean | null,
  submittedAt?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type ReviewAssignmentUserTaskFragment_ReviewAssignmentUserTask_payload_ReviewAssignmentUserTaskPayload = (
  {
  assignmentId: string,
  classId: string,
  excluded?: boolean | null,
  name: string,
  dueAt?: any | null
}
  & {
  __typename?: 'ReviewAssignmentUserTaskPayload'
}
);

export type ReviewAssignmentUserTaskFragment = (
  {
  identifier: string,
  completedAt?: any | null,
  date?: any | null,
  payload: ReviewAssignmentUserTaskFragment_ReviewAssignmentUserTask_payload_ReviewAssignmentUserTaskPayload
}
  & {
  __typename?: 'ReviewAssignmentUserTask'
}
);

export type CompleteAssignmentUserTaskFragment_CompleteAssignmentUserTask_payload_CompleteAssignmentUserTaskPayload = (
  {
  classId: string,
  assignmentId: string,
  assignmentName: string,
  dueAt?: any | null
}
  & {
  __typename?: 'CompleteAssignmentUserTaskPayload'
}
);

export type CompleteAssignmentUserTaskFragment = (
  {
  completedAt?: any | null,
  date?: any | null,
  identifier: string,
  payload: CompleteAssignmentUserTaskFragment_CompleteAssignmentUserTask_payload_CompleteAssignmentUserTaskPayload
}
  & {
  __typename?: 'CompleteAssignmentUserTask'
}
);

export type AssignmentSearchResultFragment_Assignment_assignmentStudents_AssignmentStudentsConnection = (
  {
  totalCount: number,
  totalGraded: number,
  totalSubmitted: number
}
  & {
  __typename?: 'AssignmentStudentsConnection'
}
);

export type AssignmentSearchResultFragment = (
  {
  id: string,
  createdAt: any,
  assignmentDue?: any | null,
  assignmentOpens?: any | null,
  assignmentTitle: string,
  assignmentStudents: AssignmentSearchResultFragment_Assignment_assignmentStudents_AssignmentStudentsConnection
}
  & {
  __typename: 'Assignment'
}
);

export type BasicClassGroupPresetFragment = (
  {
  id: string,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type ClassGroupPresetFragment = (
  {
  configuration: any,
  id: string,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type GenerateGroupModeInfoFragment = (
  {
  mode: GenerateGroupMode,
  name: string,
  description?: string | null,
  summary?: string | null
}
  & {
  __typename?: 'GenerateGroupModeInfo'
}
);

export type GenerateLeaderModeInfoFragment = (
  {
  mode: GenerateLeaderMode,
  name: string,
  description?: string | null,
  summary?: string | null
}
  & {
  __typename?: 'GenerateLeaderModeInfo'
}
);

export type AssignmentCategoryMapFragment = (
  {
  name: string,
  mappedTo?: string | null
}
  & {
  __typename?: 'AssignmentCategoryMap'
}
);

export type AssignmentSubmissionSummaryFragment_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion_answerDistribution_AssignmentSubmissionSummaryAnswerDistribution = (
  {
  answer: string,
  percentage: number
}
  & {
  __typename?: 'AssignmentSubmissionSummaryAnswerDistribution'
}
);

export type AssignmentSubmissionSummaryFragment_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion = (
  {
  id: string,
  averageGrade: number,
  answerDistribution?: Array<AssignmentSubmissionSummaryFragment_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion_answerDistribution_AssignmentSubmissionSummaryAnswerDistribution> | null
}
  & {
  __typename?: 'AssignmentSubmissionSummaryQuestion'
}
);

export type AssignmentSubmissionSummaryFragment = (
  {
  averageGrade?: number | null,
  questions: Array<AssignmentSubmissionSummaryFragment_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion>
}
  & {
  __typename?: 'AssignmentSubmissionSummary'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge_node_ClassSync = (
  {
  id: string,
  provider: string,
  providerId: string,
  label: string,
  canDelete: boolean
}
  & {
  __typename?: 'ClassSync'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge = (
  {
  node: ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge_node_ClassSync
}
  & {
  __typename?: 'ClassSyncsEdge'
}
);

export type ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection = (
  {
  edges: Array<ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection_edges_ClassSyncsEdge>
}
  & {
  __typename?: 'ClassSyncsConnection'
}
);

export type ClassWithSyncsFragment = (
  {
  id: string,
  canCreateClassSync: boolean,
  canAttachStudentAccounts: boolean,
  syncs: ClassWithSyncsFragment_Class_syncs_ClassSyncsConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassSyncFragment = (
  {
  id: string,
  provider: string,
  providerId: string,
  label: string,
  canDelete: boolean
}
  & {
  __typename?: 'ClassSync'
}
);

export type BasicClassSyncHistoryFragment = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type ListClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync = (
  {
  id: string,
  label: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type ListClassSyncHistoryFragment = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any,
  classSync: ListClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync = (
  {
  id: string
}
  & {
  __typename?: 'ClassSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_materialSync_MaterialSync = (
  {
  id: string
}
  & {
  __typename?: 'MaterialSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_assignmentSync_AssignmentSync = (
  {
  id: string
}
  & {
  __typename?: 'AssignmentSync'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistoryErrorDetail = (
  {
  userId: string,
  stack: string,
  message: string,
  name: string,
  responseData?: any | null,
  responseStatus?: string | null
}
  & {
  __typename: 'ClassSyncHistoryErrorDetail'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistorySuccessDetail = (
  {
  userId: string
}
  & {
  __typename: 'ClassSyncHistorySuccessDetail'
}
);

export type DetailedClassSyncHistoryFragment_ClassSyncHistory_detail = DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistoryErrorDetail | DetailedClassSyncHistoryFragment_ClassSyncHistory_detail_ClassSyncHistorySuccessDetail;

export type DetailedClassSyncHistoryFragment = (
  {
  startedAt: any,
  completedAt: any,
  type: ClassSyncHistoryType,
  status: ClassSyncHistoryStatus,
  logUrl?: string | null,
  id: string,
  classSync: DetailedClassSyncHistoryFragment_ClassSyncHistory_classSync_ClassSync,
  materialSync?: DetailedClassSyncHistoryFragment_ClassSyncHistory_materialSync_MaterialSync | null,
  assignmentSync?: DetailedClassSyncHistoryFragment_ClassSyncHistory_assignmentSync_AssignmentSync | null,
  detail?: DetailedClassSyncHistoryFragment_ClassSyncHistory_detail | null
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryLatestAttemptFragment = (
  {
  latestAttempt?: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename?: 'AssignmentSync' | 'ClassSync' | 'MaterialSync'
}
);

export type AssignmentSyncFragment = (
  {
  id: string,
  provider: string,
  providerId: string,
  classSync: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_classSync_ClassSync,
  latestAttempt?: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection_edges_AssignmentSyncsEdge_node_AssignmentSync_latestAttempt_ClassSyncHistoryConnection | null
}
  & {
  __typename: 'AssignmentSync'
}
);

export type GroupImportFragment = (
  {
  importId: string,
  name: string,
  createdAt?: any | null
}
  & {
  __typename?: 'GroupImport'
}
);

export type GroupImportGroupFragment = (
  {
  groupId: string,
  name: string,
  createdAt?: any | null,
  leaderStudentId?: string | null,
  memberStudentIds: Array<string>
}
  & {
  __typename?: 'GroupImportGroup'
}
);

export type GradeCategoryImportSourceFragment = (
  {
  sourceId: string,
  name: string
}
  & {
  __typename?: 'GradeCategoryImportSource'
}
);

export type ClassSyncProviderConfigurationFragment = (
  {
  providerIdentifier: string,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null
}
  & {
  __typename?: 'ClassSyncProviderConfiguration'
}
);

export type ClassSyncSiteConfigurationFragment_SiteConfiguration_classSyncProviders_ClassSyncProviderConfiguration = (
  {
  providerIdentifier: string,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null
}
  & {
  __typename?: 'ClassSyncProviderConfiguration'
}
);

export type ClassSyncSiteConfigurationFragment = (
  {
  classSyncProviders?: Array<ClassSyncSiteConfigurationFragment_SiteConfiguration_classSyncProviders_ClassSyncProviderConfiguration> | null
}
  & {
  __typename?: 'SiteConfiguration'
}
);

export type SiteClassSyncProviderFragment = (
  {
  identifier: string,
  name: string,
  authProviderId?: string | null,
  authProviderName?: string | null,
  supportedFeatures?: Array<ClassSyncProviderFeature> | null,
  enabledFeatures?: Array<ClassSyncProviderFeature> | null,
  requiresSetup: boolean
}
  & {
  __typename?: 'SiteClassSyncProvider'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student = (
  {
  id: string,
  user: ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent_userInfo_BasicUser = (
  {
  firstName: string,
  lastName: string,
  email: string
}
  & {
  __typename?: 'BasicUser'
}
);

export type ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent = (
  {
  id: string,
  group?: string | null,
  userInfo: ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent_userInfo_BasicUser
}
  & {
  __typename?: 'ClassSyncStudent'
}
);

export type ClassSyncRosterActionFragment = (
  {
  mode?: ClassSyncRosterActionMode | null,
  student?: ClassSyncRosterActionFragment_ClassSyncRosterAction_student_Student | null,
  providerStudent?: ClassSyncRosterActionFragment_ClassSyncRosterAction_providerStudent_ClassSyncStudent | null
}
  & {
  __typename?: 'ClassSyncRosterAction'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection = (
  {
  edges: Array<SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory = (
  {
  id: string,
  status: ClassSyncHistoryStatus,
  completedAt: any
}
  & {
  __typename?: 'ClassSyncHistory'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge = (
  {
  node: SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge_node_ClassSyncHistory
}
  & {
  __typename?: 'ClassSyncHistoryEdge'
}
);

export type SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection = (
  {
  edges: Array<SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection_edges_ClassSyncHistoryEdge>
}
  & {
  __typename?: 'ClassSyncHistoryConnection'
}
);

export type SyncHistoryOverviewFragment = (
  {
  latestMetadataSync?: SyncHistoryOverviewFragment_AssignmentSync_latestMetadataSync_ClassSyncHistoryConnection | null,
  latestGradeSync?: SyncHistoryOverviewFragment_AssignmentSync_latestGradeSync_ClassSyncHistoryConnection | null
}
  & {
  __typename?: 'AssignmentSync' | 'ClassSync' | 'MaterialSync'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string,
  email?: string | null
}
  & {
  __typename?: 'User'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student = (
  {
  id: string,
  user: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata = (
  {
  matchedStudent: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student
}
  & {
  __typename?: 'DuplicateUserPendingStudentClassMetadata'
}
);

export type WithDuplicateUserPendingStudentClassMetadataFragment = (
  {
  duplicateUserMetadata?: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata | null
}
  & {
  __typename?: 'PendingStudentClass'
}
);

export type DuplicateUserPendingStudentClassMetadataFragment = (
  {
  matchedStudent: WithDuplicateUserPendingStudentClassMetadataFragment_PendingStudentClass_duplicateUserMetadata_DuplicateUserPendingStudentClassMetadata_matchedStudent_Student
}
  & {
  __typename?: 'DuplicateUserPendingStudentClassMetadata'
}
);

export type ClassSyncProviderClassFragment = (
  {
  id: string,
  name: string,
  isSuggested?: boolean | null,
  isAttached?: boolean | null
}
  & {
  __typename?: 'ClassSyncProviderClass'
}
);

export type UserFragment = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type MinimalClassFragment = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type BasicClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean
}
  & {
  __typename: 'Class'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type AdminClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  teachers: AdminClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount = (
  {
  id: string,
  provider: string,
  providerName: string,
  label: string,
  authorized?: boolean | null
}
  & {
  __typename?: 'LinkedAccount'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean,
  linkedAccounts: Array<ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount>
}
  & {
  __typename?: 'User'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  pageInfo: ClassFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo,
  edges: Array<ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type ClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  totalCount: number
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type ClassFragment = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassStudentEdgeFragment = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassesEdgeFragment_StudentClassesEdge_node_Class = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type StudentClassesEdgeFragment = (
  {
  node: StudentClassesEdgeFragment_StudentClassesEdge_node_Class
}
  & {
  __typename?: 'StudentClassesEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  edges: Array<StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type StudentClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  students: StudentClassFragment_Class_students_ClassStudentsConnection,
  teachers: StudentClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string,
  email?: string | null
}
  & {
  __typename?: 'User'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student = (
  {
  id: string,
  user: PendingStudentClassFragment_PendingStudentClass_student_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type PendingStudentClassFragment = (
  {
  id: string,
  state: PendingStudentClassState,
  reason: PendingStudentClassReason,
  createdAt: any,
  approvedAt?: any | null,
  declinedAt?: any | null,
  student: PendingStudentClassFragment_PendingStudentClass_student_Student
}
  & {
  __typename?: 'PendingStudentClass'
}
);

type UserTask_BasicUserTask_Fragment = (
  {
  identifier: string,
  completedAt?: any | null,
  date?: any | null
}
  & {
  __typename: 'BasicUserTask'
}
);

type UserTask_CompleteAssignmentUserTask_ReviewAssignmentUserTask_ViewSegmentUserTask_Fragment = {
  __typename: 'CompleteAssignmentUserTask' | 'ReviewAssignmentUserTask' | 'ViewSegmentUserTask'
};

export type UserTaskFragment = UserTask_BasicUserTask_Fragment | UserTask_CompleteAssignmentUserTask_ReviewAssignmentUserTask_ViewSegmentUserTask_Fragment;

export type BasicUserTaskFragment = (
  {
  identifier: string,
  completedAt?: any | null,
  date?: any | null
}
  & {
  __typename?: 'BasicUserTask'
}
);

export type AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  totalCount: number,
  edges: Array<AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type AllAssignmentSubmissionsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type AllAssignmentSubmissionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type AllAssignmentSubmissionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type AllAssignmentSubmissionsQuery_node_Assignment = (
  {
  id: string,
  submissions: AllAssignmentSubmissionsQuery_node_Assignment_submissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AllAssignmentSubmissionsQuery_node = AllAssignmentSubmissionsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AllAssignmentSubmissionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AllAssignmentSubmissionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AllAssignmentSubmissionsQuery_node_Assignment;

export type AllAssignmentSubmissionsQuery_Query = (
  {
  node?: AllAssignmentSubmissionsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AllAssignmentSubmissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AllAssignmentSubmissionsQuery = AllAssignmentSubmissionsQuery_Query;

export type AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class = (
  {
  id: string,
  groups?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge = (
  {
  node: AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class
}
  & {
  __typename?: 'TeacherClassesEdge'
}
);

export type AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection = (
  {
  edges: Array<AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge>
}
  & {
  __typename?: 'TeacherClassesConnection'
}
);

export type AllClassGroupsQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  classes: AllClassGroupsQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type AllClassGroupsQuery_viewer_User = (
  {
  id: string,
  teacher?: AllClassGroupsQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type AllClassGroupsQuery_Query = (
  {
  viewer?: AllClassGroupsQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type AllClassGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllClassGroupsQuery = AllClassGroupsQuery_Query;

export type AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge_node_RubricTemplate = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge = (
  {
  node: AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge_node_RubricTemplate
}
  & {
  __typename?: 'RubricTemplatesEdge'
}
);

export type AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection = (
  {
  pageInfo: AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_pageInfo_PageInfo,
  edges: Array<AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge>
}
  & {
  __typename?: 'RubricTemplatesConnection'
}
);

export type AllRubricTemplatesQuery_Query = (
  {
  rubricTemplates: AllRubricTemplatesQuery_rubricTemplates_RubricTemplatesConnection
}
  & {
  __typename?: 'Query'
}
);


export type AllRubricTemplatesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllRubricTemplatesQuery = AllRubricTemplatesQuery_Query;

export type AssignmentGeneratedSubmissionQuery_node_Assignment_generatedSubmission_AssignmentSubmission = (
  {
  id: string,
  generated?: any | null,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentGeneratedSubmissionQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type AssignmentGeneratedSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type AssignmentGeneratedSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type AssignmentGeneratedSubmissionQuery_node_Assignment = (
  {
  id: string,
  generatedSubmission?: AssignmentGeneratedSubmissionQuery_node_Assignment_generatedSubmission_AssignmentSubmission | null
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentGeneratedSubmissionQuery_node = AssignmentGeneratedSubmissionQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AssignmentGeneratedSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentGeneratedSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentGeneratedSubmissionQuery_node_Assignment;

export type AssignmentGeneratedSubmissionQuery_Query = (
  {
  node?: AssignmentGeneratedSubmissionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentGeneratedSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
}>;


export type AssignmentGeneratedSubmissionQuery = AssignmentGeneratedSubmissionQuery_Query;

export type AssignmentQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type AssignmentQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type AssignmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type AssignmentQuery_node_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  supportedFeatures: Array<AssignmentFeature>,
  configuration?: any | null,
  autoGrading?: AssignmentAutoGradeMode | null,
  groups?: any | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection,
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentQuery_node = AssignmentQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AssignmentQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentQuery_node_Assignment;

export type AssignmentQuery_Query = (
  {
  node?: AssignmentQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  studentClassId?: InputMaybe<Scalars['ID']['input']>;
  useCurrentSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type AssignmentQuery = AssignmentQuery_Query;

export type AssignmentRemainingAttemptsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type AssignmentRemainingAttemptsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type AssignmentRemainingAttemptsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type AssignmentRemainingAttemptsQuery_node_Assignment = (
  {
  timeLimit?: number | null,
  id: string,
  remainingAttempts?: number | null,
  startedAt?: any | null,
  submittedAt?: any | null
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentRemainingAttemptsQuery_node = AssignmentRemainingAttemptsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AssignmentRemainingAttemptsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentRemainingAttemptsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentRemainingAttemptsQuery_node_Assignment;

export type AssignmentRemainingAttemptsQuery_Query = (
  {
  node?: AssignmentRemainingAttemptsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentRemainingAttemptsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  classId: Scalars['ID']['input'];
}>;


export type AssignmentRemainingAttemptsQuery = AssignmentRemainingAttemptsQuery_Query;

export type AssignmentSubmissionConfigurationQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type AssignmentSubmissionConfigurationQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type AssignmentSubmissionConfigurationQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type AssignmentSubmissionConfigurationQuery_node_AssignmentSubmission = (
  {
  id: string,
  assignmentConfiguration?: any | null,
  overrides?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentSubmissionConfigurationQuery_node = AssignmentSubmissionConfigurationQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 | AssignmentSubmissionConfigurationQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentSubmissionConfigurationQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentSubmissionConfigurationQuery_node_AssignmentSubmission;

export type AssignmentSubmissionConfigurationQuery_Query = (
  {
  node?: AssignmentSubmissionConfigurationQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentSubmissionConfigurationQueryVariables = Exact<{
  submissionId: Scalars['ID']['input'];
}>;


export type AssignmentSubmissionConfigurationQuery = AssignmentSubmissionConfigurationQuery_Query;

export type AssignmentSubmissionQuery_node_AssignmentSubmission_assignment_Assignment_rubric_AssignmentRubric = (
  {
  categories: Array<BasicAssignmentFragment_Assignment_rubric_AssignmentRubric_categories_AssignmentRubricCategory>
}
  & {
  __typename?: 'AssignmentRubric'
}
);

export type AssignmentSubmissionQuery_node_AssignmentSubmission_assignment_Assignment = (
  {
  id: string,
  rubric?: AssignmentSubmissionQuery_node_AssignmentSubmission_assignment_Assignment_rubric_AssignmentRubric | null
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentSubmissionQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type AssignmentSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type AssignmentSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type AssignmentSubmissionQuery_node_AssignmentSubmission = (
  {
  assignmentConfiguration?: any | null,
  submissionData?: any | null,
  grades?: any | null,
  comments?: any | null,
  questionMetadata?: any | null,
  overrides?: any | null,
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean,
  assignment: AssignmentSubmissionQuery_node_AssignmentSubmission_assignment_Assignment
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AssignmentSubmissionQuery_node = AssignmentSubmissionQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 | AssignmentSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentSubmissionQuery_node_AssignmentSubmission;

export type AssignmentSubmissionQuery_Query = (
  {
  node?: AssignmentSubmissionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  withConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AssignmentSubmissionQuery = AssignmentSubmissionQuery_Query;

export type AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion_answerDistribution_AssignmentSubmissionSummaryAnswerDistribution = (
  {
  answer: string,
  percentage: number
}
  & {
  __typename?: 'AssignmentSubmissionSummaryAnswerDistribution'
}
);

export type AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion = (
  {
  id: string,
  averageGrade: number,
  answerDistribution?: Array<AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion_answerDistribution_AssignmentSubmissionSummaryAnswerDistribution> | null
}
  & {
  __typename?: 'AssignmentSubmissionSummaryQuestion'
}
);

export type AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary = (
  {
  averageGrade?: number | null,
  questions: Array<AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary_questions_AssignmentSubmissionSummaryQuestion>
}
  & {
  __typename?: 'AssignmentSubmissionSummary'
}
);

export type AssignmentSubmissionSummaryQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type AssignmentSubmissionSummaryQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type AssignmentSubmissionSummaryQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type AssignmentSubmissionSummaryQuery_node_Assignment = (
  {
  id: string,
  submissionSummary?: AssignmentSubmissionSummaryQuery_node_Assignment_submissionSummary_AssignmentSubmissionSummary | null
}
  & {
  __typename: 'Assignment'
}
);

export type AssignmentSubmissionSummaryQuery_node = AssignmentSubmissionSummaryQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AssignmentSubmissionSummaryQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentSubmissionSummaryQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentSubmissionSummaryQuery_node_Assignment;

export type AssignmentSubmissionSummaryQuery_Query = (
  {
  node?: AssignmentSubmissionSummaryQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentSubmissionSummaryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AssignmentSubmissionSummaryQuery = AssignmentSubmissionSummaryQuery_Query;

export type AssignmentSubmissionsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type AssignmentSubmissionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type AssignmentSubmissionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type AssignmentSubmissionsQuery_node_Assignment = (
  {
  remainingAttempts?: number | null,
  id: string,
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentSubmissionsQuery_node = AssignmentSubmissionsQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | AssignmentSubmissionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentSubmissionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentSubmissionsQuery_node_Assignment;

export type AssignmentSubmissionsQuery_Query = (
  {
  node?: AssignmentSubmissionsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentSubmissionsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  studentClassId?: InputMaybe<Scalars['ID']['input']>;
  useCurrentSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type AssignmentSubmissionsQuery = AssignmentSubmissionsQuery_Query;

export type AssignmentTemplateQuery_node_qR9SAQosHnYq6HmKgw1CsRn6dRSrIUDh2ZUXCeXCMk = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type AssignmentTemplateQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type AssignmentTemplateQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type AssignmentTemplateQuery_node_AssignmentTemplate = (
  {
  id: string,
  name: string,
  configuration?: any | null,
  defaults?: any | null,
  createdAt: any,
  updatedAt: any
}
  & {
  __typename: 'AssignmentTemplate'
}
);

export type AssignmentTemplateQuery_node = AssignmentTemplateQuery_node_qR9SAQosHnYq6HmKgw1CsRn6dRSrIUDh2ZUXCeXCMk | AssignmentTemplateQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | AssignmentTemplateQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | AssignmentTemplateQuery_node_AssignmentTemplate;

export type AssignmentTemplateQuery_Query = (
  {
  node?: AssignmentTemplateQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AssignmentTemplateQuery = AssignmentTemplateQuery_Query;

export type AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_edges_AssignmentTemplatesEdge_node_AssignmentTemplate = (
  {
  id: string,
  name: string,
  configuration?: any | null,
  defaults?: any | null,
  createdAt: any,
  updatedAt: any
}
  & {
  __typename: 'AssignmentTemplate'
}
);

export type AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_edges_AssignmentTemplatesEdge = (
  {
  cursor: string,
  node: AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_edges_AssignmentTemplatesEdge_node_AssignmentTemplate
}
  & {
  __typename?: 'AssignmentTemplatesEdge'
}
);

export type AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection = (
  {
  totalCount: number,
  pageInfo: AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_pageInfo_PageInfo,
  edges: Array<AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection_edges_AssignmentTemplatesEdge>
}
  & {
  __typename?: 'AssignmentTemplatesConnection'
}
);

export type AssignmentTemplatesQuery_Query = (
  {
  assignmentTemplates: AssignmentTemplatesQuery_assignmentTemplates_AssignmentTemplatesConnection
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentTemplatesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type AssignmentTemplatesQuery = AssignmentTemplatesQuery_Query;

export type AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge = (
  {
  node: AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment
}
  & {
  __typename?: 'AssignmentsEdge'
}
);

export type AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection = (
  {
  pageInfo: AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_pageInfo_PageInfo,
  edges: Array<AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge>
}
  & {
  __typename?: 'AssignmentsConnection'
}
);

export type AssignmentsQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  assignments: AssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type AssignmentsQuery_viewer_User = (
  {
  id: string,
  teacher?: AssignmentsQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type AssignmentsQuery_Query = (
  {
  viewer?: AssignmentsQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type AssignmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  filters?: InputMaybe<AssignmentFiltersInput>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type AssignmentsQuery = AssignmentsQuery_Query;

export type AutoGradeAssignmentSubmissionMutation_autoGradeAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission = (
  {
  id: string,
  grades?: any | null,
  grade?: number | null,
  questionMetadata?: any | null,
  reviewed?: boolean | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type AutoGradeAssignmentSubmissionMutation_autoGradeAssignmentSubmission_AssignmentSubmissionPayload = (
  {
  assignmentSubmission: AutoGradeAssignmentSubmissionMutation_autoGradeAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionPayload'
}
);

export type AutoGradeAssignmentSubmissionMutation_Mutation = (
  {
  autoGradeAssignmentSubmission: AutoGradeAssignmentSubmissionMutation_autoGradeAssignmentSubmission_AssignmentSubmissionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type AutoGradeAssignmentSubmissionMutationVariables = Exact<{
  input: AutoGradeAssignmentSubmissionInput;
}>;


export type AutoGradeAssignmentSubmissionMutation = AutoGradeAssignmentSubmissionMutation_Mutation;

export type BasicAssignmentSubmissionQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type BasicAssignmentSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type BasicAssignmentSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type BasicAssignmentSubmissionQuery_node_AssignmentSubmission = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type BasicAssignmentSubmissionQuery_node = BasicAssignmentSubmissionQuery_node_hLSBMwqVkYb0PxNQgPAqoZ9Jm9wUIIjNzN04sCgsNL0 | BasicAssignmentSubmissionQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | BasicAssignmentSubmissionQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | BasicAssignmentSubmissionQuery_node_AssignmentSubmission;

export type BasicAssignmentSubmissionQuery_Query = (
  {
  node?: BasicAssignmentSubmissionQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type BasicAssignmentSubmissionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type BasicAssignmentSubmissionQuery = BasicAssignmentSubmissionQuery_Query;

export type BulkUpdateAssignmentsInClassMutation_bulkUpdateAssignmentsInClass_SuccessResponse = (
  {
  success: boolean
}
  & {
  __typename?: 'SuccessResponse'
}
);

export type BulkUpdateAssignmentsInClassMutation_Mutation = (
  {
  bulkUpdateAssignmentsInClass: BulkUpdateAssignmentsInClassMutation_bulkUpdateAssignmentsInClass_SuccessResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type BulkUpdateAssignmentsInClassMutationVariables = Exact<{
  input: BulkUpdateAssignmentsInClassInput;
}>;


export type BulkUpdateAssignmentsInClassMutation = BulkUpdateAssignmentsInClassMutation_Mutation;

export type ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string
}
  & {
  __typename?: 'Student'
}
);

export type ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  grade?: number | null,
  node: ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection = (
  {
  edges: Array<ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type ClassAndStudentGradesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassAndStudentGradesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassAndStudentGradesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassAndStudentGradesQuery_node_Class = (
  {
  id: string,
  averageGrade?: number | null,
  students: ClassAndStudentGradesQuery_node_Class_students_ClassStudentsConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassAndStudentGradesQuery_node = ClassAndStudentGradesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassAndStudentGradesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassAndStudentGradesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassAndStudentGradesQuery_node_Class;

export type ClassAndStudentGradesQuery_Query = (
  {
  node?: ClassAndStudentGradesQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassAndStudentGradesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassAndStudentGradesQuery = ClassAndStudentGradesQuery_Query;

export type ClassAssignmentCategoriesQuery_node_Class_assignmentCategoriesMap_AssignmentCategoryMap = (
  {
  name: string,
  mappedTo?: string | null
}
  & {
  __typename?: 'AssignmentCategoryMap'
}
);

export type ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge = (
  {
  node: ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoriesEdge'
}
);

export type ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection = (
  {
  totalCount: number,
  edges: Array<ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge>
}
  & {
  __typename?: 'AssignmentCategoriesConnection'
}
);

export type ClassAssignmentCategoriesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassAssignmentCategoriesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassAssignmentCategoriesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassAssignmentCategoriesQuery_node_Class = (
  {
  id: string,
  canManageAssignmentCategories: boolean,
  canManageAssignmentClassConfiguration: boolean,
  configuration?: any | null,
  assignmentCategoriesMap: Array<ClassAssignmentCategoriesQuery_node_Class_assignmentCategoriesMap_AssignmentCategoryMap>,
  assignmentCategories: ClassAssignmentCategoriesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassAssignmentCategoriesQuery_node = ClassAssignmentCategoriesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassAssignmentCategoriesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassAssignmentCategoriesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassAssignmentCategoriesQuery_node_Class;

export type ClassAssignmentCategoriesQuery_Query = (
  {
  node?: ClassAssignmentCategoriesQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassAssignmentCategoriesQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
}>;


export type ClassAssignmentCategoriesQuery = ClassAssignmentCategoriesQuery_Query;

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  id: string,
  grade?: number | null,
  submittedAt?: any | null,
  reviewed?: boolean | null,
  comments?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  averageGrade?: number | null,
  edges: Array<ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment = (
  {
  id: string,
  dueAt?: any | null,
  totalPoints: number,
  name: string,
  rawConfiguration?: any | null,
  submissions: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge = (
  {
  node: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment
}
  & {
  __typename?: 'AssignmentsEdge'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection = (
  {
  edges: Array<ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection_edges_AssignmentsEdge>
}
  & {
  __typename?: 'AssignmentsConnection'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory = (
  {
  grade?: number | null,
  points?: number | null,
  totalPoints?: number | null,
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number,
  assignments: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory_assignments_AssignmentsConnection
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge = (
  {
  node: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoriesEdge'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection = (
  {
  edges: Array<ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge>
}
  & {
  __typename?: 'AssignmentCategoriesConnection'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node_Class = (
  {
  id: string,
  assignmentCategories: ClassAssignmentCategoriesWithGradesQuery_node_Class_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassAssignmentCategoriesWithGradesQuery_node = ClassAssignmentCategoriesWithGradesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassAssignmentCategoriesWithGradesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassAssignmentCategoriesWithGradesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassAssignmentCategoriesWithGradesQuery_node_Class;

export type ClassAssignmentCategoriesWithGradesQuery_Query = (
  {
  node?: ClassAssignmentCategoriesWithGradesQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassAssignmentCategoriesWithGradesQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  studentIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type ClassAssignmentCategoriesWithGradesQuery = ClassAssignmentCategoriesWithGradesQuery_Query;

export type ClassGeneratedProvidersQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassGeneratedProvidersQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassGeneratedProvidersQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassGeneratedProvidersQuery_node_Class = (
  {
  generatedProviders?: Array<string> | null,
  id: string
}
  & {
  __typename?: 'Class'
}
);

export type ClassGeneratedProvidersQuery_node = ClassGeneratedProvidersQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassGeneratedProvidersQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassGeneratedProvidersQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassGeneratedProvidersQuery_node_Class;

export type ClassGeneratedProvidersQuery_Query = (
  {
  node?: ClassGeneratedProvidersQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassGeneratedProvidersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassGeneratedProvidersQuery = ClassGeneratedProvidersQuery_Query;

export type ClassGradeQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassGradeQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassGradeQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassGradeQuery_node_Class = (
  {
  id: string,
  averageGrade?: number | null
}
  & {
  __typename?: 'Class'
}
);

export type ClassGradeQuery_node = ClassGradeQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassGradeQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassGradeQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassGradeQuery_node_Class;

export type ClassGradeQuery_Query = (
  {
  node?: ClassGradeQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassGradeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassGradeQuery = ClassGradeQuery_Query;

export type ClassGroupPresetQuery_node_8afFdcZml2yXFsQbghAYbC6lxnwRi65uqOwJ2Izg = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassGroupPresetQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassGroupPresetQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassGroupPresetQuery_node_ClassGroupPreset = (
  {
  id: string,
  configuration: any,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type ClassGroupPresetQuery_node = ClassGroupPresetQuery_node_8afFdcZml2yXFsQbghAYbC6lxnwRi65uqOwJ2Izg | ClassGroupPresetQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassGroupPresetQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassGroupPresetQuery_node_ClassGroupPreset;

export type ClassGroupPresetQuery_Query = (
  {
  node?: ClassGroupPresetQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassGroupPresetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassGroupPresetQuery = ClassGroupPresetQuery_Query;

export type ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_pageInfo_PageInfo = (
  {
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_edges_ClassGroupPresetsEdge_node_ClassGroupPreset = (
  {
  id: string,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_edges_ClassGroupPresetsEdge = (
  {
  node: ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_edges_ClassGroupPresetsEdge_node_ClassGroupPreset
}
  & {
  __typename?: 'ClassGroupPresetsEdge'
}
);

export type ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection = (
  {
  pageInfo: ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_pageInfo_PageInfo,
  edges: Array<ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection_edges_ClassGroupPresetsEdge>
}
  & {
  __typename?: 'ClassGroupPresetsConnection'
}
);

export type ClassGroupPresetsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassGroupPresetsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassGroupPresetsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassGroupPresetsQuery_node_Class = (
  {
  id: string,
  groupPresets: ClassGroupPresetsQuery_node_Class_groupPresets_ClassGroupPresetsConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassGroupPresetsQuery_node = ClassGroupPresetsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassGroupPresetsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassGroupPresetsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassGroupPresetsQuery_node_Class;

export type ClassGroupPresetsQuery_Query = (
  {
  node?: ClassGroupPresetsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassGroupPresetsQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClassGroupPresetsQuery = ClassGroupPresetsQuery_Query;

export type CreateAssignmentCategoryMutation_createAssignmentCategory_AssignmentCategoryPayload_assignmentCategory_AssignmentCategory = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type CreateAssignmentCategoryMutation_createAssignmentCategory_AssignmentCategoryPayload = (
  {
  assignmentCategory: CreateAssignmentCategoryMutation_createAssignmentCategory_AssignmentCategoryPayload_assignmentCategory_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoryPayload'
}
);

export type CreateAssignmentCategoryMutation_Mutation = (
  {
  createAssignmentCategory: CreateAssignmentCategoryMutation_createAssignmentCategory_AssignmentCategoryPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateAssignmentCategoryMutationVariables = Exact<{
  input: CreateAssignmentCategoryInput;
}>;


export type CreateAssignmentCategoryMutation = CreateAssignmentCategoryMutation_Mutation;

export type CreateAssignmentMutation_createAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection,
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type CreateAssignmentMutation_createAssignment_AssignmentPayload = (
  {
  assignment: CreateAssignmentMutation_createAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type CreateAssignmentMutation_Mutation = (
  {
  createAssignment: CreateAssignmentMutation_createAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateAssignmentMutationVariables = Exact<{
  input: CreateAssignmentInput;
  studentClassId?: InputMaybe<Scalars['ID']['input']>;
  useCurrentSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type CreateAssignmentMutation = CreateAssignmentMutation_Mutation;

export type CreateAssignmentTemplateMutation_createAssignmentTemplate_AssignmentTemplatePayload_assignmentTemplate_AssignmentTemplate = (
  {
  id: string,
  name: string,
  configuration?: any | null,
  defaults?: any | null,
  createdAt: any,
  updatedAt: any
}
  & {
  __typename: 'AssignmentTemplate'
}
);

export type CreateAssignmentTemplateMutation_createAssignmentTemplate_AssignmentTemplatePayload = (
  {
  assignmentTemplate: CreateAssignmentTemplateMutation_createAssignmentTemplate_AssignmentTemplatePayload_assignmentTemplate_AssignmentTemplate
}
  & {
  __typename?: 'AssignmentTemplatePayload'
}
);

export type CreateAssignmentTemplateMutation_Mutation = (
  {
  createAssignmentTemplate: CreateAssignmentTemplateMutation_createAssignmentTemplate_AssignmentTemplatePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateAssignmentTemplateMutationVariables = Exact<{
  input: AssignmentTemplateInput;
}>;


export type CreateAssignmentTemplateMutation = CreateAssignmentTemplateMutation_Mutation;

export type CreateClassGroupPresetMutation_createClassGroupPreset_ClassGroupPresetPayload_classGroupPreset_ClassGroupPreset = (
  {
  configuration: any,
  id: string,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type CreateClassGroupPresetMutation_createClassGroupPreset_ClassGroupPresetPayload = (
  {
  classGroupPreset: CreateClassGroupPresetMutation_createClassGroupPreset_ClassGroupPresetPayload_classGroupPreset_ClassGroupPreset
}
  & {
  __typename?: 'ClassGroupPresetPayload'
}
);

export type CreateClassGroupPresetMutation_Mutation = (
  {
  createClassGroupPreset: CreateClassGroupPresetMutation_createClassGroupPreset_ClassGroupPresetPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateClassGroupPresetMutationVariables = Exact<{
  input: CreateClassGroupPresetInput;
}>;


export type CreateClassGroupPresetMutation = CreateClassGroupPresetMutation_Mutation;

export type CreateRubricTemplateMutation_createRubricTemplate_RubricTemplatePayload_rubricTemplate_RubricTemplate = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type CreateRubricTemplateMutation_createRubricTemplate_RubricTemplatePayload = (
  {
  rubricTemplate: CreateRubricTemplateMutation_createRubricTemplate_RubricTemplatePayload_rubricTemplate_RubricTemplate
}
  & {
  __typename?: 'RubricTemplatePayload'
}
);

export type CreateRubricTemplateMutation_Mutation = (
  {
  createRubricTemplate: CreateRubricTemplateMutation_createRubricTemplate_RubricTemplatePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateRubricTemplateMutationVariables = Exact<{
  input: RubricTemplateInput;
}>;


export type CreateRubricTemplateMutation = CreateRubricTemplateMutation_Mutation;

export type DeleteAssignmentCategoryMutation_deleteAssignmentCategory_DeleteNodeResponse = (
  {
  id: string,
  numDeleted: number
}
  & {
  __typename?: 'DeleteNodeResponse'
}
);

export type DeleteAssignmentCategoryMutation_Mutation = (
  {
  deleteAssignmentCategory: DeleteAssignmentCategoryMutation_deleteAssignmentCategory_DeleteNodeResponse
}
  & {
  __typename?: 'Mutation'
}
);


export type DeleteAssignmentCategoryMutationVariables = Exact<{
  input: DeleteAssignmentCategoryInput;
}>;


export type DeleteAssignmentCategoryMutation = DeleteAssignmentCategoryMutation_Mutation;

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  averageGrade?: number | null,
  totalCount: number
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  rawConfiguration?: any | null,
  submissions: GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_submissions_AssignmentSubmissionsConnection,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge = (
  {
  cursor: string,
  node: GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment
}
  & {
  __typename?: 'AssignmentsEdge'
}
);

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection = (
  {
  pageInfo: GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_pageInfo_PageInfo,
  edges: Array<GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection_edges_AssignmentsEdge>
}
  & {
  __typename?: 'AssignmentsConnection'
}
);

export type GeneratedAssignmentsQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  assignments: GeneratedAssignmentsQuery_viewer_User_teacher_Teacher_assignments_AssignmentsConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type GeneratedAssignmentsQuery_viewer_User = (
  {
  id: string,
  teacher?: GeneratedAssignmentsQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type GeneratedAssignmentsQuery_Query = (
  {
  viewer?: GeneratedAssignmentsQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type GeneratedAssignmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  filters?: InputMaybe<AssignmentFiltersInput>;
}>;


export type GeneratedAssignmentsQuery = GeneratedAssignmentsQuery_Query;

export type GeneratedGroupsConfigurationQuery_generatedGroupsConfiguration_GeneratedGroupsResult = (
  {
  groups: any
}
  & {
  __typename?: 'GeneratedGroupsResult'
}
);

export type GeneratedGroupsConfigurationQuery_Query = (
  {
  generatedGroupsConfiguration: GeneratedGroupsConfigurationQuery_generatedGroupsConfiguration_GeneratedGroupsResult
}
  & {
  __typename?: 'Query'
}
);


export type GeneratedGroupsConfigurationQueryVariables = Exact<{
  input: GeneratedGroupsConfigurationInput;
}>;


export type GeneratedGroupsConfigurationQuery = GeneratedGroupsConfigurationQuery_Query;

export type GradeAssignmentSubmissionMutation_gradeAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission = (
  {
  assignmentId: string,
  classId: string,
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type GradeAssignmentSubmissionMutation_gradeAssignmentSubmission_AssignmentSubmissionPayload = (
  {
  assignmentSubmission: GradeAssignmentSubmissionMutation_gradeAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionPayload'
}
);

export type GradeAssignmentSubmissionMutation_Mutation = (
  {
  gradeAssignmentSubmission: GradeAssignmentSubmissionMutation_gradeAssignmentSubmission_AssignmentSubmissionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type GradeAssignmentSubmissionMutationVariables = Exact<{
  input: GradeAssignmentSubmissionInput;
}>;


export type GradeAssignmentSubmissionMutation = GradeAssignmentSubmissionMutation_Mutation;

export type GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  pageInfo: GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection_pageInfo_PageInfo,
  edges: Array<GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class_Class = (
  {
  averageGrade?: number | null,
  id: string,
  students: GradeAssignmentSubmissionRefetchQuery_class_Class_students_ClassStudentsConnection
}
  & {
  __typename?: 'Class'
}
);

export type GradeAssignmentSubmissionRefetchQuery_class = GradeAssignmentSubmissionRefetchQuery_class_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | GradeAssignmentSubmissionRefetchQuery_class_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | GradeAssignmentSubmissionRefetchQuery_class_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | GradeAssignmentSubmissionRefetchQuery_class_Class;

export type GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  id: string,
  grade?: number | null,
  grades?: any | null,
  comments?: any | null,
  gradedAt?: any | null,
  reviewed?: boolean | null,
  isLate?: boolean | null,
  submittedAt?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  averageGrade?: number | null,
  averagePoints?: number | null,
  edges: Array<GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment_Assignment = (
  {
  id: string,
  submissions: GradeAssignmentSubmissionRefetchQuery_assignment_Assignment_submissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type GradeAssignmentSubmissionRefetchQuery_assignment = GradeAssignmentSubmissionRefetchQuery_assignment_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | GradeAssignmentSubmissionRefetchQuery_assignment_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | GradeAssignmentSubmissionRefetchQuery_assignment_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | GradeAssignmentSubmissionRefetchQuery_assignment_Assignment;

export type GradeAssignmentSubmissionRefetchQuery_Query = (
  {
  class?: GradeAssignmentSubmissionRefetchQuery_class | null,
  assignment?: GradeAssignmentSubmissionRefetchQuery_assignment | null
}
  & {
  __typename?: 'Query'
}
);


export type GradeAssignmentSubmissionRefetchQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  assignmentId: Scalars['ID']['input'];
}>;


export type GradeAssignmentSubmissionRefetchQuery = GradeAssignmentSubmissionRefetchQuery_Query;

export type GradesByCategoryQuery_node_Assignment_gradesByCategory_GradesByCategory = (
  {
  categories: Array<AssignmentGradesByCategoryFragment_GradesByCategory_categories_GradesByCategoryCategory>
}
  & {
  __typename?: 'GradesByCategory'
}
);

export type GradesByCategoryQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type GradesByCategoryQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type GradesByCategoryQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type GradesByCategoryQuery_node_Assignment = (
  {
  id: string,
  gradesByCategory: GradesByCategoryQuery_node_Assignment_gradesByCategory_GradesByCategory
}
  & {
  __typename?: 'Assignment'
}
);

export type GradesByCategoryQuery_node = GradesByCategoryQuery_node_1Avd64D0t2Zsg4uiUoonZjB5J1Y8bZHYrQcyE | GradesByCategoryQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | GradesByCategoryQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | GradesByCategoryQuery_node_Assignment;

export type GradesByCategoryQuery_Query = (
  {
  node?: GradesByCategoryQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type GradesByCategoryQueryVariables = Exact<{
  assignmentId: Scalars['ID']['input'];
}>;


export type GradesByCategoryQuery = GradesByCategoryQuery_Query;

export type GroupGenerationModesQuery_generateGroupModes_GenerateGroupModeInfo = (
  {
  mode: GenerateGroupMode,
  name: string,
  description?: string | null,
  summary?: string | null
}
  & {
  __typename?: 'GenerateGroupModeInfo'
}
);

export type GroupGenerationModesQuery_generateLeaderModes_GenerateLeaderModeInfo = (
  {
  mode: GenerateLeaderMode,
  name: string,
  description?: string | null,
  summary?: string | null
}
  & {
  __typename?: 'GenerateLeaderModeInfo'
}
);

export type GroupGenerationModesQuery_Query = (
  {
  generateGroupModes: Array<GroupGenerationModesQuery_generateGroupModes_GenerateGroupModeInfo>,
  generateLeaderModes: Array<GroupGenerationModesQuery_generateLeaderModes_GenerateLeaderModeInfo>
}
  & {
  __typename?: 'Query'
}
);


export type GroupGenerationModesQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupGenerationModesQuery = GroupGenerationModesQuery_Query;

export type ImportGradeCategoriesMutation_importGradeCategories_ClassPayload_class_Class = (
  {
  id: string
}
  & {
  __typename?: 'Class'
}
);

export type ImportGradeCategoriesMutation_importGradeCategories_ClassPayload = (
  {
  class: ImportGradeCategoriesMutation_importGradeCategories_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type ImportGradeCategoriesMutation_Mutation = (
  {
  importGradeCategories: ImportGradeCategoriesMutation_importGradeCategories_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type ImportGradeCategoriesMutationVariables = Exact<{
  input: ImportGradeCategoriesInput;
}>;


export type ImportGradeCategoriesMutation = ImportGradeCategoriesMutation_Mutation;

export type ResetAssignmentSubmissionsMutation_resetAssignmentSubmissions_ResetAssignmentSubmissionsPayload = (
  {
  submissionIds: Array<string>
}
  & {
  __typename?: 'ResetAssignmentSubmissionsPayload'
}
);

export type ResetAssignmentSubmissionsMutation_Mutation = (
  {
  resetAssignmentSubmissions: ResetAssignmentSubmissionsMutation_resetAssignmentSubmissions_ResetAssignmentSubmissionsPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type ResetAssignmentSubmissionsMutationVariables = Exact<{
  input: ResetAssignmentSubmissionsInput;
}>;


export type ResetAssignmentSubmissionsMutation = ResetAssignmentSubmissionsMutation_Mutation;

export type ResetGeneratedAssignmentMutation_resetGeneratedAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type ResetGeneratedAssignmentMutation_resetGeneratedAssignment_AssignmentPayload = (
  {
  assignment: ResetGeneratedAssignmentMutation_resetGeneratedAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type ResetGeneratedAssignmentMutation_Mutation = (
  {
  resetGeneratedAssignment: ResetGeneratedAssignmentMutation_resetGeneratedAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type ResetGeneratedAssignmentMutationVariables = Exact<{
  input: ResetGeneratedAssignmentInput;
}>;


export type ResetGeneratedAssignmentMutation = ResetGeneratedAssignmentMutation_Mutation;

export type ResetGeneratedAssignmentSubmissionMutation_resetGeneratedAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type ResetGeneratedAssignmentSubmissionMutation_resetGeneratedAssignmentSubmission_AssignmentSubmissionPayload = (
  {
  assignmentSubmission: ResetGeneratedAssignmentSubmissionMutation_resetGeneratedAssignmentSubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionPayload'
}
);

export type ResetGeneratedAssignmentSubmissionMutation_Mutation = (
  {
  resetGeneratedAssignmentSubmission: ResetGeneratedAssignmentSubmissionMutation_resetGeneratedAssignmentSubmission_AssignmentSubmissionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type ResetGeneratedAssignmentSubmissionMutationVariables = Exact<{
  input: ResetGeneratedAssignmentSubmissionInput;
}>;


export type ResetGeneratedAssignmentSubmissionMutation = ResetGeneratedAssignmentSubmissionMutation_Mutation;

export type RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission_assignment_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  supportedFeatures: Array<AssignmentFeature>,
  configuration?: any | null,
  autoGrading?: AssignmentAutoGradeMode | null,
  remainingAttempts?: number | null,
  startedAt?: any | null,
  submittedAt?: any | null,
  groups?: any | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection,
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission = (
  {
  id: string,
  assignment: RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission_assignment_Assignment
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload = (
  {
  assignmentSubmission: RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionPayload'
}
);

export type RetrySubmissionMutation_Mutation = (
  {
  retrySubmission: RetrySubmissionMutation_retrySubmission_AssignmentSubmissionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type RetrySubmissionMutationVariables = Exact<{
  input: RetrySubmissionInput;
  classId: Scalars['ID']['input'];
  studentClassId?: InputMaybe<Scalars['ID']['input']>;
  useCurrentSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type RetrySubmissionMutation = RetrySubmissionMutation_Mutation;

export type RubricTemplateQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'Class' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion'
};

export type RubricTemplateQuery_node_jlyDo9wgF1XZzcxE6vZoMMlKyh7XIJKcpFtz4LyiNA = {
  __typename?: 'DiscussionThread' | 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type RubricTemplateQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type RubricTemplateQuery_node_RubricTemplate = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type RubricTemplateQuery_node = RubricTemplateQuery_node_z6wuQK1CxCXT6BufKY6TdglfT4brU3AUc8RiosvNe0 | RubricTemplateQuery_node_jlyDo9wgF1XZzcxE6vZoMMlKyh7XIJKcpFtz4LyiNA | RubricTemplateQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | RubricTemplateQuery_node_RubricTemplate;

export type RubricTemplateQuery_Query = (
  {
  node?: RubricTemplateQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type RubricTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RubricTemplateQuery = RubricTemplateQuery_Query;

export type RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge_node_RubricTemplate = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge = (
  {
  node: RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge_node_RubricTemplate
}
  & {
  __typename?: 'RubricTemplatesEdge'
}
);

export type RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection = (
  {
  pageInfo: RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_pageInfo_PageInfo,
  edges: Array<RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection_edges_RubricTemplatesEdge>
}
  & {
  __typename?: 'RubricTemplatesConnection'
}
);

export type RubricTemplatesQuery_viewer_User = (
  {
  id: string,
  rubricTemplates: RubricTemplatesQuery_viewer_User_rubricTemplates_RubricTemplatesConnection
}
  & {
  __typename?: 'User'
}
);

export type RubricTemplatesQuery_Query = (
  {
  viewer?: RubricTemplatesQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type RubricTemplatesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type RubricTemplatesQuery = RubricTemplatesQuery_Query;

export type RunGradeReportMutation_runGradeReport_ReportJobPayload_reportJob_ReportJob = (
  {
  id: string,
  downloadUrl?: string | null
}
  & {
  __typename?: 'ReportJob'
}
);

export type RunGradeReportMutation_runGradeReport_ReportJobPayload = (
  {
  reportJob?: RunGradeReportMutation_runGradeReport_ReportJobPayload_reportJob_ReportJob | null
}
  & {
  __typename?: 'ReportJobPayload'
}
);

export type RunGradeReportMutation_Mutation = (
  {
  runGradeReport: RunGradeReportMutation_runGradeReport_ReportJobPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type RunGradeReportMutationVariables = Exact<{
  input: RunGradeReportInput;
}>;


export type RunGradeReportMutation = RunGradeReportMutation_Mutation;

export type SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload_assignmentSubmission_AssignmentSubmission = (
  {
  id: string,
  assignmentConfiguration?: any | null,
  overrides?: any | null,
  submissionData?: any | null,
  grades?: any | null,
  comments?: any | null,
  questionMetadata?: any | null,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload_assignment_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  remainingAttempts?: number | null,
  startedAt?: any | null,
  submittedAt?: any | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload = (
  {
  assignmentSubmission: SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload_assignmentSubmission_AssignmentSubmission,
  assignment: SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentSubmissionAndAssignmentPayload'
}
);

export type SaveAssignmentSubmissionMutation_Mutation = (
  {
  saveAssignmentSubmission: SaveAssignmentSubmissionMutation_saveAssignmentSubmission_AssignmentSubmissionAndAssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type SaveAssignmentSubmissionMutationVariables = Exact<{
  input: SaveAssignmentSubmissionInput;
  classId: Scalars['ID']['input'];
  includeConfiguration: Scalars['Boolean']['input'];
}>;


export type SaveAssignmentSubmissionMutation = SaveAssignmentSubmissionMutation_Mutation;

export type StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission = (
  {
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean,
  assignmentConfiguration?: any | null,
  overrides?: any | null
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge = (
  {
  node: StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge_node_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionsEdge'
}
);

export type StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection = (
  {
  edges: Array<StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection_edges_AssignmentSubmissionsEdge>
}
  & {
  __typename?: 'AssignmentSubmissionsConnection'
}
);

export type StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  remainingAttempts?: number | null,
  submissions: StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment_submissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload = (
  {
  assignment: StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type StudentForfeitAssignmentMutation_Mutation = (
  {
  forfeitAssignment: StudentForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type StudentForfeitAssignmentMutationVariables = Exact<{
  input: ForfeitAssignmentInput;
  classId: Scalars['ID']['input'];
}>;


export type StudentForfeitAssignmentMutation = StudentForfeitAssignmentMutation_Mutation;

export type SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission_assignment_Assignment = (
  {
  id: string,
  remainingAttempts?: number | null,
  submissions?: AssignmentSubmissionsFragment_Assignment_submissions_AssignmentSubmissionsConnection,
  currentSubmissions: AssignmentSubmissionsFragment_Assignment_currentSubmissions_AssignmentSubmissionsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission = (
  {
  submissionData?: any | null,
  grades?: any | null,
  comments?: any | null,
  questionMetadata?: any | null,
  overrides?: any | null,
  id: string,
  createdAt: any,
  updatedAt: any,
  deletedAt?: any | null,
  studentId: string,
  classId: string,
  reviewed?: boolean | null,
  grade?: number | null,
  isLate?: boolean | null,
  submittedAt?: any | null,
  gradedAt?: any | null,
  canSubmit: boolean,
  canReset: boolean,
  canGrade: boolean,
  canEdit: boolean,
  assignment: SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission_assignment_Assignment
}
  & {
  __typename?: 'AssignmentSubmission'
}
);

export type SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload = (
  {
  assignmentSubmission: SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload_assignmentSubmission_AssignmentSubmission
}
  & {
  __typename?: 'AssignmentSubmissionPayload'
}
);

export type SubmitAssignmentMutation_Mutation = (
  {
  submitAssignment: SubmitAssignmentMutation_submitAssignment_AssignmentSubmissionPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type SubmitAssignmentMutationVariables = Exact<{
  input: SubmitAssignmentInput;
  classId: Scalars['ID']['input'];
  studentClassId?: InputMaybe<Scalars['ID']['input']>;
  useCurrentSubmissions?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type SubmitAssignmentMutation = SubmitAssignmentMutation_Mutation;

export type SyncAssignmentMutation_syncAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  canSync: boolean,
  syncs: AssignmentWithSyncFragment_Assignment_syncs_AssignmentSyncsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type SyncAssignmentMutation_syncAssignment_AssignmentPayload = (
  {
  assignment: SyncAssignmentMutation_syncAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type SyncAssignmentMutation_Mutation = (
  {
  syncAssignment: SyncAssignmentMutation_syncAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type SyncAssignmentMutationVariables = Exact<{
  input: SyncAssignmentInput;
}>;


export type SyncAssignmentMutation = SyncAssignmentMutation_Mutation;

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory = (
  {
  id: string,
  classId: string
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge = (
  {
  node: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge_node_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoriesEdge'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection = (
  {
  edges: Array<TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection_edges_AssignmentCategoriesEdge>
}
  & {
  __typename?: 'AssignmentCategoriesConnection'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment = (
  {
  id: string,
  name: string,
  dueAt?: any | null,
  assignmentCategories: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment_assignmentCategories_AssignmentCategoriesConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge = (
  {
  node: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge_node_Assignment
}
  & {
  __typename?: 'AssignmentsEdge'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection = (
  {
  edges: Array<TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection_edges_AssignmentsEdge>
}
  & {
  __typename?: 'AssignmentsConnection'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks_BasicUserTask = (
  {
  identifier: string,
  completedAt?: any | null,
  date?: any | null
}
  & {
  __typename: 'BasicUserTask'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks_CompleteAssignmentUserTask_ReviewAssignmentUserTask_ViewSegmentUserTask = {
  __typename: 'CompleteAssignmentUserTask' | 'ReviewAssignmentUserTask' | 'ViewSegmentUserTask'
};

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks = TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks_BasicUserTask | TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks_CompleteAssignmentUserTask_ReviewAssignmentUserTask_ViewSegmentUserTask;

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class = (
  {
  id: string,
  userTasks: Array<TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class_userTasks>
}
  & {
  __typename?: 'Class'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge = (
  {
  node: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class
}
  & {
  __typename?: 'TeacherClassesEdge'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection = (
  {
  edges: Array<TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge>
}
  & {
  __typename?: 'TeacherClassesConnection'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  assignmentsDueSoon: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_assignmentsDueSoon_AssignmentsConnection,
  classes: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type TeacherClassTaskSummaryQuery_viewer_User = (
  {
  id: string,
  teacher?: TeacherClassTaskSummaryQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type TeacherClassTaskSummaryQuery_Query = (
  {
  viewer?: TeacherClassTaskSummaryQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type TeacherClassTaskSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type TeacherClassTaskSummaryQuery = TeacherClassTaskSummaryQuery_Query;

export type TeacherForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type TeacherForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload = (
  {
  assignment: TeacherForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type TeacherForfeitAssignmentMutation_Mutation = (
  {
  forfeitAssignment: TeacherForfeitAssignmentMutation_forfeitAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type TeacherForfeitAssignmentMutationVariables = Exact<{
  input: ForfeitAssignmentInput;
}>;


export type TeacherForfeitAssignmentMutation = TeacherForfeitAssignmentMutation_Mutation;

export type UpdateAssignmentCategoryMutation_updateAssignmentCategory_AssignmentCategoryPayload_assignmentCategory_AssignmentCategory = (
  {
  id: string,
  name: string,
  classId: string,
  gradeWeight: number,
  orderWeight: number
}
  & {
  __typename?: 'AssignmentCategory'
}
);

export type UpdateAssignmentCategoryMutation_updateAssignmentCategory_AssignmentCategoryPayload = (
  {
  assignmentCategory: UpdateAssignmentCategoryMutation_updateAssignmentCategory_AssignmentCategoryPayload_assignmentCategory_AssignmentCategory
}
  & {
  __typename?: 'AssignmentCategoryPayload'
}
);

export type UpdateAssignmentCategoryMutation_Mutation = (
  {
  updateAssignmentCategory: UpdateAssignmentCategoryMutation_updateAssignmentCategory_AssignmentCategoryPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateAssignmentCategoryMutationVariables = Exact<{
  input: UpdateAssignmentCategoryInput;
}>;


export type UpdateAssignmentCategoryMutation = UpdateAssignmentCategoryMutation_Mutation;

export type UpdateAssignmentMutation_updateAssignment_AssignmentPayload_assignment_Assignment = (
  {
  id: string,
  createdAt: any,
  name: string,
  description?: string | null,
  openAt?: any | null,
  dueAt?: any | null,
  totalPoints: number,
  excluded?: boolean | null,
  generated?: any | null,
  gradingMode: AssignmentGradingMode,
  assignmentMode: AssignmentStudentAssignmentMode,
  submissionType: SubmissionType,
  timeLimit?: number | null,
  maxAttempts: number,
  revealAttempts?: number | null,
  revealAnswers?: RevealAnswers | null,
  assignmentTemplateId?: string | null,
  canUpdate: boolean,
  canDelete: boolean,
  canGrade: boolean,
  canReset: boolean,
  canSubmit: boolean,
  dueAtOffset?: number | null,
  openAtOffset?: number | null,
  supportedFeatures: Array<AssignmentFeature>,
  configuration?: any | null,
  autoGrading?: AssignmentAutoGradeMode | null,
  groups?: any | null,
  rawConfiguration?: any | null,
  rubric?: BasicAssignmentFragment_Assignment_rubric_AssignmentRubric | null,
  assignmentCategories: BasicAssignmentFragment_Assignment_assignmentCategories_AssignmentCategoriesConnection,
  students: AssignmentAssignedStudentsFragment_Assignment_students_AssignmentStudentsConnection
}
  & {
  __typename?: 'Assignment'
}
);

export type UpdateAssignmentMutation_updateAssignment_AssignmentPayload = (
  {
  assignment: UpdateAssignmentMutation_updateAssignment_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type UpdateAssignmentMutation_Mutation = (
  {
  updateAssignment: UpdateAssignmentMutation_updateAssignment_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateAssignmentMutationVariables = Exact<{
  input: UpdateAssignmentInput;
}>;


export type UpdateAssignmentMutation = UpdateAssignmentMutation_Mutation;

export type UpdateAssignmentTemplateMutation_updateAssignmentTemplate_AssignmentTemplatePayload_assignmentTemplate_AssignmentTemplate = (
  {
  id: string,
  name: string,
  configuration?: any | null,
  defaults?: any | null,
  createdAt: any,
  updatedAt: any
}
  & {
  __typename: 'AssignmentTemplate'
}
);

export type UpdateAssignmentTemplateMutation_updateAssignmentTemplate_AssignmentTemplatePayload = (
  {
  assignmentTemplate: UpdateAssignmentTemplateMutation_updateAssignmentTemplate_AssignmentTemplatePayload_assignmentTemplate_AssignmentTemplate
}
  & {
  __typename?: 'AssignmentTemplatePayload'
}
);

export type UpdateAssignmentTemplateMutation_Mutation = (
  {
  updateAssignmentTemplate: UpdateAssignmentTemplateMutation_updateAssignmentTemplate_AssignmentTemplatePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateAssignmentTemplateMutationVariables = Exact<{
  input: UpdateAssignmentTemplateInput;
}>;


export type UpdateAssignmentTemplateMutation = UpdateAssignmentTemplateMutation_Mutation;

export type UpdateClassGroupPresetMutation_updateClassGroupPreset_ClassGroupPresetPayload_classGroupPreset_ClassGroupPreset = (
  {
  configuration: any,
  id: string,
  name: string,
  submissionType: GroupSubmissionType,
  isDefault: boolean
}
  & {
  __typename?: 'ClassGroupPreset'
}
);

export type UpdateClassGroupPresetMutation_updateClassGroupPreset_ClassGroupPresetPayload = (
  {
  classGroupPreset: UpdateClassGroupPresetMutation_updateClassGroupPreset_ClassGroupPresetPayload_classGroupPreset_ClassGroupPreset
}
  & {
  __typename?: 'ClassGroupPresetPayload'
}
);

export type UpdateClassGroupPresetMutation_Mutation = (
  {
  updateClassGroupPreset: UpdateClassGroupPresetMutation_updateClassGroupPreset_ClassGroupPresetPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateClassGroupPresetMutationVariables = Exact<{
  input: UpdateClassGroupPresetInput;
}>;


export type UpdateClassGroupPresetMutation = UpdateClassGroupPresetMutation_Mutation;

export type UpdateRubricTemplateMutation_updateRubricTemplate_RubricTemplatePayload_rubricTemplate_RubricTemplate = (
  {
  id: string,
  createdAt: any,
  name: string,
  rubric: RubricTemplateFragment_RubricTemplate_rubric_AssignmentRubric
}
  & {
  __typename?: 'RubricTemplate'
}
);

export type UpdateRubricTemplateMutation_updateRubricTemplate_RubricTemplatePayload = (
  {
  rubricTemplate: UpdateRubricTemplateMutation_updateRubricTemplate_RubricTemplatePayload_rubricTemplate_RubricTemplate
}
  & {
  __typename?: 'RubricTemplatePayload'
}
);

export type UpdateRubricTemplateMutation_Mutation = (
  {
  updateRubricTemplate: UpdateRubricTemplateMutation_updateRubricTemplate_RubricTemplatePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateRubricTemplateMutationVariables = Exact<{
  input: UpdateRubricTemplateInput;
}>;


export type UpdateRubricTemplateMutation = UpdateRubricTemplateMutation_Mutation;

export type UseAssignmentSubmissionMutation_useAssignmentSubmission_AssignmentPayload_assignment_Assignment = (
  {
  id: string
}
  & {
  __typename?: 'Assignment'
}
);

export type UseAssignmentSubmissionMutation_useAssignmentSubmission_AssignmentPayload = (
  {
  assignment: UseAssignmentSubmissionMutation_useAssignmentSubmission_AssignmentPayload_assignment_Assignment
}
  & {
  __typename?: 'AssignmentPayload'
}
);

export type UseAssignmentSubmissionMutation_Mutation = (
  {
  useAssignmentSubmission: UseAssignmentSubmissionMutation_useAssignmentSubmission_AssignmentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UseAssignmentSubmissionMutationVariables = Exact<{
  input: UseAssignmentSubmissionInput;
}>;


export type UseAssignmentSubmissionMutation = UseAssignmentSubmissionMutation_Mutation;

export const AssignmentTemplateFragmentDoc = gql`
    fragment AssignmentTemplate on AssignmentTemplate {
  __typename
  id
  name
  configuration
  defaults
  createdAt
  updatedAt
}
    `;
export const AssignmentRubricFragmentDoc = gql`
    fragment AssignmentRubric on AssignmentRubric {
  categories {
    categoryId: id
    name
    description
    totalPoints
    weight
    presets {
      presetId: id
      points
      name
      description
      weight
    }
  }
}
    `;
export const BasicAssignmentCategoryFragmentDoc = gql`
    fragment BasicAssignmentCategory on AssignmentCategory {
  id
  name
  classId
  gradeWeight
  orderWeight
}
    `;
export const BasicAssignmentFragmentDoc = gql`
    fragment BasicAssignment on Assignment {
  id
  createdAt
  name
  description
  openAt
  dueAt
  totalPoints
  excluded
  generated
  gradingMode
  assignmentMode
  submissionType
  timeLimit
  maxAttempts
  revealAttempts
  revealAnswers
  assignmentTemplateId
  canUpdate
  canDelete
  canGrade
  canReset
  canSubmit
  dueAtOffset
  openAtOffset
  rawConfiguration: configuration(raw: true)
  rubric {
    ...AssignmentRubric
  }
  assignmentCategories(first: 1) {
    edges {
      node {
        ...BasicAssignmentCategory
      }
    }
  }
}
    ${AssignmentRubricFragmentDoc}
${BasicAssignmentCategoryFragmentDoc}`;
export const AssignmentGradesByCategoryFragmentDoc = gql`
    fragment AssignmentGradesByCategory on GradesByCategory {
  categories {
    categoryId
    averageGrade
  }
}
    `;
export const RubricTemplateFragmentDoc = gql`
    fragment RubricTemplate on RubricTemplate {
  id
  createdAt
  rubric {
    ...AssignmentRubric
  }
  name
}
    ${AssignmentRubricFragmentDoc}`;
export const BasicClassSyncHistoryFragmentDoc = gql`
    fragment BasicClassSyncHistory on ClassSyncHistory {
  id
  status
  completedAt
}
    `;
export const SyncHistoryLatestAttemptFragmentDoc = gql`
    fragment SyncHistoryLatestAttempt on WithClassSyncHistory {
  latestAttempt: history(first: 1, orderBy: "completedAt", orderDirection: DESC) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const AssignmentSyncFragmentDoc = gql`
    fragment AssignmentSync on AssignmentSync {
  ...SyncHistoryLatestAttempt
  __typename
  id
  provider
  providerId
  classSync {
    id
    label
  }
}
    ${SyncHistoryLatestAttemptFragmentDoc}`;
export const AssignmentWithSyncFragmentDoc = gql`
    fragment AssignmentWithSync on Assignment {
  id
  canSync
  syncs {
    edges {
      node {
        ...AssignmentSync
      }
    }
  }
}
    ${AssignmentSyncFragmentDoc}`;
export const AssignmentWithRemainingAttemptsFragmentDoc = gql`
    fragment AssignmentWithRemainingAttempts on Assignment {
  id
  remainingAttempts(classId: $classId)
  startedAt(classId: $classId)
  submittedAt(classId: $classId)
}
    `;
export const AssignmentWithConfigurationFragmentDoc = gql`
    fragment AssignmentWithConfiguration on Assignment {
  id
  supportedFeatures
  configuration(includeDisabled: true)
  autoGrading
}
    `;
export const AssignmentWithGroupsFragmentDoc = gql`
    fragment AssignmentWithGroups on Assignment {
  id
  groups
}
    `;
export const AssignmentCategoryFragmentDoc = gql`
    fragment AssignmentCategory on AssignmentCategory {
  ...BasicAssignmentCategory
  totalAssignments: assignments {
    totalCount
  }
}
    ${BasicAssignmentCategoryFragmentDoc}`;
export const AssignmentAssignedStudentsFragmentDoc = gql`
    fragment AssignmentAssignedStudents on Assignment {
  students(first: 400, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      isMissing
      forfeitAttempts
      classId
      node {
        id
        user {
          id
          name
          username
        }
      }
    }
  }
}
    `;
export const BasicAssignmentSubmissionFragmentDoc = gql`
    fragment BasicAssignmentSubmission on AssignmentSubmission {
  id
  createdAt
  updatedAt
  deletedAt
  studentId
  classId
  reviewed
  grade
  isLate
  submittedAt
  gradedAt
  canSubmit
  canReset
  canGrade
  canEdit
}
    `;
export const FullAssignmentSubmissionFragmentDoc = gql`
    fragment FullAssignmentSubmission on AssignmentSubmission {
  ...BasicAssignmentSubmission
  submissionData
  grades
  comments
  questionMetadata
  overrides
}
    ${BasicAssignmentSubmissionFragmentDoc}`;
export const AssignmentSubmissionsFragmentDoc = gql`
    fragment AssignmentSubmissions on Assignment {
  submissions(first: 400, submitted: true) @skip(if: $useCurrentSubmissions) {
    averageGrade
    averagePoints
    edges {
      node {
        ...BasicAssignmentSubmission
      }
    }
  }
  currentSubmissions: submissions(
    first: $first
    after: $after
    orderBy: $orderBy
    orderDirection: $orderDirection
    classIds: [$studentClassId]
  ) @include(if: $useCurrentSubmissions) {
    totalCount
    edges {
      node {
        ...FullAssignmentSubmission
      }
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}
${FullAssignmentSubmissionFragmentDoc}`;
export const AssignmentSubmissionWithConfigurationFragmentDoc = gql`
    fragment AssignmentSubmissionWithConfiguration on AssignmentSubmission {
  id
  assignmentConfiguration
  overrides
}
    `;
export const SubmittedAssignmentSubmissionFragmentDoc = gql`
    fragment SubmittedAssignmentSubmission on AssignmentSubmission {
  id
  grade
  comments
  isLate
  reviewed
  submittedAt
}
    `;
export const ReviewAssignmentUserTaskFragmentDoc = gql`
    fragment ReviewAssignmentUserTask on ReviewAssignmentUserTask {
  identifier
  completedAt
  date
  payload {
    assignmentId
    classId
    excluded
    name
    dueAt
  }
}
    `;
export const CompleteAssignmentUserTaskFragmentDoc = gql`
    fragment CompleteAssignmentUserTask on CompleteAssignmentUserTask {
  completedAt
  date
  identifier
  payload {
    classId
    assignmentId
    assignmentName
    dueAt
  }
}
    `;
export const AssignmentSearchResultFragmentDoc = gql`
    fragment AssignmentSearchResult on Assignment {
  __typename
  id
  createdAt
  assignmentDue: dueAt
  assignmentOpens: openAt
  assignmentTitle: name
  assignmentStudents: students {
    totalCount
    totalGraded
    totalSubmitted
  }
}
    `;
export const BasicClassGroupPresetFragmentDoc = gql`
    fragment BasicClassGroupPreset on ClassGroupPreset {
  id
  name
  submissionType
  isDefault
}
    `;
export const ClassGroupPresetFragmentDoc = gql`
    fragment ClassGroupPreset on ClassGroupPreset {
  ...BasicClassGroupPreset
  configuration
}
    ${BasicClassGroupPresetFragmentDoc}`;
export const GenerateGroupModeInfoFragmentDoc = gql`
    fragment GenerateGroupModeInfo on GenerateGroupModeInfo {
  mode
  name
  description
  summary
}
    `;
export const GenerateLeaderModeInfoFragmentDoc = gql`
    fragment GenerateLeaderModeInfo on GenerateLeaderModeInfo {
  mode
  name
  description
  summary
}
    `;
export const AssignmentCategoryMapFragmentDoc = gql`
    fragment AssignmentCategoryMap on AssignmentCategoryMap {
  name
  mappedTo
}
    `;
export const AssignmentSubmissionSummaryFragmentDoc = gql`
    fragment AssignmentSubmissionSummary on AssignmentSubmissionSummary {
  averageGrade
  questions {
    id
    averageGrade
    answerDistribution {
      answer
      percentage
    }
  }
}
    `;
export const ClassSyncFragmentDoc = gql`
    fragment ClassSync on ClassSync {
  id
  provider
  providerId
  label
  canDelete
}
    `;
export const ClassWithSyncsFragmentDoc = gql`
    fragment ClassWithSyncs on Class {
  id
  canCreateClassSync
  canAttachStudentAccounts
  syncs(first: 32) {
    edges {
      node {
        ...ClassSync
      }
    }
  }
}
    ${ClassSyncFragmentDoc}`;
export const ListClassSyncHistoryFragmentDoc = gql`
    fragment ListClassSyncHistory on ClassSyncHistory {
  ...BasicClassSyncHistory
  classSync {
    id
    label
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const DetailedClassSyncHistoryFragmentDoc = gql`
    fragment DetailedClassSyncHistory on ClassSyncHistory {
  ...BasicClassSyncHistory
  classSync {
    id
  }
  materialSync {
    id
  }
  assignmentSync {
    id
  }
  startedAt
  completedAt
  type
  status
  detail {
    __typename
    ... on BaseClassSyncHistoryDetail {
      userId
    }
    ... on ClassSyncHistoryErrorDetail {
      stack
      message
      name
      responseData
      responseStatus
    }
  }
  logUrl
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const GroupImportFragmentDoc = gql`
    fragment GroupImport on GroupImport {
  importId
  name
  createdAt
}
    `;
export const GroupImportGroupFragmentDoc = gql`
    fragment GroupImportGroup on GroupImportGroup {
  groupId
  name
  createdAt
  leaderStudentId
  memberStudentIds
}
    `;
export const GradeCategoryImportSourceFragmentDoc = gql`
    fragment GradeCategoryImportSource on GradeCategoryImportSource {
  sourceId
  name
}
    `;
export const ClassSyncProviderConfigurationFragmentDoc = gql`
    fragment ClassSyncProviderConfiguration on ClassSyncProviderConfiguration {
  providerIdentifier
  enabledFeatures
}
    `;
export const ClassSyncSiteConfigurationFragmentDoc = gql`
    fragment ClassSyncSiteConfiguration on SiteConfiguration {
  classSyncProviders {
    ...ClassSyncProviderConfiguration
  }
}
    ${ClassSyncProviderConfigurationFragmentDoc}`;
export const SiteClassSyncProviderFragmentDoc = gql`
    fragment SiteClassSyncProvider on SiteClassSyncProvider {
  identifier
  name
  authProviderId
  authProviderName
  supportedFeatures
  enabledFeatures
  requiresSetup
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  firstName
  lastName
  username
  group
  status
  createdAt
  updatedAt
  lastActivity
  canUpdate
}
    `;
export const ClassSyncRosterActionFragmentDoc = gql`
    fragment ClassSyncRosterAction on ClassSyncRosterAction {
  mode
  student {
    id
    user {
      ...User
    }
  }
  providerStudent {
    id
    group
    userInfo {
      firstName
      lastName
      email
    }
  }
}
    ${UserFragmentDoc}`;
export const SyncHistoryOverviewFragmentDoc = gql`
    fragment SyncHistoryOverview on WithClassSyncHistory {
  latestMetadataSync: history(
    first: 1
    orderBy: "completedAt"
    orderDirection: DESC
    filters: {type: [BASIC, WITH_GRADES]}
  ) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
  latestGradeSync: history(
    first: 1
    orderBy: "completedAt"
    orderDirection: DESC
    filters: {type: [WITH_GRADES, ONLY_GRADES]}
  ) {
    edges {
      node {
        ...BasicClassSyncHistory
      }
    }
  }
}
    ${BasicClassSyncHistoryFragmentDoc}`;
export const DuplicateUserPendingStudentClassMetadataFragmentDoc = gql`
    fragment DuplicateUserPendingStudentClassMetadata on DuplicateUserPendingStudentClassMetadata {
  matchedStudent {
    id
    user {
      id
      name
      username
      email
    }
  }
}
    `;
export const WithDuplicateUserPendingStudentClassMetadataFragmentDoc = gql`
    fragment WithDuplicateUserPendingStudentClassMetadata on PendingStudentClass {
  duplicateUserMetadata {
    ...DuplicateUserPendingStudentClassMetadata
  }
}
    ${DuplicateUserPendingStudentClassMetadataFragmentDoc}`;
export const ClassSyncProviderClassFragmentDoc = gql`
    fragment ClassSyncProviderClass on ClassSyncProviderClass {
  id
  name
  isSuggested
  isAttached
}
    `;
export const MinimalClassFragmentDoc = gql`
    fragment MinimalClass on Class {
  id
  name
  weight
  classId
  canInvite
  canUpdate
  isOwn
  archivedAt
}
    `;
export const BasicClassFragmentDoc = gql`
    fragment BasicClass on Class {
  ...MinimalClass
  __typename
  id
  name
  description
  weight
  classId
  configuration
  createdAt
  updatedAt
  archivedAt
}
    ${MinimalClassFragmentDoc}`;
export const AdminClassFragmentDoc = gql`
    fragment AdminClass on Class {
  ...BasicClass
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const ClassStudentEdgeFragmentDoc = gql`
    fragment ClassStudentEdge on ClassStudentsEdge {
  node {
    id
    user {
      ...User
      linkedAccounts {
        id
        provider
        providerName
        label
        authorized
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const ClassFragmentDoc = gql`
    fragment Class on Class {
  ...BasicClass
  isOwn
  canInvite
  canUpdate
  canRemoveStudents
  canUpdateSettings
  students(first: 400, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...ClassStudentEdge
    }
  }
  teachers {
    totalCount
  }
}
    ${BasicClassFragmentDoc}
${ClassStudentEdgeFragmentDoc}`;
export const StudentClassesEdgeFragmentDoc = gql`
    fragment StudentClassesEdge on StudentClassesEdge {
  node {
    ...MinimalClass
  }
}
    ${MinimalClassFragmentDoc}`;
export const StudentClassFragmentDoc = gql`
    fragment StudentClass on Class {
  ...BasicClass
  students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const PendingStudentClassFragmentDoc = gql`
    fragment PendingStudentClass on PendingStudentClass {
  id
  student {
    id
    user {
      id
      name
      username
      email
    }
  }
  state
  reason
  createdAt
  approvedAt
  declinedAt
}
    `;
export const BasicUserTaskFragmentDoc = gql`
    fragment BasicUserTask on BasicUserTask {
  identifier
  completedAt
  date
}
    `;
export const UserTaskFragmentDoc = gql`
    fragment UserTask on UserTask {
  __typename
  ...BasicUserTask
}
    ${BasicUserTaskFragmentDoc}`;
export const AllAssignmentSubmissionsDocument = gql`
    query allAssignmentSubmissions($id: ID!, $first: Int = 1, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC, $classId: ID!, $studentId: ID!, $includeDeleted: Boolean = true) {
  node(id: $id) {
    id
    ... on Assignment {
      submissions(
        first: $first
        after: $after
        orderBy: $orderBy
        orderDirection: $orderDirection
        classIds: [$classId]
        studentIds: [$studentId]
        includeDeleted: $includeDeleted
      ) {
        totalCount
        edges {
          node {
            ...BasicAssignmentSubmission
          }
        }
      }
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}`;

/**
 * __useAllAssignmentSubmissionsQuery__
 *
 * To run a query within a React component, call `useAllAssignmentSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAssignmentSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAssignmentSubmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useAllAssignmentSubmissionsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AllAssignmentSubmissionsQuery, AllAssignmentSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AllAssignmentSubmissionsQuery, AllAssignmentSubmissionsQueryVariables>(AllAssignmentSubmissionsDocument, options);
      }
export function useAllAssignmentSubmissionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AllAssignmentSubmissionsQuery, AllAssignmentSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AllAssignmentSubmissionsQuery, AllAssignmentSubmissionsQueryVariables>(AllAssignmentSubmissionsDocument, options);
        }
export type AllAssignmentSubmissionsQueryHookResult = ReturnType<typeof useAllAssignmentSubmissionsQuery>;
export type AllAssignmentSubmissionsLazyQueryHookResult = ReturnType<typeof useAllAssignmentSubmissionsLazyQuery>;
export type AllAssignmentSubmissionsQueryResult = ApolloReactCommon.QueryResult<AllAssignmentSubmissionsQuery, AllAssignmentSubmissionsQueryVariables>;
export const AllClassGroupsDocument = gql`
    query allClassGroups {
  viewer {
    id
    teacher {
      id
      classes(first: 1000) {
        edges {
          node {
            id
            groups
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllClassGroupsQuery__
 *
 * To run a query within a React component, call `useAllClassGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllClassGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllClassGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllClassGroupsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<AllClassGroupsQuery, AllClassGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AllClassGroupsQuery, AllClassGroupsQueryVariables>(AllClassGroupsDocument, options);
      }
export function useAllClassGroupsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AllClassGroupsQuery, AllClassGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AllClassGroupsQuery, AllClassGroupsQueryVariables>(AllClassGroupsDocument, options);
        }
export type AllClassGroupsQueryHookResult = ReturnType<typeof useAllClassGroupsQuery>;
export type AllClassGroupsLazyQueryHookResult = ReturnType<typeof useAllClassGroupsLazyQuery>;
export type AllClassGroupsQueryResult = ApolloReactCommon.QueryResult<AllClassGroupsQuery, AllClassGroupsQueryVariables>;
export const AllRubricTemplatesDocument = gql`
    query allRubricTemplates($first: Int = 32, $after: String, $orderBy: String = "name", $orderDirection: OrderDirection = ASC, $name: String) {
  rubricTemplates(
    first: $first
    after: $after
    orderBy: $orderBy
    orderDirection: $orderDirection
    name: $name
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...RubricTemplate
      }
    }
  }
}
    ${RubricTemplateFragmentDoc}`;

/**
 * __useAllRubricTemplatesQuery__
 *
 * To run a query within a React component, call `useAllRubricTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRubricTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRubricTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAllRubricTemplatesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<AllRubricTemplatesQuery, AllRubricTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AllRubricTemplatesQuery, AllRubricTemplatesQueryVariables>(AllRubricTemplatesDocument, options);
      }
export function useAllRubricTemplatesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AllRubricTemplatesQuery, AllRubricTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AllRubricTemplatesQuery, AllRubricTemplatesQueryVariables>(AllRubricTemplatesDocument, options);
        }
export type AllRubricTemplatesQueryHookResult = ReturnType<typeof useAllRubricTemplatesQuery>;
export type AllRubricTemplatesLazyQueryHookResult = ReturnType<typeof useAllRubricTemplatesLazyQuery>;
export type AllRubricTemplatesQueryResult = ApolloReactCommon.QueryResult<AllRubricTemplatesQuery, AllRubricTemplatesQueryVariables>;
export const AssignmentGeneratedSubmissionDocument = gql`
    query assignmentGeneratedSubmission($id: ID!, $classId: ID!, $studentId: ID!) {
  node(id: $id) {
    id
    ... on Assignment {
      generatedSubmission(classId: $classId, studentId: $studentId) {
        id
        ...BasicAssignmentSubmission
        generated
      }
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}`;

/**
 * __useAssignmentGeneratedSubmissionQuery__
 *
 * To run a query within a React component, call `useAssignmentGeneratedSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentGeneratedSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentGeneratedSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      classId: // value for 'classId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useAssignmentGeneratedSubmissionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentGeneratedSubmissionQuery, AssignmentGeneratedSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentGeneratedSubmissionQuery, AssignmentGeneratedSubmissionQueryVariables>(AssignmentGeneratedSubmissionDocument, options);
      }
export function useAssignmentGeneratedSubmissionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentGeneratedSubmissionQuery, AssignmentGeneratedSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentGeneratedSubmissionQuery, AssignmentGeneratedSubmissionQueryVariables>(AssignmentGeneratedSubmissionDocument, options);
        }
export type AssignmentGeneratedSubmissionQueryHookResult = ReturnType<typeof useAssignmentGeneratedSubmissionQuery>;
export type AssignmentGeneratedSubmissionLazyQueryHookResult = ReturnType<typeof useAssignmentGeneratedSubmissionLazyQuery>;
export type AssignmentGeneratedSubmissionQueryResult = ApolloReactCommon.QueryResult<AssignmentGeneratedSubmissionQuery, AssignmentGeneratedSubmissionQueryVariables>;
export const AssignmentDocument = gql`
    query assignment($id: ID!, $studentClassId: ID = "", $useCurrentSubmissions: Boolean = false, $first: Int = 1, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC) {
  node(id: $id) {
    ...BasicAssignment
    ...AssignmentSubmissions
    ...AssignmentAssignedStudents
    ...AssignmentWithConfiguration
    ...AssignmentWithGroups
  }
}
    ${BasicAssignmentFragmentDoc}
${AssignmentSubmissionsFragmentDoc}
${AssignmentAssignedStudentsFragmentDoc}
${AssignmentWithConfigurationFragmentDoc}
${AssignmentWithGroupsFragmentDoc}`;

/**
 * __useAssignmentQuery__
 *
 * To run a query within a React component, call `useAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      studentClassId: // value for 'studentClassId'
 *      useCurrentSubmissions: // value for 'useCurrentSubmissions'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useAssignmentQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentQuery, AssignmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
      }
export function useAssignmentLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentQuery, AssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
        }
export type AssignmentQueryHookResult = ReturnType<typeof useAssignmentQuery>;
export type AssignmentLazyQueryHookResult = ReturnType<typeof useAssignmentLazyQuery>;
export type AssignmentQueryResult = ApolloReactCommon.QueryResult<AssignmentQuery, AssignmentQueryVariables>;
export const AssignmentRemainingAttemptsDocument = gql`
    query assignmentRemainingAttempts($id: ID!, $classId: ID!) {
  node(id: $id) {
    id
    ... on Assignment {
      timeLimit
    }
    ...AssignmentWithRemainingAttempts
  }
}
    ${AssignmentWithRemainingAttemptsFragmentDoc}`;

/**
 * __useAssignmentRemainingAttemptsQuery__
 *
 * To run a query within a React component, call `useAssignmentRemainingAttemptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentRemainingAttemptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentRemainingAttemptsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useAssignmentRemainingAttemptsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentRemainingAttemptsQuery, AssignmentRemainingAttemptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentRemainingAttemptsQuery, AssignmentRemainingAttemptsQueryVariables>(AssignmentRemainingAttemptsDocument, options);
      }
export function useAssignmentRemainingAttemptsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentRemainingAttemptsQuery, AssignmentRemainingAttemptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentRemainingAttemptsQuery, AssignmentRemainingAttemptsQueryVariables>(AssignmentRemainingAttemptsDocument, options);
        }
export type AssignmentRemainingAttemptsQueryHookResult = ReturnType<typeof useAssignmentRemainingAttemptsQuery>;
export type AssignmentRemainingAttemptsLazyQueryHookResult = ReturnType<typeof useAssignmentRemainingAttemptsLazyQuery>;
export type AssignmentRemainingAttemptsQueryResult = ApolloReactCommon.QueryResult<AssignmentRemainingAttemptsQuery, AssignmentRemainingAttemptsQueryVariables>;
export const AssignmentSubmissionConfigurationDocument = gql`
    query assignmentSubmissionConfiguration($submissionId: ID!) {
  node(id: $submissionId) {
    ...AssignmentSubmissionWithConfiguration
  }
}
    ${AssignmentSubmissionWithConfigurationFragmentDoc}`;

/**
 * __useAssignmentSubmissionConfigurationQuery__
 *
 * To run a query within a React component, call `useAssignmentSubmissionConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentSubmissionConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentSubmissionConfigurationQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useAssignmentSubmissionConfigurationQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentSubmissionConfigurationQuery, AssignmentSubmissionConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentSubmissionConfigurationQuery, AssignmentSubmissionConfigurationQueryVariables>(AssignmentSubmissionConfigurationDocument, options);
      }
export function useAssignmentSubmissionConfigurationLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentSubmissionConfigurationQuery, AssignmentSubmissionConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentSubmissionConfigurationQuery, AssignmentSubmissionConfigurationQueryVariables>(AssignmentSubmissionConfigurationDocument, options);
        }
export type AssignmentSubmissionConfigurationQueryHookResult = ReturnType<typeof useAssignmentSubmissionConfigurationQuery>;
export type AssignmentSubmissionConfigurationLazyQueryHookResult = ReturnType<typeof useAssignmentSubmissionConfigurationLazyQuery>;
export type AssignmentSubmissionConfigurationQueryResult = ApolloReactCommon.QueryResult<AssignmentSubmissionConfigurationQuery, AssignmentSubmissionConfigurationQueryVariables>;
export const AssignmentSubmissionDocument = gql`
    query assignmentSubmission($id: ID!, $withConfiguration: Boolean = false, $includeDeleted: Boolean = false) {
  node(id: $id, includeDeleted: $includeDeleted) {
    ... on AssignmentSubmission {
      ...FullAssignmentSubmission
      assignmentConfiguration @include(if: $withConfiguration)
      assignment {
        id
        rubric {
          ...AssignmentRubric
        }
      }
    }
  }
}
    ${FullAssignmentSubmissionFragmentDoc}
${AssignmentRubricFragmentDoc}`;

/**
 * __useAssignmentSubmissionQuery__
 *
 * To run a query within a React component, call `useAssignmentSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withConfiguration: // value for 'withConfiguration'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useAssignmentSubmissionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentSubmissionQuery, AssignmentSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentSubmissionQuery, AssignmentSubmissionQueryVariables>(AssignmentSubmissionDocument, options);
      }
export function useAssignmentSubmissionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentSubmissionQuery, AssignmentSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentSubmissionQuery, AssignmentSubmissionQueryVariables>(AssignmentSubmissionDocument, options);
        }
export type AssignmentSubmissionQueryHookResult = ReturnType<typeof useAssignmentSubmissionQuery>;
export type AssignmentSubmissionLazyQueryHookResult = ReturnType<typeof useAssignmentSubmissionLazyQuery>;
export type AssignmentSubmissionQueryResult = ApolloReactCommon.QueryResult<AssignmentSubmissionQuery, AssignmentSubmissionQueryVariables>;
export const AssignmentSubmissionSummaryDocument = gql`
    query assignmentSubmissionSummary($id: ID!) {
  node(id: $id) {
    __typename
    id
    ... on Assignment {
      submissionSummary {
        averageGrade
        questions {
          id
          averageGrade
          answerDistribution {
            answer
            percentage
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAssignmentSubmissionSummaryQuery__
 *
 * To run a query within a React component, call `useAssignmentSubmissionSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentSubmissionSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentSubmissionSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignmentSubmissionSummaryQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentSubmissionSummaryQuery, AssignmentSubmissionSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentSubmissionSummaryQuery, AssignmentSubmissionSummaryQueryVariables>(AssignmentSubmissionSummaryDocument, options);
      }
export function useAssignmentSubmissionSummaryLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentSubmissionSummaryQuery, AssignmentSubmissionSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentSubmissionSummaryQuery, AssignmentSubmissionSummaryQueryVariables>(AssignmentSubmissionSummaryDocument, options);
        }
export type AssignmentSubmissionSummaryQueryHookResult = ReturnType<typeof useAssignmentSubmissionSummaryQuery>;
export type AssignmentSubmissionSummaryLazyQueryHookResult = ReturnType<typeof useAssignmentSubmissionSummaryLazyQuery>;
export type AssignmentSubmissionSummaryQueryResult = ApolloReactCommon.QueryResult<AssignmentSubmissionSummaryQuery, AssignmentSubmissionSummaryQueryVariables>;
export const AssignmentSubmissionsDocument = gql`
    query assignmentSubmissions($id: ID!, $studentClassId: ID = "", $useCurrentSubmissions: Boolean = false, $first: Int = 1, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC) {
  node(id: $id) {
    id
    ... on Assignment {
      remainingAttempts(classId: $studentClassId) @include(if: $useCurrentSubmissions)
      ...AssignmentSubmissions
    }
  }
}
    ${AssignmentSubmissionsFragmentDoc}`;

/**
 * __useAssignmentSubmissionsQuery__
 *
 * To run a query within a React component, call `useAssignmentSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentSubmissionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      studentClassId: // value for 'studentClassId'
 *      useCurrentSubmissions: // value for 'useCurrentSubmissions'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useAssignmentSubmissionsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentSubmissionsQuery, AssignmentSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentSubmissionsQuery, AssignmentSubmissionsQueryVariables>(AssignmentSubmissionsDocument, options);
      }
export function useAssignmentSubmissionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentSubmissionsQuery, AssignmentSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentSubmissionsQuery, AssignmentSubmissionsQueryVariables>(AssignmentSubmissionsDocument, options);
        }
export type AssignmentSubmissionsQueryHookResult = ReturnType<typeof useAssignmentSubmissionsQuery>;
export type AssignmentSubmissionsLazyQueryHookResult = ReturnType<typeof useAssignmentSubmissionsLazyQuery>;
export type AssignmentSubmissionsQueryResult = ApolloReactCommon.QueryResult<AssignmentSubmissionsQuery, AssignmentSubmissionsQueryVariables>;
export const AssignmentTemplateDocument = gql`
    query assignmentTemplate($id: ID!) {
  node(id: $id) {
    ... on AssignmentTemplate {
      __typename
      ...AssignmentTemplate
    }
  }
}
    ${AssignmentTemplateFragmentDoc}`;

/**
 * __useAssignmentTemplateQuery__
 *
 * To run a query within a React component, call `useAssignmentTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAssignmentTemplateQuery(baseOptions: ApolloReactCommon.QueryHookOptions<AssignmentTemplateQuery, AssignmentTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentTemplateQuery, AssignmentTemplateQueryVariables>(AssignmentTemplateDocument, options);
      }
export function useAssignmentTemplateLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentTemplateQuery, AssignmentTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentTemplateQuery, AssignmentTemplateQueryVariables>(AssignmentTemplateDocument, options);
        }
export type AssignmentTemplateQueryHookResult = ReturnType<typeof useAssignmentTemplateQuery>;
export type AssignmentTemplateLazyQueryHookResult = ReturnType<typeof useAssignmentTemplateLazyQuery>;
export type AssignmentTemplateQueryResult = ApolloReactCommon.QueryResult<AssignmentTemplateQuery, AssignmentTemplateQueryVariables>;
export const AssignmentTemplatesDocument = gql`
    query assignmentTemplates($first: Int = 16, $after: String, $name: String, $orderBy: String = "name", $orderDirection: OrderDirection = ASC) {
  assignmentTemplates(
    first: $first
    after: $after
    name: $name
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
    edges {
      cursor
      node {
        ...AssignmentTemplate
      }
    }
  }
}
    ${AssignmentTemplateFragmentDoc}`;

/**
 * __useAssignmentTemplatesQuery__
 *
 * To run a query within a React component, call `useAssignmentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      name: // value for 'name'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useAssignmentTemplatesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<AssignmentTemplatesQuery, AssignmentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentTemplatesQuery, AssignmentTemplatesQueryVariables>(AssignmentTemplatesDocument, options);
      }
export function useAssignmentTemplatesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentTemplatesQuery, AssignmentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentTemplatesQuery, AssignmentTemplatesQueryVariables>(AssignmentTemplatesDocument, options);
        }
export type AssignmentTemplatesQueryHookResult = ReturnType<typeof useAssignmentTemplatesQuery>;
export type AssignmentTemplatesLazyQueryHookResult = ReturnType<typeof useAssignmentTemplatesLazyQuery>;
export type AssignmentTemplatesQueryResult = ApolloReactCommon.QueryResult<AssignmentTemplatesQuery, AssignmentTemplatesQueryVariables>;
export const AssignmentsDocument = gql`
    query assignments($first: Int = 32, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC, $filters: AssignmentFiltersInput, $name: String) {
  viewer {
    id
    teacher {
      id
      assignments(
        first: $first
        after: $after
        orderBy: $orderBy
        orderDirection: $orderDirection
        name: $name
        filters: $filters
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...BasicAssignment
          }
        }
      }
    }
  }
}
    ${BasicAssignmentFragmentDoc}`;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAssignmentsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
      }
export function useAssignmentsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
        }
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsQueryResult = ApolloReactCommon.QueryResult<AssignmentsQuery, AssignmentsQueryVariables>;
export const AutoGradeAssignmentSubmissionDocument = gql`
    mutation autoGradeAssignmentSubmission($input: AutoGradeAssignmentSubmissionInput!) {
  autoGradeAssignmentSubmission(input: $input) {
    assignmentSubmission {
      id
      grades
      grade
      questionMetadata
      reviewed
    }
  }
}
    `;
export type AutoGradeAssignmentSubmissionMutationFn = ApolloReactCommon.MutationFunction<AutoGradeAssignmentSubmissionMutation, AutoGradeAssignmentSubmissionMutationVariables>;

/**
 * __useAutoGradeAssignmentSubmissionMutation__
 *
 * To run a mutation, you first call `useAutoGradeAssignmentSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAutoGradeAssignmentSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [autoGradeAssignmentSubmissionMutation, { data, loading, error }] = useAutoGradeAssignmentSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAutoGradeAssignmentSubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<AutoGradeAssignmentSubmissionMutation, AutoGradeAssignmentSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<AutoGradeAssignmentSubmissionMutation, AutoGradeAssignmentSubmissionMutationVariables>(AutoGradeAssignmentSubmissionDocument, options);
      }
export type AutoGradeAssignmentSubmissionMutationHookResult = ReturnType<typeof useAutoGradeAssignmentSubmissionMutation>;
export type AutoGradeAssignmentSubmissionMutationResult = ApolloReactCommon.MutationResult<AutoGradeAssignmentSubmissionMutation>;
export type AutoGradeAssignmentSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<AutoGradeAssignmentSubmissionMutation, AutoGradeAssignmentSubmissionMutationVariables>;
export const BasicAssignmentSubmissionDocument = gql`
    query basicAssignmentSubmission($id: ID!) {
  node(id: $id) {
    ...BasicAssignmentSubmission
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}`;

/**
 * __useBasicAssignmentSubmissionQuery__
 *
 * To run a query within a React component, call `useBasicAssignmentSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicAssignmentSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicAssignmentSubmissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBasicAssignmentSubmissionQuery(baseOptions: ApolloReactCommon.QueryHookOptions<BasicAssignmentSubmissionQuery, BasicAssignmentSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<BasicAssignmentSubmissionQuery, BasicAssignmentSubmissionQueryVariables>(BasicAssignmentSubmissionDocument, options);
      }
export function useBasicAssignmentSubmissionLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<BasicAssignmentSubmissionQuery, BasicAssignmentSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<BasicAssignmentSubmissionQuery, BasicAssignmentSubmissionQueryVariables>(BasicAssignmentSubmissionDocument, options);
        }
export type BasicAssignmentSubmissionQueryHookResult = ReturnType<typeof useBasicAssignmentSubmissionQuery>;
export type BasicAssignmentSubmissionLazyQueryHookResult = ReturnType<typeof useBasicAssignmentSubmissionLazyQuery>;
export type BasicAssignmentSubmissionQueryResult = ApolloReactCommon.QueryResult<BasicAssignmentSubmissionQuery, BasicAssignmentSubmissionQueryVariables>;
export const BulkUpdateAssignmentsInClassDocument = gql`
    mutation bulkUpdateAssignmentsInClass($input: BulkUpdateAssignmentsInClassInput!) {
  bulkUpdateAssignmentsInClass(input: $input) {
    success
  }
}
    `;
export type BulkUpdateAssignmentsInClassMutationFn = ApolloReactCommon.MutationFunction<BulkUpdateAssignmentsInClassMutation, BulkUpdateAssignmentsInClassMutationVariables>;

/**
 * __useBulkUpdateAssignmentsInClassMutation__
 *
 * To run a mutation, you first call `useBulkUpdateAssignmentsInClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateAssignmentsInClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateAssignmentsInClassMutation, { data, loading, error }] = useBulkUpdateAssignmentsInClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateAssignmentsInClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<BulkUpdateAssignmentsInClassMutation, BulkUpdateAssignmentsInClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<BulkUpdateAssignmentsInClassMutation, BulkUpdateAssignmentsInClassMutationVariables>(BulkUpdateAssignmentsInClassDocument, options);
      }
export type BulkUpdateAssignmentsInClassMutationHookResult = ReturnType<typeof useBulkUpdateAssignmentsInClassMutation>;
export type BulkUpdateAssignmentsInClassMutationResult = ApolloReactCommon.MutationResult<BulkUpdateAssignmentsInClassMutation>;
export type BulkUpdateAssignmentsInClassMutationOptions = ApolloReactCommon.BaseMutationOptions<BulkUpdateAssignmentsInClassMutation, BulkUpdateAssignmentsInClassMutationVariables>;
export const ClassAndStudentGradesDocument = gql`
    query classAndStudentGrades($id: ID!) {
  node(id: $id) {
    ... on Class {
      id
      averageGrade
      students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
        edges {
          node {
            id
          }
          grade
        }
      }
    }
  }
}
    `;

/**
 * __useClassAndStudentGradesQuery__
 *
 * To run a query within a React component, call `useClassAndStudentGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassAndStudentGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassAndStudentGradesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassAndStudentGradesQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassAndStudentGradesQuery, ClassAndStudentGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassAndStudentGradesQuery, ClassAndStudentGradesQueryVariables>(ClassAndStudentGradesDocument, options);
      }
export function useClassAndStudentGradesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassAndStudentGradesQuery, ClassAndStudentGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassAndStudentGradesQuery, ClassAndStudentGradesQueryVariables>(ClassAndStudentGradesDocument, options);
        }
export type ClassAndStudentGradesQueryHookResult = ReturnType<typeof useClassAndStudentGradesQuery>;
export type ClassAndStudentGradesLazyQueryHookResult = ReturnType<typeof useClassAndStudentGradesLazyQuery>;
export type ClassAndStudentGradesQueryResult = ApolloReactCommon.QueryResult<ClassAndStudentGradesQuery, ClassAndStudentGradesQueryVariables>;
export const ClassAssignmentCategoriesDocument = gql`
    query classAssignmentCategories($classId: ID!) {
  node(id: $classId) {
    ... on Class {
      id
      canManageAssignmentCategories
      canManageAssignmentClassConfiguration
      configuration
      assignmentCategoriesMap {
        ...AssignmentCategoryMap
      }
      assignmentCategories(first: 100, orderBy: "orderWeight", orderDirection: ASC) {
        totalCount
        edges {
          node {
            ...BasicAssignmentCategory
          }
        }
      }
    }
  }
}
    ${AssignmentCategoryMapFragmentDoc}
${BasicAssignmentCategoryFragmentDoc}`;

/**
 * __useClassAssignmentCategoriesQuery__
 *
 * To run a query within a React component, call `useClassAssignmentCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassAssignmentCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassAssignmentCategoriesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassAssignmentCategoriesQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassAssignmentCategoriesQuery, ClassAssignmentCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassAssignmentCategoriesQuery, ClassAssignmentCategoriesQueryVariables>(ClassAssignmentCategoriesDocument, options);
      }
export function useClassAssignmentCategoriesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassAssignmentCategoriesQuery, ClassAssignmentCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassAssignmentCategoriesQuery, ClassAssignmentCategoriesQueryVariables>(ClassAssignmentCategoriesDocument, options);
        }
export type ClassAssignmentCategoriesQueryHookResult = ReturnType<typeof useClassAssignmentCategoriesQuery>;
export type ClassAssignmentCategoriesLazyQueryHookResult = ReturnType<typeof useClassAssignmentCategoriesLazyQuery>;
export type ClassAssignmentCategoriesQueryResult = ApolloReactCommon.QueryResult<ClassAssignmentCategoriesQuery, ClassAssignmentCategoriesQueryVariables>;
export const ClassAssignmentCategoriesWithGradesDocument = gql`
    query classAssignmentCategoriesWithGrades($classId: ID!, $studentIds: [ID!]) {
  node(id: $classId) {
    id
    ... on Class {
      assignmentCategories(first: 10, orderBy: "orderWeight", orderDirection: ASC) {
        edges {
          node {
            ...BasicAssignmentCategory
            grade(studentIds: $studentIds)
            points(studentIds: $studentIds)
            totalPoints(studentIds: $studentIds)
            assignments(first: 200, studentIds: $studentIds) {
              edges {
                node {
                  id
                  dueAt
                  totalPoints
                  name
                  rawConfiguration: configuration(raw: true)
                  submissions(first: 1, submitted: true, studentIds: $studentIds) {
                    averageGrade
                    edges {
                      node {
                        id
                        grade
                        submittedAt
                        reviewed
                        comments
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${BasicAssignmentCategoryFragmentDoc}`;

/**
 * __useClassAssignmentCategoriesWithGradesQuery__
 *
 * To run a query within a React component, call `useClassAssignmentCategoriesWithGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassAssignmentCategoriesWithGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassAssignmentCategoriesWithGradesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      studentIds: // value for 'studentIds'
 *   },
 * });
 */
export function useClassAssignmentCategoriesWithGradesQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassAssignmentCategoriesWithGradesQuery, ClassAssignmentCategoriesWithGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassAssignmentCategoriesWithGradesQuery, ClassAssignmentCategoriesWithGradesQueryVariables>(ClassAssignmentCategoriesWithGradesDocument, options);
      }
export function useClassAssignmentCategoriesWithGradesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassAssignmentCategoriesWithGradesQuery, ClassAssignmentCategoriesWithGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassAssignmentCategoriesWithGradesQuery, ClassAssignmentCategoriesWithGradesQueryVariables>(ClassAssignmentCategoriesWithGradesDocument, options);
        }
export type ClassAssignmentCategoriesWithGradesQueryHookResult = ReturnType<typeof useClassAssignmentCategoriesWithGradesQuery>;
export type ClassAssignmentCategoriesWithGradesLazyQueryHookResult = ReturnType<typeof useClassAssignmentCategoriesWithGradesLazyQuery>;
export type ClassAssignmentCategoriesWithGradesQueryResult = ApolloReactCommon.QueryResult<ClassAssignmentCategoriesWithGradesQuery, ClassAssignmentCategoriesWithGradesQueryVariables>;
export const ClassGeneratedProvidersDocument = gql`
    query classGeneratedProviders($id: ID!) {
  node(id: $id) {
    id
    ... on Class {
      generatedProviders
    }
  }
}
    `;

/**
 * __useClassGeneratedProvidersQuery__
 *
 * To run a query within a React component, call `useClassGeneratedProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassGeneratedProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassGeneratedProvidersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassGeneratedProvidersQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassGeneratedProvidersQuery, ClassGeneratedProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassGeneratedProvidersQuery, ClassGeneratedProvidersQueryVariables>(ClassGeneratedProvidersDocument, options);
      }
export function useClassGeneratedProvidersLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassGeneratedProvidersQuery, ClassGeneratedProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassGeneratedProvidersQuery, ClassGeneratedProvidersQueryVariables>(ClassGeneratedProvidersDocument, options);
        }
export type ClassGeneratedProvidersQueryHookResult = ReturnType<typeof useClassGeneratedProvidersQuery>;
export type ClassGeneratedProvidersLazyQueryHookResult = ReturnType<typeof useClassGeneratedProvidersLazyQuery>;
export type ClassGeneratedProvidersQueryResult = ApolloReactCommon.QueryResult<ClassGeneratedProvidersQuery, ClassGeneratedProvidersQueryVariables>;
export const ClassGradeDocument = gql`
    query classGrade($id: ID!) {
  node(id: $id) {
    ... on Class {
      id
      averageGrade
    }
  }
}
    `;

/**
 * __useClassGradeQuery__
 *
 * To run a query within a React component, call `useClassGradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassGradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassGradeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassGradeQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassGradeQuery, ClassGradeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassGradeQuery, ClassGradeQueryVariables>(ClassGradeDocument, options);
      }
export function useClassGradeLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassGradeQuery, ClassGradeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassGradeQuery, ClassGradeQueryVariables>(ClassGradeDocument, options);
        }
export type ClassGradeQueryHookResult = ReturnType<typeof useClassGradeQuery>;
export type ClassGradeLazyQueryHookResult = ReturnType<typeof useClassGradeLazyQuery>;
export type ClassGradeQueryResult = ApolloReactCommon.QueryResult<ClassGradeQuery, ClassGradeQueryVariables>;
export const ClassGroupPresetDocument = gql`
    query classGroupPreset($id: ID!) {
  node(id: $id) {
    id
    ...ClassGroupPreset
  }
}
    ${ClassGroupPresetFragmentDoc}`;

/**
 * __useClassGroupPresetQuery__
 *
 * To run a query within a React component, call `useClassGroupPresetQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassGroupPresetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassGroupPresetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassGroupPresetQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>(ClassGroupPresetDocument, options);
      }
export function useClassGroupPresetLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>(ClassGroupPresetDocument, options);
        }
export type ClassGroupPresetQueryHookResult = ReturnType<typeof useClassGroupPresetQuery>;
export type ClassGroupPresetLazyQueryHookResult = ReturnType<typeof useClassGroupPresetLazyQuery>;
export type ClassGroupPresetQueryResult = ApolloReactCommon.QueryResult<ClassGroupPresetQuery, ClassGroupPresetQueryVariables>;
export const ClassGroupPresetsDocument = gql`
    query classGroupPresets($classId: ID!, $first: Int = 32, $after: String, $orderBy: String, $orderDirection: OrderDirection, $name: String) {
  node(id: $classId) {
    id
    ... on Class {
      groupPresets(
        first: $first
        after: $after
        orderBy: $orderBy
        orderDirection: $orderDirection
        name: $name
      ) {
        pageInfo {
          endCursor
        }
        edges {
          node {
            ...BasicClassGroupPreset
          }
        }
      }
    }
  }
}
    ${BasicClassGroupPresetFragmentDoc}`;

/**
 * __useClassGroupPresetsQuery__
 *
 * To run a query within a React component, call `useClassGroupPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassGroupPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassGroupPresetsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useClassGroupPresetsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassGroupPresetsQuery, ClassGroupPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassGroupPresetsQuery, ClassGroupPresetsQueryVariables>(ClassGroupPresetsDocument, options);
      }
export function useClassGroupPresetsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassGroupPresetsQuery, ClassGroupPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassGroupPresetsQuery, ClassGroupPresetsQueryVariables>(ClassGroupPresetsDocument, options);
        }
export type ClassGroupPresetsQueryHookResult = ReturnType<typeof useClassGroupPresetsQuery>;
export type ClassGroupPresetsLazyQueryHookResult = ReturnType<typeof useClassGroupPresetsLazyQuery>;
export type ClassGroupPresetsQueryResult = ApolloReactCommon.QueryResult<ClassGroupPresetsQuery, ClassGroupPresetsQueryVariables>;
export const CreateAssignmentCategoryDocument = gql`
    mutation createAssignmentCategory($input: CreateAssignmentCategoryInput!) {
  createAssignmentCategory(input: $input) {
    assignmentCategory {
      ...BasicAssignmentCategory
    }
  }
}
    ${BasicAssignmentCategoryFragmentDoc}`;
export type CreateAssignmentCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateAssignmentCategoryMutation, CreateAssignmentCategoryMutationVariables>;

/**
 * __useCreateAssignmentCategoryMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentCategoryMutation, { data, loading, error }] = useCreateAssignmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentCategoryMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateAssignmentCategoryMutation, CreateAssignmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateAssignmentCategoryMutation, CreateAssignmentCategoryMutationVariables>(CreateAssignmentCategoryDocument, options);
      }
export type CreateAssignmentCategoryMutationHookResult = ReturnType<typeof useCreateAssignmentCategoryMutation>;
export type CreateAssignmentCategoryMutationResult = ApolloReactCommon.MutationResult<CreateAssignmentCategoryMutation>;
export type CreateAssignmentCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssignmentCategoryMutation, CreateAssignmentCategoryMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation createAssignment($input: CreateAssignmentInput!, $studentClassId: ID = "", $useCurrentSubmissions: Boolean = false, $first: Int = 1, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC) {
  createAssignment(input: $input) {
    assignment {
      ...BasicAssignment
      ...AssignmentSubmissions
      ...AssignmentAssignedStudents
    }
  }
}
    ${BasicAssignmentFragmentDoc}
${AssignmentSubmissionsFragmentDoc}
${AssignmentAssignedStudentsFragmentDoc}`;
export type CreateAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      studentClassId: // value for 'studentClassId'
 *      useCurrentSubmissions: // value for 'useCurrentSubmissions'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const CreateAssignmentTemplateDocument = gql`
    mutation createAssignmentTemplate($input: AssignmentTemplateInput!) {
  createAssignmentTemplate(input: $input) {
    assignmentTemplate {
      ...AssignmentTemplate
    }
  }
}
    ${AssignmentTemplateFragmentDoc}`;
export type CreateAssignmentTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateAssignmentTemplateMutation, CreateAssignmentTemplateMutationVariables>;

/**
 * __useCreateAssignmentTemplateMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentTemplateMutation, { data, loading, error }] = useCreateAssignmentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssignmentTemplateMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateAssignmentTemplateMutation, CreateAssignmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateAssignmentTemplateMutation, CreateAssignmentTemplateMutationVariables>(CreateAssignmentTemplateDocument, options);
      }
export type CreateAssignmentTemplateMutationHookResult = ReturnType<typeof useCreateAssignmentTemplateMutation>;
export type CreateAssignmentTemplateMutationResult = ApolloReactCommon.MutationResult<CreateAssignmentTemplateMutation>;
export type CreateAssignmentTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAssignmentTemplateMutation, CreateAssignmentTemplateMutationVariables>;
export const CreateClassGroupPresetDocument = gql`
    mutation createClassGroupPreset($input: CreateClassGroupPresetInput!) {
  createClassGroupPreset(input: $input) {
    classGroupPreset {
      ...ClassGroupPreset
    }
  }
}
    ${ClassGroupPresetFragmentDoc}`;
export type CreateClassGroupPresetMutationFn = ApolloReactCommon.MutationFunction<CreateClassGroupPresetMutation, CreateClassGroupPresetMutationVariables>;

/**
 * __useCreateClassGroupPresetMutation__
 *
 * To run a mutation, you first call `useCreateClassGroupPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassGroupPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassGroupPresetMutation, { data, loading, error }] = useCreateClassGroupPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassGroupPresetMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateClassGroupPresetMutation, CreateClassGroupPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateClassGroupPresetMutation, CreateClassGroupPresetMutationVariables>(CreateClassGroupPresetDocument, options);
      }
export type CreateClassGroupPresetMutationHookResult = ReturnType<typeof useCreateClassGroupPresetMutation>;
export type CreateClassGroupPresetMutationResult = ApolloReactCommon.MutationResult<CreateClassGroupPresetMutation>;
export type CreateClassGroupPresetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClassGroupPresetMutation, CreateClassGroupPresetMutationVariables>;
export const CreateRubricTemplateDocument = gql`
    mutation createRubricTemplate($input: RubricTemplateInput!) {
  createRubricTemplate(input: $input) {
    rubricTemplate {
      ...RubricTemplate
    }
  }
}
    ${RubricTemplateFragmentDoc}`;
export type CreateRubricTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateRubricTemplateMutation, CreateRubricTemplateMutationVariables>;

/**
 * __useCreateRubricTemplateMutation__
 *
 * To run a mutation, you first call `useCreateRubricTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRubricTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRubricTemplateMutation, { data, loading, error }] = useCreateRubricTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRubricTemplateMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateRubricTemplateMutation, CreateRubricTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateRubricTemplateMutation, CreateRubricTemplateMutationVariables>(CreateRubricTemplateDocument, options);
      }
export type CreateRubricTemplateMutationHookResult = ReturnType<typeof useCreateRubricTemplateMutation>;
export type CreateRubricTemplateMutationResult = ApolloReactCommon.MutationResult<CreateRubricTemplateMutation>;
export type CreateRubricTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRubricTemplateMutation, CreateRubricTemplateMutationVariables>;
export const DeleteAssignmentCategoryDocument = gql`
    mutation deleteAssignmentCategory($input: DeleteAssignmentCategoryInput!) {
  deleteAssignmentCategory(input: $input) {
    id
    numDeleted
  }
}
    `;
export type DeleteAssignmentCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteAssignmentCategoryMutation, DeleteAssignmentCategoryMutationVariables>;

/**
 * __useDeleteAssignmentCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentCategoryMutation, { data, loading, error }] = useDeleteAssignmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssignmentCategoryMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<DeleteAssignmentCategoryMutation, DeleteAssignmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<DeleteAssignmentCategoryMutation, DeleteAssignmentCategoryMutationVariables>(DeleteAssignmentCategoryDocument, options);
      }
export type DeleteAssignmentCategoryMutationHookResult = ReturnType<typeof useDeleteAssignmentCategoryMutation>;
export type DeleteAssignmentCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteAssignmentCategoryMutation>;
export type DeleteAssignmentCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAssignmentCategoryMutation, DeleteAssignmentCategoryMutationVariables>;
export const GeneratedAssignmentsDocument = gql`
    query generatedAssignments($first: Int = 16, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC, $filters: AssignmentFiltersInput) {
  viewer {
    id
    teacher {
      id
      assignments(
        first: $first
        after: $after
        orderBy: $orderBy
        orderDirection: $orderDirection
        filters: $filters
      ) {
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            ...BasicAssignment
            submissions {
              averageGrade
              totalCount
            }
          }
        }
      }
    }
  }
}
    ${BasicAssignmentFragmentDoc}`;

/**
 * __useGeneratedAssignmentsQuery__
 *
 * To run a query within a React component, call `useGeneratedAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedAssignmentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGeneratedAssignmentsQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<GeneratedAssignmentsQuery, GeneratedAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GeneratedAssignmentsQuery, GeneratedAssignmentsQueryVariables>(GeneratedAssignmentsDocument, options);
      }
export function useGeneratedAssignmentsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GeneratedAssignmentsQuery, GeneratedAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GeneratedAssignmentsQuery, GeneratedAssignmentsQueryVariables>(GeneratedAssignmentsDocument, options);
        }
export type GeneratedAssignmentsQueryHookResult = ReturnType<typeof useGeneratedAssignmentsQuery>;
export type GeneratedAssignmentsLazyQueryHookResult = ReturnType<typeof useGeneratedAssignmentsLazyQuery>;
export type GeneratedAssignmentsQueryResult = ApolloReactCommon.QueryResult<GeneratedAssignmentsQuery, GeneratedAssignmentsQueryVariables>;
export const GeneratedGroupsConfigurationDocument = gql`
    query generatedGroupsConfiguration($input: GeneratedGroupsConfigurationInput!) {
  generatedGroupsConfiguration(input: $input) {
    groups
  }
}
    `;

/**
 * __useGeneratedGroupsConfigurationQuery__
 *
 * To run a query within a React component, call `useGeneratedGroupsConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedGroupsConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedGroupsConfigurationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGeneratedGroupsConfigurationQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GeneratedGroupsConfigurationQuery, GeneratedGroupsConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GeneratedGroupsConfigurationQuery, GeneratedGroupsConfigurationQueryVariables>(GeneratedGroupsConfigurationDocument, options);
      }
export function useGeneratedGroupsConfigurationLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GeneratedGroupsConfigurationQuery, GeneratedGroupsConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GeneratedGroupsConfigurationQuery, GeneratedGroupsConfigurationQueryVariables>(GeneratedGroupsConfigurationDocument, options);
        }
export type GeneratedGroupsConfigurationQueryHookResult = ReturnType<typeof useGeneratedGroupsConfigurationQuery>;
export type GeneratedGroupsConfigurationLazyQueryHookResult = ReturnType<typeof useGeneratedGroupsConfigurationLazyQuery>;
export type GeneratedGroupsConfigurationQueryResult = ApolloReactCommon.QueryResult<GeneratedGroupsConfigurationQuery, GeneratedGroupsConfigurationQueryVariables>;
export const GradeAssignmentSubmissionDocument = gql`
    mutation gradeAssignmentSubmission($input: GradeAssignmentSubmissionInput!) {
  gradeAssignmentSubmission(input: $input) {
    assignmentSubmission {
      ...BasicAssignmentSubmission
      assignmentId
      classId
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}`;
export type GradeAssignmentSubmissionMutationFn = ApolloReactCommon.MutationFunction<GradeAssignmentSubmissionMutation, GradeAssignmentSubmissionMutationVariables>;

/**
 * __useGradeAssignmentSubmissionMutation__
 *
 * To run a mutation, you first call `useGradeAssignmentSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGradeAssignmentSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gradeAssignmentSubmissionMutation, { data, loading, error }] = useGradeAssignmentSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGradeAssignmentSubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<GradeAssignmentSubmissionMutation, GradeAssignmentSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<GradeAssignmentSubmissionMutation, GradeAssignmentSubmissionMutationVariables>(GradeAssignmentSubmissionDocument, options);
      }
export type GradeAssignmentSubmissionMutationHookResult = ReturnType<typeof useGradeAssignmentSubmissionMutation>;
export type GradeAssignmentSubmissionMutationResult = ApolloReactCommon.MutationResult<GradeAssignmentSubmissionMutation>;
export type GradeAssignmentSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<GradeAssignmentSubmissionMutation, GradeAssignmentSubmissionMutationVariables>;
export const GradeAssignmentSubmissionRefetchDocument = gql`
    query gradeAssignmentSubmissionRefetch($classId: ID!, $assignmentId: ID!) {
  class: node(id: $classId) {
    id
    ... on Class {
      averageGrade
      students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          ...ClassStudentEdge
        }
      }
    }
  }
  assignment: node(id: $assignmentId) {
    id
    ... on Assignment {
      submissions(first: 400, submitted: true) {
        averageGrade
        averagePoints
        edges {
          node {
            id
            grade
            grades
            comments
            gradedAt
            reviewed
            isLate
            submittedAt
          }
        }
      }
    }
  }
}
    ${ClassStudentEdgeFragmentDoc}`;

/**
 * __useGradeAssignmentSubmissionRefetchQuery__
 *
 * To run a query within a React component, call `useGradeAssignmentSubmissionRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradeAssignmentSubmissionRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradeAssignmentSubmissionRefetchQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGradeAssignmentSubmissionRefetchQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GradeAssignmentSubmissionRefetchQuery, GradeAssignmentSubmissionRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GradeAssignmentSubmissionRefetchQuery, GradeAssignmentSubmissionRefetchQueryVariables>(GradeAssignmentSubmissionRefetchDocument, options);
      }
export function useGradeAssignmentSubmissionRefetchLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GradeAssignmentSubmissionRefetchQuery, GradeAssignmentSubmissionRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GradeAssignmentSubmissionRefetchQuery, GradeAssignmentSubmissionRefetchQueryVariables>(GradeAssignmentSubmissionRefetchDocument, options);
        }
export type GradeAssignmentSubmissionRefetchQueryHookResult = ReturnType<typeof useGradeAssignmentSubmissionRefetchQuery>;
export type GradeAssignmentSubmissionRefetchLazyQueryHookResult = ReturnType<typeof useGradeAssignmentSubmissionRefetchLazyQuery>;
export type GradeAssignmentSubmissionRefetchQueryResult = ApolloReactCommon.QueryResult<GradeAssignmentSubmissionRefetchQuery, GradeAssignmentSubmissionRefetchQueryVariables>;
export const GradesByCategoryDocument = gql`
    query gradesByCategory($assignmentId: ID!) {
  node(id: $assignmentId) {
    ... on Assignment {
      id
      gradesByCategory {
        ...AssignmentGradesByCategory
      }
    }
  }
}
    ${AssignmentGradesByCategoryFragmentDoc}`;

/**
 * __useGradesByCategoryQuery__
 *
 * To run a query within a React component, call `useGradesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGradesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGradesByCategoryQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGradesByCategoryQuery(baseOptions: ApolloReactCommon.QueryHookOptions<GradesByCategoryQuery, GradesByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GradesByCategoryQuery, GradesByCategoryQueryVariables>(GradesByCategoryDocument, options);
      }
export function useGradesByCategoryLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GradesByCategoryQuery, GradesByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GradesByCategoryQuery, GradesByCategoryQueryVariables>(GradesByCategoryDocument, options);
        }
export type GradesByCategoryQueryHookResult = ReturnType<typeof useGradesByCategoryQuery>;
export type GradesByCategoryLazyQueryHookResult = ReturnType<typeof useGradesByCategoryLazyQuery>;
export type GradesByCategoryQueryResult = ApolloReactCommon.QueryResult<GradesByCategoryQuery, GradesByCategoryQueryVariables>;
export const GroupGenerationModesDocument = gql`
    query groupGenerationModes {
  generateGroupModes {
    ...GenerateGroupModeInfo
  }
  generateLeaderModes {
    ...GenerateLeaderModeInfo
  }
}
    ${GenerateGroupModeInfoFragmentDoc}
${GenerateLeaderModeInfoFragmentDoc}`;

/**
 * __useGroupGenerationModesQuery__
 *
 * To run a query within a React component, call `useGroupGenerationModesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupGenerationModesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupGenerationModesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupGenerationModesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<GroupGenerationModesQuery, GroupGenerationModesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<GroupGenerationModesQuery, GroupGenerationModesQueryVariables>(GroupGenerationModesDocument, options);
      }
export function useGroupGenerationModesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<GroupGenerationModesQuery, GroupGenerationModesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<GroupGenerationModesQuery, GroupGenerationModesQueryVariables>(GroupGenerationModesDocument, options);
        }
export type GroupGenerationModesQueryHookResult = ReturnType<typeof useGroupGenerationModesQuery>;
export type GroupGenerationModesLazyQueryHookResult = ReturnType<typeof useGroupGenerationModesLazyQuery>;
export type GroupGenerationModesQueryResult = ApolloReactCommon.QueryResult<GroupGenerationModesQuery, GroupGenerationModesQueryVariables>;
export const ImportGradeCategoriesDocument = gql`
    mutation importGradeCategories($input: ImportGradeCategoriesInput!) {
  importGradeCategories(input: $input) {
    class {
      id
    }
  }
}
    `;
export type ImportGradeCategoriesMutationFn = ApolloReactCommon.MutationFunction<ImportGradeCategoriesMutation, ImportGradeCategoriesMutationVariables>;

/**
 * __useImportGradeCategoriesMutation__
 *
 * To run a mutation, you first call `useImportGradeCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportGradeCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importGradeCategoriesMutation, { data, loading, error }] = useImportGradeCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportGradeCategoriesMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ImportGradeCategoriesMutation, ImportGradeCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ImportGradeCategoriesMutation, ImportGradeCategoriesMutationVariables>(ImportGradeCategoriesDocument, options);
      }
export type ImportGradeCategoriesMutationHookResult = ReturnType<typeof useImportGradeCategoriesMutation>;
export type ImportGradeCategoriesMutationResult = ApolloReactCommon.MutationResult<ImportGradeCategoriesMutation>;
export type ImportGradeCategoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportGradeCategoriesMutation, ImportGradeCategoriesMutationVariables>;
export const ResetAssignmentSubmissionsDocument = gql`
    mutation resetAssignmentSubmissions($input: ResetAssignmentSubmissionsInput!) {
  resetAssignmentSubmissions(input: $input) {
    submissionIds
  }
}
    `;
export type ResetAssignmentSubmissionsMutationFn = ApolloReactCommon.MutationFunction<ResetAssignmentSubmissionsMutation, ResetAssignmentSubmissionsMutationVariables>;

/**
 * __useResetAssignmentSubmissionsMutation__
 *
 * To run a mutation, you first call `useResetAssignmentSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAssignmentSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAssignmentSubmissionsMutation, { data, loading, error }] = useResetAssignmentSubmissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetAssignmentSubmissionsMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ResetAssignmentSubmissionsMutation, ResetAssignmentSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ResetAssignmentSubmissionsMutation, ResetAssignmentSubmissionsMutationVariables>(ResetAssignmentSubmissionsDocument, options);
      }
export type ResetAssignmentSubmissionsMutationHookResult = ReturnType<typeof useResetAssignmentSubmissionsMutation>;
export type ResetAssignmentSubmissionsMutationResult = ApolloReactCommon.MutationResult<ResetAssignmentSubmissionsMutation>;
export type ResetAssignmentSubmissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetAssignmentSubmissionsMutation, ResetAssignmentSubmissionsMutationVariables>;
export const ResetGeneratedAssignmentDocument = gql`
    mutation resetGeneratedAssignment($input: ResetGeneratedAssignmentInput!) {
  resetGeneratedAssignment(input: $input) {
    assignment {
      ...BasicAssignment
      ...AssignmentAssignedStudents
    }
  }
}
    ${BasicAssignmentFragmentDoc}
${AssignmentAssignedStudentsFragmentDoc}`;
export type ResetGeneratedAssignmentMutationFn = ApolloReactCommon.MutationFunction<ResetGeneratedAssignmentMutation, ResetGeneratedAssignmentMutationVariables>;

/**
 * __useResetGeneratedAssignmentMutation__
 *
 * To run a mutation, you first call `useResetGeneratedAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetGeneratedAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetGeneratedAssignmentMutation, { data, loading, error }] = useResetGeneratedAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetGeneratedAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ResetGeneratedAssignmentMutation, ResetGeneratedAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ResetGeneratedAssignmentMutation, ResetGeneratedAssignmentMutationVariables>(ResetGeneratedAssignmentDocument, options);
      }
export type ResetGeneratedAssignmentMutationHookResult = ReturnType<typeof useResetGeneratedAssignmentMutation>;
export type ResetGeneratedAssignmentMutationResult = ApolloReactCommon.MutationResult<ResetGeneratedAssignmentMutation>;
export type ResetGeneratedAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetGeneratedAssignmentMutation, ResetGeneratedAssignmentMutationVariables>;
export const ResetGeneratedAssignmentSubmissionDocument = gql`
    mutation resetGeneratedAssignmentSubmission($input: ResetGeneratedAssignmentSubmissionInput!) {
  resetGeneratedAssignmentSubmission(input: $input) {
    assignmentSubmission {
      ...BasicAssignmentSubmission
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}`;
export type ResetGeneratedAssignmentSubmissionMutationFn = ApolloReactCommon.MutationFunction<ResetGeneratedAssignmentSubmissionMutation, ResetGeneratedAssignmentSubmissionMutationVariables>;

/**
 * __useResetGeneratedAssignmentSubmissionMutation__
 *
 * To run a mutation, you first call `useResetGeneratedAssignmentSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetGeneratedAssignmentSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetGeneratedAssignmentSubmissionMutation, { data, loading, error }] = useResetGeneratedAssignmentSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetGeneratedAssignmentSubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ResetGeneratedAssignmentSubmissionMutation, ResetGeneratedAssignmentSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ResetGeneratedAssignmentSubmissionMutation, ResetGeneratedAssignmentSubmissionMutationVariables>(ResetGeneratedAssignmentSubmissionDocument, options);
      }
export type ResetGeneratedAssignmentSubmissionMutationHookResult = ReturnType<typeof useResetGeneratedAssignmentSubmissionMutation>;
export type ResetGeneratedAssignmentSubmissionMutationResult = ApolloReactCommon.MutationResult<ResetGeneratedAssignmentSubmissionMutation>;
export type ResetGeneratedAssignmentSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetGeneratedAssignmentSubmissionMutation, ResetGeneratedAssignmentSubmissionMutationVariables>;
export const RetrySubmissionDocument = gql`
    mutation retrySubmission($input: RetrySubmissionInput!, $classId: ID!, $studentClassId: ID = "", $useCurrentSubmissions: Boolean = false, $first: Int = 32, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC) {
  retrySubmission(input: $input) {
    assignmentSubmission {
      id
      assignment {
        ...BasicAssignment
        ...AssignmentSubmissions
        ...AssignmentAssignedStudents
        ...AssignmentWithConfiguration
        ...AssignmentWithRemainingAttempts
        ...AssignmentWithGroups
      }
    }
  }
}
    ${BasicAssignmentFragmentDoc}
${AssignmentSubmissionsFragmentDoc}
${AssignmentAssignedStudentsFragmentDoc}
${AssignmentWithConfigurationFragmentDoc}
${AssignmentWithRemainingAttemptsFragmentDoc}
${AssignmentWithGroupsFragmentDoc}`;
export type RetrySubmissionMutationFn = ApolloReactCommon.MutationFunction<RetrySubmissionMutation, RetrySubmissionMutationVariables>;

/**
 * __useRetrySubmissionMutation__
 *
 * To run a mutation, you first call `useRetrySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrySubmissionMutation, { data, loading, error }] = useRetrySubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      classId: // value for 'classId'
 *      studentClassId: // value for 'studentClassId'
 *      useCurrentSubmissions: // value for 'useCurrentSubmissions'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useRetrySubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<RetrySubmissionMutation, RetrySubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<RetrySubmissionMutation, RetrySubmissionMutationVariables>(RetrySubmissionDocument, options);
      }
export type RetrySubmissionMutationHookResult = ReturnType<typeof useRetrySubmissionMutation>;
export type RetrySubmissionMutationResult = ApolloReactCommon.MutationResult<RetrySubmissionMutation>;
export type RetrySubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<RetrySubmissionMutation, RetrySubmissionMutationVariables>;
export const RubricTemplateDocument = gql`
    query rubricTemplate($id: ID!) {
  node(id: $id) {
    ...RubricTemplate
  }
}
    ${RubricTemplateFragmentDoc}`;

/**
 * __useRubricTemplateQuery__
 *
 * To run a query within a React component, call `useRubricTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRubricTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRubricTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRubricTemplateQuery(baseOptions: ApolloReactCommon.QueryHookOptions<RubricTemplateQuery, RubricTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<RubricTemplateQuery, RubricTemplateQueryVariables>(RubricTemplateDocument, options);
      }
export function useRubricTemplateLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<RubricTemplateQuery, RubricTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<RubricTemplateQuery, RubricTemplateQueryVariables>(RubricTemplateDocument, options);
        }
export type RubricTemplateQueryHookResult = ReturnType<typeof useRubricTemplateQuery>;
export type RubricTemplateLazyQueryHookResult = ReturnType<typeof useRubricTemplateLazyQuery>;
export type RubricTemplateQueryResult = ApolloReactCommon.QueryResult<RubricTemplateQuery, RubricTemplateQueryVariables>;
export const RubricTemplatesDocument = gql`
    query rubricTemplates($first: Int = 32, $after: String, $orderBy: String = "name", $orderDirection: OrderDirection = ASC, $name: String) {
  viewer {
    id
    rubricTemplates(
      first: $first
      after: $after
      orderBy: $orderBy
      orderDirection: $orderDirection
      name: $name
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RubricTemplate
        }
      }
    }
  }
}
    ${RubricTemplateFragmentDoc}`;

/**
 * __useRubricTemplatesQuery__
 *
 * To run a query within a React component, call `useRubricTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRubricTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRubricTemplatesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRubricTemplatesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<RubricTemplatesQuery, RubricTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<RubricTemplatesQuery, RubricTemplatesQueryVariables>(RubricTemplatesDocument, options);
      }
export function useRubricTemplatesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<RubricTemplatesQuery, RubricTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<RubricTemplatesQuery, RubricTemplatesQueryVariables>(RubricTemplatesDocument, options);
        }
export type RubricTemplatesQueryHookResult = ReturnType<typeof useRubricTemplatesQuery>;
export type RubricTemplatesLazyQueryHookResult = ReturnType<typeof useRubricTemplatesLazyQuery>;
export type RubricTemplatesQueryResult = ApolloReactCommon.QueryResult<RubricTemplatesQuery, RubricTemplatesQueryVariables>;
export const RunGradeReportDocument = gql`
    mutation runGradeReport($input: RunGradeReportInput!) {
  runGradeReport(input: $input) {
    reportJob {
      id
      downloadUrl
    }
  }
}
    `;
export type RunGradeReportMutationFn = ApolloReactCommon.MutationFunction<RunGradeReportMutation, RunGradeReportMutationVariables>;

/**
 * __useRunGradeReportMutation__
 *
 * To run a mutation, you first call `useRunGradeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunGradeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runGradeReportMutation, { data, loading, error }] = useRunGradeReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunGradeReportMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<RunGradeReportMutation, RunGradeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<RunGradeReportMutation, RunGradeReportMutationVariables>(RunGradeReportDocument, options);
      }
export type RunGradeReportMutationHookResult = ReturnType<typeof useRunGradeReportMutation>;
export type RunGradeReportMutationResult = ApolloReactCommon.MutationResult<RunGradeReportMutation>;
export type RunGradeReportMutationOptions = ApolloReactCommon.BaseMutationOptions<RunGradeReportMutation, RunGradeReportMutationVariables>;
export const SaveAssignmentSubmissionDocument = gql`
    mutation saveAssignmentSubmission($input: SaveAssignmentSubmissionInput!, $classId: ID!, $includeConfiguration: Boolean!) {
  saveAssignmentSubmission(input: $input) {
    assignmentSubmission {
      id
      ...FullAssignmentSubmission
      assignmentConfiguration @include(if: $includeConfiguration)
      overrides @include(if: $includeConfiguration)
    }
    assignment {
      id
      ...BasicAssignment
      ...AssignmentWithRemainingAttempts
    }
  }
}
    ${FullAssignmentSubmissionFragmentDoc}
${BasicAssignmentFragmentDoc}
${AssignmentWithRemainingAttemptsFragmentDoc}`;
export type SaveAssignmentSubmissionMutationFn = ApolloReactCommon.MutationFunction<SaveAssignmentSubmissionMutation, SaveAssignmentSubmissionMutationVariables>;

/**
 * __useSaveAssignmentSubmissionMutation__
 *
 * To run a mutation, you first call `useSaveAssignmentSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssignmentSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssignmentSubmissionMutation, { data, loading, error }] = useSaveAssignmentSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      classId: // value for 'classId'
 *      includeConfiguration: // value for 'includeConfiguration'
 *   },
 * });
 */
export function useSaveAssignmentSubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<SaveAssignmentSubmissionMutation, SaveAssignmentSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<SaveAssignmentSubmissionMutation, SaveAssignmentSubmissionMutationVariables>(SaveAssignmentSubmissionDocument, options);
      }
export type SaveAssignmentSubmissionMutationHookResult = ReturnType<typeof useSaveAssignmentSubmissionMutation>;
export type SaveAssignmentSubmissionMutationResult = ApolloReactCommon.MutationResult<SaveAssignmentSubmissionMutation>;
export type SaveAssignmentSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveAssignmentSubmissionMutation, SaveAssignmentSubmissionMutationVariables>;
export const StudentForfeitAssignmentDocument = gql`
    mutation studentForfeitAssignment($input: ForfeitAssignmentInput!, $classId: ID!) {
  forfeitAssignment(input: $input) {
    assignment {
      id
      remainingAttempts(classId: $classId)
      submissions(first: 100, classIds: [$classId]) {
        edges {
          node {
            ...BasicAssignmentSubmission
            ...AssignmentSubmissionWithConfiguration
          }
        }
      }
    }
  }
}
    ${BasicAssignmentSubmissionFragmentDoc}
${AssignmentSubmissionWithConfigurationFragmentDoc}`;
export type StudentForfeitAssignmentMutationFn = ApolloReactCommon.MutationFunction<StudentForfeitAssignmentMutation, StudentForfeitAssignmentMutationVariables>;

/**
 * __useStudentForfeitAssignmentMutation__
 *
 * To run a mutation, you first call `useStudentForfeitAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentForfeitAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentForfeitAssignmentMutation, { data, loading, error }] = useStudentForfeitAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useStudentForfeitAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<StudentForfeitAssignmentMutation, StudentForfeitAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<StudentForfeitAssignmentMutation, StudentForfeitAssignmentMutationVariables>(StudentForfeitAssignmentDocument, options);
      }
export type StudentForfeitAssignmentMutationHookResult = ReturnType<typeof useStudentForfeitAssignmentMutation>;
export type StudentForfeitAssignmentMutationResult = ApolloReactCommon.MutationResult<StudentForfeitAssignmentMutation>;
export type StudentForfeitAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<StudentForfeitAssignmentMutation, StudentForfeitAssignmentMutationVariables>;
export const SubmitAssignmentDocument = gql`
    mutation submitAssignment($input: SubmitAssignmentInput!, $classId: ID!, $studentClassId: ID = "", $useCurrentSubmissions: Boolean = false, $first: Int = 32, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = ASC) {
  submitAssignment(input: $input) {
    assignmentSubmission {
      ...FullAssignmentSubmission
      assignment {
        id
        ...AssignmentSubmissions
        remainingAttempts(classId: $classId)
      }
    }
  }
}
    ${FullAssignmentSubmissionFragmentDoc}
${AssignmentSubmissionsFragmentDoc}`;
export type SubmitAssignmentMutationFn = ApolloReactCommon.MutationFunction<SubmitAssignmentMutation, SubmitAssignmentMutationVariables>;

/**
 * __useSubmitAssignmentMutation__
 *
 * To run a mutation, you first call `useSubmitAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssignmentMutation, { data, loading, error }] = useSubmitAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      classId: // value for 'classId'
 *      studentClassId: // value for 'studentClassId'
 *      useCurrentSubmissions: // value for 'useCurrentSubmissions'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useSubmitAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<SubmitAssignmentMutation, SubmitAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<SubmitAssignmentMutation, SubmitAssignmentMutationVariables>(SubmitAssignmentDocument, options);
      }
export type SubmitAssignmentMutationHookResult = ReturnType<typeof useSubmitAssignmentMutation>;
export type SubmitAssignmentMutationResult = ApolloReactCommon.MutationResult<SubmitAssignmentMutation>;
export type SubmitAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitAssignmentMutation, SubmitAssignmentMutationVariables>;
export const SyncAssignmentDocument = gql`
    mutation syncAssignment($input: SyncAssignmentInput!) {
  syncAssignment(input: $input) {
    assignment {
      ...AssignmentWithSync
    }
  }
}
    ${AssignmentWithSyncFragmentDoc}`;
export type SyncAssignmentMutationFn = ApolloReactCommon.MutationFunction<SyncAssignmentMutation, SyncAssignmentMutationVariables>;

/**
 * __useSyncAssignmentMutation__
 *
 * To run a mutation, you first call `useSyncAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAssignmentMutation, { data, loading, error }] = useSyncAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<SyncAssignmentMutation, SyncAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<SyncAssignmentMutation, SyncAssignmentMutationVariables>(SyncAssignmentDocument, options);
      }
export type SyncAssignmentMutationHookResult = ReturnType<typeof useSyncAssignmentMutation>;
export type SyncAssignmentMutationResult = ApolloReactCommon.MutationResult<SyncAssignmentMutation>;
export type SyncAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<SyncAssignmentMutation, SyncAssignmentMutationVariables>;
export const TeacherClassTaskSummaryDocument = gql`
    query teacherClassTaskSummary {
  viewer {
    id
    teacher {
      id
      assignmentsDueSoon: assignments(
        first: 15
        filters: {dueDate: {type: AFTER}}
        orderBy: "dueAt"
        orderDirection: ASC
      ) {
        edges {
          node {
            id
            name
            dueAt
            assignmentCategories(first: 1) {
              edges {
                node {
                  id
                  classId
                }
              }
            }
          }
        }
      }
      classes(first: 1000, deleted: false) {
        edges {
          node {
            id
            userTasks(first: 10) {
              ...UserTask
            }
          }
        }
      }
    }
  }
}
    ${UserTaskFragmentDoc}`;

/**
 * __useTeacherClassTaskSummaryQuery__
 *
 * To run a query within a React component, call `useTeacherClassTaskSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherClassTaskSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherClassTaskSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeacherClassTaskSummaryQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<TeacherClassTaskSummaryQuery, TeacherClassTaskSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TeacherClassTaskSummaryQuery, TeacherClassTaskSummaryQueryVariables>(TeacherClassTaskSummaryDocument, options);
      }
export function useTeacherClassTaskSummaryLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TeacherClassTaskSummaryQuery, TeacherClassTaskSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TeacherClassTaskSummaryQuery, TeacherClassTaskSummaryQueryVariables>(TeacherClassTaskSummaryDocument, options);
        }
export type TeacherClassTaskSummaryQueryHookResult = ReturnType<typeof useTeacherClassTaskSummaryQuery>;
export type TeacherClassTaskSummaryLazyQueryHookResult = ReturnType<typeof useTeacherClassTaskSummaryLazyQuery>;
export type TeacherClassTaskSummaryQueryResult = ApolloReactCommon.QueryResult<TeacherClassTaskSummaryQuery, TeacherClassTaskSummaryQueryVariables>;
export const TeacherForfeitAssignmentDocument = gql`
    mutation teacherForfeitAssignment($input: ForfeitAssignmentInput!) {
  forfeitAssignment(input: $input) {
    assignment {
      id
      ...AssignmentAssignedStudents
    }
  }
}
    ${AssignmentAssignedStudentsFragmentDoc}`;
export type TeacherForfeitAssignmentMutationFn = ApolloReactCommon.MutationFunction<TeacherForfeitAssignmentMutation, TeacherForfeitAssignmentMutationVariables>;

/**
 * __useTeacherForfeitAssignmentMutation__
 *
 * To run a mutation, you first call `useTeacherForfeitAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeacherForfeitAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teacherForfeitAssignmentMutation, { data, loading, error }] = useTeacherForfeitAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeacherForfeitAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<TeacherForfeitAssignmentMutation, TeacherForfeitAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<TeacherForfeitAssignmentMutation, TeacherForfeitAssignmentMutationVariables>(TeacherForfeitAssignmentDocument, options);
      }
export type TeacherForfeitAssignmentMutationHookResult = ReturnType<typeof useTeacherForfeitAssignmentMutation>;
export type TeacherForfeitAssignmentMutationResult = ApolloReactCommon.MutationResult<TeacherForfeitAssignmentMutation>;
export type TeacherForfeitAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<TeacherForfeitAssignmentMutation, TeacherForfeitAssignmentMutationVariables>;
export const UpdateAssignmentCategoryDocument = gql`
    mutation updateAssignmentCategory($input: UpdateAssignmentCategoryInput!) {
  updateAssignmentCategory(input: $input) {
    assignmentCategory {
      ...BasicAssignmentCategory
    }
  }
}
    ${BasicAssignmentCategoryFragmentDoc}`;
export type UpdateAssignmentCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateAssignmentCategoryMutation, UpdateAssignmentCategoryMutationVariables>;

/**
 * __useUpdateAssignmentCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentCategoryMutation, { data, loading, error }] = useUpdateAssignmentCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentCategoryMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateAssignmentCategoryMutation, UpdateAssignmentCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateAssignmentCategoryMutation, UpdateAssignmentCategoryMutationVariables>(UpdateAssignmentCategoryDocument, options);
      }
export type UpdateAssignmentCategoryMutationHookResult = ReturnType<typeof useUpdateAssignmentCategoryMutation>;
export type UpdateAssignmentCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentCategoryMutation>;
export type UpdateAssignmentCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentCategoryMutation, UpdateAssignmentCategoryMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation updateAssignment($input: UpdateAssignmentInput!) {
  updateAssignment(input: $input) {
    assignment {
      ...BasicAssignment
      ...AssignmentWithConfiguration
      ...AssignmentAssignedStudents
      ...AssignmentWithGroups
    }
  }
}
    ${BasicAssignmentFragmentDoc}
${AssignmentWithConfigurationFragmentDoc}
${AssignmentAssignedStudentsFragmentDoc}
${AssignmentWithGroupsFragmentDoc}`;
export type UpdateAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const UpdateAssignmentTemplateDocument = gql`
    mutation updateAssignmentTemplate($input: UpdateAssignmentTemplateInput!) {
  updateAssignmentTemplate(input: $input) {
    assignmentTemplate {
      ...AssignmentTemplate
    }
  }
}
    ${AssignmentTemplateFragmentDoc}`;
export type UpdateAssignmentTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateAssignmentTemplateMutation, UpdateAssignmentTemplateMutationVariables>;

/**
 * __useUpdateAssignmentTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentTemplateMutation, { data, loading, error }] = useUpdateAssignmentTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignmentTemplateMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateAssignmentTemplateMutation, UpdateAssignmentTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateAssignmentTemplateMutation, UpdateAssignmentTemplateMutationVariables>(UpdateAssignmentTemplateDocument, options);
      }
export type UpdateAssignmentTemplateMutationHookResult = ReturnType<typeof useUpdateAssignmentTemplateMutation>;
export type UpdateAssignmentTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentTemplateMutation>;
export type UpdateAssignmentTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentTemplateMutation, UpdateAssignmentTemplateMutationVariables>;
export const UpdateClassGroupPresetDocument = gql`
    mutation updateClassGroupPreset($input: UpdateClassGroupPresetInput!) {
  updateClassGroupPreset(input: $input) {
    classGroupPreset {
      ...ClassGroupPreset
    }
  }
}
    ${ClassGroupPresetFragmentDoc}`;
export type UpdateClassGroupPresetMutationFn = ApolloReactCommon.MutationFunction<UpdateClassGroupPresetMutation, UpdateClassGroupPresetMutationVariables>;

/**
 * __useUpdateClassGroupPresetMutation__
 *
 * To run a mutation, you first call `useUpdateClassGroupPresetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassGroupPresetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassGroupPresetMutation, { data, loading, error }] = useUpdateClassGroupPresetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassGroupPresetMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateClassGroupPresetMutation, UpdateClassGroupPresetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateClassGroupPresetMutation, UpdateClassGroupPresetMutationVariables>(UpdateClassGroupPresetDocument, options);
      }
export type UpdateClassGroupPresetMutationHookResult = ReturnType<typeof useUpdateClassGroupPresetMutation>;
export type UpdateClassGroupPresetMutationResult = ApolloReactCommon.MutationResult<UpdateClassGroupPresetMutation>;
export type UpdateClassGroupPresetMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClassGroupPresetMutation, UpdateClassGroupPresetMutationVariables>;
export const UpdateRubricTemplateDocument = gql`
    mutation updateRubricTemplate($input: UpdateRubricTemplateInput!) {
  updateRubricTemplate(input: $input) {
    rubricTemplate {
      ...RubricTemplate
    }
  }
}
    ${RubricTemplateFragmentDoc}`;
export type UpdateRubricTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateRubricTemplateMutation, UpdateRubricTemplateMutationVariables>;

/**
 * __useUpdateRubricTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateRubricTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRubricTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRubricTemplateMutation, { data, loading, error }] = useUpdateRubricTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRubricTemplateMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateRubricTemplateMutation, UpdateRubricTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateRubricTemplateMutation, UpdateRubricTemplateMutationVariables>(UpdateRubricTemplateDocument, options);
      }
export type UpdateRubricTemplateMutationHookResult = ReturnType<typeof useUpdateRubricTemplateMutation>;
export type UpdateRubricTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateRubricTemplateMutation>;
export type UpdateRubricTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRubricTemplateMutation, UpdateRubricTemplateMutationVariables>;
export const UseAssignmentSubmissionDocument = gql`
    mutation useAssignmentSubmission($input: UseAssignmentSubmissionInput!) {
  useAssignmentSubmission(input: $input) {
    assignment {
      id
    }
  }
}
    `;
export type UseAssignmentSubmissionMutationFn = ApolloReactCommon.MutationFunction<UseAssignmentSubmissionMutation, UseAssignmentSubmissionMutationVariables>;

/**
 * __useUseAssignmentSubmissionMutation__
 *
 * To run a mutation, you first call `useUseAssignmentSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseAssignmentSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useAssignmentSubmissionMutation, { data, loading, error }] = useUseAssignmentSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUseAssignmentSubmissionMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UseAssignmentSubmissionMutation, UseAssignmentSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UseAssignmentSubmissionMutation, UseAssignmentSubmissionMutationVariables>(UseAssignmentSubmissionDocument, options);
      }
export type UseAssignmentSubmissionMutationHookResult = ReturnType<typeof useUseAssignmentSubmissionMutation>;
export type UseAssignmentSubmissionMutationResult = ApolloReactCommon.MutationResult<UseAssignmentSubmissionMutation>;
export type UseAssignmentSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UseAssignmentSubmissionMutation, UseAssignmentSubmissionMutationVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}

declare module '@thesisedu/feature-users-react' {
  export interface PermissionsMap extends Record<`${Permission}`, true> {}
}
