import { Form } from '@thesisedu/react'
import React from 'react'

import { RubricGrader } from '../rubric/RubricGrader'
import { transformRubric } from '../rubric/helpers'
import { AssignmentRubricFragment } from '../schema'

export interface SubmissionGradeRubricProps {
  rubric: AssignmentRubricFragment
  disabled?: boolean
}
export function SubmissionGradeRubric({ rubric: _rubric, disabled }: SubmissionGradeRubricProps) {
  const rubric = transformRubric(_rubric)

  // This isn't the nicest way of using the form, but we have to do it this way
  // because we need access to all of the fields at the root of the form (grades
  // and comments).
  const formContext = Form.useReactFormContext()
  return (
    <RubricGrader
      rubric={rubric}
      value={formContext.form.watch()}
      onChange={values => {
        formContext.form.setValue('grades', values.grades)
        formContext.form.setValue('comments', values.comments)
      }}
      disabled={disabled}
    />
  )
}
