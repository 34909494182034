import type { MutateSentryReplayStateChange } from '@thesisedu/feature-sentry-web'

import IntercomWebFeature from '..'

export default function (feature: IntercomWebFeature) {
  feature.hookManager.registerMutateHook<MutateSentryReplayStateChange>(
    'feature-sentry-web:replay-state',
    async (_, { replayId, state }) => {
      const intercom = feature.intercom
      if (intercom) {
        if (state === 'started') {
          intercom('trackEvent', 'sentry-replay-start', { id: replayId })
        } else if (state === 'stopped') {
          intercom('trackEvent', 'sentry-replay-stop', { id: replayId })
        }
      }
    },
  )
}
