import React from 'react'

export function DefaultBadgePlaceholder() {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 102 118'}>
      <defs />
      <path
        fill={'#2A3859'}
        fillRule={'evenodd'}
        d={
          'M49.755.336L1.245 28.582A2.51 2.51 0 000 30.754v56.491a2.51 2.51 0 001.244 2.173l48.51 28.245c.771.45 1.72.45 2.49 0l48.512-28.245A2.512 2.512 0 00102 87.245V30.754c0-.896-.474-1.724-1.244-2.172L52.244.336a2.473 2.473 0 00-2.49 0'
        }
      />
    </svg>
  )
}
