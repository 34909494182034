import { useBulkActionsWithModal } from '@thesisedu/feature-apollo-react'
import {
  useClasses,
  useSelectedClassId,
  useTeacherSelectedClass,
} from '@thesisedu/feature-classes-react'
import { flattenSegments } from '@thesisedu/feature-course-types'
import { Format } from '@thesisedu/feature-utils'
import { Modal, Text, Tooltip, useToast } from '@thesisedu/ui'
import React from 'react'

import { useCourseContext } from '../../contexts/CourseContext'
import { useCopySegmentSettingsMutation } from '../../queries/useCopySegmentSettingsMutation'
import {
  ClassCourseFragment,
  ClassFragment,
  CopySegmentSettingsDocument,
  CopySegmentSettingsMutationVariables,
} from '../../schema'
import { ClassFragmentWithPermissions, Segment } from '../../types'

export interface CopySegmentToClasses {
  copy: (
    segment?: Pick<Segment, 'id' | 'name' | 'childSegments'>,
    classIds?: string[],
  ) => Promise<void>
  otherClasses: (ClassFragment & ClassCourseFragment)[]
  courseName: string
  progressModal: React.ReactElement
  disabled: boolean
}
type Frag = Pick<ClassFragmentWithPermissions, 'canCopySegmentsTo' | 'id' | 'archivedAt' | 'name'>
export function useCopySegmentToClasses(): CopySegmentToClasses {
  const { classes } = useClasses()
  const selectedClassId = useSelectedClassId()
  const { courseVersion } = useCourseContext(true)
  const { cls: selectedClass } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const { confirm, modal: confirmModal } = Modal.useConfirmModal()
  const [run, { modal }] = useBulkActionsWithModal()
  const toast = useToast()
  const otherClasses = (selectedClass as any)?.courseVersion
    ? (classes as Frag[]).filter(
        cls =>
          cls.id !== selectedClassId &&
          !cls.archivedAt &&
          cls.canCopySegmentsTo &&
          (cls as any).courseVersion?.id === (selectedClass as any).courseVersion.id,
      )
    : []
  const [copySettings] = useCopySegmentSettingsMutation()
  return {
    disabled: !otherClasses.length || !selectedClass?.canCopySegmentsFrom,
    progressModal: (
      <>
        {modal}
        {confirmModal}
      </>
    ),
    courseName: courseVersion?.course.label || courseVersion?.course.name || '',
    otherClasses: otherClasses as any,
    copy: async (segment, classIds) => {
      if (!selectedClass) return
      const otherSelectedClasses = classIds
        ? otherClasses.filter(c => classIds.includes(c.id))
        : otherClasses
      let contentPrefix: React.ReactElement
      if (segment) {
        contentPrefix = (
          <span>
            Are you sure you want to copy settings for{' '}
            <strong>
              "{segment.name}"{segment.childSegments?.length ? ' and its children' : ''}
            </strong>
          </span>
        )
      } else {
        contentPrefix = <span>Are you sure you want to copy all settings</span>
      }
      await new Promise<void>(resolve => {
        confirm({
          title: 'Are you sure?',
          confirm: {
            children: 'Yes, I am sure',
            status: 'danger',
          },
          cancel: { children: 'No, go back' },
          width: 600,
          onCancel: () => {
            resolve()
          },
          async onConfirm() {
            try {
              const classIds = otherSelectedClasses.map(c => c.id)
              if (segment) {
                const flatSegments = flattenSegments([segment as Segment]) as Segment[]
                await run<CopySegmentSettingsMutationVariables>({
                  message: 'Copying content',
                  mutation: CopySegmentSettingsDocument,
                  variables: flatSegments.map(s => ({
                    input: {
                      id: selectedClassId!,
                      segmentIds: [s.id],
                      destinationIds: classIds,
                    },
                  })),
                })
              } else {
                const result = await copySettings({
                  variables: {
                    input: {
                      id: selectedClassId!,
                      destinationIds: classIds!,
                    },
                  },
                })
                if (result?.data?.copySegmentSettings?.classes !== undefined) {
                  toast({
                    status: 'success',
                    title: 'Copied class settings!',
                  })
                }
              }
            } finally {
              resolve()
            }
          },
          children: (
            <div>
              <Text>
                {contentPrefix} from <strong>{selectedClass.name}</strong> to{' '}
                <Tooltip title={otherSelectedClasses.map(c => c.name).join(', ')}>
                  <strong>{Format.plural('class', otherSelectedClasses.length)}?</strong>
                </Tooltip>
              </Text>
              {segment ? null : (
                <>
                  <p>Here's what is going to be copied:</p>
                  <ul>
                    <li>Basic enabled / disabled / locked state for content</li>
                    <li>Content order</li>
                    <li>Assignment settings for content</li>
                    <li>Custom-placed content</li>
                  </ul>
                  <p>
                    <strong>
                      Please make sure you would like to do this. It cannot be undone.
                    </strong>
                  </p>
                </>
              )}
            </div>
          ),
        })
      })
    },
  }
}
