import { Feature } from '@thesisedu/feature'
import { addUserTask } from '@thesisedu/feature-user-tasks-react'

import { CompleteAssignmentUserTask } from './CompleteAssignmentUserTask'
import { ReviewAssignmentUserTask } from './ReviewAssignmentUserTask'
import {
  ReviewAssignmentUserTaskFragmentDoc,
  CompleteAssignmentUserTaskFragmentDoc,
  ReviewAssignmentUserTaskFragment,
  CompleteAssignmentUserTaskFragment,
} from '../../schema'

export default function addTasks(feature: Feature) {
  addUserTask<ReviewAssignmentUserTaskFragment>(
    feature,
    ReviewAssignmentUserTaskFragmentDoc,
    ReviewAssignmentUserTask,
  )
  addUserTask<CompleteAssignmentUserTaskFragment>(
    feature,
    CompleteAssignmentUserTaskFragmentDoc,
    CompleteAssignmentUserTask,
  )
}
