import { CaretDownOutlined, CaretUpOutlined, MinusOutlined } from '@ant-design/icons'
import React, { useContext } from 'react'

import { styled, ThemeContext } from './styledTypes'

const OverviewStatsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  > div {
    padding: 0 10px;
    flex-grow: 1;
  }
`
export function OverviewStats(props: React.PropsWithChildren<React.HTMLProps<HTMLDivElement>>) {
  return <OverviewStatsContainer {...(props as any)}>{props.children}</OverviewStatsContainer>
}

const OverviewStatContainer = styled.div`
  line-height: 1.4;
  > span {
    display: block;
  }
  > span.change {
    display: inline-block;
    white-space: nowrap;
    &.change-positive {
      color: ${props => props.theme['@green']};
    }
    &.change-negative {
      color: ${props => props.theme['@red']};
    }
    &.change-neutral {
      color: ${props => props.theme['@gray-7']};
    }
  }
  > strong {
    display: inline-block;
    margin-right: 5px;
  }
  &.size-normal {
    > span {
      font-size: 14px;
    }
    > span.change {
      font-size: 12px;
    }
    > strong {
      font-size: 20px;
    }
  }
  &.size-large {
    > span {
      font-size: 20px;
    }
    > span.change {
      font-size: 16px;
    }
    > strong {
      font-size: 30px;
    }
  }
`

export interface OverviewStatProps {
  name: string
  value: string
  colorKey: string
  size?: string
  change?: string
  changeNumber?: number
  style?: any
}
export function OverviewStat({
  name,
  value,
  colorKey,
  size = 'normal',
  change,
  changeNumber,
  style,
}: OverviewStatProps) {
  const theme = useContext(ThemeContext)
  let changeContent: React.ReactElement | null = null
  if (changeNumber !== undefined) {
    if (changeNumber > 0) {
      changeContent = (
        <span className={'change change-positive'}>
          <CaretUpOutlined /> {change}
        </span>
      )
    } else if (changeNumber < 0) {
      changeContent = (
        <span className={'change change-negative'}>
          <CaretDownOutlined /> {change}
        </span>
      )
    } else if (changeNumber === 0) {
      changeContent = (
        <span className={'change change-neutral'}>
          <MinusOutlined /> No Change
        </span>
      )
    }
  }

  return (
    <OverviewStatContainer className={`size-${size}`} style={style}>
      <span>{name}</span>
      <strong style={{ color: theme[colorKey] }}>{value}</strong>
      {changeContent}
    </OverviewStatContainer>
  )
}
