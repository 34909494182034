import { FeatureReactRoot } from '@thesisedu/feature-react'
import { DevToolsTabResource } from '@thesisedu/feature-react/devtools'

import { DetailedLogsTab } from './DetailedLogs/DetailedLogsTab'

export function install(root: FeatureReactRoot<any>) {
  root.deps.resources.addResource<DevToolsTabResource>({
    type: 'DevToolsTab',
    identifier: 'DetailedLogs',
    title: 'Server Console',
    component: DetailedLogsTab,
    noPadding: true,
  })
}
