import { getSegmentName } from '@thesisedu/feature-course-types'
import { Block, Text } from '@thesisedu/ui'
import { styled } from '@thesisedu/web'
import React from 'react'

import { CourseViewerSegmentHeaderActions } from './CourseViewerSegmentHeaderActions'
import { CourseViewerSegmentHeaderPortal } from './CourseViewerSegmentHeaderPortal'
import { SegmentNavigationTabs } from './SegmentNavigationTabs'
import { SegmentParents } from './SegmentParents'
import { Segment } from '../types'

export interface CourseViewerSegmentHeaderProps {
  segment: Segment
  actions?: React.ReactElement
  hideParents?: boolean
  isPreview?: boolean
}
export function CourseViewerSegmentHeader({
  segment,
  actions,
  hideParents,
  isPreview,
}: CourseViewerSegmentHeaderProps) {
  return (
    <Container>
      <div className={'segment-header'}>
        {hideParents ? null : <SegmentParents segment={segment} />}
        <HeaderTitle>
          <Text level={'h1'} truncate bottom={false}>
            {getSegmentName(segment)}
          </Text>
          <Block left>
            <CourseViewerSegmentHeaderActions segment={segment} actions={actions} />
          </Block>
          <CourseViewerSegmentHeaderPortal />
        </HeaderTitle>
      </div>
      <SegmentNavigationTabs />
    </Container>
  )
}

const Container = styled.div`
  > .segment-header,
  > .ant-tabs {
    margin: 0 -${props => props.theme['@size-m']} 0 -${props => props.theme['@size-l']};
    padding: 0 ${props => props.theme['@size-l']};
    background: ${props => props.theme['@background-color-base']};
    > .ant-tabs-nav {
      margin-bottom: 0;
      .ant-tabs-tab {
        transition: background 0.25s linear;
        border-top-left-radius: ${props => props.theme['@border-radius-base']};
        border-top-right-radius: ${props => props.theme['@border-radius-base']};
        padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-m']};
        margin-right: 0;
        background: transparent;
        &.ant-tabs-tab-active {
          background: ${props => props.theme['@white']};
        }
      }
    }
  }
  > .ant-tabs > .ant-tabs-content-holder {
    margin: 0 -${props => props.theme['@size-l']};
    padding: 0 ${props => props.theme['@size-l']};
    padding-top: ${props => props.theme['@size-l']};
    background: ${props => props.theme['@white']};
  }
  > .segment-header {
    margin-top: -${props => props.theme['@size-l']};
    padding-top: ${props => props.theme['@size-l']};
    padding-right: calc(${props => props.theme['@size-xl']} + ${props => props.theme['@size-l']});
    > div:last-child {
      padding-bottom: ${props => props.theme['@size-m']};
      margin-bottom: ${props => props.theme['@size-l']} !important;
    }
    + .ant-tabs {
      margin-top: -${props => props.theme['@size-l']};
    }
  }
`
const HeaderTitle = styled.div`
  display: flex;
  align-items: flex-start;
  > :last-child:not(:first-child) {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
