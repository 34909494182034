import { useResource } from '@thesisedu/feature-react'
import { Text, HSpaced, s, styled, Tooltip } from '@thesisedu/ui'
import { InfoEmpty } from '@thesisedu/ui/icons'
import React from 'react'

import { AllMetricSummariesFragment } from '../../execute/types'
import { ReportMetricResource, ReportMetricSummaryResource } from '../../types'
import { getFormattedMetric } from '../getFormattedMetric'

export interface MetricSummariesProps {
  summaries: Partial<AllMetricSummariesFragment>
  display: keyof AllMetricSummariesFragment
  /** If this is not specified, it is inferred. */
  metric?: string
  isRow?: boolean
}
export function MetricSummaries({
  summaries,
  display,
  metric: _metric,
  isRow,
}: MetricSummariesProps) {
  const metric = _metric || Object.keys(summaries[display] || [])[0]
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  const metricSummaryResources = useResource<ReportMetricSummaryResource>(
    'ReportMetricSummary',
  ).filter(resource => resource.associatedMetrics.includes(metric))
  const resource =
    metricResources.find(r => r.identifier === metric) ||
    metricResources.find(r => r.metricKey === metric && r.summarization === display)
  if (!metric) return null
  if (!resource) throw new Error(`Invalid metric resource: '${metric}'`)
  const displayValue = summaries[display]?.[resource.metricKey]
  if (displayValue === null || displayValue === undefined) return null
  return (
    <Tooltip
      contentProps={{ side: isRow ? 'right' : 'bottom', style: { padding: s.size('xs') } }}
      colorVariant={'inherit'}
      title={
        <div>
          {metricSummaryResources.map(summaryResource => {
            return (
              <MetricSummaryItem
                key={summaryResource.identifier}
                item={summaryResource.label}
                value={summaries[summaryResource.identifier]?.[resource.metricKey]}
                resource={resource}
              />
            )
          })}
          <MetricSummaryItem
            item={'Average'}
            value={summaries.average?.[resource.metricKey]}
            resource={resource}
          />
          <MetricSummaryItem
            item={'Count'}
            value={summaries.count?.[resource.metricKey]}
            resource={resource}
            noFormat
          />
          <MetricSummaryItem
            item={'Sum'}
            value={summaries.sum?.[resource.metricKey]}
            resource={resource}
          />
          <MetricSummaryItem
            item={'Minimum'}
            value={summaries.min?.[resource.metricKey]}
            resource={resource}
          />
          <MetricSummaryItem
            item={'Maximum'}
            value={summaries.max?.[resource.metricKey]}
            resource={resource}
          />
        </div>
      }
    >
      <Container isRow={isRow} color={'secondary'} level={'xs'} inline>
        {getFormattedMetric(displayValue, resource)}&nbsp;&nbsp;
        <InfoEmpty style={{ display: 'inline-block', verticalAlign: 'middle' }} />
      </Container>
    </Tooltip>
  )
}
const Container = styled(Text)<{ isRow?: boolean }>`
  cursor: pointer;
  white-space: nowrap;
  margin-left: ${props => (props.isRow ? s.size('xs') : 0)};
  text-align: ${props => (props.isRow ? 'right' : 'center')};
`

export interface MetricSummaryItemProps {
  item: string
  value?: number | null
  resource: ReportMetricResource
  noFormat?: boolean
}
export function MetricSummaryItem({ noFormat, item, value, resource }: MetricSummaryItemProps) {
  return (
    <SummaryItem justify={'space-between'}>
      <Text level={'xs'} color={'secondary'}>
        {item}
      </Text>
      <Text level={'s'}>{noFormat ? value : getFormattedMetric(value, resource)}</Text>
    </SummaryItem>
  )
}

const SummaryItem = styled(HSpaced)`
  padding: ${s.size('xxs')} ${s.size('xs')};
  &:first-child {
    padding-top: ${s.size('xs')};
  }
  &:last-child {
    padding-bottom: ${s.size('xs')};
  }
  &:not(:last-child) {
    border-bottom: solid 1px ${s.color('subtleBorder')};
  }
`
