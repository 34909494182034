import { Button, ButtonProps, message } from '@thesisedu/react'
import { Refresh } from '@thesisedu/react/icons'
import React from 'react'

import { useResetGeneratedAssignmentSubmissionMutation } from '../queries/useResetGeneratedAssignmentSubmissionMutation'

export interface ResetGeneratedAssignmentSubmissionButtonProps extends Partial<ButtonProps> {
  assignmentId: string
  studentId: string
  classId: string
}
export function ResetGeneratedAssignmentSubmissionButton({
  assignmentId,
  classId,
  studentId,
  ...rest
}: ResetGeneratedAssignmentSubmissionButtonProps) {
  const [reset, { loading }] = useResetGeneratedAssignmentSubmissionMutation({
    onCompleted() {
      message.success('Submission reset successfully.')
    },
    variables: {
      input: {
        id: assignmentId,
        classId,
        studentId,
      },
    },
  })

  return (
    <Button
      children={'Use Generated Submission'}
      icon={<Refresh />}
      {...rest}
      loading={loading}
      onClick={e => {
        e.preventDefault()
        reset()
      }}
    />
  )
}
