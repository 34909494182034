import { useMutateHook } from '@thesisedu/feature-react'
import { styled, Block } from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import { Breadcrumbs, getFlag, setFlag } from '@thesisedu/web'
import { groupBy, orderBy } from 'lodash'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { LeftMenuDividerItem, LeftMenuHeaderItem } from './LeftMenuItem'
import { LeftItemsHook, TopItemsHook, TopRightItemsHook } from './types'
import { Footer } from '../shell/Footer'
import { Logo } from '../shell/Logo'

const COLLAPSED_FLAG = 'feature-navigation-collapsed'

export interface ShellContextValue {
  shellProps: Partial<ShellProps>
  addPropsModification: (key: string, props: Partial<ShellProps>) => void
  removePropsModification: (key: string) => void
}
export const ShellContext = React.createContext<ShellContextValue>({
  shellProps: {},
  addPropsModification: () => {},
  removePropsModification: () => {},
})
interface ModificationsMap {
  [key: string]: Partial<ShellProps>
}
export function ShellProvider({ children }: React.PropsWithChildren<object>) {
  const [modifications, setModifications] = React.useState<ModificationsMap>({})
  return (
    <ShellContext.Provider
      value={{
        shellProps: Object.keys(modifications).reduce<Partial<ShellProps>>(
          (acc, key) => ({ ...acc, ...modifications[key] }),
          {},
        ),
        addPropsModification: (key, props) => {
          setModifications(m => ({
            ...m,
            [key]: props,
          }))
        },
        removePropsModification: key => {
          setModifications(m => {
            const { [key]: omitted, ...rest } = m
            return rest
          })
        },
      }}
      children={children}
    />
  )
}
export function useShellProps(key: string, props: Partial<ShellProps>, deps: any[]) {
  const { addPropsModification, removePropsModification } = React.useContext(ShellContext)
  React.useEffect(() => {
    addPropsModification(key, props)
    return () => {
      removePropsModification(key)
    }
  }, deps)
}

export interface LeftNavigationItem {
  group: 'top' | 'bottom' | string
  weight: number
  element: React.ReactElement
}
export interface UserMenuItem {
  weight: number
  element: React.ReactElement
}
export interface TopNavigationItem {
  weight: number
  element: React.ReactElement
}
export interface ShellProps {
  topItems: TopNavigationItem[]
  leftItems?: LeftNavigationItem[]
  leftTopItem?: React.ReactElement
  leftNavPrefix?: string
  userMenu?: UserMenuItem[]
  hideLeftMenu?: boolean
  hideBreadcrumb?: boolean
  noLeftMenu?: boolean
  noPadding?: boolean
  hideTopBar?: boolean
  noScroll?: boolean
  smallWidth?: boolean
  alternateBackground?: boolean
}
export function Shell({ children, ...props }: React.PropsWithChildren<ShellProps>) {
  const { shellProps } = React.useContext(ShellContext)
  const [leftMenuCollapsed, _setLeftMenuCollapsed] = React.useState<boolean>(
    shellProps.hideLeftMenu || props.hideLeftMenu || getFlag(COLLAPSED_FLAG) || false,
  )
  const setLeftMenuCollapsed = (collapsed: boolean) => {
    setFlag(COLLAPSED_FLAG, collapsed)
    _setLeftMenuCollapsed(collapsed)
  }
  React.useEffect(() => {
    if (props.hideLeftMenu || shellProps.hideLeftMenu) {
      _setLeftMenuCollapsed(true)
    }
  }, [shellProps.hideLeftMenu, props.hideLeftMenu])
  const {
    topItems: _topItems,
    leftTopItem,
    leftItems: _leftItems,
    userMenu: _userMenu,
    smallWidth,
    noScroll,
    noLeftMenu,
    noPadding,
    hideTopBar,
    alternateBackground,
  } = {
    ...props,
    ...shellProps,
  }
  const topItems = useMutateHook<TopItemsHook>(
    'feature-web:shell-top-items',
    _topItems || [],
    undefined,
  )
  const leftItems = useMutateHook<LeftItemsHook>(
    'feature-web:shell-left-items',
    _leftItems || [],
    undefined,
  )
  const groupedLeftItems = groupBy(leftItems, 'group')
  let groups: {
    group: LeftNavigationItem['group']
    minWeight: number
    items: LeftNavigationItem[]
  }[] = []
  for (const group of Object.keys(groupedLeftItems)) {
    const items = orderBy(groupedLeftItems[group], 'weight', 'asc')
    const minWeight = items.reduce((acc, item) => {
      return item.weight < acc ? item.weight : acc
    }, Infinity)
    groups.push({
      group,
      items,
      minWeight,
    })
  }
  groups = orderBy(groups, 'minWeight', 'asc')
  const userMenu = useMutateHook<TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    _userMenu || [],
    undefined,
  )
  return (
    <>
      <AlternateBackground hasAlternate={alternateBackground} />
      {hideTopBar ? null : (
        <HeaderContainer>
          <HeaderLeft>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </HeaderLeft>
          <HeaderCenter>
            {orderBy(topItems, 'weight', 'asc').map(item => item.element)}
          </HeaderCenter>
          <HeaderRight>
            {userMenu.length ? (
              <UserMenuContainer>
                {orderBy(userMenu, 'weight', 'asc').map(item => item.element)}
              </UserMenuContainer>
            ) : null}
          </HeaderRight>
        </HeaderContainer>
      )}
      <ContentContainer noScroll={noScroll}>
        {groups.length && !noLeftMenu ? (
          <LeftBarContainer className={leftMenuCollapsed ? 'collapsed' : ''} noScroll={noScroll}>
            <ExpandCollapseContainer hasLeftTop={!!leftTopItem}>
              <ExpandCollapseButton
                className={'button'}
                hasLeftTop={!!leftTopItem}
                onClick={() => setLeftMenuCollapsed(!leftMenuCollapsed)}
              >
                <ArrowLeft />
              </ExpandCollapseButton>
            </ExpandCollapseContainer>
            <div className={'content'}>
              {leftTopItem ? React.cloneElement(leftTopItem, { leftMenuCollapsed }) : null}
              <LeftBarNavigationContainer>
                {groups.map(group => {
                  const header =
                    group.group === 'top' ? null : group.group === 'bottom' ? (
                      <LeftMenuDividerItem key={`${group.group}-divider`} />
                    ) : (
                      <LeftMenuHeaderItem key={group.group} name={group.group!} />
                    )
                  return (
                    <>
                      {header}
                      {group.items.map(item =>
                        React.cloneElement(item.element, { leftMenuCollapsed }),
                      )}
                    </>
                  )
                })}
              </LeftBarNavigationContainer>
            </div>
          </LeftBarContainer>
        ) : null}
        <RightContainer
          className={smallWidth ? 'small-width' : ''}
          $noScroll={noScroll}
          $noPadding={noPadding}
        >
          {props.hideBreadcrumb || shellProps.hideBreadcrumb ? null : (
            <Block marginBottom={'@size-xs'}>
              <Breadcrumbs />
            </Block>
          )}
          {children}
          {noScroll ? (
            <BodyNoScroll />
          ) : (
            <FooterContainer>
              <Footer />
            </FooterContainer>
          )}
        </RightContainer>
      </ContentContainer>
    </>
  )
}

const BodyNoScroll = createGlobalStyle`
  #root {
    height: 100vh;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    > div.content {
      display: flex;
      flex-direction: column;
      min-height: 0;
      align-items: stretch;
      flex: 1;
      > div:last-child {
        flex: 1;
      }
    }
  }
`
const HeaderContainer = styled.div`
  padding-top: ${props => props.theme['@size-m']};
  padding-bottom: ${props => props.theme['@size-s']};
  padding-left: ${props => props.theme['@size-l']};
  padding-right: ${props => props.theme['@size-l']};
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 3;
  position: relative;
`
const HeaderItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    display: block;
  }
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
const HeaderCenter = styled(HeaderItem)`
  padding: 0 ${props => props.theme['@size-s']};
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
const HeaderLeft = styled(HeaderItem)`
  justify-content: flex-start;
`
const HeaderRight = styled(HeaderItem)`
  justify-content: flex-end;
`
const LogoContainer = styled.div`
  img {
    height: 30px;
    // Lighthouse improvement to prevent layout shifting.
    width: calc(30px * 3.83);
  }
`
const UserMenuContainer = styled.div`
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xs']};
  }
`
const ContentContainer = styled.div<{ noScroll?: boolean }>`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 75px);
  ${props => (props.noScroll ? '&' : '&.noop')} {
    min-height: 0;
  }
`
const LeftBarContainer = styled.div<{ noScroll?: boolean }>`
  background: ${props => props.theme['@background-color-base']};
  transition: width 0.25s ease-in-out;
  width: 250px;
  overflow-x: visible;
  position: relative;
  > div.content {
    width: 250px;
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: auto;
  }
  .left-button > :not(.icon),
  .left-header,
  .left-divider {
    transition: opacity 0.1s linear 0.25s;
    opacity: 1;
  }
  .left-header {
    transition:
      opacity 0.1s linear 0.25s,
      max-height 0.25s ease-in-out;
    max-height: 12px;
  }
  .left-button {
    flex-wrap: none;
    overflow-x: hidden;
    transition:
      max-width 0.25s ease-in-out,
      padding 0.25s ease-in-out,
      color 0.1s linear,
      background 0.1s linear;
    max-width: 250px;
  }
  &:hover .button {
    opacity: 1;
  }
  &.collapsed {
    transition-delay: 0.1s;
    width: 64px;
    .button {
      transform: rotate(180deg);
      opacity: 1;
    }
    .left-button > :not(.icon),
    .left-header,
    .left-divider {
      transition-delay: 0s;
      opacity: 0;
    }
    .left-header {
      transition:
        opacity 0.1s linear,
        max-height 0.25s ease-in-out 0.1s;
      max-height: 0px;
    }
    .left-button {
      transition:
        max-width 0.25s ease-in-out 0.1s,
        padding 0.25s ease-in-out 0.1s,
        color 0.1s linear,
        background 0.1s linear;
      max-width: 48px;
      padding-left: 13px;
      padding-right: 13px;
    }
  }
  ${props => (props.noScroll ? '&' : '&.noop')} {
    > div.content {
      height: 100%;
    }
  }
`
const LeftBarNavigationContainer = styled.div`
  padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-xs']};
  position: relative;
  flex: 1;
`
const ExpandCollapseContainer = styled.div<{ hasLeftTop?: boolean }>`
  position: absolute;
  top: ${props => (props.hasLeftTop ? '60px' : props.theme['@size-m'])};
  width: auto !important;
  right: 0;
  bottom: 0;
  z-index: 4;
  margin-right: -14px;
  &:hover .button {
    opacity: 1;
  }
`
const ExpandCollapseButton = styled.button<{ hasLeftTop?: boolean }>`
  position: sticky;
  top: ${props => (props.hasLeftTop ? '60px' : props.theme['@size-m'])};
  transition:
    color 0.1s linear,
    background 0.1s linear,
    transform 0.25s ease-in-out,
    opacity 0.1s linear;
  border: none;
  outline: none;
  opacity: 0;
  background: ${props => props.theme['@gray-3']};
  color: ${props => props.theme['@gray-5']};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.theme['@shadow-small']};
  width: 28px;
  height: 28px;
  font-size: ${props => props.theme['@size-s']};
  border-radius: 50%;
  &:hover {
    background: ${props => props.theme['@gray-4']};
    color: ${props => props.theme['@gray-7']};
  }
`
const RightContainer = styled.div<{ $noScroll?: boolean; $noPadding?: boolean }>`
  box-shadow: ${props => props.theme['@shadow-medium']};
  background: white;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.$noPadding ? 0 : props.theme['@size-s'])}
    ${props => (props.$noPadding ? 0 : props.theme['@size-l'])};
  flex: 1;
  z-index: 2;
  min-width: 0;
  &.small-width {
    max-width: 1024px;
    margin: 0 auto;
    padding: ${props => props.theme['@size-l']} ${props => props.theme['@size-s']};
    box-shadow: none;
  }
  ${props => (props.$noScroll ? '&' : '&.noop')} {
    height: 100%;
    overflow: hidden;
  }
`
const FooterContainer = styled.div`
  margin-top: auto;
  padding: ${props => props.theme['@size-l']} 0 0 0;
  text-align: center;
`
const AlternateBackground = createGlobalStyle<{ hasAlternate?: boolean }>`
  ${RightContainer}, ${HeaderContainer} {
    transition: background 0.25s linear;
    background: ${props =>
      (props.theme as any)[props.hasAlternate ? '@gray-1' : '@gray-0']} !important;
  }
`
