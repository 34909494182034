import { GeneratedAssignmentSubmissionMetadata } from '@thesisedu/feature-assignments-core'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Format } from '@thesisedu/feature-utils'
import { Body, BodyLarge, BodySmallTransparent, Color, Space, VSpaced } from '@thesisedu/react'
import { ShoppingCodeCheck } from '@thesisedu/react/icons'
import React from 'react'

import { Container } from './GeneratedAssignmentDirtyStatus'
import { ResetGeneratedAssignmentSubmissionButton } from './ResetGeneratedAssignmentSubmissionButton'
import { useAssignmentGeneratedSubmissionQuery } from '../schema'

export interface GeneratedAssignmentSubmissionDirtyStatusProps {
  classId: string
  studentId: string
  assignmentId: string
}
export function GeneratedAssignmentSubmissionDirtyStatus({
  classId,
  studentId,
  assignmentId,
}: GeneratedAssignmentSubmissionDirtyStatusProps) {
  const { data } = useAssignmentGeneratedSubmissionQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: assignmentId,
      classId,
      studentId,
    },
  })
  const root = useFeatureRoot()
  const generatedSubmission =
    data?.node?.__typename === 'Assignment' ? data.node.generatedSubmission : undefined
  if (
    !generatedSubmission ||
    generatedSubmission.generated?.originalGrade === undefined ||
    generatedSubmission.generated?.originalGrade === null
  )
    return null
  const generated: GeneratedAssignmentSubmissionMetadata = generatedSubmission.generated

  return (
    <Container>
      <Color color={'@green'}>
        <ShoppingCodeCheck width={64} height={64} />
      </Color>
      <VSpaced space={'@size-xs'} align={'flex-start'}>
        <BodyLarge color={'@green'}>
          An automatically generated assignment submission is available.
        </BodyLarge>
        <Body color={'@green'}>
          Automatically generated grade:{' '}
          <strong>{Format.number(generated.originalGrade, 'decimalPercentage')}</strong>
        </Body>
        <BodySmallTransparent color={'@green'}>
          A submission has been automatically created by another part of {root.appOptions.name} and
          will automatically update unless you change the grade. You may restore this automatic
          submission at any time.
        </BodySmallTransparent>
      </VSpaced>
      <Space />
      <ResetGeneratedAssignmentSubmissionButton
        assignmentId={assignmentId}
        studentId={studentId}
        classId={classId}
      />
    </Container>
  )
}
