import { useIsGeneratingPdf } from '@thesisedu/feature-pdf-generation-react'
import { BlockEditor, BlockEditorProps, Legacy } from '@thesisedu/feature-widgets-react'
import { styled } from '@thesisedu/ui'
import React from 'react'

export interface EditorProps extends Omit<BlockEditorProps, 'defaultValue'> {
  noPadding?: boolean
  defaultValue?: Legacy.AnyEditorValue
}

export type ReadOnlyEditorProps = Omit<EditorProps, 'readOnly'>
export function ReadOnlyEditor(props: ReadOnlyEditorProps) {
  const isGeneratingPDF = useIsGeneratingPdf()
  return <Editor readOnly {...props} noDynamicWidgets={props.noDynamicWidgets || isGeneratingPDF} />
}

export function Editor(rest: EditorProps) {
  const value = (rest as any).value as EditorProps['defaultValue'] | null | undefined
  const valueRef = React.useRef(value)
  const focusRef = React.useRef(false)
  const [defaultValueKey, setDefaultValueKey] = React.useState(0)
  React.useEffect(() => {
    // Do nothing if we are currently focused on the editor.
    if (focusRef.current) return

    // Only update the valueRef whenever the value changes for the first time. This means
    // subsequent updates to the value will be ignored, which should be sufficient.
    if (!valueRef.current || Legacy.anyEditorValueIsEmpty(valueRef.current)) {
      valueRef.current = value
      setDefaultValueKey(k => k + 1)
    }
  }, [value])
  const defaultValue = rest.defaultValue ? rest.defaultValue : valueRef.current || undefined
  return (
    <Container>
      <BlockEditor
        {...rest}
        onFocus={() => {
          focusRef.current = true
        }}
        onBlur={() => {
          focusRef.current = false
        }}
        defaultValue={Legacy.getBlockValueFromAnyValue(defaultValue, {
          namespace: rest.id,
        })}
        defaultValueKey={defaultValueKey.toString()}
      />
    </Container>
  )
}

const Container = styled.div`
  max-width: 800px;
`
