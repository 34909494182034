import { Tabs } from '@thesisedu/ui'
import React from 'react'

import { useSelectedSidebarTabContext } from './SelectedSidebarTabContext'
import { useSidebarTabs } from './useSidebarTabs'

export function Sidebar() {
  const { selectedTab, setSelectedTab } = useSelectedSidebarTabContext(true)
  const tabs = useSidebarTabs()
  if (tabs.length) {
    return (
      <Tabs.Root
        defaultValue={tabs[0].title}
        value={selectedTab}
        onValueChange={setSelectedTab}
        style={{ height: '100%' }}
      >
        <Tabs.SegmentedControlList aria-label={'Media Sidebar'} size={'small'}>
          {tabs.map(tab => {
            return (
              <Tabs.SegmentedControlList.Item
                key={tab.title}
                value={tab.title}
                icon={tab.icon}
                children={tab.title}
              />
            )
          })}
        </Tabs.SegmentedControlList>
        <Tabs.Content forceMount>
          {tabs.map(tab => {
            return <Tabs.Tab value={tab.title} key={tab.title} children={tab.children} forceMount />
          })}
        </Tabs.Content>
      </Tabs.Root>
    )
  } else {
    return null
  }
}
