import { H2, styled, LargeBlock, H2Subtitle } from '@thesisedu/web'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-m']};
  > div:last-child:not(:first-child) {
    margin-left: auto;
  }
`

export interface ReportBoxProps {
  title: string
  description?: string
  link?: LinkProps
}
export function ReportBox({
  title,
  description,
  link,
  children,
}: React.PropsWithChildren<ReportBoxProps>) {
  return (
    <LargeBlock>
      <Header>
        <div>
          <H2 isBlock={false}>{title}</H2>
          {description ? <H2Subtitle isBlock={false}>{description}</H2Subtitle> : null}
        </div>
        {link ? (
          <div>
            <Link {...link} />
          </div>
        ) : null}
      </Header>
      {children}
    </LargeBlock>
  )
}
