import AntModal, { ModalProps as AntModalProps } from 'antd/es/modal'
import React, { useContext } from 'react'
import { createGlobalStyle } from 'styled-components'

import { HSpaced } from './Block'
import { LightOverlayStyle, DarkOverlayStyle } from './Overlay'
import { H2, Body, FontWeight } from './Typography'
import { ThemeContext as StyledThemeContext } from './styledTypes'

const ModalGlobalStyle = createGlobalStyle<{ id: string; alternateBackground?: boolean }>`
  .feature-modal-${props => props.id} .ant-modal-close { right: 15px !important; }
  .feature-modal-${props => props.id} .ant-modal-close-x { width: auto !important; }
  .feature-modal-${props => props.id} .ant-modal-content {
    background: ${props => (props.theme as any)[props.alternateBackground ? '@gray-1' : '@gray-0']};
  }
  .feature-modal-${props => props.id} .ant-modal-header {
    background: transparent;
  }
`

export type ModalProps = AntModalProps & {
  lightMask?: boolean
  alternateBackground?: boolean
  customTitle?: React.ReactElement
  modalRef?: React.MutableRefObject<HTMLDivElement | null>
  topRightActions?: React.ReactElement
}
export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  title,
  lightMask,
  alternateBackground,
  customTitle,
  modalRef,
  topRightActions,
  ...props
}) => {
  const theme = useContext(StyledThemeContext)
  const [id] = React.useState(Math.floor(Math.random() * 10000).toString())
  const style = lightMask ? LightOverlayStyle : DarkOverlayStyle
  return (
    <>
      <ModalGlobalStyle id={id} alternateBackground={alternateBackground} />
      <AntModal
        footer={null}
        maskStyle={style(theme)}
        closeIcon={
          <HSpaced align={'center'} style={{ marginTop: theme['@size-xs'] }}>
            {topRightActions}
            <Body
              color={'@primary-color'}
              weight={FontWeight.Normal}
              data-testid={'ModalClose'}
              ref={el => {
                if (el && modalRef) {
                  modalRef.current = el.closest('.feature-modal') as HTMLDivElement | null
                }
              }}
            >
              Close
            </Body>
          </HSpaced>
        }
        {...props}
        className={`feature-modal feature-modal-${id} ${props.className}`}
        bodyStyle={{
          ...props.bodyStyle,
          paddingTop: 0,
        }}
        title={
          customTitle ? (
            customTitle
          ) : title ? (
            <H2 isBlock={false} style={{ lineHeight: 1, marginTop: theme['@size-xs'] }}>
              {title}
            </H2>
          ) : undefined
        }
      />
    </>
  )
}
