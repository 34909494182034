import { s } from '@thesisedu/ui'
import { createGlobalStyle } from 'styled-components'

export const EmptyBlockAddMenuPluginStyle = createGlobalStyle`
  .ft-new-empty {
    position: relative;
    &::before {
      display: flex;
      content: "Start typing, or type '/' to add a special block.";
      background: ${s.color('gray.background')};
      color: ${s.color('gray.hoverBorder')};
      position: absolute;
      align-items: center;
      flex-direction: row;
      inset: 0;
      left: 1px;
      padding-inline-start: inherit;
    }
  }
`
