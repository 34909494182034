import {
  ApolloReactHooks,
  ExpectedErrorPayload,
  ExpectedErrorContext,
} from '@thesisedu/feature-apollo-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Text, Modal, Block, Modal$ } from '@thesisedu/ui'
import { WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

function ApprovalRequiredModal(props: Partial<Modal$.ModalProps>) {
  const root = useFeatureRoot()
  return (
    <Modal {...props} title={'Administrator approval required'}>
      <Block top={'l'}>
        <Text level={'l'} color={'red'} bottom>
          <WarningCircle style={{ fontSize: 48 }} />
        </Text>
      </Block>
      <Text level={'l'} bottom>
        We need approval from your district IT administrator before you can continue.
      </Text>
      <Text bottom>
        In order to use advanced Microsoft Teams features like grade sync and student import, your
        district IT administrator will need to approve {root.appOptions.name} for use. To start the
        process, reach out to your district IT administrator and have them contact{' '}
        <a href={`mailto:support@${root.appOptions.domain}`}>{root.appOptions.name} Support.</a>
      </Text>
      <Modal.Footer>
        <Modal.CloseButton children={'OK'} />
      </Modal.Footer>
    </Modal>
  )
}

function TeamIsArchivedModal(props: Partial<Modal$.ModalProps>) {
  return (
    <Modal {...props} title={'Team is archived'}>
      <Block top={'l'}>
        <Text level={'l'} color={'red'} bottom>
          <WarningCircle style={{ fontSize: 48 }} />
        </Text>
      </Block>
      <Text level={'l'} bottom>
        It looks like the team associated with this class is archived.
      </Text>
      <Text bottom>
        You cannot create or edit assignments for archived teams. To continue, please go to the
        Students tab and make sure you have attached this class to the correct team. You will not be
        able to sync new content until you detach the archived team and re-attach a new team.
      </Text>
      <Modal.Footer>
        <Modal.CloseButton children={'OK'} />
      </Modal.Footer>
    </Modal>
  )
}

export function ExpectedErrorHandler() {
  const root = useFeatureRoot()
  const [approvalVisible, setApprovalVisible] = React.useState(false)
  const [archivedVisible, setArchivedVisible] = React.useState(false)
  React.useEffect(() => {
    root.deps.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
      ApolloReactHooks.ExpectedError,
      (err, context) => {
        if (context?.code === 'NO_TENANT_CONFIGURED_ERROR') {
          setApprovalVisible(true)
        } else if (context?.code === 'ASSOCIATED_TEAM_IS_ARCHIVED_ERROR') {
          setArchivedVisible(true)
        }
        return err
      },
    )
  }, [])
  return (
    <>
      <ApprovalRequiredModal visible={approvalVisible} onVisibleChange={setApprovalVisible} />
      <TeamIsArchivedModal visible={archivedVisible} onVisibleChange={setArchivedVisible} />
    </>
  )
}
