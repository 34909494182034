import React from 'react'

import { useBadgeQuery } from '../schema'

export interface BadgeNameProps {
  id: string
}
export function BadgeName({ id }: BadgeNameProps) {
  const { data } = useBadgeQuery({ variables: { id } })
  const badge = data?.node?.__typename === 'Badge' ? data.node : undefined
  return <span>{badge?.name || '..'}</span>
}
