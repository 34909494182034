import { Icon } from '@ant-design/compatible'
import * as allIcons from '@ant-design/icons'
import { Select } from 'antd'
import Case from 'case'
import React from 'react'

const icons = Object.keys(allIcons)
  .filter(iconName => iconName.match(/Outlined$/))
  .map(iconName => Case.kebab(iconName.replace('Outlined', '')))

export interface IconSelectProps {
  onChange?: (icon?: string) => void
  value?: string
  whitelist?: string[]
}
export function IconSelect({ onChange, value, whitelist }: IconSelectProps) {
  return (
    <Select
      onChange={val => {
        onChange?.(val === 'no-value' ? undefined : val)
      }}
      value={value ?? 'no-value'}
      showSearch
      filterOption={(input, option) => {
        return (
          !!option?.key && option.key.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }}
    >
      <Select.Option key={'no-value'} value={'no-value'}>
        Default Icon
      </Select.Option>
      {icons
        .filter(icon => !whitelist || whitelist.includes(icon))
        .map(icon => (
          <Select.Option key={icon} value={icon}>
            {/* @ts-ignore react 18 support */}
            <Icon type={icon} /> {Case.title(icon)}
          </Select.Option>
        ))}
    </Select>
  )
}
