import { HSpaced } from '@thesisedu/web'
import React from 'react'
import { createPortal } from 'react-dom'

export interface ActionsContextValue {
  hostRef: React.RefObject<HTMLElement>
}
export const ActionsContext = React.createContext<ActionsContextValue | undefined>(undefined)

export interface ActionsContainerProps {
  children: React.ReactNode
}
export function ActionsContainer({ children }: ActionsContainerProps) {
  const { hostRef } = useActionsContext(true)
  if (hostRef.current) {
    return createPortal(<HSpaced align={'center'}>{children}</HSpaced>, hostRef.current)
  } else {
    return null
  }
}

export function useActionsContext(): ActionsContextValue | undefined
export function useActionsContext(require: false): ActionsContextValue | undefined
export function useActionsContext(require: true): ActionsContextValue
export function useActionsContext(require?: boolean): ActionsContextValue | undefined {
  const context = React.useContext(ActionsContext)
  if (!context && require) {
    throw new Error('ActionsContext is required, yet not provided.')
  }
  return context
}
