import { onMutationError, useImpersonation } from '@thesisedu/feature-apollo-react'
import { BodyExtraSmall, Reset, Space } from '@thesisedu/react'
import { NavigationListPulseHeader } from '@thesisedu/react/devtools'
import React from 'react'

import { useUpdateUserDetailedLoggingMutation } from '../../schema'
import { useViewerContext } from '../../useViewerContext'

export interface ListHeaderContextValue {
  fetchingInitial?: boolean
}
export const ListHeaderContext = React.createContext<ListHeaderContextValue | undefined>(undefined)

export function ListHeader() {
  const { fetchingInitial } = React.useContext(ListHeaderContext) || {}
  const { withCoreAuth } = useImpersonation()
  const id = useViewerContext(true).id
  const [updateDetailedLogging, { loading }] = useUpdateUserDetailedLoggingMutation(
    withCoreAuth({
      onError: onMutationError('There was an error updating logging preferences.'),
    }),
  )
  return (
    <NavigationListPulseHeader loading={fetchingInitial}>
      <>
        <span>{fetchingInitial ? 'Loading logs...' : 'Listening for logs...'}</span>
        <Space />
        <Reset.Button
          onClick={e => {
            e.preventDefault()
            updateDetailedLogging({
              variables: {
                input: {
                  id,
                  enabled: false,
                },
              },
            })
          }}
        >
          <BodyExtraSmall color={loading ? '@text-color-secondary' : '@red'}>
            {loading ? 'Stopping...' : 'Stop'}
          </BodyExtraSmall>
        </Reset.Button>
      </>
    </NavigationListPulseHeader>
  )
}
