import { ViewColumns2 } from '@thesisedu/react/icons'
import React from 'react'

import { $createColumnsNode, $isColumnsNode } from '../../nodes/ColumnsNode'
import { ElementsGroup, useElementsMenuItem } from '../../ui/ElementsMenu/ElementsMenuContext'

export function useColumns() {
  useElementsMenuItem({
    identifier: 'two-col',
    icon: <ViewColumns2 />,
    title: 'Columns',
    weight: 33,
    group: ElementsGroup.Layout,
    noReplace: true,
    isSelected: $isColumnsNode,
    onCommit(element) {
      const node = $createColumnsNode()
      const result = element.replace(node, false)
      node.getFirstChildOrThrow().select()
      return result
    },
  })
}
