import ApolloReactFeature, { BackgroundJobReporterResource } from '@thesisedu/feature-apollo-react'

import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'courseAssignmentsAfterClassUpdated',
    humanLabel: () => 'Updating assignments...',
    async afterComplete() {
      const apolloFeature = feature.root.requireFeature<ApolloReactFeature>(
        ApolloReactFeature.package,
      )
      await apolloFeature.client.refetchQueries({
        include: ['classDecorations'],
      })
    },
  })
}
