import { States } from '@thesisedu/svgs'
import Case from 'case'
import React from 'react'

const AnyStates = States as Record<string, any>

export interface StandardSetStateProps {
  state: string
}
export function StandardSetState({ state }: StandardSetStateProps) {
  const StateComponent = state ? AnyStates[Case.pascal(state)] : undefined
  return state === 'all' ? <States.Usa /> : StateComponent ? <StateComponent /> : null
}
