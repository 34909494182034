import { SegmentedControl, SegmentedControl$ } from '@thesisedu/ui'
import React from 'react'

import { SHARE_FILTER_OPTS } from './constants'
import { ShareFilterButtonProps } from './types'

export function ShareFilterSegmentedControl({
  sharingTypes,
  onSharingTypesChanged,
  label = 'Content',
  ...props
}: ShareFilterButtonProps & Partial<SegmentedControl$.SegmentedControlProps>) {
  const selectedValue = sharingTypes?.length ? sharingTypes.join('|') : 'none'
  return (
    <SegmentedControl {...props}>
      {SHARE_FILTER_OPTS.map(opt => {
        return (
          <SegmentedControl.Item
            key={opt.value}
            selected={opt.value === selectedValue}
            onPress={() => {
              onSharingTypesChanged(
                opt.value === 'none' ? undefined : (opt.value.split('|') as any),
              )
            }}
            children={opt.label.replace('Content', label)}
          />
        )
      })}
    </SegmentedControl>
  )
}
