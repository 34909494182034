import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { AssignmentStudentAssignmentMode } from './schema'

export function AssignmentModeSelect(
  props: Omit<SelectProps<AssignmentStudentAssignmentMode>, 'options'>,
) {
  return (
    <Select<AssignmentStudentAssignmentMode>
      {...props}
      options={[
        { value: AssignmentStudentAssignmentMode.AllStudents, label: 'All Students' },
        { value: AssignmentStudentAssignmentMode.SpecificStudents, label: 'Specific Students' },
      ]}
    />
  )
}
