import { styled, useMeasureCache } from '@thesisedu/web'
import { StickyGrid, useCollapsibleGrid } from '@thesisedu/web/dist/react-window'
import React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { areEqual, GridChildComponentProps, VariableSizeGrid } from 'react-window'

import { ReportGridContext, useReportGridContext } from './Context'
import { ReportGridExport } from './ReportGridExport'
import { getAxesResponsibility, isMetrics } from './axes'
import { ColumnHeaderCell } from './cells/ColumnHeaderCell'
import { RowHeaderCell } from './cells/RowHeaderCell'
import { TopLeftCell } from './cells/TopLeftCell'
import { ValueCell } from './cells/ValueCell'
import { getReportCells } from './cells/getReportCells'
import { getDimensionResults } from './dimensions'
import { getGridExtents } from './extents'
import { getGridSizes } from './sizes'
import { getDefaultExpandedIds, getSummaryCells } from './summary'
import { useReportResources } from '../../execute/input'
import { ReportActions } from '../ReportActions'
import { ReportResultsProps } from '../ReportResults'

export function ReportResultGrid({ data, report, onReportChange }: ReportResultsProps) {
  const resources = useReportResources()
  const responsibility = getAxesResponsibility(resources, report)
  const sizes = getGridSizes(responsibility)
  const dimensionResults = getDimensionResults(data)
  const summaryConfig = !isMetrics(responsibility.row) && responsibility.row.summary
  const summaryResult = summaryConfig
    ? getSummaryCells(responsibility, data, report, resources.metrics)
    : undefined
  const extents = getGridExtents(data, responsibility, summaryResult)
  const cells = summaryResult
    ? summaryResult.cells
    : getReportCells(data, dimensionResults, report, extents, responsibility, resources.metrics)
  const [expandedRowIds, setExpandedRowIds] = React.useState<string[]>(
    getDefaultExpandedIds(summaryResult),
  )
  React.useEffect(() => {
    setExpandedRowIds(getDefaultExpandedIds(summaryResult))
  }, [data])
  const collapsibleGrid = useCollapsibleGrid({
    rowIds: summaryResult ? summaryResult.rowIds : [],
    collapsibleRowRanges: summaryResult ? summaryResult.collapsibleRowRanges : [],
    expandedRowIds,
  })
  const isCollapsible = !!summaryResult
  const visibleRows = isCollapsible ? collapsibleGrid.visibleRows : extents.numRows
  const gridRef = React.useRef<VariableSizeGrid | null>(null)
  const measureCache = useMeasureCache([data], () => {
    if (gridRef.current) {
      gridRef.current.resetAfterRowIndex(0)
    }
  })
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterRowIndex(0)
    }
  }, [expandedRowIds])

  return (
    <ReportGridContext.Provider
      value={{
        responsibility,
        opts: report,
        setOpts: onReportChange,
        data,
        extents,
        dimensionResults,
        cells,
        getRealRowIndex: isCollapsible ? collapsibleGrid.getRealRowIndex : undefined,
        flatSummaryRows: summaryResult?.flatRows,
        expandedRowIds,
        setExpandedRowIds,
        visibleRows,
        measureCache,
      }}
    >
      <ReportActions report={report} onReportChange={onReportChange}>
        <ReportGridExport />
      </ReportActions>
      <GridContainer>
        <AutoSizer>
          {({ width, height }) => (
            <StickyGrid
              gridRef={gridRef}
              height={height}
              width={width}
              columnCount={extents.numColumns + 1}
              columnWidth={sizes.columnWidthFn}
              stickColumns={1}
              rowCount={visibleRows + 1}
              rowHeight={visibleRow => {
                const realIndex = isCollapsible
                  ? collapsibleGrid.getRealRowIndex(visibleRow - 1) + 1
                  : visibleRow
                const measured = measureCache.at(realIndex.toString())
                const originalHeight = sizes.rowHeightFn(realIndex)
                return measured ? Math.max(originalHeight, measured.height) : originalHeight
              }}
              stickRows={1}
              className={'report-grid-outer-content'}
              children={GridItem}
            />
          )}
        </AutoSizer>
      </GridContainer>
    </ReportGridContext.Provider>
  )
}

const GridItem = React.memo(
  ({ columnIndex, rowIndex: _rowIndex, style }: GridChildComponentProps) => {
    const { getRealRowIndex } = useReportGridContext(true)
    // The -1 and +1 is for the header.
    const rowIndex = getRealRowIndex ? getRealRowIndex(_rowIndex - 1) + 1 : _rowIndex
    if (columnIndex === 0 && rowIndex === 0) {
      return <TopLeftCell style={style} />
    } else if (rowIndex === 0) {
      return <ColumnHeaderCell rawIndex={columnIndex} style={style} />
    } else if (columnIndex === 0) {
      return <RowHeaderCell rawIndex={rowIndex} visibleIndex={_rowIndex} style={style} />
    } else {
      return (
        <ValueCell
          rawRow={rowIndex}
          rawVisibleRow={_rowIndex}
          rawColumn={columnIndex}
          style={style}
        />
      )
    }
  },
  areEqual,
)
GridItem.displayName = 'GridItem'

const GridContainer = styled.div`
  flex: 1;
  margin-right: -${props => props.theme['@size-l']};
  margin-bottom: -${props => props.theme['@size-s']};
  border-top-left-radius: ${props => props.theme['@border-radius-base']};
  overflow: hidden;
  position: relative;
`
