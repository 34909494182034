import { styled } from '@thesisedu/web'
import React from 'react'

import { STAFF_COLOR, TuneBarItem, CommonSheetMusicViewerProps } from './types'
import { MultiDropBox } from '../editor/DropBox'

export interface BarProps extends CommonSheetMusicViewerProps {
  bar: TuneBarItem
  remainingRoom?: number
}
export function Bar({ bar, remainingRoom, ...common }: BarProps) {
  return (
    <>
      {remainingRoom ? (
        <div style={{ flex: remainingRoom * 4 }}>
          <MultiDropBox {...common} startPos={bar.startChar} duration={remainingRoom} />
        </div>
      ) : null}
      <BarContainer />
    </>
  )
}
const BarContainer = styled.div`
  width: 2px;
  background: ${props => props.theme[STAFF_COLOR]};
  height: 100%;
`
