import { MutationFunctionOptions } from '@apollo/client'
import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  BasicClassFragment,
  BasicClassFragmentDoc,
  ClassFragment,
  ClassFragmentDoc,
  MinimalClassFragment,
  UpdateClassMutation,
  UpdateClassMutationVariables,
  useUpdateClassMutation as useMutation,
} from '../schema'

export const useUpdateClassMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError('There was an error updating your class.'),
    ...opts,
    update: (...args) => {
      updateMutation<UpdateClassMutation, ClassFragment>({
        path: 'updateClass.class',
        fragment: ClassFragmentDoc,
        fragmentName: 'Class',
      })(...args)
      updateMutation<UpdateClassMutation, BasicClassFragment>({
        path: 'updateClass.class',
        fragment: BasicClassFragmentDoc,
        fragmentName: 'BasicClass',
      })(...args)
      if (opts?.update) {
        opts.update(...args)
      }
    },
  })
}

export const updateOpts = (
  cls: MinimalClassFragment,
  updates: Partial<ClassFragment>,
): MutationFunctionOptions<UpdateClassMutation, UpdateClassMutationVariables> => ({
  variables: {
    input: {
      id: cls.id,
      patch: updates,
    },
  },
  optimisticResponse: {
    __typename: 'Mutation',
    updateClass: {
      __typename: 'ClassPayload',
      class: {
        ...(cls as any), // FIXME this might not be a good idea.
        ...updates,
      },
    },
  },
})
