import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Body, BodyLarge, Modal, styled, Block, ModalProps } from '@thesisedu/web'
import { Button, Tooltip, Menu } from 'antd'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import copy from 'copy-to-clipboard'
import React from 'react'

import { useGenerateMicrosoftTenantTokenMutation } from '../schema'

const LinkContainer = styled(BodyLarge)`
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  transition: color 0.25s linear;
  color: ${props => props.theme[props.disabled ? '@text-color-secondary' : '@primary-color']};
`

export interface GenerateTenantTokenModalProps extends ModalProps {
  siteId: string
}
export function GenerateTenantTokenModal({ siteId, ...props }: GenerateTenantTokenModalProps) {
  const [token, setToken] = React.useState<string | undefined>(undefined)
  const [generateLink, { loading }] = useGenerateMicrosoftTenantTokenMutation({
    onError: onMutationError('There was an error generating your tenant link.'),
    onCompleted: data => {
      setToken(data.generateMicrosoftTenantToken.token)
    },
  })
  const [tooltipTitle, setTooltipTitle] = React.useState<string>('Click to copy')
  React.useEffect(() => {
    if (tooltipTitle === 'Copied!') {
      const handle = setTimeout(() => {
        setTooltipTitle('Click to copy')
      }, 1000)
      return () => clearTimeout(handle)
    }
  }, [tooltipTitle])
  return (
    <Modal
      title={'Generate Microsoft Tenant Link'}
      footer={null}
      width={600}
      style={{ maxWidth: '90vw' }}
      {...props}
    >
      <Body>
        Use the button below to generate your link, and then copy the link once it has been created.{' '}
        <strong>Links are valid for up to 30 days after creation.</strong>
      </Body>
      <Block marginTop={'@size-m'}>
        {token ? (
          <Tooltip title={tooltipTitle}>
            <LinkContainer
              onClick={() => {
                copy(`${window.location.origin}/auth/microsoft-tenant?token=${token}`)
                setTooltipTitle('Copied!')
              }}
            >
              {`${window.location.origin}/auth/microsoft-tenant?token=${token}`}
            </LinkContainer>
          </Tooltip>
        ) : (
          <Button
            size={'large'}
            loading={loading}
            onClick={() => generateLink({ variables: { input: { siteId } } })}
          >
            Generate Link
          </Button>
        )}
      </Block>
    </Modal>
  )
}

export type GenerateTenantTokenMenuItemProps = Omit<
  GenerateTenantTokenModalProps,
  'visible' | 'onCancel'
> &
  Partial<MenuItemProps>

export function GenerateTenantTokenMenuItem({
  siteId,
  ...menuItemProps
}: GenerateTenantTokenMenuItemProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <Menu.Item key={'microsoft-link'} {...menuItemProps}>
      <a onClick={() => setVisible(true)}>Generate MS Tenant Link</a>
      <GenerateTenantTokenModal
        siteId={siteId}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </Menu.Item>
  )
}
