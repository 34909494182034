import { Modal, Button, Button$, Tooltip, Text } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { useDeleteRubricTemplateMutation } from '../queries/useDeleteRubricTemplateMutation'

export interface DeleteRubricTemplateButtonProps extends Partial<Button$.ButtonProps> {
  templateId: string
  onComplete?: () => void
}
export function DeleteRubricTemplateButton({
  templateId,
  onComplete,
  ...buttonProps
}: DeleteRubricTemplateButtonProps) {
  const [del, { loading }] = useDeleteRubricTemplateMutation({})
  return (
    <Modal.Confirm
      trigger={
        <Tooltip title={'Delete Template'} contentProps={{ side: 'left' }}>
          <Button status={'danger'} loading={loading} icon={<Trash />} {...buttonProps} />
        </Tooltip>
      }
      title={'Are you sure?'}
      confirm={{ status: 'danger', children: 'Yes, delete' }}
      cancel={{ children: 'No, keep' }}
      onConfirm={async () => {
        await del({
          variables: {
            id: templateId,
          },
        })
        if (onComplete) {
          onComplete()
        }
      }}
    >
      <Text>
        Existing assignments will <strong>not</strong> be affected. Are you sure you want to delete
        this template?
      </Text>
    </Modal.Confirm>
  )
}
