import { useFreshRef, useMutateHook } from '@thesisedu/feature-react'
import { message, useNavigate, useLocation, isNative } from '@thesisedu/react'
import React from 'react'

import { GradingModalContextValue } from './GradingModalContext'
import { GradingModal } from '../grading/GradingModal'
import { isFullAssignment } from '../grading/types'
import { debug } from '../log'
import { useAssignmentQuery, BasicAssignmentFragment } from '../schema'
import {
  GradingModalVisibilityContextChildrenHook,
  MutateGradingModalVisibilityAssignment,
} from '../types'

export interface SetGradingAssignmentOpts {
  defaultTab?: string
}
type SetGradingAssignment = (
  assignment: GradingModalContextValue['assignment'] | undefined | BasicAssignmentFragment,
  opts?: SetGradingAssignmentOpts,
) => void
export interface GradingModalVisibilityContextValue {
  setGradingAssignment: SetGradingAssignment
  isVisible: boolean
}
export const GradingModalVisibilityContext = React.createContext<
  GradingModalVisibilityContextValue | undefined
>(undefined)

const LOADING_KEY = 'loading-assignment'
export interface GradingModalVisibilityContextProviderProps {
  getLink?: (
    assignment: GradingModalContextValue['assignment'] | BasicAssignmentFragment | undefined,
    opts?: SetGradingAssignmentOpts,
  ) => string
}
export function GradingModalVisibilityContextProvider({
  getLink,
  children,
}: React.PropsWithChildren<GradingModalVisibilityContextProviderProps>) {
  const navigate = useNavigate()
  if (getLink) {
    return (
      <GradingModalVisibilityContext.Provider
        value={{
          setGradingAssignment: (assignment, opts) => {
            navigate(getLink(assignment, opts))
          },
          isVisible: false,
        }}
        children={children}
      />
    )
  } else {
    return <ModalGradingModalVisibilityContextProvider children={children} />
  }
}

export function ModalGradingModalVisibilityContextProvider({
  children,
}: React.PropsWithChildren<object>) {
  const existing = React.useContext(GradingModalVisibilityContext)
  const location = useLocation()
  const hookedSetGradingAssignment = useMutateHook<MutateGradingModalVisibilityAssignment>(
    'feature-assignments-react:grading-modal-visibility-assignment',
    null,
    undefined,
  )
  const [gradingAssignment, __setGradingAssignment] = React.useState<
    GradingModalContextValue['assignment'] | undefined
  >()
  const [defaultTab, setDefaultTab] = React.useState<string | undefined>(undefined)
  const defaultTabRef = useFreshRef(defaultTab)
  const _setGradingAssignment = (
    assignment: GradingModalContextValue['assignment'] | undefined,
  ) => {
    if (hookedSetGradingAssignment) {
      __setGradingAssignment(
        hookedSetGradingAssignment(assignment, { defaultTab: defaultTabRef.current }),
      )
    } else {
      __setGradingAssignment(assignment)
    }
  }
  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (gradingAssignment) setVisible(true)
  }, [gradingAssignment])

  // Populate the assignment from the URL...
  const [loadAssignmentId, setLoadAssignmentId] = React.useState<string | undefined>(undefined)
  const { data: loadData, error } = useAssignmentQuery({
    variables: {
      id: loadAssignmentId || '',
    },
    skip: !loadAssignmentId,
  })

  // Listen for URL changes and update the assignment ID accordingly...
  React.useEffect(() => {
    // We need to debounce this in case the location changes rapidly.
    const timeout = setTimeout(() => {
      if (isNative) return
      const params = new URLSearchParams(window.location.search)
      const tab = params.get('tab')
      const assignmentId = params.get('grading')
      debug('setting assignment id from url %s', assignmentId)
      if (assignmentId) {
        setLoadAssignmentId(assignmentId)
      }
      if (tab) {
        setDefaultTab(tab)
      }
    }, 250)
    return () => clearTimeout(timeout)
  }, [location])

  // Handle updating the grading assignment based on the loading assignment.
  const loadingMessageTimeout = React.useRef<any>()
  const setLoading = (show: boolean) => {
    const loadedAssignment = loadData?.node?.__typename === 'Assignment' ? loadData.node : undefined
    if (show && loadedAssignment?.id !== loadAssignmentId) {
      loadingMessageTimeout.current = setTimeout(() => {
        message.loading({
          content: 'Loading assignment...',
          duration: 0,
          key: LOADING_KEY,
        })
      }, 500)
    } else {
      message.destroy(LOADING_KEY)
      clearTimeout(loadingMessageTimeout.current)
    }
  }
  React.useEffect(() => {
    const loadedAssignment = loadData?.node?.__typename === 'Assignment' ? loadData.node : undefined
    if (loadedAssignment) {
      _setGradingAssignment(loadedAssignment)
      setVisible(true)
      setLoading(false)
    }
  }, [(loadData?.node as any)?.id])
  React.useEffect(() => {
    if (error) {
      setLoading(false)
    }
  }, [error])
  React.useEffect(() => {
    if (loadAssignmentId) {
      setLoading(true)
    }
  }, [loadAssignmentId])

  // Sync the selected assignment with the URL...
  React.useEffect(() => {
    if (gradingAssignment && visible && !isNative) {
      const params = new URLSearchParams(window.location.search)
      params.set('grading', gradingAssignment.id)
      if (defaultTab) {
        params.set('tab', defaultTab)
      }
      const stringParams = params.toString()
      window.history.pushState(
        {},
        `Grading ${gradingAssignment.name}`,
        `${window.location.pathname}${stringParams ? `?${stringParams}` : ''}`,
      )
    }
  }, [gradingAssignment?.id, visible])

  // Whenever the modal is closed, clear the load assignment ID.
  React.useEffect(() => {
    if (!visible) {
      setLoadAssignmentId(undefined)
    }
  }, [visible])

  // Performance optimization. This was causing all of the cells to re-render when
  // opening the grading modal, since the state of this component gets updated.
  const contextValue = React.useMemo<GradingModalVisibilityContextValue>(
    () => ({
      isVisible: !!gradingAssignment,
      setGradingAssignment(assignment, { defaultTab } = {}) {
        setDefaultTab(defaultTab)
        if (assignment === undefined || isFullAssignment(assignment)) {
          _setGradingAssignment(assignment)
          if (!assignment) {
            setVisible(false)
          }
          if (!assignment && !isNative) {
            const params = new URLSearchParams(window.location.search)
            params.delete('grading')
            params.delete('tab')
            const stringParams = params.toString()
            window.history.pushState(
              {},
              '',
              `${window.location.pathname}${stringParams ? `?${stringParams}` : ''}`,
            )
          }
        } else {
          setLoadAssignmentId(assignment.id)
        }
      },
    }),
    [!!gradingAssignment],
  )
  const hookChildren = useMutateHook<GradingModalVisibilityContextChildrenHook>(
    'feature-assignments-react:grading-modal-visibility-context-children',
    [],
    undefined,
  )

  // We don't want to define another context, so just pass the children directly
  // so we can add this context further up in the tree.
  if (existing) return <>{children}</>

  return (
    <GradingModalVisibilityContext.Provider value={contextValue}>
      <>
        {hookChildren}
        {gradingAssignment ? (
          <GradingModal
            assignment={gradingAssignment}
            visible={visible}
            defaultTab={defaultTab}
            onClose={() => {
              setVisible(false)
              setTimeout(() => {
                if (!isNative) {
                  const params = new URLSearchParams(window.location.search)
                  params.delete('grading')
                  params.delete('tab')
                  const stringParams = params.toString()
                  window.history.pushState(
                    {},
                    `Grading ${gradingAssignment.name}`,
                    `${window.location.pathname}${stringParams ? `?${stringParams}` : ''}`,
                  )
                }
                _setGradingAssignment(undefined)
              }, 500)
            }}
          />
        ) : null}
        {children}
      </>
    </GradingModalVisibilityContext.Provider>
  )
}

export function useGradingModalVisibilityContext(): GradingModalVisibilityContextValue | undefined
export function useGradingModalVisibilityContext(
  require: false,
): GradingModalVisibilityContextValue | undefined
export function useGradingModalVisibilityContext(require: true): GradingModalVisibilityContextValue
export function useGradingModalVisibilityContext(
  require?: boolean,
): GradingModalVisibilityContextValue | undefined {
  const context = React.useContext(GradingModalVisibilityContext)
  if (!context && require) {
    throw new Error('GradingModalVisibilityContext is required, yet not provided.')
  }
  return context
}
