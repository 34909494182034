import { styled } from '../styledTypes'

export const AspectBox = styled.div<{ numYears: number; numItems?: number }>`
  position: relative;
  width: 100%;
  padding-bottom: ${props => 15 * props.numYears}%;
  overflow-x: auto;
  overflow-y: hidden;
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: ${props => (props.numItems ? props.numItems * 20 : 0)}px;
    height: 100%;
  }
`
