import { MyContentPageResource } from '@thesisedu/feature-users-react'
import { ClipboardCheck } from '@thesisedu/react/icons'
import React from 'react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { RubricTemplateRoutes } from '../../rubric/RubricTemplateRoutes'

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<MyContentPageResource>({
    type: 'MyContentPage',
    identifier: 'rubric-templates',
    title: 'Rubric Templates',
    icon: <ClipboardCheck />,
    weight: 3,
    filter: viewer =>
      viewer?.group === 'TEACHER' ||
      viewer?.group === 'ADMIN' ||
      viewer?.group === 'CONTENT_MANAGER',
    component: () => <RubricTemplateRoutes />,
  })
}
