import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'
import { ClassStudentPair } from '@thesisedu/feature-classes-react'
import React from 'react'

import { NoGroups } from './NoGroups'
import { WithGroups } from './WithGroups'

export interface GroupsEditorProps {
  value?: GroupsConfiguration
  onChange?: (groups: GroupsConfiguration) => void
  students: ClassStudentPair[]
  hideSavePreset?: boolean
  classId: string
}
function _GroupsEditor(
  { students, value, onChange, hideSavePreset, classId }: GroupsEditorProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const hasGroups = !!Object.keys(value ?? {}).length
  if (hasGroups) {
    return (
      <WithGroups
        students={students}
        value={value}
        onChange={onChange}
        hideSavePreset={hideSavePreset}
        classId={classId}
      />
    )
  } else {
    return <NoGroups students={students} onChange={onChange} classId={classId} />
  }
}
export const GroupsEditor = React.forwardRef(_GroupsEditor)
