import {
  QuestionInstructions,
  QuestionProps,
  useSubmissionData,
  QuestionTypeResource,
} from '@thesisedu/feature-assignments-react'
import { useResource } from '@thesisedu/feature-react'
import { Form } from '@thesisedu/react'
import React from 'react'

import { GraphicDragDropView } from './GraphicDragDropView'
import { DEFAULT_INSTRUCTIONS, GraphicDragDropConfig } from './types'

export function GraphicDragDropContent(props: QuestionProps<GraphicDragDropConfig>) {
  const disabled = props.disabled
  const resource = useResource<QuestionTypeResource>('QuestionType', 'GraphicDragDrop')
  return (
    <Form.Item
      name={props.question.id}
      validate={value => {
        const isComplete = resource!.isQuestionComplete!(props.question, value)
        return isComplete ? true : 'This question is required.'
      }}
      initialValue={useSubmissionData(props.question.id)}
    >
      <QuestionInstructions
        instructions={props.question.config?.instructions || DEFAULT_INSTRUCTIONS}
      >
        <GraphicDragDropView config={props.question.config!} disabled={disabled} />
      </QuestionInstructions>
    </Form.Item>
  )
}
