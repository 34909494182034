import { useClassSelector } from '@thesisedu/feature-classes-react'
import { PipMediaPlugin } from '@thesisedu/feature-media-react'
import {
  useCreatePractice,
  UseCreatePractice,
  UseCreatePracticeOpts,
} from '@thesisedu/feature-practices-react'
import { useUserStudentQuery } from '@thesisedu/feature-students-react'
import React from 'react'

import { LessonFragment, useLessonQuery } from '../schema'

export interface UseCreatePracticeWithLessonOpts extends UseCreatePracticeOpts {
  lessonId?: string
}
export interface UseCreatePracticeWithLesson extends UseCreatePractice {
  loading?: boolean
  lesson?: LessonFragment
}

export function useCreatePracticeWithLesson({
  lessonId,
  ...rest
}: UseCreatePracticeWithLessonOpts): UseCreatePracticeWithLesson {
  const { setSelectedClassId } = useClassSelector()
  const studentId = useUserStudentQuery().data?.viewer?.student?.id
  const { data, loading } = useLessonQuery({
    variables: { id: lessonId || '' },
    skip: !lessonId,
  })
  const { recordProps, ...returnRest } = useCreatePractice({
    ...rest,
    extraCreateInput: {
      ...rest.extraCreateInput,
      lessonId,
    },
  })
  const lesson = data?.node?.__typename === 'Lesson' ? data.node : undefined
  const lessonClassId = lesson?.students.edges.find(
    edge => edge.node.id === studentId && edge.class?.id,
  )?.class?.id
  React.useEffect(() => {
    if (lessonClassId) {
      setSelectedClassId(lessonClassId)
    }
  }, [lessonClassId])

  return {
    loading,
    lesson,
    recordProps: {
      ...recordProps,
      children: (
        <>
          {lesson?.media.id ? <PipMediaPlugin mediaId={lesson.media.id} title={'Lesson'} /> : null}
          {recordProps.children}
        </>
      ),
    },
    ...returnRest,
  }
}
