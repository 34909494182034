import { useStatePropFallback } from '@thesisedu/feature-react'
import React from 'react'

export interface OutlineCollapseContextValue {
  expandedParentIds?: string[]
  setParentCollapsed: (parentId: string, collapsed: boolean) => void
}
export const OutlineCollapseContext = React.createContext<OutlineCollapseContextValue | undefined>(
  undefined,
)

export interface OutlineCollapseProviderProps {
  expandedParentIds?: string[]
  setExpandedParentIds?: (ids: string[]) => void
  children: React.ReactElement
}
export function OutlineCollapseProvider({
  expandedParentIds: _expandedParentIds,
  setExpandedParentIds: _setExpandedParentIds,
  children,
}: OutlineCollapseProviderProps) {
  const [expandedParentIds, setExpandedParentIds] = useStatePropFallback(
    _expandedParentIds,
    _setExpandedParentIds,
    [],
  )
  return (
    <OutlineCollapseContext.Provider
      value={{
        expandedParentIds,
        setParentCollapsed: (parentId, collapsed) => {
          const without = expandedParentIds.filter(pid => pid !== parentId)
          setExpandedParentIds(collapsed ? without : [...without, parentId])
        },
      }}
    >
      {children}
    </OutlineCollapseContext.Provider>
  )
}

export function useOutlineCollapseContext(): OutlineCollapseContextValue | undefined
export function useOutlineCollapseContext(require: false): OutlineCollapseContextValue | undefined
export function useOutlineCollapseContext(require: true): OutlineCollapseContextValue
export function useOutlineCollapseContext(
  require?: boolean,
): OutlineCollapseContextValue | undefined {
  const context = React.useContext(OutlineCollapseContext)
  if (!context && require) {
    throw new Error('OutlineCollapseContext is required, yet not provided.')
  }
  return context
}
