import { MainContentComponentProps } from '@thesisedu/feature-classes-react'
import { useResource } from '@thesisedu/feature-react'
import { Text, LoadingIndicator, Button, HSpaced, styled, VSpaced, Block } from '@thesisedu/ui'
import { NavArrowRight, Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { Logo } from '../setup/Rostering/RosteringHeader'
import { SetupModal } from '../setup/SetupModal'
import { ClassSyncProviderResource, ImportStudentPayload } from '../types'
import { ClassSyncItem, useClassSyncSummary } from '../useClassSyncSummary'

export interface ImportClassSyncItemProps {
  sync: ClassSyncItem
  onRoster: () => void
}
export function ImportClassSyncItem({ sync, onRoster }: ImportClassSyncItemProps) {
  const resource = useResource<ClassSyncProviderResource>(
    'ClassSyncProvider',
    sync.providerIdentifier,
  )
  if (!resource) return null
  return (
    <ClassSyncItemContainer space={'xs'}>
      <Logo
        style={{ width: 48, height: 48, flexShrink: 0 }}
        src={resource.logos.square}
        alt={`Logo for ${sync.providerName}`}
      />
      <Block right>
        <Text truncate>{sync.label}</Text>
        <Text level={'s'} color={'secondary'} truncate>
          {sync.providerName}
        </Text>
      </Block>
      <Button onPress={onRoster} style={{ flexShrink: 0 }}>
        <span>Roster</span> <NavArrowRight />
      </Button>
    </ClassSyncItemContainer>
  )
}
const ClassSyncItemContainer = styled(HSpaced)`
  > button {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s linear;
  }
  &:hover > button {
    opacity: 1;
    pointer-events: all;
  }
`

export function ImportClassSyncMain({
  onModeSelected,
  class: cls,
}: MainContentComponentProps<ImportStudentPayload>) {
  const { syncs, loading } = useClassSyncSummary(cls.id)
  let content
  if (loading) {
    content = <LoadingIndicator.Labeled label={'Loading syncs...'} />
  } else {
    const listContent = [
      ...syncs.map(sync => (
        <ImportClassSyncItem
          key={sync.providerId}
          sync={sync}
          onRoster={() => {
            onModeSelected({
              ...sync,
              classId: cls.id,
            })
          }}
        />
      )),
    ]
    content = (
      <>
        <VSpaced bottom={'m'} space={'s'} align={'stretch'}>
          {listContent}
        </VSpaced>
        <SetupModal
          trigger={
            <Button
              icon={<Plus />}
              variant={['chromeless', 'primary']}
              children={`Attach ${listContent.length ? 'Another ' : ''}Class from LMS`}
              style={{ alignSelf: 'center' }}
            />
          }
        />
      </>
    )
  }

  return (
    <>
      <Text bottom={'m'} color={'secondary'}>
        Select one of your classes from the list below, and then select the students you would like
        to import.
      </Text>
      {content}
    </>
  )
}
