import { Button, Form, Modal, useToast } from '@thesisedu/ui'
import { Plus, SaveActionFloppy } from '@thesisedu/ui/icons'
import React from 'react'

import { useCreateClassGroupPresetMutation } from '../../queries/useCreateClassGroupPresetMutation'
import { ClassGroupPresetInput, GroupSubmissionType } from '../../schema'
import { useGroupsContext } from '../GroupsContext'

export function SaveAsPresetButton() {
  const { groups, classId } = useGroupsContext(true)
  const toast = useToast()
  const [create] = useCreateClassGroupPresetMutation({
    onCompleted() {
      toast.success('Preset created')
    },
  })
  const form = Form.useForm<Pick<ClassGroupPresetInput, 'name'>>()
  return (
    <Form.Modal
      form={form}
      title={'Save as Preset'}
      trigger={<Button icon={<SaveActionFloppy />} children={'Save as Preset'} />}
      onFinish={async values => {
        await create({
          variables: {
            input: {
              id: classId,
              preset: {
                submissionType: GroupSubmissionType.Group,
                configuration: groups,
                name: values.name,
              },
            },
          },
        })
      }}
    >
      <Form.TextField
        name={'name'}
        label={'Name'}
        placeholder={'My Preset'}
        required
        autoComplete={'off'}
      />
      <Modal.Footer>
        <Modal.CloseButton children={'Cancel'} />
        <Form.Submit children={'Create Preset'} icon={<Plus />} disableUntilDirty />
      </Modal.Footer>
    </Form.Modal>
  )
}
