import * as ReactDropdown from '@radix-ui/react-dropdown-menu'
import React from 'react'

import { NavArrowDown } from '../../../icons'
import Button, * as Button$ from '../Button'

export interface DropdownButtonProps extends Button$.ButtonProps {}
function _DropdownButton(props: DropdownButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  return (
    <ReactDropdown.Trigger asChild disabled={props.disabled} ref={ref}>
      <Button rightDecorator={<NavArrowDown />} {...props} />
    </ReactDropdown.Trigger>
  )
}
export const DropdownButton = React.forwardRef(_DropdownButton)
