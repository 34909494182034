import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Dropdown } from '@thesisedu/ui'
import { Group } from '@thesisedu/ui/icons'
import React from 'react'

import { useUpdateClassGroupPresetMutation } from '../../schema'

export interface MakeDefaultActionProps {
  isDefault?: boolean
  id: string
  classId: string
}
export function MakeDefaultAction({ isDefault, classId, id }: MakeDefaultActionProps) {
  const [update, { loading }] = useUpdateClassGroupPresetMutation({
    onError: onMutationError('There was an error making that preset the default.'),
    update(cache) {
      cache.evict({
        id: cache.identify({ __typename: 'Class', id: classId }),
        fieldName: 'groupPresets',
      })
    },
  })
  return (
    <Dropdown.Item
      loading={loading}
      disabled={isDefault}
      children={isDefault ? 'Already Default' : 'Make Default'}
      icon={<Group />}
      onClick={async () => {
        await update({
          variables: {
            input: {
              id,
              patch: { isDefault: true },
            },
          },
        })
      }}
    />
  )
}
