import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { isNative } from '@thesisedu/react'

export interface UpdatesReactFeatureOptions {}

export class UpdatesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-updates-react'
  static path = ''
  static requires: string[] = []
  public options!: UpdatesReactFeatureOptions

  public reactResources() {
    if (!isNative) {
      require('./hooks/shellTopRight').default(this)
    }
  }
}

export const updatesReactFeature: ReactUse = (opts: UpdatesReactFeatureOptions) => [
  UpdatesReactFeature,
  opts,
]
