import React from 'react'

import { GridContextValue } from './types'

export const GridContext = React.createContext<GridContextValue<any>>({
  items: [],
  renderItem: () => null,
})

export interface InnerGridContextValue {
  numColumns: number
  itemWidth: number
  gutter: number
}
export const InnerGridContext = React.createContext<InnerGridContextValue>({
  numColumns: 0,
  itemWidth: 0,
  gutter: 0,
})
