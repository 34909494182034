import { useClasses } from '@thesisedu/feature-classes-react'
import { Dropdown } from '@thesisedu/ui'
import { ViewColumns2 } from '@thesisedu/ui/icons'
import React from 'react'

import { useNavigateToSplitView } from './useNavigateToSplitView'

export interface OpenInSplitViewButtonProps {
  courseId: string
}
export function OpenInSplitViewButton({ courseId }: OpenInSplitViewButtonProps) {
  const { classes, loading } = useClasses({ archived: false })
  const navigate = useNavigateToSplitView()
  return (
    <Dropdown.Container>
      <Dropdown.Button
        variant={'primary'}
        icon={<ViewColumns2 />}
        children={'Open in Split View'}
        loading={loading}
        disabled={!classes.length}
      />
      <Dropdown.Menu align={'end'}>
        {classes.map(cls => {
          return (
            <Dropdown.Item
              key={cls.id}
              children={cls.name}
              onClick={() => {
                navigate({
                  classId: cls.id,
                  pane: courseId,
                })
              }}
            />
          )
        })}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
