import {
  ExpandedContentComponentProps,
  MainContentComponentProps,
} from '@thesisedu/feature-classes-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { useTeacherIdQuery } from '@thesisedu/feature-teachers-react/dist/schema'
import { WarningCircle } from '@thesisedu/react/icons'
import { BodySmall, Body, Block, BodyLarge } from '@thesisedu/web'
import React from 'react'

import { AddManual } from './import/AddManual'
import { Generate } from './import/Generate'
import { Import } from './import/Import'
import { InviteExisting } from './import/InviteExisting'

export enum ImportMode {
  Generate = 'generate',
  Manual = 'manual',
  Import = 'import',
  Invite = 'invite',
}
export interface ImportStudentIDPayload {
  selectedMode?: ImportMode
}

export function MainContent(props: MainContentComponentProps<ImportStudentIDPayload>) {
  const name = useFeatureRoot().appOptions.name
  const { data } = useTeacherIdQuery()
  const teacherId = data?.viewer?.teacher?.teacherId
  return (
    <>
      <Body color={'@text-color-secondary'}>
        Students will login with your Teacher ID ({teacherId?.toUpperCase() || '--'}) and their
        Student ID, which you assign to them.
      </Body>
      <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
        <BodyLarge color={'@orange'}>
          <WarningCircle />
        </BodyLarge>
        <BodySmall color={'@orange'}>
          <strong>This is a legacy feature.</strong> {name} supports several different ways to
          invite students to your class that are much easier to use. It's only recommended to use
          this method if your students don't have a school email address.
        </BodySmall>
      </Block>
      <Block marginTop={'@size-xs'}>
        <a onClick={() => props.onModeSelected({ selectedMode: ImportMode.Generate })}>
          Generate Students in Bulk &rarr;
        </a>
      </Block>
      <Block marginTop={'@size-xs'}>
        <a onClick={() => props.onModeSelected({ selectedMode: ImportMode.Import })}>
          Import Students from Spreadsheet &rarr;
        </a>
      </Block>
      <Block marginTop={'@size-xs'}>
        <a onClick={() => props.onModeSelected({ selectedMode: ImportMode.Manual })}>
          Add Students Manually &rarr;
        </a>
      </Block>
      <Block marginTop={'@size-xs'}>
        <a onClick={() => props.onModeSelected({ selectedMode: ImportMode.Invite })}>
          Add Existing Student with Student ID / Teacher ID &rarr;
        </a>
      </Block>
    </>
  )
}

export function ExpandedContent(props: ExpandedContentComponentProps<ImportStudentIDPayload>) {
  const {
    payload: { selectedMode },
  } = props
  if (selectedMode === ImportMode.Generate) {
    return <Generate {...props} />
  } else if (selectedMode === ImportMode.Manual) {
    return <AddManual {...props} />
  } else if (selectedMode === ImportMode.Import) {
    return <Import {...props} />
  } else if (selectedMode === ImportMode.Invite) {
    return <InviteExisting {...props} />
  }

  return null
}
