import {
  FullAssignment,
  AssignmentSettings as RealAssignmentSettings,
} from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { ScheduleSegmentButton, useCourseContext } from '@thesisedu/feature-courses-react'
import { Format } from '@thesisedu/feature-utils'
import { Text, Block, Button, Separator } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { useCreateAssociatedClassAssignmentMutation } from '../../queries/useCreateAssociatedClassAssignmentMutation'

export interface AssignmentSettingsProps {
  assignment?: FullAssignment
  segmentId: string
  templateId: string
}
export function AssignmentSettings({ assignment, segmentId, templateId }: AssignmentSettingsProps) {
  const classId = useSelectedClassId(true)
  const { segmentMetadata } = useCourseContext(true)
  const scheduledAt = segmentMetadata?.segments?.find(s => s.id === segmentId)?.scheduledAt
  const [create, { loading }] = useCreateAssociatedClassAssignmentMutation({
    awaitRefetchQueries: true,
  })
  if (assignment) {
    return <RealAssignmentSettings assignment={assignment} classId={classId} />
  } else {
    return (
      <Block bottom={'xl'}>
        <Text level={'l'} bottom style={{ maxWidth: 800 }}>
          This content is not in your gradebook yet.{' '}
          <strong>In order to adjust this assignment's settings, you must create it first.</strong>
        </Text>
        <Button
          variant={'primary'}
          icon={<Edit />}
          loading={loading}
          onPress={() => {
            create({
              variables: {
                input: {
                  segmentId,
                  templateId,
                  id: classId,
                },
              },
            })
          }}
        >
          Edit Assignment Settings
        </Button>
        <Block top={'xs'}>
          <Text level={'s'} color={'secondary'}>
            Don't worry, it won't show up for your students until you enable it.
          </Text>
        </Block>
        <Separator top={'m'} bottom={'m'} />
        <Text bottom style={{ maxWidth: 800 }}>
          You may also schedule this content to be enabled at a later date.
        </Text>
        <ScheduleSegmentButton segmentId={segmentId} />
        {scheduledAt ? (
          <Block top={'xs'}>
            <Text bottom style={{ maxWidth: 800 }}>
              Scheduled for {Format.date(scheduledAt, 'dateTime')}
            </Text>
          </Block>
        ) : null}
      </Block>
    )
  }
}
