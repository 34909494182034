import * as React from 'react'

function SvgNumber1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#FEFEFE'}
          d={
            'M250.985 128c0 67.923-55.062 122.985-122.985 122.985S5.014 195.923 5.014 128 60.077 5.015 128 5.015 250.985 60.077 250.985 128'
          }
        />
        <path
          fill={'#EC2224'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.97 159.51 245.97 128c0-31.511-12.27-61.136-34.553-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.333 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.332 242.684 162.19 256 128 256'
          }
        />
        <path
          fill={'#EC3131'}
          fillRule={'nonzero'}
          d={
            'M156.007 202.335l7.194-159.414-52.254 5.302-37.298 35.404 18.554 23.855 16.85-14.199.568 102.994z'
          }
        />
        <path
          stroke={'#502D1B'}
          strokeWidth={6}
          d={
            'M109.053 93.283l.568 102.994 46.386 6.058 7.194-159.414-52.254 5.302-37.298 35.404 18.554 23.855z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNumber1 = React.memo(SvgNumber1)
export default MemoSvgNumber1
