import { useModelLink } from '@thesisedu/feature-react'
import React from 'react'
import { Link } from 'react-router-dom'

import { AdminCommentFragment } from './schema'

export interface CommentContentLinkProps {
  comment: Pick<AdminCommentFragment, 'contentName' | 'nodeId'>
}
export function CommentContentLink({ comment }: CommentContentLinkProps) {
  const link = useModelLink({ id: comment.nodeId })
  if (link) {
    return <Link to={link} children={comment.contentName} />
  } else {
    return <span>{comment.contentName}</span>
  }
}
