import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { CampaignListItem } from './CampaignListItem'
import {
  TrialRequestCampaignFragment,
  TrialRequestCampaignsDocument,
  TrialRequestCampaignsQuery,
  TrialRequestCampaignsQueryVariables,
} from '../schema'

export interface CampaignsListProps {
  onCampaignClick: (campaign: TrialRequestCampaignFragment) => void
  selectedItemId?: string
}
export function CampaignsList({ onCampaignClick, selectedItemId }: CampaignsListProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <div ref={containerRef} style={{ minHeight: 300 }}>
      <InfiniteQuery<
        TrialRequestCampaignFragment,
        TrialRequestCampaignsQuery,
        TrialRequestCampaignsQueryVariables
      >
        document={TrialRequestCampaignsDocument}
        resultPath={'trialRequestCampaigns'}
        infiniteScrollerProps={{
          scrollableTarget: () => containerRef.current,
        }}
        children={({ data }) => {
          const campaigns = data?.trialRequestCampaigns.edges.map(edge => edge.node) || []
          return (
            <>
              {campaigns.map(campaign => (
                <CampaignListItem
                  campaign={campaign}
                  key={campaign.id}
                  onClick={() => onCampaignClick(campaign)}
                  isSelected={selectedItemId === campaign.id}
                />
              ))}
            </>
          )
        }}
      />
    </div>
  )
}
