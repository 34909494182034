import { CloudUploadOutlined } from '@ant-design/icons'
import { cloneDeep } from '@apollo/client/utilities'
import { SelectButton } from '@thesisedu/feature-apollo-react/web'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Block, styled } from '@thesisedu/web'
import { Button, Divider, Input } from 'antd'
import { set } from 'lodash'
import React, { useMemo, useState } from 'react'

import { DefaultVideoUpload } from './DefaultVideoUpload'
import { GenerateCaptionsButton } from './GenerateCaptionsButton'
import { ProviderSelect } from './ProviderSelect'
import { VideoViewer } from './VideoViewer'
import {
  VideoOnDemandDocument,
  VideoOnDemandFragment,
  VideosOnDemandDocument,
  VideosOnDemandQuery,
  VideosOnDemandQueryVariables,
} from './schema'
import { VodReactProviderResource } from './types'

const Container = styled.div`
  .select {
    border: solid 1px ${props => props.theme['@border-color-base']};
    padding: 10px;
    border-radius: ${props => props.theme['@border-radius-base']};
    .create-header {
      display: block;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.5px;
      margin-bottom: 10px;
    }
    > input,
    > textarea,
    > .field {
      margin-bottom: 5px;
    }
  }
  .clear {
    display: block;
    margin-top: 10px;
  }
`

export interface VideoUploadProps {
  onChange?: (vodId: string | undefined) => void
  value?: string
  noPlaceholder?: boolean
}
export function VideoUpload({ noPlaceholder, onChange, value }: VideoUploadProps) {
  const [name, setName] = useState<string | undefined>()
  const [description, setDescription] = useState<string | undefined>()
  const [provider, setProvider] = useState<string | undefined>()
  const [upload, setUpload] = useState(false)
  const root = useFeatureRoot()

  const selectButton = useMemo(
    () => (
      <SelectButton<VideoOnDemandFragment, VideosOnDemandQuery, VideosOnDemandQueryVariables>
        document={VideosOnDemandDocument}
        nodeDocument={VideoOnDemandDocument}
        getResult={r => r?.videosOnDemand}
        setResult={(p, n) => set(cloneDeep(p), 'videosOnDemand', n)}
        selectLabel={'Select a Video'}
        onChange={selections => {
          if (selections?.[0] && onChange) {
            onChange(selections[0])
          }
        }}
        maxSelection={1}
        getItemName={item => item.name}
        filterVariableName={'name'}
      />
    ),
    [],
  )

  let content
  if (value) {
    content = (
      <>
        <VideoViewer showPlaceholder={!noPlaceholder} vodId={value} />
        <Block marginTop={'@size-s'}>
          <GenerateCaptionsButton vodId={value} />
        </Block>
        <a
          className={'clear'}
          onClick={() => {
            if (onChange) {
              onChange(undefined)
            }
          }}
        >
          Choose or Upload Another Video &rarr;
        </a>
      </>
    )
  } else if (upload && name && provider) {
    const resource = root.deps.resources.getResource<VodReactProviderResource>(
      'VodReactProvider',
      provider,
    )
    const UploadComponent = resource?.UploadComponent || DefaultVideoUpload
    content = (
      <UploadComponent
        name={name}
        provider={provider}
        description={description}
        onComplete={vodId => {
          if (onChange) {
            onChange(vodId)
          }
        }}
      />
    )
  } else {
    content = (
      <div className={'select'}>
        {selectButton}
        <Divider>or</Divider>
        <span className={'create-header'}>Create a Video</span>
        <ProviderSelect value={provider} onChange={setProvider} className={'field'} />
        <Input
          type={'text'}
          onChange={e => setName(e.target.value)}
          value={name}
          placeholder={'Name'}
        />
        <Input.TextArea
          onChange={e => setDescription(e.target.value)}
          value={description}
          placeholder={'Description'}
          autoSize={{ minRows: 2, maxRows: 4 }}
        />
        <Button
          type={'primary'}
          onClick={() => {
            if (name?.trim()) {
              setUpload(true)
            }
          }}
          icon={<CloudUploadOutlined />}
          disabled={!name?.trim()}
        >
          Upload New Video
        </Button>
      </div>
    )
  }

  return <Container children={content} />
}
