import { useResource } from '@thesisedu/feature-react'

import { SimpleSegmentPresetResource } from '../../types'

export interface SimplePresetOpts {
  type: string
}
interface DeepMap {
  [key: string]: any
}
export interface SimplePresetResult {
  formValues: DeepMap
  typeLabel?: string
}

export function useSimplePreset({ type }: SimplePresetOpts): SimplePresetResult | null {
  const params = new URLSearchParams(window.location.search)
  const preset = useResource<SimpleSegmentPresetResource>(
    'SimpleSegmentPreset',
    params.get('preset') || '',
  )
  return preset && preset.segmentType === type
    ? {
        formValues: preset.formValues,
        typeLabel: preset.label,
      }
    : null
}
