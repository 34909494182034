import React from 'react'
import { TableProps as RATableProps, Table as RATable } from 'react-aria-components'

import { s } from '../..'

export interface TableContextValue {
  loading?: boolean
}
export const TableContext = React.createContext<TableContextValue | undefined>(undefined)

export function useTableContext(): TableContextValue {
  const context = React.useContext(TableContext)
  if (!context) {
    throw new Error('TableContext is required, yet not provided.')
  }
  return context
}

export interface TableProps extends RATableProps {
  loading?: boolean
}
function _Table({ loading, ...props }: TableProps, ref: React.ForwardedRef<HTMLTableElement>) {
  return (
    <TableContext.Provider value={{ loading }}>
      <__Table disabledBehavior={'all'} {...props} ref={ref} />
    </TableContext.Provider>
  )
}
export const Table = React.forwardRef(_Table)

const { css } = s
const __Table = s.styledWithVariants(
  RATable,
  'Table',
  css`
    --table-highlight-background: ${s.color('subtle')};
    --table-highlight-foreground: ${s.color('textActive')};
    --table-text-color: ${s.color('text')};
    --table-text-color-disabled: ${s.color('secondary')};
    --table-text-emphasis-color: ${s.color('textEmphasis')};
    --table-font-size: ${s.size('font.m')};
    --table-size-xxs: calc(${s.size('xs')} * 0.75);
    --table-size-xs: calc(${s.size('s')} * 0.75);
  `,
  {},
)
