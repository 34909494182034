import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { MatchConfig, MatchOption } from '@thesisedu/feature-assignments-core'
import { HSpaced } from '@thesisedu/web'
import { AutoComplete, Button, Input } from 'antd'
import { DataSourceItemType } from 'antd/lib/auto-complete'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { QuestionTextMediaField } from '../../../questions/QuestionTextMediaField'
import { QuestionProps } from '../../../types'
import { WarningMessage } from '../Match/MatchEdit'
import { MatchGroupQuestionAdditionalFields } from '../MatchGroupQuestionAdditionalFields'
import { MultipleChoiceChoice } from '../MultipleChoiceChoice'
import { QuestionInstructions } from '../QuestionInstructions'
import { ChoiceEditorContainer } from '../styled'

export function GroupEdit(props: QuestionProps<MatchConfig>) {
  const { onChange, question } = props
  const { options = [] } = question.config || {}
  const destinations = [...new Set(options.map(opt => opt.destination) || [])]
  const uniqueItems = [...new Set(options.map(opt => [opt.source, opt.destination].join('')))]
  const uniqueSources = [...new Set(options.map(opt => opt.source) || [])]
  const hasDuplicateItems = uniqueItems.length !== options.length
  const hasDuplicateSources = uniqueSources.length !== options.length
  const addOption = () => {
    if (onChange) {
      const newId = uuid()
      const newOptions = [
        ...options,
        {
          id: newId,
          source: '',
          destination: '',
        },
      ]
      onChange({
        config: {
          ...question.config,
          options: newOptions,
        },
        totalPoints: newOptions.length,
      })
    }
  }
  return (
    <>
      <QuestionInstructions instructions={'Enter your group associations using the fields below.'}>
        {hasDuplicateItems ? (
          <WarningMessage>
            <ExclamationCircleOutlined /> You cannot have two items with the same name and group.
          </WarningMessage>
        ) : null}
        {hasDuplicateSources ? (
          <WarningMessage>
            <ExclamationCircleOutlined /> You cannot have two items on the left side with the same
            name.
          </WarningMessage>
        ) : null}
        {options.map(option => {
          const update = (changes: Partial<MatchOption>) => {
            if (onChange) {
              onChange({
                config: {
                  ...question.config,
                  options: options.map(opt => {
                    if (opt.id === option.id) {
                      return { ...opt, ...changes }
                    } else {
                      return opt
                    }
                  }),
                },
                totalPoints: options.length || 1,
              })
            }
          }
          return (
            <GroupOptionEditor
              key={option.id}
              option={option}
              onChange={update}
              destinations={destinations}
              onDelete={() => {
                if (onChange) {
                  const newOptions = options.filter(opt => opt.id !== option.id)
                  onChange({
                    config: {
                      ...question.config,
                      options: newOptions,
                    },
                    totalPoints: newOptions.length || 1,
                  })
                }
              }}
            />
          )
        })}
        <MultipleChoiceChoice
          isAdd
          option={{
            id: 'add',
            name: `Add ${options.length ? 'another' : 'the first'} group assocation...`,
            correct: false,
          }}
          index={1}
          onClick={addOption}
        />
      </QuestionInstructions>
      <MatchGroupQuestionAdditionalFields {...(props as QuestionProps<any>)} />
    </>
  )
}

const CREATE_VALUE = 'create'
export interface GroupOptionEditorProps {
  onChange: (changes: Partial<MatchOption>) => void
  onDelete: () => void
  option: MatchOption
  destinations: string[]
}
export function GroupOptionEditor({
  option,
  onDelete,
  onChange,
  destinations,
}: GroupOptionEditorProps) {
  const [search, setSearch] = React.useState<string>(option.destination)
  React.useEffect(() => setSearch(option.destination), [option.destination])
  const dataSource: DataSourceItemType[] = destinations
    .filter(destination => {
      return destination && destination.toLowerCase().includes(search.toLowerCase())
    })
    .map(destination => ({
      value: destination,
      text: destination,
    }))
  if (!destinations.some(d => d === search) && search.trim()) {
    dataSource.push({ value: CREATE_VALUE, text: `Create '${search}'` })
  }
  return (
    <ChoiceEditorContainer>
      <HSpaced align={'flex-start'} style={{ width: '100%' }}>
        <QuestionTextMediaField
          value={option.source}
          onChange={source => onChange({ source })}
          textAreaProps={{
            autoSize: { minRows: 1, maxRows: 3 },
            placeholder: 'Wordbank Word',
          }}
          style={{ flex: 1 }}
        />
        <AutoComplete
          onSearch={setSearch}
          onSelect={value => {
            if (value === CREATE_VALUE) {
              onChange({ destination: search })
            } else {
              onChange({ destination: value })
            }
          }}
          style={{ flex: 1 }}
          value={search || ''}
          onBlur={() => {
            onChange({ destination: search })
          }}
          dataSource={dataSource}
        >
          <Input placeholder={'Search for groups...'} />
        </AutoComplete>
        <Button icon={<DeleteOutlined />} onClick={onDelete} />
      </HSpaced>
    </ChoiceEditorContainer>
  )
}
