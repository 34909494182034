import { OrderedListOutlined } from '@ant-design/icons'
import { OptionsConfig } from '@thesisedu/feature-assignments-core'

import { MultipleChoiceAnswer } from './MultipleChoiceAnswer'
import { MultipleChoiceContent } from './MultipleChoiceContent'
import { MultipleChoiceEdit } from './MultipleChoiceEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'MultipleChoice',
  defaults: {
    name: 'Question',
    config: {
      options: [{ id: 'option-one', name: 'Option 1', correct: false }],
      requireExpansion: false,
      excludeFromQuizVault: false,
      isRecommended: false,
      explanation: null,
    },
  },
  icon: OrderedListOutlined,
  label: 'Multiple Choice',
  group: 'Multiple Choice',
  renderAnswerView: MultipleChoiceAnswer,
  renderContent: MultipleChoiceContent,
  renderEdit: MultipleChoiceEdit,
  isAutoGraded: question => {
    return !!question.config?.options?.some(opt => opt.correct !== undefined)
  },
} as QuestionTypeResource<OptionsConfig>
