import { FeatureResource } from '@thesisedu/feature'
import { useResource } from '@thesisedu/feature-react'
import { VSpaced } from '@thesisedu/react'
import React from 'react'

import { MinimalClassFragment, UserFragment } from '../schema'

export interface MoveStudentErrorsProps {
  errors: MoveStudentClassError[]
  toClass: MinimalClassFragment
  studentUser: UserFragment
}
export function MoveStudentErrors({ errors, toClass, studentUser }: MoveStudentErrorsProps) {
  const resources = useResource<MoveStudentErrorResource<any>>('MoveStudentError')
  if (errors.length) {
    return (
      <VSpaced align={'stretch'}>
        {errors.map((err, index) => {
          const resource = resources.find(r => r.identifier === err.type)
          if (!resource) throw new Error(`Cannot find resource '${err.type}'`)
          return (
            <resource.messageComponent
              error={err}
              studentUser={studentUser}
              toClass={toClass}
              key={`${err.type}-${index}`}
            />
          )
        })}
      </VSpaced>
    )
  } else {
    return null
  }
}

export interface MoveStudentClassError {
  type: string
  [key: string]: any
}
export interface MoveStudentErrorMessageProps<Error extends MoveStudentClassError> {
  error: Error
  toClass: MinimalClassFragment
  studentUser: UserFragment
}
export interface MoveStudentErrorResource<Error extends MoveStudentClassError>
  extends FeatureResource {
  type: 'MoveStudentError'
  /** This is the `type` field on the error. */
  identifier: Error['type']
  /** Rendered whenever this error is thrown. */
  messageComponent: (props: MoveStudentErrorMessageProps<Error>) => React.ReactElement
}
