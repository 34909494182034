import { UploadRequestMode } from '@thesisedu/feature'

import { useFileField, UseFileFieldOpts } from './FileField'
import { debug } from './log'

export interface UploadFileOpts {
  extension: string
  mimeType: string
  name: string
  contents: Blob
}

export interface UseFileUploadOpts extends Omit<UseFileFieldOpts, 'onChange'> {
  namespace?: string
  isPublic?: boolean
}
export function useFileUpload({ namespace, isPublic = false, ...opts }: UseFileUploadOpts) {
  const { getWriteFragment } = useFileField(opts)
  return async ({
    contents,
    extension,
    mimeType,
    name,
  }: UploadFileOpts): Promise<string | null> => {
    debug('getting write fragment for %s', name)
    const extensionWithoutPeriod = extension.replace(/^\.+/, '')
    const fragment = await getWriteFragment({
      name,
      sizeInBytes: contents.size,
      signedUploadInput: {
        extension: extensionWithoutPeriod,
        mimeType,
        isPublic,
        namespace,
        requestMode: UploadRequestMode.Basic,
      },
    })
    if (fragment?.uploadUrl.upload) {
      const signedUrlPath = fragment.uploadUrl.upload.path
      const result = await fetch(fragment.uploadUrl.upload.signedUrl, {
        method: 'PUT',
        body: contents,
      })
      if (result.ok) {
        return signedUrlPath
      } else {
        debug('result not ok')
        return null
      }
    } else {
      debug('cannot upload; no path found')
      return null
    }
  }
}
