import { styled, s } from '@thesisedu/ui'
import React from 'react'

const directionMap: Record<MovingParticlesProps['direction'], 'top' | 'left' | 'bottom' | 'right'> =
  {
    left: 'right',
    right: 'left',
    up: 'bottom',
    down: 'top',
  }
const offsetMap: Record<MovingParticlesProps['direction'], 'top' | 'left' | 'bottom' | 'right'> = {
  left: 'top',
  right: 'top',
  up: 'left',
  down: 'left',
}
export interface MovingParticlesProps extends React.HTMLAttributes<HTMLDivElement> {
  direction: 'left' | 'right' | 'up' | 'down'
  style?: any
}
export function MovingParticles({ direction, ...rest }: MovingParticlesProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const container = containerRef.current
    if (container) {
      const handle = setInterval(() => {
        const particle = document.createElement('div')
        particle.style.setProperty('--size', `${Math.random() * 5 + 5}px`)
        if (direction === 'left' || direction === 'right') {
          particle.style.setProperty('transform', 'rotate(90deg)')
        }
        particle.style.setProperty(offsetMap[direction], `${Math.random() * 90}%`)
        particle.style.setProperty('opacity', '0')
        container.appendChild(particle)
        const animation = particle.animate(
          [
            { offset: 0, opacity: 0, [directionMap[direction]]: '-10%' },
            { offset: 0.25, opacity: 0.5 },
            { offset: 0.7, opacity: 0.5 },
            { offset: 0.95, opacity: 0, [directionMap[direction]]: '90%' },
            { offset: 1, opacity: 0 },
          ],
          {
            duration: Math.random() * 500 + 1500,
            delay: Math.random() * 500,
            easing: 'cubic-bezier(.5,0,.5,1)',
          },
        )
        animation.onfinish = () => particle.remove()
        animation.play()
      }, 250)
      return () => clearInterval(handle)
    }
  }, [direction])
  return <Container ref={containerRef} {...rest} />
}

const Container = styled.div`
  position: relative;
  --particle-color: ${s.color('gray.solid')};
  --particle-shadow-color: ${s.opacity(s.color('gray'), 0.2)};
  > div {
    border-radius: 50%;
    position: absolute;
    width: calc(var(--size) * 0.25);
    height: calc(var(--size) * 1.5);
    background: var(--particle-color);
    box-shadow: 0 0 0 calc(var(--size) * 0.25) var(--particle-shadow-color);
  }
`
