import { useTeacherClass } from '@thesisedu/feature-classes-react'
import { Form, Button, Text } from '@thesisedu/ui'
import React from 'react'
import { Link } from 'react-router-dom'

import { AssignmentCategorySelect } from '../AssignmentCategorySelect'
import { MultiAssignmentCategorySelectButton } from '../MultiAssignmentCategorySelectButton'
import { ClassFragmentWithPermissions } from '../types'

export interface AssignmentCategoryFieldProps extends AssignmentCategoryFieldsProps {
  /** An array of assignment category IDs. */
  value?: string[]
  onChange?: (value: string[]) => void
}
function _AssignmentCategoryField(
  { classIds, value, onChange }: AssignmentCategoryFieldProps,
  ref: React.ForwardedRef<HTMLSelectElement>,
) {
  if (classIds?.length === 1) {
    return (
      <AssignmentCategorySelect
        ref={ref}
        classId={classIds[0]}
        possibleValues={value}
        placeholder={'Select a Category...'}
        onChange={categoryId => {
          if (onChange) {
            onChange([categoryId])
          }
        }}
      />
    )
  } else if (classIds && classIds?.length > 0) {
    return (
      <MultiAssignmentCategorySelectButton classIds={classIds} value={value} onChange={onChange} />
    )
  } else {
    return <Button disabled children={'Assign Students First'} />
  }
}
const AssignmentCategoryField = React.forwardRef(_AssignmentCategoryField)

export interface AssignmentCategoryFieldsProps {
  classIds?: string[]
}
export function AssignmentCategoryFields({ classIds }: AssignmentCategoryFieldsProps) {
  let labelRight: React.ReactNode | undefined = undefined
  const { cls: firstClass } = useTeacherClass<ClassFragmentWithPermissions>(
    classIds?.[0] || '',
    false,
  )
  if (classIds?.length === 1) {
    const canManageFirst = firstClass?.canManageAssignmentCategories
    labelRight = canManageFirst ? (
      <Link to={`/teacher/classes/${classIds[0]}/settings`} target={'_blank'}>
        <Text level={'s'} color={'primary'}>
          Manage
        </Text>
      </Link>
    ) : undefined
  }
  return (
    <Form.Field
      name={'assignmentCategoryIds'}
      required={'At least one category is required.'}
      label={'Grading category'}
      labelRight={labelRight}
    >
      <AssignmentCategoryField classIds={classIds} />
    </Form.Field>
  )
}
