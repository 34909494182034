import { Empty } from '@thesisedu/react'
import { Button, LoadingIndicator } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import { PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import React from 'react'

import { CoursePreviewHeader } from './CoursePreviewHeader'
import { UseInClassButton } from './UseInClassButton'
import { CreateClassModal } from '../classes/CreateClassModal'
import { Outline } from '../outline/Outline'
import { OutlineContextProvider } from '../outline/context/OutlineContext'
import { Segment, SMALL_OUTLINE_SEGMENT_HEIGHT } from '../outline/items/Segment'
import { OpenInSplitViewButton } from '../placement/OpenInSplitViewButton'
import { useCourseQuery } from '../schema'

export interface CoursePreviewProps {
  courseId: string
}
export function CoursePreview({ courseId }: CoursePreviewProps) {
  const { data, loading } = useCourseQuery({ variables: { id: courseId } })
  const [createVisible, setCreateVisible] = React.useState(false)
  const course = data?.node?.__typename === 'Course' ? data.node : undefined
  useBreadcrumb(
    { title: course?.label || course?.name || 'View Course' },
    course?.id || 'no-course',
  )
  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading course...'} />
  } else if (course) {
    return (
      <>
        <CreateClassModal
          onClose={() => setCreateVisible(false)}
          courseId={createVisible ? courseId : undefined}
          courseName={createVisible ? course.label || course.name : undefined}
        />
        <PageHead title={`${course.label || course.name} - Explore`} />
        <PageHeader title={course.label || course.name} />
        <CoursePreviewHeader course={course} />
        <OutlineContextProvider
          groupActions={props => (
            <UseInClassButton rawId={props.item.segment.id} children={null} size={'small'} />
          )}
          renderSegment={props => <Segment {...props} useInClass />}
        >
          <Outline
            courseOrClassId={course.id}
            segments={course.latestPublishedVersion?.configuration.segments}
            courseName={course.label || course.name}
            segmentHeight={SMALL_OUTLINE_SEGMENT_HEIGHT}
            collapseDepth={2}
            filterContent={
              <>
                <Button icon={<Plus />} onPress={() => setCreateVisible(true)}>
                  Create Class
                </Button>
                <OpenInSplitViewButton courseId={course.id} />
              </>
            }
          />
        </OutlineContextProvider>
      </>
    )
  } else {
    return <Empty description={'That course could not be found.'} />
  }
}
