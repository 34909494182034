import { MatchGroupConfig, Question } from '@thesisedu/feature-assignments-core'
import { AntIconWrapper } from '@thesisedu/web'
import { Menu } from 'antd'
import { List } from 'iconoir-react'
import React from 'react'

import { QuestionAdditionalFields, QuestionAdditionalFieldsProps } from './QuestionAdditionalFields'

export interface MatchGroupQuestionAdditionalFieldProps extends QuestionAdditionalFieldsProps {
  question: Question<MatchGroupConfig>
}

export function MatchGroupQuestionAdditionalFields({
  question,
  onChange,
  ...rest
}: MatchGroupQuestionAdditionalFieldProps) {
  return (
    <QuestionAdditionalFields
      {...rest}
      onChange={onChange}
      question={question}
      additionalMenuItems={[
        ...(rest.additionalMenuItems || []),
        <Menu.Item
          key={'useDropdown'}
          icon={<AntIconWrapper children={<List />} />}
          onClick={() =>
            onChange &&
            onChange({ config: { ...question.config, useDropdown: !question.config?.useDropdown } })
          }
        >
          Use Dropdown
        </Menu.Item>,
      ]}
      selectedKeys={question.config?.useDropdown ? ['useDropdown'] : []}
    />
  )
}
