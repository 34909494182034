import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createParagraphNode, $getRoot, $isParagraphNode } from 'lexical'
import React from 'react'

export interface AddParagraphOnemptyClickPluginProps {
  anchorElement: HTMLElement
}
export function AddParagraphOnemptyClickPlugin({
  anchorElement,
}: AddParagraphOnemptyClickPluginProps) {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    const click = (event: MouseEvent) => {
      if (event.target === anchorElement) {
        editor.update(() => {
          const root = $getRoot()
          const lastChild = root.getLastChild()
          if (!$isParagraphNode(lastChild) || lastChild.getTextContent().trim() !== '') {
            const node = $createParagraphNode()
            root.append(node)
            node.select()
          }
        })
      }
    }
    anchorElement.addEventListener('click', click)
    return () => {
      anchorElement.removeEventListener('click', click)
    }
  }, [editor])

  return null
}
