import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { Row, StyledThemeContext as ThemeContext } from '@thesisedu/web'
import { Form, Input, Col, DatePicker, InputNumber, Checkbox } from 'antd'
import { FormInstance, FormProps } from 'antd/es/form'
import React, { useContext, useEffect, useState } from 'react'

import { TypeSelect } from './TypeSelect'
import { LicenseType } from './schema'
import { LicenseFormPayload, LicenseHooks } from './types'

export interface LicenseFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
  isStudentPay?: boolean
  disableMonthly?: boolean
}
export const LicenseForm: React.FC<React.PropsWithChildren<LicenseFormProps>> = ({
  form,
  onFinish,
  isStudentPay: _isStudentPay,
  disableMonthly,
}) => {
  const featureRoot = useFeatureRoot()!
  const theme = useContext(ThemeContext)
  const [isStudentPay, setIsStudentPay] = useState(_isStudentPay)
  useEffect(() => {
    setIsStudentPay(_isStudentPay)
  }, [_isStudentPay])
  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onFinish}
      onValuesChange={changedValues => {
        if (Object.keys(changedValues).includes('type')) {
          setIsStudentPay(changedValues.type === LicenseType.StudentPay)
        }
      }}
    >
      <Row>
        <Col xs={24} md={12}>
          <Form.Item
            name={'type'}
            label={'Type'}
            rules={[{ required: true, message: 'A license type is required.' }]}
          >
            <TypeSelect />
          </Form.Item>
          <Form.Item name={'expiresAt'} label={'Expiration Date'}>
            <DatePicker />
          </Form.Item>
          <Form.Item name={'price'} label={'Price'}>
            <InputNumber min={0} precision={2} style={{ minWidth: 150 }} />
          </Form.Item>
          <Form.Item
            name={'seats'}
            label={'Seats'}
            rules={[{ required: true, message: 'A number of seats is required.' }]}
          >
            <InputNumber min={0} precision={0} style={{ minWidth: 150 }} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          {isStudentPay ? (
            <>
              <Form.Item
                name={'monthlyPriceInDollars'}
                label={'Monthly Price ($)'}
                extra={
                  disableMonthly
                    ? 'You cannot change the monthly price on an existing License.'
                    : ''
                }
              >
                <InputNumber
                  min={0}
                  precision={2}
                  style={{ minWidth: 150 }}
                  disabled={disableMonthly}
                />
              </Form.Item>
              <Form.Item name={'payUpFrontPriceInDollars'} label={'Up-front Price ($)'}>
                <InputNumber min={0} precision={2} style={{ minWidth: 150 }} />
              </Form.Item>
              <Form.Item
                name={'payUpFrontMonths'}
                label={'Up-front Months'}
                extra={'The number of months the student gets for paying up-front.'}
              >
                <InputNumber min={0} precision={0} style={{ minWidth: 150 }} />
              </Form.Item>
            </>
          ) : null}
          <Form.Item
            name={'productId'}
            label={'Product ID'}
            extra={"This is internal. You probably don't need to change it."}
          >
            <Input style={{ fontFamily: theme['@code-family'] }} />
          </Form.Item>
          {featureRoot.deps.hookManager.mutateHookSync<LicenseFormPayload>(
            LicenseHooks.LicenseForm,
            null,
            undefined,
          )}
          <Form.Item name={'noEmail'} label={'Email Notification'} valuePropName={'checked'}>
            <Checkbox>Don't send an email notification for updates.</Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
