import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined'
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import { Button, Dropdown, Menu } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { useClassSelector } from '../ClassContext'
import { useOptions } from '../options'

export interface TeacherClassSelectorProps extends ButtonProps {
  selectedClassId?: string
  onSelectedClassChanged: (id: string | undefined) => void
}
export const TeacherClassSelector: React.FC<React.PropsWithChildren<TeacherClassSelectorProps>> = ({
  selectedClassId,
  onSelectedClassChanged,
  ...props
}) => {
  const { classes, loading } = useClassSelector()
  const options = useOptions()
  const selectedName =
    classes.find(cls => cls.id === selectedClassId)?.name ||
    `Select a ${options.classNameCapitalized}`
  const menu = (
    <Menu onClick={({ key }) => onSelectedClassChanged(key.toString())}>
      {classes.map(cls => (
        <Menu.Item key={cls.id}>{cls.name}</Menu.Item>
      ))}
    </Menu>
  )
  return (
    <Button.Group>
      <Dropdown overlay={menu} placement={'bottomRight'}>
        <Button loading={loading} {...props}>
          {selectedName} <DownOutlined />
        </Button>
      </Dropdown>
      {selectedClassId ? (
        <Button icon={<CloseOutlined />} onClick={() => onSelectedClassChanged(undefined)} />
      ) : null}
    </Button.Group>
  )
}
