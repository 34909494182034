import { motion } from 'framer-motion'
import React from 'react'

import { overlayAnimation } from './Image'
import { styled, s } from '../../'

export interface ImageOverlayProps {
  children: React.ReactElement
  animated?: boolean
  className?: string
}
export function ImageOverlay({ children, animated, className }: ImageOverlayProps) {
  return (
    <_ImageOverlay
      children={children}
      initial={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      animate={{ opacity: 1, backdropFilter: `blur(${s.getThemeValue('blur.1')}px)` }}
      exit={{ opacity: 0, backdropFilter: 'blur(0px)' }}
      $animated={animated}
      className={className}
    />
  )
}

const _ImageOverlay = styled(motion.div)<{ $animated?: boolean }>`
  position: absolute;
  inset: 0;
  pointer-events: none;
  border-radius: inherit;
  overflow: hidden;
  background: linear-gradient(
    160deg,
    ${s.var('color.overlayWhite.8')} 10%,
    ${s.var('color.overlayBlack.10')} 100%
  );
  backdrop-filter: blur(${s.var('blur.1')});
  background-size: 200% 200%;
  animation: ${overlayAnimation} 5s ease infinite;
  animation-play-state: ${props => (props.$animated ? 'running' : 'paused')};
  box-shadow: inset 0 0 ${s.size('4')} ${s.var('color.overlayBlack.2')};
`
