import { FeatureResourceManager } from '@thesisedu/feature'
import { AssignmentFlagResource } from '@thesisedu/feature-assignments-react'

import hideKeyboardField from './hideKeyboardField'

const RESOURCES: AssignmentFlagResource[] = [hideKeyboardField]
export function addClassAssignmentFields(resources: FeatureResourceManager) {
  for (const resource of RESOURCES) {
    resources.addResource(resource)
  }
}
