import { $findMatchingParent } from '@lexical/utils'
import { useFreshRef, useStateAndFreshRef } from '@thesisedu/feature-react'
import { fuzzySearch } from '@thesisedu/feature-utils'
import { $getSelection, $isElementNode, ElementNode, LexicalEditor, LexicalNode } from 'lexical'
import { groupBy, orderBy } from 'lodash'
import React from 'react'

import { ElementsMenuItem, useElementsMenuContext } from './ElementsMenuContext'
import { ElementsMenuItem as MenuItem, GroupMenuItem } from './ElementsMenuItem'
import { ChangeableNode } from './types'

function getSelectedElementNode(selectedNode?: LexicalNode | null): ElementNode | null {
  const selection = $getSelection()
  const nodes = selectedNode ? [selectedNode] : selection?.getNodes() || []
  let candidate
  if (nodes.length === 1 && $isElementNode(nodes[0])) {
    candidate = nodes[0]
  } else if (nodes.length === 1) {
    const node = nodes[0]
    candidate = $findMatchingParent(node, node => $isElementNode(node)) as ElementNode | null
  } else {
    candidate = null
  }

  // If the candidate is a paragraph and the parent is a list item, use the list item instead.
  if (candidate && candidate.getParent()?.getType() === 'listItem') {
    candidate = candidate.getParentOrThrow()
  }

  return candidate
}

export function commitElement(
  editor: LexicalEditor,
  item: ElementsMenuItem,
  _selectedNode?: LexicalNode | null,
) {
  editor.update(() => {
    const selectedNode = getSelectedElementNode(_selectedNode) as ChangeableNode | null
    if (selectedNode) {
      if (selectedNode.getTextContent().startsWith('/')) {
        selectedNode.clear()
      }
      if (selectedNode.onChangeTo) {
        const didCommit = selectedNode.onChangeTo(item)
        if (didCommit) return
      }

      item.onCommit(selectedNode)
    }
  })
}

export function useGroupedElementsMenuItems(
  search?: string,
  filter?: (item: ElementsMenuItem) => boolean,
) {
  const context = useElementsMenuContext(true)
  let items = orderBy(context.items, 'weight', 'asc').filter(filter || (() => true))
  if (search?.trim()) {
    items = fuzzySearch(items, ['identifier', 'title'], search)
  }

  return groupBy(items, 'group')
}

export interface UseElementsMenuItemsOpts {
  search?: string
  editor: LexicalEditor
}
export function useElementsMenuItems({ editor, search }: UseElementsMenuItemsOpts) {
  const [selectedIndex, selectedIndexRef, _setSelectedIndex] = useStateAndFreshRef(0)
  const recentAddNode = React.useRef<ElementNode | null>(null)
  const debounceRef = React.useRef<any>()
  const setSelectedIndex: typeof _setSelectedIndex = (...args) => {
    if (!debounceRef.current) {
      debounceRef.current = setTimeout(() => {
        _setSelectedIndex(...args)
        debounceRef.current = null
      }, 50)
    }
  }
  const items = useGroupedElementsMenuItems(search)
  const flatItems = Object.keys(items).flatMap(key => items[key])
  const flatItemsRef = useFreshRef(flatItems)

  // Move the selected index to the end if it exceeds the current items.
  React.useEffect(() => {
    if (selectedIndex > flatItems.length - 1 && flatItems.length) {
      setSelectedIndex(flatItems.length - 1)
    }
  }, [flatItems, selectedIndex])

  function commit(item?: ElementsMenuItem | null) {
    const realItem = item ?? flatItemsRef.current[selectedIndexRef.current ?? 0]
    if (realItem) {
      commitElement(editor, realItem, recentAddNode.current)
    }
  }

  function deltaSelectedIndex(delta: number) {
    setSelectedIndex(selectedIndex => {
      const newIndex = selectedIndex + delta
      if (newIndex < 0) return flatItemsRef.current.length - 1
      else return newIndex % flatItemsRef.current.length
    })
  }

  return {
    items: Object.keys(items).map(group => {
      return (
        <React.Fragment key={group}>
          <GroupMenuItem>{group}</GroupMenuItem>
          {items[group].map(item => {
            const { identifier, icon, title } = item
            const overallIndex = flatItemsRef.current.findIndex(
              item => item.identifier === identifier,
            )
            return (
              <MenuItem
                key={identifier}
                icon={icon}
                title={title}
                selected={overallIndex === selectedIndex}
                onClick={e => {
                  e.preventDefault()
                  commit(item)
                }}
              />
            )
          })}
        </React.Fragment>
      )
    }),
    setSelectedIndex,
    deltaSelectedIndex,
    setRecentAddNode: (node: ElementNode | null) => {
      recentAddNode.current = node
    },
    commit,
  }
}
