import { LoadingIndicator, s, styled } from '@thesisedu/ui'
import { MinusCircle } from '@thesisedu/ui/icons'
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { warn } from '../../../log'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export interface PDFDisplayProps {
  filename: string
  startPage?: number
  numPages?: number
  width?: number
}
function _PDFDisplay({
  startPage: _startPage = 1,
  filename,
  numPages: _numPages,
  width,
}: PDFDisplayProps) {
  const [stateNumPages, setNumPages] = useState(1)
  const [errored, setErrored] = useState(false)
  const pages = []
  const startPage = _startPage ? _startPage - 1 : 0
  for (let i = 0; i < (_numPages || stateNumPages - startPage); i++) {
    pages.push(startPage + i + 1)
  }

  if (errored) {
    return <MinusCircle />
  } else {
    return (
      <Document
        file={{ url: filename }}
        onLoadSuccess={(result: any) => {
          // We have to type as any because pdfInfo is not available.
          if (result?._pdfInfo?.numPages) {
            setNumPages(result._pdfInfo.numPages)
          }
        }}
        onLoadError={err => {
          warn('error fetching PDF (load)', err)
          setErrored(true)
        }}
        onSourceError={err => {
          warn('error fetching PDF (source)', err)
          setErrored(true)
        }}
        externalLinkTarget={'_blank'}
        loading={<LoadingIndicator />}
      >
        {pages.map(pageNumber => (
          <PageContainer key={pageNumber}>
            <Page pageNumber={pageNumber} width={width} loading={<LoadingIndicator />} />
          </PageContainer>
        ))}
      </Document>
    )
  }
}

export const PDFDisplay = React.memo<PDFDisplayProps>(_PDFDisplay, (p, n) => {
  return (
    p.numPages === n.numPages &&
    p.width === n.width &&
    p.filename === n.filename &&
    p.startPage === n.startPage
  )
})

const PageContainer = styled.div`
  border-radius: ${s.var('radii.2')};
  overflow: hidden;
  &:not(:first-child):not(:last-child) {
    margin: ${s.size('s')} 0;
  }
`
