import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { InteractionList } from '@thesisedu/feature-interactions-react/web'
import { WebContext } from '@thesisedu/feature-web'
import { styled } from '@thesisedu/web'
import { set } from 'lodash'
import React from 'react'

import { useClassStudents } from './ClassStudents'
import {
  ClassInteractionsQueryVariables,
  ClassInteractionsQuery,
  ClassInteractionsDocument,
  InteractionFragment,
} from '../schema'

const Container = styled.div`
  overflow-y: auto;
`

export interface ClassActivityProps {
  classId: string
  studentId?: string
  types?: string[]
  containerHeight?: number
}
export function ClassActivity({
  classId,
  types,
  studentId,
  containerHeight = 400,
}: ClassActivityProps) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { students } = useClassStudents()
  const { featureWeb } = React.useContext(WebContext)!
  const availableTypes = featureWeb.deps.resources
    .getResourcesForType<any>('InteractionType')
    .map(r => r.identifier)
  return (
    <Container ref={containerRef} style={{ height: containerHeight }}>
      <InfiniteQuery<InteractionFragment, ClassInteractionsQuery, ClassInteractionsQueryVariables>
        document={ClassInteractionsDocument}
        variables={{
          classId,
          types: types ? availableTypes.filter(t => types.includes(t)) : availableTypes,
          ...(studentId
            ? {
                userIds: students
                  ? [students.find(s => s.id === studentId)?.user.id || ''].filter(Boolean)
                  : [],
              }
            : {}),
        }}
        getResult={r => (r?.node?.__typename === 'Class' ? r.node.interactions : undefined)}
        setResult={(p, n) => set(cloneDeep(p), 'node.interactions', n)}
        infiniteScrollerProps={{ scrollableTarget: containerRef.current || undefined }}
        children={({ data }) => {
          const interactions =
            data?.node?.__typename === 'Class'
              ? data.node.interactions.edges.map(edge => edge.node)
              : []
          return <InteractionList interactions={interactions} />
        }}
      />
    </Container>
  )
}
