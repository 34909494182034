import React from 'react'
import {
  Collection,
  Row as RARow,
  RowProps as RARowProps,
  useTableOptions,
} from 'react-aria-components'

import { Cell, __Cell } from './Cell'
import { Checkbox } from './Checkbox'
import { DragButton } from './DragButton'
import { styled, s } from '../..'

export interface RowProps<T extends object> extends RARowProps<T> {
  dragDropDisabled?: boolean
}
export function Row<T extends object>({
  id,
  columns,
  children,
  className,
  style,
  dragDropDisabled,
  ...rest
}: RowProps<T>) {
  const { selectionBehavior, allowsDragging } = useTableOptions()

  return (
    <_Row id={id} style={style} className={className} {...rest}>
      {allowsDragging && !dragDropDisabled ? (
        <Cell>
          <DragButton />
        </Cell>
      ) : null}
      {selectionBehavior === 'toggle' ? (
        <Cell>
          <Checkbox />
        </Cell>
      ) : null}
      <Collection items={columns}>{children}</Collection>
    </_Row>
  )
}

const { css } = s
export const SelectionSlotShowOnHover = css`
  [slot='selection'],
  [slot='drag'] {
    transition: opacity 0.1s linear;
    opacity: 0;
    &[data-state='checked'] {
      opacity: 1;
    }
  }

  &:hover:not([aria-disabled]),
  &[aria-selected='true'] {
    [slot='selection'] {
      opacity: 1;
    }
  }

  &:hover:not([aria-disabled]) [slot='drag'] {
    opacity: 1;
  }
`
const _Row = styled(RARow)`
  cursor: default;

  [data-visible-on-hover] {
    opacity: 0;
    transition: opacity 0.1s linear;
  }
  &:hover [data-visible-on-hover],
  &:focus [data-visible-on-hover],
  &:focus-within [data-visible-on-hover] {
    opacity: 1;
  }

  &[aria-selected='true'] {
    ${__Cell} {
      background: var(--table-highlight-background);
      color: var(--table-highlight-foreground);
    }
  }

  &[aria-disabled] {
    color: var(--table-text-color-disabled);
  }

  &[data-dragging] {
    opacity: 0.6;
    transform: translateZ(0);
  }
`
