import { Dropdown } from '@thesisedu/ui'
import React from 'react'

export interface SettingsDropdownEnumItemProps<T extends string> {
  value: T
  label: string
  selected: boolean
  onChange: (value: T) => void
}
export function SettingsDropdownEnumItem<T extends string>({
  value,
  label,
  selected,
  onChange,
}: SettingsDropdownEnumItemProps<T>) {
  return (
    <Dropdown.Item.Checkbox
      checked={selected}
      children={label}
      onCheckedChange={checked => {
        if (checked) {
          onChange(value)
        }
      }}
    />
  )
}
