import { LineSvgProps, ResponsiveLine as NivoResponsiveLine } from '@nivo/line'
import { Format } from '@thesisedu/feature-utils'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { AspectBox } from './AspectBox'
import { getColors, getNivoTheme } from './theme'
import { styled } from '../styledTypes'

interface Data {
  [key: string]: any
}
export interface ValueConfig {
  name: string
  formatter?: (value: any, data: Data) => any
}
export interface ValueLabelMap {
  [dataKey: string]: ValueConfig
}
export type ResponsiveLineProps = Omit<LineSvgProps, 'height' | 'width'> & {
  loading?: boolean
  valueLabels?: ValueLabelMap
}
export function Line({ loading, valueLabels, ...props }: ResponsiveLineProps) {
  const theme = useContext(ThemeContext)
  const formatX =
    typeof props.axisBottom?.format === 'function' ? props.axisBottom.format : undefined
  const formatY = typeof props.axisLeft?.format === 'function' ? props.axisLeft.format : undefined
  return (
    <NivoResponsiveLine
      colors={getColors(theme, loading)}
      theme={getNivoTheme(theme)}
      margin={{ left: 45, top: 10, bottom: 30 }}
      pointSize={6}
      pointColor={'#FFFFFF'}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      sliceTooltip={({ slice }) => {
        return (
          <TooltipContainer>
            <div style={{ marginBottom: 8, lineHeight: 1 }}>
              <strong>{slice.points[0].data.xFormatted}</strong>
            </div>
            {slice.points.map(point => {
              return (
                <div key={point.id}>
                  <ColorIcon style={{ background: point.serieColor }} />
                  <span>
                    <span>{point.serieId}:</span>
                    <strong>&nbsp;{point.data.yFormatted}</strong>
                  </span>
                </div>
              )
            })}
          </TooltipContainer>
        )
      }}
      tooltip={p => {
        return (
          <TooltipContainer>
            <span>
              <span>{formatX ? formatX(p.point.data.x) : p.point.data.xFormatted}: </span>
              <strong>
                {formatY
                  ? formatY(p.point.data.y)
                  : Format.number(parseFloat(p.point.data.y.toString()), 'number')}
              </strong>
              <br />
            </span>
          </TooltipContainer>
        )
      }}
      {...props}
      axisLeft={{
        legendOffset: -40,
        legendPosition: 'middle',
        ...props.axisLeft,
      }}
      data={props.data}
    />
  )
}

export function ResponsiveLine({
  numYears = 2,
  ...props
}: ResponsiveLineProps & { numYears?: number }) {
  return (
    <AspectBox numYears={numYears} numItems={props.data?.length || 0}>
      <div>
        <Line {...props} />
      </div>
    </AspectBox>
  )
}

const TooltipContainer = styled.div`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  border-radius: ${props => props.theme['@border-radius-base']};
  box-shadow: ${props => props.theme['@shadow-small']};
  background: ${props => props.theme['@gray-0']};
  border: solid 1px ${props => props.theme['@border-color-base']};
`
const ColorIcon = styled.div`
  width: 0.875em;
  height: 0.875em;
  display: inline-block;
  transform: translateY(2px);
  margin-right: 8px;
  border-radius: 2px;
`
