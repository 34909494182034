import { useFeatureRoot } from '@thesisedu/feature-react'
import { SAMLButtons } from '@thesisedu/feature-users-react/dist/web/routes/SAMLButtons'
import { useProtection, LoginForm } from '@thesisedu/feature-users-web'
import { useTheme, H1, H1Subtitle, Block, VSpaced, Body, BodyLarge, Link } from '@thesisedu/react'
import { ArrowRight } from '@thesisedu/react/icons'
import React from 'react'

export function LoginStudent() {
  useProtection({ allowedGroups: [], redirect: '/' })
  const theme = useTheme()
  const appOptions = useFeatureRoot().appOptions
  return (
    <>
      <H1 style={{ marginBottom: 0 }}>Welcome back</H1>
      <H1Subtitle style={{ marginBottom: theme['@size-l'] }}>
        Login to your {appOptions.name} account
      </H1Subtitle>
      <LoginForm
        submitText={`Login to ${appOptions.name}`}
        copy={{
          usernameName: 'Teacher ID',
          usernamePlaceholder: 'ABCDE',
          usernameRequired: 'A teacher ID is required',
          passwordName: 'Student ID',
          passwordPlaceholder: '12345',
          passwordRequired: 'A student ID is required',
        }}
        usernameIsEmail={false}
        modifyCredentials={credentials => ({
          username: `${credentials.password}${credentials.username.toLowerCase()}`,
          password: `${credentials.username.toLowerCase()}`,
        })}
      />
      <Block marginTop={'@size-s'}>
        <VSpaced space={'@size-xs'} align={'stretch'}>
          <SAMLButtons />
        </VSpaced>
      </Block>
      <Block marginTop={'@size-l'}>
        <VSpaced space={'@size-m'} align={'flex-start'}>
          <Link to={'../register'}>
            <BodyLarge color={'@primary-color'}>
              Create an Account <ArrowRight />
            </BodyLarge>
          </Link>
          <Link to={'../request-password-reset'}>
            <Body color={'@text-color-secondary'}>
              Forgot password <ArrowRight />
            </Body>
          </Link>
        </VSpaced>
      </Block>
    </>
  )
}
