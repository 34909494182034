import { useResource } from '@thesisedu/feature-react'
import { Button$, Dropdown } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { SimpleSegmentPresetResource } from '../types'

export interface CreateSegmentButtonProps extends Button$.ButtonProps {
  additionalItems?: React.ReactElement[]
  groupItems?: React.ReactElement[]
  linkRoot?: string
}
export function CreateSegmentButton({
  linkRoot,
  additionalItems,
  groupItems,
  ...props
}: CreateSegmentButtonProps) {
  const navigate = useNavigate()
  const presets = useResource<SimpleSegmentPresetResource>('SimpleSegmentPreset')
  const onClick = (key: string) => () => {
    const preset = presets.find(preset => preset.identifier === key)
    if (preset) {
      navigate(
        `${linkRoot || ''}create/${preset.segmentType.toLowerCase()}?preset=${preset.identifier}`,
      )
    }
  }
  return (
    <Dropdown.Container>
      <Dropdown.Button variant={'primary'} icon={<Plus />} children={'Create Content'} {...props} />
      <Dropdown.Menu align={'end'}>
        {additionalItems ? [...additionalItems, <Dropdown.Item.Separator key={'divider'} />] : null}
        {presets.map(preset => (
          <Dropdown.Item
            key={preset.identifier}
            icon={preset.icon}
            onClick={onClick(preset.identifier!)}
          >
            {preset.label}
          </Dropdown.Item>
        ))}
        {groupItems?.length ? (
          <>
            <Dropdown.Item.Separator />
            {groupItems}
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
