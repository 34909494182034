import React from 'react'

import { Root, RootProps } from './ToastParts'
import { UpdateOrCloseToast, useToast } from './useToast'
import { useStatePropFallback } from '../../utils/useStatePropFallback'

export interface ExternalRootProps extends RootProps {}

/**
 * If you want more control over how the toast is displayed, use this
 * component instead of the default (and easier) Toast component.
 *
 * Do note, if you use this component, you are responsible for showing /
 * hiding the toast. The default timeout functionality is only available
 * when you useToast() or use the Toast component.
 */
export function ExternalRoot(props: ExternalRootProps) {
  const create = useToast()
  const updateCallbackRef = React.useRef<UpdateOrCloseToast | null>(null)
  const [open, setOpen] = useStatePropFallback(props.open, props.onOpenChange, true)
  React.useEffect(() => {
    if (open && !updateCallbackRef.current) {
      updateCallbackRef.current = create({
        element: <Root {...props} open onOpenChange={setOpen} />,
      })
    } else if (open && updateCallbackRef.current) {
      updateCallbackRef.current({
        element: <Root {...props} open onOpenChange={setOpen} />,
      })
    } else if (updateCallbackRef.current) {
      updateCallbackRef.current()
      updateCallbackRef.current = null
    }
  }, [props, open])

  // Close the Toast when the component unmounts.
  React.useEffect(() => {
    return () => {
      if (updateCallbackRef.current) {
        updateCallbackRef.current()
      }
    }
  }, [])

  return null
}
