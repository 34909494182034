import React from 'react'

import { Cell } from './Cell'
import { Column, ColumnProps } from './Column'
import { Row } from './Row'
import { Table, TableProps } from './Table'
import { TableBody, TableBodyProps } from './TableBody'
import { TableHeader } from './TableHeader'

export interface ImperativeTableRecord {
  id: string
}
export interface ImperativeTableRenderCellProps<T extends ImperativeTableRecord> {
  item: T
}
export interface ImperativeTableColumn<T extends ImperativeTableRecord> extends ColumnProps {
  id: string | (keyof T extends string ? keyof T : never)
  /** Defaults to outputting the field, based on the id. */
  renderCell?: (props: ImperativeTableRenderCellProps<T>) => React.ReactNode
}

export interface ImperativeTableProps<T extends ImperativeTableRecord>
  extends Omit<TableProps, 'children' | 'style' | 'className'>,
    Omit<TableBodyProps<T>, 'children' | 'className' | 'style' | keyof TableProps> {
  columns: ImperativeTableColumn<T>[]
  items: T[]
  className?: string
  style?: React.CSSProperties
}
export function ImperativeTable<T extends ImperativeTableRecord>({
  columns,
  className,
  items,
  style,
  ...props
}: ImperativeTableProps<T>) {
  return (
    <Table {...props} style={style} className={className}>
      <TableHeader>
        {columns.map(column => {
          return <Column key={column.id} {...column} />
        })}
      </TableHeader>
      <TableBody<T> {...props} items={items}>
        {item => {
          return (
            <Row>
              {columns.map(column => {
                // We have to cast this because it returns React.ReactNode, and creating
                // it as an element below doesn't like that. This might bite us in the
                // future, but we'll take our chances.
                const RenderCell = column.renderCell as (
                  props: ImperativeTableRenderCellProps<T>,
                ) => React.ReactElement
                return (
                  <Cell key={column.id}>
                    {RenderCell ? (
                      <RenderCell item={item} />
                    ) : (
                      item[column.id as keyof T]?.toString()
                    )}
                  </Cell>
                )
              })}
            </Row>
          )
        }}
      </TableBody>
    </Table>
  )
}
