import { Format } from '@thesisedu/feature-utils'
import { styled, s, Text } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { PRESET_MIN_WIDTH_PX } from './CriterionPresetEditor'
import { HEADER_WIDTH_PX } from './RubricEditorCriterion'
import { useRubricGraderContext } from './RubricGraderContext'
import { RubricGraderPreset } from './RubricGraderPreset'
import { AssignmentRubricCategoryInput } from '../schema'

export interface RubricGraderCriterionProps {
  criterion: AssignmentRubricCategoryInput
  maxPresets: number
}
export function RubricGraderCriterion({ criterion, maxPresets }: RubricGraderCriterionProps) {
  const { value } = useRubricGraderContext(true)
  const grade = value?.grades?.[criterion.id]
  return (
    <Container
      data-testid={`RubricGraderCriterion ${criterion.name}`}
      style={{
        minWidth: (PRESET_MIN_WIDTH_PX + 1) * maxPresets + HEADER_WIDTH_PX + 1 /* Borders */,
      }}
    >
      <Header>
        <Text bottom level={'s'} weight={'bold'}>
          {criterion.name}
        </Text>
        {criterion.description ? <Text level={'xs'}>{criterion.description}</Text> : null}
        <PointsContainer>
          <IconContainer level={'xs'} color={'primary.secondary'}>
            {grade != null ? <Check /> : null}
          </IconContainer>
          <Text level={'xs'} color={grade != null ? 'primary.secondary' : 'secondary'}>
            {grade ? parseFloat((grade * criterion.totalPoints).toFixed(2)) + ' / ' : null}
            {Format.plural('point', criterion.totalPoints)}
          </Text>
        </PointsContainer>
      </Header>
      {criterion.presets?.map(preset => {
        return (
          <RubricGraderPreset
            key={preset.id}
            preset={preset}
            categoryId={criterion.id}
            totalPoints={criterion.totalPoints}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
  &:not(:last-child) {
    border-bottom: solid 1px ${s.color('border')};
  }
`
const Header = styled.div`
  background: ${s.color('subtle')};
  padding: ${s.size('xs')};
  padding-left: calc(${s.size('xs')} * 2 + ${s.size('xxs')});
  border-right: solid 1px ${s.color('border')};
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  width: ${HEADER_WIDTH_PX}px;
  flex-shrink: 0;
  z-index: 2;
`
const PointsContainer = styled.div`
  margin-left: calc(${s.size('xs')} * -2);
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  margin-top: auto;
  padding-top: ${s.size('xs')};
`
const IconContainer = styled(Text)`
  width: ${s.size('xs')};
`
