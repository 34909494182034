import { Radio } from 'antd'
import React from 'react'

import { CommentModerationFilters } from './types'
import { CommentApproval } from '../schema'

export interface CommentModerationFilterButtonsProps {
  filters?: CommentModerationFilters
  onChange?: (filters: CommentModerationFilters) => void
}
export function CommentModerationFilterButtons({
  filters,
  onChange,
}: CommentModerationFilterButtonsProps) {
  return (
    <>
      <Radio.Group
        value={filters?.approvals?.[0]}
        buttonStyle={'solid'}
        onChange={e => {
          if (onChange) {
            onChange({
              ...filters,
              approvals: [e.target.value as CommentApproval],
            })
          }
        }}
      >
        <Radio.Button value={CommentApproval.Unmoderated}>Unmoderated</Radio.Button>
        <Radio.Button value={CommentApproval.Approved}>Approved</Radio.Button>
        <Radio.Button value={CommentApproval.Rejected}>Rejected</Radio.Button>
      </Radio.Group>
    </>
  )
}
