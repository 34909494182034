import { useBulkActionsWithModal } from '@thesisedu/feature-apollo-react'
import { Dropdown, Modal } from '@thesisedu/ui'
import { Cpu } from '@thesisedu/ui/icons'
import React from 'react'

import { filterUniqueGroups } from './AssignGrade'
import { AssignmentBulkActionItemProps } from './AssignmentBulkActions'
import { refetchQueries } from './refetchQueries'
import {
  AutoGradeAssignmentSubmissionDocument,
  AutoGradeAssignmentSubmissionMutationVariables,
} from '../../schema'

export function AutoGrade({ selections, assignment }: AssignmentBulkActionItemProps) {
  const validSelections = selections.filter(s => !!s.submissionId)
  const [run, { modal }] = useBulkActionsWithModal()

  return (
    <>
      {modal}
      <Modal.Confirm
        title={'Are you sure?'}
        children={'Grades for automatically-gradeable questions will be overridden.'}
        confirm={{ children: 'Yes, re-grade', status: 'danger' }}
        onConfirm={async () => {
          const filteredSelections = filterUniqueGroups(selections).filter(
            selection => selection.submissionId,
          )
          await run<AutoGradeAssignmentSubmissionMutationVariables>({
            message: 'Auto-grading submissions',
            mutation: AutoGradeAssignmentSubmissionDocument,
            refetchQueries: refetchQueries(assignment.id),
            variables: filteredSelections.map(selection => ({
              input: {
                id: selection.submissionId!,
              },
            })),
          })
        }}
        trigger={
          <Dropdown.Item
            icon={<Cpu />}
            disabled={!validSelections.length || !assignment.canGrade}
            children={'Auto-Grade Again'}
          />
        }
      />
    </>
  )
}
