import { ApolloCache, Reference, DocumentNode, MutationUpdaterFunction } from '@apollo/client'
import { get } from 'lodash'

import { debug } from './log'

interface Node {
  __typename?: string
  id?: string
}

export interface UpdateMutationOpts {
  fragment: DocumentNode
  fragmentName?: string
  path: string
}

export function updateMutation<Data, Fragment extends Node>({
  fragment,
  fragmentName,
  path,
}: UpdateMutationOpts): MutationUpdaterFunction<Data, any, any, ApolloCache<any>> {
  return (proxy, mutationResult) => {
    const resultItem = get(mutationResult, `data.${path}`) as Fragment | undefined
    if (!resultItem) return
    const typename = resultItem.__typename
    const id = resultItem.id
    if (!typename || !id) return
    let fragmentResult: Fragment | null = null
    try {
      fragmentResult = proxy.readFragment<Fragment>({
        id: `${typename}:${id}`,
        fragment,
        fragmentName,
      })
    } catch {
      // This probably means the fragment does not yet exist... Let's do nothing.
      debug('fragment result failed for %s fragment - not updating', fragmentName)
    }
    if (fragmentResult) {
      proxy.writeFragment<Fragment>({
        id: `${typename}:${id}`,
        fragment,
        fragmentName,
        data: {
          ...fragmentResult,
          ...resultItem,
        },
      })
    }
  }
}

export interface AddEdgeOpts {
  ref?: Reference
  field: string
  id: string
}
export function addEdge(proxy: ApolloCache<any>, { ref, field, id }: AddEdgeOpts) {
  if (ref) {
    proxy.modify({
      id,
      fields: {
        [field]: existingValues => {
          if (!existingValues.edges?.some((edge: any) => edge.node === ref)) {
            return {
              ...existingValues,
              edges: [
                ...existingValues.edges,
                {
                  node: ref,
                },
              ],
            }
          } else return existingValues
        },
      },
    })
  }
}

export interface RemoveEdgeOpts {
  field: string
  id: string
  removeId: string
}
export function removeEdge(proxy: ApolloCache<any>, { field, id, removeId }: RemoveEdgeOpts) {
  proxy.modify({
    id,
    fields: {
      [field]: (existingValues, { readField }) => {
        return {
          ...existingValues,
          edges: existingValues.edges.filter((edge: any) => {
            return removeId !== readField('id', edge.node)
          }),
        }
      },
    },
  })
}
