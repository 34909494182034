import { onMutationError } from '@thesisedu/feature-apollo-react'
import { s } from '@thesisedu/ui'
import {
  Block,
  BlockSpin,
  DiscreteLoadingIndicator,
  HSpaced,
  NotFound,
  PageHead,
  PageHeader,
  styled,
  useBreadcrumb,
} from '@thesisedu/web'
import { Button, Form } from 'antd'
import React from 'react'
import { useParams, Prompt } from 'react-router-dom'

import { CourseEditorProvider } from '../../contexts/CourseEditorContext'
import { SegmentType, useBuiltSegmentQuery, useCourseVersionQuery } from '../../schema'
import { Segment } from '../../segment/Segment'
import { getOnSegmentChange } from '../onSegmentChange'
import { useCreateOrUpdateSegmentMutation } from '../useCreateOrUpdateSegmentMutation'

export interface EditSegmentPageProps {
  segmentId: string
  courseVersionId: string
}
export function EditSegmentPage({ segmentId, courseVersionId }: EditSegmentPageProps) {
  const [isDirty, setIsDirty] = React.useState(false)
  const courseVersionData = useCourseVersionQuery({
    variables: { id: courseVersionId },
    fetchPolicy: 'cache-and-network',
  })
  const { data, loading } = useBuiltSegmentQuery({
    variables: { id: segmentId },
    fetchPolicy: 'network-only',
  })
  const [createOrUpdate, { loading: saving }] = useCreateOrUpdateSegmentMutation(
    {
      onError: onMutationError('There was an error saving your changes. Please refresh the page.'),
      onCompleted: () => {
        setIsDirty(false)
      },
    },
    courseVersionId,
  )
  const onChange = getOnSegmentChange(courseVersionId, createOrUpdate)
  const courseVersion =
    courseVersionData.data?.node?.__typename === 'CourseVersion'
      ? courseVersionData.data.node
      : undefined
  const segment = data?.node?.__typename === 'Segment' ? data.node : undefined
  const [form] = Form.useForm()
  useBreadcrumb(
    [
      ...(courseVersionId
        ? [
            {
              title: courseVersion?.name || '...',
              relativeHref: '..',
            },
          ]
        : []),
      {
        title: segment?.name || '...',
      },
    ],
    `${segment?.id}-${courseVersion?.id}`,
  )

  if (loading || courseVersionData.loading) {
    return <BlockSpin />
  } else if (segment?.built && courseVersion?.configuration) {
    return (
      <>
        <Prompt
          when={isDirty}
          message={'You have pending changes. If you leave the page, you will lose those changes.'}
        />
        <PageHead title={`${segment.name} - Course Administration`} />
        <CourseEditorProvider
          status={{
            enableReferences: true,
          }}
          read={{
            courseVersionId,
            courseConfiguration: courseVersion.configuration,
            rootSegment: {
              id: courseVersion.rootSegmentId,
              name: `${courseVersion.name} - Root`,
              type: SegmentType.Root,
              config: {},
              childSegments: courseVersion.configuration.segments,
            },
          }}
        >
          <>
            <HeaderContainer data-sticky={'EditSegmentPage'}>
              <PageHeader title={segment.name}>
                <HSpaced>
                  <DiscreteLoadingIndicator loading={saving} />
                  <Button
                    type={'primary'}
                    disabled={!isDirty}
                    onClick={async e => {
                      e.preventDefault()
                      form.submit()
                    }}
                  >
                    Save Changes
                  </Button>
                </HSpaced>
              </PageHeader>
            </HeaderContainer>
            <Block marginTop={'@size-l'}>
              <Segment
                useStandaloneEditing
                form={form}
                segment={segment.built}
                onDirty={() => {
                  setIsDirty(true)
                }}
                onChange={(originalSegment, values) => {
                  return onChange({ ...originalSegment, ...values })
                }}
              />
            </Block>
          </>
        </CourseEditorProvider>
      </>
    )
  } else {
    return <NotFound description={'That segment could not be found.'} />
  }
}

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  background: ${props => props.theme['@gray-0']};
  display: flex;
  align-items: center;
  height: 100px !important;
  z-index: ${s.var('zIndices.stickyHeaders')};
  > div {
    width: 100%;
  }
`

export function EditSegmentRoute() {
  const { segmentId, courseVersionId } = useParams()
  return <EditSegmentPage segmentId={segmentId} courseVersionId={courseVersionId} />
}
