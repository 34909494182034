import { AssignmentGraderResource, Question } from '@thesisedu/feature-assignments-core'
import { useResource } from '@thesisedu/feature-react'

import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { useSubmissionContext } from '../contexts/SubmissionContext'

export function useGradeQuestionCallback() {
  const graderResources = useResource<AssignmentGraderResource>('AssignmentGrader')
  const { realTimeSubmissionData: submissionData = {} } = useSubmissionContext(false) || {}
  const { configuration, templateId } = useAssignmentViewerContext(false) || {}
  return (question: Question): boolean | null => {
    const resource = graderResources.find(r => r.identifier === question.type)
    if (submissionData[question.id] === null || submissionData[question.id] === undefined)
      return false
    if (
      resource &&
      (!resource.canGradeQuestion ||
        resource.canGradeQuestion(submissionData[question.id], question, templateId))
    ) {
      return (
        resource.grader(
          submissionData[question.id],
          question,
          configuration || { questions: [question] },
          templateId,
        ) === 1
      )
    } else {
      return null
    }
  }
}
