import { Format } from '@thesisedu/feature-utils'
import { BodySmall, FontWeight, styled } from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'

import { CommentsViewer } from './CommentsViewer'
import { GradeTreeRecord, isAssignment } from './translateGradeTree'
import { Grade } from '../Grade'

const hasComments = (comments?: any) => {
  return comments && Object.keys(comments).some(commentKey => comments[commentKey])
}

export function GradeColumnGrade({ record }: GradeColumnProps) {
  if (record.grade === null || record.grade === undefined) return null
  return (
    <span className={'grade'}>
      <Grade hideProgress grade={record.grade} />
      {record.totalPoints !== undefined &&
      record.totalPoints !== null &&
      record.points !== null &&
      record.points !== undefined ? (
        <BodySmall
          color={'@text-color-secondary'}
          style={{ marginLeft: '0.5em' }}
          weight={FontWeight.Normal}
        >
          {parseFloat(record.points.toFixed(2))} /{' '}
          {pluralize('point', parseFloat(record.totalPoints.toFixed(2)), true)}
        </BodySmall>
      ) : null}
      {isAssignment(record) &&
      hasComments(record.submissions[0]?.comments) &&
      record.submissions[0]?.id ? (
        <CommentsViewer submissionId={record.submissions[0].id} />
      ) : null}
    </span>
  )
}

export interface GradeColumnProps {
  record: GradeTreeRecord
}
export function GradeColumn({ record }: GradeColumnProps) {
  if (record.grade === null || record.grade === undefined) return null
  return (
    <Container className={!isAssignment(record) && record.children ? 'has-children' : ''}>
      <GradeColumnGrade record={record} />
      {!isAssignment(record) && record.weight ? (
        <BodySmall color={'@text-color-secondary'} className={'weight'}>
          {Format.number(record.weight, 'decimalPercentage')} of your grade
        </BodySmall>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  &.has-children > .grade {
    font-size: 125%;
    font-weight: bold;
  }
  > .grade {
    display: flex;
    align-items: center;
    > div {
      padding: 0;
    }
    svg {
      line-height: 1;
      margin: 5px 0 0 5px;
    }
  }
  > .weight {
    margin-top: 3px;
    font-size: 75%;
  }
  p {
    display: inline;
  }
`
