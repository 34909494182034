import { Shell } from '@thesisedu/feature-web'
import React from 'react'

import { UsersReactFeature } from '../UsersReactFeature'
import { UserMenu } from '../web/UserMenu'

export default function (feature: UsersReactFeature) {
  feature.hookManager.registerMutateHook<Shell.TopRightItemsHook>(
    'feature-web:shell-top-right-items',
    items => {
      return [
        ...items,
        {
          weight: 1,
          element: <UserMenu />,
        },
      ]
    },
  )
}
