import { CheckCircleFilled } from '@ant-design/icons'
import { H3Alternate, TutorialPopover, Color, Body, Block } from '@thesisedu/web'
import React from 'react'

import { EnableSwitch } from './SegmentEnable'

const HIDE_ENABLE_TUTORIAL_FLAG = 'courses-react:hide-enable-tutorial'

export interface EnableTutorialPopoverProps {
  children: React.ReactElement
}
export function EnableTutorialPopover({ children }: EnableTutorialPopoverProps) {
  return (
    <TutorialPopover
      tutorialKey={HIDE_ENABLE_TUTORIAL_FLAG}
      autoShow
      content={
        <>
          <H3Alternate>
            <Color color={'@primary-color'}>
              <CheckCircleFilled />
            </Color>{' '}
            Enabling Content
          </H3Alternate>
          <Block marginTop={'@size-s'}>
            <Body isBlock>Your first step is to enable some content.</Body>
            <Body isBlock>
              <EnableSwitch
                checked
                size={'small'}
                style={{ pointerEvents: 'none', marginBottom: 3 }}
              />{' '}
              <strong>If content is enabled,</strong> it is visible to students, appears in the
              gradebook (and counts toward student grades), and appears in reports.
            </Body>
            <Body isBlock>
              <EnableSwitch size={'small'} style={{ pointerEvents: 'none', marginBottom: 3 }} />{' '}
              <strong>If content is disabled,</strong> it is not visible to students, does not
              appear in the gradebook (and does not count toward student grades), and does not
              appear in reports.
            </Body>
          </Block>
        </>
      }
      children={children}
    />
  )
}
