import { IssuesCloseOutlined } from '@ant-design/icons'
import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { GradesBreakdown } from './GradesBreakdown'
import { ReportItem, ReportItemProps, GradingModalReportItemsHook } from '../types'

export function useAssignmentReports(props: ReportItemProps) {
  const reports: ReportItem[] = []
  if (props.assignment.rubric) {
    reports.push({
      key: 'grades-by-rubric',
      label: 'Grades Breakdown',
      icon: <IssuesCloseOutlined />,
      component: GradesBreakdown,
    })
  }

  return useMutateHook<GradingModalReportItemsHook>(
    'feature-assignments-react:grading-modal-report-items',
    reports,
    { props },
  )
}
