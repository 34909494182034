import { ReactProvider as CoreReactProvider } from '@thesisedu/react'
import { s, UIProvider } from '@thesisedu/ui'
import React from 'react'

import { FeatureReactRoot } from '../FeatureReactRoot'
import { IDsProvider } from '../IDsContext'

export interface ReactContextValue {
  root: FeatureReactRoot<any>
}
export const ReactContext = React.createContext<ReactContextValue | undefined>(undefined)

export interface ReactProviderProps {
  root: FeatureReactRoot<any>
  children: React.ReactElement
  theme?: s.Theme
}
export function ReactProvider({ root, children, theme }: ReactProviderProps) {
  return (
    <ReactContext.Provider value={{ root }}>
      <IDsProvider>
        <CoreReactProvider>
          <UIProvider theme={theme}>{children}</UIProvider>
        </CoreReactProvider>
      </IDsProvider>
    </ReactContext.Provider>
  )
}

export function useReactContext(): ReactContextValue | undefined
export function useReactContext(require: false): ReactContextValue | undefined
export function useReactContext(require: true): ReactContextValue
export function useReactContext(require?: boolean): ReactContextValue | undefined {
  const context = React.useContext(ReactContext)
  if (!context && require) {
    throw new Error('ReactContext is required, yet not provided.')
  }
  return context
}
