import { $generateJSONFromSelectedNodes } from '@lexical/clipboard'
import { onMutationError } from '@thesisedu/feature-apollo-react'
import {
  $createNodeSelection,
  ElementNode,
  LexicalEditor,
  useSettingsDropdownItem,
  walk,
} from '@thesisedu/feature-widgets-react'
import { Dropdown, Form, Modal } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import { notification } from 'antd'
import React from 'react'

import { useCreateWidgetTemplateMutation } from '../../../schema'

export function useSaveAsTemplate() {
  const [visible, setVisible] = React.useState(false)
  const editorRef = React.useRef<LexicalEditor | null>(null)
  const nodeRef = React.useRef<ElementNode | null>(null)

  useSettingsDropdownItem({
    identifier: 'SaveAsTemplate',
    weight: 10,
    element({ editor, node }) {
      editorRef.current = editor
      nodeRef.current = node
      return (
        <Dropdown.Item onClick={() => setVisible(true)} icon={<Copy />}>
          Save as Template
        </Dropdown.Item>
      )
    },
  })

  return (
    <SaveAsTemplateModal
      visible={visible}
      onVisibleChange={setVisible}
      editorRef={editorRef}
      nodeRef={nodeRef}
    />
  )
}

interface SaveAsTemplateModalProps {
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
  editorRef: React.RefObject<LexicalEditor | null>
  nodeRef: React.RefObject<ElementNode | null>
}
function SaveAsTemplateModal({
  editorRef,
  nodeRef,
  visible,
  onVisibleChange,
}: SaveAsTemplateModalProps) {
  const [create, { loading }] = useCreateWidgetTemplateMutation({
    onError: onMutationError('There was an error saving your widget as a template.'),
    onCompleted: data => {
      onVisibleChange(false)
      notification.success({
        message: 'Template Created Successfully',
        description: (
          <span>
            Click{' '}
            <a href={`/admin/templates/${data.createWidgetTemplate.widgetTemplate.id}`}>here</a> to
            view it.
          </span>
        ),
        duration: 5,
      })
    },
  })
  const form = Form.useForm<{ name: string }>()
  React.useEffect(() => {
    if (visible) {
      form.reset()
    }
  }, [visible])

  return (
    <Form.Modal
      form={form}
      title={'Save as Template'}
      visible={visible}
      onVisibleChange={onVisibleChange}
      onFinish={async values => {
        const editor = editorRef.current
        const node = nodeRef.current
        if (editor && node) {
          await new Promise<void>(resolve => {
            editor.update(async () => {
              const selection = $createNodeSelection()
              // We need to add all children keys to the selection, or they won't be included
              // in the template.
              walk([node], node => {
                selection.add(node.getKey())
              })
              const widgets = $generateJSONFromSelectedNodes(editor, selection).nodes
              await create({
                variables: {
                  input: {
                    name: values.name,
                    widgets,
                  },
                },
              })
              resolve()
            })
          })
        }
      }}
    >
      <Form.TextField name={'name'} label={'Name'} placeholder={'My Template'} required />
      <Modal.Footer>
        <Modal.CloseButton children={'Cancel'} />
        <Form.Submit children={'Save as Template'} loading={loading} />
      </Modal.Footer>
    </Form.Modal>
  )
}
