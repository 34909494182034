import { addTeacherPermission } from '@thesisedu/feature-classes-react'

import { ClassSyncReactFeature } from '../../ClassSyncReactFeature'

export default function (feature: ClassSyncReactFeature) {
  addTeacherPermission(feature, 'Classes', 'Manage class settings', [
    'ATTACH_CLASS_SYNC',
    'VIEW_CLASS_SYNC',
    'LIST_CLASS_SYNC_STUDENTS',
  ])
  addTeacherPermission(feature, 'Classes', 'Invite new students to this class', [
    'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
    'VIEW_CLASS_SYNC',
    'LIST_CLASS_SYNC_STUDENTS',
    'ROSTER_STUDENTS_FROM_CLASS_SYNC',
  ])
}
