import { Avatar, AvatarProps, Image } from '@thesisedu/react'
import React from 'react'

import { UserWithAvatarFragment } from '../schema'

export interface UserAvatarProps extends Partial<AvatarProps> {
  user: Pick<UserWithAvatarFragment, 'name' | 'username'> &
    Partial<Pick<UserWithAvatarFragment, 'avatar'>>
}
export function UserAvatar({ user, ...avatarProps }: UserAvatarProps) {
  const name = user.name || user.username
  return (
    <Avatar
      size={32}
      src={
        user.avatar?.url?.signedUrl ? <Image preview src={user.avatar.url.signedUrl} /> : undefined
      }
      {...avatarProps}
    >
      {name
        .split(' ')
        .slice(0, 2)
        .map(i => i.slice(0, 1))
        .join('')
        .toUpperCase()}
    </Avatar>
  )
}
