import { isVisibleToStudent } from '@thesisedu/feature-course-types'

import { Segment } from '../types'

export const getTermHasStudentContent = (term: Segment | undefined): boolean => {
  return !!term?.childSegments?.find(
    child =>
      isVisibleToStudent(child) ||
      child.childSegments?.some(
        child => isVisibleToStudent(child) || getTermHasStudentContent(child),
      ),
  )
}
