import { useFlag } from '@thesisedu/react'
import React from 'react'

import { CampaignBanner } from './CampaignBanner'
import { getCampaignDismissedFlag, hasWelcomeMessage } from './helpers'
import { useViewerCampaignQuery } from '../schema'

export function LoadCampaignBanner() {
  const campaign = useCampaignBanner()
  if (campaign && hasWelcomeMessage(campaign)) {
    return <CampaignBanner campaign={campaign.identifier} {...campaign.welcomeMessage} />
  } else return null
}

export function useCampaignBanner() {
  const { data } = useViewerCampaignQuery()
  const campaign = data?.viewer?.teacher?.site?.license?.trialRequest?.trialRequestCampaign
  const [flagValue] = useFlag(getCampaignDismissedFlag(campaign?.id ?? 'no-campaign'), false)
  return campaign && hasWelcomeMessage(campaign) && !flagValue ? campaign : null
}
