import { useResource } from '@thesisedu/feature-react'
import { VSpaced } from '@thesisedu/web'
import React from 'react'

import { ClassTaskSummaryProviderResource } from '../types'

export interface ClassTaskSummaryProps {
  classId: string
}
export function ClassTaskSummary({ classId }: ClassTaskSummaryProps) {
  const resources = useResource<ClassTaskSummaryProviderResource>('ClassTaskSummaryProvider')
  return (
    <VSpaced align={'stretch'} space={'@size-s'}>
      {resources.map(resource => (
        <resource.provider key={resource.identifier} classId={classId} />
      ))}
    </VSpaced>
  )
}
