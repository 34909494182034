import { useBreadcrumb } from '@thesisedu/web'
import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { ClassRoute } from './ClassRoute'
import { ClassesRoot } from './ClassesRoot'
import { Create } from './Create'

export function Classes() {
  useBreadcrumb({ title: 'Classes' })
  return (
    <Routes>
      <Route path={'/create/*'} element={<Create />} />
      <Route path={'/:classId/*'} element={<ClassRoute group={'TEACHER'} />} />
      <Route path={'/'} element={<ClassesRoot group={'TEACHER'} />} />
    </Routes>
  )
}
