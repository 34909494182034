import React from 'react'

export interface WindowContextValue {
  window?: Window
}
export const WindowContext = React.createContext<WindowContextValue | undefined>(undefined)

export interface WindowProviderProps {
  window?: Window
}
export function WindowProvider({ window, children }: React.PropsWithChildren<WindowProviderProps>) {
  return <WindowContext.Provider value={{ window }} children={children} />
}

export function useWindowContext(): WindowContextValue | undefined
export function useWindowContext(require: false): WindowContextValue | undefined
export function useWindowContext(require: true): WindowContextValue
export function useWindowContext(require?: boolean): WindowContextValue | undefined {
  const context = React.useContext(WindowContext)
  if (!context && require) {
    throw new Error('WindowContext is required, yet not provided.')
  }
  return context
}
