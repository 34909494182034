import { useViewerContext } from '@thesisedu/feature-users-react'
import { Cpu } from '@thesisedu/react/icons'
import { Body, H2, styled } from '@thesisedu/web'
import React from 'react'

const Container = styled.div`
  margin: ${props => props.theme['@size-xl']} auto;
  text-align: center;
  max-width: 500px;
`

export function CalculatedWarning() {
  const isAdmin = useViewerContext(false)?.group === 'ADMIN'
  return (
    <Container>
      <H2>
        <Cpu /> {isAdmin ? 'Calculated' : 'Quiz Vault'} Assignment
      </H2>
      <Body>
        This assignment is automatically calculated using the <strong>Quiz Vault.</strong>{' '}
        {isAdmin ? (
          <>
            If you would like to add more questions to this assignment and not deliver them to the
            teacher throughout the semester, create a new Assignment segment in the
            standard-relevant location, and mark it as hidden.
          </>
        ) : (
          <>
            To configure the questions inside this assignment, you need to save your changes here
            and add this assignment to one of your classes.
          </>
        )}
      </Body>
    </Container>
  )
}
