import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { Empty } from '@thesisedu/react'
import { getGridItemHeight, Grid } from '@thesisedu/react/dist/grid'
import React from 'react'

import { AssignmentSubmissionGridItem } from './AssignmentSubmissionGridItem'
import {
  CREATE_SUBMISSION_GRID_ITEM,
  CreateSubmissionGridItem,
  isCreateSubmissionGridItem,
  TCreateSubmissionGridItem,
} from './CreateSubmissionGridItem'
import { _useInfiniteQueryProps } from './submissionsList'
import { SubmissionWithMediaFragment } from './types'
import { GridAssignment } from '../grid'
import {
  AssignmentSubmissionsQueryVariables,
  AssignmentSubmissionsQuery,
  BasicAssignmentSubmissionFragment,
} from '../schema'

export type SubmissionsGridAssignment = Omit<GridAssignment, 'students'>
export interface AssignmentSubmissionsGridProps {
  assignment: SubmissionsGridAssignment
  classId: string
  onClick: (submission: SubmissionWithMediaFragment) => void
  onCreateClick?: () => void
}
export function AssignmentSubmissionsGrid(props: AssignmentSubmissionsGridProps) {
  const { assignment, onClick, onCreateClick } = props
  const [createLoading, setCreateLoading] = React.useState(false)
  return (
    <InfiniteQuery<
      BasicAssignmentSubmissionFragment,
      AssignmentSubmissionsQuery,
      AssignmentSubmissionsQueryVariables
    >
      {..._useInfiniteQueryProps(props)}
      children={({ data, refetch }) => {
        const submissions =
          data?.node?.__typename === 'Assignment'
            ? data.node.currentSubmissions.edges.map(edge => edge.node)
            : []
        const remainingAttempts =
          data?.node?.__typename === 'Assignment' ? data.node.remainingAttempts : undefined
        if (submissions.length || onCreateClick) {
          return (
            <Grid<SubmissionWithMediaFragment | TCreateSubmissionGridItem>
              items={onCreateClick ? [...submissions, CREATE_SUBMISSION_GRID_ITEM] : submissions}
              itemHeight={itemWidth => getGridItemHeight(itemWidth)}
              onClick={async item => {
                if (isCreateSubmissionGridItem(item)) {
                  if (onCreateClick && remainingAttempts && remainingAttempts > 0) {
                    setCreateLoading(true)
                    try {
                      onCreateClick()
                    } finally {
                      setCreateLoading(false)
                    }
                  }
                } else {
                  onClick(item)
                }
              }}
              renderItem={({ item }) => {
                if (isCreateSubmissionGridItem(item)) {
                  return (
                    <CreateSubmissionGridItem
                      loading={createLoading}
                      remainingAttempts={remainingAttempts ?? 0}
                    />
                  )
                } else {
                  const index = submissions.indexOf(item)
                  return (
                    <AssignmentSubmissionGridItem
                      assignment={assignment}
                      item={item}
                      index={index + 1}
                      onUpdate={() => {
                        refetch()
                      }}
                    />
                  )
                }
              }}
            />
          )
        } else {
          return <Empty description={'No submissions yet!'} />
        }
      }}
    />
  )
}
