import React from 'react'
import { useDrop } from 'react-dnd'

import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { DraggableChild, MultiDraggableView } from './MultiDraggableView'
import { DropArea } from './styled'
import { DragItem, DragItemType, DropArea as TDropArea, DropItem } from './types'

export interface DropAreaViewProps {
  dropArea: TDropArea
  draggables?: DraggableChild[]
}
export function DropAreaView({ dropArea, draggables }: DropAreaViewProps) {
  const { width = 0, height = 0 } = useGraphicDragDropContext(true)
  const [{ opacity }, dropRef] = useDrop<DragItem, DropItem, { opacity: number }>(
    () => ({
      accept: DragItemType.Image,
      drop: () => {
        return { id: dropArea.id }
      },
      collect: monitor => {
        const item = monitor.getItem()
        return {
          opacity: item ? (monitor.isOver() ? 1 : 0.25) : 0,
        }
      },
    }),
    [],
  )

  return (
    <DropArea
      ref={dropRef}
      style={{
        width: dropArea.width * width,
        height: dropArea.height * height,
        left: dropArea.x * width,
        top: dropArea.y * height,
        position: 'absolute',
        opacity: draggables?.length ? 1 : opacity,
      }}
      className={draggables?.length && opacity === 0 ? 'has-children' : ''}
    >
      {draggables ? (
        <MultiDraggableView
          draggables={draggables}
          width={dropArea.width}
          height={dropArea.height}
        />
      ) : null}
    </DropArea>
  )
}
