import { SelectButtonChildProps } from '@thesisedu/feature-apollo-react'
import {
  Block,
  Body,
  ClickableContainer,
  getColor,
  getSize,
  HSpaced,
  styledContainer as styled,
  styled as coreStyled,
  isNative,
  AntIconWrapper,
} from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

export function TagFieldChild({
  hasValue,
  disabled,
  itemNames,
  label,
  onClick,
}: SelectButtonChildProps) {
  const ClickContainer = isNative ? ClickableContainer : WebClickContainer
  return (
    <ClickContainer
      onClick={onClick}
      style={
        disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : undefined
      }
    >
      <HSpaced align={'center'}>
        <Block>
          <Body color={hasValue ? undefined : '@text-color-secondary'}>
            {hasValue && itemNames ? itemNames.join(', ') : label}
          </Body>
        </Block>
        <IconContainer>
          <Body useDiv style={{ lineHeight: 1 }}>
            <AntIconWrapper children={<Plus color={'white'} />} />
          </Body>
        </IconContainer>
      </HSpaced>
    </ClickContainer>
  )
}

const WebClickContainer = coreStyled(ClickableContainer)`
  background: ${props => getColor(props.theme, '@gray-1')};
  padding: ${props => getSize(props.theme, '@size-s')};
  border-radius: ${props => getSize(props.theme, '@border-radius-base')};
`
const IconContainer = styled`
  margin-left: auto;
  width: ${props => getSize(props.theme, '@size-m')};
  height: ${props => getSize(props.theme, '@size-m')};
  border-radius: 200px;
  background: ${props => getColor(props.theme, '@text-color-secondary')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`
