import { _themePathToVariable } from './themePathToVariableName'
import { Theme } from './types'

const color: Theme['color'] = {
  light: {
    gray: [
      'hsl(218, 15%, 100%)',
      'hsl(218, 15%, 99%)',
      'hsl(218, 15%, 96%)',
      'hsl(218, 13%, 94%)',
      'hsl(218, 11%, 92%)',
      'hsl(218, 9%, 90%)',
      'hsl(218, 8%, 58%)',
      'hsl(218, 6%, 43%)',
      'hsl(218, 15%, 29%)',
      'hsl(218, 15%, 25%)',
      'hsl(218, 15%, 22%)',
      'hsl(218, 15%, 10%)',
    ],
    blue: [
      'hsl(218, 67%, 100%)',
      'hsl(218, 67%, 99%)',
      'hsl(218, 67%, 97%)',
      'hsl(218, 59%, 93%)',
      'hsl(218, 50%, 87%)',
      'hsl(218, 42%, 79%)',
      'hsl(218, 34%, 70%)',
      'hsl(218, 25%, 59%)',
      'hsl(218, 67%, 47%)',
      'hsl(218, 67%, 43%)',
      'hsl(218, 67%, 31%)',
      'hsl(218, 67%, 10%)',
    ],
    green: [
      'hsl(157, 52%, 100%)',
      'hsl(157, 52%, 99%)',
      'hsl(157, 52%, 96%)',
      'hsl(157, 46%, 91%)',
      'hsl(157, 39%, 84%)',
      'hsl(157, 33%, 74%)',
      'hsl(157, 26%, 63%)',
      'hsl(157, 20%, 49%)',
      'hsl(157, 52%, 34%)',
      'hsl(157, 52%, 31%)',
      'hsl(157, 52%, 23%)',
      'hsl(157, 52%, 10%)',
    ],
    orange: [
      'hsl(43, 125%, 100%)',
      'hsl(43, 113%, 99%)',
      'hsl(43, 100%, 97%)',
      'hsl(43, 100%, 93%)',
      'hsl(43, 100%, 88%)',
      'hsl(43, 100%, 80%)',
      'hsl(43, 100%, 72%)',
      'hsl(43, 100%, 62%)',
      'hsl(43, 100%, 50%)',
      'hsl(43, 100%, 46%)',
      'hsl(43, 100%, 32%)',
      'hsl(43, 100%, 10%)',
    ],
    red: [
      'hsl(360, 70%, 100%)',
      'hsl(360, 70%, 99%)',
      'hsl(360, 70%, 97%)',
      'hsl(360, 61%, 93%)',
      'hsl(360, 53%, 87%)',
      'hsl(360, 44%, 80%)',
      'hsl(360, 35%, 71%)',
      'hsl(360, 26%, 61%)',
      'hsl(360, 70%, 49%)',
      'hsl(360, 70%, 45%)',
      'hsl(360, 70%, 32%)',
      'hsl(360, 70%, 10%)',
    ],
  },
  dark: {
    gray: [
      'hsl(218, 15%, 0%)',
      'hsl(218, 15%, 10%)',
      'hsl(218, 15%, 16%)',
      'hsl(218, 15%, 24%)',
      'hsl(218, 15%, 38%)',
      'hsl(218, 15%, 40%)',
      'hsl(218, 15%, 50%)',
      'hsl(218, 15%, 60%)',
      'hsl(218, 15%, 70%)',
      'hsl(218, 10%, 85%)',
      'hsl(218, 5%, 95%)',
      'hsl(218, 0%, 100%)',
    ],
    blue: [
      'hsl(218, 67%, 0%)',
      'hsl(218, 67%, 1%)',
      'hsl(218, 67%, 3%)',
      'hsl(218, 67%, 7%)',
      'hsl(218, 67%, 12%)',
      'hsl(218, 67%, 18%)',
      'hsl(218, 67%, 26%)',
      'hsl(218, 67%, 36%)',
      'hsl(218, 67%, 47%)',
      'hsl(218, 67%, 65%)',
      'hsl(218, 67%, 82%)',
      'hsl(218, 67%, 100%)',
    ],
    green: [
      'hsl(157, 52%, 0%)',
      'hsl(157, 52%, 1%)',
      'hsl(157, 52%, 2%)',
      'hsl(157, 52%, 5%)',
      'hsl(157, 52%, 9%)',
      'hsl(157, 52%, 13%)',
      'hsl(157, 52%, 19%)',
      'hsl(157, 52%, 26%)',
      'hsl(157, 52%, 34%)',
      'hsl(157, 52%, 56%)',
      'hsl(157, 52%, 78%)',
      'hsl(157, 52%, 100%)',
    ],
    orange: [
      'hsl(43, 100%, 0%)',
      'hsl(43, 100%, 1%)',
      'hsl(43, 100%, 3%)',
      'hsl(43, 100%, 7%)',
      'hsl(43, 100%, 13%)',
      'hsl(43, 100%, 20%)',
      'hsl(43, 100%, 28%)',
      'hsl(43, 100%, 38%)',
      'hsl(43, 100%, 50%)',
      'hsl(43, 100%, 67%)',
      'hsl(43, 100%, 83%)',
      'hsl(43, 100%, 100%)',
    ],
    red: [
      'hsl(360, 70%, 0%)',
      'hsl(360, 70%, 1%)',
      'hsl(360, 70%, 3%)',
      'hsl(360, 70%, 7%)',
      'hsl(360, 70%, 12%)',
      'hsl(360, 70%, 19%)',
      'hsl(360, 70%, 28%)',
      'hsl(360, 70%, 38%)',
      'hsl(360, 70%, 49%)',
      'hsl(360, 70%, 66%)',
      'hsl(360, 70%, 83%)',
      'hsl(360, 70%, 100%)',
    ],
  },
  overlayBlack: [
    'hsla(0, 0%, 0%, 0.012)',
    'hsla(0, 0%, 0%, 0.027)',
    'hsla(0, 0%, 0%, 0.047)',
    'hsla(0, 0%, 0%, 0.071)',
    'hsla(0, 0%, 0%, 0.090)',
    'hsla(0, 0%, 0%, 0.114)',
    'hsla(0, 0%, 0%, 0.141)',
    'hsla(0, 0%, 0%, 0.220)',
    'hsla(0, 0%, 0%, 0.439)',
    'hsla(0, 0%, 0%, 0.478)',
    'hsla(0, 0%, 0%, 0.565)',
    'hsla(0, 0%, 0%, 0.910)',
  ],
  overlayWhite: [
    'hsla(0, 0%, 100%, 0)',
    'hsla(0, 0%, 100%, 0.013)',
    'hsla(0, 0%, 100%, 0.034)',
    'hsla(0, 0%, 100%, 0.056)',
    'hsla(0, 0%, 100%, 0.086)',
    'hsla(0, 0%, 100%, 0.124)',
    'hsla(0, 0%, 100%, 0.176)',
    'hsla(0, 0%, 100%, 0.249)',
    'hsla(0, 0%, 100%, 0.386)',
    'hsla(0, 0%, 100%, 0.446)',
    'hsla(0, 0%, 100%, 0.592)',
    'hsla(0, 0%, 100%, 0.923)',
  ],
  special: {
    gradientSecondary: '#71B3F3',
  },
}

const size: Theme['size'] = {
  0: {
    1: 2,
    25: 4, // @size-xxs
    5: 8, // @size-xs
    75: 12, // @font-size-xs
    9: 14, // @font-size-sm
  },
  1: 16, // @font-size-base, @size-s
  2: 18, // @font-size-lg
  3: 21, // @font-size-larger
  4: 24, // @size-m
  5: 28,
  6: 32, // @heading-2-size, @size-l
  7: 48, // @heading-1-size
  8: 64,
}

export const DEFAULT_ALIASES: Theme['aliases'] = {
  colors: {
    primary: 'blue',
    gray: 'gray',
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    red: 'red',
  },
  colorScales: {
    background: 0,
    subtle: 1,
    element: 2,
    hoverElement: 3,
    active: 4,
    subtleBorder: 3,
    border: 4,
    hoverBorder: 5,
    textDisabled: 7,
    secondary: 7,
    solid: 8,
    hoverSolid: 9,
    activeSolid: 9,
    text: 10,
    highContrast: 11,
    textHover: 11,
    textEmphasis: 11,
    textActive: 11,
  },
  sizes: {
    xxs: '0.25',
    xs: '0.5',
    s: '1',
    m: '4',
    l: '6',
    xl: '8',
    font: {
      xs: '0.75',
      s: '0.9',
      m: '1',
      l: '2',
      xl: '3',
      h1: '7',
      h2: '6',
      h3: '4',
      h4: '2',
    },
  },
}

export const DEFAULT_THEME: Theme = {
  color,
  size,
  font: {
    default: '"Mulish", sans-serif',
    serif: '"Mulish", sans-serif',
    code: 'Courier, monospace',
  },
  fontWeight: {
    light: '300',
    normal: '400',
    medium: '500',
    bold: '700',
    extraBold: '900',
  },
  shadow: {
    0: `0.5px 1px 2px ${_themePathToVariable('color.overlayBlack.5')}`,
    1: `1px 1px 6px ${_themePathToVariable('color.overlayBlack.5')}`,
    2: 'rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px',
  },
  lineHeights: {
    default: '1.5',
    spaced: '2',
  },
  letterSpacings: {
    '-3': '-0.05em',
    '-2': '-0.02em',
    '-1': '-0.01em',
    0: '0',
    1: '0.01em',
    2: '0.02em',
    3: '0.05em',
  },
  borders: {
    split: 'solid 1px var(--color-light-gray-4)',
    default: 'solid 1px var(--color-light-gray-6)',
  },
  zIndices: {
    notifications: '10000',
    devTools: '9999',
    overlays: '1001', // This will show up above Ant Modals.
    modals: '1050',
    stickyHeaders: '950',
    dropdown: '1100',
    forceMountedDropdown: '999',
  },
  radii: {
    0: 2,
    1: 4, // @border-radius-base
    2: 8, // @border-radius-medium
    3: 16, // @border-radius-large
  },
  curves: {
    easeIn: 'ease-in',
    easeOut: 'ease-out',
    ease: 'ease-in-out',
    linear: 'linear',
    exponential: 'cubic-bezier(0.16, 1, 0.3, 1)',
  },
  aliases: DEFAULT_ALIASES,
  blur: {
    0: 2,
    1: 4,
  },
}
