import { useResource } from '@thesisedu/feature-react'
import React from 'react'

import { VideoThumbnail, VideoThumbnailTypeResource, DEFAULT_TYPE } from './types'

export interface VideoThumbnailViewProps {
  thumbnail: VideoThumbnail
}
export function VideoThumbnailView({ thumbnail }: VideoThumbnailViewProps) {
  const { ViewComponent } =
    useResource<VideoThumbnailTypeResource<any>>(
      'VideoThumbnailType',
      thumbnail.type || DEFAULT_TYPE,
    ) || {}
  if (ViewComponent) {
    return <ViewComponent value={thumbnail} />
  } else {
    return null
  }
}
