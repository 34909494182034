import { FeaturePreviewSettings } from '@thesisedu/feature-feature-preview-react'
import { PageHead } from '@thesisedu/react'
import { Rocket } from '@thesisedu/react/icons'
import React from 'react'

import { UsersReactFeature } from '../../UsersReactFeature'
import { UserSettingsPageResource } from '../../types'

export default function (feature: UsersReactFeature) {
  feature.resources.addResource<UserSettingsPageResource>({
    type: 'UserSettingsPage',
    identifier: 'preview',
    title: 'Feature Preview',
    icon: <Rocket />,
    weight: 10,
    component: () => (
      <>
        <PageHead title={'Feature Preview'} />
        <FeaturePreviewSettings />
      </>
    ),
  })
}
