import { MenuOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { BodyLarge, styled } from '@thesisedu/web'
import { Tooltip, Button, Typography } from 'antd'
import { transparentize } from 'polished'
import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'

import { ArchiveClassButton } from './ArchiveClassButton'
import { useUpdateClassMutation } from '../queries/useUpdateClassMutation'
import { MinimalClassFragment } from '../schema'

interface Theme {
  [key: string]: any
}
const getThemeColor = (theme: Theme, props: ContainerProps): string => {
  if (props.isDropping) {
    return transparentize(0.75, theme['@primary-color-light'])
  } else if (props.isDragging) {
    return theme['@background-color-base']
  } else if (props.isDroppable && !props.isDropDisabled) {
    return theme['@background-color-base']
  } else {
    return theme['@background-color-light']
  }
}

interface ContainerProps {
  isDragging?: boolean
  isDroppable?: boolean
  isDropDisabled?: boolean
  isDropping?: boolean
}
const Container = styled.div<ContainerProps>`
  background: ${props => getThemeColor(props.theme, props)};
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-s']};
  width: 100%;
  max-width: 350px;
  margin: ${props => props.theme['@size-xs']};
  transition:
    background 0.25s linear,
    opacity 0.25s linear;
  opacity: ${props => (!props.isDragging && props.isDropDisabled ? 0.5 : 1)};
`

const Header = styled.div`
  margin-bottom: ${props => props.theme['@size-m']};
  display: flex;
  align-items: center;
`

const HeaderRight = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
  white-space: nowrap;
`

const AddIconContainer = styled.div`
  cursor: pointer;
  padding: 2px;
  color: ${props => props.theme['@primary-color']};
  display: inline-block;
`

const Dragger = styled(MenuOutlined)`
  height: ${props => props.theme['@size-m']};
  padding: 2px;
  cursor: pointer;
  margin-right: ${props => props.theme['@size-xs']};
`

export interface ClassDraggableProps {
  index: number
  class: MinimalClassFragment
  draggingItem?: {
    classId: string
    studentId: string
  }
  dragHandleProps?: SyntheticListenerMap | undefined
  isDropping?: boolean
  onInvite?: () => void
  isArchived?: boolean
  settingsLink?: (classId: string) => string
}
export const ClassDraggable: React.FC<React.PropsWithChildren<ClassDraggableProps>> = ({
  index,
  class: cls,
  settingsLink = clsId => clsId,
  draggingItem,
  isDropping,
  onInvite,
  isArchived,
}) => {
  const navigate = useNavigate()
  const [updateClass] = useUpdateClassMutation()
  return (
    <Draggable key={cls.id} draggableId={cls.id} index={index} isDragDisabled={!cls.canUpdate}>
      {(provided, snapshot) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
          isDragging={snapshot.isDragging || draggingItem?.classId === cls.id}
          isDroppable={!!draggingItem}
          isDropping={isDropping}
        >
          <Header {...provided.dragHandleProps}>
            <Dragger style={{ display: cls.canUpdate ? 'block' : 'none' }} />
            <BodyLarge>
              <Typography.Paragraph
                style={{ marginBottom: 0 }}
                editable={
                  cls.canUpdate
                    ? {
                        onChange: async value => {
                          await updateClass({
                            variables: {
                              input: {
                                id: cls.id,
                                patch: {
                                  name: value,
                                },
                              },
                            },
                          })
                        },
                      }
                    : undefined
                }
              >
                {cls.name}
              </Typography.Paragraph>
            </BodyLarge>
            <HeaderRight>
              {cls.canUpdate ? (
                <Tooltip title={'Manage Class'} mouseEnterDelay={1}>
                  {isArchived ? (
                    <ArchiveClassButton
                      buttonProps={{
                        variant: 'chromeless',
                        children: undefined,
                      }}
                      classId={cls.id}
                    />
                  ) : (
                    <Button
                      type={'link'}
                      icon={<SettingOutlined />}
                      onClick={() => navigate(settingsLink(cls.id))}
                    />
                  )}
                </Tooltip>
              ) : null}
              {onInvite ? (
                <Tooltip title={'Invite Student'} mouseEnterDelay={1}>
                  <AddIconContainer onClick={() => onInvite()}>
                    <PlusOutlined />
                  </AddIconContainer>
                </Tooltip>
              ) : null}
            </HeaderRight>
          </Header>
          <Droppable type={'STUDENT'} droppableId={cls.id}>
            {dropProvided => (
              <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                <div>TODO reimplement</div>
                {dropProvided.placeholder}
              </div>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  )
}
