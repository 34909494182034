import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { BlockSpin, FragmentListMode, H2, styled } from '@thesisedu/web'
import { Button } from 'antd'
import pluralize from 'pluralize'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { LessonsList } from '../LessonsList'
import { BasicLessonFragment, useStudentLessonsQuery } from '../schema'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-m']};
  > div:last-child {
    margin-left: auto;
  }
`

export const StudentLessonSummary: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classId = useSelectedClassId()
  const navigate = useNavigate()
  const { data, loading } = useStudentLessonsQuery({
    variables: { first: 6, classId },
    skip: !classId,
  })
  const toReview: BasicLessonFragment[] =
    data?.viewer?.student?.lessons.edges
      .filter(edge => !edge.node.practices.edges.some(e => e.node.class?.id === classId))
      .map(edge => edge.node) || []
  if (classId) {
    if (loading) {
      return <BlockSpin />
    } else if (toReview.length) {
      return (
        <>
          <HeaderContainer>
            <H2 isBlock={false}>
              You have {pluralize('Lesson', toReview.length, true)} to review.
            </H2>
            <div>
              <Link to={'../lessons'}>View all Lessons</Link>
            </div>
          </HeaderContainer>
          <LessonsList
            lessons={toReview}
            refetch={() => Promise.resolve()}
            noSections
            forceMode={FragmentListMode.GRID}
            onLessonClick={lessonId => navigate(`../lessons/${lessonId}`)}
            action={item => (
              <Button
                type={'primary'}
                shape={'round'}
                onClick={e => {
                  e.stopPropagation()
                  navigate(`/student/practices/create?lessonId=${item.id}`)
                }}
              >
                Practice <ArrowRightOutlined />
              </Button>
            )}
          />
        </>
      )
    } else {
      return null
    }
  } else {
    return null
  }
}
