import { useFeatureRoot } from '@thesisedu/feature-react'
import { DocumentNode } from 'graphql'
import React from 'react'

import { getSearchQueryDocument } from './getSearchQueryDocument'

export interface UseSearchQueryOpts {
  query?: string | null
}

export function useSearchQueryDocument(): DocumentNode {
  const root = useFeatureRoot()
  return React.useMemo(() => {
    return getSearchQueryDocument(root)
  }, [!!root])
}
