import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Result } from '@thesisedu/react'
import { Form, LoadingIndicator, useToast } from '@thesisedu/ui'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PresetForm } from './PresetForm'
import {
  ClassGroupPresetFragment,
  ClassGroupPresetInput,
  useClassGroupPresetQuery,
  useUpdateClassGroupPresetMutation,
} from '../../schema'

interface EditPresetFormProps {
  preset: ClassGroupPresetFragment
  classId: string
}
function EditPresetForm({ classId, preset }: EditPresetFormProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const [update] = useUpdateClassGroupPresetMutation({
    onError: onMutationError('There was an error updating that preset.'),
    onCompleted() {
      toast.success('Preset updated')
      navigate('..')
    },
  })
  const form = Form.useForm<ClassGroupPresetInput>({
    defaultValues: {
      configuration: preset.configuration,
      isDefault: preset.isDefault,
      name: preset.name,
      submissionType: preset.submissionType,
    },
  })
  return (
    <Form
      form={form}
      onFinish={async patch => {
        await update({
          variables: {
            input: {
              id: preset.id,
              patch,
            },
          },
        })
      }}
    >
      <PresetForm
        presetId={preset.id}
        classId={classId}
        onDeletePreset={() => {
          navigate('..')
        }}
      />
    </Form>
  )
}

export interface EditPresetPageProps {
  classId: string
}
export function EditPresetPage({ classId }: EditPresetPageProps) {
  const { presetId } = useParams()
  const { data, loading } = useClassGroupPresetQuery({
    variables: {
      id: presetId,
    },
  })
  const preset = data?.node?.__typename === 'ClassGroupPreset' ? data.node : undefined

  if (loading) {
    return <LoadingIndicator.Labeled label={'Loading preset...'} />
  } else if (preset) {
    return <EditPresetForm preset={preset} classId={classId} />
  } else {
    return <Result.Error description={'There was an error loading that preset.'} />
  }
}
