import { styled } from '@thesisedu/web'

export const Container = styled.div`
  display: flex;
  align-items: center;
`
export const ImageContainer = styled.div`
  margin-bottom: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  margin-top: ${props => props.theme['@size-xs']};
  overflow: hidden;
  text-align: center;
  > .attachment-image {
    max-width: 100%;
    max-height: 300px;
    width: auto;
    height: auto;
  }
`
export const Content = styled.div`
  flex-grow: 1;
  margin: 0 ${props => props.theme['@size-s']};
`
export const Page = styled.div`
  display: flex;
  width: 40px;
  height: 50px;
  background: ${props => props.theme['@background-color-dark']};
  color: ${props => props.theme['@text-color-dark']};
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  text-wrap: none;
  border-radius: ${props => props.theme['@border-radius-base']};
`
