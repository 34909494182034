import { ExpandedContentComponentProps } from '@thesisedu/feature-classes-react'
import { Block, Body } from '@thesisedu/web'
import { Button, Form, Input, message } from 'antd'
import React from 'react'

import { ImportStudentIDPayload } from '../ImportStudentID'
import { useInviteStudentIdMutation } from '../queries/useInviteStudentIDMutation'

interface Values {
  studentId: string
  teacherId: string
}
export function InviteExisting({
  onClose,
  class: cls,
}: ExpandedContentComponentProps<ImportStudentIDPayload>) {
  const [invite, { loading }] = useInviteStudentIdMutation({
    onCompleted: () => {
      message.success('Student added successfully!')
      onClose()
    },
  })
  const [form] = Form.useForm<Values>()
  return (
    <Form
      form={form}
      onFinish={values => {
        invite({
          variables: {
            input: {
              id: cls.id,
              studentId: values.studentId,
              teacherId: values.teacherId,
            },
          },
        })
      }}
      layout={'vertical'}
    >
      <Body isBlock>
        To add a student with an existing student with a Student ID, type in their Student ID and
        Teacher ID below and then click <strong>Invite.</strong>
      </Body>
      <Form.Item
        name={'teacherId'}
        label={'Teacher ID'}
        rules={[{ required: true, message: 'A teacher ID is required.' }]}
      >
        <Input size={'large'} placeholder={'12345'} />
      </Form.Item>
      <Form.Item
        name={'studentId'}
        label={'Student ID'}
        rules={[{ required: true, message: 'A student ID is required.' }]}
      >
        <Input size={'large'} placeholder={'ABCDE'} />
      </Form.Item>
      <Block style={{ textAlign: 'right' }}>
        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          Invite
        </Button>
      </Block>
    </Form>
  )
}
