import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  LicenseSubscriptionFragmentDoc,
  useUpdateSubscriptionMutation as useMutation,
} from '../schema'

export const useUpdateSubscriptionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that subscription'),
    update: updateMutation({
      fragmentName: 'LicenseSubscription',
      fragment: LicenseSubscriptionFragmentDoc,
      path: 'updateLicenseSubscription.licenseSubscription',
    }),
    ...opts,
  })
