import { useErrorService, useResource } from '@thesisedu/feature-react'
import { NotFound } from '@thesisedu/web'
import React from 'react'

import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { AssignmentCalculationResource } from '../types'

export function CalculatedAssignmentViewer() {
  const { configuration } = useAssignmentViewerContext(true)
  const errorService = useErrorService()
  if (!configuration.calculation)
    throw new Error('Using CalculatedAssignmentViewer without calculation.')
  const resource = useResource<AssignmentCalculationResource>(
    'AssignmentCalculation',
    configuration.calculation?.identifier,
  )
  if (resource) {
    const { Editor } = resource
    return <Editor calculation={configuration.calculation} />
  } else {
    errorService.reportError(
      new Error(`Invalid calculation type: '${configuration.calculation.identifier}`),
    )
    return <NotFound description={'We ran into an error showing this calculation.'} />
  }
}
