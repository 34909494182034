import React from 'react'

export interface ScrollableContainerContextValue {
  target?: React.RefObject<HTMLElement>
}
export const ScrollableContainerContext = React.createContext<
  ScrollableContainerContextValue | undefined
>(undefined)

export interface ScrollableContainerProviderProps {
  targetRef: React.RefObject<HTMLElement>
  children: React.ReactElement
}
export function ScrollableContainerProvider({
  targetRef,
  children,
}: ScrollableContainerProviderProps) {
  return <ScrollableContainerContext.Provider value={{ target: targetRef }} children={children} />
}

export function useScrollableContainerContext(): ScrollableContainerContextValue | undefined
export function useScrollableContainerContext(
  require: false,
): ScrollableContainerContextValue | undefined
export function useScrollableContainerContext(require: true): ScrollableContainerContextValue
export function useScrollableContainerContext(
  require?: boolean,
): ScrollableContainerContextValue | undefined {
  const context = React.useContext(ScrollableContainerContext)
  if (!context && require) {
    throw new Error('ScrollableContainerContext is required, yet not provided.')
  }
  return context
}
