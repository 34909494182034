import { AdvancedBells } from './advanced/Bells'
import { AdvancedColors } from './advanced/Colors'
import { AdvancedNotes } from './advanced/Notes'
import { SimpleBells } from './simple/Bells'
import { SimpleColors } from './simple/Colors'
import { SimpleNotes } from './simple/Notes'
import { SimpleNumbers } from './simple/Numbers'
import { SimpleSolfege } from './simple/Solfege'
import { Mode } from './types'

export const MODES: Mode[] = [
  SimpleBells,
  SimpleNumbers,
  SimpleNotes,
  SimpleSolfege,
  SimpleColors,
  AdvancedBells,
  AdvancedNotes,
  AdvancedColors,
]
