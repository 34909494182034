import { Radio } from 'antd'
import React from 'react'

import { styled } from '../styled'
import { HSpaced } from '../styled/Block'

export interface BlockRadioProps {
  children: React.ReactElement | React.ReactElement[]
  selected?: boolean
  value: string
}
/** @deprecated */
export function BlockRadio({ children, selected, value }: BlockRadioProps) {
  return (
    <Label isSelected={selected}>
      <HSpaced align={'flex-start'}>
        <Radio value={value} />
        <div>{children}</div>
      </HSpaced>
    </Label>
  )
}

const Label = styled.label<{ isSelected?: boolean }>`
  transition:
    background 0.1s linear,
    color 0.1s linear;
  cursor: pointer;
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@padding-sm']};
  color: ${props => props.theme['@gray-5']};
  &:hover,
  ${props => (props.isSelected ? '&' : '.noop')} {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@primary-color']};
  }
`
