import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled } from '@thesisedu/web'
import React from 'react'
import { useDragLayer } from 'react-dnd'

import { DraggableImage } from './DraggableImage'
import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { Draggable } from './types'

export interface DragLayerProps {
  draggables?: Draggable[]
}
export function DragLayer({ draggables }: DragLayerProps) {
  const { width = 0, height = 0 } = useGraphicDragDropContext(true)
  const { isDragging, item, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const draggable = React.useMemo<Draggable | undefined>(() => {
    return item?.id ? draggables?.find(d => d.id === item?.id) : undefined
  }, [item?.id])
  const { filename } = getFileInfo(draggable?.imagePath || '')

  if (!isDragging || !draggable || !filename) return null
  return (
    <Layer>
      <div
        style={{
          transform: currentOffset
            ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
            : undefined,
          opacity: currentOffset ? 1 : 0,
          width: draggable.width * width,
          height: draggable.height * height,
        }}
      >
        <DraggableImage
          imagePath={filename}
          width={draggable.width * width}
          height={draggable.height * height}
          gifProps={{
            loopTimes: draggable.gifPlaybackLoopTimes || 1,
            playing: true,
          }}
        />
      </div>
    </Layer>
  )
}

const Layer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`
