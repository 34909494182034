import {
  SheetMusicDragDropConfig,
  SheetMusicDragDropRecordMode,
} from '@thesisedu/feature-sheet-music-core'
import { Drawer, Form, Checkbox, Button, Select } from 'antd'
import React from 'react'

import { InstrumentSelect } from '../../../viewer/InstrumentSelect'

export interface SheetMusicDragDropOptionsDrawerProps {
  visible?: boolean
  onClose?: () => void
  value?: Partial<SheetMusicDragDropConfig>
  onChange?: (value: Partial<SheetMusicDragDropConfig>) => void
}
export function SheetMusicDragDropOptionsDrawer({
  visible,
  onClose,
  value,
  onChange,
}: SheetMusicDragDropOptionsDrawerProps) {
  const [form] = Form.useForm<Partial<SheetMusicDragDropConfig>>()
  React.useEffect(() => {
    if (visible) {
      form.setFieldsValue(value || {})
    }
  }, [visible])
  return (
    <Drawer
      visible={visible}
      onClose={() => {
        form.submit()
      }}
      width={350}
      title={'Additional Settings'}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          if (onChange) {
            onChange(values)
          }
          if (onClose) {
            onClose()
          }
        }}
      >
        <Form.Item
          name={'showControls'}
          label={'Controls'}
          extra={'If checked, "free-mode" controls are included with any custom draggables.'}
          valuePropName={'checked'}
        >
          <Checkbox>Always Show Controls</Checkbox>
        </Form.Item>
        <Form.Item name={'useAdvancedMode'} label={'Use Advanced Mode'}>
          <AdvancedModeField />
        </Form.Item>
        <Form.Item
          name={'allowChangingTimeSignature'}
          label={'Time Signature'}
          extra={
            'By default, the time signature used in the answer is the only one allowed. If this is enabled, students may change the time signature.'
          }
        >
          <Checkbox>Allow students to change Time Signature</Checkbox>
        </Form.Item>
        <Form.Item
          name={'allowChangingBpm'}
          label={'BPM'}
          extra={
            'By default, the BPM used in the answer is the only one allowed. If this is enabled, students may change the BPM.'
          }
        >
          <Checkbox>Allow students to change BPM</Checkbox>
        </Form.Item>
        <Form.Item
          name={'defaultInstrument'}
          label={'Default Instrument'}
          extra={'If not specified, the first available instrument is used.'}
        >
          <InstrumentSelect allowClear />
        </Form.Item>
        <Form.Item
          name={'recordMode'}
          label={'Recording Mode'}
          extra={'Allow students to record themselves performing to their creations.'}
        >
          <Select
            options={[
              { value: SheetMusicDragDropRecordMode.None, label: 'No Recordings' },
              { value: SheetMusicDragDropRecordMode.Allow, label: 'Allow Recordings' },
              { value: SheetMusicDragDropRecordMode.Require, label: 'Require Recordings' },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button type={'primary'} onClick={form.submit}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  )
}

interface AdvancedModeFieldProps {
  value?: boolean
  onChange?: (value: boolean | undefined) => void
}
function AdvancedModeField({ value, onChange }: AdvancedModeFieldProps) {
  return (
    <Select
      value={value === null || value === undefined ? 'pref' : value.toString()}
      onChange={newValue => {
        if (onChange) {
          onChange(newValue === 'pref' ? undefined : newValue === 'true')
        }
      }}
      options={[
        { value: 'pref', label: 'Use Class Preference' },
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ]}
    />
  )
}
