import { styledContainer as styled } from '@thesisedu/react'

/** @deprecated this was specific to the old widget functionality */
export const ContentWrapper: React.FC<React.PropsWithChildren<any>> = styled`
  // .header is the first child, usually.
  .content > div {
    > :first-child > :first-child > :first-child > :first-child > :first-child > :first-child { margin-top: 0 !important; }
    > :first-child > :first-child > :first-child > :first-child > :first-child { margin-top: 0 !important; }
    > :first-child > :first-child > :first-child > :first-child { margin-top: 0 !important; }
    > :first-child > :first-child > :first-child { margin-top: 0 !important; }
    > :first-child > :first-child { margin-top: 0 !important; }
    > :first-child { margin-top: 0 !important; }
  }

  div.widget-container:not(.no-widget-styles) {
    p,
    li:not(.ant-dropdown-menu-item) {
      line-height: 2 !important;
      font-size: ${props => props.theme['@font-size-lg']} !important;
      margin: ${props => props.theme['@size-s']} 0 !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
      &:first-child {
        margin-top: 0 !important;
      }
    }
    .large,
    .large p {
      font-size: ${props => props.theme['@size-m']} !important;
    }
    .small,
    .small p {
      font-size: ${props => props.theme['@font-size-base']} !important;
    }
    .subtitle {
      line-height: 1.1 !important;
    }

    > :first-child {
      margin-top: 0 !important;
    }

    h2 {
      margin-top: ${props => props.theme['@size-xl']} !important;
      margin-bottom: ${props => props.theme['@size-s']} !important;
      letter-spacing: -0.25px;
    }
    h3 {
      margin-top: ${props => props.theme['@size-l']} !important;
      margin-bottom: ${props => props.theme['@size-s']} !important;
      text-transform: none;
      font-size: calc(${props => props.theme['@size-m']} * 1.25);
      font-weight: bold;
      letter-spacing: -0.15px;
      color: ${props => props.theme['@gray-5']} !important;
    }
    h4 {
      margin-top: ${props => props.theme['@size-m']} !important;
      margin-bottom: ${props => props.theme['@size-xs']} !important;
      font-size: ${props => props.theme['@size-m']} !important;
      font-weight: bold !important;
      letter-spacing: 0;
      color: ${props => props.theme['@primary-color']} !important;
    }
    ul:not(.ant-dropdown-menu),
    ol {
      margin-top: ${props => props.theme['@size-s']} !important;
      margin-bottom: ${props => props.theme['@size-s']} !important;
      li {
        margin: ${props => props.theme['@size-xs']} 0 !important;
        padding-left: ${props => props.theme['@size-xs']} !important;
        position: relative;
        font-size: ${props => props.theme['@font-size-lg']};
      }
      ol {
        list-style-type: lower-alpha;
        ol {
          list-style-type: lower-roman;
        }
      }
    }
    ul:not(.ant-dropdown-menu) {
      list-style: none none;
      margin: ${props => props.theme['@size-s']} 0 !important;
      padding-left: ${props => props.theme['@size-s']} !important;
      li {
        padding-left: ${props => props.theme['@size-m']} !important;
        &:before {
          display: block;
          content: ' ';
          position: absolute;
          top: 0.75em;
          left: 0;
          width: 8px;
          height: 8px;
          background: ${props => props.theme['@gray-2']};
          border-radius: 50%;
        }
      }
    }

    table:not(.htCore) {
      border: solid 1px ${props => props.theme['@border-color-base']};
      border-radius: ${props => props.theme['@border-radius-base']};
      margin: ${props => props.theme['@size-s']} auto !important;
      border-spacing: 0;
      border-collapse: separate;
      min-width: 300px;
      td:not(:last-child) {
        border-right: solid 1px ${props => props.theme['@border-color-base']};
      }
      td,
      th {
        padding: ${props => props.theme['@padding-sm']} !important;
        text-align: left !important;
      }
      tr td {
        border-bottom: solid 1px ${props => props.theme['@border-color-base']};
      }
      thead {
        td,
        th {
          background: ${props => props.theme['@gray-1']};
          font-weight: bold;
          font-size: ${props => props.theme['@font-size-lg']} !important;
          &:first-child {
            border-top-left-radius: ${props => props.theme['@border-radius-base']};
          }
          &:last-child {
            border-top-right-radius: ${props => props.theme['@border-radius-base']};
          }
        }
      }
      tbody tr:last-child td {
        border-bottom: none !important;
      }
    }

    code {
      background: none !important;
      margin: 0 0.15em;
      padding: 0 !important;
      display: inline-block !important;
      color: #1a1e23 !important;
      font-weight: bold;
      font-family: ${props => props.theme['@font-family']};
    }

    hr {
      background: ${props => props.theme['@border-color-base']};
      border-radius: 2px;
      height: 4px;
      border: 0;
      margin: ${props => props.theme['@size-s']} 0 !important;
    }

    .ck.ck-editor__editable_inline {
      padding: 0;
      border: none !important;
    }
    .ck.ck-editor__editable_inline > :last-child {
      margin-bottom: 0;
    }
    .ck.ck-editor__editable_inline > :first-child {
      margin-top: 0;
    }
  }

  div.widget-container.no-bottom-margin + div.widget-container:not(.no-widget-styles) {
    h2, h3, h4, ul:not(.ant-dropdown-menu), ol, p {
      margin-top: 0 !important;
    }
  }
`
