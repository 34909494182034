import { Body, BodyLarge, H2, styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'
import { Link } from 'react-router-dom'

import { LargeBoxButton } from './styled'

export interface NavButtonProps {
  subtitle: string
  title: string
  imageUrl: string
  centerImage?: boolean
  color: string
  linkText: string
  linkTo?: string
  imageWidth?: string
  disabled?: React.ReactElement | string
  onClick?: () => void
}
export function NavButton({
  subtitle,
  title,
  imageUrl,
  centerImage,
  color,
  linkText,
  linkTo,
  imageWidth,
  disabled,
  onClick,
}: NavButtonProps) {
  const content = (
    <Container className={disabled ? 'disabled' : ''}>
      <ContainerLeft color={color} pinBottom={!centerImage}>
        <img src={imageUrl} style={{ width: imageWidth }} />
      </ContainerLeft>
      <ContainerContent>
        <BodyLarge color={'@text-color-secondary'}>{subtitle}</BodyLarge>
        <H2>{title}</H2>
        <Body color={color}>{linkText} &rarr;</Body>
      </ContainerContent>
      <DisabledContainer className={disabled ? 'visible' : undefined}>
        <BodyLarge color={'@white'}>{disabled}</BodyLarge>
      </DisabledContainer>
    </Container>
  )

  if (disabled) {
    return content
  } else if (linkTo) {
    return <Link to={linkTo}>{content}</Link>
  } else {
    return (
      <div onClick={onClick} style={{ cursor: 'pointer' }}>
        {content}
      </div>
    )
  }
}

const Container = styled(LargeBoxButton)`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: ${props => props.theme['@size-m']};
  position: relative;
`
const ContainerLeft = styled.div<{ color: string; pinBottom?: boolean }>`
  background: ${props => props.theme[`${props.color}-light`]};
  padding-top: ${props => props.theme['@size-s']};
  padding-bottom: ${props => (props.pinBottom ? 0 : props.theme['@size-s'])};
  text-align: center;
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: center;
  width: 182px;
  align-self: stretch;
  > * {
    width: 75%;
  }
`
const ContainerContent = styled.div<{ isLarge?: boolean }>`
  padding: ${props => props.theme[props.isLarge ? '@size-l' : '@size-s']}
    ${props => props.theme['@size-m']};
  h2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const DisabledContainer = styled.div`
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s linear;
  backdrop-filter: blur(5px);
  background: ${props => props.theme['@modal-mask-bg']};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme['@size-l']};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > * {
    background: ${props => transparentize(0.25, props.theme['@gray-6'])};
    border-radius: ${props => props.theme['@border-radius-base']};
    padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  }
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
`
