import { useApolloClient } from '@apollo/client'
import React from 'react'

import { Upload } from './Upload'
import { CommonAttachmentCreate } from './common/CommonAttachmentCreate'
import { AttachmentCreateProps } from './types'

export const AttachmentCreate: React.FC<React.PropsWithChildren<AttachmentCreateProps>> = props => {
  const client = useApolloClient()
  return <CommonAttachmentCreate {...props} client={client} UploadComponent={Upload} />
}
