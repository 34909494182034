import {
  BookmarksPlugin,
  MetronomePlugin,
  PitchPlugin,
  RecordOrViewWithSidebar,
  SummaryPlugin,
} from '@thesisedu/feature-media-react'
import { Block, Form } from '@thesisedu/react'
import { styled } from '@thesisedu/web'
import React, { useState } from 'react'
import { useNavigate, Prompt } from 'react-router-dom'

import { LessonForm } from './LessonForm'
import { LessonFormValues } from './useCreateLesson'
import { debug } from '../log'
import { useUpdateLessonTagsMutation } from '../queries/useUpdateLessonTagsMutation'
import { LessonFragment, useCreateLessonMutation } from '../schema'

export const DarkFormContainer = styled.div`
  label,
  h2 {
    color: ${props => props.theme['@text-color']};
  }
`

export interface CreateLessonProps {
  onCreated?: (lesson: LessonFragment) => void
}
export const CreateLesson: React.FC<React.PropsWithChildren<CreateLessonProps>> = ({
  onCreated,
}) => {
  const [mediaId, setMediaId] = useState<string>()
  const [createLesson, { loading }] = useCreateLessonMutation({
    refetchQueries: ['teacherLessons'],
  })
  const [updateTags, { loading: tagsLoading }] = useUpdateLessonTagsMutation()
  const form = Form.useForm<LessonFormValues>()
  const navigate = useNavigate()
  return (
    <RecordOrViewWithSidebar label={'Lesson Recording'} onChange={setMediaId}>
      <BookmarksPlugin />
      <MetronomePlugin />
      <PitchPlugin />
      {mediaId ? (
        <SummaryPlugin
          title={'Create Lesson'}
          subtitle={
            "Now that you've uploaded a video, give your new lesson a name and add some tags to help organize it."
          }
        >
          <DarkFormContainer>
            <Prompt
              when
              message={'Are you sure you want to leave the page? You will lose changes.'}
            />
            <LessonForm form={form} showAttachment onFinish={() => {}} />
            <Block marginTop={'@size-s'}>
              <Form.StandaloneSubmit
                form={form}
                onFinish={async values => {
                  if (mediaId) {
                    debug('found mediaId %s, creating lesson', mediaId)
                    const result = await createLesson({
                      variables: {
                        input: {
                          mediaId,
                          name: values.name,
                          attachment: values.attachment,
                        },
                      },
                    })
                    if (result.data?.createLesson.lesson.id) {
                      debug('lesson create successful. checking if tags...', values.tags)
                      if (values.tags && values.tags.length) {
                        debug('updating tags')
                        await updateTags({
                          variables: {
                            input: {
                              id: result.data.createLesson.lesson.id,
                              tags: values.tags,
                            },
                          },
                        })
                      }
                      if (onCreated) {
                        debug('finished creating lesson. calling onCreated...')
                        onCreated(result.data.createLesson.lesson)
                      } else {
                        debug('finished creating lesson. redirecting to lesson...')
                        navigate(`../${result.data.createLesson.lesson.id}`)
                      }
                    }
                  }
                }}
                loading={loading || tagsLoading}
                children={'Create Lesson'}
              />
            </Block>
          </DarkFormContainer>
        </SummaryPlugin>
      ) : null}
    </RecordOrViewWithSidebar>
  )
}
