import moment from 'moment'

import { GradeTreeAssignment, GradeTreeCategory } from './useGradeTree'

export type GradeTreeRecord = GradeTreeAssignmentExt | GradeTreeChild
export interface GradeTreeAssignmentExt extends GradeTreeAssignment {
  grade?: number | null
  points?: number | null
  todo: boolean
  overdue: boolean
  dueToday: boolean
  notGraded: boolean
  late: boolean
  key: string
}

export interface GradeTreeChild {
  name: string
  grade?: number | null
  weight: number
  points?: number | null
  totalPoints?: number | null
  key: string
  todo: number
  overdue: number
  dueToday: number
  notGraded: number
  late: number
  children: GradeTreeAssignmentExt[]
}

export function statusesForChildren(children: (GradeTreeAssignmentExt | GradeTreeChild)[]) {
  return children.reduce<[number, number, number, number, number]>(
    (sums, child) => {
      let [todo, overdue, dueToday, notGraded, late] = sums
      todo += child.todo === true ? 1 : child.todo || 0
      overdue += child.overdue === true ? 1 : child.overdue || 0
      dueToday += child.dueToday === true ? 1 : child.dueToday || 0
      notGraded += child.notGraded === true ? 1 : child.notGraded || 0
      late += child.late === true ? 1 : child.late || 0
      return [todo, overdue, dueToday, notGraded, late]
    },
    [0, 0, 0, 0, 0],
  )
}

export function translateGradeTreeAssignment(
  assignment: GradeTreeAssignment,
  incompleteAsZero: boolean,
): GradeTreeAssignmentExt {
  const submission = assignment.submissions[0]
  const todo = !submission?.submittedAt
  const overdue = todo && assignment.dueAt && moment(assignment.dueAt).isBefore(moment())
  const notGraded = !todo && !submission?.reviewed
  const late =
    !todo && assignment.dueAt && moment(assignment.dueAt).isBefore(submission.submittedAt)
  const dueToday =
    !overdue && todo && assignment.dueAt && moment(assignment.dueAt).isSame(moment(), 'day')
  return {
    ...assignment,
    grade: incompleteAsZero && overdue ? 0 : submission?.grade,
    points:
      submission?.grade !== undefined && submission?.grade !== null
        ? parseFloat((submission.grade * assignment.totalPoints).toFixed(2))
        : undefined,
    todo,
    overdue,
    notGraded,
    late,
    dueToday,
    key: assignment.id,
  }
}

/**
 * Translates an entire grade tree from the classAssignmentCategoriesWithGrade
 * query into something usable for CategoryGradesTable
 * @param rootCategories - the root categories
 * @param incompleteAsZero - whether or not incomplete and late assignments are graded as zero
 */
export function translateGradeTreeToTableData(
  rootCategories: GradeTreeCategory[],
  incompleteAsZero: boolean,
): GradeTreeRecord[] {
  const totalGradeWeight = rootCategories.reduce<number>(
    (sum, cat) => sum + cat.category.gradeWeight,
    0,
  )
  return rootCategories.map<GradeTreeChild>(childNode => {
    const children = childNode.assignments.map<GradeTreeAssignmentExt>(assignment => {
      return translateGradeTreeAssignment(assignment, incompleteAsZero)
    })
    const totalPoints = children
      .filter(child => !child.todo && !child.notGraded)
      .reduce<number>((sum, assignment) => sum + assignment.totalPoints, 0)
    const [todo, overdue, dueToday, notGraded, late] = statusesForChildren(children)
    return {
      todo,
      overdue,
      dueToday,
      notGraded,
      late,
      children,
      name: childNode.category.name,
      grade: childNode.grade,
      points: childNode.points,
      totalPoints,
      weight: totalGradeWeight ? childNode.category.gradeWeight / totalGradeWeight : 0,
      key: childNode.category.id,
    }
  })
}

export function isAssignment(
  record: GradeTreeRecord,
  field?: keyof GradeTreeRecord,
): record is GradeTreeAssignmentExt {
  if (field) {
    return record[field] === true || record[field] === false
  } else {
    return !!(record as GradeTreeAssignmentExt).id
  }
}
