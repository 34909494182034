import { Format } from '@thesisedu/feature-utils'
import { styled, FontWeight } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'
import { GridChildComponentProps } from 'react-window'

import { AssignmentAverageCell } from './AssignmentAverageCell'
import { AssignmentHeader } from './AssignmentHeader'
import { AssignmentLoadingCell } from './AssignmentLoadingCell'
import { AssignmentStudentCell } from './AssignmentStudentCell'
import { useClassGradesTableContext } from '../ClassGradesTable'
import { getColumnOffset, ROW_OFFSET } from '../constants'
import { isAssignmentWithSubmissions } from '../grading/types'

/**
 * Basic Column Headers
 */
export interface ColumnHeaderCellProps extends GridChildComponentProps {
  title: string | React.ReactNode
  className?: string
}
export function ColumnHeaderCell({ title, className, style }: ColumnHeaderCellProps) {
  return (
    <ColumnHeaderContainer style={style} className={className}>
      {title}
    </ColumnHeaderContainer>
  )
}
const ColumnHeaderContainer = styled.div`
  background: ${props => props.theme['@background-color-base']};
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-weight: ${() => FontWeight.Bold};
`

/**
 * Basic Row Headers
 */
export interface RowHeaderCellProps extends GridChildComponentProps {
  title: string | React.ReactElement
  className?: string
}
export function RowHeaderCell({ title, style, className }: RowHeaderCellProps) {
  return (
    <RowHeaderContainer style={style} className={className}>
      {title}
    </RowHeaderContainer>
  )
}
const RowHeaderContainer = styled.div`
  background: ${props => props.theme['@background-color-base']};
  padding: ${props => props.theme['@size-s']};
  align-items: center;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme['@font-size-sm']};
`

/**
 * Assignment Header
 */
export function AssignmentHeaderCell({ columnIndex, style }: GridChildComponentProps) {
  const { assignments, hasGradeColumn, cls } = useClassGradesTableContext(true)
  const assignment = assignments[columnIndex - getColumnOffset(hasGradeColumn)]
  return assignment ? (
    <AssignmentHeader assignment={assignment} classId={cls?.id} style={style} />
  ) : null
}

/**
 * Assignment Header - Class Average
 */
export function AssignmentAverageHeaderCell({ columnIndex, style }: GridChildComponentProps) {
  const { assignments, hasGradeColumn } = useClassGradesTableContext(true)
  const assignment = assignments[columnIndex - getColumnOffset(hasGradeColumn)]
  return assignment ? (
    <AssignmentAverageHeaderContainer
      style={style}
      className={'grid-border-right grid-border-bottom'}
    >
      <AssignmentAverageCell assignment={assignment} />
    </AssignmentAverageHeaderContainer>
  ) : null
}
const AssignmentAverageHeaderContainer = styled.div`
  background: ${props => props.theme['@background-color-base']};
  align-items: center;
  display: flex;
  justify-content: center;
`

/**
 * Class Average Grade Cell
 */
export function ClassAverageCell(props: GridChildComponentProps) {
  const { cls } = useClassGradesTableContext(true)
  const averageGrade =
    cls?.averageGrade !== null && cls?.averageGrade !== undefined ? cls.averageGrade : undefined
  return (
    <RowHeaderCell
      {...props}
      className={'grid-border-right grid-border-bottom'}
      title={
        averageGrade !== undefined && averageGrade !== null
          ? Format.number(averageGrade, 'decimalPercentage')
          : ''
      }
    />
  )
}

/**
 * Student Name Header Cell
 */
export function StudentNameHeaderCell(props: GridChildComponentProps) {
  const { rowIndex } = props
  const { students, studentLink } = useClassGradesTableContext(true)
  const student = students[rowIndex - ROW_OFFSET]
  return React.useMemo(() => {
    if (student?.node?.id) {
      const contents = studentLink ? (
        <Link to={studentLink(student.classId, student.node.id)}>
          {student.node.user.name || student.node.user.username}
        </Link>
      ) : (
        student.node.user.name || student.node.user.username
      )
      return <RowHeaderCell className={'grid-border-bottom'} title={contents} {...props} />
    } else {
      return null
    }
  }, [student?.node?.id, studentLink, student?.classId, props.style?.top, props.style?.left])
}

/**
 * Student Header - Class Average Grade
 */
export function StudentAverageGradeCell(props: GridChildComponentProps) {
  const { rowIndex } = props
  const { students } = useClassGradesTableContext(true)
  const student = students[rowIndex - ROW_OFFSET]
  const averageGrade =
    student?.grade !== undefined && student?.grade !== null ? student.grade : undefined
  return (
    <RowHeaderCell
      {...props}
      className={'grid-border-right grid-border-bottom'}
      title={averageGrade !== undefined ? Format.number(averageGrade, 'decimalPercentage') : ''}
    />
  )
}

/**
 * Finally... the actual assignment student cell (cell)
 */
export function AssignmentStudentCellCell({
  rowIndex,
  columnIndex,
  style,
}: GridChildComponentProps) {
  const { assignments, students, hasGradeColumn } = useClassGradesTableContext(true)
  const student = students[rowIndex - ROW_OFFSET]
  const assignment = assignments[columnIndex - getColumnOffset(hasGradeColumn)]
  const submission =
    student?.node?.id && assignment && isAssignmentWithSubmissions(assignment)
      ? assignment?.submissions?.edges.find(edge => edge.node.studentId === student.node!.id)?.node
      : undefined
  const studentId = student?.node?.id
  const classId = student?.classId || submission?.classId

  return (
    <div style={style} className={'grid-border-right grid-border-bottom'}>
      <AssignmentLoadingCell isFull={assignment && isAssignmentWithSubmissions(assignment)}>
        {studentId && classId && assignment && isAssignmentWithSubmissions(assignment) ? (
          <AssignmentStudentCell
            studentId={studentId}
            classId={classId}
            assignment={assignment}
            submission={submission}
          />
        ) : null}
      </AssignmentLoadingCell>
    </div>
  )
}
