import { SettingOutlined } from '@ant-design/icons'
import {
  QuestionProps,
  QuestionInstructions,
  QuestionAdditionalFields,
} from '@thesisedu/feature-assignments-react'
import { TuneMeter, TuneItem } from '@thesisedu/feature-sheet-music-core'
import { HSpaced, Space, Block } from '@thesisedu/web'
import { renderAbc } from 'abcjs'
import { Alert, Button } from 'antd'
import React from 'react'

import { CustomGroupsEdit } from './CustomGroupsEdit'
import { SheetMusicDragDropOptionsDrawer } from './SheetMusicDragDropOptionsDrawer'
import { SheetMusicDragDropConfig } from './types'
import { SimpleSheetMusicEditor } from '../../../editor/SimpleSheetMusicEditor'
import { useHasValue } from '../../../editor/useHasValue'

export function SheetMusicDragDropEdit(props: QuestionProps<SheetMusicDragDropConfig>) {
  const [visible, setVisible] = React.useState(false)
  const configRef = React.useRef<SheetMusicDragDropConfig | undefined>(undefined)
  const hasValue = useHasValue(props.question.config?.answer || '')
  React.useEffect(() => {
    configRef.current = props.question.config
  }, [props.question.config])
  return (
    <>
      <SheetMusicDragDropOptionsDrawer
        visible={visible}
        onClose={() => setVisible(false)}
        value={props.question.config || {}}
        onChange={v => {
          if (props.onChange) {
            props.onChange({
              config: {
                ...configRef.current,
                ...v,
              },
            })
          }
        }}
      />
      <QuestionInstructions
        instructions={'Configure the sheet music answer and optional draggables below.'}
        padded
      >
        <HSpaced align={'flex-start'} style={{ width: '100%' }}>
          <Space />
          <Button icon={<SettingOutlined />} onClick={() => setVisible(true)} />
        </HSpaced>
        {hasValue ? null : (
          <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
            <Alert
              message={
                'This question will not be automatically graded unless you provide an answer using the compose tool below.'
              }
              type={'warning'}
              showIcon
            />
          </Block>
        )}
        <SimpleSheetMusicEditor
          value={props.question.config?.answer || ''}
          onChange={answer => {
            if (props.onChange) {
              let meter: TuneMeter | undefined = undefined
              if (answer) {
                try {
                  const rendered = renderAbc('*', answer) as TuneItem[]
                  meter = rendered[0]?.meter
                } catch {}
              }
              props.onChange({
                config: {
                  ...configRef.current,
                  meter,
                  answer,
                },
              })
            }
          }}
          topControls={
            <CustomGroupsEdit
              value={props.question.config?.customGroups}
              onChange={customGroups => {
                if (props.onChange) {
                  props.onChange({
                    config: {
                      ...configRef.current,
                      customGroups,
                    },
                  })
                }
              }}
            />
          }
        />
      </QuestionInstructions>
      <QuestionAdditionalFields {...(props as any)} />
    </>
  )
}
