import { debug } from '../log'

function limit(fn: Function, delay: number) {
  let pending = false
  return function (this: any, ...args: any[]) {
    if (pending) return
    pending = true
    fn.apply(this, args)
    setTimeout(() => {
      pending = false
    }, delay)
  }
}

function normalize(s: string): string {
  return s.replace(/\s/g, '')
}

let intervalTimer: any

export interface SetupOptions {
  checkInterval?: number
  checkOnWindowFocus?: boolean
  checkImmediately?: boolean
  checkOnLoadFileError?: boolean
}
export function setupCheckForUpdates(
  _release: string,
  onUpdateAvailable: () => void,
  {
    checkInterval = 10 * 60 * 1000,
    checkOnWindowFocus = true,
    checkImmediately,
    checkOnLoadFileError = true,
  }: SetupOptions = {},
) {
  const release = normalize(_release)
  clearInterval(intervalTimer)
  const checkForUpdate = () => {
    fetch(`/release.txt?t=${performance.now()}`)
      .then(response => {
        if (!response.ok) throw new Error('Failed to fetch latest release')
        return response.text()
      })
      .then(_latestRelease => {
        const latestRelease = normalize(_latestRelease)
        if (latestRelease !== release) {
          onUpdateAvailable()
        }
      })
      .catch(err => {
        debug('error checking for updates: %O', err)
      })
  }
  const limitedCheckForUpdate = limit(checkForUpdate, 5000)

  if (checkImmediately) {
    setTimeout(checkForUpdate)
  }

  const setupPolling = () => {
    if (checkInterval > 0) {
      intervalTimer = setInterval(checkForUpdate, checkInterval)
    }
  }
  setupPolling()

  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      setupPolling()
      if (checkOnWindowFocus) limitedCheckForUpdate()
    } else if (document.visibilityState === 'hidden') {
      clearInterval(intervalTimer)
    }
  })

  window.addEventListener('focus', () => {
    if (checkOnWindowFocus) limitedCheckForUpdate()
  })

  if (checkOnLoadFileError) {
    window.addEventListener(
      'error',
      err => {
        const errTagName = (err?.target as any)?.tagName
        if (errTagName === 'SCRIPT') {
          limitedCheckForUpdate()
        }
      },
      true,
    )
  }
}
