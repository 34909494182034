import { EditOutlined } from '@ant-design/icons'
import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { Empty } from '@thesisedu/react'
import { Block, LoadingIndicator } from '@thesisedu/ui'
import { InfiniteScrollerProps } from '@thesisedu/web'
import { Menu } from 'antd'
import { set } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { CustomSegment, CustomSegmentProps } from './CustomSegment'
import { DeleteCustomSegment } from './DeleteCustomSegment'
import {
  ListSegmentsDocument,
  ListSegmentsQuery,
  ListSegmentsQueryVariables,
  BuiltSegmentFragment,
} from '../schema'
import { CreateSegmentButton } from '../segment/CreateSegmentButton'
import { SegmentFilterValues, SegmentUserFilter } from '../segment/SegmentFilters'

export interface WrapItemsProps {
  children: React.ReactNode
  items: BuiltSegmentFragment[]
}
export interface CustomSegmentListProps {
  filters: SegmentFilterValues
  renderSegment?: (props: CustomSegmentProps) => React.ReactElement
  wrapItems?: (props: WrapItemsProps) => React.ReactElement
  hideCreate?: boolean
  infiniteScrollerProps?: Partial<InfiniteScrollerProps>
}
export function CustomSegmentList({
  filters,
  renderSegment: RenderSegment,
  wrapItems: WrapItems,
  hideCreate,
  infiniteScrollerProps,
}: CustomSegmentListProps) {
  const viewer = useViewerContext(true)
  const [dFilters] = useDebounce(filters, 500)
  const navigate = useNavigate()
  const variables = {
    userIds: dFilters.user === SegmentUserFilter.Me ? [viewer.id] : [],
    types: dFilters.type ? [dFilters.type] : [],
    name: dFilters.name,
    tags: dFilters.tags,
    tagsOp: dFilters.tagsOp,
  }

  return (
    <InfiniteQuery<BuiltSegmentFragment, ListSegmentsQuery, ListSegmentsQueryVariables>
      document={ListSegmentsDocument}
      getResult={r => r?.segments}
      setResult={(p, r) => set(cloneDeep(p), 'segments', r)}
      variables={variables}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      infiniteScrollerProps={infiniteScrollerProps}
      children={({ data, loading }) => {
        if (data?.segments.edges.length) {
          const items = data?.segments.edges.map(edge => edge.node)
          const children = items?.map(node => {
            const { id } = fromGlobalId(node.id, true)
            const props: CustomSegmentProps = {
              segment: { ...node, config: node.config || {} },
              rawId: id,
              useInClass: true,
              onClick: () => {
                navigate(node.id)
              },
              actionMenuItems: [
                <Menu.Item
                  key={'edit'}
                  onClick={() => {
                    navigate(node.id)
                  }}
                  icon={<EditOutlined />}
                >
                  Edit
                </Menu.Item>,
                <DeleteCustomSegment key={'delete'} apiId={node.id} />,
              ],
            }
            return RenderSegment ? <RenderSegment {...props} /> : <CustomSegment {...props} />
          })
          return WrapItems ? <WrapItems children={children} items={items} /> : <>{children}</>
        } else if (loading) {
          return <LoadingIndicator.Labeled label={'Loading content...'} />
        } else {
          return (
            <Block top={'l'}>
              <Empty
                description={"You don't have any custom content."}
                action={hideCreate ? undefined : <CreateSegmentButton />}
              />
            </Block>
          )
        }
      }}
    />
  )
}
