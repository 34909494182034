import { useResource } from '@thesisedu/feature-react'
import { BodyLarger, Block, styledContainer as styled, Button } from '@thesisedu/react'
import { Packages } from '@thesisedu/react/icons'
import React from 'react'

import { LargeConfigurationButton } from './LargeConfigurationButton'
import { LargeDimensionButton } from './LargeDimensionButton'
import { LargeMetricButton } from './LargeMetricButton'
import { LargeReportInputSummary } from './LargeReportInputSummary'
import { useDimensionsAtLevel } from './resources'
import { packOpts } from './storage'
import { ReportMetricInput } from '../../schema'
import { dimensionsSupportMultipleMetrics, getInputIdentifier } from '../execute/input'
import { NoneDimensionInput } from '../execute/types'
import {
  ReportDimensionResource,
  ReportMetricResource,
  RunReportOpts,
  SelectedDimension,
} from '../types'

function scrollBottom() {
  setTimeout(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
  }, 100)
}

export interface LargeConfigurationProps {
  onRedirect: (compressed: string) => void
  onChange?: (config: RunReportOpts) => void
}
export function LargeConfiguration({ onRedirect, onChange }: LargeConfigurationProps) {
  const dimensionResources = useResource<ReportDimensionResource<object, object>>('ReportDimension')
  const [firstDimension, setFirstDimension] = React.useState<SelectedDimension | null>(null)
  const firstDimensionResource = dimensionResources.find(
    r => r.identifier === firstDimension?.identifier,
  )
  const [secondDimension, setSecondDimension] = React.useState<
    SelectedDimension | NoneDimensionInput | null
  >(null)
  const allDimensionSelections = [
    firstDimension,
    secondDimension === 'none' ? null : secondDimension,
  ].filter(Boolean) as SelectedDimension[]
  const [metric, setMetric] = React.useState<ReportMetricInput | null>(null)
  const metricIdentifier = metric ? getInputIdentifier(metric) : null
  const showSelectMetric =
    secondDimension && !dimensionsSupportMultipleMetrics(dimensionResources, allDimensionSelections)
  React.useEffect(() => {
    if (!showSelectMetric) {
      setMetric(null)
    }
  }, [showSelectMetric])
  const firstLevelDimensions = useDimensionsAtLevel(0)
  const secondLevelDimensions = useDimensionsAtLevel(1)
  const metricResources = useResource<ReportMetricResource>('ReportMetric')
  React.useEffect(() => {
    if (onChange) {
      onChange({
        dimensions: allDimensionSelections,
        metrics: metric ? [metric] : [],
      })
    }
  }, [allDimensionSelections, metric])

  return (
    <Container>
      <BodyLarger>Who should be included in this report?</BodyLarger>
      <Block marginTop={'@size-m'} marginBottom={'@size-xxl'}>
        <ItemContainer>
          {firstLevelDimensions.map(dimension => {
            const selection =
              firstDimension?.identifier === dimension.identifier ? firstDimension : undefined
            return (
              <LargeDimensionButton
                key={dimension.identifier}
                dimension={dimension}
                options={selection?.options}
                context={selection?.context}
                selected={!!selection}
                onChange={(options, context, identifier?: string) => {
                  setFirstDimension({
                    identifier: identifier || dimension.identifier,
                    options,
                    context,
                  })
                  if (!firstDimension) {
                    scrollBottom()
                  }
                }}
              />
            )
          })}
        </ItemContainer>
      </Block>
      {firstDimension && firstDimensionResource ? (
        <>
          <BodyLarger>How do you want it reported?</BodyLarger>
          <Block marginTop={'@size-m'} marginBottom={'@size-xxl'}>
            <ItemContainer>
              {secondLevelDimensions.map(dimension => {
                const selection =
                  secondDimension !== 'none' && secondDimension?.identifier === dimension.identifier
                    ? secondDimension
                    : undefined
                return (
                  <LargeDimensionButton
                    key={dimension.identifier}
                    dimension={dimension}
                    options={selection?.options}
                    context={selection?.context}
                    onChange={(options, context) => {
                      setSecondDimension({ identifier: dimension.identifier, options, context })
                      if (!secondDimension) {
                        scrollBottom()
                      }
                    }}
                    selected={!!selection}
                    shortDescription={
                      firstDimensionResource.shortLabel
                        ? firstDimensionResource.shortLabel(
                            firstDimension.options,
                            firstDimension.context,
                          )
                        : ''
                    }
                  />
                )
              })}
              <LargeConfigurationButton
                icon={<Packages />}
                title={'In General'}
                description={'Compare SHORTDESC generically.'}
                onClick={() => {
                  setSecondDimension('none')
                  if (!secondDimension) {
                    scrollBottom()
                  }
                }}
                selected={secondDimension === 'none'}
                shortDescription={
                  firstDimensionResource.shortLabel
                    ? firstDimensionResource.shortLabel(
                        firstDimension.options,
                        firstDimension.context,
                      )
                    : ''
                }
              />
            </ItemContainer>
          </Block>
        </>
      ) : null}
      {showSelectMetric ? (
        <>
          <BodyLarger>What do you want to see?</BodyLarger>
          <Block marginTop={'@size-m'} marginBottom={'@size-xxl'}>
            <ItemContainer>
              {metricResources.map(resource => (
                <LargeMetricButton
                  key={resource.identifier}
                  metric={resource}
                  value={metricIdentifier === resource.identifier ? metric : null}
                  onChange={m => {
                    setMetric(m)
                    if (!metric) {
                      scrollBottom()
                    }
                  }}
                />
              ))}
            </ItemContainer>
          </Block>
        </>
      ) : null}
      {(showSelectMetric && metric) || (!showSelectMetric && secondDimension) ? (
        <>
          <LargeReportInputSummary
            metric={metric || undefined}
            dimensions={allDimensionSelections}
          />
          <Block marginTop={'@size-l'} marginBottom={'@size-xxl'}>
            <Button
              size={'large'}
              shape={'round'}
              type={'primary'}
              children={'Run Report'}
              onClick={() => {
                packOpts({
                  dimensions: allDimensionSelections,
                  metrics: metric ? [metric] : [],
                }).then(packed => {
                  onRedirect(packed)
                })
              }}
            />
          </Block>
        </>
      ) : null}
    </Container>
  )
}

const Container = styled`
  text-align: center;
`
const ItemContainer = styled`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  > * {
    margin: calc(${props => props.theme['@size-l']} / 2);
  }
`
