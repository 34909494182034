import { useViewerContext } from '@thesisedu/feature-users-web'
import { Checkbox, Form, Input } from 'antd'
import { FormProps } from 'antd/es/form'
import React, { useEffect } from 'react'

import { AssignmentSubmissionTypeSelect } from './AssignmentSubmissionTypeSelect'
import { CalculationField } from './CalculationField'
import { AssignmentTemplateFragment, SubmissionType } from '../schema'

export interface EditAssignmentFormProps {
  disableInput?: boolean
  hideName?: boolean
  value?: AssignmentTemplateFragment
  form: FormProps['form']
}
export function EditAssignmentForm({
  form,
  disableInput,
  hideName,
  value,
}: EditAssignmentFormProps) {
  const viewer = useViewerContext(true)
  const isAdmin = viewer.group === 'ADMIN'
  useEffect(() => {
    form?.setFieldsValue({
      assignment: value,
    })
  }, [value])
  return (
    <>
      {hideName ? null : (
        <Form.Item
          name={['assignment', 'name']}
          label={'Assignment Name'}
          initialValue={value?.name}
          rules={[{ required: true, message: 'Assignment name is required.' }]}
        >
          <Input disabled={disableInput} />
        </Form.Item>
      )}
      <Form.Item
        name={['assignment', 'defaults', 'submissionType']}
        label={'Assignment Type'}
        rules={[{ required: true, message: 'An assignment type is required.' }]}
        initialValue={value?.defaults?.submissionType || SubmissionType.Individual}
      >
        <AssignmentSubmissionTypeSelect disabled={disableInput} />
      </Form.Item>
      {isAdmin ? (
        <>
          <Form.Item
            name={['assignment', 'configuration', 'calculation']}
            extra={"Whether or not this assignment's questions are calculated (quiz vault)."}
            initialValue={value?.configuration?.calculation}
          >
            <CalculationField />
          </Form.Item>
          <Form.Item
            name={['assignment', 'configuration', 'isRandomized']}
            extra={
              "Whether or not this assignment's questions are randomized for all students by default."
            }
            initialValue={value?.configuration?.isRandomized}
            valuePropName={'checked'}
          >
            <Checkbox>Randomize Questions</Checkbox>
          </Form.Item>
          <Form.Item
            name={['assignment', 'configuration', 'disableRandomization']}
            extra={
              'If this is checked, questions in this assignment will never be randomized, even if the assignment is configured to randomize.'
            }
            initialValue={value?.configuration?.disableRandomization}
            valuePropName={'checked'}
          >
            <Checkbox>Disable Randomization</Checkbox>
          </Form.Item>
        </>
      ) : null}
    </>
  )
}
