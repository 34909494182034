import { Dropdown } from '@thesisedu/ui'
import { NavArrowDown } from '@thesisedu/ui/icons'
import React from 'react'

import { ForceSyncAction } from './actions/ForceSyncAction'
import { SyncAssignmentActionItem } from '../useSyncAssignmentActions'

export interface SyncAssignmentItemActionsDropdownProps {
  item: SyncAssignmentActionItem
}
export function SyncAssignmentItemActionsDropdown({
  item,
}: SyncAssignmentItemActionsDropdownProps) {
  if (!item.sync?.__typename) return null
  return (
    <Dropdown.Container>
      <Dropdown.Button rightDecorator={null} icon={<NavArrowDown />} size={'small'} />
      <Dropdown.Menu align={'end'}>
        <ForceSyncAction id={item.sync.id} typename={item.sync.__typename} />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
