import { QuestionAssignmentSubmissionViewerOptionsHook } from '@thesisedu/feature-assignments-react'
import { FeatureReactRoot } from '@thesisedu/feature-react'

import { ClassAssignmentViewerExtraContent } from './ClassAssignmentViewerExtraContent'

export function registerHooks(root: FeatureReactRoot<any>) {
  root.deps.hookManager.registerMutateHook<QuestionAssignmentSubmissionViewerOptionsHook>(
    'feature-assignments-react:question-assignment-submission-viewer-options',
    opts => {
      return {
        ...opts,
        HeaderComponents: [...(opts.HeaderComponents || []), ClassAssignmentViewerExtraContent],
      }
    },
  )
}
