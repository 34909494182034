import { Question } from '@thesisedu/feature-assignments-core'
import {
  EditorValue,
  getDefaultEditorValue,
  WeightedWidget,
  Legacy,
} from '@thesisedu/feature-widgets-core'
import { orderBy } from 'lodash'

import { QuestionWidget } from '../resources/Widgets/Question/common'

export function getCombinedItems(
  questions: Question[],
  widgets?: Legacy.WeightedAnyWidget[],
): EditorValue {
  const items = [
    ...(widgets || []).flatMap(widget => {
      return Legacy.convertWidget(widget)
    }),
    ...questions.map<WeightedWidget<QuestionWidget>>(question => ({
      type: 'Question',
      id: question.id,
      weight: question.weight || 0,
      version: 1,
    })),
  ]
  const children = orderBy(items, 'weight', 'asc')
  return getDefaultEditorValue(children)
}
