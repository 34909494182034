import { SPACE, TARGET_LINE_HALF_HEIGHT } from './constants'

let targetLine: HTMLElement | null = null

export type BeforeAfter = -1 | 1
export function getBeforeAfter(
  clientY: number,
  { top: targetBlockElementTop, height: targetBlockElementHeight }: DOMRect,
): BeforeAfter {
  return clientY - targetBlockElementTop > targetBlockElementHeight / 2 ? 1 : -1
}

export function setTargetLine(
  targetLineElement: HTMLElement,
  targetBlockElement: HTMLElement,
  targetBlockElementRect: DOMRect,
  beforeAfter: BeforeAfter,
  anchorElement: HTMLElement,
) {
  const targetStyle = window.getComputedStyle(targetBlockElement)
  const {
    top: targetBlockElementTop,
    height: targetBlockElementHeight,
    width: targetBlockElementWidth,
    left: targetBlockElementLeft,
  } = targetBlockElementRect
  const { top: anchorTop, left: anchorLeft } = anchorElement.getBoundingClientRect()

  let lineTop = targetBlockElementTop
  // At the bottom of the target
  if (beforeAfter === 1) {
    lineTop += targetBlockElementHeight + parseFloat(targetStyle.marginBottom)
  } else {
    lineTop -= parseFloat(targetStyle.marginTop)
  }

  const top = lineTop - anchorTop - TARGET_LINE_HALF_HEIGHT
  const left = targetBlockElementLeft - SPACE - anchorLeft

  targetLineElement.style.transform = `translate(${left}px, ${top}px)`
  targetLineElement.style.width = `${targetBlockElementWidth + SPACE * 2}px`
  targetLineElement.style.opacity = '.4'

  targetLine = targetLineElement
}

export function hideTargetLine(targetLineElement: HTMLElement | null = targetLine) {
  if (targetLineElement) {
    // Global cache so other plugins can call this one.
    targetLine = targetLineElement
    targetLineElement.style.opacity = '0'
    targetLineElement.style.transform = 'translate(-10000px, -10000px)'
  }
}
