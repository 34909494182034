import { FORMAT_TEXT_COMMAND, LexicalEditor, TextFormatType } from 'lexical'
import React from 'react'

import { TextFormatToolbarButton, TextFormatToolbarButtonProps } from './TextFormatToolbarButton'
import { useTextFormatToolbarItem } from './TextFormatToolbarContext'

export interface BasicTextFormatToolbarButtonProps
  extends Omit<TextFormatToolbarButtonProps, 'onClick'> {
  command: TextFormatType
  editor: LexicalEditor
}
export function BasicTextFormatToolbarButton({
  command,
  editor,
  ...rest
}: BasicTextFormatToolbarButtonProps) {
  return (
    <TextFormatToolbarButton
      {...rest}
      onClick={() => {
        editor.dispatchCommand(FORMAT_TEXT_COMMAND, command)
      }}
    />
  )
}

export interface BasicTextFormatToolbarItemOpts {
  command: TextFormatType
  icon: React.ReactElement
  label: string
  ariaLabel: string
  weight?: number
  group?: string
}
export function useBasicTextFormatToolbarItem({
  command,
  icon,
  weight,
  group,
  label,
  ariaLabel,
}: BasicTextFormatToolbarItemOpts) {
  useTextFormatToolbarItem({
    identifier: command,
    weight,
    group,
    element: props => (
      <BasicTextFormatToolbarButton
        {...props}
        label={label}
        ariaLabel={ariaLabel}
        command={command}
        icon={icon}
      />
    ),
    getActive(editor, selection) {
      return selection.hasFormat(command)
    },
  })
}
