import * as React from 'react'

function SvgNoteB(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 256'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <circle cx={128} cy={128} r={128} fill={'#FFF'} fillRule={'nonzero'} />
        <path
          fill={'#EC6CA4'}
          fillRule={'nonzero'}
          stroke={'#512D1C'}
          strokeWidth={2.118}
          d={
            'M68 61.977c16.834-3.211 30.727-5.361 41.68-6.449A254.918 254.918 0 01135.55 54a83.937 83.937 0 0117.024 1.71 75.007 75.007 0 0112.03 6.733 46.589 46.589 0 018.874 8.443 23.864 23.864 0 015.174 11.835 28.072 28.072 0 01-2.587 15.176 60.903 60.903 0 01-14.617 18.672c10.038 5.18 17.178 10.834 21.421 16.963a35.785 35.785 0 017.012 17.844 37.171 37.171 0 01-2.587 16.885 69.79 69.79 0 01-7.762 14.166 113.346 113.346 0 01-11.746 13.467 24.549 24.549 0 01-2.432 1.528 42.79 42.79 0 01-8.796 3.237 112.13 112.13 0 01-17.464 3.134 239.204 239.204 0 01-28.458 1.165 398.03 398.03 0 01-41.395-2.77L68 61.976zm73.786 88.777a13.268 13.268 0 00-1.604-4.195 9.42 9.42 0 00-4.398-3.859 16.49 16.49 0 00-8.512-.906 44.934 44.934 0 00-14.074 4.661l2.328 18.854c5.094.728 10.28.446 15.265-.829a18.106 18.106 0 007.761-4.402 14.12 14.12 0 003.312-7.252s.103-.777-.078-2.072zM131.437 96.11a7.268 7.268 0 00-4.941-2.59 21.92 21.92 0 00-10.737 2.253l-.724 18.828c3.044.686 6.229.34 9.055-.984a15.526 15.526 0 005.33-4.299 22.465 22.465 0 003.13-7.096 9.616 9.616 0 00-1.164-6.034l.051-.078z'
          }
        />
        <path
          fill={'#EC6BA2'}
          d={
            'M128 10.03c-31.511 0-61.137 12.27-83.418 34.552C22.3 66.864 10.03 96.49 10.03 128c0 31.51 12.271 61.136 34.553 83.418C66.863 233.699 96.49 245.97 128 245.97c31.51 0 61.136-12.272 83.417-34.553C233.7 189.136 245.971 159.51 245.971 128c0-31.511-12.272-61.136-34.554-83.418C189.137 22.3 159.511 10.03 128 10.03M128 256c-34.19 0-66.334-13.315-90.51-37.49C13.314 194.332 0 162.19 0 128c0-34.19 13.314-66.334 37.49-90.51C61.666 13.314 93.81 0 128 0c34.19 0 66.334 13.314 90.51 37.49C242.684 61.666 256 93.81 256 128c0 34.19-13.315 66.333-37.49 90.51C194.333 242.684 162.19 256 128 256'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteB = React.memo(SvgNoteB)
export default MemoSvgNoteB
