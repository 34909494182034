import { useFeatureRoot } from '@thesisedu/feature-react'
import { Button } from '@thesisedu/ui'
import { CloudUpload } from '@thesisedu/ui/icons'
import React from 'react'

export interface ConfirmFooterButtonProps {
  onUpload: () => void
}
export function ConfirmFooterButton({ onUpload }: ConfirmFooterButtonProps) {
  const { appOptions } = useFeatureRoot()
  return (
    <Button
      icon={<CloudUpload />}
      size={'small'}
      variant={'primary'}
      children={`Upload to ${appOptions.name}`}
      onPress={() => {
        onUpload()
      }}
    />
  )
}
