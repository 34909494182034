import { RequestResetPasswordForm, useProtection } from '@thesisedu/feature-users-web'
import { Block, Body, H1, H1Subtitle, Link } from '@thesisedu/react'
import { ArrowLeft } from '@thesisedu/react/icons'
import React from 'react'

export function RequestPasswordReset() {
  useProtection({ allowedGroups: [], redirect: '/' })
  return (
    <>
      <H1 isBlock={false}>Reset Password</H1>
      <Block marginBottom={'@size-l'}>
        <H1Subtitle isBlock={false}>
          Enter your email address or username below to reset your password.
        </H1Subtitle>
      </Block>
      <RequestResetPasswordForm />
      <Block marginTop={'@size-l'}>
        <Link to={'../login'}>
          <Body color={'@text-color-secondary'}>
            <ArrowLeft /> Go Back
          </Body>
        </Link>
      </Block>
    </>
  )
}
