import { useFeatureRoot } from '@thesisedu/feature-react'
import { Alert } from 'antd'
import moment from 'moment'
import React from 'react'

import { useViewerLicenseQuery } from './schema'

export const TrialBanner: React.FC<React.PropsWithChildren<unknown>> = () => {
  const root = useFeatureRoot()
  const { data } = useViewerLicenseQuery()
  const licenseExpiresAt = data?.viewer?.teacher?.site?.license?.expiresAt
  const licenseType = data?.viewer?.teacher?.site?.license?.type
  if (licenseType === 'TRIAL' && licenseExpiresAt) {
    const hasExpired = moment().isAfter(licenseExpiresAt)
    const trialExpirationDate = moment(licenseExpiresAt).fromNow()
    return (
      <Alert
        banner
        type={hasExpired ? 'error' : 'info'}
        message={
          hasExpired ? (
            <span>
              Thanks for trying {root.appOptions.name}! Your trial has <strong>expired</strong>.
              Purchase a license by{' '}
              <a
                href={`mailto:sales@@${root.appOptions.domain}`}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                contacting sales
              </a>
            </span>
          ) : (
            <span>
              Thanks for trying {root.appOptions.name}! Your trial will expire{' '}
              <strong>{trialExpirationDate}</strong>. Purchase a full license by{' '}
              <a
                href={`mailto:sales@${root.appOptions.domain}`}
                target={'_blank'}
                rel={'nofollow noreferrer'}
              >
                contacting sales
              </a>
            </span>
          )
        }
      />
    )
  }
  return null
}
