import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { GridTagFilter } from './grid'
import { ExploreGridFiltersHook } from '../types'

const DEFAULT_FILTERS = [
  <GridTagFilter key={'lesson'} label={'Lesson'} tagType={'IMPLEMENTATION_GUIDE'} />,
  <GridTagFilter key={'topic'} label={'Topic'} tagType={'TOPIC'} />,
  <GridTagFilter key={'content-area'} label={'Content Area'} tagType={'CONTENT_AREA'} />,
]

export function useExploreGridFilters() {
  return useMutateHook<ExploreGridFiltersHook>(
    'courses-react:explore-grid-filters',
    DEFAULT_FILTERS,
    undefined,
  )
}
