import { styled, s } from '@thesisedu/ui'
import { ArrowSeparate, FastArrowLeft, FastArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { PlacementArrowDirection } from '../PlacementSplitView'

export interface CenterPaneProps {
  arrowDirection: PlacementArrowDirection
}
export function CenterPane({ arrowDirection }: CenterPaneProps) {
  let icon = <FastArrowRight />
  if (arrowDirection === PlacementArrowDirection.Left) icon = <FastArrowLeft />
  if (arrowDirection === PlacementArrowDirection.LeftRight) icon = <ArrowSeparate />
  return (
    <Center>
      <CenterIcon children={icon} />
      <CenterIcon children={icon} />
      <CenterIcon children={icon} />
    </Center>
  )
}

const Center = styled.div`
  grid-area: center;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  &::before {
    content: '';
    position: absolute;
    top: ${s.size('m')};
    bottom: 0;
    left: calc(50% - 1px);
    width: 2px;
    background: ${s.color('subtleBorder')};
    z-index: -1;
  }
`
const CenterIcon = styled.div`
  padding: ${s.size('s')} ${s.size('xs')};
  background: ${s.color('background')};
  border-radius: ${s.var('radii.1')};
  color: ${s.color('secondary')};
  font-size: ${s.size('font.s')};
`
