import { getSegmentName } from '@thesisedu/feature-course-types'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Format, toGlobalId } from '@thesisedu/feature-utils'
import { CapitalSmall, styled } from '@thesisedu/web'
import React from 'react'

import { SegmentLink } from './SegmentLink'
import { SegmentNotInLicense } from './SegmentNotInLicense'
import { useCourseEditorSegmentNameContext } from '../contexts/CourseEditorContext'
import { Segment } from '../types'

export const formatDays = (days: number) => {
  if (isNaN(days)) return null
  return Format.number(days, 'decimal')
}

type RequiredKeys = 'name' | 'id'
export interface SegmentNameProps {
  segment: Partial<Omit<Segment, RequiredKeys>> & Pick<Segment, RequiredKeys>
  onClick?: () => void
  days?: number
  onWeek?: number
  onDay?: number
  isCompleted?: boolean
  isTeacherInfo?: boolean
  linkToSegment?: boolean
  disabled?: boolean
  breakBeforeName?: boolean
}
export function SegmentName(props: SegmentNameProps) {
  const {
    segment,
    onClick,
    days,
    onWeek,
    onDay,
    isCompleted,
    isTeacherInfo,
    linkToSegment,
    disabled,
    breakBeforeName,
  } = props
  const viewer = useViewerContext(true)
  const editorContext = useCourseEditorSegmentNameContext(false)
  const displayType = segment.config?.label || segment.type
  const fDays = days !== undefined ? formatDays(days) : undefined
  const content = (
    <Container
      className={'segment-name'}
      data-testid={'SegmentName'}
      notInLicense={segment?.notInLicense}
    >
      {isCompleted ? <CapitalSmall color={'@green'}>Complete&nbsp;</CapitalSmall> : null}
      {isTeacherInfo ? <CapitalSmall color={'@blue'}>Teacher Only&nbsp;</CapitalSmall> : null}
      {displayType ? <strong>{displayType}: </strong> : null}
      {segment.notInLicense && breakBeforeName ? <SegmentNotInLicense /> : null}
      {breakBeforeName ? <br /> : null}
      <>{getSegmentName(segment)}</>
      {disabled && <span className={'disabled'}>Disabled</span>}
      {segment.config?.isHidden && viewer?.group === 'ADMIN' && (
        <span className={'disabled'}>Hidden</span>
      )}
      {segment.notInLicense && !breakBeforeName ? <SegmentNotInLicense /> : null}
      {!segment.config?.isHidden &&
      (onWeek !== undefined || onDay !== undefined || days !== undefined) ? (
        <span className={'days'}>
          {onWeek !== undefined && `Week ${onWeek}, `}
          {onDay !== undefined && `day ${onDay} - `}
          {days !== undefined && `${fDays} day${days !== 1 ? 's' : ''} long`}
        </span>
      ) : null}
    </Container>
  )

  if (editorContext?.renderSegmentName) {
    return editorContext.renderSegmentName(props, content)
  } else if (onClick && !segment?.notInLicense) {
    return (
      <a
        className={'edit-link'}
        onClick={e => {
          if (!e.ctrlKey && !e.metaKey) {
            e.preventDefault()
            onClick()
          }
        }}
        target={'_blank'}
        rel={'noopener noreferrer'}
        href={`${window.location.pathname}/${toGlobalId('Segment', props.segment.id)}`}
      >
        {content}
      </a>
    )
  } else if (linkToSegment && !segment?.notInLicense) {
    return (
      <SegmentLink className={'edit-link'} id={segment.id}>
        {content}
      </SegmentLink>
    )
  } else {
    return content
  }
}

const Container = styled.div<{ notInLicense?: boolean }>`
  display: block;
  max-width: 100%;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > * {
    vertical-align: center;
    display: inline;
    &::after {
      content: ' ';
      display: inline;
    }
  }
  .disabled {
    margin-left: ${props => props.theme['@size-xxs']};
    display: inline-block;
    color: ${props => props.theme['@gray-6']} !important;
    padding: ${props => props.theme['@size-xxs']} ${props => props.theme['@size-xs']};
    background: ${props => props.theme['@gray-2']};
    border-radius: ${props => props.theme['@border-radius-base']};
    font-size: 12px;
  }
  .days {
    margin-left: 10px;
    font-style: italic;
    font-size: 12px;
    color: ${props => props.theme['@text-color-secondary']};
  }
  svg {
    margin-left: auto;
  }
  ${props => (props.notInLicense ? '&' : '&.noop')} {
    pointer-events: none;
    * {
      color: ${props => props.theme['@text-color-secondary']} !important;
    }
  }
`
