import { findSegment, reduceSegments, Segment } from '@thesisedu/feature-course-types'
import { omit } from 'lodash'
import React, { useContext, useState } from 'react'

import {
  useCourseEditorReadContext,
  useCourseEditorStatusContext,
  useCourseEditorWriteContext,
} from './CourseEditorContext'
import { useSegmentCache } from './SegmentContext'
import { clearParentsCache, getSegmentParents } from '../helpers'
import { debug, warn } from '../log'

export interface SegmentDragDropContextValue {
  movingSegmentId?: string
  setMovingSegment: (movingSegmentId?: string) => void
  movingSegmentChildren: string[]
  moveSegmentToList: (targetParentId: string, segmentId: string) => void
  disableMove?: boolean
}
export const SegmentDragDropContext = React.createContext<SegmentDragDropContextValue | undefined>(
  undefined,
)

export interface SegmentDragDrop extends SegmentDragDropContextValue {}
export function useSegmentDragDrop(): SegmentDragDrop {
  const [movingSegmentId, setMovingSegmentId] = useState<string>()
  const [movingSegmentChildren, setMovingSegmentChildren] = useState<string[]>([])
  const segmentCache = useSegmentCache()
  const { enableReferences } = useCourseEditorStatusContext(true)
  const { courseConfiguration } = useCourseEditorReadContext(true)
  const { onSegmentChange } = useCourseEditorWriteContext(false) || {}

  const setMovingSegment: SegmentDragDropContextValue['setMovingSegment'] = segmentId => {
    if (segmentId) {
      const segment = segmentCache[segmentId]
      if (!segment) return
      setMovingSegmentChildren(
        reduceSegments<string[]>([segment], (acc, segment) => [...acc, segment.id], []),
      )
      setMovingSegmentId(segment.id)
    } else {
      setMovingSegmentId(undefined)
      setMovingSegmentChildren([])
    }
  }
  const filterChildSegments = (childSegments: Segment[]) => {
    if (enableReferences) {
      return childSegments.map(childSegment => {
        return childSegment.referenceSegment || childSegment
      })
    } else {
      return childSegments
    }
  }
  const moveSegmentToList: SegmentDragDropContextValue['moveSegmentToList'] = (
    targetParentId,
    segmentId,
  ) => {
    if (!onSegmentChange) return
    const foundSegment = segmentCache[segmentId]
    debug('moving segment', segmentId)
    const segmentParents = getSegmentParents(courseConfiguration.segments, segmentId, true)
    debug('parents', segmentParents)
    const originalParentId = segmentParents[segmentParents.length - 1]
    const originalParent = findSegment(courseConfiguration.segments, originalParentId)
    const foundParent = findSegment(courseConfiguration.segments, targetParentId)
    if (foundSegment && foundParent && originalParent) {
      onSegmentChange({
        id: originalParent.id,
        config: omit(originalParent.config, ['childSegments']), // Automatically calculate later.
        childSegments: filterChildSegments(originalParent.childSegments || []).filter(
          childSegment => childSegment.id !== segmentId,
        ),
      })
      onSegmentChange({
        id: foundParent.id,
        config: omit(foundParent.config, ['childSegments']), // Automatically calculate later.
        childSegments: filterChildSegments(foundParent.childSegments || []).concat({
          ...foundSegment,
          weight: 0,
        }),
      })
      clearParentsCache() // Reset parents because we're currently messing with them.
      setMovingSegmentId(undefined)
    } else {
      warn(`Not moving segment '${segmentId}' because we could not find it or the parent.`)
    }
  }

  return {
    setMovingSegment,
    moveSegmentToList,
    movingSegmentChildren,
    movingSegmentId,
  }
}

export function useSegmentDragDropContext(): SegmentDragDropContextValue | undefined
export function useSegmentDragDropContext(require: false): SegmentDragDropContextValue | undefined
export function useSegmentDragDropContext(require: true): SegmentDragDropContextValue
export function useSegmentDragDropContext(
  require?: boolean,
): SegmentDragDropContextValue | undefined {
  const context = useContext(SegmentDragDropContext)
  if (!context && require) {
    throw new Error('SegmentDragDropContext is required, yet not provided.')
  }
  return context
}
