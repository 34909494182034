import { Dropdown, Modal, Text, VSpaced, useToast } from '@thesisedu/ui'
import { MinusCircle, Refresh } from '@thesisedu/ui/icons'
import React from 'react'

import { useGradingModalContext } from '../contexts/GradingModalContext'
import { useGradingModalSubmissionContext } from '../contexts/GradingModalSubmissionContext'
import { FullAssignment } from '../node'
import { useResetAssignmentSubmissionsMutation } from '../queries/useResetAssignmentSubmissionsMutation'

export interface ResetSubmissionsDropdownItemsProps {
  assignmentId: string
  studentId: string
  classId?: string
  assignment?: Pick<FullAssignment, 'timeLimit'>
}
export function ResetSubmissionsDropdownItems({
  assignment: propAssignment,
  assignmentId,
  studentId,
  classId,
}: ResetSubmissionsDropdownItemsProps) {
  const { form } = useGradingModalSubmissionContext(false) || {}
  const { assignment: contextAssignment } = useGradingModalContext(false) || {}
  const assignment = propAssignment ?? contextAssignment
  const toast = useToast()
  const [reset, { loading }] = useResetAssignmentSubmissionsMutation({
    onCompleted: () => {
      toast({ status: 'success', title: 'Submissions reset successfully!' })
      if (form) {
        form.reset()
      }
    },
  })
  const { confirm, modal, visible } = Modal.useConfirmModal()

  const timeLimit = assignment?.timeLimit
  function confirmAndReset(preserve: boolean) {
    const timeLimitMessage = timeLimit ? (
      <Text>
        <strong>This assignment has a time limit.</strong> The student will have{' '}
        {Math.round(timeLimit / 60)} minutes from now to complete this assignment.
      </Text>
    ) : null
    confirm({
      title: 'Are you sure?',
      width: 600,
      children: preserve ? (
        <VSpaced>
          <Text>
            The student's submissions will be reset, and grades / comments cleared.{' '}
            <strong>Their existing answers will be preserved.</strong>
          </Text>
          {timeLimitMessage}
          <Text>Are you sure you would like to continue?</Text>
        </VSpaced>
      ) : (
        <VSpaced>
          <Text>
            The student's submissions will be reset, and grades / comments cleared.{' '}
            <strong>Their existing answers will also be cleared.</strong>
          </Text>
          <Text>Are you sure you would like to continue?</Text>
        </VSpaced>
      ),
      confirm: {
        children: 'Yes, reset',
        status: 'danger',
      },
      async onConfirm() {
        await reset({
          variables: {
            input: {
              id: assignmentId,
              studentId,
              classId,
              preserveLatestSubmission: preserve,
            },
          },
        })
      },
    })
  }

  return (
    <>
      {modal}
      <Dropdown.Item
        icon={<MinusCircle />}
        onClick={() => confirmAndReset(false)}
        children={'Reset and Clear Answers'}
        forceMount={visible}
        loading={loading}
      />
      <Dropdown.Item
        icon={<Refresh />}
        onClick={() => confirmAndReset(true)}
        children={'Unsubmit'}
        forceMount={visible}
        loading={loading}
      />
    </>
  )
}
