import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React from 'react'
import { CSVLink } from 'react-csv'

import { AssignedStudentRow } from './AssignmentSubmissions'

export interface ExportSubmissionsButtonProps {
  rows: AssignedStudentRow[]
}
export function ExportSubmissionsButton({ rows }: ExportSubmissionsButtonProps) {
  return (
    <CSVLink
      data={rows.map(row => {
        return {
          name: row.student.user.name || row.student.user.username,
          studentFirstName: row.student.user.firstName,
          studentLastName: row.student.user.lastName,
          studentUsername: row.student.user.username,
          grade: row.submission?.grade !== undefined ? row.submission.grade : null,
          submittedAt: row.submission?.submittedAt,
          isLate: row.submission?.isLate ? 'TRUE' : 'FALSE',
          reviewed: row.submission?.reviewed ? 'TRUE' : 'FALSE',
        }
      })}
      filename={'export.csv'}
    >
      <Button icon={<CloudDownloadOutlined />}>Export Grades</Button>
    </CSVLink>
  )
}
