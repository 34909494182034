import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { assignmentToFormValues } from './assignmentToFormValues'
import { FullAssignment, SettingsFormValues } from './types'
import { debug } from '../log'

export function useAssignmentSettingsFormUpdates(
  form: Form$.FormInstance<SettingsFormValues>,
  assignment: FullAssignment,
) {
  // Update the form fields when the assignment ID changes.
  React.useEffect(() => {
    form.reset(assignmentToFormValues(assignment), {
      keepDirty: false,
      keepValues: false,
    })
  }, [assignment.id, assignment.openAt])

  // Update the rubric when the total points value changes.
  const rubric = form.watch('rubric')
  const totalPoints = form.watch('totalPoints')
  let didUpdate = false
  React.useEffect(() => {
    if (totalPoints && rubric) {
      const rubricTotalPoints = rubric.categories.reduce(
        (sum, cat) => sum + (cat.totalPoints ?? 0),
        0,
      )
      if (
        parseInt((totalPoints * 100).toString(), 10) !==
        parseInt((rubricTotalPoints * 100).toString(), 10)
      ) {
        debug(
          'updating rubric because total points value was changed to %d (rubric reports %d)',
          totalPoints,
          rubricTotalPoints,
        )
        const scale = rubricTotalPoints === 0 ? 0 : totalPoints / rubricTotalPoints

        // Re-allocate the total points and then add / remove a few more from the last
        // item to get our total points to match exactly.
        const newCategories = rubric.categories.map(cat => ({
          ...cat,
          totalPoints: parseFloat(((cat.totalPoints ?? 0) * scale).toFixed(2)),
        }))

        if (newCategories.length) {
          let newSum = newCategories.reduce((acc, cat) => acc + (cat.totalPoints ?? 0), 0)
          let delta = totalPoints - newSum
          const deltaPerCategory = parseFloat((delta / newCategories.length).toFixed(2))
          debug('round 2 - delta per category is %s', deltaPerCategory.toFixed(2))
          for (const cat of newCategories) {
            cat.totalPoints += deltaPerCategory
          }
          newSum = newCategories.reduce((acc, cat) => acc + (cat.totalPoints ?? 0), 0)
          delta = totalPoints - newSum
          debug('round 2 - new remainder is %s', delta.toFixed(2))
          newCategories[newCategories.length - 1].totalPoints += delta
        }

        form.setValue('rubric', {
          ...rubric,
          categories: newCategories,
        })
        didUpdate = true
      }
    }
  }, [totalPoints])

  // Update the total points value when the rubric changes.
  React.useEffect(() => {
    if (rubric && !didUpdate) {
      const rubricTotalPoints = rubric.categories.reduce(
        (sum, cat) => sum + (cat.totalPoints ?? 0),
        0,
      )
      // We only care up to 2 decimal places.
      if (
        parseInt((totalPoints * 100).toString(), 10) !==
        parseInt((rubricTotalPoints * 100).toString(), 10)
      ) {
        debug(
          'updating total points value to %d because rubric was changed (total points is %d)',
          rubricTotalPoints,
          totalPoints,
        )
        form.setValue('totalPoints', parseFloat(rubricTotalPoints.toFixed(2)))
      }
    }
  }, [rubric])

  // Update revealAttempts when maxAttempts updates.
  const maxAttempts = form.watch('maxAttempts')
  const existingMaxAttempts = React.useRef(maxAttempts)
  React.useEffect(() => {
    if (maxAttempts > 1) {
      const revealAttempts = form.getValues('revealAttempts')
      if (revealAttempts === existingMaxAttempts.current) {
        debug(
          'updating revealAttempts because maxAttempts was changed to %d, and revealAttempts was the previous value',
          maxAttempts,
        )
        form.setValue('revealAttempts', maxAttempts)
      }
    }
    existingMaxAttempts.current = maxAttempts
  }, [maxAttempts])
}
