import { styled, s, Text, HSpaced } from '@thesisedu/ui'
import { MagicWand } from '@thesisedu/ui/icons'
import React from 'react'

import { DismissablePopover, DismissablePopoverProps } from './DismissablePopover'

export interface NewFeatureDismissablePopoverProps
  extends Omit<DismissablePopoverProps, 'children' | 'content'> {
  children: React.ReactNode
  title: string
  description: React.ReactNode
}
export function NewFeatureDismissablePopover({
  children,
  title,
  description,
  ...props
}: NewFeatureDismissablePopoverProps) {
  const foregroundColor = s.useForegroundColor('primary')
  const [forceVisible, setForceVisible] = React.useState(false)
  return (
    <Popover
      noFooter
      autoShow
      {...props}
      open={forceVisible}
      onOpenChange={v => {
        if (!v) setForceVisible(false)
      }}
      $foregroundColor={foregroundColor}
      contentProps={{
        width: 300,
        ...props.contentProps,
        onInteractOutside: forceVisible
          ? undefined
          : e => {
              e.preventDefault()
            },
        style: {
          ...props.contentProps?.style,
          padding: s.size('xs'),
        },
      }}
      content={
        <HSpaced align={'flex-start'} space={'xxs'}>
          <Text style={{ flexShrink: 0 }}>
            <MagicWand />
          </Text>
          <div>
            <Text level={'s'} weight={'bold'}>
              {title}
            </Text>
            {typeof description === 'string' ? (
              <Text level={'xs'}>{description}</Text>
            ) : (
              description
            )}
          </div>
        </HSpaced>
      }
      children={(isExpired, isDismissed) =>
        isExpired ? (
          children
        ) : (
          <Container>
            {children}
            {isDismissed ? null : (
              <NewContent
                children={'New!'}
                level={'xs'}
                color={'primary'}
                weight={'bold'}
                onClick={() => {
                  setForceVisible(true)
                }}
              />
            )}
          </Container>
        )
      }
    />
  )
}

const Container = styled.div`
  position: relative;
`
const NewContent = styled(Text)`
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - ${s.size('xxs')}));
  left: 0;
  cursor: pointer;
`
const Popover = styled(DismissablePopover)<{ $foregroundColor: string }>`
  --popover-background: ${s.color('primary')};
  * {
    color: ${props => props.$foregroundColor};
  }
  button:hover,
  button:hover svg path {
    color: var(--popover-background) !important;
  }
`
