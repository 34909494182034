import { onMutationError } from '@thesisedu/feature-apollo-react'
import { CLASS_STUDENTS_REFETCH_QUERIES } from '@thesisedu/feature-classes-react'
import { message } from 'antd'

import { useImportStudentsMutation as useMutation } from '../schema'

export const useImportStudentsMutation: typeof useMutation = opts =>
  useMutation({
    refetchQueries: CLASS_STUDENTS_REFETCH_QUERIES,
    awaitRefetchQueries: true,
    ...opts,
    onError: err => {
      const importError = err.graphQLErrors.find(
        err => err.extensions?.code === 'INVALID_STUDENTS_ERROR',
      )
      if (importError) {
        message.error(importError.message)
      } else {
        onMutationError(
          'There was an error importing your students. Not all of them were imported.',
        )(err)
      }
      if (opts?.onError) {
        opts.onError(err)
      }
    },
  })
