import { FilePreset } from './FilePreset'
import { LinkPreset } from './LinkPreset'
import { MaterialPreset } from './MaterialPreset'
import { VideoPreset } from './VideoPreset'
import { CoursesReactFeature } from '../CoursesReactFeature'
import { SimpleSegmentPresetResource } from '../types'

const PRESETS: (() => SimpleSegmentPresetResource)[] = [
  MaterialPreset,
  FilePreset,
  LinkPreset,
  VideoPreset,
]

export function addSegmentPresets(feature: CoursesReactFeature) {
  for (const preset of PRESETS) {
    feature.resources.addResource<SimpleSegmentPresetResource>(preset())
  }
}
