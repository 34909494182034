import { ImportStudentModeResource } from '@thesisedu/feature-classes-react'
import { CloudSync } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassSyncReactFeature } from '../ClassSyncReactFeature'
import { ImportStudentPayload } from '../types'
import { ImportClassSyncExpanded } from '../web/ImportClassSyncExpanded'
import { ImportClassSyncMain } from '../web/ImportClassSyncMain'

export default function (feature: ClassSyncReactFeature) {
  feature.resources.addResource<ImportStudentModeResource<ImportStudentPayload>>({
    type: 'ImportStudentMode',
    identifier: 'class-sync',
    title: 'Roster from LMS',
    MainContentComponent: ImportClassSyncMain,
    ExpandedContentComponent: ImportClassSyncExpanded,
    filter: cls => cls.canUpdate,
    icon: <CloudSync />,
    weight: -1,
    widths: {
      expanded: 600,
    },
  })
}
