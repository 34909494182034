import { PageHead } from '@thesisedu/react'
import { Button, Text, HSpaced, VSpaced } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate, useParams, Routes, Route } from 'react-router-dom'

import { CreateRubricTemplatePage, EditRubricTemplatePage } from './EditRubricTemplatePage'
import { RubricTemplateList } from './RubricTemplateList'

function EditRubricTemplateRoute() {
  const { templateId } = useParams()
  return (
    <>
      <PageHead title={'Edit Rubric Template'} />
      <EditRubricTemplatePage rubricTemplateId={templateId} />
    </>
  )
}

function List() {
  const navigate = useNavigate()
  return (
    <VSpaced style={{ maxWidth: 900, width: '100%' }} align={'stretch'} space={'l'}>
      <PageHead title={'Rubric Templates'} />
      <HSpaced justify={'space-between'}>
        <div>
          <Text level={'h2'}>Rubric Templates</Text>
        </div>
        <Button variant={'primary'} onPress={() => navigate('create')} icon={<Plus />}>
          Create Template
        </Button>
      </HSpaced>
      <RubricTemplateList />
    </VSpaced>
  )
}

export function RubricTemplateRoutes() {
  return (
    <Routes>
      <Route
        path={'/create'}
        element={
          <>
            <PageHead title={'Create Rubric Template'} />
            <CreateRubricTemplatePage />
          </>
        }
      />
      <Route path={'/:templateId'} element={<EditRubricTemplateRoute />} />
      <Route path={'/'} element={<List />} />
    </Routes>
  )
}
