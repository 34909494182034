import { Feature } from '@thesisedu/feature'
import { VideoOnDemandViewerResource } from '@thesisedu/feature-video-on-demand-react'
import React from 'react'

import { LessonField, ViewLesson } from '..'

export default function addVideoOnDemandViewer(feature: Feature) {
  feature.resources.addResource<VideoOnDemandViewerResource>({
    type: 'VideoOnDemandViewer',
    identifier: 'Lesson',
    Component: props => {
      const { children, vodId, ...rest } = props
      return <ViewLesson lessonId={vodId} playerChildren={children} {...rest} noSidebar />
    },
    shouldUseEditor: user => user.group === 'TEACHER',
    Editor: LessonField,
  })
}
