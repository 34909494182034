import { SingleSelectButton, SingleSelectButtonProps } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import { CreateSiteButton } from './CreateSiteButton'
import {
  SitesQuery,
  SitesQueryVariables,
  SiteFragment,
  SitesDocument,
  SiteDocument,
} from './schema'

export type SiteSelectProps = Partial<
  SingleSelectButtonProps<SiteFragment, SitesQuery, SitesQueryVariables>
>
export function SiteSelect(props: SiteSelectProps) {
  return (
    <SingleSelectButton<SiteFragment, SitesQuery, SitesQueryVariables>
      filterVariableName={'name'}
      selectLabel={'Select a Site'}
      addButton={
        <CreateSiteButton onCreated={site => (props.onChange ? props.onChange(site.id) : null)} />
      }
      maxSelection={1}
      getItemName={item => item.name}
      document={SitesDocument}
      nodeDocument={SiteDocument}
      getResult={data => data?.sites}
      setResult={(previous, result) => ({
        ...previous,
        sites: {
          ...previous.sites,
          ...result,
        },
      })}
      {...props}
    />
  )
}
