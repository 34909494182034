import { Body, BodySmall } from '@thesisedu/react'
import React from 'react'

import { LessonFragment } from './schema'

export interface ViewLessonTagsProps {
  lesson: Pick<LessonFragment, 'tags'>
}
export function ViewLessonTags({ lesson }: ViewLessonTagsProps) {
  return (
    <>
      <BodySmall isBlock color={'@text-color-secondary'}>
        Tags
      </BodySmall>
      <Body>
        {lesson.tags.edges.length
          ? lesson.tags.edges.map(edge => edge.node.name).join(', ')
          : 'No Tags'}
      </Body>
    </>
  )
}
