import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'
import { Button } from '@thesisedu/ui'
import React from 'react'

import { WidgetNode } from './WidgetNode'
import { WidgetSettingsModal } from './WidgetSettingsModal'
import { SimpleWidgetDefinition } from './createWidgetResource'
import { useEditorContext } from '../../../lexical/context/EditorContext'

export interface WidgetEmptyStateProps<Config extends BaseWidgetConfig>
  extends Pick<SimpleWidgetDefinition<any, any>, 'title' | 'identifier'> {
  buttonLabel: string
  /** The icon to go on the button. */
  icon: React.ReactElement
  settingsModal: Required<SimpleWidgetDefinition<any, any>>['settingsModal']
  node: WidgetNode<any, Config>
}
export function WidgetEmptyState<Config extends BaseWidgetConfig>({
  settingsModal,
  identifier,
  node,
  buttonLabel,
  title,
  icon,
}: WidgetEmptyStateProps<Config>) {
  const { readOnly } = useEditorContext(false) || { readOnly: true }
  const [visible, setVisible] = React.useState(false)
  if (readOnly) return null

  return (
    <>
      <Button
        icon={icon}
        children={buttonLabel}
        style={{
          width: '100%',
        }}
        onPress={() => setVisible(true)}
      />
      <WidgetSettingsModal<Config, Partial<Config>>
        identifier={identifier}
        node={node}
        settingsModal={settingsModal}
        title={title}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  )
}
