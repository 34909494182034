import React from 'react'

import { CreateAnotherAttemptAssignmentAction } from './CreateAnotherAttemptAssignmentAction'
import { SubmissionContextValue } from '../contexts/SubmissionContext'
import { FullAssignment } from '../grading/types'
import { AssignmentAutoGradeMode, FullAssignmentSubmissionFragment } from '../schema'

export interface UseManageAttemptsContextValueOpts {
  submission?: FullAssignmentSubmissionFragment
  assignment: FullAssignment
}
export function useManageAttemptsContextValue({
  assignment,
  submission,
}: UseManageAttemptsContextValueOpts): Partial<SubmissionContextValue> {
  const canManageAttempts =
    assignment.configuration?.studentsManageAttempts && assignment.maxAttempts > 1
  if (canManageAttempts) {
    const isFullyAutoGraded = assignment.autoGrading === AssignmentAutoGradeMode.AllQuestions
    const isSubmitted = !!submission?.submittedAt
    const isGraded = !!submission?.gradedAt

    /**
     * Follows this line of logic:
     *
     * - If not auto-gradeable, not submitted, and not graded:
     *   Submit this Attempt + Create Another Attempt
     * - If not auto-gradeable, submitted, and either graded / not graded:
     *   Create Another Attempt
     * - If auto-gradeable, not submitted, and not graded: Submit for Grading
     * - If auto-gradeable, not submitted, and graded:
     *   Submit this Attempt + Create Another Attempt
     * - If auto-gradeable, submitted, and either graded / not graded:
     *   Create Another Attempt
     */
    let submitMessage: string | undefined = undefined
    let submitAlternative: React.ReactElement | undefined = undefined
    let skipSubmitConfirmation = false
    let hideSubmit = false
    const createAction = <CreateAnotherAttemptAssignmentAction />
    if (isFullyAutoGraded) {
      if (isSubmitted) {
        hideSubmit = true
        if (!isGraded) {
          submitAlternative = createAction
        }
      } else if (isGraded) {
        submitMessage = 'Submit this Attempt'
        skipSubmitConfirmation = true
        submitAlternative = createAction
      }
    } else {
      submitAlternative = createAction
      if (!isSubmitted) {
        submitMessage = 'Submit this Attempt'
        skipSubmitConfirmation = true
      } else {
        hideSubmit = true
      }
    }

    return {
      submitMessage,
      submitAlternative,
      skipSubmitConfirmation,
      hideSubmit,
    }
  } else return {}
}
