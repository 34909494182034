import { HSpaced, Text } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { File, mergeFileInfo } from './File'
import { Upload } from './Upload'
import { useAttachGenericFileMutation } from './schema'
import { UploadProps } from './types'

export interface UseFileFieldOpts extends Partial<UploadProps> {
  onChange?: (value?: string) => void
}
export function useFileField({
  onChange,
  getWriteFragment,
}: UseFileFieldOpts): Pick<UploadProps, 'onMinimalUploaded' | 'getWriteFragment'> {
  const [attachGenericFile] = useAttachGenericFileMutation()
  const filenameRef = React.useRef<string>('')
  return {
    onMinimalUploaded: path => {
      if (onChange) {
        onChange(mergeFileInfo(path, filenameRef.current))
      }
    },
    getWriteFragment: async input => {
      let result
      if (getWriteFragment) {
        result = await getWriteFragment(input)
      } else {
        const queryResult = await attachGenericFile({
          variables: {
            input: input.signedUploadInput,
          },
        })
        if (queryResult?.data?.attachGenericFile) {
          result = {
            uploadUrl: {
              upload: queryResult.data.attachGenericFile,
            },
          }
        }
      }

      if (result) {
        filenameRef.current = input.name
        return result
      } else return null
    },
  }
}

export interface FileFieldProps {
  value?: string
  onChange?: (value?: string) => void
  uploadProps?: Partial<UploadProps>
  disabled?: boolean
}
function _FileField(
  { value, onChange, uploadProps, disabled }: FileFieldProps,
  ref: React.ForwardedRef<HTMLElement>,
) {
  const fileFieldProps = useFileField({ onChange, ...uploadProps })
  if (value) {
    return (
      <File filename={value}>
        {disabled ? null : (
          /* Using a button here causes issues with the click event for some reason. */
          <a
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              if (onChange) {
                onChange(undefined)
              }
            }}
          >
            <HSpaced space={'xxs'}>
              <Text level={'s'} color={'red'}>
                <Trash />
              </Text>
              <Text level={'s'} color={'red'}>
                Delete File
              </Text>
            </HSpaced>
          </a>
        )}
      </File>
    )
  } else {
    return <Upload disabled={disabled} {...uploadProps} {...fileFieldProps} ref={ref} />
  }
}
export const FileField = React.forwardRef(_FileField)
