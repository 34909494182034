import { SyncAssignmentActionsProps } from '@thesisedu/feature-class-sync-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { message } from 'antd'

import { useSegmentDecoration } from '../../contexts/SegmentDecorationsContext'
import { SegmentDecorationWithAssignmentSyncFragment, useSyncSegmentMutation } from '../../schema'

export interface UseSyncAssignmentActionsPropsOpts {
  rawSegmentId: string
  classId: string
}

const LOADING_KEY = 'syncing-segment'
export function useSyncAssignmentActionsProps({
  rawSegmentId,
  classId,
}: UseSyncAssignmentActionsPropsOpts): SyncAssignmentActionsProps {
  const [sync] = useSyncSegmentMutation()
  const decoration = useSegmentDecoration<SegmentDecorationWithAssignmentSyncFragment>(rawSegmentId)
  return {
    async onSync(classSyncId) {
      message.loading({
        content: 'Syncing...',
        key: LOADING_KEY,
        duration: 0,
      })
      try {
        await sync({
          variables: {
            input: {
              id: toGlobalId('Segment', rawSegmentId),
              syncIds: [classSyncId],
            },
          },
        })
      } finally {
        message.destroy(LOADING_KEY)
      }
    },
    syncs: decoration?.materialSyncs ?? decoration?.assignmentSyncs ?? [],
    assignedClassIds: [classId],
    classIds: [classId],
  }
}
