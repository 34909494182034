import { Dragger } from '@sammarks/icons'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import {
  ClassDraggableProps as OGClassDraggableProps,
  ArchiveClassMenuItem,
  ClassTaskSummary,
  ClassDraggableFooterItemsHook,
  ClassDraggableOverlayItemsHook,
} from '@thesisedu/feature-classes-react'
import { useMutateHook } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Tag } from '@thesisedu/react'
import { styled, s, Block, Text, Dropdown } from '@thesisedu/ui'
import { AddUser, NavArrowDown, Settings } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { BasicClassCourseFragment, BasicClassFragment } from '../schema'
import { ClassFragmentWithPermissions } from '../types'

export interface ClassDraggableProps extends OGClassDraggableProps {
  overlayItems?: React.ReactElement[]
  footerItems?: React.ReactElement
  readOnly?: boolean
}
export function ClassDraggable(props: ClassDraggableProps) {
  const {
    class: _cls,
    readOnly,
    dragHandleProps,
    overlayItems: _overlayItems,
    footerItems: _footerItems,
    onInvite,
    settingsLink,
    isArchived,
  } = props
  const viewer = useViewerContext(false)
  const cls = _cls as BasicClassFragment & BasicClassCourseFragment & ClassFragmentWithPermissions
  const navigate = useNavigate()
  const group = viewer?.group || 'NONE'
  const isStudent = group === 'STUDENT'
  const { filename: courseIcon } = getFileInfo(cls.courseVersion?.course.basicConfiguration.icon)
  const footerItems = useMutateHook<ClassDraggableFooterItemsHook>(
    'classes-react:class-draggable-footer-items',
    _footerItems ? [_footerItems] : [],
    { ...props, group },
  )
  const overlayItems = useMutateHook<ClassDraggableOverlayItemsHook>(
    'classes-react:class-draggable-overlay-items',
    _overlayItems || [],
    { ...props, group },
  )
  const courseColor = cls.courseVersion?.course.basicConfiguration.color
  return (
    <Container>
      <CourseHeader
        $color={courseColor}
        onClick={e => {
          // Make sure we dont clobber clicks from the dropdown menu.
          if (!(e.target as any).closest('button, .dropdown-menu, [role=menu]') && !isArchived) {
            navigate(cls.id)
          }
        }}
      >
        {courseIcon ? <CourseImage src={courseIcon} /> : null}
        <div>
          <Text level={'h3'} bottom={false}>
            {cls.name}
          </Text>
          {isStudent ? null : (
            <>
              <Text className={'secondary'}>
                {cls.courseVersion?.course.label || cls.courseVersion?.course.name || 'No Course'}
              </Text>
              {cls.isOwn ? (
                cls.teachers?.totalCount > 1 ? (
                  <Block top={'xxs'}>
                    <Tag
                      ghost
                      textColor={courseColor}
                      color={'gray-0'}
                      children={`Owner - team with ${cls.teachers.totalCount - 1} other${
                        cls.teachers.totalCount > 2 ? 's' : ''
                      }`}
                      size={'xsmall'}
                    />
                  </Block>
                ) : null
              ) : (
                <Block top={'xxs'}>
                  <Tag
                    ghost
                    textColor={courseColor}
                    color={'gray-0'}
                    children={'Team Teaching'}
                    size={'xsmall'}
                  />
                </Block>
              )}
            </>
          )}
        </div>
        <DropdownContainer>
          <Dropdown.Container>
            <Dropdown.Button variant={'ghost'} icon={<NavArrowDown />} rightDecorator={null} />
            <Dropdown.Menu side={'bottom'} align={'end'}>
              {!isArchived && !readOnly && onInvite && cls.canInvite ? (
                <Dropdown.Item onClick={onInvite} icon={<AddUser />}>
                  Invite Students
                </Dropdown.Item>
              ) : null}
              {settingsLink && !isArchived && !readOnly && cls.canUpdateSettings ? (
                <Dropdown.Item onClick={() => navigate(settingsLink(cls.id))} icon={<Settings />}>
                  Class Settings
                </Dropdown.Item>
              ) : null}
              {!isArchived && overlayItems && !readOnly && (onInvite || cls.canUpdateSettings) ? (
                <Dropdown.Item.Separator />
              ) : null}
              {!isArchived ? overlayItems : null}
              {!isArchived && !readOnly && cls.canUpdate ? <Dropdown.Item.Separator /> : null}
              {!readOnly && cls.canUpdate ? <ArchiveClassMenuItem classId={cls.id} /> : null}
            </Dropdown.Menu>
          </Dropdown.Container>
        </DropdownContainer>
      </CourseHeader>
      <ContentContainer>
        <ClassTaskSummary classId={_cls.id} />
      </ContentContainer>
      {!isArchived ? (
        <FooterContainer>
          {dragHandleProps ? (
            <Block right>
              <Text {...dragHandleProps} as={'div'} color={'secondary'} style={{ cursor: 'grab' }}>
                <Dragger />
              </Text>
            </Block>
          ) : null}
          {footerItems}
        </FooterContainer>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  border-radius: ${s.var('radii.2')};
  border: solid 1px ${s.color('border')};
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 300px;
`
const ContentContainer = styled.div`
  padding: ${s.size('m')};
  display: flex;
  align-items: stretch;
  justify-content: center;
`
const DropdownContainer = styled.div`
  margin-left: auto;
  padding-left: ${s.size('s')};
  align-self: flex-start;
`
const FooterContainer = styled.div`
  padding: ${s.size('xs')} ${s.size('s')};
  background: ${s.color('element')};
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * {
    color: ${s.color('secondary')};
  }
`
const CourseHeader = styled.div<{ $color?: s.ColorAlias | 'gray-7' }>`
  background: ${props =>
    s.color(props.$color === 'gray-7' || !props.$color ? 'activeSolid' : props.$color)};
  padding: ${s.size('s')} ${s.size('s')} ${s.size('s')} ${s.size('xs')};
  display: flex;
  align-items: center;
  cursor: pointer;
  > div > * {
    color: white !important;
  }
  button {
    &:hover {
      background: rgba(255, 255, 255, 0.5);
      color: ${props =>
        s.color(props.$color === 'gray-7' || !props.$color ? 'activeSolid' : props.$color)};
    }
  }
  .secondary {
    opacity: 0.75;
  }
  h3 {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;

    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
`
const CourseImage = styled.img`
  margin-right: ${s.size('xs')};
  width: 64px;
  display: block;
`
