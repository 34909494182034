import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { RecordViewProps } from '@thesisedu/feature-media-react'

import { useCreatePracticeMutation } from './queries/useCreatePracticeMutation'
import { PracticeFragment } from './schema'

export interface UseCreatePracticeOpts {
  onCreated?: (practice: PracticeFragment) => void
  extraCreateInput?: Record<string, any>
}
export interface UseCreatePractice {
  recordProps: RecordViewProps
}
export function useCreatePractice({
  onCreated,
  extraCreateInput,
}: UseCreatePracticeOpts = {}): UseCreatePractice {
  const classId = useSelectedClassId(true)
  const [createPractice] = useCreatePracticeMutation()

  return {
    recordProps: {
      label: 'Practice',
      classId,
      onCreated: result => {
        if (result.practice && onCreated) {
          const practice = result.practice as PracticeFragment
          setTimeout(() => {
            onCreated(practice)
          }, 100) // Wait for the navigate prompt to process the practice.
        }
      },
      getMediaUploadUrl: async input => {
        try {
          const result = await createPractice({
            variables: {
              input: {
                classId,
                media: input,
                ...extraCreateInput,
              },
            },
          })
          if (result?.data?.createPractice) {
            return {
              media: result.data.createPractice.uploadPayload,
              practice: result.data.createPractice.practice,
            }
          } else {
            return null
          }
        } catch (errs: any) {
          if ((errs.graphQLErrors || [])[0]?.extensions?.code === 'LICENSE_INVALID_ERROR') {
            return {
              errors: errs.graphQLErrors,
            }
          } else {
            onMutationError('There was an error creating that practice.')(errs)
            return null
          }
        }
      },
    },
  }
}
