import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  LessonFragment,
  LessonFragmentDoc,
  UpdateLessonMutation,
  useUpdateLessonMutation as useMutation,
} from '../schema'

export const useUpdateLessonMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that lesson.'),
    update: updateMutation<UpdateLessonMutation, LessonFragment>({
      fragment: LessonFragmentDoc,
      fragmentName: 'Lesson',
      path: 'updateLesson.lesson',
    }),
    ...opts,
  })
