import { PageHead, PageHeader } from '@thesisedu/web'
import React from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'

import { ClassSheetMusicCompositionsList } from '../../share/ClassSheetMusicCompositionsList'
import {
  useSheetMusicCompName,
  ViewSheetMusicComposition,
} from '../../share/ViewSheetMusicComposition'

function List() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Compositions'} />
      <PageHeader title={'Compositions'} />
      <ClassSheetMusicCompositionsList onClick={navigate} />
    </>
  )
}

function View() {
  const navigate = useNavigate()
  const { sheetMusicCompositionId: id } = useParams()
  const name = useSheetMusicCompName(id)
  return (
    <>
      <PageHead title={`${name} - Compositions`} />
      <PageHeader onBack={() => navigate('..')} title={'View Composition'} />
      <ViewSheetMusicComposition compId={id} />
    </>
  )
}

export function Compositions() {
  return (
    <Routes>
      <Route path={'/'} element={<List />} />
      <Route path={'/:sheetMusicCompositionId'} element={<View />} />
    </Routes>
  )
}
