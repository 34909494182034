import { Popover, Slider, styled, s } from '@thesisedu/ui'
import { SoundHigh, SoundLow, SoundMin, SoundOff } from '@thesisedu/ui/icons'
import React from 'react'

import { useMediaPlaybackContext } from '../MediaPlaybackContext'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

const SoundIcon: [number, React.ReactElement][] = [
  [0.75, <SoundHigh />],
  [0.5, <SoundLow />],
  [0.25, <SoundMin />],
  [0, <SoundOff />],
]
const PopoverContent = styled(Popover.Content)`
  padding: ${s.size('xs')} 0;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function Volume({ compact }: PlayerFooterProps) {
  const { volume, setVolume } = useMediaPlaybackContext()
  const icon = SoundIcon.find(([value]) => volume >= value)?.[1] ?? <SoundOff />
  return (
    <>
      <Popover.Container>
        <Popover.Trigger>
          <ControlContainer compact={compact !== undefined}>{icon}</ControlContainer>
        </Popover.Trigger>
        <PopoverContent className={'volume-slider'} side={'top'} align={'end'} width={40} hideClose>
          <Slider
            orientation={'vertical'}
            min={0}
            max={1}
            value={[volume]}
            variant={'monochrome'}
            onValueChange={values => {
              const value = values[0]
              setVolume(value)
            }}
            step={0.1}
            style={{ height: 125 }}
          />
        </PopoverContent>
      </Popover.Container>
    </>
  )
}
