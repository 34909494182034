import { useDistrictQuery } from '@thesisedu/feature-districts-web/dist/schema'
import { useResource } from '@thesisedu/feature-react'
import { LoadingIndicator } from '@thesisedu/ui'
import { H3Alternate, styled } from '@thesisedu/web'
import { groupBy } from 'lodash'
import React from 'react'

import { SiteConfigurationFieldResource } from './types'

export interface SiteConfigurationProps {
  fieldPrefix: string[]
  siteId?: string
  districtId?: string
}
export function SiteConfiguration({ fieldPrefix, siteId, districtId }: SiteConfigurationProps) {
  const { data, loading } = useDistrictQuery({
    variables: { id: districtId ?? '' },
    skip: !districtId,
  })
  const district = data?.node?.__typename === 'District' ? data.node : undefined
  const fields = useResource<SiteConfigurationFieldResource>('SiteConfigurationField')
  const groups = groupBy(fields, 'group') as Record<string, SiteConfigurationFieldResource[]>
  if (loading) return <LoadingIndicator />
  return (
    <>
      {Object.keys(groups).map(groupName => (
        <GroupContainer key={groupName || 'ungrouped'}>
          {groupName !== 'undefined' && groupName ? <H3Alternate>{groupName}</H3Alternate> : null}
          {groups[groupName].map(groupedField => {
            const Field = groupedField.renderField
            return (
              <Field
                key={groupedField.identifier}
                siteId={siteId}
                fieldPrefix={fieldPrefix}
                district={district}
              />
            )
          })}
        </GroupContainer>
      ))}
    </>
  )
}

const GroupContainer = styled.div`
  margin: ${props => props.theme['@size-m']} 0;
`
