import React from 'react'

import { ConfirmModal, ConfirmModalProps } from './ConfirmModal'

export interface UseConfirmModal {
  modal: React.ReactElement | null
  confirm: (opts: ConfirmModalProps) => void
  visible: boolean
}
export function useConfirmModal(): UseConfirmModal {
  const [props, setProps] = React.useState<ConfirmModalProps | undefined>(undefined)
  return {
    modal: props ? (
      <ConfirmModal
        {...props}
        visible
        onVisibleChange={v => {
          if (!v) {
            setProps(undefined)
          }
        }}
        onConfirm={async () => {
          await props?.onConfirm?.()
          setProps(undefined)
        }}
        onCancel={async () => {
          await props?.onCancel?.()
          setProps(undefined)
        }}
      />
    ) : null,
    confirm: setProps,
    visible: !!props,
  }
}
