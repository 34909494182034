import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  RubricTemplateFragmentDoc,
  useUpdateRubricTemplateMutation as useMutation,
} from '../schema'

export const useUpdateRubricTemplateMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error saving your rubric template.'),
    update: updateMutation({
      fragment: RubricTemplateFragmentDoc,
      fragmentName: 'RubricTemplate',
      path: 'updateRubricTemplate.rubricTemplate',
    }),
    ...opts,
  })
