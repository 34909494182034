import { CollapsibleRange } from '@thesisedu/web/dist/react-window'

import { AxesResponsibility, isMetrics } from './axes'
import { dimensionMatches, ReportCell, SecondGetter } from './cells/getReportCells'
import { getInputIdentifier } from '../../execute/input'
import { RunReportQuery } from '../../execute/types'
import {
  FlatReportDimensionSummaryRow,
  ReportDimensionSummaryRow,
  ReportMetricResource,
  RunReportOpts,
} from '../../types'

export interface SummaryCells {
  cells: ReportCell[][]
  rowIds: string[]
  collapsibleRowRanges: CollapsibleRange[]
  flatRows: FlatReportDimensionSummaryRow[]
}

function flattenSummaryRows(
  rows: ReportDimensionSummaryRow[],
  depth = 0,
): FlatReportDimensionSummaryRow[] {
  return rows.reduce<FlatReportDimensionSummaryRow[]>((acc, row) => {
    return [...acc, { ...row, depth }, ...flattenSummaryRows(row.children || [], depth + 1)]
  }, [])
}

function getCollapsibleRanges(rows: ReportDimensionSummaryRow[]): CollapsibleRange[] {
  return rows.reduce<CollapsibleRange[]>((acc, row) => {
    if (row.children?.length) {
      const flatChildren = flattenSummaryRows(row.children)
      return [
        ...acc,
        { id: row.id, length: flatChildren.length },
        ...getCollapsibleRanges(row.children),
      ]
    } else return acc
  }, [])
}

export function getSummaryCells(
  responsibility: AxesResponsibility,
  data: RunReportQuery['runReport'],
  opts: RunReportOpts,
  allMetricResources: ReportMetricResource[],
): SummaryCells {
  const rowDimension = isMetrics(responsibility.row) ? undefined : responsibility.row
  if (!rowDimension?.summary)
    throw new Error('#getSummaryCells() requires a row dimension with a summary')
  const summaryRows = rowDimension.summary.getRows(data)
  const flatRows = flattenSummaryRows(summaryRows)
  const rowIds = flatRows.map(row => row.id)
  const collapsibleRowRanges = getCollapsibleRanges(summaryRows)
  const totalColumns = isMetrics(responsibility.col)
    ? responsibility.col.length
    : data.dimensionSummaries.length

  const getter: SecondGetter = (fragments, index) => {
    if (isMetrics(responsibility.col)) {
      const metricResource = responsibility.col[index]
      return {
        metricIdentifier: metricResource.identifier,
        metricValue: fragments[0].metrics[metricResource.summarization]?.[metricResource.metricKey],
        item: fragments[0],
      }
    } else {
      const metric = getInputIdentifier(opts.metrics[0])
      const metricResource = allMetricResources.find(r => r.identifier === metric)
      if (!metricResource) throw new Error(`Metric resource '${metric}' could not be found.`)
      const dimensionValue = data.dimensionSummaries[index].dimensions[responsibility.col.inputKey]
      if (!dimensionValue)
        throw new Error('No dimension summary found for index ' + index.toString())
      const result = fragments.find(r =>
        Object.keys(r.dimensions).some(
          dimKey => r.dimensions[dimKey] && dimensionMatches(r.dimensions[dimKey], dimensionValue),
        ),
      )
      return {
        metricIdentifier: metric,
        metricValue: result?.metrics?.[metricResource.summarization]?.[metricResource.metricKey],
        item: result || null,
      }
    }
  }

  const cells: ReportCell[][] = []
  for (let col = 0; col < totalColumns; col++) {
    if (!cells[col]) cells[col] = []
    for (let row = 0; row < rowIds.length; row++) {
      const summaryRow = flatRows[row]
      cells[col][row] = getter(summaryRow.results, col)
    }
  }

  return {
    rowIds,
    cells,
    collapsibleRowRanges,
    flatRows,
  }
}

const MAX_DEFAULT_EXPANDED_ROWS = 40
const MAX_DEPTH = 2
export function getDefaultExpandedIds(summary?: SummaryCells): string[] {
  if (summary) {
    let currentDepth = 1
    let currentResult: string[] = []
    while (currentDepth <= MAX_DEPTH) {
      const nextResult = summary.flatRows
        .filter(flatRow => flatRow.depth < currentDepth)
        .map(row => row.id)
      const nextResultWithChildren = summary.flatRows
        .filter(flatRow => flatRow.depth < currentDepth + 1)
        .map(row => row.id)
      if (nextResultWithChildren.length > MAX_DEFAULT_EXPANDED_ROWS) break
      currentResult = nextResult
      currentDepth++
    }

    return currentResult
  } else {
    return []
  }
}
