import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { CanDeleteAssignmentHook } from '../../node'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<CanDeleteAssignmentHook>(
    'feature-assignments-react:can-delete-assignment',
    (canDelete, { assignment }) => {
      if (assignment.generated) {
        return false
      } else return canDelete
    },
  )
}
