import { RequireFields } from '@thesisedu/feature-types'
import { Random } from '@thesisedu/feature-utils'

export interface MatchGroupConfig {
  useDropdown?: boolean
}

export interface MatchOption {
  id: string
  source: string
  destination: string
}
export type PartialMatchOption = RequireFields<MatchOption, 'id' | 'destination'>
export interface MatchConfig extends MatchGroupConfig {
  options?: MatchOption[]
}
export interface MatchClientConfig extends MatchGroupConfig {
  /** On clients, the answer is moved from options -> answer, so we can filter it out. */
  answer?: MatchOption[]
  destinations: string[]
  sources: string[]
}

export interface MatchAnswer {
  source: string
  destination: string
}

export function isMatchClientConfig(
  config: MatchClientConfig | MatchConfig,
): config is MatchClientConfig {
  return !(config as MatchConfig)?.options?.length
}

export function matchToClientConfig(
  { options = [], ...rest }: MatchConfig,
  randomize: string | false,
): MatchClientConfig {
  const random = randomize ? Random.get(randomize) : undefined
  const sources = [...new Set(options.map(opt => opt.source))]
  if (random) sources.sort(() => random() - 0.5)
  const destinations = [...new Set(options.map(opt => opt.destination))]
  if (random) destinations.sort(() => random() - 0.5)
  return {
    ...rest,
    answer: options,
    sources,
    destinations,
  }
}
