import React from 'react'

export interface PersistedStateArgs<T> {
  defaultValue: T
  key: string
}

export function usePersistedState<T>(opts: PersistedStateArgs<T>): [T, (value: T) => void] {
  const { key, defaultValue } = opts
  const [value, setValue] = React.useState(() => {
    const persistedValue = window.localStorage.getItem(key)
    if (persistedValue !== null) {
      try {
        return JSON.parse(persistedValue)
      } catch {
        window.localStorage.removeItem(key)
      }
    }
    return defaultValue
  })
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])
  return [value, setValue]
}
