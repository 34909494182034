import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { $isImageNode, ImageNode } from '../../../nodes/Image/ImageNode'
import {
  useSettingsDropdownItem,
  SettingsDropdownElementProps,
} from '../../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../../ui/SettingsDropdown/useNodeState'

function ImageCaptionElement({ editor, node }: SettingsDropdownElementProps<ImageNode>) {
  const [showCaption, setShowCaption] = useNodeState(
    editor,
    node,
    node => node.getShowCaption(),
    (node, value) => node.setShowCaption(value),
  )
  const [src] = useNodeState(
    editor,
    node,
    node => node.getSrc(),
    () => {},
  )
  return (
    <Dropdown.Item.Checkbox
      checked={showCaption}
      disabled={!src}
      onCheckedChange={checked => {
        setShowCaption(checked === true)
      }}
    >
      Caption
    </Dropdown.Item.Checkbox>
  )
}

export function useImageCaptionSetting() {
  useSettingsDropdownItem({
    identifier: 'image-caption',
    group: 'image',
    weight: -10,
    filter(editor, node) {
      return $isImageNode(node)
    },
    element: ImageCaptionElement,
  })
}
