const pad = (input: number): string => {
  const result = input.toString()
  return result.length !== 2 ? `0${result}` : result
}

export const formatTime = (timeInSeconds: string | undefined): string | null => {
  if (!timeInSeconds) return null
  const intTimeInSeconds = parseInt(timeInSeconds, 10)
  if (isNaN(intTimeInSeconds)) return null
  const minutes = intTimeInSeconds / 60
  const hours = minutes / 60
  if (hours > 1) {
    return `${pad(Math.floor(hours))}:${pad(Math.floor(minutes - 60 * Math.floor(hours)))}:${pad(
      Math.floor(intTimeInSeconds - 60 * Math.floor(minutes)),
    )}`
  } else {
    return `${pad(Math.floor(minutes))}:${pad(
      Math.floor(intTimeInSeconds - 60 * Math.floor(minutes)),
    )}`
  }
}
