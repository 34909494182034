import { addField, useModifiedFragment } from '@thesisedu/feature-apollo-react'
import { DeepLinkResource, ReactFeature, ReactUse } from '@thesisedu/feature-react'

export class DirectMessagesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-direct-messages-react'
  static path = __dirname
  static requires = []

  reactResources() {
    require('./hooks/routes').default(this)
    require('./hooks/userMenu').default(this)
    const { ThreadDeepLinkRemapper } = require('./DeepLinkRemapper')
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'dm-thread',
      params: [{ identifier: 'id', required: true }],
      Component: ThreadDeepLinkRemapper,
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'User', fragment => {
      return addField(fragment, 'canMessage')
    })
  }
}

export const directMessagesReactFeature: ReactUse = opts => [DirectMessagesReactFeature, opts]
