import { SerializedWidget } from '@thesisedu/feature-widgets-core'

import { SimpleWidget } from '../utils'

export type LinkEmbedAlignment = SimpleWidget.Alignment
export interface LinkEmbedConfigV1 extends SimpleWidget.BaseWidgetConfig {
  url: string | null
  alignment?: LinkEmbedAlignment
  version: 1
}
export type LinkEmbedConfig = LinkEmbedConfigV1
export type LinkEmbedWidget = SerializedWidget<'LinkEmbed', LinkEmbedConfig>
export const DEFAULT_ALIGNMENT: LinkEmbedAlignment = 'center'
