import { Button } from 'antd'
import React from 'react'

import { styled } from './styledTypes'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 -5px;
  > button {
    text-align: center;
    min-height: 120px;
    > span[role='img'] {
      display: block;
      font-size: 20px;
      margin: 0 0 10px 0;
    }
    > div {
      margin: 0 auto 5px auto;
    }
    > span {
      margin-left: 0 !important;
      max-width: 100%;
      white-space: normal;
      line-height: 1.1;
    }
    margin: 5px;
    min-width: 125px;
    width: 125px;
    height: 90px;
  }
`

export interface AvailableType {
  type: string
  icon: React.FC<React.PropsWithChildren<object>>
  label: string
}
export interface AvailableTypeSelectorProps {
  items: AvailableType[]
  onItemSelected: (type: string) => void
  style?: any
  buttonStyle?: any
}
export function AvailableTypeSelector({
  items,
  onItemSelected,
  style,
  buttonStyle,
}: AvailableTypeSelectorProps) {
  return (
    <Container style={style}>
      {items.map(item => (
        <Button key={item.type} onClick={() => onItemSelected(item.type)} style={buttonStyle}>
          <item.icon />
          <span>{item.label}</span>
        </Button>
      ))}
    </Container>
  )
}
