import React from 'react'
import { Cell as RACell, CellProps as RACellProps } from 'react-aria-components'

import { styled, s } from '../..'

const { css } = s
export const CellStyles = css`
  font-size: var(--table-font-size);
  padding: var(--table-size-xxs) var(--table-size-xs);
  &:first-child {
    border-top-left-radius: ${s.var('radii.1')};
    border-bottom-left-radius: ${s.var('radii.1')};
  }
  &:last-child {
    border-top-right-radius: ${s.var('radii.1')};
    border-bottom-right-radius: ${s.var('radii.1')};
  }
`

export interface CellProps extends RACellProps {
  visibleOnHover?: boolean
}
function _Cell(
  { visibleOnHover, ...rest }: CellProps,
  ref: React.ForwardedRef<HTMLTableCellElement>,
) {
  return <__Cell ref={ref} {...rest} data-visible-on-hover={visibleOnHover} />
}
export const Cell = React.forwardRef(_Cell)

export const __Cell = styled(RACell)`
  ${CellStyles}
`
