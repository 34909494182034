import React from 'react'
import { ObjectInspector } from 'react-inspector'

import { Cell, CellProps, ScrollContent } from './Cell'
import { CopyToClipboardButton } from '../CopyToClipboardButton'
import { BodySmall } from '../typography'

export interface InspectCellProps {
  title: CellProps['title']
  value: any
}
export function InspectCell({ title, value }: InspectCellProps) {
  return (
    <Cell
      title={title}
      rightActions={
        <BodySmall>
          <CopyToClipboardButton text={JSON.stringify(value, undefined, 2)} />
        </BodySmall>
      }
    >
      <ScrollContent>
        <ObjectInspector data={value} />
      </ScrollContent>
    </Cell>
  )
}
