import { TodoTopRightContentHook } from '@thesisedu/feature-classes-react'
import { BasicUserTaskFragment } from '@thesisedu/feature-user-tasks-react/dist/schema'
import { BookmarkBook } from '@thesisedu/react/icons'
import React from 'react'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { ViewSegmentUserTaskFragment } from '../schema'
import { SegmentButton } from '../segment/SegmentLink'

interface ViewPastWorkProps {
  tasks: BasicUserTaskFragment[]
}
function ViewPastWork({ tasks }: ViewPastWorkProps) {
  const completeSegmentId = (tasks[0] as ViewSegmentUserTaskFragment | undefined)?.payload.segmentId
  return completeSegmentId ? (
    <SegmentButton buttonProps={{ type: 'primary', icon: <BookmarkBook /> }} id={completeSegmentId}>
      View past work
    </SegmentButton>
  ) : null
}

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<TodoTopRightContentHook>(
    'classes-react:todo-top-right-content',
    (content, { tasks }) => {
      return [<ViewPastWork key={'view-past-work'} tasks={tasks} />, ...content]
    },
  )
}
