import { DownOutlined } from '@ant-design/icons'
import { transparentize } from 'polished'
import React from 'react'

import { styled } from './styledTypes'

const NONE_VALUE = '--none--'
export interface SimpleSelectOption {
  label?: string
  value: string
}
export interface SimpleSelectProps {
  value?: string
  onChange?: (value: string) => void
  options: (SimpleSelectOption | string)[]
  disabled?: boolean
  style?: any
  allowClear?: boolean
}
export function SimpleSelect({
  value,
  onChange,
  options,
  disabled,
  style,
  allowClear,
}: SimpleSelectProps) {
  return (
    <SimpleSelectContainer className={disabled ? 'disabled' : undefined} style={style}>
      <select
        value={value || NONE_VALUE}
        disabled={disabled}
        onChange={e => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      >
        {allowClear || !value ? <option value={NONE_VALUE}>-- None --</option> : undefined}
        {options.map(opt => {
          const label = typeof opt === 'string' ? opt : opt.label || opt.value
          const value = typeof opt === 'string' ? opt : opt.value
          return (
            <option key={value} value={value}>
              {label}
            </option>
          )
        })}
      </select>
      <div className={'arrow'}>
        <DownOutlined />
      </div>
    </SimpleSelectContainer>
  )
}
const SimpleSelectContainer = styled.div`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  min-width: 150px;
  border-radius: ${props => props.theme['@border-radius-base']};
  background: ${props => props.theme['@gray-1']};
  padding: ${props => props.theme['@size-xs']} 15px;
  transition:
    box-shadow 0.25s linear,
    opacity 0.25s linear;
  box-shadow: 0 0 ${props => props.theme['@size-xxs']} transparent;

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: ${props => props.theme['@font-size-base']};
    cursor: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
  }

  .arrow {
    justify-self: end;
    font-size: 14px;
    width: 14px;
    height: 14px;
    span {
      display: block;
      color: rgba(0, 0, 0, 0.25);
    }
    /* path {
      fill: rgba(0, 0, 0, 0.25);
    } */
  }

  select,
  .arrow {
    grid-area: select;
  }

  &:focus-within {
    box-shadow: 0 0 ${props => props.theme['@size-xxs']}
      ${props => transparentize(0.5, props.theme['@primary-color'])};
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }
`
