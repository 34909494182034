import { useAssignmentTemplateQuery } from '..'
import { FullAssignment } from '../grading/types'

export function useAssignmentTemplateAndConfiguration(
  templateId?: string | null,
  assignment?: FullAssignment,
) {
  const templateResult = useAssignmentTemplateQuery({
    variables: { id: templateId || '' },
  })

  const template =
    templateResult.data?.node?.__typename === 'AssignmentTemplate'
      ? templateResult.data.node
      : undefined

  const configuration = assignment?.configuration
    ? { ...assignment.rawConfiguration, ...assignment.configuration }
    : template?.configuration

  return { template, configuration, loading: templateResult.loading, error: templateResult.error }
}
