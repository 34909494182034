import React from 'react'

import { SharedVariants } from '.'

export interface StyleContextValue {
  size?: SharedVariants.Size.Type
}
export const StyleContext = React.createContext<StyleContextValue | undefined>(undefined)

export interface StyleProviderProps extends StyleContextValue {
  children: React.ReactNode
}
export function StyleProvider({ children, ...value }: StyleProviderProps) {
  return <StyleContext.Provider value={value} children={children} />
}

export function useStyleContext<Props extends Partial<StyleContextValue>>(props: Props): Props {
  const styleContext = React.useContext(StyleContext) ?? {}
  return React.useMemo(
    () => ({
      ...styleContext,
      ...props,
    }),
    [props, styleContext],
  )
}
