import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled } from '@thesisedu/web'
import React, { CSSProperties } from 'react'

import { useGraphicDragDropContext } from './GraphicDragDropContext'
import { Draggable as TDraggable } from './types'

// Fit the below to a linear curve and took the result.
const getRadiusSize = (amount: number) => 0.5 * Math.exp(-0.0975 * amount)
const DRAGGABLE_SIZES = [
  [3, 0.55],
  [2, 0.45],
  [1, 1],
  [0, 0],
]
const RADIUS_DRAGGABLE_OFFSET_AMT = 0

export interface DraggableChild {
  draggable: TDraggable
  correct?: boolean
  child: React.ReactElement
}

export interface MultiDraggableViewProps {
  draggables: DraggableChild[]
  /** In percentage. */
  width: number
  /** In percentage. */
  height: number
  /** From 0 - 1. The size of the draggable relative to the size of the container. 0.4 by default. */
  draggableSize?: number
}
export function MultiDraggableView({
  draggables,
  width: widthCent,
  height: heightCent,
}: MultiDraggableViewProps) {
  const { width: containerWidth = 0, height: containerHeight = 0 } = useGraphicDragDropContext(true)
  const draggableSize = DRAGGABLE_SIZES.find(i => draggables.length >= i[0])![1]
  const draggableBoundsWidthCent = widthCent * draggableSize
  const draggableBoundsHeightCent = heightCent * draggableSize
  const minDimensionPx = Math.min(widthCent * containerWidth, heightCent * containerHeight)
  const radiusSizeMultiple = getRadiusSize(draggables.length - 1)
  const sizeMultiple = radiusSizeMultiple * draggables.length
  const radiusSizePx = minDimensionPx * (sizeMultiple - draggableSize + RADIUS_DRAGGABLE_OFFSET_AMT)
  const angle = 360 / draggables.length

  const renderedDraggables = draggables
    .map(({ draggable, child, correct }, index) => {
      const { filename } = getFileInfo(draggable?.imagePath || '')
      if (filename) {
        const draggableRatio = draggable.width / draggable.height
        const boundsRatio = draggableBoundsWidthCent / draggableBoundsHeightCent
        let _width, _height, _top, _left
        if (draggableRatio > boundsRatio) {
          _width = draggableBoundsWidthCent
          _height = draggableBoundsWidthCent / draggableRatio
          _top = (draggableBoundsHeightCent - _height) / 2
          _left = 0
        } else {
          _width = draggableBoundsHeightCent * draggableRatio
          _height = draggableBoundsHeightCent
          _top = 0
          _left = (draggableBoundsWidthCent - _width) / 2
        }
        const rotation = angle * index
        const multipleProps: CSSProperties =
          draggables.length > 1
            ? {
                marginLeft: -(_width * containerWidth) / 2,
                marginTop: -(_height * containerHeight) / 2,
                // Transforms are applied in order, so we rotate, translate, then rotate back.
                transform: `rotate(${rotation}deg) translate(${radiusSizePx / 2}px) rotate(${
                  rotation * -1
                }deg)`,
                // To override the styles created by the draggable...
                top: '50%',
                left: '50%',
              }
            : {}
        return React.cloneElement(child, {
          draggable: {
            ...child.props.draggable,
            width: _width,
            height: _height,
            x: _left,
            y: _top,
          },
          correct,
          style: {
            ...child.props.style,
            ...multipleProps,
          },
          key: draggable.id,
        })
      } else {
        return null
      }
    })
    .filter(Boolean) as React.ReactElement[]

  if (draggables.length === 1) {
    return <>{renderedDraggables}</>
  }

  const widthPx = widthCent * containerWidth
  const heightPx = heightCent * containerHeight
  return (
    <Container style={{ width: widthPx, height: heightPx }}>
      <RadiusContainer
        style={{
          width: radiusSizePx,
          height: radiusSizePx,
          left: (widthPx - radiusSizePx) / 2,
          top: (heightPx - radiusSizePx) / 2,
          // By default, the radius is contained inside the container.
          transform: `scale(${1 / sizeMultiple})`,
        }}
        className={sizeMultiple <= 1 ? 'no-hover' : ''}
        children={renderedDraggables}
      />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`
const RadiusContainer = styled.div`
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  border-radius: 50%;
  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:not(.no-hover):hover {
    transform: scale(1) !important;
  }
`
