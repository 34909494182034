import { throttle } from 'lodash'
import React from 'react'

import { useScrollableContainerContext } from './ScrollableContainerContext'
import { isHtmlElement } from './isHtmlElement'

export interface UseShrinkingStickyHeaderOpts {
  offsetTop?: number
  height?: number
}
export interface UseShrinkingStickyHeader {
  style: any
  ref: React.RefObject<HTMLDivElement>
  isShrunk: boolean
}
/** @deprecated */
export function useShrinkingStickyHeader({
  offsetTop = 0,
  height,
}: UseShrinkingStickyHeaderOpts = {}): UseShrinkingStickyHeader {
  const ref = React.useRef<HTMLDivElement>(null)
  const [isShrunk, setIsShrunk] = React.useState(false)
  const { target } = useScrollableContainerContext(false) || {}
  React.useEffect(() => {
    if (ref.current && !height) {
      // Forcefully-set the height so we don't get flickering when scrolling up / down
      // the page.
      ref.current.style.height = `${ref.current.clientHeight}px`
      ref.current.style.background = 'transparent'
    }
  }, [])
  React.useEffect(() => {
    if (ref.current) {
      ref.current.style.height = `${height}px`
    }
  }, [height])
  React.useEffect(() => {
    const el = target?.current || window
    const handler = throttle(
      () => {
        const scrollTop = isHtmlElement(el) ? el.scrollTop : el.scrollY
        setIsShrunk(scrollTop > offsetTop)
      },
      10,
      { leading: true, trailing: true },
    )
    el.addEventListener('scroll', handler)
    return () => el.removeEventListener('scroll', handler)
  }, [target, offsetTop])

  return {
    ref,
    isShrunk,
    style: {
      position: 'sticky',
      top: offsetTop,
    },
  }
}
