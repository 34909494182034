import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'

export function useNameFilter(): [string | undefined, React.ReactElement] {
  const [name, setName] = React.useState('')
  const [dName, setDName] = React.useState<string | undefined>(undefined)
  React.useEffect(() => {
    const t = setTimeout(() => {
      setDName(name.trim() ? name : undefined)
    }, 1000)
    return () => clearTimeout(t)
  }, [name])
  return [
    dName,
    <Input
      suffix={<SearchOutlined />}
      placeholder={'Find compositions...'}
      value={name}
      onChange={e => setName(e.target.value)}
    />,
  ]
}
