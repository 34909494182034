import { eventFiles } from '@lexical/rich-text'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $getNodeByKey,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  ElementNode,
  LexicalEditor,
  LexicalNode,
} from 'lexical'
import React from 'react'

import { LINE_WIDTH } from './constants'
import { getDragResizableBlockElement } from './getDragResizableBlockElement'
import {
  $isColumnNode,
  $wrapInColumnNode,
  normalizeWidth,
  widthFromElement,
} from '../../nodes/ColumnNode'
import { $isColumnsNode, ColumnsNode } from '../../nodes/ColumnsNode'
import { isHTMLElement } from '../../utils/guard'
import { hideResizeLine, setResizeLine } from '../../utils/resizeLine'
import { useResizableElement } from '../../utils/useResizableElement'
import { DRAG_DATA_FORMAT } from '../DraggableBlockPlugin/constants'
import { hideTargetLine } from '../DraggableBlockPlugin/targetLine'

function validateEvent(
  event: DragEvent,
  editor: LexicalEditor,
  lineRef: React.RefObject<HTMLDivElement>,
) {
  const [isFileTransfer] = eventFiles(event)
  if (isFileTransfer) return false
  const { pageX, target } = event
  if (!isHTMLElement(target)) {
    return null
  }
  const result = getDragResizableBlockElement(editor, event)
  const line = lineRef.current
  if (result === null || !line) return null

  // If we're not close enough to the edge, ignore...
  const { left: targetLeft, width: targetWidth } = result.blockElement.getBoundingClientRect()
  if (Math.abs(pageX - targetLeft - targetWidth) > LINE_WIDTH * 2) {
    return null
  }

  return result
}

interface ColumnResizeContext {
  nextNode: LexicalNode
  nextNodeElement: HTMLElement
  parent: HTMLElement
  currentWidth: number | null
  elementLeft: number
  nextLeft: number
  nextWidth: number
}
export function useColumnResize(editor: LexicalEditor, anchorElement: HTMLElement) {
  const { children, resizeLineRef } = useResizableElement<ElementNode, ColumnResizeContext>({
    editor,
    anchorElement,
    filterNode(node) {
      return $isColumnNode(node) && !node.isLastChild()
    },
    beforeDrag({ node, element }) {
      const nextNode = editor.getEditorState().read(() => node?.getNextSibling())
      const nextNodeElement = element?.nextSibling as HTMLElement | null | undefined
      const parent = element?.parentElement
      if (!nextNode || !nextNodeElement || !parent) return false
      const currentWidth = widthFromElement(element)
      const { left: elementLeft } = element.getBoundingClientRect()
      const { left: nextLeft, width: nextWidth } = nextNodeElement.getBoundingClientRect()
      return { nextNode, nextNodeElement, parent, currentWidth, elementLeft, nextLeft, nextWidth }
    },
    onMove(e, { context, node, line, element }) {
      const { elementLeft, nextLeft, nextWidth, nextNode } = context
      const minX = elementLeft
      const maxX = nextLeft + nextWidth
      const newRawWidth = (e.clientX - minX) / (maxX - minX)
      const newWidth = normalizeWidth(newRawWidth, 1)
      if (newWidth) {
        const newNextWidth = 1 - newWidth || 0
        if (newWidth !== context.currentWidth) {
          context.currentWidth = newWidth
          editor.update(() => {
            nextNode.setWidth(newNextWidth)
            node.setWidth(newWidth)
          })
          setTimeout(() => {
            setResizeLine(line, element, anchorElement)
          })
        }
      }
    },
  })

  React.useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        DRAGOVER_COMMAND,
        event => {
          const result = validateEvent(event, editor, resizeLineRef)
          const line = resizeLineRef.current
          if (!line) return false
          if (result) {
            setResizeLine(line, result.blockElement, anchorElement, { active: true })
            hideTargetLine()

            // Prevent default event to be able to trigger onDrop events.
            event.preventDefault()
            return true
          } else {
            hideResizeLine(line)
            return false
          }
        },
        // We want this to take precedence over the block menu command.
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        DROP_COMMAND,
        event => {
          const result = validateEvent(event, editor, resizeLineRef)
          const line = resizeLineRef.current
          if (!line) return false
          if (result) {
            // If we're the same node, do nothing...
            const { dataTransfer } = event
            const dragData = dataTransfer?.getData(DRAG_DATA_FORMAT) || ''
            if (dragData === result.blockKey) {
              hideResizeLine(line)
              return false
            }

            // Replace the target element with a column and move this
            // into the new column.
            const targetKey = result.blockKey
            const targetNode = $getNodeByKey(targetKey)
            const draggedNode = $getNodeByKey(dragData)
            if (!targetNode || !draggedNode) {
              hideResizeLine(line)
              return false
            }

            // Is the target element a column? If so, just add another column.
            if ($isColumnsNode(targetNode)) {
              const draggedColumn = $wrapInColumnNode(draggedNode)
              draggedColumn.remove()
              targetNode.append(draggedColumn)
              draggedColumn.select()
            } else {
              // Do the column replacement.
              const targetColumn = $wrapInColumnNode(targetNode)
              const draggedColumn = $wrapInColumnNode(draggedNode)
              const wrapped = $wrapNodeInElement(targetColumn, () => new ColumnsNode())
              draggedColumn.remove()
              wrapped.append(draggedColumn)
              draggedColumn.select()
            }

            hideResizeLine(line)
            return true
          } else {
            return false
          }
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [anchorElement, editor])

  return children
}
