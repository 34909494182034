import { ImageNode } from '@thesisedu/feature-widgets-core/dist/editorNodes/ImageNode'
import React from 'react'

export * from '@thesisedu/feature-widgets-core/dist/editorNodes/ImageNode'
import { ImageComponent } from './ImageComponent'
import { debug } from '../../../log'

ImageNode.prototype.decorate = function (this: ImageNode) {
  return (
    <ImageComponent
      src={this.__src}
      altText={this.__altText}
      attribution={this.__attribution}
      showCaption={this.__showCaption}
      caption={this.__caption}
      radius={this.__radius}
      primaryColor={this.__primaryColor}
      dimensions={this.__dimensions}
      uploadFile={this.__uploadFile}
      afterUpload={(editor, src) => {
        editor.update(() => {
          if (src) {
            debug('updating src', src)
            this.setSrc(src)
          }
          this.clearUploadFile()
        })
      }}
      onUpdateDimensionsAndColor={(editor, dimensions, color) => {
        editor.update(() => {
          debug('updating dimensions and color', dimensions, color)
          this.setDimensions(dimensions)
          this.setPrimaryColor(color)
        })
      }}
      nodeKey={this.getKey()}
    />
  )
}
