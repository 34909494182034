import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import { ClassesReactFeature } from './ClassesReactFeature'

export function NewTeacher() {
  const feature = useFeature(ClassesReactFeature)
  if (feature.options.getNewTeacherContent) {
    const Component = feature.options.getNewTeacherContent()
    return <Component />
  } else return null
}
