import React from 'react'

export interface QuestionRealTimeConfirmationContextValue {
  completeQuestionIds: string[]
  updateQuestion: (id: string, complete: boolean) => void
}
export const QuestionRealTimeConfirmationContext = React.createContext<
  QuestionRealTimeConfirmationContextValue | undefined
>(undefined)

export function useQuestionRealTimeConfirmationContext():
  | QuestionRealTimeConfirmationContextValue
  | undefined
export function useQuestionRealTimeConfirmationContext(
  require: false,
): QuestionRealTimeConfirmationContextValue | undefined
export function useQuestionRealTimeConfirmationContext(
  require: true,
): QuestionRealTimeConfirmationContextValue
export function useQuestionRealTimeConfirmationContext(
  require?: boolean,
): QuestionRealTimeConfirmationContextValue | undefined {
  const context = React.useContext(QuestionRealTimeConfirmationContext)
  if (!context && require) {
    throw new Error('QuestionRealTimeConfirmationContext is required, yet not provided.')
  }
  return context
}
