import {
  CategoryGradesTable,
  isAssignment,
  useGradingModalVisibilityContext,
  GradeAssignmentLink,
  GradeTreeRecord,
  getStudentClassTabName,
} from '@thesisedu/feature-assignments-react'
import { SegmentLink } from '@thesisedu/feature-courses-react'
import React from 'react'

import { isSegmentConfiguration } from '../helpers'

export interface StudentGradesTableProps {
  studentId: string
  classId: string
}
export function StudentGradesTable({ studentId, classId }: StudentGradesTableProps) {
  const context = useGradingModalVisibilityContext(false)
  const renderName = React.useCallback(
    (record: GradeTreeRecord, children: React.ReactElement) => {
      const assignmentRecord = isAssignment(record) ? record : undefined
      const config = assignmentRecord?.rawConfiguration
      const segmentId = config && isSegmentConfiguration(config) ? config.segmentId : undefined
      if (context && assignmentRecord) {
        return (
          <GradeAssignmentLink
            assignment={assignmentRecord}
            defaultTab={getStudentClassTabName(studentId, classId)}
            children={children}
          />
        )
      } else if (segmentId) {
        return <SegmentLink id={segmentId} children={children} />
      } else {
        return children
      }
    },
    [!!context],
  )
  return <CategoryGradesTable classId={classId} studentIds={[studentId]} renderName={renderName} />
}
