import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { Form, Text, useToast, HSpaced } from '@thesisedu/ui'
import { SendDiagonal } from '@thesisedu/ui/icons'
import React from 'react'

import { useOptions } from '../../options'
import { useInviteStudentMutation } from '../../queries/useInviteStudentMutation'
import { InviteResult } from '../../schema'
import { ImportStudentModeResource, MainContentComponentProps } from '../../types'

function AddByEmail({ class: cls }: MainContentComponentProps) {
  const form = Form.useForm()
  const root = useFeatureRoot()
  const options = useOptions()
  const toast = useToast()
  const [inviteStudent, { loading }] = useInviteStudentMutation({
    onCompleted: data => {
      if (data.inviteStudent.invited === InviteResult.AddedToClass) {
        toast({
          title: 'Student added successfully!',
          status: 'success',
        })
      } else {
        toast({
          title: `Student invited to join ${root!.appOptions.name}`,
          status: 'success',
        })
      }
      form.reset()
    },
  })
  return (
    <>
      <Text bottom={'m'} color={'secondary'}>
        Type in your student's email below and click Invite. We will send an email to this student
        with instructions on how to create a {options.name} account and join this class.
      </Text>
      <Form
        form={form}
        onFinish={values => {
          if (!cls) return
          inviteStudent({
            variables: {
              input: {
                id: cls.id,
                email: values.email,
              },
            },
          })
        }}
      >
        <HSpaced align={'flex-start'} space={'xs'}>
          <Form.TextField
            name={'email'}
            required
            type={'email'}
            placeholder={'first.last@stu.schools.us'}
            style={{ flex: 1 }}
          />
          <Form.Submit children={'Invite'} loading={loading} />
        </HSpaced>
      </Form>
    </>
  )
}

export const Email: ImportStudentModeResource = {
  type: 'ImportStudentMode',
  identifier: 'email',
  title: 'Invite via Email',
  icon: <SendDiagonal />,
  MainContentComponent: AddByEmail,
  weight: 1,
}
