import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, Popconfirm, AntIconWrapper } from '@thesisedu/react'
import { Trash } from '@thesisedu/react/icons'
import React from 'react'

export interface DeleteStandardSetButtonProps {
  standardSetId: string
  onDeleted: () => void
}
export function DeleteStandardSetButton({
  onDeleted,
  standardSetId,
}: DeleteStandardSetButtonProps) {
  const [del] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that standard set.',
    refetchQueries: ['standardSets'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      onDeleted()
    },
  })

  return (
    <Popconfirm
      okButtonProps={{ danger: true }}
      okText={'Yes, delete'}
      cancelText={'No, keep'}
      title={'Are you sure you want to delete this standard set?'}
      onConfirm={() => del({ variables: { id: standardSetId } })}
    >
      <Button
        danger
        children={'Delete Standard Set'}
        icon={<AntIconWrapper children={<Trash />} />}
      />
    </Popconfirm>
  )
}
