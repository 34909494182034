import React from 'react'
import { createGlobalStyle } from 'styled-components'

import { ModalProps, Modal } from './Modal'
import { H2 } from './Typography'
import { styled, ThemeContext } from './styledTypes'

export interface Transform {
  x: number
  y: number
}

const MIN_SIZE = 200

export interface ResizeableModalProps extends ModalProps {
  defaultWidth?: number
  defaultHeight?: number
  onResize?: (width: number, height: number) => void
}
export function ResizeableModal({
  title,
  children,
  defaultWidth = 500,
  defaultHeight = 300,
  onResize,
  ...modalProps
}: React.PropsWithChildren<ResizeableModalProps>) {
  const theme = React.useContext(ThemeContext)
  const size = React.useRef<Transform>({
    x: defaultWidth,
    y: defaultHeight,
  }).current
  const transform = React.useRef<Transform>({
    x: (window.innerWidth - size.x) / 2,
    y: 0,
  }).current
  const [, setVisibleKey] = React.useState(0)
  React.useEffect(() => {
    if (modalProps.visible) {
      size.x = defaultWidth
      size.y = defaultHeight
      onResize?.(size.x, size.y)
      // Force a re-render to get the size to update.
      setTimeout(() => {
        setVisibleKey(k => k + 1)
      }, 100)
    }
  }, [modalProps.visible])
  return (
    <>
      <ResizeableGlobalStyle />
      <Modal
        {...modalProps}
        transitionName={''}
        className={'resizeable-modal'}
        width={size.x}
        style={{
          transform: `translateX(${transform.x}px) translateY(${transform.y}px)`,
          maxWidth: '100vw',
          maxHeight: '100vw',
          minWidth: 100,
          minHeight: 100,
          width: size.x,
          height: size.y,
          margin: 0,
          top: 0,
          paddingBottom: 0,
        }}
        customTitle={
          <DraggableTitle
            onMouseDown={event => {
              if (event.button !== 0) return
              const targetElement = event.target as HTMLDivElement
              const modal = targetElement.closest<HTMLDivElement>('.feature-modal')
              const startTransform = { ...transform }
              const startClient = { x: event.clientX, y: event.clientY }
              const move = (event: MouseEvent) => {
                if (modal) {
                  const deltaX = event.clientX - startClient.x
                  const deltaY = event.clientY - startClient.y
                  transform.x = Math.min(window.innerWidth, Math.max(0, startTransform.x + deltaX))
                  transform.y = Math.min(window.innerHeight, Math.max(0, startTransform.y + deltaY))
                  modal.style.transform = `translateX(${transform.x}px) translateY(${transform.y}px)`
                }
              }
              const up = () => {
                document.removeEventListener('mousemove', move)
                document.removeEventListener('mouseup', up)
              }
              document.addEventListener('mousemove', move)
              document.addEventListener('mouseup', up)
            }}
          >
            {title ? (
              <H2 isBlock={false} style={{ lineHeight: 1, paddingTop: theme['@size-xs'] }}>
                {title}
              </H2>
            ) : null}
          </DraggableTitle>
        }
      >
        {children}
        <Resizer
          onMouseDown={event => {
            const targetElement = event.target as HTMLDivElement
            const modal = targetElement.closest<HTMLDivElement>('.feature-modal')
            const startSize = { ...size }
            const startClient = { x: event.clientX, y: event.clientY }
            const move = (event: MouseEvent) => {
              if (modal) {
                const deltaX = event.clientX - startClient.x
                const deltaY = event.clientY - startClient.y
                size.x = Math.max(MIN_SIZE, Math.min(startSize.x + deltaX, window.innerWidth))
                size.y = Math.max(MIN_SIZE, Math.min(startSize.y + deltaY, window.innerHeight))
                modal.style.width = `${size.x}px`
                modal.style.height = `${size.y}px`
                onResize?.(size.x, size.y)
              }
            }
            const up = () => {
              document.removeEventListener('mousemove', move)
              document.removeEventListener('mouseup', up)
            }
            document.addEventListener('mousemove', move)
            document.addEventListener('mouseup', up)
          }}
        />
      </Modal>
    </>
  )
}

const DraggableTitle = styled.div`
  cursor: move;
  min-height: 50px;
  width: 100%;
  margin-top: -18px;
  margin-left: -22px;
  margin-right: -22px;
  padding: 18px 22px 0 22px;
  user-select: none;
`
const Resizer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  cursor: nwse-resize;
`
const ResizeableGlobalStyle = createGlobalStyle`
  .resizeable-modal {
    .ant-modal-header {
      padding-top: 0;
    }
    .ant-modal-close-x {
      line-height: 30px;
    }
    .ant-modal-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      border-radius: 8px !important;
      .ant-modal-body {
        flex: 1;
        overflow: auto;
        img {
          max-width: 100%;
        }
      }
    }
  }
`
