import { LooseTagSummary, TagEditor } from '@thesisedu/feature-tags-react'
import {
  Styled,
  TypeMenuItem,
  AddTypeDropdown,
  AddTypeDropdownProps,
} from '@thesisedu/feature-tags-react/dist/TagsEditorModal'
import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import {
  BodyLarge,
  H2,
  H3Alternate,
  Button,
  AntIconWrapper,
  LoadingIndicator,
  Modal,
} from '@thesisedu/react'
import { ArrowLeft, EmptyPage, Plus } from '@thesisedu/react/icons'
import { Transition } from '@thesisedu/web'
import { orderBy, partition } from 'lodash'
import React from 'react'

import { StandardSetFragment, useStandardSetsQuery } from '../schema'

function getStandardSetsWithValue(value?: LooseTagSummary): string[] {
  return Object.keys(value || {})
    .filter(
      key =>
        !!value?.[key]?.length &&
        key.startsWith('SS_') &&
        !isNaN(parseFloat(key.replace('SS_', ''))),
    )
    .map(key => toGlobalId('StandardSet', key.replace('SS_', '')))
}

function getStandardSetTagType(standardSet: StandardSetFragment) {
  return `SS_${fromGlobalId(standardSet.id, true).id}`
}

export interface StandardsEditorModalProps {
  value?: LooseTagSummary
  onChange?: (value: LooseTagSummary) => void
  visible?: boolean
  onClose?: () => void
}
export function StandardsEditorModal({
  value,
  onChange,
  visible,
  onClose,
}: StandardsEditorModalProps) {
  const { data, loading } = useStandardSetsQuery({
    variables: {
      first: 1000,
    },
  })
  const standardSets = data?.standardSets.edges.map(edge => edge.node) || []
  const [standardSetIdsWithValue, setStandardSetIdsWithValue] = React.useState(
    getStandardSetsWithValue(value),
  )
  React.useEffect(() => {
    if (visible) {
      setStandardSetIdsWithValue(getStandardSetsWithValue(value))
    }
  }, [visible])
  const [visibleStandardSets, remainingStandardSets] = partition(standardSets, standardSet =>
    standardSetIdsWithValue.includes(standardSet.id),
  )
  const [selectedStandardSetId, setSelectedStandardSetId] = React.useState<string | null>(
    visibleStandardSets[0]?.id || null,
  )
  const selectedStandardSet = standardSets.find(s => s.id === selectedStandardSetId)
  const addProps = {
    remainingTypes: remainingStandardSets.map(standardSet => ({
      type: standardSet.id,
      label: standardSet.name,
    })),
    onSelected: (id: string) => {
      setStandardSetIdsWithValue(t => [...t, id])
      setSelectedStandardSetId(id)
    },
  }
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      bodyStyle={{ padding: 0, maxWidth: '90vw' }}
      width={900}
    >
      {loading ? (
        <LoadingIndicator block />
      ) : (
        <Styled.ContentContainer>
          <Styled.LeftContainer>
            <Styled.Header>
              <H2>Standards</H2>
            </Styled.Header>
            <Styled.NavItemsContainer>
              {orderBy(visibleStandardSets, 'name', 'asc').map(standardSet => {
                const count = (value?.[getStandardSetTagType(standardSet)] || []).length
                return (
                  <TypeMenuItem
                    label={standardSet.name}
                    key={standardSet.id}
                    selected={selectedStandardSetId === standardSet.id}
                    count={count}
                    onClick={() => setSelectedStandardSetId(standardSet.id)}
                  />
                )
              })}
              <AddTypeDropdown {...addProps}>
                <TypeMenuItem label={'Add Standard Set...'} isAdd />
              </AddTypeDropdown>
            </Styled.NavItemsContainer>
          </Styled.LeftContainer>
          <Styled.TagContainer>
            <Transition
              type={'fade-scale'}
              state={selectedStandardSetId || 'none'}
              containerProps={{ style: { height: '100%' } }}
            >
              {selectedStandardSet ? (
                <Styled.TagContainerInner>
                  <H3Alternate>{selectedStandardSet.name}</H3Alternate>
                  <Styled.TagEditorContainer>
                    <TagEditor
                      value={(value
                        ? value[getStandardSetTagType(selectedStandardSet)] || []
                        : []
                      ).map(name => ({
                        type: getStandardSetTagType(selectedStandardSet),
                        name,
                      }))}
                      type={getStandardSetTagType(selectedStandardSet)}
                      typeLabel={selectedStandardSet.name}
                      searchProps={{
                        noAdd: true,
                      }}
                      onChange={tags => {
                        if (onChange) {
                          onChange({
                            ...value,
                            [getStandardSetTagType(selectedStandardSet)]: tags.map(tag => tag.name),
                          })
                        }
                      }}
                    />
                  </Styled.TagEditorContainer>
                </Styled.TagContainerInner>
              ) : (
                <NoStandardsSelected typeDropdownProps={addProps} value={value} />
              )}
            </Transition>
          </Styled.TagContainer>
        </Styled.ContentContainer>
      )}
    </Modal>
  )
}

interface NoStandardsSelectedProps extends Pick<StandardsEditorModalProps, 'value'> {
  typeDropdownProps: Omit<AddTypeDropdownProps, 'children'>
}
function NoStandardsSelected({ value, typeDropdownProps }: NoStandardsSelectedProps) {
  const hasValue = getStandardSetsWithValue(value)?.length
  return (
    <Styled.NoTypesSelectedContainer>
      <H2 color={'@text-color-secondary'}>{hasValue ? <ArrowLeft /> : <EmptyPage />}</H2>
      <BodyLarge color={'@text-color-secondary'} isBlock>
        {hasValue ? (
          <span>Select a standard set from the menu at the left to get started.</span>
        ) : (
          <span>
            You haven't added any standards yet. To get started, add a standard by using the button
            below.
          </span>
        )}
      </BodyLarge>
      {hasValue ? null : (
        <AddTypeDropdown {...typeDropdownProps}>
          <Button
            type={'primary'}
            icon={
              <AntIconWrapper>
                <Plus />
              </AntIconWrapper>
            }
          >
            Add Standards
          </Button>
        </AddTypeDropdown>
      )}
    </Styled.NoTypesSelectedContainer>
  )
}
