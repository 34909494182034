import React from 'react'
import { useParams } from 'react-router-dom'

import { NewExploreTag } from '../../../explore/NewExploreTag'
import { useExploreGridFilters } from '../../../explore/useExploreGridFilters'

export function ContentArea() {
  const { tagId } = useParams()
  const filters = useExploreGridFilters()
  return (
    <NewExploreTag
      tagId={tagId}
      children={filters.filter(filter => filter.key !== 'content-area')}
    />
  )
}
