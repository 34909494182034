import { Block } from '@thesisedu/react'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

import { GradingModalSidebarAttemptsList } from './GradingModalSidebarAttemptsList'
import { StudentClassTabResult } from './helpers'
import { FullAssignment } from './types'

export interface GradingModalSidebarAttemptsProps {
  studentTab: StudentClassTabResult | null
  assignment: FullAssignment
}
export function GradingModalSidebarAttempts({
  studentTab,
  assignment,
}: GradingModalSidebarAttemptsProps) {
  const canManageAttempts =
    assignment.configuration?.studentsManageAttempts && assignment.maxAttempts > 1
  return (
    <AnimatePresence exitBeforeEnter>
      {studentTab && assignment.maxAttempts > 1 && !canManageAttempts ? (
        <motion.div
          transition={{ duration: 0.25 }}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0, transition: { ease: 'easeOut' } }}
          exit={{ opacity: 0, x: -50, transition: { ease: 'easeIn' } }}
          key={[studentTab.classId, studentTab.studentId].join('')}
        >
          <Block marginTop={'@size-m'}>
            <GradingModalSidebarAttemptsList
              assignment={assignment}
              studentId={studentTab.studentId}
              classId={studentTab.classId}
            />
          </Block>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
