import { useCachedStudentUserCallback, useStudentUser } from '@thesisedu/feature-classes-react'
import {
  ReportDimensionTableHeaderProps,
  useReportGridContext,
} from '@thesisedu/feature-reports-react'
import { LoadingIndicator, SolidIcon } from '@thesisedu/react'
import { DeleteCircle } from '@thesisedu/react/icons'
import { BodySmall, styled } from '@thesisedu/web'
import React from 'react'

import {
  StudentsReportDimensionInput,
  StudentsReportDimensionResultFragment,
} from '../../../schema'
import { StudentsOptionsContext } from '../MultipleStudents'

export function useGetStudentHeader() {
  const getStudent = useCachedStudentUserCallback()
  return ({ result }: ReportDimensionTableHeaderProps<StudentsReportDimensionResultFragment>) => {
    const student = getStudent(result.student.id, result.class.id)
    return student?.user.name || student?.user.username || 'Unknown Student'
  }
}

export function StudentHeader({
  result,
  type,
}: ReportDimensionTableHeaderProps<StudentsReportDimensionResultFragment>) {
  const { opts, setOpts } = useReportGridContext(true)
  const { student, error, loading } = useStudentUser(result.student.id, result.class.id)
  const loadingContent = loading ? (
    <LoadingIndicator />
  ) : error ? (
    <SolidIcon color={'red'} children={<DeleteCircle />} />
  ) : null
  if (type === 'column') {
    const firstName = student?.user.firstName
    const lastName = student?.user.lastName || student?.user.username || 'Student'
    return (
      <Container
        onClick={e => {
          e.preventDefault()
          setOpts({
            ...opts,
            metrics: [],
            dimensions: [
              {
                context: {
                  firstName: student?.user.name || student?.user.username || 'Student',
                } as StudentsOptionsContext,
                identifier: 'OneStudent',
                options: {
                  studentPairs: [
                    {
                      studentId: result.student.id,
                      classId: result.class.id,
                    },
                  ],
                } as StudentsReportDimensionInput,
              },
              ...opts.dimensions.slice(1),
            ],
          })
        }}
      >
        {loadingContent ? (
          loadingContent
        ) : (
          <>
            <Small>{lastName}</Small>
            {firstName ? (
              <Small color={'@text-color-secondary'} style={{ fontSize: 12 }}>
                {firstName}
              </Small>
            ) : null}
          </>
        )}
      </Container>
    )
  } else if (type === 'row') {
    return (
      <Small>
        {loadingContent
          ? loadingContent
          : student?.user.name || student?.user.username || 'Student'}
      </Small>
    )
  } else return null
}

const Container = styled.div`
  cursor: pointer;
`
const Small = styled(BodySmall)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
