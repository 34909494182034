import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Popconfirm, message } from 'antd'
import React from 'react'

import { TeachersForSiteDocument } from './schema'

export interface DeleteTeacherButtonProps {
  children: React.ReactElement<{ onClick?: () => any }>
  teacherId: string
  siteId: string
  goBack?: boolean
}
export const DeleteTeacherButton: React.FC<React.PropsWithChildren<DeleteTeacherButtonProps>> = ({
  children,
  teacherId,
  siteId,
  goBack,
}) => {
  const [deleteTeacher] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that teacher.',
    onCompleted: () => {
      message.success('Teacher deleted successfully.')
      if (goBack) {
        window.history.back()
      }
    },
    refetchQueries: [{ query: TeachersForSiteDocument, variables: { siteId } }],
    awaitRefetchQueries: true,
    variables: { id: teacherId },
  })
  return (
    <Popconfirm
      title={'Are you sure you want to delete this teacher?'}
      onConfirm={() => deleteTeacher()}
      okText={'Yes, delete'}
      okType={'danger'}
      cancelText={'No, keep'}
      placement={'topRight'}
    >
      {children}
    </Popconfirm>
  )
}
