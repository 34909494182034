import { _getAssignInput } from './getAssignInput'
import { SettingsFormValues } from './types'
import { transformRubric } from '../rubric/helpers'
import {
  AssignmentAssignedStudentsFragment,
  AssignmentWithConfigurationFragment,
  BasicAssignmentFragment,
} from '../schema'

export function assignmentToFormValues(
  assignment: BasicAssignmentFragment &
    AssignmentAssignedStudentsFragment &
    Pick<AssignmentWithConfigurationFragment, 'configuration'>,
): SettingsFormValues {
  const anyAssignment = assignment as any
  return {
    ...assignment,
    rubric: assignment.rubric ? transformRubric(assignment.rubric) : undefined,
    assign: _getAssignInput(assignment),
    assignmentCategoryIds: assignment.assignmentCategories.edges.map(edge => edge.node.id),
    revealAttempts: assignment.revealAttempts ?? assignment.maxAttempts,
    configuration: {
      ...anyAssignment.configuration,
      isRandomized: anyAssignment.configuration?.isRandomized,
    },
  }
}
