import { CreateClassContentHook } from '@thesisedu/feature-classes-react'
import React from 'react'

import { CoursesReactFeature } from '../CoursesReactFeature'
import { Create } from '../classes/Create'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<CreateClassContentHook>(
    'classes-react:create-class',
    content => {
      return [...content, <Create />]
    },
  )
}
