export { SelectButton } from './select/SelectButton'
export { SelectButtonContent } from './select/SelectButtonContent'
export * from './select/types'
export { SingleSelectButton, SingleSelectButtonProps } from './select/SingleSelectButton'
export { InfiniteQueryList, InfiniteQueryListProps } from './InfiniteQueryList'
export { PersistenceMenuItem } from './PersistenceMenuItem'

import { RequiredVariables } from './loadMore'
import { InfiniteQueryProps as _InfiniteQueryProps } from './useInfiniteQuery'

/**
 * @deprecated Use InfiniteQueryProps from the main entry point instead.
 */
export type InfiniteQueryProps<
  Item,
  Data,
  Variables extends RequiredVariables,
> = _InfiniteQueryProps<Item, Data, Variables>

/**
 * @deprecated Use InfiniteQuery from the main entry point instead.
 */
export { InfiniteQuery } from './InfiniteQuery'
