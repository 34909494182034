import { ArrowRightOutlined } from '@ant-design/icons'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { BodySmall, Body, styled } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { Interaction } from './types'

const Container = styled.div<{ isUnread?: boolean }>`
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  transition: background 0.25s linear;
  background: ${props =>
    props.isUnread ? props.theme['@primary-color-extra-light'] : 'transparent'};
  display: flex;
  align-items: center;
  > div:last-child:not(:first-child) {
    margin-left: auto;
  }
`

export interface InteractionListItemProps {
  targetChildren?: React.ReactElement | string
  userChildren?: React.ReactElement | string
  fragment: Interaction
  linkProps?: {
    to?: string
    onClick?: () => void
  }
}
export function InteractionListItem({
  children,
  targetChildren,
  userChildren,
  fragment,
  linkProps,
}: React.PropsWithChildren<InteractionListItemProps>) {
  const viewer = useViewerContext(false)
  const startMoment = moment.utc(fragment.periodStart).local()
  const endMoment = moment.utc(fragment.periodEnd).local()
  const timeDifference = moment.duration(endMoment.diff(startMoment))
  const timeString = timeDifference.asMilliseconds() > 999 ? timeDifference.humanize() : null
  const ago = startMoment.fromNow()
  const timeInfo = timeString ? `For ${timeString}, ${ago}` : ago
  const isTarget = viewer && fragment.targetUser?.id === viewer.id && targetChildren
  const isSource = viewer && fragment.user.id === viewer.id
  let childrenContent = children
  if (isTarget) {
    childrenContent = targetChildren
  } else if (userChildren) {
    childrenContent = (
      <>
        {isSource ? 'You' : fragment.user.name || fragment.user.username} {userChildren}
      </>
    )
  }
  const content = (
    <Container isUnread={fragment.isUnread}>
      <div>
        <Body color={linkProps ? '@primary-color' : undefined}>{childrenContent}</Body>
        <BodySmall color={'@text-color-secondary'}>{timeInfo}</BodySmall>
      </div>
      <div>{linkProps ? <ArrowRightOutlined /> : null}</div>
    </Container>
  )

  if (linkProps?.to) {
    return (
      <Link to={linkProps.to} {...linkProps}>
        {content}
      </Link>
    )
  } else if (linkProps?.onClick) {
    return <a onClick={linkProps.onClick}>{content}</a>
  } else {
    return content
  }
}
