import { Segment } from '@thesisedu/feature-course-types'
import { useResource } from '@thesisedu/feature-react'
import { ReactWindowScroller } from '@thesisedu/react'
import {
  GridProps,
  VariableSizeGrid as Grid,
  IVariableSizeGrid,
  areEqual,
} from '@thesisedu/react-virtual'
import { usePlaceholder, StyledThemeContext, styled } from '@thesisedu/web'
import React from 'react'
import ResizeObserver from 'react-resize-detector'

import { SegmentGridItem } from './SegmentGridItem'
import { SegmentGridPlaceholder } from './SegmentGridPlaceholder'
import { SegmentGridRendererResource } from './types'
import composeRefs from '../../composeRefs'

interface SegmentGridContextValue {
  segments: SegmentWithResource[]
  onClick?: (segment: Segment) => void
}
export const SegmentGridContext = React.createContext<SegmentGridContextValue>({
  segments: [],
})
interface SegmentInnerGridContextValue {
  numColumns: number
  itemWidth: number
}
const SegmentInnerGridContext = React.createContext<SegmentInnerGridContextValue>({
  numColumns: 0,
  itemWidth: 0,
})

interface SegmentWithResource extends Segment {
  resource: SegmentGridRendererResource
}

const GRID_ITEM_WIDTH_SPAN = [250, 350]
const MAX_COLUMNS = 10
function getItemWidthAndColumns(
  gutter: number,
  totalWidth: number,
): { itemWidth: number; numColumns: number } {
  for (let i = MAX_COLUMNS; i > 0; i--) {
    const itemWidth = (totalWidth - gutter * (i - 1)) / i
    if (itemWidth >= GRID_ITEM_WIDTH_SPAN[0] && itemWidth <= GRID_ITEM_WIDTH_SPAN[1]) {
      return { itemWidth, numColumns: i }
    }
  }
  return { itemWidth: GRID_ITEM_WIDTH_SPAN[0], numColumns: 1 }
}

interface SegmentGridInnerProps {
  width: number
  segments: SegmentWithResource[]
}
function SegmentGridInner({ width, segments: segmentsWithResources }: SegmentGridInnerProps) {
  const theme = React.useContext(StyledThemeContext)
  const gutter = parseFloat(theme['@size-l'].replace('px', ''))
  const { itemWidth, numColumns } = getItemWidthAndColumns(gutter, width || 0)
  const gridRef = React.useRef<IVariableSizeGrid>(null)
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      })
    }
  }, [width])

  return (
    <ReactWindowScroller<GridProps> isGrid>
      {({ ref, outerRef, style, onScroll }) => {
        const numRows = Math.ceil(segmentsWithResources.length / numColumns)
        return (
          <SegmentInnerGridContext.Provider value={{ numColumns, itemWidth }}>
            <Grid
              columnCount={numColumns}
              columnWidth={columnIndex => {
                if (columnIndex === 0) return itemWidth
                else return itemWidth + gutter
              }}
              rowCount={numRows}
              rowHeight={rowIndex => {
                return Array(numColumns)
                  .fill('')
                  .reduce((acc, _, columnIndex) => {
                    const segmentWithResource =
                      segmentsWithResources[rowIndex * numColumns + columnIndex]
                    const resource = segmentWithResource?.resource
                    return resource
                      ? Math.max(
                          acc,
                          resource.height({
                            width: itemWidth,
                            segment: segmentWithResource,
                          }),
                        )
                      : acc
                  }, 0)
              }}
              height={window.innerHeight}
              width={width}
              outerRef={outerRef}
              ref={composeRefs(ref, gridRef)}
              onScroll={onScroll}
              style={style}
              children={SegmentGridCell}
            />
          </SegmentInnerGridContext.Provider>
        )
      }}
    </ReactWindowScroller>
  )
}

export function getSegmentGridRendererResource(
  resources: SegmentGridRendererResource[],
  segment: Segment,
) {
  let matchingResource
  let matchingWeight = Infinity
  for (const resource of resources) {
    const weight = resource.weight(segment)
    if (weight !== null && weight < matchingWeight) {
      matchingResource = resource
      matchingWeight = weight
    }
  }

  return matchingResource || null
}

export interface SegmentGridProps {
  segments: Segment[]
  onClick?: (segment: Segment) => void
}
export function SegmentGrid({ segments, onClick }: SegmentGridProps) {
  const renderers = useResource<SegmentGridRendererResource>('SegmentGridRenderer')
  const segmentsWithResources = React.useMemo<SegmentWithResource[]>(() => {
    return segments
      .map(segment => {
        const matchingResource = getSegmentGridRendererResource(renderers, segment)
        return matchingResource ? { ...segment, resource: matchingResource } : null
      })
      .filter(Boolean) as SegmentWithResource[]
  }, [segments.map(s => s.id).join(',')])
  return (
    <SegmentGridContext.Provider value={{ segments: segmentsWithResources, onClick }}>
      <ResizeObserver
        handleWidth
        refreshMode={'debounce'}
        refreshRate={1000}
        refreshOptions={{ leading: true, trailing: true }}
        render={({ width }) => {
          return (
            <div style={{ width: '100%' }}>
              {width ? <SegmentGridInner width={width} segments={segmentsWithResources} /> : null}
            </div>
          )
        }}
      />
    </SegmentGridContext.Provider>
  )
}

interface SegmentGridCellProps {
  style?: any
  columnIndex: number
  rowIndex: number
}
const SegmentGridCell = React.memo(({ columnIndex, rowIndex, style }: SegmentGridCellProps) => {
  const { segments } = React.useContext(SegmentGridContext)
  const { numColumns } = React.useContext(SegmentInnerGridContext)
  const segment = segments[rowIndex * numColumns + columnIndex]
  return usePlaceholder({
    placeholder: (
      <CellContainer style={style} index={columnIndex}>
        <SegmentGridPlaceholder />
      </CellContainer>
    ),
    getChildren: React.useCallback(() => {
      if (segment) {
        return (
          <CellContainer style={style} index={columnIndex}>
            <SegmentGridCellContent segment={segment} />
          </CellContainer>
        )
      } else {
        return <CellContainer style={style} index={columnIndex} />
      }
    }, [columnIndex, rowIndex, segment?.id, style]),
  })
}, areEqual)
SegmentGridCell.displayName = 'SegmentGridCell'

interface SegmentGridCellContentProps {
  segment: SegmentWithResource
}
function SegmentGridCellContent({ segment }: SegmentGridCellContentProps) {
  const { onClick } = React.useContext(SegmentGridContext)
  const { itemWidth } = React.useContext(SegmentInnerGridContext)
  return (
    <SegmentGridItem
      segment={segment}
      resource={segment.resource}
      width={itemWidth}
      onClick={onClick}
    />
  )
}

const CellContainer = styled.div<{ index: number }>`
  padding-left: ${props => (props.index === 0 ? 0 : props.theme['@size-l'])};
`
