import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { useDefaultRubricTypesQuery } from './schema'

export type RubricTypeSelectProps = SelectProps<string>
export function RubricTypeSelect(props: RubricTypeSelectProps) {
  const { data } = useDefaultRubricTypesQuery()
  const defaultRubricTypes = data?.defaultRubricTypes || []
  return (
    <Select
      {...props}
      showSearch
      filterOption={(input, option) => {
        return (
          typeof option?.children === 'string' &&
          (option?.children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }}
      options={defaultRubricTypes.map(cat => ({
        value: cat,
        label: cat,
      }))}
    />
  )
}
