import {
  styledContainer as styled,
  getColor,
  getSize,
  useTheme,
  LoadingIndicator,
  AntIconWrapper,
} from '@thesisedu/react'
import { WarningCircle, Redo, Check } from '@thesisedu/react/icons'
import { Progress } from 'antd'
import React from 'react'

import { PartialUpload, UploadStatus } from './types'

const Container = styled<{ borderColor: string }>`
  border-radius: 50%;
  border: solid ${props => getSize(props.theme, '@size-xxs')} ${props =>
    getColor(props.theme, props.borderColor)};
  color: ${props => getColor(props.theme, props.borderColor)};
  font-size: ${props => getSize(props.theme, '@size-s')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${props => getSize(props.theme, '@size-l')};
  height: ${props => getSize(props.theme, '@size-l')};
`
const ProgressContainer = styled`
  position: relative;
`
const StopContainer = styled`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Stop = styled<{ color?: string }>`
  width: 12px;
  height: 12px;
  background: ${props => getColor(props.theme, props.color || '@primary-color')};
`
export interface UploadStatusIconProps {
  upload: PartialUpload
  background?: string
}
export const UploadingStatusIcon: React.FC<React.PropsWithChildren<UploadStatusIconProps>> = ({
  upload,
}) => {
  const theme = useTheme()
  return (
    <ProgressContainer>
      <Progress
        width={parseFloat(theme['@size-l'].replace('px', ''))}
        type={'circle'}
        strokeWidth={parseFloat(theme['@size-xs'].replace('px', ''))}
        percent={(upload.uploaded || 0) * 100}
        showInfo={false}
        status={'normal'}
        strokeColor={theme['@primary-color']}
      />
      <StopContainer>
        <Stop />
      </StopContainer>
    </ProgressContainer>
  )
}

export const ProcessingStatusIcon: React.FC<
  React.PropsWithChildren<UploadStatusIconProps>
> = () => {
  const theme = useTheme()
  return <LoadingIndicator size={'@size-l'} color={getColor(theme, '@primary-color')} />
}

export const WaitingStatusIcon: React.FC<React.PropsWithChildren<UploadStatusIconProps>> = () => (
  <Container borderColor={'@gray-2'}>
    <StopContainer>
      <Stop color={'@gray-4'} />
    </StopContainer>
  </Container>
)

export const FailedStatusIcon: React.FC<React.PropsWithChildren<UploadStatusIconProps>> = ({
  upload,
}) => {
  const IconComponent = upload.fileBlob ? Redo : WarningCircle
  return (
    <Container borderColor={'@orange'}>
      <IconComponent />
    </Container>
  )
}

export const CompleteStatusIcon: React.FC<React.PropsWithChildren<UploadStatusIconProps>> = () => {
  return (
    <Container borderColor={'@blue'}>
      <AntIconWrapper children={<Check />} />
    </Container>
  )
}

export const UploadStatusIcon: React.FC<React.PropsWithChildren<UploadStatusIconProps>> = props => {
  const { upload } = props
  switch (upload.currentStatus) {
    case UploadStatus.Waiting:
      return <WaitingStatusIcon {...props} />
    case UploadStatus.Uploading:
    case UploadStatus.WaitingConnection:
    case UploadStatus.Submitting:
      if (upload.uploaded) {
        return <UploadingStatusIcon {...props} />
      } else {
        return <ProcessingStatusIcon {...props} />
      }
    case UploadStatus.Uploaded:
    case UploadStatus.Processing:
      return <ProcessingStatusIcon {...props} />
    case UploadStatus.Failed:
      return <FailedStatusIcon {...props} />
    case UploadStatus.Complete:
      return <CompleteStatusIcon {...props} />
  }
}
