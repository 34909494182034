import * as React from 'react'

function SvgE(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'}>
        <path
          fill={'#F2E70C'}
          d={
            'M255.049 194.616a64.478 64.478 0 01.848 14.673 80.567 80.567 0 01-2.714 18.45 53.487 53.487 0 01-14.968 24.677c-8.442 8.185-18.48 13.842-29.038 18.65a156.911 156.911 0 01-42.429 12.243 239.42 239.42 0 01-31.462 2.669c-2.415.05-4.82 0-7.225 0a244.627 244.627 0 01-36.083-2.189c-21.164-2.839-41.192-9.115-59.582-20.15a73.783 73.783 0 01-20.087-17.08 53.774 53.774 0 01-10.977-25.197 89.374 89.374 0 01-1.207-20.1 52.017 52.017 0 0112.493-30.853c3.672-4.407 8.073-7.995 12.464-11.644a25.464 25.464 0 004.48-4.377 7.822 7.822 0 001.856-4.338 31.786 31.786 0 012.584-11.084c5.928-14.452 10.528-29.444 17.024-43.687a87.584 87.584 0 0116.555-24.886c.838-.86 1.686-1.72 2.584-2.54 2.864-2.718 5.987-5.166 8.831-7.995 4.191-4.128 9.57-6.416 15.068-8.315a95.917 95.917 0 0125.505-4.758 114.793 114.793 0 0129.597 1.85c6.705 1.338 13.281 3.157 19.209 6.606 11.455 6.626 21.514 14.842 28.858 26.066a122.421 122.421 0 0111.475 22.768c4.56 11.843 8.921 23.747 13.521 35.57a29.314 29.314 0 012.226 9.626 9.62 9.62 0 003.233 6.436c2.893 2.869 6.226 5.277 9.3 7.996 9.36 8.335 15.646 18.52 18.061 30.913z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M10.962 210.808a73.305 73.305 0 0017.961 32.712c7.684 7.916 17.643 13.993 28.599 15.992a43.15 43.15 0 0018.021-.63c2.315-.59 1.338-4.178-.997-3.588-21.395 5.407-41.691-8.655-52.239-26.496a72.917 72.917 0 01-7.773-18.93c-.599-2.308-4.18-1.329-3.582 1l.01-.06zm234.098.51c-5.468 21.158-21.134 41.887-43.666 45.975a38.52 38.52 0 01-16.345-.57c-2.315-.59-3.303 2.999-.998 3.578a44.91 44.91 0 0031.343-3.907c10.428-5.278 18.96-13.993 24.947-23.988a76.787 76.787 0 008.332-20.129c.598-2.309-2.994-3.298-3.583-1l-.03.04z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} d={'M117.104 52.252h25.536v10.025h-25.536z'} />
        <path
          fill={'#F2E70C'}
          d={
            'M131.174 3.128c-17.262 0-31.355 11.834-31.355 26.476 0 10.559 7.42 19.94 18.653 24.189a36.251 36.251 0 0012.736 2.268 36.745 36.745 0 009.85-1.328c12.72-3.544 21.521-13.607 21.521-25.129 0-14.66-14.117-26.476-31.405-26.476z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M131.174 5.268c16.182 0 29.265 10.95 29.265 24.336 0 10.496-8.11 19.767-19.952 23.067a34.61 34.61 0 01-9.28 1.25 34.11 34.11 0 01-11.984-2.132c-10.44-3.95-17.264-12.576-17.264-22.185 0-13.369 13.06-24.336 29.215-24.336z'
          }
        />
        <path
          fill={'#502C1A'}
          d={
            'M138.555 11.934h-19.68v34.68h19.68v-6.015l-13.37-.001v-9.885h12.981v-6.015l-12.981-.001v-6.748h13.37z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M162.797 165.442h.16c.09 18.44-14.26 33.452-32.152 33.732-17.961-.12-32.4-14.992-32.49-33.422h.18c0 6.666 14.199 11.993 31.821 12.043h.769c17.622-.25 31.742-5.697 31.712-12.353z'
          }
          opacity={0.6}
        />
        <path
          fill={'#502C1A'}
          d={
            'M108.403 125.003c0 4.416-3.574 7.996-7.983 7.996s-7.983-3.58-7.983-7.996c0-4.415 3.574-7.995 7.983-7.995s7.983 3.58 7.983 7.995zm59.363 0c0 4.416-3.574 7.996-7.983 7.996s-7.983-3.58-7.983-7.996c0-4.415 3.574-7.995 7.983-7.995s7.983 3.58 7.983 7.995zM99.163 94.99a18.75 18.75 0 019.16.27.79.79 0 00.599-1.45c-6.51-3.665-14.73-1.864-19.12 4.188a.79.79 0 00.065 1.084.787.787 0 001.083-.005 18.629 18.629 0 018.213-4.088zm61.598-.7a18.675 18.675 0 017.843 4.787.794.794 0 001.118.12.797.797 0 00.12-1.12c-3.873-6.38-11.894-8.856-18.68-5.766a.79.79 0 00.469 1.49 18.73 18.73 0 019.13.489zm-30.954 57.829a10.295 10.295 0 014.94 1.25.439.439 0 00.499-.72 8.193 8.193 0 00-10.887 0 .44.44 0 00.499.72 10.434 10.434 0 014.95-1.25z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgE = React.memo(SvgE)
export default MemoSvgE
