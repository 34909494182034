import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { useViewerContext } from '@thesisedu/feature-users-react'

export function useAssignmentQueryVariables(classId?: string) {
  const viewer = useViewerContext(false)
  const selectedClassId = useSelectedClassId()
  if (viewer?.group === 'STUDENT' && (classId || selectedClassId)) {
    return {
      studentClassId: classId || selectedClassId,
      useCurrentSubmissions: true,
    }
  } else {
    return {}
  }
}
