import { FeatureResource } from '@thesisedu/feature'
import { UserFragment } from '@thesisedu/feature-users-web/dist/schema'
import { PlayerProps } from '@thesisedu/react'

import { DynamicVideoFieldProps } from './DynamicVideoField'
import { DynamicVideoViewProps } from './DynamicVideoView'
import { VodPlaybackDataFragment } from './schema'

export interface VideoThumbnail {
  type?: string
}

export const DEFAULT_TYPE = 'URLVideoThumbnail'
export interface URLVideoThumbnail extends VideoThumbnail {
  url: string
}

export interface VideoThumbnailTypeFieldProps<Thumbnail extends VideoThumbnail> {
  value?: Thumbnail | null
  onChange?: (value?: Thumbnail | null) => void
}
export interface VideoThumbnailTypeViewProps<Thumbnail extends VideoThumbnail> {
  value: Thumbnail
}

export interface VideoThumbnailTypeResource<Thumbnail extends VideoThumbnail>
  extends FeatureResource {
  type: 'VideoThumbnailType'
  FieldComponent: (props: VideoThumbnailTypeFieldProps<Thumbnail>) => React.ReactElement | null
  ViewComponent: (props: VideoThumbnailTypeViewProps<Thumbnail>) => React.ReactElement | null
  icon: React.ReactElement
  label: string
}

export interface VideoUploadProps {
  name: string
  description?: string
  provider: string
  onComplete: (vodId: string) => void
}
export interface VideoViewProps<
  PlaybackData extends Record<string, any> = VodPlaybackDataFragment,
> {
  playbackData: PlaybackData
  playerProps?: Partial<PlayerProps>
}
export interface VodReactProviderResource extends FeatureResource {
  type: 'VodReactProvider'
  UploadComponent?: React.FC<React.PropsWithChildren<VideoUploadProps>>
  ViewComponent?: React.FC<React.PropsWithChildren<VideoViewProps>>
}

export interface VideoOnDemandViewerResource extends FeatureResource {
  type: 'VideoOnDemandViewer'
  identifier: string
  Component: (props: DynamicVideoViewProps) => React.ReactElement
  shouldUseEditor: (user: UserFragment) => boolean
  Editor: (props: DynamicVideoFieldProps) => React.ReactElement
}
