import { ApolloReactFeature } from './ApolloReactFeature'
import { useFeature } from './feature'
import { BackgroundJobFragment } from './schema'
import { BackgroundJobReporterResource } from './types'

export interface EnrichedBackgroundJob<
  Payload extends Record<string, any> = any,
  ResultPayload extends Record<string, any> = any,
> extends Omit<BackgroundJobFragment, 'payload' | 'resultPayload'> {
  label: string
  onComplete?: (job: BackgroundJobFragment) => void | Promise<void>
  resource: BackgroundJobReporterResource<Payload, ResultPayload>
  payload?: Payload | null
  resultPayload?: ResultPayload | null
}

export function getEnrichedBackgroundJobs(
  this: ApolloReactFeature,
  jobs: BackgroundJobFragment[],
): EnrichedBackgroundJob[] {
  const resources =
    this.resources.getResourcesForType<BackgroundJobReporterResource>('BackgroundJobReporter')
  return jobs
    .map(job => {
      const resource = resources.find(r => r.identifier === job.type)
      return resource
        ? {
            ...job,
            resource,
            label: resource.humanLabel(
              job.payload,
              job.resultPayload,
              job.progress || undefined,
              job.status,
            ),
            onComplete: resource.afterComplete
              ? (job: BackgroundJobFragment) =>
                  resource.afterComplete!(job.payload, job.resultPayload)
              : undefined,
          }
        : null
    })
    .filterNil()
}

export function useEnrichedBackgroundJobs(jobs: BackgroundJobFragment[]) {
  const feature = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  return getEnrichedBackgroundJobs.call(feature, jobs)
}
