import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import { Dropdown } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useCourseViewerModalContext } from '../../contexts/CourseViewerModalContext'
import { SegmentType } from '../../schema'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

export function EditCustomSegment({ segment }: SegmentActionComponentProps) {
  const navigate = useNavigate()
  const { setSegmentId } = useCourseViewerModalContext(false) || {}
  return (
    <Dropdown.Item
      icon={<Edit />}
      onClick={() => {
        if (setSegmentId) {
          setSegmentId(segment.id, 'edit')
        } else {
          navigate(`/teacher/my-content/custom-content/${toGlobalId('Segment', segment.id)}`)
        }
      }}
    >
      Edit Custom Content
    </Dropdown.Item>
  )
}

export const EditCustomSegmentAction: SegmentActionResource = {
  identifier: 'edit-custom-segment',
  type: 'SegmentAction',
  component: EditCustomSegment,
  weight: 401,
  group: 'My Content',
  filter: (segment, context) => {
    return (
      segment.type !== SegmentType.Group &&
      !!context.viewer &&
      segment.userId?.toString() === fromGlobalId(context.viewer.id, true).id
    )
  },
  handler: () => {},
}
