import React from 'react'

import { debug } from '../../../log'

export interface UseMediaDevicesOpts {
  constraints: any
  refreshDevicesOnMount?: boolean
  type: 'audioinput' | 'videoinput'
}
export interface MediaDevice {
  label: string
  id: string
}
export interface UseMediaDevices {
  state: 'loading' | 'error' | 'noPermission' | 'idle' | 'unsupported'
  devices: MediaDevice[]
  refreshDevices: (force?: boolean) => Promise<void>
}

export function useMediaDevices({
  constraints,
  refreshDevicesOnMount,
  type,
}: UseMediaDevicesOpts): UseMediaDevices {
  const [state, setState] = React.useState<UseMediaDevices['state']>('loading')
  const [devices, setDevices] = React.useState<MediaDevice[]>([])
  const refreshDevices = React.useCallback(async (force?: boolean) => {
    setState('loading')
    debug('refreshing devices')
    try {
      if (
        typeof navigator === 'undefined' ||
        typeof navigator.mediaDevices === 'undefined' ||
        typeof navigator.mediaDevices.enumerateDevices === 'undefined'
      ) {
        debug('cannot find mediaDevices.enumerateDevices')
        setState('unsupported')
        return
      }

      // Check for permissions.
      debug('checking for %s permissions', type)
      let hasPermission = false
      try {
        const permission = await navigator.permissions.query({
          name: type === 'audioinput' ? 'microphone' : ('camera' as any),
        })
        if (permission.state === 'denied') {
          debug('permission was denied')
          setState('noPermission')
          if (!force) return
        }
        hasPermission = permission.state === 'granted'
      } catch (err: any) {
        if (err instanceof TypeError) {
          debug('received TypeError; we are likely in Firefox')
          // This likely means we are in Firefox, so let's just assume we have permission.
          hasPermission = true
        } else {
          throw err
        }
      }

      // Prompt for permission if we don't have it.
      if (!hasPermission) {
        debug('we dont have permission; requesting')
        const stream = await navigator.mediaDevices.getUserMedia({
          video: type === 'videoinput',
          audio: type === 'audioinput',
        })
        stream.getTracks().forEach(track => track.stop())

        hasPermission = true
      }

      // List all of the devices.
      const devices = (await navigator.mediaDevices.enumerateDevices()).filter(
        device => device.kind === type,
      )
      const stateDevices = devices.map(device => {
        return {
          label: device.label,
          id: device.deviceId,
        }
      })
      debug('found %s devices: %O', type, stateDevices)
      setDevices(stateDevices)
      setState('idle')
    } catch (err: any) {
      if (err instanceof DOMException) {
        if (err.name === 'NotAllowedError') {
          debug('received NotAllowedError')
          setState('noPermission')
        } else if (err.name === 'NotFoundError') {
          debug('received NotFoundError')
          setState('unsupported')
          setDevices([])
        } else {
          debug('received %s', err.name)
          setState('error')
        }
      } else {
        debug('received unknown error %O', err)
        setState('error')
      }
    }
  }, [])

  // Handle refreshDevicesOnMount
  React.useEffect(() => {
    if (refreshDevicesOnMount) {
      refreshDevices()
    }
  }, [refreshDevices])

  return { state, refreshDevices, devices }
}
