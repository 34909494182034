import { Button, Button$ } from '@thesisedu/ui'
import { EyeAlt } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface ShowInClassButtonProps extends Button$.ButtonProps {
  segmentId: string
  classId: string
}
export function ShowInClassButton({ segmentId, classId, ...rest }: ShowInClassButtonProps) {
  const navigate = useNavigate()
  return (
    <Button
      variant={'primary'}
      icon={<EyeAlt />}
      children={'Show in Class'}
      {...rest}
      onPress={(...args) => {
        const params = new URLSearchParams({
          highlight: segmentId,
        })
        navigate(`/teacher/classes/${classId}/course/outline?${params.toString()}`)
        rest.onPress?.(...args)
      }}
    />
  )
}
