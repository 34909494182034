import { useObjectRef } from '@react-aria/utils'
import React from 'react'

import { TextAreaField, TextAreaFieldProps } from './TextAreaField'

export interface ArrayTextAreaFieldProps extends Omit<TextAreaFieldProps, 'value' | 'onChange'> {
  value?: string[]
  onChange?: (value: string[]) => void
}
function _ArrayTextAreaField(
  { value, onChange, ...rest }: ArrayTextAreaFieldProps,
  _ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  const [localValue, setLocalValue] = React.useState(value?.join('\n') ?? '')
  const ref = useObjectRef(_ref)

  // Only update the value when it changes and we are not focused.
  const focusRef = React.useRef(false)
  React.useEffect(() => {
    if (!focusRef.current) {
      setLocalValue(value?.join('\n') ?? '')
    }
  }, [value])

  return (
    <TextAreaField
      {...rest}
      ref={ref}
      value={localValue}
      onFocus={e => {
        // Workaround for autoFocus. If we focus too quickly, we won't update the value
        // if we're setting it shortly after initializing the form.
        setTimeout(() => {
          focusRef.current = true
        }, 1)
        rest.onFocus?.(e)
      }}
      onBlur={e => {
        focusRef.current = false
        rest.onBlur?.(e)
      }}
      onChange={newValue => {
        setLocalValue(newValue)
        if (onChange) {
          onChange(
            newValue
              .split('\n')
              .map(s => s.trim())
              .filter(Boolean),
          )
        }
      }}
    />
  )
}
export const ArrayTextAreaField = React.forwardRef(_ArrayTextAreaField)
