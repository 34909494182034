import { Theme } from '@thesisedu/native'
import { LoadingIndicator as WebLoadingIndicator } from '@thesisedu/web'
import React from 'react'

import { Block } from '../styled/Block'

export interface LoadingIndicatorProps {
  size?: `@size-${keyof Theme['size']}`
  color?: string
  block?: boolean
}
export function LoadingIndicator({
  block,
  size = block ? '@size-l' : undefined,
}: LoadingIndicatorProps) {
  const indicator = <WebLoadingIndicator size={size} />
  if (block) {
    return (
      <Block marginTop={'@size-s'} marginBottom={'@size-s'} style={{ textAlign: 'center' }}>
        {indicator}
      </Block>
    )
  } else return indicator
}
