import { useAllStudentsQuery } from '@thesisedu/feature-classes-react'
import { UserSource, UseUsers } from '@thesisedu/feature-direct-messages-react'
import { useParams } from 'react-router-dom'

import { useAllClassGroupsQuery } from '../../schema'

export const useUsers: UseUsers = () => {
  const { classes: _classes, loading, error } = useAllStudentsQuery()
  const { data, loading: classGroupsLoading, error: classGroupsError } = useAllClassGroupsQuery()
  const { classId } = useParams()
  const classesWithGroups = data?.viewer?.teacher?.classes.edges.map(edge => edge.node) || []
  const classes = _classes?.filter(cls => !classId || cls.id === classId) || []
  const sources: UserSource[] = []
  for (const cls of classes) {
    const withGroups = classesWithGroups.find(c => c.id === cls.id)
    const students = cls.students.edges.map(edge => edge.node) || []
    if (withGroups && withGroups.groups && Object.keys(withGroups.groups || {}).length) {
      for (const groupKey of Object.keys(withGroups.groups || {})) {
        const userIds = withGroups.groups[groupKey].students
          .map((studentId: string) => {
            const student = students.find(s => s.id === studentId)
            return (student?.user as any)?.canMessage ? student!.user.id : null
          })
          .filter(Boolean)
        if (userIds.length) {
          sources.push({
            label: `All in ${withGroups.groups[groupKey].name}`,
            value: `${cls.id}-${groupKey}`,
            group: cls.name,
            weight: 2,
            userIds,
          })
        }
      }
    }
  }

  return { sources, loading: loading || classGroupsLoading, error: !!error || !!classGroupsError }
}
