import { onMutationError } from '@thesisedu/feature-apollo-react'
import { PendingStudentClassReasonReviewComponentProps } from '@thesisedu/feature-classes-react'
import { Button, HSpaced, Modal, Text, VSpaced, s } from '@thesisedu/ui'
import { Cancel, Check, MinusCircle, WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

import {
  DuplicateUserPendingStudentClassResolution,
  WithDuplicateUserPendingStudentClassMetadataFragment,
  useResolveDuplicateUserPendingStudentClassMutation,
} from '../../schema'

export function ReviewDuplicateUser({
  onClose,
  item,
}: PendingStudentClassReasonReviewComponentProps<WithDuplicateUserPendingStudentClassMetadataFragment>) {
  const requestingStudent = item.student
  const existingStudent = item.duplicateUserMetadata?.matchedStudent
  if (!existingStudent)
    throw new Error('ExistingStudent could not be found inside ReviewDuplicateUser')

  const [existingClassesErrorVisible, setExistingClassesErrorVisible] = React.useState(false)
  const [_resolve, { loading }] = useResolveDuplicateUserPendingStudentClassMutation({
    onError: onMutationError(
      'There was an error updating that student.',
      ['CANNOT_MERGE_DUPLICATE_USER_STUDENT_WITH_EXISTING_CLASSES_ERROR'],
      () => {
        setExistingClassesErrorVisible(true)
      },
    ),
    refetchQueries: ['classPendingStudentClasses'],
    async update(cache, result) {
      const resultClass = result.data?.resolveDuplicateUserPendingStudentClass.class
      if (resultClass) {
        await cache.evict({
          id: cache.identify({ __typename: 'Class', id: resultClass.id }),
          fieldName: 'students',
        })
      }
    },
    onCompleted() {
      onClose()
    },
  })
  const resolve = React.useCallback(
    (resolution: DuplicateUserPendingStudentClassResolution) => {
      return _resolve({
        variables: {
          input: {
            id: item.id,
            resolution,
          },
        },
      })
    },
    [_resolve],
  )

  let requestingStudentName = requestingStudent.user.name ?? requestingStudent.user.username
  let existingStudentName = existingStudent.user.name ?? existingStudent.user.username
  if (requestingStudent.user.email) requestingStudentName += ` (${requestingStudent.user.email})`
  if (existingStudent.user.email) existingStudentName += ` (${existingStudent.user.email})`
  return (
    <Modal
      title={'Review Duplicate User'}
      visible
      onVisibleChange={v => {
        if (!v) onClose()
      }}
    >
      <Text bottom>
        <strong>{requestingStudentName}</strong> tried to join this class, and was denied because we
        think they're already in this class.
      </Text>
      <Text bottom>
        Here is the name of the student that's already in this class:{' '}
        <strong>{existingStudentName}</strong>
      </Text>
      <Text>Are these the same student?</Text>
      <VSpaced space={'xs'} top={'m'}>
        <Button
          variant={'primary'}
          icon={<Check />}
          loading={loading}
          onPress={() => resolve(DuplicateUserPendingStudentClassResolution.Merge)}
          disabled={existingClassesErrorVisible}
        >
          These are the same students
        </Button>
        {existingClassesErrorVisible ? (
          <HSpaced align={'flex-start'} space={'xs'}>
            <Text level={'s'} color={'orange'} style={{ flexShrink: 0 }}>
              <WarningCircle />
            </Text>
            <Text level={'s'} color={'orange'}>
              <strong>This student is already enrolled in another class.</strong> Because of this,
              merging the student in this class would remove them from their other class(es). If you
              need them in this class, you can still accept them into this class using the{' '}
              <strong>Allow student to join class</strong> button. Be advised, this might result in
              the student being in the class twice.
            </Text>
          </HSpaced>
        ) : null}
        <Button
          icon={<Cancel />}
          loading={loading}
          onPress={() => resolve(DuplicateUserPendingStudentClassResolution.Accept)}
        >
          {existingClassesErrorVisible ? (
            'Allow student to join class'
          ) : (
            <span>
              These are <strong>not</strong> the same students
            </span>
          )}
        </Button>
        <Button
          icon={<MinusCircle />}
          variant={'ghost'}
          loading={loading}
          onPress={() => resolve(DuplicateUserPendingStudentClassResolution.Decline)}
          style={{ color: s.color('secondary') }}
        >
          Ignore
        </Button>
      </VSpaced>
    </Modal>
  )
}
