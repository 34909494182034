import {
  QuestionInstructions,
  QuestionProps,
  useSubmissionData,
} from '@thesisedu/feature-assignments-react'
import React from 'react'

import { GraphicDragDropView } from './GraphicDragDropView'
import { DEFAULT_INSTRUCTIONS, GraphicDragDropConfig } from './types'

export function GraphicDragDropAnswer(props: QuestionProps<GraphicDragDropConfig>) {
  const data = useSubmissionData(props.question.id)
  return (
    <QuestionInstructions
      instructions={props.question.config?.instructions || DEFAULT_INSTRUCTIONS}
    >
      <GraphicDragDropView config={props.question.config!} value={data} showAnswers disabled />
    </QuestionInstructions>
  )
}
