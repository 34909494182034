import { AssignmentOverrides } from './assignments'

export interface GradesMap {
  [rubricId: string]: number
}
export interface CommentsMap {
  [rubricId: string]: string | null | undefined
}

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC',
}

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS',
}

export enum RevealAnswers {
  AfterSubmit = 'AFTER_SUBMIT',
  RealTime = 'REAL_TIME',
}

export enum SubmissionType {
  Individual = 'INDIVIDUAL',
  Group = 'GROUP',
  GroupIndividual = 'GROUP_INDIVIDUAL',
}

export interface PartialAssignment {
  name?: string
  description?: string | null
  deletedAt?: string | null
  createdAt?: string
  updatedAt?: string
  openAt?: string | null
  dueAt?: string | null
  totalPoints?: number
  excluded?: boolean | null
  gradingMode?: AssignmentGradingMode
  assignmentMode?: AssignmentStudentAssignmentMode
  gradingConfigurationLocked?: boolean | null
  teacherId?: string | number
  assignmentTemplateId?: number | string | null
  maxAttempts?: number
  revealAttempts?: number | null
  timeLimit?: number | null
  revealAnswers?: RevealAnswers | null
}

export enum AutoGradingStatus {
  Failed = 'FAILED',
  Graded = 'GRADED',
  Grading = 'GRADING',
}
export interface QuestionMetadata {
  autoGradingStatus?: AutoGradingStatus
  autoGrade?: number
  [key: string]: any
}
export interface QuestionMetadataMap {
  [questionId: string]: QuestionMetadata | null | undefined
}

export interface PartialAssignmentSubmission {
  classId?: string | number
  studentId?: string | number
  createdAt?: string
  updatedAt?: string
  deletedAt?: string | null
  reviewed?: boolean | null
  grades?: GradesMap | null
  comments?: CommentsMap | null
  relatedType?: string | null
  grade?: number | null
  submittedAt?: string | null
  gradedAt?: string | null
  submissionData?: Record<string, any> | null
  overrides?: AssignmentOverrides
  questionMetadata?: QuestionMetadataMap | null
}
