import { useExpectedErrorListener } from '@thesisedu/feature-apollo-react'
import { useFreshRef, useResource } from '@thesisedu/feature-react'
import { SAMLButtonPlacement, SAMLButtonResource } from '@thesisedu/feature-users-react'
import { useLocation } from '@thesisedu/react'
import { Modal, Separator, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { WaitForApproval } from './WaitForApproval'

export function DuplicateUserExpectedErrorListener() {
  const { error, onClose } = useExpectedErrorListener({
    error: 'STUDENT_PROBABLY_ALREADY_IN_CLASS_ERROR',
  })
  const errorRef = useFreshRef(error)
  const classId = error?.extensions?.classId
  const otherProviders = error?.extensions?.otherSamlProviders
  const providerResources = useResource<SAMLButtonResource>('SAMLButton')
  const otherProviderResources = otherProviders
    ? providerResources.filter(resource => otherProviders.includes(resource.identifier))
    : undefined
  const location = useLocation()
  React.useEffect(() => {
    // Make sure to close the modal when we navigate to a different page.
    if (errorRef.current) {
      onClose()
    }
  }, [location])
  if (!classId) return null
  return (
    <Modal
      visible={!!error}
      onVisibleChange={v => {
        if (!v) onClose()
      }}
    >
      <VSpaced align={'flex-start'} top={'l'}>
        <Text level={'l'}>
          You might have already enrolled in this class using a different account. We need to check
          with your teacher before you can join this class.
        </Text>
        <WaitForApproval classId={classId} onClose={onClose} />
        {otherProviderResources?.length ? (
          <>
            <Separator children={'or'} />
            <Text>
              While you wait, you might want to try signing in with one of these providers:
            </Text>
            <VSpaced space={'xs'} align={'flex-start'}>
              {otherProviderResources.map(resource => {
                const Comp = resource.component
                return <Comp placement={SAMLButtonPlacement.Prominent} />
              })}
            </VSpaced>
          </>
        ) : null}
      </VSpaced>
    </Modal>
  )
}
