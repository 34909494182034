import { ClassDraggableFooterItemsHook } from '@thesisedu/feature-classes-react'
import { useNavigate } from '@thesisedu/react'
import { Button, Tooltip } from '@thesisedu/ui'
import { DoubleCheck } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'

interface ViewGradesItemProps {
  classId: string
  group: string
}
function ViewGradesItem({ classId, group }: ViewGradesItemProps) {
  const navigate = useNavigate()
  return (
    <Tooltip title={'View Grades'}>
      <Button
        size={'large'}
        variant={'ghost'}
        icon={<DoubleCheck />}
        onPress={() => {
          navigate(`/${group.toLowerCase()}/classes/${classId}/grades`)
        }}
      />
    </Tooltip>
  )
}

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<ClassDraggableFooterItemsHook>(
    'classes-react:class-draggable-footer-items',
    (items, props) => {
      return [
        ...items,
        <ViewGradesItem key={'grades'} group={props.group} classId={props.class.id} />,
      ]
    },
  )
}
