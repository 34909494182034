import { UserRoutesHook, UserLeftItemsHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { AntIconWrapper } from '@thesisedu/react'
import { Copy } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { WidgetTemplatesReactFeature } from '../../WidgetTemplatesReactFeature'
import { WidgetTemplatesRoute } from '../../manage/WidgetTemplatesRoute'

export default function (feature: WidgetTemplatesReactFeature) {
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...routes,
          <Route key={'templates'} path={'templates/*'} element={<WidgetTemplatesRoute />} />,
        ]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            weight: 1,
            element: (
              <Shell.LeftMenuItem
                name={'Templates'}
                icon={<AntIconWrapper children={<Copy />} />}
                path={'/admin/templates'}
              />
            ),
          },
        ]
      } else return items
    },
  )
}
