import { SegmentStructureOverride } from '@thesisedu/feature-course-types'
import React from 'react'

import { useSplitViewPendingChangesContext } from '../SplitViewPendingChangesContext'

export interface PlacementDrawerInnerContextValue {
  onMouseDownRef: React.MutableRefObject<Function | null>
  nodeRef: React.MutableRefObject<HTMLElement | null>
  pickUp: () => void
}
export const PlacementDrawerInnerContext = React.createContext<
  PlacementDrawerInnerContextValue | undefined
>(undefined)

export interface PlacementDrawerInnerContextProviderProps {
  children: React.ReactNode
  /**
   * We have to do it this way because we have to force the element to re-mount
   * inside the React tree. This is ugly as hell, but it's the way it has to be
   * as far as I know. If we don't force the element to re-mount, then it gets
   * lost inside useSortable(), and it refuses to pick it back up.
   *
   * Yes, this happened even before we ripped out most of the sortable behavior
   * inside our own custom PlacementDrawerDroppedOutlineItem.
   */
  updateDraggableKey: () => void
}
export function PlacementDrawerInnerContextProvider({
  children,
  updateDraggableKey,
}: PlacementDrawerInnerContextProviderProps) {
  const onMouseDownRef = React.useRef<((event: any) => void) | null>(null)
  const nodeRef = React.useRef<HTMLElement | null>(null)
  const { changes, setChanges } = useSplitViewPendingChangesContext(true)

  /**
   * This only applies when you drop something inside a lesson, pick it
   * back up, and then drop it somewhere else in that same lesson:
   *
   * The structure overrides get cleared whenever we pick the item back up,
   * but the drag / drop helpers don't think the parent changed, so they
   * don't create the structure override again. Instead of passing a prop
   * several layers down into the tree, we can use this "hack" here instead
   * to make sure we always have a structure override if we have a metadata
   * override (which we should always).
   */
  const existingStructureOverridesRef = React.useRef<SegmentStructureOverride[]>([])
  React.useEffect(() => {
    if (changes.structureOverrides.length) {
      existingStructureOverridesRef.current = changes.structureOverrides
    }
    if (!changes.structureOverrides.length && changes.metadataOverrides.length) {
      setChanges({ ...changes, structureOverrides: existingStructureOverridesRef.current })
    }
  }, [changes])

  const pickUp = React.useCallback(() => {
    setChanges({ metadataOverrides: [], structureOverrides: [] })
    updateDraggableKey()
  }, [])

  return (
    <PlacementDrawerInnerContext.Provider
      value={{ onMouseDownRef, nodeRef, pickUp }}
      children={children}
    />
  )
}

export function usePlacementDrawerInnerContext(): PlacementDrawerInnerContextValue | undefined
export function usePlacementDrawerInnerContext(
  require: false,
): PlacementDrawerInnerContextValue | undefined
export function usePlacementDrawerInnerContext(require: true): PlacementDrawerInnerContextValue
export function usePlacementDrawerInnerContext(
  require?: boolean,
): PlacementDrawerInnerContextValue | undefined {
  const context = React.useContext(PlacementDrawerInnerContext)
  if (!context && require) {
    throw new Error('PlacementDrawerInnerContext is required, yet not provided.')
  }
  return context
}
