import { Level, MediaPlaylist } from 'hls.js'
import React from 'react'

import { HlsVideo, HlsVideoProps } from './HlsVideo'
import { Player, PlayerProps } from './Player'
import { FooterControl } from './PlayerFooter'
import { CaptionSelector } from './controls/CaptionSelector'
import { QualitySelector } from './controls/QualitySelector'

function cleanCaption(caption: string) {
  return caption.replace(/<\/*v.*?>/g, '').replace(/^\w?-\w?/, '')
}

export interface HlsPlayerProps extends Omit<HlsVideoProps, 'src' | 'ref'> {
  hlsUrl: string
  playerProps?: Partial<PlayerProps>
}
export function HlsPlayer({ hlsUrl, playerProps, ...props }: HlsPlayerProps) {
  const playerRef = React.useRef<any>()
  const [levels, setLevels] = React.useState<Level[]>([])
  const [currentLevelIndex, setCurrentLevelIndex] = React.useState<number | undefined>(undefined)
  const [subtitleTracks, setSubtitleTracks] = React.useState<MediaPlaylist[]>([])
  const [subtitleTrack, setSubtitleTrack] = React.useState<number>(-1)
  const [currentCue, setCurrentCue] = React.useState<string | undefined>(undefined)
  const [previousCue, setPreviousCue] = React.useState<string | undefined>(undefined)
  const qualitySelector = (
    <QualitySelector
      levels={levels}
      currentLevel={currentLevelIndex}
      onChange={setCurrentLevelIndex}
    />
  )
  const captionSelector = (
    <CaptionSelector
      tracks={subtitleTracks}
      selectedTrack={subtitleTrack}
      onChange={setSubtitleTrack}
    />
  )
  return (
    <Player
      {...playerProps}
      playerRef={playerRef}
      captions={
        [cleanCaption(previousCue || ''), cleanCaption(currentCue || '')].filter(
          Boolean,
        ) as string[]
      }
      footerProps={{
        ...playerProps?.footerProps,
        controls: playerProps?.footerProps?.controls
          ? [...playerProps.footerProps.controls, qualitySelector, FooterControl.Fullscreen]
          : [
              FooterControl.SkipBackward,
              FooterControl.SkipForward,
              FooterControl.PlaybackSpeed,
              FooterControl.Volume,
              qualitySelector,
              ...(subtitleTracks.length ? [captionSelector] : []),
              FooterControl.Fullscreen,
            ],
      }}
    >
      <HlsVideo
        src={hlsUrl}
        width={'100%'}
        height={'100%'}
        playsInline
        onLevels={setLevels}
        onCurrentLevelChange={setCurrentLevelIndex}
        currentLevel={currentLevelIndex}
        onSubtitleTracks={setSubtitleTracks}
        onSubtitleTrackChange={setSubtitleTrack}
        currentSubtitleTrack={subtitleTrack}
        onSubtitleChange={(subtitle, previousSubtitle) => {
          setPreviousCue(previousSubtitle)
          setCurrentCue(subtitle)
        }}
        {...props}
      />
    </Player>
  )
}
