import { PlusOutlined, TeamOutlined } from '@ant-design/icons'
import { TeacherSelectorModal } from '@thesisedu/feature-teachers-react'
import {
  Block,
  BlockSpin,
  BodyLarge,
  BodySmall,
  ErrorIndicator,
  FontWeight,
  Modal,
  Row,
  Transition,
} from '@thesisedu/web'
import { Button, Col, Tag } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import pluralize from 'pluralize'
import React from 'react'

import { TeacherPermissions, usePermissionResources } from './TeacherPermissions'
import { useOptions } from '../options'
import { useInviteTeacherToClassMutation } from '../queries/useInviteTeacherToClassMutation'
import { ClassRole, useClassTeamTeachingQuery, UserFragment } from '../schema'

export interface TeamTeachingTeacherProps {
  user: UserFragment
  onClick: () => void
  permissions: string[]
  siteName: string
  isOwner?: boolean
}
export function TeamTeachingTeacher({
  user,
  onClick,
  permissions,
  isOwner,
}: TeamTeachingTeacherProps) {
  const { enabledResources } = usePermissionResources(permissions)
  return (
    <Block marginBottom={'@size-s'}>
      <Button
        onClick={onClick}
        style={{ width: '100%', height: 'auto', textAlign: 'left' }}
        disabled={isOwner}
      >
        <Block marginBottom={'@size-xxs'}>
          <BodyLarge weight={FontWeight.Bold}>
            {user.name || user.username} {isOwner ? <Tag>Owner</Tag> : null}
          </BodyLarge>
        </Block>
        <BodySmall color={'@text-color-secondary'}>
          {isOwner ? 'All permissions' : pluralize('permission', enabledResources.length, true)}
        </BodySmall>
      </Button>
    </Block>
  )
}

export interface ManageTeamTeachingModalContentProps {
  classId: string
}
export function ManageTeamTeachingModalContent({ classId }: ManageTeamTeachingModalContentProps) {
  const [selectedTeacherId, setSelectedTeacherId] = React.useState<string | undefined>(undefined)
  const { data, loading } = useClassTeamTeachingQuery({
    variables: { classId },
  })
  const [visible, setVisible] = React.useState(false)
  const [invite] = useInviteTeacherToClassMutation()
  const options = useOptions()

  const teacherEdges = data?.node?.__typename === 'Class' ? data.node.teachers.edges : undefined
  if (!teacherEdges && loading) {
    return <BlockSpin />
  } else if (teacherEdges) {
    const selectedTeacher = teacherEdges.find(edge => edge.node.id === selectedTeacherId)
    const state = selectedTeacher ? selectedTeacher.node.id : 'list'
    return (
      <Transition state={state} type={'fade-up'}>
        <div>
          {selectedTeacher ? (
            <TeacherPermissions
              teacher={selectedTeacher}
              classId={classId}
              onBack={() => setSelectedTeacherId(undefined)}
            />
          ) : (
            <>
              <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
                <TeacherSelectorModal
                  visible={visible}
                  onVisibleChange={setVisible}
                  title={'Add a Teacher'}
                  submit={`Add to ${options.classNameCapitalized}`}
                  onSelected={async teachers => {
                    const results = await Promise.all(
                      teachers.map(teacher => {
                        return invite({
                          variables: {
                            input: {
                              id: classId,
                              teacherId: teacher.teacherId,
                            },
                          },
                        })
                      }),
                    )
                    setVisible(false)
                    if (results.length === 1 && results[0]?.data?.inviteTeacherToClass.class.id) {
                      setSelectedTeacherId(teachers[0].teacherId)
                    }
                  }}
                />
                <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setVisible(true)}>
                  Add Teachers
                </Button>
              </Block>
              <Row>
                {teacherEdges.map(teacher => (
                  <Col xs={12} key={teacher.node.id}>
                    <TeamTeachingTeacher
                      user={teacher.node.user}
                      onClick={() => setSelectedTeacherId(teacher.node.id)}
                      permissions={teacher.permissions}
                      isOwner={teacher.role === ClassRole.Owner}
                      siteName={teacher.node.site.name}
                    />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </div>
      </Transition>
    )
  } else {
    return <ErrorIndicator />
  }
}

export interface ManageTeamTeachingModalProps extends ManageTeamTeachingModalContentProps {
  visible?: boolean
  onCancel: () => void
}
export function ManageTeamTeachingModal({
  visible,
  onCancel,
  ...rest
}: ManageTeamTeachingModalProps) {
  return (
    <Modal
      title={'Manage Teachers'}
      onCancel={onCancel}
      visible={visible}
      width={800}
      style={{ maxWidth: '90vw' }}
      bodyStyle={{ paddingBottom: 50 }}
    >
      <ManageTeamTeachingModalContent {...rest} />
    </Modal>
  )
}

export interface ManageTeamTeachingButtonProps
  extends Omit<ManageTeamTeachingModalProps, 'visible' | 'onCancel'> {
  buttonProps?: Partial<ButtonProps>
}
export function ManageTeamTeachingButton({ buttonProps, ...rest }: ManageTeamTeachingButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <ManageTeamTeachingModal {...rest} visible={visible} onCancel={() => setVisible(false)} />
      <Button
        icon={<TeamOutlined />}
        onClick={() => setVisible(true)}
        children={'Manage Teachers'}
        {...buttonProps}
      />
    </>
  )
}
