import { styled, s, Block } from '@thesisedu/ui'
import { motion } from 'framer-motion'
import React from 'react'

import { useDetailedRecordStateContext } from '../DetailedRecordStateContext'

const DEFAULT_COUNTDOWN_SECONDS = 5
export function RecordButtonCountdownState() {
  const { setState } = useDetailedRecordStateContext()
  const [current, setCurrent] = React.useState(DEFAULT_COUNTDOWN_SECONDS)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(current => {
        const newCurrent = Math.max(0, current - 1)
        if (newCurrent === 0) {
          setState({ type: 'recording', startTime: Date.now() })
        }
        return newCurrent
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  return (
    <>
      <Block left right style={{ zIndex: 2 }}>
        <span>{current}</span>
      </Block>
      <Progress
        animate={{
          width: `${((DEFAULT_COUNTDOWN_SECONDS - current) * 100) / DEFAULT_COUNTDOWN_SECONDS}%`,
        }}
      />
    </>
  )
}

const Progress = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: ${s.color('red.activeSolid')};
  z-index: 1;
`
