import { flattenSegments } from '@thesisedu/feature-course-types'
import React, { useState, useEffect, useContext } from 'react'

import { CourseConfiguration, Segment } from '../types'

export interface SegmentContextValue {
  [segmentId: string]: Segment
}
export const SegmentContext = React.createContext<SegmentContextValue>({})

export const generateSegmentCache = (flatSegments: Segment[]) => {
  const cache: SegmentContextValue = {}
  for (const segment of flatSegments) {
    cache[segment.id] = segment
    if (segment.referenceSegment?.id) {
      cache[segment.referenceSegment.id] = segment.referenceSegment
    }
  }
  return cache
}

export interface SegmentContextProviderProps {
  courseConfiguration: CourseConfiguration
}
export function SegmentContextProvider({
  courseConfiguration,
  children,
}: React.PropsWithChildren<SegmentContextProviderProps>) {
  const [segmentCache, setSegmentCache] = useState<SegmentContextValue>({})
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setSegmentCache(
      generateSegmentCache(
        flattenSegments(courseConfiguration.segments, { includeChildren: true }) as Segment[],
      ),
    )
    setLoading(false)
  }, [courseConfiguration])
  if (loading) {
    return null
  } else {
    return <SegmentContext.Provider value={segmentCache} children={children} />
  }
}

export function useSegmentCache() {
  return useContext(SegmentContext)
}
export function useSegment(segmentId?: string) {
  const segmentCache = useSegmentCache()
  if (segmentCache && segmentId) {
    return segmentCache[segmentId]
  } else {
    return undefined
  }
}
