import { ModalNavigationItem } from '@thesisedu/react'
import React from 'react'

import { NavigationItem } from './types'
import { useGradingModalContext } from '../contexts/GradingModalContext'

export interface GradingModalNavigationProps {
  items: NavigationItem[]
}
export function GradingModalNavigation({ items: navigationItems }: GradingModalNavigationProps) {
  const content = navigationItems
    .filter(item => !item.skipItem)
    .map(item =>
      item.renderNavigationItem ? (
        item.renderNavigationItem({ item })
      ) : (
        <GradingModalNavigationItem key={item.key} item={item} />
      ),
    )
  return <>{content}</>
}

export interface GradingModalNavigationItemProps {
  item: NavigationItem
  onClick?: () => void
}
export function GradingModalNavigationItem({ item, onClick }: GradingModalNavigationItemProps) {
  const { selectedTab, setSelectedTab } = useGradingModalContext(true)
  return (
    <ModalNavigationItem
      key={item.key}
      selected={selectedTab === item.key}
      onClick={onClick ? onClick : () => setSelectedTab(item.key)}
      label={item.label}
      icon={item.icon}
      data-testid={`GradingModalNavigationItem ${item.key}`}
    />
  )
}
