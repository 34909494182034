import { useToast } from '@thesisedu/ui'
import React from 'react'

import { useRecordStateContext, RecordingState } from './RecordStateContext'
import { useRecording } from './useRecording'
import { debug } from '../../../log'
import { usePrepareForRecording } from '../../../upload/usePrepareForRecording'
import { useRecordViewContext } from '../../RecordViewContext'
import { getReview } from '../review'
import { RecordStateProps, Streams } from '../types'

export interface UseRecordStateOpts {
  streams: Streams
  onError: (e: any) => void
  onTransition: RecordStateProps<any>['onTransition']
}
export interface UseRecordState {
  state: RecordingState | undefined
  setState: (state: RecordingState | undefined) => void | Promise<void>
}
export function useRecordState({
  streams,
  onError,
  onTransition,
}: UseRecordStateOpts): UseRecordState {
  const { state, setState } = useRecordStateContext(true)
  // Reset the state whenever the component mounts / unmounts.
  React.useEffect(() => {
    setState(undefined)
    return () => {
      setState(undefined)
    }
  }, [])
  const { classId, onPrepare } = useRecordViewContext(true)
  const toast = useToast()
  const prepareForRecording = usePrepareForRecording(classId, onPrepare)
  const { startRecording, stopRecording, pauseRecording, resumeRecording, recordingStartTime } =
    useRecording({
      streams,
      onFinished(result) {
        onTransition(
          getReview({
            ...result,
            streamHasVideo: !!streams.videoDeviceId,
          }),
        )
      },
      onError,
    })

  // Update the recordingStartTime.
  React.useLayoutEffect(() => {
    if (state?.type === 'recording' && recordingStartTime) {
      setState({ type: 'recording', startTime: recordingStartTime })
    }
  }, [recordingStartTime])

  // Respond to state changes.
  const existingStateRef = React.useRef(state)
  React.useLayoutEffect(() => {
    const newState = state
    const existingState = existingStateRef.current
    if (newState?.type === 'preparing') {
      debug('preparing for recording')
      try {
        prepareForRecording()
          .then(result => {
            if (result) {
              debug('prepare returned true; counting down')
              setState({ type: 'countdown' })
            } else {
              debug('prepare returned false; stopping')
              toast({ title: 'We could not start your recording.', status: 'error' })
              setState(undefined)
            }
          })
          .catch(err => {
            onError(err)
            setState(undefined)
          })
      } catch (err: any) {
        onError(err)
        setState(undefined)
      }
    }
    if (newState?.type === 'recording') {
      if (existingState?.type === 'paused') {
        resumeRecording()
      } else {
        startRecording()
      }
    }
    if (newState?.type === 'stopping') {
      stopRecording()
    }
    if (newState?.type === 'paused') {
      pauseRecording()
    }

    existingStateRef.current = newState
  }, [state?.type])

  return {
    state,
    setState,
  }
}
