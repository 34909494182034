import { FeatureUse } from '@thesisedu/feature'
import {
  ClassHooks,
  StudentMetadataContext,
  StudentMetadataPayload,
} from '@thesisedu/feature-classes-react'
import { ReactFeature } from '@thesisedu/feature-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

export class StudentIdReactFeature extends ReactFeature {
  public static package = '@thesisedu/feature-student-id-react'
  public static path = ''
  public static requires: string[] = []

  public reactResources() {
    if (!isNative) {
      require('./hooks/routes').default(this)
      require('./hooks/LoginLinks').default(this)
      require('./resources/import').default(this)
    }

    const { StudentIDMetadata } = require('./StudentIDMetadata')
    this.hookManager.registerMutateHook<StudentMetadataPayload, StudentMetadataContext>(
      ClassHooks.StudentMetadata,
      (items, context) => {
        return [...items, <StudentIDMetadata key={'student-id'} context={context!} />]
      },
    )
  }
}

export const studentIdReactFeature = (options: object = {}): FeatureUse => [
  StudentIdReactFeature,
  options,
]
