import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { StudentContextHook } from './types'

export interface WrapWithStudentContextProps {
  children: React.ReactElement
}
export function WrapWithStudentContext({ children }: WrapWithStudentContextProps) {
  return useMutateHook<StudentContextHook>('students-react:student-context', children, undefined)
}
