import { FeatureResource, Feature, MutateHook } from '@thesisedu/feature'
import { BasicUserTaskFragment } from '@thesisedu/feature-user-tasks-react/dist/schema'
import { Viewer } from '@thesisedu/feature-users-web/dist/types'
import { Shell } from '@thesisedu/feature-web'
import { MenuItemProps } from 'antd/es/menu/MenuItem'
import React from 'react'

import { UseOptions } from './options'
import {
  ClassFragment,
  ClassStudentEdgeFragment,
  MinimalClassFragment,
  UserFragment,
} from './schema'
import { ClassDraggableProps } from './teacher/ClassDraggable'

export enum ClassHooks {
  StudentMetadata = 'classes-web:student-metadata',
  NoClassContent = 'classes-web:no-class-content',
  StudentClassGroupings = 'classes-web:student-class-groupings',
}
export type StudentMetadataPayload = React.ReactElement[]
export interface StudentMetadataContext {
  edge: ClassStudentEdgeFragment
  class: MinimalClassFragment & Partial<Pick<ClassFragment, 'students' | 'canUpdate'>>
  refetchQueries: string[]
}
export type NoClassContentPayload = React.ReactElement | null
export interface NoClassContentContext {}
export interface StudentWithGroup {
  id: string
  classId: string
  user: UserFragment
  group: string
}
export type StudentClassGroupingsPayload = StudentWithGroup[]
export interface StudentClassGroupingsContext {
  class: ClassFragment | undefined
}
export type MutateStudentClassGroupingsHook = MutateHook<
  ClassHooks.StudentClassGroupings,
  StudentClassGroupingsPayload,
  StudentClassGroupingsContext
>

export interface ClassTaskSummaryProviderProps {
  classId: string
}
export interface ClassTaskSummaryProviderResource extends FeatureResource {
  type: 'ClassTaskSummaryProvider'
  provider: (props: ClassTaskSummaryProviderProps) => React.ReactElement | null
}

export interface ImportPayload {
  [key: string]: any
}

export interface MainContentComponentProps<T extends ImportPayload = ImportPayload> {
  onModeSelected: (payload: T) => void
  class: ClassFragment
}

export interface ExpandedContentComponentProps<T extends ImportPayload = ImportPayload> {
  class: ClassFragment
  onBack: () => void
  onClose: () => void
  payload: T
}

export type TitleFunc = (options: UseOptions) => string
export interface ImportStudentModeResource<T extends ImportPayload = ImportPayload>
  extends FeatureResource {
  type: 'ImportStudentMode'
  identifier: string
  title: string | TitleFunc
  icon: React.ReactElement
  MainContentComponent: React.FC<React.PropsWithChildren<MainContentComponentProps<T>>>
  ExpandedContentComponent?: React.FC<React.PropsWithChildren<ExpandedContentComponentProps<T>>>
  filter?: (cls: ClassFragment) => boolean
  weight?: number
  group?: string
  widths?: {
    main?: number
    expanded?: number
  }
}

export interface ClassesWebOptions {
  /** @deprecated */
  className: string
  /** @deprecated */
  classNamePlural: string
  userGroupClassPrefix?: {
    [group: string]: string
  }
  getNewTeacherContent?: () => () => React.ReactElement | null
  getNewStudentContent?: () => () => React.ReactElement | null
  showClassCodeOnInviteButton?: boolean
}

export interface BulkStudentMenuItemProps extends MenuItemProps {
  selectedStudentIds: string[]
  classId: string
}

export interface PermissionDependency {
  identifier: string
  message: string
}
export interface ClassTeacherPermissionResource extends FeatureResource {
  type: 'ClassTeacherPermission'
  label: string
  group: string
  permissions: string[]
  dependencies?: PermissionDependency[]
}
export function addTeacherPermission(
  feature: Feature,
  group: string,
  label: string,
  permissions: string[],
  dependencies?: PermissionDependency[],
) {
  const identifier = `${group}-${label}`
  const existing = feature.resources.getResource<ClassTeacherPermissionResource>(
    'ClassTeacherPermission',
    identifier,
  )
  if (existing) {
    existing.permissions.push(...permissions)
  } else {
    feature.resources.addResource<ClassTeacherPermissionResource>({
      type: 'ClassTeacherPermission',
      identifier: `${group}-${label}`,
      label,
      group,
      permissions,
      dependencies,
    })
  }
}

export interface ClassConfigurationFieldProps {
  fieldPrefix: string[]
  classId?: string
}
export interface ClassConfigurationFieldResource extends FeatureResource {
  type: 'ClassConfigurationField'
  group: string
  groupIcon?: React.ReactElement
  weight?: number
  filter?: (viewer?: Viewer, isImpersonating?: boolean) => boolean
  renderField: (props: ClassConfigurationFieldProps) => React.ReactElement | null
  /**
   * If this is enabled, the field is not visible unless specified inside the configuration
   * component settings.
   */
  hidden?: boolean
  /** If this is true, a form will not be rendered around this item's group. */
  skipForm?: boolean
}

export interface ClassStudentPair {
  studentId: string
  classId: string
}

export type CreateClassContentHook = MutateHook<
  'classes-react:create-class',
  React.ReactElement[],
  undefined
>
export type CreateClassActionsHook = MutateHook<
  'classes-react:create-class-actions',
  React.ReactElement[],
  undefined
>
export type ListClassesContentHook = MutateHook<
  'classes-react:list-classes',
  React.ReactElement[],
  { group: string }
>

export type ClassDraggableFooterItemsHook = MutateHook<
  'classes-react:class-draggable-footer-items',
  React.ReactElement[],
  ClassDraggableProps & { group: string }
>
export type ClassDraggableOverlayItemsHook = MutateHook<
  'classes-react:class-draggable-overlay-items',
  React.ReactElement[],
  ClassDraggableProps & { group: string }
>
export type ClassRouteHook = MutateHook<
  'classes-react:class-route',
  React.ReactElement,
  {
    viewer?: Viewer
    group: string
  }
>
export type ClassContextHook = MutateHook<
  'classes-react:class-context',
  React.ReactElement,
  {
    cls: ClassFragment
    group: string
  }
>
export type ClassLeftNavHook = MutateHook<
  'classes-react:class-left-nav',
  Shell.LeftNavigationItem[],
  {
    cls: ClassFragment
    group: string
  }
>
export type ClassRoutesHook = MutateHook<
  'classes-react:class-routes',
  React.ReactElement[],
  { cls: ClassFragment; group: string }
>
export type ClassDangerZoneItemsHook = MutateHook<
  'classes-react:danger-zone-items',
  React.ReactElement[],
  { cls: ClassFragment }
>
export type ClassSettingsGeneralItemsHook = MutateHook<
  'classes-react:settings-general-items',
  React.ReactElement[],
  { cls: ClassFragment }
>
export type TodoTopRightContentHook = MutateHook<
  'classes-react:todo-top-right-content',
  React.ReactElement[],
  { tasks: BasicUserTaskFragment[] }
>
export type StudentsPageActionsHook = MutateHook<
  'classes-react:students-page-actions',
  React.ReactElement[],
  { cls: MinimalClassFragment }
>
export type StudentsPageHeaderHook = MutateHook<
  'classes-react:students-page-header',
  React.ReactElement[],
  { cls: MinimalClassFragment }
>
