import { useBulkActionsWithModal } from '@thesisedu/feature-apollo-react'
import { Button, HSpaced } from '@thesisedu/ui'
import { MinusCircle, Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { UpdateAssignmentDocument } from '../schema'

export interface GeneratedAssignmentsBulkEnableProps {
  assignmentIds: string[]
}
export function GeneratedAssignmentsBulkEnable({
  assignmentIds,
}: GeneratedAssignmentsBulkEnableProps) {
  const [_run, { modal }] = useBulkActionsWithModal()
  const run = React.useCallback(
    async (enable: boolean) => {
      return _run({
        message: 'Updating assignments',
        mutation: UpdateAssignmentDocument,
        async refetchQueries(client) {
          for (const assignmentId of assignmentIds) {
            client.cache.evict({
              id: client.cache.identify({ __typename: 'Assignment', id: assignmentId }),
            })
          }
        },
        variables: assignmentIds.map(assignmentId => {
          return {
            input: {
              id: assignmentId,
              patch: {
                excluded: !enable,
              },
            },
          }
        }),
      })
    },
    [_run, assignmentIds],
  )
  return (
    <HSpaced space={'xs'} justify={'flex-start'}>
      {modal}
      <Button icon={<Plus />} size={'small'} onPress={() => run(true)}>
        Add All to Gradebook
      </Button>
      <Button icon={<MinusCircle />} size={'small'} onPress={() => run(false)}>
        Remove All from Gradebook
      </Button>
    </HSpaced>
  )
}
