import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { ClassDraggable, ClassDraggableProps } from './ClassDraggable'
import { ClassManagerProps } from './ClassManager'
import { MinimalClassFragment } from '../schema'

export interface ClassManagerItemProps {
  class: MinimalClassFragment
  index: number
  renderDraggable: ClassManagerProps['renderDraggable']
  setInviteClass: (cls: MinimalClassFragment) => void
  draggableProps?: Partial<ClassDraggableProps>
}
export function ClassManagerItem({
  class: cls,
  index,
  setInviteClass,
  draggableProps,
  renderDraggable,
}: ClassManagerItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isOver, isDragging } =
    useSortable({
      id: cls.id,
    })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }
  const props: ClassDraggableProps & { key: string } = {
    class: cls,
    index,
    key: cls.id,
    // TODO: Come back and implement this properly. This means the default behavior
    // is currently broken (poor Collabra).
    draggingItem: undefined,
    isDropping: isOver,
    onInvite: cls.canInvite ? () => setInviteClass(cls) : undefined,
    isArchived: !!cls.archivedAt,
    dragHandleProps: cls.archivedAt ? undefined : listeners,
    ...draggableProps,
  }

  return (
    <Container ref={setNodeRef} style={style} {...attributes} $dragging={isDragging}>
      {renderDraggable ? renderDraggable(props) : <ClassDraggable {...props} />}
    </Container>
  )
}

const Container = styled.div<{ $dragging?: boolean }>`
  z-index: 1;
  > div {
    transition:
      box-shadow 0.25s ${s.var('curves.ease')},
      border 0.25s linear;
  }
  ${s.ifs(props => props.$dragging)} {
    z-index: 2;
    > div {
      box-shadow: ${s.var('shadow.2')};
      border-color: ${s.color('primary')};
    }
  }
`
