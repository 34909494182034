import { ReactFeature, ReactUse } from '@thesisedu/feature-react'

export interface StandardSetsReactFeatureOptions {}

export class StandardSetsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-standard-sets-react'
  static path = ''
  static requires: string[] = []
  public options!: StandardSetsReactFeatureOptions

  public reactResources() {
    require('./hooks/validTagTypes').default(this)
    require('./hooks/additionalTagEditors').default(this)
    require('./resources/siteConfiguration').default(this)
    require('./hooks/segmentStandards').default(this)
    require('./hooks/routes').default(this)
    require('./resources/ReportDimension/Standards').default(this)
    require('./hooks/ExploreHomeContent').default(this)
    require('./hooks/exploreRoutes').default(this)
    require('./hooks/ExploreGridFilters').default(this)
    require('./resources/RubricEditorActions/ImportStandardRubricEditorAction').default(this)
  }
}

export const standardSetsReactFeature: ReactUse = (opts: StandardSetsReactFeatureOptions) => [
  StandardSetsReactFeature,
  opts,
]
