import { onMutationError } from '@thesisedu/feature-apollo-react'
import { ClassConfigurationWithAssignmentSettings } from '@thesisedu/feature-assignments-core'
import { useUpdateClassMutation } from '@thesisedu/feature-classes-react'
import { Text, Button, Modal, useToast, Block, VSpaced, Checkbox } from '@thesisedu/ui'
import { Calculator } from '@thesisedu/ui/icons'
import React from 'react'

import { GRADING_MODE_MAP } from './modes'
import { UPDATE_GRADE_QUERIES } from '../queries/constants'
import { ClassGradingMode } from '../schema'

export interface ChangeGradingModeModalProps {
  visible?: boolean
  onCancel: () => void
  classId: string
  configuration: ClassConfigurationWithAssignmentSettings
  gradingMode: ClassGradingMode
}
export function ChangeGradingModeModal({
  visible,
  onCancel,
  classId,
  configuration,
  gradingMode,
}: ChangeGradingModeModalProps) {
  const toast = useToast()
  const [update, { loading }] = useUpdateClassMutation({
    onError: onMutationError('There was an error updating your grading calculation.'),
    refetchQueries: UPDATE_GRADE_QUERIES,
    update(cache) {
      cache.evict({
        id: cache.identify({ __typename: 'Class', id: classId }),
        fieldName: 'averageGrade',
      })
      cache.evict({
        id: cache.identify({ __typename: 'ClassStudentsEdge' }),
        fieldName: 'grade',
      })
    },
    onCompleted: () => {
      toast({
        title: 'Grading calculation updated!',
        status: 'success',
      })
      onCancel()
    },
  })
  const [selectedMode, setSelectedMode] = React.useState(gradingMode)
  React.useEffect(() => setSelectedMode(gradingMode), [gradingMode])
  return (
    <Modal
      title={'Change Grade Calculation'}
      visible={visible}
      onVisibleChange={v => {
        if (!v) onCancel()
      }}
      width={600}
    >
      <Text level={'l'} bottom>
        You are updating your selected grade calculation method.
      </Text>
      <Text bottom>
        We support multiple methods, and each are explained below. Select the method you would like
        to switch to, and then click on the <strong>Change Calculation</strong> button.
      </Text>
      <Text bottom>
        Please be aware,{' '}
        <strong>
          changing the grade calculation method for your class will result in the re-calculation of
          student grades.
        </strong>
      </Text>
      <Block top={'m'}>
        <VSpaced align={'stretch'}>
          {(Object.keys(GRADING_MODE_MAP) as ClassGradingMode[]).map(modeMapKey => (
            <Checkbox
              key={modeMapKey}
              value={modeMapKey}
              align={'flex-start'}
              checked={selectedMode === modeMapKey}
              onCheckedChange={checked => {
                if (checked) {
                  setSelectedMode(modeMapKey)
                }
              }}
              aria-label={GRADING_MODE_MAP[modeMapKey].label}
              label={
                <>
                  <Text weight={'bold'}>{GRADING_MODE_MAP[modeMapKey].label}</Text>
                  <Text>{GRADING_MODE_MAP[modeMapKey].description}</Text>
                </>
              }
            />
          ))}
        </VSpaced>
      </Block>
      <Modal.Footer>
        <Modal.CloseButton />
        <Button
          disabled={!selectedMode}
          loading={loading}
          variant={'primary'}
          onPress={() => {
            return update({
              variables: {
                input: {
                  id: classId,
                  patch: {
                    configuration: {
                      ...configuration,
                      gradingMode: selectedMode,
                    },
                  },
                },
              },
            })
          }}
        >
          Change Calculation
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export type ChangeGradingModeButtonProps = Omit<ChangeGradingModeModalProps, 'visible' | 'onCancel'>
export function ChangeGradingModeButton(props: ChangeGradingModeButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <ChangeGradingModeModal {...props} visible={visible} onCancel={() => setVisible(false)} />
      <Button
        icon={<Calculator />}
        onPress={() => setVisible(true)}
        style={{ whiteSpace: 'nowrap' }}
      >
        Change Grade Calculation
      </Button>
    </>
  )
}
