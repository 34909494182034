import { LexicalNode } from 'lexical'
import React from 'react'

export interface SelectedElementContextValue {
  element?: LexicalNode
  setElement: (node: LexicalNode | undefined) => void
}
export const SelectedElementContext = React.createContext<SelectedElementContextValue | undefined>(
  undefined,
)

export interface SelectedElementProviderProps {
  children: React.ReactElement | React.ReactElement[]
}
export function SelectedElementProvider({ children }: SelectedElementProviderProps) {
  const [element, setElement] = React.useState<LexicalNode | undefined>()
  return <SelectedElementContext.Provider value={{ element, setElement }} children={children} />
}

export function useSelectedElementContext(): SelectedElementContextValue | undefined
export function useSelectedElementContext(require: false): SelectedElementContextValue | undefined
export function useSelectedElementContext(require: true): SelectedElementContextValue
export function useSelectedElementContext(
  require?: boolean,
): SelectedElementContextValue | undefined {
  const context = React.useContext(SelectedElementContext)
  if (!context && require) {
    throw new Error('SelectedElementContext is required, yet not provided.')
  }
  return context
}
