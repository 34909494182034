import { useResource } from '@thesisedu/feature-react'
import { callAttention, HSpaced } from '@thesisedu/web'
import React from 'react'

import { DimensionDropdownButton } from './DimensionDropdownButton'
import { FilterDropdownButton } from './FilterDropdownButton'
import { MetricDropdownButton } from './MetricDropdownButton'
import { dimensionsSupportMultipleMetrics } from '../execute/input'
import { ReportDimensionResource, RunReportOpts, SelectedDimension } from '../types'

export interface SmallConfigurationProps {
  value: RunReportOpts
  onChange: (opts: RunReportOpts) => void
}
export function SmallConfiguration({ value, onChange }: SmallConfigurationProps) {
  const dimensionResources = useResource<ReportDimensionResource<object, object>>('ReportDimension')
  const firstDimension = value.dimensions[0]
  const secondDimension = value.dimensions[1]
  const metric = value.metrics[0]
  const metricContainerRef = React.useRef<HTMLDivElement>(null)
  const showSelectMetric =
    secondDimension && !dimensionsSupportMultipleMetrics(dimensionResources, value.dimensions)
  const shouldPulseMetric = !metric && showSelectMetric
  React.useEffect(() => {
    if (shouldPulseMetric) {
      if (metricContainerRef.current) {
        callAttention(metricContainerRef.current)
      }
      const interval = setInterval(() => {
        if (metricContainerRef.current) {
          callAttention(metricContainerRef.current)
        }
      }, 1500)
      return () => {
        clearInterval(interval)
      }
    }
  }, [shouldPulseMetric])

  return (
    <HSpaced>
      <DimensionDropdownButton
        dimension={firstDimension}
        index={0}
        onChange={dimension => {
          if (dimension) {
            onChange({
              metrics: [], // We have to reset these because you could be selecting "in general"
              dimensions: [dimension, secondDimension].filter(Boolean),
            })
          }
        }}
      />
      <DimensionDropdownButton
        dimension={secondDimension}
        index={1}
        allowNone
        onChange={dimension => {
          onChange({
            metrics: [], // We have to reset these because you could be selecting "in general"
            dimensions: [firstDimension, dimension].filter(Boolean) as SelectedDimension[],
          })
        }}
      />
      <div ref={metricContainerRef}>
        {showSelectMetric ? (
          <MetricDropdownButton
            metric={metric}
            onChange={metric => {
              onChange({
                ...value,
                metrics: [metric].filter(Boolean),
              })
            }}
          />
        ) : null}
      </div>
      <FilterDropdownButton
        value={value}
        onChange={partialValue => {
          onChange({
            ...value,
            ...partialValue,
          })
        }}
      />
    </HSpaced>
  )
}
