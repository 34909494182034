import React from 'react'

export interface IDEntry {
  id: string
  /** Where did the ID come from? */
  label: string
}

export interface IDsContextValue {
  currentIdsRef: React.MutableRefObject<IDEntry[]>
  registerID: (entry: IDEntry) => void
  removeID: (id: string, label: string) => void
}
export const IDsContext = React.createContext<IDsContextValue | undefined>(undefined)

export function IDsProvider({ children }: React.PropsWithChildren<object>) {
  const idsRef = React.useRef<IDEntry[]>([])
  const existing = React.useContext(IDsContext)
  return existing ? (
    <>{children}</>
  ) : (
    <IDsContext.Provider
      value={{
        currentIdsRef: idsRef,
        registerID(entry) {
          const existing = idsRef.current
          if (!existing.some(id => id.id === entry.id && id.label === entry.label)) {
            idsRef.current = [...existing, entry]
          }
        },
        removeID(id, label) {
          idsRef.current = idsRef.current.filter(
            entry => !(entry.id === id && entry.label === label),
          )
        },
      }}
      children={children}
    />
  )
}

export function useIDsContext(): IDsContextValue | undefined
export function useIDsContext(require: false): IDsContextValue | undefined
export function useIDsContext(require: true): IDsContextValue
export function useIDsContext(require?: boolean): IDsContextValue | undefined {
  const context = React.useContext(IDsContext)
  if (!context && require) {
    throw new Error('IDsContext is required, yet not provided.')
  }
  return context
}
