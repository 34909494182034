import { onMutationError } from '@thesisedu/feature-apollo-react'
import { QuestionOverride } from '@thesisedu/feature-assignments-core'
import {
  AssignmentCalculationEditorProps,
  useAssignmentViewerContext,
  useUpdateAssignmentMutation,
  ViewingAssignment,
} from '@thesisedu/feature-assignments-react'
import {
  SegmentAssignmentConfiguration,
  SegmentCalculationOptions,
} from '@thesisedu/feature-course-types'
import { useFreshRef } from '@thesisedu/feature-react'
import { orderBy } from 'lodash'
import React from 'react'

import { PresetSelection } from './PresetSelection'
import {
  SegmentCalculationContext,
  SegmentCalculationContextValue,
  useSegmentCalculationSegmentIdContext,
} from './SegmentCalculationContext'
import { SegmentCalculationInnerEditor } from './SegmentCalculationInnerEditor'
import { debug } from '../../log'
import { useCreateAssociatedClassAssignmentMutation } from '../../queries/useCreateAssociatedClassAssignmentMutation'

export function SegmentCalculationEditor({
  calculation,
}: AssignmentCalculationEditorProps<SegmentCalculationOptions>) {
  const { classId, assignment, configuration, templateId } = useAssignmentViewerContext(true)
  const { segmentId } = useSegmentCalculationSegmentIdContext(true)
  const assignmentRef = useFreshRef(assignment)
  const [createAssociated, { loading: createLoading }] = useCreateAssociatedClassAssignmentMutation(
    {
      onError: onMutationError('There was an error setting up your assignment.'),
    },
  )
  const [updateAssignment, { loading: updateLoading }] = useUpdateAssignmentMutation()

  const shouldShowPreset = !calculation.calculatedPreset || !assignment
  const updateConfiguration: SegmentCalculationContextValue['updateConfiguration'] = async (
    newRawConfiguration,
    awaitRefetch,
  ) => {
    if (assignmentRef.current) {
      debug('preparing updateConfiguration optimistic response')
      const realConfiguration = assignmentRef.current?.configuration || configuration
      const assignmentOptimistic: ViewingAssignment = {
        __typename: 'Assignment',
        configuration,
        ...assignmentRef.current,
        rawConfiguration: newRawConfiguration,
      }
      let optimisticQuestions = realConfiguration.questions.map(question => {
        const override = (newRawConfiguration.questions || []).find(q => q.id === question.id)
        return { ...question, ...override }
      })
      optimisticQuestions = orderBy(optimisticQuestions, q => q.weight || 0, 'asc')

      return updateAssignment({
        variables: {
          input: {
            id: assignmentRef.current.id,
            patch: { configuration: newRawConfiguration },
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateAssignment: {
            __typename: 'AssignmentPayload',
            assignment: {
              ...assignmentOptimistic,
              configuration: {
                ...assignmentOptimistic.configuration,
                questions: optimisticQuestions,
              },
            },
          },
        },
        awaitRefetchQueries: awaitRefetch,
      })
    } else {
      throw new Error('Cannot update configuration without first creating an assignment.')
    }
  }

  const updateOverrides: SegmentCalculationContextValue['updateOverrides'] = async (
    overrides,
    extraConfiguration,
    awaitRefetch,
  ) => {
    if (assignmentRef.current) {
      const existingOverrides =
        (assignmentRef.current.rawConfiguration as SegmentAssignmentConfiguration | undefined)
          ?.questions || []
      const newOverrides = [
        ...existingOverrides.map(existingOverride => {
          const override = overrides.find(o => o.id === existingOverride.id)
          return {
            ...existingOverride,
            ...override,
          }
        }),
        ...overrides.filter(o => !existingOverrides.some(eo => eo.id === o.id)),
      ]
      return updateConfiguration(
        {
          ...assignmentRef.current.rawConfiguration,
          ...extraConfiguration,
          questions: newOverrides,
        },
        awaitRefetch,
      )
    } else {
      throw new Error('Cannot update assignment overrides without an assignment.')
    }
  }

  return (
    <SegmentCalculationContext.Provider
      value={{
        updateConfiguration,
        loading: updateLoading || createLoading,
        selectPreset: async preset => {
          let createdAssignment = assignment
          if (!createdAssignment) {
            const createResult = await createAssociated({
              variables: {
                input: {
                  id: classId,
                  segmentId,
                  templateId,
                },
              },
            })
            createdAssignment = createResult?.data?.createAssociatedClassAssignment.assignment
          }
          if (createdAssignment) {
            assignmentRef.current = createdAssignment
            const configuration = assignmentRef.current?.configuration
            if (configuration) {
              let questions: QuestionOverride[] = []
              if (preset.filter) {
                questions = configuration.questions.reduce<QuestionOverride[]>(
                  (overrideQuestions, question) => {
                    if (preset.filter && preset.filter(question)) {
                      return [...overrideQuestions, { id: question.id, disabled: false }]
                    } else return overrideQuestions
                  },
                  [],
                )
              }

              await updateOverrides(
                questions,
                {
                  calculation: {
                    identifier: 'segment',
                    calculatedPreset: preset.type,
                  },
                },
                true,
              )
            } else {
              throw new Error(
                'Cannot update overrides on created assignment without the created assignment configuration.',
              )
            }
          }
        },
        updateOverrides,
      }}
    >
      {shouldShowPreset ? <PresetSelection /> : <SegmentCalculationInnerEditor />}
    </SegmentCalculationContext.Provider>
  )
}
