import { getSegmentName } from '@thesisedu/feature-course-types'
import classnames from 'classnames'
import React, { useContext, useMemo, useState } from 'react'

import { Segment } from '../types'

type WalkerFn = (segment: Segment, parents: Segment[]) => void
const walkSegmentsWithParents = (
  segments: Segment[],
  walker: WalkerFn,
  parents: Segment[] = [],
) => {
  for (const segment of segments) {
    walker(segment, parents)
    if (segment.childSegments) {
      walkSegmentsWithParents(segment.childSegments, walker, [...parents, segment])
    }
  }
}

export interface OutlineSearchContextValue {
  currentSegmentIds?: string[]
  setSearch: (search: string) => void
}
export const OutlineSearchContext = React.createContext<OutlineSearchContextValue | undefined>(
  undefined,
)

const segmentMatches = (segment: Segment, searchTerm: string): boolean => {
  return getSegmentName(segment).toLowerCase().includes(searchTerm.trim().toLowerCase())
}

export interface OutlineSearchContextProviderProps {
  segments: Segment[]
  onChange?: (segmentIds: string[] | undefined) => void
}
export const OutlineSearchContextProvider: React.FC<
  React.PropsWithChildren<OutlineSearchContextProviderProps>
> = ({ children, segments, onChange }) => {
  const [currentSegmentIds, setCurrentSegmentIds] = useState<string[] | undefined>(undefined)
  const value = useMemo<OutlineSearchContextValue>(
    () => ({
      currentSegmentIds,
      setSearch(search) {
        if (search.trim().length > 1) {
          const segmentIds = new Set<string>()
          const parentsToAdd = new Set<string>()
          walkSegmentsWithParents(segments, (segment, parents) => {
            if (
              segmentMatches(segment, search) ||
              parents.some(parent => segmentIds.has(parent.id))
            ) {
              segmentIds.add(segment.id)
              for (const parent of parents) {
                parentsToAdd.add(parent.id)
              }
            }
          })

          // Add the remaining parents.
          for (const parent of parentsToAdd) {
            segmentIds.add(parent)
          }
          setCurrentSegmentIds(Array.from(segmentIds))
          if (onChange) {
            onChange(Array.from(segmentIds))
          }
        } else {
          setCurrentSegmentIds(undefined)
          if (onChange) {
            onChange(undefined)
          }
        }
      },
    }),
    [currentSegmentIds],
  )
  return <OutlineSearchContext.Provider value={value} children={children} />
}

export const useOutlineSearchContext = () => {
  return (
    useContext(OutlineSearchContext) || {
      setSearch: () => {},
    }
  )
}

/**
 * The OutlineSearchWrapper uses CSS styles to control visibility as opposed to adding / removing
 * HTML elements as it is MUCH FASTER to use CSS to simply hide / show results when the entire
 * result set is already rendered instead of adding / removing items from the DOM (which is
 * expensive).
 */
export interface OutlineSearchWrapperProps {
  segmentIds?: string[]
  className?: string
  [other: string]: any
}
export const OutlineSearchWrapper: React.FC<React.PropsWithChildren<OutlineSearchWrapperProps>> =
  React.forwardRef<HTMLDivElement, OutlineSearchWrapperProps>(
    ({ segmentIds, children, className, ...props }, ref) => {
      const { currentSegmentIds } = useOutlineSearchContext()
      const isVisible =
        !segmentIds || !currentSegmentIds || currentSegmentIds.some(id => segmentIds.includes(id))
      return (
        <div
          {...props}
          ref={ref}
          className={classnames({
            [className || '']: true,
            searchEnabled: currentSegmentIds && currentSegmentIds.length > 0,
            isVisible,
          })}
          style={{ ...props.style, display: isVisible ? undefined : 'none' }}
          children={children}
        />
      )
    },
  )

export function useIsSearching() {
  const { currentSegmentIds } = useOutlineSearchContext() || {}
  return currentSegmentIds !== undefined
}
