import React from 'react'
import { Link } from 'react-router-dom'

export interface StudentLinkProps {
  studentId: string
  classId: string
  children: any
}
export function StudentLink({ studentId, classId, children }: StudentLinkProps) {
  const link = `/teacher/reports/${classId}/${studentId}`
  return <Link to={link} children={children} />
}
