import { UserMenuItemsHook } from '@thesisedu/feature-users-react'
import { Dropdown } from '@thesisedu/ui'
import { RefreshDouble } from '@thesisedu/ui/icons'
import React from 'react'

import { TeachersWebFeature } from '../TeachersWebFeature'
import { useLoginAsStudent } from '../student/useLoginAsStudent'

function LoginAsStudentMenuItem(props: any) {
  const { loginAsStudent, loading } = useLoginAsStudent()
  return (
    <Dropdown.Item onClick={() => loginAsStudent()} loading={loading} icon={<RefreshDouble />}>
      Switch to Student Account
    </Dropdown.Item>
  )
}

export default function (feature: TeachersWebFeature) {
  feature.hookManager.registerMutateHook<UserMenuItemsHook>(
    'feature-users-react:user-menu',
    (items, { viewer }) => {
      if (viewer?.group === 'TEACHER') {
        return [<LoginAsStudentMenuItem key={'login-as-student'} />, ...items]
      } else {
        return items
      }
    },
  )
}
