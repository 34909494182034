import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, ButtonProps, Popconfirm } from 'antd'
import React from 'react'

export interface DeleteWidgetTemplateButtonProps extends Partial<ButtonProps> {
  widgetTemplateId: string
  afterDeleted?: () => void
}
export function DeleteWidgetTemplateButton({
  widgetTemplateId,
  afterDeleted,
  ...rest
}: DeleteWidgetTemplateButtonProps) {
  const [del, { loading }] = useDeleteNodeMutation({
    errorMessage: 'There was an error deleting that widget template.',
    refetchQueries: ['widgetTemplates'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      if (afterDeleted) {
        afterDeleted()
      }
    },
  })
  return (
    <Popconfirm
      title={'Are you sure you want to delete this template?'}
      okText={'Yes, delete'}
      cancelText={'No, keep'}
      okButtonProps={{ danger: true }}
      onConfirm={() => {
        return del({
          variables: {
            id: widgetTemplateId,
          },
        })
      }}
    >
      <Button children={'Delete'} type={'link'} danger {...rest} loading={loading} />
    </Popconfirm>
  )
}
