import { DefinitionNode, DocumentNode, OperationDefinitionNode } from 'graphql'

const REMOVE_MUTATION_SELECTIONS_CACHE = new Map<string, DocumentNode>()
const DEFAULT_VALID_FIELDS = ['__typename', 'success', 'id']

function isOperationDefinition(definition: DefinitionNode): definition is OperationDefinitionNode {
  return definition.kind === 'OperationDefinition' && definition.operation === 'mutation'
}

export interface RemoveMutationSelectionsOpts {
  validFields?: string[]
}
export function removeMutationSelections(
  document: DocumentNode,
  { validFields = DEFAULT_VALID_FIELDS }: RemoveMutationSelectionsOpts = {},
) {
  const operations = document.definitions.filter(isOperationDefinition)
  if (operations.length !== 1)
    throw new Error('Document must contain exactly one mutation definition.')
  const operationName = operations[0].name?.value
  if (!operationName) throw new Error('Document is missing a name.')

  if (!REMOVE_MUTATION_SELECTIONS_CACHE.has(operationName)) {
    const usedVariables = new Set<string>()
    const mutationDefinition = document.definitions.find(isOperationDefinition)
    if (!mutationDefinition) throw new Error('Missing mutation definition.')

    const selections = mutationDefinition.selectionSet.selections
    if (selections.length !== 1) throw new Error('Mutation is selecting more than 1 field.')
    const selection = selections[0]
    if (selection.kind !== 'Field') throw new Error('Mutation is not selecting a field.')
    if (!selection.selectionSet) throw new Error('Mutation is not selecting any fields.')

    // Keep track of which variables are actually used.
    for (const arg of selection.arguments ?? []) {
      if (arg.value.kind === 'Variable') {
        usedVariables.add(arg.value.name.value)
      }
    }

    const clonedMutation: DefinitionNode = {
      ...mutationDefinition,
      selectionSet: {
        ...mutationDefinition.selectionSet,
        selections: [
          {
            ...selection,
            selectionSet: {
              ...selection.selectionSet,
              selections: selection.selectionSet.selections.map(selection => {
                if (selection.kind !== 'Field')
                  throw new Error('Mutation is not selecting a field.')
                return {
                  ...selection,
                  selectionSet: selection.selectionSet
                    ? {
                        ...selection.selectionSet,
                        selections: selection.selectionSet?.selections.filter(selection => {
                          return (
                            selection.kind === 'Field' && validFields.includes(selection.name.value)
                          )
                        }),
                      }
                    : undefined,
                }
              }),
            },
          },
        ],
      },
      variableDefinitions: mutationDefinition.variableDefinitions?.filter(def => {
        return usedVariables.has(def.variable.name.value)
      }),
    }

    REMOVE_MUTATION_SELECTIONS_CACHE.set(operationName, {
      kind: 'Document',
      definitions: [clonedMutation],
    })
  }

  const result = REMOVE_MUTATION_SELECTIONS_CACHE.get(operationName)
  if (!result) throw new Error('We should not have gotten here.')
  return result
}
