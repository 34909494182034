import { ExternalRoot } from './ExternalRoot'
import * as Toast$ from './Toast'
import * as Parts from './ToastParts'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './ToastParts'
export * from './Toast'
export * from './useToast'
export * from './ExternalRoot'

const Toast = withSubComponents(Toast$.Toast, {
  ...Parts,
  /**
   * If you want more control over how the toast is displayed, use this
   * component instead of the default (and easier) Toast component.
   *
   * Do note, if you use this component, you are responsible for showing /
   * hiding the toast. The default timeout functionality is only available
   * when you useToast() or use the Toast component.
   */
  Root: ExternalRoot,
})
export default Toast
