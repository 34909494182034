import * as React from 'react'

function SvgNoteHalfInverse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#000'}
          d={
            'M83.955 4.718C92.869 9.6 97.427 15.72 97.427 24.835c0 15.243-12.95 32.837-34.145 42.547-17.5 8.018-35.652 8.56-47.722 2.655V260h-14L1.558 49.083a23.86 23.86 0 01.002-.846V48c.423-14.837 13.729-31.527 34.157-40.886C53.467-1.018 71.89-1.46 83.955 4.718z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M33.938 13.738C14.056 26.808 4.93 47.817 13.556 60.664c8.625 12.847 31.734 12.667 51.616-.403C85.055 47.192 94.18 26.183 85.555 13.336 76.929.489 53.82.669 33.938 13.738z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteHalfInverse = React.memo(SvgNoteHalfInverse)
export default MemoSvgNoteHalfInverse
