import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { AssignmentWithSyncFragmentDoc, useSyncAssignmentMutation as useMutation } from '../schema'

export const useSyncAssignmentMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error syncing your assignment.'),
    update: updateMutation({
      fragment: AssignmentWithSyncFragmentDoc,
      fragmentName: 'AssignmentWithSync',
      path: 'syncAssignment.assignment',
    }),
    ...opts,
  })
