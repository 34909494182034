import { DoubleCheck } from '@thesisedu/react/icons'
import { Body, Tooltip, VSpaced } from '@thesisedu/web'
import { Button, Popconfirm, Popover } from 'antd'
import React from 'react'

import { QuickGradeField } from './QuickGradeField'
import { useGradingModalContext } from '../contexts/GradingModalContext'
import { useGradingModalSubmissionContext } from '../contexts/GradingModalSubmissionContext'

export interface SubmissionGradeAllProps {
  hideHeader?: boolean
}
export function SubmissionGradeAll({ hideHeader }: SubmissionGradeAllProps) {
  const { assignment } = useGradingModalContext(true)
  const { form, onSave, submission } = useGradingModalSubmissionContext(true)
  const [visible, setVisible] = React.useState(false)
  const pendingGrade = React.useRef<number>()
  if (submission && !submission.canGrade) return null
  return (
    <VSpaced space={'@size-xxs'} align={'flex-start'}>
      {hideHeader ? null : <Body>Assign grade to entire assignment:</Body>}
      <Popconfirm
        visible={visible}
        onVisibleChange={setVisible}
        trigger={[]}
        title={'Are you sure? This will override any existing grades for this submission.'}
        okText={'Yes, grade entire assignment.'}
        placement={'bottom'}
        onCancel={() => {
          pendingGrade.current = undefined
        }}
        onConfirm={() => {
          if (pendingGrade.current !== undefined) {
            const values = form.getValues()
            form.setValue('grades', { all: pendingGrade.current })
            return onSave({
              grades: { all: pendingGrade.current },
              comments: values.comments || {},
            })
          }
        }}
      >
        <QuickGradeField
          value={submission?.grades?.all}
          totalPoints={assignment.totalPoints}
          onChange={grade => {
            if (grade !== undefined) {
              pendingGrade.current = grade
              setVisible(true)
            }
          }}
        />
      </Popconfirm>
    </VSpaced>
  )
}

export function SubmissionGradeAllButton() {
  return (
    <Popover placement={'bottom'} trigger={['click']} content={<SubmissionGradeAll hideHeader />}>
      <Tooltip placement={'bottom'} title={'Assign grade to entire assignment'}>
        <Button icon={<DoubleCheck />} />
      </Tooltip>
    </Popover>
  )
}
