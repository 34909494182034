import { useFeature } from '@thesisedu/feature-react'
import { MediaPlaybackContextProvider } from '@thesisedu/react'
import React from 'react'

import { MediaUpdatesProvider } from './MediaUpdatesContext'
import { PlaybackFooterContextProvider } from './PlaybackFooterContext'
import { RecordViewProps } from './RecordView'
import { RecordViewContext, useRecordViewContext } from './RecordViewContext'
import { RecordStateProvider } from './states/record/RecordStateContext'
import { RecordFooterContextProvider } from './states/record/footer/RecordFooterContext'
import MediaReactFeature from '../MediaReactFeature'
import { Container } from '../metronome/Container'

export interface RecordViewProvidersProps extends RecordViewProps {
  children: React.ReactNode
}
export function RecordViewProviders({ children, ...props }: RecordViewProvidersProps) {
  const existing = useRecordViewContext(false)
  const feature = useFeature(MediaReactFeature)
  if (existing) {
    return <>{children}</>
  } else {
    let _children = children
    if (feature.options.enableMetronomeTool) {
      _children = <Container>{_children}</Container>
    }
    return (
      <RecordStateProvider>
        <MediaPlaybackContextProvider>
          <MediaUpdatesProvider>
            <PlaybackFooterContextProvider>
              <RecordFooterContextProvider>
                <RecordViewContext.Provider value={props}>{_children}</RecordViewContext.Provider>
              </RecordFooterContextProvider>
            </PlaybackFooterContextProvider>
          </MediaUpdatesProvider>
        </MediaPlaybackContextProvider>
      </RecordStateProvider>
    )
  }
}
