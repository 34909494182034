import React, { useState } from 'react'

import { TagDescriptionsProps } from './TagDescriptions'
import { TagDescriptionsModal } from './TagDescriptionsModal'

export interface TagDescriptionsLinkProps extends TagDescriptionsProps {
  label?: string
}
export function TagDescriptionsLink({ label = 'View Tags', ...props }: TagDescriptionsLinkProps) {
  const [visible, setVisible] = useState(false)
  if (Object.keys(props.tagSummary).length) {
    return (
      <>
        <TagDescriptionsModal onClose={() => setVisible(false)} visible={visible} {...props} />
        <a onClick={() => setVisible(true)}>{label}</a>
      </>
    )
  } else {
    return null
  }
}
