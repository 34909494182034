import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  AssignmentTemplateFragmentDoc,
  useUpdateAssignmentTemplateMutation as useMutation,
} from '../schema'

export const useUpdateAssignmentTemplateMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating your template.'),
    update: updateMutation({
      fragment: AssignmentTemplateFragmentDoc,
      fragmentName: 'AssignmentTemplate',
      path: 'updateAssignmentTemplate.segmentAssignment',
    }),
    ...opts,
  })
