import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { AdvancedSheetMusicEditor } from './AdvancedSheetMusicEditor'
import { SimpleSheetMusicEditor } from './SimpleSheetMusicEditor'
import { SheetMusicViewerProps } from '../viewer/SheetMusicViewer'

export interface SheetMusicEditorProps {
  value?: SheetMusicViewerProps
  onChange?: (value?: SheetMusicViewerProps) => void
}
export function SheetMusicEditor(props: SheetMusicEditorProps) {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'ADMIN') {
    return <AdvancedSheetMusicEditor {...props} />
  } else {
    return (
      <SimpleSheetMusicEditor
        value={props.value?.abc}
        onChange={abc => {
          if (props.onChange) {
            props.onChange({ abc })
          }
        }}
      />
    )
  }
}
