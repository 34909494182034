import { Modal, Modal$ } from '@thesisedu/ui'
import React from 'react'

import { RubricTemplateSelect, RubricTemplateSelectProps } from './RubricTemplateSelect'

export interface RubricTemplateSelectModalProps
  extends RubricTemplateSelectProps,
    Pick<Modal$.ModalProps, 'trigger'> {}
export function RubricTemplateSelectModal({
  trigger,
  onSelected,
  ...rest
}: RubricTemplateSelectModalProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <Modal
      title={'Select a Rubric Template'}
      trigger={trigger}
      visible={visible}
      onVisibleChange={setVisible}
      style={{ height: 400 }}
    >
      <RubricTemplateSelect
        {...rest}
        onSelected={(...args) => {
          setVisible(false)
          onSelected(...args)
        }}
      />
    </Modal>
  )
}
