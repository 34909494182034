import { DEFAULT_APPLICATION_OPTIONS } from '@thesisedu/feature'

import { colors, mappings, sizes } from './theme'
import { FeatureWebApplicationOptions } from './types'

export const BUILD_APP_HOOK = 'feature-web:build-app'
export const POST_BUILD_HOOK = 'feature-web:post-build'
export const ERROR_PAGE_PROPS_HOOK = 'feature-web:error-page-props'
export const MAINTENANCE_CLEAN_INDEX_FILE = '__index-no-maintenance'

export const WRAP_APP_HOOK = 'feature-web:wrap-app'
export const DEFAULT_OPTIONS: FeatureWebApplicationOptions = {
  ...DEFAULT_APPLICATION_OPTIONS,
  release: process.env.REACT_APP_RELEASE,
  logo: () => '',
  icon: () => '',
  themes: {
    light: {
      ...colors,
      ...sizes,
      ...mappings,
    },
    dark: {
      ...colors, // TODO: Reverse the colors.
      ...sizes,
      ...mappings,
    },
  },
}
