import { fromGlobalId } from '@thesisedu/feature-utils'
import { AntIconWrapper, Block, styled } from '@thesisedu/web'
import { Button, Empty } from 'antd'
import { Plus } from 'iconoir-react'
import React from 'react'
import { Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom'

import { CampaignsList } from './CampaignsList'
import { CreateCampaign } from './CreateCampaign'
import { DeleteCampaignButton } from './DeleteCampaignButton'
import { EditCampaign } from './EditCampaign'

export function ManageCampaigns() {
  const navigate = useNavigate()
  const location = useLocation()
  const segments = location.pathname.split('/')
  const potential = segments[segments.length - 1]
  const selectedId =
    fromGlobalId(potential)?.type === 'TrialRequestCampaign' ? potential : undefined
  return (
    <Container>
      <Left>
        <Block marginBottom={'@size-s'}>
          <Button
            size={'small'}
            icon={<AntIconWrapper children={<Plus />} />}
            onClick={() => navigate('create')}
          >
            Create Campaign
          </Button>
        </Block>
        <CampaignsList
          onCampaignClick={campaign => navigate(campaign.id)}
          selectedItemId={selectedId}
        />
      </Left>
      <Right>
        <Routes>
          <Route
            path={'/create'}
            element={
              <CreateCampaign
                onCreated={campaign => {
                  navigate(campaign.id)
                }}
              />
            }
          />
          <Route path={'/:campaignId'} element={<EditCampaignPage />} />
          <Route path={'/'} element={<EmptyPage />} />
        </Routes>
      </Right>
    </Container>
  )
}

function EmptyPage() {
  const navigate = useNavigate()
  return (
    <Empty description={'Select a campaign from the left to get started, or create one.'}>
      <Button
        size={'large'}
        icon={<AntIconWrapper children={<Plus />} />}
        type={'primary'}
        onClick={() => navigate('create')}
      >
        Create a Campaign
      </Button>
    </Empty>
  )
}
function EditCampaignPage() {
  const { campaignId } = useParams()
  const navigate = useNavigate()
  return (
    <EditCampaign
      campaignId={campaignId}
      actions={<DeleteCampaignButton campaignId={campaignId} onDeleted={() => navigate('..')} />}
    />
  )
}

const Container = styled.div`
  display: flex;
  align-items: stretch;
`
const Left = styled.div`
  width: 25%;
  margin-right: ${props => props.theme['@size-l']};
  display: flex;
  flex-direction: column;
  > div:last-child {
    flex: 1;
  }
`
const Right = styled.div`
  flex: 1;
`
