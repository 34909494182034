import { Form } from '@thesisedu/react'
import React from 'react'

import {
  useAssignmentViewerContext,
  useAssignmentViewerFormContext,
} from './AssignmentViewerFormContext'
import { useSubmissionContext } from '../../contexts/SubmissionContext'
import { AssignmentActions } from '../AssignmentActions'

export function FormActions() {
  const { assignment, classId, teacherView, configuration } = useAssignmentViewerContext()
  const {
    groups: { areAnswersShared, isGroupLeader },
    onSaveSubmission,
  } = useSubmissionContext(true)
  const { form, shouldDisplayRetry, timeLimitReached, completedFields, actionsDisabled } =
    useAssignmentViewerFormContext(true)
  const submit = Form.useFormSubmit()

  if (teacherView) return null

  return (
    <AssignmentActions
      disabled={actionsDisabled || timeLimitReached || (areAnswersShared && !isGroupLeader)}
      onSubmitAnswers={submit}
      onSaveAnswers={() => {
        return onSaveSubmission({
          id: assignment.id,
          classId,
          submissionData: form.getValues(),
        })
      }}
      displayRetry={!!shouldDisplayRetry}
      configuration={configuration}
      completedFields={completedFields}
    />
  )
}
