import { StudentMetadataContext } from '@thesisedu/feature-classes-react'
import { useTeacherIdQuery } from '@thesisedu/feature-teachers-react/dist/schema'
import { CopyToClipboardButton } from '@thesisedu/react'
import { Button, Text } from '@thesisedu/ui'
import React from 'react'

import { EditStudentIDModal } from './EditStudentIDModal'

export interface StudentIDMetadataProps {
  context: StudentMetadataContext
}
export function StudentIDMetadata({ context }: StudentIDMetadataProps) {
  const { data } = useTeacherIdQuery()
  const [visible, setVisible] = React.useState(false)
  const teacherId = data?.viewer?.teacher?.teacherId
  const isStudentId =
    teacherId && context.edge.node.user.username.toLowerCase().includes(teacherId.toLowerCase())
  if (isStudentId && teacherId) {
    const studentId = context.edge.node.user.username.replace(teacherId, '')
    return (
      <>
        {visible ? (
          <EditStudentIDModal
            context={context}
            onVisibleChange={setVisible}
            visible
            studentId={studentId}
            teacherId={teacherId}
          />
        ) : null}
        <Text level={'s'} color={'secondary'}>
          Student ID: {studentId}{' '}
          <CopyToClipboardButton text={studentId} style={{ display: 'inline-block' }} /> -{' '}
          <Button
            size={'small'}
            variant={'chromeless'}
            children={'Edit'}
            onPress={() => setVisible(true)}
            style={{ display: 'inline-block' }}
          />
        </Text>
      </>
    )
  } else {
    return null
  }
}
