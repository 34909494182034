import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { AuthMSALReactFeature } from '@thesisedu/feature-auth-msal-react/dist/AuthMSALReactFeature'
import { useFeature, useFeatureRoot } from '@thesisedu/feature-react'
import { Body, Block, H1, StyledThemeContext } from '@thesisedu/web'
import { Button, Input, Result, message } from 'antd'
import React from 'react'

import { useRecordMicrosoftTenantMutation } from '../schema'

export function TenantPage() {
  const [record, { loading, error, data }] = useRecordMicrosoftTenantMutation()
  const theme = React.useContext(StyledThemeContext)
  const root = useFeatureRoot()
  const [enteredTenant, setEnteredTenant] = React.useState<string>('')
  const originalParams = new URLSearchParams(window.location.search)
  const originalToken = originalParams.get('token')
  const authFeature = useFeature(AuthMSALReactFeature)
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const tenant = params.get('tenant')
    const token = params.get('state')
    if (params.get('admin_consent')?.toLowerCase() === 'true' && tenant && token) {
      record({
        variables: {
          input: {
            token,
            tenantId: tenant,
          },
        },
      })
    }
  }, [])
  if (loading) {
    return <Result icon={<LoadingOutlined size={75} />} title={'Authenticating...'} />
  } else if (error) {
    return (
      <Result
        icon={<ExclamationCircleOutlined size={75} style={{ color: theme['@red'] }} />}
        title={'Error'}
        subTitle={`There was an error approving this product with Microsoft. Please contact ${root.appOptions.name} support.`}
      />
    )
  } else if (data?.recordMicrosoftTenant.success) {
    return (
      <Result
        icon={<CheckCircleOutlined size={75} style={{ color: theme['@green'] }} />}
        title={'Success!'}
        subTitle={`You have authenticated successfully. Thanks for using ${root.appOptions.name}`}
      />
    )
  } else {
    const submit = () => {
      if (originalToken) {
        const params = new URLSearchParams({
          redirect_uri: `${window.location.origin}${window.location.pathname}`,
          client_id: authFeature.options.clientId,
          state: originalToken,
        })
        window.location.href = `https://login.microsoftonline.com/${encodeURIComponent(
          enteredTenant.trim(),
        )}/adminconsent?${params.toString()}`
      } else {
        message.error('There was an error redirecting to Microsoft.')
      }
    }
    return (
      <>
        <H1>MS Teams</H1>
        <Body isBlock>
          In order for teachers to get the most out of {root.appOptions.name}, you'll need to
          provide us with your Microsoft Tenant ID and approve our connection with your Microsoft
          products.
        </Body>
        <Body isBlock>
          Your Tenant ID will either look like a UUID (for example,
          CBE880F6-699D-44F1-A258-F3386C73EF84), or it will be your domain (for example,
          contoso.onmicrosoft.com).
        </Body>
        <Body isBlock>
          For more information on how this works and why we're requesting specific scopes,{' '}
          <a
            href={`https://${root.appOptions.domain}/support/portal/microsoft-tenant`}
            target={'_blank'}
            rel={'noreferrer'}
          >
            check out our support article.
          </a>
        </Body>
        <Block marginTop={'@size-l'}>
          <Input
            size={'large'}
            style={{ width: '100%' }}
            value={enteredTenant}
            onChange={e => setEnteredTenant(e.target.value)}
            onPressEnter={submit}
            placeholder={originalToken ? 'contoso.onmicrosoft.com' : 'Invalid URL'}
            disabled={!originalToken}
          />
          <Block marginTop={'@size-s'} style={{ textAlign: 'right' }}>
            <Button
              size={'large'}
              type={'primary'}
              disabled={!originalToken || !enteredTenant.trim()}
              onClick={submit}
            >
              Approve with Microsoft
            </Button>
          </Block>
        </Block>
      </>
    )
  }
}
