import { MessageOutlined } from '@ant-design/icons'
import { Markdown as ReactMarkdown, BlockSpin, ErrorIndicator, styled, Modal } from '@thesisedu/web'
import { Button, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useDebounce } from 'use-debounce'

import { AssignmentRubricFragment, useAssignmentSubmissionQuery } from '../schema'

interface CommentAndQuestion {
  name: string
  comment: string
}
const getCommentsAndQuestions = (
  rubric: AssignmentRubricFragment,
  comments: any,
): CommentAndQuestion[] => {
  const { categories } = rubric
  return Object.keys(comments)
    .map(categoryId => {
      const category = categories.find(q => q.categoryId === categoryId)
      if (!category || !comments[categoryId]) return null
      return {
        name: category.name,
        comment: comments[categoryId],
      }
    })
    .filter(Boolean) as CommentAndQuestion[]
}

export interface CommentsViewerProps {
  submissionId: string
}
export function CommentsViewer({ submissionId }: CommentsViewerProps) {
  const [visible, setVisible] = useState(false)
  const [delayedVisible] = useDebounce(visible, 500, { leading: true })
  const { data, loading } = useAssignmentSubmissionQuery({
    variables: { id: submissionId },
    skip: !delayedVisible,
  })
  const commentsAndQuestions =
    data?.node?.__typename === 'AssignmentSubmission' && data.node.assignment.rubric
      ? getCommentsAndQuestions(data.node.assignment.rubric, data.node.comments)
      : null
  let content = commentsAndQuestions ? (
    commentsAndQuestions.map(({ name, comment }) => (
      <Container key={name}>
        <strong>{name}</strong>
        <ReactMarkdown children={comment} />
      </Container>
    ))
  ) : (
    <ErrorIndicator />
  )

  if (!commentsAndQuestions && loading) {
    content = <BlockSpin />
  }

  return (
    <>
      <a onClick={() => setVisible(true)} data-testid={'CommentsViewer'}>
        <Tooltip title={'View Teacher Comments'}>
          <MessageOutlined />
        </Tooltip>
      </a>
      <Modal
        title={'Teacher Comments'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {content}
      </Modal>
    </>
  )
}

const Container = styled.div`
  margin-bottom: 15px;
  > p:first-of-type {
    margin-top: 0;
  }
`
