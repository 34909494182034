import { QuestionOverride } from '@thesisedu/feature-assignments-core'
import { filterValuesDeep, notUndefined } from '@thesisedu/feature-utils'

import { QuestionSettingsValue } from './types'
import { FullAssignment } from '../../grading/types'
import { debug } from '../../log'
import { transformRubric } from '../../rubric/helpers'
import { AssignmentInput } from '../../schema'

export function questionSettingsValueToAssignment(
  value: QuestionSettingsValue,
  assignment: FullAssignment,
): AssignmentInput {
  const questionOverrides: QuestionOverride[] = [...(assignment.rawConfiguration?.questions ?? [])]
  for (const questionId of Object.keys(value)) {
    const { totalPoints, ..._questionSettings } = value[questionId]
    const questionSettings = filterValuesDeep(_questionSettings, notUndefined)
    if (!Object.keys(questionSettings).length) continue
    const overrideIndex = questionOverrides.findIndex(q => q.id === questionId)
    if (overrideIndex !== -1) {
      questionOverrides[overrideIndex] = {
        ...questionOverrides[overrideIndex],
        settings: questionSettings,
      }
    } else {
      questionOverrides.push({
        id: questionId,
        settings: questionSettings,
      })
    }
  }
  const result: AssignmentInput = {
    configuration: {
      ...assignment.rawConfiguration,
      questions: questionOverrides,
    },
  }

  // Now handle total points.
  const transformedRubric = assignment.rubric ? transformRubric(assignment.rubric) : undefined
  if (transformedRubric) {
    result.rubric = {
      ...transformedRubric,
      categories: transformedRubric.categories.map(category => {
        return {
          ...category,
          totalPoints: value[category.id]?.totalPoints ?? category.totalPoints,
        }
      }),
    }
  }

  debug('transformed question settings form value from %O to %O', value, result)
  return result
}
