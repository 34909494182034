import { Button, ButtonProps, message } from 'antd'
import { MultiWindow } from 'iconoir-react'
import React from 'react'
import NewWindow from 'react-new-window'
import { createGlobalStyle } from 'styled-components'

import { AntIconWrapper } from './AntIconWrapper'
import { Tooltip } from './AntStyled'
import { ModalProps } from './Modal'
import { Reset } from './Reset'
import { ResizeableModal } from './ResizeableModal'
import { WindowProvider } from './WindowContext'
import { styled } from './styledTypes'

export enum ViewMode {
  InBrowser = 'browser',
  Window = 'window',
}

export interface PopoutModalProps extends Omit<ModalProps, 'title'> {
  title?: string
  defaultViewMode?: ViewMode
  defaultWidth?: number
  defaultHeight?: number
  noPadding?: boolean
  children: React.ReactElement
}
export function PopoutModal({
  title,
  visible,
  onCancel,
  children,
  defaultViewMode = ViewMode.InBrowser,
  defaultWidth = 500,
  defaultHeight = 300,
  noPadding,
  ...modalProps
}: PopoutModalProps) {
  const [viewMode, setViewMode] = React.useState<ViewMode | null>(visible ? defaultViewMode : null)
  React.useEffect(() => {
    setViewMode(visible ? defaultViewMode : null)
  }, [visible])
  const lastWidth = React.useRef(defaultWidth)
  const lastHeight = React.useRef(defaultHeight)
  const [contentHeight, _setContentHeight] = React.useState(defaultHeight)
  const heightTimeout = React.useRef<any>(undefined)
  const setContentHeight = (height: number) => {
    clearTimeout(heightTimeout.current)
    heightTimeout.current = setTimeout(() => {
      _setContentHeight(height)
    }, 500)
  }
  const modalRef = React.useRef<HTMLDivElement | null>(null)
  const [window, setWindow] = React.useState<Window | undefined>(undefined)

  if (viewMode === ViewMode.Window) {
    return (
      <NewWindow
        title={title || 'Viewing Content'}
        features={{ popup: true, width: lastWidth.current, height: lastHeight.current }}
        onOpen={w => {
          setWindow(w)
        }}
        onUnload={() => {
          setWindow(undefined)
          if (onCancel) {
            onCancel({} as any)
          }
        }}
        onBlock={() => {
          setViewMode(ViewMode.InBrowser)
          message.error(
            'You need to disable your popup blocker in order to open this content in a new window.',
          )
        }}
      >
        <WindowProvider window={window || undefined}>
          <WindowContentContainer noPadding={noPadding}>{children}</WindowContentContainer>
        </WindowProvider>
      </NewWindow>
    )
  } else {
    return (
      <>
        {visible ? <PopoutGlobalStyles /> : null}
        <ResizeableModal
          {...modalProps}
          onResize={(width, height) => {
            setContentHeight(height - 60) // 10px padding on the bottom
          }}
          defaultHeight={defaultHeight}
          defaultWidth={defaultWidth}
          title={title || undefined}
          visible={!!viewMode}
          onCancel={onCancel}
          modalRef={modalRef}
          mask={false}
          maskClosable={false}
          topRightActions={
            <>
              {modalProps.topRightActions}
              <Tooltip title={'Open in New Window'}>
                <PopoutIconContainer
                  onClick={e => {
                    e.stopPropagation()
                    const modal = (e.target as HTMLElement).closest('.feature-modal')
                    if (modal) {
                      lastWidth.current = modal.clientWidth
                      lastHeight.current = modal.clientHeight
                    }
                    setViewMode(ViewMode.Window)
                  }}
                >
                  <AntIconWrapper children={<MultiWindow />} />
                </PopoutIconContainer>
              </Tooltip>
            </>
          }
        >
          <ModalContentContainer>
            {React.cloneElement(children, { poppedOutHeight: contentHeight })}
          </ModalContentContainer>
        </ResizeableModal>
      </>
    )
  }
}

export interface PopoutModalButtonProps extends PopoutModalProps {
  buttonChildren?: React.ReactElement
}
export function PopoutModalButton({
  buttonChildren,
  ...rest
}: React.PropsWithChildren<PopoutModalButtonProps>) {
  const [visible, setVisible] = React.useState(false)
  const [defaultViewMode, setDefaultViewMode] = React.useState<ViewMode | undefined>(undefined)
  const button = buttonChildren || <Button icon={<AntIconWrapper children={<MultiWindow />} />} />
  return (
    <>
      <PopoutModal
        {...rest}
        visible={visible}
        onCancel={() => setVisible(false)}
        defaultViewMode={defaultViewMode}
      />
      {React.cloneElement<ButtonProps>(button, {
        onClick: e => {
          if (e) e.preventDefault()
          setDefaultViewMode(e.ctrlKey || e.metaKey ? ViewMode.Window : ViewMode.InBrowser)
          setVisible(true)
        },
      })}
    </>
  )
}

export interface PopoutContentProps extends PopoutModalButtonProps {}
export function PopoutContent({ children, ...rest }: PopoutContentProps) {
  return (
    <ContentContainer>
      <PopoutModalButton
        {...rest}
        buttonChildren={
          <Tooltip title={'Open in Pop-out Window'} placement={'bottomRight'}>
            <PopContentButton>
              <AntIconWrapper children={<MultiWindow />} />
            </PopContentButton>
          </Tooltip>
        }
        children={children}
      />
      {children}
    </ContentContainer>
  )
}

const PopContentButton = styled(Reset.Button)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
  pointer-events: none;
  transition: opacity 0.25s linear;
  padding: ${props => props.theme['@size-xs']};
  opacity: 0;
  cursor: pointer;
  z-index: 951;
`
const ContentContainer = styled.div`
  position: relative;
  &:hover ${PopContentButton} {
    opacity: 1;
    pointer-events: all;
  }
`
const PopoutIconContainer = styled(Reset.Button)`
  font-size: ${props => props.theme['@size-sm']};
  color: ${props => props.theme['@text-color-base']};
  cursor: pointer;
`
const WindowContentContainer = styled.div<{ noPadding?: boolean }>`
  padding: ${props => (props.noPadding ? 0 : props.theme['@size-s'])};
  img {
    max-width: 100%;
  }
  [data-sticky] {
    top: 0 !important;
  }
`
const ModalContentContainer = styled.div`
  [data-sticky] {
    top: 0 !important;
  }
`
const PopoutGlobalStyles = createGlobalStyle`
  .ant-modal-wrap {
    pointer-events: none;
  }
`
