import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { decode } from 'jsonwebtoken'

import { UsersReactFeature } from './UsersReactFeature'
import { UserFragment } from './schema'

export async function getAuthenticatedUser(
  this: UsersReactFeature,
  noSession?: boolean,
): Promise<Partial<UserFragment> | null> {
  const apolloFeature = this.root.requireFeature<ApolloReactFeature>(ApolloReactFeature.package)
  const authenticationKey = await apolloFeature.getAuthenticationToken(noSession)
  if (authenticationKey) {
    const decoded = decode(authenticationKey)
    if (decoded && typeof decoded === 'object' && decoded.group) {
      return decoded as Partial<UserFragment>
    }
  }

  return null
}
