import { styled } from './styledTypes'

export const LargeProse = styled.div`
  p,
  li {
    line-height: 2 !important;
    font-size: ${props => props.theme['@font-size-lg']};
  }
  h2 {
    margin-top: ${props => props.theme['@size-xl']};
    margin-bottom: ${props => props.theme['@size-s']};
  }
  h3 {
    margin-top: ${props => props.theme['@size-l']};
    margin-bottom: ${props => props.theme['@size-s']};
  }
  ul {
    list-style: none none;
    padding-left: ${props => props.theme['@size-xs']};
    margin: ${props => props.theme['@size-s']} 0;
    li {
      margin: ${props => props.theme['@size-xs']} 0;
      padding-left: ${props => props.theme['@size-m']};
      position: relative;
      font-size: ${props => props.theme['@font-size-lg']};
      &:before {
        display: block;
        content: ' ';
        position: absolute;
        top: 0.65em;
        left: 0;
        width: 8px;
        height: 8px;
        background: ${props => props.theme['@gray-2']};
        border-radius: 50%;
      }
    }
  }
`
