import {
  AppstoreFilled,
  AppstoreOutlined,
  EditOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons'
import { CalculatedPreset, CourseQuestion } from '@thesisedu/feature-course-types'
import React from 'react'

export interface QuizVaultPreset {
  type: CalculatedPreset
  title: string
  description: string
  icon: React.FC<React.PropsWithChildren<object>>
  filter?: (question: CourseQuestion) => boolean
}

export const QUIZ_VAULT_PRESETS: QuizVaultPreset[] = [
  {
    type: CalculatedPreset.Recommended,
    title: 'Use our recommended exam.',
    description:
      'Includes a standard set of quiz questions chosen by BusinessU and our partners. ' +
      'Covers all standards, whether or not you skipped them in class.',
    icon: StarOutlined,
    filter: question => !!question.config?.isRecommended,
  },
  {
    type: CalculatedPreset.RecommendedCovered,
    title: 'Use our recommended exam, but only content you covered.',
    description:
      'Includes **a subset of** quiz questions chosen by BusinessU and our partners. ' +
      'Covers **most** standards, and only includes questions related to content you covered.',
    icon: StarFilled,
    filter: question => !!question.config?.isRecommended && !!question.isCovered,
  },
  {
    type: CalculatedPreset.AllCovered,
    title: 'Use all questions from content you covered.',
    description:
      'Includes the entire bank of available quiz questions, ' +
      'only including questions related to content you covered.',
    icon: AppstoreFilled,
    filter: question => !!question.isCovered,
  },
  {
    type: CalculatedPreset.All,
    title: 'Use all questions available.',
    description:
      'Includes the entire bank of available quiz questions, ' +
      '**regardless of if you covered them or not.**',
    icon: AppstoreOutlined,
    filter: () => true,
  },
  {
    type: CalculatedPreset.Manual,
    title: 'Choose questions manually.',
    description:
      'Start with a blank slate! Pick and choose questions you want ' +
      'to add to your exam, one by one. Allows creating an exam completely tailored ' +
      'to your students.',
    icon: EditOutlined,
  },
]
