import React from 'react'

import { ForwardTheme } from './ThemeProvider'
import { OverlayProvider } from '../context/OverlayContext'

export function wrapPortal<
  Props extends { children: any },
  T extends React.ComponentType<React.PropsWithChildren<Props>>,
>(PortalComponent: T) {
  return function ({ children, ...props }: Props) {
    return (
      <PortalComponent {...(props as any)}>
        <OverlayProvider>
          <ForwardTheme>{children}</ForwardTheme>
        </OverlayProvider>
      </PortalComponent>
    )
  } as T
}
