import { execSync } from 'child_process'

import { debug, warn } from './log'

export function getRelease(): string | null {
  try {
    if (process.env.REACT_APP_RELEASE_OVERRIDE) return process.env.REACT_APP_RELEASE_OVERRIDE
    debug('getting git commit hash')
    const result = execSync('git rev-parse --short HEAD')
    const shortHash = result.toString().trim()
    if (!shortHash) {
      warn('could not get commit hash; not setting current release')
      return null
    }
    return shortHash
  } catch (err: any) {
    warn('error getting current git commit hash')
    warn(err)
    return null
  }
}
