import * as ReactToolbar from '@radix-ui/react-toolbar'
import React from 'react'

import { styled, s, Button$, Button } from '../..'
import { NavArrowDown } from '../../../icons'

export interface ToolbarContextValue {
  size?: Button$.ButtonSize
}
export const ToolbarContext = React.createContext<ToolbarContextValue | undefined>(undefined)

export function useToolbarContext(): ToolbarContextValue | undefined
export function useToolbarContext(require: false): ToolbarContextValue | undefined
export function useToolbarContext(require: true): ToolbarContextValue
export function useToolbarContext(require?: boolean): ToolbarContextValue | undefined {
  const context = React.useContext(ToolbarContext)
  if (!context && require) {
    throw new Error('ToolbarContext is required, yet not provided.')
  }
  return context
}

export interface ToolbarProps extends ReactToolbar.ToolbarProps {
  size?: Button$.ButtonSize
}
export function Toolbar({ size, ...props }: ToolbarProps) {
  return (
    <ToolbarContext.Provider value={{ size: size ?? 'medium' }}>
      <_Toolbar {...props} />
    </ToolbarContext.Provider>
  )
}
const _Toolbar = styled(ReactToolbar.Toolbar)`
  display: flex;
  align-items: center;
  padding: ${s.size('xxs')};
  border-radius: ${s.var('radii.1')};
  background: ${s.color('subtle')};
  &[data-orientation='horizontal'] {
    flex-wrap: wrap;
  }
  &[data-orientation='vertical'] {
    overflow-y: auto;
    flex-direction: column;
  }
`

export interface ToolbarButtonProps extends Button$.ButtonProps {
  selected?: boolean
  isDropdown?: boolean
}
function _ToolbarButton(
  { selected, isDropdown, ...props }: ToolbarButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { size = 'medium' } = useToolbarContext(false) || {}
  return (
    <ReactToolbar.Button asChild>
      <Button
        ref={ref}
        rightDecorator={isDropdown ? <NavArrowDown /> : undefined}
        size={size}
        {...props}
        variant={s.variants({ selected }, 'ghost', props.variant)}
      />
    </ReactToolbar.Button>
  )
}
export const ToolbarButton = React.forwardRef(_ToolbarButton)

export const Separator = styled(ReactToolbar.Separator)`
  width: 1px;
  background: ${s.color('border')};
  margin: ${s.size('xs')} ${s.size('xxs')};
  align-self: stretch;
`
