import * as React from 'react'

function SvgNoteWhole(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#000'}
          d={
            'M96.427 210.835c0 15.243-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1-7.612-16.644 6.771-39.552 32.125-51.168 17.75-8.132 36.173-8.574 48.238-2.396 8.914 4.882 13.472 11.003 13.472 20.117z'
          }
        />
        <path
          fill={'#FFF'}
          d={
            'M32.938 199.738c-19.882 13.07-29.007 34.079-20.382 46.926 8.625 12.847 31.734 12.667 51.616-.403 19.883-13.069 29.008-34.078 20.383-46.925-8.626-12.847-31.735-12.667-51.617.402z'
          }
        />
      </g>
    </svg>
  )
}

const MemoSvgNoteWhole = React.memo(SvgNoteWhole)
export default MemoSvgNoteWhole
