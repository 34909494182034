import moment from 'moment'

import { AssignmentWithRemainingAttemptsFragment, BasicAssignmentFragment } from '../schema'

export function useAssignmentTimeLeft(
  assignment:
    | (AssignmentWithRemainingAttemptsFragment & Pick<BasicAssignmentFragment, 'timeLimit'>)
    | undefined,
) {
  if (
    assignment?.timeLimit &&
    parseInt(assignment.timeLimit.toString(), 10) > 30 &&
    assignment.startedAt &&
    !assignment.submittedAt
  ) {
    return moment(assignment.startedAt)
      .add(assignment.timeLimit, 'seconds')
      .diff(moment(), 'second')
  } else return null
}
