import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'

export function ReadOnlyPlugin() {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    if (editor._observer) {
      editor._observer.observe = () => {}
      editor._observer.disconnect()
    }
  }, [])

  return null
}
