import { onMutationError } from '@thesisedu/feature-apollo-react'
import { SiteSelect } from '@thesisedu/feature-sites-web'
import { Modal } from '@thesisedu/web'
import { Button, message } from 'antd'
import React from 'react'

import { useMoveTeacherMutation } from './schema'

export interface MoveTeacherToSiteButtonProps {
  teacherId: string
}

export function MoveTeacherToSiteButton({ teacherId }: MoveTeacherToSiteButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const [toSite, setToSite] = React.useState<string | undefined>(undefined)
  const [moveTeacherMutation, { loading }] = useMoveTeacherMutation({
    onCompleted: () => {
      setVisible(false)
      message.success('Teacher moved successfully!')
      setToSite(undefined)
    },
    onError: onMutationError(
      'There was an error moving that teacher.',
      ['CANNOT_MOVE_TEACHER_SAME_SITE_ERROR'],
      () => {
        message.error('The teacher you are trying to move already belongs to that site.')
      },
    ),
    refetchQueries: ['teachersForSite'],
  })
  const handleClose = () => {
    setVisible(false)
    setToSite(undefined)
  }
  return (
    <>
      <Button type={'link'} onClick={() => setVisible(true)} style={{ whiteSpace: 'nowrap' }}>
        Move to Site &rarr;
      </Button>
      <Modal
        visible={visible}
        title={'Move Teacher to New Site'}
        onCancel={handleClose}
        footer={[
          <Button loading={loading} key={'cancel'} onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            loading={loading}
            type={'primary'}
            disabled={!toSite}
            onClick={() => {
              if (!toSite) return
              moveTeacherMutation({
                variables: {
                  input: { toSite, id: teacherId },
                },
              })
            }}
          >
            Move
          </Button>,
        ]}
      >
        <SiteSelect value={toSite} onChange={setToSite} />
      </Modal>
    </>
  )
}
