import { UserSettingsPage } from '@thesisedu/feature-users-web'
import { User } from '@thesisedu/react/icons'
import React from 'react'

import { UsersReactFeature } from '../../UsersReactFeature'
import { UserSettingsPageResource } from '../../types'

export default function (feature: UsersReactFeature) {
  feature.resources.addResource<UserSettingsPageResource>({
    type: 'UserSettingsPage',
    identifier: 'user',
    icon: <User />,
    title: 'User Settings',
    weight: -10,
    component: () => <UserSettingsPage />,
  })
}
