import { InfiniteScrollerProps } from '@thesisedu/web'
import { get } from 'lodash'
import React from 'react'

import { DocumentNode, QueryHookOptions, QueryResult, useQuery } from './apollo'
import {
  DataResult,
  getSetResult,
  RequiredVariables,
  loadMore as _loadMore,
  LoadMoreOpts,
} from './loadMore'
import { debug } from './log'
import { useVariableEffect } from './useVariableEffect'

export type InfiniteQueryChildrenOpts<
  Item,
  Data,
  Variables extends RequiredVariables,
> = QueryResult<Data, Variables> & {
  result?: DataResult<{ node: Item }>
  loadingMore?: boolean
  loadMore: () => Promise<any>
  refetch: () => Promise<any>
  /** True if we are either refetching data, or loading new data completely. */
  loadingFresh: boolean
}
export interface InfiniteQuerySubscribeToMoreOpts<
  Item = Record<string, any>,
  Variables extends object = Record<string, any>,
  Edge extends object = { node: Item },
> {
  document: DocumentNode
  resultPath: string
  variables: Variables
  /**
   * If you would like to control the order where new items get added, override this
   * function. Else, new items will be added to the end of the list.
   */
  addItem?: (existingEdges: Edge[], newItem: Item) => Edge[]
}

export interface InfiniteQueryProps<Item, Data, Variables extends RequiredVariables>
  extends Omit<LoadMoreOpts<Data, Variables, { node: Item }>, 'fetchMore' | 'data'> {
  document: DocumentNode
  variables?: Variables
  /**
   * @deprecated this should no longer be used, as it only referred to the error indicator
   */
  isCompact?: boolean
  infiniteScrollerProps?: Partial<InfiniteScrollerProps>
  children: (result: InfiniteQueryChildrenOpts<Item, Data, Variables>) => React.ReactNode
  queryOpts?: Partial<QueryHookOptions<Data, Variables>>
  refetchKey?: string
  noLoading?: boolean
  /**
   * Specify this if you would like to use subscriptions to subscribe to new results
   * for this query.
   */
  subscribeToMore?: InfiniteQuerySubscribeToMoreOpts<Item, any>
}

export function useInfiniteQuery<Item, Data, Variables extends RequiredVariables>(
  props: InfiniteQueryProps<Item, Data, Variables>,
) {
  const {
    document,
    queryOpts,
    variables,
    getResult: _getResult,
    setResult: _setResult,
    resultPath,
    refetchKey,
  } = props
  const [loadingMore, setLoadingMore] = React.useState(false)
  const firstMountRef = React.useRef(true)
  const lastValidResultRef = React.useRef<DataResult>()
  const queryResult = useQuery<Data, Variables>(document, {
    ...queryOpts,
    variables,
  })
  const [refetching, setRefetching] = React.useState(false)
  const { data, error, loading, fetchMore, refetch: _refetch } = queryResult
  const { getResult, setResult } = getSetResult<Data, Variables>({
    getResult: _getResult,
    setResult: _setResult,
    resultPath,
  })
  useSubscribeToMore<Data>({ queryResult, opts: props.subscribeToMore, getResult, setResult })
  const _result = getResult(data)
  React.useEffect(() => {
    if (_result) {
      lastValidResultRef.current = _result
    }
  }, [_result])
  const result = _result || lastValidResultRef.current
  const refetch: typeof _refetch = async (...args) => {
    setRefetching(true)
    try {
      return await _refetch(...args)
    } finally {
      setRefetching(false)
    }
  }
  useVariableEffect(variables, () => {
    debug('variables have changed; refetching')
    refetch(variables)
  })
  React.useEffect(() => {
    if (!firstMountRef.current) {
      debug('refetchKey changed; refetching')
      refetch(variables)
    }
  }, [refetchKey])
  React.useEffect(() => {
    firstMountRef.current = false
  }, [])

  const loadMore = async () => {
    setLoadingMore(true)
    try {
      await _loadMore<Data, Variables>({
        getResult: _getResult,
        setResult: _setResult,
        resultPath,
        fetchMore,
        data,
        variables,
      })
    } finally {
      setLoadingMore(false)
    }
  }

  const loadingFresh = refetching || (loading && !result?.edges.length)

  return {
    error,
    loading,
    loadingMore,
    loadingFresh,
    result,
    loadMore,
    queryResult,
    async refetch() {
      return refetch(variables)
    },
  }
}

interface UseSubscribeToMoreOpts<Data> {
  queryResult: QueryResult<any, any>
  opts: InfiniteQuerySubscribeToMoreOpts<any, any> | undefined
  getResult: (data?: Data | undefined) => DataResult<any> | null | undefined
  setResult: (previous: Data, data: DataResult<any>) => Data
}
function useSubscribeToMore<Data>({
  queryResult,
  opts,
  getResult,
  setResult,
}: UseSubscribeToMoreOpts<Data>) {
  const { subscribeToMore } = queryResult
  React.useEffect(() => {
    if (opts && subscribeToMore) {
      const { document, resultPath, variables } = opts
      debug('subscribing to more')
      return subscribeToMore({
        document,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev
          debug('new InfiniteQuery subscription item received: %O', subscriptionData.data)
          const result = getResult(prev)
          const newItem = get(subscriptionData.data, resultPath)
          if (newItem && result) {
            debug('handling newItem %O and result %O', newItem, result)
            return setResult(prev, {
              ...result,
              edges: opts.addItem
                ? opts.addItem(result.edges, newItem)
                : [...result.edges, newItem],
            })
          } else {
            debug('could not find newItem, or result')
          }
        },
      })
    }
  }, [opts, subscribeToMore])
}
