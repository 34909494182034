import { getSegmentName } from '@thesisedu/feature-course-types'
import { useResource } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { EditorValue, getWidgetHeadings, Legacy } from '@thesisedu/feature-widgets-react'
import { BookmarkBook, InfoEmpty, Packages, User, Edit } from '@thesisedu/react/icons'
import React from 'react'

import { EditSegmentItem } from './EditSegmentItem'
import { useSegmentDecoration } from '../contexts/SegmentDecorationsContext'
import {
  GetNavigationOpts,
  Segment,
  SegmentNavigation,
  SegmentNavigationItem,
  SegmentTypeResource,
} from '../types'

export enum NavigationKey {
  Teacher = 'teacher',
  Student = 'content',
  Standards = 'standards',
  Children = 'children',
  Edit = 'edit',
}

export function widgetsToNavigationItems(value: EditorValue): SegmentNavigationItem[] {
  return getWidgetHeadings(value).map(item => ({
    ...item,
    isElement: true,
  }))
}

export interface GetSegmentNavigationItemsOpts extends GetNavigationOpts {
  forceTeacher?: boolean
  forceStudent?: boolean
}
export function getSegmentNavigationItems({
  segment,
  viewer,
  forceTeacher,
  forceStudent,
}: GetSegmentNavigationItemsOpts): SegmentNavigation {
  const { studentContent, teacherContent } = segment.config
  const items: SegmentNavigationItem[] = []
  if (
    (!Legacy.anyEditorValueIsEmpty(teacherContent) || forceTeacher) &&
    viewer?.group !== 'STUDENT'
  ) {
    items.push({
      id: NavigationKey.Teacher,
      name: 'Teacher Information',
      icon: <InfoEmpty />,
    })
  }
  if (!Legacy.anyEditorValueIsEmpty(studentContent) || forceStudent) {
    items.push({
      id: NavigationKey.Student,
      name: viewer?.group === 'STUDENT' ? getSegmentName(segment) : 'Student View',
      icon: <User />,
      children: widgetsToNavigationItems(Legacy.getBlockValueFromAnyValue(studentContent)),
    })
  }
  if (viewer && viewer.group !== 'STUDENT' && segment.tags) {
    items.push({
      id: NavigationKey.Standards,
      name: 'Standards',
      icon: <Packages />,
    })
  }
  if (viewer && viewer.group !== 'STUDENT' && segment.childSegments?.length) {
    items.push({
      id: NavigationKey.Children,
      name: 'Group Contents',
      icon: <BookmarkBook />,
    })
  }
  if (
    viewer &&
    viewer.group !== 'STUDENT' &&
    segment.userId?.toString() === fromGlobalId(viewer.id, true).id
  ) {
    items.push({
      id: NavigationKey.Edit,
      name: 'Edit',
      icon: <Edit />,
      renderContent: EditSegmentItem,
    })
  }

  return items
}

export function useDefaultNavigationKey(segment?: Segment) {
  const segmentResource = useResource<SegmentTypeResource>('SegmentType', segment?.type || '')
  const viewer = useViewerContext(false)

  const decoration = useSegmentDecoration(segment?.id || '')

  if (segmentResource?.defaultNavigationKey && segment) {
    return segmentResource.defaultNavigationKey(viewer, segment, decoration)
  } else {
    const hasTeacherContent =
      segment?.client?.hasTeacherContent ||
      !Legacy.anyEditorValueIsEmpty(segment?.config.teacherContent)
    const hasGroupContent = segment?.childSegments?.length
    const isTeacher = viewer?.group === 'TEACHER'
    return hasTeacherContent && isTeacher
      ? NavigationKey.Teacher
      : hasGroupContent
      ? NavigationKey.Children
      : NavigationKey.Student
  }
}
