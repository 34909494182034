import React from 'react'

import { s, styled } from '../../'
import { Block, BlockProps } from '../Block/Block'

export interface VSpacedProps extends BlockProps {
  /** Pass false to not have any space. */
  space?: s.AnySize | false
  align?: 'flex-start' | 'center' | 'flex-end' | 'baseline' | 'stretch'
  justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around'
}
function _VSpaced(
  { space, align, justify, ...rest }: VSpacedProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return <__VSpaced $space={space} $align={align} $justify={justify} {...rest} ref={ref} />
}
export const VSpaced = React.forwardRef(_VSpaced)

const __VSpaced = styled(Block)<{
  $space: VSpacedProps['space']
  $align: VSpacedProps['align']
  $justify: VSpacedProps['justify']
}>`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.$align || 'stretch'};
  justify-content: ${props => props.$justify || 'flex-start'};
  gap: ${props => (props.$space === false ? 0 : s.size(props.$space || 's'))};
`
