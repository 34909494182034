import { getFragmentDefinitions } from '@apollo/client/utilities'
import {
  addField,
  addFragmentReference,
  useModifiedFragment,
} from '@thesisedu/feature-apollo-react'
import { AssignmentGraderResource, GroupsConfiguration } from '@thesisedu/feature-assignments-core'
import {
  ClassHooks,
  StudentClassGroupingsContext,
  StudentClassGroupingsPayload,
} from '@thesisedu/feature-classes-react'
import {
  DeepLinkResource,
  isInNode,
  ReactFeature,
  ReactFeatureDependencies,
  ReactHooks,
  ReactUse,
} from '@thesisedu/feature-react'
import gql from 'graphql-tag'
import React from 'react'

import { AssignmentWithSyncFragmentDoc } from './schema'
import {
  AssignmentsReactOptions,
  RequiredAssignmentsReactOptions,
  ClassWithGroupsFragment,
} from './types'

export class AssignmentsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-assignments-react'
  static path = __dirname
  static requires = []
  public options!: AssignmentsReactOptions

  constructor(options: RequiredAssignmentsReactOptions, deps: ReactFeatureDependencies) {
    const getAssignmentLink = isInNode() ? () => {} : require('./links').DEFAULT_GET_ASSIGNMENT_LINK
    const getAssignmentSubmissionLink = isInNode()
      ? () => {}
      : require('./links').DEFAULT_GET_ASSIGNMENT_SUBMISSION_LINK
    super(
      {
        submitForGradingLabel: 'Submit for Grading',
        getAssignmentLink,
        getAssignmentSubmissionLink,
        performanceFieldAllowsBookmarks: true,
        ...options,
      } as AssignmentsReactOptions,
      deps,
    )
  }

  public reactResources() {
    const { ASSIGNMENT_GRADERS } = require('@thesisedu/feature-assignments-core')
    for (const graderKey of Object.keys(ASSIGNMENT_GRADERS)) {
      this.resources.addResource<AssignmentGraderResource>({
        type: 'AssignmentGrader',
        identifier: graderKey,
        grader: ASSIGNMENT_GRADERS[graderKey],
      })
    }

    // Add question types.
    require('./resources/questions').default(this)

    // And other builder resources...
    require('./resources/Widgets').default(this)
    require('./resources/interactions').default(this)
    require('./resources/user_tasks').default(this)
    require('./resources/background_job_reporters/bulkUpdateAssignmentsInClass').default(this)

    const {
      GradingTeacherClassTaskContextProvider,
    } = require('./contexts/GradingTeacherClassTaskContext')
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
      return <GradingTeacherClassTaskContextProvider children={children} />
    })

    const { addClassConfigurationFields } = require('./resources/class_configuration')
    addClassConfigurationFields(this)

    require('./resources/class_tasks').default(this)

    const {
      AssignmentDeepLinkRemapper,
      AssignmentSubmissionDeepLinkRemapper,
    } = require('./DeepLinkRemapper')
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'assignment',
      params: [{ identifier: 'id', required: true }],
      Component: AssignmentDeepLinkRemapper,
    })
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'assignment-submission',
      params: [{ identifier: 'id', required: true }],
      Component: AssignmentSubmissionDeepLinkRemapper,
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'ClassStudentEdge', fragment => {
      return addField(fragment, 'grade')
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Class', fragment => {
      let result = addField(fragment, 'averageGrade')
      result = addField(result, 'canUpdateDefaultGroups')
      result = addField(result, 'canCreateAssignment')
      result = addField(result, 'canManageAssignmentCategories')
      result = addField(result, 'canManageAssignmentClassConfiguration')
      return addField(result, 'groups')
    })

    require('./resources/MyContentPage/RubricTemplates').default(this)
    require('./resources/MutationConfirmation/GradingMode').default(this)
    require('./hooks/ClassDraggableOverlayItems').default(this)
    require('./hooks/ClassDraggableFooterItems').default(this)
    require('./hooks/classRoutes').default(this)
    require('./hooks/ClassContext').default(this)
    require('./hooks/routes').default(this)

    const hasSyncFeature = this.root.getFeature('@thesisedu/feature-class-sync-react')
    if (hasSyncFeature) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useModifiedFragment(this.hookManager, 'BasicAssignment', (fragment, context) => {
        const otherFragments = AssignmentWithSyncFragmentDoc.definitions.slice(1) as any[]
        for (const otherFragment of otherFragments) {
          if (
            !context.additionalDefinitions.some(def => def.name.value === otherFragment.name.value)
          ) {
            context.additionalDefinitions.push(otherFragment)
          }
        }
        return addFragmentReference(
          fragment,
          AssignmentWithSyncFragmentDoc.definitions[0] as any,
          context,
        )
      })

      require('./hooks/sync').default(this)
      require('./hooks/GroupsEditImport').default(this)
    }

    // Allow grouping students by their default groups...
    this.hookManager.registerMutateHook<StudentClassGroupingsPayload, StudentClassGroupingsContext>(
      ClassHooks.StudentClassGroupings,
      (students, context) => {
        const cls = context!.class as ClassWithGroupsFragment | undefined
        const groups = cls?.groups as GroupsConfiguration | undefined
        if (groups) {
          return students.map(student => {
            const groupKey = Object.keys(groups).find(groupKey =>
              groups[groupKey].students.includes(student.id),
            )
            if (groupKey) return { ...student, group: groups[groupKey].name }
            else return student
          })
        } else {
          return students
        }
      },
    )

    require('./hooks/generated').default(this)
    require('./resources/ClassTeacherPermission').default(this)
  }

  prepareEarly() {
    const mediaFeature = this.root.getFeature('@thesisedu/feature-media-react')
    if (mediaFeature && !isInNode()) {
      const { MediaFragmentDoc } = require('@thesisedu/feature-media-react/dist/schema')
      const resource = require('./resources/questions/Performance').default(
        this.options.performanceQuestionLabel,
      )
      if (resource) {
        this.resources.addResource(resource)
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useModifiedFragment(this.hookManager, 'BasicAssignmentSubmission', (fragment, context) => {
          return addFragmentReference(
            fragment,
            getFragmentDefinitions(gql`
              fragment AssignmentSubmissionWithMedia on AssignmentSubmission {
                id
                media {
                  ...Media
                }
              }

              ${MediaFragmentDoc}
            `),
            context,
          )
        })
      }
    }

    if (this.root.getFeature('@thesisedu/feature-direct-messages-react') && !isInNode()) {
      require('./resources/dm_user_source').default(this)
    }

    if (this.root.getFeature('@thesisedu/feature-search-react') && !isInNode()) {
      require('./resources/search').default(this)
    }
  }
}

export const assignmentsReactFeature: ReactUse<RequiredAssignmentsReactOptions> = opts => [
  AssignmentsReactFeature,
  opts,
]
