import { ApolloClient } from '@apollo/client'
import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { FeaturePreviewResource } from '@thesisedu/feature-feature-preview-react'
import { isInNode, ReactFeature } from '@thesisedu/feature-react'
import React from 'react'

import * as background from './background'
import { createClient } from './client'
import { DEFAULT_OPTIONS, PREVIEW_PERSISTED_CACHE, SESSION_AUTH_KEY } from './constants'
import { getAuthenticationKey, openSessionAuth } from './platform/context'
import * as preload from './preload'
import { ApolloReactOptions } from './types'

export class ApolloReactFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-apollo-react'
  public static path = __dirname
  public static requires: string[] = []
  public apolloClient?: ApolloClient<any>
  public apolloClientPromise!: Promise<ApolloClient<any>>
  public readonly options!: ApolloReactOptions
  public static additionalConstructors = (feature: ApolloReactFeature) => {}

  public preloadQueries = preload.preloadQueries
  public getEnrichedBackgroundJobs = background.getEnrichedBackgroundJobs

  constructor(options: Partial<ApolloReactOptions>, deps: FeatureDependencies) {
    super(
      {
        ...DEFAULT_OPTIONS,
        ...options,
      },
      deps,
    )
    this.hookManager.registerMutateHook<React.ReactElement>('feature-web:wrap-app', children => {
      return this.wrapAppWithProvider(children)
    })
    // FIXME combine these two
    this.hookManager.registerMutateHook<React.ReactElement>('feature-native:wrap-app', children => {
      return this.wrapAppWithProvider(children)
    })
    this.resources.addResource<FeaturePreviewResource>({
      type: 'FeaturePreview',
      identifier: PREVIEW_PERSISTED_CACHE,
      title: 'Persisted Cache',
      subtitle: 'Increase performance by storing some information offline between sessions.',
      description: (
        <p>
          You will notice this most when leaving / coming back to the product, but the product will
          generally seem faster as well.{' '}
          <strong>Be careful, enabling this could result in out-of-date data showing.</strong> Our
          system is not perfect (yet). You may periodically want to reset your persisted cache by
          clicking "Reset Cache" in the user menu at the top-right of the page.
        </p>
      ),
    })
    ApolloReactFeature.additionalConstructors(this)
  }

  public isUsingSessionAuth() {
    return typeof sessionStorage !== 'undefined' && !!sessionStorage.getItem(SESSION_AUTH_KEY)
  }

  public openSessionAuthTab(jwt: string) {
    openSessionAuth(jwt)
  }

  public async prepareEarly() {
    if (!isInNode()) {
      this.apolloClientPromise = createClient(this.options, this).then(client => {
        this.apolloClient = client
        return client
      })
    }
  }

  public async prepareLate() {
    if (!isInNode()) {
      // Add the rest of the wrappers later so they appear closer to the bottom of the tree.
      require('./hooks/wrap').default(this)
    }
  }

  public reactResources() {
    require('./hooks/expectedError/ClientRequiresUpdateError').default(this)
  }

  public async wrapAppWithProvider(children: React.ReactElement): Promise<React.ReactElement> {
    const { ApolloReactProvider } = require('./ApolloReactProvider')
    return <ApolloReactProvider clientPromise={this.apolloClientPromise} children={children} />
  }

  public get client() {
    if (!this.apolloClient) {
      throw new Error('client has not been created')
    }
    return this.apolloClient
  }

  public get authenticationKey() {
    return this.options.authenticationKey
  }

  public async getAuthenticationToken(noSession?: boolean) {
    return getAuthenticationKey(this.options, noSession)
  }
}

export const apolloReactFeature = (options: Partial<ApolloReactOptions> = {}): FeatureUse => [
  ApolloReactFeature,
  options,
]
