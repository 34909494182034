/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AcceptInviteInput = {
  /** The token provided when being invited. */
  token: Scalars['String']['input'];
};

export enum AccountRegion {
  UsEast_1 = 'US_EAST_1',
  UsWest_1 = 'US_WEST_1'
}

export type AnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AnsweredQuestionContentInput = {
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type AnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum AnsweredQuestionCreator {
  Me = 'ME'
}

export type AnsweredQuestionInput = {
  content?: InputMaybe<AnsweredQuestionContentInput>;
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postName?: InputMaybe<Scalars['String']['input']>;
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AnsweredQuestionStatus>;
  thumbnail?: InputMaybe<Scalars['JSON']['input']>;
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
  vodId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible statuses for an answered question */
export enum AnsweredQuestionStatus {
  /** The question has been accepted, but we haven't started responding yet */
  Accepted = 'ACCEPTED',
  /** The question has just been submitted */
  New = 'NEW',
  /** We have completed our response and published it */
  Published = 'PUBLISHED',
  /** The question has been denied, for whatever reason */
  Rejected = 'REJECTED',
  /** We are preparing our response */
  Responding = 'RESPONDING'
}

/** Filters available when querying for answered questions. */
export type AnsweredQuestionsFilters = {
  /** Filter based on questions created by specific contributors. */
  contributorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Filter based on who created the question. */
  creator?: InputMaybe<AnsweredQuestionCreator>;
  /** Filter based on the status of the question. */
  status?: InputMaybe<Array<AnsweredQuestionStatus>>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
};

export type ApproveCommentInput = {
  id: Scalars['ID']['input'];
};

export type ApproveTrialRequestInput = {
  approval: TrialRequestApproval;
  id: Scalars['ID']['input'];
  noEmail?: Scalars['Boolean']['input'];
};

export type ArchiveClassInput = {
  /** The ID of the class to archive. */
  id: Scalars['ID']['input'];
};

/**
 * Specify either assignedStudents or assignedClasses, depending on the assignment mode for the
 * assignment. You may not use assignedStudents if the mode is ALL_STUDENTS.
 */
export type AssignInput = {
  assignedClasses?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedStudents?: InputMaybe<Array<ClassStudentInput>>;
};

export type AssignLessonInput = {
  assignments: Array<LessonAssociationInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum AssignmentAutoGradeMode {
  AllQuestions = 'ALL_QUESTIONS',
  None = 'NONE',
  SomeQuestions = 'SOME_QUESTIONS'
}

export type AssignmentCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  gradeWeight?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentClassConfigurationInput = {
  gradingMode?: InputMaybe<ClassGradingMode>;
};

export enum AssignmentExcludedFilter {
  Both = 'BOTH',
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

export enum AssignmentFeature {
  Attempts = 'ATTEMPTS',
  Groups = 'GROUPS'
}

export type AssignmentFiltersInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  dueDate?: InputMaybe<DueDateFilterInput>;
  /** What exclusion status of assignments should be included? */
  excluded?: InputMaybe<AssignmentExcludedFilter>;
  /**
   * If this is true, only generated assignments are included. This is the same as passing
   * all available generated providers to the generatedProviders option.
   */
  generated?: InputMaybe<Scalars['Boolean']['input']>;
  /** The identifiers for generated-assignment providers to filter on. */
  generatedProviders?: InputMaybe<Array<Scalars['String']['input']>>;
  missing?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<TagsFilter>;
  ungraded?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum AssignmentGradingMode {
  Questions = 'QUESTIONS',
  Rubric = 'RUBRIC'
}

export type AssignmentInput = {
  assign?: InputMaybe<AssignInput>;
  assignmentCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignmentMode?: InputMaybe<AssignmentStudentAssignmentMode>;
  assignmentTemplateId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  dueAtOffset?: InputMaybe<Scalars['Int']['input']>;
  excluded?: InputMaybe<Scalars['Boolean']['input']>;
  gradingMode?: InputMaybe<AssignmentGradingMode>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openAt?: InputMaybe<Scalars['DateTime']['input']>;
  openAtOffset?: InputMaybe<Scalars['Int']['input']>;
  revealAnswers?: InputMaybe<RevealAnswers>;
  revealAttempts?: InputMaybe<Scalars['Int']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
  submissionType?: InputMaybe<SubmissionType>;
  timeLimit?: InputMaybe<Scalars['Float']['input']>;
  totalPoints?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type AssignmentRubricCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  presets?: InputMaybe<Array<AssignmentRubricPresetInput>>;
  totalPoints: Scalars['Float']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AssignmentRubricInput = {
  categories: Array<AssignmentRubricCategoryInput>;
};

export type AssignmentRubricPresetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  points: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export enum AssignmentStudentAssignmentMode {
  AllStudents = 'ALL_STUDENTS',
  SpecificStudents = 'SPECIFIC_STUDENTS'
}

export type AssignmentTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  configurationChanges?: InputMaybe<Scalars['JSON']['input']>;
  defaults?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AssociatedClassAssignmentInput = {
  /** The class ID. */
  id: Scalars['ID']['input'];
  overrides?: InputMaybe<AssignmentInput>;
  segmentId: Scalars['String']['input'];
  templateId: Scalars['ID']['input'];
};

export type AttachClassSyncInput = {
  /** The ID of the class to sync. */
  id: Scalars['ID']['input'];
  /** The name of the class on the provider. */
  label: Scalars['String']['input'];
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The ID of the class on the provider to sync with. */
  providerId: Scalars['String']['input'];
};

export type AttachClassSyncStudentAccountInput = {
  /** The current class ID to attach the student to. */
  id: Scalars['ID']['input'];
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
  /** An optional user ID to pass to force the association with a specific user. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  userInfo: BasicUserInput;
};

/**
 * AttachFileInput is used whenever attaching a file "out of context," or without a parent GraphQL
 * node. You would use this on a create form. See the attachFile() mutation for more info.
 */
export type AttachFileInput = {
  /** The name of the field on the parent you're uploading the attachment to. */
  parentField: Scalars['String']['input'];
  /** The GraphQL type of the parent you're uploading the attachment for. */
  parentType: Scalars['String']['input'];
};

/** The input required when uploading a new attachment. */
export type AttachmentUploadInput = {
  /** The original name of the file. */
  name: Scalars['String']['input'];
  /** The signed upload input required for all standard uploads. */
  signedUploadInput: SignedUploadInput;
  /** The size of the file, in bytes. */
  sizeInBytes: Scalars['Float']['input'];
};

export type AutoGradeAssignmentSubmissionInput = {
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export enum AutoGradingStatus {
  /** Automatic grading failed for this question. */
  Failed = 'FAILED',
  /** The question has been graded already. */
  Graded = 'GRADED',
  /** The question is currently being graded. */
  Grading = 'GRADING'
}

export type AvailableGroupImportGroupsInput = {
  importId: Scalars['String']['input'];
};

export type AwardBadgeInput = {
  /** The ID of the badge to award. */
  id: Scalars['ID']['input'];
  /** The IDs of the users to award the badge to. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export enum BadgeEventType {
  Badge = 'badge',
  Interaction = 'interaction',
  Segment = 'segment'
}

export type BadgeInput = {
  awardedText?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<BadgeEventType>;
  eventConfig?: InputMaybe<Scalars['JSON']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BasicUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type BuiltSegmentInput = {
  /**
   * If this is false, the studentContent and teacherContent are not included for this
   * segment. See Segment.hydrated for how to fetch the hydrated segment if this is
   * false.
   */
  hydrated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkUpdateAssignmentsInClassInput = {
  /** The ID of the class. */
  id: Scalars['ID']['input'];
};

export type CancelLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
};

/** Reasons a trial request might not be able to be approved. */
export enum CantApproveTrialRequestReason {
  /** This user already belongs to a non-trial license. */
  BelongsToNonTrialLicense = 'BELONGS_TO_NON_TRIAL_LICENSE',
  /** A deleted user already exists with this email address. */
  DeletedUserExists = 'DELETED_USER_EXISTS'
}

export type CanvasAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type CanvasHealthInput = {
  consumerKey?: InputMaybe<Scalars['String']['input']>;
  redirectUri: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CanvasIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export enum CheckoutSessionType {
  /** The subscription automatically renews and is paid on a monthly basis. */
  Monthly = 'MONTHLY',
  /** The subscription is paid completely up-front. */
  UpFront = 'UP_FRONT'
}

/** This is deprecated; use the class configuration instead. */
export enum ClassGradingMode {
  PurePoints = 'PURE_POINTS',
  WeightedAverage = 'WEIGHTED_AVERAGE',
  WeightedPoints = 'WEIGHTED_POINTS'
}

export type ClassGroupPresetInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  submissionType?: InputMaybe<GroupSubmissionType>;
};

export type ClassImplementationGuideReportInput = {
  id: Scalars['ID']['input'];
};

export type ClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segmentMetadata?: InputMaybe<Scalars['JSON']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ClassReportInput = {
  classIds: Array<Scalars['ID']['input']>;
  igNames?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ClassRole {
  Member = 'MEMBER',
  Owner = 'OWNER'
}

export type ClassStudentInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type ClassSyncHistoryFilters = {
  /**
   * If you want to filter the results to match only specific class
   * sync IDs.
   */
  classSyncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<Array<ClassSyncHistoryStatus>>;
  /**
   * If you want to filter the results to match only specific assignment
   * or material sync IDs.
   */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Array<ClassSyncHistoryType>>;
};

export enum ClassSyncHistoryStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export enum ClassSyncHistoryType {
  /** The sync created or updated an assignment without touching the grades. */
  Basic = 'BASIC',
  /** The sync was in response to a grade change and only updated the grade. */
  OnlyGrades = 'ONLY_GRADES',
  /** This was an assignment submit event. */
  Submit = 'SUBMIT',
  /** The sync either did not exist or touched both the basic metadata and grades. */
  WithGrades = 'WITH_GRADES'
}

export type ClassSyncProviderConfigurationInput = {
  enabledFeatures?: InputMaybe<Array<ClassSyncProviderFeature>>;
  providerIdentifier: Scalars['String']['input'];
};

export enum ClassSyncProviderFeature {
  GradeMaterialSync = 'GRADE_MATERIAL_SYNC',
  Rostering = 'ROSTERING'
}

export type ClassSyncRosterActionInput = {
  mode: ClassSyncRosterActionMode;
  providerStudentId?: InputMaybe<Scalars['String']['input']>;
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

/** Types of actions to take when rostering students. */
export enum ClassSyncRosterActionMode {
  /** Import the student from the provider into Feature. */
  Import = 'IMPORT',
  /** Match a provider student with an existing Feature student. */
  Match = 'MATCH',
  /** Remove an existing Feature student from the class. */
  Remove = 'REMOVE'
}

export type ClassTemplatesFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sharingTypes?: InputMaybe<Array<SharingType>>;
};

export type ClassesReportDimensionInput = {
  classIds: Array<Scalars['ID']['input']>;
};

export type CommentAddedInput = {
  /** The ID of the entity to listen to for new comments. */
  id: Scalars['ID']['input'];
};

export enum CommentApproval {
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Unmoderated = 'UNMODERATED'
}

export type CommentInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentOnNodeInput = {
  /**
   * If you are using the more complicated block editor, this is the serialized
   * editor state.
   */
  blockComment?: InputMaybe<Scalars['JSON']['input']>;
  /**
   * The actual text of the comment. If you are using blockComment, pass an
   * empty string for this.
   */
  comment: Scalars['String']['input'];
  /** The ID of the node to comment on. */
  id: Scalars['ID']['input'];
  /** Any additional metadata to include with the comment. */
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CommentsFilterInput = {
  /** If this is true, only shows comments for the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is passed, filters comments for those made by the specified user ID. */
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ConfirmationInput = {
  clearGradesWhenChangingAssignmentRubric?: InputMaybe<Scalars['Boolean']['input']>;
  overwriteClassTemplate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentVisitedReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CopyAssignmentCategoriesInput = {
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /** The ID of the class to copy to. */
  targetClassId: Scalars['ID']['input'];
};

export type CopySegmentAsOwnInput = {
  /** The ID of the class the teacher is working in. */
  id: Scalars['ID']['input'];
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
};

export type CopySegmentSettingsInput = {
  /** The IDs of the classes to copy this information to. */
  destinationIds: Array<Scalars['ID']['input']>;
  /** The ID of the class to copy from. */
  id: Scalars['ID']['input'];
  /**
   * An optional array of segment IDs to copy. If none are provided, all data
   * is copied.
   */
  segmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CopyTagsToChildrenInput = {
  /** The ID of the course version you are currently viewing. */
  courseVersionId: Scalars['ID']['input'];
  /** The ID of the segment to copy the tags to its children. */
  id: Scalars['ID']['input'];
};

export type CourseInput = {
  classConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  isByoc?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type CourseVersionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CourseViewWeightInput = {
  /** The global ID of the segment. */
  segmentId: Scalars['ID']['input'];
  /** The new weight for that segment. */
  weight: Scalars['Float']['input'];
};

export type CreateAnnouncementInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID for the class to create the announcement for. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateAnsweredQuestionContributorInput = {
  bio?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  user: RegisterInput;
};

export type CreateAssignmentCategoryInput = {
  category: AssignmentCategoryInput;
  /** The class ID */
  id: Scalars['ID']['input'];
};

export type CreateAssignmentInput = {
  assignment: AssignmentInput;
};

export type CreateCheckoutSessionInput = {
  /** The URL to redirect to on cancel. */
  cancelUrl: Scalars['String']['input'];
  /** The class to create a checkout session for. */
  id: Scalars['ID']['input'];
  /** The URL to redirect to on success. */
  successUrl: Scalars['String']['input'];
};

export type CreateClassFromTemplateInput = {
  /** The template to create a class from. */
  id: Scalars['ID']['input'];
  /** The name of the class. */
  name: Scalars['String']['input'];
};

export type CreateClassGroupPresetInput = {
  /** The ID of the class to associate the preset with. */
  id: Scalars['ID']['input'];
  preset: ClassGroupPresetInput;
};

export type CreateClassInput = {
  configuration?: InputMaybe<Scalars['JSON']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  siteId?: InputMaybe<Scalars['ID']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateClassWithPlacedContentInput = {
  class: ClassInput;
  segmentId: Scalars['ID']['input'];
};

export type CreateCourseVersionInput = {
  /** The ID of the CourseVersion to base this new version off of. */
  baseCourseVersionId: Scalars['ID']['input'];
  /** The name of the new version to create. */
  name: Scalars['String']['input'];
};

export type CreateDirectMessageThreadInput = {
  /** The block-based message to send. */
  blockMessage: Scalars['JSON']['input'];
  /** An array of user IDs to send the message to. */
  recipientIds: Array<Scalars['ID']['input']>;
  /** An optional label to provide to display as the recipients instead. */
  recipientsLabel?: InputMaybe<Scalars['String']['input']>;
  /** The subject of the thread. */
  subject: Scalars['String']['input'];
};

export type CreateDiscussionThreadInput = {
  /** The ID of the discussion to create the thread in. */
  id: Scalars['ID']['input'];
  thread: DiscussionThreadInput;
};

export type CreateSavedReportInput = {
  name: Scalars['String']['input'];
  report: Scalars['JSON']['input'];
};

export type CreateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type CreateStudentInput = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  /** This is only required if the provider requires it. */
  signedUploadInput?: InputMaybe<SignedUploadInput>;
  sizeInBytes: Scalars['Int']['input'];
};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteAssignmentCategoryInput = {
  /** The ID of the assignment category to delete. */
  id: Scalars['ID']['input'];
  /**
   * The ID of a category inside the same class to move all assignments to that
   * belong to the category being deleted.
   */
  replacementCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteAttachmentInput = {
  /** The ID of the attachment to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteSegmentInput = {
  /** The ID of the segment to delete. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
};

export type DetachSegmentInput = {
  /** The ID of the segment to detach. */
  id: Scalars['ID']['input'];
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId: Scalars['ID']['input'];
};

export type DetailedLogsForUserInput = {
  /** Filter by the name of the function handler, rather than displaying all handlers. */
  handler?: InputMaybe<Scalars['String']['input']>;
  /** The ID of the user to get logs for. */
  id: Scalars['ID']['input'];
  /** The JWT of the administration user. */
  jwt: Scalars['String']['input'];
  /** Any additional tags to filter by. */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DirectMessageThreadsFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Other features are supposed to add to this later... */
  noop?: InputMaybe<Scalars['String']['input']>;
};

export type DiscussionThreadInput = {
  content: Scalars['JSON']['input'];
};

export type DiscussionThreadsFilterInput = {
  /**
   * If selected, only returns discussion threads either created by the passed user, or with
   * comments from the passed user.
   */
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If this is true, only shows discussion threads created by the current user. */
  mine?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DistrictConfigurationInput = {
  /** Provide default values for all sites inside this district. */
  defaultSiteConfiguration?: InputMaybe<SiteConfigurationInput>;
};

export type DistrictInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<DistrictConfigurationInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type DueDateFilterInput = {
  /** Defaults to the current date if not specified. */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  type: DueDateFilterType;
};

export enum DueDateFilterType {
  After = 'AFTER',
  Before = 'BEFORE'
}

export enum DuplicateUserPendingStudentClassResolution {
  /**
   * These are not the same students, so the student should be accepted into
   * the class but should not be merged into the matchedStudentId.
   */
  Accept = 'ACCEPT',
  /**
   * Ignore this request; the student should not be added to the class and the
   * students should not be merged.
   */
  Decline = 'DECLINE',
  /**
   * These are actually the same students, and the current student should be
   * merged into the matchedStudentId, and then the current student should be
   * deleted.
   */
  Merge = 'MERGE'
}

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FinishUploadingVideoOnDemandInput = {
  /** The ID of the video on demand you just finished uploading. */
  vodId: Scalars['ID']['input'];
};

export type ForceResyncAssignmentSyncInput = {
  /** The ID of the assignment sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForceResyncMaterialSyncInput = {
  /** The ID of the material sync to forcefully sync. */
  id: Scalars['ID']['input'];
};

export type ForfeitAssignmentInput = {
  classId: Scalars['ID']['input'];
  forfeit: Scalars['Boolean']['input'];
  /** The ID of the assignment to forfeit. */
  id: Scalars['ID']['input'];
  /** If modifying as a teacher, the ID of the student to modify. */
  studentId?: InputMaybe<Scalars['ID']['input']>;
};

export type FreeUsernameInput = {
  /** The username or email address you would like to free. */
  username: Scalars['String']['input'];
};

export type GenerateCanvasCredentialsTokenInput = {
  districtId?: InputMaybe<Scalars['ID']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export enum GenerateGroupMode {
  MaximumCollaboration = 'MAXIMUM_COLLABORATION',
  Random = 'RANDOM'
}

export enum GenerateLeaderMode {
  LeadershipBoost = 'LEADERSHIP_BOOST',
  Random = 'RANDOM'
}

export type GenerateMicrosoftTenantTokenInput = {
  siteId: Scalars['ID']['input'];
};

export type GeneratePdfInput = {
  requestData: Scalars['JSON']['input'];
  type: Scalars['String']['input'];
};

export type GenerateStudentsInput = {
  count: Scalars['Int']['input'];
  /** The ID of the class to generate students for. */
  id: Scalars['ID']['input'];
};

export type GenerateVodCaptionsInput = {
  /** The ID of the video to generate captions for. */
  id: Scalars['ID']['input'];
};

export type GeneratedGroupsConfigurationInput = {
  /** The logic to use when grouping students. */
  groupMode: GenerateGroupMode;
  /** The logic to use when choosing leaders. */
  leaderMode: GenerateLeaderMode;
  /** The number of groups to generate. */
  numGroups: Scalars['Int']['input'];
  /** The students to include in the groups. */
  students: Array<ClassStudentInput>;
};

export enum GeneratedPdfStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type GradeAssignmentSubmissionInput = {
  comments: Scalars['JSON']['input'];
  grades: Scalars['JSON']['input'];
  /** The ID of the submission to grade. */
  id: Scalars['ID']['input'];
};

export type GradeReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum GroupSubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL'
}

export type ImportCourseViewWeightsInput = {
  /** The ID of the course view to update weights for. */
  courseViewId: Scalars['ID']['input'];
  /**
   * If this is true, the update will be done in the foreground
   * instead of via a background job.
   */
  sync?: InputMaybe<Scalars['Boolean']['input']>;
  /** The new weights to import. */
  weights: Array<CourseViewWeightInput>;
};

export type ImportGradeCategoriesInput = {
  /** The class ID */
  id: Scalars['ID']['input'];
  /** The ID of the source to use. */
  sourceId: Scalars['String']['input'];
};

export type ImportSegmentsInput = {
  /** The contents of the CSV to use for imports. */
  csvContent: Scalars['String']['input'];
  /**
   * If this is true, new tags will not be created. So if you specify
   * a tag that has not yet been registered in the system, it won't
   * be added to that content.
   */
  dontCreateTags?: InputMaybe<Scalars['Boolean']['input']>;
  /** How should we process tags when importing? */
  tagsMode: ImportSegmentsTagsMode;
  /**
   * If this is true, the segment ID column is required, and the label
   * of the segment will be updated when imported.
   */
  updateLabel?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * If this is true, the segment ID column is required, and the name
   * of the segment will be updated when imported.
   */
  updateName?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ImportSegmentsTagsMode {
  /** Tags will be appended to the existing tags. */
  Append = 'APPEND',
  /** No tags will be imported. */
  Ignore = 'IGNORE',
  /**
   * The existing tags (in the specified types) will be replaced completely.
   * If you pass a "COURSE" tag of "Course A" and the content already has a
   * "COURSE" tag of "Course B", the "Course B" tag will be removed.
   * If the content already had an "IMPLEMENTATION_GUIDE" tag but you didn't
   * specify a new one in the sheet, the existing one will be kept.
   */
  Replace = 'REPLACE'
}

export type ImportStudentsInput = {
  /** A base64-encoded representation of the spreadsheet. */
  base64File: Scalars['String']['input'];
  /** The ID of the class to import the students to. */
  id: Scalars['ID']['input'];
};

export type IndividualSubscriptionInput = {
  /** The receipt from Apple or Google. */
  receipt: Scalars['String']['input'];
};

export type InteractionInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  periodEnd: Scalars['DateTime']['input'];
  periodStart: Scalars['DateTime']['input'];
  reference?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export enum InviteResult {
  AddedToClass = 'ADDED_TO_CLASS',
  InvitedToPlatform = 'INVITED_TO_PLATFORM'
}

export type InviteStudentIdInput = {
  /** The ID of the class to import the student to. */
  id: Scalars['ID']['input'];
  /** The student ID to import. */
  studentId: Scalars['String']['input'];
  /** The teacher ID to import. */
  teacherId: Scalars['String']['input'];
};

export type InviteStudentInput = {
  /** The email address of the student to invite. */
  email: Scalars['String']['input'];
  /** The ID of the class to invite the student to. */
  id: Scalars['ID']['input'];
};

export type InviteTeacherToClassInput = {
  /** The ID of the class to invite the teacher to. */
  id: Scalars['ID']['input'];
  /** Any default permissions to apply to the teacher. */
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The ID of the teacher to invite to the class. */
  teacherId: Scalars['ID']['input'];
};

export type JoinClassInput = {
  /** The Class ID for the class to join (not the ID). */
  classId: Scalars['String']['input'];
};

export type LessonAssociationInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LessonInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  mediaId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type LessonsAssignedStudentPairInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type LicenseInput = {
  courses?: InputMaybe<Scalars['JSON']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  monthlyPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontMonths?: InputMaybe<Scalars['Int']['input']>;
  payUpFrontPriceInCents?: InputMaybe<Scalars['Int']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<LicenseType>;
};

export type LicenseSubscriptionInput = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  isValid?: InputMaybe<Scalars['Boolean']['input']>;
  licenseId?: InputMaybe<Scalars['ID']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  nextValidityCheck?: InputMaybe<Scalars['DateTime']['input']>;
  size?: InputMaybe<LicenseSubscriptionSize>;
  subscriptionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<LicenseSubscriptionType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum LicenseSubscriptionSize {
  Large = 'LARGE',
  Small = 'SMALL'
}

export enum LicenseSubscriptionType {
  Individual = 'INDIVIDUAL',
  StudentPay = 'STUDENT_PAY'
}

export enum LicenseType {
  Site = 'SITE',
  StudentPay = 'STUDENT_PAY',
  Trial = 'TRIAL'
}

export type LikeNodeInput = {
  /** The ID of the node to like. */
  id: Scalars['ID']['input'];
};

export type ListSyncProviderClassesInput = {
  /** If you would like to have a class automatically suggested, pass in the class ID. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  provider: Scalars['String']['input'];
};

export type LoginWithCanvasInput = {
  authCode: CanvasAuthCodeInput;
  /** An optional Canvas integration to use, for anonymous access. */
  integrationId?: InputMaybe<Scalars['ID']['input']>;
};

export type LoginWithCleverInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithGoogleInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
};

export type LoginWithMsalInput = {
  authCode?: InputMaybe<MsalAuthCodeInput>;
  tokens?: InputMaybe<MsalTokensInput>;
};

export type LoginWithSchoologyInput = {
  token: SchoologyTokenInput;
};

export type MsalAuthCodeInput = {
  code: Scalars['String']['input'];
  redirectUri: Scalars['String']['input'];
  scopes: Array<Scalars['String']['input']>;
};

export type MsalIntegrationSearchInput = {
  name: Scalars['String']['input'];
};

export type MsalTokensInput = {
  accessToken: Scalars['String']['input'];
  expiresAt: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type MediaBookmarkInput = {
  endSeconds: Scalars['Float']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  startSeconds: Scalars['Float']['input'];
};

export type MediaInput = {
  metadata?: InputMaybe<MediaMetadataInput>;
  status?: InputMaybe<MediaStatus>;
};

export type MediaMetadataInput = {
  bookmarks?: InputMaybe<Array<MediaBookmarkInput>>;
};

export enum MediaStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED'
}

export enum MediaType {
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type MediaUploadUrlInput = {
  /** An optional class to associate the media with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  extension: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  region?: InputMaybe<AccountRegion>;
  requestMode?: InputMaybe<UploadRequestMode>;
  source?: InputMaybe<Scalars['String']['input']>;
  type: MediaType;
};

export type MoveStudentClassInput = {
  /**
   * Whether or not to force the move. This typically means some warnings were given, and
   * you acknowledge them and would like to continue anyway.
   */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the source class. */
  fromClassId: Scalars['ID']['input'];
  /** The ID of the student. */
  id: Scalars['ID']['input'];
  /** The ID of the destination class. */
  toClassId: Scalars['ID']['input'];
};

export type MoveTeacherInput = {
  id: Scalars['ID']['input'];
  toSite: Scalars['ID']['input'];
};

export type NumAttemptsMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum PendingStudentClassReason {
  DuplicateUser = 'DUPLICATE_USER',
  /** Don't use this. */
  Placeholder = 'PLACEHOLDER'
}

export enum PendingStudentClassState {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING'
}

export type PendingStudentClassesFilters = {
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  reasons?: InputMaybe<Array<PendingStudentClassReason>>;
  states?: InputMaybe<Array<PendingStudentClassState>>;
  studentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum Permission {
  AcceptInvite = 'ACCEPT_INVITE',
  ApproveTrialRequest = 'APPROVE_TRIAL_REQUEST',
  ArchiveClass = 'ARCHIVE_CLASS',
  ArchiveOwnClass = 'ARCHIVE_OWN_CLASS',
  AssignLesson = 'ASSIGN_LESSON',
  AssignOwnLesson = 'ASSIGN_OWN_LESSON',
  AttachClassSync = 'ATTACH_CLASS_SYNC',
  AttachClassSyncStudentAccount = 'ATTACH_CLASS_SYNC_STUDENT_ACCOUNT',
  AttachFiles = 'ATTACH_FILES',
  AttachGenericFiles = 'ATTACH_GENERIC_FILES',
  AttachOwnClassSync = 'ATTACH_OWN_CLASS_SYNC',
  AttachOwnClassSyncStudentAccount = 'ATTACH_OWN_CLASS_SYNC_STUDENT_ACCOUNT',
  BulkUpdateAssignmentsInClass = 'BULK_UPDATE_ASSIGNMENTS_IN_CLASS',
  BulkUpdateAssignmentsInOwnClass = 'BULK_UPDATE_ASSIGNMENTS_IN_OWN_CLASS',
  CancelLicenseSubscription = 'CANCEL_LICENSE_SUBSCRIPTION',
  CancelOwnLicenseSubscription = 'CANCEL_OWN_LICENSE_SUBSCRIPTION',
  CommentOnNode = 'COMMENT_ON_NODE',
  CommentOnOwnDiscussionThread = 'COMMENT_ON_OWN_DISCUSSION_THREAD',
  CommentOnOwnNode = 'COMMENT_ON_OWN_NODE',
  CopyCourseContentFrom = 'COPY_COURSE_CONTENT_FROM',
  CopyCourseContentTo = 'COPY_COURSE_CONTENT_TO',
  CopyOwnCourseContentFrom = 'COPY_OWN_COURSE_CONTENT_FROM',
  CopyOwnCourseContentTo = 'COPY_OWN_COURSE_CONTENT_TO',
  CopyOwnSegmentAsOwn = 'COPY_OWN_SEGMENT_AS_OWN',
  CopyOwnSegmentSettings = 'COPY_OWN_SEGMENT_SETTINGS',
  CopySegmentAsOwn = 'COPY_SEGMENT_AS_OWN',
  CopySegmentSettings = 'COPY_SEGMENT_SETTINGS',
  CreateAllRubricTemplate = 'CREATE_ALL_RUBRIC_TEMPLATE',
  CreateAnnouncement = 'CREATE_ANNOUNCEMENT',
  CreateAssignment = 'CREATE_ASSIGNMENT',
  CreateAssignmentTemplate = 'CREATE_ASSIGNMENT_TEMPLATE',
  CreateAssociatedClassAssignment = 'CREATE_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateCheckoutSession = 'CREATE_CHECKOUT_SESSION',
  CreateClass = 'CREATE_CLASS',
  CreateClassFromOwnTemplate = 'CREATE_CLASS_FROM_OWN_TEMPLATE',
  CreateClassFromSharedTemplate = 'CREATE_CLASS_FROM_SHARED_TEMPLATE',
  CreateClassFromTemplate = 'CREATE_CLASS_FROM_TEMPLATE',
  CreateCourse = 'CREATE_COURSE',
  CreateCourseVersion = 'CREATE_COURSE_VERSION',
  CreateDirectMessagesThread = 'CREATE_DIRECT_MESSAGES_THREAD',
  CreateDiscussionThread = 'CREATE_DISCUSSION_THREAD',
  CreateDistrict = 'CREATE_DISTRICT',
  CreateIndividualSubscription = 'CREATE_INDIVIDUAL_SUBSCRIPTION',
  CreateLesson = 'CREATE_LESSON',
  CreateLicense = 'CREATE_LICENSE',
  CreateLicenseSubscription = 'CREATE_LICENSE_SUBSCRIPTION',
  CreateOwnAnnouncement = 'CREATE_OWN_ANNOUNCEMENT',
  CreateOwnAssignment = 'CREATE_OWN_ASSIGNMENT',
  CreateOwnAssociatedClassAssignment = 'CREATE_OWN_ASSOCIATED_CLASS_ASSIGNMENT',
  CreateOwnCheckoutSession = 'CREATE_OWN_CHECKOUT_SESSION',
  CreateOwnDiscussionThread = 'CREATE_OWN_DISCUSSION_THREAD',
  CreateOwnStudent = 'CREATE_OWN_STUDENT',
  CreatePractice = 'CREATE_PRACTICE',
  CreateSavedReport = 'CREATE_SAVED_REPORT',
  CreateSite = 'CREATE_SITE',
  CreateStudent = 'CREATE_STUDENT',
  CreateTeacher = 'CREATE_TEACHER',
  CreateUser = 'CREATE_USER',
  CreateVideoOnDemand = 'CREATE_VIDEO_ON_DEMAND',
  DeleteAnsweredQuestion = 'DELETE_ANSWERED_QUESTION',
  DeleteAnsweredQuestionContributor = 'DELETE_ANSWERED_QUESTION_CONTRIBUTOR',
  DeleteClass = 'DELETE_CLASS',
  DeleteClassGroupPreset = 'DELETE_CLASS_GROUP_PRESET',
  DeleteClassTemplate = 'DELETE_CLASS_TEMPLATE',
  DeleteComment = 'DELETE_COMMENT',
  DeleteCourse = 'DELETE_COURSE',
  DeleteCourseVersion = 'DELETE_COURSE_VERSION',
  DeleteDiscussion = 'DELETE_DISCUSSION',
  DeleteDiscussionThread = 'DELETE_DISCUSSION_THREAD',
  DeleteDistrict = 'DELETE_DISTRICT',
  DeleteLesson = 'DELETE_LESSON',
  DeleteLicense = 'DELETE_LICENSE',
  DeleteLicenseSubscription = 'DELETE_LICENSE_SUBSCRIPTION',
  DeleteMedia = 'DELETE_MEDIA',
  DeleteOwnAnnouncement = 'DELETE_OWN_ANNOUNCEMENT',
  DeleteOwnClass = 'DELETE_OWN_CLASS',
  DeleteOwnClassGroupPreset = 'DELETE_OWN_CLASS_GROUP_PRESET',
  DeleteOwnClassSync = 'DELETE_OWN_CLASS_SYNC',
  DeleteOwnClassTemplate = 'DELETE_OWN_CLASS_TEMPLATE',
  DeleteOwnComment = 'DELETE_OWN_COMMENT',
  DeleteOwnDirectMessageThread = 'DELETE_OWN_DIRECT_MESSAGE_THREAD',
  DeleteOwnDiscussion = 'DELETE_OWN_DISCUSSION',
  DeleteOwnDiscussionThread = 'DELETE_OWN_DISCUSSION_THREAD',
  DeleteOwnDistrict = 'DELETE_OWN_DISTRICT',
  DeleteOwnLesson = 'DELETE_OWN_LESSON',
  DeleteOwnLicense = 'DELETE_OWN_LICENSE',
  DeleteOwnLicenseSubscription = 'DELETE_OWN_LICENSE_SUBSCRIPTION',
  DeleteOwnMedia = 'DELETE_OWN_MEDIA',
  DeleteOwnPractice = 'DELETE_OWN_PRACTICE',
  DeleteOwnRubricTemplate = 'DELETE_OWN_RUBRIC_TEMPLATE',
  DeleteOwnSavedReport = 'DELETE_OWN_SAVED_REPORT',
  DeleteOwnSite = 'DELETE_OWN_SITE',
  DeleteOwnSitePermanently = 'DELETE_OWN_SITE_PERMANENTLY',
  DeleteOwnStudent = 'DELETE_OWN_STUDENT',
  DeleteOwnTag = 'DELETE_OWN_TAG',
  DeleteOwnTeacher = 'DELETE_OWN_TEACHER',
  DeleteOwnVideoOnDemand = 'DELETE_OWN_VIDEO_ON_DEMAND',
  DeletePractice = 'DELETE_PRACTICE',
  DeleteSavedReport = 'DELETE_SAVED_REPORT',
  DeleteSegment = 'DELETE_SEGMENT',
  DeleteSite = 'DELETE_SITE',
  DeleteSitePermanently = 'DELETE_SITE_PERMANENTLY',
  DeleteStandardSet = 'DELETE_STANDARD_SET',
  DeleteStudent = 'DELETE_STUDENT',
  DeleteTag = 'DELETE_TAG',
  DeleteTeacher = 'DELETE_TEACHER',
  DeleteTrialRequest = 'DELETE_TRIAL_REQUEST',
  DeleteTrialRequestCampaign = 'DELETE_TRIAL_REQUEST_CAMPAIGN',
  DeleteVideoOnDemand = 'DELETE_VIDEO_ON_DEMAND',
  DeleteWidgetTemplate = 'DELETE_WIDGET_TEMPLATE',
  DownloadLesson = 'DOWNLOAD_LESSON',
  DownloadOwnLesson = 'DOWNLOAD_OWN_LESSON',
  FetchGenericFiles = 'FETCH_GENERIC_FILES',
  ForceResyncAssignmentSync = 'FORCE_RESYNC_ASSIGNMENT_SYNC',
  ForceResyncMaterialSync = 'FORCE_RESYNC_MATERIAL_SYNC',
  ForceResyncOwnAssignmentSync = 'FORCE_RESYNC_OWN_ASSIGNMENT_SYNC',
  ForceResyncOwnMaterialSync = 'FORCE_RESYNC_OWN_MATERIAL_SYNC',
  FreeUsername = 'FREE_USERNAME',
  GenerateCanvasCredentialsToken = 'GENERATE_CANVAS_CREDENTIALS_TOKEN',
  GenerateMsTenantToken = 'GENERATE_MS_TENANT_TOKEN',
  GeneratePdf = 'GENERATE_PDF',
  GetPageMetadata = 'GET_PAGE_METADATA',
  GradeAssignmentSubmission = 'GRADE_ASSIGNMENT_SUBMISSION',
  GradeOwnAssignmentSubmission = 'GRADE_OWN_ASSIGNMENT_SUBMISSION',
  Impersonate = 'IMPERSONATE',
  ImportGradeCategories = 'IMPORT_GRADE_CATEGORIES',
  ImportOwnGradeCategories = 'IMPORT_OWN_GRADE_CATEGORIES',
  InviteOwnStudent = 'INVITE_OWN_STUDENT',
  InviteStudent = 'INVITE_STUDENT',
  InviteTeacherToClass = 'INVITE_TEACHER_TO_CLASS',
  InviteTeacherToOwnClass = 'INVITE_TEACHER_TO_OWN_CLASS',
  JoinClass = 'JOIN_CLASS',
  ListAnsweredQuestions = 'LIST_ANSWERED_QUESTIONS',
  ListBadges = 'LIST_BADGES',
  ListBadgeUsers = 'LIST_BADGE_USERS',
  ListClass = 'LIST_CLASS',
  ListClassInteractions = 'LIST_CLASS_INTERACTIONS',
  ListClassLicenseSubscriptions = 'LIST_CLASS_LICENSE_SUBSCRIPTIONS',
  ListClassStudents = 'LIST_CLASS_STUDENTS',
  ListClassSyncClasses = 'LIST_CLASS_SYNC_CLASSES',
  ListClassSyncStudents = 'LIST_CLASS_SYNC_STUDENTS',
  ListClassTeachers = 'LIST_CLASS_TEACHERS',
  ListClassTemplates = 'LIST_CLASS_TEMPLATES',
  ListComments = 'LIST_COMMENTS',
  ListCourse = 'LIST_COURSE',
  ListCourseViews = 'LIST_COURSE_VIEWS',
  ListDistrict = 'LIST_DISTRICT',
  ListDistrictSites = 'LIST_DISTRICT_SITES',
  ListInteractions = 'LIST_INTERACTIONS',
  ListLesson = 'LIST_LESSON',
  ListLessonPractices = 'LIST_LESSON_PRACTICES',
  ListLicenses = 'LIST_LICENSES',
  ListLicenseSubscriptions = 'LIST_LICENSE_SUBSCRIPTIONS',
  ListOwnClassInteractions = 'LIST_OWN_CLASS_INTERACTIONS',
  ListOwnClassLicenseSubscriptions = 'LIST_OWN_CLASS_LICENSE_SUBSCRIPTIONS',
  ListOwnClassStudents = 'LIST_OWN_CLASS_STUDENTS',
  ListOwnClassSyncStudents = 'LIST_OWN_CLASS_SYNC_STUDENTS',
  ListOwnClassTeachers = 'LIST_OWN_CLASS_TEACHERS',
  ListOwnInteractions = 'LIST_OWN_INTERACTIONS',
  ListOwnLessonPractices = 'LIST_OWN_LESSON_PRACTICES',
  ListOwnSiteClasses = 'LIST_OWN_SITE_CLASSES',
  ListOwnStudentLessons = 'LIST_OWN_STUDENT_LESSONS',
  ListOwnStudentPractices = 'LIST_OWN_STUDENT_PRACTICES',
  ListOwnTeacherLessons = 'LIST_OWN_TEACHER_LESSONS',
  ListOwnTeacherPractices = 'LIST_OWN_TEACHER_PRACTICES',
  ListOwnUserBadges = 'LIST_OWN_USER_BADGES',
  ListOwnUserLicenseSubscriptions = 'LIST_OWN_USER_LICENSE_SUBSCRIPTIONS',
  ListPractice = 'LIST_PRACTICE',
  ListRawClassSyncProviders = 'LIST_RAW_CLASS_SYNC_PROVIDERS',
  ListSegments = 'LIST_SEGMENTS',
  ListSharedLessonPractices = 'LIST_SHARED_LESSON_PRACTICES',
  ListSite = 'LIST_SITE',
  ListSiteClasses = 'LIST_SITE_CLASSES',
  ListStudent = 'LIST_STUDENT',
  ListStudentLessons = 'LIST_STUDENT_LESSONS',
  ListStudentPractices = 'LIST_STUDENT_PRACTICES',
  ListTags = 'LIST_TAGS',
  ListTeacher = 'LIST_TEACHER',
  ListTeacherLessons = 'LIST_TEACHER_LESSONS',
  ListTeacherPractices = 'LIST_TEACHER_PRACTICES',
  ListTrialRequests = 'LIST_TRIAL_REQUESTS',
  ListUser = 'LIST_USER',
  ListUserBadges = 'LIST_USER_BADGES',
  ListUserLicenseSubscriptions = 'LIST_USER_LICENSE_SUBSCRIPTIONS',
  ListVideoOnDemand = 'LIST_VIDEO_ON_DEMAND',
  Login = 'LOGIN',
  LoginAsStudent = 'LOGIN_AS_STUDENT',
  ManageAnsweredQuestions = 'MANAGE_ANSWERED_QUESTIONS',
  ManageAssignments = 'MANAGE_ASSIGNMENTS',
  ManageAssignmentCategories = 'MANAGE_ASSIGNMENT_CATEGORIES',
  ManageAssignmentClassConfiguration = 'MANAGE_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageAssignmentTemplates = 'MANAGE_ASSIGNMENT_TEMPLATES',
  ManageBadges = 'MANAGE_BADGES',
  ManageBanner = 'MANAGE_BANNER',
  ManageClassGroupPresets = 'MANAGE_CLASS_GROUP_PRESETS',
  ManageClassSegmentEnabled = 'MANAGE_CLASS_SEGMENT_ENABLED',
  ManageClassSegmentLocked = 'MANAGE_CLASS_SEGMENT_LOCKED',
  ManageClassSegmentStructure = 'MANAGE_CLASS_SEGMENT_STRUCTURE',
  ManageCourseViews = 'MANAGE_COURSE_VIEWS',
  ManageDirectMessageThreads = 'MANAGE_DIRECT_MESSAGE_THREADS',
  ManageLogs = 'MANAGE_LOGS',
  ManageOwnAssignments = 'MANAGE_OWN_ASSIGNMENTS',
  ManageOwnAssignmentCategories = 'MANAGE_OWN_ASSIGNMENT_CATEGORIES',
  ManageOwnAssignmentClassConfiguration = 'MANAGE_OWN_ASSIGNMENT_CLASS_CONFIGURATION',
  ManageOwnClassGroupPresets = 'MANAGE_OWN_CLASS_GROUP_PRESETS',
  ManageOwnClassSegmentEnabled = 'MANAGE_OWN_CLASS_SEGMENT_ENABLED',
  ManageOwnClassSegmentLocked = 'MANAGE_OWN_CLASS_SEGMENT_LOCKED',
  ManageOwnClassSegmentStructure = 'MANAGE_OWN_CLASS_SEGMENT_STRUCTURE',
  ManageOwnDirectMessageThreads = 'MANAGE_OWN_DIRECT_MESSAGE_THREADS',
  ManageOwnPendingClassStudents = 'MANAGE_OWN_PENDING_CLASS_STUDENTS',
  ManageOwnSegments = 'MANAGE_OWN_SEGMENTS',
  ManagePendingClassStudents = 'MANAGE_PENDING_CLASS_STUDENTS',
  ManageSegments = 'MANAGE_SEGMENTS',
  ManageStandardSets = 'MANAGE_STANDARD_SETS',
  ManageTrialRequestCampaigns = 'MANAGE_TRIAL_REQUEST_CAMPAIGNS',
  ManageWidgetTemplates = 'MANAGE_WIDGET_TEMPLATES',
  MessageOwnUser = 'MESSAGE_OWN_USER',
  MessageUser = 'MESSAGE_USER',
  ModerateComments = 'MODERATE_COMMENTS',
  MoveTeacher = 'MOVE_TEACHER',
  PublishCourseVersion = 'PUBLISH_COURSE_VERSION',
  ReadOwnNotifications = 'READ_OWN_NOTIFICATIONS',
  RecordInteractions = 'RECORD_INTERACTIONS',
  RefreshMediaUrl = 'REFRESH_MEDIA_URL',
  RefreshOwnMediaUrl = 'REFRESH_OWN_MEDIA_URL',
  Register = 'REGISTER',
  RemoveStudentFromClass = 'REMOVE_STUDENT_FROM_CLASS',
  RemoveStudentFromOwnClass = 'REMOVE_STUDENT_FROM_OWN_CLASS',
  RemoveTeacherFromClass = 'REMOVE_TEACHER_FROM_CLASS',
  RemoveTeacherFromOwnClass = 'REMOVE_TEACHER_FROM_OWN_CLASS',
  RequestInformationForClassSyncSetup = 'REQUEST_INFORMATION_FOR_CLASS_SYNC_SETUP',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  RequestTrial = 'REQUEST_TRIAL',
  ResetAssignmentSubmission = 'RESET_ASSIGNMENT_SUBMISSION',
  ResetOwnAssignmentSubmission = 'RESET_OWN_ASSIGNMENT_SUBMISSION',
  ResetPassword = 'RESET_PASSWORD',
  RestoreAnsweredQuestion = 'RESTORE_ANSWERED_QUESTION',
  RestoreAnsweredQuestionContributor = 'RESTORE_ANSWERED_QUESTION_CONTRIBUTOR',
  RestoreClass = 'RESTORE_CLASS',
  RestoreClassGroupPreset = 'RESTORE_CLASS_GROUP_PRESET',
  RestoreClassTemplate = 'RESTORE_CLASS_TEMPLATE',
  RestoreComment = 'RESTORE_COMMENT',
  RestoreCourse = 'RESTORE_COURSE',
  RestoreCourseVersion = 'RESTORE_COURSE_VERSION',
  RestoreDiscussion = 'RESTORE_DISCUSSION',
  RestoreDiscussionThread = 'RESTORE_DISCUSSION_THREAD',
  RestoreDistrict = 'RESTORE_DISTRICT',
  RestoreLesson = 'RESTORE_LESSON',
  RestoreLicense = 'RESTORE_LICENSE',
  RestoreLicenseSubscription = 'RESTORE_LICENSE_SUBSCRIPTION',
  RestoreMedia = 'RESTORE_MEDIA',
  RestoreOwnAnnouncement = 'RESTORE_OWN_ANNOUNCEMENT',
  RestoreOwnClass = 'RESTORE_OWN_CLASS',
  RestoreOwnClassGroupPreset = 'RESTORE_OWN_CLASS_GROUP_PRESET',
  RestoreOwnClassTemplate = 'RESTORE_OWN_CLASS_TEMPLATE',
  RestoreOwnComment = 'RESTORE_OWN_COMMENT',
  RestoreOwnDiscussion = 'RESTORE_OWN_DISCUSSION',
  RestoreOwnDiscussionThread = 'RESTORE_OWN_DISCUSSION_THREAD',
  RestoreOwnDistrict = 'RESTORE_OWN_DISTRICT',
  RestoreOwnLesson = 'RESTORE_OWN_LESSON',
  RestoreOwnLicense = 'RESTORE_OWN_LICENSE',
  RestoreOwnLicenseSubscription = 'RESTORE_OWN_LICENSE_SUBSCRIPTION',
  RestoreOwnMedia = 'RESTORE_OWN_MEDIA',
  RestoreOwnPractice = 'RESTORE_OWN_PRACTICE',
  RestoreOwnRubricTemplate = 'RESTORE_OWN_RUBRIC_TEMPLATE',
  RestoreOwnSavedReport = 'RESTORE_OWN_SAVED_REPORT',
  RestoreOwnSite = 'RESTORE_OWN_SITE',
  RestoreOwnStudent = 'RESTORE_OWN_STUDENT',
  RestoreOwnTag = 'RESTORE_OWN_TAG',
  RestoreOwnTeacher = 'RESTORE_OWN_TEACHER',
  RestoreOwnVideoOnDemand = 'RESTORE_OWN_VIDEO_ON_DEMAND',
  RestorePractice = 'RESTORE_PRACTICE',
  RestoreSavedReport = 'RESTORE_SAVED_REPORT',
  RestoreSegment = 'RESTORE_SEGMENT',
  RestoreSite = 'RESTORE_SITE',
  RestoreStandardSet = 'RESTORE_STANDARD_SET',
  RestoreStudent = 'RESTORE_STUDENT',
  RestoreTag = 'RESTORE_TAG',
  RestoreTeacher = 'RESTORE_TEACHER',
  RestoreTrialRequest = 'RESTORE_TRIAL_REQUEST',
  RestoreTrialRequestCampaign = 'RESTORE_TRIAL_REQUEST_CAMPAIGN',
  RestoreVideoOnDemand = 'RESTORE_VIDEO_ON_DEMAND',
  RestoreWidgetTemplate = 'RESTORE_WIDGET_TEMPLATE',
  RetryOwnSubmission = 'RETRY_OWN_SUBMISSION',
  RetrySubmission = 'RETRY_SUBMISSION',
  RosterStudentsFromClassSync = 'ROSTER_STUDENTS_FROM_CLASS_SYNC',
  RosterStudentsFromOwnClassSync = 'ROSTER_STUDENTS_FROM_OWN_CLASS_SYNC',
  RunClassReport = 'RUN_CLASS_REPORT',
  RunOwnClassReport = 'RUN_OWN_CLASS_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunOwnStudentReport = 'RUN_OWN_STUDENT_REPORT',
  RunReport = 'RUN_REPORT',
  /** @deprecated Use the new reporting functionality instead. */
  RunStudentReport = 'RUN_STUDENT_REPORT',
  SaveAssignmentSubmission = 'SAVE_ASSIGNMENT_SUBMISSION',
  SaveClassTemplate = 'SAVE_CLASS_TEMPLATE',
  SaveOwnAssignmentSubmission = 'SAVE_OWN_ASSIGNMENT_SUBMISSION',
  SaveOwnClassTemplate = 'SAVE_OWN_CLASS_TEMPLATE',
  ScheduleOwnSegment = 'SCHEDULE_OWN_SEGMENT',
  ScheduleSegment = 'SCHEDULE_SEGMENT',
  Search = 'SEARCH',
  SearchAnsweredQuestions = 'SEARCH_ANSWERED_QUESTIONS',
  SearchAnsweredQuestionContributors = 'SEARCH_ANSWERED_QUESTION_CONTRIBUTORS',
  SearchBadges = 'SEARCH_BADGES',
  SearchCourses = 'SEARCH_COURSES',
  SearchDistricts = 'SEARCH_DISTRICTS',
  SearchSites = 'SEARCH_SITES',
  SearchUsers = 'SEARCH_USERS',
  ShareNode = 'SHARE_NODE',
  ShareOwnNode = 'SHARE_OWN_NODE',
  SubmitAnsweredQuestion = 'SUBMIT_ANSWERED_QUESTION',
  SubmitAssignment = 'SUBMIT_ASSIGNMENT',
  SubmitMedia = 'SUBMIT_MEDIA',
  SubmitOwnAssignment = 'SUBMIT_OWN_ASSIGNMENT',
  SubmitOwnMedia = 'SUBMIT_OWN_MEDIA',
  SyncAssignment = 'SYNC_ASSIGNMENT',
  SyncOwnAssignment = 'SYNC_OWN_ASSIGNMENT',
  SyncOwnSegment = 'SYNC_OWN_SEGMENT',
  SyncSegment = 'SYNC_SEGMENT',
  UpdateAnnouncement = 'UPDATE_ANNOUNCEMENT',
  UpdateAssignmentTemplate = 'UPDATE_ASSIGNMENT_TEMPLATE',
  UpdateClass = 'UPDATE_CLASS',
  UpdateClassSettings = 'UPDATE_CLASS_SETTINGS',
  UpdateComment = 'UPDATE_COMMENT',
  UpdateCourse = 'UPDATE_COURSE',
  UpdateCourseVersion = 'UPDATE_COURSE_VERSION',
  UpdateDiscussionThread = 'UPDATE_DISCUSSION_THREAD',
  UpdateDistrict = 'UPDATE_DISTRICT',
  UpdateLesson = 'UPDATE_LESSON',
  UpdateLicense = 'UPDATE_LICENSE',
  UpdateLicenseSubscription = 'UPDATE_LICENSE_SUBSCRIPTION',
  UpdateMedia = 'UPDATE_MEDIA',
  UpdateOwnAnnouncement = 'UPDATE_OWN_ANNOUNCEMENT',
  UpdateOwnAssignmentTemplate = 'UPDATE_OWN_ASSIGNMENT_TEMPLATE',
  UpdateOwnClass = 'UPDATE_OWN_CLASS',
  UpdateOwnClassSettings = 'UPDATE_OWN_CLASS_SETTINGS',
  UpdateOwnComment = 'UPDATE_OWN_COMMENT',
  UpdateOwnDiscussionThread = 'UPDATE_OWN_DISCUSSION_THREAD',
  UpdateOwnLesson = 'UPDATE_OWN_LESSON',
  UpdateOwnMedia = 'UPDATE_OWN_MEDIA',
  UpdateOwnPractice = 'UPDATE_OWN_PRACTICE',
  UpdateOwnSavedReport = 'UPDATE_OWN_SAVED_REPORT',
  UpdateOwnStudent = 'UPDATE_OWN_STUDENT',
  UpdateOwnTags = 'UPDATE_OWN_TAGS',
  UpdateOwnTeacher = 'UPDATE_OWN_TEACHER',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdatePractice = 'UPDATE_PRACTICE',
  UpdateSavedReport = 'UPDATE_SAVED_REPORT',
  UpdateSite = 'UPDATE_SITE',
  UpdateStudent = 'UPDATE_STUDENT',
  UpdateTags = 'UPDATE_TAGS',
  UpdateTeacher = 'UPDATE_TEACHER',
  UpdateTeacherClassPermissions = 'UPDATE_TEACHER_CLASS_PERMISSIONS',
  UpdateTeacherOwnClassPermissions = 'UPDATE_TEACHER_OWN_CLASS_PERMISSIONS',
  UpdateTeacherSite = 'UPDATE_TEACHER_SITE',
  UpdateUser = 'UPDATE_USER',
  UpdateVideoOnDemand = 'UPDATE_VIDEO_ON_DEMAND',
  UploadMedia = 'UPLOAD_MEDIA',
  UseInReport = 'USE_IN_REPORT',
  UseOwnInReport = 'USE_OWN_IN_REPORT',
  UsePracticeIsViewed = 'USE_PRACTICE_IS_VIEWED',
  ViewAllRubricTemplates = 'VIEW_ALL_RUBRIC_TEMPLATES',
  ViewAnnouncementStudentViews = 'VIEW_ANNOUNCEMENT_STUDENT_VIEWS',
  ViewAnsweredQuestion = 'VIEW_ANSWERED_QUESTION',
  ViewAnsweredQuestionContributor = 'VIEW_ANSWERED_QUESTION_CONTRIBUTOR',
  ViewAssignmentAnswers = 'VIEW_ASSIGNMENT_ANSWERS',
  ViewAssignmentReport = 'VIEW_ASSIGNMENT_REPORT',
  ViewAssignmentSync = 'VIEW_ASSIGNMENT_SYNC',
  ViewAssignmentTemplate = 'VIEW_ASSIGNMENT_TEMPLATE',
  ViewCanvasGroupSets = 'VIEW_CANVAS_GROUP_SETS',
  ViewClass = 'VIEW_CLASS',
  ViewClassGroupPreset = 'VIEW_CLASS_GROUP_PRESET',
  ViewClassIdsWithSegment = 'VIEW_CLASS_IDS_WITH_SEGMENT',
  ViewClassPractices = 'VIEW_CLASS_PRACTICES',
  ViewClassReport = 'VIEW_CLASS_REPORT',
  ViewClassSyncHistory = 'VIEW_CLASS_SYNC_HISTORY',
  ViewClassTemplate = 'VIEW_CLASS_TEMPLATE',
  ViewComment = 'VIEW_COMMENT',
  ViewCourse = 'VIEW_COURSE',
  ViewCourseClasses = 'VIEW_COURSE_CLASSES',
  ViewCourseVersion = 'VIEW_COURSE_VERSION',
  ViewCourseVersions = 'VIEW_COURSE_VERSIONS',
  ViewDirectMessageThreads = 'VIEW_DIRECT_MESSAGE_THREADS',
  ViewDiscussion = 'VIEW_DISCUSSION',
  ViewDiscussionThread = 'VIEW_DISCUSSION_THREAD',
  ViewDistrict = 'VIEW_DISTRICT',
  ViewDistrictContact = 'VIEW_DISTRICT_CONTACT',
  ViewFullClass = 'VIEW_FULL_CLASS',
  ViewFullCourse = 'VIEW_FULL_COURSE',
  ViewGradeCategoryImports = 'VIEW_GRADE_CATEGORY_IMPORTS',
  ViewGroupImports = 'VIEW_GROUP_IMPORTS',
  ViewLatestCourseVersion = 'VIEW_LATEST_COURSE_VERSION',
  ViewLesson = 'VIEW_LESSON',
  ViewLessonAssignments = 'VIEW_LESSON_ASSIGNMENTS',
  ViewLessonStudentViews = 'VIEW_LESSON_STUDENT_VIEWS',
  ViewLicense = 'VIEW_LICENSE',
  ViewLicenseSubscription = 'VIEW_LICENSE_SUBSCRIPTION',
  ViewMaterialSync = 'VIEW_MATERIAL_SYNC',
  ViewMedia = 'VIEW_MEDIA',
  ViewNotifications = 'VIEW_NOTIFICATIONS',
  ViewOwnAnnouncement = 'VIEW_OWN_ANNOUNCEMENT',
  ViewOwnAssignment = 'VIEW_OWN_ASSIGNMENT',
  ViewOwnAssignmentReport = 'VIEW_OWN_ASSIGNMENT_REPORT',
  ViewOwnAssignmentSubmission = 'VIEW_OWN_ASSIGNMENT_SUBMISSION',
  ViewOwnAssignmentSync = 'VIEW_OWN_ASSIGNMENT_SYNC',
  ViewOwnCanvasGroupSets = 'VIEW_OWN_CANVAS_GROUP_SETS',
  ViewOwnClass = 'VIEW_OWN_CLASS',
  ViewOwnClassGroupPreset = 'VIEW_OWN_CLASS_GROUP_PRESET',
  ViewOwnClassPractices = 'VIEW_OWN_CLASS_PRACTICES',
  ViewOwnClassReport = 'VIEW_OWN_CLASS_REPORT',
  ViewOwnClassSync = 'VIEW_OWN_CLASS_SYNC',
  ViewOwnClassSyncHistory = 'VIEW_OWN_CLASS_SYNC_HISTORY',
  ViewOwnClassTemplate = 'VIEW_OWN_CLASS_TEMPLATE',
  ViewOwnComment = 'VIEW_OWN_COMMENT',
  ViewOwnCommentModeration = 'VIEW_OWN_COMMENT_MODERATION',
  ViewOwnCourse = 'VIEW_OWN_COURSE',
  ViewOwnDirectMessageThread = 'VIEW_OWN_DIRECT_MESSAGE_THREAD',
  ViewOwnDirectMessageThreads = 'VIEW_OWN_DIRECT_MESSAGE_THREADS',
  ViewOwnDiscussion = 'VIEW_OWN_DISCUSSION',
  ViewOwnDiscussionThread = 'VIEW_OWN_DISCUSSION_THREAD',
  ViewOwnDistrict = 'VIEW_OWN_DISTRICT',
  ViewOwnGradeCategoryImports = 'VIEW_OWN_GRADE_CATEGORY_IMPORTS',
  ViewOwnGroupImports = 'VIEW_OWN_GROUP_IMPORTS',
  ViewOwnLesson = 'VIEW_OWN_LESSON',
  ViewOwnLessonAssignments = 'VIEW_OWN_LESSON_ASSIGNMENTS',
  ViewOwnLicense = 'VIEW_OWN_LICENSE',
  ViewOwnLicenseSubscription = 'VIEW_OWN_LICENSE_SUBSCRIPTION',
  ViewOwnMaterialSync = 'VIEW_OWN_MATERIAL_SYNC',
  ViewOwnMedia = 'VIEW_OWN_MEDIA',
  ViewOwnNotifications = 'VIEW_OWN_NOTIFICATIONS',
  ViewOwnPractice = 'VIEW_OWN_PRACTICE',
  ViewOwnRelatedTeachers = 'VIEW_OWN_RELATED_TEACHERS',
  ViewOwnRubricTemplate = 'VIEW_OWN_RUBRIC_TEMPLATE',
  ViewOwnSavedReport = 'VIEW_OWN_SAVED_REPORT',
  ViewOwnSegment = 'VIEW_OWN_SEGMENT',
  ViewOwnSite = 'VIEW_OWN_SITE',
  ViewOwnSiteLicense = 'VIEW_OWN_SITE_LICENSE',
  ViewOwnStudent = 'VIEW_OWN_STUDENT',
  ViewOwnStudentClasses = 'VIEW_OWN_STUDENT_CLASSES',
  ViewOwnTag = 'VIEW_OWN_TAG',
  ViewOwnTeacher = 'VIEW_OWN_TEACHER',
  ViewOwnTeacherClasses = 'VIEW_OWN_TEACHER_CLASSES',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserComments = 'VIEW_OWN_USER_COMMENTS',
  ViewOwnUserCustomerId = 'VIEW_OWN_USER_CUSTOMER_ID',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserMedia = 'VIEW_OWN_USER_MEDIA',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewOwnUserRubricTemplates = 'VIEW_OWN_USER_RUBRIC_TEMPLATES',
  ViewOwnUserTags = 'VIEW_OWN_USER_TAGS',
  ViewOwnUserTasks = 'VIEW_OWN_USER_TASKS',
  ViewOwnUseInUnownedClasses = 'VIEW_OWN_USE_IN_UNOWNED_CLASSES',
  ViewOwnVideoOnDemand = 'VIEW_OWN_VIDEO_ON_DEMAND',
  ViewPractice = 'VIEW_PRACTICE',
  ViewQuestionSegment = 'VIEW_QUESTION_SEGMENT',
  ViewRelatedTeachers = 'VIEW_RELATED_TEACHERS',
  ViewSavedReport = 'VIEW_SAVED_REPORT',
  ViewSegment = 'VIEW_SEGMENT',
  ViewSegmentStartedCount = 'VIEW_SEGMENT_STARTED_COUNT',
  ViewSharedClassTemplate = 'VIEW_SHARED_CLASS_TEMPLATE',
  ViewSharedLesson = 'VIEW_SHARED_LESSON',
  ViewSharedPractice = 'VIEW_SHARED_PRACTICE',
  ViewSite = 'VIEW_SITE',
  ViewSiteLicense = 'VIEW_SITE_LICENSE',
  ViewSiteNote = 'VIEW_SITE_NOTE',
  ViewStandardSet = 'VIEW_STANDARD_SET',
  ViewStudent = 'VIEW_STUDENT',
  ViewStudentClasses = 'VIEW_STUDENT_CLASSES',
  ViewTag = 'VIEW_TAG',
  ViewTeacher = 'VIEW_TEACHER',
  ViewTeacherClasses = 'VIEW_TEACHER_CLASSES',
  ViewTrialRequest = 'VIEW_TRIAL_REQUEST',
  ViewTrialRequestCampaign = 'VIEW_TRIAL_REQUEST_CAMPAIGN',
  ViewUnapprovedComments = 'VIEW_UNAPPROVED_COMMENTS',
  ViewUserComments = 'VIEW_USER_COMMENTS',
  ViewUserCustomerId = 'VIEW_USER_CUSTOMER_ID',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserMedia = 'VIEW_USER_MEDIA',
  ViewUserName = 'VIEW_USER_NAME',
  ViewUserRubricTemplates = 'VIEW_USER_RUBRIC_TEMPLATES',
  ViewUserTags = 'VIEW_USER_TAGS',
  ViewUserTasks = 'VIEW_USER_TASKS',
  ViewUseInUnownedClasses = 'VIEW_USE_IN_UNOWNED_CLASSES',
  ViewVideoOnDemand = 'VIEW_VIDEO_ON_DEMAND',
  ViewWidgetTemplate = 'VIEW_WIDGET_TEMPLATE'
}

export type PracticeInput = {
  /** Optionally the class you would like to associate the practice with. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  lessonId?: InputMaybe<Scalars['ID']['input']>;
  media: MediaUploadUrlInput;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PracticePatch = {
  classId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PreferencesInput = {
  /** The name of the channels to disable. */
  disabledChannels: Array<Scalars['String']['input']>;
  /** The identifier for the type of notification. */
  notificationType: Scalars['String']['input'];
};

export type PrepareCourseViewSampleSpreadsheetInput = {
  /** The ID of the course view to prepare the spreadsheet for. */
  courseViewId: Scalars['ID']['input'];
};

export type PublishCourseVersionInput = {
  id: Scalars['ID']['input'];
};

export type ReadNotificationInput = {
  id: Scalars['ID']['input'];
  read: Scalars['Boolean']['input'];
};

export type ReadNotificationPathInput = {
  read: Scalars['Boolean']['input'];
  universalPath: Scalars['String']['input'];
};

export type ReadNotificationsInput = {
  /**
   * ... or set this to true or false if you want to affect all notifications
   * for the specified user.
   */
  allRead?: InputMaybe<Scalars['Boolean']['input']>;
  /** The notifications to update. */
  notifications?: InputMaybe<Array<ReadNotificationInput>>;
  /** The universal paths, used for updating associated notifications. */
  pathNotifications?: InputMaybe<Array<ReadNotificationPathInput>>;
};

export type RecordCanvasCredentialsInput = {
  consumerKey: Scalars['String']['input'];
  consumerSecret: Scalars['String']['input'];
  token: Scalars['String']['input'];
  /**
   * Should be something like https://canvas-qa.thesis.education if the API
   * URL is https://canvas-qa.thesis.education/api/v1
   */
  url: Scalars['String']['input'];
};

export type RecordInteractionsInput = {
  interactions: Array<InteractionInput>;
};

export type RecordMicrosoftTenantInput = {
  tenantId: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RefreshMediaUploadUrlInput = {
  id: Scalars['ID']['input'];
  mimeType: Scalars['String']['input'];
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RejectCommentInput = {
  id: Scalars['ID']['input'];
  /** The reason the comment was rejected. */
  reason: Scalars['String']['input'];
};

export type RemoveDirectMessageThreadRecipientsInput = {
  /** The ID of the direct message thread. */
  id: Scalars['ID']['input'];
  /** An array of user IDs to remove from the thread. */
  userIds: Array<Scalars['ID']['input']>;
};

export type RemoveStudentFromClassInput = {
  /** The ID of the class to remove the student from. */
  id: Scalars['ID']['input'];
  /** The ID of the student to remove. */
  studentId: Scalars['ID']['input'];
};

export type RemoveTeacherFromClassInput = {
  /** The ID of the class to remove the teacher from. */
  id: Scalars['ID']['input'];
  /** The ID of the teacher to remove. */
  teacherId: Scalars['ID']['input'];
};

export type ReportDimensionInput = {
  classes?: InputMaybe<ClassesReportDimensionInput>;
  segment?: InputMaybe<SegmentReportDimensionInput>;
  students?: InputMaybe<StudentsReportDimensionInput>;
};

export type ReportFilterInput = {
  isLeader?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportMetricInput = {
  contentVisited?: InputMaybe<ContentVisitedReportMetricInput>;
  grade?: InputMaybe<GradeReportMetricInput>;
  numAttempts?: InputMaybe<NumAttemptsMetricInput>;
  timeOnTask?: InputMaybe<TimeOnTaskReportMetricInput>;
};

export type ReportStandardsSummaryInput = {
  standardSetId: Scalars['ID']['input'];
};

export type RequestInformationForClassSyncSetupInput = {
  /** The identifier for the class sync provider to request information for. */
  providerIdentifier: Scalars['String']['input'];
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetAssignmentSubmissionsInput = {
  /** The ID of the class to reset submissions for, optionally. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of the assignment to reset submissions for. */
  id: Scalars['ID']['input'];
  /**
   * If this is true, the latest submission (with its answers) is preserved.
   * The grade will still be reset, the created date will be reset to now, and the
   * submitted status will be reset.
   */
  preserveLatestSubmission?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the student to reset submissions for. */
  studentId: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentInput = {
  /** The ID of the assignment to reset. */
  id: Scalars['ID']['input'];
};

export type ResetGeneratedAssignmentSubmissionInput = {
  /** The ID of the class associated with the submission. */
  classId: Scalars['ID']['input'];
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the student associated with the submission. */
  studentId: Scalars['ID']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ResolveDuplicateUserPendingStudentClassInput = {
  /** The ID of the PendingStudentClass record. */
  id: Scalars['ID']['input'];
  /** The resolution to apply. */
  resolution: DuplicateUserPendingStudentClassResolution;
};

export type RetrySubmissionInput = {
  /** The ID of the class to retry the submission in. */
  classId: Scalars['ID']['input'];
  /** The ID of the Assignment */
  id: Scalars['ID']['input'];
};

export enum RevealAnswers {
  /** Answers are revealed to students after they are finished, or after revealAttempts. */
  AfterSubmit = 'AFTER_SUBMIT',
  /** Answers are revealed to students in real time. */
  RealTime = 'REAL_TIME'
}

export type RosterStudentsFromClassSyncInput = {
  actions: Array<ClassSyncRosterActionInput>;
  /** The ID of the class sync to roster students from. */
  id: Scalars['ID']['input'];
};

export type RubricTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  rubric?: InputMaybe<AssignmentRubricInput>;
};

export type RunGradeReportInput = {
  /** Optional Class IDs to filter on. */
  classIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Optional if we want to format based on points instead of percentages. */
  showAsPoints?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RunReportInput = {
  /** Any dimensions to filter on inside the report. */
  dimensions: Array<ReportDimensionInput>;
  /** Any filters to apply to the report. */
  filters?: InputMaybe<Array<ReportFilterInput>>;
  /** Any metrics to display inside the report. */
  metrics: Array<ReportMetricInput>;
};

/**
 * Input required for creating or updating assignment submissions.
 * These are used by both teachers and students.
 */
export type SaveAssignmentSubmissionInput = {
  /** The ID of the class to create or update the submission for. */
  classId: Scalars['ID']['input'];
  /** If this is true, a new submission will be created instead of updating the existing one. */
  createNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment to save submission data for. */
  id: Scalars['ID']['input'];
  /** Any overrides to save back to the submission. */
  overrides?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the student (defaults to the current user). */
  studentId?: InputMaybe<Scalars['ID']['input']>;
  /** The submission data to save. */
  submissionData: Scalars['JSON']['input'];
  /** Whether or not to immediately submit after saving. */
  submit?: InputMaybe<Scalars['Boolean']['input']>;
  /** If this is specified, this will attempt to update the specified submission ID instead. */
  updateId?: InputMaybe<Scalars['ID']['input']>;
};

export type SaveClassTemplateInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the class to save as a template. */
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type SchoologyTokenInput = {
  token: Scalars['String']['input'];
  tokenSecret: Scalars['String']['input'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
  students?: InputMaybe<Array<ClassStudentInput>>;
  tags?: InputMaybe<TagsFilter>;
};

export type SegmentCompletionInput = {
  /** The new complete status of these segments. */
  complete: Scalars['Boolean']['input'];
  /** The IDs of the segments to update, in GraphQL format. */
  segmentIds: Array<Scalars['ID']['input']>;
  /** The IDs of the students to update with these segment IDs, in GraphQL format. */
  studentIds: Array<Scalars['ID']['input']>;
};

export type SegmentInput = {
  /** The configuration for the segment. */
  config?: InputMaybe<Scalars['JSON']['input']>;
  /** The ID of the segment. */
  id: Scalars['ID']['input'];
  /** The index for the segment. */
  index?: InputMaybe<Scalars['String']['input']>;
  /** Whether or not the segment is hidden in search. */
  isHiddenInSearch?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the segment. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The type of segment to create or update. */
  type?: InputMaybe<SegmentType>;
  /** The course version ID to update (this segment must be used in it). */
  updateCourseVersionId?: InputMaybe<Scalars['ID']['input']>;
  /** The new tags to set for the segment. */
  updateTags?: InputMaybe<Scalars['JSON']['input']>;
};

export type SegmentReportDimensionInput = {
  /** This is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SegmentType {
  /** eslint-disable-next-line @graphql-eslint/naming-convention */
  Assignment = 'Assignment',
  Group = 'Group',
  Reference = 'Reference',
  Root = 'Root',
  Section = 'Section',
  Term = 'Term',
  Topic = 'Topic'
}

export type SegmentsFilters = {
  hiddenFromTeachers?: InputMaybe<Scalars['Boolean']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<TagFilterInput>>;
  tagsOp?: InputMaybe<FilterOperation>;
  types?: InputMaybe<Array<SegmentType>>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type ShareNodeInput = {
  /** The ID of the node to share or unshare. */
  id: Scalars['ID']['input'];
  /** For sharing, whether this list of user ids will replace the existing shares. */
  replace?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the users to share or unshare with. */
  userIds: Array<Scalars['ID']['input']>;
};

export enum SharingType {
  IveShared = 'IVE_SHARED',
  Own = 'OWN',
  SharedWithMe = 'SHARED_WITH_ME'
}

/** The input required when requesting a signed upload URL. */
export type SignedUploadInput = {
  /** The extension of the file. */
  extension: Scalars['String']['input'];
  /** Whether or not the upload should be public. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** The mime-type of the file. */
  mimeType: Scalars['String']['input'];
  /** An optional namespace to prefix the file with. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The request mode to use when uploading. */
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type SiteConfigurationInput = {
  /** Pass providers here to override their configuration. */
  classSyncProviders?: InputMaybe<Array<ClassSyncProviderConfigurationInput>>;
  defaultClassConfiguration?: InputMaybe<Scalars['JSON']['input']>;
  /** The standard set IDs enabled for this site. */
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SiteInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  configuration?: InputMaybe<SiteConfigurationInput>;
  disableCourseCodes?: InputMaybe<Scalars['Boolean']['input']>;
  districtId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<AccountRegion>;
  standardSetIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  state?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type SiteWideBannerInput = {
  message: Scalars['String']['input'];
  /** The user groups the message should show up for. */
  userGroups: Array<Scalars['String']['input']>;
};

export type StandardSetTagInput = {
  name: Scalars['String']['input'];
  parents?: InputMaybe<Array<Scalars['String']['input']>>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type StartStandardSetImportInput = {
  csvPath: Scalars['String']['input'];
  standardSetId: Scalars['ID']['input'];
};

export type StoreVodCaptionInput = {
  /** The WebVTT contents of the caption file. */
  fileContents: Scalars['String']['input'];
  /** The language code for the caption file. */
  language: Scalars['String']['input'];
};

export type StoreVodCaptionsInput = {
  /** The new captions to store (they will be added to the existing ones) */
  captions: Array<StoreVodCaptionInput>;
  /** The ID of the Video on Demand to store captions for. */
  id: Scalars['ID']['input'];
};

export type StudentFilterInput = {
  classId: Scalars['ID']['input'];
  studentId: Scalars['ID']['input'];
};

export type StudentInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type StudentReportInput = {
  /** The ID of the class the student belongs to. */
  classId: Scalars['ID']['input'];
  /** The ID of the student to run the report on. */
  id: Scalars['ID']['input'];
};

export enum StudentReportResultType {
  Assignment = 'ASSIGNMENT',
  Section = 'SECTION'
}

export type StudentsReportDimensionInput = {
  studentPairs: Array<ClassStudentInput>;
};

export enum SubmissionType {
  /** This means this assignment is a group assignment, and will respect the groups. */
  Group = 'GROUP',
  /**
   * This type will just alert students that they should work in a group, but submissions are still
   * individual.
   */
  GroupIndividual = 'GROUP_INDIVIDUAL',
  /** The default assignment type. All submissions are individual, no students work together. */
  Individual = 'INDIVIDUAL'
}

export type SubmitAnsweredQuestionInput = {
  /** The question being asked. */
  name: Scalars['String']['input'];
};

export type SubmitAssignmentInput = {
  /** Whether or not to force the submission. */
  force?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of the assignment submission to submit. */
  id: Scalars['ID']['input'];
  /** Any submission data to add. */
  submissionData?: InputMaybe<Scalars['JSON']['input']>;
};

export type SubmitMediaInput = {
  id: Scalars['ID']['input'];
};

export type SyncAssignmentInput = {
  /** The ID of the assignment to sync. */
  id: Scalars['ID']['input'];
  /** An optional list of ClassSync IDs to limit the sync to. */
  syncIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SyncSegmentInput = {
  /** The global segment ID (not the raw one). */
  id: Scalars['ID']['input'];
  /** The class syncs to use to sync this assignment. */
  syncIds: Array<Scalars['ID']['input']>;
};

export type TagConfigInput = {
  icon?: InputMaybe<Scalars['String']['input']>;
};

export type TagFilterInput = {
  /** The name of the tag. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The path of parent tags to find all children for. */
  path?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The type of tag. */
  type: Scalars['String']['input'];
};

export enum TagFilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type TagFilters = {
  /** Whether or not to filter on tags that have content available in the current user's license. */
  inLicense?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type TagsFilter = {
  operation: TagFilterOperation;
  tags: Array<TagFilterInput>;
};

export type TeacherInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  noEmail?: Scalars['Boolean']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type TimeOnTaskInput = {
  /** The non-database segment ID. If none provided, runs for the entire class. */
  rawSegmentId?: InputMaybe<Scalars['String']['input']>;
};

export type TimeOnTaskReportMetricInput = {
  /** This field is just a placeholder. */
  place?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TrialRequestApproval {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY'
}

export type TrialRequestCampaignInput = {
  /** The campaign name */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The target path for the campaign */
  targetPath?: InputMaybe<Scalars['String']['input']>;
  /** The welcome message */
  welcomeMessage?: InputMaybe<TrialRequestCampaignWelcomeMessageInput>;
};

export type TrialRequestCampaignWelcomeMessageInput = {
  background?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum TrialRequestStatus {
  Created_15Day = 'CREATED_15DAY',
  Created_30Day = 'CREATED_30DAY',
  Ignored = 'IGNORED',
  Open = 'OPEN'
}

export type TrialSignupInput = {
  /** The identifier for the trial request campaign to associate. */
  campaign?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siteName: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type UnarchiveClassInput = {
  /** The ID of the class to unarchive. */
  id: Scalars['ID']['input'];
};

export type UnlikeNodeInput = {
  /** The ID of the node to unlike. */
  id: Scalars['ID']['input'];
};

export type UnlinkAccountInput = {
  /** The identifier for the provider. */
  provider: Scalars['String']['input'];
  /** The user's specific ID related to the provider. */
  providerId: Scalars['String']['input'];
};

export type UpdateAnnouncementInput = {
  /** The ID of the announcement. */
  id: Scalars['ID']['input'];
  patch: AnnouncementInput;
};

export type UpdateAnsweredQuestionContributorInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionContributorInput;
};

export type UpdateAnsweredQuestionInput = {
  id: Scalars['ID']['input'];
  patch: AnsweredQuestionInput;
};

export type UpdateAssignmentCategoryInput = {
  /** The ID of the assignment category. */
  id: Scalars['ID']['input'];
  patch: AssignmentCategoryInput;
};

export type UpdateAssignmentClassConfigurationInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentClassConfigurationInput;
};

export type UpdateAssignmentInput = {
  confirm?: InputMaybe<ConfirmationInput>;
  /** The ID of the assignment to update. */
  id: Scalars['ID']['input'];
  patch: AssignmentInput;
};

export type UpdateAssignmentTemplateInput = {
  id: Scalars['ID']['input'];
  patch: AssignmentTemplateInput;
};

export type UpdateBadgeInput = {
  id: Scalars['ID']['input'];
  patch: BadgeInput;
};

export type UpdateClassGroupPresetInput = {
  id: Scalars['ID']['input'];
  patch: ClassGroupPresetInput;
};

export type UpdateClassInput = {
  force?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  patch: ClassInput;
  segmentMetadataChanges?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID']['input'];
  patch: CommentInput;
};

export type UpdateCourseInput = {
  id: Scalars['ID']['input'];
  patch: CourseInput;
};

export type UpdateCourseVersionInput = {
  id: Scalars['ID']['input'];
  patch: CourseVersionInput;
};

export type UpdateDiscussionThreadInput = {
  /** The ID of the discussion thread to update. */
  id: Scalars['ID']['input'];
  patch: DiscussionThreadInput;
};

export type UpdateDistrictInput = {
  id: Scalars['ID']['input'];
  patch: DistrictInput;
};

export type UpdateLessonInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<LessonInput>;
};

export type UpdateLicenseInput = {
  id: Scalars['ID']['input'];
  noEmail?: InputMaybe<Scalars['Boolean']['input']>;
  patch: LicenseInput;
};

export type UpdateLicenseSubscriptionInput = {
  id: Scalars['ID']['input'];
  patch: LicenseSubscriptionInput;
};

export type UpdateMediaInput = {
  id: Scalars['ID']['input'];
  patch: MediaInput;
};

export type UpdateNodeTagsInput = {
  id: Scalars['ID']['input'];
  tags: Array<Scalars['String']['input']>;
};

export type UpdatePracticeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patch?: InputMaybe<PracticePatch>;
};

export type UpdatePreferencesInput = {
  /** An optional JWT to authenticate with. */
  jwt?: InputMaybe<Scalars['String']['input']>;
  /** The user's current preferences. */
  preferences: Array<PreferencesInput>;
};

export type UpdateRubricTemplateInput = {
  /** The ID of the rubric template to update. */
  id: Scalars['ID']['input'];
  patch: RubricTemplateInput;
};

export type UpdateSavedReportInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  report?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSegmentCompletionInput = {
  /** The ID of the class to update the completion in. */
  id: Scalars['ID']['input'];
  /** The completion updates to perform. */
  updates: Array<SegmentCompletionInput>;
};

export type UpdateSiteInput = {
  id: Scalars['ID']['input'];
  patch: SiteInput;
};

export type UpdateSiteWideBannerInput = {
  message?: InputMaybe<SiteWideBannerInput>;
};

export type UpdateStandardSetInput = {
  attachment?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<StandardSetTagInput>>;
};

export type UpdateStudentInput = {
  id: Scalars['ID']['input'];
  patch: StudentInput;
};

export type UpdateTagInput = {
  config?: InputMaybe<TagConfigInput>;
  /** The ID of the tag to update. */
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeacherClassPermissionsInput = {
  /** The ID of the class to update. */
  id: Scalars['ID']['input'];
  /** The new permissions to assign to the teacher. */
  permissions: Array<Scalars['String']['input']>;
  /** The ID of the teacher to update (they should already belong to the class). */
  teacherId: Scalars['ID']['input'];
};

export type UpdateTeacherInput = {
  id: Scalars['ID']['input'];
  patch: TeacherInput;
};

export type UpdateTrialRequestCampaignInput = {
  id: Scalars['ID']['input'];
  patch: TrialRequestCampaignInput;
};

export type UpdateUserDetailedLoggingInput = {
  /** Whether or not to enable detailed logging for this user. */
  enabled: Scalars['Boolean']['input'];
  /** The ID of the user to update. */
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  /** If this is passed, the user's last activity is updated to the current time. */
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateVideoOnDemandInput = {
  id: Scalars['ID']['input'];
  patch: VideoOnDemandInput;
};

export type UpdateWidgetTemplateInput = {
  id: Scalars['ID']['input'];
  patch: WidgetTemplateInput;
};

export enum UploadRequestMode {
  /** The upload should be done by PUTing the file contents directly to the URL provided. */
  Basic = 'BASIC',
  /** The upload should be done by POSTing using mutlipart form-data. */
  FormData = 'FORM_DATA'
}

export type UseAssignmentSubmissionInput = {
  /** The ID of the assignment. */
  id: Scalars['ID']['input'];
  /** The ID of the submission to use. */
  submissionId: Scalars['ID']['input'];
};

export enum UserGroup {
  Admin = 'ADMIN',
  Anonymous = 'ANONYMOUS',
  ContentManager = 'CONTENT_MANAGER',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  User = 'USER'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type UserTasksFilterInput = {
  /** An optional class ID to filter the user tasks on. */
  classId?: InputMaybe<Scalars['ID']['input']>;
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VideoOnDemandCaptionStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export type VideoOnDemandInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  thumbnailData?: InputMaybe<Scalars['JSON']['input']>;
};

export enum VideoOnDemandStatus {
  Error = 'ERROR',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Uploading = 'UPLOADING'
}

export type WidgetTemplateInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  widgets?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type UserFragment = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type MinimalClassFragment = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type BasicClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean
}
  & {
  __typename: 'Class'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type AdminClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<AdminClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type AdminClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  teachers: AdminClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount = (
  {
  id: string,
  provider: string,
  providerName: string,
  label: string,
  authorized?: boolean | null
}
  & {
  __typename?: 'LinkedAccount'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean,
  linkedAccounts: Array<ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User_linkedAccounts_LinkedAccount>
}
  & {
  __typename?: 'User'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type ClassFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  pageInfo: ClassFragment_Class_students_ClassStudentsConnection_pageInfo_PageInfo,
  edges: Array<ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type ClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  totalCount: number
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type ClassFragment = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassStudentEdgeFragment = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassesEdgeFragment_StudentClassesEdge_node_Class = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type StudentClassesEdgeFragment = (
  {
  node: StudentClassesEdgeFragment_StudentClassesEdge_node_Class
}
  & {
  __typename?: 'StudentClassesEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student = (
  {
  id: string,
  user: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type StudentClassFragment_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  edges: Array<StudentClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type StudentClassFragment_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<StudentClassFragment_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type StudentClassFragment = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  students: StudentClassFragment_Class_students_ClassStudentsConnection,
  teachers: StudentClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student_user_User = (
  {
  id: string,
  name?: string | null,
  username: string,
  email?: string | null
}
  & {
  __typename?: 'User'
}
);

export type PendingStudentClassFragment_PendingStudentClass_student_Student = (
  {
  id: string,
  user: PendingStudentClassFragment_PendingStudentClass_student_Student_user_User
}
  & {
  __typename?: 'Student'
}
);

export type PendingStudentClassFragment = (
  {
  id: string,
  state: PendingStudentClassState,
  reason: PendingStudentClassReason,
  createdAt: any,
  approvedAt?: any | null,
  declinedAt?: any | null,
  student: PendingStudentClassFragment_PendingStudentClass_student_Student
}
  & {
  __typename?: 'PendingStudentClass'
}
);

export type InteractionFragment_Interaction_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type InteractionFragment_Interaction_targetUser_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type InteractionFragment = (
  {
  id: string,
  type: string,
  reference?: string | null,
  metadata?: any | null,
  periodStart: any,
  periodEnd: any,
  user: InteractionFragment_Interaction_user_User,
  targetUser?: InteractionFragment_Interaction_targetUser_User | null
}
  & {
  __typename?: 'Interaction'
}
);

export type RecordInteractionsPayloadFragment = (
  {
  numRecorded: number
}
  & {
  __typename?: 'RecordInteractionsPayload'
}
);

export type AcceptInviteMutation_acceptInvite_AcceptInvitePayload_user_User = (
  {
  id: string
}
  & {
  __typename?: 'User'
}
);

export type AcceptInviteMutation_acceptInvite_AcceptInvitePayload = (
  {
  user: AcceptInviteMutation_acceptInvite_AcceptInvitePayload_user_User
}
  & {
  __typename?: 'AcceptInvitePayload'
}
);

export type AcceptInviteMutation_Mutation = (
  {
  acceptInvite: AcceptInviteMutation_acceptInvite_AcceptInvitePayload
}
  & {
  __typename?: 'Mutation'
}
);


export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;


export type AcceptInviteMutation = AcceptInviteMutation_Mutation;

export type ArchiveClassMutation_archiveClass_ClassPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ArchiveClassMutation_archiveClass_ClassPayload = (
  {
  class: ArchiveClassMutation_archiveClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type ArchiveClassMutation_Mutation = (
  {
  archiveClass: ArchiveClassMutation_archiveClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type ArchiveClassMutationVariables = Exact<{
  input: ArchiveClassInput;
}>;


export type ArchiveClassMutation = ArchiveClassMutation_Mutation;

export type ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_edges_InteractionsEdge_node_Interaction = (
  {
  id: string,
  type: string,
  reference?: string | null,
  metadata?: any | null,
  periodStart: any,
  periodEnd: any,
  user: InteractionFragment_Interaction_user_User,
  targetUser?: InteractionFragment_Interaction_targetUser_User | null
}
  & {
  __typename?: 'Interaction'
}
);

export type ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_edges_InteractionsEdge = (
  {
  node: ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_edges_InteractionsEdge_node_Interaction
}
  & {
  __typename?: 'InteractionsEdge'
}
);

export type ClassInteractionsQuery_node_Class_interactions_InteractionsConnection = (
  {
  pageInfo: ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_pageInfo_PageInfo,
  edges: Array<ClassInteractionsQuery_node_Class_interactions_InteractionsConnection_edges_InteractionsEdge>
}
  & {
  __typename?: 'InteractionsConnection'
}
);

export type ClassInteractionsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassInteractionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassInteractionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassInteractionsQuery_node_Class = (
  {
  id: string,
  interactions: ClassInteractionsQuery_node_Class_interactions_InteractionsConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassInteractionsQuery_node = ClassInteractionsQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassInteractionsQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassInteractionsQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassInteractionsQuery_node_Class;

export type ClassInteractionsQuery_Query = (
  {
  node?: ClassInteractionsQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassInteractionsQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  types?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type ClassInteractionsQuery = ClassInteractionsQuery_Query;

export type ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_edges_PendingStudentClassEdge_node_PendingStudentClass = (
  {
  id: string,
  state: PendingStudentClassState,
  reason: PendingStudentClassReason,
  createdAt: any,
  approvedAt?: any | null,
  declinedAt?: any | null,
  student: PendingStudentClassFragment_PendingStudentClass_student_Student
}
  & {
  __typename?: 'PendingStudentClass'
}
);

export type ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_edges_PendingStudentClassEdge = (
  {
  cursor: string,
  node: ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_edges_PendingStudentClassEdge_node_PendingStudentClass
}
  & {
  __typename?: 'PendingStudentClassEdge'
}
);

export type ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection = (
  {
  totalCount: number,
  pageInfo: ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_pageInfo_PageInfo,
  edges: Array<ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection_edges_PendingStudentClassEdge>
}
  & {
  __typename?: 'PendingStudentClassesConnection'
}
);

export type ClassPendingStudentClassesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassPendingStudentClassesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassPendingStudentClassesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassPendingStudentClassesQuery_node_Class = (
  {
  id: string,
  pendingStudentClasses: ClassPendingStudentClassesQuery_node_Class_pendingStudentClasses_PendingStudentClassesConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassPendingStudentClassesQuery_node = ClassPendingStudentClassesQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassPendingStudentClassesQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassPendingStudentClassesQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassPendingStudentClassesQuery_node_Class;

export type ClassPendingStudentClassesQuery_Query = (
  {
  node?: ClassPendingStudentClassesQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassPendingStudentClassesQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
  states?: InputMaybe<Array<PendingStudentClassState> | PendingStudentClassState>;
}>;


export type ClassPendingStudentClassesQuery = ClassPendingStudentClassesQuery_Query;

export type ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection_pageInfo_PageInfo = (
  {
  hasNextPage?: boolean | null,
  endCursor?: string | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge = (
  {
  node: ClassFragment_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge_node_Student
}
  & {
  __typename?: 'ClassStudentsEdge'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection = (
  {
  totalCount: number,
  pageInfo: ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection_pageInfo_PageInfo,
  edges: Array<ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection_edges_ClassStudentsEdge>
}
  & {
  __typename?: 'ClassStudentsConnection'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User
}
  & {
  __typename?: 'Teacher'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  node: ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type ClassStudentsAndTeachersQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type ClassStudentsAndTeachersQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type ClassStudentsAndTeachersQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type ClassStudentsAndTeachersQuery_node_Class = (
  {
  id: string,
  students: ClassStudentsAndTeachersQuery_node_Class_students_ClassStudentsConnection,
  teachers: ClassStudentsAndTeachersQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassStudentsAndTeachersQuery_node = ClassStudentsAndTeachersQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassStudentsAndTeachersQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassStudentsAndTeachersQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassStudentsAndTeachersQuery_node_Class;

export type ClassStudentsAndTeachersQuery_Query = (
  {
  node?: ClassStudentsAndTeachersQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassStudentsAndTeachersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClassStudentsAndTeachersQuery = ClassStudentsAndTeachersQuery_Query;

export type ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User = (
  {
  id: string,
  name?: string | null,
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  username: string,
  group: string,
  status: UserStatus,
  createdAt: any,
  updatedAt: any,
  lastActivity?: any | null,
  canUpdate: boolean
}
  & {
  __typename?: 'User'
}
);

export type ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_site_Site = (
  {
  id: string,
  name: string
}
  & {
  __typename?: 'Site'
}
);

export type ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher = (
  {
  id: string,
  user: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_user_User,
  site: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher_site_Site
}
  & {
  __typename?: 'Teacher'
}
);

export type ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge = (
  {
  role: ClassRole,
  permissions: Array<string>,
  node: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection = (
  {
  edges: Array<ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge>
}
  & {
  __typename?: 'ClassTeachersConnection'
}
);

export type ClassTeamTeachingQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
};

export type ClassTeamTeachingQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
};

export type ClassTeamTeachingQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
};

export type ClassTeamTeachingQuery_node_Class = (
  {
  id: string,
  teachers: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type ClassTeamTeachingQuery_node = ClassTeamTeachingQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | ClassTeamTeachingQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | ClassTeamTeachingQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | ClassTeamTeachingQuery_node_Class;

export type ClassTeamTeachingQuery_Query = (
  {
  node?: ClassTeamTeachingQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type ClassTeamTeachingQueryVariables = Exact<{
  classId: Scalars['ID']['input'];
}>;


export type ClassTeamTeachingQuery = ClassTeamTeachingQuery_Query;

export type TeamTeachingClassFragment = (
  {
  id: string,
  teachers: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type TeamTeachingEdgeFragment = (
  {
  role: ClassRole,
  permissions: Array<string>,
  node: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection_edges_ClassTeachersEdge_node_Teacher
}
  & {
  __typename?: 'ClassTeachersEdge'
}
);

export type ClassesQuery_classes_ClassesConnection_pageInfo_PageInfo = (
  {
  endCursor?: string | null,
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type ClassesQuery_classes_ClassesConnection_edges_ClassesEdge_node_Class = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  teachers: AdminClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type ClassesQuery_classes_ClassesConnection_edges_ClassesEdge = (
  {
  node: ClassesQuery_classes_ClassesConnection_edges_ClassesEdge_node_Class
}
  & {
  __typename?: 'ClassesEdge'
}
);

export type ClassesQuery_classes_ClassesConnection = (
  {
  pageInfo: ClassesQuery_classes_ClassesConnection_pageInfo_PageInfo,
  edges: Array<ClassesQuery_classes_ClassesConnection_edges_ClassesEdge>
}
  & {
  __typename?: 'ClassesConnection'
}
);

export type ClassesQuery_Query = (
  {
  classes: ClassesQuery_classes_ClassesConnection
}
  & {
  __typename?: 'Query'
}
);


export type ClassesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type ClassesQuery = ClassesQuery_Query;

export type CreateClassMutation_createClass_ClassPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type CreateClassMutation_createClass_ClassPayload = (
  {
  class: CreateClassMutation_createClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type CreateClassMutation_Mutation = (
  {
  createClass: CreateClassMutation_createClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type CreateClassMutationVariables = Exact<{
  input: CreateClassInput;
}>;


export type CreateClassMutation = CreateClassMutation_Mutation;

export type InviteStudentMutation_inviteStudent_InviteStudentPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type InviteStudentMutation_inviteStudent_InviteStudentPayload = (
  {
  invited: InviteResult,
  class: InviteStudentMutation_inviteStudent_InviteStudentPayload_class_Class
}
  & {
  __typename?: 'InviteStudentPayload'
}
);

export type InviteStudentMutation_Mutation = (
  {
  inviteStudent: InviteStudentMutation_inviteStudent_InviteStudentPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type InviteStudentMutationVariables = Exact<{
  input: InviteStudentInput;
}>;


export type InviteStudentMutation = InviteStudentMutation_Mutation;

export type InviteTeacherToClassMutation_inviteTeacherToClass_ClassPayload_class_Class = (
  {
  id: string,
  teachers: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type InviteTeacherToClassMutation_inviteTeacherToClass_ClassPayload = (
  {
  class: InviteTeacherToClassMutation_inviteTeacherToClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type InviteTeacherToClassMutation_Mutation = (
  {
  inviteTeacherToClass: InviteTeacherToClassMutation_inviteTeacherToClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type InviteTeacherToClassMutationVariables = Exact<{
  input: InviteTeacherToClassInput;
}>;


export type InviteTeacherToClassMutation = InviteTeacherToClassMutation_Mutation;

export type JoinClassMutation_joinClass_ClassPayload_class_Class = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean
}
  & {
  __typename: 'Class'
}
);

export type JoinClassMutation_joinClass_ClassPayload = (
  {
  class: JoinClassMutation_joinClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type JoinClassMutation_Mutation = (
  {
  joinClass: JoinClassMutation_joinClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type JoinClassMutationVariables = Exact<{
  input: JoinClassInput;
}>;


export type JoinClassMutation = JoinClassMutation_Mutation;

export type LicenseStatusQuery_viewer_User_teacher_Teacher_site_Site_license_License = (
  {
  id: string,
  type: LicenseType,
  usedSeats?: number | null,
  seats?: number | null
}
  & {
  __typename?: 'License'
}
);

export type LicenseStatusQuery_viewer_User_teacher_Teacher_site_Site = (
  {
  id: string,
  license?: LicenseStatusQuery_viewer_User_teacher_Teacher_site_Site_license_License | null
}
  & {
  __typename?: 'Site'
}
);

export type LicenseStatusQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  site: LicenseStatusQuery_viewer_User_teacher_Teacher_site_Site
}
  & {
  __typename?: 'Teacher'
}
);

export type LicenseStatusQuery_viewer_User = (
  {
  id: string,
  teacher?: LicenseStatusQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type LicenseStatusQuery_Query = (
  {
  viewer?: LicenseStatusQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type LicenseStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseStatusQuery = LicenseStatusQuery_Query;

export type MinimalStudentClassesQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge = (
  {
  node: StudentClassesEdgeFragment_StudentClassesEdge_node_Class
}
  & {
  __typename?: 'StudentClassesEdge'
}
);

export type MinimalStudentClassesQuery_viewer_User_student_Student_classes_StudentClassesConnection = (
  {
  edges: Array<MinimalStudentClassesQuery_viewer_User_student_Student_classes_StudentClassesConnection_edges_StudentClassesEdge>
}
  & {
  __typename?: 'StudentClassesConnection'
}
);

export type MinimalStudentClassesQuery_viewer_User_student_Student = (
  {
  id: string,
  classes: MinimalStudentClassesQuery_viewer_User_student_Student_classes_StudentClassesConnection
}
  & {
  __typename?: 'Student'
}
);

export type MinimalStudentClassesQuery_viewer_User = (
  {
  id: string,
  student?: MinimalStudentClassesQuery_viewer_User_student_Student | null
}
  & {
  __typename?: 'User'
}
);

export type MinimalStudentClassesQuery_Query = (
  {
  viewer?: MinimalStudentClassesQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type MinimalStudentClassesQueryVariables = Exact<{ [key: string]: never; }>;


export type MinimalStudentClassesQuery = MinimalStudentClassesQuery_Query;

export type MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class = (
  {
  id: string,
  name: string,
  weight: number,
  classId?: string | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  archivedAt?: any | null
}
  & {
  __typename?: 'Class'
}
);

export type MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge = (
  {
  node: MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge_node_Class
}
  & {
  __typename?: 'TeacherClassesEdge'
}
);

export type MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection = (
  {
  edges: Array<MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection_edges_TeacherClassesEdge>
}
  & {
  __typename?: 'TeacherClassesConnection'
}
);

export type MinimalTeacherClassesQuery_viewer_User_teacher_Teacher = (
  {
  id: string,
  classes: MinimalTeacherClassesQuery_viewer_User_teacher_Teacher_classes_TeacherClassesConnection
}
  & {
  __typename?: 'Teacher'
}
);

export type MinimalTeacherClassesQuery_viewer_User = (
  {
  id: string,
  teacher?: MinimalTeacherClassesQuery_viewer_User_teacher_Teacher | null
}
  & {
  __typename?: 'User'
}
);

export type MinimalTeacherClassesQuery_Query = (
  {
  viewer?: MinimalTeacherClassesQuery_viewer_User | null
}
  & {
  __typename?: 'Query'
}
);


export type MinimalTeacherClassesQueryVariables = Exact<{
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type MinimalTeacherClassesQuery = MinimalTeacherClassesQuery_Query;

export type MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload_fromClass_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload_toClass_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload = (
  {
  fromClass: MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload_fromClass_Class,
  toClass: MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload_toClass_Class
}
  & {
  __typename?: 'MoveStudentClassPayload'
}
);

export type MoveStudentClassMutation_Mutation = (
  {
  moveStudentClass: MoveStudentClassMutation_moveStudentClass_MoveStudentClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type MoveStudentClassMutationVariables = Exact<{
  input: MoveStudentClassInput;
}>;


export type MoveStudentClassMutation = MoveStudentClassMutation_Mutation;

export type RemoveStudentFromClassMutation_removeStudentFromClass_ClassPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type RemoveStudentFromClassMutation_removeStudentFromClass_ClassPayload = (
  {
  class: RemoveStudentFromClassMutation_removeStudentFromClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type RemoveStudentFromClassMutation_Mutation = (
  {
  removeStudentFromClass: RemoveStudentFromClassMutation_removeStudentFromClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type RemoveStudentFromClassMutationVariables = Exact<{
  input: RemoveStudentFromClassInput;
}>;


export type RemoveStudentFromClassMutation = RemoveStudentFromClassMutation_Mutation;

export type RemoveTeacherFromClassMutation_removeTeacherFromClass_ClassPayload_class_Class = (
  {
  id: string,
  teachers: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type RemoveTeacherFromClassMutation_removeTeacherFromClass_ClassPayload = (
  {
  class: RemoveTeacherFromClassMutation_removeTeacherFromClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type RemoveTeacherFromClassMutation_Mutation = (
  {
  removeTeacherFromClass: RemoveTeacherFromClassMutation_removeTeacherFromClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type RemoveTeacherFromClassMutationVariables = Exact<{
  input: RemoveTeacherFromClassInput;
}>;


export type RemoveTeacherFromClassMutation = RemoveTeacherFromClassMutation_Mutation;

export type StudentClassQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type StudentClassQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type StudentClassQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type StudentClassQuery_node_Class = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean,
  students: StudentClassFragment_Class_students_ClassStudentsConnection,
  teachers: StudentClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type StudentClassQuery_node = StudentClassQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | StudentClassQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | StudentClassQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | StudentClassQuery_node_Class;

export type StudentClassQuery_Query = (
  {
  node?: StudentClassQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type StudentClassQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type StudentClassQuery = StudentClassQuery_Query;

export type TeacherClassQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 = (
  {
  id: string
}
  & {
  __typename?: 'Announcement' | 'AnsweredQuestion' | 'AnsweredQuestionContributor' | 'Assignment' | 'AssignmentCategory' | 'AssignmentSubmission' | 'AssignmentSync' | 'AssignmentTemplate' | 'Badge' | 'ClassGroupPreset' | 'ClassSync' | 'ClassSyncHistory' | 'ClassTemplate' | 'Comment' | 'Course' | 'CourseVersion' | 'CourseView' | 'DirectMessageThread' | 'Discussion' | 'DiscussionThread'
}
);

export type TeacherClassQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk = (
  {
  id: string
}
  & {
  __typename?: 'District' | 'GeneratedPDF' | 'Interaction' | 'Lesson' | 'License' | 'LicenseSubscription' | 'MaterialSync' | 'Media' | 'Notification' | 'PendingStudentClass' | 'Practice' | 'ReportJob' | 'RubricTemplate' | 'SavedReport' | 'Segment' | 'Site' | 'StandardSet' | 'Student' | 'Tag' | 'Teacher'
}
);

export type TeacherClassQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds = (
  {
  id: string
}
  & {
  __typename?: 'TrialRequest' | 'TrialRequestCampaign' | 'User' | 'VideoOnDemand' | 'WidgetTemplate'
}
);

export type TeacherClassQuery_node_Class = (
  {
  id: string,
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type TeacherClassQuery_node = TeacherClassQuery_node_Ranggev617wmAvO0x8eZXHea1zsbpOWTx8EVOvxB8 | TeacherClassQuery_node_zSGulCH0UPEHoyaygGcs6muJATNkVeh6UdAMdsdYk | TeacherClassQuery_node_Oktxy2bd1GoIuKKifheTt0qWuEuVcvDwtWAuP2Ds | TeacherClassQuery_node_Class;

export type TeacherClassQuery_Query = (
  {
  node?: TeacherClassQuery_node | null
}
  & {
  __typename?: 'Query'
}
);


export type TeacherClassQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type TeacherClassQuery = TeacherClassQuery_Query;

export type UnarchiveClassMutation_unarchiveClass_ClassPayload_class_Class = (
  {
  isOwn: boolean,
  canInvite: boolean,
  canUpdate: boolean,
  canRemoveStudents: boolean,
  canUpdateSettings: boolean,
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  students: ClassFragment_Class_students_ClassStudentsConnection,
  teachers: ClassFragment_Class_teachers_ClassTeachersConnection
}
  & {
  __typename: 'Class'
}
);

export type UnarchiveClassMutation_unarchiveClass_ClassPayload = (
  {
  class: UnarchiveClassMutation_unarchiveClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type UnarchiveClassMutation_Mutation = (
  {
  unarchiveClass: UnarchiveClassMutation_unarchiveClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UnarchiveClassMutationVariables = Exact<{
  input: UnarchiveClassInput;
}>;


export type UnarchiveClassMutation = UnarchiveClassMutation_Mutation;

export type UpdateClassMutation_updateClass_ClassPayload_class_Class = (
  {
  id: string,
  name: string,
  description?: string | null,
  weight: number,
  classId?: string | null,
  configuration?: any | null,
  createdAt: any,
  updatedAt: any,
  archivedAt?: any | null,
  canInvite: boolean,
  canUpdate: boolean,
  isOwn: boolean
}
  & {
  __typename: 'Class'
}
);

export type UpdateClassMutation_updateClass_ClassPayload = (
  {
  class: UpdateClassMutation_updateClass_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type UpdateClassMutation_Mutation = (
  {
  updateClass: UpdateClassMutation_updateClass_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateClassMutationVariables = Exact<{
  input: UpdateClassInput;
}>;


export type UpdateClassMutation = UpdateClassMutation_Mutation;

export type UpdateTeacherClassPermissionsMutation_updateTeacherClassPermissions_ClassPayload_class_Class = (
  {
  id: string,
  teachers: ClassTeamTeachingQuery_node_Class_teachers_ClassTeachersConnection
}
  & {
  __typename?: 'Class'
}
);

export type UpdateTeacherClassPermissionsMutation_updateTeacherClassPermissions_ClassPayload = (
  {
  class: UpdateTeacherClassPermissionsMutation_updateTeacherClassPermissions_ClassPayload_class_Class
}
  & {
  __typename?: 'ClassPayload'
}
);

export type UpdateTeacherClassPermissionsMutation_Mutation = (
  {
  updateTeacherClassPermissions: UpdateTeacherClassPermissionsMutation_updateTeacherClassPermissions_ClassPayload
}
  & {
  __typename?: 'Mutation'
}
);


export type UpdateTeacherClassPermissionsMutationVariables = Exact<{
  input: UpdateTeacherClassPermissionsInput;
}>;


export type UpdateTeacherClassPermissionsMutation = UpdateTeacherClassPermissionsMutation_Mutation;

export const MinimalClassFragmentDoc = gql`
    fragment MinimalClass on Class {
  id
  name
  weight
  classId
  canInvite
  canUpdate
  isOwn
  archivedAt
}
    `;
export const BasicClassFragmentDoc = gql`
    fragment BasicClass on Class {
  ...MinimalClass
  __typename
  id
  name
  description
  weight
  classId
  configuration
  createdAt
  updatedAt
  archivedAt
}
    ${MinimalClassFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  firstName
  lastName
  username
  group
  status
  createdAt
  updatedAt
  lastActivity
  canUpdate
}
    `;
export const AdminClassFragmentDoc = gql`
    fragment AdminClass on Class {
  ...BasicClass
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const ClassStudentEdgeFragmentDoc = gql`
    fragment ClassStudentEdge on ClassStudentsEdge {
  node {
    id
    user {
      ...User
      linkedAccounts {
        id
        provider
        providerName
        label
        authorized
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const ClassFragmentDoc = gql`
    fragment Class on Class {
  ...BasicClass
  isOwn
  canInvite
  canUpdate
  canRemoveStudents
  canUpdateSettings
  students(first: 400, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...ClassStudentEdge
    }
  }
  teachers {
    totalCount
  }
}
    ${BasicClassFragmentDoc}
${ClassStudentEdgeFragmentDoc}`;
export const StudentClassesEdgeFragmentDoc = gql`
    fragment StudentClassesEdge on StudentClassesEdge {
  node {
    ...MinimalClass
  }
}
    ${MinimalClassFragmentDoc}`;
export const StudentClassFragmentDoc = gql`
    fragment StudentClass on Class {
  ...BasicClass
  students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
    totalCount
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
  teachers(first: 5, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${BasicClassFragmentDoc}
${UserFragmentDoc}`;
export const PendingStudentClassFragmentDoc = gql`
    fragment PendingStudentClass on PendingStudentClass {
  id
  student {
    id
    user {
      id
      name
      username
      email
    }
  }
  state
  reason
  createdAt
  approvedAt
  declinedAt
}
    `;
export const InteractionFragmentDoc = gql`
    fragment Interaction on Interaction {
  id
  type
  reference
  metadata
  user {
    ...User
  }
  periodStart
  periodEnd
  targetUser {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const RecordInteractionsPayloadFragmentDoc = gql`
    fragment RecordInteractionsPayload on RecordInteractionsPayload {
  numRecorded
}
    `;
export const TeamTeachingEdgeFragmentDoc = gql`
    fragment TeamTeachingEdge on ClassTeachersEdge {
  role
  permissions
  node {
    id
    user {
      ...User
    }
    site {
      id
      name
    }
  }
}
    ${UserFragmentDoc}`;
export const TeamTeachingClassFragmentDoc = gql`
    fragment TeamTeachingClass on Class {
  id
  teachers(first: 50, orderBy: "user.lastName", orderDirection: ASC) {
    edges {
      ...TeamTeachingEdge
    }
  }
}
    ${TeamTeachingEdgeFragmentDoc}`;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($input: AcceptInviteInput!) {
  acceptInvite(input: $input) {
    user {
      id
    }
  }
}
    `;
export type AcceptInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = ApolloReactCommon.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const ArchiveClassDocument = gql`
    mutation archiveClass($input: ArchiveClassInput!) {
  archiveClass(input: $input) {
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type ArchiveClassMutationFn = ApolloReactCommon.MutationFunction<ArchiveClassMutation, ArchiveClassMutationVariables>;

/**
 * __useArchiveClassMutation__
 *
 * To run a mutation, you first call `useArchiveClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClassMutation, { data, loading, error }] = useArchiveClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<ArchiveClassMutation, ArchiveClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<ArchiveClassMutation, ArchiveClassMutationVariables>(ArchiveClassDocument, options);
      }
export type ArchiveClassMutationHookResult = ReturnType<typeof useArchiveClassMutation>;
export type ArchiveClassMutationResult = ApolloReactCommon.MutationResult<ArchiveClassMutation>;
export type ArchiveClassMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveClassMutation, ArchiveClassMutationVariables>;
export const ClassInteractionsDocument = gql`
    query classInteractions($classId: ID!, $types: [String!], $after: String, $first: Int, $userIds: [ID!]) {
  node(id: $classId) {
    id
    ... on Class {
      interactions(first: $first, after: $after, types: $types, userIds: $userIds) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            ...Interaction
          }
        }
      }
    }
  }
}
    ${InteractionFragmentDoc}`;

/**
 * __useClassInteractionsQuery__
 *
 * To run a query within a React component, call `useClassInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassInteractionsQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      types: // value for 'types'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useClassInteractionsQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassInteractionsQuery, ClassInteractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassInteractionsQuery, ClassInteractionsQueryVariables>(ClassInteractionsDocument, options);
      }
export function useClassInteractionsLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassInteractionsQuery, ClassInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassInteractionsQuery, ClassInteractionsQueryVariables>(ClassInteractionsDocument, options);
        }
export type ClassInteractionsQueryHookResult = ReturnType<typeof useClassInteractionsQuery>;
export type ClassInteractionsLazyQueryHookResult = ReturnType<typeof useClassInteractionsLazyQuery>;
export type ClassInteractionsQueryResult = ApolloReactCommon.QueryResult<ClassInteractionsQuery, ClassInteractionsQueryVariables>;
export const ClassPendingStudentClassesDocument = gql`
    query classPendingStudentClasses($classId: ID!, $first: Int = 16, $after: String, $orderBy: String = "createdAt", $orderDirection: OrderDirection = DESC, $states: [PendingStudentClassState!] = [PENDING]) {
  node(id: $classId) {
    id
    ... on Class {
      pendingStudentClasses(
        first: $first
        after: $after
        orderBy: $orderBy
        orderDirection: $orderDirection
        filters: {states: $states}
      ) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          cursor
          node {
            ...PendingStudentClass
          }
        }
      }
    }
  }
}
    ${PendingStudentClassFragmentDoc}`;

/**
 * __useClassPendingStudentClassesQuery__
 *
 * To run a query within a React component, call `useClassPendingStudentClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassPendingStudentClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassPendingStudentClassesQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      states: // value for 'states'
 *   },
 * });
 */
export function useClassPendingStudentClassesQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassPendingStudentClassesQuery, ClassPendingStudentClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassPendingStudentClassesQuery, ClassPendingStudentClassesQueryVariables>(ClassPendingStudentClassesDocument, options);
      }
export function useClassPendingStudentClassesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassPendingStudentClassesQuery, ClassPendingStudentClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassPendingStudentClassesQuery, ClassPendingStudentClassesQueryVariables>(ClassPendingStudentClassesDocument, options);
        }
export type ClassPendingStudentClassesQueryHookResult = ReturnType<typeof useClassPendingStudentClassesQuery>;
export type ClassPendingStudentClassesLazyQueryHookResult = ReturnType<typeof useClassPendingStudentClassesLazyQuery>;
export type ClassPendingStudentClassesQueryResult = ApolloReactCommon.QueryResult<ClassPendingStudentClassesQuery, ClassPendingStudentClassesQueryVariables>;
export const ClassStudentsAndTeachersDocument = gql`
    query classStudentsAndTeachers($id: ID!) {
  node(id: $id, includeDeleted: true) {
    id
    ... on Class {
      students(first: 1000, orderBy: "user.lastName", orderDirection: ASC) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          ...ClassStudentEdge
        }
      }
      teachers(first: 100, orderBy: "user.lastName", orderDirection: ASC) {
        edges {
          node {
            id
            user {
              ...User
            }
          }
        }
      }
    }
  }
}
    ${ClassStudentEdgeFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useClassStudentsAndTeachersQuery__
 *
 * To run a query within a React component, call `useClassStudentsAndTeachersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassStudentsAndTeachersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassStudentsAndTeachersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClassStudentsAndTeachersQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassStudentsAndTeachersQuery, ClassStudentsAndTeachersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassStudentsAndTeachersQuery, ClassStudentsAndTeachersQueryVariables>(ClassStudentsAndTeachersDocument, options);
      }
export function useClassStudentsAndTeachersLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassStudentsAndTeachersQuery, ClassStudentsAndTeachersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassStudentsAndTeachersQuery, ClassStudentsAndTeachersQueryVariables>(ClassStudentsAndTeachersDocument, options);
        }
export type ClassStudentsAndTeachersQueryHookResult = ReturnType<typeof useClassStudentsAndTeachersQuery>;
export type ClassStudentsAndTeachersLazyQueryHookResult = ReturnType<typeof useClassStudentsAndTeachersLazyQuery>;
export type ClassStudentsAndTeachersQueryResult = ApolloReactCommon.QueryResult<ClassStudentsAndTeachersQuery, ClassStudentsAndTeachersQueryVariables>;
export const ClassTeamTeachingDocument = gql`
    query classTeamTeaching($classId: ID!) {
  node(id: $classId) {
    ...TeamTeachingClass
  }
}
    ${TeamTeachingClassFragmentDoc}`;

/**
 * __useClassTeamTeachingQuery__
 *
 * To run a query within a React component, call `useClassTeamTeachingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassTeamTeachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassTeamTeachingQuery({
 *   variables: {
 *      classId: // value for 'classId'
 *   },
 * });
 */
export function useClassTeamTeachingQuery(baseOptions: ApolloReactCommon.QueryHookOptions<ClassTeamTeachingQuery, ClassTeamTeachingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassTeamTeachingQuery, ClassTeamTeachingQueryVariables>(ClassTeamTeachingDocument, options);
      }
export function useClassTeamTeachingLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassTeamTeachingQuery, ClassTeamTeachingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassTeamTeachingQuery, ClassTeamTeachingQueryVariables>(ClassTeamTeachingDocument, options);
        }
export type ClassTeamTeachingQueryHookResult = ReturnType<typeof useClassTeamTeachingQuery>;
export type ClassTeamTeachingLazyQueryHookResult = ReturnType<typeof useClassTeamTeachingLazyQuery>;
export type ClassTeamTeachingQueryResult = ApolloReactCommon.QueryResult<ClassTeamTeachingQuery, ClassTeamTeachingQueryVariables>;
export const ClassesDocument = gql`
    query classes($first: Int = 32, $after: String, $name: String) {
  classes(first: $first, after: $after, name: $name) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        ...AdminClass
      }
    }
  }
}
    ${AdminClassFragmentDoc}`;

/**
 * __useClassesQuery__
 *
 * To run a query within a React component, call `useClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClassesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useClassesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesQueryResult = ApolloReactCommon.QueryResult<ClassesQuery, ClassesQueryVariables>;
export const CreateClassDocument = gql`
    mutation createClass($input: CreateClassInput!) {
  createClass(input: $input) {
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type CreateClassMutationFn = ApolloReactCommon.MutationFunction<CreateClassMutation, CreateClassMutationVariables>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<CreateClassMutation, CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<CreateClassMutation, CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = ApolloReactCommon.MutationResult<CreateClassMutation>;
export type CreateClassMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClassMutation, CreateClassMutationVariables>;
export const InviteStudentDocument = gql`
    mutation inviteStudent($input: InviteStudentInput!) {
  inviteStudent(input: $input) {
    invited
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type InviteStudentMutationFn = ApolloReactCommon.MutationFunction<InviteStudentMutation, InviteStudentMutationVariables>;

/**
 * __useInviteStudentMutation__
 *
 * To run a mutation, you first call `useInviteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteStudentMutation, { data, loading, error }] = useInviteStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteStudentMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<InviteStudentMutation, InviteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<InviteStudentMutation, InviteStudentMutationVariables>(InviteStudentDocument, options);
      }
export type InviteStudentMutationHookResult = ReturnType<typeof useInviteStudentMutation>;
export type InviteStudentMutationResult = ApolloReactCommon.MutationResult<InviteStudentMutation>;
export type InviteStudentMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteStudentMutation, InviteStudentMutationVariables>;
export const InviteTeacherToClassDocument = gql`
    mutation inviteTeacherToClass($input: InviteTeacherToClassInput!) {
  inviteTeacherToClass(input: $input) {
    class {
      ...TeamTeachingClass
    }
  }
}
    ${TeamTeachingClassFragmentDoc}`;
export type InviteTeacherToClassMutationFn = ApolloReactCommon.MutationFunction<InviteTeacherToClassMutation, InviteTeacherToClassMutationVariables>;

/**
 * __useInviteTeacherToClassMutation__
 *
 * To run a mutation, you first call `useInviteTeacherToClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteTeacherToClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteTeacherToClassMutation, { data, loading, error }] = useInviteTeacherToClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteTeacherToClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<InviteTeacherToClassMutation, InviteTeacherToClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<InviteTeacherToClassMutation, InviteTeacherToClassMutationVariables>(InviteTeacherToClassDocument, options);
      }
export type InviteTeacherToClassMutationHookResult = ReturnType<typeof useInviteTeacherToClassMutation>;
export type InviteTeacherToClassMutationResult = ApolloReactCommon.MutationResult<InviteTeacherToClassMutation>;
export type InviteTeacherToClassMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteTeacherToClassMutation, InviteTeacherToClassMutationVariables>;
export const JoinClassDocument = gql`
    mutation joinClass($input: JoinClassInput!) {
  joinClass(input: $input) {
    class {
      ...BasicClass
    }
  }
}
    ${BasicClassFragmentDoc}`;
export type JoinClassMutationFn = ApolloReactCommon.MutationFunction<JoinClassMutation, JoinClassMutationVariables>;

/**
 * __useJoinClassMutation__
 *
 * To run a mutation, you first call `useJoinClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinClassMutation, { data, loading, error }] = useJoinClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<JoinClassMutation, JoinClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<JoinClassMutation, JoinClassMutationVariables>(JoinClassDocument, options);
      }
export type JoinClassMutationHookResult = ReturnType<typeof useJoinClassMutation>;
export type JoinClassMutationResult = ApolloReactCommon.MutationResult<JoinClassMutation>;
export type JoinClassMutationOptions = ApolloReactCommon.BaseMutationOptions<JoinClassMutation, JoinClassMutationVariables>;
export const LicenseStatusDocument = gql`
    query licenseStatus {
  viewer {
    id
    teacher {
      id
      site {
        id
        license {
          id
          type
          usedSeats
          seats
        }
      }
    }
  }
}
    `;

/**
 * __useLicenseStatusQuery__
 *
 * To run a query within a React component, call `useLicenseStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseStatusQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<LicenseStatusQuery, LicenseStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<LicenseStatusQuery, LicenseStatusQueryVariables>(LicenseStatusDocument, options);
      }
export function useLicenseStatusLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<LicenseStatusQuery, LicenseStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<LicenseStatusQuery, LicenseStatusQueryVariables>(LicenseStatusDocument, options);
        }
export type LicenseStatusQueryHookResult = ReturnType<typeof useLicenseStatusQuery>;
export type LicenseStatusLazyQueryHookResult = ReturnType<typeof useLicenseStatusLazyQuery>;
export type LicenseStatusQueryResult = ApolloReactCommon.QueryResult<LicenseStatusQuery, LicenseStatusQueryVariables>;
export const MinimalStudentClassesDocument = gql`
    query minimalStudentClasses {
  viewer {
    id
    student {
      id
      classes(first: 1000, orderBy: "weight", orderDirection: ASC) {
        edges {
          ...StudentClassesEdge
        }
      }
    }
  }
}
    ${StudentClassesEdgeFragmentDoc}`;

/**
 * __useMinimalStudentClassesQuery__
 *
 * To run a query within a React component, call `useMinimalStudentClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinimalStudentClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinimalStudentClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMinimalStudentClassesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<MinimalStudentClassesQuery, MinimalStudentClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<MinimalStudentClassesQuery, MinimalStudentClassesQueryVariables>(MinimalStudentClassesDocument, options);
      }
export function useMinimalStudentClassesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<MinimalStudentClassesQuery, MinimalStudentClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<MinimalStudentClassesQuery, MinimalStudentClassesQueryVariables>(MinimalStudentClassesDocument, options);
        }
export type MinimalStudentClassesQueryHookResult = ReturnType<typeof useMinimalStudentClassesQuery>;
export type MinimalStudentClassesLazyQueryHookResult = ReturnType<typeof useMinimalStudentClassesLazyQuery>;
export type MinimalStudentClassesQueryResult = ApolloReactCommon.QueryResult<MinimalStudentClassesQuery, MinimalStudentClassesQueryVariables>;
export const MinimalTeacherClassesDocument = gql`
    query minimalTeacherClasses($deleted: Boolean = false) {
  viewer {
    id
    teacher {
      id
      classes(first: 1000, orderBy: "weight", orderDirection: ASC, deleted: $deleted) {
        edges {
          node {
            ...MinimalClass
          }
        }
      }
    }
  }
}
    ${MinimalClassFragmentDoc}`;

/**
 * __useMinimalTeacherClassesQuery__
 *
 * To run a query within a React component, call `useMinimalTeacherClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinimalTeacherClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinimalTeacherClassesQuery({
 *   variables: {
 *      deleted: // value for 'deleted'
 *   },
 * });
 */
export function useMinimalTeacherClassesQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<MinimalTeacherClassesQuery, MinimalTeacherClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<MinimalTeacherClassesQuery, MinimalTeacherClassesQueryVariables>(MinimalTeacherClassesDocument, options);
      }
export function useMinimalTeacherClassesLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<MinimalTeacherClassesQuery, MinimalTeacherClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<MinimalTeacherClassesQuery, MinimalTeacherClassesQueryVariables>(MinimalTeacherClassesDocument, options);
        }
export type MinimalTeacherClassesQueryHookResult = ReturnType<typeof useMinimalTeacherClassesQuery>;
export type MinimalTeacherClassesLazyQueryHookResult = ReturnType<typeof useMinimalTeacherClassesLazyQuery>;
export type MinimalTeacherClassesQueryResult = ApolloReactCommon.QueryResult<MinimalTeacherClassesQuery, MinimalTeacherClassesQueryVariables>;
export const MoveStudentClassDocument = gql`
    mutation moveStudentClass($input: MoveStudentClassInput!) {
  moveStudentClass(input: $input) {
    fromClass {
      ...Class
    }
    toClass {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type MoveStudentClassMutationFn = ApolloReactCommon.MutationFunction<MoveStudentClassMutation, MoveStudentClassMutationVariables>;

/**
 * __useMoveStudentClassMutation__
 *
 * To run a mutation, you first call `useMoveStudentClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveStudentClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveStudentClassMutation, { data, loading, error }] = useMoveStudentClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveStudentClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<MoveStudentClassMutation, MoveStudentClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<MoveStudentClassMutation, MoveStudentClassMutationVariables>(MoveStudentClassDocument, options);
      }
export type MoveStudentClassMutationHookResult = ReturnType<typeof useMoveStudentClassMutation>;
export type MoveStudentClassMutationResult = ApolloReactCommon.MutationResult<MoveStudentClassMutation>;
export type MoveStudentClassMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveStudentClassMutation, MoveStudentClassMutationVariables>;
export const RemoveStudentFromClassDocument = gql`
    mutation removeStudentFromClass($input: RemoveStudentFromClassInput!) {
  removeStudentFromClass(input: $input) {
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type RemoveStudentFromClassMutationFn = ApolloReactCommon.MutationFunction<RemoveStudentFromClassMutation, RemoveStudentFromClassMutationVariables>;

/**
 * __useRemoveStudentFromClassMutation__
 *
 * To run a mutation, you first call `useRemoveStudentFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentFromClassMutation, { data, loading, error }] = useRemoveStudentFromClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveStudentFromClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<RemoveStudentFromClassMutation, RemoveStudentFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<RemoveStudentFromClassMutation, RemoveStudentFromClassMutationVariables>(RemoveStudentFromClassDocument, options);
      }
export type RemoveStudentFromClassMutationHookResult = ReturnType<typeof useRemoveStudentFromClassMutation>;
export type RemoveStudentFromClassMutationResult = ApolloReactCommon.MutationResult<RemoveStudentFromClassMutation>;
export type RemoveStudentFromClassMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStudentFromClassMutation, RemoveStudentFromClassMutationVariables>;
export const RemoveTeacherFromClassDocument = gql`
    mutation removeTeacherFromClass($input: RemoveTeacherFromClassInput!) {
  removeTeacherFromClass(input: $input) {
    class {
      ...TeamTeachingClass
    }
  }
}
    ${TeamTeachingClassFragmentDoc}`;
export type RemoveTeacherFromClassMutationFn = ApolloReactCommon.MutationFunction<RemoveTeacherFromClassMutation, RemoveTeacherFromClassMutationVariables>;

/**
 * __useRemoveTeacherFromClassMutation__
 *
 * To run a mutation, you first call `useRemoveTeacherFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeacherFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeacherFromClassMutation, { data, loading, error }] = useRemoveTeacherFromClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTeacherFromClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<RemoveTeacherFromClassMutation, RemoveTeacherFromClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<RemoveTeacherFromClassMutation, RemoveTeacherFromClassMutationVariables>(RemoveTeacherFromClassDocument, options);
      }
export type RemoveTeacherFromClassMutationHookResult = ReturnType<typeof useRemoveTeacherFromClassMutation>;
export type RemoveTeacherFromClassMutationResult = ApolloReactCommon.MutationResult<RemoveTeacherFromClassMutation>;
export type RemoveTeacherFromClassMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveTeacherFromClassMutation, RemoveTeacherFromClassMutationVariables>;
export const StudentClassDocument = gql`
    query studentClass($id: ID!) {
  node(id: $id) {
    id
    ...StudentClass
  }
}
    ${StudentClassFragmentDoc}`;

/**
 * __useStudentClassQuery__
 *
 * To run a query within a React component, call `useStudentClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStudentClassQuery(baseOptions: ApolloReactCommon.QueryHookOptions<StudentClassQuery, StudentClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<StudentClassQuery, StudentClassQueryVariables>(StudentClassDocument, options);
      }
export function useStudentClassLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<StudentClassQuery, StudentClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<StudentClassQuery, StudentClassQueryVariables>(StudentClassDocument, options);
        }
export type StudentClassQueryHookResult = ReturnType<typeof useStudentClassQuery>;
export type StudentClassLazyQueryHookResult = ReturnType<typeof useStudentClassLazyQuery>;
export type StudentClassQueryResult = ApolloReactCommon.QueryResult<StudentClassQuery, StudentClassQueryVariables>;
export const TeacherClassDocument = gql`
    query teacherClass($id: ID!) {
  node(id: $id, includeDeleted: true) {
    id
    ...Class
  }
}
    ${ClassFragmentDoc}`;

/**
 * __useTeacherClassQuery__
 *
 * To run a query within a React component, call `useTeacherClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeacherClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeacherClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeacherClassQuery(baseOptions: ApolloReactCommon.QueryHookOptions<TeacherClassQuery, TeacherClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<TeacherClassQuery, TeacherClassQueryVariables>(TeacherClassDocument, options);
      }
export function useTeacherClassLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<TeacherClassQuery, TeacherClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<TeacherClassQuery, TeacherClassQueryVariables>(TeacherClassDocument, options);
        }
export type TeacherClassQueryHookResult = ReturnType<typeof useTeacherClassQuery>;
export type TeacherClassLazyQueryHookResult = ReturnType<typeof useTeacherClassLazyQuery>;
export type TeacherClassQueryResult = ApolloReactCommon.QueryResult<TeacherClassQuery, TeacherClassQueryVariables>;
export const UnarchiveClassDocument = gql`
    mutation unarchiveClass($input: UnarchiveClassInput!) {
  unarchiveClass(input: $input) {
    class {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}`;
export type UnarchiveClassMutationFn = ApolloReactCommon.MutationFunction<UnarchiveClassMutation, UnarchiveClassMutationVariables>;

/**
 * __useUnarchiveClassMutation__
 *
 * To run a mutation, you first call `useUnarchiveClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveClassMutation, { data, loading, error }] = useUnarchiveClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UnarchiveClassMutation, UnarchiveClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UnarchiveClassMutation, UnarchiveClassMutationVariables>(UnarchiveClassDocument, options);
      }
export type UnarchiveClassMutationHookResult = ReturnType<typeof useUnarchiveClassMutation>;
export type UnarchiveClassMutationResult = ApolloReactCommon.MutationResult<UnarchiveClassMutation>;
export type UnarchiveClassMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveClassMutation, UnarchiveClassMutationVariables>;
export const UpdateClassDocument = gql`
    mutation updateClass($input: UpdateClassInput!) {
  updateClass(input: $input) {
    class {
      ...BasicClass
    }
  }
}
    ${BasicClassFragmentDoc}`;
export type UpdateClassMutationFn = ApolloReactCommon.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;

/**
 * __useUpdateClassMutation__
 *
 * To run a mutation, you first call `useUpdateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassMutation, { data, loading, error }] = useUpdateClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClassMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = ApolloReactCommon.MutationResult<UpdateClassMutation>;
export type UpdateClassMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClassMutation, UpdateClassMutationVariables>;
export const UpdateTeacherClassPermissionsDocument = gql`
    mutation updateTeacherClassPermissions($input: UpdateTeacherClassPermissionsInput!) {
  updateTeacherClassPermissions(input: $input) {
    class {
      ...TeamTeachingClass
    }
  }
}
    ${TeamTeachingClassFragmentDoc}`;
export type UpdateTeacherClassPermissionsMutationFn = ApolloReactCommon.MutationFunction<UpdateTeacherClassPermissionsMutation, UpdateTeacherClassPermissionsMutationVariables>;

/**
 * __useUpdateTeacherClassPermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateTeacherClassPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeacherClassPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeacherClassPermissionsMutation, { data, loading, error }] = useUpdateTeacherClassPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeacherClassPermissionsMutation(baseOptions?: ApolloReactCommon.MutationHookOptions<UpdateTeacherClassPermissionsMutation, UpdateTeacherClassPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useMutation<UpdateTeacherClassPermissionsMutation, UpdateTeacherClassPermissionsMutationVariables>(UpdateTeacherClassPermissionsDocument, options);
      }
export type UpdateTeacherClassPermissionsMutationHookResult = ReturnType<typeof useUpdateTeacherClassPermissionsMutation>;
export type UpdateTeacherClassPermissionsMutationResult = ApolloReactCommon.MutationResult<UpdateTeacherClassPermissionsMutation>;
export type UpdateTeacherClassPermissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTeacherClassPermissionsMutation, UpdateTeacherClassPermissionsMutationVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}

declare module '@thesisedu/feature-users-react' {
  export interface PermissionsMap extends Record<`${Permission}`, true> {}
}
