import { AntIconWrapper, Body, BodyLarge, BodySmall, FontWeight, Link } from '@thesisedu/react'
import { HelpCircle } from '@thesisedu/react/icons'
import { Popover } from 'antd'
import React from 'react'

import {
  GRADE_INCOMPLETE_LATE_AS_ZERO,
  useGradeIncompleteLateAsZero,
} from '../resources/class_configuration/GradeIncompleteLateAsZero'

export interface AssignmentCellMissingProps {
  classId: string
}
export function AssignmentCellMissing({ classId }: AssignmentCellMissingProps) {
  const useGradeIncompleteAsZero = useGradeIncompleteLateAsZero(classId)
  return (
    <div style={{ textAlign: 'center' }}>
      <BodySmall weight={FontWeight.SemiBold} color={'@red'}>
        Missing
      </BodySmall>
      {useGradeIncompleteAsZero ? (
        <Popover
          overlayStyle={{ width: 325 }}
          content={
            <>
              <BodyLarge isBlock weight={FontWeight.Bold}>
                Missing assignments are automatically graded as 0%.
              </BodyLarge>
              <Body>
                You have configured in your settings to automatically give assignments a 0% if they
                are not submitted by the due date. To change this,{' '}
                <Link
                  to={`/teacher/classes/${classId}/settings#configuration_${GRADE_INCOMPLETE_LATE_AS_ZERO}`}
                >
                  click here
                </Link>{' '}
                to go to your class settings.
              </Body>
            </>
          }
          trigger={['hover']}
          mouseEnterDelay={1}
        >
          <BodySmall color={'@text-color-secondary'}>
            Graded as 0% <AntIconWrapper children={<HelpCircle />} />
          </BodySmall>
        </Popover>
      ) : null}
    </div>
  )
}
