import { useUserStudentQuery } from './schema'

export function useStudent() {
  const { data, loading, error } = useUserStudentQuery()
  const student = data?.viewer?.student
  return {
    student,
    loading,
    error: !!error,
  }
}
