import { SelectButtonContent } from '@thesisedu/feature-apollo-react/web'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import {
  AllRubricTemplatesDocument,
  AllRubricTemplatesQuery,
  RubricTemplateFragment,
  RubricTemplatesDocument,
  RubricTemplatesQuery,
  RubricTemplatesQueryVariables,
} from '../schema'

export interface RubricTemplateSelectProps {
  onSelected: (rubricTemplate: RubricTemplateFragment) => void
}
export function RubricTemplateSelect({ onSelected }: RubricTemplateSelectProps) {
  const viewer = useViewerContext(false)
  const useAllQuery = viewer?.group === 'ADMIN' || viewer?.group === 'CONTENT_MANAGER'
  const document = useAllQuery ? AllRubricTemplatesDocument : RubricTemplatesDocument
  const resultPath = useAllQuery ? 'rubricTemplates' : 'viewer.rubricTemplates'
  return (
    <SelectButtonContent<
      RubricTemplateFragment,
      RubricTemplatesQuery | AllRubricTemplatesQuery,
      RubricTemplatesQueryVariables
    >
      filterVariableName={'name'}
      getItemName={r => r.name}
      document={document}
      resultPath={resultPath}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      maxSelection={1}
      onChange={(selectedItems, fragments) => {
        if (selectedItems[0] && fragments[0]) {
          onSelected(fragments[0])
        }
      }}
    />
  )
}
