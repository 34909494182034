import { CloudUploadOutlined } from '@ant-design/icons'

import { FileUploadAnswer } from './FileUploadAnswer'
import { FileUploadContent } from './FileUploadContent'
import { FileUploadEdit } from './FileUploadEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'FileUpload',
  defaults: {
    name: 'Question',
    config: {
      type: 'VIDEO',
    },
  },
  icon: CloudUploadOutlined,
  label: 'File Upload',
  group: 'Upload',
  renderAnswerView: FileUploadAnswer,
  renderContent: FileUploadContent,
  renderEdit: FileUploadEdit,
} as QuestionTypeResource
