import { SegmentNavigationChildProps } from '@thesisedu/feature-courses-react'
import React from 'react'

import { useSegmentAssignment } from './AssignmentContent'

export interface DisableIfNoAssignmentNavigationItemProps extends SegmentNavigationChildProps {
  segmentId: string
}
export function DisableIfNoAssignmentNavigationItem({
  children,
  segmentId,
}: DisableIfNoAssignmentNavigationItemProps) {
  const { assignment, loading } = useSegmentAssignment(segmentId)
  return React.cloneElement(children, {
    disabled: loading || !assignment,
  })
}
