import { DeepLinkProps } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import React from 'react'

import { usePracticeQuery } from './schema'

export function PracticesClassPrefixDeepLinkRemapper({ resolved, url, params }: DeepLinkProps) {
  const viewer = useViewerContext(false)
  const { data, error } = usePracticeQuery({ variables: { id: params.id || '' }, skip: !params.id })
  const practice = data?.node?.__typename === 'Practice' ? data.node : undefined
  React.useEffect(() => {
    if (viewer?.group && practice?.class) {
      resolved({
        type: 'Found',
        resolvedPath: `/${viewer.group.toLowerCase()}/classes/${practice.class.id}/practices/${
          practice.id
        }`,
      })
    } else if (error) {
      resolved({
        type: 'AccessDenied',
      })
    } else if (!params.id) {
      resolved({
        type: 'NotFound',
      })
    }
  }, [practice, viewer])

  return null
}
