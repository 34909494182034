import { EditDistrictActionsHook } from '@thesisedu/feature-districts-web'
import React from 'react'

import { CanvasReactFeature } from '../CanvasReactFeature'
import { GenerateCanvasTokenMenuItem } from '../web/GenerateCanvasCredentialsTokenModal'

export default function (feature: CanvasReactFeature) {
  feature.hookManager.registerMutateHook<EditDistrictActionsHook>(
    'districts-web:edit-actions',
    (actions, context) => {
      return [
        <GenerateCanvasTokenMenuItem districtId={context.districtId} key={'generate-canvas'} />,
        ...actions,
      ]
    },
  )
}
