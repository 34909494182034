import {
  ApolloClient,
  DocumentNode,
  useApolloClient,
  useModifiedQueryDocument,
} from '@thesisedu/feature-apollo-react/apollo'
import React from 'react'

import { useClasses } from './ClassContext'
import { error } from './log'
import {
  ClassStudentEdgeFragment,
  ClassStudentsAndTeachersDocument,
  MinimalClassFragment,
  ClassStudentsAndTeachersQuery,
  ClassStudentsAndTeachersQueryVariables,
  UserFragment,
} from './schema'

interface TeacherEdge {
  node: {
    id: string
    user: UserFragment
  }
}
export interface ClassWithStudents extends MinimalClassFragment {
  students: {
    edges: ClassStudentEdgeFragment[]
  }
  teachers: {
    edges: TeacherEdge[]
  }
}
interface AllStudentsQuery {
  classes?: ClassWithStudents[]
  loading: boolean
  error?: boolean
}

async function fetchAll(
  client: ApolloClient<object>,
  classes: MinimalClassFragment[],
  document: DocumentNode,
) {
  return Promise.all(
    classes.map<Promise<ClassWithStudents>>(async cls => {
      const result = await client.query<
        ClassStudentsAndTeachersQuery,
        ClassStudentsAndTeachersQueryVariables
      >({
        query: document,
        variables: { id: cls.id },
        fetchPolicy: 'cache-first',
      })
      if (result.error || result.errors) {
        error('ran into error fetching students')
        error(result.error || result.errors)
        throw new Error('error fetching students')
      }
      const resultNode = result.data.node?.__typename === 'Class' ? result.data.node : undefined
      return {
        ...cls,
        students: resultNode?.students || { edges: [] },
        teachers: resultNode?.teachers || { edges: [] },
      }
    }),
  )
}

export function useAllStudentsLazyQuery(): [AllStudentsQuery, () => Promise<void>] {
  const client = useApolloClient()
  const { classes, loading } = useClasses()
  const [results, setResults] = React.useState<AllStudentsQuery | undefined>()
  const modified = useModifiedQueryDocument(ClassStudentsAndTeachersDocument)
  return [
    results || { loading: true },
    async () => {
      if (!loading && classes) {
        setResults(r => ({ ...r, loading: true }))
        const validClasses = classes.filter(cls => !cls.archivedAt)
        return fetchAll(client, validClasses, modified)
          .then(classesWithStudents => {
            setResults({ classes: classesWithStudents, loading: false, error: false })
          })
          .catch((err: any) => {
            error('error fetching students %O', err)
            setResults(r => ({ ...r, loading: false, error: true }))
          })
      }
    },
  ]
}

export function useAllStudentsQuery(skip?: boolean) {
  const [results, doFetch] = useAllStudentsLazyQuery()
  const { classes, loading } = useClasses()
  React.useEffect(() => {
    if (!skip) {
      doFetch()
    }
  }, [classes.length, loading, skip])

  return results
}
