import { ScrollableContainerProvider, s, styled } from '@thesisedu/ui'
import { motion, AnimatePresence } from 'framer-motion'
import React from 'react'
import FullScreen from 'react-full-screen'

import { FullscreenContext } from './RecordViewFullscreen'
import { RecordViewProviders } from './RecordViewProviders'
import states from './states'
import { DEFAULT_STATE, DEFAULT_STATE_DISABLED } from './states/default'
import { TransitionOpts } from './states/types'
import { GetMediaUploadResponse, GetMediaUploadUrl } from './types'

export interface RecordViewProps {
  /** The label for the media being uploaded. */
  label: string
  /** RecordView plugins are installed via children. */
  children?: React.ReactNode
  /** The class ID to associate the media recording with, if there is one. */
  classId?: string
  /** Called before recording is started. */
  onPrepare?: () => Promise<void> | void
  /** True if we should skip the review step. */
  skipReview?: boolean
  /** If true, hides any video-related streams. */
  hideVideo?: boolean
  /** If true, hides any audio-related streams. */
  hideAudio?: boolean
  /**
   * If you would like to provide custom behavior for getting the upload URL for media,
   * specify that callback function here.
   */
  getMediaUploadUrl?: GetMediaUploadUrl
  /**
   * Called depending on #waitForUpload. If waitForUpload is true, this is
   * called whenever the upload has finished uploading. If false, this
   * is called whenever the upload is added to the upload manager.
   */
  onCreated?: (result: GetMediaUploadResponse) => void
  /**
   * Called whenever the media is processed. If provided, automatically sets
   * waitForUpload to true.
   */
  onProcessed?: (result: GetMediaUploadResponse) => void
  /** If true, wait for the upload to finish before calling onCreated() */
  waitForUpload?: boolean
  /**
   * True if the record view is disabled. If this is a string, that
   * message is used on the disabled screen instead.
   */
  disabled?: boolean | string
  /** The maximum allowed duration of the recording. */
  maxDurationSeconds?: number
}
export function RecordView({
  onProcessed,
  waitForUpload = !!onProcessed,
  disabled,
  children,
  ..._props
}: RecordViewProps) {
  const props = { onProcessed, waitForUpload, ..._props }
  const [_state, _setState] = React.useState<TransitionOpts<any>>(DEFAULT_STATE)
  const [fullscreen, setFullscreen] = React.useState(false)
  const state = disabled ? DEFAULT_STATE_DISABLED : _state
  const setState = (state: TransitionOpts<any>) => {
    const newState = states[state.state]
    const beforeTransition = newState?.beforeTransition?.({ ...props, context: state.context })
    _setState(beforeTransition ?? state)
  }
  React.useEffect(() => {
    setFullscreen(false)
  }, [state])
  const currentState = states[state.state]
  if (!currentState) throw new Error(`RecordView state '${state.state}' is invalid.`)
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <s.ThemeProvider colorVariant={'dark'}>
      <FullScreen enabled={fullscreen} onChange={setFullscreen}>
        <FullscreenContext.Provider value={{ fullscreen, setFullscreen }}>
          <RecordViewProviders {...props}>
            <Container $fullscreen={fullscreen} ref={containerRef}>
              <ScrollableContainerProvider targetRef={fullscreen ? containerRef : undefined}>
                <>
                  <AnimatePresence exitBeforeEnter initial={false}>
                    <AnimatedContainer
                      key={state.state}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <currentState.Component context={state.context} onTransition={setState} />
                    </AnimatedContainer>
                  </AnimatePresence>
                  {children}
                </>
              </ScrollableContainerProvider>
            </Container>
          </RecordViewProviders>
        </FullscreenContext.Provider>
      </FullScreen>
    </s.ThemeProvider>
  )
}

const Container = styled.div<{ $fullscreen?: boolean }>`
  border-radius: ${s.var('radii.2')};
  background: ${s.color('subtle')};
  overflow: hidden;
  position: relative;
  ${props => (props.$fullscreen ? 'inset: 0;' : 'aspect-ratio: 16 / 9;')}
  width: 100%;
  display: flex;
  position: ${props => (props.$fullscreen ? 'absolute' : 'relative')};
`
const AnimatedContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  position: relative;
`
