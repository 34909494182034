import { Form, Modal, Button, Input, message } from 'antd'
import React from 'react'

import { useOptions } from '../options'
import { useCreateClassMutation } from '../queries/useCreateClassMutation'
import { ClassFragment } from '../schema'

export interface CreateClassModalProps {
  visible?: boolean
  onClose: () => void
  onCreated?: (createdClass: ClassFragment) => void
}
export const CreateClassModal: React.FC<React.PropsWithChildren<CreateClassModalProps>> = ({
  visible,
  onClose,
  onCreated,
}) => {
  const options = useOptions()
  const [createClass, { loading }] = useCreateClassMutation({
    onCompleted: data => {
      message.success(`${options.classNameCapitalized} created successfully!`)
      if (onCreated) onCreated(data.createClass.class)
      onClose()
    },
  })
  const [form] = Form.useForm()
  return (
    <Modal
      title={`Create ${options.classNameCapitalized}`}
      onCancel={onClose}
      visible={visible}
      footer={[
        <Button key={'cancel'} onClick={onClose}>
          Cancel
        </Button>,
        <Button key={'submit'} onClick={() => form.submit()} type={'primary'} loading={loading}>
          Create {options.classNameCapitalized}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={values => {
          createClass({
            variables: {
              input: {
                name: values.name,
                description: values.description,
              },
            },
          })
        }}
      >
        <Form.Item
          name={'name'}
          label={'Name'}
          rules={[{ required: true, message: `A ${options.className} name is required.` }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={'description'} label={'Description'}>
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 10 }} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
