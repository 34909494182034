import { s } from '@thesisedu/ui'
import { ErrorPageProps, ErrorPage } from '@thesisedu/web'
import React, { useContext, useEffect, useState } from 'react'

import { ErrorOutput } from './ErrorOutput'
import { WebContext } from './WebProvider'
import { ERROR_PAGE_PROPS_HOOK } from './constants'
import { debug } from './log'
import { OutdatedMessage } from './shell'

export interface Error500Props {
  error: Error
  errorInfo?: React.ErrorInfo | null
}
export const Error500: React.FC<React.PropsWithChildren<Error500Props>> = ({
  error,
  errorInfo,
}) => {
  const [props, setProps] = useState<Partial<ErrorPageProps>>({})
  const { featureWeb } = useContext(WebContext) || {}
  useEffect(() => {
    setProps({})
    if (featureWeb && error) {
      debug('found error, triggering hook to modify props')
      featureWeb.deps.hookManager
        .mutateHook<Partial<ErrorPageProps>, Error>(ERROR_PAGE_PROPS_HOOK, {}, error)
        .then(props => setProps(props))
    }
  }, [error, featureWeb])
  if (process.env.NODE_ENV === 'development') {
    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ErrorOutput
          error={error}
          extraStack={
            errorInfo?.componentStack ? `Component Stack:${errorInfo.componentStack}` : undefined
          }
        />
      </div>
    )
  } else {
    return (
      <ErrorPage
        title={'Something went wrong.'}
        technical={'Error 500'}
        description={
          "Something didn't go as planned. We're already on it, but your input can help us " +
          'resolve this faster. If you keep seeing this page, reach out to support and let ' +
          "them know what you're up to when this page shows up."
        }
        footer={
          !featureWeb || featureWeb.isValidBrowserVersion ? undefined : (
            <OutdatedMessage
              style={{ fontSize: s.size('font.s'), padding: s.size('s'), textAlign: 'left' }}
            >
              It looks like you're using an outdated browser, which could be causing this issue. For
              the best experience, we recommend updating to a more current version.
            </OutdatedMessage>
          )
        }
        {...props}
      />
    )
  }
}
