import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
export const CommentsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 0;
  position: relative;
  > div {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
  }
  .ant-empty-description {
    color: ${props => props.theme['@text-color']};
  }
`
export const InverseCommentsContainer = styled(CommentsContainer)`
  > div {
    flex-direction: column-reverse;
    > div:last-child {
      padding-top: ${props => props.theme['@size-m']};
    }
  }
`
export const CommentBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.theme['@size-m']};
  background: linear-gradient(
    to bottom,
    ${props => props.theme['@component-background']} 0%,
    ${props => transparentize(1, props.theme['@component-background'])} 100%
  );
`
export const Footer = styled.div`
  margin-top: ${props => props.theme['@size-m']};
  flex-shrink: 0;
`
export const Header = styled.div`
  margin-bottom: ${props => props.theme['@size-m']};
  flex-shrink: 0;
`
