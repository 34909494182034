import { Body, BodySmall, Checkbox, Empty, LoadingIndicator } from '@thesisedu/react'
import { Modal, styled } from '@thesisedu/web'
import React from 'react'

import { useSiteStandardSetsQuery, useStandardSetsQuery } from '../schema'

export interface SiteStandardSetsModalProps {
  siteId: string
  visible?: boolean
  onClose: () => void
  onChange?: (standardSetIds: string[]) => void
  value?: string[]
}
export function SiteStandardSetsModal({
  siteId,
  visible,
  onClose,
  onChange,
  value,
}: SiteStandardSetsModalProps) {
  const standardSetsQuery = useStandardSetsQuery({ variables: { first: 1000 } })
  const siteStandardSetsQuery = useSiteStandardSetsQuery({
    variables: { id: siteId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    skip: !visible,
  })
  const standardSets = standardSetsQuery.data?.standardSets.edges.map(edge => edge.node)
  const siteStandardSets =
    siteStandardSetsQuery.data?.node?.__typename === 'Site'
      ? siteStandardSetsQuery.data.node.standardSets.edges
      : []
  return (
    <Modal
      title={'Standard Sets'}
      width={900}
      style={{ maxWidth: '90vw', minHeight: 300 }}
      visible={visible}
      onCancel={onClose}
    >
      {standardSetsQuery.loading || siteStandardSetsQuery.loading ? (
        <LoadingIndicator block />
      ) : standardSets?.length ? (
        <ListContainer>
          {standardSets.map(standardSet => {
            const isAll = standardSet.state === 'all'
            const selected =
              isAll ||
              (value
                ? value.includes(standardSet.id)
                : siteStandardSets.some(sss => sss.node.id === standardSet.id))
            return (
              <ListItem
                key={standardSet.id}
                selected={selected}
                onClick={() => {
                  const defaultValue = value ? value : siteStandardSets.map(sss => sss.node.id)
                  const without = defaultValue.filter(v => v !== standardSet.id)
                  if (onChange) {
                    onChange(selected ? without : [...without, standardSet.id])
                  }
                }}
              >
                <Checkbox
                  checked={selected}
                  disabled={isAll}
                  onChange={e => {
                    e.preventDefault()
                  }}
                />
                <div>
                  <Body>{standardSet.name}</Body>
                  {selected &&
                  !isAll &&
                  siteStandardSets.some(sss => sss.node.id === standardSet.id && sss.generated) ? (
                    <BodySmall color={'@text-color-secondary'}>Automatically Assigned</BodySmall>
                  ) : null}
                  {isAll ? (
                    <BodySmall color={'@text-color-secondary'}>Assigned to All Sites</BodySmall>
                  ) : null}
                </div>
              </ListItem>
            )
          })}
        </ListContainer>
      ) : (
        <Empty description={'There are no standard sets.'} />
      )}
    </Modal>
  )
}

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${props => props.theme['@size-s']};
  > * {
    margin-bottom: ${props => props.theme['@size-xxs']};
  }
`
const ListItem = styled.div<{ selected: boolean }>`
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  transition:
    background 0.25s linear,
    color 0.25s linear;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  > :first-child {
    margin-right: ${props => props.theme['@size-xs']};
  }
  > :last-child {
    flex: 1;
  }
  ${props => (props.selected ? '&' : '&.noop')}, &:hover {
    background: ${props => props.theme['@gray-1']};
  }
`
