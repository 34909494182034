import { useIds, useMutateHook } from '@thesisedu/feature-react'
import { omitDeep } from '@thesisedu/react'
import { Form, HSpaced, Separator, Tooltip, VSpaced, s, styled, useToast } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import { mapValues, pick } from 'lodash'
import React from 'react'

import { AssignFields, HiddenAssignField } from './AssignFields'
import { AssignmentAttemptsField } from './AssignmentAttemptsField'
import { AssignmentCategoryFields } from './AssignmentCategoryFields'
import { AssignmentDateFields } from './AssignmentDateFields'
import { AssignmentFlags } from './AssignmentFlags'
import { AssignmentGradingModeField } from './AssignmentGradingModeField'
import { AssignmentSettingsContent } from './AssignmentSettingsContent'
import { AssignmentSettingsContext } from './AssignmentSettingsContext'
import { AssignmentSyncs } from './AssignmentSyncs'
import { AssignmentTimeLimitField } from './AssignmentTimeLimitField'
import { AssignmentTotalPointsField } from './AssignmentTotalPointsField'
import { DeleteAssignmentButton } from './DeleteAssignmentButton'
import { RevealAnswersFields } from './RevealAnswersFields'
import { assignmentToFormValues } from './assignmentToFormValues'
import { SETTINGS_WHITELIST_FIELDS } from './constants'
import { FullAssignment } from './types'
import { useAssignedClassIds } from './useAssignedClassIds'
import { useAssignmentSettingsFormDirtyState } from './useAssignmentSettingsFormDirtyState'
import { useAssignmentSettingsFormUpdates } from './useAssignmentSettingsFormUpdates'
import { useCanDeleteAssignment } from './useCanDeleteAssignment'
import { useGradingModalVisibilityContext } from '../contexts/GradingModalVisibilityContext'
import { debug } from '../log'
import { AssignmentSettingsOptionsHook, SettingsFormValues } from '../node'
import { useUpdateAssignmentMutation } from '../queries/useUpdateAssignmentMutation'
import { AssignmentFeature, AssignmentStudentAssignmentMode } from '../schema'

export interface AssignmentSettingsProps {
  assignment: FullAssignment
  /** If you only want to allow assigning to a specific class, pass this. */
  classId?: string
}
export function AssignmentSettings(props: AssignmentSettingsProps) {
  const { assignment, classId } = props
  useIds([{ id: assignment.id, label: 'Assignment Settings' }])
  const gradingModalVisibility = useGradingModalVisibilityContext(false)
  const {
    hideAssignment,
    hideName,
    hideDescription,
    hideRevealAnswers,
    hideRandomizeQuestions,
    hideMaxAttempts,
    hideTimeLimit,
    assignmentSyncsProps,
    questionSettingsTab,
    Submit,
    OpensAt,
    SettingsContent,
  } = useMutateHook<AssignmentSettingsOptionsHook>(
    'feature-assignments-react:assignment-settings-options',
    {
      hideMaxAttempts: !assignment.supportedFeatures.includes(AssignmentFeature.Attempts),
    },
    { assignment },
  )
  const canDeleteAssignment = useCanDeleteAssignment(assignment)
  const form = Form.useForm<SettingsFormValues>({
    defaultValues: assignmentToFormValues(assignment),
  })
  const { onSaved } = useAssignmentSettingsFormDirtyState(form, assignment)
  const toast = useToast()
  const [update, { loading }] = useUpdateAssignmentMutation({
    onCompleted(data) {
      onSaved()
      toast({ title: 'Assignment saved!', status: 'success' })
    },
  })
  useAssignmentSettingsFormUpdates(form, assignment)
  const assignedClassIds = useAssignedClassIds(form, assignment)

  const submitButtonButton = (
    <Tooltip
      title={assignment.canUpdate ? '' : 'You do not have permission to update this assignment.'}
    >
      <Form.StandaloneSubmit
        icon={<Check />}
        form={form}
        loading={loading}
        className={'assignment-settings-save'}
        disabled={!assignment.canUpdate}
        data-testid={'AssignmentSettings save'}
        onFinish={async values => {
          if (!assignment.canUpdate) return

          let whitelistFields = [...SETTINGS_WHITELIST_FIELDS]
          if (OpensAt !== undefined)
            whitelistFields = whitelistFields.filter(field => field !== 'openAt')
          if (hideRevealAnswers)
            whitelistFields = whitelistFields.filter(field => field !== 'revealAnswers')
          if (hideName) whitelistFields = whitelistFields.filter(field => field !== 'name')
          if (hideDescription)
            whitelistFields = whitelistFields.filter(field => field !== 'description')
          if (hideMaxAttempts)
            whitelistFields = whitelistFields.filter(field => field !== 'maxAttempts')

          const offset = -new Date().getTimezoneOffset() // Inverting native getTimezoneOffset() to align with ISO 8601 Standards.
          const didChangeGradingMode = values.gradingMode !== assignment.gradingMode
          await update({
            variables: {
              input: {
                id: assignment.id,
                patch: omitDeep(
                  {
                    ...mapValues(
                      pick(
                        { ...values, dueAtOffset: offset, openAtOffset: offset },
                        whitelistFields,
                      ),
                      value => {
                        return value?.toString().trim() ? value : null
                      },
                    ),
                    configuration: {
                      ...assignment.configuration,
                      ...values.configuration,
                    },
                    totalPoints: values.rubric ? undefined : values.totalPoints,
                    assign:
                      classId &&
                      values.assignmentMode === AssignmentStudentAssignmentMode.AllStudents
                        ? { assignedClasses: [classId] }
                        : values.assign,
                  },
                  ['__typename'],
                ),
              },
            },
            update(cache) {
              if (didChangeGradingMode) {
                debug('grading mode changed, evicting submissions')
                cache.evict({
                  id: cache.identify({ __typename: 'Assignment', id: assignment.id }),
                  fieldName: 'submissions',
                })
              }
            },
          })
        }}
      >
        Save Changes
      </Form.StandaloneSubmit>
    </Tooltip>
  )
  let submitButton = (
    <OuterButtonContainer className={'assignment-settings-header'}>
      <ButtonContainer>{submitButtonButton}</ButtonContainer>
    </OuterButtonContainer>
  )
  if (Submit) {
    submitButton = <Submit children={submitButton} button={submitButtonButton} />
  }

  return (
    <AssignmentSettingsContext.Provider value={{ form }}>
      <Form form={form}>
        {submitButton}
        <VSpaced space={'l'}>
          {hideName ? null : (
            <Form.TextField
              name={'name'}
              label={'Name'}
              required
              size={'large'}
              placeholder={'My Assignment'}
            />
          )}
          {hideDescription ? null : (
            <Form.TextAreaField
              name={'description'}
              label={'Description'}
              size={'large'}
              placeholder={'Give your students instructions on how to complete this assignment.'}
            />
          )}
          <Container>
            <LeftContainer space={'m'}>
              {hideAssignment ? <HiddenAssignField /> : <AssignFields classId={classId} />}
              <AssignmentDateFields OpensAt={OpensAt} />
              <AssignmentCategoryFields classIds={classId ? [classId] : assignedClassIds} />
              <AssignmentTotalPointsField questionSettingsTab={questionSettingsTab} />
              {hideMaxAttempts ? null : <AssignmentAttemptsField />}
              {hideRevealAnswers ? null : <RevealAnswersFields />}
              {hideTimeLimit ? null : <AssignmentTimeLimitField />}
            </LeftContainer>
            <RightContainer space={'m'}>
              <AssignmentSyncs {...assignmentSyncsProps} assignment={assignment} />
              <Separator />
              <AssignmentFlags hideRandomizeQuestions={hideRandomizeQuestions} />
              <AssignmentGradingModeField
                hasQuestions={!!assignment.configuration?.questions?.length}
              />
              {canDeleteAssignment ? (
                <DeleteAssignmentButton
                  assignmentId={assignment.id}
                  variant={'chromeless'}
                  style={{ margin: '0 auto' }}
                  onDelete={() => {
                    if (gradingModalVisibility) {
                      gradingModalVisibility.setGradingAssignment(undefined)
                    }
                  }}
                />
              ) : null}
            </RightContainer>
          </Container>
          <AssignmentSettingsContent {...props} SettingsContent={SettingsContent} />
        </VSpaced>
      </Form>
    </AssignmentSettingsContext.Provider>
  )
}

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: calc(${s.size('xl')} * -1);
  z-index: 12;
  padding-bottom: ${s.size('s')};
  &:empty {
    display: none;
  }
`
const OuterButtonContainer = styled(HSpaced)`
  &:empty {
    display: none;
  }
`
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${s.size('l')};
  flex-wrap: wrap;
`
const LeftContainer = styled(VSpaced)`
  flex: 1;
  min-width: 400px;
`
const RightContainer = styled(VSpaced)`
  width: 40%;
  min-width: 400px;
`
