import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { InputProps } from 'antd/es/input'
import React from 'react'
import { useDebounce } from 'use-debounce'

import { useOutlineSearchContext } from './OutlineSearchContext'

const DEBOUNCE = 500
export interface OutlineSearchProps extends Partial<InputProps> {
  courseName?: string
}
export function OutlineSearch({ courseName, ...inputProps }: OutlineSearchProps) {
  const { setSearch } = useOutlineSearchContext()
  const [value, setValue] = React.useState('')
  const [dValue] = useDebounce(value, DEBOUNCE)
  React.useEffect(() => {
    setSearch(dValue)
  }, [dValue])
  return (
    <Input
      placeholder={`Search ${courseName || 'Course'}`}
      suffix={<SearchOutlined />}
      style={{ flex: 1 }}
      value={value}
      onChange={e => setValue(e.target.value)}
      allowClear
      {...inputProps}
    />
  )
}
