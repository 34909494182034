import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_NORMAL } from 'lexical'
import React from 'react'

import { debug } from '../../../log'
import { $createFileNode } from '../../nodes/File/FileNode'
import { DROP_FILE_COMMAND } from '../DraggableBlockPlugin/commands'

export function getFileFiles(files: File[]): File[] {
  return files.filter(file => !file.type.startsWith('image'))
}

export function useDropFile() {
  const [editor] = useLexicalComposerContext()
  React.useEffect(() => {
    return editor.registerCommand(
      DROP_FILE_COMMAND,
      ({ files, nodes }) => {
        const fileFiles = getFileFiles(files)
        if (fileFiles.length > 0) {
          debug('uploading files', fileFiles)
          for (const file of fileFiles) {
            nodes.push(
              $createFileNode({
                uploadFile: {
                  editor,
                  file,
                },
              }),
            )
          }
          return true
        } else return false
      },
      COMMAND_PRIORITY_NORMAL,
    )
  }, [editor])
}
