import { Container } from './Container'
import { Item } from './Item'
import { SegmentedControl } from './SegmentedControl'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './Container'
export * from './Item'
export * from './SegmentedControl'

export default withSubComponents(SegmentedControl, {
  Container,
  Item,
})
