import { MutationConfirmationResource } from '@thesisedu/feature-apollo-react'
import { Modal, Text } from '@thesisedu/ui'
import React from 'react'

import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  feature.resources.addResource<
    MutationConfirmationResource<'clearGradesWhenChangingAssignmentRubric'>
  >({
    type: 'MutationConfirmation',
    input: 'clearGradesWhenChangingAssignmentRubric',
    // ConfirmClearGradesWhenChangingAssignmentRubric
    identifier: 'CONFIRM_CLEAR_GRADES_WHEN_CHANGING_ASSIGNMENT_RUBRIC',
    render({ onConfirm, onCancel }) {
      return (
        <Modal.Confirm
          visible
          onVisibleChange={() => {}}
          title={'Are you sure?'}
          confirm={{ children: 'Yes, change grading mode', status: 'danger' }}
          cancel={{ children: 'No, cancel update' }}
          onConfirm={() => onConfirm(true)}
          onCancel={onCancel}
        >
          <Text bottom weight={'bold'}>
            Changing to / from rubric-based grading will clear all existing grades for this
            assignment.
          </Text>
          <Text bottom>
            Student submissions will <strong>not</strong> be deleted, but you will have to re-grade
            them.
          </Text>
          <Text>Are you sure you want to change the grading mode?</Text>
        </Modal.Confirm>
      )
    },
  })
}
