import { BlockSpin, BodySmall, styled } from '@thesisedu/web'
import { Checkbox } from 'antd'
import React from 'react'

import { MinimalClassClass, useClasses } from './ClassContext'

export interface ClassSelectorListProps {
  value?: string[]
  onChange?: (value: string[]) => void
  /** The IDs of classes you would like to exclude. */
  excludeClasses?: string[]
  /** A more powerful filter. */
  filterClasses?: (cls: MinimalClassClass) => boolean
}
export function ClassSelectorList({
  value,
  onChange,
  excludeClasses,
  filterClasses,
}: ClassSelectorListProps) {
  const { classes: _classes, loading } = useClasses()
  let classes = _classes.filter(
    cls => (!excludeClasses || !excludeClasses.includes(cls.id)) && !cls.archivedAt,
  )
  if (filterClasses) classes = classes.filter(filterClasses)
  if (loading && !classes?.length) {
    return <BlockSpin />
  } else if (classes?.length) {
    return (
      <Container>
        {classes.map(cls => (
          <div key={cls.id}>
            <Checkbox
              checked={value?.includes(cls.id)}
              onChange={e => {
                if (onChange) {
                  const without = value?.filter(v => v !== cls.id) || []
                  onChange(e.target.checked ? [...without, cls.id] : without)
                }
              }}
              children={cls.name}
            />
          </div>
        ))}
      </Container>
    )
  } else {
    return (
      <BodySmall color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
        You have no classes.
      </BodySmall>
    )
  }
}

export interface SingleClassSelectProps extends Omit<ClassSelectorListProps, 'value' | 'onChange'> {
  value?: string
  onChange?: (value?: string) => void
}
export function SingleClassSelect({ value, onChange, ...rest }: SingleClassSelectProps) {
  return (
    <ClassSelectorList
      {...rest}
      value={value ? [value] : undefined}
      onChange={selected => {
        if (onChange) {
          onChange(selected.length ? selected[selected.length - 1] : undefined)
        }
      }}
    />
  )
}

const Container = styled.div`
  > * {
    display: flex;
    align-items: center;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ${props => props.theme['@size-xs']};
    }
  }
`
