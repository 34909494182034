import { ButtonProps } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

import { CreateSubmissionButtonButton } from './CreateSubmissionButtonButton'
import { useCreateNewSubmission } from './useCreateNewSubmission'
import { useAssignmentTimeLeft } from '../questions/useAssignmentTimeLeft'
import { BasicAssignmentSubmissionFragment, useAssignmentRemainingAttemptsQuery } from '../schema'

export interface CreateSubmissionButtonProps extends Omit<ButtonProps, 'onClick' | 'loading'> {
  assignmentId: string
  classId: string
  icon?: React.ReactElement
  title?: string
  onCreated: (submission: BasicAssignmentSubmissionFragment) => void
}
export function CreateSubmissionButton({
  assignmentId,
  classId,
  icon = <Plus />,
  onCreated,
  ...rest
}: CreateSubmissionButtonProps) {
  const { data, loading: remainingLoading } = useAssignmentRemainingAttemptsQuery({
    variables: {
      id: assignmentId,
      classId,
    },
  })
  const assignment = data?.node?.__typename === 'Assignment' ? data.node : undefined
  const remainingAttempts = assignment?.remainingAttempts
  const didSubmit = assignment?.submittedAt
  const timeLeft = useAssignmentTimeLeft(assignment)
  const remaining = remainingAttempts || 0
  const [create, { loading }] = useCreateNewSubmission({ onCreated, classId, assignmentId })
  if (remainingLoading) return null
  return (
    <CreateSubmissionButtonButton
      disabled={
        remaining <= 0 ||
        remainingLoading ||
        (timeLeft !== null && timeLeft < 0) ||
        didSubmit ||
        loading
      }
      icon={icon}
      loading={loading}
      onClick={create}
      title={
        didSubmit
          ? 'Assignment Submitted'
          : timeLeft !== null && timeLeft < 0
          ? 'Out of Time'
          : remaining
          ? 'Create Attempt'
          : 'No Attempts Left'
      }
      subtitle={
        didSubmit
          ? 'No more attempts allowed.'
          : timeLeft !== null && timeLeft < 0
          ? ''
          : `${remaining} remaining`
      }
      {...rest}
    />
  )
}
