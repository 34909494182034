import {
  filterStudents,
  useCachedStudentUserCallback,
  useIsTeacherUser,
} from '@thesisedu/feature-classes-react'
import { Refresh } from '@thesisedu/react/icons'
import { TextField } from '@thesisedu/ui'
import { Search } from '@thesisedu/ui/icons'
import { styled, Body, DATE_FORMATS, HSpaced, VSpaced, Block, Spacer, Space } from '@thesisedu/web'
import { Button, Table } from 'antd'
import moment from 'moment'
import React from 'react'

import { ExportSubmissionsButton } from './ExportSubmissionsButton'
import {
  getGroupName,
  GradeModalSubmissionNavigationItemSubtitle,
} from './GradeModalGroupsNavigation'
import { GradeSummary } from './GradeSummary'
import { getStudentClassTabName } from './GradingModal'
import { ResetSubmissionsButton } from './ResetSubmissionsButton'
import { AssignmentBulkActions } from './bulk_actions/AssignmentBulkActions'
import { FullAssignment } from './types'
import { useGradeDisplayFn } from './useGradeDisplay'
import { useGradingModalVisibilityContext } from '../contexts/GradingModalVisibilityContext'
import { useGradeIncompleteLateAsZero } from '../resources/class_configuration/GradeIncompleteLateAsZero'
import {
  BasicAssignmentSubmissionFragment,
  useAssignmentSubmissionsLazyQuery,
  OrderDirection,
} from '../schema'

export interface AssignedStudentRow {
  submission?: BasicAssignmentSubmissionFragment
  classId: string
  student: {
    id: string
    user: {
      name?: string | null
      firstName?: string | null
      lastName?: string | null
      username: string
    }
  }
}
export interface AssignmentSubmissionsProps {
  assignment: FullAssignment
}
export function AssignmentSubmissions({ assignment }: AssignmentSubmissionsProps) {
  const { setGradingAssignment } = useGradingModalVisibilityContext(true)
  const [selectedStudentIds, setSelectedStudentIds] = React.useState<string[]>([])
  const displayGrade = useGradeDisplayFn()
  const isTeacherUser = useIsTeacherUser()
  const getStudent = useCachedStudentUserCallback()
  const [studentFilter, setStudentFilter] = React.useState('')
  const { canGrade, canReset } = assignment
  let rows: AssignedStudentRow[] = assignment.students.edges
    .map(edge => ({
      submission: (assignment.submissions || assignment.currentSubmissions).edges.find(
        sEdge => sEdge.node.studentId === edge.node.id,
      )?.node,
      student: {
        ...edge.node,
        user: {
          ...edge.node.user,
          ...getStudent(edge.node.id, edge.classId)?.user,
        },
      },
      classId: edge.classId,
    }))
    .filter(row => row.student && !isTeacherUser(row.student.user.id))
  rows = filterStudents(rows, studentFilter, entry => entry.student)
  const useGradeIncompleteAsZero = useGradeIncompleteLateAsZero(rows[0]?.classId)

  const [load, { loading }] = useAssignmentSubmissionsLazyQuery({
    variables: {
      first: 1,
      id: assignment.id,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Asc,
    },
    fetchPolicy: 'network-only',
  })

  const ungradedRows = rows.filter(row => {
    return row.submission?.submittedAt && !row.submission?.gradedAt
  })
  const missingRows = rows.filter(row => !row.submission || !row.submission?.submittedAt)

  return (
    <VSpaced space={'@size-m'} align={'stretch'}>
      <span style={{ alignSelf: 'flex-end' }}>
        <ExportSubmissionsButton rows={rows} />
      </span>
      <GradeSummary assignment={assignment} />
      <Block marginBottom={'@size-xs'}>
        <HSpaced space={'@size-xs'}>
          {canReset || canGrade ? (
            <>
              <AssignmentBulkActions
                selections={rows
                  .filter(row => selectedStudentIds.includes(row.student.id))
                  .map(row => ({
                    submissionId: row.submission?.id,
                    studentId: row.student.id,
                    groupName: getGroupName(assignment, row.student.id),
                    classId: row.classId,
                  }))}
                assignment={assignment}
                totalPoints={assignment.totalPoints}
              />
              <Button
                aria-label={'Refresh submissions'}
                data-testid={'RefreshSubmissions'}
                icon={<Refresh />}
                loading={loading}
                onClick={async () => await load()}
              />
              <Space />
              <Body color={'@text-color-secondary'}>Select</Body>
              <Spacer horizontal={'@size-xxs'} />
              <Button
                type={'link'}
                size={'small'}
                onClick={() => setSelectedStudentIds(rows.map(row => row.student.id))}
              >
                All ({rows.length})
              </Button>
              <Button
                type={'link'}
                size={'small'}
                onClick={() => {
                  setSelectedStudentIds(ungradedRows.map(row => row.student.id))
                }}
              >
                Ungraded ({ungradedRows.length})
              </Button>
              <Button
                type={'link'}
                size={'small'}
                onClick={() => {
                  setSelectedStudentIds(missingRows.map(row => row.student.id))
                }}
              >
                Missing ({missingRows.length})
              </Button>
              <Button type={'link'} size={'small'} onClick={() => setSelectedStudentIds([])}>
                None
              </Button>
            </>
          ) : null}
          <Block marginLeft={canReset || canGrade ? '@size-s' : true}>
            <TextField
              aria-label={'Filter Students'}
              value={studentFilter}
              onChange={value => {
                React.startTransition(() => {
                  setStudentFilter(value)
                })
              }}
              placeholder={'Find a student...'}
              suffix={<Search />}
            />
          </Block>
        </HSpaced>
      </Block>
      <TableContainer>
        <Table<AssignedStudentRow>
          dataSource={rows}
          pagination={false}
          rowKey={row => row.student.id}
          scroll={{
            x: true,
          }}
          rowSelection={
            canReset || canGrade
              ? {
                  selectedRowKeys: selectedStudentIds,
                  onChange: keys => setSelectedStudentIds(keys.map(key => key.toString())),
                }
              : undefined
          }
          rowClassName={record => {
            if (!record.submission?.submittedAt) return 'unsubmitted'
            else if (!record.submission?.reviewed) return 'unreviewed'
            return ''
          }}
          columns={[
            {
              title: 'First Name',
              dataIndex: ['student', 'user', 'firstName'],
              sorter: (a, b) => {
                const aName = a.student.user.firstName || ''
                const bName = b.student.user.firstName || ''
                return aName.localeCompare(bName)
              },
              render: (_, record) => (
                <a
                  href={'#'}
                  onClick={e => {
                    e.preventDefault()
                    setGradingAssignment(assignment, {
                      defaultTab: getStudentClassTabName(record.student.id, record.classId),
                    })
                  }}
                  children={_}
                />
              ),
            },
            {
              title: 'Last Name',
              dataIndex: ['student', 'user', 'lastName'],
              render: (lastName, record) => (
                <a
                  href={'#'}
                  onClick={e => {
                    e.preventDefault()
                    setGradingAssignment(assignment, {
                      defaultTab: getStudentClassTabName(record.student.id, record.classId),
                    })
                  }}
                  children={lastName || record.student.user.username}
                />
              ),
              sorter: (a, b) => {
                const aName = a.student.user.lastName || a.student.user.username
                const bName = b.student.user.lastName || b.student.user.username
                return aName.localeCompare(bName)
              },
            },
            {
              title: 'Group',
              dataIndex: ['student', 'user', 'id'],
              sorter: (a, b) => {
                const aName = getGroupName(assignment, a.student.id) || ''
                const bName = getGroupName(assignment, b.student.id) || ''
                return aName.localeCompare(bName)
              },
              render: (_, record) => {
                return (
                  <GradeModalSubmissionNavigationItemSubtitle
                    assignment={assignment}
                    studentId={record.student.id}
                  />
                )
              },
            },
            {
              title: 'Reviewed',
              dataIndex: ['submission', 'reviewed'],
              render: (_, record) => (
                <span className={'reviewed'}>
                  {record.submission ? (record.submission.reviewed ? 'Yes' : 'Not Reviewed') : ''}
                </span>
              ),
              sorter: (a, b) => {
                const aVal = a.submission?.reviewed ? 1 : 0
                const bVal = b.submission?.reviewed ? 1 : 0
                return aVal - bVal
              },
            },
            {
              title: 'Grade',
              dataIndex: ['submission', 'grade'],
              render: (grade, record) =>
                grade !== null && grade !== undefined
                  ? displayGrade({
                      percentage: grade,
                      totalPoints: assignment.totalPoints,
                      hideSuffix: true,
                    })
                  : useGradeIncompleteAsZero &&
                    assignment.dueAt &&
                    moment(assignment.dueAt).isBefore(moment()) &&
                    !record.submission?.submittedAt
                  ? '0%'
                  : '',
              sorter: (a, b) => {
                const aVal = a.submission?.grade || 0
                const bVal = b.submission?.grade || 0
                return aVal - bVal
              },
            },
            {
              title: 'Submitted',
              dataIndex: ['submission', 'submittedAt'],
              sorter: (a, b) => {
                const aVal = a.submission?.submittedAt || ''
                const bVal = b.submission?.submittedAt || ''
                return aVal.localeCompare(bVal)
              },
              render: (submittedAt, record) => {
                return submittedAt ? (
                  <span>
                    {moment(submittedAt).format(DATE_FORMATS.FULL)}
                    {record.submission?.isLate ? (
                      <Body color={'@red'} style={{ display: 'inline' }}>
                        &nbsp;&nbsp;&nbsp;Late
                      </Body>
                    ) : null}
                  </span>
                ) : (
                  <span className={'unsubmitted'}>Unsubmitted</span>
                )
              },
            },
            {
              title: '',
              dataIndex: ['student', 'id'],
              render: (_, record) => (
                <HSpaced space={'@size-xs'} justify={'flex-end'}>
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => {
                      setGradingAssignment(assignment, {
                        defaultTab: getStudentClassTabName(record.student.id, record.classId),
                      })
                    }}
                  >
                    {record.submission?.reviewed
                      ? assignment.canGrade
                        ? 'Edit Grade'
                        : 'View Grade'
                      : assignment.canGrade
                      ? 'Grade'
                      : 'View'}{' '}
                    &rarr;
                  </Button>
                  {assignment.canReset ? (
                    <ResetSubmissionsButton
                      assignmentId={assignment.id}
                      studentId={record.student.id}
                      type={'link'}
                      size={'small'}
                    />
                  ) : null}
                </HSpaced>
              ),
            },
          ]}
        />
      </TableContainer>
    </VSpaced>
  )
}

const TableContainer = styled.div`
  .ant-table-row.unsubmitted {
    opacity: 0.5;
    background: ${props => props.theme['@gray-1']};
    &:hover > td {
      background: transparent !important;
    }
    span.unsubmitted {
      font-weight: bold;
    }
  }
  .ant-table-row.unreviewed {
    background: ${props => props.theme['@red-light']};
    &:hover > td {
      background: transparent !important;
    }
    span.reviewed,
    a {
      font-weight: bold;
      color: ${props => props.theme['@red']};
    }
  }
`
