import path from 'path'
import React from 'react'

import { ControlledGif, ControlledGifProps } from './ControlledGif'

export interface DraggableImageProps {
  imagePath: string
  title?: string
  width: number
  height: number
  onLoad?: () => void
  gifProps?: Partial<ControlledGifProps>
}
export function DraggableImage({
  title,
  imagePath,
  onLoad,
  width,
  height,
  gifProps,
}: DraggableImageProps) {
  const isGif = path.extname(imagePath).substr(1).toLowerCase() === 'gif'
  if (isGif) {
    return (
      <ControlledGif {...gifProps} src={imagePath} width={width} height={height} onLoad={onLoad} />
    )
  } else {
    return (
      <img
        src={imagePath}
        alt={title}
        onLoad={onLoad}
        style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%' }}
      />
    )
  }
}
