import { styled, Body, Block, FontWeight } from '@thesisedu/web'
import React from 'react'

export interface QuestionExtraProps {
  title: string
}
export function QuestionExtra({ title, children }: React.PropsWithChildren<QuestionExtraProps>) {
  return (
    <Block marginTop={'@size-s'}>
      <Body weight={FontWeight.SemiBold}>{title}</Body>
      <ChildContainer>{children}</ChildContainer>
    </Block>
  )
}

const ChildContainer = styled.div`
  margin-top: ${props => props.theme['@size-xxs']};
  * {
    font-size: ${props => props.theme['@font-size-sm']} !important;
  }
  > :last-child {
    margin-bottom: 0 !important;
  }
`
