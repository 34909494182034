import { ClassSyncProviderResource } from '@thesisedu/feature-class-sync-react'
import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

export interface CleverReactFeatureOptions {
  consumerKey: string
  noSAMLButton?: boolean
}

export class CleverReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-clever-react'
  static path = ''
  static requires: string[] = []
  public options!: CleverReactFeatureOptions

  public reactResources() {
    if (!isNative) {
      require('./hooks/routes').default(this)
    }
    require('./resources/SAMLButton').default(this)
    const { AuthProviderHandler } = require('./web/AuthProviderHandler')
    const { ColorClever } = require('@sammarks/icons')
    this.resources.addResource<AuthProviderHandlerResource>({
      type: 'AuthProviderHandler',
      identifier: 'clever',
      name: 'Clever',
      headerLogoPath: require('../assets/large.svg').default,
      squareLogoPath: require('../assets/icon.svg').default,
      buttonIcon: <ColorClever />,
      render: payload => <AuthProviderHandler {...payload} />,
    })
    this.resources.addResource<ClassSyncProviderResource>({
      type: 'ClassSyncProvider',
      identifier: 'clever',
      logos: {
        horizontal: require('../assets/large.svg').default,
        square: require('../assets/icon.svg').default,
      },
    })
  }
}

export const cleverReactFeature: ReactUse<CleverReactFeatureOptions> = (
  opts: CleverReactFeatureOptions,
) => [CleverReactFeature, opts]
