import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import TextField, * as TextField$ from '../TextField'
import { ArrayTextAreaField, TextAreaField } from '../TextField'

function _FormTextField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<TextField$.TextFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <TextField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormTextField = React.forwardRef(_FormTextField)

function _FormTextAreaField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<TextField$.TextAreaFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <TextAreaField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormTextAreaField = React.forwardRef(_FormTextAreaField)

function _FormArrayTextAreaField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<TextField$.ArrayTextAreaFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <ArrayTextAreaField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormArrayTextAreaField = React.forwardRef(_FormArrayTextAreaField)
