import { LoadingOutlined } from '@ant-design/icons'
import {
  BlockSpin,
  composeRefs,
  H3Alternate,
  LightOverlay,
  styled,
  StyledThemeContext,
  usePlaceholder,
} from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'
import { areEqual, VariableSizeGrid, VariableSizeGridProps } from 'react-window'
import { keyframes } from 'styled-components'

import { GridContext, InnerGridContext } from './GridContext'
import { getItem, getSectionHeaderForRow, getTotalRows } from './sections'
import { BaseGridItem, GridSection, InnerGridProps } from './types'
import { DefaultGridPlaceholder } from './web/DefaultPlaceholder'
import { ReactWindowScroller } from './web/WindowScroller'
import { getItemWidthAndColumns } from './widths'
import { debug, error } from '../log'

const HEADER_CELL_HEIGHT = 20 + 30 + 20 // 20 addl header padding, 30 height of text, 20 underneath.
const COLUMN_WIDTH_SIZE = '@size-l'
export function InnerGrid<GridItem extends BaseGridItem>({
  width,
  itemHeight,
}: InnerGridProps<GridItem>) {
  const theme = React.useContext(StyledThemeContext)
  const { items, loadingFresh, empty } = React.useContext(GridContext)
  const gutter = parseFloat(theme[COLUMN_WIDTH_SIZE].replace('px', ''))
  const { itemWidth, numColumns } = getItemWidthAndColumns(gutter, width || 0)
  const gridRef = React.useRef<VariableSizeGrid>(null)
  React.useEffect(() => {
    if (gridRef.current) {
      debug('resetting grid heights')
      gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      })
    }
  }, [width, itemHeight])

  if (loadingFresh) {
    return <BlockSpin />
  } else if (!items.length && empty) {
    return empty
  } else if (!items.length) {
    return <Empty description={'No content found!'} />
  } else {
    return (
      <ReactWindowScroller<VariableSizeGridProps> isGrid>
        {({ ref, outerRef, style, onScroll }) => {
          const numRows = getTotalRows(numColumns, items)
          return (
            <InnerGridContext.Provider value={{ numColumns, itemWidth, gutter }}>
              <VariableSizeGrid
                columnCount={numColumns}
                columnWidth={columnIndex => {
                  if (columnIndex === 0) return itemWidth
                  else return itemWidth + gutter
                }}
                rowCount={numRows}
                rowHeight={rowIndex => {
                  if (getSectionHeaderForRow(rowIndex, numColumns, items)) {
                    return HEADER_CELL_HEIGHT
                  } else {
                    return Array(numColumns)
                      .fill('')
                      .reduce((acc, _, columnIndex) => {
                        const item = getItem(rowIndex, columnIndex, numColumns, items)
                        return item ? Math.max(acc, itemHeight(itemWidth, item)) : acc
                      }, 0)
                  }
                }}
                height={window.innerHeight}
                width={width || 0}
                outerRef={outerRef}
                ref={composeRefs(ref, gridRef)}
                onScroll={onScroll}
                style={style}
                children={GridCell}
              />
            </InnerGridContext.Provider>
          )
        }}
      </ReactWindowScroller>
    )
  }
}

interface GridCellProps {
  style?: any
  columnIndex: number
  rowIndex: number
}
const GridCell = React.memo((props: GridCellProps) => {
  const { items } = React.useContext(GridContext)
  const { numColumns } = React.useContext(InnerGridContext)
  const headerForSection = getSectionHeaderForRow(props.rowIndex, numColumns, items)
  if (headerForSection) {
    if (props.columnIndex === 0) {
      return <GridHeaderCell section={headerForSection} style={props.style} />
    } else return null
  } else {
    return <GridItemCell {...props} />
  }
}, areEqual)
GridCell.displayName = 'GridCell'

interface GridHeaderCellProps {
  section: GridSection<BaseGridItem>
  style?: any
}
function GridHeaderCell({ section, style }: GridHeaderCellProps) {
  return (
    <HeaderCellContainer style={style}>
      <H3Alternate>{section.title}</H3Alternate>
    </HeaderCellContainer>
  )
}
const HeaderCellContainer = styled.div`
  width: 100% !important;
  padding-top: ${props => props.theme['@size-xm']};
  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

function GridItemCell({ rowIndex, columnIndex, style }: GridCellProps) {
  const {
    items,
    renderItem: ItemComponent,
    placeholder = <DefaultGridPlaceholder />,
    onClick,
  } = React.useContext(GridContext)
  const { numColumns, gutter } = React.useContext(InnerGridContext)
  const item = getItem(rowIndex, columnIndex, numColumns, items)
  return usePlaceholder({
    placeholder: (
      <CellContainer gutter={gutter} style={style} index={columnIndex}>
        {placeholder}
      </CellContainer>
    ),
    getChildren: React.useCallback(() => {
      if (item) {
        return (
          <CellContainer gutter={gutter} style={style} index={columnIndex}>
            <CellClickWrapper
              onClick={
                onClick
                  ? async () => {
                      await onClick(item)
                    }
                  : undefined
              }
            >
              <ItemComponent item={item} />
            </CellClickWrapper>
          </CellContainer>
        )
      } else {
        return <CellContainer gutter={gutter} style={style} index={columnIndex} />
      }
    }, [columnIndex, rowIndex, item, style]),
  })
}

const CellContainer = styled.div<{ index: number; gutter: number }>`
  padding-left: ${props => (props.index === 0 ? 0 : props.gutter)}px;
`

interface CellClickWrapperProps {
  onClick?: () => Promise<void> | void
  children: React.ReactElement
}
function CellClickWrapper({ onClick, children }: CellClickWrapperProps) {
  const [loading, setLoading] = React.useState(false)
  return (
    <div
      style={{ position: 'relative' }}
      onClick={
        !loading && onClick
          ? async () => {
              setLoading(true)
              try {
                await onClick()
              } catch (err) {
                error('error in onClick handler for grid item')
                error(err)
              } finally {
                setLoading(false)
              }
            }
          : undefined
      }
    >
      {children}
      {loading ? (
        <LoadingOverlay>
          <LoadingOutlined />
        </LoadingOverlay>
      ) : null}
    </div>
  )
}
const loadingEnter = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const LoadingOverlay = styled(LightOverlay)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme['@size-xl']};
  border-radius: ${props => props.theme['@border-radius-base']};
  animation: ${loadingEnter} 0.25s linear;
`
