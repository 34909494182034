import {
  Bold,
  Code,
  Italic,
  Linear,
  NavArrowDown,
  NavArrowUp,
  Underline,
  Edit,
} from '@thesisedu/react/icons'
import React from 'react'

import { useBasicTextFormatToolbarItem } from '../../ui/TextFormatToolbar/BasicTextFormatToolbarButton'

export function useRichTextToolbarItems() {
  useBasicTextFormatToolbarItem({
    weight: 0,
    group: 'basic',
    command: 'bold',
    ariaLabel: 'Format as bold',
    label: 'Bold',
    icon: <Bold />,
  })
  useBasicTextFormatToolbarItem({
    weight: 1,
    group: 'basic',
    command: 'italic',
    ariaLabel: 'Format as italic',
    label: 'Italic',
    icon: <Italic />,
  })
  useBasicTextFormatToolbarItem({
    weight: 2,
    group: 'basic',
    command: 'underline',
    ariaLabel: 'Format as underline',
    label: 'Underline',
    icon: <Underline />,
  })
  useBasicTextFormatToolbarItem({
    weight: 3,
    group: 'basic',
    command: 'strikethrough',
    ariaLabel: 'Format as strikethrough',
    label: 'Strikethrough',
    icon: <Linear />,
  })
  useBasicTextFormatToolbarItem({
    weight: 3.4,
    group: 'basic',
    command: 'highlight',
    ariaLabel: 'Format as highlight',
    label: 'Highlight',
    icon: <Edit />,
  })
  useBasicTextFormatToolbarItem({
    weight: 3.5,
    group: 'code',
    command: 'code',
    ariaLabel: 'Format as code',
    label: 'Code',
    icon: <Code />,
  })
  useBasicTextFormatToolbarItem({
    weight: 4,
    group: 'supersub',
    command: 'superscript',
    ariaLabel: 'Format as superscript',
    label: 'Superscript',
    icon: <NavArrowUp />,
  })
  useBasicTextFormatToolbarItem({
    weight: 5,
    group: 'supersub',
    command: 'subscript',
    ariaLabel: 'Format as subscript',
    label: 'Subscript',
    icon: <NavArrowDown />,
  })
}
