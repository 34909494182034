import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { copyToClipboard } from '@thesisedu/react'
import { Dropdown, useToast } from '@thesisedu/ui'
import { ShareIos } from '@thesisedu/ui/icons'
import React from 'react'

import { NON_GROUP_TYPES } from '../../constants'
import { SegmentType } from '../../schema'
import { SegmentActionComponentProps, SegmentActionResource } from '../../types'

function CopyLink({ segment }: SegmentActionComponentProps) {
  const cls = useSelectedClass(true)
  const toast = useToast()
  return (
    <Dropdown.Item
      onClick={async () => {
        const result = await copyToClipboard(
          `${window.location.origin}/segment/${cls.id}/${segment.id}`,
        )
        if (result) {
          toast({ title: 'Link copied to clipboard!', status: 'success' })
        } else {
          toast({ title: "Couldn't copy link to clipboard.", status: 'error' })
        }
      }}
      icon={<ShareIos />}
      children={'Copy Link'}
    />
  )
}

export const CopyLinkAction: SegmentActionResource = {
  identifier: 'copyLink',
  type: 'SegmentAction',
  component: CopyLink,
  group: 'Sharing',
  weight: 300,
  filter: segment => {
    return NON_GROUP_TYPES.includes(segment.type as SegmentType)
  },
  handler: () => {},
}
