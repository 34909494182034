import { SimpleWidget, useNodeState } from '@thesisedu/feature-widgets-react'
import React from 'react'

import { SheetMusicConfig } from './common'
import { SheetMusicEditor } from '../../../editor/SheetMusicEditor'
import { SafeSheetMusicViewer } from '../../../viewer/SafeSheetMusicViewer'

export function SheetMusicElement({
  readOnly,
  editor,
  node,
}: SimpleWidget.SimpleWidgetProps<SheetMusicConfig>) {
  const [config, setConfig] = useNodeState(
    editor,
    node,
    node => node.getConfig(),
    (node, config) => node.setConfig(config),
  )

  let isGeneratingPdf = false
  try {
    const { useIsGeneratingPdf } = require('@thesisedu/feature-pdf-generation-react')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useIsGeneratingPdf()) {
      isGeneratingPdf = true
    }
  } catch {
    // Do nothing...
  }

  if (!config) return null
  if (readOnly) {
    return (
      <SafeSheetMusicViewer
        {...config}
        playbackContainerProps={{
          ...config.playbackContainerProps,
          hideControls: isGeneratingPdf || config.playbackContainerProps?.hideControls,
        }}
      />
    )
  } else {
    return (
      <SheetMusicEditor
        value={config}
        onChange={newConfig =>
          setConfig({
            ...config,
            ...newConfig,
          })
        }
      />
    )
  }
}
