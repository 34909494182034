import { MutateHook } from '@thesisedu/feature'
import { AssignmentConfiguration } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { AssignmentSettingsProps } from './AssignmentSettings'
import { AssignmentSyncsProps } from './AssignmentSyncs'
import { GradingModalNavigationItemProps } from './GradingModalNavigation'
import {
  AssignmentAssignedStudentsFragment,
  AssignmentSubmissionsFragment,
  AssignmentWithConfigurationFragment,
  BasicAssignmentFragment,
  AssignmentWithGroupsFragment,
  AssignmentWithRemainingAttemptsFragment,
  AssignmentStudentAssignmentMode,
  AssignmentRubricInput,
  AssignInput,
  RevealAnswers,
} from '../schema'

export type FullAssignment = BasicAssignmentFragment &
  AssignmentSubmissionsFragment &
  AssignmentAssignedStudentsFragment &
  Omit<AssignmentWithConfigurationFragment, 'configuration'> &
  AssignmentWithGroupsFragment &
  AssignmentWithRemainingAttemptsFragment & {
    configuration?: AssignmentConfiguration | null
  }
export type AssignmentWithSubmissions = BasicAssignmentFragment & AssignmentSubmissionsFragment
export type ViewingAssignment = BasicAssignmentFragment &
  AssignmentAssignedStudentsFragment &
  Omit<AssignmentWithConfigurationFragment, 'configuration'> & {
    configuration?: AssignmentConfiguration | null
  }
export function isFullAssignment(
  assignment: BasicAssignmentFragment | FullAssignment,
): assignment is FullAssignment {
  const configuration = (assignment as FullAssignment).configuration
  const hasConfiguration = configuration === null || !!configuration
  const groups = (assignment as FullAssignment).groups
  const hasGroups = groups === null || !!groups
  return !!(assignment as FullAssignment).submissions && hasConfiguration && hasGroups
}
export function isAssignmentWithSubmissions(
  assignment: BasicAssignmentFragment | AssignmentWithSubmissions,
): assignment is AssignmentWithSubmissions {
  return !!(assignment as AssignmentWithSubmissions).submissions
}
export interface NavigationItemProps {
  assignment: FullAssignment
}
export interface NavigationItem {
  key: string
  label: string
  icon: React.ReactElement
  skipItem?: boolean
  renderNavigationItem?: (props: GradingModalNavigationItemProps) => React.ReactElement | null
  render: (props: NavigationItemProps) => React.ReactElement | null
}

export type MutateAssignmentFields = MutateHook<
  'feature-assignments-react:assignment-fields',
  React.ReactElement[],
  { assignment: FullAssignment; renderingFlags: boolean }
>

export interface AssignmentSettingsOptions {
  hideAssignment?: boolean
  hideName?: boolean
  hideDescription?: boolean
  hideRevealAnswers?: boolean
  hideRandomizeQuestions?: boolean
  hideMaxAttempts?: boolean
  hideTimeLimit?: boolean
  assignmentSyncsProps?: Partial<AssignmentSyncsProps>
  questionSettingsTab?: string
  Submit?: ((props: AssignmentSettingsSubmitProps) => React.ReactElement) | null
  OpensAt?: (() => React.ReactElement) | null
  SettingsContent?: ((props: AssignmentSettingsProps) => React.ReactElement) | null
}

export interface AssignmentSettingsSubmitProps {
  children: React.ReactNode
  button: React.ReactElement
}
export interface SettingsFormValues {
  name: string
  dueAt?: string | moment.Moment | null
  openAt?: string | moment.Moment | null
  assignmentMode: AssignmentStudentAssignmentMode
  description?: string | null
  assignmentCategoryIds?: string[]
  totalPoints: number
  excluded?: boolean | null
  rubric?: AssignmentRubricInput | null
  assign?: AssignInput
  revealAnswers?: RevealAnswers | null
  revealAttempts?: number | null
  [key: string]: any
}
