import { BodyLarge, Block } from '@thesisedu/react'
import React from 'react'

export function Welcome() {
  return (
    <>
      <BodyLarge isBlock>We're thrilled you're here. 😊</BodyLarge>
      <BodyLarge>
        Your 30-day free trial has begun. You have full access to all the standards-aligned courses
        and lessons, including video content, activities, assessments, and digital interactivity
        tools. You can easily import students and start using Prodigies Academy in your classroom
        immediately.
      </BodyLarge>
      <Block marginTop={'@size-m'}>
        <BodyLarge>
          <strong>To get started,</strong> create a password to go along with your email to login{' '}
          <strong>or</strong> you can login using Google.
        </BodyLarge>
      </Block>
    </>
  )
}
