import { PageHead } from '@thesisedu/react'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'

import { CommentModeration } from '../../moderation/CommentModeration'

export function Comments() {
  useBreadcrumb({ title: 'Comments' })
  return (
    <>
      <PageHead title={'Comments'} />
      <PageHeader title={'Comments'} />
      <CommentModeration />
    </>
  )
}
