import { DistrictFragment } from '@thesisedu/feature-districts-web/dist/schema'
import { isEqual } from '@thesisedu/feature-utils'
import { omitDeep } from '@thesisedu/react'
import { styled, s, Tooltip, Button } from '@thesisedu/ui'
import { Undo } from '@thesisedu/ui/icons'
import Form, { FormItemProps } from 'antd/lib/form'
import { get } from 'lodash'
import React from 'react'

import { DistrictConfiguration } from './schema'

export interface SiteDistrictConfigurationFieldProps extends FormItemProps {
  district?: DistrictFragment
}
export function SiteDistrictConfigurationField({
  district,
  name,
  children,
  ...rest
}: SiteDistrictConfigurationFieldProps) {
  if (!Array.isArray(name)) throw new Error('name must be an array of fields')
  return (
    <div style={{ position: 'relative' }}>
      <Form.Item name={name} {...rest}>
        <_InnerSiteDistrictConfigurationField
          name={name as string[]}
          children={children as React.ReactElement}
          district={district}
        />
      </Form.Item>
    </div>
  )
}

interface _InnerSiteDistrictConfigurationFieldProps {
  children: React.ReactElement
  district?: DistrictFragment
  name: string[]
  value?: any
  onChange?: (value: any) => void
}
function _InnerSiteDistrictConfigurationField({
  name,
  children,
  district,
  value,
  onChange,
}: _InnerSiteDistrictConfigurationFieldProps) {
  const [isCustom, setIsCustom] = React.useState(false)
  const districtConfiguration = district?.configuration as DistrictConfiguration | null | undefined
  let districtConfigurationValue = get(
    districtConfiguration?.defaultSiteConfiguration,
    name[0] === 'configuration' ? name.slice(1) : name, // Remove the first "configuration" entry,
  )
  if (
    districtConfigurationValue &&
    (typeof districtConfigurationValue === 'object' || Array.isArray(districtConfigurationValue))
  ) {
    districtConfigurationValue = omitDeep(districtConfigurationValue, ['__typename'])
  }
  React.useEffect(() => {
    if (districtConfigurationValue !== null && districtConfigurationValue !== undefined) {
      setIsCustom(!isEqual(districtConfigurationValue, omitDeep(value, ['__typename'])))
    } else {
      setIsCustom(false)
    }
  }, [value, district])

  return (
    <>
      {isCustom ? (
        <>
          <CustomIndicator />
          <CustomIndicatorButtonContainer>
            <Tooltip
              title={
                'This value has been customized from the district default value. Click here to reset it back to the district default.'
              }
            >
              <Button
                variant={'chromeless'}
                size={'small'}
                icon={<Undo />}
                children={'Reset'}
                onPress={() => {
                  onChange?.(districtConfigurationValue)
                }}
              />
            </Tooltip>
          </CustomIndicatorButtonContainer>
        </>
      ) : null}
      {React.cloneElement(children, {
        value,
        onChange,
      })}
    </>
  )
}

const CustomIndicator = styled.div`
  position: absolute;
  z-index: -1;
  inset: calc(-1 * ${s.size('xs')});
  top: -40px;
  padding: ${s.size('xs')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border-radius: ${s.var('radii.1')};
  background: ${s.color('primary.element')};
`
const CustomIndicatorButtonContainer = styled.div`
  position: absolute;
  top: calc(${s.size('xs')} - 40px);
  right: ${s.size('xs')};
`
