import type { Transformer } from '@lexical/markdown'
import {
  registerMarkdownShortcuts,
  TEXT_FORMAT_TRANSFORMERS,
  TEXT_MATCH_TRANSFORMERS,
  HEADING,
  QUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
} from '@lexical/markdown'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React from 'react'

const DEFAULT_TRANSFORMERS = [
  ...TEXT_FORMAT_TRANSFORMERS,
  ...TEXT_MATCH_TRANSFORMERS,
  HEADING,
  QUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
]

export interface MarkdownShortcutPluginProps {
  transformers?: Transformer[]
}
export function MarkdownShortcutPlugin({
  transformers = DEFAULT_TRANSFORMERS,
}: MarkdownShortcutPluginProps) {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    return registerMarkdownShortcuts(editor, transformers)
  }, [editor, transformers])

  return null
}
