import React from 'react'
import { Route } from 'react-router-dom'

import { ClassSettingsRoute } from './ClassSettingsRoute'
import { StudentsApprovalRoute } from './StudentsApprovalRoute'
import { StudentsRoute } from './StudentsRoute'
import ClassesReactFeature from '../../ClassesReactFeature'
import { ClassRoutesHook } from '../../types'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls, group }) => {
      let result
      if (group === 'TEACHER') {
        result = [
          ...routes,
          <Route
            key={'approval'}
            path={'students/requests/:state'}
            element={<StudentsApprovalRoute />}
          />,
          <Route key={'approval'} path={'students/requests'} element={<StudentsApprovalRoute />} />,
          <Route key={'students'} path={'students'} element={<StudentsRoute />} />,
        ]
      } else {
        result = routes
      }
      if (cls.canUpdateSettings) {
        return [
          ...result,
          <Route key={'settings'} path={'settings/*'} element={<ClassSettingsRoute />} />,
        ]
      } else {
        return result
      }
    },
  )
}
