import { PluginLevel } from '@thesisedu/feature-widgets-react'
import { Row, HTMLOrMarkdown } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { QuestionInstructions } from '../QuestionInstructions'
import { LegacyEditorWrapper } from '../blocks'

export function MediumFormAnswer(props: QuestionProps) {
  const data = useSubmissionData(props.question.id)
  return (
    <Row>
      {data ? (
        <Col xs={24} md={props.question.config?.answer ? 12 : 24}>
          <QuestionInstructions instructions={'Type your answer in the box below.'} padded>
            <LegacyEditorWrapper disabled value={data} level={PluginLevel.Simple} minHeight={100} />
          </QuestionInstructions>
        </Col>
      ) : null}
      {props.question.config?.answer ? (
        <Col xs={24} md={data ? 12 : 24}>
          <QuestionInstructions instructions={'Sample Answer'} padded>
            <HTMLOrMarkdown children={props.question.config.answer} />
          </QuestionInstructions>
        </Col>
      ) : null}
    </Row>
  )
}
