import { CustomGroup } from '@thesisedu/feature-sheet-music-core'
import React from 'react'

import { StaffContainer } from './styled'
import { SafeSheetMusicViewer } from '../../../viewer/SafeSheetMusicViewer'

const BAR_WIDTH = 300
export interface CustomGroupStaffProps {
  customGroup: CustomGroup
}
export function CustomGroupStaff({ customGroup }: CustomGroupStaffProps) {
  return (
    <StaffContainer
      style={{
        width:
          BAR_WIDTH *
          customGroup.abc
            .split('|')
            .map(s => s.trim())
            .filter(Boolean).length,
      }}
    >
      <SafeSheetMusicViewer
        abc={`${customGroup.abc
          .split('|')
          .map(i => i.trim())
          .filter(Boolean)
          .join('|')}|`}
        noClef
        playbackContainerProps={{ hideControls: true }}
      />
    </StaffContainer>
  )
}
