import { styled, s } from '@thesisedu/ui'
import { AddUser, Check, MinusCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassSyncRosterActionMode } from '../../schema'

export enum ListItemStatus {
  Matched = ClassSyncRosterActionMode.Match,
  Unmatched = 'unmatched',
  Ignore = 'ignore',
  Import = ClassSyncRosterActionMode.Import,
  ToRemove = ClassSyncRosterActionMode.Remove,
}
const StatusColors: Record<ListItemStatus, s.ColorAlias> = {
  [ListItemStatus.Matched]: 'blue.element',
  [ListItemStatus.Unmatched]: 'gray.element',
  [ListItemStatus.Ignore]: 'gray.element',
  [ListItemStatus.Import]: 'green.element',
  [ListItemStatus.ToRemove]: 'red.element',
}
const StatusIcons: Record<ListItemStatus, React.ReactElement> = {
  [ListItemStatus.Matched]: <Check />,
  [ListItemStatus.Unmatched]: <MinusCircle />,
  [ListItemStatus.Ignore]: <MinusCircle />,
  [ListItemStatus.Import]: <AddUser />,
  [ListItemStatus.ToRemove]: <MinusCircle />,
}
const StatusLabels: Record<ListItemStatus, string> = {
  [ListItemStatus.Matched]: 'Matched',
  [ListItemStatus.Unmatched]: 'Unmatched',
  [ListItemStatus.Ignore]: 'Ignore',
  [ListItemStatus.Import]: 'Import',
  [ListItemStatus.ToRemove]: 'To Remove',
}

export interface RosteringListItemStatusProps {
  status: ListItemStatus
}
export function RosteringListItemStatus({ status }: RosteringListItemStatusProps) {
  return (
    <Container $status={status}>
      {StatusIcons[status]}
      <span>{StatusLabels[status]}</span>
    </Container>
  )
}

const Container = styled.div<{ $status: ListItemStatus }>`
  border-radius: ${s.var('radii.1')};
  background: ${props => s.color(StatusColors[props.$status])};
  color: ${props => s.color(StatusColors[props.$status].split('.')[0] as s.ColorAlias)};
  display: flex;
  align-items: center;
  gap: ${s.size('xxs')};
  font-size: ${s.size('font.xs')};
  white-space: nowrap;
  padding: 0 ${s.size('xxs')};
`
