import { Body, VSpaced } from '@thesisedu/web'
import { InputNumber } from 'antd'
import React from 'react'

const DEBOUNCE = 1000
export interface BPMFieldProps {
  bpm: number
  onChange: (bpm: number) => void
}
export function BPMField({ bpm, onChange }: BPMFieldProps) {
  const timeoutRef = React.useRef<any>()
  const [value, setValue] = React.useState(bpm)
  React.useEffect(() => {
    setValue(bpm)
  }, [bpm])
  return (
    <VSpaced space={'@size-xs'} align={'stretch'}>
      <Body>BPM</Body>
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        value={value}
        onChange={v => {
          setValue(v || 0)
          clearTimeout(timeoutRef.current)
          timeoutRef.current = setTimeout(() => {
            onChange(v || 0)
          }, DEBOUNCE)
        }}
      />
    </VSpaced>
  )
}
