import { useMutateHook } from '@thesisedu/feature-react'
import { Button, PageHead } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import { PageHeader } from '@thesisedu/web'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { JoinClass } from './JoinClass'
import { ListClassesContentHook } from '../../../types'

export interface ClassesRootProps {
  group: string
}
export function ClassesRoot({ group }: ClassesRootProps) {
  const navigate = useNavigate()
  const content = useMutateHook<ListClassesContentHook>('classes-react:list-classes', [], { group })
  let actions: React.ReactElement | null = null
  switch (group) {
    case 'TEACHER':
      actions = (
        <Button
          type={'primary'}
          icon={<Plus />}
          onClick={() => navigate('/teacher/classes/create')}
        >
          Add Class
        </Button>
      )
      break
    case 'STUDENT':
      actions = <JoinClass />
  }
  return (
    <>
      <PageHead title={'Classes'} />
      <PageHeader title={'Classes'}>{actions}</PageHeader>
      {content}
    </>
  )
}
