import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React from 'react'

import { useCourseViewerModalContext } from '../contexts/CourseViewerModalContext'

export interface SegmentNavigate {
  id: string
  defaultTab?: string
}
export function useSegmentNavigate({ id, defaultTab }: SegmentNavigate) {
  const { setSegmentId } = useCourseViewerModalContext(true)
  return () => {
    setSegmentId(id, defaultTab)
  }
}

export interface SegmentLinkProps extends SegmentNavigate {
  className?: string
  onClick?: () => void
}
export function SegmentLink({
  children,
  className,
  onClick,
  ...navigateProps
}: React.PropsWithChildren<SegmentLinkProps>) {
  const nav = useSegmentNavigate(navigateProps)
  return (
    <a
      href={`?viewSegment=${navigateProps.id}`}
      data-testid={`SegmentLink ${navigateProps.id}`}
      onClick={e => {
        e.preventDefault()
        if (onClick) {
          onClick()
        }
        nav()
      }}
      className={className}
      children={children}
    />
  )
}

export type SegmentButtonProps = SegmentLinkProps & {
  buttonProps?: Partial<ButtonProps>
}
export function SegmentButton({
  children,
  className,
  buttonProps,
  onClick,
  ...navigateProps
}: React.PropsWithChildren<SegmentButtonProps>) {
  const nav = useSegmentNavigate(navigateProps)
  return (
    <Button
      className={className}
      children={children}
      {...buttonProps}
      onClick={e => {
        e.preventDefault()
        if (onClick) {
          onClick()
        }
        if (buttonProps?.onClick) {
          buttonProps.onClick(e)
        }
        nav()
      }}
    />
  )
}
