import { Button, ButtonProps, DropdownIcon } from '@thesisedu/react'
import { Dropdown } from '@thesisedu/ui'
import React from 'react'

import { ResetSubmissionsDropdownItems } from './ResetSubmissionsDropdownItems'

export interface ResetSubmissionsButtonProps extends Partial<ButtonProps> {
  assignmentId: string
  studentId: string
  classId?: string
}
export function ResetSubmissionsButton({
  assignmentId,
  studentId,
  classId,
  ...buttonProps
}: ResetSubmissionsButtonProps) {
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <Button danger {...buttonProps} data-testid={'ResetSubmissionsButton'}>
          {buttonProps.children === undefined ? 'Reset' : buttonProps.children} <DropdownIcon />
        </Button>
      </Dropdown.ManualTrigger>
      <Dropdown.Menu side={'bottom'} align={'end'}>
        <ResetSubmissionsDropdownItems
          assignmentId={assignmentId}
          classId={classId}
          studentId={studentId}
        />
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
