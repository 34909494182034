import * as ReactDropdown from '@radix-ui/react-dropdown-menu'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'

import { useDropdownContext } from './DropdownContext'
import { ItemStyles } from './itemStyles'
import { styled, s } from '../../'
import { Check } from '../../../icons'
import { withSubComponents } from '../../utils/withSubComponents'
import { LoadingIndicator } from '../LoadingIndicator'
import * as TextField$ from '../TextField'

export interface DropdownMenuItemContentProps {
  icon?: React.ReactElement
  loading?: boolean
  children?: React.ReactNode
}
export function DropdownMenuItemContent({ icon, loading, children }: DropdownMenuItemContentProps) {
  return (
    <>
      {loading ? (
        <_IconContainer children={<LoadingIndicator />} />
      ) : icon ? (
        <_IconContainer children={icon} />
      ) : null}
      {children}
    </>
  )
}

export interface CoreDropdownMenuItemProps {
  danger?: boolean
}
export interface DropdownMenuItemProps
  extends ReactDropdown.MenuItemProps,
    DropdownMenuItemContentProps,
    CoreDropdownMenuItemProps {
  /**
   * This is used in cases where you are rendering an overlay mounted inside the React tree
   * at the same level as the dropdown item. Simply pass your `visible` state to this prop,
   * and the dropdown will remain mounted when clicked on so your overlay renders, and will
   * automatically close / unmount when your overlay closes.
   *
   * See the `ModalInsideDropdown` story for an example.
   */
  forceMount?: boolean
}
function _DropdownMenuItem({
  icon,
  danger,
  loading,
  children,
  forceMount,
  onClick,
  ...rest
}: DropdownMenuItemProps) {
  const { setForceMount } = useDropdownContext(true)
  // Close the dropdown whenever forceMount is changed to false.
  const previousForceMount = React.useRef<boolean | undefined>(undefined)
  React.useEffect(() => {
    if (previousForceMount.current && forceMount === false) {
      setForceMount(false)
    }
    previousForceMount.current = forceMount
  }, [!!forceMount])
  return (
    <__DropdownMenuItem
      disabled={loading}
      {...rest}
      data-danger={danger}
      onClick={e => {
        if (forceMount != null) {
          setForceMount(true)
          onClick?.(e)
        } else {
          onClick?.(e)
        }
      }}
    >
      <DropdownMenuItemContent icon={icon} loading={loading} children={children} />
    </__DropdownMenuItem>
  )
}

export interface DropdownCheckboxMenuItemProps
  extends ReactDropdown.MenuCheckboxItemProps,
    CoreDropdownMenuItemProps {
  loading?: boolean
}
function Checkbox({ children, danger, loading, ...props }: DropdownCheckboxMenuItemProps) {
  return (
    <_CheckboxItem {...props} data-danger={danger} disabled={loading || props.disabled}>
      {loading ? (
        <_IconContainer children={<LoadingIndicator />} />
      ) : (
        <DropdownMenuItemIndicator>
          <Check />
        </DropdownMenuItemIndicator>
      )}
      {children}
    </_CheckboxItem>
  )
}

export interface DropdownRadioMenuItemProps extends ReactDropdown.MenuRadioItemProps {}
function Radio({ children, ...props }: DropdownRadioMenuItemProps) {
  return (
    <_RadioItem {...props}>
      <DropdownMenuItemIndicator>&bull;</DropdownMenuItemIndicator>
      {children}
    </_RadioItem>
  )
}

const TEXT_NON_IGNORE_KEYS = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape', 'Tab']
export interface DropdownTextFieldMenuItemProps extends TextField$.TextFieldProps {}
function _TextField(
  { value, onChange, placeholder, size = 'small', ...field }: DropdownTextFieldMenuItemProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const simpleProps = { value, onChange, placeholder, size }
  const inputRef = useObjectRef(ref)
  return (
    <ReactDropdown.DropdownMenuItem
      onFocusCapture={e => {
        e.stopPropagation()
        e.preventDefault()
        inputRef.current.focus()
      }}
      onClickCapture={e => {
        e.stopPropagation()
      }}
    >
      <DropdownTextField
        {...field}
        {...simpleProps}
        ref={inputRef}
        onKeyDown={e => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setTimeout(() => {
              const parent = inputRef.current.parentElement?.parentElement?.parentElement
              const element =
                e.key === 'ArrowDown' ? parent?.nextElementSibling : parent?.previousElementSibling
              if (element) {
                ;(element as HTMLElement).focus()
              }
            })
          } else if (TEXT_NON_IGNORE_KEYS.includes(e.key)) {
            e.continuePropagation()
          }
          field?.onKeyDown?.(e)
        }}
      />
    </ReactDropdown.DropdownMenuItem>
  )
}
const TextField = React.forwardRef(_TextField)
const DropdownTextField = styled(TextField$.TextField)`
  margin-bottom: ${s.size('xxs')};
`

const { css } = s
const __DropdownMenuItem = styled(ReactDropdown.Item)`
  ${ItemStyles}
`
const _CheckboxItem = styled(ReactDropdown.CheckboxItem)`
  ${ItemStyles}
`
const _RadioItem = styled(ReactDropdown.RadioItem)`
  ${ItemStyles}
`
export const IndicatorStyle = css`
  position: absolute;
  left: 0;
  width: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
const _IconContainer = styled.div`
  ${IndicatorStyle}
`
const DropdownMenuItemIndicator = styled(ReactDropdown.ItemIndicator)`
  ${IndicatorStyle}
`

export const LabelStyles = css`
  line-height: calc(${s.var('size.0.9')} * 2);
  font-size: ${s.var('size.0.75')};
  color: ${s.color('gray.secondary')};
  padding-left: calc(${s.var('size.0.9')} * 2);
  &:not(:first-child) {
    padding-top: ${s.size('s')};
  }
`
export const SeparatorStyles = css`
  height: 1px;
  background: ${s.color('gray.subtleBorder')};
  margin: ${s.var('size.0.5')};
`
export const RightStyles = css`
  margin-left: auto;
  padding-left: ${s.var('size.1')};
  color: var(--menu-item-color, ${s.color('secondary')});
  + div {
    margin-left: ${s.size('xs')};
    padding-left: 0;
  }
`
export const DropdownMenuItem = withSubComponents(_DropdownMenuItem, {
  Checkbox,
  Radio,
  TextField,
  RadioGroup: ReactDropdown.RadioGroup,
  Right: styled.div`
    ${RightStyles}
  `,
  Indicator: DropdownMenuItemIndicator,
  Separator: styled(ReactDropdown.Separator)`
    ${SeparatorStyles}
  `,
  Label: styled(ReactDropdown.Label)`
    ${LabelStyles}
  `,
})
