export const getErrorCode = (name: string): string => name

export class ExpectedError extends Error {
  public expectedErrorCode: string

  constructor(message: string) {
    super(`${message} (ExpectedError)`)
    this.expectedErrorCode = getErrorCode(this.constructor.name)
  }
}

export function isExpectedError(error: any): error is ExpectedError
export function isExpectedError<T extends typeof ExpectedError>(
  error: any,
  constraint: T,
): error is T
export function isExpectedError(error: any, constraint?: typeof ExpectedError) {
  if (constraint) {
    return (error as ExpectedError).expectedErrorCode === getErrorCode(constraint.name)
  } else {
    return !!(error as ExpectedError).expectedErrorCode
  }
}

export class NotFoundError extends ExpectedError {}
export class AccessDeniedError extends ExpectedError {}
