import { toGlobalId } from '@thesisedu/feature-utils'
import React from 'react'

import { PlacementDrawer } from './PlacementDrawer'
import { PlacementDrawerContext, PlacingSegment } from './PlacementDrawerContext'

export interface PlacementDrawerProviderProps {
  children: React.ReactNode
}
export function PlacementDrawerProvider({ children }: PlacementDrawerProviderProps) {
  const [placing, setPlacing] = React.useState<PlacingSegment | undefined>(undefined)
  return (
    <>
      {placing ? (
        <PlacementDrawer
          visible
          onVisibleChange={v => {
            if (!v) setPlacing(undefined)
          }}
          classId={placing.classId}
          globalSegmentId={toGlobalId('Segment', placing.rawSegmentId)}
        />
      ) : null}
      <PlacementDrawerContext.Provider
        value={React.useMemo(() => ({ setPlacing }), [setPlacing])}
        children={children}
      />
    </>
  )
}
