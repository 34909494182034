import { updateMutation, onMutationError } from '@thesisedu/feature-apollo-react'

import { AnnouncementFragmentDoc, useUpdateAnnouncementMutation as useMutation } from '../schema'

export const useUpdateAnnouncementMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that announcement.'),
    ...opts,
    update: updateMutation({
      fragment: AnnouncementFragmentDoc,
      fragmentName: 'Announcement',
      path: 'updateAnnouncement.announcement',
    }),
  })
