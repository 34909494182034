import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import {
  ApolloReactHooks,
  ExpectedErrorContext,
  ExpectedErrorPayload,
} from '@thesisedu/feature-apollo-react'
import { ReactFeature } from '@thesisedu/feature-react'

export interface TrialsWebFeatureOptions {
  welcomeComponent: () => () => React.ReactElement | null
  signupAuthProviders: string[]
}
const DEFAULT_OPTIONS: TrialsWebFeatureOptions = {
  signupAuthProviders: [],
  welcomeComponent() {
    return () => null
  },
}

export class TrialsWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-trials-web'
  static path = ''
  static requires: string[] = []
  public options!: TrialsWebFeatureOptions

  constructor(options: Partial<TrialsWebFeatureOptions>, deps: FeatureDependencies) {
    super(
      {
        ...DEFAULT_OPTIONS,
        ...options,
      },
      deps,
    )
    this.hookManager.registerMutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
      ApolloReactHooks.ExpectedError,
      (err, context) => {
        const { Modal } = require('antd')
        if (context?.code === 'CANNOT_LOGIN_WITH_TRIAL_EMAIL') {
          Modal.warning({
            title: "We haven't approved your trial request yet.",
            content:
              "You've already requested a trial with that email address that hasn't been approved yet. " +
              'Check back later, or reach out to support to check on the status of your trial ' +
              'request approval.',
            width: 500,
          })
        }
        return err
      },
    )
  }

  public reactResources() {
    require('./hooks/routes').default(this)
  }
}

export const trialsWebFeature = (options: TrialsWebFeatureOptions): FeatureUse => [
  TrialsWebFeature,
  options,
]
