import React from 'react'

import { SplitViewDraggableChange } from './SplitViewDraggableChange'
import { useSplitViewSegment } from './SplitViewOutlineItemContext'
import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { useDragDropOutlineContext } from '../outline/DragDropOutlineContext'
import {
  DraggableOutlineItem,
  DraggableOutlineItemProps,
} from '../outline/items/DraggableOutlineItem'

export interface SplitViewDraggableOutlineItemProps extends DraggableOutlineItemProps {}
export function SplitViewDraggableOutlineItem(props: SplitViewDraggableOutlineItemProps) {
  const { draggingId } = useDragDropOutlineContext(false) ?? {}
  const { addedSegmentIds, changedSegmentIds } = useSplitViewPendingChangesContext(false) ?? {}
  const hasSegmentId =
    addedSegmentIds?.includes(props.item.id) || changedSegmentIds?.includes(props.item.id)
  const draggingSegment = useSplitViewSegment(draggingId)
  return (
    <DraggableOutlineItem
      // This has to be here because otherwise if you drag an item where dropping
      // is disabled (dragging from an explore course to your class), and then you
      // cancel the drag, this item will not be draggable until it re-renders.
      // We change the key here so we can force this component to re-render, and
      // "reset" the state of the draggable. We also have to change the key whenever
      // this segment is or is not included in the list of changes, because otherwise
      // if you drag it over, then undo, it won't be draggable again.
      key={
        hasSegmentId
          ? 'is-changed'
          : draggingId === props.item.id
          ? 'is-dragging'
          : 'is-not-dragging'
      }
      draggingSegment={draggingSegment}
      children={
        props.droppableDisabled ? undefined : <SplitViewDraggableChange id={props.item.id} />
      }
      {...props}
    />
  )
}
