import {
  isMatchClientConfig,
  MatchClientConfig,
  MatchConfig,
  matchToClientConfig,
} from '@thesisedu/feature-assignments-core'
import { Form } from '@thesisedu/react'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps, IsQuestionComplete } from '../../../types'
import { useAssignmentSeed } from '../../../useAssignmentSeed'
import { DragDropDropdownField } from '../DragDropDropdownField'
import { DragDropField, DragDropDropdownValidator } from '../DragDropField'

export function MatchContent(props: QuestionProps<MatchClientConfig>) {
  const submissionData = useSubmissionData(props.question.id)
  const seed = useAssignmentSeed(props.question.id)
  const disabled = props.disabled
  const useDropDown = props.question.config?.useDropdown
  const config = props.question.config
  if (!config) return null
  const clientConfig = isMatchClientConfig(config) ? config : matchToClientConfig(config, seed)
  if (!clientConfig) return null
  const { sources, destinations } = clientConfig
  return (
    <Form.Item
      name={props.question.id}
      initialValue={submissionData}
      // eslint-disable-next-line react/jsx-no-bind
      validate={DragDropDropdownValidator.bind(null, sources, destinations)}
    >
      {useDropDown ? (
        <DragDropDropdownField
          labelField={'destination'}
          sources={sources}
          destinations={destinations}
          disabled={disabled}
        />
      ) : (
        <DragDropField sources={sources} destinations={destinations} disabled={disabled} />
      )}
    </Form.Item>
  )
}

export const isQuestionComplete: IsQuestionComplete<MatchClientConfig | MatchConfig> = (
  question,
  answer,
) => {
  const config = question.config
  if (!config) return false
  const clientConfig = isMatchClientConfig(config) ? config : matchToClientConfig(config, false)
  const greaterLength = Math.max(
    clientConfig.destinations.length || -Infinity,
    clientConfig.sources.length || -Infinity,
  )
  return greaterLength !== -Infinity ? answer?.length === greaterLength : false
}
