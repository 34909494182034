import { Segment } from '@thesisedu/feature-course-types'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { WebContext } from '@thesisedu/feature-web'
import React, { useContext } from 'react'

import { useCourseContext } from '../contexts/CourseContext'
import { SegmentDecorationsContext } from '../contexts/SegmentDecorationsContext'
import {
  CoursesHooks,
  SegmentProgress,
  SegmentProgressContext,
  SegmentProgressPayload,
} from '../types'

export function useSegmentProgressCallback() {
  const decorations = React.useContext(SegmentDecorationsContext).segments
  const viewer = useViewerContext(false)
  const { featureWeb } = useContext(WebContext)!
  const { segmentMetadata } = useCourseContext(false) || {}
  return (segment: Segment) => {
    const segmentId = segment.id
    if (segmentMetadata) {
      if (segmentMetadata?.segments?.some(s => s.id === segmentId && s.forceReportAsViewed)) {
        return SegmentProgress.COMPLETE
      }
    } else if (segment.forceReportAsViewed) {
      return SegmentProgress.COMPLETE
    }
    const decoration = decorations[segmentId]
    return featureWeb.deps.hookManager.mutateHookSync<
      SegmentProgressPayload,
      SegmentProgressContext
    >(
      CoursesHooks.SegmentProgress,
      (decoration?.isComplete && viewer?.group === 'STUDENT') ||
        (decoration?.isViewed && viewer?.group === 'STUDENT' && segment.type === 'Section')
        ? SegmentProgress.COMPLETE
        : decoration?.isViewed && viewer?.group === 'STUDENT'
        ? SegmentProgress.IN_PROGRESS
        : SegmentProgress.NONE,
      { segmentId },
    )
  }
}

export function useSegmentProgress(segment: Segment): SegmentProgress {
  return useSegmentProgressCallback()(segment)
}
