import { onMutationError } from '@thesisedu/feature-apollo-react'
import {
  AssignmentConfiguration,
  AssignmentOverrides,
  GroupsConfiguration,
  SubmissionType,
} from '@thesisedu/feature-assignments-core'
import { useUserStudentQuery } from '@thesisedu/feature-students-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { AntIconWrapper, message, Modal } from '@thesisedu/react'
import { WarningCircle } from '@thesisedu/react/icons'
import React from 'react'

import { clearAnswers, saveAnswers } from './answerStore'
import { useManageAttemptsContextValue } from './useManageAttemptsContextValue'
import { useRetryAssignment } from './useRetryAssignment'
import { useAssignmentViewerContext } from '../contexts/AssignmentViewerContext'
import { usePartialSubmissionContext } from '../contexts/PartialSubmissionContext'
import { SubmissionContextValue } from '../contexts/SubmissionContext'
import { FullAssignment } from '../grading/types'
import { getAssignmentSubmission } from '../helpers'
import { debug } from '../log'
import { useAssignmentQueryVariables } from '../queries/useAssignmentQueryVariables'
import { useSaveAssignmentSubmissionMutation } from '../queries/useSaveAssignmentSubmissionMutation'
import { useSubmitAssignmentMutation } from '../queries/useSubmitAssignmentMutation'
import {
  FullAssignmentSubmissionFragment,
  SaveAssignmentSubmissionInput,
  SubmitAssignmentInput,
  useAssignmentSubmissionConfigurationQuery,
} from '../schema'

/** Debounce for saving changes to the database. 15 seconds. */
const DEBOUNCE = 15000

/**
 * Use this below when saving so we don't save values to LocalStorage during the "jitter"
 * period where the fields are updating their calculated values.
 *
 * This allows fields to make adjustments to the values being passed and save those back
 * without immediately modifying the data stored locally.
 *
 * This should also be taken into account when determining dirty status.
 */
function useHasFullyMounted() {
  const hasFullyMounted = React.useRef(false)
  React.useEffect(() => {
    const handler = setTimeout(() => {
      hasFullyMounted.current = true
    }, 1000)
    return () => clearTimeout(handler)
  }, [])

  return hasFullyMounted
}

interface SubmissionConfigurationResult {
  loading?: boolean
  configuration?: AssignmentConfiguration | null
  overrides?: AssignmentOverrides | null
}
function useSubmissionConfiguration(
  assignmentId: string,
  submissionId?: string,
): SubmissionConfigurationResult {
  const hadSubmissionId = React.useRef(!!submissionId)
  React.useEffect(() => {
    // Reset this whenever we change assignments.
    hadSubmissionId.current = !!submissionId
  }, [assignmentId])
  const { data, loading } = useAssignmentSubmissionConfigurationQuery({
    variables: { submissionId: submissionId || '' },
    skip: !submissionId,
  })

  const submission = data?.node?.__typename === 'AssignmentSubmission' ? data.node : undefined

  return {
    // We don't want to go back to the loading state if we save an assignment for the first time
    // and therefore have a submission. The configuration should, in theory, be the same so no
    // need to re-fetch it. This subsequent un-mount / re-mount process was causing answers to
    // disappear.
    loading: hadSubmissionId.current ? loading : false,
    configuration: submission?.assignmentConfiguration,
    overrides: submission?.overrides,
  }
}

export function useGroupsContextValue(
  assignment: FullAssignment,
  studentId?: string,
): SubmissionContextValue['groups'] {
  const viewer = useViewerContext(false)
  const groups: GroupsConfiguration =
    assignment.submissionType !== SubmissionType.Individual ? assignment.groups || {} : {}
  const studentGroupKey = studentId
    ? Object.keys(groups).find(groupKey => {
        return groups[groupKey].students.includes(studentId)
      })
    : undefined
  const groupLeader = studentGroupKey ? groups[studentGroupKey].leader : undefined
  // If the user is not a student (aka, viewing the content inside "Student View"), then mark them
  // as the group leader.
  const isGroupLeader =
    viewer?.group === 'STUDENT' ? (studentId ? groupLeader === studentId : false) : true
  const areAnswersShared = assignment.submissionType === SubmissionType.Group
  return {
    groupMemberIds: studentGroupKey ? groups[studentGroupKey].students : [],
    groupLeader,
    isGroupLeader,
    areAnswersShared,
  }
}

export interface UseSubmissionContextValueOpts {
  assignment: FullAssignment
  configuration?: AssignmentConfiguration
  submission?: FullAssignmentSubmissionFragment
  disableAutoSave?: boolean
  /** Called whenever the first submission is created for an assignment. */
  onFirstSubmissionCreated?: (submission: FullAssignmentSubmissionFragment) => void
}
export interface UseSubmissionContextValueResult {
  loadingConfiguration?: boolean
  assignmentConfiguration?: AssignmentConfiguration
  contextValue: SubmissionContextValue
  /** True if the submission has been changed. */
  isDirty: boolean
  /** True if the submission has been started, but not submitted. */
  submittedDirty: boolean
}
export function useSubmissionContextValue({
  assignment,
  submission: _submission,
  configuration: _configuration,
  disableAutoSave,
  onFirstSubmissionCreated,
}: UseSubmissionContextValueOpts): UseSubmissionContextValueResult {
  const existing = usePartialSubmissionContext(false)
  const [debouncedValues, setDebouncedValues] =
    React.useState<SaveAssignmentSubmissionInput | null>(null)
  const inputRef = React.useRef<SaveAssignmentSubmissionInput | null>(null)
  const [isDirty, setIsDirty] = React.useState(false)
  const [submittedDirty, setSubmittedDirty] = React.useState(false)
  const hasFullyMounted = useHasFullyMounted()
  const submissionId = _submission?.id
  const debounceRef = React.useRef<any | null>(null)
  const cancelValuesDebounce = () => {
    if (debounceRef.current) {
      clearInterval(debounceRef.current)
      debounceRef.current = null
    }
  }
  const { classId, configuration } = useAssignmentViewerContext(true)
  const viewer = useViewerContext(true)
  const studentQuery = useUserStudentQuery()
  const studentId = studentQuery.data?.viewer?.student?.id
  const submission = _submission
    ? _submission
    : viewer?.group === 'STUDENT'
    ? getAssignmentSubmission(assignment, classId)
    : null
  const groups = useGroupsContextValue(assignment, studentId)
  const manageAttempts = useManageAttemptsContextValue({
    assignment,
    submission: submission ?? undefined,
  })

  // We fetch the customized submission configuration separately from the rest of
  // the queries, and only for students, because theoretically this could return a
  // lot of data. So we don't want to fetch it until we need it, which is now.
  //
  // We also don't want to fetch the submission configuration if we have already provided
  // it above.
  const submissionConfiguration = useSubmissionConfiguration(
    assignment.id,
    _configuration ? undefined : viewer?.group !== 'STUDENT' ? undefined : submission?.id,
  )
  const assignmentConfiguration =
    submissionConfiguration.configuration || _configuration || configuration

  const [setRetrySubmission, promptRetry, { loading: retryLoading }] = useRetryAssignment({
    assignmentId: assignment.id,
    assignment,
    classId,
    studentId,
    submission: submission ?? undefined,
    onSubmissionCreated: existing?.onSubmissionCreated,
  })
  const [saveSubmission, { loading: saveLoading }] = useSaveAssignmentSubmissionMutation({
    onCompleted: result => {
      setIsDirty(false)
      const studentId = result.saveAssignmentSubmission.assignmentSubmission.studentId
      clearAnswers(studentId, classId, assignment.id, submissionId)
    },
  })
  const submitAssignmentVariables = useAssignmentQueryVariables(classId)
  const submitInputRef = React.useRef<SubmitAssignmentInput | null>(null)
  const [submit, { loading: submitLoading }] = useSubmitAssignmentMutation({
    onError: onMutationError(
      'There was an error submitting your assignment.',
      ['ASSIGNMENT_INCOMPLETE_ERROR'],
      (code, error) => {
        // This only really happens if the auto-grading code comes back and says the
        // assignment is incomplete, when the client thought the assignment was complete.
        if (submitInputRef.current && code === 'ASSIGNMENT_INCOMPLETE_ERROR') {
          Modal.confirm({
            title: 'Are you ready to submit your assignment?',
            content: (
              <>
                <p>Once you submit your assignment, you will no longer be able to edit it.</p>
                <p>
                  <strong>It also looks like you have not completed this assignment.&nbsp;</strong>
                  <span>
                    You are still able to submit the assignment, but you won't get credit for
                    questions you have left blank.
                  </span>
                </p>
              </>
            ),
            icon: <AntIconWrapper children={<WarningCircle />} />,
            okText: 'Yes, submit assignment.',
            okType: 'primary',
            okButtonProps: { danger: true },
            cancelText: 'No, go back.',
            width: 500,
            onOk: async () => {
              await submit({
                variables: {
                  ...submitAssignmentVariables,
                  classId,
                  input: {
                    ...submitInputRef.current!,
                    force: true,
                  },
                },
              })
            },
          })
        }
      },
    ),
    onCompleted: data => {
      message.success('Assignment submitted successfully!')
      if (studentId) {
        clearAnswers(studentId, classId, assignment.id, submissionId)
      }
      setIsDirty(false)
      setSubmittedDirty(false)
      setRetrySubmission(data.submitAssignment.assignmentSubmission)
    },
  })

  const _onSaveSubmission = async (
    _input: SaveAssignmentSubmissionInput,
    includeConfiguration = false,
  ) => {
    cancelValuesDebounce()
    if (submission?.submittedAt) return
    const input = {
      ...inputRef.current,
      ..._input,
    }
    if (submissionId) {
      input.updateId = submissionId
    }
    const localStudentId = studentId || input.studentId
    if (localStudentId) {
      saveAnswers(localStudentId, classId, assignment.id, submissionId, input.submissionData)
    }
    const result = await saveSubmission({ variables: { classId, input, includeConfiguration } })

    const resultSubmission = result?.data?.saveAssignmentSubmission.assignmentSubmission
    if (resultSubmission && !submission) {
      debug('first submission %s created for assignment', resultSubmission.id)
      onFirstSubmissionCreated?.(resultSubmission)
    }

    return result?.data?.saveAssignmentSubmission.assignmentSubmission.id
  }

  // Whenever the debounced value changes, let's save the submission.
  React.useEffect(() => {
    if (debouncedValues && !disableAutoSave) {
      _onSaveSubmission(debouncedValues)
    }
  }, [debouncedValues])

  return {
    loadingConfiguration: submissionConfiguration.loading,
    assignmentConfiguration,
    isDirty,
    submittedDirty,
    contextValue: {
      ...existing,
      assignment,
      overrides: submissionConfiguration.overrides,
      cancelValuesDebounce,
      classId,
      submission: submission || undefined,
      submissionData: submission?.submissionData,
      questionMetadata: submission?.questionMetadata,
      studentId: viewer?.group === 'STUDENT' ? studentId : undefined,
      groups,
      onRetry: promptRetry,
      onSaveSubmission: _onSaveSubmission,
      loading: submitLoading || saveLoading || retryLoading,
      onSubmit: input => {
        cancelValuesDebounce()
        submitInputRef.current = input
        return submit({ variables: { ...submitAssignmentVariables, classId, input } })
      },
      onFieldsChange: (input, setImmediately) => {
        cancelValuesDebounce()
        inputRef.current = {
          ...inputRef.current,
          ...input,
        }
        if (hasFullyMounted.current) {
          setSubmittedDirty(true)
          setIsDirty(true)
          if (setImmediately) {
            setDebouncedValues(input)
          } else if (studentId) {
            // Save the answers to localStorage immediately, save it to the server after a debounce.
            saveAnswers(studentId, classId, assignment.id, submissionId, input.submissionData)
            debounceRef.current = setTimeout(() => {
              setDebouncedValues(input)
            }, DEBOUNCE)
          }
        }
      },
      ...manageAttempts,
    },
  }
}
