import {
  IVariableSizeList,
  VariableSizeList,
  VariableSizeListProps,
} from '@thesisedu/react-virtual'
import React from 'react'

import { OutlineListContextProvider } from './OutlineListContext'
import { OutlineRow } from './OutlineRow'
import { useOutlineSearchContext } from './OutlineSearchContext'
import { OutlineCommonProps } from './OutlineTerm'
import { OutlineItem as OutlineItemType, getOutlineItemHeight } from './types'
import { useOutlineListRef } from './useOutlineListRef'
import composeRefs from '../composeRefs'
import { GroupSegment } from '../types'

export interface OutlineListImperativeHandle {
  highlightSegment: (segmentId: string) => void
}

export interface OutlineListProps extends Omit<OutlineCommonProps, 'segments'> {
  items: OutlineItemType[]
  listProps?: Partial<VariableSizeListProps>
  forceRenderIds?: string[]
  selectedTerm: GroupSegment
  height: number
  className?: string
}
function _OutlineList(
  {
    items: _items,
    listProps,
    segmentHeight,
    forceRenderIds,
    selectedTerm,
    height,
    className,
  }: OutlineListProps,
  ref: React.ForwardedRef<OutlineListImperativeHandle>,
) {
  const { currentSegmentIds } = useOutlineSearchContext()
  const items = React.useMemo(() => {
    return currentSegmentIds === undefined
      ? _items
      : _items.filter(i => currentSegmentIds.some(currentId => i.id.endsWith(currentId)))
  }, [currentSegmentIds?.length, _items])
  const listRef = React.useRef<IVariableSizeList>(null)
  const forceRenderIndexes = forceRenderIds
    ? forceRenderIds.map(forceRenderId => {
        return items.findIndex(item => item.id === forceRenderId)
      })
    : undefined
  React.useEffect(() => {
    listRef.current?.resetAfterIndex(0, true)
  }, [items, forceRenderIds])
  const { highlightedItemIds } = useOutlineListRef(ref, { items, listRef, selectedTerm })

  return (
    <OutlineListContextProvider
      items={items}
      selectedTerm={selectedTerm}
      highlightedItemIds={highlightedItemIds}
    >
      <>
        <VariableSizeList
          className={className}
          itemCount={items.length}
          itemData={items}
          itemKey={(index, data) => data[index].id}
          height={height}
          width={400}
          itemSize={index => {
            return getOutlineItemHeight(items[index], index, segmentHeight)
          }}
          {...listProps}
          ref={composeRefs(listRef, listProps?.ref) as any}
          forceRenderIndexes={forceRenderIndexes}
        >
          {OutlineRow}
        </VariableSizeList>
      </>
    </OutlineListContextProvider>
  )
}
export const OutlineList = React.memo(React.forwardRef(_OutlineList), (prevProps, nextProps) => {
  return (
    prevProps.items.map(i => i.id).join(',') === nextProps.items.map(i => i.id).join(',') &&
    prevProps.forceRenderIds?.join(',') === nextProps.forceRenderIds?.join(',') &&
    prevProps.height === nextProps.height
  )
})
