import React from 'react'

import { s, Block$, Block, Text, HSpaced } from '../..'

export interface WellProps extends Omit<Block$.BlockProps, 'title'> {
  title?: string | React.ReactElement
  actions?: React.ReactElement
  status?: s.SharedVariants.Status.Type
}
function _Well(
  { title, actions, children, status, className, ...rest }: WellProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <Container {...rest} ref={ref} className={s.variants(className, {}, status)}>
      {title || actions ? (
        <HSpaced bottom={'s'}>
          {title ? <Title children={title} /> : null}
          {actions ? <Actions children={actions} /> : null}
        </HSpaced>
      ) : null}
      {children}
    </Container>
  )
}
export const Well = React.forwardRef(_Well)

export interface TitleProps {
  children: string | React.ReactElement
}
export function Title({ children }: TitleProps) {
  return (
    <Text
      weight={'bold'}
      as={'div'}
      style={{ flex: 1, color: `var(--status-color, ${s.color('textEmphasis')})` }}
    >
      {children}
    </Text>
  )
}

export interface ActionsProps {
  children: React.ReactNode
}
export function Actions({ children }: ActionsProps) {
  return (
    <Block left>
      <s.StyleProvider size={'small'} children={children} />
    </Block>
  )
}

const { css } = s
const Container = s.styledWithVariants(
  Block,
  'WellContainer',
  css`
    border: solid 1px var(--status-color, ${s.color('border')});
    border-radius: ${s.var('radii.2')};
    padding: ${s.size('s')};
  `,
  {
    ...s.SharedVariants.Status.Variants,
  },
)
