import {
  $createListNode,
  $createListItemNode,
  $isListItemNode,
  ListType,
  $isListNode,
} from '@lexical/list'
import { ListPlugin as LexicalListPlugin } from '@lexical/react/LexicalListPlugin'
import { List, NumberedListLeft } from '@thesisedu/ui/icons'
import { ElementNode } from 'lexical'
import React from 'react'

import { useListSettings } from './settings'
import { useElementsMenuItem, ElementsGroup } from '../../ui/ElementsMenu/ElementsMenuContext'

function commitList(element: ElementNode, type: ListType) {
  if (!$isListItemNode(element)) {
    const listItemNode = $createListItemNode()
    listItemNode.append(...element.getChildren())
    const listNode = $createListNode(type)
    listNode.append(listItemNode)
    const result = element.replace(listNode, false)
    listItemNode.select()
    return result
  } else if ($isListItemNode(element)) {
    const parent = element.getParent()
    if ($isListNode(parent)) {
      parent.replace($createListNode(type), true)
      element.select()
    }
  }
}

export function ListPlugin() {
  useElementsMenuItem({
    identifier: 'bulleted-list',
    weight: 2,
    group: ElementsGroup.Text,
    title: 'Bulleted List',
    icon: <List />,
    isSelected(element) {
      if (!$isListItemNode(element)) return false
      const parent = element.getParent()
      return $isListNode(parent) && parent.getListType() === 'bullet'
    },
    onCommit(element) {
      return commitList(element, 'bullet')
    },
  })
  useElementsMenuItem({
    identifier: 'numbered-list',
    weight: 3,
    title: 'Numbered List',
    group: ElementsGroup.Text,
    icon: <NumberedListLeft />,
    isSelected(element) {
      if (!$isListItemNode(element)) return false
      const parent = element.getParent()
      return $isListNode(parent) && parent.getListType() === 'number'
    },
    onCommit(element) {
      return commitList(element, 'number')
    },
  })

  return (
    <>
      {useListSettings()}
      <LexicalListPlugin />
    </>
  )
}
