import { onMutationError, useImpersonation } from '@thesisedu/feature-apollo-react'
import { LoadingIndicator, styled } from '@thesisedu/web'
import { Modal } from 'antd'
import React from 'react'

import { useLoginAsStudentMutation } from '../schema'

export const useLoginAsStudent = () => {
  const { impersonate } = useImpersonation()
  const modalRef = React.useRef<{ destroy: () => void } | undefined>(undefined)
  const [loginAsStudent, { loading }] = useLoginAsStudentMutation({
    onCompleted: async data => {
      await impersonate(data.loginAsStudent.jwt)
      modalRef.current?.destroy()
    },
    onError: (...args) => {
      modalRef.current?.destroy()
      onMutationError('There was an error logging in as your student.')(...args)
    },
  })

  return {
    loginAsStudent: (async (...args) => {
      modalRef.current = Modal.info({
        content: (
          <Container>
            <LoadingIndicator size={32} />
            <p>Logging in as your student...</p>
          </Container>
        ),
        okButtonProps: { style: { display: 'none' } },
        icon: null,
        closable: false,
      })
      return loginAsStudent(...args)
    }) as typeof loginAsStudent,
    loading,
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > :not(:last-child) {
    margin-bottom: 16px; // We don't have access to the theme inside the modal.
  }
  > p {
    font-size: 20px;
    text-align: center;
  }
`
