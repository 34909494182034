import { MutateSyncAssignmentRefetchQueries } from '@thesisedu/feature-class-sync-react'

import { AssignmentsReactFeature } from '../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<MutateSyncAssignmentRefetchQueries>(
    'class-sync-react:sync-assignment-refetch',
    async queries => {
      return [...queries, 'assignment']
    },
  )
}
