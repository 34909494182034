import { HSpaced, Text } from '@thesisedu/ui'
import { Circle, CheckCircle } from '@thesisedu/ui/icons'
import React from 'react'

export interface SetupNavigationItemProps {
  title: string
  description: string
  index: number
  activeIndex?: number
}
export function SetupNavigationItem({
  title,
  description,
  index,
  activeIndex,
}: SetupNavigationItemProps) {
  const state =
    activeIndex && activeIndex > index ? 'complete' : activeIndex === index ? 'active' : undefined
  const textColor = state === 'complete' ? 'green' : state === 'active' ? 'primary' : 'text'
  return (
    <HSpaced space={'xs'} align={'flex-start'}>
      <Text level={'s'} color={textColor} top={'xxs'}>
        {state === 'complete' ? <CheckCircle /> : <Circle />}
      </Text>
      <div>
        <Text level={'s'} color={textColor}>
          {index}. {title}
        </Text>
        <Text level={'xs'} color={'secondary'}>
          {description}
        </Text>
      </div>
    </HSpaced>
  )
}
