import { useToast, Modal, Text, LoadingIndicator, Block, Button, VSpaced } from '@thesisedu/ui'
import { ArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { SyncAssignmentItem } from './SyncAssignmentItem'
import { useSyncAssignmentDevtools } from './useSyncAssignmentDevtools'
import { SetupModal } from '../setup/SetupModal'
import { SyncAssignmentActionsProps } from '../types'
import { useSyncAssignmentActions } from '../useSyncAssignmentActions'

export interface SyncAssignmentProps extends SyncAssignmentActionsProps {}
export function SyncAssignment(props: SyncAssignmentProps) {
  const [setupVisible, setSetupVisible] = React.useState(false)
  const toast = useToast()
  const [modalContent, setModalContent] = React.useState<string | null>(null)
  const { onItemSelected, items, loadingSyncs, loadingId } = useSyncAssignmentActions({
    ...props,
    onError: (msg, prominent) => {
      if (prominent) {
        setModalContent(msg)
      } else {
        toast({ title: msg, status: 'error' })
      }
    },
    onSuccess(message) {
      toast({ title: message, status: 'success' })
    },
  })
  const classSyncIds = [...new Set(items.map(i => i.syncId))]
  useSyncAssignmentDevtools(
    items.length && classSyncIds?.length
      ? {
          classSyncIds,
          syncIds: items.filter(i => i.sync).map(i => i.sync!.id),
        }
      : null,
  )

  return (
    <>
      <SetupModal visible={setupVisible} onVisibleChange={setSetupVisible} />
      <Modal
        title={'Cannot sync content.'}
        visible={!!modalContent}
        onVisibleChange={v => {
          if (!v) setModalContent(null)
        }}
      >
        <Text>{modalContent}</Text>
        <Modal.Footer>
          <Modal.CloseButton />
        </Modal.Footer>
      </Modal>
      {loadingSyncs ? (
        <Block style={{ textAlign: 'center' }} bottom={'s'}>
          <LoadingIndicator.InlineLabeled label={'Loading syncs...'} />
        </Block>
      ) : null}
      {items.length ? (
        <VSpaced>
          {items.map(item => {
            return (
              <SyncAssignmentItem
                key={item.syncId}
                item={item}
                onSelected={() => onItemSelected(item.syncId)}
                loading={loadingId === item.syncId}
                disabled={props.disabled}
              />
            )
          })}
        </VSpaced>
      ) : !loadingSyncs ? (
        <div>
          <Text bottom>You don't have any LMS connections yet.</Text>
          <Text level={'s'} color={'secondary'} bottom={'xs'}>
            Use your LMS integrations to roster students, sync assignments, transfer grades, and
            more.
          </Text>
          <Button
            variant={['chromeless', 'primary']}
            size={'small'}
            onPress={() => setSetupVisible(true)}
          >
            Attach a Class from your LMS <ArrowRight />
          </Button>
        </div>
      ) : null}
    </>
  )
}
