import { AppWindow } from '@thesisedu/ui/icons'
import React from 'react'

import { MediaSourceFooterButton } from './SelectMediaSourceFooterButton'
import { Indicator } from '../../selectDevice/styled'

export interface ScreenRecordingFooterButtonProps {
  value?: boolean
  onChange?: (value?: boolean) => void
  disabled?: boolean
}
export function ScreenRecordingFooterButton({
  value,
  onChange,
  disabled,
}: ScreenRecordingFooterButtonProps) {
  return (
    <MediaSourceFooterButton
      disabled={disabled}
      onPress={() => {
        onChange?.(!value)
      }}
    >
      <AppWindow />
      <Indicator $color={disabled || !value ? 'red' : 'green'} />
    </MediaSourceFooterButton>
  )
}
