import { Button, Checkbox, Tooltip, VSpaced, BodySmall } from '@thesisedu/react'
import { Settings } from '@thesisedu/react/icons'
import { Popover } from 'antd'
import React from 'react'

import { useHeaderTagVisibility } from './useHeaderTagVisibility'

export function AssignmentTableSettings() {
  const [headerTagVisibility, setHeaderTagVisibility] = useHeaderTagVisibility()
  return (
    <VSpaced space={'@size-xs'} align={'flex-start'}>
      <Checkbox
        checked={headerTagVisibility}
        onChange={e => {
          setHeaderTagVisibility(e.target.checked)
        }}
      >
        Show Assignment Tags
      </Checkbox>
      <BodySmall color={'@text-color-secondary'}>
        Assignment tags allow you to quickly view more information about your assignments, like due
        dates, sync status, and more.
      </BodySmall>
    </VSpaced>
  )
}

export function AssignmentTableSettingsButton() {
  return (
    <Popover
      placement={'bottomRight'}
      trigger={['click']}
      overlayStyle={{ width: 300 }}
      content={<AssignmentTableSettings />}
    >
      <Tooltip title={'Grades Table Settings'}>
        <Button icon={<Settings />} />
      </Tooltip>
    </Popover>
  )
}
