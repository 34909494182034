import ApolloReactFeature, { MutateCompletedMutation } from '@thesisedu/feature-apollo-react'
import { ReactFeature, ReactHooks, ReactUse } from '@thesisedu/feature-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

import { getAuthenticatedUser } from './getAuthenticatedUser'
import { debug } from './log'
import { AuthWrapperProps } from './web/routes/AuthWrapper'

export interface UsersReactFeatureOptions {
  getAuthWrapper?: () => (props: AuthWrapperProps) => React.ReactElement
}

export class UsersReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-users-react'
  static path = ''
  static requires: string[] = []
  public options!: UsersReactFeatureOptions

  public getAuthenticatedUser = getAuthenticatedUser

  public reactResources() {
    if (!isNative) {
      require('./hooks/routes').default(this)
      require('./hooks/shell').default(this)
      require('./hooks/NotFoundPage').default(this)
      require('./resources/UserSettingsPage/General').default(this)
      require('./resources/UserSettingsPage/ConnectedAccounts').default(this)
    }

    require('./hooks/expectedError').default(this)

    const { UserBackgroundJobWatcher } = require('./background/UserBackgroundJobWatcher')
    const { ProviderAuthContextProvider } = require('./saml/ProviderAuthContext')
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => (
      <ProviderAuthContextProvider>
        <UserBackgroundJobWatcher children={children} />
      </ProviderAuthContextProvider>
    ))

    let refetchTimeout: any
    this.hookManager.registerMutateHook<MutateCompletedMutation>(
      'apollo-react:completed-mutation',
      payload => {
        const apolloFeature = this.root.requireFeature<ApolloReactFeature>(
          ApolloReactFeature.package,
        )
        clearTimeout(refetchTimeout)
        refetchTimeout = setTimeout(() => {
          debug('refetching background jobs after mutation')
          apolloFeature.client.refetchQueries({
            include: ['viewerBackgroundJobs'],
          })
        }, 500)

        return payload
      },
    )
  }

  public getDevToolsInstaller() {
    return import('./devtools/install').then(tools => tools.install)
  }

  public prepareEarly() {
    if (this.root.getFeature('@thesisedu/feature-feature-preview-react')) {
      require('./resources/UserSettingsPage/Preview').default(this)
    }
  }
}

export const usersReactFeature: ReactUse = (opts: UsersReactFeatureOptions) => [
  UsersReactFeature,
  opts,
]
