import ApolloReactFeature from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-web'
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingContainer } from './LoadingContainer'
import UsersWebFeature from './UsersWebFeature'
import { debug, error } from './log'
import { UserFragment, ViewerDocument, ViewerQuery, ViewerQueryVariables } from './schema'

export const authAndRedirect = async (
  navigate: (route: string) => any,
  usersFeature: UsersWebFeature,
  apolloFeature: ApolloReactFeature,
  loadedUser?: UserFragment,
  search?: string,
  initialPath?: string,
): Promise<void> => {
  debug('authing and redirecting user')
  const jwt = localStorage.getItem(apolloFeature.authenticationKey)
  const client = apolloFeature.client
  const searchParams = new URLSearchParams(search || window.location.search)
  const authLink = `/auth/login${window.location.search}`
  if (!jwt) {
    debug('jwt not found, redirecting to auth')
    navigate(authLink)
  } else {
    try {
      let viewerUser: UserFragment | undefined | null = loadedUser
      if (!viewerUser) {
        const { data } = await client.query<ViewerQuery, ViewerQueryVariables>({
          query: ViewerDocument,
        })
        viewerUser = data?.viewer
      }
      if (!viewerUser) {
        debug('no viewer, removing key and redirecting to auth')
        localStorage.removeItem(apolloFeature.authenticationKey)
        navigate(authLink)
      } else if (viewerUser?.group) {
        const destination = searchParams.get('redirect') || initialPath
        if (destination) {
          debug(
            'found viewer with group %s, redirecting to custom path %s',
            viewerUser.group,
            destination,
          )
          navigate(destination.startsWith('/') ? destination : `/${destination}`)
        } else {
          debug('found viewer with group %s, redirecting', viewerUser.group)
          navigate('/' + viewerUser.group.toLowerCase().replace(/_/g, '-'))
        }
      } else {
        debug('viewer group is invalid, clearing and redirecting to auth')
        localStorage.removeItem(apolloFeature.authenticationKey)
        navigate(authLink)
      }
    } catch (err: any) {
      if (err.extensions?.code === 'ACCESS_DENIED_ERROR') {
        debug('got an access denied error. clearing and redirecting to auth')
        localStorage.removeItem(apolloFeature.authenticationKey)
        navigate(authLink)
      } else {
        error('error with viewer query. logging out.')
        error(err)
        localStorage.removeItem(apolloFeature.authenticationKey)
        navigate(authLink)
      }
    }
  }
}

export const useAuthAndRedirect = () => {
  const navigate = useNavigate()
  const apolloFeature = useFeature<ApolloReactFeature>(ApolloReactFeature.package)
  const usersFeature = useFeature<UsersWebFeature>(UsersWebFeature.package)
  useEffect(() => {
    authAndRedirect(navigate, usersFeature, apolloFeature)
  }, [navigate, usersFeature, apolloFeature])
}

export const AuthLoadingScreen: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  useAuthAndRedirect()
  if (children) {
    return <>{children}</>
  } else {
    return (
      <LoadingContainer>
        <Spin size={'large'} />
      </LoadingContainer>
    )
  }
}
