import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'
import { GeneratedAssignmentSettingsContent } from '../../generated/GeneratedAssignmentSettingsContent'
import { AssignmentSettingsOptionsHook } from '../../node'

export default function (feature: AssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<AssignmentSettingsOptionsHook>(
    'feature-assignments-react:assignment-settings-options',
    (settings, { assignment }) => {
      if (assignment.generated) {
        return {
          ...settings,
          hideAssignment: true,
          hideDescription: true,
          hideRevealAnswers: true,
          hideRandomizeQuestions: true,
          hideMaxAttempts: true,
          hideTimeLimit: true,
          OpensAt: null,
          SettingsContent: GeneratedAssignmentSettingsContent,
        }
      }
      return settings
    },
  )
}
