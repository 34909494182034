import { useSelectedTermId } from './TermSelector'
import { GenerateOutlineItemsOpts, generateOutlineItems, getMaximumDepth } from './generate'
import { OutlineItem } from './types'
import { Segment } from '../types'

export const MINIMUM_DEPTH = 2

export interface OutlineData {
  items: OutlineItem[]
  selectedTerm?: Segment
  selectedTermId: string | undefined
  setSelectedTermId: (termId: string) => void
}
export interface OutlineDataOpts
  extends Omit<GenerateOutlineItemsOpts, 'depth' | 'parentIds' | 'parentSegment'> {
  segments: Segment[]
  selectedTermId?: string
  setSelectedTermId?: (termId: string) => void
  courseOrClassId: string
}
export function useOutlineData({
  segments,
  selectedTermId: _selectedTermId,
  setSelectedTermId: _setSelectedTermId,
  courseOrClassId,
  ...rest
}: OutlineDataOpts): OutlineData {
  const selectedTermHook = useSelectedTermId(courseOrClassId, segments)
  const selectedTermId = _setSelectedTermId ? _selectedTermId : selectedTermHook[0]
  const setSelectedTermId = _setSelectedTermId || selectedTermHook[1]
  const selectedTerm = segments?.find(s => s.id === selectedTermId)
  const items = generateOutlineItems(selectedTerm?.childSegments || [], {
    depth: 0,
    parentIds: [selectedTerm?.id || ''],
    // We can force it here, because the only place this is used, it will be defined.
    parentSegment: selectedTerm!,
    maximumDepth: Math.max(MINIMUM_DEPTH, getMaximumDepth(selectedTerm ? [selectedTerm] : []) - 1),
    ...rest,
  })

  return {
    items,
    selectedTerm,
    selectedTermId,
    setSelectedTermId,
  }
}
