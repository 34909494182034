import { ReloadOutlined } from '@ant-design/icons'
import { InfiniteQuery, InfiniteQueryProps } from '@thesisedu/feature-apollo-react/web'
import { Block, BlockSpin, VSpaced } from '@thesisedu/web'
import { Button, Empty } from 'antd'
import React from 'react'

import { DirectMessageThreadItem } from './DirectMessageThreadItem'
import {
  DirectMessageThreadFragment,
  DirectMessageThreadsDocument,
  DirectMessageThreadsQuery,
  DirectMessageThreadsQueryVariables,
} from '../schema'
import { ThreadListFilters } from '../types'

export interface DirectMessageThreadListProps {
  filters?: ThreadListFilters
  infiniteQueryProps?: Partial<
    InfiniteQueryProps<
      DirectMessageThreadFragment,
      DirectMessageThreadsQuery,
      DirectMessageThreadsQueryVariables
    >
  >
}
export function DirectMessageThreadList({
  filters,
  infiniteQueryProps,
}: DirectMessageThreadListProps) {
  return (
    <InfiniteQuery<
      DirectMessageThreadFragment,
      DirectMessageThreadsQuery,
      DirectMessageThreadsQueryVariables
    >
      resultPath={'viewer.directMessageThreads'}
      document={DirectMessageThreadsDocument}
      variables={{
        filters,
      }}
      {...infiniteQueryProps}
      children={({ data, loading, refetch }) => {
        const threads = data?.viewer?.directMessageThreads.edges.map(edge => edge.node)
        if (threads?.length) {
          return (
            <VSpaced align={'stretch'}>
              <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
                <Button
                  loading={loading}
                  icon={<ReloadOutlined />}
                  onClick={() => refetch({ filters })}
                  size={'small'}
                />
              </Block>
              {threads.map(thread => (
                <DirectMessageThreadItem key={thread.id} thread={thread} />
              ))}
            </VSpaced>
          )
        } else if (loading) {
          return <BlockSpin />
        } else {
          return <Empty description={'No threads yet!'} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      }}
    />
  )
}
