import { VideoCamera } from '@thesisedu/react/icons'

import { PerformanceAnswer } from './PerformanceAnswer'
import { PerformanceContent } from './PerformanceContent'
import { PerformanceEdit } from './PerformanceEdit'
import { QuestionTypeResource } from '../../../types'

export default function (label = 'Recording') {
  return {
    type: 'QuestionType',
    identifier: 'Performance',
    defaults: {
      name: 'Record yourself performing.',
      config: {},
    },
    icon: VideoCamera,
    label,
    group: label,
    renderAnswerView: PerformanceAnswer,
    renderContent: PerformanceContent,
    renderEdit: PerformanceEdit,
  } as QuestionTypeResource
}
