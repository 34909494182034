import React from 'react'

import { OutlineItemProps } from '../items/OutlineItem'

export interface OutlineListConfigContextValue {
  outlineItemComponent: (props: OutlineItemProps) => React.ReactElement | null
  listId?: string
}
export const OutlineListConfigContext = React.createContext<
  OutlineListConfigContextValue | undefined
>(undefined)

export function useOutlineListConfigContext(): OutlineListConfigContextValue | undefined
export function useOutlineListConfigContext(
  require: false,
): OutlineListConfigContextValue | undefined
export function useOutlineListConfigContext(require: true): OutlineListConfigContextValue
export function useOutlineListConfigContext(
  require?: boolean,
): OutlineListConfigContextValue | undefined {
  const context = React.useContext(OutlineListConfigContext)
  if (!context && require) {
    throw new Error('OutlineListConfigContext is required, yet not provided.')
  }
  return context
}
