import { UserLeftItemsHook, UserRoutesHook } from '@thesisedu/feature-users-react'
import { Shell } from '@thesisedu/feature-web'
import { Block } from '@thesisedu/ui'
import { ViewStructureUp } from '@thesisedu/ui/icons'
import { useBreadcrumb, PageHead, PageHeader } from '@thesisedu/web'
import React from 'react'
import { useParams, Route, useNavigate } from 'react-router-dom'

import { CourseViewsReactFeature } from '../../CourseViewsReactFeature'
import { CourseView } from '../../manage/CourseView'
import { CourseViewsInfiniteQuery } from '../../manage/CourseViewsInfiniteQuery'
import { ImportButton } from '../../manage/ImportButton'

function ListCourseViews() {
  useBreadcrumb({ title: 'Course Views' })
  return (
    <>
      <PageHead title={'Course Views'} />
      <PageHeader title={'Course Views'} />
      <CourseViewsInfiniteQuery />
    </>
  )
}

function ViewCourseView() {
  const { courseViewId } = useParams()
  useBreadcrumb({ title: 'Course Views' })
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Course Views'} />
      <PageHeader
        title={'Course Views'}
        onBack={() => {
          navigate('/admin/course-views')
        }}
      >
        <ImportButton courseViewId={courseViewId} />
      </PageHeader>
      <Block top={'l'} style={{ width: '100%' }}>
        <CourseView courseViewId={courseViewId} />
      </Block>
    </>
  )
}

export default function (feature: CourseViewsReactFeature) {
  feature.hookManager.registerMutateHook<UserLeftItemsHook>(
    'feature-users-react:left-items',
    (items, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...items,
          {
            group: 'Content',
            element: (
              <Shell.LeftMenuItem
                name={'Course Views'}
                icon={<ViewStructureUp />}
                path={'/admin/course-views'}
              />
            ),
            weight: 0.1,
          },
        ]
      } else return items
    },
  )
  feature.hookManager.registerMutateHook<UserRoutesHook>(
    'feature-users-react:user-routes',
    (routes, { group }) => {
      if (group === 'ADMIN') {
        return [
          ...routes,
          <Route key={'course-views'} path={'course-views'} element={<ListCourseViews />} />,
          <Route path={'course-views/:courseViewId'} element={<ViewCourseView />} />,
        ]
      } else return routes
    },
  )
}
