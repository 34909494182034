import { onMutationError } from '@thesisedu/feature-apollo-react'

import { TeacherLessonsDocument, useDeleteLessonMutation as useMutation } from '../schema'

export const useDeleteLessonMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error deleting your lesson.'),
    refetchQueries: [{ query: TeacherLessonsDocument }],
    awaitRefetchQueries: true,
    ...opts,
  })
