import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { SegmentedControl, Tooltip, Button, HSpaced } from '@thesisedu/ui'
import { ViewColumns2 } from '@thesisedu/ui/icons'
import { getFlag, setFlag } from '@thesisedu/web'
import React from 'react'

import { useNavigateToSplitView } from '../placement/useNavigateToSplitView'
import { Segment } from '../types'

export function useSelectedTermId(
  courseOrClassId: string,
  segments?: Segment[],
): [string | undefined, (termId: string) => void] {
  const flag = `feature-courses-react-selected-${courseOrClassId}`
  const [selectedTermId, setSelectedTermId] = React.useState<string | undefined>(undefined)
  const segmentIds = segments?.map(s => s.id).join(',')
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const selectedSegment = params.get('term') || getFlag(flag)
    if (selectedSegment && segments?.some(s => s.id === selectedSegment)) {
      setSelectedTermId(selectedSegment)
    } else {
      setSelectedTermId(segments?.[0]?.id)
    }
  }, [segmentIds])
  React.useEffect(() => {
    setFlag(flag, selectedTermId)
  }, [selectedTermId])
  return [selectedTermId, setSelectedTermId]
}

export interface TermSelectorProps {
  segments?: Segment[]
  selectedTermId?: string
  onSelectedTermChanged: (selectedTermId: string) => void
  hideSplitView?: boolean
}
export function TermSelector({
  segments,
  selectedTermId,
  onSelectedTermChanged,
  hideSplitView,
}: TermSelectorProps) {
  const termSegments = segments || []
  const navigate = useNavigateToSplitView()
  const classId = useSelectedClassId(false)
  return (
    <HSpaced space={'xs'}>
      <SegmentedControl style={{ flex: 1 }}>
        {termSegments.map(termSegment => (
          <SegmentedControl.Item
            key={termSegment.id}
            selected={termSegment.id === selectedTermId}
            onPress={() => onSelectedTermChanged(termSegment.id)}
          >
            {termSegment.name}
          </SegmentedControl.Item>
        ))}
      </SegmentedControl>
      {!hideSplitView && termSegments.length === 2 ? (
        <Tooltip contentProps={{ side: 'bottom' }} title={'Open in Split View'}>
          <Button
            icon={<ViewColumns2 />}
            size={'large'}
            variant={'ghost'}
            onPress={() => {
              if (classId) {
                navigate({
                  classId,
                  pane: termSegments[0].id,
                  rightTerm: termSegments[1].id,
                })
              }
            }}
          />
        </Tooltip>
      ) : null}
    </HSpaced>
  )
}
