import { useMutateHook } from '@thesisedu/feature-react'
import { Button, s } from '@thesisedu/ui'
import { MoreVert } from '@thesisedu/ui/icons'
import { styled } from '@thesisedu/web'
import React from 'react'

import { OutlineSearchWrapper } from './OutlineSearchContext'
import { SegmentActions } from './SegmentActions'
import { SegmentDescriptions } from './SegmentDescriptions'
import { SegmentLockEnable } from './SegmentLockEnable'
import { useSegment } from '../contexts/SegmentContext'
import { SegmentDynamicIcon } from '../course_viewer/SegmentDynamicIcon'
import { isTeacherInfoSegment } from '../helpers'
import { SegmentName } from '../segment/SegmentName'
import {
  CoursesHooks,
  Segment as SegmentType,
  SegmentRightContentContext,
  SegmentRightContentPayload,
} from '../types'

const Container = styled(OutlineSearchWrapper)`
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  transition: box-shadow 0.1s linear;
  border-radius: ${props => props.theme['@border-radius-base']};
  overflow: visible;
  box-shadow: none;
  & + .implementation-guide-wrapper {
    margin-top: ${props => props.theme['@size-xl']};
  }
  .action-container {
    transition: opacity 0.1s linear;
    pointer-events: none;
    opacity: 0;
    width: ${props => props.theme['@size-m']};
    text-align: center;
    display: flex;
    align-items: center;
    margin-left: ${props => props.theme['@size-s']};
  }
  .segment-name-container {
    margin: 0 auto 0 ${props => props.theme['@size-s']};
    padding-right: ${props => props.theme['@size-s']};
    min-width: 0;
  }
  .hover-content {
    transition: opacity 0.1s linear;
    pointer-events: none;
    opacity: 0;
    background: white;
  }
  &.hoverable:hover,
  &.actions-visible {
    box-shadow: ${props => props.theme['@shadow-medium']};
    .action-container {
      opacity: 1;
      pointer-events: all;
    }
    .hover-content {
      opacity: 1;
      pointer-events: all;
    }
  }
`

export interface SegmentProps {
  segment: SegmentType
  readOnly?: boolean
  children?: React.ReactNode
  noLink?: boolean
  [key: string]: any
}
const _Segment = ({ segment: _segment, readOnly, children, noLink, ...props }: SegmentProps) => {
  const segment = useSegment(_segment?.id) || _segment
  const isTeacherInfo = isTeacherInfoSegment(segment)
  const [actionsVisible, setActionsVisible] = React.useState(false)
  const rightContent = useMutateHook<SegmentRightContentPayload, SegmentRightContentContext>(
    CoursesHooks.SegmentRightContent,
    [],
    {
      segment,
      readOnly,
    },
  )
  return (
    <Container
      segmentIds={[segment.id]}
      {...props}
      className={`last-nomargin ${readOnly ? '' : 'hoverable'} ${
        actionsVisible ? 'actions-visible' : ''
      } ${props.className}`}
      id={segment.id}
    >
      <SegmentDynamicIcon segment={segment} size={32} />
      <div className={'segment-name-container'}>
        <SegmentName segment={segment} isTeacherInfo={isTeacherInfo} linkToSegment={!noLink} />
        <SegmentDescriptions segment={segment} />
      </div>
      {readOnly ? (
        <div className={'toggle-container'} children={rightContent} />
      ) : (
        <>
          {rightContent}
          {isTeacherInfo || segment.config.alwaysEnabled ? null : (
            <SegmentLockEnable segmentId={segment.id} disabled={segment.notInLicense} />
          )}
          <SegmentActions segment={segment} onOpenChange={setActionsVisible} open={actionsVisible}>
            <Button variant={'ghost'} icon={<MoreVert />} style={{ marginLeft: s.size('xs') }} />
          </SegmentActions>
        </>
      )}
      {children}
    </Container>
  )
}

export const Segment = React.memo(
  _Segment,
  (prevProps, nextProps) => prevProps.segment.id === nextProps.segment.id,
)
