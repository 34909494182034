import { useFieldArray } from 'react-hook-form'

import { Form as CForm, useFormSubmit, useReactFormContext } from './Form'
import { FormItem } from './FormItem'
import { FormListItem } from './FormListItem'
import { FormSubmit, StandaloneFormSubmit } from './FormSubmit'
import { useForm, useControlledForm } from './useForm'

export * from './types'
export * from './BlockRadio'
export { UseFormReturn } from 'react-hook-form'

export const Form = CForm as typeof CForm & {
  Item: typeof FormItem
  ListItem: typeof FormListItem
  Submit: typeof FormSubmit
  StandaloneSubmit: typeof StandaloneFormSubmit
  useForm: typeof useForm
  useControlledForm: typeof useControlledForm
  useReactFormContext: typeof useReactFormContext
  useFormSubmit: typeof useFormSubmit
  useFieldArray: typeof useFieldArray
}
Form.Item = FormItem
Form.ListItem = FormListItem
Form.Submit = FormSubmit
Form.StandaloneSubmit = StandaloneFormSubmit
Form.useForm = useForm
Form.useControlledForm = useControlledForm
Form.useReactFormContext = useReactFormContext
Form.useFormSubmit = useFormSubmit
Form.useFieldArray = useFieldArray

export { StandaloneFormSubmitProps } from './FormSubmit'
