import React from 'react'

import { PlacementPaneImperativeHandle } from './types'

export interface SplitViewRefsContextValue {
  leftRef?: React.RefObject<PlacementPaneImperativeHandle>
  rightRef?: React.RefObject<PlacementPaneImperativeHandle>
}
export const SplitViewRefsContext = React.createContext<SplitViewRefsContextValue | undefined>(
  undefined,
)

export function useSplitViewRefsContext(): SplitViewRefsContextValue | undefined
export function useSplitViewRefsContext(require: false): SplitViewRefsContextValue | undefined
export function useSplitViewRefsContext(require: true): SplitViewRefsContextValue
export function useSplitViewRefsContext(require?: boolean): SplitViewRefsContextValue | undefined {
  const context = React.useContext(SplitViewRefsContext)
  if (!context && require) {
    throw new Error('SplitViewRefsContext is required, yet not provided.')
  }
  return context
}
