import { SelectButton, SelectButtonProps } from '@thesisedu/feature-apollo-react/web'
import React from 'react'

import {
  AdminBadgeFragment,
  BadgesQuery,
  BadgesQueryVariables,
  BadgesDocument,
  BadgeDocument,
} from '../schema'

export interface BadgeSelectProps
  extends Partial<SelectButtonProps<AdminBadgeFragment, BadgesQuery, BadgesQueryVariables>> {}
export function BadgeSelect(props: BadgeSelectProps) {
  return (
    <SelectButton<AdminBadgeFragment, BadgesQuery, BadgesQueryVariables>
      filterVariableName={'name'}
      getItemName={item => item.name}
      resultPath={'badges'}
      document={BadgesDocument}
      nodeDocument={BadgeDocument}
      selectLabel={'Select Badge(s)'}
      {...props}
    />
  )
}

export interface BadgeIDsSelectProps {
  value?: string[]
  onChange?: (value?: string[]) => void
}
export function BadgeIDsSelect({ value, onChange }: BadgeIDsSelectProps) {
  return <BadgeSelect value={value} onChange={onChange} />
}
