import { useViewerContext } from '@thesisedu/feature-users-react'

import { useExploreStandardSetsQuery, useStandardSetsQuery } from '../schema'

export function useAnyoneStandardSets() {
  const viewer = useViewerContext(false)
  const adminResult = useStandardSetsQuery({
    variables: { first: 100 },
    skip: viewer?.group !== 'ADMIN',
  })
  const teacherResult = useExploreStandardSetsQuery({
    skip: viewer?.group !== 'TEACHER',
  })
  if (viewer?.group === 'ADMIN') {
    return {
      ...adminResult,
      standardSets: adminResult.data?.standardSets,
    }
  } else if (viewer?.group === 'TEACHER') {
    return {
      ...teacherResult,
      standardSets: teacherResult.data?.viewer?.teacher?.site.standardSets,
    }
  } else return null
}
