import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React from 'react'

import { useTeacherClass } from '../useClass'

export interface StudentSelectProps extends Omit<SelectProps<string>, 'options'> {
  classId: string
}
export function StudentSelect({ classId, ...props }: StudentSelectProps) {
  const { cls, loading } = useTeacherClass(classId, false)
  const students = cls?.students.edges.map(edge => edge.node) || []

  return (
    <Select<string>
      {...props}
      disabled={loading || props.disabled}
      placeholder={loading ? 'Loading students...' : props.placeholder}
      options={students.map(student => ({
        label: student.user.name || student.user.username,
        value: student.id,
      }))}
    />
  )
}
