import { BookOutlined } from '@ant-design/icons'
import { BadgeEventTypeResource } from '@thesisedu/feature-badges-react'
import { SegmentBadgeEventConfig } from '@thesisedu/feature-course-types'
import React from 'react'

import { CoursesReactFeature } from '../../CoursesReactFeature'

export function addBadgeResources(feature: CoursesReactFeature) {
  const { SegmentBadgeConfig } = require('./SegmentBadgeConfig')
  feature.resources.addResource<BadgeEventTypeResource<SegmentBadgeEventConfig>>({
    type: 'BadgeEventType',
    identifier: 'segment',
    label: 'Segments',
    description:
      'Award badges based on completing pieces of content, be it completing assignments, watching videos, or reading sections.',
    icon: <BookOutlined />,
    displayWeight: 0,
    configComponent: SegmentBadgeConfig,
    defaultConfig: {
      selectedSegmentIds: [],
    },
  })
}
