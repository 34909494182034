import { CollapseProps } from '@thesisedu/native'
import { Collapse as AntCollapse } from 'antd'
import React from 'react'

const AnyPanel = AntCollapse.Panel as any

export function Collapse({
  header,
  rightContent,
  children,
  panelKey,
  ...rest
}: React.PropsWithChildren<CollapseProps>) {
  return (
    <AnyPanel
      {...rest}
      key={panelKey}
      panelKey={panelKey}
      header={header}
      extra={rightContent}
      children={children}
    />
  )
}
