import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { FormValues } from './AssignmentGroups'
import { useGradingModalContext } from '../contexts/GradingModalContext'

export interface UseAssignmentGroupsFormDirtyStateResult {
  onSaved: () => void
}
export function useAssignmentGroupsFormDirtyState(
  form: Form$.FormInstance<FormValues>,
): UseAssignmentGroupsFormDirtyStateResult {
  const { addPrompt, removePrompt } = useGradingModalContext(true)
  const isDirty = form.formState.isDirty
  React.useEffect(() => {
    if (isDirty) {
      addPrompt?.('assignment-groups', 'You have unsaved changes to your assignment groups.')
      return () => {
        removePrompt?.('assignment-groups')
      }
    }
  }, [isDirty])
  return {
    onSaved() {
      removePrompt?.('assignment-settings')
    },
  }
}
