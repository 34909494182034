import { UseInClassButton } from '@thesisedu/feature-courses-react/dist/explore/UseInClassButton'
import { getSegmentRawId } from '@thesisedu/feature-courses-react/dist/helpers'
import { useSegmentPreviewContext } from '@thesisedu/feature-courses-react/dist/segment/SegmentPreviewContext'
import React from 'react'

import { MinimalSegmentFragment } from '../schema'

export interface ExploreStandardSetsContextValue {
  segments: MinimalSegmentFragment[]
  openSegment: (segmentApiId: string) => void
}
export const ExploreStandardSetsContext = React.createContext<
  ExploreStandardSetsContextValue | undefined
>(undefined)

export interface ExploreStandardSetsContextProviderProps
  extends Omit<ExploreStandardSetsContextValue, 'openSegment'> {}
export function ExploreStandardSetsContextProvider({
  children,
  ...context
}: React.PropsWithChildren<ExploreStandardSetsContextProviderProps>) {
  const { setPreviewSegmentId } = useSegmentPreviewContext(true)
  return (
    <ExploreStandardSetsContext.Provider
      value={{
        ...context,
        openSegment(apiId) {
          setPreviewSegmentId(apiId, id => {
            return (
              <UseInClassButton
                rawId={getSegmentRawId(id)}
                variant={'primary'}
                onClick={() => setPreviewSegmentId(undefined)}
              />
            )
          })
        },
      }}
      children={children}
    />
  )
}

export function useExploreStandardSetsContext(): ExploreStandardSetsContextValue | undefined
export function useExploreStandardSetsContext(
  require: false,
): ExploreStandardSetsContextValue | undefined
export function useExploreStandardSetsContext(require: true): ExploreStandardSetsContextValue
export function useExploreStandardSetsContext(
  require?: boolean,
): ExploreStandardSetsContextValue | undefined {
  const context = React.useContext(ExploreStandardSetsContext)
  if (!context && require) {
    throw new Error('ExploreStandardSetsContext is required, yet not provided.')
  }
  return context
}
