import { useSelectedClass } from '@thesisedu/feature-classes-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { SetupNavigationItem } from './SetupNavigationItem'

export type SetupNavigationGroup = 'chooseProvider' | 'attachClass' | 'rosterStudents' | 'learnSync'
export const NavigationGroupIndexes: Record<SetupNavigationGroup, number> = {
  chooseProvider: 1,
  attachClass: 2,
  rosterStudents: 3,
  learnSync: 4,
}

export interface SetupNavigationProps {
  activeIndex?: number
}
export function SetupNavigation({ activeIndex }: SetupNavigationProps) {
  const cls = useSelectedClass(true)
  const appName = useFeatureRoot().appOptions.name
  return (
    <Container>
      <SetupNavigationItem
        index={1}
        title={'Choose a Provider'}
        description={'Pick a LMS to connect to.'}
        activeIndex={activeIndex}
      />
      <SetupNavigationItem
        index={2}
        title={'Attach a Class'}
        description={`Choose a class to connect to your ${cls.name} class.`}
        activeIndex={activeIndex}
      />
      <SetupNavigationItem
        index={3}
        title={'Roster Students'}
        description={`Match students from your LMS to ${appName}.`}
        activeIndex={activeIndex}
      />
      <SetupNavigationItem
        index={4}
        title={'Learn About Sync'}
        description={`Learn how to sync content from ${appName} to your LMS.`}
        activeIndex={activeIndex}
      />
    </Container>
  )
}

const Container = styled.div`
  background: ${s.color('subtle')};
  padding: ${s.size('m')};
  align-self: stretch;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: ${s.size('s')};
  width: 300px;
  flex-shrink: 0;
`
