import { ApolloClient } from '@apollo/client'
import { omitDeep } from '@thesisedu/web'
import { Button, Modal } from 'antd'
import axios, { CancelTokenSource } from 'axios'
import { throttle } from 'lodash'
import moment, { DurationInputArg2 } from 'moment'
import path from 'path'
import React from 'react'

import { sortUploads } from './helpers'
import {
  IUploadManager,
  Upload,
  UploadManagerBlobMap,
  UploadManagerListener,
  UploadManagerState,
  UploadPayload,
  UploadStatus,
} from './types'
import MediaReactFeature from '../MediaReactFeature'
import { debug, error, warn } from '../log'
import {
  MediaNodeDocument,
  MediaNodeQuery,
  MediaNodeQueryVariables,
  MediaStatus,
  RefreshMediaUploadUrlDocument,
  RefreshMediaUploadUrlMutation,
  RefreshMediaUploadUrlMutationVariables,
  SubmitMediaDocument,
  SubmitMediaMutation,
  SubmitMediaMutationVariables,
  UploadRequestMode,
} from '../schema'

export const LOOP_DELAY = 5000
export const STORAGE_KEY = 'feature-media-state'
export const INITIAL_STATE: UploadManagerState = {
  uploads: [],
}
export const ENTRY_EXPIRATION = '5'
export const ENTRY_EXPIRATION_UNIT = 'days'
export const FAILED_ENTRY_EXPIRATION = '30'
export const FAILED_ENTRY_EXPIRATION_UNIT = 'days'

export const getCacheName = (mediaId: string) => `feature-media-cache:${mediaId}`

export class UploadManager implements IUploadManager {
  public client: ApolloClient<any>
  private listeners: UploadManagerListener[]
  private state?: UploadManagerState
  private cancelToken?: CancelTokenSource
  private blobs: UploadManagerBlobMap
  private feature: MediaReactFeature
  private getStatePromise?: Promise<UploadManagerState>

  constructor(apollo: ApolloClient<any>, feature: MediaReactFeature) {
    this.client = apollo
    this.feature = feature
    this.listeners = []
    this.blobs = {}
    this.loop().catch(err => {
      error('error in loop')
      error(err)
    })
  }

  // We have to use the same promise so we can make sure we're using the same
  // state object when multiple items request it at once, since we're not
  // operating in synchronous mode here. We have to make some sort of "locking"
  // mechanism.
  //
  // This is because inside _getState() below, it could take a second or two
  // depending on how long it takes to retrieve the items from the cache. If
  // it takes a little longer and something else requests the state in the
  // meantime, we could have two versions of the state floating around, and we
  // don't want that.
  public async getState(): Promise<UploadManagerState> {
    if (!this.getStatePromise) {
      this.getStatePromise = this._getState().finally(() => {
        this.getStatePromise = undefined
      })
    }
    return this.getStatePromise
  }

  public async _getState(): Promise<UploadManagerState> {
    if (!this.state) {
      const encodedState = localStorage.getItem(STORAGE_KEY)
      this.state = encodedState ? JSON.parse(encodedState) : INITIAL_STATE
      // Restore the file blobs from memory.
      if (this.state?.uploads) {
        for (const upload of this.state.uploads) {
          if (this.blobs[upload.mediaId]) {
            upload.fileBlob = this.blobs[upload.mediaId]
          } else if (await caches.has(getCacheName(upload.mediaId))) {
            // Restore the blob from cache if we have it.
            debug('restoring media blob from cache %s', upload.mediaId)
            const cache = await caches.open(getCacheName(upload.mediaId))
            if (cache) {
              const result = await cache.match(`/${getCacheName(upload.mediaId)}`)
              if (result) {
                upload.fileBlob = await result.blob()
                // Also restore to our internal cache so we don't have to keep re-fetching from cache
                this.blobs[upload.mediaId] = upload.fileBlob
              }
            } else {
              warn('cache object was invalid, cannot restore blob')
            }
          }
        }
      }
    }
    return this.state || INITIAL_STATE
  }

  public saveState() {
    if (this.state) {
      this.fireListeners(this.state!)
      // Save the file blobs.
      this.blobs = this.state.uploads.reduce<UploadManagerBlobMap>((acc, upload) => {
        if (upload.fileBlob) {
          return { ...acc, [upload.mediaId]: upload.fileBlob }
        } else return acc
      }, {})
      localStorage.setItem(STORAGE_KEY, JSON.stringify(omitDeep(this.state, ['fileBlob'])))
    } else {
      debug('not saving uploading state, as there is no state.')
    }
  }

  public async addUpload(payload: UploadPayload) {
    const state = await this.getState()
    const exists = state.uploads.some(upload => upload.mediaId === payload.mediaId)
    if (!exists) {
      debug('adding upload %O', payload)
      state.uploads.push({
        ...payload,
        addedDate: moment().format(),
        currentStatus: UploadStatus.Waiting,
        uploaded: 0,
      })
      this.saveState()
      if (payload.fileBlob) {
        debug('saving file blob to cache')
        const cache = await caches.open(getCacheName(payload.mediaId))
        if (cache) {
          cache.put(`/${getCacheName(payload.mediaId)}`, new Response(payload.fileBlob))
        } else {
          warn('cache object was invalid, cannot save file blob')
        }
      }
    }
  }

  public async retryUpload(mediaId: string) {
    const state = await this.getState()
    const existing = state.uploads.find(upl => upl.mediaId === mediaId)
    if (!existing) {
      throw new Error('Cannot retry an upload that does not exist.')
    }
    if (existing.currentStatus === UploadStatus.Uploading && this.cancelToken) {
      debug('cancelling the existing upload...')
      this.cancelToken.cancel()
    }
    existing.currentStatus = UploadStatus.Waiting
    existing.uploaded = 0
    existing.completedDate = undefined
    this.saveState()
  }

  private saveUpload(upload: Upload) {
    if (upload.fileBlob) {
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(upload.fileBlob)
      downloadLink.download = path.basename(upload.signedUrlPath)
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } else {
      warn('cannot save upload %s, there is no fileBlob', upload.mediaId)
    }
  }

  private async failUploadPermanently(upload: Upload) {
    if (upload.fileBlob) {
      Modal.confirm({
        title: 'Upload Failed',
        content: 'We failed to upload one of your recordings. Would you like to save it?',
        cancelButtonProps: { danger: true },
        cancelText: 'No, delete',
        okType: 'primary',
        okText: 'Yes, save',
        onCancel: async () => {
          await this.removeUpload(upload.mediaId)
        },
        onOk: async () => {
          this.saveUpload(upload)
          await this.removeUpload(upload.mediaId)
        },
      })
    }
  }

  public addListener(fn: UploadManagerListener) {
    this.removeListener(fn)
    this.listeners.push(fn)
  }

  public removeListener(fn: UploadManagerListener) {
    this.listeners = this.listeners.filter(listener => listener !== fn)
  }

  public async removeExpiredEntries() {
    return this.removeExpiredInternal(ENTRY_EXPIRATION, ENTRY_EXPIRATION_UNIT)
  }

  public async removeExpiredFailedEntries() {
    return this.removeExpiredInternal(FAILED_ENTRY_EXPIRATION, FAILED_ENTRY_EXPIRATION_UNIT)
  }

  public async confirmAndRemoveUpload(mediaId: string, removed?: () => void) {
    const state = await this.getState()
    const upload = state.uploads.find(upl => upl.mediaId === mediaId)
    let close: () => any = () => false
    if (upload) {
      const allowRetry = [
        UploadStatus.Uploading,
        UploadStatus.Failed,
        UploadStatus.Waiting,
      ].includes(upload.currentStatus)
      if (!allowRetry) return
      const exists = !!upload.fileBlob
      const buttons = [
        <Button
          key={'retry'}
          onClick={() => {
            this.retryUpload(mediaId)
            close()
          }}
          style={{ margin: '0 5px' }}
        >
          Retry
        </Button>,
      ]
      if (exists) {
        buttons.push(
          <Button
            key={'save'}
            onClick={() => {
              this.saveUpload(upload)
              close()
            }}
            style={{ margin: '0 5px' }}
          >
            Download
          </Button>,
        )
      }
      buttons.push(
        <Button
          key={'delete'}
          danger
          onClick={() => {
            this.removeUpload(mediaId)
            close()
            if (removed) removed()
          }}
          style={{ margin: '0 5px' }}
        >
          Delete Upload
        </Button>,
      )
      const modal = Modal.info({
        title: 'Remove or retry?',
        width: 500,
        content: (
          <>
            <p>Would you like to remove this upload (you will lose your recording), or retry it?</p>
            <div style={{ textAlign: 'center' }}>{buttons}</div>
          </>
        ),
        okText: 'Go Back',
        okType: 'default',
      })
      close = () => modal.destroy()
    }
  }

  public async removeUpload(mediaId: string) {
    debug('removing upload %s', mediaId)
    const state = await this.getState()
    const upload = state.uploads.find(upl => upl.mediaId === mediaId)
    if (upload && upload.currentStatus === UploadStatus.Uploading && this.cancelToken) {
      this.cancelToken.cancel()
    }
    delete this.blobs[mediaId]
    debug('removing upload blob from cache')
    await caches.delete(getCacheName(mediaId))
    state.uploads = state.uploads.filter(upl => upl.mediaId !== mediaId)
    this.fireListeners(state)
    this.saveState()
  }

  public async finishUpload(candidateUpload: Upload) {
    debug('submitting upload %s', candidateUpload.mediaId)
    try {
      const result = await this.client.mutate<SubmitMediaMutation, SubmitMediaMutationVariables>({
        mutation: SubmitMediaDocument,
        variables: {
          input: {
            id: candidateUpload.mediaId,
          },
        },
      })
      if (result?.data?.submitMedia?.media.id) {
        debug('  submitted successfully. marking as uploaded')
        this.updateUploadLater(candidateUpload.mediaId, {
          currentStatus: UploadStatus.Uploaded,
          uploaded: 1,
        })
      } else {
        error('  error submitting, no response received')
        this.feature.services.error.reportError('error submitting upload', {
          errors: result.errors,
          data: result.data,
          candidateUpload,
        })
        this.updateUploadLater(candidateUpload.mediaId, {
          currentStatus: UploadStatus.Failed,
          uploaded: 1,
        })
      }
    } catch (err: any) {
      error('  error submitting')
      this.feature.services.error.reportError(err, {
        candidateUpload,
      })
      this.updateUploadLater(candidateUpload.mediaId, {
        currentStatus: UploadStatus.Failed,
        uploaded: 1,
      })
    }
  }

  public async startFileUploadIfNeeded() {
    const state = await this.getState()
    let existingUpload = state.uploads.find(
      upload => upload.currentStatus === UploadStatus.Uploading,
    )
    if (existingUpload && !this.cancelToken) {
      debug('found an existing upload, but no cancelToken. restarting upload.')
      await this.updateUploadLater(existingUpload.mediaId, {
        currentStatus: UploadStatus.Waiting,
      })
      existingUpload = undefined
    }
    if (!existingUpload) {
      const uploadable = state.uploads.filter(
        upload => upload.currentStatus === UploadStatus.Waiting,
      )
      const candidateUpload = sortUploads(uploadable)[0]
      if (candidateUpload && !candidateUpload.fileBlob) {
        warn('failing upload %s - fileBlob does not exist', candidateUpload.mediaId)
        this.feature.services.error.reportError('Failing upload - fileBlob does not exist.', {
          candidateUpload,
        })
        this.updateUploadLater(candidateUpload.mediaId, {
          currentStatus: UploadStatus.Failed,
        })
      } else if (candidateUpload && candidateUpload.fileBlob) {
        debug('found an upload to start: %s (%s)', candidateUpload.label, candidateUpload.mediaId)
        debug('fetching updated upload token')
        this.cancelToken = axios.CancelToken.source()
        candidateUpload.currentStatus = UploadStatus.Uploading
        try {
          const result = await this.client.mutate<
            RefreshMediaUploadUrlMutation,
            RefreshMediaUploadUrlMutationVariables
          >({
            mutation: RefreshMediaUploadUrlDocument,
            variables: {
              input: {
                id: candidateUpload.mediaId,
                requestMode: UploadRequestMode.Basic,
                mimeType: candidateUpload.contentType,
              },
            },
          })
          if (result?.data?.refreshMediaUploadUrl) {
            debug('refreshed upload url, starting upload')
            const r = result.data.refreshMediaUploadUrl
            candidateUpload.signedUrl = r.signedUrl
            candidateUpload.signedUrlPath = r.path
            candidateUpload.additionalFields = r.data
          }
        } catch (errs: any) {
          if ((errs.graphQLErrors || [])[0]?.extensions?.code === 'MEDIA_INVALID_ERROR') {
            error('media is invalid, cannot continue upload')
            await this.failUploadPermanently(candidateUpload)
            return
          } else if (
            (errs.graphQLErrors || [])[0]?.extensions?.code === 'MEDIA_ALREADY_UPLOADED_ERROR'
          ) {
            debug('media has already been uploaded, marking as processing')
            await this.finishUpload(candidateUpload)
            return
          } else {
            error('some other error occurred, failing upload')
            this.feature.services.error.reportError(errs, {
              candidateUpload,
            })
            this.updateUploadLater(candidateUpload.mediaId, {
              currentStatus: UploadStatus.Failed,
              uploaded: undefined,
            })
            return
          }
        }
        axios({
          method: 'PUT',
          data: candidateUpload.fileBlob,
          url: candidateUpload.signedUrl,
          headers: {
            'Content-Type': candidateUpload.contentType,
            ...candidateUpload.additionalFields,
            key: candidateUpload.signedUrlPath,
          },
          cancelToken: this.cancelToken.token,
          onUploadProgress: throttle(({ total, loaded }) => {
            debug('upload progress for %s: %d', candidateUpload.mediaId, loaded / total)
            this.updateUploadLater(candidateUpload.mediaId, {
              uploaded: loaded / total,
            })
          }, 100),
        })
          .then(async response => {
            debug('response status is %s: %s', response.status, response.statusText)
            debug('response body is %s', response.data)
            debug('upload complete for %s. marking as uploaded', candidateUpload.mediaId)
            await this.finishUpload(candidateUpload)
          })
          .catch(async err => {
            if (err.response) {
              error('upload for %s failed with error', candidateUpload.mediaId)
              error(err)
              this.updateUploadLater(candidateUpload.mediaId, {
                currentStatus: UploadStatus.Failed,
                uploaded: undefined,
              })
            } else {
              warn('upload failed, but no response. retrying.')
              this.updateUploadLater(candidateUpload.mediaId, {
                currentStatus: UploadStatus.Waiting,
                uploaded: undefined,
              })
            }
          })
          .finally(() => {
            this.cancelToken = undefined
          })
      }
    }
  }

  public async clearUploads() {
    const state = await this.getState()
    for (const upload of state.uploads) {
      await this.removeUpload(upload.mediaId)
    }
  }

  public async updateMediaStatus() {
    const state = await this.getState()
    const uploadsToFetch = state.uploads.filter(upload =>
      [UploadStatus.Uploaded, UploadStatus.Processing].includes(upload.currentStatus),
    )
    if (uploadsToFetch.length > 0) {
      debug('updating media status for %d uploads', uploadsToFetch.length)
    }
    await Promise.all(
      uploadsToFetch.map(async upload => {
        debug('updating upload %s', upload.mediaId)
        try {
          const { data } = await this.client.query<MediaNodeQuery, MediaNodeQueryVariables>({
            query: MediaNodeDocument,
            variables: {
              id: upload.mediaId,
            },
            fetchPolicy: 'network-only',
          })
          if (data) {
            if (data.node?.__typename === 'Media') {
              debug('media status is %s', data.node.status)
              if (data.node.status === MediaStatus.Error) {
                debug('marking upload %s as failed', upload.mediaId)
                await this.updateUploadLater(upload.mediaId, {
                  currentStatus: UploadStatus.Failed,
                })
              } else if (data.node.status === MediaStatus.Complete) {
                debug('marking upload %s as complete', upload.mediaId)
                await this.updateUploadLater(upload.mediaId, {
                  currentStatus: UploadStatus.Complete,
                  completedDate: moment().format(),
                })
              } else if (data.node.status === MediaStatus.Pending) {
                debug('media is in pending status for a strange reason. trying upload again...')
                await this.updateUploadLater(upload.mediaId, {
                  currentStatus: UploadStatus.Waiting,
                })
              }
            } else {
              debug('no node returned for %s, deleting', upload.mediaId)
              await this.removeUpload(upload.mediaId)
            }
          } else {
            warn('no data found when updating %s', upload.mediaId)
          }
        } catch (err: any) {
          warn('error updating status for upload %s', upload.mediaId)
          warn(err)
        }
      }),
    )
  }

  private fireListeners(state: UploadManagerState) {
    for (const listener of this.listeners) {
      listener(state)
    }
  }

  private async removeExpiredInternal(expiration: string, unit: DurationInputArg2) {
    const state = await this.getState()
    for (const upload of state.uploads) {
      const result =
        !upload.completedDate ||
        moment(upload.completedDate).add(expiration, unit).isAfter(moment())
      if (!result) {
        debug('expiring upload %s', upload.mediaId)
        await this.removeUpload(upload.mediaId)
      }
    }
  }

  private async updateUploadLater(mediaId: string, changes: Partial<Upload>) {
    debug('updating upload later %s: %O', mediaId, changes)
    const state = await this.getState()
    const upload = state.uploads.find(upl => upl.mediaId === mediaId)
    if (upload) {
      const previousStatus = upload.currentStatus
      Object.assign(upload, changes)
      if (changes.currentStatus && previousStatus !== changes.currentStatus) {
        upload.statusCallback?.(changes.currentStatus)
      }
      if (
        previousStatus !== UploadStatus.Complete &&
        changes.currentStatus === UploadStatus.Complete &&
        upload.processedCallback
      ) {
        upload.processedCallback()
      }
      this.saveState()
    } else {
      warn('could not update upload %s. does not exist.', mediaId)
    }
  }

  private async loop() {
    await this.removeExpiredEntries()
    await this.removeExpiredFailedEntries()
    await this.startFileUploadIfNeeded()
    await this.updateMediaStatus()
    this.saveState()
    setTimeout(() => {
      this.loop().catch(err => {
        error('error in loop')
        error(err)
      })
    }, LOOP_DELAY)
  }
}
