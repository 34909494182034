import { styled, BodyLarge } from '@thesisedu/web'
import React from 'react'

import { HEIGHTS } from './types'

export interface LyricProps {
  lyric: string
  color: string
}
export function Lyric({ lyric, color }: LyricProps) {
  return (
    <Container>
      <BodyLarge color={color}>{lyric}</BodyLarge>
    </Container>
  )
}

const Container = styled.div`
  height: ${HEIGHTS.lyrics}px;
  width: 100%;
  > *,
  p {
    white-space: nowrap;
    overflow: visible;
    font-size: ${HEIGHTS.lyrics * 0.6}px !important;
  }
`
