import { ThemePath } from './types'

export function themePathToVariable(path: ThemePath) {
  return _themePathToVariable(path)
}

export function _themePathToVariable(path: string, suffix?: string): string {
  return `var(${_themePathToVariableName(path, undefined, suffix)})`
}

export function _themePathToVariableName(
  path: string,
  noPrefix?: boolean,
  suffix?: string,
): string {
  const segments = path.split('.')
  if (suffix) segments.push(suffix)
  return noPrefix ? segments.join('-') : `--ft-${segments.join('-')}`
}
