import React from 'react'

export enum PlaybackFooterLocation {
  Toolbar,
  Track,
  BeforeFooter,
}
export interface PlaybackFooterItem {
  identifier: string
  location: PlaybackFooterLocation
  weight: number
  children: React.ReactElement
}

export interface PlaybackFooterContextValue {
  items: PlaybackFooterItem[]
  addItem: (item: PlaybackFooterItem) => void
  removeItem: (identifier: string) => void
}
export const PlaybackFooterContext = React.createContext<PlaybackFooterContextValue | undefined>(
  undefined,
)

export interface PlaybackFooterContextProviderProps {
  children: React.ReactNode
}
export function PlaybackFooterContextProvider({ children }: PlaybackFooterContextProviderProps) {
  const [items, setItems] = React.useState<PlaybackFooterItem[]>([])
  return (
    <PlaybackFooterContext.Provider
      value={React.useMemo(
        () => ({
          items,
          addItem(item) {
            setItems(items => {
              let hadExisting = false
              const newItems = items.map(existingItem => {
                if (existingItem.identifier === item.identifier) {
                  hadExisting = true
                  return item
                }
                return existingItem
              })
              return hadExisting ? newItems : [...newItems, item]
            })
          },
          removeItem(identifier) {
            setItems(items => items.filter(item => item.identifier !== identifier))
          },
        }),
        [items, setItems],
      )}
      children={children}
    />
  )
}

export function usePlaybackFooterContext(): PlaybackFooterContextValue | undefined
export function usePlaybackFooterContext(require: false): PlaybackFooterContextValue | undefined
export function usePlaybackFooterContext(require: true): PlaybackFooterContextValue
export function usePlaybackFooterContext(
  require?: boolean,
): PlaybackFooterContextValue | undefined {
  const context = React.useContext(PlaybackFooterContext)
  if (!context && require) {
    throw new Error('PlaybackFooterContext is required, yet not provided.')
  }
  return context
}
