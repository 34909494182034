import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import { styled, s } from '@thesisedu/ui'
import {
  $getSelection,
  $isElementNode,
  $isParagraphNode,
  $isTextNode,
  BLUR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  ElementNode,
  INSERT_PARAGRAPH_COMMAND,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
} from 'lexical'
import React from 'react'
import ReactDOM from 'react-dom'

import { EmptyBlockAddMenuPluginStyle } from './styles'
import { useReplaceWithSettingsItem } from './useReplaceWithSettingsItem'
import { useElementsMenuItems } from '../../ui/ElementsMenu/useElementsMenuItems'

const ADD_MAX_HEIGHT = 400
const SPACE = 4

let currentSelectedDomElement: HTMLElement | null = null

function showAddOverlayContainer(
  element: HTMLElement,
  anchorElement: HTMLElement,
  container: HTMLDivElement | null,
) {
  if (container) {
    const rect = element.getBoundingClientRect()
    const anchorElementRect = anchorElement.getBoundingClientRect()
    const inner = container.firstChild as HTMLElement | null
    if (!inner) throw new Error('cannot find inner')
    const innerHeight = inner.clientHeight
    const hasEnoughHeightBelow =
      rect.top + rect.height + SPACE + innerHeight - anchorElementRect.top <
      anchorElementRect.height - anchorElementRect.top
    inner.scrollTop = 0
    if (hasEnoughHeightBelow) {
      container.style.transformOrigin = '0 0'
      container.style.transform = `translate(${rect.left - anchorElementRect.left}px, ${
        rect.top + rect.height + SPACE - anchorElementRect.top
      }px)`
      inner.classList.add('appear-below')
    } else {
      container.style.transformOrigin = '0 0'
      container.style.transform = `translate(${rect.left - anchorElementRect.left}px, ${
        rect.top - innerHeight - SPACE - anchorElementRect.top
      }px)`
      inner.classList.add('appear-above')
    }
  }
}

function hideOverlayContainer(container: HTMLDivElement | null) {
  if (container) {
    container.style.transformOrigin = '0 0'
    container.style.transform = `translate(-10000px, -10000px)`
    ;(container.firstChild as HTMLElement | null)?.classList.remove('appear-above', 'appear-below')
  }
}

export interface EmptyBlockAddMenuPluginProps {
  anchorElement?: HTMLElement
}
export function EmptyBlockAddMenuPlugin({
  anchorElement = document.body,
}: EmptyBlockAddMenuPluginProps = {}) {
  const [editor] = useLexicalComposerContext()
  useReplaceWithSettingsItem()
  const addOverlayContainerRef = React.useRef<HTMLDivElement>(null)
  const ignoreBlurTimeout = React.useRef<any>()
  const [search, setSearch] = React.useState('')
  const {
    items: menuItems,
    setSelectedIndex,
    deltaSelectedIndex,
    commit,
    setRecentAddNode,
  } = useElementsMenuItems({
    editor,
    search,
  })

  React.useEffect(() => {
    let addVisible = false

    return mergeRegister(
      // Hide the overlay on blur.
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          // We have to delay this to give the editor a chance to pick up on the onClick()
          setTimeout(() => {
            if (!ignoreBlurTimeout.current) {
              addVisible = false
              hideOverlayContainer(addOverlayContainerRef.current)
              setRecentAddNode(null)
            }
          }, 250)
          return false
        },
        COMMAND_PRIORITY_HIGH,
      ),

      // Handle arrow keys when the dropdown is visible.
      editor.registerCommand(
        KEY_ARROW_DOWN_COMMAND,
        payload => {
          if (addVisible) {
            payload.preventDefault()
            deltaSelectedIndex(1)
            return true
          } else return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        KEY_ARROW_UP_COMMAND,
        payload => {
          if (addVisible) {
            payload.preventDefault()
            deltaSelectedIndex(-1)
            return true
          } else return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        () => {
          if (addVisible) {
            editor.update(() => {
              commit()
              hideOverlayContainer(addOverlayContainerRef.current)
              setRecentAddNode(null)
              addVisible = false
            })
            return true
          } else return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),

      editor.registerUpdateListener(({ editorState }) => {
        if (currentSelectedDomElement) {
          currentSelectedDomElement.classList.remove('ft-new-empty')
          currentSelectedDomElement = null
        }
        editorState.read(() => {
          const selection = $getSelection()
          const nodes = selection?.getNodes() || []
          if (nodes.length === 1 && $isElementNode(nodes[0]) && nodes[0].isEmpty()) {
            const domElement = editor.getElementByKey(nodes[0].getKey())
            if (domElement && $isParagraphNode(nodes[0])) {
              currentSelectedDomElement = domElement
              domElement.classList.add('ft-new-empty')
            }
            hideOverlayContainer(addOverlayContainerRef.current)
            setRecentAddNode(null)
            addVisible = false
          } else if (nodes.length === 1 && $isTextNode(nodes[0])) {
            const node = nodes[0]
            const matchingParent = $findMatchingParent(node, node =>
              $isElementNode(node),
            ) as ElementNode | null
            if (matchingParent) {
              const textContent = matchingParent.getTextContent()
              if (textContent.startsWith('/')) {
                const domElement = editor.getElementByKey(matchingParent.getKey())
                if (domElement) {
                  const search = textContent.slice(1)
                  ignoreBlurTimeout.current = setTimeout(() => {
                    ignoreBlurTimeout.current = null
                  }, 300)
                  addVisible = true
                  setRecentAddNode(matchingParent)
                  setSearch(search)
                  setSelectedIndex(0)
                  setTimeout(() => {
                    showAddOverlayContainer(
                      domElement,
                      anchorElement,
                      addOverlayContainerRef.current,
                    )
                  }, 5)
                }
              } else {
                hideOverlayContainer(addOverlayContainerRef.current)
                setRecentAddNode(null)
                addVisible = false
              }
            }
          } else {
            hideOverlayContainer(addOverlayContainerRef.current)
            setRecentAddNode(null)
            addVisible = false
          }
        })
      }),
    )
  }, [editor, anchorElement])

  return (
    <>
      {ReactDOM.createPortal(
        <AddOverlayContainer ref={addOverlayContainerRef}>
          <AddOverlayContainerInner>
            {menuItems.length ? menuItems : <NoItems>No items</NoItems>}
          </AddOverlayContainerInner>
        </AddOverlayContainer>,
        anchorElement,
      )}
      <EmptyBlockAddMenuPluginStyle />
    </>
  )
}

const NoItems = styled.div`
  color: ${s.color('gray.secondary')};
  text-align: center;
  font-size: ${s.var('size.0.9')};
`
const AddOverlayContainerInner = styled.div`
  will-change: transform;
  width: 300px;
  max-height: ${ADD_MAX_HEIGHT}px;
  overflow-y: auto;
  border-radius: ${s.var('radii.1')};
  background: ${s.color('gray.background')};
  box-shadow: ${s.var('shadow.1')};
  padding: ${s.var('size.0.5')};
  animation-duration: 400ms;
  animation-timing-function: ${s.var('curves.exponential')};
  &.appear-above {
    animation-name: ${s.animations.slideDownAndFade};
  }
  &.appear-below {
    animation-name: ${s.animations.slideUpAndFade};
  }
`
const AddOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  transform: translate(-10000px, -10000px);
  z-index: ${s.var('zIndices.dropdown')};
`
