import { AutoComplete } from 'antd'
import React from 'react'

import { useTagTypesQuery } from '../schema'

export interface TagTypeSelectProps {
  value?: string
  onChange?: (value?: string) => void
}
export function TagTypeSelect({ value, onChange }: TagTypeSelectProps) {
  const { data } = useTagTypesQuery()
  const tagTypes = data?.tagTypes || []

  return (
    <AutoComplete
      value={value}
      onChange={onChange}
      dataSource={tagTypes}
      filterOption={(inputValue, option) => {
        return option?.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
      }}
    />
  )
}
