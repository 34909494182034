import { useMinimalStudentClassesQuery } from '@thesisedu/feature-classes-react/dist/schema'
import { useNavigate } from '@thesisedu/react'
import { LoadingIndicator, useToast } from '@thesisedu/ui'
import { Refresh } from '@thesisedu/ui/icons'
import React from 'react'

const POLL_INTERVAL = 10000
export interface WaitForApprovalProps {
  classId: string
  onClose: () => void
}
export function WaitForApproval({ classId, onClose }: WaitForApprovalProps) {
  const navigate = useNavigate()
  const toast = useToast()
  const { data } = useMinimalStudentClassesQuery({
    pollInterval: POLL_INTERVAL,
  })
  // This needs to be inside the render function because the component is unmounted before
  // the useEffect is called to react to the data changing.
  const shownRef = React.useRef(false)
  if (data && !data.viewer && !shownRef.current) {
    shownRef.current = true
    toast({
      title: 'Your teacher has modified your account. Please login again.',
      icon: <Refresh />,
      timeout: 10000,
    })
  }
  React.useEffect(() => {
    const hasClass = data?.viewer?.student?.classes.edges.some(edge => edge.node.id === classId)
    if (hasClass) {
      toast({
        status: 'success',
        title: 'You have been accepted into the class!',
      })
      onClose()
      navigate(`/student/classes/${classId}`)
    }
  }, [data])
  return (
    <LoadingIndicator.InlineLabeled
      label={
        <span>
          Wait for your teacher to approve you on their <strong>Students</strong> tab.
        </span>
      }
      style={{ margin: 0 }}
      size={'m'}
    />
  )
}
