import { Feature } from '@thesisedu/feature'
import {
  AssignmentFilterProps,
  AssignmentFilterResource,
} from '@thesisedu/feature-assignments-react'
import { FilterIGMenu } from '@thesisedu/feature-courses-react'
import { BookmarkBook } from '@thesisedu/react/icons'
import React from 'react'

import { TagFilterOperation } from '../../schema'

export function AssignmentsIGFilter({
  filters,
  onChange,
  classId,
  ...rest
}: AssignmentFilterProps) {
  const igTag = filters?.tags?.tags.find(tag => tag.type === 'IMPLEMENTATION_GUIDE')?.name
  return (
    <FilterIGMenu
      classId={classId}
      value={igTag || undefined}
      label={'Lesson'}
      icon={<BookmarkBook />}
      segmentType={'Assignment'}
      onChange={ig => {
        const remainingTags = [
          ...(filters?.tags?.tags.filter(tag => tag.type !== 'IMPLEMENTATION_GUIDE') || []),
          ...(ig
            ? [
                {
                  type: 'IMPLEMENTATION_GUIDE',
                  name: ig,
                },
              ]
            : []),
        ]
        onChange({
          ...filters,
          tags: remainingTags.length
            ? {
                operation: TagFilterOperation.And,
                ...filters?.tags,
                tags: remainingTags,
              }
            : undefined,
        })
      }}
      {...rest}
    />
  )
}

export default function addResources(feature: Feature) {
  feature.resources.addResource<AssignmentFilterResource>({
    type: 'AssignmentFilter',
    identifier: 'implementation-guide',
    component: AssignmentsIGFilter,
    hasValue: filters => !!filters?.tags,
  })
}
