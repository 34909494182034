import { addTeacherPermission } from '@thesisedu/feature-classes-react'
import { ReactUse, ReactFeature, DeepLinkResource } from '@thesisedu/feature-react'
import { UserGroupPrefixDeepLinkRemapper } from '@thesisedu/feature-users-react'

import { PracticesClassPrefixDeepLinkRemapper } from './DeepLink'

export interface PracticesReactOptions {
  useClassPathInDeepLink?: boolean
}

export class PracticesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-practices-react'
  static path = __dirname
  static requires = []
  public options!: PracticesReactOptions

  public reactResources() {
    this.resources.addResource<DeepLinkResource>({
      type: 'DeepLink',
      identifier: 'practices',
      params: [
        {
          identifier: 'id',
          required: false,
        },
      ],
      Component: this.options.useClassPathInDeepLink
        ? PracticesClassPrefixDeepLinkRemapper
        : UserGroupPrefixDeepLinkRemapper,
    })
    addTeacherPermission(this, 'Practices', 'View practices for all students', [
      'VIEW_PRACTICE',
      'VIEW_CLASS_PRACTICES',
      'LIST_STUDENT_PRACTICES',
    ])
    addTeacherPermission(this, 'Practices', 'Comment on student practices', ['COMMENT_ON_PRACTICE'])

    if (this.root.getFeature('@thesisedu/feature-search-react')) {
      require('./resources/search').default(this)
    }
  }
}

export const practicesReactFeature: ReactUse<PracticesReactOptions> = opts => [
  PracticesReactFeature,
  opts,
]
