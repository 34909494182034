import { transparentize } from 'polished'

import { styled, StyledComponent } from './styledTypes'
import { Theme } from './theme'

export function LightOverlayStyle(theme: Theme) {
  return {
    backdropFilter: 'blur(3px) saturate(70%)',
    background: transparentize(0.5, theme['@gray-2']),
  }
}
export const LightOverlay: StyledComponent<'div'> = styled.div(props =>
  LightOverlayStyle(props.theme),
)
export function DarkOverlayStyle(theme: Theme) {
  return {
    backdropFilter: 'blur(3px)',
    background: transparentize(0.5, theme['@gray-7']),
  }
}
export const DarkOverlay: StyledComponent<'div'> = styled.div(props =>
  DarkOverlayStyle(props.theme),
)
