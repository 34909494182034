import { Form, useToast } from '@thesisedu/ui'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { PresetForm } from './PresetForm'
import { PresetsRoutesProps } from './PresetsRoutes'
import { useCreateClassGroupPresetMutation } from '../../queries/useCreateClassGroupPresetMutation'
import { ClassGroupPresetInput, GroupSubmissionType } from '../../schema'

export function CreatePresetPage({ classId }: PresetsRoutesProps) {
  const form = Form.useForm<ClassGroupPresetInput>({
    defaultValues: {
      submissionType: GroupSubmissionType.Group,
    },
  })
  const navigate = useNavigate()
  const toast = useToast()
  const [create] = useCreateClassGroupPresetMutation({
    onCompleted() {
      toast.success('Preset created')
      navigate('..')
    },
  })
  return (
    <Form
      onFinish={async values => {
        await create({
          variables: {
            input: {
              id: classId,
              preset: values,
            },
          },
        })
      }}
      form={form}
    >
      <PresetForm classId={classId} />
    </Form>
  )
}
