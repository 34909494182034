import { s } from '@thesisedu/ui'
import React from 'react'

import { AudioOverlay } from './AudioOverlay'
import { EventsContainer } from './EventsContainer'
import {
  MediaPlaybackContextProvider,
  MediaPlaybackContextProviderProps,
  useMediaPlaybackContext,
} from './MediaPlaybackContext'
import { PlayerCaption } from './PlayerCaption'
import { PlayerFooter, PlayerFooterCompactMode, PlayerFooterProps } from './PlayerFooter'
import { FooterContainer } from './PlayerFooterContainer'
import { FooterWrapper, PlayerWrapper } from './PlayerWrapper'
import { useResizeObserver } from '../dom/useResizeObserver'
import { isIOS, isNative } from '../isNative'
import { styledContainer } from '../styled'

const COMPACT_SIZE_WIDTH = 400
const ULTRA_COMPACT_SIZE_WIDTH = 300

function useFooterKey(fullscreen?: boolean) {
  const [footerKey, setFooterKey] = React.useState(0)
  React.useEffect(() => {
    setFooterKey(k => k + 1)
  }, [fullscreen])
  return footerKey
}
const noop = () => {}

/**
 * Hook for binding event listeners to the player.
 */
function useEvent(
  event: string,
  ref: React.RefObject<HTMLVideoElement | HTMLAudioElement | undefined>,
  callback: EventListener = noop,
) {
  React.useEffect(() => {
    if (!ref.current) return
    const el = ref.current
    el.addEventListener(event, callback)
    return () => el.removeEventListener(event, callback)
  }, [callback, event, ref])
}

export interface PlayerSpecificProps {
  children: React.ReactElement<
    React.DetailedHTMLProps<
      React.HTMLProps<HTMLVideoElement | HTMLAudioElement>,
      HTMLVideoElement | HTMLAudioElement
    >
  >
  playerRef?: React.MutableRefObject<HTMLVideoElement | HTMLAudioElement | undefined | null>
  useAudioOverlay?: boolean
  forceFooter?: boolean
  footerProps?: Partial<PlayerFooterProps>
  /** If this is true, fills the screen regardless of aspect ratio. */
  fill?: boolean
  /** The current subtitles to display above the footer. */
  captions?: (string | React.ReactElement)[]
  style?: React.CSSProperties
}
function InnerPlayer({
  children,
  playerRef: propPlayerRef,
  forceFooter,
  footerProps,
  useAudioOverlay,
  fill,
  captions,
  style,
}: PlayerSpecificProps) {
  const {
    playerRef,
    setDuration,
    setProgress,
    setIsBuffering,
    onSetPlaying,
    onSeek,
    duration,
    isPlaying,
    setOnSeek,
    playbackSpeed,
    volume,
    fullscreen,
  } = useMediaPlaybackContext()
  const contextDurationRef = React.useRef(duration)
  contextDurationRef.current = duration
  const [_footerVisible, setFooterVisible] = React.useState(false)
  const [overFooter, setOverFooter] = React.useState(false)
  const [playingTimeoutVisible, setPlayingTimeoutVisible] = React.useState(isPlaying)
  React.useEffect(() => {
    if (isPlaying) {
      setPlayingTimeoutVisible(true)
      const timeout = setTimeout(() => {
        setPlayingTimeoutVisible(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [isPlaying])
  const footerVisible = _footerVisible || overFooter || playingTimeoutVisible
  const containerRef = React.useRef<any>()
  const { width } = useResizeObserver({
    ref: containerRef,
  })
  let compactMode: PlayerFooterCompactMode | undefined = undefined
  if (!isNative && !!width && width > 0 && width < COMPACT_SIZE_WIDTH) {
    compactMode = PlayerFooterCompactMode.Compact
  } else if (!!width && width > 0 && width < ULTRA_COMPACT_SIZE_WIDTH) {
    compactMode = PlayerFooterCompactMode.UltraCompact
  }

  // This is to make sure we re-render the footer whenever fullscreen is changed
  // so the tools show with the proper container.
  const footerKey = useFooterKey(fullscreen)

  React.useLayoutEffect(() => {
    if (playerRef.current?.pause) {
      if (isPlaying && playerRef.current.paused) {
        playerRef.current.play()
      } else if (!isPlaying && !playerRef.current.paused) {
        playerRef.current.pause()
      }
    }
  }, [isPlaying])
  React.useEffect(() => {
    if (playerRef.current?.playbackRate) {
      playerRef.current.playbackRate = playbackSpeed
    }
  }, [playbackSpeed])
  React.useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume = volume
    }
  }, [volume])
  React.useEffect(() => {
    setOnSeek(seconds => {
      if (playerRef.current) {
        playerRef.current.currentTime = seconds
      }
    })
  }, [])
  const events = React.useMemo(
    () => ({
      durationChange: (event: HTMLMediaElementEventMap['durationchange']) => {
        let d = null
        if (
          playerRef.current &&
          playerRef.current.duration &&
          playerRef.current.duration < Infinity &&
          playerRef.current.duration > -Infinity
        ) {
          d = playerRef.current.duration
        }
        if (d && d < Infinity && d > -Infinity) {
          setDuration(d)
        }
      },
      updateProgress: () => {
        if (playerRef.current) {
          const loadedSeconds = playerRef.current.buffered.length
            ? playerRef.current.buffered.end(playerRef.current.buffered.length - 1)
            : 0
          const playedSeconds = playerRef.current.currentTime
          const duration =
            !isNaN(playerRef.current.duration) && isFinite(playerRef.current.duration)
              ? playerRef.current.duration
              : contextDurationRef.current ?? 0
          setProgress({
            loaded: duration ? loadedSeconds / duration : 0,
            loadedSeconds,
            played: duration ? playedSeconds / duration : 0,
            playedSeconds,
          })
        }
      },
      waiting: () => {
        setIsBuffering(true)
      },
      playing: () => {
        setIsBuffering(false)
      },
      play: () => {
        onSetPlaying(true)
        setIsBuffering(false)
      },
      ended: () => {
        onSetPlaying(false)
        onSeek(0)
      },
    }),
    [],
  )
  useEvent('durationchange', playerRef, events.durationChange)
  useEvent('timeupdate', playerRef, events.updateProgress)
  useEvent('progress', playerRef, events.updateProgress)
  useEvent('waiting', playerRef, events.waiting)
  useEvent('canplay', playerRef, events.playing)
  useEvent('playing', playerRef, events.playing)
  useEvent('play', playerRef, events.play)
  useEvent('ended', playerRef, events.ended)

  return (
    <PlayerWrapper containerRef={containerRef}>
      <PlayerContainer
        fullscreen={fullscreen}
        ref={containerRef}
        className={'player-container'}
        fill={!!fill}
        style={style}
      >
        <PlayerOuter
          fullscreen={fullscreen}
          isAudio={!!useAudioOverlay}
          className={'player-outer'}
          fill={!!fill}
        >
          {useAudioOverlay ? <AudioOverlay isCompact={compactMode !== undefined} /> : null}
          {React.useMemo(() => {
            return React.cloneElement(children, {
              ref: (c: HTMLVideoElement | HTMLAudioElement | any) => {
                playerRef.current = c
                if (propPlayerRef) {
                  propPlayerRef.current = c
                }
              },
              className: `player ${children.props.className}`,
              style: {
                ...children.props.style,
                ...PlayerInnerStyles,
                width: '100%',
                height: '100%', // This is required for web to display videos properly.
                display: isNative ? undefined : 'block',
                transform: 'scale(1.01)', // Black bar at the right side of videos.
              } as any,
            })
          }, [children, isNative])}
        </PlayerOuter>
        <EventsContainer
          footerVisible={
            useAudioOverlay ? true : isPlaying && !forceFooter ? !!footerVisible : true
          }
          setFooterVisible={setFooterVisible}
          setOverFooter={setOverFooter}
        />
        <FooterContainer
          className={'footer'}
          footerVisible={
            useAudioOverlay ? true : isPlaying && !forceFooter ? !!footerVisible : true
          }
          isAudio={!!useAudioOverlay}
        >
          <FooterWrapper
            setOverFooter={setOverFooter}
            footerVisible={
              useAudioOverlay ? true : isPlaying && !forceFooter ? !!footerVisible : true
            }
          >
            <PlayerFooter
              {...footerProps}
              key={footerKey}
              compact={compactMode}
              hideFullscreen={footerProps?.hideFullscreen || fill}
              visible={useAudioOverlay ? true : isPlaying && !forceFooter ? !!footerVisible : true}
            />
          </FooterWrapper>
        </FooterContainer>
        <PlayerCaption
          captions={captions}
          footerVisible={
            useAudioOverlay ? true : isPlaying && !forceFooter ? !!footerVisible : true
          }
          playerWidth={width}
        />
      </PlayerContainer>
    </PlayerWrapper>
  )
}

type PlayerWithoutContextProps = PlayerSpecificProps & { noContext: true }
export type PlayerProps =
  | (PlayerSpecificProps & Omit<MediaPlaybackContextProviderProps, 'children'>)
  | PlayerWithoutContextProps
export function Player({
  children,
  playerRef,
  forceFooter,
  footerProps,
  useAudioOverlay,
  fill,
  captions,
  ...providerProps
}: PlayerProps) {
  const playerProps: PlayerSpecificProps = {
    children,
    playerRef,
    forceFooter,
    footerProps,
    useAudioOverlay,
    fill,
    captions,
  }
  let content
  if ((providerProps as PlayerWithoutContextProps).noContext) {
    content = <InnerPlayer {...playerProps} />
  } else {
    content = (
      <MediaPlaybackContextProvider {...providerProps}>
        <InnerPlayer {...playerProps} />
      </MediaPlaybackContextProvider>
    )
  }

  return <s.ThemeProvider colorVariant={'dark'}>{content}</s.ThemeProvider>
}

export const PlayerContainer = styledContainer<{ fullscreen?: boolean; fill: boolean }>`
  position: relative;
  width: 100%;
  height: ${props => ((props.fullscreen && !isIOS) || props.fill ? '100%' : 'auto')};
  ${props => (props.fill ? 'flex: 1;' : '')}
  background: black;
  overflow: hidden;
`
export const PlayerOuter = styledContainer<{
  fullscreen?: boolean
  fill: boolean
  isAudio: boolean
}>`
  position: relative;
  padding-top: ${props =>
    props.fill
      ? '0'
      : isNative
      ? '56.25%'
      : props.isAudio
      ? 'calc(max(100px, min(20%, 100vh)))'
      : 'calc(min(56.25%, 100vh))'};
  width: ${isNative ? '100%' : 'calc(100% + 2px)'};
  transform: translateX(-1px);
  margin: 0 auto;
  height: ${props => ((props.fullscreen && !isIOS) || props.fill ? '100%' : 'auto')};
  max-width: ${isNative ? '10000px' : 'calc(100vh * (16 / 9))'};
  z-index: 0;
`
const PlayerInnerStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 1,
}
