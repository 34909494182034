import assignmentSettingsOptions from './assignmentSettingsOptions'
import canDeleteAssignment from './canDeleteAssignment'
import gradingModalNavigationItems from './gradingModalNavigationItems'
import { AssignmentsReactFeature } from '../../AssignmentsReactFeature'

export default function (feature: AssignmentsReactFeature) {
  gradingModalNavigationItems(feature)
  canDeleteAssignment(feature)
  assignmentSettingsOptions(feature)
}
