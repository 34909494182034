import { Tag, Tag$ } from '@thesisedu/ui'
import React from 'react'

import { ShareIndicatorProps } from './types'
import { useShareIndicator } from './useShareIndicator'

export function ShareIndicator({
  shareable,
  ...rest
}: ShareIndicatorProps & Partial<Tag$.TagProps>) {
  const { message } = useShareIndicator({ shareable })
  if (message) {
    return <Tag monoColor={'blue'} {...rest} children={message} />
  } else {
    return null
  }
}
