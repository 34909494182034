import { Select } from '@thesisedu/ui'
import React from 'react'

import { useDimensionContexts } from './useDimensionContexts'
import { ReportResources, useReportResources } from '../../execute/input'
import { ReportDimensionResource, RunReportOpts, RunReportQuery } from '../../types'
import { ReportResultsProps } from '../ReportResults'
import { getAxesResponsibility, isMetrics } from '../grid/axes'
import { getDimensionResults, getDimensionResultsFromSummary } from '../grid/dimensions'
import { getSummaryCells } from '../grid/summary'

function getDimensionResponsibilityType(
  resources: ReportResources,
  report: ReportResultsProps['report'],
  dimension: string,
): 'row' | 'col' | null {
  const responsibility = getAxesResponsibility(resources, report)
  if (!isMetrics(responsibility.row) && responsibility.row.identifier === dimension) return 'row'
  else if (!isMetrics(responsibility.col) && responsibility.col.identifier === dimension)
    return 'col'
  else return null
}

export interface UseDimensionValueSelectValuesOpts {
  dimension?: ReportDimensionResource<any, any>
  report: RunReportOpts
  data: RunReportQuery['runReport']
}
export function useDimensionValueSelectValues({
  dimension,
  report,
  data,
}: UseDimensionValueSelectValuesOpts) {
  const dimensionContexts = useDimensionContexts()
  const resources = useReportResources()
  const responsibility = getAxesResponsibility(resources, report)
  const summaryConfig = !isMetrics(responsibility.row) && responsibility.row.summary
  const summaryResult = summaryConfig
    ? getSummaryCells(responsibility, data, report, resources.metrics)
    : undefined
  if (!dimension) return []
  const graphContext = dimensionContexts[dimension.identifier]
  const responsibilityType = getDimensionResponsibilityType(resources, report, dimension.identifier)
  if (summaryResult && responsibilityType === 'row') {
    const summaryItems = getDimensionResultsFromSummary(summaryResult)
    return summaryItems.map(item => ({
      label: dimension.graph.label({}, graphContext, item.summaryItem) || '',
      value: JSON.stringify(item.id),
      style: { paddingLeft: 12 + 8 * (item.depth || 0) },
    }))
  } else {
    const dimensionValues = responsibilityType === 'col' ? getDimensionResults(data) : []
    return dimensionValues.map(dimensionValue => {
      return {
        label:
          dimension.graph.label(
            dimensionValue[dimension.inputKey],
            graphContext,
            dimensionValue.summaryItem,
          ) || '',
        value: JSON.stringify(dimensionValue[dimension.inputKey]),
      }
    })
  }
}

export interface DimensionValueSelectProps extends Pick<ReportResultsProps, 'data' | 'report'> {
  dimension: ReportDimensionResource<any, any>
  value?: any
  onChange?: (value: any) => void
}
export function DimensionValueSelect({
  dimension,
  value,
  onChange,
  data,
  report,
}: DimensionValueSelectProps) {
  const options = useDimensionValueSelectValues({
    data,
    dimension,
    report,
  })
  const valueIsValid = value ? options.some(opt => JSON.stringify(value) === opt.value) : undefined
  return (
    <Select
      style={{ width: '100%' }}
      value={valueIsValid ? JSON.stringify(value) : undefined}
      size={'small'}
      onValueChange={(v: any) => {
        if (v && onChange) {
          onChange(JSON.parse(v))
        }
      }}
    >
      {options.map(option => {
        return <Select.Item key={option.value} value={option.value} children={option.label} />
      })}
    </Select>
  )
}
