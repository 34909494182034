import {
  LogViewer as PatternLogViewer,
  LogViewerSearch,
  LogViewerToolbarContext,
} from '@patternfly/react-log-viewer'
import { darken } from 'polished'
import React from 'react'

import { styled } from '../styled'

interface LogViewerSearchSyncProps {
  defaultSearch?: string
}
function LogViewerSearchSync({ defaultSearch }: LogViewerSearchSyncProps) {
  const { setSearchedInput } = React.useContext(LogViewerToolbarContext)
  React.useEffect(() => {
    if (defaultSearch) {
      setTimeout(() => {
        setSearchedInput(defaultSearch)
      }, 10)
    }
  }, [defaultSearch])
  return null
}

export interface LogViewerProps {
  text?: string
  defaultSearch?: string
}
export function LogViewer({ text, defaultSearch }: LogViewerProps) {
  return (
    <Container>
      <PatternLogViewer
        data={text || ''}
        hasLineNumbers={false}
        height={'100%'}
        width={'100%'}
        isTextWrapped
        toolbar={
          <SearchContainer>
            <LogViewerSearchSync defaultSearch={defaultSearch} />
            <LogViewerSearch minSearchChars={2} placeholder={'Search logs...'} />
          </SearchContainer>
        }
      />
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: ${props => props.theme['@size-xs']};
  box-sizing: border-box;
  position: relative;
  font-family: ${props => props.theme['@code-family']};
  background: ${props => darken(0.1, props.theme['@gray-7'])};
  font-size: ${props => props.theme['@font-size-sm']};
  color: ${props => props.theme['@gray-1']};
  .pf-c-log-viewer__text {
    white-space: pre-wrap;
  }
  .pf-c-log-viewer__scroll-container {
    outline: none !important;
    overflow: auto !important;
  }
  .pf-c-text-input-group__text-input {
    background: transparent !important;
    padding-left: 20px;
  }
  .pf-m-current {
    background: ${props => props.theme['@orange']} !important;
    color: ${props => props.theme['@gray-7']} !important;
  }
  .pf-m-match {
    color: ${props => props.theme['@gray-0']} !important;
    background: ${props => props.theme['@gray-7']} !important;
  }
`
const SearchContainer = styled.div`
  position: absolute;
  top: ${props => props.theme['@size-xs']};
  right: ${props => props.theme['@size-xs']};
  z-index: 2;
`
