import React from 'react'
import { FieldPath, FieldValues } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import FileField, * as FileField$ from '../FileField'

function _FormFileField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(
  props: ConnectedFormFieldProps<FileField$.FileFieldProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <FormFieldConnector {...props}>
      <FileField ref={ref} />
    </FormFieldConnector>
  )
}
export const FormFileField = React.forwardRef(_FormFileField)
