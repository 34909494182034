import { SubListItemProps } from '@thesisedu/native/dist/list'
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'

import { FormItemProps } from './FormItem'

export interface FormListItemProps<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
> extends FormItemProps<TFieldValues, FieldName> {
  children: React.ReactElement<
    SubListItemProps & ControllerRenderProps<TFieldValues, FieldName> & Record<string, any>
  >
  listItemProps?: SubListItemProps['listItemProps']
}
/** @deprecated */
export function FormListItem<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>(props: FormListItemProps<TFieldValues, FieldName>) {
  return null
}
