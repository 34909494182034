import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useUseAssignmentSubmissionMutation as useMutation } from '../schema'

export const useUseAssignmentSubmissionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error using that attempt.'),
    refetchQueries: ['allAssignmentSubmissions', 'assignmentSubmissions'],
    awaitRefetchQueries: true,
    ...opts,
  })
