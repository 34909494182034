import { useMutateHook } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import React from 'react'

import { ClassRouteHook } from '../../../types'

export interface ClassRouteProps {
  group: string
}
export function ClassRoute({ group }: ClassRouteProps) {
  const viewer = useViewerContext(false)
  return useMutateHook<ClassRouteHook>('classes-react:class-route', <></>, { viewer, group })
}
