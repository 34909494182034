import { Button, Tooltip } from '@thesisedu/ui'
import { Message } from '@thesisedu/ui/icons'
import React from 'react'

import { PlaybackFooterLocation } from '../../record/PlaybackFooterContext'
import { useSelectedSidebarTabContext } from '../../sidebar/SelectedSidebarTabContext'
import { MediaComments } from '../../view/MediaComments'
import { MediaCommentsDecorator } from '../../view/MediaCommentsDecorator'
import { useMediaViewContext } from '../../view/MediaViewContext'
import { PlaybackFooterItem } from '../api/PlaybackFooterItem'
import { SidebarTab } from '../api/SidebarTab'

export function CommentsPlugin() {
  const { mediaId } = useMediaViewContext(false) ?? {}
  const { setSelectedTab } = useSelectedSidebarTabContext(false) ?? {}
  const [selectedCommentId, setSelectedCommentId] = React.useState<string | undefined>(undefined)
  const commentsContainer = React.useRef<HTMLDivElement>(null)
  if (!mediaId) return null
  return (
    <>
      <SidebarTab title={'Comments'} icon={<Message />} weight={1}>
        <div ref={commentsContainer} style={{ flex: 1, display: 'flex' }}>
          <MediaComments mediaId={mediaId} selectedCommentId={selectedCommentId} />
        </div>
      </SidebarTab>
      <PlaybackFooterItem location={PlaybackFooterLocation.Track} weight={1}>
        <MediaCommentsDecorator
          mediaId={mediaId}
          onClick={comment => {
            setSelectedTab?.('Comments')
            setSelectedCommentId(comment.id)
          }}
        />
      </PlaybackFooterItem>
      <PlaybackFooterItem location={PlaybackFooterLocation.Toolbar} weight={1}>
        <Tooltip title={'Add comment here'}>
          <Button
            variant={'chromeless'}
            children={<Message />}
            onPress={() => {
              setSelectedTab?.('Comments')
              setTimeout(() => {
                commentsContainer.current?.querySelector<HTMLElement>('#post-comment')?.focus()
              }, 500)
            }}
            style={{ color: 'white' }}
          />
        </Tooltip>
      </PlaybackFooterItem>
    </>
  )
}
