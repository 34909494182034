import { useClassSyncSummary } from '@thesisedu/feature-class-sync-react/dist/useClassSyncSummary'
import {
  ClassConfigurationFieldProps,
  ClassConfigurationFieldResource,
  useClassConfiguration,
  useSelectedClassId,
} from '@thesisedu/feature-classes-react'
import {
  Body,
  Bold,
  Button,
  Checkbox as AntCheckbox,
  CheckboxProps,
  HSpaced,
  Modal,
  VSpaced,
  AntiIcon,
  Block,
  H3Alternate,
  Link,
} from '@thesisedu/react'
import { Check, CloudSync, HelpCircle, Rewind } from '@thesisedu/react/icons'
import { Form } from 'antd'
import React from 'react'

function Checkbox({ onChange, ...rest }: CheckboxProps) {
  const selectedClassId = useSelectedClassId()
  const { syncs } = useClassSyncSummary(selectedClassId || '')
  const hasSyncs = syncs?.length > 0
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={'Are you sure?'}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key={'save'}
            type={'primary'}
            onClick={() => {
              if (onChange) {
                onChange({
                  target: {
                    checked: true,
                  },
                } as any)
              }
              setVisible(false)
            }}
          >
            Grade late incomplete assignments as 0%
          </Button>,
        ]}
        width={600}
        style={{ maxWidth: '90vw' }}
      >
        <Block marginTop={'@size-m'}>
          <VSpaced space={'@size-m'}>
            <HSpaced align={'flex-start'}>
              <Block marginTop={'@size-xxs'}>
                <H3Alternate isBlock={false}>
                  <AntiIcon color={'@green'} icon={<Check />}>
                    <Rewind />
                  </AntiIcon>
                </H3Alternate>
              </Block>
              <Body>
                <Bold>Be careful, this applies retroactively.</Bold> This means if you have students
                who have not submitted anything to an assignment with a due date in the past, they
                will receive a 0% for that in the gradebook. If you would like to reset their grade,
                you can either remove the due date from the assignment, or give them a grade
                manually yourself.
              </Body>
            </HSpaced>
            {hasSyncs ? (
              <HSpaced align={'flex-start'}>
                <Block marginTop={'@size-xxs'}>
                  <VSpaced align={'center'} space={'@size-xxs'}>
                    <H3Alternate isBlock={false}>
                      <AntiIcon>
                        <Rewind />
                      </AntiIcon>
                    </H3Alternate>
                    <H3Alternate isBlock={false} style={{ marginTop: 0 }}>
                      <CloudSync />
                    </H3Alternate>
                  </VSpaced>
                </Block>
                <Body isBlock>
                  <Bold>However, this does not apply retroactively with synced assignments.</Bold>{' '}
                  If you sync your assignments with a third-party LMS, any assignments with due
                  dates without submissions in the past{' '}
                  <Bold>will not be updated with a 0% on your LMS.</Bold> To sync the 0% in the
                  past, you will have to trigger a re-sync, by editing the assignment and then
                  clicking "Save Changes."
                </Body>
              </HSpaced>
            ) : null}
            <HSpaced align={'flex-start'}>
              <Block marginTop={'@size-xxs'}>
                <H3Alternate isBlock={false}>
                  <HelpCircle />
                </H3Alternate>
              </Block>
              <Body>
                <Bold>As always, we are here to help if you have questions!</Bold> To reach support,
                start by clicking the blue chat bubble in the bottom-right corner of the page. You
                may also visit our <Link to={'/teacher/resources'}>help page.</Link>
              </Body>
            </HSpaced>
          </VSpaced>
        </Block>
      </Modal>
      <AntCheckbox
        {...rest}
        onChange={e => {
          if (e.target.checked) {
            setVisible(true)
          } else if (onChange) {
            onChange(e)
          }
        }}
      />
    </>
  )
}

function Field({ fieldPrefix }: ClassConfigurationFieldProps) {
  return (
    <Form.Item
      name={[...fieldPrefix, GRADE_INCOMPLETE_LATE_AS_ZERO]}
      valuePropName={'checked'}
      extra={
        'If this is checked, if a student has not submitted work for an assignment by the due date, they will ' +
        'automatically receive a 0% in the gradebook until that work is submitted.'
      }
    >
      <Checkbox>Grade late incomplete assignments as 0%</Checkbox>
    </Form.Item>
  )
}

export function useGradeIncompleteLateAsZero(classId?: string) {
  return useClassConfiguration<boolean>(GRADE_INCOMPLETE_LATE_AS_ZERO, classId)
}

export const GRADE_INCOMPLETE_LATE_AS_ZERO = 'gradeIncompleteLateAsZero'
const config: ClassConfigurationFieldResource = {
  type: 'ClassConfigurationField',
  identifier: GRADE_INCOMPLETE_LATE_AS_ZERO,
  group: 'Assignments & Grading',
  renderField: Field,
}
export default config
