import { ClassRoutes, WrapWithClassContext } from '@thesisedu/feature-classes-react'
import { ClassFragment as ClassesClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { ClassRouteNav } from './ClassRouteNav'
import { CoursesContextProvider } from '../../../CoursesContextProvider'
import { CourseViewerModal } from '../../../course_viewer/CourseViewerModal'
import { ClassFragment } from '../../../schema'
import { CourseClassContextHook } from '../../../types'

export interface ClassRouteWithClassProps {
  cls: ClassFragment
  group: string
}
export function ClassRouteWithClass({ cls, group }: ClassRouteWithClassProps) {
  const innerContextContent = (
    <WrapWithClassContext cls={cls as ClassesClassFragment} group={group}>
      <>
        <CourseViewerModal />
        <ClassRouteNav cls={cls} group={group} />
        <ClassRoutes cls={cls as ClassesClassFragment} group={group} />
      </>
    </WrapWithClassContext>
  )
  const innerContent = useMutateHook<CourseClassContextHook>(
    'courses-react:course-class-context',
    innerContextContent,
    {
      cls,
      group,
    },
  )

  return <CoursesContextProvider class={cls}>{innerContent}</CoursesContextProvider>
}
