import { UserAvatar } from '@thesisedu/feature-attachments-react'
import { BlockEditor, Legacy } from '@thesisedu/feature-widgets-react'
import { Text, styled, s, HSpaced } from '@thesisedu/ui'
import { MiddotDelimitedList } from '@thesisedu/web'
import React from 'react'

import { CommentProps } from './types'
import { useComment } from './useComment'

export function LongComment(props: CommentProps) {
  const { user, actionLinks, metadataSegments, sideContent, customContent } = useComment({
    ...props,
    shorthandPosted: false,
  })

  const avatarSize = 42
  const avatar = user ? <UserAvatar user={user as any} size={avatarSize} shape={'square'} /> : null
  return (
    <Container>
      <LeftContent>
        {sideContent}
        {avatar}
      </LeftContent>
      <Header>
        <Text
          level={'l'}
          weight={'medium'}
          color={props.isHighlighted ? 'primary' : undefined}
          style={{ lineHeight: 1.2 }}
        >
          {user ? user.name || user.username : 'Anonymous'}
        </Text>
        <Text color={'secondary'} level={'s'}>
          <MiddotDelimitedList>
            {metadataSegments.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </MiddotDelimitedList>
        </Text>
      </Header>
      <RightContent className={'edit'}>{actionLinks}</RightContent>
      <Content className={'comment-content'}>
        {customContent || (
          <BlockEditor
            id={`comment-${props.comment.id}`}
            readOnly
            defaultValue={Legacy.getBlockValueFromAnyValue(
              props.comment.blockComment || props.comment.comment,
            )}
            defaultValueKey={props.comment.updatedAt}
          />
        )}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-areas:
    'leftContent header  rightContent'
    'contentLeft content content';
  grid-template-columns: max-content auto min-content;
  align-items: center;
  gap: ${s.size('xs')} ${s.size('s')};
`
const LeftContent = styled(HSpaced)`
  grid-area: leftContent;
`
const Header = styled.div`
  grid-area: header;
`
const RightContent = styled(HSpaced)`
  grid-area: rightContent;
  white-space: nowrap;
`
const Content = styled.div`
  grid-area: content;
`
