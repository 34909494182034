import { IssuesCloseOutlined } from '@ant-design/icons'
import { Grade } from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { SegmentInfoItem } from '@thesisedu/feature-courses-react'
import { Tooltip } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { useImplementationGuideContext } from '../contexts/ImplementationGuideContext'

export interface SegmentInfoGradeProps {
  segmentName: string
}
export function SegmentInfoGrade({ segmentName }: SegmentInfoGradeProps) {
  const classId = useSelectedClassId(true)
  const context = useImplementationGuideContext()
  if (
    context?.gradeReport[segmentName] !== null &&
    context?.gradeReport[segmentName] !== undefined
  ) {
    return (
      <Tooltip title={'View Grade Report'} mouseEnterDelay={1}>
        <Link
          to={`/teacher/reports?classIds=${encodeURIComponent(classId)}&igIds=${encodeURIComponent(
            segmentName,
          )}`}
        >
          <SegmentInfoItem icon={<IssuesCloseOutlined />}>
            <Grade
              grade={context.gradeReport[segmentName]!}
              hideProgress
              style={{ display: 'inline-block', padding: 0 }}
            />{' '}
            average grade
          </SegmentInfoItem>
        </Link>
      </Tooltip>
    )
  } else {
    return null
  }
}
