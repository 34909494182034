import { BareDateField } from './BareDateField'
import { Calendar } from './Calendar'
import { DateField } from './DateField'
import { DatePickerField } from './DatePickerField'
import { DateRangePickerField } from './DateRangePickerField'
import { RangeCalendar } from './RangeCalendar'
import { TimeField } from './TimeField'
import { parse as parseDate } from './parse'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './DateField'
export * from './DateSegment'
export * from './Calendar'
export * from './CalendarCell'
export * from './CalendarGrid'
export * from './DatePickerField'
export * from './DateRangePickerField'
export * from './RangeCalendar'
export * from './BareDateField'
export * from './TimeField'

export { parse as parseDate } from './parse'

export default withSubComponents(DateField, {
  Bare: BareDateField,
  TimeField,
  Calendar,
  RangeCalendar,
  DatePickerField,
  DateRangePickerField,
  parseDate,
})
