import { Table } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { CourseViewFragment } from '../schema'

export interface CourseViewsTableProps {
  courseViews: CourseViewFragment[]
}
export function CourseViewsTable({ courseViews }: CourseViewsTableProps) {
  return (
    <Table<CourseViewFragment> rowKey={'id'} pagination={false} dataSource={courseViews}>
      <Table.Column<CourseViewFragment>
        title={'Name'}
        dataIndex={'name'}
        render={(name, record) => <Link to={record.id}>{name}</Link>}
      />
      <Table.Column<CourseViewFragment>
        dataIndex={'id'}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(_, record) => (
          <>
            <Link to={record.id} style={{ whiteSpace: 'nowrap' }}>
              Edit &rarr;
            </Link>
          </>
        )}
      />
    </Table>
  )
}
