import { PromptContextValue, createPromptContext } from '@thesisedu/react'
import { useHash } from '@thesisedu/web'
import React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'

const CourseViewerPromptContext = createPromptContext()

export interface CourseViewerModalContextValue extends PromptContextValue {
  segmentId?: string
  setSegmentId: (segmentId: string | undefined, defaultTab?: string) => void
  hidden?: boolean
  /** Use this to hide the viewer without closing it completely. */
  setHidden: (hidden: boolean, reason: string) => void
}
export const CourseViewerModalContext = React.createContext<
  CourseViewerModalContextValue | undefined
>(undefined)

export function CourseViewerModalContextProvider({ children }: React.PropsWithChildren<object>) {
  const [searchParams] = useSearchParams()
  const promptContextResult = CourseViewerPromptContext.usePromptContextProvider()
  const navigate = useNavigate()
  const [hiddenReasons, setHiddenReasons] = React.useState<string[]>([])
  const hidden = !!hiddenReasons.length
  const segmentId = searchParams.get('viewSegment')
  const setSegmentId = (segmentId: string | undefined, defaultTab?: string, replace?: boolean) => {
    if (segmentId) {
      navigate(
        `?${new URLSearchParams({ viewSegment: segmentId })}${defaultTab ? `#${defaultTab}` : ''}`,
        { replace },
      )
    } else {
      navigate(window.location.pathname, { replace })
    }
  }
  const [, setTab] = useHash()

  React.useEffect(() => {
    if (!segmentId) {
      setTab(undefined)
      promptContextResult.context.clearPrompts()
    }
  }, [segmentId])

  return (
    <>
      {promptContextResult.children}
      <CourseViewerModalContext.Provider
        value={{
          ...promptContextResult.context,
          hidden,
          setHidden: (hidden, reason) => {
            setHiddenReasons(reasons => {
              const contains = reasons.includes(reason)
              if (hidden && !contains) {
                return [...reasons, reason]
              } else if (!hidden && contains) {
                return reasons.filter(r => r !== reason)
              } else return reasons
            })
          },
          segmentId: segmentId || undefined,
          setSegmentId: (segId, defTab) => {
            promptContextResult.context.checkForPrompts(() => {
              setSegmentId(segId, defTab, !!defTab)
            })
          },
        }}
        children={children}
      />
    </>
  )
}

export function useCourseViewerModalContext(): CourseViewerModalContextValue | undefined
export function useCourseViewerModalContext(
  require: false,
): CourseViewerModalContextValue | undefined
export function useCourseViewerModalContext(require: true): CourseViewerModalContextValue
export function useCourseViewerModalContext(
  require?: boolean,
): CourseViewerModalContextValue | undefined {
  const context = React.useContext(CourseViewerModalContext)
  if (!context && require) {
    throw new Error('CourseViewerModalContext is required, yet not provided.')
  }
  return context
}

export function useCourseModalPrompt(enabled: boolean, name: string, message: string) {
  const { addPrompt, removePrompt } = useCourseViewerModalContext(false) || {}
  React.useEffect(() => {
    if (addPrompt && removePrompt) {
      if (enabled) {
        addPrompt(name, message)
        return () => removePrompt(name)
      }
    }
  }, [enabled])
}
