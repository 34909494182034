import { PerformanceConfig } from '@thesisedu/feature-assignments-core'
import MediaReactFeature, { MediaTypeSelect } from '@thesisedu/feature-media-react'
import { useFeature } from '@thesisedu/feature-react'
import {
  DynamicVideoField,
  useShouldRenderDynamicVideoField,
} from '@thesisedu/feature-video-on-demand-react'
import { VSpaced } from '@thesisedu/ui'
import { Body, Row, SecondsInput } from '@thesisedu/web'
import { Checkbox, Col, Form } from 'antd'
import React from 'react'

import { QuestionProps } from '../../../types'
import { QuestionAdditionalFields } from '../QuestionAdditionalFields'
import { QuestionInstructions } from '../QuestionInstructions'

export interface PerformanceConfigFieldsProps {
  config?: PerformanceConfig
  onChange?: (config: PerformanceConfig) => void
}
export function PerformanceConfigFields({ config, onChange }: PerformanceConfigFieldsProps) {
  const shouldRenderDynamicVideoField = useShouldRenderDynamicVideoField()
  const mediaOptions = useFeature(MediaReactFeature).options
  const anyToolEnabled = mediaOptions.enablePitchTool || mediaOptions.enableMetronomeTool
  return (
    <Row>
      <Col xs={24} lg={12}>
        {config?.disableVod || !shouldRenderDynamicVideoField ? null : (
          <Form.Item
            label={'Video'}
            extra={
              'If you would like students to create their recording to a video, specify one here.'
            }
          >
            <DynamicVideoField
              value={config?.vodId}
              onChange={vodId => {
                if (onChange) {
                  onChange({
                    ...config,
                    vodId,
                  })
                }
              }}
            />
          </Form.Item>
        )}
        <Form.Item label={'Maximum Length'} extra={'The maximum length of each recording.'}>
          <SecondsInput
            value={config?.maxLengthSeconds}
            onChange={e => {
              if (onChange) {
                onChange({
                  ...config,
                  maxLengthSeconds:
                    e !== null && e !== undefined
                      ? parseFloat(e?.toString() || '') || 0
                      : undefined,
                })
              }
            }}
          />
        </Form.Item>
        <Form.Item label={'Media Type'}>
          <MediaTypeSelect
            value={config?.mediaType}
            onChange={types => {
              if (onChange) {
                onChange({
                  ...config,
                  mediaType: types,
                })
              }
            }}
          />
        </Form.Item>
      </Col>
      <Col xs={24} lg={12}>
        {anyToolEnabled ? (
          <>
            <Body>Available Recording Tools</Body>
            <VSpaced space={'xxs'} />
            {mediaOptions.enableMetronomeTool ? (
              <Checkbox
                checked={config?.tools?.metronome}
                onChange={e => {
                  if (onChange) {
                    onChange({
                      ...config,
                      tools: {
                        ...config?.tools,
                        metronome: e.target.checked,
                      },
                    })
                  }
                }}
              >
                Metronome
              </Checkbox>
            ) : null}
            {mediaOptions.enablePitchTool ? (
              <Checkbox
                checked={config?.tools?.pitch}
                onChange={e => {
                  if (onChange) {
                    onChange({
                      ...config,
                      tools: {
                        ...config?.tools,
                        pitch: e.target.checked,
                      },
                    })
                  }
                }}
              >
                Pitch
              </Checkbox>
            ) : null}
          </>
        ) : null}
      </Col>
    </Row>
  )
}

export function PerformanceEdit(props: QuestionProps<PerformanceConfig>) {
  return (
    <>
      <QuestionInstructions
        instructions={'Edit the performance configuration using the fields below.'}
        padded
      >
        <PerformanceConfigFields
          config={props.question.config}
          onChange={config => {
            if (props.onChange) {
              props.onChange({ config })
            }
          }}
        />
      </QuestionInstructions>
      <QuestionAdditionalFields {...(props as any)} />
    </>
  )
}
