import { LexicalEditor } from 'lexical'
import React from 'react'

import { EditorProps } from '../Editor'

export interface EditorContextValue {
  readOnly?: boolean
  noAdd?: boolean
  noDynamicWidgets?: boolean
  editorsRef: React.MutableRefObject<Set<LexicalEditor>>
  onChangeRef: React.MutableRefObject<EditorProps['onChange']>
  anchorRef: React.RefObject<HTMLElement | null>
}
export const EditorContext = React.createContext<EditorContextValue | undefined>(undefined)

export interface EditorProviderProps {
  readOnly?: boolean
  noAdd?: boolean
  noDynamicWidgets?: boolean
  onChange: EditorProps['onChange']
  anchorRef: React.RefObject<HTMLElement | null>
  children: React.ReactElement
}
export function EditorProvider({
  readOnly,
  noAdd,
  noDynamicWidgets,
  onChange,
  children,
  anchorRef,
}: EditorProviderProps) {
  const editorsRef = React.useRef(new Set<LexicalEditor>())
  const onChangeRef = React.useRef(onChange)
  return (
    <EditorContext.Provider
      value={React.useMemo(
        () => ({ noAdd, readOnly, editorsRef, onChangeRef, anchorRef, noDynamicWidgets }),
        [!!readOnly, !!noAdd, !!noDynamicWidgets],
      )}
    >
      {children}
    </EditorContext.Provider>
  )
}

export function useEditorContext(): EditorContextValue | undefined
export function useEditorContext(require: false): EditorContextValue | undefined
export function useEditorContext(require: true): EditorContextValue
export function useEditorContext(require?: boolean): EditorContextValue | undefined {
  const context = React.useContext(EditorContext)
  if (!context && require) {
    throw new Error('EditorContext is required, yet not provided.')
  }
  return context
}
