import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import MediaReactFeature, { MediaReactOptions } from '../../../MediaReactFeature'
import { setupScreencastCanvas } from '../record/setupScreencastCanvas'
import { Streams } from '../types'

export interface GetStreamsOpts {
  videoDeviceId?: string
  audioDeviceId?: string
  screenRecording?: boolean
  options: MediaReactOptions
}
export async function getStreams({
  videoDeviceId,
  audioDeviceId,
  screenRecording,
  options,
}: GetStreamsOpts): Promise<Streams> {
  const { audioConstraint } = options
  const streamPromise =
    videoDeviceId || audioDeviceId
      ? navigator.mediaDevices.getUserMedia({
          audio: audioDeviceId
            ? {
                ...audioConstraint,
                deviceId: audioDeviceId,
              }
            : false,
          video: videoDeviceId
            ? {
                aspectRatio: { ideal: 16 / 9 },
                width: { min: 640, ideal: 1920 },
                height: { min: 400, ideal: 1080 },
                deviceId: videoDeviceId,
              }
            : false,
        })
      : Promise.resolve(undefined)
  const captureController =
    // @ts-ignore CaptureController is not yet typed
    typeof CaptureController !== 'undefined' ? new CaptureController() : undefined
  const desktopStreamPromise = screenRecording
    ? navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
        controller: captureController,
        surfaceSwitching: 'include',
        selfBrowserSurface: 'exclude',
        systemAudio: 'include',
      } as any)
    : Promise.resolve(undefined)

  // Prevent Chrome / Edge from stealing focus when the user starts screen recording.
  if (captureController && screenRecording) {
    captureController.setFocusBehavior('no-focus-change')
  }

  const [stream, desktopStream] = await Promise.all([streamPromise, desktopStreamPromise])

  // If we have a desktopStream, merge them.
  const resultStream =
    desktopStream && stream
      ? await setupScreencastCanvas({ desktopStream, stream })
      : stream ?? desktopStream

  return {
    stream: resultStream,
    audioDeviceId,
    videoDeviceId,
    hasDesktopRecording: !!desktopStream,
  }
}

export function useGetStreamsCallback(): [
  (opts: Omit<GetStreamsOpts, 'options'>) => ReturnType<typeof getStreams>,
  { loading: boolean },
] {
  const options = useFeature(MediaReactFeature).options
  const [loading, setLoading] = React.useState(false)
  return [
    async opts => {
      setLoading(true)
      let result
      try {
        result = await getStreams({ ...opts, options })
      } finally {
        setLoading(false)
      }

      return result
    },
    { loading },
  ]
}
