import { H2 } from '@thesisedu/web'
import { message, Form, Button, Input } from 'antd'
import React from 'react'

import { useUpdateUserMutation } from '../queries/useUpdateUserMutation'
import { Viewer } from '../types'

export interface ChangePasswordProps {
  viewer: Viewer
}
export function ChangePassword({ viewer }: ChangePasswordProps) {
  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: () => {
      message.success('Your password has been changed!')
    },
  })
  const [form] = Form.useForm()
  return (
    <Form
      form={form}
      onFinish={async values => {
        const response = await updateUser({
          variables: {
            input: {
              id: viewer.id,
              patch: {
                password: values.password,
              },
            },
          },
        })
        if (response?.data?.updateUser.user.id) {
          form.resetFields()
        }
      }}
      layout={'vertical'}
    >
      <H2>Change Password</H2>
      <Form.Item
        name={'password'}
        rules={[
          { required: true, min: 8, message: 'Password must be at least 8 characters long.' },
        ]}
      >
        <Input type={'password'} data-sentry-ignore placeholder={'Password'} size={'large'} />
      </Form.Item>
      <Form.Item
        name={'confirmPassword'}
        rules={[
          { required: true, message: 'Password confirmation is required.' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Passwords must match.')
            },
          }),
        ]}
      >
        <Input
          type={'password'}
          data-sentry-ignore
          placeholder={'Confirm Password'}
          size={'large'}
        />
      </Form.Item>
      <Button loading={loading} type={'primary'} onClick={form.submit}>
        Change Password
      </Button>
    </Form>
  )
}
