import { useMutateHook } from '@thesisedu/feature-react'
import { LoadCampaignBanner, useCampaignBanner } from '@thesisedu/feature-trials-web'
import { Block } from '@thesisedu/react'
import React from 'react'

import { ExploreBannerHook } from '../types'

export function ExploreBanner() {
  const hasCampaign = !!useCampaignBanner()
  const banner = useMutateHook<ExploreBannerHook>('courses-react:explore-banner', null, undefined)
  if (hasCampaign) {
    return (
      <Block marginTop={'@size-l'} marginBottom={'@size-xl'}>
        <LoadCampaignBanner />
      </Block>
    )
  } else if (banner) {
    return banner
  } else {
    return (
      <Block paddingTop={'@size-xl'}>
        <div />
      </Block>
    )
  }
}
