import React from 'react'

import { SimpleList } from './SimpleList'
// @ts-ignore
import { ReactComponent as N1 } from '../../../../assets/sketch/sheet-music/number-1.svg'
// @ts-ignore
import { ReactComponent as N2 } from '../../../../assets/sketch/sheet-music/number-2.svg'
// @ts-ignore
import { ReactComponent as N3 } from '../../../../assets/sketch/sheet-music/number-3.svg'
// @ts-ignore
import { ReactComponent as N4 } from '../../../../assets/sketch/sheet-music/number-4.svg'
// @ts-ignore
import { ReactComponent as N5 } from '../../../../assets/sketch/sheet-music/number-5.svg'
// @ts-ignore
import { ReactComponent as N6 } from '../../../../assets/sketch/sheet-music/number-6.svg'
// @ts-ignore
import { ReactComponent as N7 } from '../../../../assets/sketch/sheet-music/number-7.svg'
// @ts-ignore
import { ReactComponent as N8 } from '../../../../assets/sketch/sheet-music/number-8.svg'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function SimpleNumbersContent(props: ModeContentsProps) {
  return <SimpleList items={[N1, N2, N3, N4, N5, N6, N7, N8]} {...props} />
}

export const SimpleNumbers: Mode = {
  id: 'SimpleNumbers',
  name: 'Numbers',
  icon: require('../../../../assets/sketch/sheet-music/number-1.svg').default,
  large: true,
  types: [KeyboardType.Note8],
  contents: SimpleNumbersContent,
}
