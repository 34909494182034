import { useImpersonation, useLoadAllResults } from '@thesisedu/feature-apollo-react'

import {
  ListClassSyncHistoryFragment,
  SyncHistoryDocument,
  SyncHistoryQuery,
  SyncHistoryQueryVariables,
} from '../schema'

export interface UseSyncHistory {
  loading: boolean
  totalCount?: number
  history: ListClassSyncHistoryFragment[]
  loaded: number
}
export function useSyncHistory(classSyncIds: string[], syncIds: string[]) {
  const { withCoreAuth } = useImpersonation()
  return useLoadAllResults<
    ListClassSyncHistoryFragment,
    SyncHistoryQueryVariables,
    SyncHistoryQuery
  >(
    withCoreAuth({
      document: SyncHistoryDocument,
      fetchPolicy: 'no-cache',
      variables: {
        classSyncIds,
        syncIds,
        first: 32,
      },
      skip: !classSyncIds.length && !syncIds.length,
      getResult(data) {
        return data.classSyncHistory
      },
    }),
  )
}
