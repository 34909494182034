import { fuzzySearch } from '@thesisedu/feature-utils'

import { ClassStudentEdgeFragment } from './schema'

type FilterStudentsStudent = {
  user: Pick<ClassStudentEdgeFragment['node']['user'], 'name' | 'username'>
}

export function filterStudents<T extends FilterStudentsStudent>(
  students: T[],
  filter: string | null | undefined,
): T[]
export function filterStudents<T extends Record<string, any>>(
  entries: T[],
  filter: string | null | undefined,
  getStudent: (entry: T) => FilterStudentsStudent | null | undefined,
): T[]
export function filterStudents<T extends Record<string, any>>(
  students: T[],
  filter: string | null | undefined,
  getStudent?: (entry: T) => FilterStudentsStudent | null | undefined,
): T[] {
  return filter?.trim()
    ? fuzzySearch(
        students,
        item => {
          const student = getStudent ? getStudent(item) : item
          if (!student) return []
          return [student.user.name, student.user.username]
        },
        filter,
      )
    : students
}
