import { Form, Input, Checkbox } from 'antd'
import { FormInstance, FormProps } from 'antd/es/form'
import React from 'react'

export interface TeacherFormProps {
  form: FormInstance
  onFinish: FormProps['onFinish']
}
export const TeacherForm: React.FC<React.PropsWithChildren<TeacherFormProps>> = ({
  form,
  onFinish,
}) => (
  <Form form={form} layout={'vertical'} onFinish={onFinish}>
    <Form.Item
      name={'firstName'}
      label={'First Name'}
      rules={[{ required: true, message: 'A first name is required.' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={'lastName'}
      label={'Last Name'}
      rules={[{ required: true, message: 'A last name is required.' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={'email'}
      label={'Email'}
      rules={[{ required: true, type: 'email', message: 'A valid email address is required.' }]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      name={'password'}
      label={'Password'}
      rules={[
        { required: true, message: 'A password is required.' },
        { min: 8, message: 'Must be at least 8 characters long.' },
      ]}
    >
      <Input type={'password'} />
    </Form.Item>
    <Form.Item name={'noEmail'} valuePropName={'checked'} label={'Email Notifications'}>
      <Checkbox>Don't send an email notification.</Checkbox>
    </Form.Item>
  </Form>
)
