import * as React from 'react'

function SvgNoteQuarter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 260'} {...props}>
      <path
        fillRule={'evenodd'}
        d={
          'M96.504 0l-.077 210.835c.578 15.236-12.95 32.837-34.145 42.547-25.354 11.617-52.078 7.542-59.69-9.1-7.612-16.644 6.771-39.552 32.125-51.168 17.75-8.132 36.173-8.574 48.238-2.396-.192-1.903-.3-3.644-.302-5.076l.102-10.092.086-14.02.063-20.798.02-20.867-.019-28.878-.178-70.58L82.647 0h13.857z'
        }
      />
    </svg>
  )
}

const MemoSvgNoteQuarter = React.memo(SvgNoteQuarter)
export default MemoSvgNoteQuarter
