import { FileField } from '@thesisedu/feature-attachments-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Block, H2, media, styled, useResizeObserver } from '@thesisedu/web'
import { Form, FormProps, Input } from 'antd'
import React from 'react'

import { CampaignBanner } from './CampaignBanner'
import { hasWelcomeMessage } from './helpers'
import { TrialRequestCampaignFragment } from '../schema'

export interface CampaignFormProps extends FormProps {
  campaign?: TrialRequestCampaignFragment
}
export function CampaignForm({ campaign, ...formProps }: CampaignFormProps) {
  const [campaignCode, setCampaignCode] = React.useState(campaign?.identifier)
  const [message, setMessage] = React.useState<
    TrialRequestCampaignFragment['welcomeMessage'] | undefined
  >(campaign?.welcomeMessage)
  const root = useFeatureRoot()
  const { ref, width } = useResizeObserver()
  const hasMessage = hasWelcomeMessage({ welcomeMessage: message })
  return (
    <Form
      {...formProps}
      layout={'vertical'}
      onValuesChange={(_, values) => {
        setCampaignCode(values.identifier)
        setMessage(values.welcomeMessage)
        if (formProps.onValuesChange) {
          formProps.onValuesChange(_, values)
        }
      }}
    >
      <Form.Item
        name={'identifier'}
        label={'Campaign Code'}
        rules={[{ required: true, message: 'A campaign code is required.' }]}
        extra={
          <>
            <span>
              This is the code for this campaign. To have users register for a trial with this
              campaign, send them to:
              <br />
              {window.location.origin}/auth/trial-request?utm_campaign={campaignCode}
            </span>
          </>
        }
      >
        <CodeInput size={'large'} placeholder={'my-campaign'} style={{ width: '100%' }} />
      </Form.Item>
      <Form.Item
        name={'targetPath'}
        label={'Target Path'}
        extra={
          <span>
            If you would like users to be redirected to a specific page whenever they login for the
            first time (after their trial is approved), specify that path here. It should start with
            a slash.
          </span>
        }
      >
        <Input placeholder={'/teacher/classes'} />
      </Form.Item>
      <H2>Welcome Message</H2>
      <ColContainer>
        <div>
          <Form.Item name={['welcomeMessage', 'title']} label={'Title'}>
            <Input style={{ width: '100%' }} placeholder={`Welcome to ${root.appOptions.name}`} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['welcomeMessage', 'background']} label={'Background Image'}>
            <FileField uploadProps={{ isPublic: true }} />
          </Form.Item>
        </div>
      </ColContainer>
      <Form.Item name={['welcomeMessage', 'message']} label={'Description'}>
        <Input.TextArea
          style={{ width: '100%' }}
          autoSize={{ minRows: 4, maxRows: 6 }}
          placeholder={'*Markdown* is supported.'}
        />
      </Form.Item>
      <div ref={ref} style={{ width: '100%' }}>
        {hasMessage && width ? (
          <Block marginTop={'@size-l'}>
            <BannerContainer style={{ zoom: width / BANNER_PREVIEW_WIDTH }}>
              <CampaignBanner campaign={campaignCode || ''} {...message} isPreview />
            </BannerContainer>
          </Block>
        ) : null}
      </div>
    </Form>
  )
}

const CodeInput = styled(Input)`
  font-family: ${props => props.theme['@code-family']};
`
const ColContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: ${props => props.theme['@size-m']};
  ${media.lg} {
    grid-template-columns: repeat(2, 1fr);
  }
`
const BANNER_PREVIEW_WIDTH = 992
const BannerContainer = styled.div`
  > div {
    width: ${BANNER_PREVIEW_WIDTH}px;
  }
`
