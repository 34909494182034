import { PictureOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Grid } from '@thesisedu/react'
import { AntIconWrapper as Aiw, StyledThemeContext } from '@thesisedu/web'
import { Progress } from 'antd'
import { Clock, Mic } from 'iconoir-react'
import React from 'react'

import { MediaFragment, MediaStatus, MediaType } from '../schema'
import { PartialUpload } from '../upload/types'

const { ImagePlaceholder, IconContainer, GridImage } = Grid

export function hasThumbnail(node: Pick<MediaFragment, 'thumbnail' | 'status' | 'type'>) {
  return node.type === MediaType.Video && node.thumbnail && node.status === MediaStatus.Complete
}

export interface MediaThumbnailProps {
  node: Pick<MediaFragment, 'thumbnail' | 'status' | 'type'> & Pick<PartialUpload, 'uploaded'>
}
export function MediaThumbnail({ node }: MediaThumbnailProps) {
  const theme = React.useContext(StyledThemeContext)
  if (hasThumbnail(node)) {
    return <GridImage src={node.thumbnail!} alt={'Media Thumbnail'} />
  } else {
    let icon = node.type === MediaType.Audio ? <Aiw children={<Mic />} /> : <PictureOutlined />
    let background: string | undefined = undefined
    if (node.status === MediaStatus.Error) {
      icon = <ExclamationCircleOutlined />
      background = '@orange'
    } else if (node.status !== MediaStatus.Complete) {
      if (node.uploaded) {
        background = '@primary-color'
        icon = (
          <Progress
            width={parseFloat(theme['@size-l'].replace('px', ''))}
            type={'circle'}
            strokeWidth={parseFloat(theme['@size-xs'].replace('px', ''))}
            percent={(node.uploaded || 0) * 100}
            showInfo={false}
            status={'normal'}
            strokeColor={theme['@gray-0']}
            trailColor={'transparent'}
          />
        )
      } else {
        icon = <Aiw children={<Clock />} />
      }
    }
    return (
      <ImagePlaceholder>
        <IconContainer background={background} children={icon} />
      </ImagePlaceholder>
    )
  }
}
