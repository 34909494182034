import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { fromGlobalId, toGlobalId } from '@thesisedu/feature-utils'
import { useEffectAfterMount } from '@thesisedu/react'
import { styled, BodySmall, Color, BlockSpin } from '@thesisedu/web'
import { Empty } from 'antd'
import { DocumentNode } from 'graphql'
import { get, omit } from 'lodash'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { ExploreGridContext } from './ExploreGridContext'
import { GridSearch } from './GridSearch'
import { SegmentGrid } from './SegmentGrid'
import { ExploreGridItemProps, ExploreGridConfiguration } from './types'
import {
  ListBuiltSegmentsDocument,
  BuiltSegmentFragment,
  ListBuiltSegmentsQuery,
  ListBuiltSegmentsQueryVariables,
  FilterOperation,
  TagFilterInput as TagSearchInput,
} from '../../schema'
import { useSegmentPreviewContext } from '../../segment/SegmentPreviewContext'
import { UseInClassButton } from '../UseInClassButton'
import { useExploreGridFilters } from '../useExploreGridFilters'

function getInitialTags(params: URLSearchParams) {
  const tagsParam = params.getAll('tags')
  if (tagsParam?.length) {
    return tagsParam
      .map<TagSearchInput | null>(tagParam => {
        const [type, name, pathStr] = decodeURIComponent(tagParam).split(TAG_SEPARATOR)
        const pathSegments = pathStr?.length
          ? pathStr.split(PATH_SEPARATOR) || undefined
          : undefined
        if (type && name) return { type, name, path: pathSegments }
        else return null
      })
      .filter(Boolean) as TagSearchInput[]
  } else return []
}
function getInitialName(params: URLSearchParams) {
  return params.get('name') || ''
}

const SEARCH_DEBOUNCE = 500
const TAG_SEPARATOR = ':|:'
const PATH_SEPARATOR = ':!:'
export interface ExploreGridProps<
  Variables extends ListBuiltSegmentsQueryVariables = ListBuiltSegmentsQueryVariables,
> extends ExploreGridConfiguration<Variables> {
  children?: React.ReactElement<ExploreGridItemProps>[]
  document?: DocumentNode
  resultPath?: string
}
export function ExploreGrid<
  Variables extends ListBuiltSegmentsQueryVariables = ListBuiltSegmentsQueryVariables,
>({
  children: _children,
  document,
  resultPath = 'segments',
  ...configuration
}: ExploreGridProps<Variables>) {
  const defaultFilters = useExploreGridFilters()
  const children = _children || defaultFilters
  const { variables } = configuration
  const { setPreviewSegmentId } = useSegmentPreviewContext(true)
  const [params, setParams] = useSearchParams()
  const selectedTags = getInitialTags(params)
  const setSelectedTags = (action: React.SetStateAction<TagSearchInput[]>) => {
    const newValue = typeof action === 'function' ? action(selectedTags) : action
    params.delete('tags')
    if (newValue.length) {
      for (const tag of newValue) {
        params.append(
          'tags',
          encodeURIComponent(
            [tag.type, tag.name, tag.path?.join(PATH_SEPARATOR) || ''].join(TAG_SEPARATOR),
          ),
        )
      }
    }

    setParams(params)
  }
  const dGridSearch = getInitialName(params)
  const [gridSearch, _setGridSearch] = React.useState(dGridSearch)
  const timeout = React.useRef<any>()
  useEffectAfterMount(() => {
    if (gridSearch !== dGridSearch) {
      _setGridSearch(dGridSearch)
    }
  }, [dGridSearch])
  const setGridSearch = (value: string) => {
    _setGridSearch(value)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      if (value) {
        params.set('name', value)
      } else {
        params.delete('name')
      }
      setParams(params)
    }, SEARCH_DEBOUNCE)
  }
  return (
    <ExploreGridContext.Provider value={{ configuration, selectedTags, setSelectedTags }}>
      <Container>
        <SidebarContainer>
          <SearchContainer>
            <GridSearch value={gridSearch} onChange={setGridSearch} />
          </SearchContainer>
          {children}
        </SidebarContainer>
        <ContentContainer>
          <InfiniteQuery<
            BuiltSegmentFragment,
            ListBuiltSegmentsQuery,
            ListBuiltSegmentsQueryVariables
          >
            document={document ?? ListBuiltSegmentsDocument}
            variables={{
              ...omit(variables, ['tags', 'tagsOp', 'hiddenFromTeachers', 'types', 'name']),
              filters: {
                name: variables?.name ?? dGridSearch.trim() ? dGridSearch.trim() : undefined,
                tags: [
                  ...(selectedTags.length ? selectedTags : []),
                  ...(variables?.tags
                    ? Array.isArray(variables.tags)
                      ? variables.tags
                      : [variables.tags]
                    : []),
                ].filter(Boolean) as TagSearchInput[],
                tagsOp: FilterOperation.And,
                types: variables?.types,
                hiddenFromTeachers: variables?.hiddenFromTeachers,
              },
            }}
            resultPath={resultPath}
            children={({ data, loading }) => {
              const result = get(
                data,
                resultPath ?? 'segments',
              ) as ListBuiltSegmentsQuery['segments']
              const segments = result?.edges.map(edge => edge.node.built).filter(Boolean) || []
              let content
              if (segments.length) {
                content = (
                  <SegmentGrid
                    segments={segments}
                    onClick={segment =>
                      setPreviewSegmentId(toGlobalId('Segment', segment.id), id => (
                        <UseInClassButton
                          rawId={fromGlobalId(id, true).id}
                          variant={'primary'}
                          onClick={() => setPreviewSegmentId(undefined)}
                        />
                      ))
                    }
                  />
                )
              } else if (loading) {
                content = <BlockSpin />
              } else {
                content = (
                  <Empty description={'No content could be found that matches your search.'} />
                )
              }
              return (
                <>
                  <BodySmall color={'@text-color-secondary'} isBlock>
                    &nbsp;
                    {gridSearch || selectedTags.length ? (
                      <a
                        onClick={e => {
                          e.preventDefault()
                          setGridSearch('')
                          setSelectedTags([])
                        }}
                      >
                        <Color color={'@gray-6'}>Reset Filters</Color>
                      </a>
                    ) : null}
                  </BodySmall>
                  {content}
                </>
              )
            }}
          />
        </ContentContainer>
      </Container>
    </ExploreGridContext.Provider>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`
const SidebarContainer = styled.div`
  width: 300px;
  margin-right: ${props => props.theme['@size-l']};
  border: solid 1px ${props => props.theme['@border-color-base']};
  border-radius: ${props => props.theme['@border-radius-large']};
  padding-bottom: ${props => props.theme['@border-radius-large']};
  min-height: calc(var(--app-height) - ${props => props.theme['@size-l']} * 4);
  flex-shrink: 0;
`
const ContentContainer = styled.div`
  flex: 1;
  min-width: 0;
`
const SearchContainer = styled.div`
  padding: ${props => props.theme['@size-m']};
`
