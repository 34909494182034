import { FilterButton, FilterButtonProps } from '@thesisedu/react'
import React from 'react'

import { useSearchTagsQuery } from './schema'

export interface TagFilterProps extends Partial<FilterButtonProps<string>> {
  tagType: string
  label: string
  value?: string
  onChange?: (value?: string) => void
  valueKind?: 'id' | 'name'
}
export function TagFilter({ tagType, value, onChange, valueKind = 'id', ...rest }: TagFilterProps) {
  const { data, loading } = useSearchTagsQuery({
    variables: {
      first: 100,
      types: [tagType],
    },
  })
  const options =
    data?.tags.edges.map(edge => ({
      value: edge.node[valueKind],
      label: edge.node.name,
    })) || []

  return (
    <FilterButton<string>
      {...rest}
      value={value}
      onChange={newValue => {
        if (onChange) {
          onChange(newValue)
        }
      }}
      options={options}
      disabled={loading || !options.length}
    />
  )
}
