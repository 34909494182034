import React from 'react'
import { ButtonContext, useContextProps } from 'react-aria-components'

import { styled, s } from '../..'
import { Menu } from '../../../icons'
import { HTMLProps } from '../../sharedTypes'
import { FocusRing } from '../FocusRing/FocusRing'

function _DragButton(
  props: HTMLProps<HTMLButtonElement>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  ;[props, ref] = useContextProps({ ...props, slot: 'drag' }, ref, ButtonContext)
  return (
    <FocusRing>
      <__DragButton {...(props as any)} children={<Menu />} ref={ref} />
    </FocusRing>
  )
}
export const DragButton = React.forwardRef(_DragButton)

const __DragButton = styled.button`
  all: unset;
  font-size: var(--table-font-size);
  color: var(--table-text-color);
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('xxs')};
`
