import React from 'react'

import { SegmentCompletionHost } from '../../types'

export interface SegmentCompletionContextValue {
  host: SegmentCompletionHost
}
export const SegmentCompletionContext = React.createContext<
  SegmentCompletionContextValue | undefined
>(undefined)

export interface SegmentCompletionProviderProps {
  host: SegmentCompletionHost
  children: React.ReactElement
}
export function SegmentCompletionProvider({ host, children }: SegmentCompletionProviderProps) {
  return <SegmentCompletionContext.Provider value={{ host }} children={children} />
}

export function useSegmentCompletionContext(): SegmentCompletionContextValue | undefined
export function useSegmentCompletionContext(
  require: false,
): SegmentCompletionContextValue | undefined
export function useSegmentCompletionContext(require: true): SegmentCompletionContextValue
export function useSegmentCompletionContext(
  require?: boolean,
): SegmentCompletionContextValue | undefined {
  const context = React.useContext(SegmentCompletionContext)
  if (!context && require) {
    throw new Error('SegmentCompletionContext is required, yet not provided.')
  }
  return context
}
