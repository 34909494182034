import { ExpandedContentComponentProps } from '@thesisedu/feature-classes-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Text } from '@thesisedu/ui'
import React from 'react'

import { StandaloneRostering } from '../setup/Rostering/StandaloneRostering'
import { ImportStudentPayload } from '../types'

export interface ImportClassSyncExpandedProps
  extends Omit<ExpandedContentComponentProps<ImportStudentPayload>, 'onBack' | 'class'> {
  onBack?: ExpandedContentComponentProps['onBack']
  userId?: string
  maxSelection?: number
  message?: React.ReactElement
}

export function ImportClassSyncExpanded({
  payload,
  onClose,
  userId,
  maxSelection,
  message,
}: ImportClassSyncExpandedProps) {
  const appName = useFeatureRoot().appOptions.name
  return (
    <>
      <Text bottom={'m'} level={'s'} color={'secondary'}>
        Match your {payload.providerName} students with {appName} students.
      </Text>
      <StandaloneRostering
        classId={payload.classId}
        providerIdentifier={payload.providerIdentifier}
        providerName={payload.providerName}
        syncId={payload.syncId}
        onComplete={onClose}
      />
    </>
  )
}
