import { MeasureCache } from '@thesisedu/web'
import { CollapsibleGridResults } from '@thesisedu/web/dist/react-window'
import React from 'react'

import { AxesResponsibility } from './axes'
import { ReportCell } from './cells/getReportCells'
import { GridExtents } from './extents'
import { ReportDimensionResult } from '../../execute/extensibleTypes'
import { RunReportQuery } from '../../execute/types'
import { FlatReportDimensionSummaryRow, RunReportOpts } from '../../types'

export interface ReportGridContextValue {
  responsibility: AxesResponsibility
  extents: GridExtents
  opts: RunReportOpts
  setOpts: (opts: RunReportOpts, noNewTab?: boolean) => void
  data: RunReportQuery['runReport']
  dimensionResults: ReportDimensionResult[]
  cells: ReportCell[][]
  getRealRowIndex: CollapsibleGridResults['getRealRowIndex'] | undefined
  flatSummaryRows: FlatReportDimensionSummaryRow[] | undefined
  expandedRowIds: string[]
  setExpandedRowIds: React.Dispatch<React.SetStateAction<string[]>>
  visibleRows: number
  measureCache: MeasureCache
}
export const ReportGridContext = React.createContext<ReportGridContextValue | undefined>(undefined)

export function useReportGridContext(): ReportGridContextValue | undefined
export function useReportGridContext(require: false): ReportGridContextValue | undefined
export function useReportGridContext(require: true): ReportGridContextValue
export function useReportGridContext(require?: boolean): ReportGridContextValue | undefined {
  const context = React.useContext(ReportGridContext)
  if (!context && require) {
    throw new Error('ReportGridContext is required, yet not provided.')
  }
  return context
}
