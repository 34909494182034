import {
  useAssignmentTemplateQuery,
  useCreateAssignmentTemplateMutation,
  useUpdateAssignmentTemplateMutation,
} from '@thesisedu/feature-assignments-react'
import { AssignmentTemplateFragment } from '@thesisedu/feature-assignments-react/dist/schema'
import { Segment, SegmentProps } from '@thesisedu/feature-courses-react'
import { message } from 'antd'
import { omit } from 'lodash'

import { error } from '../../log'

export interface AssignmentEditOpts extends SegmentProps {}
export interface AssignmentEditResult {
  onChange: (segment: Segment, values: any) => Promise<any>
  loading: boolean
  assignment?: AssignmentTemplateFragment
}

export function useSegmentAssignmentEdit({
  segment,
  onChange,
}: AssignmentEditOpts): AssignmentEditResult {
  const { data, loading } = useAssignmentTemplateQuery({
    variables: {
      id: segment.config.templateId || '',
    },
    skip: !segment.config.templateId,
  })
  const assignment = data?.node?.__typename === 'AssignmentTemplate' ? data.node : undefined
  const [createTemplate] = useCreateAssignmentTemplateMutation()
  const [updateTemplate] = useUpdateAssignmentTemplateMutation({
    refetchQueries: ['assignmentForSegment'],
  })
  return {
    loading,
    assignment,
    onChange: async (segment, values) => {
      if (!assignment && values.assignment && !values.assignment.id) {
        const hideLoadingMessage = message.loading('Creating assignment...', 0)
        try {
          const result = await createTemplate({
            variables: {
              input: {
                ...values.assignment,
                name: `${segment.prefix ? `${segment.prefix} ` : ''}${
                  values.assignment.name || values.name
                }`,
              },
            },
          })
          hideLoadingMessage()
          if (result?.data?.createAssignmentTemplate) {
            if (onChange) {
              onChange(segment, {
                ...omit(values, ['assignment']),
                config: {
                  ...values.config,
                  templateId: result.data.createAssignmentTemplate.assignmentTemplate.id,
                },
              })
            }
          }
        } catch (err: any) {
          error(err)
          hideLoadingMessage()
        }
      } else if (assignment && values.assignment) {
        const hideLoadingMessage = message.loading('Updating assignment...', 0)
        try {
          const result = await updateTemplate({
            variables: {
              input: {
                id: assignment.id,
                patch: {
                  ...omit(values.assignment, ['id']),
                  name: `${segment.prefix ? `${segment.prefix} ` : ''}${values.name}`,
                },
              },
            },
          })
          hideLoadingMessage()
          if (!result?.data?.updateAssignmentTemplate) {
            return
          }
        } catch (err: any) {
          error(err)
          hideLoadingMessage()
        }
        if (onChange) {
          onChange(segment, omit(values, ['assignment']))
        }
      }
    },
  }
}
