import { fromGlobalId } from '@thesisedu/feature-utils'
import { NavigationListItem } from '@thesisedu/react/devtools'
import { Text } from '@thesisedu/ui'
import React from 'react'

import { Container } from './HistoryListItem'

export interface SyncListItemProps {
  syncId: string
  onClick: () => void
  isSelected: boolean
}
export function SyncListItem({ syncId, onClick, isSelected }: SyncListItemProps) {
  const { type, id } = fromGlobalId(syncId, true)
  return (
    <NavigationListItem onClick={onClick} isSelected={isSelected}>
      <Container>
        <Text level={'s'}>{type}</Text>
        <Text level={'xs'} color={'secondary'}>
          {id}
        </Text>
      </Container>
    </NavigationListItem>
  )
}
