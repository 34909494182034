import { Switch } from '@thesisedu/react'
import React from 'react'

import { useUpdateAssignmentMutation } from '../queries/useUpdateAssignmentMutation'

export interface GeneratedAssignmentSwitchProps {
  assignmentId: string
  enabled?: boolean
}
export function GeneratedAssignmentSwitch({
  assignmentId,
  enabled,
}: GeneratedAssignmentSwitchProps) {
  const [update, { loading }] = useUpdateAssignmentMutation()
  return (
    <Switch
      checked={enabled}
      loading={loading}
      onChange={e => {
        update({
          variables: {
            input: {
              id: assignmentId,
              patch: {
                excluded: !e,
              },
            },
          },
        })
      }}
    />
  )
}
