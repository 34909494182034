import { Format } from '@thesisedu/feature-utils'
import { Block, Button, HSpaced, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

import { useBookmarks } from './useBookmarks'
import { useUpdateMedia } from '../api/useUpdateMedia'

export function BookmarksTab() {
  const bookmarks = useBookmarks()
  const [update, { loading }] = useUpdateMedia()
  if (bookmarks.length) {
    return (
      <VSpaced space={'s'} style={{ width: '100%' }}>
        {bookmarks.map(bookmark => {
          return (
            <div key={bookmark.startSeconds}>
              <HSpaced space={'xs'}>
                <Text level={'xs'} color={'secondary'} style={{ lineHeight: 1 }}>
                  {[
                    bookmark.startSeconds != null ? Format.duration(bookmark.startSeconds) : null,
                    bookmark.endSeconds != null ? Format.duration(bookmark.endSeconds) : null,
                  ]
                    .filter(Boolean)
                    .join(' - ')}
                </Text>
                <Block left>
                  <Button
                    loading={loading}
                    variant={'chromeless'}
                    size={'extraSmall'}
                    children={'Delete'}
                    status={'danger'}
                    onPress={async () => {
                      await update(existing => {
                        return {
                          ...existing,
                          metadata: {
                            ...existing.metadata,
                            bookmarks: existing.metadata?.bookmarks?.filter(
                              bk => bk.startSeconds !== bookmark.startSeconds,
                            ),
                          },
                        }
                      })
                    }}
                  />
                </Block>
              </HSpaced>
              <Text level={'s'}>{bookmark.name}</Text>
            </div>
          )
        })}
      </VSpaced>
    )
  } else {
    return (
      <Text
        color={'secondary'}
        children={'No bookmarks'}
        style={{ textAlign: 'center', width: '100%' }}
      />
    )
  }
}
