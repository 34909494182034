import pathLib from 'path'
import React from 'react'

import { EmbeddedFileResource } from './types'
import { useResource } from './useFeatureRoot'

export interface EmbeddedFileProps {
  path: string
}
export function EmbeddedFile({ path }: EmbeddedFileProps) {
  const renderers = useResource<EmbeddedFileResource>('EmbeddedFile')
  const extension = pathLib.extname(path).slice(1)
  const Component = renderers.find(r => r.extensions.includes(extension))?.render
  if (Component) {
    return <Component path={path} />
  } else {
    return null
  }
}
