window.PagedPolyfill = require('pagedjs/dist/paged.polyfill')

// Remove Intercom if it's present. It seems to be finicky with wanting to add itself.
if (typeof Intercom !== 'undefined') {
  Intercom('shutdown')
}
if (typeof fcWidget !== 'undefined' && fcWidget.destroy) {
  fcWidget.destroy()
}

class ReactFilter extends Paged.Handler {
  constructor(chunker, polisher, caller) {
    super(chunker, polisher, caller)
  }

  filter(content) {
    console.info('filtering out anything but #root')
    for (const child of content.children) {
      if (child.id !== 'root') {
        console.info('removing', child)
        child.remove()
      }
    }
  }

  afterRendered() {
    console.info('adding rendered class')
    document.body.className = 'rendered'
  }
}

Paged.registerHandlers(ReactFilter)
