import { uniqBy, orderBy } from 'lodash'
import React from 'react'

import { getLogContent } from './ViewDetailedLog'
import { DetailedLogFragment } from '../../schema'

async function getMatchingLogs(
  logs: DetailedLogFragment[],
  query: string,
  onProgress: (percentage: number, matches: DetailedLogFragment[]) => void,
  cancelReference: { shouldContinue: boolean },
) {
  const matches: DetailedLogFragment[] = []
  for (let i = 0; i < logs.length; i++) {
    if (!cancelReference.shouldContinue) break
    const log = logs[i]
    const content = await getLogContent(log.signedUrl)
    if (content && content.toLowerCase().includes(query.toLowerCase())) {
      matches.push(log)
    }
    onProgress((i + 1) / logs.length, matches)
  }
}

export interface UseDetailedLogContentSearchOpts {
  filteredLogs: DetailedLogFragment[]
  query?: string
}
export interface UseDetailedLogContentSearchResult {
  matchingLogs?: DetailedLogFragment[]
  progress?: number
}
export function useDetailedLogContentSearch({
  filteredLogs,
  query,
}: UseDetailedLogContentSearchOpts): UseDetailedLogContentSearchResult {
  const [matchingLogs, setMatchingLogs] = React.useState<DetailedLogFragment[] | undefined>()
  const [progress, setProgress] = React.useState<number | undefined>()
  React.useEffect(() => {
    const cancelReference = { shouldContinue: true }
    if (query) {
      getMatchingLogs(
        uniqBy(orderBy(filteredLogs, ['filename'], ['desc']), 'id'),
        query,
        (percentage, matches) => {
          setProgress(percentage)
          setMatchingLogs(matches)
        },
        cancelReference,
      )
      return () => {
        cancelReference.shouldContinue = false
      }
    } else {
      setProgress(undefined)
      setMatchingLogs(undefined)
    }
  }, [query, filteredLogs.length])

  return {
    matchingLogs: query ? matchingLogs : undefined,
    progress: query ? progress : undefined,
  }
}
