import h from 'hastscript'
import raw from 'rehype-raw'
import html from 'rehype-stringify'
import directive from 'remark-directive'
import gfm from 'remark-gfm'
import remarkParse from 'remark-parse'
import remarkRehype from 'remark-rehype'
import unified from 'unified'
import visit from 'unist-util-visit'
import vfile from 'vfile'

const VALID_TAG_NAMES =
  'a,abbr,address,area,article,aside,audio,b,base,bdi,bdo,blockquote,body,br,button,canvas,caption,cite,code,col,colgroup,data,datalist,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,head,header,hgroup,hr,html,i,iframe,img,input,ins,kbd,label,legend,li,link,main,map,mark,menu,meta,meter,nav,noscript,object,ol,optgroup,option,output,p,param,picture,pre,progress,q,rp,rt,ruby,s,samp,script,section,select,slot,small,source,span,strong,style,sub,summary,sup,table,tbody,td,template,textarea,tfoot,th,thead,time,title,tr,track,u,ul,var,video,wbr'.split(
    ',',
  )
function htmlDirectives() {
  return transform
  function transform(tree: any) {
    visit(tree, ['textDirective', 'leafDirective', 'containerDirective'], ondirective)
  }
  function ondirective(node: any) {
    const data = node.data || (node.data = {})
    const hast = h(node.name, node.attributes)
    const tagName = (hast as any).tagName
    if (VALID_TAG_NAMES.includes(tagName)) {
      data.hName = (hast as any).tagName
      data.hProperties = (hast as any).properties
    } else {
      data.hName = 'span'
      const attrString = Object.keys(node.attributes || {})
        .reduce<string[]>((acc, attributeKey) => {
          return [...acc, [attributeKey, node.attributes[attributeKey]].join('=')]
        }, [])
        .join(',')
      data.hChildren = [
        {
          type: 'text',
          value: `:${node.name}${attrString ? `{${attrString}}` : ''}`,
        },
      ]
    }
  }
}

export function markdownToHtml(markdown: string) {
  const processor = unified()
    .use(remarkParse as any)
    .use([directive, htmlDirectives, gfm] as any)
    .use(remarkRehype, { allowDangerousHtml: true })
    .use(raw)
    .use(html)
  const file = vfile(markdown)
  return String(processor.processSync(file))
}
