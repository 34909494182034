import { GeneratedAssignmentMetadata } from '@thesisedu/feature-assignments-core'
import { useResource } from '@thesisedu/feature-react'
import { VSpaced } from '@thesisedu/react'
import React from 'react'

import { GeneratedAssignmentDirtyStatus } from './GeneratedAssignmentDirtyStatus'
import { GeneratedAssignmentProviderReactResource } from './types'
import { AssignmentSettingsProps } from '../grading/AssignmentSettings'

export function GeneratedAssignmentSettingsContent(props: AssignmentSettingsProps) {
  const generated = props.assignment.generated as GeneratedAssignmentMetadata
  const providerResource = useResource<GeneratedAssignmentProviderReactResource>(
    'GeneratedAssignmentProviderReact',
    generated.provider,
  )
  if (!providerResource) throw new Error(`Cannot find React resource for '${generated.provider}'`)
  const Settings = providerResource.renderAssignmentSettings
  return (
    <VSpaced align={'stretch'} space={'@size-m'}>
      <GeneratedAssignmentDirtyStatus assignmentId={props.assignment.id} />
      <Settings {...props} />
    </VSpaced>
  )
}
