import { DeleteOutlined, EditOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, styled } from '@thesisedu/web'
import { Button, Popconfirm, Tooltip } from 'antd'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { CustomGroupStaff } from './CustomGroupStaff'
import { CustomGroupDraggable } from './SheetMusicDragDropContent'
import { CustomGroupsContainer, CustomGroupContainer } from './styled'
import { CustomGroup } from './types'
import { SimpleSheetMusicEditor } from '../../../editor/SimpleSheetMusicEditor'

export interface CustomGroupsEditProps {
  value?: CustomGroup[]
  onChange?: (customGroups?: CustomGroup[]) => void
}
export function CustomGroupsEdit({ value = [], onChange }: CustomGroupsEditProps) {
  const [modalVisible, setModalVisible] = React.useState(false)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [editValue, setEditValue] = React.useState<string>('')
  React.useEffect(() => {
    if (modalVisible) {
      if (editingId) {
        setEditValue(value.find(v => v.id === editingId)?.abc || '')
      } else {
        setEditValue('')
      }
    }
  }, [modalVisible, editingId])
  return (
    <>
      <Modal
        title={editingId ? 'Editing Draggabe' : 'Create Draggable'}
        visible={modalVisible}
        width={1200}
        style={{ maxWidth: '90vw' }}
        onCancel={() => {
          setModalVisible(false)
          setEditingId(null)
        }}
        footer={[
          <Button
            key={'cancel'}
            onClick={() => {
              setModalVisible(false)
              setEditingId(null)
            }}
          >
            Cancel
          </Button>,
          <Button
            key={'save'}
            onClick={() => {
              if (editingId && onChange) {
                onChange(
                  value.map(v => {
                    if (v.id === editingId) {
                      return { ...v, abc: editValue }
                    } else return v
                  }),
                )
              } else if (onChange) {
                onChange([...value, { id: uuid(), abc: editValue }])
              }
              setModalVisible(false)
              setEditingId(null)
              setEditValue('')
            }}
            type={'primary'}
          >
            {editingId ? 'Save Changes' : 'Create Draggable'}
          </Button>,
        ]}
      >
        <div style={{ padding: '0 10px' }}>
          <SimpleSheetMusicEditor
            value={editValue}
            onChange={abc => {
              setEditValue(abc)
            }}
          />
        </div>
      </Modal>
      <CustomGroupsContainer space={'@size-m'} align={'stretch'}>
        {value.map(customGroup => (
          <CustomGroupDraggable key={customGroup.id} customGroup={customGroup}>
            <CustomGroupContainer space={'@size-m'}>
              <CustomGroupStaff customGroup={customGroup} />
              <VerticalButtonGroup>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setEditingId(customGroup.id)
                    setModalVisible(true)
                  }}
                />
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    if (onChange) {
                      onChange([
                        ...value,
                        {
                          ...customGroup,
                          id: uuid(),
                        },
                      ])
                    }
                  }}
                />
                <Popconfirm
                  title={'Are you sure?'}
                  okButtonProps={{ danger: true }}
                  okText={'Yes, delete'}
                  cancelText={'No, keep'}
                  onConfirm={() => {
                    if (onChange) {
                      onChange(value.filter(v => v.id !== customGroup.id))
                    }
                  }}
                >
                  <Tooltip title={'Delete Draggable'} mouseEnterDelay={1}>
                    <Button danger icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              </VerticalButtonGroup>
            </CustomGroupContainer>
          </CustomGroupDraggable>
        ))}
        <CustomGroupContainer>
          <Button icon={<PlusOutlined />} onClick={() => setModalVisible(true)} />
        </CustomGroupContainer>
      </CustomGroupsContainer>
    </>
  )
}

const VerticalButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  > :first-child {
    border-top-left-radius: ${props => props.theme['@border-radius-base']};
    border-top-right-radius: ${props => props.theme['@border-radius-base']};
  }
  > :last-child {
    border-bottom-left-radius: ${props => props.theme['@border-radius-base']};
    border-bottom-right-radius: ${props => props.theme['@border-radius-base']};
  }
`
