import { SettingOutlined } from '@ant-design/icons'
import { Block, BodySmall, Space } from '@thesisedu/react'
import { FontWeight, styled } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { AssignmentHeaderDecorator } from './AssignmentHeaderDecorator'
import { useHeaderTagVisibility } from './useHeaderTagVisibility'
import { useGradingModalVisibilityContext } from '../contexts/GradingModalVisibilityContext'
import { AssignmentWithSubmissions } from '../grading/types'
import { BasicAssignmentFragment } from '../schema'

export interface AssignmentHeaderProps {
  assignment: BasicAssignmentFragment | AssignmentWithSubmissions
  classId?: string
  style?: any
}
export function AssignmentHeader({ assignment, classId, style }: AssignmentHeaderProps) {
  const { setGradingAssignment } = useGradingModalVisibilityContext(false) || {}
  const [headerTagVisibility] = useHeaderTagVisibility()
  return (
    <Container
      style={style}
      className={'grid-border-right grid-border-bottom'}
      onClick={
        setGradingAssignment
          ? () => {
              setGradingAssignment(assignment, {
                defaultTab: 'all-students',
              })
            }
          : undefined
      }
    >
      <BodySmall className={'title'} weight={FontWeight.SemiBold} color={'@primary-color'}>
        {assignment.name}
      </BodySmall>
      <Space />
      {headerTagVisibility ? (
        <Block marginTop={'@size-xs'}>
          <AssignmentHeaderDecorator assignment={assignment} classId={classId} />
        </Block>
      ) : null}
      {setGradingAssignment ? (
        <Button
          className={'actions'}
          icon={<SettingOutlined />}
          size={'small'}
          onClick={e => {
            e.stopPropagation()
            setGradingAssignment(assignment, { defaultTab: 'settings' })
          }}
        />
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  color: ${props => props.theme['@gray-5']};
  padding: ${props => props.theme['@size-s']};
  transition: color 0.1s linear;
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background: ${props => props.theme['@background-color-base']};
  > .title {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: calc(${props => props.theme['@font-size-base']} * 1.4);
    max-height: calc(${props => props.theme['@font-size-base']} * 1.4 * 2);
  }
  .actions {
    position: absolute;
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;
    inset: auto 0 0 auto;
    border-radius: 0;
    border-top-left-radius: ${props => props.theme['@border-radius-base']};
    background: ${props => props.theme['@gray-2']};
  }
  &:hover {
    color: ${props => props.theme['@gray-7']};
    .actions {
      opacity: 1;
      pointer-events: all;
    }
  }
`
