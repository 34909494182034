import React from 'react'

/**
 * This is similar to `useFreshRef()` except it takes a setter function, and allows
 * you to control the dependencies. It also ensures the ref _always_ has a value, even
 * once it's initialized.
 *
 * Typically, if you have a ref and a `useEffect()` that updates it, the ref won't have
 * a value until the effect runs, which is typically after the component renders the
 * first time. The way to get around that, historically, was to use the same logic as the
 * default value of the ref _and_ inside the `useEffect`, which requires duplicating code.
 *
 * Here, we have a helper function that means you don't need to duplicate your code anymore.
 * @param setter the setter function to generate the value
 * @param deps the deps for `useEffect`
 * @returns the ref
 */
export function useUpdatingRef<T>(setter: () => T, deps: any[]) {
  const ref = React.useRef<T>(setter())
  React.useEffect(() => {
    ref.current = setter()
  }, deps)

  return ref
}

export function useUpdatingState<T>(
  setter: () => T,
  deps: any[],
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = React.useState(setter())
  React.useEffect(() => {
    setState(setter())
  }, deps)

  return [state, setState]
}
