import { useViewerContext } from '@thesisedu/feature-users-react'
import moment from 'moment'
import React from 'react'

import { ReviewAssignmentUserTaskFragment, useTeacherClassTaskSummaryQuery } from '../schema'

export interface AssignmentToGrade {
  id: string
  name: string
}
export interface AssignmentDueSoon {
  id: string
  name: string
  dueAt: string
}
export interface GradingTeacherClassTaskContextValueClass {
  classId: string
  assignmentsToGrade: AssignmentToGrade[]
  assignmentsToGradeIsMax: boolean
  assignmentsDueSoon: AssignmentDueSoon[]
  assignmentsDueSoonIsMax: boolean
}
export interface GradingTeacherClassTaskContextValue {
  classes: GradingTeacherClassTaskContextValueClass[]
}

export const GradingTeacherClassTaskContext = React.createContext<
  GradingTeacherClassTaskContextValue | undefined
>(undefined)

const POLL_INTERVAL = 1000 * 60 * 30 // 30 minutes.
const COUNT_MAX = 10
const DUE_SOON_MAX_DAYS = 3 // Don't mark items as due soon if they're due past 3 days.
export function GradingTeacherClassTaskContextProvider({
  children,
}: React.PropsWithChildren<object>) {
  const viewer = useViewerContext(false)
  const { data } = useTeacherClassTaskSummaryQuery({
    pollInterval: POLL_INTERVAL,
    // If this uses the cache, it returns undefined for the data in some cases. Seems like
    // a weird Apollo error with how we're handling our fragments.
    fetchPolicy: 'network-only',
    skip: viewer?.group !== 'TEACHER',
  })
  if (viewer?.group === 'TEACHER') {
    const value: GradingTeacherClassTaskContextValue = {
      classes:
        data?.viewer?.teacher?.classes.edges.map(edge => {
          const classId = edge.node.id
          const assignmentsToGrade = edge.node.userTasks
            .filter(
              task =>
                task.__typename === 'ReviewAssignmentUserTask' &&
                !(task as ReviewAssignmentUserTaskFragment).payload.excluded,
            )
            .map(task => ({
              id: (task as ReviewAssignmentUserTaskFragment).payload.assignmentId,
              name: (task as ReviewAssignmentUserTaskFragment).payload.name,
            }))
          const assignmentsDueSoon =
            data?.viewer?.teacher?.assignmentsDueSoon.edges
              .filter(edge => {
                const isInClass = edge.node.assignmentCategories.edges.some(
                  catEdge => catEdge.node.classId === classId,
                )
                const isBeforeMaxDays =
                  edge.node.dueAt &&
                  moment(edge.node.dueAt).isBefore(moment().add(DUE_SOON_MAX_DAYS, 'days'))
                return isInClass && isBeforeMaxDays
              })
              .map(edge => ({
                id: edge.node.id,
                name: edge.node.name,
                dueAt: edge.node.dueAt,
              })) || []
          return {
            classId: edge.node.id,
            assignmentsToGrade,
            assignmentsToGradeIsMax: assignmentsToGrade.length >= COUNT_MAX,
            assignmentsDueSoon,
            assignmentsDueSoonIsMax: assignmentsDueSoon.length >= COUNT_MAX,
          }
        }) || [],
    }
    return <GradingTeacherClassTaskContext.Provider value={value} children={children} />
  } else {
    return <>{children}</>
  }
}

export function useGradingTeacherClassTaskContext(): GradingTeacherClassTaskContextValue | undefined
export function useGradingTeacherClassTaskContext(
  require: false,
): GradingTeacherClassTaskContextValue | undefined
export function useGradingTeacherClassTaskContext(
  require: true,
): GradingTeacherClassTaskContextValue
export function useGradingTeacherClassTaskContext(
  require?: boolean,
): GradingTeacherClassTaskContextValue | undefined {
  const context = React.useContext(GradingTeacherClassTaskContext)
  if (!context && require) {
    throw new Error('GradingTeacherClassTaskContext is required, yet not provided.')
  }
  return context
}
