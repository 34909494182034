import { size, css } from '@thesisedu/react'
import { ElementNode, LexicalNode, SerializedElementNode } from 'lexical'

import { $createColumnNode, $isColumnNode, normalizeWidth } from './ColumnNode'

export const ColumnsNodeStyles: any = css`
  .columns-node {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${size('@size-s')};
  }
`

export interface SerializedColumnsNode extends SerializedElementNode {
  type: 'columns'
  version: 1
}

export class ColumnsNode extends ElementNode {
  static getType(): string {
    return 'columns'
  }

  static clone(node: ColumnsNode): ColumnsNode {
    return new ColumnsNode(node.__key)
  }

  static importJSON(): ColumnsNode {
    return new ColumnsNode()
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.classList.add('columns-node')
    return dom
  }

  updateDOM(prevNode: ColumnsNode, dom: HTMLElement): boolean {
    // If there is only one column left, remove the whole thing.
    if (this.getChildrenSize() === 1) {
      const column = this.getFirstChild()
      const parent = this.getParent()
      if (column && $isColumnNode(column) && parent) {
        for (const child of column.getChildren()) {
          this.insertBefore(child)
        }
        this.remove()
      }
      this.replace(this.getFirstChildOrThrow())
    }
    return false
  }

  exportJSON(): SerializedColumnsNode {
    return {
      ...super.exportJSON(),
      type: 'columns',
      version: 1,
    }
  }

  refreshWidths() {
    const children = this.getChildren()
    const totalWidth = children.reduce((sum, child) => sum + child.getWidth(), 0)
    if (totalWidth <= 1) return
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      if ($isColumnNode(child)) {
        child.setWidth(normalizeWidth(1 / children.length, children.length - 1))
      }
    }
  }

  append(...nodesToAppend: LexicalNode[]): this {
    const result = super.append(...nodesToAppend)
    this.refreshWidths()
    return result
  }
}

export function $createColumnsNode(): ColumnsNode {
  const node = new ColumnsNode()
  node.append($createColumnNode(), $createColumnNode())
  return node
}

export function $isColumnsNode(node: any): node is ColumnsNode {
  return node instanceof ColumnsNode
}
