import { useApolloClient } from '@apollo/client'
import { updateMutation } from '@thesisedu/feature-apollo-react'
import { useOAuthPopup } from '@thesisedu/web'
import { defaultErrorHandler } from '@thesisedu/web/dist/useOauthPopup'
import { message } from 'antd'
import React from 'react'

import { debug } from '../log'
import {
  useAssociateCanvasMutation,
  CanvasAuthenticationDocument,
  UserFragmentDoc,
  CanvasAuthenticationQuery,
  useTestCanvasHealthMutation,
  useLoginWithCanvasMutation,
  LoginWithCanvasMutation,
} from '../schema'
import { SignInOpts, UseCanvasAssociateProps, UseCanvasAuthProps } from '../types'

export function useCanvasAssociate(props: UseCanvasAssociateProps) {
  const [associate, { loading: associating }] = useAssociateCanvasMutation({
    onError: props.onError,
    update: updateMutation({
      fragment: UserFragmentDoc,
      fragmentName: 'User',
      path: 'associateCanvas changeset.user',
    }),
    onCompleted: () => {
      debug('associate complete, calling onSuccess')
      props.onSuccess()
    },
  })
  const { signIn } = useCanvasAuth({
    ...props,
    onSuccess: (code, redirectUri) => {
      associate({
        variables: {
          input: { authCode: { code, redirectUri } },
        },
      })
    },
    onCancel: () => props.onError(null),
  })

  return {
    signIn: async () => {
      try {
        await signIn()
      } catch (err: any) {
        props.onError(err)
      }
    },
    associating,
  }
}

export function useTestCanvasHealth(onError: () => void) {
  const [testHealth] = useTestCanvasHealthMutation()
  return () => {
    const redirectUri = `${window.location.origin}`
    testHealth({
      variables: {
        input: {
          redirectUri,
        },
      },
    }).then(response => {
      if (response?.data?.testCanvasHealth?.error) {
        const { error, errorDescription } = response.data.testCanvasHealth
        defaultErrorHandler({ error, description: errorDescription || undefined })
        onError()
      }
    })
  }
}

export function useCanvasAuth({ scopes, onCancel, onSuccess }: UseCanvasAuthProps) {
  const client = useApolloClient()
  const redirectUri = `${window.location.origin}`
  const optsRef = React.useRef<SignInOpts | null>(null)
  const { onContainerClick: signIn, closePopup } = useOAuthPopup({
    redirectUri,
    title: 'Sign in with Canvas',
    width: 900,
    height: 500,
    scopes,
    onClose: () => {
      if (onCancel) {
        onCancel()
      }
    },
    onCode: code => {
      onSuccess(code, redirectUri, optsRef.current ?? undefined)
    },
    authorizeUrl: '',
    clientId: '',
  })
  const testHealth = useTestCanvasHealth(() => {
    closePopup()
  })

  return {
    signIn: async (opts: SignInOpts = {}) => {
      optsRef.current = opts
      const { consumerKey, url } = opts
      const result =
        consumerKey && url
          ? { consumerKey, url }
          : (
              await client.query<CanvasAuthenticationQuery>({
                query: CanvasAuthenticationDocument,
                variables: {},
              })
            )?.data?.viewer?.canvasAuthentication
      if (result) {
        // Only test health if we haven't explicitly passed something.
        if (!consumerKey || !url) testHealth()
        signIn({
          clientId: result.consumerKey,
          authorizeUrl: `${result.url}/login/oauth2/auth`,
        })
      } else {
        message.error('Uh oh! Something went wrong trying to fetch your credentials')
      }
    },
  }
}

export interface UseCanvasLoginProps {
  scopes: string[]
  onSuccess: (result: LoginWithCanvasMutation['loginWithCanvas']) => void
  onError: (error: any) => void
}
export const useCanvasLogin = (props: UseCanvasLoginProps) => {
  const [loginWithCanvas, { loading: loggingIn }] = useLoginWithCanvasMutation({
    onError: props.onError,
    onCompleted: data => {
      debug('sign in complete, calling onSuccess')
      props.onSuccess(data.loginWithCanvas)
    },
  })

  return {
    ...useCanvasAuth({
      ...props,
      onSuccess: (code, redirectUri, opts) => {
        if (!opts?.id) throw new Error('Integration ID was not passed to useCanvasLogin.')
        loginWithCanvas({
          variables: {
            input: {
              authCode: { code, redirectUri },
              integrationId: opts.id,
            },
          },
        })
      },
    }),
    loggingIn,
  }
}
