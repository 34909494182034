import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { styled, Body, Block, BodyLarge, LargeBlock, Image } from '@thesisedu/web'
import React from 'react'
import { Link } from 'react-router-dom'

import { CourseFragment } from '../schema'

export interface CreateCoursePreviewProps {
  course: Pick<CourseFragment, 'name' | 'label' | 'id' | 'basicConfiguration'>
  showLinks?: boolean
  onCreate: () => void
}
export function CreateCoursePreview({ course, showLinks, onCreate }: CreateCoursePreviewProps) {
  const { filename } = getFileInfo(course.basicConfiguration.icon)
  return (
    <Container
      style={showLinks ? undefined : { cursor: 'pointer' }}
      onClick={showLinks ? undefined : onCreate}
    >
      <Image src={filename} alt={course.label || course.name} width={64} />
      <Block marginTop={'@size-s'} marginBottom={showLinks ? '@size-s' : undefined}>
        <BodyLarge>{course.label || course.name}</BodyLarge>
      </Block>
      {showLinks ? (
        <Footer>
          <Link to={`/teacher/explore/courses/${course.id}`}>Preview</Link>
          <span>or</span>
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault()
              onCreate()
            }}
          >
            Create Class
          </a>
        </Footer>
      ) : null}
    </Container>
  )
}

const Footer = styled(Body)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
const Container = styled(LargeBlock)`
  margin-bottom: ${props => props.theme['@size-l']};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`
