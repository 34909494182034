import React from 'react'

import { RubricGraderProps } from './RubricGrader'

export interface RubricGraderContextValue
  extends Pick<RubricGraderProps, 'disabled' | 'onChange' | 'value'> {}
export const RubricGraderContext = React.createContext<RubricGraderContextValue | undefined>(
  undefined,
)

export function useRubricGraderContext(): RubricGraderContextValue | undefined
export function useRubricGraderContext(require: false): RubricGraderContextValue | undefined
export function useRubricGraderContext(require: true): RubricGraderContextValue
export function useRubricGraderContext(require?: boolean): RubricGraderContextValue | undefined {
  const context = React.useContext(RubricGraderContext)
  if (!context && require) {
    throw new Error('RubricGraderContext is required, yet not provided.')
  }
  return context
}
