import React from 'react'

import { AssignmentCell } from './AssignmentCell'
import { AssignmentLoadingCell } from './AssignmentLoadingCell'
import { AssignmentCellMode } from './types'
import { AssignmentWithSubmissions, isAssignmentWithSubmissions } from '../grading/types'
import { BasicAssignmentFragment } from '../schema'

export interface AssignmentAverageCellProps {
  assignment: BasicAssignmentFragment | AssignmentWithSubmissions
}
export function AssignmentAverageCell({ assignment }: AssignmentAverageCellProps) {
  const points = isAssignmentWithSubmissions(assignment)
    ? assignment.submissions?.averagePoints
    : undefined
  const grade = isAssignmentWithSubmissions(assignment)
    ? assignment.submissions?.averageGrade
    : undefined
  return (
    <AssignmentLoadingCell isFull={isAssignmentWithSubmissions(assignment)}>
      {points !== null && points !== undefined && grade !== null && grade !== undefined ? (
        <AssignmentCell
          mode={AssignmentCellMode.Graded}
          totalPoints={assignment.totalPoints}
          points={parseFloat(points.toFixed(2))}
          percentage={grade}
          readOnly
        />
      ) : null}
    </AssignmentLoadingCell>
  )
}
