import Table from 'antd/es/table/Table'
import React from 'react'

import { FragmentListCommonProps } from './types'

export function FragmentListView<T>({
  sections,
  getRowData,
  tableProps,
}: FragmentListCommonProps<T>) {
  const itemList = sections.reduce<T[]>((acc, section) => {
    acc.push(...section.items)
    return acc
  }, [])
  const dataSource = getRowData ? itemList.map(item => getRowData(item)) : []
  return <Table<any> pagination={false} {...tableProps} dataSource={dataSource} />
}
