import React from 'react'

import { NavArrowDown } from '../../icons'
import { styledContainer as styled } from '../styled'
import { size } from '../theme'

/** @deprecated */
export function DropdownIcon() {
  return (
    <Container>
      <NavArrowDown />
    </Container>
  )
}

const Container = styled`
  font-size: 0.8em;
  margin-right: -${size('@size-xs')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transform: translateY(-1px);
  > svg {
    display: block;
  }
`
