import { styled } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'
import { Navigate } from 'react-router-dom'

import { AddCourseVersionButton } from './AddCourseVersionButton'
import { CourseVersion } from './CourseVersion'
import { useCourseVersionsQuery } from '../schema'

const List = styled.div`
  margin-bottom: 30px;
`
const ListItem = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px ${props => props.theme['@border-color-split']};
  }
  > div,
  > a > div {
    padding: 5px 10px;
  }
`

export interface CourseVersionsProps {
  courseId: string
  selectedVersionId?: string
  onVersionSelected: (id: string) => void
}

export const CourseVersions: React.FC<React.PropsWithChildren<CourseVersionsProps>> = ({
  courseId,
  selectedVersionId,
  onVersionSelected,
}) => {
  const { data, loading } = useCourseVersionsQuery({ variables: { courseId } })
  const versionNodes =
    data?.node?.__typename === 'Course' ? data.node.versions.edges.map(edge => edge.node) : []
  if (loading) {
    return (
      <List>
        <ListItem>
          <CourseVersion />
        </ListItem>
        <ListItem>
          <CourseVersion />
        </ListItem>
        <ListItem>
          <CourseVersion />
        </ListItem>
      </List>
    )
  } else if (versionNodes.length) {
    const latestItem = versionNodes.reduce<[string, string]>(
      ([latestId, latestTime], version) => {
        if (version.publishedAt && (!latestTime || version.publishedAt > latestTime)) {
          return [version.id, version.publishedAt]
        } else {
          return [latestId, latestTime]
        }
      },
      ['', ''],
    )
    const latestId = latestItem[0]
    const content = versionNodes.map(node => (
      <ListItem key={node.id}>
        <CourseVersion
          onClick={() => onVersionSelected(node.id)}
          version={node}
          isSelected={selectedVersionId === node.id}
          isLatest={latestId === node.id}
        />
      </ListItem>
    ))
    return (
      <List>
        {!selectedVersionId && latestId ? <Navigate to={latestId} replace /> : null}
        {content}
        <ListItem>
          <div>
            <AddCourseVersionButton courseId={courseId} versions={versionNodes} />
          </div>
        </ListItem>
      </List>
    )
  } else {
    return <Empty description={'No versions are configured for this course.'} />
  }
}
