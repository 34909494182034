import { Body, BodyLarge, BodySmall } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { SelectLessonModal } from './SelectLessonModal'
import { useStudentLessonQuery } from '../schema'

export interface LessonFieldProps {
  value?: string
  onChange?: (value?: string) => void
}
export function LessonField({ value, onChange }: LessonFieldProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <SelectLessonModal
        visible={visible}
        onCancel={() => setVisible(false)}
        onSelect={lesson => {
          if (onChange) {
            onChange(lesson.id)
            setVisible(false)
          }
        }}
      />
      {value ? (
        <LessonFieldValue value={value} onClear={() => setVisible(true)} />
      ) : (
        <Button onClick={() => setVisible(true)}>Select or Create Lesson</Button>
      )}
    </>
  )
}

export interface LessonFieldValueProps {
  value: string
  onClear: () => void
}
export function LessonFieldValue({ value, onClear }: LessonFieldValueProps) {
  const { data } = useStudentLessonQuery({ variables: { id: value } })
  const lesson = data?.node?.__typename === 'Lesson' ? data.node : undefined
  return (
    <>
      <BodySmall color={'@text-color-secondary'}>Selected Lesson</BodySmall>
      <BodyLarge>{lesson?.name || '--'}</BodyLarge>
      <Body>
        <a
          onClick={e => {
            e.preventDefault()
            onClear()
          }}
        >
          Select Another
        </a>
      </Body>
    </>
  )
}
