import { $createListNode, $isListNode, ListNode } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Dropdown, Form, Modal, VSpaced } from '@thesisedu/ui'
import { Check, Settings } from '@thesisedu/ui/icons'
import React from 'react'

import { useSettingsDropdownItem } from '../../ui/SettingsDropdown/SettingsDropdownContext'
import { useNodeState } from '../../ui/SettingsDropdown/useNodeState'

export interface ListNodeSettingsProps {
  node: ListNode
  onClose: () => void
}
export function ListNodeSettings({ node, onClose }: ListNodeSettingsProps) {
  const [editor] = useLexicalComposerContext()
  const [start, setStart] = useNodeState(
    editor,
    node,
    node => node.getStart(),
    (node, value) => {
      node.replace($createListNode(node.getListType(), value), true)
    },
  )
  const form = Form.useForm<{ start?: number }>()
  return (
    <Form.Modal
      title={'List Settings'}
      form={form}
      visible
      onVisibleChange={visible => {
        if (!visible) {
          onClose()
        }
      }}
      defaultValue={{ start }}
      onFinish={values => {
        setStart(values.start ?? 0)
      }}
    >
      <VSpaced space={'m'}>
        <Form.NumberField
          name={'start'}
          label={'Start'}
          required
          description={'The number to start the list at.'}
          minValue={1}
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit icon={<Check />} children={'Save Settings'} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export function useListSettings() {
  const [visibleNode, setVisibleNode] = React.useState<ListNode | undefined>(undefined)
  useSettingsDropdownItem({
    identifier: 'list-settings',
    group: 'list',
    weight: -7,
    filter(editor, node) {
      const parent = node.getParent()
      return $isListNode(parent)
    },
    element: ({ node, editor }) => (
      <Dropdown.Item
        onClick={() => {
          editor.getEditorState().read(() => {
            const parent = node.getParent()
            if ($isListNode(parent)) {
              setVisibleNode(parent)
            }
          })
        }}
        icon={<Settings />}
      >
        List Settings...
      </Dropdown.Item>
    ),
  })

  return visibleNode ? (
    <ListNodeSettings node={visibleNode} onClose={() => setVisibleNode(undefined)} />
  ) : null
}
