import { Checkbox, CheckboxProps } from 'antd'
import React from 'react'

import { styled } from './styledTypes'

const Container = styled.button`
  display: flex;
  margin: 10px 0;
  font-size: 14px;
  line-height: 1.5;
  align-items: flex-start;
  position: relative;
  background: ${props => props.theme['@gray-1']};
  transition:
    background 0.1s linear,
    color 0.1s linear,
    opacity 0.1s linear;
  color: ${props => props.theme['@text-color']};
  padding: 10px 10px 10px 20px;
  opacity: 1;
  border-radius: ${props => props.theme['@border-radius-base']};
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background: ${props => props.theme['@blue']};
    color: white;
  }
  > div {
    flex-grow: 1;
    margin: 0 0 0 10px;
    text-align: left;
    > p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    > strong {
      font-size: 18px;
    }
  }
  svg {
    font-size: 20px;
    margin-top: 5px;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`

export interface LargeButtonProps {
  title: string
  disabled?: boolean
  icon: React.ReactElement
  onClick: () => void
}
export function LargeButton({
  title,
  children,
  icon,
  onClick,
  disabled,
}: React.PropsWithChildren<LargeButtonProps>) {
  return (
    <Container onClick={onClick} disabled={disabled}>
      {icon}
      <div>
        <strong>{title}</strong>
        {children}
      </div>
    </Container>
  )
}

export interface LargeCheckboxProps extends CheckboxProps {
  title: string
  children?: React.ReactElement
  icon: React.ReactElement
}
export function LargeCheckbox({ title, children, icon, ...rest }: LargeCheckboxProps) {
  const classNames = [
    rest.checked ? 'checked' : undefined,
    rest.disabled ? 'disabled' : undefined,
  ].filter(Boolean)
  return (
    <CheckboxContainer
      className={classNames.join(' ')}
      onClick={() => {
        if (rest.onChange) {
          rest.onChange({
            target: {
              checked: !rest.checked,
            },
          } as any)
        }
      }}
    >
      {icon}
      <div>
        <strong>{title}</strong>
        {children}
      </div>
      <CheckboxInnerContainer onClick={e => e.preventDefault()}>
        <Checkbox {...rest} />
      </CheckboxInnerContainer>
    </CheckboxContainer>
  )
}

const CheckboxInnerContainer = styled.div`
  opacity: 0;
  transition: opacity 0.25s linear;
  position: absolute;
  top: 0;
  right: 0;
`
const CheckboxContainer = styled(Container)`
  background: transparent !important;
  color: ${props => props.theme['@text-color']} !important;
  padding: 0;
  width: 100%;
  transition: opacity 0.25s linear;
  opacity: 0.75;
  position: relative;
  * {
    transition:
      color 0.25s linear,
      opacity 0.25s linear;
  }
  &.checked,
  &:hover {
    opacity: 1;
    ${CheckboxInnerContainer} {
      opacity: 1;
    }
  }
  &.disabled {
    opacity: 0.5 !important;
    ${CheckboxInnerContainer} {
      opacity: 0 !important;
    }
  }
  &.checked * {
    color: ${props => props.theme['@primary-color']};
  }
  &:not(:last-child) {
    margin-bottom: ${props => props.theme['@size-xs']};
  }
`
