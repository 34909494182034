import { AnyEditorValue, BasicSerializedWidget } from './types'
import { find as findBlock } from '../find'

const findWidget = (widgets: any[], id: string): any | null => {
  for (const widget of widgets) {
    if (widget.id === id) {
      return widget
    } else if (widget.childGroups) {
      for (const key of Object.keys(widget.childGroups)) {
        const hasChild = findWidget(widget.childGroups[key] || [], id)
        if (hasChild) return hasChild
      }
    }
  }
  return null
}

export function find(
  value: AnyEditorValue | undefined | null,
  id: string,
): BasicSerializedWidget | null {
  if (Array.isArray(value)) {
    return findWidget(value, id)
  } else if (value) {
    return findBlock(value, id)
  } else {
    return null
  }
}
