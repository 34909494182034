import { Modal, ModalFuncProps } from 'antd'
import React from 'react'
import { useBlocker } from 'react-router-dom'

interface Transaction {
  retry: () => void
}
type Blocker = (tx: Transaction) => void

export function usePrompt(isDirty: boolean, modalProps?: Partial<ModalFuncProps>) {
  const blocker = React.useCallback<Blocker>(
    tx => {
      Modal.confirm({
        title: 'You have unsaved changes.',
        content: 'You will lose them if you continue. Are you sure you want to close this?',
        okText: 'Go Back & Keep Changes',
        cancelButtonProps: { danger: true },
        cancelText: 'Continue Without Saving',
        width: 650,
        ...modalProps,
        onCancel: () => {
          tx.retry()
        },
      })
    },
    [modalProps],
  )
  useBlocker(blocker, isDirty)
}
