import {
  ListNode,
  ListItemNode,
  $isListNode,
  $createListNode,
  $isListItemNode,
} from '@lexical/list'
import { DraggableNode } from '@thesisedu/feature-widgets-core'
import { $getNodeByKey, $isElementNode, $splitNode, LexicalNode } from 'lexical'

import { ChangeableNode } from '../ui/ElementsMenu/types'
import { $getDOMNode } from '../utils/getDomNode'

export function $listItemContainsNestedList(item: LexicalNode): boolean {
  return item.getChildrenSize() === 1 && $isListNode(item.getFirstChild())
}

declare module '@lexical/list' {
  interface ListNode extends DraggableNode {}
  interface ListItemNode extends DraggableNode, ChangeableNode {}
}

ListNode.prototype.canDrag = function () {
  return false
}

const originalUpdateDOM = ListNode.prototype.updateDOM
ListNode.prototype.updateDOM = function (prevNode, dom, config) {
  // Collapse duplicate lists into one...
  const nextSibling = this.getNextSibling()
  const nextListSibling = $isListNode(nextSibling) ? nextSibling : null
  if (nextListSibling) {
    const nextListChildren = nextListSibling.getChildren()
    for (const child of nextListChildren) {
      this.append(child)
    }
    nextListSibling.remove()
  }

  // If the list is empty, remove it.
  if (!this.getChildrenSize()) {
    this.remove()
    return false
  }

  return originalUpdateDOM.call(this, prevNode, dom, config)
}

export function splitListIfRequired(newElements: LexicalNode[], index: number, parent: ListNode) {
  if (!newElements.length) return
  const [before, after] = index ? $splitNode(parent, index) : [null, parent]
  for (const element of newElements) {
    after.insertBefore(element)
  }
  if (!after.getChildrenSize()) {
    after.remove()
  }
  if (!parent.getChildrenSize()) {
    parent.remove()
  }
  if (before && !before.getChildrenSize()) {
    before.remove()
  }
  const nextChild = newElements[newElements.length - 1].getNextSibling()

  // Update the start of the second list if we're using numbered lists.
  if ($isListNode(nextChild) && $isListNode(before) && before.getListType() === 'number') {
    nextChild.replace(
      $createListNode(nextChild.getListType(), (before.getStart() || 0) + before.getChildrenSize()),
      true,
    )
  }
}

ListItemNode.prototype.onChangeTo = function (item) {
  // If the previous item is a ListItem, add it as a child of that item instead.
  const parent = this.getParent()
  const indexWithinParent = this.getIndexWithinParent()
  const newElement = item.onCommit(this)
  if (newElement && $isListNode(parent)) {
    splitListIfRequired([newElement], indexWithinParent, parent)
    this.remove()

    return true
  }
}

ListItemNode.prototype.canDrag = function () {
  const isNestedListParent = this.getChildren().some(child => $isListNode(child))
  return !isNestedListParent
}

ListItemNode.prototype.getDraggableNode = function () {
  const nextSibling = this.getNextSibling()
  const nextChildContainsNestedList = nextSibling && $listItemContainsNestedList(nextSibling)
  if (nextChildContainsNestedList) {
    return [this, nextSibling]
  } else {
    return this
  }
}

ListItemNode.prototype.beforeDrop = function (draggedNodes, dropTarget, shouldInsertAfter) {
  // If we are not dropping into a list, we need to wrap this item in a list.
  const dropTargetParentIsList = $isListNode(dropTarget.getParent())
  if (!dropTargetParentIsList) {
    const parentListNode = this.getParent() as ListNode
    // Remove the nodes first so we automatically remove any empty lists.
    for (const node of draggedNodes) {
      node.remove(false)
    }
    const list = $createListNode(parentListNode.getListType())
    list.append(...draggedNodes)
    return [list]
  } else {
    return draggedNodes
  }
}

ListItemNode.prototype.modifyDropOnDOMElement = function (
  editor,
  targetBlockElement,
  draggedNodes,
  beforeAfter,
) {
  // If we are inserting non-list after this item, and this item already has children, add as a child instead.
  const elementChildren = this.getChildren().filter(child => $isElementNode(child))
  if (beforeAfter === 1 && !$isListItemNode(draggedNodes[0]) && elementChildren.length) {
    const key = elementChildren[0].getKey()
    return { key, element: $getDOMNode(editor, key), beforeAfter: -1 }
  }
}
ListItemNode.prototype.beforeDropOn = function (draggedNodes, shouldInsertAfter) {
  // Split the list if we need to.
  if (!$isListItemNode(draggedNodes[0])) {
    const indexWithinParent = this.getIndexWithinParent() + (shouldInsertAfter ? 1 : 0)
    const parent = this.getParent()
    if ($isListNode(parent)) {
      splitListIfRequired(draggedNodes, indexWithinParent, parent)
      return []
    }
  }

  return draggedNodes
}
// We have to override this because otherwise it will try to add the paragraph's
// contents inside it instead of adding underneath.
ListItemNode.prototype.canMergeWith = function (node) {
  return $isListItemNode(node)
}

ListItemNode.prototype.modifyDropDOMElement = function (
  editor,
  targetBlockElement,
  targetKey,
  beforeAfter,
) {
  const targetNode = $getNodeByKey(targetKey)
  if (targetNode) {
    const nextSibling = targetNode.getNextSibling()

    // If we are dropping before the top of the list.
    const nextChildIsFirstInList = $isListNode(nextSibling)
    if (nextChildIsFirstInList) {
      const firstItem = nextSibling.getFirstChild()
      const firstListItem = $isListItemNode(firstItem) ? firstItem : null
      if (firstListItem) {
        const key = firstListItem.getKey()
        return { key, element: $getDOMNode(editor, key), beforeAfter: -1 }
      }
    }

    // If we are dropping after the top of the list.
    const nextChildContainsNestedList = nextSibling && $listItemContainsNestedList(nextSibling)
    if (nextChildContainsNestedList) {
      const nestedListContainer = nextSibling as ListNode
      const nestedList = nestedListContainer.getFirstChild()
      if ($isListNode(nestedList)) {
        const nestedListItem = nestedList.getFirstChild()
        if ($isListItemNode(nestedListItem)) {
          const key = nestedListItem.getKey()
          return { key, element: $getDOMNode(editor, key), beforeAfter: -1 }
        }
      }
    }
  }
}

export { ListNode, ListItemNode } from '@lexical/list'
