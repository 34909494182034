import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useRetrySubmissionMutation as useMutation } from '../schema'

export const useRetrySubmissionMutation = (opts?: Parameters<typeof useMutation>[0]) =>
  useMutation({
    onError: onMutationError('There was an error retrying that assignment.'),
    ...opts,
    refetchQueries: [...(opts?.refetchQueries as any), 'classAssignmentCategoriesWithGrades'],
    awaitRefetchQueries: true,
  })
