import { useGoogleLogin as useCoreGoogleLogin } from '@react-oauth/google'
import { updateMutation } from '@thesisedu/feature-apollo-react'

import { debug, warn } from '../log'
import { useAssociateGoogleMutation, useLoginWithGoogleMutation, UserFragmentDoc } from '../schema'
import { UseGoogleAssociateProps, UseGoogleAuthProps, UseGoogleLoginProps } from '../types'

export const useGoogleLogin = (props: UseGoogleLoginProps) => {
  const [loginWithGoogle, { loading: loggingIn }] = useLoginWithGoogleMutation({
    onError: props.onError,
    onCompleted: data => {
      debug('sign in complete, calling onSuccess')
      props.onSuccess(data.loginWithGoogle)
    },
  })

  return {
    ...useGoogleAuth({
      ...props,
      onSuccess: (code, redirectUri) => {
        loginWithGoogle({
          variables: {
            input: { code, redirectUri },
          },
        })
      },
    }),
    loggingIn,
  }
}

export const useGoogleAssociate = (props: UseGoogleAssociateProps) => {
  const [associate, { loading: associating }] = useAssociateGoogleMutation({
    onError: props.onError,
    update: updateMutation({
      fragment: UserFragmentDoc,
      fragmentName: 'User',
      path: 'associateGoogle.user',
    }),
    onCompleted: () => {
      debug('associate complete, calling onSuccess')
      props.onSuccess()
    },
  })

  return {
    ...useGoogleAuth({
      ...props,
      onSuccess: (code, redirectUri) => {
        associate({
          variables: {
            input: { code, redirectUri },
          },
        })
      },
      onCancel: () => props.onError(null),
    }),
    associating,
  }
}

export const useGoogleAuth = (props: UseGoogleAuthProps) => {
  const signIn = useCoreGoogleLogin({
    scope: props.scopes.join(' '),
    flow: 'auth-code',
    onSuccess: response => {
      debug('sign in result from google', response)
      if (response.code) {
        props.onSuccess(response.code, 'postmessage')
      } else {
        warn('no code found from google', response)
        props.onError(null)
      }
    },
    onError(error) {
      warn('error signing in with google: %O', error)
      props.onError(error)
    },
    onNonOAuthError() {
      if (props.onCancel) {
        props.onCancel()
      }
    },
  })

  return { signIn }
}
