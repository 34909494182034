import { onMutationError } from '@thesisedu/feature-apollo-react'
import { useFeatureRoot } from '@thesisedu/feature-react'
import { Button, HSpaced, Modal, Text, useToast } from '@thesisedu/ui'
import React from 'react'

import { useRequestInformationForClassSyncSetupMutation } from '../../schema'

export interface RequiresSetupModalProps {
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
  providerName: string
  providerIdentifier: string
}
export function RequiresSetupModal({
  visible,
  onVisibleChange,
  providerName,
  providerIdentifier,
}: RequiresSetupModalProps) {
  const { appOptions } = useFeatureRoot()
  const [requested, setRequested] = React.useState(false)
  const toast = useToast()
  const [request, { loading }] = useRequestInformationForClassSyncSetupMutation({
    variables: {
      input: {
        providerIdentifier,
      },
    },
    onError: onMutationError('There was an error requesting setup. Please contact support.'),
    onCompleted(data) {
      onVisibleChange?.(false)
      toast({
        title: 'Request sent!',
        description: `${appOptions.name} Support will be in touch shortly.`,
        status: 'success',
      })
    },
  })
  return (
    <Modal
      visible={visible}
      onVisibleChange={onVisibleChange}
      title={`The ${providerName} integration requires setup from an IT administrator.`}
    >
      <Text>
        If you would like to get started using {providerName} with {appOptions.name}, either contact
        support or automatically let us know your interest by using the button below.
      </Text>
      <HSpaced justify={'center'} top={'s'}>
        <Button
          onPress={() => {
            setRequested(true)
            return request()
          }}
          loading={loading}
          disabled={requested}
        >
          Request Information to Setup {providerName}
        </Button>
      </HSpaced>
    </Modal>
  )
}
