import React from 'react'

export interface SegmentedControlContextValue {
  id: string
}
export const SegmentedControlContext = React.createContext<
  SegmentedControlContextValue | undefined
>(undefined)

export function useSegmentedControlContext(): SegmentedControlContextValue {
  const context = React.useContext(SegmentedControlContext)
  if (!context) {
    throw new Error('SegmentedControlContext is required, yet not provided.')
  }
  return context
}
