import { onMutationError } from '@thesisedu/feature-apollo-react'

import {
  CourseViewSegmentFragment,
  CourseViewSegmentFragmentDoc,
  useImportCourseViewWeightsMutation,
} from '../schema'

export const useImportCourseViewWeightsSyncMutation: typeof useImportCourseViewWeightsMutation =
  opts => {
    const [mutate, rest] = useImportCourseViewWeightsMutation({
      onError: onMutationError('There was an error updating the order.'),
      optimisticResponse: {
        __typename: 'Mutation',
        importCourseViewWeights: {
          success: true,
        },
      },
      ...opts,
    })
    return [
      opts =>
        mutate({
          variables: {
            input: {
              ...opts!.variables!.input,
              sync: true,
            },
          },
          async update(cache) {
            for (const weight of opts?.variables?.input.weights ?? []) {
              const existing = await cache.readFragment<CourseViewSegmentFragment>({
                fragment: CourseViewSegmentFragmentDoc,
                fragmentName: 'CourseViewSegment',
                id: `Segment:${weight.segmentId}`,
              })
              if (!existing) continue
              await cache.writeFragment<CourseViewSegmentFragment>({
                fragment: CourseViewSegmentFragmentDoc,
                fragmentName: 'CourseViewSegment',
                id: `Segment:${weight.segmentId}`,
                data: {
                  ...existing,
                  courseViewWeight: weight.weight,
                },
              })
            }
          },
        }),
      rest,
    ]
  }
