import { SettingOutlined } from '@ant-design/icons'
import { useStatePropFallback } from '@thesisedu/feature-react'
import {
  InstrumentSelect,
  KeyboardProps as SheetMusicKeyboardProps,
  useInstruments,
} from '@thesisedu/feature-sheet-music-react'
import { ProgressOverlay, SimpleSelect, Body, VSpaced, styled, Transition } from '@thesisedu/web'
import { Button, Popover } from 'antd'
import * as MIDI from 'midicube'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { useKeyboardButtonContext } from './KeyboardButtonContext'
import { KeyboardContext, KeyboardContextValue } from './KeyboardContext'
import { RestButton } from './RestButton'
import { MODES } from './modes'
import { KeyboardType } from './modes/types'

export type KeyboardProps = SheetMusicKeyboardProps & {
  dontHideButton?: boolean
}
export function Keyboard({
  simple,
  selectedInstrument: _selectedInstrument,
  onInstrumentChange: _onInstrumentChange,
  dontHideButton,
  onSimpleChange,
  ...rest
}: KeyboardProps) {
  const buttonContext = useKeyboardButtonContext(false)
  const [hideKey] = React.useState(uuid())
  React.useEffect(() => {
    if (!dontHideButton && buttonContext) {
      buttonContext.setButtonHidden(true, hideKey)
      return () => buttonContext.setButtonHidden(false, hideKey)
    }
  }, [dontHideButton, hideKey])
  const [selectedType, setSelectedType] = React.useState(
    simple ? KeyboardType.Note8 : KeyboardType.Note25,
  )
  React.useEffect(() => {
    if (onSimpleChange) {
      onSimpleChange(selectedType === KeyboardType.Note8)
    }
  }, [onSimpleChange, selectedType])
  const INSTRUMENTS = useInstruments({ simpleMode: selectedType === KeyboardType.Note8 })
  const [selectedInstrumentKey, setSelectedInstrumentKey] = useStatePropFallback<string>(
    _selectedInstrument,
    _onInstrumentChange,
    INSTRUMENTS[0].identifier!,
  )
  const availableModes = MODES.filter(mode => mode.types.includes(selectedType))
  const [selectedMode, setSelectedMode] = React.useState(availableModes[0].id)
  const [current, setCurrent] = React.useState(100)
  const readyRef = React.useRef(false)
  const originalInstrumentRef = React.useRef<string | null>(null)
  React.useEffect(() => {
    const mode = availableModes.find(mode => mode.id === selectedMode)
    if (mode?.setInstrument) {
      if (!originalInstrumentRef.current) {
        originalInstrumentRef.current = selectedInstrumentKey
      }
      setSelectedInstrumentKey(mode.setInstrument)
    } else if (originalInstrumentRef.current) {
      setSelectedInstrumentKey(originalInstrumentRef.current)
      originalInstrumentRef.current = null
    }
  }, [selectedMode])
  React.useEffect(() => {
    readyRef.current = false
    const instrument = INSTRUMENTS.find(i => i.identifier === selectedInstrumentKey)
    if (instrument) {
      setCurrent(0)
      ;(global as any).MIDI = MIDI
      // This is here to reset the soundfont each time the instrument changes.
      MIDI.Soundfont[instrument.instrument || 'acoustic_grand_piano'] = undefined
      MIDI.loadPlugin({
        onsuccess: () => {
          setCurrent(100)
          readyRef.current = true
          MIDI.setVolume(0, 100)
          // Set the instrument on channel 0 once loaded.
          MIDI.programChange(
            0,
            MIDI.GM.byName[instrument.instrument || 'acoustic_grand_piano'].program,
          )
        },
        onerror: (err: any) => {
          console.warn('error loading soundfont')
          console.warn(err)
        },
        onprogress: (state: any, percent: number) => {
          setCurrent(percent * 100)
        },
        soundfontUrl: instrument.soundfontUrl,
        instrument: instrument.instrument,
      })
    }
  }, [selectedInstrumentKey])
  React.useEffect(() => {
    setSelectedMode(availableModes[0].id)
  }, [selectedType])
  const keyboardContext = React.useMemo<KeyboardContextValue>(() => {
    return {
      playKey: key => {
        if (!readyRef.current) {
          console.warn('not playing key yet; midi is not ready')
          return
        }
        MIDI.noteOn(0, MIDI.keyToNote[key], 127, 0)
        MIDI.noteOff(0, MIDI.keyToNote[key], 0.5)
      },
    }
  }, [])
  const ModeContents = MODES.find(m => m.id === selectedMode)?.contents
  return (
    <KeyboardContext.Provider value={keyboardContext}>
      <Container>
        <ModesContainer vertical={selectedType === KeyboardType.Note25}>
          {MODES.filter(mode => mode.types.includes(selectedType)).map(mode => (
            <ModeButton
              key={mode.id}
              large={mode.large}
              onClick={e => {
                e.preventDefault()
                setSelectedMode(mode.id)
              }}
              selected={selectedMode === mode.id}
            >
              {typeof mode.icon === 'string' ? (
                <img src={mode.icon} alt={mode.name} />
              ) : (
                <mode.icon />
              )}
            </ModeButton>
          ))}
        </ModesContainer>
        <ContentContainer>
          <Transition state={selectedMode} type={'fade'}>
            {ModeContents ? <ModeContents simple={simple} {...rest} /> : <div />}
          </Transition>
        </ContentContainer>
        <VSpaced justify={'center'}>
          <RestButton {...rest} />
          <Popover
            trigger={['click']}
            title={'Keyboard Settings'}
            placement={'topRight'}
            content={
              <VSpaced align={'stretch'} space={'@size-s'}>
                <VSpaced space={'@size-xxs'}>
                  <Body>Instrument</Body>
                  <InstrumentSelect
                    instruments={INSTRUMENTS}
                    value={selectedInstrumentKey}
                    onChange={setSelectedInstrumentKey}
                    style={{ width: 300 }}
                  />
                </VSpaced>
                <VSpaced space={'@size-xxs'}>
                  <Body>Keyboard Type</Body>
                  <SimpleSelect
                    value={selectedType}
                    onChange={key => setSelectedType(key as KeyboardType)}
                    style={{ width: 300 }}
                    options={[
                      { value: KeyboardType.Note8, label: '8 Note' },
                      { value: KeyboardType.Note25, label: '25 Note' },
                    ]}
                  />
                </VSpaced>
              </VSpaced>
            }
          >
            <Button icon={<SettingOutlined />} size={'large'} />
          </Popover>
        </VSpaced>
        <ProgressOverlay current={current} total={100} />
      </Container>
    </KeyboardContext.Provider>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-s']};
  }
`
const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-x: auto;
`
const ModesContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  max-width: 200px;
`
const ModeButton = styled.button<{ selected?: boolean; large?: boolean }>`
  outline: none;
  background: ${props => props.theme['@gray-1']};
  border-radius: ${props => props.theme['@border-radius-base']};
  opacity: 0.5;
  transition:
    opacity 0.25s linear,
    border 0.25s linear;
  padding: ${props => props.theme['@padding-xs']};
  cursor: pointer;
  &:hover,
  ${props => (props.selected ? '&' : '.noop')} {
    opacity: 1;
  }
  margin-right: ${props => props.theme['@size-xs']};
  margin-bottom: ${props => props.theme['@size-xs']};
  flex-basis: calc(33.33% - ${props => props.theme['@size-xs']});
  border: solid 2px ${props => (props.selected ? props.theme['@red'] : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg,
  img {
    width: ${props => props.theme['@size-l']};
    height: auto;
    display: block;
    path[fill^='#000'] {
      fill: ${props => props.theme['@red']};
    }
  }
  ${props => (props.large ? '&' : '&.noop')} {
    flex-basis: calc(50% - ${props => props.theme['@size-xs']});
    padding: ${props => props.theme['@padding-sm']};
    svg,
    img {
      width: ${props => props.theme['@size-xl']};
    }
  }
`
