import { Question } from '@thesisedu/feature-assignments-core'
import React from 'react'

export interface QuestionContainerContextValue {
  onAdd: (questionType: string, afterId?: string) => void
  onChange: (id: string, changes: Partial<Question>) => void
  onClone: (id: string) => void
  onRemove: (id: string) => void
}
export const QuestionContainerContext = React.createContext<
  QuestionContainerContextValue | undefined
>(undefined)

export function useQuestionContainerContext(): QuestionContainerContextValue | undefined
export function useQuestionContainerContext(
  require: false,
): QuestionContainerContextValue | undefined
export function useQuestionContainerContext(require: true): QuestionContainerContextValue
export function useQuestionContainerContext(
  require?: boolean,
): QuestionContainerContextValue | undefined {
  const context = React.useContext(QuestionContainerContext)
  if (!context && require) {
    throw new Error('QuestionContainerContext is required, yet not provided.')
  }
  return context
}
