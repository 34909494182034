import { useMutateHook } from '@thesisedu/feature-react'
import { Shell } from '@thesisedu/feature-web'
import React from 'react'

import { ClassFragment } from './schema'
import { ClassLeftNavHook } from './types'

export interface ClassLeftNavProps {
  cls: ClassFragment
  leftTopItem?: React.ReactElement
  group: string
}
export function ClassLeftNav({ cls, leftTopItem, group }: ClassLeftNavProps) {
  const leftItems = useMutateHook<ClassLeftNavHook>('classes-react:class-left-nav', [], {
    cls,
    group,
  })
  Shell.useShellProps(
    'ClassLeftNav',
    {
      leftTopItem,
      leftItems,
      leftNavPrefix: `/${group.toLowerCase()}/classes/${cls.id}`,
    },
    [cls.id, leftItems.length, cls.students?.edges?.length],
  )

  return null
}
