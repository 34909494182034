import { PageHead } from '@thesisedu/react'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { EditSite } from './EditSite'
import { CreateSiteButton } from '../../CreateSiteButton'
import { AllSitesTable } from '../../SitesTable'

export function SitesRoute() {
  const navigate = useNavigate()
  useBreadcrumb({ title: 'Sites' })
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <>
            <PageHead title={'Sites'} />
            <PageHeader title={'Sites'}>
              <CreateSiteButton onCreated={site => navigate(`/admin/sites/${site.id}`)} />
            </PageHeader>
            <AllSitesTable />
          </>
        }
      />
      <Route path={'/:siteId'} element={<EditSite />} />
    </Routes>
  )
}
