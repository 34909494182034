import React from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

/**
 * Like useEffect(), except it does a deep comparison and does not run
 * on the first mount.
 * @param variables - the variables for the query
 * @param callback - callback to call whenever the variables _actually_ change
 */
export function useVariableEffect(variables: object | undefined, callback: () => void) {
  const variableRef = React.useRef<object>()
  useDeepCompareEffect(() => {
    if (!variableRef.current) {
      variableRef.current = variables
    } else if (variables) {
      callback()
    }
  }, [variables || {}])
}
