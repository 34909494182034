import { styled, s } from '@thesisedu/ui'

const { css } = s
export const RecordFooterCellStyles = css`
  border-radius: ${s.var('radii.1')};
  display: flex;
  align-items: center;
  gap: ${s.size('xxs')};
  height: 40px;
  padding: 0 ${s.size('xs')};
`
export const RecordFooterCell = styled.div`
  ${RecordFooterCellStyles}
  background: ${s.color('element')};
`
