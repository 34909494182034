import { BulkActionEntry, useBulkActionsWithModal } from '@thesisedu/feature-apollo-react'
import { Button, Dropdown, Modal, Text } from '@thesisedu/ui'
import { Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { AssignmentBulkActionItemProps, BulkActionSelection } from './AssignmentBulkActions'
import { refetchQueries } from './refetchQueries'
import {
  GradeAssignmentSubmissionDocument,
  SaveAssignmentSubmissionDocument,
  SaveAssignmentSubmissionMutation,
  SaveAssignmentSubmissionMutationVariables,
} from '../../schema'
import { QuickGradeField } from '../QuickGradeField'

export function AssignGrade({
  selections,
  assignment,
  totalPoints,
  ...props
}: AssignmentBulkActionItemProps) {
  const assignmentId = assignment.id
  const [grade, setGrade] = React.useState<number | undefined>(0)
  const [run, { modal }] = useBulkActionsWithModal()
  const [visible, setVisible] = React.useState(false)

  return (
    <>
      {modal}
      <Modal
        title={'Assign Grade'}
        visible={visible}
        onVisibleChange={v => {
          setVisible(v)
          if (v) {
            setGrade(0)
          }
        }}
        trigger={
          <Dropdown.Item
            icon={<Edit />}
            disabled={!assignment.canGrade}
            children={'Assign Grade...'}
          />
        }
      >
        <Text bottom>
          Select a grade below and then click <strong>Assign Grade</strong>
        </Text>
        <QuickGradeField totalPoints={totalPoints} value={grade} onChange={setGrade} />

        <Modal.Footer>
          <Modal.CloseButton />
          <Button
            variant={'primary'}
            children={'Assign Grade'}
            disabled={grade === undefined}
            onPress={async () => {
              const filteredSelections = filterUniqueGroups(selections)

              const selectionsToSave = filteredSelections
                .filter(selection => !selection.submissionId)
                .map<
                  BulkActionEntry<
                    SaveAssignmentSubmissionMutationVariables,
                    SaveAssignmentSubmissionMutation
                  >
                >(selection => ({
                  onComplete(data) {
                    selection.submissionId = data.saveAssignmentSubmission.assignmentSubmission.id
                  },
                  classId: selection.classId,
                  includeConfiguration: false,
                  input: {
                    id: assignmentId,
                    classId: selection.classId,
                    studentId: selection.studentId,
                    submit: true,
                    submissionData: {},
                  },
                }))

              if (selectionsToSave.length) {
                await run({
                  message: 'Saving submissions before grading',
                  mutation: SaveAssignmentSubmissionDocument,
                  variables: selectionsToSave,
                  noWait: true,
                })
              }

              await run({
                message: 'Assigning grades',
                mutation: GradeAssignmentSubmissionDocument,
                variables: filteredSelections
                  .filter(selection => selection.submissionId)
                  .map(selection => ({
                    input: {
                      id: selection.submissionId!,
                      grades: {
                        all: grade,
                      },
                      comments: {},
                    },
                  })),
                refetchQueries: refetchQueries(assignment.id),
              })

              setVisible(false)
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export function filterUniqueGroups(selections: BulkActionSelection[]): BulkActionSelection[] {
  const coveredGroups = new Set<string>()
  return selections.filter(selection => {
    // We only want unique groups in the selections, otherwise we'll run into deadlock
    // errors in the database.
    if (selection.groupName) {
      if (!coveredGroups.has(selection.groupName)) {
        coveredGroups.add(selection.groupName)
        return true
      } else return false
    } else {
      return true
    }
  })
}
