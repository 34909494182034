import { useFeature } from '@thesisedu/feature-react'
import { Mic } from '@thesisedu/ui/icons'
import React from 'react'

import { SelectMediaSource, SelectMediaSourceProps } from './SelectMediaSource'
import MediaReactFeature from '../../../MediaReactFeature'

export interface SelectAudioSourceProps
  extends Pick<SelectMediaSourceProps, 'value' | 'onChange' | 'disabled'> {}
export function SelectAudioSource(props: SelectAudioSourceProps) {
  const audioConstraint = useFeature(MediaReactFeature).options.audioConstraint ?? true
  return (
    <SelectMediaSource
      {...props}
      icon={<Mic />}
      label={'Audio'}
      constraints={{ audio: audioConstraint }}
      type={'audioinput'}
    />
  )
}
