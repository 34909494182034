import { NotFoundPageAccessDeniedHook } from '@thesisedu/feature-web/dist/NotFoundPage'

import { UsersReactFeature } from '../UsersReactFeature'

export default function (feature: UsersReactFeature) {
  feature.hookManager.registerMutateHook<NotFoundPageAccessDeniedHook>(
    'web:not-found-access-denied',
    (_, { cleanPath }) => {
      return `/auth/login?redirect=${encodeURIComponent(cleanPath)}`
    },
  )
}
