import { ExploreBannerHook } from '@thesisedu/feature-courses-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { Link, styled } from '@thesisedu/react'
import { LargeCard } from '@thesisedu/web'
import React from 'react'

// @ts-ignore
import sheetMusicLibrary from '../assets/sheet-music-library-themed.jpg'
// @ts-ignore
import videoLibrary from '../assets/video-library-themed.jpg'

function ExploreBanner() {
  return (
    <BannerContainer>
      <Link to={'videos'}>
        <VideoLibraryCard>
          <img src={videoLibrary} alt={'Video Library'} />
        </VideoLibraryCard>
      </Link>
      <Link to={'sheet-music'}>
        <VideoLibraryCard>
          <img src={sheetMusicLibrary} alt={'Sheet Music Library'} />
        </VideoLibraryCard>
      </Link>
    </BannerContainer>
  )
}
const VideoLibraryCard = styled(LargeCard)`
  cursor: pointer;
  img {
    width: 100%;
  }
`
const BannerContainer = styled.div`
  margin-top: ${props => props.theme['@size-m']};
  margin-bottom: ${props => props.theme['@size-xl']};
  display: flex;
  align-items: center;
  gap: ${props => props.theme['@size-l']};
  > * {
    flex: 1;
  }
`

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<ExploreBannerHook>(
    'courses-react:explore-banner',
    banner => {
      return <ExploreBanner />
    },
  )
}
