import React, { useEffect, useState } from 'react'

import { AttachmentReadFragment } from '../schema'
import {
  AttachmentCreateProps,
  AttachmentFormFieldProps,
  AttachmentFormFieldValue,
  AttachmentReadProps,
} from '../types'

export const isReadFragment = (
  value: AttachmentReadFragment | AttachmentFormFieldValue,
): value is AttachmentReadFragment => {
  return (value as AttachmentReadFragment).field !== undefined
}

export interface CommonAttachmentFormFieldProps extends AttachmentFormFieldProps {
  AttachmentRead: React.FC<React.PropsWithChildren<AttachmentReadProps>>
  AttachmentCreate: React.FC<React.PropsWithChildren<AttachmentCreateProps>>
}
export const CommonAttachmentFormField: React.FC<
  React.PropsWithChildren<CommonAttachmentFormFieldProps>
> = ({
  AttachmentRead,
  AttachmentCreate,
  onUploaded,
  value,
  onChange,
  camelField,
  readOnly,
  ...createProps
}) => {
  const [fragment, setFragment] = useState<AttachmentReadFragment | undefined>()
  useEffect(() => {
    if (!value || isReadFragment(value)) {
      setFragment(value)

      // We need to immediately mark the value as updated to get the form to
      // not clear the value every time we submit without changing the file.
      if (value?.field && onChange) {
        onChange(value.field)
      }
    }
  }, [value, setFragment])
  if (fragment && fragment.field) {
    return (
      <AttachmentRead
        fragment={fragment}
        allowDelete={
          readOnly
            ? undefined
            : {
                onDelete: () => {
                  if (onChange) {
                    onChange(null)
                  }
                },
              }
        }
      />
    )
  } else if (!readOnly) {
    return (
      <AttachmentCreate
        {...createProps}
        camelField={camelField}
        onUploaded={f => {
          setFragment(f)
          if (onChange) {
            onChange(f.field || null)
          }
          if (onUploaded) {
            onUploaded(f)
          }
        }}
      />
    )
  } else {
    return null
  }
}
