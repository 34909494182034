import * as React from 'react'

function SvgC2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 256 286'} {...props}>
      <g fill={'none'} fillRule={'evenodd'}>
        <path
          fill={'#E82627'}
          fillRule={'nonzero'}
          d={
            'M235.468 184.52a55.17 55.17 0 01.71 12.35 66.76 66.76 0 01-2.28 15.57 45.2 45.2 0 01-12.65 20.83c-7.1 6.89-15.56 11.68-24.53 15.73a132.24 132.24 0 01-35.82 10.33 209.44 209.44 0 01-26.58 2.24h-6.12a205.67 205.67 0 01-30.48-1.84c-17.87-2.41-34.76-7.69-50.32-17a62.68 62.68 0 01-17-14.4 45.51 45.51 0 01-9.29-21.22 76.24 76.24 0 01-1-17 44 44 0 0110.58-26 73.1 73.1 0 0110.5-9.78 22.16 22.16 0 003.81-3.75 6.45 6.45 0 001.53-3.63 28.19 28.19 0 012.2-9.4c5-12.17 8.89-24.81 14.38-36.87a73.14 73.14 0 0113.97-20.89c.73-.76 1.44-1.5 2.21-2.2 2.4-2.28 5-4.32 7.42-6.73 3.55-3.45 8.09-5.46 12.75-7a80.16 80.16 0 0121.51-4 101.06 101.06 0 0125 1.61c5.63 1.15 11.23 2.69 16.2 5.58 9.69 5.61 18.2 12.51 24.38 22a102 102 0 019.72 19.22c3.87 10 7.55 20 11.45 30a25.54 25.54 0 011.88 8.08 8.08 8.08 0 002.69 5.45c2.47 2.42 5.27 4.45 7.87 6.71 7.92 7.03 13.27 15.6 15.31 26.01z'
          }
        />
        <path d={'M95.228 92.11c0-4.35 6-7.84 13.37-7.84'} />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          stroke={'#FFF'}
          strokeWidth={1.29}
          d={
            'M115.598 135.73c0 3.86-.61 7.94-3.71 9.81-1.61 1-5 .83-7 .83s-5.94-.08-7.6-1.14c-3-1.89-3.07-5.74-3.07-9.5a10.68 10.68 0 1121.35 0h.03z'
          }
        />
        <path
          fill={'#3F2516'}
          fillRule={'nonzero'}
          d={
            'M110.148 138.31c0 1.16-.05 3.11-.66 3.89-.61.78-2 .9-3 .9s-2.65 0-3.29-.79c-.64-.79-.2-2.81-.2-4 0-2.43 1.6-4.37 3.56-4.37s3.59 1.94 3.59 4.37z'
          }
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          stroke={'#FFF'}
          strokeWidth={1.29}
          d={
            'M147.268 135.73c0 3.86.6 7.94 3.71 9.81 1.6 1 5 .83 7 .83s5.94-.08 7.59-1.14c3-1.89 3.08-5.74 3.08-9.5a10.68 10.68 0 10-21.35 0h-.03z'
          }
        />
        <path
          fill={'#3F2516'}
          fillRule={'nonzero'}
          d={
            'M152.718 138.31c0 1.16 0 3.11.66 3.89.66.78 2 .9 3 .9s2.64 0 3.29-.79c.65-.79.19-2.81.19-4 0-2.43-1.59-4.37-3.56-4.37s-3.58 1.94-3.58 4.37zm-25.37 15.88a10.13 10.13 0 001.42-.92 3.17 3.17 0 011.89-.36 7.79 7.79 0 011.94.6 5 5 0 011.57.94c.46.41 1.15-.27.68-.68a5.82 5.82 0 00-1.78-1.12 9.68 9.68 0 00-2.16-.68 4.49 4.49 0 00-2.18.24c-.61.25-1 .8-1.63 1.05a.49.49 0 00-.34.59.48.48 0 00.59.34zm-30.48-40.53a18.56 18.56 0 012.87-2.66 4.67 4.67 0 012.78-.83c1.8-.21 4.42-.65 5.54 1.19a.49.49 0 00.84-.49c-1.35-2.21-4.16-1.93-6.38-1.67a5.83 5.83 0 00-3.19.9 17.82 17.82 0 00-3.3 3.07c-.37.49.47 1 .84.49zm68.8-1.48a9.71 9.71 0 00-1.57-1.57c-.58-.48-1.13-1-1.72-1.47a5.77 5.77 0 00-3.2-.92c-2.24-.27-5-.53-6.39 1.67-.33.53.51 1 .84.49 1.14-1.83 3.73-1.41 5.55-1.2a4.6 4.6 0 012.79.87 19.84 19.84 0 012.87 2.62c.37.49 1.21 0 .83-.49z'
          }
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          d={
            'M152.078 172.79h.11c0 12-10 21.73-22.39 21.86-12.38-.13-22.39-9.86-22.39-21.86h.13c0 4.31 9.8 7.8 22 7.91h.52c12.18-.09 22.02-3.58 22.02-7.91z'
          }
          opacity={0.3}
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          d={
            'M152.318 172.79h.14c0 12-10 21.73-22.38 21.86-12.45-.13-22.42-9.86-22.42-21.86h.11c0 4.31 9.83 7.8 22 7.91h.55c12.23-.09 21.97-3.58 22-7.91z'
          }
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          d={
            'M28.438 198.31c4.93 19 19.29 37.77 39.62 41.31a37 37 0 0015.39-.54c2.41-.6 1.39-4.33-1-3.72-17.87 4.49-34.8-7.22-43.65-22.07a61.54 61.54 0 01-6.6-16c-.63-2.4-4.36-1.38-3.73 1l-.03.02z'
          }
          opacity={0.8}
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          d={
            'M226.948 198.31c-4.59 17.68-17.63 35-36.46 38.5a32.4 32.4 0 01-13.79-.45c-2.41-.61-3.44 3.12-1 3.73 19.51 4.89 38.13-7.38 47.91-23.68a64.81 64.81 0 007.09-17.07c.62-2.41-3.1-3.44-3.73-1l-.02-.03z'
          }
          opacity={0.6}
        />
        <path fill={'#FFF'} fillRule={'nonzero'} d={'M118.108 64.5h22.09v8.46h-22.09z'} />
        <path
          fill={'#E82627'}
          fillRule={'nonzero'}
          d={
            'M130.348 22.71c-14.795 0-26.86 10.1-26.86 22.69 0 9.118 6.354 17.163 15.989 20.759a30.298 30.298 0 0010.877 1.962 30.976 30.976 0 008.44-1.138c10.886-3.03 18.454-11.678 18.454-21.583 0-12.585-12.099-22.69-26.9-22.69z'
          }
        />
        <path
          fill={'#FFF'}
          fillRule={'nonzero'}
          d={
            'M130.348 25.29c13.469 0 24.32 9.063 24.32 20.11 0 8.667-6.733 16.36-16.566 19.097a28.42 28.42 0 01-7.749 1.044 27.743 27.743 0 01-9.968-1.797c-8.685-3.242-14.317-10.372-14.317-18.344 0-11.054 10.819-20.11 24.28-20.11z'
          }
        />
        <path
          fill={'#502C1A'}
          fillRule={'nonzero'}
          d={
            'M130.767 32.822c-3.834 0-7.094 1.294-9.739 3.87-2.648 2.58-3.98 5.764-3.98 9.509s1.316 6.906 3.934 9.438c2.615 2.512 5.874 3.77 9.736 3.77 2.204 0 4.403-.534 6.59-1.596l.849-.426v-9.838l-1.36 1.644c-1.26 1.525-2.342 2.538-3.22 3.035-.885.5-1.916.753-3.102.753-1.852 0-3.344-.629-4.527-1.899-1.176-1.277-1.766-2.916-1.766-4.954 0-1.984.61-3.604 1.836-4.902 1.217-1.29 2.726-1.926 4.578-1.926 1.18 0 2.189.257 3.038.767.847.486 1.822 1.377 2.92 2.678l1.603 1.93v-9.863l-.404-.218a14.519 14.519 0 00-6.986-1.772z'
          }
        />
        <path
          stroke={'#FFF'}
          strokeWidth={2.58}
          d={'M208.188 185.64c-1.63-.52-3.3-2.78-3.86-4.36-.48-1.34-.39-4.2-.86-5'}
          opacity={0.7}
        />
      </g>
    </svg>
  )
}

const MemoSvgC2 = React.memo(SvgC2)
export default MemoSvgC2
