import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from '@thesisedu/react'
import { HSpaced, Space, VSpaced } from '@thesisedu/web'
import { Input, Button } from 'antd'
import React from 'react'

import { QuickGradeField } from './QuickGradeField'
import { useGradingModalSubmissionContext } from '../contexts/GradingModalSubmissionContext'
import { transformRubric } from '../rubric/helpers'

export interface SubmissionGradeQuestionGradeProps {
  gradeContent?: React.ReactElement
  rubricId: string
}
export function SubmissionGradeItemGrade({
  gradeContent,
  rubricId,
}: SubmissionGradeQuestionGradeProps) {
  return (
    <VSpaced align={'stretch'}>
      <HSpaced>
        {gradeContent ? gradeContent : <DefaultGradeContent rubricId={rubricId} />}
        <Space />
        <Form.Item name={['comments', rubricId].join('.')}>
          <CommentButton />
        </Form.Item>
      </HSpaced>
      <Form.Item name={['comments', rubricId].join('.')}>
        <CommentField />
      </Form.Item>
    </VSpaced>
  )
}

interface DefaultGradeContentProps {
  rubricId: string
}
export function DefaultGradeContent({ rubricId }: DefaultGradeContentProps) {
  const { rubric } = useGradingModalSubmissionContext(true)
  const rubricItem = rubric
    ? transformRubric(rubric).categories.find(cat => cat.id === rubricId)
    : undefined
  if (!rubricItem) return null
  return (
    <Form.Item name={['grades', rubricId].join('.')}>
      <QuickGradeField totalPoints={rubricItem.totalPoints} />
    </Form.Item>
  )
}

interface CommentFieldProps {
  value?: string
  onChange?: (value?: string) => void
}
function CommentField({ value, onChange }: CommentFieldProps) {
  const hasValue = value !== null && value !== undefined
  if (hasValue) {
    return (
      <Input.TextArea
        value={value}
        onChange={onChange ? e => onChange(e.target.value) : undefined}
        autoSize={{ minRows: 2, maxRows: 4 }}
        placeholder={'Add your comments here.'}
        data-testid={'CommentField'}
      />
    )
  } else {
    return <span />
  }
}

interface CommentButtonProps {
  value?: string
  onChange?: (value?: string) => void
}
function CommentButton({ value, onChange }: CommentButtonProps) {
  if (onChange) {
    const hasValue = value !== null && value !== undefined
    return (
      <Button
        onClick={() => onChange(hasValue ? undefined : '')}
        icon={hasValue ? <MinusOutlined /> : <PlusOutlined />}
        data-testid={'CommentButton'}
      >
        {hasValue ? 'Delete Comments' : 'Add Comments'}
      </Button>
    )
  } else {
    return null
  }
}
