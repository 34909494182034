import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Form } from '@thesisedu/react'
import {
  AntIconWrapper,
  BlockSpin,
  NotFound,
  PageHead,
  PageHeader,
  useBreadcrumb,
} from '@thesisedu/web'
import { Plus } from 'iconoir-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { DeleteTagButton } from './DeleteTagButton'
import { TagForm } from './TagForm'
import { TagFragment, UpdateTagInput, useTagQuery, useUpdateTagMutation } from '../schema'

export interface EditTagProps {
  onSaved?: (tag: TagFragment) => void
  tagId: string
}
export function EditTag({ onSaved, tagId }: EditTagProps) {
  const navigate = useNavigate()
  const [save, { loading }] = useUpdateTagMutation({
    onError: onMutationError('There was an error updating your tag.'),
    onCompleted: data => {
      navigate('..')
      if (onSaved) {
        onSaved(data.updateTag.tag)
      }
    },
  })
  const { data, loading: tagLoading } = useTagQuery({
    variables: {
      id: tagId,
    },
  })
  const tag = data?.node?.__typename === 'Tag' ? data.node : undefined
  useBreadcrumb(
    {
      title: tag?.name || 'Edit Tag',
    },
    tag?.name || 'edit-tag',
  )
  const form = Form.useForm<UpdateTagInput>()
  React.useEffect(() => {
    if (tag) {
      form.setValue('name', tag.name)
      form.setValue('type', tag.type)
      form.setValue('config', tag.config)
    }
  }, [tag])

  if (tagLoading) {
    return <BlockSpin />
  } else if (!tag) {
    return <NotFound description={'That tag could not be found.'} />
  }

  return (
    <Form
      form={form}
      onFinish={async values => {
        await save({
          variables: {
            input: {
              ...values,
              id: tagId,
            },
          },
        })
      }}
    >
      <PageHead title={'Editing Tag'} />
      <PageHeader title={'Editing Tag'}>
        <DeleteTagButton tagId={tagId} onDeleted={() => navigate('..')} type={'default'} />
        <Form.Submit
          type={'primary'}
          icon={<AntIconWrapper children={<Plus />} />}
          children={'Save Changes'}
          disableUntilDirty
          loading={loading}
        />
      </PageHeader>
      <TagForm />
    </Form>
  )
}
