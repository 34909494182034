import React from 'react'

import { useProgressBarContext } from './context'
import { Text, Text$ } from '../..'

export interface ValueLabelProps extends Text$.TextProps {}
export function ValueLabel(props: ValueLabelProps) {
  const { progressBarProps } = useProgressBarContext(true)
  return <Text {...props} children={progressBarProps['aria-valuetext']} />
}
