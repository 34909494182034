import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Row } from '@thesisedu/web'
import { AutoComplete, Col, Form, Input, Tabs } from 'antd'
import React from 'react'

import { DaysInput } from '../../DaysInput'
import { IconSelect } from '../../IconSelect'
import { Editor } from '../../editor/Editor'
import { SegmentType } from '../../schema'
import { EditableSegment } from '../../segment/EditableSegment'
import { OverrideField } from '../../segment/OverrideField'
import { SegmentProps } from '../../types'
import { useSectionLabels, useLabelPlaceholder } from '../../useOptions'

export function SectionEdit(props: SegmentProps) {
  const SECTION_LABELS = useSectionLabels()
  const LABEL_PLACEHOLDER = useLabelPlaceholder()
  return (
    <EditableSegment
      {...props}
      addTypes={[SegmentType.Section, SegmentType.Group, SegmentType.Reference, 'Assignment']}
    >
      {({ childrenFields, submitFields, segment }) => {
        return (
          <Row>
            <Col xs={24} md={8} lg={6}>
              <OverrideField
                label={'Label'}
                name={['config', 'label']}
                initialValue={segment.config.label}
                overrideFieldName={['config', 'studentLabel']}
                overrideInitialValue={segment.config.studentLabel}
              >
                <AutoComplete
                  dataSource={SECTION_LABELS}
                  filterOption={(inputValue, option) =>
                    option?.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  placeholder={LABEL_PLACEHOLDER}
                />
              </OverrideField>
              <Form.Item
                label={'Index'}
                name={'index'}
                initialValue={segment.index}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value?.toString().includes(' ')) {
                        callback('Index cannot contain spaces.')
                      } else callback()
                    },
                  },
                ]}
              >
                <Input style={{ width: 100 }} placeholder={'1-1'} />
              </Form.Item>
              <OverrideField
                label={'Name'}
                name={'name'}
                initialValue={segment.name}
                overrideFieldName={['config', 'studentName']}
                overrideInitialValue={segment.config.studentName}
                rules={[{ required: true, message: 'A section name is required.' }]}
              >
                <Input />
              </OverrideField>
              <Form.Item
                label={'Minutes'}
                name={['config', 'days']}
                extra={'How many minutes should be reserved for this content?'}
                initialValue={segment.config.days}
                rules={[
                  { required: true, message: 'You must specify a number of minutes. It can be 0.' },
                ]}
              >
                <DaysInput />
              </Form.Item>
              <Form.Item
                label={'Icon'}
                name={['config', 'icon']}
                extra={'This is the icon displayed to teachers and students for this section.'}
                initialValue={segment.config.icon}
              >
                <IconSelect />
              </Form.Item>
              {submitFields}
            </Col>
            <Col xs={24} md={16} lg={18}>
              <Tabs defaultActiveKey={'student'}>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <UserOutlined /> Student View
                    </span>
                  }
                  key={'student'}
                >
                  <Form.Item
                    name={['config', 'studentContent']}
                    initialValue={segment.config.studentContent || []}
                  >
                    <Editor id={`${segment.id}-student`} />
                  </Form.Item>
                </Tabs.TabPane>
                <Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                      <InfoCircleOutlined /> Teacher View
                    </span>
                  }
                  key={'teacher'}
                >
                  <Form.Item
                    name={['config', 'teacherContent']}
                    initialValue={segment.config.teacherContent || []}
                  >
                    <Editor id={`${segment.id}-teacher`} />
                  </Form.Item>
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        )
      }}
    </EditableSegment>
  )
}
