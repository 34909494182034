import { SESSION_AUTH_KEY } from '../constants'
import { ApolloReactOptions } from '../types'

export function openSessionAuth(jwt: string) {
  window.open(`${window.location.origin}?${SESSION_AUTH_KEY}=${jwt}`)
}

export function getAuthenticationKey(
  options: ApolloReactOptions,
  noSession?: boolean,
): Promise<string | null> {
  const params = new URLSearchParams(window.location.search)

  const sessionKey = params.get(SESSION_AUTH_KEY) || sessionStorage.getItem(SESSION_AUTH_KEY)
  if (sessionKey && !noSession) {
    sessionStorage.setItem(SESSION_AUTH_KEY, sessionKey)
    return Promise.resolve(sessionKey)
  }

  const key = params.has('auth')
    ? params.get('auth')
    : localStorage.getItem(options.authenticationKey)
  if (key && !localStorage.getItem(options.authenticationKey)) {
    localStorage.setItem(options.authenticationKey, key)
  }

  return Promise.resolve(key)
}

export function removeAuthenticationKey(options: ApolloReactOptions) {
  localStorage.removeItem(options.authenticationKey)
}

export const platform = 'web'
