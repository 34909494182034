import { NodeIndexOutlined } from '@ant-design/icons'
import {
  isMatchClientConfig,
  MatchClientConfig,
  MatchConfig,
} from '@thesisedu/feature-assignments-core'

import { MatchAnswer } from './MatchAnswer'
import { isQuestionComplete, MatchContent } from './MatchContent'
import { MatchEdit } from './MatchEdit'
import { QuestionTypeResource } from '../../../types'

export default {
  type: 'QuestionType',
  identifier: 'Match',
  defaults: {
    name: 'Match Question',
    config: {
      options: [],
    },
  },
  icon: NodeIndexOutlined,
  label: 'Match',
  group: 'Drag and Drop',
  renderAnswerView: MatchAnswer,
  renderContent: MatchContent,
  renderEdit: MatchEdit,
  isQuestionComplete,
  usesRealTimeConfirmation: true,
  isAutoGraded: question => {
    const config = question.config
    if (config && isMatchClientConfig(config)) {
      return !!config.destinations.length && !!config.sources.length
    } else if (config && !isMatchClientConfig(config)) {
      return !!config.options?.length
    } else return false
  },
} as QuestionTypeResource<MatchConfig | MatchClientConfig>
