import { css } from 'styled-components'

import { color } from '..'
import { Variants as TVariants, VariantString } from '../types'

export const Variants = {
  danger: css`
    --status-color: ${color('red')};
    --status-subtle-color: ${color('red.element')};
  `,
  error: css`
    --status-color: ${color('red')};
    --status-subtle-color: ${color('red.element')};
  `,
  success: css`
    --status-color: ${color('green')};
    --status-subtle-color: ${color('green.element')};
  `,
  warning: css`
    --status-color: ${color('orange')};
    --status-subtle-color: ${color('orange.element')};
  `,
  info: css`
    --status-color: ${color('blue')};
    --status-subtle-color: ${color('blue.element')};
  `,
  default: css``,
} satisfies TVariants

export type Type = VariantString<typeof Variants>
export const defaultValue: Type = 'default'
