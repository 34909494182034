import { Button } from '@thesisedu/react'
import { AntIconWrapper, Modal } from '@thesisedu/web'
import { message } from 'antd'
import { AddCircle } from 'iconoir-react'
import React from 'react'

import { TrialRequestForm } from './TrialRequestForm'

export function CreateTrialRequestButton() {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <Button
        children={'Create Request'}
        icon={<AntIconWrapper children={<AddCircle />} />}
        onClick={() => setVisible(true)}
        type={'primary'}
      />
      <Modal
        visible={visible}
        title={'Create a Trial Request'}
        destroyOnClose
        onCancel={() => setVisible(false)}
      >
        <TrialRequestForm
          showCampaignField
          refetchRequests
          onComplete={() => {
            setVisible(false)
            message.success('Trial successfully created!')
          }}
        />
      </Modal>
    </>
  )
}
