import { getSegmentName } from '@thesisedu/feature-course-types'
import { BodySmall, H1, useShrinkingStickyHeader } from '@thesisedu/react'
import { HSpaced, Space, styled } from '@thesisedu/web'
import React from 'react'

import { CourseViewerSegmentHeaderActions } from './CourseViewerSegmentHeaderActions'
import { CourseViewerSegmentHeaderPortal } from './CourseViewerSegmentHeaderPortal'
import { SegmentParents } from './SegmentParents'
import { PaddedContainer } from './styled'
import { useCourseViewerModalHeaderContext } from '../contexts/CourseViewerModalHeaderContext'
import { Segment } from '../types'

export interface CourseViewerSegmentProgressHeaderProps {
  segment: Segment
}
export function CourseViewerSegmentProgressHeader({
  segment,
}: CourseViewerSegmentProgressHeaderProps) {
  const { isShrunk, ref, style } = useShrinkingStickyHeader({ height: 145 })
  const { customHeader } = useCourseViewerModalHeaderContext(false) ?? {}
  return (
    <Container style={style} ref={ref}>
      <ContainerInner shrunk={isShrunk}>
        <ParentContainer>
          <SegmentParents segment={segment} includeName={!!customHeader} />
        </ParentContainer>
        <HSpaced align={'flex-end'}>
          <Title
            isBlock={false}
            data-testid={customHeader ? 'CourseViewerCustomHeader' : 'CourseViewerSegmentName'}
          >
            {customHeader ?? getSegmentName(segment)}
          </Title>
          <Space />
          <ActionsContainer>
            <CourseViewerSegmentHeaderActions segment={segment} />
            <CourseViewerSegmentHeaderPortal />
          </ActionsContainer>
        </HSpaced>
      </ContainerInner>
    </Container>
  )
}

const ParentContainer = styled(BodySmall)`
  overflow: hidden;
  transition:
    height 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    opacity 0.25s linear;
  height: ${props => props.theme['@size-m']};
  margin-right: 70px;
  white-space: nowrap;
  > p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const ActionsContainer = styled.div`
  padding-bottom: ${props => props.theme['@size-xxs']};
  transition: padding 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const Title = styled(H1)`
  transition: font-size 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`
const ContainerInner = styled(PaddedContainer)<{ shrunk?: boolean }>`
  background: ${props => props.theme['@gray-0']};
  width: 100%;
  transition: padding 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  pointer-events: all;
  ${props => (props.shrunk ? '&' : '&.noop')} {
    padding-top: ${props => props.theme['@size-m']};
    padding-bottom: ${props => props.theme['@size-xs']};
    ${Title} {
      font-size: ${props => props.theme['@size-m']};
    }
    ${ParentContainer} {
      height: 0;
      opacity: 0;
    }
    ${ActionsContainer} {
      padding-right: ${props => props.theme['@size-xl']};
    }
  }
`
const Container = styled.div`
  z-index: 11; // TODO: Might need to change this back to 10...
  pointer-events: none;
`
