import * as Slider from '@radix-ui/react-slider'

import { styled, s } from '../..'

export const Root = styled(Slider.Root)`
  --track-color: ${s.color('element')};
  --range-color: ${s.color('border')};
  --thumb-color: ${s.color('primary.solid')};
  --thumb-hover: ${s.color('primary.hoverSolid')};
  --thumb-focus: ${s.color('primary.hoverSolid')};
  --thumb-shadow: ${s.opacity(s.color('primary.hoverSolid'), 0.2)};
  --thumb-size: 20px;
  --track-height: 3px;

  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;

  &[data-disabled] {
    opacity: 0.75;
  }

  &[data-orientation='horizontal'] {
    width: 200px;
    height: var(--thumb-size);
    flex-direction: row;
  }
  &[data-orientation='vertical'] {
    width: var(--thumb-size);
    height: 200px;
    flex-direction: column;
  }
`

export const Track = styled(Slider.Track)`
  background: var(--track-color);
  position: relative;
  border-radius: 9999px;
  flex-grow: 1;
  &[data-orientation='horizontal'] {
    height: var(--track-height);
  }
  &[data-orientation='vertical'] {
    width: var(--track-height);
  }
`

export const Range = styled(Slider.Range)`
  position: absolute;
  background-color: var(--range-color);
  border-radius: 9999px;
  &[data-orientation='horizontal'] {
    height: 100%;
  }
  &[data-orientation='vertical'] {
    width: 100%;
  }
`

export const Thumb = styled(Slider.Thumb)`
  display: block;
  width: var(--thumb-size);
  height: var(--thumb-size);
  background: var(--thumb-color);
  box-shadow: ${s.var('shadow.0')};
  border-radius: 50%;
  transition:
    background 0.1s linear,
    box-shadow 0.1s linear;
  box-shadow: 0 0 0 0px var(--thumb-shadow);
  &:hover {
    background: var(--thumb-hover);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px var(--thumb-shadow);
    background: var(--thumb-focus);
  }
`
