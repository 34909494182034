import * as Legacy from '.'
import { isEmpty } from '../empty'

export function anyEditorValueIsEmpty(value: Legacy.AnyEditorValue | undefined | null): boolean {
  if (Array.isArray(value)) {
    return !value.length
  } else if (value) {
    return isEmpty(value)
  } else {
    return true
  }
}
