import { getPreviewDomain, PREVIEW_BRANCH_PREFIX } from '@thesisedu/feature/dist/preview'
import { FeatureReactRoot, LocationIDReporter, RootConfiguration } from '@thesisedu/feature-react'
import { s } from '@thesisedu/ui'
import { WebSettingsProvider } from '@thesisedu/web'
import fs from 'fs'
import path from 'path'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import yargs from 'yargs'

import { HashSync } from './HashSync'
import { BUILD_APP_HOOK, DEFAULT_OPTIONS, POST_BUILD_HOOK, WRAP_APP_HOOK } from './constants'
import { getRelease } from './getRelease'
import { isValidBrowserVersion } from './isValidBrowserVersion'
import { debug } from './log'
import { addEmbeddedRenderers } from './resources/EmbeddedRenderers'
import { RequiredFeatureWebApplicationOptions } from './types'
import { buildWebpack } from './webpack/webpack'

const run = async (callback: () => Promise<any>): Promise<void> => {
  try {
    await callback()
  } catch (err: any) {
    console.error('error running script')
    console.error(err)
    process.exit(1)
  }
  process.exit(0)
}

export class FeatureWeb extends FeatureReactRoot<RequiredFeatureWebApplicationOptions> {
  /** This will be false if the user is using an outdated browser. */
  public readonly isValidBrowserVersion: boolean

  constructor(appOptions: RequiredFeatureWebApplicationOptions) {
    super({
      ...DEFAULT_OPTIONS,
      ...appOptions,
    })
    debug('constructing')

    // Set the UI theme.
    debug('setting UI theme')
    if (this.appOptions.uiTheme) {
      s.setTheme(this.appOptions.uiTheme)
    }

    if (typeof window !== 'undefined' && window) {
      window.addEventListener('error', evt => {
        if (evt.error) {
          this.deps.services.error.reportError(evt.error)
        }
      })

      // We're probably inside react-land...
      addEmbeddedRenderers(this)
    }

    this.isValidBrowserVersion = isValidBrowserVersion()
    if (!this.isValidBrowserVersion) {
      debug('browser version is invalid')
    }
  }

  public rootConfiguration(): RootConfiguration {
    return {
      wrapAppHook: WRAP_APP_HOOK,
    }
  }

  public getDevToolsInstaller() {
    return import('./devtools/install').then(file => file.install)
  }

  public shouldUseDevTools() {
    const DEV_TOOLS_KEY = 'ftdevthesisedu'

    const explicitlyDisabled =
      window.location.search.includes('dev-tools=false') ||
      window.localStorage.getItem('dev-tools') === 'false'
    const explicitlyEnabled =
      window.location.search.includes(`dev-tools=${DEV_TOOLS_KEY}`) ||
      window.localStorage.getItem('dev-tools') === DEV_TOOLS_KEY
    return (
      (!explicitlyDisabled && process.env.REACT_APP_ENVIRONMENT !== 'production') ||
      explicitlyEnabled
    )
  }

  public async wrapAppWithDefaults(children: React.ReactElement): Promise<React.ReactElement> {
    const { GlobalStyles, AudioPlaybackProvider, PageHeadProvider } = require('@thesisedu/web')
    const { ThemeContext, ThemeProvider } = require('@thesisedu/web/dist/ThemeContext')
    const { WebDndProvider } = require('@thesisedu/web/dist/react-dnd')
    const { ResponsiveContextProvider } = require('@thesisedu/web/dist/responsive')
    const { ErrorProvider } = require('./ErrorProvider')
    return super.wrapAppWithDefaults(
      <ThemeContext.Provider value={this.appOptions.themes}>
        <ThemeProvider>
          <WebSettingsProvider settings={this.appOptions.webSettings}>
            <PageHeadProvider titleSuffix={this.appOptions.name}>
              <ResponsiveContextProvider>
                <ErrorProvider
                  errorService={this.deps.services.error}
                  debugOutput={this.appOptions.debugErrorOutput}
                >
                  <AudioPlaybackProvider>
                    <WebDndProvider>
                      <>
                        <GlobalStyles />
                        <BrowserRouter>
                          <HashSync />
                          <LocationIDReporter />
                          {React.cloneElement(children)}
                        </BrowserRouter>
                      </>
                    </WebDndProvider>
                  </AudioPlaybackProvider>
                </ErrorProvider>
              </ResponsiveContextProvider>
            </PageHeadProvider>
          </WebSettingsProvider>
        </ThemeProvider>
      </ThemeContext.Provider>,
    )
  }

  start() {
    const { startWebpack } = require('./webpack/webpack')
    startWebpack(this, {
      directory: process.cwd(),
    })
  }

  async build() {
    const environment = process.env.NODE_ENV || 'staging'
    const backendDomain = environment.startsWith(PREVIEW_BRANCH_PREFIX)
      ? `https://${getPreviewDomain(environment)}-${this.appOptions.apiDomain}/graphql`
      : `https://${environment === 'production' ? '' : 'qa-'}${this.appOptions.apiDomain}/graphql`
    const publicDomain = environment.startsWith(PREVIEW_BRANCH_PREFIX)
      ? `https://${getPreviewDomain(environment)}.${this.appOptions.domain}`
      : `https://${environment === 'production' ? '' : 'qa.'}${this.appOptions.domain}`
    const release = getRelease()
    const env = await this.deps.hookManager.mutateHook<object>(
      BUILD_APP_HOOK,
      {
        SKIP_PREFLIGHT_CHECK: 'true',
        DISABLE_NEW_JSX_TRANSFORM: 'true',
        REACT_APP_RELEASE: release,
        ...(process.env.NODE_ENV === 'test'
          ? {}
          : {
              REACT_APP_CONFIG_NODE_ENV: environment,
              REACT_APP_ENVIRONMENT: environment,
              REACT_APP_BACKEND: backendDomain,
              REACT_APP_PUBLIC: publicDomain,
            }),
      },
      undefined,
    )
    try {
      Object.assign(process.env, env)
      await buildWebpack(this, { directory: process.cwd() })
    } catch (err: any) {
      console.error(err)
      process.exit(1)
    }
    if (!fs.existsSync(path.join(process.cwd(), 'build', 'index.html'))) {
      console.error(
        'build/index.html does not exist, which means the build failed. See logs for detail.',
      )
      process.exit(1)
    }
    await this.deps.hookManager.mutateHook<object>(POST_BUILD_HOOK, env, undefined)
    process.exit(0)
  }

  test() {
    process.exit(0)
  }

  public async bootstrapScripts(argv: yargs.Argv<any>): Promise<yargs.Argv<any>> {
    return super.bootstrapScripts(argv).then(argv => {
      return argv
        .command('start', 'Starts the frontend', {}, () => {
          this.start()
        })
        .command('build', 'Builds the frontend', {}, () => {
          this.build()
        })
        .command('test', 'Tests the frontend', {}, () => {
          this.test()
        })
        .command(
          'deploy <stage>',
          'Deploys the specified environment.',
          a => {
            return a.positional('stage', {
              type: 'string',
            })
          },
          a => {
            const { deploy } = require('./deploy')
            run(() => deploy.call(this, a.stage))
          },
        )
        .command(
          'maintenance <stage> <enabled>',
          'Toggles maintenance mode for a specific environment',
          a => {
            return a
              .positional('stage', {
                type: 'string',
              })
              .positional('enabled', {
                type: 'string',
                choices: ['true', 'false'],
              })
          },
          a => {
            // eslint-disable-next-line no-eval
            const { setMaintenance } = eval('require')('./maintenance')
            run(() => setMaintenance.call(this, a.stage, a.enabled === 'true'))
          },
        )
    })
  }
}
