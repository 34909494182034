import { Shell } from '@thesisedu/feature-web'
import { List } from '@thesisedu/react/icons'
import React from 'react'
import { Route } from 'react-router-dom'

import { TodoRoute } from './TodoRoute'
import ClassesReactFeature from '../ClassesReactFeature'
import { ClassLeftNavHook, ClassRoutesHook } from '../types'

export default function (feature: ClassesReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls, group }) => {
      if (group === 'STUDENT') {
        return [...routes, <Route key={'todo'} path={'todo/*'} element={<TodoRoute />} />]
      } else return routes
    },
  )
  feature.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { cls, group }) => {
      if (group === 'STUDENT') {
        return [
          ...items,
          {
            group: 'top',
            weight: -1000,
            element: <Shell.LeftMenuItem name={'To-Do'} icon={<List />} path={'todo'} />,
          },
        ]
      } else return items
    },
  )
}
