import { BrochureLink, useFeatureRoot } from '@thesisedu/feature-react'
import { styled, s, Image, VSpaced, Text, Block, Button } from '@thesisedu/ui'
import React from 'react'

import { SiteClassSyncProviderFragment } from '../../schema'
import { LoadingStep } from '../Loading/LoadingStep'
import { SetupStep, getStepFactory } from '../types'

export interface LearnGradeSyncContext {
  provider: SiteClassSyncProviderFragment
}
export default {
  size: {
    width: 650,
    height: 850,
    noMaxHeight: true,
  },
  Component({ context, transition }) {
    const videoPath = require('../../../assets/tutorial-sync-content.mp4').default
    const appName = useFeatureRoot().appOptions.name
    return (
      <LoadingStep
        style={{ width: 525 }}
        providerIdentifier={context.provider.identifier}
        providerName={context.provider.name}
        state={'success'}
        title={`Syncing Grades and Content with ${context.provider.name}`}
        message={
          <>
            To sync grades and content with your class in {context.provider.name}, click on the menu
            to the right of content in the outline view, then click <strong>Sync With...,</strong>{' '}
            and then click on your class.
          </>
        }
        action={
          <VSpaced space={'xs'} align={'center'} style={{ textAlign: 'center' }}>
            <StyledVideo src={videoPath} />
            <Text level={'s'} color={'secondary'}>
              <strong>Need more help?</strong> Feel free to{' '}
              <BrochureLink to={'contact'}>reach out to {appName} support</BrochureLink> at any
              time.
            </Text>
            <Block top={'m'}>
              <Button
                variant={'primary'}
                onPress={() => {
                  transition(null)
                }}
              >
                Close
              </Button>
            </Block>
          </VSpaced>
        }
      />
    )
  },
} satisfies SetupStep<LearnGradeSyncContext>
export const getLearnGradeSync = getStepFactory<LearnGradeSyncContext>('learnGradeSync')

const StyledVideo = styled(Image.Video)`
  border: solid 3px ${s.color('element')};
  border-radius: ${s.var('radii.1')};
  overflow: hidden;
  width: 100%;
  > div {
    width: 100%;
  }
`
