import { useSyncAssignmentActionsProps } from '@thesisedu/feature-assignments-react/dist/grading/AssignmentSyncs'
import { SyncAssignmentActions } from '@thesisedu/feature-class-sync-react'
import {
  SegmentActionComponentProps,
  SegmentActionResource,
} from '@thesisedu/feature-courses-react'
import { useSegmentDecoration } from '@thesisedu/feature-courses-react/dist/contexts/SegmentDecorationsContext'
import { SegmentDecorationWithAssignmentSyncFragment } from '@thesisedu/feature-courses-react/dist/schema'
import { Dropdown, LoadingIndicator } from '@thesisedu/ui'
import { CloudSync, WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { SegmentType } from '../../schema'

interface Decoration extends SegmentDecorationWithAssignmentSyncFragment {
  assignmentId?: string
}

function AssignmentSync({ segment }: SegmentActionComponentProps) {
  const decoration = useSegmentDecoration(segment.id) as Decoration | undefined
  const { loading, props: syncProps } = useSyncAssignmentActionsProps(decoration?.assignmentId)
  if (decoration?.assignmentId) {
    if (loading) {
      return (
        <Dropdown.Item icon={<LoadingIndicator />} disabled>
          Loading syncs...
        </Dropdown.Item>
      )
    } else if (syncProps) {
      return <SyncAssignmentActions {...syncProps} />
    } else {
      return null
    }
  } else {
    return (
      <Dropdown.SubMenu>
        <Dropdown.SubMenu.Trigger icon={<CloudSync />} children={'Sync with...'} />
        <Dropdown.SubMenu.Content>
          <Dropdown.Item disabled icon={<WarningCircle />} style={{ lineHeight: 1.4 }}>
            You have to enable this assignment
            <br />
            before you can sync it.
          </Dropdown.Item>
        </Dropdown.SubMenu.Content>
      </Dropdown.SubMenu>
    )
  }
}

export const AssignmentSyncAction: SegmentActionResource = {
  identifier: 'assignment-sync',
  type: 'SegmentAction',
  group: '',
  weight: 10,
  component: AssignmentSync,
  handler: () => {},
  filter: segment => segment.type === SegmentType.Assignment,
}
