import { Button } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import { usePrompt } from '@thesisedu/web'
import React from 'react'

import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { getFinalChanges } from './getFinalChanges'
import { PlacementPaneImperativeHandle } from './types'

export interface SplitViewSaveChangesButtonProps {
  leftRef: React.RefObject<PlacementPaneImperativeHandle>
  rightRef: React.RefObject<PlacementPaneImperativeHandle>
}
export function SplitViewSaveChangesButton({ leftRef, rightRef }: SplitViewSaveChangesButtonProps) {
  const { changes, addedSegmentIds, changedSegmentIds } = useSplitViewPendingChangesContext(true)
  const hasChanges = !!addedSegmentIds.length || !!changedSegmentIds.length
  const [loading, setLoading] = React.useState(false)
  usePrompt(hasChanges && !loading)
  return (
    <Button
      variant={'primary'}
      children={'Save Changes'}
      icon={<Check />}
      disabled={!hasChanges}
      loading={loading}
      onPress={async () => {
        const candidate = [rightRef.current?.onSave, leftRef.current?.onSave].find(Boolean)
        setLoading(true)
        try {
          const finalChanges = getFinalChanges(changes)
          await candidate?.(finalChanges)
        } finally {
          setLoading(false)
        }
      }}
    />
  )
}
