import { DateValue, getLocalTimeZone } from '@internationalized/date'
import { AriaDateFieldOptions, useDateField } from '@react-aria/datepicker'
import { useLocale } from '@react-aria/i18n'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { DateFieldStateOptions, useDateFieldState } from 'react-stately'

import { ClearButton } from './ClearButton'
import { DateSegment } from './DateSegment'
import { createCalendar } from './createCalendar'
import { parse } from './parse'
import { styled, s, HSpaced } from '../..'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'
import { FocusStyle } from '../FocusRing/FocusRing'
import { HSpacedProps } from '../HSpaced/HSpaced'
import { InputStates, _InputContainerStyle } from '../TextField/TextField'

export interface InnerDateFieldProps
  extends ExtendedBareFieldProps,
    Omit<AriaDateFieldOptions<DateValue>, 'isDisabled'>,
    Omit<DateFieldStateOptions<DateValue>, 'createCalendar' | 'locale'> {
  className?: string
  style?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  disabled?: boolean
  variant?: DateFieldVariant
  prefix?: React.ReactElement
  suffix?: React.ReactElement
  __groupProps?: HSpacedProps
}
function _InnerDateField(
  {
    className,
    style,
    disabled,
    size = s.SharedVariants.Size.defaultValue,
    variant,
    prefix,
    suffix,
    __groupProps,
    ...props
  }: InnerDateFieldProps,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { bareFieldProps, rest } = getBareFieldProps(props)
  const ref = useObjectRef(_ref)
  const { locale } = useLocale()
  const state = useDateFieldState({
    ...rest,
    hideTimeZone: rest.hideTimeZone !== false,
    isDisabled: disabled,
    locale,
    createCalendar,
  })
  const { fieldProps, ...aria } = useDateField(
    {
      ...bareFieldProps,
      ...rest,
      isDisabled: disabled,
    },
    state,
    ref,
  )

  return (
    <BareField {...bareFieldProps} style={style} __aria={aria}>
      <_DateFieldContainer
        {...__groupProps}
        className={s.variants<typeof _variants>(
          className,
          {
            disabled,
            error: !!props.error,
            allArePlaceholders: state.segments.every(
              segment => segment.isPlaceholder || !segment.isEditable,
            ),
          },
          size,
          variant,
        )}
        style={style}
        space={'xs'}
        ref={ref}
      >
        {prefix}
        <_DateFieldValueContainer {...fieldProps}>
          {state.segments.map((segment, i) => (
            <DateSegment key={i} segment={segment} state={state} />
          ))}
          {state.validationState === 'invalid' ? (
            <span aria-hidden={'true'}>Invalid Date</span>
          ) : null}
        </_DateFieldValueContainer>
        {suffix}
      </_DateFieldContainer>
    </BareField>
  )
}
export const InnerDateField = React.forwardRef(_InnerDateField)

export interface DateFieldProps extends Omit<InnerDateFieldProps, 'value' | 'onChange'> {
  value?: string | null
  onChange?: (value: string | null) => void
}
function _DateField(
  { value, onChange, ...props }: DateFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <InnerDateField
      {...props}
      ref={ref}
      value={value ? parse(value) : null}
      onChange={value => {
        const timezone = getLocalTimeZone()
        onChange?.(value?.toDate(timezone).toUTCString() ?? null)
      }}
      suffix={
        <>
          <ClearButton {...props} value={value} onChange={onChange} />
          {props.suffix}
        </>
      }
    />
  )
}
export const DateField = React.forwardRef(_DateField)

const { css } = s
const DateFieldVariants = {
  code: css`
    font-family: ${s.var('font.code')};
  `,
  allArePlaceholders: css`
    --placeholder-color: transparent;
    &:focus-within {
      --placeholder-color: ${s.color('secondary')};
    }
  `,
} satisfies s.Variants
export type DateFieldVariant = keyof typeof DateFieldVariants

export const _variants = s.merge(InputStates, s.SharedVariants.Size.Variants, DateFieldVariants)
export const _DateFieldContainer = s.styledWithVariants(
  HSpaced,
  '_DateField',
  css`
    ${_InputContainerStyle}
    --placeholder-color: ${s.color('secondary')};
    &:focus-within {
      ${FocusStyle}
    }
  `,
  _variants,
)
export const _DateFieldValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
`
