import { DirectMessageThreadFragment } from './schema'

export function getRecipients(
  thread: Pick<DirectMessageThreadFragment, 'recipients' | 'recipientsLabel'>,
): string {
  if (!thread.recipients[0]) return ''
  const defaultMessage =
    thread.recipients.length === 1
      ? thread.recipients[0].name || thread.recipients[0].username
      : `${thread.recipients
          .slice(0, -1)
          .map(u => u.name || u.username)
          .join(', ')} & ${
          thread.recipients[thread.recipients.length - 1].name ||
          thread.recipients[thread.recipients.length - 1].username
        }`
  return thread.recipientsLabel || defaultMessage
}
