import { VSpaced, Form, Text } from '@thesisedu/ui'
import React from 'react'

import { ConfigureCellColorsProps } from './ConfigureCellColors'

export function ConfigureStatic({ value }: ConfigureCellColorsProps) {
  return (
    <>
      <VSpaced space={'xs'}>
        <Form.NumberField
          name={'above'}
          label={'Above'}
          required
          description={
            <>
              Values{' '}
              <strong>
                <Text level={null} inline color={'blue'}>
                  above
                </Text>
              </strong>{' '}
              {value.above} will be highlighted in{' '}
              <Text level={null} inline color={'blue'}>
                blue.
              </Text>
            </>
          }
          formatOptions={{ maximumFractionDigits: 2 }}
        />
        <Form.NumberField
          name={'below'}
          label={'Below'}
          required
          description={
            <>
              Values{' '}
              <strong>
                <Text level={null} inline color={'red'}>
                  below
                </Text>
              </strong>{' '}
              {value.above} will be highlighted in{' '}
              <Text level={null} inline color={'red'}>
                red.
              </Text>
            </>
          }
          formatOptions={{ maximumFractionDigits: 2 }}
        />
        <Text level={'s'} color={'secondary'}>
          <strong>For percentages like grades,</strong> you will need to enter a value between 0 and
          1, not 0 and 100.
        </Text>
      </VSpaced>
    </>
  )
}
