import { Button, Result } from 'antd'
import React from 'react'

export interface NotAllowedProps {
  description?: string
  hideBack?: boolean
}
export const NotAllowed: React.FC<React.PropsWithChildren<NotAllowedProps>> = ({
  description = 'The page you are looking for does not exist.',
  hideBack,
}) => (
  <Result
    status={'403'}
    title={'Not Allowed'}
    subTitle={description}
    extra={
      hideBack ? undefined : (
        <Button type={'primary'} onClick={() => window.history.back()}>
          Go Back
        </Button>
      )
    }
  />
)
