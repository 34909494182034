import { Button, Form, Modal, VSpaced } from '@thesisedu/ui'
import { Check, Edit } from '@thesisedu/ui/icons'
import React from 'react'

import { useUpdateAssignmentCategoryMutation } from '../queries/useUpdateAssignmentCategoryMutation'
import { BasicAssignmentCategoryFragment } from '../schema'

export interface EditCategoryButtonProps {
  category: BasicAssignmentCategoryFragment
}
export function EditCategoryButton({ category }: EditCategoryButtonProps) {
  const form = Form.useForm<{ name: string; gradeWeight: number }>()
  React.useEffect(() => {
    form.reset({
      name: category.name,
      gradeWeight: category.gradeWeight * 100,
    })
  }, [category])
  const [update, { loading: updating }] = useUpdateAssignmentCategoryMutation()
  return (
    <Form.Modal
      trigger={<Button aria-label={'Edit'} icon={<Edit />} variant={'ghost'} />}
      title={'Edit Grading Category'}
      form={form}
      onFinish={async values => {
        await update({
          variables: {
            input: {
              id: category.id,
              patch: {
                name: values.name,
                gradeWeight: values.gradeWeight / 100,
              },
            },
          },
        })
      }}
    >
      <VSpaced space={'m'}>
        <Form.TextField name={'name'} label={'Name'} required autoComplete={'off'} />
        <Form.NumberField min={0} label={'Weight'} name={'gradeWeight'} required />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit loading={updating} children={'Save Changes'} icon={<Check />} />
      </Modal.Footer>
    </Form.Modal>
  )
}
