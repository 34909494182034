import { FeatureResource, MutateHook } from '@thesisedu/feature'
import {
  AssignmentCalculation,
  AssignmentConfiguration,
  GroupsConfiguration,
  Question,
  QuestionConfig,
} from '@thesisedu/feature-assignments-core'
import { ClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import React from 'react'

import { AssignmentFlagsProps } from './grading/AssignmentFlags'
import {
  AssignmentSettingsOptions,
  FullAssignment,
  NavigationItem,
  ViewingAssignment,
} from './grading/types'
import { QuestionAssignmentSubmissionViewerOptions } from './questions/QuestionAssignmentSubmissionViewer'
import {
  useUpdateAssignmentMutation,
  AssignmentSubmissionWithConfigurationFragment,
  AssignmentFiltersInput,
  BasicAssignmentFragment,
  BasicAssignmentSubmissionFragment,
  UserFragment,
  useSubmitAssignmentMutation,
} from './schema'

export interface AssignmentFilterProps {
  filters?: AssignmentFiltersInput
  classId: string
  onChange: (filters: AssignmentFiltersInput) => void
  [key: string]: any
}
export interface AssignmentFilterResource extends FeatureResource {
  type: 'AssignmentFilter'
  component: (props: AssignmentFilterProps) => React.ReactElement | null
  hasValue: (filters: AssignmentFiltersInput) => boolean
}

export interface ClassFragmentWithGrade extends ClassFragment {
  averageGrade?: number | null
}
export interface ClassFragmentWithPermissions extends ClassFragment {
  canCreateAssignment?: boolean
  canManageAssignmentCategories?: boolean
  canManageAssignmentClassConfiguration?: boolean
  canUpdateDefaultGroups?: boolean
}

export interface ReportItemProps {
  assignment: FullAssignment
}
export interface ReportItem {
  component: (props: ReportItemProps) => React.ReactElement | null
  label: string
  icon: React.ReactElement
  key: string
}
export interface SubmissionNavigationItemSubtitleProps {
  assignment: FullAssignment
  studentId: string
  submission?: BasicAssignmentSubmissionFragment
}

export interface GradesMap {
  [rubricKey: string]: number | null | undefined
}
export interface CommentsMap {
  [rubricKey: string]: string | null | undefined
}

export enum GradeDisplayMode {
  Percentage,
  Points,
}
type AssignmentSubmissionLinkFragment = BasicAssignmentSubmissionFragment & {
  assignment: { id: string }
}

export interface AssignmentsReactOptions {
  gradeDisplayMode?: GradeDisplayMode
  showAssignmentTags?: boolean
  allowMultiClassAssignments?: boolean
  /** Enable this to allow bookmarks on performance questions. */
  performanceFieldAllowsBookmarks?: boolean
  submitForGradingLabel: string
  /** By default, the max attempts field is hidden whenever reveal answers is set to immediately. */
  dontHideMaxAttempts?: boolean
  getAssignmentLink: (
    viewer: UserFragment,
    assignment: BasicAssignmentFragment,
    classId: string | null,
    tab?: string,
  ) => string
  getAssignmentSubmissionLink: (
    viewer: UserFragment,
    submission: AssignmentSubmissionLinkFragment,
  ) => string
  performanceQuestionLabel?: string
}
export type RequiredAssignmentsReactOptions = Partial<AssignmentsReactOptions>

export interface AnswerViewOpts {
  showExplanation?: boolean
  hideCorrectAnswer?: boolean
}
export interface QuestionProps<
  Config extends QuestionConfig = QuestionConfig,
  Settings extends Record<string, any> = Record<string, any>,
> {
  question: Question<Config, Settings>
  /** The ID of the question container. */
  containerId: string
  className?: string
  answerView?: boolean | AnswerViewOpts
  showGradeFeedback?: boolean
  readOnly?: boolean
  disabled?: boolean
  onChange?: (changes: Partial<Question<Config>>) => void
  onAddAfter?: (type: string) => void
  onDelete?: () => void
  onClone?: () => void
}
export type IsQuestionComplete<Config extends QuestionConfig = QuestionConfig> = (
  question: Question<Config>,
  answer: any,
) => boolean
export type IsAutoGraded<Config extends QuestionConfig = QuestionConfig> = (
  question: Question<Config>,
) => boolean
export interface QuestionTypeWrapViewerContentProps {
  children: React.ReactElement
}
export interface QuestionTypeWrapEditContentProps {
  children: React.ReactElement
}
export interface QuestionTypeResource<Config extends QuestionConfig = QuestionConfig>
  extends FeatureResource {
  type: 'QuestionType'
  identifier: string
  renderContent: (props: QuestionProps<Config>) => React.ReactElement
  renderEdit: (props: QuestionProps<Config>) => React.ReactElement
  renderAnswerView?: (props: QuestionProps<Config>) => React.ReactElement
  renderSettings?: (props: QuestionProps<Config>) => React.ReactElement
  /** True if this question uses the real time confirmation wrapper. */
  usesRealTimeConfirmation?: boolean
  /** True if this question does not support real-time grading. */
  disableRealTimeGrading?: boolean
  /** True if the real time confirmation is in the header along with the footer. */
  realTimeConfirmationInHeader?: boolean
  isQuestionComplete?: IsQuestionComplete<Config>
  isAutoGraded?: IsAutoGraded<Config>
  icon?: React.FC<React.PropsWithChildren<object>>
  label?: string
  defaults?: Partial<Question>
  group?: string
  hiddenForTeachers?: boolean
  /** If this is supplied, it will be called with the children when rendering an assignment submission. */
  wrapViewerContent?: (props: QuestionTypeWrapViewerContentProps) => React.ReactElement
  /** If this is supplied, it will be called with the children when rendering an assignment editor. */
  wrapEditContent?: (props: QuestionTypeWrapEditContentProps) => React.ReactElement
}

export type ClassWithGroupsFragment = ClassFragment & {
  groups: Record<string, any>
}

export interface AssignmentFlagResource extends FeatureResource {
  type: 'AssignmentFlag'
  render: (props: AssignmentFlagsProps) => React.ReactElement
}

export type AssignmentSettingsOptionsPayload = AssignmentSettingsOptions
export interface AssignmentSettingsOptionsContext {
  assignment: FullAssignment
}
export type AssignmentSettingsOptionsHook = MutateHook<
  'feature-assignments-react:assignment-settings-options',
  AssignmentSettingsOptionsPayload,
  AssignmentSettingsOptionsContext
>

export type QuestionAssignmentSubmissionViewerOptionsPayload =
  QuestionAssignmentSubmissionViewerOptions
export interface QuestionAssignmentSubmissionViewerOptionsContext {
  assignment: FullAssignment
  configuration: AssignmentConfiguration
}
export type QuestionAssignmentSubmissionViewerOptionsHook = MutateHook<
  'feature-assignments-react:question-assignment-submission-viewer-options',
  QuestionAssignmentSubmissionViewerOptionsPayload,
  QuestionAssignmentSubmissionViewerOptionsContext
>

export type AssignmentDeepLinkPayload = string
export interface AssignmentDeepLinkContext {
  assignment: FullAssignment
  viewer: UserFragment
  classId: string | null
  tab?: string
}
export type AssignmentDeepLinkHook = MutateHook<
  'feature-assignments-react:assignment-deep-link',
  AssignmentDeepLinkPayload,
  AssignmentDeepLinkContext
>

export type AssignmentSubmissionDeepLinkPayload = string
export interface AssignmentSubmissionDeepLinkContext {
  submission: BasicAssignmentSubmissionFragment & AssignmentSubmissionWithConfigurationFragment
  viewer: UserFragment
}
export type AssignmentSubmissionDeepLinkHook = MutateHook<
  'feature-assignments-react:assignment-submission-deep-link',
  AssignmentSubmissionDeepLinkPayload,
  AssignmentSubmissionDeepLinkContext
>

export interface AssignmentCalculationEditorProps<
  Opts extends AssignmentCalculation = AssignmentCalculation,
> {
  calculation: Opts
}
export interface AssignmentCalculationResource<
  Opts extends AssignmentCalculation = AssignmentCalculation,
> extends FeatureResource {
  type: 'AssignmentCalculation'
  identifier: string
  label: string
  Editor: (props: AssignmentCalculationEditorProps<Opts>) => React.ReactElement | null
}

type NonOptional<T> = T extends undefined ? never : T
type NonFunction<T> = T extends Function ? never : T
export type UpdateAssignmentRefetchQueriesPayload = NonOptional<
  NonFunction<NonOptional<Parameters<typeof useUpdateAssignmentMutation>[0]>['refetchQueries']>
>
export type UpdateAssignmentRefetchQueriesHook = MutateHook<
  'feature-assignments-react:update-assignment-refetch-queries',
  UpdateAssignmentRefetchQueriesPayload,
  undefined
>

export type SubmitAssignmentRefetchQueriesPayload = NonOptional<
  NonFunction<NonOptional<Parameters<typeof useSubmitAssignmentMutation>[0]>['refetchQueries']>
>
export type SubmitAssignmentRefetchQueriesHook = MutateHook<
  'feature-assignments-react:submit-assignment-refetch-queries',
  SubmitAssignmentRefetchQueriesPayload,
  undefined
>

export type GradingModalNavigationItemsPayload = NavigationItem[]
export interface GradingModalNavigationItemsContext {
  assignment: ViewingAssignment
}
export type GradingModalNavigationItemsHook = MutateHook<
  'feature-assignments-react:grading-modal-navigation-items',
  GradingModalNavigationItemsPayload,
  GradingModalNavigationItemsContext
>

export type GradingModalReportItemsPayload = ReportItem[]
export interface GradingModalReportItemsContext {
  props: ReportItemProps
}
export type GradingModalReportItemsHook = MutateHook<
  'feature-assignments-react:grading-modal-report-items',
  GradingModalReportItemsPayload,
  GradingModalReportItemsContext
>

export type CanDeleteAssignmentHook = MutateHook<
  'feature-assignments-react:can-delete-assignment',
  boolean,
  {
    assignment: FullAssignment
  }
>

export type GradingModalVisibilityContextChildrenHook = MutateHook<
  'feature-assignments-react:grading-modal-visibility-context-children',
  React.ReactElement[],
  undefined
>

export type ImportGroupsComplete = (groups: GroupsConfiguration) => Promise<any>
export type GroupsEditImportGroupsHook = MutateHook<
  'feature-assignments-react:groups-edit-import-groups',
  React.ReactElement | null,
  { onComplete: ImportGroupsComplete; classId: string }
>

export interface GradingModalVisibilityAssignmentCallbackContext {
  defaultTab?: string
}
export type MutateGradingModalVisibilityAssignment = MutateHook<
  'feature-assignments-react:grading-modal-visibility-assignment',
  | ((
      assignment: FullAssignment | undefined,
      opts: GradingModalVisibilityAssignmentCallbackContext,
    ) => FullAssignment | undefined)
  | null,
  undefined
>
