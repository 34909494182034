import { orderBy } from 'lodash'
import React from 'react'
import { SortDirection } from 'react-stately'

import {
  ImperativeTableColumn,
  ImperativeTableProps,
  ImperativeTableRecord,
} from './ImperativeTable'

interface SortDescriptor {
  column?: React.Key
  direction?: SortDirection
}
export interface SortedImperativeTableColumn<T extends ImperativeTableRecord>
  extends ImperativeTableColumn<T> {
  /** Defaults to getting the value based on the ID of the column. */
  getValue?: (item: T) => string | number | boolean
}
export interface AutoSortedImperativeTableOptions<T extends ImperativeTableRecord> {
  columns: SortedImperativeTableColumn<T>[]
  items: ImperativeTableProps<T>['items']
}
export function useAutoSortedImperativeTable<T extends ImperativeTableRecord>({
  columns,
  items,
}: AutoSortedImperativeTableOptions<T>): Pick<
  ImperativeTableProps<T>,
  'items' | 'sortDescriptor' | 'onSortChange' | 'columns'
> {
  const [sortDescriptor, setSortDescriptor] = React.useState<SortDescriptor>()
  return {
    columns: columns.map(col => {
      if (col.allowsSorting === false) return col
      return { ...col, allowsSorting: true }
    }),
    items: React.useMemo(() => {
      if (sortDescriptor) {
        const column = columns.find(column => column.id === sortDescriptor.column)
        if (column) {
          return orderBy(
            items,
            item => {
              if (column.getValue) {
                return column.getValue(item)
              } else {
                const val = item[column.id as keyof T]
                if (typeof val === 'string') return (val as string).toLowerCase()
                return val
              }
            },
            sortDescriptor.direction === 'ascending' ? 'asc' : 'desc',
          )
        } else return items
      } else return items
    }, [items, sortDescriptor]),
    sortDescriptor,
    onSortChange: setSortDescriptor,
  }
}
