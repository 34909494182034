import { CloseCircleFilled } from '@ant-design/icons'
import { styled, H1, Block, BodyLarge } from '@thesisedu/web'
import { Result } from 'antd'
import { decode } from 'jsonwebtoken'
import React from 'react'

import { PreferencesEditor } from './PreferencesEditor'
import { debug } from '../log'
import { useJwtNotificationPreferencesQuery } from '../schema'

export interface JWTPreferencesEditorProps {
  jwt: string
}
export function JWTPreferencesEditor({ jwt }: JWTPreferencesEditorProps) {
  const { data, loading } = useJwtNotificationPreferencesQuery({
    variables: { jwt },
  })
  return (
    <PreferencesEditor
      jwt={jwt}
      preferences={data?.userNotificationPreferences || []}
      loading={loading}
    />
  )
}

export function JWTPreferencesPage() {
  const [jwt, setJwt] = React.useState<string>()
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')
    if (token) {
      try {
        const payload = decode(token)
        if (payload && Object.keys(payload).length > 0) {
          setJwt(token)
        }
      } catch {
        debug('token is invalid, showing error page')
      }
    }
  }, [])
  React.useEffect(() => {})
  return (
    <Container>
      <H1>Notification Preferences</H1>
      <BodyLarge isBlock>
        Use the fields below to edit your notification preferences. Changes are saved automatically.
      </BodyLarge>
      <Block marginTop={'@size-xl'}>
        {jwt ? (
          <JWTPreferencesEditor jwt={jwt} />
        ) : (
          <Result
            icon={<CloseCircleFilled />}
            title={'Something went wrong'}
            subTitle={
              "We can't find your notification preferences. If you clicked a link to get to this " +
              'page, please try clicking it again.'
            }
          />
        )}
      </Block>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  padding: ${props => props.theme['@padding-sm']};
  margin: ${props => props.theme['@size-l']} auto;
`
