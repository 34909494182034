import { useMutateHook } from '@thesisedu/feature-react'
import { useUserTasks, LargeUserTasksList } from '@thesisedu/feature-user-tasks-react'
import { Block, LoadingIndicator, H2, PageHead, styled, Empty, SolidIcon } from '@thesisedu/react'
import { Check } from '@thesisedu/react/icons'
import { Row, SplitHeader } from '@thesisedu/web'
import { Col } from 'antd'
import React from 'react'

import { useSelectedClass } from '../ClassContext'
import { ClassSelectorText } from '../ClassSelectorText'
import { TodoTopRightContentHook } from '../types'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme['@size-s']};
  > :last-child {
    margin-left: auto;
    padding-left: ${props => props.theme['@size-s']};
  }
`

export function TodoRoute() {
  const classInstance = useSelectedClass(true)
  const { loading, tasks } = useUserTasks({
    filters: { completed: true, classId: classInstance.id },
    variables: {
      first: 100,
      input: {
        completed: true,
        classId: classInstance.id,
      },
    },
  })
  const topRightContent = useMutateHook<TodoTopRightContentHook>(
    'classes-react:todo-top-right-content',
    [],
    { tasks },
  )

  let content
  if (loading && !tasks?.length) {
    content = <LoadingIndicator block />
  } else {
    content = (
      <Row>
        <Col xs={24} md={12}>
          <H2>Incomplete</H2>
          <Block marginBottom={'@size-l'}>
            <LargeUserTasksList
              empty={
                <Empty
                  icon={<SolidIcon color={'@green'} children={<Check />} />}
                  title={'Nothing left to do!'}
                  description={
                    'Check back later to see if your teacher has assigned any more readings or assignments.'
                  }
                />
              }
              filters={{
                completed: false,
                classId: classInstance.id,
              }}
              first={200}
            />
          </Block>
        </Col>
        <Col xs={24} md={12}>
          <Header>
            <H2 isBlock={false} style={{ lineHeight: 1 }}>
              Completed Recently
            </H2>
            {topRightContent}
          </Header>
          <LargeUserTasksList
            filters={{
              completed: true,
              classId: classInstance.id,
            }}
            empty={<Empty description={'Nothing completed recently'} />}
          />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <PageHead title={`To-Do - ${classInstance.name}`} />
      <SplitHeader
        smallText={<ClassSelectorText>{classInstance.name}</ClassSelectorText>}
        largeText={'To-Do'}
      />
      {content}
    </>
  )
}
