import React from 'react'
import ReactDOM from 'react-dom'

import { ThemeProvider, ThemeContext } from './ThemeContext'
import { debug } from './log'
import { styled } from './styledTypes'

export interface MeasureElementOpts {
  wrapper?: (child: React.ReactElement) => React.ReactElement
}
async function measureElement(
  theme: any,
  element: React.ReactElement,
  measureLayer: HTMLDivElement | null,
  { wrapper }: MeasureElementOpts = {},
): Promise<MeasureResult | null> {
  if (measureLayer) {
    const child = document.createElement('div')
    measureLayer.appendChild(child)
    return new Promise<MeasureResult | null>((resolve, reject) => {
      ReactDOM.render(
        <ThemeContext.Provider value={theme}>
          <ThemeProvider>{wrapper ? wrapper(element) : element}</ThemeProvider>
        </ThemeContext.Provider>,
        child,
        () => {
          const height = child.clientHeight
          const width = child.clientWidth
          ReactDOM.unmountComponentAtNode(child)
          child.remove()
          resolve({ height, width })
        },
      )
    })
  } else {
    return null
  }
}

export class MeasureCache {
  $cache: Record<string, MeasureResult> = {}
  $onUpdate?: () => void

  constructor(onUpdate?: () => void) {
    this.$onUpdate = onUpdate
  }

  public measureElement(id: string, element: HTMLElement) {
    if (this.$cache[id]) return
    debug('measuring element %d', id)
    const oldHeight = element.style.height
    element.style.height = 'auto'
    this.$cache[id] = {
      width: element.offsetWidth,
      height: element.offsetHeight,
    }
    element.style.height = oldHeight
    if (this.$onUpdate) {
      this.$onUpdate()
    }
  }

  public at(id: string): MeasureResult | null {
    return this.$cache[id] || null
  }

  public clear() {
    this.$cache = {}
    if (this.$onUpdate) {
      this.$onUpdate()
    }
  }
}
export interface UseMeasureCache {
  cache: MeasureCache
}
export function useMeasureCache(deps: any[], onUpdate?: () => void) {
  const cacheRef = React.useRef(new MeasureCache(onUpdate))
  React.useEffect(() => {
    cacheRef.current.clear()
  }, deps)

  return cacheRef.current
}
export function useMeasureItem(
  cache: MeasureCache,
  id: string,
  ref?: React.RefObject<HTMLDivElement>,
) {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const realRef = ref || containerRef
  React.useEffect(() => {
    if (realRef.current) {
      cache.measureElement(id, realRef.current)
    }
  }, [id])
  return realRef
}

export interface MeasureResult {
  width: number
  height: number
}
export interface MeasureElement {
  container: React.ReactElement
  measure: (element: React.ReactElement) => Promise<MeasureResult | null>
  ready: boolean
}
export function useMeasureElement(opts?: MeasureElementOpts): MeasureElement {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const theme = React.useContext(ThemeContext)
  const [ready, setReady] = React.useState(false)

  return {
    container: (
      <Container
        ref={c => {
          containerRef.current = c
          setReady(!!c)
        }}
      />
    ),
    ready,
    measure(element) {
      return measureElement(theme, element, containerRef.current, opts)
    },
  }
}

export interface MeasureElements {
  container: React.ReactElement
  measurements: (MeasureResult | null)[] | null
}
export interface UseMeasureElementsOpts extends MeasureElementOpts {
  cacheKey?: string
  wrapper?: (children: React.ReactElement) => React.ReactElement
}
export function useMeasureElements(
  elements: React.ReactElement[],
  deps: any[],
  { cacheKey, ...rest }: UseMeasureElementsOpts = {},
) {
  const [measurements, setMeasurements] = React.useState<(MeasureResult | null)[] | null>(null)
  const cache = React.useRef<Record<string, MeasureResult | null>>({})
  const didMount = React.useRef(false)
  React.useEffect(() => {
    if (didMount.current) {
      debug('clearing useMeasureElements() cache')
      cache.current = {}
    }
    didMount.current = true
  }, [cacheKey])
  const { ready, container, measure } = useMeasureElement(rest)
  React.useEffect(() => {
    if (ready) {
      let cancel = false
      let cached = 0
      debug('measuring elements')
      Promise.all(
        elements.map(async element => {
          const elementKey = element.key
          if (elementKey && cache.current[elementKey]) {
            cached++
            return cache.current[elementKey]
          }
          const measureResult = await measure(element)
          if (elementKey) cache.current[elementKey] = measureResult
          return measureResult
        }),
      ).then(measurements => {
        if (!cancel) {
          debug('done measuring elements (found %d in cache)', cached)
          setMeasurements(measurements)
        }
      })
      return () => {
        cancel = true
      }
    } else {
      setMeasurements(null)
    }
  }, [...deps, ready, cacheKey])

  return { container, measurements }
}

const Container = styled.div`
  position: absolute;
  display: inline-block;
  visibility: hidden;
  z-index: -1;
`
