import { Divider } from 'antd'
import React from 'react'

import {
  SectionIconField,
  SectionLabelField,
  SectionNameField,
  SectionTagsField,
  StudentContentField,
  TeacherContentField,
} from './SectionLabelField'
import { SimpleEditableSegment } from '../../segment/simple/SimpleEditableSegment'
import { SegmentProps } from '../../types'

export function SectionSimpleEdit(props: SegmentProps) {
  return (
    <SimpleEditableSegment
      {...props}
      typeLabel={'Section'}
      rightContent={() => (
        <>
          <SectionLabelField initialValue={props.segment.config.label} />
          <SectionIconField initialValue={props.segment.config.icon} />
          <SectionTagsField initialValue={props.segment.tagSummary || props.segment.tags} />
        </>
      )}
      children={({ typeLabel }) => (
        <>
          <SectionNameField typeLabel={typeLabel} initialValue={props.segment.name} />
          <StudentContentField
            segmentId={props.segment.id}
            initialValue={props.segment.config.studentContent}
          />
          <Divider />
          <TeacherContentField
            segmentId={props.segment.id}
            initialValue={props.segment.config.teacherContent}
          />
        </>
      )}
    />
  )
}
