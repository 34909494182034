import { Alignment, FilePayload, SerializedFileNodeV1 } from '@thesisedu/feature-widgets-core'
import { $applyNodeReplacement, DecoratorNode, LexicalNode, NodeKey } from 'lexical'
import React from 'react'

import { FileComponent } from './FileComponent'
import { UploadFile } from './useUploadFile'
import { debug } from '../../../log'

export class FileNode extends DecoratorNode<JSX.Element> {
  __file?: string | null
  __label?: string | null
  __alignment: Alignment
  __uploadFile?: UploadFile

  static getType() {
    return 'File'
  }

  static clone(node: FileNode): FileNode {
    return new FileNode(node.__file, node.__label)
  }

  static importJSON(serializedNode: SerializedFileNodeV1): FileNode {
    return $createFileNode(serializedNode)
  }

  constructor(
    file?: string | null,
    label?: string | null,
    alignment: Alignment = 'center',
    uploadFile?: UploadFile,
    key?: NodeKey,
  ) {
    super(key)
    this.__file = file
    this.__label = label
    this.__alignment = alignment
    this.__uploadFile = uploadFile
  }

  exportJSON(): SerializedFileNodeV1 {
    return {
      file: this.getFile(),
      label: this.getLabel(),
      version: 1,
      type: 'File',
    }
  }

  getFile(): string | null | undefined {
    const self = this.getLatest()
    return self.__file
  }

  setFile(file?: string | null) {
    const self = this.getWritable()
    self.__file = file
  }

  getLabel(): string | null | undefined {
    const self = this.getLatest()
    return self.__label
  }

  setLabel(label?: string | null) {
    const self = this.getWritable()
    self.__label = label
  }

  getAlignment(): Alignment {
    const self = this.getLatest()
    return self.__alignment
  }

  setAlignment(alignment: Alignment) {
    const self = this.getWritable()
    self.__alignment = alignment
  }

  clearUploadFile() {
    const writable = this.getWritable()
    writable.__uploadFile = undefined
  }

  setUploadFile(uploadFile: UploadFile) {
    const writable = this.getWritable()
    writable.__uploadFile = uploadFile
  }

  updateAlignment(node: HTMLElement) {
    node.style.marginLeft = 'auto'
    node.style.marginRight = 'auto'
    if (this.__alignment === 'left') {
      node.style.marginLeft = '0'
    } else if (this.__alignment === 'right') {
      node.style.marginRight = '0'
    }
  }

  createDOM(): HTMLElement {
    const div = document.createElement('div')
    div.classList.add('fteditor-file')
    this.updateAlignment(div)
    return div
  }

  updateDOM(prevNode: FileNode, dom: HTMLElement): false {
    this.updateAlignment(dom)
    return false
  }

  decorate(): JSX.Element {
    return (
      <FileComponent
        file={this.__file}
        label={this.__label}
        uploadFile={this.__uploadFile}
        afterUpload={(editor, file) => {
          editor.update(() => {
            if (file) {
              debug('updating file', file)
              this.setFile(file)
            }
            this.clearUploadFile()
          })
        }}
      />
    )
  }
}

export function $createFileNode({
  uploadFile,
  key,
  file,
  label,
  alignment,
}: FilePayload & { key?: NodeKey; uploadFile?: UploadFile } = {}): FileNode {
  return $applyNodeReplacement(new FileNode(file, label, alignment, uploadFile, key))
}

export function $isFileNode(node: LexicalNode | null | undefined): node is FileNode {
  return node instanceof FileNode
}
