import { transparentize } from 'polished'
import { createGlobalStyle, keyframes } from 'styled-components'

interface ElementWithCallAttention extends HTMLElement {
  callAttentionHandle?: any
}
const CALL_ATTENTION = 'call-attention-active'
export function callAttention(element: ElementWithCallAttention) {
  if (element.classList.contains(CALL_ATTENTION)) {
    element.classList.remove(CALL_ATTENTION)
  }
  clearTimeout(element.callAttentionHandle)
  setTimeout(() => {
    element.classList.add(CALL_ATTENTION)
    element.callAttentionHandle = setTimeout(() => {
      element.classList.remove(CALL_ATTENTION)
    }, 1000)
  }, 10)
}

const CallAttentionAnimation = keyframes`
  0% {
    inset: 0;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  100% {
    inset: -15px;
    opacity: 0;
  }
`
const CallAttentionContainerAnimation = keyframes`
  0% {
    outline: 0px solid transparent;
  }
  10% {
    outline: 0px solid transparent;
  }
  20% {
    outline: 2px solid var(--outline-call-attention-color);
  }
  70% {
    outline: 2px solid var(--outline-call-attention-color);
  }
  80% {
    outline: 0px solid transparent;
  }
`
export const CallAttentionGlobalStyle = createGlobalStyle`
  :root {
    --outline-call-attention-color: ${props => (props.theme as any)['@primary-color']};
  }
  .call-attention-active {
    position: relative;
    border-radius: ${props => (props.theme as any)['@border-radius-base']};
    animation: ${CallAttentionContainerAnimation} 1s linear;
    &::before {
      display: block;
      content: ' ';
      position: absolute;
      inset: 0;
      background: ${props => transparentize(0.85, (props.theme as any)['@primary-color'])};
      border-radius: ${props => (props.theme as any)['@border-radius-large']};
      animation: ${CallAttentionAnimation} 1.25s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }
`
