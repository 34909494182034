import { DeepLinkProps, useSyncMutateCallback } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

import { AssignmentDeepLinkHook, AssignmentSubmissionDeepLinkHook } from '.'
import { getBestGuessClassId } from './helpers'
import { useAssignmentQuery, useAssignmentSubmissionQuery, UserFragment } from './schema'
import { useOptions } from './useOptions'

export function AssignmentSubmissionDeepLinkRemapper({ params, resolved }: DeepLinkProps) {
  const viewer = useViewerContext(false) as UserFragment | undefined
  const { getAssignmentSubmissionLink } = useOptions()
  const { data, loading, error } = useAssignmentSubmissionQuery({
    variables: {
      id: params.id || '',
    },
    skip: !params.id,
  })
  const getLink = useSyncMutateCallback<AssignmentSubmissionDeepLinkHook>(
    'feature-assignments-react:assignment-submission-deep-link',
  )
  const submission = data?.node?.__typename === 'AssignmentSubmission' ? data.node : undefined
  React.useEffect(() => {
    if (viewer?.group) {
      if (submission) {
        resolved({
          type: 'Found',
          resolvedPath: getLink(getAssignmentSubmissionLink(viewer, submission), {
            submission,
            viewer,
          }),
        })
      } else if (error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND_ERROR') {
        resolved({
          type: 'NotFound',
        })
      } else if (!submission && !loading) {
        resolved({
          type: 'AccessDenied',
        })
      }
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [submission, loading, error, viewer])
  return null
}

export interface AssignmentDeepLinkRemapperProps extends DeepLinkProps {
  tab?: string
}
export function AssignmentDeepLinkRemapper({
  params,
  resolved,
  tab,
}: AssignmentDeepLinkRemapperProps) {
  const viewer = useViewerContext(false) as UserFragment | undefined
  const { getAssignmentLink } = useOptions()
  const { data, loading, error } = useAssignmentQuery({
    variables: {
      id: params.id || '',
    },
    skip: !params.id,
  })
  const getLink = useSyncMutateCallback<AssignmentDeepLinkHook>(
    'feature-assignments-react:assignment-deep-link',
  )
  const assignment = data?.node?.__typename === 'Assignment' ? data.node : undefined
  React.useEffect(() => {
    if (viewer?.group) {
      if (assignment) {
        if (isNative) {
          resolved({
            type: 'Found',
            resolvedPath: `/${viewer.group.toLowerCase()}/assignments/${assignment.id}`,
          })
        } else {
          const classId = getBestGuessClassId(assignment)
          resolved({
            type: 'Found',
            resolvedPath: getLink(getAssignmentLink(viewer, assignment, classId, tab), {
              assignment,
              viewer,
              classId,
              tab,
            }),
          })
        }
      } else if (error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND_ERROR') {
        resolved({
          type: 'NotFound',
        })
      } else if (!assignment && !loading) {
        resolved({
          type: 'AccessDenied',
        })
      }
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [assignment, loading, error, viewer])
  return null
}
