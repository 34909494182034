import React from 'react'
import { useLocation } from 'react-router-dom'

export function useHash(): [string | undefined, (newHash: string | undefined) => void] {
  const [hash, _setHash] = React.useState<string | undefined>(window.location.hash?.slice(1))
  const location = useLocation()

  function setHash(newHash: string | undefined) {
    _setHash(newHash)
    const historyMethod = window.location.hash ? 'pushState' : 'replaceState'
    window.history[historyMethod](
      {},
      '',
      window.location.toString().replace(window.location.hash, '') + (newHash ? `#${newHash}` : ''),
    )
  }

  // Also listen for changes to react-router-dom's location.
  React.useEffect(() => {
    const newHash = location.hash?.slice(1)
    _setHash(newHash)
  }, [location.search, location.hash, location.pathname])

  return [hash, setHash]
}
