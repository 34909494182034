import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import React, { useContext } from 'react'

import { StyledThemeContext } from './index'
import { styled } from './styledTypes'

export const COLORS = [
  { key: 'blue', name: 'Blue' },
  { key: 'green', name: 'Green' },
  { key: 'orange', name: 'Orange' },
  { key: 'red', name: 'Red' },
  { key: 'gray-7', name: 'Gray' },
]

const ColorOption = styled.div`
  display: flex;
  align-items: center;
  .color-label {
    display: block;
    width: 16px;
    height: 10px;
    border-radius: ${props => props.theme['@border-radius-base']};
    margin: 0 5px 0 0;
  }
`

export type ColorSelectProps = SelectProps<string>
export function ColorSelect(props: ColorSelectProps) {
  const theme = useContext(StyledThemeContext)
  return (
    <Select<string> {...props}>
      {COLORS.map(color => (
        <Select.Option key={color.key} value={color.key}>
          <ColorOption>
            <span className={'color-label'} style={{ background: theme[`@${color.key}`] }} />
            <span className={'color-name'}>{color.name}</span>
          </ColorOption>
        </Select.Option>
      ))}
    </Select>
  )
}
