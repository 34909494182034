import { useFeature } from '@thesisedu/feature-react'

import { CourseAssignmentsReactFeature } from './CourseAssignmentsReactFeature'

const DEFAULT_ASSIGNMENT_LABELS = [
  'Individual Activity',
  'Group Activity',
  'Quiz',
  'Posttest',
  'Ethics Case',
]
const DEFAULT_ASSIGNMENT_PRESETS = ['Total Recall Quiz', 'Gray Zone', 'Activity']
const DEFAULT_LABEL_PLACEHOLDER = 'Individual Activity, Quiz, etc.'

export function useOptions() {
  const feature = useFeature(CourseAssignmentsReactFeature)
  return {
    assignmentLabels: DEFAULT_ASSIGNMENT_LABELS,
    assignmentNamePresets: DEFAULT_ASSIGNMENT_PRESETS,
    labelPlaceholder: DEFAULT_LABEL_PLACEHOLDER,
    ...feature.options,
  }
}

export function useAssignmentLabels() {
  const feature = useFeature(CourseAssignmentsReactFeature)
  return feature.options.assignmentLabels || DEFAULT_ASSIGNMENT_LABELS
}

export function useAssignmentNamePresets() {
  const feature = useFeature(CourseAssignmentsReactFeature)
  return feature.options.assignmentNamePresets || DEFAULT_ASSIGNMENT_PRESETS
}

export function useLabelPlaceholder() {
  const feature = useFeature(CourseAssignmentsReactFeature)
  return feature.options.labelPlaceholder || DEFAULT_LABEL_PLACEHOLDER
}
