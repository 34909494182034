import { LoadingOutlined } from '@ant-design/icons'
import { useSyncMutateCallback } from '@thesisedu/feature-react'
import { ErrorIndicator } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { AssignmentViewerForm } from './form/AssignmentViewerForm'
import { useSubmissionContextValue } from './useSubmissionContextValue'
import {
  AssignmentViewerContextPartialProvider,
  useAssignmentViewerContext,
} from '../contexts/AssignmentViewerContext'
import { SubmissionContextProvider } from '../contexts/SubmissionContext'
import { TeacherSubmissionProvider } from '../contexts/TeacherSubmissionContext'
import { FullAssignment } from '../grading/types'
import { FullAssignmentSubmissionFragment } from '../schema'
import { QuestionAssignmentSubmissionViewerOptionsHook } from '../types'

export interface QuestionAssignmentSubmissionViewerSubProps {
  isDirty: boolean
  submittedDirty: boolean
}
type AddlComponent = React.FC<React.PropsWithChildren<QuestionAssignmentSubmissionViewerSubProps>>
export interface QuestionAssignmentSubmissionViewerOptions {
  HeaderComponents?: AddlComponent[]
  FooterComponents?: AddlComponent[]
}
interface AdditionalComponentsProps extends QuestionAssignmentSubmissionViewerSubProps {
  additionalComponents?: AddlComponent[]
}
function AdditionalComponents({ additionalComponents, ...rest }: AdditionalComponentsProps) {
  return additionalComponents?.length ? (
    <>
      {additionalComponents.map((Component, index) => (
        <Component key={index} {...rest} />
      ))}
    </>
  ) : null
}

export interface QuestionAssignmentSubmissionViewerProps {
  assignment: FullAssignment
  submission?: FullAssignmentSubmissionFragment
  /** Called whenever the first submission is created for an assignment. */
  onFirstSubmissionCreated?: (submission: FullAssignmentSubmissionFragment) => void
}
export function QuestionAssignmentSubmissionViewer({
  assignment,
  submission,
  onFirstSubmissionCreated,
}: QuestionAssignmentSubmissionViewerProps) {
  const { contextValue, assignmentConfiguration, loadingConfiguration, isDirty, submittedDirty } =
    useSubmissionContextValue({ assignment, submission, onFirstSubmissionCreated })
  const mutate = useSyncMutateCallback<QuestionAssignmentSubmissionViewerOptionsHook>(
    'feature-assignments-react:question-assignment-submission-viewer-options',
  )
  const { teacherView } = useAssignmentViewerContext(true)

  if (loadingConfiguration) {
    return <Result icon={<LoadingOutlined size={75} />} title={'Preparing assignment...'} />
  } else if (!assignmentConfiguration) {
    return <ErrorIndicator />
  } else {
    const { HeaderComponents, FooterComponents } = mutate(
      {},
      { assignment, configuration: assignmentConfiguration },
    )
    const subProps: QuestionAssignmentSubmissionViewerSubProps = {
      isDirty,
      submittedDirty,
    }
    const content = (
      <AssignmentViewerContextPartialProvider
        value={{
          configuration: assignmentConfiguration,
        }}
      >
        <SubmissionContextProvider value={contextValue}>
          <>
            <AdditionalComponents additionalComponents={HeaderComponents} {...subProps} />
            <AssignmentViewerForm />
            <AdditionalComponents additionalComponents={FooterComponents} {...subProps} />
          </>
        </SubmissionContextProvider>
      </AssignmentViewerContextPartialProvider>
    )

    if (teacherView) {
      return <TeacherSubmissionProvider assignmentId={assignment.id} children={content} />
    } else return content
  }
}
