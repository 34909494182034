import { styled, Body, BodyLarge } from '@thesisedu/react'
import React from 'react'

import { TagsTreeFragment } from './schema'

export interface TagTreeProps {
  tree: (Omit<TagsTreeFragment, 'children'> & Pick<Partial<TagsTreeFragment>, 'children'>)[]
  indent?: number
}
export function TagTree({ tree, indent = 0 }: TagTreeProps) {
  return (
    <>
      {tree.map(treeItem => {
        const hasChildren = !!treeItem.children?.length
        const TextComponent = hasChildren ? Body : BodyLarge
        return (
          <Container key={treeItem.tag.id} indent={indent} hasChildren={hasChildren}>
            <TextComponent children={treeItem.tag.name} />
            {hasChildren ? <TagTree tree={treeItem.children as any} indent={indent + 1} /> : null}
          </Container>
        )
      })}
    </>
  )
}

const Container = styled.div<{ hasChildren: boolean; indent: number }>`
  padding-left: ${props => (props.indent ? props.theme['@size-s'] : 0)};
  font-weight: ${props => (props.hasChildren ? 'normal' : 'bold')} !important;
  > p {
    padding-top: ${props => props.theme['@size-xxs']};
    padding-bottom: ${props => props.theme['@size-xxs']};
  }
  ${props => (props.indent === 0 ? '&' : '&.noop')} {
    margin-bottom: ${props => props.theme['@size-s']};
  }
  ${props => (props.indent !== 0 ? '> p' : '&.noop')} {
    position: relative;
    &::before {
      display: block;
      content: '';
      height: 3px;
      background: ${props => props.theme['@gray-2']};
      position: absolute;
      top: 50%;
      margin-top: -1.5px;
      left: calc(-${props => props.theme['@size-s']} + 4px);
      width: calc(${props => props.theme['@size-s']} - 8px);
    }
    &::after {
      display: block;
      content: '';
      width: 3px;
      background: ${props => props.theme['@gray-2']};
      position: absolute;
      top: 0;
      bottom: 50%;
      left: calc(-${props => props.theme['@size-s']} + 4px);
    }
  }
  > div:not(:last-child) > p::after {
    bottom: 0;
  }
`
