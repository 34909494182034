import * as ReactPopover from '@radix-ui/react-popover'
import React from 'react'

import { styled, s } from '../..'
import { Cancel } from '../../../icons'
import { usePortalContainer } from '../../context/ScrollableContainerContext'
import { wrapPortal } from '../../style/wrapPortal'
import { useOverlay } from '../../utils/useOverlay'
import Button from '../Button'
import { FocusStyle } from '../FocusRing/FocusRing'
import Text from '../Text'

export type ContainerProps = ReactPopover.PopoverProps
export function Container(props: ContainerProps) {
  const [open, setOpen] = useOverlay({ ...props, weight: 'popover' })
  return <ReactPopover.Root {...props} open={open} onOpenChange={setOpen} />
}
const PopoverPortal = wrapPortal(ReactPopover.Portal)

export type TriggerProps = ReactPopover.PopoverTriggerProps
function _Trigger(props: TriggerProps, ref: React.ForwardedRef<HTMLButtonElement>) {
  return <ReactPopover.Trigger asChild {...props} ref={ref} />
}
export const Trigger = React.forwardRef(_Trigger)

export interface ContentProps extends Omit<ReactPopover.PopoverContentProps, 'title'> {
  title?: string | React.ReactElement
  width?: number
  hideClose?: boolean
}
function _Content(
  { sideOffset = 5, children, title, width = 200, hideClose, ...props }: ContentProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const container = usePortalContainer()
  return (
    <PopoverPortal container={container}>
      <PopoverContent
        sideOffset={sideOffset}
        {...props}
        ref={ref}
        title={typeof title === 'string' ? title : undefined}
        style={{ width, ...props.style }}
      >
        {title ? (
          <Text level={'l'} weight={'bold'} bottom={'s'}>
            {title}
          </Text>
        ) : null}
        {children}
        {hideClose ? null : (
          <ReactPopover.Close asChild>
            <CloseButton
              icon={<Cancel />}
              variant={'ghost'}
              size={'extraSmall'}
              data-testid={'Popover close'}
            />
          </ReactPopover.Close>
        )}
        <Arrow />
      </PopoverContent>
    </PopoverPortal>
  )
}
export const Content = React.forwardRef(_Content)
const PopoverContent = styled(ReactPopover.Content)`
  border-radius: ${s.var('radii.1')};
  padding: ${s.size('s')};
  --popover-background: ${s.color('background')};
  background: var(--popover-background);
  box-shadow: ${s.var('shadow.1')};
  animation-duration: 400ms;
  animation-timing-function: ${s.var('curves.exponential')};
  will-change: transform, opacity;
  z-index: ${s.var('zIndices.overlays')};
  &[data-state='open'] {
    &[data-side='top'] {
      animation-name: ${s.animations.slideDownAndFade};
    }
    &[data-side='right'] {
      animation-name: ${s.animations.slideLeftAndFade};
    }
    &[data-side='bottom'] {
      animation-name: ${s.animations.slideUpAndFade};
    }
    &[data-side='left'] {
      animation-name: ${s.animations.slideRightAndFade};
    }
  }
  &:focus {
    ${FocusStyle}
  }
`

export const Arrow = styled(ReactPopover.Arrow)`
  fill: var(--popover-background);
`
const CloseButton = styled(Button)`
  position: absolute;
  top: ${s.size('0.5')};
  right: ${s.size('0.5')};
`
