import { Feature, FeatureDependencies, FeatureUse } from '@thesisedu/feature'

import { AuthGoogleReactNativeOptions } from './types'

export class AuthGoogleReactNativeFeature extends Feature {
  public static package = '@thesisedu/feature-auth-google-react/native'
  public static path = __dirname
  public static requires: string[] = []
  public options: AuthGoogleReactNativeOptions

  constructor(options: AuthGoogleReactNativeOptions, deps: FeatureDependencies) {
    super(options, deps)
    this.options = options
  }
}

export const authGoogleReactNativeFeature = (
  opts: AuthGoogleReactNativeOptions,
): FeatureUse<AuthGoogleReactNativeOptions> => [AuthGoogleReactNativeFeature, opts]
