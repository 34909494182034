import { Grade } from '@thesisedu/feature-assignments-react'
import {
  OrderDirection,
  useAssignmentSubmissionsQuery,
} from '@thesisedu/feature-assignments-react/dist/schema'
import {
  SegmentNavigationItem,
  useSegmentNavigationItemsContext,
} from '@thesisedu/feature-courses-react'
import { fromGlobalId } from '@thesisedu/feature-utils'
import { Block, HSpaced, Text } from '@thesisedu/ui'
import { Star } from '@thesisedu/ui/icons'
import React from 'react'

export interface UseAttemptNavigationItemsOpts {
  canManageAttempts: boolean
  assignmentId?: string
  classId?: string | null
}
export function useAttemptNavigationItems({
  canManageAttempts,
  assignmentId,
  classId,
}: UseAttemptNavigationItemsOpts) {
  const { setNavigationItems } = useSegmentNavigationItemsContext(false) ?? {}
  const { data } = useAssignmentSubmissionsQuery({
    skip: !setNavigationItems || !canManageAttempts || !assignmentId || !classId,
    variables: {
      id: assignmentId ?? '',
      studentClassId: classId,
      useCurrentSubmissions: true,
      first: 16,
      orderBy: 'createdAt',
      orderDirection: OrderDirection.Asc,
    },
  })
  const submissions =
    data?.node?.__typename === 'Assignment'
      ? data.node.currentSubmissions.edges.map(edge => edge.node)
      : []
  const submittedSubmissions = submissions
    .filter(sub => sub.submittedAt)
    .map(sub => sub.id)
    .join(',')
  React.useEffect(() => {
    if (setNavigationItems && canManageAttempts) {
      const filterSubmissionId = (item: SegmentNavigationItem) =>
        fromGlobalId(item.id)?.type !== 'AssignmentSubmission'
      setNavigationItems(items => [
        ...items.filter(filterSubmissionId),
        ...(submissions ?? []).map<SegmentNavigationItem>((submission, index) => {
          const attemptNumber = index + 1
          return {
            id: submission.id,
            name: (
              <HSpaced>
                <span>Attempt {attemptNumber}</span>
                <Block left>
                  {submission.gradedAt && submission.grade != null ? (
                    <Grade grade={submission.grade} hideProgress size={'small'} />
                  ) : null}
                </Block>
              </HSpaced>
            ),
            icon: submission.submittedAt ? (
              <Star />
            ) : (
              <Text level={'xs'} children={attemptNumber} />
            ),
          }
        }),
      ])
      return () => {
        setNavigationItems(items => items.filter(filterSubmissionId))
      }
    }
  }, [canManageAttempts, submittedSubmissions, submissions.length])
}
