import { StudentMetadataContext } from '@thesisedu/feature-classes-react'
import { useUpdateUserMutation } from '@thesisedu/feature-users-web/dist/queries/useUpdateUserMutation'
import { Form, Modal, useToast, VSpaced, HSpaced } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

export interface EditStudentIDModalProps {
  context: StudentMetadataContext
  visible?: boolean
  onVisibleChange: (visible: boolean) => void
  studentId: string
  teacherId: string
}
export function EditStudentIDModal({
  studentId,
  teacherId,
  context,
  visible,
  onVisibleChange,
}: EditStudentIDModalProps) {
  const toast = useToast()
  const [updateUser, { loading }] = useUpdateUserMutation({
    onCompleted: () => {
      toast({ title: 'User updated successfully!', status: 'success' })
      onVisibleChange(false)
    },
  })
  const form = Form.useForm()
  React.useEffect(() => {
    form.reset({
      firstName: context.edge.node.user.firstName,
      lastName: context.edge.node.user.lastName,
      studentId,
    })
  }, [context, studentId, form])
  return (
    <Form.Modal
      title={'Edit Student'}
      visible={visible}
      onVisibleChange={onVisibleChange}
      form={form}
      onFinish={async values => {
        await updateUser({
          variables: {
            input: {
              id: context.edge.node.user.id,
              patch: {
                firstName: values.firstName,
                lastName: values.lastName,
                username: `${values.studentId}${teacherId}`,
              },
            },
          },
        })
      }}
    >
      <VSpaced space={'m'}>
        <HSpaced>
          <div style={{ flex: 1 }}>
            <Form.TextField name={'firstName'} label={'First Name'} autoComplete={'off'} />
          </div>
          <div style={{ flex: 1 }}>
            <Form.TextField name={'lastName'} label={'Last Name'} autoComplete={'off'} />
          </div>
        </HSpaced>
        <Form.TextField
          name={'studentId'}
          label={'Student ID'}
          variant={'code'}
          size={'large'}
          required
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton children={'Cancel'} />
        <Form.Submit loading={loading} children={'Save Changes'} icon={<Check />} />
      </Modal.Footer>
    </Form.Modal>
  )
}
