import { LicenseCourse } from '@thesisedu/feature-course-types'
import { HSpaced, Space, Checkbox } from '@thesisedu/react'
import React from 'react'

import { LicenseCourseSettings } from './LicenseCourseSettings'
import { CourseFragment } from '../schema'

export interface LicenseCourseCheckboxProps {
  course: CourseFragment
  allLabels?: string[]
  value?: LicenseCourse
  onChange?: (value: LicenseCourse | undefined) => void
}
export function LicenseCourseCheckbox(props: LicenseCourseCheckboxProps) {
  const { course, value, onChange } = props
  return (
    <HSpaced>
      <Checkbox
        checked={!!value?.enabled}
        onChange={e => {
          if (onChange) {
            onChange(e.target.checked ? { enabled: true } : undefined)
          }
        }}
        style={{ minWidth: 0 }}
      >
        {course.name}
        {course.label ? ` (${course.label})` : ''}
      </Checkbox>
      <Space />
      <div style={{ flexShrink: 0 }}>
        <LicenseCourseSettings {...props} />
      </div>
    </HSpaced>
  )
}
