import React, { useEffect, useState } from 'react'

import { BasicUserTaskFragment } from '../schema'

export interface CompletedTasksContextValue {
  completeTasks: readonly string[]
  completeTask: (task: BasicUserTaskFragment, complete?: boolean) => void
}
export const CompletedTasksContext = React.createContext<CompletedTasksContextValue | undefined>(
  undefined,
)

const COMPLETED_KEY = 'feature-user-tasks-complete'
export function getTaskKey(task: BasicUserTaskFragment) {
  return [task.__typename, task.identifier].join('|')
}
const _getCompleteTasks = () => {
  let completeTasks: string[]
  try {
    const value = localStorage.getItem(COMPLETED_KEY)
    completeTasks = value ? JSON.parse(value) : []
  } catch {
    completeTasks = []
  }

  return completeTasks
}

const _saveCompleteTasks = (tasks: string[]) => {
  localStorage.setItem(COMPLETED_KEY, JSON.stringify(tasks))
}

export function CompletedTasksContextProvider({ children }: React.PropsWithChildren<object>) {
  const [completeTasks, setCompleteTasks] = useState<string[]>(_getCompleteTasks())
  useEffect(() => {
    _saveCompleteTasks(completeTasks)
  }, [completeTasks])

  return (
    <CompletedTasksContext.Provider
      value={{
        completeTasks,
        completeTask: (task, completed = true) => {
          const taskKey = getTaskKey(task)
          if (!completeTasks.includes(taskKey) && completed) {
            setCompleteTasks(c => [...c, taskKey])
          } else if (completeTasks.includes(taskKey) && !completed) {
            setCompleteTasks(c => c.filter(t => t !== taskKey))
          }
        },
      }}
      children={children}
    />
  )
}

export function useCompletedTasksContext(): CompletedTasksContextValue | undefined
export function useCompletedTasksContext(require: false): CompletedTasksContextValue | undefined
export function useCompletedTasksContext(require: true): CompletedTasksContextValue
export function useCompletedTasksContext(
  require?: boolean,
): CompletedTasksContextValue | undefined {
  const context = React.useContext(CompletedTasksContext)
  if (!context && require) {
    throw new Error('CompletedTasksContext is required, yet not provided.')
  }
  return context
}
