import {
  BookmarksPlugin,
  MediaView,
  MediaViewProps,
  MediaViewWithSidebar,
  SummaryPlugin,
} from '@thesisedu/feature-media-react'
import { BlockSpin, DATE_FORMATS, NotFound } from '@thesisedu/web'
import moment from 'moment'
import React from 'react'

import { LessonSummary } from './LessonSummary'
import { useStudentLessonQuery } from './schema'

export interface ViewLessonProps extends Partial<MediaViewProps> {
  lessonId: string
  summaryChildren?: React.ReactElement
  noSidebar?: boolean
  playerChildren?: React.ReactElement
}
export function ViewLesson({
  lessonId,
  playerChildren,
  summaryChildren,
  noSidebar,
  ...rest
}: ViewLessonProps) {
  const { data, loading } = useStudentLessonQuery({
    variables: {
      id: lessonId,
    },
  })
  const lesson = data?.node?.__typename === 'Lesson' ? data.node : undefined
  if (lesson) {
    const Component = noSidebar ? MediaView : MediaViewWithSidebar
    return (
      <Component mediaId={lesson.media.id} {...rest}>
        <BookmarksPlugin />
        <SummaryPlugin
          title={lesson.name}
          subtitle={`Posted ${moment(lesson.createdAt).format(DATE_FORMATS.FULL)}`}
        >
          <LessonSummary lesson={lesson} children={summaryChildren} />
        </SummaryPlugin>
        {playerChildren}
      </Component>
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return (
      <NotFound description={'That lesson could not be found or you do not have access to it.'} />
    )
  }
}
