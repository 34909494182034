import React from 'react'

import { SegmentNavigationItem } from '../types'

export interface SegmentNavigationItemsContextValue {
  navigationItems: SegmentNavigationItem[]
  setNavigationItems: React.Dispatch<React.SetStateAction<SegmentNavigationItem[]>>
  loading?: boolean
  error?: boolean
}
export const SegmentNavigationItemsContext = React.createContext<
  SegmentNavigationItemsContextValue | undefined
>(undefined)

export function useSegmentNavigationItemsContext(): SegmentNavigationItemsContextValue | undefined
export function useSegmentNavigationItemsContext(
  require: false,
): SegmentNavigationItemsContextValue | undefined
export function useSegmentNavigationItemsContext(require: true): SegmentNavigationItemsContextValue
export function useSegmentNavigationItemsContext(
  require?: boolean,
): SegmentNavigationItemsContextValue | undefined {
  const context = React.useContext(SegmentNavigationItemsContext)
  if (!context && require) {
    throw new Error('SegmentNavigationItemsContext is required, yet not provided.')
  }
  return context
}
