import { onMutationError } from '@thesisedu/feature-apollo-react'
import { toGlobalId } from '@thesisedu/feature-utils'
import { Modal } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import React from 'react'

import { useCourseEditorReadContext } from '../../contexts/CourseEditorContext'
import { useCopyTagsToChildrenMutation } from '../../schema'
import { Segment } from '../../types'

export interface CopyTagsToChildrenProps {
  segment: Segment
}
export function CopyTagsToChildren({ segment }: CopyTagsToChildrenProps) {
  const { courseVersionId } = useCourseEditorReadContext(true)

  const { confirm, modal } = Modal.useConfirmModal()
  const [copyTags] = useCopyTagsToChildrenMutation({
    onError: onMutationError('There was an error starting the background job.'),
  })
  return (
    <>
      {modal}
      <a
        onClick={() => {
          confirm({
            title: 'Are you sure?',
            children: (
              <>
                This will overwrite all of the tags on all children segments with the current
                segment's tags. You cannot undo this.
              </>
            ),
            onConfirm: async () => {
              await copyTags({
                variables: {
                  input: {
                    id: toGlobalId('Segment', segment.id),
                    courseVersionId,
                  },
                },
              })
            },
          })
        }}
      >
        <Copy style={{ display: 'inline' }} /> Copy Tags to Children
      </a>
    </>
  )
}
