import {
  AssignInput,
  AssignmentAssignedStudentsFragment,
  AssignmentStudentAssignmentMode,
  BasicAssignmentFragment,
} from '../schema'

export function _getAssignInput(
  assignment: BasicAssignmentFragment & AssignmentAssignedStudentsFragment,
): AssignInput {
  if (assignment.assignmentMode === AssignmentStudentAssignmentMode.AllStudents) {
    return { assignedClasses: [...new Set(assignment.students.edges.map(edge => edge.classId))] }
  } else if (assignment.assignmentMode === AssignmentStudentAssignmentMode.SpecificStudents) {
    return {
      assignedStudents: assignment.students.edges.map(edge => ({
        classId: edge.classId,
        studentId: edge.node.id,
      })),
    }
  } else {
    throw new Error(`Invalid assignment mode: ${assignment.assignmentMode}`)
  }
}
