import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

import { useKeyboardContext } from '../../KeyboardContext'
import { TupleOf } from '../simple/SimpleList'
import { ModeContentsProps } from '../types'

const PITCH_INDEX_OFFSET = -3

interface PianoItem {
  name: string
  color: string
  colorName: string
}
const BASE_ITEMS: PianoItem[] = [
  { name: 'G', color: '@blue', colorName: 'Teal' },
  { name: 'A', color: '@purple', colorName: 'Purple' },
  { name: 'B', color: '@pink', colorName: 'Pink' },
  { name: 'C', color: '@red', colorName: 'Red' },
  { name: 'D', color: '@orange', colorName: 'Orange' },
  { name: 'E', color: '@yellow', colorName: 'Yellow' },
  { name: 'F', color: '@green', colorName: 'Green' },
]
const ITEMS: PianoItem[] = [
  ...BASE_ITEMS,
  ...BASE_ITEMS,
  { name: 'G', color: '@blue', colorName: 'Teal' },
]
export const ITEM_NOTES: string[] = [
  'G3',
  'A3',
  'B3',
  'C4',
  'D4',
  'E4',
  'F4',
  'G4',
  'A4',
  'B4',
  'C5',
  'D5',
  'E5',
  'F5',
  'G5',
]
const BASE_TOP_ITEMS: (TupleOf<PianoItem, 2> | null)[] = [
  [
    { name: 'G', color: '@blue-2', colorName: 'Blue' },
    { name: 'A', color: '@blue-2', colorName: 'Blue' },
  ],
  [
    { name: 'A', color: '@plum', colorName: 'Plum' },
    { name: 'B', color: '@plum', colorName: 'Plum' },
  ],
  null,
  [
    { name: 'C', color: '@poppy', colorName: 'Poppy' },
    { name: 'D', color: '@poppy', colorName: 'Poppy' },
  ],
  [
    { name: 'D', color: '@gold', colorName: 'Gold' },
    { name: 'E', color: '@gold', colorName: 'Gold' },
  ],
  null,
  [
    { name: 'F', color: '@mint', colorName: 'Mint' },
    { name: 'G', color: '@mint', colorName: 'Mint' },
  ],
]
const TOP_ITEMS: (TupleOf<PianoItem, 2> | null)[] = [...BASE_TOP_ITEMS, ...BASE_TOP_ITEMS]
export const TOP_ITEM_NOTES: (string | null)[] = [
  'Ab3',
  'Bb3',
  null,
  'Db4',
  'Eb4',
  null,
  'Gb4',
  'Ab4',
  'Bb4',
  null,
  'Db5',
  'Eb5',
  null,
  'Gb5',
]

export interface PianoProps extends ModeContentsProps {
  colorNames?: boolean
}
export function Piano({ colorNames, wrapNote: WrapNote }: PianoProps) {
  const { playKey } = useKeyboardContext(true)
  return (
    <Container>
      <LargeKeysContainer>
        {ITEMS.map(({ name, color, colorName }, index) => {
          const contents = (
            <LargeKeyContainer
              color={color}
              key={index}
              large={!colorNames}
              onMouseDown={() => playKey(ITEM_NOTES[index])}
            >
              {colorNames ? colorName : name}
            </LargeKeyContainer>
          )
          return WrapNote ? (
            <WrapNote pitch={index + PITCH_INDEX_OFFSET} children={contents} key={index} />
          ) : (
            contents
          )
        })}
      </LargeKeysContainer>
      <SmallKeysContainer>
        {TOP_ITEMS.map((topItem, index) => {
          if (topItem) {
            const [sharp, flat] = topItem
            const note = TOP_ITEM_NOTES[index]
            const sharpContents = (
              <SmallKeyPart
                color={sharp.color}
                large={!colorNames}
                onMouseDown={note ? () => playKey(note) : undefined}
              >
                {colorNames ? null : (
                  <>
                    {sharp.name}
                    <sup>#</sup>
                  </>
                )}
              </SmallKeyPart>
            )
            const flatContents = (
              <SmallKeyPart
                color={flat.color}
                flat
                large={!colorNames}
                onMouseDown={note ? () => playKey(note) : undefined}
              >
                {colorNames ? (
                  flat.colorName
                ) : (
                  <>
                    {flat.name}
                    <sup>b</sup>
                  </>
                )}
              </SmallKeyPart>
            )
            return (
              <SmallKeyContainer>
                {WrapNote ? (
                  <WrapNote
                    pitch={index + PITCH_INDEX_OFFSET}
                    accidental={'sharp'}
                    children={sharpContents}
                  />
                ) : (
                  sharpContents
                )}
                {WrapNote ? (
                  <WrapNote
                    pitch={index + PITCH_INDEX_OFFSET + 1} // +1 because flat is the next note.
                    accidental={'flat'}
                    children={flatContents}
                  />
                ) : (
                  flatContents
                )}
              </SmallKeyContainer>
            )
          } else {
            return <span className={'spacer'} />
          }
        })}
      </SmallKeysContainer>
    </Container>
  )
}

const KEY_WIDTH = 58
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: ${props => props.theme['@size-xs']} 0;
`
const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xxs']};
  }
  > * {
    flex-shrink: 0;
  }
`
const LargeKeysContainer = styled(RowContainer)`
  > * {
    margin-top: ${props => props.theme['@size-s']};
  }
`
const LargeKeyContainer = styled.div<{ color: string; large?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  padding: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => props.theme[props.color]};
  color: white;
  font-weight: bold;
  width: ${KEY_WIDTH}px;
  font-size: ${props => (props.large ? props.theme['@size-l'] : '14px')};
  height: 150px;
  -webkit-text-stroke: ${props => (props.large ? 1 : 0.5)}px ${props => props.theme['@brown']};
  cursor: pointer;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s linear,
    box-shadow 0.25s ease-in-out;
  opacity: 0.85;
  &:hover {
    transform: scale(1.05);
    opacity: 1;
    box-shadow: 0 2px 10px ${props => transparentize(0.5, props.theme[props.color])};
    svg,
    img {
      filter: drop-shadow(0 2px 10px ${props => transparentize(0.5, props.theme[props.color])});
    }
  }
`
const SmallKeysContainer = styled(RowContainer)`
  position: absolute;
  top: ${props => props.theme['@size-xs']};
  left: calc(${KEY_WIDTH}px / 2);
  right: calc(${KEY_WIDTH}px / 2);
  > * {
    width: ${KEY_WIDTH}px;
    display: block;
  }
`
const SmallKeyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  height: 100px;
  width: ${KEY_WIDTH}px;
  > * {
    flex: 0.5;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
`
const SmallKeyPart = styled.div<{ color: string; flat?: boolean; large?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme[props.color]};
  font-size: ${props => (props.large ? 20 : 12)}px;
  font-weight: bold;
  width: ${props => props.theme['@size-xl']};
  border-${props => (props.flat ? 'bottom' : 'top')}-left-radius: ${props =>
    props.theme['@border-radius-base']};
  border-${props => (props.flat ? 'bottom' : 'top')}-right-radius: ${props =>
    props.theme['@border-radius-base']};
  color: white;
  -webkit-text-stroke: 0.5px ${props => props.theme['@brown']};
  cursor: pointer;
  transition: transform 0.25s ease-in-out, opacity 0.25s linear, box-shadow 0.25s ease-in-out;
  overflow: hidden;
  position: relative;
  &:hover {
    transform: scale(1.05);
    opacity: 1;
    box-shadow: 0 2px 10px ${props => transparentize(0.5, props.theme[props.color])};
    svg,
    img {
      filter: drop-shadow(0 2px 10px ${props => transparentize(0.5, props.theme[props.color])});
    }
  }
`
