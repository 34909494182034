import * as Dialog from '@radix-ui/react-dialog'
import React from 'react'

import { styled, s } from '../../'
import { Cancel } from '../../../icons'
import {
  ScrollableContainerProvider,
  usePortalContainer,
} from '../../context/ScrollableContainerContext'
import { wrapPortal } from '../../style/wrapPortal'
import { addForceMount } from '../../utils/addForceMount'
import * as Status from '../../utils/status'
import { useStatePropFallback } from '../../utils/useStatePropFallback'
import Button from '../Button'
import * as Overlay$ from '../Overlay'
import VisuallyHidden from '../VisuallyHidden'

let ConfigProvider: React.FC<any>
try {
  ConfigProvider = require('antd').ConfigProvider
} catch {
  ConfigProvider = ({ children }) => <>{children}</>
}

export interface ModalProps {
  children: React.ReactNode
  /**
   * Whether or not the modal is visible. You may also use the trigger prop
   * for this to automatically control the visibility of the modal. You may
   * leave this prop blank to allow the modal to control its own visibility.
   */
  visible?: boolean
  /**
   * Called whenever the visibility of the modal changes. You may
   * leave this prop blank to allow the modal to control its own visibility.
   */
  onVisibleChange?: (visible: boolean) => void
  /**
   * If this is a simple Modal without needing to control its visibility, you
   * may use a button as the trigger for this to automatically open the modal whenever
   * it is clicked.
   */
  trigger?: React.ReactElement
  /** An optional title for the modal. */
  title?: string
  /** If you want the title to only be read by screen-readers, pass true here. */
  hideTitle?: boolean
  /** Pass true for this if you want to hide the close button inside the modal. */
  noClose?: boolean
  /**
   * Override the width of the modal. This maxes out at 90vw, no matter what
   * width you pass here.
   */
  width?: number | 'auto'
  /**
   * The presentation of the modal. Defaults to modal.
   */
  presentation?: DialogPresentation
  /**
   * Only applies when presentation is drawer; the side of the screen the dialog
   * appears on.
   */
  drawerSide?: DrawerSide
  /**
   * If this is passed, this will display an icon in the header of the modal to
   * represent the current status.
   */
  status?: Status.Status
  className?: string
  style?: React.CSSProperties
}
export function Modal({
  visible,
  onVisibleChange,
  trigger,
  title,
  hideTitle,
  children,
  className,
  style,
  width = 500,
  noClose,
  status,
  presentation = 'modal',
  drawerSide = 'left',
  ...rest
}: ModalProps) {
  const container = usePortalContainer()
  const [_visible, _onVisibleChange] = useStatePropFallback(visible, onVisibleChange, false)
  const contentRef = React.useRef<HTMLDivElement>(null)
  const portalContainerRef = React.useRef<HTMLDivElement>(null)
  const titleElement = title ? (
    <DialogTitle>
      {status ? (
        <StatusContainer $color={Status.StatusColors[status]}>
          {Status.StatusIcons[status]}
        </StatusContainer>
      ) : null}
      {title}
    </DialogTitle>
  ) : null
  return (
    <Dialog.Root open={_visible} onOpenChange={_onVisibleChange}>
      {trigger ? <Dialog.Trigger asChild children={addForceMount(trigger, _visible)} /> : null}
      <DialogPortal container={container}>
        <DialogOverlay />
        <DialogContent
          {...rest}
          style={{ width, ...style }}
          className={s.variants<typeof DialogVariants>(
            className,
            {},
            presentation,
            presentation === 'drawer' ? drawerSide : undefined,
          )}
          ref={contentRef}
        >
          <ScrollableContainerProvider targetRef={contentRef} portalRef={portalContainerRef}>
            <ConfigProvider getPopupContainer={() => contentRef.current || document.body}>
              <>
                {hideTitle ? <VisuallyHidden asChild>{titleElement}</VisuallyHidden> : titleElement}
                {children}
                {noClose ? null : (
                  <Dialog.Close asChild>
                    <CloseButton icon={<Cancel />} variant={'ghost'} />
                  </Dialog.Close>
                )}
              </>
            </ConfigProvider>
          </ScrollableContainerProvider>
        </DialogContent>
        <div
          ref={portalContainerRef}
          style={{ zIndex: `calc(${s.var('zIndices.modals')} + 1)`, position: 'relative' }}
        />
      </DialogPortal>
    </Dialog.Root>
  )
}

const DialogPortal = wrapPortal(Dialog.Portal)
const DialogOverlay = styled(Dialog.Overlay)`
  ${Overlay$.OverlayStyles}
  z-index: ${s.var('zIndices.modals')};
  position: fixed;
  inset: 0;
`
const { css } = s
const DialogPresentations = {
  modal: css`
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 85vh;
    animation: ${s.animations.scaleAndFade} 0.25s ${s.var('curves.exponential')};
    border-radius: ${s.var('radii.2')};
  `,
  drawer: css`
    top: 0;
    bottom: 0;
    max-width: 90vw;
  `,
} satisfies s.Variants
type DialogPresentation = keyof typeof DialogPresentations
const DrawerSides = {
  left: css`
    left: 0;
    animation: ${s.animations.appearSlideRightAndFade} 0.25s ${s.var('curves.exponential')};
  `,
  right: css`
    right: 0;
    animation: ${s.animations.appearSlideLeftAndFade} 0.25s ${s.var('curves.exponential')};
  `,
} satisfies s.Variants
type DrawerSide = keyof typeof DrawerSides
const DialogVariants = { ...DrawerSides, ...DialogPresentations }
const DialogContent = s.styledWithVariants(
  Dialog.Content,
  'DialogContent',
  css`
    background: ${s.color('gray.background')};
    box-shadow: ${s.var('shadow.2')};
    position: fixed;
    padding: ${s.size('m')};
    z-index: ${s.var('zIndices.modals')};
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &:focus {
      outline: none;
    }
  `,
  DialogVariants,
)
const DialogTitle = styled(Dialog.Title)`
  font-size: ${s.size('3')};
  display: flex;
  align-items: center;
  gap: ${s.size('xs')};
  font-weight: bold;
  margin: 0 0 ${s.size('1')} 0;
  color: ${s.color('textEmphasis')};
`
const CloseButton = styled(Button)`
  position: absolute;
  top: ${s.size('1')};
  right: ${s.size('1')};
`
const StatusContainer = styled.div<{ $color: s.ColorAlias }>`
  border-radius: ${s.var('radii.1')};
  width: 1.25em;
  height: 1.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: ${props => s.color(props.$color)};
  > * {
    font-size: 0.75em;
  }
`
