import { Input } from 'antd'
import React, { useEffect, useState } from 'react'

export interface SearchFieldProps {
  value?: string
  onChange: (search?: string) => void
}
export const SearchField: React.FC<React.PropsWithChildren<SearchFieldProps>> = ({
  value,
  onChange,
}) => {
  const [fieldValue, setFieldValue] = useState<string>()
  useEffect(() => {
    setFieldValue(value)
  }, [value])
  return (
    <Input.Search
      placeholder={'Start typing here to find content...'}
      enterButton={'Search'}
      size={'large'}
      value={fieldValue}
      onChange={e => setFieldValue(e.target.value)}
      onSearch={onChange}
    />
  )
}
