import { L, O } from '@thesisedu/feature-types'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { NoneVariant, Variants } from './types'

export function variants<K extends Variants>(
  className: string | undefined | null | string[],
  variants: Partial<Record<keyof K, boolean | undefined | null>>,
  ...extra: (
    | keyof K
    | null
    | undefined
    | false
    | NoneVariant
    | (keyof K | null | undefined | false | NoneVariant)[]
  )[]
): string
export function variants<K extends Record<keyof K, boolean | undefined | null>>(
  variants: K,
  ...existing: (keyof K | (keyof K)[] | string | string[] | undefined | null)[]
): (keyof K)[]
export function variants<K extends Variants>(
  classNameOrVariants:
    | string
    | undefined
    | null
    | string[]
    | Partial<Record<keyof K, boolean | undefined | null>>,
  variants?:
    | Partial<Record<keyof K, boolean | undefined | null>>
    | keyof K
    | (keyof K)[]
    | undefined
    | null,
  ...extra: (
    | keyof K
    | null
    | undefined
    | false
    | NoneVariant
    | (keyof K | null | undefined | false | NoneVariant)[]
  )[]
): string | (keyof K)[] {
  if (variants && typeof variants === 'object' && !Array.isArray(variants)) {
    const className = classNameOrVariants
    return [
      ...Object.keys(variants)
        .filter(key => variants[key as keyof K])
        .map(v => `v-${v?.toString()}`),
      ...(extra ?? [])
        .filter(Boolean)
        .flat()
        .map(v => `v-${v?.toString()}`),
      ...(Array.isArray(className) ? [...className] : className ? [className] : []),
    ].join(' ')
  } else if (
    classNameOrVariants &&
    typeof classNameOrVariants === 'object' &&
    !Array.isArray(classNameOrVariants)
  ) {
    const rest = [variants, ...(extra ?? [])].filter(Boolean).flat() as (keyof K)[]
    const _variants = classNameOrVariants
    return [...Object.keys(_variants).filter(key => _variants[key as keyof K]), ...rest]
  } else {
    throw new Error('variants called with invalid arguments')
  }
}

export function merge<F extends Variants, T extends L.List<Variants>>(
  firstVariant: F,
  ...variants: T
): O.PatchAll<F, T, 'flat'> {
  return variants.reduce((acc, variant) => ({ ...acc, ...variant }), firstVariant) as O.PatchAll<
    F,
    T,
    'flat'
  >
}

export function styledWithVariants<
  C extends keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>,
  T extends Variants,
>(component: C, displayName: string, defaultVariant: FlattenSimpleInterpolation, variants: T) {
  const result = styled(component)`
    ${defaultVariant}
    ${Object.keys(variants).map(variantKey => {
      return css`
        &.v-${variantKey} {
          ${variants[variantKey as keyof T]}
        }
      `
    })}
  `
  result.displayName = displayName
  return result
}
