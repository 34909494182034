import React from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

import { useScrollContext } from './ScrollContext'
import { DragItem, DragItemType } from './types'
import { WrapNoteProps } from '../types'

export function WrapNote({ pitch, accidental, children }: WrapNoteProps) {
  const { onCollect, onDragEnd } = useScrollContext(false) || {}
  const contentRef = React.useRef<HTMLDivElement | null>(null)
  const [{ opacity }, dragRef, preview] = useDrag<DragItem, any, { opacity: number }>(
    () => ({
      type: DragItemType.Note,
      item: () => {
        return {
          pitch,
          accidental,
          element: children,
          width: contentRef.current?.clientWidth,
          height: contentRef.current?.clientHeight,
        }
      },
      end: () => {
        if (onDragEnd) onDragEnd()
      },
      collect: monitor => {
        if (onCollect) onCollect(monitor)
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
        }
      },
    }),
    [children, pitch],
  )
  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])
  return (
    <div
      style={{ opacity }}
      ref={(...args) => {
        dragRef(...args)
        contentRef.current = args[0]
      }}
      children={children}
    />
  )
}
