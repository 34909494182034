import { BaseWidgetConfig, Alignment, SerializedWidget } from '@thesisedu/feature-widgets-core'

export enum VideoWidgetDisplayMode {
  Block = 'block',
  Inline = 'inline',
}
export interface VideoConfigV1 extends BaseWidgetConfig {
  vodId: string | null
  alignment?: Alignment
  displayMode: VideoWidgetDisplayMode
  inlineLabel?: string
  version: 1
}
export type VideoConfig = VideoConfigV1
export type VideoConfigWithVodId = Omit<VideoConfig, 'vodId'> & { vodId: string }
export type VideoWidget = SerializedWidget<'Video', VideoConfig>
