import { CheckCircleFilled, ArrowRightOutlined } from '@ant-design/icons'
import { Row, styled, StyledThemeContext } from '@thesisedu/web'
import { Col, Empty } from 'antd'
import classnames from 'classnames'
import React, { useContext } from 'react'

import { COURSE_META_DEFAULTS } from './constants'
import { CourseFragment, useListCoursesQuery } from './schema'

const CourseListContainer = styled.div`
  a.course {
    display: flex;
    align-items: center;
    color: white;
    padding: 10px 15px 10px 10px;
    margin: 5px 0;
    border-radius: ${props => props.theme['@border-radius-base']};
    > img {
      width: 35px;
      height: auto;
      margin-right: 10px;
    }
    > div {
      line-height: 1.3;
      > span {
        display: block;
      }
      .name {
        font-weight: bold;
        font-size: 16px;
      }
      .description {
        font-size: 12px;
      }
    }
    > span[role='img'] {
      margin-left: auto;
    }
  }
  &.has-selected a.course {
    transition: opacity 0.1s linear;
    opacity: 0.5;
    &.is-selected {
      opacity: 1;
      position: relative;
      .selected-icon {
        position: absolute;
        font-size: 30px;
        top: -10px;
        left: -10px;
        color: ${props => props.theme['@green']};
        background: ${props => props.theme['@white']};
        border-radius: 50%;
        box-shadow: 0 2px 3px ${props => props.theme['@white']};
      }
    }
  }
`

export interface CourseListProps {
  onCourseSelected: (course: CourseFragment) => void
  selectedCourseId?: string
  grid?: boolean
}
export function CourseList({ onCourseSelected, selectedCourseId, grid }: CourseListProps) {
  const { data } = useListCoursesQuery({ variables: { first: 100 } })
  const theme = useContext(StyledThemeContext)
  const content = data?.courses?.edges.map(({ node }) => {
    const config = {
      ...COURSE_META_DEFAULTS,
      ...node.basicConfiguration,
    }
    const selected = node.id === selectedCourseId
    const content = (
      <a
        key={node.id}
        onClick={() => onCourseSelected(node)}
        className={classnames({
          course: true,
          [`background-${config.color}`]: true,
          'is-selected': selected,
        })}
        style={{ background: theme[`@${config.color}`] }}
      >
        {selected && <CheckCircleFilled className={'selected-icon'} />}
        <img src={config.icon} alt={node.label || node.name} />
        <div>
          <span className={'name'}>{node.label || node.name}</span>
          <span className={'description'}>{config.shortDescription}</span>
        </div>
        <ArrowRightOutlined />
      </a>
    )
    if (grid) {
      return (
        <Col key={node.id} xs={24} md={12} lg={8}>
          {content}
        </Col>
      )
    } else return content
  })
  const gridContent = <Row>{content}</Row>
  return (
    <CourseListContainer className={selectedCourseId ? 'has-selected' : ''}>
      {content?.length ? (
        grid ? (
          gridContent
        ) : (
          content
        )
      ) : (
        <Empty
          description={'There are no courses assigned to your account. Please contact sales.'}
        />
      )}
    </CourseListContainer>
  )
}
