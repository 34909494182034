import { Color, formatTime } from '@thesisedu/react'
import moment from 'moment'
import React from 'react'

type ColorMap = [number, string][]
const COLOR_MAP: ColorMap = [
  [60, '@blue'],
  [30, '@orange'],
  [-Infinity, '@red'],
]

export interface AssignmentCountdownTimerProps {
  timeLimit: number
  onTimerReached: () => void
  createdAt: string
}
export function AssignmentCountdownTimer({
  timeLimit,
  onTimerReached,
  createdAt,
}: AssignmentCountdownTimerProps) {
  const initialSecondsRemaining = timeLimit - moment().diff(createdAt, 'seconds')
  const [secondsRemaining, setSecondsRemaining] = React.useState(initialSecondsRemaining)
  const color = COLOR_MAP.find(item => secondsRemaining > item[0])![1]

  React.useEffect(() => {
    const handle = setInterval(() => {
      setSecondsRemaining(timeLimit - moment().diff(createdAt, 'seconds'))
    }, 1000)
    return () => clearInterval(handle)
  }, [])
  React.useEffect(() => {
    if (timeLimit && timeLimit > 30 && secondsRemaining === 0) {
      onTimerReached()
    }
  }, [timeLimit, secondsRemaining])

  return <Color color={color}>{formatTime(Math.max(0, secondsRemaining).toString())} left</Color>
}
