import { useFeature } from '@thesisedu/feature-react'
import React from 'react'

import { ApolloReactFeature } from './ApolloReactFeature'
import { MutationHookOptions, QueryHookOptions } from './apollo'
import { getAuthenticationKey } from './platform/context'

export const useImpersonation = () => {
  const apolloFeature = useFeature(ApolloReactFeature)
  const isImpersonating = apolloFeature.isUsingSessionAuth()
  const [coreAuthKey, setCoreAuthKey] = React.useState<string | null>(null)
  React.useEffect(() => {
    getAuthenticationKey(apolloFeature.options, true).then(key => {
      if (key) {
        setCoreAuthKey(key)
      }
    })
  }, [])

  const impersonate = async (jwt: string) => {
    apolloFeature.openSessionAuthTab(jwt)
  }

  const stopImpersonating = async () => {
    window.close()
  }

  return {
    impersonate,
    stopImpersonating,
    isImpersonating,
    async duplicate() {
      // This should come back with the session auth key.
      const authenticationKey = await getAuthenticationKey(apolloFeature.options)
      if (authenticationKey) {
        apolloFeature.openSessionAuthTab(authenticationKey)
      }
    },
    coreAuthKey: isImpersonating ? coreAuthKey : undefined,
    withCoreAuth<T extends QueryHookOptions<any, any> | MutationHookOptions<any, any, any, any>>(
      opts: T,
    ): T {
      if (isImpersonating && coreAuthKey) {
        return {
          ...opts,
          context: {
            ...opts.context,
            headers: {
              Authorization: `Bearer ${coreAuthKey}`,
            },
            customBatchKey: 'core-auth',
          },
          skip: false,
        }
      } else {
        return { ...opts, skip: true }
      }
    },
  }
}
