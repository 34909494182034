import { ScrollableContainerProvider } from '@thesisedu/react'
import { Transition } from '@thesisedu/web'
import { ConfigProvider } from 'antd'
import React from 'react'

import { useViewingSegmentId } from './CourseViewerModal'
import {
  CourseViewerSegmentWrapper,
  CourseViewerSegmentWrapperProps,
} from './CourseViewerSegmentWrapper'
import { ContainerRight } from './styled'
import { useHeadingScrollSync } from '../contexts/HeadingScrollContext'
import { useSegmentNavigationContext } from '../contexts/SegmentNavigationContext'

export interface CourseViewerModalRightContentProps
  extends Partial<Omit<CourseViewerSegmentWrapperProps, 'segmentId' | 'children'>> {
  children: React.ReactElement | null
  padded?: boolean
}
export function CourseViewerModalRightContent({
  children,
  padded = true,
  ...rest
}: CourseViewerModalRightContentProps) {
  const rightContainerRef = React.useRef<HTMLDivElement>(null)
  const [viewingSegmentId] = useViewingSegmentId()
  const { selectedNavigationItemId } = useSegmentNavigationContext(false) || {}
  useHeadingScrollSync(rightContainerRef, selectedNavigationItemId || 'no-navigation')
  return (
    <ContainerRight ref={rightContainerRef} style={!padded ? { padding: '0' } : undefined}>
      <ConfigProvider getTargetContainer={() => rightContainerRef.current || document.body}>
        <ScrollableContainerProvider targetRef={rightContainerRef}>
          <Transition type={'fade-scale'} state={viewingSegmentId || 'no-segment'}>
            {viewingSegmentId ? (
              <CourseViewerSegmentWrapper
                segmentId={viewingSegmentId}
                {...rest}
                children={children}
              />
            ) : (
              <div />
            )}
          </Transition>
        </ScrollableContainerProvider>
      </ConfigProvider>
    </ContainerRight>
  )
}
