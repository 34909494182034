import { ArrowLeftOutlined } from '@ant-design/icons'
import { getFileInfo } from '@thesisedu/feature-attachments-react'
import { useFeature } from '@thesisedu/feature-react'
import { styled, Modal, sparkle } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { Badge } from '../Badge'
import { BadgesReactFeature } from '../BadgesReactFeature'
import { BadgeFragment } from '../schema'

export interface BadgeGridItemProps {
  badge?: BadgeFragment
  awardedAt?: string
}
export function BadgeGridItem({ badge, awardedAt }: BadgeGridItemProps) {
  const feature = useFeature(BadgesReactFeature)
  const [visible, setVisible] = React.useState(false)
  const badgeContainerRef = React.useRef<HTMLDivElement>(null)
  const imageInfo = badge?.image ? getFileInfo(badge.image) : undefined
  React.useEffect(() => {
    if (visible) {
      let cancel = () => {}
      setTimeout(() => {
        if (badgeContainerRef.current) {
          const image = badgeContainerRef.current.querySelector('img')
          if (image) {
            cancel = sparkle(image)
          }
        }
      }, 100)
      return () => {
        cancel()
      }
    }
  }, [visible])
  return (
    <>
      {badge ? (
        <Modal visible={visible} onCancel={() => setVisible(false)} closeIcon={<span />}>
          <BadgeContainer ref={badgeContainerRef}>
            <Badge badge={badge} awardedAt={awardedAt} noBorder />
          </BadgeContainer>
          <StyledButton icon={<ArrowLeftOutlined />} onClick={() => setVisible(false)}>
            Back to Treasures
          </StyledButton>
        </Modal>
      ) : null}
      <ItemContainer
        className={badge ? 'badge-grid-item' : 'badge-grid-item disabled'}
        onClick={() => setVisible(true)}
      >
        {imageInfo?.filename && badge ? (
          <img src={imageInfo.filename} alt={badge.label} />
        ) : (
          feature.placeholder
        )}
      </ItemContainer>
    </>
  )
}

const BadgeContainer = styled.div`
  padding-top: ${props => props.theme['@size-xl']};
`
const StyledButton = styled(Button)`
  background: ${props => props.theme['@gray-1']};
  color: ${props => props.theme['@gray-7']};
  margin: ${props => props.theme['@size-l']} auto ${props => props.theme['@size-s']} auto;
  display: block;
  &:hover,
  &:focus,
  &:active {
    background: ${props => props.theme['@gray-2']};
    color: ${props => props.theme['@gray-7']};
  }
`
const ItemContainer = styled.div`
  background: ${props => props.theme['@gray-0']};
  padding: ${props => props.theme['@padding-md']};
  cursor: pointer;
  transition:
    transform 0.5s cubic-bezier(0.16, 1, 0.3, 1),
    background 0.5s linear,
    box-shadow 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  transform: scale(1);
  border-radius: ${props => props.theme['@border-radius-base']};
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    box-shadow: 0 2px 5px ${props => props.theme['@gray-0']};
    transform: scale(1.03);
  }
  &:active {
    transform: scale(0.98);
  }
  img,
  svg {
    width: 100%;
    height: auto;
    display: block;
    path {
      fill: ${props => props.theme['@gray-1']};
    }
  }
`
