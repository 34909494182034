import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import React from 'react'

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

function getSortOrder(
  key: string,
  orderBy: string,
  orderDirection: OrderDirection,
): 'ascend' | 'descend' | null {
  if (orderBy === key) {
    return orderDirection === OrderDirection.Desc ? 'descend' : 'ascend'
  } else {
    return null
  }
}

export interface SortableTableProps<T extends object> extends Omit<TableProps<T>, 'children'> {
  orderBy?: string
  orderDirection?: OrderDirection
  onSortChange?: (orderBy: string, orderDirection: OrderDirection) => void
}
export function SortableTable<T extends object = object>({
  orderBy,
  orderDirection,
  onSortChange,
  onChange,
  ...tableProps
}: SortableTableProps<T>) {
  return (
    <Table<T>
      {...tableProps}
      columns={tableProps.columns?.map(column => ({
        ...column,
        sortOrder:
          column.sorter && column.key && orderBy && orderDirection
            ? getSortOrder(column.key?.toString(), orderBy, orderDirection)
            : undefined,
      }))}
      onChange={(pagination, filters, sorter, ...args) => {
        if (onChange) {
          onChange(pagination, filters, sorter, ...args)
        }
        if (onSortChange && sorter && !Array.isArray(sorter) && sorter.columnKey && sorter.order) {
          onSortChange(
            sorter.columnKey.toString(),
            sorter.order === 'descend' ? OrderDirection.Desc : OrderDirection.Asc,
          )
        } else if (onSortChange) {
          onSortChange(
            orderBy!,
            orderDirection === OrderDirection.Asc ? OrderDirection.Desc : OrderDirection.Asc,
          )
        }
      }}
    />
  )
}
