import { BodySmall, FontWeight } from '@thesisedu/web'
import filesize from 'filesize'
import { isNil } from 'lodash'
import React from 'react'

import { useViewerUsageQuery } from './schema'

export interface UsageSummaryProps {
  usage?: number
  quota?: number
}
export const UsageSummary: React.FC<React.PropsWithChildren<UsageSummaryProps>> = ({
  usage: _usage,
  quota: _quota,
}) => {
  const { data } = useViewerUsageQuery({ skip: _usage !== undefined && _quota !== undefined })
  const usage = _usage || data?.viewer?.subscriptionUsage
  const quota = _quota || data?.viewer?.subscriptionQuota
  if (!isNil(usage) && !isNil(quota)) {
    return (
      <BodySmall
        color={usage >= quota ? '@orange' : '@text-color-secondary'}
        weight={usage >= quota ? FontWeight.Bold : FontWeight.Normal}
        children={[filesize(usage, { round: 0 }), filesize(quota, { round: 0 })].join(' / ')}
      />
    )
  } else {
    return null
  }
}
