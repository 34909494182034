import { useMutateHook } from '@thesisedu/feature-react'
import { PageHead, VSpaced } from '@thesisedu/react'
import { HSpaced } from '@thesisedu/ui'
import { useBreadcrumb, PageHeader } from '@thesisedu/web'
import React from 'react'

import { CreateClassActionsHook, CreateClassContentHook } from '../../../types'

export function Create() {
  useBreadcrumb({ title: 'Add Class' })
  const actions = useMutateHook<CreateClassActionsHook>(
    'classes-react:create-class-actions',
    [],
    undefined,
  )
  const content = useMutateHook<CreateClassContentHook>('classes-react:create-class', [], undefined)
  return (
    <>
      <PageHead title={'Add Class'} />
      <PageHeader title={'Add Class'}>
        <HSpaced>{actions}</HSpaced>
      </PageHeader>
      <VSpaced space={'@size-l'} align={'stretch'}>
        {content}
      </VSpaced>
    </>
  )
}
