import { groupBy, orderBy } from 'lodash'
import moment from 'moment'

import { BaseGridItem, GridContextValue, GridSection } from './types'

export function isSections(items: Items): items is GridSection<BaseGridItem>[] {
  return !!(items[0] as GridSection<BaseGridItem>)?.items
}

type Items<Item extends BaseGridItem = BaseGridItem> = GridContextValue<Item>['items']
export function getTotalRows(numColumns: number, items: Items) {
  if (isSections(items)) {
    return items.reduce((acc, section) => {
      return acc + Math.ceil(section.items.length / numColumns) + 1
    }, 0)
  } else {
    return Math.ceil(items.length / numColumns)
  }
}

export function getSectionHeaderForRow(
  rowIndex: number,
  numColumns: number,
  items: Items,
): GridSection<BaseGridItem> | null {
  if (isSections(items)) {
    let currentRow = -1
    for (const section of items) {
      currentRow++
      if (rowIndex === currentRow) return section
      const numRows = Math.ceil(section.items.length / numColumns)
      currentRow += numRows
      if (rowIndex <= currentRow) return null
    }
    return null
  } else return null
}

export function getItem<Item extends BaseGridItem>(
  rowIndex: number,
  columnIndex: number,
  numColumns: number,
  items: Items<Item>,
): Item | null {
  if (isSections(items)) {
    let currentRowIndex = -1
    for (const section of items) {
      currentRowIndex++ // For the title.
      const sectionItem = section.items[(rowIndex - currentRowIndex - 1) * numColumns + columnIndex]
      if (sectionItem) {
        return sectionItem
      }
      const numRows = Math.ceil(section.items.length / numColumns)
      currentRowIndex += numRows
    }
    return null
  } else {
    return items[rowIndex * numColumns + columnIndex] || null
  }
}

// Daily, up to 1 week ago.
// Up to (and including) 3 weeks ago.
// Up to (and including) 12 months ago.

interface DateZone {
  filter: (date: string) => boolean
  format: (date: string) => string
}
const DATE_ZONES: DateZone[] = [
  { filter: date => moment(date).isAfter(moment()), format: () => 'Up Next' },
  { filter: date => moment(date).isSame(moment(), 'day'), format: () => 'Today' },
  {
    filter: date => moment(date).isSame(moment().subtract(1, 'day'), 'day'),
    format: () => 'Yesterday',
  },
  {
    filter: date => moment(date).isSame(moment(), 'week'),
    format: date => moment(date).format('dddd'),
  },
  {
    filter: date => moment(date).isSame(moment().subtract(1, 'week'), 'week'),
    format: () => 'Last Week',
  },
  {
    filter: date => moment(date).isSame(moment().subtract(2, 'week'), 'week'),
    format: () => '2 Weeks Ago',
  },
  {
    filter: date => moment(date).isSame(moment().subtract(3, 'week'), 'week'),
    format: () => '3 Weeks Ago',
  },
  {
    filter: date => moment(date).isAfter(moment().subtract(1, 'year')),
    format: date => moment(date).format('MMMM YYYY'),
  },
  { filter: () => true, format: date => moment(date).format('YYYY') },
]

export type GridItemWithDate<T extends BaseGridItem = BaseGridItem> = { date: string } & T
export function generateSectionsByDate<T extends BaseGridItem = BaseGridItem>(
  items: GridItemWithDate<T>[],
): GridSection<T>[] {
  const grouped = groupBy(items, item => {
    for (const dateZone of DATE_ZONES) {
      if (dateZone.filter(item.date)) {
        return dateZone.format(item.date)
      }
    }
    return DATE_ZONES[DATE_ZONES.length - 1].format(item.date)
  })
  return orderBy(Object.keys(grouped), key => grouped[key][0].date, 'desc').map(group => {
    return {
      title: group,
      items: grouped[group],
    }
  })
}
