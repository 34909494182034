import { useFeatureRoot } from '@thesisedu/feature-react'
import React from 'react'

import { FetchClassStudentsFromProviderContext } from './fetchClassStudentsFromProvider'
import { getFetchClassesFromProvider } from './fetchClassesFromProvider'
import { getProviderError } from './providerError'
import { getRosterComplete } from './rosterComplete'
import { getRosterStudentsProgress } from './rosterStudentsProgress'
import {
  ClassSyncProviderFeature,
  ClassSyncRosterActionFragment,
  useRosterStudentsFromClassSyncMutation,
} from '../../schema'
import { Rostering } from '../Rostering/Rostering'
import { StepHeader } from '../StepHeader'
import { SetupStep, getStepFactory } from '../types'

export interface RosterStudentsContext extends FetchClassStudentsFromProviderContext {
  updates: ClassSyncRosterActionFragment[]
  providerClassName: string
}
export default {
  size: {
    width: 900,
    height: 1000,
  },
  group: 'rosterStudents',
  Component({ context, cls, transition }) {
    const appName = useFeatureRoot().appOptions.name
    const [startRostering, { loading }] = useRosterStudentsFromClassSyncMutation({
      onError() {
        transition(
          getProviderError({ provider: context.provider, nextStep: getRosterStudents(context) }),
        )
      },
      onCompleted(data) {
        const jobId = data.rosterStudentsFromClassSync.job?.id
        if (jobId) {
          transition(
            getRosterStudentsProgress({
              ...context,
              jobId,
            }),
          )
        } else {
          transition(
            getProviderError({ provider: context.provider, nextStep: getRosterStudents(context) }),
          )
        }
      },
    })
    return (
      <>
        <StepHeader
          title={'Roster Students'}
          description={`Match your ${context.provider.name} students with ${appName} students.`}
          onBack={() => {
            transition(
              getFetchClassesFromProvider({
                provider: context.provider,
              }),
            )
          }}
        />
        <Rostering
          cls={cls}
          providerClassName={context.providerClassName}
          providerIdentifier={context.provider.identifier}
          noImport={
            !!context.provider.enabledFeatures &&
            !context.provider.enabledFeatures.includes(ClassSyncProviderFeature.Rostering)
          }
          providerName={context.provider.name}
          defaultUpdates={context.updates}
          highlightUserIds={context.highlightUserIds}
          loading={loading}
          onComplete={updates => {
            if (updates.filter(update => !!update.mode).length) {
              startRostering({
                variables: {
                  input: {
                    id: context.syncId,
                    actions: updates,
                  },
                },
              })
            } else {
              // Skip straight to the complete step if there are no students.
              transition(getRosterComplete(context))
            }
          }}
        />
      </>
    )
  },
} satisfies SetupStep<RosterStudentsContext>
export const getRosterStudents = getStepFactory<RosterStudentsContext>('rosterStudents')
