import { Button } from '@thesisedu/react'
import { Download } from '@thesisedu/react/icons'
import React from 'react'
import { CSVLink } from 'react-csv'

import { useReportGridContext } from './Context'
import { ColumnHeaderInfoFn, useGetColumnHeaderInfo } from './cells/ColumnHeaderCell'
import { RowHeaderInfoFn, useGetRowHeaderInfo } from './cells/RowHeaderCell'
import { ReportCell } from './cells/getReportCells'
import { GridExtents } from './extents'

interface GetExportDataOpts {
  cells: ReportCell[][]
  getRowHeaderInfo: RowHeaderInfoFn
  getColumnHeaderInfo: ColumnHeaderInfoFn
  extents: GridExtents
}
function getExportData({
  cells,
  extents,
  getRowHeaderInfo,
  getColumnHeaderInfo,
}: GetExportDataOpts): string[][] {
  const columnHeaders = [
    '',
    ...new Array(extents.numColumns).fill('').map((_, colIndex) => {
      const { label } = getColumnHeaderInfo(colIndex + 1)
      return label
    }),
  ]
  const results: string[][] = [columnHeaders]
  for (let row = 1; row < extents.numRows; row++) {
    const { label, depth } = getRowHeaderInfo(row)
    results[row] = [
      `${'-- '.repeat(depth || 0)}${label}`,
      ...new Array(extents.numColumns).fill('').map((_, colIndex) => {
        const cell = cells[colIndex]?.[row - 1]
        if (cell) {
          return cell.metricValue !== null && cell.metricValue !== undefined
            ? cell.metricValue.toString()
            : ''
        } else return ''
      }),
    ]
  }

  return results
}

export function ReportGridExport() {
  const { cells, extents } = useReportGridContext(true)
  const getRowHeaderInfo = useGetRowHeaderInfo()
  const getColumnHeaderInfo = useGetColumnHeaderInfo()
  const [exportData, setExportData] = React.useState(
    getExportData({
      cells,
      extents,
      getRowHeaderInfo,
      getColumnHeaderInfo,
    }),
  )
  return (
    <CSVLink
      filename={'report.csv'}
      data={exportData}
      // eslint-disable-next-line node/handle-callback-err
      onClick={(e, done) => {
        setExportData(
          getExportData({
            cells,
            extents,
            getRowHeaderInfo,
            getColumnHeaderInfo,
          }),
        )
        setTimeout(done, 100)
      }}
    >
      <Button type={'primary'} children={'Export'} icon={<Download />} />
    </CSVLink>
  )
}
