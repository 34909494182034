import { Block, Button, HSpaced, Text } from '@thesisedu/ui'
import { NavArrowLeft } from '@thesisedu/ui/icons'
import React from 'react'

export interface StepHeaderProps {
  title: string
  description: string | React.ReactElement
  onBack?: () => void
}
export function StepHeader({ title, description, onBack }: StepHeaderProps) {
  return (
    <Block bottom={'m'}>
      <HSpaced space={'xs'}>
        {onBack ? <Button size={'small'} icon={<NavArrowLeft />} onPress={onBack} /> : null}
        <Text level={'h3'}>{title}</Text>
      </HSpaced>
      <Text level={'s'} color={'secondary'}>
        {description}
      </Text>
    </Block>
  )
}
