import { SegmentAssignmentConfiguration } from '@thesisedu/feature-course-types'
import React from 'react'

import { ClassFragment } from './schema'

export * from '@thesisedu/feature-course-types'

export enum CourseAssignmentsHooks {
  GradeActions = 'course-assignments-react:grade-actions',
}
export type GradeActionsPayload = React.ReactElement | null
export interface GradeActionsContext {
  id?: string
}

export type ClassWithGroupsFragment = ClassFragment & {
  groups: Record<string, any>
}

export interface CourseAssignmentsReactOptions {
  assignmentLabels?: string[]
  assignmentNamePresets?: string[]
  labelPlaceholder?: string
}

export const ASSIGNMENT_KIND_TAG = 'ASSIGNMENT_KIND'

export interface ResetOption {
  title: string
  onConfirm: Partial<SegmentAssignmentConfiguration>
  confirm: {
    status: 'danger' | 'none' | undefined
    children: string
  }
  triggerText: string
  modalText: string
}
