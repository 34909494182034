import React from 'react'

import { debug } from '../../log'
import { EditorProps } from '../Editor'

export function useOnChange({
  onChange: _onChange,
  onChangeDebounce,
  defaultValue,
}: Pick<EditorProps, 'onChange' | 'onChangeDebounce' | 'defaultValue'>) {
  const debounceRef = React.useRef<any>()
  /**
   * Whenever the defaultValue of the editor changes, the onChange event is called
   * immediately after that because we are resetting the state of the editor. We
   * want to ignore the onChange event so we don't end up in a loop of constantly
   * updating the defaultValue of the editor <-> onChange handler.
   */
  const ignoreChangeAfterUpdateDefaultValueRef = React.useRef(false)
  React.useEffect(() => {
    ignoreChangeAfterUpdateDefaultValueRef.current = true
    // Make sure to unset this after a certain period of time, otherwise the first change
    // we make to content won't save.
    const timeout = setTimeout(() => {
      ignoreChangeAfterUpdateDefaultValueRef.current = false
    }, 50)
    return () => clearTimeout(timeout)
  }, [defaultValue])
  const onChange = React.useCallback<Required<EditorProps>['onChange']>(
    (...args) => {
      if (_onChange && !ignoreChangeAfterUpdateDefaultValueRef.current) {
        if (onChangeDebounce) {
          clearTimeout(debounceRef.current)
          debounceRef.current = setTimeout(() => {
            debug('debounced change')
            _onChange(...args)
          }, onChangeDebounce)
        } else {
          _onChange(...args)
        }
      }
      ignoreChangeAfterUpdateDefaultValueRef.current = false
    },
    [_onChange, onChangeDebounce],
  )

  return onChange
}
