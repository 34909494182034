import { ClassSyncProviderResource } from '@thesisedu/feature-class-sync-react'
import { ReactFeature, ReactHooks, ReactUse } from '@thesisedu/feature-react'
import { AuthProviderHandlerResource } from '@thesisedu/feature-users-react'
import { isNative } from '@thesisedu/react'
import React from 'react'

import { CanvasReactFeatureOptions } from './types'

export class CanvasReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-canvas-react'
  static path = __dirname
  static requires = []
  public options!: CanvasReactFeatureOptions

  public reactResources() {
    require('./hooks/routes').default(this)
    require('./hooks/DistrictActions').default(this)
    require('./hooks/SiteActions').default(this)
    if (!isNative) {
      require('./resources/SAMLButton').default(this)
    }
    const { AuthProviderHandler } = require('./web/AuthProviderHandler')
    const { ExpectedErrorHandler } = require('./ExpectedErrorHandler')
    this.resources.addResource<AuthProviderHandlerResource>({
      type: 'AuthProviderHandler',
      identifier: 'canvas',
      render: payload => <AuthProviderHandler {...payload} />,
      name: 'Canvas',
      headerLogoPath: require('../assets/large.svg').default,
      squareLogoPath: require('../assets/icon.svg').default,
    })
    this.resources.addResource<ClassSyncProviderResource>({
      type: 'ClassSyncProvider',
      identifier: 'canvas',
      logos: {
        horizontal: require('../assets/large.svg').default,
        square: require('../assets/icon.svg').default,
      },
    })
    this.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => (
      <>
        <ExpectedErrorHandler />
        {children}
      </>
    ))
  }
}

export const canvasReactFeature: ReactUse<CanvasReactFeatureOptions> = opts => [
  CanvasReactFeature,
  opts,
]
