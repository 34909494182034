import React from 'react'

import { AttachmentReadFragment, AttachmentWriteFragment } from '../schema'
import {
  AttachmentFieldVariables,
  AttachmentFieldProps,
  AttachmentReadProps,
  AttachmentWriteProps,
} from '../types'

export const isWriteFragment = (
  fragment: AttachmentReadFragment | AttachmentWriteFragment,
): fragment is AttachmentWriteFragment => {
  return (fragment as AttachmentWriteFragment).uploadUrl !== undefined
}

export interface CommonAttachmentFieldProps<
  Result,
  Variables extends AttachmentFieldVariables = AttachmentFieldVariables,
> extends AttachmentFieldProps<Result, Variables> {
  AttachmentRead: React.FC<React.PropsWithChildren<AttachmentReadProps>>
  AttachmentWrite: React.FC<React.PropsWithChildren<AttachmentWriteProps<Result, Variables>>>
  data?: Result
}
export function CommonAttachmentField<
  Result,
  Variables extends AttachmentFieldVariables = AttachmentFieldVariables,
>({
  AttachmentRead,
  AttachmentWrite,
  data,
  ...props
}: CommonAttachmentFieldProps<Result, Variables>) {
  let attachment = props.fragment || (data ? props.getAttachment(data) : null)
  if (attachment && isWriteFragment(attachment)) {
    attachment = attachment.uploadUrl.attachment
  }
  if (attachment?.url) {
    return <AttachmentRead fragment={attachment} allowDelete={!props.readOnly} />
  } else if (!props.readOnly) {
    return <AttachmentWrite writeQueryDocument={props.readWriteQueryDocument} {...props} />
  } else {
    return null
  }
}
