import * as Tone from 'tone'

import { debug, warn } from './log'

interface SoundsMap {
  [key: string]: { [subkey: string]: string } | string
}
const SOUNDS: SoundsMap = {
  tada: {
    a: require('./assets/sounds/tada/a.mp3').default,
    b: require('./assets/sounds/tada/b.mp3').default,
    c: require('./assets/sounds/tada/c.mp3').default,
    d: require('./assets/sounds/tada/d.mp3').default,
    e: require('./assets/sounds/tada/e.mp3').default,
    f: require('./assets/sounds/tada/f.mp3').default,
    g: require('./assets/sounds/tada/g.mp3').default,
    all: require('./assets/sounds/tada/all.mp3').default,
  },
  bell: {
    a: require('./assets/sounds/bell/a.mp3').default,
    b: require('./assets/sounds/bell/b.mp3').default,
    c: require('./assets/sounds/bell/c.mp3').default,
    d: require('./assets/sounds/bell/d.mp3').default,
    e: require('./assets/sounds/bell/e.mp3').default,
    f: require('./assets/sounds/bell/f.mp3').default,
    g: require('./assets/sounds/bell/g.mp3').default,
    'hi-c': require('./assets/sounds/bell/hi-c.mp3').default,
  },
  badges: {
    earn: require('./assets/sounds/badge-earn.mp3').default,
  },
}

interface Players {
  [soundType: string]: Tone.Player
}

class SoundEffects {
  private players: Players = {}

  constructor() {
    debug('initializing sound effects')
    for (const key of Object.keys(SOUNDS)) {
      const value = SOUNDS[key]
      if (typeof value === 'string') {
        debug('adding %s: %s', key, value)
        this.players[key] = new Tone.Player(value).toDestination()
      } else {
        for (const subkey of Object.keys(value)) {
          const joined = [key, subkey].join('.')
          debug('adding %s: %s', joined, value[subkey])
          this.players[joined] = new Tone.Player(value[subkey]).toDestination()
        }
      }
    }
  }

  play(key: string) {
    if (this.players[key]) {
      this.players[key].start()
    } else {
      warn('cannot play %s, does not exist', key)
    }
  }

  stop(key: string) {
    if (this.players[key]) {
      this.players[key].stop()
    } else {
      warn('cannot stop %s, does not exist', key)
    }
  }
}

const soundEffects = new SoundEffects()
export default soundEffects
