import React from 'react'
import { useContextProps, CheckboxContext } from 'react-aria-components'

import CoreCheckbox, { CheckboxProps as CoreCheckboxProps } from '../Checkbox'

export interface CheckboxProps extends Omit<CoreCheckboxProps, 'checked' | 'onCheckedChange'> {
  isSelected?: boolean
  isIndeterminate?: boolean
  onChange?: (isSelected: boolean) => void
}
function _Checkbox(props: CheckboxProps, ref: React.ForwardedRef<HTMLLabelElement>) {
  ;[props, ref] = useContextProps({ ...props, slot: 'selection' }, ref, CheckboxContext)
  const { isSelected, isIndeterminate, value, onChange, ...rest } = props
  return (
    <CoreCheckbox
      size={'small'}
      checked={isIndeterminate ? 'indeterminate' : isSelected}
      onPointerDown={e => {
        // We need this because otherwise the table row will check itself, and then immediately
        // uncheck itself. For some reason not having this works for table headers, though.
        e.stopPropagation()
      }}
      onCheckedChange={onChange}
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
      {...rest}
    />
  )
}
export const Checkbox = React.forwardRef(_Checkbox)
