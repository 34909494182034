import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import {
  BasicAssignmentSubmissionFragmentDoc,
  useResetGeneratedAssignmentSubmissionMutation as useMutation,
} from '../schema'

export const useResetGeneratedAssignmentSubmissionMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error resetting that submission.'),
    update: updateMutation({
      fragment: BasicAssignmentSubmissionFragmentDoc,
      fragmentName: 'BasicAssignmentSubmission',
      path: 'gradeAssignmentSubmission.assignmentSubmission',
    }),
    ...opts,
  })
