import React from 'react'

import { CheckboxProps } from './Checkbox'
import { VSpaced, VSpacedProps } from '../VSpaced/VSpaced'

export interface CheckboxGroupProps<T = string> extends Omit<VSpacedProps, 'value' | 'onChange'> {
  value?: T
  onChange?: (value: T) => void
  children: React.ReactNode
}
function _CheckboxGroup<T = string>(
  { value, onChange, children, ...rest }: CheckboxGroupProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <VSpaced space={'xs'} ref={ref} {...rest}>
      {React.Children.map(children, child => {
        if (!React.isValidElement<CheckboxProps>(child)) {
          return child
        }
        return React.cloneElement(child, {
          checked: value === child.props.value,
          onCheckedChange: (checked: boolean) => {
            if (checked) {
              onChange?.(child.props.value as T)
            }
          },
        })
      })}
    </VSpaced>
  )
}
export const CheckboxGroup = React.forwardRef(_CheckboxGroup)
