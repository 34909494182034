import { useDeleteNodeMutation } from '@thesisedu/feature-apollo-react'
import { Button, ButtonProps, Popconfirm } from 'antd'
import React from 'react'

export interface DeleteBadgeButtonProps extends Partial<ButtonProps> {
  id: string
  onComplete?: () => void
}
export function DeleteBadgeButton({ id, onComplete, ...rest }: DeleteBadgeButtonProps) {
  const [deleteBadge, { loading }] = useDeleteNodeMutation({
    variables: {
      id,
    },
    errorMessage: 'There was an error deleting that badge.',
    onCompleted: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
  return (
    <Popconfirm
      title={'Are you sure?'}
      placement={'bottomLeft'}
      okText={'Yes, delete badge.'}
      okButtonProps={{ danger: true }}
      cancelText={'No, keep.'}
      onConfirm={() => {
        return deleteBadge()
      }}
    >
      <Button type={'link'} danger loading={loading} children={'Delete'} {...rest} />
    </Popconfirm>
  )
}
