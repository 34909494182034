import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
} from '@lexical/rich-text'
import { Quote, Text } from '@thesisedu/react/icons'
import { $createParagraphNode, $isParagraphNode } from 'lexical'
import React from 'react'

import { useElementsMenuItem, ElementsGroup } from '../../ui/ElementsMenu/ElementsMenuContext'

export function useRichTextElementsMenuItems() {
  useElementsMenuItem({
    identifier: 'paragraph',
    icon: <Text />,
    title: 'Paragraph',
    weight: -10,
    group: ElementsGroup.Text,
    isSelected: $isParagraphNode,
    onCommit(element) {
      return element.replace($createParagraphNode(), true)
    },
  })
  useElementsMenuItem({
    identifier: 'heading-1',
    icon: <>H1</>,
    title: 'Heading 1',
    weight: 4,
    group: ElementsGroup.Text,
    isSelected(element) {
      return $isHeadingNode(element) && element.getTag() === 'h1'
    },
    onCommit(element) {
      return element.replace($createHeadingNode('h1'), true)
    },
  })
  useElementsMenuItem({
    identifier: 'heading-2',
    icon: <>H2</>,
    title: 'Heading 2',
    weight: 5,
    group: ElementsGroup.Text,
    isSelected(element) {
      return $isHeadingNode(element) && element.getTag() === 'h2'
    },
    onCommit(element) {
      return element.replace($createHeadingNode('h2'), true)
    },
  })
  useElementsMenuItem({
    identifier: 'heading-3',
    icon: <>H3</>,
    title: 'Heading 3',
    weight: 6,
    group: ElementsGroup.Text,
    isSelected(element) {
      return $isHeadingNode(element) && element.getTag() === 'h3'
    },
    onCommit(element) {
      return element.replace($createHeadingNode('h3'), true)
    },
  })
  useElementsMenuItem({
    identifier: 'quote',
    icon: <Quote />,
    title: 'Quote',
    weight: 21,
    group: ElementsGroup.TextBlocks,
    isSelected: $isQuoteNode,
    onCommit(element) {
      return element.replace($createQuoteNode(), true)
    },
  })
}
