import { SegmentNavigationChildProps } from '@thesisedu/feature-courses-react'
import { Tooltip } from '@thesisedu/ui'
import React from 'react'

import { useSegmentAssignment } from './AssignmentContent'
import { AssignmentFeature } from '../../schema'

export interface GroupsNavigationItemProps extends SegmentNavigationChildProps {
  segmentId: string
}
export function GroupsNavigationItem({ children, segmentId }: GroupsNavigationItemProps) {
  const { assignment, loading } = useSegmentAssignment(segmentId)
  const supportsGroups =
    !assignment || assignment.supportedFeatures.includes(AssignmentFeature.Groups)
  const content = React.cloneElement(children, {
    disabled: loading || !assignment || !supportsGroups,
  })
  if (supportsGroups) {
    return content
  } else {
    return (
      <Tooltip
        contentProps={{ side: 'right' }}
        title={'This assignment does not support groups.'}
        children={content}
      />
    )
  }
}
