import { debug } from './log'

export function isEqualString(a: any, b: any) {
  try {
    return JSON.stringify(a) === JSON.stringify(b)
  } catch (e) {
    debug('error when comparing equality of %O -> %O', a, b, e)
    return false
  }
}
