import { BackgroundJobReporterResource } from '@thesisedu/feature-apollo-react'

import { CourseViewsReactFeature } from '../CourseViewsReactFeature'

export default function (feature: CourseViewsReactFeature) {
  feature.resources.addResource<BackgroundJobReporterResource>({
    type: 'BackgroundJobReporter',
    identifier: 'courseViewImportJobReporter',
    humanLabel: () => 'Importing segment weights...',
  })
}
