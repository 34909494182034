import { addTeacherPermission } from '@thesisedu/feature-classes-react'

import { AnnouncementsReactFeature } from '../../AnnouncementsReactFeature'

export default function (feature: AnnouncementsReactFeature) {
  addTeacherPermission(feature, 'Announcements', 'Manage announcements', [
    'CREATE_ANNOUNCEMENT',
    'UPDATE_ANNOUNCEMENT',
    'DELETE_ANNOUNCEMENT',
  ])
}
