import { onMutationError } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper } from '@thesisedu/web'
import { Button, ButtonProps, message } from 'antd'
import { Copy } from 'iconoir-react'
import React from 'react'

import { useCreateWidgetTemplateMutation, WidgetTemplateFragment } from '../schema'

export interface CopyTemplateButtonProps extends Partial<ButtonProps> {
  template: WidgetTemplateFragment
  onCopied: (template: WidgetTemplateFragment) => void
}
export function CopyTemplateButton({ template, onCopied, ...rest }: CopyTemplateButtonProps) {
  const [create, { loading }] = useCreateWidgetTemplateMutation({
    onError: onMutationError('There was an error copying that template.'),
    onCompleted: data => {
      message.success('Template copied successfully!')
      onCopied(data.createWidgetTemplate.widgetTemplate)
    },
  })

  return (
    <Button
      loading={loading}
      children={'Make a Copy'}
      icon={<AntIconWrapper children={<Copy />} />}
      {...rest}
      onClick={() => {
        create({
          variables: {
            input: {
              name: `Copy of ${template.name}`,
              widgets: template.widgets,
            },
          },
        })
      }}
    />
  )
}
