import { useSelectedClassId } from './ClassContext'
import { warn } from './log'
import { useUpdateClassMutation } from './queries/useUpdateClassMutation'
import { useTeacherOrStudentClass, useTeacherSelectedClass } from './useClass'

export function useClassConfiguration<T>(fieldName: string, classId?: string): T | undefined {
  const selectedClassId = useSelectedClassId()
  const { cls } = useTeacherOrStudentClass(classId ? classId : selectedClassId, false)
  return cls?.configuration?.[fieldName]
}

export function useUpdateClassConfiguration(): [(config: object) => Promise<any>, boolean] {
  const { cls } = useTeacherSelectedClass(false)
  const [update, { loading }] = useUpdateClassMutation()
  return [
    config => {
      if (cls) {
        return update({
          variables: {
            input: {
              id: cls.id,
              patch: {
                configuration: {
                  ...cls.configuration,
                  ...config,
                },
              },
            },
          },
        })
      } else {
        warn('not updating class configuration. could not find currently selected class.')
        return Promise.resolve()
      }
    },
    loading,
  ]
}
