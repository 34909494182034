import { onMutationError } from '@thesisedu/feature-apollo-react'

import { TeacherPracticesDocument, useDeletePracticeMutation as useMutation } from '../schema'

export const useDeletePracticeMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error deleting your practice.'),
    refetchQueries: [{ query: TeacherPracticesDocument }],
    awaitRefetchQueries: true,
    ...opts,
  })
