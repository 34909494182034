import React from 'react'

import { InfiniteSearch } from './InfiniteSearch'
import { SearchResultList } from './SearchResultList'
import { SearchQueryVariables } from '../schema'
import { CommonSearchProps } from '../types'

export type SearchListProps<Variables extends SearchQueryVariables = SearchQueryVariables> =
  CommonSearchProps<Variables>
export function SearchList<Variables extends SearchQueryVariables = SearchQueryVariables>(
  props: SearchListProps<Variables>,
) {
  return (
    <InfiniteSearch<Variables>
      {...props}
      mode={'renderList'}
      children={items => <SearchResultList results={items} />}
    />
  )
}
