import { styled, StyledThemeContext } from '@thesisedu/web'
import React from 'react'

import { getNoteColor, DOTTED_NOTES, STEM_OFFSET, getStemLeftOffset, STEM_WIDTH } from './Note'
import {
  TuneNoteItem,
  isNoteItem,
  isRestItem,
  TuneRestItem,
  TuneVoice,
  TuneMeter,
  TuneVoiceItem,
} from './types'
export interface MaxBeamedNotesMap {
  [meter: number]: number
}
// In cases of 2 digits: first number is the meter numerator, second is the meter denominator
// In cases of 3 digits: first two number are the meter numberator, third is the meter denominator
export const MAX_BEAMED_NOTES: MaxBeamedNotesMap = {
  24: 2,
  28: 1,
  34: 2,
  38: 3,
  44: 4,
  48: 4,
  54: 6,
  58: 3,
  64: 6,
  68: 3,
  128: 3,
}

function simplifyMeter(meter: TuneMeter) {
  return parseInt(`${meter.num}${meter.den}`, 10)
}

export function getUsedDuration(measure: TuneVoice) {
  return measure.reduce((_acc, _voice, _index) => {
    if (_voice.el_type === 'note') {
      return (_acc += _voice.duration)
    } else {
      return _acc
    }
  }, 0)
}

export function enforceBeamingLength(voices: TuneVoice, meter: TuneMeter): TuneVoice {
  const simplifiedMeter = simplifyMeter(meter)
  let beamLimit = MAX_BEAMED_NOTES[simplifiedMeter]
  let restIdx: number | undefined

  const enforcedBeamLengthVoices = voices.map((voice: TuneVoiceItem, index: number) => {
    if (voice.el_type === 'bar') return voice
    const previousVoice: TuneVoiceItem | undefined = voices[index - 1]
    const previousNote: TuneNoteItem | undefined =
      previousVoice && previousVoice.el_type !== 'bar' && isNoteItem(previousVoice)
        ? previousVoice
        : undefined
    const previousRest: TuneRestItem | undefined =
      previousVoice && previousVoice.el_type !== 'bar' && isRestItem(previousVoice)
        ? previousVoice
        : undefined

    beamLimit = (beamLimit * 0.125 - voice.duration) / 0.125

    if (isRestItem(voice)) {
      restIdx = index

      if (previousNote) {
        previousNote.endBeam = true
        previousNote.startBeam = false
      }
    }

    if (isNoteItem(voice)) {
      if (voice.duration >= 0.25) {
      }
      if (voice.duration <= 0.125) {
        if (beamLimit === MAX_BEAMED_NOTES[simplifiedMeter]) {
          voice.startBeam = true
          voice.endBeam = false
        }
        if (beamLimit === 0) {
          voice.startBeam = false
          voice.endBeam = true
          if (simplifiedMeter === 34 && previousNote) {
            previousNote.startBeam = true
            previousNote.endBeam = false
          }
        }
        if (beamLimit === -2) {
          if (previousNote && previousNote.duration <= 0.125) {
            previousNote.endBeam = false
            previousNote.startBeam = true
          }
          if (previousRest) {
            voice.endBeam = false
          }
        }
        if (beamLimit === -3 && restIdx === index - 2 && previousNote) {
          previousNote.startBeam = true
          previousNote.endBeam = false
        }

        if (beamLimit === -MAX_BEAMED_NOTES[simplifiedMeter]) {
          voice.endBeam = true
          voice.startBeam = false
          beamLimit = MAX_BEAMED_NOTES[simplifiedMeter]
        }
      }
    }
    return voice
  })
  return enforcedBeamLengthVoices
}

export function getMaxMinPitchAndDuration(notes: TuneNoteItem[]) {
  let maxPitch = -Infinity,
    minPitch = Infinity,
    totalDuration = 0
  for (const note of notes) {
    totalDuration += note.duration
    if (note.pitches[0].pitch > maxPitch) maxPitch = note.pitches[0].pitch
    if (note.pitches[0].pitch < minPitch) minPitch = note.pitches[0].pitch
  }
  return { maxPitch, minPitch, totalDuration }
}

const BEAM_LAST_WIDTH = 40
const BEAM_LAST_WIDTH_DOTTED = 10
const BEAM_INCLUDES_DOTTED_DELTA = 30
export interface BeamProps {
  notes: TuneNoteItem[]
  duration: number
  inverse?: boolean
}
export function Beam({ notes, duration, inverse }: BeamProps) {
  const theme = React.useContext(StyledThemeContext)
  const { maxPitch, minPitch, totalDuration } = getMaxMinPitchAndDuration(notes)
  const delta =
    notes.length > 1 ? notes[notes.length - 1].pitches[0].pitch - notes[0].pitches[0].pitch : 0
  const hasDotted = !!notes.filter(note => DOTTED_NOTES.includes(note.duration)).length
  const lastItemDotted = DOTTED_NOTES.includes(notes[notes.length - 1].duration)
  let lastWidth = lastItemDotted ? BEAM_LAST_WIDTH_DOTTED : BEAM_LAST_WIDTH
  if (hasDotted) {
    lastWidth += BEAM_INCLUDES_DOTTED_DELTA
  }

  return (
    <BeamContainer
      style={{
        width: `calc(${(totalDuration / duration) * 100}% - (100% - ${lastWidth}px) + ${
          STEM_WIDTH * 2
        }px + ${inverse ? STEM_WIDTH * 2 + 4.25 : 0}px)`,
      }}
      numItems={notes.length}
      className={inverse ? 'inverse' : ''}
      depth={delta < 0 ? -1 : delta > 0 ? 1 : 0}
      maxPitch={maxPitch}
      minPitch={minPitch}
      duration={duration}
      inverse={inverse}
    >
      {notes.map((note, index) => (
        <BeamItem
          key={index}
          style={{
            background: theme[getNoteColor(note.pitches[0].pitch, note.pitches[0].accidental)],
            flex: note.duration / totalDuration,
          }}
        />
      ))}
    </BeamContainer>
  )
}

const BeamItem = styled.div`
  height: ${props => props.theme['@size-l']};
  width: 100%;
`
const ClipPathDeltas = [
  'polygon(35px 0%, 100% 50%, 100% 100%, 35px 50%)',
  'polygon(35px 50%, 100% 50%, 100% 100%, 35px 100%)',
  'polygon(35px 50%, 100% 0%, 100% 50%, 35px 100%)',
]
const InverseClipPathDeltas = [
  'polygon(1px 0%, calc(100% - 35px) 50%, calc(100% - 35px) 100%, 1px 50%)',
  'polygon(1px 50%, calc(100% - 35px) 50%, calc(100% - 35px) 100%, 1px 100%)',
  'polygon(1px 50%, calc(100% - 35px) 0%, calc(100% - 35px) 50%, 1px 100%)',
]

export function getPitchOffset(
  inverse: boolean,
  { maxPitch, minPitch }: { maxPitch: number; minPitch: number },
  noZero: boolean = false,
) {
  if (inverse) {
    const inverseOffset = Math.max(0, PITCH_BEAM_PX * (INVERSE_PITCH - minPitch))
    return noZero && inverseOffset === 0 ? inverseOffset + 60 : inverseOffset
  } else {
    const uprightOffset = Math.max(0, PITCH_BEAM_PX * (maxPitch - NORMAL_PITCH))
    return noZero && uprightOffset === 0 ? uprightOffset + 60 : uprightOffset
  }
}
export function getAveragePitch(notes: TuneNoteItem[]) {
  let pitchesSum: number = 0
  for (const note of notes) {
    pitchesSum += note.pitches[0].pitch
  }
  return pitchesSum / notes.length
}
const PITCH_BEAM_PX = 15
const NORMAL_PITCH = 5
const INVERSE_PITCH = 3
const BeamContainer = styled.div<{
  numItems: number
  depth: -1 | 0 | 1
  maxPitch: number
  minPitch: number
  duration: number
  inverse: boolean | undefined
}>`
  height: ${props => props.theme['@size-l']};
  position: absolute;
  clip-path: ${props => ClipPathDeltas[props.depth + 1]};
  display: flex;
  left: 0;
  bottom: calc(48% + ${props => getPitchOffset(false, props, true)}px);
  padding-left: ${props => getStemLeftOffset(false, props.duration, false)}px;
  border-radius: 0 0 10px 10px;
  &.inverse {
    bottom: auto;
    top: calc(90% - ${props => getPitchOffset(true, props)}px);
    clip-path: ${props => InverseClipPathDeltas[props.depth + 1]};
    padding-left: ${props => getStemLeftOffset(true, props.duration, false)}px;
    padding-right: ${() => STEM_OFFSET}px;
    border-radius: 10px 10px 0 0;
  }
`
