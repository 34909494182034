import * as React from 'react'

function SvgNoteEighthInverse(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 98 262'} {...props}>
      <path
        fillRule={'evenodd'}
        d={
          'M82.955 4.718C91.869 9.6 96.427 15.72 96.427 24.835c0 15.243-12.95 32.837-34.145 42.547-17.5 8.018-35.652 8.56-47.722 2.655l-.001 153.299c.886-2.767 1.807-5.485 2.8-8.103v.43l.322-1.079c.055-.18.11-.362.167-.544l.345-1.097c.118-.368.238-.739.362-1.111l.378-1.124.195-.568.404-1.145.42-1.16.44-1.173.459-1.19.477-1.204c.243-.606.494-1.218.753-1.837l.526-1.244.272-.628.558-1.27c.474-1.065.97-2.147 1.49-3.249l.635-1.33c.107-.223.216-.447.326-.672l.67-1.358.693-1.377a203.215 203.215 0 013.425-6.435l.832-1.486.858-1.506.885-1.528c.75-1.282 1.528-2.586 2.336-3.914l.984-1.605c.166-.27.334-.54.503-.81 14.324-23.008 11.868-46.64 8.83-59.408l-.27-1.098-.135-.527-.269-1.01-.266-.95a76.482 76.482 0 00-.131-.451l-.257-.855-.247-.79-.235-.723a64.187 64.187 0 00-.113-.336l-.213-.62-.285-.794-.303-.8-.266-.657c13.233 13.735 16.608 53.797-2.401 82.002l-.583.851c-11.002 15.797-16.88 35.637-19.961 50.59l-.331 1.642c-.16.81-.311 1.604-.455 2.38l-.276 1.528-.256 1.48-.236 1.425-.217 1.372-.198 1.313-.181 1.252-.164 1.19-.147 1.123-.132 1.054-.117.983-.103.909-.13 1.218-.128 1.338-.124 1.497-.023.356V261h-6.126l.002-1H.56L.558 49.083a26.51 26.51 0 01.002-.846V48C.983 33.163 14.289 16.473 34.717 7.114 52.467-1.018 70.89-1.46 82.955 4.718z'
        }
      />
    </svg>
  )
}

const MemoSvgNoteEighthInverse = React.memo(SvgNoteEighthInverse)
export default MemoSvgNoteEighthInverse
