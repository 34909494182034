import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import { Empty, VSpaced } from '@thesisedu/react'
import React from 'react'

import { GeneratedAssignmentsBulkEnable } from './GeneratedAssignmentsBulkEnable'
import { GeneratedAssignmentsListItem } from './GeneratedAssignmentsListItem'
import * as schema from '../schema'

type Fragment = schema.BasicAssignmentFragment & {
  submissions: {
    averageGrade?: number | null
    totalCount: number
  }
}

export interface GeneratedAssignmentsListProps {
  classId: string
}
export function GeneratedAssignmentsList({ classId }: GeneratedAssignmentsListProps) {
  return (
    <InfiniteQuery<
      Fragment,
      schema.GeneratedAssignmentsQuery,
      schema.GeneratedAssignmentsQueryVariables
    >
      document={schema.GeneratedAssignmentsDocument}
      resultPath={'viewer.teacher.assignments'}
      queryOpts={{
        fetchPolicy: 'cache-and-network',
      }}
      variables={{
        filters: {
          classIds: [classId],
          generated: true,
          excluded: schema.AssignmentExcludedFilter.Both,
        },
      }}
      children={({ data }) => {
        const assignments = data?.viewer?.teacher?.assignments.edges.map(edge => edge.node)
        if (assignments?.length) {
          return (
            <VSpaced space={'@size-m'} align={'stretch'}>
              <GeneratedAssignmentsBulkEnable assignmentIds={assignments.map(a => a.id)} />
              {assignments.map(assignment => {
                return (
                  <GeneratedAssignmentsListItem
                    key={assignment.id}
                    assignment={assignment}
                    averageGrade={assignment.submissions.averageGrade}
                    totalSubmissions={assignment.submissions.totalCount}
                  />
                )
              })}
            </VSpaced>
          )
        } else {
          return <Empty description={'There are no automatic grades currently available.'} />
        }
      }}
    />
  )
}
