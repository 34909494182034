import { onMutationError } from '@thesisedu/feature-apollo-react'

import { useAcceptInviteMutation as useMutation } from '../schema'

export const useAcceptInviteMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error accepting that invite.'),
    refetchQueries: ['minimalStudentClasses'],
    awaitRefetchQueries: true,
    ...opts,
  })
