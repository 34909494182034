import * as React from 'react'

function SvgAccidentalFlat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns={'http://www.w3.org/2000/svg'} viewBox={'0 0 50 130'} {...props}>
      <path
        fill={'#512D1C'}
        d={
          'M0 0h7.912v74.565c2.947-1.396 5.48-2.715 8.092-3.8 7.963-3.259 16.004-4.551 24.122-.44 10.704 5.43 13.135 15.874 5.171 24.794a96.49 96.49 0 01-14.271 11.945c-9.412 6.877-18.926 13.47-28.44 20.193-.776.517-1.5 1.111-2.586 2.016V0zm8.118 114.562l.828.465c5.17-5.17 10.47-9.98 15.254-15.512a37.955 37.955 0 005.972-10.135 9.075 9.075 0 00-2.585-10.834 9.67 9.67 0 00-11.299-1.81 42.815 42.815 0 00-5.74 3.62 6.36 6.36 0 00-2.585 5.662c.13 8.532 0 17.039 0 25.57.026 1.087.104 2.172.155 2.974z'
        }
      />
    </svg>
  )
}

const MemoSvgAccidentalFlat = React.memo(SvgAccidentalFlat)
export default MemoSvgAccidentalFlat
