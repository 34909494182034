/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@thesisedu/feature-apollo-react/apollo';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export enum BackgroundJobStatus {
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type ConfirmationInput = {};

export type DateFilterInput = {
  after?: InputMaybe<Scalars['DateTime']['input']>;
  before?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DetailedLogsForUserInput = {
  handler?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  jwt: Scalars['String']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum FilterOperation {
  And = 'AND',
  Or = 'OR'
}

export type FreeUsernameInput = {
  username: Scalars['String']['input'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Permission {
  CreateUser = 'CREATE_USER',
  FreeUsername = 'FREE_USERNAME',
  Impersonate = 'IMPERSONATE',
  ListUser = 'LIST_USER',
  Login = 'LOGIN',
  ManageLogs = 'MANAGE_LOGS',
  Register = 'REGISTER',
  RequestResetPassword = 'REQUEST_RESET_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  Search = 'SEARCH',
  SearchUsers = 'SEARCH_USERS',
  UpdateOwnUser = 'UPDATE_OWN_USER',
  UpdateUser = 'UPDATE_USER',
  ViewOwnUser = 'VIEW_OWN_USER',
  ViewOwnUserEmail = 'VIEW_OWN_USER_EMAIL',
  ViewOwnUserName = 'VIEW_OWN_USER_NAME',
  ViewUserEmail = 'VIEW_USER_EMAIL',
  ViewUserGroups = 'VIEW_USER_GROUPS',
  ViewUserName = 'VIEW_USER_NAME'
}

export type RegisterInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type RequestResetPasswordInput = {
  usernameOrEmail: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SearchFilterInput = {
  createdAt?: InputMaybe<DateFilterInput>;
  providers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SignedUploadInput = {
  extension: Scalars['String']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType: Scalars['String']['input'];
  namespace?: InputMaybe<Scalars['String']['input']>;
  requestMode?: InputMaybe<UploadRequestMode>;
};

export type UnlinkAccountInput = {
  provider: Scalars['String']['input'];
  providerId: Scalars['String']['input'];
};

export type UpdateUserDetailedLoggingInput = {
  enabled: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateUserInput = {
  id: Scalars['ID']['input'];
  patch: UserInput;
  updateLastActivity?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UploadRequestMode {
  Basic = 'BASIC',
  FormData = 'FORM_DATA'
}

export type UserInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type SearchQuery_search_SearchResultsConnection_pageInfo_PageInfo = (
  {
  endCursor?: string | null,
  hasNextPage?: boolean | null
}
  & {
  __typename?: 'PageInfo'
}
);

export type SearchQuery_search_SearchResultsConnection_edges_SearchResultsEdge_node_User = (
  {
  id: string
}
  & {
  __typename: 'User'
}
);

export type SearchQuery_search_SearchResultsConnection_edges_SearchResultsEdge = (
  {
  node: SearchQuery_search_SearchResultsConnection_edges_SearchResultsEdge_node_User
}
  & {
  __typename?: 'SearchResultsEdge'
}
);

export type SearchQuery_search_SearchResultsConnection = (
  {
  pageInfo: SearchQuery_search_SearchResultsConnection_pageInfo_PageInfo,
  edges: Array<SearchQuery_search_SearchResultsConnection_edges_SearchResultsEdge>
}
  & {
  __typename?: 'SearchResultsConnection'
}
);

export type SearchQuery_Query = (
  {
  search: SearchQuery_search_SearchResultsConnection
}
  & {
  __typename?: 'Query'
}
);


export type SearchQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<SearchFilterInput>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type SearchQuery = SearchQuery_Query;


export const SearchDocument = gql`
    query search($query: String, $first: Int = 32, $after: String, $filters: SearchFilterInput, $orderBy: String, $orderDirection: OrderDirection) {
  search(
    query: $query
    after: $after
    first: $first
    filters: $filters
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        __typename
        id
      }
    }
  }
}
    `;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: ApolloReactCommon.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactCommon.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: ApolloReactCommon.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactCommon.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = ApolloReactCommon.QueryResult<SearchQuery, SearchQueryVariables>;
declare module '@thesisedu/feature-apollo-react' {
  export interface MutationConfirmations extends ConfirmationInput {}
}

declare module '@thesisedu/feature-users-react' {
  export interface PermissionsMap extends Record<`${Permission}`, true> {}
}
