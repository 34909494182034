import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import React from 'react'

export interface ReportsReactFeatureOptions {}

export class ReportsReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-reports-react'
  static path = ''
  static requires: string[] = []
  public options!: ReportsReactFeatureOptions

  public reactResources() {
    require('./hooks/routes').default(this)
    const { ReportsContextProvider } = require('./ReportsContext')
    this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, children => {
      return <ReportsContextProvider children={children} />
    })
  }
}

export const reportsReactFeature: ReactUse = (opts: ReportsReactFeatureOptions) => [
  ReportsReactFeature,
  opts,
]
