import { DeleteLink, Body, DATE_FORMATS, FontWeight } from '@thesisedu/web'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import Case from 'case'
import moment from 'moment'
import React from 'react'

import { CancelSubscriptionButton } from './CancelSubscriptionButton'
import { UsageSummary } from './UsageSummary'
import { LicenseSubscriptionFragment, LicenseSubscriptionType } from './schema'

export const UserSubscriptionsTable: React.FC<
  React.PropsWithChildren<Partial<TableProps<LicenseSubscriptionFragment>>>
> = props => (
  <Table<LicenseSubscriptionFragment> rowKey={'id'} pagination={false} {...props}>
    <Table.Column dataIndex={['class', 'name']} title={'Course'} />
    <Table.Column dataIndex={'type'} title={'Type'} render={Case.title} />
    <Table.Column dataIndex={'size'} title={'Size'} render={Case.title} />
    <Table.Column
      dataIndex={'expiresAt'}
      title={'Expires'}
      render={expiresAt => (expiresAt ? moment(expiresAt).format(DATE_FORMATS.FULL) : 'Never')}
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'nextValidityCheck'}
      title={'Validity'}
      render={(nextCheck, record) => (
        <>
          <Body
            style={{ display: 'inline' }}
            weight={FontWeight.Bold}
            color={record.isValid ? '@green' : '@orange'}
          >
            {record.isValid ? 'Valid' : 'Invalid'}
          </Body>
        </>
      )}
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'usage'}
      title={'Usage'}
      render={(_, record) =>
        record.type === LicenseSubscriptionType.Individual ? (
          <UsageSummary usage={record.used || undefined} quota={record.quota || undefined} />
        ) : null
      }
    />
    <Table.Column<LicenseSubscriptionFragment>
      dataIndex={'id'}
      onCell={() => ({ style: { textAlign: 'right' } })}
      render={(_, record) =>
        record.type === LicenseSubscriptionType.StudentPay ? (
          <CancelSubscriptionButton subscriptionId={record.id}>
            <DeleteLink>Cancel &rarr;</DeleteLink>
          </CancelSubscriptionButton>
        ) : null
      }
    />
  </Table>
)
