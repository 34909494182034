import { getSegmentName } from '@thesisedu/feature-course-types'
import { styled, BodySmall, Body, BodyExtraSmall } from '@thesisedu/react'
import React from 'react'

import { Segment } from '../../types'

export interface DraggableOutlineItemPreviewProps {
  segment?: Segment
}
export function DraggableOutlineItemPreview({ segment }: DraggableOutlineItemPreviewProps) {
  if (segment) {
    return (
      <Container>
        <BodySmall color={'@text-color-secondary'}>
          {segment.config?.label || segment.type}
        </BodySmall>
        <Body>{getSegmentName(segment)}</Body>
        <HelpContainer>Press esc to cancel</HelpContainer>
      </Container>
    )
  } else {
    return <span />
  }
}

const Container = styled.div`
  border-radius: ${props => props.theme['@border-radius-base']};
  background: white;
  padding: ${props => props.theme['@padding-xs']} ${props => props.theme['@padding-sm']};
  box-shadow: ${props => props.theme['@shadow-medium']};
  border-color: ${props => props.theme['@border-color-base']};
  opacity: 0.75;
  position: relative;
  pointer-events: none;
  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
const HelpContainer = styled(BodyExtraSmall)`
  position: absolute;
  top: ${props => props.theme['@padding-xs']};
  right: ${props => props.theme['@padding-sm']};
  color: ${props => props.theme['@text-color-secondary']};
`
