import { LoadingOutlined } from '@ant-design/icons'
import { MutateHook } from '@thesisedu/feature'
import {
  DeepLinkHandler,
  DeepLinkHandlerProps,
  useSyncMutateCallback,
} from '@thesisedu/feature-react'
import { Block } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Error404 } from './Error404'

export function NotFoundPage(props: Partial<DeepLinkHandlerProps>) {
  const navigate = useNavigate()
  const callback = useSyncMutateCallback<NotFoundPageAccessDeniedHook>(
    'web:not-found-access-denied',
  )
  return (
    <DeepLinkHandler
      url={window.location.pathname}
      onFound={path => {
        const [pathWithoutHash, hashString = ''] = path.split('#')
        const [pathWithoutParams, pathString = ''] = pathWithoutHash.split('?')
        const pathParams = new URLSearchParams(pathString)
        const existingParams = new URLSearchParams(window.location.search)
        const combined = new URLSearchParams({
          ...Object.fromEntries(existingParams as any),
          ...Object.fromEntries(pathParams as any),
        })
        navigate(
          `${pathWithoutParams}${combined.toString() ? `?${combined.toString()}` : ''}${
            hashString ? `#${hashString}` : ''
          }`,
        )
      }}
      onAccessDenied={cleanPath => {
        const resultPath = callback('/403', { cleanPath })
        navigate(resultPath)
      }}
      renderLoading={() => (
        <Block marginTop={'@size-xl'}>
          <Result icon={<LoadingOutlined size={75} />} title={'Redirecting...'} />
        </Block>
      )}
      renderNotFound={() => <Error404 onBack={() => navigate('/')} />}
      {...props}
    />
  )
}

export type NotFoundPageAccessDeniedHook = MutateHook<
  'web:not-found-access-denied',
  string,
  { cleanPath: string }
>
