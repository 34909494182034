import { DateValue } from '@react-aria/calendar'
import { AriaDateRangePickerProps, useDateRangePicker } from '@react-aria/datepicker'
import { useObjectRef } from '@react-aria/utils'
import React from 'react'
import { DateRangePickerStateOptions, useDateRangePickerState } from 'react-stately'

import { BareDateField } from './BareDateField'
import { ClearButton } from './ClearButton'
import { DateFieldVariant, _DateFieldContainer, _variants } from './DateField'
import { RangeCalendar } from './RangeCalendar'
import { parse } from './parse'
import { Button, Popover, s } from '../..'
import { Calendar as CalendarIcon } from '../../../icons'
import { BareField, ExtendedBareFieldProps, getBareFieldProps } from '../Field/Field'

export interface RangeValue {
  start: string
  end: string
}
export interface DateRangePickerFieldProps
  extends Omit<DateRangePickerStateOptions<DateValue>, 'value' | 'onChange'>,
    Omit<AriaDateRangePickerProps<DateValue>, 'value' | 'onChange'>,
    ExtendedBareFieldProps {
  className?: string
  style?: React.CSSProperties
  size?: s.SharedVariants.Size.Type
  disabled?: boolean
  variant?: DateFieldVariant
  prefix?: React.ReactElement
  suffix?: React.ReactElement
  value?: RangeValue | null
  onChange?: (value: RangeValue | null) => void
}
function _DateRangePickerField(
  {
    className,
    style,
    size = s.SharedVariants.Size.defaultValue,
    disabled,
    variant,
    prefix,
    suffix,
    value,
    onChange,
    ...props
  }: DateRangePickerFieldProps,
  _ref: React.ForwardedRef<HTMLDivElement>,
) {
  const state = useDateRangePickerState({
    ...props,
    value: value
      ? {
          start: parse(value.start)!,
          end: parse(value.end)!,
        }
      : null,
    onChange: value => {
      onChange?.(
        value
          ? {
              start: value.start.toDate('UTC').toISOString(),
              end: value.end.toDate('UTC').toISOString(),
            }
          : null,
      )
    },
  })
  const ref = useObjectRef(_ref)
  const {
    labelProps,
    groupProps,
    startFieldProps,
    endFieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDateRangePicker(props, state, ref)
  const { bareFieldProps } = getBareFieldProps(props)

  return (
    <BareField {...bareFieldProps} style={style} {...labelProps}>
      <_DateFieldContainer
        {...groupProps}
        className={s.variants<typeof _variants>(
          className,
          {
            disabled,
            error: !!props.error,
            allArePlaceholders: !state.value?.start && !state.value?.end,
          },
          size,
          variant,
        )}
        style={style}
        space={'xs'}
        ref={ref}
      >
        {prefix}
        <BareDateField {...startFieldProps} style={{ marginRight: 0 }} />
        <span style={{ padding: '0 4px', color: 'var(--placeholder-color)' }}>-</span>
        <BareDateField {...endFieldProps} />
        {state.validationState === 'invalid' ? (
          <span aria-hidden={'true'}>Invalid Date</span>
        ) : null}
        <ClearButton value={value} onChange={onChange} {...props} />
        <Popover.Container>
          <Popover.Trigger>
            <Button {...buttonProps} size={size} icon={<CalendarIcon />} style={{ padding: 4 }} />
          </Popover.Trigger>
          <Popover.Content {...dialogProps} hideClose width={300}>
            <RangeCalendar {...calendarProps} />
          </Popover.Content>
        </Popover.Container>
        {suffix}
      </_DateFieldContainer>
    </BareField>
  )
}
export const DateRangePickerField = React.forwardRef(_DateRangePickerField)
