import { WebRoutesHook } from '@thesisedu/feature-web'
import React from 'react'
import { Route } from 'react-router-dom'

import { Student } from './Student'
import { StudentsReactFeature } from '../../StudentsReactFeature'

export default function (feature: StudentsReactFeature) {
  feature.hookManager.registerMutateHook<WebRoutesHook>('feature-web:routes', routes => {
    return [<Route path={'student/*'} key={'student'} element={<Student />} />, ...routes]
  })
}
