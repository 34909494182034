import { WidgetConverter } from './types'
import * as Legacy from '..'
import * as Nodes from '../../nodes'

interface LinkWidgetConfig {
  url: string | null
}

export default {
  weight: 0,
  label: 'link',
  identifier: 'Link',
  convert(value) {
    const url = value.config?.url
    if (url) {
      return [
        {
          id: value.id,
          type: 'LinkEmbed',
          url,
          version: 1,
        } satisfies Nodes.SerializedWidget<'LinkEmbed', LinkWidgetConfig & { version: 1 }>,
      ]
    } else return []
  },
} satisfies WidgetConverter<Legacy.Widget<LinkWidgetConfig>>
