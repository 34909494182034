import React from 'react'

import { SimpleList } from './SimpleList'
// @ts-ignore
import { ReactComponent as SolfegeDo } from '../../../../assets/sketch/sheet-music/solfege-do.svg'
// @ts-ignore
import { ReactComponent as SolfegeFa } from '../../../../assets/sketch/sheet-music/solfege-fa.svg'
// @ts-ignore
import { ReactComponent as SolfegeHiDo } from '../../../../assets/sketch/sheet-music/solfege-hi-do.svg'
// @ts-ignore
import { ReactComponent as SolfegeLa } from '../../../../assets/sketch/sheet-music/solfege-la.svg'
// @ts-ignore
import { ReactComponent as SolfegeMi } from '../../../../assets/sketch/sheet-music/solfege-mi.svg'
// @ts-ignore
import { ReactComponent as SolfegeRe } from '../../../../assets/sketch/sheet-music/solfege-re.svg'
// @ts-ignore
import { ReactComponent as SolfegeSol } from '../../../../assets/sketch/sheet-music/solfege-sol.svg'
// @ts-ignore
import { ReactComponent as SolfegeTi } from '../../../../assets/sketch/sheet-music/solfege-ti.svg'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function SimpleSolfegeContent(props: ModeContentsProps) {
  return (
    <SimpleList
      items={[
        SolfegeDo,
        SolfegeRe,
        SolfegeMi,
        SolfegeFa,
        SolfegeSol,
        SolfegeLa,
        SolfegeTi,
        SolfegeHiDo,
      ]}
      {...props}
    />
  )
}

export const SimpleSolfege: Mode = {
  id: 'SimpleSolfege',
  name: 'Solfege',
  icon: SolfegeDo,
  large: false,
  types: [KeyboardType.Note8],
  contents: SimpleSolfegeContent,
  setInstrument: 'solfege',
}
