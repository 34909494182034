import { Format } from '@thesisedu/feature-utils'
import { Form, Button, VSpaced, Modal } from '@thesisedu/ui'
import { Plus } from '@thesisedu/ui/icons'
import React from 'react'

import { useCreateAssignmentCategoryMutation } from '../queries/useCreateAssignmentCategoryMutation'
import { BasicAssignmentCategoryFragment } from '../schema'

interface FormValues {
  name: string
  gradeWeight: number
}
export interface AddCategoryModalProps {
  visible?: boolean
  onCancel: () => void
  classId: string
  totalPoints: number
  onCreated?: (category: BasicAssignmentCategoryFragment) => void
}
export function AddCategoryModal({
  visible,
  onCancel,
  classId,
  totalPoints,
  onCreated,
}: AddCategoryModalProps) {
  const [create, { loading }] = useCreateAssignmentCategoryMutation({
    onCompleted: data => {
      if (onCreated) {
        onCreated(data.createAssignmentCategory.assignmentCategory)
      }
      onCancel()
    },
    update(cache, { data }) {
      const classId = data?.createAssignmentCategory.assignmentCategory.classId
      if (classId) {
        cache.evict({
          id: cache.identify({ __typename: 'Class', id: classId }),
          fieldName: 'assignmentCategories',
        })
      }
    },
  })
  const [currentPointValue, setCurrentPointValue] = React.useState(0)
  const form = Form.useForm<FormValues>()
  React.useEffect(() => {
    if (visible) {
      form.reset()
    }
  }, [visible])

  form.watch(values => {
    if (values.gradeWeight != null) {
      setCurrentPointValue(values.gradeWeight || 0)
    }
  })

  return (
    <Form.Modal
      form={form}
      title={'Create Assignment Category'}
      visible={visible}
      onVisibleChange={v => {
        if (!v) onCancel()
      }}
      onFinish={async values => {
        await create({
          variables: {
            input: {
              id: classId,
              category: {
                name: values.name,
                gradeWeight: values.gradeWeight / 100,
              },
            },
          },
        })
      }}
    >
      <VSpaced space={'m'}>
        <Form.TextField
          name={'name'}
          label={'Name'}
          required
          placeholder={'Category'}
          autoComplete={'off'}
        />
        <Form.NumberField
          name={'gradeWeight'}
          label={'Points'}
          required
          description={`out of ${Format.plural('point', totalPoints + currentPointValue)}`}
          placeholder={'20'}
        />
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Create Category'} icon={<Plus />} loading={loading} />
      </Modal.Footer>
    </Form.Modal>
  )
}

export interface AddCategoryButtonProps
  extends Omit<AddCategoryModalProps, 'visible' | 'onCancel'> {}
export function AddCategoryButton(props: AddCategoryButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <AddCategoryModal {...props} visible={visible} onCancel={() => setVisible(false)} />
      <Button variant={'chromeless'} icon={<Plus />} onPress={() => setVisible(true)}>
        Add Category
      </Button>
    </>
  )
}
