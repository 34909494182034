import {
  SegmentCompletionHostProps,
  SegmentCompletionWrapper,
} from '@thesisedu/feature-courses-react'
import { styled, Text, VSpaced } from '@thesisedu/ui'
import React from 'react'

// @ts-ignore
import bellC from '../../assets/sketch/branding/bell/c.svg'

export function SegmentCompletionHost({ onMarkComplete }: SegmentCompletionHostProps) {
  React.useEffect(() => {
    onMarkComplete()
  }, [])
  return (
    <SegmentCompletionWrapper>
      <VSpaced align={'center'} space={'m'}>
        <BellImage alt={'C Bell'} src={bellC} />
        <Text level={'h1'} color={'background'}>
          Great work!
        </Text>
      </VSpaced>
    </SegmentCompletionWrapper>
  )
}

const BellImage = styled.img`
  height: 125px;
  transform: rotate(-9deg);
  width: auto;
`
