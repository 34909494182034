import { message } from '@thesisedu/react'
import React from 'react'

import { error } from '../log'
import { useErrorService } from '../useFeatureRoot'

type CallbackFn = (...args: any[]) => Promise<any>
export interface MutationLikeOpts {
  errorMessage?: string
}
export interface MutationLikeResult {
  loading: boolean
  error?: any
}
export type MutationLike<T extends CallbackFn> = [
  (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>> | undefined>,
  MutationLikeResult,
]

export function useMutationLike<T extends CallbackFn>(
  callback: T,
  { errorMessage }: MutationLikeOpts = {},
): MutationLike<T> {
  const errorService = useErrorService()
  const [result, setResult] = React.useState<MutationLikeResult>({ loading: false })
  return [
    (async (...args) => {
      setResult({ loading: true })
      try {
        const returnValue = await callback(...args)
        setResult({ loading: false })
        return returnValue
      } catch (err: any) {
        error('error in mutation-like callback')
        error(err)
        errorService.reportError(err)
        setResult({ loading: false, error: err })
        if (errorMessage) {
          message.error(errorMessage)
        }
      }
    }) as T,
    result,
  ]
}
