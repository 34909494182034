import React from 'react'

import { MinusCircle } from '../../icons'
import { EmptyMessage } from '../ant/Empty'
import { Color } from '../typography'

export interface NotAllowedProps {
  message: string
  children?: React.ReactElement | React.ReactElement[]
}
export function NotAllowed({ message, children }: NotAllowedProps) {
  return (
    <EmptyMessage>
      <EmptyMessage.Icon>
        <Color color={'@red'}>
          <MinusCircle />
        </Color>
      </EmptyMessage.Icon>
      <EmptyMessage.Title>{message}</EmptyMessage.Title>
      {children}
    </EmptyMessage>
  )
}
