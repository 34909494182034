import { MutateSyncAssignmentRefetchQueries } from '@thesisedu/feature-class-sync-react'
import CoursesReactFeature from '@thesisedu/feature-courses-react'

export default function (feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<MutateSyncAssignmentRefetchQueries>(
    'class-sync-react:sync-assignment-refetch',
    async queries => {
      return [...queries, 'assignmentForSegment']
    },
  )
}
