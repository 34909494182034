import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Body, BodyLarge, Button } from '@thesisedu/react'
import { Copy } from '@thesisedu/react/icons'
import { AntIconWrapper, Block, Callout, Modal } from '@thesisedu/web'
import React from 'react'

import { AssignmentTemplateSelectButton } from './AssignmentTemplateSelectButton'
import { AssignmentTemplateFragment, useUpdateAssignmentTemplateMutation } from '../schema'

export interface CopyFromTemplateButtonProps {
  assignmentTemplateId: string
}
export function CopyFromTemplateButton({ assignmentTemplateId }: CopyFromTemplateButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<string | undefined>()
  const selectedTemplate = React.useRef<AssignmentTemplateFragment>()
  const [update, { loading }] = useUpdateAssignmentTemplateMutation({
    onError: onMutationError('There was an error copying the assignment settings.'),
    onCompleted: () => {
      setVisible(false)
    },
  })
  React.useEffect(() => {
    if (!visible) {
      selectedTemplate.current = undefined
      setSelectedTemplateId(undefined)
    }
  }, [visible])

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={'Copy from Assignment Template'}
        width={700}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            disabled={!selectedTemplateId}
            loading={loading}
            onClick={() => {
              if (selectedTemplate.current) {
                update({
                  variables: {
                    input: {
                      id: assignmentTemplateId,
                      patch: {
                        configuration: selectedTemplate.current.configuration,
                        defaults: selectedTemplate.current.defaults,
                      },
                    },
                  },
                })
              }
            }}
          >
            Copy from Template
          </Button>,
        ]}
      >
        <Block marginTop={'@size-m'} marginBottom={'@size-s'}>
          <Callout type={'warning'}>
            <Body isBlock>
              <strong>This is a potentially destructive action.</strong>
            </Body>
            <Body>
              Once you select another template to restore from, it will replace all the content of
              this template with the content from the selected template.{' '}
              <strong>
                This means you will lose all changes from the current template, and this is
                irreversible.
              </strong>
            </Body>
          </Callout>
        </Block>
        <BodyLarge isBlock>
          Use this feature if you would like to quickly copy settings from another assignment.
        </BodyLarge>
        <Body>
          To get started, find another assignment template from below, and then click{' '}
          <strong>Copy from Template</strong>
        </Body>
        <Block marginTop={'@size-s'}>
          <AssignmentTemplateSelectButton
            value={selectedTemplateId}
            onChange={(id, fragment) => {
              selectedTemplate.current = fragment
              setSelectedTemplateId(id)
            }}
          />
        </Block>
      </Modal>
      <Button icon={<AntIconWrapper children={<Copy />} />} onClick={() => setVisible(true)}>
        Copy from Existing Template
      </Button>
    </>
  )
}
