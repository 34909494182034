import { Dragger as DraggerIcon } from '@sammarks/icons'
import { styled } from '@thesisedu/web'
import React from 'react'

const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  padding: 2px 5px;
  color: ${props => props.theme['@gray-6']};
`

export function Dragger(props: any) {
  return (
    <Container {...props}>
      <DraggerIcon />
    </Container>
  )
}
