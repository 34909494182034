import { WidgetResource } from '@thesisedu/feature-widgets-react'

import { WidgetTemplatesPlugin } from './WidgetTemplatesPlugin'
import { WidgetTemplatesReactFeature } from '../../../WidgetTemplatesReactFeature'

export default function (feature: WidgetTemplatesReactFeature) {
  feature.resources.addResource<WidgetResource>({
    type: 'Widget',
    identifier: 'WidgetTemplate',
    plugin: WidgetTemplatesPlugin,
  })
}
