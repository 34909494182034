import { useAssignmentQueryVariables, FullAssignment } from '@thesisedu/feature-assignments-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'

import { useAssignmentForSegmentQuery } from '../../schema'

export const useAssignmentForSegment = (segmentId?: string) => {
  const classId = useSelectedClassId(false)

  const assignmentResult = useAssignmentForSegmentQuery({
    variables: {
      classId: classId || '',
      segmentId: segmentId || '',
      ...useAssignmentQueryVariables(classId || undefined),
    },
    skip: !segmentId,
  })

  const assignment = (
    assignmentResult.data?.node?.__typename === 'Class'
      ? assignmentResult.data.node.assignmentForSegment
      : undefined
  ) as FullAssignment | undefined

  return {
    assignment,
    assignmentResult,
    classId: classId || '',
    loading: assignmentResult.loading,
    error: assignmentResult.error,
  }
}
