/** This filters out all null values, shallow. */
export function filterNulls<T extends Record<string, any>>(obj: T): T {
  const result: Record<string, any> = {}
  for (const key of Object.keys(obj)) {
    if (obj[key] !== null) {
      result[key] = obj[key]
    }
  }

  return result as T
}
