import { styled, s, Block, Text } from '@thesisedu/ui'
import { Barcode } from '@thesisedu/ui/icons'
import React from 'react'

import { useOptions } from '../../options'
import { ClassId } from '../../teacher/ClassId'
import { ImportStudentModeResource, MainContentComponentProps } from '../../types'

function AddByCode({ class: cls }: MainContentComponentProps) {
  const options = useOptions()
  return (
    <>
      <Block bottom={'s'}>
        <Text color={'secondary'}>
          Your students can join this class by typing in the class code in their {options.name}{' '}
          account.
        </Text>
      </Block>
      <ClassId code={cls.classId || undefined} noWrap>
        <CourseCodeContainer>
          <div>
            <Text color={'secondary'}>Your Class Code is</Text>
            <Text font={'code'} level={'l'} weight={'bold'}>
              {cls.classId}
            </Text>
          </div>
          <CourseCodeRight>
            <Text color={'primary'}>Click to Copy</Text>
          </CourseCodeRight>
        </CourseCodeContainer>
      </ClassId>
    </>
  )
}

const CourseCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`
const CourseCodeRight = styled.div`
  margin-left: auto;
  padding-left: ${s.size('s')};
`

export const ClassCode: ImportStudentModeResource = {
  type: 'ImportStudentMode',
  identifier: 'code',
  title: 'Invite via Class Code',
  MainContentComponent: AddByCode,
  filter: cls => !!cls.classId,
  icon: <Barcode />,
  weight: 0,
}
