import { Shell } from '@thesisedu/feature-web'
import { BodyLarge, BlockSpin, Row } from '@thesisedu/web'
import { Empty, Col } from 'antd'
import React from 'react'

import { CreateClassModal } from './CreateClassModal'
import { CreateCoursePreview } from './CreateCoursePreview'
import { CourseFragment, useListCoursesQuery } from '../schema'

export function Create() {
  const { data, loading } = useListCoursesQuery({ variables: { first: 100 } })
  const courses = data?.courses.edges.map(edge => edge.node) || []
  const byocCourse = courses.find(course => course.isByoc)
  const [creatingCourse, setCreatingCourse] = React.useState<CourseFragment | undefined>(undefined)
  Shell.useShellProps('CreateClass', { smallWidth: true }, [])
  if (loading) {
    return <BlockSpin />
  } else if (courses.length) {
    return (
      <Row>
        <CreateClassModal
          onClose={() => setCreatingCourse(undefined)}
          courseId={creatingCourse?.id}
          courseName={creatingCourse?.label || creatingCourse?.name}
        />
        <Col xs={16}>
          <BodyLarge isBlock color={'@text-color-secondary'}>
            Choose one of our standards-based courses below to preview &amp; use
          </BodyLarge>
          <Row>
            {courses
              .filter(c => !c.isByoc)
              .map(course => (
                <Col xs={12} key={course.id}>
                  <CreateCoursePreview
                    key={course.id}
                    course={course}
                    onCreate={() => setCreatingCourse(course)}
                    showLinks
                  />
                </Col>
              ))}
          </Row>
        </Col>
        {byocCourse ? (
          <Col xs={8}>
            <BodyLarge isBlock color={'@text-color-secondary'} style={{ textAlign: 'right' }}>
              ...or build your own course.
            </BodyLarge>
            <CreateCoursePreview
              key={byocCourse.id}
              course={byocCourse}
              onCreate={() => setCreatingCourse(byocCourse)}
            />
          </Col>
        ) : null}
      </Row>
    )
  } else {
    return <Empty description={"It doesn't look like you have access to any courses."} />
  }
}
