import { Block } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { SheetMusicViewer, SheetMusicViewerProps } from './SheetMusicViewer'

function ErrorFallback() {
  return (
    <Block marginTop={'@size-s'} marginBottom={'@size-s'}>
      <Result
        title={'Invalid Content'}
        subTitle={'It looks like the sheet music you specified is not valid.'}
        status={'error'}
      />
    </Block>
  )
}

export function SafeSheetMusicViewer(props: SheetMusicViewerProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[props.abc]}>
      <SheetMusicViewer empty={<ErrorFallback />} {...props} />
    </ErrorBoundary>
  )
}
