import { useFreshRef } from '@thesisedu/feature-react'
import { EditorValue } from '@thesisedu/feature-widgets-core'
import { Form$ } from '@thesisedu/ui'
import React from 'react'

import { Editor, EditorProps } from './Editor'

export interface EditorFieldImperativeRef {
  reloadValue: () => void
}
interface EditorFieldInnerProps extends EditorProps {
  value?: EditorValue
  onChange?: (value?: EditorValue) => void
}
function _EditorFieldInner(
  { value, onChange, ...rest }: EditorFieldInnerProps,
  ref: React.ForwardedRef<EditorFieldImperativeRef>,
) {
  const [defaultValue, setDefaultValue] = React.useState(value)
  const [defaultValueKey, setDefaultValueKey] = React.useState(0)
  const valueRef = useFreshRef(value)
  React.useImperativeHandle(
    ref,
    () => ({
      reloadValue() {
        setDefaultValue(valueRef.current)
        setDefaultValueKey(key => key + 1)
      },
    }),
    [],
  )

  return (
    <Editor
      {...rest}
      defaultValue={defaultValue}
      defaultValueKey={defaultValueKey.toString()}
      onChange={onChange}
    />
  )
}
const EditorFieldInner = React.forwardRef(_EditorFieldInner)

export type EditorFieldProps<
  TFieldValues extends Form$.FieldValues,
  FieldName extends Form$.FieldPath<TFieldValues>,
> = Form$.ConnectedFormFieldProps<EditorProps, TFieldValues, FieldName>
function _EditorField<
  TFieldValues extends Form$.FieldValues,
  FieldName extends Form$.FieldPath<TFieldValues>,
>(
  { onBlur, ...props }: EditorFieldProps<TFieldValues, FieldName>,
  ref: React.ForwardedRef<EditorFieldImperativeRef>,
) {
  return (
    <Form$.FormFieldConnector {...props} onBlur={onBlur}>
      <EditorFieldInner id={props.name} {...props} ref={ref} />
    </Form$.FormFieldConnector>
  )
}

export const EditorField: Form$.ExplicitFieldType<EditorProps, EditorFieldImperativeRef> =
  React.forwardRef(_EditorField)
