import { LoadingOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { BodyLarge, styled } from '@thesisedu/web'
import { defaultErrorHandler } from '@thesisedu/web/dist/useOauthPopup'
import React from 'react'

import { useTestCanvasHealthMutation } from '../schema'

const TEST_DEBOUNCE = 1000
export interface HealthStatus {
  healthy: boolean
  error?: string
  errorDescription?: string
}
export interface CredentialHealthCheckProps {
  consumerKey?: string
  consumerSecret?: string
  url?: string
  onHealthChange: (status: HealthStatus) => void
}
export function CredentialHealthCheck({
  consumerKey,
  consumerSecret,
  url,
  onHealthChange,
}: CredentialHealthCheckProps) {
  const [healthStatus, setHealthStatus] = React.useState<HealthStatus | undefined>(undefined)
  React.useEffect(() => {
    if (healthStatus) {
      onHealthChange(healthStatus)
    }
  }, [healthStatus])
  const [testHealth, { loading: _loading }] = useTestCanvasHealthMutation({
    onCompleted: data => {
      const result = data.testCanvasHealth
      setHealthStatus({
        healthy: result.healthy,
        error: result.error || undefined,
        errorDescription: result.errorDescription || undefined,
      })
    },
    onError: () => {
      setHealthStatus({
        healthy: false,
      })
    },
  })
  const testTimeoutRef = React.useRef<any>()
  React.useEffect(() => {
    clearTimeout(testTimeoutRef.current)
    if (consumerKey && consumerSecret && url && url.includes('https://')) {
      testTimeoutRef.current = setTimeout(() => {
        testHealth({
          variables: {
            input: {
              redirectUri: `${window.location.origin}`,
              consumerKey,
              url,
            },
          },
        })
      }, TEST_DEBOUNCE)
    }
  }, [consumerKey, consumerSecret, url])

  if (consumerKey && consumerSecret && url) {
    const loading = _loading || !healthStatus
    return (
      <Container loading={loading} healthy={!!healthStatus?.healthy}>
        <div className={'status-colored icon'}>
          {loading ? (
            <LoadingOutlined />
          ) : healthStatus?.healthy ? (
            <CheckCircleOutlined />
          ) : (
            <ExclamationCircleOutlined />
          )}
        </div>
        <BodyLarge>
          {loading ? (
            'Testing information...'
          ) : healthStatus?.healthy ? (
            <>
              <strong>Everything checks out.</strong> These credentials appear to be valid. When
              you're ready, click <strong>Save and Continue</strong> below.
            </>
          ) : (
            <>
              <strong>These credentials might not work.</strong> We're running into some issues when
              using these credentials. Double-check them and make sure you've followed our support
              article.{' '}
              {healthStatus?.error ? (
                <a
                  onClick={e => {
                    e.preventDefault()
                    defaultErrorHandler({
                      error: healthStatus.error!,
                      description: healthStatus?.errorDescription || undefined,
                    })
                  }}
                  className={'status-colored'}
                >
                  Click here to see more information about the issue we're seeing.
                </a>
              ) : null}
            </>
          )}
        </BodyLarge>
      </Container>
    )
  } else {
    return null
  }
}

const Container = styled.div<{ loading?: boolean; healthy: boolean }>`
  transition: background 0.25s linear;
  background: ${props =>
    props.theme[props.loading ? '@gray-1' : props.healthy ? '@green-light' : '@orange-light']};
  border-radius: ${props => props.theme['@border-radius-base']};
  margin: ${props => props.theme['@size-m']} 0;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  padding: ${props => props.theme['@padding-lg']};
  .status-colored {
    transition: color 0.25s linear;
    color: ${props =>
      props.theme[props.loading ? '@text-color-base' : props.healthy ? '@green' : '@orange']};
  }
  .icon {
    font-size: ${props => props.theme['@size-l']};
    margin-right: ${props => props.theme['@size-s']};
    display: block;
  }
`
