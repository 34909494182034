import { cloneDeep } from '@apollo/client/utilities'
import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { set } from 'lodash'
import React from 'react'

import { SubscriptionsTable } from './SubscriptionsTable'
import {
  LicenseSubscriptionFragment,
  UserSubscriptionsDocument,
  UserSubscriptionsQuery,
  UserSubscriptionsQueryVariables,
} from './schema'

export interface AdminUserSubscriptionsTableProps {
  userId: string
}
export function AdminUserSubscriptionsTable({ userId }: AdminUserSubscriptionsTableProps) {
  return (
    <InfiniteQuery<
      LicenseSubscriptionFragment,
      UserSubscriptionsQuery,
      UserSubscriptionsQueryVariables
    >
      document={UserSubscriptionsDocument}
      getResult={d => (d?.node?.__typename === 'User' ? d.node.licenseSubscriptions : undefined)}
      setResult={(previous, result) =>
        set(cloneDeep(previous), 'node.licenseSubscriptions', result)
      }
      variables={{
        userId,
      }}
      children={({ data }) => (
        <SubscriptionsTable
          dataSource={
            data?.node?.__typename === 'User'
              ? data.node.licenseSubscriptions.edges.map(edge => edge.node)
              : []
          }
        />
      )}
    />
  )
}
