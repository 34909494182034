import {
  isMatchClientConfig,
  MatchClientConfig,
  matchToClientConfig,
} from '@thesisedu/feature-assignments-core'
import { Form } from '@thesisedu/react'
import React from 'react'

import { useSubmissionData } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { useAssignmentSeed } from '../../../useAssignmentSeed'
import { DragDropDropdownField } from '../DragDropDropdownField'
import { DragDropField, DragDropDropdownValidator } from '../DragDropField'

export function GroupContent(props: QuestionProps<MatchClientConfig>) {
  const submissionData = useSubmissionData(props.question.id)
  const seed = useAssignmentSeed(props.question.id)
  const disabled = props.disabled
  const useDropdown = props.question.config?.useDropdown
  const config = props.question.config
  if (!config) return null
  const clientConfig = isMatchClientConfig(config) ? config : matchToClientConfig(config, seed)
  if (!clientConfig) return null
  const { sources, destinations } = clientConfig
  return (
    <Form.Item
      name={props.question.id}
      initialValue={submissionData}
      // eslint-disable-next-line react/jsx-no-bind
      validate={DragDropDropdownValidator.bind(null, sources, destinations)}
    >
      {useDropdown ? (
        <DragDropDropdownField
          labelField={'source'}
          sources={sources}
          destinations={destinations}
          disabled={disabled}
        />
      ) : (
        <DragDropField
          sources={sources}
          destinations={destinations}
          disabled={disabled}
          allowMultipleInDestinations
        />
      )}
    </Form.Item>
  )
}

export { isQuestionComplete } from '../Match/MatchContent'
