import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'
import { message } from 'antd'

import {
  UpdateUserMutation,
  UserFragment,
  UserFragmentDoc,
  useUpdateUserMutation as useMutation,
} from '../schema'

export const useUpdateUserMutation: typeof useMutation = opts => {
  return useMutation({
    onError: onMutationError(
      'There was an error updating that user.',
      ['NAME_TAKEN_ERROR'],
      code => {
        if (code === 'NAME_TAKEN_ERROR') {
          message.error('That username or email address is already taken.')
        }
      },
    ),
    update: updateMutation<UpdateUserMutation, UserFragment>({
      path: 'updateUser.user',
      fragment: UserFragmentDoc,
    }),
    ...opts,
  })
}
