import { SiteContentHook } from '@thesisedu/feature-sites-web'
import React from 'react'

import LicensesReactFeature from '../LicensesReactFeature'
import { SiteLicenseForm } from '../SiteLicenseForm'

export default function (feature: LicensesReactFeature) {
  feature.hookManager.registerMutateHook<SiteContentHook>(
    'sites-web:site-content',
    (content, { siteId }) => {
      return [...content, <SiteLicenseForm siteId={siteId} key={'license'} />]
    },
  )
}
