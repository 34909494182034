import { toGlobalId } from '@thesisedu/feature-utils'
import {
  Body,
  Button,
  ButtonProps,
  HSpaced,
  LoadingIndicator,
  Modal,
  Space,
} from '@thesisedu/react'
import React from 'react'

import { SegmentSelectField, SegmentSelectFieldProps } from './SegmentSelectField'
import { useBuiltSegmentQuery } from '../schema'

export interface SegmentSelectButtonProps extends SegmentSelectFieldProps {
  buttonProps?: Partial<ButtonProps>
}
export function SegmentSelectButton({ buttonProps, ...rest }: SegmentSelectButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const { data } = useBuiltSegmentQuery({
    variables: {
      id: rest.value ? toGlobalId('Segment', rest.value) : '',
    },
    skip: !rest.value,
  })
  const segment = data?.node?.__typename === 'Segment' ? data.node.built : undefined
  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={'Select a Segment'}
        width={800}
        style={{ maxWidth: '90vw' }}
      >
        <SegmentSelectField {...rest} />
      </Modal>
      <HSpaced>
        <Body>{rest.value ? segment ? segment.name : <LoadingIndicator /> : 'No Segment'}</Body>
        <Space />
        <Button
          children={'Select Segment'}
          onClick={e => {
            e.preventDefault()
            setVisible(true)
          }}
        />
      </HSpaced>
    </>
  )
}
