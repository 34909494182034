import { InfiniteQuery } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { CourseViewsTable } from './CourseViewsTable'
import {
  CourseViewFragment,
  CourseViewsDocument,
  CourseViewsQuery,
  CourseViewsQueryVariables,
} from '../schema'

export function CourseViewsInfiniteQuery() {
  return (
    <InfiniteQuery<CourseViewFragment, CourseViewsQuery, CourseViewsQueryVariables>
      document={CourseViewsDocument}
      resultPath={'courseViews'}
      children={({ data }) => {
        const courseViews = data?.courseViews.edges.map(edge => edge.node) ?? []
        return <CourseViewsTable courseViews={courseViews} />
      }}
    />
  )
}
