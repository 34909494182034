import { ClassSummaryPopover } from '@thesisedu/feature-assignments-react'
import { CourseTeacherOutlineHeaderActionsHook } from '@thesisedu/feature-courses-react'
import React from 'react'

import { CourseAssignmentsReactFeature } from '../CourseAssignmentsReactFeature'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<CourseTeacherOutlineHeaderActionsHook>(
    'courses-react:teacher-outline-header-actions',
    (items, context) => {
      return [<ClassSummaryPopover classId={context.cls.id} key={'grading-summary'} />, ...items]
    },
  )
}
