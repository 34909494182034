import { ClassTaskSummaryProviderResource } from '@thesisedu/feature-classes-react'
import { ReactFeature } from '@thesisedu/feature-react'

import { AssignmentsDueSoonClassTask } from './AssignmentsDueSoonClassTask'
import { UngradedAssignmentsClassTask } from './UngradedAssignmentsClassTask'

export default function addClassTasks(feature: ReactFeature) {
  feature.resources.addResource<ClassTaskSummaryProviderResource>({
    type: 'ClassTaskSummaryProvider',
    identifier: 'UngradedAssignmentsClassTask',
    provider: UngradedAssignmentsClassTask,
  })
  feature.resources.addResource<ClassTaskSummaryProviderResource>({
    type: 'ClassTaskSummaryProvider',
    identifier: 'AssignmentsDueSoonClassTask',
    provider: AssignmentsDueSoonClassTask,
  })
}
