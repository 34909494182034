import { EditorValue } from './types'

export function isEmpty(value?: EditorValue | null, minChildren = 1): boolean {
  if (!value) return true
  // Oftentimes we will just have a root with an empty paragraph.
  const filteredChildren = value.root?.children.filter(
    child => child.type !== 'paragraph' || !!(child as any).children?.length,
  )
  if (!filteredChildren || filteredChildren.length <= minChildren - 1) return true
  return false
}
