import React from 'react'

export interface AudioStreamLevelsOpts {
  stream: MediaStream
  /** Called with the audio levels on regular intervals. Level is a number from 0 to 1. */
  onLevel: (level: number) => void
}
export function useAudioStreamLevels({ stream, onLevel }: AudioStreamLevelsOpts) {
  React.useEffect(() => {
    let mounted = true
    if (!stream.getAudioTracks().length) return
    const audioContext = new AudioContext()
    const source = audioContext.createMediaStreamSource(stream)
    const analyser = audioContext.createAnalyser()

    // Connect the source to the analyser
    source.connect(analyser)

    // Set the parameters
    analyser.fftSize = 32
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const draw = () => {
      // Stop processing if we're unmounted.
      if (!mounted) return

      // Request an animation frame for continuous updates.
      requestAnimationFrame(draw)

      // Get the current data and calculate the level.
      analyser.getByteFrequencyData(dataArray)
      const total = dataArray.reduce((acc, val) => acc + val, 0)
      const average = total / bufferLength
      const scale = average / 255

      // Report the level
      onLevel(scale)
    }

    // Start drawing...
    draw()

    return () => {
      mounted = false
      audioContext.close()
    }
  }, [])
}
