export function setDragImage(dataTransfer: DataTransfer, draggableBlockElement: HTMLElement) {
  const { transform } = draggableBlockElement.style

  // Remove dragImage borders
  draggableBlockElement.style.transform = 'translateZ(0)'
  dataTransfer.setDragImage(draggableBlockElement, 0, 0)

  setTimeout(() => {
    draggableBlockElement.style.transform = transform
  })
}
