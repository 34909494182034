import { useNavigate } from '@thesisedu/react'
import { Copy } from '@thesisedu/ui/icons'
import React from 'react'

import { CreateSavedReportButton } from './CreateSavedReportButton'
import { RunReportOpts } from '../reporting/types'
import { CreateSavedReportMutation } from '../schema'

interface SaveAsReportButtonProps {
  report: RunReportOpts
}

export function SaveAsReportButton({ report }: SaveAsReportButtonProps) {
  const navigate = useNavigate()
  const onCompleted = (data: CreateSavedReportMutation) => {
    const savedReport = data.createSavedReport.savedReport
    navigate(`../${savedReport.id}`)
  }
  return (
    <CreateSavedReportButton
      report={report}
      onCompleted={onCompleted}
      children={'Save As'}
      icon={<Copy />}
    />
  )
}
