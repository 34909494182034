import { BlockSpin, NotFound } from '@thesisedu/web'
import React from 'react'

import { UnpackedReportPage } from './UnpackedReportPage'
import { CreateSavedReportButton } from '../../saved/CreateSavedReportButton'
import { useUnpackedReport } from '../config/storage'

export interface ReportPageProps {
  /** Either the ID of a saved report, or the encoded report options. */
  report: string
}
export function ReportPage({ report }: ReportPageProps) {
  const { loading, opts: loadedOpts } = useUnpackedReport(report)

  if (loadedOpts && !loading) {
    return (
      <UnpackedReportPage
        report={loadedOpts}
        onReportPacked={packed => {
          const segments = window.location.pathname.toString().split('/')
          segments.pop()
          window.history.replaceState(
            '',
            '',
            window.location.origin + [...segments, packed].join('/'),
          )
        }}
        ConfigurationRight={CreateSavedReportButton}
      />
    )
  } else if (loading) {
    return <BlockSpin />
  } else {
    return <NotFound description={'That report could not be found or is invalid.'} />
  }
}
