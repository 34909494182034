import React from 'react'

import { ClassSyncRosterActionFragment } from '../../schema'

export interface RosteringContextValue {
  providerStudents: NonNullable<Required<ClassSyncRosterActionFragment>['providerStudent']>[]
}
export const RosteringContext = React.createContext<RosteringContextValue | undefined>(undefined)

export function useRosteringContext(): RosteringContextValue | undefined
export function useRosteringContext(require: false): RosteringContextValue | undefined
export function useRosteringContext(require: true): RosteringContextValue
export function useRosteringContext(require?: boolean): RosteringContextValue | undefined {
  const context = React.useContext(RosteringContext)
  if (!context && require) {
    throw new Error('RosteringContext is required, yet not provided.')
  }
  return context
}
