import { Feature } from '@thesisedu/feature'
import React from 'react'

import { FeatureReactRoot } from '.'
import { isInNode } from './helpers'
import { ReactFeatureDependencies } from './types'

export type DevToolsInstaller = (root: FeatureReactRoot<any>) => void
export type RootDevToolsInstaller = (root: FeatureReactRoot<any>) => React.ReactPortal

export abstract class ReactFeature extends Feature {
  constructor(options: object, deps: ReactFeatureDependencies) {
    super(options, deps)
    if (!isInNode()) {
      this.reactResources()
    }
  }

  public getDevToolsInstaller(): null | Promise<DevToolsInstaller> {
    return null
  }

  public reactResources() {
    // Nothing in here...
  }
}
