import React from 'react'

import { OutlineEmptyView, OutlineEmptyViewProps } from './OutlineEmptyView'
import { OutlineList, OutlineListImperativeHandle, OutlineListProps } from './OutlineList'
import { OutlineWindowList, OutlineWindowListProps } from './OutlineWindowList'
import { TopicsList, TopicsListProps } from './TopicsList'
import { OutlineData } from './useOutlineData'
import { GroupSegment } from '../types'

interface ListProps extends Omit<OutlineListProps, 'items' | 'selectedTerm'> {
  data: OutlineData
}
function List({ data, ...rest }: ListProps, ref: React.ForwardedRef<OutlineListImperativeHandle>) {
  return (
    <OutlineList
      items={data.items}
      selectedTerm={data.selectedTerm as GroupSegment}
      {...rest}
      ref={ref}
    />
  )
}

interface WindowListProps extends Omit<OutlineWindowListProps, 'items' | 'selectedTerm'> {
  data: OutlineData
}
function WindowList(
  { data, ...rest }: WindowListProps,
  ref: React.ForwardedRef<OutlineListImperativeHandle>,
) {
  return (
    <OutlineWindowList
      items={data.items}
      selectedTerm={data.selectedTerm as GroupSegment}
      ref={ref}
      {...rest}
    />
  )
}

interface TopicsProps extends Omit<TopicsListProps, 'items' | 'selectedTerm'> {
  data: OutlineData
}
function Topics({ data, ...rest }: TopicsProps) {
  return (
    <TopicsList items={data.items} selectedTerm={data.selectedTerm as GroupSegment} {...rest} />
  )
}

interface EmptyProps extends Omit<OutlineEmptyViewProps, 'termId'> {
  data: OutlineData
}
function Empty({ data, ...rest }: EmptyProps) {
  if (data.selectedTerm) {
    return (
      <OutlineEmptyView
        {...rest}
        termId={data.selectedTerm.id}
        topics={data.selectedTerm.childSegments}
      />
    )
  } else return null
}

export const OutlineParts = {
  List: React.forwardRef(List),
  WindowList: React.forwardRef(WindowList),
  Topics,
  Empty,
}
