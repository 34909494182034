import { styled } from '@thesisedu/web'
import React from 'react'

import { FullAssignment } from './types'
import { useGradingModalVisibilityContext } from '../contexts/GradingModalVisibilityContext'
import { useAssignmentQuery } from '../schema'

const isFull = (assignment: FullAssignment | { id: string }): assignment is FullAssignment => {
  return !!(assignment as FullAssignment).submissions?.edges
}

export interface GradeAssignmentLinkProps {
  assignment: FullAssignment | { id: string }
  defaultTab?: string
  onClick?: () => void
}
export function GradeAssignmentLink({
  assignment,
  onClick,
  defaultTab = 'all-students',
  children,
}: React.PropsWithChildren<GradeAssignmentLinkProps>) {
  const { setGradingAssignment } = useGradingModalVisibilityContext(false) || {}
  const [loadAssignmentId, setLoadAssignmentId] = React.useState<string | undefined>(undefined)
  const { data, loading } = useAssignmentQuery({
    variables: {
      id: loadAssignmentId || '',
    },
    skip: !loadAssignmentId,
  })
  const loadedAssignment = data?.node?.__typename === 'Assignment' ? data.node : undefined
  React.useEffect(() => {
    if (loadedAssignment?.id && setGradingAssignment) {
      setGradingAssignment(loadedAssignment, { defaultTab })
      setLoadAssignmentId(undefined)
    }
  }, [loadedAssignment?.id])

  if (setGradingAssignment) {
    return (
      <Link
        onClick={async e => {
          e.preventDefault()
          if (onClick) {
            onClick()
          }
          if (!loading) {
            if (isFull(assignment)) {
              setGradingAssignment(assignment, { defaultTab })
            } else {
              setLoadAssignmentId(assignment.id)
            }
          }
        }}
        className={loading ? 'loading' : ''}
        children={loading ? 'Loading assignment...' : children}
        data-testid={`GradeAssignmentLink ${assignment.id}`}
      />
    )
  } else {
    return <>{children}</>
  }
}

const Link = styled.a`
  transition:
    opacity 0.1s linear,
    color 0.1s linear;
  opacity: 1;
  &.loading {
    opacity: 0.5;
    color: ${props => props.theme['@text-color-secondary']};
  }
`
