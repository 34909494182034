import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { RecordView, RecordViewProps } from './RecordView'
import { RecordViewProviders } from './RecordViewProviders'
import { Sidebar } from '../sidebar/Sidebar'
import { SidebarProvider } from '../sidebar/SidebarContext'

export function RecordViewWithSidebar(props: RecordViewProps) {
  return (
    <SidebarProvider>
      <RecordViewProviders {...props}>
        <Container>
          <div style={{ flex: 1 }}>
            <RecordView {...props} />
          </div>
          <div style={{ width: '33%' }}>
            <Sidebar />
          </div>
        </Container>
      </RecordViewProviders>
    </SidebarProvider>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: ${s.size('m')};
`
