import React from 'react'

import { SimpleList } from './SimpleList'
// @ts-ignore
import { ReactComponent as Green } from '../../../../assets/sketch/sheet-music/color-green.svg'
// @ts-ignore
import { ReactComponent as Orange } from '../../../../assets/sketch/sheet-music/color-orange.svg'
// @ts-ignore
import { ReactComponent as Pink } from '../../../../assets/sketch/sheet-music/color-pink.svg'
// @ts-ignore
import { ReactComponent as Purple } from '../../../../assets/sketch/sheet-music/color-purple.svg'
// @ts-ignore
import { ReactComponent as Red } from '../../../../assets/sketch/sheet-music/color-red.svg'
// @ts-ignore
import { ReactComponent as Teal } from '../../../../assets/sketch/sheet-music/color-teal.svg'
// @ts-ignore
import { ReactComponent as Yellow } from '../../../../assets/sketch/sheet-music/color-yellow.svg'
import { KeyboardType, Mode, ModeContentsProps } from '../types'

export function SimpleColorsContent(props: ModeContentsProps) {
  return <SimpleList items={[Red, Orange, Yellow, Green, Teal, Purple, Pink, Red]} {...props} />
}

export const SimpleColors: Mode = {
  id: 'SimpleColors',
  name: 'Colors',
  icon: require('../../../../assets/sketch/sheet-music/color-red.svg').default,
  large: false,
  types: [KeyboardType.Note8],
  contents: SimpleColorsContent,
}
