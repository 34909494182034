import { Popover, Button, Input } from 'antd'
import Case from 'case'
import * as IconoirReact from 'iconoir-react'
import React from 'react'

import { AntIconWrapper } from './AntIconWrapper'
import { Block } from './Block'
import { BodySmall } from './Typography'
import { styled } from './styledTypes'

const DummyIcon = styled.span``
const { IconoirContext, IconoirProvider, ...Icons } = IconoirReact
const allIcons = Icons as Record<string, React.FC<React.PropsWithChildren<unknown>>>
function getIconComponent(iconName: string): React.FC<React.PropsWithChildren<unknown>> {
  if (allIcons[iconName]) return allIcons[iconName]
  return DummyIcon
}

export interface IconViewProps {
  icon: string
  [key: string]: any
}
export function IconView({ icon, ...rest }: IconViewProps) {
  const Component = getIconComponent(icon)
  return <Component {...rest} />
}

const SEARCH_DEBOUNCE = 50
export interface IconSelectProps {
  value?: string
  onChange?: (icon?: string) => void
}
export function IconSelect({ value, onChange }: IconSelectProps) {
  const IconComponent = value ? getIconComponent(Case.pascal(value)) : undefined
  const [search, setSearch] = React.useState('')
  const searchTimeout = React.useRef<any>(null)
  const matchingIconKeys = search.trim()
    ? Object.keys(allIcons).filter(key => {
        return key.toLowerCase().replace(/ /g, '').includes(search.toLowerCase().replace(/ /g, ''))
      })
    : Object.keys(allIcons)
  return (
    <Popover
      overlayInnerStyle={{ width: 400 }}
      trigger={['click']}
      placement={'bottom'}
      content={
        <>
          <Block marginBottom={'@size-s'}>
            <Input
              style={{ width: '100%' }}
              suffix={<Icons.Search />}
              onChange={e => {
                const val = e.target.value
                clearTimeout(searchTimeout.current)
                searchTimeout.current = setTimeout(() => {
                  setSearch(val)
                }, SEARCH_DEBOUNCE)
              }}
              placeholder={'Find an icon...'}
            />
          </Block>
          {matchingIconKeys.length ? (
            <IconGrid>
              {matchingIconKeys.map(iconKey => {
                const IconComponent = allIcons[iconKey]
                const selected = value === iconKey
                return (
                  <IconGridItem
                    key={iconKey}
                    selected={selected}
                    onClick={() => {
                      if (onChange) {
                        onChange(selected ? undefined : iconKey)
                      }
                    }}
                    title={Case.title(iconKey)}
                  >
                    <IconComponent />
                  </IconGridItem>
                )
              })}
            </IconGrid>
          ) : (
            <BodySmall color={'@text-color-secondary'} style={{ textAlign: 'center' }}>
              No icons found.
            </BodySmall>
          )}
        </>
      }
    >
      <Button
        icon={<AntIconWrapper children={IconComponent ? <IconComponent /> : <Icons.Iconoir />} />}
        children={value ? Case.title(value) : 'Select an Icon'}
      />
    </Popover>
  )
}

const IconGridItem = styled.div<{ selected?: boolean }>`
  padding: ${props => props.theme['@size-xs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  color: ${props => props.theme['@gray-5']};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: ${props => props.theme['@size-l']};
  transition:
    color 0.1s linear,
    background 0.1s linear;
  &:hover {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
  ${props => (props.selected ? '&' : '&.noop')} {
    background: ${props => props.theme['@primary-color']};
    color: white;
  }
`
const IconGrid = styled.div`
  display: grid;
  gap: ${props => props.theme['@size-xs']};
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  max-height: 400px;
  overflow-y: auto;
`
