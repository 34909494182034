import { styled } from '@thesisedu/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export interface TopMenuItemProps {
  name: string
  path: string
}
export function TopMenuItem({ name, path }: TopMenuItemProps) {
  const location = useLocation()
  const isActive = React.useMemo(() => {
    return location.pathname.replace(/^\//, '').startsWith(path.replace(/^\//, ''))
  }, [location.pathname, path])
  return <Container className={isActive ? 'active' : ''} to={path} children={name} />
}

const Container = styled(Link)`
  font-size: ${props => props.theme['@size-s']};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition:
    color 0.1s linear,
    background 0.1s linear;
  color: ${props => props.theme['@gray-5']};
  border-radius: ${props => props.theme['@border-radius-base']};
  white-space: nowrap;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  &:hover {
    background: ${props => props.theme['@gray-2']};
    color: ${props => props.theme['@gray-7']};
  }
  &.active {
    color: white;
    font-weight: 600;
    background: ${props => props.theme['@primary-color']};
  }
`
