import { Feature, FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ApolloReactFeature } from '@thesisedu/feature-apollo-react'
import { HOOKS } from '@thesisedu/feature-users-web'
import { Viewer } from '@thesisedu/feature-users-web/dist/types'

import { debug } from './log'

export interface GoogleTagManagerOptions {
  gtmId: string
  auth: string
  preview: string
}

export class GoogleTagManagerWebFeature extends Feature {
  static package = '@thesisedu/feature-google-tag-manager-web'
  static path = __dirname
  static requires = []
  public options!: GoogleTagManagerOptions

  constructor(options: GoogleTagManagerOptions, deps: FeatureDependencies) {
    super(options, deps)
    if (typeof window !== 'undefined') {
      debug('initializing google tag manager...')
      const TagManager = require('react-gtm-module')
      TagManager.initialize({
        gtmId: options.gtmId,
        auth: options.auth,
        preview: options.preview,
      })
      this.hookManager.registerHook<Viewer>(HOOKS.UserUpdated, async (feature, viewer) => {
        const apolloFeature = this.root.getFeature<ApolloReactFeature>(ApolloReactFeature.package)
        const isImpersonating = !!apolloFeature?.isUsingSessionAuth()
        if (!isImpersonating) {
          debug('updating viewer with google tag manager:', viewer)
          TagManager.dataLayer({
            dataLayer: {
              user_id: viewer?.id,
              user_group: viewer?.group,
            },
          })
        }
      })
    }
  }

  public updateDataLayer(data: Record<string, any>) {
    const TagManager = require('react-gtm-module')
    TagManager.dataLayer({
      dataLayer: data,
    })
  }
}

export const googleTagManagerWebFeature = (
  opts: GoogleTagManagerOptions,
): FeatureUse<GoogleTagManagerOptions> => [GoogleTagManagerWebFeature, opts]
