import { Widgets } from '@thesisedu/feature-course-types'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { styled } from '@thesisedu/ui'
import React from 'react'

import { VideoViewWithCompletion } from './VideoViewWithCompletion'

export interface BlockViewProps extends Widgets.VideoConfigWithVodId {}
export function BlockView({ vodId, alignment }: BlockViewProps) {
  return (
    <Container $alignment={alignment}>
      <VideoViewWithCompletion vodId={vodId} />
    </Container>
  )
}

const Container = styled(SimpleWidget.WidgetContainer)`
  width: 100%;
  max-width: 800px;
  .player-container {
    border-radius: 0 !important;
  }
`
