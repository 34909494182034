import { Form } from '@thesisedu/react'
import { Input } from 'antd'
import React from 'react'

import { useSubmissionData, useSubmissionProps } from '../../../contexts/SubmissionContext'
import { QuestionProps } from '../../../types'
import { MultipleChoiceField } from '../MultipleChoiceField'

export function CheckboxSelectContent(props: QuestionProps) {
  const submissionData = useSubmissionData(props.question.id)
  const expansionData = useSubmissionData(`${props.question.id}-expansion`)
  const submissionFieldProps = useSubmissionProps(props)
  return (
    <>
      <Form.Item
        name={props.question.id}
        initialValue={submissionData}
        required={{ value: true, message: 'This question is required.' }}
      >
        <MultipleChoiceField
          options={props.question.config?.options || []}
          multiple
          instructions={'Select your answer(s) from the options below.'}
          disabled={props.disabled}
        />
      </Form.Item>
      {props.question.config?.requireExpansion ? (
        <Form.Item
          label={'Explain your answer.'}
          style={{ marginTop: 20 }}
          name={`${props.question.id}-expansion`}
          initialValue={expansionData}
          required={{ value: true, message: 'An expansion on your answer is required.' }}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} {...submissionFieldProps} />
        </Form.Item>
      ) : null}
    </>
  )
}
