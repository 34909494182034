import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { ReactFeature } from '@thesisedu/feature-react'
import { WRAP_APP_HOOK } from '@thesisedu/feature-web'
import React from 'react'

export interface UsersWebOptions {}
export const DEFAULT_OPTIONS: UsersWebOptions = {}

export class UsersWebFeature extends ReactFeature {
  public static package: string = '@thesisedu/feature-users-web'
  public static path = __dirname
  public static requires: string[] = [require.resolve('@thesisedu/feature-apollo-react')]
  public readonly options!: UsersWebOptions

  constructor(options: Partial<UsersWebOptions>, deps: FeatureDependencies) {
    super(
      {
        ...DEFAULT_OPTIONS,
        ...options,
      },
      deps,
    )
    this.hookManager.registerMutateHook<React.ReactElement>(WRAP_APP_HOOK, children => {
      return this.wrapAppWithProvider(children)
    })
  }

  public async wrapAppWithProvider(children: React.ReactElement): Promise<React.ReactElement> {
    const { ImpersonateHeader } = require('./impersonation/ImpersonateHeader')
    const { ViewerContextProvider } = require('./ViewerContext')
    return (
      <ViewerContextProvider>
        <>
          <ImpersonateHeader />
          {children}
        </>
      </ViewerContextProvider>
    )
  }
}

export const usersWebFeature = (options: Partial<UsersWebOptions> = {}): FeatureUse => [
  UsersWebFeature,
  options,
]
export default UsersWebFeature
