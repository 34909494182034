import { CommonSearchProps } from '@thesisedu/feature-search-react'

import { LessonPracticesDocument, LessonPracticesQueryVariables } from '../schema'

export function useLessonPractices(
  lessonId: string,
): CommonSearchProps<LessonPracticesQueryVariables> {
  return {
    searchDocument: {
      document: LessonPracticesDocument,
      resultPath: 'node.practices',
    },
    variables: {
      lessonId,
    },
  }
}
