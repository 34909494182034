import React from 'react'
import { createPortal } from 'react-dom'

export interface SplitViewFooterHostContextValue {
  containerRef: React.RefObject<HTMLDivElement>
}
export const SplitViewFooterHostContext = React.createContext<
  SplitViewFooterHostContextValue | undefined
>(undefined)

export interface UseSplitViewFooterHostProviderResult {
  ref: React.RefObject<HTMLDivElement>
  contextValue: SplitViewFooterHostContextValue
}
export function useSplitViewFooterHostProvider(): UseSplitViewFooterHostProviderResult {
  const ref = React.useRef<HTMLDivElement>(null)
  const contextValue = React.useMemo(() => {
    return {
      containerRef: ref,
    }
  }, [ref])

  return {
    ref,
    contextValue,
  }
}

export interface SplitViewFooterContentProps {
  children: React.ReactNode
}
export function SplitViewFooterContent({ children }: SplitViewFooterContentProps) {
  const { containerRef } = useSplitViewFooterHostContext(false) ?? {}
  return containerRef?.current ? createPortal(children, containerRef.current) : null
}

export function useSplitViewFooterHostContext(): SplitViewFooterHostContextValue | undefined
export function useSplitViewFooterHostContext(
  require: false,
): SplitViewFooterHostContextValue | undefined
export function useSplitViewFooterHostContext(require: true): SplitViewFooterHostContextValue
export function useSplitViewFooterHostContext(
  require?: boolean,
): SplitViewFooterHostContextValue | undefined {
  const context = React.useContext(SplitViewFooterHostContext)
  if (!context && require) {
    throw new Error('SplitViewFooterHostContext is required, yet not provided.')
  }
  return context
}
