import { getSegmentName } from '@thesisedu/feature-course-types'
import { s, styled } from '@thesisedu/ui'
import React from 'react'

import { Header } from './Header'
import { Segment, SegmentOutlineItemProps } from './Segment'
import { useOutlineListContext } from '../OutlineListContext'
import { useOutlineContext } from '../context/OutlineContext'
import { isItemWithSegment, OutlineItem as OutlineItemInterface, OutlineItemType } from '../types'

export interface OutlineItemProps extends React.HTMLAttributes<HTMLDivElement> {
  item: OutlineItemInterface
  index: number
  className?: string
}
export const OutlineItem = React.forwardRef<HTMLDivElement, OutlineItemProps>(
  ({ className, item, index, children, ...divProps }, ref) => {
    const { renderSegment, segmentContainerProps } = useOutlineContext(false) || {}
    const { highlightedItemIds } = useOutlineListContext(false) ?? {}
    let content
    let containerProps: React.HTMLAttributes<HTMLDivElement> = {}
    if (item.type === OutlineItemType.HEADER) {
      content = <Header item={item} index={index} children={children} />
    } else if (item.type === OutlineItemType.SEGMENT && isItemWithSegment(item)) {
      containerProps = segmentContainerProps
        ? segmentContainerProps({ item, index, children } as SegmentOutlineItemProps)
        : {}
      if (renderSegment) {
        content = renderSegment({ item, index, children } as SegmentOutlineItemProps)
      } else {
        content = <Segment item={item} index={index} children={children} />
      }
    } else {
      return null
    }
    return (
      <Container
        className={`OutlineItem ${className}`}
        ref={ref}
        $highlighted={highlightedItemIds?.includes(item.id)}
        {...divProps}
        {...containerProps}
        data-testid={
          isItemWithSegment(item) ? `Segment ${getSegmentName(item.segment)}` : undefined
        }
        style={{
          ...divProps.style,
          ...containerProps?.style,
        }}
      >
        {content}
      </Container>
    )
  },
)

const { keyframes } = s
const pulse = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`
const Container = styled.div<{ $highlighted?: boolean }>`
  .outer-container,
  .hover-container {
    position: relative;
    &::before {
      display: block;
      content: '';
      position: absolute;
      left: calc(-1 * ${s.size('xs')} - 6px);
      top: 0;
      bottom: 0;
      pointer-events: none;
      transition: opacity 0.25s linear;
      border-radius: 3px;
      width: 6px;
      background: ${s.color('blue')};
      opacity: 0;
      ${s.ifs(props => props.$highlighted)} {
        opacity: 1;
        animation: ${pulse} 1.667s infinite ${s.var('curves.ease')};
      }
    }
  }
`
