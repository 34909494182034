import { WebContext } from '@thesisedu/feature-web'
import { styled } from '@thesisedu/web'
import React from 'react'

import { ClassHooks, StudentMetadataContext, StudentMetadataPayload } from './types'

const Container = styled.div`
  line-height: 1;
  > *:not(:empty) {
    display: inline-block;
    &:not(:last-child):after {
      content: '-';
      display: inline-block;
      margin: 0 0.25em;
      font-size: 12px;
      color: ${props => props.theme['@text-color-secondary']};
    }
  }
`

export interface ClassStudentMetadataProps {
  context: StudentMetadataContext
}
export function ClassStudentMetadata({ context }: ClassStudentMetadataProps) {
  const feature = React.useContext(WebContext)
  if (!feature) return null
  const children = feature.featureWeb.deps.hookManager.mutateHookSync<
    StudentMetadataPayload,
    StudentMetadataContext
  >(ClassHooks.StudentMetadata, [], context)

  return <Container>{children}</Container>
}
