import { OptionsConfig } from '@thesisedu/feature-assignments-core'
import { Block } from '@thesisedu/web'
import { Alert } from 'antd'
import React from 'react'

import { QuestionProps } from '../../../types'
import CheckboxSelect from '../CheckboxSelect'
import { MultipleChoiceEditor } from '../MultipleChoiceEditor'
import { QuestionAdditionalFields } from '../QuestionAdditionalFields'

export function MultipleChoiceEdit(props: QuestionProps<OptionsConfig>) {
  return (
    <>
      <MultipleChoiceEditor
        options={props.question.config?.options}
        onChange={options => {
          if (props.onChange) {
            props.onChange({
              config: {
                ...props.question.config,
                options,
              },
            })
          }
        }}
      >
        {props.question.config?.options &&
        props.question.config.options.filter(opt => opt.correct).length > 1 ? (
          <Block
            marginTop={'@size-s'}
            marginBottom={'@size-s'}
            marginLeft={'@size-s'}
            marginRight={'@size-s'}
          >
            <Alert
              message={
                <>
                  <strong>Students can only select one answer.</strong> Since this is a multiple
                  choice question, students may only select one answer (while multiple answers can
                  be marked as correct). If you want students to select multiple answers,{' '}
                  <a
                    onClick={e => {
                      e.preventDefault()
                      if (props.onChange) {
                        props.onChange({
                          type: CheckboxSelect.identifier,
                        })
                      }
                    }}
                  >
                    convert this to a Checkbox Select question.
                  </a>
                </>
              }
              type={'warning'}
              showIcon
            />
          </Block>
        ) : null}
      </MultipleChoiceEditor>
      <QuestionAdditionalFields {...props} useExplanation />
    </>
  )
}
