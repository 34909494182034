import { BookStack } from '@thesisedu/ui/icons'
import React from 'react'

import { DefinitionElement } from './DefinitionElement'
import { DefinitionConfig } from './common'
import { WidgetsReactFeature } from '../../../WidgetsReactFeature'
import { SimpleWidget } from '../utils'

export const definition = {
  identifier: 'Definition',
  title: 'Definition',
  weight: 23,
  group: SimpleWidget.ElementsGroup.TextBlocks,
  icon: <BookStack />,
  defaultConfig: { version: 1 },
  nestedEditorKeys: ['word', 'definition'],
  hidden(context) {
    return !context.group || context.group === 'STUDENT'
  },
  element: DefinitionElement,
} satisfies SimpleWidget.SimpleWidgetDefinition<DefinitionConfig, 'Definition'>

export default function (feature: WidgetsReactFeature) {
  feature.resources.addResource(SimpleWidget.createWidgetResource(definition))
}
