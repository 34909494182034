import { useStatePropFallback } from '@thesisedu/feature-react'
import React from 'react'

import { CourseConfiguration } from '../types'

export function useSelectedTermId(
  configuration?: CourseConfiguration,
  _selectedTermId?: string,
  _setSelectedTermId?: (termId: string) => void,
): [string, (val: string) => void] {
  const [selectedTermId, setSelectedTermId] = useStatePropFallback(
    _selectedTermId,
    _setSelectedTermId,
    configuration?.segments[0]?.id ?? 'none',
  )
  React.useEffect(() => {
    setSelectedTermId(configuration?.segments[0]?.id ?? 'none')
  }, [configuration?.segments[0]?.id ?? 'no-id'])
  return [selectedTermId, setSelectedTermId]
}
