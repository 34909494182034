import { Dropdown, Tooltip } from '@thesisedu/ui'
import { DashboardSpeed } from '@thesisedu/ui/icons'
import { useWebSettings } from '@thesisedu/web/dist/WebSettingsContext'
import React from 'react'

import { useMediaPlaybackContext } from '../MediaPlaybackContext'
import { PlayerFooterProps } from '../PlayerFooter'
import { ControlContainer } from '../styled'

const DEFAULT_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

export function PlaybackSpeed({ compact }: PlayerFooterProps) {
  const { playbackSpeeds = DEFAULT_SPEEDS } = useWebSettings()
  const { playbackSpeed, setPlaybackSpeed } = useMediaPlaybackContext()
  return (
    <Dropdown.Container>
      <Dropdown.ManualTrigger>
        <Tooltip title={'Playback Speed'}>
          <ControlContainer compact={compact !== undefined}>
            <DashboardSpeed />
          </ControlContainer>
        </Tooltip>
      </Dropdown.ManualTrigger>
      <Dropdown.Menu side={'top'} align={'end'}>
        {playbackSpeeds.map(speed => (
          <Dropdown.Item.Checkbox
            key={speed.toString()}
            checked={playbackSpeed === speed}
            onCheckedChange={checked => {
              if (checked) {
                setPlaybackSpeed(speed)
              }
            }}
          >
            {speed.toString()}x
          </Dropdown.Item.Checkbox>
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  )
}
