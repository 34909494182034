import { Dropdown, HSpaced } from '@thesisedu/ui'
import React from 'react'

import { ReportDimensionResource } from '../types'

export interface DimensionOptionsMenuItemProps<Options> {
  dimension: ReportDimensionResource<Options, object>
  options?: Options
  context?: object
  onChange: (options: Options, context: object, overrideIdentifier?: string) => void
}
export function DimensionOptionsMenuItem({
  dimension,
  options,
  context,
  onChange,
}: DimensionOptionsMenuItemProps<object>) {
  const LabelComponent = dimension.renderOptionsLabel
  const WrapperComponent = dimension.renderOptionsWrapper
  if (!LabelComponent || !WrapperComponent) return null
  return (
    <WrapperComponent value={options} context={context} onConfirm={onChange} isInDropdown>
      <Dropdown.Item.Checkbox checked={!!options}>
        <HSpaced space={'xs'}>
          <span>{dimension.icon}</span>
          <span>{dimension.title}</span>
        </HSpaced>
        <Dropdown.Item.Right>
          <LabelComponent value={options} context={context} />
        </Dropdown.Item.Right>
      </Dropdown.Item.Checkbox>
    </WrapperComponent>
  )
}
