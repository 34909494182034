import { isNoteItem } from '@thesisedu/feature-sheet-music-core'
import { InstrumentResource } from '@thesisedu/feature-sheet-music-react'

export const INSTRUMENTS: Omit<InstrumentResource, 'type'>[] = [
  {
    identifier: 'bells',
    name: 'Bells',
    soundfontUrl: 'https://assets.prodigiesacademy.com/prodigies/',
    instrument: 'helicopter',
  },
  {
    identifier: 'piano',
    name: 'Piano',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'acoustic_grand_piano',
  },
  {
    identifier: 'solfege',
    name: 'Solfege Voices',
    soundfontUrl: 'https://assets.prodigiesacademy.com/prodigies/',
    instrument: 'applause',
    hidden: true,
    // Solfege does not support accidentals.
    simpleOnly: true,
    filter: abc => {
      return !abc.some(item => {
        return item.lines.some(line => {
          return line.staff.some(staffItem => {
            return staffItem.voices.some(voice => {
              return voice.some(voiceItem => {
                return (
                  voiceItem.el_type === 'note' &&
                  isNoteItem(voiceItem) &&
                  voiceItem.pitches.some(pitch => {
                    return pitch.accidental
                  })
                )
              })
            })
          })
        })
      })
    },
  },
  {
    identifier: 'marimba',
    name: 'Marimba',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'marimba',
  },
  {
    identifier: 'steel-drum',
    name: 'Steel Drum',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'steel_drums',
  },
  {
    identifier: 'woodblock',
    name: 'Woodblock',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'woodblock',
  },
  {
    identifier: 'taiko-drum',
    name: 'Taiko Drum',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'taiko_drum',
  },
  {
    identifier: 'synth-drum',
    name: 'Synth Drum',
    soundfontUrl: 'https://assets.prodigiesacademy.com/fluidr3-gm/',
    instrument: 'synth_drum',
  },
]
