import { FontWeight, styled, StyledComponent } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

export const ThumbnailMetricContainer: React.FC<React.PropsWithChildren<any>> = styled.div<{
  color?: string
}>`
  background: ${props =>
    props.color ? props.theme[props.color] : transparentize(0.25, props.theme['@gray-7'])};
  color: ${props => props.theme['@gray-0']};
  font-size: ${props => props.theme['@font-size-sm']};
  padding: ${props => props.theme['@size-xxs']};
  border-radius: ${props => props.theme['@border-radius-base']};
  position: absolute;
  bottom: ${props => props.theme['@size-xs']};
  right: ${props => props.theme['@size-xs']};
  line-height: 1;
  display: flex;
  align-items: center;
  > :not(:last-child) {
    margin-right: ${props => props.theme['@size-xxs']};
  }
  > svg {
    height: ${props => props.theme['@size-s']};
    width: ${props => props.theme['@size-s']};
  }
`

export const GridTitle: StyledComponent<'div'> = styled.div`
  margin-top: ${props => props.theme['@size-s']};
  font-weight: ${FontWeight.SemiBold};
  color: ${props => props.theme['@text-color']};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: ${props => props.theme['@font-size-lg']};
  line-height: calc(${props => props.theme['@font-size-lg']} * 1.4);
  max-height: calc(${props => props.theme['@font-size-lg']} * 1.4 * 2);
`

export const GridSubtitle: StyledComponent<'div'> = styled.div`
  color: ${props => props.theme['@text-color-secondary']};
  font-size: ${props => props.theme['@font-size-sm']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
