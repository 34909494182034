import { Format } from '@thesisedu/feature-utils'
import { VSpaced } from '@thesisedu/web'
import { ResponsiveBar } from '@thesisedu/web/dist/nivo'
import { Table } from 'antd'
import React from 'react'

import { QuestionTextMediaView } from '../questions/QuestionTextMediaField'
import { useGradesByCategoryQuery } from '../schema'
import { ReportItemProps } from '../types'

interface GradesByCategoryRow {
  grade?: number
  categoryId: string
  category: string
  index: number
}
export function GradesBreakdown({ assignment }: ReportItemProps) {
  const { data, loading } = useGradesByCategoryQuery({
    variables: {
      assignmentId: assignment.id,
    },
    fetchPolicy: 'network-only',
  })
  const categories =
    data?.node?.__typename === 'Assignment' ? data.node.gradesByCategory.categories : undefined
  const rows =
    assignment.rubric?.categories.map<GradesByCategoryRow>((cat, index) => {
      const gradeResult = categories?.find(c => c.categoryId === cat.categoryId)
      return {
        category: `${index + 1}. ${cat.name || 'Unnamed Category'}`,
        categoryId: cat.categoryId,
        grade: gradeResult?.averageGrade,
        index,
      }
    }) || []

  return (
    <VSpaced space={'@size-l'}>
      <ResponsiveBar
        loading={loading}
        data={rows.map(item => ({
          category: item.index + 1,
          grade: item.grade ? item.grade * 100 : 0,
        }))}
        keys={['grade']}
        indexBy={'category'}
        axisLeft={{ legend: 'Average Category Grade' }}
        axisBottom={{
          // This is to override the default ResponsiveBar logic, which assumes we are displaying
          // names.
          format: value => value.toString(),
        }}
        minValue={0}
        maxValue={100}
      />
      <Table<GradesByCategoryRow>
        dataSource={rows}
        loading={loading}
        rowKey={item => item.categoryId}
        pagination={false}
      >
        <Table.Column<GradesByCategoryRow>
          title={'Category'}
          dataIndex={'category'}
          render={name => {
            return <QuestionTextMediaView value={name} />
          }}
        />
        <Table.Column
          title={'Average Grade'}
          dataIndex={'grade'}
          render={g =>
            g !== null && g !== undefined ? Format.number(g, 'decimalPercentage') : 'N/A'
          }
        />
      </Table>
    </VSpaced>
  )
}
