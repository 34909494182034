import { HSpaced } from '@thesisedu/ui'
import React from 'react'

import { useGroupsContext } from './GroupsContext'
import { GenerateGroupsButton } from './generate/GenerateGroupsButton'
import { LoadFromPresetButton } from './presets/LoadFromPresetButton'
import { SaveAsPresetButton } from './presets/SaveAsPresetButton'
import { ShuffleButton } from './shuffle/ShuffleButton'

export interface ActionsProps {
  hideSavePreset?: boolean
}
export function Actions({ hideSavePreset }: ActionsProps) {
  const { classId, onChange } = useGroupsContext(true)
  return (
    <HSpaced space={'xs'}>
      <GenerateGroupsButton />
      <LoadFromPresetButton classId={classId} onChange={onChange} />
      <div style={{ marginLeft: 'auto' }} />
      {hideSavePreset ? null : <SaveAsPresetButton />}
      <ShuffleButton />
    </HSpaced>
  )
}
