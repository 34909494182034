import { onMutationError } from '@thesisedu/feature-apollo-react'
import { FileField, getFileInfo } from '@thesisedu/feature-attachments-react'
import { Block, Body, BodyLarge, Modal } from '@thesisedu/web'
import { Button, message } from 'antd'
import React from 'react'

import { ImportStandardSetProgressModal } from './ImportStandardSetProgressModal'
import { useGetStandardSetImportUrlMutation, useStartStandardSetImportMutation } from '../schema'

export interface ImportStandardSetButtonProps {
  standardSetId: string
}
export function ImportStandardSetButton({ standardSetId }: ImportStandardSetButtonProps) {
  const [visible, setVisible] = React.useState(false)
  const [activeJob, setActiveJob] = React.useState<string>()
  const [uploadPath, setUploadPath] = React.useState<string>()
  const [getUploadUrl] = useGetStandardSetImportUrlMutation({
    onError: onMutationError('There was an error starting your upload.'),
  })
  const [startImport, { loading }] = useStartStandardSetImportMutation({
    onError: onMutationError(
      'There was an error starting your import job.',
      ['INVALID_DATA_ERROR', 'TAG_NOT_FOUND_ERROR'],
      (code, err) => {
        if (code === 'INVALID_DATA_ERROR') {
          message.error(err.graphQLErrors[0].message)
        } else if (code === 'TAG_NOT_FOUND_ERROR') {
          const tagName = err.graphQLErrors[0].extensions?.tagName || 'Unknown Tag'
          message.error(
            <span>
              Your upload has invalid tags: <strong>{tagName}</strong> does not exist.
            </span>,
          )
        }
      },
    ),
  })

  React.useEffect(() => {
    if (!visible) {
      setUploadPath(undefined)
    }
  }, [visible])

  return (
    <>
      {activeJob ? (
        <ImportStandardSetProgressModal
          identifier={activeJob}
          onDone={() => {
            setActiveJob(undefined)
          }}
        />
      ) : null}
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        title={'Import Standards'}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            key={'start'}
            type={'primary'}
            loading={loading}
            onClick={async () => {
              if (uploadPath) {
                const fileInfo = getFileInfo(uploadPath)
                const result = await startImport({
                  variables: {
                    input: {
                      csvPath: fileInfo.filename,
                      standardSetId,
                    },
                  },
                })
                if (result?.data?.startStandardSetImport) {
                  setActiveJob(result.data.startStandardSetImport.identifier)
                  setVisible(false)
                }
              }
            }}
            disabled={!uploadPath}
          >
            Start Import
          </Button>,
        ]}
      >
        <BodyLarge isBlock>
          Use this feature to automatically associate standards with segments in the system.
        </BodyLarge>
        <Body isBlock>
          To get started, upload a CSV with the following columns <strong>exactly:</strong>
        </Body>
        <ul>
          <li>
            <strong>leaf:</strong> This is the name of the standard.
          </li>
          <li>
            <strong>shortCode:</strong> The short code of the course.
          </li>
          <li>
            <strong>index:</strong> The content's index inside that course.
          </li>
        </ul>
        <Body isBlock>
          For example, if you were associating the standard "(A) identify components of the
          promotional mix such as advertising, visual merchandising, and personal selling" with the
          MKT 1-2 lesson, you would have the following values:
        </Body>
        <ul>
          <li>
            <strong>leaf:</strong> (A) identify components of the promotional mix such as
            advertising, visual merchandising, and personal selling
          </li>
          <li>
            <strong>shortCode:</strong> MKT
          </li>
          <li>
            <strong>index:</strong> 1-2
          </li>
        </ul>
        <Block marginTop={'@size-m'}>
          <FileField
            value={uploadPath}
            onChange={value => setUploadPath(value)}
            uploadProps={{
              accept: 'text/csv',
              getWriteFragment: async input => {
                const result = await getUploadUrl({
                  variables: {
                    input: input.signedUploadInput,
                  },
                })

                if (result?.data?.getStandardSetImportUrl) {
                  return {
                    uploadUrl: {
                      upload: result.data.getStandardSetImportUrl,
                    },
                  }
                } else return null
              },
            }}
          />
        </Block>
      </Modal>
      <Button onClick={() => setVisible(true)}>Import Standards</Button>
    </>
  )
}
