import { css } from 'styled-components'

import { TextProps } from '../../components/Text'
import { Variants as TVariants, VariantString } from '../types'
import { sizeToVariable } from '../variables'

export const Variants = {
  extraSmall: css`
    font-size: ${sizeToVariable('font.xs')};
    --vertical-padding: 0.25em;
    --horizontal-padding: 0.25em;
  `,
  small: css`
    font-size: ${sizeToVariable('font.s')};
    --vertical-padding: 0.5em;
    --horizontal-padding: ${sizeToVariable('s')};
  `,
  medium: css`
    font-size: ${sizeToVariable('font.m')};
    --vertical-padding: 0.6em;
    --horizontal-padding: ${sizeToVariable('s')};
  `,
  large: css`
    font-size: ${sizeToVariable('font.l')};
    --vertical-padding: 0.6em;
    --horizontal-padding: ${sizeToVariable('s')};
  `,
} satisfies TVariants
export type Type = VariantString<typeof Variants>
export const defaultValue: Type = 'medium'

export const TextLevelMap: Record<Type, TextProps['level']> = {
  extraSmall: 'xs',
  small: 's',
  medium: 'm',
  large: 'l',
}
