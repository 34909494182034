import { VideoCameraAddOutlined } from '@ant-design/icons'
import { Block, Modal } from '@thesisedu/web'
import { Button } from 'antd'
import React from 'react'

import { CreateLessonModal } from './CreateLessonModal'
import { TeacherLessonsList } from './TeacherLessonsList'
import { BasicLessonFragment } from '../schema'

export interface SelectLessonModalProps {
  visible?: boolean
  onCancel: () => void
  onSelect: (lesson: BasicLessonFragment) => void
}
export function SelectLessonModal({ visible, onCancel, onSelect }: SelectLessonModalProps) {
  const [isCreating, setIsCreating] = React.useState(false)
  return (
    <>
      <CreateLessonModal
        visible={isCreating}
        onCancel={() => setIsCreating(false)}
        onCreated={lesson => {
          setIsCreating(false)
          onSelect(lesson)
        }}
      />
      <Modal
        visible={visible}
        onCancel={onCancel}
        title={isCreating ? 'Create a Lesson' : 'Select a Lesson'}
        width={1100}
        bodyStyle={{ maxWidth: '90vw' }}
      >
        <Block marginBottom={'@size-s'} style={{ textAlign: 'right' }}>
          <Button
            type={'primary'}
            icon={<VideoCameraAddOutlined />}
            onClick={() => {
              setIsCreating(true)
            }}
          >
            Record new Lesson
          </Button>
        </Block>
        <TeacherLessonsList
          onLessonClick={(_, lesson) => {
            onSelect(lesson)
          }}
          noParams
        />
      </Modal>
    </>
  )
}
