import { Nullable } from '@thesisedu/feature-types'
import React from 'react'
import { Control, FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

import { FormInstance } from './types'

export interface ReactFormContextValue<Values extends FieldValues> {
  handleSubmit: (e: any) => void
  control: Control<Values>
  form: FormInstance<Values>
}
export const ReactFormContext = React.createContext<ReactFormContextValue<any> | undefined>(
  undefined,
)
export function useFormContext<Values extends FieldValues>(
  require: false,
): ReactFormContextValue<Values> | undefined
export function useFormContext<Values extends FieldValues>(
  require?: true,
): ReactFormContextValue<Values>
export function useFormContext<Values extends FieldValues>(
  require = true,
): ReactFormContextValue<Values> | undefined {
  const context = React.useContext(ReactFormContext)
  if (!context && require) {
    throw new Error('ReactFormContext is required, but not provided.')
  }
  return context
}

export function useFormSubmit() {
  const { handleSubmit } = useFormContext()
  return (e?: any) => handleSubmit(e || {})
}

export interface ReactFormContextProviderProps<Values extends FieldValues> {
  onFinish?: FormProps<Values>['onFinish']
  onFinishFailed?: FormProps<Values>['onFinishFailed']
  onValuesChange?: FormProps<Values>['onValuesChange']
  form: FormInstance<Values>
}
export function ReactFormContextProvider<Values extends Record<string, any>>({
  onFinish,
  onFinishFailed,
  onValuesChange,
  form,
  children,
}: React.PropsWithChildren<ReactFormContextProviderProps<Values>>) {
  return (
    <ReactFormContext.Provider
      value={{
        form,
        control: form.control,
        handleSubmit: form.handleSubmit(onFinish as any, async () => {
          if (onFinishFailed) {
            await onFinishFailed(form.getValues() as Partial<Nullable<Values>>)
          }
        }),
      }}
    >
      {onValuesChange ? (
        <OnValuesChangeWatcher onValuesChange={onValuesChange} form={form} />
      ) : null}
      {children}
    </ReactFormContext.Provider>
  )
}

export interface OnValuesChangeWatcherProps<T extends FieldValues>
  extends Pick<FormProps<T>, 'onValuesChange' | 'form'> {}
export function OnValuesChangeWatcher<T extends FieldValues>({
  onValuesChange,
  form,
}: OnValuesChangeWatcherProps<T>) {
  const values = onValuesChange ? form.watch() : undefined
  // We have to stringify because it looks like the watcher returns a different value by reference each time.
  React.useEffect(() => {
    if (onValuesChange && values) {
      onValuesChange(values as Partial<Nullable<T>>)
    }
  }, [JSON.stringify(values)])
  return null
}

export interface FormProps<Values extends FieldValues> {
  form: UseFormReturn<Values>
  onFinish?: (values: Values) => any | Promise<any>
  onFinishFailed?: (values: Partial<Nullable<Values>>) => void | Promise<void>
  onValuesChange?: (values: Partial<Nullable<Values>>) => void
  className?: string
  style?: any
}
export function getFormProps<T extends object, Values extends FieldValues>(
  props: T & FormProps<Values>,
) {
  const { form, onFinish, onFinishFailed, onValuesChange, className, style, ...rest } = props
  return { formProps: { form, onFinish, onFinishFailed, onValuesChange, className, style }, rest }
}

export function Form<Values extends FieldValues>({
  form,
  onFinish,
  onFinishFailed,
  onValuesChange,
  children,
  className,
  style,
}: React.PropsWithChildren<FormProps<Values>>) {
  return (
    <FormProvider {...form}>
      <ReactFormContextProvider
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <InnerForm children={children} className={className} style={style} />
      </ReactFormContextProvider>
    </FormProvider>
  )
}

export function InnerForm({ children, ...rest }: React.PropsWithChildren<any>) {
  const { handleSubmit } = useFormContext()
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        handleSubmit(e)
      }}
      {...rest}
      children={children}
    />
  )
}
