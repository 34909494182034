import { AttachmentRead } from '@thesisedu/feature-attachments-react'
import { BodySmall } from '@thesisedu/react'
import React from 'react'

import { LessonFragment } from './schema'

export interface ViewLessonAttachmentProps {
  lesson: Pick<LessonFragment, 'attachment'>
}
export function ViewLessonAttachment({ lesson }: ViewLessonAttachmentProps) {
  const attachment = lesson.attachment
  if (attachment.url) {
    return (
      <>
        <BodySmall isBlock color={'@text-color-secondary'}>
          Attachment
        </BodySmall>
        <AttachmentRead fragment={lesson.attachment} />
      </>
    )
  } else return null
}
