import { apolloReactFeature } from '@thesisedu/feature-apollo-react'
import { assignmentsReactFeature, GradeDisplayMode } from '@thesisedu/feature-assignments-react'
import { authGoogleReactFeature } from '@thesisedu/feature-auth-google-react'
import { authMSALReactFeature } from '@thesisedu/feature-auth-msal-react'
import { badgesReactFeature } from '@thesisedu/feature-badges-react'
import { browserDetectWebFeature } from '@thesisedu/feature-browser-detect-web'
import { canvasReactFeature } from '@thesisedu/feature-canvas-react'
import { classSyncReactFeature } from '@thesisedu/feature-class-sync-react'
import { classesReactFeature } from '@thesisedu/feature-classes-react'
import { cleverReactFeature } from '@thesisedu/feature-clever-react'
import { commentsReactFeature } from '@thesisedu/feature-comments-react'
import { courseAssignmentsReactFeature } from '@thesisedu/feature-course-assignments-react'
import { courseReportsReactFeature } from '@thesisedu/feature-course-reports-react'
import { courseViewsReactFeature } from '@thesisedu/feature-course-views-react'
import { coursesReactFeature } from '@thesisedu/feature-courses-react'
import { directMessagesReactFeature } from '@thesisedu/feature-direct-messages-react'
import { districtsWebFeature } from '@thesisedu/feature-districts-web'
import { featurePreviewReactFeature } from '@thesisedu/feature-feature-preview-react'
import { googleClassroomReactFeature } from '@thesisedu/feature-google-classroom-react'
import { googleTagManagerWebFeature } from '@thesisedu/feature-google-tag-manager-web'
import { graphicDragDropReactFeature } from '@thesisedu/feature-graphic-drag-drop-react'
import { interactionsWebFeature } from '@thesisedu/feature-interactions-react'
import { intercomWebFeature } from '@thesisedu/feature-intercom-web'
import { lessonsReactFeature } from '@thesisedu/feature-lessons-react'
import { licensesReactFeature } from '@thesisedu/feature-licenses-react'
import { mediaReactFeature } from '@thesisedu/feature-media-react'
import { msTeamsReactFeature } from '@thesisedu/feature-ms-teams-react'
import { notificationDbReactFeature } from '@thesisedu/feature-notification-db-react'
import { notificationPreferencesReactFeature } from '@thesisedu/feature-notification-preferences-react'
import { pdfGenerationReactFeature } from '@thesisedu/feature-pdf-generation-react'
import { isInNode } from '@thesisedu/feature-react'
import { reportsReactFeature } from '@thesisedu/feature-reports-react'
import { schoologyReactFeature } from '@thesisedu/feature-schoology-react'
import { searchReactFeature } from '@thesisedu/feature-search-react'
import { sentryWebFeature } from '@thesisedu/feature-sentry-web'
import { sheetMusicReactFeature } from '@thesisedu/feature-sheet-music-react'
import { sitesWebFeature } from '@thesisedu/feature-sites-web'
import { standardSetsReactFeature } from '@thesisedu/feature-standard-sets-react'
import { studentIdReactFeature } from '@thesisedu/feature-student-id-react'
import { tagsReactFeature } from '@thesisedu/feature-tags-react'
import { teachersWebFeature } from '@thesisedu/feature-teachers-react'
import { trialsWebFeature } from '@thesisedu/feature-trials-web'
import { updatesReactFeature } from '@thesisedu/feature-updates-react'
import { userTasksReactFeature } from '@thesisedu/feature-user-tasks-react'
import { usersReactFeature } from '@thesisedu/feature-users-react'
import { usersWebFeature } from '@thesisedu/feature-users-web'
import { videoOnDemandReactFeature } from '@thesisedu/feature-video-on-demand-react'
import { FeatureWeb } from '@thesisedu/feature-web'
import { widgetTemplatesReactFeature } from '@thesisedu/feature-widget-templates-react'
import { widgetsReactFeature } from '@thesisedu/feature-widgets-react'

import { INSTRUMENTS } from './components/keyboard/instruments'
import fragmentTypes from './fragmentTypes'
import { darkTheme, theme, uiTheme } from './theme'

const web = new FeatureWeb({
  name: 'Prodigies Academy',
  frontend: 'https://app.prodigiesacademy.com',
  domain: 'prodigiesacademy.com',
  rootDomain: 'prodigiesacademy.com',
  uiTheme,
  logo: () => {
    return require('./assets/academy-light-logo.svg').default
  },
  icon: () => {
    return require('./assets/academy-light-icon.svg').default
  },
  darkLogo: () => {
    return require('./assets/academy-dark-logo.svg').default
  },
  darkIcon: () => {
    return require('./assets/academy-dark-icon.svg').default
  },
  themes: {
    light: theme,
    dark: darkTheme,
  },
  webSettings: {
    playbackSpeeds: [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.25, 1.5],
  },
  acmCertificateId: '58f0e624-1a8a-4aa8-bb96-05ad1be91f04',
  hostedZoneId: 'Z10306191HBUEV3IK3T0',
  apiDomain: 'api.prodigiesacademy.com',
  region: 'us-east-1',
  terraformOrg: 'thesisedu',
})
web.use([
  sentryWebFeature({
    dsn: 'https://5cf161e3744742de921e95ff3aec119f@o429952.ingest.sentry.io/5747695',
    additionalOptions: {
      ignoreErrors: [
        /useRequiredClass/,
        /captured with value: null/,
        /Internal server error/,
        /JSON\.parse/,
        /QuotaExceededError/,
        /network/i,
        /unexpected token .* in json/i,
        /json parse error:/i,
        /^cancelled$/i,
        /non-error promise rejection captured with/i,
        /access-control-allow-origin/i,
        /avast_submit/i,
        /failed to fetch/i,
        /fullscreen error/i,
      ],
    },
    project: 'prodigies-academy-web',
    organization: 'thesis',
  }),
  apolloReactFeature({
    host: process.env.REACT_APP_BACKEND || 'http://localhost:3000/graphql',
    authenticationKey: 'prodigies-academy-auth',
    possibleTypes: fragmentTypes.possibleTypes,
    persistence: {
      persistKey: 'prodigies-academy-persisted-storage',
      schemaVersion: 'v1',
      schemaVersionKey: 'prodigies-academy-persisted-storage-version',
      debug: true,
      hideBehindPreview: true,
    },
  }),
  usersWebFeature({}),
  usersReactFeature({
    getAuthWrapper: () => require('./AuthWrapper').AuthWrapper,
  }),
  mediaReactFeature({
    getKeyboard: () => {
      return require('./components/keyboard/Keyboard').Keyboard
    },
    enablePitchTool: true,
    enableMetronomeTool: true,
  }),
  sitesWebFeature({}),
  districtsWebFeature({}),
  trialsWebFeature({
    signupAuthProviders: ['google', 'msal', 'clever'],
    welcomeComponent() {
      return require('./Welcome').Welcome
    },
  }),
  classesReactFeature({
    className: 'class',
    classNamePlural: 'classes',
    showClassCodeOnInviteButton: true,
    getNewTeacherContent() {
      return require('./NewUser').NewTeacher
    },
    getNewStudentContent() {
      return require('./NewUser').NewStudent
    },
  }),
  licensesReactFeature({
    showStudentPay: true,
  }),
  coursesReactFeature({
    getSegmentCompletionHost() {
      return require('./components/completion/SegmentCompletionHost').SegmentCompletionHost
    },
    tagNameOverrides: {
      CONCEPT_OBJECTIVE: 'Concepts / Objectives',
      INSTRUCTIONAL_AREA: 'Activity Title',
      PERFORMANCE_ELEMENT: 'Activity Description',
      PERFORMANCE_INDICATOR: 'LEQ',
      IMPLEMENTATION_GUIDE: 'Lesson',
      LAP: 'Types of Music',
      LEARNING_GOAL: 'Theme',
      CONTENT_AREA: 'Difficulty',
    },
    additionalTagTypes: [
      { type: 'GENRE', label: 'Genre' },
      { type: 'NUM_NOTES', label: '# of Notes' },
      { type: 'INSTRUMENT', label: 'Instrument' },
      { type: 'NOTES', label: 'Notes' },
      { type: 'KEY', label: 'Key' },
      { type: 'SONG_FORM', label: 'Song Form' },
      { type: 'VOCAL_MODEL', label: 'Vocal Model' },
      { type: 'NOTATION_STYLE', label: 'Notation Style' },
    ],
    labelPlaceholder: 'Activator, Core Content, etc.',
    sectionLabels: [
      'Welcome Activity',
      'Activator',
      'Core Content',
      'Activity',
      'Lesson Information',
      'Sheet Music',
      'Video',
    ],
  }),
  courseAssignmentsReactFeature({
    assignmentLabels: ['Activity', 'Assessment', 'Digital Interaction'],
    assignmentNamePresets: [], // Most of the assignments are named differently...
    labelPlaceholder: 'Assessment, Digital Interaction, etc.',
  }),
  teachersWebFeature(),
  authGoogleReactFeature({
    clientId:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '198837520266-j495magvtsop993mkdohfurabs95moui.apps.googleusercontent.com'
        : '1082711383136-jmhhgaebg9fn7lkgkdjuc76rqr9hlqrs.apps.googleusercontent.com',
  }),
  userTasksReactFeature(),
  interactionsWebFeature(),
  classSyncReactFeature(),
  studentIdReactFeature(),
  intercomWebFeature({
    appId: 'miij354u',
  }),
  googleTagManagerWebFeature({
    gtmId: 'GTM-TDWZWT5',
    auth:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 't4USi-k74h4IZnCJ4bXglg'
        : 'ITXWaqF_5mWIdP6-BnSJqw',
    preview: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'env-1' : 'env-2',
  }),
  browserDetectWebFeature(),
  authMSALReactFeature({
    clientId:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? 'e625b536-c693-4508-851b-0f478f529873'
        : 'a99a5b94-8f1f-4012-9e40-cdd6615c3679',
  }),
  msTeamsReactFeature({}),
  commentsReactFeature({}),
  assignmentsReactFeature({
    gradeDisplayMode: GradeDisplayMode.Points,
    submitForGradingLabel: 'Share with Teacher',
  }),
  notificationDbReactFeature({}),
  featurePreviewReactFeature(),
  directMessagesReactFeature({}),
  canvasReactFeature({}),
  pdfGenerationReactFeature({}),
  sheetMusicReactFeature({
    getSoundEffects: () => {
      return require('./SoundEffects').default
    },
    getKeyboard: () => {
      return require('./components/keyboard/Keyboard').Keyboard
    },
    instruments: INSTRUMENTS,
  }),
  cleverReactFeature({
    consumerKey: '1d468362345c878b180f',
  }),
  badgesReactFeature({
    getSoundEffects: () => {
      return require('./SoundEffects').default
    },
  }),
  schoologyReactFeature({}),
  lessonsReactFeature({
    disableStudentAssignment: true,
    disableLinkGeneration: true,
    disableSharing: true,
  }),
  graphicDragDropReactFeature({}),
  videoOnDemandReactFeature({}),
  reportsReactFeature({}),
  searchReactFeature({}),
  standardSetsReactFeature({}),
  courseReportsReactFeature({}),
  widgetsReactFeature({}),
  widgetTemplatesReactFeature({}),
  notificationPreferencesReactFeature({}),
  updatesReactFeature({}),
  tagsReactFeature({}),
  googleClassroomReactFeature({}),
  courseViewsReactFeature({}),
])

if (!isInNode()) {
  const { addCustomEmojis } = require('./resources/emoji')
  addCustomEmojis(web.deps.resources)
  const { registerHooks } = require('./resources/hooks')
  registerHooks(web)
  const { addClassConfigurationFields } = require('./resources/class_configuration')
  addClassConfigurationFields(web.deps.resources)
  const { addClassAssignmentFields } = require('./resources/class_assignment_fields')
  addClassAssignmentFields(web.deps.resources)

  require('./hooks/createClass').default(web)
  require('./hooks/exploreBanner').default(web)
  require('./resources/ExploreLookingFor/sheetMusic').default(web)
  require('./resources/ExploreLookingFor/videos').default(web)
  require('./hooks/support').default(web)
  require('./hooks/exploreHome').default(web)
  require('./hooks/exploreFilters').default(web)
  require('./hooks/classRoutes').default(web)
  require('./hooks/student').default(web)
  require('./hooks/composeRoutes').default(web)
  require('./hooks/defaultSegmentThumbnail').default(web)
  require('./resources/SegmentGridRenderer/SheetMusicRenderer').default(web)
}

export default web
