import { useAssignmentQuery, useAssignmentSubmissionQuery } from '../schema'

export interface UseAssignmentSubmissionOpts {
  studentId: string
  classId: string
  assignmentId: string
}

/**
 * When you only know the assignment ID, student ID, and class ID, use this hook to load
 * the assignment and resolve the full submission.
 */
export function useAssignmentSubmission({
  studentId,
  classId,
  assignmentId,
}: UseAssignmentSubmissionOpts) {
  const assignmentQuery = useAssignmentQuery({
    variables: { id: assignmentId },
  })
  const assignment =
    assignmentQuery.data?.node?.__typename === 'Assignment' ? assignmentQuery.data.node : undefined
  const submissionId = assignment?.submissions?.edges.find(
    edge => edge.node.studentId === studentId && edge.node.classId === classId,
  )?.node.id
  const submissionQuery = useAssignmentSubmissionQuery({
    variables: {
      id: submissionId || '',
    },
    skip: !submissionId,
  })

  return {
    loading: submissionQuery.loading || assignmentQuery.loading,
    assignment:
      assignmentQuery.data?.node?.__typename === 'Assignment'
        ? assignmentQuery.data.node
        : undefined,
    submission:
      submissionQuery.data?.node?.__typename === 'AssignmentSubmission'
        ? submissionQuery.data.node
        : undefined,
  }
}
