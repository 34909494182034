import { getFileInfo } from './File'
import { useGenericFetchUrlQuery } from './schema'

export interface UseFileUrl {
  error?: boolean
  loading?: boolean
  url?: string
}
export function useFileUrl(_filename: string | null | undefined): UseFileUrl {
  const { filename } = getFileInfo(_filename || '')
  const shouldFetch = !filename.startsWith('http') && !filename.startsWith('data:')
  const { data, loading, error } = useGenericFetchUrlQuery({
    variables: {
      path: filename,
    },
    skip: !filename || !shouldFetch,
  })
  const urlResult = data?.genericFetchUrl?.signedUrl

  return shouldFetch
    ? { url: urlResult, loading, error: !!error }
    : {
        url: filename,
        loading: false,
        error: false,
      }
}
