import { GroupsConfiguration } from '@thesisedu/feature-assignments-core'
import React from 'react'

import { GroupsStudent } from './types'

export interface GroupsContextValue {
  groups: GroupsConfiguration
  classId: string
  onChange: (groups: GroupsConfiguration) => void
  students: Record<string, GroupsStudent[]>
  ungroupedStudents: GroupsStudent[]
}
export const GroupsContext = React.createContext<GroupsContextValue | undefined>(undefined)

export function useGroupsContext(): GroupsContextValue | undefined
export function useGroupsContext(require: false): GroupsContextValue | undefined
export function useGroupsContext(require: true): GroupsContextValue
export function useGroupsContext(require?: boolean): GroupsContextValue | undefined {
  const context = React.useContext(GroupsContext)
  if (!context && require) {
    throw new Error('GroupsContext is required, yet not provided.')
  }
  return context
}
