import React from 'react'

import { styled, s, HSpaced } from '../..'
import { HSpacedProps } from '../HSpaced/HSpaced'

export interface ContainerProps extends HSpacedProps {
  children: React.ReactElement[] | React.ReactElement
}
function _Container(
  { children, ...rest }: ContainerProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <__Container ref={ref} {...rest} space={false} justify={'stretch'}>
      {children}
    </__Container>
  )
}

const __Container = styled(HSpaced)`
  align-self: center;
  justify-content: center;
  padding: ${s.size('xxs')};
  border-radius: ${s.var('radii.1')};
  background: var(--segmented-control-background, ${s.color('element')});
  flex-wrap: wrap;
`

export const Container = React.forwardRef(_Container)
