import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ClassTaskSummaryProviderProps } from '@thesisedu/feature-classes-react'
import { styled, Body } from '@thesisedu/web'
import pluralize from 'pluralize'
import React from 'react'
import { Link } from 'react-router-dom'

import { useGradingTeacherClassTaskContext } from '../../contexts/GradingTeacherClassTaskContext'

export function UngradedAssignmentsClassTask({ classId }: ClassTaskSummaryProviderProps) {
  const { classes } = useGradingTeacherClassTaskContext(false) || {}
  const { assignmentsToGrade, assignmentsToGradeIsMax } =
    classes?.find(cls => cls.classId === classId) || {}

  if (assignmentsToGrade?.length) {
    const areIs = assignmentsToGrade.length === 1 ? 'is' : 'are'
    return (
      <Link to={`/teacher/classes/${classId}/grades?ungraded`}>
        <Container color={'@red'} useDiv>
          <ExclamationCircleOutlined className={'icon'} />
          {assignmentsToGradeIsMax
            ? `${assignmentsToGrade.length}+ assignments`
            : pluralize('assignment', assignmentsToGrade.length, true)}{' '}
          {areIs} not graded.
        </Container>
      </Link>
    )
  } else {
    return null
  }
}

export const Container = styled(Body)`
  display: flex;
  align-items: center;
  > .icon {
    font-size: ${props => props.theme['@size-l']};
    margin-right: ${props => props.theme['@size-xs']};
  }
`
