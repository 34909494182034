import {
  QuestionProps,
  QuestionAdditionalFields,
  QuestionInstructions,
} from '@thesisedu/feature-assignments-react'
import React from 'react'

import { SheetMusicPerformanceConfig } from './types'
import { SheetMusicEditor } from '../../../editor/SheetMusicEditor'

export function SheetMusicPerformanceEdit(props: QuestionProps<SheetMusicPerformanceConfig>) {
  return (
    <>
      <QuestionInstructions
        instructions={'Configure the sheet music students will perform to below.'}
        padded
      >
        <SheetMusicEditor
          value={props.question.config?.viewerProps}
          onChange={v => {
            if (props.onChange) {
              props.onChange({
                config: {
                  ...props.question.config,
                  viewerProps: v || { abc: '' },
                },
              })
            }
          }}
        />
      </QuestionInstructions>
      <QuestionAdditionalFields {...(props as any)} />
    </>
  )
}
