import { Form } from '@thesisedu/ui'
import React from 'react'

import { AssignmentSettingsOptions } from './types'

export interface AssignmentDateFieldsProps {
  OpensAt?: AssignmentSettingsOptions['OpensAt']
}
export function AssignmentDateFields({ OpensAt }: AssignmentDateFieldsProps) {
  return (
    <>
      {OpensAt !== undefined ? (
        OpensAt ? (
          <OpensAt />
        ) : null
      ) : (
        <Form.DatePickerField name={'openAt'} label={'Opens at'} granularity={'minute'} />
      )}
      <Form.DatePickerField
        name={'dueAt'}
        label={'Due at'}
        granularity={'minute'}
        defaultTime={'23:59:00'}
      />
    </>
  )
}
