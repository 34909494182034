import { styled, s } from '@thesisedu/ui'
import React from 'react'

import { useSegmentNavigationFooter } from './useSegmentNavigationFooter'
import { Segment } from '../types'

export interface SegmentNavigationVerticalFooterProps {
  segment: Segment | undefined
  isPreview?: boolean
}
export function SegmentNavigationVerticalFooter({
  segment,
  isPreview,
}: SegmentNavigationVerticalFooterProps) {
  const footer = useSegmentNavigationFooter({ segment, isPreview })

  if (footer) {
    return <FooterContainer>{footer}</FooterContainer>
  } else return null
}

const FooterContainer = styled.div`
  margin-top: ${s.size('m')};
  padding: 0 0 ${s.size('m')} 0;
  flex: 1;
`
