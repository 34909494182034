import React from 'react'
import { createPortal } from 'react-dom'

import {
  PlaybackFooterLocation,
  usePlaybackFooterContext,
} from '../../record/PlaybackFooterContext'

export interface PlaybackFooterItemProps {
  weight: number
  children: React.ReactNode
  location: PlaybackFooterLocation
}
export function PlaybackFooterItem({ location, weight, children }: PlaybackFooterItemProps) {
  const id = React.useId()
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null)
  const { addItem, removeItem } = usePlaybackFooterContext(true)
  React.useEffect(() => {
    addItem({ identifier: id, location, weight, children: <div ref={c => setContainer(c)} /> })
    return () => removeItem(id)
  }, [weight, location, id])

  return container ? createPortal(children, container) : null
}
