import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useViewerContext } from '@thesisedu/feature-users-react'
import { Random } from '@thesisedu/feature-utils'
import {
  BaseWidgetConfig,
  DraggableNode,
  NestedEditorKey,
  RequireNestedEditors,
  SerializedWidget,
  WithoutNestedEditors,
} from '@thesisedu/feature-widgets-core'
import { createEditor, LexicalEditor, NodeKey } from 'lexical'
import { cloneDeep } from 'lodash'
import React from 'react'

import { $isWidgetNode, WidgetNode } from './WidgetNode'
import { WidgetSettings } from './WidgetSettings'
import { useEditorContext } from '../../../lexical/context/EditorContext'
import {
  ElementsGroup,
  useElementsMenuItem,
} from '../../../lexical/ui/ElementsMenu/ElementsMenuContext'
import { SettingsDropdownItem } from '../../../lexical/ui/SettingsDropdown/SettingsDropdownContext'
import { WidgetResource } from '../../../types'

export interface SimpleWidgetProps<Config extends BaseWidgetConfig> {
  config: RequireNestedEditors<Config>
  id: string
  node: WidgetNode<any, Config>
  editor: LexicalEditor
  readOnly?: boolean
}
export interface HiddenContext {
  group?: string
}

export interface SimpleWidgetDefinition<Config extends BaseWidgetConfig, Type extends string> {
  /** The identifier for the widget; this is also the Lexical node type. */
  identifier: Type
  /** The icon to display inside the add menu with the widget. */
  icon: React.ReactElement
  /** The human title for the widget. */
  title: string
  /** This controls the order the element appears in inside the add content menu. */
  weight: number
  /** The group the widget appears in when viewing the add elements menu. */
  group: ElementsGroup | string
  /** An optional filter for when this element should be hidden to add for users. */
  hidden?: boolean | ((context: HiddenContext) => boolean)
  /** The Widget element itself. */
  element: (props: SimpleWidgetProps<Config>) => React.ReactElement | null
  /** The default configuration to use when creating new widgets. */
  defaultConfig: WithoutNestedEditors<Config>
  /**
   * If your configuration contains support for nested editors, those
   * need to be specified here so they can be serialized / parsed properly.
   */
  nestedEditorKeys?: NestedEditorKey<Config>[]
  /** Settings dropdown items to show for the widget when the dragger icon is clicked on. */
  settingsDropdownItems?: Omit<SettingsDropdownItem, 'group' | 'filter'>[]
  /**
   * If this is specified, a generic settings item is created for the widget and a form
   * modal is opened, presenting the rest of these options whenever it is clicked. These
   * options need to be rendered as form items using the new UI, and the changes will
   * automatically save whenever the user closes the modal.
   */
  settingsModal?: () => React.ReactElement
  /**
   * By default, the dragger / drop indicators use the outer widget element, which has
   * a 100% width. If your widget uses alignment and might not be aligned to the left,
   * this means the dragger won't appear to the immediate right of your widget and will
   * instead show up at the far left of the page. Set this to true to use the first child
   * of the widget as the drag element instead, moving it closer to the widget.
   *
   * This, of course, means the first child needs to be positioned properly and not 100%
   * width, or you'll have the same problem.
   */
  draggerUsesFirstChild?: boolean
  /**
   * Determines if this widget does anything like animations, loading content remotely,
   * or anything else that wouldn't look great in a saved format like PDF / Image / etc.
   * If we are rendering the editor in one of these scenarios, these widgets are
   * automatically hidden.
   */
  isDynamic?: boolean
  /**
   * All widgets are wrapped inside a Lexical decorator element. If you would like to style
   * that element, you'll need to specify a class name here and create a global style (and
   * mount it in your element) to target that style.
   */
  wrapperClassName?: (node: WidgetNode<Type, Config>) => string | null
  /**
   * If this element should be full-width, specify that here.
   */
  fullWidth?: boolean
}

export function createWidgetResource<
  Config extends BaseWidgetConfig,
  Type extends string,
  SettingsConfig extends Partial<Config> = Partial<Config>,
>({
  identifier,
  icon,
  title,
  weight,
  group,
  hidden,
  isDynamic,
  defaultConfig,
  settingsDropdownItems,
  settingsModal,
  nestedEditorKeys,
  draggerUsesFirstChild,
  wrapperClassName,
  fullWidth,
  element: Element,
}: SimpleWidgetDefinition<Config, Type>): WidgetResource {
  const MemoElement = React.memo(Element, (prev, next) => {
    return prev.readOnly === next.readOnly && prev.config === next.config && prev.node === next.node
  })
  MemoElement.displayName = `WidgetElement(${identifier})`
  function ElementContainer(props: Omit<SimpleWidgetProps<Config>, 'readOnly' | 'editor'>) {
    const { readOnly, noDynamicWidgets } = useEditorContext(true)
    const [editor] = useLexicalComposerContext()
    if (noDynamicWidgets && isDynamic) return null
    return <MemoElement {...props} editor={editor} readOnly={readOnly} />
  }

  class WidgetClass extends WidgetNode<Type, Config> implements DraggableNode {
    static getType(): Type {
      return identifier
    }

    static clone(node: WidgetClass): WidgetClass {
      /**
       * This seems counter-intuitive, but we actually have to pass the ID through
       * otherwise the ID will change every time someone makes a change to the
       * widget inside the editor.
       */
      return new WidgetClass(cloneDeep(node.__config), node.__id)
    }

    /** Creates a clone of the current widget, with a different ID. */
    clone(): WidgetClass {
      return new WidgetClass(cloneDeep(this.__config))
    }

    static importJSON({ type, id, ...config }: SerializedWidget<Type, Config>): WidgetClass {
      const result = new WidgetClass(cloneDeep(config as unknown as Config), id)

      // Unpack all of the nested editors.
      for (const key of nestedEditorKeys || []) {
        const nestedEditor = result.__config[key] as LexicalEditor
        if (!nestedEditor) throw new Error(`Editor at ${key.toString()} was not initialized.`)
        const serialized = (config as any)[key]
        if (serialized) {
          const editorState = nestedEditor.parseEditorState(serialized.editorState)
          if (!editorState.isEmpty()) {
            nestedEditor.setEditorState(editorState)
          }
        }
      }

      return result
    }

    constructor(config: WithoutNestedEditors<Config>, id: string = Random.id(), key?: NodeKey) {
      // Casting to as any here because of the nested editor keys, which
      // we handle below.
      super(cloneDeep(config as any), id, key)

      // Create all editors that haven't already been created.
      for (const key of nestedEditorKeys || []) {
        if (!this.__config[key] || (this.__config[key] as any)?.editorState) {
          this.__config[key] = createEditor() as any
        }
      }
    }

    exportJSON(): SerializedWidget<Type, Config> {
      return {
        type: identifier,
        id: this.__id,
        ...this.__config,
        ...(nestedEditorKeys || []).reduce((acc, nestedEditorKey) => {
          return {
            ...acc,
            [nestedEditorKey]: (this.__config[nestedEditorKey] as LexicalEditor).toJSON(),
          }
        }, {}),
      } as SerializedWidget<Type, Config>
    }

    getClassName(): string | null {
      const classNames = []
      if (wrapperClassName) {
        classNames.push(wrapperClassName(this))
      }
      if (fullWidth) {
        classNames.push('full-width')
      }
      return classNames.length ? classNames.join(' ') : null
    }

    decorate() {
      return (
        <ElementContainer
          node={this}
          id={this.__id}
          config={this.__config as RequireNestedEditors<Config>}
        />
      )
    }

    modifyMenuDOMElement(element: HTMLElement) {
      if (draggerUsesFirstChild) return (element.firstElementChild || element) as HTMLElement
      return element
    }
  }

  function AddWidget() {
    useElementsMenuItem({
      identifier: `widget-${identifier}`,
      icon,
      title,
      weight,
      group,
      isSelected(element) {
        return $isWidgetNode(element, identifier)
      },
      onCommit(element) {
        const node = new WidgetClass(defaultConfig)
        element.replace(node, false)
      },
    })

    return null
  }

  function Plugin() {
    const viewer = useViewerContext(false)
    const group = viewer?.group
    const shouldShowAddItem =
      hidden === undefined || (typeof hidden === 'function' ? !hidden({ group }) : !hidden)

    return (
      <>
        {shouldShowAddItem ? <AddWidget /> : null}
        <WidgetSettings<Config, SettingsConfig>
          identifier={identifier}
          title={title}
          settingsDropdownItems={settingsDropdownItems}
          settingsModal={settingsModal}
        />
      </>
    )
  }
  Plugin.displayName = `${identifier}Plugin`

  return {
    type: 'Widget',
    identifier,
    nodes: [WidgetClass],
    plugin: Plugin,
  }
}
