import { onMutationError } from '@thesisedu/feature-apollo-react'

import { SiteLicenseDocument, useCreateLicenseMutation as useMutation } from '../schema'

export const useCreateLicenseMutation: typeof useMutation = opts => {
  const [mutate, result] = useMutation({
    onError: onMutationError('There was an error creating that license.'),
    ...opts,
  })
  return [
    mutateOpts =>
      mutate({
        refetchQueries: [
          {
            query: SiteLicenseDocument,
            variables: { siteId: mutateOpts?.variables?.input.siteId },
          },
        ],
        awaitRefetchQueries: true,
        ...mutateOpts,
      }),
    result,
  ]
}
