import { flattenSegments } from '@thesisedu/feature-course-types'
import { mergeCollections } from '@thesisedu/feature-utils'
import React from 'react'

import {
  SplitViewPendingChanges,
  SplitViewPendingChangesContextValue,
} from './SplitViewPendingChangesContext'
import { Segment } from '../types'

export interface SplitViewPendingChangesResult {
  context: SplitViewPendingChangesContextValue
}
export function useSplitViewPendingChanges(): SplitViewPendingChangesResult {
  const [pendingChanges, setPendingChanges] = React.useState<SplitViewPendingChanges>({
    structureOverrides: [],
    metadataOverrides: [],
    createdSegments: [],
  })
  const foreignSegmentsRef = React.useRef<Record<string, Segment>>({})
  return {
    context: React.useMemo(() => {
      const addedSegmentIds = new Set<string>()
      const changedSegmentIds = new Set<string>()
      const structureOverrideMap: Record<string, string> = {}
      for (const structureOverride of pendingChanges.structureOverrides) {
        const foreignSegment = foreignSegmentsRef.current[structureOverride.id]
        if (foreignSegment) {
          flattenSegments([foreignSegment]).forEach(s => {
            addedSegmentIds.add(s.id)
            structureOverrideMap[s.id] = structureOverride.id
          })
        } else {
          changedSegmentIds.add(structureOverride.id)
        }
      }
      for (const metadataOverride of pendingChanges.metadataOverrides) {
        if (!addedSegmentIds.has(metadataOverride.id)) {
          changedSegmentIds.add(metadataOverride.id)
        }
      }
      return {
        changes: pendingChanges,
        setChanges(changes) {
          setPendingChanges(c => {
            return { ...c, ...changes }
          })
        },
        addedSegmentIds: [...addedSegmentIds],
        changedSegmentIds: [...changedSegmentIds],
        removeChanges(segmentId) {
          delete foreignSegmentsRef.current[segmentId]
          setPendingChanges(existingChanges => {
            const withoutId = existingChanges.structureOverrides.filter(o => o.id !== segmentId)
            const withoutIdMetadata = existingChanges.metadataOverrides.filter(
              o => o.id !== segmentId,
            )
            return {
              ...existingChanges,
              // Try to remove without the current ID first, and fallback to the parent if
              // there is no override associated with the current segment.
              metadataOverrides:
                withoutIdMetadata.length !== existingChanges.metadataOverrides.length
                  ? withoutIdMetadata
                  : existingChanges.metadataOverrides.filter(
                      o => o.id !== segmentId && o.id !== structureOverrideMap[segmentId],
                    ),
              structureOverrides:
                withoutId.length !== existingChanges.structureOverrides.length
                  ? withoutId
                  : existingChanges.structureOverrides.filter(
                      o => o.id !== segmentId && o.id !== structureOverrideMap[segmentId],
                    ),
            }
          })
        },
        addChanges(changes) {
          setPendingChanges(existingChanges => {
            return {
              ...existingChanges,
              metadataOverrides: mergeCollections(
                existingChanges.metadataOverrides,
                changes.metadataOverrides,
              ),
              structureOverrides: mergeCollections(
                existingChanges.structureOverrides,
                changes.structureOverrides,
              ),
            }
          })
        },
        addSegment(segment) {
          setPendingChanges(changes => {
            if (changes.createdSegments.some(s => s.id === segment.id)) {
              throw new Error('Cannot #addSegment() because it already exists')
            }
            return {
              ...changes,
              createdSegments: [...changes.createdSegments, segment],
            }
          })
        },
        foreignSegmentsRef,
      }
    }, [pendingChanges]),
  }
}
