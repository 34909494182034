import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { getSegmentName } from '@thesisedu/feature-course-types'
import { Text, Block, Button, s, styled } from '@thesisedu/ui'
import { Undo } from '@thesisedu/ui/icons'
import React from 'react'

import { usePlacementDrawerInnerContext } from './PlacementDrawerInnerContext'
import { isItemWithSegment } from '../../outline/types'
import { SplitViewDraggableOutlineItemProps } from '../SplitViewDraggableOutlineItem'

export interface PlacementDrawerDroppedOutlineItemProps
  extends SplitViewDraggableOutlineItemProps {}
export function PlacementDrawerDroppedOutlineItem(props: PlacementDrawerDroppedOutlineItemProps) {
  const { pickUp } = usePlacementDrawerInnerContext(true)
  const { attributes, setNodeRef, listeners, transform, transition } = useSortable({
    id: props.item.id,
    transition: null,
    disabled: {
      draggable: false,
      droppable: true,
    },
  })
  const { onMouseDown, ...restListeners } = listeners ?? {}
  return (
    <Container
      {...props}
      className={`${props.className} OutlineItem`}
      {...restListeners}
      ref={setNodeRef}
      {...attributes}
      style={{
        ...props.style,
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <InnerContainer>
        <Block right style={{ minWidth: 0 }} paddingRight={'m'}>
          <Text level={'s'} color={'secondary'}>
            Placing
          </Text>
          <Text color={'text'} truncate>
            {isItemWithSegment(props.item) ? getSegmentName(props.item.segment) : 'Item'}
          </Text>
        </Block>
        <ResetButton
          icon={<Undo />}
          variant={'ghost'}
          onPress={() => {
            pickUp()
          }}
        />
      </InnerContainer>
    </Container>
  )
}

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  height: 60px;
  max-height: 100%;
  flex: 1;
  background: ${s.color('element')};
  padding: ${s.size('s')} ${s.size('m')};
  border-radius: ${s.var('radii.1')};
  gap: ${s.size('s')};
  position: relative;
`
const Container = styled.div`
  display: flex;
  align-items: center;
`
const ResetButton = styled(Button)`
  position: absolute;
  right: ${s.size('xs')};
  top: 50%;
  transform: translateY(-50%);
`
