import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { Callout$, Dropdown } from '@thesisedu/ui'
import { InfoEmpty, Settings } from '@thesisedu/ui/icons'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  INSERT_PARAGRAPH_COMMAND,
  LexicalEditor,
} from 'lexical'
import React from 'react'

import {
  $isCalloutNode,
  $wrapInCalloutNode,
  CalloutNode,
  CUSTOM_ICONS,
} from '../../nodes/CalloutElementNode'
import { ElementsGroup, useElementsMenuItem } from '../../ui/ElementsMenu/ElementsMenuContext'
import {
  useSettingsDropdownItem,
  SettingsDropdownElementProps,
} from '../../ui/SettingsDropdown/SettingsDropdownContext'
import { SettingsDropdownEnumItem } from '../../ui/SettingsDropdown/SettingsDropdownEnumItem'
import { useNodeState } from '../../ui/SettingsDropdown/useNodeState'

export interface VariantItemProps {
  variant: Callout$.CalloutVariant
  selected: Callout$.CalloutVariant
  editor: LexicalEditor
  node: CalloutNode
  label: string
}
export function VariantItem({ variant, editor, node, label, selected }: VariantItemProps) {
  return (
    <Dropdown.Item.Checkbox
      checked={selected === variant}
      onCheckedChange={checked => {
        if (checked) {
          editor.update(() => {
            node.setVariant(variant)
          })
        }
      }}
    >
      {label}
    </Dropdown.Item.Checkbox>
  )
}

function CalloutSettingsDropdownItem({ editor, node }: SettingsDropdownElementProps) {
  if (!$isCalloutNode(node)) return null
  const selected = editor.getEditorState().read(() => node.getVariant())
  const common = { editor, node, selected }
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={<Settings />}>Callout Type</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        <VariantItem {...common} variant={'info'} label={'Info'} />
        <VariantItem {...common} variant={'success'} label={'Success'} />
        <VariantItem {...common} variant={'warning'} label={'Warning'} />
        <VariantItem {...common} variant={'error'} label={'Error'} />
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

const NONE_VALUE = 'none'
function CalloutCustomIconDropdownItem({
  editor,
  node,
}: SettingsDropdownElementProps<CalloutNode>) {
  const [customIcon, _setCustomIcon] = useNodeState(
    editor,
    node,
    node => node.getCustomIcon(),
    (node, value) => node.setCustomIcon(value),
  )
  const [variant] = useNodeState(
    editor,
    node,
    node => node.getVariant(),
    (node, value) => node.setVariant(value),
  )
  const setCustomIcon = (icon: keyof typeof CUSTOM_ICONS | typeof NONE_VALUE) => {
    if (icon === NONE_VALUE) {
      _setCustomIcon(undefined)
    } else {
      _setCustomIcon(icon)
    }
  }
  let icon
  if (customIcon) {
    icon = CUSTOM_ICONS[customIcon]
  } else if (variant) {
    const El = Callout$.iconMap[variant]
    icon = <El />
  }
  if (!icon) return null
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger icon={icon}>Icon</Dropdown.SubMenu.Trigger>
      <Dropdown.SubMenu.Content>
        <SettingsDropdownEnumItem
          value={NONE_VALUE}
          label={'Default'}
          selected={!customIcon}
          onChange={setCustomIcon}
        />
        {Object.keys(CUSTOM_ICONS).map(key => (
          <SettingsDropdownEnumItem
            key={key}
            value={key as keyof typeof CUSTOM_ICONS}
            label={key}
            selected={customIcon === key}
            onChange={setCustomIcon}
          />
        ))}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

export function CalloutPlugin() {
  const [editor] = useLexicalComposerContext()
  useElementsMenuItem({
    identifier: 'callout',
    icon: <InfoEmpty />,
    title: 'Callout',
    group: ElementsGroup.TextBlocks,
    weight: 22,
    isSelected(element) {
      return $isCalloutNode(element)
    },
    onCommit(element) {
      return $wrapInCalloutNode(element, 'info')
    },
  })
  useSettingsDropdownItem({
    identifier: 'callout-type',
    group: 'callout',
    weight: -1,
    filter(editor, node) {
      return $isCalloutNode(node)
    },
    element: CalloutSettingsDropdownItem,
  })
  useSettingsDropdownItem({
    identifier: 'callout-icon',
    group: 'callout',
    weight: 0,
    filter(editor, node) {
      return $isCalloutNode(node)
    },
    element: CalloutCustomIconDropdownItem,
  })

  React.useEffect(() => {
    return editor.registerCommand(
      INSERT_PARAGRAPH_COMMAND,
      () => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
          return false
        }

        // Only run this code on empty paragraph items inside a callout.
        const anchor = selection.anchor.getNode()
        const parent = anchor.getParent()
        if (!$isCalloutNode(parent) || anchor.getTextContent() !== '') {
          return false
        }

        // Only if we are the last child.
        if (!anchor.isLastChild()) {
          return false
        }

        // Append the current node after the callout.
        parent.insertAfter(anchor)
        anchor.select()
        return true
      },
      COMMAND_PRIORITY_LOW,
    )
  })

  return null
}
