import { Button } from '@thesisedu/ui'
import { ClipboardCheck } from '@thesisedu/ui/icons'
import { callAttention } from '@thesisedu/web'
import React from 'react'

export function JumpToRubricButton() {
  return (
    <Button
      variant={'primary'}
      onPress={() => {
        const element = document.getElementById('custom-rubric')
        element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        setTimeout(() => {
          if (element) callAttention(element)
        }, 500)
      }}
      children={'Jump to Rubric to Grade'}
      icon={<ClipboardCheck />}
    />
  )
}
