import { applyOverrides } from '@thesisedu/feature-course-types'
import React from 'react'

import { useSplitViewPendingChangesContext } from './SplitViewPendingChangesContext'
import { CourseConfiguration } from '../types'

export function useModifiedCurrentClassConfiguration(
  configuration: CourseConfiguration,
): CourseConfiguration {
  const { changes, foreignSegmentsRef } = useSplitViewPendingChangesContext(true)

  return React.useMemo(
    () =>
      applyOverrides(configuration, {
        ...changes,
        builtForeignSegments: foreignSegmentsRef.current ?? {},
      }) as CourseConfiguration,
    [changes.metadataOverrides, changes.structureOverrides],
  )
}
