import React from 'react'

import { styled, ThemeContext } from './styledTypes'

export interface CircleIconProps {
  children: React.ReactElement
  color?: string
  iconColor?: string
}
export function CircleIcon({ color, iconColor, children }: CircleIconProps) {
  const theme = React.useContext(ThemeContext)
  return (
    <Container style={{ background: color ? theme[color] : 'currentColor' }}>
      {React.cloneElement(children, {
        width: '0.8em',
        height: '0.8em',
        color: iconColor ? theme[iconColor] : 'white',
      })}
    </Container>
  )
}

const Container = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
