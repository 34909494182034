import { Widgets } from '@thesisedu/feature-course-types'
import { SimpleWidget } from '@thesisedu/feature-widgets-react'
import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import { DisplayModeSetting } from './DisplayModeSetting'
import { VideoElement } from './VideoElement'
import { VideoSettingsModal } from './VideoSettingsModal'

export default {
  identifier: 'Video',
  title: 'Video',
  icon: <Play />,
  weight: 13,
  group: SimpleWidget.ElementsGroup.Media,
  defaultConfig: { vodId: null, version: 1, displayMode: Widgets.VideoWidgetDisplayMode.Block },
  draggerUsesFirstChild: true,
  hidden({ group }) {
    return group !== 'ADMIN'
  },
  settingsDropdownItems: [
    {
      identifier: 'alignment',
      weight: 0,
      element: SimpleWidget.AlignmentSetting,
    },
    {
      identifier: 'display-mode',
      weight: 1,
      element: DisplayModeSetting,
    },
  ],
  wrapperClassName(node) {
    return node.__config.displayMode === Widgets.VideoWidgetDisplayMode.Inline
      ? 'fteditor-video-inline'
      : null
  },
  element: VideoElement,
  settingsModal: VideoSettingsModal,
} satisfies SimpleWidget.SimpleWidgetDefinition<Widgets.VideoConfig, 'Video'>
