import { ReactHooks } from '@thesisedu/feature-react'
import React from 'react'

import { Import } from './Import'
import { SegmentChildAlreadyExistsHandler } from './SegmentChildAlreadyExists'
import { CoursesReactFeature } from '../../CoursesReactFeature'

function CoursesExpectedErrors() {
  return (
    <>
      <SegmentChildAlreadyExistsHandler />
    </>
  )
}

export default function addExpectedErrorHook(feature: CoursesReactFeature) {
  feature.hookManager.registerMutateHook<React.ReactElement>(ReactHooks.WrapApp, children => {
    return (
      <>
        <CoursesExpectedErrors />
        <Import />
        {children}
      </>
    )
  })
}
