import { getStudentClassTabName } from './grading/helpers'
import { AssignmentsReactOptions } from './types'

export const DEFAULT_GET_ASSIGNMENT_LINK: AssignmentsReactOptions['getAssignmentLink'] = (
  viewer,
  assignment,
  classId,
  tab = 'all-students',
) => `/${viewer.group.toLowerCase()}/classes/${classId}/grades?grading=${assignment.id}&tab=${tab}`
export const DEFAULT_GET_ASSIGNMENT_SUBMISSION_LINK: AssignmentsReactOptions['getAssignmentSubmissionLink'] =
  (viewer, submission) =>
    `/${viewer.group.toLowerCase()}/classes/${submission.classId}/grades?grading=${
      submission.assignment.id
    }&tab=${getStudentClassTabName(submission.studentId, submission.classId)}`
