import { useResource } from '@thesisedu/feature-react'
import { VSpaced, Button } from '@thesisedu/ui'
import React from 'react'

import { AuthProviderHandlerResource } from '../types'

export interface PreAuthenticateScreenProps {
  providerId: string
  onContinue: () => void
}
export function PreAuthenticateScreen({ providerId, onContinue }: PreAuthenticateScreenProps) {
  const provider = useResource<AuthProviderHandlerResource>('AuthProviderHandler', providerId)
  if (!provider) throw new Error(`Auth provider '${providerId}' is invalid.`)
  if (!provider.preAuthenticateScreen) return null
  return (
    <VSpaced>
      <provider.preAuthenticateScreen />
      <div style={{ alignSelf: 'center' }}>
        <Button
          variant={'primary'}
          onPress={onContinue}
          children={`Sign in with ${provider.name}`}
        />
      </div>
    </VSpaced>
  )
}
