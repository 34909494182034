import { Popover$ } from '@thesisedu/ui'
import React from 'react'

import { Settings } from './Settings'

export interface PopoverProps extends Popover$.ContainerProps {
  children: React.ReactNode
}
export function Popover({ children, ...props }: Popover$.ContainerProps) {
  return (
    <Popover$.Container {...props}>
      <Popover$.Trigger children={children} />
      <Popover$.Content title={'Metronome Settings'} width={300}>
        <Settings />
      </Popover$.Content>
    </Popover$.Container>
  )
}
