import { styled, s, Text } from '@thesisedu/ui'
import React from 'react'

import { DefinitionConfig } from './common'
import { PluginLevel } from '../../../lexical/EditorPlugins'
import { SimpleWidget } from '../utils'

export function DefinitionElement({
  config,
  node,
}: SimpleWidget.SimpleWidgetProps<DefinitionConfig>) {
  return (
    <Container>
      <Text level={'s'} color={'secondary'} style={{ lineHeight: 1 }}>
        Definition
      </Text>
      <Text level={'xl'} weight={'bold'} as={'div'}>
        <SimpleWidget.NestedEditor
          hostNodeKey={node.getKey()}
          editor={config.word}
          level={PluginLevel.Bare}
          placeholder={'Word'}
        />
      </Text>
      <SimpleWidget.NestedEditor
        hostNodeKey={node.getKey()}
        editor={config.definition}
        level={PluginLevel.Bare}
        placeholder={'Definition'}
      />
    </Container>
  )
}

const Container = styled(SimpleWidget.WidgetContainer)`
  padding: ${s.size('1')};
`
