import { NotificationOutlined } from '@ant-design/icons'
import { Block, BlockSpin, H3, styled, StyledThemeContext } from '@thesisedu/web'
import { Badge, Button, Empty, Popover } from 'antd'
import { ButtonProps } from 'antd/es/button'
import React, { useContext, useState } from 'react'

import { UserTaskListItem } from './UserTask'
import { getTaskKey } from './contexts/CompletedTasksContext'
import { UserTaskListContextProvider, UserTaskListDisplay } from './contexts/UserTaskListContext'
import { UserTaskFilters } from './types'
import { useUserTasks } from './useUserTasks'

const TasksContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const REFRESH_INTERVAL = 30000
export interface UserTasksButtonProps extends Partial<ButtonProps> {
  filters?: UserTaskFilters
}
export function UserTasksButton({ filters, ...props }: UserTasksButtonProps) {
  const { loading, tasks } = useUserTasks({ filters, pollInterval: REFRESH_INTERVAL })
  const [popoverVisible, setPopoverVisible] = useState(false)
  const theme = useContext(StyledThemeContext)
  let popoverContent
  if (tasks.length) {
    popoverContent = (
      <UserTaskListContextProvider display={UserTaskListDisplay.Small}>
        <TasksContainer>
          {tasks.map(task => {
            return (
              <UserTaskListItem
                onClick={() => setPopoverVisible(false)}
                task={task}
                key={getTaskKey(task)}
              />
            )
          })}
        </TasksContainer>
      </UserTaskListContextProvider>
    )
  } else if (loading) {
    popoverContent = <BlockSpin />
  } else {
    popoverContent = (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nothing left to do!'} />
    )
  }

  return (
    <Popover
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      content={
        <>
          <H3>Notifications &amp; Tasks</H3>
          <Block marginTop={'@size-s'}>{popoverContent}</Block>
        </>
      }
      trigger={'click'}
      placement={'bottomRight'}
      overlayStyle={{ width: 450, maxWidth: '90vw' }}
    >
      <Badge
        count={tasks.length === 50 ? '50+' : tasks.length}
        style={{ zIndex: 1000, background: theme['@red'] }}
      >
        <Button icon={<NotificationOutlined />} {...props} />
      </Badge>
    </Popover>
  )
}
