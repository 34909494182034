import { StateSelect } from '@thesisedu/feature-districts-web'
import { useTheme } from '@thesisedu/react'
import { SendMail } from '@thesisedu/react/icons'
import { HSpaced } from '@thesisedu/web'
import { Button, Form, Input, Select } from 'antd'
import { getNames, getName } from 'country-list'
import React from 'react'

import { useTrialSignupMutation } from './queries/useTrialSignupMutation'
import { TrialSignupInput } from './schema'

const COUNTRIES = getNames()
export const US = getName('US')

export interface TrialRequestFormProps {
  onComplete: () => void
  showCampaignField?: boolean
  refetchRequests?: boolean
}
export const TrialRequestForm: React.FC<React.PropsWithChildren<TrialRequestFormProps>> = ({
  onComplete,
  showCampaignField = false,
  refetchRequests = false,
}) => {
  const [requestTrial, { loading }] = useTrialSignupMutation({
    onCompleted: () => {
      onComplete()
    },
    awaitRefetchQueries: refetchRequests,
    refetchQueries: refetchRequests ? ['trialRequests'] : [],
  })
  const defaultCountry = US // Replace this with something else if we want to bring the country field back.
  const [showStateCity, setShowStateCity] = React.useState(defaultCountry === US)
  const [form] = Form.useForm()
  const theme = useTheme()
  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const firstName = params.get('firstName')
    const lastName = params.get('lastName')
    form.setFieldsValue({
      email: params.get('email'),
      name: [firstName, lastName].filter(Boolean).join(' '),
      siteName: params.get('siteName'),
      state: params.get('state'),
      country: defaultCountry || params.get('country') || US,
      phone: params.get('phone'),
    })
    setShowStateCity((defaultCountry || params.get('country') || US) === US)
  }, [])
  return (
    <Form
      layout={'vertical'}
      form={form}
      hideRequiredMark
      onValuesChange={(_, allValues) => {
        if (!defaultCountry) {
          setShowStateCity(allValues.country === US)
        }
      }}
      onFinish={values => {
        const params = new URLSearchParams(window.location.search)
        const utmCampaign = params.get('utm_campaign')
        requestTrial({
          variables: {
            input: {
              ...values,
              country: defaultCountry || values.country,
              campaign: values.campaign?.trim() ? values.campaign : utmCampaign,
            } as TrialSignupInput,
          },
        })
      }}
    >
      <HSpaced align={'flex-end'} space={'@size-m'}>
        <Form.Item
          name={'firstName'}
          label={'Name'}
          rules={[{ required: true, message: 'A first name is required.' }]}
          style={{ flex: '1' }}
        >
          <Input placeholder={'First'} />
        </Form.Item>
        <Form.Item
          name={'lastName'}
          rules={[{ required: true, message: 'A last name is required.' }]}
          style={{ flex: '1' }}
        >
          <Input placeholder={'Last'} />
        </Form.Item>
      </HSpaced>

      <Form.Item
        name={'email'}
        label={'Email Address'}
        rules={[{ required: true, type: 'email', message: 'A valid email address is required.' }]}
      >
        <Input type={'email'} placeholder={'teacher@school.edu'} />
      </Form.Item>
      <Form.Item
        name={'siteName'}
        label={'School Name'}
        rules={[{ required: true, message: 'A school name is required.' }]}
      >
        <Input placeholder={'Somewhereville School'} />
      </Form.Item>
      {defaultCountry ? null : (
        <Form.Item
          name={'country'}
          label={'Country'}
          rules={[{ required: true, message: 'A country is required.' }]}
          initialValue={US}
        >
          <Select showSearch options={COUNTRIES.map(c => ({ label: c, value: c }))} />
        </Form.Item>
      )}
      {showStateCity ? (
        <>
          <Form.Item
            name={'state'}
            label={'State'}
            rules={[{ required: true, message: 'A state is required.' }]}
          >
            <StateSelect internationalOption />
          </Form.Item>
        </>
      ) : null}
      <Form.Item name={'phone'} label={'Phone Number'}>
        <Input placeholder={'Optional'} />
      </Form.Item>
      {showCampaignField ? (
        <Form.Item name={'campaign'} label={'Campaign'}>
          <Input style={{ fontFamily: theme['@code-family'] }} />
        </Form.Item>
      ) : null}
      <Button
        loading={loading}
        block
        type={'primary'}
        children={'Submit Trial Request'}
        icon={<SendMail />}
        htmlType={'submit'}
      />
    </Form>
  )
}
