import { SharingType } from './schema'

export interface ShareFilterOpt {
  value?: string
  label: string
}
export const SHARE_FILTER_OPTS = [
  { value: 'none', label: 'All Content' },
  { value: SharingType.Own, label: 'My Content' },
  { value: SharingType.IveShared, label: "Content I've Shared" },
  { value: SharingType.SharedWithMe, label: 'Shared with Me' },
]
