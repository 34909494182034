import { AntIconWrapper, PageHead, PageHeader, useBreadcrumb } from '@thesisedu/web'
import { Button } from 'antd'
import { Plus } from 'iconoir-react'
import React from 'react'
import { Routes, Route, useNavigate, useParams } from 'react-router-dom'

import { CreateTag } from './CreateTag'
import { EditTag } from './EditTag'
import { TagList } from './TagList'

function Edit() {
  const { tagId } = useParams()
  return <EditTag tagId={tagId} />
}

function Root() {
  const navigate = useNavigate()
  return (
    <>
      <PageHead title={'Manage Tags'} />
      <PageHeader title={'Manage Tags'}>
        <Button
          type={'primary'}
          icon={<AntIconWrapper children={<Plus />} />}
          children={'Create Tag'}
          onClick={() => navigate('create')}
        />
      </PageHeader>
      <TagList showNameFilter />
    </>
  )
}

export function ManageTags() {
  useBreadcrumb({ title: 'Tags' })
  return (
    <Routes>
      <Route path={'/create'} element={<CreateTag />} />
      <Route path={'/:tagId'} element={<Edit />} />
      <Route path={'/'} element={<Root />} />
    </Routes>
  )
}
