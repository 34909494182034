import { List } from '@thesisedu/react'
import React from 'react'

import { UploadListItem } from './UploadListItem'
import { Upload } from './types'

export interface UploadListProps {
  uploads: Upload[]
  onUploadClick: (upload: Upload) => any
}
export const UploadList: React.FC<React.PropsWithChildren<UploadListProps>> = ({
  uploads,
  onUploadClick,
}) => (
  <List>
    {uploads.map(upload => (
      <UploadListItem onClick={() => onUploadClick(upload)} upload={upload} key={upload.mediaId} />
    ))}
  </List>
)
