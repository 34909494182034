import { Grid, LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncSummaryItem } from './ClassSyncSummaryItem'
import { useClassSyncSummary } from '../useClassSyncSummary'

export interface ClassSyncSummaryProps {
  classId: string
}
export function ClassSyncSummary({ classId }: ClassSyncSummaryProps) {
  const { deleteSync, loading, syncs } = useClassSyncSummary(classId)

  if (loading) {
    return <LoadingIndicator.InlineLabeled label={'Loading LMS integrations...'} />
  } else if (syncs.length) {
    return (
      <Grid columnWidth={250}>
        {syncs.map(sync => {
          return (
            <ClassSyncSummaryItem
              key={sync.syncId}
              sync={sync}
              onDelete={() => deleteSync(sync.syncId)}
            />
          )
        })}
      </Grid>
    )
  } else {
    return null
  }
}
