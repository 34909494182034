import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import Field, * as Field$ from '../Field'

export function FormField<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>({ children, ...props }: ConnectedFormFieldProps<Field$.FieldProps, TFieldValues, FieldName>) {
  return (
    <FormFieldConnector {...props}>
      <Field children={children} />
    </FormFieldConnector>
  )
}
