import { useScrollIfRequired } from '@thesisedu/web/react-dnd'
import React from 'react'
import { DragSourceMonitor } from 'react-dnd'

export interface ScrollContextValue {
  onCollect: (monitor: DragSourceMonitor) => void
  onDragEnd: () => void
}
export const ScrollContext = React.createContext<ScrollContextValue | undefined>(undefined)

export interface ScrollContextProviderProps {
  containerRef: React.RefObject<HTMLElement>
  children: React.ReactElement
}
export function ScrollContextProvider({ containerRef, children }: ScrollContextProviderProps) {
  const existingContext = useScrollContext(false)
  const [onCollect, onDragEnd] = useScrollIfRequired(containerRef)
  if (existingContext) {
    return children
  } else {
    return <ScrollContext.Provider value={{ onCollect, onDragEnd }} children={children} />
  }
}

export function useScrollContext(): ScrollContextValue | undefined
export function useScrollContext(require: false): ScrollContextValue | undefined
export function useScrollContext(require: true): ScrollContextValue
export function useScrollContext(require?: boolean): ScrollContextValue | undefined {
  const context = React.useContext(ScrollContext)
  if (!context && require) {
    throw new Error('ScrollContext is required, yet not provided.')
  }
  return context
}
