import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Form, Modal$, Modal, useToast } from '@thesisedu/ui'
import { Check } from '@thesisedu/ui/icons'
import React from 'react'

import { useFreeUsernameMutation } from '../schema'

export interface FreeUsernameModalProps extends Omit<Modal$.ModalProps, 'children'> {}
export function FreeUsernameModal(props: FreeUsernameModalProps) {
  const form = Form.useForm<{ username: string }>()
  const toast = useToast()
  const [freeUsername, { loading }] = useFreeUsernameMutation({
    onCompleted() {
      toast({
        title: 'Username freed!',
        status: 'success',
      })
    },
    onError: onMutationError(
      'There was an error freeing that username.',
      ['NOT_FOUND_ERROR'],
      () => {
        toast({
          title: 'That username does not exist or they are currently active.',
          status: 'error',
        })
      },
    ),
  })
  return (
    <Form.Modal
      title={'Free Username'}
      {...props}
      form={form}
      onFinish={async values => {
        const result = await freeUsername({
          variables: {
            input: {
              username: values.username,
            },
          },
        })
        return !!result.data
      }}
    >
      <Form.TextField name={'username'} label={'Username'} required autoComplete={'off'} />
      <Modal.Footer>
        <Modal.CloseButton />
        <Form.Submit children={'Free Username'} icon={<Check />} loading={loading} />
      </Modal.Footer>
    </Form.Modal>
  )
}
