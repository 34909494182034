import ApolloReactFeature, {
  ApolloReactHooks,
  ExpectedErrorContext,
  ExpectedErrorPayload,
  useEnrichedBackgroundJobs,
} from '@thesisedu/feature-apollo-react'
import { useFeature } from '@thesisedu/feature-react'
import { LoadingIndicator, ProgressBar$, HSpaced, Toast$ } from '@thesisedu/ui'
import { CheckCircle, WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { UserBackgroundJobContext } from './UserBackgroundJobContext'
import { error, debug } from '../log'
import { BackgroundJobStatus, useViewerBackgroundJobsQuery } from '../schema'

const COMPLETE_STATUSES: BackgroundJobStatus[] = [
  BackgroundJobStatus.Error,
  BackgroundJobStatus.Success,
]

const ACTIVE_JOBS_INTERVAL = 2500
const JOB_DONE_TIMEOUT = 2500
export interface UserBackgroundJobWatcherProps {
  children: React.ReactNode
}
export function UserBackgroundJobWatcher({ children }: UserBackgroundJobWatcherProps) {
  const { data } = useViewerBackgroundJobsQuery()
  const allJobs = data?.viewer?.backgroundJobs || []
  const jobs = useEnrichedBackgroundJobs(allJobs)

  // These keep track of keeping jobs around for a few seconds to reveal their final state.
  const [showingFinalMessageForJobs, setShowingFinalMessageForJobs] = React.useState<string[]>([])
  const completeJobTimeouts = React.useRef<Record<string, any>>({})
  const progressJobsRef = React.useRef<string[] | undefined>()

  const hasJobs = allJobs.filter(j => !COMPLETE_STATUSES.includes(j.status)).length
  const apolloFeature = useFeature(ApolloReactFeature)

  // Automatically poll for job updates if there are active jobs in progress.
  React.useEffect(() => {
    if (hasJobs) {
      const handle = setInterval(() => {
        apolloFeature.client.refetchQueries({
          include: ['viewerBackgroundJobs'],
        })
      }, ACTIVE_JOBS_INTERVAL)
      return () => {
        clearInterval(handle)
      }
    }
  }, [hasJobs])

  React.useEffect(() => {
    for (const job of jobs) {
      // We only want to act on job status changes if we were already monitoring it.
      if (progressJobsRef.current?.includes(job.id)) {
        if (COMPLETE_STATUSES.includes(job.status)) {
          if (job.onComplete) {
            debug('calling onComplete for job %s', job.id, job)
            new Promise(resolve => resolve(job.onComplete!(job))).catch((err: any) => {
              error('error in onComplete for job %O', job)
              error(err)
            })
          }
          if (job.status === BackgroundJobStatus.Error && job.errorDetails) {
            debug(
              'expected error %s found in background job, notifying hooks',
              job.errorDetails.code,
            )
            apolloFeature.hookManager
              .mutateHook<ExpectedErrorPayload, ExpectedErrorContext>(
                ApolloReactHooks.ExpectedError,
                {
                  message: job.errorDetails.message,
                  extensions: job.errorDetails.extensions,
                  shouldRetry: false,
                },
                { code: job.errorDetails.code },
              )
              .catch(err => {
                debug('error when processing ExpectedError hooks for background job')
                debug(err)
              })
          }
          setShowingFinalMessageForJobs(j => [...j, job.id])
          if (!job.resource.render) {
            completeJobTimeouts.current[job.id] = setTimeout(() => {
              setShowingFinalMessageForJobs(j => j.filter(id => id !== job.id))
            }, JOB_DONE_TIMEOUT)
          }
        }
      }
    }

    // Update the progressJobsRef so we can keep track of which jobs were in progress in the lifetime
    // of this component.
    progressJobsRef.current = jobs.filter(j => !COMPLETE_STATUSES.includes(j.status)).map(j => j.id)
  }, [jobs])

  // Clear the timeouts when the component is unmounted.
  React.useEffect(() => {
    return () => {
      for (const v of Object.values(completeJobTimeouts.current)) {
        clearTimeout(v)
      }
    }
  }, [])

  return (
    <>
      <UserBackgroundJobContext.Provider
        value={React.useMemo(
          () => ({
            allJobs,
            enrichedJobs: jobs,
          }),
          [jobs, allJobs],
        )}
      >
        {children}
      </UserBackgroundJobContext.Provider>
      {jobs
        // We only want to show jobs we are currently showing the final state for, or jobs that are
        // in progress.
        .filter(
          job =>
            !COMPLETE_STATUSES.includes(job.status) || showingFinalMessageForJobs.includes(job.id),
        )
        .map(job => {
          const RenderComponent = job.resource.render
          const children = (
            <Toast$.Root
              key={job.id}
              id={job.id}
              open
              onOpenChange={() => {}}
              status={
                job.status === BackgroundJobStatus.Success
                  ? 'success'
                  : job.status === BackgroundJobStatus.Error
                  ? 'error'
                  : undefined
              }
            >
              <Toast$.Icon>
                {job.status === BackgroundJobStatus.Success ? (
                  <CheckCircle />
                ) : job.status === BackgroundJobStatus.Error ? (
                  <WarningCircle />
                ) : (
                  <LoadingIndicator />
                )}
              </Toast$.Icon>
              <Toast$.Title>{job.label}</Toast$.Title>
              {job.progress && job.status === BackgroundJobStatus.Processing ? (
                <Toast$.Description>
                  <ProgressBar$.Container
                    maxValue={job.progress.total}
                    value={job.progress.current}
                  >
                    <HSpaced top={'xxs'}>
                      <ProgressBar$.Bar style={{ flex: 1 }} />
                      <ProgressBar$.ValueLabel level={'s'} color={'secondary'} />
                    </HSpaced>
                  </ProgressBar$.Container>
                </Toast$.Description>
              ) : null}
            </Toast$.Root>
          )

          if (RenderComponent) {
            return (
              <RenderComponent
                key={job.id}
                children={children}
                job={job}
                onClose={() => {
                  setShowingFinalMessageForJobs(j => j.filter(id => id !== job.id))
                }}
              />
            )
          } else {
            return children
          }
        })}
    </>
  )
}
