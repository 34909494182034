import React from 'react'

import { QuestionProps } from '../../../types'
import { MultipleChoiceEditor } from '../MultipleChoiceEditor'
import { QuestionAdditionalFields } from '../QuestionAdditionalFields'

export function CheckboxSelectEdit(props: QuestionProps) {
  return (
    <>
      <MultipleChoiceEditor
        options={props.question.config?.options}
        onChange={options => {
          if (props.onChange) {
            props.onChange({
              config: {
                ...props.question.config,
                options,
              },
            })
          }
        }}
      />
      <QuestionAdditionalFields {...props} useExplanation />
    </>
  )
}
