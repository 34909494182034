import { useApolloClient } from '@apollo/client'
import { Button, Toast$, useToast } from '@thesisedu/ui'
import { Download } from '@thesisedu/ui/icons'
import React from 'react'

import { ReportDocument, ReportQuery, ReportQueryVariables } from './schema'

export interface ReportsContextValue {
  trackReport: (reportId: string, reportName: string, downloadUrl?: string | null) => void
}
export const ReportsContext = React.createContext<ReportsContextValue | undefined>(undefined)

export function ReportsContextProvider({ children }: React.PropsWithChildren<object>) {
  const client = useApolloClient()
  const toast = useToast()
  const updateRef = React.useRef<Toast$.UpdateOrCloseToast | null>(null)
  const onFinish = (reportId: string, downloadUrl: string, reportName: string) => {
    updateRef.current?.({
      title: `Your ${reportName} report is ready!`,
      status: 'success',
      action: (
        <a href={downloadUrl} target={'_blank'} download rel={'noopener noreferrer'}>
          <Button children={'Download'} icon={<Download />} />
        </a>
      ),
      timeout: null,
      closable: true,
      actionAlt: 'Download Report',
    })
  }
  const contextValue = React.useMemo<ReportsContextValue>(
    () => ({
      trackReport: (reportId, reportName, downloadUrl) => {
        if (downloadUrl) {
          onFinish(reportId, downloadUrl, reportName)
        } else {
          const observable = client.watchQuery<ReportQuery, ReportQueryVariables>({
            query: ReportDocument,
            pollInterval: 10000,
            variables: {
              id: reportId,
            },
          })
          updateRef.current = toast({
            title: `Running ${reportName} report...`,
            loading: true,
            key: reportId,
            action: <Button children={'Cancel'} onPress={() => observable.stopPolling()} />,
          })
          observable.subscribe(data => {
            const downloadUrl =
              data?.data?.node?.__typename === 'ReportJob' ? data.data.node.downloadUrl : undefined
            if (downloadUrl) {
              observable.stopPolling()
              onFinish(reportId, downloadUrl, reportName)
            }
          })
        }
      },
    }),
    [],
  )
  return <ReportsContext.Provider value={contextValue} children={children} />
}

export function useReportsContext(): ReportsContextValue | undefined
export function useReportsContext(require: false): ReportsContextValue | undefined
export function useReportsContext(require: true): ReportsContextValue
export function useReportsContext(require?: boolean): ReportsContextValue | undefined {
  const context = React.useContext(ReportsContext)
  if (!context && require) {
    throw new Error('ReportsContext is required, yet not provided.')
  }
  return context
}
