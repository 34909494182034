import React from 'react'

import { useColorScheme } from './useColorScheme'
import { styled, styledContainer } from '../styled'
import { getSize, getColor } from '../theme'
import { BodySmall } from '../typography'

export interface PageSectionProps {
  title?: string
  padded?: boolean
  [key: string]: any
}
/** @deprecated */
export function PageSection({ title, padded, ...rest }: PageSectionProps) {
  return (
    <>
      {title ? <_PageSectionHeader color={'@text-color-secondary'} children={title} /> : null}
      <_PageSection
        {...rest}
        padded={padded}
        hasTitle={!!title}
        isDark={useColorScheme() === 'dark'}
      />
    </>
  )
}
const _PageSectionHeader = styled(BodySmall)`
  margin-left: ${props => getSize(props.theme, '@size-s')};
  margin-top: ${props => getSize(props.theme, '@size-s')};
`
const _PageSection = styledContainer<{ padded?: boolean; hasTitle?: boolean; isDark: boolean }>`
  margin: ${props => (props.hasTitle ? getSize(props.theme, '@size-xs') : 0)} 0 ${props =>
    getSize(props.theme, '@size-s')} 0;
  background: ${props => getColor(props.theme, props.isDark ? '@gray-1' : '@component-background')};
  border-radius: 10px;
  padding: ${props => (props.padded ? getSize(props.theme, '@size-s') : 0)};
  overflow: hidden;
`
