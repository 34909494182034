import { ClassLeftNavHook, ClassRoutesHook } from '@thesisedu/feature-classes-react'
import { ClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import { FeatureWeb } from '@thesisedu/feature-web'

import { ENABLE_FREE_COMPOSE } from '../resources/class_configuration/enableFreeCompose'

function hasFreeCompose(cls: ClassFragment) {
  return !!cls?.configuration?.[ENABLE_FREE_COMPOSE]
}

export default function (web: FeatureWeb) {
  web.deps.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { cls }) => {
      if (!hasFreeCompose(cls)) {
        return routes.filter(route => route.key !== 'compositions')
      } else {
        return routes
      }
    },
  )
  web.deps.hookManager.registerMutateHook<ClassLeftNavHook>(
    'classes-react:class-left-nav',
    (items, { cls }) => {
      if (!hasFreeCompose(cls)) {
        return items
          .filter(item => item.element.key !== 'compositions')
          .map(item => {
            if (item.group === 'Grades & Compositions') {
              return { ...item, group: 'Grades' }
            } else return item
          })
      } else {
        return items
      }
    },
  )
}
