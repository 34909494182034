import { styled, s } from '@thesisedu/ui'

export type Alignment = 'left' | 'center' | 'right'
export const WidgetContainer = styled.div<{ $alignment?: Alignment }>`
  margin-top: ${s.size('1')};
  margin-bottom: ${s.size('1')};
  margin-left: ${({ $alignment }) => ($alignment === 'left' ? '0' : 'auto')};
  margin-right: ${({ $alignment }) => ($alignment === 'right' ? '0' : 'auto')};
  border-radius: ${s.var('radii.2')};
  background: ${s.color('gray.element')};
  color: ${s.color('gray.text')};
  transition:
    background 0.1s linear,
    color 0.1s linear,
    border 0.1s linear;
  border: solid 1px ${s.color('gray.border')};
`

export const HoverWidgetContainer = styled(WidgetContainer)`
  &:hover {
    background: ${s.color('gray.hoverElement')};
    color: ${s.color('primary.text')};
    border-color: ${s.color('primary.border')};
  }
`

export const StatusContainer = styled.div`
  color: ${s.color('secondary')};
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 5;
  min-height: 75px;
  font-size: ${s.size('m')};
  width: 100%;
`
