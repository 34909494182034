import { ArrowRightOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Result } from '@thesisedu/react'
import { BlockSpin, Body, BodyLarge, BodySmall, styled } from '@thesisedu/web'
import { Button, Empty, Input, Modal } from 'antd'
import classnames from 'classnames'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'

import { useClassSelector } from './ClassContext'
import { useOptions } from './options'

const ClassContainer = styled.div`
  overflow-y: auto;
  max-height: 500px;
  margin-top: ${props => props.theme['@size-m']};
`
const SelectorItem = styled.div`
  transition:
    color 0.1s linear,
    background 0.1s linear;
  padding: ${props => props.theme['@size-s']};
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme['@gray-5']};
  &.selected {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
  &.active {
    color: ${props => props.theme['@primary-color']};
    p:last-child {
      opacity: 0.75;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme['@border-color-split']};
  }
`
const SelectorRight = styled.div`
  margin-left: auto;
  padding-left: ${props => props.theme['@size-s']};
`
const Key = styled.span`
  padding: 1px ${props => props.theme['@size-xxs']};
  font-family: ${props => props.theme['@code-family']};
  background: ${props => props.theme['@background-color-base']};
  border: ${props => props.theme['@gray-2']};
  box-shadow: ${props => props.theme['@shadow-small']};
  display: inline-block;
`

export type VisibleChangeCallback = (visible: boolean) => boolean
export interface ClassSelectorModalProps {
  visible?: boolean
  onVisibleChange: (callbackOrValue: VisibleChangeCallback | boolean) => void
}
export function ClassSelectorModal({ visible, onVisibleChange }: ClassSelectorModalProps) {
  const { classes, selectedClassId, setSelectedClassId, loading, error } = useClassSelector()
  const [filter, setFilter] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const options = useOptions()
  const inputRef = useRef<any>(null)
  const viewer = useViewerContext(false)
  const navigate = useNavigate()
  useHotkeys('/, \\', () => {
    onVisibleChange(v => !v)
  })
  useEffect(() => {
    if (visible) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
      setTimeout(() => {
        setFilter('')
      }, 1) // Give the dialog some time to appear.
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus()
        }
      }, 100) // Give the dialog some extra time to appear.
    }
  }, [visible])
  const filteredClasses = classes.filter(cls => {
    const segments = filter.toLowerCase().trim().split(' ')
    return segments.every(segment => cls.name.toLowerCase().includes(segment))
  })
  useEffect(() => {
    setHighlightedIndex(0)
  }, [filteredClasses.length])
  const selectClassId = (id: string) => {
    setSelectedClassId(id)
    onVisibleChange(false)
  }

  let content = <BlockSpin />
  if (!loading && classes.length) {
    content = (
      <>
        <BodySmall color={'@text-color-secondary'} isBlock>
          <strong>Tip:</strong> You can also bring up this dialog by pressing <Key>/</Key>
        </BodySmall>
        <Input
          ref={inputRef}
          suffix={<SearchOutlined />}
          placeholder={'Search Classes'}
          value={filter}
          onChange={e => setFilter(e.target.value)}
          onKeyDown={e => {
            const isDown = e.key === 'ArrowDown'
            const isUp = e.key === 'ArrowUp'
            const isSlash = e.key === '/' || e.key === '\\'
            const isEnter = e.key === 'Enter'
            if (isDown || isUp || isSlash || isEnter) e.preventDefault()
            if (isDown) setHighlightedIndex(i => (i === filteredClasses.length - 1 ? 0 : i + 1))
            if (isUp) setHighlightedIndex(i => (i > 0 ? i - 1 : filteredClasses.length - 1))
            if (isSlash) onVisibleChange(false)
            if (isEnter) {
              const classId = filteredClasses[highlightedIndex]?.id
              if (classId) {
                selectClassId(classId)
              }
            }
          }}
        />
        {filteredClasses.length ? (
          <ClassContainer>
            {filteredClasses
              .filter(cls => !cls.archivedAt)
              .map((cls, index) => (
                <SelectorItem
                  key={cls.id}
                  className={classnames({
                    selected: highlightedIndex === index,
                    active: selectedClassId === cls.id,
                  })}
                  onClick={() => selectClassId(cls.id)}
                >
                  <div>
                    <BodyLarge weight={500}>{cls.name}</BodyLarge>
                    {viewer?.group === 'TEACHER' && cls.classId ? (
                      <Body color={'@text-color-secondary'}>
                        {options.classNameCapitalized} Code: {cls.classId.toUpperCase()}
                      </Body>
                    ) : null}
                  </div>
                  <SelectorRight>
                    <ArrowRightOutlined />
                  </SelectorRight>
                </SelectorItem>
              ))}
          </ClassContainer>
        ) : (
          <ClassContainer>
            <Empty description={'No classes match your filter.'} />
          </ClassContainer>
        )}
      </>
    )
  } else if (error) {
    content = <Result.Error description={'There was an error loading your classes.'} />
  } else if (!loading) {
    content = (
      <Empty description={"You don't have any classes!"}>
        <Button
          type={'primary'}
          icon={<PlusOutlined />}
          onClick={() => {
            onVisibleChange(false)
            navigate('/teacher/classes/create')
          }}
        >
          Create One
        </Button>
      </Empty>
    )
  }
  return (
    <Modal
      visible={visible}
      onCancel={() => onVisibleChange(false)}
      title={'Select Class'}
      footer={null}
      children={content}
    />
  )
}

export interface ClassSelectorModalContextValue {
  selectClass: () => void
}
export const ClassSelectorModalContext = React.createContext<ClassSelectorModalContextValue>({
  selectClass: () => {},
})

export function useClassSelectorModalContext(): ClassSelectorModalContextValue | undefined
export function useClassSelectorModalContext(
  require: false,
): ClassSelectorModalContextValue | undefined
export function useClassSelectorModalContext(require: true): ClassSelectorModalContextValue
export function useClassSelectorModalContext(
  require?: boolean,
): ClassSelectorModalContextValue | undefined {
  const context = useContext(ClassSelectorModalContext)
  if (!context && require) {
    throw new Error('ClassSelectorModalContext is required, yet not provided.')
  }
  return context
}

export function ClassSelectorModalContextProvider({ children }: React.PropsWithChildren<object>) {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <ClassSelectorModal visible={visible} onVisibleChange={setVisible} />
      <ClassSelectorModalContext.Provider
        value={{
          selectClass: () => setVisible(true),
        }}
        children={children}
      />
    </>
  )
}
