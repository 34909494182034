import type { Image$ } from '@thesisedu/ui'
import {
  $applyNodeReplacement,
  createEditor,
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedEditor,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

export type Alignment = 'left' | 'center' | 'right'
export type Radius = '0' | '1' | '2' | '3'
export type ImageAttribution = Omit<Image$.ImageAttribution, 'text'> & { text: string }

export interface ImageUploadFile {
  editor: LexicalEditor
  file: File
}

export interface ImagePayload {
  src?: string
  altText?: string
  attribution?: ImageAttribution | null
  /** Expressed as a percentage. */
  width?: 'auto' | number
  /** The cached dimensions of the image. */
  dimensions?: [number, number] | null
  /** The cached primary color of the image. */
  primaryColor?: string | null
  showCaption?: boolean
  alignment?: Alignment
  radius?: Radius
}
export type SerializedImageNodeV1 = Spread<
  ImagePayload & {
    type: 'Image'
    caption: SerializedEditor
    version: 1
  },
  SerializedLexicalNode
>
export type SerializedImageNode = SerializedImageNodeV1

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if ((domNode as HTMLElement).tagName?.toLowerCase() === 'img') {
    const { alt: altText, src } = domNode as HTMLImageElement
    const node = $createImageNode({ altText, src, radius: '1' })
    return { node }
  }
  return null
}

export class ImageNode extends DecoratorNode<any> {
  __src?: string
  __altText?: string
  __attribution?: ImageAttribution | null
  __width: 'auto' | number
  __dimensions: [number, number] | null
  __primaryColor: string | null
  __showCaption: boolean
  __caption: LexicalEditor
  __radius: Radius
  __alignment: Alignment
  __uploadFile?: ImageUploadFile

  static getType() {
    return 'Image'
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__attribution,
      node.__width,
      node.__showCaption,
      node.__caption,
      node.__radius,
      node.__alignment,
      node.__dimensions,
      node.__primaryColor,
      node.__uploadFile,
      node.__key,
    )
  }

  static importJSON(serializedNode: SerializedImageNodeV1): ImageNode {
    const { caption, ...rest } = serializedNode
    const node = $createImageNode(rest)
    const nestedEditor = node.__caption
    const editorState = nestedEditor.parseEditorState(caption.editorState)
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState)
    }
    return node
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: (node: Node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    }
  }

  constructor(
    src?: string,
    altText?: string,
    attribution?: ImageAttribution | null,
    width: 'auto' | number = 'auto',
    showCaption = false,
    caption: LexicalEditor = createEditor(),
    radius: Radius = '3',
    alignment: Alignment = 'center',
    dimensions: [number, number] | null = null,
    primaryColor: string | null = null,
    uploadFile?: ImageUploadFile,
    key?: NodeKey,
  ) {
    super(key)
    this.__src = src
    this.__altText = altText
    this.__attribution = attribution
    this.__width = width
    this.__showCaption = showCaption
    this.__caption = caption
    this.__radius = radius
    this.__alignment = alignment
    this.__dimensions = dimensions
    this.__primaryColor = primaryColor
    this.__uploadFile = uploadFile
  }

  exportJSON(): SerializedImageNodeV1 {
    let src = this.getSrc()
    if (src?.startsWith('data:')) {
      src = undefined
    }
    return {
      altText: this.getAltText(),
      attribution: this.getAttribution(),
      caption: this.__caption.toJSON(),
      width: this.getWidth(),
      showCaption: this.__showCaption,
      src,
      radius: this.getRadius(),
      alignment: this.getAlignment(),
      dimensions: this.getDimensions(),
      primaryColor: this.getPrimaryColor(),
      type: 'Image',
      version: 1,
    }
  }

  getShowCaption(): boolean {
    const self = this.getLatest()
    return self.__showCaption
  }

  setShowCaption(showCaption: boolean) {
    const writable = this.getWritable()
    writable.__showCaption = showCaption
  }

  getWidth(): 'auto' | number {
    const self = this.getLatest()
    return self.__width
  }

  setWidth(width: 'auto' | number) {
    const writable = this.getWritable()
    writable.__width = width
  }

  getSrc(): string | undefined {
    const self = this.getLatest()
    return self.__src
  }

  setSrc(src: string | undefined) {
    const writable = this.getWritable()
    // Also clear the cached information about the image.
    writable.__dimensions = null
    writable.__primaryColor = null
    writable.__src = src
  }

  getAltText(): string | undefined {
    const self = this.getLatest()
    return self.__altText
  }

  setAltText(altText: string | undefined) {
    const writable = this.getWritable()
    writable.__altText = altText
  }

  getAttribution(): ImageAttribution | null | undefined {
    const self = this.getLatest()
    return self.__attribution
  }

  setAttribution(attribution: ImageAttribution | null | undefined) {
    const writable = this.getWritable()
    writable.__attribution = attribution
  }

  getRadius(): Radius {
    const self = this.getLatest()
    return self.__radius
  }

  setRadius(radius: Radius) {
    const writable = this.getWritable()
    writable.__radius = radius
  }

  getAlignment(): Alignment {
    const self = this.getLatest()
    return self.__alignment
  }

  setAlignment(alignment: Alignment) {
    const writable = this.getWritable()
    writable.__alignment = alignment
  }

  getDimensions(): [number, number] | null {
    const self = this.getLatest()
    return self.__dimensions
  }

  setDimensions(dimensions: [number, number] | null) {
    const writable = this.getWritable()
    writable.__dimensions = dimensions
  }

  getPrimaryColor(): string | null {
    const self = this.getLatest()
    return self.__primaryColor
  }

  setPrimaryColor(primaryColor: string | null) {
    const writable = this.getWritable()
    writable.__primaryColor = primaryColor
  }

  clearUploadFile() {
    const writable = this.getWritable()
    writable.__uploadFile = undefined
  }

  setUploadFile(uploadFile: ImageUploadFile) {
    const writable = this.getWritable()
    writable.__uploadFile = uploadFile
  }

  getUploadFile() {
    const latest = this.getLatest()
    return latest.__uploadFile
  }

  updateAlignment(node: HTMLElement) {
    node.style.marginLeft = 'auto'
    node.style.marginRight = 'auto'
    if (this.__alignment === 'left') {
      node.style.marginLeft = '0'
    } else if (this.__alignment === 'right') {
      node.style.marginRight = '0'
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const div = document.createElement('div')
    const className = config.theme.image
    if (className) div.classList.add(className)
    const isVector = this.__src?.split('?')[0]?.endsWith('.svg')
    div.style.maxWidth = this.__width === 'auto' || !this.__width ? '100%' : `${this.__width}px`
    div.style.width = isVector ? '100%' : 'max-content'
    this.updateAlignment(div)
    return div
  }

  updateDOM(prevNode: ImageNode, dom: HTMLElement): false {
    dom.style.maxWidth = this.__width === 'auto' || !this.__width ? '100%' : `${this.__width}px`
    const isVector = this.__src?.split('?')[0]?.endsWith('.svg')
    dom.style.width = isVector ? '100%' : 'max-content'
    this.updateAlignment(dom)
    return false
  }

  decorate(): any {
    throw new Error('not implemented')
  }
}

export function $createImageNode({
  src,
  altText,
  attribution,
  width,
  showCaption,
  caption,
  radius,
  alignment,
  dimensions,
  primaryColor,
  uploadFile,
  key,
}: ImagePayload & {
  caption?: LexicalEditor
  key?: NodeKey
  uploadFile?: ImageUploadFile
} = {}): ImageNode {
  return $applyNodeReplacement(
    new ImageNode(
      src,
      altText,
      attribution,
      width,
      showCaption,
      caption,
      radius,
      alignment,
      dimensions,
      primaryColor,
      uploadFile,
      key,
    ),
  )
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
  return node instanceof ImageNode
}
