import {
  InfoCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import React from 'react'

import { styled } from './styledTypes'

export type CalloutType = 'info' | 'warning' | 'success' | 'error'
type CalloutMap<T> = {
  [key in CalloutType]: T
}

const colorMap: CalloutMap<string> = {
  info: '@blue',
  warning: '@orange',
  error: '@red',
  success: '@green',
}
export const iconMap: CalloutMap<React.FC<React.PropsWithChildren<any>>> = {
  info: InfoCircleOutlined,
  warning: ExclamationCircleOutlined,
  error: CloseCircleOutlined,
  success: CheckCircleOutlined,
}

export interface CalloutProps {
  type: CalloutType
  icon?: React.ReactElement
}
export function Callout({ children, type, icon }: React.PropsWithChildren<CalloutProps>) {
  const Icon = iconMap[type]
  return (
    <Container type={type}>
      <TopLeftIcon type={type}>{icon ? icon : <Icon />}</TopLeftIcon>
      {children}
    </Container>
  )
}

const Container = styled.div<{ type: CalloutType }>`
  padding: ${props => props.theme['@size-m']} ${props => props.theme['@size-l']};
  background: ${props => props.theme[`${colorMap[props.type]}-light`]};
  border-left: solid ${props => props.theme['@border-radius-base']}
    ${props => props.theme[colorMap[props.type]]};
  position: relative;
  border-radius: ${props => props.theme['@border-radius-base']};
  *:last-child {
    margin-bottom: 0;
  }
`
const TopLeftIcon = styled.div<{ type: CalloutType }>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  background: white;
  width: calc(${props => props.theme['@size-l']} + 6px);
  height: calc(${props => props.theme['@size-l']} + 6px);
  font-size: calc(${props => props.theme['@size-m']} + 4px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme[colorMap[props.type]]};
`
