import { ImageAttribution } from '@thesisedu/feature-widgets-core'
import { HSpaced, Select, TextField, VSpaced } from '@thesisedu/ui'
import React from 'react'

export interface ImageAttributionSettingsProps {
  value?: ImageAttribution
  onChange?: (value?: ImageAttribution) => void
}
export function ImageAttributionSettings({ value, onChange }: ImageAttributionSettingsProps) {
  return (
    <VSpaced space={'xs'}>
      <TextField
        label={'Attribution'}
        description={'Provide attribution for the source of this image.'}
        value={value?.text}
        onChange={text =>
          onChange?.({
            side: 'bottom',
            placement: 'outside',
            align: 'start',
            ...value,
            text,
          })
        }
      />
      {value ? (
        <HSpaced>
          <Select
            label={'Placement'}
            style={{ flex: 1 }}
            value={value.placement}
            onValueChange={placement => onChange?.({ ...value, placement })}
            size={'small'}
          >
            <Select.Item value={'outside'}>Outside</Select.Item>
            <Select.Item value={'inside'}>Inside</Select.Item>
          </Select>
          <Select
            label={'Side'}
            style={{ flex: 1 }}
            value={value.side}
            onValueChange={side => onChange?.({ ...value, side })}
            size={'small'}
          >
            <Select.Item value={'top'}>Top</Select.Item>
            <Select.Item value={'bottom'}>Bottom</Select.Item>
            <Select.Item value={'left'}>Left</Select.Item>
            <Select.Item value={'right'}>Right</Select.Item>
          </Select>
          <Select
            label={'Align'}
            style={{ flex: 1 }}
            value={value.align}
            onValueChange={align => onChange?.({ ...value, align })}
            size={'small'}
          >
            <Select.Item value={'start'}>Start</Select.Item>
            <Select.Item value={'end'}>End</Select.Item>
          </Select>
        </HSpaced>
      ) : null}
    </VSpaced>
  )
}
