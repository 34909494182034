import { Button } from '@thesisedu/react'
import { Plus } from '@thesisedu/react/icons'
import React from 'react'

import { JoinClassModal } from '../../../JoinClassModal'

export function JoinClass() {
  const [joinVisible, setJoinVisible] = React.useState(false)
  return (
    <>
      <JoinClassModal visible={joinVisible} onClose={() => setJoinVisible(false)} />
      <Button type={'primary'} icon={<Plus />} onClick={() => setJoinVisible(true)}>
        Join Class
      </Button>
    </>
  )
}
