import { ReportMetricResource } from '@thesisedu/feature-reports-react'
import { Keyframes } from '@thesisedu/react/icons'
import React from 'react'

import { getGradeOnClick } from './Grade'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'

export default function addMetric(feature: CourseReportsReactFeature) {
  feature.resources.addResource<ReportMetricResource>({
    type: 'ReportMetric',
    metricKey: 'numAttempts',
    summarization: 'average',
    identifier: 'numAttempts',
    icon: <Keyframes />,
    title: 'Attempts',
    description: 'The number of attempts on each assignment.',
    shortLabel: 'number of attempts',
    format: 'number',
    getOnClick: getGradeOnClick(feature, 'numAttempts'),
  })
}
