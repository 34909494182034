import { CustomEmojiResource, useResource } from '@thesisedu/feature-react'
import { styled } from '@thesisedu/web'
import { Mentions } from 'antd'
import { MentionProps } from 'antd/es/mentions'
import React from 'react'

export interface ExtensibleInputProps extends MentionProps {
  singleLine?: boolean
}
export function ExtensibleInput({ singleLine, ...props }: ExtensibleInputProps) {
  const resources = useResource<CustomEmojiResource>('CustomEmoji')
  const codes = new Set<string>()
  for (const resource of resources) {
    for (const code of resource.codes) {
      codes.add(code)
    }
  }
  return (
    <StyledMentions
      {...props}
      prefix={[':']}
      value={singleLine ? props.value?.replace(/(\r\n|\n|\r)/g, '') : props.value}
    >
      {[...codes].map(code => (
        <Mentions.Option key={code} value={`${code}:`} children={code} />
      ))}
    </StyledMentions>
  )
}
const StyledMentions = styled(Mentions)`
  &.ant-mentions {
    margin-bottom: 0;
  }
  input,
  textarea {
    background: ${props => props.theme['@background-color-base']};
  }
`
