import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { CourseFragmentDoc, useUpdateCourseMutation as useMutation } from '../schema'

export const useUpdateCourseMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('There was an error updating that course.'),
    update: updateMutation({
      fragment: CourseFragmentDoc,
      fragmentName: 'Course',
      path: 'updateCourse.course',
    }),
    ...opts,
  })
