import PlusCircleOutlined from '@ant-design/icons/lib/icons/PlusCircleOutlined'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Block } from '@thesisedu/web'
import { Result } from 'antd'
import React from 'react'

import { AddContentButton } from './AddContentButton'
import { useCourseContext } from '../contexts/CourseContext'
import { CreateGroupModalProps } from '../custom_content/CreateGroupModal'

export interface OutlineEmptyViewProps {
  topics?: CreateGroupModalProps['topics']
  termId: string
  readOnly?: boolean
  action?: React.ReactElement
  /** If you want to override the default empty message, pass it here. */
  message?: string
}
export function OutlineEmptyView({
  readOnly,
  termId,
  topics,
  action,
  message: _message,
}: OutlineEmptyViewProps) {
  const viewer = useViewerContext(false)
  const courseContext = useCourseContext(false)
  const message =
    _message ??
    (viewer?.group === 'TEACHER' && courseContext && !readOnly
      ? topics?.length
        ? 'It looks like you haven\'t added any content to this class yet. Click the "Add Content"\n' +
          'button below to start adding content.'
        : 'It looks like this class doesn\'t have a topic. Create your first topic using the "Add Topic" button below.'
      : "It looks like this class doesn't have any content yet.")
  return (
    <Block marginTop={'@size-xl'}>
      <Result
        icon={<PlusCircleOutlined size={64} />}
        title={'No content yet!'}
        subTitle={message}
        extra={
          action ??
          (viewer?.group === 'TEACHER' && courseContext && !readOnly
            ? [
                <div style={{ margin: '0 auto', display: 'inline-block' }}>
                  <AddContentButton termId={termId} topics={topics} />
                </div>,
              ]
            : undefined)
        }
      />
    </Block>
  )
}
