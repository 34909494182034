import * as Legacy from '.'
import { CONVERTERS } from './converters'
import { WidgetConverter } from './converters/types'
import { WeightedWidget, Widget } from '../WidgetNode'
import { getDefaultEditorValue } from '../defaults'
import { debug } from '../log'
import { EditorValue } from '../types'

export function getBlockValueFromAnyValue(
  value: Legacy.AnyEditorValue | undefined | null | string,
  opts?: ConvertWidgetOpts,
): EditorValue {
  if (typeof value === 'string') {
    return getDefaultEditorValue(
      convertWidget(
        {
          id: 'content',
          type: 'Content',
          config: { content: value, isMarkdown: true },
        },
        { skipCache: true },
      ),
    )
  } else if (Array.isArray(value)) {
    return getDefaultEditorValue(value.flatMap(v => convertWidget(v, opts)))
  } else if (value) {
    return value
  } else {
    return getDefaultEditorValue()
  }
}

const WIDGET_CACHE: Record<string, (Widget | WeightedWidget)[]> = {}
const useCache =
  typeof process === 'undefined' ||
  typeof process.env === 'undefined' ||
  !process.env.LAMBDA_TASK_ROOT

export interface ConvertWidgetOpts {
  skipCache?: boolean
  namespace?: string
}
export function convertWidget(
  value: Legacy.WeightedAnyWidget,
  opts?: ConvertWidgetOpts,
): WeightedWidget[]
export function convertWidget(value: Legacy.Widget | Widget, opts?: ConvertWidgetOpts): Widget[]
export function convertWidget(
  value: Legacy.Widget | Widget | Legacy.WeightedAnyWidget,
  opts: ConvertWidgetOpts = {},
): (Widget | WeightedWidget)[] {
  if (Legacy.isWidget(value)) {
    return [value]
  } else {
    const { skipCache, namespace } = opts
    const cacheKey = namespace ? `${namespace}-${value.id}` : value.id
    if (!WIDGET_CACHE[cacheKey] || !useCache || skipCache) {
      // Find a suitable converter.
      let converter: WidgetConverter<Legacy.Widget> | undefined = CONVERTERS.find(
        converter => converter.identifier === value.type,
      )
      if (!converter) {
        converter = CONVERTERS.find(converter => !converter.identifier)
      }

      if (converter) {
        debug('converting widget %s: %s with %s', value.type, value.id, converter.label)
        WIDGET_CACHE[cacheKey] = converter.convert(value, opts) as (Widget | WeightedWidget)[]
      } else {
        debug('could not find converter for widget %s: %s; dropping', value.type, value.id)
        WIDGET_CACHE[cacheKey] = []
      }
    }

    const result = WIDGET_CACHE[cacheKey]
    if (typeof value.weight !== 'undefined') {
      return result.map(widget => ({ ...widget, weight: value.weight }))
    } else {
      return result
    }
  }
}
