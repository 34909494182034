import { UserBadges } from '@thesisedu/feature-badges-react'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import { BodyLarge, H1, styled, ThemeProvider } from '@thesisedu/web'
import React from 'react'

import { SmallNavButton } from './components/SmallNavButton'
import { LargeDarkTransparentBox } from './components/styled'
// @ts-ignore
import sun from '../../assets/sketch/website/sun.svg'
// @ts-ignore
import treasure from '../../assets/sketch/website/treasure-chest-icon.svg'

export function Treasures() {
  const selectedClassId = useSelectedClassId(true)
  return (
    <Container>
      <LeftContent className={'left-content'}>
        <SmallNavButton
          subtitle={'Back to Prodigies'}
          title={'Go back'}
          color={'@white'}
          linkTo={`/student/classes/${selectedClassId}`}
          placement={'left'}
          rightContent={<SunImage src={sun} alt={'Sun'} />}
          dark
        />
      </LeftContent>
      <RightContent className={'right-content'}>
        <LargeDarkTransparentBox>
          <HeaderContainer>
            <TreasureImage src={treasure} alt={'Treasure'} />
            <div>
              <BodyLarge color={'@text-color-secondary'}>Collect them All</BodyLarge>
              <H1 isBlock={false} color={'@white'}>
                Treasure Chest
              </H1>
            </div>
          </HeaderContainer>
          <ContentContainer>
            <ThemeProvider theme={'dark'}>
              <UserBadges />
            </ThemeProvider>
          </ContentContainer>
        </LargeDarkTransparentBox>
      </RightContent>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: ${props => props.theme['@size-xl']};
`
const LeftContent = styled.div`
  margin-right: ${props => props.theme['@size-l']};
  width: 235px;
  padding-top: ${props => props.theme['@size-l']};
`
const RightContent = styled.div`
  width: 600px;
`
const HeaderContainer = styled.div`
  background: ${props => props.theme['@gray-7']};
  padding: ${props => props.theme['@size-m']};
  display: flex;
  align-items: center;
`
const TreasureImage = styled.img`
  height: 100px;
  margin: 0 ${props => props.theme['@size-m']} 0 ${props => props.theme['@size-s']};
`
const SunImage = styled.img`
  width: ${props => props.theme['@size-xl']};
`
const ContentContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 60vh;
`
