import { useStudentSelectorContext } from '@thesisedu/feature-classes-react'

import { useAssignLessonMutation } from './queries/useAssignLessonMutation'
import { LessonFragment } from './schema'

export type AssignStudents = () => Promise<any>
export const useLessonAssignment = (lesson?: LessonFragment): AssignStudents | null => {
  const [assign] = useAssignLessonMutation({
    refetchQueries: ['lessonStudentViewership'],
  })
  const { selectStudents } = useStudentSelectorContext(true)
  if (!lesson?.canAssign) return null
  return async () => {
    return selectStudents({
      saveText: 'Assign',
      callback: async result => {
        await assign({
          variables: {
            input: {
              id: lesson.id,
              assignments: result.students,
            },
          },
        })
      },
      initialValue: lesson?.students.edges
        .filter(s => s.class)
        .map(s => ({
          classId: s.class!.id,
          studentId: s.node.id,
        })),
    })
  }
}
