import { debug } from '../../../log'
import { Streams } from '../types'

export function stopStreams(streams: Streams, type: 'audio' | 'video' | undefined = undefined) {
  if (streams.stream) {
    debug('stopping stream %O (%s)', streams.stream, type ?? 'all')
    const method =
      type === undefined ? 'getTracks' : type === 'audio' ? 'getAudioTracks' : 'getVideoTracks'
    streams.stream[method]().forEach(track => track.stop())
  }
}
