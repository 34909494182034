import { Nullable } from '@thesisedu/feature-types'
import React from 'react'
import { FieldValues, FormProvider, useFormState } from 'react-hook-form'

import { ReactFormContextProvider, ReactFormContextProviderProps, useFormContext } from './Form'
import { isNative } from '../../utils/isNative'
import Button, * as Button$ from '../Button'

export interface FormSubmitProps extends Partial<Button$.ButtonProps> {
  disableUntilDirty?: boolean
  loadingWhenSubmitting?: boolean
  as?: React.ComponentType<React.PropsWithChildren<Button$.ButtonProps>>
}
export function FormSubmit({
  disableUntilDirty,
  loadingWhenSubmitting = true,
  as: Element = Button,
  ...rest
}: FormSubmitProps) {
  const { handleSubmit } = useFormContext(true)
  const { isDirty, isValid, isSubmitting } = useFormState()
  const disabled = rest.disabled || (disableUntilDirty && !isDirty) || (isNative && !isValid)
  const loading = rest.loading || (loadingWhenSubmitting && isSubmitting)
  return (
    <Element
      children={'Submit'}
      variant={'primary'}
      type={'submit'}
      data-testid={'FormSubmit'}
      {...rest}
      disabled={disabled}
      loading={loading}
      style={{ alignSelf: 'flex-start', ...rest.style }}
      onPress={e => {
        // This is here to be able to handle nested forms. Because nested form tags is technically
        // against the HTML spec, we have to manually call the submit handler whenever the submit
        // button is pressed. If we don't do this, no submit handler is called and the page is
        // refreshed.
        // This is fine, because we have handleSubmit() available to us inside the FormContext, which
        // is defined with each UI form.
        handleSubmit(e)
      }}
    />
  )
}

export type StandaloneFormSubmitProps<Values extends FieldValues> =
  ReactFormContextProviderProps<Values> & Omit<FormSubmitProps, 'form'>
export function StandaloneFormSubmit<Values extends FieldValues>({
  form,
  onFinish,
  onFinishFailed,
  ...rest
}: StandaloneFormSubmitProps<Values>) {
  return (
    <FormProvider {...form}>
      <ReactFormContextProvider form={form} onFinish={onFinish}>
        <FormSubmit
          {...rest}
          type={'button'}
          onPress={() =>
            form.handleSubmit(onFinish as any, async () => {
              if (onFinishFailed) {
                await onFinishFailed(form.getValues() as Partial<Nullable<Values>>)
              }
            })()
          }
        />
      </ReactFormContextProvider>
    </FormProvider>
  )
}
