import { ClassRoutesHook } from '@thesisedu/feature-classes-react'
import React from 'react'
import { Route } from 'react-router-dom'

import { Grades } from './Grades'
import { CourseAssignmentsReactFeature } from '../../CourseAssignmentsReactFeature'

export default function (feature: CourseAssignmentsReactFeature) {
  feature.hookManager.registerMutateHook<ClassRoutesHook>(
    'classes-react:class-routes',
    (routes, { group }) => {
      if (group === 'STUDENT') {
        return [...routes, <Route key={'grades'} path={'grades/*'} element={<Grades />} />]
      } else {
        return routes
      }
    },
  )
}
