import { FeatureReactRoot, installFeatureDevtools } from '@thesisedu/feature-react'
import React from 'react'
import ReactDOM from 'react-dom'

import { DevTools } from './DevTools'
import { error } from '../log'

const ID = 'ft-devtools'

export function install(root: FeatureReactRoot<any>) {
  installFeatureDevtools(root).catch(err => {
    error('error installing DevTools')
    error(err)
  })

  const existing = document.getElementById(ID)
  if (existing) {
    document.body.removeChild(existing)
  }

  const devToolsRoot = document.createElement('div')
  devToolsRoot.id = ID
  document.body.appendChild(devToolsRoot)

  return ReactDOM.createPortal(<DevTools />, devToolsRoot, 'devtools')
}
