import { SiteSelect } from '@thesisedu/feature-sites-web'
import { message, Modal, Button } from 'antd'
import React, { useState } from 'react'

import { useUpdateTeacherMutation } from './queries/useUpdateTeacherMutation'

export interface UpdateTeacherSiteButtonProps {
  teacherId: string
}
export function UpdateTeacherSiteButton({ teacherId }: UpdateTeacherSiteButtonProps) {
  const [visible, setVisible] = useState(false)
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>()
  const [updateTeacher, { loading }] = useUpdateTeacherMutation({
    onCompleted: () => {
      message.success('Teacher site updated!')
      setVisible(false)
    },
  })
  return (
    <>
      <Modal
        title={'Update Teacher Site'}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key={'cancel'} onClick={() => setVisible(false)}>
            Cancel
          </Button>,
          <Button
            type={'primary'}
            key={'submit'}
            onClick={() => {
              if (selectedSiteId) {
                updateTeacher({
                  variables: {
                    input: {
                      id: teacherId,
                      patch: {
                        siteId: selectedSiteId,
                        noEmail: true,
                      },
                    },
                  },
                })
              }
            }}
            loading={loading}
            disabled={!selectedSiteId}
          >
            Update Site
          </Button>,
        ]}
      >
        <SiteSelect value={selectedSiteId} onChange={setSelectedSiteId} />
      </Modal>
      <Button size={'small'} onClick={() => setVisible(true)} type={'link'}>
        Change &rarr;
      </Button>
    </>
  )
}
