import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Space, AntIconWrapper, BodyLarge, BodySmall, HSpaced, styled } from '@thesisedu/react'
import { WarningCircle, RefreshCircular, Trash } from '@thesisedu/ui/icons'
import { Button, Popconfirm } from 'antd'
import React from 'react'

import { LinkedAccountFragment, useUnlinkAccountMutation } from '../../schema'

export interface UserAccountItemProps {
  account: LinkedAccountFragment
  onRefresh: () => void
}
export function UserAccountItem({ account, onRefresh }: UserAccountItemProps) {
  const [unlinkAccount, { loading }] = useUnlinkAccountMutation({
    variables: {
      input: {
        provider: account.provider,
        providerId: account.providerId,
      },
    },
    onError: onMutationError('There was an error unlinking your account.'),
  })

  return (
    <Container space={'@size-xs'}>
      <div>
        <BodyLarge>{account.label}</BodyLarge>
        <BodySmall
          color={account.invalidSince ? '@red' : '@text-color-secondary'}
          style={{ lineHeight: 1 }}
        >
          {account.invalidSince ? (
            <>
              <WarningCircle style={{ verticalAlign: 'middle' }} /> Needs refreshing
            </>
          ) : (
            account.providerId
          )}
        </BodySmall>
      </div>
      <Space />
      <IconButton
        onClick={onRefresh}
        icon={
          <AntIconWrapper>
            <RefreshCircular />
          </AntIconWrapper>
        }
        danger={!!account.invalidSince}
        type={'link'}
      />
      <Popconfirm
        title={'Are you sure?'}
        okButtonProps={{ danger: true }}
        okText={'Yes, unlink'}
        cancelText={'No, keep'}
        onConfirm={() => {
          unlinkAccount()
        }}
      >
        <IconButton
          loading={loading}
          icon={
            <AntIconWrapper>
              <Trash />
            </AntIconWrapper>
          }
          type={'link'}
        />
      </Popconfirm>
    </Container>
  )
}

const Container = styled(HSpaced)`
  &:not(:first-child) {
    margin-top: ${props => props.theme['@size-s']};
  }
  > div:first-child {
    min-width: 0;
    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
const IconButton = styled(Button)`
  width: ${props => props.theme['@size-m']};
  height: ${props => props.theme['@size-m']};
  color: ${props => (props.danger ? props.theme['@red'] : props.theme['@text-color-secondary'])};
  padding: 0;
  > * {
    font-size: ${props => props.theme['@size-m']} !important;
  }
`
