import { Option } from '@thesisedu/feature-assignments-core'
import { Format } from '@thesisedu/feature-utils'
import { Markdown, styled } from '@thesisedu/web'
import React from 'react'

import { MultipleChoiceChoice } from './MultipleChoiceChoice'
import { MultipleChoiceLetterMode } from './MultipleChoiceLetter'
import { QuestionExtra } from './QuestionExtra'
import { QuestionInstructions } from './QuestionInstructions'
import { useQuestionSubmissionSummary } from '../../contexts/TeacherSubmissionContext'

export interface MultipleChoiceAnswerViewProps {
  expansion?: string
  selectedAnswers?: string[]
  options?: Option[]
  questionId: string
  usesExpansion?: boolean
  instructions?: string
  hideCorrectAnswer?: boolean
}
export function MultipleChoiceAnswerView({
  expansion,
  selectedAnswers = [],
  options = [],
  questionId,
  usesExpansion,
  instructions = 'Select an answer from the options below.',
  hideCorrectAnswer,
}: MultipleChoiceAnswerViewProps) {
  const submissionSummary = useQuestionSubmissionSummary(questionId)
  return (
    <QuestionInstructions instructions={instructions}>
      {options.map((option, index) => {
        const distribution = submissionSummary?.answerDistribution?.find(
          ({ answer }) => answer === option.name,
        )
        const distributionContainer = distribution ? (
          <DistributionLabel>
            {Format.number(distribution.percentage, 'percentage')}
          </DistributionLabel>
        ) : null
        const selected =
          selectedAnswers.includes(option.id) || selectedAnswers.includes(option.name)
        const correct = option.correct
        let mode = MultipleChoiceLetterMode.Default
        if (correct && (!hideCorrectAnswer || selected)) mode = MultipleChoiceLetterMode.Correct
        else if (!correct && selected) mode = MultipleChoiceLetterMode.Incorrect
        return (
          <MultipleChoiceChoice
            key={option.id}
            option={option}
            index={index}
            mode={mode}
            selected={selected}
            readOnly
            rightContent={
              <>
                {correct && !selected && !hideCorrectAnswer ? (
                  <CorrectContainer>Correct Answer</CorrectContainer>
                ) : null}
                {distributionContainer}
              </>
            }
          />
        )
      })}
      {usesExpansion ? (
        <QuestionExtra title={'Expansion'}>
          {expansion ? <Markdown skipHtml children={expansion} /> : <em>No expansion provided.</em>}
        </QuestionExtra>
      ) : null}
    </QuestionInstructions>
  )
}

const CorrectContainer = styled.div`
  background: ${props => props.theme['@blue']};
  border-radius: ${props => props.theme['@border-radius-base']};
  color: white;
  font-size: ${props => props.theme['@font-size-lg']};
  font-weight: 500;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
`
const DistributionLabel = styled.div`
  background: ${props => props.theme['@gray-3']};
  border-radius: ${props => props.theme['@border-radius-base']};
  color: ${props => props.theme['@gray-6']};
  font-size: ${props => props.theme['@font-size-lg']};
  font-weight: 500;
  padding: ${props => props.theme['@size-xs']} ${props => props.theme['@size-s']};
  margin-left: ${props => props.theme['@size-xs']};
`
