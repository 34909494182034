import { Widget } from './WidgetNode'
import { MaybeElement } from './nodes'
import { EditorValue } from './types'

export type NodeMapper = (node: MaybeElement) => MaybeElement
export function map(value: Widget[], mapper: NodeMapper): Widget[]
export function map(value: EditorValue, mapper: NodeMapper): EditorValue
export function map(value: EditorValue | Widget[], mapper: NodeMapper): EditorValue | Widget[]
export function map(value: EditorValue | Widget[], mapper: NodeMapper): EditorValue | Widget[] {
  function mapNode(node: MaybeElement): MaybeElement {
    const mapped = mapper(node)
    if (mapped.children) {
      mapped.children = mapped.children.map(mapNode)
    }
    return mapped
  }
  if (Array.isArray(value)) {
    return value.map(mapper) as Widget[]
  } else {
    return {
      ...value,
      root: {
        ...value.root,
        children: value.root.children.map(mapNode),
      },
    }
  }
}

export type AsyncNodeMapper = (node: MaybeElement) => Promise<MaybeElement> | MaybeElement
export async function mapAsync(value: Widget[], mapper: AsyncNodeMapper): Promise<Widget[]>
export async function mapAsync(value: EditorValue, mapper: AsyncNodeMapper): Promise<EditorValue>
export async function mapAsync(
  value: EditorValue | Widget[],
  mapper: AsyncNodeMapper,
): Promise<EditorValue | Widget[]>
export async function mapAsync(
  value: EditorValue | Widget[],
  mapper: AsyncNodeMapper,
): Promise<EditorValue | Widget[]> {
  async function mapNode(node: MaybeElement): Promise<MaybeElement> {
    const mapped = await mapper(node)
    if (mapped.children) {
      mapped.children = await Promise.all(mapped.children.map(mapNode))
    }
    return mapped
  }
  if (Array.isArray(value)) {
    return Promise.all(value.map(mapper)) as Promise<Widget[]>
  } else {
    return {
      ...value,
      root: {
        ...value.root,
        children: await Promise.all(value.root.children.map(mapNode)),
      },
    }
  }
}
