import { styled, s } from '@thesisedu/ui'

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  container: RecordContainer / size;
`
export const IconContainer = styled.div`
  position: relative;
  font-size: calc(min(48px, 20cqh));
  color: ${s.color('textEmphasis')};
  padding: 0.5em;
  svg {
    position: relative;
    z-index: 2;
  }
`
