import { AnimatePresence } from 'framer-motion'
import React from 'react'

import { TabProps } from './Tab'
import { useTabsContext } from './context'
import { styled } from '../../style'

export interface TabContentContextValue {
  useCSSAnimations?: boolean
}
export const TabContentContext = React.createContext<TabContentContextValue | undefined>(undefined)

export function useTabContentContext(): TabContentContextValue | undefined
export function useTabContentContext(require: false): TabContentContextValue | undefined
export function useTabContentContext(require: true): TabContentContextValue
export function useTabContentContext(require?: boolean): TabContentContextValue | undefined {
  const context = React.useContext(TabContentContext)
  if (!context && require) {
    throw new Error('TabContentContext is required, yet not provided.')
  }
  return context
}

export interface ContentProps {
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>
  forceMount?: boolean
}
export function Content({ children, forceMount }: ContentProps) {
  const { selectedTab } = useTabsContext()
  const childrenArray = Array.isArray(children) ? children : [children]
  const child = childrenArray.find(child => child.props.value === selectedTab)
  let content
  if (forceMount) {
    content = <ForceMountContainer>{children}</ForceMountContainer>
  } else {
    content = (
      <AnimatePresence initial={false} exitBeforeEnter>
        {child ? React.cloneElement(child, { key: child.props.value, forceMount: true }) : null}
      </AnimatePresence>
    )
  }

  return (
    <TabContentContext.Provider value={{ useCSSAnimations: !!forceMount }}>
      {content}
    </TabContentContext.Provider>
  )
}

const ForceMountContainer = styled.div`
  position: relative;
  flex: 1;
  > div {
    position: absolute;
    inset: 0;
  }
`
