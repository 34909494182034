import React from 'react'

export type PlaceholderContextValue = Record<string, any>
export const PlaceholderContext = React.createContext<PlaceholderContextValue | undefined>(
  undefined,
)

export function usePlaceholders() {
  return React.useContext(PlaceholderContext) || {}
}

export interface PlaceholderProviderProps {
  placeholders: Record<string, any>
  children: React.ReactElement
}
export function PlaceholderProvider({ placeholders, children }: PlaceholderProviderProps) {
  const existing = usePlaceholders()
  return (
    <PlaceholderContext.Provider value={{ ...existing, ...placeholders }} children={children} />
  )
}
