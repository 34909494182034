import { useForwardedRef } from '@thesisedu/react'
import { styled, s, HTMLProps } from '@thesisedu/ui'
import React from 'react'

import { CompactAudioIndicator } from './CompactAudioIndicator'
import { useRecordStateContext } from './RecordStateContext'

export interface VideoStreamPreviewProps extends HTMLProps<HTMLVideoElement> {
  stream: MediaStream | Blob
}
function _VideoStreamPreview(
  { stream, ...rest }: VideoStreamPreviewProps,
  ref: React.ForwardedRef<HTMLVideoElement>,
) {
  const videoRef = useForwardedRef(ref)
  React.useEffect(() => {
    if (videoRef.current) {
      if (stream instanceof Blob) {
        videoRef.current.srcObject = null
        videoRef.current.src = URL.createObjectURL(stream)
      } else {
        videoRef.current.srcObject = stream
      }
    }
  }, [stream])

  // Pause the stream whenever recording pauses.
  const { state } = useRecordStateContext(true)
  const isPaused = state?.type === 'paused'
  React.useEffect(() => {
    if (isPaused) {
      videoRef.current?.pause()
      return () => {
        videoRef.current?.play()
      }
    }
  }, [isPaused])

  return (
    <>
      <Video ref={videoRef} autoPlay muted {...rest} />
      {stream instanceof MediaStream ? (
        <IndicatorContainer>
          <CompactAudioIndicator stream={stream} />
        </IndicatorContainer>
      ) : null}
    </>
  )
}
export const VideoStreamPreview = React.forwardRef(_VideoStreamPreview)

export const Video = styled.video`
  position: absolute;
  inset: 0;
  background: ${s.color('subtle')};
  width: 100%;
  height: 100%;
`
const IndicatorContainer = styled.div`
  position: absolute;
  bottom: ${s.size('xs')};
  right: ${s.size('xs')};
`
