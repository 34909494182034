import { Widgets } from '@thesisedu/feature-course-types'
import { Modal, Button, styled, s } from '@thesisedu/ui'
import { Play } from '@thesisedu/ui/icons'
import React from 'react'

import { VideoView } from './VideoView'

export interface InlineViewProps extends Widgets.VideoConfigWithVodId {}
export function InlineView({ vodId, inlineLabel = 'Watch this in action.' }: InlineViewProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <Container>
      <Button
        size={'small'}
        children={inlineLabel}
        icon={<Play style={{ fill: 'currentColor' }} />}
        variant={'primary'}
        onPress={() => setVisible(true)}
      />
      <s.ThemeProvider colorVariant={'dark'}>
        <StyledModal width={1000} visible={visible} onVisibleChange={setVisible}>
          <VideoView vodId={vodId} />
        </StyledModal>
      </s.ThemeProvider>
    </Container>
  )
}

const Container = styled.div`
  margin-top: ${s.size('1')};
  margin-bottom: 0;
`
const StyledModal = styled(Modal)`
  max-width: 90vw;
  padding: 0 !important;
  min-height: 150px;
  .player-container {
    border-radius: 0 !important;
  }
`
