import { $wrapNodeInElement } from '@lexical/utils'
import { DraggableNode } from '@thesisedu/feature-widgets-core'
import { ElementNode, NodeKey, SerializedElementNode, Spread, LexicalNode } from 'lexical'

export type FloatAlignment = 'left' | 'right'
export type SerializedFloatNodeV1 = Spread<
  {
    type: 'float'
    width: number
    alignment: FloatAlignment
    version: 1
  },
  SerializedElementNode
>

export function normalizeFloatWidth(width: number) {
  return Math.min(0.9, Math.max(0.1, Math.round(width * 10) / 10))
}

export class FloatNode extends ElementNode implements DraggableNode {
  __width: number
  __alignment: FloatAlignment

  constructor(alignment: FloatAlignment, width: number, key?: NodeKey) {
    super(key)
    this.__alignment = alignment
    this.__width = width
  }

  static getType(): string {
    return 'float'
  }

  static clone(node: FloatNode): FloatNode {
    return new FloatNode(node.__alignment, node.__width, node.__key)
  }

  static importJSON({ alignment, width }: SerializedFloatNodeV1): FloatNode {
    return $createFloatNode(alignment, width)
  }

  exportJSON(): SerializedFloatNodeV1 {
    return {
      ...super.exportJSON(),
      type: 'float',
      alignment: this.__alignment,
      width: this.__width,
      version: 1,
    }
  }

  setWidth(width: number) {
    const self = this.getWritable()
    self.__width = width
  }

  getWidth(): number {
    const self = this.getLatest()
    return self.__width
  }

  setAlignment(alignment: FloatAlignment) {
    const self = this.getWritable()
    self.__alignment = alignment
  }

  getAlignment(): FloatAlignment {
    const self = this.getLatest()
    return self.__alignment
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.classList.add('fteditor-float')
    this.__updateWidth(dom)
    this.__updateAlignment(dom)
    return dom
  }

  __updateWidth(dom: HTMLElement) {
    const normalized = normalizeFloatWidth(this.__width)
    dom.style.width = `${normalized * 100}%`
  }

  __updateAlignment(dom: HTMLElement) {
    dom.style.float = this.__alignment
    dom.classList.remove('float-alignment-left', 'float-alignment-right')
    dom.classList.add('float-alignment-' + this.__alignment)
  }

  updateDOM(prevNode: FloatNode, dom: HTMLElement): boolean {
    if (!this.getChildrenSize()) {
      this.remove()
    }
    this.__updateAlignment(dom)
    this.__updateWidth(dom)
    return false
  }
}

export function $createFloatNode(alignment: FloatAlignment, width: number): FloatNode {
  return new FloatNode(alignment, width)
}

export function $wrapInFloatNode(node: LexicalNode): ElementNode {
  return $wrapNodeInElement(node, () => new FloatNode('right', 0.3))
}

export function $isFloatNode(node: any): node is FloatNode {
  return node instanceof FloatNode
}
