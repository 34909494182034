import { BaseWidgetConfig } from '@thesisedu/feature-widgets-core'

import { WidgetNode } from './WidgetNode'
import { SettingsDropdownElementProps } from '../../../lexical/ui/SettingsDropdown/SettingsDropdownContext'

export type SettingsDropdownProps<Config extends BaseWidgetConfig> = SettingsDropdownElementProps<
  WidgetNode<any, Config>
>

export {
  createWidgetResource,
  SimpleWidgetDefinition,
  SimpleWidgetProps,
  HiddenContext,
} from './createWidgetResource'
export {
  BaseWidgetConfig,
  SerializedWidget,
  NestedEditor as ConfigNestedEditor,
} from '@thesisedu/feature-widgets-core'
export { WidgetEmptyState } from './WidgetEmptyState'
export { useWidgetConfig } from './useWidgetConfig'
export { NestedEditor, NestedEditorProps } from '../../../lexical/NestedEditor'
export * from './styled'
export { WidgetAlignmentSetting as AlignmentSetting } from './WidgetAlignmentSetting'
export { WidgetNumberConfig, getWidgetNumber } from './getWidgetNumber'
export { StartingNumberField } from './StartingNumberField'
export {
  SettingsDropdownEnumItem,
  SettingsDropdownEnumItemProps,
} from '../../../lexical/ui/SettingsDropdown/SettingsDropdownEnumItem'
export { ElementsGroup } from '../../../lexical/ui/ElementsMenu/ElementsMenuContext'
export { $createWidgetNode } from './WidgetNode'
