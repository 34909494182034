import { SegmentMetadata } from '@thesisedu/feature-course-types'
import moment from 'moment'
import React from 'react'

import { debug } from './log'
import { ClassCourseFragment, useClassCourseQuery } from './schema'

const SCHEDULE_CHECK_INTERVAL = 1000
const SCHEDULE_CHECK_COOLDOWN_SEC = 90 // 90-second cooldown.
export interface ScheduledSegmentWatcherProps {
  class: ClassCourseFragment
}
export function ScheduledSegmentWatcher({ class: classInstance }: ScheduledSegmentWatcherProps) {
  const { refetch } = useClassCourseQuery({
    variables: { id: classInstance.id },
  })
  const classRef = React.useRef<ClassCourseFragment>(classInstance)
  React.useEffect(() => {
    classRef.current = classInstance
  }, [classInstance])

  /**
   * This is here to avoid scenarios where we are constantly refetching the classCourse query
   * every second, thus crashing the database. To my knowledge, this would only happen when the
   * user's computer time is off from the time on the server (timezone-independent of course).
   *
   * So we just keep track of the segment IDs we've think are supposed to be enabled. The first time
   * it comes around, we'll refetch the request. From then on, that segment is ignored (until the user
   * refreshes the page and this all starts over again).
   */
  const fetchedSegmentIds = React.useRef<Set<string>>(new Set<string>())

  /**
   * To help this even more, we're also adding a "cooldown" so we don't refetch too frequently. The
   * cooldown is configured using the constant above. We wait X seconds after the request comes back
   * (success or failure) before attempting to request again.
   */
  const cooldown = React.useRef<boolean>(false)

  React.useEffect(() => {
    const checkInterval = setInterval(() => {
      if (cooldown.current) return
      const cls = classRef.current
      const scheduledSegmentsInPast = (
        cls.segmentMetadata as SegmentMetadata | undefined
      )?.segments?.filter(
        s => s.scheduledAt && !s.visibleOverride && moment(s.scheduledAt).isBefore(moment()),
      )
      if (scheduledSegmentsInPast?.length) {
        const validSegmentIds: string[] = []
        for (const segment of scheduledSegmentsInPast) {
          if (!fetchedSegmentIds.current.has(segment.id)) {
            validSegmentIds.push(segment.id)
            fetchedSegmentIds.current.add(segment.id)
          }
        }
        if (validSegmentIds.length) {
          debug('found disabled segments scheduled for the past, refetching...')
          cooldown.current = true
          refetch({ id: cls.id }).finally(() => {
            setTimeout(() => {
              cooldown.current = false
            }, SCHEDULE_CHECK_COOLDOWN_SEC * 1000)
          })
        }
      }
    }, SCHEDULE_CHECK_INTERVAL)
    return () => {
      clearInterval(checkInterval)
    }
  }, [classInstance.id])

  return null
}
