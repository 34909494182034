import React from 'react'

export function useTimedOut(timeout: number | undefined, callback: () => void, deps: any[]) {
  const timedOut = React.useRef(false)
  React.useEffect(() => {
    if (timeout !== undefined) {
      const handle = setTimeout(() => {
        timedOut.current = true
      }, timeout)
      return () => clearTimeout(handle)
    }
  }, [])
  React.useEffect(() => {
    if (!timedOut.current) {
      callback()
    }
  }, [deps])
}

export interface DefaultValueOptions {
  timeout?: number
}
export function useDefaultValueState<T>(
  value: T,
  { timeout }: DefaultValueOptions = {},
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [valueState, setValueState] = React.useState<T>(value)
  useTimedOut(
    timeout,
    () => {
      setValueState(value)
    },
    [!!value],
  )
  return [valueState, setValueState]
}

export function useDefaultValueRef<T>(value: T, { timeout }: DefaultValueOptions = {}) {
  const valueRef = React.useRef(value)
  useTimedOut(
    timeout,
    () => {
      valueRef.current = value
    },
    [!!value],
  )
  return valueRef
}
