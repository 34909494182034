import {
  Page,
  SoundHigh,
  Package,
  MediaVideo,
  MediaImage,
  DatabaseExport,
  MultiplePages as Presentation,
  Calculator as Spreadsheet,
} from '@thesisedu/react/icons'

/* eslint-disable prettier/prettier */
const IconExtensionMap: [typeof Page, string[]][] = [
  [Page, ['pdf', 'txt', 'doc', 'docx', 'odt', 'rtf', 'tex', 'wks', 'wps', 'wpd']],
  [SoundHigh, ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wpl']],
  [Package, ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'tar.gz', 'z', 'zip']],
  [MediaVideo, ['3g2', '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'vob', 'wmv']],
  [MediaImage, ['ai', 'bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff', 'cr2']],
  [DatabaseExport, ['csv', 'dat', 'db', 'dbf', 'log', 'mdb', 'sav', 'sql', 'tar', 'xml']],
  [Presentation, ['key', 'odp', 'pps', 'ppt', 'pptx']],
  [Spreadsheet, ['ods', 'xlr', 'xls', 'xlsx']],
]
/* eslint-enable prettier/prettier */

export const ExtensionIconMap = IconExtensionMap.reduce<Record<string, typeof Page>>(
  (acc, [icon, extensions]) => {
    extensions.forEach(extension => {
      acc[extension] = icon
    })
    return acc
  },
  {},
)

export function getExtensionIcon(extension: string) {
  return ExtensionIconMap[extension] || Page
}
