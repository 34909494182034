import { Format } from '@thesisedu/feature-utils'
import { NavigationListItem, RenderNavigationListItemProps } from '@thesisedu/react/devtools'
import { styled, s, HSpaced, Text } from '@thesisedu/ui'
import React from 'react'

import { ClassSyncHistoryStatus, ListClassSyncHistoryFragment } from '../schema'

const Statuses: Record<ClassSyncHistoryStatus, { label: string; color: s.ColorAlias }> = {
  [ClassSyncHistoryStatus.Error]: {
    color: 'red',
    label: 'Error',
  },
  [ClassSyncHistoryStatus.NotFound]: {
    color: 'orange',
    label: 'Not Found',
  },
  [ClassSyncHistoryStatus.Success]: {
    color: 'green',
    label: 'Success',
  },
}

export function HistoryListItem({
  data,
  onClick,
  isSelected,
}: RenderNavigationListItemProps<ListClassSyncHistoryFragment>) {
  return (
    <NavigationListItem onClick={onClick} isSelected={isSelected}>
      <Container>
        <HSpaced space={'xs'}>
          <Text level={'s'}>{Format.date(data.completedAt, 'dateTime')}</Text>
          <StatusIcon style={{ background: s.color(Statuses[data.status].color) }} />
        </HSpaced>
        <Text level={'xs'} color={'secondary'}>
          {data.classSync.label}
        </Text>
      </Container>
    </NavigationListItem>
  )
}

const StatusIcon = styled.div`
  border-radius: 50%;
  height: ${s.size('xs')};
  width: ${s.size('xs')};
  margin-left: auto;
`
export const Container = styled.div`
  * {
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
