import React, { useState } from 'react'

import {
  SegmentFilters,
  SegmentFiltersProps,
  SegmentFilterValues,
  SegmentUserFilter,
} from './SegmentFilters'
import { SegmentPreviewList, SegmentPreviewListProps } from './SegmentPreviewList'
import { FilterOperation, SegmentFragment } from '../schema'

export interface SegmentSelectProps {
  onSegmentSelected: (segment: SegmentFragment) => void
  segmentListProps?: Partial<SegmentPreviewListProps>
  filterProps?: Partial<SegmentFiltersProps>
}
export const SegmentSelect: React.FC<React.PropsWithChildren<SegmentSelectProps>> = ({
  onSegmentSelected,
  segmentListProps,
  filterProps,
}) => {
  const [filters, setFilters] = useState<SegmentFilterValues>({
    type: undefined,
    user: SegmentUserFilter.BusinessU,
    name: undefined,
    tags: [],
    tagsOp: FilterOperation.And,
    hiddenFromTeachers: false,
  })
  return (
    <>
      <SegmentFilters hideCreator {...filterProps} filters={filters} onChange={setFilters} />
      <SegmentPreviewList
        filters={filters}
        onSegmentClick={onSegmentSelected}
        {...segmentListProps}
      />
    </>
  )
}
