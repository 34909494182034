import { StyledThemeContext } from '@thesisedu/web'
import { Form, Input, InputNumber } from 'antd'
import React from 'react'

import { BadgeEventTypeProps } from '../types'

export function InteractionBadgeEventConfig({ prefix }: BadgeEventTypeProps) {
  const theme = React.useContext(StyledThemeContext)
  return (
    <>
      <Form.Item
        name={[prefix, 'type']}
        label={'Interaction Type'}
        rules={[{ required: true, message: 'An interaction type is required.' }]}
      >
        <Input style={{ fontFamily: theme['@code-family'] }} />
      </Form.Item>
      <Form.Item name={[prefix, 'referenceType']} label={'Reference Type'}>
        <Input style={{ fontFamily: theme['@code-family'] }} />
      </Form.Item>
      <Form.Item name={[prefix, 'referenceId']} label={'Reference ID'}>
        <Input style={{ fontFamily: theme['@code-family'] }} />
      </Form.Item>
      <Form.Item
        name={[prefix, 'count']}
        label={'Count'}
        rules={[{ required: true, message: 'A count is required.' }]}
        initialValue={1}
      >
        <InputNumber min={0} precision={0} />
      </Form.Item>
    </>
  )
}
