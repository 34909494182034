import { useViewerContext } from '@thesisedu/feature-users-web'
import { BlockSpin, BodySmall, Block } from '@thesisedu/web'
import { Empty } from 'antd'
import React from 'react'

import { UserSubscriptionsTable } from './UserSubscriptionsTable'
import { useViewerSubscriptionsQuery } from './schema'

export const UserSubscriptionManagement: React.FC<React.PropsWithChildren<unknown>> = () => {
  const viewer = useViewerContext(false)
  const { data, loading } = useViewerSubscriptionsQuery({
    skip: !viewer,
  })
  const results = data?.viewer?.licenseSubscriptions.edges.map(edge => edge.node) || []
  if (loading) {
    return <BlockSpin />
  } else if (results.length) {
    return (
      <>
        <Block marginBottom={'@size-s'}>
          <UserSubscriptionsTable dataSource={results} />
        </Block>
        <BodySmall color={'@text-color-secondary'}>
          You can manage <strong>Individual</strong> subscriptions inside the Collabra Mobile app.
        </BodySmall>
      </>
    )
  } else {
    return <Empty description={"You don't have any active subscriptions."} />
  }
}
