import { ApolloClient } from '@apollo/client'

export function refetchQueries(assignmentId: string): (client: ApolloClient<any>) => Promise<void> {
  return async client => {
    client.cache.evict({
      id: client.cache.identify({ __typename: 'Assignment', id: assignmentId }),
      fieldName: 'submissions',
    })
  }
}
