import { Legacy } from '@thesisedu/feature-widgets-core'
import { isEmpty, omit, orderBy, get, cloneDeep } from 'lodash'

import { warn, debug } from './log'

export interface SegmentConfig {
  days?: number
  daysLeft?: number
  daysCalculated?: number
  studentContent?: Legacy.AnyEditorValue
  teacherContent?: Legacy.AnyEditorValue
  label?: string | null
  studentLabel?: string | null
  studentName?: string | null
  alwaysEnabled?: boolean
  [key: string]: any
}
export interface SegmentClientInformation {
  hasStudentContent?: boolean
  hasTeacherContent?: boolean
  vodId?: string | null
}
export interface Segment<Config extends SegmentConfig = SegmentConfig> {
  id: string
  onLevel?: string
  visibleOverride?: boolean
  forceReportAsViewed?: boolean
  excludedLevels?: string[]
  childSegments?: Segment[]
  config?: Config
  weight?: number
  name: string
  /** This should be displayed in front of the name if provided. */
  prefix?: string | null
  referenceSegment?: Segment
  index?: string | null
  /** True if this content is not included in the teacher's license. */
  notInLicense?: boolean
  client?: SegmentClientInformation
  type: 'Assignment' | 'Section' | 'Group' | 'Topic' | 'Term' | string
  [key: string]: any
  originalId?: string
  classIdsWithSegment?: string[] | null
}

export interface ReferenceSegmentOverrides {
  index?: string
  name?: string
}

export interface ReferenceSegmentConfig {
  referenceId: string
  referenceOverrides?: ReferenceSegmentOverrides
}

export interface CourseConfiguration {
  segments: Segment[]
}

interface BakeSegmentsOpts {
  level?: string
  keepHidden?: boolean
}
function bakeSegments(
  segments: Segment[],
  { level, keepHidden = true }: BakeSegmentsOpts = {},
): Segment[] {
  return segments.reduce<Segment[]>((segments, segment) => {
    if (segment.visibleOverride !== true && !keepHidden) return segments
    let result: Segment = segment
    if (level) {
      const excludedLevels = segment.excludedLevels || []
      const onLevel = !excludedLevels.includes(level)
      if (!onLevel && segment.visibleOverride !== true) return segments
      result = Object.assign({}, result, { onLevel })
    }
    if (segment.childSegments) {
      result = Object.assign({}, result, {
        childSegments: bakeSegments(segment.childSegments, { level, keepHidden }),
      })
    }
    return segments.concat(result)
  }, [])
}

export function processAlwaysEnabled(segments: Segment[]): Segment[] {
  return segments.map(segment => {
    const childSegments = segment.childSegments
      ? processAlwaysEnabled(segment.childSegments)
      : undefined
    const hasEnabledChildren = childSegments?.some(childSegment => childSegment.visibleOverride)
    if (segment.config?.alwaysEnabled || hasEnabledChildren) {
      return {
        ...segment,
        visibleOverride: true,
        childSegments,
      }
    } else return segment
  })
}

export function bakeCourseConfiguration(
  configuration: CourseConfiguration,
  opts?: BakeSegmentsOpts,
): CourseConfiguration {
  const { segments } = configuration
  const baked = Object.assign({}, configuration, { segments: bakeSegments(segments, opts) })
  return {
    segments: processAlwaysEnabled(baked.segments),
  }
}

export type SegmentWalker = (segment: Segment) => boolean
export interface FilterSegmentsOpts {
  /**
   * By default, the filter is applied to ALL segments. So if it is true for a parent, but false
   * for a child, the child will not be included. If you would like to include children if the parent
   * matches, regardless of if the child matches, pass true for includeChildren.
   */
  includeChildren?: boolean
  /**
   * By default, the filter is top-down. This means we start with the first semester, check to see if it
   * matches the filter, and if it doesn't then it's removed from the result. If it does, we check each
   * of its children.
   *
   * If leavesOnly is true, we only run the filter logic against leaves, and automatically include the
   * parents if they match. Parents with no valid children (even multiple levels deep) are omitted.
   */
  leavesOnly?: boolean
}
export function filterSegments(
  segments: Segment[],
  walker: SegmentWalker,
  opts: FilterSegmentsOpts = {},
): Segment[] {
  const { includeChildren, leavesOnly } = opts
  return segments
    .map(segment => {
      const shouldFilter = !leavesOnly || !segment.childSegments?.length
      if (shouldFilter && !walker(segment)) return null
      if (segment.childSegments) {
        if (includeChildren) {
          return segment
        } else {
          const withMatchingChildren = {
            ...segment,
            childSegments: filterSegments(segment.childSegments, walker, opts),
          }
          return leavesOnly
            ? withMatchingChildren.childSegments.length
              ? withMatchingChildren
              : null
            : withMatchingChildren
        }
      } else return segment
    })
    .filter(Boolean) as Segment[]
}

export type SegmentWalkerWithDepth<S extends Segment = Segment> = (
  segment: S,
  depth: number,
  parents: S[],
) => void
export function walkSegments<S extends Segment = Segment>(
  segments: S[],
  walker: SegmentWalkerWithDepth<S>,
  depth = 0,
  parents: S[] = [],
) {
  segments.forEach(segment => {
    walker(segment, depth, parents)
    if (segment.childSegments) {
      walkSegments(segment.childSegments as S[], walker, depth + 1, [...parents, segment])
    }
  })
}

export type SegmentReducer<T, S extends Segment = Segment> = (
  acc: T,
  segment: S,
  depth: number,
  parents: S[],
) => T
export function reduceSegments<T, S extends Segment = Segment>(
  segments: S[],
  reducer: SegmentReducer<T, S>,
  initialValue: T,
): T {
  let result = initialValue
  walkSegments(segments, (segment, depth, parents) => {
    result = reducer(result, segment, depth, parents)
  })
  return result
}

export interface FlattenSegmentsOptions {
  includeChildren?: boolean
}
export function flattenSegments<T extends Segment = Segment>(
  segments: T[],
  { includeChildren }: FlattenSegmentsOptions = {},
): T[] {
  return reduceSegments<T[], T>(
    segments,
    (resultSegments, segment) => {
      let sanitizedSegment = segment
      if (segment.childSegments) {
        sanitizedSegment = Object.assign(
          {},
          includeChildren ? segment : omit(segment, ['childSegments']),
          {
            config: omit(segment.config || {}, ['days']),
          },
        ) as T
      }
      return resultSegments.concat(sanitizedSegment)
    },
    [],
  )
}

export function orderSegments(segments: Segment[]): Segment[] {
  const ordered = orderBy(segments, ['weight'], ['asc'])
  return ordered.reduce<Segment[]>((newSegments, segment) => {
    let result = segment
    if (segment.childSegments) {
      result = Object.assign({}, segment, { childSegments: orderSegments(segment.childSegments) })
    }
    return newSegments.concat(result)
  }, [])
}

const DAY_QUOTA = 1
type SegmentDay = Segment[]
export function getDaysForSegments(segments: Segment[]): SegmentDay[] {
  let currentSegmentIndex = 0
  let currentSegmentRemainingQuota: number | null = null
  const resultDays = []
  let currentDayUsedQuota = 0
  let currentDay = []
  while (segments[currentSegmentIndex]) {
    while (currentDayUsedQuota < 1) {
      const currentSegment = segments[currentSegmentIndex]
      if (!currentSegment) break
      const config = currentSegment.config || {}
      const hasDays = config.days !== undefined
      const days = config.days
      if (currentSegmentRemainingQuota === null) currentSegmentRemainingQuota = days || 0
      const dayRemainingQuota = DAY_QUOTA - currentDayUsedQuota
      const configOverride: SegmentConfig = {}
      if (days !== undefined) {
        configOverride.days = days
      }
      const pushPayload = Object.assign({}, currentSegment, {
        config: Object.assign({}, currentSegment.config || {}, configOverride),
      })
      if (currentSegmentRemainingQuota > dayRemainingQuota) {
        if (hasDays) {
          pushPayload.config.daysLeft = currentSegmentRemainingQuota
          pushPayload.config.daysCalculated = dayRemainingQuota
        }
        currentSegmentRemainingQuota -= dayRemainingQuota
        currentDayUsedQuota += dayRemainingQuota
        currentDay.push(pushPayload)
      } else {
        currentDayUsedQuota += currentSegmentRemainingQuota
        if (hasDays) {
          pushPayload.config.daysLeft = currentSegmentRemainingQuota
          pushPayload.config.daysCalculated = currentSegmentRemainingQuota
        }
        currentDay.push(pushPayload)
        currentSegmentRemainingQuota = null
        currentSegmentIndex++
      }
    }
    resultDays.push(currentDay)
    currentDay = []
    currentDayUsedQuota = 0
  }

  return resultDays
}

export function generateSegmentDays(configuration: CourseConfiguration) {
  const orderedSegments = orderSegments(configuration.segments || [])
  const flatSegments = flattenSegments(orderedSegments)
  return getDaysForSegments(flatSegments)
}

export function getSegmentsForDay(
  configuration: CourseConfiguration,
  zeroBasedDay: number,
): Segment[] | null {
  const segmentDays = generateSegmentDays(configuration)
  return segmentDays[zeroBasedDay] || null
}

function _findSegment(
  field: string,
  segments: Segment[],
  segmentId: string,
  pathRef?: { current: string[] },
): Segment | null {
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    if (get(segment, field) === segmentId) {
      pathRef?.current.push(i.toString())
      return segment
    }
    if (segment.childSegments) {
      const subPathRef = pathRef ? { current: [] } : undefined
      const result = _findSegment(field, segment.childSegments, segmentId, subPathRef)
      if (result !== null) {
        pathRef?.current.push(i.toString(), ...(subPathRef?.current ?? []))
        return result
      }
    }
  }
  for (const segment of segments) {
    if (get(segment, field) === segmentId) return segment
    if (segment.childSegments) {
      const result = _findSegment(field, segment.childSegments, segmentId, pathRef)
      if (result !== null) return result
    }
  }
  return null
}
export function findSegment(
  segments: Segment[],
  segmentId: string,
  withReferences = false,
  pathRef?: { current: string[] },
): Segment | null {
  const result = _findSegment('id', segments, segmentId, pathRef)
  if (!result && withReferences) {
    return _findSegment('referenceSegment.id', segments, segmentId, pathRef)
  } else {
    return result
  }
}

export function isEnabled(segment: Segment) {
  return !!segment.visibleOverride
}

export function isVisibleToStudent(segment: Segment): boolean {
  return !!(
    segment.type === 'Assignment' ||
    !isEmpty(segment.config?.studentContent) ||
    segment.client?.hasStudentContent
  )
}
export function isVisibleToTeacher(segment: Segment): boolean {
  return !!(
    isVisibleToStudent(segment) ||
    !isEmpty(segment.config?.teacherContent) ||
    segment.client?.hasTeacherContent
  )
}

export interface SegmentMetadataSegment {
  id: string
  config?: Partial<SegmentConfig>
  isLocked?: boolean
  visibleOverride?: boolean
  weight?: number
  scheduledAt?: string
  /** Timezone offset for scheduledAt from UTC, in minutes */
  scheduledAtOffset?: number
  enabledStudentIds?: string[]
  forceReportAsViewed?: boolean
}

export interface SegmentStructureOverride {
  id: string
  parentId: string
}

export interface SegmentMetadata {
  segments?: SegmentMetadataSegment[]
  structureOverrides?: SegmentStructureOverride[]
}

export interface ClassWithCourse {
  segmentMetadata?: SegmentMetadata | null
}
export interface ClassConfigurationWithCourse {
  disableVideoSeekLimitation?: boolean
}

export function combineSegmentMetadata(
  segmentMetadata: SegmentMetadataSegment[],
  courseSegments: Segment[],
): Segment[] {
  return courseSegments.reduce<Segment[]>((segments, segment) => {
    const metadataForSegment = segmentMetadata.find(m => m.id === segment.id)
    let newSegment = segment
    if (metadataForSegment) {
      let configOverride: Partial<Segment> = {}
      if (metadataForSegment.config) {
        configOverride = {
          config: Object.assign({}, newSegment.config || {}, metadataForSegment.config),
        }
      }
      newSegment = Object.assign({}, newSegment, metadataForSegment, configOverride)
    }
    if (newSegment.childSegments) {
      newSegment.childSegments = combineSegmentMetadata(segmentMetadata, newSegment.childSegments)
    }
    return segments.concat(newSegment)
  }, [])
}

/** This applies the changes to the course configuration IN PLACE. */
export function combineStructureOverrides(
  structureOverrides: SegmentStructureOverride[],
  courseSegments: Segment[],
  builtForeignSegments: Record<string, Segment>,
): Segment[] {
  let segments = courseSegments
  const existingSegmentIds = new Set<string>()
  walkSegments(courseSegments, segment => {
    existingSegmentIds.add(segment.id)
  })

  for (const override of structureOverrides) {
    // Find the segment.
    const segment = builtForeignSegments[override.id]
      ? cloneDeep(builtForeignSegments[override.id])
      : // Fallback to the course segments in case it was removed from the tree earlier.
        findSegment(segments, override.id) ?? findSegment(courseSegments, override.id)
    if (!segment) {
      warn("skipping segment %s for structure override, since we can't find it", override.id)
      continue
    }

    // Remove the segment (and any of its children) from the tree if it already exists...
    const segmentIds = flattenSegments([segment]).map(s => s.id)
    segments = filterSegments(segments, segment => {
      return !segmentIds.includes(segment.id)
    })
    const parentSegment = findSegment(segments, override.parentId)
    if (!parentSegment) {
      warn(
        "skipping segment %s because we can't find the parent segment %s",
        override.id,
        override.parentId,
      )
      continue
    }

    // Add it back to the tree.
    debug('applying override %s -> new parent %s', override.id, override.parentId)
    if (!parentSegment.childSegments) parentSegment.childSegments = []

    // Only mark segments as moved if they were already inside the course.
    if (existingSegmentIds.has(segment.id)) {
      segment.isMoved = true
    }

    parentSegment.childSegments.push(segment)
  }

  return segments
}

function _getSegmentParents(
  segments: Segment[],
  segmentId: string,
  field: string,
  parents: string[] = [],
): string[] | undefined {
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i]
    if (get(segment, field) === segmentId) return parents
    const foundInChild =
      segment.childSegments &&
      _getSegmentParents(segment.childSegments, segmentId, field, parents.concat(segment.id))
    if (foundInChild) return foundInChild
  }
}

export function getSegmentParents(segments: Segment[], segmentId: string, withReferences = false) {
  let result = _getSegmentParents(segments, segmentId, 'id')
  if (!result && withReferences) {
    result = _getSegmentParents(segments, segmentId, 'referenceSegment.id')
  }
  return result || []
}

export function getSegmentParent(
  segments: Segment[],
  segmentId: string,
  withReferences = false,
): Segment | null {
  const parents = getSegmentParents(segments, segmentId, withReferences)
  const lastParent = parents[parents.length - 1]
  return lastParent ? findSegment(segments, lastParent) : null
}
