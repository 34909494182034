import {
  ClassStudentPair,
  ClassWithStudents,
  useAllStudentsQuery,
  useClasses,
  useStudentSelectorContext,
} from '@thesisedu/feature-classes-react'
import { MinimalClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import { ClassCourseFragment } from '@thesisedu/feature-courses-react'
import {
  inferGraphContext,
  ReportDimensionContext,
  ReportDimensionOptionsLabelProps,
  ReportDimensionOptionsProps,
  ReportDimensionResource,
} from '@thesisedu/feature-reports-react'
import { Format } from '@thesisedu/feature-utils'
import { Dropdown } from '@thesisedu/ui'
import { AddKeyframes } from '@thesisedu/ui/icons'
import React from 'react'

import { ClassHeader, useClassHeader } from './components/ClassHeader'
import { CourseReportsReactFeature } from '../../CourseReportsReactFeature'
import {
  ClassesReportDimensionInput,
  ClassesReportDimensionResultFragment,
  ClassesReportDimensionResultFragmentDoc,
} from '../../schema'

interface MultipleClassesContext extends ReportDimensionContext {
  courseName: string
}

function getAllPairsForClass(cls: ClassWithStudents): ClassStudentPair[] {
  return (
    cls.students?.edges.map(edge => ({
      studentId: edge.node.id,
      classId: cls.id,
    })) ?? []
  )
}

function useMultipleClasses({
  value,
  context,
}: Pick<
  ReportDimensionOptionsProps<ClassesReportDimensionInput, MultipleClassesContext>,
  'value' | 'context'
>) {
  const { classes } = useClasses()
  const { classes: classesWithStudents } = useAllStudentsQuery()
  const classesWithCourseVersions = classes as (ClassCourseFragment & MinimalClassFragment)[]
  const courseClassMap = classesWithCourseVersions
    .filter(cls => !cls.archivedAt && cls.courseVersion && !cls.courseVersion.course.isByoc)
    .reduce<Record<string, string[]>>((acc, cls) => {
      const key = cls.courseVersion!.course.label || cls.courseVersion!.course.name
      return {
        ...acc,
        [key]: [...(acc[key] || []), cls.id],
      }
    }, {})
  let selectedKey =
    context?.courseName ||
    (value?.classIds?.length &&
      Object.keys(courseClassMap).find(key => {
        return (
          value.classIds.length === courseClassMap[key].length &&
          courseClassMap[key].every(classId => {
            return value.classIds.includes(classId)
          })
        )
      }))
  if (!selectedKey && value?.classIds.length) {
    selectedKey = Format.plural('class', value.classIds.length || 0)
  }
  const selectedPairs = value?.classIds.reduce<ClassStudentPair[]>((acc, cls) => {
    const fullClass = classesWithStudents?.find(c => c.id === cls)
    if (fullClass) {
      return [...acc, ...getAllPairsForClass(fullClass)]
    } else {
      return acc
    }
  }, [])
  return { selectedKey, courseClassMap, selectedPairs }
}

function MultipleClassesOptionsWrapper({
  value,
  context,
  onConfirm,
  children,
  isInDropdown,
  ...rest
}: ReportDimensionOptionsProps<ClassesReportDimensionInput, MultipleClassesContext>) {
  const { courseClassMap, selectedKey, selectedPairs } = useMultipleClasses({ value, context })
  const { selectStudents } = useStudentSelectorContext(true)
  const menuItems = [
    ...Object.keys(courseClassMap).map(name => (
      <Dropdown.Item.Checkbox
        key={name}
        onCheckedChange={checked => {
          if (!checked) return
          const classIds = courseClassMap[name.toString()]
          if (classIds) {
            onConfirm({ classIds }, { courseName: name.toString() })
          }
        }}
        checked={selectedKey === name}
      >
        {name}
      </Dropdown.Item.Checkbox>
    )),
    <Dropdown.Item.Separator key={'divide'} />,
    <Dropdown.Item.Checkbox
      key={'pick'}
      onCheckedChange={checked => {
        if (!checked) return
        selectStudents({
          classesOnly: true,
          title: 'Classes',
          saveText: 'Select',
          initialValue: selectedPairs,
          callback: async pairs => {
            const classIds = [...new Set(pairs.students.map(pair => pair.classId))]
            if (classIds.length > 0) {
              onConfirm({ classIds }, { courseName: '' })
            }
          },
        })
      }}
    >
      Pick Specific Classes...
    </Dropdown.Item.Checkbox>,
  ]

  if (isInDropdown) {
    return (
      <Dropdown.SubMenu key={'MultipleClasses'}>
        <Dropdown.SubMenu.Trigger {...children.props} />
        <Dropdown.SubMenu.Content>{menuItems}</Dropdown.SubMenu.Content>
      </Dropdown.SubMenu>
    )
  } else {
    return (
      <Dropdown.Container>
        <Dropdown.ManualTrigger>{children}</Dropdown.ManualTrigger>
        <Dropdown.Menu>{menuItems}</Dropdown.Menu>
      </Dropdown.Container>
    )
  }
}

function MultipleClassesOptionsLabel({
  value,
  context,
}: ReportDimensionOptionsLabelProps<ClassesReportDimensionInput, MultipleClassesContext>) {
  const { selectedKey } = useMultipleClasses({ value, context })
  return <>{selectedKey || 'Select a Course'}</>
}

export const classGraphOptions = inferGraphContext({
  useContext: () => {
    const { classes } = useClasses()
    return { classes }
  },
  label: (item: ClassesReportDimensionResultFragment, context) => {
    const classId = item.class.id
    const cls = context.classes.find(cls => cls.id === classId)
    return cls ? cls.name : null
  },
})

export default function addDimension(feature: CourseReportsReactFeature) {
  feature.resources.addResource<
    ReportDimensionResource<
      ClassesReportDimensionInput,
      ClassesReportDimensionResultFragment,
      MultipleClassesContext
    >
  >({
    type: 'ReportDimension',
    identifier: 'MultipleClasses',
    inputKey: 'classes',
    title: 'Multiple Classes',
    singular: 'Class',
    description: 'I want to compare multiple classes with one another.',
    icon: <AddKeyframes />,
    hasMultipleValues: true,
    supportedIndices: [0],
    fragment: ClassesReportDimensionResultFragmentDoc,
    columnWidth: 175,
    renderTableHeader: ClassHeader,
    useGetExportHeader: useClassHeader,
    graph: classGraphOptions,
    shortLabel: (input, { courseName }) => {
      if (courseName) {
        return `all of my ${courseName} classes`
      } else {
        return Format.plural('class', input.classIds.length)
      }
    },
    titleLabel: (input, { courseName }) => {
      if (courseName) {
        return `All ${courseName} Classes`
      } else {
        return Format.plural('Class', input.classIds.length)
      }
    },
    renderOptionsWrapper: MultipleClassesOptionsWrapper,
    renderOptionsLabel: MultipleClassesOptionsLabel,
  })
}
