import { EditorValue, getPlainTextFromEditorState } from '@thesisedu/feature-widgets-core'
import React from 'react'

import { debug } from './log'

export function useWidgetPlainText(value?: EditorValue | null) {
  const [plainText, setPlainText] = React.useState<string | null>(null)
  React.useEffect(() => {
    if (value) {
      getPlainTextFromEditorState(value).then(text => {
        debug('setting plain text %s', text)
        setPlainText(text)
      })
    } else {
      setPlainText(null)
    }
  }, [value])
  return plainText
}

export interface WidgetPlainTextProps {
  value?: EditorValue | null
}
export function WidgetPlainText({ value }: WidgetPlainTextProps) {
  const plainText = useWidgetPlainText(value)
  return <>{plainText}</>
}
