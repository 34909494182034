import { ApolloReactFeature } from '@thesisedu/feature-apollo-react'
import { useFeature, useFeatureRoot } from '@thesisedu/feature-react'
import { UsersWebFeature, loginUser } from '@thesisedu/feature-users-web'
import { useDebounce, useFlag } from '@thesisedu/react'
import {
  Button,
  HSpaced,
  LoadingIndicator,
  Modal,
  Separator,
  Text,
  TextField,
  VSpaced,
  useToast,
} from '@thesisedu/ui'
import { NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { error } from '../log'
import {
  RefreshAuthMutation_refreshAuth_LoginResponse as LoginResponse,
  UserFragment,
} from '../schema'
import { SAMLButtonPlacement, SAMLButtonResource } from '../types'
import { SAMLButtonsProps } from '../web/routes/SAMLButtons'

interface InnerProps extends SAMLButtonsProps {
  scopes: string[]
}

interface UseLoginOpts {
  scopes: string[]
  onError: (err: string) => void
  onSuccess: (result: { jwt: LoginResponse['jwt']; user: UserFragment }) => Promise<void>
}
interface UseLoginResult<SignInOpts = unknown> {
  signIn: (opts: SignInOpts) => void
  loggingIn: boolean
}
type UseLoginHook<SignInOpts = unknown> = (opts: UseLoginOpts) => UseLoginResult<SignInOpts>
type UseScopesHook = () => string[]
export interface UseIntegrationSearchOpts {
  name: string
  skip?: boolean
}
export interface UseIntegrationSearchResultItem<SignInOpts> {
  signInOpts: SignInOpts
  id: string
  name: string
  siteName?: string | null
}
export interface SAMLAuthInfo<SignInOpts> extends UseIntegrationSearchResultItem<SignInOpts> {
  label: string
}
export interface UseIntegrationSearchResult<SignInOpts> {
  loading?: boolean
  results: UseIntegrationSearchResultItem<SignInOpts>[]
}
type UseIntegrationSearchHook<SignInOpts> = (
  opts: UseIntegrationSearchOpts,
) => UseIntegrationSearchResult<SignInOpts>
interface SAMLResultProps<SignInOpts> {
  result: UseIntegrationSearchResultItem<SignInOpts>
  onPress: () => void
}

export interface GetSAMLButtonOpts<SignInOpts = unknown> {
  identifier: string
  label: string
  icon: React.ReactElement
  useLoginHook: UseLoginHook<SignInOpts>
  useIntegrationSearch?: UseIntegrationSearchHook<SignInOpts>
  useScopes?: UseScopesHook
}
export function getSAMLButton<SignInOpts>(
  opts: GetSAMLButtonOpts<SignInOpts>,
): SAMLButtonResource['component']
export function getSAMLButton(
  opts: Omit<GetSAMLButtonOpts<unknown>, 'useIntegrationSearch'>,
): SAMLButtonResource['component']
export function getSAMLButton<SignInOpts = unknown>({
  identifier,
  label,
  icon,
  useLoginHook,
  useIntegrationSearch,
  useScopes: _useScopes,
}: GetSAMLButtonOpts<SignInOpts>): SAMLButtonResource['component'] {
  const useScopes = _useScopes ?? (() => [])
  const shouldUseScopes = !!_useScopes
  const infoFlag = (release: string) => `${identifier}-${release}-sso-info`

  function SAMLResult({ onPress, result }: SAMLResultProps<SignInOpts>) {
    return (
      <Button aria-label={`Sign in with ${result.name}`} variant={'ghost'} onPress={onPress}>
        <HSpaced style={{ flex: 1 }}>
          <div style={{ flex: 1, minWidth: 0, textAlign: 'left' }}>
            <Text level={'l'} truncate>
              {result.siteName ?? result.name}
            </Text>
            {result.siteName ? (
              <Text truncate color={'secondary'}>
                {result.name}
              </Text>
            ) : null}
          </div>
          <NavArrowRight style={{ flexShrink: 0 }} />
        </HSpaced>
      </Button>
    )
  }

  let Inner: (props: InnerProps) => React.ReactElement
  if (useIntegrationSearch) {
    Inner = ({ scopes, placement, onRegister }) => {
      const navigate = useNavigate()
      const release = useFeatureRoot().release
      const usersFeature = useFeature(UsersWebFeature)
      const apolloFeature = useFeature(ApolloReactFeature)
      const [authInfo, setAuthInfo] = useFlag<SAMLAuthInfo<SignInOpts> | null>(
        infoFlag(release),
        null,
      )
      const [visible, setVisible] = React.useState(false)
      const [nameSearch, setNameSearch] = React.useState('')
      const [dNameSearch] = useDebounce(nameSearch)
      React.useEffect(() => {
        if (!visible) {
          setNameSearch('')
        }
      }, [visible])
      const { results, loading } = useIntegrationSearch({
        name: dNameSearch,
        skip: !dNameSearch || dNameSearch.length <= 3,
      })
      const toast = useToast()
      const onError = (err: string) => {
        error('error logging in with %s', identifier)
        error(err)
        toast({ title: `There was an error logging in with ${label}.`, status: 'error' })
      }
      const { signIn, loggingIn } = useLoginHook({
        scopes,
        onError,
        onSuccess: async result => {
          if (onRegister) {
            await onRegister()
          }
          await loginUser({
            navigate,
            usersFeature,
            apolloFeature,
            jwt: result.jwt,
            user: result.user,
          })
        },
      })
      const onSelect = (label: string, result: UseIntegrationSearchResultItem<SignInOpts>) => {
        setAuthInfo({ label, ...result })
        setVisible(false)
        signIn(result.signInOpts)
      }

      return (
        <Modal
          width={500}
          style={{ minHeight: 400 }}
          trigger={
            <Button
              loading={loggingIn}
              style={{ flex: 1 }}
              icon={icon}
              children={
                placement === SAMLButtonPlacement.Prominent ? `Sign in with ${label}` : label
              }
            />
          }
          visible={visible}
          onVisibleChange={setVisible}
          title={`Find your ${label} Account`}
        >
          {authInfo ? (
            <>
              <Text level={'l'} bottom={'s'}>
                Welcome back!
              </Text>
              <Button
                variant={'primary'}
                size={'large'}
                style={{ width: '100%' }}
                children={`Sign in with ${authInfo.name}`}
                onPress={() => {
                  onSelect(authInfo.name, authInfo)
                }}
                loading={loggingIn}
              />
              <Separator children={'or'} top={'s'} bottom={'s'} />
            </>
          ) : (
            <Text bottom={'s'}>
              To sign in with {label}, use the box below to find your school or district.
            </Text>
          )}
          <TextField
            size={'large'}
            autoComplete={'off'}
            placeholder={'Type your school or district name here...'}
            value={nameSearch}
            disabled={loggingIn}
            onChange={v => {
              setAuthInfo(null)
              setNameSearch(v)
            }}
          />
          <VSpaced top={'s'}>
            {loading || (nameSearch && nameSearch !== dNameSearch) ? (
              <LoadingIndicator.Labeled label={'Finding your school or district...'} />
            ) : loggingIn ? (
              <LoadingIndicator.Labeled label={`Signing in with ${authInfo?.name ?? label}...`} />
            ) : nameSearch?.length > 3 && dNameSearch?.length > 3 && results.length ? (
              <>
                {results.map(result => {
                  return (
                    <SAMLResult
                      key={result.id}
                      result={result}
                      onPress={() => {
                        onSelect(result.name, result)
                      }}
                    />
                  )
                })}
              </>
            ) : nameSearch?.length > 3 && dNameSearch?.length > 3 ? (
              <Text color={'secondary'} style={{ textAlign: 'center' }}>
                We could not find any matching schools or districts.
              </Text>
            ) : null}
          </VSpaced>
        </Modal>
      )
    }
  } else {
    Inner = ({ scopes, placement, onRegister }) => {
      const navigate = useNavigate()
      const usersFeature = useFeature(UsersWebFeature)
      const apolloFeature = useFeature(ApolloReactFeature)
      const toast = useToast()
      const onError = (err: string) => {
        error('error logging in with %s', identifier)
        error(err)
        toast({ title: `There was an error logging in with ${label}.`, status: 'error' })
      }
      const { signIn, loggingIn } = useLoginHook({
        scopes,
        onError,
        onSuccess: async result => {
          if (onRegister) {
            await onRegister()
          }
          await loginUser({
            navigate,
            usersFeature,
            apolloFeature,
            jwt: result.jwt,
            user: result.user,
          })
        },
      })

      return (
        <Button
          loading={loggingIn}
          style={{ flex: 1 }}
          icon={icon}
          onPress={() => signIn(undefined as any)}
          children={placement === SAMLButtonPlacement.Prominent ? `Sign in with ${label}` : label}
        />
      )
    }
  }

  function LoginButton(props: SAMLButtonsProps) {
    const scopes = useScopes()
    if (shouldUseScopes && !scopes.length) {
      return null
    } else {
      return <Inner scopes={scopes} {...props} />
    }
  }

  return LoginButton
}
