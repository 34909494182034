import React from 'react'
import { FieldValues, FieldPath } from 'react-hook-form'

import { ConnectedFormFieldProps, FormFieldConnector } from './FormFieldConnector'
import Switch, * as Switch$ from '../Switch'

function _FormSwitch<TFieldValues extends FieldValues, FieldName extends FieldPath<TFieldValues>>(
  props: ConnectedFormFieldProps<Switch$.SwitchProps, TFieldValues, FieldName>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <FormFieldConnector {...props} valuePropName={'checked'}>
      <Switch ref={ref} />
    </FormFieldConnector>
  )
}
export const FormSwitch = React.forwardRef(_FormSwitch)
