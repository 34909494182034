import { onMutationError } from '@thesisedu/feature-apollo-react'
import { AntIconWrapper, Block } from '@thesisedu/web'
import { Button, Form } from 'antd'
import { Plus } from 'iconoir-react'
import React from 'react'

import { CampaignForm } from './CampaignForm'
import { TrialRequestCampaignFragment, useCreateTrialRequestCampaignMutation } from '../schema'

export interface CreateCampaignProps {
  onCreated: (campaign: TrialRequestCampaignFragment) => void
}
export function CreateCampaign({ onCreated }: CreateCampaignProps) {
  const [create, { loading }] = useCreateTrialRequestCampaignMutation({
    refetchQueries: ['trialRequestCampaigns'],
    awaitRefetchQueries: true,
    onError: onMutationError('There was an error creating your campaign.'),
    onCompleted: data => {
      onCreated(data.createTrialRequestCampaign.trialRequestCampaign)
    },
  })
  const [form] = Form.useForm()
  return (
    <>
      <Block marginBottom={'@size-m'} style={{ textAlign: 'right' }}>
        <Button
          type={'primary'}
          icon={<AntIconWrapper children={<Plus />} />}
          loading={loading}
          onClick={() => form.submit()}
        >
          Create Campaign
        </Button>
      </Block>
      <CampaignForm
        form={form}
        onFinish={values => {
          create({
            variables: {
              input: values,
            },
          })
        }}
      />
    </>
  )
}
