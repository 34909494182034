import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { BlockSpin } from './LoadingIndicators'

const LoadMoreContainer = styled.div`
  display: flex;
  margin-top: ${props => props.theme['@size-s']};
  justify-content: center;
  flex-direction: row;
`

export enum ScrollDirection {
  Vertical,
  Horizontal,
}
export type ScrollableTargetFn = () => HTMLElement | null
export interface InfiniteScrollerProps {
  scrollableTarget?: HTMLElement | ScrollableTargetFn
  direction?: ScrollDirection
  loadMore: () => Promise<any>
  hasMore?: boolean
  threshold?: number
  hideLoader?: boolean
}

export function InfiniteScroller({
  scrollableTarget: _scrollableTarget,
  direction = ScrollDirection.Vertical,
  loadMore,
  hasMore,
  children,
  threshold = 0.5,
  hideLoader,
}: PropsWithChildren<InfiniteScrollerProps>): React.ReactElement {
  const [fetchingMore, setFetchingMore] = useState(false)
  const fetchPromise = useRef<Promise<any>>()
  const scrollD: 'scrollX' | 'scrollY' =
    direction === ScrollDirection.Vertical ? 'scrollY' : 'scrollX'
  const scrollT: 'scrollTop' | 'scrollLeft' =
    direction === ScrollDirection.Vertical ? 'scrollTop' : 'scrollLeft'
  const scrollH: 'scrollHeight' | 'scrollWidth' =
    direction === ScrollDirection.Vertical ? 'scrollHeight' : 'scrollWidth'
  const clientH: 'clientHeight' | 'clientWidth' =
    direction === ScrollDirection.Vertical ? 'clientHeight' : 'clientWidth'
  const innerH: 'innerHeight' | 'innerWidth' =
    direction === ScrollDirection.Vertical ? 'innerHeight' : 'innerWidth'

  useEffect(() => {
    let undo: () => void
    const processScrollableTarget = () => {
      const target =
        typeof _scrollableTarget === 'function' ? _scrollableTarget() : _scrollableTarget || window
      const scrollableTarget =
        typeof _scrollableTarget === 'function' ? _scrollableTarget() : _scrollableTarget
      if (hasMore && target) {
        const onScroll = () => {
          let scrollY = scrollableTarget ? scrollableTarget[scrollT] : window[scrollD]
          const height = scrollableTarget
            ? scrollableTarget[scrollH]
            : window.document.body[scrollH]
          const containerHeight = scrollableTarget ? scrollableTarget[clientH] : window[innerH]
          scrollY += containerHeight
          const thresholdPx = threshold * containerHeight
          if (height - scrollY <= thresholdPx && !fetchPromise.current) {
            setFetchingMore(true)
            fetchPromise.current = loadMore()
              .catch(err => {
                console.warn('Error occurred when fetching more.', err)
              })
              .then(() => {
                setTimeout(() => {
                  setFetchingMore(false)
                  fetchPromise.current = undefined
                }, 100)
              })
          }
        }
        target.addEventListener('scroll', onScroll, { passive: true })
        return () => target.removeEventListener('scroll', onScroll)
      } else {
        return false
      }
    }

    // Try to process it now. If it's not here, try again after a short delay.
    const result = processScrollableTarget()
    let interval: any
    if (!result) {
      interval = setTimeout(() => {
        const result = processScrollableTarget()
        if (result) {
          undo = result
        }
      }, 150)
    } else {
      undo = result
    }

    return () => {
      if (undo) {
        undo()
      }
      clearInterval(interval)
    }
  }, [hasMore, _scrollableTarget, setFetchingMore, loadMore])

  const loader =
    fetchingMore && !hideLoader ? (
      <LoadMoreContainer>
        <BlockSpin />
      </LoadMoreContainer>
    ) : null

  return (
    <>
      {children}
      {loader}
    </>
  )
}
