import { FeatureDependencies, FeatureUse } from '@thesisedu/feature'
import { useModifiedFragment, addField } from '@thesisedu/feature-apollo-react'
import { DirectMessageUserSourceResource } from '@thesisedu/feature-direct-messages-react'
import { ReactFeature, isInNode } from '@thesisedu/feature-react'
import { SiteFormItemResource } from '@thesisedu/feature-sites-web'
import { isNative } from '@thesisedu/react'
import React from 'react'

import { ClassTaskSummaryProviderResource, ClassesWebOptions, addTeacherPermission } from './types'
import { useUsers } from './useUsers'

export class ClassesReactFeature extends ReactFeature {
  public static package = '@thesisedu/feature-classes-react'
  static path = ''
  static requires: string[] = []
  public options: ClassesWebOptions

  constructor(options: Partial<ClassesWebOptions>, deps: FeatureDependencies) {
    super(options, deps)
    this.options = {
      className: 'class',
      classNamePlural: 'classes',
      ...options,
    }
    this.resources.addResource<SiteFormItemResource>({
      type: 'SiteFormItem',
      identifier: 'classes-site-form-disable-codes',
      render: () => {
        const { SiteFormExtension } = require('./SiteFormExtension')
        return <SiteFormExtension />
      },
    })
    this.resources.addResource<DirectMessageUserSourceResource>({
      type: 'DirectMessageUserSource',
      identifier: 'classes-user-source',
      useUsers,
    })
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'Site', fragment =>
      addField(fragment, 'disableCourseCodes'),
    )
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useModifiedFragment(this.hookManager, 'SiteConfiguration', fragment => {
      return addField(fragment, 'defaultClassConfiguration')
    })
    addTeacherPermission(this, 'Classes', `Invite new students to this class`, ['INVITE_STUDENT'])
    addTeacherPermission(this, 'Classes', `Remove students from this class`, [
      'REMOVE_STUDENT_FROM_CLASS',
    ])
    addTeacherPermission(this, 'Classes', `Manage class settings`, ['UPDATE_CLASS_SETTINGS'])
    addTeacherPermission(this, 'Classes', 'Update students', ['UPDATE_USER'])

    if (!isInNode() && !isNative) {
      const { addImportModes } = require('./resources/import')
      addImportModes(this.resources)
      require('./resources/site_configuration').addSiteConfigurationFields(this)
      require('./hooks/register').default(this)
      require('./hooks/routes').default(this)
      require('./hooks/TeacherContext').default(this)
      require('./hooks/StudentContext').default(this)
      require('./hooks/ClassLeftNav').default(this)
      require('./hooks/classRoutes').default(this)
      require('./hooks/traceSampleRate').default(this)
      require('./resources/StudentAction').default(this)
    }
  }

  public prepareEarly() {
    const dmFeature = this.root.getFeature('@thesisedu/feature-direct-messages-react')
    if (dmFeature && !isInNode()) {
      const { UnreadDirectMessagesTask } = require('./integration/UnreadDirectMessagesTask')
      this.resources.addResource<ClassTaskSummaryProviderResource>({
        type: 'ClassTaskSummaryProvider',
        identifier: 'UnreadDirectMessagesTask',
        provider: UnreadDirectMessagesTask,
      })
      addTeacherPermission(this, 'Messages', 'Message students', ['MESSAGE_USER'])
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useModifiedFragment(this.hookManager, 'MinimalClass', fragment => {
        return addField(fragment, 'canMessageStudents')
      })
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useModifiedFragment(this.hookManager, 'Class', fragment => {
        return addField(fragment, 'canMessageStudents')
      })
      require('./hooks/ClassDraggableOverlayItems').default(this)
      require('./integration/messagesClassRoute').default(this)
    }
    if (!isInNode() && this.root.getFeature('@thesisedu/feature-search-react')) {
      require('./resources/search').default(this)
    }
    if (!isInNode() && this.root.getFeature('@thesisedu/feature-user-tasks-react')) {
      require('./integration/userTasksClassRoute').default(this)
    }
    if (!isInNode()) {
      require('./approval/prepareEarly').default(this)
    }
  }
}

export const classesReactFeature = (opts: Partial<ClassesWebOptions> = {}): FeatureUse => [
  ClassesReactFeature,
  opts,
]
export default ClassesReactFeature
