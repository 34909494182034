import { useClasses } from '@thesisedu/feature-classes-react'
import { LoadingIndicator } from '@thesisedu/react'
import { styled } from '@thesisedu/web'
import React from 'react'

import { LicenseInvalidContent } from './LicenseInvalidModal'
import { useClassValidity } from './useClassValidity'

const ContentContainer = styled.div`
  max-width: 600px;
  margin: ${props => props.theme['@size-xl']} auto;
`

export const ClassValidationGate: React.FC<React.PropsWithChildren<object>> = ({ children }) => {
  const { loading, anyValid } = useClassValidity()
  const { classes, loading: _classesLoading } = useClasses()
  if (loading || _classesLoading) {
    return <LoadingIndicator block />
  } else if (!anyValid && classes.length) {
    return (
      <ContentContainer>
        <LicenseInvalidContent classId={classes[0].id} />
      </ContentContainer>
    )
  } else {
    return <>{children}</>
  }
}
