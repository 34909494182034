import { Form } from '@thesisedu/react'
import { Button, Modal } from 'antd'
import { ModalProps } from 'antd/es/modal'
import React from 'react'

import { LessonForm } from './LessonForm'
import { useEditLesson } from './useEditLesson'
import { LessonFragment } from '../schema'

export interface EditLessonModalProps extends Partial<ModalProps> {
  lesson: LessonFragment
  onCancel: () => void
}
export const EditLessonModal: React.FC<React.PropsWithChildren<EditLessonModalProps>> = ({
  lesson,
  ...props
}) => {
  const { form, loading, onFinish } = useEditLesson({
    lesson,
    fieldsKey: props.visible,
    onSave: () => {
      if (props.onCancel) {
        props.onCancel()
      }
    },
  })
  return (
    <Modal
      {...props}
      title={'Edit Lesson'}
      footer={[
        <Button key={'cancel'} onClick={props.onCancel}>
          Cancel
        </Button>,
        <Form.StandaloneSubmit loading={loading} key={'save'} form={form} onFinish={onFinish}>
          Save Changes
        </Form.StandaloneSubmit>,
      ]}
    >
      <LessonForm form={form} onFinish={onFinish} />
    </Modal>
  )
}
