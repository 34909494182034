import { useAllStudentsQuery, useClasses } from '@thesisedu/feature-classes-react'
import { ClassFragment } from '@thesisedu/feature-classes-react/dist/schema'
import {
  ReportDimensionTableHeaderProps,
  useReportGridContext,
} from '@thesisedu/feature-reports-react'
import { Format } from '@thesisedu/feature-utils'
import { LoadingIndicator } from '@thesisedu/react'
import { BodySmall, styled } from '@thesisedu/web'
import React from 'react'

import { ClassesReportDimensionResultFragment, StudentsReportDimensionInput } from '../../../schema'
import { StudentsOptionsContext } from '../MultipleStudents'

export function useClassHeader() {
  const { classes } = useClasses()
  return ({ result }: ReportDimensionTableHeaderProps<ClassesReportDimensionResultFragment>) => {
    const cls = classes.find(cls => cls.id === result.class.id) as ClassFragment | null | undefined
    return cls?.name || 'Unknown Class'
  }
}

export function ClassHeader({
  result,
  type,
}: ReportDimensionTableHeaderProps<ClassesReportDimensionResultFragment>) {
  const { classes, loading } = useAllStudentsQuery()
  const { setOpts, opts } = useReportGridContext(true)
  const cls = classes?.find(cls => cls.id === result.class.id)
  const numStudents = cls?.students.edges.length
  if (loading) return <LoadingIndicator />
  if (!cls) return null
  if (type === 'column') {
    return (
      <Container
        onClick={e => {
          e.preventDefault()
          const students = cls.students?.edges.map(edge => edge.node)
          // Change the first dimension to the students for this class.
          if (students.length) {
            setOpts({
              ...opts,
              dimensions: [
                {
                  context: {
                    firstName: '',
                    fullClassNames: [cls.name],
                  } as StudentsOptionsContext,
                  identifier: 'MultipleStudents',
                  options: {
                    studentPairs: students.map(s => ({
                      classId: cls.id,
                      studentId: s.id,
                    })),
                  } as StudentsReportDimensionInput,
                },
                ...opts.dimensions.slice(1),
              ],
            })
          }
        }}
      >
        <Name>{cls.name}</Name>
        <BodySmall color={'@text-color-secondary'} style={{ fontSize: 12 }}>
          {Format.plural('student', numStudents ?? 0)}
        </BodySmall>
      </Container>
    )
  } else if (type === 'row') {
    return <Name>{cls.name}</Name>
  } else return null
}

const Container = styled.div`
  cursor: pointer;
`
const Name = styled(BodySmall)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
