import { Checkbox } from './Checkbox'
import { CheckboxGroup } from './CheckboxGroup'
import { withSubComponents } from '../../utils/withSubComponents'

export * from './Checkbox'
export * from './CheckboxGroup'

export default withSubComponents(Checkbox, {
  Group: CheckboxGroup,
})
