import { s } from '@thesisedu/ui'
import { css, keyframes } from 'styled-components'

const tableControlsAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const TableStyles = css`
  .fteditor {
    &-table {
      border-collapse: collapse;
      border-spacing: 0;
      max-width: 100%;
      overflow-y: scroll;
      table-layout: fixed;
      margin: ${s.size('1')} auto;
    }
    &-tableSelected {
      outline: 2px solid ${s.color('primary')};
    }
    &-tableCell {
      border: 1px solid ${s.color('gray.border')};
      min-width: 75px;
      vertical-align: top;
      text-align: start;
      padding: ${s.size('0.5')};
      position: relative;
      cursor: default;
      outline: none;
      font-size: ${s.size('font.m')};
      p {
        margin: ${s.size('xxs')} 0;
      }
      .fteditor-tableCellContent,
      .fteditor-root {
        > :last-child {
          margin-bottom: 0;
        }
        > :first-child {
          margin-top: 0;
        }
      }
    }
    &-tableCellSortedIndicator {
      display: block;
      opacity: 0.5;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${s.color('gray.hoverBorder')};
    }
    &-tableCellResizer {
      position: absolute;
      right: -4px;
      height: 100%;
      width: 8px;
      cursor: ew-resize;
      z-index: 10;
      top: 0;
    }
    &-tableCellHeader {
      background-color: ${s.color('gray.element')};
      text-align: start;
    }
    &-tableCellSelected {
      background-color: ${s.color('primary.element')};
    }
    &-tableCellPrimarySelected {
      border: 2px solid ${s.color('primary')};
      display: block;
      position: absolute;
      inset: -1px;
      z-index: 2;
    }
    &-tableCellEditing {
      box-shadow: 0 0 5px ${s.color('overlayBlack.solid')};
      border-radius: 3px;
    }
    &-tableAddColumns {
      position: absolute;
      top: 0;
      width: 20px;
      background-color: ${s.color('gray.element')};
      height: 100%;
      right: -25px;
      animation: ${tableControlsAnimation} 0.2s ease;
      border: 0;
      cursor: pointer;
    }
    &-tableAddColumns:after {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='46px' height='46px' stroke-width='1.4' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23000000'%3E%3Cpath d='M6 12h6m6 0h-6m0 0V6m0 6v6' stroke='%23000000' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    &-tableAddColumns:hover {
      background-color: ${s.color('gray.hoverElement')};
    }
    &-tableAddRows {
      position: absolute;
      bottom: -25px;
      width: 100%;
      background-color: ${s.color('gray.element')};
      height: 20px;
      left: 0;
      animation: ${tableControlsAnimation} 0.2s ease;
      border: 0;
      cursor: pointer;
    }
    &-tableAddRows:after {
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='46px' height='46px' stroke-width='1.4' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' color='%23000000'%3E%3Cpath d='M6 12h6m6 0h-6m0 0V6m0 6v6' stroke='%23000000' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/svg%3E");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
    }
    &-tableAddRows:hover {
      background-color: ${s.color('gray.hoverElement')};
    }
    &-tableCellResizeRuler {
      display: block;
      position: absolute;
      width: 1px;
      background-color: ${s.color('primary')};
      height: 100%;
      top: 0;
    }
    &-tableCellActionButtonContainer {
      display: block;
      right: ${s.size('xs')};
      top: ${s.size('xs')};
      position: absolute;
      z-index: 4;
    }
  }
`
export const ReadonlyTableStyles = css`
  .fteditor {
    &-tableAddRows,
    &-tableAddColumns {
      display: none;
    }
    &-tableCellResizer {
      display: none;
    }
  }
`
