import { HSpaced, Tooltip } from '@thesisedu/ui'
import { Settings } from '@thesisedu/ui/icons'
import React from 'react'

import MetronomeIcon from '../../metronome/Icon'
import { Indicator } from '../../metronome/Indicator'
import { useMetronomeContext } from '../../metronome/MetronomeContext'
import { Popover } from '../../metronome/Popover'
import { useRecordStateContext } from '../../record/states/record/RecordStateContext'
import { RecordFooterButton } from '../../record/states/record/footer/RecordFooterButton'
import { FooterItem } from '../api/FooterItem'

export function MetronomePlugin() {
  const context = useMetronomeContext(false)
  const { state } = useRecordStateContext(false) ?? {}
  React.useEffect(() => {
    if (!state && context) {
      context.update({ playing: false })
    }
  }, [state])
  if (!context) return null
  return (
    <FooterItem weight={10} showBeforeRecording>
      <HSpaced style={{ gap: 0 }}>
        <Tooltip title={'Metronome'}>
          <RecordFooterButton
            style={{
              display: 'flex',
              flexDirection: 'column',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onPress={() => context.update({ playing: !context.playing })}
          >
            <MetronomeIcon />
            <Indicator style={{ width: '6px', height: '6px' }} />
          </RecordFooterButton>
        </Tooltip>
        <Popover>
          <Tooltip title={'Metronome Settings'}>
            <RecordFooterButton
              icon={<Settings />}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            />
          </Tooltip>
        </Popover>
      </HSpaced>
    </FooterItem>
  )
}
