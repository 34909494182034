import React from 'react'

import { BaseImageProps, ImageOrVideoWrapper } from './Image'

export interface VideoProps extends React.HTMLAttributes<HTMLVideoElement>, BaseImageProps {}
function _Video(props: VideoProps, ref: React.ForwardedRef<HTMLVideoElement>) {
  return (
    <ImageOrVideoWrapper
      {...props}
      loadProp={'onCanPlay'}
      containerStyle={{ width: 'fit-content' }}
    >
      <video autoPlay loop controls={false} ref={ref}>
        <source src={props.src} />
      </video>
    </ImageOrVideoWrapper>
  )
}
export const Video = React.forwardRef(_Video)
