import * as ReactTabs from '@radix-ui/react-tabs'
import { motion } from 'framer-motion'
import React from 'react'

import { useTabContentContext } from './Content'
import { styled, s } from '../..'

export interface TabProps extends ReactTabs.TabsContentProps {
  children: React.ReactNode
}
function _Tab({ children, ...rest }: TabProps, ref: React.ForwardedRef<HTMLDivElement>) {
  const { useCSSAnimations } = useTabContentContext(false) ?? {}
  return (
    <ReactTabs.Content {...rest} asChild>
      {useCSSAnimations ? (
        <__AnimatedTab ref={ref} children={children} />
      ) : (
        <__Tab
          ref={ref}
          children={children}
          initial={{ opacity: 0, scale: 0.99 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.99 }}
          transition={{ duration: 0.2 }}
        />
      )}
    </ReactTabs.Content>
  )
}
export const Tab = React.forwardRef(_Tab)

const { css } = s
const TabStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

const __AnimatedTab = styled.div`
  ${TabStyles}
  transition: opacity 0.2s linear, transform 0.2s ease-in-out;
  opacity: 0;
  transform: scale(0.99);
  pointer-events: none;
  display: flex;
  > div {
    flex: 1;
    display: flex;
    max-height: 100%;
  }
  &.visible,
  &[data-state='active'] {
    pointer-events: all;
    opacity: 1;
    transform: scale(1);
    transition-delay: 0.2s;
  }
`
const __Tab = styled(motion.div)`
  ${TabStyles}
  > div {
    display: flex;
    flex: 1;
  }
`
