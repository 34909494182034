import { useResource } from '@thesisedu/feature-react'

import { warn } from './log'
import { ResourceRenderMode, SearchResultNode, SearchResultResource } from './types'

export function useSearchResultRenderer(mode: ResourceRenderMode, typename: string) {
  const resource = useResource<SearchResultResource<SearchResultNode>>('SearchResult', typename)
  if (resource && resource[mode]) {
    return resource[mode]
  } else {
    warn('Cannot find search result renderer for %s resource and %s mode', typename, mode)
    return null
  }
}

export function useSearchResultLinkHandler(typename: string) {
  const resource = useResource<SearchResultResource<SearchResultNode>>('SearchResult', typename)
  if (resource && resource.getUniversalLink) {
    return resource.getUniversalLink
  } else {
    warn('Cannot find search link handler for %s resource', typename)
    return null
  }
}

export function useValidRendererResources(mode: ResourceRenderMode) {
  return useResource<SearchResultResource<SearchResultNode>>('SearchResult').filter(
    resource => !!resource[mode],
  )
}
export function useValidRenderers(mode: ResourceRenderMode) {
  return useValidRendererResources(mode).map(resource => resource.identifier)
}
