import { adjustHue } from 'polished'
import { createGlobalStyle } from 'styled-components'

export const ParticleStyle = createGlobalStyle`
  particle {
    border-radius: 50%;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    opacity: 0;
    z-index: 10000;
  }
`

interface ParticleOptions {
  color: string
  maxSize?: number
  minSize?: number
  radius?: number
  duration?: number
}
export function showParticles(element: HTMLElement, opts: ParticleOptions) {
  const bounds = element.getBoundingClientRect()
  const x = bounds.x + bounds.width / 2
  const y = bounds.y + bounds.height / 2
  for (let i = 0; i < 30; i++) {
    createParticle(x, y, opts)
  }
}

function createParticle(
  x: number,
  y: number,
  { color, maxSize = 25, minSize = 5, radius = 75, duration = 1000 }: ParticleOptions,
) {
  const particle = document.createElement('particle')
  document.body.appendChild(particle)

  const size = Math.floor(Math.random() * (maxSize - minSize) + minSize)
  particle.style.width = `${size}px`
  particle.style.height = `${size}px`
  particle.style.background = adjustHue(Math.random() * 30, color)

  const destinationX = x + (Math.random() - 0.5) * 2 * radius
  const destinationY = y + (Math.random() - 0.5) * 2 * radius
  const animation = particle.animate(
    [
      {
        transform: `translate(${x - size / 2}px, ${y - size / 2}px)`,
        opacity: 1,
      },
      {
        transform: `translate(${destinationX}px, ${destinationY}px)`,
        opacity: 0,
      },
    ],
    {
      duration: 500 + Math.random() * duration,
      easing: 'cubic-bezier(0, .9, .57, 1)',
      delay: Math.random() * 200,
    },
  )

  animation.onfinish = () => {
    particle.remove()
  }
}
