import { onMutationError, updateMutation } from '@thesisedu/feature-apollo-react'

import { ClassFragmentDoc, useInviteStudentIdMutation as useMutation } from '../schema'

export const useInviteStudentIdMutation: typeof useMutation = opts =>
  useMutation({
    onError: onMutationError('That student could not be found.'),
    update: updateMutation({
      fragment: ClassFragmentDoc,
      fragmentName: 'Class',
      path: 'inviteStudentID.class',
    }),
    ...opts,
  })
