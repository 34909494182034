import React from 'react'

import { AssignmentSubmissionSummaryFragment, useAssignmentSubmissionSummaryQuery } from '../schema'

export interface TeacherSubmissionContextValue {
  submissionSummary?: AssignmentSubmissionSummaryFragment | null
}
export const TeacherSubmissionContext = React.createContext<
  TeacherSubmissionContextValue | undefined
>(undefined)

export interface TeacherSubmissionProviderProps {
  assignmentId: string
}
export function TeacherSubmissionProvider({
  assignmentId,
  children,
}: React.PropsWithChildren<TeacherSubmissionProviderProps>) {
  const { data } = useAssignmentSubmissionSummaryQuery({
    variables: { id: assignmentId },
  })
  return (
    <TeacherSubmissionContext.Provider
      value={{
        submissionSummary:
          data?.node?.__typename === 'Assignment' ? data.node.submissionSummary : undefined,
      }}
      children={children}
    />
  )
}

export function useTeacherSubmissionContext(): TeacherSubmissionContextValue | undefined
export function useTeacherSubmissionContext(
  require: false,
): TeacherSubmissionContextValue | undefined
export function useTeacherSubmissionContext(require: true): TeacherSubmissionContextValue
export function useTeacherSubmissionContext(
  require?: boolean,
): TeacherSubmissionContextValue | undefined {
  const context = React.useContext(TeacherSubmissionContext)
  if (!context && require) {
    throw new Error('TeacherSubmissionContext is required, yet not provided.')
  }
  return context
}

export function useQuestionSubmissionSummary(questionId: string) {
  const { submissionSummary } = useTeacherSubmissionContext(false) || {}
  return submissionSummary?.questions.find(q => q.id === questionId)
}
