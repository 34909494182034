import { styled, s, TextField, Text, Dropdown, Dropdown$ } from '@thesisedu/ui'
import { ArrowRight, DeleteCircle, Search } from '@thesisedu/ui/icons'
import React from 'react'

import { SelectButtonContentProps } from './types'
import { useAddListItem, useRenderItem, useSelectButtonContent } from './useSelectButtonContent'
import { InfiniteQueryList } from '../InfiniteQueryList'
import { RequiredVariables } from '../loadMore'
import { Node } from '../schema'

const Container = styled.div`
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${s.size('s')};
`
export const ListItem = styled.div`
  ${Dropdown$.ItemStyles}
`

export function SelectButtonContent<Item extends Node, Data, Variables extends RequiredVariables>(
  props: SelectButtonContentProps<Item, Data, Variables>,
) {
  const {
    value = [],
    getItemName,
    getItemSubtitle,
    extraItems,
    onAddFromSearch,
    maxSelection,
    ...infiniteQueryProps
  } = props
  const containerRef = React.useRef<HTMLDivElement>(null)
  const { onItemSelected, setFilter, filter, variables, getItemValue } =
    useSelectButtonContent(props)
  const renderItem = useRenderItem<Item>(
    item => {
      const selected = value.some(sitem => sitem === getItemValue(item))
      const icon = selected ? <DeleteCircle /> : <ArrowRight />
      const subtitle = getItemSubtitle ? getItemSubtitle(item) : undefined

      // We have to do it this way because React still adds the attribute when it's
      // set to false.
      const restProps: Record<string, any> = {}
      if (selected) restProps['data-highlighted'] = true

      return (
        <ListItem onClick={() => onItemSelected(item)} key={item.id} {...restProps}>
          <div style={{ flex: 1 }}>
            <span>{getItemName(item)}</span>
            {subtitle ? (
              <Text level={'xs'} color={'secondary'}>
                {subtitle}
              </Text>
            ) : null}
          </div>
          <Dropdown.Item.Right>{icon}</Dropdown.Item.Right>
        </ListItem>
      )
    },
    { filter, maxSelection, onAddFromSearch },
  )
  const { getAddItem } = useAddListItem<Item, Data, Variables>({
    getItemName,
    filter,
    onAddFromSearch,
  })

  return (
    <Container ref={containerRef}>
      <TextField
        aria-label={'Search for content'}
        placeholder={'Search for content...'}
        suffix={<Search />}
        size={'small'}
        value={filter}
        onChange={setFilter}
      />
      <InfiniteQueryList<Item, Data, Variables>
        variables={variables}
        renderItem={renderItem}
        infiniteScrollerProps={{ scrollableTarget: containerRef.current || undefined }}
        prependItems={extraItems}
        noMore
        appendItems={nodes =>
          [getAddItem([...(extraItems || []), ...nodes])].filter(Boolean) as any[]
        }
        {...infiniteQueryProps}
      />
    </Container>
  )
}
