import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useStudentUser } from '@thesisedu/feature-classes-react'
import { Button } from 'antd'
import React from 'react'

import { getStudentClassTabName } from './GradingModal'
import { useGradingModalContext } from '../contexts/GradingModalContext'

export function useNStudent(delta: number, { studentId }: NStudentButtonProps) {
  const { assignment, setSelectedTab } = useGradingModalContext(true)
  const currentIndex = assignment.students.edges.findIndex(edge => edge.node.id === studentId)
  const newStudent = assignment.students.edges[currentIndex + delta]
  const { student } = useStudentUser(
    newStudent?.node.id || '',
    newStudent?.classId || '',
    !newStudent,
  )
  return {
    setSelectedTab,
    student,
    classId: newStudent?.classId,
  }
}

export interface NStudentButtonProps {
  studentId: string
}

export function NextStudentButton(props: NStudentButtonProps) {
  const { setSelectedTab, student, classId } = useNStudent(1, props)
  return student ? (
    <Button onClick={() => setSelectedTab(getStudentClassTabName(student.id, classId))}>
      {student.user.name || student.user.username} <ArrowRightOutlined />
    </Button>
  ) : null
}

export function PreviousStudentButton(props: NStudentButtonProps) {
  const { setSelectedTab, student, classId } = useNStudent(-1, props)
  return student ? (
    <Button
      onClick={() => setSelectedTab(getStudentClassTabName(student.id, classId))}
      icon={<ArrowLeftOutlined />}
    >
      {student.user.name || student.user.username}
    </Button>
  ) : null
}
