import { useResource } from '@thesisedu/feature-react'
import { HSpaced, styled, s, Button, Tooltip, Modal, Text } from '@thesisedu/ui'
import { Trash } from '@thesisedu/ui/icons'
import React from 'react'

import { warn } from '../log'
import { Logo } from '../setup/Rostering/RosteringHeader'
import { ClassSyncProviderResource } from '../types'
import { ClassSyncItem } from '../useClassSyncSummary'

export interface ClassSyncSummaryItemProps {
  onDelete: () => Promise<void>
  sync: ClassSyncItem
}
export function ClassSyncSummaryItem({ onDelete, sync }: ClassSyncSummaryItemProps) {
  const provider = useResource<ClassSyncProviderResource>(
    'ClassSyncProvider',
    sync.providerIdentifier,
  )
  const [confirmVisible, setConfirmVisible] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  if (provider) {
    return (
      <Container>
        <Modal title={'Are you sure?'} visible={confirmVisible} onVisibleChange={setConfirmVisible}>
          <Text>
            If you delete this class sync, you will have to re-add it before you can roster students
            and sync assignments again.
          </Text>
          <Modal.Footer>
            <Modal.CloseButton children={'No, go back'} />
            <Button
              status={'danger'}
              variant={'primary'}
              children={'Yes, delete class sync'}
              loading={loading}
              onPress={async () => {
                setLoading(true)
                try {
                  await onDelete()
                } catch (err: any) {
                  warn('error deleting sync', err)
                } finally {
                  setLoading(false)
                }
                setConfirmVisible(false)
              }}
            />
          </Modal.Footer>
        </Modal>
        <div style={{ position: 'relative', flexShrink: 0 }}>
          <Logo
            src={provider.logos.square}
            alt={`Logo for ${sync.providerName}`}
            style={{ width: 48, height: 48 }}
          />
          {sync.canDelete ? (
            <DeleteContainer>
              <Tooltip title={'Delete Sync'}>
                <Button
                  icon={<Trash />}
                  status={'danger'}
                  variant={'ghost'}
                  onPress={() => {
                    setConfirmVisible(true)
                  }}
                />
              </Tooltip>
            </DeleteContainer>
          ) : null}
        </div>
        <div>
          <Text truncate>{sync.label}</Text>
          <Text level={'s'} color={'secondary'} truncate>
            {sync.providerName}
          </Text>
        </div>
      </Container>
    )
  } else return null
}

const DeleteContainer = styled.div`
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${s.opacity(s.color('gray.element'), 0.6)};
  backdrop-filter: blur(4px);
  border-radius: ${s.var('radii.1')};
`
const Container = styled(HSpaced)`
  &:hover ${DeleteContainer} {
    opacity: 1;
  }
`
