import React from 'react'

import { InfiniteSearch } from './InfiniteSearch'
import { SearchResultGrid, SearchResultGridProps } from './SearchResultGrid'
import { SearchQueryVariables } from '../schema'
import { CommonSearchProps } from '../types'

export type SearchGridProps<Variables extends SearchQueryVariables = SearchQueryVariables> =
  CommonSearchProps<Variables> &
    Omit<SearchResultGridProps, 'results' | 'itemHeightKey' | 'loadMore'>
export function SearchGrid<Variables extends SearchQueryVariables = SearchQueryVariables>({
  useSections,
  query,
  filters,
  orderBy,
  orderDirection,
  variables,
  searchDocument,
  queryOpts,
  ...props
}: SearchGridProps<Variables>) {
  const [itemHeightKey, setItemHeightKey] = React.useState(0)
  React.useEffect(() => {
    setItemHeightKey(ik => ik + 1)
  }, [filters, query, orderBy, orderDirection])
  return (
    <InfiniteSearch<Variables>
      filters={filters}
      query={query}
      orderBy={orderBy}
      orderDirection={orderDirection}
      mode={'renderGrid'}
      variables={variables}
      queryOpts={queryOpts}
      searchDocument={searchDocument}
      children={(items, { loadMore, refetch, loadingFresh }) => (
        <SearchResultGrid
          {...props}
          loadMore={loadMore}
          onRefresh={() => {
            return refetch()
          }}
          loadingFresh={loadingFresh}
          results={items}
          useSections={useSections}
          itemHeightKey={itemHeightKey.toString()}
        />
      )}
    />
  )
}
