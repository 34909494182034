import { Body, BodySmall, VSpaced } from '@thesisedu/web'
import React from 'react'

import { ReportMetricResource } from '../../types'

export interface MetricColHeaderProps {
  metric: ReportMetricResource
}
export function MetricColHeader({ metric }: MetricColHeaderProps) {
  return (
    <VSpaced space={'@size-xxs'}>
      <Body>{metric.icon}</Body>
      <BodySmall>{metric.title}</BodySmall>
    </VSpaced>
  )
}
