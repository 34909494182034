import { useResizeObserver } from '@thesisedu/web'
import React from 'react'

import { GridContext } from './GridContext'
import { InnerGrid } from './InnerGrid'
import { BaseGridItem, GridProps } from './types'

export function Grid<GridItem extends BaseGridItem>({
  itemHeight,
  header,
  ...contextValue
}: GridProps<GridItem>) {
  const ref = React.useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver({ ref })
  return (
    <GridContext.Provider value={contextValue}>
      <div ref={ref} style={{ width: '100%' }}>
        {width && width > 10 ? (
          <InnerGrid width={width} itemHeight={itemHeight} header={header} />
        ) : null}
      </div>
    </GridContext.Provider>
  )
}
