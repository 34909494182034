import {
  ReportResultItemFragment,
  ReportDimensionResult,
  FlatReportDimensionSummaryRow,
  ReportMetricResource,
  ReportDimensionResource,
  RunReportOpts,
} from '../../types'
import { ReportResultsProps } from '../ReportResults'
import { AxesResponsibility } from '../grid/axes'
import { dimensionMatches } from '../grid/cells/getReportCells'
import { getSummaryCells } from '../grid/summary'

function _filterResultsWithFilterDimension(
  results: ReportResultItemFragment[],
  filterDimension?: ReportDimensionResult | string,
  summaryRows?: FlatReportDimensionSummaryRow[],
) {
  if (typeof filterDimension === 'string' && summaryRows) {
    return summaryRows.find(row => row.id === filterDimension)?.results || []
  } else if (typeof filterDimension !== 'string') {
    return results.filter(resultItem => {
      if (filterDimension) {
        return Object.keys(resultItem.dimensions).some(dimensionKey => {
          return dimensionMatches(filterDimension, resultItem.dimensions[dimensionKey])
        })
      } else {
        return true
      }
    })
  } else {
    return []
  }
}

interface GetGraphDataOpts {
  data: ReportResultsProps['data']
  metric: ReportMetricResource
  dimension: ReportDimensionResource<any, any>
  responsibility: AxesResponsibility
  dimensionContexts: Record<string, any>
  filterDimension?: ReportDimensionResult | string
  otherDimension?: ReportDimensionResource<any, any>
  report: RunReportOpts
}
export function _getGraphData({
  data,
  report,
  responsibility,
  metric,
  dimension,
  filterDimension,
  otherDimension,
  dimensionContexts,
}: GetGraphDataOpts) {
  const summaryConfig = dimension.summary
  if (summaryConfig) {
    const summaryResult = summaryConfig
      ? getSummaryCells(responsibility, data, report, [metric])
      : undefined
    if (!summaryResult) throw new Error('No summary result available.')
    const deepestDepth = summaryResult.flatRows.reduce((deepest, row) => {
      return row.depth > deepest ? row.depth : deepest
    }, 0)
    const targetDepth = deepestDepth > 0 ? deepestDepth - 1 : 0
    const targetItems = summaryResult.flatRows.filter(flatRow => flatRow.depth === targetDepth)
    return targetItems.map(targetItem => {
      const matchingDimension = _filterResultsWithFilterDimension(
        targetItem.results,
        filterDimension,
      )[0]
      const metricValue = matchingDimension?.metrics[metric.summarization]?.[metric.metricKey]
      const dimensionLabel = dimension.graph.label(
        {},
        dimensionContexts[dimension.identifier],
        targetItem.item,
      )
      if (!dimensionLabel) {
        throw new Error(
          `Could not find label for dimension '${dimension.identifier}' and id '${targetItem.id}'`,
        )
      }
      return metricValue ? { dimensionLabel, [metric.identifier]: metricValue } : { dimensionLabel }
    })
  } else {
    const otherSummaryConfig = otherDimension?.summary
    const otherSummaryResult = otherSummaryConfig
      ? getSummaryCells(responsibility, data, report, [metric])
      : undefined
    return (
      _filterResultsWithFilterDimension(
        data.results || [],
        filterDimension,
        otherSummaryResult?.flatRows,
      ).map(r => {
        if (!r.dimensions[dimension.inputKey]) {
          throw new Error(
            `Could not find dimension value for dimension '${dimension.identifier}' and data '${r}'`,
          )
        }
        const dimensionLabel = dimension.graph.label(
          r.dimensions[dimension.inputKey],
          dimensionContexts[dimension.identifier],
        )
        if (!dimensionLabel) {
          throw new Error(
            `Could not find label for dimension '${dimension.identifier}' and data '${r}'`,
          )
        }
        const metricValue = r.metrics[metric.summarization]?.[metric.metricKey]
        return metricValue
          ? { dimensionLabel, [metric.identifier]: metricValue }
          : { dimensionLabel }
      }) || []
    )
  }
}

export interface GraphData {
  dimensionLabel: string
  [metricKey: string]: string | number
}
