import { useApolloClient } from '@thesisedu/feature-apollo-react/apollo'
import { useViewerContext } from '@thesisedu/feature-users-web'
import {
  WidgetPluginsProps,
  useElementsMenuItem,
  Legacy,
  $getNodeByKey,
  $parseSerializedNode,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  ElementsGroup,
} from '@thesisedu/feature-widgets-react'
import { Modal } from '@thesisedu/ui'
import { Copy } from '@thesisedu/ui/icons'
import React from 'react'

import { useSaveAsTemplate } from './useSaveAsTemplate'
import { WidgetTemplateSelect } from '../../../manage/WidgetTemplateSelect'
import {
  WidgetTemplateDocument,
  WidgetTemplateQuery,
  WidgetTemplateQueryVariables,
} from '../../../schema'

export function WidgetTemplatesPlugin(props: WidgetPluginsProps) {
  const viewer = useViewerContext(false)
  if (viewer?.group === 'ADMIN') {
    return <_WidgetTemplatesPlugin {...props} />
  } else {
    return null
  }
}

function _WidgetTemplatesPlugin({ editor }: WidgetPluginsProps) {
  const client = useApolloClient()
  const [elementKey, setElementKey] = React.useState<string | null>(null)
  const selectionRef = React.useRef<any>()
  useElementsMenuItem({
    identifier: 'WidgetTemplate',
    icon: <Copy />,
    title: 'Add from Template',
    weight: 44,
    group: ElementsGroup.Other,
    noReplace: true,
    isSelected: () => false,
    onCommit(element) {
      selectionRef.current = $getSelection()?.clone()
      setElementKey(element.getKey())
    },
  })
  const saveModal = useSaveAsTemplate()

  return (
    <>
      {saveModal}
      <Modal
        visible={!!elementKey}
        onVisibleChange={visible => {
          if (!visible) setElementKey(null)
        }}
        title={'Add from Template'}
      >
        <WidgetTemplateSelect
          onSelected={async template => {
            if (!elementKey) return
            const fullTemplateResult = await client.query<
              WidgetTemplateQuery,
              WidgetTemplateQueryVariables
            >({
              query: WidgetTemplateDocument,
              variables: {
                id: template.id,
              },
            })
            const fullTemplate =
              fullTemplateResult?.data?.node?.__typename === 'WidgetTemplate'
                ? fullTemplateResult.data.node
                : undefined
            if (fullTemplate && selectionRef.current) {
              const transformed = (fullTemplate.widgets || []).flatMap(widget =>
                Legacy.convertWidget(widget),
              )

              editor.update(() => {
                const element = $getNodeByKey(elementKey)
                $setSelection(selectionRef.current)
                const selection = $getSelection()
                if (element && $isRangeSelection(selection)) {
                  const nodes = transformed.map(node => $parseSerializedNode(node))
                  selection.insertNodes(nodes)
                }
              })
            }
            setElementKey(null)
          }}
        />
      </Modal>
    </>
  )
}
