import { Form, VSpaced } from '@thesisedu/ui'
import React from 'react'

export function VideoEmbedSettingsModal() {
  return (
    <VSpaced space={'m'}>
      <Form.TextField name={'url'} label={'URL'} placeholder={'https://example.com/'} />
    </VSpaced>
  )
}
