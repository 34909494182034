import { TagType } from '@thesisedu/feature-tags-react'

import { SegmentType } from './schema'

export const COURSE_META_DEFAULTS = {
  icon: null,
  shortDescription: 'Short course description',
  longDescription: 'Long course description',
  color: 'blue',
}

export const NON_GROUP_TYPES: SegmentType[] = [SegmentType.Assignment, SegmentType.Section]

export const SIMPLE_ICONS = [
  'solution',
  'ordered-list',
  'book',
  'link',
  'file-pdf',
  'play-circle',
  'menu-fold',
  'folder',
  'comment',
  'paper-clip',
]

interface LabelIcons {
  [label: string]: string
}
export const LABEL_ICONS: LabelIcons = {
  Reading: 'read',
  Read: 'read',
  Present: 'comment',
  Glossary: 'book',
  Recall: 'edit',
  Quiz: 'form',
  Ethics: 'question-circle',
  Link: 'link',
  Exercise: 'table',
  Review: 'table',
  Activity: 'solution',
  Discussion: 'message',
  Video: 'play-circle',
  Watch: 'play-circle',
  'Individual Activity': 'user',
  'Group Activity': 'usergroup-add',
  Posttest: 'clock-circle',
  'Ethics Case': 'experiment',
  'Sheet Music': 'music-1',
}

export const WEEK_SIZE_DAYS = 5

export const SEGMENT_GROUPS = [
  'Lesson',
  'Guiding Question',
  'LAP',
  'Leading Question',
  'Learning Goal',
  'Additional',
]
export const SECTION_LABELS = ['Reading', 'Discussion', 'Video']

interface LabelVerbs {
  [key: string]: string
}
export const SECTION_LABEL_VERBS: LabelVerbs = {
  Reading: 'Read',
  Discussion: 'Discuss',
  Video: 'Watch',
  'Startup Channel': 'Watch',
  'Welcome Activity': 'Watch',
  'Core Content': 'Watch',
  Activator: 'Complete',
  Activity: 'Complete',
  Assessment: 'Complete',
}

export const INPUT_FIELDS = [
  'type',
  'id',
  'name',
  'index',
  'config',
  'updateCourseVersionId',
  'updateCourseId',
  'updateTags',
  'isHiddenInSearch',
]
export const LABEL_PLACEHOLDER = 'Quiz, Reading, etc.'

export const TAG_TYPES: TagType[] = [
  { type: 'COURSE', label: 'Course' },
  { type: 'TOPIC', label: 'Topic' },
  { type: 'IMPLEMENTATION_GUIDE', label: 'Lesson' },
  { type: 'CONTENT_AREA', label: 'Content Area' },
  { type: 'STANDARD', label: 'Standard' },
  { type: 'CONCEPT_OBJECTIVE', label: 'Concept Objective' },
]
