import { DecoratorNode, SerializedLexicalNode, Spread } from 'lexical'
import React from 'react'

import { updateWithEditorPlaceholder } from '../utils/updateWithEditorPlaceholder'

export type SerializedClearFloatNodeV1 = Spread<
  {
    type: 'ClearFloat'
    version: 1
  },
  SerializedLexicalNode
>

export class ClearFloatNode extends DecoratorNode<JSX.Element> {
  static getType() {
    return 'ClearFloat'
  }

  static clone(node: ClearFloatNode): ClearFloatNode {
    return new ClearFloatNode(node.__key)
  }

  static importJSON(serialized: SerializedClearFloatNodeV1): ClearFloatNode {
    return $createClearFloatNode()
  }

  exportJSON(): SerializedClearFloatNodeV1 {
    return {
      type: 'ClearFloat',
      version: 1,
    }
  }

  createDOM(): HTMLElement {
    const div = document.createElement('div')
    div.classList.add('fteditor-clear-float')
    div.style.clear = 'both'
    updateWithEditorPlaceholder(div)
    return div
  }

  updateDOM(): false {
    return false
  }

  decorate() {
    return <p>Clearing Floats</p>
  }
}

export function $createClearFloatNode() {
  return new ClearFloatNode()
}

export function $isClearFloatNode(node: any): node is ClearFloatNode {
  return node instanceof ClearFloatNode
}
