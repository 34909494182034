import { SelectButton, SelectButtonProps } from '@thesisedu/feature-apollo-react'
import React from 'react'

import { TagFieldChild } from './TagFieldChild'
import { TagFragment, UserTagsDocument, UserTagsQuery, UserTagsQueryVariables } from './schema'

export interface TagFieldProps
  extends Omit<
    Partial<SelectButtonProps<TagFragment, UserTagsQuery, UserTagsQueryVariables>>,
    'value' | 'onChange'
  > {
  value?: string[]
  onChange?: (tags: string[]) => any
}
export const TagField = React.forwardRef<any, TagFieldProps>((props, ref) => {
  const [addedNames, setAddedNames] = React.useState<string[]>([])
  return (
    <SelectButton<TagFragment, UserTagsQuery, UserTagsQueryVariables>
      filterVariableName={'name'}
      getItemName={item => item.name}
      resultPath={'viewer.tags'}
      document={UserTagsDocument}
      selectLabel={'Add Tags'}
      value={props.value}
      onChange={props.onChange}
      getItemValue={item => item.name}
      onAddFromSearch={async filter => {
        // We don't actually need to add the tag here; we usually handle that on
        // the server. So we can just call `onChange` with the new tag name instead.
        if (props.onChange) {
          props.onChange([...(props.value || []), filter])
          setAddedNames(a => [...a, filter])
        }
      }}
      extraItems={addedNames.map(name => ({
        id: name,
        createdAt: new Date(),
        name,
        updatedAt: new Date(),
      }))}
      children={TagFieldChild}
    />
  )
})
