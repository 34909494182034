import { Tooltip, Text, Button, Dropdown, Button$, useToast } from '@thesisedu/ui'
import { CloudSync, NavArrowDown, WarningCircle } from '@thesisedu/ui/icons'
import React from 'react'

import { SetupModal } from '../setup/SetupModal'
import { SyncAssignmentActionsProps } from '../types'
import { useSyncAssignmentActions } from '../useSyncAssignmentActions'

export function useSyncAssignmentMenuItems({
  syncs,
  onSync,
  assignedClassIds,
  classIds,
  disabled,
}: SyncAssignmentActionsProps) {
  const toast = useToast()
  const { onItemSelected, items, loadingId } = useSyncAssignmentActions({
    syncs,
    onSync,
    assignedClassIds,
    classIds,
    onError: (msg, prominent) => {
      if (prominent) {
        const update = toast({
          status: 'error',
          title: 'Cannot sync content.',
          description: msg,
          action: <Button size={'small'} children={'OK'} onPress={() => update()} />,
          timeout: null,
        })
      } else {
        toast({
          status: 'error',
          title: msg,
        })
      }
    },
    onSuccess(msg) {
      toast({
        status: 'success',
        title: msg,
      })
    },
  })

  return items.map(item => (
    <Dropdown.Item.Checkbox
      key={item.syncId}
      onClick={() => {
        onItemSelected(item.syncId)
      }}
      checked={item.isSynced}
      loading={loadingId === item.syncId}
      disabled={item.isSynced || item.needsStudents || disabled}
    >
      {item.needsStudents ? (
        <>
          Assign students in <strong>{item.classNameOnProvider}</strong> before syncing.
        </>
      ) : (
        <>
          {item.isSynced ? 'Synced' : 'Sync'} with&nbsp;
          <strong>
            {item.providerName} ({item.classNameOnProvider})
          </strong>
        </>
      )}
    </Dropdown.Item.Checkbox>
  ))
}

export function SyncAssignmentActions({
  syncs,
  onSync,
  assignedClassIds,
  classIds,
}: SyncAssignmentActionsProps) {
  const items = useSyncAssignmentMenuItems({ onSync, syncs, assignedClassIds, classIds })
  return (
    <Dropdown.SubMenu>
      <Dropdown.SubMenu.Trigger children={'Sync with...'} icon={<CloudSync />} />
      <Dropdown.SubMenu.Content>
        {items.length ? (
          items
        ) : (
          <Dropdown.Item disabled icon={<WarningCircle />} style={{ lineHeight: 1.4 }}>
            You don't have any LMS connections yet.
          </Dropdown.Item>
        )}
      </Dropdown.SubMenu.Content>
    </Dropdown.SubMenu>
  )
}

export function SyncAssignmentButton({
  buttonProps,
  ...actionProps
}: SyncAssignmentActionsProps & { buttonProps?: Partial<Button$.ButtonProps> }) {
  const items = useSyncAssignmentMenuItems(actionProps)
  const [setupVisible, setSetupVisible] = React.useState(false)

  const button = (
    <Button
      disabled={!items.length || actionProps.disabled}
      children={'Sync Assignment'}
      rightDecorator={<NavArrowDown />}
      {...buttonProps}
    />
  )
  return (
    <>
      <SetupModal visible={setupVisible} onVisibleChange={setSetupVisible} />
      <Dropdown.Container>
        <Dropdown.ManualTrigger>
          {items.length > 0 ? (
            button
          ) : (
            <Tooltip
              title={
                <>
                  <span>You have not attached any classes from a third-party LMS.</span>
                  <br />
                  <a
                    href={'#'}
                    onClick={e => {
                      e.preventDefault()
                      setSetupVisible(true)
                    }}
                  >
                    Click here to attach one.
                  </a>
                </>
              }
            >
              {button}
            </Tooltip>
          )}
        </Dropdown.ManualTrigger>
        <Dropdown.Menu>{items}</Dropdown.Menu>
      </Dropdown.Container>
    </>
  )
}

export interface SyncAssignmentSummaryProps {
  syncs: SyncAssignmentActionsProps['syncs']
  assignedClassIds: string[]
}
export function SyncAssignmentSummary({ syncs, assignedClassIds }: SyncAssignmentSummaryProps) {
  const { items } = useSyncAssignmentActions({
    syncs,
    onSync: () => Promise.resolve(),
    assignedClassIds,
    onError: () => {},
    onSuccess: () => {},
  })
  const filteredItems = items
    .filter(item => item.isSynced)
    .map(item => `${item.providerName} (${item.classNameOnProvider})`)
  if (filteredItems.length) {
    return (
      <Text>
        Synced with <strong>{filteredItems.join(', ')}</strong>
      </Text>
    )
  } else {
    return <Text>Not synced yet.</Text>
  }
}
