import { Body, Block, BodyLarge, FontWeight, HSpaced, styled } from '@thesisedu/web'
import Radio from 'antd/es/radio'
import React from 'react'

import { useBadgeEventTypes } from '../useBadgeEventTypes'

export interface EventTypeSelectProps {
  value?: string
  onChange?: (value?: string) => void
}
export function EventTypeSelect({ value, onChange }: EventTypeSelectProps) {
  const types = useBadgeEventTypes()
  const selectedType = types.find(type => type.identifier === value)
  if (selectedType) {
    const type = selectedType
    return (
      <Label isSelected={value === type.identifier}>
        <HSpaced align={'flex-start'}>
          <Radio value={type.identifier} checked />
          <div>
            <BodyLarge weight={FontWeight.SemiBold}>
              {type.icon} {type.label}
            </BodyLarge>
            <Body children={type.description} />
            <Block marginTop={'@size-s'}>
              <a
                href={'#'}
                onClick={() => {
                  if (onChange) {
                    onChange(undefined)
                  }
                }}
              >
                Select another &rarr;
              </a>
            </Block>
          </div>
        </HSpaced>
      </Label>
    )
  } else {
    return (
      <Radio.Group
        onChange={e => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        value={value}
      >
        {types.map(type => (
          <Label key={type.identifier} isSelected={value === type.identifier}>
            <HSpaced align={'flex-start'}>
              <Radio value={type.identifier} />
              <div>
                <BodyLarge weight={FontWeight.SemiBold}>
                  {type.icon} {type.label}
                </BodyLarge>
                <Body children={type.description} />
              </div>
            </HSpaced>
          </Label>
        ))}
      </Radio.Group>
    )
  }
}

const Label = styled.label<{ isSelected?: boolean }>`
  transition:
    background 0.1s linear,
    color 0.1s linear;
  cursor: pointer;
  display: block;
  border-radius: ${props => props.theme['@border-radius-base']};
  padding: ${props => props.theme['@padding-sm']};
  color: ${props => props.theme['@gray-5']};
  &:hover,
  ${props => (props.isSelected ? '&' : '.noop')} {
    background: ${props => props.theme['@gray-1']};
    color: ${props => props.theme['@gray-7']};
  }
`
