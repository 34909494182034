import { orderBy } from 'lodash'

import { useRecordFooterContext } from './RecordFooterContext'

export function useRecordFooterItems() {
  const { items } = useRecordFooterContext(true)
  return orderBy(items, 'weight', 'asc')
}

export function usePrepareForRecordFooterItems() {
  const { items } = useRecordFooterContext(true)
  return orderBy(
    items.filter(item => item.showBeforeRecording),
    'weight',
    'asc',
  )
}
