import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $createParagraphNode,
  $getNodeByKey,
  $isParagraphNode,
  INSERT_PARAGRAPH_COMMAND,
  NodeKey,
  COMMAND_PRIORITY_HIGH,
} from 'lexical'
import React from 'react'

import { $getSelectedBlockElement } from '../../utils/getSelectedElement'

export function useForwardFocusToParent(enable: boolean, hostNodeKey: NodeKey) {
  const [editor] = useLexicalComposerContext()

  React.useEffect(() => {
    const parentEditor = editor._parentEditor
    if (parentEditor) {
      return editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        () => {
          const selectedBlock = $getSelectedBlockElement()
          const isEmpty =
            selectedBlock &&
            $isParagraphNode(selectedBlock) &&
            selectedBlock.getParents().length === 1 &&
            !selectedBlock.getTextContent().trim()
          if (isEmpty) {
            selectedBlock.remove()
          }
          if (enable || isEmpty) {
            setTimeout(() => {
              parentEditor.focus()
              parentEditor.update(() => {
                const node = $getNodeByKey(hostNodeKey)
                if (node) {
                  const paragraphNode = $createParagraphNode()
                  node.insertAfter(paragraphNode)
                  paragraphNode.select()
                }
              })
            })
            return true
          } else {
            return false
          }
        },
        COMMAND_PRIORITY_HIGH,
      )
    }
  }, [editor, enable])
}
