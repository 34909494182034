import { DeleteOutlined } from '@ant-design/icons'
import {
  getFileInfo,
  UploadButton,
  UploadButtonProps,
  useFileField,
} from '@thesisedu/feature-attachments-react'
import { EmbeddedFile, ExtensibleText } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { ExtensibleInput } from '@thesisedu/feature-web'
import { HSpaced, styled } from '@thesisedu/web'
import { Button, Tooltip } from 'antd'
import { InputProps, TextAreaProps } from 'antd/lib/input'
import React from 'react'

import { ImageWithAudioEdit, ImageWithAudioPayload } from './MediaField/ImageWithAudioEdit'
import { ImageWithAudioView } from './MediaField/ImageWithAudioView'

type MediaFieldPayload = ImageWithAudioPayload

function isObjectPayload(value?: string) {
  if (!value) return false
  try {
    const parsed = JSON.parse(value) as MediaFieldPayload
    return !!parsed.type
  } catch {
    return false
  }
}

export interface QuestionTextMediaFieldProps {
  value?: string
  onChange?: (value: string | undefined) => void
  inputProps?: Partial<InputProps>
  uploadButtonProps?: Partial<UploadButtonProps>
  textAreaProps?: Partial<TextAreaProps>
  style?: any
}
export function QuestionTextMediaField({
  value,
  onChange,
  inputProps,
  textAreaProps,
  uploadButtonProps,
  style,
}: QuestionTextMediaFieldProps) {
  const viewer = useViewerContext(false)
  const { filename } = getFileInfo(value || '')
  const fileValue = filename?.startsWith('http') ? filename : undefined
  const fileFieldProps = useFileField({
    onChange: newValue => {
      const isImage =
        newValue?.endsWith('.png') ||
        newValue?.endsWith('.jpg') ||
        newValue?.endsWith('.svg') ||
        newValue?.endsWith('.jpeg')
      if (viewer?.group === 'ADMIN' && onChange && isImage) {
        onChange(
          JSON.stringify({ type: 'imageWithAudio', imagePath: newValue } as ImageWithAudioPayload),
        )
      } else if (onChange) {
        onChange(newValue)
      }
    },
  })
  if (isObjectPayload(value)) {
    const valuePayload = JSON.parse(value!) as MediaFieldPayload
    if (valuePayload.type === 'imageWithAudio') {
      return (
        <ImageWithAudioEdit
          value={valuePayload}
          onChange={v => {
            if (onChange) {
              onChange(v ? JSON.stringify(v) : undefined)
            }
          }}
          style={style}
        />
      )
    } else {
      return null
    }
  } else if (fileValue) {
    return (
      <HSpaced style={style}>
        <QuestionTextMediaView value={fileValue} />
        <Tooltip title={'Remove media'} mouseEnterDelay={1}>
          <Button
            icon={<DeleteOutlined />}
            danger
            type={'link'}
            onClick={() => {
              if (onChange) {
                onChange(undefined)
              }
            }}
          />
        </Tooltip>
      </HSpaced>
    )
  } else {
    return (
      <HSpaced style={style}>
        <ExtensibleInput
          {...(inputProps as any)}
          {...(textAreaProps as any)}
          singleLine={!!inputProps}
          value={value}
          onChange={e => {
            if (onChange) {
              onChange(e)
            }
          }}
        />
        <UploadButton
          buttonProps={{ children: '', ...uploadButtonProps?.buttonProps }}
          {...uploadButtonProps}
          isPublic
          {...fileFieldProps}
        />
      </HSpaced>
    )
  }
}

export interface QuestionTextMediaViewProps {
  value: string
}
export function QuestionTextMediaView({ value }: QuestionTextMediaViewProps) {
  const { filename } = getFileInfo(value)
  if (isObjectPayload(value)) {
    const valuePayload = JSON.parse(value) as MediaFieldPayload
    if (valuePayload.type === 'imageWithAudio') {
      return <ImageWithAudioView value={valuePayload} />
    } else {
      return null
    }
  } else if (filename.startsWith('http')) {
    return (
      <Wrapper className={'question-text-media-wrapper'}>
        <EmbeddedFile path={filename} />
      </Wrapper>
    )
  } else {
    return <ExtensibleText value={value} />
  }
}

const MAX_DIMENSION = 100
export interface WrapperProps {
  disableSizeCheck?: boolean
  [key: string]: any
}
export function Wrapper({
  children,
  disableSizeCheck,
  ...rest
}: React.PropsWithChildren<WrapperProps>) {
  const wrapperRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    const listener = (e: Event) => {
      const target = e.target as HTMLImageElement | null
      if (target) {
        let { naturalWidth: width, naturalHeight: height } = target
        if (width === 0 || height === 0) {
          width = target.clientWidth
          height = target.clientHeight
        }
        if (width > height) {
          target.style.width = 'auto'
          target.style.height = `${MAX_DIMENSION}px`
        } else {
          target.style.height = 'auto'
          target.style.width = `${MAX_DIMENSION}px`
        }
      }
    }
    const images = wrapperRef.current?.querySelectorAll<HTMLImageElement>(':scope > img')
    if (images) {
      if (disableSizeCheck) {
        images.forEach(image => {
          image.style.height = 'auto'
          image.style.width = '100%'
        })
      } else {
        images.forEach(image => {
          image.addEventListener('load', listener)
        })
        return () => {
          images.forEach(image => {
            image.removeEventListener('load', listener)
          })
        }
      }
    }
  }, [disableSizeCheck])
  return <WrapperInner {...rest} children={children} ref={wrapperRef} />
}

const WrapperInner = styled.div`
  > img {
    width: auto;
    height: auto;
    max-width: 90%;
    max-height: 500px;
  }
`
