import {
  SegmentMetadataSegment,
  SegmentStructureOverride,
  walkSegments,
} from '@thesisedu/feature-course-types'
import { cloneDeep } from 'lodash'

import { between } from './generate'
import { getValidPlacementWithOffset } from './getValidPlacement'
import { getRealItem } from './items/DraggableOutlineItem'
import { OutlineItem, isItemWithSegment } from './types'
import { getSegmentParents } from '../helpers'
import { debug } from '../log'
import { CreatedSegment } from '../placement/SplitViewPendingChangesContext'
import { Segment } from '../types'

export interface GetDragEndPlacementResult {
  structureOverrides: SegmentStructureOverride[]
  metadataOverrides: SegmentMetadataSegment[]
}
export interface GetDragEndPlacementOpts {
  /**
   * This should NOT include the new segment we are dragging from somewhere
   * else, if it is foreign.
   */
  selectedTerm?: Segment
  offsetLeft: number | null
  /**
   * This should include the new segment we are dragging from somewhere else,
   * if it is foreign.
   */
  items: OutlineItem[]
  movingId: string
  movingSegment?: Segment
  overId: string
}
export function getDragEndPlacement({
  movingId,
  movingSegment,
  overId,
  selectedTerm,
  offsetLeft,
  items,
}: GetDragEndPlacementOpts): GetDragEndPlacementResult | null {
  if (!selectedTerm) return null
  debug('dragEnd; offsetLeft %d', offsetLeft)
  const fromIndex = items.findIndex(i => i.id === movingId)
  const toIndex = items.findIndex(i => i.id === overId)
  const realItem = getRealItem(items, fromIndex, toIndex)
  const itemsWithoutDragging = items.filter(item => item.id !== movingId)
  const placement = realItem
    ? getValidPlacementWithOffset({
        // We have to exclude the current item from this list because otherwise we
        // end up with a false minDepth, since the foreign segment is often added
        // to the bottom of the list, with a depth. Makes the system think you are
        // placing content in the middle of a lesson when actually you're at the
        // end of the course. Same logic inside DraggableOutlineItem.
        outlineItems: itemsWithoutDragging,
        draggingItemId: movingId.toString(),
        draggingSegment: movingSegment,
        overItemId: realItem.id,
        offsetLeft,
        unsortedTerm: selectedTerm,
      })
    : {
        parentSegment: selectedTerm!,
        depth: 0,
      }
  if (placement) {
    debug('moving segment from drag drop...')
    debug('placement %O', placement)
    const currentItem = items.find(item => item.id === movingId)
    if (!currentItem) throw new Error(`Cannot find current dragging item: '${movingId}'`)

    const currentItemParents = getSegmentParents([selectedTerm], movingId.toString())
    const toIndexIsParent = currentItemParents?.includes(overId.toString())
    debug('toIndexIsParent %O', toIndexIsParent)
    let overItem = getRealItem(items, fromIndex, toIndex)
    debug('overItem %O', overItem)

    // If the overItem depth is greater than the target depth, go up the tree until
    // we find one that matches the target depth.
    const depthDifference = overItem ? overItem.depth - placement.depth : 0
    if (depthDifference > 0 && overItem) {
      debug(
        'overItem %O is too deep and depthDifference is %d; finding overItem',
        overItem,
        depthDifference,
      )
      const newOverItemId = overItem.parentIds[overItem.parentIds.length - depthDifference]
      overItem = items.find(i => i.id === newOverItemId)
    }

    const currentParent = currentItem.place.trueParentSegment || currentItem.place.parentSegment
    const newParent = placement.parentSegment
    const didParentChange = currentParent.id !== newParent.id
    debug(
      'previous parent: %s (%s) / new parent: %s (%s)',
      currentParent.name,
      currentParent.id,
      newParent.name,
      newParent.id,
    )
    debug(didParentChange ? 'parent did change' : 'parent did NOT change')

    // Calculate the weight. Clone this because we might modify it later.
    const parentChildren = cloneDeep(newParent.childSegments || [])
    debug('parentChildren %O', parentChildren)

    // Make sure there are no duplicate weights.
    // FIXME: We should probably validate this on the server? The question is do we auto-correct
    // for this on the server, or do we try to fix the original data and then make sure it
    // doesn't happen again?
    const overrideWeights: { id: string; weight: number }[] = []
    const parentWeights = new Set<number>()
    let hasDuplicateWeights = false
    for (const child of parentChildren) {
      if (child.weight !== undefined && child.weight !== null) {
        if (parentWeights.has(child.weight)) {
          hasDuplicateWeights = true
          break
        }
        parentWeights.add(child.weight)
      }
    }

    const overrideAllChildren = () => {
      for (let i = 0; i < parentChildren.length; i++) {
        if (parentChildren[i].id !== movingId) {
          // We'll add the override for the moving ID below.
          overrideWeights.push({ id: parentChildren[i].id, weight: i })
        }
        parentChildren[i].weight = i
      }
    }

    if (hasDuplicateWeights) {
      debug('duplicate weights found; overriding all children')
      overrideAllChildren()
    }

    const afterParentIndex = overItem
      ? parentChildren.findIndex(child => child.id === overItem!.id)
      : -1
    debug(
      'before weight %d; after weight %d',
      parentChildren[afterParentIndex]?.weight,
      parentChildren[afterParentIndex + 1]?.weight,
    )

    if (
      parentChildren[afterParentIndex]?.weight == null &&
      parentChildren[afterParentIndex + 1]?.weight == null
    ) {
      debug('neither before weight or after weight are set; overriding all children')
      overrideAllChildren()
    }

    const finalWeight = between(
      parentChildren[afterParentIndex]?.weight,
      parentChildren[afterParentIndex + 1]?.weight,
    )
    debug('new weight %d', finalWeight)
    overrideWeights.push({ id: movingId.toString(), weight: finalWeight })

    const structureOverrides: SegmentStructureOverride[] = []
    if (didParentChange) {
      debug('adding structure override because the parent changed')
      structureOverrides.push({ id: movingId.toString(), parentId: newParent.id })

      // Re-add the children to the parent, and their children.
      if (isItemWithSegment(currentItem) && (currentItem as Partial<CreatedSegment>).isCreated) {
        debug('adding children because this is a newly-created group / topic we just moved')
        walkSegments(currentItem.segment.childSegments ?? [], (segment, depth, parents) => {
          const lastParent = parents[parents.length - 1]
          debug('.. %s -> child of %s', segment.id, lastParent.id)
          structureOverrides.push({ id: segment.id, parentId: lastParent.id })
        })
      }
    }

    return {
      structureOverrides,
      metadataOverrides: overrideWeights,
    }
  }

  return null
}
