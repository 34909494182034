import { LoadingIndicator } from '@thesisedu/ui'
import React from 'react'

import { Container, IconContainer } from './styled'

export function StreamsLoading() {
  return (
    <Container>
      <IconContainer>
        <LoadingIndicator />
      </IconContainer>
    </Container>
  )
}
