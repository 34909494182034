import ArrowRightOutlined from '@ant-design/icons/lib/icons/ArrowRightOutlined'
import DeleteOutlined from '@ant-design/icons/lib/icons/DeleteOutlined'
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined'
import { GenerateLinkMenuItem } from '@thesisedu/feature-classes-react'
import { message, Modal } from 'antd'
import Menu, { MenuProps } from 'antd/es/menu'
import React, { useState } from 'react'

import { getPracticeLabel } from './getPracticeLabel'
import { useDeletePracticeMutation } from './queries/useDeletePracticeMutation'
import { PracticeFragment } from './schema'
import { MovePracticeModal } from './student/MovePracticeModal'

export interface PracticeActionsProps extends MenuProps {
  practice: PracticeFragment
  onDeleted?: () => any
  onRefetch?: () => void
}
export const PracticeActions: React.FC<React.PropsWithChildren<PracticeActionsProps>> = ({
  practice,
  onRefetch,
  onDeleted,
  ...props
}) => {
  const [moveVisible, setMoveVisible] = useState(false)
  const [deletePractice] = useDeletePracticeMutation({
    onCompleted: () => {
      message.success('Practice deleted successfully!')
      if (onDeleted) {
        onDeleted()
      }
    },
  })
  return (
    <>
      <MovePracticeModal
        visible={moveVisible}
        practiceId={practice.id}
        onCancel={() => setMoveVisible(false)}
      />
      <Menu
        {...props}
        onClick={({ key }) => {
          if (key === 'download') {
            if (practice.media.path) {
              window.open(practice.media.path, '_blank')
            }
          } else if (key === 'delete') {
            Modal.confirm({
              title: 'Are you sure?',
              type: 'warning',
              icon: <ExclamationCircleOutlined />,
              content: (
                <span>
                  You are about to delete the <strong>{getPracticeLabel(practice)}</strong>{' '}
                  practice. Are you sure you want to do this?
                </span>
              ),
              okText: 'Yes, delete.',
              okType: 'danger',
              cancelText: 'No, keep.',
              onOk: () => {
                return deletePractice({
                  variables: {
                    id: practice.id,
                  },
                }).then(() => {
                  if (onRefetch) {
                    onRefetch()
                  }
                })
              },
            })
          } else if (key === 'move') {
            setMoveVisible(true)
          }
        }}
      >
        <Menu.Item key={'delete'}>
          <DeleteOutlined />
          <span>Delete Practice</span>
        </Menu.Item>
        {practice.media.path ? (
          <Menu.Item key={'download'}>
            <DownloadOutlined />
            <span>Download</span>
          </Menu.Item>
        ) : null}
        <GenerateLinkMenuItem
          universalPath={`practices/${practice.id}`}
          classId={practice.class?.id}
          promptClassId={!practice.class}
          requireClassId={!practice.class}
        />
        <Menu.Item key={'move'}>
          <ArrowRightOutlined />
          <span>Move</span>
        </Menu.Item>
      </Menu>
    </>
  )
}
