import { useAssignmentSubmissionQuery } from '../schema'

export function usePastAttempt(submissionId: string | undefined) {
  const { data, loading } = useAssignmentSubmissionQuery({
    variables: {
      id: submissionId || '',
      withConfiguration: false,
      includeDeleted: true,
    },
    skip: !submissionId,
  })
  const loadedSubmission = data?.node?.__typename === 'AssignmentSubmission' ? data.node : undefined

  return { loading, submission: loadedSubmission }
}
