import { addExpectedErrorHandler } from '@thesisedu/feature-apollo-react'
import { Modal } from 'antd'
import React from 'react'

import { ClassSyncReactFeature } from '../ClassSyncReactFeature'

export default function (feature: ClassSyncReactFeature) {
  addExpectedErrorHandler(feature, (payload, context) => {
    if (context.code === 'INVALID_CLASS_SYNC_ERROR') {
      Modal.warn({
        title: 'Class Sync is Invalid',
        width: 600,
        style: { maxWidth: '90vw' },
        content: (
          <>
            <p>
              <strong>It looks like your synced class is no longer valid.</strong>
            </p>
            <p>
              Sometimes, this is because the class has been deleted or archived inside your LMS.
            </p>
            <p>
              To fix the issue, go to the <strong>Students</strong> page inside your class, delete
              the existing class sync, re-create it, and then try this action again.
            </p>
          </>
        ),
      })
    }

    return payload
  })
}
