import { onMutationError } from '@thesisedu/feature-apollo-react'
import { Modal } from 'antd'

import { useOptions } from '../options'
import { useJoinClassMutation as useMutation } from '../schema'

export const useJoinClassMutation: typeof useMutation = opts => {
  const options = useOptions()
  return useMutation({
    onError: err => {
      if (err.graphQLErrors[0]?.extensions?.code === 'CLASS_NOT_FOUND') {
        Modal.error({
          title: `${options.classNameCapitalized} not found`,
          content: `A ${options.className} with that code does not exist.`,
        })
      } else {
        return onMutationError(`There was an error joining that ${options.className}`)(err)
      }
    },
    refetchQueries: ['minimalStudentClasses'],
    awaitRefetchQueries: true,
    ...opts,
  })
}
