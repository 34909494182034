import { UpdateSiteRefetchQueriesHook } from '@thesisedu/feature-sites-web'

import LicensesReactFeature from '../LicensesReactFeature'

export default function (feature: LicensesReactFeature) {
  feature.hookManager.registerMutateHook<UpdateSiteRefetchQueriesHook>(
    'feature-sites-web:update-site-refetch-queries',
    queries => {
      return [...queries, 'siteLicense']
    },
  )
}
