import { SolidIcon, Text } from '@thesisedu/ui'
import { Check, NavArrowRight } from '@thesisedu/ui/icons'
import React from 'react'

import { ListItem, SelectedStandardSet } from './StandardSelect'

export interface StandardSetListItemProps {
  standardSet: SelectedStandardSet
  selected?: boolean
  right?: React.ReactElement | null
  onClick?: () => void
}
export function StandardSetListItem({
  standardSet,
  selected,
  right,
  onClick,
}: StandardSetListItemProps) {
  return (
    <ListItem
      onClick={onClick}
      justify={'space-between'}
      data-testid={`StandardSetListItem ${standardSet.name}`}
    >
      <Text level={'s'} style={{ minWidth: 0 }}>
        {standardSet.name}
      </Text>
      {selected ? (
        <SolidIcon
          icon={<Check />}
          background={'green'}
          level={'s'}
          left
          style={{ flexShrink: 0 }}
        />
      ) : right === undefined ? (
        <Text level={'s'} color={'secondary'} left style={{ flexShrink: 0 }}>
          <NavArrowRight />
        </Text>
      ) : (
        right
      )}
    </ListItem>
  )
}
