import { pick } from 'lodash'
import moment from 'moment'

import { warn } from '../log'

const _key = (
  studentId: string,
  classId: string,
  assignmentId: string,
  submissionId = 'no-submission',
) => `feature-assignments-${studentId}-${classId}-${assignmentId}-${submissionId}`

export const saveAnswers = (
  studentId: string,
  classId: string,
  assignmentId: string,
  submissionId: string | undefined,
  submissionData: Record<string, any>,
) => {
  try {
    localStorage.setItem(
      _key(studentId, classId, assignmentId, submissionId),
      JSON.stringify({
        DATE: moment.utc().format(),
        ...submissionData,
      }),
    )
  } catch (err: any) {
    warn('error saving assignment answers to LocalStorage')
    warn(err)
  }
}

export const clearAnswers = (
  studentId: string,
  classId: string,
  assignmentId: string,
  submissionId: string | undefined,
) => {
  try {
    localStorage.removeItem(_key(studentId, classId, assignmentId, submissionId))
    localStorage.removeItem(_key(studentId, classId, assignmentId, undefined))
  } catch (err: any) {
    warn('error clearing assignment answers from LocalStorage')
    warn(err)
  }
}

export function hasAnswers(
  studentId: string,
  classId: string,
  assignmentId: string,
  submissionId: string | undefined,
) {
  return !!localStorage.getItem(_key(studentId, classId, assignmentId, submissionId))
}

export const mergeAnswers = (
  studentId: string,
  classId: string,
  assignmentId: string,
  submissionId: string | undefined,
  submissionData: Record<string, any>,
  submissionUpdated?: string,
) => {
  try {
    const { DATE, ...fromLocalStorage } = JSON.parse(
      localStorage.getItem(_key(studentId, classId, assignmentId, submissionId)) || '{}',
    )
    const goodKeys = Object.keys(submissionData).filter(key => submissionData[key])
    const localStorageDate = DATE ? moment(DATE) : undefined
    // Prefer the local storage data if it was saved _after_ the latest copy from the server.
    if (
      submissionUpdated &&
      localStorageDate?.isValid() &&
      localStorageDate.isAfter(submissionUpdated)
    ) {
      return {
        ...pick(submissionData, goodKeys),
        ...fromLocalStorage,
      }
    } else {
      return {
        ...fromLocalStorage,
        ...pick(submissionData, goodKeys),
      }
    }
  } catch (err: any) {
    warn('error getting assignment answers from LocalStorage')
    warn(err)
    return submissionData
  }
}
