import { message } from 'antd'

export function withLoadingMessage<T extends any[], U extends Promise<any>>(
  loadingMessage: string,
  callback: (...args: T) => U,
) {
  // @ts-ignore its complaining because U is not a promise, but it is...
  return async (...args: T): U => {
    const hideLoading = message.loading(loadingMessage)
    try {
      const result = await callback(...args)
      hideLoading()
      return result
    } catch (err: any) {
      hideLoading()
      throw err
    }
  }
}
