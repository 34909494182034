import { IFeatureHookManager } from '@thesisedu/feature'
import { FragmentDefinitionNode } from 'graphql'

import { getMutateFragmentHook } from './constants'
import { MutateFragmentContext, MutateFragmentPayload } from './types'

export const modifyFragments = (
  fragments: FragmentDefinitionNode[],
  hookManager: IFeatureHookManager,
): FragmentDefinitionNode[] => {
  const result: FragmentDefinitionNode[] = []
  let workingFragments = fragments
  while (workingFragments.length > 0) {
    const additionalDefinitions: FragmentDefinitionNode[] = []
    for (const fragment of workingFragments) {
      result.push(
        hookManager.mutateHookSync<MutateFragmentPayload, MutateFragmentContext>(
          getMutateFragmentHook(fragment.name.value),
          fragment,
          { additionalDefinitions },
        ),
      )
    }
    workingFragments = additionalDefinitions
  }

  return result
}
