import { useFeatureRoot } from '@thesisedu/feature-apollo-react/dist/feature'
import { useSelectedClass, useTeacherSelectedClass } from '@thesisedu/feature-classes-react'
import { useStatePropFallback } from '@thesisedu/feature-react'
import {
  Form,
  Button,
  useToast,
  Modal,
  Dropdown$,
  Text,
  Button$,
  Dropdown,
  VSpaced,
} from '@thesisedu/ui'
import { Clock } from '@thesisedu/ui/icons'
import moment from 'moment'
import React from 'react'

import { useUpdateClassMutation } from '../classes/useUpdateClassMutation'
import { useCourseContext } from '../contexts/CourseContext'
import { overrideSegment } from '../helpers'
import {
  ClassFragmentWithPermissions,
  CoursesHooks,
  SegmentEnableRefetchQueriesContext,
  SegmentEnableRefetchQueriesPayload,
} from '../types'

export function useSegmentScheduledAt(segmentId: string) {
  const { segmentMetadata } = useCourseContext(true)
  return segmentMetadata?.segments?.find(s => s.id === segmentId)?.scheduledAt
}

export interface ScheduleSegmentModalProps {
  segmentId: string
  visible?: boolean
  onVisibleChange?: (visible: boolean) => void
  trigger?: React.ReactElement
}
export function ScheduleSegmentModal({
  trigger,
  segmentId,
  visible: _visible,
  onVisibleChange: _onVisibleChange,
}: ScheduleSegmentModalProps) {
  const [visible, setVisible] = useStatePropFallback(_visible, _onVisibleChange, false)
  const cls = useSelectedClass(true)
  const { segmentMetadata } = useCourseContext(true)
  const form = Form.useForm()
  const root = useFeatureRoot()!
  const scheduledAt = useSegmentScheduledAt(segmentId)
  const enabled = segmentMetadata?.segments?.find(s => s.id === segmentId)?.visibleOverride
  const toast = useToast()
  const [updateClass, { loading }] = useUpdateClassMutation(cls.id, {
    onCompleted: () => {
      toast({ title: 'Segment schedule saved!', status: 'success' })
      setVisible(false)
    },
    onError: () => {
      setVisible(false)
    },
    refetchQueries: root.deps.hookManager.mutateHookSync<
      SegmentEnableRefetchQueriesPayload,
      SegmentEnableRefetchQueriesContext
    >(CoursesHooks.SegmentEnableRefetchQueries, [], {
      segmentId,
      classId: cls.id,
      enabled: true,
    }),
  })
  return (
    <Form.Modal
      title={'Schedule Segment'}
      visible={visible}
      onVisibleChange={setVisible}
      trigger={trigger}
      form={form}
      onFinish={values => {
        const dateMoment = moment(values.date)
        const formattedDate = dateMoment.format()
        const offsetInMinutes = dateMoment.utcOffset()

        updateClass({
          segmentMetadata: overrideSegment(
            segmentId,
            {
              scheduledAt: formattedDate,
              visibleOverride: false,
              scheduledAtOffset: offsetInMinutes,
            },
            segmentMetadata,
          ),
        })
      }}
    >
      <VSpaced space={'m'}>
        <Form.DatePickerField
          name={'date'}
          granularity={'minute'}
          validate={(value: any) => {
            if (!value) return true
            if (!moment(value).isValid()) {
              return 'Must be a valid date.'
            } else if (moment(value).subtract(20, 'minutes').isBefore(moment())) {
              return 'Must be a valid date at least 20 minutes in the future.'
            } else return true
          }}
          label={'Enable content for all students at'}
          initialValue={scheduledAt ? moment(scheduledAt).toDate() : undefined}
          isDateUnavailable={date => {
            return moment(date.toString()).endOf('day').isBefore(moment())
          }}
          disabled={loading}
        />
        {enabled ? (
          <Text color={'orange'}>
            <strong>This content is already enabled.</strong> This means students are currently able
            to view it. If you schedule this content for a future date, it will be hidden from
            students until that date and time.
          </Text>
        ) : null}
      </VSpaced>
      <Modal.Footer>
        <Modal.CloseButton>Cancel</Modal.CloseButton>
        {scheduledAt ? (
          <Button
            status={'danger'}
            onPress={() => {
              updateClass({
                segmentMetadata: overrideSegment(
                  segmentId,
                  { scheduledAt: undefined, visibleOverride: false, scheduledAtOffset: undefined },
                  segmentMetadata,
                ),
              })
            }}
            loading={loading}
          >
            Unschedule
          </Button>
        ) : null}
        <Form.Submit variant={'primary'} loading={loading}>
          Schedule
        </Form.Submit>
      </Modal.Footer>
    </Form.Modal>
  )
}

export interface ScheduleSegmentButtonProps extends Partial<Button$.ButtonProps> {
  segmentId: string
}
export function ScheduleSegmentButton({ segmentId, ...buttonProps }: ScheduleSegmentButtonProps) {
  const scheduledAt = useSegmentScheduledAt(segmentId)
  const [visible, setVisible] = React.useState(false)
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  if (!cls?.canEnableSegments) return null
  return (
    <>
      <ScheduleSegmentModal segmentId={segmentId} visible={visible} onVisibleChange={setVisible} />
      <Button onPress={() => setVisible(true)} icon={<Clock />} {...buttonProps}>
        {scheduledAt ? 'Reschedule / Unschedule' : 'Schedule'}
      </Button>
    </>
  )
}

export interface ScheduleSegmentMenuItemProps extends Partial<Dropdown$.DropdownMenuItemProps> {
  segmentId: string
}
export function ScheduleSegmentMenuItem({
  segmentId,
  ...menuItemProps
}: ScheduleSegmentMenuItemProps) {
  const scheduledAt = useSegmentScheduledAt(segmentId)
  const { cls } = useTeacherSelectedClass<ClassFragmentWithPermissions>(true)
  const { canEnableSegments } = cls || {}
  return (
    <ScheduleSegmentModal
      segmentId={segmentId}
      trigger={
        <Dropdown.Item {...menuItemProps} icon={<Clock />} disabled={!canEnableSegments}>
          {scheduledAt ? 'Reschedule / Unschedule' : 'Schedule'}
        </Dropdown.Item>
      }
    />
  )
}
