import { ReactFeature, ReactUse } from '@thesisedu/feature-react'
import { isNative } from '@thesisedu/react'

export interface NotificationPreferencesReactOptions {}

export class NotificationPreferencesReactFeature extends ReactFeature {
  static package = '@thesisedu/feature-notification-preferences-react'
  static path = ''
  static requires: string[] = []
  public options!: NotificationPreferencesReactOptions

  public reactResources() {
    if (!isNative) {
      require('./hooks/routes').default(this)
      require('./resources/UserSettingsPage/Notifications').default(this)
    }
  }
}

export const notificationPreferencesReactFeature: ReactUse = (
  opts: NotificationPreferencesReactOptions,
) => [NotificationPreferencesReactFeature, opts]
