import { Feature } from '@thesisedu/feature'
import { CanDeleteAssignmentHook } from '@thesisedu/feature-assignments-react'

export default function addCanDeleteAssignmentHook(feature: Feature) {
  feature.hookManager.registerMutateHook<CanDeleteAssignmentHook>(
    'feature-assignments-react:can-delete-assignment',
    (canDelete, { assignment }) => {
      // Teachers are not allowed to delete assignment associated with segments or templates.
      if (assignment.rawConfiguration?.segmentId || assignment.assignmentTemplateId) {
        return false
      } else {
        return canDelete
      }
    },
  )
}
