import { Body, FontWeight, HSpaced, Space, styled } from '@thesisedu/web'
import React from 'react'

import { getSelectedResources, useReportResources } from '../../../execute/input'
import { CollapseExpandAll } from '../CollapseExpandAll'
import { useReportGridContext } from '../Context'
import { MetricSummaries } from '../MetricSummaries'
import { getTopLeftHeader } from '../axes'

export interface TopLeftCellProps {
  style: any
}
export function TopLeftCell({ style }: TopLeftCellProps) {
  const { metrics } = useReportResources()
  const { responsibility, data, opts, flatSummaryRows } = useReportGridContext(true)
  const optsMetrics = getSelectedResources(metrics, opts.metrics)
  const display = optsMetrics.length === 1 ? optsMetrics[0].summarization : 'average'
  const topLeftTitle = getTopLeftHeader(responsibility)
  return (
    <TopLeftCellContainer style={style}>
      <HSpaced space={'@size-xs'} align={'baseline'} style={{ width: '100%' }}>
        {flatSummaryRows?.length ? <CollapseExpandAll /> : null}
        <Body weight={FontWeight.Bold}>{topLeftTitle}</Body>
        <Space />
        {optsMetrics.length === 1 ? (
          <MetricSummaries display={display} summaries={data.allMetrics} />
        ) : null}
      </HSpaced>
    </TopLeftCellContainer>
  )
}

const TopLeftCellContainer = styled.div`
  background: ${props => props.theme['@gray-1']};
  padding: ${props => props.theme['@size-xs']};
  border-right: solid 1px ${props => props.theme['@border-color-base']};
  border-bottom: solid 1px ${props => props.theme['@border-color-base']};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`
