interface SparkleOptions {
  color?: string
  padding?: number
}
export function sparkle(element: HTMLElement, opts?: SparkleOptions) {
  const { padding = 0.1 } = opts || {}
  const bounds = element.getBoundingClientRect()
  const xPadding = bounds.width * padding
  const yPadding = bounds.height * padding
  const xBounds: [number, number] = [bounds.x + xPadding, bounds.x + bounds.width - xPadding * 2]
  const yBounds: [number, number] = [bounds.y + yPadding, bounds.y + bounds.height - yPadding * 2]
  const averageSize = Math.max(bounds.x, bounds.y) * 0.035 // 3.5% of the element max dimension

  const numSparkles = 1 + Math.floor(Math.max(bounds.x, bounds.y) * 0.005)
  const cancelFns: (() => void)[] = []
  for (let i = 0; i < numSparkles; i++) {
    const timeout = setTimeout(() => {
      cancelFns.push(createSparklesLoop(xBounds, yBounds, averageSize, opts))
    }, i * 200)
    cancelFns.push(() => clearTimeout(timeout))
  }

  return () => {
    for (const cancel of cancelFns) {
      cancel()
    }
  }
}

function createSparklesLoop(
  xBounds: [number, number],
  yBounds: [number, number],
  averageSize: number,
  opts?: SparkleOptions,
) {
  const x = xBounds[0] + Math.random() * (xBounds[1] - xBounds[0])
  const y = yBounds[0] + Math.random() * (yBounds[1] - yBounds[0])
  let currentCancel = () => {}

  currentCancel = createSparkle(x, y, averageSize, opts, () => {
    currentCancel = createSparklesLoop(xBounds, yBounds, averageSize, opts)
  })

  return () => {
    currentCancel()
  }
}

function createSparkle(
  x: number,
  y: number,
  size: number,
  { color = '#FFF' }: SparkleOptions = {},
  completeCallback: () => void,
) {
  const particle = document.createElement('particle')
  document.body.appendChild(particle)

  const random = Math.random() * 0.5 + 0.5 // 50% - 150%

  const calculatedSize = size * random
  particle.style.width = `${calculatedSize}px`
  particle.style.height = `${calculatedSize}px`
  particle.style.left = `${x - calculatedSize / 2}px`
  particle.style.top = `${y - calculatedSize / 2}px`
  particle.style.borderRadius = '0'
  particle.style.background = color

  const targetOpacity = 0.95 * random // More opaque as gets larger
  const rotation = 45 * random
  const animation = particle.animate(
    [
      {
        transform: `scale(0) rotate(${rotation}deg)`,
        opacity: 0,
      },
      {
        transform: `scale(0) rotate(${rotation}deg)`,
        opacity: 0,
        offset: 0.4,
      },
      {
        transform: `scale(1) rotate(${rotation}deg)`,
        opacity: targetOpacity,
        offset: 0.5,
      },
      {
        transform: `scale(1) rotate(${rotation}deg)`,
        opacity: targetOpacity,
        offset: 0.9,
      },
      {
        transform: `scale(0) rotate(${rotation}deg)`,
        opacity: 0,
        offset: 1,
      },
    ],
    {
      duration: 2000 + 5000 * random,
      easing: 'ease-in-out',
    },
  )

  animation.onfinish = () => {
    particle.remove()
    completeCallback()
  }

  return () => {
    animation.cancel()
    particle.remove()
  }
}
