import React from 'react'

import { styledContainer as styled } from '../styled'
import { color, getColor } from '../theme'

export interface ProgressProps {
  current: number
  total: number
  style?: any
  className?: string
}
/** @deprecated */
export function Progress({ current, total, style, className }: ProgressProps) {
  if (total > 0) {
    return (
      <Outer style={style} className={className}>
        <Inner style={{ width: `${((current * 100) / total).toFixed(2)}%` }} />
      </Outer>
    )
  } else {
    return null
  }
}

const Outer = styled`
  border-radius: 200px;
  height: 4px;
  background: ${color('@gray-1')};
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  width: 200px;
`
const Inner = styled<{ color?: string }>`
  transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  background: ${props => getColor(props.theme, props.color || '@primary-color')};
  border-radius: 200px;
`
