import { useMutateHook } from '@thesisedu/feature-react'
import React from 'react'

import { TeacherContextHook } from './types'

export interface WrapWithTeacherContextProps {
  children: React.ReactElement
}
export function WrapWithTeacherContext({ children }: WrapWithTeacherContextProps) {
  return useMutateHook<TeacherContextHook>('teachers-react:teacher-context', children, undefined)
}
