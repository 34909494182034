import { CloseOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { MenuProps } from 'antd/es/menu'
import React from 'react'

export interface Option {
  value: string
  label: string
}
export interface FilterButtonProps {
  label: string
  menuProps?: Partial<MenuProps>
  buttonProps?: Partial<ButtonProps>
  value?: string | string[]
  multiple?: boolean
  options: Option[]
  onChange?: (value: string | string[] | null) => void
  children?: any
  hideClear?: boolean
}
export function FilterButton({
  label,
  children,
  value,
  options,
  onChange,
  multiple,
  hideClear,
  buttonProps = {},
}: FilterButtonProps) {
  const overlay = (
    <Menu
      selectedKeys={multiple ? (value as string[]) : [value as string]}
      onClick={({ key }) => {
        if (onChange) {
          if (multiple) {
            const filtered = ((value || []) as string[]).filter(item => item !== key)
            if (filtered.length === (value || []).length) {
              onChange([...(value as string[]), key.toString()])
            } else {
              onChange(filtered)
            }
          } else {
            onChange(key === value ? null : key.toString())
          }
        }
      }}
    >
      {children || options.map(option => <Menu.Item key={option.value}>{option.label}</Menu.Item>)}
    </Menu>
  )
  const arrayValues = value ? (Array.isArray(value) ? value : [value]) : []
  const selectedOptions = arrayValues
    .map(val => {
      return options.find(opt => opt.value === val)
    })
    .filter(Boolean)
  const selectedLabel =
    selectedOptions.length > 1
      ? `${selectedOptions.length} selected`
      : selectedOptions[0]
      ? selectedOptions[0].label
      : null

  const buttonInner = selectedOptions.length ? (
    <>
      <strong>{label}: </strong>
      <span>
        {selectedLabel?.length && selectedLabel.length > 30
          ? `${selectedLabel?.slice(0, 30)}...`
          : selectedLabel}
      </span>
    </>
  ) : (
    `Filter ${label}`
  )

  const type = value && value.length > 0 ? 'primary' : undefined
  return (
    <Button.Group>
      <Dropdown overlay={overlay} trigger={['click']}>
        <Button {...buttonProps} type={type}>
          {buttonInner}
        </Button>
      </Dropdown>
      {type && !hideClear ? (
        <Button
          {...buttonProps}
          icon={<CloseOutlined />}
          onClick={() => {
            if (onChange) {
              onChange(multiple ? [] : null)
            }
          }}
          type={type}
        />
      ) : null}
    </Button.Group>
  )
}
