import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { Link } from '@thesisedu/ui/icons'
import React from 'react'

import { TextFormatToolbarButton } from '../../ui/TextFormatToolbar/TextFormatToolbarButton'
import {
  TextFormatToolbarItemProps,
  useTextFormatToolbarItem,
} from '../../ui/TextFormatToolbar/TextFormatToolbarContext'
import { getSelectedNode } from '../../utils/getSelectedNode'

function LinkElement({ active, editor }: TextFormatToolbarItemProps) {
  return (
    <TextFormatToolbarButton
      ariaLabel={'Insert Link'}
      icon={<Link />}
      label={'Link'}
      active={active}
      onClick={() => {
        if (!active) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://example.com')
        } else {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
        }
      }}
    />
  )
}

export function useLinkEditorToolbarButton() {
  useTextFormatToolbarItem({
    identifier: 'link',
    weight: 3.6,
    group: 'code',
    getActive(editor, selection) {
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      return $isLinkNode(parent) || $isLinkNode(node)
    },
    element: LinkElement,
  })
}
