import {
  PageMetadataQuery_pageMetadata_PageMetadata as PageMetadata,
  usePageMetadataQuery,
} from '../../../schema'

export interface UsePageMetadata {
  metadata: PageMetadata | null
  loading: boolean
}

export function usePageMetadata(url: string): UsePageMetadata {
  const { data, loading } = usePageMetadataQuery({ variables: { url } })
  const metadata = data?.pageMetadata ?? null
  return { loading, metadata }
}
