export function throttle<F extends (...args: any[]) => any>(
  func: F,
  delay: number,
): (...args: Parameters<F>) => ReturnType<F> {
  let lastCall = 0
  let lastResult: ReturnType<F>

  return (...args: Parameters<F>): ReturnType<F> => {
    const now = new Date().getTime()

    if (!lastCall || now - lastCall >= delay) {
      lastCall = now
      lastResult = func(...args)
    }

    return lastResult
  }
}
