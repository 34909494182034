import { InfiniteQuery } from '@thesisedu/feature-apollo-react/web'
import { useSelectedClassId } from '@thesisedu/feature-classes-react'
import React from 'react'

import { PracticeList, PracticeListProps } from '../PracticeList'
import {
  PracticeFragment,
  StudentPracticesDocument,
  StudentPracticesQuery,
  StudentPracticesQueryVariables,
} from '../schema'

export const StudentPracticeList: React.FC<
  React.PropsWithChildren<Partial<PracticeListProps>>
> = props => {
  const selectedClassId = useSelectedClassId()
  return (
    <InfiniteQuery<PracticeFragment, StudentPracticesQuery, StudentPracticesQueryVariables>
      document={StudentPracticesDocument}
      getResult={d => d?.viewer?.student?.practices}
      variables={{
        classId: selectedClassId,
      }}
      setResult={(previous, result) => ({
        ...previous,
        viewer: {
          ...previous.viewer!,
          student: {
            ...previous.viewer!.student!,
            practices: {
              ...previous.viewer!.student!.practices,
              ...result,
            },
          },
        },
      })}
      children={({ data, refetch }) => (
        <PracticeList
          practices={data?.viewer?.student?.practices.edges.map(edge => edge.node) || []}
          refetch={after => refetch({ classId: selectedClassId, after })}
          {...props}
        />
      )}
    />
  )
}
