import styled from 'styled-components'

interface Theme {
  [key: string]: any
}
const getSize = (identifier: string | boolean | undefined, theme: Theme): any => {
  if (identifier === true) {
    return theme['@size-xs']
  } else if (identifier) {
    return theme[identifier]
  } else return 0
}

export interface BlockProps {
  marginTop?: string | boolean
  marginBottom?: string | boolean
  marginLeft?: string | boolean
  marginRight?: string | boolean
  paddingTop?: string | boolean
  paddingBottom?: string | boolean
  paddingLeft?: string | boolean
  paddingRight?: string | boolean
}
export const Block = styled.div<BlockProps>`
  margin-top: ${props => getSize(props.marginTop, props.theme)};
  margin-bottom: ${props => getSize(props.marginBottom, props.theme)};
  margin-left: ${props => getSize(props.marginLeft, props.theme)};
  margin-right: ${props => getSize(props.marginRight, props.theme)};
  padding-top: ${props => getSize(props.paddingTop, props.theme)};
  padding-bottom: ${props => getSize(props.paddingBottom, props.theme)};
  padding-left: ${props => getSize(props.paddingLeft, props.theme)};
  padding-right: ${props => getSize(props.paddingRight, props.theme)};
  &:empty {
    display: none;
  }
`

export interface SpacerProps {
  horizontal?: string | boolean
  vertical?: string | boolean
}
export const Spacer = styled.div<SpacerProps>`
  width: ${props => (props.horizontal ? getSize(props.horizontal, props.theme) : 0)};
  height: ${props => (props.vertical ? getSize(props.vertical, props.theme) : 0)};
`
export const Space = styled.div`
  margin: auto;
`

export interface SpacedProps {
  align?: string
  justify?: string
  space?: string | boolean
}
export const HSpaced = styled.div<SpacedProps>`
  display: flex;
  align-items: ${props => props.align || 'center'};
  justify-content: ${props => props.justify || 'flex-start'};
  > :not(:last-child) {
    margin-right: ${props => getSize(props.space || true, props.theme)} !important;
  }
`
export const VSpaced = styled(HSpaced)`
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
  > :not(:last-child) {
    margin-bottom: ${props => getSize(props.space || true, props.theme)} !important;
    margin-right: 0 !important;
  }
`

export const LargeBlock = styled.div`
  border-radius: ${props => props.theme['@border-radius-large']};
  background: ${props => props.theme['@gray-0']};
  box-shadow: ${props => props.theme['@shadow-medium']};
  padding: ${props => props.theme['@padding-md']};
  border: solid 1px ${props => props.theme['@border-color-split']};
  overflow: hidden;
`

export const ContentBlock = styled(LargeBlock)<{ compact?: boolean }>`
  box-shadow: none;
  border: none;
  overflow: visible;
  padding: ${props => props.theme[props.compact ? '@size-s' : '@size-l']};
  &:empty {
    display: none;
  }
`
