import { TuneMeter } from '@thesisedu/feature-sheet-music-core'
import { styled } from '@thesisedu/web'
import { transparentize } from 'polished'
import React from 'react'

const MAX_STEP = 2
export interface MeasureMeterBackgroundProps {
  meter: TuneMeter
  numMeasures: number
}
export function MeasureMeterBackground({ meter, numMeasures }: MeasureMeterBackgroundProps) {
  const meterSteps: number[] = []
  const meterStep = meter.num % 3 === 0 ? 1 : 2
  for (let i = 0; i < numMeasures * meter.num; i++) {
    const lastStep = meterSteps[meterSteps.length - 1]
    if (lastStep === undefined || lastStep + meterStep > MAX_STEP) {
      meterSteps.push(0)
    } else {
      meterSteps.push(lastStep + meterStep)
    }
  }
  return (
    <Container>
      {meterSteps.map((step, index) => (
        <MeterItem key={index} step={step} proportion={(1 / numMeasures) * meter.num} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  z-index: -1;
  opacity: 0.5;
`
const MeterItem = styled.div<{ step: number; proportion: number }>`
  flex: ${props => props.proportion};
  background: ${props => transparentize(props.step * 0.4, props.theme['@gray-2'])};
`
