import { useFeature, DeepLinkProps } from '@thesisedu/feature-react'
import { useViewerContext } from '@thesisedu/feature-users-web'
import React from 'react'

import { useClasses } from './ClassContext'
import { ClassesReactFeature } from './ClassesReactFeature'

export function UserGroupClassPrefixDeepLinkRemapper({ resolved, url }: DeepLinkProps) {
  const viewer = useViewerContext(false)
  const feature = useFeature(ClassesReactFeature)
  const { classes, loading } = useClasses()
  React.useEffect(() => {
    if (viewer?.group) {
      if (feature.options.userGroupClassPrefix?.[viewer.group]) {
        if (!loading) {
          if (classes.length) {
            resolved({
              type: 'Found',
              resolvedPath: `/${feature.options.userGroupClassPrefix[viewer.group].replace(
                /:classId/g,
                classes[0].id,
              )}/${url.replace(/^\//, '')}`,
            })
          } else {
            resolved({
              type: 'NotFound',
            })
          }
        }
      } else {
        resolved({
          type: 'Found',
          resolvedPath: `/${viewer.group.toLowerCase()}/${url.replace(/^\//, '')}`,
        })
      }
    } else {
      resolved({
        type: 'AccessDenied',
      })
    }
  }, [classes, loading])
  return null
}
