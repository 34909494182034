import { Format } from '@thesisedu/feature-utils'
import { Link } from '@thesisedu/react'
import { Text, Well } from '@thesisedu/ui'
import React from 'react'

import { ClassApprovalTable } from './ClassApprovalTable'
import { PendingStudentClassState, useClassPendingStudentClassesQuery } from '../schema'

const FIRST = 10
export interface ClassApprovalOverviewProps {
  classId: string
}
export function ClassApprovalOverview({ classId }: ClassApprovalOverviewProps) {
  const { data } = useClassPendingStudentClassesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      first: FIRST,
      classId,
      states: [PendingStudentClassState.Pending],
    },
  })

  const totalCount =
    data?.node?.__typename === 'Class' ? data.node.pendingStudentClasses.totalCount : 0
  const records =
    data?.node?.__typename === 'Class'
      ? data.node.pendingStudentClasses.edges.map(edge => edge.node)
      : []

  if (records.length) {
    return (
      <Well
        top={'s'}
        bottom={'s'}
        title={Format.plural('join request', totalCount)}
        actions={
          totalCount > FIRST ? (
            <Link to={`/teacher/classes/${classId}/students/approval`}>
              <Text level={'s'} color={'primary'}>
                View all
              </Text>
            </Link>
          ) : undefined
        }
      >
        <ClassApprovalTable records={records} />
      </Well>
    )
  } else return null
}
