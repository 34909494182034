import { AssignmentRubricFragment, AssignmentRubricInput } from '../schema'

export const transformRubric = (rubric: AssignmentRubricFragment): AssignmentRubricInput => {
  return {
    categories: rubric.categories.map(({ categoryId, __typename, ...rest }) => ({
      ...rest,
      id: categoryId,
      presets: rest.presets?.map(({ presetId, __typename, ...rest }) => ({
        id: presetId,
        ...rest,
      })),
    })),
  }
}
