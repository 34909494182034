import {
  Modal,
  Button,
  ButtonProps,
  Input,
  message,
  useUpdatingState,
  Block,
} from '@thesisedu/react'
import React from 'react'

import { useUpdatePracticeMutation } from '../queries/useUpdatePracticeMutation'

export interface NamePracticeProps {
  id: string
  name?: string | null
  onComplete?: () => void
}
export function NamePractice({ id, name: _name, onComplete }: NamePracticeProps) {
  const [name, setName] = useUpdatingState(() => _name, [_name])
  const [updatePractice, { loading }] = useUpdatePracticeMutation({
    onCompleted: () => {
      message.success('Practice renamed successfully!')
      if (onComplete) {
        onComplete()
      }
    },
  })

  function saveChanges() {
    if (name?.trim()) {
      updatePractice({
        variables: {
          input: {
            id,
            patch: {
              name,
            },
          },
        },
      })
    }
  }

  return (
    <>
      <Block marginBottom={'@size-s'}>
        <Input
          size={'large'}
          placeholder={'My Practice'}
          value={name || undefined}
          onChange={e => setName(e.target.value)}
          onPressEnter={() => saveChanges()}
        />
      </Block>
      <Button
        type={'primary'}
        loading={loading}
        onClick={() => saveChanges()}
        disabled={!name?.trim()}
      >
        Rename Practice
      </Button>
    </>
  )
}

export interface NamePracticeModalProps extends NamePracticeProps {
  visible?: boolean
  onCancel: () => void
}
export function NamePracticeModal({ visible, onCancel, ...rest }: NamePracticeModalProps) {
  return (
    <Modal title={'Rename Practice'} footer={null} visible={visible} onCancel={onCancel}>
      <NamePractice
        {...rest}
        onComplete={() => {
          onCancel()
          if (rest.onComplete) {
            rest.onComplete()
          }
        }}
      />
    </Modal>
  )
}

export interface NamePracticeButtonProps extends NamePracticeProps {
  buttonProps?: ButtonProps
}
export function NamePracticeButton({ buttonProps, ...rest }: NamePracticeButtonProps) {
  const [visible, setVisible] = React.useState(false)
  return (
    <>
      <NamePracticeModal visible={visible} onCancel={() => setVisible(false)} {...rest} />
      <Button children={'Rename'} {...buttonProps} onClick={() => setVisible(true)} />
    </>
  )
}
