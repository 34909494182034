import { ErrorService } from '@thesisedu/feature'
import React, { useContext } from 'react'

import { Error403 } from './Error403'
import { Error404 } from './Error404'
import { Error500 } from './Error500'
import { AccessDeniedError, isExpectedError, NotFoundError } from './errors'
import { debug } from './log'

export type ErrorServiceContextValue = ErrorService | undefined
export const ErrorServiceContext = React.createContext<ErrorServiceContextValue>(undefined)

export const useErrorService = (): ErrorService => {
  const value = useContext<ErrorServiceContextValue>(ErrorServiceContext)
  if (!value) {
    throw new Error('error service is required, but not defined')
  }
  return value
}

export interface ErrorProviderProps {
  errorService: ErrorService
  debugOutput?: boolean
}

interface ProviderState {
  error: Error | null
  errorInfo: React.ErrorInfo | null
}
export class ErrorProvider extends React.Component<React.PropsWithChildren<ErrorProviderProps>> {
  state: ProviderState = { error: null, errorInfo: null }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    if (!isExpectedError(error)) {
      debug('reporting error inside ErrorProvider')
      this.props.errorService.reportError(error, { errorInfo })
    }
    this.setState({ error, errorInfo })
  }

  render() {
    let content
    if (this.state.error) {
      if (this.props.debugOutput) {
        // This require is done here because of node requirements.
        const { ErrorOutput } = require('./ErrorOutput')
        content = <ErrorOutput error={this.state.error} />
      } else {
        const onBack = () => {
          window.history.back()
          setTimeout(() => {
            this.setState({ error: undefined })
          }, 10)
        }
        if (isExpectedError(this.state.error, NotFoundError)) {
          content = <Error404 onBack={onBack} />
        } else if (isExpectedError(this.state.error, AccessDeniedError)) {
          content = <Error403 onBack={onBack} />
        } else {
          content = <Error500 error={this.state.error} errorInfo={this.state.errorInfo} />
        }
      }
    } else {
      content = this.props.children
    }
    return (
      <ErrorServiceContext.Provider value={this.props.errorService}>
        {content}
      </ErrorServiceContext.Provider>
    )
  }
}
