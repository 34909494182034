import { useDimensionValueSelectValues } from './DimensionValueSelect'
import { GraphConfiguration } from './GraphConfig'
import { getSelectedResources, ReportResources } from '../../execute/input'
import { RunReportOpts, RunReportQuery } from '../../types'

export interface GraphTitleOpts {
  report: RunReportOpts
  data: RunReportQuery['runReport']
  resources: ReportResources
  config: GraphConfiguration
}

export function useGraphTitle({ report, data, resources, config }: GraphTitleOpts) {
  const metric =
    report.metrics.length === 1
      ? getSelectedResources(resources.metrics, report.metrics)[0]
      : resources.metrics.find(m => m.identifier === config.selectedMetricKey)
  const filteredDimension =
    config.selectedDimensionIdentifier && config.selectedDimensionValue
      ? resources.dimensions.find(d => d.identifier === config.selectedDimensionIdentifier)
      : undefined
  const firstDimension = getSelectedResources(resources.dimensions, report.dimensions)[0]
  const filterOptions = useDimensionValueSelectValues({
    report,
    data,
    dimension: filteredDimension,
  })

  if (metric) {
    const filterOption = filteredDimension
      ? filterOptions.find(opt => opt.value === JSON.stringify(config.selectedDimensionValue))
      : undefined
    const _for = filterOption
      ? ` for ${filterOption.label}`
      : firstDimension && report.dimensions.length && firstDimension.titleLabel
      ? ` for ${firstDimension.titleLabel(
          report.dimensions[0].options,
          report.dimensions[0].context,
        )}`
      : ''
    return `${metric.title}${_for}`
  } else {
    return ''
  }
}
