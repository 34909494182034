import { SplitViewPendingChanges } from './SplitViewPendingChangesContext'

export function getFinalChanges(changes: SplitViewPendingChanges): SplitViewPendingChanges {
  return {
    ...changes,
    // Only create segments that were actually added to the class.
    createdSegments: changes.createdSegments.filter(segment => {
      return changes.structureOverrides.some(override => override.id === segment.id)
    }),
  }
}
