import { isEqual } from '@thesisedu/feature-utils'
import React from 'react'
import {
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

import { FieldProps } from '../Field'

export type FormFieldConnectorProps<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
> = Omit<UseControllerProps<TFieldValues, FieldName>, 'rules' | 'defaultValue'> &
  UseControllerProps<TFieldValues, FieldName>['rules'] &
  Omit<FieldProps, 'children'> & {
    name: FieldName
    children: React.ReactElement<
      ControllerRenderProps<TFieldValues, FieldName> & FieldProps & Record<string, any>
    >
    initialValue?: UseControllerProps<TFieldValues, FieldName>['defaultValue']
    /**
     * This is like initialValue, but is sent to the child only if the form value is empty. So
     * if the value changes to match defaultValue, it will actually return undefined. If the value
     * is undefined, defaultValue will be passed to the component instead.
     */
    defaultValue?: UseControllerProps<TFieldValues, FieldName>['defaultValue']
    valuePropName?: string
    changePropName?: string
  }
export function FormFieldConnector<
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
>({
  name,
  initialValue,
  defaultValue,
  valuePropName = 'value',
  changePropName = 'onChange',
  children,
  description,
  label,
  ...restProps
}: FormFieldConnectorProps<TFieldValues, FieldName>) {
  const { required, min, max, minLength, maxLength, pattern, validate, control, ...rest } =
    restProps
  const rules = { required, min, max, minLength, maxLength, pattern, validate }
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue: initialValue,
    control,
    rules: {
      ...rules,
      required: rules?.required === true ? 'This field is required.' : rules?.required,
    },
  })
  const fieldError = error?.message
  const onChange: typeof field.onChange = val => {
    if (defaultValue !== undefined) {
      return field.onChange(isEqual(val, defaultValue) ? undefined : val)
    } else {
      return field.onChange(val)
    }
  }
  return React.cloneElement(children, {
    ...rest,
    ...field,
    [changePropName]: onChange,
    [valuePropName]:
      field.value === undefined && defaultValue !== undefined ? defaultValue : field.value,
    isRequired: !!required || !!rules?.required,
    onChangeText: (value?: string) => onChange(value),
    error: fieldError,
    label,
    description,
    'data-field': field.name,
    'data-testid': `FormField ${field.name}`,
  })
}

export type ConnectedFormFieldProps<
  BaseProps extends object,
  TFieldValues extends FieldValues,
  FieldName extends FieldPath<TFieldValues>,
> = Omit<BaseProps, keyof Omit<FormFieldConnectorProps<TFieldValues, FieldName>, 'children'>> &
  Omit<FormFieldConnectorProps<TFieldValues, FieldName>, 'children'>
