import { useSelectedClass } from '@thesisedu/feature-classes-react'
import {
  ReadOnlyEditor,
  Editor,
  useCourseContext,
  useUpdateClassMutation,
  Segment,
} from '@thesisedu/feature-courses-react'
import { overrideSegment } from '@thesisedu/feature-courses-react/dist/helpers'
import { useViewerContext } from '@thesisedu/feature-users-web'
import { Legacy } from '@thesisedu/feature-widgets-react'
import { H3Alternate, styled } from '@thesisedu/web'
import { Button } from 'antd'
import React, { useState } from 'react'

const Container = styled.div`
  padding-bottom: ${props => props.theme['@size-l']};
  > .header {
    margin: 0 0 ${props => props.theme['@size-s']} 0;
    display: flex;
    align-items: center;
    > button {
      margin-left: auto;
    }
  }
`

declare module '@thesisedu/feature-course-types' {
  interface SegmentMetadataSegment {
    studentContent?: Legacy.AnyEditorValue
  }
}

export interface AssignmentInstructionsProps {
  segment: Segment
  readOnly?: boolean
}
export function AssignmentInstructions({ segment, readOnly }: AssignmentInstructionsProps) {
  const viewer = useViewerContext(true)
  const classInstance = useSelectedClass(false)
  const courseContext = useCourseContext(false)
  const [contentDirty, setContentDirty] = useState(false)
  const [updateClass, { loading }] = useUpdateClassMutation(classInstance?.id || '', {
    onCompleted: () => setContentDirty(false),
  })
  const [content, setContent] = useState<Legacy.AnyEditorValue>(
    segment.studentContent || segment.config.studentContent,
  )

  if (viewer.group === 'STUDENT' || !classInstance || readOnly) {
    if (Legacy.anyEditorValueIsEmpty(content)) {
      return null
    } else {
      return (
        <Container>
          <ReadOnlyEditor id={`instructions-${segment.id}`} defaultValue={content} />
        </Container>
      )
    }
  } else {
    return (
      <Container>
        <div className={'header'}>
          <H3Alternate>Student Instructions</H3Alternate>
          <Button
            disabled={!contentDirty}
            loading={loading}
            type={'primary'}
            onClick={() => {
              updateClass({
                segmentMetadata: overrideSegment(
                  segment.id,
                  {
                    studentContent: content,
                  },
                  courseContext?.segmentMetadata,
                ),
              })
            }}
          >
            Save Student Instructions
          </Button>
        </div>
        <div className={'content'}>
          <Editor
            id={`${segment.id}-student-content`}
            defaultValue={content}
            onChange={updatedContent => {
              setContent(updatedContent)
              setContentDirty(true)
            }}
            minHeight={75}
            placeholder={'Add your student instructions here.'}
          />
        </div>
      </Container>
    )
  }
}
