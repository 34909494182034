import { useMediaPlaybackContext, styled, getColor, ClickableContainer } from '@thesisedu/react'
import React from 'react'

import { CommentFragment, useMediaCommentsQuery } from '../schema'
const CommentDecorator = styled(ClickableContainer)<{ percentage: number }>`
  position: absolute;
  display: flex;
  left: ${props => props.percentage * 100}%;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 14px;
  background: ${props => getColor(props.theme, '@blue')};
  width: 14px;
  height: 14px;
  margin-left: -7px;
  pointer-events: all;
  border-radius: 200px;
  cursor: pointer;
  z-index: 99;
`

export interface MediaCommentsDecoratorProps {
  mediaId: string
  onClick: (comment: CommentFragment) => void
}
export const MediaCommentsDecorator: React.FC<
  React.PropsWithChildren<MediaCommentsDecoratorProps>
> = ({ mediaId, onClick }) => {
  const { duration } = useMediaPlaybackContext()
  const { data } = useMediaCommentsQuery({ variables: { mediaId } })
  if (!duration) return null
  const comments: CommentFragment[] =
    data?.node?.__typename === 'Media' ? data.node.comments.edges.map(edge => edge.node) || [] : []
  const results = comments
    .filter(comment => comment.metadata?.currentTimeSeconds)
    .map(comment => (
      <CommentDecorator
        key={comment.id}
        onClick={() => onClick(comment)}
        percentage={comment.metadata.currentTimeSeconds / duration}
      />
    ))
  return <>{results}</>
}
