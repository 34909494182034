import { UploadedFile } from './types'

export function isWebFile(file: UploadedFile): file is File {
  return typeof (file as File).arrayBuffer !== 'undefined'
}

export function getFileContents(file: UploadedFile): Blob | string {
  return isWebFile(file) ? file : file.uri
}

export function getFileMimeType(file: UploadedFile): string | null {
  return file.type
}

export function getFileName(file: UploadedFile): string {
  return file.name
}

export function getFileSize(file: UploadedFile): number | null {
  return file.size
}
