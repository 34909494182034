import React from 'react'
import { useLocation } from 'react-router-dom'

import { debug } from './log'

export function HashSync() {
  const location = useLocation()
  React.useEffect(() => {
    const timer = setTimeout(() => {
      const id = location.hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        debug('scrolling element into view %s', id)
        element.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [location.hash])
  return null
}
